"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class CustomProfileFormAPI {
  static addFields(fieldBody) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/company/fields/add-field", { fieldBody })).data;
    });
  }
  static deleteField(fieldId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/company/fields/${fieldId}`)).data;
    });
  }
  static createForm(form) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/forms/create`, form)).data;
    });
  }
  static updateForm(formId, form) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/company/forms/${formId}`, form)).data;
    });
  }
  static deleteForm(formId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/company/forms/${encodeURIComponent(formId)}`);
    });
  }
  static setFormValues(userId, formId, values) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/users/${userId}/custom/${formId}`, { values })).data;
    });
  }
  static listForms() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/company/forms")).data;
    });
  }
  static getFormValues(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/custom`)).data;
    });
  }
}
export class CustomProfileFormEndpoints {
  static listForms() {
    return {
      url: "/apiv2/company/forms"
    };
  }
  // returns all fields as not hidden if current user has user:all scope
  static listAllFieldsProfileHook() {
    return {
      url: "/apiv2/company/fields/all-fields-profile"
    };
  }
  static listAllFields() {
    return {
      url: "/apiv2/company/fields/all-fields"
    };
  }
  static listAllDateFields() {
    return {
      url: "/apiv2/company/fields/all-date-fields"
    };
  }
  static getFormValues(userId) {
    return { url: `/apiv2/users/${userId}/custom` };
  }
}
