"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserAPI } from "@/v2/feature/user/user.api";
export const EmergencyContactSchema = Yup.object().shape({
  emergencyName: Yup.string().nullable().required("Full name of the contact is required"),
  emergencyNumber: Yup.string().nullable().required("Contact phone is required"),
  emergencyRelationship: Yup.string().nullable().required("Specifying a relationship is required")
});
export const EmergencyContactForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  }
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: __spreadProps(__spreadValues({}, initialValues), { customUpdates: (_a = initialValues.customUpdates) != null ? _a : [] }),
    enableReinitialize: true,
    validationSchema: EmergencyContactSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2, _d2;
      setLoading(true);
      try {
        const update = {
          emergencyName: (_a2 = values.emergencyName) != null ? _a2 : null,
          emergencyRelationship: (_b2 = values.emergencyRelationship) != null ? _b2 : null,
          emergencyNumber: (_c2 = values.emergencyNumber) != null ? _c2 : null,
          customUpdates: (_d2 = values.customUpdates) != null ? _d2 : []
        };
        if (!usedForDataImport) {
          yield UserAPI.patchUserEmergencyContactInfo(userId, update);
          onSubmit(update);
          showMessage(polyglot.t("EmergencyContactForm.successMessages.update"), "success");
        } else {
          importHandler == null ? void 0 : importHandler(update);
        }
      } catch (error) {
        showMessage(polyglot.t("EmergencyContactForm.errorMessages.save", { msg: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("EmergencyContactForm.edit") }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "user.emergencyName", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyName",
        label: polyglot.t("EmergencyContactForm.fullName"),
        value: formik.values.emergencyName,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.emergencyName && !!formik.errors.emergencyName,
        helperText: (_b = formik.touched.emergencyName && formik.errors.emergencyName) != null ? _b : " ",
        clearText: () => formik.setFieldValue("emergencyName", "")
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "user.emergencyNumber", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyNumber",
        label: polyglot.t("EmergencyContactForm.phone"),
        value: formik.values.emergencyNumber,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.emergencyNumber && !!formik.errors.emergencyNumber,
        helperText: (_c = formik.touched.emergencyNumber && formik.errors.emergencyNumber) != null ? _c : " ",
        clearText: () => formik.setFieldValue("emergencyNumber", "")
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "user.emergencyRelationship", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyRelationship",
        label: polyglot.t("EmergencyContactForm.relationship"),
        value: formik.values.emergencyRelationship,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.emergencyRelationship && !!formik.errors.emergencyRelationship,
        helperText: (_d = formik.touched.emergencyRelationship && formik.errors.emergencyRelationship) != null ? _d : " ",
        clearText: () => formik.setFieldValue("emergencyRelationship", "")
      }
    ) }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: (_e = formik.values.customUpdates) != null ? _e : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values)
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
