"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  MaxInactivity,
  MinimumComplexChars,
  MinimumLength,
  ZeltPolicyPayloadIdentifier
} from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@/v2/styles/spacing.styles";
export const ZeltMdmPasscodeSecurityPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const [maxPINAgeInDaysHelper, setMaxPINAgeInDaysHelper] = useState("");
  const [pinHistoryHelper, setPinHistoryHelper] = useState("");
  const [allowSimpleHelper, setAllowSimpleHelper] = useState(false);
  const setPasswordHelperValue = (passwordOption) => {
    if (!passwordOption) {
      return;
    }
    if (passwordOption.pinHistory) {
      setPinHistoryHelper(passwordOption.pinHistory.toString());
    }
    if (passwordOption.maxPINAgeInDays) {
      setMaxPINAgeInDaysHelper(passwordOption.maxPINAgeInDays.toString());
    }
    if (passwordOption.allowSimple !== void 0) {
      setAllowSimpleHelper(!passwordOption.allowSimple);
    }
  };
  const getPolicy = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
    return {
      minLength: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _a.minLength) != null ? _b : 11,
      maxInactivity: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _c.maxInactivity) != null ? _d : 0,
      minComplexChars: (_f = (_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _e.minComplexChars) != null ? _f : 0,
      maxPINAgeInDays: (_h = (_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _g.maxPINAgeInDays) != null ? _h : 0,
      pinHistory: (_j = (_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _i.pinHistory) != null ? _j : 0,
      requireAlphanumeric: (_l = (_k = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _k.requireAlphanumeric) != null ? _l : false,
      allowSimple: (_n = (_m = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _m.allowSimple) != null ? _n : true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.PASSWORD_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(policy) {
    if (policy.minLength === 0 && policy.maxInactivity === 0 && policy.minComplexChars === 0 && policy.maxPINAgeInDays === 0 && policy.pinHistory === 0 && policy.pinHistory === 0 && !policy.requireAlphanumeric && policy.allowSimple) {
      return void 0;
    }
    return __spreadProps(__spreadValues(__spreadValues(__spreadValues(__spreadValues({
      minLength: policy.minLength
    }, policy.maxInactivity !== 0 && { maxInactivity: policy.maxInactivity }), policy.minComplexChars !== 0 && { minComplexChars: policy.minComplexChars }), policy.pinHistory !== 0 && { pinHistory: policy.pinHistory }), policy.maxPINAgeInDays !== 0 && { maxPINAgeInDays: policy.maxPINAgeInDays }), {
      allowSimple: policy.allowSimple,
      requireAlphanumeric: policy.requireAlphanumeric,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.PASSWORD_PAYLOAD_IDENTIFIER.valueOf()
    });
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (passwordForm) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(passwordForm);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurablePasscode: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    const policy = getPolicy();
    formik.validateForm();
    setPasswordHelperValue(policy);
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: spacing.mb20, children: "Passcode" }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "minLength",
            label: "Minimum length",
            options: MinimumLength,
            value: formik.values.minLength,
            onChange: (e) => {
              const minLength = e == null ? void 0 : e.target.value;
              formik.setFieldValue("minLength", minLength);
            }
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "minComplexChar",
            label: "Minimum complex characters",
            options: MinimumComplexChars,
            value: formik.values.minComplexChars,
            onChange: (e) => {
              const minComplexChars = e == null ? void 0 : e.target.value;
              formik.setFieldValue("minComplexChars", minComplexChars);
            }
          }
        ) }),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Require alphanumeric passcode",
            name: "requireAlphanumeric",
            description: "Require both numbers and letters. If disabled, numbers only is accepted ",
            checked: formik.values.requireAlphanumeric,
            onChange: (e, checked) => {
              formik.setFieldValue("requireAlphanumeric", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Enforce strong passcode",
            name: "allowSimple",
            description: "Passcodes containing guessable sequences like 123 or ABCD will not be allowed. At least 1 complex char",
            checked: allowSimpleHelper,
            onChange: (e, checked) => {
              setAllowSimpleHelper(checked);
              formik.setFieldValue("allowSimple", !checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Maximumim passcode age (days).",
            name: "number",
            type: "number",
            value: maxPINAgeInDaysHelper,
            minValue: 0,
            maxValue: 720,
            validateNumber: true,
            tooltip: "The number of days for which the passcode can remain unchanged. After this number of days, the user is forced to change the passcode before the device is unlocked. Range 1-720",
            onChange: (e) => {
              let maxPINAgeInDays = e == null ? void 0 : e.target.value;
              if (maxPINAgeInDays === "0") {
                setMaxPINAgeInDaysHelper("");
              } else {
                setMaxPINAgeInDaysHelper(maxPINAgeInDays);
              }
              formik.setFieldValue("maxPINAgeInDays", Number(maxPINAgeInDays));
            }
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Maximum history kept",
            name: "minPinHistory",
            type: "number",
            value: pinHistoryHelper,
            minValue: 0,
            maxValue: 50,
            validateNumber: true,
            tooltip: "This value defines N, where the new passcode must be unique within the last N entries in the passcode history. Range 1-50",
            onChange: (e) => {
              let pinHistory = e == null ? void 0 : e.target.value;
              if (pinHistory === "0") {
                setPinHistoryHelper("");
              } else {
                setPinHistoryHelper(pinHistory);
              }
              formik.setFieldValue("pinHistory", Number(pinHistory));
            }
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "maxInactivity",
            label: "Autolock after",
            options: MaxInactivity,
            value: formik.values.maxInactivity,
            onChange: (e) => {
              const maxInactivity = e == null ? void 0 : e.target.value;
              formik.setFieldValue("maxInactivity", maxInactivity);
            }
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center", marginTop: "20px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => formik.handleSubmit(), fullWidth: true, colorVariant: "primary", sizeVariant: "medium", children: "Save" }) })
    ] })
  ] });
};
