"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export const TaskAPI = {
  listTasks(assignedUserId, status) {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/tasks", {
        params: { assignedUserId, status }
      })).data.items;
    });
  },
  listTeamTasks() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/tasks/team")).data.items;
    });
  },
  getTaskStatuses() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/tasks/statuses")).data;
    });
  },
  createTask(task) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/tasks", task)).data;
    });
  },
  createTasksInBulk(task) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/tasks/bulk", task);
    });
  },
  updateTask(task) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/tasks/${task.id}`, task)).data;
    });
  },
  getTaskById(taskId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/tasks/${taskId}/by-id`)).data;
    });
  },
  updateTaskStatus(_0, _1, _2) {
    return __async(this, arguments, function* (taskId, assignedUserId, { status }) {
      return (yield axios.patch(`/apiv2/tasks/status/${assignedUserId}/${taskId}`, {
        status
      })).data;
    });
  },
  removeTask(id) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/tasks/${id}`);
    });
  },
  remindAllOverdue() {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/tasks/remind-all-overdue");
    });
  },
  removeTasks(taskIds, forOffboarding = false) {
    return __async(this, null, function* () {
      let query = "";
      if (forOffboarding) query = "?forOffboarding=true";
      return axios.post(`/apiv2/tasks/bulk-delete${query}`, taskIds);
    });
  },
  remindAboutTask(taskId) {
    return __async(this, null, function* () {
      return axios.post(`/apiv2/tasks/remind`, { taskId });
    });
  },
  exportTasks(taskIds) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/tasks/export`, { taskIds })) == null ? void 0 : _a.data;
    });
  },
  addChecklist(checklist) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/checklists", checklist)).data;
    });
  },
  getChecklists() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/checklists")).data;
    });
  },
  getChecklistById(id) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/checklists/${id}`)).data;
    });
  },
  updateChecklist(checklistId, checklist) {
    return __async(this, null, function* () {
      return axios.patch(`/apiv2/checklists/${checklistId}`, checklist);
    });
  },
  deleteChecklistById(id) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/checklists/${id}`);
    });
  },
  deleteChecklistItemById(checklistId, checklistItemId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/checklists/${checklistId}/checklist-items/${checklistItemId}`);
    });
  },
  addChecklistItemToChecklist(id, checklistItem) {
    return __async(this, null, function* () {
      return axios.post(`/apiv2/checklists/${id}`, checklistItem);
    });
  },
  copyChecklistItemToChecklist(id) {
    return __async(this, null, function* () {
      return axios.post(`/apiv2/checklists/${id}/duplicate`);
    });
  },
  updateChecklistItem(checklistId, checklistItemId, checklistItem) {
    return __async(this, null, function* () {
      return axios.patch(`/apiv2/checklists/${checklistId}/checklist-items/${checklistItemId}`, checklistItem);
    });
  },
  launchChecklistById(id, values) {
    return __async(this, null, function* () {
      const checklist = __spreadValues({}, values);
      yield axios.post(`/apiv2/checklists/${id}/launch`, checklist);
    });
  },
  getAlerts(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/tasks/${userId}/alerts`)).data;
    });
  }
};
export class TaskEndpoints {
  static listTasks(reachOrTaskAssignee, { pageIndex, pageSize }, options) {
    const urlParams = new URLSearchParams(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({
      pageIndex: `${pageIndex}`,
      pageSize: `${pageSize}`
    }, typeof reachOrTaskAssignee === "string" ? { reach: reachOrTaskAssignee } : { assignedUserId: `${reachOrTaskAssignee}` }), (options == null ? void 0 : options.sortField) && { sort: options.sortField.id, sortDesc: `${options.sortField.desc}` }), (options == null ? void 0 : options.status) && { status: options.status }), (options == null ? void 0 : options.search) && { search: options.search }), (options == null ? void 0 : options.filterString) && { filterString: options.filterString }), (options == null ? void 0 : options.forOffboarding) && { forOffboarding: "true" }));
    return {
      url: `/apiv2/tasks?${urlParams.toString()}`
    };
  }
  static getAlerts(userId) {
    return {
      url: `/apiv2/tasks/${userId}/alerts`
    };
  }
  static getChecklists() {
    return {
      url: "/apiv2/checklists"
    };
  }
  static getChecklistsOptions() {
    return {
      url: "/apiv2/checklists/options"
    };
  }
}
