"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { SuperAdminAllDevicesOverviewPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-all-devices-overview.page";
import { SuperAdminDeviceDetailsPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-details.page";
import { SuperAdminDeviceModelsPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-models.page";
import { SuperAdminDeviceOrdersPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-orders.page";
import { SuperAdminDeviceStockPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-devices-stock.page";
import { SuperAdminDevicesTransitsPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-devices-transits.page";
import { SuperAdminHexnodeDevicesOverviewPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-hexnode-devices-overview.page";
import { SuperAdminInHouseMdmDevicesOverviewPage } from "@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-in-house-mdm-devices-overview.page";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  SETTINGS_DEVICES_ROUTE,
  SUPER_ADMIN_ALL_DEVICES_OVERVIEW_ROUTE,
  SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE,
  SUPER_ADMIN_DEVICE_STOCK_DETAILS_ROUTE,
  SUPER_ADMIN_DEVICES_MODELS_ROUTE,
  SUPER_ADMIN_DEVICES_ORDERS_ROUTE,
  SUPER_ADMIN_DEVICES_ROUTE,
  SUPER_ADMIN_DEVICES_STOCK_ROUTE,
  SUPER_ADMIN_DEVICES_TRANSITS_ROUTE,
  SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE,
  SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE
} from "@/lib/routes";
const DEVICES_PAGE_CONFIG = {
  header: {
    settingsPath: SETTINGS_DEVICES_ROUTE,
    tabs: [
      { label: "Hexnode", value: SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE, scopes: ["devices"] },
      { label: "Zelt MDM", value: SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE, scopes: ["devices"] },
      { label: "All devices", value: SUPER_ADMIN_ALL_DEVICES_OVERVIEW_ROUTE, scopes: ["devices"] },
      { label: "Inventory", value: SUPER_ADMIN_DEVICES_STOCK_ROUTE, scopes: ["devices"] },
      { label: "Orders", value: SUPER_ADMIN_DEVICES_ORDERS_ROUTE, scopes: ["devices"] },
      { label: "Transits", value: SUPER_ADMIN_DEVICES_TRANSITS_ROUTE, scopes: ["devices"] },
      { label: "Shop", value: SUPER_ADMIN_DEVICES_MODELS_ROUTE, scopes: ["devices"] }
    ]
  }
};
export const SuperAdminDevicesRouter = ({ companies, users }) => {
  const activeCompanies = companies.filter((company) => company.isActive);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(
      SuperAdminInHouseMdmDevicesOverviewPage,
      {
        users,
        companies: activeCompanies,
        pageConfig: DEVICES_PAGE_CONFIG
      }
    ) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_DEVICES_MODELS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminDeviceModelsPage, { pageConfig: DEVICES_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_DEVICES_ORDERS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminDeviceOrdersPage, { users, companies, pageConfig: DEVICES_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_DEVICES_TRANSITS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminDevicesTransitsPage, { users, companies: activeCompanies, pageConfig: DEVICES_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: [SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE, SUPER_ADMIN_DEVICE_STOCK_DETAILS_ROUTE], children: /* @__PURE__ */ jsx(SuperAdminDeviceDetailsPage, {}) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_DEVICES_STOCK_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminDeviceStockPage, { pageConfig: DEVICES_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(
      SuperAdminHexnodeDevicesOverviewPage,
      {
        users,
        companies: activeCompanies,
        pageConfig: DEVICES_PAGE_CONFIG
      }
    ) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_ALL_DEVICES_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminAllDevicesOverviewPage, { users, companies: activeCompanies, pageConfig: DEVICES_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_DEVICES_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE }) })
  ] });
};
