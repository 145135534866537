"use strict";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
export var QuestionType = /* @__PURE__ */ ((QuestionType2) => {
  QuestionType2["SingleAnswer"] = "singleAnswer";
  QuestionType2["OpenEnded"] = "openEnded";
  QuestionType2["MultipleAnswer"] = "multipleAnswer";
  QuestionType2["ScaleQuestion"] = "scaleQuestion";
  return QuestionType2;
})(QuestionType || {});
export var QuestionTypeChoiceEnum = /* @__PURE__ */ ((QuestionTypeChoiceEnum2) => {
  QuestionTypeChoiceEnum2["SingleAnswer"] = "singleAnswer";
  QuestionTypeChoiceEnum2["SingleAnswerComment"] = "singleAnswer+comment";
  QuestionTypeChoiceEnum2["MultipleAnswer"] = "multipleAnswer";
  QuestionTypeChoiceEnum2["MultipleAnsweComment"] = "multipleAnswer+comment";
  QuestionTypeChoiceEnum2["ScaleQuestion"] = "scaleQuestion";
  QuestionTypeChoiceEnum2["ScaleQuestionComment"] = "scaleQuestion+comment";
  QuestionTypeChoiceEnum2["OpenEnded"] = "openEnded";
  return QuestionTypeChoiceEnum2;
})(QuestionTypeChoiceEnum || {});
export const QuestionTypeMap = {
  ["singleAnswer" /* SingleAnswer */]: "Single choice",
  ["multipleAnswer" /* MultipleAnswer */]: "Multiple choice",
  ["scaleQuestion" /* ScaleQuestion */]: "Scale question",
  ["openEnded" /* OpenEnded */]: "Comment"
};
export const SingleAnswerQuestion = (polyglot) => {
  return { label: "Single choice", value: "singleAnswer" /* SingleAnswer */ };
};
export const SingleAnswerPlusComment = (polyglot) => {
  return { label: "Single choice + Comment", value: "singleAnswer+comment" /* SingleAnswerComment */ };
};
export const MultipleAnswer = (polyglot) => {
  return { label: "Multiple choice", value: "multipleAnswer" /* MultipleAnswer */ };
};
export const MultipleChoicePlusComment = (polyglot) => {
  return { label: "Multiple choice + Comment", value: "multipleAnswer+comment" /* MultipleAnsweComment */ };
};
export const ScaleQuestion = (polyglot) => {
  return { label: "Scale", value: "scaleQuestion" /* ScaleQuestion */ };
};
export const ScaleQuestionPlusComment = (polyglot) => {
  return { label: "Scale + Comment", value: "scaleQuestion+comment" /* ScaleQuestionComment */ };
};
export const OpenEnded = (polyglot) => {
  return { label: "Comment", value: "openEnded" /* OpenEnded */ };
};
export const AnswerTypes = (polyglot) => {
  return [
    OpenEnded(polyglot),
    ScaleQuestion(polyglot),
    ScaleQuestionPlusComment(polyglot),
    SingleAnswerQuestion(polyglot),
    SingleAnswerPlusComment(polyglot),
    MultipleAnswer(polyglot),
    MultipleChoicePlusComment(polyglot)
  ];
};
export const ManagerReviewer = (polyglot) => {
  return { value: ReviewerTypes.Manager, label: polyglot.t("FormInterface.manager") };
};
export const SelfReviewer = (polyglot) => {
  return { value: ReviewerTypes.Self, label: polyglot.t("FormInterface.self") };
};
export const DirectReviewer = (polyglot) => {
  return { value: ReviewerTypes.Upward, label: polyglot.t("FormInterface.upward") };
};
export const PeerReviewer = (polyglot) => {
  return { value: ReviewerTypes.Peer, label: polyglot.t("FormInterface.peer") };
};
export const ReviewerTypesArray = (polyglot) => {
  return [ManagerReviewer(polyglot), SelfReviewer(polyglot), DirectReviewer(polyglot), PeerReviewer(polyglot)];
};
export const MainReviwerTypeArray = [ReviewerTypes.Manager, ReviewerTypes.Upward, ReviewerTypes.Peer];
export const SelfReviwerTypeArray = [ReviewerTypes.Self];
