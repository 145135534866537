"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TableEmptyState } from "@v2/components/empty-state.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { EmployeeMemberships } from "@v2/feature/benefits/subfeature/pension/components/employee-memberships.component";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { useUserProfileConfig } from "@v2/feature/user/features/user-profile/user-profile.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BENEFITS_COMPANY_ROUTE } from "@/lib/routes";
import { PensionEmptyState } from "@/v2/feature/benefits/subfeature/pension/components/pension-empty-state.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
export const PensionSummaryPage = ({
  pensionSchemes,
  loadingParent,
  userPensionState
}) => {
  const [state] = useContext(GlobalContext);
  const { pensionSchemeId } = useParams();
  const { userOverviewConfig, loading } = useUserProfileConfig(state.user.userId);
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(true);
  const [userPensions, setUserPensions] = userPensionState;
  const refreshMemberships = useCallback(() => __async(void 0, null, function* () {
    setIsLoading(true);
    if (!pensionSchemes) return setIsLoading(false);
    try {
      if (!userOverviewConfig.companyInPayroll) return;
      const memberships = yield PensionAPI.getEmployeeMemberships();
      setUserPensions(memberships);
    } catch (error) {
      showMessage(
        polyglot.t("PensionModule.ErrorMessages.couldNotReadEmployeesMemberships", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  }), [polyglot, showMessage, pensionSchemes, userOverviewConfig.companyInPayroll, setUserPensions]);
  useEffect(() => {
    refreshMemberships();
  }, [refreshMemberships]);
  useEffect(() => {
    trackPage("Pension company summary");
  }, [trackPage]);
  const pensionSummaryTitle = useMemo(() => {
    if (pensionSchemeId && pensionSchemes && pensionSchemes.length > 0) {
      const matchingPensionScheme = pensionSchemes.find((pensionScheme) => pensionScheme.id === +pensionSchemeId);
      return matchingPensionScheme ? matchingPensionScheme.displayName : void 0;
    }
  }, [pensionSchemes, pensionSchemeId]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: pensionSummaryTitle != null ? pensionSummaryTitle : polyglot.t("PensionModule.pension"),
        showBack: true,
        backPath: BENEFITS_COMPANY_ROUTE
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: loading || isLoading || loadingParent, children: !pensionSchemes || pensionSchemes.length === 0 ? /* @__PURE__ */ jsx(PensionEmptyState, {}) : /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        EmployeeMemberships,
        {
          userPensions,
          companyPensions: pensionSchemes,
          refreshMemberships
        }
      ),
      !userPensions || userPensions.length === 0 && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(TableEmptyState, { title: polyglot.t("PensionModule.noEmployeesInPayroll") }) })
    ] }) })
  ] });
};
