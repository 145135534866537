"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const OverflowSx = {
  maxWidth: "70px",
  textAlign: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden"
};
export const AvatarCardWidth = 110;
export const AvatarCard = ({
  title,
  titleSx = __spreadValues(__spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), OverflowSx),
  caption,
  captionSx = __spreadValues(__spreadProps(__spreadValues({}, themeFonts.captionSmall), { color: themeColors.Grey }), OverflowSx),
  onClick,
  subTitle,
  subTitleSx = __spreadValues(__spreadProps(__spreadValues({}, themeFonts.captionSmall), { color: themeColors.Grey }), OverflowSx),
  size = "medium",
  userId,
  freeWidth,
  publicURL
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        bgcolor: themeColors.Background,
        overflow: "hidden",
        py: spacing.p15,
        borderRadius: radius.br15,
        width: freeWidth ? "auto" : `${AvatarCardWidth}px`,
        cursor: onClick && "pointer",
        minHeight: "130px"
      },
      onClick,
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(UserAvatar, { userId, size, publicURL }) }),
        title && /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, titleSx), {
              marginTop: spacing.m10
            }),
            children: title
          }
        ) }),
        subTitle && /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadValues({}, subTitleSx),
            children: subTitle
          }
        ) }),
        caption && /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadValues({}, captionSx),
            children: caption
          }
        ) })
      ]
    }
  );
};
