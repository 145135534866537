"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { NewBenefitDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component";
import {
  CustomBenefitType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { SETTINGS_BENEFITS_DETAILS_ROUTE } from "@/lib/routes";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { getDefaultBenefitImageByCategory } from "@/v2/feature/benefits/benefits.util";
import { InsuranceType } from "@/v2/feature/benefits/subfeature/insurance/insurance.interface";
import { getLogoByProviderName } from "@/v2/feature/benefits/subfeature/insurance/insurance.util";
import { getPensionLogoByProviderName } from "@/v2/feature/benefits/subfeature/pension/pension.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const BenefitsSettingsListPage = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshCustomBenefits,
  loading
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();
  const rows = useMemo(() => {
    var _a, _b;
    const data = [];
    if (pensionSchemes) {
      data.push(
        ...pensionSchemes.map(
          (pS) => ({
            id: pS.id,
            name: pS.displayName,
            category: "Pension",
            type: "Recurring",
            benefitType: "pension",
            productType: "standard",
            url: null
          })
        )
      );
    }
    if (insuranceQuote) {
      data.push({
        id: insuranceQuote.id,
        name: (_b = (_a = insuranceQuote.policy) == null ? void 0 : _a.providerName) != null ? _b : insuranceQuote.type,
        category: InsuranceType.Health,
        type: CustomBenefitType.Recurring,
        status: insuranceQuote.status,
        benefitType: "insurance",
        productType: "standard",
        url: null
      });
    }
    if (customBenefits) {
      data.push(
        ...customBenefits.map(
          (cB) => ({
            id: cB.id,
            name: cB.name,
            category: cB.category,
            type: cB.type,
            benefitType: "custom-benefit",
            productType: "custom",
            url: cB.url || null
          })
        )
      );
    }
    if (!searchInput) return data;
    const search = searchInput.toLowerCase();
    return data.filter((r) => r.name.toLowerCase().includes(search));
  }, [insuranceQuote, pensionSchemes, customBenefits, searchInput]);
  const renderBenefitCard = (item) => {
    const image = item.benefitType === "insurance" ? getLogoByProviderName(item.name, 40) : item.benefitType === "custom-benefit" ? item.url ? /* @__PURE__ */ jsx("img", { src: item.url, width: 40, height: 40, alt: "benefit-logo" }) : getDefaultBenefitImageByCategory(item.category, "50px", "auto") : item.benefitType === "pension" ? getPensionLogoByProviderName(item.name, 40) : /* @__PURE__ */ jsx(Fragment, {});
    return /* @__PURE__ */ jsx(
      SettingsItemCard,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
          /* @__PURE__ */ jsx(Box, { children: image }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: item.name })
        ] }),
        boxSx: responsiveCardStyle,
        boxAction: () => {
          history.push(
            generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
              productType: item.productType,
              category: item.category,
              id: item.id
            })
          );
        },
        contentItemsSets: [
          {
            name: item.type,
            type: ContentItemType.chip,
            textColor: "DarkGrey",
            backgroundColor: "white",
            border: "middle"
          },
          {
            name: item.category,
            type: ContentItemType.chip,
            textColor: "DarkGrey",
            backgroundColor: "white",
            border: "middle"
          }
        ]
      },
      item.id
    );
  };
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("BenefitModule.benefits"),
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsOpen(true), children: polyglot.t("BenefitModule.newBenefit") })
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            loadingAll: loading,
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          setSearchInput(e.target.value);
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: rows.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: rows.map(renderBenefitCard) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("BenefitModule.benefitsNotFound") })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(NewBenefitDrawer, { isOpen, setIsOpen, refresh: refreshCustomBenefits })
  ] });
};
