"use strict";
import { generatePath } from "react-router-dom";
import { SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE } from "@/lib/routes";
export function goToEditTemplate(routerHistory, templateId, mode) {
  const path = generatePath(SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE, { templateId });
  const state = {
    mode
  };
  routerHistory.push(path, state);
}
export const filterByLifecycle = (users, validValues) => {
  return users.filter((user) => {
    var _a;
    return ((_a = user.userEvent) == null ? void 0 : _a.status) && validValues.includes(user.userEvent.status);
  });
};
