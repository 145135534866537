"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ONBOARDING_ROUTE } from "@/lib/routes";
import { DeviceSelectPage } from "@/v2/feature/onboarding/onboarding-device/device-select.page";
import { DeviceShippingPage } from "@/v2/feature/onboarding/onboarding-device/device-shipping.page";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
export const OnboardingDeviceInventoryPage = () => {
  const [page, setPage] = useState("device-select");
  const [selectedDevice, setSelectedDevice] = useState();
  const { getCachedUserById } = useCachedUsers();
  const params = useParams();
  const userId = Number(params.userId);
  const user = userId && getCachedUserById(userId);
  const routerHistory = useHistory();
  const returnToOnboardingStatus = useCallback(() => {
    routerHistory.goBack();
  }, [routerHistory]);
  if (!user) {
    routerHistory.push(ONBOARDING_ROUTE);
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    page === "device-select" && /* @__PURE__ */ jsx(
      DeviceSelectPage,
      {
        selectedDevice,
        onContinueClick: (device) => {
          setSelectedDevice(device);
          setPage("shipping");
        },
        onCloseClick: returnToOnboardingStatus
      }
    ),
    page === "shipping" && selectedDevice && /* @__PURE__ */ jsx(
      DeviceShippingPage,
      {
        devicePossession: selectedDevice,
        user,
        onBackClick: () => setPage("device-select"),
        onCloseClick: returnToOnboardingStatus,
        onFinish: returnToOnboardingStatus
      }
    )
  ] });
};
