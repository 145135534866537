"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Switch } from "react-router-dom";
import { SurveyQuestionBankPage } from "./features/survey-question-bank/survey-question-bank.page";
import { SurveyTemplateOverviewPage } from "./features/survey-template/survey-template-builder/survey-template-overview.page";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  SETTINGS_GROWTH_SURVEYS_QUESTIONS_ROUTE,
  SETTINGS_GROWTH_SURVEYS_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE
} from "@/lib/routes";
import { SurveyTemplateListPage } from "@/v2/feature/growth/surveys/features/survey-template/survey-template-list/survey-template-list.page";
export const SurveySettingsRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(RouteScopesControl, { context, scopes: ["surveys:all"], path: SETTINGS_GROWTH_SURVEYS_ROUTE, children: /* @__PURE__ */ jsx(SurveySettingRouter, { context }) }) });
};
const SurveySettingRouter = ({ context }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["surveys:all"],
        path: SETTINGS_GROWTH_SURVEYS_QUESTIONS_ROUTE,
        exact: true,
        children: /* @__PURE__ */ jsx(SurveyQuestionBankPage, {})
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { context, scopes: ["surveys:all"], path: SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE, children: /* @__PURE__ */ jsx(SurveyTemplateOverviewPage, {}) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["surveys:all"],
        path: SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE,
        exact: true,
        children: /* @__PURE__ */ jsx(SurveyTemplateListPage, {})
      }
    )
  ] });
};
