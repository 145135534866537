"use strict";
import {
  AbsenceLengthUnit,
  AccrualCliff,
  HolidaysProratingRule,
  TimeUnit
} from "@v2/feature/absence/absence.interface";
import {
  convertMinutesToClockHours,
  convertMinutesToWorkDays,
  isHourlyPolicy,
  isUnlimitedPolicy
} from "@v2/feature/absence/absence.util";
import { LocalDate } from "@v2/util/local-date";
import { round2Digits } from "@v2/util/number.util";
export const getAnnualBalanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  if (!breakdown || breakdown.userAllowance === null) return "Unknown";
  if (isUnlimitedPolicy(absencePolicy)) return polyglot.t("AllowanceDrawer.unlimited");
  const isHourly = isHourlyPolicy(absencePolicy);
  if (isHourly) return polyglot.t("AllowanceDrawer.noOfHours", { smart_count: breakdown.userAllowance });
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: breakdown.userAllowance });
};
export const getCurrentBalanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!breakdown || breakdown.currentBalance === null || breakdown.currentBalanceInDays === null)
    return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(breakdown.currentBalance, polyglot);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: breakdown.currentBalanceInDays });
};
export const getProratedAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  var _a;
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!breakdown || breakdown.proRatedAbsenceAllowance.proRatedAbsenceAllowance === null)
    return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const proratedAllowance = (_a = breakdown.proRatedAbsenceAllowance.proRatedAbsenceAllowance) != null ? _a : 0;
  const sign = includeSign && proratedAllowance > 0 ? "+" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(proratedAllowance, polyglot)}`;
  const allowanceInDays = round2Digits(proratedAllowance / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: allowanceInDays != null ? allowanceInDays : 0 });
};
export const getUsedFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  var _a;
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const usedTotal = breakdown.unitsTaken.history + ((_a = breakdown.unitsTaken.publicHolidaysHistory) != null ? _a : 0);
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotal, polyglot);
  const usedInDays = round2Digits(usedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: usedInDays != null ? usedInDays : 0 });
};
export const getUsedFromAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const usedTotal = breakdown.unitsTaken.history;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotal, polyglot);
  const usedInDays = round2Digits(usedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: usedInDays != null ? usedInDays : 0 });
};
export const getPendingUsedFromAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  var _a;
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const usedTotalPending = (_a = breakdown.unitsTaken.historyPending) != null ? _a : 0;
  if (usedTotalPending === 0) return null;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotalPending, polyglot);
  const usedInDays = round2Digits(usedTotalPending / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: usedInDays != null ? usedInDays : 0 });
};
export const getUsedFromPHAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  var _a;
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const usedTotal = (_a = breakdown.unitsTaken.publicHolidaysHistory) != null ? _a : 0;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotal, polyglot);
  const usedInDays = round2Digits(usedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: usedInDays != null ? usedInDays : 0 });
};
export const getBookedFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  var _a;
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const bookedTotal = breakdown.unitsTaken.upcoming + ((_a = breakdown.unitsTaken.publicHolidaysUpcoming) != null ? _a : 0);
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(bookedTotal, polyglot);
  const upcomingInDays = round2Digits(bookedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: upcomingInDays != null ? upcomingInDays : 0 });
};
export const getPendingBookedFromAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  var _a;
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const usedTotalPending = (_a = breakdown.unitsTaken.upcomingPending) != null ? _a : 0;
  if (usedTotalPending === 0) return null;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(usedTotalPending, polyglot);
  const usedInDays = round2Digits(usedTotalPending / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: usedInDays != null ? usedInDays : 0 });
};
export const getBookedFromAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const bookedTotal = breakdown.unitsTaken.upcoming;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(bookedTotal, polyglot);
  const usedInDays = round2Digits(bookedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: usedInDays != null ? usedInDays : 0 });
};
export const getBookedFromPHAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot) => {
  var _a;
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const isHourly = isHourlyPolicy(absencePolicy);
  const bookedTotal = (_a = breakdown.unitsTaken.publicHolidaysUpcoming) != null ? _a : 0;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(bookedTotal, polyglot);
  const usedInDays = round2Digits(bookedTotal / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: usedInDays != null ? usedInDays : 0 });
};
export const getPublicHolidayAllowanceFromBreakdown = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  var _a;
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.publicHolidayOnTop) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance === null)
    return polyglot.t("AllowanceDrawer.unknown");
  const sign = includeSign && breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance > 0 ? "+" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(
      breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance,
      polyglot
    )}`;
  const phAllowanceInDays = round2Digits(
    ((_a = breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance) != null ? _a : 0) / breakdown.currentAverageWorkDayLength
  );
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: phAllowanceInDays != null ? phAllowanceInDays : 0 });
};
export const getAccrualRoundingAdjustment = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!(breakdown == null ? void 0 : breakdown.proRatedAbsenceAllowance)) return polyglot.t("AllowanceDrawer.unknown");
  if (breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceRounded === null || breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceNonRounded === null)
    return null;
  const roundingValue = breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceRounded - breakdown.proRatedAbsenceAllowance.totalAccruedFromAllowanceNonRounded;
  if (!roundingValue) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  const sign = includeSign && roundingValue > 0 ? "+" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(roundingValue, polyglot)}`;
  const roundingInDays = round2Digits(roundingValue / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: roundingInDays != null ? roundingInDays : 0 });
};
export const getAllowanceFromBreakdown = (absencePolicy, breakdown, timeUnit, polyglot) => {
  if (isUnlimitedPolicy(absencePolicy) || !breakdown || breakdown.userAllowance === null) return null;
  const { lengthUnit, userAllowance, currentAverageWorkDayLength, isOnRegularSchedule } = breakdown;
  if (!currentAverageWorkDayLength || !isOnRegularSchedule) {
    if (lengthUnit === AbsenceLengthUnit.Minute) convertMinutesToClockHours(userAllowance, polyglot);
    return polyglot.t("General.noOfDays", { smart_count: userAllowance });
  }
  const convertAllowance = (allowance, fromUnit, toUnit) => {
    var _a;
    if (fromUnit === AbsenceLengthUnit.Day && toUnit === TimeUnit.Hours) {
      const allowanceInMinutes = allowance * currentAverageWorkDayLength;
      return convertMinutesToClockHours(allowanceInMinutes, polyglot);
    }
    if (fromUnit === AbsenceLengthUnit.Minute && toUnit === TimeUnit.Hours) {
      return convertMinutesToClockHours(allowance, polyglot);
    }
    if (fromUnit === AbsenceLengthUnit.Minute && toUnit === TimeUnit.Days) {
      return convertMinutesToWorkDays(allowance, currentAverageWorkDayLength, polyglot);
    }
    return polyglot.t("General.noOfDays", { smart_count: (_a = round2Digits(allowance)) != null ? _a : 0 });
  };
  return convertAllowance(userAllowance, lengthUnit, timeUnit);
};
export const getConvertedValue = (valueInMinutes, breakdown, timeUnit, polyglot, includeSign = false) => {
  if (!breakdown || valueInMinutes === null) return polyglot.t("AllowanceDrawer.unknown");
  const sign = includeSign && valueInMinutes > 0 ? "+" : "";
  if (timeUnit === TimeUnit.Hours || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength) {
    return `${sign}${convertMinutesToClockHours(valueInMinutes, polyglot)}`;
  }
  const valueInDays = convertMinutesToWorkDays(valueInMinutes, breakdown.currentAverageWorkDayLength, polyglot, sign);
  return valueInDays;
};
export const getOneOffAdjustmentFromBreakdown = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.oneOffAdjustment === null) return polyglot.t("AllowanceDrawer.unknown");
  const sign = includeSign && breakdown.oneOffAdjustment > 0 ? "+" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.oneOffAdjustment, polyglot)}`;
  const oneOffAdjustmentInDays = round2Digits(breakdown.oneOffAdjustment / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: oneOffAdjustmentInDays != null ? oneOffAdjustmentInDays : 0 });
};
export const getCarryOverFromBreakdown = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled)
    return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign: "", smart_count: 0 });
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.carryOver.allowedUnitsThisCycle === null) return polyglot.t("AllowanceDrawer.unknown");
  const sign = includeSign && breakdown.carryOver.allowedUnitsThisCycle > 0 ? "+" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.allowedUnitsThisCycle, polyglot)}`;
  const carryOverInDays = round2Digits(
    breakdown.carryOver.allowedUnitsThisCycle / breakdown.currentAverageWorkDayLength
  );
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: carryOverInDays != null ? carryOverInDays : 0 });
};
export const getTOILValueFromBreakdown = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.toilTypeId) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown) return polyglot.t("AllowanceDrawer.unknown");
  const toilValueInMinutes = breakdown.toil.toilValueInMinutes;
  const sign = includeSign && toilValueInMinutes > 0 ? "+" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(toilValueInMinutes, polyglot)}`;
  const toilValueInDays = round2Digits(toilValueInMinutes / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: toilValueInDays != null ? toilValueInDays : 0 });
};
export const getTotalAllowanceBreakdown = (breakdown, polyglot) => {
  if (!breakdown || breakdown.totalAllowanceForCycle === null) return polyglot.t("AllowanceDrawer.unknown");
  if (isUnlimitedPolicy(breakdown)) return null;
  const isHourly = isHourlyPolicy(breakdown);
  const totalAllowance = breakdown.totalAllowanceForCycle;
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(totalAllowance, polyglot);
  const totalProratedAllowance = round2Digits(totalAllowance / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: totalProratedAllowance != null ? totalProratedAllowance : 0 });
};
export const getAccrualBreakdownValue = (absencePolicy, breakdown, variant, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!absencePolicy.accrualUnit || !absencePolicy.accrualUnitsNo) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || variant === "total" && breakdown.holidayAccrued === null || variant === "regular" && breakdown.proRatedAbsenceAllowance.totalRegularAbsenceAccrued === null || variant === "publicHolidays" && breakdown.proRatedAbsenceAllowance.totalPublicHolidaysAccrued === null)
    return polyglot.t("AllowanceDrawer.unknown");
  const valueInMinutes = variant === "total" ? breakdown.holidayAccrued : variant === "regular" ? breakdown.proRatedAbsenceAllowance.totalRegularAbsenceAccrued : breakdown.proRatedAbsenceAllowance.totalPublicHolidaysAccrued;
  const sign = includeSign && valueInMinutes !== null && valueInMinutes > 0 ? "+" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(valueInMinutes, polyglot)}`;
  const totalAccruedInDays = round2Digits((valueInMinutes != null ? valueInMinutes : 0) / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: totalAccruedInDays != null ? totalAccruedInDays : 0 });
};
export const getAccrualRemainingValue = (absencePolicy, breakdown, polyglot) => {
  var _a;
  if (isUnlimitedPolicy(absencePolicy)) return null;
  if (!absencePolicy.accrualUnit || !absencePolicy.accrualUnitsNo) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.proRatedAbsenceAllowance.totalAccruedWithoutPHAccrualUsedOrBooked === null)
    return polyglot.t("AllowanceDrawer.unknown");
  const valueInMinutes = breakdown.proRatedAbsenceAllowance.totalAccruedWithoutPHAccrualUsedOrBooked - ((_a = breakdown.unitsTaken.history) != null ? _a : 0);
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(valueInMinutes, polyglot);
  const totalAccruedInDays = round2Digits((valueInMinutes != null ? valueInMinutes : 0) / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: totalAccruedInDays != null ? totalAccruedInDays : 0 });
};
export const getExpiredCarryOver = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.carryOver.allowedUnitsThisCycle === null || breakdown.carryOver.expiredCarryOver === null)
    return null;
  if (!breakdown.carryOver.isCarryOverExpired) return null;
  const sign = includeSign && breakdown.carryOver.expiredCarryOver > 0 ? "-" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.expiredCarryOver, polyglot)}`;
  const carryOverInDays = round2Digits(breakdown.carryOver.expiredCarryOver / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: carryOverInDays != null ? carryOverInDays : 0 });
};
export const getExpiredUnusedCarryOver = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.carryOver.unusedUnitsLastCycle === null || breakdown.carryOver.allowedUnitsThisCycle === null)
    return null;
  if (!breakdown.carryOver.unusedUnitsLastCycle) return null;
  const sign = includeSign && breakdown.carryOver.unusedUnitsLastCycle > 0 ? "-" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.unusedUnitsLastCycle, polyglot)}`;
  const carryOverInDays = round2Digits(
    breakdown.carryOver.unusedUnitsLastCycle / breakdown.currentAverageWorkDayLength
  );
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: carryOverInDays != null ? carryOverInDays : 0 });
};
export const getUsedCarryOver = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.carryOver.unusedUnitsLastCycle === null || breakdown.carryOver.used === null) return null;
  if (!breakdown.carryOver.used) return null;
  const sign = includeSign && breakdown.carryOver.used > 0 ? "-" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.used, polyglot)}`;
  const carryOverInDays = round2Digits(breakdown.carryOver.used / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: carryOverInDays != null ? carryOverInDays : 0 });
};
export const getRemainingCarryOver = (absencePolicy, breakdown, polyglot, includeSign = false) => {
  if (isUnlimitedPolicy(absencePolicy) || !absencePolicy.carryOverEnabled) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (!breakdown || breakdown.carryOver.unusedUnitsLastCycle === null || breakdown.carryOver.remaining === null)
    return null;
  if (!breakdown.carryOver.remaining) return null;
  const sign = includeSign && breakdown.carryOver.remaining > 0 ? "-" : "";
  if (isHourly || !breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength)
    return `${sign}${convertMinutesToClockHours(breakdown.carryOver.remaining, polyglot)}`;
  const carryOverInDays = round2Digits(breakdown.carryOver.remaining / breakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: carryOverInDays != null ? carryOverInDays : 0 });
};
export const getCarryOverExpiryOn = (absencePolicy, breakdown, polyglot) => {
  if (!breakdown || !absencePolicy.carryOverEnabled || !absencePolicy.carryOverExpirationDay || !absencePolicy.carryOverExpirationDay || !breakdown.carryOver.expiryDate)
    return null;
  const expiryDate = breakdown.carryOver.expiryDate;
  const dateString = new LocalDate(expiryDate).toLocaleDateString(void 0, { month: "short", day: "2-digit" });
  return polyglot.t("AllowanceDrawer.expiryOnDate", { date: dateString });
};
export const getHolidaysProratingRuleShort = (absencePolicy, polyglot) => {
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated)
    return polyglot.t("AllowanceDrawer.phRuleAllocateAllInPeriodProrated");
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated)
    return polyglot.t("AllowanceDrawer.phRuleAllocateFullValueProrated");
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriod)
    return polyglot.t("AllowanceDrawer.phRuleAllocateAllInPeriod");
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInCycle)
    return polyglot.t("AllowanceDrawer.phRuleAllocateAllInCycle");
  return polyglot.t("AllowanceDrawer.unknown");
};
export const getHolidaysProratingRuleLong = (absencePolicy, polyglot) => {
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated)
    return polyglot.t("AllowanceDrawer.phRuleAllocateAllInPeriodProratedLong");
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated)
    return polyglot.t("AllowanceDrawer.phRuleAllocateFullValueProratedLong");
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriod)
    return polyglot.t("AllowanceDrawer.phRuleAllocateAllInPeriodLong");
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInCycle)
    return polyglot.t("AllowanceDrawer.phRuleAllocateAllInCycleLong");
  return polyglot.t("AllowanceDrawer.unknown");
};
export const getAllowanceProratingRuleShortDesc = (absencePolicy, polyglot) => {
  if (absencePolicy.proratingStartDate && absencePolicy.proratingFte)
    return polyglot.t("AllowanceDrawer.phRuleAllocateFullValueProrated");
  if (absencePolicy.proratingStartDate && !absencePolicy.proratingFte)
    return polyglot.t("AllowanceDrawer.allowanceProratingRuleStartDateShort");
  return polyglot.t("AllowanceDrawer.noProration");
};
export const getAllowanceProratingRuleLongDesc = (absencePolicy, polyglot) => {
  if (absencePolicy.proratingStartDate && absencePolicy.proratingFte)
    return polyglot.t("AllowanceDrawer.allowanceProratingRuleFTELong");
  if (absencePolicy.proratingStartDate && !absencePolicy.proratingFte)
    return polyglot.t("AllowanceDrawer.allowanceProratingRuleStartDateLong");
  return polyglot.t("AllowanceDrawer.noProration");
};
export const isoDateToLocale = (date) => {
  return new LocalDate(date).toLocaleDateString(void 0, {
    month: "short",
    day: "numeric",
    year: "numeric"
  });
};
export const getRoundingAdjustment = (absencePolicy, policyBreakdown, polyglot) => {
  var _a, _b;
  if (!policyBreakdown) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  const difference = ((_a = policyBreakdown.proRatedAbsenceAllowance.totalProratedAllowance) != null ? _a : 0) - ((_b = policyBreakdown.proRatedAbsenceAllowance.totalProratedAllowanceNonRounded) != null ? _b : 0);
  if (difference === 0) return null;
  if (isHourly || !policyBreakdown.isOnRegularSchedule || !policyBreakdown.currentAverageWorkDayLength)
    return convertMinutesToClockHours(difference, polyglot);
  const differenceInDays = round2Digits(difference / policyBreakdown.currentAverageWorkDayLength);
  return polyglot.t("AllowanceDrawer.noOfDays", { smart_count: differenceInDays != null ? differenceInDays : 0 });
};
export const getAccrualCliffReason = (breakdown, polyglot) => {
  var _a;
  if (!((_a = breakdown == null ? void 0 : breakdown.proRatedAbsenceAllowance) == null ? void 0 : _a.accrualCliff) || !breakdown.proRatedAbsenceAllowance.didNotPassAccrualCliff)
    return null;
  const accrualCliff = breakdown.proRatedAbsenceAllowance.accrualCliff;
  if (accrualCliff === AccrualCliff.Probation) return polyglot.t("AllowanceDrawer.accrualStartsAfterProbation");
  return "";
};
