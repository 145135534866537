"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledEmojiPicker } from "@/v2/components/theme-components/styled-emoji-picker.component";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { UpgradeToProModal, PlanNames } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { spacing } from "@/v2/styles/spacing.styles";
const ChecklistSchema = Yup.object().shape({
  bgImg: Yup.string().optional().nullable(),
  name: Yup.string().nullable().notRequired()
});
const defaultBlankModalValues = {
  bgImg: void 0,
  name: null
};
export const ChecklistFormDrawer = ({ checklist, onClose }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const routerHistory = useHistory();
  const initialValues = {
    bgImg: (_a = checklist == null ? void 0 : checklist.bgImg) != null ? _a : defaultBlankModalValues.bgImg,
    name: (_b = checklist == null ? void 0 : checklist.name) != null ? _b : defaultBlankModalValues.name
  };
  const addChecklist = (values) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const newChecklist = yield TaskAPI.addChecklist(values);
      routerHistory.push(generatePath(SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, { checklistId: newChecklist.id }), {
        openNewTaskModal: true
      });
      onClose();
      showMessage("Checklist successfully added.", "success");
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(`Checklist could not be created. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setLoading(false);
    }
  });
  const updateChecklist = (id, values) => __async(void 0, null, function* () {
    setLoading(true);
    yield TaskAPI.updateChecklist(id, values);
    onClose();
    try {
      showMessage("Checklist successfully update.", "success");
    } catch (error) {
      showMessage(`Checklist could not be update. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ChecklistSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      if (checklist == null ? void 0 : checklist.id) yield updateChecklist(checklist.id, values);
      else yield addChecklist(values);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (checklist == null ? void 0 : checklist.id) ? polyglot.t("ChecklistsPage.edit") : polyglot.t("ChecklistsPage.new") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "20%" }, children: /* @__PURE__ */ jsx(
        StyledEmojiPicker,
        {
          name: "bgImg",
          label: "Mark",
          value: formik.values.bgImg,
          onChange: (emoji) => {
            formik.setFieldValue("bgImg", emoji.emoji);
          }
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "80%" }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: "Name",
          value: formik.values.name,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.name && !!formik.errors.name,
          helperText: (_c = formik.touched.name && formik.errors.name) != null ? _c : " ",
          clearText: () => formik.setFieldValue("name", "")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      (checklist == null ? void 0 : checklist.id) && /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: "Cancel" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: (checklist == null ? void 0 : checklist.id) ? polyglot.t("General.update") : polyglot.t("General.add"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) });
};
