"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Button, Typography, Stack } from "@mui/material";
import { ReactComponent as Warning } from "@/images/side-bar-icons/MistakeOrange.svg";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { secondarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const PayrollHMRCConnectState = ({ onConnectClick, sx }) => {
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { children: "HMRC connection" }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Warning, __spreadValues({}, actionIconSize)),
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "No HMRC credentials set up" })
    ] }),
    /* @__PURE__ */ jsx(Button, { sx: __spreadProps(__spreadValues({}, secondarySmallBtn), { mt: spacing.mt20, width: "140px" }), onClick: () => onConnectClick(), children: "Connect HMRC" })
  ] });
};
