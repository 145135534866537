"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionType, DeviceType } from "@v2/feature/device/device.interface";
import {
  DeviceOSValueLabelOptions,
  DeviceTypesValueLabelOptions,
  MDMABLE_DEVICE_TYPES,
  processMdmServerName
} from "@v2/feature/device/device.util";
import { useDepAccountDetails } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { SiteAPI } from "@/api-client/site.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_ROUTE } from "@/lib/routes";
export const AddExistingDeviceDrawer = ({ isOpen, setIsOpen, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(AddExistingDeviceDrawerContent, { refresh }) });
};
const AddExistingDeviceDrawerContent = ({ refresh }) => {
  var _a, _b, _c, _d;
  const [loading, setLoading] = useState(false);
  const [sitesOptions, setSitesOptions] = useState([]);
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const location = useLocation();
  const isAdmin = location.pathname.includes(DEVICES_COMPANY_ROUTE) && hasScopes(["devices:all"], scopesContext);
  const [globalState] = useContext(GlobalContext);
  const { depAccountDetails } = useDepAccountDetails(
    globalState.user.company.companyId + globalState.user.company.name
  );
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      assignToEmployee: true,
      userId: isAdmin ? null : state.user.userId,
      siteId: null,
      os: "",
      modelName: "",
      deviceName: "",
      serialNumber: null,
      isDep: false,
      type: DeviceType.Laptop,
      depDeviceUsername: ""
    },
    validationSchema: yup.object({
      assignToEmployee: yup.boolean().required(),
      userId: yup.number().when("assignToEmployee", {
        is: true,
        then: (schema) => schema.typeError("Employee should be selected").required("Employee should be selected"),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
      siteId: yup.number().when("assignToEmployee", {
        is: false,
        then: (schema) => schema.typeError("A site should be selected").required("A site should be selected"),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
      type: yup.string().required("Type is required"),
      os: yup.string().nullable().notRequired(),
      modelName: yup.string().required("Model Name is required"),
      deviceName: yup.string().nullable().notRequired(),
      serialNumber: yup.string().nullable().notRequired(),
      isDep: yup.boolean().nullable().notRequired(),
      depDeviceUsername: yup.string().nullable().notRequired()
    }),
    onSubmit: (formData) => __async(void 0, null, function* () {
      let isDeviceAdded = false;
      let isDepCorrect = false;
      if (formData.assignToEmployee && !formData.userId || !formData.assignToEmployee && !formData.siteId) {
        showMessage("A user or a site should be selected.", "error");
        return;
      }
      try {
        setLoading(true);
        if (formData.assignToEmployee && !isAdmin) {
          const pattern = /\/users\/(\d+)\/devices/;
          const userID = location.pathname.match(pattern);
          if (userID && userID[1]) {
            formData.userId = Number(userID[1]);
          }
        }
        if (formData.isDep && !formData.serialNumber && !formData.depDeviceUsername) {
          throw new Error();
        }
        isDepCorrect = true;
        const existingDeviceData = {
          possessionType: formData.assignToEmployee ? DevicePossessionType.User : DevicePossessionType.CompanySite,
          possessionId: formData.assignToEmployee ? formData.userId : formData.siteId,
          modelName: formData.modelName,
          deviceName: formData.deviceName,
          serialNumber: formData.serialNumber,
          os: formData.os,
          type: formData.type,
          mdmServerName: formData.isDep ? processMdmServerName(globalState.user.company.companyId + globalState.user.company.name) : null,
          depDeviceUsername: formData.isDep ? formData.depDeviceUsername : null
        };
        yield DeviceAPI.addExistingDevice(existingDeviceData);
        showMessage("Device added successfully.", "success");
        isDeviceAdded = true;
        yield refresh();
      } catch (error) {
        if (!isDepCorrect) {
          showMessage(
            "The device has to have the correct serial number or device username to enroll it by auto enrolment",
            "error"
          );
        }
        if (isDeviceAdded) {
          showMessage("Something went wrong. Please refresh the page.", "error");
        } else {
          showMessage(`Could not add device. ${nestErrorMessage(error)}`, "error");
        }
        setLoading(false);
      }
    })
  });
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            var _a2;
            return { value: site.id, label: site.name, address: (_a2 = site.address) != null ? _a2 : "" };
          })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Add existing device" }),
    isAdmin && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "assignToEmployee",
        label: "Device in use by an employee",
        checked: formik.values.assignToEmployee,
        onChange: formik.handleChange
      }
    ),
    formik.values.assignToEmployee && isAdmin && /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: "company",
        value: formik.values.userId,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("userId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        label: "Employee",
        error: Boolean(formik.errors.userId),
        helperText: formik.errors.userId
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: DeviceTypesValueLabelOptions,
        value: (_a = formik.values.type) != null ? _a : void 0,
        compareValue: (_b = formik.values.type) != null ? _b : void 0,
        onChange: formik.handleChange,
        error: formik.touched.type && !!formik.errors.type,
        helperText: formik.touched.type && formik.errors.type
      }
    ),
    !formik.values.assignToEmployee && isAdmin && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "siteId",
        label: "Site",
        options: sitesOptions,
        value: formik.values.siteId,
        compareValue: formik.values.siteId,
        error: !!formik.errors.siteId && formik.touched.siteId,
        helperText: formik.touched.siteId && formik.errors.siteId,
        onChange: (e) => __async(void 0, null, function* () {
          var _a2, _b2;
          yield formik.handleChange(e);
          const siteAddress = (_b2 = (_a2 = sitesOptions.find((s) => s.value === e.target.value)) == null ? void 0 : _a2.address) != null ? _b2 : "";
          yield formik.setFieldValue("receiverAddress", siteAddress);
        })
      }
    ),
    MDMABLE_DEVICE_TYPES.includes(formik.values.type) && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "os",
        label: "Platform",
        options: DeviceOSValueLabelOptions,
        value: (_c = formik.values.os) != null ? _c : void 0,
        compareValue: (_d = formik.values.os) != null ? _d : void 0,
        error: formik.touched.os && !!formik.errors.os,
        onChange: formik.handleChange,
        helperText: formik.touched.os && formik.errors.os
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Model Name",
        name: "modelName",
        value: formik.values.modelName,
        onChange: formik.handleChange,
        error: formik.touched.modelName && Boolean(formik.errors.modelName),
        helperText: formik.touched.modelName && formik.errors.modelName,
        size: "small",
        endAdornment: "none",
        placeholder: "MacBook Air"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Device Name",
        name: "deviceName",
        value: formik.values.deviceName,
        onChange: formik.handleChange,
        error: formik.touched.deviceName && Boolean(formik.errors.deviceName),
        helperText: formik.touched.deviceName && formik.errors.deviceName,
        size: "small",
        endAdornment: "none",
        placeholder: "My device "
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Serial Number",
        name: "serialNumber",
        value: formik.values.serialNumber,
        onChange: formik.handleChange,
        error: formik.touched.serialNumber && Boolean(formik.errors.serialNumber),
        helperText: formik.touched.serialNumber && formik.errors.serialNumber,
        size: "small",
        endAdornment: "none",
        placeholder: "00000000000"
      }
    ),
    depAccountDetails && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "isDep",
        label: "Device is added in the connected Apple Business Manager",
        checked: formik.values.isDep,
        onChange: formik.handleChange
      }
    ),
    depAccountDetails && formik.values.isDep && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Device username",
        name: "depDeviceUsername",
        value: formik.values.depDeviceUsername,
        onChange: formik.handleChange,
        error: formik.touched.depDeviceUsername && Boolean(formik.errors.depDeviceUsername),
        helperText: formik.touched.depDeviceUsername && formik.errors.depDeviceUsername,
        size: "small",
        endAdornment: "none",
        placeholder: "TestAccount"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Add existing", loading, fullWidth: true, colorVariant: "primary", sizeVariant: "medium" }) })
  ] }) });
};
