"use strict";
import { jsx } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { MoveToSectionContent } from "@/v2/feature/growth/reviews/features/components/move-to-section-content.component";
export const SurveyCycleCreationMoveToSectionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  action,
  sections,
  questionId
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setIsOpen(false);
      },
      children: /* @__PURE__ */ jsx(MoveToSectionContent, { onClose, action, sections, questionId })
    }
  );
};
