"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { AbsenceRequestsPage } from "@v2/feature/absence/company/policies/pages/absence-requests.page";
import { AbsenceTeamBalancesPage } from "@v2/feature/absence/team/policies/pages/absence-team-balances.page";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { ABSENCE_TEAM_BALANCES_ROUTE, ABSENCE_TEAM_REQUESTS_ROUTE } from "@/lib/routes";
export const AbsenceTeamRouter = () => {
  const { data: absencePolicies, isLoading } = useApiClient(AbsenceEndpoints.getTeamAbsencePoliciesExtended(), {
    suspense: false
  });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:manager"], path: ABSENCE_TEAM_REQUESTS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
      AbsenceRequestsPage,
      {
        reach: "team",
        absencePolicies: absencePolicies != null ? absencePolicies : [],
        absencePoliciesLoading: Boolean(isLoading)
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:manager"], path: ABSENCE_TEAM_BALANCES_ROUTE, exact: true, children: /* @__PURE__ */ jsx(AbsenceTeamBalancesPage, { absencePolicies: absencePolicies != null ? absencePolicies : [] }) }),
    /* @__PURE__ */ jsx(Redirect, { to: ABSENCE_TEAM_REQUESTS_ROUTE })
  ] });
};
