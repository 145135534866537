"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReportConfigSection } from "@v2/feature/reports/reports-advanced/components/report-config.section";
import { ReportViewResult } from "@v2/feature/reports/reports-advanced/components/report-view-result.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { reverseColumnsSelectionCleanUpUsingDefaultValues } from "@v2/feature/reports/util/report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { deepCopy } from "@v2/infrastructure/object/object.util";
import { RootStyle } from "@v2/styles/root.styles";
import { LocalDate } from "@v2/util/local-date";
import { useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ReportsAdvancedCreatePage = ({ refreshReportsList, target }) => {
  var _a, _b;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getTestReportsFiltersOptions(), {
    suspense: false
  });
  const location = useLocation();
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [effectiveDate, setEffectiveDate] = useState(new LocalDate().toDateString());
  const [reportResponse, setReportResponse] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const querySettings = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryColumns = queryParams.get("columns");
    const queryFilters = queryParams.get("filters");
    if (!queryColumns && !queryFilters) return null;
    return { queryColumns, queryFilters };
  }, [location]);
  useEffect(() => {
    var _a2, _b2;
    if (!filtersAndColumns) return;
    let initialSelectedColumns = {};
    let initialSelectedFilters = {};
    try {
      if (querySettings) {
        initialSelectedColumns = querySettings.queryColumns ? JSON.parse(decodeURIComponent(querySettings.queryColumns)) : (
          // JSON.parse(Base64.decode(querySettings.queryColumns)) // import { Base64 } from 'js-base64';
          {}
        );
        initialSelectedFilters = querySettings.queryFilters ? JSON.parse(decodeURIComponent(querySettings.queryFilters)) : (
          // JSON.parse(Base64.decode(querySettings.queryFilters))
          {}
        );
        reverseColumnsSelectionCleanUpUsingDefaultValues(initialSelectedColumns, filtersAndColumns.columns);
      } else {
        initialSelectedColumns = deepCopy((_a2 = filtersAndColumns.defaultSelectedColumns) != null ? _a2 : {});
        initialSelectedFilters = deepCopy((_b2 = filtersAndColumns.defaultSelectedFilters) != null ? _b2 : {});
      }
    } catch (error) {
      console.error(error);
      showMessage(`Something went wrong. Could not load report setup. ${error.message}`, "error");
    }
    setSelectedColumns(initialSelectedColumns);
    setSelectedFilters(initialSelectedFilters);
  }, [filtersAndColumns, querySettings, showMessage]);
  const generateReport = useCallback(() => __async(void 0, null, function* () {
    if (Object.keys(selectedColumns).length === 0 && Object.keys(selectedFilters).length === 0) {
      setReportResponse(null);
      return;
    }
    try {
      setIsGenerating(true);
      const response = yield ReportsAPI.generateReport(selectedColumns, selectedFilters, effectiveDate, target);
      setReportResponse(response);
    } catch (error) {
      setReportResponse(null);
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsGenerating(false);
  }), [selectedColumns, selectedFilters, effectiveDate, polyglot, showMessage, target]);
  useEffect(() => {
    if (Object.keys(selectedColumns).length > 0) generateReport();
    else setReportResponse(null);
  }, [selectedColumns, generateReport]);
  return /* @__PURE__ */ jsxs(RootStyle, { style: { display: "flex", flexDirection: "row" }, children: [
    /* @__PURE__ */ jsx(
      ReportConfigSection,
      {
        selectedColumns,
        setSelectedColumns,
        selectedFilters,
        setSelectedFilters,
        reportColumns: (_a = filtersAndColumns == null ? void 0 : filtersAndColumns.columns) != null ? _a : [],
        reportFilters: (_b = filtersAndColumns == null ? void 0 : filtersAndColumns.filters) != null ? _b : [],
        target,
        effectiveDate,
        setEffectiveDate
      }
    ),
    /* @__PURE__ */ jsx(
      ReportViewResult,
      {
        report: null,
        reportResponse,
        isLoading: !!isLoading || isGenerating,
        selectedColumns,
        selectedFilters,
        refreshReportsList,
        refreshReport: generateReport,
        target
      }
    )
  ] });
};
