"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const IconTextCell = ({ avatarSx, icon, textSx, text, sx, endAdornment }) => {
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", flex: 1, gap: spacing.g10, alignItems: "center" }, sx), children: [
    /* @__PURE__ */ jsx(Stack, { sx: __spreadValues({ flex: "0 0 auto" }, avatarSx), children: icon }),
    /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadValues(__spreadProps(__spreadValues({}, themeFonts.caption), {
          overflow: "hidden",
          textOverflow: "ellipsis"
        }), textSx),
        children: text
      }
    ),
    endAdornment
  ] });
};
