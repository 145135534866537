"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { AppStatusTabFilter } from "@v2/feature/app-integration/app-router.util";
import cover from "@/images/app-empty.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { getIconForAppStatus } from "@/v2/feature/app-integration/app-integration-detailed-personal.router";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import {
  APP_GROUP_MANAGEMENT_DRAWER_MODES
} from "@/v2/feature/app-integration/app-integration.interface";
import {
  externalUserHasDelayedAppAction,
  getScheduledActionDateString,
  matchingDelayedActionForExternalUser
} from "@/v2/feature/app-integration/app-integration.util";
import { getGroupListForUser } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { AppAccessDetailDrawer } from "@/v2/feature/app-integration/features/app-details/components/app-access-detail-drawer.component";
import { AppDetailConfigurationModal } from "@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component";
import { AppDetailsActionButton } from "@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component";
import { AppDetailsTable } from "@/v2/feature/app-integration/features/app-details/components/app-details-table.component";
import { AppNameHeader } from "@/v2/feature/app-integration/features/app-details/components/app-name-header.component";
import { AppActionsDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section";
import { AppGroupManagementDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { primarySmallBtn, secondarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppExternalNotEnrolledPage = ({
  teamAccessUserList,
  externalUserList,
  groupMemberships,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  hasAppsAllOrAppOwner
}) => {
  const { polyglot } = usePolyglot();
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("all");
  const [filteredExternalList, setFilteredExternalList] = useState(externalUserList);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [selectedActiveUserLogin, setSelectedActiveUserLogin] = useState(void 0);
  const [preselectedUser, setPreselectedUser] = useState(void 0);
  const [actionsDrawerMode, setActionsDrawerMode] = useState(void 0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState(void 0);
  const [openAccessDetailModal, setOpenAccessDetailModal] = useState(false);
  const [userForAccessDetail, setUserForAccessDetail] = useState(void 0);
  const [scheduledActionDate, setScheduledActionDate] = useState(void 0);
  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false
    }
  );
  const statusColumn = useCallback(
    (original) => /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsxs(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
          display: "flex",
          alignItems: "center",
          gap: spacing.m5
        }),
        children: [
          original.userStatus && !externalUserHasDelayedAppAction(
            delayedActions,
            original.primaryEmail,
            original.id ? String(original.id) : "0"
          ) ? getIconForAppStatus(polyglot, original.userStatus) : "",
          original.userStatus && delayedActions && externalUserHasDelayedAppAction(
            delayedActions,
            original.primaryEmail,
            original.id ? String(original.id) : "0"
          ) ? getIconForAppStatus(polyglot, "Scheduled") : ""
        ]
      }
    ) }),
    [delayedActions, polyglot]
  );
  useEffect(() => {
    setFilteredExternalList(
      filterString === "all" ? externalUserList : externalUserList.filter(
        (eachUser) => {
          var _a;
          return eachUser.userStatus === filterString || ((_a = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a.some((eachEmail) => eachEmail.status === filterString));
        }
      )
    );
  }, [externalUserList, filterString]);
  useEffect(() => {
    setFilteredExternalList(
      searchInput.length === 0 ? externalUserList : externalUserList.filter(
        (eachUser) => {
          var _a;
          return eachUser.displayName && ((_a = eachUser.displayName) == null ? void 0 : _a.toLowerCase().includes(searchInput.toLowerCase()));
        }
      )
    );
  }, [externalUserList, searchInput]);
  const usersNotEnrolledColumn = useMemo(
    () => [
      {
        header: () => polyglot.t("AppTeamAccessPage.columns.name"),
        id: "fullName",
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5,
                color: themeColors.DarkGrey
              }),
              children: (_a = original.name && original.name.fullName) != null ? _a : "N/A"
            }
          );
        }
      },
      {
        header: () => polyglot.t("AppAccessDetailsDrawer.login"),
        id: "primaryEmail",
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        hide: appStub === "github",
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m15
            }),
            children: original.primaryEmail || "N/A"
          }
        ) })
      },
      ...(app == null ? void 0 : app.allowsGroupManagement) ? [
        {
          header: () => "Groups",
          id: "groupMemberships",
          size: 160,
          accessorFn: (row) => row,
          enableSorting: false,
          cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: groupMemberships && original.primaryEmail ? getGroupListForUser(original.primaryEmail, groupMemberships) : ""
            }
          )
        }
      ] : [],
      {
        header: () => polyglot.t("AppTeamAccessPage.columns.status"),
        id: "userStatus",
        maxSize: 180,
        minSize: 100,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => statusColumn(original)
      },
      {
        header: () => "",
        id: "action",
        maxSize: 80,
        minSize: 80,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          AppDetailsActionButton,
          {
            setIsAppActionsDrawerOpen,
            setSelectedActiveUserLogin,
            setGroupManagementDrawerOpen: setIsDrawerOpen,
            setGroupManagementDrawerMode: setDrawerMode,
            refresh: onAppChange,
            appStub,
            user: original,
            delayedActions: delayedActions != null ? delayedActions : { delayed: [] },
            refreshDelayedActions,
            table: "external",
            hasAppsAllOrAppOwner,
            setPreselectedUser,
            setActionsDrawerMode,
            error
          }
        )
      }
    ],
    [
      appStub,
      app == null ? void 0 : app.allowsGroupManagement,
      polyglot,
      groupMemberships,
      statusColumn,
      onAppChange,
      delayedActions,
      refreshDelayedActions,
      hasAppsAllOrAppOwner,
      error
    ]
  );
  const activeTeamMembers = (teamList) => {
    return teamList == null ? void 0 : teamList.filter(
      (eachUser) => {
        var _a;
        return ((_a = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a.some((eachEmail) => ["Active", "Invited"].includes(eachEmail.status))) || eachUser.userStatus === "Active";
      }
    );
  };
  const teamMembersWithNoAccess = (teamList) => {
    return teamList.filter(
      (eachUser) => {
        var _a;
        return ((_a = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a.some((eachEmail) => ["No acess"].includes(eachEmail.status))) || eachUser.userStatus === "No access";
      }
    );
  };
  const addUsersToGroup = () => {
    setIsDrawerOpen(true);
    setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.add);
  };
  const handleRowClicked = useCallback(
    (row) => {
      const { original } = row;
      setOpenAccessDetailModal(true);
      setUserForAccessDetail(original);
      const matchingAction = matchingDelayedActionForExternalUser(
        delayedActions,
        original.primaryEmail,
        original.id ? String(original.id) : "0"
      );
      setScheduledActionDate(matchingAction ? getScheduledActionDateString(matchingAction) : void 0);
    },
    [delayedActions]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(AppNameHeader, { title: polyglot.t("AppExternalNotEnrolledPage.title"), app }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => addUsersToGroup(), children: "New account" }),
        showAction: hasAppsAllOrAppOwner && (app == null ? void 0 : app.allowsGroupManagement)
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading != null ? loading : false, children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "left"
          },
          children: !error && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(
              TabFilterButtons,
              {
                filters: AppStatusTabFilter(polyglot),
                setFilterValue: setFilterString,
                filterValue: filterString,
                hasSearch: true,
                onFilterChange: ({ filterValue, searchInput: searchInput2 }) => {
                  setFilterString(filterValue);
                  setSearchInput(searchInput2);
                }
              }
            ),
            hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(
              Button,
              {
                sx: secondarySmallBtn,
                startIcon: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)),
                onClick: () => onAppChange(appStub),
                children: "Refresh"
              }
            )
          ] })
        }
      ),
      !error && /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          AppDetailsTable,
          {
            column: usersNotEnrolledColumn,
            row: filteredExternalList != null ? filteredExternalList : [],
            loading: loading != null ? loading : false,
            handleRowClicked
          }
        ),
        error && !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
          EmptyStateComponent,
          {
            header: "Authorize this app",
            subheader: "Zelt allows you to manage your whole toolstack from one place.",
            detail: "To start using your app connect your account in Settings first.",
            cover
          }
        )
      ] }),
      appStub && appStub === "github" && error && (app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            mt: spacing.mt40
          },
          children: [
            "Please add your organisation name to finish integration setup",
            /* @__PURE__ */ jsx(Button, { sx: __spreadValues(__spreadValues({}, primarySmallBtn), spacing.mt40), onClick: () => setShowModal(true), children: "Add organisation" })
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        AppDetailConfigurationModal,
        {
          open: showModal,
          handleClose: () => setShowModal(false),
          app,
          appStub,
          refresh: () => onAppChange(appStub)
        }
      ),
      /* @__PURE__ */ jsx(
        AppActionsDrawer,
        {
          appStub,
          usersWithoutAccess: teamAccessUserList,
          usersWithAccess: externalUserList,
          isOpen: isAppActionsDrawerOpen,
          onClose: () => setIsAppActionsDrawerOpen(false),
          refreshApp: () => onAppChange(appStub),
          refreshDelayedActions,
          setIsAppActionsDrawerOpen,
          preselectedUser,
          mode: actionsDrawerMode,
          selectedActiveUserLogin,
          setSelectedActiveUserLogin,
          directoryMode: "external"
        }
      ),
      /* @__PURE__ */ jsx(
        AppGroupManagementDrawer,
        {
          appStub,
          selectedGroupMembership: void 0,
          externalUserList,
          usersWithoutAccess: teamMembersWithNoAccess(teamAccessUserList),
          usersWithAccess: activeTeamMembers(teamAccessUserList),
          isOpen: isDrawerOpen,
          onClose: () => setIsDrawerOpen(false),
          refreshApp: () => onAppChange(appStub),
          setIsDrawerOpen,
          existingUser: preselectedUser,
          mode: drawerMode,
          groupMembers: {},
          groupList: groupMemberships
        }
      ),
      userForAccessDetail && (app == null ? void 0 : app.name) && /* @__PURE__ */ jsx(
        AppAccessDetailDrawer,
        {
          isOpen: openAccessDetailModal,
          setIsOpen: setOpenAccessDetailModal,
          appUserDetail: userForAccessDetail,
          appName: app == null ? void 0 : app.name,
          appStatusValue: statusColumn(userForAccessDetail),
          scheduledActionDate,
          appActionButtons: /* @__PURE__ */ jsx(
            AppDetailsActionButton,
            {
              setIsAppActionsDrawerOpen,
              setGroupManagementDrawerOpen: setIsDrawerOpen,
              setGroupManagementDrawerMode: setDrawerMode,
              refresh: onAppChange,
              appStub,
              user: userForAccessDetail,
              delayedActions: delayedActions != null ? delayedActions : { delayed: [] },
              refreshDelayedActions,
              table: "team",
              setPreselectedUser,
              setActionsDrawerMode,
              hasAppsAllOrAppOwner,
              error,
              appAccessDetailsDrawerMode: true
            }
          )
        }
      )
    ] })
  ] });
};
