"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { EditAttendanceEntryDrawer } from "@v2/feature/entity-import/wizard/components/attendance/edit-attendance-entry-drawer.component";
import { ImportedAttendanceTableCell } from "@v2/feature/entity-import/wizard/components/attendance/imported-attendance-table-cell.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { validateAttendanceImport } from "@/v2/feature/entity-import/wizard/entity-import-validator.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AttendanceImportResultTable = ({
  result,
  completeImport,
  onUpdateAndRevalidate,
  loading,
  setLoading
}) => {
  const { data: attendanceTypes } = useApiClient(AttendanceEndpoints.getCompanyAttendanceTypes(), { suspense: false });
  const [showMessage] = useMessage();
  const [rowBeingEdited, setRowBeingEdited] = useState(void 0);
  const { cachedUsers: allUsersIncludingTerminated } = useCachedUsers();
  const [isOpen, setIsOpen] = useState(false);
  const rowData = useMemo(() => {
    if (result == null ? void 0 : result.errors.some((e) => e.entity)) {
      return result == null ? void 0 : result.errors.flatMap((record) => record.entity);
    }
  }, [result]);
  const rowErrors = useMemo(() => {
    if (result == null ? void 0 : result.errors.some((e) => e.errors.length > 0)) {
      return result == null ? void 0 : result.errors.map((record) => record.errors);
    }
  }, [result == null ? void 0 : result.errors]);
  const notifyPendingErrors = useCallback(() => {
    showMessage("We found some issues in your formatting. Please correct the mistakes and try again", "info");
  }, [showMessage]);
  const notifyDataValid = useCallback(() => {
    showMessage("Your imported data looks good! Continue to finish import.", "success");
  }, [showMessage]);
  useEffect(() => {
    if (rowErrors == null ? void 0 : rowErrors.length) {
      notifyPendingErrors();
    } else {
      notifyDataValid();
    }
  }, [notifyDataValid, notifyPendingErrors, rowErrors == null ? void 0 : rowErrors.length]);
  const getErrorForRow = useCallback(
    (index) => {
      return rowErrors ? rowErrors[index] : [];
    },
    [rowErrors]
  );
  const attendanceColumns = useMemo(() => {
    return [
      {
        header: () => "First name",
        accessorFn: (row) => row,
        id: "firstName",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "firstName"
            }
          );
        },
        maxSize: 120,
        minSize: 120
      },
      {
        header: () => "Last name",
        accessorFn: (row) => row,
        id: "lastName",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "lastName"
            }
          );
        },
        maxSize: 120,
        minSize: 120
      },
      {
        header: () => "Work Email",
        accessorFn: (row) => row,
        id: "workEmail",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "workEmail"
            }
          );
        },
        maxSize: 200,
        minSize: 150
      },
      {
        header: () => "Attendance type",
        accessorFn: (row) => row,
        id: "attendanceType",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "attendanceType"
            }
          );
        },
        maxSize: 200,
        minSize: 150
      },
      {
        header: () => "Date",
        accessorFn: (row) => row,
        id: "logDate",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "logDate"
            }
          );
        },
        maxSize: 120,
        minSize: 120
      },
      {
        header: () => "Start hour",
        accessorFn: (row) => row,
        id: "startHour",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "startHour"
            }
          );
        },
        maxSize: 120,
        minSize: 120
      },
      {
        header: () => "End hour",
        accessorFn: (row) => row,
        id: "endHour",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "endHour"
            }
          );
        },
        maxSize: 120,
        minSize: 120
      },
      {
        header: () => "Validation",
        accessorFn: (row) => row,
        id: "validation",
        enableSorting: false,
        cell: (info) => {
          var _a, _b;
          return /* @__PURE__ */ jsx(
            ImportedAttendanceTableCell,
            {
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "validation",
              statusColumn: (_b = rowErrors && ((_a = rowErrors[info.row.index]) == null ? void 0 : _a.length)) != null ? _b : 0
            }
          );
        },
        maxSize: 200,
        minSize: 150
      }
    ];
  }, [getErrorForRow, rowErrors]);
  const updateDatasetWithRecord = useCallback(
    (values) => {
      if (!rowData) return [];
      const foundIndex = rowData.findIndex((record) => record.id === values.id);
      if (foundIndex < 0) return rowData;
      const olderRecord = rowData[foundIndex];
      const updatedEntry = __spreadProps(__spreadValues(__spreadValues({}, olderRecord), values), {
        id: olderRecord.id
      });
      const updatedRecords = rowData ? [...rowData] : [];
      updatedRecords[foundIndex] = updatedEntry;
      return updatedRecords;
    },
    [rowData]
  );
  const importHandler = useCallback(
    (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const updatedRecords = updateDatasetWithRecord(values);
        const validationResult = yield validateAttendanceImport(
          updatedRecords,
          allUsersIncludingTerminated,
          attendanceTypes != null ? attendanceTypes : []
        );
        onUpdateAndRevalidate(validationResult);
      } catch (error) {
        showMessage("Something went wrong", "error");
      } finally {
        setLoading(false);
      }
    }),
    [
      allUsersIncludingTerminated,
      attendanceTypes,
      onUpdateAndRevalidate,
      setLoading,
      updateDatasetWithRecord,
      showMessage
    ]
  );
  const handleRowClick = useCallback(
    (row) => {
      if (rowData) {
        setRowBeingEdited(row.original);
        setIsOpen(true);
      }
    },
    [rowData]
  );
  const totalPendingErrors = useMemo(() => {
    var _a, _b;
    return (_b = (_a = rowErrors == null ? void 0 : rowErrors.flat()) == null ? void 0 : _a.length) != null ? _b : 0;
  }, [rowErrors]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          pt: spacing.p25,
          flex: 1,
          overflow: "auto",
          borderTop: "1px solid",
          borderColor: themeColors.GreyLight
        },
        children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: rowData != null ? rowData : [],
            columnData: attendanceColumns,
            rowClick: handleRowClick,
            loading
          }
        )
      }
    ),
    rowBeingEdited && /* @__PURE__ */ jsx(
      EditAttendanceEntryDrawer,
      {
        isOpen,
        setIsOpen,
        onClose: () => {
          setIsOpen(false);
          setRowBeingEdited(void 0);
        },
        attendance: rowBeingEdited,
        attendanceTypes: attendanceTypes != null ? attendanceTypes : [],
        allUsers: allUsersIncludingTerminated,
        importHandler
      }
    ),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: "30px" },
        disabled: loading,
        onClick: () => {
          if (rowErrors == null ? void 0 : rowErrors.length) {
            notifyPendingErrors();
          } else {
            completeImport();
          }
        },
        children: totalPendingErrors ? "Try again" : "Continue"
      }
    )
  ] });
};
