"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { PaymentsListPage } from "./pages/payments-list.page";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { PAYMENT_COMPANY_OVERVIEW_ROUTE, PAYMENT_COMPANY_ROUTE } from "@/lib/routes";
export const PaymentsCompanyRouter = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["payments:all"], path: PAYMENT_COMPANY_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PaymentsListPage, {}) }),
  /* @__PURE__ */ jsx(Redirect, { from: PAYMENT_COMPANY_ROUTE, to: PAYMENT_COMPANY_OVERVIEW_ROUTE, exact: true })
] });
