"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { InfoCard } from "@v2/feature/absence/components/info-card.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import {
  SeatsMethod,
  SubscriptionType
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
export const CompanySubscriptionBillingOverview = ({ subscriptionDetailsForCompany }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const { polyglot } = usePolyglot();
  const nextInvoiceCost = (_b = (_a = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.billResult) == null ? void 0 : _a.totalCost) != null ? _b : 0;
  const isInTrialMode = ((_c = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _c.type) === SubscriptionType.TRIAL;
  const trialDateEnding = isInTrialMode && ((_d = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _d.endDate) ? new Date((_e = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _e.endDate) : null;
  const activeUsers = (_f = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _f.seatMultiplier;
  const rentedDevices = (_i = (_h = (_g = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _g.billingMetric) == null ? void 0 : _h.noOfRentedDevices) != null ? _i : 0;
  const activeAccountsText = `${activeUsers}`;
  const today = /* @__PURE__ */ new Date();
  const nextInvoiceDate = new Date(today.getFullYear(), today.getMonth() + 1, 1).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric"
  });
  const rentedDevicesText = `${rentedDevices} device${rentedDevices === 1 ? "" : "s"}`;
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.gap60 }, children: isInTrialMode && trialDateEnding ? /* @__PURE__ */ jsx(InfoCard, { title: polyglot.t("BillingOverview.trialEnding"), value: dateAPItoDisplay(trialDateEnding) }) : /* @__PURE__ */ jsxs(Fragment, { children: [
    nextInvoiceCost > 0 && /* @__PURE__ */ jsx(InfoCard, { title: polyglot.t("BillingOverview.nextInvoiceDate"), value: nextInvoiceDate }),
    nextInvoiceCost > 0 && /* @__PURE__ */ jsx(
      InfoCard,
      {
        title: polyglot.t("BillingOverview.amount"),
        value: formatMoney({
          locale: "en-US",
          amount: nextInvoiceCost,
          currency: (_j = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _j.currency
        })
      }
    ),
    /* @__PURE__ */ jsx(
      InfoCard,
      {
        title: polyglot.t(
          ((_k = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _k.seatsMethod) === SeatsMethod.ACTIVE ? "BillingOverview.active" : "BillingOverview.fixed"
        ),
        value: activeAccountsText
      }
    ),
    rentedDevices > 0 && /* @__PURE__ */ jsx(InfoCard, { title: polyglot.t("BillingOverview.rented"), value: rentedDevicesText })
  ] }) });
};
