"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import useScopes from "@/hooks/scopes.hook";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PensionMe } from "@/v2/feature/benefits/subfeature/pension/features/pension-me/components/pension-me.components";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { BENEFITS_ME_ROUTE } from "@/lib/routes";
export const UserPensionPage = ({ userId, employeePension, onEmployeeUpdate, loading }) => {
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation();
  const [isEnrolDrawerOpen, setIsEnrolDrawerOpen] = useState(false);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionScope = hasScopes(["pension"], scopesContext);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: routerLocation.pathname.includes("/settings") ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("UserPensionPage.settings") }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("UserPensionPage.pension") }),
        showBack: true,
        backPath: BENEFITS_ME_ROUTE,
        settingsAction: () => setIsEnrolDrawerOpen(true),
        settingsHide: !hasPensionScope
      }
    ),
    /* @__PURE__ */ jsx(
      PensionMe,
      {
        userId,
        employeePension,
        onEmployeeUpdate,
        isEnrolDrawerOpen,
        setIsEnrolDrawerOpen,
        loading
      }
    )
  ] });
};
