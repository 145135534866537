"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { parentCardSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as NotiBig } from "@/images/onboarding-intro/NotiBig.svg";
import { ReactComponent as SmileBig } from "@/images/payments-intro/SmileBig.svg";
import { ReactComponent as WalletBig } from "@/images/pension/WalletBig.svg";
import { ReactComponent as MultiBig } from "@/images/reports/MultiBig.svg";
import { SETTINGS_TIME_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const AbsenceEmpty = () => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, parentCardSx), { height: "100%" }), children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsenceEmpty.track") }),
          /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.g30 }, children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "primary",
              onClick: () => history.push(SETTINGS_TIME_ROUTE),
              children: polyglot.t("AbsenceEmpty.starts")
            }
          ) })
        ] }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              gap: spacing.g40
            },
            children: [
              /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", gap: spacing.g40 }, children: [
                /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
                  /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m15 }, children: /* @__PURE__ */ jsx(MultiBig, {}) }),
                  /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: spacing.m10 }, children: polyglot.t("AbsenceEmpty.configurable") }),
                  /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsenceEmpty.custom") })
                ] }),
                /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
                  /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m15 }, children: /* @__PURE__ */ jsx(NotiBig, {}) }),
                  /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: spacing.m10 }, children: polyglot.t("AbsenceEmpty.seamlessApproval") }),
                  /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsenceEmpty.setNotification") })
                ] })
              ] }),
              /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", gap: spacing.g40 }, children: [
                /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
                  /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m15 }, children: /* @__PURE__ */ jsx(WalletBig, {}) }),
                  /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: spacing.m10 }, children: polyglot.t("AbsenceEmpty.payrollIntegration") }),
                  /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
                    "T",
                    polyglot.t("AbsenceEmpty.timeOffInfo")
                  ] })
                ] }),
                /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
                  /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m15 }, children: /* @__PURE__ */ jsx(SmileBig, {}) }),
                  /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: spacing.m10 }, children: polyglot.t("AbsenceEmpty.wellbeing") }),
                  /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsenceEmpty.spotoverWorked") })
                ] })
              ] })
            ]
          }
        )
      ]
    }
  );
};
