"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateDomainTypes } from "@v2/infrastructure/i18n/translate.util";
import { ReactComponent as Trophy } from "@/images/dashboard-icons/Trophy.svg";
import { TodoCardVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/todo-card-version-small.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const ReviewTodoVersionSmall = ({ todo }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    TodoCardVersionSmall,
    {
      icon: /* @__PURE__ */ jsx(Trophy, __spreadValues({}, iconSize)),
      title: translateDomainTypes(todo.domain, polyglot),
      countTitle: polyglot.t("ReviewTodoVersionSmall.requests", { smart_count: todo.count })
    },
    `${todo.domain}-req`
  );
};
