"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import {
  definitionListSx,
  drawerContentSx
} from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { FieldStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { iconSize } from "@/v2/styles/menu.styles";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const DeveloperHubNewClientInfoDrawer = ({
  openInfo,
  clientInfo,
  onClose,
  onUpdate
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(openInfo);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        onClose();
      },
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeveloperHub.credentialsPageExplainer") }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: /* @__PURE__ */ jsx("b", { children: polyglot.t("DeveloperHubClientGenerateDrawer.clientSecretExplainer") }) }),
        /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
          /* @__PURE__ */ jsx(Box, { component: "dl", sx: definitionListSx, children: (clientInfo == null ? void 0 : clientInfo.id) && /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("DeveloperHub.appId"),
              fieldValue: (clientInfo == null ? void 0 : clientInfo.id) && {
                isLink: true,
                label: truncateWithEllipses(clientInfo.id, 50),
                icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                onClick: () => navigator.clipboard.writeText(clientInfo.id)
              },
              fieldStub: "id"
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { component: "dl", sx: definitionListSx, children: (clientInfo == null ? void 0 : clientInfo.clientId) && /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("DeveloperHub.clientId"),
              fieldValue: (clientInfo == null ? void 0 : clientInfo.id) && {
                isLink: true,
                label: truncateWithEllipses(clientInfo.clientId, 50),
                icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                onClick: () => navigator.clipboard.writeText(clientInfo.clientId)
              },
              fieldStub: "clientId"
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { component: "dl", sx: definitionListSx, children: (clientInfo == null ? void 0 : clientInfo.clientSecret) && /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("DeveloperHub.clientSecret"),
              fieldValue: (clientInfo == null ? void 0 : clientInfo.clientSecret) && {
                isLink: true,
                label: truncateWithEllipses(clientInfo.clientSecret, 50),
                icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                onClick: () => navigator.clipboard.writeText(clientInfo.clientSecret)
              },
              fieldStub: "clientSecret"
            }
          ) }),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              fullWidth: true,
              colorVariant: "primary",
              sizeVariant: "medium",
              onClick: () => {
                onUpdate();
                onClose();
              },
              children: polyglot.t("General.close")
            }
          )
        ] })
      ] })
    }
  );
};
