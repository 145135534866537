"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { BulkFileUploadInput } from "@/v2/components/upload/bulk-file-upload.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const iconSize = { width: 14, height: 14 };
export const DocumentBulkUploadFlow = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const returnToDocumentsCompanyPage = useCallback(() => {
    routerHistory.goBack();
  }, [routerHistory]);
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30, position: "absolute", zIndex: 10, inset: 0, background: themeColors.white }, children: [
    /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", width: "70%", mx: "auto" }, children: /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", left: spacing.m50 }),
        onClick: () => returnToDocumentsCompanyPage(),
        children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
      }
    ) }),
    /* @__PURE__ */ jsxs(
      Stack,
      {
        sx: {
          overflow: "hidden auto",
          pb: spacing.p30,
          width: "1000px",
          maxWidth: "90vw",
          mx: "auto",
          mt: spacing.mt40
        },
        children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("DocumentBulkUploadFlow.title") }),
          /* @__PURE__ */ jsx(
            BulkFileUploadInput,
            {
              onComplete: (success) => {
                if (success > 0) showMessage(`Successfully uploaded ${success} documents`, "success");
              },
              finishBulkUploadSession: () => {
                returnToDocumentsCompanyPage();
              },
              skipUpload: true,
              onChange: (_, file) => __async(void 0, null, function* () {
                try {
                  if (!file) return;
                } catch (error) {
                  showMessage(polyglot.t("DataSourceChoiceSection.errorMessages.parsingCsv"), "error");
                  console.error(":::: ERROR PARSING FILES :::::", error);
                }
              })
            }
          )
        ]
      }
    )
  ] });
};
