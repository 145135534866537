"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { ATTENDANCE_TEAM_REQUESTS_ROUTE } from "@/lib/routes";
import { AttendanceTeamView } from "@/v2/feature/attendance/team/pages/attendance-team-requests/attendance-team-view.page";
export const AttendanceTeamRouter = ({ isManager }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:manager"], path: ATTENDANCE_TEAM_REQUESTS_ROUTE, children: /* @__PURE__ */ jsx(AttendanceTeamView, { view: "team" }) }),
    /* @__PURE__ */ jsx(Redirect, { to: ATTENDANCE_TEAM_REQUESTS_ROUTE })
  ] });
};
