"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  SETTINGS_REQUEST_FORMS_EDIT_ROUTE,
  SETTINGS_REQUEST_FORMS_NEW_ROUTE,
  SETTINGS_REQUEST_FORMS_ROUTE
} from "@/lib/routes";
import { EditRequestFormPage } from "@/v2/feature/requests/features/request-forms/edit-request-form/edit-request-form.page";
import { NewRequestFormPage } from "@/v2/feature/requests/features/request-forms/new-request-form/new-request-form.page";
import { RequestFormsPage } from "@/v2/feature/requests/features/request-forms/request-forms.page";
export const RequestFormsRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["company.settings:all"],
        path: SETTINGS_REQUEST_FORMS_ROUTE,
        component: RequestFormsPage,
        exact: true,
        children: /* @__PURE__ */ jsx(RequestFormsPage, {})
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["company.settings:all"],
        path: SETTINGS_REQUEST_FORMS_NEW_ROUTE,
        component: NewRequestFormPage,
        exact: true
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["company.settings:all"],
        path: SETTINGS_REQUEST_FORMS_EDIT_ROUTE,
        component: EditRequestFormPage,
        exact: true
      }
    )
  ] });
};
