"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { NumberCell } from "@v2/components/table/number-cell.component";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { ColumnTypeToSign } from "@v2/feature/reports/reports-advanced/reports-advanced.util";
import { formatReportValue } from "@v2/feature/reports/reports-formatting.util";
import { ReportColumnType } from "@v2/feature/reports/reports.interface";
import { spacing } from "@/v2/styles/spacing.styles";
function getDefaultLabel(key, label) {
  const tableSplit = key.split("__").join("->");
  const [colLabel, operator] = tableSplit.split("|");
  const appliedOperator = operator ? ColumnTypeToSign[operator] : null;
  if (label) return { colLabel: label, appliedOperator };
  return { colLabel, appliedOperator };
}
function sortTimeSinceReports(a, b, getValue) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if ((aValue == null ? void 0 : aValue.years) !== (bValue == null ? void 0 : bValue.years)) return ((_a = aValue == null ? void 0 : aValue.years) != null ? _a : 0) - ((_b = bValue == null ? void 0 : bValue.years) != null ? _b : 0);
  if ((aValue == null ? void 0 : aValue.months) !== (bValue == null ? void 0 : bValue.months)) return ((_c = aValue == null ? void 0 : aValue.months) != null ? _c : 0) - ((_d = bValue == null ? void 0 : bValue.months) != null ? _d : 0);
  if ((aValue == null ? void 0 : aValue.days) !== (bValue == null ? void 0 : bValue.days)) return ((_e = aValue == null ? void 0 : aValue.days) != null ? _e : 0) - ((_f = bValue == null ? void 0 : bValue.days) != null ? _f : 0);
  if ((aValue == null ? void 0 : aValue.hours) !== (bValue == null ? void 0 : bValue.hours)) return ((_g = aValue == null ? void 0 : aValue.hours) != null ? _g : 0) - ((_h = bValue == null ? void 0 : bValue.hours) != null ? _h : 0);
  if ((aValue == null ? void 0 : aValue.minutes) !== (bValue == null ? void 0 : bValue.minutes)) return ((_i = aValue == null ? void 0 : aValue.minutes) != null ? _i : 0) - ((_j = bValue == null ? void 0 : bValue.minutes) != null ? _j : 0);
  if ((aValue == null ? void 0 : aValue.seconds) !== (bValue == null ? void 0 : bValue.seconds)) return ((_k = aValue == null ? void 0 : aValue.seconds) != null ? _k : 0) - ((_l = bValue == null ? void 0 : bValue.seconds) != null ? _l : 0);
  if ((aValue == null ? void 0 : aValue.milliseconds) !== (bValue == null ? void 0 : bValue.milliseconds)) return ((_m = aValue == null ? void 0 : aValue.milliseconds) != null ? _m : 0) - ((_n = bValue == null ? void 0 : bValue.milliseconds) != null ? _n : 0);
  return 0;
}
export const ReportTable = ({ reportResponse, stickyHeader }) => {
  var _a;
  const columns = useMemo(() => {
    if (!(reportResponse == null ? void 0 : reportResponse.data) || reportResponse.data.length === 0) return [];
    const firstRow = reportResponse.data[0];
    const columnsList = Object.keys(
      firstRow
    ).map((key) => {
      var _a2;
      return {
        id: key,
        // minSize: 100, // size configured bellow in Box sx
        // maxSize: 500,
        enableSorting: true,
        sortingFn: (a, b) => {
          var _a3, _b, _c;
          return ((_a3 = firstRow[key]) == null ? void 0 : _a3.type) === "number" ? sortNumeric(a, b, (item) => {
            var _a4;
            return (_a4 = item[key].raw) != null ? _a4 : 0;
          }) : ((_b = firstRow[key]) == null ? void 0 : _b.type) === "date" && typeof ((_c = firstRow[key]) == null ? void 0 : _c.raw) === "object" && key.split("|")[1] === ReportColumnType.AGE ? sortTimeSinceReports(a, b, (item) => {
            var _a4;
            return (_a4 = item[key].raw) != null ? _a4 : void 0;
          }) : sortString(a, b, (item) => {
            var _a4;
            return (_a4 = item[key].raw) != null ? _a4 : "";
          });
        },
        accessorFn: (row) => row,
        header: () => {
          const { colLabel, appliedOperator } = getDefaultLabel(key, reportResponse.header.labels[key]);
          return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.sm }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: colLabel }),
            appliedOperator && /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Grey", children: [
              "(",
              appliedOperator,
              ")"
            ] })
          ] });
        },
        cell: ({ row: { original } }) => {
          var _a3;
          if (!original[key] || original[key].value === null || original[key].value === void 0) return "";
          const formattedValue = formatReportValue(original[key].value, (_a3 = reportResponse.header) == null ? void 0 : _a3.displayFormat[key]);
          if (original[key].type === "number")
            return /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "144px" }, children: /* @__PURE__ */ jsx(NumberCell, { value: formattedValue, cellSx: { minWidth: "50px" } }) });
          return /* @__PURE__ */ jsx(Box, { sx: { minWidth: "100px", maxWidth: "500px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { width: "100%" }, children: formattedValue }) });
        },
        order: (_a2 = reportResponse.header.order[key]) != null ? _a2 : 0
      };
    });
    columnsList.sort((a, b) => a.order - b.order);
    return columnsList;
  }, [reportResponse]);
  return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", maxHeight: `${window.innerHeight - 123}px` }, children: /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: [...(_a = reportResponse == null ? void 0 : reportResponse.data) != null ? _a : []],
      columnData: columns,
      fixedLastColumn: false,
      stickyHeader,
      paginationSx: { px: spacing.s2 }
    }
  ) });
};
