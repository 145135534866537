"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { NotImplemented } from "@v2/feature/reports/reports-advanced/filters-components/not-implemented.component";
import { ReportSQLOperator } from "@v2/feature/reports/reports.interface";
export const StringSelector = ({ operator, value, setValue, label }) => {
  const StringOperatorsWithNoExtraField = useMemo(() => {
    return /* @__PURE__ */ new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);
  const StringOperatorsWithTextField = useMemo(() => {
    return /* @__PURE__ */ new Set([ReportSQLOperator.eq, ReportSQLOperator.startsWith, ReportSQLOperator.endsWith]);
  }, []);
  return StringOperatorsWithTextField.has(operator) ? /* @__PURE__ */ jsx(
    TextfieldComponent,
    {
      value,
      name: "value",
      label,
      onChange: (e) => {
        setValue(e.target.value);
      },
      endAdornment: "none"
    }
  ) : operator === ReportSQLOperator.contains || operator === ReportSQLOperator.notContains ? /* @__PURE__ */ jsx(ArrayStringsPicker, { value, setValue, label }) : StringOperatorsWithNoExtraField.has(operator) ? null : /* @__PURE__ */ jsx(NotImplemented, {});
};
const ArrayStringsPicker = ({
  value,
  setValue,
  label
}) => {
  const [localValue, setLocalValue] = useState(Array.isArray(value) ? value.join(",") : "");
  useEffect(() => {
    const newValue = localValue.split(",").filter(Boolean).map((n) => n.trim());
    setValue(newValue);
  }, [localValue, setValue]);
  return /* @__PURE__ */ jsx(
    TextfieldComponent,
    {
      value: localValue,
      name: "value",
      label,
      onChange: (e) => {
        setLocalValue(e.target.value);
      },
      endAdornment: "none",
      helperText: "eg.: text1,text2,text3"
    }
  );
};
