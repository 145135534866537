"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { HelperAbsencePoliciesTable } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policies-table.component";
import { HelperAbsencePolicyBalance } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-balance.component";
import { HelperAbsenceSettingsSection } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-settings-section.component";
import { HelperAbsenceUsersCarryOverSection } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-users-carry-over-section.component";
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { generatePath, Route, Switch } from "react-router-dom";
export const HelperCarryOverPage = ({
  users,
  company
}) => {
  const companyId = company.companyId;
  const {
    data: absenceSettings,
    isLoading: isLoadingSettings
  } = useApiClient(AbsenceEndpoints.getCompanyAbsenceSettingsAsSuperAdmin(companyId), { suspense: false });
  const {
    data: companyPolicies,
    isLoading: isLoadingPolicies
  } = useApiClient(AbsenceEndpoints.getAllCompanyAbsencePoliciesAsSuperAdmin(companyId), { suspense: false });
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsxs(Route, { path: SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE, children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
          "Helper page - Absence | ",
          company.name,
          " [",
          companyId,
          "]"
        ] }),
        showBack: true,
        backPath: generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "100%", gap: "40px", mb: "20px" }, children: [
        /* @__PURE__ */ jsx(HelperAbsenceSettingsSection, { absenceSettings, isLoadingSettings: !!isLoadingSettings }),
        /* @__PURE__ */ jsx(
          HelperAbsencePoliciesTable,
          {
            absencePolicies: companyPolicies != null ? companyPolicies : [],
            isLoadingPolicies: !!isLoadingPolicies,
            users
          }
        )
      ] }),
      /* @__PURE__ */ jsx(HelperAbsencePolicyBalance, { companyId, absencePolicies: companyPolicies != null ? companyPolicies : [], users }),
      /* @__PURE__ */ jsx(
        HelperAbsenceUsersCarryOverSection,
        {
          companyId,
          absencePolicies: companyPolicies != null ? companyPolicies : [],
          users
        }
      )
    ] })
  ] }) });
};
