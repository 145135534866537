"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ContractorInvoiceAPI {
  static getAlerts() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/contractor/invoice/alerts")).data;
    });
  }
  static createInvoice(payload) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/contractor/invoice", payload)).data;
    });
  }
  static updateInvoice(invoiceId, payload) {
    return __async(this, null, function* () {
      return (yield axios.put(`/apiv2/contractor/invoice/${invoiceId}`, payload)).data;
    });
  }
  static bulkApprove(invoiceIds) {
    return __async(this, null, function* () {
      return (yield axios.patch("/apiv2/contractor/invoice/bulk-approve", invoiceIds)).data;
    });
  }
  static getSpecificUserInvoices(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/contractor/invoice/users/${userId}`)).data;
    });
  }
  static getTeamInvoices() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/contractor/invoice/team")).data;
    });
  }
  static getCompanyInvoices() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/contractor/invoice")).data;
    });
  }
  static updateInvoiceApproval(invoiceId, userId, status, notes) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/contractor/invoice/${invoiceId}/users/${userId}/approval`, { status, notes })).data;
    });
  }
  static downloadInvoice(payload) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/contractor/invoice/download/${payload.invoiceId}`, {
        responseType: "blob"
      })).data;
    });
  }
  static payInvoice(invoiceId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/contractor/invoice/${invoiceId}/pay`);
    });
  }
  static simulatePaymentResponse(invoiceId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/contractor/invoice/${invoiceId}/simulate-payment-response`);
    });
  }
  static syncInvoiceWithExternalProvider(invoiceId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/contractor/invoice/${invoiceId}/sync-with-accounting-provider`)).data;
    });
  }
  static voidInvoice(invoiceId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/contractor/invoice/${invoiceId}/void`);
    });
  }
  static deleteInvoice(invoiceId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/contractor/invoice/${invoiceId}`);
    });
  }
  static getUserInvoiceForPaymentId(userId, paymentId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/contractor/invoice/users/${userId}/payments/${paymentId}`)).data;
    });
  }
  static getApprovalListForNewInvoice(userId, typeId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/contractor/invoice/approver-list-for-new-invoice/${userId}/${typeId}`)).data;
    });
  }
  static withdrawFromAccount(amount) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/contractor/invoice/withdraw`, { amount });
    });
  }
}
export class ContractorInvoiceEndpoints {
  static getCompanyInvoices() {
    return {
      url: "/apiv2/contractor/invoice"
    };
  }
  static getCompanyInvoiceMembers() {
    return {
      url: "/apiv2/contractor/invoice/members"
    };
  }
  static getSpecificUserInvoices(userId) {
    return {
      url: `/apiv2/contractor/invoice/users/${userId}`
    };
  }
  static getUserInvoiceById(userId, invoiceId) {
    return {
      url: `/apiv2/contractor/invoice/${invoiceId}/users/${userId}`
    };
  }
}
