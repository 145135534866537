"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { LOGIN_ROUTE, USER_ONBOARDING_PASSWORD } from "@/lib/routes";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
export const UserOnboardingWelcomePage = () => {
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const searchParams = new URLSearchParams(window.location.search);
  const passwordScreen = useCallback(() => {
    const searchParams2 = new URLSearchParams(window.location.search);
    const apiParams = new URLSearchParams();
    apiParams.set("id", searchParams2.get("id") || "");
    apiParams.set("token", searchParams2.get("token") || "");
    apiParams.set("expires", searchParams2.get("expires") || "");
    apiParams.set("email", searchParams2.get("email") || "");
    apiParams.set("signature", searchParams2.get("signature") || "");
    return `${USER_ONBOARDING_PASSWORD}?${apiParams.toString()}`;
  }, []);
  const userId = Number(searchParams.get("id"));
  const email = searchParams.get("email");
  useEffect(() => {
    function checkPwdSet() {
      return __async(this, null, function* () {
        try {
          if (!email) {
            routerHistory.replace(LOGIN_ROUTE);
            return;
          }
          const passwordSet = yield AuthAPI.isPasswordSet(email);
          const isPasswordAlreadyDone = (passwordSet == null ? void 0 : passwordSet.userId) === userId;
          if (isPasswordAlreadyDone) {
            routerHistory.replace(LOGIN_ROUTE, {
              continueOnboarding: true,
              email
            });
            return;
          }
          routerHistory.replace(passwordScreen());
        } catch (error) {
          showMessage(
            `Something bad happened while trying to onboard user: Please try again. ${nestErrorMessage(error)}`,
            "error"
          );
        }
      });
    }
    checkPwdSet();
  }, [email, passwordScreen, routerHistory, showMessage, userId]);
  return /* @__PURE__ */ jsx(Fragment, {});
};
