"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/system";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const ImageDimensionsEditor = ({
  editor,
  anchorEl,
  setAnchorEl,
  setShowImageModal,
  showImageModal
}) => {
  const [imageError, setImageError] = useState();
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: showImageModal,
      onClose: () => {
        setAnchorEl(void 0);
        setShowImageModal(false);
      },
      setIsOpen: (val) => {
        if (!val) setAnchorEl(void 0);
        setShowImageModal(val);
      },
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Update Image Size" }),
        /* @__PURE__ */ jsx(Box, { sx: { width: "100%", gap: "5px" }, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "Height",
            label: "Height",
            value: anchorEl == null ? void 0 : anchorEl.height,
            onChange: (e) => {
              setImageError(void 0);
              setAnchorEl((prevState) => {
                if (!prevState) {
                  return;
                }
                return __spreadProps(__spreadValues({}, prevState), {
                  height: parseInt(e.target.value)
                });
              });
            },
            type: "number",
            fullWidth: true,
            minValue: 0,
            error: imageError == null ? void 0 : imageError.height
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { width: "100%", gap: "5px" }, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "Width",
            label: "Width",
            value: anchorEl == null ? void 0 : anchorEl.width,
            onChange: (e) => {
              setImageError(void 0);
              setAnchorEl((prevState) => {
                if (!prevState) {
                  return;
                }
                return __spreadProps(__spreadValues({}, prevState), {
                  width: parseInt(e.target.value)
                });
              });
            },
            type: "number",
            fullWidth: true,
            minValue: 0,
            error: imageError == null ? void 0 : imageError.width
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            loading: false,
            name: polyglot.t("General.save"),
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true,
            onClick: () => {
              const view = editor.view;
              if (!(anchorEl == null ? void 0 : anchorEl.height) || anchorEl && anchorEl.height < 1) {
                setImageError({ height: true });
                return;
              }
              if (!(anchorEl == null ? void 0 : anchorEl.width) || anchorEl && anchorEl.width < 1) {
                setImageError({ width: true });
                return;
              }
              setImageError(void 0);
              const pos = editor == null ? void 0 : editor.view.posAtDOM(anchorEl == null ? void 0 : anchorEl.target, 0);
              const tr = view.state.tr;
              const node = view.state.doc.nodeAt(pos);
              const newStyle = `height: ${anchorEl == null ? void 0 : anchorEl.height}px; width: ${anchorEl == null ? void 0 : anchorEl.width}px;`;
              tr.setNodeMarkup(pos, void 0, __spreadProps(__spreadValues({}, node == null ? void 0 : node.attrs), {
                style: newStyle
              }));
              view.dispatch(tr);
              setAnchorEl(void 0);
              setShowImageModal(false);
            }
          }
        ) })
      ] })
    }
  );
};
