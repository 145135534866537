"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from "@mui/material";
import { themeColors } from "@v2/styles/colors.styles";
const styles = {
  root: { transform: "rotate(90deg) scaleX(-1)" }
};
export const CircularProgressChart = ({
  value,
  color,
  size = 50,
  thickness = 10,
  baseColor = themeColors.Background
}) => {
  return /* @__PURE__ */ jsxs(Box, { position: "relative", display: "inline-flex", children: [
    /* @__PURE__ */ jsx(
      CircularProgress,
      {
        thickness,
        size,
        sx: { color: baseColor, position: "absolute", left: 0 },
        value: 100,
        variant: "determinate",
        style: styles.root
      }
    ),
    /* @__PURE__ */ jsx(
      CircularProgress,
      {
        thickness,
        size,
        sx: { color },
        value,
        variant: "determinate",
        style: styles.root
      }
    )
  ] });
};
