"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { BidirectionalButton } from "@/v2/components/bidirectional-button.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { iconSize } from "@/v2/feature/onboarding/onboarding-template-edit.page";
import { spacing } from "@/v2/styles/spacing.styles";
export const AttendanceViewTopHeaderActions = ({
  handleRange,
  rangeType,
  navigateBack,
  navigateNext,
  buttonTitle,
  recenterToday,
  showToday
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
    showToday && /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: () => recenterToday(), children: polyglot.t("AttendanceDomain.today") }),
    /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            handler: () => handleRange("Month"),
            label: polyglot.t("AttendanceDomain.month"),
            disabled: false
          },
          {
            handler: () => handleRange("Week"),
            label: polyglot.t("AttendanceDomain.week"),
            disabled: false
          }
        ],
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: rangeType === "Month" ? polyglot.t("AttendanceDomain.month") : polyglot.t("AttendanceDomain.week"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    ),
    /* @__PURE__ */ jsx(BidirectionalButton, { navigateBack, navigateNext, buttonTitle })
  ] });
};
