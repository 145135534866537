"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CompanyConfigAPI } from "@v2/feature/company-config/company-config.api";
import { HelperCompanyRouter } from "@v2/feature/super-admin/features/helper-dashboard/helper-company.router";
import { UserAPI } from "@v2/feature/user/user.api";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, Route, Switch, useHistory } from "react-router-dom";
import { CompanyAPI } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SUPER_ADMIN_USERS } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TooltipPopper } from "@/v2/components/theme-components/notification-tooltip-popper.component";
export const SUPER_ADMIN_HELPER_ROUTE = "/backoffice/helper-dashboard";
export const SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE = "/backoffice/helper-dashboard/companies/:companyId";
export const SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE = "/backoffice/helper-dashboard/companies/:companyId/payruns";
export const SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE = "/backoffice/helper-dashboard/companies/:companyId/payruns/:payrunId";
export const SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE = "/backoffice/helper-dashboard/companies/:companyId/devices";
export const SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE = "/backoffice/helper-dashboard/companies/:companyId/next-year-carry-over";
export const SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE = "/backoffice/helper-dashboard/companies/:companyId/account-statements";
export const SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE = "/backoffice/helper-dashboard/companies/:companyId/records";
export const SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE = "/backoffice/helper-dashboard/companies/:companyId/time";
export const HelperRouter = () => {
  const [searchInput, setSearchInput] = useState("");
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const fetchCompanies = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const { companies: companies2 } = yield CompanyAPI.getAllCompaniesAsSuperAdmin({ shouldLimitResult: "false" });
      setCompanies(companies2);
      const { users: users2 } = yield UserAPI.getAllUsersAsSuperAdmin({ shouldLimitResult: "false" });
      setUsers(users2);
    } catch (error) {
      showMessage("Could not load the companies", "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);
  const filteredCompanies = useMemo(() => {
    return companies.filter((company) => {
      return company.name.toLowerCase().includes(searchInput.toLowerCase()) || String(company.companyId).includes(searchInput);
    });
  }, [companies, searchInput]);
  const companiesWithTestModeEnabled = useMemo(() => {
    return companies.filter((c) => {
      var _a;
      return c.companyConfigBackoffice && ((_a = c.companyConfigBackoffice[0]) == null ? void 0 : _a.disableEmails);
    });
  }, [companies]);
  const handleRowClick = useCallback(
    ({ original }) => {
      routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId: original.companyId }));
    },
    [routerHistory]
  );
  const columns = useMemo(
    () => [
      {
        header: () => "Company ID",
        id: "companyId",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.companyId })
      },
      {
        header: () => "Company name",
        id: "name",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.name })
      },
      {
        header: () => "Has support",
        id: "hasSupport",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.hasSupport ? "Yes" : "No" })
      },
      {
        header: () => "Priority support",
        id: "prioritySupport",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.prioritySupport ? "Yes" : "No" })
      },
      {
        header: () => "Company entities",
        id: "entities",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.entities && original.entities.length > 0 ? /* @__PURE__ */ jsx(Box, { children: original.entities.map((entity) => {
          var _a, _b;
          return /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.caption, children: [
            (_a = entity.legalName) != null ? _a : "N/A",
            " | ",
            (_b = entity.nationalId) != null ? _b : "N/A"
          ] }, entity.id);
        }) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Is active",
        id: "isActive",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.isActive ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Red } }))
      },
      {
        header: () => "Created at",
        id: "createdAt",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: new Date(original.createdAt).toLocaleDateString() })
      },
      {
        header: () => "Actions",
        id: "actions",
        maxSize: 110,
        minSize: 110,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          const hasEmailsDisabled = original.companyConfigBackoffice && ((_a = original.companyConfigBackoffice[0]) == null ? void 0 : _a.disableEmails);
          return /* @__PURE__ */ jsx(
            Box,
            {
              onClick: (e) => {
                e.stopPropagation();
              },
              children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  fullWidth: true,
                  onClick: () => __async(void 0, null, function* () {
                    try {
                      setLoading(true);
                      yield CompanyConfigAPI.disableOrEnableTestModeAsSuperAdmin(original.companyId);
                      yield fetchCompanies();
                    } catch (error) {
                      showMessage(`Could not enable/disable company emails. ${nestErrorMessage(error)}`, "error");
                    } finally {
                      setLoading(false);
                    }
                  }),
                  sizeVariant: "small",
                  colorVariant: hasEmailsDisabled ? "primary" : "secondary",
                  children: hasEmailsDisabled ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
                    /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize)),
                    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "Disable test mode" })
                  ] }) : /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Enable test mode" })
                }
              )
            }
          );
        }
      }
    ],
    [showMessage, fetchCompanies]
  );
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, children: /* @__PURE__ */ jsx(HelperCompanyRouter, { loading, users, companies }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_ROUTE, children: /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        TopHeader,
        {
          title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Helper page" }),
          showBack: true,
          backPath: SUPER_ADMIN_USERS
        }
      ),
      /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              handleChange: (e) => {
                setSearchInput(e.target.value);
              },
              placeholder: "Search",
              style: { minWidth: "200px" }
            }
          ),
          companiesWithTestModeEnabled.length > 0 && /* @__PURE__ */ jsx(
            TooltipPopper,
            {
              position: "bottom",
              title: /* @__PURE__ */ jsxs("div", { children: [
                /* @__PURE__ */ jsx("p", { children: "The following companies have test mode enabled:" }),
                /* @__PURE__ */ jsx("ul", { children: companiesWithTestModeEnabled.map((i) => /* @__PURE__ */ jsxs("li", { children: [
                  i.companyId,
                  " - ",
                  i.name
                ] })) })
              ] }),
              children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
                /* @__PURE__ */ jsx(Mistake, { width: 25, height: 25 }),
                /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: `${companiesWithTestModeEnabled.length} compan${companiesWithTestModeEnabled.length === 1 ? "y" : "ies"} ha${companiesWithTestModeEnabled.length === 1 ? "s" : "ve"} test mode enabled` })
              ] })
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { width: "100%", mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: filteredCompanies,
            columnData: columns,
            loading,
            rowClick: handleRowClick,
            hidePagination: true
          }
        ) })
      ] })
    ] }) })
  ] });
};
