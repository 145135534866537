"use strict";
import { DefaultApprovalRule } from "@v2/feature/approval-rule/approval-rule.interface";
import { capitalize } from "lodash";
const transformString = (string, toLower = true) => {
  if (!string) return "";
  const aux = string.replace(/\s/g, "").replaceAll(":", "");
  return toLower ? aux.toLowerCase() : aux;
};
export const translateUserFamilyMemberType = (type, polyglot) => {
  if (polyglot.has(`UserFamilyMemberType.${type}`)) return polyglot.t(`UserFamilyMemberType.${type}`);
  return type;
};
export const translatePermissionCategory = (category, polyglot) => {
  if (polyglot.has(`PermissionCategory.${transformString(category)}`))
    return polyglot.t(`PermissionCategory.${transformString(category)}`);
  return category;
};
export const translatePermissionCategoryDesc = (desc, polyglot) => {
  if (polyglot.has(`PermissionCategoryDesc.${transformString(desc)}`))
    return polyglot.t(`PermissionCategoryDesc.${transformString(desc)}`);
  return desc;
};
export const translateCustomField = (field, polyglot) => {
  if (polyglot.has(`CustomField.${transformString(field)}`)) return polyglot.t(`CustomField.${transformString(field)}`);
  return field;
};
export const translateCustomFieldCategory = (category, polyglot) => {
  if (polyglot.has(`CustomFieldCategory.${transformString(category)}`)) {
    return polyglot.t(`CustomFieldCategory.${transformString(category)}`);
  }
  return category;
};
export const translateAbsenceStatuses = (status, polyglot) => {
  if (polyglot.has(`AbsenceStatus.${transformString(status)}`))
    return polyglot.t(`AbsenceStatus.${transformString(status)}`);
  return status;
};
export const translategetGenderOptions = (gender, polyglot) => {
  if (polyglot.has(`getGenderOptions.${transformString(gender)}`))
    return polyglot.t(`getGenderOptions.${transformString(gender)}`);
  return gender;
};
export const translategetEmploymentTypeOptions = (type, polyglot) => {
  if (polyglot.has(`getEmploymentTypeOptions.${transformString(type)}`))
    return polyglot.t(`getEmploymentTypeOptions.${transformString(type)}`);
  return type;
};
export const translategetContractTypeOptions = (type, polyglot) => {
  if (polyglot.has(`getContractTypeOptions.${transformString(type)}`))
    return polyglot.t(`getContractTypeOptions.${transformString(type)}`);
  return type;
};
export const translateUserProfileLifecycleCard = (status, polyglot) => {
  if (polyglot.has(`UserLifecycleStatuses.${transformString(status)}`))
    return polyglot.t(`UserLifecycleStatuses.${transformString(status)}`);
  return status;
};
export const translategetAllChangeTypeOptions = (option, polyglot) => {
  if (polyglot.has(`AllChangeTypeOptions.${transformString(option)}`))
    return polyglot.t(`AllChangeTypeOptions.${transformString(option)}`);
  return option;
};
export const translatePaySchedule = (schedule, polyglot) => {
  if (polyglot.has(`PaySchedules.${transformString(schedule)}`))
    return polyglot.t(`PaySchedules.${transformString(schedule)}`);
  return schedule;
};
export const translateequityTypeOptions = (option, polyglot) => {
  if (polyglot.has(`equityTypeOptions.${transformString(option)}`))
    return polyglot.t(`equityTypeOptions.${transformString(option)}`);
  return option;
};
export const translateDocumentStatus = (status, polyglot) => {
  if (polyglot.has(`DocumentStatus.${transformString(status)}`))
    return polyglot.t(`DocumentStatus.${transformString(status)}`);
  return status;
};
export const translateColumnsOptions = (col, polyglot) => {
  if (polyglot.has(`ColumnsOptions.${transformString(col)}`))
    return capitalize(polyglot.t(`ColumnsOptions.${transformString(col)}`));
  return col;
};
export const translatePeopleDirectoryPage = (col, polyglot) => {
  if (polyglot.has(`PeopleDirectoryPage.${transformString(col)}`))
    return capitalize(polyglot.t(`PeopleDirectoryPage.${transformString(col)}`));
  return col;
};
export const translatePageFilters = (option, polyglot) => {
  if (typeof (option == null ? void 0 : option.value) === "number") return polyglot.t(option.label);
  const filter = option.value;
  if (polyglot.has(`PageFilters.${transformString(filter)}`))
    return capitalize(polyglot.t(`PageFilters.${transformString(filter)}`));
  if (polyglot.has(filter)) return polyglot.t(filter);
  return filter;
};
export const translatePageFilterSet = (name, polyglot) => {
  if (polyglot.has(`PageFilters.${transformString(name)}`))
    return capitalize(polyglot.t(`PageFilters.${transformString(name)}`));
  return name;
};
export const translateCustomBenefitCategory = (category, polyglot) => {
  if (polyglot.has(`BenefitModule.CustomBenefitCategory.${transformString(category)}`))
    return capitalize(polyglot.t(`BenefitModule.CustomBenefitCategory.${transformString(category)}`));
  return category;
};
export const translateCustomBenefitType = (type, polyglot) => {
  if (polyglot.has(`BenefitModule.CustomBenefitType.${transformString(type)}`))
    return capitalize(polyglot.t(`BenefitModule.CustomBenefitType.${transformString(type)}`));
  return type;
};
export const translateCustomBenefitAllowanceType = (type, polyglot) => {
  if (polyglot.has(`BenefitModule.CustomBenefitAllowanceType.${transformString(type)}`))
    return capitalize(polyglot.t(`BenefitModule.CustomBenefitAllowanceType.${transformString(type)}`));
  return type;
};
export const translateDomainTypes = (type, polyglot) => {
  if (polyglot.has(`BenefitModule.DomainTypes.${transformString(type)}`))
    return polyglot.t(`BenefitModule.DomainTypes.${transformString(type)}`);
  return type;
};
export const translateUserInsuranceDependants = (dependants, polyglot) => {
  if (polyglot.has(`BenefitModule.UserInsuranceDependants.${transformString(dependants)}`))
    return polyglot.t(`BenefitModule.UserInsuranceDependants.${transformString(dependants)}`);
  return dependants;
};
export const translatePaymentStatus = (status, polyglot) => {
  if (polyglot.has(`PaymentStatus.${transformString(status)}`))
    return polyglot.t(`PaymentStatus.${transformString(status)}`);
  return status;
};
export const translateAbsencePolicyTenureAllocation = (tenureAllocation, polyglot) => {
  if (polyglot.has(`AbsenceModule.AbsencePolicyTenureAllocation.${transformString(tenureAllocation)}`))
    return polyglot.t(`AbsenceModule.AbsencePolicyTenureAllocation.${transformString(tenureAllocation)}`);
  return tenureAllocation;
};
export const translateAbsencePolicyTenureAllocationDescription = (tenureAllocation, polyglot) => {
  if (polyglot.has(`AbsenceModule.AbsencePolicyTenureAllocationDescription.${transformString(tenureAllocation)}`))
    return polyglot.t(`AbsenceModule.AbsencePolicyTenureAllocationDescription.${transformString(tenureAllocation)}`);
  return tenureAllocation;
};
export const translateAbsencePolicyAccrualCliff = (accrualCliff, polyglot) => {
  if (polyglot.has(`AbsenceModule.AccrualCliff.${transformString(accrualCliff)}`))
    return polyglot.t(`AbsenceModule.AccrualCliff.${transformString(accrualCliff)}`);
  return accrualCliff;
};
export const translateUserCustomBenefitRequestType = (type, polyglot) => {
  if (polyglot.has(`BenefitModule.UserCustomBenefitRequestType.${transformString(type)}`))
    return polyglot.t(`BenefitModule.UserCustomBenefitRequestType.${transformString(type)}`);
  return type;
};
export const translateJSMonthNo = (month, polyglot) => {
  const monthStr = String(month);
  if (polyglot.has(`getMonthOptionsByJSMonthNo.${transformString(monthStr)}`))
    return polyglot.t(`getMonthOptionsByJSMonthNo.${transformString(monthStr)}`);
  return monthStr;
};
export const translateDefaultApprovalRule = (name, polyglot) => {
  switch (name) {
    case DefaultApprovalRule.AutoApprove:
      return polyglot.t("ApprovalRuleModule.DefaultApprovalRuleDescription.autoapprove");
    case DefaultApprovalRule.Admin:
      return polyglot.t("ApprovalRuleModule.DefaultApprovalRuleDescription.domainAdmin");
    case DefaultApprovalRule.Manager:
      return polyglot.t("ApprovalRuleModule.DefaultApprovalRuleDescription.manager");
    case DefaultApprovalRule.ManagerPlusManager:
      return polyglot.t("ApprovalRuleModule.DefaultApprovalRuleDescription.managerAndManagersManager");
    default:
      return name;
  }
};
export const translateManagerPermissionGroupReportsLevel = (level, polyglot) => {
  if (polyglot.has(`ManagerPermissionGroup.Levels.${level}`))
    return polyglot.t(`ManagerPermissionGroup.Levels.${level}`);
  return level.toString();
};
