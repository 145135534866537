"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useCallback } from "react";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { AttendanceSettingsApprovalSection } from "@v2/feature/attendance/settings/sections/attendance-settings-approval.section";
import { AttendanceSettingsGeneralSection } from "@v2/feature/attendance/settings/sections/attendance-settings-general.section";
import { AttendanceSettingsMembersSection } from "@v2/feature/attendance/settings/sections/attendance-settings-members.section";
import { AttendanceSettingsPayrollSection } from "@v2/feature/attendance/settings/sections/attendance-settings-payroll.section";
import { AttendanceSettingsScheduleSection } from "@v2/feature/attendance/settings/sections/attendance-settings-schedule.section";
import { AttendanceSettingsTrackingSection } from "@v2/feature/attendance/settings/sections/attendance-settings-tracking.section";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import {
  SETTINGS_ATTENDANCE_SCHEDULE_APPROVAL_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_MEMBERS_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_PAYROLL_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_TRACKING_ROUTE
} from "@/lib/routes";
export const getPageConfig = (scheduleId, inAnyPayroll, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("General.general"),
          stub: "general",
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE, { scheduleId })
        },
        {
          title: polyglot.t("AttendanceDomain.schedule"),
          stub: "general",
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE, { scheduleId })
        },
        {
          title: polyglot.t("AttendanceSchedule.tracking"),
          stub: "general",
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_TRACKING_ROUTE, { scheduleId })
        },
        {
          title: polyglot.t("AttendanceSchedule.approval"),
          stub: "general",
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_APPROVAL_ROUTE, { scheduleId })
        },
        {
          title: polyglot.t("General.members"),
          stub: "general",
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_MEMBERS_ROUTE, { scheduleId })
        },
        {
          title: polyglot.t("AttendanceSchedule.payroll"),
          stub: "general",
          path: generatePath(SETTINGS_ATTENDANCE_SCHEDULE_PAYROLL_ROUTE, { scheduleId }),
          isHidden: !inAnyPayroll
        }
      ]
    }
  ];
};
export const AttendanceScheduleRouter = ({
  refreshSchedules,
  sites,
  approvalRules,
  refreshApprovalRules,
  jobTypes,
  attendancePayMultipliers
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const params = useParams();
  const scheduleId = Number(params.scheduleId);
  const {
    data: schedule,
    mutate: refreshAttendanceSchedule,
    isLoading
  } = useApiClient(AttendanceEndpoints.getAttendanceScheduleById(scheduleId), { suspense: false });
  const { companyConfig } = useCompanyConfigState();
  const refreshSchedule = useCallback(() => __async(void 0, null, function* () {
    if (refreshAttendanceSchedule) yield refreshAttendanceSchedule();
  }), [refreshAttendanceSchedule]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_a = schedule == null ? void 0 : schedule.name) != null ? _a : "",
        subtitle: polyglot.t("AttendanceDomain.schedule"),
        pageConfig: getPageConfig(scheduleId, companyConfig.inPayroll || companyConfig.inGlobalPayroll, polyglot),
        backPath: SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, children: schedule && /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        AttendanceSettingsGeneralSection,
        {
          schedule,
          refreshSchedule,
          refreshSchedules
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_SCHEDULE_ROUTE, children: /* @__PURE__ */ jsx(
        AttendanceSettingsScheduleSection,
        {
          schedule,
          refreshSchedule,
          refreshAllSchedules: refreshSchedules,
          jobTypes,
          attendancePayMultipliers
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_TRACKING_ROUTE, children: /* @__PURE__ */ jsx(AttendanceSettingsTrackingSection, { schedule, refreshSchedule, sites }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_APPROVAL_ROUTE, children: /* @__PURE__ */ jsx(
        AttendanceSettingsApprovalSection,
        {
          schedule,
          refreshSchedule,
          approvalRules,
          refreshApprovalRules
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_MEMBERS_ROUTE, children: /* @__PURE__ */ jsx(AttendanceSettingsMembersSection, { schedule }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_PAYROLL_ROUTE, children: /* @__PURE__ */ jsx(AttendanceSettingsPayrollSection, { schedule, refreshSchedule }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE })
    ] }) })
  ] });
};
