"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { RootStyle } from "@v2/styles/root.styles";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { PayItemEndpoints } from "@v2/feature/payroll/features/pay-item/pay-item.api";
import { UpcomingPayItemsTable } from "@/component/dashboard/userDetails/pay-items/components/upcoming-pay-items-table.component";
import { useCallback } from "react";
export const UserPayItemsPage = ({ userId }) => {
  const {
    data: userOneOffPayments,
    mutate: refreshPI,
    isLoading: loading
  } = useApiClient(PayItemEndpoints.getUserOneOffPayments(userId), { suspense: false });
  const { polyglot } = usePolyglot();
  const refreshUpcoming = useCallback(() => __async(void 0, null, function* () {
    if (refreshPI) yield refreshPI();
  }), [refreshPI]);
  const combinedOneOffPayments = [];
  if ((userOneOffPayments == null ? void 0 : userOneOffPayments.upcoming) && (userOneOffPayments == null ? void 0 : userOneOffPayments.upcoming.length) > 0) {
    combinedOneOffPayments.push(
      ...userOneOffPayments == null ? void 0 : userOneOffPayments.upcoming.map((p) => {
        return __spreadProps(__spreadValues({}, p), { paidImpliedStatus: false });
      })
    );
  }
  if ((userOneOffPayments == null ? void 0 : userOneOffPayments.history) && (userOneOffPayments == null ? void 0 : userOneOffPayments.history.length) > 0) {
    combinedOneOffPayments.push(
      ...userOneOffPayments == null ? void 0 : userOneOffPayments.history.map((p) => {
        return __spreadProps(__spreadValues({}, p), { paidImpliedStatus: true });
      })
    );
  }
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PayItemModule.payItems") }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { sx: { mt: spacing.m20 }, loading, children: /* @__PURE__ */ jsx(
      UpcomingPayItemsTable,
      {
        title: "",
        userId,
        oneOffPayments: combinedOneOffPayments.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
        refresh: refreshUpcoming,
        showAdd: true,
        showEdit: true
      }
    ) })
  ] });
};
