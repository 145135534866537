"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { DeliveryMethod, DevicePossessionType } from "@v2/feature/device/device.interface";
import { DeliveryMethodsOptions } from "@v2/feature/device/device.util";
import { UserAddressAPI } from "@v2/feature/user/features/user-forms/user-address/user-address.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { formatAddress } from "@v2/util/user-data.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ChangeOwnerUserForm = ({ currentDevicePossession, changeOwner }) => {
  const [loading, setLoading] = useState(false);
  const [senderAddress, setSenderAddress] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [customAccountName, setCustomAccountName] = useState(false);
  const { hasScopes, getScopesContext } = useScopes();
  const [showMessage] = useMessage();
  const validationSchema = yup.object({
    deliveryMethod: yup.string().required(),
    possessionId: yup.number().required("New user is required"),
    senderAddress: yup.string().when("deliveryMethod", {
      is: DeliveryMethod.ShippingToEmployee,
      then: (schema) => schema.required("Sender Address is required"),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    deliveryAddress: yup.string().when("deliveryMethod", {
      is: DeliveryMethod.ShippingToEmployee,
      then: (schema) => schema.required("Sender Address is required"),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    accountName: yup.string().nullable()
  });
  const formik = useFormik({
    initialValues: {
      deliveryMethod: DeliveryMethod.ShippingToEmployee,
      possessionId: 0,
      senderAddress,
      deliveryAddress: "",
      accountName: null
    },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      const { deliveryMethod, possessionId, senderAddress: senderAddress2, deliveryAddress, accountName } = values;
      if (currentDevicePossession.possessionType === DevicePossessionType.User && currentDevicePossession.possessionId === possessionId) {
        showMessage("The sender and the receiver should not be the same.", "error");
        return;
      }
      if (!possessionId) {
        showMessage("No receiver has been selected.", "error");
        return;
      }
      const transitData = {
        deliveryMethod,
        possessionId,
        senderAddress: deliveryMethod === DeliveryMethod.ShippingToEmployee ? senderAddress2 : null,
        deliveryAddress: deliveryMethod === DeliveryMethod.ShippingToEmployee ? deliveryAddress : null,
        accountName
      };
      setLoading(true);
      yield changeOwner(currentDevicePossession.id, transitData);
      setLoading(false);
    }),
    enableReinitialize: true,
    validateOnMount: true
  });
  const fetchOwnerAddress = useCallback(
    (devicePossession) => __async(void 0, null, function* () {
      try {
        if (devicePossession.possessionType === DevicePossessionType.User) {
          const hasReadAddressScope = hasScopes(
            ["user.address:read"],
            getScopesContext({ userId: devicePossession.possessionId })
          );
          if (!hasReadAddressScope) return "";
          const userAddress = yield UserAddressAPI.findByUserId(devicePossession.possessionId);
          if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
            const effectiveAddress = userAddress.effectiveRecord;
            return formatAddress(effectiveAddress);
          }
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
      return "";
    }),
    [getScopesContext, hasScopes, showMessage]
  );
  const fetchReceiverAddress = useCallback(
    (possessionId) => __async(void 0, null, function* () {
      try {
        const hasReadAddressScope = hasScopes(["user.address:read"], getScopesContext({ userId: possessionId }));
        if (!hasReadAddressScope) return "";
        const userAddress = yield UserAddressAPI.findByUserId(possessionId);
        if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
          const effectiveAddress = userAddress.effectiveRecord;
          return formatAddress(effectiveAddress);
        }
      } catch (error) {
        console.error("Could not retrieve user address.");
      }
      return "";
    }),
    [getScopesContext, hasScopes]
  );
  const setOwnerAddress = useCallback(() => __async(void 0, null, function* () {
    const ownerAddress = yield fetchOwnerAddress(currentDevicePossession);
    setSenderAddress(ownerAddress);
  }), [fetchOwnerAddress, currentDevicePossession]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      yield setOwnerAddress();
    }))();
  }, [setOwnerAddress]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: "company",
        value: formik.values.possessionId,
        onChange: (_, x) => __async(void 0, null, function* () {
          var _a;
          const userId = (_a = x == null ? void 0 : x.value) != null ? _a : null;
          yield formik.setFieldValue("possessionId", userId);
          if (userId) {
            const receiverAddress2 = yield fetchReceiverAddress(userId);
            setReceiverAddress(receiverAddress2);
            if (formik.values.deliveryMethod !== DeliveryMethod.Handover) {
              yield formik.setFieldValue("deliveryAddress", receiverAddress2);
            }
          }
        }),
        label: "Employee",
        error: Boolean(formik.errors.possessionId),
        helperText: formik.errors.possessionId
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "deliveryMethod",
        label: "Delivery method",
        options: DeliveryMethodsOptions.filter((o) => o.value !== DeliveryMethod.ShippingToSite),
        value: formik.values.deliveryMethod,
        compareValue: formik.values.deliveryMethod,
        error: !!formik.errors.deliveryMethod && formik.touched.deliveryMethod,
        onChange: (e) => {
          var _a;
          formik.handleChange(e);
          if (((_a = e.target) == null ? void 0 : _a.value) === DeliveryMethod.Handover) {
            formik.setFieldValue("deliveryAddress", "Handover");
            formik.setFieldValue("senderAddress", "Handover");
          } else {
            formik.setFieldValue("senderAddress", senderAddress != null ? senderAddress : "");
            formik.setFieldValue("deliveryAddress", receiverAddress != null ? receiverAddress : "");
          }
        },
        helperText: formik.touched.deliveryMethod && formik.errors.deliveryMethod
      }
    ),
    formik.values.deliveryMethod === DeliveryMethod.Handover && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "customAccountName",
        label: "Custom device account name",
        labelTooltip: "Set a custom device account name for a device controlled by automated enrolment. If not set user email will be used.",
        checked: customAccountName,
        onChange: (e, checked) => __async(void 0, null, function* () {
          setCustomAccountName(checked);
          if (!checked) {
            yield formik.setFieldValue("accountName", null);
          }
        })
      }
    ),
    formik.values.deliveryMethod === DeliveryMethod.Handover && customAccountName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Device account name for automated enrolment device",
        name: "accountName",
        value: formik.values.accountName,
        onChange: formik.handleChange,
        error: formik.touched.accountName && Boolean(formik.errors.accountName),
        helperText: formik.touched.accountName && formik.errors.accountName,
        size: "small",
        endAdornment: "none",
        placeholder: "TestAccount",
        tooltip: "Set a custom account name for a device controlled by automated enrolment. If not set user email will be used."
      }
    ),
    formik.values.deliveryMethod === DeliveryMethod.ShippingToEmployee && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Delivery Address",
          name: "deliveryAddress",
          value: formik.values.deliveryAddress,
          onChange: formik.handleChange,
          error: formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress),
          helperText: formik.touched.deliveryAddress && formik.errors.deliveryAddress,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Sender Address",
          name: "senderAddress",
          value: formik.values.senderAddress,
          onChange: formik.handleChange,
          error: formik.touched.senderAddress && Boolean(formik.errors.senderAddress),
          helperText: formik.touched.senderAddress && formik.errors.senderAddress,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Change owner",
        colorVariant: "primary",
        sizeVariant: "medium",
        type: "submit",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
