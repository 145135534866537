"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { SuperAdminInsurancePoliciesPage } from "@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-policies.page";
import { SuperAdminInsurancePolicyDetailsPage } from "@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-policy-details.page";
import { Redirect, Route, Switch } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SUPER_ADMIN_INSURANCE_POLICIES_ROUTE, SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE } from "@/lib/routes";
export const SuperAdminInsurancePoliciesRouter = ({
  companies,
  users,
  pageConfig
}) => {
  const [loading, setLoading] = useState(true);
  const [policies, setPolicies] = useState([]);
  const [showMessage] = useMessage();
  const refreshPolicies = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const policies2 = yield InsuranceAPI.getAllInsurancePoliciesAsSuperAdmin();
      setPolicies(policies2);
    } catch (error) {
      showMessage(`Could not fetch insurance policies. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refreshPolicies();
  }, [refreshPolicies]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE, children: /* @__PURE__ */ jsx(
      SuperAdminInsurancePolicyDetailsPage,
      {
        companies,
        users,
        policies,
        pageConfig,
        loading
      }
    ) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_INSURANCE_POLICIES_ROUTE, children: /* @__PURE__ */ jsx(
      SuperAdminInsurancePoliciesPage,
      {
        companies,
        policies,
        refreshPolicies,
        pageConfig,
        loading
      }
    ) }),
    /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_INSURANCE_POLICIES_ROUTE })
  ] });
};
