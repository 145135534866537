"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ReportsTestPage = () => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { polyglot } = usePolyglot();
  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getTestReportsFiltersOptions(), {
    suspense: false
  });
  const [showMessage] = useMessage();
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [reportResponse, setReportResponse] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const generateReport = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsGenerating(true);
      const response = yield ReportsAPI.generateTestReport(selectedColumns, selectedFilters);
      setReportResponse(response);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
    setIsGenerating(false);
  }), [selectedColumns, selectedFilters, showMessage]);
  const columns = useMemo(() => {
    var _a2;
    if (!((_a2 = reportResponse == null ? void 0 : reportResponse.result) == null ? void 0 : _a2.data) || !reportResponse.result.data[0]) return [];
    const firstRow = reportResponse.result.data[0];
    const columnsList = Object.keys(
      firstRow
    ).map((key) => {
      var _a3, _b2, _c2;
      return {
        id: key,
        size: 120,
        enableSorting: true,
        sortingFn: (a, b) => {
          var _a4;
          return ((_a4 = firstRow[key]) == null ? void 0 : _a4.type) === "number" ? sortNumeric(a, b, (item) => {
            var _a5;
            return (_a5 = item[key].raw) != null ? _a5 : 0;
          }) : sortString(a, b, (item) => {
            var _a5;
            return (_a5 = item[key].raw) != null ? _a5 : "";
          });
        },
        accessorFn: (row) => row,
        header: () => {
          var _a4, _b3, _c3;
          return (_c3 = (_b3 = (_a4 = reportResponse.result) == null ? void 0 : _a4.header) == null ? void 0 : _b3.labels[key]) != null ? _c3 : key.split("__").join("->");
        },
        cell: ({ row: { original } }) => {
          if (!original[key] || original[key].value === null || original[key].value === void 0) return "";
          return /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: original[key].value });
        },
        order: (_c2 = (_b2 = (_a3 = reportResponse.result) == null ? void 0 : _a3.header) == null ? void 0 : _b2.order[key]) != null ? _c2 : 0
      };
    });
    columnsList.sort((a, b) => a.order - b.order);
    return columnsList;
  }, [reportResponse]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("ReportsPage.reports") }),
          /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { color: themeColors.Grey }, children: "(TEST PAGE)" })
        ] }),
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            fullWidth: true,
            colorVariant: "secondary",
            sizeVariant: "small",
            onClick: generateReport,
            loading: isGenerating,
            children: "Generate report"
          }
        ),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoading, sx: __spreadValues({}, spacing.pt20), children: [
      filtersAndColumns && /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: "TODO: Columns and Filters list is retrieved. Implement new columns and filters selection here" }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { mt: "15px", width: "50%" }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2, alignItems: "center", mb: 1 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Selected columns" }),
            /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "text", onClick: () => setSelectedColumns({}), children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "clear" }) })
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: { overflowY: "auto", height: "200px" }, children: /* @__PURE__ */ jsx("pre", { style: { fontSize: "14px" }, children: JSON.stringify(selectedColumns, null, 2) }) })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { mt: "20px", width: "50%" }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2, alignItems: "center", mb: 1 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Selected filters" }),
            /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "text", onClick: () => setSelectedFilters({}), children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "clear" }) })
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: { overflowY: "auto", height: "200px" }, children: /* @__PURE__ */ jsx("pre", { style: { fontSize: "14px" }, children: JSON.stringify(selectedFilters, null, 2) }) })
        ] })
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mt: "15px" }, children: "RESULTS" }),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: [...(_b = (_a = reportResponse == null ? void 0 : reportResponse.result) == null ? void 0 : _a.data) != null ? _b : []],
          columnData: columns,
          fixedLastColumn: false
        }
      ),
      /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mt: "15px" }, children: "GENERATED TABLES TREE" }),
      (reportResponse == null ? void 0 : reportResponse.table) ? /* @__PURE__ */ jsx(TreeView, { children: /* @__PURE__ */ jsx(TableTree, { table: reportResponse.table, level: 1 }) }) : /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey, my: "10px" }, children: "No report has been generated yet..." }) }),
      /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mt: "25px" }, children: "RESPONSE JSON DATA" }),
      /* @__PURE__ */ jsxs(Accordion, { children: [
        /* @__PURE__ */ jsx(AccordionSummary, { expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}), children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "RESULT" }) }),
        /* @__PURE__ */ jsx(AccordionDetails, { children: /* @__PURE__ */ jsx("pre", { style: { fontSize: "14px" }, children: JSON.stringify((_c = reportResponse == null ? void 0 : reportResponse.result) != null ? _c : {}, null, 2) }) })
      ] }),
      /* @__PURE__ */ jsxs(Accordion, { children: [
        /* @__PURE__ */ jsx(AccordionSummary, { expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}), children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "QUERIES" }) }),
        /* @__PURE__ */ jsx(AccordionDetails, { children: !!(reportResponse == null ? void 0 : reportResponse.query) && ((_d = reportResponse == null ? void 0 : reportResponse.query) == null ? void 0 : _d.length) > 0 ? reportResponse.query.map((query, index) => /* @__PURE__ */ jsxs(Box, { sx: { mb: "10px" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title5", sx: { mb: "5px" }, children: index === 0 ? "USER QUERY" : index === 1 ? "CUSTOM FIELDS DATA QUERY" : "CUSTOM FIELDS FILTER QUERY" }),
          /* @__PURE__ */ jsx("code", { style: { fontSize: "14px" }, children: JSON.stringify(query != null ? query : {}, null, 2) })
        ] }, index)) : null })
      ] }),
      /* @__PURE__ */ jsxs(Accordion, { children: [
        /* @__PURE__ */ jsx(AccordionSummary, { expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}), children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "TABLE" }) }),
        /* @__PURE__ */ jsx(AccordionDetails, { children: /* @__PURE__ */ jsx("pre", { style: { fontSize: "14px" }, children: JSON.stringify((_e = reportResponse == null ? void 0 : reportResponse.table) != null ? _e : {}, null, 2) }) })
      ] }),
      /* @__PURE__ */ jsxs(Accordion, { children: [
        /* @__PURE__ */ jsx(AccordionSummary, { expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}), children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "CUSTOM FIELDS" }) }),
        /* @__PURE__ */ jsxs(AccordionDetails, { children: [
          /* @__PURE__ */ jsxs(Box, { sx: { mb: "10px" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title5", sx: { mb: "5px" }, children: "FILTERED USERS BY CUSTOM FIELDS" }),
            /* @__PURE__ */ jsx("pre", { style: { fontSize: "14px" }, children: JSON.stringify((_f = reportResponse == null ? void 0 : reportResponse.customFieldsFilteredUsers) != null ? _f : {}, null, 2) })
          ] }),
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title5", sx: { mb: "5px" }, children: "CUSTOM FIELDS BY USER ID" }),
            /* @__PURE__ */ jsx("pre", { style: { fontSize: "14px" }, children: JSON.stringify((_g = reportResponse == null ? void 0 : reportResponse.customFieldsData) != null ? _g : {}, null, 2) })
          ] })
        ] })
      ] })
    ] })
  ] });
};
const LevelColor = {
  1: "#44ae4f",
  2: "#347dAF",
  3: "#7d8028"
};
const TableTree = ({
  table,
  level
}) => {
  var _a, _b;
  return /* @__PURE__ */ jsx(
    TreeItem,
    {
      nodeId: (_a = table.stub) != null ? _a : "root",
      collapseIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}),
      expandIcon: /* @__PURE__ */ jsx(ChevronRightIcon, {}),
      label: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: 2 }, children: table.tableName }),
        table.attributes && table.attributes.length > 0 && /* @__PURE__ */ jsxs(Accordion, { sx: { bgcolor: "#dedede" }, children: [
          /* @__PURE__ */ jsx(AccordionSummary, { sx: { minHeight: "15px", maxHeight: "15px" }, expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}), children: /* @__PURE__ */ jsx(Typography, { variant: "title5", children: "attributes" }) }),
          /* @__PURE__ */ jsx(AccordionDetails, { children: table.attributes.map((att, index) => /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            att.value,
            att.forcePushed ? " (forcePushed)" : ""
          ] }, index)) })
        ] }),
        table.filters && table.filters.length > 0 && /* @__PURE__ */ jsxs(Accordion, { sx: { bgcolor: "#dedede" }, children: [
          /* @__PURE__ */ jsx(
            AccordionSummary,
            {
              sx: { minHeight: "15px", maxHeight: "15px" },
              expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}),
              "aria-controls": "panel1a-content",
              id: "panel1a-header",
              children: /* @__PURE__ */ jsx(Typography, { variant: "title5", children: "filters" })
            }
          ),
          /* @__PURE__ */ jsx(AccordionDetails, { children: table.filters.map((filter, index) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: filter.columnName }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: filter.op }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: JSON.stringify(filter.value) })
          ] }, index)) })
        ] })
      ] }),
      sx: { bgcolor: LevelColor[level], p: 2, m: 2, borderRadius: "15px" },
      children: (table == null ? void 0 : table.include) && ((_b = table.include) == null ? void 0 : _b.length) > 0 ? table.include.map((child, index) => /* @__PURE__ */ jsx(TableTree, { table: child, level: level + 1 }, index)) : null
    }
  );
};
