"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DashboardAPI } from "@/v2/feature/dashboard/dashboard.api";
import { getDateFormation, getDayMonthFormation } from "@/v2/feature/dashboard/dashboard.util";
import { GifSelector } from "@/v2/feature/dashboard/features/sections/user-calendar/components/gifs-search.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserEventDetailDrawer = ({
  selectedEvent,
  isOpen,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [noteString, setNoteString] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedGIF, setSelectedGIF] = useState(null);
  const handleButtonClick = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (!noteString) setError(true);
      if (selectedEvent && noteString) {
        const body = {
          userId: selectedEvent == null ? void 0 : selectedEvent.userId,
          type: selectedEvent == null ? void 0 : selectedEvent.type,
          note: noteString,
          gifSrc: selectedGIF
        };
        yield DashboardAPI.sendWishes(body);
        setIsOpen(false);
        showMessage(polyglot.t("UserEventDetailDrawer.successMessages.send"), "success");
      }
    } catch (error2) {
      showMessage(
        polyglot.t("UserEventDetailDrawer.errorMessages.send", { errorMessage: nestErrorMessage(error2) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  const differenceInCalendarYears = (date1, date2) => {
    return date1.getFullYear() - date2.getFullYear();
  };
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setIsOpen(false);
      },
      afterClose: () => {
        setNoteString(null);
        setSelectedGIF(null);
      },
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: selectedEvent == null ? void 0 : selectedEvent.type }),
          /* @__PURE__ */ jsx(
            ViewItem,
            {
              label: polyglot.t("UserEventDetailDrawer.employee"),
              value: (selectedEvent == null ? void 0 : selectedEvent.userId) ? /* @__PURE__ */ jsx(
                UserCell,
                {
                  avatarSize: "xxxsmall",
                  userId: selectedEvent == null ? void 0 : selectedEvent.userId,
                  sx: { gap: spacing.g5 },
                  nameSx: __spreadValues({}, themeFonts.title4)
                }
              ) : "-"
            }
          ),
          selectedEvent && /* @__PURE__ */ jsx(
            ViewItem,
            {
              label: polyglot.t("UserEventDetailDrawer.date"),
              value: ((_a = selectedEvent == null ? void 0 : selectedEvent.type) == null ? void 0 : _a.toLowerCase()) === "birthday" ? getDayMonthFormation(selectedEvent.startDate) : (_b = getDateFormation(selectedEvent.startDate, selectedEvent.endDate)) != null ? _b : "-"
            }
          ),
          ((_d = (_c = selectedEvent == null ? void 0 : selectedEvent.type) == null ? void 0 : _c.toLowerCase()) == null ? void 0 : _d.includes("anniversary")) && /* @__PURE__ */ jsx(
            ViewItem,
            {
              label: polyglot.t("UserEventDetailDrawer.lengthService"),
              value: (_e = `${differenceInCalendarYears(
                new LocalDate(selectedEvent.day).getDate(),
                new LocalDate(selectedEvent.startDate).getDate()
              )} ${differenceInCalendarYears(
                new LocalDate().getDate(),
                new LocalDate(selectedEvent.startDate).getDate()
              ) > 1 ? "years" : "year"}`) != null ? _e : "-"
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserEventDetailDrawer.sendANote") }),
        selectedGIF ? /* @__PURE__ */ jsxs("div", { style: { position: "relative" }, children: [
          /* @__PURE__ */ jsx(
            "button",
            {
              className: "event-short-button",
              style: {
                top: -5,
                position: "absolute",
                right: -10,
                background: themeColors.white,
                fill: themeColors.DarkGrey,
                zIndex: 2
              },
              onClick: () => setSelectedGIF(null),
              children: /* @__PURE__ */ jsx(TrashIcon, {})
            }
          ),
          /* @__PURE__ */ jsx("img", { src: selectedGIF, alt: "GIF", width: "360px", height: "320px" }, "1234")
        ] }) : /* @__PURE__ */ jsx(GifSelector, { type: (_f = selectedEvent == null ? void 0 : selectedEvent.type) != null ? _f : "Congratulations", setSelectedGIF }),
        /* @__PURE__ */ jsxs(FormControl, { error, fullWidth: true, children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              multiline: true,
              name: "note",
              label: polyglot.t("UserEventDetailDrawer.note"),
              value: noteString,
              type: "text",
              onChange: (e) => {
                setNoteString(e.target.value);
                setError(false);
              },
              endAdornment: "none"
            }
          ),
          error && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "darkRed", sx: { marginTop: spacing.m10 }, children: polyglot.t("UserEventDetailDrawer.pleaseAddAnote") })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            loading,
            colorVariant: "secondary",
            sizeVariant: "large",
            fullWidth: true,
            onClick: handleButtonClick,
            name: polyglot.t("UserEventDetailDrawer.sendCongrats")
          }
        ) })
      ] })
    }
  );
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: label }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value })
  ] });
};
