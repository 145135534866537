"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Typography } from "@v2/components/typography/typography.component";
import {
  documentBelongsToEveryone,
  documentHasOwner,
  DocumentsDomain,
  getDefaultNameForSingleUserDocument,
  getDocumentTypeListBasedOnAudience,
  getOwnersUserIds,
  getTypeState,
  OTHER_TYPE
} from "@v2/feature/documents/documents.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize, iconSize } from "@v2/styles/table.styles";
import dayjs from "dayjs";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { useParams } from "react-router-dom";
import { Subject } from "rxjs";
import { UploadInput } from "@/component/forms/UploadInput";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as InfoGrey } from "@/images/side-bar-icons/InfoGrey.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Subtract.svg";
import { BELONGS_TO_EVERYONE } from "@/lib/documents";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { MultiUserSelect } from "@/v2/components/forms/user-select/multi-user-select.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { ClearIconButton } from "@/v2/components/theme-components/clear-icon-button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { DocumentSettingsNewTypeForm } from "@/v2/feature/documents/components/document-settings-new-type-drawer.component";
import { DocumentAPI, DocumentEndpoints } from "@/v2/feature/documents/document.api";
import {
  DocumentUploadStatus
} from "@/v2/feature/documents/documents.interface";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { StyledTextfield } from "@/v2/styles/textfield.styles";
export const DocumentFormModal = ({
  mode,
  state,
  currentUserIsAdmin,
  dispatch,
  newDocumentTypes,
  onSubmit,
  users = [],
  canChangeOwnership = false,
  canSelectEveryone = false,
  setOpen,
  open,
  setLoading,
  getDocuments,
  setFilterValue,
  filterValue,
  onClose,
  afterClose,
  bulkUpload = false,
  bulkUploadDispatch
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v;
  const { polyglot } = usePolyglot();
  const { data: allTypes, mutate: refreshAllTypes } = useApiClient(
    open ? DocumentEndpoints.getTypesData() : { url: void 0 },
    {
      suspense: false
    }
  );
  const Views = [
    { name: polyglot.t("DocumentFormModal.companyDocuments"), value: "company" },
    { name: polyglot.t("DocumentFormModal.personalDocuments"), value: "personal" }
  ];
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [newTypeDrawerIsOpen, setNewTypeDrawerIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const [removalCandidateId, setRemovalCandidateId] = useState(void 0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const optionObject = useMemo(() => {
    return allTypes ? getDocumentTypeListBasedOnAudience(allTypes, currentUserIsAdmin, filterValue) : [];
  }, [allTypes, currentUserIsAdmin, filterValue]);
  const hasDocumentsAllScope = checkScopes(globalState.user, ["documents:all"], { userId: globalState.user.userId });
  const { userId: userIdParam } = useParams();
  const currentUserId = !!userIdParam ? Number(userIdParam) : void 0;
  const [showMessage] = useMessage();
  const belongsToOnlyYou = mode === "edit" && state && state.owners && state.owners.length === 1 && state.owners[0].value === globalState.user.userId;
  const isEditableOrRemovable = (canSelectEveryone || !documentBelongsToEveryone(state)) && state.type && ((_a = newDocumentTypes == null ? void 0 : newDocumentTypes.find((t) => {
    var _a2;
    return t.value === ((_a2 = state.type) == null ? void 0 : _a2.value);
  })) == null ? void 0 : _a.label) !== "Payslip";
  const [changeName$] = useState(() => new Subject());
  useEffect(
    () => pipe(
      changeName$,
      // TODO change this so that it's not a full debounce, and instead it accepts a value every {DEBOUNCE_TIME_MS}
      ($) => $.subscribe((value) => dispatch({ kind: "change_name", value })),
      (s) => () => s.unsubscribe()
    ),
    [changeName$, dispatch]
  );
  const [changeNote$] = useState(() => new Subject());
  useEffect(
    () => pipe(
      changeNote$,
      ($) => $.subscribe((value) => {
        dispatch({ kind: "change_note", value });
        if (bulkUpload && bulkUploadDispatch && state.fileUuid)
          bulkUploadDispatch({
            type: "UPDATE_DOCUMENT_PENDING",
            payload: { documentId: state.fileUuid, updatedData: { note: value } }
          });
      }),
      (s) => () => s.unsubscribe()
    ),
    [changeNote$, dispatch, bulkUploadDispatch, bulkUpload, state.fileUuid]
  );
  const [changeTypes$] = useState(() => new Subject());
  useEffect(
    () => pipe(
      changeTypes$,
      // TODO change this so that it's not a full debounce, and instead it accepts a value every {DEBOUNCE_TIME_MS}
      ($) => $.subscribe(
        (value) => dispatch({ kind: "change_type", type: value, keepOwnership: !canChangeOwnership })
      ),
      (s) => () => s.unsubscribe()
    ),
    [changeTypes$, dispatch, canChangeOwnership]
  );
  const allSelectedUserIds = getOwnersUserIds(state);
  const belongsToUserOptions = pipe(
    users,
    RA.filter((u) => !allSelectedUserIds.has(u.userId)),
    RA.map((u) => __spreadValues({ value: u.userId, label: u.displayName }, u))
  );
  const documentId = state.id;
  const hasDocumentsManagerScope = useMemo(() => {
    if (currentUserId) return checkScopes(globalState.user, ["documents:manager"], { userId: currentUserId });
    if (allSelectedUserIds.size > 0) {
      return Array.from(allSelectedUserIds).every(
        (eachUserId) => checkScopes(globalState.user, ["documents:manager"], { userId: eachUserId })
      );
    }
  }, [currentUserId, allSelectedUserIds, globalState.user]);
  const managerOrAdmin = useMemo(() => {
    return hasDocumentsAllScope || hasDocumentsManagerScope;
  }, [hasDocumentsAllScope, hasDocumentsManagerScope]);
  function handleRemoveClick(documentId2) {
    setRemovalCandidateId(documentId2);
    setIsRemovalModalOpen(true);
  }
  const removeDocument = () => __async(void 0, null, function* () {
    if (removalCandidateId) {
      setLoading == null ? void 0 : setLoading(true);
      try {
        yield DocumentAPI.deleteDocumentById(removalCandidateId);
        yield getDocuments == null ? void 0 : getDocuments();
        setOpen(false);
        showMessage(polyglot.t("DocumentFormModal.successMessages.remove"), "success");
      } catch (error) {
        showMessage(
          polyglot.t("DocumentFormModal.errorMessages.remove", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading == null ? void 0 : setLoading(false);
        setIsRemovalModalOpen(false);
        setOpen(false);
      }
    }
  });
  const commitChangesForDocument = () => {
    if (state.fileUuid && bulkUploadDispatch)
      bulkUploadDispatch({
        type: "COMMIT_DOCUMENT_UPDATE",
        payload: state.fileUuid
      });
  };
  const cancelPendingChangesForDocument = () => {
    if (state.fileUuid && bulkUploadDispatch)
      bulkUploadDispatch({
        type: "CANCEL_DOCUMENT_UPDATE",
        payload: state.fileUuid
      });
  };
  const updateSingleOwnershipForDocument = (v) => {
    const userForV = getCachedUserById(v.value);
    if (bulkUpload && bulkUploadDispatch && state.fileUuid) {
      const ownershipList = [
        {
          documentId: 0,
          User: {
            userId: v.value,
            firstName: userForV == null ? void 0 : userForV.firstName,
            lastName: userForV == null ? void 0 : userForV.lastName,
            displayName: userForV == null ? void 0 : userForV.displayName
          }
        }
      ];
      bulkUploadDispatch({
        type: "UPDATE_DOCUMENT_PENDING",
        payload: {
          documentId: state.fileUuid,
          updatedData: {
            assignee: ownershipList,
            state: DocumentUploadStatus.MAPPED
          }
        }
      });
    }
  };
  const updateVisibilityForDocument = (hidden) => {
    if (bulkUpload && bulkUploadDispatch && state.fileUuid) {
      bulkUploadDispatch({
        type: "UPDATE_DOCUMENT_PENDING",
        payload: {
          documentId: state.fileUuid,
          updatedData: {
            visibilitySettings: { [DocumentsDomain.employee]: { hideDocument: hidden } }
          }
        }
      });
    }
  };
  const updateExpiryForDocument = (expires, expiryDate) => {
    if (bulkUpload && bulkUploadDispatch && state.fileUuid) {
      bulkUploadDispatch({
        type: "UPDATE_DOCUMENT_PENDING",
        payload: {
          documentId: state.fileUuid,
          updatedData: {
            expirySettings: { expiryDate, documentExpires: expires }
          }
        }
      });
    }
  };
  const updateDocumentType = (x) => {
    if (bulkUpload && bulkUploadDispatch && state.fileUuid)
      bulkUploadDispatch({
        type: "UPDATE_DOCUMENT_PENDING",
        payload: {
          documentId: state.fileUuid,
          updatedData: { documentType: x, state: DocumentUploadStatus.MAPPED }
        }
      });
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, onClose, afterClose, children: /* @__PURE__ */ jsxs(
      "form",
      {
        onSubmit: (event) => __async(void 0, null, function* () {
          commitChangesForDocument();
          event.preventDefault();
          setIsLoading(true);
          yield onSubmit();
          setIsLoading(false);
          setOpen(false);
        }),
        style: drawerContentSx,
        children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center"
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "title2", children: mode === "request" ? polyglot.t("DocumentFormModal.requestDocument") : mode === "edit" ? polyglot.t("DocumentFormModal.editDocument") : polyglot.t("DocumentFormModal.newDocument") }),
                mode === "edit" && !bulkUpload ? documentId === void 0 || !isEditableOrRemovable || !hasDocumentsAllScope ? /* @__PURE__ */ jsx(IconButton, { sx: iconButtonSx, disabled: true, children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize)) }) : /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    sx: iconButtonSx,
                    onClick: (event) => {
                      setAnchorEl(event.currentTarget);
                      handleRemoveClick(documentId);
                    },
                    children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
                  }
                ) : /* @__PURE__ */ jsx(Fragment, {})
              ]
            }
          ),
          currentUserIsAdmin && /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              filters: mode === "request" ? Views.filter((v) => v.value === "personal") : Views,
              setFilterValue,
              filterValue,
              disabled: mode === "edit",
              onFilterChange: ({ filterValue: filterValue2 }) => {
                getTypeState(state, changeTypes$, filterValue2);
              }
            }
          ),
          canChangeOwnership ? /* @__PURE__ */ jsx(Box, { children: state.multiownership ? /* @__PURE__ */ jsx(
            MultiUserSelect,
            {
              id: "multiownership",
              limitTags: 5,
              options: [
                ...canSelectEveryone && state.canBelongToEveryone ? [BELONGS_TO_EVERYONE] : [],
                ...belongsToUserOptions
              ],
              value: state.canBelongToEveryone && state.belongsToEveryone ? [BELONGS_TO_EVERYONE] : [...state.owners],
              onChange: (_, values) => {
                var _a2;
                const vs = pipe(
                  values,
                  RA.filterMap((v) => v.value !== "public-org" ? O.some(v) : O.none)
                );
                if (vs.length === values.length || ((_a2 = values[values.length - 1]) == null ? void 0 : _a2.value) !== "public-org") {
                  dispatch({
                    kind: "select_owners",
                    value: vs
                  });
                } else {
                  dispatch({ kind: "select_everyone" });
                }
              },
              isOptionEqualToValue: (x, y) => x.value === y.value,
              getOptionLabel: ({ label }) => label,
              label: polyglot.t("DocumentFormModal.belongs")
            }
          ) : /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "singleownership",
              options: belongsToUserOptions,
              onChange: (_, value) => {
                if (value) {
                  updateSingleOwnershipForDocument(value);
                  dispatch({
                    kind: "select_owner",
                    value
                  });
                } else {
                  dispatch({
                    kind: "unselect_owners"
                  });
                }
              },
              value: (_b = state.owner) == null ? void 0 : _b.value,
              label: polyglot.t("DocumentFormModal.belongs"),
              error: false,
              helperText: false
            }
          ) }) : canChangeOwnership ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 0.5 }, children: state.multiownership ? state.canBelongToEveryone && state.belongsToEveryone ? /* @__PURE__ */ jsx(
            StyledTextfield,
            {
              label: polyglot.t("DocumentFormModal.belongs"),
              disabled: true,
              fullWidth: true,
              value: "Everyone",
              sx: __spreadValues({}, themeFonts.caption),
              variant: "standard"
            }
          ) : /* @__PURE__ */ jsx(
            StyledTextfield,
            {
              label: polyglot.t("DocumentFormModal.belongs"),
              disabled: true,
              fullWidth: true,
              sx: __spreadValues({}, themeFonts.caption),
              variant: "standard",
              InputProps: {
                startAdornment: state.owners.map((option) => /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: (option == null ? void 0 : option.value) && /* @__PURE__ */ jsx(UserAvatar, { userId: option.value, size: "xxsmall" }) }, option.value))
              }
            }
          ) : state.owner ? /* @__PURE__ */ jsx(
            StyledTextfield,
            {
              label: polyglot.t("DocumentFormModal.belongs"),
              disabled: true,
              fullWidth: true,
              value: state.owner.label,
              sx: __spreadValues({}, themeFonts.caption),
              variant: "standard",
              InputProps: {
                startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: ((_c = state.owner) == null ? void 0 : _c.value) && /* @__PURE__ */ jsx(UserAvatar, { userId: state.owner.value, size: "xxsmall" }, state.owner.value) })
              }
            }
          ) : null }) : null,
          filterValue === "company" && /* @__PURE__ */ jsx(
            AutocompleteComponent,
            {
              name: "documentType",
              label: polyglot.t("DocumentFormModal.documentType"),
              options: optionObject,
              value: state.type,
              onChange: (_, x) => {
                var _a2;
                if (x) {
                  updateDocumentType(x);
                  dispatch({
                    kind: "change_type",
                    type: x,
                    keepOwnership: !canChangeOwnership
                  });
                } else {
                  dispatch({
                    kind: "change_type",
                    type: (_a2 = newDocumentTypes.find((type) => type.value === "other")) != null ? _a2 : OTHER_TYPE,
                    keepOwnership: !canChangeOwnership
                  });
                }
              },
              compareValue: (_e = (_d = state.type) == null ? void 0 : _d.value) != null ? _e : "",
              editList: {
                handler: () => setNewTypeDrawerIsOpen(true),
                isHidden: !hasScopes(["documents:all"], { userId: user.userId })
              }
            }
          ),
          filterValue === "personal" && /* @__PURE__ */ jsx(
            AutocompleteComponent,
            {
              name: "documentType",
              label: polyglot.t("DocumentFormModal.documentType"),
              options: optionObject,
              value: state.type,
              onChange: (_, x) => {
                updateDocumentType(x);
                changeTypes$.next(x);
              },
              compareValue: (_g = (_f = state.type) == null ? void 0 : _f.value) != null ? _g : "",
              editList: {
                handler: () => setNewTypeDrawerIsOpen(true),
                isHidden: !hasScopes(["documents:all"], { userId: user.userId })
              }
            }
          ),
          /* @__PURE__ */ jsx(
            StyledTextfield,
            {
              label: polyglot.t("DocumentFormModal.name"),
              size: "small",
              required: true,
              value: (_h = state.name) != null ? _h : getDefaultNameForSingleUserDocument(state, changeName$),
              onChange: ({ target: { value } }) => changeName$.next(value),
              fullWidth: true,
              variant: "standard",
              InputLabelProps: { shrink: true },
              InputProps: {
                endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: state.name && ((_i = state.name) == null ? void 0 : _i.length) > 0 && /* @__PURE__ */ jsx(ClearIconButton, { onClick: () => changeName$.next("") }) })
              }
            }
          ),
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: mode === "request" ? polyglot.t("DocumentFormModal.description") : polyglot.t("DocumentFormModal.note"),
              value: state.note,
              onChange: ({ target: { value } }) => changeNote$.next(value),
              fullWidth: true,
              clearText: () => changeNote$.next("")
            }
          ),
          filterValue === "company" && /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              name: "isPinned",
              label: polyglot.t("DocumentFormModal.isPinned"),
              checked: state.pinned,
              onChange: () => {
                dispatch({
                  kind: "set_pinned",
                  value: !state.pinned
                });
              }
            }
          ),
          managerOrAdmin && /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              name: "visibilitySettings",
              label: polyglot.t("DocumentFormModal.visibilitySettings"),
              checked: (_l = (_k = (_j = state.visibility) == null ? void 0 : _j.employee) == null ? void 0 : _k.hideDocument) != null ? _l : false,
              onChange: (e) => {
                var _a2, _b2;
                const target = e.target;
                updateVisibilityForDocument(target.checked);
                dispatch({
                  kind: "set_visibility",
                  value: {
                    employee: { hideDocument: target.checked },
                    private: (_b2 = (_a2 = state.visibility) == null ? void 0 : _a2.private) != null ? _b2 : void 0
                  }
                });
              }
            }
          ),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "5px" }, children: [
            /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                name: "privateDocument",
                label: polyglot.t("DocumentFormModal.privateDocument"),
                checked: (_n = (_m = state.visibility) == null ? void 0 : _m.private) != null ? _n : false,
                onChange: (e) => {
                  var _a2, _b2, _c2;
                  const target = e.target;
                  updateVisibilityForDocument(target.checked);
                  dispatch({
                    kind: "set_visibility",
                    value: {
                      employee: { hideDocument: (_c2 = (_b2 = (_a2 = state.visibility) == null ? void 0 : _a2.employee) == null ? void 0 : _b2.hideDocument) != null ? _c2 : false },
                      private: target.checked
                    }
                  });
                }
              }
            ),
            /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("DocumentFormModal.privateHelperText"), children: /* @__PURE__ */ jsx(InfoGrey, __spreadValues({}, iconSize)) })
          ] }),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              name: "expirySettings",
              label: polyglot.t("DocumentFormModal.expirySettings"),
              checked: !!((_o = state == null ? void 0 : state.expirySettings) == null ? void 0 : _o.documentExpires),
              onChange: (e) => {
                const target = e.target;
                updateExpiryForDocument(target.checked, "");
                dispatch({
                  kind: "set_expiry",
                  value: { documentExpires: target.checked, expiryDate: "" }
                });
              }
            }
          ),
          ((_p = state == null ? void 0 : state.expirySettings) == null ? void 0 : _p.documentExpires) && /* @__PURE__ */ jsx(
            DatePickerComponent,
            {
              inputFormat: "DD/MM/YYYY",
              label: polyglot.t("DocumentFormModal.expiryDate"),
              name: "expiryDate",
              value: ((_q = state.expirySettings) == null ? void 0 : _q.expiryDate) ? (_r = state.expirySettings) == null ? void 0 : _r.expiryDate : "",
              onChange: (value) => {
                if (dayjs(value).isValid()) {
                  updateExpiryForDocument(true, value);
                  dispatch({
                    kind: "set_expiry",
                    value: { documentExpires: true, expiryDate: value }
                  });
                }
              },
              error: void 0,
              helperText: void 0,
              sx: { mt: spacing.mt30 }
            }
          ),
          state.fileUuid ? /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              },
              children: /* @__PURE__ */ jsxs(
                Box,
                {
                  component: "li",
                  sx: {
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between"
                  },
                  children: [
                    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
                      /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
                      /* @__PURE__ */ jsx(Box, { component: "span", sx: __spreadProps(__spreadValues({}, themeFonts.title4), { marginLeft: spacing.m10 }), children: pipe(
                        ((_s = state.fileName) != null ? _s : "placeholder").split("/"),
                        RA.reverse,
                        RA.head,
                        O.getOrElse(() => "")
                      ) })
                    ] }),
                    /* @__PURE__ */ jsx(
                      IconButton,
                      {
                        sx: tableIconButtonSx,
                        onClick: () => dispatch({ kind: "clear_file" }),
                        title: polyglot.t("General.remove"),
                        disabled: mode === "edit" && !hasDocumentsAllScope && !belongsToOnlyYou || bulkUpload,
                        children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
                      }
                    )
                  ]
                },
                state.fileUuid
              )
            }
          ) : /* @__PURE__ */ jsx(Box, { sx: { display: state.fileUuid || mode === "request" ? "none" : "block" }, children: /* @__PURE__ */ jsx(
            UploadInput,
            {
              sx: {
                display: (state == null ? void 0 : state.documentLink) && ((_t = state == null ? void 0 : state.documentLink) == null ? void 0 : _t.length) > 0 || mode === "request" ? "none" : "block"
              },
              onChange: (response, file, status) => {
                var _a2;
                if (file && response && response.uuid && response.fileName) {
                  dispatch({
                    kind: "upload_file",
                    value: {
                      fileUuid: response.uuid,
                      fileName: response.fileName
                    }
                  });
                  dispatch({
                    kind: "currently_uploading",
                    value: false
                  });
                } else if (status === "preparing") {
                  dispatch({
                    kind: "prepare_file_for_upload",
                    value: __spreadValues(__spreadValues({}, state.owner ? { owner: state.owner } : {}), state.owners && ((_a2 = state.owners) == null ? void 0 : _a2.length) > 0 ? { owner: state.owners[0] } : {})
                  });
                } else if (status === "uploading" || status === "getting_upload_params") {
                  dispatch({
                    kind: "currently_uploading",
                    value: true
                  });
                } else {
                  dispatch({ kind: "clear_file" });
                  dispatch({
                    kind: "currently_uploading",
                    value: false
                  });
                }
              },
              fileOwner: state.owner ? (_u = state.owner) == null ? void 0 : _u.value : state.owners && ((_v = state.owners) == null ? void 0 : _v.length) > 0 ? state.owners[0].value : void 0
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: mode === "edit" ? /* @__PURE__ */ jsxs(Box, { sx: { display: state.currentlyUploading ? "none" : "flex", gap: 1, width: "100%" }, children: [
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                fullWidth: true,
                sizeVariant: "medium",
                colorVariant: "secondary",
                onClick: () => {
                  cancelPendingChangesForDocument();
                  setOpen(false);
                },
                children: polyglot.t("General.cancel")
              }
            ),
            /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("General.save"),
                colorVariant: "primary",
                sizeVariant: "medium",
                fullWidth: true,
                loading: isLoading,
                disabled: !documentHasOwner(state) || !(state == null ? void 0 : state.type)
              }
            )
          ] }) : /* @__PURE__ */ jsx(
            Box,
            {
              sx: { display: state.currentlyUploading ? "none" : "flex", width: "100%", justifyContent: "center" },
              children: /* @__PURE__ */ jsx(
                LoaderButton,
                {
                  fullWidth: true,
                  colorVariant: "primary",
                  sizeVariant: "medium",
                  name: mode === "request" ? polyglot.t("DocumentFormModal.request") : polyglot.t("General.save"),
                  loading: isLoading,
                  disabled: mode !== "request" ? !documentHasOwner(state) || !(state == null ? void 0 : state.type) : !(state == null ? void 0 : state.type)
                }
              )
            }
          ) })
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: newTypeDrawerIsOpen, setIsOpen: setNewTypeDrawerIsOpen, children: /* @__PURE__ */ jsx(
      DocumentSettingsNewTypeForm,
      {
        initialValues: void 0,
        onClose: () => {
          setNewTypeDrawerIsOpen(false);
        },
        refreshDocumentTypes: () => refreshAllTypes == null ? void 0 : refreshAllTypes(),
        mode: "create"
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => setIsRemovalModalOpen(false),
        anchorEl,
        takeAction: removeDocument,
        message: polyglot.t("DocumentFormModal.confirmDelete"),
        callToAction: polyglot.t("General.yes")
      }
    )
  ] });
};
