"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { nestErrorMessage } from "@/lib/errors";
import {
  APPS_COMPANY_OVERVIEW_ROUTE,
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE,
  APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE,
  APP_INTEGRATION_DETAILS_GROUPS_ROUTE,
  APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE,
  APP_INTEGRATION_DETAILS_MAPPING_ROUTE,
  APP_INTEGRATION_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_CANDIDATES_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_EMPLOYMENTS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_GROUPS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_INSIGHTS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_MAPPING_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { AppDetailsAPI } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { useLocalStorage } from "@/v2/util/use-local-storage.util";
export const REVOKE_ACCESS_LIST = [401, 404, 500];
export function useAppDetails(appStub, handleAppAPIError, inCompanyView = true) {
  const routerHistory = useHistory();
  const [app, setApp] = useState(void 0);
  const IntegrationUsersPath = generatePath(
    inCompanyView ? APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
    { appStub }
  );
  const IntegrationAboutPath = generatePath(
    inCompanyView ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
    { appStub }
  );
  useEffect(() => {
    function fetchAppDetails() {
      return __async(this, null, function* () {
        try {
          const app2 = yield AppDetailsAPI.getIntegrationData(appStub);
          setApp(app2);
          if (app2 == null ? void 0 : app2.authorised) {
            routerHistory.push(IntegrationUsersPath);
          }
          if (!(app2 == null ? void 0 : app2.authorised)) {
            routerHistory.push(IntegrationAboutPath);
          }
        } catch (err) {
          yield handleAppAPIError(err);
        }
      });
    }
    fetchAppDetails();
  }, [appStub, routerHistory, IntegrationUsersPath, IntegrationAboutPath, handleAppAPIError]);
  return { app, setApp };
}
export function useFetchEmployments(appStub, showMessage, app) {
  const [employmentList, setEmploymentList] = useState([]);
  const [countriesForEmployment, setCountriesForEmployment] = useState([]);
  const [loadingEmployments, setLoadingEmployments] = useState(false);
  const fetchEmployments = useCallback(() => __async(this, null, function* () {
    var _a, _b;
    try {
      if (!app || !app.authorised || !app.allowsEmploymentManagement) return;
      setLoadingEmployments(true);
      const employmentData = yield AppIntegrationAPI.getEmployments(appStub);
      setEmploymentList((_a = employmentData == null ? void 0 : employmentData.employments) != null ? _a : []);
      setCountriesForEmployment((_b = employmentData == null ? void 0 : employmentData.countriesForEmployment) != null ? _b : []);
    } catch (error) {
      setEmploymentList([]);
      showMessage(`Encountered an error: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoadingEmployments(false);
    }
  }), [app, appStub, showMessage]);
  useEffect(() => {
    fetchEmployments();
  }, [app, appStub, fetchEmployments, showMessage]);
  return { employmentList, countriesForEmployment, loadingEmployments, fetchEmployments };
}
export function useFetchUsers(appStub, showMessage, handleAppAPIError, app, setAPIError) {
  const routerHistory = useHistory();
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState([]);
  const [notEnrolledUsers, setNotEnrolledUsers] = useState([]);
  const [_pendingTeamAppList, setPendingTeamAppList] = useLocalStorage("pendingTeamAppList", []);
  const fetchUsers = useCallback(
    (_stub) => __async(this, null, function* () {
      var _a, _b, _c;
      try {
        if (!app || !app.authorised) return;
        setLoadingUsers(true);
        const { users: users2, notEnrolledUsers: notEnrolledUsers2 } = yield AppIntegrationAPI.listAppUsers(appStub);
        setUsers(users2);
        setNotEnrolledUsers(notEnrolledUsers2);
        setPendingTeamAppList([]);
        setAPIError == null ? void 0 : setAPIError(false);
      } catch (error) {
        if (((_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data.statusCode) === 400) yield handleAppAPIError(error);
        if (((_b = error == null ? void 0 : error.response) == null ? void 0 : _b.data.statusCode) === 429) {
          showMessage(`Error while trying to list app users: ${nestErrorMessage(error)}`, "error");
        }
        if ([401, 403].includes((_c = error == null ? void 0 : error.response) == null ? void 0 : _c.data.statusCode)) routerHistory.push(APPS_COMPANY_OVERVIEW_ROUTE);
      } finally {
        setLoadingUsers(false);
      }
    }),
    [app, appStub, handleAppAPIError, routerHistory, setAPIError, setPendingTeamAppList, showMessage]
  );
  useEffect(() => {
    fetchUsers();
  }, [app, appStub, fetchUsers, handleAppAPIError, setPendingTeamAppList, showMessage]);
  return { loadingUsers, users, notEnrolledUsers, fetchUsers };
}
export function useFetchGroups(appStub, showMessage, app, currentUser) {
  var _a;
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [groupMembershipList, setGroupMembershipList] = useLocalStorage(
    `${appStub}-appGroupMemberships-${(_a = currentUser == null ? void 0 : currentUser.company) == null ? void 0 : _a.companyId}`,
    []
  );
  const fetchGroups = useCallback(() => __async(this, null, function* () {
    try {
      if (!app || !app.authorised || !app.allowsGroupManagement) return;
      setLoadingGroups(true);
      const groupData = yield AppIntegrationAPI.getGroupMemberships(appStub);
      setGroupMembershipList(groupData);
    } catch (error) {
      showMessage(`Failed to fetch group data: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoadingGroups(false);
    }
  }), [app, appStub, setGroupMembershipList, showMessage]);
  useEffect(() => {
    fetchGroups();
  }, [appStub, fetchGroups]);
  return { loadingGroups, groupMembershipList, fetchGroups };
}
export function useFetchCandidates(appStub, showMessage, handleAppAPIError, app) {
  const [candidateList, setCandidateList] = useState(void 0);
  const [importedCandidateList, setImportedCandidateList] = useState(
    void 0
  );
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const fetchCandidates = useCallback(() => __async(this, null, function* () {
    var _a, _b, _c, _d, _e;
    try {
      if (!app || !app.authorised || !app.allowsCandidateManagement) return;
      setLoadingCandidates(true);
      const candidateData = yield AppIntegrationAPI.getCandidateList(appStub);
      setCandidateList((_a = candidateData == null ? void 0 : candidateData.notEnrolledUsers) != null ? _a : []);
      const onboardedCandidates = (_c = (_b = candidateData == null ? void 0 : candidateData.users) == null ? void 0 : _b.filter((c) => c.userStatus === "Onboarded").map((c) => {
        return __spreadProps(__spreadValues({}, c), { userStatus: "Onboarded in Zelt" });
      })) != null ? _c : [];
      setImportedCandidateList(onboardedCandidates);
    } catch (error) {
      setCandidateList([]);
      setImportedCandidateList([]);
      if (((_d = error == null ? void 0 : error.response) == null ? void 0 : _d.data.statusCode) === 400) yield handleAppAPIError(error);
      if (((_e = error == null ? void 0 : error.response) == null ? void 0 : _e.data.statusCode) === 429) {
        showMessage(`Error while trying to fetch candidates: ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setLoadingCandidates(false);
    }
  }), [app, appStub, handleAppAPIError, showMessage]);
  useEffect(() => {
    fetchCandidates();
  }, [appStub, fetchCandidates]);
  return { candidateList, importedCandidateList, loadingCandidates, fetchCandidates };
}
export const TabFilter = [
  { name: "All", value: "all" },
  { name: "Active", value: "Active" },
  { name: "Invited", value: "Invited" },
  { name: "Imported", value: "Imported" },
  { name: "Suspended", value: "Suspended" },
  { name: "No access", value: "No access" }
];
export const EmploymentsTabFilter = [
  { name: "All", value: "all" },
  { name: "Draft", value: "Draft" },
  { name: "Active", value: "Active" },
  { name: "Onboarded in Zelt", value: "Onboarded in Zelt" },
  { name: "No access", value: "No access" }
];
export const AppStatusTabFilter = (polyglot) => {
  return [
    { name: polyglot.t("AppTeamAccessPage.statuses.All"), value: "all" },
    { name: polyglot.t("AppTeamAccessPage.statuses.Active"), value: "Active" },
    { name: polyglot.t("AppTeamAccessPage.statuses.Invited"), value: "Invited" },
    { name: polyglot.t("AppTeamAccessPage.statuses.Imported"), value: "Imported" },
    { name: polyglot.t("AppTeamAccessPage.statuses.Suspended"), value: "Suspended" },
    { name: polyglot.t("AppTeamAccessPage.statuses.Noaccess"), value: "No access" }
  ];
};
export const getAppPageConfig = (polyglot, currentUser, context, appStub, app, settingsHide, inCompanyView = true) => {
  var _a, _b;
  let menu = [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("AppTeamAccessPage.title"),
          stub: "team-access",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || !(app == null ? void 0 : app.authorised),
          hasChildren: false
        },
        {
          title: polyglot.t("AppExternalNotEnrolledPage.title"),
          stub: "external",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || !(app == null ? void 0 : app.authorised),
          hasChildren: false
        },
        {
          title: polyglot.t("AppGroupManagementPage.title"),
          stub: "groups",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_GROUPS_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_GROUPS_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || !(app == null ? void 0 : app.authorised) || !(app == null ? void 0 : app.allowsGroupManagement),
          hasChildren: false
        },
        {
          title: polyglot.t("AppCandidateManagementPage.title"),
          stub: "candidates",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_CANDIDATES_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || !(app == null ? void 0 : app.authorised) || !(app == null ? void 0 : app.allowsCandidateManagement),
          hasChildren: false
        },
        {
          title: ((_a = app == null ? void 0 : app.customTabMapping) == null ? void 0 : _a["employments"]) ? (_b = app == null ? void 0 : app.customTabMapping) == null ? void 0 : _b["employments"] : polyglot.t("AppEmploymentManagementPage.title"),
          stub: "employments",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_EMPLOYMENTS_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || !(app == null ? void 0 : app.authorised) || !(app == null ? void 0 : app.allowsEmploymentManagement),
          hasChildren: false
        },
        {
          title: polyglot.t("AppMappingPage.title"),
          stub: "mapping",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_MAPPING_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_MAPPING_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || !(app == null ? void 0 : app.authorised) || !(app == null ? void 0 : app.allowsExternalMapping),
          hasChildren: false
        },
        {
          title: polyglot.t("AppInsightsPage.title"),
          stub: "insights",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_INSIGHTS_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || !(app == null ? void 0 : app.authorised) || !(app == null ? void 0 : app.hasKPIs),
          hasChildren: false
        },
        {
          title: polyglot.t("AppAboutPage.title"),
          stub: "about",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context),
          hasChildren: false
        },
        {
          title: polyglot.t("AppUserSettingPage.title"),
          stub: "settings",
          path: generatePath(
            inCompanyView ? APP_INTEGRATION_DETAILS_SETTINGS_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_SETTINGS_ROUTE,
            { appStub }
          ),
          isHidden: !checkScopes(currentUser, ["apps"], context) || settingsHide,
          hasChildren: false
        }
      ]
    }
  ];
  return menu;
};
