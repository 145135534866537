"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback } from "react";
import { Box } from "@mui/material";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { SuperAdminDeviceDetailsOrders } from "@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-orders.component";
import { SuperAdminDeviceDetailsPossessions } from "@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-possessions.component";
import { SuperAdminDeviceDetailsTransits } from "@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-transits.component";
import { SuperAdminDeviceDetails } from "@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { useParams } from "react-router-dom";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminDeviceDetailsPage = () => {
  const params = useParams();
  const deviceId = Number(params.deviceId);
  const {
    data: device,
    isLoading: isLoadingDevice,
    mutate: refreshDeviceM
  } = useApiClient(DeviceEndpoints.getDeviceByIdAsSuperAdmin(deviceId), { suspense: false });
  const refreshDevice = useCallback(() => __async(void 0, null, function* () {
    if (refreshDeviceM) yield refreshDeviceM();
  }), [refreshDeviceM]);
  const deviceIdentification = device ? `[${device.id} | ${device.serialNumber}]` : `[${deviceId}]`;
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: `Device details ${deviceIdentification}` }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoadingDevice, children: /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: "80%" }), children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "50px" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", gap: "20px" }, children: /* @__PURE__ */ jsx(SuperAdminDeviceDetails, { device: device != null ? device : null, refreshDevice }) }),
      /* @__PURE__ */ jsx(SuperAdminDeviceDetailsPossessions, { deviceId }),
      /* @__PURE__ */ jsx(SuperAdminDeviceDetailsTransits, { deviceId }),
      /* @__PURE__ */ jsx(SuperAdminDeviceDetailsOrders, { deviceId, refreshDeviceDetails: refreshDevice })
    ] }) }) })
  ] });
};
