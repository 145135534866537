"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { SettingsReadOnlyLine } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { useParams } from "react-router-dom";
import { CompanyDepartmentAPI } from "@/api-client/company-department.api";
import { CompanyAPI } from "@/api-client/index.api";
import { SiteAPI } from "@/api-client/site.api";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { CustomRuleOptions, getUserSelectOptionLabel } from "@/v2/components/user-select-type/user-select.interface";
import { getCustomRule } from "@/v2/feature/absence/absence.util";
import { EditPaymentTypeMembersDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-setting-edit-members-drawer.component";
import { SettingsPageSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const PaymentTypeMembersSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const params = useParams();
  const { cachedUsers } = useCachedUsers();
  const typeId = Number(params.id);
  const [ruleDataOption, setRuleDataOption] = useState([]);
  const { data: paymentTypeSetting, mutate: refreshPaymentTypeSetting } = useApiClient(
    PaymentTypeSettingsEndpoints.getTypeSettingById(typeId),
    {
      suspense: false
    }
  );
  const members = useMemo(() => {
    var _a, _b;
    const memberIds = new Set((_a = paymentTypeSetting == null ? void 0 : paymentTypeSetting.selectedMembersIds) != null ? _a : []);
    const viaBenefitsSet = new Set((_b = paymentTypeSetting == null ? void 0 : paymentTypeSetting.viaBenefit) != null ? _b : []);
    return cachedUsers.filter((u) => memberIds.has(u.userId)).map((u) => {
      var _a2;
      return {
        userId: u.userId,
        name: (_a2 = u.displayName) != null ? _a2 : `${u.firstName} ${u.lastName}`,
        viaBenefit: viaBenefitsSet.has(u.userId)
      };
    });
  }, [cachedUsers, paymentTypeSetting]);
  const filteredUsers = useMemo(() => {
    if (!searchInput) return members ? [...members] : [];
    const search = searchInput.toLowerCase();
    return members ? [...members.filter((u) => u && u.name.toLowerCase().includes(search))] : [];
  }, [members, searchInput]);
  const getCustomRuleData = useCallback(() => __async(void 0, null, function* () {
    var _a, _b, _c, _d;
    const ruleTypeArray = (paymentTypeSetting == null ? void 0 : paymentTypeSetting.customRule) ? (_a = paymentTypeSetting == null ? void 0 : paymentTypeSetting.customRule) == null ? void 0 : _a.split("=") : [];
    if (ruleTypeArray.length === 0) return;
    const ruleType = (_b = ruleTypeArray[0]) != null ? _b : [];
    if (ruleType === CustomRuleOptions.Site) {
      const sitesData = yield SiteAPI.listSites(true);
      setRuleDataOption(
        sitesData.map((s) => {
          return { label: s.name, value: s.id };
        })
      );
    }
    if (ruleType === CustomRuleOptions.Department) {
      const departmentsData = yield CompanyDepartmentAPI.getCompanyDepartments();
      setRuleDataOption(
        departmentsData.map((d) => {
          return { label: d.name, value: d.id };
        })
      );
    }
    if (ruleType === CustomRuleOptions.Entity) {
      const entitiesData = (_d = (_c = yield CompanyAPI.getGeneralSettings()) == null ? void 0 : _c.entities) != null ? _d : [];
      setRuleDataOption(
        entitiesData.map((e) => {
          return { label: e.legalName, value: e.id };
        })
      );
    }
  }), [paymentTypeSetting == null ? void 0 : paymentTypeSetting.customRule]);
  useEffect(() => {
    getCustomRuleData();
  }, [getCustomRuleData]);
  const customRuleString = useMemo(
    () => getCustomRule(paymentTypeSetting ? paymentTypeSetting.customRule : null, ruleDataOption, polyglot),
    [paymentTypeSetting, ruleDataOption, polyglot]
  );
  const tableColumns = useMemo(
    () => [
      {
        header: () => /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.name") }),
        accessorFn: (row) => row,
        id: "name",
        enableSorting: true,
        cell: ({
          row: {
            original: { userId, name, viaBenefit }
          }
        }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId, size: "xsmall" }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t(name) }),
          viaBenefit && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "(via Benefit)" })
        ] }, userId)
      }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(SettingsPageSection, { title: polyglot.t("General.members"), onEdit: () => setIsEditDrawerOpen(true), children: [
    paymentTypeSetting && paymentTypeSetting.membersRule && /* @__PURE__ */ jsx(
      SettingsReadOnlyLine,
      {
        field: polyglot.t("AbsencePolicyMembersSection.members"),
        value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getUserSelectOptionLabel(paymentTypeSetting.membersRule, polyglot) })
      }
    ),
    customRuleString && /* @__PURE__ */ jsx(
      SettingsReadOnlyLine,
      {
        field: polyglot.t("AbsencePolicyMembersSection.rule"),
        value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("MembersSectionReadonly.selectAllFrom", { source: customRuleString }) })
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("AbsencePolicyMembersSection.list") }),
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => setSearchInput(e.target.value),
        style: { width: "572px" }
      }
    ),
    filteredUsers && filteredUsers.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { width: "600px" }, children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredUsers,
        columnData: tableColumns,
        initialSort: [{ id: "displayName", desc: true }],
        fixedLastColumn: false
      }
    ) }),
    paymentTypeSetting && /* @__PURE__ */ jsx(
      EditPaymentTypeMembersDrawer,
      {
        isOpen: isEditDrawerOpen,
        setIsOpen: setIsEditDrawerOpen,
        paymentTypeSetting,
        refresh: refreshPaymentTypeSetting
      }
    )
  ] });
};
