"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import {
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { detailTermSx, detailValueSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const AppCalendarConfiguration = () => {
  const fieldLabel = "Domain for calendar";
  const [edit, setEdit] = useState(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [currentDomain, setCurrentDomain] = useState("");
  const [updatedDomain, setUpdatedDomain] = useState("");
  const refreshCalendarDomainName = useCallback(() => __async(void 0, null, function* () {
    const config = yield AppIntegrationAPI.getCalendarDomainConfig();
    setDomainList(config.list);
    setCurrentDomain(config == null ? void 0 : config.current);
    setUpdatedDomain(config == null ? void 0 : config.current);
  }), []);
  useEffect(() => {
    refreshCalendarDomainName();
  }, [refreshCalendarDomainName]);
  const domainNameOptions = useMemo(
    () => domainList.map((eachOption) => {
      return {
        value: eachOption,
        label: eachOption
      };
    }),
    [domainList]
  );
  const saveDomainNameForCalendarConfig = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (updatedDomain)
        AppIntegrationAPI.saveCalendarDomainConfig(updatedDomain).then((config) => {
          if (config.current) setCurrentDomain(config.current);
        });
      showMessage("Successfully saved calendar domain!", "success");
      setEdit(false);
    } catch (error) {
      showMessage(`Failed to save calendar domain: ${JSON.stringify(error)}`, "error");
    } finally {
      setLoading(false);
      refreshCalendarDomainName();
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "550px", mt: spacing.m60 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: "Calendar configuration" }),
      /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { display: `${edit ? "none" : ""}` }), onClick: () => setEdit(true), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
    ] }),
    !edit ? /* @__PURE__ */ jsx(Box, { component: "dl", sx: { definitionListSx }, children: /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: "30px", display: "flex", alignItems: "center" }), children: [
      /* @__PURE__ */ jsx(Typography, { component: "dt", sx: definitionTermSx, children: fieldLabel }),
      /* @__PURE__ */ jsx(Typography, { component: "dd", sx: definitionValueSx, children: currentDomain != null ? currentDomain : "None" })
    ] }) }) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", mt: "30px", alignItems: "center" }, component: "section", children: [
        /* @__PURE__ */ jsx(Box, { sx: { minWidth: "190px", maxWidth: "190px" }, children: /* @__PURE__ */ jsxs(Typography, { sx: detailTermSx, children: [
          " ",
          fieldLabel
        ] }) }),
        /* @__PURE__ */ jsx(Box, { sx: detailValueSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            label: "",
            name: "",
            options: domainNameOptions,
            value: updatedDomain,
            onChange: (e) => {
              setUpdatedDomain(e.target.value);
            }
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", my: spacing.m50, gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            disabled: false,
            onClick: () => setEdit(false),
            children: "Cancel"
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            disabled: !updatedDomain || updatedDomain === currentDomain || !domainList || loading,
            onClick: () => saveDomainNameForCalendarConfig(),
            children: "Save"
          }
        )
      ] })
    ] })
  ] });
};
