"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@v2/components/typography/typography.component";
import { borders } from "@v2/styles/borders.styles";
import { themeColors } from "@v2/styles/colors.styles";
export const ChipComponentWithIcon = ({
  name,
  icon,
  onClick,
  textColor = "Grey",
  backgroundColor = "Background",
  border,
  textVariant = "caption"
}) => {
  const chipStyle = {
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 10px",
    borderRadius: "25px",
    backgroundColor: themeColors[backgroundColor],
    cursor: onClick ? "pointer" : "default",
    border: border ? borders[border] : void 0,
    gap: "8px"
  };
  const iconStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
  const handleClick = () => {
    if (onClick) onClick();
  };
  return /* @__PURE__ */ jsxs("div", { style: chipStyle, onClick: handleClick, children: [
    icon && /* @__PURE__ */ jsx("div", { style: iconStyle, children: icon }),
    /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, children: name })
  ] });
};
