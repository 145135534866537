"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Stack } from "@mui/material";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { PayrollEmploymentAllowance } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/employer-allowance-settings/payroll-employment-allowance.component";
export const PayrollEmploymentAllowanceSection = ({
  payrollId,
  setOtherEdit,
  sx
}) => {
  const [empAllowanceDrawerOpen, setEmpAllowanceDrawerOpen] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { showEditButton, onEditClick: () => setEmpAllowanceDrawerOpen(true), children: "Employment allowance" }),
    /* @__PURE__ */ jsx(
      PayrollEmploymentAllowance,
      {
        payrollId,
        setOtherEdit,
        setShowEditButton,
        empAllowanceDrawerOpen,
        setEmpAllowanceDrawerOpen
      }
    )
  ] });
};
