"use strict";
export var PERMISSION_GROUP_EDIT_DRAWER_MODES = /* @__PURE__ */ ((PERMISSION_GROUP_EDIT_DRAWER_MODES2) => {
  PERMISSION_GROUP_EDIT_DRAWER_MODES2["member"] = "member";
  PERMISSION_GROUP_EDIT_DRAWER_MODES2["scope"] = "scope";
  PERMISSION_GROUP_EDIT_DRAWER_MODES2["add"] = "add";
  PERMISSION_GROUP_EDIT_DRAWER_MODES2["view"] = "view";
  PERMISSION_GROUP_EDIT_DRAWER_MODES2["target"] = "target";
  PERMISSION_GROUP_EDIT_DRAWER_MODES2["general"] = "general";
  return PERMISSION_GROUP_EDIT_DRAWER_MODES2;
})(PERMISSION_GROUP_EDIT_DRAWER_MODES || {});
export const DefaultManagerPermissionLevel = 1;
export const AllManagerPermissionLevels = 999;
