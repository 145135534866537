"use strict";
import { jsx } from "react/jsx-runtime";
import { FiltersEndpoints } from "@v2/feature/filters/filters.api";
import { TimeReportBalancesSection } from "@v2/feature/reports/features/update-report/time/time-report-balances-section.component";
import { TimeReportRequestsSection } from "@v2/feature/reports/features/update-report/time/time-report-requests-section.component";
import { ReportTypeTimeSelection } from "@v2/feature/reports/reports.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const TimeReportSection = ({ report, loading }) => {
  var _a, _b, _c;
  const { data: filters } = useApiClient(FiltersEndpoints.geTimeFiltersOfReports(), {
    suspense: false
  });
  return ((_a = report.config) == null ? void 0 : _a.type) === ReportTypeTimeSelection.Balances ? /* @__PURE__ */ jsx(TimeReportBalancesSection, { filters: (_b = filters == null ? void 0 : filters.peopleFilters) != null ? _b : {}, report, loading }) : ((_c = report.config) == null ? void 0 : _c.type) === ReportTypeTimeSelection.Requests ? /* @__PURE__ */ jsx(
    TimeReportRequestsSection,
    {
      report,
      filters: filters != null ? filters : { peopleFilters: {}, timeFilters: {} },
      loading
    }
  ) : null;
};
