"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { primarySmallBtn } from "@v2/styles/buttons.styles";
import { BillingDiscountAPI } from "@/api-client/billing-discount.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SuperAdminBillingDiscountAddForm } from "@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-discount-add-form.component";
import { SuperAdminBillingDiscountEditForm } from "@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-discount-edit-form.component";
import { SuperAdminBillingDiscountTableHelper } from "@/v2/feature/super-admin/features/super-admin-billing/helper/super-admin-billing-discounts-table-helper";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function SuperAdminBillingDiscountsPage({ pageConfig }) {
  var _a, _b;
  const [discounts, setDiscounts] = useState([]);
  const [isLoadingDiscounts, setIsLoadingDiscounts] = useState(false);
  const [selectedDiscountToEdit, setDiscountToEdit] = useState();
  const [showMessage] = useMessage();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const fetchDiscounts = useCallback(
    (params) => __async(this, null, function* () {
      try {
        setIsLoadingDiscounts(true);
        const { discounts: discounts2, pagination: paginationInfo } = yield BillingDiscountAPI.getAllDiscounts(params);
        setDiscounts(discounts2 || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
        setIsLoadingDiscounts(false);
      } catch (error) {
        showMessage(`Something went wrong fetching discounts. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const refresh = () => __async(this, null, function* () {
    try {
      yield fetchDiscounts();
      setIsEditModalOpen(false);
      setIsAddModalOpen(false);
    } catch (error) {
      showMessage(`Failed to refresh. ${nestErrorMessage(error)}`, "error");
    }
  });
  useEffect(() => {
    (() => __async(this, null, function* () {
      yield fetchDiscounts({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery
      });
    }))();
  }, [pageIndex, searchQuery, fetchDiscounts]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const handleEditButtonClick = (selectedDiscount) => {
    setDiscountToEdit(selectedDiscount);
    setIsEditModalOpen(true);
  };
  const discountTableColumns = useMemo(
    () => [
      SuperAdminBillingDiscountTableHelper.getDiscountNameColumn({
        header: "Discount Name",
        id: "discountName",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountIdColumn({
        header: "Discount Id",
        id: "discountId",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountTypeColumn({
        header: "Discount Type",
        id: "discountType",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountAmountColumn({
        header: "Discount Amount",
        id: "discountAmount",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountStartDateColumn({
        header: "Start Date",
        id: "discountStartDate",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountEndDateColumn({
        header: "End Date",
        id: "discountEndDate",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountCreatedAtColumn({
        header: "Created At",
        id: "discountCreatedAt",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountUpdatedAtColumn({
        header: "Updated At",
        id: "discountUpdated",
        size: 145
      }),
      SuperAdminBillingDiscountTableHelper.getDiscountUpdatedByColumn({
        header: "Updated By",
        id: "discountUpdatedBy",
        size: 145
      }),
      {
        header: () => "Action",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => original.id ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Button,
          {
            onClick: () => {
              handleEditButtonClick(original);
            },
            sx: {
              minWidth: 10,
              padding: 1,
              maxWidth: 40,
              borderRadius: 1,
              backgroundColor: themeColors.lightGrey,
              color: themeColors.DarkGrey
            },
            children: /* @__PURE__ */ jsx(Edit, { sx: { height: 20, width: "20px" } })
          },
          original.id
        ) }) : /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Grey
            }),
            children: "\u2014"
          }
        ),
        size: 220
      }
    ],
    []
  );
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Billing" }),
        actions: /* @__PURE__ */ jsx(Button, { sx: primarySmallBtn, onClick: () => setIsAddModalOpen(true), children: "Add discount" }),
        showAction: true
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoadingDiscounts, secondLevel: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({
            display: "flex",
            justifyContent: "flex-start",
            width: "100%"
          }, spacing.mb20), {
            gap: "5px",
            alignItems: "center"
          }),
          children: /* @__PURE__ */ jsx(
            TableSearch,
            {
              style: { width: "16em" },
              query: searchQuery,
              handleChange: (e) => {
                var _a2, _b2;
                setSearchQuery((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              },
              placeholder: "Enter discount name..."
            }
          )
        }
      ),
      !isLoadingDiscounts && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          BasicServerTable,
          {
            rowData: [...discounts],
            columnData: discountTableColumns,
            sorting,
            setSorting,
            pagination,
            setPagination,
            totalPages,
            totalItems
          }
        ),
        isAddModalOpen && /* @__PURE__ */ jsx(DrawerModal, { isOpen: isAddModalOpen, setIsOpen: setIsAddModalOpen, children: /* @__PURE__ */ jsx(SuperAdminBillingDiscountAddForm, { onSave: refresh }) }),
        isEditModalOpen && /* @__PURE__ */ jsx(DrawerModal, { isOpen: isEditModalOpen, setIsOpen: setIsEditModalOpen, children: /* @__PURE__ */ jsx(
          SuperAdminBillingDiscountEditForm,
          {
            discount: selectedDiscountToEdit,
            onSave: refresh
          }
        ) })
      ] })
    ] }) })
  ] });
}
