"use strict";
import { ATTENDANCE_COMPANY_ROUTE, ATTENDANCE_ME_ROUTE, ATTENDANCE_TEAM_ROUTE } from "@/lib/routes";
export const TIME_ATTENDANCE_ROUTES = {
  company: ATTENDANCE_COMPANY_ROUTE,
  team: ATTENDANCE_TEAM_ROUTE,
  me: ATTENDANCE_ME_ROUTE
};
export const TIME_ATTENDANCE_ROUTES_SCOPES = {
  company: ["attendance:all"],
  team: ["attendance:manager"],
  me: ["attendance"]
};
