"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PayrollExternalApi {
  static getExternalPayRun(payrollId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/external?payrollId=${payrollId}`)).data;
    });
  }
  static getExternalPayRunPayments(payrollId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/payments?payrollId=${payrollId}`)).data;
    });
  }
  static getExternalPayRunEntries(payrollId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/entries?payrollId=${payrollId}`)).data;
    });
  }
  static getAnalysisSummaryReport(taxYear, payPeriod, period) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/summary-report`)).data;
    });
  }
  static getExternalFPS(taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/fps`)).data;
    });
  }
  static getExternalFPSReportByPeriod(payrollId, taxYear, payPeriod, periodNumber, contentType) {
    return __async(this, null, function* () {
      return (yield axios.get(
        `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/fps/report?payrollId=${payrollId}&contentType=${contentType}`
      )).data;
    });
  }
  static finalisePayRun(payrollId, taxYear, payPeriod, periodNumber, paymentDate) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/finalise?payrollId=${payrollId}`, {
        paymentDate
      });
    });
  }
  static createPayRun(payrollId, taxYear, payPeriod) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}?payrollId=${payrollId}`)).data;
    });
  }
  static createNewPaySchedule(payrollId, taxYear, payPeriod, payload) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/payroll/payschedule/${taxYear}/${payPeriod}?payrollId=${payrollId}`, payload)).data;
    });
  }
  static updatePaySchedule(payrollId, taxYear, payPeriod, ordinal, payload) {
    return __async(this, null, function* () {
      return (yield axios.patch(
        `/apiv2/company/payroll/payschedule/${taxYear}/${payPeriod}?payrollId=${payrollId}&ordinal=${ordinal}`,
        payload
      )).data;
    });
  }
  static getPaySchedules(payrollId, taxYear) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/payroll/payschedule/${taxYear}?payrollId=${payrollId}`)).data;
    });
  }
  static getPaySchedulesForAllTaxYears(payrollId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/payschedules?payrollId=${payrollId}`)).data;
    });
  }
  static getPaySchedulesAsSuperadmin(companyId, payrollId, taxYear) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/superadmin/payschedule/${companyId}/${taxYear}?payrollId=${payrollId}`)).data;
    });
  }
  static moveUsersToPaySchedule(companyId, payrollId, payPeriod, ordinal) {
    return __async(this, null, function* () {
      return (yield axios.post(
        `/apiv2/company/payroll/superadmin/payschedule/migrate/${companyId}/${payPeriod}/${ordinal}?payrollId=${payrollId}`
      )).data;
    });
  }
  static getPayrunsStatsForTaxYear(payrollId, taxYear) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/stats/${taxYear}?payrollId=${payrollId}`)).data;
    });
  }
  static fetchExternalFPSStatus(payRunId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payRunId}/fps-submission/external`)).data;
    });
  }
  static submitFPS(payRunId, lateReason) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payRunId}/fps/submit`, { lateReason })).data;
    });
  }
  static markFPSAsSent(payRunId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payroll/payruns/${payRunId}/fps/markassent`)).data;
    });
  }
  static createCompanyPayroll(entityId, payrollType) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/company/payroll", { entityId, payrollType })).data;
    });
  }
  static updatePayroll(payrollId, payroll) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/company/payroll?payrollId=${payrollId}`, payroll)).data;
    });
  }
  static getHmrcLiabilities(payrollId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/hmrcpayment/liabilities?payrollId=${payrollId}`)).data;
    });
  }
  static getHmrcLiabilityByPeriod(payrollId, taxYear, period) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/hmrcpayment/liabilities/${taxYear}/${period}?payrollId=${payrollId}`)).data;
    });
  }
  static downloadP30Url(payrollId, taxYear, periodEnding) {
    return __async(this, null, function* () {
      return `/apiv2/company/payroll/reports/p30/${taxYear}/${periodEnding}?payrollId=${payrollId}`;
    });
  }
  static downloadP32Url(payrollId, taxYear) {
    return __async(this, null, function* () {
      return `/apiv2/company/payroll/reports/p32/${taxYear}?payrollId=${payrollId}`;
    });
  }
  static downloadPayslipsUrl(payrollId, taxYear, payPeriod, period, ordinal) {
    return __async(this, null, function* () {
      return `/apiv2/company/payroll/reports/payslips/${taxYear}/${payPeriod}/${period}?ordinal=${ordinal}&payrollId=${payrollId}`;
    });
  }
  static getBankPaymentInstructionsForPayrun(payRunId, contentType) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payRunId}/payments/instructions?contentType=${contentType}`)).data;
    });
  }
  static getBankPaymentInstructionsForPayrunByPeriod(taxYear, payPeriod, period, contentType) {
    return __async(this, null, function* () {
      return (yield axios.get(
        `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/payments/instructions?contentType=${contentType}`
      )).data;
    });
  }
  static updateIncome(payrollId, taxYear, payPeriod, periodNumber, income) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/income?payrollId=${payrollId}`, {
        income
      });
    });
  }
  // TODO: @payroll-now - is this needed?
  static getUpcomingPayrollChanges() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/company/payroll/dps/notices/upcoming")).data;
    });
  }
}
