"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const EmptyStateBox = ({
  title,
  subtitle,
  buttonTitle,
  onClick
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      style: {
        backgroundColor: themeColors.Background,
        padding: "16px",
        borderRadius: radius.br8,
        maxWidth: "600px",
        minHeight: "50%",
        display: "flex",
        flexDirection: "column"
      },
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g16
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title3", children: title }),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: subtitle })
            ]
          }
        ),
        onClick && /* @__PURE__ */ jsx(Box, { sx: { mt: "auto" }, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick, children: buttonTitle }) })
      ]
    }
  );
};
