"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ChartBoxPlot } from "@/v2/components/charts/chart-box-plot.component";
import { ChartColumnSingle } from "@/v2/components/charts/chart-column-single.component";
import { chartBoxSx, equalChartSx } from "@/v2/components/charts/styles.layout";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { FiltersDrawer } from "@/v2/components/table/filters-drawer.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AnalyticEndpoints } from "@/v2/feature/analytics/analytics.api";
import {
  ContentWrapper,
  Loader
} from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { FiltersEndpoints } from "@/v2/feature/filters/filters.api";
import { DEFAULT_LIFECYCLE_STATUS_FILTER } from "@/v2/feature/user/user-directory.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { isDefined } from "@/v2/util/string.util";
export const PayrollAnalyticsPage = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("AnalyticsInsightsPeoplePage.insights") })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(Loader, { loading: true, children: /* @__PURE__ */ jsx(Fragment, {}) }),
        children: /* @__PURE__ */ jsx(PayrollAnalyticsDataLayer, {})
      }
    ) })
  ] });
};
const PayrollAnalyticsDataLayer = () => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
  const [state, dispatch] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const currentsalaryBandFilterString = isDefined((_c = (_b = (_a = currentUser.features) == null ? void 0 : _a.insights) == null ? void 0 : _b.salaryBand) == null ? void 0 : _c.filters) ? (_f = (_e = (_d = currentUser.features) == null ? void 0 : _d.insights) == null ? void 0 : _e.salaryBand) == null ? void 0 : _f.filters : null;
  const currentgenderPayGapFilterString = isDefined((_i = (_h = (_g = currentUser.features) == null ? void 0 : _g.insights) == null ? void 0 : _h.genderPayGap) == null ? void 0 : _i.filters) ? (_l = (_k = (_j = currentUser.features) == null ? void 0 : _j.insights) == null ? void 0 : _k.genderPayGap) == null ? void 0 : _l.filters : null;
  const currentsalaryChangeFilterString = isDefined((_o = (_n = (_m = currentUser.features) == null ? void 0 : _m.insights) == null ? void 0 : _n.salaryChange) == null ? void 0 : _o.filters) ? (_r = (_q = (_p = currentUser.features) == null ? void 0 : _p.insights) == null ? void 0 : _q.salaryChange) == null ? void 0 : _r.filters : null;
  const [salaryBandFilterString, setSalaryBandFilterString] = useState(
    currentsalaryBandFilterString || DEFAULT_LIFECYCLE_STATUS_FILTER
  );
  const [genderPayGapFilterString, setGenderPayGapFilterString] = useState(
    currentgenderPayGapFilterString || DEFAULT_LIFECYCLE_STATUS_FILTER
  );
  const [salaryChangeFilterString, setsalaryChangeFilterString] = useState(
    currentsalaryChangeFilterString || DEFAULT_LIFECYCLE_STATUS_FILTER
  );
  const { data: filtersConfig } = useApiClient(FiltersEndpoints.getReportsSalaryFilters());
  return /* @__PURE__ */ jsx(
    PayrollAnalyticsLayout,
    {
      filtersConfig,
      setSalaryBandFilterString,
      salaryBandFilterString,
      setGenderPayGapFilterString,
      setsalaryChangeFilterString,
      dispatch,
      genderPayGapFilterString,
      salaryChangeFilterString
    }
  );
};
const PayrollAnalyticsLayout = ({
  filtersConfig,
  setSalaryBandFilterString,
  salaryBandFilterString,
  setGenderPayGapFilterString,
  genderPayGapFilterString,
  setsalaryChangeFilterString,
  salaryChangeFilterString,
  dispatch
}) => {
  var _a, _b, _c;
  const { trackPage } = useJune();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  useEffect(() => {
    trackPage("Company payroll analytics");
  }, []);
  const updateUserFeaturesSelectedsetGenderPayGapFilters = useCallback(
    (filterString) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "insights",
          "salaryBand",
          "filters",
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`${polyglot.t("PeopleDirectoryPage.errorMessages.update")}. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, showMessage, polyglot]
  );
  const updateUserFeaturesSelectedsetSalaryChangeFilters = useCallback(
    (filterString) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "insights",
          "salaryChange",
          "filters",
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`${polyglot.t("PeopleDirectoryPage.errorMessages.update")}. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, showMessage, polyglot]
  );
  const updateUserFeaturesSelectedSalaryBandFilters = useCallback(
    (filterString) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "insights",
          "salaryBand",
          "filters",
          filterString
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`${polyglot.t("PeopleDirectoryPage.errorMessages.update")}. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, showMessage, polyglot]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: chartBoxSx, children: [
      /* @__PURE__ */ jsxs(Box, { sx: equalChartSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("AnalyticsInsightsPeoplePage.genderPayGap") }),
          /* @__PURE__ */ jsx(
            FiltersDrawer,
            {
              filtersOptions: [{ filters: (_a = filtersConfig == null ? void 0 : filtersConfig.filterOptions) != null ? _a : {} }],
              selectedFilters: genderPayGapFilterString,
              setSelectedFilters: setGenderPayGapFilterString,
              onApply: updateUserFeaturesSelectedsetGenderPayGapFilters
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          Suspense,
          {
            fallback: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                height: "230px",
                width: "100%",
                sx: { background: themeColors.Background, mt: spacing.m10 }
              }
            ),
            children: /* @__PURE__ */ jsx(GenderPayGapComponent, { genderPayGapFilterString })
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: equalChartSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("AnalyticsInsightsPeoplePage.lastSalaryChange") }),
          /* @__PURE__ */ jsx(
            FiltersDrawer,
            {
              filtersOptions: [{ filters: (_b = filtersConfig == null ? void 0 : filtersConfig.filterOptions) != null ? _b : {} }],
              selectedFilters: salaryChangeFilterString,
              setSelectedFilters: setsalaryChangeFilterString,
              onApply: updateUserFeaturesSelectedsetSalaryChangeFilters
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          Suspense,
          {
            fallback: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                height: "230px",
                width: "100%",
                sx: { background: themeColors.Background, mt: spacing.m10 }
              }
            ),
            children: /* @__PURE__ */ jsx(LastSalaryChange, { salaryChangeFilterString })
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: spacing.mt20, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("AnalyticsInsightsPeoplePage.salaryBands") }),
        /* @__PURE__ */ jsx(
          FiltersDrawer,
          {
            filtersOptions: [{ filters: (_c = filtersConfig == null ? void 0 : filtersConfig.filterOptions) != null ? _c : {} }],
            selectedFilters: salaryBandFilterString,
            setSelectedFilters: setSalaryBandFilterString,
            onApply: updateUserFeaturesSelectedSalaryBandFilters
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              height: "230px",
              width: "100%",
              sx: { background: themeColors.Background, mt: spacing.m10 }
            }
          ),
          children: /* @__PURE__ */ jsx(SalaryBandComponent, { salaryBandFilterString })
        }
      )
    ] })
  ] });
};
const SalaryBandComponent = ({ salaryBandFilterString }) => {
  const { polyglot } = usePolyglot();
  const { data: salaryBand } = useApiClient(AnalyticEndpoints.getSalaryBand(salaryBandFilterString));
  const [salaryBandCandleStick, salaryBandCategories] = useMemo(() => {
    const bandSeries = salaryBand == null ? void 0 : salaryBand.map((item) => {
      return {
        x: item.levelCode,
        y: [
          parseFloat(item.minRate),
          parseFloat(item.lowerQuartile),
          parseFloat(item.median),
          parseFloat(item.upperQuartile),
          parseFloat(item.maxRate)
        ]
      };
    });
    const categories = salaryBand == null ? void 0 : salaryBand.map((a) => a.levelCode);
    return [bandSeries, categories];
  }, [salaryBand]);
  const customTooltip = ({ dataPointIndex }) => {
    var _a;
    if (!salaryBandCandleStick || !salaryBandCandleStick[dataPointIndex]) return "";
    const yValue = salaryBandCandleStick ? (_a = salaryBandCandleStick[dataPointIndex]) == null ? void 0 : _a.y : [];
    return `<div class="arrow_box">${polyglot.t("AnalyticsInsightsPeoplePage.maxRate")}: ${formatCurrency(yValue[4], {
      wholeNumber: true
    })}<br/>${polyglot.t("AnalyticsInsightsPeoplePage.upperQuartile")}: ${formatCurrency(yValue[3], {
      wholeNumber: true
    })}<br/>${polyglot.t("AnalyticsInsightsPeoplePage.median")}: ${formatCurrency(yValue[2], {
      wholeNumber: true
    })}<br/>${polyglot.t("AnalyticsInsightsPeoplePage.lowerQuartile")}: ${formatCurrency(yValue[1], {
      wholeNumber: true
    })}<br/>${polyglot.t("AnalyticsInsightsPeoplePage.minRate")}: ${formatCurrency(yValue[0], {
      wholeNumber: true
    })}<br/></div>`;
  };
  const tableColumns = useMemo(
    () => [
      {
        header: polyglot.t("AnalyticsInsightsPeoplePage.levelCode"),
        accessorFn: (row) => row,
        id: "levelCode",
        enableSorting: false,
        cell: ({ row: { original } }) => original.levelCode ? /* @__PURE__ */ jsx("div", { children: original.levelCode }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120,
        maxSize: 150
      },
      {
        header: () => polyglot.t("AnalyticsInsightsPeoplePage.minRate"),
        accessorFn: (row) => row,
        id: "minRate",
        enableSorting: false,
        cell: ({
          row: {
            original: { minRate }
          }
        }) => minRate ? /* @__PURE__ */ jsx("div", { children: formatCurrency(minRate) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120,
        maxSize: 150
      },
      {
        header: () => polyglot.t("AnalyticsInsightsPeoplePage.lowerQuartile"),
        accessorFn: (row) => row,
        id: "lowerQuartile",
        enableSorting: false,
        cell: ({
          row: {
            original: { lowerQuartile }
          }
        }) => lowerQuartile ? /* @__PURE__ */ jsx("div", { children: formatCurrency(lowerQuartile) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120,
        maxSize: 150
      },
      {
        header: () => polyglot.t("AnalyticsInsightsPeoplePage.median"),
        accessorFn: (row) => row,
        id: "median",
        enableSorting: false,
        cell: ({
          row: {
            original: { median }
          }
        }) => median ? /* @__PURE__ */ jsx("div", { children: formatCurrency(median) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120,
        maxSize: 150
      },
      {
        header: () => polyglot.t("AnalyticsInsightsPeoplePage.upperQuartile"),
        accessorFn: (row) => row,
        id: "upperQuartile",
        enableSorting: false,
        cell: ({
          row: {
            original: { upperQuartile }
          }
        }) => upperQuartile ? /* @__PURE__ */ jsx("div", { children: formatCurrency(upperQuartile) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120,
        maxSize: 150
      },
      {
        header: () => polyglot.t("AnalyticsInsightsPeoplePage.maxRate"),
        accessorFn: (row) => row,
        id: "maxRate",
        enableSorting: false,
        cell: ({
          row: {
            original: { maxRate }
          }
        }) => maxRate ? /* @__PURE__ */ jsx("div", { children: formatCurrency(maxRate) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120,
        maxSize: 150
      }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    salaryBand && salaryBand.length > 0 && salaryBandCandleStick && salaryBandCategories && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        ChartBoxPlot,
        {
          series: salaryBandCandleStick != null ? salaryBandCandleStick : [],
          categories: salaryBandCategories != null ? salaryBandCategories : [],
          customTooltip
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(BasicTable, { rowData: salaryBand != null ? salaryBand : [], columnData: tableColumns != null ? tableColumns : [] }) })
    ] }),
    salaryBand && salaryBand.length === 0 && // empty state
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey, mt: spacing.m10 }, children: polyglot.t("AnalyticsInsightsPeoplePage.emptyState") }) })
  ] });
};
const GenderPayGapComponent = ({ genderPayGapFilterString }) => {
  const { polyglot } = usePolyglot();
  const { data: genderPayGap } = useApiClient(AnalyticEndpoints.findGenderPayGap(genderPayGapFilterString));
  const getTranslatedCategoriesForGenderPayGap = (polyglot2, genderPayGap2) => {
    return (genderPayGap2 == null ? void 0 : genderPayGap2.categories) ? genderPayGap2.categories.map((category) => polyglot2.t(`GenderPayGapChart.${category}`)) : [];
  };
  const getTranslatedSeriesNamesForGenderPayGap = (polyglot2, genderPayGap2) => {
    return (genderPayGap2 == null ? void 0 : genderPayGap2.series) ? genderPayGap2.series.map((eachSeries) => {
      return __spreadProps(__spreadValues({}, eachSeries), { name: polyglot2.t(`GenderPayGapChart.${eachSeries.name}`) });
    }) : [];
  };
  return /* @__PURE__ */ jsx(
    ChartColumnSingle,
    {
      series: getTranslatedSeriesNamesForGenderPayGap(polyglot, genderPayGap) || [],
      categories: getTranslatedCategoriesForGenderPayGap(polyglot, genderPayGap) || [],
      percentage: true,
      min: 0,
      max: 100,
      tickAmount: 5
    }
  );
};
const LastSalaryChange = ({ salaryChangeFilterString }) => {
  const { polyglot } = usePolyglot();
  const { data: lastSalaryChange } = useApiClient(AnalyticEndpoints.findLastSalaryChange(salaryChangeFilterString));
  const getTranslatedCategoriesForLastSalaryChange = (polyglot2, lastSalaryChange2) => {
    return (lastSalaryChange2 == null ? void 0 : lastSalaryChange2.categories) ? lastSalaryChange2.categories.map((category) => polyglot2.t(`LastSalaryChangeChart.${category}`)) : [];
  };
  return /* @__PURE__ */ jsx(
    ChartColumnSingle,
    {
      series: (lastSalaryChange == null ? void 0 : lastSalaryChange.series) || [],
      categories: getTranslatedCategoriesForLastSalaryChange(polyglot, lastSalaryChange) || []
    }
  );
};
