"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { INVOICES_TEAM_OVERVIEW_ROUTE, INVOICES_TEAM_NEW_INVOICE_ROUTE, INVOICES_TEAM_ROUTE } from "@/lib/routes";
import { InvoicesPage } from "@/v2/feature/payments/pages/invoice.page";
import { NewInvoicePage } from "@/v2/feature/payments/pages/new-invoice.page";
export const InvoiceTeamRouter = ({ isManager }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["invoices:manager"], path: INVOICES_TEAM_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(InvoicesPage, { reach: "team" }) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["invoices:manager"], path: INVOICES_TEAM_NEW_INVOICE_ROUTE, children: /* @__PURE__ */ jsx(NewInvoicePage, { reach: "team" }) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["invoices:manager"], path: INVOICES_TEAM_NEW_INVOICE_ROUTE, children: /* @__PURE__ */ jsx(NewInvoicePage, { reach: "team" }) }),
    /* @__PURE__ */ jsx(Redirect, { from: INVOICES_TEAM_ROUTE, to: INVOICES_TEAM_OVERVIEW_ROUTE })
  ] });
};
