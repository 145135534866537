"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  isLoanBenefit,
  isUsingOpeningBalance
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const ViewUserCustomBenefitDrawer = ({ isOpen, setIsOpen, userBenefit }) => {
  const { data: userFamilyMembers } = useApiClient(UserEndpoints.getUserFamilyMembers(userBenefit.userId), {
    suspense: false
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(ViewUserCustomBenefitDrawerContent, { userBenefit, userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [] }) });
};
export const ViewUserCustomBenefitDrawerContent = ({
  userBenefit,
  userFamilyMembers
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { polyglot } = usePolyglot();
  const hasOpeningBalance = isUsingOpeningBalance((_a = userBenefit.customBenefit) == null ? void 0 : _a.type);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g5, justifyContent: "space-between" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_c = (_b = userBenefit.customBenefit) == null ? void 0 : _b.name) != null ? _c : polyglot.t("BenefitModule.userBenefit") }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("General.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: userBenefit.userId, sx: { mt: spacing.m5 }, nameSx: __spreadValues({}, themeFonts.title4) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.effectiveDate") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: userBenefit.effectiveDate })
    ] }),
    hasOpeningBalance && /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("BenefitModule.openingBalance") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: formatMoney({ amount: (_d = userBenefit.openingBalance) != null ? _d : 0, asDecimal: true }) })
    ] }),
    isLoanBenefit((_e = userBenefit.customBenefit) == null ? void 0 : _e.type) && /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("BenefitModule.numberOfInstallments") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: userBenefit.numberOfInstallments })
    ] }),
    !hasOpeningBalance && /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("BenefitModule.employerContribution") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: formatMoney({
        amount: (_f = userBenefit.employerContribution) != null ? _f : 0,
        asDecimal: true
      }) })
    ] }),
    !hasOpeningBalance && /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("BenefitModule.employeeContribution") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: formatMoney({
        amount: (_g = userBenefit.employeeContribution) != null ? _g : 0,
        asDecimal: true
      }) })
    ] }),
    userFamilyMembers.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("BenefitModule.additionalMembers") }),
      userFamilyMembers.map((member) => /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: member.name }, member.id))
    ] })
  ] });
};
