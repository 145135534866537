"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { groupBy } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import { sortByUserProfileTabOrder } from "@/component/dashboard/userDetails/user-details.interface";
import { SETTINGS_PROFILE_TAB_ROUTE } from "@/lib/routes";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { pluralText } from "@/v2/util/string.util";
export const CustomProfileSectionsPage = () => {
  const { data: allForms, isLoading } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false
  });
  const { trackPage } = useJune();
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const tabIds = useMemo(() => {
    if (!allForms) return allForms;
    const formTabs = [...new Set(allForms == null ? void 0 : allForms.map(({ formTab }) => formTab))];
    return sortByUserProfileTabOrder(formTabs, (formTab) => formTab);
  }, [allForms]);
  const formLookup = groupBy(allForms, "formTab");
  useEffect(() => {
    trackPage("Company profile fields settings");
  }, []);
  const tableData = useMemo(() => {
    const dataForTable = (tabIds || []).map((tabId) => {
      var _a;
      const fieldCount = (_a = formLookup == null ? void 0 : formLookup[tabId]) == null ? void 0 : _a.flatMap((item) => item.fields);
      const sectionCount = formLookup == null ? void 0 : formLookup[tabId].length;
      return {
        name: translateCustomFieldCategory(tabId, polyglot),
        fieldCount: (fieldCount == null ? void 0 : fieldCount.length) || 0,
        sectionCount: sectionCount || 0,
        action: () => routerHistory.push(generatePath(SETTINGS_PROFILE_TAB_ROUTE, { tabId }))
      };
    });
    return dataForTable;
  }, [tabIds, formLookup, polyglot, routerHistory]);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, children: /* @__PURE__ */ jsx(SettingsSectionContent, { title: polyglot.t("CompanyProfileSectionsPage.settingHeader"), contentWidth: "100%", children: /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          contentWidth: "100%",
          items: [
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: tableData.map((item) => /* @__PURE__ */ jsx(
                SettingsItemCard,
                {
                  title: item.name,
                  boxSx: responsiveCardStyle,
                  contentItemsSets: [
                    {
                      name: `${item == null ? void 0 : item.sectionCount} section${pluralText(item == null ? void 0 : item.sectionCount)}`,
                      type: ContentItemType.chip,
                      textColor: "DarkGrey",
                      backgroundColor: "white",
                      border: "middle"
                    },
                    {
                      name: `${item == null ? void 0 : item.fieldCount} field${pluralText(item == null ? void 0 : item.fieldCount)}`,
                      type: ContentItemType.chip,
                      textColor: "DarkGrey",
                      backgroundColor: "white",
                      border: "middle"
                    }
                  ],
                  boxAction: item.action
                },
                item.name
              )) })
            }
          ]
        }
      ]
    }
  ) }) });
};
