"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { OnboardingScopes } from "@/v2/feature/onboarding/onboarding.scopes";
import { goToEditTemplate } from "@/v2/feature/onboarding/onboarding.util";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
import { pluralText } from "@/v2/util/string.util";
export const OnboardingSettings = ({ templates: initialTemplates, refresh }) => {
  const { polyglot } = usePolyglot();
  const sortTemplatesByName = useCallback(
    (templates2) => templates2.sort((a, b) => a.name.localeCompare(b.name, void 0, { sensitivity: "base" })),
    []
  );
  const [templates, setTemplates] = useState(sortTemplatesByName(initialTemplates));
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const refreshTemplates = useCallback(() => {
    OnboardingAPI.getOnboardingTemplates().then(sortTemplatesByName).then(setTemplates).catch(
      (error) => showMessage(
        polyglot.t("OnboardingSettings.errorMessages.load", { errorMessage: nestErrorMessage(error) }),
        "error"
      )
    );
  }, [showMessage, sortTemplatesByName, polyglot]);
  useEffect(refreshTemplates, [refreshTemplates]);
  const closeOnboardingSettings = useCallback(() => routerHistory.goBack(), [routerHistory]);
  useEscapeKey(closeOnboardingSettings);
  const createNewTemplate = useCallback(() => __async(void 0, null, function* () {
    try {
      setCreatingTemplate(true);
      const currentTemplates = yield OnboardingAPI.getOnboardingTemplates();
      let newTemplateName;
      for (let i = 1; ; i++) {
        const templateName = polyglot.t("OnboardingSettings.flowNumber", { num: i.toString() });
        if (currentTemplates.find((t) => t.name === templateName)) {
          continue;
        }
        newTemplateName = templateName;
        break;
      }
      const template = yield OnboardingAPI.createOnboardingTemplate({
        name: newTemplateName,
        template: {
          basic: true
        }
      });
      refresh == null ? void 0 : refresh();
      goToEditTemplate(routerHistory, template.templateId, "new-template");
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(
          polyglot.t("OnboardingSettings.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
      setCreatingTemplate(false);
    }
  }), [refresh, routerHistory, showMessage, polyglot]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: __spreadProps(__spreadValues({}, spacing.pt20), { pb: 0 }), children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("OnboardingSettings.flows"),
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          hasScopes(OnboardingScopes.CREATE_TEMPLATE, scopesContext) && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("OnboardingSettings.add"),
              type: "button",
              onClick: () => createNewTemplate(),
              loading: creatingTemplate,
              colorVariant: "primary",
              sizeVariant: "small",
              style: { width: "fit-content", marginTop: "20px" }
            }
          )
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: templates.map((item) => {
                      var _a, _b;
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: item.name,
                          boxSx: responsiveCardStyle,
                          contentItemsSets: [
                            {
                              name: `${((_a = Object.keys(item == null ? void 0 : item.template)) == null ? void 0 : _a.length) || 0} item${pluralText(
                                ((_b = Object.keys(item == null ? void 0 : item.template)) == null ? void 0 : _b.length) || 0
                              )}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ],
                          boxActionDisabled: !hasScopes(OnboardingScopes.EDIT_TEMPLATE, scopesContext),
                          boxAction: () => goToEditTemplate(routerHistory, item.templateId, "edit-template"),
                          advanceActionHidden: !hasScopes(OnboardingScopes.DELETE_TEMPLATE, scopesContext),
                          advanceActionOptions: [
                            {
                              label: "Delete",
                              icon: /* @__PURE__ */ jsx(Trash, {}),
                              handler: (e) => {
                                try {
                                  OnboardingAPI.deleteOnboardingTemplate(item.templateId).then(refreshTemplates);
                                } catch (error) {
                                  showMessage(
                                    polyglot.t("OnboardingSettings.errorMessages.delete", {
                                      errorMessage: nestErrorMessage(error)
                                    }),
                                    "error"
                                  );
                                }
                                e == null ? void 0 : e.stopPropagation();
                              }
                            }
                          ]
                        },
                        item.name
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] });
};
