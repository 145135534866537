"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@v2/components/divider.component";
import { formatMoney, formatPercentage } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Plan } from "@/models/subscription.model";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import {
  BillingCurrency,
  DiscountTypeValues
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface";
import { formatShortDate } from "@/v2/infrastructure/date/date-format.util";
const TableRow = ({
  column1,
  plan,
  planName,
  listPrice,
  column3,
  column2 = "",
  showDivider = false,
  boldModule = false,
  boldPrice = false,
  nonTableRow = false
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between",
          height: showDivider ? "50px" : "40px",
          alignItems: "center"
        },
        children: [
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({ width: "32%" }, boldModule ? themeFonts.title4 : themeFonts.caption), children: [
            column1,
            plan && /* @__PURE__ */ jsx(
              Typography,
              {
                component: "span",
                sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                  color: themeColors.Grey,
                  ml: spacing.m5
                }),
                children: plan === Plan.FREE ? "Free" : "Pro"
              }
            ),
            planName && /* @__PURE__ */ jsx(
              Typography,
              {
                component: "span",
                sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                  color: themeColors.Grey,
                  ml: spacing.m5
                }),
                children: planName
              }
            )
          ] }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ width: "10%" }, boldModule ? themeFonts.title4 : themeFonts.caption), children: listPrice }),
          !nonTableRow && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ width: "40%" }, themeFonts.caption), children: column2 ? column2 : /* @__PURE__ */ jsx(EmptyCell, {}) }),
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadValues({
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                width: "70px"
              }, boldPrice ? themeFonts.title4 : themeFonts.caption),
              children: column3
            }
          )
        ]
      }
    ),
    showDivider && /* @__PURE__ */ jsx(Divider, {})
  ] });
};
export const CompanySubscriptionsTable = ({ subscriptionDetailsForCompany }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { polyglot } = usePolyglot();
  const totalCost = (_b = (_a = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.billResult) == null ? void 0 : _a.totalCost) != null ? _b : 0;
  const noOfActiveUsers = (_d = (_c = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.billResult) == null ? void 0 : _c.seatMultiplier) != null ? _d : 1;
  const subscriptionForCompany = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription;
  const currencyForCompany = (_e = subscriptionForCompany == null ? void 0 : subscriptionForCompany.currency) != null ? _e : BillingCurrency.GBP;
  const getDiscountAmountFromPlan = (module, currency) => {
    const { discountType, discountValue, discountValid, discountEndDate } = module;
    const finalDiscountValue = discountValue != null ? discountValue : 0;
    if (!discountValid || !finalDiscountValue) return void 0;
    const discountRatio = discountType === DiscountTypeValues.FIXED ? formatMoney({ locale: "en-US", amount: finalDiscountValue, currency }) : discountType === DiscountTypeValues.PERCENTAGE ? formatPercentage(finalDiscountValue, 0) : "";
    const discountExpiryDate = discountValid && discountEndDate ? formatShortDate(new Date(discountEndDate)) : void 0;
    return discountExpiryDate ? `${discountRatio} (expiring ${discountExpiryDate})` : discountRatio;
  };
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", height: "40px", alignItems: "center" }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({ width: "32%" }, themeFonts.captionSmall), { color: themeColors.Grey }), children: [
        " ",
        polyglot.t("SubscriptionsTable.subscription")
      ] }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({ width: "10%" }, themeFonts.captionSmall), { color: themeColors.Grey }), children: [
        " ",
        polyglot.t("SubscriptionsTable.listPrice")
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ width: "40%" }, themeFonts.captionSmall), { color: themeColors.Grey }), children: polyglot.t("SubscriptionsTable.discount") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ width: "70px" }, themeFonts.captionSmall), { color: themeColors.Grey }), children: polyglot.t("SubscriptionsTable.pricePerSeat") })
    ] }),
    /* @__PURE__ */ jsx(Divider, {}),
    ((_f = subscriptionForCompany == null ? void 0 : subscriptionForCompany.subscribedPlans) == null ? void 0 : _f.length) && ((_g = subscriptionForCompany == null ? void 0 : subscriptionForCompany.subscribedPlans) == null ? void 0 : _g.length) > 0 ? subscriptionForCompany == null ? void 0 : subscriptionForCompany.subscribedPlans.map((eachModule) => {
      const finalModuleCost = eachModule.discountValid ? (eachModule == null ? void 0 : eachModule.pricePerSeat) && (eachModule == null ? void 0 : eachModule.pricePerSeat[currencyForCompany]) : eachModule.cost[currencyForCompany];
      return /* @__PURE__ */ jsx(
        TableRow,
        {
          listPrice: formatMoney({
            locale: "en-US",
            amount: eachModule.cost[currencyForCompany],
            currency: currencyForCompany
          }),
          column1: eachModule.planName,
          column2: getDiscountAmountFromPlan(eachModule, currencyForCompany),
          column3: formatMoney({
            locale: "en-US",
            amount: finalModuleCost != null ? finalModuleCost : 0,
            currency: currencyForCompany
          }),
          showDivider: true
        },
        eachModule.planDefinitionId
      );
    }) : /* @__PURE__ */ jsx(Typography, { variant: "h6", children: "No modules subscribed to" }),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: polyglot.t("SubscriptionsTable.subscriptionFeePerUser"),
        column3: formatMoney({ locale: "en-US", amount: totalCost / noOfActiveUsers, currency: currencyForCompany }),
        boldPrice: true,
        nonTableRow: true
      }
    ),
    /* @__PURE__ */ jsx(TableRow, { column1: polyglot.t("SubscriptionsTable.seats"), column3: `${noOfActiveUsers}`, boldPrice: true, nonTableRow: true }),
    /* @__PURE__ */ jsx(TableRow, { column1: "Billing currency", column3: `${currencyForCompany}`, boldPrice: true, nonTableRow: true }),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: polyglot.t("SubscriptionsTable.totalSubscription"),
        column3: formatMoney({ locale: "en-US", amount: totalCost, currency: currencyForCompany }),
        boldModule: true,
        boldPrice: true,
        nonTableRow: true
      }
    )
  ] });
};
