"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledMenu = {
  //select paper
  "& div.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
    backgroundColor: themeColors.white,
    boxShadow: "0px 2px 20px 0px #0D0D0E1A",
    borderRadius: radius.br10,
    scrollbarWidth: "none"
  },
  //select field menulist
  "& ul.MuiList-root.MuiList-padding.MuiMenu-list ": {
    padding: "0px"
  },
  //select field menuitem
  "& li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root": __spreadProps(__spreadValues({
    color: "black"
  }, themeFonts.caption), {
    minHeight: "20px",
    paddingTop: spacing.m10,
    paddingBottom: spacing.m10,
    "&.Mui-selected": __spreadValues({
      color: themeColors.DarkGrey
    }, themeFonts.title4)
  })
};
export const StyledMenuItem = styled(MenuItem)(() => ({
  "&.MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: themeColors.Background
    }
  },
  '&[aria-selected="true"]': {
    backgroundColor: "#fff !important",
    borderColor: "transparent"
  },
  '&.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
    backgroundColor: "#fff !important"
  }
}));
export const getSelectProps = {
  IconComponent: ArrowDownACIcon,
  MenuProps: { sx: __spreadValues({}, StyledMenu) }
};
export const iconSize = { width: 14, height: 14 };
export const iconSize20 = { width: 20, height: 20 };
