"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { LOGIN_ROUTE } from "@/lib/routes";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { MFA_CODE_LENGTH } from "@/v2/feature/auth/auth.dto";
import { performLogin } from "@/v2/feature/auth/auth.util";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { MFACodeEntry } from "@/v2/feature/auth/components/auth-mfa-code-entry.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { spacing } from "@/v2/styles/spacing.styles";
const validationSchema = yup.object({
  mfaCode: yup.string().required()
});
export const AuthLogin2FAPage = () => {
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const state = routerLocation.state;
  const [submittingCode, setSubmittingCode] = useState(false);
  const [errorReason, setErrorReason] = useState("");
  const { identifyUser } = useJune();
  const checkCodeAndSignIn = useCallback(
    (username, password, mfaCode) => {
      (() => __async(void 0, null, function* () {
        var _a;
        setErrorReason("");
        setSubmittingCode(true);
        try {
          yield performLogin(routerHistory, routerLocation, { username, password, mfaCode }, identifyUser);
        } catch (err) {
          if (((_a = err.response) == null ? void 0 : _a.status) === 401) {
            setErrorReason("Invalid code. Please try again.");
          } else {
            setErrorReason("Authentication could not be completed");
          }
        } finally {
          setSubmittingCode(false);
        }
      }))();
    },
    [identifyUser, routerHistory, routerLocation]
  );
  const formik = useFormik({
    initialValues: {
      mfaCode: ""
    },
    validationSchema,
    onSubmit: (values) => {
      if (!state) return;
      checkCodeAndSignIn(state.username, state.password, values.mfaCode);
    }
  });
  if (!state) {
    routerHistory.replace(LOGIN_ROUTE);
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsx(
    AuthLayout,
    {
      title: "Enter code",
      description: `We've sent a ${MFA_CODE_LENGTH}-digit code to your work email`,
      submit: formik.handleSubmit,
      children: /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
        /* @__PURE__ */ jsx(
          MFACodeEntry,
          {
            disabled: submittingCode,
            error: !!errorReason,
            helperText: errorReason,
            onCodeComplete: (mfaCode) => formik.setValues({ mfaCode }),
            sx: { mt: spacing.m30 }
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Confirm",
            fullWidth: true,
            loading: submittingCode,
            style: { marginTop: "40px" },
            sizeVariant: "large",
            colorVariant: "primary"
          }
        )
      ] })
    }
  );
};
