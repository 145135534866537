"use strict";
import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const defaultRequest = {
  reference: "",
  amount: 0,
  category: "",
  type: "",
  currency: "GBP"
};
const defaultContext = {
  requests: [defaultRequest]
};
const defaultState = [defaultContext, (_) => {
}];
const PaymentContext = createContext(defaultState);
export const usePaymentContext = () => {
  const contextState = useContext(PaymentContext);
  if (contextState === null) {
    throw new Error("useMakePaymentContext must be used within a MakePaymentContextProvider tag");
  }
  return contextState;
};
export const getRequestFromPayment = (payment) => ({
  reference: payment.reference,
  amount: payment.amount,
  category: payment.category,
  type: payment.type,
  userId: payment.userId,
  currency: payment.currency
});
export const PaymentContextProvider = ({ children, payments }) => {
  const ctx = payments ? { payments, requests: payments.map((payment) => getRequestFromPayment(payment)) } : defaultContext;
  const [state, setState] = useState(ctx);
  return /* @__PURE__ */ jsx(PaymentContext.Provider, { value: [state, setState], children });
};
