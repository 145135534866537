"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@v2/styles/spacing.styles";
export const SettingsReadOnlyLine = ({ field, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(Box, { sx: { width: "240px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: field }) }),
    /* @__PURE__ */ jsx(Box, { sx: { width: "390px" }, children: ["string", "number"].includes(typeof value) ? /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value }) : value })
  ] });
};
