"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const SCGeneralRollingStateModal = ({
  surveyCycle,
  refresh,
  isOpen,
  setIsOpen,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SCGeneralRollingStateContent, { surveyCycle, onClose, refresh }) });
};
export const SCGeneralRollingStateContent = ({ surveyCycle, onClose, refresh }) => {
  const [showMessage] = useMessage();
  const handleStateOfRollingReview = () => __async(void 0, null, function* () {
    try {
      if (!surveyCycle) {
        showMessage("Cycle does not exist", "error");
        return;
      }
      yield SurveyCycleAPI.updateStateOfRollingSurvey(surveyCycle.id);
      showMessage("Successfully updated the cycle", "success");
      refresh == null ? void 0 : refresh();
      onClose();
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  });
  if (!surveyCycle) return /* @__PURE__ */ jsx(Fragment, { children: "Not found" });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: surveyCycle.state === CycleState.Ongoing ? "Pause cycle" : "Reactivate cycle" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Pausing a rolling cycle will retain all collected answers, new invites will not be sent out. Reactivate this cycle when you are ready to start inviting participants again." }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { onClick: onClose, sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, children: "Cancel" }),
      /* @__PURE__ */ jsx(ButtonComponent, { onClick: handleStateOfRollingReview, sizeVariant: "medium", colorVariant: "primary", fullWidth: true, children: surveyCycle.state === CycleState.Ongoing ? "Pause" : "Reactivate" })
    ] })
  ] });
};
