"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2, useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { AppMappingPage } from "./features/app-details/pages/app-mapping.page";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as RejectedGrey } from "@/images/side-bar-icons/Rejected-grey.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import {
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE,
  APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE,
  APP_INTEGRATION_DETAILS_GROUPS_ROUTE,
  APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE,
  APP_INTEGRATION_DETAILS_MAPPING_ROUTE,
  APP_INTEGRATION_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
  APPS_COMPANY_OVERVIEW_ROUTE,
  APPS_PERSONAL_OVERVIEW_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { appStubToName } from "@/v2/feature/app-integration/app-integration.interface";
import {
  getAppPageConfig,
  REVOKE_ACCESS_LIST,
  useAppDetails,
  useFetchCandidates,
  useFetchEmployments,
  useFetchGroups,
  useFetchUsers
} from "@/v2/feature/app-integration/app-router.util";
import { isAppOwner } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { AppAboutPage } from "@/v2/feature/app-integration/features/app-details/pages/app-about.page";
import { AppCandidateManagementPage } from "@/v2/feature/app-integration/features/app-details/pages/app-candidate-management.page";
import { AppEmploymentManagementPage } from "@/v2/feature/app-integration/features/app-details/pages/app-employment-management.page";
import { AppExternalNotEnrolledPage } from "@/v2/feature/app-integration/features/app-details/pages/app-external-not-enrolled.page";
import { AppGroupManagementPage } from "@/v2/feature/app-integration/features/app-details/pages/app-group-management.page";
import { AppInsightsPage } from "@/v2/feature/app-integration/features/app-details/pages/app-insights.page";
import { AppTeamAccessPage } from "@/v2/feature/app-integration/features/app-details/pages/app-team-access.page";
import { AppUserSettingPage } from "@/v2/feature/app-integration/features/app-details/pages/app-user-setting.page";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const getIconForAppStatus = (appStatus) => {
  if (appStatus && ["Active", "Imported", "Onboarded in Zelt"].includes(appStatus))
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(OkGreen, { style: { fill: themeColors.Green } }),
      appStatus
    ] });
  if (["Invited"].includes(appStatus != null ? appStatus : ""))
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Waiting, { style: { fill: themeColors.DarkGrey } }),
      appStatus
    ] });
  if (["Pending", "Scheduled", "Hired"].includes(appStatus != null ? appStatus : ""))
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Waiting, { style: { fill: themeColors.middleGrey } }),
      appStatus
    ] });
  if (["No access", "Suspended"].includes(appStatus != null ? appStatus : ""))
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(RejectedGrey, {}),
      appStatus
    ] });
  return /* @__PURE__ */ jsx(Fragment, {});
};
export function AppIntegrationDetailedCompanyRouter() {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const { appStub } = useParams();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(currentUser);
  const [showMessage] = useMessage();
  const [APIError, setAPIError] = useState(false);
  const handleAppAPIError = useCallback(
    (err) => __async(this, null, function* () {
      setAPIError(true);
      if (REVOKE_ACCESS_LIST.includes(err.response.data.statusCode)) {
        yield AppIntegrationAPI.invalidateApp(appStub);
        routerHistory.push(APP_INTEGRATION_DETAILS_ABOUT_ROUTE);
      }
    }),
    [appStub, routerHistory]
  );
  const { app, setApp } = useAppDetails(appStub, handleAppAPIError);
  const { loadingUsers, users, notEnrolledUsers, fetchUsers } = useFetchUsers(
    appStub,
    showMessage,
    handleAppAPIError,
    app,
    setAPIError
  );
  const { groupMembershipList, fetchGroups } = useFetchGroups(appStub, showMessage, app);
  const { employmentList, countriesForEmployment, loadingEmployments, fetchEmployments } = useFetchEmployments(
    appStub,
    showMessage,
    app
  );
  const { candidateList, loadingCandidates, importedCandidateList, fetchCandidates } = useFetchCandidates(
    appStub,
    showMessage,
    handleAppAPIError,
    app
  );
  const hasAppsAllOrAppOwner = useMemo(
    () => checkScopes(globalState.user, ["apps:all"], scopesContext) || isAppOwner(currentUser.userId, app),
    [globalState.user, scopesContext, currentUser.userId, app]
  );
  const error = useMemo(() => APIError, [APIError]);
  const hideAppSettings = !(app == null ? void 0 : app.authorised) || !hasAppsAllOrAppOwner;
  const customTitle = useMemo(() => {
    return /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g10,
          alignItems: "center",
          justifyContent: "center"
        },
        children: [
          /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx("img", { src: `/app-icons-v2/images/${appStub}.png`, width: 60, alt: appStub }) }),
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: appStubToName[appStub] })
        ]
      }
    );
  }, [appStub]);
  return /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        pageConfig: getAppPageConfig(polyglot, currentUser, scopesContext, appStub, app, hideAppSettings),
        showBack: true,
        backPath: routerLocation.pathname.includes("company") ? APPS_COMPANY_OVERVIEW_ROUTE : APPS_PERSONAL_OVERVIEW_ROUTE,
        type: "Domain",
        customTitle
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_CANDIDATES_ROUTE,
          component: () => /* @__PURE__ */ jsx(
            AppCandidateManagementPage,
            {
              appStub,
              candidateList,
              importedCandidateList: importedCandidateList != null ? importedCandidateList : [],
              loading: loadingCandidates != null ? loadingCandidates : false,
              onAppChange: fetchCandidates,
              app,
              error,
              hasAppsAllOrAppOwner,
              refreshCandidates: fetchCandidates
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_EMPLOYMENTS_ROUTE,
          component: () => /* @__PURE__ */ jsx(
            AppEmploymentManagementPage,
            {
              appStub,
              teamAccessUserList: users,
              countriesForEmployment,
              employmentList,
              loading: loadingEmployments,
              onAppChange: fetchEmployments,
              app,
              error,
              hasAppsAllOrAppOwner
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_INSIGHTS_ROUTE,
          component: () => /* @__PURE__ */ jsx(AppInsightsPage, { currentUser, appStub, app })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
          component: () => /* @__PURE__ */ jsx(AppAboutPage, { currentUser, appStub, app })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
          component: () => /* @__PURE__ */ jsx(
            AppTeamAccessPage,
            {
              appStub,
              teamAccessUserList: users,
              externalUserList: notEnrolledUsers,
              groupMemberships: groupMembershipList,
              loading: loadingUsers,
              onAppChange: fetchUsers,
              app,
              error,
              hasAppsAllOrAppOwner,
              setApp
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_USER_EXTERNAL_DIRECTORY_ROUTE,
          component: () => /* @__PURE__ */ jsx(
            AppExternalNotEnrolledPage,
            {
              appStub,
              teamAccessUserList: users,
              externalUserList: notEnrolledUsers,
              groupMemberships: groupMembershipList,
              loading: loadingUsers,
              onAppChange: fetchUsers,
              app,
              error,
              hasAppsAllOrAppOwner
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_GROUPS_ROUTE,
          component: () => /* @__PURE__ */ jsx(
            AppGroupManagementPage,
            {
              appStub,
              teamAccessUserList: users,
              externalUserList: notEnrolledUsers,
              onAppChange: fetchGroups,
              app,
              error,
              hasAppsAllOrAppOwner
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_MAPPING_ROUTE,
          component: () => /* @__PURE__ */ jsx(AppMappingPage, { appStub, app })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["apps"],
          context: scopesContext,
          path: APP_INTEGRATION_DETAILS_SETTINGS_ROUTE,
          component: AppUserSettingPage
        }
      )
    ] })
  ] });
}
