"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ChipComponentWithIcon } from "@v2/components/chip/chip-with-icon.component";
import { DeviceActionChip } from "@v2/components/chip/device-shipping-chip.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell, IconEmptyCell } from "@v2/components/table/empty-cell.component";
import { sortDate, sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceTransitDrawer } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/device-transit-action-item.component";
import { ConfigurableDeviceStatus, DevicePossessionType } from "@v2/feature/device/device.interface";
import {
  getDeviceLastActiveInDays,
  getDeviceOwnerByDevicePossessionTable,
  getModelImage
} from "@v2/feature/device/device.util";
import { getEnrolmentDeviceStatus } from "@v2/feature/device/features/devices-company/components/configurable-device-view.component";
import { DeviceOrderDrawer } from "@v2/feature/device/features/devices-company/components/device-order-drawer.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { keyBy } from "lodash";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Device } from "@/images/side-bar-icons/Device.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Order } from "@/images/side-bar-icons/OrderBag.svg";
import { ReactComponent as Shipping } from "@/images/side-bar-icons/Shipping.svg";
const formatDate = (date) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};
export const DevicesTable = ({
  devices,
  handleRowClick,
  hiddenColumns,
  loading,
  fixedLastColumn,
  refresh
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerShippingOpen, setIsDrawerShippingOpen] = useState(false);
  const [selectedDeviceTransitId, setSelectedDeviceTransitId] = useState(null);
  const [selectedConfigurableDeviceOrder, setSelectedConfigurableDeviceOrder] = useState(
    null
  );
  const getUserName = useCallback(
    (assignedUserId) => {
      var _a2;
      const user = getCachedUserById(assignedUserId);
      return polyglot.t((_a2 = user == null ? void 0 : user.displayName) != null ? _a2 : "");
    },
    [getCachedUserById, polyglot]
  );
  function stripHtmlTags(input) {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
  }
  const statusOrder = useMemo(
    () => ({
      [ConfigurableDeviceStatus.shipping]: 1,
      [ConfigurableDeviceStatus.pending]: 2,
      [ConfigurableDeviceStatus.order]: 3,
      [ConfigurableDeviceStatus.inUse]: 4,
      [ConfigurableDeviceStatus.inventory]: 5
    }),
    []
  );
  const customStatusSort = useCallback(
    (rowA, rowB) => {
      const statusA = statusOrder[rowA.original.status] || 9;
      const statusB = statusOrder[rowB.original.status] || 9;
      return statusA - statusB;
    },
    [statusOrder]
  );
  const handleOpenDrawer = useCallback((deviceTransitId) => {
    setSelectedDeviceTransitId(deviceTransitId);
    setIsDrawerShippingOpen(true);
  }, []);
  const handleOpenDrawerDeviceOrder = useCallback((configurableDeviceData) => {
    setSelectedConfigurableDeviceOrder(configurableDeviceData);
    setIsDrawerOpen(true);
  }, []);
  const getDeviceStatus = useCallback(
    (cd) => {
      const statusMap = {
        [ConfigurableDeviceStatus.inUse]: /* @__PURE__ */ jsx(
          ChipComponentWithIcon,
          {
            name: "In use",
            icon: /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
            backgroundColor: "white",
            textColor: "DarkGrey",
            border: "light",
            textVariant: "caption"
          }
        ),
        [ConfigurableDeviceStatus.shipping]: /* @__PURE__ */ jsx(
          DeviceActionChip,
          {
            name: "Shipping",
            icon: /* @__PURE__ */ jsx(Shipping, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "black",
            border: "dark",
            textVariant: "caption",
            onClick: (event) => {
              event.stopPropagation();
              handleOpenDrawer(cd.deviceTransitId);
            }
          }
        ),
        [ConfigurableDeviceStatus.pending]: /* @__PURE__ */ jsx(
          DeviceActionChip,
          {
            name: "Pending",
            icon: /* @__PURE__ */ jsx(Clock, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "black",
            border: "dark",
            textVariant: "caption",
            onClick: (event) => {
              event.stopPropagation();
              handleOpenDrawer(cd.deviceTransitId);
            }
          }
        ),
        [ConfigurableDeviceStatus.inventory]: /* @__PURE__ */ jsx(
          ChipComponentWithIcon,
          {
            name: "Inventory",
            icon: /* @__PURE__ */ jsx(Device, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "DarkGrey",
            border: "light",
            textVariant: "caption"
          }
        ),
        [ConfigurableDeviceStatus.order]: /* @__PURE__ */ jsx(
          DeviceActionChip,
          {
            name: "Order",
            icon: /* @__PURE__ */ jsx(Order, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "DarkGrey",
            border: "dark",
            textVariant: "caption",
            onClick: (event) => {
              event.stopPropagation();
              handleOpenDrawerDeviceOrder(cd);
            }
          }
        )
      };
      return statusMap[cd.status];
    },
    [handleOpenDrawer, handleOpenDrawerDeviceOrder]
  );
  const tableColumns = useMemo(
    () => [
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.device"),
        accessorFn: (row) => row,
        id: "device",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2;
          return (_a2 = item.devicePossession.device) == null ? void 0 : _a2.modelName;
        }),
        cell: (info) => {
          var _a2, _b, _c, _d, _e, _f, _g;
          const configurableDevice = info.row.original;
          const modelName = configurableDevice.status === "order" ? (_b = (_a2 = configurableDevice.order) == null ? void 0 : _a2.deviceModel) == null ? void 0 : _b.modelName : (_c = configurableDevice.devicePossession.device) == null ? void 0 : _c.modelName;
          const deviceType = configurableDevice.status === "order" ? (_e = (_d = configurableDevice.order) == null ? void 0 : _d.deviceModel) == null ? void 0 : _e.type : (_f = configurableDevice.devicePossession.device) == null ? void 0 : _f.type;
          const deviceId = configurableDevice.status === "order" ? (_g = configurableDevice.order) == null ? void 0 : _g.deviceId : configurableDevice.devicePossession.deviceId;
          return modelName && deviceType ? /* @__PURE__ */ jsxs(
            Box,
            {
              sx: { display: "flex", gap: spacing.g10, alignItems: "center", height: "inherit" },
              children: [
                /* @__PURE__ */ jsx(
                  Box,
                  {
                    sx: {
                      objectFit: "contain"
                    },
                    children: getModelImage(deviceType, modelName, {
                      width: "30px",
                      height: "30px"
                    })
                  },
                  `model-img-cell-${deviceId}`
                ),
                /* @__PURE__ */ jsx("div", { children: modelName }, `model-name-${deviceId}`)
              ]
            },
            `model-cell-${deviceId}`
          ) : /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 1 }, children: /* @__PURE__ */ jsx(IconEmptyCell, {}) }, `${info.row.id}-${info.row.index}`);
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.usedBy"),
        accessorFn: (row) => row,
        id: "usedBy",
        enableSorting: false,
        cell: (info) => {
          var _a2, _b;
          const devicePossession = info.getValue().devicePossession;
          if (devicePossession.possessionType === DevicePossessionType.User && devicePossession.possessionId)
            return /* @__PURE__ */ jsxs(
              Box,
              {
                sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.m5
                }),
                children: [
                  /* @__PURE__ */ jsx(
                    UserAvatar,
                    {
                      userId: devicePossession.possessionId,
                      size: "xxsmall"
                    },
                    `avatar-${devicePossession.id}`
                  ),
                  /* @__PURE__ */ jsx("div", { children: getUserName(devicePossession.possessionId) }, `user-${devicePossession.id}`)
                ]
              },
              devicePossession.id
            );
          return /* @__PURE__ */ jsx(Box, { children: getDeviceOwnerByDevicePossessionTable(
            devicePossession,
            info.getValue().sites.find((item) => item.id === devicePossession.possessionId),
            polyglot,
            (_b = (_a2 = getCachedUserById(devicePossession.possessionId)) == null ? void 0 : _a2.displayName) != null ? _b : ""
          ) }, devicePossession.id);
        },
        maxSize: 200,
        minSize: 140
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: true,
        sortingFn: customStatusSort,
        cell: ({ row }) => {
          var _a2;
          const configurableDevice = row.original;
          return (_a2 = getDeviceStatus(configurableDevice)) != null ? _a2 : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.deviceName"),
        accessorFn: (row) => row,
        id: "deviceName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.deviceName) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a2, _b;
          return ((_a2 = devicePossession.device) == null ? void 0 : _a2.deviceName) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.deviceName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.enrolment"),
        accessorFn: (row) => row,
        id: "enrolment",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.enrollmentStatus) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { enrolment }
          }
        }) => enrolment ? getEnrolmentDeviceStatus(enrolment) : /* @__PURE__ */ jsx(EmptyCell, {}),
        maxSize: 110,
        minSize: 100
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.serialNumber"),
        accessorFn: (row) => row,
        id: "serialNumber",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.serialNumber) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a2, _b;
          return ((_a2 = devicePossession.device) == null ? void 0 : _a2.serialNumber) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.serialNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.model"),
        accessorFn: (row) => row,
        id: "modelNumber",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.modelNumber) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a2, _b;
          return ((_a2 = devicePossession.device) == null ? void 0 : _a2.modelNumber) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.modelNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 150,
        minSize: 100
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.lastActive"),
        accessorFn: (row) => row,
        id: "lastActive",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.lastCheckIn) != null ? _b : void 0;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a2;
          const lastCheckIn = (_a2 = devicePossession.device) == null ? void 0 : _a2.lastCheckIn;
          const deviceLastActiveInDays = getDeviceLastActiveInDays(lastCheckIn);
          return lastCheckIn ? /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [
            formatDate(new Date(lastCheckIn)),
            deviceLastActiveInDays.isOverdue && /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", sx: { color: themeColors.Red, marginLeft: "4px" }, children: [
              deviceLastActiveInDays.daysDifference,
              " days ago"
            ] })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 140,
        minSize: 140
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.storage"),
        accessorFn: (row) => row,
        id: "storage",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.storage) != null ? _b : void 0;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a2, _b;
          return ((_a2 = devicePossession.device) == null ? void 0 : _a2.storage) ? /* @__PURE__ */ jsxs("div", { children: [
            (_b = devicePossession.device) == null ? void 0 : _b.storage,
            " GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.notes"),
        accessorFn: (row) => row,
        id: "customerNotes",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.customerNotes) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a2, _b;
          return ((_a2 = devicePossession.device) == null ? void 0 : _a2.customerNotes) ? /* @__PURE__ */ jsx("div", { children: stripHtmlTags((_b = devicePossession.device) == null ? void 0 : _b.customerNotes) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      },
      {
        header: () => polyglot.t("DeviceDirectoryPage.columns.osVersion"),
        accessorFn: (row) => row,
        id: "osVersion",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2, _b;
          return (_b = (_a2 = item.devicePossession.device) == null ? void 0 : _a2.osVersion) != null ? _b : null;
        }),
        cell: ({
          row: {
            original: { devicePossession }
          }
        }) => {
          var _a2, _b;
          return ((_a2 = devicePossession.device) == null ? void 0 : _a2.osVersion) ? /* @__PURE__ */ jsx("div", { children: (_b = devicePossession.device) == null ? void 0 : _b.osVersion }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 150
      }
    ],
    [customStatusSort, polyglot, getUserName, getCachedUserById, getDeviceStatus]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    isDrawerShippingOpen && selectedDeviceTransitId && /* @__PURE__ */ jsx(
      DeviceTransitDrawer,
      {
        deviceTransitId: selectedDeviceTransitId,
        refresh: () => __async(void 0, null, function* () {
          yield refresh == null ? void 0 : refresh();
        }),
        afterClose: () => setIsDrawerShippingOpen(false),
        isOpen: isDrawerShippingOpen,
        setIsOpen: setIsDrawerShippingOpen
      }
    ),
    isDrawerOpen && selectedConfigurableDeviceOrder && /* @__PURE__ */ jsx(
      DeviceOrderDrawer,
      {
        deviceOrder: selectedConfigurableDeviceOrder.order,
        refresh: () => __async(void 0, null, function* () {
          yield refresh == null ? void 0 : refresh();
          setIsDrawerOpen(false);
          yield refresh == null ? void 0 : refresh();
        }),
        sitesById: keyBy((_a = selectedConfigurableDeviceOrder.sites) != null ? _a : [], "id"),
        reach: "company",
        setIsOpen: setIsDrawerOpen,
        isOpen: isDrawerOpen,
        onClose: () => {
          setIsDrawerOpen(false);
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: devices,
        columnData: tableColumns,
        loading,
        initialSort: [{ id: "status", desc: false }],
        rowClick: handleRowClick,
        hiddenColumns,
        fixedLastColumn,
        stickyHeader: true
      }
    ) })
  ] });
};
