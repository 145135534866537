"use strict";
export var ContractorTypes = /* @__PURE__ */ ((ContractorTypes2) => {
  ContractorTypes2["CONTRACTOR"] = "Contractor";
  ContractorTypes2["EOR"] = "EOR";
  return ContractorTypes2;
})(ContractorTypes || {});
export const getContractTypeOptions = (polyglot) => {
  return [
    { value: "Full-time", label: polyglot.t("getContractTypeOptions.fullTime") },
    { value: "Part-time", label: polyglot.t("getContractTypeOptions.partTime") },
    { value: "Shifts", label: polyglot.t("getContractTypeOptions.shifts") }
  ];
};
export const getEmploymentTypeOptions = (polyglot) => {
  return [
    { value: "Employee", label: polyglot.t("getEmploymentTypeOptions.employee") },
    { value: "Contractor" /* CONTRACTOR */, label: polyglot.t("getEmploymentTypeOptions.contractor") },
    { value: "Consultant", label: polyglot.t("getEmploymentTypeOptions.consultant") },
    { value: "EOR" /* EOR */, label: polyglot.t("getEmploymentTypeOptions.eor") }
  ];
};
export const getUnitTypeOptions = (polyglot) => {
  return [
    { value: "Months", label: polyglot.t("getUnitTypeOptions.months") },
    { value: "Weeks", label: polyglot.t("getUnitTypeOptions.weeks") },
    { value: "Days", label: polyglot.t("getUnitTypeOptions.days") }
  ];
};
export const EMPTY_CHECK = {
  requestId: "0",
  statusCode: 0,
  status: "unknown",
  packageId: "",
  packageName: "",
  packageDescription: "",
  checks: []
};
