"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Dropzone from "react-dropzone-uploader";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/DocumentFull.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import "react-dropzone-uploader/dist/styles.css";
export const UploadPublicImage = ({
  value,
  uploadedFileName = "Uploaded file",
  onChange,
  disabled = false,
  url,
  width = "100%",
  handleDelete,
  restrictedFormats
}) => {
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showMessage] = useMessage();
  const resetKey = 0;
  useEffect(() => {
    if (value) {
      setIsLoading(false);
    }
  }, [value]);
  const validateFile = useCallback(
    (file) => {
      if (restrictedFormats && restrictedFormats.length > 0 && !restrictedFormats.includes(file.file.type)) {
        showMessage(`${polyglot.t("UploadPublicImage.errorMessages.type")}`, "error");
      }
    },
    [showMessage, polyglot, restrictedFormats]
  );
  const handleChangeStatus = useCallback(
    (file, status) => {
      try {
        validateFile(file);
        if (status === "uploading") {
          setUploadProgress(file.meta.percent || 0);
        }
        if (status === "done" && file.xhr) {
          onChange(file.xhr.response);
          setUploadProgress(0);
        } else if (status === "removed") {
          onChange();
          setUploadProgress(0);
        } else if (status === "preparing") {
          setIsLoading(true);
        }
      } catch (error) {
        showMessage(`${polyglot.t("UploadPublicImage.errorMessages.somethingWentWrong")}`, "error");
        file.remove();
      }
    },
    [onChange, showMessage, validateFile, polyglot]
  );
  const initiateDelete = useCallback(() => __async(void 0, null, function* () {
    setIsLoading(true);
    yield handleDelete();
    setIsLoading(false);
  }), [handleDelete]);
  const transitionStyle = {
    transition: "all 0.3s ease"
  };
  return /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g20 }, transitionStyle), children: value && !isLoading ? /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "40px",
        boxSizing: "border-box"
      }, transitionStyle),
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: uploadedFileName })
        ] }),
        /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: initiateDelete, children: /* @__PURE__ */ jsx(TrashIcon, {}) })
      ]
    }
  ) : isLoading ? /* @__PURE__ */ jsx(
    Box,
    {
      sx: __spreadValues({
        width: "100%",
        height: "40px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box"
      }, transitionStyle),
      children: /* @__PURE__ */ jsx(
        LinearProgress,
        {
          variant: "determinate",
          value: uploadProgress,
          sx: {
            width: "100%",
            height: "4px",
            borderRadius: radius.br10
          }
        }
      )
    }
  ) : /* @__PURE__ */ jsx(
    Dropzone,
    {
      styles: {
        dropzone: __spreadValues({
          borderRadius: "8px",
          width,
          border: `1px dashed ${themeColors.Grey}`,
          opacity: !disabled ? 1 : 0,
          height: !disabled ? "40px" : "0px",
          minHeight: "0px",
          boxSizing: "border-box",
          overflow: "hidden"
        }, transitionStyle),
        inputLabel: __spreadProps(__spreadValues({
          textAlign: "center"
        }, themeFonts.caption), {
          color: themeColors.DarkGrey
        })
      },
      getUploadParams: () => ({ url }),
      onChangeStatus: handleChangeStatus,
      maxFiles: 1,
      maxSizeBytes: 10485760,
      canCancel: false,
      accept: restrictedFormats && (restrictedFormats == null ? void 0 : restrictedFormats.toString()),
      inputContent: polyglot.t("UploadPublicImage.dropFile")
    },
    resetKey
  ) });
};
