"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ReactComponent as Colomn } from "@/images/attendance/Colomn.svg";
import { ReactComponent as List } from "@/images/attendance/List.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const buttonStyles = {
  paddingX: spacing.p10,
  paddingY: spacing.p5,
  borderRadius: radius.br20,
  height: "auto",
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  "&:hover": {
    cursor: "pointer"
  }
};
export const SplitSwitchButton = ({
  listViewAction,
  columnViewAction,
  listActive,
  columnActive
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "60px",
        height: "30px",
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: "flex",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx(SwitchButton, { isActive: columnActive, color: themeColors.DarkGrey, action: columnViewAction, children: /* @__PURE__ */ jsx(Colomn, __spreadProps(__spreadValues({}, iconSize), { stroke: columnActive ? themeColors.white : themeColors.DarkGrey })) }),
        /* @__PURE__ */ jsx(SwitchButton, { isActive: listActive, color: themeColors.DarkGrey, action: listViewAction, children: /* @__PURE__ */ jsx(List, __spreadProps(__spreadValues({}, iconSize), { fill: listActive ? themeColors.white : themeColors.DarkGrey })) })
      ]
    }
  );
};
export const SwitchButton = ({
  isActive,
  color,
  action,
  children
}) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      className: isActive ? "switch-no-hover" : "switch-hover",
      sx: __spreadProps(__spreadValues({}, buttonStyles), {
        color: isActive ? themeColors.white : themeColors.DarkGrey,
        background: isActive ? color : "none",
        transition: "all 0.2s ease-in-out"
      }),
      onClick: action,
      children
    }
  );
};
