"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { OffboardingDrawer } from "@v2/feature/offboarding/components/offboarding-drawer.component";
import { SelectChecklistDrawer } from "@v2/feature/offboarding/components/select-checklist-drawer.component";
import { OffboardingProgressionBar } from "@v2/feature/offboarding/offboarding-process/components/offboarding-progression-bar.component";
import { OffboardingActionCard } from "@v2/feature/offboarding/offboarding-process/offboarding-action-card.component";
import { TaskFormModal } from "@v2/feature/task/components/task-add-modal/task-form-modal.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import { generatePath, useHistory } from "react-router-dom";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import {
  OFFBOARDING_ROUTE,
  USER_DETAILS_PROFILE_ROUTE,
  USER_OFFBOARDING_ABSENCE_ROUTE,
  USER_OFFBOARDING_APPS_ROUTE,
  USER_OFFBOARDING_DEVICES_ROUTE,
  USER_OFFBOARDING_MANAGER_CHANGE_ROUTE,
  USER_OFFBOARDING_TASKS_ROUTE
} from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export var OffboardingStepStatus = /* @__PURE__ */ ((OffboardingStepStatus2) => {
  OffboardingStepStatus2[OffboardingStepStatus2["TODO"] = 0] = "TODO";
  OffboardingStepStatus2[OffboardingStepStatus2["PENDING"] = 1] = "PENDING";
  OffboardingStepStatus2[OffboardingStepStatus2["COMPLETE"] = 2] = "COMPLETE";
  return OffboardingStepStatus2;
})(OffboardingStepStatus || {});
function getIconState(state) {
  if (state === 2 /* COMPLETE */) return "done";
  if (state === 1 /* PENDING */) return "pending";
  return "todo";
}
export const OffboardingProcessPage = ({ userId, offboardingState, refresh, loading }) => {
  const history = useHistory();
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { getScopesContext, hasScopes } = useScopes();
  const [isEditLeaveDateOpen, setIsEditLeaveDateOpen] = useState(false);
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState(false);
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
  const offboardingUser = useMemo(() => getCachedUserById(userId), [userId, getCachedUserById]);
  const showAbsences = useMemo(() => {
    var _a, _b;
    return ((_b = (_a = offboardingState.absenceData) == null ? void 0 : _a.policyMembershipsNo) != null ? _b : 0) > 0;
  }, [offboardingState]);
  const showDevices = useMemo(() => {
    var _a, _b;
    return ((_b = (_a = offboardingState.devicesData) == null ? void 0 : _a.devicePossessionAllTimeNo) != null ? _b : 0) > 0;
  }, [
    offboardingState
  ]);
  const showReportingLine = useMemo(() => {
    var _a;
    return !!((_a = offboardingState.managerChangeData) == null ? void 0 : _a.wasOrIsManager);
  }, [offboardingState]);
  const goToAbsences = useCallback(() => {
    history.push(generatePath(USER_OFFBOARDING_ABSENCE_ROUTE, { userId }));
  }, [userId, history]);
  const {
    hasUserLifecycleManagerScope,
    hasTaskManagerScope,
    hasAbsenceManagerScope,
    hasAppsManagerScope,
    hasDevicesManagerScope,
    hasRoleManagerScope
  } = useMemo(() => {
    const scopeContext = getScopesContext({ userId });
    const hasUserLifecycleManagerScope2 = hasScopes(["user.lifecycle:manager"], scopeContext);
    const hasTaskManagerScope2 = hasScopes(["task:manager"], scopeContext);
    const hasAbsenceManagerScope2 = hasScopes(["absence:manager"], scopeContext);
    const hasAppsManagerScope2 = hasScopes(["apps:manager"], scopeContext);
    const hasDevicesManagerScope2 = hasScopes(["devices:manager"], scopeContext);
    const hasRoleManagerScope2 = hasScopes(["user.role:manager"], scopeContext);
    return {
      hasUserLifecycleManagerScope: hasUserLifecycleManagerScope2,
      hasTaskManagerScope: hasTaskManagerScope2,
      hasAbsenceManagerScope: hasAbsenceManagerScope2,
      hasAppsManagerScope: hasAppsManagerScope2,
      hasDevicesManagerScope: hasDevicesManagerScope2,
      hasRoleManagerScope: hasRoleManagerScope2
    };
  }, [hasScopes, getScopesContext, userId]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: offboardingUser ? polyglot.t("OffboardingPage.offboardingUser", { name: offboardingUser.displayName }) : polyglot.t("OffboardingPage.offboarding"),
        showBack: true,
        backPath: OFFBOARDING_ROUTE,
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "secondary",
            sizeVariant: "small",
            onClick: () => {
              history.push(generatePath(USER_DETAILS_PROFILE_ROUTE, { userId }));
            },
            children: "View profile"
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { width: "600px" }, children: [
      /* @__PURE__ */ jsx(OffboardingProgressionBar, { state: offboardingState }),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.s3 }, children: [
        /* @__PURE__ */ jsx(
          OffboardingActionCard,
          {
            label: polyglot.t("OffboardingPage.terminationDate"),
            iconState: offboardingState.leaveDate ? "done" : "todo",
            buttonLabel: polyglot.t("General.edit"),
            onActionClick: hasUserLifecycleManagerScope ? () => {
              setIsEditLeaveDateOpen(true);
            } : void 0,
            buttonColor: "secondary",
            chips: offboardingState.leaveDate ? [
              {
                text: new LocalDate(offboardingState.leaveDate).toLocaleDateString(void 0, {
                  day: "numeric",
                  month: "short",
                  year: "numeric"
                })
              }
            ] : []
          }
        ),
        /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        /* @__PURE__ */ jsx(
          OffboardingActionCard,
          {
            label: polyglot.t("OffboardingPage.tasks"),
            description: polyglot.t("OffboardingPage.addTasks"),
            iconState: getIconState(offboardingState.tasks),
            actionButton: hasTaskManagerScope && offboardingState.tasksData.tasksNo === 0 ? /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: [
                  {
                    handler: () => {
                      setIsTaskDrawerOpen(true);
                    },
                    label: polyglot.t("OffboardingPage.newTask")
                  },
                  {
                    handler: () => {
                      setIsChecklistDrawerOpen(true);
                    },
                    label: polyglot.t("OffboardingPage.checklist")
                  }
                ],
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: polyglot.t("General.actions"),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end",
                  fullWidth: true
                }
              }
            ) : void 0,
            buttonLabel: polyglot.t("General.view"),
            buttonColor: "secondary",
            onActionClick: hasTaskManagerScope ? () => {
              history.push(generatePath(USER_OFFBOARDING_TASKS_ROUTE, { userId }));
            } : void 0
          }
        ),
        showAbsences && /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        showAbsences && /* @__PURE__ */ jsx(
          OffboardingActionCard,
          {
            label: polyglot.t("OffboardingPage.absence"),
            description: polyglot.t("OffboardingPage.reviewAccruedAbsence"),
            iconState: getIconState(offboardingState.absence),
            buttonLabel: polyglot.t("General.view"),
            buttonColor: "secondary",
            onActionClick: hasAbsenceManagerScope ? goToAbsences : void 0
          }
        ),
        /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        /* @__PURE__ */ jsx(
          OffboardingActionCard,
          {
            label: polyglot.t("OffboardingPage.apps"),
            description: polyglot.t("OffboardingPage.scheduleDeactivation"),
            iconState: getIconState(offboardingState.apps),
            buttonLabel: polyglot.t("General.view"),
            buttonColor: "secondary",
            onActionClick: hasAppsManagerScope ? () => {
              history.push(generatePath(USER_OFFBOARDING_APPS_ROUTE, { userId }));
            } : void 0,
            chips: offboardingState.appsData.activeAppsNo ? [
              {
                text: polyglot.t("OffboardingPage.noOfAccounts", {
                  smart_count: offboardingState.appsData.activeAppsNo
                })
              }
            ] : []
          }
        ),
        showDevices && /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        showDevices && /* @__PURE__ */ jsx(
          OffboardingActionCard,
          {
            label: polyglot.t("OffboardingPage.devices"),
            description: polyglot.t("OffboardingPage.wipeAndReturnDevices"),
            iconState: getIconState(offboardingState.devices),
            buttonLabel: polyglot.t("General.view"),
            buttonColor: "secondary",
            onActionClick: hasDevicesManagerScope && (offboardingState.devicesData.activeDevicesNo > 0 || offboardingState.devicesData.activeOrdersNo > 0) ? () => {
              history.push(generatePath(USER_OFFBOARDING_DEVICES_ROUTE, { userId }));
            } : void 0,
            chips: offboardingState.devicesData.activeDevicesNo > 0 || offboardingState.devicesData.activeOrdersNo > 0 ? [
              {
                text: polyglot.t("OffboardingPage.noOfDevices", {
                  smart_count: offboardingState.devicesData.activeDevicesNo + offboardingState.devicesData.activeOrdersNo
                })
              }
            ] : []
          }
        ),
        showDevices && /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        showReportingLine && /* @__PURE__ */ jsx(
          OffboardingActionCard,
          {
            label: polyglot.t("OffboardingPage.managerChange"),
            description: polyglot.t("OffboardingPage.managerChangeDescription"),
            iconState: getIconState(offboardingState.managerChange),
            buttonLabel: polyglot.t("General.view"),
            buttonColor: "secondary",
            onActionClick: hasRoleManagerScope && offboardingState.managerChangeData.usersNeedingChangeNo > 0 ? () => {
              history.push(generatePath(USER_OFFBOARDING_MANAGER_CHANGE_ROUTE, { userId }));
            } : void 0,
            chips: offboardingState.managerChangeData.usersNeedingChangeNo > 0 ? [
              {
                text: polyglot.t("OffboardingPage.noOfEmployees", {
                  smart_count: offboardingState.managerChangeData.usersNeedingChangeNo
                })
              }
            ] : []
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        OffboardingDrawer,
        {
          isOpen: isEditLeaveDateOpen,
          setIsOpen: setIsEditLeaveDateOpen,
          userId,
          terminationUserEvent: offboardingState.userEvent,
          pushToOffboarding: false,
          refresh
        }
      ),
      /* @__PURE__ */ jsx(
        SelectChecklistDrawer,
        {
          isOpen: isChecklistDrawerOpen,
          setIsOpen: setIsChecklistDrawerOpen,
          userId,
          refresh
        }
      ),
      /* @__PURE__ */ jsx(
        TaskFormModal,
        {
          isOpen: isTaskDrawerOpen,
          setIsOpen: setIsTaskDrawerOpen,
          userId,
          formData: null,
          refresh
        }
      )
    ] })
  ] });
};
