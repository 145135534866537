"use strict";
export const AttendanceReportColumnsOptions = (polyglot) => {
  return [
    { label: polyglot.t("AttendanceReportColumnsOptions.employeeName"), value: "employeeName" },
    { label: polyglot.t("AttendanceReportColumnsOptions.entity"), value: "entity" },
    { label: polyglot.t("AttendanceReportColumnsOptions.role"), value: "role" },
    { label: polyglot.t("AttendanceReportColumnsOptions.reportsTo"), value: "reportsTo" },
    { label: polyglot.t("AttendanceReportColumnsOptions.schedule"), value: "schedule" },
    { label: polyglot.t("AttendanceReportColumnsOptions.logDate"), value: "logDate" },
    { label: polyglot.t("AttendanceReportColumnsOptions.status"), value: "status" },
    { label: polyglot.t("AttendanceReportColumnsOptions.length"), value: "length" },
    { label: polyglot.t("AttendanceReportColumnsOptions.type"), value: "type" },
    { label: polyglot.t("AttendanceReportColumnsOptions.startLatitude"), value: "startLatitude" },
    { label: polyglot.t("AttendanceReportColumnsOptions.startLongitude"), value: "startLongitude" },
    { label: polyglot.t("AttendanceReportColumnsOptions.endLatitude"), value: "endLatitude" },
    { label: polyglot.t("AttendanceReportColumnsOptions.endLongitude"), value: "endLongitude" },
    { label: polyglot.t("AttendanceReportColumnsOptions.notes"), value: "notes" }
  ];
};
