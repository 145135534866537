"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { IconContentActionCard } from "@v2/components/theme-components/icon-content-action-card.component";
import { downloadFileByUrl } from "@v2/feature/documents/documents.util";
import { secondaryCTABtn } from "@v2/styles/buttons.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as Document } from "@/images/side-bar-icons/Document.svg";
import { themeColors } from "@/v2/styles/colors.styles";
export const InsurancePolicyDocuments = ({ insurancePolicy }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: { sm: "400px", md: "800px", lg: "800px" } }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Policy documents" }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap20, mt: spacing.mt20 }, children: insurancePolicy.uploadedDocuments.map((document, index) => {
      var _a;
      return /* @__PURE__ */ jsx(
        IconContentActionCard,
        {
          title: document.name,
          content: (_a = document.note) != null ? _a : "",
          iconMedia: /* @__PURE__ */ jsx(Document, __spreadValues({}, actionIconSize)),
          action: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "end" }, children: /* @__PURE__ */ jsx(
            Button,
            {
              sx: secondaryCTABtn,
              fullWidth: true,
              onClick: () => {
                const attachmentExists = document.attachments && document.attachments.length > 0;
                if (attachmentExists) {
                  const documentUrl = document.attachments[0].url;
                  downloadFileByUrl(documentUrl, documentUrl);
                }
              },
              children: "Download"
            }
          ) }),
          showDivider: true
        },
        index
      );
    }) })
  ] });
};
