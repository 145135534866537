"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { spacing } from "@/v2/styles/spacing.styles";
const UserEmergencyContactSchema = (visibility, polyglot) => Yup.object().shape({
  emergencyName: visibility.emergencyName ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  emergencyNumber: visibility.emergencyNumber ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  emergencyRelationship: visibility.emergencyRelationship ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired()
});
export const UserEmergencyContactSection = ({
  onNext,
  sx,
  emergencyValues,
  userId,
  form
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const defaultFieldsVisibility = useMemo(() => {
    var _a2, _b, _c;
    if (!form)
      return {
        emergencyName: false,
        emergencyNumber: false,
        emergencyRelationship: false
      };
    const emergencyNameField = (_a2 = form.fields) == null ? void 0 : _a2.find((f) => f.isDefault && f.fieldStub === "user.emergencyName");
    const emergencyNumberField = (_b = form.fields) == null ? void 0 : _b.find((f) => f.isDefault && f.fieldStub === "user.emergencyNumber");
    const emergencyRelationshipField = (_c = form.fields) == null ? void 0 : _c.find(
      (f) => f.isDefault && f.fieldStub === "user.emergencyRelationship"
    );
    return {
      emergencyName: Boolean(emergencyNameField && !emergencyNameField.isHidden),
      emergencyNumber: Boolean(emergencyNumberField && !emergencyNumberField.isHidden),
      emergencyRelationship: Boolean(emergencyRelationshipField && !emergencyRelationshipField.isHidden)
    };
  }, [form]);
  const initialValues = useMemo(() => {
    var _a2, _b, _c, _d;
    const values = {};
    if (defaultFieldsVisibility.emergencyName) values.emergencyName = (_a2 = emergencyValues == null ? void 0 : emergencyValues.emergencyName) != null ? _a2 : "";
    if (defaultFieldsVisibility.emergencyNumber) values.emergencyNumber = (_b = emergencyValues == null ? void 0 : emergencyValues.emergencyNumber) != null ? _b : "";
    if (defaultFieldsVisibility.emergencyRelationship)
      values.emergencyRelationship = (_c = emergencyValues == null ? void 0 : emergencyValues.emergencyRelationship) != null ? _c : "";
    values.customUpdates = (_d = emergencyValues == null ? void 0 : emergencyValues.customUpdates) != null ? _d : [];
    return values;
  }, [defaultFieldsVisibility, emergencyValues]);
  const formik = useFormik({
    initialValues,
    validationSchema: UserEmergencyContactSchema(defaultFieldsVisibility, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserEmergencyContactInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.emergencyContactNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("EmergencyContactForm.emergencyContact") }),
    defaultFieldsVisibility.emergencyName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyName",
        label: polyglot.t("EmergencyContactForm.fullName"),
        value: formik.values.emergencyName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("emergencyName", ""),
        helperText: hasSubmitted && formik.errors.emergencyName,
        error: hasSubmitted && !!formik.errors.emergencyName,
        autoFocus: true
      }
    ),
    defaultFieldsVisibility.emergencyNumber && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyNumber",
        label: polyglot.t("EmergencyContactForm.phone"),
        type: "tel",
        value: formik.values.emergencyNumber,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("emergencyNumber", ""),
        helperText: hasSubmitted && formik.errors.emergencyNumber,
        error: hasSubmitted && !!formik.errors.emergencyNumber
      }
    ),
    defaultFieldsVisibility.emergencyRelationship && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyRelationship",
        label: polyglot.t("EmergencyContactForm.relationship"),
        value: formik.values.emergencyRelationship,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("emergencyRelationship", ""),
        helperText: hasSubmitted && formik.errors.emergencyRelationship,
        error: hasSubmitted && !!formik.errors.emergencyRelationship
      }
    ),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: (_a = formik.values.customUpdates) != null ? _a : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
