"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { styled, Tab, Tabs, Typography } from "@mui/material";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { ScopesControl, ScopesInspector } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { checkScopes } from "@/lib/scopes";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const StyledTabs = styled(Tabs)(() => ({
  height: 40,
  minHeight: 40,
  backgroundColor: themeColors.white,
  "& .MuiTabs-flexContainer": {
    height: 40
  },
  "& .MuiTabs-indicator": {
    display: "inherit",
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.DarkGrey,
    width: "inherit",
    height: "3px"
  },
  "& .MuiTabs-scroller": { overflow: "visible !important", height: 40 }
}));
export const StyledTab = styled(Tab)(() => __spreadProps(__spreadValues({
  textTransform: "inherit"
}, themeFonts.caption), {
  color: themeColors.DarkGrey,
  height: 40,
  padding: "8px 1px",
  marginRight: "18px",
  minWidth: "fit-content",
  minHeight: 40,
  borderRadius: 6,
  display: "flex",
  "& > div": {
    marginBottom: "20px"
  },
  "&.Mui-selected": __spreadProps(__spreadValues({}, themeFonts.title4), {
    color: themeColors.DarkGrey,
    "&:hover": __spreadProps(__spreadValues({}, themeFonts.title4), {
      color: themeColors.DarkGrey
    })
  }),
  "&:hover": __spreadProps(__spreadValues({}, themeFonts.caption), {
    color: themeColors.Grey
  })
}));
const RootStyle = styled("div")({
  boxShadow: "none",
  position: "relative",
  top: 20,
  width: "100%",
  backgroundColor: themeColors.white,
  zIndex: 1
});
export const SecondaryHeaderMenu = ({ tabs }) => {
  const routerHistory = useHistory();
  const getActiveTab = useCallback((currentPath, tabs2) => {
    var _a, _b;
    const exactMatchedTab = tabs2.find((tab) => matchPath(currentPath, { path: tab.value, exact: true }));
    const matchedTab = tabs2.find((tab) => matchPath(currentPath, { path: tab.value, exact: false }));
    return exactMatchedTab ? exactMatchedTab.value : (_b = matchedTab == null ? void 0 : matchedTab.value) != null ? _b : (_a = tabs2[0]) == null ? void 0 : _a.value;
  }, []);
  const { hasScopes } = useScopes();
  const location = useLocation();
  const [globalState] = useContext(GlobalContext);
  const [currentTab, setCurrentTab] = useState(getActiveTab(location.pathname, tabs));
  useEffect(() => {
    setCurrentTab(getActiveTab(location.pathname, tabs));
  }, [getActiveTab, location, tabs]);
  return /* @__PURE__ */ jsx(RootStyle, { sx: { ml: "20px" }, children: /* @__PURE__ */ jsx(
    StyledTabs,
    {
      value: currentTab,
      scrollButtons: "auto",
      variant: "scrollable",
      allowScrollButtonsMobile: true,
      onChange: (_, value) => {
        setCurrentTab(value);
        routerHistory.push(value);
      },
      children: tabs.filter(
        (tab) => !tab.isHidden && (!tab.scopes || !tab.scopes || tab.context ? checkScopes(globalState.user, tab.scopes, tab.context) : hasScopes(tab.scopes, tab.context))
      ).map(
        ({ label, value, scopes, alerts = 0, context, onClick }) => globalState.inspectorMode ? /* @__PURE__ */ jsx(ScopesInspector, { scopes: scopes != null ? scopes : [], children: /* @__PURE__ */ jsx(StyledTab, { disableRipple: true, label, value, onClick }) }, value) : /* @__PURE__ */ jsx(
          StyledTab,
          {
            disableRipple: true,
            onClick,
            label: /* @__PURE__ */ jsxs(
              "div",
              {
                style: {
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  textTransform: "inherit"
                },
                children: [
                  label,
                  /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user", "user:manager", "user:all"], context, children: alerts > 0 && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, minWidth: "15px" }), children: alerts }) })
                ]
              }
            ),
            value
          },
          value
        )
      )
    }
  ) });
};
