"use strict";
import {
  REPORT_COMPANY_REPORTS_OVERVIEW,
  REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE,
  REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE,
  REPORTS_COMPANY_INSIGHTS_ROUTE,
  REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE,
  REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE
} from "@/lib/routes";
export const REPORT_REPORTS_OVERVIEW_ROUTES = {
  company: REPORT_COMPANY_REPORTS_OVERVIEW
};
export const REPORT_REPORTS_V2_NEW_OVERVIEW_ROUTES = {
  company: REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE,
  team: REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE
};
export const REPORT_REPORTS_INSIGHTS_ROUTES = {
  company: REPORTS_COMPANY_INSIGHTS_ROUTE
};
export const REPORT_REPORTS_DASHBOARD_PEOPLE_ROUTES = {
  company: REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE
};
export const REPORTS_COMPANY_SALARY_ROUTE = {
  company: REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE
};
