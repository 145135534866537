"use strict";
import { jsx } from "react/jsx-runtime";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { DOCUMENTS_NEW_COMPANY_ROUTE } from "@/lib/routes";
import { DnCompanyListing } from "@/v2/feature/document-new/features/document-new-company/dn-company-listing.page";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { ReachType } from "@/v2/interfaces/general.interface";
export const DocumentNewCompanyRouter = ({ customRuleDataset }) => {
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_COMPANY_DOCUMENTS, path: DOCUMENTS_NEW_COMPANY_ROUTE, exact: true, children: /* @__PURE__ */ jsx(DnCompanyListing, { reach: ReachType.Company, customRuleDataset }) }) });
};
