"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { keyBy } from "lodash";
import { GlobalContext } from "@/GlobalState";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { GrowthPreviewer } from "@/v2/feature/growth/shared/components/growth-previewer.component";
import { replaceParamsInQuestionText, stripHtml } from "@/v2/util/string.util";
export const CycleCreationQuestionPreviewer = ({
  isPreviewerOpen,
  setIsPreviewerOpen,
  allQuestions,
  allSections,
  order
}) => {
  var _a;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const questionsLookup = useMemo(() => {
    var _a2, _b;
    if (!allQuestions) return keyBy([], "id");
    const company_name = (_b = (_a2 = user == null ? void 0 : user.company) == null ? void 0 : _a2.name) != null ? _b : void 0;
    const questions = allQuestions.map((q) => {
      var _a3;
      return __spreadProps(__spreadValues({}, q), {
        questionText: (_a3 = replaceParamsInQuestionText(stripHtml(q.questionText), { company_name })) != null ? _a3 : "",
        options: q.choiceOptions
      });
    });
    return keyBy(questions != null ? questions : [], "id");
  }, [allQuestions, (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: isPreviewerOpen,
      setIsOpen: setIsPreviewerOpen,
      onClose: () => {
        setIsPreviewerOpen(false);
      },
      widthPercentage: 50,
      children: /* @__PURE__ */ jsx(GrowthPreviewer, { allSections, order, questionsLookup })
    }
  );
};
