"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { TaskAPI, TaskEndpoints } from "@/v2/feature/task/task.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const SelectChecklistSchema = Yup.object().shape({
  checklistId: Yup.number().nullable().required(),
  checklistDate: Yup.string().test(dateFieldTest).required("deactivation date is required")
});
export const SelectChecklistDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { data: checklists } = useApiClient(TaskEndpoints.getChecklists(), {
    suspense: false
  });
  const formik = useFormik({
    initialValues: {
      checklistId: "",
      checklistDate: new LocalDate().toDateString()
    },
    enableReinitialize: false,
    validationSchema: SelectChecklistSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (values.checklistId) {
          yield TaskAPI.launchChecklistById(Number(values.checklistId), {
            dueDate: values.checklistDate,
            assignedUserIds: [userId]
          });
        }
        showMessage(polyglot.t("SelectChecklistDrawer.successMessages.add"), "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("SelectChecklistDrawer.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  const checklistOptions = useMemo(() => {
    if (!checklists) return [];
    return checklists.map((c) => {
      var _a;
      return {
        label: (_a = c.name) != null ? _a : "",
        value: c.id
      };
    });
  }, [checklists]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("SelectChecklistDrawer.select") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        label: polyglot.t("SelectChecklistDrawer.select"),
        name: "checklistId",
        options: checklistOptions,
        value: formik.values.checklistId,
        onChange: formik.handleChange
      }
    ),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: formik.values.checklistDate,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("checklistDate", value);
          }
        },
        name: "checklistDate",
        label: polyglot.t("SelectChecklistDrawer.checklistDate"),
        error: Boolean(!!formik.errors.checklistDate && formik.touched.checklistDate),
        helperText: formik.touched.checklistDate && formik.errors.checklistDate
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("SelectChecklistDrawer.add"),
        loading,
        fullWidth: true,
        colorVariant: "primary",
        sizeVariant: "medium"
      }
    ) })
  ] }) }) });
};
