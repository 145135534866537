"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { CreateFlowLayout } from "@v2/feature/reports/features/create-report/sections/create-flow-layout.component";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CreateReportPage = () => {
  const { companyConfig, isLoading } = useCompanyConfigState();
  const { trackPage } = useJune();
  useEffect(() => {
    trackPage("Create report");
  }, []);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(
    ContentWrapper,
    {
      loading: isLoading,
      noHeader: true,
      border: false,
      sx: __spreadValues({ paddingTop: spacing.p30 }, spacing.px40),
      children: companyConfig && /* @__PURE__ */ jsx(CreateFlowLayout, { companyConfig })
    }
  ) });
};
