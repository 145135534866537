"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { AppStatusTabFilter } from "@v2/feature/app-integration/app-router.util";
import { AppGroupManagementDrawer } from "@v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory, useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import cover from "@/images/app-empty.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { getIconForAppStatus } from "@/v2/feature/app-integration/app-integration-detailed-personal.router";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import {
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  AppIntegrationUserStatusEnum,
  AppsWithLogins
} from "@/v2/feature/app-integration/app-integration.interface";
import {
  getScheduledActionDateString,
  hasScheduledAction,
  userHasDelayedAppAction
} from "@/v2/feature/app-integration/app-integration.util";
import { getGroupListForUser } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { AppAccessDetailDrawer } from "@/v2/feature/app-integration/features/app-details/components/app-access-detail-drawer.component";
import { AppDetailConfigurationModal } from "@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component";
import { AppDetailsActionButton } from "@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component";
import { AppDetailsTable } from "@/v2/feature/app-integration/features/app-details/components/app-details-table.component";
import { AppNameHeader } from "@/v2/feature/app-integration/features/app-details/components/app-name-header.component";
import { AppActionsDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section";
import { AppRequestAPI, AppRequestEndpoints } from "@/v2/feature/app-integration/features/app-request/app-request.api";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { primarySmallBtn, secondarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useLocalStorage } from "@/v2/util/use-local-storage.util";
export const AppTeamAccessPage = ({
  teamAccessUserList,
  externalUserList,
  groupMemberships,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  setApp,
  hasAppsAllOrAppOwner
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState(false);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("all");
  const [filteredTeamAccessList, setFilteredTeamAccessList] = useState(
    teamAccessUserList
  );
  const [preselectedUser, setPreselectedUser] = useState(void 0);
  const [pendingTeamAppList, setPendingTeamAppList] = useLocalStorage("pendingTeamAppList", []);
  const [actionsDrawerMode, setActionsDrawerMode] = useState(void 0);
  const [openAccessDetailModal, setOpenAccessDetailModal] = useState(false);
  const [userForAccessDetail, setUserForAccessDetail] = useState(void 0);
  const [scheduledActionDate, setScheduledActionDate] = useState(void 0);
  const [paginationReset, setPaginationReset] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState(void 0);
  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false
    }
  );
  const { data: pendingRequests, mutate: refreshAppRequests } = useApiClient(
    AppRequestEndpoints.getAppRequestsForCompany("Pending"),
    {
      suspense: false
    }
  );
  const checkForAnyMatchingPendingRequests = useCallback(() => __async(void 0, null, function* () {
    for (const eachUser of teamAccessUserList) {
      const matchingPendingRequest = pendingRequests == null ? void 0 : pendingRequests.find(
        (eachRequest) => eachRequest.requestedFor === eachUser.userId && eachRequest.requestInfo.appStub === appStub && eachUser.userStatus === "Active"
      );
      if (matchingPendingRequest && matchingPendingRequest.id) {
        yield AppRequestAPI.completePendingAppRequest(matchingPendingRequest.id);
        refreshAppRequests();
        showMessage("Found user matching pending app acccess request; marked request complete!", "success");
      }
    }
  }), [teamAccessUserList, pendingRequests, appStub, refreshAppRequests, showMessage]);
  useEffect(() => {
    checkForAnyMatchingPendingRequests();
  }, [teamAccessUserList, pendingRequests, checkForAnyMatchingPendingRequests]);
  useEffect(() => {
    if (routerLocation.state && routerLocation.state.app && routerLocation.state.needToRefreshDirectory) {
      const stubToRefresh = app == null ? void 0 : app.stub;
      routerHistory.replace({});
      if (routerLocation.state.app && setApp) setApp(routerLocation.state.app);
      if (stubToRefresh) onAppChange(stubToRefresh);
    }
  }, [app, app == null ? void 0 : app.stub, onAppChange, routerHistory, routerLocation.state, setApp]);
  useEffect(() => {
    setFilteredTeamAccessList(
      filterString === "all" ? teamAccessUserList : teamAccessUserList.filter(
        (eachUser) => eachUser.userStatus === filterString || eachUser.emails && eachUser.emails.some((eachEmail) => eachEmail.status === filterString)
      )
    );
  }, [teamAccessUserList, filterString]);
  useEffect(() => {
    setFilteredTeamAccessList(
      searchInput.length === 0 ? teamAccessUserList : teamAccessUserList.filter(
        (eachUser) => {
          var _a;
          return eachUser.displayName && ((_a = eachUser.displayName) == null ? void 0 : _a.toLowerCase().includes(searchInput.toLowerCase()));
        }
      )
    );
  }, [teamAccessUserList, searchInput]);
  const markTeamUserPending = useCallback(
    (userId) => {
      setPendingTeamAppList((prevList) => [...prevList, userId]);
    },
    [setPendingTeamAppList]
  );
  const userInPendingList = useCallback(
    (userId) => {
      return pendingTeamAppList.length > 0 && pendingTeamAppList.includes(userId);
    },
    [pendingTeamAppList]
  );
  const statusColumn = useCallback(
    (polyglot2, original) => {
      var _a, _b, _c;
      if (AppsWithLogins.includes(appStub) && original && original.emails && ((_a = original == null ? void 0 : original.emails) == null ? void 0 : _a.length) > 0) {
        return (_b = original == null ? void 0 : original.emails) == null ? void 0 : _b.map((emailObj) => /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot2, userInPendingList(+original.userId) ? "Pending" : emailObj.status)
          },
          `${original.userId}-status-${emailObj.email}`
        ) }, `${original.userId}-status-container`));
      } else if (delayedActions && (original == null ? void 0 : original.userId) && userHasDelayedAppAction(delayedActions, Number(original.userId), appStub)) {
        return /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot2, "Scheduled")
          },
          `${original.userId}-status`
        ) }) }, `${original.userId}-status-container`);
      } else if (delayedActions && (original == null ? void 0 : original.userId) && !userHasDelayedAppAction(delayedActions, Number(original.userId), appStub) && (original == null ? void 0 : original.emails) && original.emails.length > 0) {
        return (_c = original == null ? void 0 : original.emails) == null ? void 0 : _c.map((emailObj) => /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot2, userInPendingList(+original.userId) ? "Pending" : emailObj.status)
          },
          `${original.userId}-status`
        ) }, `${original.userId}-status-container`));
      } else {
        return /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot2, userInPendingList(+original.userId) ? "Pending" : original.userStatus)
          },
          `${original.userId}-status`
        ) }, `${original.userId}-status-container`);
      }
    },
    [appStub, delayedActions, userInPendingList]
  );
  const teamAccessColumns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("AppTeamAccessPage.columns.name"),
        id: "displayName",
        maxSize: 200,
        minSize: 140,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          const user = original && original.userId ? getCachedUserById(+original.userId) : void 0;
          return user ? /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.DarkGrey,
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: [
                /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "xxsmall" }),
                /* @__PURE__ */ jsx("div", { children: polyglot.t((_a = user.displayName) != null ? _a : "") || "N/A" })
              ]
            },
            `${original.userId}-name`
          ) : /* @__PURE__ */ jsx(Fragment, {});
        }
      },
      {
        header: () => polyglot.t("AppAccessDetailsDrawer.login"),
        id: "primaryEmail",
        maxSize: 200,
        minSize: 140,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(Box, { children: (original == null ? void 0 : original.emails) ? (_a = original == null ? void 0 : original.emails) == null ? void 0 : _a.map((emailObj) => /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.DarkGrey,
                display: "flex",
                alignItems: "center",
                gap: spacing.m15,
                mb: "15px",
                mt: "15px"
              }),
              children: emailObj.email
            },
            `${original.userId}-email`
          )) : /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.DarkGrey,
                display: "flex",
                alignItems: "center",
                gap: spacing.m5,
                mb: "15px"
              }),
              children: original.primaryEmail
            }
          ) });
        }
      },
      ...(app == null ? void 0 : app.allowsGroupManagement) ? [
        {
          header: () => "Groups",
          id: "groupMemberships",
          size: 160,
          accessorFn: (row) => row,
          enableSorting: false,
          hide: appStub === "github",
          cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.DarkGrey,
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: groupMemberships && original.primaryEmail ? getGroupListForUser(original.primaryEmail, groupMemberships) : ""
            }
          )
        }
      ] : [],
      {
        header: () => polyglot.t("AppTeamAccessPage.columns.status"),
        id: "userStatus",
        maxSize: 160,
        minSize: 110,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => statusColumn(polyglot, original)
      },
      {
        header: () => "",
        id: "action",
        maxSize: 80,
        minSize: 80,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.userId && userInPendingList(+original.userId) ? /* @__PURE__ */ jsx(Fragment, {}) : /* @__PURE__ */ jsx(
          AppDetailsActionButton,
          {
            setIsAppActionsDrawerOpen,
            setGroupManagementDrawerOpen: setIsDrawerOpen,
            setGroupManagementDrawerMode: setDrawerMode,
            markTeamUserPending,
            refresh: onAppChange,
            appStub,
            user: original,
            delayedActions: delayedActions != null ? delayedActions : { delayed: [] },
            refreshDelayedActions,
            table: "team",
            setPreselectedUser,
            setActionsDrawerMode,
            hasAppsAllOrAppOwner,
            error
          }
        )
      }
    ];
  }, [
    polyglot,
    app == null ? void 0 : app.allowsGroupManagement,
    appStub,
    getCachedUserById,
    groupMemberships,
    statusColumn,
    userInPendingList,
    markTeamUserPending,
    onAppChange,
    delayedActions,
    refreshDelayedActions,
    hasAppsAllOrAppOwner,
    error
  ]);
  const activeTeamMembers = (teamList) => {
    return teamList == null ? void 0 : teamList.filter(
      (eachUser) => {
        var _a;
        return ((_a = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a.some(
          (eachEmail) => [AppIntegrationUserStatusEnum.Active, AppIntegrationUserStatusEnum.Invited].includes(
            eachEmail.status
          )
        )) || eachUser.userStatus === AppIntegrationUserStatusEnum.Active;
      }
    );
  };
  const teamMembersWithNoAccess = (teamList) => {
    return teamList.filter(
      (eachUser) => {
        var _a;
        return ((_a = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a.some(
          (eachEmail) => [AppIntegrationUserStatusEnum.NoAccess].includes(eachEmail.status)
        )) || eachUser.userStatus === AppIntegrationUserStatusEnum.NoAccess;
      }
    );
  };
  const addUsersToGroup = () => {
    setIsDrawerOpen(true);
    setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.add);
  };
  const handleRowClicked = useCallback(
    (row) => {
      var _a, _b, _c;
      setOpenAccessDetailModal(true);
      setUserForAccessDetail(row.original);
      const matchingAction = ((_a = row.original) == null ? void 0 : _a.userId) && !Number.isNaN(Number((_b = row.original) == null ? void 0 : _b.userId)) ? hasScheduledAction(delayedActions, Number((_c = row.original) == null ? void 0 : _c.userId)) : void 0;
      setScheduledActionDate(matchingAction ? getScheduledActionDateString(matchingAction) : void 0);
    },
    [delayedActions]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(AppNameHeader, { title: polyglot.t("AppTeamAccessPage.title"), app }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => addUsersToGroup(), children: "New account" }),
        showAction: hasAppsAllOrAppOwner && (app == null ? void 0 : app.allowsGroupManagement)
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading != null ? loading : false, children: [
      /* @__PURE__ */ jsxs(Box, { children: [
        appStub && appStub === "github" && error && (app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              mt: spacing.mt40
            },
            children: [
              "Please add your organisation name to finish integration setup",
              /* @__PURE__ */ jsx(Button, { sx: __spreadValues(__spreadValues({}, primarySmallBtn), spacing.mt40), onClick: () => setShowModal(true), children: "Add organisation" })
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "left"
            },
            children: !error && /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsx(
                TabFilterButtons,
                {
                  filters: AppStatusTabFilter(polyglot),
                  setFilterValue: setFilterString,
                  filterValue: filterString,
                  hasSearch: true,
                  onFilterChange: ({ filterValue, searchInput: searchInput2 }) => {
                    setFilterString(filterValue);
                    if (searchInput2) {
                      setSearchInput(searchInput2);
                      setPaginationReset(true);
                    }
                  },
                  handleBlur: () => setPaginationReset(false)
                }
              ),
              hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(
                Button,
                {
                  sx: secondarySmallBtn,
                  startIcon: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)),
                  onClick: () => onAppChange(appStub),
                  children: polyglot.t("AppTeamAccessPage.actions.refresh")
                }
              )
            ] })
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        !error && /* @__PURE__ */ jsx(
          AppDetailsTable,
          {
            column: teamAccessColumns,
            row: filteredTeamAccessList != null ? filteredTeamAccessList : [],
            loading: loading != null ? loading : false,
            paginationReset,
            handleRowClicked
          }
        ),
        error && !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
          EmptyStateComponent,
          {
            header: "Authorize this app",
            subheader: "Zelt allows you to manage your whole toolstack from one place.",
            detail: "To start using your app connect your account in Settings first.",
            cover
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        AppDetailConfigurationModal,
        {
          open: showModal,
          handleClose: () => setShowModal(false),
          app,
          appStub,
          refresh: () => onAppChange(appStub)
        },
        appStub
      ),
      /* @__PURE__ */ jsx(
        AppActionsDrawer,
        {
          appStub,
          usersWithoutAccess: teamAccessUserList,
          usersWithAccess: activeTeamMembers(teamAccessUserList),
          isOpen: isAppActionsDrawerOpen,
          onClose: () => setIsAppActionsDrawerOpen(false),
          refreshApp: () => onAppChange(appStub),
          refreshDelayedActions,
          markTeamUserPending,
          setIsAppActionsDrawerOpen,
          preselectedUser,
          mode: actionsDrawerMode,
          directoryMode: "team"
        }
      ),
      /* @__PURE__ */ jsx(
        AppGroupManagementDrawer,
        {
          appStub,
          selectedGroupMembership: void 0,
          externalUserList,
          usersWithoutAccess: teamMembersWithNoAccess(teamAccessUserList),
          usersWithAccess: activeTeamMembers(teamAccessUserList),
          isOpen: isDrawerOpen,
          onClose: () => setIsDrawerOpen(false),
          refreshApp: () => onAppChange(appStub),
          setIsDrawerOpen,
          mode: drawerMode,
          existingUser: preselectedUser,
          groupMembers: {},
          groupList: groupMemberships
        }
      ),
      userForAccessDetail && (app == null ? void 0 : app.name) && /* @__PURE__ */ jsx(
        AppAccessDetailDrawer,
        {
          isOpen: openAccessDetailModal,
          setIsOpen: setOpenAccessDetailModal,
          appUserDetail: userForAccessDetail,
          appName: app == null ? void 0 : app.name,
          appStatusValue: statusColumn(polyglot, userForAccessDetail),
          scheduledActionDate,
          appActionButtons: /* @__PURE__ */ jsx(
            AppDetailsActionButton,
            {
              setIsAppActionsDrawerOpen,
              setGroupManagementDrawerOpen: setIsDrawerOpen,
              setGroupManagementDrawerMode: setDrawerMode,
              markTeamUserPending,
              refresh: onAppChange,
              appStub,
              user: userForAccessDetail,
              delayedActions: delayedActions != null ? delayedActions : { delayed: [] },
              refreshDelayedActions,
              table: "team",
              setPreselectedUser,
              setActionsDrawerMode,
              hasAppsAllOrAppOwner,
              error,
              appAccessDetailsDrawerMode: true
            }
          )
        }
      )
    ] })
  ] });
};
