"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from "react";
import { Box } from "@mui/material";
import { getISOWeek } from "date-fns";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import "@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.scss";
import { getMondayOfCurrentWeek } from "@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserAttendanceLoader = () => {
  const weekStart = getMondayOfCurrentWeek();
  const currentWeekNo = useMemo(() => getISOWeek(weekStart), [weekStart]);
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "big", isLoadingState: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Attendance" }) }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, width: "100%" }, children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            maxWidth: "50px",
            width: "100%",
            pt: spacing.p10,
            pb: spacing.p10
          },
          children: /* @__PURE__ */ jsxs(
            "div",
            {
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                gap: spacing.g15,
                paddingRight: spacing.p5,
                paddingLeft: spacing.p5
              },
              children: [
                /* @__PURE__ */ jsxs("div", { style: { width: "40px", height: "40px" }, children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Week" }),
                  /* @__PURE__ */ jsx(Typography, { variant: "caption", children: currentWeekNo })
                ] }),
                /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Start" }),
                /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "End" }),
                /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Break" })
              ]
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pt: spacing.p10,
            pb: spacing.p10
          },
          children: /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                textAlign: "left",
                gap: spacing.g30,
                paddingRight: spacing.p5,
                paddingLeft: spacing.p5
              },
              children: [0, 1, 2, 3].map((num) => /* @__PURE__ */ jsx("div", { className: "u-att-loader-container", children: /* @__PURE__ */ jsx("div", { className: "u-att-progress" }) }, `${num}-ual`))
            }
          )
        }
      )
    ] })
  ] }) });
};
