"use strict";
export const UserDetailsScopes = {
  VIEW_USER_PERSONAL: [
    "user.basicInfo",
    "user.personalInfo",
    "user.family",
    "user.about",
    "user.basicInfo:read:all",
    "user.personalInfo:read:all",
    "user.family:read:all",
    "user.about:read:all"
  ],
  VIEW_USER_WORK: [
    "user.role:read",
    "user.contract:read",
    "user.contract:read:manager",
    "user.lifecycle:read",
    "user.rightWork:read",
    "user.role:read:all",
    "user.contract:read:all",
    "user.lifecycle:read:all",
    "user.rightWork:read:all"
  ],
  VIEW_USER_COMPENSATION: [
    "user.compensation:read",
    "user.bankAccount",
    "user.equity:read",
    "user.compensation:read:all",
    "user.bankAccount:read:all",
    "user.equity:read:all"
  ],
  VIEW_USER_CONTACT: [
    "user.address",
    "user.address:read:all",
    "user.emergencyContact",
    "user.emergencyContact:read:all",
    "user.workContact",
    "user.workContact:read:all"
  ],
  CREATE_CONTRACT: ["user.contract", "user.contract:manager", "user.contract:all"],
  READ_CONTRACT: ["user.contract", "user.contract:read", "user.contract:read:manager", "user.contract:read:all"],
  UPDATE_CONTRACT: ["user.contract", "user.contract:manager", "user.contract:all"],
  CREATE_COMPENSATION: ["user.compensation:manager"],
  READ_COMPENSATION: ["user.compensation:read"],
  UPDATE_COMPENSATION: ["user.compensation:manager"],
  CREATE_EQUITY: ["user.equity", "user.equity:all"],
  READ_EQUITY: ["user.equity", "user.equity:read", "user.equity:read:all"],
  UPDATE_EQUITY: ["user.equity", "user.equity:all"]
};
