"use strict";
export const WeekDayNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
export const DayNoToWeekDay = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday"
};
export const weekDayToDayNo = (weekDay) => {
  return Object.values(DayNoToWeekDay).findIndex((wd) => wd === weekDay);
};
export var ScheduleTrackingType = /* @__PURE__ */ ((ScheduleTrackingType2) => {
  ScheduleTrackingType2["None"] = "None";
  ScheduleTrackingType2["ClockInClockOut"] = "ClockInClockOut";
  ScheduleTrackingType2["Regular"] = "Regular";
  return ScheduleTrackingType2;
})(ScheduleTrackingType || {});
export const ScheduleTrackingTypeOptions = (isFlexible, polyglot) => [
  ...isFlexible ? [] : [{ label: polyglot.t("getTrackingTypeLabel.clockInOut"), value: "ClockInClockOut" /* ClockInClockOut */ }],
  { label: polyglot.t("getTrackingTypeLabel.regularTracking"), value: "Regular" /* Regular */ },
  { label: polyglot.t("getTrackingTypeLabel.noTracking"), value: "None" /* None */ }
];
export var ScheduleTrackingTimesheetType = /* @__PURE__ */ ((ScheduleTrackingTimesheetType2) => {
  ScheduleTrackingTimesheetType2["Daily"] = "Daily";
  ScheduleTrackingTimesheetType2["Weekly"] = "Weekly";
  ScheduleTrackingTimesheetType2["Monthly"] = "Monthly";
  return ScheduleTrackingTimesheetType2;
})(ScheduleTrackingTimesheetType || {});
export var AttendanceStatus = /* @__PURE__ */ ((AttendanceStatus2) => {
  AttendanceStatus2["InProgress"] = "InProgress";
  AttendanceStatus2["Submitted"] = "Submitted";
  AttendanceStatus2["Approved"] = "Approved";
  AttendanceStatus2["Rejected"] = "Rejected";
  return AttendanceStatus2;
})(AttendanceStatus || {});
