"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { formatMoney, formatPercentage } from "@v2/feature/payments/utils/money.util";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { BillingSubscriptionAPI } from "@/api-client/billing-subscription.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Pro } from "@/images/Pro.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  getProductPlanIdToName,
  PaidProductPlans,
  Plan,
  Product
} from "@/models/subscription.model";
const getProductOption = (productName, proPricePerUser, descriptionFree, descriptionPro, discountRate, disableDowngrade = false) => [
  {
    label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", height: "35px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: productName }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey, ml: spacing.m5 }), children: "Free" })
    ] }),
    value: Plan.FREE,
    description: descriptionFree,
    disabled: disableDowngrade
  },
  {
    label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", height: "35px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: productName }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(Pro, { width: 40, height: 40 }) }),
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: formatMoney({ amount: proPricePerUser * (1 - discountRate) }) }),
      discountRate > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Red }), children: [
          formatPercentage(Math.round(discountRate * 100), 0),
          " discount"
        ] }),
        /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Red,
              textDecoration: "line-through"
            }),
            children: formatMoney({ amount: proPricePerUser })
          }
        )
      ] })
    ] }),
    value: Plan.PRO,
    description: descriptionPro
  }
];
const getProductOptions = (productsCostsPerUser, downgradeViability, productDiscount) => {
  var _a, _b, _c, _d;
  const {
    peoplePaidPlanCostPerUser,
    moneyPaidPlanCostPerUser,
    appsPaidPlanCostPerUser,
    devicesPaidPlanCostPerUser
  } = productsCostsPerUser;
  const peopleOptions = getProductOption(
    "People",
    peoplePaidPlanCostPerUser,
    "Invite up to 9 employees.",
    "Unlimited departments and sites.",
    productDiscount[Product.PEOPLE],
    (_a = downgradeViability["PEOPLE"]) == null ? void 0 : _a.disableDowngrade
  );
  const moneyOptions = getProductOption(
    "Money",
    moneyPaidPlanCostPerUser,
    "Add up to 9 employees to payroll.",
    "Unlimited employees on payroll.",
    productDiscount[Product.MONEY],
    (_b = downgradeViability["MONEY"]) == null ? void 0 : _b.disableDowngrade
  );
  const appsOptions = getProductOption(
    "Apps",
    appsPaidPlanCostPerUser,
    "Connect up to 5 apps.",
    "Unlimited apps.",
    productDiscount[Product.APPS],
    (_c = downgradeViability["APPS"]) == null ? void 0 : _c.disableDowngrade
  );
  const devicesOptions = getProductOption(
    "Devices",
    devicesPaidPlanCostPerUser,
    "Basic logistics, replacement service and device configurations.",
    "Advanced logistics, replacement service and device configurations.",
    productDiscount[Product.DEVICES],
    (_d = downgradeViability["DEVICES"]) == null ? void 0 : _d.disableDowngrade
  );
  return { peopleOptions, moneyOptions, appsOptions, devicesOptions };
};
export const UpdateSubscriptionDrawer = ({
  isOpen,
  setIsOpen,
  subscriptions,
  subscriptionsStats,
  subscriptionsBillingStats,
  downgradeViability,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    UpdateSubscriptionDrawerContent,
    {
      subscriptions,
      subscriptionsStats,
      downgradeViability,
      subscriptionsBillingStats,
      refresh
    }
  ) });
};
const detectUpgradeAndDowngrades = (currentPeoplePlan, currentMoneyPlan, currentAppsPlan, currentDevicesPlan, formValues, polyglot) => {
  const downgrades = [];
  const upgrades = [];
  if ((currentPeoplePlan == null ? void 0 : currentPeoplePlan.productPlan.planId) !== formValues.people) {
    currentPeoplePlan && currentPeoplePlan.productPlan.planId > formValues.people ? downgrades.push({
      id: currentPeoplePlan.subscriptionId,
      productPlanId: currentPeoplePlan.productPlan.id,
      plan: getProductPlanIdToName(polyglot)[currentPeoplePlan.productPlan.id]
    }) : upgrades.push({
      productPlanId: PaidProductPlans.PEOPLE_PRO,
      plan: getProductPlanIdToName(polyglot)[PaidProductPlans.PEOPLE_PRO]
    });
  }
  if ((currentMoneyPlan == null ? void 0 : currentMoneyPlan.productPlan.planId) !== formValues.money) {
    currentMoneyPlan && currentMoneyPlan.productPlan.planId > formValues.money ? downgrades.push({
      id: currentMoneyPlan.subscriptionId,
      productPlanId: currentMoneyPlan.productPlan.id,
      plan: getProductPlanIdToName(polyglot)[currentMoneyPlan.productPlan.id]
    }) : upgrades.push({
      productPlanId: PaidProductPlans.MONEY_PRO,
      plan: getProductPlanIdToName(polyglot)[PaidProductPlans.MONEY_PRO]
    });
  }
  if ((currentAppsPlan == null ? void 0 : currentAppsPlan.productPlan.planId) !== formValues.apps) {
    currentAppsPlan && currentAppsPlan.productPlan.planId > formValues.apps ? downgrades.push({
      id: currentAppsPlan.subscriptionId,
      productPlanId: currentAppsPlan.productPlan.id,
      plan: getProductPlanIdToName(polyglot)[currentAppsPlan.productPlan.id]
    }) : upgrades.push({
      productPlanId: PaidProductPlans.APPS_PRO,
      plan: getProductPlanIdToName(polyglot)[PaidProductPlans.APPS_PRO]
    });
  }
  if ((currentDevicesPlan == null ? void 0 : currentDevicesPlan.productPlan.planId) !== formValues.devices) {
    currentDevicesPlan && currentDevicesPlan.productPlan.planId > formValues.devices ? downgrades.push({
      id: currentDevicesPlan.subscriptionId,
      productPlanId: currentDevicesPlan.productPlan.id,
      plan: getProductPlanIdToName(polyglot)[currentDevicesPlan.productPlan.id]
    }) : upgrades.push({
      productPlanId: PaidProductPlans.DEVICES_PRO,
      plan: getProductPlanIdToName(polyglot)[PaidProductPlans.DEVICES_PRO]
    });
  }
  return { downgrades, upgrades };
};
const extractDiscountRate = (totalCost, totalDiscount, noOfActiveUsers) => {
  if (totalCost === 0 || totalDiscount === 0 || noOfActiveUsers === 0) return 0;
  return totalDiscount / totalCost;
};
const extractDiscountFromPlan = (peopleSubscriptionBills) => {
  const { totalCost, totalDiscount, noOfActiveUsers } = peopleSubscriptionBills.reduce(
    (acc, bill) => {
      var _a;
      acc.totalCost += bill.subscriptionCost;
      acc.totalDiscount += (_a = bill.totalDiscount) != null ? _a : 0;
      acc.noOfActiveUsers = Math.max(acc.noOfActiveUsers, bill.noOfActiveUsers);
      return acc;
    },
    {
      totalCost: 0,
      totalDiscount: 0,
      noOfActiveUsers: 0
    }
  );
  return extractDiscountRate(totalCost, totalDiscount, noOfActiveUsers);
};
const getDiscountPercentagesPerProduct = (billingStats) => {
  if (!billingStats) return { [Product.PEOPLE]: 0, [Product.MONEY]: 0, [Product.APPS]: 0, [Product.DEVICES]: 0 };
  const peopleSubscriptionBills = billingStats[Product.PEOPLE].filter(
    (subscription) => subscription.subscriptionCost > 0
  );
  const peopleDiscount = extractDiscountFromPlan(peopleSubscriptionBills);
  const moneySubscriptionBills = billingStats[Product.MONEY].filter(
    (subscription) => subscription.subscriptionCost > 0
  );
  const moneyDiscount = extractDiscountFromPlan(moneySubscriptionBills);
  const appsSubscriptionBills = billingStats[Product.APPS].filter((subscription) => subscription.subscriptionCost > 0);
  const appsDiscount = extractDiscountFromPlan(appsSubscriptionBills);
  const devicesSubscriptionBills = billingStats[Product.DEVICES].filter(
    (subscription) => subscription.subscriptionCost > 0
  );
  const devicesDiscount = extractDiscountFromPlan(devicesSubscriptionBills);
  return {
    [Product.PEOPLE]: peopleDiscount,
    [Product.MONEY]: moneyDiscount,
    [Product.APPS]: appsDiscount,
    [Product.DEVICES]: devicesDiscount
  };
};
const getProPlansCostPerUser = (subscriptionsStats) => {
  var _a, _b, _c, _d;
  const peoplePaidPlanCostPerUser = (_a = subscriptionsStats == null ? void 0 : subscriptionsStats.productPlanPricing[PaidProductPlans.PEOPLE_PRO].costPerUserPerMonth) != null ? _a : 0;
  const moneyPaidPlanCostPerUser = (_b = subscriptionsStats == null ? void 0 : subscriptionsStats.productPlanPricing[PaidProductPlans.MONEY_PRO].costPerUserPerMonth) != null ? _b : 0;
  const appsPaidPlanCostPerUser = (_c = subscriptionsStats == null ? void 0 : subscriptionsStats.productPlanPricing[PaidProductPlans.APPS_PRO].costPerUserPerMonth) != null ? _c : 0;
  const devicesPaidPlanCostPerUser = (_d = subscriptionsStats == null ? void 0 : subscriptionsStats.productPlanPricing[PaidProductPlans.DEVICES_PRO].costPerUserPerMonth) != null ? _d : 0;
  return { peoplePaidPlanCostPerUser, moneyPaidPlanCostPerUser, appsPaidPlanCostPerUser, devicesPaidPlanCostPerUser };
};
const UpdateSubscriptionDrawerContent = ({
  subscriptions,
  subscriptionsStats,
  subscriptionsBillingStats,
  downgradeViability,
  refresh
}) => {
  var _a, _b, _c, _d, _e;
  const [loading, setLoading] = useState(false);
  const currentPeoplePlan = subscriptions.find((plan) => plan.productPlan.productId === Product.PEOPLE);
  const currentMoneyPlan = subscriptions.find((plan) => plan.productPlan.productId === Product.MONEY);
  const currentAppsPlan = subscriptions.find((plan) => plan.productPlan.productId === Product.APPS);
  const currentDevicesPlan = subscriptions.find((plan) => plan.productPlan.productId === Product.DEVICES);
  const currentActiveUsers = (_a = subscriptionsStats == null ? void 0 : subscriptionsStats.companyStats.noOfActiveUsers) != null ? _a : 0;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      people: (_b = currentPeoplePlan == null ? void 0 : currentPeoplePlan.productPlan.planId) != null ? _b : Plan.FREE,
      money: (_c = currentMoneyPlan == null ? void 0 : currentMoneyPlan.productPlan.planId) != null ? _c : Plan.FREE,
      apps: (_d = currentAppsPlan == null ? void 0 : currentAppsPlan.productPlan.planId) != null ? _d : Plan.FREE,
      devices: (_e = currentDevicesPlan == null ? void 0 : currentDevicesPlan.productPlan.planId) != null ? _e : Plan.FREE
    },
    validationSchema: yup.object({
      people: yup.number().required("People module is required"),
      money: yup.number().required("Money module is required"),
      apps: yup.number().required("Apps module is required"),
      devices: yup.number().required("Devices module is required")
    }),
    onSubmit: (formData) => __async(void 0, null, function* () {
      const { downgrades, upgrades } = detectUpgradeAndDowngrades(
        currentPeoplePlan,
        currentMoneyPlan,
        currentAppsPlan,
        currentDevicesPlan,
        formData,
        polyglot
      );
      if (!downgrades.length && !upgrades.length) return;
      try {
        setLoading(true);
        if (downgrades.length > 0)
          yield Promise.all(
            downgrades.map((downgradePayload) => BillingSubscriptionAPI.downgradeSubscription(downgradePayload))
          );
        let checkoutSession;
        if (upgrades.length > 0) {
          checkoutSession = yield BillingSubscriptionAPI.upgradeSubscription(upgrades);
        }
        if (checkoutSession == null ? void 0 : checkoutSession.url) {
          return window.location.replace(checkoutSession.url);
        }
        showMessage("Plan updated successfully", "success");
        yield refresh();
      } catch (error) {
        showMessage(`Failed to update plan. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const productsCostsPerUser = getProPlansCostPerUser(subscriptionsStats);
  const productsDiscounts = getDiscountPercentagesPerProduct(subscriptionsBillingStats);
  const { peopleOptions, moneyOptions, appsOptions, devicesOptions } = getProductOptions(
    productsCostsPerUser,
    downgradeViability,
    productsDiscounts
  );
  const costPerUser = useMemo(() => {
    const { people, money, apps, devices } = formik.values;
    const {
      peoplePaidPlanCostPerUser,
      moneyPaidPlanCostPerUser,
      appsPaidPlanCostPerUser,
      devicesPaidPlanCostPerUser
    } = productsCostsPerUser;
    const peopleCost = people === Plan.PRO ? peoplePaidPlanCostPerUser : 0;
    const moneyCost = money === Plan.PRO ? moneyPaidPlanCostPerUser : 0;
    const appsCost = apps === Plan.PRO ? appsPaidPlanCostPerUser : 0;
    const devicesCost = devices === Plan.PRO ? devicesPaidPlanCostPerUser : 0;
    return peopleCost + moneyCost + appsCost + devicesCost;
  }, [formik.values, productsCostsPerUser]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Your subscription" }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "people",
        label: "Module",
        options: peopleOptions,
        value: formik.values.people,
        compareValue: formik.values.people,
        error: !!formik.errors.people && formik.touched.people,
        helperText: formik.touched.people && formik.errors.people,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "money",
        label: "Module",
        options: moneyOptions,
        value: formik.values.money,
        compareValue: formik.values.money,
        error: !!formik.errors.money && formik.touched.money,
        helperText: formik.touched.money && formik.errors.money,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "apps",
        label: "Module",
        options: appsOptions,
        value: formik.values.apps,
        compareValue: formik.values.apps,
        error: !!formik.errors.apps && formik.touched.apps,
        helperText: formik.touched.apps && formik.errors.apps,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "devices",
        label: "Module",
        options: devicesOptions,
        value: formik.values.devices,
        compareValue: formik.values.devices,
        error: !!formik.errors.devices && formik.touched.devices,
        helperText: formik.touched.devices && formik.errors.devices,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15, mt: spacing.m30 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Subscription fee per user" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: formatMoney({ amount: costPerUser }) })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Active users" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: `${currentActiveUsers} user${currentActiveUsers === 1 ? "" : "s"}` })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Total (excl. VAT)" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: formatMoney({ amount: currentActiveUsers * costPerUser }) })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "VAT" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: formatMoney({ amount: currentActiveUsers * costPerUser * 0.2 }) })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Total (incl. VAT)" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: formatMoney({ amount: currentActiveUsers * costPerUser * 1.2 }) })
      ] })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey, mt: spacing.m20, mb: spacing.m20 }), children: "You will be redirected to make this payment for the remainder of the current pay period. Subscription will activate once the payment is complete." }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        loading,
        disabled: !formik.isValid,
        sizeVariant: "small",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) });
};
