"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { UserProfileBar } from "@v2/feature/user/components/user-profile-bar.component";
import { UserAPI } from "@v2/feature/user/user.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import * as d3 from "d3";
import { OrgChart as D3OrgChart } from "d3-org-chart";
import { jsPDF } from "jspdf";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as CleanCircle } from "@/images/side-bar-icons/CleanCircle.svg";
import { nestErrorMessage } from "@/lib/errors";
import { USER_PERSONAL_TAB } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SwitchButton } from "@/v2/components/switch-button.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ProfileModal } from "@/v2/components/theme-components/profile-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getEmploymentTypeOptions } from "@/v2/feature/user/features/user-profile/user-profile.interface";
import { filterStringToObject } from "@/v2/feature/user/user.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
import { isDefined } from "@/v2/util/string.util";
export const OrgChartComponent = ({
  data,
  departments,
  generalSettings
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [globalState, dispatch] = useContext(GlobalContext);
  const { user: currentUser, publicURL } = globalState;
  const [error, setError] = useState(null);
  const routerHistory = useHistory();
  const [chart, setChart] = useState(null);
  const d3Container = useRef(null);
  const [selectedUserId, setSetSelectedUserId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const currentFilterString = isDefined((_c = (_b = (_a = currentUser.features) == null ? void 0 : _a.user) == null ? void 0 : _b.orgChart) == null ? void 0 : _c.orgView) ? (_f = (_e = (_d = currentUser.features) == null ? void 0 : _d.user) == null ? void 0 : _e.orgChart) == null ? void 0 : _f.orgView : "vertical";
  const [showMode, setShowMode] = useState(currentFilterString != null ? currentFilterString : "vertical");
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    const scripts = [
      "https://d3js.org/d3.v7.min.js",
      "https://cdn.jsdelivr.net/npm/d3-org-chart@3.1.0",
      "https://cdn.jsdelivr.net/npm/d3-flextree@2.1.2/build/d3-flextree.js"
    ];
    scripts.forEach((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    });
  }, []);
  const getInitials = (name) => {
    var _a2, _b2;
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length === 1) {
      return (name.length >= 2 ? name.substring(0, 2) : name[0]).toUpperCase();
    }
    return `${(_a2 = nameParts[0][0]) != null ? _a2 : "N"}${(_b2 = nameParts[1][0]) != null ? _b2 : "A"}`.toUpperCase();
  };
  function stringToColor(avatarName) {
    if (!avatarName) return "white";
    let hash = 0;
    let i;
    for (i = 0; i < avatarName.length; i += 1) {
      hash = avatarName.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = hash >> i * 8 & 255;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return `${color}5c`;
  }
  const hasCycleErrors = (orgchartData) => {
    return orgchartData.some((eachUser) => ["cycle_error_root", "cycle_error_reportee"].includes(eachUser.tags));
  };
  const filterByDepartment = (users, validValues) => {
    return users.filter((user) => {
      var _a2;
      return user.areaId && validValues.includes((_a2 = user.areaId) == null ? void 0 : _a2.toString());
    });
  };
  const filterByEntity = (users, validValues) => {
    return users.filter((user) => {
      var _a2;
      return user.entity && validValues.includes((_a2 = user.entity) == null ? void 0 : _a2.toString());
    });
  };
  const filterByWorkerType = (users, validValues) => {
    return users.filter((user) => {
      var _a2;
      return user.workerType && validValues.includes((_a2 = user.workerType) == null ? void 0 : _a2.toString());
    });
  };
  const filterByName = (users, query) => {
    return users.filter((user) => user.name && user.name.toLowerCase().includes(query.toLowerCase()));
  };
  const getFiltereData = (orgchartData, filterString2, searchInput2) => {
    let data2 = orgchartData;
    if (filterString2 && data2) {
      const filterOptions = filterStringToObject(filterString2);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case "Department": {
              data2 = filterByDepartment(data2, filterOptions[key]);
              break;
            }
            case "Entity": {
              data2 = filterByEntity(data2, filterOptions[key]);
              break;
            }
            case "Worker type": {
              data2 = filterByWorkerType(data2, filterOptions[key]);
              break;
            }
            default:
              break;
          }
        }
      }
    }
    if (searchInput2 && data2) {
      data2 = filterByName(data2, searchInput2);
    }
    return data2 ? new Set(data2.map((d) => Number(d.id))) : void 0;
  };
  function filterChart(searchInput2, filterString2) {
    chart.clearHighlighting();
    let data2 = chart.data();
    const filteredIds = getFiltereData(data2, filterString2, searchInput2);
    if (!filteredIds) return;
    data2.forEach((d) => d._expanded = false);
    data2.forEach((d) => {
      if ((filterString2 !== "" || searchInput2 !== "") && filteredIds.has(d.id)) {
        d._highlighted = true;
        d._expanded = true;
      }
    });
    chart.data(data2).expandLevel(2).render().fit();
  }
  function highlightMe() {
    setFilterString("");
    setSearchInput("");
    chart.clearHighlighting();
    const data2 = chart.data();
    data2.forEach((d) => d._expanded = false);
    data2.forEach((d) => {
      if (currentUser && d.id === currentUser.userId) {
        d._highlighted = true;
        d._expanded = true;
      }
    });
    chart.data(data2).expandLevel(2).render().fit();
  }
  useLayoutEffect(() => {
    try {
      const newChart = new D3OrgChart();
      if (d3Container.current) {
        newChart.nodeHeight((_) => 85 + 25).nodeWidth((_) => 220 + 2).childrenMargin((_) => 50).compactMarginBetween((_) => 35).compactMarginPair((_) => 30).neightbourMargin((_) => 20).nodeUpdate(function(d) {
          const node = d3.select(`[data-id='${d.id}']`).select(".node-rect");
          node.attr("stroke", "none");
        }).nodeContent(function(d) {
          var _a2, _b2, _c2, _d2, _e2, _f2, _g;
          const color = "#FFFFFF";
          const imageDiffVert = 15 + 2;
          const avatar = d.data.avatar && d.data.avatar !== "" ? `<img src='${d.data.avatar}' style='margin-left:${20}px;border-radius:100px;width:40px;height:40px;' />` : `<div style='margin-left:${20}px;border-radius:100px;width:40px;height:40px;background-color: ${stringToColor(
            (_a2 = d.data) == null ? void 0 : _a2.name
          )};'>
                 <span style='width:100%;height:100%;display:flex;justify-content:center;align-items:center;font-size:20px;font-weight: 500; color: #fff; text-transform: uppercase; font-family: Inter, sans-serif; letter-spacing: -0.5px; line-height: 30px;'>
                  ${d.data.name ? getInitials(d.data.name) : "NA"}
                 </span>
               </div>`;
          const userDetail = ((_b2 = d.data) == null ? void 0 : _b2.positionName) && ((_c2 = d.data) == null ? void 0 : _c2.positionName) !== "" || ((_d2 = d.data) == null ? void 0 : _d2.office) && ((_e2 = d.data) == null ? void 0 : _e2.office) !== "" ? `${((_f2 = d.data) == null ? void 0 : _f2.positionName) || "\u2013"}, ${((_g = d.data) == null ? void 0 : _g.office) || "\u2013"}` : "";
          return `
          <div style='width:${d.width}px;height:${d.height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px;'>
                  <div style="font-family: 'Inter', sans-serif;background-color:${color};  margin-left:-1px;width:${d.width - 2}px;height:${d.height - imageDiffVert}px;border-radius:10px;border:${d.data._highlighted ? `4px solid ${themeColors.ZeltYellow}` : "1px solid #E4E2E9"};">
                      <div style='background-color:${color};margin-top:${-imageDiffVert - 20}px;margin-left:${15}px;border-radius:100px;width:50px;height:50px;' ></div>
                      <div style='margin-top:${-imageDiffVert - 20}px;'>  ${avatar}</div>
                      <div style='font-size:15px;color:#08011E;margin-left:20px;margin-top:10px'> ${d.data.name} </div>
                      <div style='color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;'> ${userDetail} </div>
                  </div>
              </div>`;
        }).onNodeClick((d) => {
          const data2 = d;
          if (data2 !== 999999) {
            setIsOpen(true);
            setSetSelectedUserId(data2);
          }
        }).compact(showMode === "vertical").container(d3Container.current).data(data).render().fit();
        setChart(newChart);
      }
    } catch (error2) {
      console.error("Encountered an error while trying to render the orgchart: ", error2);
      setError(error2);
    }
  }, [polyglot, data, showMode, publicURL]);
  function downloadPdf() {
    return __async(this, null, function* () {
      const pdf = new jsPDF();
      if (chart) {
        chart.exportImg({
          full: true,
          save: false,
          onLoad: (base64) => {
            const img = new Image();
            img.src = base64;
            img.onload = function() {
              pdf.addImage(img, "JPEG", 5, 5, 595 / 3, img.height / img.width * 595 / 3);
              pdf.save("organizational-chart.pdf");
            };
          }
        });
      }
    });
  }
  function downloadCsv() {
    return __async(this, null, function* () {
      const allFiles = yield UserAPI.exportReportById();
      const csvData = new Blob([allFiles], { type: "text/csv;charset=utf-8;" });
      let csvURL;
      const fileName = `users-${new LocalDate().toDateString()}.csv`;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, fileName);
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }
      const tempLink = document.createElement("a");
      if (typeof csvURL === "string") {
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName);
        tempLink.click();
      } else {
        showMessage(polyglot.t("OrgChartComponent.errorMessages.export"), "error");
      }
    });
  }
  const FilterTypes = __spreadProps(__spreadValues(__spreadValues({}, departments && (departments == null ? void 0 : departments.length) > 0 ? {
    [polyglot.t("OrgChartComponent.departmentFilter")]: departments.map((d) => ({
      value: d.id,
      label: polyglot.t(d.name)
    }))
  } : {}), (generalSettings == null ? void 0 : generalSettings.entities) ? {
    [polyglot.t("OrgChartComponent.entityFilter")]: generalSettings == null ? void 0 : generalSettings.entities.map((entity) => {
      return { label: polyglot.t(entity.legalName), value: entity.id.toString() };
    })
  } : {}), {
    [polyglot.t("OrgChartComponent.workerTypeFilter")]: getEmploymentTypeOptions(polyglot)
  });
  const getDownloadActions = () => {
    return [
      {
        handler: () => __async(void 0, null, function* () {
          return yield downloadPdf();
        }),
        label: "PDF",
        disabled: false
      },
      {
        handler: () => chart == null ? void 0 : chart.exportImg({ full: true }),
        label: "PNG",
        disabled: false
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield downloadCsv();
        }),
        label: "CSV",
        disabled: false
      }
    ];
  };
  const viewOptions = [
    { label: "Vertical", value: "vertical" },
    { label: "Horizontal", value: "horizontal" }
  ];
  const updateUserFeaturesOrgView = useCallback(
    (mode) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("user", "orgChart", "orgView", mode);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error2) {
        showMessage(`${polyglot.t("PeopleDirectoryPage.errorMessages.update")}. ${nestErrorMessage(error2)}`, "error");
      }
    }),
    [dispatch, showMessage, polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: spacing.p16 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
              filterChart(e.target.value, filterString);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CategoryFilters,
          {
            filterTypes: FilterTypes,
            setFilterString: (filterString2) => {
              setFilterString(filterString2);
              filterChart(searchInput, filterString2);
            },
            filterString
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: getDownloadActions(),
          sx: { borderRadius: radius.br20 },
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: polyglot.t("General.export"),
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end"
          }
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingY: spacing.p16,
          borderTop: borders.light
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: highlightMe,
                style: { borderRadius: radius.br16, padding: `${spacing.p4}px 0px` },
                children: "Find me"
              }
            ),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => {
                  if (chart) {
                    chart.expandAll().fit();
                  }
                },
                style: { borderRadius: radius.br16, padding: `${spacing.p4}px 0px` },
                children: "Expand all"
              }
            ),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => {
                  if (chart) {
                    chart.collapseAll().fit();
                  }
                },
                style: { borderRadius: radius.br16, padding: `${spacing.p4}px 0px` },
                children: "Collapse all"
              }
            )
          ] }),
          /* @__PURE__ */ jsx(
            SwitchButton,
            {
              selectedValue: showMode,
              color: themeColors.DarkGrey,
              action: (value) => {
                if (chart) {
                  chart.compact(value === "vertical").render().fit();
                  setShowMode(value);
                  updateUserFeaturesOrgView(value);
                }
              },
              options: viewOptions
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Snackbar,
      {
        open: (data == null ? void 0 : data.length) > 0 && !error && hasCycleErrors(data) || data && (data == null ? void 0 : data.length) === 0 && !error || Boolean(error),
        onClose: void 0,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        children: /* @__PURE__ */ jsx(
          MuiAlert,
          {
            sx: {
              width: "100%",
              backgroundColor: themeColors.Red,
              borderRadius: radius.br10,
              boxShadow: "none",
              maxWidth: "600px"
            },
            elevation: 10,
            variant: "filled",
            icon: false,
            children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "flex-start", gap: spacing.g10 }, children: [
              /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m3 }, children: /* @__PURE__ */ jsx(CleanCircle, { style: { fill: themeColors.white }, width: 16, height: 16 }) }),
              (data == null ? void 0 : data.length) > 0 && !error && hasCycleErrors(data) && /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "caption",
                  color: "white",
                  sx: {
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.g10
                  },
                  children: polyglot.t("OrgChartComponent.resolve")
                }
              ),
              data && (data == null ? void 0 : data.length) === 0 && !error && /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "caption",
                  color: "white",
                  sx: {
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.g10
                  },
                  children: polyglot.t("OrgChartComponent.noData")
                }
              ),
              error && /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "caption",
                  color: "white",
                  sx: {
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.g10
                  },
                  children: polyglot.t("OrgChartComponent.toView")
                }
              )
            ] })
          }
        )
      }
    ),
    /* @__PURE__ */ jsx("div", { ref: d3Container }),
    /* @__PURE__ */ jsx(
      ProfileModal,
      {
        isOpen,
        setIsOpen,
        onClose: () => {
          setIsOpen(false);
          setSetSelectedUserId(null);
        },
        openProfile: () => {
          if (selectedUserId) routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: selectedUserId }));
        },
        openInNewWindow: () => {
          if (selectedUserId) window.open(generatePath(USER_PERSONAL_TAB, { userId: selectedUserId }), "_blank");
        },
        children: selectedUserId ? /* @__PURE__ */ jsx(UserProfileBar, { userId: selectedUserId }) : void 0
      }
    )
  ] });
};
