"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { CompanyDepartmentAPI } from "@/api-client/company-department.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DepartmentViewModal = ({
  isOpen,
  setIsOpen,
  closePage,
  setIsFormOpen,
  selectedDepartment,
  refreshDepartments
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose: closePage, children: /* @__PURE__ */ jsx(
    DepartmentViewModalContent,
    {
      setIsFormOpen,
      closePage,
      selectedDepartment,
      refreshDepartments
    }
  ) });
};
const DepartmentViewModalContent = ({
  setIsFormOpen,
  closePage,
  selectedDepartment,
  refreshDepartments
}) => {
  var _a, _b;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState();
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const deleteDepartment = () => __async(void 0, null, function* () {
    try {
      if (departmentToDelete) yield CompanyDepartmentAPI.deleteCompanyDepartment(departmentToDelete);
      else throw new Error("No department to delete");
      showMessage("Successfully deleted department", "success");
      yield refreshDepartments();
    } catch (error) {
      showMessage(`Error encountered when trying to delete department: ${nestErrorMessage(error)}`, "error");
    }
  });
  const confirmDelete = (event, departmentId) => {
    if (event) setAnchorEl(event.currentTarget);
    setDepartmentToDelete(departmentId);
    setIsConfirmModalOpen(true);
  };
  if (!selectedDepartment) {
    return /* @__PURE__ */ jsx(Box, { sx: drawerContentSx, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Department not found" }) });
  }
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: selectedDepartment == null ? void 0 : selectedDepartment.name }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Members" }),
      (selectedDepartment == null ? void 0 : selectedDepartment.users) && ((_a = selectedDepartment == null ? void 0 : selectedDepartment.users) == null ? void 0 : _a.length) > 0 ? /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            maxHeight: "70vh",
            overflowY: "auto"
          },
          children: (_b = selectedDepartment == null ? void 0 : selectedDepartment.users) == null ? void 0 : _b.map((eachUser) => {
            return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(UserCell, { userId: eachUser.userId, avatarSize: "xxsmall", nameVariant: "caption" }, eachUser.userId) }, eachUser.userId);
          })
        }
      ) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "No members" })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: (event) => {
            if (selectedDepartment) confirmDelete(event, selectedDepartment.id);
            else showMessage("No department selected", "error");
          },
          children: polyglot.t("DepartmentManagementDrawerPage.deleteButton")
        }
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => {
            setIsFormOpen(true);
          },
          children: polyglot.t("DepartmentManagementDrawerPage.editButton")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isConfirmModalOpen,
        onClose: () => {
          setIsConfirmModalOpen(false);
        },
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (departmentToDelete) {
            setIsConfirmModalOpen(false);
            closePage();
            yield deleteDepartment();
          } else showMessage("No department selected", "error");
        }),
        message: polyglot.t("DepartmentManagementDrawerPage.confirmDeleteMessage"),
        callToAction: "Yes"
      }
    )
  ] });
};
