"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { IconContentRadioCard } from "@v2/components/theme-components/icon-content-radio-card.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { StyledRadio } from "@v2/components/theme-components/styled-radio.component";
import { Typography } from "@v2/components/typography/typography.component";
import { OTHER_PAYROLL_ENTITY } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup.page";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  CustomBenefitCategory,
  CustomBenefitType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_BENEFITS_DETAILS_ROUTE } from "@/lib/routes";
export const ChoosePayrollStep = ({
  name,
  onNext,
  payrolls,
  refresh,
  payrollId,
  setPayrollId
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const [isCreatingCustomBenefit, setIsCreatingCustomBenefit] = useState(false);
  const createCustomPension = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsCreatingCustomBenefit(true);
      const data = {
        category: CustomBenefitCategory.Pension,
        type: CustomBenefitType.Recurring,
        name,
        description: null,
        includedUserIds: [],
        benefitLink: null
      };
      const id = yield CustomBenefitAPI.createCustomBenefit(data);
      yield refresh();
      history.push(
        generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
          category: CustomBenefitCategory.Pension,
          id,
          productType: "custom"
        })
      );
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsCreatingCustomBenefit(false);
  }), [polyglot, name, history, showMessage, refresh]);
  const nextStep = useCallback(() => __async(void 0, null, function* () {
    if (payrollId === OTHER_PAYROLL_ENTITY) yield createCustomPension();
    else onNext();
  }), [onNext, payrollId, createCustomPension]);
  return /* @__PURE__ */ jsxs(Box, { sx: { margin: "auto", mt: spacing.mt10, width: "400px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: spacing.mb30 }, children: polyglot.t("PensionModule.selectPayrollEntity") }),
    payrolls.map((payroll) => {
      var _a, _b, _c, _d;
      return /* @__PURE__ */ jsx(
        IconContentRadioCard,
        {
          cardOnClick: () => setPayrollId(payroll.id),
          title: (_b = (_a = payroll.entity) == null ? void 0 : _a.legalName) != null ? _b : `PayrollID ${payroll.id}`,
          content: null,
          action: /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                flexDirection: "column",
                gap: 1,
                justifyContent: "center",
                alignItems: "end"
              },
              children: /* @__PURE__ */ jsx(
                StyledRadio,
                {
                  name: (_d = (_c = payroll.entity) == null ? void 0 : _c.legalName) != null ? _d : `PayrollID ${payroll.id}`,
                  id: `payroll-${payroll.id}`,
                  value: `${payroll.id}`,
                  selectedValue: `${payrollId || ""}`
                }
              )
            }
          ),
          showDivider: true,
          sx: { minHeight: "40px", maxHeight: "40px" }
        },
        payroll.id
      );
    }),
    /* @__PURE__ */ jsx(
      IconContentRadioCard,
      {
        cardOnClick: () => setPayrollId(OTHER_PAYROLL_ENTITY),
        title: polyglot.t("General.other"),
        content: null,
        action: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              alignItems: "end"
            },
            children: /* @__PURE__ */ jsx(
              StyledRadio,
              {
                name: polyglot.t("General.other"),
                id: `payroll-other`,
                value: String(OTHER_PAYROLL_ENTITY),
                selectedValue: `${payrollId || ""}`
              }
            )
          }
        ),
        showDivider: true,
        sx: { minHeight: "40px", maxHeight: "40px" }
      },
      "other"
    ),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.mt40 }), children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading: isCreatingCustomBenefit,
        fullWidth: true,
        onClick: nextStep,
        disabled: !payrollId
      }
    ) })
  ] });
};
