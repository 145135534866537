"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { linkButtonSx } from "@v2/styles/buttons.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { SETTINGS_NOTIFICATIONS_ROUTE } from "@/lib/routes";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const TestModeBanner = () => {
  const [globalState] = useContext(GlobalContext);
  const history = useHistory();
  const { polyglot } = usePolyglot();
  return globalState.isTestModeEnabled ? /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({
        width: "100%",
        backgroundColor: themeColors.Grey
      }, themeFonts.caption), {
        color: themeColors.black,
        display: "flex",
        justifyContent: "center",
        gap: spacing.g5,
        paddingY: spacing.p10,
        position: "absolute",
        zIndex: 5,
        alignItems: "center",
        opacity: "60%"
      }),
      children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("TestModeBanner.bannerText") }),
        /* @__PURE__ */ jsx(
          Button,
          {
            onClick: () => history.push(SETTINGS_NOTIFICATIONS_ROUTE),
            sx: __spreadProps(__spreadValues(__spreadValues({}, themeFonts.caption), linkButtonSx), { color: themeColors.black, paddingX: 0 }),
            children: polyglot.t("TestModeBanner.here")
          }
        )
      ]
    }
  ) : null;
};
