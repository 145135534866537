"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PermissionGroupAPI {
  static enablePermissionScope(permissionGroupId, scope) {
    return __async(this, null, function* () {
      return axios.patch(`/apiv2/permission-group-scopes/${permissionGroupId}/permission/enable`, { scope });
    });
  }
  static disablePermissionScope(permissionGroupId, scope) {
    return __async(this, null, function* () {
      return axios.patch(`/apiv2/permission-group-scopes/${permissionGroupId}/permission/disable`, { scope });
    });
  }
  static listPermissionGroups() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/permission-groups")).data;
    });
  }
  static listPermissionGroupsWithMembers() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/permission-groups/members")).data;
    });
  }
  static getPermissionGroup(permissionGroupId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/permission-groups/${permissionGroupId}`)).data;
    });
  }
  static updatePermissionGroupOptions(permissionGroupId, options) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/permission-groups/${permissionGroupId}/options`, options);
    });
  }
  static deletePermissionGroup(permissionGroupId) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/permission-groups/${permissionGroupId}`);
    });
  }
  static updatePermissionGroupDetails(permissionGroupId, permissionGroupDetails) {
    return __async(this, null, function* () {
      return axios.patch(`/apiv2/permission-groups/${permissionGroupId}`, permissionGroupDetails);
    });
  }
  static updatePermissionGroupMembers(permissionGroupId, members) {
    return __async(this, null, function* () {
      return axios.patch(`/apiv2/permission-groups/${permissionGroupId}/members`, { members });
    });
  }
  static createPermissionGroup(createPermissionGroup) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/permission-groups", createPermissionGroup)).data;
    });
  }
  static listPermissions() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/permissions")).data;
    });
  }
}
