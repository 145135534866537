"use strict";
export var CustomBenefitPaymentMethodType = /* @__PURE__ */ ((CustomBenefitPaymentMethodType2) => {
  CustomBenefitPaymentMethodType2["Tracking"] = "Tracking";
  CustomBenefitPaymentMethodType2["Expenses"] = "Expenses";
  CustomBenefitPaymentMethodType2["Payroll"] = "Payroll";
  return CustomBenefitPaymentMethodType2;
})(CustomBenefitPaymentMethodType || {});
export var UserCustomBenefitPaymentStatus = /* @__PURE__ */ ((UserCustomBenefitPaymentStatus2) => {
  UserCustomBenefitPaymentStatus2["Pending"] = "Pending";
  UserCustomBenefitPaymentStatus2["Approved"] = "Approved";
  UserCustomBenefitPaymentStatus2["Rejected"] = "Rejected";
  return UserCustomBenefitPaymentStatus2;
})(UserCustomBenefitPaymentStatus || {});
export var CustomBenefitCategory = /* @__PURE__ */ ((CustomBenefitCategory2) => {
  CustomBenefitCategory2["Asset"] = "Asset";
  CustomBenefitCategory2["Entertainment"] = "Entertainment";
  CustomBenefitCategory2["Educational"] = "Educational";
  CustomBenefitCategory2["Health"] = "Health";
  CustomBenefitCategory2["Loans"] = "Loans";
  CustomBenefitCategory2["Medical"] = "Medical";
  CustomBenefitCategory2["Mileage"] = "Mileage";
  CustomBenefitCategory2["Pension"] = "Pension";
  CustomBenefitCategory2["Telephone"] = "Telephone";
  CustomBenefitCategory2["Travel"] = "Travel";
  CustomBenefitCategory2["Vehicles"] = "Vehicles";
  CustomBenefitCategory2["Vouchers"] = "Vouchers";
  CustomBenefitCategory2["Other"] = "Other";
  return CustomBenefitCategory2;
})(CustomBenefitCategory || {});
export var CustomBenefitType = /* @__PURE__ */ ((CustomBenefitType2) => {
  CustomBenefitType2["Recurring"] = "Recurring";
  CustomBenefitType2["Allowance"] = "Allowance";
  CustomBenefitType2["Loan"] = "Loan";
  return CustomBenefitType2;
})(CustomBenefitType || {});
export var CustomBenefitAllowanceType = /* @__PURE__ */ ((CustomBenefitAllowanceType2) => {
  CustomBenefitAllowanceType2["Yearly"] = "Yearly";
  CustomBenefitAllowanceType2["Monthly"] = "Monthly";
  CustomBenefitAllowanceType2["OneOff"] = "OneOff";
  return CustomBenefitAllowanceType2;
})(CustomBenefitAllowanceType || {});
export var UserCustomBenefitRequestType = /* @__PURE__ */ ((UserCustomBenefitRequestType2) => {
  UserCustomBenefitRequestType2["OptOut"] = "OptOut";
  UserCustomBenefitRequestType2["LoanRequest"] = "LoanRequest";
  UserCustomBenefitRequestType2["RecurringChange"] = "RecurringChange";
  return UserCustomBenefitRequestType2;
})(UserCustomBenefitRequestType || {});
