"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useContext, useMemo } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { addDays, isMonday, previousMonday } from "date-fns";
import { groupBy } from "lodash";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { TIME_ME_CALENDAR_ROUTE } from "@/lib/routes";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getWeekRange } from "@/v2/feature/dashboard/dashboard.util";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { UserCalendarEmptyState } from "@/v2/feature/dashboard/features/sections/user-calendar/components/user-calendar-empty-state.component";
import {
  getCategorizedData,
  getCurrentDateAndFutureEvents,
  getFilteredData
} from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserCalendarVersionSmall = ({
  calendarSmall,
  readOnly = false
}) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const signedInUser = getCachedUserById(state.user.userId);
  const weekDaysFromMonToSun = useMemo(() => ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], []);
  const monday = useMemo(
    () => isMonday(new LocalDate().getDate()) ? new LocalDate().getDate() : previousMonday(new LocalDate().getDate()),
    []
  );
  const routerHistory = useHistory();
  const filteredData = useMemo(() => {
    var _a;
    const weekRange = getWeekRange();
    let users = nonTerminatedCachedUsers;
    return getFilteredData(
      weekRange,
      users,
      calendarSmall == null ? void 0 : calendarSmall.view,
      signedInUser,
      (_a = calendarSmall == null ? void 0 : calendarSmall.time) == null ? void 0 : _a.requests,
      calendarSmall == null ? void 0 : calendarSmall.fellowReportees
    );
  }, [
    calendarSmall == null ? void 0 : calendarSmall.time.requests,
    nonTerminatedCachedUsers,
    signedInUser,
    calendarSmall == null ? void 0 : calendarSmall.view,
    calendarSmall == null ? void 0 : calendarSmall.fellowReportees
  ]);
  const structuredData = useMemo(() => {
    let eventData = [];
    weekDaysFromMonToSun.map((d, idx) => {
      const currentDate = new LocalDate(addDays(monday, idx)).getDate();
      return getCategorizedData(eventData, filteredData, currentDate);
    });
    return eventData != null ? eventData : [];
  }, [monday, weekDaysFromMonToSun, filteredData]);
  const compressedData = useMemo(() => {
    const weekRange = getWeekRange();
    const firstDayOfTheWeek = new LocalDate(weekRange[0]).getDate();
    const todayAndFutureEvents = getCurrentDateAndFutureEvents(structuredData, firstDayOfTheWeek);
    return groupBy(todayAndFutureEvents, "type");
  }, [structuredData]);
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly, size: "small", children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          width: "100%",
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          cursor: "pointer"
        },
        onClick: () => routerHistory.push(TIME_ME_CALENDAR_ROUTE),
        children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserCalendarVersionSmall.calendar") }) })
      }
    ) }),
    Object.keys(compressedData).length > 0 ? /* @__PURE__ */ jsxs(Box, { children: [
      [...Object.keys(compressedData)].slice(0, 4).map((key) => {
        const userIds = [...compressedData[key]].map((u) => u.userId);
        return /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: spacing.p5,
              paddingY: spacing.p3
            },
            children: [
              /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "caption",
                  sx: { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
                  children: key
                }
              ),
              /* @__PURE__ */ jsx(MultiUserAvatar, { userIds, showLimit: 2, avatarSize: "xxxsmall", textVariant: "caption" })
            ]
          },
          `${key}-ucs`
        );
      }),
      Object.keys(compressedData).length > 4 && /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: spacing.m5
          },
          children: /* @__PURE__ */ jsx(
            "a",
            {
              href: TIME_ME_CALENDAR_ROUTE,
              target: "_blank",
              rel: "noopener noreferrer",
              style: __spreadValues({ color: themeColors.Grey, textDecoration: "none" }, themeFonts.captionSmall),
              children: polyglot.t("UserCalendarVersionSmall.viewMore", { count: Object.keys(compressedData).length - 4 })
            }
          )
        }
      )
    ] }) : /* @__PURE__ */ jsx(UserCalendarEmptyState, {})
  ] }) });
};
