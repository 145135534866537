"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { ReactComponent as Edit } from "@/images/side-bar-icons/Edit.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { Divider } from "@/v2/components/divider.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { styledChipSx } from "@/v2/styles/chip.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 14, height: 14 };
export const ActionSelectionItemActionButton = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx(
    IconButton,
    __spreadProps(__spreadValues({
      sx: __spreadProps(__spreadValues({}, secondaryCTABtn), {
        backgroundColor: "#ffffffc8",
        p: 0,
        height: "30px",
        width: "30px"
      })
    }, props), {
      children
    })
  );
};
export const ActionSelectionItemAddButton = (_c) => {
  var props = __objRest(_c, []);
  return /* @__PURE__ */ jsx(ActionSelectionItemActionButton, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)) }));
};
export const ActionSelectionItemEditButton = (_d) => {
  var props = __objRest(_d, []);
  return /* @__PURE__ */ jsx(ActionSelectionItemActionButton, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }));
};
export const ActionSelectionItemDeleteButton = (_e) => {
  var props = __objRest(_e, []);
  return /* @__PURE__ */ jsx(ActionSelectionItemActionButton, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }));
};
export const ActionSelectionItem = ({
  chips,
  step,
  addButtonIcon = "plus",
  onAddClick,
  onDeleteClick,
  sx,
  warning
}) => {
  const { Icon, title, description, required } = step;
  const displayedChips = useMemo(() => {
    var _a;
    return (_a = chips == null ? void 0 : chips.filter(Boolean)) != null ? _a : [];
  }, [chips]);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", alignItems: "flex-start", gap: spacing.g15 }, sx), children: [
    Icon && /* @__PURE__ */ jsx(Icon, { style: { flexShrink: 0 } }),
    /* @__PURE__ */ jsxs(Stack, { children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
        required && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "Required" })
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: description }),
      displayedChips.length > 0 && /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", flexWrap: "wrap", alignItems: "center", mt: spacing.mt10, gap: spacing.g5 }, children: displayedChips.map((content) => /* @__PURE__ */ jsx(Box, { sx: styledChipSx, children: content }, typeof content === "string" ? content : null)) }),
      !!warning && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt10 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.RedDark }, children: warning }) })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", ml: "auto" }, children: [
      onAddClick && /* @__PURE__ */ jsx(ActionSelectionItemActionButton, { onClick: onAddClick, children: {
        edit: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        plus: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
      }[addButtonIcon] }),
      onDeleteClick && /* @__PURE__ */ jsx(ActionSelectionItemActionButton, { onClick: onDeleteClick, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
    ] })
  ] });
};
export const ActionSelectionGroupComponent = ({
  title,
  actions,
  emptyActionsMessage
}) => {
  return /* @__PURE__ */ jsx(Stack, { sx: { maxWidth: "440px", flex: 1, ml: "auto", overflowY: "auto" }, children: /* @__PURE__ */ jsxs(
    Stack,
    {
      sx: {
        p: spacing.p30,
        mb: spacing.mb30,
        borderRadius: radius.br15,
        backgroundColor: themeColors.Background,
        height: "fit-content"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: title }),
        /* @__PURE__ */ jsx(ActionSelectionItemList, { actions, emptyActionsMessage })
      ]
    }
  ) });
};
export const ActionSelectionItemList = ({ actions, emptyActionsMessage }) => {
  return /* @__PURE__ */ jsxs(
    Stack,
    {
      sx: {
        mt: spacing.mt10,
        overflow: "auto",
        // this pr+mr combination creates a gap between the boxes and the scrollbar
        pr: "16px",
        mr: "-16px",
        "&::-webkit-scrollbar": { width: "3px" }
      },
      children: [
        actions.map((step, idx) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
          idx > 0 && /* @__PURE__ */ jsx(Divider, {}),
          step
        ] }, null)),
        actions.length === 0 && emptyActionsMessage && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: emptyActionsMessage })
      ]
    }
  );
};
