"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { DeviceOSOther, DeviceOSValueLabelOptions } from "@v2/feature/device/device.util";
import {
  deviceListSx,
  deviceSx,
  deviceTermSx,
  devicetitleSx,
  deviceValueSx
} from "@/v2/feature/device/features/device-cards/components/devices-styles.layout";
export const DeviceTechSpecsCard = ({ deviceModel, device }) => {
  var _a, _b, _c;
  return /* @__PURE__ */ jsxs(Box, { component: "header", sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: devicetitleSx, children: "Tech specs" }),
    /* @__PURE__ */ jsx(Box, { component: "section", children: /* @__PURE__ */ jsxs(Box, { component: "dl", sx: deviceListSx, children: [
      ((device == null ? void 0 : device.modelName) || deviceModel.modelName) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Model Name" }),
        /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: (_a = device == null ? void 0 : device.modelName) != null ? _a : deviceModel.modelName })
      ] }),
      ((device == null ? void 0 : device.modelNumber) || deviceModel.modelNumber) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Model Number" }),
        /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: (_b = device == null ? void 0 : device.modelNumber) != null ? _b : deviceModel.modelNumber })
      ] }),
      deviceModel.os && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Platform" }),
        /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: ((_c = DeviceOSValueLabelOptions.find((opt) => opt.value === deviceModel.os)) == null ? void 0 : _c.label) || DeviceOSOther.label })
      ] }),
      (device == null ? void 0 : device.serialNumber) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Serial Number" }),
        /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: device == null ? void 0 : device.serialNumber })
      ] }),
      (deviceModel == null ? void 0 : deviceModel.ram) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Memory" }),
        /* @__PURE__ */ jsxs(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: [
          deviceModel == null ? void 0 : deviceModel.ram,
          "GB"
        ] })
      ] }),
      (deviceModel == null ? void 0 : deviceModel.storage) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Storage" }),
        /* @__PURE__ */ jsxs(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: [
          deviceModel == null ? void 0 : deviceModel.storage,
          "GB"
        ] })
      ] }),
      (deviceModel == null ? void 0 : deviceModel.cpuCores) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "CPU" }),
        /* @__PURE__ */ jsxs(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: [
          deviceModel == null ? void 0 : deviceModel.cpuCores,
          "-cores"
        ] })
      ] }),
      (deviceModel == null ? void 0 : deviceModel.gpuCores) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "GPU" }),
        /* @__PURE__ */ jsxs(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: [
          deviceModel == null ? void 0 : deviceModel.gpuCores,
          "-cores"
        ] })
      ] }),
      (device == null ? void 0 : device.customerNotes) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "GPU" }),
        /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: device == null ? void 0 : device.customerNotes })
      ] })
    ] }) })
  ] });
};
