"use strict";
import { useCallback, useContext } from "react";
import { GlobalContext } from "@/GlobalState";
import { canAccessScopes, checkScopes } from "@/lib/scopes";
const useScopes = () => {
  const context = useContext(GlobalContext);
  const state = context ? context[0] : { user: void 0 };
  const getScopesContext = useCallback(
    (targetUser) => {
      var _a;
      return {
        userId: (_a = targetUser == null ? void 0 : targetUser.userId) != null ? _a : -1
      };
    },
    []
  );
  const hasScopes = useCallback(
    (scopes, context2) => state.user ? checkScopes(state.user, scopes, context2) : false,
    [state.user]
  );
  const hasScopesAccess = useCallback(
    (scopes) => state.user ? canAccessScopes(state.user, scopes) : false,
    [state.user]
  );
  return {
    hasScopes,
    hasScopesAccess,
    getScopesContext
  };
};
export default useScopes;
