"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { MultiUserAvatar } from "@v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
const translateStepNo = (stepNo, noOfApprovers, polyglot) => {
  if (polyglot.has(`ApprovalRuleModule.ApprovalSteps.${stepNo}`))
    return polyglot.t(`ApprovalRuleModule.ApprovalSteps.${stepNo}`, { noOfApprovers });
  return polyglot.t(`ApprovalRuleModule.ApprovalSteps.x`, { stepNo, noOfApprovers });
};
export const ApproversList = ({
  approverSteps,
  sx = {},
  layout = "vertical",
  rowAvatarsLimit = 5,
  labelVariant = "captionSmall",
  verticalGap = "4px"
}) => {
  const { polyglot } = usePolyglot();
  const isAutoApprove = useMemo(() => {
    return !approverSteps || approverSteps.length === 0 || approverSteps.every((step) => step.approversIds.length === 0);
  }, [approverSteps]);
  return isAutoApprove ? null : /* @__PURE__ */ jsx(Stack, { gap: "20px", sx: __spreadValues({}, sx), children: approverSteps == null ? void 0 : approverSteps.map((step, index) => {
    return layout === "vertical" ? /* @__PURE__ */ jsxs(Stack, { gap: verticalGap, children: [
      /* @__PURE__ */ jsx(Typography, { variant: labelVariant, color: "Grey", children: translateStepNo(index + 1, step.minApprovers, polyglot) }),
      /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: step.approversIds, showLimit: rowAvatarsLimit }, index)
    ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: labelVariant, children: translateStepNo(index + 1, step.minApprovers, polyglot) }),
      /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: step.approversIds, showLimit: rowAvatarsLimit }, index)
    ] });
  }) });
};
