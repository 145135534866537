"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { SuperAdminInsurancePoliciesRouter } from "@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-policies.router";
import { SuperAdminInsuranceQuotesPage } from "@v2/feature/super-admin/features/super-admin-insurance/pages/super-admin-insurance-quotes.page";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  SUPER_ADMIN_INSURANCE_POLICIES_ROUTE,
  SUPER_ADMIN_INSURANCE_QUOTES_ROUTE,
  SUPER_ADMIN_INSURANCE_ROUTE
} from "@/lib/routes";
const PAGE_CONFIG = {
  header: {
    tabs: [
      { label: "Quotes", value: SUPER_ADMIN_INSURANCE_QUOTES_ROUTE, scopes: ["insurance"] },
      { label: "Policies", value: SUPER_ADMIN_INSURANCE_POLICIES_ROUTE, scopes: ["insurance"] }
    ]
  }
};
export const SuperAdminInsuranceRouter = ({ companies, users }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_INSURANCE_QUOTES_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminInsuranceQuotesPage, { companies, users, pageConfig: PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_INSURANCE_POLICIES_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminInsurancePoliciesRouter, { companies, users, pageConfig: PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_INSURANCE_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_INSURANCE_QUOTES_ROUTE }) })
  ] });
};
