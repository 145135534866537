"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
export const ChipComponent = ({
  name,
  onClick,
  textColor = "Grey",
  backgroundColor = "Background",
  border,
  textVariant = "caption",
  style = {},
  textSx = {},
  showTooltip = false
}) => {
  const chipStyle = __spreadValues({
    display: "inline-block",
    padding: "2px 6px",
    borderRadius: "25px",
    backgroundColor: themeColors[backgroundColor],
    cursor: onClick ? "pointer" : "default",
    border: border ? borders[border] : void 0
  }, style);
  const handleClick = () => {
    if (onClick) onClick();
  };
  return /* @__PURE__ */ jsx(StyledTooltip, { title: name, disabled: !showTooltip || typeof name !== "string", children: /* @__PURE__ */ jsx("div", { style: chipStyle, onClick: handleClick, children: /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, sx: textSx, children: name }) }) });
};
