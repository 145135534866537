"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from "react";
import { ReportsAdvancedCreatePage } from "@v2/feature/reports/reports-advanced/reports-advanced-create.page";
import { ReportsAdvancedOverviewPage } from "@v2/feature/reports/reports-advanced/reports-advanced-overview.page";
import { ReportsAdvancedViewPage } from "@v2/feature/reports/reports-advanced/reports-advanced-view.page";
import { ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE,
  REPORTS_TEAM_CREATE_ROUTE,
  REPORTS_TEAM_VIEW_REPORT_ROUTE
} from "@/lib/routes";
export const ReportsV2TeamRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const { data: reports, mutate: refreshReports, isLoading } = useApiClient(ReportsEndpoints.getAllNewReports("team"), {
    suspense: false
  });
  const refreshReportsList = useCallback(() => __async(void 0, null, function* () {
    if (refreshReports) yield refreshReports();
  }), [refreshReports]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reports:manager"], path: REPORTS_TEAM_CREATE_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(ReportsAdvancedCreatePage, { refreshReportsList, target: "team" }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reports:manager"], path: REPORTS_TEAM_VIEW_REPORT_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(
      ReportsAdvancedViewPage,
      {
        reports: reports != null ? reports : [],
        refreshReportsList,
        isLoading: !!isLoading,
        target: "team"
      }
    ) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reports:manager"],
        path: REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE,
        context: scopesContext,
        children: /* @__PURE__ */ jsx(
          ReportsAdvancedOverviewPage,
          {
            reports: reports != null ? reports : [],
            isLoading: !!isLoading,
            refreshReportsList,
            target: "team"
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(Redirect, { to: REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE })
  ] });
};
