"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { NotificationAPI } from "@/api-client/index.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ApproverSelectComponent } from "@/v2/components/approver-select/approver-select.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import { NotificationStatus } from "@/v2/feature/notification/notification-settings/features/components/notifications-setting-table.component";
import {
  ATTENDANCE_NOTIFICATIONS,
  DAYS_OF_THE_WEEK_OPTIONS,
  DOMAINS_WITH_BEFORE,
  DefaultNotifications,
  EMPLOYEE_TRIGGER_EVENTS,
  NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK,
  NOTIFICATIONS_TO_HIDE_SPECIFIC,
  NotificationSendToHumanValue,
  NotificationSystemName,
  NotificationWhen,
  REMINDER_SCHEDULE_OPTIONS,
  USER_NOTIFICATIONS_LIST,
  WhenFilterEnum,
  WhenFilterOptions,
  DEVICE_NOTIFICATIONS
} from "@/v2/feature/notification/notification-settings/notification.interface";
import { addDefaultUserDateFields } from "@/v2/feature/notification/notification.util";
import { ProfileNotificationAPI } from "@/v2/feature/notification/profile-notification.api";
import { HOUR_OF_THE_DAY_OPTIONS } from "@/v2/feature/user/features/user-notifications/user-notifications.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const NotificationsSettingEditFormDrawer = ({
  notificationToEdit,
  setIsOpen,
  allSlackChannels,
  slackConnected,
  refreshNotificationData,
  allDateFields
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(
    notificationToEdit.systemName && DOMAINS_WITH_BEFORE.includes(notificationToEdit.systemName) && (notificationToEdit == null ? void 0 : notificationToEdit.daysBefore) && notificationToEdit.daysBefore > 0 ? WhenFilterEnum.BEFORE : WhenFilterEnum.IMMEDIATE
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleSubmitForUserNotifications = (values) => __async(void 0, null, function* () {
    var _a2, _b2;
    const { emailEnabled, slackEnabled, sendToEditValue, cronHour, slackChannels } = values;
    const slackChannelValues = slackChannels == null ? void 0 : slackChannels.map((d) => d.value);
    const specificUserList = ((_a2 = sendToEditValue == null ? void 0 : sendToEditValue.userIds) == null ? void 0 : _a2.length) > 0 ? sendToEditValue == null ? void 0 : sendToEditValue.userIds : [];
    const payload = {
      email: emailEnabled,
      slack: slackChannelValues && (slackChannelValues == null ? void 0 : slackChannelValues.length) > 0 && slackEnabled ? slackEnabled : false,
      emails: specificUserList,
      specificUsers: specificUserList,
      sendToEveryone: ((_b2 = sendToEditValue == null ? void 0 : sendToEditValue.userIds) == null ? void 0 : _b2.length) <= 0 && sendToEditValue.presets.includes("everyone"),
      cronHour: cronHour != null ? cronHour : 0,
      slackChannels: slackChannelValues != null ? slackChannelValues : []
    };
    if (notificationToEdit.systemName)
      yield NotificationAPI.updateNotificationSettings(notificationToEdit.systemName, payload);
  });
  const handleSubmitForProfileNotifications = (values) => __async(void 0, null, function* () {
    var _a2, _b2, _c2, _d2;
    const {
      id,
      systemName,
      emailEnabled,
      sendToEditValue,
      createdAt,
      slackChannels,
      notificationText,
      employeeTriggerEvent,
      name,
      when
    } = values;
    const isOnboardingReminder2 = name === DefaultNotifications.onboardingReminder || systemName === NotificationSystemName.securityDigest;
    const isProfileNotification = systemName === NotificationSystemName.profileNotification;
    const [fieldId, formId] = isProfileNotification ? (_a2 = employeeTriggerEvent != null ? employeeTriggerEvent : "") == null ? void 0 : _a2.split("/") : [];
    const slackChannelValues = slackChannels == null ? void 0 : slackChannels.map((d) => d.value);
    const payload = {
      id,
      domain: systemName,
      sendAdmin: (_b2 = sendToEditValue == null ? void 0 : sendToEditValue.presets) == null ? void 0 : _b2.includes("admin"),
      sendManager: (_c2 = sendToEditValue == null ? void 0 : sendToEditValue.presets) == null ? void 0 : _c2.includes("manager:1"),
      sendSpecificUsers: ((_d2 = sendToEditValue == null ? void 0 : sendToEditValue.userIds) == null ? void 0 : _d2.length) > 0 ? true : false,
      email: emailEnabled,
      specificUserEmails: sendToEditValue == null ? void 0 : sendToEditValue.userIds,
      createdAt,
      daysBefore: isOnboardingReminder2 && when === NotificationWhen.ONCE ? null : isOnboardingReminder2 && [NotificationWhen.WEEKLY, NotificationWhen.DAILY].includes(when) ? 0 : filterValue === WhenFilterEnum.BEFORE ? values.daysBefore : 0,
      updatedAt: /* @__PURE__ */ new Date(),
      cronHour: isProfileNotification ? values == null ? void 0 : values.cronHour : void 0,
      slack: isProfileNotification ? values == null ? void 0 : values.slackEnabled : void 0,
      slackChannels: isProfileNotification ? slackChannelValues : [],
      notificationText: isProfileNotification ? notificationText : void 0,
      fieldId: isProfileNotification ? fieldId : void 0,
      formId: isProfileNotification ? formId : void 0,
      name: isProfileNotification || isOnboardingReminder2 ? name : void 0,
      sendSlackBot: isProfileNotification ? values == null ? void 0 : values.sendSlackBot : void 0,
      weeklyDay: isOnboardingReminder2 && when === NotificationWhen.WEEKLY ? 1 : isOnboardingReminder2 && when !== NotificationWhen.WEEKLY ? null : void 0
    };
    yield ProfileNotificationAPI.updateNotificationSettings(payload);
  });
  const handleSubmitForAttendanceNotifications = (values) => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    const { id, systemName, emailEnabled, sendToEditValue, createdAt, weeklyDay } = values;
    const payload = {
      id,
      domain: systemName,
      sendAdmin: (_a2 = sendToEditValue == null ? void 0 : sendToEditValue.presets) == null ? void 0 : _a2.includes("admin"),
      sendManager: (_b2 = sendToEditValue == null ? void 0 : sendToEditValue.presets) == null ? void 0 : _b2.includes("manager:1"),
      sendSpecificUsers: ((_c2 = sendToEditValue == null ? void 0 : sendToEditValue.userIds) == null ? void 0 : _c2.length) > 0 ? true : false,
      email: emailEnabled,
      specificUserEmails: sendToEditValue == null ? void 0 : sendToEditValue.userIds,
      weeklyDay,
      createdAt,
      updatedAt: /* @__PURE__ */ new Date()
    };
    yield ProfileNotificationAPI.updateNotificationSettings(payload);
  });
  const handleSubmit = (values) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (notificationToEdit.systemName && USER_NOTIFICATIONS_LIST.includes(notificationToEdit.systemName)) {
        yield handleSubmitForUserNotifications(values);
      } else if (notificationToEdit.systemName && ATTENDANCE_NOTIFICATIONS.includes(notificationToEdit.systemName)) {
        yield handleSubmitForAttendanceNotifications(values);
      } else if (notificationToEdit.systemName && DEVICE_NOTIFICATIONS.includes(notificationToEdit.systemName)) {
        yield handleSubmitForAttendanceNotifications(values);
      } else {
        yield handleSubmitForProfileNotifications(values);
      }
      setTimeout(() => {
        refreshNotificationData();
        setIsOpen(false);
        formik.resetForm();
        showMessage(
          polyglot.t("NotificationsSettingEditFormDrawer.successMessages.update", { name: notificationToEdit.name }),
          "success"
        );
        setLoading(false);
      }, 1e3);
    } catch (e) {
      showMessage(polyglot.t("NotificationsSettingsEdit.errorMessages.cannotbeSaved"), "error");
      setLoading(false);
    }
  });
  const confirmDelete = (event) => {
    if (event) setAnchorEl(event.currentTarget);
    setIsConfirmModalOpen(true);
  };
  const handleCustomProfileNotificationDelete = (notificationId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield ProfileNotificationAPI.deleteCustomProfileNotification(notificationId);
      setTimeout(() => {
        refreshNotificationData();
        setIsOpen(false);
        formik.resetForm();
        showMessage(polyglot.t("NotificationsSettingsEdit.successMessages.delete"), "success");
        setLoading(false);
      }, 1e3);
    } catch (e) {
      showMessage(polyglot.t("NotificationsSettingsEdit.errorMessages.delete"), "error");
      setLoading(false);
    }
  });
  const employeeTriggerEventFormAndField = (_a = notificationToEdit == null ? void 0 : notificationToEdit.employeeTriggerEvent) == null ? void 0 : _a.split("/");
  let triggerField;
  let triggerFormId;
  if (Array.isArray(employeeTriggerEventFormAndField) && employeeTriggerEventFormAndField.length === 2) {
    [triggerField, triggerFormId] = employeeTriggerEventFormAndField;
  }
  const isDefaultEmployeeDateProfileNotification = triggerField && (!triggerFormId || triggerFormId === "null") ? EMPLOYEE_TRIGGER_EVENTS.has(triggerField) : false;
  const formik = useFormik({
    initialValues: __spreadValues(__spreadValues({}, notificationToEdit), isDefaultEmployeeDateProfileNotification ? { employeeTriggerEvent: triggerField } : {}),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      handleSubmit(values);
    })
  });
  useEffect(() => {
    setFilterValue(
      notificationToEdit.systemName && DOMAINS_WITH_BEFORE.includes(notificationToEdit.systemName) && (notificationToEdit == null ? void 0 : notificationToEdit.daysBefore) && notificationToEdit.daysBefore > 0 ? WhenFilterEnum.BEFORE : WhenFilterEnum.IMMEDIATE
    );
  }, [notificationToEdit]);
  const toggleNotificationStatus = (skipMessage = false) => __async(void 0, null, function* () {
    try {
      if (!skipMessage) setLoading(true);
      if (notificationToEdit.systemName) {
        if (USER_NOTIFICATIONS_LIST.includes(notificationToEdit.systemName)) {
          yield NotificationAPI.toggleNotificationStatus(notificationToEdit.systemName, !notificationToEdit.status);
        } else {
          if (notificationToEdit.id)
            yield ProfileNotificationAPI.toggleNotificationStatus(
              notificationToEdit.id,
              notificationToEdit.systemName,
              !notificationToEdit.status
            );
        }
      }
      refreshNotificationData();
      setIsOpen(false);
      formik.resetForm();
      if (!skipMessage)
        showMessage(
          notificationToEdit.status ? polyglot.t("NotificationsSettingEditFormDrawer.successMessages.disable", {
            name: notificationToEdit.name
          }) : polyglot.t("NotificationsSettingEditFormDrawer.successMessages.enable", {
            name: notificationToEdit.name
          }),
          "success"
        );
    } catch (error) {
      showMessage(
        polyglot.t("NotificationsSettingEditFormDrawer.errorMessages.toggle", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      if (!skipMessage) setLoading(false);
    }
  });
  const saveAndEnable = () => __async(void 0, null, function* () {
    try {
      yield handleSubmit(formik.values);
      yield toggleNotificationStatus(true);
    } catch (error) {
      showMessage(
        polyglot.t("NotificationsSettingEditFormDrawer.errorMessages.save", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    }
  });
  const handleCronHourChange = (event) => {
    var _a2;
    formik.setFieldValue("cronHour", (_a2 = event.target) == null ? void 0 : _a2.value);
  };
  const handleWeeklyDayChange = (event) => {
    var _a2;
    formik.setFieldValue("weeklyDay", (_a2 = event.target) == null ? void 0 : _a2.value);
  };
  const handleReminderScheduleChange = (event) => {
    var _a2;
    formik.setFieldValue("when", (_a2 = event.target) == null ? void 0 : _a2.value);
  };
  const isCustomProfileNotification = formik.values.systemName === NotificationSystemName.profileNotification;
  const handleEmployeeTriggerEvent = (event) => {
    var _a2;
    formik.setFieldValue("employeeTriggerEvent", (_a2 = event.target) == null ? void 0 : _a2.value);
  };
  const allDateFieldOptions = useMemo(() => {
    const result = [];
    if (allDateFields && allDateFields.dataFieldsCustom) {
      for (const eachCustom of allDateFields.dataFieldsCustom) {
        result.push({
          value: `${eachCustom.fieldId}/${eachCustom.formId}`,
          label: eachCustom.fieldName
        });
      }
    }
    return addDefaultUserDateFields(result);
  }, [allDateFields]);
  const sendToAdmin = (_d = (_c = (_b = formik.values) == null ? void 0 : _b.sendToEditValue) == null ? void 0 : _c.presets) == null ? void 0 : _d.includes("admin");
  const sendToManager = (_g = (_f = (_e = formik.values) == null ? void 0 : _e.sendToEditValue) == null ? void 0 : _f.presets) == null ? void 0 : _g.includes("manager:1");
  const suffixLabelForSlackBot = useMemo(() => {
    const botRecipients = [];
    if (sendToAdmin) botRecipients.push("Admin");
    if (sendToManager) botRecipients.push("Manager");
    return botRecipients.length > 0 ? ` to ${botRecipients.join(", ")}` : "";
  }, [sendToAdmin, sendToManager]);
  const shouldHideEveryone = (formik2) => {
    var _a2;
    if (formik2.values.systemName === NotificationSystemName.profileNotification) return true;
    if (formik2.values.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik2.values.systemName) && ((_a2 = formik2.values.sendToEditValue.userIds) == null ? void 0 : _a2.length) === 0)
      return false;
    return true;
  };
  const getHidePresets = (formik2) => {
    if (formik2.values.systemName && formik2.values.systemName !== NotificationSystemName.profileNotification && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik2.values.systemName)) {
      return ["admin", "manager:1"];
    } else if (formik2.values.systemName && NotificationSystemName.securityDigest === formik2.values.systemName) {
      return ["manager:1"];
    } else if (formik2.values.systemName && NotificationSystemName.userSecurityDigest === formik2.values.systemName) {
      return ["manager:1", "everyone", "admin"];
    } else {
      return ["everyone"];
    }
  };
  const shouldHideSpecific = (formik2) => {
    const EVERYONE_NOT_ALREADY_SELECTED_AND_NOTIFICATION_CAN_USE_SLACK = formik2.values.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik2.values.systemName) && !formik2.values.sendToEditValue.presets.includes("everyone");
    const NOTIFICATION_DOES_NOT_USE_SLACK_AND_NOT_ATTENDANCE_NOTIFICATION = formik2.values.systemName && !NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik2.values.systemName) && !ATTENDANCE_NOTIFICATIONS.includes(formik2.values.systemName);
    if (formik2.values.systemName && NOTIFICATIONS_TO_HIDE_SPECIFIC.includes(formik2.values.systemName)) return true;
    if (EVERYONE_NOT_ALREADY_SELECTED_AND_NOTIFICATION_CAN_USE_SLACK || NOTIFICATION_DOES_NOT_USE_SLACK_AND_NOT_ATTENDANCE_NOTIFICATION)
      return false;
    return true;
  };
  const isOnboardingReminder = formik.values.name === DefaultNotifications.onboardingReminder;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: polyglot.t("NotificationSettingsPage.editNotification") }),
      notificationToEdit.id && notificationToEdit.systemName === NotificationSystemName.profileNotification && /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadValues({}, iconButtonSx),
          onClick: (event) => confirmDelete(event),
          title: "Remove",
          disabled: loading,
          children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
        }
      )
    ] }),
    !isCustomProfileNotification && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, spacing.mt40), {
          display: "flex",
          flexDirection: "row",
          gap: spacing.g20,
          justifyContent: "space-between"
        }),
        children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingEditFormDrawer.notificationName") }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingEditFormDrawer.status") })
        ]
      }
    ),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          mt: isCustomProfileNotification ? spacing.mt40 : spacing.m5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        },
        children: [
          isCustomProfileNotification ? /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey, mb: spacing.mb5 }), children: polyglot.t("NotificationsSettingEditFormDrawer.notificationName") }),
            /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                label: "",
                name: "name",
                value: formik.values.name,
                onChange: formik.handleChange,
                error: formik.touched.name && !!formik.errors.name,
                helperText: formik.touched.name && formik.errors.name,
                type: "text",
                clearText: () => formik.setFieldValue("name", ""),
                sx: { minWidth: "270px" }
              }
            )
          ] }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: notificationToEdit.name }),
          !isCustomProfileNotification ? /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: /* @__PURE__ */ jsx(NotificationStatus, { status: notificationToEdit == null ? void 0 : notificationToEdit.status }) }) : /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey, mb: spacing.mb5 }), children: polyglot.t("NotificationsSettingEditFormDrawer.status") }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: /* @__PURE__ */ jsx(NotificationStatus, { status: notificationToEdit == null ? void 0 : notificationToEdit.status }) })
          ] })
        ]
      }
    ),
    isCustomProfileNotification && /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, spacing.mt15), {
          display: "flex",
          flexDirection: "row",
          gap: spacing.g20,
          justifyContent: "space-between"
        }),
        children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("NotificationsSettingEditFormDrawer.notificationText"),
            name: "notificationText",
            value: formik.values.notificationText,
            onChange: formik.handleChange,
            error: formik.touched.notificationText && !!formik.errors.notificationText,
            helperText: formik.touched.notificationText && formik.errors.notificationText,
            type: "text",
            clearText: () => formik.setFieldValue("notificationText", "")
          }
        )
      }
    ),
    isCustomProfileNotification && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt15 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingsEdit.employeeTriggerEvent") }),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "employeeTriggerEvent",
          label: "",
          options: allDateFieldOptions,
          value: formik.values.employeeTriggerEvent,
          compareValue: formik.values.employeeTriggerEvent,
          error: void 0,
          onChange: handleEmployeeTriggerEvent,
          helperText: void 0,
          fullWidth: true
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Stack, { display: "flex", direction: "column", sx: __spreadValues({}, spacing.mt20), children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey, mb: spacing.mb5 }), children: polyglot.t("NotificationsSettingEditFormDrawer.sendTo") }),
      isOnboardingReminder || NotificationSystemName.userSecurityDigest === formik.values.systemName ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { mb: spacing.mb20 }), children: NotificationSendToHumanValue.allEmployees }) : /* @__PURE__ */ jsx(
        ApproverSelectComponent,
        {
          hideEveryone: shouldHideEveryone(formik),
          hidePresets: getHidePresets(formik),
          hideSpecific: shouldHideSpecific(formik),
          hideAutoApprove: true,
          value: formik.values.sendToEditValue,
          userModalTitle: polyglot.t("NotificationsSettingEditFormDrawer.userModalTitle"),
          onChange: (value) => {
            formik.setFieldValue("sendToEditValue", value);
          },
          sx: { mb: spacing.mb20 }
        }
      ),
      notificationToEdit.systemName && !(ATTENDANCE_NOTIFICATIONS.includes(notificationToEdit.systemName) || DEVICE_NOTIFICATIONS.includes(notificationToEdit.systemName)) && /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.m5 }, gap: spacing.g10, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "When" }),
        isOnboardingReminder ? /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "reminderSchedule",
            label: "",
            options: REMINDER_SCHEDULE_OPTIONS,
            value: formik.values.when,
            compareValue: formik.values.reminderSchedule,
            error: void 0,
            onChange: handleReminderScheduleChange,
            helperText: void 0,
            fullWidth: true
          }
        ) : /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              disabled: (notificationToEdit == null ? void 0 : notificationToEdit.systemName) && !DOMAINS_WITH_BEFORE.includes(notificationToEdit == null ? void 0 : notificationToEdit.systemName),
              filters: WhenFilterOptions(polyglot),
              setFilterValue,
              filterValue,
              onFilterChange: ({ filterValue: filterValue2 }) => {
                setFilterValue(filterValue2);
                formik.setFieldValue("when", filterValue2);
              }
            }
          ),
          filterValue === WhenFilterEnum.BEFORE && /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "daysBefore",
              label: polyglot.t("NotificationsSettingEditFormDrawer.daysBefore"),
              value: formik.values.daysBefore,
              type: "number",
              onChange: formik.handleChange,
              error: formik.touched.daysBefore && !!formik.errors.daysBefore,
              helperText: (_h = formik.touched.daysBefore && formik.errors.daysBefore) != null ? _h : " ",
              clearText: () => formik.setFieldValue("daysBefore", "")
            }
          )
        ] })
      ] }),
      notificationToEdit.systemName && (ATTENDANCE_NOTIFICATIONS.includes(notificationToEdit.systemName) || DEVICE_NOTIFICATIONS.includes(notificationToEdit.systemName)) && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingsEdit.dayOfTheWeek") }),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "week",
            label: "",
            options: DAYS_OF_THE_WEEK_OPTIONS,
            value: formik.values.weeklyDay,
            compareValue: formik.values.weeklyDay,
            error: void 0,
            onChange: handleWeeklyDayChange,
            helperText: void 0,
            fullWidth: true
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingEditFormDrawer.channel") }),
        notificationToEdit && /* @__PURE__ */ jsx(Box, { sx: { display: "inline-flex", alignItems: "center", mt: spacing.mt10 }, children: /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Email",
            name: "emailEnabledSetting",
            checked: formik.values.emailEnabled,
            value: formik.values.emailEnabled,
            onChange: (e, checked) => {
              formik.setFieldValue("emailEnabled", checked);
            }
          }
        ) }),
        isCustomProfileNotification && (sendToAdmin || sendToManager) && /* @__PURE__ */ jsx(Box, { sx: { display: "inline-flex", alignItems: "center", mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("NotificationsSettingEditFormDrawer.slackBotSettings", {
              suffix: suffixLabelForSlackBot
            }),
            name: "slackBotSetting",
            checked: formik.values.sendSlackBot,
            value: formik.values.sendSlackBot,
            onChange: (e, checked) => {
              var _a2;
              formik.setFieldValue("sendSlackBot", checked);
              formik.setFieldValue(
                "slackEnabled",
                formik.values.slackChannels && ((_a2 = formik.values.slackChannels) == null ? void 0 : _a2.length) > 0 || checked
              );
            }
          }
        ) }),
        notificationToEdit && slackConnected && notificationToEdit.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(notificationToEdit.systemName) && /* @__PURE__ */ jsx(Box, { sx: { display: "inline-flex", alignItems: "center", mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("NotificationsSettingsEdit.slackChannels"),
            name: "slackEnabledSetting",
            checked: !!(formik.values.slackChannels && ((_i = formik.values.slackChannels) == null ? void 0 : _i.length) > 0),
            value: formik.values.slackEnabled,
            onChange: (e, checked) => {
              formik.setFieldValue("slackEnabled", checked);
              const updatedValues = checked && formik.values.slackChannels ? [...formik.values.slackChannels] : [];
              formik.setFieldValue("slackChannels", updatedValues);
            }
          }
        ) })
      ] }),
      notificationToEdit && slackConnected && formik.values.slackEnabled && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingsEdit.slackChannels") }),
        /* @__PURE__ */ jsx(
          MultipleSelectCheckbox,
          {
            label: "",
            id: "slackChannel",
            limitTags: -1,
            options: allSlackChannels,
            value: (_j = formik.values.slackChannels) != null ? _j : [],
            onChange: (e, values) => {
              formik.setFieldValue("slackChannels", values);
            },
            isOptionEqualToValue: (x, y) => x.value === y.value,
            getOptionLabel: ({ label }) => label
          }
        )
      ] }),
      notificationToEdit && formik.values && notificationToEdit.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(notificationToEdit.systemName) && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingsEdit.hourToSendReminders") }),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "cronHour",
            label: "",
            options: HOUR_OF_THE_DAY_OPTIONS,
            value: formik.values.cronHour,
            compareValue: formik.values.cronHour,
            error: void 0,
            onChange: handleCronHourChange,
            helperText: void 0,
            fullWidth: true
          }
        )
      ] })
    ] }),
    formik.values.status === true ? /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadValues({
          display: "flex",
          justifyContent: "space-between",
          gap: spacing.g10,
          width: "100%"
        }, spacing.mt20),
        children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "secondary",
              sizeVariant: "medium",
              disabled: loading || isOnboardingReminder,
              fullWidth: true,
              onClick: () => toggleNotificationStatus(false),
              children: polyglot.t("NotificationsSettingEditFormDrawer.disable")
            }
          ),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("General.save"),
              fullWidth: true,
              disabled: loading,
              loading,
              colorVariant: "primary",
              sizeVariant: "medium"
            }
          )
        ]
      }
    ) : /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadValues({
          display: "flex",
          justifyContent: "space-between",
          gap: spacing.g10,
          width: "100%"
        }, spacing.mt20),
        children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "medium",
            disabled: loading,
            fullWidth: true,
            onClick: saveAndEnable,
            children: polyglot.t("General.saveAndEnable")
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isConfirmModalOpen,
        onClose: () => {
          setIsConfirmModalOpen(false);
        },
        anchorEl,
        takeAction: () => {
          if (notificationToEdit && notificationToEdit.id) {
            setIsConfirmModalOpen(false);
            handleCustomProfileNotificationDelete(notificationToEdit.id);
          } else showMessage("No department selected", "error");
        },
        message: polyglot.t("NotificationsSettingEditFormDrawer.confirmDeleteMessage"),
        callToAction: "Yes"
      }
    )
  ] }) });
};
