"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
export const DelayLoad = ({ delay, children }) => {
  const displayTime = useRef(Date.now() + delay);
  const [showComponent, setShowComponent] = useState(delay <= 0);
  useEffect(() => {
    if (showComponent) return;
    const t = setTimeout(() => setShowComponent(true), displayTime.current - Date.now());
    return () => clearTimeout(t);
  }, [showComponent]);
  return showComponent ? /* @__PURE__ */ jsx(Fragment, { children }) : /* @__PURE__ */ jsx("span", { style: { visibility: "hidden" }, children });
};
