"use strict";
import { jsx } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { SectionModalContent } from "@/v2/feature/growth/surveys/features/components/section-modal-content.component";
export const SurveyCycleCreationSectionFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  section,
  refresh,
  cycleId
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setIsOpen(false);
      },
      children: /* @__PURE__ */ jsx(SectionModalContent, { cycleId, onClose, section, refresh })
    }
  );
};
