"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Skeleton, Stack } from "@mui/material";
import { DelayLoad } from "@/v2/components/delay-load.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const SkeletonLoader = ({
  variant,
  width = "100%",
  height = "100%",
  rows = 1,
  rowGap = "15px",
  sx = { backgroundColor: themeColors.Background },
  delay = 300,
  animation
}) => {
  const skeleton = useMemo(() => {
    const result = new Array(rows);
    for (let i = 0; i < rows; i++) {
      result[i] = /* @__PURE__ */ jsx(Skeleton, { variant, width, height, sx, animation }, i);
    }
    return result;
  }, [height, rows, sx, variant, width, animation]);
  return /* @__PURE__ */ jsx(DelayLoad, { delay, children: /* @__PURE__ */ jsx(Stack, { sx: { gap: rowGap }, children: skeleton }) });
};
