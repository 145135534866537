"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { UserSelect } from "@v2/components/user-select-type/user-select.component";
import { UserSelectFiltersOptions } from "@v2/components/user-select-type/user-select.interface";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { Typography } from "@/v2/components/typography/typography.component";
export const InsuranceSetupEmployeesStep = ({ onNext, quoteData, setQuoteData }) => {
  const { polyglot } = usePolyglot();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const [validationMessage, setValidationMessage] = useState("");
  const companyUserIds = useMemo(() => companyUsers.map((u) => u.userId), [companyUsers]);
  const [selectedOption, setSelectedOption] = useState(
    companyUserIds.length === quoteData.selectedUsers.length ? UserSelectFiltersOptions.Everyone : quoteData.selectedUsers.length > 0 ? UserSelectFiltersOptions.SelectSpecific : UserSelectFiltersOptions.None
  );
  const formik = useFormik({
    initialValues: {
      selectedUsers: quoteData.selectedUsers
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      selectedUsers: yup.array().nullable().notRequired()
    }),
    onSubmit: ({ selectedUsers }) => {
      if (selectedOption === UserSelectFiltersOptions.None)
        setValidationMessage(polyglot.t("BenefitModule.pleaseSelectOption"));
      else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
        setValidationMessage(polyglot.t("BenefitModule.pleaseSelectAtLeastOneUser"));
      else {
        setQuoteData((prev) => __spreadProps(__spreadValues({}, prev), { selectedUsers }));
        onNext();
      }
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { sx: { margin: "auto", mt: spacing.mt10, width: "400px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("General.employees") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.mt20, mb: spacing.mb30, color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.selectEmployeesToCoverLong") }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("BenefitModule.whoShouldBeSelected"),
        selectedLabel: polyglot.t("BenefitModule.selectedEmployees"),
        value: formik.values.selectedUsers,
        onChange: (userIds, filterValue) => {
          if (filterValue === UserSelectFiltersOptions.Everyone) {
            formik.setFieldValue("selectedUsers", companyUserIds);
          } else {
            formik.setFieldValue("selectedUsers", userIds);
          }
          setValidationMessage("");
          setSelectedOption(filterValue != null ? filterValue : UserSelectFiltersOptions.None);
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        error: !!validationMessage,
        helperText: !!validationMessage && validationMessage,
        initialFilterValue: UserSelectFiltersOptions.None
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.mt40 }), children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", type: "submit", fullWidth: true, children: polyglot.t("General.continue") }) })
  ] }) }) });
};
