"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import {
  isCurrentDateTimeLessThanOrEqualToSpecified,
  isTimingCorrectlySet
} from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-launch/rc-upsert-launch.util";
import { getDayOrDays } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/rc-upsert-timeline.page";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const LaunchScheduled = ({ reviewCycleLaunchDetails, cycleId, goBack, showFlags }) => {
  var _a, _b, _c, _d, _e, _f;
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const { timelineSettings } = reviewCycleLaunchDetails;
  const showActionButtons = useMemo(
    () => reviewCycleLaunchDetails && reviewCycleLaunchDetails.state === CycleState.Draft && reviewCycleLaunchDetails.totalQuestions > 0 && reviewCycleLaunchDetails.revieweeId.length > 0 && isTimingCorrectlySet(reviewCycleLaunchDetails.timelineSettings, reviewCycleLaunchDetails.reviewerSelect),
    [reviewCycleLaunchDetails]
  );
  const isTodayOrFuture = useMemo(() => {
    return reviewCycleLaunchDetails && reviewCycleLaunchDetails.timelineSettings.startDate ? isCurrentDateTimeLessThanOrEqualToSpecified(reviewCycleLaunchDetails.timelineSettings) && reviewCycleLaunchDetails.state === CycleState.Draft : false;
  }, [reviewCycleLaunchDetails]);
  const isStartNow = useMemo(
    () => Boolean(reviewCycleLaunchDetails && reviewCycleLaunchDetails.timelineSettings.startNow),
    [reviewCycleLaunchDetails]
  );
  const launchReviewCycle = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield ReviewCycleAPI.launchReviewCycle(cycleId);
      goBack();
      showMessage("Successfully launched the cycle", "success");
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          contentWidth: "100%",
          headerWidth: "100%",
          title: "Start review cycle",
          items: [
            {
              type: SectionItemType.Pair,
              label: "Number of questions",
              value: /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "title4",
                  color: reviewCycleLaunchDetails.totalQuestions > 0 ? "DarkGrey" : "RedDark",
                  children: (_a = reviewCycleLaunchDetails.totalQuestions) != null ? _a : "Please add some questions"
                }
              )
            },
            {
              type: SectionItemType.Pair,
              label: "Participants",
              value: /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "title4",
                  color: reviewCycleLaunchDetails.participantCount > 0 ? "DarkGrey" : "RedDark",
                  children: reviewCycleLaunchDetails.participantCount || "Please add some participants"
                }
              )
            },
            ...isStartNow ? [] : [
              {
                type: SectionItemType.Pair,
                label: "Start date",
                value: ((_b = reviewCycleLaunchDetails.timelineSettings) == null ? void 0 : _b.startDate) ? /* @__PURE__ */ jsx(Typography, { variant: "title4", color: isTodayOrFuture ? "DarkGrey" : "RedDark", children: new LocalDate((_c = reviewCycleLaunchDetails.timelineSettings) == null ? void 0 : _c.startDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }) }) : "NA"
              },
              {
                type: SectionItemType.Pair,
                label: "Start time",
                value: ((_d = reviewCycleLaunchDetails.timelineSettings) == null ? void 0 : _d.startTime) ? /* @__PURE__ */ jsxs(Typography, { variant: "title4", color: isTodayOrFuture ? "DarkGrey" : "RedDark", children: [
                  (_e = reviewCycleLaunchDetails.timelineSettings) == null ? void 0 : _e.startTime,
                  (_f = reviewCycleLaunchDetails.timelineSettings) == null ? void 0 : _f.startTimeMeridiem
                ] }) : "NA"
              }
            ],
            ...showFlags.showSelf ? [
              {
                type: SectionItemType.Pair,
                label: "Self review deadline",
                value: (timelineSettings == null ? void 0 : timelineSettings.selfReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.selfReviewDeadline} ${getDayOrDays(
                  timelineSettings == null ? void 0 : timelineSettings.selfReviewDeadline
                )} after start` : /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "RedDark", children: "Please provide a suitable deadline" })
              }
            ] : [],
            ...showFlags.showUpward ? [
              {
                type: SectionItemType.Pair,
                label: "Upward review deadline",
                value: (timelineSettings == null ? void 0 : timelineSettings.upwardReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.upwardReviewDeadline} ${getDayOrDays(
                  timelineSettings == null ? void 0 : timelineSettings.upwardReviewDeadline
                )} after start` : /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "RedDark", children: "Please provide a suitable deadline" })
              }
            ] : [],
            ...showFlags.showPeer ? [
              {
                type: SectionItemType.Pair,
                label: "Peer review deadline",
                value: (timelineSettings == null ? void 0 : timelineSettings.peerReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.peerReviewDeadline} ${getDayOrDays(
                  timelineSettings == null ? void 0 : timelineSettings.peerReviewDeadline
                )} after start` : /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "RedDark", children: "Please provide a suitable deadline" })
              }
            ] : [],
            ...showFlags.showManager ? [
              {
                type: SectionItemType.Pair,
                label: "Manager review deadline",
                value: (timelineSettings == null ? void 0 : timelineSettings.managerReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.managerReviewDeadline} ${getDayOrDays(
                  timelineSettings == null ? void 0 : timelineSettings.managerReviewDeadline
                )} after start` : /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "RedDark", children: "Please provide a suitable deadline" })
              }
            ] : [],
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g4, mt: spacing.m8 }, children: [
                /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: () => goBack(), children: "Save and close" }) }),
                showActionButtons && /* @__PURE__ */ jsx("div", { children: isStartNow || isTodayOrFuture ? /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    sizeVariant: "small",
                    colorVariant: "primary",
                    onClick: launchReviewCycle,
                    loading,
                    disabled: loading,
                    children: isStartNow ? "Start review cycle" : "Schedule review cycle"
                  }
                ) : null })
              ] }),
              hidden: Boolean(
                reviewCycleLaunchDetails && reviewCycleLaunchDetails.state && (reviewCycleLaunchDetails.state === CycleState.Ongoing || reviewCycleLaunchDetails.state === CycleState.Completed)
              )
            }
          ]
        }
      ]
    }
  );
};
