"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Card, CardContent, CardHeader, IconButton, Box, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { OauthClientEndpoints } from "@/api-client/oauth-client.api";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const processAPIData = (data) => {
  const authorisedCalls = Object.values(data.authorised).flat().length;
  const unauthorisedCalls = Object.values(data.unauthorised).flat().length;
  const missingCompanyContextCalls = Object.values(data.missingCompanyContext).flat().length;
  const rateLimitedCalls = Object.values(data.rateLimited).flat().length;
  const totalCalls = authorisedCalls + unauthorisedCalls + missingCompanyContextCalls + rateLimitedCalls;
  const allCalls = [
    ...Object.values(data.authorised).flat(),
    ...Object.values(data.unauthorised).flat(),
    ...Object.values(data.missingCompanyContext).flat(),
    ...Object.values(data.rateLimited).flat()
  ];
  const apiCounts = allCalls.reduce((acc, call) => {
    const key = `${call.method} ${call.url}`;
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});
  const topAPIs = Object.entries(apiCounts).sort((a, b) => b[1] - a[1]).slice(0, 5).map(([name, callCount]) => ({ name, callCount }));
  const callsByTime = allCalls.reduce((acc, call) => {
    const hour = new Date(call.timestamp).getHours();
    const timeSlot = `${hour.toString().padStart(2, "0")}:00`;
    acc[timeSlot] = (acc[timeSlot] || 0) + 1;
    return acc;
  }, {});
  const sortedCallsByTime = Object.entries(callsByTime).sort((a, b) => a[0].localeCompare(b[0])).map(([time, calls]) => ({ time, calls }));
  const clientCounts = allCalls.reduce((acc, call) => {
    var _a, _b, _c, _d;
    const clientId = (_a = call == null ? void 0 : call.clientId) != null ? _a : "unknown";
    const clientName = (_b = call == null ? void 0 : call.clientName) != null ? _b : null;
    const companyId = (_c = call == null ? void 0 : call.companyId) != null ? _c : null;
    const maskedToken = (_d = call == null ? void 0 : call.token) != null ? _d : null;
    const key = [clientId, clientName, companyId, maskedToken].filter(Boolean).join(" || ");
    acc[key] = (acc[key] || 0) + 1;
    return acc;
  }, {});
  const topClients = Object.entries(clientCounts).sort((a, b) => b[1] - a[1]).slice(0, 5).map(([clientId, callCount]) => ({ clientId, callCount }));
  const earliestCallTimestamp = allCalls.reduce((earliest, call) => {
    const callTime = new Date(call.timestamp).getTime();
    return callTime < earliest ? callTime : earliest;
  }, Number.MAX_VALUE);
  const earliestCallDate = new Date(earliestCallTimestamp).toString();
  return {
    totalCalls,
    authorisedCalls,
    unauthorisedCalls,
    missingCompanyContextCalls,
    rateLimitedCalls,
    topAPIs,
    callsByTime: sortedCallsByTime,
    topClients,
    earliestCallDate
  };
};
export const APIMonitoringDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: requestData, isValidating: loadingData, mutate: refreshRequestData } = useApiClient(
    OauthClientEndpoints.getGroupedStats(),
    {
      suspense: false
    }
  );
  useEffect(() => {
    try {
      setLoading(true);
      const processedData = requestData ? processAPIData(requestData) : null;
      setData(processedData);
    } catch (error) {
      console.error("Error fetching API usage data:", error);
    } finally {
      setLoading(false);
    }
  }, [requestData]);
  const callsChartOptions = useMemo(() => {
    return {
      chart: {
        id: "api-calls-chart",
        type: "bar"
      },
      xaxis: {
        categories: data ? ["Authorised Calls", "Unauthorised Calls", "Missing Context", "Rate Limited", "Total Calls"] : []
      },
      yaxis: {
        title: {
          text: "Number of Calls"
        }
      },
      colors: ["#4CAF50", "#FF5722", "#FFC107", "#2196F3", "#9C27B0"],
      title: {
        text: "API Calls Summary",
        align: "center"
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      }
    };
  }, [data]);
  const callsChartSeries = useMemo(() => {
    return [
      {
        name: "API Calls",
        data: [
          (data == null ? void 0 : data.authorisedCalls) || 0,
          (data == null ? void 0 : data.unauthorisedCalls) || 0,
          (data == null ? void 0 : data.missingCompanyContextCalls) || 0,
          (data == null ? void 0 : data.rateLimitedCalls) || 0,
          (data == null ? void 0 : data.totalCalls) || 0
        ]
      }
    ];
  }, [data]);
  const callsChart = useMemo(
    () => data ? /* @__PURE__ */ jsx(Card, { className: "mb-6", children: /* @__PURE__ */ jsx(CardContent, { children: /* @__PURE__ */ jsx(ReactApexChart, { options: callsChartOptions, series: callsChartSeries, type: "bar", height: 350 }) }) }) : null,
    [data, callsChartOptions, callsChartSeries]
  );
  const topAPIsListColumns = useMemo(() => {
    return [
      {
        header: () => "Endpoint",
        accessorFn: (row) => row,
        id: "name",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx("div", { children: original.name });
        }
      },
      {
        header: () => "Call count",
        accessorFn: (row) => row,
        id: "callCount",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.callCount),
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx("div", { children: original.callCount });
        }
      }
    ];
  }, []);
  const topAPIsList = useMemo(
    () => data ? /* @__PURE__ */ jsxs(Card, { children: [
      /* @__PURE__ */ jsx(CardHeader, { title: "Most Used APIs" }),
      /* @__PURE__ */ jsx(CardContent, { children: /* @__PURE__ */ jsx(BasicTable, { rowData: data.topAPIs, loading, columnData: topAPIsListColumns }) })
    ] }) : null,
    [data, loading, topAPIsListColumns]
  );
  const topClientsListColumns = useMemo(() => {
    return [
      {
        header: () => "Client",
        accessorFn: (row) => row,
        id: "clientId",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.clientId),
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx("div", { children: original.clientId });
        }
      },
      {
        header: () => "Call count",
        accessorFn: (row) => row,
        id: "callCount",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.callCount),
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx("div", { children: original.callCount });
        }
      }
    ];
  }, []);
  const topClientsList = useMemo(
    () => data ? /* @__PURE__ */ jsxs(Card, { children: [
      /* @__PURE__ */ jsx(CardHeader, { title: "Top Clients" }),
      /* @__PURE__ */ jsx(CardContent, { children: /* @__PURE__ */ jsx(BasicTable, { rowData: data.topClients, loading, columnData: topClientsListColumns }) })
    ] }) : null,
    [data, loading, topClientsListColumns]
  );
  const refreshData = () => {
    refreshRequestData == null ? void 0 : refreshRequestData();
  };
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "API Monitoring Dashboard" }),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: loading || loadingData, children: /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadProps(__spreadValues({
          display: "flex-column",
          justifyContent: "flex-start",
          width: "100%"
        }, spacing.mb20), {
          gap: "5px",
          alignItems: "center"
        }),
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: [
            /* @__PURE__ */ jsx(IconButton, { onClick: refreshData, className: "p-2 rounded-full hover:bg-gray-200", children: /* @__PURE__ */ jsx(RefreshIcon, {}) }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (data == null ? void 0 : data.earliestCallDate) ? `Data is from: ${data.earliestCallDate}` : "Loading data..." })
          ] }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: [
            callsChart,
            topAPIsList,
            topClientsList
          ] })
        ]
      }
    ) })
  ] });
};
