"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { PayrollSetting } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting.component";
import { PayrollSettingHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setttings-header.component";
import {
  HMRCPlaceholderData,
  isUsingPlaceholderHMRCData
} from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
const validationSchema = yup.object({
  officeNumber: yup.string().matches(/^[0-9]{3}$/, "Enter the office number in the format: 123").required("Required"),
  payeReference: yup.string().required("PAYE reference is required"),
  accountsOfficeReference: yup.string().required("Accounts office reference is required"),
  smallEmployersRelief: yup.boolean().required("This field is required"),
  govGatewayId: yup.string().required("Government gateway ID is required"),
  govGatewayIdChanged: yup.boolean(),
  password: yup.string().nullable().when("govGatewayId", {
    is: HMRCPlaceholderData.govGatewayId,
    otherwise: yup.string().nullable().required("Government gateway password is required")
  }),
  contactFirstName: yup.string().required("The full name of a payroll contact is required"),
  contactLastName: yup.string().required("The full name of a payroll contact is required"),
  contactEmail: yup.string().email("Enter a valid email address").required("A payroll contact email address is required")
});
export const PayrollSettingHMRCEdit = ({
  payrollId,
  payroll,
  title,
  onClose,
  sx
}) => {
  var _a, _b, _c, _d, _e;
  const [showMessage] = useMessage();
  const savedHMRCDetails = useRef({
    officeNumber: "",
    payeReference: "",
    accountsOfficeReference: "",
    smallEmployersRelief: false,
    govGatewayId: "",
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    password: ""
  });
  const close = useCallback(
    (updated) => {
      onClose == null ? void 0 : onClose(updated);
    },
    [onClose]
  );
  const updatePayroll = useCallback(
    (payroll2) => __async(void 0, null, function* () {
      try {
        yield PayrollExternalApi.updatePayroll(payrollId, payroll2);
        showMessage("Payroll updated successfully.", "success");
        return true;
      } catch (error) {
        showMessage(`Payroll could not be updated. ${nestErrorMessage(error)}`, "error");
        return false;
      }
    }),
    [payrollId, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      officeNumber: (payroll == null ? void 0 : payroll.hmrcDetails.officeNumber) || "",
      payeReference: (payroll == null ? void 0 : payroll.hmrcDetails.payeReference) || "",
      accountsOfficeReference: (payroll == null ? void 0 : payroll.hmrcDetails.accountsOfficeReference) || "",
      smallEmployersRelief: (payroll == null ? void 0 : payroll.hmrcDetails.smallEmployersRelief) || false,
      govGatewayId: (payroll == null ? void 0 : payroll.rtiSubmissionSettings.senderId) || "",
      contactFirstName: ((_a = payroll == null ? void 0 : payroll.rtiSubmissionSettings.contact) == null ? void 0 : _a.firstName) || "",
      contactLastName: ((_b = payroll == null ? void 0 : payroll.rtiSubmissionSettings.contact) == null ? void 0 : _b.lastName) || "",
      contactEmail: ((_c = payroll == null ? void 0 : payroll.rtiSubmissionSettings.contact) == null ? void 0 : _c.email) || "",
      password: (payroll == null ? void 0 : payroll.rtiSubmissionSettings.password) || null
    },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      const success = yield updatePayroll(values);
      if (success) {
        close(success);
      }
    })
  });
  const { touched, handleSubmit, isSubmitting } = formik;
  const [usingPlaceholderHMRCData, setUsingPlaceholderHMRCData] = useState(
    isUsingPlaceholderHMRCData(payroll && __spreadValues({ kind: "employer" }, payroll.hmrcDetails))
  );
  const onMissingHMRCChange = useCallback(
    (usePlaceholder) => {
      if (usePlaceholder) {
        savedHMRCDetails.current = __spreadValues({}, formik.values);
        formik.setValues(HMRCPlaceholderData);
      } else {
        formik.setValues(savedHMRCDetails.current);
      }
      setUsingPlaceholderHMRCData(usePlaceholder);
    },
    [formik]
  );
  useEscapeKey(() => close(false));
  const tooltips = useMemo(
    () => ({
      payeReference: /* @__PURE__ */ jsxs(Fragment, { children: [
        "You can find your PAYE reference in your Government Gateway account online and on letters or emails from HMRC regarding PAYE.",
        /* @__PURE__ */ jsx("br", {}),
        "They also appear on any P45s or P60s of your employees. If you don't have one yet, you can register online.",
        /* @__PURE__ */ jsx("br", {}),
        "Go to",
        " ",
        /* @__PURE__ */ jsx(
          "a",
          {
            href: "https://www.zelt.app/post/register-as-an-employer-in-the-uk",
            style: { color: "inherit", textDecoration: "none", fontWeight: 600, cursor: "pointer" },
            children: "register-as-an-employer-in-the-uk"
          }
        ),
        " ",
        'for more information.";'
      ] }),
      accountsOfficeReference: "13-character value required when paying to HMRC the PAYE liabilities; this number is issued at the same time as the Employer's PAYE reference number but contrary to the latter, this number does not go on documents given to employees",
      smallEmployersRelief: "You qualify if you paid \xA345,000 or less in Class 1 National Insurance in the last complete tax year. As an employer, you can usually reclaim 92% of employees' Statutory Maternity, Paternity, Adoption, Parental Bereavement and Shared Parental Pay. But you can reclaim 103% if your business qualifies for Small Employers' Relief."
    }),
    []
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ display: "flex", flex: 1, overflow: "hidden auto" }, sx), children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { rowGap: spacing.g10 }, children: [
      title && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: title }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Please complete your company details below." }),
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Company is not registered with HMRC or has no HMRC information available",
            checked: usingPlaceholderHMRCData,
            onChange: (_, checked) => onMissingHMRCChange(checked),
            disabled: isSubmitting
          }
        ),
        /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              mt: spacing.m5,
              visibility: usingPlaceholderHMRCData ? void 0 : "hidden",
              whiteSpace: "nowrap",
              color: themeColors.DarkGrey
            }),
            children: "You will need to update these settings after you receive your company account details from HMRC."
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: handleSubmit, children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "inline-grid",
            gridTemplateColumns: "1fr 3fr",
            rowGap: spacing.g10,
            columnGap: spacing.g20
          },
          children: [
            /* @__PURE__ */ jsx(PayrollSettingHeader, { sx: { mt: spacing.m30 }, children: "PAYE details" }),
            /* @__PURE__ */ jsx(
              PayrollSetting,
              {
                name: "fullPayeReference",
                label: "PAYE reference",
                formik,
                tooltip: tooltips.payeReference,
                mode: "custom",
                component: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g10, alignItems: "baseline" }, children: [
                  /* @__PURE__ */ jsx(
                    TextfieldComponent,
                    {
                      name: "officeNumber",
                      type: "tel",
                      autoFocus: true,
                      disabled: isSubmitting || usingPlaceholderHMRCData,
                      onChange: formik.handleChange,
                      clearText: () => formik.setFieldValue("officeNumber", ""),
                      endAdornment: "none",
                      value: formik.values["officeNumber"] || "",
                      error: !!formik.touched["officeNumber"] && !!formik.errors["officeNumber"],
                      helperText: formik.touched["officeNumber"] && ((_d = formik.errors["officeNumber"]) == null ? void 0 : _d.toString()),
                      maxLength: 3,
                      sx: { mt: spacing.m15, maxWidth: "80px" }
                    }
                  ),
                  /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "/" }),
                  /* @__PURE__ */ jsx(
                    TextfieldComponent,
                    {
                      name: "payeReference",
                      type: "text",
                      disabled: isSubmitting || usingPlaceholderHMRCData,
                      onChange: formik.handleChange,
                      clearText: () => formik.setFieldValue("payeReference", ""),
                      value: formik.values["payeReference"] || "",
                      error: !!formik.touched["payeReference"] && !!formik.errors["payeReference"],
                      helperText: formik.touched["payeReference"] && ((_e = formik.errors["payeReference"]) == null ? void 0 : _e.toString()),
                      sx: { mt: spacing.m15 }
                    }
                  )
                ] })
              }
            ),
            /* @__PURE__ */ jsx(
              PayrollSetting,
              {
                name: "accountsOfficeReference",
                label: "Accounts office reference",
                formik,
                tooltip: tooltips.accountsOfficeReference,
                disabled: isSubmitting || usingPlaceholderHMRCData
              }
            ),
            /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                sx: { gridColumn: "2 / 3", mt: spacing.mt20 },
                label: "Employer qualifies for Small Employers Relief",
                labelTooltip: tooltips.smallEmployersRelief,
                name: "smallEmployersRelief",
                onChange: formik.handleChange,
                disabled: isSubmitting || usingPlaceholderHMRCData
              }
            ),
            /* @__PURE__ */ jsx(PayrollSettingHeader, { sx: { mt: spacing.m30 }, children: "Government gateway" }),
            /* @__PURE__ */ jsx(
              PayrollSetting,
              {
                name: "govGatewayId",
                label: "Government gateway ID",
                formik,
                disabled: isSubmitting || usingPlaceholderHMRCData
              }
            ),
            /* @__PURE__ */ jsx(
              PayrollSetting,
              {
                name: "password",
                label: "Password",
                type: "password",
                formik,
                disabled: isSubmitting || usingPlaceholderHMRCData,
                autoComplete: "new-password"
              }
            ),
            /* @__PURE__ */ jsx(PayrollSettingHeader, { sx: { mt: spacing.m30 }, children: "Appoint a person for payroll notifications" }),
            /* @__PURE__ */ jsx(
              PayrollSetting,
              {
                name: "contactFirstName",
                label: "First name",
                formik,
                disabled: isSubmitting || usingPlaceholderHMRCData
              }
            ),
            /* @__PURE__ */ jsx(
              PayrollSetting,
              {
                name: "contactLastName",
                label: "Last name",
                formik,
                disabled: isSubmitting || usingPlaceholderHMRCData
              }
            ),
            /* @__PURE__ */ jsx(
              PayrollSetting,
              {
                name: "contactEmail",
                label: "Email",
                type: "email",
                formik,
                disabled: isSubmitting || usingPlaceholderHMRCData
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", my: spacing.m50, gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            disabled: isSubmitting,
            onClick: () => close(false),
            children: "Cancel"
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Save",
            type: "submit",
            sizeVariant: "medium",
            colorVariant: "primary",
            disabled: !touched,
            loading: isSubmitting
          }
        )
      ] })
    ] }) })
  ] });
};
