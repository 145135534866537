"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Tooltip } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { v4 } from "uuid";
import { isImportCellEditable } from "../util/entity-import.util";
function EntityImportDatagridValidationCell({ row, prop }) {
  const colErrors = row.errors.filter((error) => error.property === prop).flatMap((error) => Object.values(error.constraints));
  const colValue = row.entity[prop] || "";
  if (!colErrors.length) return /* @__PURE__ */ jsx(Fragment, { children: colValue });
  return /* @__PURE__ */ jsx(
    Tooltip,
    {
      title: /* @__PURE__ */ jsx("ul", { children: colErrors.map((error) => /* @__PURE__ */ jsx("li", { children: error }, error)) }),
      children: /* @__PURE__ */ jsxs(Box, { color: "red", children: [
        /* @__PURE__ */ jsx(ErrorIcon, { color: "error", fontSize: "small" }),
        /* @__PURE__ */ jsx(Fragment, { children: colValue })
      ] })
    }
  );
}
export function EntityImportDatagridValidation(_a) {
  var _b = _a, {
    rows,
    columns,
    setTableDataAsCsv,
    rowIdsToExport
  } = _b, props = __objRest(_b, [
    "rows",
    "columns",
    "setTableDataAsCsv",
    "rowIdsToExport"
  ]);
  const apiRef = useGridApiRef();
  const mappedColumns = columns.map((colDef) => __spreadProps(__spreadValues({}, colDef), {
    sortable: true,
    valueGetter: (param) => param.row.entity[colDef.field],
    valueSetter: (param) => {
      var _a2, _b2;
      return __spreadProps(__spreadValues({}, param.row), {
        entity: __spreadProps(__spreadValues({}, param.row.entity), { [colDef.field]: param.value }),
        errors: (_b2 = (_a2 = param.row) == null ? void 0 : _a2.errors) == null ? void 0 : _b2.filter((error) => error.property !== (colDef == null ? void 0 : colDef.field))
      });
    },
    renderCell: (param) => {
      return /* @__PURE__ */ jsx(EntityImportDatagridValidationCell, { row: param.row, prop: colDef.field });
    }
  }));
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    DataGridPro,
    __spreadProps(__spreadValues({
      isCellEditable: (param) => isImportCellEditable(param, rows),
      experimentalFeatures: { newEditingApi: true }
    }, props), {
      rows,
      apiRef,
      autoHeight: true,
      columns: mappedColumns,
      isRowSelectable: () => false,
      getRowId: (row) => (row == null ? void 0 : row.id) ? row.id : v4(),
      onStateChange: () => {
        try {
          const data = apiRef.current.getDataAsCsv({
            getRowsToExport: () => rowIdsToExport ? rowIdsToExport : apiRef.current.getAllRowIds()
          });
          if (setTableDataAsCsv) setTableDataAsCsv(data);
        } catch (error) {
          console.error(error);
        }
      }
    })
  ) });
}
