"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  SETTINGS_SECURITY_AUDIT_TRAIL_REPORT,
  SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  SETTINGS_SECURITY_SSO_DETAIL_ROUTE
} from "@/lib/routes";
import { SecurityAuditTrailReport } from "@/v2/feature/security/security-settings/components/security-audit-report.component";
import { DeveloperHubPage } from "@/v2/feature/security/security-settings/features/developer-hub/developer-hub-main.page";
import { SecuritySettingSSODetailPage } from "@/v2/feature/security/security-settings/features/security-setting-sso-detail.page";
import { SecuritySettingPage } from "@/v2/feature/security/security-settings/features/security-setting.page";
export const COMPANY_SETTINGS_ALL_SCOPE = "company.settings:all";
export const SecuritySettingRouter = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_SECURITY_AUDIT_TRAIL_REPORT, children: /* @__PURE__ */ jsx(SecurityAuditTrailReport, {}) }),
  /* @__PURE__ */ jsx(
    RouteScopesControl,
    {
      scopes: [COMPANY_SETTINGS_ALL_SCOPE],
      path: SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE,
      component: DeveloperHubPage
    }
  ),
  /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [COMPANY_SETTINGS_ALL_SCOPE], path: SETTINGS_SECURITY_SSO_DETAIL_ROUTE, exact: true, children: /* @__PURE__ */ jsx(SecuritySettingSSODetailPage, {}) }),
  /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [COMPANY_SETTINGS_ALL_SCOPE], path: SETTINGS_SECURITY_ROUTE, children: /* @__PURE__ */ jsx(SecuritySettingPage, {}) })
] });
