"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Box } from "@mui/material";
import { SurveyQuestionsBiggestImpact } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-insights/survey-questions-biggest-impact.component";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Status } from "@/images/reviews/Status.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { ChartPolarArea } from "@/v2/components/charts/chart-polar-area.component";
import { ChartProgress } from "@/v2/components/charts/chart-progress.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { impactExportReport } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail.util";
import { TitleStatusComponent } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { captureExcel, capturePNG, capturePdf } from "@/v2/util/export-reports.util";
export const SurveyCycleDetailInsightsPage = ({
  growthFactors,
  surveyCycle,
  cycleLoading,
  surveyResult,
  reach,
  filtersAndTypesOptions,
  setFilterString,
  filterString
}) => {
  var _a, _b;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : "Company";
  const { data: surveyInsights, isLoading: loadingInsights } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleInsights(surveyCycle.id, reach),
    {
      suspense: false
    }
  );
  const reportName = useMemo(() => `${surveyCycle.internalName}-insights`, [surveyCycle.internalName]);
  if (!surveyCycle || !surveyInsights) return /* @__PURE__ */ jsx(Fragment, {});
  const generateExcel = () => {
    const { participation, surveyScore, factorScore } = surveyInsights;
    const data = [];
    const addBoldHeader = (text) => {
      data.push([{ v: text, s: { font: { bold: true } } }]);
    };
    addBoldHeader("Participation rate");
    data.push(["Percentage of invited participants that have responded."]);
    data.push([
      "Participation",
      participation.average.toFixed(2),
      `(${participation.totalParticipants} out of ${participation.totalEntries})`
    ]);
    data.push([]);
    addBoldHeader("Survey score");
    data.push(["Normalised score across the survey. This score is based only on survey questions"]);
    data.push(["Survey score", (surveyScore.score / 100).toFixed(2)]);
    data.push([]);
    addBoldHeader("Factor score");
    data.push(["Normalised score by each factor used in the survey."]);
    data.push(
      ...factorScore.categories.map((category, index) => [category, (factorScore.series[index] / 100).toFixed(2)])
    );
    data.push([]);
    const impactData = impactExportReport(surveyResult, company_name);
    data.push(...impactData);
    captureExcel(data, reportName);
  };
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { surveyCycle }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      loading: Boolean(loadingInsights) || cycleLoading,
      buttons: [
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                handler: generateExcel,
                label: "Excel report"
              },
              {
                handler: () => capturePdf(reportName),
                label: "PDF report"
              }
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Export",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ],
      children: /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          id: reportName,
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: /* @__PURE__ */ jsx(SubsectionTitle, { title: "Insights", subtitle: `Key findings from ${surveyCycle.displayName}` }),
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(CycleInsights, { surveyInsights })
                }
              ]
            },
            {
              contentWidth: "100%",
              headerWidth: "100%",
              hidden: !surveyResult,
              title: /* @__PURE__ */ jsx(
                SubsectionTitle,
                {
                  title: "Biggest impact",
                  subtitle: "Questions that had the most meaningful impact",
                  tooltipText: "Impact is suggested based on a correlation analysis of survey answers. This analysis determines\n                        if there is a relationship between variables, which can help you spot the most impactful topics\n                        for your team."
                }
              ),
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    SurveyQuestionsBiggestImpact,
                    {
                      surveyResult,
                      growthFactors,
                      filtersAndTypesOptions: filtersAndTypesOptions || [],
                      setFilterString,
                      filterString,
                      surveyCycle
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    }
  );
};
const CycleInsights = ({ surveyInsights }) => {
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      sx: { borderBottom: "none" },
      cells: [
        {
          content: /* @__PURE__ */ jsx(
            ChartDisplay,
            {
              title: "Participation rate",
              subtitle: "Percentage of invited participants that have responded.",
              chart: /* @__PURE__ */ jsx(
                ChartProgress,
                {
                  progress: surveyInsights.participation.average,
                  title: `${surveyInsights.participation.average}%`,
                  color: "blue",
                  size: 200,
                  thickness: 2
                }
              ),
              hasDownload: true,
              caption: `Responded: ${surveyInsights.participation.totalEntries}/${surveyInsights.participation.totalParticipants} participants`
            }
          ),
          gridXs: 3
        },
        {
          content: /* @__PURE__ */ jsx(
            ChartDisplay,
            {
              title: "Survey score",
              subtitle: "Normalised score across the survey.",
              hasTooltip: true,
              tooltipText: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: "This score is based only on scale questions and has been normalised to percentages rather than actual values. This is to avoid skewing results if different scales are mixed in the same survey (For example, Agreement and NPS)." }),
              chart: /* @__PURE__ */ jsx(
                ChartProgress,
                {
                  progress: surveyInsights.surveyScore.score,
                  title: `${surveyInsights.surveyScore.score}%`,
                  color: "blue",
                  size: 200,
                  thickness: 2,
                  noData: surveyInsights.surveyScore.answersLowerThanThreshold,
                  noDataTitle: "Not sufficient data to show results."
                }
              ),
              hasDownload: true
            }
          ),
          gridXs: 3
        },
        {
          content: /* @__PURE__ */ jsx(
            ChartDisplay,
            {
              title: "Factor score",
              subtitle: "Normalised score by each factor used in the survey.",
              chart: /* @__PURE__ */ jsx(
                ChartPolarArea,
                {
                  series: surveyInsights.factorScore.series,
                  categories: surveyInsights.factorScore.categories,
                  noData: surveyInsights.surveyScore.answersLowerThanThreshold,
                  noDataTitle: "Not sufficient data to show results."
                }
              ),
              hasDownload: true
            }
          ),
          gridXs: 6
        }
      ]
    }
  );
};
const ChartDisplay = ({
  title,
  subtitle,
  chart,
  caption,
  hasTooltip = false,
  tooltipText,
  hasDownload
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      id: `download-chart-${title}`,
      sx: {
        padding: spacing.p16,
        border: borders.background,
        background: themeColors.white,
        borderRadius: radius.br8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: spacing.g32
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { height: "80px", display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, className: "hide-for-capture", children: [
              hasTooltip && /* @__PURE__ */ jsx(StyledTooltip, { title: tooltipText, placement: "right", children: /* @__PURE__ */ jsx(Status, __spreadValues({}, iconSize)) }),
              hasDownload && /* @__PURE__ */ jsx(
                "div",
                {
                  onClick: () => capturePNG(`download-chart-${title}`),
                  "aria-label": "Download chart as PNG",
                  role: "button",
                  style: { cursor: "pointer" },
                  children: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize))
                }
              )
            ] })
          ] }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: subtitle })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center", height: "220px", width: "100%" }, children: chart }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-start", height: "20px", width: "100%" }, children: caption && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: caption }) })
      ]
    }
  );
};
const SubsectionTitle = ({
  title,
  tooltipText,
  subtitle
}) => /* @__PURE__ */ jsxs(Box, { children: [
  /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: title }),
    tooltipText && /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: tooltipText }),
        placement: "right",
        children: /* @__PURE__ */ jsx(Status, __spreadValues({}, iconSize))
      }
    )
  ] }),
  /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: subtitle })
] });
