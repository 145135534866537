"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { ScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import {
  FieldStructure,
  ProfileCard
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { MissingInformationLabel } from "@/v2/feature/user/features/user-profile/details/components/missing-information-label.component";
import {
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { CustomSectionForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { hazardMissingField, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const SCOPE = "user.about";
const iconSize = { width: 14, height: 14 };
const skeletonHeight = "20px";
export const CustomSectionCard = ({ userId, form, fields, onSubmit, missingFields, sx }) => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [isOpen, setIsOpen] = useState(false);
  if (fields.length === 0) return /* @__PURE__ */ jsx(Fragment, {});
  const isEmpty = fields.every((f) => !f.value);
  return pipe(
    O.some(fields),
    O.fold(
      () => /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          height: skeletonHeight,
          sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
        }
      ),
      () => /* @__PURE__ */ jsxs(ProfileCard, { customUpdates: fields, fieldStubs: [], sx: __spreadValues(__spreadValues({}, cardSx), sx), children: [
        /* @__PURE__ */ jsxs(Box, { component: "header", sx: cardHeaderSx, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "h3", sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: form.formName }),
            /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.about:read"], context: scopesContext, children: (missingFields == null ? void 0 : missingFields.find((rec) => rec.cardId === form.formId)) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) })
          ] }),
          /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(IconButton, { onClick: () => setIsOpen(true), title: "Edit", sx: tableIconButtonSx, children: isEmpty ? /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }) })
        ] }),
        /* @__PURE__ */ jsx(Box, { component: "section", children: isEmpty ? /* @__PURE__ */ jsx(MissingInformationLabel, {}) : /* @__PURE__ */ jsx(Box, { component: "dl", sx: definitionListSx, children: sortCustomFields(fields).map((f) => /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: f.field.fieldName, fieldValue: f.value }, f.field.fieldId)) }) }),
        /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
          CustomSectionForm,
          {
            userId,
            form,
            fields,
            onSubmit: (values) => {
              setIsOpen(false);
              onSubmit(values);
            },
            onClose: () => setIsOpen(false)
          }
        ) }) })
      ] })
    )
  );
};
export const CustomSections = ({ userId, formTab, onSubmit, missingFields, sx }) => {
  const { data: forms, mutate: refreshForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false
  });
  const { data: formValues, mutate: refreshValues } = useApiClient(CustomProfileFormEndpoints.getFormValues(userId), {
    suspense: false
  });
  const customSections = useMemo(
    () => forms == null ? void 0 : forms.filter((f) => f.formTab === formTab && !f.isDefault).sort((a, b) => a.formName.localeCompare(b.formName, void 0, { sensitivity: "base" })),
    [formTab, forms]
  );
  return /* @__PURE__ */ jsx(Fragment, { children: customSections == null ? void 0 : customSections.map((form) => {
    var _a;
    return /* @__PURE__ */ jsx(
      CustomSectionCard,
      {
        userId,
        form,
        fields: (_a = formValues == null ? void 0 : formValues.filter((f) => f.formId === form.formId)) != null ? _a : [],
        onSubmit: (values) => {
          refreshForms == null ? void 0 : refreshForms();
          refreshValues == null ? void 0 : refreshValues(values);
          onSubmit(values);
        },
        missingFields,
        sx
      },
      form.formId
    );
  }) });
};
