"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { RequestFormComponent } from "@/v2/feature/requests/features/request-forms/components/request-form.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const PreviewPage = ({ template, saving, onNext, approvalRules }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, alignItems: "center", width: "100%" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { width: "500px", gap: spacing.g30 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PreviewPage.preview") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PreviewPage.previewDesc") }),
    /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
      RequestFormComponent,
      {
        template,
        previewMode: true,
        sx: {
          p: "30px 50px",
          m: "-30px -50px",
          border: "1px solid #00000020",
          borderRadius: "12px"
        },
        approvalRules
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "large",
        colorVariant: "primary",
        type: "button",
        fullWidth: true,
        loading: saving,
        onClick: () => onNext(),
        children: polyglot.t("PreviewPage.finish")
      }
    ) })
  ] }) });
};
