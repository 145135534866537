"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  CustomBenefitAllowanceType,
  CustomBenefitCategory,
  CustomBenefitType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { InsuranceType } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import {
  translateCustomBenefitAllowanceType,
  translateCustomBenefitCategory,
  translateCustomBenefitType
} from "@v2/infrastructure/i18n/translate.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_BENEFITS_DETAILS_ROUTE, SETTINGS_CREATE_NEW_BENEFIT_ROUTE } from "@/lib/routes";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
const getBenefitCategoryOptions = (polyglot) => Object.values(CustomBenefitCategory).map((key) => ({
  label: translateCustomBenefitCategory(key, polyglot),
  value: key
}));
const getBenefitTypeOptionDescription = (type, polyglot) => {
  if (type === CustomBenefitType.Allowance) return polyglot.t("BenefitModule.allowanceDesc");
  if (type === CustomBenefitType.Loan) return polyglot.t("BenefitModule.loanDesc");
  if (type === CustomBenefitType.Recurring) return polyglot.t("BenefitModule.recurringDesc");
  return "";
};
export const getBenefitTypeOptions = (category, polyglot) => Object.values(CustomBenefitType).filter((key) => category !== CustomBenefitCategory.Pension || key === CustomBenefitType.Recurring).map((key) => ({
  label: translateCustomBenefitType(key, polyglot),
  value: key,
  description: getBenefitTypeOptionDescription(key, polyglot)
}));
export const getCustomBenefitAllowanceTypeOptions = (polyglot) => Object.values(CustomBenefitAllowanceType).map((key) => ({
  label: translateCustomBenefitAllowanceType(key, polyglot),
  value: key
}));
export const NewBenefitDrawer = ({ isOpen, setIsOpen, refresh }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(NewBenefitDrawerContent, { setIsOpen, refresh })
  }
) });
const NewBenefitDrawerContent = ({
  setIsOpen,
  refresh
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [isCreatingCustomBenefit, setIsCreatingCustomBenefit] = useState(false);
  const createCustomBenefit = useCallback(
    (name, category, type) => __async(void 0, null, function* () {
      try {
        setIsCreatingCustomBenefit(true);
        const data = {
          type,
          category,
          name,
          description: null,
          includedUserIds: [],
          benefitLink: null
        };
        const createdBenefitId = yield CustomBenefitAPI.createCustomBenefit(data);
        yield refresh();
        setIsOpen(false);
        history.push(
          generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
            productType: type,
            category,
            id: createdBenefitId
          })
        );
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      }
      setIsCreatingCustomBenefit(false);
    }),
    [history, polyglot, refresh, setIsOpen, showMessage]
  );
  const onSubmit = useCallback(
    (_0) => __async(void 0, [_0], function* ({ type, category, name }) {
      if (category !== CustomBenefitCategory.Pension && category !== InsuranceType.Health)
        yield createCustomBenefit(name, category, type);
      else {
        history.push(
          generatePath(SETTINGS_CREATE_NEW_BENEFIT_ROUTE, {
            category,
            name
          })
        );
      }
    }),
    [createCustomBenefit, history]
  );
  const formik = useFormik({
    initialValues: {
      category: "",
      type: "",
      name: ""
    },
    validationSchema: yup.object({
      category: yup.string().required(polyglot.t("validation.requiredField")),
      type: yup.string().required(polyglot.t("validation.requiredField")),
      name: yup.string().required(polyglot.t("validation.requiredField"))
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("BenefitModule.newBenefit") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("General.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " "
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "category",
        label: polyglot.t("General.category"),
        options: getBenefitCategoryOptions(polyglot),
        value: formik.values.category,
        onChange: (e) => {
          formik.handleChange(e);
          const value = e.target.value;
          if (value === CustomBenefitCategory.Pension) {
            formik.setFieldValue("type", CustomBenefitType.Recurring);
          }
        },
        error: formik.touched.category && !!formik.errors.category,
        helperText: (_b = formik.touched.category && formik.errors.category) != null ? _b : " "
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: polyglot.t("General.type"),
        options: getBenefitTypeOptions(formik.values.category, polyglot),
        value: formik.values.type,
        onChange: formik.handleChange,
        error: formik.touched.type && !!formik.errors.type,
        helperText: (_c = formik.touched.type && formik.errors.type) != null ? _c : " "
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.create"),
        loading: isCreatingCustomBenefit,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.MONEY_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) });
};
