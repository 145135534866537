"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { capitalize } from "lodash";
import { GlobalContext } from "@/GlobalState";
import { useEventSource } from "@/hooks/event-source-hook";
import { canAccessScopes } from "@/lib/scopes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { CycleState, CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SCParticipantRollingView } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/rolling/sc-participant-rolling-view.component";
import { SCParticipantAnonymityModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-anonymity.modal.component";
import { SCParticipantScheduledView } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-scheduled-view.component";
import { TitleStatusComponent } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { eventSourceErrorHandler } from "@/v2/infrastructure/push-events/push-events.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const SurveyCycleCreationParticipantsPage = ({ cycleId, reach }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { data: surveyCycle, mutate: refreshCycle } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, reach),
    {
      suspense: false
    }
  );
  const {
    data: surveyParticipants,
    mutate: refreshSurveyParticipants,
    isLoading: surveyParticipantsLoading
  } = useApiClient(
    (surveyCycle == null ? void 0 : surveyCycle.id) ? SurveyCycleEndpoints.getSurveyParticipantsByCycleId(cycleId, reach) : { url: void 0 },
    {
      suspense: false
    }
  );
  const [isAnonymitySettings, setIsAnonymitySettings] = useState(false);
  const [state] = useContext(GlobalContext);
  const companyId = state.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const { user } = state;
  const refresh = useCallback(() => __async(void 0, null, function* () {
    yield refreshSurveyParticipants == null ? void 0 : refreshSurveyParticipants();
    yield refreshCycle == null ? void 0 : refreshCycle();
  }), [refreshCycle, refreshSurveyParticipants]);
  const isAdmin = canAccessScopes(user, ["surveys:all"]);
  const isManager = canAccessScopes(user, ["surveys:manager"]);
  const channelName = `survey_cycle-${environmentPrefix}-company_id-${companyId}`;
  const surveyParticipantsUpdatedDataHandler = (data) => __async(void 0, null, function* () {
    if (data.message && data.companyId === companyId) {
      refresh();
      return;
    }
  });
  useEventSource((_a = SurveyCycleEndpoints.getEventSourceUrlForAddParticipantsInCycle(channelName)) == null ? void 0 : _a.url, {
    onMessage: surveyParticipantsUpdatedDataHandler,
    onError: eventSourceErrorHandler
  });
  const isEditable = useMemo(() => {
    if (!surveyCycle) return false;
    const isStateEditable = surveyCycle.state === CycleState.Draft || surveyCycle.state === CycleState.Scheduled;
    const isOngoingAndAnonymised = surveyCycle.state === CycleState.Ongoing && surveyCycle.visibilitySettings && !surveyCycle.visibilitySettings.anonymiseAnswers;
    const isUserAuthorized = isAdmin || isManager && surveyCycle.owner === user.userId;
    return Boolean(isStateEditable || isOngoingAndAnonymised) && isUserAuthorized;
  }, [surveyCycle, isAdmin, isManager, user]);
  const isAnonymityEditable = useMemo(
    () => Boolean(
      surveyCycle && (surveyCycle.state === CycleState.Draft || surveyCycle.state === CycleState.Scheduled) && Boolean(isAdmin || isManager && surveyCycle.owner === user.userId)
    ),
    [surveyCycle, isAdmin, isManager, user]
  );
  if (!surveyCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { surveyCycle }),
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      noHorizontalPadding: false,
      loading: surveyParticipantsLoading,
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                title: "Anonymity settings",
                onEdit: () => setIsAnonymitySettings(true),
                editButtonDisabled: !isAnonymityEditable,
                hideEdit: !isAnonymityEditable,
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: "Anonymity",
                    value: ((_b = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) == null ? void 0 : _b.anonymiseAnswers) ? "Enable" : "Disabled"
                  },
                  ...((_c = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) == null ? void 0 : _c.anonymiseAnswers) ? [
                    {
                      type: SectionItemType.Pair,
                      label: "Anonymity type",
                      value: (surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) && ((_d = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) == null ? void 0 : _d.anonymityType) && ((_e = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) == null ? void 0 : _e.anonymityType) !== null ? capitalize((_f = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) == null ? void 0 : _f.anonymityType) : "Full"
                    },
                    {
                      type: SectionItemType.Pair,
                      label: "Anonymity threshold",
                      value: (surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) && ((_g = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) == null ? void 0 : _g.anonymityThreshold) && ((_h = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) == null ? void 0 : _h.anonymityThreshold) !== null ? (_i = surveyCycle == null ? void 0 : surveyCycle.visibilitySettings.anonymityThreshold) == null ? void 0 : _i.toString() : "3"
                    }
                  ] : []
                ]
              }
            ]
          }
        ),
        surveyCycle && surveyCycle.type === CycleType.Scheduled && /* @__PURE__ */ jsx(
          SCParticipantScheduledView,
          {
            surveyParticipants,
            surveyParticipantsLoading,
            surveyCycle,
            refresh,
            reach,
            isEditable
          }
        ),
        surveyCycle && surveyCycle.type === CycleType.Rolling && /* @__PURE__ */ jsx(
          SCParticipantRollingView,
          {
            surveyParticipants,
            surveyParticipantsLoading,
            surveyCycle,
            refresh,
            reach,
            isEditable
          }
        ),
        /* @__PURE__ */ jsx(
          SCParticipantAnonymityModal,
          {
            surveyCycle,
            isOpen: isAnonymitySettings,
            setIsOpen: setIsAnonymitySettings,
            onClose: () => setIsAnonymitySettings(false),
            refresh
          }
        )
      ]
    }
  );
};
