"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { ReactComponent as EmptyRadio } from "@/images/side-bar-icons/empty-radio.svg";
import { ReactComponent as FullRadio } from "@/images/side-bar-icons/full-radio.svg";
import { StyledRadioStyle } from "@/v2/styles/radio.styles";
export const StyledRadio = ({ id, name, onChange, value, selectedValue, disabled = false }) => {
  return /* @__PURE__ */ jsxs("label", { id: "container", style: { display: "flex" }, children: [
    /* @__PURE__ */ jsx(StyledRadioStyle, { type: "radio", onChange, name, value, disabled, id }),
    value === selectedValue ? /* @__PURE__ */ jsx(FullRadio, {}) : /* @__PURE__ */ jsx(EmptyRadio, {})
  ] });
};
