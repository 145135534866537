"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { GridDisplayBarV2 } from "@v2/feature/growth/shared/components/grid-display-bar.component";
import { GrowthCircle } from "@v2/feature/growth/shared/components/growth-chip.component";
import {
  GrowthProgressLineChart
} from "@v2/feature/growth/shared/components/growth-progress-chart.component";
import { QuestionCell } from "@v2/feature/growth/shared/components/question-cell.component";
import { MeasureFilterToLabel } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-cycle-detail-results.page";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
const getGrowthChartLinePercentLabel = (item, measureByFilter) => {
  var _a, _b, _c;
  return `${measureByFilter === "avg" ? (_a = item.normPercent) != null ? _a : 0 : measureByFilter === "positive" ? (_b = item.positivePercent) != null ? _b : 0 : (_c = item.npsPercent) != null ? _c : 0}%`;
};
const getGrowthChartLinePercentages = (item, measureByFilter) => {
  var _a, _b, _c;
  const item1Value = measureByFilter === "avg" ? (_a = item.normPercent) != null ? _a : 0 : (_b = item.positivePercent) != null ? _b : 0;
  const percentages = [
    {
      value: item1Value,
      color: measureByFilter === "avg" ? "blue" : "green",
      showTooltip: measureByFilter === "nps",
      label: measureByFilter === "avg" ? MeasureFilterToLabel.avg : "Positive sentiment"
    }
  ];
  if (measureByFilter !== "avg")
    percentages.push({
      value: (_c = item.negativePercent) != null ? _c : 0,
      color: "red",
      showTooltip: true,
      label: "Negative sentiment"
    });
  return percentages;
};
export const SurveyResultsByFactor = ({
  resultByFactor,
  growthFactors,
  measureByFilter
}) => {
  const { polyglot } = usePolyglot();
  const gridsXs = [6, 4];
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      GridDisplayBarV2,
      {
        sx: { mt: "16px" },
        cells: [
          {
            content: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Factor" }),
            gridXs: gridsXs[0]
          },
          {
            content: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Score" }),
            gridXs: gridsXs[1]
          }
        ]
      },
      "header-by-factor"
    ),
    resultByFactor.map((item) => {
      var _a, _b;
      return /* @__PURE__ */ jsx(
        GridDisplayBarV2,
        {
          cells: [
            {
              gridXs: gridsXs[0],
              content: /* @__PURE__ */ jsx(
                QuestionCell,
                {
                  text: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
                    /* @__PURE__ */ jsx(GrowthCircle, { color: (_b = (_a = growthFactors[item.factor]) == null ? void 0 : _a.color) != null ? _b : themeColors.Grey }),
                    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: item.factor })
                  ] }),
                  chips: [
                    {
                      text: polyglot.t("SurveyResult.noOfQuestions", { smart_count: item.noOfQuestions })
                    }
                  ]
                }
              )
            },
            {
              gridXs: gridsXs[1],
              content: /* @__PURE__ */ jsx(
                GrowthProgressLineChart,
                {
                  label: getGrowthChartLinePercentLabel(item, measureByFilter),
                  labelVariant: "title4",
                  percentJustifyContent: "space-between",
                  percentages: getGrowthChartLinePercentages(item, measureByFilter)
                }
              )
            }
          ]
        },
        item.factor
      );
    })
  ] });
};
