"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { AttendanceReportSection } from "@v2/feature/reports/features/update-report/attendance/attendance-report-section.component";
import { DevicesReportSection } from "@v2/feature/reports/features/update-report/devices/devices-report-section.component";
import { PeopleReportSection } from "@v2/feature/reports/features/update-report/people/people-report-section.component";
import { TimeReportSection } from "@v2/feature/reports/features/update-report/time/time-report-section.component";
import { DataDomain } from "@v2/feature/reports/reports.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { useParams } from "react-router-dom";
import { ReportsEndpoints } from "@/v2/feature/reports/reports.api";
import { useJune } from "@/v2/infrastructure/june/june.hook";
export const UpdateReportPage = () => {
  const params = useParams();
  const reportId = Number(params.reportId);
  const { trackPage } = useJune();
  const { data: report, isLoading: loading, mutate: refreshReportM } = useApiClient(
    !Number.isNaN(reportId) ? ReportsEndpoints.getReportById(reportId) : null,
    { suspense: false }
  );
  const refreshReport = useCallback(() => __async(void 0, null, function* () {
    if (refreshReportM) yield refreshReportM();
  }), [refreshReportM]);
  useEffect(() => {
    trackPage("Edit report");
  }, [trackPage]);
  switch (report == null ? void 0 : report.type) {
    case DataDomain.People:
      return /* @__PURE__ */ jsx(PeopleReportSection, { report, loading: !!loading, refreshReport });
    case DataDomain.TimeAway:
      return /* @__PURE__ */ jsx(TimeReportSection, { report, loading: !!loading });
    case DataDomain.Attendance:
      return /* @__PURE__ */ jsx(AttendanceReportSection, { report, loading: !!loading });
    case DataDomain.Devices:
      return /* @__PURE__ */ jsx(DevicesReportSection, { report, loading: !!loading });
    default:
      return null;
  }
};
