"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { IconButton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ReactComponent as Settings } from "@/images/fields/Settings.svg";
import { SETTINGS_MONEY_SALARY_ROUTE } from "@/lib/routes";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
export const PayrollTopHeader = ({
  settingsTooltip,
  title,
  payrolls,
  showBack,
  backPath,
  showSettingsMenu = true
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsx(
    TopHeader,
    {
      title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: title != null ? title : polyglot.t("CompanyPayroll.salary") }),
      showBack,
      backPath,
      showAction: !!payrolls && showSettingsMenu,
      actions: /* @__PURE__ */ jsx(StyledTooltip, __spreadProps(__spreadValues({}, settingsTooltip), { children: /* @__PURE__ */ jsx(
        IconButton,
        {
          onClick: () => routerHistory.push(SETTINGS_MONEY_SALARY_ROUTE),
          sx: iconCTAButtonSx,
          title: polyglot.t("CompanyPayroll.settings"),
          children: /* @__PURE__ */ jsx(Settings, __spreadValues({}, iconSize))
        }
      ) }))
    }
  );
};
