"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
const APPS_URL_V1 = "/api";
const APPS_URL_V2 = "/apiv2/apps";
const v2Apps = [
  "docusign",
  "github",
  "google-workspace",
  "hubspot",
  "intercom",
  "jumpcloud",
  "jira",
  "microsoft365",
  "lastpass",
  "monday",
  "notion",
  "pipedrive",
  "quickbooks",
  "remote",
  "slack",
  "workable",
  "teamtailor",
  "greenhouse",
  "xero",
  "zoom",
  "deel",
  "azure-ad",
  "oysterhr",
  "lever"
];
const isV2App = (appStub) => {
  return v2Apps.includes(appStub);
};
const getApiPrefix = (appStub) => isV2App(appStub) ? APPS_URL_V2 : APPS_URL_V1;
const getApiUrl = (appStub, url, params) => {
  const searchParams = new URLSearchParams(params).toString();
  const path = `${getApiPrefix(appStub)}/${appStub}/${url}`;
  return searchParams ? `${path}?${searchParams}` : path;
};
const _AppIntegrationAPI = class _AppIntegrationAPI {
  static getHandleCodeUrl(appStub, code) {
    return getApiUrl(appStub, `handle-code${code}`);
  }
  static getInstalledAppsAsSuperadmin(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/apps/superadmin/installed-apps?${searchParams.toString()}`)).data;
    });
  }
  static getInstalledApps() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/install")).data;
    });
  }
  static getCompanyDomainForApp(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/apps/${appStub}/company-domain`)).data;
    });
  }
  static availableApps() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps")).data;
    });
  }
  static installApps(appStubs) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/apps/install", appStubs)).data;
    });
  }
  static expiryCheck() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/expiryCheck")).data;
    });
  }
  static getInitiateUrl(appStub, extraState) {
    return getApiUrl(appStub, "initiate", extraState);
  }
  static getSignUpInitiateUrl() {
    return "apiv2/apps/xero/signup";
  }
  static listAppUsers(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(getApiUrl(appStub, "users"))).data;
    });
  }
  static listAppTeamUsersWithPagination(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(getApiUrl(appStub, "team-users"))).data;
    });
  }
  static listAppExternalUsersWithPagination(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(getApiUrl(appStub, "external-users"))).data;
    });
  }
  static getGroupMemberships(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(getApiUrl(appStub, "groups"))).data;
    });
  }
  static getEmployments(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(getApiUrl(appStub, "employments"))).data;
    });
  }
  static suspendAppUser(appId, appUserId, userId, actionDate, cronHour) {
    return __async(this, null, function* () {
      yield axios.post(getApiUrl(appId, `users/${appUserId}/suspend`), { userId, actionDate, cronHour });
    });
  }
  static resetPasswordForAppUser(appId, appUserId, userId, appUserEmail, appUserName) {
    return __async(this, null, function* () {
      return (yield axios.post(getApiUrl(appId, `users/${userId}/${appUserId}/resetPassword`), { appUserEmail, appUserName })).data;
    });
  }
  static dataTransferThenDeleteForAppUser(appId, localUserId, appUserId, newAppUserId, actionDate) {
    return __async(this, null, function* () {
      yield axios.post(getApiUrl(appId, `users/${localUserId}/${appUserId}/${newAppUserId}/dataTransferThenDelete`), {
        actionDate
      });
    });
  }
  static activateAppUser(appId, appUserId, userId, actionDate) {
    return __async(this, null, function* () {
      yield axios.post(getApiUrl(appId, `users/${userId}/${appUserId}/activate`), { actionDate });
    });
  }
  static deleteAppUser(appId, appUserId, userId, actionDate) {
    return __async(this, null, function* () {
      yield axios.delete(getApiUrl(appId, `users/${userId}/${appUserId}`), {
        data: {
          actionDate
        }
      });
    });
  }
  static deleteAppUserFromGroup(appId, appUserId, userId, groupId) {
    return __async(this, null, function* () {
      yield axios.delete(getApiUrl(appId, `users/${userId}/${appUserId}/group/${groupId}`));
    });
  }
  static addAppUserToGroup(appId, appUserId, userId, groupId) {
    return __async(this, null, function* () {
      yield axios.post(getApiUrl(appId, `users/${userId}/${appUserId}/group/${groupId}`));
    });
  }
  static revokeToken(appId) {
    return __async(this, null, function* () {
      yield axios.post(getApiUrl(appId, `revoke`));
    });
  }
  static invalidateApp(appId) {
    return __async(this, null, function* () {
      yield axios.post(getApiUrl(appId, `invalidateApp`));
    });
  }
  static assignUser(appId, userId, emailAddress) {
    return __async(this, null, function* () {
      yield axios.patch(getApiUrl(appId, `users/${userId}/assignuser`), { emailAddress });
    });
  }
  static unassignUser(appId, userId, emailAddress) {
    return __async(this, null, function* () {
      yield axios.patch(getApiUrl(appId, `users/${userId}/unassign-user`), { emailAddress });
    });
  }
  static importUser(appId, appUserId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(getApiUrl(appId, `users/${appUserId}/import`))) == null ? void 0 : _a.data;
    });
  }
  static getJobDetailForCandidate(appStub, externalId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/apps/${appStub}/candidate-job-detail/${externalId}`)).data;
    });
  }
  static createAppUser(appId, userId, params, actionDate) {
    return __async(this, null, function* () {
      return (yield axios.post(getApiUrl(appId, "users"), {
        userId,
        actionDate,
        params
      })).data;
    });
  }
  static createEmployment(appId, userId, country) {
    return __async(this, null, function* () {
      return (yield axios.post(getApiUrl(appId, "employments"), {
        userId,
        country
      })).data;
    });
  }
  static getLaunchpadApps() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/launchpad")).data;
    });
  }
  static getUserApps(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/apps/users/${userId}`)).data;
    });
  }
  static cancelScheduledAction(appId, jobId, userId, action) {
    return __async(this, null, function* () {
      return (yield axios.post(getApiUrl(appId, `actions/delayed/cancel`), {
        jobId,
        userId,
        action
      })).data;
    });
  }
  static getAlerts() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/user-alerts")).data;
    });
  }
  static saveCCEmailList(appStub, ccEmailList) {
    return __async(this, null, function* () {
      return yield axios.post(getApiUrl(appStub, "cc-email-list"), { ccEmailList });
    });
  }
  static getCCEmailList(appStub) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(getApiUrl(appStub, "cc-email-list"))) == null ? void 0 : _a.data;
    });
  }
  static saveCalendarDomainConfig(selectedDomain) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch("/apiv2/google-calendar/domain-name-config", { selectedDomain })) == null ? void 0 : _a.data;
    });
  }
  static getCalendarDomainConfig() {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get("/apiv2/google-calendar/domain-name-config")) == null ? void 0 : _a.data;
    });
  }
  static generateHubSpotIdentificationToken(email, firstName, lastName) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(getApiUrl("hubspot", "visitor-id-token"), {
        email,
        firstName,
        lastName
      })) == null ? void 0 : _a.data;
    });
  }
  static createIdMatchingForNewCandidate(appStub, newUserId, externalId) {
    return __async(this, null, function* () {
      yield axios.post(getApiUrl(appStub, "candidate-import"), {
        newUserId,
        externalId
      });
    });
  }
  static getCandidateList(appStub) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(getApiUrl(appStub, "candidates"))) == null ? void 0 : _a.data;
    });
  }
  static getAppMappingDetails(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/apps/${appStub}/mapping-details`)).data;
    });
  }
  static getAppIntegrationDetails(appStub) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/apps/${appStub}`)).data;
    });
  }
  static disconnectXeroTenant(tenantId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/apps/xero/disconnect-tenant/${tenantId}`)).data;
    });
  }
  static accountingAppInitiateTenantConnection(appStub) {
    return `/apiv2/apps/${appStub}/accounting-app-initiate`;
  }
  static appConnectAllowed() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/apps/allowed`)).data;
    });
  }
  static createNewExternalMapping(appStub, domain, newMapping) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/apps/${appStub}/${domain}/mapping`, newMapping);
    });
  }
  static updateExistingExternalMapping(appStub, domain, mapping) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/apps/${appStub}/${domain}/mapping`, mapping);
    });
  }
  static deleteExistingExternalMapping(appStub, domain, localId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/apps/${appStub}/${domain}/mapping/${localId}`);
    });
  }
};
_AppIntegrationAPI.getUserListForImport = (appStub) => __async(_AppIntegrationAPI, null, function* () {
  return (yield axios.get(`/apiv2/apps/${appStub}/user-list-for-import`)).data;
});
export let AppIntegrationAPI = _AppIntegrationAPI;
export class AppIntegrationEndpoints {
  static getSidebarApps() {
    return {
      url: "/apiv2/apps/launchpad"
    };
  }
  static getAppUsers(appStub) {
    return {
      url: getApiUrl(appStub, "users")
    };
  }
  static getPersonalEmailForAppsByUserId(userId, appStub) {
    return {
      url: appStub ? `/apiv2/apps/user/${userId}/personal-email?appStub=${appStub}` : `/apiv2/apps/user/${userId}/personal-email`
    };
  }
  static getUserApps(userId) {
    return {
      url: `/apiv2/apps/users/${userId}`
    };
  }
  static getInstalledApps() {
    return {
      url: "/apiv2/apps/install"
    };
  }
  static getCalendarApps(hasAppsAll) {
    return {
      url: hasAppsAll ? "/apiv2/apps/install" : void 0
    };
  }
  static getAvailableApps() {
    return {
      url: "/apiv2/apps"
    };
  }
  static getAppsOverviewStats() {
    return {
      url: "/apiv2/apps/overview/stats"
    };
  }
  static getDelayedAppActionsByStub(appStub) {
    return {
      url: `/apiv2/apps/actions/delayed?appStub=${appStub}`
    };
  }
  static getDelayedAppActionsByUserId(userId) {
    return {
      url: `/apiv2/apps/actions/delayed?userId=${userId}`
    };
  }
  static getAllDelayedAppActions() {
    return {
      url: `/apiv2/apps/actions/delayed`
    };
  }
  static getLatestApps() {
    return { url: "/apiv2/apps/:companyId/latest-apps" };
  }
  static getCompanyDomainForApp(appStub) {
    return { url: `/apiv2/apps/${appStub}/company-domain` };
  }
  static getAppMappingDetails(appStub) {
    return { url: `/apiv2/apps/${appStub}/mapping-details` };
  }
  static getExternalMappingDetails(appStub) {
    return { url: `/apiv2/apps/${appStub}/external-mapping-details` };
  }
}
