"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { TaskCompanyRouter } from "@v2/feature/task/task-company.router";
import { TaskMeRouter } from "@v2/feature/task/task-me.router";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { TASKS_COMPANY_ROUTE, TASKS_ME_ROUTE, TASKS_TEAM_ROUTE } from "@/lib/routes";
import { TaskTeamRouter } from "@/v2/feature/task/task-team.router";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const TaskRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(user.userId), {
    suspense: false
  });
  const isManager = useMemo(() => {
    var _a, _b, _c;
    const nonTerminatedDirectReports = (_c = (_b = (_a = userSummaryWithReports == null ? void 0 : userSummaryWithReports.directReports) == null ? void 0 : _a.filter(
      (d) => d.userEvent && d.userEvent.status && ["Employed", "Hired"].includes(d.userEvent.status)
    )) == null ? void 0 : _b.length) != null ? _c : 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["task:all"], path: TASKS_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(TaskCompanyRouter, { isManager }) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["task:manager"], path: TASKS_TEAM_ROUTE, children: /* @__PURE__ */ jsx(TaskTeamRouter, { isManager }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["task"], path: TASKS_ME_ROUTE, children: /* @__PURE__ */ jsx(TaskMeRouter, {}) })
  ] });
};
