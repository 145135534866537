"use strict";
export const UserContractKinds = (polyglot) => {
  return [
    { value: "Full-time", label: polyglot.t("UserContractKinds.fullTime") },
    { value: "Part-time", label: polyglot.t("UserContractKinds.partTime") },
    { value: "Shifts", label: polyglot.t("UserContractKinds.shifts") }
  ];
};
export const UserContractTypes = (polyglot) => {
  return [
    {
      value: "Contractor",
      label: polyglot.t("UserContractTypes.contractor")
    },
    {
      value: "Employee",
      label: polyglot.t("UserContractTypes.employee")
    },
    {
      value: "Consultant",
      label: polyglot.t("UserContractTypes.consultant")
    },
    {
      value: "EOR",
      label: polyglot.t("UserContractTypes.eor")
    }
  ];
};
export const TimeUnitTypes = (polyglot) => {
  return [
    {
      value: "Days",
      label: "Days"
    },
    {
      value: "Weeks",
      label: "Weeks"
    },
    {
      value: "Months",
      label: "Months"
    }
  ];
};
