"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReferAndEarnModal } from "@/v2/feature/app-layout/features/sidebar/components/refer-and-earn-modal.component";
import { MainMenuSection } from "@/v2/feature/app-layout/features/v2/main-menu/main-menu.section";
import { MenuActionType, RoleTypes } from "@/v2/feature/app-layout/features/v2/menu/menu.interface";
import {
  decomposePath,
  findChildByPath,
  findSubItemByStub,
  findSubMenuByPath,
  getSecondMenuItems,
  menuReducer
} from "@/v2/feature/app-layout/features/v2/menu/menu.util";
import { SecondMenuSection } from "@/v2/feature/app-layout/features/v2/second-menu/second-menu.section";
import { getRolesByDomain, getSidebarConfig } from "@/v2/feature/app-layout/features/v2/sidebar-config.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { showSecondMenuBar } from "@/v2/util/app-layout.util";
const initialState = {
  showSecondMenu: false,
  activeParent: void 0,
  activeSubMenuParent: void 0,
  activeRole: void 0
};
export const MenuSection = () => {
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { polyglot, useInitLanguage } = usePolyglot();
  useInitLanguage();
  const [state, dispatch] = useReducer(menuReducer, initialState);
  const { showSecondMenu, activeParent, activeSubMenuParent, activeRole } = state;
  const sideBarConfig = getSidebarConfig(user, activeRole, polyglot, !!globalState.showHiddenFeatures);
  const roles = useMemo(() => activeParent ? getRolesByDomain(activeParent, user) : [RoleTypes.me], [
    activeParent,
    user
  ]);
  const { hasScopes } = useScopes();
  const scopesContext = { userId: globalState.user.userId };
  const isAdminUser = hasScopes(["company.settings:all"], scopesContext);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (routerLocation.pathname.includes("refer")) setIsOpen(true);
  }, [routerLocation.pathname]);
  function shouldShowSecondMenu(domain, activeParent2, routedSecondMenuItems, pathame) {
    return routedSecondMenuItems && routedSecondMenuItems.hasChildren && (domain === routedSecondMenuItems.stub || activeParent2 === routedSecondMenuItems.stub) && showSecondMenuBar(pathame);
  }
  const routeToLocationOrDefined = useCallback(
    (pathname, path) => {
      const updatedPath = pathname.includes(path) ? pathname : path;
      if (routerHistory.location.pathname !== updatedPath) {
        routerHistory.push(updatedPath);
      }
    },
    [routerHistory]
  );
  const handleFindPathChild = useCallback(
    (findPathChild) => {
      if (findPathChild.hasChildren && findPathChild.subMenu) {
        const firstChild = findPathChild.subMenu.find((a) => a.path && !a.isHidden);
        if (firstChild == null ? void 0 : firstChild.path) {
          routeToLocationOrDefined(routerLocation.pathname, firstChild.path);
        }
      } else if (findPathChild.path) {
        routeToLocationOrDefined(routerLocation.pathname, findPathChild.path);
      }
    },
    [routeToLocationOrDefined, routerLocation.pathname]
  );
  const handleNoFindPathChild = useCallback(
    (routedSecondMenuItems) => {
      const firstChild = findChildByPath(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems);
      if (firstChild && firstChild.path && !firstChild.hasChildren) {
        routeToLocationOrDefined(routerLocation.pathname, firstChild.path);
      } else {
        const firstSubMenu = findSubMenuByPath(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems);
        if (firstSubMenu == null ? void 0 : firstSubMenu.path) {
          routeToLocationOrDefined(routerLocation.pathname, firstSubMenu.path);
        }
      }
    },
    [routeToLocationOrDefined, routerLocation.pathname]
  );
  const handleDomainNotEqualActiveParent = useCallback(
    (routedSecondMenuItems) => {
      if (routedSecondMenuItems && routedSecondMenuItems.path && !routedSecondMenuItems.hasChildren) {
        routeToLocationOrDefined(routerLocation.pathname, routedSecondMenuItems.path);
      } else {
        const decomposed = decomposePath(routerLocation.pathname);
        const { subDomain } = decomposed;
        const findPathChild = findSubItemByStub(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems, subDomain);
        if (findPathChild && !findPathChild.isHidden) {
          handleFindPathChild(findPathChild);
        } else {
          handleNoFindPathChild(routedSecondMenuItems);
        }
      }
    },
    [routerLocation.pathname, routeToLocationOrDefined, handleFindPathChild, handleNoFindPathChild]
  );
  const navigateToFirstChildOrParent = useCallback(
    (parent, subMenu, routedSecondMenuItems) => {
      if (parent && parent.hasChildren && parent.subMenu && !parent.isHidden) {
        const child = findSubItemByStub(parent.subMenu, subMenu) || findChildByPath(parent.subMenu);
        if (child && child.path && !child.isHidden) {
          routeToLocationOrDefined(routerLocation.pathname, child.path);
        } else {
          const firstChild = parent.subMenu.find((a) => a.path && !a.isHidden);
          if (firstChild && firstChild.path) {
            routeToLocationOrDefined(routerLocation.pathname, firstChild.path);
          }
        }
      } else if (parent && parent.path && !parent.isHidden) {
        routeToLocationOrDefined(routerLocation.pathname, parent.path);
      } else {
        const firstParent = findChildByPath(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems);
        if (firstParent && firstParent.path) {
          routeToLocationOrDefined(routerLocation.pathname, firstParent.path);
        } else {
          const firstSubMenu = findSubMenuByPath(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems);
          if (firstSubMenu && firstSubMenu.path) routeToLocationOrDefined(routerLocation.pathname, firstSubMenu.path);
        }
      }
    },
    [routerLocation.pathname, routeToLocationOrDefined]
  );
  const handleNavigation = useCallback(
    (domain, role, subDomain, subMenu, activeParent2) => {
      const routedSecondMenuItems = getSecondMenuItems(role, domain, user, polyglot);
      const parent = findSubItemByStub(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems, subDomain);
      if (activeParent2) {
        if (domain !== activeParent2) {
          handleDomainNotEqualActiveParent(routedSecondMenuItems);
        } else if (role !== activeRole) {
          navigateToFirstChildOrParent(parent, subMenu, routedSecondMenuItems);
        }
      } else {
        if (role !== activeRole) {
          navigateToFirstChildOrParent(parent, subMenu, routedSecondMenuItems);
        }
      }
    },
    [activeRole, handleDomainNotEqualActiveParent, navigateToFirstChildOrParent, user, polyglot]
  );
  const findMenuByPath = (config, subDomain, subMenu) => {
    if (!(config == null ? void 0 : config.subItems)) return null;
    for (let item of config.subItems) {
      if (item.stub !== subDomain) continue;
      if (!item.hasChildren) return { parent: item.stub, submenu: null };
      if (item.hasChildren && item.subMenu) {
        for (let subItem of item.subMenu) {
          if (subItem.stub === subMenu) {
            return { parent: item.stub, submenu: subItem.stub };
          }
        }
      }
    }
    return null;
  };
  const resetMenuState = useCallback(
    (domain, role, subDomain, subMenu, activeParent2) => {
      dispatch({
        type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
        payload: false
      });
      const rolesForDomain = getRolesByDomain(domain, user);
      const defaultRole = rolesForDomain.includes(role) ? role : rolesForDomain[0];
      dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: defaultRole });
      if (domain) {
        handleNavigation(domain, role, subDomain, subMenu, activeParent2);
      }
    },
    [handleNavigation, user]
  );
  useEffect(() => {
    const decomposed = decomposePath(routerLocation.pathname);
    const { domain, role, subDomain, subMenu } = decomposed;
    dispatch({
      type: MenuActionType.SET_ACTIVE_PARENT,
      payload: domain
    });
    const rolesForDomain = getRolesByDomain(domain, user);
    const defaultRole = role && rolesForDomain.includes(role) ? role : rolesForDomain[0];
    const routedSecondMenuItems = getSecondMenuItems(defaultRole, domain, user, polyglot);
    const shouldShow = shouldShowSecondMenu(domain, activeParent, routedSecondMenuItems, routerLocation.pathname);
    if (shouldShow) {
      dispatch({
        type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
        payload: true
      });
      dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: defaultRole });
      handleNavigation(domain, defaultRole, subDomain, subMenu, activeParent);
      const activeMenu = findMenuByPath(routedSecondMenuItems, subDomain, subMenu);
      dispatch({ type: MenuActionType.SET_ACTIVE_SUBMENU_PARENT, payload: activeMenu ? activeMenu.parent : void 0 });
    } else if (showSecondMenuBar(routerLocation.pathname)) {
      resetMenuState(domain, defaultRole, subDomain, subMenu, activeParent);
    } else {
      dispatch({
        type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
        payload: false
      });
    }
  }, [
    polyglot,
    routerLocation.pathname,
    activeRole,
    routerHistory,
    activeParent,
    handleNavigation,
    user,
    resetMenuState
  ]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      MainMenuSection,
      {
        setShowSecondMenu: (val) => dispatch({
          type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
          payload: val
        }),
        setActiveParent: (val) => dispatch({
          type: MenuActionType.SET_ACTIVE_PARENT,
          payload: val
        }),
        activeParent,
        sideBarConfig,
        setActiveRole: (role) => dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: role }),
        user
      }
    ),
    showSecondMenu && /* @__PURE__ */ jsx(
      SecondMenuSection,
      {
        activeParent,
        setActiveRole: (role) => dispatch({ type: MenuActionType.SET_ACTIVE_ROLE, payload: role }),
        activeRole,
        setActiveSubMenuParent: (sunMenuParent) => dispatch({ type: MenuActionType.SET_ACTIVE_SUBMENU_PARENT, payload: sunMenuParent }),
        activeSubMenuParent,
        roles,
        setShowSecondMenu: (val) => dispatch({
          type: MenuActionType.SET_SECOND_MENU_VISIBILITY,
          payload: val
        })
      }
    ),
    /* @__PURE__ */ jsx(ReferAndEarnModal, { isAdminUser, isOpen, setIsOpen, user })
  ] });
};
