"use strict";
import { jsx } from "react/jsx-runtime";
import { SettingsMembersList } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-members-list.component";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
export const AttendanceSettingsMembersSection = ({ schedule }) => {
  const { polyglot } = usePolyglot();
  const { data: scheduleMembers, isLoading } = useApiClient(AttendanceEndpoints.getScheduleMembers(schedule.id), {
    suspense: false
  });
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsx(SettingsSectionContent, { loading: isLoading, title: polyglot.t("General.members"), children: scheduleMembers && scheduleMembers.length > 0 ? /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          items: [
            {
              type: SectionItemType.TextLine,
              value: polyglot.t("AttendanceScheduleMembersSection.desc")
            },
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsx(SettingsMembersList, { members: scheduleMembers != null ? scheduleMembers : [] })
            }
          ]
        }
      ]
    }
  ) : /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          items: [
            {
              type: SectionItemType.Pair,
              label: polyglot.t("AttendanceScheduleMembersSection.employees"),
              value: polyglot.t("AttendanceScheduleMembersSection.noMembers")
            },
            {
              type: SectionItemType.Pair,
              label: "",
              value: polyglot.t("AttendanceScheduleMembersSection.desc")
            }
          ]
        }
      ]
    }
  ) }) });
};
