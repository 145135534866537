"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { ReportsV2TeamRouter } from "@v2/feature/reports/reports-v2-team.router";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { REPORT_TEAM_REPORTS_V2_ROUTE } from "@/lib/routes";
export const ReportTeamRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reports:manager"],
        context: scopesContext,
        path: REPORT_TEAM_REPORTS_V2_ROUTE,
        component: ReportsV2TeamRouter
      }
    ),
    /* @__PURE__ */ jsx(Redirect, { to: REPORT_TEAM_REPORTS_V2_ROUTE })
  ] });
};
