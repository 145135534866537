"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { RightToWorkAPI } from "@/v2/feature/user/features/user-forms/user-right-work/user-right-work.api";
import {
  buttonBoxSx,
  fieldSx,
  titleSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const RightToWorkSchema = Yup.object().shape({
  rightToWorkCheckDate: Yup.string().test(dateFieldTest).required("Right to work check date is required"),
  rightToWorkDocuments: Yup.string().required("Document is required"),
  rightToWorkIsLimited: Yup.boolean().optional().nullable(),
  rightToWorkExpiryDate: Yup.string().test(dateFieldTest).optional().nullable()
});
export const RightToWorkForm = ({
  userId,
  initialValues,
  documents,
  fetchDocuments,
  onSubmit,
  onClose,
  documentTypes,
  fetchData,
  usedForDataImport = false,
  importHandler = () => {
  }
}) => {
  var _a, _b;
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema: RightToWorkSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          if (!values.rightToWorkDocuments) {
            showMessage("Error: Not allowed to remove Right to Work check", "error");
          }
          if (values.id && values.rightToWorkDocuments && values.rightToWorkCheckDate) {
            yield RightToWorkAPI.updateByUserId(userId, values);
            showMessage("Identity check successfully updated.", "success");
          } else if (!values.id && values.rightToWorkDocuments && values.rightToWorkCheckDate) {
            yield RightToWorkAPI.createNewRTWCheck(userId, values);
            showMessage("Identity check successfully updated.", "success");
          }
          onSubmit(__spreadProps(__spreadValues({}, values), { rightToWorkChecked: true }));
          yield fetchData();
        } else {
          importHandler == null ? void 0 : importHandler(values);
        }
      } catch (error) {
        showMessage("Identity check could not be saved. Please try again.", "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const deleteExistingManualRTWCheck = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (formik.values.id) {
        yield RightToWorkAPI.deleteManualRTWCheck(userId, formik.values.id);
        showMessage("Manual RTW check successfully deleted.", "success");
      }
      onSubmit(formik.values);
      yield fetchData();
    } catch (e) {
      showMessage(`Failed to delete manual RTW check: ${nestErrorMessage(e)}`, "error");
      setLoading(false);
    }
  }), [formik.values, onSubmit, fetchData, userId, showMessage]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Stack, { flexDirection: "column", sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h1", sx: titleSx, children: "Identity check" }),
        formik.values.id && /* @__PURE__ */ jsx(
          IconButton,
          {
            disabled: loading,
            fullWidth: true,
            sizeVariant: "small",
            colorVariant: "secondary",
            onClick: (event) => {
              setAnchorEl(event.currentTarget);
              setIsRemovalModalOpen(true);
            },
            name: polyglot.t("General.delete"),
            children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mb: spacing.mb20 }), children: "If you already have conducted an ID check for this employee, you can save details below." })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_a = formik.values.rightToWorkCheckDate) != null ? _a : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("rightToWorkCheckDate", value);
            }
          },
          name: "rightToWorkCheckDate",
          label: "Date of check",
          error: !!formik.errors.rightToWorkCheckDate && formik.touched.rightToWorkCheckDate,
          helperText: formik.errors.rightToWorkCheckDate && formik.touched.rightToWorkCheckDate
        }
      ) }) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "rightToWorkDocuments",
          label: "Right to work document",
          options: documents,
          value: documents.find((option) => option.value === formik.values.rightToWorkDocuments),
          compareValue: (_b = formik.values.rightToWorkDocuments) != null ? _b : "",
          onChange: (_, x) => {
            setTimeout(() => {
              var _a2;
              return formik.setFieldValue("rightToWorkDocuments", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : "", true);
            }, 300);
          },
          error: formik.touched.rightToWorkDocuments && Boolean(formik.errors.rightToWorkDocuments),
          helperText: formik.touched.rightToWorkDocuments && formik.errors.rightToWorkDocuments || documents.length === 0 && "Please upload a Right to work document in order to select it here"
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => setIsRemovalModalOpen(false),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (formik.values.id) yield deleteExistingManualRTWCheck();
        }),
        message: `Are you sure you want to delete this check?`,
        callToAction: "Yes"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Save",
          loading,
          fullWidth: true,
          disabled: !formik.isValid || !formik.dirty,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) }) });
};
