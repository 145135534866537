"use strict";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { useCallback, useContext } from "react";
const useMessage = () => {
  const [, dispatch] = useContext(GlobalContext);
  const showMessage = useCallback(
    (message, type, position = "center", action) => {
      dispatch({
        type: GlobalStateActions.SHOW_MESSAGE,
        payload: { message, type, position }
      });
      if (action) action();
    },
    [dispatch]
  );
  return [showMessage];
};
export default useMessage;
