"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ContractorOverviewCard } from "@v2/feature/payments/features/accounting-transaction/components/contractor-overview-card.component";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { ExpenseEndpoints } from "@/api-client/expense.api";
import {
  EXPENSES_TEAM_NEW_EXPENSE_ROUTE,
  EXPENSE_COMPANY_NEW_EXPENSE_ROUTE,
  EXPENSE_ME_NEW_EXPENSE_ROUTE,
  USER_EXPENSES_NEW_EXPENSE_ROUTE
} from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ExpenseTable } from "@/v2/feature/payments/pages/components/expense-table.component";
import { PaymentsTransactionsTableSkeleton } from "@/v2/feature/payments/pages/payments-list.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function ExpensePage({ reach, userId }) {
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const [selectionModel, setSelectionModel] = useState([]);
  const {
    data: expenses,
    mutate: refreshExpenses,
    isValidating: validatingExpenses,
    isLoading: loadingExpenses
  } = useApiClient(
    userId ? ExpenseEndpoints.getSpecificUserExpenses(userId) : reach === "me" ? ExpenseEndpoints.getUserExpenses() : reach === "company" ? ExpenseEndpoints.getCompanyExpenses() : reach === "team" ? ExpenseEndpoints.getTeamExpenses() : { url: "" },
    { suspense: false }
  );
  const showOverview = false;
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("getSidebarConfig.expenses") }),
        showBack: routerLocation.pathname.includes("/settings"),
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: () => userId ? routerHistory.push(generatePath(USER_EXPENSES_NEW_EXPENSE_ROUTE, { userId })) : reach === "me" ? routerHistory.push(EXPENSE_ME_NEW_EXPENSE_ROUTE) : reach === "team" ? routerHistory.push(EXPENSES_TEAM_NEW_EXPENSE_ROUTE) : reach === "company" ? routerHistory.push(EXPENSE_COMPANY_NEW_EXPENSE_ROUTE) : routerHistory.push(EXPENSE_COMPANY_NEW_EXPENSE_ROUTE),
            children: polyglot.t("PaymentListActions.newExpense")
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: spacing.pt20, children: [
      showOverview && /* @__PURE__ */ jsx(Box, { sx: { ml: spacing.ml40, mt: spacing.mt20, mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(ContractorOverviewCard, {}) }),
      /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(PaymentsTransactionsTableSkeleton, {}) }),
          children: /* @__PURE__ */ jsx(
            ExpenseTable,
            {
              expenses: expenses != null ? expenses : [],
              refreshData: () => {
                refreshExpenses();
              },
              reach,
              setSelectionModel,
              selectionModel,
              loadingExpenses: loadingExpenses || validatingExpenses
            }
          )
        }
      )
    ] })
  ] });
}
