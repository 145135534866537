"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import {
  isAllowanceBenefit,
  isLoanBenefit,
  isRecurringBenefit
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { AllowanceBenefitOverview } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/allowance-benefit-overview.component";
import { LoanBenefitOverview } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/loan-benefit-overview.component";
import { RecurringBenefitOverview } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/recurring-benefit-overview.component";
import { useParams } from "react-router-dom";
export const UserCustomBenefitDetailsPage = ({ userId, userBenefits, loading, refreshCustomBenefits }) => {
  var _a, _b, _c;
  const params = useParams();
  const benefitId = Number(params.id);
  const userBenefit = useMemo(() => userBenefits.find((b) => b.customBenefitId === benefitId), [
    userBenefits,
    benefitId
  ]);
  return userBenefit && isAllowanceBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.type) ? /* @__PURE__ */ jsx(
    AllowanceBenefitOverview,
    {
      userId,
      userCustomBenefit: userBenefit,
      refresh: refreshCustomBenefits,
      loading
    }
  ) : userBenefit && isRecurringBenefit((_b = userBenefit.customBenefit) == null ? void 0 : _b.type) ? /* @__PURE__ */ jsx(
    RecurringBenefitOverview,
    {
      userId,
      userCustomBenefit: userBenefit,
      loading,
      refresh: refreshCustomBenefits
    }
  ) : userBenefit && isLoanBenefit((_c = userBenefit.customBenefit) == null ? void 0 : _c.type) ? /* @__PURE__ */ jsx(
    LoanBenefitOverview,
    {
      userId,
      userCustomBenefit: userBenefit,
      refresh: refreshCustomBenefits,
      loading
    }
  ) : null;
};
