"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as Edit, ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as HiddenEye } from "@/images/new-theme-icon/HiddenEye.svg";
import { ReactComponent as Lock } from "@/images/new-theme-icon/Lock.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CustomFieldDrawer } from "@/v2/feature/custom-fields/components/custom-field-drawer.component";
import { CustomFormDrawer } from "@/v2/feature/custom-fields/components/custom-form-drawer.component";
import {
  settingHeaderSx,
  settingIconSx,
  settingListSx,
  settingsMaxWidth,
  settingSx,
  settingTermSx,
  settingValueSx
} from "@/v2/feature/custom-fields/components/styles.layout";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import {
  CustomProfileFieldType
} from "@/v2/feature/custom-fields/custom-profile-fields.dto";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
const getTypeText = (type, polyglot) => {
  switch (type) {
    case "Input":
      return polyglot.t("CompanyFieldsPage.getTypeTextInput");
    case "Select":
      return polyglot.t("CompanyFieldsPage.getTypeTextSelect");
    case "Date input":
      return polyglot.t("CompanyFieldsPage.getTypeTextDateInput");
    case "Date picker":
      return polyglot.t("CompanyFieldsPage.getTypeTextDatePicker");
    case "Number":
      return polyglot.t("CompanyFieldsPage.getTypeTextNumber");
    case "Multiselect":
      return polyglot.t("CompanyFieldsPage.getTypeTextMultiselect");
    default:
      return type;
  }
};
const FormDisplayComponent = ({
  field,
  showIcon,
  setShowIcon,
  onEditClick
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({}, settingSx), {
        backgroundColor: showIcon === field.fieldId ? themeColors.TableHover : themeColors.white,
        borderRadius: radius.br10
      }),
      onMouseEnter: () => setShowIcon(field.fieldId),
      onMouseLeave: () => setShowIcon(null),
      children: [
        /* @__PURE__ */ jsx(Typography, { sx: settingTermSx, children: translateCustomField(field.fieldName, polyglot) }),
        /* @__PURE__ */ jsxs(Typography, { sx: settingValueSx, children: [
          getTypeText(field.fieldType, polyglot),
          "\xA0",
          field.isRequired && polyglot.t("FormDisplayComponent.required"),
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: field.isHidden && /* @__PURE__ */ jsx(HiddenEye, __spreadValues({ fill: themeColors.Grey }, iconSize)) })
        ] }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              settingIconSx,
              visibility: showIcon === field.fieldId ? "show" : "hidden",
              padding: spacing.p5
            },
            children: field.isEditable ? /* @__PURE__ */ jsx(
              IconButton,
              {
                sx: tableIconButtonSx,
                onClick: () => {
                  onEditClick();
                },
                children: /* @__PURE__ */ jsx(Edit, {})
              }
            ) : /* @__PURE__ */ jsx(Lock, __spreadValues({ fill: themeColors.Grey }, iconSize))
          }
        )
      ]
    }
  );
};
export const CustomProfileFieldsPage = () => {
  const { polyglot } = usePolyglot();
  const params = useParams();
  const [editingField, setEditingField] = useState(null);
  const [editingForm, setEditingForm] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isNewSectionOpen, setIsNewSectionOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(null);
  const routerHistory = useHistory();
  const { data: allForms, mutate: refreshForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false
  });
  const { data: allFields, mutate: refreshFields } = useApiClient(CustomProfileFormEndpoints.listAllFields(), {
    suspense: false
  });
  const forms = useMemo(() => {
    return allForms == null ? void 0 : allForms.filter(({ formTab }) => formTab === params.tabId);
  }, [allForms, params.tabId]);
  const applyChangedForm = useCallback(
    (update) => {
      if (!allForms) return void 0;
      const updatedForms = [...allForms];
      const idx = updatedForms.findIndex((f) => f.formId === update.form.formId);
      ({
        created: () => updatedForms.push(update.form),
        deleted: () => idx >= 0 && updatedForms.splice(idx, 1),
        updated: () => idx >= 0 && (updatedForms[idx] = update.form)
      })[update.change]();
      return updatedForms;
    },
    [allForms]
  );
  const refresh = useCallback(
    (formChange) => __async(void 0, null, function* () {
      const updatedForms = formChange && applyChangedForm(formChange);
      yield Promise.all([refreshForms == null ? void 0 : refreshForms(updatedForms), refreshFields == null ? void 0 : refreshFields()]);
    }),
    [applyChangedForm, refreshFields, refreshForms]
  );
  useEscapeKey(() => routerHistory.goBack());
  const getFieldOptions = (form) => {
    return [
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.Input });
          setIsOpen(true);
        },
        label: polyglot.t("CompanyFieldsPage.inputField"),
        disabled: false
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.Select });
          setIsOpen(true);
        },
        label: polyglot.t("CompanyFieldsPage.selectField"),
        disabled: false
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.Number });
          setIsOpen(true);
        },
        label: polyglot.t("CompanyFieldsPage.numberField"),
        disabled: false
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.DatePicker });
          setIsOpen(true);
        },
        label: polyglot.t("CompanyFieldsPage.datePicker"),
        disabled: false
      },
      {
        handler: () => {
          setEditingField({ form, fieldType: CustomProfileFieldType.DateInput });
          setIsOpen(true);
        },
        label: polyglot.t("CompanyFieldsPage.dateInput"),
        disabled: false
      }
    ];
  };
  const [defaultForms, customForms] = useMemo(() => {
    const def = [];
    const custom = [];
    forms == null ? void 0 : forms.forEach((f) => f.isDefault ? def.push(f) : custom.push(f));
    def.sort((a, b) => {
      var _a, _b;
      return ((_a = a.displayOrder) != null ? _a : 0) - ((_b = b.displayOrder) != null ? _b : 0);
    });
    custom.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    return [def, custom];
  }, [forms]);
  const formNames = useMemo(() => {
    var _a;
    return (_a = forms == null ? void 0 : forms.map((f) => f.formName)) != null ? _a : [];
  }, [forms]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { textTransform: "capitalize" }), children: translateCustomFieldCategory(params.tabId, polyglot) }),
        showBack: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => {
              setEditingForm(null);
              setIsNewSectionOpen(true);
            },
            colorVariant: "secondary",
            sizeVariant: "small",
            children: polyglot.t("CompanyFieldsPage.newSection")
          }
        ),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: !forms, children: [
      [defaultForms, customForms].map(
        (forms2) => forms2 == null ? void 0 : forms2.map((form, index) => {
          var _a, _b;
          return /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ paddingBottom: spacing.p60 }, settingsMaxWidth), children: [
            /* @__PURE__ */ jsxs(Box, { sx: settingHeaderSx, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { textTransform: "capitalize", color: themeColors.DarkGrey }), children: translateCustomFieldCategory(form.formName, polyglot) }),
              /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
                !form.isDefault && /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    sx: tableIconButtonSx,
                    onClick: () => {
                      setEditingForm(form);
                      setIsNewSectionOpen(true);
                    },
                    children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize))
                  }
                ),
                form.supportsCustomFields && /* @__PURE__ */ jsx(
                  StyledMenuComponent,
                  {
                    options: getFieldOptions(form),
                    actionButtonDetails: {
                      type: "button",
                      colorVariant: "secondary",
                      sizeVariant: "small",
                      title: polyglot.t("CompanyFieldsPage.newField")
                    }
                  }
                )
              ] })
            ] }),
            /* @__PURE__ */ jsxs(Box, { component: "dl", sx: settingListSx, children: [
              (_a = allFields == null ? void 0 : allFields.dataFieldsDefault) == null ? void 0 : _a.map(
                (field) => field.formId === form.formId && /* @__PURE__ */ jsx(
                  FormDisplayComponent,
                  {
                    field,
                    showIcon,
                    setShowIcon,
                    onEditClick: () => {
                      setEditingField({
                        form,
                        field,
                        fieldType: field.fieldType
                      });
                      setIsOpen(true);
                    }
                  },
                  field.fieldId
                )
              ),
              (_b = allFields == null ? void 0 : allFields.dataFieldsCustom) == null ? void 0 : _b.map(
                (field) => field.formId === form.formId && /* @__PURE__ */ jsx(
                  FormDisplayComponent,
                  {
                    field,
                    showIcon,
                    setShowIcon,
                    onEditClick: () => {
                      setEditingField({
                        form,
                        field,
                        fieldType: field.fieldType
                      });
                      setIsOpen(true);
                    }
                  },
                  field.fieldId
                )
              )
            ] })
          ] }, index);
        })
      ),
      editingField && /* @__PURE__ */ jsx(
        DrawerModal,
        {
          isOpen,
          setIsOpen,
          onClose: () => {
            setIsOpen(false);
            setEditingField(null);
          },
          children: /* @__PURE__ */ jsx(
            CustomFieldDrawer,
            {
              form: editingField.form,
              fieldType: editingField.fieldType,
              selectedField: editingField.field,
              onClose: () => __async(void 0, null, function* () {
                yield refresh();
                setIsOpen(false);
                setEditingField(null);
              })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        DrawerModal,
        {
          isOpen: isNewSectionOpen,
          setIsOpen: setIsNewSectionOpen,
          onClose: () => {
            setIsNewSectionOpen(false);
          },
          children: /* @__PURE__ */ jsx(
            CustomFormDrawer,
            {
              formTab: params.tabId,
              form: editingForm,
              formNames,
              close: (form, change) => __async(void 0, null, function* () {
                yield refresh({ form, change });
                setIsNewSectionOpen(false);
              })
            }
          )
        }
      )
    ] })
  ] });
};
