"use strict";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
export const formatMoney = (parameters) => {
  var _a;
  return Number(parameters.amount).toLocaleString(
    parameters.locale || "en-GB",
    {
      currency: (_a = parameters.currency) != null ? _a : DEFAULT_CURRENCY,
      maximumFractionDigits: parameters.isCompact ? 0 : 2,
      minimumFractionDigits: parameters.isCompact ? 0 : 2,
      notation: parameters.isCompact ? "compact" : "standard",
      style: parameters.asDecimal ? "decimal" : "currency"
    }
  );
};
export const formatPercentage = (percentageAmount, fractionDigits = 2) => {
  return `${Number(percentageAmount).toFixed(fractionDigits)}%`;
};
