"use strict";
export var TwoCancellationReason = /* @__PURE__ */ ((TwoCancellationReason2) => {
  TwoCancellationReason2["MIGRATION"] = "MIGRATION";
  TwoCancellationReason2["TERMINATED"] = "TERMINATED";
  TwoCancellationReason2["WRONG_DETAILS"] = "WRONG_DETAILS";
  TwoCancellationReason2["CREDIT_LIMITATION"] = "CREDIT_LIMITATION";
  TwoCancellationReason2["TAX_CHANGES"] = "TAX_CHANGES";
  return TwoCancellationReason2;
})(TwoCancellationReason || {});
export var RefinancingCompanyStatus = /* @__PURE__ */ ((RefinancingCompanyStatus2) => {
  RefinancingCompanyStatus2["Approved"] = "APPROVED";
  RefinancingCompanyStatus2["Declined"] = "DECLINED";
  return RefinancingCompanyStatus2;
})(RefinancingCompanyStatus || {});
