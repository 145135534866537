"use strict";
import { DOCUMENTS_NEW_COMPANY_ROUTE, DOCUMENTS_NEW_ME_ROUTE, DOCUMENTS_NEW_TEAM_ROUTE } from "@/lib/routes";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
export const PEOPLE_ALL_DOCUMENTS_NEW_ROUTES = [DOCUMENTS_NEW_COMPANY_ROUTE, DOCUMENTS_NEW_TEAM_ROUTE];
export const PEOPLE_DOCUMENTS_NEW_OVERVIEW_ROUTES = {
  company: DOCUMENTS_NEW_COMPANY_ROUTE,
  team: DOCUMENTS_NEW_TEAM_ROUTE,
  me: DOCUMENTS_NEW_ME_ROUTE
};
export const PEOPLE_DOCUMENTS_NEW_OVERVIEW_SCOPES = {
  company: [...DocumentsScopes.VIEW_COMPANY_DOCUMENTS],
  team: [...DocumentsScopes.VIEW_TEAM_DOCUMENTS],
  me: [...DocumentsScopes.VIEW_MY_DOCUMENTS]
};
