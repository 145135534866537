"use strict";
import { jsx } from "react/jsx-runtime";
import { useRef } from "react";
import { Box } from "@mui/material";
import { PolarArea } from "react-chartjs-2";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import "chart.js/auto";
const defaultColors = [
  "#ADD836",
  "#E43700",
  "#4F7BEB",
  "#7D5CB8",
  "#FFCD6D",
  "#B90E0F",
  "#FD9F26",
  "#F8BCD0",
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0"
];
export const ChartPolarArea = ({
  series,
  categories,
  colors = defaultColors,
  legendPosition = "left",
  legendAlign = "start",
  noDataTitle = "Data not found",
  noData
}) => {
  const chartRef = useRef(null);
  const chartData = {
    labels: categories,
    datasets: [
      {
        data: series,
        backgroundColor: colors,
        borderWidth: 1
      }
    ]
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: true
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: { padding: 4, stepSize: 25, display: false },
        pointLabels: {
          display: false,
          font: {
            size: 14,
            family: "Inter, sans-serif",
            weight: 300
          }
        },
        border: {
          dashOffset: 0.5
        }
      }
    },
    plugins: {
      legend: {
        position: legendPosition,
        align: legendAlign,
        fullWidth: false,
        maxWidth: 180,
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          boxWidth: 5,
          boxHeight: 5,
          padding: 20,
          color: themeColors.Grey,
          font: {
            size: 14,
            family: "Inter, sans-serif",
            weight: 300
          }
        }
      }
    }
  };
  if (noData)
    return /* @__PURE__ */ jsx(Box, { sx: { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: noDataTitle }) });
  return /* @__PURE__ */ jsx(PolarArea, { ref: chartRef, data: chartData, options });
};
