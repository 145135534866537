"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Button } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { linkButtonSx, tabsFilterBtnSx } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export function SelectDeselectIdRows({
  setSelectionModel,
  selectionModel,
  rows,
  hideSelectAll = false
}) {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g15 }, children: selectionModel.length > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Button,
      {
        sx: __spreadValues({}, tabsFilterBtnSx),
        onClick: () => {
          setSelectionModel([]);
        },
        disableRipple: true,
        children: [
          `${selectionModel == null ? void 0 : selectionModel.length} selected`,
          "\xA0",
          /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey, fill: themeColors.DarkGrey }))
        ]
      },
      selectionModel.length
    ),
    !hideSelectAll && selectionModel.length < rows.length && /* @__PURE__ */ jsx(
      Button,
      {
        sx: __spreadProps(__spreadValues(__spreadProps(__spreadValues({}, linkButtonSx), {
          textDecoration: "none"
        }), themeFonts.caption), {
          color: themeColors.DarkGrey,
          "&:hover": {
            background: "none"
          }
        }),
        onClick: () => {
          setSelectionModel(rows.map((r) => r.id));
        },
        children: polyglot.t("SelectDeselectRows.selectAll")
      }
    )
  ] }) });
}
