"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CompanyDepartmentAPI } from "@/api-client/company-department.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
const DepartmentFormSchema = Yup.object().shape({
  name: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "Please avoid using space or any special character").required("Department name is required")
});
export const DepartmentFormModal = ({
  isOpen,
  setIsOpen,
  selectedDepartment,
  refreshDepartments,
  closePage
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose: closePage, children: /* @__PURE__ */ jsx(
    DepartmentFormModalContent,
    {
      selectedDepartment,
      refreshDepartments,
      closePage
    }
  ) });
};
const DepartmentFormModalContent = ({
  selectedDepartment,
  refreshDepartments,
  closePage
}) => {
  var _a, _b, _c;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const deleteDepartment = () => __async(void 0, null, function* () {
    try {
      if (departmentToDelete) yield CompanyDepartmentAPI.deleteCompanyDepartment(departmentToDelete);
      else throw new Error("No department to delete");
      showMessage("Successfully deleted department", "success");
      yield refreshDepartments();
    } catch (error) {
      showMessage(`Error encountered when trying to delete department: ${nestErrorMessage(error)}`, "error");
    }
  });
  const confirmDelete = (event, departmentId) => {
    if (event) setAnchorEl(event.currentTarget);
    setDepartmentToDelete(departmentId);
    setIsConfirmModalOpen(true);
  };
  const formik = useFormik({
    initialValues: selectedDepartment ? { name: (_a = selectedDepartment == null ? void 0 : selectedDepartment.name) != null ? _a : "", employees: (_b = selectedDepartment == null ? void 0 : selectedDepartment.users) != null ? _b : [] } : { name: "", employees: [] },
    validationSchema: DepartmentFormSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setIsSubmitting(true);
        if (selectedDepartment && selectedDepartment.id) {
          yield CompanyDepartmentAPI.updateCompanyDepartment(selectedDepartment.id, {
            name: values.name
          });
          showMessage(`Successfully updated department`, "success");
        } else {
          yield CompanyDepartmentAPI.addCompanyDepartment({ name: values.name });
          showMessage(`Successfully added new department`, "success");
        }
        refreshDepartments();
        closePage();
      } catch (error) {
        showMessage(
          `Failed to ${selectedDepartment ? "update" : "add"} department: ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: selectedDepartment ? "Edit department" : "Add department" }),
      selectedDepartment && /* @__PURE__ */ jsx(
        IconButton,
        {
          colorVariant: "secondary",
          sizeVariant: "small",
          onClick: (event) => confirmDelete(event, selectedDepartment.id),
          children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
        },
        "delete-ph"
      )
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("DepartmentManagementDrawerPage.departmentNameLabel"),
        value: formik.values.name,
        type: "text",
        onChange: (e) => {
          const updatedValue = e.currentTarget.value;
          formik.setFieldValue("name", updatedValue);
        },
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_c = formik.touched.name && formik.errors.name) != null ? _c : " ",
        clearText: () => formik.setFieldValue("name", "")
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        type: "submit",
        loading: isSubmitting,
        colorVariant: "primary",
        sizeVariant: "medium",
        disabled: isSubmitting || Object.keys(formik.errors).length > 0,
        name: polyglot.t("General.save")
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isConfirmModalOpen,
        onClose: () => {
          setIsConfirmModalOpen(false);
        },
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (departmentToDelete) {
            setIsConfirmModalOpen(false);
            closePage();
            yield deleteDepartment();
          } else showMessage("No department selected", "error");
        }),
        message: polyglot.t("DepartmentManagementDrawerPage.confirmDeleteMessage"),
        callToAction: "Yes"
      }
    )
  ] }) });
};
