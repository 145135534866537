"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  INVOICES_COMPANY_NEW_INVOICE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  INVOICES_TEAM_NEW_INVOICE_ROUTE,
  USER_INVOICES_NEW_INVOICE_ROUTE
} from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ContractorInvoiceTable } from "@/v2/feature/payments/pages/components/contractor-invoice-table.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function InvoicesPage({
  reach = "company",
  userId
}) {
  const { polyglot } = usePolyglot();
  const [loadingInvoices, setIsLoadingInvoices] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state] = useContext(GlobalContext);
  const fetchInvoices = useCallback(() => __async(this, null, function* () {
    try {
      setIsLoadingInvoices(true);
      const apiInvoices = userId ? yield ContractorInvoiceAPI.getSpecificUserInvoices(userId) : reach === "company" ? yield ContractorInvoiceAPI.getCompanyInvoices() : reach === "team" ? yield ContractorInvoiceAPI.getTeamInvoices() : yield ContractorInvoiceAPI.getSpecificUserInvoices(state.user.userId);
      setInvoices(apiInvoices);
    } catch (error) {
      showMessage(`Failed to fetch invoices. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoadingInvoices(false);
    }
  }), [reach, showMessage, state.user.userId, userId]);
  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices, reach]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("getSidebarConfig.invoices") }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: () => userId ? routerHistory.push(generatePath(USER_INVOICES_NEW_INVOICE_ROUTE, { userId })) : reach === "me" ? routerHistory.push(INVOICES_ME_NEW_INVOICE_ROUTE) : reach === "team" ? routerHistory.push(INVOICES_TEAM_NEW_INVOICE_ROUTE) : reach === "company" ? routerHistory.push(INVOICES_COMPANY_NEW_INVOICE_ROUTE) : routerHistory.push(INVOICES_COMPANY_NEW_INVOICE_ROUTE),
            children: polyglot.t("PaymentListActions.newInvoice")
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: __spreadValues({}, spacing.pt20), children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      ContractorInvoiceTable,
      {
        invoices,
        onActionPerformed: fetchInvoices,
        reach,
        setSelectionModel,
        selectionModel,
        loadingInvoices
      }
    ) }) })
  ] });
}
