"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOrderStatus } from "@v2/feature/device/device.interface";
import { DeviceOrderStatusToProgressScore, getModelImage } from "@v2/feature/device/device.util";
import { DevicePossessionInformationCard } from "@v2/feature/device/features/device-cards/components/device-possession-information-card.component";
import { DeviceTechSpecsCard } from "@v2/feature/device/features/device-cards/components/device-tech-specs-card.component";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE,
  DEVICES_COMPANY_OVERVIEW_ROUTE,
  DEVICES_ME_DIRECTORY_ROUTE,
  DEVICES_ME_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDERS_ROUTE
} from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { ThemeSlider } from "@/v2/styles/slider.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceOrderDetailsPage = ({
  loading,
  setLoading,
  refreshOrders
}) => {
  var _a;
  const params = useParams();
  const orderId = Number(params.orderId);
  const [isDeleting, setIsDeleting] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [deviceOrder, setDeviceOrder] = useState(void 0);
  const [progress, setProgress] = useState(0);
  const [showMessage] = useMessage();
  const history = useHistory();
  const location = useLocation();
  const refreshLocalOrder = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    try {
      setLoading(true);
      const deviceOrder2 = yield DeviceAPI.getDeviceOrderDetails(orderId);
      setDeviceOrder(deviceOrder2);
      setProgress((_a2 = DeviceOrderStatusToProgressScore[deviceOrder2.status]) != null ? _a2 : 0);
    } catch (error) {
      showMessage(`Could not retrieve device order details. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [setLoading, showMessage, orderId]);
  useEffect(() => {
    refreshLocalOrder();
  }, [refreshLocalOrder]);
  const cancelOrder = (orderId2) => __async(void 0, null, function* () {
    let orderCancelled = false;
    try {
      const updatedOrder = yield DeviceAPI.cancelDeviceOrder(orderId2);
      setDeviceOrder(updatedOrder);
      orderCancelled = true;
      showMessage("Order cancelled", "success");
      yield refreshOrders();
    } catch (error) {
      if (!orderCancelled) showMessage(`Could not cancel order. ${nestErrorMessage(error)}`, "error");
      else showMessage(`Could not refresh orders list. ${nestErrorMessage(error)}`, "error");
    }
  });
  const deleteOrder = (orderId2) => __async(void 0, null, function* () {
    try {
      setIsDeleting(true);
      yield DeviceAPI.deleteDeviceOrder(orderId2);
      showMessage("Order deleted", "success");
      yield refreshOrders();
      if (location.pathname.includes(DEVICES_ME_ORDERS_ROUTE)) history.push(DEVICES_ME_DIRECTORY_ROUTE);
      else history.push(DEVICES_COMPANY_OVERVIEW_ROUTE);
    } catch (error) {
      showMessage(`Could not delete order. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDeleting(false);
    }
  });
  const isPendingOrAccepted = deviceOrder && [DeviceOrderStatus.Placed, DeviceOrderStatus.Accepted].includes(deviceOrder.status);
  const isCancelledOrRejected = (deviceOrder == null ? void 0 : deviceOrder.status) && [DeviceOrderStatus.Cancelled, DeviceOrderStatus.Rejected].includes(deviceOrder.status);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: "Order details",
        actions: /* @__PURE__ */ jsxs(ScopesControl, { scopes: ["devices"], context: scopesContext, children: [
          isPendingOrAccepted && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              fullWidth: true,
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => __async(void 0, null, function* () {
                yield cancelOrder(deviceOrder.id);
              }),
              children: "Cancel order"
            }
          ),
          isCancelledOrRejected && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Delete Order",
              colorVariant: "danger",
              sizeVariant: "small",
              loading: isDeleting,
              onClick: () => __async(void 0, null, function* () {
                yield deleteOrder(deviceOrder.id);
              })
            }
          ),
          (deviceOrder == null ? void 0 : deviceOrder.status) === DeviceOrderStatus.Shipping && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              fullWidth: true,
              colorVariant: "primary",
              sizeVariant: "small",
              onClick: () => location.pathname.includes(DEVICES_ME_ORDERS_ROUTE) ? history.push(generatePath(DEVICES_ME_ORDER_ACTIVATION_ROUTE, { orderId: deviceOrder.id })) : history.push(generatePath(DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE, { orderId: deviceOrder.id })),
              children: "Confirm Delivery"
            }
          )
        ] }),
        showAction: deviceOrder && ((deviceOrder == null ? void 0 : deviceOrder.status) === DeviceOrderStatus.Shipping || [DeviceOrderStatus.Cancelled, DeviceOrderStatus.Rejected].includes(deviceOrder == null ? void 0 : deviceOrder.status) || isPendingOrAccepted),
        showBack: true
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: (deviceOrder == null ? void 0 : deviceOrder.deviceModel) ? /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: deviceOrder.status === DeviceOrderStatus.Cancelled ? "Order cancelled" : deviceOrder.status === DeviceOrderStatus.Rejected ? "Order rejected" : deviceOrder.status === DeviceOrderStatus.Requested ? "Order requested" : "Order placed" }),
          /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "title4",
              color: ![DeviceOrderStatus.Accepted, DeviceOrderStatus.Shipping, DeviceOrderStatus.Delivered].includes(
                deviceOrder.status
              ) ? "Grey" : void 0,
              children: "Order accepted"
            }
          ),
          /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              color: ![DeviceOrderStatus.Shipping, DeviceOrderStatus.Delivered].includes(deviceOrder.status) ? "Grey" : void 0,
              children: "Shipping"
            }
          ),
          /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              color: deviceOrder.status !== DeviceOrderStatus.Delivered ? "Grey" : void 0,
              children: "Delivered"
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          ThemeSlider,
          {
            defaultValue: progress,
            value: progress,
            "aria-label": "Small",
            marks: [{ value: 0 }, { value: 35 }, { value: 68 }, { value: 100 }]
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: __spreadValues({
            display: "flex",
            flexDirection: "row",
            maxWidth: "60%",
            justifyContent: "space-between",
            width: "100%"
          }, spacing.mt40),
          children: [
            /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "35%",
                  justifyContent: "flex-start",
                  width: "100%"
                },
                children: /* @__PURE__ */ jsx(Box, { sx: { width: "200px", height: "auto" }, children: (_a = getModelImage(deviceOrder.deviceModel.type, deviceOrder.deviceModel.modelName, {
                  width: "200px",
                  height: "auto"
                })) != null ? _a : /* @__PURE__ */ jsx(
                  SkeletonLoader,
                  {
                    variant: "rectangular",
                    width: "200px",
                    height: "100%",
                    sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                  }
                ) })
              }
            ),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "65%",
                  justifyContent: "flex-start",
                  width: "100%"
                },
                children: [
                  (deviceOrder == null ? void 0 : deviceOrder.possession) && /* @__PURE__ */ jsx(DevicePossessionInformationCard, { devicePossession: deviceOrder.possession }),
                  (deviceOrder == null ? void 0 : deviceOrder.deviceModel) && /* @__PURE__ */ jsx(DeviceTechSpecsCard, { deviceModel: deviceOrder.deviceModel, device: deviceOrder.device })
                ]
              }
            )
          ]
        }
      )
    ] }) : /* @__PURE__ */ jsx(Box, { children: "Order details could not be found." }) })
  ] });
};
