"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { UserProfileDevicesPage } from "./pages/user-profile-devices.page";
import useMessage from "@/hooks/notification.hook";
import { USER_DEVICES_PAGE } from "@/lib/routes";
export const UserProfileDeviceRouter = () => {
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
    }))();
  }, [showMessage]);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { exact: true, path: USER_DEVICES_PAGE, children: /* @__PURE__ */ jsx(UserProfileDevicesPage, {}) }) });
};
