"use strict";
export class ReviewResultEndpoints {
  static getReviewOverviewResultByReviewee(revieweeId, cycleId) {
    return {
      url: `/apiv2/review-result/overview/${revieweeId}/${cycleId}`
    };
  }
  static getRadarChartByReviewee(revieweeId, cycleId) {
    return {
      url: `/apiv2/review-result/radar/${revieweeId}/${cycleId}`
    };
  }
  static getReviewProgressByCycleId(cycleId, reach) {
    return {
      url: `/apiv2/review-result/progress/${cycleId}?reach=${reach}`
    };
  }
  static getReviewParticipationByCycleId(cycleId, reach) {
    return {
      url: `/apiv2/review-result/participation/${cycleId}?reach=${reach}`
    };
  }
  static getReviewResultNavigation(cycleId) {
    return {
      url: `/apiv2/review-result/navigation/${cycleId}`
    };
  }
}
