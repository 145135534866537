"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { AccountInformationForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-account-info-form.component";
import { EmergencyContactSchema } from "@/v2/feature/user/features/user-profile/details/components/user-profile-emergency-contact-form.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
const SCOPE = "user.lifecycle:all";
const iconSize = { width: 14, height: 14 };
const skeletonHeight = calculateSkeletonHeight(EmergencyContactSchema);
export const AccountInformationCard = ({ userId, onSubmit }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasLifecycleScope = hasScopes(["user.lifecycle:read", "user.lifecycle:manager"], scopesContext);
  const [dataO, setDataO] = useState(() => O.none);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      var _a;
      try {
        const [data, userLifecycle] = yield Promise.all([
          UserAPI.getUserAccountInfo(userId),
          hasLifecycleScope ? UserAPI.getUserLifecycleInfo(userId) : void 0
        ]);
        setDataO(
          O.some(__spreadProps(__spreadValues({}, data), {
            employeeId: (_a = userLifecycle == null ? void 0 : userLifecycle.employeeId) != null ? _a : void 0
          }))
        );
      } catch (error) {
        showMessage(polyglot.t("AccountInformationCard.errorMessages.load"), "error");
      }
    }))();
  }, [polyglot, hasLifecycleScope, showMessage, userId]);
  return pipe(
    dataO,
    O.fold(
      () => /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          height: skeletonHeight,
          sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
        }
      ),
      (data) => {
        var _a;
        return /* @__PURE__ */ jsxs(Box, { sx: cardSx, children: [
          data && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsxs(Box, { component: "header", sx: cardHeaderSx, children: [
              /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
                polyglot.t("AccountInformationCard.accountInfo"),
                " "
              ] }),
              /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(IconButton, { onClick: () => setIsOpen(true), title: "Edit", sx: tableIconButtonSx, children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }) })
            ] }),
            /* @__PURE__ */ jsx(Box, { component: "section", children: /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
              (data == null ? void 0 : data.employeeId) && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.employeeId") }),
                /* @__PURE__ */ jsx(Typography, { sx: definitionValueSx, children: (_a = data.employeeId) != null ? _a : "" })
              ] }),
              (data == null ? void 0 : data.accountStatus) && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.status") }),
                /* @__PURE__ */ jsx(Typography, { sx: definitionValueSx, children: data.accountStatus })
              ] }),
              typeof (data == null ? void 0 : data.mfaType) !== "undefined" && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.mfaStatus") }),
                /* @__PURE__ */ jsx(Typography, { sx: definitionValueSx, children: data.mfaType ? polyglot.t("AccountInformationCard.enabled") : polyglot.t("AccountInformationCard.disabled") })
              ] }),
              (data == null ? void 0 : data.activationDate) && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.activationDate") }),
                /* @__PURE__ */ jsx(DateLabelComponent, { date: data.activationDate, titleSx: definitionValueSx })
              ] }),
              (data == null ? void 0 : data.deactivationDate) && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.deactivationDate") }),
                /* @__PURE__ */ jsx(DateLabelComponent, { date: data.deactivationDate, titleSx: definitionValueSx })
              ] }),
              (data == null ? void 0 : data.createdAt) && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.createdAt") }),
                /* @__PURE__ */ jsx(DateLabelComponent, { date: data.createdAt, titleSx: definitionValueSx })
              ] }),
              (data == null ? void 0 : data.updatedAt) && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.updatedAt") }),
                /* @__PURE__ */ jsx(DateLabelComponent, { date: data.updatedAt, titleSx: definitionValueSx })
              ] }),
              (data == null ? void 0 : data.lastTimeOnline) && /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
                /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: polyglot.t("AccountInformationCard.lastTimeOnline") }),
                /* @__PURE__ */ jsx(
                  DateLabelComponent,
                  {
                    date: data.lastTimeOnline,
                    titleSx: definitionValueSx,
                    dateformat: "d MMM yyyy, h:mm a"
                  }
                )
              ] })
            ] }) })
          ] }),
          /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
            AccountInformationForm,
            {
              userId,
              initialValues: data,
              onSubmit: (values) => {
                setDataO(O.some(values));
                setIsOpen(false);
                onSubmit(values);
              },
              onClose: () => setIsOpen(false)
            }
          ) }) })
        ] });
      }
    )
  );
};
