"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAttachmentType } from "@v2/feature/absence/absence.dto";
import { COLOR_OPTIONS, getMonthOptions } from "@v2/feature/absence/absence.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyGeneralEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refreshPolicy,
  refreshPolicies
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        color: values.color,
        name: values.name,
        fullName: values.fullName,
        isPublic: values.isPublic,
        attachmentType: values.attachmentType ? values.attachmentType : null,
        cycleStartDay: Number(values.cycleStartDay),
        cycleStartMonth: Number(values.cycleStartMonth)
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyGeneral(absencePolicy.id, policyData);
        yield Promise.all([refreshPolicy(), refreshPolicies()]);
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [absencePolicy.id, refreshPolicy, refreshPolicies, setIsOpen, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      name: absencePolicy.name,
      color: absencePolicy.color,
      fullName: absencePolicy.fullName,
      isPublic: absencePolicy.isPublic,
      attachmentType: (_a = absencePolicy.attachmentType) != null ? _a : null,
      cycleStartDay: absencePolicy.cycleStartDay,
      cycleStartMonth: absencePolicy.cycleStartMonth
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("PolicyGeneralEditDrawer.fieldIsRequired")),
      color: yup.string().required(polyglot.t("PolicyGeneralEditDrawer.fieldIsRequired")),
      fullName: yup.string().required(polyglot.t("PolicyGeneralEditDrawer.fieldIsRequired")),
      isPublic: yup.boolean().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("PolicyGeneralEditDrawer.fieldIsRequired")),
      attachmentType: yup.string().nullable().oneOf([null, ...Object.values(AbsencePolicyAttachmentType)], polyglot.t("validation.selectValid")).strict(true),
      cycleStartDay: yup.number().integer(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.selectValid")).max(31, polyglot.t("validation.selectValid")).typeError(polyglot.t("validation.selectValid")).when("cycleStartMonth", (cycleStartMonth, schema) => {
        const daysInMonth = new Date((/* @__PURE__ */ new Date()).getFullYear(), cycleStartMonth, 0).getDate();
        return schema.max(daysInMonth, polyglot.t("AbsenceGeneralSettings.thisMonthOnlyHas", { daysInMonth }));
      }).required(polyglot.t("PolicyGeneralEditDrawer.fieldIsRequired")),
      cycleStartMonth: yup.number().integer(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.selectValid")).max(12, polyglot.t("validation.selectValid")).typeError(polyglot.t("validation.selectValid")).required(polyglot.t("PolicyGeneralEditDrawer.fieldIsRequired"))
    }),
    onSubmit
  });
  const monthsOptions = useMemo(() => {
    return getMonthOptions(polyglot);
  }, [polyglot]);
  const attachmentOptions = useMemo(() => {
    return [
      { value: "", label: polyglot.t("General.none") },
      { value: AbsencePolicyAttachmentType.Optional, label: polyglot.t("General.optional") },
      { value: AbsencePolicyAttachmentType.Required, label: polyglot.t("General.required") }
    ];
  }, [polyglot]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AbsenceUtil.editGeneral") }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "60px" }, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "color",
          label: polyglot.t("NewTimePolicyGeneralStep.color"),
          options: COLOR_OPTIONS,
          value: formik.values.color,
          onChange: formik.handleChange,
          compareValue: formik.values.color,
          error: !!formik.errors.color && formik.touched.color,
          helperText: formik.touched.color && formik.errors.color
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "fullName",
          label: polyglot.t("NewTimePolicyGeneralStep.fullName"),
          value: formik.values.fullName,
          onChange: formik.handleChange,
          error: formik.touched.fullName && !!formik.errors.fullName,
          helperText: formik.touched.fullName && formik.errors.fullName,
          endAdornment: "none",
          fullWidth: true
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("NewTimePolicyGeneralStep.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: formik.touched.name && formik.errors.name,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isPublic",
          label: polyglot.t("NewTimePolicyGeneralStep.setToPrivate"),
          checked: !formik.values.isPublic,
          onChange: (_, checked) => {
            formik.setFieldValue("isPublic", !checked);
          }
        }
      ),
      /* @__PURE__ */ jsx(Typography, { color: "Grey", sx: { pl: spacing.p25 }, variant: "caption", children: polyglot.t("NewTimePolicyGeneralStep.hideDetailsOfEvent") })
    ] }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "cycleStartMonth",
        label: polyglot.t("NewTimePolicyGeneralStep.cycleStartMonth"),
        options: monthsOptions,
        value: formik.values.cycleStartMonth,
        onChange: formik.handleChange,
        compareValue: formik.values.cycleStartMonth,
        error: !!formik.errors.cycleStartMonth && formik.touched.cycleStartMonth,
        helperText: formik.touched.cycleStartMonth && formik.errors.cycleStartMonth
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "attachmentType",
        label: polyglot.t("NewTimePolicyGeneralStep.attachment"),
        options: attachmentOptions,
        value: (_b = formik.values.attachmentType) != null ? _b : "",
        onChange: formik.handleChange,
        compareValue: formik.values.attachmentType,
        error: !!formik.errors.attachmentType && formik.touched.attachmentType,
        helperText: formik.touched.attachmentType && formik.errors.attachmentType
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] }) }) });
};
