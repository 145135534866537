"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { UserSelect } from "@v2/components/user-select-type/user-select.component";
import { UserSelectFiltersOptions } from "@v2/components/user-select-type/user-select.interface";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const EditInsuranceQuoteMembersDrawer = ({ isOpen, setIsOpen, insuranceQuote, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(EditInsuranceMembersDrawerContent, { insuranceQuote, refresh, setIsOpen }) });
};
export const EditInsuranceMembersDrawerContent = ({
  insuranceQuote,
  refresh,
  setIsOpen
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [selectedOption, setSelectedOption] = useState(UserSelectFiltersOptions.None);
  const [customRule, setCustomRule] = useState("");
  const [selectedUsers, setSelectedUsers] = useState((_a = insuranceQuote.includedUserIds) != null ? _a : []);
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (includedUserIds) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield InsuranceAPI.updateQuoteMembers(insuranceQuote.id, includedUserIds);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, insuranceQuote.id, refresh, setIsOpen, showMessage]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("EditPolicyMembersDrawerContent.editMembers") }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("EditPolicyMembersDrawerContent.whoSelect"),
        selectedLabel: polyglot.t("EditPolicyMembersDrawerContent.selectedMembers"),
        value: selectedUsers,
        onChange: (userIds, filterValue, customRule2) => {
          setSelectedUsers([...userIds]);
          setValidationMessage("");
          setSelectedOption(filterValue != null ? filterValue : UserSelectFiltersOptions.None);
          setCustomRule(customRule2 != null ? customRule2 : "");
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        error: !!validationMessage,
        helperText: !!validationMessage && validationMessage,
        initialFilterValue: UserSelectFiltersOptions.None,
        ruleString: customRule
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m20 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        type: "button",
        onClick: () => __async(void 0, null, function* () {
          if (selectedOption === UserSelectFiltersOptions.None)
            setValidationMessage(polyglot.t("EditPolicyMembersDrawerContent.errorMessages.selectOption"));
          else if (selectedOption === UserSelectFiltersOptions.SelectSpecific && selectedUsers.length === 0)
            setValidationMessage(polyglot.t("EditPolicyMembersDrawerContent.errorMessages.selectOneUser"));
          else if (selectedOption === UserSelectFiltersOptions.CustomRule && !customRule)
            setValidationMessage(polyglot.t("EditPolicyMembersDrawerContent.errorMessages.enterCustomRule"));
          else {
            yield onSubmit(selectedUsers);
          }
        }),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] });
};
