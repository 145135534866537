"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ReviewQuestionBankAPI {
  static createReviewQuestionInBank(questionBody) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/review-question-bank", questionBody)).data;
    });
  }
  static removeQuestionfromTemplate(ids) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/review-question-bank/remove-questions", ids);
    });
  }
  static duplicateQuestionfromTemplate(ids) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/review-question-bank/duplicate-question", ids);
    });
  }
  static updateReviewQuestionInBank(questionBody) {
    return __async(this, null, function* () {
      yield axios.patch("/apiv2/review-question-bank", questionBody);
    });
  }
}
export class ReviewQuestionBankEndpoints {
  static getReviewQuestionFromBank(search, filterString) {
    const urlParams = new URLSearchParams(__spreadValues(__spreadValues({}, search && { search }), filterString && { filterString }));
    return {
      url: `/apiv2/review-question-bank?${urlParams.toString()}`
    };
  }
}
