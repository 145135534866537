"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { UserCustomBenefitRequestType } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { isRecurringBenefit } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateUserInsuranceDependants } from "@v2/infrastructure/i18n/translate.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const BenefitRequestViewDrawer = ({ isOpen, setIsOpen, request, refresh, onClose }) => {
  var _a;
  const { data: userFamilyMembers } = useApiClient(
    ((_a = request.customBenefit) == null ? void 0 : _a.type) && isRecurringBenefit(request.customBenefit.type) ? UserEndpoints.getUserFamilyMembers(request.userId) : null,
    { suspense: false }
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    BenefitRequestViewDrawerContent,
    {
      userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [],
      request,
      setIsOpen,
      refresh
    }
  ) });
};
export const BenefitRequestViewDrawerContent = ({
  setIsOpen,
  request,
  userFamilyMembers,
  refresh
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const canApproveOrReject = !!request.approverIds && request.approverIds.includes(state.user.userId);
  const dependantsList = useMemo(() => {
    var _a2;
    if (!(request == null ? void 0 : request.dependants) || !(request == null ? void 0 : request.dependantsList)) return "";
    return (_a2 = userFamilyMembers.filter((m) => request.dependantsList.includes(m.id)).map((m) => m.name).join(", ")) != null ? _a2 : "";
  }, [userFamilyMembers, request]);
  const approveRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsApproving(true);
      yield CustomBenefitAPI.approveUserBenefitRequest(request.userId, request.customBenefitId, request.id);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsApproving(false);
  }), [polyglot, request, showMessage, setIsOpen, refresh]);
  const rejectRequest = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsRejecting(true);
      yield CustomBenefitAPI.rejectUserBenefitRequest(request.userId, request.customBenefitId, request.id);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsRejecting(false);
  }), [polyglot, request, showMessage, setIsOpen, refresh]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: request.type === UserCustomBenefitRequestType.RecurringChange ? polyglot.t("BenefitModule.changeRequested") : request.type === UserCustomBenefitRequestType.LoanRequest ? polyglot.t("BenefitModule.loanRequested") : polyglot.t("BenefitModule.optOutRequested") }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: request.userId })
    ] }),
    request.type === UserCustomBenefitRequestType.RecurringChange ? /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        DetailsLine,
        {
          label: polyglot.t("General.date"),
          value: new LocalDate(request.effectiveDate).toLocaleDateString()
        }
      ),
      /* @__PURE__ */ jsx(
        DetailsLine,
        {
          label: polyglot.t("BenefitModule.employerContribution"),
          value: formatMoney({
            amount: (_a = request.employerContribution) != null ? _a : 0,
            asDecimal: true
          })
        }
      ),
      /* @__PURE__ */ jsx(
        DetailsLine,
        {
          label: polyglot.t("BenefitModule.employeeContribution"),
          value: formatMoney({
            amount: (_b = request.employeeContribution) != null ? _b : 0,
            asDecimal: true
          })
        }
      ),
      request.dependants && /* @__PURE__ */ jsx(
        DetailsLine,
        {
          label: polyglot.t("BenefitModule.dependants"),
          value: translateUserInsuranceDependants(request.dependants, polyglot)
        }
      ),
      request.dependants && /* @__PURE__ */ jsx(DetailsLine, { label: polyglot.t("BenefitModule.dependantsList"), value: dependantsList }),
      /* @__PURE__ */ jsx(DetailsLine, { label: polyglot.t("General.notes"), value: request.notes })
    ] }) : request.type === UserCustomBenefitRequestType.LoanRequest ? /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        DetailsLine,
        {
          label: polyglot.t("General.amount"),
          value: formatMoney({
            amount: (_c = request.amount) != null ? _c : 0,
            asDecimal: true
          })
        }
      ),
      /* @__PURE__ */ jsx(DetailsLine, { label: polyglot.t("BenefitModule.numberOfInstallments"), value: request.numberOfInstallments }),
      /* @__PURE__ */ jsx(DetailsLine, { label: polyglot.t("General.notes"), value: request.notes })
    ] }) : /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        DetailsLine,
        {
          label: polyglot.t("General.date"),
          value: new LocalDate(request.effectiveDate).toLocaleDateString()
        }
      ),
      /* @__PURE__ */ jsx(DetailsLine, { label: polyglot.t("General.notes"), value: request.notes })
    ] }),
    canApproveOrReject && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.reject"),
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: rejectRequest,
          loading: isRejecting,
          disabled: isApproving,
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.approve"),
          sizeVariant: "medium",
          colorVariant: "primary",
          onClick: approveRequest,
          loading: isApproving,
          disabled: isRejecting,
          fullWidth: true
        }
      )
    ] })
  ] });
};
const DetailsLine = ({ value, label }) => /* @__PURE__ */ jsxs(Box, { sx: { mb: "20px" }, children: [
  /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: label }),
  value || value === 0 ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value }) : /* @__PURE__ */ jsx(EmptyCell, {})
] });
