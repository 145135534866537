"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { SmallCircle } from "@v2/feature/absence/subfeatures/settings/components/draggable-absence-policies.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableWhiteIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as ProfiBig } from "@/images/benefits/ProfiBig.svg";
import { ReactComponent as NewWindow } from "@/images/new-theme-icon/NewWindow.svg";
const LOGO_SIZE = "40px";
export const UserBenefitOverviewTitle = ({ userCustomBenefit }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center"
        },
        children: ((_a = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _a.url) ? /* @__PURE__ */ jsx(
          "img",
          {
            src: userCustomBenefit.customBenefit.url,
            style: { width: LOGO_SIZE, height: LOGO_SIZE, borderRadius: "50%" },
            alt: "benefit-logo"
          }
        ) : /* @__PURE__ */ jsx(ProfiBig, { style: { width: LOGO_SIZE, height: LOGO_SIZE, borderRadius: "50%" } })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", pt: "10px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_c = (_b = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _b.name) != null ? _c : polyglot.t("BenefitModule.benefit") }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (_d = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _d.category }),
        ((_e = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _e.description) && /* @__PURE__ */ jsx(SmallCircle, {}),
        ((_f = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _f.description) && /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "caption",
            sx: {
              maxWidth: "200px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            },
            children: userCustomBenefit.customBenefit.description
          }
        ),
        ((_g = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _g.benefitLink) && /* @__PURE__ */ jsx(SmallCircle, {}),
        ((_h = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _h.benefitLink) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("BenefitModule.loginLink") }),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: __spreadProps(__spreadValues({}, tableWhiteIconButtonSx), { p: 0 }),
              onClick: (event) => {
                var _a2;
                event.preventDefault();
                window.open((_a2 = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _a2.benefitLink, "_blank", "noopener noreferrer");
              },
              children: /* @__PURE__ */ jsx(NewWindow, __spreadValues({}, iconSize))
            }
          )
        ] })
      ] })
    ] })
  ] });
};
