"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { NotificationsTestingMode } from "@v2/feature/notification/notification-settings/features/components/notifications-testing-mode.component";
import { UserNotificationEndpoints } from "@/api-client/notification.api";
import { SlackAPI } from "@/api-client/slack.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { AppDetailsEndpoints } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { NotificationSettingTable } from "@/v2/feature/notification/notification-settings/features/components/notifications-setting-table.component";
import { NotificationEndpoints } from "@/v2/feature/notification/profile-notification.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@/v2/styles/root.styles";
export const NotificationSettingPage = () => {
  const { polyglot } = usePolyglot();
  const [allSlackChannels, setAllSlackChannels] = useState([]);
  const [slackConnected, setSlackConnected] = useState(false);
  const [showMessage] = useMessage();
  const [postSave, setPostSave] = useState(false);
  const {
    data: userNotificationSettings,
    isValidating: loadingUserNotficationSettings,
    mutate: refreshUserNotificationSettings
  } = useApiClient(UserNotificationEndpoints.getNotificationSettings(), { suspense: false });
  const { data: slackAppDetails } = useApiClient(AppDetailsEndpoints.getAppDetails("slack"), { suspense: false });
  const {
    data: profileNotifications,
    isValidating: loadingProfileNotifications,
    mutate: refreshProfileNotifications
  } = useApiClient(NotificationEndpoints.getProfileNotifications(), { suspense: false });
  const refreshNotificationData = () => {
    setPostSave(false);
    refreshUserNotificationSettings == null ? void 0 : refreshUserNotificationSettings();
    refreshProfileNotifications == null ? void 0 : refreshProfileNotifications();
    setPostSave(true);
  };
  const getAllSlackChannels = useCallback(() => __async(void 0, null, function* () {
    try {
      if (slackConnected && !loadingUserNotficationSettings) {
        yield SlackAPI.importChannels();
        let allChannels = [];
        allChannels = (yield SlackAPI.getSlackChannels()).map((item) => ({
          value: item.id,
          label: item.name
        }));
        setAllSlackChannels(allChannels);
      }
    } catch (error) {
      console.error("Failed to load channels: ", nestErrorMessage(error));
      showMessage(polyglot.t("NotificationSettingPage.errorMessages.load"), "error");
    }
  }), [polyglot, loadingUserNotficationSettings, showMessage, slackConnected]);
  useEffect(() => {
    getAllSlackChannels();
  }, [slackConnected]);
  useEffect(() => {
    var _a;
    if (slackAppDetails) {
      setSlackConnected((_a = slackAppDetails == null ? void 0 : slackAppDetails.authorised) != null ? _a : false);
    }
  }, [slackAppDetails]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("NotificationSettingPage.settings") }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        NotificationSettingTable,
        {
          userNotifications: userNotificationSettings != null ? userNotificationSettings : void 0,
          profileNotifications: profileNotifications != null ? profileNotifications : void 0,
          slackChannels: allSlackChannels != null ? allSlackChannels : [],
          slackConnected,
          refreshNotificationData,
          loading: !postSave ? loadingUserNotficationSettings || loadingProfileNotifications : false
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: "60px" }, children: /* @__PURE__ */ jsx(NotificationsTestingMode, {}) })
    ] })
  ] });
};
