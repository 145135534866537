"use strict";
import { jsx } from "react/jsx-runtime";
import { Box, SwipeableDrawer } from "@mui/material";
import { ViewUserPayrollPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/view-user-payroll.page";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
export const ViewUserPayrollDrawer = ({
  isOpen,
  onOpen = () => {
  },
  onClose = () => {
  },
  close,
  addToPayroll,
  canEdit,
  isUserUpdating,
  record,
  removeFromPayroll
}) => {
  const { getCachedUserById } = useCachedUsers();
  const user = record && getCachedUserById(record.userId);
  return /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open: isOpen, onClose, onOpen, children: record && user && /* @__PURE__ */ jsx(Box, { sx: { p: "30px 40px", width: 440, maxWidth: "100vw", boxSizing: "border-box" }, children: /* @__PURE__ */ jsx(
    ViewUserPayrollPage,
    {
      user,
      record,
      canEdit,
      addToPayroll,
      removeFromPayroll,
      isUserUpdating,
      close
    }
  ) }) });
};
