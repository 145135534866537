"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { sortNumeric } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { SuperAdminOrderRefinancingCancellationDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-order-refinancing-cancellation-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const SuperAdminDeviceDetailsOrders = ({
  deviceId,
  refreshDeviceDetails
}) => {
  const {
    data: deviceOrders,
    isLoading,
    mutate: refresh
  } = useApiClient(DeviceEndpoints.getOrdersByDeviceIdAsSuperAdmin(deviceId), { suspense: true });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const refreshOrders = useCallback(() => __async(void 0, null, function* () {
    if (refresh) refresh();
  }), [refresh]);
  const columns = useMemo(() => {
    return [
      {
        id: "id",
        header: "ID",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.id }) })
      },
      {
        id: "companyId",
        header: "Company ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.companyId }) })
      },
      {
        id: "deviceId",
        header: "Device ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.deviceId }) })
      },
      {
        id: "possessionId",
        header: "Possession ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.possessionId }) })
      },
      {
        id: "status",
        header: "Status",
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.status }) })
      },
      {
        id: "deliveryDate",
        header: "Delivery date",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.deliveryDate ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.deliveryDate }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "deliveryAddress",
        header: "Delivery address",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.deliveryAddress ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.deliveryAddress }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "deliveryCountry",
        header: "Delivery country",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.deliveryCountry ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.deliveryCountry }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "createdBy",
        header: "Created by",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.createdBy }) })
      },
      {
        id: "price",
        header: "Price",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.price ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.price }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "isPurchase",
        header: "Is purchase",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.isPurchase ? "Yes" : "No" }) })
      },
      {
        id: "contractLength",
        header: "Contract length",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.contractLength ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.contractLength }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "phoneNumber",
        header: "Phone number",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.phoneNumber ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.phoneNumber }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "trackingLink",
        header: "Tracking link",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.trackingLink ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.trackingLink }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "externallyInvoiced",
        header: "Externally invoiced",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.externallyInvoiced ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.externallyInvoiced }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "eligibleForRefinance",
        header: "Eligible for refinance",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.eligibleForRefinance ? "Yes" : "No" }) })
      },
      {
        id: "createdAt",
        header: "Created at",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: new Date(original.createdAt).toLocaleString() }) })
      },
      {
        id: "updatedAt",
        header: "Updated at",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: new Date(original.updatedAt).toLocaleString() }) })
      }
    ];
  }, []);
  const handleRowClick = useCallback((row) => {
    setSelectedOrder(row.original);
    setIsOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Device orders" }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: "5px" }, children: [
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: deviceOrders != null ? deviceOrders : [],
          columnData: columns,
          loading: isLoading,
          hidePagination: true,
          rowClick: handleRowClick
        }
      ),
      /* @__PURE__ */ jsx(
        SuperAdminOrderRefinancingCancellationDrawer,
        {
          isOpen,
          setIsOpen,
          deviceOrder: selectedOrder,
          refreshOrders,
          refreshDeviceDetails
        }
      )
    ] })
  ] });
};
