"use strict";
import { jsx } from "react/jsx-runtime";
import { ReactComponent as NotEnrolledIcon } from "@/images/side-bar-icons/Rejected-grey.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const NotEnrolledInPension = ({ userId }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const name = ((_a = getCachedUserById(userId)) == null ? void 0 : _a.displayName) || "User";
  return /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("PayrunTable.notEnrolledInPension", { name }), children: /* @__PURE__ */ jsx(NotEnrolledIcon, {}) });
};
