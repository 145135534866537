"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { ProfileTab } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { ScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { UserProfileProvider } from "@/v2/feature/user/context/user-profile.context";
import { AboutCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-about-card.component";
import { AccountInformationCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-account-info-card.component";
import { BasicInformationCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-card.component";
import { CustomSections } from "@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-card.component";
import { FamilyCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-family-card.component";
import { PersonalInformationCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-card.component";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserPersonal = ({ userId, onUpdate, missingFields }) => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const finallyRefreshUserObject = (section) => __async(void 0, null, function* () {
    if (["user.basicInfo", "user.role", "user.lifecycle"].includes(section)) {
    }
    yield onUpdate();
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Personal" }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: { pt: spacing.p25 }, children: /* @__PURE__ */ jsx(UserProfileProvider, { children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.basicInfo:read"], context: scopesContext, children: /* @__PURE__ */ jsx(
        BasicInformationCard,
        {
          userId,
          onSubmit: () => finallyRefreshUserObject("user.basicInfo"),
          missingFields
        }
      ) }),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.personalInfo:read"], context: scopesContext, children: /* @__PURE__ */ jsx(
        PersonalInformationCard,
        {
          userId,
          onSubmit: () => finallyRefreshUserObject("user.personalInfo"),
          missingFields
        }
      ) }),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.family:read"], context: scopesContext, children: /* @__PURE__ */ jsx(
        FamilyCard,
        {
          userId,
          onSubmit: () => finallyRefreshUserObject("user.family"),
          missingFields
        }
      ) }),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.about:read"], context: scopesContext, children: /* @__PURE__ */ jsx(
        AboutCard,
        {
          userId,
          onSubmit: () => finallyRefreshUserObject("user.about"),
          missingFields
        }
      ) }),
      /* @__PURE__ */ jsx(
        CustomSections,
        {
          userId,
          formTab: ProfileTab.Personal,
          onSubmit: () => finallyRefreshUserObject(""),
          missingFields
        }
      ),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.accountInfo", "user.accountInfo:read"], context: scopesContext, children: /* @__PURE__ */ jsx(AccountInformationCard, { userId, onSubmit: () => finallyRefreshUserObject("user.lifecycle") }) })
    ] }) }) })
  ] });
};
