"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PaymentTypeSettingsAPI {
  static create(paymentTypeSetting) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payment-type-settings`, paymentTypeSetting)).data;
    });
  }
  static update(typeId, paymentTypeSetting) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/payment-type-settings/${typeId}`, paymentTypeSetting)).data;
    });
  }
  static updateGeneral(typeId, GeneralSettingUpdate2) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/payment-type-settings/${typeId}/general`, GeneralSettingUpdate2)).data;
    });
  }
  static updateAccounting(typeId, accountingSettingUpdate) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/payment-type-settings/${typeId}/accounting`, accountingSettingUpdate)).data;
    });
  }
  static updateSyncSetting(typeId, syncSettingUpdate) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/payment-type-settings/${typeId}/sync-setting`, syncSettingUpdate)).data;
    });
  }
  static updateApproval(typeId, approvalRuleId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/payment-type-settings/${typeId}/approval`, { approvalRuleId })).data;
    });
  }
  static updatePaymentTypeMembers(typeId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/payment-type-settings/${typeId}/members`, data);
    });
  }
  static delete(typeId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/payment-type-settings/${typeId}`)).data;
    });
  }
  static deleteAccountingCodeFromPaymentTypeSetting(typeId, code) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/payment-type-settings/${typeId}/accountingCode/${code}`)).data;
    });
  }
  static getPaymentTypeSettingsForContractorInvoice() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/payment-type-settings?type=Invoice")).data;
    });
  }
  static initiateAccountingAppConnection(appStub, paymentType, typeId) {
    return `/apiv2/payment-type-settings/app/${appStub}/${paymentType}/${typeId}/initiate`;
  }
}
export class PaymentTypeSettingsEndpoints {
  static getPaymentTypeSettingsForCompanyId() {
    return {
      url: `/apiv2/payment-type-settings`
    };
  }
  static accountingAppConfigured() {
    return {
      url: "/apiv2/contractor/invoice/is-accounting-app-configured"
    };
  }
  static getExpenseTypesForCompanyId() {
    return {
      url: `/apiv2/payment-type-settings?type=Expense`
    };
  }
  static getTypeSettingById(id) {
    return {
      url: `/apiv2/payment-type-settings/${id}`
    };
  }
  static getInvoiceTypesForCompanyId() {
    return {
      url: `/apiv2/payment-type-settings?type=Invoice`
    };
  }
  static getAccountingCodesFromProvider() {
    return {
      url: "/apiv2/company/payroll/nominal-code/accounting"
    };
  }
}
