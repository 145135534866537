"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const NewPaymentButton = ({ onExpenseClick, onInvoiceClick }) => {
  const { polyglot } = usePolyglot();
  const newDocumentButtonOptions = useMemo(() => {
    const optionsAvailable = [];
    if (onExpenseClick)
      optionsAvailable.push({
        icon: void 0,
        handler: () => onExpenseClick(),
        label: polyglot.t("PaymentListPage.newButtonExpenseOption"),
        disabled: false
      });
    if (onInvoiceClick)
      optionsAvailable.push({
        icon: void 0,
        handler: () => onInvoiceClick(),
        label: polyglot.t("PaymentListPage.newButtonInvoiceOption"),
        disabled: false
      });
    return optionsAvailable;
  }, [polyglot, onExpenseClick, onInvoiceClick]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options: newDocumentButtonOptions,
      actionButtonDetails: {
        type: "button",
        colorVariant: "primary",
        sizeVariant: "small",
        title: polyglot.t("PaymentListPage.newButtonPayment"),
        icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
        iconPosition: "end"
      }
    }
  ) });
};
