"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  PeopleDomainReportFilters,
  ReportEntity,
  ReportTypePeopleSelection,
  UserEntitiesArray
} from "@v2/feature/reports/reports.interface";
import { popularCurrencyOptions } from "@v2/infrastructure/currency/currency.interface";
import { toTitleCase } from "@v2/util/string.util";
import { findLastIndex, groupBy } from "lodash";
const EntityScopes = {
  // TIME REQUESTS REPORT
  [ReportEntity.Requests]: "absence:all",
  // DEVICES REPORT
  [ReportEntity.Devices]: "devices:all",
  // ATTENDANCES REPORT
  [ReportEntity.Attendance]: "absence:all",
  // PEOPLE REPORT
  [ReportEntity.Basic]: "user.basicInfo:all",
  [ReportEntity.Details]: "user.personalInfo:all",
  [ReportEntity.About]: "user.about:all",
  [ReportEntity.Family]: "user.family:all",
  [ReportEntity.AccountInfo]: "user.accountInfo:read:all",
  [ReportEntity.EmergencyContact]: "user.emergencyContact:all",
  [ReportEntity.WorkContact]: "user.workContact:all",
  [ReportEntity.Role]: "user.role:all",
  [ReportEntity.Contract]: "user.contract:all",
  [ReportEntity.Lifecycle]: "user.lifecycle:all",
  [ReportEntity.Compensation]: "user.compensation:all",
  [ReportEntity.BankAccount]: "user.bankAccount:all",
  [ReportEntity.Address]: "user.address:all",
  [ReportEntity.RightToWork]: "user.rightWork:all",
  [ReportEntity.TaxInformation]: "payroll:all",
  [ReportEntity.Payroll]: "payroll:all",
  [ReportEntity.Equity]: "user.equity:all"
};
export const UserReportColumnsOptions = (polyglot) => {
  return {
    [ReportEntity.User]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.employeename"), value: "employeeName", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.firstname"), value: "firstName", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.lastname"), value: "lastName", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.emailaddress"), value: "emailAddress", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.middlename"), value: "middleName", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.displayname"), value: "displayName", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.fullname"), value: "name", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.startdate"), value: "startDate", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.leavedate"), value: "leaveDate", entity: ReportEntity.Basic },
      { label: polyglot.t("ColumnsOptions.gender"), value: "gender", entity: ReportEntity.Details },
      { label: polyglot.t("ColumnsOptions.nationality"), value: "nationality", entity: ReportEntity.Details },
      { label: polyglot.t("ColumnsOptions.dateofbirth"), value: "dob", entity: ReportEntity.Details },
      {
        label: polyglot.t("ColumnsOptions.personalemail"),
        value: "personalEmail",
        entity: ReportEntity.Details
      },
      { label: polyglot.t("ColumnsOptions.phone"), value: "phone", entity: ReportEntity.Details },
      {
        label: polyglot.t("ColumnsOptions.passportnumber"),
        value: "passportNumber",
        entity: ReportEntity.Details
      },
      {
        label: polyglot.t("ColumnsOptions.maritalstatus"),
        value: "maritalStatus",
        entity: ReportEntity.Family
      },
      { label: polyglot.t("ColumnsOptions.about"), value: "about", entity: ReportEntity.About },
      { label: polyglot.t("ColumnsOptions.hobbies"), value: "hobbies", entity: ReportEntity.About },
      {
        label: polyglot.t("ColumnsOptions.foodpreferences"),
        value: "foodPreferences",
        entity: ReportEntity.About
      },
      { label: polyglot.t("ColumnsOptions.social"), value: "social", entity: ReportEntity.About },
      {
        label: polyglot.t("ColumnsOptions.dietaryrestrictions"),
        value: "dietaryRestrictions",
        entity: ReportEntity.About
      },
      {
        label: polyglot.t("ColumnsOptions.employeeid"),
        value: "employeeId",
        entity: ReportEntity.AccountInfo
      },
      {
        label: polyglot.t("ColumnsOptions.accountstatus"),
        value: "accountStatus",
        entity: ReportEntity.AccountInfo
      },
      { label: polyglot.t("ColumnsOptions.mfatype"), value: "mfaType", entity: ReportEntity.AccountInfo },
      {
        label: polyglot.t("ColumnsOptions.activationdate"),
        value: "activationDate",
        entity: ReportEntity.AccountInfo
      },
      {
        label: polyglot.t("ColumnsOptions.deactivationdate"),
        value: "deactivationDate",
        entity: ReportEntity.AccountInfo
      },
      {
        label: polyglot.t("ColumnsOptions.lasttimeonline"),
        value: "lastTimeOnline",
        entity: ReportEntity.AccountInfo
      },
      {
        label: polyglot.t("ColumnsOptions.righttoworkchecked"),
        value: "rightToWorkChecked",
        entity: ReportEntity.RightToWork
      },
      {
        label: polyglot.t("ColumnsOptions.righttoworkcheckdate"),
        value: "rightToWorkCheckDate",
        entity: ReportEntity.RightToWork
      },
      {
        label: polyglot.t("ColumnsOptions.righttoworkislimited"),
        value: "rightToWorkIsLimited",
        entity: ReportEntity.RightToWork
      },
      {
        label: polyglot.t("ColumnsOptions.righttoworkexpirydate"),
        value: "rightToWorkExpiryDate",
        entity: ReportEntity.RightToWork
      },
      {
        label: polyglot.t("ColumnsOptions.righttoworkdocuments"),
        value: "rightToWorkDocuments",
        entity: ReportEntity.RightToWork
      },
      {
        label: polyglot.t("ColumnsOptions.emergencyname"),
        value: "emergencyName",
        entity: ReportEntity.EmergencyContact
      },
      {
        label: polyglot.t("ColumnsOptions.emergencyrelationship"),
        value: "emergencyRelationship",
        entity: ReportEntity.EmergencyContact
      },
      {
        label: polyglot.t("ColumnsOptions.emergencynumber"),
        value: "emergencyNumber",
        entity: ReportEntity.EmergencyContact
      },
      {
        label: polyglot.t("ColumnsOptions.workmobile"),
        value: "workMobile",
        entity: ReportEntity.WorkContact
      },
      { label: polyglot.t("ColumnsOptions.workphone"), value: "workPhone", entity: ReportEntity.WorkContact }
    ],
    [ReportEntity.Role]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.Role },
      { label: polyglot.t("ColumnsOptions.employeename"), value: "employeeName", entity: ReportEntity.Role },
      {
        label: polyglot.t("ColumnsOptions.rolejobtitle"),
        value: "Role: jobTitle",
        entity: ReportEntity.Role
      },
      {
        label: polyglot.t("ColumnsOptions.rolelastchangetype"),
        value: "Role: lastChangeType",
        entity: ReportEntity.Role
      },
      {
        label: polyglot.t("ColumnsOptions.rolelastchangereason"),
        value: "Role: lastChangeReason",
        entity: ReportEntity.Role
      },
      {
        label: polyglot.t("ColumnsOptions.roleeffectivedate"),
        value: "Role: effectiveDate",
        entity: ReportEntity.Role
      },
      {
        label: polyglot.t("ColumnsOptions.roledepartment"),
        value: "Role: department",
        entity: ReportEntity.Role
      },
      { label: polyglot.t("ColumnsOptions.rolesite"), value: "Role: site", entity: ReportEntity.Role },
      { label: polyglot.t("ColumnsOptions.rolemanager"), value: "Role: manager", entity: ReportEntity.Role },
      { label: polyglot.t("ColumnsOptions.rolecompany"), value: "Role: company", entity: ReportEntity.Role }
    ],
    [ReportEntity.Contract]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.Contract },
      {
        label: polyglot.t("ColumnsOptions.employeename"),
        value: "employeeName",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contracteffectivedate"),
        value: "Contract: effectiveDate",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractcontract"),
        value: "Contract: contract",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contracttype"),
        value: "Contract: type",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractattendanceschedule"),
        value: "Contract: attendanceSchedule",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractftepercent"),
        value: "Contract: ftePercent",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractpublicholidays"),
        value: "Contract: publicHolidays",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractchangereason"),
        value: "Contract: changeReason",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractnoticeperiodlength"),
        value: "Contract: noticePeriodLength",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractnoticeperiodunit"),
        value: "Contract: noticePeriodUnit",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractprobationperiodlength"),
        value: "Contract: probationPeriodLength",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractprobationperiodunit"),
        value: "Contract: probationPeriodUnit",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractentityid"),
        value: "Contract: entityId",
        entity: ReportEntity.Contract
      },
      {
        label: polyglot.t("ColumnsOptions.contractentityname"),
        value: "Contract: entityName",
        entity: ReportEntity.Contract
      }
    ],
    [ReportEntity.Lifecycle]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.Lifecycle },
      {
        label: polyglot.t("ColumnsOptions.employeename"),
        value: "employeeName",
        entity: ReportEntity.Lifecycle
      },
      {
        label: polyglot.t("ColumnsOptions.lifecycleeffectivedate"),
        value: "Lifecycle: effectiveDate",
        entity: ReportEntity.Lifecycle
      },
      {
        label: polyglot.t("ColumnsOptions.lifecyclestatus"),
        value: "Lifecycle: status",
        entity: ReportEntity.Lifecycle
      },
      {
        label: polyglot.t("ColumnsOptions.lifecyclechangereason"),
        value: "Lifecycle: changeReason",
        entity: ReportEntity.Lifecycle
      },
      {
        label: polyglot.t("ColumnsOptions.lifecyclenote"),
        value: "Lifecycle: note",
        entity: ReportEntity.Lifecycle
      },
      {
        label: polyglot.t("ColumnsOptions.lifecycleeffectiveenddate"),
        value: "Lifecycle: effectiveEndDate",
        entity: ReportEntity.Lifecycle
      }
    ],
    [ReportEntity.Compensation]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.Compensation },
      {
        label: polyglot.t("ColumnsOptions.employeename"),
        value: "employeeName",
        entity: ReportEntity.Compensation
      },
      {
        label: polyglot.t("ColumnsOptions.compensationpayschedule"),
        value: "Compensation: paySchedule",
        entity: ReportEntity.Compensation
      },
      {
        label: polyglot.t("ColumnsOptions.compensationsalarybasis"),
        value: "Compensation: salaryBasis",
        entity: ReportEntity.Compensation
      },
      {
        label: polyglot.t("ColumnsOptions.compensationrate"),
        value: "Compensation: rate",
        entity: ReportEntity.Compensation
      },
      {
        label: polyglot.t("ColumnsOptions.compensationunits"),
        value: "Compensation: units",
        entity: ReportEntity.Compensation
      },
      {
        label: polyglot.t("ColumnsOptions.compensationsalary"),
        value: "Compensation: salary",
        entity: ReportEntity.Compensation
      },
      {
        label: polyglot.t("ColumnsOptions.compensationcurrency"),
        value: "Compensation: currency",
        entity: ReportEntity.Compensation
      },
      {
        label: polyglot.t("ColumnsOptions.compensationeffectivedate"),
        value: "Compensation: effectiveDate",
        entity: ReportEntity.Compensation
      }
    ],
    [ReportEntity.TaxInformation]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.TaxInformation },
      {
        label: polyglot.t("ColumnsOptions.employeename"),
        value: "employeeName",
        entity: ReportEntity.TaxInformation
      },
      {
        label: polyglot.t("ColumnsOptions.payrollcountrycode"),
        value: "Payroll: countryCode",
        entity: ReportEntity.TaxInformation
      },
      {
        label: polyglot.t("ColumnsOptions.payrolleffectivedate"),
        value: "Payroll: effectiveDate",
        entity: ReportEntity.TaxInformation
      },
      {
        label: polyglot.t("ColumnsOptions.payrollchangereason"),
        value: "Payroll: changeReason",
        entity: ReportEntity.TaxInformation
      },
      {
        label: polyglot.t("ColumnsOptions.payrollinpayroll"),
        value: "Payroll: inPayroll",
        entity: ReportEntity.TaxInformation
      }
      // THE REST OF COLUMNS COME FROM additionalColumns request, as they are different depending on the country code
      // { label: 'Payroll: NI table', value: 'Payroll: niTable', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: Tax code', value: 'Payroll: taxCode', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: NI number', value: 'Payroll: niNumber', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: Is director', value: 'Payroll: isDirector', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: Is P45 filled', value: 'Payroll: isP45Filled', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: Legal gender', value: 'Payroll: legalGender', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: Student loan', value: 'Payroll: studentLoan', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: week1Month1', value: 'Payroll: week1Month1', entity: ReportEntity.TaxInformation },
      // { label: 'Payroll: Postgrad loan', value: 'Payroll: postgradLoan', entity: ReportEntity.TaxInformation },
      // {
      //   label: 'Payroll: Opening current tax',
      //   value: 'Payroll: openingCurrentTax',
      //   entity: ReportEntity.TaxInformation,
      // },
      // {
      //   label: 'Payroll: Opening previous tax',
      //   value: 'Payroll: openingPreviousTax',
      //   entity: ReportEntity.TaxInformation,
      // },
      // {
      //   label: 'Payroll: starter declaration',
      //   value: 'Payroll: starterDeclaration',
      //   entity: ReportEntity.TaxInformation,
      // },
      // {
      //   label: 'Payroll: NI alternative method',
      //   value: 'Payroll: niAlternativeMethod',
      //   entity: ReportEntity.TaxInformation,
      // },
      // {
      //   label: 'Payroll: Opening current gross',
      //   value: 'Payroll: openingCurrentGross',
      //   entity: ReportEntity.TaxInformation,
      // },
      // {
      //   label: 'Payroll: Opening not new starter',
      //   value: 'Payroll: openingNotNewStarter',
      //   entity: ReportEntity.TaxInformation,
      // },
      // {
      //   label: 'Payroll: Opening previous gross',
      //   value: 'Payroll: openingPreviousGross',
      //   entity: ReportEntity.TaxInformation,
      // },
    ],
    [ReportEntity.BankAccount]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.BankAccount },
      {
        label: polyglot.t("ColumnsOptions.employeename"),
        value: "employeeName",
        entity: ReportEntity.BankAccount
      },
      {
        label: polyglot.t("ColumnsOptions.bankaccounteffectivedate"),
        value: "Bank account: effectiveDate",
        entity: ReportEntity.BankAccount
      },
      {
        label: polyglot.t("ColumnsOptions.bankaccountbankname"),
        value: "Bank account: bankName",
        entity: ReportEntity.BankAccount
      },
      {
        label: polyglot.t("ColumnsOptions.bankaccountaccountname"),
        value: "Bank account: accountName",
        entity: ReportEntity.BankAccount
      },
      {
        label: polyglot.t("ColumnsOptions.bankaccountaccountnumber"),
        value: "Bank account: accountNumber",
        entity: ReportEntity.BankAccount
      },
      {
        label: polyglot.t("ColumnsOptions.bankaccountsortcode"),
        value: "Bank account: sortCode",
        entity: ReportEntity.BankAccount
      },
      {
        label: polyglot.t("ColumnsOptions.bankaccountcountry"),
        value: "Bank account: country",
        entity: ReportEntity.BankAccount
      },
      {
        label: polyglot.t("ColumnsOptions.bankaccountcurrency"),
        value: "Bank account: currency",
        entity: ReportEntity.BankAccount
      }
    ],
    [ReportEntity.Address]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.Address },
      {
        label: polyglot.t("ColumnsOptions.employeename"),
        value: "employeeName",
        entity: ReportEntity.Address
      },
      {
        label: polyglot.t("ColumnsOptions.addresseffectivedate"),
        value: "Address: effectiveDate",
        entity: ReportEntity.Address
      },
      {
        label: polyglot.t("ColumnsOptions.addressaddressline1"),
        value: "Address: addressLine1",
        entity: ReportEntity.Address
      },
      {
        label: polyglot.t("ColumnsOptions.addressaddressline2"),
        value: "Address: addressLine2",
        entity: ReportEntity.Address
      },
      {
        label: polyglot.t("ColumnsOptions.addresspostcode"),
        value: "Address: postcode",
        entity: ReportEntity.Address
      },
      {
        label: polyglot.t("ColumnsOptions.addresscity"),
        value: "Address: city",
        entity: ReportEntity.Address
      },
      {
        label: polyglot.t("ColumnsOptions.addresscountry"),
        value: "Address: country",
        entity: ReportEntity.Address
      }
    ],
    [ReportEntity.Equity]: [
      { label: polyglot.t("ColumnsOptions.userid"), value: "userId", entity: ReportEntity.Equity },
      {
        label: polyglot.t("ColumnsOptions.employeename"),
        value: "employeeName",
        entity: ReportEntity.Equity
      },
      {
        label: polyglot.t("ColumnsOptions.equityamount"),
        value: "Equity: amount",
        entity: ReportEntity.Equity
      },
      { label: polyglot.t("ColumnsOptions.equitytype"), value: "Equity: type", entity: ReportEntity.Equity },
      {
        label: polyglot.t("ColumnsOptions.equitygrantdate"),
        value: "Equity: grantDate",
        entity: ReportEntity.Equity
      },
      {
        label: polyglot.t("ColumnsOptions.equityreason"),
        value: "Equity: reason",
        entity: ReportEntity.Equity
      },
      {
        label: polyglot.t("ColumnsOptions.equitycurrency"),
        value: "Equity: currency",
        entity: ReportEntity.Equity
      },
      {
        label: polyglot.t("ColumnsOptions.equityunitprice"),
        value: "Equity: unitPrice",
        entity: ReportEntity.Equity
      },
      {
        label: polyglot.t("ColumnsOptions.equityvestingstart"),
        value: "Equity: vestingStart",
        entity: ReportEntity.Equity
      },
      {
        label: polyglot.t("ColumnsOptions.equityvestingperiod"),
        value: "Equity: vestingPeriod",
        entity: ReportEntity.Equity
      },
      {
        label: polyglot.t("ColumnsOptions.equityvestingcliff"),
        value: "Equity: vestingCliff",
        entity: ReportEntity.Equity
      }
    ]
  };
};
export const getUserReportColumnsOptionsByPermissions = (userEntities, customFields, hasScopes, additionalColumns, polyglot) => {
  const keys = Object.keys(UserReportColumnsOptions(polyglot));
  return keys.reduce((acc, key) => {
    const basicFields = UserReportColumnsOptions(polyglot)[key].filter(
      (o) => userEntities.includes(o.entity) && hasScopes([EntityScopes[o.entity]])
    );
    if (basicFields.length > 0) acc[key] = basicFields;
    if (basicFields.length > 0 && key === ReportEntity.User) {
      for (const customFieldKey in customFields) {
        customFields[customFieldKey].forEach((reportField) => {
          if (reportField.entity in EntityScopes && !hasScopes([EntityScopes[reportField.entity]])) {
            return;
          }
          const idx = findLastIndex(acc[key], (x) => x.entity === reportField.entity);
          acc[key].splice(idx < 0 ? 1e9 : idx + 1, 0, reportField);
        });
      }
    }
    if (basicFields.length > 0 && key === ReportEntity.TaxInformation) {
      acc[key].push(...additionalColumns);
    }
    return acc;
  }, {});
};
export const getAllSelectedColumnsFromUserReportColumnsOptions = (userEntities, customFields, hasScopes, additionalColumns, polyglot) => {
  const permittedOptions = getUserReportColumnsOptionsByPermissions(
    userEntities,
    customFields,
    hasScopes,
    additionalColumns,
    polyglot
  );
  const keys = Object.keys(permittedOptions);
  return keys.reduce((acc, key) => {
    acc[key] = permittedOptions[key].filter((o) => !o.entity || userEntities.includes(o.entity)).map((o) => o.value);
    return acc;
  }, {});
};
export function getPeopleReportFilters(peopleFiltersOptions, polyglot) {
  let filters = {};
  const FILTERS = PeopleDomainReportFilters(__spreadProps(__spreadValues({}, peopleFiltersOptions), {
    polyglot,
    currencyOptions: popularCurrencyOptions
  }));
  for (const filterObj of FILTERS) {
    let filter = filterObj.name;
    filters = __spreadProps(__spreadValues({}, filters), { [`${filterObj.domain}-${filterObj.field}-${filter}`]: filterObj.options });
  }
  return filters;
}
export function generateCustomFieldLabel(field) {
  return field.fieldName.toLowerCase().replaceAll(" ", "-");
}
export function includeCustomFieldsColumns(entities, customFields) {
  const response = {};
  const fieldsByFormName = groupBy(customFields, "form.formName");
  for (const formName in fieldsByFormName) {
    response[formName] = fieldsByFormName[formName].map((field) => ({
      label: field.fieldName,
      value: generateCustomFieldLabel(field),
      entity: toTitleCase(formName)
    }));
  }
  return response;
}
export function getFilterColumnsPeopleChangeReport(currentTab, userEntities, customFields, hasScopes, additionalColumns, polyglot) {
  var _a;
  const permittedOptions = getUserReportColumnsOptionsByPermissions(
    userEntities,
    customFields,
    hasScopes,
    additionalColumns,
    polyglot
  );
  const result = permittedOptions[currentTab];
  const columnsSet = /* @__PURE__ */ new Set();
  return (_a = result == null ? void 0 : result.reduce((uniqueColumns, column) => {
    if (columnsSet.has(column.value)) return uniqueColumns;
    uniqueColumns.push(column);
    columnsSet.add(column.value);
    return uniqueColumns;
  }, [])) != null ? _a : [];
}
export function getAllColumnsOptionsPeopleCurrentReports(userEntities, customFields, hasScopes, additionalColumns, polyglot) {
  const permittedOptions = getUserReportColumnsOptionsByPermissions(
    userEntities,
    customFields,
    hasScopes,
    additionalColumns,
    polyglot
  );
  const result = [];
  for (const key of Object.keys(permittedOptions)) result.push(...permittedOptions[key]);
  const columnsSet = /* @__PURE__ */ new Set();
  return result.reduce((uniqueColumns, column) => {
    if (columnsSet.has(column.value)) return uniqueColumns;
    uniqueColumns.push(column);
    columnsSet.add(column.value);
    return uniqueColumns;
  }, []);
}
export function getOnlyAllowedColumnsStringsPeopleCurrentReports(userEntities, customFields, hasScopes, additionalColumns, polyglot) {
  const allColumns = getAllColumnsOptionsPeopleCurrentReports(
    userEntities,
    customFields,
    hasScopes,
    additionalColumns,
    polyglot
  ).map((o) => o.value);
  const initialColumns = [
    "employeeName",
    "Role: jobTitle",
    "Lifecycle: status",
    "Lifecycle: effectiveDate",
    "Lifecycle: startDate",
    "Lifecycle: leaveDate",
    "Role: manager",
    "Contract: entityName"
  ];
  return allColumns.filter((column) => initialColumns.includes(column));
}
export function getAllUsersCurrentRecordsEntitiesNames(hasScopes) {
  return UserEntitiesArray.filter(
    (e) => (e == null ? void 0 : e.type.includes(ReportTypePeopleSelection.CurrentRecords)) && hasScopes(e.scope)
  ).map((e) => e.name);
}
