"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { AttendanceScheduleEditGeneralDrawer } from "@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-general-drawer.component";
import { AttendanceScheduleDeleteConfirmationDrawer } from "@v2/feature/attendance/company/components/schedule-details/attendance-schedule-delete-confirmation-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Typography } from "@/v2/components/typography/typography.component";
export const AttendanceSettingsGeneralSection = ({ schedule, refreshSchedule, refreshSchedules }) => {
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("General.general"),
      onEdit: () => {
        setEdit(true);
      },
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AttendanceScheduleGeneralSection.name"),
                    value: schedule.name
                  }
                ]
              },
              {
                title: polyglot.t("AttendanceSchedule.deleteSchedule"),
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
                      polyglot.t("AttendanceSchedule.deleteDescription"),
                      /* @__PURE__ */ jsx(
                        "span",
                        {
                          onClick: () => {
                            setIsDeleteDrawerOpen(true);
                          },
                          style: { color: "red", textDecoration: "underline", cursor: "pointer" },
                          children: polyglot.t("AttendanceSchedule.here")
                        }
                      )
                    ] })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          AttendanceScheduleEditGeneralDrawer,
          {
            isOpen: edit,
            setIsOpen: setEdit,
            attendanceSchedule: schedule,
            refresh: () => __async(void 0, null, function* () {
              yield Promise.all([refreshSchedules(), refreshSchedule()]);
            })
          }
        ),
        /* @__PURE__ */ jsx(
          AttendanceScheduleDeleteConfirmationDrawer,
          {
            isOpen: isDeleteDrawerOpen,
            setIsOpen: setIsDeleteDrawerOpen,
            attendanceSchedule: schedule,
            refresh: refreshSchedules
          }
        )
      ]
    }
  );
};
