"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrunUserHeader = ({ user, sx }) => {
  var _a, _b;
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g5 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: (_b = (_a = user.role) == null ? void 0 : _a.jobPosition) == null ? void 0 : _b.title }),
    /* @__PURE__ */ jsx(UserCell, { userId: user.userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }) })
  ] });
};
