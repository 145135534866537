"use strict";
import { DevicePolicyType } from "@v2/feature/device/device.interface";
export const MinimumLength = [
  { value: 0, label: "Not set" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" }
];
export const MinimumComplexChars = [
  { value: 0, label: "Not set" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" }
];
export const MaxInactivity = [
  { value: 0, label: "Not set" },
  { value: 1, label: "1 min" },
  { value: 2, label: "2 min" },
  { value: 3, label: "3 min" },
  { value: 4, label: "4 min" },
  { value: 5, label: "5 min" },
  { value: 6, label: "6 min" },
  { value: 7, label: "7 min" },
  { value: 8, label: "8 min" },
  { value: 9, label: "9 min" },
  { value: 10, label: "10 min" },
  { value: 15, label: "15 min" }
];
export const DeferForceAtUserLoginMaxBypassAttemptsOptions = [
  { value: -1, label: "Not set" },
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" }
];
export var ZeltPolicyPayloadIdentifier = /* @__PURE__ */ ((ZeltPolicyPayloadIdentifier2) => {
  ZeltPolicyPayloadIdentifier2["FILE_VAULT_PAYLOAD_IDENTIFIER"] = "app.zelt.filevault";
  ZeltPolicyPayloadIdentifier2["FIREWALL_PAYLOAD_IDENTIFIER"] = "app.zelt.firewall";
  ZeltPolicyPayloadIdentifier2["PASSWORD_PAYLOAD_IDENTIFIER"] = "app.zelt.password";
  ZeltPolicyPayloadIdentifier2["BROWSING_PAYLOAD_IDENTIFIER"] = "app.zelt.browsing";
  ZeltPolicyPayloadIdentifier2["ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER"] = "app.zelt.restrictions.icloud";
  ZeltPolicyPayloadIdentifier2["SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER"] = "app.zelt.restrictions.security";
  ZeltPolicyPayloadIdentifier2["SCREEN_RESTRICTIONS_PAYLOAD_IDENTIFIER"] = "app.zelt.restrictions.screen";
  ZeltPolicyPayloadIdentifier2["ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER"] = "app.zelt.restrictions.additional.password";
  ZeltPolicyPayloadIdentifier2["OS_UPDATES_RESTRICTIONS_PAYLOAD_IDENTIFIER"] = "app.zelt.restrictions.os-updates";
  ZeltPolicyPayloadIdentifier2["SCREEN_LOGIN_SETTINGS_PAYLOAD_IDENTIFIER"] = "app.zelt.settings.login.screen";
  ZeltPolicyPayloadIdentifier2["LOGIN_ITEMS_PAYLOAD_IDENTIFIER"] = "app.zelt.login-items";
  ZeltPolicyPayloadIdentifier2["WIFI_PAYLOAD_IDENTIFIER"] = "app.zelt.wifi";
  return ZeltPolicyPayloadIdentifier2;
})(ZeltPolicyPayloadIdentifier || {});
export const NoAppliedPolicies = {
  [DevicePolicyType.passcode]: [0],
  [DevicePolicyType.encryption]: [0],
  [DevicePolicyType.location]: [0],
  [DevicePolicyType.updates]: [0],
  [DevicePolicyType.icloud]: [0],
  [DevicePolicyType.apps]: [0],
  [DevicePolicyType.firewall]: [0]
};
export var ZeltMdmDevicePolicyType = /* @__PURE__ */ ((ZeltMdmDevicePolicyType2) => {
  ZeltMdmDevicePolicyType2["passcode"] = "passcode";
  ZeltMdmDevicePolicyType2["encryption"] = "encryption";
  ZeltMdmDevicePolicyType2["apps"] = "apps";
  ZeltMdmDevicePolicyType2["icloud"] = "icloud";
  ZeltMdmDevicePolicyType2["firewall"] = "firewall";
  ZeltMdmDevicePolicyType2["screen"] = "screen";
  ZeltMdmDevicePolicyType2["security"] = "security";
  ZeltMdmDevicePolicyType2["browsing"] = "browsing";
  ZeltMdmDevicePolicyType2["additional_password"] = "additional_password";
  ZeltMdmDevicePolicyType2["osupdate_restrictions"] = "os_update_restrictions";
  ZeltMdmDevicePolicyType2["osupdate_automatic"] = "os_update_automatic";
  ZeltMdmDevicePolicyType2["activation_lock"] = "activation_lock";
  return ZeltMdmDevicePolicyType2;
})(ZeltMdmDevicePolicyType || {});
export const FilevaultEnabled = [
  { value: "On", label: "On" },
  { value: "Off", label: "Off" }
];
