"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import autoAnimate from "@formkit/auto-animate";
import { Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Typography } from "@v2/components/typography/typography.component";
import { FormikProvider, useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { useSWRConfig } from "swr";
import { useDebouncedCallback } from "use-debounce";
import * as yup from "yup";
import { LOGIN_ROUTE, PASSWORD_FORGOT_ROUTE } from "@/lib/routes";
import { AccountStatus } from "@/lib/users";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { CONTRACT_RECIPIENT_NOT_SET_PASSWORD_ERROR } from "@/v2/feature/auth/auth.interface";
import { performLogin, performSSOLogin } from "@/v2/feature/auth/auth.util";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { clearApiClientCache } from "@/v2/infrastructure/api-client/api-client.hook";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { spacing } from "@/v2/styles/spacing.styles";
const INVALID_CREDENTIALS_MESSAGE = "Invalid credentials";
const TOO_MANY_REQUESTS_MESSAGE = "You have tried to login too many times recently; please try again in a minute";
const DEACTIVATED_USER_MESSAGE = "Your account is currently deactivated.";
const UNACTIVATED_USER_MESSAGE = "Your account has not been activated yet, please ask your manager to send you an invite link.";
export const ENABLED_SSO_APPS = ["sso-google-workspace", "sso-okta", "sso-azure-ad"];
const ACCOUNT_STATES_TO_PUSH_SSO_USER_FOR_PASSWORD_AUTH = [
  AccountStatus.Invited,
  AccountStatus.InvitedToOnboard
];
export const AuthLoginPage = () => {
  const swrConfig = useSWRConfig();
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const { continueOnboarding, contractRecipientHasSetPassword, contractSigningRedirect, email } = routerLocation.state || {};
  const queries = new URLSearchParams(routerLocation.search);
  const resetedPassword = queries.get("reset") === "true";
  const deactivatedUser = queries.get("deactivated") === "true";
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ssoError, setSSOError] = useState(null);
  const [accountStatus, setAccountStatus] = useState(void 0);
  const [contractSignatoryPasswordNotSet, setContractSignatoryPasswordNotSet] = useState(false);
  const [ssoEnabled, setSSOEnabled] = useState(false);
  const [disableLogin, setDisableLogin] = useState(false);
  const [matchingProvider, setMatchingProvider] = useState();
  const { identifyUser } = useJune();
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);
  useEffect(() => {
    if (deactivatedUser) {
      setErrorMessage(DEACTIVATED_USER_MESSAGE);
    }
    if (contractSigningRedirect && (!contractRecipientHasSetPassword || (contractRecipientHasSetPassword == null ? void 0 : contractRecipientHasSetPassword.length) === 0)) {
      setContractSignatoryPasswordNotSet(true);
    }
  }, []);
  const forwardToOnboarding = useMemo(() => {
    return accountStatus === AccountStatus.InvitedToOnboard || continueOnboarding;
  }, [accountStatus, continueOnboarding]);
  const validationSchema = yup.object({
    username: yup.string().email("Enter a valid email address").required("Email is required"),
    password: yup.string().required("Password is required")
  });
  const formik = useFormik({
    initialValues: {
      username: continueOnboarding && email ? email : "",
      password: ""
    },
    validationSchema,
    onSubmit: (_0) => __async(void 0, [_0], function* ({ username, password }) {
      if (resetedPassword) routerHistory.replace(LOGIN_ROUTE);
      setErrorMessage("");
      setIsLoading(true);
      if (ssoEnabled) return;
      try {
        clearApiClientCache(swrConfig);
        yield performLogin(routerHistory, routerLocation, { username, password }, identifyUser, forwardToOnboarding);
      } catch (error) {
        console.error(error);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          setErrorMessage(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 422) {
          setErrorMessage(INVALID_CREDENTIALS_MESSAGE);
          return;
        }
        if (error.response && error.response.status === 429) {
          setErrorMessage(TOO_MANY_REQUESTS_MESSAGE);
          return;
        }
        setErrorMessage(`${error}`);
      } finally {
        setIsLoading(false);
      }
    })
  });
  const getSSOCheckResult = useCallback((username) => __async(void 0, null, function* () {
    try {
      const loginCheckData = yield AuthAPI.ssoCheck(username);
      return loginCheckData;
    } catch (error) {
      console.error("Error: ", error);
    }
  }), []);
  const debouncedCheckForSSO = useDebouncedCallback(
    (username) => __async(void 0, null, function* () {
      setIsLoading(true);
      if (!formik.errors.hasOwnProperty("username") && username.length > 3) {
        setSSOError(null);
        setDisableLogin(false);
        const loginCheckData = yield getSSOCheckResult(username);
        if (!loginCheckData) return;
        setAccountStatus(loginCheckData == null ? void 0 : loginCheckData.accountStatus);
        if (ACCOUNT_STATES_TO_PUSH_SSO_USER_FOR_PASSWORD_AUTH.includes(loginCheckData == null ? void 0 : loginCheckData.accountStatus)) {
          setSSOEnabled(false);
        } else if ((loginCheckData == null ? void 0 : loginCheckData.accountStatus) === AccountStatus.Active) {
          const ssoConfig = loginCheckData.ssoConfig;
          const ssoEnabledForCompany = ssoConfig.some((app) => app.enabled === true);
          const matchingProviderData = ssoConfig.find(
            (eachApp) => ENABLED_SSO_APPS.includes(eachApp.app) && eachApp.enabled
          );
          setMatchingProvider(matchingProviderData);
          setSSOEnabled(ssoEnabledForCompany);
          setSSOError(null);
        } else if ((loginCheckData == null ? void 0 : loginCheckData.accountStatus) === AccountStatus.Deactivated) {
          setSSOError(DEACTIVATED_USER_MESSAGE);
          setSSOEnabled(false);
          setDisableLogin(true);
        } else if ((loginCheckData == null ? void 0 : loginCheckData.accountStatus) === AccountStatus.Created) {
          setSSOError(UNACTIVATED_USER_MESSAGE);
          setSSOEnabled(false);
          setDisableLogin(true);
        } else {
          setSSOEnabled(false);
        }
      }
      setIsLoading(false);
    }),
    500
    // 500ms debounce time
  );
  useEffect(() => {
    debouncedCheckForSSO.callback(formik.values.username);
  }, [debouncedCheckForSSO, formik.values.username]);
  const triggerSSOLogin = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!formik.values.username) return;
      if (ssoEnabled && matchingProvider) {
        yield performSSOLogin(
          formik.values.username,
          routerHistory,
          routerLocation,
          matchingProvider,
          identifyUser,
          continueOnboarding
        );
      }
    } catch (error) {
      setSSOError(error.message);
    } finally {
      formik.resetForm();
      setIsLoading(false);
    }
  }), [continueOnboarding, formik, identifyUser, matchingProvider, routerHistory, routerLocation, ssoEnabled]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(
    AuthLayout,
    {
      title: "Log in",
      submit: formik.handleSubmit,
      onKeyDown: (e) => {
        if (e.key === "Enter") {
          if (!ssoEnabled) formik.handleSubmit(e);
          else {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      },
      children: [
        (resetedPassword || continueOnboarding) && /* @__PURE__ */ jsx(Alert, { severity: "success", sx: { my: 2 }, children: "Password already set. Please login using the password you have set or click \u201CForgot password\u201D to reset it." }),
        contractSignatoryPasswordNotSet && /* @__PURE__ */ jsx(Alert, { severity: "warning", sx: { my: 2 }, children: CONTRACT_RECIPIENT_NOT_SET_PASSWORD_ERROR }),
        /* @__PURE__ */ jsxs(Stack, { sx: { minHeight: "40px" }, ref: parent, children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Work email",
              name: "username",
              id: "username",
              value: formik.values.username,
              onChange: (e) => {
                var _a;
                formik.setFieldValue("username", (_a = e.target.value) == null ? void 0 : _a.toLowerCase());
              },
              error: formik.touched.username && Boolean(formik.errors.username),
              helperText: formik.touched.username && formik.errors.username,
              type: "email",
              autoComplete: "email",
              endAdornment: "none",
              autoFocus: true
            }
          ),
          ssoError && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "darkRed", sx: { opacity: 1 }, children: ssoError }),
          !ssoEnabled && /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.m30 }, children: [
            /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                label: "Password",
                name: "password",
                id: "password",
                value: formik.values.password,
                onChange: formik.handleChange,
                error: !!errorMessage,
                helperText: errorMessage,
                type: "password",
                autoComplete: "current-password"
              }
            ),
            /* @__PURE__ */ jsx(Stack, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "link",
                colorVariant: "textUnderline",
                onClick: (e) => {
                  routerHistory.push(PASSWORD_FORGOT_ROUTE, { email: formik.values.username });
                  e.preventDefault();
                },
                children: "Forgot password?"
              }
            ) })
          ] })
        ] }),
        /* @__PURE__ */ jsx(Stack, { sx: { marginTop: spacing.m30, width: { xs: "50%", sm: "100%", md: "100%", lg: "100%" } }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            fullWidth: true,
            disabled: isLoading || disableLogin,
            sizeVariant: "medium",
            colorVariant: "primary",
            onClick: () => __async(void 0, null, function* () {
              ssoEnabled ? yield triggerSSOLogin() : formik.handleSubmit();
            }),
            type: "button",
            children: ssoEnabled ? "Continue" : "Log in"
          }
        ) })
      ]
    }
  ) });
};
