"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class RefinancingAPI {
  static getCompaniesRefinancingData(companyIds) {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/companies/refinancing/superadmin", { params: { companyIds: companyIds.join(",") } })).data;
    });
  }
  static searchCompanyNationalId(query) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/refinancing/superadmin/search?query=${query}`)).data;
    });
  }
  static searchCompanyAddressByNationalId(nationalId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.get(`/apiv2/companies/refinancing/superadmin/address/national-id/${nationalId}`)).data) != null ? _a : null;
    });
  }
  static runCompanyCreditCheck(companyId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/credit-check`)).data;
    });
  }
  static saveCompanyDetails(companyId, nationalId, legalName, address) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/refinancing/superadmin/${companyId}`, { nationalId, legalName, address });
    });
  }
  static createCompanyRepaymentAccount(companyId, data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/account`, data);
    });
  }
  static cancelRefinancingPlanAsSuperAdmin(companyId, orderId, refinancingPlanId, data) {
    return __async(this, null, function* () {
      yield axios.post(
        `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${refinancingPlanId}/cancel`,
        data
      );
    });
  }
  static createOrderRepaymentPlan(companyId, orderId, data) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans`, data)) == null ? void 0 : _a.data;
    });
  }
  static deleteOrderRepaymentPlan(companyId, orderId, planId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${planId}`);
    });
  }
  static getCompaniesRepaymentPlansForOrders(companyIds, orderIds) {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/companies/refinancing/superadmin/plans", {
        params: {
          companyIds: companyIds.join(","),
          orderIds: orderIds.join(",")
        }
      })).data;
    });
  }
  static updateAndReturnRepaymentPlanForOrder(companyId, refinancingPlanId, orderId) {
    return __async(this, null, function* () {
      return (yield axios.put(
        `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${refinancingPlanId}/status`
      )).data;
    });
  }
  static fulfilRepaymentPlanForOrder(companyId, refinancingPlanId, orderId) {
    return __async(this, null, function* () {
      return (yield axios.put(
        `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/${refinancingPlanId}/fulfil`
      )).data;
    });
  }
  static previewOrderRepaymentPlan(companyId, orderId, data) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans/preview`, data)) == null ? void 0 : _a.data;
    });
  }
}
export class RefinancingEndpoints {
  static getOrderRepaymentPlan(companyId, orderId) {
    return {
      url: `/apiv2/companies/refinancing/superadmin/${companyId}/orders/${orderId}/plans`
    };
  }
}
