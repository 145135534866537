"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { styled, Switch } from "@mui/material";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const StyledSwitch = styled(Switch)(() => ({
  width: "38px",
  height: "20px",
  padding: "0px",
  borderRadius: radius.br15,
  "& span.MuiSwitch-switchBase.MuiSwitch-colorPrimary.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root ": {
    padding: "0px",
    "& .Mui-checked": {
      color: themeColors.DarkGrey,
      left: "-2px"
    }
  },
  "& span.MuiSwitch-thumb": {
    width: "16px",
    height: "16px",
    margin: spacing.m2
  },
  "& span.MuiButtonBase-root.MuiSwitch-switchBase": {
    color: themeColors.white
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: themeColors.GreyMiddle,
    opacity: 1
  },
  "& span.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked": {
    color: themeColors.white,
    left: "-2px"
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: themeColors.DarkGrey,
    opacity: 1
  }
}));
export const SwitchComponent = (props) => {
  return /* @__PURE__ */ jsx(StyledSwitch, __spreadValues({}, props));
};
