"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  INVOICES_COMPANY_EDIT_INVOICE_ROUTE,
  INVOICES_COMPANY_NEW_INVOICE_ROUTE,
  INVOICES_COMPANY_OVERVIEW_ROUTE,
  INVOICES_COMPANY_ROUTE
} from "@/lib/routes";
import { InvoicesPage } from "@/v2/feature/payments/pages/invoice.page";
import { NewInvoicePage } from "@/v2/feature/payments/pages/new-invoice.page";
export const InvoiceCompanyRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["invoices:all"], path: INVOICES_COMPANY_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(InvoicesPage, { reach: "company" }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["invoices:all"], path: INVOICES_COMPANY_NEW_INVOICE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(NewInvoicePage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["invoices:all"], path: INVOICES_COMPANY_EDIT_INVOICE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(NewInvoicePage, { reach: "company", editMode: true }) }),
    /* @__PURE__ */ jsx(Redirect, { to: INVOICES_COMPANY_OVERVIEW_ROUTE, from: INVOICES_COMPANY_ROUTE })
  ] });
};
