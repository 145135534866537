"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { PolicyNotificationsEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-notifications-edit-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useSlackHook } from "@/api-client/slack.hook";
export const AbsencePolicyNotificationsSection = ({ absencePolicy, refresh }) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const { slackChannels } = useSlackHook();
  const remindersChannels = useMemo(
    () => [absencePolicy.remindersSettings.slack ? polyglot.t("NotificationsSectionReadonly.slackChannel") : null].filter(Boolean).join(", "),
    [absencePolicy == null ? void 0 : absencePolicy.remindersSettings, polyglot]
  );
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyNotificationsSection.notifications"),
      onEdit: () => setIsEditDrawerOpen(true),
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyNotificationsSection.notifications"),
                    value: absencePolicy.notificationsEnabled ? polyglot.t("AbsencePolicyNotificationsSection.enabled") : polyglot.t("AbsencePolicyNotificationsSection.disabled")
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyNotificationsSection.reminders"),
                    value: remindersChannels,
                    hidden: remindersChannels.length === 0
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("NotificationsSectionReadonly.slackchannels"),
                    value: absencePolicy.remindersSettings.slackChannels.map((channelId) => {
                      var _a, _b;
                      return (_b = (_a = slackChannels.find((channel) => channel.id === channelId)) == null ? void 0 : _a.name) != null ? _b : "???";
                    }).join(", "),
                    hidden: !absencePolicy.remindersSettings.slack
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyNotificationsEditDrawer,
          {
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            absencePolicy,
            refresh,
            slackChannels
          }
        )
      ]
    }
  );
};
