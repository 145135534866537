"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { MultiUserAvatar } from "@v2/components/theme-components/multi-user-avatar.component";
import { ApproversList } from "@v2/feature/approval-rule/components/approvers-list.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { getLinkToUploadedFile } from "@/api-client/routes";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DocumentIcon } from "@/images/documents/PinnedBlack.svg";
import { ReactComponent as PendingIcon } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as RejectedIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as ApprovedIcon } from "@/images/side-bar-icons/ok-green.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ApprovalButtonSet } from "@/v2/components/approval-button-set.component";
import { stringToAttachmentComponentValue } from "@/v2/components/forms/attachment.component";
import { InlineLink } from "@/v2/components/inline-link.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { RequestFormStatus } from "@/v2/feature/requests/features/request-forms/request-forms.interface";
import { RequestFormsAPI, RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatMediumDate } from "@/v2/util/date-format.util";
const RequestFormActionItemDrawerContent = ({
  submittedForm,
  close
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const statusComponent = useCallback(
    (status) => {
      return {
        pending: () => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(PendingIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Grey })),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("RequestFormActionItemDrawerContent.pending") })
        ] }),
        approved: () => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(ApprovedIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.GreenMiddle })),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("RequestFormActionItemDrawerContent.approved") })
        ] }),
        rejected: () => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(RejectedIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.RedDark })),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("RequestFormActionItemDrawerContent.rejected") })
        ] })
      }[status]();
    },
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: submittedForm.name }),
    /* @__PURE__ */ jsxs(Stack, { gap: spacing.g3, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("RequestFormActionItemDrawerContent.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: submittedForm.userId })
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { gap: spacing.g15 }, children: submittedForm.layout.items.map((item) => {
      const formValue = submittedForm.values[item.id];
      return /* @__PURE__ */ jsxs(Stack, { gap: spacing.g3, children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Grey", children: [
          item.label,
          item.required && ` (Required)`
        ] }),
        item.type === "attachment" && (() => {
          const value = stringToAttachmentComponentValue(formValue);
          if (!value) return " ";
          return /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
            value.type === "file" && /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
            (value.type === "link" || value.type === "file") && /* @__PURE__ */ jsx(
              InlineLink,
              {
                href: value.type === "link" ? value.name : getLinkToUploadedFile(value.uuid),
                title: value.name,
                style: __spreadProps(__spreadValues({}, themeFonts.title4), {
                  color: themeColors.DarkGrey,
                  textDecoration: "underline"
                }),
                openInNewWindow: true
              }
            )
          ] });
        })(),
        item.type === "date" && /* @__PURE__ */ jsx(Typography, { variant: "title4", children: `${formValue ? formatMediumDate(formValue) : ""}` }),
        item.type === "select" && /* @__PURE__ */ jsx(Typography, { variant: "title4", children: `${formValue != null ? formValue : ""}` }),
        item.type === "text" && /* @__PURE__ */ jsx(Typography, { variant: "title4", dangerouslySetInnerHTML: { __html: formValue != null ? formValue : "" } })
      ] }, item.id);
    }) }),
    /* @__PURE__ */ jsxs(Stack, { gap: spacing.g3, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("RequestFormActionItemDrawerContent.status") }),
      statusComponent(submittedForm.status)
    ] }),
    submittedForm.approvedByIds && submittedForm.approvedByIds.length > 0 && /* @__PURE__ */ jsxs(Stack, { gap: spacing.g3, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("General.approvedBy") }),
      /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: submittedForm.approvedByIds })
    ] }),
    /* @__PURE__ */ jsx(ApproversList, { approverSteps: submittedForm.approverSteps, labelVariant: "caption" }),
    submittedForm.status === RequestFormStatus.Pending && /* @__PURE__ */ jsx(
      ApprovalButtonSet,
      {
        sx: buttonBoxDrawerSx,
        requestForm: submittedForm,
        onApprove: () => __async(void 0, null, function* () {
          yield RequestFormsAPI.setSubmittedFormStatus(submittedForm.id, {
            status: RequestFormStatus.Approved
          }).then(
            () => {
              showMessage(polyglot.t("RequestFormActionItemDrawerContent.successMessages.approve"), "success");
              close();
            },
            (error) => showMessage(
              polyglot.t("RequestFormActionItemDrawerContent.errorMessages.approve", {
                errorMessage: nestErrorMessage(error)
              }),
              "error"
            )
          );
        }),
        onReject: (rejectionNotes) => __async(void 0, null, function* () {
          yield RequestFormsAPI.setSubmittedFormStatus(submittedForm.id, {
            status: RequestFormStatus.Rejected,
            rejectionNotes
          }).then(
            () => {
              showMessage(polyglot.t("RequestFormActionItemDrawerContent.successMessages.reject"), "success");
              close();
            },
            (error) => showMessage(`Request rejection failed. ${nestErrorMessage(error)}`, "error")
          );
        }),
        onDelete: () => __async(void 0, null, function* () {
          yield RequestFormsAPI.deleteSubmittedForm(submittedForm.id).then(
            () => {
              showMessage(polyglot.t("RequestFormActionItemDrawerContent.successMessages.delete"), "success");
              close();
            },
            (error) => showMessage(
              polyglot.t("RequestFormActionItemDrawerContent.errorMessages.delete", {
                errorMessage: nestErrorMessage(error)
              }),
              "error"
            )
          );
        })
      }
    )
  ] });
};
export const RequestFormActionItem = ({
  requestFormRow,
  refresh,
  afterClose
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage] = useMessage();
  const { data: submittedForm, error } = useApiClient(RequestFormsEndpoints.getSubmittedById(requestFormRow.id), {
    suspense: false
  });
  useEffect(() => {
    var _a, _b;
    if (submittedForm) {
      setIsOpen(true);
    } else if (error) {
      showMessage(
        polyglot.t("RequestFormActionItemDrawerContent.errorMessages.load", {
          errorMessage: (_b = (_a = error.data) == null ? void 0 : _a.message) != null ? _b : error.statusText
        }),
        "error"
      );
    }
  }, [polyglot, error, showMessage, submittedForm]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: submittedForm ? /* @__PURE__ */ jsx(
    RequestFormActionItemDrawerContent,
    {
      submittedForm,
      close: () => __async(void 0, null, function* () {
        setIsOpen(false);
        yield refresh();
      })
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
