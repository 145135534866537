"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { ATTENDANCE_COMPANY_REQUESTS_ROUTE } from "@/lib/routes";
import { AttendanceCompanyView } from "@/v2/feature/attendance/company/pages/attendance-company-requests/attendance-company-view.page";
export const AttendanceCompanyRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:all"], path: ATTENDANCE_COMPANY_REQUESTS_ROUTE, children: /* @__PURE__ */ jsx(AttendanceCompanyView, { view: "company" }) }),
    /* @__PURE__ */ jsx(Redirect, { to: ATTENDANCE_COMPANY_REQUESTS_ROUTE })
  ] });
};
