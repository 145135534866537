"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { spacing } from "@/v2/styles/spacing.styles";
export const LockDeviceDrawer = ({
  isOpen,
  onClose,
  devicePossession,
  setIsOpen,
  isMobileAppleDevice
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(LockDeviceDrawerContent, { devicePossession, isMobileAppleDevice }) });
};
const LockDeviceDrawerContent = ({
  devicePossession,
  isMobileAppleDevice
}) => {
  const [confirmationText, setConfirmationText] = useState("");
  const [showLockPinModal, setShowLockPinModal] = useState(false);
  const [isMobileDevice] = useState(isMobileAppleDevice);
  const [showMessage] = useMessage();
  const macOsLockMessage = "Mobile device management (MDM) administrators can lock a Mac with a six-digit personal identification number\n            (PIN) and include a short message. After the command has been sent to the device, the device restarts and\n            the user can see the message. The user can't restart into macOS or log in any other way until the PIN has\n            been entered and validated by the Mac.";
  const mobileDeviceLockMessage = "Mobile device management (MDM) administrators can lock an iPhone or IPad include a short message. After the\n            command has been sent to the device, the device locks and logs out from the current iCloud account. The user\n            can remove the lock using either the device's regular passcode or FaceID.";
  const hexnodeDeviceLockMessage = "Mobile device management (MDM) administrators can lock a device with a six-digit personal identification number (PIN) and include a short message. After the\n command has been sent to the device, it restarts and the user can see the message. The user \ncan't restart the device or log in any other way until the PIN has been entered and validated by the system.";
  const getLockMessage = () => {
    var _a;
    if (!((_a = devicePossession.device) == null ? void 0 : _a.inHouseMdm)) {
      return hexnodeDeviceLockMessage;
    } else if (isMobileDevice) {
      return mobileDeviceLockMessage;
    } else {
      return macOsLockMessage;
    }
  };
  return /* @__PURE__ */ jsx(Fragment, { children: showLockPinModal ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Unlock PIN" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.m5 }, children: "Remember your unlock PIN to unlock your device" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "4 5 9 8 4 6" })
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Lock device" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Red", children: getLockMessage() }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "confirm",
        label: "",
        placeholder: "Type \u201CConfirm\u201D here to lock device",
        fullWidth: true,
        value: confirmationText,
        type: "text",
        onChange: (event) => setConfirmationText(event.target.value),
        clearText: () => setConfirmationText("")
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        disabled: confirmationText !== "Confirm",
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "secondary",
        onClick: () => __async(void 0, null, function* () {
          try {
            if (devicePossession && devicePossession.id)
              yield DeviceAPI.lockDevice(
                devicePossession.id,
                isMobileDevice ? mobileDeviceLockMessage : macOsLockMessage
              );
            showMessage("Action launched successfully", "success");
            if (!isMobileDevice) {
              setShowLockPinModal(true);
            }
          } catch (error) {
            showMessage("The launch of the action failed", "error");
          }
        }),
        children: "Submit"
      }
    ) })
  ] }) });
};
