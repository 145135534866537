"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { SwitchButton } from "@v2/components/split-switch-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
export const ColumnsFiltersSwitch = ({ view, setView }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        height: "30px",
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      },
      children: [
        /* @__PURE__ */ jsx(
          SwitchButton,
          {
            isActive: view === "columns-list",
            color: themeColors.DarkGrey,
            action: () => setView("columns-list"),
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: {
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  color: view === "columns-list" ? themeColors.white : themeColors.DarkGrey
                },
                className: view === "columns-list" ? "tnm-switch-active-text" : "tnm-switch-text",
                children: "Columns"
              }
            )
          },
          "columns"
        ),
        /* @__PURE__ */ jsx(
          SwitchButton,
          {
            isActive: view === "filters-list",
            color: themeColors.DarkGrey,
            action: () => setView("filters-list"),
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: {
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  color: view === "filters-list" ? themeColors.white : themeColors.DarkGrey
                },
                className: view === "filters-list" ? "tnm-switch-active-text" : "tnm-switch-text",
                children: "Filters"
              }
            )
          },
          "filters"
        ),
        /* @__PURE__ */ jsx(
          SwitchButton,
          {
            isActive: view === "settings",
            color: themeColors.DarkGrey,
            action: () => setView("settings"),
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: {
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  color: view === "settings" ? themeColors.white : themeColors.DarkGrey
                },
                className: view === "settings" ? "tnm-switch-active-text" : "tnm-switch-text",
                children: "Settings"
              }
            )
          },
          "settings"
        )
      ]
    }
  );
};
