"use strict";
import { LocalDate } from "@v2/util/local-date";
import axios from "axios";
export function useSetLocalTimeHeaderInterceptor() {
  axios.interceptors.request.use((config) => {
    config.headers["X-Now-String"] = new LocalDate().toDateTimeString();
    config.headers["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });
}
