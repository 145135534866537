"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useContext } from "react";
import { CustomBenefitSettingsRouter } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/custom-benefit-settings.router";
import { InsuranceSettingsRouter } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/insurance-settings.router";
import { PensionSettingsRouter } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pension-settings.router";
import { CustomBenefitCategory } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { InsuranceType } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@v2/styles/colors.styles";
import { Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import useScopes from "@/hooks/scopes.hook";
import { SETTINGS_BENEFITS_DETAILS_ROUTE, SETTINGS_BENEFITS_ROUTE } from "@/lib/routes";
export const BenefitsSettingsDetailsRouter = () => {
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const context = getScopesContext({ userId: currentUser.userId });
  const { companyConfig } = useCompanyConfigState();
  const params = useParams();
  const category = params.category;
  const id = Number(params.id);
  const productType = params.productType;
  const isStdPension = productType === "standard" && category === "Pension";
  const isStdInsurance = productType === "standard" && category === InsuranceType.Health;
  const isCustomType = !isStdPension && !isStdInsurance && Object.values(CustomBenefitCategory).includes(category);
  const allowAccessToDetails = (isStdPension || isStdInsurance || isCustomType) && (!isStdPension || companyConfig.inPayroll);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    allowAccessToDetails && /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: isStdPension ? ["pension:all"] : ["insurance:all"],
        path: SETTINGS_BENEFITS_DETAILS_ROUTE,
        children: /* @__PURE__ */ jsx(
          Suspense,
          {
            fallback: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                width: "90%",
                height: "90vh",
                sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
              }
            ),
            children: isStdPension ? /* @__PURE__ */ jsx(PensionSettingsRouter, { type: category, id }) : isStdInsurance ? /* @__PURE__ */ jsx(InsuranceSettingsRouter, { category, id }) : isCustomType ? /* @__PURE__ */ jsx(CustomBenefitSettingsRouter, { category, id }) : null
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_BENEFITS_ROUTE })
  ] });
};
