"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { DocumentsCompanyPage } from "@v2/feature/documents/documents-company.page";
import { Route, Switch } from "react-router-dom";
import { DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE, DOCUMENTS_COMPANY_ROUTE } from "@/lib/routes";
import { BulkUploadProvider } from "@/v2/feature/documents/bulk-upload-documents.context";
import { DocumentBulkUploadFlow } from "@/v2/feature/documents/components/document-bulk-upload-flow.component";
export const DocumentsCompanyRouter = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE, exact: true, children: /* @__PURE__ */ jsx(BulkUploadProvider, { children: /* @__PURE__ */ jsx(DocumentBulkUploadFlow, {}) }) }),
  /* @__PURE__ */ jsx(Route, { path: DOCUMENTS_COMPANY_ROUTE, exact: true, children: /* @__PURE__ */ jsx(DocumentsCompanyPage, { reach: "company" }) })
] });
