"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { AuthAPI } from "@v2/feature/auth/auth.api";
import { CompanyConfigAPI, CompanyConfigEndpoints } from "@v2/feature/company-config/company-config.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CompanySettingSectionHeader } from "@/v2/feature/company/company-settings/features/components/company-settings/entity-details/company-settings-section-header.component";
export const NotificationsTestingMode = () => {
  const { polyglot } = usePolyglot();
  const { data: companyConfig, mutate: refresh } = useApiClient(
    CompanyConfigEndpoints.getCompanyConfig(),
    { suspense: false }
  );
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [, dispatch] = useContext(GlobalContext);
  const refreshTestModeSettings = useCallback(() => __async(void 0, null, function* () {
    if (refresh) yield refresh();
  }), [refresh]);
  useEffect(() => {
    refreshTestModeSettings();
  }, [refreshTestModeSettings]);
  const enableDisableTestMode = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield CompanyConfigAPI.disableOrEnableTestMode();
      const [authMe] = yield Promise.all([AuthAPI.getAuthMe(false), refreshTestModeSettings()]);
      dispatch({
        type: GlobalStateActions.UPDATE_TEST_MODE,
        payload: authMe.isTestModeEnabled
      });
    } catch (error) {
      showMessage(
        `${polyglot.t("NotificationsTestingMode.errorMessages.notUpdated")}. ${nestErrorMessage(error)}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [dispatch, refreshTestModeSettings, showMessage, polyglot]);
  return /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "770px", width: "100%" }, children: /* @__PURE__ */ jsx(
    CompanySettingSectionHeader,
    {
      title: polyglot.t("NotificationsTestingMode.title"),
      header: polyglot.t("NotificationsTestingMode.header"),
      subheader: polyglot.t("NotificationsTestingMode.subheader"),
      buttonName: (companyConfig == null ? void 0 : companyConfig.disableEmails) ? polyglot.t("NotificationsTestingMode.disable") : polyglot.t("NotificationsTestingMode.enable"),
      onButtonClick: enableDisableTestMode,
      buttonLoading: loading
    }
  ) });
};
