"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { AbsenceCompanyBalancesPage } from "@v2/feature/absence/company/policies/pages/absence-company-balances.page";
import { AbsenceRequestsPage } from "@v2/feature/absence/company/policies/pages/absence-requests.page";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { ABSENCE_COMPANY_BALANCES_ROUTE, ABSENCE_COMPANY_REQUESTS_ROUTE, ABSENCE_COMPANY_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
export const AbsenceCompanyRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { data: absencePolicies, isLoading } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), {
    suspense: false
  });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:all"], path: ABSENCE_COMPANY_REQUESTS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
      AbsenceRequestsPage,
      {
        reach: "company",
        absencePolicies: absencePolicies != null ? absencePolicies : [],
        absencePoliciesLoading: Boolean(isLoading)
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:all"], path: ABSENCE_COMPANY_BALANCES_ROUTE, exact: true, children: /* @__PURE__ */ jsx(AbsenceCompanyBalancesPage, { absencePolicies: absencePolicies != null ? absencePolicies : [] }) }),
    checkScopes(globalState.user, ["absence:all"]) ? /* @__PURE__ */ jsx(Redirect, { to: ABSENCE_COMPANY_REQUESTS_ROUTE }) : /* @__PURE__ */ jsx(Redirect, { to: ABSENCE_COMPANY_ROUTE })
  ] });
};
