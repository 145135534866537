"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class HolidayCalendarAPI {
  static createHolidayCalendar(createBody) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post("/apiv2/holiday-calendars", createBody)) == null ? void 0 : _a.data;
    });
  }
  static updateHolidayCalendarGeneral(calendarId, update) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/holiday-calendars/${calendarId}/general`, update)) == null ? void 0 : _a.data;
    });
  }
  static updateCalendarHoliday(calendarId, holidayId, update) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/holiday-calendars/${calendarId}/holidays/${holidayId}`, update)) == null ? void 0 : _a.data;
    });
  }
  static addHolidayToCalendar(calendarId, create) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/holiday-calendars/${calendarId}/holidays`, create)) == null ? void 0 : _a.data;
    });
  }
  static deleteCalendarHoliday(calendarId, holidayId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/holiday-calendars/${calendarId}/holidays/${holidayId}`);
    });
  }
  static deleteHolidayCalendar(calendarId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/holiday-calendars/${calendarId}`);
    });
  }
}
export class HolidayCalendarEndpoints {
  static getHolidayCalendars(includeHolidays = false) {
    return {
      url: `/apiv2/holiday-calendars?includeHolidays=${includeHolidays}`
    };
  }
  static getHolidayCalendarById(calendarId, includeHolidays = false) {
    return {
      url: `/apiv2/holiday-calendars/${calendarId}?includeHolidays=${includeHolidays}`
    };
  }
}
