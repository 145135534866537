"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import {
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION
} from "@/v2/feature/app-integration/app-integration.interface";
import { themeColors } from "@/v2/styles/colors.styles";
export const getStatusColor = (status) => {
  switch (status) {
    case "Active":
      return themeColors.green;
    case "No access":
      return "#FFEB7B";
    case "Suspended":
      return "#FF7373";
    case "Invited":
      return "blue";
  }
};
export const emailOptionsForUserCreation = (appStub, preselectedUser, personalEmailForUser, cachedUsers) => {
  var _a;
  if (APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub)) {
    const emailOptions = [];
    const matchingCachedUser = cachedUsers.find((cu) => cu.userId === preselectedUser.userId);
    (_a = preselectedUser == null ? void 0 : preselectedUser.emails) == null ? void 0 : _a.filter((emailRecord) => emailRecord.status === "No access").forEach((record) => {
      var _a2, _b, _c, _d, _e, _f;
      if (!emailOptions.some((option) => option.value === record.email) && ((_a2 = record == null ? void 0 : record.email) == null ? void 0 : _a2.length) > 0 && record.email === (matchingCachedUser == null ? void 0 : matchingCachedUser.emailAddress)) {
        emailOptions.push({
          label: record.email ? `Work email (${record.email})` : void 0,
          value: (_b = record.email) != null ? _b : void 0
        });
      }
      if (!emailOptions.some((option) => option.value === record.email) && ((_c = record == null ? void 0 : record.email) == null ? void 0 : _c.length) > 0 && record.email === personalEmailForUser) {
        emailOptions.push({
          label: record.email ? `Personal email (${record.email})` : void 0,
          value: (_d = record.email) != null ? _d : void 0
        });
      }
      if (!emailOptions.some((option) => option.value === record.personalEmail) && ((_e = record == null ? void 0 : record.personalEmail) == null ? void 0 : _e.length) > 0) {
        emailOptions.push({
          label: record.personalEmail ? `Personal email (${record.personalEmail})` : void 0,
          value: (_f = record.personalEmail) != null ? _f : void 0
        });
      }
    });
    return emailOptions.filter((option) => option.value);
  }
};
export const canRequestAccess = (stub) => {
  const APPS_WITH_REQUEST_ACCESS = ["slack", "pipedrive", "zoom", "google-workspace", "notion", "intercom", "github"];
  return APPS_WITH_REQUEST_ACCESS.includes(stub);
};
export const userHasAppAccess = (userList, userEmail) => {
  return userList.some(
    (eachUser) => eachUser.emails && eachUser.emails.length > 0 && (eachUser == null ? void 0 : eachUser.emails[0].email) === userEmail && (eachUser.userStatus === "Active" || eachUser.userStatus === "Invited" || eachUser.userStatus === "Suspended")
  );
};
export const getUsersWithoutAccess = (teamList) => {
  return teamList ? teamList.filter((u) => u.userStatus && !["Active", "Invited", "Suspended"].includes(u.userStatus)) : [];
};
export const isAppOwner = (userId, app) => {
  return app ? userId === app.appOwnerId : false;
};
export const getAppOwnerId = (appStub) => __async(void 0, null, function* () {
  const appInstance = yield AppIntegrationAPI.getAppIntegrationDetails(appStub);
  return appInstance.appOwnerId;
});
export const hasActiveEmailForAccount = (user) => {
  var _a;
  if (user && user.emails && ((_a = user == null ? void 0 : user.emails) == null ? void 0 : _a.length) > 0)
    return user.emails.find((eachEmail) => eachEmail.status === "Active");
  return void 0;
};
export const getEmailAddressForMissingId = (user) => {
  if (user.emails && user.emails.length > 0) return user.emails[0].email;
};
export const APPS_WITHOUT_DELETE_OPTION = ["notion", "github", "hubspot"];
export const APPS_NEEDING_EMAIL_FOR_DELETION = ["lastpass"];
export const APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION = ["jira"];
export const getActionNameForApp = (actionName, appStub, fallback) => {
  switch (actionName) {
    case "Delete":
      if (APPS_WITHOUT_DELETE_OPTION.includes(appStub)) return "Remove from Organisation";
      if (!APPS_WITHOUT_DELETE_OPTION.includes(appStub)) return "Delete";
      break;
    case "Suspend":
      if (appStub === "pipedrive") return "Deactivate";
      break;
    default:
      return fallback != null ? fallback : actionName;
  }
  return fallback != null ? fallback : actionName;
};
export const getGroupListForUser = (userIdentifier, groupMemberships) => {
  return groupMemberships.filter((eachGroup) => eachGroup.members.hasOwnProperty(userIdentifier)).map((eachGroup) => eachGroup.name).join(", ");
};
export const seriesDataExists = (kpi) => {
  return kpi && kpi.series && kpi.series.some((d) => d.data && d.data.length > 0);
};
