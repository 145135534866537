"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ButtonNameLoader } from "@v2/components/button-name-loader.component";
import { AppDetailsAPI } from "@v2/feature/app-integration/features/app-details/app-details.api";
import { primarySmallBtn, secondarySmallBtn } from "@/v2/styles/buttons.styles";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: 1
};
export const AppDetailConfigurationModal = ({
  open,
  handleClose,
  app,
  appStub,
  refresh
}) => {
  var _a;
  const [configuration, setConfiguration] = useState(void 0);
  const [saveLoading, setSaveLoading] = useState(false);
  function onSaveConfiguration() {
    return __async(this, null, function* () {
      setSaveLoading(true);
      if (configuration) {
        yield AppDetailsAPI.saveIntegrationConfiguration(appStub, configuration);
        handleClose();
        refresh();
      }
      setSaveLoading(false);
    });
  }
  function isConfigurationValid() {
    if (!(app == null ? void 0 : app.requiredConfiguration)) return false;
    const isInvalid = app == null ? void 0 : app.requiredConfiguration.some((config) => {
      if (!configuration || !config) return true;
      const propConfig = configuration[config.name];
      return !propConfig || config.pattern && !propConfig.match(config.pattern);
    });
    return !isInvalid;
  }
  return /* @__PURE__ */ jsx(
    Modal,
    {
      open,
      onClose: handleClose,
      "aria-labelledby": "modal-modal-title",
      "aria-describedby": "modal-modal-description",
      children: /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, style), children: [
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "h6", children: "Please add your organisation name" }) }),
        /* @__PURE__ */ jsx("div", { children: (_a = app == null ? void 0 : app.requiredConfiguration) == null ? void 0 : _a.map((configItem) => /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
            TextField,
            {
              name: configItem.name,
              onChange: (event) => {
                setConfiguration(__spreadProps(__spreadValues({}, configuration), {
                  [event.target.name]: event.target.value
                }));
              },
              placeholder: configItem.label,
              InputProps: {
                inputProps: {
                  type: "text",
                  min: 1,
                  max: 39
                }
              },
              size: "small",
              sx: { my: 2 },
              fullWidth: true
            }
          ) }, configItem.name),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 2 }, children: [
            /* @__PURE__ */ jsx(
              Button,
              {
                sx: primarySmallBtn,
                onClick: (event) => __async(void 0, null, function* () {
                  if (event) event.preventDefault();
                  yield onSaveConfiguration();
                }),
                disabled: !isConfigurationValid(),
                children: /* @__PURE__ */ jsx(ButtonNameLoader, { name: "Save configuration", loading: saveLoading })
              }
            ),
            /* @__PURE__ */ jsx(Button, { sx: secondarySmallBtn, onClick: () => handleClose(), children: "Cancel" })
          ] })
        ] })) })
      ] })
    }
  );
};
