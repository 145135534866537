"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI, AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import { getNewPayItemValidationSchema } from "@/component/dashboard/userDetails/pay-items/components/add-new-pay-item-drawer.component";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const OffboardingAbsenceAddToPayrollDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  refresh,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: absencePolicy ? /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsx(
        OffboardingAbsenceAddToPayrollDrawerContent,
        {
          userId,
          absencePolicy,
          refresh,
          setIsOpen
        }
      )
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
const OffboardingAbsenceAddToPayrollDrawerContent = ({
  userId,
  absencePolicy,
  refresh,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { data: oneOffPaymentToBeCreated } = useApiClient(
    absencePolicy ? AbsenceEndpoints.getUserAbsenceOneOffPaymentToBeCreated(userId, absencePolicy.id) : null,
    {
      suspense: true
    }
  );
  const [loading, setLoading] = useState(false);
  const addToPayroll = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!absencePolicy) return;
      try {
        setLoading(true);
        const payItem = {
          date: values.date,
          amount: Number(values.amount),
          multiplier: 1,
          description: values.description,
          payCode: values.payCode
        };
        yield AbsenceAPI.addLeaverPolicyBalanceToPayroll(userId, absencePolicy.id, payItem);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setLoading(false);
    }),
    [absencePolicy, userId, showMessage, polyglot, refresh, setIsOpen]
  );
  const formik = useFormik({
    initialValues: {
      date: (_a = oneOffPaymentToBeCreated == null ? void 0 : oneOffPaymentToBeCreated.date) != null ? _a : new LocalDate().toDateString(),
      amount: (_b = oneOffPaymentToBeCreated == null ? void 0 : oneOffPaymentToBeCreated.amount) != null ? _b : 0,
      multiplier: (_c = oneOffPaymentToBeCreated == null ? void 0 : oneOffPaymentToBeCreated.multiplier) != null ? _c : 1,
      description: (_d = oneOffPaymentToBeCreated == null ? void 0 : oneOffPaymentToBeCreated.description) != null ? _d : "",
      payCode: (_e = oneOffPaymentToBeCreated == null ? void 0 : oneOffPaymentToBeCreated.payCode) != null ? _e : ""
    },
    validationSchema: getNewPayItemValidationSchema(polyglot),
    onSubmit: addToPayroll
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PayItemModule.addOneOffPayItem") }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "date",
        label: polyglot.t("General.date"),
        onChange: (value) => {
          formik.setFieldValue("date", value);
        },
        value: formik.values.date,
        error: formik.touched.date && !!formik.errors.date,
        helperText: formik.touched.date && formik.errors.date
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "payCode",
        label: polyglot.t("PayItemModule.payCode"),
        value: formik.values.payCode,
        onChange: formik.handleChange,
        error: formik.touched.payCode && !!formik.errors.payCode,
        helperText: (_f = formik.touched.payCode && formik.errors.payCode) != null ? _f : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: polyglot.t("PayItemModule.amount"),
        value: formik.values.amount,
        onChange: formik.handleChange,
        error: formik.touched.amount && !!formik.errors.amount,
        helperText: (_g = formik.touched.amount && formik.errors.amount) != null ? _g : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "description",
        label: polyglot.t("General.description"),
        multiline: true,
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: (_h = formik.touched.description && formik.errors.description) != null ? _h : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.add"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
