"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ReactComponent as Goals } from "@/images/app-icons/Goals.svg";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const flexContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: spacing.s2,
  justifyContent: "space-between"
};
export const responsiveCardStyle = {
  flex: "1 1 100%",
  maxWidth: "50%",
  "@media (max-width: 768px)": {
    flex: "1 1 100%"
  }
};
export var ContentItemType = /* @__PURE__ */ ((ContentItemType2) => {
  ContentItemType2["text"] = "text";
  ContentItemType2["chip"] = "chip";
  ContentItemType2["component"] = "component";
  return ContentItemType2;
})(ContentItemType || {});
export const SettingsItemCard = ({
  title,
  contentItemsSets,
  boxWidth,
  boxAction,
  boxSx,
  boxActionDisabled,
  description,
  advanceActionOptions,
  advanceActionHidden,
  loading
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = useState(null);
  return loading ? loadingState() : /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues({
        width: boxWidth != null ? boxWidth : "600px",
        borderRadius: radius.br10,
        border: borders.middle,
        padding: spacing.s2,
        cursor: boxAction && !boxActionDisabled ? "pointer" : "default",
        ":hover": boxActionDisabled ? void 0 : { bgcolor: themeColors.TableHover },
        opacity: boxActionDisabled ? 0.5 : 1,
        bgcolor: boxActionDisabled ? themeColors.GreyHover : void 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: spacing.s3,
        boxSizing: "border-box"
      }, boxSx),
      onClick: boxActionDisabled ? void 0 : boxAction,
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }, children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: { display: "flex", flexDirection: "column", gap: spacing.sm, minHeight: description ? "64px" : "0px" },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
                /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: description })
              ]
            }
          ),
          advanceActionOptions && advanceActionOptions.length && !advanceActionHidden && (advanceActionOptions.length === 1 ? /* @__PURE__ */ jsx(
            IconButton,
            {
              colorVariant: "secondary",
              sizeVariant: "small",
              onClick: (event) => {
                setAnchorEl(event.currentTarget);
                event.stopPropagation();
              },
              children: ((_a = advanceActionOptions[0]) == null ? void 0 : _a.icon) || /* @__PURE__ */ jsx(Goals, __spreadValues({}, iconSize))
            }
          ) : /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: advanceActionOptions,
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
              }
            }
          ))
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { marginTop: "auto" }, children: contentItemsSets && contentItemsSets.length && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: "5px", alignItems: "center", flexWrap: "wrap" }, children: contentItemsSets.map((item, index) => {
          switch (item.type) {
            case "chip" /* chip */: {
              return /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  name: item.name,
                  showTooltip: true,
                  textColor: item.textColor,
                  backgroundColor: item.backgroundColor,
                  border: item.border,
                  style: {
                    display: item.name ? "inline-flex" : "none",
                    maxWidth: "120px"
                  },
                  textSx: {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "inherit",
                    textAlign: "center",
                    display: "block",
                    overflow: "hidden"
                  }
                },
                `chip-${index}`
              );
            }
            case "text" /* text */: {
              return /* @__PURE__ */ jsx(Typography, { color: item.textColor, variant: item.textVariant, children: item.name }, `text-${index}`);
            }
            case "component" /* component */:
              return item.contentComponent;
            default:
              return null;
          }
        }) }) }),
        /* @__PURE__ */ jsx(
          NotificationModal,
          {
            isOpen: Boolean(anchorEl),
            onClose: () => setAnchorEl(null),
            anchorEl,
            takeAction: () => __async(void 0, null, function* () {
              var _a2;
              if (advanceActionOptions) {
                (_a2 = advanceActionOptions[0]) == null ? void 0 : _a2.handler();
              }
            }),
            message: "Are you sure you want to complete this action",
            callToAction: polyglot.t("General.yes")
          }
        )
      ]
    }
  );
};
const loadingState = () => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "600px",
        borderRadius: radius.br10,
        border: borders.activeGrey,
        padding: spacing.s2
      },
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm, minHeight: "84px" }, children: [
          /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "130px", height: 15, rowGap: "5px" }),
          /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "260px", height: 15, rowGap: "5px" })
        ] }) }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px", alignItems: "center", flexWrap: "wrap" }, children: [
          /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "130px", height: 15, rowGap: "5px" }),
          /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "130px", height: 15, rowGap: "5px" }),
          /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "130px", height: 15, rowGap: "5px" })
        ] })
      ]
    }
  );
};
