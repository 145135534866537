"use strict";
import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import { Stack } from "@mui/material";
import { PayrollAccountingSettings } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/accounting-settings/payroll-accounting-settings.page";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollCompanySettingsAccounting = ({ payroll }) => {
  const [accountEdit, setAccountEdit] = useState(window.location.hash.includes("edit-accounting"));
  return /* @__PURE__ */ jsx(Stack, { sx: { maxWidth: "650px", gap: spacing.g40, my: spacing.my20 }, children: payroll && /* @__PURE__ */ jsx(PayrollAccountingSettings, { payrollId: payroll.id, accountEdit, setAccountEdit }) });
};
