"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, IconButton, List, ListItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOrderStatus, DevicePossessionType } from "@v2/feature/device/device.interface";
import { getDeviceOwnerAsSuperadminByDevicePossession, sanitizeDepAccountName } from "@v2/feature/device/device.util";
import { InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { DeviceOrderTrackingLinkForm } from "@v2/feature/super-admin/features/super-admin-devices/components/device-order-tracking-link-form.component";
import { SuperAdminEditOrderForm } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-edit-order-form.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Checked } from "@/images/side-bar-icons/ok-green.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const SuperAdminDeviceOrderDrawer = ({
  isOpen,
  setIsOpen,
  deviceOrder,
  setDeviceOrder,
  companyNames,
  refresh,
  users,
  userNames,
  devices,
  sites
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(
      SuperAdminDeviceOrderDrawerContent,
      {
        deviceOrder,
        setDeviceOrder,
        setIsOpen,
        companyNames,
        refresh,
        users,
        userNames,
        devices,
        sites
      }
    )
  }
) });
const SuperAdminDeviceOrderDrawerContent = ({
  deviceOrder,
  setDeviceOrder,
  setIsOpen,
  refresh,
  users,
  userNames,
  companyNames,
  devices,
  sites
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q;
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isDeliveredLoading, setIsDeliveredLoading] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const isApple = ((_b = (_a = deviceOrder.device) == null ? void 0 : _a.manufacturer) == null ? void 0 : _b.toLowerCase()) === "apple";
  const yesterday = new LocalDate();
  yesterday.getDate().setDate(yesterday.getDate().getDate() - 1);
  yesterday.getDate().setHours(23);
  const [deliveryDate, setDeliveryDate] = useState(
    deviceOrder.deliveryDate && yesterday.toDateString() < deviceOrder.deliveryDate ? deviceOrder.deliveryDate : new LocalDate().toDateString()
  );
  const [isEditable, setIsEditable] = useState(false);
  const [showMessage] = useMessage();
  const [selectedStockDevice, setSelectedStockDevice] = useState(null);
  const [isInHouseMdm] = useState(isApple);
  const rejectOrder = () => __async(void 0, null, function* () {
    try {
      setIsRejectLoading(true);
      const rejectedDeviceOrder = yield DeviceAPI.rejectDeviceOrderAsSuperadmin(deviceOrder.id);
      setDeviceOrder(rejectedDeviceOrder);
      showMessage("Order rejected.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Could not reject device order. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsRejectLoading(false);
    }
  });
  const deleteOrder = () => __async(void 0, null, function* () {
    try {
      setIsDeletingLoading(true);
      yield DeviceAPI.deleteDeviceOrderAsSuperAdmin(deviceOrder.id);
      showMessage("Order deleted.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Could not delete device order. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDeletingLoading(false);
      setDeviceOrder(void 0);
      setIsOpen(false);
    }
  });
  const acceptOrder = () => __async(void 0, null, function* () {
    try {
      if (!deliveryDate) {
        showMessage("Please select a delivery date", "error");
        return;
      }
      const acceptedDeviceOrder = yield DeviceAPI.acceptDeviceOrderAsSuperadmin(deviceOrder.id, deliveryDate);
      setDeviceOrder(acceptedDeviceOrder);
      showMessage("Order accepted.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Could not accept device order. ${nestErrorMessage(error)}`, "error");
    }
  });
  const setOrderInShipping = () => __async(void 0, null, function* () {
    try {
      if (!deviceOrder.deviceId) {
        showMessage("Please select a stock device", "error");
        return;
      }
      const inShippingDeviceOrder = yield DeviceAPI.setDeviceOrderInShippingAsSuperadmin(
        deviceOrder.id,
        deviceOrder.deviceId
      );
      setDeviceOrder(inShippingDeviceOrder);
      showMessage("Order status updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Could not update device order. ${nestErrorMessage(error)}`, "error");
    }
  });
  const setSelectedDevice = () => __async(void 0, null, function* () {
    try {
      if (!selectedStockDevice) {
        showMessage("Please select a stock device", "error");
        return;
      }
      const updatedDeviceOrder = yield DeviceAPI.assignDeviceToDeviceOrderAsSuperadmin(
        deviceOrder.id,
        selectedStockDevice
      );
      setDeviceOrder(updatedDeviceOrder);
      showMessage("Device assigned.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Could not assign device. ${nestErrorMessage(error)}`, "error");
    }
  });
  const setOrderInStorage = () => __async(void 0, null, function* () {
    try {
      if (!deviceOrder.deviceId) {
        showMessage("Please select a stock device", "error");
        return;
      }
      const inShippingDeviceOrder = yield DeviceAPI.setDeviceOrderInShippingAsSuperadmin(
        deviceOrder.id,
        deviceOrder.deviceId
      );
      setDeviceOrder(inShippingDeviceOrder);
      showMessage("Order status updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Could not update device order. ${nestErrorMessage(error)}`, "error");
    }
  });
  const setOrderAsDelivered = () => __async(void 0, null, function* () {
    try {
      if (!deliveryDate) {
        showMessage("Please confirm the delivery date", "error");
        return;
      }
      if (!deviceOrder.deviceId) {
        showMessage("Could not set the device as delivered as no device is assigned to this order.", "error");
        return;
      }
      const deliveredDeviceOrder = yield DeviceAPI.setDeviceOrderAsDeliveredAsSuperadmin(
        deviceOrder.id,
        deviceOrder.deviceId,
        deliveryDate
      );
      setDeviceOrder(deliveredDeviceOrder);
      showMessage("Order status updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Could not update device order status. ${nestErrorMessage(error)}`, "error");
    }
  });
  const addDepDeviceAndUserToMdm = () => __async(void 0, null, function* () {
    var _a2, _b2, _c2, _d2, _e2, _f2;
    try {
      const userName = getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites);
      if (!deviceOrder.deviceId || !((_a2 = deviceOrder.device) == null ? void 0 : _a2.serialNumber) || !userName || !((_b2 = deviceOrder.possession) == null ? void 0 : _b2.companyId)) {
        showMessage(
          `Please provide all necessary data to add device to MDM, deviceId: ${deviceOrder.deviceId}, serialNumber:${(_c2 = deviceOrder.device) == null ? void 0 : _c2.serialNumber}, userName: ${userName}, companyId:${(_d2 = deviceOrder.possession) == null ? void 0 : _d2.companyId}`,
          "error"
        );
        return;
      }
      const depUserDeviceDto = {
        zeltDeviceId: deviceOrder.deviceId,
        userFullName: userName,
        userShortName: userName,
        serialNumber: (_e2 = deviceOrder.device) == null ? void 0 : _e2.serialNumber,
        companyId: (_f2 = deviceOrder.possession) == null ? void 0 : _f2.companyId
      };
      yield InHouseMdmAPI.setDepDeviceAndUser(depUserDeviceDto);
      showMessage("Dep device added to MDM.", "success");
    } catch (error) {
      showMessage(`Could not add DEP device and user to MDM. ${nestErrorMessage(error)}`, "error");
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Order details" }),
      [DeviceOrderStatus.Accepted, DeviceOrderStatus.Placed].includes(deviceOrder.status) && /* @__PURE__ */ jsx(
        IconButton,
        {
          onClick: () => setIsEditable((previousState) => !previousState),
          sx: { display: "flex", marginLeft: "auto", height: 40, width: 40 },
          title: "Edit",
          children: isEditable ? /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey, fill: themeColors.DarkGrey })) : /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
        }
      )
    ] }),
    isEditable ? /* @__PURE__ */ jsx(SuperAdminEditOrderForm, { deviceOrder, devices, refresh }) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title3", children: (_c = deviceOrder.deviceModel) == null ? void 0 : _c.modelName }),
        deviceOrder.status === DeviceOrderStatus.Accepted && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Reject Order",
            loading: isRejectLoading,
            onClick: rejectOrder,
            sizeVariant: "small",
            colorVariant: "danger"
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Device Summary" }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
        deviceOrder.deviceId && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Selected device id: ",
          deviceOrder.deviceId
        ] }),
        ((_d = deviceOrder.device) == null ? void 0 : _d.serialNumber) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Serial number: ",
          deviceOrder.device.serialNumber
        ] }),
        ((_e = deviceOrder.deviceModel) == null ? void 0 : _e.modelName) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Model Name: ",
          deviceOrder.deviceModel.modelName
        ] }),
        ((_f = deviceOrder.deviceModel) == null ? void 0 : _f.modelNumber) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Model Number: ",
          deviceOrder.deviceModel.modelNumber
        ] }),
        ((_g = deviceOrder.deviceModel) == null ? void 0 : _g.screenSize) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Screen Size: ",
          deviceOrder.deviceModel.screenSize,
          '"'
        ] }),
        ((_h = deviceOrder.deviceModel) == null ? void 0 : _h.ram) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Memory: ",
          deviceOrder.deviceModel.ram,
          "GB"
        ] }),
        ((_i = deviceOrder.deviceModel) == null ? void 0 : _i.storage) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Storage: ",
          deviceOrder.deviceModel.storage,
          "GB"
        ] })
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Order Summary" }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Assigned to: ",
          getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites)
        ] }),
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Ordered by: ",
          (_j = userNames[deviceOrder.createdBy]) != null ? _j : "Unknown"
        ] }),
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Company: ",
          (_k = companyNames[deviceOrder.companyId]) != null ? _k : "N/A"
        ] }),
        deviceOrder.deliveryDate && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Delivery Date:",
          " ",
          new Date(deviceOrder.deliveryDate).toLocaleDateString(void 0, { dateStyle: "medium" })
        ] }),
        deviceOrder.deliveryAddress && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Delivery Address: ",
          deviceOrder.deliveryAddress
        ] }),
        deviceOrder.phoneNumber && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "Phone Number: ",
          deviceOrder.phoneNumber
        ] })
      ] }),
      isInHouseMdm && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "DEP Settings" }),
        /* @__PURE__ */ jsxs(Box, { sx: { mb: "32px", mt: "16px" }, children: [
          /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            "Account name:",
            " ",
            sanitizeDepAccountName(
              (_m = (_l = users.find((user) => {
                var _a2;
                return user.userId === ((_a2 = deviceOrder.possession) == null ? void 0 : _a2.possessionId);
              })) == null ? void 0 : _l.firstName) != null ? _m : "Unknown"
            )
          ] }),
          /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            "DeviceId: ",
            deviceOrder.deviceId
          ] }),
          /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            "Serial number: ",
            (_n = deviceOrder.device) == null ? void 0 : _n.serialNumber
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: { mb: "32px", mt: "16px" }, children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => __async(void 0, null, function* () {
                yield addDepDeviceAndUserToMdm();
              }),
              fullWidth: true,
              sizeVariant: "small",
              colorVariant: "primary",
              children: "Send to MDM"
            }
          ) })
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { mb: 4 }, children: [
        [DeviceOrderStatus.Rejected, DeviceOrderStatus.Cancelled].includes(deviceOrder.status) && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Delete Order",
            loading: isDeletingLoading,
            onClick: deleteOrder,
            sizeVariant: "small",
            colorVariant: "danger",
            fullWidth: true
          }
        ) }),
        [DeviceOrderStatus.Placed, DeviceOrderStatus.Shipping].includes(deviceOrder.status) && /* @__PURE__ */ jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: /* @__PURE__ */ jsx(
          DatePicker,
          {
            disablePast: true,
            label: "Delivery date",
            className: "datepicker",
            inputFormat: "dd/MM/yyyy",
            value: deliveryDate,
            onChange: (newDate) => setDeliveryDate(newDate),
            renderInput: (params) => /* @__PURE__ */ jsx(TextField, __spreadProps(__spreadValues({}, params), { variant: "standard", sx: { width: "100%", mb: 2 }, size: "small" }))
          }
        ) }),
        deviceOrder.status === DeviceOrderStatus.Placed && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Accept Order",
              loading: isAcceptLoading,
              onClick: () => __async(void 0, null, function* () {
                setIsAcceptLoading(true);
                yield acceptOrder();
                setIsAcceptLoading(false);
              }),
              disabled: !deliveryDate,
              fullWidth: true,
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          ),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Reject Order",
              loading: isRejectLoading,
              onClick: rejectOrder,
              sizeVariant: "medium",
              colorVariant: "danger",
              fullWidth: true
            }
          )
        ] }),
        !deviceOrder.deviceId && deviceOrder.status === DeviceOrderStatus.Accepted && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: "Select Device" }),
          /* @__PURE__ */ jsx(Box, { sx: { width: "100%", mb: 2 }, children: /* @__PURE__ */ jsx(Box, { children: devices && /* @__PURE__ */ jsx(
            List,
            {
              sx: {
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                maxHeight: "200px",
                overflowY: "auto"
              },
              children: devices.map((device) => /* @__PURE__ */ jsxs(
                ListItem,
                {
                  onClick: () => {
                    setSelectedStockDevice(device.id);
                  },
                  sx: {
                    border: device.id === selectedStockDevice ? "2px solid #a0a0a0" : "1px solid #e0e0e0",
                    borderRadius: "10px",
                    cursor: "pointer",
                    display: "flex",
                    gap: 1
                  },
                  children: [
                    /* @__PURE__ */ jsxs(Box, { sx: { width: "40%" }, children: [
                      /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
                        " ",
                        device.modelName
                      ] }),
                      /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                        " Id: ",
                        device.id
                      ] }),
                      /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                        " ",
                        device.serialNumber
                      ] })
                    ] }),
                    /* @__PURE__ */ jsxs(Box, { children: [
                      /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                        "RAM: ",
                        device.ram,
                        " GB"
                      ] }),
                      /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                        "Storage: ",
                        device.storage,
                        " GB"
                      ] })
                    ] }),
                    /* @__PURE__ */ jsxs(Box, { children: [
                      /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                        "CPU: ",
                        device.cpu,
                        "-cores"
                      ] }),
                      /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                        "Screen size: ",
                        device.screenSize,
                        '"'
                      ] })
                    ] })
                  ]
                }
              ))
            }
          ) }) }),
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Select device",
              loading: isSelecting,
              onClick: () => __async(void 0, null, function* () {
                setIsSelecting(true);
                yield setSelectedDevice();
                setIsSelecting(false);
              }),
              disabled: !selectedStockDevice,
              fullWidth: true,
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          ) })
        ] }),
        deviceOrder.status === DeviceOrderStatus.Accepted && deviceOrder.deviceId && /* @__PURE__ */ jsxs(Box, { children: [
          ((_o = deviceOrder.possession) == null ? void 0 : _o.possessionType) && [DevicePossessionType.CompanySite, DevicePossessionType.User].includes(
            (_p = deviceOrder.possession) == null ? void 0 : _p.possessionType
          ) && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Set in Shipping",
              loading: isShipping,
              onClick: () => __async(void 0, null, function* () {
                setIsShipping(true);
                yield setOrderInShipping();
                setIsShipping(false);
              }),
              disabled: !deviceOrder.deviceId,
              fullWidth: true,
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          ),
          ((_q = deviceOrder.possession) == null ? void 0 : _q.possessionType) === DevicePossessionType.ZeltStorage && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Set in Storage",
              loading: isShipping,
              onClick: () => __async(void 0, null, function* () {
                setIsShipping(true);
                yield setOrderInStorage();
                setIsShipping(false);
              }),
              disabled: !selectedStockDevice,
              fullWidth: true,
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          )
        ] }),
        deviceOrder.status === DeviceOrderStatus.Shipping && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Set as Delivered",
            loading: isDeliveredLoading,
            onClick: () => __async(void 0, null, function* () {
              setIsDeliveredLoading(true);
              yield setOrderAsDelivered();
              setIsDeliveredLoading(false);
            }),
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        ),
        deviceOrder.status === DeviceOrderStatus.Delivered && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [
          /* @__PURE__ */ jsx(Checked, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: deviceOrder.status })
        ] }),
        deviceOrder.status === DeviceOrderStatus.Shipping && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(DeviceOrderTrackingLinkForm, { deviceOrder }) })
      ] })
    ] })
  ] });
};
