"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CustomRuleOptions,
  getUserSelectOptionLabel,
  UserSelectFiltersOptions
} from "@v2/components/user-select-type/user-select.interface";
import { getCustomRule } from "@v2/feature/absence/absence.util";
import { SettingsMembersList } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-members-list.component";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { EditPolicyMembersDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-members-edit-drawer.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { CompanyDepartmentAPI } from "@/api-client/company-department.api";
import { CompanyAPI } from "@/api-client/company.api";
import { SiteAPI } from "@/api-client/site.api";
export const AbsencePolicyMembersSection = ({ absencePolicy, refresh }) => {
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [ruleDataOption, setRuleDataOption] = useState([]);
  const { nonTerminatedCachedUsers: companyUsers } = useCachedUsers();
  const listOfUsers = useMemo(() => {
    var _a;
    if (absencePolicy.membersRule === UserSelectFiltersOptions.Everyone)
      return companyUsers.map((u) => {
        var _a2;
        return {
          userId: u.userId,
          name: (_a2 = u.displayName) != null ? _a2 : `${u.firstName} ${u.lastName}`
        };
      });
    const selectedIds = (_a = absencePolicy.selectedMembersIds) != null ? _a : [];
    const userIdsSet = new Set(selectedIds);
    return companyUsers.filter((u) => userIdsSet.has(u.userId)).map((u) => {
      var _a2;
      return {
        userId: u.userId,
        name: (_a2 = u.displayName) != null ? _a2 : `${u.firstName} ${u.lastName}`
      };
    });
  }, [absencePolicy.membersRule, absencePolicy.selectedMembersIds, companyUsers]);
  const getCustomRuleData = useCallback(() => __async(void 0, null, function* () {
    var _a, _b, _c, _d;
    const ruleTypeArray = absencePolicy.customRule ? (_a = absencePolicy.customRule) == null ? void 0 : _a.split("=") : [];
    if (ruleTypeArray.length === 0) return;
    const ruleType = (_b = ruleTypeArray[0]) != null ? _b : [];
    if (ruleType === CustomRuleOptions.Site) {
      const sitesData = yield SiteAPI.listSites(true);
      setRuleDataOption(
        sitesData.map((s) => {
          return { label: s.name, value: s.id };
        })
      );
    }
    if (ruleType === CustomRuleOptions.Department) {
      const departmentsData = yield CompanyDepartmentAPI.getCompanyDepartments();
      setRuleDataOption(
        departmentsData.map((d) => {
          return { label: d.name, value: d.id };
        })
      );
    }
    if (ruleType === CustomRuleOptions.Entity) {
      const entitiesData = (_d = (_c = yield CompanyAPI.getGeneralSettings()) == null ? void 0 : _c.entities) != null ? _d : [];
      setRuleDataOption(
        entitiesData.map((e) => {
          return { label: e.legalName, value: e.id };
        })
      );
    }
  }), [absencePolicy.customRule]);
  useEffect(() => {
    getCustomRuleData();
  }, [getCustomRuleData]);
  const customRuleString = useMemo(() => getCustomRule(absencePolicy.customRule, ruleDataOption, polyglot), [
    absencePolicy,
    ruleDataOption,
    polyglot
  ]);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyMembersSection.members"),
      onEdit: () => setIsEditDrawerOpen(true),
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyMembersSection.members"),
                    value: getUserSelectOptionLabel(absencePolicy.membersRule, polyglot)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyMembersSection.rule"),
                    value: polyglot.t("MembersSectionReadonly.selectAllFrom", { source: customRuleString }),
                    hidden: !customRuleString
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(SettingsMembersList, { members: listOfUsers })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          EditPolicyMembersDrawer,
          {
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            absencePolicy,
            refresh
          }
        )
      ]
    }
  );
};
