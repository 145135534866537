"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as TasksIcon } from "@/images/onboarding-step-icons/DeviceBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { EditDevices } from "@/v2/feature/onboarding/onboarding-items/devices/edit-devices.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DevicesOnboardingItem = ({ template, onDelete, setDrawerOpen, setDrawerView }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: TasksIcon,
        title: polyglot.t("DevicesOnboardingItem.title"),
        description: polyglot.t("DevicesOnboardingItem.desc")
      },
      addButtonIcon: template.template.device ? "edit" : "plus",
      onAddClick: () => {
        setDrawerView == null ? void 0 : setDrawerView(
          /* @__PURE__ */ jsx(
            EditDevices,
            {
              config: template.template.device,
              onSave: (config) => {
                template.template.device = __spreadValues({}, config);
                setDrawerOpen == null ? void 0 : setDrawerOpen(false);
              }
            }
          )
        );
        setDrawerOpen == null ? void 0 : setDrawerOpen(true);
      },
      onDeleteClick: template.template.device && onDelete,
      sx: {
        py: spacing.py20
      }
    }
  );
};
