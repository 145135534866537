"use strict";
export class DepWizardHelper {
  static formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric"
    };
    return date.toLocaleDateString("en-US", options);
  }
}
