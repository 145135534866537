"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class InHouseMdmAPI {
  static getEnrolmentProfile(deviceId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/zelt-mdm/enrollment/ota/enroll/${deviceId}`)).data;
    });
  }
  static setDepDeviceAndUser(depUserDeviceDto) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/zelt-mdm/dep/assign/device`, depUserDeviceDto)).data;
    });
  }
  static syncDevicePolicy(inHouseMdmSyncProfileDto) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/zelt-mdm/device/policy/sync`, inHouseMdmSyncProfileDto)).data;
    });
  }
  static syncDeviceAgainstDep(depDeviceInfoStatusCommand) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/zelt-mdm/device/dep/sync`, depDeviceInfoStatusCommand)).data;
    });
  }
  static syncEnrolmentStatus(deviceId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/zelt-mdm/device/${deviceId}/sync/enrolment-status`)).data;
    });
  }
  static getDepPublicKey(mdmServerName) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/zelt-mdm/dep/token/public/${mdmServerName}`)).data;
    });
  }
  static getDepAccountDetailsAndSave(mdmServerName) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/zelt-mdm/dep/account/details/${mdmServerName}`)).data;
    });
  }
  static getDepAccountDetails(mdmServerName) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/zelt-mdm/dep/account/details/${mdmServerName}`)).data;
    });
  }
  static assignDeviceToABM(assignDeviceToABM) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/zelt-mdm/abm/assign`, assignDeviceToABM);
    });
  }
  static deleteDepPublicKey(mdmServerName) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/zelt-mdm/dep/token/public/${mdmServerName}`)).data;
    });
  }
  static syncDepDevices(mdmServerName) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/zelt-mdm/dep/token/public/${mdmServerName}/sync-devices`)).data;
    });
  }
  static setAutoSyncDepDevice(mdmServerName, isEnabled) {
    return __async(this, null, function* () {
      return (yield axios.patch(
        `/apiv2/zelt-mdm/dep/token/public/${mdmServerName}/auto-sync`,
        {},
        {
          params: {
            isEnabled
          }
        }
      )).data;
    });
  }
}
export class InHouseEndpoints {
  static getDepAccountDetails(mdmServerName) {
    return {
      url: `/apiv2/zelt-mdm/dep/account/details/${mdmServerName}`
    };
  }
}
export var EnrollmentStatus = /* @__PURE__ */ ((EnrollmentStatus2) => {
  EnrollmentStatus2["ENROLMENT_FINISHED"] = "ENROLMENT_FINISHED";
  EnrollmentStatus2["ENROLMENT_FAILED_NO_DEVICE_ID_IN_PROFILE"] = "ENROLMENT_FAILED_NO_DEVICE_ID_IN_PROFILE";
  EnrollmentStatus2["ENROLMENT_FAILED_CANNOT_UPDATE_DEVICE_IN_DB"] = "ENROLMENT_FAILED_CANNOT_UPDATE_DEVICE_IN_DB";
  return EnrollmentStatus2;
})(EnrollmentStatus || {});
