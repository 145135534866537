import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.REACT_APP_DISABLE_SENTRY !== 'true') {
  Sentry.init({
    dsn: 'https://b45c39b768524f4b826723f2980cd50d@o553737.ingest.sentry.io/5723014',
    environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
  });
}
