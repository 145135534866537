"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton, Typography } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { cardTitleIconSx, settingCardSx } from "@/v2/styles/settings.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const CompanySettingSectionHeader = ({
  title,
  editButtonHint,
  editButtonLabel,
  onEditClick,
  showEditButton,
  header,
  subheader,
  buttonName,
  onButtonClick,
  buttonLoading = false
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column" }, settingCardSx), children: [
    /* @__PURE__ */ jsxs(Box, { sx: cardTitleIconSx, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: title }),
      showEditButton && !editButtonLabel && /* @__PURE__ */ jsx(IconButton, { title: editButtonHint, sx: tableIconButtonSx, onClick: () => onEditClick == null ? void 0 : onEditClick(), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) }),
      showEditButton && editButtonLabel && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          title: editButtonHint,
          colorVariant: "secondary",
          sizeVariant: "small",
          onClick: () => onEditClick == null ? void 0 : onEditClick(),
          children: editButtonLabel
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
        header && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: header }),
        subheader && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: subheader })
      ] }),
      buttonName && onButtonClick && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          name: buttonName,
          type: "button",
          onClick: onButtonClick,
          loading: buttonLoading
        }
      ) })
    ] })
  ] });
};
