"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Autocomplete, Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import {
  DeviceOSValueLabelOptions,
  DeviceTypesValueLabelOptions,
  ManufacturerOptions
} from "@v2/feature/device/device.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { StyledTextfield } from "@v2/styles/textfield.styles";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import MaterialModal from "@/component/widgets/ModalV2";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { spacing } from "@/v2/styles/spacing.styles";
export const SuperAdminDeviceModelDrawer = ({
  isOpen,
  setIsOpen,
  deviceModel,
  closeDrawer,
  refresh
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(SuperAdminDeviceModelDrawerContent, { deviceModel, closeDrawer, refresh }) });
export const SuperAdminDeviceModelDrawerContent = ({
  deviceModel,
  closeDrawer,
  refresh
}) => {
  var _a, _b, _c, _d, _e;
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const initialValues = {
    type: deviceModel == null ? void 0 : deviceModel.type,
    modelName: deviceModel == null ? void 0 : deviceModel.modelName,
    modelNumber: deviceModel == null ? void 0 : deviceModel.modelNumber,
    modelVersion: deviceModel == null ? void 0 : deviceModel.modelVersion,
    screenSize: deviceModel == null ? void 0 : deviceModel.screenSize,
    os: (_a = deviceModel == null ? void 0 : deviceModel.os) != null ? _a : "none",
    manufacturer: deviceModel == null ? void 0 : deviceModel.manufacturer,
    cpuCores: deviceModel == null ? void 0 : deviceModel.cpuCores,
    gpuCores: deviceModel == null ? void 0 : deviceModel.gpuCores,
    ram: deviceModel == null ? void 0 : deviceModel.ram,
    storage: deviceModel == null ? void 0 : deviceModel.storage,
    price24: deviceModel == null ? void 0 : deviceModel.price24,
    price36: deviceModel == null ? void 0 : deviceModel.price36,
    priceEu24: deviceModel == null ? void 0 : deviceModel.priceEu24,
    priceEu36: deviceModel == null ? void 0 : deviceModel.priceEu36,
    fullPrice: deviceModel == null ? void 0 : deviceModel.fullPrice,
    fullPriceEu: deviceModel == null ? void 0 : deviceModel.fullPriceEu,
    deliveryDays: (_b = deviceModel == null ? void 0 : deviceModel.deliveryDays) != null ? _b : 7,
    deliveryDaysEu: (_c = deviceModel == null ? void 0 : deviceModel.deliveryDaysEu) != null ? _c : 7,
    isAvailable: deviceModel ? deviceModel.isAvailable ? "Yes" : "No" : "Yes"
  };
  const validationSchema = yup.object({
    type: yup.string().required("You must specify a value."),
    modelName: yup.string().required("You must specify a value."),
    modelNumber: yup.string().nullable().notRequired(),
    modelVersion: yup.string().nullable().notRequired(),
    manufacturer: yup.string().nullable().notRequired(),
    os: yup.string().required("You must specify a value."),
    screenSize: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    cpuCores: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    gpuCores: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    ram: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    storage: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    price24: yup.number().typeError("You must specify a number.").required("You must specify a value."),
    price36: yup.number().typeError("You must specify a number.").required("You must specify a value."),
    priceEu24: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    priceEu36: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    fullPrice: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    fullPriceEu: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    deliveryDays: yup.number().typeError("You must specify a number.").required("You must specify a value."),
    deliveryDaysEu: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    isAvailable: yup.string().oneOf(["Yes", "No"]).nullable().required("Must specify if device is available")
  });
  const getFormData = (data) => {
    var _a2, _b2, _c2;
    if (!data.type || !data.modelName || !data.os || !data.price36 || !data.price24 || !data.deliveryDays) {
      return void 0;
    }
    return {
      type: data.type,
      modelName: data.modelName,
      modelNumber: (_a2 = data.modelNumber) != null ? _a2 : null,
      modelVersion: (_b2 = data.modelVersion) != null ? _b2 : null,
      screenSize: data.screenSize ? Number(data.screenSize) : null,
      manufacturer: (_c2 = data.manufacturer) != null ? _c2 : null,
      os: data.os,
      cpuCores: data.cpuCores ? Number(data.cpuCores) : null,
      gpuCores: data.gpuCores ? Number(data.gpuCores) : null,
      ram: data.ram ? Number(data.ram) : null,
      storage: data.storage ? Number(data.storage) : null,
      price24: Number(data.price24),
      price36: Number(data.price36),
      priceEu24: data.priceEu24 ? Number(data.priceEu24) : null,
      priceEu36: data.priceEu36 ? Number(data.priceEu36) : null,
      fullPrice: data.fullPrice ? Number(data.fullPrice) : null,
      fullPriceEu: data.fullPriceEu ? Number(data.fullPriceEu) : null,
      deliveryDays: Number(data.deliveryDays),
      deliveryDaysEu: data.deliveryDaysEu ? Number(data.deliveryDaysEu) : null,
      isAvailable: data.isAvailable === "Yes"
    };
  };
  const updateDeviceModel = (modelUpdate) => __async(void 0, null, function* () {
    const formData = getFormData(modelUpdate);
    if (!formData) {
      showMessage("Invalid data provided.", "error");
      return;
    }
    const update = __spreadProps(__spreadValues({}, formData), {
      os: formData.os && formData.os !== "none" ? formData.os : null
    });
    try {
      setLoading(true);
      yield DeviceAPI.updateDeviceModel(deviceModel.id, update);
      showMessage("Device model successfully updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const addDeviceModel = (deviceModel2) => __async(void 0, null, function* () {
    const formData = getFormData(deviceModel2);
    if (!formData) {
      showMessage("Invalid data provided.", "error");
      return;
    }
    try {
      setLoading(true);
      yield DeviceAPI.addDeviceModel(formData);
      showMessage("Device model successfully updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      deviceModel ? yield updateDeviceModel(values) : yield addDeviceModel(values);
      closeDrawer();
    })
  });
  return /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    /* @__PURE__ */ jsxs(Box, { component: "form", autoComplete: "off", sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: deviceModel ? deviceModel.modelName : "Add device model" }),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "isAvailable",
          label: "Available in Devices Store",
          options: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" }
          ],
          value: formik.values.isAvailable,
          onChange: formik.handleChange,
          compareValue: formik.values.isAvailable,
          error: !!formik.errors.isAvailable && formik.touched.isAvailable,
          helperText: formik.touched.isAvailable && formik.errors.isAvailable,
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "type",
          label: "Type",
          options: DeviceTypesValueLabelOptions,
          value: formik.values.type,
          onChange: formik.handleChange,
          compareValue: formik.values.type,
          error: !!formik.errors.type && formik.touched.type,
          helperText: formik.touched.type && formik.errors.type,
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Model Name",
          name: "modelName",
          value: formik.values.modelName,
          onChange: formik.handleChange,
          error: formik.touched.modelName && Boolean(formik.errors.modelName),
          helperText: formik.touched.modelName && formik.errors.modelName,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Model Number",
          name: "modelNumber",
          value: formik.values.modelNumber,
          onChange: formik.handleChange,
          error: formik.touched.modelNumber && Boolean(formik.errors.modelNumber),
          helperText: formik.touched.modelNumber && formik.errors.modelNumber,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Model Version",
          name: "modelVersion",
          value: formik.values.modelVersion,
          onChange: formik.handleChange,
          error: formik.touched.modelVersion && Boolean(formik.errors.modelVersion),
          helperText: formik.touched.modelVersion && formik.errors.modelVersion,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        Autocomplete,
        {
          id: "manufacturer",
          freeSolo: true,
          options: ManufacturerOptions,
          sx: { width: "100%" },
          onChange: (event) => {
            const target = event.target;
            formik.setFieldValue("manufacturer", target.textContent);
          },
          value: formik.values.manufacturer,
          renderInput: (params) => {
            var _a2;
            return /* @__PURE__ */ jsx(
              StyledTextfield,
              __spreadProps(__spreadValues({}, params), {
                variant: "standard",
                name: "manufacturer",
                label: "Manufacturer",
                value: (_a2 = formik.values.manufacturer) != null ? _a2 : "",
                onChange: formik.handleChange,
                fullWidth: true,
                size: "small",
                error: formik.touched.manufacturer && Boolean(formik.errors.manufacturer),
                helperText: formik.touched.manufacturer && formik.errors.manufacturer
              })
            );
          }
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "os",
          label: "OS",
          options: [{ label: "None", value: "none" }, ...DeviceOSValueLabelOptions],
          value: (_d = formik.values.os) != null ? _d : void 0,
          compareValue: (_e = formik.values.os) != null ? _e : void 0,
          error: !!formik.errors.os && formik.touched.os,
          onChange: formik.handleChange,
          helperText: formik.touched.os && formik.errors.os,
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "RAM (GB)",
          name: "ram",
          value: formik.values.ram,
          onChange: formik.handleChange,
          error: formik.touched.ram && !!formik.errors.ram,
          helperText: formik.touched.ram && formik.errors.ram,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "CPU Cores",
          name: "cpuCores",
          value: formik.values.cpuCores,
          onChange: formik.handleChange,
          error: formik.touched.cpuCores && Boolean(formik.errors.cpuCores),
          helperText: formik.touched.cpuCores && formik.errors.cpuCores,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "GPU Cores",
          name: "gpuCores",
          value: formik.values.gpuCores,
          onChange: formik.handleChange,
          error: formik.touched.gpuCores && Boolean(formik.errors.gpuCores),
          helperText: formik.touched.gpuCores && formik.errors.gpuCores,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Storage (GB)",
          name: "storage",
          value: formik.values.storage,
          onChange: formik.handleChange,
          error: formik.touched.storage && Boolean(formik.errors.storage),
          helperText: formik.touched.storage && formik.errors.storage,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Screen Size (inch)",
          name: "screenSize",
          value: formik.values.screenSize,
          onChange: formik.handleChange,
          error: formik.touched.screenSize && Boolean(formik.errors.screenSize),
          helperText: formik.touched.screenSize && formik.errors.screenSize,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Price \xA3 (36 months)",
          name: "price36",
          value: formik.values.price36,
          onChange: formik.handleChange,
          error: formik.touched.price36 && Boolean(formik.errors.price36),
          helperText: formik.touched.price36 && formik.errors.price36,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Price \xA3 (24 months)",
          name: "price24",
          value: formik.values.price24,
          onChange: formik.handleChange,
          error: formik.touched.price24 && Boolean(formik.errors.price24),
          helperText: formik.touched.price24 && formik.errors.price24,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Price EU \xA3 (36 months)",
          name: "priceEu36",
          value: formik.values.priceEu36,
          onChange: formik.handleChange,
          error: formik.touched.priceEu36 && Boolean(formik.errors.priceEu36),
          helperText: formik.touched.priceEu36 && formik.errors.priceEu36,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Price EU \xA3 (24 months)",
          name: "priceEu24",
          value: formik.values.priceEu24,
          onChange: formik.handleChange,
          error: formik.touched.priceEu24 && Boolean(formik.errors.priceEu24),
          helperText: formik.touched.priceEu24 && formik.errors.priceEu24,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Full price",
          name: "fullPrice",
          value: formik.values.fullPrice,
          onChange: formik.handleChange,
          error: formik.touched.fullPrice && Boolean(formik.errors.fullPrice),
          helperText: formik.touched.fullPrice && formik.errors.fullPrice,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Full price EU",
          name: "fullPriceEu",
          value: formik.values.fullPriceEu,
          onChange: formik.handleChange,
          error: formik.touched.fullPriceEu && Boolean(formik.errors.fullPriceEu),
          helperText: formik.touched.fullPriceEu && formik.errors.fullPriceEu,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Delivery Days (UK)",
          name: "deliveryDays",
          value: formik.values.deliveryDays,
          onChange: formik.handleChange,
          error: formik.touched.deliveryDays && Boolean(formik.errors.deliveryDays),
          helperText: formik.touched.deliveryDays && formik.errors.deliveryDays,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          required: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Delivery Days (EU)",
          name: "deliveryDaysEu",
          value: formik.values.deliveryDaysEu,
          onChange: formik.handleChange,
          error: formik.touched.deliveryDaysEu && Boolean(formik.errors.deliveryDaysEu),
          helperText: formik.touched.deliveryDaysEu && formik.errors.deliveryDaysEu,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        (deviceModel == null ? void 0 : deviceModel.id) && /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "danger", sizeVariant: "medium", onClick: () => setIsDeleteModalOpen(true), children: "Delete" }),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: "Save",
            loading,
            onClick: () => formik.handleSubmit(),
            fullWidth: true
          }
        )
      ] })
    ] }),
    (deviceModel == null ? void 0 : deviceModel.id) && /* @__PURE__ */ jsx(
      MaterialModal,
      {
        title: "Are you sure you want to delete this device model?",
        isOpen: isDeleteModalOpen,
        onClose: () => setIsDeleteModalOpen(false),
        actionButton: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              gap: spacing.g20,
              alignItems: "center"
            },
            children: [
              /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "small", onClick: () => setIsDeleteModalOpen(false), children: "Close" }),
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "small",
                  colorVariant: "danger",
                  onClick: () => __async(void 0, null, function* () {
                    try {
                      yield DeviceAPI.deleteDeviceModel(deviceModel.id);
                      showMessage("Device successfully deleted.", "success");
                      yield refresh();
                      closeDrawer();
                      setIsDeleteModalOpen(false);
                    } catch (error) {
                      showMessage(`${nestErrorMessage(error)}`, "error");
                    }
                  }),
                  children: "Delete"
                }
              )
            ]
          }
        ),
        children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Warning: This action is irreversible!" })
      }
    )
  ] });
};
