"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { AbsenceAPI } from "@/v2/feature/absence/absence.api";
import { AbsenceDrawer } from "@/v2/feature/absence/sections/absence-drawer/absence-drawer.section";
import { AbsenceViewDrawer } from "@/v2/feature/absence/sections/absence-drawer/absence-view-drawer.page";
export const TimeDrawerContent = ({
  absenceId,
  userId,
  refresh,
  afterClose
}) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const [selectedAbsence, setSelectedAbsence] = useState(void 0);
  const [isViewAbsenceDrawerOpen, setIsViewAbsenceDrawerOpen] = useState(false);
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isAdmin = hasScopes(["absence:all"], getScopesContext(user));
  const getTimeById = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const absence = yield AbsenceAPI.getAbsenceById(Number(absenceId), Number(userId));
      setSelectedAbsence(absence != null ? absence : null);
      setIsViewAbsenceDrawerOpen(true);
    } catch (error) {
      console.error(error);
      setSelectedAbsence(void 0);
    } finally {
      setLoading(false);
    }
  }), [absenceId, userId]);
  useEffect(() => {
    getTimeById();
  }, [getTimeById]);
  const approveAbsence = useCallback(
    (a) => __async(void 0, null, function* () {
      try {
        const [updatedAbsence] = yield AbsenceAPI.approveAllAbsences([a.absenceId]);
        showMessage(polyglot.t("TimeDrawerContent.successMessages.approve"), "success");
        yield refresh();
        setIsViewAbsenceDrawerOpen(false);
        return updatedAbsence;
      } catch (err) {
        showMessage("Something went wrong.", "error");
      }
    }),
    [refresh, showMessage, polyglot]
  );
  const rejectAbsence = useCallback(
    (absence, rejectionNotes) => __async(void 0, null, function* () {
      try {
        const [updatedAbsence] = yield AbsenceAPI.rejectAllAbsences([absence.absenceId], rejectionNotes);
        showMessage(polyglot.t("TimeDrawerContent.successMessages.reject"), "success");
        yield refresh();
        setIsViewAbsenceDrawerOpen(false);
        return updatedAbsence;
      } catch (err) {
        showMessage(polyglot.t("TimeDrawerContent.errorMessages.badRequest"), "error");
      }
    }),
    [refresh, showMessage, polyglot]
  );
  const handleRejectCancellation = useCallback(
    (a) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.rejectTimeCancellationRequests([a.absenceId], a.userId);
        showMessage(polyglot.t("TimeDrawerContent.successMessages.cancel"), "success");
        yield refresh();
        setIsViewAbsenceDrawerOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("TimeDrawerContent.errorMessages.badRequestMessage", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [showMessage, refresh, polyglot]
  );
  const onForceApproval = useCallback(
    (absence, status) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.forceApprovalByAbsencesIds([absence.absenceId], status);
        showMessage(
          polyglot.t(
            status === AbsenceStatus.Approved ? "PersonalAbsence.successMessages.approve" : "PersonalAbsence.successMessages.reject"
          ),
          "success"
        );
        yield refresh();
        setIsViewAbsenceDrawerOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("PersonalAbsence.errorMessages.cancel", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [refresh, showMessage, polyglot]
  );
  const removeRequestHelper = useCallback(
    (absence) => __async(void 0, null, function* () {
      if (absence) {
        try {
          yield AbsenceAPI.deleteAbsenceRecord(absence.absenceId);
          showMessage(polyglot.t("TimeDrawerContent.successMessages.remove"), "success");
          yield refresh();
          setIsViewAbsenceDrawerOpen(false);
        } catch (error) {
          showMessage(
            polyglot.t("TimeDrawerContent.errorMessages.remove", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      }
    }),
    [refresh, showMessage, polyglot]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      AbsenceDrawer,
      {
        isOpen: isAbsenceDrawerOpen,
        onClose: () => {
          setIsAbsenceDrawerOpen(false);
        },
        afterClose: () => {
          if (afterClose) afterClose();
          setSelectedAbsence(void 0);
        },
        refresh,
        absence: selectedAbsence,
        setIsAbsenceDrawerOpen,
        reach: isAdmin ? "company" : "team"
      }
    ),
    /* @__PURE__ */ jsx(
      AbsenceViewDrawer,
      {
        isOpen: isViewAbsenceDrawerOpen,
        absence: selectedAbsence,
        setIsAbsenceDrawerViewOpen: setIsViewAbsenceDrawerOpen,
        handleApprove: approveAbsence,
        handleReject: rejectAbsence,
        onDelete: removeRequestHelper,
        onRejectCancellation: handleRejectCancellation,
        onForceApproval,
        onEdit: (absence) => {
          setSelectedAbsence(absence);
          setIsAbsenceDrawerOpen(true);
        },
        showCalendarLink: true,
        afterClose,
        loading,
        refresh
      }
    )
  ] });
};
