"use strict";
export var Errors = /* @__PURE__ */ ((Errors2) => {
  Errors2["NOT_FOUND"] = "NOT_FOUND";
  Errors2["EMAIL_EXISTS"] = "EMAIL_EXISTS";
  Errors2["PASSWORD_LENGTH"] = "PASSWORD_LENGTH";
  Errors2["LEAKED_PASSWORD"] = "LEAKED_PASSWORD";
  Errors2["EXPIRED_PASSWORD_RESET"] = "EXPIRED_PASSWORD_RESET";
  return Errors2;
})(Errors || {});
export function nestErrorMessage(error, polyglot) {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  console.error({ message: error == null ? void 0 : error.message, response: error == null ? void 0 : error.response });
  let rawMessage;
  if ((_c = (_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.response) == null ? void 0 : _c.message) rawMessage = JSON.stringify((_f = (_e = (_d = error == null ? void 0 : error.response) == null ? void 0 : _d.data) == null ? void 0 : _e.response) == null ? void 0 : _f.message);
  else rawMessage = ((_h = (_g = error == null ? void 0 : error.response) == null ? void 0 : _g.data) == null ? void 0 : _h.message) || (error == null ? void 0 : error.message) || "Something went wrong";
  if (polyglot) return polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: rawMessage });
  return rawMessage;
}
