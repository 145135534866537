"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
export const TooltipCell = ({ title, maxWidth, cell }) => {
  const getCellResults = () => {
    if (title.length * 10 > maxWidth + 20) return /* @__PURE__ */ jsx(StyledTooltip, { title, children: cell });
    else {
      return /* @__PURE__ */ jsx(Fragment, { children: cell });
    }
  };
  return /* @__PURE__ */ jsx(Fragment, { children: getCellResults() });
};
