"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { LOGIN_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const UserOnboardingPasswordAlreadySet = () => {
  const routerHistory = useHistory();
  const returnToLogin = useCallback(() => {
    routerHistory.replace(LOGIN_ROUTE, { continueOnboarding: true });
  }, [routerHistory]);
  return /* @__PURE__ */ jsxs(AuthLayout, { title: "Set up your account", children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: "Your account already exists. Please log in to complete the onboarding process." }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        type: "button",
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        style: { marginTop: "30px" },
        onClick: () => returnToLogin(),
        children: "Login"
      }
    )
  ] });
};
