"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  APPS_COMPANY_ROUTE,
  APPS_PERSONAL_ROUTE,
  DEVICES_COMPANY_ROUTE,
  DEVICES_ME_ROUTE,
  TECH_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { AppIntegrationRouter } from "@/v2/feature/app-integration/app-integration.router";
import { DeviceRouter } from "@/v2/feature/device/device.router";
export const ItRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["devices", "devices.enroll"],
        context: scopesContext,
        path: [DEVICES_COMPANY_ROUTE, DEVICES_ME_ROUTE],
        component: DeviceRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["apps"],
        context: scopesContext,
        path: [APPS_COMPANY_ROUTE, APPS_PERSONAL_ROUTE],
        component: AppIntegrationRouter
      }
    ),
    canAccessScopes(user, ["devices:all", "apps:all"]) && /* @__PURE__ */ jsx(Redirect, { from: TECH_ROUTE, to: DEVICES_COMPANY_ROUTE }),
    canAccessScopes(user, ["devices", "apps"]) && /* @__PURE__ */ jsx(Redirect, { from: TECH_ROUTE, to: DEVICES_ME_ROUTE })
  ] });
};
