"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { BillingContactAPI } from "@/api-client/billing-contact.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const EditBillingContactDrawer = ({
  isOpen,
  setIsOpen,
  billingContact,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(EditBillingContactDrawerContent, { billingContact, refresh }) });
};
const EditBillingContactDrawerContent = ({ billingContact, refresh }) => {
  var _a, _b, _c;
  const [showMessage] = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      userId: (_a = billingContact == null ? void 0 : billingContact.userId) != null ? _a : null,
      sendInvoicesTo: (_b = billingContact == null ? void 0 : billingContact.invoiceEmail) != null ? _b : "",
      phoneNumber: (_c = billingContact == null ? void 0 : billingContact.phoneNumber) != null ? _c : ""
    },
    validationSchema: yup.object({
      userId: yup.number().required("Billing contact is required"),
      sendInvoicesTo: yup.string().test("is-email-list", "Please provide a list of valid emails", (value) => {
        if (!value) {
          return true;
        }
        const emails = value.split(",").map((email) => email.trim());
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emails.every((email) => emailRegex.test(email));
      }).notRequired(),
      phoneNumber: yup.string().trim().matches(/^\+\d{7,15}$/, "Enter a valid phone number. Use only numbers and +.").required("Phone number is required.")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      (billingContact == null ? void 0 : billingContact.id) ? yield updateBillingContact(billingContact.id, values) : yield createBillingContact(values);
    })
  });
  const updateBillingContact = (_0, _1) => __async(void 0, [_0, _1], function* (contactId, { userId, sendInvoicesTo, phoneNumber }) {
    if (!userId) {
      showMessage("Billing contact is required.", "error");
      return;
    }
    try {
      setIsLoading(true);
      yield BillingContactAPI.updateContact({ id: contactId, userId, invoiceEmail: sendInvoicesTo, phoneNumber });
      showMessage("Contact updated successfully", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Couldn't update billing contact. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoading(false);
    }
  });
  const createBillingContact = (_0) => __async(void 0, [_0], function* ({ userId, sendInvoicesTo, phoneNumber }) {
    if (!userId) {
      showMessage("Billing contact is required.", "error");
      return;
    }
    try {
      setIsLoading(true);
      yield BillingContactAPI.createContact(userId, sendInvoicesTo, phoneNumber);
      showMessage("Contact created successfully", "success");
      yield refresh();
    } catch (error) {
      console.error("Failed to save billing update", error);
      showMessage("Failed to save billing contact", "error");
    } finally {
      setIsLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Edit billing contact" }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        label: "Billing contact",
        options: "company",
        value: formik.values.userId,
        onChange: (_, x) => __async(void 0, null, function* () {
          var _a2;
          const userId = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
          yield formik.setFieldValue("userId", userId);
        }),
        error: Boolean(formik.errors.userId),
        helperText: formik.errors.userId
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "sendInvoicesTo",
        label: "Invoices sent to",
        value: formik.values.sendInvoicesTo,
        onChange: formik.handleChange,
        error: formik.touched.sendInvoicesTo && !!formik.errors.sendInvoicesTo,
        helperText: formik.touched.sendInvoicesTo && formik.errors.sendInvoicesTo || "Separate multiple emails by a comma",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mb: spacing.m30 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "phoneNumber",
        label: "Billing phone number",
        value: formik.values.phoneNumber,
        onChange: formik.handleChange,
        error: formik.touched.phoneNumber && !!formik.errors.phoneNumber,
        helperText: formik.touched.phoneNumber && formik.errors.phoneNumber || "Should include country code",
        placeholder: "e.g. +441234567890",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading: isLoading, sizeVariant: "large", colorVariant: "primary", fullWidth: true })
  ] }) });
};
