"use strict";
import { APPS_REQUESTS_ROUTE, APPS_ROUTE, APP_STORE_ROUTE } from "@/lib/routes";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
import {
  getScheduledTimeForAppAccessDetailDrawer,
  getStartingTimeForJob
} from "@/v2/feature/monitoring/monitoring.util";
export const appCurrentlyInstalled = (appList, stub) => {
  return appList == null ? void 0 : appList.some((eachApp) => eachApp.stub === stub);
};
export const companyAppsThatCanBeInstalled = (installedApps, userApps) => {
  return installedApps.filter(
    (eachInstalledApp) => !appCurrentlyInstalled(userApps != null ? userApps : [], eachInstalledApp.stub) && eachInstalledApp.allowsGiveAccess
  );
};
export const userHasDelayedAppAction = (delayedActions, userId, appStub) => {
  var _a;
  if (((_a = delayedActions == null ? void 0 : delayedActions.delayed) == null ? void 0 : _a.length) === 0) return false;
  if (delayedActions && delayedActions.delayed && delayedActions.delayed.length > 0)
    return delayedActions.delayed.some((a) => a.data.userId === userId && a.data.appStub === appStub);
  return false;
};
export const externalUserHasDelayedAppAction = (delayedActions, email, appUserId) => {
  var _a;
  if (((_a = delayedActions == null ? void 0 : delayedActions.delayed) == null ? void 0 : _a.length) === 0) return false;
  if (delayedActions && delayedActions.delayed && delayedActions.delayed.length > 0)
    return delayedActions.delayed.some((a) => a.data.appUserId === appUserId || a.data.appUserId === email);
  return false;
};
export const matchingDelayedActionForExternalUser = (delayedActions, email, appUserId) => {
  var _a;
  if (((_a = delayedActions == null ? void 0 : delayedActions.delayed) == null ? void 0 : _a.length) === 0) return void 0;
  if (delayedActions && delayedActions.delayed && delayedActions.delayed.length > 0) {
    const matchingAction = delayedActions.delayed.find(
      (a) => a.data.appUserId === appUserId || a.data.appUserId === email
    );
    return matchingAction ? matchingAction : void 0;
  }
  return void 0;
};
export const getCancellableActionMenuOption = (action) => {
  if (action === "delete") return "Cancel scheduled deletion";
  if (action === "created") return "Cancel scheduled creation";
  if (action === "suspended") return "Cancel scheduled suspension";
  if (action === "dataTransferThenDelete") return "Cancel scheduled data transfer and user deletion";
  return "Cancel";
};
export const getDelayedActionsForUserId = (delayedActions, userId, appStub) => {
  if (delayedActions && delayedActions.delayed.length > 0)
    return delayedActions.delayed.filter((a) => a.data.userId === userId && a.data.appStub === appStub && a.opts.delay);
  return [];
};
export const getDelayedActionsForExternalUser = (delayedActions, email, appUserId) => {
  if (delayedActions && delayedActions.delayed.length > 0)
    return delayedActions.delayed.filter(
      (a) => (a.data.appUserId === email || a.data.appUserId === appUserId) && a.opts.delay
    );
  return [];
};
export const hasScheduledAction = (delayedActions, userId, appStub) => {
  var _a, _b;
  return !appStub ? (_a = delayedActions == null ? void 0 : delayedActions.delayed) == null ? void 0 : _a.find((a) => a.data.userId === userId) : (_b = delayedActions == null ? void 0 : delayedActions.delayed) == null ? void 0 : _b.find((a) => a.data.userId === userId && a.data.appStub === appStub);
};
export const getScheduledActionDateString = (matchingAction) => {
  return matchingAction ? `Account will be ${matchingAction.data.action} on ${getScheduledTimeForAppAccessDetailDrawer(matchingAction)} UTC` : void 0;
};
export const getTooltipTitleForExternalScheduledStatus = (delayedActions, email, appUserId) => {
  if (delayedActions && delayedActions.delayed.length > 0) {
    const matchingAction = delayedActions.delayed.find(
      (a) => a.data.appUserId === appUserId || a.data.appUserId === email
    );
    return matchingAction ? `Scheduled for ${getStartingTimeForJob(matchingAction)}` : "";
  }
  return "";
};
export const getActiveUsersFromList = (userList) => {
  var _a, _b, _c, _d;
  const filteredUsers = (_b = (_a = userList == null ? void 0 : userList.users) == null ? void 0 : _a.filter(
    (eachUser) => {
      var _a2;
      return ((_a2 = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a2.some((eachEmail) => eachEmail.status === "Active")) || eachUser.userStatus === "Active";
    }
  )) != null ? _b : [];
  const filteredNotEnrolledUsers = (_d = (_c = userList == null ? void 0 : userList.notEnrolledUsers) == null ? void 0 : _c.filter(
    (eachUser) => {
      var _a2;
      return ((_a2 = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a2.some((eachEmail) => eachEmail.status === "Active")) || eachUser.userStatus === "Active";
    }
  )) != null ? _d : [];
  return {
    users: filteredUsers,
    notEnrolledUsers: filteredNotEnrolledUsers
  };
};
export const appsWithoutAutomaticAccountCreation = (installedApps) => {
  return installedApps.filter((eachApp) => eachApp.allowsGiveAccess === false).map((eachApp) => eachApp.stub);
};
export const APPS_PAGE_CONFIG = {
  header: {
    tabs: [
      // {
      //   label: 'Overview',
      //   value: APPS_OVERVIEW_ROUTE,
      //   scopes: ['apps:all'],
      // },
      // APPS_OVERVIEW_ROUTE temporarily removed page config, as designs for insights are not finalized
      {
        label: "Connected",
        value: APPS_ROUTE,
        scopes: AppScopes.VIEW_APPS_OVERVIEW
      },
      { label: "All apps", value: APP_STORE_ROUTE, scopes: AppScopes.VIEW_APP_STORE },
      { label: "Requests", value: APPS_REQUESTS_ROUTE, scopes: AppScopes.VIEW_APP_REQUESTS }
    ]
  }
};
