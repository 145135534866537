"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAllowanceType } from "@v2/feature/absence/absence.interface";
import { COLOR_OPTIONS } from "@v2/feature/absence/absence.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { getAllowancesUnits } from "@v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { COLOR_PICKER_COLORS } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
export const NewTimePolicyDrawer = ({ isOpen, setIsOpen, refresh }) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        name: values.name,
        color: values.color,
        allowance: values.allowance === null ? null : Number(values.allowance),
        allowanceType: values.allowance === null ? AbsencePolicyAllowanceType.Unlimited : values.allowanceUnits === "hour" ? AbsencePolicyAllowanceType.LimitedHourly : AbsencePolicyAllowanceType.Limited
      };
      try {
        setLoading(true);
        yield AbsenceAPI.createAbsencePolicy(policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("AbsenceDrawerPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, refresh, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      name: "",
      color: COLOR_PICKER_COLORS[Math.floor(Math.random() * COLOR_PICKER_COLORS.length)],
      allowance: null,
      allowanceUnits: "day"
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("NewTimePolicyDrawer.errorMessages.display")),
      color: yup.string().required(polyglot.t("NewTimePolicyDrawer.errorMessages.color")),
      allowance: yup.number().nullable().notRequired(),
      allowanceUnits: yup.string().oneOf(["day", "hour"]).required(polyglot.t("NewTimePolicyDrawer.errorMessages.units"))
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("NewTimePolicyDrawer.newPolicy") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "60px" }, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "color",
          label: polyglot.t("NewTimePolicyGeneralStep.color"),
          options: COLOR_OPTIONS,
          value: formik.values.color,
          onChange: formik.handleChange,
          compareValue: formik.values.color,
          error: !!formik.errors.color && formik.touched.color,
          helperText: formik.touched.color && formik.errors.color
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: polyglot.t("NewTimePolicyGeneralStep.fullName"),
          value: formik.values.name,
          onChange: formik.handleChange,
          error: formik.touched.name && !!formik.errors.name,
          helperText: formik.touched.name && formik.errors.name,
          endAdornment: "none",
          fullWidth: true
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mb: spacing.m5 }, children: polyglot.t("NewTimePolicyDrawer.allowanceType") }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => {
              formik.setFieldValue("allowance", null);
            },
            sizeVariant: "filter",
            colorVariant: formik.values.allowance === null ? "active" : "secondary",
            children: polyglot.t("NewTimePolicyDrawer.unlimited")
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => {
              formik.setFieldValue("allowance", 25);
            },
            sizeVariant: "filter",
            colorVariant: formik.values.allowance !== null ? "active" : "secondary",
            children: polyglot.t("NewTimePolicyDrawer.limited")
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "allowanceUnits",
          label: polyglot.t("AbsencePolicyAllowanceSection.unit"),
          options: getAllowancesUnits(polyglot),
          value: formik.values.allowanceUnits,
          onChange: formik.handleChange
        }
      ),
      formik.values.allowance !== null && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("AbsencePolicyAllowanceSection.allowance"),
          name: "allowance",
          value: formik.values.allowance,
          onChange: (e) => {
            const value = e.target.value;
            const numberRegex = /^\d*(.)?[0,5]?$/g;
            if (value.match(numberRegex)) formik.setFieldValue("allowance", value);
          },
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen2) => setUpgradeModalOpen(isOpen2),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) }) });
};
