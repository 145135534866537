"use strict";
import { jsx } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
export const SuperAdminBillingPlanEditModal = ({
  isOpen,
  setIsOpen,
  children,
  onClose,
  afterClose,
  closeIcon
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, closeIcon, children });
};
