"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteScopesControl, RouteScopesHas } from "@/component/widgets/Scopes";
import {
  SETTINGS_DOCUMENTS_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATES_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE,
  SETTINGS_DOCUMENTS_TYPES_ROUTE
} from "@/lib/routes";
import { DocumentsSettingsPage } from "@/v2/feature/documents/documents-settings.page";
import { TemplateEditorPage } from "@/v2/feature/templates/pages/template-editor-v-two.page";
import { TemplatesPage } from "@/v2/feature/templates/pages/templates.page";
export const DocumentSettingsRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SETTINGS_DOCUMENTS_TYPES_ROUTE, exact: true, children: /* @__PURE__ */ jsx(DocumentsSettingsPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["templates"], path: SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "readonly", action: "update_sign_send" }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["templates"], path: SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "readonly", action: "send" }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["templates"], path: SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "edit" }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["templates"], path: SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "readonly", action: "preview" }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["templates"], path: SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "editForRecipient", action: "update_before_sending" }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["templates"], path: SETTINGS_DOCUMENTS_TEMPLATES_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplatesPage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_DOCUMENTS_ROUTE, to: SETTINGS_DOCUMENTS_TYPES_ROUTE, exact: true })
  ] });
};
