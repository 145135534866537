"use strict";
export var AccountType = /* @__PURE__ */ ((AccountType2) => {
  AccountType2["Receivables"] = "Receivables";
  AccountType2["Revenue"] = "Revenue";
  AccountType2["Payables"] = "Payables";
  AccountType2["Expenses"] = "Expenses";
  AccountType2["Cash"] = "Cash";
  AccountType2["Deposit"] = "Deposit";
  return AccountType2;
})(AccountType || {});
export var AccountingTransactionType = /* @__PURE__ */ ((AccountingTransactionType2) => {
  AccountingTransactionType2["Asset"] = "Asset";
  AccountingTransactionType2["Income"] = "Income";
  AccountingTransactionType2["Liability"] = "Liability";
  AccountingTransactionType2["Expense"] = "Expense";
  return AccountingTransactionType2;
})(AccountingTransactionType || {});
export var AccountingTransactionStatus = /* @__PURE__ */ ((AccountingTransactionStatus2) => {
  AccountingTransactionStatus2["DRAFT"] = "DRAFT";
  AccountingTransactionStatus2["REJECTED"] = "REJECTED";
  AccountingTransactionStatus2["PAYMENT_RECEIVED"] = "PAYMENT_RECEIVED";
  AccountingTransactionStatus2["APPROVED"] = "APPROVED";
  AccountingTransactionStatus2["VOIDED"] = "VOIDED";
  return AccountingTransactionStatus2;
})(AccountingTransactionStatus || {});
