"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, Redirect, Route, Switch, useParams } from "react-router-dom";
import { DeveloperHubCredentialsSettingsPage } from "./developer-hub-credentials-settings.page";
import { DeveloperHubGeneralSettingsPage } from "./developer-hub-general-settings.page";
import { DeveloperHubPermissionsSettingsPage } from "./developer-hub-permissions-settings.page";
import { DeveloperHubPublishSettingsPage } from "./developer-hub-publish-settings.page";
import { OauthClientEndpoints } from "@/api-client/oauth-client.api";
import {
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_CREDENTIALS_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_PERMISSIONS_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_PUBLISH_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE
} from "@/lib/routes";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const getPageConfig = (appId, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("General.general"),
          stub: "general",
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE, {
            appId
          })
        },
        {
          title: polyglot.t("DeveloperHub.appCredentials"),
          stub: "credentials",
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_CREDENTIALS_ROUTE, {
            appId
          })
        },
        {
          title: polyglot.t("DeveloperHub.permissions"),
          stub: "permissions",
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_PERMISSIONS_ROUTE, {
            appId
          })
        },
        {
          title: polyglot.t("DeveloperHub.publish"),
          stub: "approval",
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_PUBLISH_ROUTE, {
            appId
          })
        }
      ]
    }
  ];
};
export const DeveloperHubAppDetailRouter = ({ refreshAppList }) => {
  const { appId } = useParams();
  const { data: oauthApp, mutate: refreshApp } = useApiClient(
    appId ? OauthClientEndpoints.getSpecificAppDetails(appId) : { url: void 0 },
    {
      suspense: false
    }
  );
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: oauthApp ? truncateWithEllipses(oauthApp == null ? void 0 : oauthApp.name) : "",
        pageConfig: getPageConfig(appId, polyglot),
        backPath: SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { children: /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE, children: oauthApp && /* @__PURE__ */ jsx(
        DeveloperHubGeneralSettingsPage,
        {
          oauthApp,
          onUpdate: () => refreshApp(),
          onDelete: () => refreshAppList()
        }
      ) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_SECURITY_DEVELOPER_HUB_APP_CREDENTIALS_ROUTE, children: oauthApp && /* @__PURE__ */ jsx(DeveloperHubCredentialsSettingsPage, { oauthApp }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_SECURITY_DEVELOPER_HUB_APP_PERMISSIONS_ROUTE, children: oauthApp && /* @__PURE__ */ jsx(DeveloperHubPermissionsSettingsPage, { oauthApp, onUpdate: () => refreshApp() }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_SECURITY_DEVELOPER_HUB_APP_PUBLISH_ROUTE, children: oauthApp && /* @__PURE__ */ jsx(DeveloperHubPublishSettingsPage, { oauthApp, onUpdate: () => refreshApp() }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE })
    ] }) })
  ] });
};
