"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { pipe } from "fp-ts/function";
import * as RA from "fp-ts/ReadonlyArray";
import {
  ALL_REGIONS_AND_COUNTRIES,
  Countries,
  NoneCountryEnum
} from "@/v2/infrastructure/country/country.interface";
export function getCountryByCode(code) {
  if (code === NoneCountryEnum.code) return { name: NoneCountryEnum.name, code: NoneCountryEnum.code };
  return Countries.find((country) => country.code === code);
}
export function getAllCountries() {
  return ALL_REGIONS_AND_COUNTRIES;
}
export const getCountryOptionsForSelectComponent = () => pipe(
  getCountriesForNationality(),
  RA.map(
    (r) => {
      var _a;
      return pipe(
        (_a = r.options) != null ? _a : [],
        RA.map((x) => __spreadProps(__spreadValues({}, x), { group: r.label }))
      );
    }
  ),
  RA.flatten
);
export function getCountriesForNationality() {
  return ALL_REGIONS_AND_COUNTRIES.map(
    (region) => region.label === "United Kingdom" ? __spreadProps(__spreadValues({}, region), { options: [{ label: "United Kingdom", value: "United Kingdom" }] }) : region
  );
}
export function getEURegionCoutry() {
  return ALL_REGIONS_AND_COUNTRIES.find((g) => g.label === "European Union");
}
export function getUKRegionCountry() {
  return ALL_REGIONS_AND_COUNTRIES.find((g) => g.label === "United Kingdom");
}
export function isUKCountryCode(code) {
  return /^(uk|gb)$/i.test(code);
}
export const isSameCountryCode = (a, b) => {
  return a === b || // UK and GB are treated as the same
  /^(uk|gb)$/i.test(a) === /^(uk|gb)$/i.test(b);
};
