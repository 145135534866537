"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { addMonths } from "date-fns";
import { Form, FormikProvider, useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { PayrunUserHeader } from "@/v2/feature/payroll/components/payrun-user-header.component";
import { extractPayLineEntriesFromPayRunEntry } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayLineTypeMenu } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payline-type-menu.component";
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from "@/v2/feature/payroll/payroll-external.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { createShortDateAsUTC } from "@/v2/util/date-format.util";
import { setFocusToInput } from "@/v2/util/element.util";
export const EditDeductionsPage = ({
  user,
  payrunEntry,
  payCodes,
  payrunClosed,
  saveIncomeUpdates
}) => {
  const [savingUpdate, setSavingUpdate] = useState(false);
  const [focusedTextEditId, setFocusedTextEditId] = useState("");
  const initialValues = useMemo(() => {
    return {
      paye: payrunEntry.totals.tax,
      employeeNi: payrunEntry.totals.employeeNi,
      employeePension: payrunEntry.totals.employeePensionContribution,
      studentLoan: payrunEntry.totals.studentLoanRecovered,
      postgradLoan: payrunEntry.totals.postgradLoanRecovered,
      deductions: extractPayLineEntriesFromPayRunEntry(payrunEntry, payCodes, "deduction")
    };
  }, [payCodes, payrunEntry]);
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSavingUpdate(true);
      const setIfChanged = (key) => values[key] !== formik.initialValues[key] ? values[key] : void 0;
      const update = {
        id: payrunEntry.id,
        userId: user.userId,
        deductions: {
          paye: setIfChanged("paye"),
          employeeNi: setIfChanged("employeeNi"),
          studentLoan: setIfChanged("studentLoan"),
          postgradLoan: setIfChanged("postgradLoan"),
          paylines: values.deductions
        }
      };
      yield saveIncomeUpdates([update]);
      setSavingUpdate(false);
    }),
    enableReinitialize: true
  });
  useEffect(() => {
    if (!focusedTextEditId) return;
    setFocusToInput(focusedTextEditId);
  }, [focusedTextEditId]);
  const updateDeductions = useCallback((f, entry) => {
    const idx = f.values.deductions.findIndex(({ id }) => id === entry.id);
    const updatedDeductions = [...f.values.deductions];
    if (idx >= 0) {
      updatedDeductions[idx] = entry;
    } else {
      updatedDeductions.push(entry);
    }
    f.setFieldValue("deductions", updatedDeductions);
  }, []);
  const createDeductionPayLine = useCallback(
    (f, { code, title }) => {
      const newEntry = {
        id: uuidv4(),
        code,
        amount: 0,
        description: title,
        isDeduction: true,
        recurringId: null,
        recurring: null
      };
      updateDeductions(f, newEntry);
      return newEntry;
    },
    [updateDeductions]
  );
  const updateRecurringRange = useCallback(
    (value, which, recurring) => {
      let { startDate, endDate } = recurring;
      const newDate = value;
      switch (which) {
        case "start":
          startDate = newDate;
          endDate = newDate > endDate ? newDate : endDate;
          break;
        case "end":
          endDate = newDate;
          startDate = newDate < startDate ? newDate : startDate;
          break;
      }
      return {
        startDate,
        endDate
      };
    },
    []
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Edit deductions" }),
    /* @__PURE__ */ jsx(PayrunUserHeader, { user, sx: { mt: spacing.m10 } }),
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsxs(Stack, { flex: 1, sx: { pt: spacing.p30 }, children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "paye",
              label: "PAYE",
              value: formik.values.paye,
              onChange: (value) => formik.setFieldValue("paye", value),
              emptyIsZero: true,
              disabled: savingUpdate || payrunClosed
            }
          ),
          /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "ni",
              label: "National insurance",
              value: formik.values.employeeNi,
              onChange: (value) => formik.setFieldValue("employeeNi", value),
              emptyIsZero: true,
              disabled: savingUpdate || payrunClosed
            }
          ),
          "employeePensionContribution" in payrunEntry.totals && /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "employeePension",
              label: "Pension",
              value: formik.values.employeePension,
              onChange: () => {
              },
              emptyIsZero: true,
              disabled: true
            }
          ),
          payrunEntry.payOptions.taxAndNi.studentLoan !== "None" && /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "studentLoan",
              label: "Student loan",
              value: formik.values.studentLoan,
              onChange: (value) => formik.setFieldValue("studentLoan", value),
              emptyIsZero: true,
              disabled: savingUpdate || payrunClosed
            }
          ),
          payrunEntry.payOptions.taxAndNi.postgradLoan && /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "postgradLoan",
              label: "Postgraduate loan",
              value: formik.values.postgradLoan,
              onChange: (value) => formik.setFieldValue("postgradLoan", value),
              emptyIsZero: true,
              disabled: savingUpdate || payrunClosed
            }
          )
        ] }),
        formik.values.deductions.sort((a, b) => a.code.localeCompare(b.code) || a.id.localeCompare(b.id)).map((deduction) => {
          const { id, code, description, amount, recurringId, recurring } = deduction;
          return /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.m60 }, children: [
            /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: code }) }),
            /* @__PURE__ */ jsx(
              MoneyTextfieldComponent,
              {
                id,
                name: id,
                label: "Amount",
                value: amount,
                onChange: (amount2) => typeof amount2 === "number" && updateDeductions(formik, __spreadProps(__spreadValues({}, deduction), { amount: amount2 })),
                disabled: savingUpdate || payrunClosed,
                emptyIsZero: true,
                clearToZero: true,
                allowNegative: true,
                sx: { mt: spacing.mt20 }
              }
            ),
            /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                name: `${id}Description`,
                label: "Description",
                value: description,
                maxLength: MAX_PAYLINE_DESCRIPTION_LENGTH,
                onChange: (e) => updateDeductions(formik, __spreadProps(__spreadValues({}, deduction), { description: e.target.value })),
                clearText: () => updateDeductions(formik, __spreadProps(__spreadValues({}, deduction), { description: "" })),
                disabled: savingUpdate || payrunClosed,
                sx: { mt: spacing.m30 }
              }
            ),
            /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                checked: !!recurring,
                onChange: (_, checked) => updateDeductions(formik, __spreadProps(__spreadValues({}, deduction), {
                  recurringId,
                  recurring: checked ? {
                    startDate: payrunEntry.startDate,
                    endDate: createShortDateAsUTC(addMonths(Date.parse(payrunEntry.endDate), 2))
                  } : null
                })),
                label: "Recurring",
                labelSx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }),
                disabled: savingUpdate || payrunClosed,
                sx: { mt: spacing.mt20 }
              }
            ),
            recurring && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20, mt: spacing.m30 }, children: [
              /* @__PURE__ */ jsx(
                DatePickerComponent,
                {
                  name: `${id}StartDate`,
                  label: "Effective start",
                  inputFormat: "DD MMM YYYY",
                  minDate: payrunEntry.startDate,
                  maxDate: payrunEntry.endDate,
                  value: recurring.startDate,
                  onChange: (value) => updateDeductions(formik, __spreadProps(__spreadValues({}, deduction), {
                    recurring: updateRecurringRange(value, "start", recurring)
                  })),
                  disabled: savingUpdate || payrunClosed
                }
              ),
              /* @__PURE__ */ jsx(
                DatePickerComponent,
                {
                  name: `${id}EndDate`,
                  label: "Effective end",
                  inputFormat: "DD MMM YYYY",
                  minDate: addMonths(Date.parse(payrunEntry.startDate), 1),
                  value: recurring.endDate,
                  onChange: (value) => updateDeductions(formik, __spreadProps(__spreadValues({}, deduction), {
                    recurring: updateRecurringRange(value, "end", recurring)
                  })),
                  disabled: savingUpdate || payrunClosed
                }
              )
            ] })
          ] }) }, id);
        })
      ] }),
      payrunClosed && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m30, color: themeColors.DarkGrey }), children: "Deductions cannot be changed because the payrun is closed." }),
      !payrunClosed && /* @__PURE__ */ jsx(
        PayLineTypeMenu,
        {
          kind: "deduction",
          payCodes,
          disabled: savingUpdate,
          onMenuItemClick: (payCode) => {
            const newEntry = createDeductionPayLine(formik, payCode);
            setFocusedTextEditId(newEntry.id);
          },
          sx: { mt: spacing.m30 }
        }
      ),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.m30, flexFlow: "row", alignItems: "center", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: "Total deductions" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: formatCurrency(payrunEntry.totals.deductions) })
      ] }),
      !payrunClosed && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          loading: savingUpdate,
          name: "Save",
          type: "submit",
          sizeVariant: "large",
          colorVariant: "primary",
          style: { marginTop: spacing.m30 }
        }
      )
    ] }) })
  ] });
};
