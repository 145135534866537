"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@/v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { useCallback, useMemo, useState } from "react";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { TableSearch } from "@v2/components/table/table-search.component";
import { spacing } from "@v2/styles/spacing.styles";
import { Box, IconButton } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { NumberCell } from "@v2/components/table/number-cell.component";
import { ScopesControl } from "@/component/widgets/Scopes";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { iconSize } from "@v2/styles/menu.styles";
import { UpdateUpcomingPayItemDrawer } from "@/component/dashboard/userDetails/pay-items/components/update-upcoming-pay-item-drawer.component";
import { round2Digits } from "@v2/util/number.util";
import { AddNewPayItemDrawer } from "@/component/dashboard/userDetails/pay-items/components/add-new-pay-item-drawer.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { ViewUpcomingPayItemDrawer } from "@/component/dashboard/userDetails/pay-items/components/view-upcoming-pay-item-drawer.component";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const UpcomingPayItemsTable = ({
  title,
  userId,
  oneOffPayments,
  refresh,
  showAdd = false,
  showEdit = false
}) => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const [selectedPayItem, setSelectedPayItem] = useState(null);
  const [editUpcomingPayItem, setEditUpcomingPayItem] = useState(false);
  const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
  const [isAddNewOpen, setIsAddNewOpen] = useState(false);
  const filteredPayItems = useMemo(() => {
    if (!oneOffPayments) return [];
    if (!searchInput) return [...oneOffPayments];
    const search = searchInput.toLowerCase();
    return oneOffPayments.filter((pI) => pI.description.toLowerCase().includes(search));
  }, [searchInput, oneOffPayments]);
  const tableColumns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("General.date"),
        accessorFn: (row) => row,
        id: "date",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.date),
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t(original.date) });
        }
      },
      {
        header: () => polyglot.t("General.description"),
        accessorFn: (row) => row,
        id: "description",
        minSize: 200,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.description });
        }
      },
      {
        header: () => polyglot.t("PayItemModule.amount"),
        accessorFn: (row) => row,
        id: "amount",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.amount * item.multiplier),
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(
            NumberCell,
            {
              value: (_a = round2Digits(original.amount * original.multiplier)) != null ? _a : 0,
              cellSx: { maxWidth: "50px", justifyContent: "center" }
            }
          );
        }
      },
      {
        header: () => polyglot.t("PayItemModule.payCode"),
        accessorFn: (row) => row,
        id: "payCode",
        enableSorting: true,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.payCode });
        }
      },
      {
        header: () => polyglot.t("PensionModule.state"),
        accessorFn: (row) => row,
        id: "paidImpliedStatus",
        enableSorting: true,
        cell: ({ row: { original } }) => {
          return original.paidImpliedStatus ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("PayItemModule.source"),
        accessorFn: (row) => row,
        id: "source",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.status),
        cell: ({ row: { original } }) => {
          if (original.autogenerated && original.absenceId)
            return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PayItemModule.autogeneratedFromAbsence") });
          if (original.autogenerated && original.userCustomBenefitPaymentId)
            return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PayItemModule.autogeneratedFromBenefit") });
          if (original.autogenerated)
            return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PayItemModule.autogenerated") });
          return /* @__PURE__ */ jsx(UserCell, { userId: original.createdBy, prefix: polyglot.t("PayItemModule.createdBy") });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        maxSize: 50,
        minSize: 50,
        enableSorting: false,
        cell: ({ row: { original } }) => (
          // can only edit pay item if not historical / not paid
          original.id && !original.paidImpliedStatus ? /* @__PURE__ */ jsx(
            Box,
            {
              sx: { display: "flex", justifyContent: "flex-end", cursor: "default" },
              onClick: (e) => e.stopPropagation(),
              children: /* @__PURE__ */ jsx(ScopesControl, { scopes: ["payroll:all"], context: { userId: original.userId }, children: /* @__PURE__ */ jsx(
                IconButton,
                {
                  title: polyglot.t("CompensationCard.edit"),
                  onClick: () => {
                    setSelectedPayItem(original);
                    setEditUpcomingPayItem(true);
                  },
                  sx: tableIconButtonSx,
                  children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
                }
              ) })
            }
          ) : null
        )
      }
    ];
  }, [polyglot]);
  const handleRowClick = useCallback((row) => {
    setSelectedPayItem(row.original);
    setIsViewDrawerOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mb: spacing.m5 }, children: title }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ) }),
      showAdd && /* @__PURE__ */ jsx(ScopesControl, { scopes: ["payroll:all"], context: { userId }, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: () => setIsAddNewOpen(true), children: polyglot.t("General.add") }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m20 }, children: /* @__PURE__ */ jsx(BasicTable, { rowData: filteredPayItems, columnData: tableColumns, rowClick: handleRowClick }) }),
    /* @__PURE__ */ jsx(
      ViewUpcomingPayItemDrawer,
      {
        isOpen: isViewDrawerOpen,
        setIsOpen: setIsViewDrawerOpen,
        oneOffPayment: selectedPayItem,
        refresh,
        onClose: () => setSelectedPayItem(null),
        showDelete: !(selectedPayItem == null ? void 0 : selectedPayItem.paidImpliedStatus)
      }
    ),
    showEdit && editUpcomingPayItem && selectedPayItem && /* @__PURE__ */ jsx(
      UpdateUpcomingPayItemDrawer,
      {
        isOpen: editUpcomingPayItem,
        setIsOpen: setEditUpcomingPayItem,
        oneOffPayment: selectedPayItem,
        refresh,
        onClose: () => setSelectedPayItem(null)
      }
    ),
    isAddNewOpen && /* @__PURE__ */ jsx(AddNewPayItemDrawer, { isOpen: isAddNewOpen, setIsOpen: setIsAddNewOpen, userId, refresh })
  ] });
};
