"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { Button } from "@mui/base/Button";
import "@/v2/scss/components/icon-button-component.scss";
export const IconButton = forwardRef(
  (_a, ref) => {
    var _b = _a, { className, sizeVariant, colorVariant, fullWidth, style, children } = _b, otherProps = __objRest(_b, ["className", "sizeVariant", "colorVariant", "fullWidth", "style", "children"]);
    return /* @__PURE__ */ jsx(
      Button,
      __spreadProps(__spreadValues({
        ref
      }, otherProps), {
        color: colorVariant,
        className: `${className} ${sizeVariant}-icon-button ${colorVariant}-icon-button ${fullWidth ? "fullWidth-button" : ""}`,
        style,
        children
      })
    );
  }
);
