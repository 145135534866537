"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const AbsenceAdjustmentImportDrawer = ({
  isOpen,
  setIsOpen,
  entity,
  absencePolicies,
  importHandler,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: entity ? /* @__PURE__ */ jsx(
    AbsenceAdjustmentImportDrawerContent,
    {
      entity,
      importHandler,
      absencePolicies,
      onClose
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
export const AbsenceAdjustmentImportDrawerContent = ({
  entity,
  importHandler,
  absencePolicies,
  onClose
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const policyOptions = useMemo(() => {
    return absencePolicies.map((p) => ({ value: p.id, label: p.fullName }));
  }, [absencePolicies]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        importHandler(values);
        onClose();
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [importHandler, onClose, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      id: entity.id,
      firstName: entity.firstName,
      lastName: entity.lastName,
      workEmail: entity.workEmail,
      effectiveYear: entity.effectiveYear,
      policyName: entity.policyName,
      adjustment: entity.adjustment,
      unit: ["Days", "Hours"].includes(entity.unit) ? entity.unit : "",
      note: entity.note,
      policyId: (_a = absencePolicies.find((p) => p.id === entity.policyId)) == null ? void 0 : _a.id,
      userId: entity.userId ? (_b = getCachedUserById(entity.userId)) == null ? void 0 : _b.userId : entity.userId
    },
    validationSchema: yup.object({
      id: yup.string().required("Row id is required"),
      userId: yup.number().integer("User ID is required").required("User is required"),
      policyId: yup.number().integer("Policy ID is required").required("Policy is required"),
      firstName: yup.string().required("User is required"),
      lastName: yup.string().required("User is required"),
      policyName: yup.string().required("Policy is required"),
      effectiveYear: yup.number().integer("Please input a valid effective year").required(polyglot.t("ValidationMessages.requiredField")),
      adjustment: yup.number().typeError("Please input a valid number").required("Adjustment is required"),
      unit: yup.string().oneOf(["Days", "Hours"], 'Please select one of "Day" or "Hours').required("Unit is required"),
      note: yup.string().nullable().notRequired()
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Update adjustment" }),
    /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: "company",
        allUsers: true,
        onChange: (_, x) => {
          if (!(x == null ? void 0 : x.value)) return;
          const userId = x.value;
          formik.setFieldValue("userId", userId != null ? userId : null);
          const user = getCachedUserById(userId);
          if (user) {
            formik.setFieldValue("firstName", user.firstName);
            formik.setFieldValue("lastName", user.lastName);
            formik.setFieldValue("workEmail", user.emailAddress);
          }
        },
        value: formik.values.userId,
        error: formik.touched.userId && Boolean(formik.errors.userId),
        helperText: formik.touched.userId && formik.errors.userId
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "policyId",
        label: "Policy",
        options: policyOptions,
        value: (_c = formik.values.policyId) != null ? _c : "",
        onChange: (e) => {
          formik.handleChange(e);
          const policyId = Number(e.target.value);
          const policy = absencePolicies.find((p) => p.id === policyId);
          if (policy) formik.setFieldValue("policyName", policy.fullName);
        },
        compareValue: formik.values.policyId,
        error: !!formik.errors.policyId && formik.touched.policyId,
        helperText: formik.touched.policyId && formik.errors.policyId
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "effectiveYear",
        label: "Effective year",
        value: formik.values.effectiveYear,
        onChange: formik.handleChange,
        error: formik.touched.effectiveYear && !!formik.errors.effectiveYear,
        helperText: (_d = formik.touched.effectiveYear && formik.errors.effectiveYear) != null ? _d : " "
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "adjustment",
        label: "Adjustment",
        value: formik.values.adjustment,
        onChange: formik.handleChange,
        error: formik.touched.adjustment && !!formik.errors.adjustment,
        helperText: (_e = formik.touched.adjustment && formik.errors.adjustment) != null ? _e : " "
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "unit",
        label: "Unit",
        options: [
          { value: "Days", label: "Days" },
          { value: "Hours", label: "Hours" }
        ],
        value: formik.values.unit,
        onChange: formik.handleChange,
        compareValue: formik.values.unit,
        error: !!formik.errors.unit && formik.touched.unit,
        helperText: formik.touched.unit && formik.errors.unit
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "note",
        label: "Note",
        value: formik.values.note,
        onChange: formik.handleChange,
        error: formik.touched.note && !!formik.errors.note,
        helperText: (_f = formik.touched.note && formik.errors.note) != null ? _f : " "
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.update"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
