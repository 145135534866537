"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { iconSize } from "@v2/styles/menu.styles";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
export const ReportsAlertMissingScopes = ({ tooltip }) => {
  return /* @__PURE__ */ jsx(StyledTooltip, { placement: "top", title: tooltip, children: /* @__PURE__ */ jsx(Box, { sx: iconSize, children: /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize)) }) });
};
