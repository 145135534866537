"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { getModelImage } from "@v2/feature/device/device.util";
import { titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TECH_ROUTE } from "@/lib/routes";
export const DeleteDeviceDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  onDelete
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(DeleteDeviceDrawerContent, { devicePossession, onDelete }) });
};
const DeleteDeviceDrawerContent = ({ devicePossession, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const deleteDevice = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield DeviceAPI.deleteCompanyOwnedDeviceByDevicePossessionId(devicePossession.id);
      showMessage(`Device deleted successfully`, "success");
      yield onDelete();
      history.push(TECH_ROUTE);
    } catch (error) {
      showMessage(`Could not delete device. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Delete device" }),
    devicePossession.device && /* @__PURE__ */ jsxs(Box, { sx: { mb: 2 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { mb: 1 }, children: getModelImage(devicePossession.device.type, devicePossession.device.modelName, {
        width: "200px",
        height: "auto"
      }) }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: [
        "Model Name: ",
        devicePossession.device.modelName
      ] }),
      devicePossession.device.modelNumber && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: [
        "Model Number: ",
        devicePossession.device.modelNumber
      ] }),
      devicePossession.device.serialNumber && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: [
        "Serial Number: ",
        devicePossession.device.serialNumber
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Delete device",
        loading,
        onClick: deleteDevice,
        fullWidth: true,
        colorVariant: "danger",
        sizeVariant: "small"
      }
    ) })
  ] });
};
