"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { CompanyPayrollType } from "@v2/feature/payroll/payroll.interface";
import { useHistory } from "react-router-dom";
import { PayrunHeader } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header.component";
import {
  PayrunStepper
} from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun.stepper.component";
import { PayrunCompleteSection } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-complete.section";
import { PayrunProcessSection } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-process.section";
import { PayrunReviewSection } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-review.section";
import { UKPayrunSalariesSection } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-salaries.section";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrunFlowPage = ({
  entityName,
  externalPayRun,
  entries,
  previousEntries,
  localPayRun,
  payrunEmpAllowance,
  refreshPayrun,
  startPayrun,
  completePayrun
}) => {
  const routerHistory = useHistory();
  const [step, setStep] = useState("Salaries");
  const isReopening = useRef(false);
  if (isReopening.current) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, overflow: "hidden" }, children: [
    /* @__PURE__ */ jsx(
      PayrunHeader,
      {
        entityName,
        payrollId: localPayRun.payrollId,
        payrollType: CompanyPayrollType.UKPayroll,
        state: localPayRun.state,
        onReopenPayrun: () => __async(void 0, null, function* () {
          isReopening.current = true;
          yield refreshPayrun();
          isReopening.current = false;
          setStep("Salaries");
        }),
        sx: { mt: spacing.m20, mr: spacing.mr40 }
      }
    ),
    /* @__PURE__ */ jsx(PayrunStepper, { activeStep: step, sx: { mt: spacing.m30, mr: spacing.mr40 } }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 } }),
    {
      Salaries: () => /* @__PURE__ */ jsx(
        UKPayrunSalariesSection,
        {
          localPayRun,
          entries,
          previousEntries,
          onBack: () => {
            routerHistory.goBack();
          },
          onNext: () => setStep("Review"),
          refreshPayrun
        }
      ),
      Review: () => /* @__PURE__ */ jsx(
        PayrunReviewSection,
        {
          payrollId: localPayRun.payrollId,
          payrun: externalPayRun,
          payrunEmpAllowance,
          startPayrunProcess: startPayrun,
          onBack: () => setStep("Salaries"),
          onNext: () => setStep("Process")
        }
      ),
      Process: () => /* @__PURE__ */ jsx(
        PayrunProcessSection,
        {
          localPayRun,
          completePayrun,
          onNext: () => setStep("Complete")
        }
      ),
      Complete: () => /* @__PURE__ */ jsx(PayrunCompleteSection, { payrun: localPayRun })
    }[step]()
  ] });
};
