"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
const _AppDetailsAPI = class _AppDetailsAPI {
};
_AppDetailsAPI.getIntegrationData = (appStub) => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.get(`/apiv2/apps/${appStub}`)).data;
});
_AppDetailsAPI.saveOrUpdateIntegrationCredentials = (appStub, credentials) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/${appStub}/credentials/upsert`, { credentials });
});
_AppDetailsAPI.testBasicAuthCredentials = (appStub, credentials) => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.post(`/apiv2/apps/${appStub}/test-basic-auth`, { credentials })).data;
});
_AppDetailsAPI.saveIntegrationConfiguration = (appStub, configuration) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/${appStub}/credentials/configuration`, configuration);
});
_AppDetailsAPI.generateCredentials = (appStub) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/${appStub}/credentials/generate-saml-credentials`);
});
_AppDetailsAPI.fetchConfig = (appStub) => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.get(`/apiv2/apps/${appStub}/credentials/saml-certificate`)).data;
});
_AppDetailsAPI.getSSOSamlMetadata = (appStub) => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.get(`/apiv2/apps/${appStub}/credentials/saml-metadata`)).data;
});
_AppDetailsAPI.getSSOMetadata = () => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.get(`/apiv2/apps/credentials/sso-metadata`)).data;
});
_AppDetailsAPI.getOnboardingAutomaticConfiguration = (appStub) => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.get(`/apiv2/apps/${appStub}/onboarding/configuration`)).data;
});
_AppDetailsAPI.updateOnboardingTemplateSelectionForWebhook = (appStub, payload) => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.patch(`/apiv2/apps/${appStub}/onboarding/configuration`, payload)).data;
});
_AppDetailsAPI.saveSSOSamlMetadata = (appStub, payload) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/${appStub}/credentials/saml-metadata/upsert`, payload);
});
_AppDetailsAPI.saveSSOktaMetadata = (payload) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/sso-okta/credentials/oidc-metadata/upsert`, payload);
});
_AppDetailsAPI.disableSSOOkta = () => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/sso-okta/credentials/oidc/disable`);
});
_AppDetailsAPI.disableSSOGW = () => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/sso-google-workspace/credentials/saml/disable`);
});
_AppDetailsAPI.disableSSOSaml = (appStub) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/${appStub}/credentials/saml/disable`);
});
_AppDetailsAPI.superAdminDisableSSOOkta = (companyId) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/sso-okta/credentials/oidc/superadmin/disable/${companyId.toString()}`);
});
_AppDetailsAPI.superadminDisableSSOGW = (companyId) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/sso-google-workspace/credentials/saml/superadmin/disable/${companyId.toString()}`);
});
_AppDetailsAPI.superAdminDisableSSOAzureAdSaml = (companyId) => __async(_AppDetailsAPI, null, function* () {
  yield axios.post(`/apiv2/apps/sso-azure-ad/credentials/saml/superadmin/disable/${companyId.toString()}`);
});
_AppDetailsAPI.getKPIsForApp = (appStub) => __async(_AppDetailsAPI, null, function* () {
  var _a;
  return (_a = yield axios.get(`/apiv2/apps/kpi/${appStub}`)) == null ? void 0 : _a.data;
});
_AppDetailsAPI.oauthConfigForBasicAuthApp = (appStub) => __async(_AppDetailsAPI, null, function* () {
  return (yield axios.get(`/apiv2/apps/${appStub}-oauth/instance/configuration`)).data;
});
export let AppDetailsAPI = _AppDetailsAPI;
export class AppDetailsEndpoints {
  static getAppDetails(appStub) {
    return {
      url: `/apiv2/apps/${appStub}`
    };
  }
  static getOnboardingConfiguration(appStub) {
    return {
      url: `/apiv2/apps/${appStub}/onboarding/configuration`
    };
  }
  static getSSOMetadata() {
    return {
      url: "/apiv2/apps/credentials/sso-metadata"
    };
  }
}
