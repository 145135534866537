"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DisableMFAPage = ({ onClose }) => {
  const { polyglot } = usePolyglot();
  const [disablingMFA, setDisablingMFA] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const checkPasswordAndDisableMFA = useCallback(
    (pwd) => {
      if (!pwd) return;
      (() => __async(void 0, null, function* () {
        var _a;
        setDisablingMFA(true);
        setErrorMsg("");
        try {
          yield AuthAPI.updateMFA({ disableMFAPwd: pwd });
          onClose();
        } catch (err) {
          if (((_a = err.response) == null ? void 0 : _a.status) === 401) {
            setErrorMsg(polyglot.t("DisableMFAPage.incorrectPassword"));
          } else {
            setErrorMsg(err.toString());
          }
        } finally {
          setDisablingMFA(false);
        }
      }))();
    },
    [polyglot, onClose]
  );
  return /* @__PURE__ */ jsxs(
    "form",
    {
      onSubmit: (e) => {
        checkPasswordAndDisableMFA(password);
        e.preventDefault();
      },
      children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { mb: "30px" }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: polyglot.t("DisableMFAPage.pleaseEnterPassword") }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: polyglot.t("DisableMFAPage.toDisable") })
        ] }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            type: "password",
            disabled: disablingMFA,
            onChange: (e) => {
              setPassword(e.target.value);
              setErrorMsg("");
            },
            value: password,
            error: !!errorMsg,
            helperText: errorMsg,
            autoFocus: true
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.continue"),
            sizeVariant: "medium",
            loading: disablingMFA,
            colorVariant: "primary",
            type: "submit",
            fullWidth: true,
            style: { marginTop: "40px !important" }
          }
        )
      ]
    }
  );
};
