"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Minus } from "@/images/side-bar-icons/Minus.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
export const PayrollUserActionMenu = ({
  onAddToPayrollClick,
  onRemoveFromPayrollClick
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options: [
        {
          label: "Add to payroll",
          icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
          handler: onAddToPayrollClick
        },
        {
          label: "Remove from payroll",
          icon: /* @__PURE__ */ jsx(Minus, __spreadValues({}, iconSize)),
          handler: onRemoveFromPayrollClick
        }
      ],
      actionButtonDetails: {
        type: "button",
        colorVariant: "secondary",
        sizeVariant: "small",
        title: polyglot.t("General.actions"),
        icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
        iconPosition: "end"
      }
    }
  );
};
