"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { ONBOARDING_ROUTE, ONBOARDING_USER_STATUS_ROUTE } from "@/lib/routes";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceStoreView } from "@/v2/feature/device/features/devices-company/pages/device-store.page";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const OnboardingDeviceOrderPage = () => {
  const params = useParams();
  const routerHistory = useHistory();
  const [cart, setCart] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const userId = Number(params.userId);
  useEffect(() => {
    if (cart.length) {
      setIsOpen(true);
    }
  }, [cart]);
  useEscapeKey(() => routerHistory.goBack());
  const user = userId && getCachedUserById(userId);
  if (!user) {
    routerHistory.push(ONBOARDING_ROUTE);
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30, position: "absolute", zIndex: 10, inset: 0, background: themeColors.white }, children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { ml: spacing.ml40 }), children: "Choose the device" }),
        showBack: true
      }
    ),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "auto", mt: "100px" }, children: /* @__PURE__ */ jsx(
      DeviceStoreView,
      {
        cartState: [cart, setCart],
        orderFormOpen: isOpen,
        reach: "company",
        onClose: (orderComplete) => __async(void 0, null, function* () {
          if (orderComplete) {
            routerHistory.replace(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: user.userId }));
            return;
          }
          setCart([]);
        }),
        possessionId: userId,
        sx: { mx: "140px", my: spacing.my20 }
      }
    ) })
  ] });
};
