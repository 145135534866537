"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Stack, SwipeableDrawer } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { getCountryNameForCode } from "@/v2/feature/payments/payments.util";
import { formatPayPeriod } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { SetupPayrollDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-intro/set-up-payroll-drawer.component";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { caseInsensitiveSort, filterByTextSearch } from "@/v2/util/array.util";
export const PayrollCompanySettingsPayrollList = () => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const routerHistory = useHistory();
  const [showPayrollSetupDrawer, setShowPayrollSetupDrawer] = useState(false);
  const { data: payrolls, isLoading } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });
  const sortedPayrolls = useMemo(
    () => {
      var _a;
      return (_a = payrolls == null ? void 0 : payrolls.items.sort((a, b) => caseInsensitiveSort(a, b, (entry) => entry.entity.legalName))) != null ? _a : [];
    },
    [payrolls]
  );
  const filteredPayrolls = useMemo(
    () => filterByTextSearch(searchInput, sortedPayrolls, (payroll) => [payroll.entity.legalName]),
    [searchInput, sortedPayrolls]
  );
  const goToPayrollSettings = useCallback(
    (payrollId) => {
      routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId }));
    },
    [routerHistory]
  );
  const launchNewPayroll = useCallback(() => {
    setShowPayrollSetupDrawer(true);
  }, []);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: payrolls === void 0, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("CompanyPayroll.salary"),
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => launchNewPayroll(), sizeVariant: "small", colorVariant: "primary", children: polyglot.t("PayrollSettings.addPayroll") })
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            loadingAll: isLoading,
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => setSearchInput(e.target.value) })
                  },
                  {
                    type: SectionItemType.Component,
                    value: filteredPayrolls ? /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (filteredPayrolls || []).map((item) => {
                      var _a, _b;
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
                            /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "1.2em", width: "1.2em", padding: ".25em" }),
                            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Zelt" })
                          ] }),
                          boxSx: responsiveCardStyle,
                          contentItemsSets: [
                            {
                              name: (_b = getCountryNameForCode((_a = item.entity.address) == null ? void 0 : _a.countryCode)) != null ? _b : "\u2013",
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            },
                            {
                              name: item.entity.legalName,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            },
                            {
                              name: item.schedule ? formatPayPeriod(item.schedule.payPeriod, polyglot) : "\u2013",
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ],
                          boxAction: () => goToPayrollSettings(item.id)
                        },
                        item.id
                      );
                    }) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "No payroll found" })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      SwipeableDrawer,
      {
        anchor: "right",
        open: showPayrollSetupDrawer,
        onOpen: () => {
        },
        onClose: () => setShowPayrollSetupDrawer(false),
        children: showPayrollSetupDrawer && /* @__PURE__ */ jsx(SetupPayrollDrawer, {})
      }
    )
  ] });
};
