"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class CompensationAPI {
  static findByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/compensation`)).data;
    });
  }
  static calculateSalaryFixedRates(userId, data) {
    return __async(this, null, function* () {
      const query = `salaryBasis=${data.salaryBasis}&rate=${data.rate}&paySchedule=${data.paySchedule}&units=${data.units}&currency=${data.currency}`;
      return (yield axios.get(`/apiv2/users/${userId}/compensation-breakdown?${query}`)).data;
    });
  }
  static findCurrentByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/compensation/current`)).data;
    });
  }
  static create(userId, userCompensation) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/compensation`, userCompensation);
    });
  }
  static updateById(userId, compensationUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/compensation/${compensationUpdate.id}`, compensationUpdate);
    });
  }
  static deleteById(userId, compensationId) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/users/${userId}/compensation/${compensationId}`);
    });
  }
  static getCompensationByCompanyId(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/superadmin/company/${companyId}/compensation`)).data;
    });
  }
  static bulkDeleteCompensationByIds(companyId, compensationIds) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/superadmin/company/${companyId}/compensation`, { data: compensationIds });
    });
  }
}
export class CompensationEndpoints {
  static getFTEPercentageForUser(userId) {
    return {
      url: `/apiv2/users/${userId}/ftepercentage`
    };
  }
  static findCurrentByUserId(userId) {
    return {
      url: `/apiv2/users/${userId}/compensation/current`
    };
  }
}
