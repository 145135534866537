"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const SectionSchema = () => Yup.object().shape({
  name: Yup.string().required()
});
export const SectionModalContent = ({
  onClose,
  section,
  refresh,
  onSubmit
}) => {
  var _a;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      name: section ? section.name : ""
    },
    enableReinitialize: true,
    validationSchema: SectionSchema(),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield onSubmit(values);
        refresh();
        onClose();
      } catch (error) {
        showMessage("Couldn't create the section", "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, style: { height: "100%" }, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m10 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: section ? "Edit section" : "New section" }) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              multiline: true,
              name: "name",
              label: "Name",
              value: formik.values.name,
              type: "text",
              onChange: formik.handleChange,
              error: formik.touched.name && !!formik.errors.name,
              helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " ",
              endAdornment: "none"
            }
          ) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        ) })
      ]
    }
  ) }) });
};
