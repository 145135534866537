"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { BillingInvoiceAPI } from "@/api-client/billing-invoice.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  ChargeTypes
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/one-off-charge.interface";
export const SuperAdminBillingNewOneOffChargeDrawer = ({
  isOpen,
  setIsOpen,
  refresh,
  companyList,
  chargeToEdit
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    SuperAdminBillingNewOneOffChargeDrawerContent,
    {
      refresh,
      setIsOpen,
      companyList,
      chargeToEdit
    }
  ) });
};
export const SuperAdminBillingNewOneOffChargeDrawerContent = ({
  refresh,
  setIsOpen,
  companyList,
  chargeToEdit
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const allCompanyOptions = useMemo(() => {
    return companyList.map((c) => ({ value: c.companyId, label: `${c.name} (${c.companyId})` }));
  }, [companyList]);
  const typeOptions = Object.values(ChargeTypes).map((v) => ({ value: v, label: v }));
  const originalCompanyIdForCharge = chargeToEdit == null ? void 0 : chargeToEdit.companyId;
  const editMode = chargeToEdit && chargeToEdit.id;
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (editMode && originalCompanyIdForCharge)
          yield BillingInvoiceAPI.updateExistingChargeAsSuperadmin(originalCompanyIdForCharge, values);
        else yield BillingInvoiceAPI.createNewChargeAsSuperadmin(values);
        yield refresh();
        setIsOpen(false);
        showMessage("Charge created successfully", "success");
      } catch (error) {
        showMessage(`Soemthing went wrong ${nestErrorMessage(error)})`, "error");
      } finally {
        setLoading(false);
      }
    }),
    [editMode, originalCompanyIdForCharge, refresh, setIsOpen, showMessage]
  );
  const formik = useFormik({
    initialValues: chargeToEdit ? {
      id: (_a = chargeToEdit == null ? void 0 : chargeToEdit.id) != null ? _a : null,
      companyId: (_b = chargeToEdit == null ? void 0 : chargeToEdit.companyId) != null ? _b : 0,
      userId: (_c = chargeToEdit == null ? void 0 : chargeToEdit.userId) != null ? _c : 0,
      amount: (_d = chargeToEdit == null ? void 0 : chargeToEdit.amount) != null ? _d : 0,
      type: (_e = chargeToEdit == null ? void 0 : chargeToEdit.type) != null ? _e : void 0,
      serialNumber: (_f = chargeToEdit == null ? void 0 : chargeToEdit.serialNumber) != null ? _f : void 0,
      memo: (_g = chargeToEdit == null ? void 0 : chargeToEdit.memo) != null ? _g : ""
    } : { id: null, companyId: 0, userId: 0, amount: 0, type: void 0, serialNumber: void 0, memo: "" },
    validationSchema: yup.object({
      companyId: yup.number().typeError("Please provide a valid value").required("This field is required"),
      amount: yup.number().typeError("Please provide a valid value").required("This field is required"),
      type: yup.string().oneOf(Object.values(ChargeTypes)).required("This field is required"),
      memo: yup.string().required("This field is required")
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "New one off charge" }),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "companyId",
        label: "Company",
        options: allCompanyOptions,
        value: allCompanyOptions.find(({ value }) => value === formik.values.companyId) || null,
        compareValue: (_h = formik.values.companyId) != null ? _h : "",
        onChange: (_, x) => {
          var _a2;
          formik.setFieldValue("companyId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        }
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: typeOptions,
        value: formik.values.type,
        onChange: formik.handleChange,
        compareValue: formik.values.type,
        error: !!formik.errors.type && formik.touched.type,
        helperText: formik.touched.type && formik.errors.type
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Serial number",
        name: "serialNumber",
        value: formik.values.serialNumber,
        onChange: formik.handleChange,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: `Amount`,
        value: formik.values.amount,
        onChange: formik.handleChange,
        error: formik.touched.amount && !!formik.errors.amount,
        helperText: (_i = formik.touched.amount && formik.errors.amount) != null ? _i : " ",
        endAdornment: "none",
        type: "number"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "memo",
        label: "Memo",
        multiline: true,
        value: formik.values.memo,
        onChange: formik.handleChange,
        error: formik.touched.memo && !!formik.errors.memo,
        helperText: (_j = formik.touched.memo && formik.errors.memo) != null ? _j : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: editMode ? "Update" : "Add",
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
