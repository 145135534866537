"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { BenefitRequestViewDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-request-view-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const BenefitRequestActionItem = ({
  benefitRequestTodo,
  refresh,
  onClose
}) => {
  const { data: benefitRequest } = useApiClient(
    benefitRequestTodo ? CustomBenefitEndpoints.getUserCustomBenefitRequestById(
      benefitRequestTodo.userId,
      benefitRequestTodo.customBenefitId,
      benefitRequestTodo.userCustomBenefitId,
      benefitRequestTodo.id
    ) : null,
    { suspense: false }
  );
  const [isViewOpen, setIsViewOpen] = useState(false);
  useEffect(() => {
    setIsViewOpen(Boolean(benefitRequestTodo));
  }, [benefitRequestTodo]);
  return benefitRequest ? /* @__PURE__ */ jsx(
    BenefitRequestViewDrawer,
    {
      isOpen: isViewOpen,
      setIsOpen: setIsViewOpen,
      request: benefitRequest,
      refresh,
      onClose
    }
  ) : null;
};
