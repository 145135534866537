"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class UserRoleAPI {
  static getUserRoleInfo(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/role`)).data;
    });
  }
  static patch(userId, roleId, userRoleUpdate) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/role/${roleId}`, userRoleUpdate);
    });
  }
  static create(userId, userRoleCreate) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/${userId}/role`, userRoleCreate)).data;
    });
  }
  static setNewManagerOnOnboardingCurrentUser(userId, newManagerId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/role/offboarding/new-manager`, { newManagerId });
    });
  }
  static deleteById(userId, roleId) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/users/${userId}/role/${roleId}`);
    });
  }
  static getRolesByCompanyId(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/superadmin/company/${companyId}/roles`)).data;
    });
  }
  static bulkDeleteRolesByIds(companyId, roleIds) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/superadmin/company/${companyId}/roles`, { data: roleIds });
    });
  }
}
export class UserRoleEndpoints {
  static getUserRole(userId) {
    return {
      url: `/apiv2/users/${userId}/role`
    };
  }
  static getOffboardingUserRoleChanges(userId) {
    return {
      url: `/apiv2/users/${userId}/role/offboarding/manager-changes`
    };
  }
}
