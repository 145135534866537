"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltPolicyPayloadIdentifier } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const ZeltMdmFirewallPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const getPolicy = () => {
    var _a, _b, _c, _d, _e, _f;
    return {
      EnableFirewall: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableFirewallPolicy) == null ? void 0 : _a.EnableFirewall) != null ? _b : false,
      EnableStealthMode: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableFirewallPolicy) == null ? void 0 : _c.EnableStealthMode) != null ? _d : false,
      BlockAllIncoming: (_f = (_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableFirewallPolicy) == null ? void 0 : _e.BlockAllIncoming) != null ? _f : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FIREWALL_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(policy) {
    let allNulls = true;
    for (const key in policy) {
      if (key !== "PayloadIdentifier" && policy[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return void 0;
    }
    return {
      EnableFirewall: policy.EnableFirewall,
      EnableStealthMode: policy.EnableStealthMode,
      BlockAllIncoming: policy.BlockAllIncoming,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FIREWALL_PAYLOAD_IDENTIFIER.valueOf()
    };
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableFirewallPolicy: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Firewall" }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20, mt: "20px" }, children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Enable Firewall",
            name: "EnableFirewall",
            checked: formik.values.EnableFirewall,
            onChange: (e, checked) => {
              formik.setFieldValue("EnableFirewall", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Block all incoming",
            name: "BlockAllIncoming",
            checked: formik.values.BlockAllIncoming,
            onChange: (e, checked) => {
              formik.setFieldValue("BlockAllIncoming", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Enable stealth mode",
            name: "EnableStealthMode",
            checked: formik.values.EnableStealthMode,
            onChange: (e, checked) => {
              formik.setFieldValue("EnableStealthMode", checked);
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => formik.handleSubmit(), fullWidth: true, colorVariant: "primary", sizeVariant: "medium", children: "Save" }) })
    ] })
  ] });
};
