"use strict";
import { jsx } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { AppActionsDrawerPage } from "@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.page";
export const AppActionsDrawer = ({
  appStub,
  countriesForEmployment,
  usersWithoutAccess,
  usersWithAccess,
  isOpen,
  onClose,
  preselectedUser,
  refreshApp,
  refreshDelayedActions,
  markTeamUserPending,
  setIsAppActionsDrawerOpen,
  selectedActiveUserLogin,
  setSelectedActiveUserLogin,
  mode,
  directoryMode = "team"
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen: setIsAppActionsDrawerOpen, children: /* @__PURE__ */ jsx(
  AppActionsDrawerPage,
  {
    appStub,
    countriesForEmployment,
    usersWithoutAccess,
    usersWithAccess,
    refreshApp,
    refreshDelayedActions,
    markTeamUserPending,
    selectedActiveUserLogin,
    setSelectedActiveUserLogin,
    closePage: () => onClose(),
    mode,
    preselectedUser,
    directoryMode
  }
) });
