"use strict";
export var SeatsMethod = /* @__PURE__ */ ((SeatsMethod2) => {
  SeatsMethod2["FIXED"] = "fixed";
  SeatsMethod2["ACTIVE"] = "active";
  return SeatsMethod2;
})(SeatsMethod || {});
export const seatsMethodOptions = Object.values(SeatsMethod);
export var BillingCurrency = /* @__PURE__ */ ((BillingCurrency2) => {
  BillingCurrency2["GBP"] = "GBP";
  BillingCurrency2["USD"] = "USD";
  return BillingCurrency2;
})(BillingCurrency || {});
export const currencyOptions = Object.values(BillingCurrency);
export var SubscriptionType = /* @__PURE__ */ ((SubscriptionType2) => {
  SubscriptionType2["MONTHLY"] = "monthly";
  SubscriptionType2["ANNUALLY"] = "annually";
  SubscriptionType2["TRIAL"] = "trial";
  return SubscriptionType2;
})(SubscriptionType || {});
export const subscriptionTypeOptions = Object.values(SubscriptionType);
export var DiscountTypeValues = /* @__PURE__ */ ((DiscountTypeValues2) => {
  DiscountTypeValues2["PERCENTAGE"] = "percentage";
  DiscountTypeValues2["FIXED"] = "fixed";
  return DiscountTypeValues2;
})(DiscountTypeValues || {});
export const discountTypeOptions = Object.values(DiscountTypeValues);
export var DiscountTypeLabels = /* @__PURE__ */ ((DiscountTypeLabels2) => {
  DiscountTypeLabels2["percentage"] = "Percentage discount";
  DiscountTypeLabels2["fixed"] = "Fixed amount discount";
  return DiscountTypeLabels2;
})(DiscountTypeLabels || {});
export var VatSetting = /* @__PURE__ */ ((VatSetting2) => {
  VatSetting2["PERCENTAGE_20"] = "20%";
  VatSetting2["NO_VAT"] = "No VAT";
  VatSetting2["REVERSE_CHARGE"] = "Reverse charge";
  return VatSetting2;
})(VatSetting || {});
export const vatSettingOptions = Object.values(VatSetting);
