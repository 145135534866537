"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { DEBOUNCE_500_MS } from "@v2/feature/documents/documents.util";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import { CompensationAPI, CompensationEndpoints } from "@/api-client/compensation.api";
import { UserContractEndpoints } from "@/api-client/contracts.api";
import { getUserCompensationSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { displayRateLabel, PaySchedules, SalaryBasis } from "@/lib/employment";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { payScheduleUnit } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import {
  PayScheduleEnum,
  SalaryBasisEnum
} from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { originalValueOrUndefined } from "@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { popularCurrencyShortOptions } from "@/v2/infrastructure/currency/currency.interface";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatAsCompensationCurrency } from "@/v2/util/string-format.util";
export const NewEmployeeCompensationSection = ({
  compensation,
  onNext,
  sx,
  userId,
  startDate
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = compensation ? "edit" : "add";
  const { data: userFtePercentage } = useApiClient(CompensationEndpoints.getFTEPercentageForUser(userId), {
    suspense: false
  });
  const { data: currentContract } = useApiClient(UserContractEndpoints.findCurrentByUserId(userId), {
    suspense: false
  });
  const availableUserFtePercentage = useMemo(() => {
    if (userFtePercentage && !isNaN(userFtePercentage)) return userFtePercentage;
    return 0;
  }, [userFtePercentage]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
    return {
      effectiveDate: (_a2 = compensation == null ? void 0 : compensation.effectiveDate) != null ? _a2 : new LocalDate(startDate).toDateString(),
      paySchedule: (_b2 = compensation == null ? void 0 : compensation.paySchedule) != null ? _b2 : PayScheduleEnum.Monthly,
      salaryBasis: (_c = compensation == null ? void 0 : compensation.salaryBasis) != null ? _c : SalaryBasisEnum.Annual,
      currency: (_f = (_e = compensation == null ? void 0 : compensation.currency) != null ? _e : (_d = currentContract == null ? void 0 : currentContract.entity) == null ? void 0 : _d.currency) != null ? _f : DEFAULT_CURRENCY,
      rate: (compensation == null ? void 0 : compensation.prorateSalaryByFte) ? (_g = compensation.nonProratedRate) != null ? _g : 0 : (_h = compensation == null ? void 0 : compensation.rate) != null ? _h : 0,
      units: compensation == null ? void 0 : compensation.units,
      prorateSalaryByFte: (_i = compensation == null ? void 0 : compensation.prorateSalaryByFte) != null ? _i : false,
      proratedRate: compensation ? (compensation == null ? void 0 : compensation.prorateSalaryByFte) ? (_j = compensation == null ? void 0 : compensation.rate) != null ? _j : 0 : 0 : 0,
      nonProratedRate: (_k = compensation == null ? void 0 : compensation.nonProratedRate) != null ? _k : void 0,
      annualSalary: (_m = (_l = compensation == null ? void 0 : compensation.compensationBreakdown) == null ? void 0 : _l.fixedRates) == null ? void 0 : _m.annual,
      salaryPerPaySchedule: (_n = compensation == null ? void 0 : compensation.compensationBreakdown) == null ? void 0 : _n.payScheduleRate,
      customUpdates: (_o = compensation == null ? void 0 : compensation.customUpdates) != null ? _o : []
    };
  }, [compensation, startDate, currentContract]);
  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: getUserCompensationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c;
      setSaving(true);
      try {
        const updatedValues = __spreadProps(__spreadValues({}, values), {
          units: [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes((_a2 = formik.values) == null ? void 0 : _a2.salaryBasis) ? values.units : 0,
          rate: formik.values.prorateSalaryByFte ? (_b2 = formik.values.proratedRate) != null ? _b2 : null : formik.values.rate,
          nonProratedRate: (_c = formik.values.rate) != null ? _c : void 0
        });
        delete updatedValues.proratedRate;
        if (compensation) {
          yield CompensationAPI.updateById(userId, __spreadProps(__spreadValues({}, updatedValues), {
            id: compensation.id
          }));
        } else {
          yield CompensationAPI.create(userId, __spreadValues({}, updatedValues));
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeCompensationSection.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const onChangeRate = useCallback(
    (objToCheck) => {
      if (objToCheck) {
        const { rate, prorateSalaryByFte } = objToCheck;
        const rateValue = rate != null ? rate : 0;
        const proratedRate = prorateSalaryByFte && availableUserFtePercentage ? availableUserFtePercentage * rateValue : rateValue;
        formik.setFieldValue("rate", rate, true);
        formik.setFieldValue("proratedRate", originalValueOrUndefined(proratedRate), true);
      }
    },
    [availableUserFtePercentage, formik]
  );
  const proratedSuffix = useMemo(() => {
    return formik.values.prorateSalaryByFte && availableUserFtePercentage ? "- Prorated".concat(" (" + availableUserFtePercentage * 100 + "%)") : "";
  }, [formik.values.prorateSalaryByFte, availableUserFtePercentage]);
  const hasSubmitted = formik.submitCount > 0;
  const modalUnitLabel = (objToCheck) => {
    return (objToCheck == null ? void 0 : objToCheck.salaryBasis) === "Daily" ? polyglot.t("NewEmployeeCompensationSection.days") : polyglot.t("NewEmployeeCompensationSection.hours");
  };
  const modalSalaryPerLabel = (objToCheck) => {
    return (objToCheck == null ? void 0 : objToCheck.paySchedule) === "Monthly" ? polyglot.t("NewEmployeeCompensationSection.month") : polyglot.t("NewEmployeeCompensationSection.week");
  };
  const getCalculatedSalaryRates = useCallback(
    (compensation2) => __async(void 0, null, function* () {
      var _a2;
      try {
        return CompensationAPI.calculateSalaryFixedRates(userId, __spreadProps(__spreadValues({}, compensation2), {
          currency: (_a2 = formik.values.currency) != null ? _a2 : DEFAULT_CURRENCY
        }));
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
        return null;
      }
    }),
    [showMessage, polyglot, userId, formik.values.currency]
  );
  const debouncedCall = useCallback(() => __async(void 0, null, function* () {
    if (!getCalculatedSalaryRates || !formik.values.rate || !formik.values.paySchedule || !formik.values.salaryBasis)
      return;
    getCalculatedSalaryRates({
      rate: formik.values.rate,
      paySchedule: formik.values.paySchedule,
      salaryBasis: formik.values.salaryBasis,
      units: formik.values.units
    }).then((compBreakdown) => {
      var _a2, _b2;
      formik.setFieldValue("annualSalary", (_a2 = compBreakdown == null ? void 0 : compBreakdown.fixedRates.annual) != null ? _a2 : "");
      formik.setFieldValue("salaryPerPaySchedule", (_b2 = compBreakdown == null ? void 0 : compBreakdown.payScheduleRate) != null ? _b2 : "");
    }).catch((error) => {
      showMessage(nestErrorMessage(error, polyglot), "error");
    });
  }), [
    showMessage,
    polyglot,
    getCalculatedSalaryRates,
    formik.values.rate,
    formik.values.paySchedule,
    formik.values.salaryBasis,
    formik.values.units
  ]);
  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedCall();
    }, DEBOUNCE_500_MS);
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedCall]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CompensationForm.compensation") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "paySchedule",
        label: polyglot.t("CompensationForm.paySchedule"),
        options: PaySchedules(polyglot),
        value: formik.values.paySchedule,
        onChange: formik.handleChange,
        disabled: saving,
        helperText: hasSubmitted && formik.errors.paySchedule,
        error: hasSubmitted && !!formik.errors.paySchedule
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "salaryBasis",
        label: polyglot.t("CompensationForm.salaryBasis"),
        options: SalaryBasis(polyglot),
        value: formik.values.salaryBasis,
        onChange: formik.handleChange,
        disabled: saving,
        helperText: hasSubmitted && formik.errors.salaryBasis,
        error: hasSubmitted && !!formik.errors.salaryBasis
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: polyglot.t("CompensationForm.prorateSalaryByFte"),
        name: "prorateSalaryByFte",
        checked: formik.values.prorateSalaryByFte,
        onChange: (_, value) => {
          formik.setFieldValue("prorateSalaryByFte", value);
          onChangeRate(__spreadProps(__spreadValues({}, formik.values), {
            prorateSalaryByFte: value
          }));
        }
      }
    ),
    formik.values.prorateSalaryByFte && (currentContract == null ? void 0 : currentContract.attendanceSchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.m10 }, children: polyglot.t("CompensationForm.prorateSalaryByFteDescription", {
      percentage: currentContract.attendanceSchedule.ftePercent,
      workHours: convertMinutesToClockHours(currentContract.attendanceSchedule.totalTime, polyglot),
      fteHours: convertMinutesToClockHours(currentContract.attendanceSchedule.fteEquivalent, polyglot)
    }) }) : formik.values.prorateSalaryByFte && !(currentContract == null ? void 0 : currentContract.attendanceSchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "RedDark", sx: { mt: spacing.m10 }, children: polyglot.t("CompensationForm.noScheduleMessage") }) : null,
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "currency",
          label: polyglot.t("CompensationForm.currency"),
          options: popularCurrencyShortOptions,
          value: formik.values.currency,
          onChange: formik.handleChange,
          disabled: saving,
          helperText: hasSubmitted && formik.errors.currency,
          error: hasSubmitted && !!formik.errors.currency,
          sx: { flexBasis: "50%" }
        }
      ),
      /* @__PURE__ */ jsx(
        MoneyTextfieldComponent,
        {
          name: "rate",
          label: displayRateLabel(formik.values.salaryBasis, polyglot),
          value: formik.values.rate,
          onChange: (newValue) => {
            formik.setFieldValue("rate", newValue);
            onChangeRate(__spreadProps(__spreadValues({}, formik.values), {
              rate: newValue ? Number(newValue) : void 0
            }));
          },
          autoFocus: true
        }
      )
    ] }),
    [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes((_a = formik.values) == null ? void 0 : _a.salaryBasis) && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "units",
        label: polyglot.t("NewEmployeeCompensationSection.units", {
          unitLabel: modalUnitLabel(formik.values),
          salary: modalSalaryPerLabel(formik.values)
        }),
        value: formik.values.units,
        type: "number",
        onChange: formik.handleChange,
        error: formik.touched.units && !!formik.errors.units,
        helperText: (_b = formik.touched.units && formik.errors.units) != null ? _b : " ",
        clearText: () => formik.setFieldValue("units", "")
      }
    ),
    formik.values.salaryPerPaySchedule && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("NewEmployeeCompensationSection.salaryPer", {
        paySchedule: payScheduleUnit(formik.values.paySchedule),
        proRated: proratedSuffix
      }) }),
      formik.values.salaryPerPaySchedule && !Number.isNaN(formik.values.salaryPerPaySchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "title4", children: formatAsCompensationCurrency(formik.values.currency, formik.values.salaryPerPaySchedule) }) : /* @__PURE__ */ jsx(Box, {})
    ] }),
    formik.values.annualSalary && ["Monthly", "Annual"].includes(formik.values.salaryBasis) && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("NewEmployeeCompensationSection.annualSalaryGross", { salary: proratedSuffix }) }),
      formik.values.annualSalary && !Number.isNaN(formik.values.annualSalary) ? /* @__PURE__ */ jsx(Typography, { variant: "title4", children: formatAsCompensationCurrency(formik.values.currency, formik.values.annualSalary) }) : /* @__PURE__ */ jsx(Box, {})
    ] }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        rowModalMode,
        formName: CustomProfileFormType.Salary
      }
    ),
    formik.isValid && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        colorVariant: "primary",
        sizeVariant: "large",
        fullWidth: true
      }
    )
  ] }) }) });
};
