"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { Divider } from "@v2/components/divider.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { isHourlyPolicy, isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import {
  getAccrualBreakdownValue,
  getAllowanceProratingRuleShortDesc,
  getAnnualBalanceFromBreakdown,
  getCarryOverExpiryOn,
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getHolidaysProratingRuleShort,
  getOneOffAdjustmentFromBreakdown,
  getProratedAllowanceFromBreakdown,
  getPublicHolidayAllowanceFromBreakdown,
  getRoundingAdjustment,
  getTOILValueFromBreakdown,
  getTotalAllowanceBreakdown
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { AccrualDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/accrual-drawer.component";
import { AllowanceAdjustmentDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/allowance-adjustment-drawer.component";
import { BalanceAdjustmentsDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/balance-adjustments-drawer.component";
import { BalanceCarryOverDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/balance-carry-over-drawer.component";
import { ProratedAllowanceDetailsDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/prorated-allowance-details-drawer.component";
import { PublicHolidayAllowanceDetailsDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/public-holiday-allowance-details-drawer.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
const WIDTH_COLUMN1 = "80%";
const WIDTH_COLUMN2 = "20%";
export const AllowanceDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats,
  userId,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AllowanceDrawer.allowance") }),
        /* @__PURE__ */ jsx(
          AllowanceDrawerContent,
          {
            userId,
            refresh,
            userBalanceDetailedStats,
            absencePolicy
          }
        )
      ] })
    }
  ) });
};
const AllowanceDrawerField = ({ title, description, value, action }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
  /* @__PURE__ */ jsxs(Box, { sx: { width: WIDTH_COLUMN1, display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
    description && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: description })
  ] }),
  /* @__PURE__ */ jsx(
    Box,
    {
      onClick: action,
      sx: { width: WIDTH_COLUMN2, display: "flex", justifyContent: "end", cursor: action ? "pointer" : void 0 },
      children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { textDecoration: action ? "underline" : "none" }, children: value })
    }
  )
] });
const AllowanceEditableDrawerField = ({
  title,
  description,
  secondLine,
  value,
  optionalLine,
  optionalLineValue,
  action
}) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
  /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Box, { sx: { width: WIDTH_COLUMN1, display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
    description && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: description })
  ] }) }),
  /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
    /* @__PURE__ */ jsx(Box, { sx: { width: WIDTH_COLUMN1, display: "flex", flexDirection: "column", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: secondLine }) }),
    /* @__PURE__ */ jsx(
      Box,
      {
        onClick: action,
        sx: {
          cursor: action ? "pointer" : "default",
          width: WIDTH_COLUMN2,
          display: "flex",
          justifyContent: "end"
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { textDecoration: action ? "underline" : "none" }, children: value })
      }
    )
  ] }),
  optionalLine && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
    /* @__PURE__ */ jsx(Box, { sx: { width: WIDTH_COLUMN1, display: "flex", flexDirection: "column", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: optionalLine }) }),
    optionalLineValue && /* @__PURE__ */ jsx(Box, { sx: { cursor: "pointer", width: WIDTH_COLUMN2, display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: optionalLineValue }) })
  ] })
] });
const AllowanceDrawerContent = ({
  userId,
  absencePolicy,
  userBalanceDetailedStats,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [isPHDrawerOpen, setIsPHDrawerOpen] = useState(false);
  const [isProratedAllowanceDrawerOpen, setIsProratedAllowanceDrawerOpen] = useState(false);
  const [isAdjustmentsDrawerOpen, setIsAdjustmentsDrawerOpen] = useState(false);
  const [isCarryOverDrawerOpen, setIsCarryOverDrawerOpen] = useState(false);
  const [isAllowanceUpdateDrawerOpen, setIsAllowanceUpdateDrawerOpen] = useState(false);
  const [isAccrualDrawerOpen, setIsAccrualDrawerOpen] = useState(false);
  const isHourly = isHourlyPolicy(absencePolicy);
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];
  const proratedAllowance = getProratedAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const annualAllowance = getAnnualBalanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const PHAllowance = getPublicHolidayAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const roundingAdjustment = getRoundingAdjustment(absencePolicy, policyBreakdown, polyglot);
  const carryOver = getCarryOverFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const oneOffAdjustments = getOneOffAdjustmentFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const toil = getTOILValueFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  const totalProratedAllowance = getTotalAllowanceBreakdown(policyBreakdown, polyglot);
  const carryOverExpiryOn = getCarryOverExpiryOn(absencePolicy, policyBreakdown, polyglot);
  const expiredCarryOver = getExpiredCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  const allowanceProratingRule = getAllowanceProratingRuleShortDesc(absencePolicy, polyglot);
  const holidayProratingRule = getHolidaysProratingRuleShort(absencePolicy, polyglot);
  const accruedSoFar = getAccrualBreakdownValue(absencePolicy, policyBreakdown, "total", polyglot);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", flexDirection: "column", gap: spacing.g10, mt: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(
        AllowanceDrawerField,
        {
          title: polyglot.t("AllowanceDrawer.annualPolicyAllowance"),
          description: polyglot.t("AllowanceDrawer.forWholeYear"),
          value: annualAllowance,
          action: isUnlimited ? void 0 : () => {
            setIsAllowanceUpdateDrawerOpen(true);
          }
        }
      ),
      /* @__PURE__ */ jsx(Divider, { style: { marginBottom: spacing.m10 } }),
      !isHourly && !(policyBreakdown == null ? void 0 : policyBreakdown.isOnRegularSchedule) && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey, mb: spacing.m10 }, children: polyglot.t("AllowanceDrawer.allowanceToHoursString1") }),
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("AllowanceDrawer.allowanceToHoursString2") })
      ] }),
      proratedAllowance && /* @__PURE__ */ jsx(
        AllowanceDrawerField,
        {
          title: polyglot.t("AllowanceDrawer.proratedAllowance"),
          description: allowanceProratingRule,
          value: proratedAllowance,
          action: absencePolicy.proratingFte || absencePolicy.proratingStartDate ? () => {
            setIsProratedAllowanceDrawerOpen(true);
          } : void 0
        }
      ),
      PHAllowance && /* @__PURE__ */ jsx(
        AllowanceDrawerField,
        {
          title: polyglot.t("AllowanceDrawer.publicHolidayAllowance"),
          description: holidayProratingRule,
          value: PHAllowance,
          action: () => {
            setIsPHDrawerOpen(true);
          }
        }
      ),
      roundingAdjustment && /* @__PURE__ */ jsx(AllowanceDrawerField, { title: polyglot.t("AllowanceDrawer.roundingAdjustment"), value: roundingAdjustment }),
      absencePolicy.carryOverEnabled && /* @__PURE__ */ jsx(
        AllowanceEditableDrawerField,
        {
          title: polyglot.t("AllowanceDrawer.carryOver"),
          secondLine: polyglot.t("AllowanceDrawer.carryOverPrevYear"),
          description: carryOverExpiryOn != null ? carryOverExpiryOn : void 0,
          value: carryOver,
          optionalLine: expiredCarryOver === null ? void 0 : polyglot.t("AllowanceDrawer.expiredCarryOver"),
          optionalLineValue: expiredCarryOver === null ? void 0 : expiredCarryOver,
          action: () => {
            setIsCarryOverDrawerOpen(true);
          }
        }
      ),
      oneOffAdjustments && /* @__PURE__ */ jsx(
        AllowanceEditableDrawerField,
        {
          title: polyglot.t("AllowanceDrawer.adjustments"),
          secondLine: polyglot.t("AllowanceDrawer.totalAdjustments"),
          value: oneOffAdjustments,
          action: () => {
            setIsAdjustmentsDrawerOpen(true);
          }
        }
      ),
      toil && /* @__PURE__ */ jsx(
        AllowanceEditableDrawerField,
        {
          title: polyglot.t("AllowanceDrawer.toilLong"),
          secondLine: polyglot.t("AllowanceDrawer.toilDesc"),
          value: toil
        }
      ),
      totalProratedAllowance && /* @__PURE__ */ jsx(Divider, { style: { marginBottom: spacing.m10 } }),
      totalProratedAllowance && /* @__PURE__ */ jsx(AllowanceDrawerField, { title: polyglot.t("AllowanceDrawer.totalAllowance"), value: totalProratedAllowance }),
      accruedSoFar && /* @__PURE__ */ jsx(
        AllowanceDrawerField,
        {
          title: polyglot.t("AllowanceDrawer.accruedSoFar"),
          value: accruedSoFar,
          action: () => {
            setIsAccrualDrawerOpen(true);
          }
        }
      )
    ] }),
    (absencePolicy.proratingFte || absencePolicy.proratingStartDate) && /* @__PURE__ */ jsx(
      ProratedAllowanceDetailsDrawer,
      {
        isOpen: isProratedAllowanceDrawerOpen,
        setIsOpen: setIsProratedAllowanceDrawerOpen,
        absencePolicy,
        userBalanceDetailedStats
      }
    ),
    /* @__PURE__ */ jsx(
      PublicHolidayAllowanceDetailsDrawer,
      {
        isOpen: isPHDrawerOpen,
        setIsOpen: setIsPHDrawerOpen,
        absencePolicy,
        userBalanceDetailedStats
      }
    ),
    policyBreakdown && /* @__PURE__ */ jsx(
      BalanceCarryOverDrawer,
      {
        isOpen: isCarryOverDrawerOpen,
        setIsOpen: setIsCarryOverDrawerOpen,
        userId,
        absencePolicy,
        policyBreakdown,
        refresh,
        isOnRegularSchedule: policyBreakdown.isOnRegularSchedule,
        currentAverageWorkDayLength: policyBreakdown.currentAverageWorkDayLength
      }
    ),
    policyBreakdown && /* @__PURE__ */ jsx(
      BalanceAdjustmentsDrawer,
      {
        isOpen: isAdjustmentsDrawerOpen,
        setIsOpen: setIsAdjustmentsDrawerOpen,
        userId,
        absencePolicy,
        policyBreakdown,
        refresh,
        isOnRegularSchedule: policyBreakdown.isOnRegularSchedule,
        currentAverageWorkDayLength: policyBreakdown.currentAverageWorkDayLength
      }
    ),
    policyBreakdown && /* @__PURE__ */ jsx(
      AllowanceAdjustmentDrawer,
      {
        isOpen: isAllowanceUpdateDrawerOpen,
        setIsOpen: setIsAllowanceUpdateDrawerOpen,
        userId,
        absencePolicy,
        refresh,
        policyBreakdown,
        currentCycle: policyBreakdown.currentCycle
      }
    ),
    policyBreakdown && /* @__PURE__ */ jsx(
      AccrualDrawer,
      {
        isOpen: isAccrualDrawerOpen,
        setIsOpen: setIsAccrualDrawerOpen,
        absencePolicy,
        policyBreakdown
      }
    )
  ] });
};
