"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Loader } from "@/v2/components/loader.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { MFA_CODE_LENGTH } from "@/v2/feature/auth/auth.dto";
import { MFACodeEntry } from "@/v2/feature/auth/components/auth-mfa-code-entry.component";
import { ResendAuthCodeLink } from "@/v2/feature/user/features/mfa/resend-auth-code-link.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EnableMFAPage = ({ isOpen, onClose }) => {
  const { polyglot } = usePolyglot();
  const [submittingCode, setSubmittingCode] = useState(false);
  const [codeRequest, setCodeRequest] = React.useState("needs-request");
  const [errorMsg, setErrorMsg] = useState("");
  const code = useRef("");
  const checkCodeAndEnableMFA = useCallback(
    (code2) => {
      if (code2.length !== MFA_CODE_LENGTH) {
        return;
      }
      (() => __async(void 0, null, function* () {
        var _a;
        setSubmittingCode(true);
        try {
          yield AuthAPI.updateMFA({ enableMFACode: code2 });
          onClose();
        } catch (err) {
          if (((_a = err.response) == null ? void 0 : _a.status) === 401) {
            setErrorMsg(polyglot.t("EnableMFAPage.errorMessages.code"));
          } else {
            setErrorMsg(err.toString());
          }
        } finally {
          setSubmittingCode(false);
        }
      }))();
    },
    [polyglot, onClose]
  );
  useEffect(() => {
    if (!isOpen) {
      setCodeRequest("needs-request");
      return;
    }
    if (codeRequest !== "needs-request") {
      return;
    }
    setCodeRequest("requesting");
    AuthAPI.requestMFAEnableCode().then(
      () => {
        setCodeRequest("code-sent");
      },
      (err) => {
        setErrorMsg(err.toString());
      }
    );
  }, [codeRequest, isOpen]);
  if (codeRequest !== "code-sent") {
    return /* @__PURE__ */ jsx(Loader, { loading: true, children: polyglot.t("EnableMFAPage.requestingCode") });
  }
  const onSubmitHandler = (e) => {
    checkCodeAndEnableMFA(code.current);
    e.preventDefault();
  };
  return /* @__PURE__ */ jsxs("form", { onSubmit: onSubmitHandler, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { mb: "30px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: polyglot.t("EnableMFAPage.enterCode") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: polyglot.t("EnableMFAPage.codeMessage", { code: MFA_CODE_LENGTH }) })
    ] }),
    /* @__PURE__ */ jsx(
      MFACodeEntry,
      {
        disabled: submittingCode,
        error: !!errorMsg,
        helperText: errorMsg,
        onCodeComplete: (value) => {
          code.current = value;
        },
        sx: { mb: "40px" }
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        loading: submittingCode,
        name: polyglot.t("General.continue"),
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(ResendAuthCodeLink, { sx: { mt: "30px" } })
  ] });
};
