"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { FilledField } from "@/v2/components/forms/filled-field.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  PaymentCategory,
  PaymentCategoryType,
  PaymentType
} from "@/v2/feature/payments/payments.dto";
import {
  buttonBoxSx,
  fieldSx,
  titleSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@/v2/styles/spacing.styles";
const validationSchema = Yup.object().shape({
  userId: Yup.number().nullable(true).required("Beneficiary is a required field"),
  amount: Yup.number().positive().required(),
  reference: Yup.string().max(20, "reference can not be greater than 20 characters").matches(/^[a-zA-Z\d]*$/, "Use alphanumeric characters only.").required(),
  category: Yup.string().required(),
  dueDate: Yup.string().test(dateFieldTest).nullable().notRequired()
});
const typesAndCategory = [
  { label: "Employee expense", value: "Employee expense" },
  { label: "Employee invoice", value: "Employee invoice" },
  { label: "Company taxes", value: "Company taxes" }
];
export function PaymentFormModal({ onSubmit, selectedPayment, setIsModalOpen }) {
  var _a, _b, _c;
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = (values) => __async(this, null, function* () {
    try {
      setSubmitting(true);
      const { userId, reference, amount, dueDate, typesAndCategory: typesAndCategory2, currency } = values;
      const payload = {
        userId: typesAndCategory2 === PaymentCategoryType.CompanyTaxes ? null : userId,
        reference,
        amount,
        currency,
        category: typesAndCategory2 === PaymentCategoryType.CompanyTaxes ? PaymentCategory.Payroll : typesAndCategory2 === PaymentCategoryType.EmployeeExpense ? PaymentCategory.Expense : PaymentCategory.Invoice,
        type: typesAndCategory2 === PaymentCategoryType.CompanyTaxes ? PaymentType.Taxes : PaymentType.Employee,
        dueDate
      };
      yield onSubmit(payload);
    } catch (e) {
      console.error("CreatePaymentForm handleSubmit", e);
    } finally {
      setSubmitting(false);
    }
  });
  const getTypeAndCategory = (payment) => {
    if (payment && payment.type === PaymentType.Employee) {
      return payment.category === PaymentCategory.Expense || payment.category === PaymentCategory.Payroll ? PaymentCategoryType.EmployeeExpense : PaymentCategoryType.EmployeeInvoice;
    }
    return PaymentCategoryType.CompanyTaxes;
  };
  const formik = useFormik({
    initialValues: selectedPayment ? __spreadProps(__spreadValues({}, selectedPayment), {
      typesAndCategory: getTypeAndCategory(selectedPayment),
      userId: selectedPayment.category === PaymentCategory.Payroll && selectedPayment.type === PaymentType.Taxes ? 0 : selectedPayment.userId
    }) : {
      userId: void 0,
      amount: 0,
      reference: "",
      category: PaymentCategory.Expense,
      type: PaymentType.Employee,
      dueDate: "",
      typesAndCategory: PaymentCategoryType.EmployeeExpense,
      currency: "GBP"
    },
    validationSchema,
    onSubmit: handleSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { noValidate: true, autoComplete: "off", onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: selectedPayment ? "Edit payment" : "Add payment" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "typesAndCategory",
          label: "Payment type",
          options: typesAndCategory,
          value: formik.values.typesAndCategory,
          compareValue: formik.values.typesAndCategory,
          error: !!formik.errors.typesAndCategory && formik.touched.typesAndCategory,
          onChange: (e) => {
            formik.setFieldValue("typesAndCategory", e.target.value);
            formik.setFieldValue("userId", 0);
          },
          helperText: formik.errors.typesAndCategory && formik.touched.typesAndCategory
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: formik.values.typesAndCategory !== PaymentCategoryType.CompanyTaxes ? /* @__PURE__ */ jsx(
        SingleUserSelect,
        {
          name: "userId",
          options: "company",
          onChange: (_, x) => {
            var _a2;
            return formik.setFieldValue("userId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          value: formik.values.userId,
          label: "Beneficiary",
          error: Boolean(formik.errors.userId) && formik.touched.userId,
          helperText: Boolean(formik.errors.userId) && formik.touched.userId ? formik.errors.userId : ""
        }
      ) : /* @__PURE__ */ jsx(FilledField, { label: "Beneficiary", value: "HMRC" }) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "amount",
          label: "Amount",
          value: formik.values.amount,
          type: "number",
          multiline: true,
          onChange: formik.handleChange,
          error: formik.touched.amount && !!formik.errors.amount,
          helperText: (_a = formik.touched.amount && formik.errors.amount) != null ? _a : " ",
          clearText: () => formik.setFieldValue("amount", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "reference",
          label: "Bank payment reference",
          value: formik.values.reference,
          type: "text",
          multiline: true,
          onChange: formik.handleChange,
          error: formik.touched.reference && !!formik.errors.reference,
          helperText: (_b = formik.touched.reference && formik.errors.reference) != null ? _b : " ",
          clearText: () => formik.setFieldValue("reference", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_c = formik.values.dueDate) != null ? _c : dayjs(),
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("dueDate", value);
            }
          },
          name: "dueDate",
          label: "Due date",
          error: !!formik.errors.dueDate && Boolean(formik.touched.dueDate),
          helperText: formik.errors.dueDate && Boolean(formik.touched.dueDate),
          shortcuts: true,
          minDate: /* @__PURE__ */ new Date()
        }
      ) }) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => setIsModalOpen(false),
          children: "Cancel"
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: selectedPayment ? "Save" : "Add",
          loading: submitting,
          fullWidth: true,
          colorVariant: "primary",
          sizeVariant: "medium"
        }
      )
    ] })
  ] }) }) });
}
