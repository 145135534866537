"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { styled as MUIStyled } from "@mui/material";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyleTR = MUIStyled("tr")(() => ({
  backgroundColor: "transparent"
}));
export const StyleTH = MUIStyled("th")(() => __spreadProps(__spreadValues({
  color: themeColors.Grey
}, themeFonts.captionSmall), {
  height: "16px",
  paddingBottom: spacing.p10,
  paddingRight: spacing.p5,
  paddingLeft: spacing.p5,
  textAlign: "left"
}));
export const StickyStyleTH = MUIStyled(StyleTH)(() => ({
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 2
}));
export const StyleHolidayHeader = MUIStyled("th")(() => __spreadProps(__spreadValues({
  color: themeColors.Grey
}, themeFonts.captionSmall), {
  height: "16px",
  paddingTop: spacing.p4,
  paddingBottom: spacing.p4,
  paddingRight: spacing.p5,
  paddingLeft: spacing.p5,
  textAlign: "left"
}));
export const StyleTD = MUIStyled("td")(() => __spreadProps(__spreadValues({
  position: "relative",
  color: themeColors.DarkGrey
}, themeFonts.caption), {
  textAlign: "left",
  height: "47px"
  // = 40px - 1px row dividing line
}));
export const StickyStyleTD = MUIStyled(StyleTD)(() => ({
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 2
}));
