"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { LOGIN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { CachedUsersProvider } from "@/v2/feature/user/context/cached-users.context";
import { OnboardingPayrollNonUK } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll-non-uk.page";
import { OnboardingPayrollUK } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll-uk.page";
import { isUKCountryCode } from "@/v2/infrastructure/country/country.util";
export const OnboardingPayrollPage = ({ mode }) => {
  const routerHistory = useHistory();
  const userId = useUserIdParam();
  const [userPayroll, setUserPayroll] = useState();
  const [payrollSchema, setPayrollSchema] = useState();
  const getUserP45data = useCallback(() => __async(void 0, null, function* () {
    if (!Number.isInteger(userId)) {
      routerHistory.replace(LOGIN_ROUTE);
      return;
    }
    const [data, schema] = yield Promise.all([
      PayrollAPI.getActiveUserPayroll(userId),
      PayrollAPI.getUserPayrollSchema(userId)
    ]);
    setUserPayroll(data || null);
    setPayrollSchema(schema || null);
  }), [routerHistory, userId]);
  useEffect(() => {
    getUserP45data();
  }, [getUserP45data]);
  const onClose = useCallback(() => {
    ({
      onboarding: () => routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId })),
      "new-starter": () => routerHistory.goBack()
    })[mode]();
  }, [mode, routerHistory, userId]);
  if (userPayroll === void 0 || payrollSchema === void 0) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsx(CachedUsersProvider, { children: !payrollSchema || isUKCountryCode(payrollSchema.countryCode) ? /* @__PURE__ */ jsx(OnboardingPayrollUK, { userId, userPayroll, onClose }) : /* @__PURE__ */ jsx(
    OnboardingPayrollNonUK,
    {
      userId,
      userPayroll,
      payrollSchema,
      onClose
    }
  ) });
};
