"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { keyBy } from "lodash";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { GrowthPreviewer } from "@/v2/feature/growth/shared/components/growth-previewer.component";
export const CycleCreationQuestionPreviewer = ({
  isPreviewerOpen,
  setIsPreviewerOpen,
  previewReviewer,
  allQuestions,
  allSections,
  order
}) => {
  const questionsLookup = useMemo(() => {
    if (!allQuestions || !previewReviewer) return keyBy([], "id");
    const questions = allQuestions.filter((q) => q.reviewerSelect.includes(previewReviewer)).map((q) => {
      return __spreadProps(__spreadValues({}, q), {
        questionText: previewReviewer === ReviewerTypes.Self ? q.questionSelf : q.questionMain,
        options: q.answerOptions
      });
    });
    return keyBy(questions != null ? questions : [], "id");
  }, [previewReviewer, allQuestions]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: isPreviewerOpen,
      setIsOpen: setIsPreviewerOpen,
      onClose: () => {
        setIsPreviewerOpen(false);
      },
      widthPercentage: 50,
      children: /* @__PURE__ */ jsx(GrowthPreviewer, { questionsLookup, allSections, order })
    }
  );
};
