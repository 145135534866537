"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOwnership, DevicePossessionType, ReturnDeviceReason } from "@v2/feature/device/device.interface";
import { UserAddressAPI } from "@v2/feature/user/features/user-forms/user-address/user-address.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { formatAddress } from "@v2/util/user-data.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { SiteAPI } from "@/api-client/site.api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ReturnDeviceDrawer = ({
  isOpen,
  closeDrawerAfterReturn,
  devicePossession,
  setIsOpen
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const initiateDeviceReturn = (data) => __async(void 0, null, function* () {
    var _a;
    if (!devicePossession) return;
    let isReturnInitiated = false;
    if (data.reason === ReturnDeviceReason.ReturnToCompanySite && !data.receiverAddress) {
      showMessage(polyglot.t("ReturnDeviceDrawer.errorMessages.deliveryAddress"), "error");
      return;
    }
    let deliveryAddress = "";
    if (data.reason === ReturnDeviceReason.ReturnToCompanySite) {
      deliveryAddress = data.receiverAddress;
    } else {
      deliveryAddress = "Zelt HQ";
    }
    try {
      yield DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        data.reason,
        data.senderAddress,
        deliveryAddress,
        (_a = data.siteId) != null ? _a : null,
        data.notes
      );
      if (devicePossession.possessionType === DevicePossessionType.ZeltStorage && data.reason === ReturnDeviceReason.CancelContract) {
        showMessage(polyglot.t("ReturnDeviceDrawer.infoMessages.initiated"), "info");
      } else {
        showMessage(polyglot.t("ReturnDeviceDrawer.infoMessages.returnInitiated"), "info");
      }
      isReturnInitiated = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      yield closeDrawerAfterReturn();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(
          polyglot.t("ReturnDeviceDrawer.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } else {
        showMessage(
          polyglot.t("ReturnDeviceDrawer.errorMessages.initiate", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: devicePossession ? /* @__PURE__ */ jsx(
    InitiateReturningOfDeviceContent,
    {
      devicePossession,
      initiateDeviceReturn
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
const InitiateReturningOfDeviceContent = ({
  devicePossession,
  initiateDeviceReturn
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [senderAddress, setSenderAddress] = useState("");
  const [sitesOptions, setSitesOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const validationSchema = yup.object({
    reason: yup.string().required(polyglot.t("ReturnDeviceDrawer.errorMessages.reasonRequired")),
    senderAddress: yup.string().when("reason", {
      is: ReturnDeviceReason.CancelContract,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(polyglot.t("ReturnDeviceDrawer.errorMessages.sendAddRequired"))
    }),
    receiverAddress: yup.string().when("reason", {
      is: ReturnDeviceReason.ReturnToCompanySite,
      then: (schema) => schema.required(polyglot.t("ReturnDeviceDrawer.errorMessages.receivedAddress")),
      otherwise: (schema) => schema.notRequired()
    }),
    siteId: yup.number().when("reason", {
      is: ReturnDeviceReason.ReturnToCompanySite,
      then: (schema) => schema.required(polyglot.t("ReturnDeviceDrawer.errorMessages.siteRequired")),
      otherwise: (schema) => schema.notRequired()
    }),
    notes: yup.string().nullable().notRequired()
  });
  const formik = useFormik({
    initialValues: {
      reason: devicePossession.possessionType === DevicePossessionType.ZeltStorage ? ReturnDeviceReason.CancelContract : ReturnDeviceReason.ReturnToCompanySite,
      senderAddress,
      receiverAddress: devicePossession.possessionType === DevicePossessionType.ZeltStorage ? "Zelt HQ" : "",
      siteId: void 0,
      notes: void 0
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setIsSubmitting(true);
      yield initiateDeviceReturn(values);
      setIsSubmitting(false);
    })
  });
  const fetchUserAddress = useCallback(
    (assignedUserId) => __async(void 0, null, function* () {
      try {
        const userAddress = yield UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(
          polyglot.t("ReturnDeviceDrawer.errorMessages.retrieveUserDetail", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            var _a2;
            return { value: site.id, label: site.name, address: (_a2 = site.address) != null ? _a2 : "" };
          })
        );
      } catch (error) {
        showMessage(
          polyglot.t("ReturnDeviceDrawer.errorMessages.retrieveSitesList", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }))();
  }, [polyglot, showMessage]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ReturnDeviceDrawer.returnDevice") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "reason",
        label: polyglot.t("ReturnDeviceDrawer.returnReason"),
        disabled: devicePossession.possessionType === DevicePossessionType.ZeltStorage,
        options: [
          { value: ReturnDeviceReason.ReturnToCompanySite, label: polyglot.t("ReturnDeviceDrawer.sendToCompany") },
          { value: ReturnDeviceReason.SendToZeltStorage, label: polyglot.t("ReturnDeviceDrawer.sendToZelt") },
          ...((_a = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a.ownership) === DeviceOwnership.Rental ? [
            {
              value: ReturnDeviceReason.Damage,
              label: polyglot.t("ReturnDeviceDrawer.damage")
            },
            {
              value: ReturnDeviceReason.CancelContract,
              label: polyglot.t("ReturnDeviceDrawer.cancel")
            }
          ] : []
        ].filter(
          (option) => {
            var _a2;
            return ((_a2 = devicePossession.device) == null ? void 0 : _a2.ownership) === DeviceOwnership.Company ? [ReturnDeviceReason.SendToZeltStorage, ReturnDeviceReason.ReturnToCompanySite].includes(option.value) : true;
          }
        ),
        value: formik.values.reason,
        compareValue: formik.values.reason,
        error: !!formik.errors.reason && formik.touched.reason,
        onChange: formik.handleChange,
        helperText: formik.errors.reason && formik.touched.reason
      }
    ),
    formik.values.reason === ReturnDeviceReason.ReturnToCompanySite && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "siteId",
        label: polyglot.t("ReturnDeviceDrawer.siteId"),
        options: sitesOptions,
        value: formik.values.siteId,
        compareValue: formik.values.siteId,
        error: !!formik.errors.siteId && formik.touched.siteId,
        helperText: formik.touched.siteId && formik.errors.siteId,
        onChange: (e) => __async(void 0, null, function* () {
          var _a2, _b2;
          yield formik.handleChange(e);
          const siteAddress = (_b2 = (_a2 = sitesOptions.find((s) => s.value === e.target.value)) == null ? void 0 : _a2.address) != null ? _b2 : "";
          yield formik.setFieldValue("receiverAddress", siteAddress);
        })
      }
    ),
    devicePossession.possessionType !== DevicePossessionType.ZeltStorage && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "senderAddress",
        label: polyglot.t("ReturnDeviceDrawer.senderAddress"),
        value: (_b = formik.values.senderAddress) != null ? _b : senderAddress,
        onChange: formik.handleChange,
        error: formik.touched.senderAddress && !!formik.errors.senderAddress,
        helperText: (_c = formik.touched.senderAddress && formik.errors.senderAddress) != null ? _c : " "
      }
    ),
    formik.values.reason === ReturnDeviceReason.ReturnToCompanySite && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "receiverAddress",
        label: polyglot.t("ReturnDeviceDrawer.receiverAddress"),
        value: (_d = formik.values.receiverAddress) != null ? _d : "",
        onChange: formik.handleChange,
        error: formik.touched.receiverAddress && !!formik.errors.receiverAddress,
        helperText: (_e = formik.touched.receiverAddress && formik.errors.receiverAddress) != null ? _e : " "
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "notes",
        label: polyglot.t("ReturnDeviceDrawer.notes"),
        value: formik.values.notes,
        onChange: formik.handleChange,
        error: formik.touched.notes && !!formik.errors.notes,
        helperText: (_f = formik.touched.notes && formik.errors.notes) != null ? _f : " "
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: formik.values.reason === ReturnDeviceReason.CancelContract ? polyglot.t("ReturnDeviceDrawer.cancelcontract") : polyglot.t("ReturnDeviceDrawer.returnDevice"),
        fullWidth: true,
        loading: isSubmitting,
        colorVariant: "primary",
        sizeVariant: "medium"
      }
    ) })
  ] }) });
};
