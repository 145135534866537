"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as IDVerifyIcon } from "@/images/onboarding-step-icons/IDBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { EditIdVerify } from "@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const IDVerifyOnboardingItem = ({ template, setDrawerOpen, setDrawerView, onDelete }) => {
  const { polyglot } = usePolyglot();
  const name = useMemo(() => {
    var _a;
    const { check } = (_a = template.template.idverify) != null ? _a : {};
    if (!check) return void 0;
    return check == null ? void 0 : check.name;
  }, [template.template.idverify]);
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: IDVerifyIcon,
        title: polyglot.t("IDVerifyOnboardingItem.title"),
        description: polyglot.t("IDVerifyOnboardingItem.desc")
      },
      chips: [name != null ? name : ""],
      addButtonIcon: template.template.idverify ? "edit" : "plus",
      onAddClick: template.template.idverify ? void 0 : () => {
        setDrawerView(
          /* @__PURE__ */ jsx(
            EditIdVerify,
            {
              onSave: (checkPackage) => __async(void 0, null, function* () {
                template.template.idverify = { check: checkPackage, enabled: true };
                setDrawerOpen(false);
              })
            }
          )
        );
        setDrawerOpen(true);
      },
      sx: {
        py: spacing.py20
      },
      onDeleteClick: template.template.idverify && onDelete
    }
  );
};
