"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { addMonths } from "date-fns";
import { Form, FormikProvider, useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { PayrunUserHeader } from "@/v2/feature/payroll/components/payrun-user-header.component";
import { extractPayLineEntriesFromPayRunEntry } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayLineTypeMenu } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payline-type-menu.component";
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from "@/v2/feature/payroll/payroll-external.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { createShortDateAsUTC } from "@/v2/util/date-format.util";
import { setFocusToInput } from "@/v2/util/element.util";
export const EditIncomePage = ({ user, payrunEntry, payCodes, payrunClosed, saveIncomeUpdates }) => {
  const [savingUpdate, setSavingUpdate] = useState(false);
  const [focusedTextEditId, setFocusedTextEditId] = useState("");
  const initialValues = useMemo(() => {
    return {
      salary: payrunEntry.payOptions.payAmount,
      multiplier: payrunEntry.payOptions.payAmountMultiplier,
      additions: extractPayLineEntriesFromPayRunEntry(payrunEntry, payCodes, "addition")
    };
  }, [payCodes, payrunEntry]);
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSavingUpdate(true);
      yield saveIncomeUpdates([
        {
          id: payrunEntry.id,
          userId: user.userId,
          additions: {
            salary: { amount: values.salary, multiplier: values.multiplier },
            paylines: values.additions
          }
        }
      ]);
      setSavingUpdate(false);
    }),
    enableReinitialize: true
  });
  useEffect(() => {
    if (!focusedTextEditId) return;
    setFocusToInput(focusedTextEditId);
  }, [focusedTextEditId]);
  const updateAdditions = useCallback((f, entry) => {
    const idx = f.values.additions.findIndex(({ id }) => id === entry.id);
    const updatedAdditions = [...f.values.additions];
    if (idx >= 0) {
      updatedAdditions[idx] = entry;
    } else {
      updatedAdditions.push(entry);
    }
    f.setFieldValue("additions", updatedAdditions);
  }, []);
  const createAdditionPayLine = useCallback(
    (f, { code, title, defaultValue, calculationType, hourlyRateMultiplier, dailyRateMultiplier }) => {
      var _a, _b, _c, _d;
      const isHourlyRatePaycode = calculationType === "MultipleOfHourlyRate";
      const isDailyRatePaycode = calculationType === "MultipleOfDailyRate";
      const isPercentagePaycode = calculationType === "PercentageOfGross";
      const newEntry = __spreadValues(__spreadValues(__spreadValues({
        id: uuidv4(),
        code,
        amount: 0,
        description: title,
        isDeduction: false,
        recurringId: null,
        recurring: null,
        calculationType
      }, isHourlyRatePaycode ? {
        hourlyRateMultiplier,
        baseRate: (_a = payrunEntry == null ? void 0 : payrunEntry.payOptions) == null ? void 0 : _a.baseHourlyRate,
        multiplier: (_b = payrunEntry == null ? void 0 : payrunEntry.payOptions) == null ? void 0 : _b.payAmountMultiplier
      } : {}), isDailyRatePaycode ? {
        dailyRateMultiplier,
        baseRate: (_c = payrunEntry == null ? void 0 : payrunEntry.payOptions) == null ? void 0 : _c.baseDailyRate,
        multiplier: (_d = payrunEntry == null ? void 0 : payrunEntry.payOptions) == null ? void 0 : _d.payAmountMultiplier
      } : {}), isPercentagePaycode && defaultValue ? {
        defaultValue,
        amount: defaultValue
      } : {});
      updateAdditions(f, newEntry);
      return newEntry;
    },
    [payrunEntry, updateAdditions]
  );
  const updateRecurringRange = useCallback(
    (value, which, recurring) => {
      let { startDate, endDate } = recurring;
      const newDate = value;
      switch (which) {
        case "start":
          startDate = newDate;
          endDate = newDate > endDate ? newDate : endDate;
          break;
        case "end":
          endDate = newDate;
          startDate = newDate < startDate ? newDate : startDate;
          break;
      }
      return {
        startDate,
        endDate
      };
    },
    []
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Edit income" }),
    /* @__PURE__ */ jsx(PayrunUserHeader, { user, sx: { mt: spacing.m10 } }),
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsxs(Stack, { flex: 1, sx: { gap: spacing.g60, mt: spacing.m30 }, children: [
        payrunEntry.payOptions.basis === "Monthly" && /* @__PURE__ */ jsxs(Stack, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Salary" }),
          /* @__PURE__ */ jsx(
            MoneyTextfieldComponent,
            {
              name: "salary",
              label: "Salary",
              value: formik.values.salary,
              onChange: (n) => formik.setFieldValue("salary", n),
              disabled: savingUpdate || payrunClosed,
              emptyIsZero: true,
              clearToZero: true,
              sx: { mt: spacing.mt20 }
            }
          )
        ] }),
        payrunEntry.payOptions.basis !== "Monthly" && /* @__PURE__ */ jsxs(Stack, { children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between" }, children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Salary" }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: formatCurrency(formik.values.salary * formik.values.multiplier) })
          ] }),
          /* @__PURE__ */ jsxs(
            Stack,
            {
              sx: {
                flexFlow: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
                gap: spacing.g10,
                mt: spacing.mt20
              },
              children: [
                /* @__PURE__ */ jsx(
                  MoneyTextfieldComponent,
                  {
                    name: "salary",
                    label: "Rate",
                    value: formik.values.salary,
                    onChange: (n) => formik.setFieldValue("salary", n),
                    disabled: savingUpdate || payrunClosed,
                    emptyIsZero: true,
                    clearToZero: true
                  }
                ),
                /* @__PURE__ */ jsx(
                  MoneyTextfieldComponent,
                  {
                    name: "multiplier",
                    label: {
                      Daily: "Days worked",
                      Hourly: "Hours worked"
                    }[payrunEntry.payOptions.basis],
                    value: formik.values.multiplier,
                    onChange: (n) => formik.setFieldValue("multiplier", n),
                    disabled: savingUpdate || payrunClosed,
                    emptyIsZero: true,
                    clearToZero: true
                  }
                )
              ]
            }
          )
        ] }),
        formik.values.additions.sort((a, b) => a.code.localeCompare(b.code) || a.id.localeCompare(b.id)).map((addition) => {
          const {
            id,
            description,
            amount,
            recurringId,
            recurring,
            calculationType,
            multiplier,
            hourlyRateMultiplier,
            dailyRateMultiplier,
            defaultValue,
            baseRate
          } = addition;
          const nonFixedAmountPaycode = calculationType && calculationType !== "FixedAmount";
          const isHourlyRatePaycode = calculationType === "MultipleOfHourlyRate";
          const isDailyRatePaycode = calculationType === "MultipleOfDailyRate";
          const isPercentagePaycode = calculationType === "PercentageOfGross";
          let currentFixedRate = isHourlyRatePaycode ? payrunEntry.payOptions.baseHourlyRate : isDailyRatePaycode ? payrunEntry.payOptions.baseDailyRate : isPercentagePaycode ? payrunEntry.payOptions.basicPay : payrunEntry.payOptions.payAmount;
          currentFixedRate = currentFixedRate || 0;
          return /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsxs(Stack, { children: [
            /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: description }),
              isHourlyRatePaycode && currentFixedRate && multiplier && hourlyRateMultiplier && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: formatCurrency(multiplier * hourlyRateMultiplier * currentFixedRate) }),
              isDailyRatePaycode && currentFixedRate && multiplier && dailyRateMultiplier && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: formatCurrency(multiplier * dailyRateMultiplier * currentFixedRate) }),
              isPercentagePaycode && currentFixedRate && defaultValue && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: formatCurrency(defaultValue / 100 * currentFixedRate) })
            ] }),
            !nonFixedAmountPaycode && /* @__PURE__ */ jsx(
              MoneyTextfieldComponent,
              {
                id,
                name: id,
                label: "Amount",
                value: amount,
                onChange: (amount2) => typeof amount2 === "number" && updateAdditions(formik, __spreadProps(__spreadValues({}, addition), { amount: amount2 })),
                disabled: savingUpdate || payrunClosed,
                emptyIsZero: true,
                clearToZero: true,
                allowNegative: true,
                sx: { mt: spacing.mt20 }
              }
            ),
            isHourlyRatePaycode && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(
                MoneyTextfieldComponent,
                {
                  id,
                  name: id,
                  label: "Hours",
                  value: multiplier,
                  onChange: (multiplier2) => {
                    if ((multiplier2 || multiplier2 === 0) && typeof multiplier2 === "number" && baseRate && hourlyRateMultiplier && currentFixedRate) {
                      updateAdditions(formik, __spreadProps(__spreadValues({}, addition), {
                        amount: multiplier2 * hourlyRateMultiplier * currentFixedRate,
                        multiplier: multiplier2
                      }));
                    }
                  },
                  disabled: savingUpdate || payrunClosed,
                  emptyIsZero: true,
                  clearToZero: true,
                  allowNegative: true,
                  sx: { mt: spacing.mt20, mr: spacing.mr10 }
                }
              ),
              /* @__PURE__ */ jsx(
                TextfieldComponent,
                {
                  name: `${id}HourlyRate`,
                  label: "Hourly rate",
                  value: currentFixedRate,
                  validateNumber: true,
                  disabled: true,
                  sx: { mt: spacing.mt20, mr: spacing.mr10 }
                }
              ),
              /* @__PURE__ */ jsx(
                TextfieldComponent,
                {
                  name: `${id}HourlyRateMultiplier`,
                  label: "Hourly rate multiplier",
                  value: hourlyRateMultiplier,
                  validateNumber: true,
                  disabled: true,
                  sx: { mt: spacing.mt20 }
                }
              )
            ] }),
            isDailyRatePaycode && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(
                MoneyTextfieldComponent,
                {
                  id,
                  name: id,
                  label: "Days",
                  value: multiplier,
                  onChange: (multiplier2) => (multiplier2 || multiplier2 === 0) && typeof multiplier2 === "number" && baseRate && dailyRateMultiplier && currentFixedRate && updateAdditions(formik, __spreadProps(__spreadValues({}, addition), {
                    amount: multiplier2 * dailyRateMultiplier * currentFixedRate,
                    multiplier: multiplier2
                  })),
                  disabled: savingUpdate || payrunClosed,
                  emptyIsZero: true,
                  clearToZero: true,
                  allowNegative: true,
                  sx: { mt: spacing.mt20, mr: spacing.mr10 }
                }
              ),
              /* @__PURE__ */ jsx(
                TextfieldComponent,
                {
                  name: `${id}DailyRate`,
                  label: "Daily rate",
                  value: currentFixedRate,
                  disabled: true,
                  sx: { mt: spacing.mt20, mr: spacing.mr10 }
                }
              ),
              /* @__PURE__ */ jsx(
                TextfieldComponent,
                {
                  name: `${id}DailyRateMultiplier`,
                  label: "Daily rate multiplier",
                  value: dailyRateMultiplier,
                  disabled: true,
                  sx: { mt: spacing.mt20 }
                }
              )
            ] }),
            isPercentagePaycode && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(
                MoneyTextfieldComponent,
                {
                  id,
                  name: id,
                  label: "Percentage",
                  value: defaultValue,
                  disabled: true,
                  sx: { mt: spacing.mt20, mr: spacing.mr10 }
                }
              ),
              /* @__PURE__ */ jsx(
                TextfieldComponent,
                {
                  name: `${id}BaseRate`,
                  label: "Rate",
                  value: currentFixedRate,
                  validateNumber: true,
                  disabled: true,
                  sx: { mt: spacing.mt20, mr: spacing.mr10 }
                }
              )
            ] }),
            /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                name: `${id}Description`,
                label: "Name",
                value: description,
                maxLength: MAX_PAYLINE_DESCRIPTION_LENGTH,
                onChange: (e) => updateAdditions(formik, __spreadProps(__spreadValues({}, addition), { description: e.target.value })),
                clearText: () => updateAdditions(formik, __spreadProps(__spreadValues({}, addition), { description: "" })),
                disabled: savingUpdate || payrunClosed,
                sx: { mt: spacing.m30 }
              }
            ),
            /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                checked: !!recurring,
                onChange: (_, checked) => updateAdditions(formik, __spreadProps(__spreadValues({}, addition), {
                  recurringId,
                  recurring: checked ? {
                    startDate: payrunEntry.startDate,
                    endDate: createShortDateAsUTC(addMonths(Date.parse(payrunEntry.endDate), 2))
                  } : null
                })),
                label: "Recurring",
                labelSx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }),
                disabled: savingUpdate || payrunClosed,
                sx: { mt: spacing.mt20 }
              }
            ),
            recurring && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20, mt: spacing.m30 }, children: [
              /* @__PURE__ */ jsx(
                DatePickerComponent,
                {
                  name: `${id}StartDate`,
                  label: "Effective start",
                  inputFormat: "DD MMM YYYY",
                  minDate: payrunEntry.startDate,
                  maxDate: payrunEntry.endDate,
                  value: recurring.startDate,
                  onChange: (value) => updateAdditions(formik, __spreadProps(__spreadValues({}, addition), {
                    recurring: updateRecurringRange(value, "start", recurring)
                  })),
                  disabled: savingUpdate || payrunClosed
                }
              ),
              /* @__PURE__ */ jsx(
                DatePickerComponent,
                {
                  name: `${id}EndDate`,
                  label: "Effective end",
                  inputFormat: "DD MMM YYYY",
                  minDate: addMonths(Date.parse(payrunEntry.startDate), 1),
                  value: recurring.endDate,
                  onChange: (value) => updateAdditions(formik, __spreadProps(__spreadValues({}, addition), {
                    recurring: updateRecurringRange(value, "end", recurring)
                  })),
                  disabled: savingUpdate || payrunClosed
                }
              )
            ] })
          ] }) }, id);
        })
      ] }),
      payrunClosed && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m30, color: themeColors.DarkGrey }), children: "Income cannot be changed because the payrun is closed." }),
      !payrunClosed && /* @__PURE__ */ jsx(
        PayLineTypeMenu,
        {
          kind: "addition",
          payCodes,
          disabled: savingUpdate,
          onMenuItemClick: (payCode) => {
            const newEntry = createAdditionPayLine(formik, payCode);
            setFocusedTextEditId(newEntry.id);
          },
          sx: { mt: spacing.m30 }
        }
      ),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.m30, flexFlow: "row", alignItems: "center", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: "Total income" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: formatCurrency(payrunEntry.totals.additions) })
      ] }),
      !payrunClosed && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          loading: savingUpdate,
          name: "Save",
          sizeVariant: "large",
          colorVariant: "primary",
          style: { marginTop: spacing.m30 }
        }
      )
    ] }) })
  ] });
};
