"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { NewDocumentFormModal } from "@/v2/feature/document-new/components/new-document-form-modal.component";
import { FormTypeEnum } from "@/v2/feature/document-new/interfaces/document-new.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
export const DocumentActionItem = ({
  documentActionRow,
  refresh,
  afterClose
}) => {
  const { polyglot } = usePolyglot();
  const [selectDocument, setSelectedDocument] = useState(void 0);
  const [open, setOpen] = useState(false);
  const [showMessage] = useMessage();
  const getDocumentById = useCallback(() => __async(void 0, null, function* () {
    try {
      const document = yield DocumentNewAPI.getDocumensById(Number(documentActionRow.id));
      setSelectedDocument(document);
      setOpen(true);
    } catch (error) {
      showMessage(
        polyglot.t("DocumentActionItem.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, documentActionRow.id, showMessage]);
  useEffect(() => {
    getDocumentById();
  }, [getDocumentById]);
  return /* @__PURE__ */ jsx(
    NewDocumentFormModal,
    {
      isOpen: open,
      setIsOpen: setOpen,
      onClose: () => {
        setOpen(false);
        setTimeout(() => {
          setSelectedDocument(void 0);
        }, 300);
      },
      afterClose,
      refresh,
      document: selectDocument,
      formType: FormTypeEnum.upload,
      reach: ReachType.Me
    }
  );
};
