"use strict";
export var PaymentCategoryEnum = /* @__PURE__ */ ((PaymentCategoryEnum2) => {
  PaymentCategoryEnum2["EXPENSE"] = "Expense";
  PaymentCategoryEnum2["INVOICE"] = "Invoice";
  return PaymentCategoryEnum2;
})(PaymentCategoryEnum || {});
export const isExpenseCheck = (path) => path.includes("expenses");
export const PaymentCategoryOptions = Object.values(PaymentCategoryEnum).map((category) => ({
  value: category,
  label: category
}));
export var PayrolledOptionsEnum = /* @__PURE__ */ ((PayrolledOptionsEnum2) => {
  PayrolledOptionsEnum2["PayViaPayments"] = "Pay via payments";
  PayrolledOptionsEnum2["PayViaPayroll"] = "Pay via payroll";
  return PayrolledOptionsEnum2;
})(PayrolledOptionsEnum || {});
export var SyncToExternalOptionsEnum = /* @__PURE__ */ ((SyncToExternalOptionsEnum2) => {
  SyncToExternalOptionsEnum2["on_approval"] = "On approval";
  SyncToExternalOptionsEnum2["manual"] = "Manual";
  return SyncToExternalOptionsEnum2;
})(SyncToExternalOptionsEnum || {});
export const SyncToExternalOptionList = [
  { label: "On approval" /* on_approval */, value: "on_approval" },
  { label: "Manual" /* manual */, value: "manual" }
];
