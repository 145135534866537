"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { BillingDiscountAPI } from "@/api-client/billing-discount.api";
import { BillingSubscriptionAPI } from "@/api-client/billing-subscription.api";
import useMessage from "@/hooks/notification.hook";
import { FreePlanList, FreeToProPlanMap } from "@/models/subscription.model";
import { SuperAdminBillingPlanEditFormItem } from "@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-form-item.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const SuperAdminBillingPlanEditForm = ({ companyPlan, discounts, onSave }) => {
  const [subscriptionChanges, setSubscriptionChanges] = useState();
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [showMessage] = useMessage();
  const handlePlanChange = (subscriptionId, planId) => {
    var _a;
    setSubscriptionChanges(__spreadProps(__spreadValues({}, subscriptionChanges), {
      [subscriptionId]: { discountId: (_a = subscriptionChanges == null ? void 0 : subscriptionChanges[subscriptionId]) == null ? void 0 : _a.discountId, planId }
    }));
  };
  const handleDiscountChange = (subscriptionId, discountId) => {
    var _a;
    setSubscriptionChanges(__spreadProps(__spreadValues({}, subscriptionChanges), {
      [subscriptionId]: { discountId, planId: (_a = subscriptionChanges == null ? void 0 : subscriptionChanges[subscriptionId]) == null ? void 0 : _a.planId }
    }));
  };
  const detectUpgradeAndDowngrades = useCallback(() => {
    var _a, _b, _c, _d, _e, _f;
    if (!subscriptionChanges) {
      return;
    }
    const downgrades = [];
    const upgrades = [];
    const applyDiscount = [];
    const removeDiscount = [];
    const subscriptions = (companyPlan == null ? void 0 : companyPlan.subscriptions) || [];
    for (let i = 0; i < subscriptions.length; ++i) {
      const subscription = subscriptions[i];
      const updates = subscriptionChanges[subscription.id];
      if (!updates) continue;
      const discountSelected = updates.discountId && updates.discountId > 0 && updates.discountId !== ((_a = subscription.discounts[0]) == null ? void 0 : _a.id);
      const discountRemoved = updates.discountId !== void 0 && updates.discountId === 0 && subscription.discounts.length;
      if (updates.planId) {
        if (updates.planId > subscription.productPlanId) {
          let discountsToApply = [];
          if (discountSelected) {
            discountsToApply.push(updates.discountId);
          }
          if (discountRemoved) {
            removeDiscount.push({
              companyId: Number(companyPlan == null ? void 0 : companyPlan.companyId),
              productPlanId: subscription.productPlanId,
              discountId: (_b = subscription.discounts[0]) == null ? void 0 : _b.id
            });
          }
          upgrades.push(__spreadValues({
            companyId: Number(companyPlan == null ? void 0 : companyPlan.companyId),
            productPlanId: updates.planId
          }, discountsToApply.length > 0 ? { discounts: discountsToApply } : void 0));
        }
        if (updates.planId < subscription.productPlanId) {
          downgrades.push({
            id: subscription.id,
            // Cancel pro subscription
            productPlanId: subscription.productPlanId
          });
        }
        if (updates.planId === subscription.productPlanId && discountSelected && !FreePlanList.includes(updates.planId)) {
          applyDiscount.push({
            companyId: companyPlan == null ? void 0 : companyPlan.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId
          });
        }
        if (updates.planId === subscription.productPlanId && discountSelected && FreePlanList.includes(updates.planId)) {
          applyDiscount.push({
            companyId: companyPlan == null ? void 0 : companyPlan.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId
          });
          applyDiscount.push({
            companyId: companyPlan == null ? void 0 : companyPlan.companyId,
            productPlanId: FreeToProPlanMap[subscription.productPlanId],
            discountId: updates.discountId
          });
        }
        if (updates.planId === subscription.productPlanId && discountRemoved && !FreePlanList.includes(updates.planId)) {
          removeDiscount.push({
            companyId: Number(companyPlan == null ? void 0 : companyPlan.companyId),
            productPlanId: subscription.productPlanId,
            discountId: (_c = subscription.discounts[0]) == null ? void 0 : _c.id
          });
        }
      } else {
        if (discountSelected && !FreePlanList.includes(subscription.productPlanId)) {
          applyDiscount.push({
            companyId: companyPlan == null ? void 0 : companyPlan.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId
          });
        }
        if (discountRemoved && !FreePlanList.includes(subscription.productPlanId)) {
          removeDiscount.push({
            companyId: Number(companyPlan == null ? void 0 : companyPlan.companyId),
            productPlanId: subscription.productPlanId,
            discountId: (_d = subscription.discounts[0]) == null ? void 0 : _d.id
          });
        }
        if (discountRemoved && FreePlanList.includes(subscription.productPlanId)) {
          removeDiscount.push({
            companyId: Number(companyPlan == null ? void 0 : companyPlan.companyId),
            productPlanId: subscription.productPlanId,
            discountId: (_e = subscription.discounts[0]) == null ? void 0 : _e.id
          });
          removeDiscount.push({
            companyId: Number(companyPlan == null ? void 0 : companyPlan.companyId),
            productPlanId: FreeToProPlanMap[subscription.productPlanId],
            discountId: (_f = subscription.discounts[0]) == null ? void 0 : _f.id
          });
        }
        if (discountSelected && FreePlanList.includes(subscription.productPlanId)) {
          applyDiscount.push({
            companyId: companyPlan == null ? void 0 : companyPlan.companyId,
            productPlanId: subscription.productPlanId,
            discountId: updates.discountId
          });
          applyDiscount.push({
            companyId: companyPlan == null ? void 0 : companyPlan.companyId,
            productPlanId: FreeToProPlanMap[subscription.productPlanId],
            discountId: updates.discountId
          });
        }
      }
    }
    return { downgrades, upgrades, applyDiscount, removeDiscount };
  }, [companyPlan == null ? void 0 : companyPlan.companyId, companyPlan == null ? void 0 : companyPlan.subscriptions, subscriptionChanges]);
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!subscriptionChanges) {
        showMessage("No changes made", "info");
        return;
      }
      const { downgrades, upgrades, applyDiscount, removeDiscount } = detectUpgradeAndDowngrades();
      const downgradePromises = downgrades.map(
        (downgradePayload) => BillingSubscriptionAPI.downgradeSubscriptionAsSuperadmin(companyPlan.companyId, downgradePayload)
      );
      const upgradePromise = upgrades.length ? BillingSubscriptionAPI.upgradeSubscriptionAsSuperadmin(companyPlan.companyId, upgrades) : [];
      const applyDiscountPromises = applyDiscount.map((applyPayload) => BillingDiscountAPI.applyDiscount(applyPayload));
      const removeDiscountPromises = removeDiscount.map(
        (removeDiscount2) => BillingDiscountAPI.removeDiscount(removeDiscount2)
      );
      setIsSavingChanges(true);
      if (!downgradePromises.length && !upgradePromise && !applyDiscountPromises.length && !removeDiscountPromises.length) {
        return;
      }
      yield Promise.all([...downgradePromises, upgradePromise, ...applyDiscountPromises, ...removeDiscountPromises]);
      if (onSave) {
        yield onSave();
      }
      setIsSavingChanges(false);
      showMessage("Customer plan updated successfully", "success");
    } catch (e) {
      showMessage("Failed to update customer plan", "error");
      console.error(":::: error ::::", e);
      setIsSavingChanges(false);
    }
  }), [subscriptionChanges, detectUpgradeAndDowngrades, companyPlan.companyId, onSave, showMessage]);
  return /* @__PURE__ */ jsx(Fragment, { children: companyPlan && /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Edit Plan" }),
    /* @__PURE__ */ jsxs(Box, { sx: { marginTop: 5 }, children: [
      /* @__PURE__ */ jsx(
        TextField,
        {
          label: "Company Name",
          variant: "outlined",
          fullWidth: true,
          size: "medium",
          name: "companyName",
          value: companyPlan.name,
          disabled: true
        },
        5
      ),
      /* @__PURE__ */ jsx(
        TextField,
        {
          sx: { marginTop: 5 },
          label: "Company ID",
          variant: "outlined",
          fullWidth: true,
          size: "medium",
          name: "companyId",
          value: companyPlan.companyId,
          disabled: true
        },
        5
      ),
      companyPlan.subscriptions.map((subscription, index) => {
        var _a, _b, _c, _d, _e, _f;
        return /* @__PURE__ */ jsx(
          SuperAdminBillingPlanEditFormItem,
          {
            section: (_a = subscription.planName) == null ? void 0 : _a.split(" ")[0],
            planDefaultValue: (_b = subscription.planName) == null ? void 0 : _b.split(" ")[1],
            planLabel: `${(_c = subscription.planName) == null ? void 0 : _c.split(" ")[0]} Plan`,
            planInputKey: subscription.id.toString(),
            handlePlanSelect: handlePlanChange,
            discountInputKey: `${subscription.id}-${index}`,
            discountDefaultValue: (_e = subscription.discounts[((_d = subscription == null ? void 0 : subscription.discounts) == null ? void 0 : _d.length) - 1 || 0]) == null ? void 0 : _e.id,
            handleDiscountSelect: handleDiscountChange,
            discountLabel: `${(_f = subscription.planName) == null ? void 0 : _f.split(" ")[0]} Plan Discount`,
            discountList: discounts
          }
        );
      }),
      /* @__PURE__ */ jsx(Box, { width: "100%", display: "flex", justifyContent: "center", mt: 2, children: /* @__PURE__ */ jsxs(
        Button,
        {
          disabled: !subscriptionChanges || isSavingChanges,
          variant: "contained",
          size: "large",
          color: "primary",
          onClick: handleSave,
          children: [
            "Save ",
            isSavingChanges && /* @__PURE__ */ jsx(CircularProgress, { size: "medium" })
          ]
        }
      ) })
    ] })
  ] }) });
};
