"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "@/v2/feature/dashboard/dashboard.scss";
export const WidgetLayout = ({
  children,
  readOnly,
  size,
  isLoadingState = false
}) => {
  const [blur, setBlur] = useState("0px");
  useEffect(() => {
    if (isLoadingState) return;
    let currentBlur = 0;
    const interval = setInterval(() => {
      setBlur(`${currentBlur}px`);
      currentBlur++;
      if (currentBlur > 15) clearInterval(interval);
    }, 33);
    return () => clearInterval(interval);
  }, [isLoadingState]);
  return /* @__PURE__ */ jsx(
    "div",
    {
      style: {
        backdropFilter: `blur(${blur})`,
        WebkitBackdropFilter: `blur(${blur})`,
        transition: "backdropFilter 0.1s ease"
      },
      className: `base-structure ${size === "big" ? "large-width" : "small-width"} ${readOnly ? "read-structure" : ""}`,
      children: /* @__PURE__ */ jsx("div", { className: "widget-root", style: { pointerEvents: readOnly ? "none" : "inherit" }, children })
    }
  );
};
