"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { LayoutRootStyle } from "@v2/feature/app-layout/app-layout.index";
import { SuperAdminSidebar } from "@v2/feature/app-layout/features/sidebar/super-admin-sidebar.index";
import { CachedUsersProvider } from "@v2/feature/user/context/cached-users.context";
import { useSetLocalTimeHeaderInterceptor } from "@v2/infrastructure/api-client/api-client-request-interceptor.hook";
import { useApiErrorInterceptor } from "@v2/infrastructure/api-error/api-error-interceptor.hook";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { MessageWrapper } from "@/component/widgets/Message";
import { GlobalStateProvider } from "@/GlobalState";
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "@/lib/routes";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { SuperAdminRouter } from "@/v2/feature/super-admin/super-admin.router";
import { ErrorNotificationProvider } from "@/v2/infrastructure/error-notification-provider.context";
export const SuperAdminDashboardLayout = () => {
  const routerHistory = useHistory();
  const [user, setUser] = useState(null);
  const [showHiddenFeatures, setShowHiddenFeatures] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  useApiErrorInterceptor();
  useSetLocalTimeHeaderInterceptor();
  useEffect(() => {
    (function() {
      return __async(this, null, function* () {
        var _a, _b, _c, _d, _e;
        setLoading(true);
        try {
          const response = yield AuthAPI.getAuthMe();
          const showHiddenFeatures2 = response.showHiddenFeatures;
          const isSuperAdmin2 = (_a = Boolean(response && response.isSuperAdmin)) != null ? _a : false;
          setUser(response.user);
          setIsSuperAdmin(isSuperAdmin2);
          setShowHiddenFeatures(showHiddenFeatures2);
          Sentry.setUser({
            id: `${(_b = response == null ? void 0 : response.user) == null ? void 0 : _b.userId}`,
            companyId: (_d = (_c = response == null ? void 0 : response.user) == null ? void 0 : _c.company) == null ? void 0 : _d.companyId
          });
        } catch (error) {
          if (((_e = error == null ? void 0 : error.response) == null ? void 0 : _e.status) === 401) {
            routerHistory.push(LOGIN_ROUTE);
          } else {
            throw error;
          }
        }
        setLoading(false);
      });
    })();
  }, [routerHistory]);
  if (!loading && !isSuperAdmin) {
    routerHistory.push(DASHBOARD_ROUTE);
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsx(LayoutRootStyle, { children: user && /* @__PURE__ */ jsx(
    GlobalStateProvider,
    {
      initialState: {
        user,
        loggedOut: false,
        showHiddenFeatures,
        message: [],
        inspectorMode: false,
        paymentFailed: false,
        drawerState: false,
        isTestModeEnabled: false,
        trialMode: { enabled: false, lapsed: false },
        alerts: {},
        showSettingBar: false,
        publicURL: null
      },
      children: /* @__PURE__ */ jsxs(ErrorNotificationProvider, { axios, children: [
        /* @__PURE__ */ jsxs(CachedUsersProvider, { children: [
          /* @__PURE__ */ jsx(SuperAdminSidebar, {}),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                height: "100vh",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                overflowY: "auto",
                overflowX: "hidden"
              },
              children: /* @__PURE__ */ jsx(SuperAdminRouter, {})
            }
          )
        ] }),
        /* @__PURE__ */ jsx(MessageWrapper, {})
      ] })
    }
  ) });
};
