"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { OffboardingAbsenceBalancesTable } from "@v2/feature/offboarding/offboarding-process/absence/offboarding-absence-balances-table.component";
import { OffboardingFutureAbsencesTable } from "@v2/feature/offboarding/offboarding-process/absence/offboarding-future-absences-table.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { LocalDate } from "@v2/util/local-date";
import { generatePath } from "react-router-dom";
import { USER_OFFBOARDING_ROUTE } from "@/lib/routes";
export const OffboardingAbsencePage = ({ userId, offboardingState, refresh, loading }) => {
  var _a;
  const userLeaveDate = useMemo(() => {
    var _a2;
    return (_a2 = offboardingState.leaveDate) != null ? _a2 : new LocalDate().toDateString();
  }, [offboardingState]);
  const { data: userBalanceStats, mutate: refreshPolicyBalances } = useApiClient(
    AbsenceEndpoints.fetchUserAbsenceBalanceBreakdown(userId, {
      effectiveDate: userLeaveDate
    }),
    { suspense: false }
  );
  const { data: userPolicies } = useApiClient(AbsenceEndpoints.getUserAbsencePolicies(userId), {
    suspense: false
  });
  const { data: absencesAfterLeaveDate, mutate: refreshFutureAbsences } = useApiClient(
    AbsenceEndpoints.getUserAbsencesAfterLeaveDate(userId),
    {
      suspense: false
    }
  );
  const refreshBalances = useCallback(() => __async(void 0, null, function* () {
    if (refreshPolicyBalances) yield refreshPolicyBalances();
  }), [refreshPolicyBalances]);
  const refreshAbsences = useCallback(() => __async(void 0, null, function* () {
    if (refreshFutureAbsences) yield refreshFutureAbsences();
  }), [refreshFutureAbsences]);
  const refreshBalancesAndState = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshBalances(), refresh()]);
  }), [refreshBalances, refresh]);
  const refreshAll = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshBalances(), refresh(), refreshAbsences()]);
  }), [refreshBalances, refresh, refreshAbsences]);
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OffboardingPage.absence") }),
        showBack: true,
        backPath: generatePath(USER_OFFBOARDING_ROUTE, { userId })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "900px", width: "100%" }, children: [
      /* @__PURE__ */ jsx(
        OffboardingAbsenceBalancesTable,
        {
          userId,
          userBalanceStats,
          userPolicies: userPolicies != null ? userPolicies : [],
          userLeaveDate: (_a = offboardingState.leaveDate) != null ? _a : new LocalDate().toDateString(),
          refreshState: refreshBalancesAndState,
          refreshFutureRequests: refreshAbsences
        }
      ),
      absencesAfterLeaveDate && absencesAfterLeaveDate.length > 0 && /* @__PURE__ */ jsx(
        OffboardingFutureAbsencesTable,
        {
          absencesAfterLeaveDate: absencesAfterLeaveDate != null ? absencesAfterLeaveDate : [],
          refreshAll
        }
      )
    ] }) })
  ] });
};
