"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { PayrollSettingHMRCEdit } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-edit.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollCompanyHMRCSetupPage = ({ onClose, payroll, payrollId }) => {
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, height: "100%", gap: spacing.m30, mx: spacing.mx40, mt: spacing.m30 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Complete HMRC Information" }),
    /* @__PURE__ */ jsx(PayrollSettingHMRCEdit, { payrollId, payroll, onClose })
  ] });
};
