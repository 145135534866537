"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import {
  getDietaryOptions
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
export const DietaryRestrictionsSelect = ({
  disabled,
  name,
  value,
  onChange,
  required
}) => {
  const { polyglot } = usePolyglot();
  const selectedValues = useMemo(() => {
    if (!value) return [];
    const dietaryValues = value == null ? void 0 : value.trim().split(/\s*,\s*/);
    return getDietaryOptions(polyglot).filter((option) => dietaryValues.includes(option.value));
  }, [value, polyglot]);
  return /* @__PURE__ */ jsx(
    MultipleSelectCheckbox,
    {
      label: polyglot.t("DietaryRestrictionsSelect.label"),
      id: name,
      disabled,
      limitTags: -1,
      options: getDietaryOptions(polyglot),
      value: selectedValues,
      onChange: (e, values) => {
        const updatedOptionList = values.map(({ value: value2 }) => value2).join(",");
        onChange(e, updatedOptionList);
      },
      isOptionEqualToValue: (x, y) => x.value === y.value,
      getOptionLabel: ({ label }) => label,
      required
    }
  );
};
