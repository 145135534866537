"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { TranslationDrawer } from "@v2/feature/company/company-translation/company-translation-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const TranslationTable = ({ translations, refreshTable }) => {
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const tableColumns = useMemo(() => {
    return [
      {
        header: () => "EN",
        accessorFn: (row) => row,
        id: "en",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.en),
        cell: ({ row: { original } }) => original.en ? /* @__PURE__ */ jsx("div", { children: original.en }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "AR",
        accessorFn: (row) => row,
        id: "ar",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.ar),
        cell: ({ row: { original } }) => original.ar ? /* @__PURE__ */ jsx("div", { children: original.ar }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "DE",
        accessorFn: (row) => row,
        id: "de",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.de),
        cell: ({ row: { original } }) => original.de ? /* @__PURE__ */ jsx("div", { children: original.de }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "FR",
        accessorFn: (row) => row,
        id: "fr",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.fr),
        cell: ({ row: { original } }) => original.fr ? /* @__PURE__ */ jsx("div", { children: original.fr }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "ES",
        accessorFn: (row) => row,
        id: "es",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.es),
        cell: ({ row: { original } }) => original.es ? /* @__PURE__ */ jsx("div", { children: original.es }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "IT",
        accessorFn: (row) => row,
        id: "it",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.it),
        cell: ({ row: { original } }) => original.it ? /* @__PURE__ */ jsx("div", { children: original.it }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "PT",
        accessorFn: (row) => row,
        id: "pt",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.pt),
        cell: ({ row: { original } }) => original.pt ? /* @__PURE__ */ jsx("div", { children: original.pt }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Created by",
        accessorFn: (row) => row,
        id: "creator",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.fr),
        cell: ({ row: { original } }) => {
          var _a;
          return ((_a = original.creator) == null ? void 0 : _a.displayName) ? /* @__PURE__ */ jsx("div", { children: polyglot.t(original.creator.displayName) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Created at",
        accessorFn: (row) => row,
        id: "createdAt",
        maxSize: 150,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.fr),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: new Date(original.updatedAt).toLocaleString() })
      },
      {
        header: () => "",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", width: "100%", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: polyglot.t("General.edit"),
            onClick: (event) => {
              event.stopPropagation();
              setSelectedTranslation(original);
              setIsEditOpen(true);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ];
  }, [polyglot]);
  const hiddenColumns = useMemo(() => {
    const allNonEnglishColumns = ["ar", "it", "pt", "es", "de", "fr"];
    const existingKeysWithValues = translations.reduce((result, tr) => {
      for (const key of allNonEnglishColumns) if (tr[key]) result.add(key);
      return result;
    }, /* @__PURE__ */ new Set());
    return allNonEnglishColumns.filter((k) => !existingKeysWithValues.has(k));
  }, [translations]);
  return /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
    isEditOpen && /* @__PURE__ */ jsx(
      TranslationDrawer,
      {
        isOpen: isEditOpen,
        setIsOpen: setIsEditOpen,
        translation: selectedTranslation,
        onClose: () => setSelectedTranslation(null),
        refresh: refreshTable
      }
    ),
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: translations,
        columnData: tableColumns,
        hiddenColumns
      }
    )
  ] });
};
