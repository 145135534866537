"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, FormControl, Tooltip } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TASKS_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { RelativeAssignmentValues } from "@/v2/feature/task/subfeature/checklist/checklist-item.dto";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { dateShortcutSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const LaunchSchema = (polyglot) => Yup.object().shape({
  dueDate: Yup.string().nullable().required(),
  assignedUserIds: Yup.array().of(Yup.number()).required(polyglot.t("LaunchFormDrawer.errorMessages.needUser")),
  assignedUserId: Yup.number().nullable().notRequired().typeError(polyglot.t("LaunchFormDrawer.errorMessages.assignUser"))
});
export const LaunchFormDrawer = ({ checklist, onClose }) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const isSingleSelect = useMemo(() => {
    return checklist.checklistItems.find(
      (item) => item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee || item.requestedForPlaceholder === RelativeAssignmentValues.manager
    );
  }, [checklist]);
  const showAssigneeSelect = useMemo(() => {
    return checklist.checklistItems.find(
      (item) => item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee || item.requestedForPlaceholder === RelativeAssignmentValues.manager || item.assignedToPlaceholder === RelativeAssignmentValues.checklistAssignee || item.assignedToPlaceholder === RelativeAssignmentValues.manager
    );
  }, [checklist]);
  const formik = useFormik({
    initialValues: {
      dueDate: null,
      assignedUserIds: [],
      assignedUserId: null
    },
    enableReinitialize: true,
    validationSchema: LaunchSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (isSingleSelect && values.assignedUserId) {
          values.assignedUserIds = [values.assignedUserId];
        }
        yield TaskAPI.launchChecklistById(checklist.id, values);
        showMessage(polyglot.t("LaunchFormDrawer.successMessages.create"), "success");
        onClose();
        routerHistory.push(TASKS_COMPANY_OVERVIEW_ROUTE);
      } catch (error) {
        showMessage(
          polyglot.t("LaunchFormDrawer.errorMessages.launch", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: spacing.m10 }, children: polyglot.t("LaunchFormDrawer.createChecklist") }),
    showAssigneeSelect && /* @__PURE__ */ jsx(Fragment, { children: isSingleSelect ? /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "assignedUserId",
        options: "company",
        onChange: (_, x) => {
          var _a;
          return formik.setFieldValue("assignedUserId", (_a = x == null ? void 0 : x.value) != null ? _a : null);
        },
        value: formik.values.assignedUserId,
        label: polyglot.t("LaunchFormDrawer.whoIsAssignee"),
        error: formik.touched.assignedUserId && Boolean(formik.errors.assignedUserId),
        helperText: formik.touched.assignedUserId && formik.errors.assignedUserId
      }
    ) }) : /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(Tooltip, { title: polyglot.t("LaunchFormDrawer.whoShouldCompleteTask"), placement: "top-start", children: /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("LaunchFormDrawer.whoIsAssignee"),
        selectedLabel: polyglot.t("LaunchFormDrawer.checklistUser"),
        value: formik.values.assignedUserIds,
        onChange: (userIds) => {
          formik.setFieldValue("assignedUserIds", userIds);
        },
        fieldSx: __spreadValues({}, spacing.mb20)
      }
    ) }) }) }),
    /* @__PURE__ */ jsx(Box, { sx: dateShortcutSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        shortcuts: true,
        shortcutLabel: "When is the checklist due date?",
        value: formik.values.dueDate,
        onChange: (value) => {
          formik.setFieldValue("dueDate", value);
        },
        name: "dueDate",
        label: polyglot.t("LaunchFormDrawer.date"),
        error: !!formik.errors.dueDate && Boolean(formik.touched.dueDate),
        helperText: formik.errors.dueDate && Boolean(formik.touched.dueDate)
      }
    ) }) }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues(__spreadValues({}, buttonBoxSx), spacing.mt20), children: [
      /* @__PURE__ */ jsxs(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: () => onClose(), children: [
        polyglot.t("General.cancel"),
        " "
      ] }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.launch"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) }) });
};
