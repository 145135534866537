"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import {
  DOCUMENTS_NEW_ME_ROUTE,
  TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE,
  TEMPLATE_CONTRACT_SIGN_ROUTE
} from "@/lib/routes";
import { DnUserListingPage } from "@/v2/feature/document-new/features/document-new-user/dn-user-listing.page";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { TemplateEditorPage } from "@/v2/feature/templates/pages/template-editor-v-two.page";
import { ReachType, UserType } from "@/v2/interfaces/general.interface";
export const DocumentNewMeRouter = ({ customRuleDataset }) => {
  const [globalState] = useContext(GlobalContext);
  const { userId } = globalState.user;
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: TEMPLATE_CONTRACT_SIGN_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "readonly", action: "sign" }) }),
    /* @__PURE__ */ jsx(Route, { path: TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "readonly", action: "company_sign" }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_MY_DOCUMENTS, path: DOCUMENTS_NEW_ME_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
      DnUserListingPage,
      {
        userId,
        reach: ReachType.Me,
        userReach: UserType.App,
        customRuleDataset
      }
    ) })
  ] });
};
