"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { getTheDateOfFirstMondayInYear } from "@v2/feature/attendance/attendance.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const AttendancePatternScheduleStartDateDrawer = ({
  isOpen,
  setIsOpen,
  drawerDetails,
  setDrawerDetails,
  addWeek,
  duplicateWeek,
  schedule
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      startDateOfFirstWeek: (_a = schedule == null ? void 0 : schedule.startDateOfFirstWeek) != null ? _a : getTheDateOfFirstMondayInYear()
    },
    validationSchema: yup.object({
      startDateOfFirstWeek: yup.string().test(dateFieldTest).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      if ((drawerDetails == null ? void 0 : drawerDetails.action) === "add") yield addWeek(values.startDateOfFirstWeek);
      else if ((drawerDetails == null ? void 0 : drawerDetails.action) === "duplicate")
        yield duplicateWeek(drawerDetails.weekIndexToCopy, values.startDateOfFirstWeek);
      setLoading(false);
      setDrawerDetails(null);
      setIsOpen(false);
    })
  });
  useEffect(() => {
    if (isOpen) formik.resetForm();
  }, [isOpen]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, fixedWidthPx: 465, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceSchedulePattern.setStartDate") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AttendanceSchedulePattern.setStartDateDesc") }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: formik.values.startDateOfFirstWeek,
        onChange: (value) => __async(void 0, null, function* () {
          if (dayjs(value).isValid()) yield formik.setFieldValue("startDateOfFirstWeek", value);
          else yield formik.setFieldValue("startDateOfFirstWeek", "");
        }),
        name: "startDateOfFirstWeek",
        label: polyglot.t("AttendanceSchedulePattern.selectDate"),
        error: !!formik.errors.startDateOfFirstWeek && formik.touched.startDateOfFirstWeek,
        helperText: formik.touched.startDateOfFirstWeek && formik.errors.startDateOfFirstWeek,
        shouldDisableDate: (date) => {
          if (!dayjs(date).isValid()) return true;
          return dayjs(date).day() !== 1;
        }
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          name: polyglot.t("General.save"),
          onClick: () => formik.handleSubmit(),
          loading
        }
      )
    ] })
  ] }) }) });
};
