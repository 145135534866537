"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { TemplateAPI } from "@/api-client/templates.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ContractModal } from "@/v2/feature/templates/components/contract-modal.component";
import { MissingTemplateFieldModal } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { SelectTemplateForContractModal } from "@/v2/feature/templates/components/select-template-for-contract-modal.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const ContractTemplateManager = React.memo(
  ({
    openTemplateModal,
    setOpenTemplateModal,
    openMissingFieldContractModal,
    setOpenMissingFieldContractModal,
    missingFields,
    setMissingFields
  }) => {
    const [openContractModal, setOpenContractModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(void 0);
    const [contractRecipientUserId, setContractRecipientUserId] = useState();
    const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState();
    const [showMessage] = useMessage();
    const { polyglot } = usePolyglot();
    const onMissingField = (params) => {
      const { contractRecipientUserId: contractRecipientUserId2, templateVerificationResult } = params;
      setContractRecipientUserId(contractRecipientUserId2);
      setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
      setMissingFields(templateVerificationResult.fields);
      setOpenMissingFieldContractModal(true);
    };
    const refreshMissingFieldsForTemplate = useCallback(
      (templateId, contractRecipientUserId2, companySignatoryUserId) => __async(void 0, null, function* () {
        try {
          const templateResult = yield TemplateAPI.verifyTemplateParameters({
            templateId,
            contractRecipientUserId: contractRecipientUserId2,
            companySignatoryUserId
          });
          setMissingFields(templateResult.fields);
        } catch (error) {
          showMessage(
            polyglot.t("TemplatesPage.errorMessages.verify", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      }),
      [polyglot, showMessage, setMissingFields]
    );
    return /* @__PURE__ */ jsxs("div", { children: [
      /* @__PURE__ */ jsx(
        SelectTemplateForContractModal,
        {
          open: openTemplateModal,
          setOpen: setOpenTemplateModal,
          setSelectedTemplate: (template) => {
            setSelectedTemplate(template != null ? template : void 0);
            setOpenContractModal(true);
          },
          onClose: () => {
            setOpenTemplateModal(false);
            setTimeout(() => {
              setSelectedTemplate(void 0);
            }, 300);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        ContractModal,
        {
          open: openContractModal,
          setOpen: setOpenContractModal,
          contractTemplate: selectedTemplate,
          onMissingField
        }
      ),
      /* @__PURE__ */ jsx(
        MissingTemplateFieldModal,
        {
          open: openMissingFieldContractModal,
          setOpen: setOpenMissingFieldContractModal,
          templateId: selectedTemplate == null ? void 0 : selectedTemplate.id,
          missingFields,
          contractRecipientId: contractRecipientUserId,
          companySignatoryUserId: contractCompanySignatoryUserId,
          refreshMissingFields: refreshMissingFieldsForTemplate
        }
      )
    ] });
  }
);
