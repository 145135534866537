"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from "react";
import { PaymentsAPI } from "@v2/feature/payments/payments.api";
import {
  PayrunPaymentsStates,
  PayrunProcessStepStates
} from "@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface";
import { PayrollExternalApi } from "@v2/feature/payroll/payroll-external.api";
import { PayrollLocalApi } from "@v2/feature/payroll/payroll-local.api";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PAYMENT_COMPANY_ROUTE } from "@/lib/routes";
import { PayrunProcessingItem } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component";
export const PayrunPayments = ({
  payRun,
  payrunPaymentsState,
  setPayrunPaymentsState,
  sx
}) => {
  const routerHistory = useHistory();
  const [accordionState, setAccordionState] = useState(PayrunProcessStepStates.pending);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showMessage] = useMessage();
  const [, dispatch] = useContext(GlobalContext);
  const csvRef = useRef();
  const [csvFile, setCSVFile] = useState({
    name: "default.csv",
    data: []
  });
  useEffect(() => {
    if ((csvFile == null ? void 0 : csvFile.data) && csvFile.name !== "default.csv" && csvRef.current && csvRef.current.link) {
      csvRef.current.link.click();
    }
  }, [csvFile]);
  const downloadCSVFile = () => __async(void 0, null, function* () {
    var _a;
    try {
      setIsDownloading(true);
      const req = yield PayrollExternalApi.getBankPaymentInstructionsForPayrun(payRun.id, "text/csv");
      setCSVFile({
        name: `payrun-${payRun.taxYear}-Month-${payRun.period}-wires.csv`,
        data: (_a = req.content) != null ? _a : req
      });
    } catch (error) {
      showMessage(`Could not download the payments CSV. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDownloading(false);
    }
  });
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        const payRunPaymentsDetails = yield PayrollLocalApi.getPayrunPaymentsRecord(payRun.id);
        setPayrunPaymentsState(payRunPaymentsDetails.state);
        if (PayrunPaymentsStates.created === payRunPaymentsDetails.state) {
          setAccordionState(PayrunProcessStepStates.success);
          return;
        }
        const updatedPayrunPaymentsDetails = yield PayrollLocalApi.createPayrunBankPaymentsForPayrun(payRun.id);
        setPayrunPaymentsState(updatedPayrunPaymentsDetails.state);
        setAccordionState(PayrunProcessStepStates.success);
      } catch (error) {
        setAccordionState(PayrunProcessStepStates.failure);
        showMessage(`Could not create payrun bank payments. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [payRun]);
  const createPayrunBankPayments = () => __async(void 0, null, function* () {
    try {
      const updatedPayrunPaymentsDetails = yield PayrollLocalApi.createPayrunBankPaymentsForPayrun(payRun.id);
      setPayrunPaymentsState(updatedPayrunPaymentsDetails.state);
      setAccordionState(PayrunProcessStepStates.success);
    } catch (error) {
      showMessage(`Error: Could not create payrun bank payments. ${nestErrorMessage(error)}`, "error");
      setAccordionState(PayrunProcessStepStates.failure);
    }
    try {
      yield PaymentsAPI.getAlerts(dispatch);
    } catch (error) {
      showMessage(`Error: Could not update alerts. ${nestErrorMessage(error)}`, "error");
    }
  });
  const canDownload = payrunPaymentsState === PayrunPaymentsStates.created;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      PayrunProcessingItem,
      {
        title: "Payments",
        description: {
          pending: "Creating bank payments...",
          failure: `Payrun bank payments could not be generated.`,
          success: "Payrun bank payments have been created. You can find them in the Payments section.",
          warning: "Payrun bank payments generated a warning."
        }[accordionState],
        buttons: [
          {
            style: "primary",
            label: "Retry",
            show: accordionState !== PayrunProcessStepStates.success,
            onClick: () => createPayrunBankPayments(),
            type: "button"
          },
          {
            style: "secondary",
            label: "Download",
            show: canDownload,
            loading: isDownloading,
            onClick: () => downloadCSVFile(),
            type: "button"
          },
          {
            style: "primary",
            label: "Go to Payments",
            show: canDownload,
            onClick: () => routerHistory.push(PAYMENT_COMPANY_ROUTE),
            type: "button"
          }
        ],
        success: accordionState === PayrunProcessStepStates.pending ? void 0 : accordionState === PayrunProcessStepStates.success,
        sx
      },
      "payments"
    ),
    /* @__PURE__ */ jsx(
      CSVLink,
      {
        ref: csvRef,
        filename: csvFile.name,
        data: Array.isArray(csvFile.data) || typeof csvFile.data === "string" ? csvFile.data : []
      }
    )
  ] });
};
