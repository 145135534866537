"use strict";
import { jsx } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { primarySmallBtn } from "@v2/styles/buttons.styles";
import ModalV2 from "@/component/widgets/ModalV2";
export const InfoModal = ({ title, isOpen, onClose, children, size = "xs" }) => {
  return /* @__PURE__ */ jsx(
    ModalV2,
    {
      isOpen,
      onClose,
      size,
      title,
      actionButton: /* @__PURE__ */ jsx(Button, { sx: primarySmallBtn, onClick: () => onClose(), children: "OK" }),
      buttonJustifyContent: "center",
      children
    }
  );
};
