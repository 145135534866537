"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { iconSize } from "@v2/styles/table.styles";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Success } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Failed } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as Loading } from "@/images/side-bar-icons/Spin.svg";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize20px = { height: "20px", width: "20px" };
export const PayrunProcessingItem = ({ title, description, success, buttons, sx }) => {
  const isProcessing = typeof success !== "boolean";
  const header = /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: title }),
    typeof description === "string" ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: isProcessing ? themeColors.Grey : themeColors.DarkGrey }), children: description }) : description
  ] });
  return /* @__PURE__ */ jsxs(
    Stack,
    {
      sx: __spreadValues({
        flexDirection: "row",
        width: "600px",
        borderTop: `1px solid`,
        py: spacing.p25,
        borderTopColor: themeColors.middleGrey
      }, sx),
      children: [
        isProcessing && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Loading, __spreadProps(__spreadValues({}, iconSize20px), { style: { flexShrink: 0, marginRight: spacing.m15 } })),
          header
        ] }),
        !isProcessing && /* @__PURE__ */ jsxs(Fragment, { children: [
          success ? /* @__PURE__ */ jsx(Success, __spreadProps(__spreadValues({}, iconSize20px), { style: { fill: themeColors.Green, flexShrink: 0 } })) : /* @__PURE__ */ jsx(Failed, __spreadProps(__spreadValues({}, iconSize20px), { style: { fill: themeColors.Red, flexShrink: 0 } })),
          /* @__PURE__ */ jsxs(Stack, { sx: { mx: spacing.m15 }, children: [
            header,
            /* @__PURE__ */ jsx(Stack, { sx: { flexDirection: "row", gap: spacing.g10 }, children: buttons == null ? void 0 : buttons.map(
              (b) => b.show && b.type === "dropdown" && b.dropdownOptions ? /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: b.dropdownOptions,
                  loading: b.loading,
                  actionButtonDetails: {
                    icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                    iconPosition: "end",
                    fullWidth: true,
                    type: "button",
                    colorVariant: b.style,
                    sizeVariant: "small",
                    title: "Download"
                  },
                  sx: { marginTop: "20px", minWidth: "100px" }
                }
              ) : b.show && b.type === "button" && b.onClick ? /* @__PURE__ */ jsx(
                LoaderButton,
                {
                  type: "button",
                  loading: b.loading,
                  onClick: b.onClick,
                  sizeVariant: "small",
                  colorVariant: b.style,
                  style: { minWidth: "100px", marginTop: "20px" },
                  name: b.label
                }
              ) : null
            ) })
          ] })
        ] })
      ]
    }
  );
};
