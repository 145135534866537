"use strict";
export var RequestStatus = /* @__PURE__ */ ((RequestStatus2) => {
  RequestStatus2["Requested"] = "Requested";
  RequestStatus2["Scheduled"] = "Scheduled";
  RequestStatus2["Pending"] = "Pending";
  RequestStatus2["Completed"] = "Completed";
  RequestStatus2["Failed"] = "Failed";
  RequestStatus2["Rejected"] = "Rejected";
  RequestStatus2["Cancelled"] = "Cancelled";
  return RequestStatus2;
})(RequestStatus || {});
