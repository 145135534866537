"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { FormControlLabel, IconButton, RadioGroup, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PaymentEntitySelect } from "@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/payment-entity-select.component";
import { GlobalPayrollAPI } from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { cleanPayCodeValue } from "@/v2/feature/payroll/features/payroll-global/global-payroll.util";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GlobalPayrollPayCodeSeederDrawer = ({
  countryCode,
  paycode,
  paycodes,
  onPaycodeSaved,
  onPaycodeDeleted
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const otherPaycodes = useMemo(() => paycodes.filter((p) => p.code !== (paycode == null ? void 0 : paycode.code)), [paycode == null ? void 0 : paycode.code, paycodes]);
  const currentCreditors = useMemo(() => paycodes.map(({ credit }) => credit), [paycodes]);
  const currentDebitors = useMemo(() => paycodes.map(({ debit }) => debit), [paycodes]);
  const addOrEditPaycode = useCallback(
    (newPaycode) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield GlobalPayrollAPI.updatePaycodeSeedersAsSuperAdmin(countryCode, [newPaycode, ...otherPaycodes]);
      } catch (e) {
        showMessage(`Failed to add paycode. ${nestErrorMessage(e)}`, "error");
        setSaving(false);
        return;
      }
      showMessage(`Paycodes updated.`, "success");
      onPaycodeSaved == null ? void 0 : onPaycodeSaved();
    }),
    [countryCode, onPaycodeSaved, otherPaycodes, showMessage]
  );
  const deletePaycode = useCallback(() => __async(void 0, null, function* () {
    setSaving(true);
    try {
      yield GlobalPayrollAPI.updatePaycodeSeedersAsSuperAdmin(countryCode, otherPaycodes);
    } catch (e) {
      showMessage(`Failed to delete paycode. ${nestErrorMessage(e)}`, "error");
      setSaving(false);
      return;
    }
    showMessage(`Paycode deleted.`, "success");
    onPaycodeDeleted == null ? void 0 : onPaycodeDeleted();
  }), [countryCode, onPaycodeDeleted, otherPaycodes, showMessage]);
  const formik = useFormik({
    initialValues: {
      code: (_a = paycode == null ? void 0 : paycode.code) != null ? _a : "",
      name: (_b = paycode == null ? void 0 : paycode.name) != null ? _b : "",
      order: (_c = paycode == null ? void 0 : paycode.order) != null ? _c : paycodes.length,
      required: (_d = paycode == null ? void 0 : paycode.required) != null ? _d : false,
      selectedDebit: (_e = paycode == null ? void 0 : paycode.debit) != null ? _e : "",
      selectedCredit: (_f = paycode == null ? void 0 : paycode.credit) != null ? _f : "",
      paycodeKind: (paycode == null ? void 0 : paycode.credit) ? "paycode-payment" : "paycode-value",
      formula: (_g = paycode == null ? void 0 : paycode.formula) != null ? _g : ""
    },
    validationSchema: yup.object({
      code: yup.string().required("Code is required").notOneOf(
        otherPaycodes.map((p) => p.code),
        "Pay code already defined"
      ),
      name: yup.string().required("Name is required"),
      selectedCredit: yup.string().when("paycodeKind", {
        is: (val) => val === "paycode-payment",
        then: yup.string().required("Credit party is required"),
        otherwise: yup.string().optional()
      }),
      selectedDebit: yup.string().when("paycodeKind", {
        is: (val) => val === "paycode-payment",
        then: yup.string().required("Debit party is required"),
        otherwise: yup.string().optional()
      })
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      const update = {
        code: values.code,
        name: values.name,
        credit: values.paycodeKind === "paycode-payment" ? values.selectedCredit : "",
        debit: values.paycodeKind === "paycode-payment" ? values.selectedDebit : "",
        formula: values.formula.trim(),
        order: values.order,
        required: values.required
      };
      yield addOrEditPaycode(update);
    })
  });
  const orders = useMemo(() => {
    const orderedCodes = otherPaycodes.map(({ name, order }) => ({ name, order })).sort((a, b) => a.order - b.order);
    orderedCodes.splice(formik.values.order, 0, { name: formik.values.name, order: formik.values.order });
    return orderedCodes.map(({ name }, n) => ({
      label: `${n}`,
      value: n,
      description: name
    }));
  }, [otherPaycodes, formik.values.order, formik.values.name]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexDirection: "row", justifyContent: "space-between" }, children: [
      !!paycode && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Edit pay code" }),
        /* @__PURE__ */ jsx(IconButton, { title: "Delete pay code", sx: tableIconButtonSx, onClick: () => deletePaycode(), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
      ] }),
      !paycode && /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "New pay code" })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g10, mt: spacing.mt30 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "code",
          label: "Code",
          value: formik.values.code,
          onChange: (e) => formik.setFieldValue("code", cleanPayCodeValue(e.target.value)),
          disabled: saving,
          error: formik.submitCount > 0 && !!formik.errors.code,
          helperText: formik.submitCount > 0 && formik.errors.code,
          autoFocus: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: "Name",
          value: formik.values.name,
          onChange: formik.handleChange,
          disabled: saving,
          error: formik.submitCount > 0 && !!formik.errors.name,
          helperText: formik.submitCount > 0 && formik.errors.name
        }
      ),
      /* @__PURE__ */ jsxs(
        RadioGroup,
        {
          name: "paycodeKind",
          value: formik.values.paycodeKind,
          onChange: formik.handleChange,
          sx: { flexFlow: "row" },
          children: [
            /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: "paycode-payment",
                control: /* @__PURE__ */ jsx(StyledRadio, { disableRipple: true }),
                label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Payment" }),
                disabled: saving
              }
            ),
            /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: "paycode-value",
                control: /* @__PURE__ */ jsx(StyledRadio, { disableRipple: true }),
                label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Value" }),
                disabled: saving
              }
            )
          ]
        }
      ),
      formik.values.paycodeKind === "paycode-payment" && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(
          PaymentEntitySelect,
          {
            name: "selectedCredit",
            label: "Credit",
            values: currentCreditors,
            value: formik.values.selectedCredit,
            onChange: (value) => {
              formik.setFieldValue("selectedCredit", value);
            },
            disabled: saving,
            error: formik.submitCount > 0 && !!formik.errors.selectedCredit,
            helperText: formik.submitCount > 0 && formik.errors.selectedCredit
          }
        ) }),
        /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(
          PaymentEntitySelect,
          {
            name: "selectedDebit",
            label: "Debit",
            values: currentDebitors,
            value: formik.values.selectedDebit,
            onChange: (value) => {
              formik.setFieldValue("selectedDebit", value);
            },
            disabled: saving,
            error: formik.submitCount > 0 && !!formik.errors.selectedDebit,
            helperText: formik.submitCount > 0 && formik.errors.selectedDebit
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "formula",
          label: "Default value",
          value: formik.values.formula,
          onChange: formik.handleChange,
          multiline: true,
          minRows: 4,
          InputProps: { style: { whiteSpace: "pre", overflow: "auto" } },
          disabled: saving
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "order",
          label: "Payrun display order",
          options: orders,
          value: formik.values.order,
          onChange: formik.handleChange,
          disabled: saving
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "required",
          label: "Required",
          checked: formik.values.required,
          onChange: formik.handleChange,
          disabled: saving
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        type: "submit",
        fullWidth: true,
        loading: saving,
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: spacing.m20 }
      }
    )
  ] }) });
};
