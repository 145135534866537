"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownArrow } from "@/images/fields/DownArrow.svg";
import { ReactComponent as UpArrow } from "@/images/fields/UpArrow.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SurveySectionAPI } from "@/v2/feature/growth/surveys/api-client/survey-section.api";
import { iconSize } from "@/v2/styles/menu.styles";
export const useSurveyCycleSectionOptions = (section, setSection, setIsSectionModalOpen, cycle, refreshSurveyStructure) => {
  const [showMessage] = useMessage();
  if (!cycle || !cycle.order) return [];
  const { id: cycleId } = cycle;
  const currentOrder = cycle.order ? [...cycle.order] : [];
  const findSectionIndex = () => currentOrder.findIndex((o) => o.type === "section" && o.id === section.id);
  const isSectionFirst = () => findSectionIndex() === 0;
  const isSectionLast = () => findSectionIndex() === currentOrder.length - 1;
  const moveSection = (direction) => __async(void 0, null, function* () {
    const sectionIndex = findSectionIndex();
    if (direction === "up" && sectionIndex > 0) {
      [currentOrder[sectionIndex - 1], currentOrder[sectionIndex]] = [
        currentOrder[sectionIndex],
        currentOrder[sectionIndex - 1]
      ];
    } else if (direction === "down" && sectionIndex < currentOrder.length - 1) {
      [currentOrder[sectionIndex], currentOrder[sectionIndex + 1]] = [
        currentOrder[sectionIndex + 1],
        currentOrder[sectionIndex]
      ];
    }
    yield SurveyCycleAPI.updateOrderSurveyCycle({ cycleId, order: currentOrder });
    yield refreshSurveyStructure == null ? void 0 : refreshSurveyStructure();
  });
  return [
    {
      icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        setSection(section);
        setIsSectionModalOpen(true);
      }),
      label: "Edit",
      disabled: false
    },
    {
      icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        try {
          yield SurveySectionAPI.deleteSurveySection(section.id, cycleId);
          yield refreshSurveyStructure == null ? void 0 : refreshSurveyStructure();
          showMessage("Successfully delete the section", "success");
        } catch (error) {
          showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
        }
      }),
      label: "Delete",
      disabled: false
    },
    {
      icon: /* @__PURE__ */ jsx(UpArrow, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        try {
          if (!cycle || !cycle.order || isSectionFirst()) return;
          yield moveSection("up");
          showMessage("Successfully moved the section up", "success");
        } catch (error) {
          showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
        }
      }),
      label: "Move up",
      disabled: isSectionFirst()
    },
    {
      icon: /* @__PURE__ */ jsx(DownArrow, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        try {
          if (!cycle || !cycle.order || isSectionLast()) return;
          yield moveSection("down");
          showMessage("Successfully moved the section down", "success");
        } catch (error) {
          showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
        }
      }),
      label: "Move down",
      disabled: isSectionLast()
    }
  ];
};
