"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { SETTINGS_PERSONAL_ROUTE } from "@/lib/routes";
import { CompanySettings } from "@/v2/feature/app-layout/features/v2/component/company-settings.component";
import { NavigationItem } from "@/v2/feature/app-layout/features/v2/component/navigation-item.component";
import { QuickAction } from "@/v2/feature/app-layout/features/v2/component/quick-action.component";
import { UserProfileIcon } from "@/v2/feature/app-layout/features/v2/component/user-header.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const MAIN_DRAWER_WIDTH = 60;
export const MainMenuRootStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(246, 243, 239, 0.7);
  backdrop-filter: blur(15px);
  width: 100%;
  flex: 0 0 ${MAIN_DRAWER_WIDTH}px;
  z-index: 3;
  max-width: ${MAIN_DRAWER_WIDTH}px;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const MainMenuSection = ({
  setShowSecondMenu,
  setActiveParent,
  activeParent,
  sideBarConfig,
  setActiveRole,
  user
}) => {
  const { polyglot } = usePolyglot();
  const renderContent = /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        background: themeColors.Background,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", paddingTop: spacing.p5 }, children: [
          /* @__PURE__ */ jsx(QuickAction, { user }),
          /* @__PURE__ */ jsx(
            NavigationItem,
            {
              navConfig: sideBarConfig,
              setActiveParent,
              activeParent,
              setShowSecondMenu,
              setActiveRole
            }
          )
        ] }, "nav-item"),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: spacing.p10,
              width: "100%"
            },
            children: [
              /* @__PURE__ */ jsx(CompanySettings, { headerName: polyglot.t("AppSidebar.settings"), redirectPath: SETTINGS_PERSONAL_ROUTE }),
              /* @__PURE__ */ jsx(UserProfileIcon, {})
            ]
          },
          "help-header"
        )
      ]
    }
  );
  return /* @__PURE__ */ jsx(MainMenuRootStyle, { children: /* @__PURE__ */ jsx(Box, { sx: { height: "100%", width: "100%" }, children: renderContent }) });
};
