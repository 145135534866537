"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { CalendarCompanyRouter } from "@v2/feature/calendar/calendar-company.router";
import { cloneDeep } from "lodash";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { useEventSource } from "@/hooks/event-source-hook";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TIME_COMPANY_CALENDAR_ROUTE, TIME_ME_CALENDAR_ROUTE, TIME_TEAM_CALENDAR_ROUTE } from "@/lib/routes";
import { CalendarMeRouter } from "@/v2/feature/calendar/calendar-me.router";
import { CalendarTeamRouter } from "@/v2/feature/calendar/calendar-team.router";
import { CalendarAPI } from "@/v2/feature/calendar/calendar.api";
export const CalendarRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const [showMessage] = useMessage();
  const CALENDAR_CHANNEL_NAME = `calendar-channel-${environmentPrefix}-user-${state.user.userId}`;
  const PUSH_EVENT_CALENDAR_SYNC_URL = `/apiv2/push-events/${CALENDAR_CHANNEL_NAME}-calendar-events-user-sync`;
  const [calendarAdvEventsTimeline, setCalendarAdvEventsTimeline] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const generalMessageHandling = useCallback(
    (data) => {
      setCalendarAdvEventsTimeline(cloneDeep(data.data));
      setLoading(false);
    },
    []
  );
  useEffect(() => {
    if (!loading || calendarAdvEventsTimeline) return;
    const delay = 5e3;
    const timeoutId = setTimeout(() => __async(void 0, null, function* () {
      if (loading) {
        try {
          const advEvents = yield CalendarAPI.loadAdvanceCalendarData();
          setCalendarAdvEventsTimeline(cloneDeep(advEvents));
        } catch (error) {
          showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
        } finally {
          setLoading(false);
        }
      }
    }), delay);
    return () => clearTimeout(timeoutId);
  }, [loading, calendarAdvEventsTimeline, showMessage]);
  const eventSourceErrorHandler = (error) => {
    console.error("Event source error: ", error);
    setLoading(false);
  };
  useEventSource(PUSH_EVENT_CALENDAR_SYNC_URL, {
    onMessage: generalMessageHandling,
    onError: eventSourceErrorHandler
  });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence"], path: [TIME_COMPANY_CALENDAR_ROUTE], context: scopesContext, children: /* @__PURE__ */ jsx(
      CalendarCompanyRouter,
      {
        calendarAdvEventsTimeline,
        setCalendarAdvEventsTimeline,
        setLoading,
        loading
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence"], path: [TIME_TEAM_CALENDAR_ROUTE], context: scopesContext, children: /* @__PURE__ */ jsx(
      CalendarTeamRouter,
      {
        calendarAdvEventsTimeline,
        setCalendarAdvEventsTimeline,
        setLoading,
        loading
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence"], path: [TIME_ME_CALENDAR_ROUTE], context: scopesContext, children: /* @__PURE__ */ jsx(
      CalendarMeRouter,
      {
        calendarAdvEventsTimeline,
        setCalendarAdvEventsTimeline,
        setLoading,
        loading
      }
    ) })
  ] });
};
