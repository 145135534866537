"use strict";
export const emojiOptions = [
  {
    label: "\u2708\uFE0F",
    value: ":airplane:"
  },
  {
    label: "\u{1F44B}",
    value: ":wave:"
  },
  {
    label: "\u{1F3C6}",
    value: ":trophy:"
  },
  {
    label: "\u{1F3AF}",
    value: ":dart:"
  },
  {
    label: "\u{1F680}",
    value: ":rocket:"
  },
  {
    label: "\u2757",
    value: ":exclamation:"
  }
];
export const everyoneOption = { label: "Everyone", value: "public-org" };
