"use strict";
export const AppsWithLogins = ["github"];
export const REFRESH_DELAY_APP_USER_LIST = 16e3;
export var APP_ACTION_DRAWER_MODES = /* @__PURE__ */ ((APP_ACTION_DRAWER_MODES2) => {
  APP_ACTION_DRAWER_MODES2["assign"] = "assign";
  APP_ACTION_DRAWER_MODES2["create"] = "create";
  APP_ACTION_DRAWER_MODES2["suspend"] = "suspend";
  APP_ACTION_DRAWER_MODES2["delete"] = "delete";
  APP_ACTION_DRAWER_MODES2["googleWorkspaceTransferThenDelete"] = "googleWorkspaceTransferThenDelete";
  APP_ACTION_DRAWER_MODES2["createEmployment"] = "createEmployment";
  return APP_ACTION_DRAWER_MODES2;
})(APP_ACTION_DRAWER_MODES || {});
export var APP_GROUP_MANAGEMENT_DRAWER_MODES = /* @__PURE__ */ ((APP_GROUP_MANAGEMENT_DRAWER_MODES2) => {
  APP_GROUP_MANAGEMENT_DRAWER_MODES2["edit"] = "edit";
  APP_GROUP_MANAGEMENT_DRAWER_MODES2["add"] = "add";
  APP_GROUP_MANAGEMENT_DRAWER_MODES2["addExistingToGroup"] = "addExistingToGroup";
  return APP_GROUP_MANAGEMENT_DRAWER_MODES2;
})(APP_GROUP_MANAGEMENT_DRAWER_MODES || {});
export var AppActionWhenValueEnum = /* @__PURE__ */ ((AppActionWhenValueEnum2) => {
  AppActionWhenValueEnum2["now"] = "now";
  AppActionWhenValueEnum2["later"] = "later";
  return AppActionWhenValueEnum2;
})(AppActionWhenValueEnum || {});
export var AppActionWhenLabelEnum = /* @__PURE__ */ ((AppActionWhenLabelEnum2) => {
  AppActionWhenLabelEnum2["now"] = "Now";
  AppActionWhenLabelEnum2["later"] = "Later";
  return AppActionWhenLabelEnum2;
})(AppActionWhenLabelEnum || {});
export const AppActionWhenTabFilter = [
  { name: "Now" /* now */, value: "now" /* now */ },
  { name: "Later" /* later */, value: "later" /* later */ }
];
export const APP_ACTION_WHEN_OPTIONS = [
  { value: "now", label: "Now" },
  { value: "later", label: "Later" }
];
export const appStubToName = {
  "azure-ad": "Azure AD",
  docusign: "DocuSign",
  deel: "Deel",
  github: "GitHub",
  "google-workspace": "Workspace",
  hubspot: "HubSpot",
  intercom: "Intercom",
  jumpcloud: "JumpCloud",
  jira: "Jira",
  microsoft365: "Microsoft 365",
  lastpass: "LastPass",
  lever: "Lever",
  monday: "Monday",
  notion: "Notion",
  pipedrive: "Pipedrive",
  quickbooks: "QuickBooks",
  remote: "Remote",
  slack: "Slack",
  workable: "Workable",
  teamtailor: "Teamtailor",
  greenhouse: "Greenhouse",
  xero: "Xero",
  zoom: "Zoom"
  // atlassian: 'Atlassian',
  // aws: 'AWS',
  // zelt: '',
  // 'sso-google-workspace': 'Google SSO',
  // 'sso-okta': 'Okta SSO',
  // 'sso-azure-ad': 'Azure AD SSO',
};
export const APPS_NOT_REQUIRING_REFRESH_POST_ACTION = ["slack"];
export const APPS_WITH_CC_INVITE = ["microsoft365", "google-workspace"];
export const APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION = /* @__PURE__ */ new Set(["microsoft365", "google-workspace"]);
export const APPS_WITH_DOMAIN_NAME_CALENDAR_CONFIGURATION = ["google-workspace"];
export var AppIntegrationUserStatusEnum = /* @__PURE__ */ ((AppIntegrationUserStatusEnum2) => {
  AppIntegrationUserStatusEnum2["NoAccess"] = "No access";
  AppIntegrationUserStatusEnum2["Suspended"] = "Suspended";
  AppIntegrationUserStatusEnum2["Hired"] = "Hired";
  AppIntegrationUserStatusEnum2["Active"] = "Active";
  AppIntegrationUserStatusEnum2["Invited"] = "Invited";
  AppIntegrationUserStatusEnum2["Onboarded"] = "Onboarded";
  AppIntegrationUserStatusEnum2["Deleted"] = "Deleted";
  AppIntegrationUserStatusEnum2["Unknown"] = "Unknown";
  AppIntegrationUserStatusEnum2["Imported"] = "Imported";
  return AppIntegrationUserStatusEnum2;
})(AppIntegrationUserStatusEnum || {});
