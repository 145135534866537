"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export function updateNotificationSettings(notificationName, notificationSettings) {
  return __async(this, null, function* () {
    yield axios.put(`/apiv2/users/notifications`, { notificationName, notificationSettings });
  });
}
export function toggleNotificationStatus(notificationName, newStatus) {
  return __async(this, null, function* () {
    yield axios.put(`/apiv2/users/notifications/toggle-status`, { notificationName, newStatus });
  });
}
export class UserNotificationEndpoints {
  static getNotificationSettings() {
    return {
      url: "/apiv2/users/notifications"
    };
  }
}
