"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { CustomProfileFormEndpoints } from "@v2/feature/custom-fields/custom-profile-fields.api";
import { PeopleChangeCreateReportResults } from "@v2/feature/reports/features/create-report/people/people-change-create-report-results.component";
import { PeopleCurrentCreateReportResults } from "@v2/feature/reports/features/create-report/people/people-current-create-report-results.component";
import { ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { DataDomain, ReportTypePeopleSelection } from "@v2/feature/reports/reports.interface";
import { includeCustomFieldsColumns } from "@v2/feature/reports/util/people-report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const PeopleReportResults = ({
  formData,
  setFormData
}) => {
  const { data: fields } = useApiClient(CustomProfileFormEndpoints.listAllFields(), { suspense: false });
  const { data: additionalColumns } = useApiClient(ReportsEndpoints.getPeopleReportsAdditionalColumnsOptions(), {
    suspense: false
  });
  const customFields = useMemo(() => {
    var _a;
    if (formData.domain === DataDomain.People) {
      const response = includeCustomFieldsColumns(formData.entities, (_a = fields == null ? void 0 : fields.dataFieldsCustom) != null ? _a : []);
      return response;
    }
    return {};
  }, [fields == null ? void 0 : fields.dataFieldsCustom, formData.domain, formData.entities]);
  if (!customFields || !additionalColumns) return null;
  return formData.type === ReportTypePeopleSelection.ChangeReport ? /* @__PURE__ */ jsx(
    PeopleChangeCreateReportResults,
    {
      formData,
      setFormData,
      customFields,
      additionalColumns
    }
  ) : /* @__PURE__ */ jsx(
    PeopleCurrentCreateReportResults,
    {
      formData,
      setFormData,
      customFields,
      additionalColumns
    }
  );
};
