"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { PayrunReopenConfirmationDrawer } from "@v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-reopen-confirmation-drawer.component";
import { CompanyPayrollType } from "@v2/feature/payroll/payroll.interface";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as ReOpenIcon } from "@/images/fields/Settings.svg";
import { ReactComponent as DeleteIcon } from "@/images/side-bar-icons/Delete.svg";
import { nestErrorMessage } from "@/lib/errors";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { GlobalPayrollAPI } from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { PayrollLocalApi } from "@/v2/feature/payroll/payroll-local.api";
const iconSize = { width: 14, height: 14 };
export const PayrunHeaderMenu = ({
  canDeletePayrun,
  canReopenPayrun,
  onReopenPayrun,
  onDeletePayrun,
  payrollType,
  sx
}) => {
  const [confirmReopen, setConfirmReopen] = useState(false);
  const params = useParams();
  const payrollId = Number(params.payrollId);
  const taxYear = params.taxYear;
  const payPeriod = params.payPeriod;
  const period = Number(params.periodNumber);
  const [showMessage] = useMessage();
  const deletePayrun = useCallback(() => __async(void 0, null, function* () {
    try {
      yield {
        [CompanyPayrollType.UKPayroll]: () => PayrollLocalApi.deletePayRun(payrollId, taxYear, payPeriod, period),
        [CompanyPayrollType.GlobalPayroll]: () => GlobalPayrollAPI.deletePayrun(payrollId, taxYear, payPeriod, period)
      }[payrollType]();
      setConfirmReopen(false);
      if (onDeletePayrun) onDeletePayrun();
    } catch (error) {
      showMessage(`Could not delete payrun. ${nestErrorMessage(error)}`, "error");
    }
  }), [onDeletePayrun, payPeriod, payrollId, payrollType, period, showMessage, taxYear]);
  const reopenPayrun = useCallback(() => __async(void 0, null, function* () {
    try {
      yield {
        [CompanyPayrollType.UKPayroll]: () => PayrollLocalApi.reopenPayRun(payrollId, taxYear, payPeriod, period),
        [CompanyPayrollType.GlobalPayroll]: () => GlobalPayrollAPI.reopenPayrun(payrollId, taxYear, payPeriod, period)
      }[payrollType]();
      setConfirmReopen(false);
      if (onReopenPayrun) yield onReopenPayrun();
    } catch (error) {
      showMessage(`Could not reopen payrun. ${nestErrorMessage(error)}`, "error");
    }
  }), [onReopenPayrun, payPeriod, payrollId, payrollType, period, showMessage, taxYear]);
  const payrunMenuOptions = useMemo(
    () => [
      {
        icon: /* @__PURE__ */ jsx(ReOpenIcon, __spreadValues({}, iconSize)),
        handler: () => {
          setConfirmReopen(true);
        },
        label: "Reopen payrun",
        disabled: !canReopenPayrun
      },
      {
        icon: /* @__PURE__ */ jsx(DeleteIcon, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          yield deletePayrun();
        }),
        label: "Delete payrun",
        disabled: !canDeletePayrun
      }
    ],
    [canDeletePayrun, canReopenPayrun, deletePayrun]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: payrunMenuOptions,
        actionButtonDetails: {
          type: "iconButton",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "actions",
          icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
        },
        sx
      }
    ),
    /* @__PURE__ */ jsx(
      PayrunReopenConfirmationDrawer,
      {
        payrollType,
        isOpen: confirmReopen,
        setIsOpen: setConfirmReopen,
        onConfirm: reopenPayrun
      }
    )
  ] });
};
