"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const WithdrawDrawer = ({ isOpen, setIsOpen, balance, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(WithdrawDrawerContent, { balance, refresh })
    }
  ) });
};
const WithdrawDrawerContent = ({ balance, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      amount: 0
    },
    validationSchema: yup.object({
      amount: yup.number().min(1, "The minimum amount you can withdraw is \xA31.").max(balance, "You cannot withdraw more money than your current balance.").typeError("Please enter a valid number for the amount you wish to withdraw.").required("Amount is required.")
    }),
    onSubmit: (values) => confirmWithdraw(values.amount)
  });
  const confirmWithdraw = (amount) => __async(void 0, null, function* () {
    let isWithdrawalComplete = false;
    try {
      setIsLoading(true);
      yield ContractorInvoiceAPI.withdrawFromAccount(Number(amount));
      isWithdrawalComplete = true;
      showMessage("Withdrawal completed successfully.", "success");
      yield refresh();
    } catch (error) {
      if (isWithdrawalComplete) {
        showMessage(`Could not refresh your balance. ${nestErrorMessage(error)}`, "error");
        return;
      }
      showMessage(`Could not complete withdrawal process. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Withdraw" }),
    isLoading ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "Transaction processing. Please give us a moment." }) }) : /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", gap: spacing.gap10, alignItems: "center" }, fieldSx), children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "amount",
            label: "Amount",
            value: formik.values.amount,
            onChange: formik.handleChange,
            clearText: () => formik.setFieldValue("amount", ""),
            error: formik.touched.amount && Boolean(formik.errors.amount),
            helperText: formik.touched.amount && formik.errors.amount,
            fullWidth: true
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap5, width: "80px" }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "Currency" }),
          /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: "\xA3 (GBP)" })
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.gap5 }, fieldSx), children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "Withdraw to" }),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.title4, children: [
          "Revolut ",
          /* @__PURE__ */ jsx("span", { style: { marginLeft: "25px" }, children: "************2312" })
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", mb: spacing.mb30 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Provider fee" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "\xA30.00" })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Confirm", loading: isLoading, colorVariant: "primary", sizeVariant: "large", fullWidth: true }) })
    ] })
  ] }) });
};
