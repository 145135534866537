"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  CustomDEPTokenActions,
  CustomDEPTokenSteps
} from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DownloadServerTokenFromAbmComponent = ({
  onNext,
  setFormData,
  formData
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Download server token from ABM" }),
        formData.action === CustomDEPTokenActions.Create_token ? /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m20 }, children: /* @__PURE__ */ jsxs("ol", { children: [
          /* @__PURE__ */ jsx("li", { children: "Open your Apple Business Manager" }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsx("li", { children: "Click on your account name in the bottom left corner, choose Preferences" }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsx("li", { children: "Under Your MDM Servers, click on a plus. Give your new MDM Server a recognizable name, like Zelt MDM" }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsxs("li", { children: [
            "Upload ",
            /* @__PURE__ */ jsx("u", { children: "Zelt-MDM-Token-Public-Key.pem" }),
            " in MDM Server settings"
          ] }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsx("li", { children: "Click Save" }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsx("li", { children: "Navigate to the action at the top of the screen to Download Token" })
        ] }) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m20 }, children: /* @__PURE__ */ jsxs("ol", { children: [
          /* @__PURE__ */ jsx("li", { children: "Open your Apple Business Manager" }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsx("li", { children: "Click on your account name in the bottom left corner, choose Preferences" }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsxs("li", { children: [
            "Under Your MDM Servers, select",
            " ",
            /* @__PURE__ */ jsx("u", { children: formData.depAccountDetails ? formData.depAccountDetails.server_name : "NA" })
          ] }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsxs("li", { children: [
            "Select ",
            /* @__PURE__ */ jsx("u", { children: "Download Token" }),
            " action"
          ] }),
          /* @__PURE__ */ jsx("br", {}),
          /* @__PURE__ */ jsxs("li", { children: [
            "Download token by clicking ",
            /* @__PURE__ */ jsx("u", { children: "Download Server Token" }),
            " in the pop-up"
          ] })
        ] }) }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "large",
            colorVariant: "primary",
            style: { marginTop: spacing.m25 },
            onClick: () => {
              formData.type = CustomDEPTokenSteps.Download_key;
              setFormData(formData);
              onNext();
            },
            fullWidth: true,
            children: polyglot.t("General.next")
          }
        )
      ] })
    }
  );
};
