"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo } from "react";
import { Box, Slide, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopNavigationMenu } from "@/v2/feature/app-layout/features/v2/component/top-navigation-menu.component";
import {
  decomposePath,
  findSubItemByStub,
  getSecondMenuItems,
  navigateToFirstChildOrParent
} from "@/v2/feature/app-layout/features/v2/menu/menu.util";
import "@/v2/feature/app-layout/features/v2/second-menu/second-menu.scss";
import { renderSingleItem, renderSubMenu } from "@/v2/feature/app-layout/features/v2/second-menu/second-menu.util";
import {
  getGrowthConfig,
  getMoneyConfig,
  getPeopleConfig,
  getReportsConfig,
  getTechConfig,
  getTimeConfig
} from "@/v2/feature/app-layout/features/v2/sidebar-config.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const SECOND_DRAWER_WIDTH = 199;
const SecondMenuDesktopRootStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  flex: `0 0 ${SECOND_DRAWER_WIDTH}px`,
  maxWidth: `${SECOND_DRAWER_WIDTH}px`,
  borderRight: borders.light
});
const SecondMenuTabletInnerStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  width: "100%",
  background: themeColors.white,
  flex: `0 0 ${SECOND_DRAWER_WIDTH}px`,
  maxWidth: `${SECOND_DRAWER_WIDTH}px`,
  borderRight: borders.light
});
const SecondMenuTabletStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  width: "100%",
  background: "#e2dfdc78",
  zIndex: 4,
  position: "absolute",
  left: 60
});
export const SecondMenuSection = React.memo(
  ({
    activeParent,
    setActiveRole,
    activeRole,
    setActiveSubMenuParent,
    activeSubMenuParent,
    roles,
    setShowSecondMenu
  }) => {
    var _a;
    const { polyglot } = usePolyglot();
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isTabletScreen = useMediaQuery(
      `(min-width:${theme.breakpoints.values.sm + 1}px) and (max-width:${theme.breakpoints.values.md - 1}px)`
    );
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const routerHistory = useHistory();
    const routerLocation = useLocation();
    const [state] = useContext(GlobalContext);
    const { user } = state;
    const { pathname } = useLocation();
    const items = useMemo(() => {
      if (!activeRole || !user) return void 0;
      return activeParent === "people" ? getPeopleConfig(user, activeRole, polyglot) : activeParent === "time" ? getTimeConfig(user, activeRole, polyglot) : activeParent === "money" ? getMoneyConfig(user, activeRole, polyglot) : activeParent === "report" ? getReportsConfig(user, activeRole, polyglot, !!state.showHiddenFeatures) : activeParent === "growth" ? getGrowthConfig(user, activeRole, polyglot) : activeParent === "tech" ? getTechConfig(user, activeRole, polyglot) : void 0;
    }, [polyglot, user, activeRole, activeParent, state.showHiddenFeatures]);
    const handleParentClick = useCallback(
      (list) => {
        var _a2;
        setActiveSubMenuParent(list.stub);
        if (!list.hasChildren && list.path) {
          routerHistory.push(list.path);
        } else {
          const firstChild = (_a2 = list.subMenu) == null ? void 0 : _a2.find((s) => !s.isHidden && s.path);
          if (firstChild && firstChild.path) {
            routerHistory.push(firstChild.path);
          }
        }
      },
      [routerHistory, setActiveSubMenuParent]
    );
    const handleSubmenuClick = useCallback(
      (sm) => {
        if (!sm.hasChildren && sm.path) {
          routerHistory.push(sm.path);
        }
      },
      [routerHistory]
    );
    const renderContent = /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          background: themeColors.white,
          display: "flex",
          height: "100%",
          flexDirection: "column",
          gap: spacing.g20,
          marginTop: spacing.s2
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { paddingRight: spacing.p10, paddingLeft: spacing.p10 }, children: items == null ? void 0 : items.title }),
            (isTabletScreen || isMobileScreen) && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => setShowSecondMenu(false), children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey })) })
          ] }),
          /* @__PURE__ */ jsx(
            TopNavigationMenu,
            {
              roles,
              setActiveRole: (r) => {
                const decomposed = decomposePath(routerLocation.pathname);
                const { domain, subDomain, subMenu } = decomposed;
                setActiveRole(r);
                const routedSecondMenuItems = getSecondMenuItems(r, domain, user, polyglot);
                const parent = findSubItemByStub(routedSecondMenuItems == null ? void 0 : routedSecondMenuItems.subItems, subDomain);
                navigateToFirstChildOrParent(parent, subMenu, routedSecondMenuItems, routerHistory);
              },
              activeRole
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: (_a = items == null ? void 0 : items.subItems) == null ? void 0 : _a.map(
            (list, i) => list.hasChildren ? renderSubMenu(list, handleParentClick, pathname, activeSubMenuParent, handleSubmenuClick, i) : renderSingleItem(list, handleParentClick, pathname, i)
          ) })
        ]
      }
    );
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      isDesktopScreen && /* @__PURE__ */ jsx(SecondMenuDesktopRootStyle, { children: /* @__PURE__ */ jsx(
        Slide,
        {
          direction: user.language !== "ar" ? "right" : "left",
          in: true,
          mountOnEnter: true,
          unmountOnExit: true,
          timeout: { enter: 300, exit: 300 },
          children: /* @__PURE__ */ jsx(Box, { sx: { height: "100%", px: spacing.p10, width: "100%" }, children: renderContent })
        }
      ) }),
      (isTabletScreen || isMobileScreen) && /* @__PURE__ */ jsx(SecondMenuTabletStyle, { children: /* @__PURE__ */ jsx(SecondMenuTabletInnerStyle, { children: /* @__PURE__ */ jsx(
        Slide,
        {
          direction: user.language !== "ar" ? "right" : "left",
          in: true,
          mountOnEnter: true,
          unmountOnExit: true,
          timeout: { enter: 300, exit: 300 },
          children: /* @__PURE__ */ jsx(Box, { sx: { height: "100%", px: spacing.p10, width: "100%" }, children: renderContent })
        }
      ) }) })
    ] });
  }
);
