"use strict";
import { AccrualUnit } from "@v2/feature/absence/absence.interface";
export function getAccrualFrequency(absencePolicy, polyglot) {
  if (!absencePolicy.accrualUnit || !absencePolicy.accrualUnitsNo) return "";
  if (absencePolicy.accrualUnit === AccrualUnit.Day)
    return polyglot.t("AbsenceModule.AccrualFrequency.everyXDays", { smart_count: absencePolicy.accrualUnitsNo });
  if (absencePolicy.accrualUnit === AccrualUnit.Week)
    return polyglot.t("AbsenceModule.AccrualFrequency.everyXWeeks", { smart_count: absencePolicy.accrualUnitsNo });
  if (absencePolicy.accrualUnit === AccrualUnit.Month)
    return polyglot.t("AbsenceModule.AccrualFrequency.everyXMonths", { smart_count: absencePolicy.accrualUnitsNo });
  return "";
}
