"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl, RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { INVOICES_COMPANY_ROUTE, INVOICES_ME_ROUTE, INVOICES_TEAM_ROUTE } from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { InvoiceCompanyRouter } from "@/v2/feature/invoice/invoice-company.router";
import { InvoiceMeRouter } from "@/v2/feature/invoice/invoice-me.router";
import { InvoiceTeamRouter } from "@/v2/feature/invoice/invoice-team.router";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const InvoiceRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(globalState.user.userId), {
    suspense: false
  });
  const isManager = useMemo(() => {
    var _a, _b, _c;
    const nonTerminatedDirectReports = (_c = (_b = (_a = userSummaryWithReports == null ? void 0 : userSummaryWithReports.directReports) == null ? void 0 : _a.filter(
      (d) => d.userEvent && d.userEvent.status && ["Employed", "Hired"].includes(d.userEvent.status)
    )) == null ? void 0 : _b.length) != null ? _c : 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["invoices:all"], path: INVOICES_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(InvoiceCompanyRouter, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["invoices:manager"], path: INVOICES_TEAM_ROUTE, children: /* @__PURE__ */ jsx(InvoiceTeamRouter, { isManager }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["invoices"], context: scopesContext, path: INVOICES_ME_ROUTE, children: /* @__PURE__ */ jsx(InvoiceMeRouter, {}) }),
    canAccessScopes(globalState.user, ["invoices:all"]) && /* @__PURE__ */ jsx(Redirect, { to: INVOICES_COMPANY_ROUTE })
  ] });
};
