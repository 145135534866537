"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SCParticipantDeleteModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-delete-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { iconSize } from "@/v2/styles/menu.styles";
export const SCParticipantsSharedTable = ({
  surveyCycle,
  surveyParticipants,
  surveyParticipantsLoading,
  refresh,
  isEditable
}) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [isRevieweeDeleteOpen, setIsRevieweeDeleteOpen] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const deleteParticipants = () => __async(void 0, null, function* () {
    var _a;
    try {
      if (surveyCycle && [CycleState.Draft, CycleState.Scheduled].includes(surveyCycle.state)) {
        const updatedReviewees = (_a = surveyCycle.participantIds) == null ? void 0 : _a.filter(
          (participant) => !selectionModel.includes(participant)
        );
        yield SurveyCycleAPI.updateSurveyCycle(__spreadProps(__spreadValues({}, surveyCycle), {
          participantIds: updatedReviewees
        }));
        setSelectionModel([]);
        showMessage("Successfully deleted the participants", "success");
        yield refresh();
      }
      if ((surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Ongoing) {
        setIsRevieweeDeleteOpen(true);
      }
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  });
  const tableColumns = useMemo(
    () => [
      ...isEditable ? [
        {
          id: "select",
          enableSorting: false,
          minSize: 1,
          maxSize: 1,
          header: () => {
            const displayedSelfId = new Set(surveyParticipants == null ? void 0 : surveyParticipants.map((eachId) => eachId));
            const allSelected = selectionModel.length > 0 && selectionModel.length === displayedSelfId.size && selectionModel.every((id) => displayedSelfId.has(id));
            return /* @__PURE__ */ jsx(Box, { sx: { display: "initial" }, onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: allSelected,
                value: "allSelceted",
                onChange: (_, checked) => {
                  setSelectionModel(checked ? [...displayedSelfId] : []);
                }
              }
            ) });
          },
          cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: original.toString(),
              checked: selectionModel.includes(original),
              value: original.toString(),
              onChange: () => {
                let finalArray = [];
                if (selectionModel == null ? void 0 : selectionModel.includes(original)) {
                  finalArray = selectionModel.filter((sm) => sm !== original);
                } else finalArray = [...selectionModel, original];
                setSelectionModel(finalArray);
              }
            }
          ) })
        }
      ] : [],
      {
        header: () => "Participant",
        accessorFn: (row) => row,
        id: "participant",
        enableSorting: false,
        cell: ({ row: { original } }) => original ? /* @__PURE__ */ jsx(UserCell, { userId: original }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 90,
        maxSize: 90
      },
      {
        header: () => "Email",
        accessorFn: (row) => row,
        id: "email",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const cachedUser = original ? getCachedUserById(original) : void 0;
          return original && cachedUser && (cachedUser == null ? void 0 : cachedUser.emailAddress) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: cachedUser == null ? void 0 : cachedUser.emailAddress }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        minSize: 90,
        maxSize: 90
      },
      {
        header: () => "Job title",
        accessorFn: (row) => row,
        id: "jobTitle",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d;
          const cachedUser = original ? getCachedUserById(original) : void 0;
          return original && cachedUser && ((_b = (_a = cachedUser == null ? void 0 : cachedUser.role) == null ? void 0 : _a.jobPosition) == null ? void 0 : _b.title) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (_d = (_c = cachedUser == null ? void 0 : cachedUser.role) == null ? void 0 : _c.jobPosition) == null ? void 0 : _d.title }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        minSize: 90,
        maxSize: 90
      },
      {
        header: () => "Department",
        accessorFn: (row) => row,
        id: "department",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d;
          const cachedUser = original ? getCachedUserById(original) : void 0;
          return original && cachedUser && ((_b = (_a = cachedUser == null ? void 0 : cachedUser.role) == null ? void 0 : _a.department) == null ? void 0 : _b.name) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (_d = (_c = cachedUser == null ? void 0 : cachedUser.role) == null ? void 0 : _c.department) == null ? void 0 : _d.name }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        minSize: 90,
        maxSize: 90
      }
    ],
    [surveyParticipants, selectionModel, setSelectionModel, getCachedUserById, isEditable]
  );
  if (!surveyCycle) return /* @__PURE__ */ jsx(Fragment, { children: "Survey cycle not found" });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            handler: () => __async(void 0, null, function* () {
              return deleteParticipants();
            }),
            label: "Remove participants",
            disabled: false
          }
        ],
        sx: {
          visibility: isEditable && selectionModel.length > 0 ? "visible" : "hidden",
          opacity: selectionModel.length > 0 ? 1 : 0,
          transition: "opacity 0.3s ease, visibility 0.3s ease"
        },
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "Actions",
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    ) }),
    /* @__PURE__ */ jsx(BasicTable, { rowData: surveyParticipants != null ? surveyParticipants : [], columnData: tableColumns, loading: surveyParticipantsLoading }),
    /* @__PURE__ */ jsx(
      SCParticipantDeleteModal,
      {
        surveyCycle,
        toDeleteParticipantIds: selectionModel,
        isOpen: isRevieweeDeleteOpen,
        setIsOpen: setIsRevieweeDeleteOpen,
        onClose: () => {
          setIsRevieweeDeleteOpen(false);
          setSelectionModel([]);
        },
        refresh
      }
    )
  ] });
};
