"use strict";
import { Mark } from "@tiptap/core";
export const LineHeightMark = Mark.create({
  name: "lineHeight",
  addAttributes() {
    return {
      lineHeight: {
        default: "1",
        parseHTML: (element) => element.style.lineHeight,
        renderHTML: (attributes) => {
          if (!attributes.lineHeight) {
            return {};
          }
          return { style: `line-height: ${attributes.lineHeight}` };
        }
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "span[style]",
        getAttrs: (element) => element.style.lineHeight ? {} : false
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["span", HTMLAttributes, 0];
  },
  addCommands() {
    return {
      setLineHeight: (lineHeight) => ({ commands }) => {
        return commands.setMark(this.name, { lineHeight });
      },
      unsetLineHeight: () => ({ commands }) => {
        return commands.unsetMark(this.name);
      }
    };
  }
});
