"use strict";
export var RequiredEnrolmentValueEnum = /* @__PURE__ */ ((RequiredEnrolmentValueEnum2) => {
  RequiredEnrolmentValueEnum2["AllDevices"] = "allDevices";
  RequiredEnrolmentValueEnum2["OnlyCompany"] = "onlyCompany";
  return RequiredEnrolmentValueEnum2;
})(RequiredEnrolmentValueEnum || {});
export var RequiredEnrolmentLabelEnum = /* @__PURE__ */ ((RequiredEnrolmentLabelEnum2) => {
  RequiredEnrolmentLabelEnum2["allDevices"] = "All devices";
  RequiredEnrolmentLabelEnum2["onlyCompany"] = "Only company";
  return RequiredEnrolmentLabelEnum2;
})(RequiredEnrolmentLabelEnum || {});
