"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Box, Divider } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { lastDayOfMonth } from "date-fns";
import { BillingSubscriptionAPI } from "@/api-client/billing-subscription.api";
import { BillingAPI } from "@/api-client/billing.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  getBillToEndOfMonth,
  getNoOfActiveDaysForBilling
} from "@/v2/feature/billing/components/update-company-subscription.util";
import { ViewerItem } from "@/v2/feature/payments/components/payment-details-drawer.component";
import { formatMoney } from "@/v2/feature/payments/utils/money.util";
import {
  BillingCurrency,
  SeatsMethod,
  SubscriptionType,
  VatSetting
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { getVatTaxAmount } from "@/v2/util/tax.util";
const CostDisplay = styled.span`
  display: inline-block;
  width: 80px; // Adjust this value as needed
  text-align: right;
`;
export const V2UpdateCompanySubscriptionDrawer = ({
  isOpen,
  setIsDrawerOpen,
  companySubscriptionDetails,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [selectedModulesForUpgrade, setSelectedModulesForUpgrade] = useState([]);
  const { companySubscription } = companySubscriptionDetails;
  const [showMessage] = useMessage();
  const [isLoading, setLoading] = useState(false);
  const [upgradeBlockedForAnnualCustomer, setUpgradeBlockedForAnnualCustomer] = useState(false);
  const { data: upgradeModules, isLoading: loadingUpgradeModules } = useApiClient(
    CompanyEndpoints.getAvailableModulesForUpgrade(),
    {
      suspense: false
    }
  );
  const companyOnAnnualBilling = companySubscription.type === SubscriptionType.ANNUALLY;
  const availableUpgrades = useMemo(() => {
    var _a2;
    return (_a2 = upgradeModules == null ? void 0 : upgradeModules.available) != null ? _a2 : [];
  }, [upgradeModules == null ? void 0 : upgradeModules.available]);
  const existingModules = useMemo(() => {
    var _a2;
    return (_a2 = upgradeModules == null ? void 0 : upgradeModules.existing) != null ? _a2 : [];
  }, [upgradeModules == null ? void 0 : upgradeModules.existing]);
  useEffect(() => {
    if (availableUpgrades) {
      const coreModule = availableUpgrades.find((module) => module.name === "Core");
      if (coreModule) {
        setSelectedModulesForUpgrade((prevSelected) => [...prevSelected, coreModule.id]);
      }
    }
  }, [availableUpgrades]);
  const daysChargedForThisMonth = useMemo(() => {
    return getNoOfActiveDaysForBilling();
  }, []);
  const daysInMonth = useMemo(() => {
    return lastDayOfMonth(/* @__PURE__ */ new Date()).getDate();
  }, []);
  const proratedPricePerSeat = useMemo(() => {
    if (!availableUpgrades) return 0;
    return selectedModulesForUpgrade.reduce((acc, eachModuleId) => {
      var _a2;
      const matchingPlan = availableUpgrades.find((eachPlan) => eachPlan.id === eachModuleId);
      const currency = (_a2 = companySubscription == null ? void 0 : companySubscription.currency) != null ? _a2 : BillingCurrency.GBP;
      const costForPlan = matchingPlan && matchingPlan.cost ? matchingPlan.cost[currency] : 0;
      const costForPlanToEndofMonth = getBillToEndOfMonth(costForPlan, false);
      return acc + costForPlanToEndofMonth;
    }, 0);
  }, [availableUpgrades, companySubscription == null ? void 0 : companySubscription.currency, selectedModulesForUpgrade]);
  const listPricePricePerSeat = useMemo(() => {
    if (!availableUpgrades) return 0;
    return selectedModulesForUpgrade.reduce((acc, eachModuleId) => {
      var _a2;
      const matchingPlan = availableUpgrades.find((eachPlan) => eachPlan.id === eachModuleId);
      const currency = (_a2 = companySubscription == null ? void 0 : companySubscription.currency) != null ? _a2 : BillingCurrency.GBP;
      const costForPlan = matchingPlan && matchingPlan.cost ? matchingPlan.cost[currency] : 0;
      return acc + costForPlan;
    }, 0);
  }, [availableUpgrades, companySubscription == null ? void 0 : companySubscription.currency, selectedModulesForUpgrade]);
  const listPriceTotalCostExclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return listPricePricePerSeat * (companySubscription == null ? void 0 : companySubscription.seatMultiplier);
  }, [companySubscription, listPricePricePerSeat]);
  const listPriceTotalVat = useMemo(() => {
    var _a2;
    if (!companySubscription) return 0;
    const vatRate = getVatTaxAmount(companySubscription.currency, (_a2 = companySubscription.vatSetting) != null ? _a2 : VatSetting.NO_VAT);
    return listPriceTotalCostExclusiveOfVat * vatRate;
  }, [companySubscription, listPriceTotalCostExclusiveOfVat]);
  const listPriceTotalCostInclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return (listPriceTotalCostExclusiveOfVat + listPriceTotalVat).toFixed(2);
  }, [companySubscription, listPriceTotalCostExclusiveOfVat, listPriceTotalVat]);
  const proratedTotalCostExclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return proratedPricePerSeat * (companySubscription == null ? void 0 : companySubscription.seatMultiplier);
  }, [companySubscription, proratedPricePerSeat]);
  const proratedTotalVat = useMemo(() => {
    var _a2;
    if (!companySubscription) return 0;
    const vatRate = getVatTaxAmount(companySubscription.currency, (_a2 = companySubscription.vatSetting) != null ? _a2 : VatSetting.NO_VAT);
    return proratedTotalCostExclusiveOfVat * vatRate;
  }, [companySubscription, proratedTotalCostExclusiveOfVat]);
  const proratedTotalCostInclusiveOfVat = useMemo(() => {
    if (!companySubscription) return 0;
    return (proratedTotalCostExclusiveOfVat + proratedTotalVat).toFixed(2);
  }, [companySubscription, proratedTotalCostExclusiveOfVat, proratedTotalVat]);
  const additionalVatDetails = useMemo(() => {
    if ((companySubscription == null ? void 0 : companySubscription.vatSetting) === VatSetting.NO_VAT || (companySubscription == null ? void 0 : companySubscription.currency) === BillingCurrency.USD)
      return void 0;
    return ` (${companySubscription == null ? void 0 : companySubscription.vatSetting})`;
  }, [companySubscription]);
  const upgradePossible = availableUpgrades && (availableUpgrades == null ? void 0 : availableUpgrades.length) > 0;
  const handleUpgrade = () => __async(void 0, null, function* () {
    var _a2;
    try {
      if (companyOnAnnualBilling) {
        setUpgradeBlockedForAnnualCustomer(true);
        const manualUpgradeBody = {
          companyId: companySubscription.companyId,
          companyName: (_a2 = state.user.company.name) != null ? _a2 : "",
          upgradingUserId: state.user.userId,
          upgradingUser: state.user,
          upgradeTime: /* @__PURE__ */ new Date()
        };
        const result = yield BillingAPI.manualUpgradeIntercept(manualUpgradeBody);
        if (result) {
          showMessage("Someone from the billing team will reach out to you soon", "success");
        } else {
          showMessage("Failed to notify the billing team - please try again", "error");
        }
        return;
      }
      setLoading(true);
      let checkoutSession;
      if (selectedModulesForUpgrade.length > 0) {
        const selectedModules = [];
        for (const eachPlanId of selectedModulesForUpgrade) {
          selectedModules.push({ productPlanId: eachPlanId });
        }
        checkoutSession = yield BillingSubscriptionAPI.upgradeSubscriptionV2(selectedModules);
      }
      if (checkoutSession == null ? void 0 : checkoutSession.url) {
        return window.location.replace(checkoutSession.url);
      }
      showMessage("Plan updated successfully", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Failed to update plan. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen: setIsDrawerOpen, children: !upgradeBlockedForAnnualCustomer ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        " ",
        polyglot.t("SubscriptionsTable.yourSubscription")
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("SubscriptionsTable.subscriptionSubheader") })
    ] }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      upgradePossible ? polyglot.t("SubscriptionsTable.addModule") : /* @__PURE__ */ jsx(Fragment, {}),
      !loadingUpgradeModules ? [...existingModules, ...availableUpgrades].map((eachPlan) => {
        var _a2, _b2;
        const isExisting = existingModules.some((module) => module.id === eachPlan.id);
        const isSelected = isExisting || selectedModulesForUpgrade.includes(eachPlan.id);
        const isCoreModule = eachPlan.name === "Core";
        return /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: spacing.g5
            },
            children: [
              /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  sx: { mt: spacing.mt10 },
                  name: "moduleSelectionForSubscription",
                  label: eachPlan.name,
                  checked: isSelected,
                  onChange: () => {
                    if (!isExisting) {
                      const updatedList = [...selectedModulesForUpgrade];
                      if (selectedModulesForUpgrade.includes(eachPlan.id)) {
                        setSelectedModulesForUpgrade(updatedList.filter((p) => p !== eachPlan.id));
                      } else {
                        updatedList.push(eachPlan.id);
                        setSelectedModulesForUpgrade(updatedList);
                      }
                    }
                  },
                  disabled: isExisting || isCoreModule
                }
              ),
              (isSelected || isExisting) && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: /* @__PURE__ */ jsx(CostDisplay, { children: formatMoney({
                amount: eachPlan.cost[(_a2 = companySubscription == null ? void 0 : companySubscription.currency) != null ? _a2 : BillingCurrency.GBP],
                currency: (_b2 = companySubscription == null ? void 0 : companySubscription.currency) != null ? _b2 : BillingCurrency.GBP
              }) }) })
            ]
          },
          eachPlan.id
        );
      }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "You are already subscribed to all available modules" })
    ] }),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15, mb: spacing.mb20 }, children: (selectedModulesForUpgrade == null ? void 0 : selectedModulesForUpgrade.length) > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("SubscriptionsTable.pricePerSeat"),
          value: `${formatMoney({
            amount: listPricePricePerSeat,
            currency: (_a = companySubscription == null ? void 0 : companySubscription.currency) != null ? _a : BillingCurrency.GBP
          })} / month`
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t(
            (companySubscription == null ? void 0 : companySubscription.seatsMethod) === SeatsMethod.ACTIVE ? "BillingOverview.active" : "BillingOverview.fixed"
          ),
          value: ((_b = companySubscription == null ? void 0 : companySubscription.seatMultiplier) != null ? _b : 0).toString()
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("SubscriptionsTable.subscriptionsTotal"),
          value: formatMoney({
            amount: listPriceTotalCostExclusiveOfVat,
            currency: (_c = companySubscription == null ? void 0 : companySubscription.currency) != null ? _c : BillingCurrency.GBP
          })
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: `${polyglot.t("SubscriptionsTable.VAT")}${additionalVatDetails ? additionalVatDetails : ""}`,
          value: additionalVatDetails ? formatMoney({
            amount: listPriceTotalVat,
            currency: (_d = companySubscription == null ? void 0 : companySubscription.currency) != null ? _d : BillingCurrency.GBP
          }) : "None"
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("SubscriptionsTable.totalInclVat"),
          value: formatMoney({
            amount: listPriceTotalCostInclusiveOfVat,
            currency: (_e = companySubscription == null ? void 0 : companySubscription.currency) != null ? _e : BillingCurrency.GBP
          })
        }
      ),
      /* @__PURE__ */ jsx(Divider, {}),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("SubscriptionsTable.daysChargedForThisMonth"),
          value: `${daysChargedForThisMonth} / ${daysInMonth}`
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("SubscriptionsTable.dueNow"),
          value: formatMoney({
            amount: proratedTotalCostInclusiveOfVat,
            currency: (_f = companySubscription == null ? void 0 : companySubscription.currency) != null ? _f : BillingCurrency.GBP
          })
        }
      )
    ] }) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        loading: isLoading || loadingUpgradeModules,
        colorVariant: "primary",
        disabled: !availableUpgrades || availableUpgrades.length === 0,
        fullWidth: true,
        onClick: handleUpgrade
      }
    ) })
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        " ",
        polyglot.t("SubscriptionsTable.yourSubscription")
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("SubscriptionSection.annualUpgradeBlockedMessage") })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.ok"),
        sizeVariant: "medium",
        loading: false,
        colorVariant: "primary",
        fullWidth: true,
        onClick: () => setIsDrawerOpen(false)
      }
    ) })
  ] }) });
};
