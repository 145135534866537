"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { lowerCase } from "lodash";
import * as yup from "yup";
import { UploadInput } from "@/component/forms/UploadInput";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as UploadedDocIcon } from "@/images/documents/UploadedDoc.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as InfoGrey } from "@/images/side-bar-icons/InfoGrey.svg";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { AdminDocumentView } from "@/v2/feature/document-new/interfaces/document-new.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DocumentNewImportFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  onFormSubmit,
  documentTypes,
  document,
  refresh,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    DocumentNewImportFormContent,
    {
      onClose,
      refresh,
      documentTypes,
      document,
      onFormSubmit
    }
  ) });
};
const DocumentNewImportFormContent = ({
  onClose,
  documentTypes,
  onFormSubmit,
  document,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const hasDocumentsAllScope = checkScopes(user, ["documents:all"], { userId: user.userId });
  const hasDocumentsManagerScope = useMemo(() => {
    if (document && document.userId) {
      checkScopes(globalState.user, ["documents:manager"], { userId: document.userId });
    }
    return false;
  }, [document, globalState.user]);
  const managerOrAdmin = useMemo(() => {
    return hasDocumentsAllScope || hasDocumentsManagerScope;
  }, [hasDocumentsAllScope, hasDocumentsManagerScope]);
  const formik = useFormik({
    initialValues: {
      id: (_a = document == null ? void 0 : document.id) != null ? _a : -1,
      state: (_b = document == null ? void 0 : document.state) != null ? _b : "pending",
      fileUuid: (_c = document == null ? void 0 : document.fileUuid) != null ? _c : null,
      contractId: (_d = document == null ? void 0 : document.contractId) != null ? _d : null,
      isExternalResource: (_e = document == null ? void 0 : document.isExternalResource) != null ? _e : false,
      programmaticRecordType: (_f = document == null ? void 0 : document.programmaticRecordType) != null ? _f : null,
      programmaticRecordId: (_g = document == null ? void 0 : document.programmaticRecordId) != null ? _g : null,
      userId: (_h = document == null ? void 0 : document.userId) != null ? _h : null,
      typeId: (_i = document == null ? void 0 : document.typeId) != null ? _i : -1,
      name: (_j = document == null ? void 0 : document.name) != null ? _j : "",
      note: (_k = document == null ? void 0 : document.note) != null ? _k : null,
      viewType: (_l = document == null ? void 0 : document.viewType) != null ? _l : AdminDocumentView.Personal,
      // import is always Personal
      membersRule: (_m = document == null ? void 0 : document.membersRule) != null ? _m : UserSelectFiltersOptions.SelectSpecific,
      // import is always SelectSpecific
      customRule: (_n = document == null ? void 0 : document.customRule) != null ? _n : null,
      documentSettings: (_o = document == null ? void 0 : document.documentSettings) != null ? _o : {
        isPinned: false,
        expiryDate: null,
        private: false,
        hideFromEmployee: false
      },
      file: (_p = document == null ? void 0 : document.file) != null ? _p : null
    },
    validationSchema: yup.object({
      userId: yup.number().nullable().required(),
      typeId: yup.number().required(),
      viewType: yup.string().required(),
      name: yup.string().required(),
      fileUuid: yup.string().nullable().notRequired(),
      file: yup.mixed().required("Attachment is required"),
      membersRule: yup.string().required(),
      customRule: yup.string().nullable().notRequired(),
      note: yup.string().nullable().notRequired()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        onFormSubmit(values);
        showMessage("Document successfully uploaded", "success");
        yield refresh == null ? void 0 : refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  const filteredTypes = useMemo(() => {
    if (!documentTypes) return [];
    return documentTypes.filter((d) => d.availableTo === lowerCase(formik.values.viewType) && d.visible).map((type) => {
      return { label: type.label, value: type.id };
    });
  }, [formik.values.viewType, documentTypes]);
  const reach = useMemo(() => {
    return hasDocumentsAllScope ? ReachType.Company : hasDocumentsManagerScope ? ReachType.Team : ReachType.Me;
  }, [hasDocumentsAllScope, hasDocumentsManagerScope]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Edit document" }),
    formik.values.userId ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: "Belongs to" }),
      /* @__PURE__ */ jsx(UserCell, { userId: formik.values.userId })
    ] }) : /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: reach,
        onChange: (_, x) => formik.setFieldValue("userId", (x == null ? void 0 : x.value) ? Number(x.value) : null),
        value: formik.values.userId,
        label: "Belongs to",
        error: formik.touched.userId && Boolean(formik.errors.userId),
        helperText: formik.touched.userId && formik.errors.userId
      }
    ),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "typeId",
        label: "Type",
        options: filteredTypes,
        value: (filteredTypes == null ? void 0 : filteredTypes.find(({ value }) => value === formik.values.typeId)) || null,
        compareValue: (_q = formik.values.typeId) != null ? _q : "",
        onChange: (_, x) => {
          var _a2;
          formik.setFieldValue("typeId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        error: !!formik.errors.typeId && formik.touched.typeId,
        helperText: formik.touched.typeId && formik.errors.typeId
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: "Name",
        multiline: true,
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_r = formik.touched.name && formik.errors.name) != null ? _r : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "note",
        label: "Note",
        multiline: true,
        value: formik.values.note,
        onChange: formik.handleChange,
        error: formik.touched.note && !!formik.errors.note,
        helperText: (_s = formik.touched.note && formik.errors.note) != null ? _s : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Advanced settings" }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Optional configurations for this document" })
    ] }),
    managerOrAdmin && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "documentSettings.hideFromEmployee",
        label: polyglot.t("DocumentFormModal.visibilitySettings"),
        checked: formik.values.documentSettings.hideFromEmployee,
        onChange: () => {
          formik.setFieldValue(
            "documentSettings.hideFromEmployee",
            !formik.values.documentSettings.hideFromEmployee
          );
        }
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "documentSettings.private",
          label: polyglot.t("DocumentFormModal.privateDocument"),
          checked: formik.values.documentSettings.private,
          onChange: () => {
            formik.setFieldValue("documentSettings.private", !formik.values.documentSettings.private);
          }
        }
      ),
      /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("DocumentFormModal.privateHelperText"), children: /* @__PURE__ */ jsx(InfoGrey, __spreadProps(__spreadValues({}, iconSize), { "aria-label": polyglot.t("DocumentFormModal.privateHelperText") })) })
    ] }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "documentSettings.expiryDate",
        label: polyglot.t("DocumentFormModal.expirySettings"),
        checked: Boolean(formik.values.documentSettings.expiryDate),
        onChange: (e) => {
          const target = e.target;
          if (target.checked) formik.setFieldValue("documentSettings.expiryDate", dayjs());
          else formik.setFieldValue("documentSettings.expiryDate", null);
        }
      }
    ),
    Boolean(formik.values.documentSettings.expiryDate) && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        label: polyglot.t("DocumentFormModal.expiryDate"),
        name: "documentSettings.expiryDate",
        value: formik.values.documentSettings.expiryDate,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("documentSettings.expiryDate", value);
          }
        },
        error: void 0,
        helperText: void 0
      }
    ),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Upload document" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "JPEG, PDF or Docx file not exceeding 2MB" })
          ] }),
          formik.values.file ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s2, justifyContent: "space-between" }, children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
              /* @__PURE__ */ jsx(UploadedDocIcon, __spreadValues({}, iconSize)),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Uploaded document" })
            ] }),
            (!document || !document.contractId) && /* @__PURE__ */ jsx(
              IconButton,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => __async(void 0, null, function* () {
                  try {
                    formik.setFieldValue("fileUuid", null);
                    formik.setFieldValue("file", null);
                  } catch (error) {
                    showMessage(nestErrorMessage(error), "error");
                  }
                }),
                title: polyglot.t("General.remove"),
                children: /* @__PURE__ */ jsx(TrashIcon, {})
              }
            )
          ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
            /* @__PURE__ */ jsx(
              UploadInput,
              {
                onChange: (response, file) => {
                  formik.setFieldValue("fileUuid", response == null ? void 0 : response.uuid);
                  formik.setFieldValue("file", file);
                }
              }
            ),
            (Boolean(formik.touched.fileUuid && !!formik.errors.fileUuid) || Boolean(formik.touched.file && !!formik.errors.file)) && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "RedDark", children: "Attachment is required" })
          ] })
        ]
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxDrawerSx), { pt: spacing.s2 }), children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
