"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { EditCustomBenefitPaymentDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-payment-drawer.component";
import {
  BenefitsSettingsPageSection,
  DescriptionLine
} from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import {
  CustomBenefitPaymentMethodType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { isRecurringBenefit } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const CustomBenefitSettingsPaymentPage = ({ customBenefit, refreshBenefit }) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const isRecurring = isRecurringBenefit(customBenefit.type);
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("BenefitModule.payments"),
      onEdit: () => {
        setIsEditOpen(true);
      },
      children: [
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.paymentMethod"),
            value: (_a = customBenefit.paymentMethod) != null ? _a : CustomBenefitPaymentMethodType.Tracking
          }
        ),
        customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Expenses && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.expenseType"),
            value: (_d = (_c = (_b = customBenefit.paymentTypes) == null ? void 0 : _b.map((paymentType) => paymentType.name)) == null ? void 0 : _c.join(", ")) != null ? _d : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Payroll && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("PayItemModule.payCodeEmployee"),
            value: (_e = customBenefit.payCode) != null ? _e : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        isRecurring && customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Payroll && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("PayItemModule.payCodeEmployer"),
            value: (_f = customBenefit.payCodeEmployer) != null ? _f : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        isEditOpen && /* @__PURE__ */ jsx(
          EditCustomBenefitPaymentDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            customBenefit,
            refresh: refreshBenefit
          }
        )
      ]
    }
  );
};
