"use strict";
import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import { SurveyEntriesPersonalStartModal } from "@/v2/feature/growth/surveys/features/survey-entry/survey-entries-personal/components/survey-entries-personal-start-modal.component";
export const SurveyActionItem = ({
  surveyActionRow,
  afterClose
}) => {
  const [isOpen, setIsOpen] = useState(Boolean(surveyActionRow));
  return /* @__PURE__ */ jsx(
    SurveyEntriesPersonalStartModal,
    {
      isOpen,
      setIsOpen,
      afterClose,
      onClose: () => setIsOpen(false),
      selectedEntry: surveyActionRow == null ? void 0 : surveyActionRow.entry
    }
  );
};
