"use strict";
import { isAfter, isSameDay, isWithinInterval } from "date-fns";
import { ViewSettingEnum } from "@/v2/feature/calendar/calendar.interface";
import { getPlaceholderDate, isAnniversaryInPeriod, isAnniversaryToday } from "@/v2/feature/dashboard/dashboard.util";
import {
  isISODateAfterDate2,
  isISODateBeforeDate2,
  isISODateSameAsLocaleDate
} from "@/v2/infrastructure/date/date-format.util";
import { LocalDate } from "@/v2/util/local-date";
export const getFilteredData = (range, users, view, signedInUser, requests, fellowReportees) => {
  if (view === ViewSettingEnum.Me)
    users = (signedInUser == null ? void 0 : signedInUser.userId) ? users.filter((u) => u.userId === (signedInUser == null ? void 0 : signedInUser.userId)) : [];
  if (view === ViewSettingEnum.Team) {
    users = (signedInUser == null ? void 0 : signedInUser.userId) && fellowReportees && fellowReportees.length > 0 ? users.filter((u) => fellowReportees.includes(u == null ? void 0 : u.userId) || (u == null ? void 0 : u.userId) === (signedInUser == null ? void 0 : signedInUser.userId)) : users.filter(
      (u) => {
        var _a;
        return (u == null ? void 0 : u.role) && ((_a = u.role) == null ? void 0 : _a.managerId) === (signedInUser == null ? void 0 : signedInUser.userId) || (u == null ? void 0 : u.userId) === (signedInUser == null ? void 0 : signedInUser.userId);
      }
    );
  }
  let filteredUsers = users.map((user) => {
    var _a, _b, _c, _d, _e, _f;
    const absences = requests == null ? void 0 : requests.filter((a) => a.userId === user.userId);
    return {
      id: user.userId,
      absences: absences ? [...absences] : [],
      name: (_a = user.displayName) != null ? _a : "",
      department: (_d = (_c = (_b = user.role) == null ? void 0 : _b.department) == null ? void 0 : _c.name) != null ? _d : "-",
      siteId: (_f = (_e = user.role) == null ? void 0 : _e.site) == null ? void 0 : _f.id,
      role: user.role,
      startDate: user.startDate,
      dob: user.showBirthday !== null ? getPlaceholderDate(user.showBirthday) : null,
      leaveDate: user.leaveDate
    };
  }).filter((user) => {
    var _a;
    return user.dob && isAnniversaryInPeriod(user.dob, range) || user.startDate && isAnniversaryInPeriod(user.startDate, range) || user.startDate && isWithinInterval(new LocalDate(user.startDate).getDate(), {
      start: new LocalDate(range[0]).getDate(),
      end: new LocalDate(range[1]).getDate()
    }) || user.leaveDate && isWithinInterval(new LocalDate(user.leaveDate).getDate(), {
      start: new LocalDate(range[0]).getDate(),
      end: new LocalDate(range[1]).getDate()
    }) || (user == null ? void 0 : user.absences) && ((_a = user == null ? void 0 : user.absences) == null ? void 0 : _a.length) > 0;
  });
  return filteredUsers;
};
export const getCategorizedData = (eventData, filteredData, currentDate) => {
  return filteredData.forEach((user) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    if (user.dob && isAnniversaryToday(user.dob, currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: (_c = (_b = (_a = user.role) == null ? void 0 : _a.jobPosition) == null ? void 0 : _b.title) != null ? _c : null,
        department: (_f = (_e = (_d = user.role) == null ? void 0 : _d.department) == null ? void 0 : _e.id) != null ? _f : null,
        type: "Birthday",
        startDate: user.dob,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null
      });
    }
    if (user.startDate && isAnniversaryToday(user.startDate, currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: (_i = (_h = (_g = user.role) == null ? void 0 : _g.jobPosition) == null ? void 0 : _h.title) != null ? _i : null,
        department: (_l = (_k = (_j = user.role) == null ? void 0 : _j.department) == null ? void 0 : _k.id) != null ? _l : null,
        type: "Work anniversary",
        startDate: user.startDate,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null
      });
    }
    if (user.startDate && isSameDay(new LocalDate(user.startDate).getDate(), currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: (_o = (_n = (_m = user.role) == null ? void 0 : _m.jobPosition) == null ? void 0 : _n.title) != null ? _o : null,
        department: (_r = (_q = (_p = user.role) == null ? void 0 : _p.department) == null ? void 0 : _q.id) != null ? _r : null,
        type: "First day",
        startDate: user.startDate,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null
      });
    }
    if (user.leaveDate && isSameDay(new LocalDate(user.leaveDate).getDate(), currentDate)) {
      eventData.push({
        displayName: user.name,
        userId: user.id,
        jobTitle: (_u = (_t = (_s = user.role) == null ? void 0 : _s.jobPosition) == null ? void 0 : _t.title) != null ? _u : null,
        department: (_x = (_w = (_v = user.role) == null ? void 0 : _v.department) == null ? void 0 : _w.id) != null ? _x : null,
        type: "Last day",
        startDate: user.leaveDate,
        endDate: null,
        currentDate: new LocalDate(currentDate).toDateString(),
        isAbsence: false,
        absenceId: null
      });
    }
    const absence = (_y = user == null ? void 0 : user.absences) == null ? void 0 : _y.filter((absence2) => {
      if (absence2.end)
        return isISODateAfterDate2(absence2.end, currentDate) && isISODateBeforeDate2(absence2.start, currentDate) || isISODateSameAsLocaleDate(absence2.start, currentDate) || isISODateSameAsLocaleDate(absence2.end, currentDate);
      return isISODateSameAsLocaleDate(absence2.start, currentDate);
    });
    if (absence && absence.length > 0) {
      absence.map((a) => {
        var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2;
        if (eventData.find((event) => event.absenceId === a.absenceId)) return null;
        return eventData.push({
          displayName: user.name,
          userId: user.id,
          jobTitle: (_c2 = (_b2 = (_a2 = user.role) == null ? void 0 : _a2.jobPosition) == null ? void 0 : _b2.title) != null ? _c2 : null,
          department: (_f2 = (_e2 = (_d2 = user.role) == null ? void 0 : _d2.department) == null ? void 0 : _e2.id) != null ? _f2 : null,
          type: (_h2 = (_g2 = a.policy) == null ? void 0 : _g2.name) != null ? _h2 : "Away",
          startDate: new LocalDate(a.start).toDateString(),
          endDate: a.end ? new LocalDate(a.end).toDateString() : null,
          currentDate: new LocalDate(currentDate).toDateString(),
          isAbsence: true,
          absenceId: a.absenceId
        });
      }).filter(Boolean);
    }
  });
};
export const getCurrentDateAndFutureEvents = (structuredData, currentDate) => {
  return structuredData.filter((d) => {
    if (!d.isAbsence) {
      const startDate = new LocalDate(currentDate).getDate();
      const eventDate = new LocalDate(d.startDate).getDate();
      eventDate.setFullYear(startDate.getFullYear());
      return isAfter(new LocalDate(eventDate).getDate(), currentDate) || isSameDay(new LocalDate(eventDate).getDate(), currentDate);
    } else if (d.isAbsence && d.endDate && (isSameDay(new LocalDate(d.endDate).getDate(), currentDate) || isAfter(new LocalDate(d.endDate).getDate(), currentDate)) || isSameDay(new LocalDate(d.startDate).getDate(), currentDate) || isAfter(new LocalDate(d.startDate).getDate(), currentDate)) {
      return true;
    }
    return false;
  });
};
export const DaysOptions = (polyglot) => {
  return {
    Mon: polyglot == null ? void 0 : polyglot.t("UserCalendar.Mon"),
    Tue: polyglot == null ? void 0 : polyglot.t("UserCalendar.Tue"),
    Wed: polyglot == null ? void 0 : polyglot.t("UserCalendar.Wed"),
    Thu: polyglot == null ? void 0 : polyglot.t("UserCalendar.Thu"),
    Fri: polyglot == null ? void 0 : polyglot.t("UserCalendar.Fri"),
    Sat: polyglot == null ? void 0 : polyglot.t("UserCalendar.Sat"),
    Sun: polyglot == null ? void 0 : polyglot.t("UserCalendar.Sun")
  };
};
