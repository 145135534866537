"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { REQUESTS_CHOOSE_REQUEST_ROUTE, REQUESTS_SUBMIT_REQUEST_ROUTE } from "@/lib/routes";
import { ChooseRequestPage } from "@/v2/feature/requests/features/submit-request/choose-request.page";
import { SubmitRequestPage } from "@/v2/feature/requests/features/submit-request/submit-request.page";
export const RequestsRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: REQUESTS_SUBMIT_REQUEST_ROUTE, component: SubmitRequestPage, exact: true }),
    /* @__PURE__ */ jsx(Route, { path: REQUESTS_CHOOSE_REQUEST_ROUTE, component: ChooseRequestPage, exact: true })
  ] });
};
