"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { IconContentRadioCard } from "@v2/components/theme-components/icon-content-radio-card.component";
import { StyledRadio } from "@v2/components/theme-components/styled-radio.component";
import { PensionProvidersOptions } from "@v2/feature/benefits/subfeature/pension/pension.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { NetPayArrangement, PensionProviders, PensionRulesOptions, ReliefAtSource } from "@/lib/pensions";
export const SelectPensionProviderStep = ({
  onNext,
  pensionProvider,
  setPensionProvider,
  setPensionRule
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { margin: "auto", mt: spacing.mt10, width: "400px" }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.mb30, color: themeColors.DarkGrey }), children: polyglot.t("PensionModule.selectYourProvider") }),
    PensionProvidersOptions.map((provider) => /* @__PURE__ */ jsx(
      IconContentRadioCard,
      {
        cardOnClick: () => {
          setPensionProvider(provider.value);
          if (provider.value === PensionProviders.Nest) setPensionRule(ReliefAtSource.value);
          else if (provider.value === PensionProviders.SmartPension) setPensionRule(NetPayArrangement.value);
          else setPensionRule(PensionRulesOptions[0].value);
        },
        title: provider.label,
        content: null,
        iconMedia: provider.icon,
        action: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              alignItems: "end"
            },
            children: /* @__PURE__ */ jsx(
              StyledRadio,
              {
                name: provider.label,
                id: provider.value,
                value: provider.value,
                selectedValue: pensionProvider
              }
            )
          }
        ),
        showDivider: true,
        sx: { minHeight: "40px", maxHeight: "40px" }
      }
    )),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.mt40 }), children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        onClick: onNext,
        disabled: !pensionProvider,
        children: polyglot.t("PensionModule.selectProvider")
      }
    ) })
  ] });
};
