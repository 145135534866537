"use strict";
import { jsx } from "react/jsx-runtime";
import { BasicTable } from "@/v2/components/table/basic-table.component";
export const AppDetailsTable = ({
  column,
  row,
  loading,
  hidePagination = false,
  paginationReset = false,
  handleRowClicked = () => {
  }
}) => {
  return /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: [...row],
      columnData: column,
      loading,
      hidePagination,
      paginationReset,
      rowClick: handleRowClicked
    }
  );
};
