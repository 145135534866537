"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { PensionSettingsGeneralPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-general.page";
import { PensionSettingsMembersPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-members.page";
import { PensionSettingsProviderPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-provider.page";
import { PensionSettingsWorkerGroupsPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/pages/pension-settings-worker-groups.page";
import { PensionEndpoints } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, Redirect, Route, Switch } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE,
  SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE,
  SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE,
  SETTINGS_BENEFITS_ROUTE
} from "@/lib/routes";
export const getPageConfig = (type, id, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("General.general"),
          stub: "general",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, { category: type, id, productType: "standard" })
        },
        {
          title: polyglot.t("PensionModule.providerSettings"),
          stub: "provider",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE, { category: type, id, productType: "standard" })
        },
        {
          title: polyglot.t("PensionModule.workerGroups"),
          stub: "worker-groups",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE, {
            category: type,
            id,
            productType: "standard"
          })
        },
        {
          title: polyglot.t("General.members"),
          stub: "members",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, { category: type, id, productType: "standard" })
        }
      ]
    }
  ];
};
export const PensionSettingsRouter = ({ id, type }) => {
  var _a;
  const { data: pensionScheme, mutate: refresh } = useApiClient(PensionEndpoints.getPension(id));
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const refreshPension = useCallback(() => __async(void 0, null, function* () {
    try {
      if (refresh) yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [polyglot, refresh, showMessage]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_a = pensionScheme == null ? void 0 : pensionScheme.displayName) != null ? _a : polyglot.t("PensionModule.pensionScheme"),
        subtitle: polyglot.t("PensionModule.pension"),
        pageConfig: getPageConfig(type, id, polyglot),
        backPath: SETTINGS_BENEFITS_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { children: pensionScheme && /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(PensionSettingsGeneralPage, { type, pensionScheme, refreshPension }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_PROVIDER_ROUTE, children: /* @__PURE__ */ jsx(PensionSettingsProviderPage, { pensionScheme, refreshPension }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_WORKER_GROUPS_ROUTE, children: /* @__PURE__ */ jsx(PensionSettingsWorkerGroupsPage, { pensionScheme, refreshPension }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, children: /* @__PURE__ */ jsx(PensionSettingsMembersPage, {}) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE })
    ] }) })
  ] });
};
