"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DietaryRestrictionsSelect } from "@/v2/components/dietary-select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { spacing } from "@/v2/styles/spacing.styles";
const UserAboutSchema = (polyglot) => Yup.object().shape({
  about: Yup.string().nullable().optional(),
  hobbies: Yup.string().nullable().optional(),
  social: Yup.string().nullable().matches(/http[s]:\/\/\S+/, polyglot.t("OnboardingByUser.validations.validProfileURL")).optional(),
  foodPreferences: Yup.string().nullable().optional(),
  dietaryRestrictions: Yup.string().nullable().optional()
});
export const UserAboutSection = ({ onNext, sx, aboutValues, userId, form }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const defaultFieldsVisibility = useMemo(() => {
    var _a2, _b, _c, _d, _e;
    if (!form)
      return {
        about: false,
        hobbies: false,
        social: false,
        foodPreferences: false,
        dietaryRestrictions: false
      };
    const aboutField = (_a2 = form.fields) == null ? void 0 : _a2.find((f) => f.isDefault && f.fieldStub === "about");
    const hobbiesField = (_b = form.fields) == null ? void 0 : _b.find((f) => f.isDefault && f.fieldStub === "hobbies");
    const socialField = (_c = form.fields) == null ? void 0 : _c.find((f) => f.isDefault && f.fieldStub === "social");
    const foodPreferencesField = (_d = form.fields) == null ? void 0 : _d.find((f) => f.isDefault && f.fieldStub === "foodPreferences");
    const dietaryRestrictionsField = (_e = form.fields) == null ? void 0 : _e.find((f) => f.isDefault && f.fieldStub === "dietaryRestrictions");
    return {
      about: Boolean(aboutField && !aboutField.isHidden),
      hobbies: Boolean(hobbiesField && !hobbiesField.isHidden),
      social: Boolean(socialField && !socialField.isHidden),
      foodPreferences: Boolean(foodPreferencesField && !foodPreferencesField.isHidden),
      dietaryRestrictions: Boolean(dietaryRestrictionsField && !dietaryRestrictionsField.isHidden)
    };
  }, [form]);
  const initialValues = useMemo(() => {
    var _a2, _b, _c, _d, _e, _f;
    const values = {};
    if (defaultFieldsVisibility.about) values.about = (_a2 = aboutValues.about) != null ? _a2 : "";
    if (defaultFieldsVisibility.hobbies) values.hobbies = (_b = aboutValues.hobbies) != null ? _b : "";
    if (defaultFieldsVisibility.social) values.social = (_c = aboutValues.social) != null ? _c : "";
    if (defaultFieldsVisibility.foodPreferences) values.foodPreferences = (_d = aboutValues.foodPreferences) != null ? _d : "";
    if (defaultFieldsVisibility.dietaryRestrictions) values.dietaryRestrictions = (_e = aboutValues.dietaryRestrictions) != null ? _e : "";
    values.customUpdates = (_f = aboutValues.customUpdates) != null ? _f : [];
    return values;
  }, [defaultFieldsVisibility, aboutValues]);
  const formik = useFormik({
    initialValues,
    validationSchema: UserAboutSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserAboutInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.emergencyContactNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OnboardingByUser.aboutYou") }),
    defaultFieldsVisibility.about && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "about",
        label: polyglot.t("OnboardingByUser.tellUsAboutYou"),
        value: formik.values.about,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("about", ""),
        helperText: hasSubmitted && formik.errors.about,
        error: hasSubmitted && !!formik.errors.about,
        autoFocus: true
      }
    ),
    defaultFieldsVisibility.hobbies && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "hobbies",
        label: polyglot.t("OnboardingByUser.hobbies"),
        value: formik.values.hobbies,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("hobbies", ""),
        helperText: hasSubmitted && formik.errors.hobbies,
        error: hasSubmitted && !!formik.errors.hobbies
      }
    ),
    defaultFieldsVisibility.social && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "social",
        label: polyglot.t("OnboardingByUser.LinkedIn"),
        value: formik.values.social,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("social", ""),
        helperText: hasSubmitted && formik.errors.social,
        error: hasSubmitted && !!formik.errors.social
      }
    ),
    defaultFieldsVisibility.foodPreferences && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "foodPreferences",
        label: polyglot.t("OnboardingByUser.favouriteFoods"),
        value: formik.values.foodPreferences,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("foodPreferences", ""),
        helperText: hasSubmitted && formik.errors.foodPreferences,
        error: hasSubmitted && !!formik.errors.foodPreferences
      }
    ),
    defaultFieldsVisibility.dietaryRestrictions && /* @__PURE__ */ jsx(
      DietaryRestrictionsSelect,
      {
        name: "dietaryRestrictions",
        value: formik.values.dietaryRestrictions,
        disabled: saving,
        onChange: (_, value) => formik.setFieldValue("dietaryRestrictions", value)
      }
    ),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: (_a = formik.values.customUpdates) != null ? _a : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
