"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ViewerItem } from "./payment-details-drawer.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const LineItemInDrawerComponent = ({
  item,
  currency,
  showBottomBorder = false
}) => {
  var _a, _b, _c, _d;
  const accountingCodeDescription = item.accountingCodeDescription && ((_a = item.accountingCodeDescription) == null ? void 0 : _a.length) > 0 ? ` - ${truncateWithEllipses(item.accountingCodeDescription, 25)}` : "";
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: showBottomBorder ? borders.background : null,
        pb: "5px"
      },
      children: /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (_c = (_b = item == null ? void 0 : item.type) == null ? void 0 : _b.name) != null ? _c : "" }),
        /* @__PURE__ */ jsx(
          ViewerItem,
          {
            title: (item == null ? void 0 : item.description) ? item.description : (item == null ? void 0 : item.accountingCode) ? `${item.accountingCode}${accountingCodeDescription}` : "Gross (Inc.Tax)",
            value: (_d = formatCurrency(item.gross, void 0, currency)) != null ? _d : ""
          }
        )
      ] })
    }
  );
};
