"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box, IconButton, Input, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { themeFonts } from "@v2/styles/fonts.styles";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey, useKeypress } from "@/v2/util/keyboard-hook.util";
export const iconSize = { width: 14, height: 14 };
export const EditableTitle = ({
  variant,
  sx = {},
  onChange,
  maxLength,
  value,
  editButtonTooltip
}) => {
  var _a;
  const [editing, setEditing] = useState(false);
  const [editButtonVisible, setEditButtonVisible] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const editRef = useRef(null);
  const finishEdit = useCallback(() => {
    setEditing(false);
    const newValue = editedValue.trim();
    if (!newValue || newValue === value) {
      setEditedValue(newValue);
      return;
    }
    onChange == null ? void 0 : onChange(newValue);
  }, [editedValue, onChange, value]);
  useEscapeKey(finishEdit);
  useKeypress("Enter", finishEdit);
  useEffect(() => {
    setEditButtonVisible(!!(editButtonTooltip == null ? void 0 : editButtonTooltip.open));
  }, [editButtonTooltip]);
  return editing ? /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
    Input,
    {
      fullWidth: true,
      ref: editRef,
      sx: __spreadProps(__spreadValues(__spreadValues({}, (_a = themeFonts[variant]) != null ? _a : {}), sx), {
        border: "none",
        outline: "none",
        padding: "0px",
        "&::before": { display: "none" },
        "&::after": { display: "none" }
      }),
      onChange: (e) => setEditedValue(e.target.value),
      onBlur: finishEdit,
      value: editedValue,
      autoFocus: true,
      inputProps: {
        maxLength
      }
    }
  ) }) : /* @__PURE__ */ jsxs(
    Stack,
    {
      onPointerEnter: () => setEditButtonVisible(true),
      onPointerLeave: () => setEditButtonVisible(false),
      sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10, justifyContent: "space-between" },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant, sx, children: value }),
        /* @__PURE__ */ jsx(StyledTooltip, __spreadProps(__spreadValues({}, editButtonTooltip), { children: /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: __spreadProps(__spreadValues({}, iconButtonSx), { opacity: editButtonVisible || (editButtonTooltip == null ? void 0 : editButtonTooltip.open) ? 1 : 0 }),
            onClick: () => setEditing(true),
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }))
      ]
    }
  );
};
