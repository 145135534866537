"use strict";
import { camelCase } from "lodash";
import { parse } from "papaparse";
export function getCSVRows(arr) {
  const dataMap = /* @__PURE__ */ new Map();
  function setValue(rowValues, path, val) {
    if (Object(val) !== val) {
      const pathStr = path.join(".");
      const idx = (dataMap.has(pathStr) ? dataMap : dataMap.set(pathStr, dataMap.size)).get(pathStr);
      rowValues[idx] = val;
    } else {
      for (let key in val) {
        setValue(rowValues, key === "0" ? path : path.concat(key), val[key]);
      }
    }
    return rowValues;
  }
  const result = arr.map((obj) => setValue([], [], obj));
  return [[...dataMap.keys()], ...result];
}
export function toCsv(arr) {
  return arr.map((row) => row.join(",")).join("\n");
}
export const parseCsvBuffer = (csvString) => {
  const { data, errors, meta: _ } = parse(csvString.toString(), {
    download: false,
    header: true,
    transformHeader: (header) => {
      if (header.includes(".")) {
        return header.trim();
      }
      return camelCase(header);
    },
    skipEmptyLines: "greedy",
    transform: (value) => {
      return value.trim();
    }
  });
  if (errors[0]) {
    throw errors[0];
  }
  return data;
};
