"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export const PAYROLL_IMPORT_CSV_ENDPOINT = "/apiv2/payroll/import/csv";
export class PayrollImportAPI {
  static importPayrollCsvText(csvText) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/payroll/import/csv/text", { csvText })).data;
    });
  }
}
