"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class UserImportAPI {
  static bulkCreate(users) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/users/import/bulk", users)).data;
    });
  }
  // TODO: should delete this endpoints FE & BE if not planning to use it anymore
  static importUsersCsvText(csvText) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/users/import/csv/text", { csvText })).data;
    });
  }
  // used for azure import
  static importViaCsv(users) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/users/import/csv-v2", users)).data;
    });
  }
  // used for queue old and azure import
  static importViaCsvV3(file, source) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/import/csv-v3/${source}`, file)).data;
    });
  }
  // used for csv import
  static importViaCsvV4(importData, source) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/import/csv-v4/${source}`, importData)).data;
    });
  }
  // superadmin endpoints
  static importViaLinkedIn(linkedInUrl, emailDomain) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/import/linkedin/${encodeURIComponent(linkedInUrl)}/${emailDomain}`)).data;
    });
  }
  static getUserImportLinkedInQueues() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/import/queues/import-queue`)).data;
    });
  }
}
