"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, List, ListItem } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import HoorayLogo from "@/images/vendors/HoorayLogo.svg";
import { nestErrorMessage } from "@/lib/errors";
import { INSURANCE_COMPANY_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
export const InsuranceSetupDataSharingStep = ({ quoteData, refresh }) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const requestAQuote = (values) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield InsuranceAPI.requestInsuranceQuote(values);
      yield refresh();
      history.push(INSURANCE_COMPANY_ROUTE);
      showMessage(polyglot.t("BenefitModule.quoteRequestedSuccess"), "success");
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { margin: "auto", mt: spacing.mt10, width: "400px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.beforeYouContinue") }),
    /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mt: spacing.mt30, mb: spacing.mb20, color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.howDataIsUsed") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.mb10, color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.howDataIsUsedLong") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.mb10, color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.hooraySuggestionsLong") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.ifWantToKnowMore") }),
    /* @__PURE__ */ jsxs(List, { dense: true, sx: { listStyleType: "disc", pl: 4, py: 0, mt: 0 }, children: [
      /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item", py: 0, my: 0 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: /* @__PURE__ */ jsx(
        "a",
        {
          href: "https://hoorayinsurance.co.uk/group-private-medical/",
          target: "_blank",
          rel: "noreferrer",
          style: { textDecoration: "none" },
          children: polyglot.t("BenefitModule.aboutHooray")
        }
      ) }) }),
      /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item", py: 0, my: 0 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: /* @__PURE__ */ jsx(
        "a",
        {
          href: "https://drive.google.com/file/d/1KpuwIyMT_G9gvGUo5llgtD6nJ5a58X9I/view?usp=share_link",
          target: "_blank",
          rel: "noreferrer",
          style: { textDecoration: "none" },
          children: polyglot.t("BenefitModule.hoorayPrivacyNotice")
        }
      ) }) }),
      /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item", py: 0, my: 0 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: /* @__PURE__ */ jsx(
        "a",
        {
          href: "https://drive.google.com/file/d/1dz9sRmMDKhD0MAgnxrIysOuyxi52PGe4/view?usp=share_link",
          target: "_blank",
          rel: "noreferrer",
          style: { textDecoration: "none" },
          children: polyglot.t("BenefitModule.hoorayTermsOfBusiness")
        }
      ) }) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt30, display: "flex", gap: spacing.gap20, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx("img", { src: HoorayLogo, alt: "payroll-empty", width: 80, height: "auto" }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.mb10, color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.poweredByHooray") })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.mt40 }), children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.accept"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading,
        onClick: () => __async(void 0, null, function* () {
          return yield requestAQuote(quoteData);
        }),
        fullWidth: true,
        type: "button"
      }
    ) })
  ] });
};
