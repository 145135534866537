"use strict";
import { NO_VAT_VALUE } from "@v2/feature/payments/expenses.util";
import { fixedNumber } from "@v2/util/number.util";
export const isItemsArrayValid = (items) => !items.find((item) => !item.amount || item.amount <= 0);
export const calculateGrossAmountForInvoice = (amount, taxRate) => {
  if (!taxRate || taxRate === NO_VAT_VALUE) return { gross: amount, taxAmount: 0 };
  if (taxRate && amount) {
    const multiplier = taxRate ? 1 + taxRate / 100 : 1;
    const totalGross = fixedNumber(multiplier ? amount * multiplier : amount, 2);
    const totalTaxAmount = fixedNumber(totalGross - amount, 2);
    return { gross: totalGross, taxAmount: totalTaxAmount };
  }
  return { gross: amount, taxAmount: 0 };
};
export const getInvoiceTotalsForIndividualLineItem = (amount, taxRate) => {
  const totalAmount = amount;
  const { gross: totalGross, taxAmount: totalTaxAmount } = calculateGrossAmountForInvoice(amount, taxRate);
  return {
    totalGross,
    totalAmount,
    totalTaxAmount
  };
};
export const isTaxIncluded = (taxRate) => {
  return taxRate === NO_VAT_VALUE ? false : true;
};
export const getInvoiceTotalsBasedOnLineItems = (lineItems) => {
  let totalGross = 0;
  let totalAmount = 0;
  let totalTaxAmount = 0;
  for (const eachLineItem of lineItems) {
    const { amount = 0, taxRate = 0 } = eachLineItem;
    const {
      totalGross: grossForLineItem,
      totalAmount: amountForLineItem,
      totalTaxAmount: taxAmountForLineItem
    } = getInvoiceTotalsForIndividualLineItem(amount, taxRate != null ? taxRate : 0);
    totalGross += grossForLineItem;
    totalAmount += amountForLineItem;
    totalTaxAmount += taxAmountForLineItem;
  }
  return {
    totalGross,
    totalAmount,
    totalTaxAmount
  };
};
export const getFinalGrossForLineItemInvoice = (lineItems) => {
  let finalAmount = 0;
  for (const eachLineItem of lineItems) {
    const { amount = 0, taxRate = 0 } = eachLineItem;
    const { gross: totalGross } = calculateGrossAmountForInvoice(amount, taxRate);
    finalAmount += totalGross;
  }
  return finalAmount;
};
