"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { UserContractEndpoints } from "@/api-client/contracts.api";
import { getUserCompensationSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { USER_PAYROLL_NEW_STARTER } from "@/lib/routes";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { EditPayrollRecordDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import {
  calculateSkeletonHeight,
  cardSx,
  hazardSize
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { TaxCardStructure } from "@/v2/feature/user/features/user-profile/details/components/tax-card-structure.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { isSameCountryCode } from "@/v2/infrastructure/country/country.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { hazardMissingField } from "@/v2/styles/icon-button.styles";
const skeletonHeight = (polyglot) => calculateSkeletonHeight(getUserCompensationSchema(polyglot));
const PAYROLL_ALL_SCOPE = ["payroll:all"];
export const TaxInformationCard = ({
  userId,
  handleSubmit = () => {
  },
  missingFields
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const isOwnProfile = userId === globalState.user.userId;
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const viewerContext = getScopesContext(state.user);
  const hasPayrollScope = hasScopes(["payroll", "payroll:all"], viewerContext);
  const [showDetails, setShowDetails] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [editingRecord, setEditingRecord] = useState();
  const getProfileScope = (scopeRoot) => [
    isOwnerProfile ? `${scopeRoot}:read` : `${scopeRoot}:read:all`
  ];
  const isOwnerProfile = ((_a = state.user) == null ? void 0 : _a.userId) === userId;
  const PAYROLL_READ_SCOPE = getProfileScope("payroll");
  const { data: payroll, isValidating: loadingPayrollData, mutate: refreshPayrollData } = useApiClient(
    hasPayrollScope || isOwnerProfile ? PayrollEndpoints.getUserPayrolls(userId) : { url: void 0 },
    {
      suspense: false
    }
  );
  const { data: contract } = useApiClient(UserContractEndpoints.findCurrentByUserId(userId), { suspense: false });
  const effectiveRecord = payroll == null ? void 0 : payroll.effectiveRecord;
  const payrollCountry = ((_b = payroll == null ? void 0 : payroll.effectiveRecord) == null ? void 0 : _b.countryCode) || "GB";
  const contractCountry = ((_d = (_c = contract == null ? void 0 : contract.entity) == null ? void 0 : _c.address) == null ? void 0 : _d.countryCode) || "GB";
  const addAction = useMemo(() => {
    if (effectiveRecord && isSameCountryCode(payrollCountry, contractCountry)) return void 0;
    return isOwnProfile ? () => {
      routerHistory.push(generatePath(USER_PAYROLL_NEW_STARTER, { userId }));
    } : () => {
      setEditingRecord({ mode: "initial" });
      setEditDrawerOpen(true);
    };
  }, [contractCountry, effectiveRecord, isOwnProfile, payrollCountry, routerHistory, userId]);
  const editAction = useCallback(() => {
    setEditingRecord({ mode: "append", record: effectiveRecord });
    setEditDrawerOpen(true);
  }, [effectiveRecord]);
  const onEditRowClick = useCallback((record) => {
    setEditingRecord({ mode: "edit", record });
    setEditDrawerOpen(true);
  }, []);
  const drawerDetails = /* @__PURE__ */ jsx(ScopesControl, { scopes: PAYROLL_ALL_SCOPE, context: scopesContext, children: editingRecord && /* @__PURE__ */ jsx(
    EditPayrollRecordDrawer,
    {
      mode: editingRecord.mode,
      payrollRecord: (_e = editingRecord.record) != null ? _e : null,
      isOpen: editDrawerOpen,
      onOpen: setEditDrawerOpen,
      close: () => setEditDrawerOpen(false),
      userId,
      onUpdateFinished: () => {
        refreshPayrollData == null ? void 0 : refreshPayrollData();
        handleSubmit == null ? void 0 : handleSubmit(userId);
      }
    }
  ) });
  const missingInfoIcon = /* @__PURE__ */ jsx(ScopesControl, { scopes: PAYROLL_ALL_SCOPE, context: scopesContext, children: (missingFields == null ? void 0 : missingFields.find((rec) => rec.cardId === CustomProfileFormType.TaxInformation)) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    loadingPayrollData && /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        height: skeletonHeight(polyglot),
        sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
      }
    ),
    !loadingPayrollData && /* @__PURE__ */ jsx(
      TaxCardStructure,
      {
        addAction,
        addButtonTitle: isOwnProfile ? polyglot.t("TaxInformationCard.newStarter") : polyglot.t("TaxInformationCard.newTax"),
        cardScope: effectiveRecord ? PAYROLL_ALL_SCOPE : PAYROLL_READ_SCOPE,
        drawerDetails,
        editAction,
        missingInfoIcon,
        onEditRowClick,
        payroll,
        payrollCountry,
        contractCountry,
        showDetailsState: [showDetails, setShowDetails],
        userId
      }
    )
  ] });
};
