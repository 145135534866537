"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Alert, Box, Stack } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { Errors } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { AuthSignupThanks } from "@/v2/feature/auth/components/auth-signup-thanks.component";
import { spacing } from "@/v2/styles/spacing.styles";
const validationSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  companyName: yup.string().required("Company name is required"),
  jobTitle: yup.string().required("Job title is required")
});
export const AuthXeroSignupPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [invitationSent, setInvitationSent] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const getAppCredential = useCallback(() => {
    if (!email) {
      window.location.href = AppIntegrationAPI.getSignUpInitiateUrl();
    }
  }, [email]);
  const formik = useFormik({
    initialValues: {
      email,
      firstName,
      lastName,
      companyName: "",
      jobTitle: ""
    },
    validationSchema,
    onSubmit: (formData) => __async(void 0, null, function* () {
      var _a;
      try {
        setIsLoading(true);
        const onboarding = {
          companyName: formData.companyName,
          user: {
            emailAddress: email,
            firstName,
            lastName,
            jobTitle: formData.jobTitle
          }
        };
        const user = yield AuthAPI.signupAccountCreation(onboarding);
        yield AuthAPI.signupEmail({
          userId: user.userId,
          password: user.password,
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          companyName: (_a = user.company) == null ? void 0 : _a.name
        });
        setIsLoading(false);
        setInvitationSent(true);
        setError("");
      } catch (error2) {
        if (error2.response.data.message === Errors.EMAIL_EXISTS) {
          setError("User already registered!");
        } else {
          setError("Something bad happened. " + error2.response.data.message);
        }
      } finally {
        setIsLoading(false);
      }
    })
  });
  useEffect(() => {
    getAppCredential();
  }, [getAppCredential]);
  if (invitationSent) {
    return /* @__PURE__ */ jsx(AuthSignupThanks, {});
  }
  return /* @__PURE__ */ jsx(AuthLayout, { title: "Get started with Zelt", submit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    error && /* @__PURE__ */ jsx(Alert, { severity: "error", children: error }),
    /* @__PURE__ */ jsxs(Stack, { spacing: spacing.m25, sx: { mt: 1 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "First Name",
          name: "firstName",
          disabled: true,
          value: formik.values.firstName,
          onChange: formik.handleChange,
          error: formik.touched.firstName && Boolean(formik.errors.firstName),
          helperText: formik.touched.firstName && formik.errors.firstName,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Last Name",
          disabled: true,
          name: "lastName",
          value: formik.values.lastName,
          onChange: formik.handleChange,
          error: formik.touched.lastName && Boolean(formik.errors.lastName),
          helperText: formik.touched.lastName && formik.errors.lastName,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Email",
          disabled: true,
          name: "email",
          value: formik.values.email,
          onChange: formik.handleChange,
          error: formik.touched.email && Boolean(formik.errors.email),
          helperText: formik.touched.email && formik.errors.email,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Company Name",
          name: "companyName",
          value: formik.values.companyName,
          onChange: formik.handleChange,
          error: formik.touched.companyName && Boolean(formik.errors.companyName),
          helperText: formik.touched.companyName && formik.errors.companyName,
          size: "small",
          endAdornment: "none",
          autoFocus: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Job Title",
          name: "jobTitle",
          value: formik.values.jobTitle,
          onChange: formik.handleChange,
          error: formik.touched.jobTitle && Boolean(formik.errors.jobTitle),
          helperText: formik.touched.jobTitle && formik.errors.jobTitle,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: spacing.mt40, children: [
        " ",
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            type: "submit",
            name: "Create account",
            fullWidth: true,
            loading: isLoading,
            sizeVariant: "large",
            colorVariant: "primary"
          }
        )
      ] })
    ] })
  ] }) });
};
