"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Typography } from "@mui/material";
import { generatePath } from "react-router-dom";
import useScopes from "@/hooks/scopes.hook";
import { USER_BENEFITS_OVERVIEW_ROUTE } from "@/lib/routes";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PensionMe } from "@/v2/feature/benefits/subfeature/pension/features/pension-me/components/pension-me.components";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
export const UserProfilePension = ({ userId, employeePension, onEmployeeUpdate, loading }) => {
  const [isEnrolDrawerOpen, setIsEnrolDrawerOpen] = useState(false);
  const { hasScopes } = useScopes();
  const hasPensionScope = hasScopes(["pension"], { userId });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: "Pension" }),
        settingsAction: () => setIsEnrolDrawerOpen(true),
        settingsHide: !hasPensionScope,
        showBack: true,
        backPath: generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId })
      }
    ),
    /* @__PURE__ */ jsx(
      PensionMe,
      {
        userId,
        employeePension,
        onEmployeeUpdate,
        isEnrolDrawerOpen,
        setIsEnrolDrawerOpen,
        loading
      }
    )
  ] });
};
