"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@v2/components/typography/typography.component";
import { Link } from "react-router-dom";
import { LOGIN_ROUTE } from "@/lib/routes";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const AuthSignupThanks = () => /* @__PURE__ */ jsxs(AuthLayout, { title: "Thank you for signing up with Zelt!", children: [
  /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mr: "4px" }, children: "Please check your inbox for your activation email" }),
  /* @__PURE__ */ jsx(Link, { style: __spreadProps(__spreadValues({}, themeFonts.caption), { whiteSpace: "nowrap" }), to: LOGIN_ROUTE, children: "or Sign In" })
] });
