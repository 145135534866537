"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { CompanyAPI } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const DeleteCompanyEntityDrawer = ({ entity, close }) => {
  const { polyglot } = usePolyglot();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showMessage] = useMessage();
  const deleteEntity = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsDeleting(true);
      yield CompanyAPI.deleteEntity(entity);
      close(true);
    } catch (error) {
      showMessage(
        `${polyglot.t("DeleteCompanyEntityDrawer.errorMessages.delete")}}: ${nestErrorMessage(error)}`,
        "error"
      );
      setIsDeleting(false);
    }
  }), [showMessage, polyglot, close, entity]);
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("DeleteCompanyEntityDrawer.deleteEntity") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeleteCompanyEntityDrawer.confirmDeletion", { entityName: entity.legalName }) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => close(false),
          disabled: isDeleting,
          fullWidth: true,
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.delete"),
          loading: isDeleting,
          onClick: deleteEntity,
          colorVariant: "danger",
          sizeVariant: "medium",
          fullWidth: true
        }
      )
    ] })
  ] });
};
