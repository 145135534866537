"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
import { GlobalStateActions } from "@/GlobalState";
import {
  PaymentStatus
} from "@/v2/feature/payments/payments.dto";
export class PaymentsEndpoints {
  static getTransactionStatus(transactionId) {
    return {
      url: `/apiv2/payments/transactions/${transactionId}/status`
    };
  }
  static getTransactionPaymentRequest(transactionId) {
    if (!transactionId) return { url: "" };
    return {
      url: `/apiv2/payments/transactions/${transactionId}/payment-request`
    };
  }
  static getPaymentsByTransactionId(transactionId) {
    return {
      url: `/apiv2/payments/transactions/${transactionId}/group-payments`
    };
  }
  static findByCompanyId({ pageIndex, pageSize }, sortField, filter = PaymentStatus.All, search = "") {
    var _a, _b;
    const sort = (_a = sortField == null ? void 0 : sortField.id) != null ? _a : "";
    const sortDesc = (_b = sortField == null ? void 0 : sortField.desc) != null ? _b : "";
    const params = new URLSearchParams({
      page: `${pageIndex}`,
      pageSize: `${pageSize}`,
      sort,
      sortDesc: `${sortDesc}`,
      filter,
      search
    });
    return {
      url: `/apiv2/payments?${params.toString()}`
    };
  }
  static teamFindByCompanyId({ pageIndex, pageSize }, sortField, filter = PaymentStatus.All, search = "") {
    var _a, _b;
    const sort = (_a = sortField == null ? void 0 : sortField.id) != null ? _a : "";
    const sortDesc = (_b = sortField == null ? void 0 : sortField.desc) != null ? _b : "";
    const params = new URLSearchParams({
      page: `${pageIndex}`,
      pageSize: `${pageSize}`,
      sort,
      sortDesc: `${sortDesc}`,
      filter,
      search
    });
    return {
      url: `/apiv2/payments/team?${params.toString()}`
    };
  }
  static getPaymentInstitution() {
    return {
      url: "/apiv2/payments/institutions"
    };
  }
}
export class PaymentsAPI {
  static createPayment(payment) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payments/single`, { payment })).data;
    });
  }
  static updatePayment(payment, paymentId) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/payments/single/${paymentId}`, { payment });
    });
  }
  static initSinglePaymentRequest(institutionId, payment) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payments/single/init`, { institutionId, payment })).data;
    });
  }
  static executeSinglePaymentRequest(institutionId, paymentId, executionDateTime, payerDetails) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payments/single/${paymentId}`, {
        institutionId,
        executionDateTime,
        payerDetails
      })).data;
    });
  }
  static executeBulkPaymentRequest(institutionId, paymentIds, executionDateTime, payerDetails, groupPayments, groupPaymentsReference) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/payments/bulk?groupPayments=${groupPayments}&reference=${groupPaymentsReference}`, {
        institutionId,
        paymentIds,
        executionDateTime,
        payerDetails
      })).data;
    });
  }
  static deletePayments(paymentIds) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payments/bulk/delete`, { paymentIds });
    });
  }
  static rejectUnderlyingExpense(paymentId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payments/single/reject-underlying-expense/${paymentId}`);
    });
  }
  static voidUnderlyingInvoice(paymentId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payments/single/void-underlying-invoice/${paymentId}`);
    });
  }
  static markAsPaid(paymentIds) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payments/bulk/mark-paid`, { paymentIds });
    });
  }
  static getUserReceivablesAccountPayments(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payments/account-statements/receivables/users/${userId}`)).data;
    });
  }
  static getAlerts(dispatch = void 0) {
    return __async(this, null, function* () {
      const paymentsAlerts = (yield axios.get(`/apiv2/payments/alerts`)).data;
      if (dispatch) {
        dispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: {
            payments: paymentsAlerts
          }
        });
      }
      return paymentsAlerts;
    });
  }
  static refreshPaymentTransactionByPaymentId(paymentId, userId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/payments/${paymentId}/refresh-transaction`, { userId });
    });
  }
}
