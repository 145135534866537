"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsEmptyState = ({ setIsOpen }) => {
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: /* @__PURE__ */ jsx(
    ButtonComponent,
    {
      sizeVariant: "small",
      colorVariant: "primary",
      onClick: () => setIsOpen(true),
      "aria-label": "Add Participants",
      children: "Add participants"
    }
  ) });
};
