"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { JsonViewer } from "@textea/json-viewer";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { toTitleCase } from "@/v2/util/string.util";
export const AuditReportEntryDetailDrawer = ({
  isOpen,
  setIsOpen,
  entry
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, widthPercentage: 50, children: entry ? /* @__PURE__ */ jsxs(Box, { children: [
  /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Audit entry - Payload" }),
  /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: [
    /* @__PURE__ */ jsx("i", { children: `${entry.action.toUpperCase()}` }),
    " - ",
    /* @__PURE__ */ jsx("b", { children: `${toTitleCase(entry.domain)}` })
  ] }),
  /* @__PURE__ */ jsx(Box, { sx: { mt: "20px" }, children: /* @__PURE__ */ jsx(JsonViewer, { value: entry == null ? void 0 : entry.entityPayload, theme: "dark", rootName: "payload" }) })
] }) : /* @__PURE__ */ jsx(Fragment, {}) });
