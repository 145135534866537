"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { ListItemIcon } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserAvatarMenu } from "@/v2/feature/user/components/user-avatar-menu.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { NavItemStyle } from "@/v2/styles/list-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserProfileIcon = () => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const [avatarMenuAnchor, setAvatarMenuAnchor] = useState(null);
  const [isHover, setIsHover] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      NavItemStyle,
      {
        onClick: (e) => setAvatarMenuAnchor(e.currentTarget),
        onMouseEnter: () => setIsHover(true),
        onMouseLeave: () => setIsHover(false),
        sx: {
          gap: spacing.g5
        },
        children: [
          /* @__PURE__ */ jsx(
            ListItemIcon,
            {
              sx: {
                display: "flex",
                justifyContent: "center",
                transition: "transform 0.2s ease-in-out",
                transform: isHover ? "scale(1.2)" : "none"
              },
              children: /* @__PURE__ */ jsx(UserAvatar, { userId: globalState.user.userId, size: "xxsmall" })
            }
          ),
          /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "captionSmall",
              sx: {
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "inherit",
                textAlign: "center"
              },
              children: polyglot.t("UserProfileIcon.me")
            }
          )
        ]
      },
      "user"
    ),
    /* @__PURE__ */ jsx(
      UserAvatarMenu,
      {
        anchorEl: avatarMenuAnchor,
        open: !!avatarMenuAnchor,
        onClose: () => setAvatarMenuAnchor(null),
        userId: globalState.user.userId
      }
    )
  ] });
};
