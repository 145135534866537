"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { yupToFormErrors } from "formik";
export function getFormikValidationHandler(handleValidation) {
  return (values) => {
    const yupErrors = handleValidation(values);
    if (yupErrors) {
      const errors = yupToFormErrors(yupErrors);
      return errors;
    }
  };
}
export function mapSchemaFieldsToFormikObject(fields) {
  function getInitialFieldValue(schema) {
    var _a;
    if (schema.type === "object") {
      return getInitialObjectValue(schema.fields);
    }
    return (_a = {
      number: 0,
      string: "",
      boolean: false,
      array: []
    }[schema.type]) != null ? _a : "";
  }
  function getInitialObjectValue(fields2) {
    const obj = {};
    for (const f in fields2) {
      obj[f] = getInitialFieldValue(fields2[f]);
    }
    return obj;
  }
  const values = fields.reduce((acc, curr) => {
    return __spreadProps(__spreadValues({}, acc), {
      [curr.name]: getInitialFieldValue(curr.schema)
    });
  }, {});
  return values;
}
