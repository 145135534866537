"use strict";
import { jsx } from "react/jsx-runtime";
import { FiltersEndpoints } from "@v2/feature/filters/filters.api";
import { TimeReportBalancesResults } from "@v2/feature/reports/features/create-report/time/time-report-balances-result.component";
import { TimeReportRequestsResults } from "@v2/feature/reports/features/create-report/time/time-report-requests-results.component";
import { ReportTypeTimeSelection } from "@v2/feature/reports/reports.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const TimeReportResults = ({
  formData,
  setFormData
}) => {
  var _a;
  const { data: filters } = useApiClient(FiltersEndpoints.geTimeFiltersOfReports(), {
    suspense: false
  });
  return formData.type === ReportTypeTimeSelection.Balances ? /* @__PURE__ */ jsx(TimeReportBalancesResults, { formData, setFormData, filters: (_a = filters == null ? void 0 : filters.peopleFilters) != null ? _a : {} }) : formData.type === ReportTypeTimeSelection.Requests ? /* @__PURE__ */ jsx(
    TimeReportRequestsResults,
    {
      formData,
      setFormData,
      filters: filters != null ? filters : {
        peopleFilters: {},
        timeFilters: {}
      }
    }
  ) : null;
};
