"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { buttonBoxSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const HelperAbsenceEditDrawer = ({
  isOpen,
  setIsOpen,
  absence,
  refreshAbsences,
  closePage
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const initialValues = useMemo(
    () => {
      var _a;
      return {
        totalLength: absence.totalLength,
        workdayCount: (_a = absence.workdayCount) != null ? _a : 0,
        year2021: absence.year2021,
        year2022: absence.year2022,
        year2023: absence.year2023,
        year2024: absence.year2024,
        year2025: absence.year2025,
        year2026: absence.year2026,
        potentialCarryOver: absence.potentialCarryOver
      };
    },
    [absence]
  );
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      totalLength: Yup.number().required("Field required"),
      workdayCount: Yup.number().integer().required("Field required"),
      year2021: Yup.number().required("Field required"),
      year2022: Yup.number().required("Field required"),
      year2023: Yup.number().required("Field required"),
      year2024: Yup.number().required("Field required"),
      year2025: Yup.number().required("Field required"),
      year2026: Yup.number().required("Field required"),
      potentialCarryOver: Yup.number().required("Field required")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        const toUpdate = {
          totalLength: Number(values.totalLength),
          workdayCount: Number(values.workdayCount),
          year2021: Number(values.year2021),
          year2022: Number(values.year2022),
          year2023: Number(values.year2023),
          year2024: Number(values.year2024),
          potentialCarryOver: Number(values.potentialCarryOver)
        };
        yield AbsenceAPI.updateAbsenceByIdAsSuperAdmin(absence.absenceId, toUpdate);
        showMessage("Absence updated.", "success");
        yield refreshAbsences();
        closePage();
      } catch (error) {
        showMessage(`Something went wrong ${nestErrorMessage(error)}`, "error");
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Edit absence" }),
    /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
      "AbsenceId: ",
      absence.absenceId
    ] }),
    /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
      "UserId: ",
      absence.userId
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "totalLength",
        label: "totalLength",
        value: formik.values.totalLength,
        onChange: formik.handleChange,
        error: formik.touched.totalLength && Boolean(formik.errors.totalLength),
        helperText: formik.touched.totalLength && formik.errors.totalLength
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "workdayCount",
        label: "workdayCount",
        value: formik.values.workdayCount,
        onChange: formik.handleChange,
        error: formik.touched.workdayCount && Boolean(formik.errors.workdayCount),
        helperText: formik.touched.workdayCount && formik.errors.workdayCount
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "year2021",
        label: "year2021",
        value: formik.values.year2021,
        onChange: formik.handleChange,
        error: formik.touched.year2021 && Boolean(formik.errors.year2021),
        helperText: formik.touched.year2021 && formik.errors.year2021
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "year2022",
        label: "year2022",
        value: formik.values.year2022,
        onChange: formik.handleChange,
        error: formik.touched.year2022 && Boolean(formik.errors.year2022),
        helperText: formik.touched.year2022 && formik.errors.year2022
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "year2023",
        label: "year2023",
        value: formik.values.year2023,
        onChange: formik.handleChange,
        error: formik.touched.year2023 && Boolean(formik.errors.year2023),
        helperText: formik.touched.year2023 && formik.errors.year2023
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "year2024",
        label: "year2024",
        value: formik.values.year2024,
        onChange: formik.handleChange,
        error: formik.touched.year2024 && Boolean(formik.errors.year2024),
        helperText: formik.touched.year2024 && formik.errors.year2024
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "year2025",
        label: "year2025",
        value: formik.values.year2025,
        onChange: formik.handleChange,
        error: formik.touched.year2025 && Boolean(formik.errors.year2025),
        helperText: formik.touched.year2025 && formik.errors.year2025
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "year2026",
        label: "year2026",
        value: formik.values.year2026,
        onChange: formik.handleChange,
        error: formik.touched.year2026 && Boolean(formik.errors.year2026),
        helperText: formik.touched.year2026 && formik.errors.year2026
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "potentialCarryOver",
        label: "potentialCarryOver",
        value: formik.values.potentialCarryOver,
        onChange: formik.handleChange,
        error: formik.touched.potentialCarryOver && Boolean(formik.errors.potentialCarryOver),
        helperText: formik.touched.potentialCarryOver && formik.errors.potentialCarryOver
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: "10px" }), children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading: isSubmitting, fullWidth: true, sizeVariant: "medium", colorVariant: "primary" }) })
  ] }) }) });
};
