"use strict";
import { Node } from "@tiptap/core";
import { Fragment } from "@tiptap/pm/model";
import { TextSelection } from "@tiptap/pm/state";
import { Plugin } from "prosemirror-state";
import { v4 } from "uuid";
import {
  SMART_FIELD_MAP_BY_LABEL,
  SMART_FIELD_MAP_BY_VALUE,
  SMART_FIELDS
} from "@/v2/feature/templates/components/editor";
export const smartFieldRegex = /\{\{(\w+)\}\}/;
export const SMART_FIELD_CUSTOM_MARKER = "smart-field-custom-marker";
export const updateStylingOnCompletedFields = (mode, html) => {
  if (!mode.includes("readonly")) {
    return html;
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const markers = tempDiv.querySelectorAll(`.${SMART_FIELD_CUSTOM_MARKER}`);
  markers.forEach((marker) => {
    if (!(marker == null ? void 0 : marker.textContent)) return;
    const value = SMART_FIELD_MAP_BY_LABEL.get(marker.textContent);
    if (value) return;
    marker.classList.remove(SMART_FIELD_CUSTOM_MARKER);
    if (SMART_FIELD_MAP_BY_VALUE.get(marker == null ? void 0 : marker.textContent)) {
      marker.classList.add(`${SMART_FIELD_CUSTOM_MARKER}-sign`);
    } else {
      marker.classList.add(`${SMART_FIELD_CUSTOM_MARKER}-completed`);
    }
  });
  return tempDiv.innerHTML;
};
export const updateSignature = (mode, signature, html) => {
  if (!mode.includes("readonly")) {
    return html;
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const markers = tempDiv.querySelectorAll(`.${SMART_FIELD_CUSTOM_MARKER}-sign`);
  markers.forEach((marker) => {
    marker.classList.add(`${SMART_FIELD_CUSTOM_MARKER}-signing`);
    if (!(marker == null ? void 0 : marker.textContent)) return;
    marker.textContent = signature;
  });
  const separators = tempDiv.querySelectorAll(".ProseMirror-separator");
  separators.forEach((element) => {
    element.remove();
  });
  const trailingBreak = tempDiv.querySelectorAll(".ProseMirror-trailingBreak");
  trailingBreak.forEach((element) => {
    element.remove();
  });
  const emptyImages = tempDiv.querySelectorAll('img[alt=""]');
  emptyImages.forEach((element) => {
    element.remove();
  });
  return tempDiv.innerHTML;
};
export const generateSmartNodeWrapperElements = (mode, newState, label, classNameOverRide, id) => {
  const customMark = newState.schema.marks.customMark.create({
    class: classNameOverRide ? classNameOverRide : SMART_FIELD_CUSTOM_MARKER,
    contenteditable: false,
    draggable: mode === "readonly" ? false : true,
    id: id ? id : v4()
  });
  if (!label) return;
  const textNode = newState.schema.text(label);
  const img = newState.schema.nodes.image.create({
    class: "ProseMirror-separator",
    alt: ""
  });
  const nodes = [img, textNode.mark([customMark]), img];
  return Fragment.fromArray(nodes);
};
export const SmartFieldNode = Node.create({
  name: "placeholderReplace",
  group: "block",
  content: "inline*",
  draggable: true,
  allowGapCursor: true,
  addOptions() {
    return {
      handleClick: () => {
      },
      signer: "",
      mode: ""
    };
  },
  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const { state, dispatch } = editor.view;
        const { selection } = state;
        const { empty, from } = selection;
        if (!empty) return false;
        const nodeBefore = state.doc.nodeAt(from - 1);
        if (!nodeBefore) return false;
        const mark = nodeBefore.marks.find((mark2) => {
          var _a, _b, _c;
          return (_c = (_b = (_a = mark2 == null ? void 0 : mark2.attrs) == null ? void 0 : _a.class) == null ? void 0 : _b.includes) == null ? void 0 : _c.call(_b, SMART_FIELD_CUSTOM_MARKER);
        });
        if (mark) {
          const spanStartPos = from - nodeBefore.nodeSize;
          const spanEndPos = from;
          dispatch(state.tr.delete(spanStartPos, spanEndPos));
          return true;
        }
        return false;
      },
      Enter: ({ editor }) => {
        const { state, view } = editor;
        const { selection } = state;
        const { $from, $to } = selection;
        const nodeBefore = $from.nodeBefore;
        if (!nodeBefore) return false;
        const mark = nodeBefore.marks.find((mark2) => {
          var _a, _b, _c;
          return (_c = (_b = (_a = mark2 == null ? void 0 : mark2.attrs) == null ? void 0 : _a.class) == null ? void 0 : _b.includes) == null ? void 0 : _c.call(_b, SMART_FIELD_CUSTOM_MARKER);
        });
        if (mark) {
          const transaction = state.tr.split($to.pos);
          const newSelection = TextSelection.create(transaction.doc, $to.pos + 1);
          transaction.setSelection(newSelection);
          view.dispatch(transaction);
          return true;
        }
        return false;
      }
    };
  },
  addProseMirrorPlugins() {
    const { handleClick, signer, mode } = this.options;
    return [
      new Plugin({
        appendTransaction(transactions, oldState, newState) {
          let tr = newState.tr;
          let replaced = false;
          let updates = [];
          newState.doc.descendants((node, pos) => {
            if (node.isText) {
              const { text } = node;
              const match = text == null ? void 0 : text.match(smartFieldRegex);
              const signedMark = node.marks.findIndex(
                (m) => {
                  var _a, _b, _c, _d, _e, _f;
                  return ((_c = (_b = (_a = m == null ? void 0 : m.attrs) == null ? void 0 : _a.class) == null ? void 0 : _b.includes) == null ? void 0 : _c.call(_b, `${SMART_FIELD_CUSTOM_MARKER}-sign`)) || ((_f = (_e = (_d = m == null ? void 0 : m.attrs) == null ? void 0 : _d.class) == null ? void 0 : _e.includes) == null ? void 0 : _f.call(_e, `${SMART_FIELD_CUSTOM_MARKER}-signing`));
                }
              );
              if (match) {
                SMART_FIELDS.forEach((smartField) => {
                  if (smartField.value === match[0]) {
                    const nodeDetails = getSmartNodeClassAndTextOverrides(signer, mode, smartField.label);
                    const fragment = generateSmartNodeWrapperElements(
                      mode,
                      newState,
                      nodeDetails.text,
                      signedMark !== -1 ? nodeDetails.class : void 0,
                      smartField.value
                    );
                    if (!fragment) return;
                    updates.push({
                      from: pos + ((match == null ? void 0 : match.index) || 0),
                      to: pos + ((match == null ? void 0 : match.index) || 0) + match[0].length,
                      element: fragment
                    });
                  }
                });
                replaced = true;
              }
            }
          });
          updates.reverse().forEach(({ from, to, element }) => {
            tr.replaceWith(from, to, element);
          });
          return replaced ? tr : null;
        }
      }),
      new Plugin({
        props: {
          handleClick(view, pos, _) {
            var _a, _b;
            const { state } = view;
            const node = state.doc.nodeAt(pos);
            if (((_b = (_a = node == null ? void 0 : node.marks) == null ? void 0 : _a.find) == null ? void 0 : _b.call(_a, (m) => {
              var _a2, _b2, _c;
              return (_c = (_b2 = (_a2 = m == null ? void 0 : m.attrs) == null ? void 0 : _a2.class) == null ? void 0 : _b2.includes) == null ? void 0 : _c.call(_b2, `${SMART_FIELD_CUSTOM_MARKER}-sign`);
            })) !== void 0) {
              handleClick("sign");
              return true;
            }
            return false;
          }
        }
      })
    ];
  }
});
const getSmartNodeClassAndTextOverrides = (signer, mode, text) => {
  if (mode === "readonly") {
    return {
      class: `${SMART_FIELD_CUSTOM_MARKER}-readonly`,
      text
    };
  }
  return {
    class: `${SMART_FIELD_CUSTOM_MARKER}`,
    text
  };
};
