"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box, styled } from "@mui/material";
import { Color } from "@tiptap/extension-color";
import { ListItem } from "@tiptap/extension-list-item";
import { TextStyle } from "@tiptap/extension-text-style";
import { Underline } from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { ReactComponent as BlockQuote } from "@/images/fields/BlockQuote.svg";
import { ReactComponent as Bold } from "@/images/fields/Bold.svg";
import { ReactComponent as Bullets } from "@/images/fields/Bullets.svg";
import { ReactComponent as CodeBlock } from "@/images/fields/CodeBlock.svg";
import { ReactComponent as Italic } from "@/images/fields/Italic.svg";
import { ReactComponent as Numbers } from "@/images/fields/Numbers.svg";
import { ReactComponent as UnderlineSVG } from "@/images/fields/Underline.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/scss/components/rich-text-field.scss";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const StyledEditorContent = styled(EditorContent)(() => ({
  background: themeColors.Background,
  padding: "0px",
  margin: "0px",
  "& .ProseMirror-trailingBreak": {
    padding: "0px",
    margin: "0px"
  },
  "& p": {
    marginBlockStart: "0px !important",
    marginBlockEnd: "0px !important"
  }
}));
const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g3, flexWrap: "wrap" }, children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        style: {},
        type: "button",
        onClick: () => editor.chain().focus().toggleBold().run(),
        disabled: !editor.can().chain().focus().toggleBold().run(),
        className: editor.isActive("bold") ? "is-active" : "",
        children: /* @__PURE__ */ jsx(Bold, __spreadValues({}, iconSize))
      }
    ),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        type: "button",
        onClick: () => editor.chain().focus().toggleItalic().run(),
        disabled: !editor.can().chain().focus().toggleItalic().run(),
        className: editor.isActive("italic") ? "is-active" : "",
        children: /* @__PURE__ */ jsx(Italic, __spreadValues({}, iconSize))
      }
    ),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        type: "button",
        onClick: () => editor.chain().focus().toggleUnderline().run(),
        disabled: !editor.can().chain().focus().toggleUnderline().run(),
        className: editor.isActive("underline") ? "is-active" : "",
        children: /* @__PURE__ */ jsx(UnderlineSVG, __spreadValues({}, iconSize))
      }
    ),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        type: "button",
        onClick: () => editor.chain().focus().toggleBulletList().run(),
        className: editor.isActive("bulletList") ? "is-active" : "",
        children: /* @__PURE__ */ jsx(Bullets, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.TableHover }))
      }
    ),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        type: "button",
        onClick: () => editor.chain().focus().toggleOrderedList().run(),
        className: editor.isActive("orderedList") ? "is-active" : "",
        children: /* @__PURE__ */ jsx(Numbers, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey }))
      }
    ),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        type: "button",
        onClick: () => editor.chain().focus().toggleBlockquote().run(),
        className: editor.isActive("blockquote") ? "is-active" : "",
        children: /* @__PURE__ */ jsx(BlockQuote, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey }))
      }
    ),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        type: "button",
        onClick: () => editor.chain().focus().toggleCodeBlock().run(),
        className: editor.isActive("codeBlock") ? "is-active" : "",
        children: /* @__PURE__ */ jsx(CodeBlock, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
      }
    )
  ] });
};
const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false
    }
  }),
  Underline
];
const generatePlaceholderRows = (value, minRows = 2) => {
  const row = "<p></p>";
  if (!value) {
    return new Array(minRows).fill(row).join("");
  }
  return value + new Array(1).fill(row).join("");
};
export const RichTextField = ({
  value,
  onChange,
  label,
  error,
  helperText,
  disabled = false
}) => {
  const initialContent = generatePlaceholderRows(value);
  const editor = useEditor({
    extensions,
    content: initialContent,
    editable: !disabled,
    onUpdate: ({ editor: editor2 }) => {
      const updatedValue = editor2.getHTML() === "<p></p>" ? null : editor2.getHTML();
      if (onChange) {
        onChange(updatedValue);
      }
    }
  });
  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(generatePlaceholderRows(value), false);
    }
  }, [value, editor]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
    label && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: label }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadProps(__spreadValues({
          backgroundColor: themeColors.Background,
          borderRadius: radius.br10,
          padding: spacing.p10
        }, themeFonts.title4), {
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? 0.5 : 1
        }),
        children: [
          editor && /* @__PURE__ */ jsx(MenuBar, { editor }),
          /* @__PURE__ */ jsx(StyledEditorContent, { editor })
        ]
      }
    ),
    helperText && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: error ? themeColors.darkRed : themeColors.DarkGrey }, children: helperText })
  ] });
};
const Divider = () => {
  return /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx("div", { style: { width: "0.5px", backgroundColor: themeColors.DarkGrey, height: "16px", verticalAlign: "middle" } }) });
};
