"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DevicePossessionType, ReturnDeviceReason } from "@/v2/feature/device/device.interface";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { formatAddress } from "@/v2/util/user-data.util";
export const ChangeOwnerZeltStorageForm = ({
  devicePossession,
  closePage
}) => {
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const [senderAddress, setSenderAddress] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initiateDeviceReturn = () => __async(void 0, null, function* () {
    let isReturnInitiated = false;
    setIsSubmitting(true);
    try {
      yield DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        ReturnDeviceReason.SendToZeltStorage,
        senderAddress,
        "Zelt HQ",
        null,
        void 0
      );
      showMessage("Successfully requested a return", "success");
      isReturnInitiated = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      yield closePage();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not initiate return device flow. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setIsSubmitting(false);
    }
  });
  const fetchUserAddress = useCallback(
    (assignedUserId) => __async(void 0, null, function* () {
      try {
        const userAddress = yield UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      "We will reach out to you to arrange a pickup shortly. Please ask the current owner to remove all personal files. You can initiate a wipe of this device later.",
      " "
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        colorVariant: "primary",
        sizeVariant: "medium",
        fullWidth: true,
        loading: isSubmitting,
        onClick: () => __async(void 0, null, function* () {
          return yield initiateDeviceReturn();
        })
      }
    ) })
  ] });
};
