"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { AttendanceScheduleRouter } from "@v2/feature/attendance/attendance-schedule.router";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { AttendanceCompanySettingsAttendanceSchedulesPage } from "@v2/feature/attendance/company/pages/attendance-company-settings/attendance-company-settings-attendance-schedules.page";
import { AttendanceCompanySettingsJobsPage } from "@v2/feature/attendance/company/pages/attendance-company-settings/attendance-company-settings-jobs.page";
import { AttendanceCompanySettingsMultipliersPage } from "@v2/feature/attendance/company/pages/attendance-company-settings/attendance-company-settings-multipliers.page";
import { AttendanceCompanySettingsOtherPage } from "@v2/feature/attendance/company/pages/attendance-company-settings/attendance-company-settings-other.page";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Redirect, Switch } from "react-router-dom";
import { SiteEndpoints } from "@/api-client/site.api";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import {
  SETTINGS_ATTENDANCE_JOBS_OVERVIEW_ROUTE,
  SETTINGS_ATTENDANCE_MULTIPLIERS_OVERVIEW_ROUTE,
  SETTINGS_ATTENDANCE_OTHER_ROUTE,
  SETTINGS_ATTENDANCE_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE,
  SETTINGS_ATTENDANCE_SCHEDULE_ROUTE
} from "@/lib/routes";
export const AttendanceSettingsRouter = () => {
  const { data: attendanceSchedules, mutate: refreshAttendanceSchedules } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(), {
    suspense: false
  });
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: attendanceTypes, mutate: refreshTypes, isLoading } = useApiClient(
    AttendanceEndpoints.getCompanyAttendanceTypes(),
    {
      suspense: false
    }
  );
  const {
    data: attendancePayMultipliers,
    mutate: refreshPayMultipliers,
    isLoading: isLoadingMultipliers
  } = useApiClient(AttendanceEndpoints.getCompanyAttendancePayMultipliers(), {
    suspense: false
  });
  const refreshApprovalRules = useCallback(() => __async(void 0, null, function* () {
    if (refreshRules) yield refreshRules();
  }), [refreshRules]);
  const refreshSchedules = useCallback(() => __async(void 0, null, function* () {
    if (refreshAttendanceSchedules) yield refreshAttendanceSchedules();
  }), [refreshAttendanceSchedules]);
  const refreshJobs = useCallback(() => __async(void 0, null, function* () {
    if (refreshTypes) yield refreshTypes();
  }), [refreshTypes]);
  const refreshMultipliers = useCallback(() => __async(void 0, null, function* () {
    if (refreshPayMultipliers) yield refreshPayMultipliers();
  }), [refreshPayMultipliers]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_ROUTE, children: /* @__PURE__ */ jsx(
      AttendanceScheduleRouter,
      {
        refreshSchedules,
        sites: sites != null ? sites : [],
        approvalRules: approvalRules != null ? approvalRules : [],
        refreshApprovalRules,
        jobTypes: attendanceTypes != null ? attendanceTypes : [],
        attendancePayMultipliers: attendancePayMultipliers != null ? attendancePayMultipliers : []
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
      AttendanceCompanySettingsAttendanceSchedulesPage,
      {
        attendanceSchedules: attendanceSchedules != null ? attendanceSchedules : [],
        refreshSchedules
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_JOBS_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
      AttendanceCompanySettingsJobsPage,
      {
        jobTypes: attendanceTypes != null ? attendanceTypes : [],
        isLoading: isLoading || isLoadingMultipliers,
        refreshJobs,
        attendancePayMultipliers: attendancePayMultipliers != null ? attendancePayMultipliers : []
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_MULTIPLIERS_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
      AttendanceCompanySettingsMultipliersPage,
      {
        isLoading: isLoading || isLoadingMultipliers,
        refreshMultipliers,
        attendancePayMultipliers: attendancePayMultipliers != null ? attendancePayMultipliers : []
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:all"], path: SETTINGS_ATTENDANCE_OTHER_ROUTE, exact: true, children: /* @__PURE__ */ jsx(AttendanceCompanySettingsOtherPage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_ATTENDANCE_ROUTE, to: SETTINGS_ATTENDANCE_SCHEDULE_OVERVIEW_ROUTE })
  ] });
};
