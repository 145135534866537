"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { spacing } from "@v2/styles/spacing.styles";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
const getPercentageColor = (color) => {
  return color && ["blue", "red", "green"].includes(color) ? color : "blue";
};
export const GrowthProgressLineChart = ({
  label,
  labelVariant = "caption",
  labelColor = "DarkGrey",
  labelPlacement = "start",
  percentages,
  percentJustifyContent = "start",
  percentagesGap = "4px",
  labelWidth = "50px"
}) => {
  const labelText = useMemo(() => {
    if (!label) return null;
    return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: labelWidth, justifyContent: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: labelVariant, color: labelColor, children: label }) });
  }, [label, labelVariant, labelColor, labelWidth]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        alignItems: "center",
        flexDirection: ["start", "end"].includes(labelPlacement) ? "row" : "column",
        gap: spacing.g8,
        width: "100%"
      },
      children: [
        ["start", "top"].includes(labelPlacement) && labelText,
        /* @__PURE__ */ jsx(
          "div",
          {
            className: "progress-bar",
            style: {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: percentJustifyContent,
              gap: percentagesGap
            },
            children: percentages.map((percentage, index) => {
              var _a;
              return /* @__PURE__ */ jsx(
                StyledTooltip,
                {
                  disabled: !percentage.showTooltip,
                  title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: (_a = percentage.label) != null ? _a : "" }),
                    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: `${percentage.value}%` })
                  ] }),
                  placement: "top",
                  children: /* @__PURE__ */ jsx(
                    "div",
                    {
                      className: `progress-bar-fill progress-bar-${getPercentageColor(percentage.color)}`,
                      style: {
                        width: `${Math.max(percentage.value, 0)}%`
                      }
                    },
                    index
                  )
                },
                index
              );
            })
          }
        ),
        ["end", "bottom"].includes(labelPlacement) && labelText
      ]
    }
  );
};
