"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/scss/components/progress-bar.scss";
import { spacing } from "@/v2/styles/spacing.styles";
export const ProgressBar = ({ progress, label, showRed }) => {
  const percentage = Math.min(100, Math.max(0, progress));
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8, width: "100%" }, children: [
    /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", alignItems: "center", minHeight: "6px" }, children: /* @__PURE__ */ jsx("div", { className: `progress-bar ${showRed ? "progress-border-red" : "progress-no-border"}`, children: /* @__PURE__ */ jsx(
      "div",
      {
        className: `progress-bar-fill ${showRed ? "progress-bar-red" : "progress-bar-blue"}`,
        style: { width: `${percentage}%` }
      }
    ) }) }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: label })
  ] });
};
