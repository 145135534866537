"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { UserRoleAPI } from "@/api-client/user-role.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import {
  AutocompleteComponent
} from "@/v2/components/forms/autocomplete.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DepartmentFormModal } from "@/v2/feature/department/department-settings/components/department-form-modal.component";
import { JobPositionAddEditFormDrawer } from "@/v2/feature/job-position/job-position-settings/features/components/job-position-setting-add-edit-form-drawer.component";
import { SiteFormModal } from "@/v2/feature/site/site-settings/features/components/site-form-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const NewEmployeeRoleSection = ({
  role,
  departments,
  sites,
  jobPositions,
  onNext,
  startDate,
  sx,
  userId,
  refreshSites,
  refreshDept,
  refreshJobPosition,
  defaultJobPosition
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const scopesContext = { userId: user.userId };
  const [departmentDrawerOpen, setDepartmentDrawerOpen] = useState(false);
  const [siteDrawerOpen, setSiteDrawerOpen] = useState(false);
  const [jobDrawerOpen, setJobDrawerOpen] = useState(false);
  const NewEmployeeRoleSchema = useMemo(
    () => Yup.object().shape({
      jobPositionId: Yup.string().trim().required(polyglot.t("NewEmployeeRoleSection.errorMessages.jobTitleRequired")),
      departmentId: Yup.number().optional().nullable().integer(),
      siteId: Yup.number().optional().nullable().integer(),
      managerId: Yup.number().optional().nullable().integer()
    }),
    [polyglot]
  );
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = role ? "edit" : "add";
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const users = useMemo(() => {
    return nonTerminatedCachedUsers.filter((u) => u.userId !== userId).map((u) => __spreadValues({
      label: u.displayName,
      value: u.userId
    }, u));
  }, [nonTerminatedCachedUsers, userId]);
  const formik = useFormik({
    initialValues: __spreadValues({
      effectiveDate: new LocalDate(startDate).toDateString(),
      departmentId: null,
      siteId: null,
      managerId: null,
      jobPositionId: null,
      customUpdates: []
    }, role),
    validateOnMount: true,
    validationSchema: NewEmployeeRoleSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        if ("id" in values) {
          yield UserRoleAPI.patch(userId, values.id, values);
        } else {
          yield UserRoleAPI.create(userId, values);
          yield OnboardingAPI.assignTemplateTasksToUser(userId);
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeRoleSection.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  const currentJobPositionValue = useMemo(() => {
    return jobPositions.find((option) => option.value === Number(formik.values.jobPositionId));
  }, [formik.values.jobPositionId, jobPositions]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("NewEmployeeRoleSection.role") }),
      /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "jobPositionId",
          label: polyglot.t("RoleForm.jobTitle"),
          options: jobPositions,
          value: currentJobPositionValue,
          compareValue: formik.values.jobPositionId,
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("jobPositionId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          helperText: hasSubmitted && formik.errors.jobPositionId,
          error: hasSubmitted && !!formik.errors.jobPositionId,
          editList: {
            handler: () => setJobDrawerOpen(true),
            isHidden: false
          }
        }
      ),
      /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "departmentId",
          label: polyglot.t("NewEmployeeRoleSection.departmentId"),
          options: departments,
          value: departments.find(({ value }) => value === formik.values.departmentId),
          compareValue: (_a = formik.values.departmentId) != null ? _a : "",
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("departmentId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          helperText: hasSubmitted && formik.errors.departmentId,
          error: hasSubmitted && !!formik.errors.departmentId,
          editList: {
            handler: () => setDepartmentDrawerOpen(true),
            isHidden: !checkScopes(user, ["company.settings:all"], scopesContext)
          }
        }
      ),
      /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "siteId",
          label: polyglot.t("NewEmployeeRoleSection.siteId"),
          options: sites,
          value: sites.find(({ value }) => value === formik.values.siteId),
          compareValue: (_b = formik.values.siteId) != null ? _b : "",
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("siteId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          helperText: hasSubmitted && formik.errors.siteId,
          error: hasSubmitted && !!formik.errors.siteId,
          editList: {
            handler: () => setSiteDrawerOpen(true),
            isHidden: !checkScopes(user, ["company.settings:all"], scopesContext)
          }
        }
      ),
      /* @__PURE__ */ jsx(
        SingleUserSelect,
        {
          name: "managerId",
          label: polyglot.t("NewEmployeeRoleSection.managerId"),
          options: users,
          disabled: saving,
          onChange: (_, selection) => {
            var _a2;
            formik.setFieldValue("managerId", (_a2 = selection == null ? void 0 : selection.value) != null ? _a2 : null);
          },
          value: formik.values.managerId,
          error: hasSubmitted && Boolean(formik.errors.managerId),
          helperText: hasSubmitted && formik.errors.managerId
        }
      ),
      /* @__PURE__ */ jsx(
        CustomFieldComponents,
        {
          values: formik.values.customUpdates,
          onChange: (values) => formik.setFieldValue("customUpdates", values),
          rowModalMode,
          formName: CustomProfileFormType.Role
        }
      ),
      formik.isValid && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.continue"),
          loading: saving,
          colorVariant: "primary",
          sizeVariant: "large",
          fullWidth: true
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      SiteFormModal,
      {
        isOpen: siteDrawerOpen,
        setIsOpen: setSiteDrawerOpen,
        selectedSite: void 0,
        refreshSites: () => __async(void 0, null, function* () {
          if (refreshSites) yield refreshSites();
        }),
        closePage: () => setSiteDrawerOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(
      DepartmentFormModal,
      {
        isOpen: departmentDrawerOpen,
        setIsOpen: setDepartmentDrawerOpen,
        selectedDepartment: void 0,
        refreshDepartments: () => __async(void 0, null, function* () {
          if (refreshDept) yield refreshDept();
        }),
        closePage: () => setDepartmentDrawerOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(
      JobPositionAddEditFormDrawer,
      {
        isOpen: jobDrawerOpen,
        setIsOpen: setJobDrawerOpen,
        jobPositionToEdit: defaultJobPosition,
        onClose: () => {
          setJobDrawerOpen(false);
        },
        refreshJobPositions: () => __async(void 0, null, function* () {
          if (refreshJobPosition) yield refreshJobPosition();
        })
      }
    )
  ] }) });
};
