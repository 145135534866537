"use strict";
export var Sentiments = /* @__PURE__ */ ((Sentiments2) => {
  Sentiments2["Negative"] = "Negative";
  Sentiments2["Neutral"] = "Neutral";
  Sentiments2["Positive"] = "Positive";
  return Sentiments2;
})(Sentiments || {});
export const SentimentsOptions = [
  { label: "Negative" /* Negative */, value: "Negative" /* Negative */ },
  { label: "Neutral" /* Neutral */, value: "Neutral" /* Neutral */ },
  { label: "Positive" /* Positive */, value: "Positive" /* Positive */ }
];
