"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
export const warningCaptionSmallStyledChipSx = __spreadProps(__spreadValues({
  padding: "4px 10px",
  minHeight: "30px",
  minWidth: "30px",
  border: themeColors.grey,
  borderRadius: radius.br15,
  boxSizing: "border-box"
}, themeFonts.captionSmall), {
  color: themeColors.RedDark,
  backgroundColor: themeColors.white,
  textTransform: "initial",
  cursor: "pointer",
  whiteSpace: "nowrap",
  "&.MuiButton-root:hover": {
    backgroundColor: themeColors.GreyHover
  }
});
export const normalCaptionSmallStyledChipSx = __spreadProps(__spreadValues({
  padding: "4px 10px",
  minHeight: "30px",
  minWidth: "30px",
  border: themeColors.grey,
  borderRadius: radius.br15,
  boxSizing: "border-box"
}, themeFonts.captionSmall), {
  color: themeColors.grey,
  backgroundColor: themeColors.white,
  textTransform: "initial",
  cursor: "pointer",
  whiteSpace: "nowrap",
  "&.MuiButton-root:hover": {
    backgroundColor: themeColors.GreyHover
  }
});
export const styledChipSx = __spreadProps(__spreadValues({
  padding: "5px 10px",
  minHeight: "30px",
  minWidth: "30px",
  border: "none",
  borderRadius: radius.br15,
  boxSizing: "border-box"
}, themeFonts.caption), {
  color: themeColors.DarkGrey,
  backgroundColor: themeColors.Background,
  textTransform: "initial",
  whiteSpace: "nowrap",
  "&.MuiButton-root:hover": {
    backgroundColor: themeColors.GreyHover
  }
});
export const styledChipSelectedSx = __spreadProps(__spreadValues({}, styledChipSx), {
  color: themeColors.white,
  backgroundColor: themeColors.black,
  "&.MuiButton-root:hover": {
    backgroundColor: themeColors.DarkGrey
  }
});
