"use strict";
import { jsx } from "react/jsx-runtime";
import { NumberCell } from "@v2/components/table/number-cell.component";
import { sortNumeric } from "@v2/components/table/table-sorting.util";
import {
  CustomBenefitAllowanceType,
  CustomBenefitType,
  UserCustomBenefitPaymentStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { LocalDate } from "@/v2/util/local-date";
export const isUsingOpeningBalance = (type) => !!type && [CustomBenefitType.Allowance, CustomBenefitType.Loan].includes(type);
export const isAllowanceBenefit = (type) => type === CustomBenefitType.Allowance;
export const isLoanBenefit = (type) => type === CustomBenefitType.Loan;
export const isRecurringBenefit = (type) => type === CustomBenefitType.Recurring;
export const calculatePaidAmount = (benefitPayments, benefitType, allowanceType, date, status = UserCustomBenefitPaymentStatus.Approved) => {
  const isAllowance = isAllowanceBenefit(benefitType);
  let start = null;
  let end = null;
  if (isAllowance && allowanceType === CustomBenefitAllowanceType.Yearly) {
    start = new LocalDate(date).getFirstDayOfYear().toDateString();
    end = new LocalDate(date).getLastDayOfYear().toDateString();
  } else if (isAllowance && allowanceType === CustomBenefitAllowanceType.Monthly) {
    start = new LocalDate(date).getFirstDayOfMonth().toDateString();
    end = new LocalDate(date).getLastDayOfMonth().toDateString();
  }
  return benefitPayments.filter((p) => {
    if (start && end) return p.status === status && p.date >= start && p.date <= end;
    return p.status === status;
  }).reduce((sum, p) => sum + p.amount, 0);
};
export const getOpeningBalanceColumns = (isLoan, polyglot) => [
  {
    header: () => isLoan ? polyglot.t("BenefitModule.borrowed") : polyglot.t("BenefitModule.allowance"),
    accessorFn: (row) => row,
    id: "openingBalance",
    enableSorting: true,
    sortingFn: (a, b) => sortNumeric(a, b, (item) => {
      var _a;
      return (_a = item.openingBalance) != null ? _a : 0;
    }),
    cell: (info) => {
      const userBenefit = info.getValue();
      return /* @__PURE__ */ jsx(
        NumberCell,
        {
          value: (userBenefit == null ? void 0 : userBenefit.openingBalance) ? formatMoney({
            amount: userBenefit.openingBalance,
            asDecimal: true
          }) : null
        }
      );
    }
  },
  ...isLoan ? [
    {
      header: () => polyglot.t("BenefitModule.numberOfInstallments"),
      accessorFn: (row) => row,
      id: "numberOfInstallments",
      enableSorting: true,
      sortingFn: (a, b) => sortNumeric(a, b, (item) => {
        var _a;
        return (_a = item.numberOfInstallments) != null ? _a : 0;
      }),
      cell: (info) => {
        const userBenefit = info.getValue();
        return /* @__PURE__ */ jsx(NumberCell, { value: userBenefit == null ? void 0 : userBenefit.numberOfInstallments });
      }
    }
  ] : []
];
