"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { TabFilterButtons } from "@v2/components/tab-filter-buttons.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { TabFilter } from "@v2/feature/reports/features/create-report/people/people-report-type.component";
import { DateType } from "@v2/feature/reports/reports.interface";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { subDays } from "date-fns";
import dayjs from "dayjs";
export const UpdateReportDatesDrawer = ({ isOpen, setIsOpen, reportDates, action }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UpdateReportDatesDrawerContent, { setIsOpen, reportDates, action }) })
    }
  ) });
};
const UpdateReportDatesDrawerContent = ({ setIsOpen, reportDates, action }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [reportUpdate, setReportUpdate] = useState({
    dateType: reportDates.dateType,
    start: reportDates.start,
    end: reportDates.end
  });
  const [filterString, setFilterString] = useState((_a = reportDates.dateType) != null ? _a : "");
  const executeAction = useCallback(
    (datesUpdate) => __async(void 0, null, function* () {
      setLoading(true);
      yield action(datesUpdate);
      setIsOpen(false);
      setLoading(false);
    }),
    [action, setIsOpen]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("UpdateReportDatesDrawerContent.edit") }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10 }, children: /* @__PURE__ */ jsx(
      TabFilterButtons,
      {
        filters: TabFilter(polyglot),
        setFilterValue: setFilterString,
        filterValue: filterString,
        onFilterChange: ({ filterValue }) => {
          if (filterValue === DateType.Last30days) {
            setReportUpdate((prev) => __spreadProps(__spreadValues({}, prev), {
              start: new LocalDate(subDays(/* @__PURE__ */ new Date(), 30)).toDateString(),
              end: new LocalDate().toDateString(),
              dateType: DateType.Last30days
            }));
          } else if (filterValue === DateType.Last7days) {
            setReportUpdate((prev) => __spreadProps(__spreadValues({}, prev), {
              start: new LocalDate(subDays(/* @__PURE__ */ new Date(), 7)).toDateString(),
              end: new LocalDate().toDateString(),
              dateType: DateType.Last7days
            }));
          } else {
            setReportUpdate((prev) => __spreadProps(__spreadValues({}, prev), {
              dateType: DateType.SelectDates
            }));
          }
          setFilterString(filterValue);
        }
      }
    ) }),
    filterString === DateType.SelectDates && /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: reportUpdate.start,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              setReportUpdate((prev) => __spreadProps(__spreadValues({}, prev), { start: value }));
            }
          },
          name: "startDate",
          label: polyglot.t("UpdateReportDatesDrawerContent.startDate")
        }
      ) }) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: reportUpdate.end,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              setReportUpdate((prev) => __spreadProps(__spreadValues({}, prev), { end: value }));
            }
          },
          name: "endDate",
          label: polyglot.t("UpdateReportDatesDrawerContent.endDate")
        }
      ) }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.m40 }), children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        onClick: () => __async(void 0, null, function* () {
          return executeAction(reportUpdate);
        }),
        loading,
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] });
};
