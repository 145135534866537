"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box, FormControl, Tooltip } from "@mui/material";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TASK_CHECKLIST_ROUTE } from "@/lib/routes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { RelativeAssignmentValues } from "@/v2/feature/task/subfeature/checklist/checklist-item.dto";
import { TaskAPI, TaskEndpoints } from "@/v2/feature/task/task.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const TaskLaunchModal = ({
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  assignUserId,
  reach
}) => {
  const { data: allChecklists, isValidating: loading } = useApiClient(TaskEndpoints.getChecklists(), {
    suspense: false
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, loading, children: /* @__PURE__ */ jsx(
    TaskLaunchContent,
    {
      allChecklists,
      onClose,
      refresh,
      assignUserId,
      reach
    }
  ) });
};
const LaunchSchema = (polyglot) => Yup.object({
  checklistId: Yup.number().required(),
  dueDate: Yup.string().nullable().required(),
  assignedUserIds: Yup.array().of(Yup.number()).required(polyglot.t("LaunchFormDrawer.errorMessages.needUser")),
  assignedUserId: Yup.number().nullable().integer().notRequired().typeError(polyglot.t("LaunchFormDrawer.errorMessages.assignUser"))
});
const TaskLaunchContent = ({
  allChecklists,
  onClose,
  refresh,
  assignUserId,
  reach
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedChecklistId, setSelectedChecklistId] = useState(void 0);
  const users = useMemo(() => {
    const reports = new Set(globalState.user.reports);
    return reach === "team" ? nonTerminatedCachedUsers.filter((u) => reports.has(u.userId) || u.userId === globalState.user.userId).map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    }) : nonTerminatedCachedUsers.map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    });
  }, [globalState.user.reports, globalState.user.userId, nonTerminatedCachedUsers, reach]);
  const formik = useFormik({
    initialValues: {
      checklistId: null,
      dueDate: null,
      assignedUserId: null,
      assignedUserIds: assignUserId ? [assignUserId] : []
    },
    validationSchema: LaunchSchema(polyglot),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        if (values.checklistId) {
          setLoading(true);
          if (!assignUserId && isSingleSelect && values.assignedUserId) {
            values.assignedUserIds = [values.assignedUserId];
          }
          if (assignUserId) values.assignedUserIds = [assignUserId];
          yield TaskAPI.launchChecklistById(values.checklistId, values);
          showMessage(polyglot.t("LaunchFormDrawer.successMessages.create"), "success");
          refresh();
          onClose();
        } else {
          showMessage("Please select the checklist you want to launch", "error");
        }
      } catch (error) {
        showMessage(
          polyglot.t("LaunchFormDrawer.errorMessages.launch", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  const checklistOptions = useMemo(() => {
    let options = [];
    if (allChecklists) {
      options = allChecklists.map((c) => {
        var _a2;
        return {
          label: (_a2 = c.name) != null ? _a2 : "",
          value: c.id
        };
      });
    }
    return options;
  }, [allChecklists]);
  const selectedChecklist = useMemo(() => {
    return allChecklists == null ? void 0 : allChecklists.find((checklist) => checklist.id === selectedChecklistId);
  }, [allChecklists, selectedChecklistId]);
  const isSingleSelect = useMemo(() => {
    return selectedChecklist == null ? void 0 : selectedChecklist.checklistItems.find(
      (item) => item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee || item.requestedForPlaceholder === RelativeAssignmentValues.manager
    );
  }, [selectedChecklist]);
  const showAssigneeSelect = useMemo(() => {
    return selectedChecklist == null ? void 0 : selectedChecklist.checklistItems.find(
      (item) => item.requestedForPlaceholder === RelativeAssignmentValues.checklistAssignee || item.requestedForPlaceholder === RelativeAssignmentValues.manager || item.assignedToPlaceholder === RelativeAssignmentValues.checklistAssignee || item.assignedToPlaceholder === RelativeAssignmentValues.manager
    );
  }, [selectedChecklist]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Launch checklist" }),
    assignUserId && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "5px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: "Checklist Assignee" }),
      /* @__PURE__ */ jsx(UserCell, { userId: assignUserId, nameVariant: "title4" })
    ] }),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "checklistId",
        label: "Select checklist",
        options: checklistOptions,
        value: checklistOptions.find(({ value }) => value === formik.values.checklistId),
        compareValue: (_a = formik.values.checklistId) != null ? _a : "",
        onChange: (_, x) => {
          var _a2;
          setSelectedChecklistId(x == null ? void 0 : x.value);
          formik.setFieldValue("checklistId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          formik.setFieldValue("assignUserId", null);
          formik.setFieldValue("assignedUserIds", []);
          formik.setFieldValue("dueDate", null);
        },
        error: formik.touched.checklistId && Boolean(formik.errors.checklistId),
        helperText: formik.touched.checklistId && formik.errors.checklistId,
        editList: {
          handler: () => routerHistory.push(SETTINGS_TASK_CHECKLIST_ROUTE),
          isHidden: !hasScopes(["task:all"], { userId: user.userId })
        }
      }
    ),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          transition: "all 0.3s ease",
          transitionProperty: "opacity",
          opacity: formik.values.checklistId ? 1 : 0,
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        },
        children: [
          showAssigneeSelect && !assignUserId && /* @__PURE__ */ jsx(Fragment, { children: isSingleSelect ? /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "assignedUserId",
              options: reach,
              onChange: (_, x) => {
                var _a2;
                return formik.setFieldValue("assignedUserId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
              },
              value: formik.values.assignedUserId,
              label: polyglot.t("LaunchFormDrawer.whoIsAssignee"),
              error: formik.touched.assignedUserId && Boolean(formik.errors.assignedUserId),
              helperText: formik.touched.assignedUserId && formik.errors.assignedUserId
            }
          ) : /* @__PURE__ */ jsx(Tooltip, { title: polyglot.t("LaunchFormDrawer.whoShouldCompleteTask"), placement: "top-start", children: /* @__PURE__ */ jsx(
            UserSelect,
            {
              label: polyglot.t("LaunchFormDrawer.whoIsAssignee"),
              selectedLabel: polyglot.t("LaunchFormDrawer.checklistUser"),
              value: formik.values.assignedUserIds,
              onChange: (userIds) => {
                formik.setFieldValue("assignedUserIds", userIds);
              },
              fieldSx: __spreadValues({}, spacing.mb20),
              userOptions: users
            }
          ) }) }),
          /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
            DatePickerComponent,
            {
              inputFormat: "DD/MM/YYYY",
              shortcuts: true,
              value: formik.values.dueDate,
              shortcutLabel: "When is the checklist due date?",
              onChange: (value) => {
                formik.setFieldValue("dueDate", value);
              },
              name: "dueDate",
              label: polyglot.t("LaunchFormDrawer.date"),
              error: !!formik.errors.dueDate && Boolean(formik.touched.dueDate),
              helperText: formik.errors.dueDate && Boolean(formik.touched.dueDate)
            }
          ) })
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, buttonBoxDrawerSx), {
          transition: "all 0.3s ease",
          transitionProperty: "opacity",
          opacity: formik.values.checklistId ? 1 : 0,
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }),
        children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.launch"),
            loading,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        )
      }
    )
  ] }) });
};
