"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Success } from "@/images/side-bar-icons/ok-green.svg";
import { TEMPLATE_CONTRACT_SIGN_ROUTE } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { MissingAddressFormForPersonalContractSigning } from "@/v2/feature/templates/components/missing-address-form-contract-signing.component";
import { MissingPersonalFormForPersonalContractSigning } from "@/v2/feature/templates/components/missing-personal-form-contract-signing.component";
import {
  CONTRACT_FIELDS_CATEGORIES,
  defaultMissingFieldValues,
  PERSONAL_TYPE_FIELDS
} from "@/v2/feature/templates/templates.interface";
import { getTemplateSchema } from "@/v2/feature/templates/validations/missing-field-form-validations";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const returnValueOrEmptyString = (input, suffix = "") => {
  if (input || !isNaN(Number(input)) && Number(input) !== 0) {
    return `${input}${suffix}`;
  }
  return "";
};
export const getFormattedAddress = (address) => {
  const addressLine1 = returnValueOrEmptyString(address == null ? void 0 : address.address_line_1, ", ");
  const addressLine2 = returnValueOrEmptyString(address == null ? void 0 : address.address_line_2, ", ");
  const address_city = returnValueOrEmptyString(address == null ? void 0 : address.address_city, ", ");
  const postalCode = returnValueOrEmptyString(address == null ? void 0 : address.address_postalcode, ", ");
  const country = returnValueOrEmptyString(address == null ? void 0 : address.address_country);
  return `${addressLine1}${addressLine2}${address_city}${postalCode}${country}`;
};
export const MissingPersonalEmployeeFieldsModal = ({
  setOpen,
  open,
  templateId,
  contractId,
  missingFields,
  contractRecipientId,
  refreshMissingFields
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [isSavingMissingFields, setIsSavingMissingFields] = useState(false);
  const [hasMissingPersonalFields, setHasMissingPersonalFields] = useState(false);
  const [hasSomeMissingFields, setHasSomeMissingFields] = useState(false);
  const [fillingMissingPersonalFields, setFillingMissingPersonalFields] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const categorisedMissingFields = useMemo(() => {
    const missingFieldsForTemplate = [];
    for (const eachField of Object.keys(missingFields)) {
      missingFieldsForTemplate.push({
        category: CONTRACT_FIELDS_CATEGORIES[eachField],
        fieldId: eachField
      });
    }
    return missingFieldsForTemplate;
  }, [missingFields]);
  const handleRefresh = useCallback(() => __async(void 0, null, function* () {
    setIsRefreshing(true);
    if (contractId) yield refreshMissingFields == null ? void 0 : refreshMissingFields(contractId);
    setIsRefreshing(false);
  }), [contractId, refreshMissingFields]);
  const sections = useMemo(() => {
    const updateContractWithMissingDomainFields = (values) => __async(void 0, null, function* () {
      try {
        const addressValues = values;
        const personalValues = values;
        const templateFields = {
          employee_address: (addressValues == null ? void 0 : addressValues.addressLine1) ? getFormattedAddress({
            address_line_1: returnValueOrEmptyString(addressValues.addressLine1),
            address_line_2: returnValueOrEmptyString(addressValues.addressLine2),
            address_city: returnValueOrEmptyString(addressValues.city),
            address_country: returnValueOrEmptyString(addressValues.country),
            address_postalcode: returnValueOrEmptyString(addressValues.postcode)
          }) : "",
          employee_address_line1: addressValues == null ? void 0 : addressValues.addressLine1,
          employee_address_line2: addressValues == null ? void 0 : addressValues.addressLine2,
          employee_address_city: addressValues == null ? void 0 : addressValues.city,
          employee_address_country: addressValues == null ? void 0 : addressValues.country,
          employee_address_postcode: addressValues == null ? void 0 : addressValues.postcode,
          // Personal Information
          employee_dob: (personalValues == null ? void 0 : personalValues.dob) ? personalValues.dob : void 0,
          employee_passport_number: (personalValues == null ? void 0 : personalValues.passportNumber) ? personalValues.passportNumber : void 0,
          employee_personal_email: (personalValues == null ? void 0 : personalValues.personalEmail) ? personalValues.personalEmail : void 0
        };
        const finalData = __spreadValues(__spreadValues({}, values), templateFields);
        setIsSavingMissingFields(true);
        yield ContractAPI.updateContractWithMissingEmployeeFields({
          contractId,
          recipientId: contractRecipientId,
          fieldsToSave: finalData
        });
        showMessage(polyglot.t("MissingPersonalEmployeeFieldsModal.successMessages.update"), "success");
      } catch (error) {
        console.error("Failed to save data", error.message);
        showMessage(polyglot.t("MissingPersonalEmployeeFieldsModal.errorMessages.save"), "error");
      } finally {
        setIsSavingMissingFields(false);
      }
    });
    const hasMissingField = (category) => categorisedMissingFields.some((f) => f.category === category);
    const result = [];
    const listOfMissingFieldsForCategory = (category) => categorisedMissingFields.filter((f) => f.category === category);
    if (hasMissingField(CustomProfileFormType.Details))
      result.push(
        /* @__PURE__ */ jsx(
          MissingPersonalFormForPersonalContractSigning,
          {
            userId: contractRecipientId,
            refreshData: handleRefresh,
            updateDomainForMissingFields: updateContractWithMissingDomainFields,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Details)
          }
        )
      );
    if (hasMissingField(CustomProfileFormType.Address))
      result.push(
        /* @__PURE__ */ jsx(
          MissingAddressFormForPersonalContractSigning,
          {
            userId: contractRecipientId,
            refreshData: handleRefresh,
            updateDomainForMissingFields: updateContractWithMissingDomainFields,
            missingFieldsForCategory: listOfMissingFieldsForCategory(CustomProfileFormType.Address)
          }
        )
      );
    return result;
  }, [polyglot, categorisedMissingFields, contractId, contractRecipientId, handleRefresh, showMessage]);
  const redirectToSendContractRoutePage = () => __async(void 0, null, function* () {
    routerHistory.push(
      generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
        templateId,
        contractId,
        userId: contractRecipientId
      })
    );
  });
  useEffect(() => {
    let hasMissing = false;
    Object.keys(missingFields).forEach((key) => {
      if (PERSONAL_TYPE_FIELDS.includes(key)) {
        hasMissing = true;
        setHasMissingPersonalFields(true);
        setFillingMissingPersonalFields(true);
      }
    });
    setHasSomeMissingFields(hasMissing);
    if (!hasMissing) redirectToSendContractRoutePage();
  }, [missingFields]);
  const handleSubmitAction = () => {
    if (fillingMissingPersonalFields) {
      setFillingMissingPersonalFields(false);
      redirectToSendContractRoutePage();
    }
  };
  const formik = useFormik({
    initialValues: defaultMissingFieldValues,
    enableReinitialize: true,
    validationSchema: getTemplateSchema(missingFields),
    onSubmit: handleSubmitAction
  });
  const disableMissingFields = useMemo(() => {
    let disable = true;
    if (Object.keys(missingFields).length === 0) disable = false;
    for (const key of Object.keys(missingFields).filter((f) => Boolean(f))) {
      if (PERSONAL_TYPE_FIELDS.includes(key)) {
        disable = true;
      }
      if (disable) break;
    }
    return disable;
  }, [missingFields]);
  const missingFieldsButtonText = useMemo(() => {
    if (!hasSomeMissingFields) {
      return polyglot.t("MissingPersonalEmployeeFieldsModal.preview");
    }
    if (hasMissingPersonalFields && fillingMissingPersonalFields) {
      return polyglot.t("MissingPersonalEmployeeFieldsModal.updateAndPreview");
    }
    return polyglot.t("MissingPersonalEmployeeFieldsModal.preview");
  }, [polyglot, fillingMissingPersonalFields, hasMissingPersonalFields, hasSomeMissingFields]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          paddingBottom: spacing.p10
        },
        children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: polyglot.t("MissingPersonalEmployeeFieldsModal.missingInformation") })
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          paddingBottom: spacing.p10
        },
        children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("MissingPersonalEmployeeFieldsModal.missingInformationDesc") })
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20 }, children: [
      isRefreshing && /* @__PURE__ */ jsx(LoadingSpinner, {}),
      !isRefreshing && sections.length === 0 && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10, mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Success, { height: "1em", style: { fill: themeColors.Green, flexShrink: 0 } }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("MissingPersonalEmployeeFieldsModal.allInfoPresent") })
      ] }),
      !isRefreshing && sections.length > 0 && sections.map((section, idx) => /* @__PURE__ */ jsx(
        Stack,
        {
          sx: { py: spacing.p10, borderTop: idx ? `1px solid ${themeColors.lightGrey}` : void 0 },
          children: section
        },
        idx
      ))
    ] }),
    fillingMissingPersonalFields && /* @__PURE__ */ jsxs(Fragment, { children: [
      missingFields.employee_first_name || missingFields.employee_last_name ? /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            paddingBottom: spacing.p10
          },
          children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: polyglot.t("MissingPersonalEmployeeFieldsModal.userProfile") })
        }
      ) : "",
      missingFields.employee_first_name && /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "employee_first_name",
          label: polyglot.t("MissingPersonalEmployeeFieldsModal.employee_first_name"),
          value: formik.values.employee_first_name,
          type: "text",
          onChange: formik.handleChange,
          error: !!formik.errors.employee_first_name,
          helperText: formik.errors.employee_first_name,
          clearText: () => formik.setFieldValue("employee_first_name", "")
        }
      ) }),
      missingFields.employee_last_name && /* @__PURE__ */ jsx(FormControl, { sx: { marginTop: spacing.m30 }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "employee_last_name",
          label: polyglot.t("MissingPersonalEmployeeFieldsModal.employee_last_name"),
          value: formik.values.employee_last_name,
          type: "text",
          onChange: formik.handleChange,
          error: !!formik.errors.employee_last_name,
          helperText: formik.errors.employee_last_name,
          clearText: () => formik.setFieldValue("employee_last_name", "")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", justifyContent: "space-between", width: "100%" }, spacing.mt40), children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true,
        name: missingFieldsButtonText,
        loading: isSavingMissingFields,
        disabled: disableMissingFields
      }
    ) }) })
  ] }) }) }) }) });
};
