"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { ReactComponent as Contract } from "@/images/documents/Contract.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { ReactComponent as Cancel } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { ReactComponent as Survey } from "@/images/survey/Survey.svg";
import {
  ActionView,
  DocumentFileOperation
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { iconSize } from "@/v2/styles/menu.styles";
export const useNewDocumentOptions = (document, actions, view) => {
  return [
    ...view !== ActionView.Table ? [
      {
        icon: /* @__PURE__ */ jsx(Contract, __spreadValues({}, iconSize)),
        handler: () => actions.onSign(document),
        label: "Sign",
        hidden: !document.showSign
      }
    ] : [],
    ...view !== ActionView.Table || (document == null ? void 0 : document.fileUuid) ? [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => actions.onEdit(document),
        label: (document == null ? void 0 : document.fileUuid) ? "Edit" : "Upload",
        hidden: !document.showEdit
      }
    ] : [],
    {
      icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
      handler: () => actions.onDownload(document, DocumentFileOperation.download),
      label: "Download",
      hidden: !document.showDownload
    },
    {
      icon: /* @__PURE__ */ jsx(Survey, __spreadValues({}, iconSize)),
      handler: () => actions.onDownload(document, DocumentFileOperation.audit),
      label: "Audit trail",
      hidden: !document.showAuditTrail
    },
    {
      icon: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)),
      handler: () => actions.onPreview(document, DocumentFileOperation.preview),
      label: "Preview",
      hidden: !document.showPreview
    },
    {
      icon: /* @__PURE__ */ jsx(Clock, __spreadValues({}, iconSize)),
      handler: () => actions.onRemind(document),
      label: "Remind",
      hidden: !document.showRemind
    },
    {
      icon: /* @__PURE__ */ jsx(Cancel, __spreadValues({}, iconSize)),
      handler: () => actions.onCancel(document),
      label: "Cancel",
      hidden: !document.showCancel
    },
    {
      icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
      handler: () => actions.onDelete(document),
      label: "Delete",
      hidden: !document.showDelete
    }
  ];
};
