"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { AppGroupManagementMemberAddList } from "./components/app-group-management-member-add.component";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppIntegrationAPI, AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import {
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  REFRESH_DELAY_APP_USER_LIST
} from "@/v2/feature/app-integration/app-integration.interface";
import { emailOptionsForUserCreation } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { AppGroupManagementMemberEditList } from "@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/components/app-group-management-member-edit-list.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import {
  buttonBoxSx,
  fieldSx,
  titleSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const AppGroupManagementDrawerPage = ({
  appStub,
  selectedGroupMembership,
  usersWithoutAccess,
  usersWithAccess,
  externalUserList,
  existingUser,
  refreshApp,
  groupMembers,
  closePage,
  mode = APP_GROUP_MANAGEMENT_DRAWER_MODES.edit,
  groupList
}) => {
  var _a;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const { data: personalEmailForUser } = useApiClient(
    (existingUser == null ? void 0 : existingUser.userId) ? AppIntegrationEndpoints.getPersonalEmailForAppsByUserId(+(existingUser == null ? void 0 : existingUser.userId), appStub) : { url: void 0 },
    {
      suspense: false
    }
  );
  const defaultUserForAdding = {
    appUserId: "",
    dateOfActivation: new LocalDate().toDateString(),
    selectedUser: void 0,
    groupList: []
  };
  const usersWithoutAccessOptions = usersWithoutAccess.map((u) => {
    return { label: u.displayName, value: u.userId };
  });
  const externalEmailList = useMemo(() => {
    return externalUserList ? externalUserList == null ? void 0 : externalUserList.map((e) => e.primaryEmail) : [];
  }, [externalUserList]);
  const microsoft365UsersWithoutAccessOptions = usersWithoutAccess.filter((u) => {
    var _a2;
    return !((_a2 = u.emails) == null ? void 0 : _a2.some((e) => externalEmailList.includes(e.email)));
  }).map((u) => {
    return { label: u.displayName, value: u.userId };
  });
  const userOptionsForAdd = useMemo(() => {
    if (["google-workspace", "jira"].includes(appStub)) return usersWithoutAccessOptions;
    if (appStub === "microsoft365") return microsoft365UsersWithoutAccessOptions;
  }, [appStub, microsoft365UsersWithoutAccessOptions, usersWithoutAccessOptions]);
  const [showMessage] = useMessage();
  const [userListForMembershipDeletion, setUserListForMembershipDeletion] = useState([]);
  const [refreshAt, setRefreshAt] = useState(null);
  const matchingUserForEmailOrId = useCallback(
    (identifier) => {
      let matchingExternalUser;
      if (["google-workspace", "microsoft365"].includes(appStub))
        matchingExternalUser = externalUserList == null ? void 0 : externalUserList.find((eachUser) => eachUser.primaryEmail === identifier);
      if (appStub === "jira") matchingExternalUser = externalUserList == null ? void 0 : externalUserList.find((eachUser) => eachUser.id === identifier);
      if (matchingExternalUser) return matchingExternalUser;
      let matchingTeamUser;
      if (["google-workspace", "microsoft365"].includes(appStub))
        matchingTeamUser = usersWithoutAccess == null ? void 0 : usersWithoutAccess.find(
          (eachUser) => eachUser.emails && eachUser.emails.some((eachEmailEntry) => eachEmailEntry.email === identifier)
        );
      if (appStub === "jira") matchingTeamUser = usersWithoutAccess == null ? void 0 : usersWithoutAccess.find((eachUser) => eachUser.id === identifier);
      if (matchingTeamUser) return matchingTeamUser;
      let matchingUserWithAccess;
      if (["google-workspace", "microsoft365"].includes(appStub))
        matchingUserWithAccess = usersWithAccess == null ? void 0 : usersWithAccess.find(
          (eachUser) => eachUser.emails && eachUser.emails.some((eachEmailEntry) => eachEmailEntry.email === identifier)
        );
      if (appStub === "jira") matchingUserWithAccess = usersWithAccess == null ? void 0 : usersWithAccess.find((eachUser) => eachUser.id === identifier);
      if (matchingUserWithAccess) return matchingUserWithAccess;
      return void 0;
    },
    [appStub, externalUserList, usersWithAccess, usersWithoutAccess]
  );
  useEffect(() => {
    if (!refreshAt) return;
    const delay = Math.max(refreshAt - Date.now(), 0);
    setTimeout(() => {
      setRefreshAt(null);
      refreshApp();
    }, delay);
  }, [refreshApp, refreshAt]);
  function refreshAppData(delay = REFRESH_DELAY_APP_USER_LIST) {
    setRefreshAt(Date.now() + delay);
  }
  const initialValues = {
    userListForProcessing: mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add ? [defaultUserForAdding] : [],
    emailSelectorRequired: false,
    selectedEmailForUserCreation: void 0
  };
  function doDeleteUserFromGroup(appUserId, identifier, groupId) {
    return __async(this, null, function* () {
      try {
        const matchingUser = matchingUserForEmailOrId(identifier);
        yield AppIntegrationAPI.deleteAppUserFromGroup(
          appStub,
          appUserId,
          (matchingUser == null ? void 0 : matchingUser.userId) ? +matchingUser.userId : 0,
          groupId
        );
        showMessage("Deleting user from group...", "success");
      } catch (error) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, "error");
      }
    });
  }
  function addUserToGroup(identifier, groupId, appUserEmail) {
    return __async(this, null, function* () {
      try {
        if (!identifier) {
          showMessage("Unable to add user to group, as user identifier is missing", "error");
          return;
        }
        if (identifier && ["google-workspace", "jira"].includes(appStub)) {
          const matchingUser = identifier ? matchingUserForEmailOrId(identifier) : void 0;
          if (matchingUser)
            yield AppIntegrationAPI.addAppUserToGroup(
              appStub,
              identifier,
              (matchingUser == null ? void 0 : matchingUser.userId) ? +matchingUser.userId : 0,
              groupId
            );
          showMessage("Adding user to group(s)...", "success");
        }
        if (identifier && appStub === "microsoft365") {
          const matchingUser = appUserEmail ? matchingUserForEmailOrId(appUserEmail) : void 0;
          if (matchingUser)
            yield AppIntegrationAPI.addAppUserToGroup(
              appStub,
              identifier,
              (matchingUser == null ? void 0 : matchingUser.userId) ? +matchingUser.userId : 0,
              groupId
            );
          showMessage("Adding user to group(s)...", "success");
        }
      } catch (error) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, "error");
      }
    });
  }
  const emailSelectorRequired = useMemo(
    () => mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub),
    [mode, appStub]
  );
  const getParamsForAppUserCreation = (values) => {
    if (emailSelectorRequired) return { selectedEmail: values.selectedEmailForUserCreation };
    return void 0;
  };
  const validationSchema = yup.object({
    selectedEmailForUserCreation: yup.string().notRequired().when("emailSelectorRequired", {
      is: true,
      then: yup.string().nullable().required("Selected email is required")
    })
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b, _c;
      try {
        setIsSubmitting(true);
        if (appStub === "google-workspace") {
          if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && values.userListForProcessing) {
            const userCreationWithGroupsPromises = values.userListForProcessing.map(
              (eachUser) => AppIntegrationAPI.createAppUser(
                appStub,
                +eachUser.userId,
                __spreadValues({
                  groupIds: eachUser.groupList ? eachUser.groupList.map((g) => String(g.value)) : []
                }, getParamsForAppUserCreation(values)),
                eachUser.dateOfActivation
              )
            );
            yield Promise.all(userCreationWithGroupsPromises);
            showMessage("User creation initiated...", "success");
          } else if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && selectedGroupMembership && selectedGroupMembership.id) {
            const userMembershipDeletionPromises = userListForMembershipDeletion.map((m) => {
              const eachMember = m;
              return doDeleteUserFromGroup(eachMember.id, eachMember.email, selectedGroupMembership == null ? void 0 : selectedGroupMembership.id);
            });
            yield Promise.all(userMembershipDeletionPromises);
          } else if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup && existingUser && values.existingUserGroupList) {
            if (((_a2 = values.existingUserGroupList) == null ? void 0 : _a2.length) > 0 && existingUser && existingUser.id) {
              let appUserEmail = (existingUser == null ? void 0 : existingUser.emails) && (existingUser == null ? void 0 : existingUser.emails.length) > 0 ? existingUser.emails[0].email : void 0;
              if (!appUserEmail && (existingUser == null ? void 0 : existingUser.primaryEmail)) appUserEmail = existingUser.primaryEmail;
              const addUserToGroupPromises = values.existingUserGroupList.map(
                (eachGroup) => appUserEmail ? addUserToGroup(appUserEmail, String(eachGroup.value)) : void 0
              );
              yield Promise.all(addUserToGroupPromises.filter(Boolean));
            }
          }
        }
        if (appStub === "jira") {
          if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && values.userListForProcessing) {
            const userCreationWithGroupsPromises = values.userListForProcessing.map(
              (eachUser) => AppIntegrationAPI.createAppUser(
                appStub,
                +eachUser.userId,
                {
                  groupIds: eachUser.groupList ? eachUser.groupList.map((g) => String(g.value)) : []
                },
                eachUser.dateOfActivation
              )
            );
            yield Promise.all(userCreationWithGroupsPromises);
            showMessage("User creation initiated...", "success");
          } else if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && selectedGroupMembership && selectedGroupMembership.id) {
            const userMembershipDeletionPromises = userListForMembershipDeletion.map((m) => {
              const eachMember = m;
              return doDeleteUserFromGroup(eachMember.accountId, eachMember.accountId, selectedGroupMembership == null ? void 0 : selectedGroupMembership.id);
            });
            yield Promise.all(userMembershipDeletionPromises);
          } else if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup && existingUser && values.existingUserGroupList) {
            if (((_b = values.existingUserGroupList) == null ? void 0 : _b.length) > 0 && existingUser && existingUser.id) {
              const addUserToGroupPromises = values.existingUserGroupList.map(
                (eachGroup) => addUserToGroup(String(existingUser.id), String(eachGroup.value))
              );
              yield Promise.all(addUserToGroupPromises.filter(Boolean));
            }
          }
        }
        if (appStub === "microsoft365") {
          if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && values.userListForProcessing) {
            const userCreationWithGroupsPromises = values.userListForProcessing.map(
              (eachUser) => AppIntegrationAPI.createAppUser(
                appStub,
                +eachUser.userId,
                __spreadValues({
                  groupIds: eachUser.groupList ? eachUser.groupList.map((g) => String(g.value)) : []
                }, getParamsForAppUserCreation(values)),
                eachUser.dateOfActivation
              )
            );
            yield Promise.all(userCreationWithGroupsPromises);
            showMessage("User creation initiated...", "success");
          } else if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && selectedGroupMembership && selectedGroupMembership.id) {
            const userMembershipDeletionPromises = userListForMembershipDeletion.map((m) => {
              const eachMember = m;
              return doDeleteUserFromGroup(eachMember.id, eachMember.email, selectedGroupMembership == null ? void 0 : selectedGroupMembership.id);
            });
            yield Promise.all(userMembershipDeletionPromises);
          } else if (mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup && existingUser && values.existingUserGroupList) {
            if (((_c = values.existingUserGroupList) == null ? void 0 : _c.length) > 0 && existingUser && existingUser.id) {
              let appIdentifier = String(existingUser == null ? void 0 : existingUser.id);
              const addUserToGroupPromises = values.existingUserGroupList.map(
                (eachGroup) => appIdentifier && existingUser.primaryEmail ? addUserToGroup(appIdentifier, String(eachGroup.value), existingUser.primaryEmail) : void 0
              );
              yield Promise.all(addUserToGroupPromises.filter(Boolean));
            }
          }
        }
        closePage();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  useEffect(() => {
    formik.setFieldValue("emailSelectorRequired", emailSelectorRequired);
  }, [emailSelectorRequired]);
  const enhancedSelectedUserObject = useMemo(() => {
    if (formik.values.userListForProcessing && formik.values.userListForProcessing.length === 1 && formik.values.userListForProcessing[0].userId) {
      const userObject = getCachedUserById(formik.values.userListForProcessing[0].userId);
      return userObject ? __spreadProps(__spreadValues({}, userObject), {
        emails: [
          {
            email: userObject.emailAddress,
            personalEmail: personalEmailForUser != null ? personalEmailForUser : "",
            primary: true,
            status: "No access"
            // all users who already have access are already removed from this user list
          }
        ]
      }) : void 0;
    }
  }, [formik.values.userListForProcessing, getCachedUserById, personalEmailForUser]);
  const selectedEmailOptions = useMemo(() => {
    return enhancedSelectedUserObject ? emailOptionsForUserCreation(
      appStub,
      enhancedSelectedUserObject,
      personalEmailForUser != null ? personalEmailForUser : "",
      nonTerminatedCachedUsers
    ) : void 0;
  }, [appStub, enhancedSelectedUserObject, nonTerminatedCachedUsers, personalEmailForUser]);
  const emailSelectorForUserCreation = useMemo(() => {
    if (emailSelectorRequired) {
      return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "emailSelector",
          label: "Personal or Work email",
          options: selectedEmailOptions != null ? selectedEmailOptions : [],
          value: formik.values.selectedEmailForUserCreation,
          error: !!formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation,
          onChange: (e) => {
            const newVal = e.target.value;
            formik.setFieldValue("selectedEmailForUserCreation", newVal, true);
          },
          helperText: formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation
        }
      ) });
    } else return /* @__PURE__ */ jsx(Fragment, {});
  }, [emailSelectorRequired, selectedEmailOptions, formik]);
  const getDrawerTitle = (currentMode) => {
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit) return "Edit members";
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add) return "Add users";
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup) return "Add to group";
    else return "";
  };
  const getDrawerConfirmButtonText = (currentMode) => {
    if (currentMode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit) return "Save";
    if ([APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup, APP_GROUP_MANAGEMENT_DRAWER_MODES.add].includes(
      currentMode
    ))
      return "Add";
    else return "";
  };
  const groupOptionList = useMemo(() => {
    if (groupList && !existingUser)
      return groupList.map((g) => {
        return {
          label: g.name,
          value: g.id
        };
      });
    if (groupList && existingUser) {
      return groupList.filter(
        (eachGroup) => !Object.values(eachGroup.members).some((groupMember) => {
          if (["google-workspace", "microsoft365"].includes(appStub))
            return groupMember.id === existingUser.id;
          if (appStub === "jira") return groupMember.accountId === existingUser.id;
          return false;
        })
      ).map((g) => {
        return {
          label: g.name,
          value: g.id
        };
      });
    }
    return groupList ? groupList.map((g) => {
      return {
        label: g.name,
        value: g.id
      };
    }) : [];
  }, [appStub, existingUser, groupList]);
  const listForMemberDeletion = useMemo(() => {
    if (userListForMembershipDeletion && appStub === "google-workspace")
      return userListForMembershipDeletion.map((g) => g.email);
    if (userListForMembershipDeletion && appStub === "jira")
      return userListForMembershipDeletion.map((g) => g.accountId);
    if (userListForMembershipDeletion && appStub === "microsoft365")
      return userListForMembershipDeletion.map((g) => g.id);
    return [];
  }, [appStub, userListForMembershipDeletion]);
  useEffect(() => {
    var _a2;
    if ((selectedEmailOptions == null ? void 0 : selectedEmailOptions.length) === 1) {
      formik.setFieldValue("selectedEmailForUserCreation", (_a2 = selectedEmailOptions[0]) == null ? void 0 : _a2.value, true);
    } else {
      formik.setFieldValue("selectedEmailForUserCreation", void 0, true);
    }
  }, [selectedEmailOptions]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: getDrawerTitle(mode) }),
    /* @__PURE__ */ jsx(
      AppGroupManagementMemberEditList,
      {
        appStub,
        existingUser,
        mode,
        setUserListForMembershipDeletion,
        userListForMembershipDeletion,
        matchingUserForEmailOrId,
        groupMembers: groupMembers != null ? groupMembers : {},
        listForMemberDeletion,
        groupOptionList,
        existingUserGroupList: (_a = formik.values.existingUserGroupList) != null ? _a : [],
        formikSetFieldValue: formik.setFieldValue
      }
    ),
    /* @__PURE__ */ jsx(
      AppGroupManagementMemberAddList,
      {
        mode,
        userListForProcessing: formik.values.userListForProcessing,
        userOptionsForAdd: userOptionsForAdd != null ? userOptionsForAdd : [],
        groupOptionList,
        formikSetFieldValue: formik.setFieldValue
      }
    ),
    emailSelectorForUserCreation,
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        type: "submit",
        loading: isSubmitting,
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: isSubmitting || mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && (formik.values.userListForProcessing.some((e) => !e.userId) || !formik.isValid) || mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && userListForMembershipDeletion.length === 0,
        children: getDrawerConfirmButtonText(mode)
      }
    ) })
  ] }) }) });
};
