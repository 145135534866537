"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { RemoteFormBuilder } from "@/v2/infrastructure/forms/remote/remote-form-builder.component";
import { LocalDate } from "@/v2/util/local-date";
export const EditUserPayrollNonUK = ({
  payrollId,
  payrollSchema,
  remotePayrollValues,
  isUserUpdating,
  close,
  savePayrollRecord,
  changeReason: initialChangeReason,
  effectiveDate,
  requireReason,
  userPayrollId
}) => {
  const { polyglot } = usePolyglot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeReason, setChangeReason] = useState(initialChangeReason != null ? initialChangeReason : null);
  const [changeReasonError, setChangeReasonError] = useState(false);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const changeReasonValid = !requireReason || !!changeReason;
      setChangeReasonError(!changeReasonValid);
      if (!changeReasonValid) return;
      setIsSubmitting(true);
      const saved = yield savePayrollRecord({
        payrollId,
        countryCode: payrollSchema.countryCode,
        payrollValues: null,
        remotePayrollValues: values,
        effectiveDate: effectiveDate || new LocalDate().toDateString(),
        changeReason,
        userPayrollId
      });
      if (!saved) {
        setIsSubmitting(false);
        return;
      }
      close();
    }),
    [
      changeReason,
      close,
      effectiveDate,
      payrollId,
      payrollSchema.countryCode,
      requireReason,
      savePayrollRecord,
      userPayrollId
    ]
  );
  return /* @__PURE__ */ jsx(
    RemoteFormBuilder,
    {
      onSubmit,
      schema: payrollSchema.schema,
      values: remotePayrollValues,
      style: drawerContentSx,
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        requireReason && /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("OnboardingByUser.changeReason"),
            name: "changeReason",
            value: changeReason != null ? changeReason : "",
            onChange: (e) => setChangeReason(e.target.value),
            clearText: () => setChangeReason(""),
            error: changeReasonError,
            helperText: changeReasonError ? polyglot.t("OnboardingByUser.validations.requiredField") : " "
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "secondary",
              fullWidth: true,
              onClick: () => close(),
              disabled: isUserUpdating,
              children: polyglot.t("General.cancel")
            }
          ),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("General.save"),
              loading: isSubmitting,
              fullWidth: true,
              disabled: isUserUpdating,
              colorVariant: "primary",
              sizeVariant: "medium"
            }
          )
        ] })
      ] })
    }
  );
};
