"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { SlackAPI } from "@/api-client/slack.api";
import { useCallback, useEffect, useState } from "react";
export const useSlackHook = () => {
  const [slackChannels, setSlackChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchChannels = useCallback(() => __async(void 0, null, function* () {
    try {
      yield SlackAPI.importChannels();
      const slackChannels2 = yield SlackAPI.getSlackChannels();
      setSlackChannels(slackChannels2);
    } catch (error) {
      console.error("Could not fetch slack channels.", error);
    } finally {
      setIsLoading(false);
    }
  }), []);
  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);
  return { slackChannels, isLoading };
};
