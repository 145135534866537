"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { capitalize } from "lodash";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { countFilters } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { isTimingCorrectlySet } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCRollingLaunchView = ({ surveyCycleLaunchDetails, cycleId, goBack }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const hasEnrolmentTrigger = useMemo(
    () => Boolean(
      surveyCycleLaunchDetails.enrolmentTriggerFilters && surveyCycleLaunchDetails.enrolmentTriggerFilters !== null && Object.keys(surveyCycleLaunchDetails.enrolmentTriggerFilters).length > 0
    ),
    [surveyCycleLaunchDetails]
  );
  const activateSurveyCycle = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield SurveyCycleAPI.activateSurveyCycle(cycleId);
      goBack();
      showMessage("Successfully launched the cycle", "success");
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  });
  const showActionButtons = useMemo(
    () => surveyCycleLaunchDetails && surveyCycleLaunchDetails.state === CycleState.Draft && surveyCycleLaunchDetails.totalQuestions > 0 && surveyCycleLaunchDetails.enrolmentTriggerFilters && Object.keys(surveyCycleLaunchDetails.enrolmentTriggerFilters).length > 0 && surveyCycleLaunchDetails.timelineSettings && isTimingCorrectlySet(surveyCycleLaunchDetails == null ? void 0 : surveyCycleLaunchDetails.timelineSettings),
    [surveyCycleLaunchDetails]
  );
  return /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          contentWidth: "100%",
          headerWidth: "100%",
          title: "Start cycle",
          items: [
            {
              type: SectionItemType.Pair,
              label: "Survey type",
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: capitalize(surveyCycleLaunchDetails.type) })
            },
            {
              type: SectionItemType.Pair,
              label: polyglot.t("SurveyParticipantDrawer.anonymousSwitch"),
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "DarkGrey", children: ((_a = surveyCycleLaunchDetails == null ? void 0 : surveyCycleLaunchDetails.visibilitySettings) == null ? void 0 : _a.anonymiseAnswers) ? "Enabled" : "Disabled" })
            },
            ...(surveyCycleLaunchDetails == null ? void 0 : surveyCycleLaunchDetails.visibilitySettings) && ((_b = surveyCycleLaunchDetails == null ? void 0 : surveyCycleLaunchDetails.visibilitySettings) == null ? void 0 : _b.anonymityType) ? [
              {
                type: SectionItemType.Pair,
                label: polyglot.t("SurveyParticipantDrawer.anonymityType"),
                value: /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "DarkGrey", children: capitalize((_c = surveyCycleLaunchDetails == null ? void 0 : surveyCycleLaunchDetails.visibilitySettings) == null ? void 0 : _c.anonymityType) })
              }
            ] : [],
            {
              type: SectionItemType.Pair,
              label: "Number of questions",
              value: /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "title4",
                  color: surveyCycleLaunchDetails.totalQuestions > 0 ? "DarkGrey" : "RedDark",
                  children: surveyCycleLaunchDetails.totalQuestions || "Please add some questions"
                }
              )
            },
            {
              type: SectionItemType.Pair,
              label: "Currently qualifying",
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: hasEnrolmentTrigger ? surveyCycleLaunchDetails.participantCount > 0 ? `${surveyCycleLaunchDetails.participantCount} participant(s)` : "No participants qualify the enrolment trigger" : "Please add participant(s) by creating an enrolment trigger" })
            },
            {
              type: SectionItemType.Pair,
              label: "Filters",
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", color: hasEnrolmentTrigger ? "DarkGrey" : "RedDark", children: hasEnrolmentTrigger ? `${countFilters(surveyCycleLaunchDetails.enrolmentTriggerFilters)} filter(s)` : "Please create an enrolment trigger" })
            },
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g4, mt: spacing.m8 }, children: [
                /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: () => goBack(), children: "Save and close" }) }),
                showActionButtons && /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    sizeVariant: "small",
                    colorVariant: "primary",
                    onClick: activateSurveyCycle,
                    loading,
                    disabled: loading,
                    children: "Activate cycle"
                  }
                )
              ] }),
              hidden: Boolean(
                surveyCycleLaunchDetails && surveyCycleLaunchDetails.state && surveyCycleLaunchDetails.state !== CycleState.Draft
              )
            }
          ]
        }
      ]
    }
  );
};
