"use strict";
import { jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import {
  AdminDocumentView,
  ManagerDocumentView,
  UserDocumentView
} from "@/v2/feature/document-new/interfaces/document-new.interface";
export const NewDocumentCategoryFilters = React.memo(
  ({
    documentTypes,
    handleFilter,
    filterString,
    viewFilter
  }) => {
    const filterTypes = useMemo(() => {
      let availableTo = null;
      if (viewFilter === AdminDocumentView.Company || viewFilter === UserDocumentView.Company) {
        availableTo = "company";
      } else if (viewFilter === AdminDocumentView.Personal || viewFilter === ManagerDocumentView.Personal || viewFilter === UserDocumentView.Personal) {
        availableTo = "personal";
      }
      return availableTo ? documentTypes.filter((type) => type.availableTo === availableTo) : documentTypes;
    }, [documentTypes, viewFilter]);
    return /* @__PURE__ */ jsx(
      CategoryFilters,
      {
        filterTypes: {
          type: filterTypes.map((d) => ({ value: d.id, label: d.label }))
        },
        setFilterString: handleFilter,
        filterString
      }
    );
  }
);
