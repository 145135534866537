"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import {
  getCountryOptionsForSelectComponent,
  getEURegionCoutry,
  getUKRegionCountry
} from "@v2/infrastructure/country/country.util";
import { popularCurrencyOptions } from "@v2/infrastructure/currency/currency.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import { UserBankAccountFormSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserBankAccountAPI } from "@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserBankAccountSection = ({
  defaultHolderName,
  onNext,
  sx,
  bankAccountValues,
  userId,
  form
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = bankAccountValues ? "edit" : "add";
  const defaultBankAccountValues = useMemo(() => {
    return {
      accountName: defaultHolderName != null ? defaultHolderName : "",
      accountNumber: "",
      sortCode: "",
      bankName: "",
      country: "United Kingdom",
      currency: "GBP",
      effectiveDate: new LocalDate().toDateString(),
      customUpdates: []
    };
  }, [defaultHolderName]);
  const defaultFieldsVisibility = useMemo(() => {
    var _a2, _b2, _c2, _d, _e, _f;
    if (!form)
      return {
        country: false,
        currency: false,
        bankName: false,
        accountName: false,
        accountNumber: false,
        sortCode: false
      };
    const countryField = (_a2 = form.fields) == null ? void 0 : _a2.find((f) => f.isDefault && f.fieldStub === "bankAccount.country");
    const currencyField = (_b2 = form.fields) == null ? void 0 : _b2.find((f) => f.isDefault && f.fieldStub === "bankAccount.currency");
    const bankNameField = (_c2 = form.fields) == null ? void 0 : _c2.find((f) => f.isDefault && f.fieldStub === "bankAccount.bankName");
    const accountNameField = (_d = form.fields) == null ? void 0 : _d.find((f) => f.isDefault && f.fieldStub === "bankAccount.accountName");
    const accountNumberField = (_e = form.fields) == null ? void 0 : _e.find((f) => f.isDefault && f.fieldStub === "bankAccount.accountNumber");
    const sortCodeField = (_f = form.fields) == null ? void 0 : _f.find((f) => f.isDefault && f.fieldStub === "bankAccount.sortCode");
    return {
      country: Boolean(countryField && !countryField.isHidden),
      currency: Boolean(currencyField && !currencyField.isHidden),
      bankName: Boolean(bankNameField && !bankNameField.isHidden),
      accountName: Boolean(accountNameField && !accountNameField.isHidden),
      accountNumber: Boolean(accountNumberField && !accountNumberField.isHidden),
      sortCode: Boolean(sortCodeField && !sortCodeField.isHidden)
    };
  }, [form]);
  const validationSchema = useMemo(() => UserBankAccountFormSchema(polyglot), [polyglot]);
  const formik = useFormik({
    // all default values are required here = no need to clean up data
    initialValues: bankAccountValues != null ? bankAccountValues : defaultBankAccountValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        let updatedValues;
        if ("bankAccountId" in values) {
          updatedValues = yield UserBankAccountAPI.update(userId, values.bankAccountId, values);
        } else {
          updatedValues = yield UserBankAccountAPI.create(userId, values);
        }
        onNext(updatedValues);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.bankAccountNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  const countryOptions = useMemo(() => getCountryOptionsForSelectComponent(), []);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserBankInfoForm.bankAccount") }),
    defaultFieldsVisibility.country && /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "country",
        label: polyglot.t("BankForm.country"),
        options: countryOptions,
        value: countryOptions.find(({ value }) => value === formik.values.country),
        compareValue: (_a = formik.values.country) != null ? _a : " ",
        onChange: (_, x) => {
          var _a2, _b2, _c2;
          formik.setFieldValue("country", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          const EUCountries = (_b2 = getEURegionCoutry()) == null ? void 0 : _b2.options.map((o) => o.value);
          const isEUCountry = (x == null ? void 0 : x.value) && (EUCountries == null ? void 0 : EUCountries.includes(x.value));
          if (isEUCountry && !formik.values.currency) {
            formik.setFieldValue("currency", "EUR");
            return;
          }
          const UKCountries = (_c2 = getUKRegionCountry()) == null ? void 0 : _c2.options.map((o) => o.value);
          const isUKCountry = (x == null ? void 0 : x.value) && (UKCountries == null ? void 0 : UKCountries.includes(x.value));
          if (isUKCountry && !formik.values.currency) formik.setFieldValue("currency", "GBP");
        },
        error: !!formik.errors.country && Boolean(formik.touched.country),
        helperText: formik.touched.country ? formik.errors.country : ""
      }
    ),
    defaultFieldsVisibility.currency && /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "currency",
        label: polyglot.t("BankForm.currency"),
        options: popularCurrencyOptions,
        value: (_b = popularCurrencyOptions.find(({ value }) => value === formik.values.currency)) != null ? _b : null,
        compareValue: (_c = formik.values.currency) != null ? _c : null,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("currency", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        error: !!formik.errors.currency && formik.touched.currency,
        helperText: formik.touched.currency ? formik.errors.currency : ""
      }
    ),
    defaultFieldsVisibility.bankName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "bankName",
        label: polyglot.t("BankForm.bank"),
        value: formik.values.bankName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("bankName", ""),
        helperText: hasSubmitted && formik.errors.bankName,
        error: hasSubmitted && !!formik.errors.bankName
      }
    ),
    defaultFieldsVisibility.accountName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accountName",
        label: polyglot.t("BankForm.accountName"),
        value: formik.values.accountName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("accountName", ""),
        helperText: hasSubmitted && formik.errors.accountName,
        error: hasSubmitted && !!formik.errors.accountName
      }
    ),
    defaultFieldsVisibility.accountNumber && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accountNumber",
        label: polyglot.t("BankForm.accountNumber"),
        value: formik.values.accountNumber,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("accountNumber", ""),
        helperText: hasSubmitted && formik.errors.accountNumber,
        error: hasSubmitted && !!formik.errors.accountNumber
      }
    ),
    defaultFieldsVisibility.sortCode && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "sortCode",
        label: polyglot.t("OnboardingByUser.sortCodeBICSWIFT"),
        value: formik.values.sortCode,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("sortCode", ""),
        helperText: hasSubmitted && formik.errors.sortCode,
        error: hasSubmitted && !!formik.errors.sortCode
      }
    ),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        formName: CustomProfileFormType.BankAccount,
        rowModalMode,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true
      }
    )
  ] }) }) });
};
