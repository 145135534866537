"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { ProgressBar } from "@/v2/components/progress-bar.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import { ReviewCycleFinaliseModal } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-overview/components/review-cycle-finalise-modal.component";
import { DATE_FORMAT, TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import {
  ReminderType,
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState, CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const ReviewCycleDetailOverviewPage = ({
  reviewCycle,
  refresh,
  cycleLoading,
  reach
}) => {
  var _a, _b;
  const [showMessage] = useMessage();
  const params = useParams();
  const cycleId = params.cycleId;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["reviews:all"]);
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const [finaliseOpen, setFinaliseOpen] = useState(false);
  const { data: participation, isLoading: loadingParticipation } = useApiClient(
    ReviewResultEndpoints.getReviewParticipationByCycleId(cycleId, reach),
    {
      suspense: false
    }
  );
  const isFinished = useMemo(() => {
    if (!participation) return false;
    return Boolean(participation.participant === 100);
  }, [participation]);
  const getActionsOptions = useCallback(() => {
    if (!reviewCycle) return [];
    const sendReminder = (type) => __async(void 0, null, function* () {
      try {
        yield ReviewCycleAPI.sendReminderByCycleId(type, reviewCycle.id, reach);
        showMessage("Successfully sent reminders", "success");
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    });
    return [
      ...reviewCycle.type !== CycleType.Rolling ? [
        {
          handler: () => setFinaliseOpen(true),
          label: "Finalise cycle",
          disabled: false
        }
      ] : [],
      {
        handler: () => sendReminder(ReminderType.All),
        label: "Remind all",
        disabled: false
      },
      {
        handler: () => sendReminder(ReminderType.Overdue),
        label: "Remind overdue",
        disabled: false
      }
    ];
  }, [reviewCycle, showMessage, reach]);
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      SettingsSectionContent,
      {
        headerWidth: "100%",
        contentWidth: "100%",
        title: /* @__PURE__ */ jsx(
          TitleStatusComponent,
          {
            reviewCycle,
            reachType: reach,
            showAction: (isAdmin || isManager && reviewCycle.owner === user.userId) && (reviewCycle.state === CycleState.Ongoing || reviewCycle.state === CycleState.Paused),
            action: /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: getActionsOptions(),
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: "Actions",
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end"
                }
              }
            )
          }
        ),
        noHorizontalPadding: false,
        topHeaderPaddingSx: { px: spacing.px16 },
        loading: cycleLoading || loadingParticipation,
        children: [
          /* @__PURE__ */ jsx(
            SettingsSubsectionContent,
            {
              sections: [
                {
                  contentWidth: "100%",
                  headerWidth: "100%",
                  title: "Overview",
                  items: [
                    {
                      type: SectionItemType.Pair,
                      label: "Review cycle started on",
                      value: format(new LocalDate(reviewCycle.timelineSettings.startDate).getDate(), DATE_FORMAT)
                    },
                    {
                      type: SectionItemType.Pair,
                      label: "Participants",
                      value: `${reviewCycle.revieweeId.length} participants`
                    },
                    {
                      type: SectionItemType.Component,
                      value: /* @__PURE__ */ jsx(
                        ProgressBar,
                        {
                          progress: (_a = participation == null ? void 0 : participation.participant) != null ? _a : 0,
                          label: `${(_b = participation == null ? void 0 : participation.participant) != null ? _b : 0}%`
                        }
                      )
                    }
                  ]
                }
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            SettingsSubsectionContent,
            {
              sections: [
                {
                  contentWidth: "100%",
                  headerWidth: "100%",
                  title: "Timeline",
                  hidden: Boolean(reviewCycle.reviewerSelect.length === 0),
                  items: [
                    {
                      type: SectionItemType.Component,
                      value: /* @__PURE__ */ jsx(ReviewerProgress, { reviewCycle, participation })
                    }
                  ]
                }
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      ReviewCycleFinaliseModal,
      {
        reviewCycle,
        isOpen: finaliseOpen,
        setIsOpen: setFinaliseOpen,
        onClose: () => setFinaliseOpen(false),
        isFinished,
        refresh,
        reach
      }
    )
  ] });
};
const containerStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  boxSizing: "border-box",
  gap: "16px",
  justifyContent: "space-between",
  alignItems: "center"
};
const ReviewerProgress = ({
  reviewCycle,
  participation
}) => {
  const generateReviews = useCallback(
    (reviewCycle2, participation2) => {
      if (!reviewCycle2) return [];
      return Object.values(ReviewerTypes).map((type) => {
        var _a;
        const progress = participation2 == null ? void 0 : participation2[type.toLowerCase()];
        return {
          label: `${type} review `,
          completion: (_a = progress.percent) != null ? _a : 0,
          isHidden: !reviewCycle2.reviewerSelect.includes(type) || progress.percent === null,
          isOverdue: reviewCycle2.state === CycleState.Ongoing && Boolean(progress.percent ? progress.percent < 100 : true) && progress.overdue
        };
      });
    },
    []
  );
  const reviews = useMemo(() => generateReviews(reviewCycle, participation), [
    participation,
    reviewCycle,
    generateReviews
  ]);
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(Box, { sx: containerStyle, children: reviews.map((review, index) => {
    if (review.isHidden) return null;
    return /* @__PURE__ */ jsx("div", { style: { flex: "1", minWidth: "200px" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: review.label }),
      /* @__PURE__ */ jsx(ProgressBar, { label: `${review.completion}%`, progress: review.completion, showRed: review.isOverdue })
    ] }) }, index);
  }) });
};
