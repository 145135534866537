"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, FormControl } from "@mui/material";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MultipleChoiceQuestionReadonly = ({
  questionText,
  options,
  hasComment,
  isCommentRequired
}) => {
  var _a;
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%", margin: 0 }, error: false, disabled: false, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: options && ((_a = Object.keys(options)) == null ? void 0 : _a.map((a) => /* @__PURE__ */ jsx(CheckboxComponent, { name: a, label: options[+a], disabled: false, checked: false }))) }) }),
    hasComment && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g8,
          pt: spacing.p16,
          borderTop: `${borders.background}`
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Add comment" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: isCommentRequired ? "Required" : "Optional" })
          ] }),
          /* @__PURE__ */ jsx(RichTextField, { value: null, disabled: true })
        ]
      }
    )
  ] });
};
