"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const GeneralisedRemoveConfirmDrawer = ({
  isOpen,
  setIsOpen,
  drawerTitle,
  confirmationMessage,
  onClose,
  onConfirm
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: drawerTitle }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: confirmationMessage }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => onClose(), fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          onClick: () => {
            onConfirm();
            onClose();
          },
          sizeVariant: "medium",
          colorVariant: "primary",
          children: polyglot.t("General.yes")
        }
      )
    ] })
  ] }) });
};
