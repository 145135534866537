"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class BillingContactAPI {
  // To get Billing contact use BillingAPI.getBillingInformation()
  static updateContact(billingContactInfo) {
    return __async(this, null, function* () {
      return (yield axios.put("/apiv2/billing/contact", billingContactInfo)).data;
    });
  }
  static createContact(userId, invoiceEmail, phoneNumber) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/billing/contact", { userId, invoiceEmail, phoneNumber })).data;
    });
  }
}
