"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Autocomplete, Box, FormControl, IconButton, Paper, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { UserAddressSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { getCountriesForNationality } from "@/v2/infrastructure/country/country.util";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { StyledMenuItem } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map(
    (r) => {
      var _a;
      return pipe(
        (_a = r.options) != null ? _a : [],
        RA.map((x) => __spreadProps(__spreadValues({}, x), { group: r.label }))
      );
    }
  ),
  RA.flatten
);
const iconSize = { width: "14px", height: "14px" };
export const AddressForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  handleRefresh,
  usedForDataImport = false,
  importHandler = () => {
  },
  showEmployee = false
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const mode = initialValues ? "edit" : "add";
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const validationSchema = useMemo(() => UserAddressSchema(polyglot), [polyglot]);
  const formik = useFormik({
    initialValues: __spreadValues({
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      postcode: "",
      effectiveDate: new LocalDate().toDateString(),
      customUpdates: []
    }, initialValues),
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      if (!values.effectiveDate || !values.country) {
        return;
      }
      try {
        setLoading(true);
        if (!usedForDataImport) {
          if ("userAddressId" in values) {
            yield UserAddressAPI.update(userId, values);
            onSubmit == null ? void 0 : onSubmit();
            handleRefresh();
          } else {
            yield UserAddressAPI.create(userId, values);
            onSubmit == null ? void 0 : onSubmit();
            handleRefresh();
          }
          showMessage(polyglot.t("AddressForm.successMessages.update"), "success");
        } else {
          importHandler == null ? void 0 : importHandler(values);
        }
      } catch (error) {
        showMessage(polyglot.t("AddressForm.errorMessages.save", { msg: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const deleteAddress = () => __async(void 0, null, function* () {
    if (!("userAddressId" in formik.values)) return;
    try {
      setLoading(true);
      yield UserAddressAPI.delete(userId, formik.values.userAddressId);
      handleRefresh();
      onSubmit == null ? void 0 : onSubmit();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("AddressForm.errorMessages.saveTryAgain", { msg: nestErrorMessage(error) }), "error");
    } finally {
      setIsRemovalModalOpen(false);
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    mode === "add" ? /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AddressForm.new") }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AddressForm.edit") }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: iconCTAButtonSx,
          onClick: (event) => {
            setAnchorEl(event.currentTarget);
            setIsRemovalModalOpen(true);
          },
          children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
        }
      )
    ] }),
    showEmployee && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("AddressForm.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }) })
    ] }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "address.effectiveDate", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_a = formik.values.effectiveDate) != null ? _a : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("effectiveDate", value);
          }
        },
        name: "effectiveDate",
        label: polyglot.t("AddressForm.effectiveDate"),
        error: formik.touched.effectiveDate && !!formik.errors.effectiveDate,
        helperText: formik.touched.effectiveDate && formik.errors.effectiveDate
      }
    ) }) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "address.addressLine1", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine1",
        label: polyglot.t("AddressForm.addressLine1"),
        value: formik.values.addressLine1,
        onChange: formik.handleChange,
        error: formik.touched.addressLine1 && !!formik.errors.addressLine1,
        helperText: formik.touched.addressLine1 && formik.errors.addressLine1,
        clearText: () => formik.setFieldValue("addressLine1", ""),
        maxLength: 30,
        autoFocus: true
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "address.addressLine2", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine2",
        label: polyglot.t("AddressForm.addressLine2"),
        value: formik.values.addressLine2,
        onChange: formik.handleChange,
        error: formik.touched.addressLine2 && !!formik.errors.addressLine2,
        helperText: formik.touched.addressLine2 && formik.errors.addressLine2,
        clearText: () => formik.setFieldValue("addressLine2", ""),
        maxLength: 30
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "address.city", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "city",
        label: polyglot.t("AddressForm.city"),
        value: formik.values.city,
        onChange: formik.handleChange,
        error: formik.touched.city && !!formik.errors.city,
        helperText: formik.touched.city && formik.errors.city,
        clearText: () => formik.setFieldValue("city", ""),
        maxLength: 30
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "address.postcode", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "postcode",
        label: polyglot.t("AddressForm.postCode"),
        value: formik.values.postcode,
        onChange: formik.handleChange,
        error: formik.touched.postcode && !!formik.errors.postcode,
        helperText: formik.touched.postcode && formik.errors.postcode,
        clearText: () => formik.setFieldValue("postcode", ""),
        maxLength: 20
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "address.country", children: /* @__PURE__ */ jsx(FormControl, { fullWidth: true, children: /* @__PURE__ */ jsx(
      Autocomplete,
      {
        fullWidth: true,
        options: countryOptions,
        getOptionLabel: (option) => option.label,
        value: (_b = countryOptions.find(({ value }) => value === formik.values.country)) != null ? _b : null,
        renderOption: (props, option) => {
          return option.value === formik.values.country ? /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "title4", children: option.label }),
                /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
              ]
            }
          ) })) : /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: option.label }) }));
        },
        groupBy: (option) => option.group,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("country", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : "");
        },
        renderInput: (params) => /* @__PURE__ */ jsx(
          StyledAutoTextfield,
          __spreadProps(__spreadValues({}, params), {
            variant: "standard",
            InputLabelProps: { shrink: true },
            size: "small",
            label: polyglot.t("AddressForm.country"),
            name: "country",
            error: formik.touched.country && !!formik.errors.country,
            helperText: formik.touched.country && formik.errors.country
          })
        ),
        PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children }),
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, {}),
        clearIcon: /* @__PURE__ */ jsx(ClearIcon, {})
      }
    ) }) }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        rowModalMode: mode,
        formName: CustomProfileFormType.Address
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => setIsRemovalModalOpen(false),
        anchorEl,
        takeAction: deleteAddress,
        message: polyglot.t("AddressForm.confirmDelete"),
        callToAction: polyglot.t("AddressForm.callToAction")
      }
    )
  ] }) });
};
