"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  ZeltMdmDevicePolicyType,
  ZeltPolicyPayloadIdentifier
} from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@v2/styles/colors.styles";
import { StyledRadio } from "@v2/styles/radio.styles";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const ZeltMdmOsUpdatesPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  selectedRestriction,
  isOpen,
  setIsOpen
}) => {
  const [osUpdateDelayHelper, setOsUpdateDelayHelper] = useState("");
  const [appUpdateDelayHelper, setAppUpdateDelayHelper] = useState("");
  const getPolicy = () => {
    var _a, _b, _c, _d;
    return {
      automaticOsUpdate: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAutomaticOsUpdate) == null ? void 0 : _a.automaticOsUpdate) != null ? _b : false,
      installAction: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAutomaticOsUpdate) == null ? void 0 : _c.installAction) != null ? _d : "Default"
    };
  };
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(policy) {
    if (!policy.automaticOsUpdate) {
      return {
        automaticOsUpdate: policy.automaticOsUpdate
      };
    }
    return {
      automaticOsUpdate: policy.automaticOsUpdate,
      installAction: policy.installAction
    };
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableAutomaticOsUpdate: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  const getOsUpdatesRestrictions = () => {
    var _a, _b, _c, _d;
    return {
      enforcedSoftwareUpdateDelay: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableOsUpdateRestrictions) == null ? void 0 : _a.enforcedSoftwareUpdateDelay) !== void 0 ? (_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableOsUpdateRestrictions) == null ? void 0 : _b.enforcedSoftwareUpdateDelay : 0,
      enforcedSoftwareUpdateNonOSDeferredInstallDelay: ((_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableOsUpdateRestrictions) == null ? void 0 : _c.enforcedSoftwareUpdateNonOSDeferredInstallDelay) !== void 0 ? (_d = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableOsUpdateRestrictions) == null ? void 0 : _d.enforcedSoftwareUpdateNonOSDeferredInstallDelay : 0,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.OS_UPDATES_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const osUpdatesRestrictionsValues = getOsUpdatesRestrictions();
  function analyzeOsUpdateRestrictionsPolicyBeforeSave(policy) {
    if (policy.enforcedSoftwareUpdateDelay === 0 && policy.enforcedSoftwareUpdateNonOSDeferredInstallDelay === 0) {
      return void 0;
    }
    return __spreadProps(__spreadValues(__spreadValues({}, policy.enforcedSoftwareUpdateDelay !== 0 && {
      enforcedSoftwareUpdateDelay: policy.enforcedSoftwareUpdateDelay,
      forceDelayedSoftwareUpdates: true
    }), policy.enforcedSoftwareUpdateNonOSDeferredInstallDelay !== 0 && {
      enforcedSoftwareUpdateNonOSDeferredInstallDelay: policy.enforcedSoftwareUpdateNonOSDeferredInstallDelay,
      forceDelayedAppSoftwareUpdates: true
    }), {
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.OS_UPDATES_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    });
  }
  const formikRestrictions = useFormik({
    initialValues: osUpdatesRestrictionsValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzeOsUpdateRestrictionsPolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableOsUpdateRestrictions: configurablePolicy
      }));
    })
  });
  function setHelperValues(osUpdatesRestrictions) {
    var _a, _b;
    if (osUpdatesRestrictions.enforcedSoftwareUpdateDelay && osUpdatesRestrictions.enforcedSoftwareUpdateDelay === 0) {
      setOsUpdateDelayHelper("");
    } else if (osUpdatesRestrictions.enforcedSoftwareUpdateDelay && osUpdatesRestrictions.enforcedSoftwareUpdateDelay !== 0) {
      setOsUpdateDelayHelper((_a = osUpdatesRestrictions.enforcedSoftwareUpdateDelay) == null ? void 0 : _a.toString());
    }
    if (osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay && osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay === 0) {
      setAppUpdateDelayHelper("");
    } else if (osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay && osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay !== 0) {
      setAppUpdateDelayHelper((_b = osUpdatesRestrictions.enforcedSoftwareUpdateNonOSDeferredInstallDelay) == null ? void 0 : _b.toString());
    }
  }
  useEffect(() => {
    formik.validateForm();
    formikRestrictions.validateForm();
    const osUpdatesRestrictions = getOsUpdatesRestrictions();
    setHelperValues(osUpdatesRestrictions);
  }, []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    selectedRestriction === ZeltMdmDevicePolicyType.osupdate_automatic && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "OS updates" }),
      /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap80, mt: "20px" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Automatic updates" }),
          /* @__PURE__ */ jsx(
            SwitchComponent,
            {
              checked: formik.values.automaticOsUpdate,
              name: "automaticOsUpdate",
              sx: { marginLeft: "auto" },
              onChange: (_e, enabled) => __async(void 0, null, function* () {
                formik.setFieldValue("automaticOsUpdate", enabled);
              })
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { width: "80%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey, my: spacing.m5 }, children: "Installing operating system updates automatically" }) }),
        formik.values.automaticOsUpdate && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Box, { sx: { my: "20px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Install action type" }) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
            RadioGroup,
            {
              name: "installAction",
              onChange: (event) => {
                formik.setFieldValue("installAction", event.target.value);
              },
              children: [
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: "Default",
                    checked: formik.values.installAction === "Default",
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsxs(Box, { children: [
                      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Default" }),
                      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: "Download or install the update, depending on the current state" })
                    ] }),
                    sx: { mb: spacing.m20 }
                  },
                  "no-rounding"
                ),
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: "InstallASAP",
                    checked: formik.values.installAction === "InstallASAP",
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsxs(Box, { children: [
                      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Install ASAP" }),
                      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: "Download the software update, install immediately and trigger the restart countdown notification" })
                    ] }),
                    sx: { mb: spacing.m20 }
                  },
                  "no-rounding"
                ),
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: "InstallForceRestart",
                    checked: formik.values.installAction === "InstallForceRestart",
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsxs(Box, { children: [
                      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Install and force restart" }),
                      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: "Perform the Default action, and then force a restart" })
                    ] }),
                    sx: { mb: spacing.m20 }
                  },
                  "no-rounding"
                )
              ]
            }
          ) }) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => formik.handleSubmit(),
            fullWidth: true,
            colorVariant: "primary",
            sizeVariant: "medium",
            children: "Save"
          }
        ) })
      ] })
    ] }),
    selectedRestriction === ZeltMdmDevicePolicyType.osupdate_restrictions && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "OS updates delay" }),
      /* @__PURE__ */ jsxs(FormikProvider, { value: formikRestrictions, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: "20px" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Delay operating system updates (days)",
              name: "enforcedSoftwareUpdateDelay",
              type: "number",
              value: osUpdateDelayHelper,
              minValue: 0,
              maxValue: 90,
              validateNumber: true,
              tooltip: "How many days to delay a software update on the device. The user doesn\u2019t see a software update until the specified number of days after the software update release date. Range 1-90",
              onChange: (e) => {
                let osUpdateDelay = e == null ? void 0 : e.target.value;
                if (osUpdateDelay === "0") {
                  setOsUpdateDelayHelper("");
                } else {
                  setOsUpdateDelayHelper(osUpdateDelay);
                }
                formikRestrictions.setFieldValue("enforcedSoftwareUpdateDelay", Number(osUpdateDelay));
              }
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Delay applications updates (days)",
              name: "enforcedSoftwareUpdateNonOSDeferredInstallDelay",
              type: "number",
              value: appUpdateDelayHelper,
              minValue: 0,
              maxValue: 90,
              validateNumber: true,
              tooltip: "How many days to delay an app software update on the device. The user sees a non-OS software update only after the specified delay after the release of the software. Range 1-90",
              onChange: (e) => {
                let appUpdateDelay = e == null ? void 0 : e.target.value;
                if (appUpdateDelay === "0") {
                  setAppUpdateDelayHelper("");
                } else {
                  setAppUpdateDelayHelper(appUpdateDelay);
                }
                formikRestrictions.setFieldValue(
                  "enforcedSoftwareUpdateNonOSDeferredInstallDelay",
                  Number(appUpdateDelay)
                );
              }
            }
          ) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center", marginTop: "20px" }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => formikRestrictions.handleSubmit(),
            fullWidth: true,
            colorVariant: "primary",
            sizeVariant: "medium",
            children: "Save"
          }
        ) })
      ] })
    ] })
  ] });
};
