"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Snackbar, Box, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { ReactComponent as InfoBlack } from "@/images/side-bar-icons/InfoBlack.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PersistentNotification = ({ inUse, message, maxWidth = "500px" }) => {
  return /* @__PURE__ */ jsx(
    Snackbar,
    {
      open: inUse,
      onClose: void 0,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      children: /* @__PURE__ */ jsx(
        MuiAlert,
        {
          sx: {
            width: "100%",
            backgroundColor: themeColors.black,
            borderRadius: radius.br10,
            boxShadow: "none",
            maxWidth
          },
          elevation: 10,
          variant: "filled",
          icon: false,
          children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "flex-start", gap: spacing.g10 }, children: [
            /* @__PURE__ */ jsx(InfoBlack, { style: { fill: themeColors.white }, width: 15, height: 15 }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.g10,
                  color: themeColors.white
                }),
                children: message
              }
            )
          ] })
        }
      )
    }
  );
};
