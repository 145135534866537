"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { getPublicHolidayCalendarOptions, NoPublicHolidayCalendarOption } from "@v2/feature/absence/absence.util";
import { getOverlapsRuleOptions } from "@v2/feature/absence/company/policies/pages/absence-company-settings-general.page";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { detailSx } from "@v2/styles/settings.styles";
export const AbsenceGeneralSettingsReadonly = ({ absenceSettings }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const holidayCalendar = (_b = (_a = getPublicHolidayCalendarOptions().find((option) => option.value === absenceSettings.holidayCountry)) == null ? void 0 : _a.label) != null ? _b : NoPublicHolidayCalendarOption.label;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      ReadOnlyRow,
      {
        label: polyglot.t("AbsenceGeneralSettings.defaultHolidayCountry"),
        value: holidayCalendar
      },
      "public-holiday-calendar"
    ),
    /* @__PURE__ */ jsx(
      ReadOnlyRow,
      {
        label: polyglot.t("AbsenceGeneralSettings.preventOwnOverlaps"),
        value: polyglot.t(
          absenceSettings.preventOwnOverlaps ? "AbsenceGeneralSettings.on" : "AbsenceGeneralSettings.off"
        )
      },
      "prevent-own-overlaps"
    ),
    /* @__PURE__ */ jsx(
      ReadOnlyRow,
      {
        label: polyglot.t("AbsenceGeneralSettings.overlapSummary"),
        value: polyglot.t(absenceSettings.showOverlap ? "AbsenceGeneralSettings.on" : "AbsenceGeneralSettings.off")
      },
      "overlap-summary"
    ),
    absenceSettings.showOverlap && /* @__PURE__ */ jsx(
      ReadOnlyRow,
      {
        label: polyglot.t("AbsenceGeneralSettings.showOverlapWith"),
        value: (_d = (_c = getOverlapsRuleOptions(polyglot).find((o) => o.value === absenceSettings.overlapsRule)) == null ? void 0 : _c.label) != null ? _d : ""
      },
      "overlaps-rule"
    )
  ] });
};
const ReadOnlyRow = ({ label, value }) => /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
  /* @__PURE__ */ jsx(Typography, { sx: { maxWidth: { sm: "90px", md: "190px", lg: "190px" }, width: "100%" }, variant: "caption", children: label }),
  /* @__PURE__ */ jsx(Typography, { sx: { maxWidth: { sm: "200px", md: "400px", lg: "400px" }, width: "100%" }, variant: "title4", children: value })
] });
