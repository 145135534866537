"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BidirectionalButton } from "@v2/components/bidirectional-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { AttendanceAPI } from "@/v2/feature/attendance/attendance.api";
import { AttendanceStatus } from "@/v2/feature/attendance/attendance.interface";
import { AttendanceMeContent } from "@/v2/feature/attendance/me/components/attendance-me-content.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
export const AttendanceMeView = () => {
  const { polyglot } = usePolyglot();
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [state] = useContext(GlobalContext);
  const [year, setYear] = useState((/* @__PURE__ */ new Date()).getFullYear());
  const [attendanceWeekList, setAttendanceWeekList] = useState(void 0);
  const [selectedWeekAndYear, setSelectedWeekAndYear] = useState(void 0);
  const [weeklyEntries, setWeeklyEntries] = useState(void 0);
  const [searchInput, setSearchInput] = useState("");
  const [showMessage] = useMessage();
  const getUserAttendances = useCallback(() => __async(void 0, null, function* () {
    var _a;
    setLoading(true);
    setUserLoading(true);
    setSelectedWeekAndYear(void 0);
    setAttendanceWeekList(void 0);
    setWeeklyEntries(void 0);
    try {
      const requestsResponse = yield AttendanceAPI.getWeekListByUserId(state.user.userId, year, searchInput);
      setTotalItems(requestsResponse.totalItems);
      setAttendanceWeekList(requestsResponse.items);
      if (!((_a = requestsResponse == null ? void 0 : requestsResponse.items) == null ? void 0 : _a.length)) return;
      const weeklyEntriesResponse = yield AttendanceAPI.getPersonalAttendanceEntriesByWeekNo(
        state.user.userId,
        requestsResponse == null ? void 0 : requestsResponse.items[0].weekNo,
        requestsResponse == null ? void 0 : requestsResponse.items[0].year
      );
      setSelectedWeekAndYear(`${requestsResponse == null ? void 0 : requestsResponse.items[0].weekNo}/${requestsResponse == null ? void 0 : requestsResponse.items[0].year}`);
      setWeeklyEntries(weeklyEntriesResponse);
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserAttendances", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
      setUserLoading(false);
    }
  }), [polyglot, searchInput, showMessage, state.user.userId, year]);
  useEffect(() => {
    getUserAttendances();
  }, [getUserAttendances]);
  const weekList = useMemo(() => {
    return attendanceWeekList ? attendanceWeekList.map((n) => {
      return {
        id: `${n.weekNo}/${n.year}`,
        name: polyglot.t("UserAttendancePage.weekNum", { weekNum: n.weekNo }),
        details: Number(n.noOfTimeSheets) > 0 ? polyglot.t("AttendanceDomain.noOfTimesheets", { smart_count: n.noOfTimeSheets }) : polyglot.t("AttendanceDomain.noTimesheets"),
        showAlert: n.status === AttendanceStatus.Submitted,
        alertColor: n.status === AttendanceStatus.Submitted ? themeColors.Grey : "none",
        highlightTitle: n.status === AttendanceStatus.Submitted
      };
    }) : [];
  }, [polyglot, attendanceWeekList]);
  const getWeeklyDataByWeekNo = (weekAndYear) => __async(void 0, null, function* () {
    setUserLoading(true);
    try {
      setSelectedWeekAndYear(weekAndYear);
      const weekAndYearArray = weekAndYear.split("/");
      const weeklyEntriesResponse = yield AttendanceAPI.getPersonalAttendanceEntriesByWeekNo(
        state.user.userId,
        Number(weekAndYearArray[0]),
        Number(weekAndYearArray[1])
      );
      setWeeklyEntries(weeklyEntriesResponse);
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserAttendanceDetails", {
          nestError: nestErrorMessage(error)
        }),
        "error"
      );
      setSelectedWeekAndYear(void 0);
    } finally {
      setUserLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceDomain.Attendance") }),
        actions: /* @__PURE__ */ jsx(
          BidirectionalButton,
          {
            navigateBack: () => setYear((prev) => prev - 1),
            navigateNext: () => setYear((prev) => prev + 1),
            buttonTitle: String(year),
            disableNext: year === (/* @__PURE__ */ new Date()).getFullYear()
          }
        ),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: { pt: 0 }, children: /* @__PURE__ */ jsx(
      AttendanceMeContent,
      {
        searchInput,
        setSearchInput,
        weekList,
        refreshWeekList: getUserAttendances,
        loading,
        selectedWeekAndYear,
        weeklyEntries,
        userLoading,
        refresh: getWeeklyDataByWeekNo,
        userId: state.user.userId,
        totalItems
      }
    ) })
  ] });
};
