"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const ZeltMdmScreenSettingsSecurityPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const getPolicy = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
    return {
      allowWallpaperModification: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _a.allowWallpaperModification) !== void 0 ? !((_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _b.allowWallpaperModification) : false,
      SHOWFULLNAME: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _c.SHOWFULLNAME) != null ? _d : false,
      LoginwindowText: (_f = (_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _e.LoginwindowText) != null ? _f : "",
      ShutDownDisabled: (_h = (_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _g.ShutDownDisabled) != null ? _h : false,
      SleepDisabled: (_j = (_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _i.SleepDisabled) != null ? _j : false,
      RestartDisabled: (_l = (_k = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _k.RestartDisabled) != null ? _l : false,
      DisableConsoleAccess: (_n = (_m = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableScreenSettingPolicy) == null ? void 0 : _m.DisableConsoleAccess) != null ? _n : false
    };
  };
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(policy) {
    if (!policy.allowWallpaperModification && !policy.SHOWFULLNAME && policy.LoginwindowText === "" && !policy.ShutDownDisabled && !policy.SleepDisabled && !policy.RestartDisabled && !policy.DisableConsoleAccess) {
      return void 0;
    }
    return {
      allowWallpaperModification: !policy.allowWallpaperModification,
      SHOWFULLNAME: policy.SHOWFULLNAME,
      LoginwindowText: policy.LoginwindowText,
      ShutDownDisabled: policy.ShutDownDisabled,
      SleepDisabled: policy.SleepDisabled,
      RestartDisabled: policy.RestartDisabled,
      DisableConsoleAccess: policy.DisableConsoleAccess
    };
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableScreenSettingPolicy: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Screen" }),
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 2 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Login welcome text",
          name: "LoginwindowText",
          value: formik.values.LoginwindowText,
          onChange: formik.handleChange,
          error: formik.touched.LoginwindowText && !!formik.errors.LoginwindowText,
          helperText: formik.touched.LoginwindowText && formik.errors.LoginwindowText,
          autoFocus: true
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: "Login window - shows the name and password",
          name: "SHOWFULLNAME",
          checked: formik.values.SHOWFULLNAME,
          onChange: (e, checked) => {
            formik.setFieldValue("SHOWFULLNAME", checked);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: "Hide shut down",
          name: "ShutDownDisabled",
          checked: formik.values.ShutDownDisabled,
          onChange: (e, checked) => {
            formik.setFieldValue("ShutDownDisabled", checked);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: "Hide sleep",
          name: "SleepDisabled",
          checked: formik.values.SleepDisabled,
          onChange: (e, checked) => {
            formik.setFieldValue("SleepDisabled", checked);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: "Hide restart",
          name: "RestartDisabled",
          checked: formik.values.RestartDisabled,
          onChange: (e, checked) => {
            formik.setFieldValue("RestartDisabled", checked);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: "Disable console access",
          name: "DisableConsoleAccess",
          checked: formik.values.DisableConsoleAccess,
          onChange: (e, checked) => {
            formik.setFieldValue("DisableConsoleAccess", checked);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: "Disable wallpaper change",
          name: "allowWallpaperModification",
          checked: formik.values.allowWallpaperModification,
          onChange: (e, checked) => {
            formik.setFieldValue("allowWallpaperModification", checked);
          }
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: 2 }, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => formik.handleSubmit(),
          fullWidth: true,
          colorVariant: "primary",
          sizeVariant: "medium",
          children: "Save"
        }
      ) })
    ] }) })
  ] });
};
