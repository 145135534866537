"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { UserBenefitExpensePage } from "@v2/feature/benefits/subfeature/custom-benefit/components/user-benefit-expense.page";
import { UserCustomBenefitDetailsPage } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-details.page";
import { matchPath, Redirect, Switch, useLocation } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  CUSTOM_BENEFIT_EXPENSE_ROUTE,
  CUSTOM_BENEFIT_ME_ROUTE,
  CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE
} from "@/lib/routes";
export const CustomBenefitUserRouter = ({
  userId,
  userBenefits,
  loading,
  refreshCustomBenefits
}) => {
  const location = useLocation();
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance"],
        context: { userId },
        path: [CUSTOM_BENEFIT_OVERVIEW_ROUTE, USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE],
        children: /* @__PURE__ */ jsx(
          UserCustomBenefitDetailsPage,
          {
            userId,
            userBenefits,
            loading,
            refreshCustomBenefits
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance"],
        context: { userId },
        path: [CUSTOM_BENEFIT_EXPENSE_ROUTE, USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE],
        children: /* @__PURE__ */ jsx(UserBenefitExpensePage, { userId, userBenefits })
      }
    ),
    matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE) ? /* @__PURE__ */ jsx(Redirect, { to: USER_BENEFITS_CUSTOM_BENEFIT_ROUTE }) : /* @__PURE__ */ jsx(Redirect, { to: CUSTOM_BENEFIT_ME_ROUTE })
  ] });
};
