"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2 } from "react";
import { Redirect, Switch, generatePath, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  REVIEWS_ME_RESULT_DETAIL_OVERVIEW_ROUTE,
  REVIEWS_ME_RESULT_DETAIL_RADAR_ROUTE,
  REVIEWS_ME_RESULT_ROUTE
} from "@/lib/routes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import { ReviewCycleDetailResultRadar } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-result-radar/review-cycle-detail-result-radar.page";
import { ReviewCycleDetailResultsOverviewPage } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/review-cycle-detail-results-overview.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ReachType } from "@/v2/interfaces/general.interface";
const getPageConfig = (cycleId, revieweeId, navigation) => {
  return [
    {
      title: "",
      stub: "detail",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: "Results",
          stub: "results",
          path: generatePath(REVIEWS_ME_RESULT_DETAIL_OVERVIEW_ROUTE, { cycleId, revieweeId }),
          isHidden: false,
          hasChildren: false
        },
        ...navigation && navigation.radarPage ? [
          {
            title: "Radar chart",
            stub: "chart",
            path: generatePath(REVIEWS_ME_RESULT_DETAIL_RADAR_ROUTE, { cycleId, revieweeId }),
            isHidden: false,
            hasChildren: false
          }
        ] : []
      ]
    }
  ];
};
export const ReviewCycleDetailResultsMeRouter = () => {
  const params = useParams();
  const { cycleId, revieweeId } = params;
  const { data: navigation } = useApiClient(ReviewResultEndpoints.getReviewResultNavigation(cycleId), {
    suspense: false
  });
  const { data: reviewCycle, isLoading: cycleLoading } = useApiClient(
    ReviewCycleEndpoints.getUserCycleById(cycleId, Number(revieweeId)),
    {
      suspense: false
    }
  );
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Reviews",
        pageConfig: getPageConfig(cycleId, revieweeId, navigation),
        backPath: REVIEWS_ME_RESULT_ROUTE,
        showBack: true,
        type: "Domain"
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews"], path: REVIEWS_ME_RESULT_DETAIL_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(
        ReviewCycleDetailResultsOverviewPage,
        {
          reviewCycle,
          cycleLoading,
          reachType: ReachType.Me
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews"], path: REVIEWS_ME_RESULT_DETAIL_RADAR_ROUTE, children: /* @__PURE__ */ jsx(
        ReviewCycleDetailResultRadar,
        {
          reviewCycle,
          cycleLoading,
          reachType: ReachType.Me
        }
      ) }),
      /* @__PURE__ */ jsx(Redirect, { to: REVIEWS_ME_RESULT_DETAIL_OVERVIEW_ROUTE, exact: true })
    ] })
  ] });
};
