"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { keyBy } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewTemplateAPI, ReviewTemplateEndpoints } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { PlaceholderBox } from "@/v2/feature/growth/reviews/features/components/placeholder-box.component";
import { QuestionBuilder } from "@/v2/feature/growth/reviews/features/components/question-builder.component";
import { SectionBuilder } from "@/v2/feature/growth/reviews/features/components/section-builder.component";
import { CycleCreationQuestionPreviewer } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-question-previewer.component";
import { CycleCreationQuestionSelectModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/review-cycle-creation-questions/components/cycle-creation-question-select-modal.component";
import { ReviewTemplateMoveToSectionModal } from "@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-move-to-section-modal.component";
import { ReviewTemplateQuestionModal } from "@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-question-modal.component";
import { ReviewTemplateSectionModal } from "@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-section-modal.component";
import { useTemplateQuestionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-template-question-options.hook";
import { useTemplateSectionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-template-section-options.hook";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewTemplateBuilderPage = ({
  templateObject,
  refreshTemplate
}) => {
  var _a, _b, _c;
  const params = useParams();
  const templateId = params.templateId;
  const { data: bankCount } = useApiClient(ReviewTemplateEndpoints.getBankCount(), { suspense: false });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const [question, setQuestion] = useState(null);
  const [section, setSection] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(void 0);
  const [isPreviewerOpen, setIsPreviewerOpen] = useState(false);
  const [previewReviewer, setPreviewReviewer] = useState(void 0);
  let template;
  let sections;
  let questions;
  if (templateObject) {
    ({ template, sections, questions } = templateObject);
  }
  const getReviewerSelects = useMemo(() => {
    if (!questions) return [];
    const allReviewerSelects = questions == null ? void 0 : questions.flatMap((q) => q.reviewerSelect);
    return allReviewerSelects;
  }, [questions]);
  const renderQuestion = (questionId) => {
    if (!questions || !template) return null;
    const question2 = questions.find((q) => q.id === questionId);
    if (!question2) return null;
    return /* @__PURE__ */ jsx(
      QuestionComponent,
      {
        question: question2,
        template,
        sectionId: null,
        setQuestion,
        setIsQuestionModalOpen,
        refreshTemplate,
        setIsMoveToSectionOpen,
        setSelectedQuestionId,
        hasSections: Boolean(sections && sections.length > 0),
        growthFactors
      },
      question2.id
    );
  };
  const renderSection = (sectionId) => {
    var _a2;
    const section2 = sections == null ? void 0 : sections.find((s) => s.id === sectionId);
    if (!section2 || !template) return null;
    const orderSection = template == null ? void 0 : template.order.find((o) => o.id === sectionId && o.type === "section");
    if (!orderSection) return null;
    const orderedQuestions = ((_a2 = orderSection.questions) != null ? _a2 : []).map((questionId) => questions == null ? void 0 : questions.find((q) => q.id === questionId)).filter((question2) => Boolean(question2));
    const handleDragDrop = (results) => __async(void 0, null, function* () {
      const { source, destination, type } = results;
      if (!template) return;
      if (type !== "group") return;
      const sectionIndex = template == null ? void 0 : template.order.findIndex((o) => o.type === "section" && o.id === section2.id);
      if (sectionIndex === -1) return;
      const reOrdered = __spreadValues({}, template.order[sectionIndex]);
      if (!reOrdered || !reOrdered.questions) return;
      const sourceIndex = source.index;
      const destinationIndex = destination == null ? void 0 : destination.index;
      if (sourceIndex === -1 || destinationIndex === void 0 || destinationIndex === -1) return;
      const [removed] = reOrdered.questions.splice(sourceIndex, 1);
      reOrdered.questions.splice(destinationIndex, 0, removed);
      const updatedOrder = [...template.order];
      updatedOrder[sectionIndex] = reOrdered;
      yield ReviewTemplateAPI.updateOrderReviewTemplate({ templateId, order: updatedOrder });
      yield refreshTemplate == null ? void 0 : refreshTemplate();
    });
    return /* @__PURE__ */ jsx(
      TemplateBuilderSection,
      {
        section: section2,
        setSection,
        setIsQuestionModalOpen,
        setIsSectionModalOpen,
        questions: orderedQuestions,
        handleDragDrop,
        template,
        setQuestion,
        refreshTemplate,
        setIsMoveToSectionOpen,
        setSelectedQuestionId,
        sections,
        growthFactors
      },
      section2.id
    );
  };
  const addQuestionsToTemplateHandle = (selectedQuestionIds) => __async(void 0, null, function* () {
    try {
      yield ReviewTemplateAPI.addQuestionsToTemplate([...selectedQuestionIds], templateId);
      refreshTemplate == null ? void 0 : refreshTemplate();
    } catch (error) {
    }
  });
  const moveQuestionInsideSection = (sectionId, questionId) => __async(void 0, null, function* () {
    try {
      yield ReviewTemplateAPI.moveQuestionInsideSection({ templateId, sectionId, questionId });
      refreshTemplate == null ? void 0 : refreshTemplate();
    } catch (error) {
    }
  });
  const getReviewerActionsOptions = useCallback(() => {
    return [
      ...getReviewerSelects.includes(ReviewerTypes.Self) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Self);
          },
          label: ReviewerTypes.Self,
          disabled: false
        }
      ] : [],
      ...getReviewerSelects.includes(ReviewerTypes.Manager) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Manager);
          },
          label: ReviewerTypes.Manager,
          disabled: false
        }
      ] : [],
      ...getReviewerSelects.includes(ReviewerTypes.Upward) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Upward);
          },
          label: ReviewerTypes.Upward,
          disabled: false
        }
      ] : [],
      ...getReviewerSelects.includes(ReviewerTypes.Peer) ? [
        {
          handler: () => {
            setIsPreviewerOpen(true);
            setPreviewReviewer(ReviewerTypes.Peer);
          },
          label: ReviewerTypes.Peer,
          disabled: false
        }
      ] : []
    ];
  }, [getReviewerSelects]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        height: "100%",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            className: "sub-title",
            sx: {
              px: spacing.p16,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: spacing.m4
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Questions" }),
              getReviewerSelects.length > 1 && /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: getReviewerActionsOptions(),
                  actionButtonDetails: {
                    type: "button",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "Preview"
                  }
                }
              )
            ]
          }
        ),
        (template == null ? void 0 : template.companyId) && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m24, width: "100%", boxSizing: "border-box", px: spacing.p16 }, children: /* @__PURE__ */ jsx(
          PlaceholderBox,
          {
            title: "Select questions from library",
            countOne: `${(_a = bankCount == null ? void 0 : bankCount.totalCategories) != null ? _a : 0} factors`,
            countTwo: `${(_b = bankCount == null ? void 0 : bankCount.totalQuestions) != null ? _b : 0} questions`,
            action: () => setIsQuestionBankOpen(true)
          }
        ) }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({}, template && template.order ? {} : { margin: spacing.m16, border: `1px dashed ${themeColors.Background}` }), {
              mt: spacing.m24,
              height: "100%"
            }),
            children: [
              /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    flexDirection: "column"
                  },
                  children: template && ((_c = template.order) == null ? void 0 : _c.map((item) => {
                    if (item.type === "question") {
                      return renderQuestion(item.id);
                    } else if (item.type === "section") {
                      return renderSection(item.id);
                    }
                    return /* @__PURE__ */ jsx(Fragment, {});
                  }))
                }
              ),
              (template == null ? void 0 : template.companyId) && /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    gap: spacing.g5,
                    mt: template && template.order ? spacing.m24 : "0px",
                    padding: spacing.p16
                  },
                  children: [
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsSectionModalOpen(true),
                        children: "New section"
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsQuestionModalOpen(true),
                        children: "New question"
                      }
                    )
                  ]
                }
              ),
              /* @__PURE__ */ jsx(
                ReviewTemplateQuestionModal,
                {
                  setIsOpen: setIsQuestionModalOpen,
                  isOpen: isQuestionModalOpen,
                  question,
                  onClose: () => {
                    setIsQuestionModalOpen(false);
                    setQuestion(null);
                    setSection(null);
                  },
                  refresh: () => __async(void 0, null, function* () {
                    yield refreshTemplate == null ? void 0 : refreshTemplate();
                  }),
                  section,
                  templateId
                }
              ),
              /* @__PURE__ */ jsx(
                ReviewTemplateSectionModal,
                {
                  setIsOpen: setIsSectionModalOpen,
                  isOpen: isSectionModalOpen,
                  section,
                  onClose: () => {
                    setIsSectionModalOpen(false);
                    setSection(null);
                    setQuestion(null);
                  },
                  refresh: () => __async(void 0, null, function* () {
                    yield refreshTemplate == null ? void 0 : refreshTemplate();
                  }),
                  templateId
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionSelectModal,
          {
            setIsQuestionsModalOpen: setIsQuestionBankOpen,
            isQuestionsModalOpen: isQuestionBankOpen,
            action: addQuestionsToTemplateHandle,
            excludeQuestions: questions || []
          }
        ),
        /* @__PURE__ */ jsx(
          ReviewTemplateMoveToSectionModal,
          {
            isOpen: isMoveToSectionOpen,
            setIsOpen: setIsMoveToSectionOpen,
            onClose: () => {
              setIsMoveToSectionOpen(false);
            },
            action: moveQuestionInsideSection,
            sections,
            questionId: selectedQuestionId
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionPreviewer,
          {
            isPreviewerOpen,
            setIsPreviewerOpen,
            previewReviewer,
            allQuestions: questions || [],
            allSections: sections || [],
            order: (template == null ? void 0 : template.order) || []
          }
        )
      ]
    }
  );
};
const TemplateBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  setIsMoveToSectionOpen,
  questions,
  handleDragDrop,
  template,
  setQuestion,
  refreshTemplate,
  setSelectedQuestionId,
  sections,
  growthFactors
}) => {
  const sectionOptions = useTemplateSectionOptions(
    template,
    section,
    setSection,
    refreshTemplate,
    setIsSectionModalOpen
  );
  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };
  return /* @__PURE__ */ jsx(
    SectionBuilder,
    {
      section,
      sectionOptions,
      dragQuestionComponent: /* @__PURE__ */ jsx(DragDropContext, { onDragEnd: handleDragDrop, children: /* @__PURE__ */ jsx(Droppable, { droppableId: "ROOT", type: "group", isDropDisabled: false, children: (provided) => /* @__PURE__ */ jsxs("div", __spreadProps(__spreadValues({}, provided.droppableProps), { ref: provided.innerRef, children: [
        questions && questions.map((question, idx) => /* @__PURE__ */ jsx(Draggable, { isDragDisabled: false, draggableId: question.id, index: idx, children: (provided2) => /* @__PURE__ */ jsx("div", __spreadProps(__spreadValues(__spreadValues({}, provided2.dragHandleProps), provided2.draggableProps), { ref: provided2.innerRef, children: /* @__PURE__ */ jsx(
          QuestionComponent,
          {
            question,
            template,
            sectionId: section.id,
            setQuestion,
            setIsQuestionModalOpen,
            refreshTemplate,
            setIsMoveToSectionOpen,
            setSelectedQuestionId,
            hasSections: Boolean(sections && sections.length > 0),
            growthFactors
          }
        ) })) }, question.id)),
        provided.placeholder
      ] })) }) }),
      newQuestionAction,
      showAction: true
    }
  );
};
const QuestionComponent = ({
  question,
  template,
  sectionId,
  setQuestion,
  setIsQuestionModalOpen,
  refreshTemplate,
  setIsMoveToSectionOpen,
  setSelectedQuestionId,
  hasSections,
  growthFactors
}) => {
  const questionOptions = useTemplateQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    template,
    sectionId,
    refreshTemplate,
    setSelectedQuestionId,
    hasSections
  );
  return /* @__PURE__ */ jsx(
    QuestionBuilder,
    {
      questionOptions,
      question,
      growthFactors,
      showAction: Boolean(template.companyId)
    }
  );
};
