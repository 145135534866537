"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell, IconEmptyCell } from "@v2/components/table/empty-cell.component";
import { TableFilter } from "@v2/components/table/table-filter.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { DeviceOrderStatus, DevicePossessionType } from "@v2/feature/device/device.interface";
import {
  getCompanyNamesKeyedByCompanyIds,
  getDeviceOwnerAsSuperadminByDevicePossession,
  getModelImage,
  getOrderStatus,
  getUserNamesKeyedByUserIds
} from "@v2/feature/device/device.util";
import { SuperAdminDeviceOrderDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-order-drawer.component";
import { filterStringToObject } from "@v2/feature/user/user.util";
import { spacing } from "@v2/styles/spacing.styles";
export const SuperAdminDeviceOrdersTable = ({
  deviceOrders,
  users,
  companies,
  refresh,
  devices,
  sites
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState(
    `Status=${DeviceOrderStatus.Placed},${DeviceOrderStatus.Accepted},${DeviceOrderStatus.Shipping}`
  );
  const [filterTypes, setFilterTypes] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(void 0);
  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  const companiesNames = useMemo(() => getCompanyNamesKeyedByCompanyIds(companies), [companies]);
  const getFilterOptions = useCallback(() => __async(void 0, null, function* () {
    const orderStatus = [
      { label: "Requested", value: DeviceOrderStatus.Requested },
      { label: "Cancelled", value: DeviceOrderStatus.Cancelled },
      { label: "Rejected", value: DeviceOrderStatus.Rejected },
      { label: "Accepted", value: DeviceOrderStatus.Accepted },
      { label: "Pending", value: DeviceOrderStatus.Placed },
      { label: "Shipping", value: DeviceOrderStatus.Shipping },
      { label: "Delivered", value: DeviceOrderStatus.Delivered }
    ];
    const FILTERS = ["Status"];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case "Status":
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: orderStatus });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), []);
  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);
  const filteredDevices = useMemo(() => {
    let filteredDeviceOrders = [...deviceOrders];
    if (searchInput) {
      filteredDeviceOrders = filteredDeviceOrders.filter((deviceOrder) => {
        var _a, _b;
        let userName;
        if (((_a = deviceOrder.possession) == null ? void 0 : _a.possessionType) === DevicePossessionType.User) {
          const user = users.find((user2) => {
            var _a2;
            return user2.userId === ((_a2 = deviceOrder.possession) == null ? void 0 : _a2.possessionId);
          });
          userName = user ? `${user.firstName} ${user.lastName}` : void 0;
        }
        const modelName = (_b = deviceOrder.deviceModel) == null ? void 0 : _b.modelName;
        return (userName == null ? void 0 : userName.toLowerCase().includes(searchInput.toLowerCase())) || (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase()));
      });
    }
    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case "Status": {
              filteredDeviceOrders = filteredDeviceOrders.filter((order) => {
                var _a;
                return (_a = filterOptions[key]) == null ? void 0 : _a.includes(order.status);
              });
              break;
            }
            default:
              break;
          }
        }
      }
    }
    return filteredDeviceOrders;
  }, [deviceOrders, searchInput, filterString, users]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Model",
        accessorFn: (row) => row,
        id: "deviceModelId",
        enableSorting: false,
        cell: (info) => {
          var _a, _b;
          const deviceOrder = info.getValue();
          return ((_a = deviceOrder.deviceModel) == null ? void 0 : _a.id) ? /* @__PURE__ */ jsx(Box, { children: (_b = deviceOrder.deviceModel) == null ? void 0 : _b.id }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        },
        size: 30
      },
      {
        header: () => "Device",
        accessorFn: (row) => row,
        id: "deviceModel.id",
        enableSorting: false,
        cell: (info) => {
          var _a, _b;
          const deviceOrder = info.getValue();
          return ((_a = deviceOrder.deviceModel) == null ? void 0 : _a.modelName) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Box, { children: deviceOrder && getModelImage(deviceOrder.deviceModel.type, deviceOrder.deviceModel.modelName, {
              width: "30px",
              height: "auto"
            }) }),
            (_b = deviceOrder.deviceModel) == null ? void 0 : _b.modelName
          ] }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        },
        size: 180
      },
      {
        header: () => "Price",
        accessorFn: (row) => row,
        id: "price",
        enableSorting: false,
        cell: (info) => {
          const deviceOrder = info.getValue();
          return /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            deviceOrder.price,
            " ",
            deviceOrder.isPurchase ? "purchase" : "per month"
          ] });
        },
        size: 140
      },
      {
        header: () => "Company",
        accessorFn: (row) => row,
        id: "company",
        enableSorting: false,
        cell: (info) => {
          const deviceOrder = info.getValue();
          const companyName = companiesNames[deviceOrder.companyId];
          return companyName ? /* @__PURE__ */ jsx(Box, { children: companyName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 140
      },
      {
        header: () => "Ordered for",
        accessorFn: (row) => row,
        id: "possessionId",
        enableSorting: false,
        cell: (info) => {
          const deviceOrder = info.getValue();
          return deviceOrder.possession ? /* @__PURE__ */ jsx(Box, { children: getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites) }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        },
        size: 180
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: (info) => {
          const deviceOrder = info.getValue();
          return /* @__PURE__ */ jsx(Fragment, { children: getOrderStatus(deviceOrder.status) });
        },
        size: 150
      },
      {
        header: () => "Delivery date",
        accessorFn: (row) => row,
        id: "deliveryDate",
        enableSorting: false,
        cell: (info) => {
          const deviceOrder = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: (deviceOrder == null ? void 0 : deviceOrder.deliveryDate) ? new Date(deviceOrder == null ? void 0 : deviceOrder.deliveryDate).toLocaleDateString() : /* @__PURE__ */ jsx(IconEmptyCell, {}) });
        },
        size: 180
      }
    ];
  }, [companiesNames, userNames, sites]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    deviceOrders.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex", justifyContent: "flex-start", width: "100%" }, spacing.mb20), { gap: 1 }), children: [
      filterTypes && /* @__PURE__ */ jsx(TableFilter, { filterTypes, setFilterString, filterString }),
      /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
        var _a, _b;
        return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
      } })
    ] }),
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredDevices,
        columnData,
        rowClick: (row) => {
          setSelectedRecord(row.original);
          setIsOpen(true);
        }
      }
    ),
    selectedRecord && isOpen && /* @__PURE__ */ jsx(
      SuperAdminDeviceOrderDrawer,
      {
        isOpen,
        setIsOpen,
        deviceOrder: selectedRecord,
        setDeviceOrder: setSelectedRecord,
        refresh,
        users,
        userNames,
        companyNames: companiesNames,
        devices,
        sites
      }
    )
  ] });
};
