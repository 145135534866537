"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2, Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { UserShiftHandler } from "@v2/feature/dashboard/features/sections/user-attendance/components/user-shift-handler.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import _, { keyBy } from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { DashboardLayoutLoader } from "@/v2/feature/dashboard/components/dashboard-layout-loader.component";
import { DashboardEndpoints } from "@/v2/feature/dashboard/dashboard.api";
import "@/v2/feature/dashboard/dashboard.scss";
import { DashboardEditMode } from "@/v2/feature/dashboard/features/dashboard-edit-mode.page";
import { UserAttendance } from "@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.section";
import { UserCalendarVersionBig } from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-big.section";
import { UserCalendarVersionSmall } from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-version-small.section";
import { DashboardUserProfile } from "@/v2/feature/dashboard/features/sections/user-profile-widget/dashboard-user-profile.section";
import { UserTimePlannerBig } from "@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-big.section";
import { UserTimePlannerSmall } from "@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner-small.section";
import { UserTodosVersionBig } from "@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-big.section";
import { UserTodosVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/user-todos-version-small.section";
import {
  WidgetTypes
} from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
const ResponsiveGridLayout = WidthProvider(Responsive);
export const DashboardPage = () => {
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(Fragment, {}), children: /* @__PURE__ */ jsx(DashboardLayoutConfigLoader, {}) }) });
};
const DashboardLayoutConfigLoader = () => {
  var _a;
  const {
    data: userConfig,
    isLoading: configLoading,
    mutate: refreshConfig,
    isValidating: configValidating
  } = useApiClient(DashboardEndpoints.getUserConfiguration());
  return /* @__PURE__ */ jsx(
    ContentWrapper,
    {
      loading: false,
      sx: {
        padding: 0,
        scrollbarWidth: "none",
        paddingBottom: "30px",
        backgroundImage: `url(${(_a = userConfig == null ? void 0 : userConfig.companyWallpaper) == null ? void 0 : _a.wallpaper})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      },
      hideFooter: true,
      border: false,
      noHeader: true,
      children: /* @__PURE__ */ jsx(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          },
          children: /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(DashboardLayoutLoader, { userConfig }), children: /* @__PURE__ */ jsx(
            DashboardLayout,
            {
              userConfig,
              refreshConfig,
              configLoading,
              configValidating
            }
          ) })
        }
      )
    }
  );
};
const DashboardLayout = ({
  userConfig,
  refreshConfig,
  configLoading,
  configValidating
}) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: userWidget, mutate: refreshWidgetData } = useApiClient(DashboardEndpoints.getUserWidgets());
  const refreshWidget = useCallback(() => __async(void 0, null, function* () {
    if (refreshWidgetData) refreshWidgetData();
  }), [refreshWidgetData]);
  const { trackPage } = useJune();
  const { data: widgetData } = userWidget != null ? userWidget : {};
  const widgetDataLookup = useMemo(() => {
    return keyBy(widgetData, "type");
  }, [widgetData]);
  const { preference, order } = userConfig != null ? userConfig : {};
  let preferenceIdLookup = useMemo(() => {
    return keyBy(preference, "id");
  }, [preference]);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    trackPage("Dashboard");
  }, []);
  const initialRows = useMemo(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
    return [
      ...widgetDataLookup[WidgetTypes.USER_PROFILE_SMALL] ? [
        {
          type: WidgetTypes.USER_PROFILE_SMALL,
          content: /* @__PURE__ */ jsx(
            DashboardUserProfile,
            {
              user,
              widgetData: (_a = widgetDataLookup[WidgetTypes.USER_PROFILE_SMALL]) == null ? void 0 : _a.profile,
              refreshWidgetData: refreshWidget
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_TODOS_BIG] ? [
        {
          type: WidgetTypes.USER_TODOS_BIG,
          content: /* @__PURE__ */ jsx(
            UserTodosVersionBig,
            {
              todos: (_b = widgetDataLookup[WidgetTypes.USER_TODOS_BIG]) == null ? void 0 : _b.todosBig,
              refreshtodoList: refreshWidget
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_TODOS_SMALL] ? [
        {
          type: WidgetTypes.USER_TODOS_SMALL,
          content: /* @__PURE__ */ jsx(
            UserTodosVersionSmall,
            {
              todos: (_c = widgetDataLookup[WidgetTypes.USER_TODOS_SMALL]) == null ? void 0 : _c.todosSmall
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_CALENDAR_BIG] ? [
        {
          type: WidgetTypes.USER_CALENDAR_BIG,
          content: /* @__PURE__ */ jsx(
            UserCalendarVersionBig,
            {
              calendarBig: (_d = widgetDataLookup[WidgetTypes.USER_CALENDAR_BIG]) == null ? void 0 : _d.calendarBig,
              refreshWidgetData: refreshWidget
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL] ? [
        {
          type: WidgetTypes.USER_CALENDAR_SMALL,
          content: /* @__PURE__ */ jsx(
            UserCalendarVersionSmall,
            {
              calendarSmall: (_e = widgetDataLookup[WidgetTypes.USER_CALENDAR_SMALL]) == null ? void 0 : _e.calendarSmall
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG] ? [
        {
          type: WidgetTypes.USER_ATTENDANCE_BIG,
          content: /* @__PURE__ */ jsx(
            UserAttendance,
            {
              attendanceData: (_f = widgetDataLookup[WidgetTypes.USER_ATTENDANCE_BIG]) == null ? void 0 : _f.attendance,
              refreshWidgetData: refreshWidget,
              shiftWidgetData: (_g = widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) != null ? _g : void 0
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL] ? [
        {
          type: WidgetTypes.USER_SHIFT_SMALL,
          content: /* @__PURE__ */ jsx(
            UserShiftHandler,
            {
              attendanceSchedule: (_h = widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _h.shift.attendanceSchedule,
              shift: (_j = (_i = widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _i.shift) == null ? void 0 : _j.todayShift,
              userSite: (_l = (_k = widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _k.shift) == null ? void 0 : _l.userSite,
              refresh: refreshWidget,
              currentWeekAttendanceEntries: (_n = (_m = widgetDataLookup[WidgetTypes.USER_SHIFT_SMALL]) == null ? void 0 : _m.shift) == null ? void 0 : _n.currentWeekAttendanceEntries,
              mode: "widget"
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL] ? [
        {
          type: WidgetTypes.USER_TIME_PLANNER_SMALL,
          content: /* @__PURE__ */ jsx(
            UserTimePlannerSmall,
            {
              userBalance: (_o = widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_SMALL]) == null ? void 0 : _o.balancesSmall
            }
          )
        }
      ] : [],
      ...widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG] ? [
        {
          type: WidgetTypes.USER_TIME_PLANNER_BIG,
          content: /* @__PURE__ */ jsx(
            UserTimePlannerBig,
            {
              userBalance: (_p = widgetDataLookup[WidgetTypes.USER_TIME_PLANNER_BIG]) == null ? void 0 : _p.balancesBig
            }
          )
        }
      ] : []
    ];
  }, [widgetDataLookup, user, refreshWidget]);
  const generateLayout = useCallback(() => {
    let x = 0;
    let y = 0;
    let countY = 0;
    const layouts2 = order == null ? void 0 : order.reduce((acc, widget) => {
      const widgetDetails = preferenceIdLookup[widget];
      const w = (widgetDetails == null ? void 0 : widgetDetails.size) === "small" ? 4 : 8;
      if (!widgetDataLookup[widgetDetails == null ? void 0 : widgetDetails.type]) return acc;
      if (x + w > 12) {
        x = 0;
        y += countY;
        countY = 0;
      }
      const layout = {
        x,
        y,
        w,
        h: 1,
        i: widget,
        static: widgetDetails == null ? void 0 : widgetDetails.isStatic,
        isBounded: true,
        isResizable: false
      };
      x += w;
      countY += w;
      return [...acc, layout];
    }, []);
    return layouts2;
  }, [preferenceIdLookup, widgetDataLookup, order]);
  const layouts = useMemo(() => {
    return { lg: generateLayout() };
  }, [generateLayout]);
  const generateDOM = () => {
    return _.map(layouts.lg, function(l, i) {
      var _a;
      const widget = preferenceIdLookup[l.i];
      const component = (_a = initialRows.find((row) => row.type === widget.type)) == null ? void 0 : _a.content;
      return /* @__PURE__ */ jsx("div", { id: `user-widget-${i}`, children: component }, l.i);
    });
  };
  return /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%"
        },
        children: [
          /* @__PURE__ */ jsx(
            ResponsiveGridLayout,
            {
              className: "layout grid-width",
              layouts: { lg: generateLayout() },
              breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
              cols: { lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 },
              measureBeforeMount: true,
              rowHeight: 280,
              isDraggable: false,
              isResizable: false,
              useCSSTransforms: false,
              preventCollision: true,
              margin: [30, 30],
              children: generateDOM()
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
            Button,
            {
              endIcon: /* @__PURE__ */ jsx(ArrowDown, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })),
              onClick: () => setEdit(true),
              sx: __spreadProps(__spreadValues({}, secondaryCTABtn), { borderRadius: radius.br15 }),
              children: polyglot.t("DashboardLayout.edit")
            }
          ) })
        ]
      }
    ),
    edit && /* @__PURE__ */ jsx(
      DashboardEditMode,
      {
        setEdit,
        refreshWidgetData: refreshWidget,
        userConfig,
        refreshConfig,
        configLoading,
        configValidating
      }
    )
  ] });
};
