"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrunButtons = ({
  showBack,
  primaryButtonLabel,
  loadingPrimary,
  disabledPrimary,
  disabledBack,
  onPrimaryClick,
  onBackClick,
  sx
}) => {
  return /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", sx: __spreadValues({ gap: spacing.g20 }, sx), children: [
    showBack && /* @__PURE__ */ jsxs(
      ButtonComponent,
      {
        sizeVariant: "large",
        colorVariant: "secondary",
        disabled: disabledBack,
        onClick: onBackClick,
        style: { display: "flex", alignItems: "center" },
        children: [
          /* @__PURE__ */ jsx(Back, { style: { marginRight: spacing.m4 } }),
          "Go back"
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "button",
        name: primaryButtonLabel,
        loading: loadingPrimary,
        disabled: disabledPrimary,
        onClick: onPrimaryClick,
        sizeVariant: "large",
        colorVariant: "primary"
      }
    )
  ] });
};
