"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import {
  ActionSelectionGroupComponent,
  ActionSelectionItem,
  ActionSelectionItemDeleteButton,
  ActionSelectionItemEditButton
} from "@/v2/components/action-selection-group.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { RequestTemplateComponent } from "@/v2/feature/requests/features/request-forms/components/request-form.component";
import { AttachmentComponentDrawer } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/components/attachment-component-drawer.component";
import { DateComponentDrawer } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/components/date-component-drawer.component";
import { SelectComponentDrawer } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/components/select-component-drawer.component";
import { TextComponentDrawer } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/components/text-component-drawer.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const FormBuilderPage = ({ initialForm, onNext }) => {
  const { polyglot } = usePolyglot();
  const [form, setForm] = useState(
    initialForm != null ? initialForm : {
      items: []
    }
  );
  const [componentDrawer, setDrawer] = useState({ drawer: /* @__PURE__ */ jsx(Fragment, {}), open: false });
  const updateFormComponent = useCallback((component) => {
    setForm((current) => {
      const newItems = current.items.slice();
      const idx = current.items.findIndex((item) => item.id === component.id);
      if (idx < 0) {
        newItems.push(component);
      } else {
        newItems.splice(idx, 1, component);
      }
      return __spreadProps(__spreadValues({}, current), { items: newItems });
    });
  }, []);
  const deleteComponentFromForm = useCallback((component) => {
    setForm((current) => __spreadProps(__spreadValues({}, current), {
      items: current.items.filter((item) => item !== component)
    }));
  }, []);
  const updateFormComponentAndCloseDrawer = useCallback(
    (component) => {
      updateFormComponent(component);
      setDrawer(componentDrawer && __spreadProps(__spreadValues({}, componentDrawer), { open: false }));
    },
    [componentDrawer, updateFormComponent]
  );
  const getDrawerForComponent = useCallback(
    (component) => {
      switch (component.type) {
        case "select":
          return /* @__PURE__ */ jsx(SelectComponentDrawer, { value: component, onSave: updateFormComponentAndCloseDrawer });
        case "text":
          return /* @__PURE__ */ jsx(TextComponentDrawer, { value: component, onSave: updateFormComponentAndCloseDrawer });
        case "attachment":
          return /* @__PURE__ */ jsx(AttachmentComponentDrawer, { value: component, onSave: updateFormComponentAndCloseDrawer });
        case "date":
          return /* @__PURE__ */ jsx(DateComponentDrawer, { value: component, onSave: updateFormComponentAndCloseDrawer });
      }
      return /* @__PURE__ */ jsx(Fragment, {});
    },
    [updateFormComponentAndCloseDrawer]
  );
  const editComponent = useCallback(
    (component) => {
      setDrawer({ drawer: getDrawerForComponent(component), open: true });
    },
    [getDrawerForComponent]
  );
  const FormComponents = useMemo(
    () => [
      /* @__PURE__ */ jsx(
        ActionSelectionItem,
        {
          step: {
            title: polyglot.t("FormBuilderPage.datePicker"),
            description: polyglot.t("FormBuilderPage.datePickerDesc")
          },
          onAddClick: () => setDrawer({ drawer: /* @__PURE__ */ jsx(DateComponentDrawer, { onSave: updateFormComponentAndCloseDrawer }), open: true }),
          sx: { py: spacing.py20 }
        }
      ),
      /* @__PURE__ */ jsx(
        ActionSelectionItem,
        {
          step: {
            title: polyglot.t("FormBuilderPage.attachment"),
            description: polyglot.t("FormBuilderPage.attachmentDesc")
          },
          onAddClick: () => setDrawer({ drawer: /* @__PURE__ */ jsx(AttachmentComponentDrawer, { onSave: updateFormComponentAndCloseDrawer }), open: true }),
          sx: { py: spacing.py20 }
        }
      ),
      /* @__PURE__ */ jsx(
        ActionSelectionItem,
        {
          step: {
            title: polyglot.t("FormBuilderPage.textField"),
            description: polyglot.t("FormBuilderPage.textFieldDesc")
          },
          onAddClick: () => setDrawer({ drawer: /* @__PURE__ */ jsx(TextComponentDrawer, { onSave: updateFormComponentAndCloseDrawer }), open: true }),
          sx: { py: spacing.py20 }
        }
      ),
      /* @__PURE__ */ jsx(
        ActionSelectionItem,
        {
          step: {
            title: polyglot.t("FormBuilderPage.selectField"),
            description: polyglot.t("FormBuilderPage.selectFieldDesc")
          },
          onAddClick: () => setDrawer({ drawer: /* @__PURE__ */ jsx(SelectComponentDrawer, { onSave: updateFormComponentAndCloseDrawer }), open: true }),
          sx: { py: spacing.py20 }
        }
      )
    ],
    [updateFormComponentAndCloseDrawer, polyglot]
  );
  const formComponentViews = useMemo(() => {
    return form.items.map((item) => /* @__PURE__ */ jsxs(Stack, { sx: { py: "5px", gap: spacing.g2 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g20 }, children: [
        /* @__PURE__ */ jsx(RequestTemplateComponent, { component: item, disabled: true }),
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g2, ml: "auto" }, children: [
          /* @__PURE__ */ jsx(ActionSelectionItemEditButton, { sx: { ml: "auto" }, onClick: () => editComponent(item) }),
          /* @__PURE__ */ jsx(ActionSelectionItemDeleteButton, { onClick: () => deleteComponentFromForm(item) })
        ] })
      ] }),
      "required" in item && /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "captionSmall",
          sx: { color: themeColors.Grey, visibility: item.required ? "visible" : "hidden" },
          children: polyglot.t("FormBuilderPage.required")
        }
      )
    ] }));
  }, [deleteComponentFromForm, editComponent, form.items, polyglot]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, alignItems: "center", width: "100%" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", pt: spacing.p30, px: spacing.p30, width: "inherit", overflow: "hidden" }, children: [
      /* @__PURE__ */ jsx(
        ActionSelectionGroupComponent,
        {
          title: polyglot.t("FormBuilderPage.component"),
          actions: FormComponents,
          emptyActionsMessage: polyglot.t("FormBuilderPage.componentEmptyActions")
        }
      ),
      /* @__PURE__ */ jsxs(
        Stack,
        {
          sx: {
            px: spacing.p30,
            borderRadius: radius.br15,
            maxWidth: "400px",
            flex: 1,
            mr: "auto",
            gap: spacing.g10
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("FormBuilderPage.form") }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("FormBuilderPage.createForm") }),
            /* @__PURE__ */ jsx(Stack, { sx: { gap: spacing.g5, maxHeight: "400px", overflowY: "auto" }, children: formComponentViews }),
            form.items.length > 0 && /* @__PURE__ */ jsxs(
              ButtonComponent,
              {
                fullWidth: true,
                type: "button",
                onClick: () => {
                  onNext == null ? void 0 : onNext(form);
                },
                sizeVariant: "large",
                colorVariant: "primary",
                style: { flexShrink: 0, marginTop: "20px" },
                children: [
                  polyglot.t("General.continue"),
                  " "
                ]
              }
            )
          ]
        }
      )
    ] }) }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: componentDrawer.open, setIsOpen: () => setDrawer(__spreadProps(__spreadValues({}, componentDrawer), { open: false })), children: componentDrawer.drawer })
  ] });
};
