"use strict";
import { jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from "@/lib/routes";
import { PayrollCompanyHMRCSetupPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-hmrc-setup.page";
import { RootStyle } from "@/v2/styles/root.styles";
export const PayrollCompanyCreate = () => {
  const newPayrollId = Number(useParams().payrollId);
  const routerHistory = useHistory();
  const finishPayrollCreation = useCallback(() => {
    routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId: newPayrollId }));
  }, [routerHistory, newPayrollId]);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(PayrollCompanyHMRCSetupPage, { payrollId: newPayrollId, onClose: () => finishPayrollCreation() }) });
};
