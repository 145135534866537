"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { GlobalContext } from "@/GlobalState";
import { canAccessScopes } from "@/lib/scopes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { TimelineRollingView } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/rolling/timeline-rolling-view.component";
import { TimelineScheduledView } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/scheduled/timeline-scheduled-view.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState, CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const getDayOrDays = (days) => days > 1 ? "days" : "day";
export const RCUpsertTimelinePage = ({ cycleId, reach }) => {
  var _a, _b, _c, _d;
  const { data: reviewCycle, mutate: refreshCycle, isLoading: cycleLoading } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, reach),
    {
      suspense: false
    }
  );
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["reviews:all"]);
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const isEditable = useMemo(
    () => Boolean(
      reviewCycle && reviewCycle.state !== CycleState.Completed && Boolean(isAdmin || isManager && reviewCycle.owner === user.userId)
    ),
    [reviewCycle, isAdmin, isManager, user]
  );
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  const showFlags = {
    showSelf: Boolean((_a = reviewCycle.reviewerSelect) == null ? void 0 : _a.includes(ReviewerTypes.Self)),
    showUpward: Boolean((_b = reviewCycle.reviewerSelect) == null ? void 0 : _b.includes(ReviewerTypes.Upward)),
    showPeer: Boolean((_c = reviewCycle.reviewerSelect) == null ? void 0 : _c.includes(ReviewerTypes.Peer)),
    showManager: Boolean((_d = reviewCycle.reviewerSelect) == null ? void 0 : _d.includes(ReviewerTypes.Manager))
  };
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType: reach }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: cycleLoading,
      children: reviewCycle.type === CycleType.Scheduled ? /* @__PURE__ */ jsx(
        TimelineScheduledView,
        {
          reviewCycle,
          showFlags,
          isEditable,
          refreshCycle
        }
      ) : /* @__PURE__ */ jsx(
        TimelineRollingView,
        {
          reviewCycle,
          showFlags,
          isEditable,
          refreshCycle
        }
      )
    }
  );
};
