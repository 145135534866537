"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { EditUserPayrollPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll.page";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const MissingTaxInformation = ({ userId, refreshPayroll }) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("PayrollMissing.taxInformation") }),
      /* @__PURE__ */ jsx(IconButton, { onClick: () => setIsModalOpen(true), colorVariant: "secondary", sizeVariant: "small", children: /* @__PURE__ */ jsx(Plus, { width: "14px", height: "14px" }) })
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, closeIcon: "back", children: /* @__PURE__ */ jsx(
      EditUserPayrollPage,
      {
        userId,
        record: null,
        onUpdateFinished: () => refreshPayroll == null ? void 0 : refreshPayroll(),
        close: () => setIsModalOpen(false),
        isUserUpdating: false,
        mode: "initial",
        requireReason: false
      }
    ) })
  ] });
};
