"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { MultipleSelectCheckbox } from "@v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import {
  UserInsuranceDependants,
  UserInsurancePolicyStatus
} from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { UserFamilyMemberType } from "@v2/feature/user/features/user-forms/user-family/user-family.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { nestErrorMessage } from "@/lib/errors";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
const STATUS_OPTIONS = [
  { label: "None", value: UserInsurancePolicyStatus.None },
  { label: "Active", value: UserInsurancePolicyStatus.Active },
  { label: "Pending", value: UserInsurancePolicyStatus.Pending },
  { label: "PendingOptOut", value: UserInsurancePolicyStatus.PendingOptOut },
  { label: "OptOut", value: UserInsurancePolicyStatus.OptOut }
];
const DEPENDANTS_OPTIONS = [
  { label: "Spouse/Partner", value: UserInsuranceDependants.SpousePartner },
  { label: "Children", value: UserInsuranceDependants.Children },
  { label: "Family", value: UserInsuranceDependants.Family }
];
export const ManageUserInsuranceDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  refresh,
  userName,
  onSave
}) => {
  const { data: userFamilyMembers } = useApiClient(
    UserEndpoints.getUserFamilyMembersAsSuperAdmin(userInsurance.userId),
    { suspense: false }
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    ManageUserInsuranceDrawerContent,
    {
      refresh,
      userInsurance,
      userName,
      userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [],
      onSave
    }
  ) });
};
const ManageUserInsuranceDrawerContent = ({
  userInsurance,
  refresh,
  userName,
  userFamilyMembers,
  onSave
}) => {
  var _a, _b, _c, _d;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      status: (_a = userInsurance.status) != null ? _a : UserInsurancePolicyStatus.None,
      startDate: userInsurance.startDate,
      endDate: (_b = userInsurance.endDate) != null ? _b : null,
      monthlyPremium: userInsurance.monthlyPremium,
      monthlyContribution: userInsurance.monthlyContribution,
      dependants: userInsurance.dependants,
      dependantsList: userInsurance.dependantsList,
      dependantsMonthlyPremium: userInsurance.dependantsMonthlyPremium,
      dependantsMonthlyContribution: userInsurance.dependantsMonthlyContribution,
      dependantsStatus: userInsurance.dependantsStatus
    },
    validationSchema: yup.object({
      status: yup.string().nullable().notRequired(),
      startDate: yup.string().test(dateFieldTest).nullable().notRequired(),
      endDate: yup.string().test(dateFieldTest).nullable().notRequired(),
      monthlyPremium: yup.number().nullable().typeError("Monthly premium should be a number").notRequired(),
      monthlyContribution: yup.number().nullable().typeError("Monthly contribution should be a number").notRequired(),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup.array().of(yup.number().integer()).nullable().notRequired(),
      dependantsMonthlyPremium: yup.number().typeError("Monthly premium should be a number").nullable().notRequired(),
      dependantsMonthlyContribution: yup.number().typeError("Monthly contribution should be a number").nullable().notRequired(),
      dependantsStatus: yup.string().nullable().notRequired()
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      yield updateUserPolicy(values);
    })
  });
  const updateUserPolicy = (values) => __async(void 0, null, function* () {
    var _a2, _b2;
    setLoading(true);
    try {
      const update = {
        status: values.status === UserInsurancePolicyStatus.None ? null : values.status,
        startDate: values.startDate ? values.startDate : null,
        endDate: values.endDate ? values.endDate : null,
        monthlyPremium: values.monthlyPremium ? Number(values.monthlyPremium) : null,
        monthlyContribution: values.monthlyContribution ? Number(values.monthlyContribution) : null,
        dependants: (_a2 = values.dependants) != null ? _a2 : null,
        dependantsList: values.dependants ? values.dependantsList : null,
        dependantsStatus: (_b2 = values.dependantsStatus) != null ? _b2 : null,
        dependantsMonthlyPremium: values.dependantsMonthlyPremium ? Number(values.dependantsMonthlyPremium) : null,
        dependantsMonthlyContribution: values.dependantsMonthlyContribution ? Number(values.dependantsMonthlyContribution) : null
      };
      yield InsuranceAPI.updateUserInsuranceAsSuperAdmin(userInsurance.policyId, userInsurance.userId, update);
      yield refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not create the policy. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];
    return formik.values.dependantsList.map((id) => {
      const familyMember = userFamilyMembers.find((m) => m.id === id);
      return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
    }).filter(Boolean);
  }, [formik.values.dependantsList, userFamilyMembers]);
  const dependantsListOptions = useMemo(() => {
    var _a2, _b2, _c2;
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (_a2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type)).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _a2 : [];
    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (_b2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => m.type === UserFamilyMemberType.Child).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _b2 : [];
    return (_c2 = userFamilyMembers == null ? void 0 : userFamilyMembers.map((m) => ({ value: m.id, label: m.name }))) != null ? _c2 : [];
  }, [userFamilyMembers, formik.values.dependants]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Edit user insurance" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: userName }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "status",
        label: "Status",
        options: STATUS_OPTIONS,
        value: formik.values.status,
        onChange: formik.handleChange,
        compareValue: formik.values.status,
        error: !!formik.errors.status && formik.touched.status,
        helperText: formik.touched.status && formik.errors.status
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: "5px", width: "100%", alignItems: "end" }, children: [
      /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          name: "startDate",
          label: "Start date",
          inputFormat: "DD/MM/YYYY",
          value: (_c = formik.values.startDate) != null ? _c : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("startDate", value);
            }
          },
          sx: { width: "100%" },
          error: !!formik.errors.startDate && Boolean(formik.touched.startDate),
          helperText: formik.errors.startDate && Boolean(formik.touched.startDate)
        }
      ),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        IconButton,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => {
            formik.setFieldValue("startDate", null);
          },
          children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: "5px", width: "100%", alignItems: "end" }, children: [
      /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          name: "endDate",
          label: "End date",
          inputFormat: "DD/MM/YYYY",
          value: (_d = formik.values.endDate) != null ? _d : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("endDate", value);
            }
          },
          sx: { width: "100%" },
          error: !!formik.errors.endDate && Boolean(formik.touched.endDate),
          helperText: formik.errors.endDate && Boolean(formik.touched.endDate)
        }
      ),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        IconButton,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => {
            formik.setFieldValue("endDate", null);
          },
          children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey, fill: themeColors.DarkGrey }))
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Monthly premium",
        name: "monthlyPremium",
        value: formik.values.monthlyPremium,
        onChange: formik.handleChange,
        error: formik.touched.monthlyPremium && Boolean(formik.errors.monthlyPremium),
        helperText: formik.touched.monthlyPremium && formik.errors.monthlyPremium,
        size: "small",
        endAdornment: "none",
        type: "number"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Monthly contribution",
        name: "monthlyContribution",
        value: formik.values.monthlyContribution,
        onChange: formik.handleChange,
        error: formik.touched.monthlyContribution && Boolean(formik.errors.monthlyContribution),
        helperText: formik.touched.monthlyContribution && formik.errors.monthlyContribution,
        size: "small",
        endAdornment: "none",
        type: "number"
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "dependants",
        label: "Dependants",
        options: DEPENDANTS_OPTIONS,
        value: formik.values.dependants,
        onChange: (e) => {
          formik.handleChange(e);
          formik.setFieldValue("dependantsList", []);
        },
        compareValue: formik.values.dependants,
        error: !!formik.errors.dependants && formik.touched.dependants,
        helperText: formik.touched.dependants && formik.errors.dependants
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Dependants monthly premium",
        name: "dependantsMonthlyPremium",
        value: formik.values.dependantsMonthlyPremium,
        onChange: formik.handleChange,
        error: formik.touched.dependantsMonthlyPremium && Boolean(formik.errors.dependantsMonthlyPremium),
        helperText: formik.touched.dependantsMonthlyPremium && formik.errors.dependantsMonthlyPremium,
        size: "small",
        endAdornment: "none",
        type: "number"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Dependants monthly contribution",
        name: "dependantsMonthlyContribution",
        value: formik.values.dependantsMonthlyContribution,
        onChange: formik.handleChange,
        error: formik.touched.dependantsMonthlyContribution && Boolean(formik.errors.dependantsMonthlyContribution),
        helperText: formik.touched.dependantsMonthlyContribution && formik.errors.dependantsMonthlyContribution,
        size: "small",
        endAdornment: "none",
        type: "number"
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "dependantsStatus",
        label: "Dependants status",
        options: STATUS_OPTIONS,
        value: formik.values.dependantsStatus,
        onChange: formik.handleChange,
        compareValue: formik.values.dependantsStatus,
        error: !!formik.errors.dependantsStatus && formik.touched.dependantsStatus,
        helperText: formik.touched.dependantsStatus && formik.errors.dependantsStatus
      }
    ),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        MultipleSelectCheckbox,
        {
          id: "dependantsList",
          label: "Additional members list",
          limitTags: -1,
          options: dependantsListOptions,
          value: selectedDependantsList,
          onChange: (_, values) => {
            const updatedOptionList = values.map(({ value }) => value);
            formik.setFieldValue("dependantsList", updatedOptionList);
          },
          isOptionEqualToValue: (x, y) => x.value === y.value,
          getOptionLabel: ({ label }) => label
        }
      ),
      !!formik.errors.dependantsList && formik.touched.dependantsList && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.RedDark }, children: formik.errors.dependantsList })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading, sizeVariant: "medium", colorVariant: "primary", fullWidth: true }) })
  ] }) });
};
