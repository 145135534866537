"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { UserInsurancePolicyStatus } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { getUserNamesKeyedByUserIds } from "@v2/feature/device/device.util";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { ManageUserInsuranceDrawer } from "@v2/feature/super-admin/features/super-admin-insurance/components/manage-user-insurance-drawer.component";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tablePrimaryIconButtonSx } from "@v2/styles/icon-button.styles";
import { BackofficeRootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { keyBy } from "lodash";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as CheckGreen } from "@/images/side-bar-icons/CheckGreen.svg";
import { ReactComponent as CleanCircle } from "@/images/side-bar-icons/CleanCircle.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { nestErrorMessage } from "@/lib/errors";
export const SuperAdminInsurancePolicyDetailsPage = ({
  companies,
  users,
  policies,
  loading,
  pageConfig
}) => {
  var _a, _b, _c;
  const [userInsurances, setUserInsurances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserInsurance, setSelectedUserInsurance] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const params = useParams();
  const policyId = Number(params.policyId);
  const policy = policies.find((policy2) => policy2.id === policyId);
  const company = policy ? companies.find((company2) => company2.companyId === policy.companyId) : void 0;
  const companyUsers = useMemo(() => {
    return company ? users.filter((user) => user.company.companyId === company.companyId) : [];
  }, [company, users]);
  const userNames = useMemo(() => getUserNamesKeyedByUserIds(companyUsers), [companyUsers]);
  const [showMessage] = useMessage();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsLoading(true);
      const userInsurances2 = yield InsuranceAPI.getAllUserInsuranceRecordsAsSuperAdmin(policyId);
      setUserInsurances(userInsurances2);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get user insurance records. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoading(false);
    }
  }), [showMessage, policyId]);
  const tableRows = useMemo(() => {
    const userInsurancesByUserId = keyBy(userInsurances, "userId");
    const nonIncludedUsers = companyUsers.filter((user) => !userInsurancesByUserId[user.userId]).map((user) => ({
      userId: user.userId,
      companyId: user.company.companyId,
      policyId: null,
      status: null,
      startDate: null,
      endDate: null,
      monthlyPremium: null,
      monthlyContribution: null,
      dependants: null,
      dependantsList: null,
      dependantsMonthlyPremium: null,
      dependantsMonthlyContribution: null,
      dependantsStatus: null,
      noOfDependants: null
    }));
    return [...userInsurances, ...nonIncludedUsers];
  }, [companyUsers, userInsurances]);
  const filteredTableRows = useMemo(() => {
    return tableRows.filter(
      (row) => userNames[row.userId] && userNames[row.userId].toLowerCase().includes(searchInput.toLowerCase()) || String(row.userId).includes(searchInput)
    );
  }, [userNames, tableRows, searchInput]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const createUserInsuranceRecord = useCallback(
    (userId) => __async(void 0, null, function* () {
      if (!policy) {
        showMessage("Policy could not be found!", "error");
        return;
      }
      try {
        yield InsuranceAPI.createEmptyUserInsuranceAsSuperAdmin(policy.companyId, userId, policy.id);
        yield refresh();
      } catch (error) {
        showMessage(`Could not create user record. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [policy, refresh, showMessage]
  );
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Employee",
        accessorFn: (row) => row,
        id: "userId",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userNames[userInsurance.userId] ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: userNames[userInsurance.userId] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.status ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: userInsurance.status }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => "Start date",
        accessorFn: (row) => row,
        id: "startDate",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.startDate ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: new Date(userInsurance.startDate).toLocaleDateString() }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => "End date",
        accessorFn: (row) => row,
        id: "endDate",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.endDate ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: new Date(userInsurance.endDate).toLocaleDateString() }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => "Monthly premium",
        accessorFn: (row) => row,
        id: "monthlyPremium",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.monthlyPremium ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: formatMoney({ amount: userInsurance.monthlyPremium }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 130,
        minSize: 130
      },
      {
        header: () => "Monthly contribution",
        accessorFn: (row) => row,
        id: "monthlyContribution",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.monthlyContribution ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: formatMoney({ amount: userInsurance.monthlyContribution }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 130,
        minSize: 130
      },
      {
        header: () => "Dependants",
        accessorFn: (row) => row,
        id: "dependants",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.dependants ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: userInsurance.dependants }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 150,
        minSize: 150
      },
      {
        header: () => "Dependants status",
        accessorFn: (row) => row,
        id: "dependantsStatus",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.dependants ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: userInsurance.dependantsStatus }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 150,
        minSize: 150
      },
      {
        header: () => "Dependants monthly premium",
        accessorFn: (row) => row,
        id: "dependantsMonthlyPremium",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.dependantsMonthlyPremium ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: formatMoney({ amount: userInsurance.dependantsMonthlyPremium }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 180
      },
      {
        header: () => "Dependants monthly contribution",
        accessorFn: (row) => row,
        id: "dependantsMonthlyContribution",
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.dependantsMonthlyContribution ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: formatMoney({ amount: userInsurance.dependantsMonthlyContribution }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 180
      },
      {
        header: () => "Total premium",
        accessorFn: (row) => row,
        id: "totalPremium",
        cell: (info) => {
          var _a2;
          const userInsurance = info.getValue();
          return userInsurance.monthlyPremium ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: formatMoney({ amount: userInsurance.monthlyPremium + ((_a2 = userInsurance.dependantsMonthlyPremium) != null ? _a2 : 0) }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 180
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        cell: (info) => {
          const userInsurance = info.getValue();
          const isOwnStatusPending = userInsurance.status && [UserInsurancePolicyStatus.Pending, UserInsurancePolicyStatus.PendingOptOut].includes(userInsurance.status);
          const isDependantsStatusPending = userInsurance.dependantsStatus && [UserInsurancePolicyStatus.Pending, UserInsurancePolicyStatus.PendingOptOut].includes(
            userInsurance.dependantsStatus
          );
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, onClick: (e) => e.stopPropagation(), children: userInsurance.policyId && (isOwnStatusPending || isDependantsStatusPending) ? /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: [
                {
                  icon: /* @__PURE__ */ jsx(CheckGreen, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    try {
                      yield InsuranceAPI.addUserToInsurancePolicyAsSuperAdmin(
                        userInsurance.policyId,
                        userInsurance.userId
                      );
                      yield refresh();
                    } catch (error) {
                      console.error(error);
                      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                    } finally {
                      setIsLoading(false);
                    }
                  }),
                  label: "Add to policy",
                  hidden: userInsurance.status !== UserInsurancePolicyStatus.Pending
                },
                {
                  icon: /* @__PURE__ */ jsx(CheckGreen, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    try {
                      yield InsuranceAPI.confirmUserDependantsAsSuperAdmin(
                        userInsurance.policyId,
                        userInsurance.userId
                      );
                      yield refresh();
                    } catch (error) {
                      console.error(error);
                      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                    } finally {
                      setIsLoading(false);
                    }
                  }),
                  label: "Confirm dependants",
                  hidden: userInsurance.dependantsStatus !== UserInsurancePolicyStatus.Pending
                },
                {
                  icon: /* @__PURE__ */ jsx(CleanCircle, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    try {
                      yield InsuranceAPI.removeUserFromInsurancePolicyAsSuperAdmin(
                        userInsurance.policyId,
                        userInsurance.userId
                      );
                      yield refresh();
                    } catch (error) {
                      console.error(error);
                      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                    } finally {
                      setIsLoading(false);
                    }
                  }),
                  label: "Remove from policy",
                  hidden: userInsurance.status !== UserInsurancePolicyStatus.PendingOptOut
                }
              ],
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
              }
            }
          ) : userInsurance.policyId ? null : /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tablePrimaryIconButtonSx,
              onClick: () => createUserInsuranceRecord(userInsurance.userId),
              id: `btnCreate_${userInsurance.userId}`,
              children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
            }
          ) });
        },
        maxSize: 100,
        minSize: 80
      }
    ];
  }, [createUserInsuranceRecord, refresh, showMessage, userNames]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Health Insurance" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading || isLoading, secondLevel: true, children: [
      company && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: company.name }),
      policy && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10, alignItems: "center", mt: spacing.m10 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Provider:" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: policy.providerName }),
        /* @__PURE__ */ jsx(Box, { sx: { ml: spacing.m10 }, children: /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredTableRows,
          columnData,
          rowClick: (row) => {
            if (row.original.policyId) {
              setSelectedUserInsurance(row.original);
              setIsDrawerOpen(true);
            }
          },
          hidePagination: true
        }
      ) }),
      isDrawerOpen && policy && (selectedUserInsurance == null ? void 0 : selectedUserInsurance.policyId) && /* @__PURE__ */ jsx(
        ManageUserInsuranceDrawer,
        {
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          userInsurance: selectedUserInsurance,
          refresh,
          userName: (_c = userNames[selectedUserInsurance.userId]) != null ? _c : "N/A",
          onSave: () => {
            setSelectedUserInsurance(null);
            setIsDrawerOpen(false);
          }
        }
      )
    ] })
  ] });
};
