"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableFilter } from "@v2/components/table/table-filter.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import {
  DeviceOSValueLabelOptions,
  DeviceShopFilter,
  DeviceTypesValueLabelOptions,
  IsAvailableValueLabelOptions,
  ManufacturerValueLabelOptions,
  RamValueLabelOptions,
  StorageValueLabelOptions
} from "@v2/feature/device/device.util";
import { SuperAdminDeviceModelDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-model-drawer.component";
import { filterStringToObject } from "@v2/feature/user/user.util";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminDeviceModelsPage = ({ pageConfig }) => {
  var _a, _b;
  const [deviceModels, setDeviceModels] = useState([]);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(true);
  const [deviceModelToBeEdited, setDeviceModelToBeEdited] = useState(null);
  const [isDeviceModelDrawerOpen, setIsDeviceModelDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const [filterTypes, setFilterTypes] = useState({});
  const refreshDevicesStockAndModels = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [deviceModels2] = yield Promise.all([DeviceAPI.getDeviceModels(true)]);
      setDeviceModels(deviceModels2);
    } catch (error) {
      showMessage(`Could not retrieve devices list. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refreshDevicesStockAndModels();
  }, [refreshDevicesStockAndModels]);
  const getFilterOptions = useCallback(() => __async(void 0, null, function* () {
    const FILTERS = [
      DeviceShopFilter.Manufacturer,
      DeviceShopFilter.RAM,
      DeviceShopFilter.Storage,
      DeviceShopFilter.OS,
      DeviceShopFilter.IsAvailable
    ];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case DeviceShopFilter.Manufacturer:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: ManufacturerValueLabelOptions });
          break;
        case DeviceShopFilter.RAM:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: RamValueLabelOptions });
          break;
        case DeviceShopFilter.Storage:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: StorageValueLabelOptions });
          break;
        case DeviceShopFilter.OS:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: DeviceOSValueLabelOptions });
          break;
        case DeviceShopFilter.IsAvailable:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: IsAvailableValueLabelOptions });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), []);
  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);
  const tableData = useMemo(() => {
    let filteredDeviceModels = [...deviceModels];
    if (searchInput) {
      filteredDeviceModels = filteredDeviceModels.filter((deviceModel) => {
        const modelName = deviceModel.modelName;
        const id = deviceModel.id;
        return (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase())) || String(id).includes(searchInput.toLowerCase());
      });
    }
    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case DeviceShopFilter.Manufacturer: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a2;
                  return model.manufacturer && ((_a2 = filterOptions[key]) == null ? void 0 : _a2.includes(model.manufacturer.toLowerCase()));
                }
              );
              break;
            }
            case DeviceShopFilter.RAM: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a2;
                  return model.ram && ((_a2 = filterOptions[key]) == null ? void 0 : _a2.includes(String(model.ram)));
                }
              );
              break;
            }
            case DeviceShopFilter.Storage: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a2;
                  return model.storage && ((_a2 = filterOptions[key]) == null ? void 0 : _a2.includes(String(model.storage)));
                }
              );
              break;
            }
            case DeviceShopFilter.OS: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a2;
                  return model.os && ((_a2 = filterOptions[key]) == null ? void 0 : _a2.includes(model.os.toLowerCase()));
                }
              );
              break;
            }
            case DeviceShopFilter.IsAvailable: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.isAvailable && filterOptions[key].includes("Yes") || !model.isAvailable && filterOptions[key].includes("No")
              );
              break;
            }
            default:
              break;
          }
        }
      }
    }
    return filteredDeviceModels;
  }, [deviceModels, searchInput, filterString]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "ID",
        accessorFn: (row) => row,
        id: "id",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: deviceModel.id });
        },
        size: 20
      },
      {
        header: () => "Type",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: (info) => {
          var _a2, _b2;
          const deviceModel = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: (_b2 = (_a2 = DeviceTypesValueLabelOptions.find((type) => type.value === deviceModel.type)) == null ? void 0 : _a2.label) != null ? _b2 : /* @__PURE__ */ jsx(EmptyCell, {}) });
        },
        size: 100
      },
      {
        header: () => "Model Name",
        accessorFn: (row) => row,
        id: "modelName",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: deviceModel.modelName });
        },
        size: 135
      },
      {
        header: () => "Model Number",
        accessorFn: (row) => row,
        id: "modelNumber",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.modelNumber ? /* @__PURE__ */ jsx(Box, { children: deviceModel.modelNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      },
      {
        header: () => "Model Version",
        accessorFn: (row) => row,
        id: "modelVersion",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.modelVersion ? /* @__PURE__ */ jsx(Box, { children: deviceModel.modelVersion }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 110
      },
      {
        header: () => "Manufacturer",
        accessorFn: (row) => row,
        id: "manufacturer",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.manufacturer ? /* @__PURE__ */ jsx(Box, { children: deviceModel.manufacturer }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 110
      },
      {
        header: () => "Screen Size",
        accessorFn: (row) => row,
        id: "screenSize",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.screenSize ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.screenSize,
            '"'
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "RAM",
        accessorFn: (row) => row,
        id: "ram",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.ram ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.ram,
            "GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 30
      },
      {
        header: () => "Storage",
        accessorFn: (row) => row,
        id: "storage",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.storage ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.storage,
            "GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 50
      },
      {
        header: () => "CPU",
        accessorFn: (row) => row,
        id: "cpuCores",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.cpuCores ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.cpuCores,
            "-cores"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 65
      },
      {
        header: () => "GPU",
        accessorFn: (row) => row,
        id: "gpuCores",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.gpuCores ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.gpuCores,
            "-cores"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 65
      },
      {
        header: () => "Delivery Days (UK)",
        accessorFn: (row) => row,
        id: "deliveryDays",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.deliveryDays,
            " days"
          ] });
        },
        size: 100
      },
      {
        header: () => "Delivery Days (EU)",
        accessorFn: (row) => row,
        id: "deliveryDaysEu",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.deliveryDaysEu ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.deliveryDaysEu,
            " days"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      },
      {
        header: () => "Is Available",
        accessorFn: (row) => row,
        id: "isAvailable",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.isAvailable ? /* @__PURE__ */ jsx(Box, { children: "Yes" }) : /* @__PURE__ */ jsx(Box, { children: "No" });
        },
        size: 60
      },
      {
        header: () => "Price (36)",
        accessorFn: (row) => row,
        id: "price36",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          const price = deviceModel.price36;
          return /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            price
          ] });
        },
        size: 75
      },
      {
        header: () => "Price (24)",
        accessorFn: (row) => row,
        id: "price24",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          const price = deviceModel.price24;
          return /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            price
          ] });
        },
        size: 75
      },
      {
        header: () => "Price EU (36)",
        accessorFn: (row) => row,
        id: "priceEu36",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          const priceEu = deviceModel.priceEu36;
          return priceEu ? /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            priceEu
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Price EU (24)",
        accessorFn: (row) => row,
        id: "priceEu24",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          const priceEu = deviceModel.priceEu24;
          return priceEu ? /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            priceEu
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Full Price",
        accessorFn: (row) => row,
        id: "fullPrice",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          const fullPrice = deviceModel.fullPrice;
          return fullPrice ? /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            fullPrice
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Full Price (EU)",
        accessorFn: (row) => row,
        id: "fullPriceEu",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          const fullPriceEu = deviceModel.fullPriceEu;
          return fullPriceEu ? /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            fullPriceEu
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Count",
        accessorFn: (row) => row,
        id: "count",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: deviceModel.count });
        },
        size: 60
      }
    ];
  }, []);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: "Devices",
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsDeviceModelDrawerOpen(true), children: "Add Model" }),
        showAction: true
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, secondLevel: true, children: [
      deviceModels.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex", justifyContent: "flex-start", width: "100%" }, spacing.mb20), { gap: 2 }), children: [
        filterTypes && /* @__PURE__ */ jsx(TableFilter, { filterTypes, setFilterString, filterString }),
        /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
          var _a2, _b2;
          return setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
        } })
      ] }),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: tableData,
          columnData,
          rowClick: (row) => {
            setDeviceModelToBeEdited(row.original);
            setIsDeviceModelDrawerOpen(true);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        SuperAdminDeviceModelDrawer,
        {
          isOpen: isDeviceModelDrawerOpen,
          setIsOpen: setIsDeviceModelDrawerOpen,
          deviceModel: deviceModelToBeEdited,
          closeDrawer: () => setIsDeviceModelDrawerOpen(false),
          refresh: refreshDevicesStockAndModels
        }
      )
    ] })
  ] });
};
