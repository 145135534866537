"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { PolicyApprovalEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-approval-edit-drawer.component";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AbsencePolicyApprovalSection = ({ absencePolicy, refresh }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), {
    suspense: false
  });
  const refreshApprovalRules = useCallback(() => __async(void 0, null, function* () {
    if (refreshRules) yield refreshRules();
  }), [refreshRules]);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyApprovalSection.approval"),
      onEdit: () => setIsEditDrawerOpen(true),
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyApprovalSection.approval"),
                    value: (_b = (_a = absencePolicy == null ? void 0 : absencePolicy.backoffApprovalRule) == null ? void 0 : _a.name) != null ? _b : "N/A",
                    hidden: !absencePolicy.backoffApprovalRule
                  },
                  {
                    type: SectionItemType.TextLine,
                    value: polyglot.t("AbsencePolicyApprovalSection.approvalDesc"),
                    hidden: ((_c = absencePolicy == null ? void 0 : absencePolicy.backoffApprovalRule) == null ? void 0 : _c.name) === "Auto approve" || absencePolicy.updateApprovalNotRequired
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyApprovalEditDrawer,
          {
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            absencePolicy,
            refresh,
            approvalRules: approvalRules != null ? approvalRules : [],
            refreshApprovalRules
          }
        )
      ]
    }
  );
};
