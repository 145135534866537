"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { PeopleDirectoryPage } from "@v2/feature/user/pages/people-directory.page";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  DASHBOARD_ROUTE,
  PEOPLE_ROUTE,
  PEOPLE_COMPANY_DIRECTORY_ROUTE,
  PEOPLE_TEAM_DIRECTORY_ROUTE,
  PEOPLE_ME_DIRECTORY_ROUTE
} from "@/lib/routes";
export const UserDirectoryRouter = () => {
  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const user = state.user;
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        exact: true,
        scopes: ["user:read:all"],
        context: getScopesContext(user),
        path: [PEOPLE_COMPANY_DIRECTORY_ROUTE, PEOPLE_TEAM_DIRECTORY_ROUTE, PEOPLE_ME_DIRECTORY_ROUTE],
        children: /* @__PURE__ */ jsx(PeopleDirectoryPage, {})
      }
    ),
    hasScopes(["user:read:all"]) ? /* @__PURE__ */ jsx(Redirect, { to: PEOPLE_ROUTE }) : /* @__PURE__ */ jsx(Redirect, { to: DASHBOARD_ROUTE })
  ] });
};
