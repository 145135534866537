"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, Redirect, Route, Switch } from "react-router-dom";
import {
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
  SETTINGS_MONEY_INVOICES_ROUTE,
  SETTINGS_MONEY_INVOICES_TYPES_DETAILS_ACCOUNTING_ROUTE,
  SETTINGS_MONEY_INVOICES_TYPES_DETAILS_APPROVAL_ROUTE,
  SETTINGS_MONEY_INVOICES_TYPES_DETAILS_MEMBERS_ROUTE
} from "@/lib/routes";
import { PaymentSettingsApprovalsPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings-approvals.page";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { PaymentTypeAccountingSettingsPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-accounting-settings.page";
import { PaymentTypeGeneralSettingsPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-general-settings.page";
import { PaymentTypeMembersSettingsPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/pages/payment-type-members-settings.page";
export const getPageConfig = (id, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("General.general"),
          stub: "general",
          path: generatePath(SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE, { id })
        },
        {
          title: polyglot.t("PaymentSettingsPage.accountingHeader"),
          stub: "accounting",
          path: generatePath(SETTINGS_MONEY_INVOICES_TYPES_DETAILS_ACCOUNTING_ROUTE, {
            id
          })
        },
        {
          title: polyglot.t("General.members"),
          stub: "provider",
          path: generatePath(SETTINGS_MONEY_INVOICES_TYPES_DETAILS_MEMBERS_ROUTE, { id })
        },
        {
          title: polyglot.t("PaymentSettingsPage.approvalsTitle"),
          stub: "approval",
          path: generatePath(SETTINGS_MONEY_INVOICES_TYPES_DETAILS_APPROVAL_ROUTE, { id })
        }
      ]
    }
  ];
};
export const InvoiceSettingDetailRouter = ({ id }) => {
  var _a;
  const { data: invoiceSetting, mutate: refresh } = useApiClient(PaymentTypeSettingsEndpoints.getTypeSettingById(id));
  const { mutate: refreshAllSettings } = useApiClient(
    PaymentTypeSettingsEndpoints.getPaymentTypeSettingsForCompanyId()
  );
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_a = invoiceSetting == null ? void 0 : invoiceSetting.name) != null ? _a : polyglot.t("PaymentSettingsPage.invoiceType"),
        subtitle: polyglot.t("PaymentSettingsPage.invoiceType"),
        pageConfig: getPageConfig(id, polyglot),
        backPath: SETTINGS_MONEY_INVOICES_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { children: invoiceSetting && /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE, children: refreshAllSettings && /* @__PURE__ */ jsx(
        PaymentTypeGeneralSettingsPage,
        {
          paymentTypeSetting: invoiceSetting,
          refresh,
          refreshAllPaymentSettings: refreshAllSettings
        }
      ) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_MONEY_INVOICES_TYPES_DETAILS_MEMBERS_ROUTE, children: /* @__PURE__ */ jsx(PaymentTypeMembersSettingsPage, {}) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_MONEY_INVOICES_TYPES_DETAILS_APPROVAL_ROUTE, children: /* @__PURE__ */ jsx(PaymentSettingsApprovalsPage, { paymentTypeSetting: invoiceSetting, refreshPaymentTypeSetting: refresh }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_MONEY_INVOICES_TYPES_DETAILS_ACCOUNTING_ROUTE, children: /* @__PURE__ */ jsx(PaymentTypeAccountingSettingsPage, { paymentTypeSetting: invoiceSetting, refresh }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_MONEY_INVOICES_ROUTE })
    ] }) })
  ] });
};
