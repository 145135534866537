"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { StepperHeader } from "@v2/components/stepper-header.component";
import { StepperComponent } from "@v2/components/stepper.component";
import { ReportDomain } from "@v2/feature/reports/features/create-report/sections/report-domain.component";
import { ReportEntities } from "@v2/feature/reports/features/create-report/sections/report-entities.component";
import { ReportResults } from "@v2/feature/reports/features/create-report/sections/report-results.component";
import { ReportType } from "@v2/feature/reports/features/create-report/sections/report-type.component";
import {
  DataDomain,
  DateType,
  ReportTypePeopleSelection
} from "@v2/feature/reports/reports.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { subDays } from "date-fns";
import { useHistory } from "react-router-dom";
import { REPORT_COMPANY_REPORTS_ROUTE } from "@/lib/routes";
export const CreateFlowLayout = ({ companyConfig }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [step, setStep] = useState(polyglot.t("CreateFlowLayout.data"));
  const [formData, setFormData] = useState({
    domain: DataDomain.People,
    type: ReportTypePeopleSelection.CurrentRecords,
    entities: [],
    start: new LocalDate(subDays(/* @__PURE__ */ new Date(), 30)).toDateString(),
    end: new LocalDate().toDateString(),
    dateType: DateType.Last30days,
    filters: "",
    columns: {}
  });
  const reportStepsComponent = useMemo(
    () => [
      {
        name: polyglot.t("CreateFlowLayout.data"),
        component: /* @__PURE__ */ jsx(
          ReportDomain,
          {
            onNext: () => setStep(
              [DataDomain.Devices, DataDomain.Attendance].includes(formData.domain) ? polyglot.t("CreateFlowLayout.results") : polyglot.t("CreateFlowLayout.type")
            ),
            setFormData,
            formData,
            companyConfig
          }
        )
      },
      ...![DataDomain.Devices, DataDomain.Attendance].includes(formData.domain) ? [
        {
          name: polyglot.t("CreateFlowLayout.type"),
          component: /* @__PURE__ */ jsx(
            ReportType,
            {
              onNext: () => setStep(
                formData.domain === DataDomain.People && formData.type === ReportTypePeopleSelection.ChangeReport ? polyglot.t("CreateFlowLayout.entities") : polyglot.t("CreateFlowLayout.results")
              ),
              setFormData,
              formData
            }
          )
        }
      ] : [],
      ...formData.domain === DataDomain.People ? [
        {
          name: polyglot.t("CreateFlowLayout.entities"),
          component: /* @__PURE__ */ jsx(
            ReportEntities,
            {
              onNext: () => setStep(polyglot.t("CreateFlowLayout.results")),
              formData,
              setFormData
            }
          )
        }
      ] : [],
      ...formData.domain !== DataDomain.Payroll ? [
        {
          name: polyglot.t("CreateFlowLayout.results"),
          component: /* @__PURE__ */ jsx(ReportResults, { formData, setFormData })
        }
      ] : []
    ],
    [polyglot, companyConfig, formData]
  );
  const reportSteps = useMemo(
    () => [
      polyglot.t("CreateFlowLayout.data"),
      ...![DataDomain.Devices, DataDomain.Attendance].includes(formData.domain) ? [polyglot.t("CreateFlowLayout.type")] : [],
      ...formData.domain === DataDomain.People ? [polyglot.t("CreateFlowLayout.entities")] : [],
      ...formData.domain !== DataDomain.Payroll ? [polyglot.t("CreateFlowLayout.results")] : []
    ],
    [formData, polyglot]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
    /* @__PURE__ */ jsx(
      StepperHeader,
      {
        content: /* @__PURE__ */ jsx(StepperComponent, { steps: reportSteps, activeStep: step }),
        contentSx: { width: "80%", textAlign: "center" },
        showStepBack: true,
        stepBackAction: () => {
          if (reportSteps.indexOf(step) > 0) {
            if (step === "Results" && formData.domain === DataDomain.People && formData.type === ReportTypePeopleSelection.CurrentRecords)
              return setStep(reportSteps[reportSteps.indexOf(step) - 2]);
            return setStep(reportSteps[reportSteps.indexOf(step) - 1]);
          }
          routerHistory.goBack();
        },
        stepBackSx: {
          width: "10%",
          textAlign: "left"
        },
        showBack: true,
        backAction: () => {
          routerHistory.push(REPORT_COMPANY_REPORTS_ROUTE);
        },
        backSx: { width: "10%", textAlign: "right" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 } }),
    (_a = reportStepsComponent[reportSteps.indexOf(step)]) == null ? void 0 : _a.component
  ] });
};
