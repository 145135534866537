"use strict";
export const formatAccountingJournal = (journal) => {
  var _a;
  const formattedJournal = [];
  if (!(journal == null ? void 0 : journal.JournalLines)) return formattedJournal;
  for (const line of journal.JournalLines) {
    formattedJournal.push({
      Narration: journal.Narration,
      Date: journal.Date,
      Description: line.Description,
      AccountCode: (_a = line.AccountCode) != null ? _a : "",
      TaxRate: "NoVAT",
      Amount: line.LineAmount
    });
  }
  return formattedJournal;
};
