"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ReportConfigSection } from "@v2/feature/reports/reports-advanced/components/report-config.section";
import { ReportViewResult } from "@v2/feature/reports/reports-advanced/components/report-view-result.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { deepCopy } from "@v2/infrastructure/object/object.util";
import { RootStyle } from "@v2/styles/root.styles";
import { LocalDate } from "@v2/util/local-date";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const ReportsAdvancedViewPage = ({ reports, refreshReportsList, isLoading, target }) => {
  var _a, _b, _c, _d;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const params = useParams();
  const reportId = Number(params.reportId);
  const { data: filtersAndColumns, isLoading: isLoadingConfig } = useApiClient(
    ReportsEndpoints.getTestReportsFiltersOptions(),
    {
      suspense: false
    }
  );
  const report = useMemo(() => reports.find((r) => r.id === reportId), [reports, reportId]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [reportResponse, setReportResponse] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState((_a = report == null ? void 0 : report.columns) != null ? _a : {});
  const [selectedFilters, setSelectedFilters] = useState((_b = report == null ? void 0 : report.filters) != null ? _b : {});
  const [effectiveDate, setEffectiveDate] = useState(new LocalDate().toDateString());
  useEffect(() => {
    var _a2, _b2;
    if (!report) return;
    setSelectedColumns(deepCopy((_a2 = report.columns) != null ? _a2 : {}));
    setSelectedFilters(deepCopy((_b2 = report.filters) != null ? _b2 : {}));
  }, [report]);
  const generateReport = useCallback(() => __async(void 0, null, function* () {
    if (!report) {
      setReportResponse(null);
      return;
    }
    try {
      setIsGenerating(true);
      const response = yield ReportsAPI.generateReport(selectedColumns, selectedFilters, effectiveDate, target);
      setReportResponse(response);
    } catch (error) {
      setReportResponse(null);
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsGenerating(false);
  }), [report, selectedColumns, selectedFilters, effectiveDate, showMessage, polyglot, target]);
  useEffect(() => {
    generateReport();
  }, [generateReport]);
  return report ? /* @__PURE__ */ jsxs(RootStyle, { style: { display: "flex", flexDirection: "row" }, children: [
    /* @__PURE__ */ jsx(
      ReportConfigSection,
      {
        selectedColumns,
        setSelectedColumns,
        selectedFilters,
        setSelectedFilters,
        reportColumns: (_c = filtersAndColumns == null ? void 0 : filtersAndColumns.columns) != null ? _c : [],
        reportFilters: (_d = filtersAndColumns == null ? void 0 : filtersAndColumns.filters) != null ? _d : [],
        target,
        effectiveDate,
        setEffectiveDate
      }
    ),
    /* @__PURE__ */ jsx(
      ReportViewResult,
      {
        report,
        reportResponse,
        isLoading: isLoading || isGenerating || !!isLoadingConfig,
        selectedColumns,
        selectedFilters,
        refreshReportsList,
        refreshReport: generateReport,
        target
      }
    )
  ] }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Report not found" }) });
};
