"use strict";
import { SEAT_LIMIT_ERROR, UPGRADE_REQUIRED_ERROR } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
export const doesErrorRequireCompanyToUpgrade = (error) => {
  var _a, _b, _c, _d;
  return ((_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.statusCode) === 403 && ((_d = (_c = error == null ? void 0 : error.response) == null ? void 0 : _c.data) == null ? void 0 : _d.message) === UPGRADE_REQUIRED_ERROR;
};
export const doesErrorRequireCompanyToIncreaseFixedSeatsLimit = (error) => {
  var _a, _b, _c, _d;
  return ((_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.statusCode) === 403 && ((_d = (_c = error == null ? void 0 : error.response) == null ? void 0 : _c.data) == null ? void 0 : _d.message) === SEAT_LIMIT_ERROR;
};
