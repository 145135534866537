"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Loader } from "@v2/components/loader.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { RefinancingAPI } from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api";
import {
  RefinancingCompanyStatus
} from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { nestErrorMessage } from "@/lib/errors";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const SearchCompanyNationalIdDrawer = ({
  isOpen,
  setIsOpen,
  order,
  refresh
}) => {
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState(false);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose: shouldRefreshOnClose ? refresh : void 0, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsx(SearchCompanyNationalIdDrawerContent, { order, setShouldRefreshOnClose })
    }
  ) });
};
const SearchCompanyNationalIdDrawerContent = ({
  order,
  setShouldRefreshOnClose
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const [query, setQuery] = useState(
    (_f = (_e = ((_a = order.company) == null ? void 0 : _a.entities) ? (_c = (_b = order.company) == null ? void 0 : _b.entities[0]) == null ? void 0 : _c.legalName : null) != null ? _e : (_d = order.company) == null ? void 0 : _d.name) != null ? _f : ""
  );
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [creditCheckLoading, setCreditCheckLoading] = useState(false);
  const [companyRefinancing, setCompanyRefinancing] = useState((_g = order.refinancing) != null ? _g : null);
  const [isSaved, setIsSaved] = useState(
    !!(((_h = order.company) == null ? void 0 : _h.entities[0]) && order.company.entities[0].nationalId)
  );
  const [results, setResults] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showMessage] = useMessage();
  const searchCompany = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const results2 = yield RefinancingAPI.searchCompanyNationalId(query);
      setResults(results2);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  });
  const searchCompanyAddressByNationalId = useCallback(
    (selectedOption2) => __async(void 0, null, function* () {
      setAddressLoading(true);
      try {
        const address2 = yield RefinancingAPI.searchCompanyAddressByNationalId(selectedOption2.id);
        if (!(address2 == null ? void 0 : address2.streetAddress) || !(address2 == null ? void 0 : address2.city) || !(address2 == null ? void 0 : address2.postalCode)) {
          showMessage("Missing address details.", "error");
          return;
        }
        const addressLine2 = {
          addressLine1: address2.streetAddress,
          postCode: address2.postalCode,
          city: address2.city,
          countryCode: "GB"
        };
        setAddress(addressLine2);
      } catch (error) {
        showMessage(`Could not fetch the company address. ${nestErrorMessage(error)}`, "error");
      } finally {
        setAddressLoading(false);
      }
    }),
    [showMessage]
  );
  useEffect(() => {
    if (selectedOption) searchCompanyAddressByNationalId(selectedOption);
  }, [selectedOption, searchCompanyAddressByNationalId]);
  const saveCompanyDetails = () => __async(void 0, null, function* () {
    if (!selectedOption || !address) {
      showMessage("Please select and id and an address.", "error");
      return;
    }
    try {
      yield RefinancingAPI.saveCompanyDetails(order.companyId, selectedOption.id, selectedOption.name, address);
      setIsSaved(true);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not save company details. ${nestErrorMessage(error)}`, "error");
    }
  });
  const runCreditCheck = () => __async(void 0, null, function* () {
    try {
      setCreditCheckLoading(true);
      const response = yield RefinancingAPI.runCompanyCreditCheck(order.companyId);
      setCompanyRefinancing(response != null ? response : null);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not run credit check. ${nestErrorMessage(error)}`, "error");
    } finally {
      setCreditCheckLoading(false);
    }
  });
  const addressLine = useMemo(() => {
    return address ? [address == null ? void 0 : address.addressLine1, address == null ? void 0 : address.postCode, address == null ? void 0 : address.city, address == null ? void 0 : address.countryCode].filter(Boolean).join(", ") : "";
  }, [address]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Credit check" }),
    /* @__PURE__ */ jsxs(Box, { sx: { mb: spacing.m20, display: "flex", flexDirection: "column", gap: spacing.m10 }, children: [
      ((_i = order.company) == null ? void 0 : _i.entities) && ((_j = order.company.entities[0]) == null ? void 0 : _j.legalName) && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Current legal name" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: order.company.entities[0].legalName })
      ] }),
      (companyRefinancing == null ? void 0 : companyRefinancing.creditCheckStatus) && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Current credit check status" }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
          companyRefinancing.creditCheckStatus === RefinancingCompanyStatus.Approved ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Red } })),
          /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: companyRefinancing.creditCheckStatus })
        ] })
      ] }),
      (companyRefinancing == null ? void 0 : companyRefinancing.minimumInstalments) && (companyRefinancing == null ? void 0 : companyRefinancing.maximumInstalments) && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Allowed instalments" }),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.title4, children: [
          companyRefinancing.minimumInstalments,
          " - ",
          companyRefinancing.maximumInstalments
        ] })
      ] }),
      (companyRefinancing == null ? void 0 : companyRefinancing.maximumInstalmentAmount) && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Maximum instalment amount" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: companyRefinancing.maximumInstalmentAmount })
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { mb: spacing.m10, display: "flex", gap: spacing.g10, alignItems: "center", width: "100%" }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Search query",
          name: "query",
          value: query,
          onChange: (e) => {
            setQuery(e.target.value);
          },
          clearText: () => setQuery(""),
          error: !!(results && results.length === 0),
          helperText: results && results.length === 0 ? "No results found" : void 0
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Search",
          onClick: searchCompany,
          loading,
          sizeVariant: "small",
          colorVariant: results && results.length > 0 || isSaved ? "secondary" : "primary",
          disabled: query.length < 3
        }
      )
    ] }),
    results && results.length > 0 && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "results",
        label: "Select company ID",
        options: results.map((result) => ({ value: result.id, label: `${result.name} (#${result.id})` })),
        value: (_k = selectedOption == null ? void 0 : selectedOption.id) != null ? _k : null,
        compareValue: (_l = selectedOption == null ? void 0 : selectedOption.id) != null ? _l : null,
        onChange: (e) => {
          const option = results.find((option2) => option2.id === e.target.value);
          setSelectedOption(option != null ? option : null);
        }
      }
    ) }),
    /* @__PURE__ */ jsxs(Loader, { loading: addressLoading, yMargin: "xxs", size: "xs", children: [
      address && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(TextfieldComponent, { label: "Company address", name: "address", value: addressLine }) }),
      selectedOption && address && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Save",
          loading: false,
          onClick: saveCompanyDetails,
          sizeVariant: "small",
          colorVariant: isSaved ? "secondary" : "primary"
        }
      ) })
    ] }),
    isSaved && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Run Credit Check",
        onClick: runCreditCheck,
        loading: creditCheckLoading,
        type: "button",
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    )
  ] });
};
