"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Box } from "@mui/material";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as MistakeIcon } from "@/images/alerts/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/alerts/Ok.svg";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { collateMissingFieldsForUser } from "@/v2/feature/user/features/user-forms/user-forms.util";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserProfileBar = ({ userId }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const currentScopesContext = getScopesContext({ userId });
  const hasAboutScope = hasScopes(["user.about:read"], scopesContext);
  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(userId);
  const manager = ((_a = user == null ? void 0 : user.role) == null ? void 0 : _a.managerId) ? getCachedUserById(user.role.managerId) : void 0;
  const { data: missingFields, isLoading: missingFieldsLoading } = useApiClient(
    UserEndpoints.getUserMissingFields(userId),
    { suspense: false }
  );
  const { data: aboutData } = useApiClient(UserEndpoints.getUserAbout(userId, hasAboutScope), { suspense: false });
  const { data: userSummary } = useApiClient(UserEndpoints.getUserSummaryById(userId), { suspense: false });
  const userMissingFields = useMemo(() => missingFields && collateMissingFieldsForUser(missingFields), [missingFields]);
  const directReportsId = useMemo(() => {
    var _a2;
    return (_a2 = userSummary == null ? void 0 : userSummary.directReports) == null ? void 0 : _a2.map((u) => u.userId);
  }, [userSummary]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "flex-start" }, children: [
      /* @__PURE__ */ jsx(UserAvatar, { userId, size: "large" }, userId),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, spacing.ml20), { display: "flex", flexDirection: "column" }), children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t((_b = user == null ? void 0 : user.displayName) != null ? _b : `${user == null ? void 0 : user.firstName} ${user == null ? void 0 : user.lastName}`) }),
        ((_d = (_c = user == null ? void 0 : user.role) == null ? void 0 : _c.jobPosition) == null ? void 0 : _d.title) && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, marginTop: spacing.m5 }, children: polyglot.t(user.role.jobPosition.title) }),
        /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user:all"], context: currentScopesContext, children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              gap: spacing.m10,
              marginTop: spacing.m10,
              minHeight: "20px"
            },
            children: missingFieldsLoading ? /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                width: "50%",
                height: "16px",
                sx: { borderRadius: 2, backgroundColor: themeColors.Background }
              }
            ) : userMissingFields && (userMissingFields == null ? void 0 : userMissingFields.length) > 1 ? (
              //userid is always on the first index
              /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    borderRadius: radius.br10
                  },
                  children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
                    /* @__PURE__ */ jsx(MistakeIcon, { style: { fill: themeColors.Red }, width: 14, height: 14 }),
                    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Red }, children: polyglot.t("DashboradUserProfile.missingInfo") })
                  ] })
                }
              )
            ) : userMissingFields && (userMissingFields == null ? void 0 : userMissingFields.length) <= 1 ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5, justifyContent: "center" }, children: [
              /* @__PURE__ */ jsx(OkGreen, { style: { fill: themeColors.Green }, width: 14, height: 14 }),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: polyglot.t("DashboradUserProfile.upToDate") })
            ] }) : /* @__PURE__ */ jsx(Fragment, {})
          }
        ) }, "actions")
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex", flexDirection: "column" }, spacing.gap20), { marginTop: spacing.m30 }), children: [
      ((_f = (_e = user == null ? void 0 : user.role) == null ? void 0 : _e.department) == null ? void 0 : _f.name) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.department") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t(user.role.department.name) })
      ] }),
      (user == null ? void 0 : user.emailAddress) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.email") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: user.emailAddress })
      ] }),
      manager && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.reportsTo") }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: manager.userId, size: "xxsmall" }),
          /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "title4",
              sx: {
                color: themeColors.DarkGrey
              },
              children: polyglot.t(manager.displayName)
            }
          )
        ] })
      ] }),
      directReportsId && directReportsId.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.directReports") }),
        /* @__PURE__ */ jsx(Box, { sx: { gap: spacing.m5, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: directReportsId, showLimit: 5 }) })
      ] }),
      (user == null ? void 0 : user.startDate) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.startDate") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: getDateString(new Date(user.startDate)) })
      ] }),
      ((_g = user == null ? void 0 : user.userEvent) == null ? void 0 : _g.status) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.status") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: user.userEvent.status })
      ] }),
      ((_i = (_h = user == null ? void 0 : user.role) == null ? void 0 : _h.site) == null ? void 0 : _i.name) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.site") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t(user.role.site.name) })
      ] }),
      (aboutData == null ? void 0 : aboutData.about) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.about") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: aboutData.about })
      ] }),
      (aboutData == null ? void 0 : aboutData.hobbies) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.hobbies") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: aboutData.hobbies })
      ] }),
      (aboutData == null ? void 0 : aboutData.foodPreferences) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserProfileBar.favouriteFood") }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: aboutData.foodPreferences })
      ] })
    ] })
  ] });
};
