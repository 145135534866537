"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { HelperAbsencePage } from "@v2/feature/super-admin/features/helper-dashboard/helper-absence.page";
import { HelperAccountingTransactionsPage } from "@v2/feature/super-admin/features/helper-dashboard/helper-accounting-transactions.page";
import { HelperCarryOverPage } from "@v2/feature/super-admin/features/helper-dashboard/helper-carry-over.page";
import { HelperCompanyPayrunsRouter } from "@v2/feature/super-admin/features/helper-dashboard/helper-company-payruns.router";
import { HelperDevicesRouter } from "@v2/feature/super-admin/features/helper-dashboard/helper-devices.router";
import {
  SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE,
  SUPER_ADMIN_HELPER_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { generatePath, Route, Switch, useHistory, useParams } from "react-router-dom";
import { HelperCompanyRecordsRouter } from "./helper-company-records.router";
export const HelperCompanyRouter = ({
  users,
  companies,
  loading
}) => {
  const routerHistory = useHistory();
  const params = useParams();
  const companyId = Number(params.companyId);
  const company = companies.find((company2) => company2.companyId === companyId);
  return company ? /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE, children: /* @__PURE__ */ jsx(HelperCompanyPayrunsRouter, { company }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE, children: /* @__PURE__ */ jsx(HelperDevicesRouter, { company }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE, children: /* @__PURE__ */ jsx(HelperCarryOverPage, { users, company }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE, children: /* @__PURE__ */ jsx(HelperAccountingTransactionsPage, { users, company }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE, children: /* @__PURE__ */ jsx(HelperAbsencePage, { company }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE, children: /* @__PURE__ */ jsx(HelperCompanyRecordsRouter, { company }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        TopHeader,
        {
          title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
            "Helper page | ",
            company.name,
            " [ ",
            companyId,
            " ]"
          ] }),
          showBack: true,
          backPath: SUPER_ADMIN_HELPER_ROUTE
        }
      ),
      /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2 }, children: [
        /* @__PURE__ */ jsx(
          Button,
          {
            variant: "outlined",
            onClick: () => routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE, { companyId })),
            children: "Payruns"
          }
        ),
        /* @__PURE__ */ jsx(
          Button,
          {
            variant: "outlined",
            onClick: () => routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE, { companyId })),
            children: "Devices"
          }
        ),
        /* @__PURE__ */ jsx(
          Button,
          {
            variant: "outlined",
            onClick: () => routerHistory.push(
              generatePath(SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE, { companyId })
            ),
            children: "Account Statements"
          }
        ),
        /* @__PURE__ */ jsx(
          Button,
          {
            variant: "outlined",
            onClick: () => routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE, { companyId })),
            children: "Absence records"
          }
        ),
        /* @__PURE__ */ jsx(
          Button,
          {
            variant: "outlined",
            onClick: () => routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_NEXT_YEAR_CARRY_OVER_ROUTE, { companyId })),
            children: "Absence"
          }
        ),
        /* @__PURE__ */ jsx(
          Button,
          {
            variant: "outlined",
            color: "error",
            onClick: () => routerHistory.push(generatePath(SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE, { companyId })),
            children: "Delete Records"
          }
        )
      ] }) })
    ] }) })
  ] }) : /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Helper page" }),
        showBack: true,
        backPath: SUPER_ADMIN_HELPER_ROUTE
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsx(Typography, { children: "Could not find the company!" }) })
  ] });
};
