"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { TEMPLATE_CONTRACT_SIGN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { MissingPersonalEmployeeFieldsModal } from "@/v2/feature/templates/components/missing-personal-employee-fields-modal.component";
export const OnboardingContracts = ({ selectedContract, openModal, setOpenModal }) => {
  const userId = useUserIdParam();
  const routerHistory = useHistory();
  const [missingFieldsForContractSigning, setMissingFieldsForContractSigning] = useState();
  const handleMissingFieldContractPopulation = useCallback(
    (missingFields) => {
      setMissingFieldsForContractSigning(missingFields);
      setOpenModal(true);
    },
    [setOpenModal]
  );
  const refreshMissingFieldsForEmployeeContract = (contractId) => __async(void 0, null, function* () {
    const missingFields = yield ContractAPI.getEmployeeFieldsForContractById(contractId);
    setMissingFieldsForContractSigning(missingFields);
    return missingFields;
  });
  const handleContractSignAction = useCallback(
    (contract) => __async(void 0, null, function* () {
      var _a;
      const recipientSignatureRequired = (contract2) => {
        return (contract2 == null ? void 0 : contract2.recipient) === userId && !(contract2 == null ? void 0 : contract2.recipientSignatureTimestamp);
      };
      if (recipientSignatureRequired(contract) && (contract == null ? void 0 : contract.id)) {
        const missingFields = yield refreshMissingFieldsForEmployeeContract(contract.id);
        if ((_a = Object.values(missingFields)) == null ? void 0 : _a.some((v) => v === true)) {
          handleMissingFieldContractPopulation(missingFields);
        } else {
          routerHistory.push(
            generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
              templateId: contract == null ? void 0 : contract.templateId,
              contractId: contract == null ? void 0 : contract.id,
              userId: contract == null ? void 0 : contract.recipient
            }),
            { returnPath: generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }) }
          );
        }
      }
    }),
    [handleMissingFieldContractPopulation, routerHistory, userId]
  );
  useEffect(() => {
    handleContractSignAction(selectedContract);
  }, [handleContractSignAction, selectedContract]);
  return /* @__PURE__ */ jsx(Fragment, { children: selectedContract && missingFieldsForContractSigning && openModal && /* @__PURE__ */ jsx(
    MissingPersonalEmployeeFieldsModal,
    {
      open: openModal,
      setOpen: setOpenModal,
      templateId: selectedContract.templateId,
      contractId: selectedContract.id,
      missingFields: missingFieldsForContractSigning,
      contractRecipientId: selectedContract.recipient,
      refreshMissingFields: refreshMissingFieldsForEmployeeContract
    }
  ) });
};
