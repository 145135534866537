"use strict";
import {
  INVOICES_COMPANY_OVERVIEW_ROUTE,
  INVOICES_ME_OVERVIEW_ROUTE,
  INVOICES_TEAM_OVERVIEW_ROUTE
} from "@/lib/routes";
export const MONEY_INVOICES_OVERVIEW_ROUTES = {
  company: INVOICES_COMPANY_OVERVIEW_ROUTE,
  me: INVOICES_ME_OVERVIEW_ROUTE,
  team: INVOICES_TEAM_OVERVIEW_ROUTE
};
export const MONEY_INVOICE_SCOPES = {
  company: ["invoices:all"],
  team: ["invoices:manager"],
  me: ["invoices"]
};
