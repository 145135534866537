"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Stack } from "@mui/material";
import { PayrollTestModeSettings } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-settings-test-mode.component";
import { PayrollEmploymentAllowanceSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/employer-allowance-settings/payroll-employment-allowance-section.component";
import { PayrollSettingHmrcSetting } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-setting.component";
import { PayrollCompanyHMRCSetupPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-hmrc-setup.page";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollCompanySettingsHMRC = ({ payroll, refreshPayroll }) => {
  const [edit, setEdit] = useState(false);
  const [otherEdit, setOtherEdit] = useState(false);
  const [connectHMRC, setConnectHMRC] = useState(false);
  if (connectHMRC) {
    return /* @__PURE__ */ jsx(RootStyle, { children: payroll.employer && /* @__PURE__ */ jsx(
      PayrollCompanyHMRCSetupPage,
      {
        payrollId: payroll.id,
        payroll: payroll.employer,
        onClose: () => __async(void 0, null, function* () {
          yield refreshPayroll();
          setConnectHMRC(false);
        })
      }
    ) });
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: { maxWidth: "650px", gap: spacing.g40, my: spacing.my20, mr: spacing.mr20 }, children: [
    (payroll == null ? void 0 : payroll.employer) && /* @__PURE__ */ jsx(
      PayrollSettingHmrcSetting,
      {
        payrollId: payroll.id,
        employer: payroll.employer,
        setEdit,
        edit,
        disable: otherEdit,
        refreshPayroll,
        onConnectClick: () => setConnectHMRC(true)
      }
    ),
    (payroll == null ? void 0 : payroll.employer) && /* @__PURE__ */ jsx(PayrollEmploymentAllowanceSection, { payrollId: payroll.id, setOtherEdit }),
    (payroll == null ? void 0 : payroll.employer) && /* @__PURE__ */ jsx(
      PayrollTestModeSettings,
      {
        payrollId: payroll.id,
        disable: edit || otherEdit,
        refreshPayroll,
        testMode: !!payroll.employer.rtiSubmissionSettings.useTestGateway
      }
    )
  ] });
};
