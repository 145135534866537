"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { EventsAPI } from "@/api-client/events.api";
import { UserEventSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserLifecycleStatuses } from "@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import {
  getAllChangeTypeOptions,
  getAllDefaultTerminationChangeReasonOptions,
  getAllEmployedOptions,
  getAllLeaveOptions,
  getOtherOption,
  getRehiredOption
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { LocalDate } from "@/v2/util/local-date";
const eventStatusOptions = [
  { value: "Employed", label: "Employed" },
  { value: "Leave", label: "Leave" },
  { value: "Terminated", label: "Terminated" }
];
export const UserProfileLifecycleForm = ({
  initialValues,
  allEntries,
  userId,
  loadInitialData,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  refreshLifecycle,
  formState
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const EFFECTIVE_END_DATE_DISABLE_FOR_STATUSES = ["Employed", "Terminated"];
  const HIDE_STATUS_FOR_FORM_STATE = ["Terminate", "Leave"];
  const STANDARD_SAVE_CANCEL_BUTTONS = ["Rehire", "Leave"];
  const DISABLED_CHANGE_TYPES = ["Imported", "imported"];
  const [showMessage] = useMessage();
  const defaultBlankModalValues = {
    id: 0,
    userId: 0,
    companyId: 0,
    createdBy: 0,
    updatedBy: 0,
    effectiveDate: new LocalDate().toDateString(),
    status: void 0,
    changeReason: "",
    note: null,
    effectiveEndDate: null
  };
  const getFormTitle = () => {
    if (formState === "Terminate") return formState;
    if (formState === "Leave") return polyglot.t("UserProfileLifecycleForm.addLeave");
    if (formState === "Rehire") return polyglot.t("UserProfileLifecycleForm.rehire");
    return "Add lifecycle";
  };
  const getEffectiveDateLabel = () => {
    if (formState === "Terminate") return polyglot.t("UserProfileLifecycleForm.terminationDate");
    if (formState === "Leave" || formState === "Rehire") return polyglot.t("UserProfileLifecycleForm.startDate");
    return polyglot.t("UserProfileLifecycleForm.takesEffectOn");
  };
  const getReasonBasedOnFormState = () => {
    if (formState === "Terminate" || initialValues && initialValues.status === "Terminated")
      return getAllDefaultTerminationChangeReasonOptions(polyglot);
    if (formState === "Leave") return [...getAllLeaveOptions(polyglot), getOtherOption(polyglot)];
    if (formState === "Rehire") return [getRehiredOption(polyglot)];
    if (initialValues && initialValues.status === "Employed") return getAllEmployedOptions(polyglot);
    return getAllChangeTypeOptions(polyglot);
  };
  const getSuccessMessageBasedOnFormState = () => {
    if (formState === "Terminate") return polyglot.t("UserProfileLifecycleForm.successMessages.terminate");
    if (formState === "Leave") return polyglot.t("UserProfileLifecycleForm.successMessages.addLeave");
    if (formState === "Rehire") return polyglot.t("UserProfileLifecycleForm.successMessages.rehire");
    return rowModalMode === "add" ? polyglot.t("UserProfileLifecycleForm.successMessages.add") : polyglot.t("UserProfileLifecycleForm.successMessages.update");
  };
  const getEmployedEventEffectiveDate = () => {
    var _a2;
    return (_a2 = allEntries == null ? void 0 : allEntries.find((eachEvent) => eachEvent.status === UserLifecycleStatuses.Employed)) == null ? void 0 : _a2.effectiveDate;
  };
  const getTerminatedEventEffectiveDate = () => {
    var _a2;
    return (_a2 = allEntries == null ? void 0 : allEntries.find((eachEvent) => eachEvent.status === UserLifecycleStatuses.Terminated)) == null ? void 0 : _a2.effectiveDate;
  };
  const oneDayBeforeTerminatedEvent = () => {
    if (formState === "Rehire") return void 0;
    let terminatedEffectiveDate = getTerminatedEventEffectiveDate();
    if (!terminatedEffectiveDate) return void 0;
    const terminatedEffectiveDateObj = new LocalDate(terminatedEffectiveDate).getDate();
    return new Date(terminatedEffectiveDateObj.setDate(terminatedEffectiveDateObj.getDate() - 1));
  };
  const oneDayAfterEmployedEvent = () => {
    const employedEffectiveDate = getEmployedEventEffectiveDate();
    if (!employedEffectiveDate) return void 0;
    const employedEffectiveDateObj = new LocalDate(employedEffectiveDate).getDate();
    return new Date(employedEffectiveDateObj.setDate(employedEffectiveDateObj.getDate() + 1));
  };
  const formik = useFormik({
    initialValues: initialValues != null ? initialValues : defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: UserEventSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const modalUserEventBody = __spreadProps(__spreadValues({}, values), {
          userId,
          createdBy: state.user.userId,
          updatedBy: state.user.userId,
          companyId: state.user.company.companyId
        });
        if (rowModalMode === "add") {
          yield EventsAPI.createUserEventEntry(userId, modalUserEventBody);
        } else if (rowModalMode === "edit") {
          yield EventsAPI.updateUserEventEntry(userId, modalUserEventBody);
          if (formState === "Rehire") yield EventsAPI.additionalStepsForRehire(userId);
        }
        showMessage(getSuccessMessageBasedOnFormState(), "success");
        yield loadInitialData();
        formik.resetForm();
        setIsModalOpen(false);
        yield refreshLifecycle();
      } catch (error) {
        showMessage(
          `${polyglot.t("UserProfileLifecycleForm.errorMessages.encounter")}: ${nestErrorMessage(error)}; ${polyglot.t(
            "UserProfileLifecycleForm.errorMessages.pleaseTryAgain"
          )}`,
          "error"
        );
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    rowModalMode === "add" ? /* @__PURE__ */ jsx(Typography, { variant: "title2", children: getFormTitle() }) : /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: formState ? getFormTitle() : "Edit lifecycle" }) }),
    formState && !HIDE_STATUS_FOR_FORM_STATE.includes(formState) && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "status",
        label: polyglot.t("UserProfileLifecycleForm.status"),
        disabled: formState === "Rehire" || !formState,
        options: eventStatusOptions,
        value: formik.values.status,
        compareValue: formik.values.status,
        error: !!formik.errors.status && formik.touched.status,
        onChange: formik.handleChange,
        helperText: formik.errors.status && formik.touched.status
      }
    ),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_a = formik.values.effectiveDate) != null ? _a : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("effectiveDate", value);
          }
        },
        name: "effectiveDate",
        label: getEffectiveDateLabel(),
        error: !!formik.errors.effectiveDate && formik.touched.effectiveDate,
        helperText: formik.errors.effectiveDate && formik.touched.effectiveDate,
        minDate: initialValues && initialValues.status === UserLifecycleStatuses.Terminated ? oneDayAfterEmployedEvent() : void 0,
        maxDate: initialValues && initialValues.status === UserLifecycleStatuses.Employed ? oneDayBeforeTerminatedEvent() : void 0
      }
    ),
    formik.values.status && !EFFECTIVE_END_DATE_DISABLE_FOR_STATUSES.includes(formik.values.status) && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_b = formik.values.effectiveEndDate) != null ? _b : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("effectiveEndDate", value);
          }
        },
        name: "effectiveEndDate",
        label: polyglot.t("UserProfileLifecycleForm.effectiveEndDate"),
        error: !!formik.errors.effectiveEndDate && formik.touched.effectiveEndDate,
        helperText: formik.errors.effectiveEndDate && formik.touched.effectiveEndDate
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "changeReason",
        label: polyglot.t("UserProfileLifecycleForm.changeType"),
        options: formState || initialValues && initialValues.status ? getReasonBasedOnFormState() : getAllChangeTypeOptions(polyglot),
        value: formik.values.changeReason,
        compareValue: formik.values.changeReason,
        error: !!formik.errors.changeReason && formik.touched.changeReason,
        onChange: formik.handleChange,
        helperText: formik.errors.changeReason && formik.touched.changeReason,
        disabled: DISABLED_CHANGE_TYPES.includes(formik.values.changeReason)
      }
    ),
    formState === "Terminate" && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("UserProfileLifecycleForm.saveClose"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: !formik.values.status || !formik.values.effectiveDate || !formik.values.changeReason
        }
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: !formik.values.status || !formik.values.effectiveDate || !formik.values.changeReason,
          onClick: () => {
            formik.handleSubmit();
          },
          children: polyglot.t("UserProfileLifecycleForm.offboard")
        }
      )
    ] }),
    (formState && STANDARD_SAVE_CANCEL_BUTTONS.includes(formState) || !formState) && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: !formik.values.status || !formik.values.effectiveDate || !formik.values.changeReason
        }
      )
    ] })
  ] }) });
};
