"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  EntityImportWizardData,
  EntityImportWizardSource
} from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { pluralText } from "@/v2/util/string.util";
export const ImportCompleteSection = ({ importState, onNext, sx }) => {
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Import completed" }),
    importState && importState.source && [EntityImportWizardSource.CSV, EntityImportWizardSource.AzureAD].includes(importState.source) && importState.domain === EntityImportWizardData.Users && importState.result && (importState == null ? void 0 : importState.result).importedCount > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5, minWidth: "300px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Successfully imported" }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
        (importState == null ? void 0 : importState.result).importedCount,
        " employee",
        pluralText((importState == null ? void 0 : importState.result).importedCount)
      ] })
    ] }),
    importState && importState.source === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Users && importState.result && (importState == null ? void 0 : importState.result).updatedCount > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5, minWidth: "300px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Successfully updated" }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
        (importState == null ? void 0 : importState.result).updatedCount,
        " employee",
        pluralText((importState == null ? void 0 : importState.result).updatedCount)
      ] })
    ] }),
    importState && importState.source === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Absences && importState.result && (importState == null ? void 0 : importState.result).importedCount > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5, minWidth: "300px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Successfully imported" }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
        (importState == null ? void 0 : importState.result).importedCount,
        " absence",
        pluralText((importState == null ? void 0 : importState.result).importedCount)
      ] })
    ] }),
    importState && importState.source === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Devices && importState.result && (importState == null ? void 0 : importState.result).importedCount > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5, minWidth: "300px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Successfully imported" }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
        (importState == null ? void 0 : importState.result).importedCount,
        " device",
        pluralText((importState == null ? void 0 : importState.result).importedCount)
      ] })
    ] }),
    importState && importState.source === EntityImportWizardSource.CSV && importState.domain === EntityImportWizardData.Devices && importState.result && (importState == null ? void 0 : importState.result).updatedCount > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5, minWidth: "300px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Successfully updated" }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
        (importState == null ? void 0 : importState.result).updatedCount,
        " device",
        pluralText((importState == null ? void 0 : importState.result).updatedCount)
      ] })
    ] }),
    importState && importState.source === EntityImportWizardSource.List && importState.domain === EntityImportWizardData.Documents && importState.result && (importState == null ? void 0 : importState.result).importedCount > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5, minWidth: "300px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Successfully imported" }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
        (importState == null ? void 0 : importState.result).importedCount,
        " document",
        pluralText((importState == null ? void 0 : importState.result).importedCount)
      ] })
    ] }),
    /* @__PURE__ */ jsxs(ButtonComponent, { colorVariant: "primary", sizeVariant: "large", fullWidth: true, onClick: onNext, children: [
      "Done",
      " "
    ] })
  ] });
};
