"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const GeneralPage = ({ initialValues, onNext }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      name: (_a = initialValues == null ? void 0 : initialValues.name) != null ? _a : "",
      description: (_b = initialValues == null ? void 0 : initialValues.description) != null ? _b : ""
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("GeneralPage.nameRequired")),
      description: yup.string().notRequired()
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      onNext(values);
    }
  });
  return /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, alignItems: "center", width: "100%" }, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { width: "450px", gap: spacing.g30 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("GeneralPage.general") }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: polyglot.t("GeneralPage.name"),
          value: formik.values.name,
          onChange: formik.handleChange,
          clearText: () => formik.setFieldValue("name", ""),
          maxLength: 255,
          autoFocus: true,
          helperText: formik.submitCount > 0 && formik.errors.name,
          error: formik.submitCount > 0 && !!formik.errors.name
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "description",
          label: polyglot.t("GeneralPage.description"),
          multiline: true,
          maxLength: 1e3,
          value: formik.values.description,
          onChange: formik.handleChange,
          clearText: () => formik.setFieldValue("description", ""),
          helperText: formik.submitCount > 0 && formik.errors.description,
          error: formik.submitCount > 0 && !!formik.errors.description
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: formik.isValid && /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "large", colorVariant: "primary", fullWidth: true, children: polyglot.t("General.continue") }) })
  ] }) }) });
};
