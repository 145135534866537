"use strict";
export class DocumentTypeEndpoints {
  static getTypeOptionsCompanyId() {
    return {
      url: `/apiv2/documents/custom-types/options`
    };
  }
  static getTypeCompanyId() {
    return {
      url: `/apiv2/documents/custom-types`
    };
  }
}
