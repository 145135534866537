"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import moment from "moment";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
const DATE_FORMAT = "DD/MM/YYYY";
export var ProductTypes = /* @__PURE__ */ ((ProductTypes2) => {
  ProductTypes2["PEOPLE"] = "People";
  ProductTypes2["APPS"] = "Apps";
  ProductTypes2["DEVICES"] = "Devices";
  ProductTypes2["MONEY"] = "Money";
  return ProductTypes2;
})(ProductTypes || {});
export const getPlanFromSubscriptions = (productType, subscriptions) => {
  return subscriptions.find((subscription) => {
    var _a;
    return (_a = subscription == null ? void 0 : subscription.planName) == null ? void 0 : _a.startsWith(productType);
  });
};
export const groupSubscriptionDiscounts = (subscription) => {
  if (!subscription) return [];
  return subscription.discounts.map((discount) => discount.description);
};
export class SuperAdminBillingPlanTableHelper {
  static getProductPlanColumn(columnDefinition) {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions }
        }
      }) => {
        var _a, _b;
        return /* @__PURE__ */ jsx("div", { children: subscriptions.length ? (_b = (_a = getPlanFromSubscriptions(productType, subscriptions)) == null ? void 0 : _a.planName) == null ? void 0 : _b.split(" ")[1] : /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Grey
            }),
            children: "\u2014"
          }
        ) });
      },
      size
    };
  }
  static getDateColumn(columnDefinition) {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions }
        }
      }) => {
        var _a;
        return /* @__PURE__ */ jsx("div", { children: subscriptions.length ? moment((_a = getPlanFromSubscriptions(productType, subscriptions)) == null ? void 0 : _a.activationDate).format(DATE_FORMAT) : /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Grey
            }),
            children: "\u2014"
          }
        ) });
      },
      size
    };
  }
  static getDiscountColumn(columnDefinition) {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions }
        }
      }) => subscriptions.length ? /* @__PURE__ */ jsx("div", { children: groupSubscriptionDiscounts(
        getPlanFromSubscriptions(productType, subscriptions)
      ).map((discount) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, width: "100%", children: discount })) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
      size
    };
  }
  static getPriceColumn(columnDefinition) {
    const { id, header, size, productType } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { subscriptions }
        }
      }) => {
        var _a;
        return /* @__PURE__ */ jsx("div", { children: subscriptions.length ? (_a = getPlanFromSubscriptions(productType, subscriptions)) == null ? void 0 : _a.cost : /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Grey
            }),
            children: "\u2014"
          }
        ) });
      },
      size
    };
  }
}
