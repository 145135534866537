"use strict";
import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import { ReviewEntriesPersonalStartModal } from "@/v2/feature/growth/reviews/features/review-personal/review-entry/components/review-entries-personal-start-modal.component";
export const ReviewActionItem = ({
  reviewActionRow,
  afterClose
}) => {
  const [isOpen, setIsOpen] = useState(Boolean(reviewActionRow));
  return /* @__PURE__ */ jsx(
    ReviewEntriesPersonalStartModal,
    {
      isOpen,
      setIsOpen,
      afterClose,
      onClose: () => setIsOpen(false),
      selectedEntry: reviewActionRow.entry
    }
  );
};
