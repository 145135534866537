"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { ReportsAlertMissingScopes } from "@v2/feature/reports/reports-advanced/components/reports-alert-missing-scopes.component";
import { ColumnTypeToSign, unselectColumnUtil } from "@v2/feature/reports/reports-advanced/reports-advanced.util";
import {
  ReportColumnType
} from "@v2/feature/reports/reports.interface";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Drag } from "@/images/side-bar-icons/Drag.svg";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const ColumnsListSection = ({
  selectedColumns,
  setSelectedColumns,
  editColumn,
  reportColumns
}) => {
  const columns = useMemo(() => {
    var _a;
    const cols = [];
    for (const stub in selectedColumns) {
      cols.push(
        ...((_a = selectedColumns[stub]) != null ? _a : []).map((c) => {
          var _a2;
          const col = __spreadProps(__spreadValues({}, c), { stub });
          const columnIsAvailableToSelect = (_a2 = reportColumns == null ? void 0 : reportColumns.find((g) => g.stub === col.stub)) == null ? void 0 : _a2.columns[c.col];
          if (!columnIsAvailableToSelect) col.missesScopes = true;
          return col;
        })
      );
    }
    return cols.sort((a, b) => a.order - b.order);
  }, [selectedColumns, reportColumns]);
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = reorder(columns, result.source.index, result.destination.index);
    setSelectedColumns((prev) => {
      const prevCopy = __spreadValues({}, prev);
      for (let order = 1; order < newItems.length + 1; order++) {
        const column = newItems[order - 1];
        const index = prevCopy[column.stub].findIndex(
          (col) => col.col === column.col && col.type === column.type && col.label === column.label
        );
        if (index >= 0) prevCopy[column.stub][index].order = order;
      }
      return prevCopy;
    });
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "8px", overflowY: "hidden" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Selected" }),
    /* @__PURE__ */ jsx(DragDropContext, { onDragEnd, children: /* @__PURE__ */ jsx(Droppable, { droppableId: "ROOT", children: (provided) => /* @__PURE__ */ jsxs(
      Box,
      __spreadProps(__spreadValues({}, provided.droppableProps), {
        ref: provided.innerRef,
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          height: "100%",
          overflowY: "auto",
          scrollbarWidth: "thin",
          paddingRight: "4px"
        },
        children: [
          columns.map((column, index) => /* @__PURE__ */ jsx(Draggable, { isDragDisabled: false, draggableId: String(column.order), index, children: (provided2, snapshot) => /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues(__spreadValues({}, provided2.dragHandleProps), provided2.draggableProps), { ref: provided2.innerRef, children: /* @__PURE__ */ jsx(
            ColumnDisplay,
            {
              column,
              setSelectedColumns,
              editColumn,
              isDragging: snapshot.isDragging
            },
            `${column.stub}-${column.col}-${column.type}`
          ) })) }, column.order)),
          provided.placeholder
        ]
      })
    ) }) })
  ] });
};
const ColumnDisplay = ({ column, setSelectedColumns, editColumn, isDragging }) => {
  var _a, _b;
  const [isGrabbing, setIsGrabbing] = useState(false);
  const handleMouseOver = () => {
    if (!isGrabbing) {
      document.body.style.cursor = "grab";
    }
  };
  const handleMouseLeave = () => {
    if (!isGrabbing) {
      document.body.style.cursor = "default";
    }
  };
  const handleMouseDown = () => {
    setIsGrabbing(true);
    document.body.style.cursor = "grabbing";
  };
  const handleMouseUp = () => {
    var _a2;
    setIsGrabbing(false);
    if ((_a2 = document.getElementById(`column-${column.stub}-${column.col}-${column.type}-drag`)) == null ? void 0 : _a2.matches(":hover")) {
      document.body.style.cursor = "grab";
    } else {
      document.body.style.cursor = "default";
    }
  };
  function unselectColumn(stub, col, type) {
    setSelectedColumns((prev) => unselectColumnUtil(prev, stub, col, type));
  }
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        bgcolor: isDragging ? themeColors.GreyHover : themeColors.Background,
        borderRadius: radius.br5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
      draggable: true,
      children: [
        /* @__PURE__ */ jsx(
          Box,
          {
            id: `column-${column.stub}-${column.col}-${column.type}-drag`,
            onMouseOver: handleMouseOver,
            onMouseLeave: handleMouseLeave,
            onMouseDown: handleMouseDown,
            onMouseUp: handleMouseUp,
            sx: { p: "4px" },
            children: /* @__PURE__ */ jsx(Drag, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.Grey }))
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              width: "100%",
              gap: spacing.s1,
              alignItems: "center",
              cursor: "pointer",
              ":hover": { background: themeColors.GreyHover },
              p: "4px"
            },
            onClick: () => {
              var _a2;
              return editColumn(column.stub, column.col, (_a2 = column.type) != null ? _a2 : ReportColumnType.PLAIN, column.order);
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (_a = column.label) != null ? _a : column.col }),
              column.type && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: (_b = ColumnTypeToSign[column.type]) != null ? _b : "" })
            ]
          }
        ),
        column.missesScopes && /* @__PURE__ */ jsx(ReportsAlertMissingScopes, { tooltip: "This column's values are unavailable due to insufficient permissions." }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              cursor: "pointer",
              p: "4px",
              ":hover": { background: themeColors.GreyHover }
            },
            onClick: () => {
              var _a2;
              return unselectColumn(column.stub, column.col, (_a2 = column.type) != null ? _a2 : ReportColumnType.PLAIN);
            },
            draggable: false,
            children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.Grey }))
          }
        )
      ]
    }
  );
};
