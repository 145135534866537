"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { StepperHeader } from "@/v2/components/stepper-header.component";
import { StepperComponent } from "@/v2/components/stepper.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { ExecutionDateStep } from "@/v2/feature/payments/features/make-payment/components/execution-date-step.component";
import { PayerDetailsStep } from "@/v2/feature/payments/features/make-payment/components/payer-details-step.component";
import { PaymentResultStep } from "@/v2/feature/payments/features/make-payment/components/payment-result-step.component";
import { PaymentYapilyConnectStep } from "@/v2/feature/payments/features/make-payment/components/payment-yapily-connect-step.component";
import { PAYMENTS_ROUTE } from "@/v2/feature/payments/payments.router";
import { spacing } from "@/v2/styles/spacing.styles";
export const PaymentFlowPage = ({ showPayerDetails }) => {
  var _a;
  const routerHistory = useHistory();
  const paymentSteps = useMemo(() => [...showPayerDetails ? ["Details"] : [], "Date", "Confirm", "Success"], [
    showPayerDetails
  ]);
  const [step, setStep] = useState(showPayerDetails ? "Details" : "Date");
  const [unsafeClose, setUnsafeClose] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const paymentStepsComponent = useMemo(
    () => [
      ...showPayerDetails ? [{ name: "Details", component: /* @__PURE__ */ jsx(PayerDetailsStep, { onNext: () => setStep("Date") }) }] : [],
      { name: "Date", component: /* @__PURE__ */ jsx(ExecutionDateStep, { onNext: () => setStep("Confirm") }) },
      {
        name: "Confirm",
        component: /* @__PURE__ */ jsx(PaymentYapilyConnectStep, { onNext: () => setStep("Success"), setUnsafeClose })
      },
      { name: "Success", component: /* @__PURE__ */ jsx(PaymentResultStep, {}) }
    ],
    [showPayerDetails]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
    /* @__PURE__ */ jsx(
      StepperHeader,
      {
        content: /* @__PURE__ */ jsx(StepperComponent, { steps: paymentSteps, activeStep: step }),
        contentSx: { width: "80%", textAlign: "center" },
        showStepBack: true,
        stepBackAction: () => {
          if (paymentSteps.indexOf(step) > 0) return setStep(paymentSteps[paymentSteps.indexOf(step) - 1]);
          routerHistory.goBack();
        },
        stepBackSx: {
          width: "10%",
          textAlign: "left"
        },
        showBack: true,
        backAction: (e) => {
          if (unsafeClose) {
            setAnchorEl(e.currentTarget);
          } else routerHistory.push(PAYMENTS_ROUTE);
        },
        backSx: { width: "10%", textAlign: "right" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 } }),
    (_a = paymentStepsComponent[paymentSteps.indexOf(step)]) == null ? void 0 : _a.component,
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: Boolean(anchorEl),
        onClose: () => {
          setAnchorEl(null);
        },
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          return routerHistory.push(PAYMENTS_ROUTE);
        }),
        message: `Are you sure you want to close this page? The payment is still being processed. Closing may cause errors.`,
        callToAction: "Yes"
      }
    )
  ] });
};
