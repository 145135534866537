"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ChartRadar } from "@/v2/components/charts/chart-radar.component";
import { iconSize } from "@/v2/components/forms/editable-title.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import {
  externalTooltipHandler,
  getReviewerTypesColor,
  hexToRGBA,
  TitleStatusComponent
} from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { capturePdf, capturePNG } from "@/v2/util/export-reports.util";
export const ReviewCycleDetailResultRadar = ({
  reviewCycle,
  cycleLoading,
  reachType
}) => {
  const params = useParams();
  const { cycleId, revieweeId } = params;
  const { data: radarData, isLoading: radarDataLoading, error: radarError } = useApiClient(
    ReviewResultEndpoints.getRadarChartByReviewee(revieweeId, cycleId),
    {
      suspense: false
    }
  );
  const reportNamePdf = useMemo(() => `${reviewCycle.internalName}-radar-pdf`, [reviewCycle.internalName]);
  const reportNamePng = useMemo(() => `${reviewCycle.internalName}-radar-png`, [reviewCycle.internalName]);
  const shouldShowEmptyState = useMemo(() => {
    var _a, _b, _c, _d;
    return Boolean(
      ((_b = (_a = radarData == null ? void 0 : radarData.radarChartByFactors) == null ? void 0 : _a.categories) == null ? void 0 : _b.length) === 0 && ((_d = (_c = radarData == null ? void 0 : radarData.radarChartByQuestionId) == null ? void 0 : _c.categories) == null ? void 0 : _d.length) === 0
    ) || !radarData;
  }, [radarData]);
  if (!radarData && radarError && radarError.status === 403) {
    return /* @__PURE__ */ jsx("div", { style: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) });
  }
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      loading: radarDataLoading || cycleLoading,
      buttons: [
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                handler: () => capturePdf(reportNamePdf),
                label: "PDF report"
              },
              {
                handler: () => capturePNG(reportNamePng),
                label: "PNG report"
              }
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Export",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ],
      children: /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          id: reportNamePdf,
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: "Radar chart",
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    RadarChartByCategories,
                    {
                      radarData,
                      shouldShowEmptyState,
                      reportNamePng
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    }
  );
};
const RadarChartByCategories = ({
  radarData,
  shouldShowEmptyState,
  reportNamePng
}) => {
  var _a;
  const [data, setData] = useState(radarData == null ? void 0 : radarData.radarChartByQuestionId);
  const [label, setLabel] = useState("Questions");
  const getActions = () => {
    return [
      {
        handler: () => {
          setData(radarData == null ? void 0 : radarData.radarChartByQuestionId);
          setLabel("Questions");
        },
        label: "Questions",
        disabled: false
      },
      {
        label: "Factors",
        handler: () => {
          setData(radarData == null ? void 0 : radarData.radarChartByFactors);
          setLabel("Factors");
        },
        disabled: false
      }
    ];
  };
  const seriesData = useMemo(() => {
    var _a2;
    const series = (_a2 = data == null ? void 0 : data.series) != null ? _a2 : [];
    return series.map((s) => {
      return __spreadProps(__spreadValues({}, s), {
        fill: true,
        borderColor: themeColors[getReviewerTypesColor()[s.label]],
        backgroundColor: hexToRGBA(themeColors[getReviewerTypesColor()[s.label]], 0.2),
        pointBackgroundColor: themeColors[getReviewerTypesColor()[s.label]],
        pointHoverBackgroundColo: themeColors[getReviewerTypesColor()[s.label]]
      });
    });
  }, [data == null ? void 0 : data.series]);
  return !shouldShowEmptyState ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx("div", { className: "hide-for-capture", children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: getActions(),
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: label,
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end",
          style: { borderRadius: radius.br25 }
        }
      }
    ) }),
    /* @__PURE__ */ jsx(
      Box,
      {
        id: reportNamePng,
        sx: {
          width: "100%",
          height: "500px",
          border: borders.background,
          borderRadius: radius.br12,
          paddingY: spacing.p24
        },
        children: /* @__PURE__ */ jsx(
          ChartRadar,
          {
            series: seriesData != null ? seriesData : [],
            categories: (_a = data == null ? void 0 : data.categories) != null ? _a : [],
            externalTooltipHandler,
            tooltipEnabled: false
          }
        )
      }
    )
  ] }) : /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        width: "100%",
        height: "500px",
        border: borders.background,
        borderRadius: radius.br12,
        paddingY: spacing.p24
      },
      children: /* @__PURE__ */ jsx(ChartRadar, { series: [], categories: [], externalTooltipHandler, tooltipEnabled: false })
    }
  );
};
