"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { CompanyPayrollType } from "@v2/feature/payroll/payroll.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Document.svg";
import { nestErrorMessage } from "@/lib/errors";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { RevealableCell, RevealableColumnHeader } from "@/v2/components/table/revealable-column-header.component";
import { sortDate, sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { PayrollTableSkeleton } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/payroll-table-skeleton.component";
import { GlobalPayrollSalarySummaryDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/global-payroll-salary-summary-drawer.component";
import { DocPreviewer } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { UKPayrollAPI } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/user-payroll.api";
import { PayrollLocalEndpoints } from "@/v2/feature/payroll/payroll-local.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { formatMediumDate, formatShortMonthYear } from "@/v2/util/date-format.util";
const taxYearToYearNumber = (value) => Number(value.slice(4));
export const payslipDownloadUrl = (pdfPath) => {
  return window.location.origin.concat(pdfPath);
};
export const PersonalPayroll = ({ userId }) => {
  const [columnsRevealed, setColumnsRevealed] = useState({});
  const [viewingPayslip, setViewingPayslip] = useState(null);
  const [viewingGlobalPayslip, setViewingGlobalPayslip] = useState(null);
  const [downloadingP60s, setDownloadingP60s] = useState(/* @__PURE__ */ new Set());
  const p60Urls = useRef(/* @__PURE__ */ new Map());
  const [showMessage] = useMessage();
  const { data: payslips, isLoading: loading } = useApiClient(PayrollLocalEndpoints.getPayslipsForUser(userId), {
    suspense: false
  });
  const inGlobalPayroll = payslips == null ? void 0 : payslips.every((p) => p.payrollKind === CompanyPayrollType.GlobalPayroll);
  const changeColumnReveal = useCallback((columnId, revealed) => {
    setColumnsRevealed((revealState) => __spreadProps(__spreadValues({}, revealState), {
      [columnId]: revealed
    }));
  }, []);
  const fetchP60Data = useCallback(
    (userId2, taxYear, window2) => __async(void 0, null, function* () {
      setDownloadingP60s((s) => /* @__PURE__ */ new Set([...s, taxYear]));
      try {
        let p60Url = p60Urls.current.get(taxYear);
        if (!p60Url) {
          const { pdfUrl } = yield UKPayrollAPI.getP60DocumentForUser(userId2, taxYearToYearNumber(taxYear));
          p60Url = pdfUrl;
          p60Urls.current.set(taxYear, p60Url);
        }
        if (window2) window2.location = p60Url;
      } catch (error) {
        showMessage(`P60 ${userId2}/${taxYear} download failed. ${nestErrorMessage(error)}`, "error");
      } finally {
        setDownloadingP60s((s) => /* @__PURE__ */ new Set([...(s.delete(taxYear), s)]));
      }
    }),
    [showMessage]
  );
  const handleDocumentButtonClick = useCallback(
    (item, isPreview = false) => __async(void 0, null, function* () {
      if (item.entryType === "Payslip" && item.payrollKind === CompanyPayrollType.UKPayroll && item.entryId) {
        const url = isPreview ? `/apiv2/payroll/payruns/${item.taxYear}/${item.payPeriod}/${item.period}/${item.entryId}/${userId}/payslip-pdf/download/preview` : `/apiv2/payroll/payruns/${item.entryId}/${userId}/payslip-pdf/download`;
        const payslipUrl = payslipDownloadUrl(url);
        if (payslipUrl) setViewingPayslip(payslipUrl);
        else showMessage(`Encountered error while trying to download payslip: ${JSON.stringify(item)}`, "error");
        return;
      }
      if (item.entryType === "Payslip" && item.payrollKind === CompanyPayrollType.GlobalPayroll) {
        const url = `/apiv2/company/salary/payroll/${item.payrollId}/global/global-payroll/${item.taxYear}/${item.payPeriod}/${item.period}/${item.userId}/payslip-pdf/download`;
        const payslipUrl = payslipDownloadUrl(url);
        if (payslipUrl) setViewingPayslip(payslipUrl);
      }
      if (item.entryType === "P60") {
        fetchP60Data(userId, item.taxYear, window.open());
        return;
      }
    }),
    [fetchP60Data, showMessage, userId]
  );
  const payrunColumns = useMemo(
    () => [
      {
        id: "date",
        size: 120,
        enableSorting: true,
        accessorFn: (row) => row,
        header: () => "Date",
        sortingFn: (a, b) => sortDate(a, b, (item) => item.paymentDate),
        cell: ({ row: { original } }) => {
          if (!original.paymentDate) return "";
          return original.payPeriod === "Monthly" ? formatShortMonthYear(original.paymentDate) : formatMediumDate(original.paymentDate);
        }
      },
      {
        id: "gross",
        header: () => /* @__PURE__ */ jsx(
          RevealableColumnHeader,
          {
            label: inGlobalPayroll ? "Total payments" : "Gross",
            visible: columnsRevealed["gross"],
            onVisibilityChange: (visible) => changeColumnReveal("gross", visible)
          }
        ),
        size: 80,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.gross),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(RevealableCell, { visible: columnsRevealed["gross"], children: typeof original.gross === "number" ? formatCurrency(original.gross, { noCurrencySymbol: true }) : "" })
      },
      {
        id: "take-home",
        header: () => /* @__PURE__ */ jsx(
          RevealableColumnHeader,
          {
            label: "Take home",
            visible: columnsRevealed["take-home"],
            onVisibilityChange: (visible) => changeColumnReveal("take-home", visible)
          }
        ),
        size: 80,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.takeHome),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(RevealableCell, { visible: columnsRevealed["take-home"], children: typeof original.takeHome === "number" ? formatCurrency(original.takeHome, { noCurrencySymbol: true }) : "" })
      },
      {
        id: "payroll-name",
        header: () => "Payroll",
        size: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortString(a, b, (item) => item.payrollName),
        cell: ({ row: { original } }) => original.payrollName
      },
      {
        id: "doc-type",
        header: () => "Document type",
        size: 100,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortString(a, b, (item) => item.entryType),
        cell: ({ row: { original } }) => original.entryType
      },
      {
        id: "tax-year",
        header: () => "Tax year",
        size: 60,
        enableSorting: true,
        accessorFn: (row) => row,
        // when sorting by tax year, also sort by period
        sortingFn: (a, b) => sortNumeric(a, b, (item) => taxYearToYearNumber(item.taxYear) * 100 + item.period),
        cell: ({ row: { original } }) => original.taxYear
      },
      {
        id: "period",
        header: () => "Period",
        size: 40,
        enableSorting: true,
        accessorFn: (row) => row,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.period),
        cell: ({ row: { original } }) => original.period
      },
      {
        id: "action-buttons",
        header: () => "",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 40,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Stack, { flexDirection: "row", justifyContent: "flex-end", gap: "5px", whiteSpace: "nowrap", children: /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: (event) => {
                event.preventDefault();
                event.stopPropagation();
                handleDocumentButtonClick(original, original.isPreview);
              },
              disabled: downloadingP60s.has(original.taxYear),
              title: "View/Download",
              children: /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize))
            }
          ) });
        }
      }
    ],
    [inGlobalPayroll, columnsRevealed, changeColumnReveal, downloadingP60s, handleDocumentButtonClick]
  );
  const handleRowClick = useCallback(({ original }) => {
    if (original.payrollKind === CompanyPayrollType.GlobalPayroll) setViewingGlobalPayslip(original);
  }, []);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
    loading && /* @__PURE__ */ jsx(PayrollTableSkeleton, { includeMargin: false, includeGutter: true, rows: 5 }),
    !loading && (payslips == null ? void 0 : payslips.length) === 0 && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "Once your first salary is paid, you will find your payslips here." }),
    !loading && !!(payslips == null ? void 0 : payslips.length) && /* @__PURE__ */ jsx(BasicTable, { rowData: payslips, columnData: payrunColumns, rowClick: handleRowClick }),
    viewingPayslip && /* @__PURE__ */ jsx(DocPreviewer, { docData: viewingPayslip, visible: true, onClose: () => setViewingPayslip(null), title: "Payslip" }),
    /* @__PURE__ */ jsx(
      GlobalPayrollSalarySummaryDrawer,
      {
        payslip: viewingGlobalPayslip,
        onClose: () => setViewingGlobalPayslip(null)
      }
    )
  ] });
};
