"use strict";
export function setFocusToInput(inputElement, selectContent = true) {
  const element = typeof inputElement === "string" ? document.getElementById(inputElement) : inputElement;
  if ((element == null ? void 0 : element.nodeName) === "INPUT" || (element == null ? void 0 : element.nodeName) === "TEXTAREA") {
    element.focus();
    if (selectContent) {
      element.select();
    }
  }
}
