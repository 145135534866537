"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { CustomBenefitSettingsApprovalPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-approval.page";
import { CustomBenefitSettingsGeneralPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-general.page";
import { CustomBenefitSettingsMembersPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-members.page";
import { CustomBenefitSettingsPaymentPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/pages/custom-benefit-settings-payment.page";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateCustomBenefitCategory } from "@v2/infrastructure/i18n/translate.util";
import { generatePath, Redirect, Route, Switch } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  SETTINGS_BENEFITS_DETAILS_APPROVAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE,
  SETTINGS_BENEFITS_DETAILS_PAYMENT_ROUTE,
  SETTINGS_BENEFITS_ROUTE
} from "@/lib/routes";
export const getPageConfig = (category, id, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("General.general"),
          stub: "general",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, { category, id, productType: "custom" })
        },
        {
          title: polyglot.t("BenefitModule.payment"),
          stub: "payment",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_PAYMENT_ROUTE, { category, id, productType: "custom" })
        },
        {
          title: polyglot.t("General.members"),
          stub: "members",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, { category, id, productType: "custom" })
        },
        {
          title: polyglot.t("BenefitModule.approval"),
          stub: "approval",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_APPROVAL_ROUTE, { category, id, productType: "custom" })
        }
      ]
    }
  ];
};
export const CustomBenefitSettingsRouter = ({ id, category }) => {
  var _a;
  const { data: customBenefit, mutate: refresh } = useApiClient(CustomBenefitEndpoints.getCustomBenefitById(id));
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const refreshBenefit = useCallback(() => __async(void 0, null, function* () {
    try {
      if (refresh) yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [polyglot, refresh, showMessage]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_a = customBenefit == null ? void 0 : customBenefit.name) != null ? _a : polyglot.t("BenefitModule.customBenefit"),
        subtitle: translateCustomBenefitCategory(category, polyglot),
        pageConfig: getPageConfig(category, id, polyglot),
        backPath: SETTINGS_BENEFITS_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    customBenefit && /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        CustomBenefitSettingsGeneralPage,
        {
          category,
          customBenefit,
          refreshBenefit
        }
      ) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, children: /* @__PURE__ */ jsx(CustomBenefitSettingsMembersPage, { customBenefit, refreshBenefit }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_PAYMENT_ROUTE, children: /* @__PURE__ */ jsx(CustomBenefitSettingsPaymentPage, { customBenefit, refreshBenefit }) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_APPROVAL_ROUTE, children: /* @__PURE__ */ jsx(CustomBenefitSettingsApprovalPage, { customBenefit, refreshBenefit }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE })
    ] })
  ] });
};
