"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@/v2/styles/spacing.styles";
export const GrowthScaleViewModal = ({
  isOpen,
  setIsOpen,
  growthScale,
  onClose,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, sx: { px: 0 }, children: /* @__PURE__ */ jsx(GrowthScaleViewContent, { growthScale }) });
};
const GrowthScaleViewContent = ({ growthScale }) => {
  const transformedData = growthScale ? Object.keys(growthScale.points).map((key) => ({
    points: growthScale.points[key],
    value: growthScale.value[key],
    sentiments: growthScale.sentiments[key]
  })) : [];
  const tableColumns = useMemo(
    () => [
      {
        header: () => "Points",
        accessorFn: (row) => row,
        id: "point",
        enableSorting: false,
        cell: ({
          row: {
            original: { points }
          }
        }) => points ? /* @__PURE__ */ jsxs("div", { children: [
          points,
          " "
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 170,
        maxSize: 200
      },
      {
        header: () => "Values",
        accessorFn: (row) => row,
        id: "value",
        enableSorting: false,
        cell: ({
          row: {
            original: { value }
          }
        }) => value ? /* @__PURE__ */ jsxs("div", { children: [
          value,
          " "
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 30,
        maxSize: 40
      },
      {
        header: () => "Sentiments",
        accessorFn: (row) => row,
        id: "sentiments",
        enableSorting: false,
        cell: ({
          row: {
            original: { sentiments }
          }
        }) => sentiments ? /* @__PURE__ */ jsxs("div", { children: [
          sentiments,
          " "
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 60,
        maxSize: 80
      }
    ],
    []
  );
  if (!growthScale)
    return /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, drawerContentSx), { px: spacing.s2 }), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Scale" }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Scale information not found" })
    ] });
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { px: spacing.s2 }, children: growthScale.type }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1, px: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Created by" }),
      growthScale.companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
        /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "1em", width: "1em", padding: ".25em" }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Zelt" })
      ] }) : growthScale.createdBy ? /* @__PURE__ */ jsx(UserCell, { userId: growthScale.createdBy }) : /* @__PURE__ */ jsx(EmptyCell, {})
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.s3 }, children: /* @__PURE__ */ jsx(BasicTable, { rowData: transformedData != null ? transformedData : [], columnData: tableColumns, hidePagination: true, style: { padding: 0 } }) })
  ] });
};
