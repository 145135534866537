"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import {
  AttendanceStatus,
  ScheduleTrackingTimesheetType,
  ScheduleTrackingType,
  WeekDayNames
} from "@v2/feature/attendance/attendance.interface";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { extractTimeOnlyFromDateTimeString } from "@v2/util/date-format.util";
import { LocalDate } from "@v2/util/local-date";
import { format } from "date-fns";
import * as yup from "yup";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as WaitingEmpty } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { ReactComponent as WaitingEmptyRed } from "@/images/side-bar-icons/WaitingEmptyRed.svg";
import { spacing } from "@/v2/styles/spacing.styles";
export const getTheDateOfFirstMondayInYear = (year = (/* @__PURE__ */ new Date()).getFullYear()) => {
  const firstDay = new Date(year, 0, 1);
  const dayOfWeek = firstDay.getDay();
  const daysToAdd = (8 - dayOfWeek) % 7;
  const firstMonday = new Date(firstDay);
  firstMonday.setDate(firstDay.getDate() + daysToAdd);
  return new LocalDate(firstMonday).toDateString();
};
export const EMPLOYEE_SITE = (polyglot) => {
  return { value: "employeeSite", label: polyglot.t("EMPLOYEE_SITE.employeeSite") };
};
export const getScheduleCreateValidationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("validation.requiredField"))
});
export const getScheduleGeneralValidationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("validation.requiredField"))
});
export const getScheduleTrackingSettingsValidationSchema = (polyglot) => yup.object({
  trackingType: yup.string().required(polyglot.t("validation.requiredField")),
  timesheetType: yup.string().oneOf([
    ScheduleTrackingTimesheetType.Daily,
    ScheduleTrackingTimesheetType.Weekly,
    ScheduleTrackingTimesheetType.Monthly
  ]).required(polyglot.t("validation.selectValid")),
  useGeolocation: yup.boolean().required(polyglot.t("validation.requiredField")),
  restrictByGeolocation: yup.boolean().required(polyglot.t("validation.requiredField")),
  geolocationDistance: yup.number().when("restrictByGeolocation", {
    is: true,
    then: (schema) => schema.integer(polyglot.t("validation.selectValid")).typeError(polyglot.t("validation.selectValid")).min(100, polyglot.t("AttendanceDomain.minimumDistanceAllowed", { distance: 100 })).max(500, polyglot.t("AttendanceDomain.maximumDistanceAllowed", { distance: 500 })).required(polyglot.t("validation.requiredField")),
    otherwise: (schema) => schema.nullable().notRequired()
  }),
  geolocationEmployeeSite: yup.boolean().required(polyglot.t("validation.requiredField")),
  allowedWorkSites: yup.array().when("restrictByGeolocation", {
    is: true,
    then: (schema) => schema.when("geolocationEmployeeSite", {
      is: true,
      then: (schema2) => schema2.of(yup.number().integer().typeError(polyglot.t("validation.selectValid"))).nullable().notRequired(),
      otherwise: (schema2) => schema2.of(yup.number().integer().typeError(polyglot.t("validation.selectValid"))).min(1, polyglot.t("validation.requiredField")).required(polyglot.t("validation.requiredField"))
    }),
    otherwise: (schema) => schema.nullable().notRequired()
  }),
  clockInEarlyCapMinutes: yup.number().when("trackingType", {
    is: (val) => val === ScheduleTrackingType.ClockInClockOut,
    then: (schema) => schema.integer().typeError(polyglot.t("validation.selectValid")).min(0, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
    otherwise: (schema) => schema.nullable().notRequired()
  })
});
export const getScheduleApprovalValidationSchema = (polyglot) => yup.object({
  approvalRuleId: yup.number().integer().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
});
export const getSchedulePayrollValidationSchema = (polyglot) => yup.object({
  includedInPayroll: yup.boolean().required(polyglot.t("validation.requiredField")),
  payCode: yup.string().when("includedInPayroll", {
    is: true,
    then: (schema) => schema.required(polyglot.t("validation.requiredField")),
    otherwise: (schema) => schema.notRequired()
  })
});
export const getScheduleSettingsValidationSchema = (polyglot) => yup.object({
  isFlexible: yup.boolean().required(polyglot.t("validation.requiredField")),
  fteEquivalent: yup.number().integer(polyglot.t("validation.selectValid")).typeError(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.selectValid")).max(10080, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
  fteEquivalentInDays: yup.number().typeError(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.selectValid")).max(7, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
  attendanceTypesAllowedIds: yup.array().of(
    yup.number().integer().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
  ).required(polyglot.t("validation.requiredField"))
});
export function getTrackingTypeLabel(type, polyglot) {
  if (type === ScheduleTrackingType.None) return polyglot.t("getTrackingTypeLabel.noTracking");
  if (type === ScheduleTrackingType.ClockInClockOut) return polyglot.t("getTrackingTypeLabel.clockInOut");
  if (type === ScheduleTrackingType.Regular) return polyglot.t("getTrackingTypeLabel.regularTracking");
  return "Unknown tracking type";
}
export function getTrackingTypeLabelDescription(type, polyglot) {
  if (type === ScheduleTrackingType.None) return polyglot.t("getTrackingTypeLabelDescription.employees");
  if (type === ScheduleTrackingType.ClockInClockOut) return polyglot.t("getTrackingTypeLabelDescription.clockIn");
  if (type === ScheduleTrackingType.Regular) return polyglot.t("getTrackingTypeLabelDescription.loggingHours");
  return "";
}
export const getAllTimeFromScheduleByWeekIndex = (schedule, weekIndex, attendancePayMultipliersById) => {
  return WeekDayNames.reduce((total, day) => {
    if (!schedule[day] || !schedule[day][weekIndex] || schedule[day][weekIndex].length === 0) return total;
    const dayEntries = schedule[day][weekIndex].filter(Boolean);
    if (dayEntries.length === 0) return total;
    total += getTotalLengthOfDayFromScheduleEntries(dayEntries, attendancePayMultipliersById, schedule.isFlexible);
    return total;
  }, 0);
};
function getTimeDifferenceInMinutes(time1, time2) {
  const [h1, m1] = time1.split(":");
  const [h2, m2] = time2.split(":");
  const minutes1 = Math.round(Number(h1) * 60 + Number(m1));
  const minutes2 = Math.round(Number(h2) * 60 + Number(m2));
  return Math.abs(minutes1 - minutes2);
}
export function calculateSingleEntryLengthInMinutes(entry) {
  if (!entry.startHour || !entry.endHour) return 0;
  const startTime = extractTimeOnlyFromDateTimeString(entry.startHour);
  const endTime = extractTimeOnlyFromDateTimeString(entry.endHour);
  const isOvernight = startTime > endTime;
  if (isOvernight)
    return getTimeDifferenceInMinutes(startTime, "23:59") + 1 + getTimeDifferenceInMinutes("00:00", endTime);
  return getTimeDifferenceInMinutes(startTime, endTime);
}
export function calculateTotalEntriesDuration(allEntries, polyglot) {
  const totalLength = allEntries.reduce((sum, entry) => sum + calculateSingleEntryLengthInMinutes(entry), 0);
  return convertMinutesToClockHours(totalLength, polyglot);
}
export function getAttendanceEntriesStatus(attendanceEntries) {
  if (attendanceEntries.length === 0) return null;
  if (attendanceEntries.every((entry) => entry.status === AttendanceStatus.Rejected)) return AttendanceStatus.Rejected;
  if (attendanceEntries.some(
    (entry) => entry.status === AttendanceStatus.Approved || entry.status === AttendanceStatus.Rejected
  ))
    return AttendanceStatus.Approved;
  return AttendanceStatus.Submitted;
}
export const getAttendanceStatusIcon = (status, cancellationRequested, polyglot) => {
  switch (status) {
    case AttendanceStatus.InProgress:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceStatus.inprogress") })
      ] });
    case AttendanceStatus.Submitted:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceStatus.submitted") })
      ] });
    case AttendanceStatus.Approved:
      return cancellationRequested ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(WaitingEmptyRed, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Red }), children: polyglot.t("AttendanceStatus.pendingcancellation") })
      ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: polyglot.t("AttendanceStatus.approved") })
      ] });
    case AttendanceStatus.Rejected:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: polyglot.t("AttendanceStatus.rejected") })
      ] });
    case "none":
      return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("AttendanceStatus.none") });
    default:
      return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: status });
  }
};
export function getWeekDates(year, weekNumber) {
  const startDate = new Date(year, 0, 1);
  const firstDay = startDate.getDay();
  const daysToAdd = (weekNumber - 1) * 7 - firstDay + 1;
  startDate.setDate(startDate.getDate() + daysToAdd);
  const dates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate.getTime());
    currentDate.setDate(currentDate.getDate() + i);
    dates.push({
      value: new LocalDate(currentDate).toDateString(),
      label: format(currentDate, "eee")
    });
  }
  return dates;
}
export function getTimeFromDateString(dateString) {
  const dateTimeParts = dateString.split("T");
  return dateTimeParts[1].substring(0, 5);
}
export function getWeekStatusFromAttendanceStatuses(weekStatuses) {
  if (weekStatuses.size === 0) return "none";
  if (weekStatuses.size === 1) return [...weekStatuses][0];
  if (weekStatuses.has(AttendanceStatus.InProgress)) return AttendanceStatus.InProgress;
  if (weekStatuses.has(AttendanceStatus.Rejected)) return AttendanceStatus.Rejected;
  return AttendanceStatus.Submitted;
}
function calculateIntervalInMinutes(start, end) {
  const [startH, startMin] = start.split(":").map(Number);
  const [endH, endMin] = end.split(":").map(Number);
  const startTotalMinutes = startH * 60 + startMin;
  const endTotalMinutes = endH * 60 + endMin;
  if (startTotalMinutes < endTotalMinutes) {
    return endTotalMinutes - startTotalMinutes;
  } else {
    const minutesUntilMidnight = 24 * 60 - startTotalMinutes;
    return minutesUntilMidnight + endTotalMinutes;
  }
}
export function getTotalLengthOfDayFromScheduleEntries(dayEntries, attendancePayMultipliersById, isFlexible) {
  if (!dayEntries || dayEntries.length === 0) return 0;
  return dayEntries.reduce((total, dayEntry) => {
    var _a, _b;
    if (!dayEntry || !dayEntry.payMultiplierId) return total;
    const multiplierValue = attendancePayMultipliersById ? (_b = (_a = attendancePayMultipliersById.get(dayEntry.payMultiplierId)) == null ? void 0 : _a.value) != null ? _b : 1 : 1;
    if (isFlexible) {
      if (!dayEntry.totalHours) return total;
      const totalHoursDate = new Date(dayEntry.totalHours);
      const h = totalHoursDate.getHours();
      const min = totalHoursDate.getMinutes();
      total += Math.round((h * 60 + min) * multiplierValue);
      return total;
    }
    if (!dayEntry.from || !dayEntry.to) return total;
    const start = extractTimeOnlyFromDateTimeString(dayEntry.from);
    const end = extractTimeOnlyFromDateTimeString(dayEntry.to);
    total += Math.round(calculateIntervalInMinutes(start, end) * multiplierValue);
    return total;
  }, 0);
}
