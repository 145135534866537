"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import {
  ReportTypePeopleSelection,
  UserEntitiesArray
} from "@v2/feature/reports/reports.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { borders } from "@v2/styles/borders.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const ReportEntities = ({
  onNext,
  setFormData,
  formData
}) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const [searchInput, setSearchInput] = useState("");
  const [filteredEntities, setFilteredEntities] = useState(UserEntitiesArray);
  useEffect(() => {
    if (searchInput.length > 0) {
      setFilteredEntities(UserEntitiesArray.filter((e) => e.name.toLowerCase().includes(searchInput.toLowerCase())));
    } else {
      setFilteredEntities(UserEntitiesArray);
    }
  }, [searchInput]);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.m30 }), children: polyglot.t("ReportEntities.select") }),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            style: { width: "90%" },
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        ),
        formData.type === ReportTypePeopleSelection.ChangeReport ? /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({ width: "100%" }, spacing.mt20), children: /* @__PURE__ */ jsxs(FormGroup, { sx: { flexFlow: "column" }, children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "start",
              value: "all",
              control: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  checked: formData.entities.length === filteredEntities.filter(
                    (u) => u.type.includes(ReportTypePeopleSelection.ChangeReport) && hasScopes(u.scope, scopesContext)
                  ).length,
                  name: "all",
                  onChange: (e, checked) => {
                    const value = filteredEntities.filter(
                      (u) => u.type.includes(ReportTypePeopleSelection.ChangeReport) && hasScopes(u.scope, scopesContext)
                    ).map((f) => f.name);
                    if (checked) {
                      setFormData(__spreadProps(__spreadValues({}, formData), { entities: [...value] }));
                    } else {
                      setFormData(__spreadProps(__spreadValues({}, formData), { entities: [] }));
                    }
                  },
                  label: void 0
                }
              ),
              disabled: false,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), { color: themeColors.Grey }), children: "All entities" }),
              sx: {
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                margin: 0,
                paddingY: spacing.p15,
                borderBottom: borders.background
              }
            },
            "all"
          ),
          filteredEntities.filter((u) => u.type.includes(ReportTypePeopleSelection.ChangeReport)).map((d, idx) => /* @__PURE__ */ jsx(ScopesControl, { scopes: d.scope, context: scopesContext, children: /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "start",
              value: d.name,
              control: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  checked: formData.entities.includes(d.name),
                  name: d.name,
                  onChange: (e, checked) => {
                    const target = e.target;
                    const value = target.name;
                    if (checked) {
                      setFormData((prev) => __spreadProps(__spreadValues({}, prev), { entities: [...formData.entities, value] }));
                    } else {
                      setFormData((prev) => __spreadProps(__spreadValues({}, prev), { entities: prev.entities.filter((s) => s !== value) }));
                    }
                  },
                  label: void 0
                }
              ),
              disabled: false,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: d.name }),
              sx: {
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                margin: 0,
                paddingY: spacing.p15,
                borderBottom: idx < UserEntitiesArray.length - 1 ? borders.background : "none"
              }
            },
            `-${d}-${idx}`
          ) }, `-${idx}-${d}`))
        ] }) }) : /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({ width: "100%" }, spacing.mt20), children: /* @__PURE__ */ jsxs(FormGroup, { sx: { flexFlow: "column" }, children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "start",
              value: "all",
              control: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  checked: formData.entities.length === filteredEntities.filter(
                    (u) => u.type.includes(ReportTypePeopleSelection.CurrentRecords) && hasScopes(u.scope, scopesContext)
                  ).length,
                  name: "all",
                  onChange: (e, checked) => {
                    const value = filteredEntities.filter(
                      (u) => u.type.includes(ReportTypePeopleSelection.CurrentRecords) && hasScopes(u.scope, scopesContext)
                    ).map((f) => f.name);
                    if (checked) {
                      setFormData(__spreadProps(__spreadValues({}, formData), { entities: [...value] }));
                    } else {
                      setFormData(__spreadProps(__spreadValues({}, formData), { entities: [] }));
                    }
                  },
                  label: void 0
                }
              ),
              disabled: false,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), { color: themeColors.Grey }), children: "All entities" }),
              sx: {
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                margin: 0,
                paddingY: spacing.p15,
                borderBottom: borders.background
              }
            },
            "all"
          ),
          filteredEntities.filter((u) => u.type.includes(ReportTypePeopleSelection.CurrentRecords)).map((d, idx) => /* @__PURE__ */ jsx(ScopesControl, { scopes: d.scope, context: scopesContext, children: /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "start",
              value: d.name,
              control: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  checked: formData.entities.includes(d.name),
                  name: d.name,
                  onChange: (e, checked) => {
                    const target = e.target;
                    const value = target.name;
                    if (checked) {
                      setFormData(__spreadProps(__spreadValues({}, formData), { entities: [...formData.entities, value] }));
                    } else {
                      const newArray = formData.entities.filter((s) => s !== value);
                      setFormData(__spreadProps(__spreadValues({}, formData), { entities: newArray }));
                    }
                  },
                  label: void 0
                }
              ),
              disabled: false,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: d.name }),
              sx: {
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                margin: 0,
                paddingY: spacing.p15,
                borderBottom: idx < UserEntitiesArray.length - 1 ? borders.background : "none"
              }
            },
            `-${d}-${idx}`
          ) }, `-${idx}-${d}`))
        ] }) }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            style: { marginTop: spacing.m25 },
            sizeVariant: "large",
            colorVariant: "primary",
            onClick: onNext,
            fullWidth: true,
            disabled: formData.entities.length < 1,
            children: polyglot.t("General.next")
          }
        )
      ] })
    }
  );
};
