"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class SurveyCycleAPI {
  static createSurveyCycle(surveyCycleBody) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-cycle`, surveyCycleBody)).data;
    });
  }
  static duplicateCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-cycle/${cycleId}/duplicate`)).data;
    });
  }
  static deleteCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/survey-cycle/${cycleId}`)).data;
    });
  }
  static updateSurveyCycle(surveyCycleBody) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle`, surveyCycleBody)).data;
    });
  }
  static launchSurveyCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-cycle/${cycleId}/launch`)).data;
    });
  }
  static cloneTemplateInCycle(templateId, cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-cycle/clone-template/${templateId}/${cycleId}`)).data;
    });
  }
  static cloneQuestionsInCycle(questionBankIds, cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-cycle/clone-questions/${cycleId}`, questionBankIds)).data;
    });
  }
  static updateOrderSurveyCycle(updateBody) {
    return __async(this, null, function* () {
      return (yield axios.patch("/apiv2/survey-cycle/order", updateBody)).data;
    });
  }
  static moveQuestionOutsideSection(updateBody) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/remove-from-section`, updateBody)).data;
    });
  }
  static moveQuestionInsideSection(updateBody) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/add-to-section`, updateBody)).data;
    });
  }
  static addParticipants(cycleId, toAddParticipantIds) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/${cycleId}/add-participants`, toAddParticipantIds)).data;
    });
  }
  static updateStateOfRollingSurvey(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/${cycleId}/rolling-state`)).data;
    });
  }
  static updateTimelinelinesSurveyCycle(cycleId, timelineSettings) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/${cycleId}/timeline-settings`, timelineSettings)).data;
    });
  }
  static reopenCycle(cycleId, extendFor) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/${cycleId}/reopen`, { extendFor })).data;
    });
  }
  // deleteParticipants and entries in an ongoing cycle
  static deleteParticipants(cycleId, participantsToDelete) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/${cycleId}/delete-participants`, participantsToDelete)).data;
    });
  }
  static generateRollingSurveyParticipants(selectedFilters, reach) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-cycle/generate/rolling-particpants?reach=${reach}`, { selectedFilters })).data;
    });
  }
  static updateEnrolmentTrigger(cycleId, filter) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-cycle/${cycleId}/enrolment-trigger`, filter)).data;
    });
  }
  static activateSurveyCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-cycle/${cycleId}/activate`)).data;
    });
  }
}
export class SurveyCycleEndpoints {
  static getEventSourceUrlForSurveyCycleLaunch(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-surveyCycleLaunch`
    };
  }
  static getEventSourceUrlForAddParticipantsInCycle(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-addParticipantsInCycle`
    };
  }
  static getSurveyCycleById(cycleId, reach) {
    return {
      url: `/apiv2/survey-cycle/${cycleId}?reach=${reach}`
    };
  }
  static getSurveyCycleResult(cycleId, reach, filterString) {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/result?reach=${reach}&${filterString}`
    };
  }
  static getSurveyCycleInsights(cycleId, reach) {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/insights?reach=${reach}`
    };
  }
  static getSurveyCycleHeatmap(cycleId, filter, reach) {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/heatmap?filter=${filter}&reach=${reach}`
    };
  }
  static getSurveyParticipantsByCycleId(cycleId, reach) {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/participants?reach=${reach}`
    };
  }
  static getSurveyParticipationByCycleId(cycleId) {
    return {
      url: `/apiv2/survey-cycle/participation/${cycleId}`
    };
  }
  static getSurveyLaunchDetailsByCycleId(cycleId) {
    return {
      url: `/apiv2/survey-cycle/${cycleId}/launch`
    };
  }
  static getSurveyCycleStructureById(cycleId) {
    return {
      url: `/apiv2/survey-cycle/structure/${cycleId}`
    };
  }
  static getOngoingCycles(reach, search, filterString) {
    const urlParams = new URLSearchParams(__spreadValues(__spreadValues({
      reach
    }, search && { search }), filterString && { filterString }));
    return {
      url: `/apiv2/survey-cycle/ongoing?${urlParams.toString()}`
    };
  }
}
