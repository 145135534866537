"use strict";
export const TimeRequestsReportColumnsOptions = (polyglot) => {
  return [
    { label: polyglot.t("TimeRequestsReportColumnsOptions.employeeName"), value: "employeeName" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.period"), value: "period" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.length"), value: "length" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.workdayCount"), value: "workdayCount" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.policy"), value: "policy" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.status"), value: "status" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.notes"), value: "notes" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.approvedByIds"), value: "approvedByIds" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.rejectedByIds"), value: "rejectedByIds" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.approvedOnTimestamp"), value: "approvedOnTimestamp" },
    { label: polyglot.t("TimeRequestsReportColumnsOptions.entity"), value: "entity" }
  ];
};
export const TimeBalancesReportColumnsOptions = (polyglot) => {
  return [
    { label: polyglot.t("TimeBalancesReportColumnsOptions.employeeName"), value: "employeeName" },
    { label: polyglot.t("TimeBalancesReportColumnsOptions.currentBalance"), value: "currentBalance" },
    { label: polyglot.t("TimeBalancesReportColumnsOptions.currentBalanceInDays"), value: "currentBalanceInDays" },
    { label: polyglot.t("TimeBalancesReportColumnsOptions.unitsTaken"), value: "unitsTaken" },
    { label: polyglot.t("TimeBalancesReportColumnsOptions.unitsBooked"), value: "unitsBooked" },
    { label: polyglot.t("TimeBalancesReportColumnsOptions.entity"), value: "entity" }
  ];
};
