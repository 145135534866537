"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef } from "react";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { getCountryNameForCode } from "@/v2/feature/payments/payments.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const EntitySelect = (_a) => {
  var _b = _a, {
    label = "Entity",
    name,
    error,
    helperText,
    value,
    onChange,
    countryCodeFilter,
    footer,
    autoSelectSingleEntity
  } = _b, selectProps = __objRest(_b, [
    "label",
    "name",
    "error",
    "helperText",
    "value",
    "onChange",
    "countryCodeFilter",
    "footer",
    "autoSelectSingleEntity"
  ]);
  const hasAutoSelected = useRef(false);
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const entityOptions = useMemo(() => {
    return generalSettings == null ? void 0 : generalSettings.entities.filter((entity) => {
      var _a2;
      return !countryCodeFilter || countryCodeFilter === ((_a2 = entity.address) == null ? void 0 : _a2.countryCode);
    }).map((e) => {
      var _a2;
      return {
        label: e.legalName,
        value: e.id,
        description: getCountryNameForCode((_a2 = e.address) == null ? void 0 : _a2.countryCode)
      };
    }).sort((a, b) => a.label.localeCompare(b.label, void 0, { sensitivity: "base" }));
  }, [generalSettings == null ? void 0 : generalSettings.entities, countryCodeFilter]);
  useEffect(() => {
    if (!hasAutoSelected.current && !!autoSelectSingleEntity && !value && (entityOptions == null ? void 0 : entityOptions.length) === 1) {
      hasAutoSelected.current = true;
      autoSelectSingleEntity(entityOptions[0].value);
    }
  }, [autoSelectSingleEntity, entityOptions, value]);
  return /* @__PURE__ */ jsx(
    SelectComponent,
    __spreadValues({
      name,
      label,
      options: entityOptions != null ? entityOptions : [],
      value: value != null ? value : "",
      error,
      helperText,
      footer,
      onChange: (e) => {
        const selectedEntity = generalSettings == null ? void 0 : generalSettings.entities.find(({ id }) => id === e.target.value);
        if (!selectedEntity) return;
        onChange == null ? void 0 : onChange(e, selectedEntity);
      }
    }, selectProps)
  );
};
