"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import {
  languageOptions,
  UserLocaleSettingsDrawer
} from "@v2/feature/user/features/user-settings/components/user-locale-settings-drawer.component";
import { UserPrivacySettingDrawer } from "@v2/feature/user/features/user-settings/components/user-privacy-setting-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SnackBarMessage } from "@/v2/components/snack-bar-message.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { DisableMFADrawer } from "@/v2/feature/user/features/mfa/disable-mfa-drawer.component";
import { EnableMFADrawer } from "@/v2/feature/user/features/mfa/enable-mfa-drawer.component";
export const UserPersonalSettingsSections = ({
  settings,
  refreshSettings
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMFAChangedMessage, setShowMFAChangedMessage] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editPrivacyMode, setEditPrivacyMode] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    showMFAChangedMessage && /* @__PURE__ */ jsx(
      SnackBarMessage,
      {
        showMessage: {
          message: {
            enabled: polyglot.t("UserMfaSettings.mfaEnabled"),
            disabled: polyglot.t("UserMfaSettings.mfaDisabled")
          }[showMFAChangedMessage],
          show: true,
          type: "success"
        },
        handleClose: () => setShowMFAChangedMessage(null)
      }
    ),
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            title: polyglot.t("UserMfaSettings.mfa"),
            items: [
              {
                type: SectionItemType.TextLine,
                value: polyglot.t("UserMfaSettings.protectMessage")
              },
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(GetCodeByEmail, { setShowMFAChangedMessage })
              }
            ]
          },
          {
            title: polyglot.t("UserLocaleSettings.language"),
            onEdit: () => setEditMode(true),
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t("UserLocaleSettings.languagesSettings"),
                value: (_b = (_a = languageOptions.find((l) => l.value === (settings == null ? void 0 : settings.language))) == null ? void 0 : _a.label) != null ? _b : "NA"
              }
            ]
          },
          {
            title: polyglot.t("UserPrivacySettings.privacysettings"),
            onEdit: () => setEditPrivacyMode(true),
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t("UserPrivacySettings.showmybirthday"),
                value: (settings == null ? void 0 : settings.showBirthday) ? polyglot.t("General.yes") : polyglot.t("General.no")
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      UserLocaleSettingsDrawer,
      {
        isOpen: editMode,
        setIsOpen: setEditMode,
        initialValues: settings,
        refresh: refreshSettings
      }
    ),
    /* @__PURE__ */ jsx(
      UserPrivacySettingDrawer,
      {
        setIsOpen: setEditPrivacyMode,
        isOpen: editPrivacyMode,
        settings,
        refreshSettings
      }
    )
  ] });
};
export const GetCodeByEmail = ({
  setShowMFAChangedMessage
}) => {
  const { polyglot } = usePolyglot();
  const [isMFAEnableOpen, openEnableMFAPage] = useState(false);
  const [isMFADisableOpen, openDisableMFAPage] = useState(false);
  const [activeMFAType, setActiveMFAType] = useState();
  const lastMFAState = useRef();
  const [ssoEnabled, setSSOEnabled] = useState(false);
  const refreshMFAState = useCallback(() => {
    return (() => __async(void 0, null, function* () {
      const { user } = yield AuthAPI.getAuthMe();
      setActiveMFAType(user.mfaType || null);
      if (lastMFAState.current !== void 0 && lastMFAState.current !== user.mfaType) {
        setShowMFAChangedMessage(user.mfaType ? "enabled" : "disabled");
      }
      lastMFAState.current = user.mfaType;
      return user.mfaType;
    }))();
  }, [setShowMFAChangedMessage]);
  const refreshSSOState = useCallback(() => __async(void 0, null, function* () {
    const data = yield AuthAPI.ssoCheckForCompany();
    const ssoEnabledForCompany = data.some((app) => app.enabled);
    setSSOEnabled(ssoEnabledForCompany);
  }), []);
  const configureMFA = useCallback(
    (enable) => {
      refreshMFAState().then((mfaType) => {
        const isCurrentlyEnabled = !!mfaType;
        if (enable === isCurrentlyEnabled) return;
        if (enable) {
          openEnableMFAPage(true);
        } else {
          openDisableMFAPage(true);
        }
      });
    },
    [refreshMFAState]
  );
  useEffect(() => {
    if (isMFAEnableOpen || isMFADisableOpen) {
      setActiveMFAType(void 0);
    }
    if (!isMFAEnableOpen && !isMFADisableOpen) {
      refreshMFAState();
      refreshSSOState();
    }
  }, [isMFADisableOpen, isMFAEnableOpen, refreshMFAState, refreshSSOState]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsxs("div", { style: { display: "inline-block" }, children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
          " ",
          polyglot.t("UserMfaSettings.getCodeByEmail")
        ] }),
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", sx: { mt: "5px" }, children: polyglot.t("UserMfaSettings.askedToLogin") })
      ] }),
      activeMFAType === "email" && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          style: { display: "inline-block" },
          onClick: () => configureMFA(false),
          children: polyglot.t("UserMfaSettings.turnOff")
        }
      ),
      activeMFAType === null && /* @__PURE__ */ jsx(StyledTooltip, { title: ssoEnabled ? polyglot.t("UserMfaSettings.ssoEnabled") : "", placement: "top", children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          type: "button",
          sizeVariant: "small",
          colorVariant: "primary",
          disabled: ssoEnabled,
          onClick: () => configureMFA(true),
          children: polyglot.t("UserMfaSettings.enable")
        }
      ) }) })
    ] }),
    /* @__PURE__ */ jsx(EnableMFADrawer, { isOpen: isMFAEnableOpen, setIsOpen: openEnableMFAPage }),
    /* @__PURE__ */ jsx(DisableMFADrawer, { isOpen: isMFADisableOpen, setIsOpen: openDisableMFAPage })
  ] });
};
