"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { TemplateAPI } from "@/api-client/templates.api";
import { ReactComponent as ContractIcon } from "@/images/onboarding-step-icons/ContractBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { EditContracts } from "@/v2/feature/onboarding/onboarding-items/contracts/edit-contracts.component";
import { TemplatesScopes } from "@/v2/feature/templates/templates.scopes";
import { spacing } from "@/v2/styles/spacing.styles";
export const ContractOnboardingItem = ({
  template,
  checkOnboardingItemScope,
  onDelete,
  setDrawerOpen,
  setDrawerView,
  step
}) => {
  const { polyglot } = usePolyglot();
  const [contractTemplates, setContractTemplates] = useState();
  const canAddTemplate = checkOnboardingItemScope(TemplatesScopes.LIST_CONTRACT_TEMPLATES);
  const templateContractData = useMemo(() => {
    switch (step) {
      case 1:
        return template.template.contracts;
      case 2:
        return template.template.contracts_step2;
      case 3:
        return template.template.contracts_step3;
      case 4:
        return template.template.contracts_step4;
      case 5:
        return template.template.contracts_step5;
      default:
        return void 0;
    }
  }, [
    template.template,
    template.template.contracts,
    template.template.contracts_step2,
    template.template.contracts_step3,
    template.template.contracts_step4,
    template.template.contracts_step5,
    step
  ]);
  const updateTemplateContractData = (updatedContracts) => {
    if (step === 1) template.template.contracts = updatedContracts;
    if (step === 2) template.template.contracts_step2 = updatedContracts;
    if (step === 3) template.template.contracts_step3 = updatedContracts;
    if (step === 4) template.template.contracts_step4 = updatedContracts;
    if (step === 5) template.template.contracts_step5 = updatedContracts;
  };
  useEffect(() => {
    if (!templateContractData) return;
    if (!canAddTemplate) return;
    TemplateAPI.getTemplates().then(setContractTemplates);
  }, [canAddTemplate, template.template.contracts, templateContractData]);
  const [name, templateMissing] = useMemo(() => {
    var _a;
    const { templateId, templateName } = templateContractData != null ? templateContractData : {};
    if (!templateId) return [void 0, void 0];
    if (!contractTemplates) return [templateName, false];
    const selectedTemplate = contractTemplates.find((t) => t.id === templateId);
    return [(_a = selectedTemplate == null ? void 0 : selectedTemplate.name) != null ? _a : templateName, !selectedTemplate];
  }, [contractTemplates, templateContractData]);
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: ContractIcon,
        title: polyglot.t("ContractOnboardingItem.title"),
        description: polyglot.t("ContractOnboardingItem.desc")
      },
      chips: [name != null ? name : ""],
      addButtonIcon: templateContractData ? "edit" : "plus",
      onAddClick: canAddTemplate && (() => {
        setDrawerView(
          /* @__PURE__ */ jsx(
            EditContracts,
            {
              contract: templateContractData,
              onSave: (contracts) => {
                updateTemplateContractData(contracts);
                setDrawerOpen(false);
              }
            }
          )
        );
        setDrawerOpen(true);
      }),
      onDeleteClick: step !== 1 ? template.template[`contracts_step${step}`] && onDelete : step === 1 ? template.template.contracts && onDelete : void 0,
      sx: {
        py: spacing.py20
      },
      warning: templateMissing ? polyglot.t("ContractOnboardingItem.notFound") : void 0
    },
    step
  );
};
