"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconButtonSx } from "@v2/styles/icon-button.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { iconSize } from "@v2/styles/table.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CompanyAPI } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
export const TranslationDrawer = ({ isOpen, setIsOpen, translation, refresh, onClose }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(TranslationDrawerContent, { setIsOpen, translation, refresh })
  }
) });
const TranslationDrawerContent = ({ setIsOpen, translation, refresh }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      en: (_a = translation == null ? void 0 : translation.en) != null ? _a : "",
      it: (_b = translation == null ? void 0 : translation.it) != null ? _b : "",
      ar: (_c = translation == null ? void 0 : translation.ar) != null ? _c : "",
      de: (_d = translation == null ? void 0 : translation.de) != null ? _d : "",
      fr: (_e = translation == null ? void 0 : translation.fr) != null ? _e : "",
      es: (_f = translation == null ? void 0 : translation.es) != null ? _f : "",
      pt: (_g = translation == null ? void 0 : translation.pt) != null ? _g : ""
    },
    validationSchema: Yup.object({
      en: Yup.string().nullable().notRequired(),
      it: Yup.string().nullable().notRequired(),
      ar: Yup.string().nullable().notRequired(),
      de: Yup.string().nullable().notRequired(),
      fr: Yup.string().nullable().notRequired(),
      es: Yup.string().nullable().notRequired(),
      pt: Yup.string().nullable().notRequired()
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      const providedValues = Object.keys(values).filter((key) => !!values[key]);
      if (providedValues.length < 2) {
        showMessage("At least 2 values should be provided.", "error");
        return;
      }
      const data = {
        en: values.en ? values.en : null,
        it: values.it ? values.it : null,
        ar: values.ar ? values.ar : null,
        es: values.es ? values.es : null,
        pt: values.pt ? values.pt : null,
        de: values.de ? values.de : null,
        fr: values.fr ? values.fr : null
      };
      try {
        setLoading(true);
        if (translation == null ? void 0 : translation.id) yield CompanyAPI.updateCompanyTranslation(translation.id, data);
        else yield CompanyAPI.createCompanyTranslation(data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const deleteTranslation = useCallback(() => __async(void 0, null, function* () {
    if (!(translation == null ? void 0 : translation.id)) return;
    try {
      yield CompanyAPI.deleteCompanyTranslation(translation.id);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
  }), [translation == null ? void 0 : translation.id, showMessage, setIsOpen, refresh]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("SettingsRouterPageConfig.translations") }),
    (translation == null ? void 0 : translation.id) && /* @__PURE__ */ jsx(IconButton, { title: polyglot.t("General.delete"), onClick: deleteTranslation, sx: iconButtonSx, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "en",
        label: "EN",
        value: formik.values.en,
        onChange: formik.handleChange,
        error: formik.touched.en && !!formik.errors.en,
        helperText: (_h = formik.touched.en && formik.errors.en) != null ? _h : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "ar",
        label: "AR",
        value: formik.values.ar,
        onChange: formik.handleChange,
        error: formik.touched.ar && !!formik.errors.ar,
        helperText: (_i = formik.touched.ar && formik.errors.ar) != null ? _i : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "de",
        label: "DE",
        value: formik.values.de,
        onChange: formik.handleChange,
        error: formik.touched.de && !!formik.errors.de,
        helperText: (_j = formik.touched.de && formik.errors.de) != null ? _j : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "es",
        label: "ES",
        value: formik.values.es,
        onChange: formik.handleChange,
        error: formik.touched.es && !!formik.errors.es,
        helperText: (_k = formik.touched.es && formik.errors.es) != null ? _k : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "fr",
        label: "FR",
        value: formik.values.fr,
        onChange: formik.handleChange,
        error: formik.touched.fr && !!formik.errors.fr,
        helperText: (_l = formik.touched.fr && formik.errors.fr) != null ? _l : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "it",
        label: "IT",
        value: formik.values.it,
        onChange: formik.handleChange,
        error: formik.touched.it && !!formik.errors.it,
        helperText: (_m = formik.touched.it && formik.errors.it) != null ? _m : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "pt",
        label: "PT",
        value: formik.values.pt,
        onChange: formik.handleChange,
        error: formik.touched.pt && !!formik.errors.pt,
        helperText: (_n = formik.touched.pt && formik.errors.pt) != null ? _n : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        colorVariant: "primary",
        sizeVariant: "medium",
        fullWidth: true
      }
    ) })
  ] }) });
};
