"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ManagerPermissionGroupOptionsEditDrawer } from "@v2/feature/permission-group/components/manager-permission-group-options-edit-drawer.component";
import { translateManagerPermissionGroupReportsLevel } from "@v2/infrastructure/i18n/translate.util";
import { DEFAULT_GROUPS } from "../permission-group.util";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { PermissionGroupEditDrawerPage } from "@/v2/feature/permission-group/components/permission-group-edit-drawer.component";
import {
  DefaultManagerPermissionLevel,
  PERMISSION_GROUP_EDIT_DRAWER_MODES
} from "@/v2/feature/permission-group/permission-group.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const EditPermissionGroupGeneralPage = ({ permissionGroup, refresh, setLoading }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [isOptionsEditOpen, setIsOptionsEditOpen] = useState(false);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup == null ? void 0 : permissionGroup.name) : false;
  const openEditDrawer = () => {
    setEditDrawerOpen(true);
  };
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyGeneralSection.general"),
      onEdit: !isDefault ? () => openEditDrawer() : void 0,
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("PermissionGroupListingTable.name"),
                    value: permissionGroup.name
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("PermissionGroupListingTable.description"),
                    value: permissionGroup.description
                  }
                ]
              },
              {
                title: polyglot.t("ManagerPermissionGroup.options"),
                onEdit: () => {
                  setIsOptionsEditOpen(true);
                },
                hidden: permissionGroup.name !== "Manager",
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("ManagerPermissionGroup.levelsOfReports"),
                    value: translateManagerPermissionGroupReportsLevel(
                      (_a = permissionGroup.options.reportsLevels) != null ? _a : DefaultManagerPermissionLevel,
                      polyglot
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          ManagerPermissionGroupOptionsEditDrawer,
          {
            isOpen: isOptionsEditOpen,
            setIsOpen: setIsOptionsEditOpen,
            permissionGroup,
            refreshPermissionGroup: refresh
          }
        ),
        /* @__PURE__ */ jsx(DrawerModal, { isOpen: editDrawerOpen, setIsOpen: setEditDrawerOpen, children: /* @__PURE__ */ jsx(
          PermissionGroupEditDrawerPage,
          {
            refreshPermissionGroup: refresh,
            selectedPermission: permissionGroup,
            permissionGroupMembers: (_b = permissionGroup == null ? void 0 : permissionGroup.members) != null ? _b : [],
            closePage: () => __async(void 0, null, function* () {
              setEditDrawerOpen(false);
              yield refresh();
            }),
            mode: PERMISSION_GROUP_EDIT_DRAWER_MODES.general,
            setLoading,
            permissionGroupName: (_c = permissionGroup == null ? void 0 : permissionGroup.name) != null ? _c : ""
          }
        ) })
      ]
    }
  );
};
