"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { ProfileTab } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { ReactComponent as Close } from "@/images/app-icons/Close.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { StepperComponent } from "@/v2/components/stepper.component";
import { CustomProfileFormAPI } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { UserBankAccountAPI } from "@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import { UserAPI } from "@/v2/feature/user/user.api";
import { UserAboutSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-about.section";
import { UserAddressSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-address.section";
import { UserBankAccountSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-bank-account.section";
import { UserBasicInfoSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-basic-info.section";
import { UserCustomSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-custom.section";
import { UserEmergencyContactSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-emergency-contact.section";
import { UserPersonalInfoSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-personal-info.section";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { caseInsensitiveSort } from "@/v2/util/array.util";
export const OnboardingStepperDefaultSteps = [
  "Basic info",
  "Personal",
  "Bank",
  "Address",
  "Emergency",
  "About"
];
export const iconSize = { width: 14, height: 14 };
const checkScopesForOnboardingFlow = (currentUser) => {
  const { userId } = currentUser;
  const hasBankingScope = checkScopes(currentUser, ["user.bankAccount"], { userId });
  const hasAddressScope = checkScopes(currentUser, ["user.address"], { userId });
  const hasEmergencyContactScope = checkScopes(currentUser, ["user.emergencyContact"], { userId });
  const hasAboutScope = checkScopes(currentUser, ["user.about"], { userId });
  return { hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope };
};
const removeStepsFromArray = (steps, stepsToRemove) => {
  return steps.filter((step) => !stepsToRemove.has(step));
};
const getStepsToRemove = (hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope) => {
  const stepsToRemove = /* @__PURE__ */ new Set();
  if (!hasBankingScope) {
    stepsToRemove.add("Bank");
  }
  if (!hasAddressScope) {
    stepsToRemove.add("Address");
  }
  if (!hasEmergencyContactScope) {
    stepsToRemove.add("Emergency");
  }
  if (!hasAboutScope) {
    stepsToRemove.add("About");
  }
  return stepsToRemove;
};
export const OnboardingBasicFlow = (_) => {
  const userId = useUserIdParam();
  const [globalState] = useContext(GlobalContext);
  const [activeStep, setActiveStep] = useState("Basic info");
  const [onboardingState, setOnboardingState] = useState();
  const { hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope } = checkScopesForOnboardingFlow(
    globalState.user
  );
  const routerHistory = useHistory();
  const OnboardingStepperSteps = useMemo(() => {
    var _a;
    let initialSteps = [
      ...OnboardingStepperDefaultSteps,
      ...(_a = onboardingState == null ? void 0 : onboardingState.customSections.map(({ form }) => form.formName)) != null ? _a : []
    ];
    const stepsToRemove = getStepsToRemove(hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope);
    return removeStepsFromArray(initialSteps, stepsToRemove);
  }, [hasAboutScope, hasAddressScope, hasBankingScope, hasEmergencyContactScope, onboardingState == null ? void 0 : onboardingState.customSections]);
  const isFirstStep = useCallback(() => {
    return OnboardingStepperSteps.indexOf(activeStep) === 0;
  }, [activeStep, OnboardingStepperSteps]);
  const handleNext = useCallback(() => {
    const currentStepIdx = OnboardingStepperSteps.indexOf(activeStep);
    if (currentStepIdx >= OnboardingStepperSteps.length - 1) {
      routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
      return;
    }
    setActiveStep(OnboardingStepperSteps[currentStepIdx + 1]);
  }, [activeStep, routerHistory, userId, OnboardingStepperSteps]);
  const handleBack = useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIdx = OnboardingStepperSteps.indexOf(currentStep);
      return OnboardingStepperSteps[Math.max(currentStepIdx - 1, 0)];
    });
  }, [OnboardingStepperSteps]);
  const getOnboardingInfo = useCallback(() => __async(void 0, null, function* () {
    if (!Number.isInteger(userId)) {
      return;
    }
    const [
      basicInfo,
      personalInfo,
      bankAccount,
      homeAddress,
      emergencyContact,
      about,
      forms,
      formValues
    ] = yield Promise.all([
      UserAPI.getUserBasicInfo(userId),
      UserAPI.getUserPersonalInfo(userId),
      hasBankingScope ? UserBankAccountAPI.findCurrentByUserId(userId) : null,
      hasAddressScope ? UserAddressAPI.findCurrentByUserId(userId) : null,
      hasEmergencyContactScope ? UserAPI.getUserEmergencyContact(userId) : null,
      UserAPI.getUserAbout(userId),
      CustomProfileFormAPI.listForms(),
      CustomProfileFormAPI.getFormValues(userId)
    ]);
    const customFormsWithRequiredFields = forms.filter((f) => {
      var _a;
      return !f.isDefault && ((_a = f.fields) == null ? void 0 : _a.length) && f.fields.some((field) => field.isRequired);
    }).sort((a, b) => caseInsensitiveSort(a, b, (item) => item.formName));
    const customSections = [];
    for (const customForm of customFormsWithRequiredFields) {
      customSections.push({
        form: customForm,
        values: formValues.filter((value) => value.formId === customForm.formId)
      });
    }
    setOnboardingState({
      basicInfo,
      personalInfo,
      bankAccount,
      homeAddress,
      emergencyContact,
      about,
      customSections,
      forms
    });
  }), [hasAddressScope, hasBankingScope, hasEmergencyContactScope, userId]);
  useEffect(() => {
    getOnboardingInfo();
  }, [getOnboardingInfo]);
  const stepRenderFunctions = useMemo(() => {
    var _a;
    if (!onboardingState) {
      return {};
    }
    const defaultSection = {
      "Basic info": () => {
        var _a2;
        return /* @__PURE__ */ jsx(
          UserBasicInfoSection,
          {
            basicInfo: onboardingState.basicInfo,
            form: (_a2 = onboardingState.forms) == null ? void 0 : _a2.find((f) => f.formTab === ProfileTab.Personal && f.formName === "basic"),
            onNext: (basicInfo) => {
              var _a3;
              onboardingState.basicInfo = __spreadValues(__spreadValues({}, (_a3 = onboardingState.basicInfo) != null ? _a3 : {}), basicInfo);
              handleNext();
            },
            sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
            userId
          }
        );
      },
      Personal: () => {
        var _a2;
        return /* @__PURE__ */ jsx(
          UserPersonalInfoSection,
          {
            form: (_a2 = onboardingState.forms) == null ? void 0 : _a2.find((f) => f.formTab === ProfileTab.Personal && f.formName === "details"),
            onNext: (personalInfo) => {
              var _a3;
              onboardingState.personalInfo = __spreadValues(__spreadValues({}, (_a3 = onboardingState.personalInfo) != null ? _a3 : {}), personalInfo);
              handleNext();
            },
            sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
            userId,
            personalInfo: onboardingState.personalInfo
          }
        );
      },
      Bank: () => {
        var _a2;
        return /* @__PURE__ */ jsx(
          UserBankAccountSection,
          {
            form: (_a2 = onboardingState.forms) == null ? void 0 : _a2.find(
              (f) => f.formTab === ProfileTab.Compensation && f.formName === "bank-account"
            ),
            defaultHolderName: `${onboardingState.basicInfo.firstName} ${onboardingState.basicInfo.lastName}`,
            onNext: (bankAccount) => {
              onboardingState.bankAccount = bankAccount;
              handleNext();
            },
            sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
            userId,
            bankAccountValues: onboardingState.bankAccount
          }
        );
      },
      Address: () => {
        var _a2;
        return /* @__PURE__ */ jsx(
          UserAddressSection,
          {
            form: (_a2 = onboardingState.forms) == null ? void 0 : _a2.find((f) => f.formTab === ProfileTab.Contact && f.formName === "address"),
            onNext: (address) => {
              onboardingState.homeAddress = address;
              handleNext();
            },
            sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
            userId,
            values: onboardingState.homeAddress
          }
        );
      },
      Emergency: () => {
        var _a2;
        return /* @__PURE__ */ jsx(
          UserEmergencyContactSection,
          {
            form: (_a2 = onboardingState.forms) == null ? void 0 : _a2.find((f) => f.formTab === ProfileTab.Contact && f.formName === "emergency"),
            onNext: (contact) => {
              var _a3;
              onboardingState.emergencyContact = __spreadValues(__spreadValues({}, (_a3 = onboardingState == null ? void 0 : onboardingState.emergencyContact) != null ? _a3 : {}), contact);
              handleNext();
            },
            sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
            userId,
            emergencyValues: onboardingState.emergencyContact
          }
        );
      },
      About: () => {
        var _a2;
        return /* @__PURE__ */ jsx(
          UserAboutSection,
          {
            form: (_a2 = onboardingState.forms) == null ? void 0 : _a2.find((f) => f.formTab === ProfileTab.Personal && f.formName === "about"),
            onNext: (about) => {
              var _a3;
              onboardingState.about = __spreadValues(__spreadValues({}, (_a3 = onboardingState.about) != null ? _a3 : {}), about);
              handleNext();
            },
            sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
            userId,
            aboutValues: onboardingState.about
          }
        );
      }
    };
    let customSectionsSteps = onboardingState.customSections.reduce((acc, section) => {
      acc[section.form.formName] = () => /* @__PURE__ */ jsx(
        UserCustomSection,
        {
          onNext: (values) => {
            section.values = values;
            handleNext();
          },
          form: section.form,
          fields: section.values,
          userId,
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 }
        },
        section.form.formName
      );
      return acc;
    }, {});
    return (_a = __spreadValues(__spreadValues({}, defaultSection), customSectionsSteps)) != null ? _a : {};
  }, [handleNext, onboardingState, userId]);
  const renderStep = (step) => {
    const renderFunction = stepRenderFunctions[step];
    return renderFunction ? renderFunction() : null;
  };
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30 }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", width: "70%", mx: "auto" }, children: [
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", left: spacing.m50 }),
          onClick: handleBack,
          disabled: isFirstStep(),
          children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
        }
      ),
      /* @__PURE__ */ jsx(StepperComponent, { sx: { flex: 1 }, steps: OnboardingStepperSteps, activeStep }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", right: spacing.m50 }),
          onClick: () => routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId })),
          children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden auto", pb: spacing.p30 }, children: renderStep(activeStep) })
  ] });
};
