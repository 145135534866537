"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { PayrollOtherSettingsSection } from "@v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-other-settings-section.component";
import { PayrollDeleteSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-delete-section.component";
import { PayrollGeneralSettingsSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-general-settings-section.component";
import { PayrollScheduleSettingsSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-schedule-settings-section.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollCompanySettingsGeneral = ({ payroll, refreshPayroll }) => {
  return /* @__PURE__ */ jsxs(Stack, { sx: { maxWidth: "650px", gap: spacing.g40, my: spacing.my20 }, children: [
    /* @__PURE__ */ jsx(PayrollGeneralSettingsSection, { payroll, refreshPayroll }),
    /* @__PURE__ */ jsx(PayrollScheduleSettingsSection, { payroll, refreshPayroll }),
    /* @__PURE__ */ jsx(PayrollOtherSettingsSection, { payroll, refreshPayroll }),
    /* @__PURE__ */ jsx(PayrollDeleteSection, { payroll, refreshPayroll })
  ] });
};
