"use strict";
import { jsx } from "react/jsx-runtime";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { NewExpensePage } from "@v2/feature/payments/pages/new-expense.page";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { LocalDate } from "@v2/util/local-date";
import { useLocation, useParams } from "react-router-dom";
export const UserBenefitExpensePage = ({ userId, userBenefits }) => {
  const params = useParams();
  const customBenefitId = Number(params.id);
  const userBenefit = userBenefits.find((b) => b.customBenefitId === customBenefitId);
  const { data: remainingAllowance } = useApiClient(
    userBenefit ? CustomBenefitEndpoints.getUserCustomBenefitRemainingAllowance(
      userId,
      userBenefit.customBenefitId,
      userBenefit.id,
      new LocalDate().toDateString()
    ) : null,
    { suspense: false }
  );
  const location = useLocation();
  const fromMePage = location.pathname.includes("/me/");
  return /* @__PURE__ */ jsx(
    NewExpensePage,
    {
      userBenefit,
      preselectedEmployee: userId,
      fromUserProfile: !fromMePage,
      reach: "user",
      maxAmount: remainingAllowance
    }
  );
};
