"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  CustomBenefitCategory,
  CustomBenefitType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateCustomBenefitCategory } from "@v2/infrastructure/i18n/translate.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_BENEFITS_DETAILS_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
export const InsuranceSetupExploreStep = ({ name, onNext }) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const createExistingInsurance = useCallback(() => __async(void 0, null, function* () {
    try {
      const data = {
        type: CustomBenefitType.Recurring,
        category: CustomBenefitCategory.Health,
        name,
        description: null,
        includedUserIds: [],
        benefitLink: null
      };
      const id = yield CustomBenefitAPI.createCustomBenefit(data);
      history.push(
        generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
          id,
          category: CustomBenefitCategory.Health,
          productType: "custom"
        })
      );
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [polyglot, name, showMessage, history]);
  return /* @__PURE__ */ jsxs(Box, { sx: { margin: "auto", mt: spacing.mt10, width: "400px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot) }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.mt20, mb: spacing.mb30, color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.businessHealthInsuranceDesc") }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.mt40, display: "flex", flexDirection: "column" }), children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", onClick: onNext, fullWidth: true, children: polyglot.t("BenefitModule.newInsuranceWithZelt") }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            return yield createExistingInsurance();
          }),
          fullWidth: true,
          children: polyglot.t("BenefitModule.addExisting")
        }
      )
    ] })
  ] });
};
