"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { NotEnrolledInPension } from "@/v2/feature/payroll/features/payroll-uk/components/not-enrolled-in-pension.component";
import { EditEmployerCostPage } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-employer-cost-page.component";
import { EditPayrunEntryDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-payrun-entry-drawer.component";
import {
  CurrencyWithDiff,
  ValueWithDiff
} from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component";
import { sum } from "@/v2/util/array.util";
export const PayrunEmployerCostTable = ({
  entries,
  previousEntries,
  payCodes,
  payrunClosed,
  saveIncomeUpdates,
  sx
}) => {
  const [editEmployerCost, setEditEmployerCost] = useState();
  const [empCostTotals, prevEmpCostTotals] = useMemo(() => {
    const calculateTotals = (entries2) => ({
      income: sum(entries2, (e) => {
        var _a;
        return (_a = e.income) != null ? _a : 0;
      }),
      employerNi: sum(entries2, (e) => {
        var _a;
        return (_a = e.employerNi) != null ? _a : 0;
      }),
      employerPension: sum(entries2, (e) => {
        var _a;
        return (_a = e.employerPension) != null ? _a : 0;
      }),
      employerCost: sum(entries2, (e) => {
        var _a;
        return (_a = e.employerCost) != null ? _a : 0;
      })
    });
    return [
      calculateTotals(entries),
      previousEntries.length ? calculateTotals(previousEntries) : {}
    ];
  }, [entries, previousEntries]);
  const columnData = useMemo(() => {
    const previousById = new Map(previousEntries.map((entry) => [entry.employee.id, entry]));
    return [
      {
        id: "employee",
        header: () => "Employee",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (entry) => entry.employee.name),
        cell: (c) => /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId }),
        footer: () => "Total",
        size: 100
      },
      {
        id: "income",
        header: () => "Income",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => {
          var _a;
          return (_a = entry.income) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (entry) => {
              var _a;
              return (_a = entry.income) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: empCostTotals.income, previousValue: prevEmpCostTotals.income }),
        size: 60
      },
      {
        id: "ni",
        header: () => "National Insurance",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => {
          var _a;
          return (_a = entry.employerNi) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (entry) => {
              var _a;
              return (_a = entry.employerNi) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: empCostTotals.employerNi, previousValue: prevEmpCostTotals.employerNi }),
        size: 80
      },
      {
        id: "pension",
        header: () => "Pension",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => {
          var _a;
          return (_a = entry.employerPension) != null ? _a : 0;
        }),
        cell: (c) => "employerPensionContribution" in c.getValue().totals ? /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (entry) => {
              var _a;
              return (_a = entry.employerPension) != null ? _a : 0;
            }
          }
        ) : /* @__PURE__ */ jsx(NotEnrolledInPension, { userId: c.row.original.userId }),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: empCostTotals.employerPension,
            previousValue: prevEmpCostTotals.employerPension
          }
        ),
        size: 80
      },
      {
        id: "total-employer-cost",
        header: () => "Total employer cost",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => {
          var _a;
          return (_a = entry.employerCost) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: previousById.get(c.getValue().employee.id),
            getValue: (entry) => {
              var _a;
              return (_a = entry.employerCost) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: empCostTotals.employerCost, previousValue: prevEmpCostTotals.employerCost }),
        size: 100
      }
    ];
  }, [empCostTotals, previousEntries, prevEmpCostTotals]);
  return /* @__PURE__ */ jsxs(Box, { sx, children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: entries,
        columnData,
        hidePagination: true,
        showFooter: true,
        rowClick: (row) => setEditEmployerCost(row.original)
      }
    ),
    /* @__PURE__ */ jsx(
      EditPayrunEntryDrawer,
      {
        userId: editEmployerCost == null ? void 0 : editEmployerCost.userId,
        payrunEntry: editEmployerCost,
        payCodes,
        payrunClosed,
        onClose: () => setEditEmployerCost(void 0),
        saveIncomeUpdates,
        Content: EditEmployerCostPage
      }
    )
  ] });
};
