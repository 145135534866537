"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { MultiUserAvatar } from "@v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@v2/components/typography/typography.component";
import { BenefitPaymentActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/benefit-payment-action-item.component";
import { DeviceTransitActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/device-transit-action-item.component";
import { ExpenseRequestActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/expense-request-action-item.component";
import { InvoiceRequestActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/invoice-request-action-item.component";
import { PendingPHCancellationActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/pending-ph-cancellation-action-item.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateDomainTypes } from "@v2/infrastructure/i18n/translate.util";
import { useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { nestErrorMessage } from "@/lib/errors";
import { REQUESTS_CHOOSE_REQUEST_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortDate } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DashboardEndpoints } from "@/v2/feature/dashboard/dashboard.api";
import { getDetailByDomain } from "@/v2/feature/dashboard/dashboard.util";
import { AppActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/app-action-item.component";
import { AttendanceActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/attendance-action-item.component";
import { ContractActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/contract-action-item.component";
import { DocumentActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/document-action-item.component";
import { RequestFormActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/request-form-action-item.component";
import { ReviewActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/review-action-item.component";
import { ShiftActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/shift-action-item.component";
import { SurveyActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/survey-action-item.component";
import { TaskActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/task-action-item.component";
import { TimeActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-action-item.component";
import {
  DomainTypes,
  DomainTypesArray,
  KindTypes
} from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { ActionItemsTabFilter } from "@/v2/feature/task/task.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserAPI } from "@/v2/feature/user/user.api";
import { filterStringToObject } from "@/v2/feature/user/user.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserActionList = () => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [globalState, dispatch] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const [actionStatus, setActionStatus] = useState(
    (_d = (_c = (_b = (_a = currentUser == null ? void 0 : currentUser.features) == null ? void 0 : _a.user) == null ? void 0 : _b.actionItems) == null ? void 0 : _c.tabFilter) != null ? _d : ActionItemsTabFilter.InSevenDays
  );
  const TabFilter = useMemo(
    () => [
      { name: polyglot.t("TasksListPage.inSevenDays"), value: ActionItemsTabFilter.InSevenDays },
      { name: polyglot.t("TasksListPage.inThirtyDays"), value: ActionItemsTabFilter.InThirtyDays },
      { name: polyglot.t("TasksListPage.pending"), value: ActionItemsTabFilter.Pending },
      { name: polyglot.t("TasksListPage.approved"), value: ActionItemsTabFilter.Approved }
    ],
    [polyglot]
  );
  const { data: actionItems, isLoading: loading, mutate: refreshActionItems } = useApiClient(
    DashboardEndpoints.getAllActionItems(actionStatus),
    {
      suspense: false
    }
  );
  const { data: requestForms } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), {
    suspense: false
  });
  const refreshItems = useCallback(() => __async(void 0, null, function* () {
    if (refreshActionItems) yield refreshActionItems();
  }), [refreshActionItems]);
  const routerHistory = useHistory();
  const { getCachedUserById, loaded } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState(void 0);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const updateUserFeaturesActionItemsFilter = useCallback(
    (newFilterValue) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "user",
          "actionItems",
          "tabFilter",
          newFilterValue
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        console.error(`Failed to save user preferences for action items tab filter:  ${nestErrorMessage(error)}`);
      }
    }),
    [dispatch]
  );
  const columns = useMemo(
    () => [
      {
        header: () => polyglot.t("UserActionList.employeeName"),
        accessorFn: (row) => row,
        id: "employeeName",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.userId ? /* @__PURE__ */ jsx(UserCell, { userId: original.userId }) : /* @__PURE__ */ jsx(EmptyCell, {}) }),
        maxSize: 120,
        minSize: 100
      },
      {
        id: "type",
        header: () => polyglot.t("UserActionList.type"),
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 80,
        minSize: 50,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: (original == null ? void 0 : original.type) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: translateDomainTypes(original.type, polyglot) }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "detail",
        header: () => polyglot.t("UserActionList.detail"),
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 200,
        minSize: 160,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getDetailByDomain(original, getCachedUserById, currentUser, polyglot) }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "updatedAt",
        header: () => polyglot.t("UserActionList.dueDate"),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.updatedAt),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: "dueDate" in original && (original == null ? void 0 : original.dueDate) ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getDateString(original.dueDate) }) : original.updatedAt ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getDateString(original.updatedAt) }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "approver",
        header: () => polyglot.t("UserActionList.approver"),
        accessorFn: (row) => row,
        maxSize: 120,
        minSize: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.approverSteps ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          MultiUserAvatar,
          {
            userIds: original.approverSteps.flatMap((approverStep) => {
              var _a2;
              return (_a2 = approverStep.approversIds) != null ? _a2 : [];
            }).filter(
              (userId) => !original.approvedByIds.includes(userId) && !original.rejectedByIds.includes(userId)
            ),
            showLimit: 5
          }
        ) }) : original.approverIds && original.approverIds.length > 1 ? /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: original.approverIds, showLimit: 5 }) : original.approverIds && original.approverIds.length === 1 ? /* @__PURE__ */ jsx(UserCell, { userId: original.approverIds[0] }) : original.approverId ? /* @__PURE__ */ jsx(UserCell, { userId: original.approverId }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      }
    ],
    [currentUser, getCachedUserById, polyglot]
  );
  const handleRowClick = useCallback(
    (row) => __async(void 0, null, function* () {
      setSelectedRow(row.original);
    }),
    [setSelectedRow]
  );
  const filteredActionData = useMemo(() => {
    let filteredData = actionItems;
    if (searchInput) {
      filteredData = actionItems == null ? void 0 : actionItems.filter((a) => {
        const titleString = getDetailByDomain(a, getCachedUserById, currentUser, polyglot);
        return titleString ? titleString.toLowerCase().includes(searchInput.toLowerCase()) : titleString;
      });
    }
    if (filterString && filteredData) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case "type": {
              filteredData = filteredData.filter((d) => filterOptions[key].includes(d.type));
              break;
            }
            default:
              break;
          }
        }
      }
    }
    return filteredData;
  }, [searchInput, actionItems, filterString, currentUser, getCachedUserById, polyglot]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserActionList.actions") }),
        showAction: !!(requestForms == null ? void 0 : requestForms.length),
        actions: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => routerHistory.push(REQUESTS_CHOOSE_REQUEST_ROUTE),
              children: polyglot.t("UserActionList.new")
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: __spreadProps(__spreadValues({}, spacing.pt20), { overflow: "hidden" }), children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            alignItems: "center",
            gap: spacing.g5
          },
          children: [
            /* @__PURE__ */ jsx(
              TabFilterButtons,
              {
                filters: TabFilter,
                setFilterValue: (filterValue) => {
                  setActionStatus(filterValue);
                  updateUserFeaturesActionItemsFilter(filterValue);
                },
                filterValue: actionStatus
              }
            ),
            /* @__PURE__ */ jsx(
              CategoryFilters,
              {
                filterTypes: {
                  type: DomainTypesArray.map((d) => {
                    return { value: d, label: translateDomainTypes(d, polyglot) };
                  })
                },
                setFilterString,
                filterString
              }
            ),
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                query: searchInput,
                handleChange: (e) => {
                  setSearchInput(e.target.value);
                }
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredActionData ? [...filteredActionData] : [],
          columnData: columns,
          initialSort: [{ id: "updatedAt", desc: true }],
          loading: loading || !loaded,
          rowClick: handleRowClick,
          stickyHeader: true
        }
      ) }),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Tasks && /* @__PURE__ */ jsx(
        TaskActionItem,
        {
          taskActionRow: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Expenses && /* @__PURE__ */ jsx(
        ExpenseRequestActionItem,
        {
          expenseRequestTodo: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Invoices && /* @__PURE__ */ jsx(
        InvoiceRequestActionItem,
        {
          invoiceRequestTodo: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.CustomBenefitPayments && /* @__PURE__ */ jsx(
        BenefitPaymentActionItem,
        {
          pendingBenefitPaymentTodo: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Time && selectedRow.kind === KindTypes.PendingRequest && /* @__PURE__ */ jsx(
        TimeActionItem,
        {
          timeActionRow: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Time && selectedRow.kind === KindTypes.PendingPHCancellation && /* @__PURE__ */ jsx(
        PendingPHCancellationActionItem,
        {
          timeActionRow: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Devices && selectedRow.kind === KindTypes.DeviceTransit && /* @__PURE__ */ jsx(
        DeviceTransitActionItem,
        {
          deviceTransitTodo: selectedRow,
          afterClose: () => setSelectedRow(void 0),
          refresh: refreshItems
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Documents && /* @__PURE__ */ jsx(
        DocumentActionItem,
        {
          documentActionRow: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Contracts && /* @__PURE__ */ jsx(
        ContractActionItem,
        {
          selectedRow,
          afterClose: () => setSelectedRow(void 0),
          isModalOpen: true
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Reviews && /* @__PURE__ */ jsx(ReviewActionItem, { reviewActionRow: selectedRow, afterClose: () => setSelectedRow(void 0) }),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Surveys && /* @__PURE__ */ jsx(SurveyActionItem, { surveyActionRow: selectedRow, afterClose: () => setSelectedRow(void 0) }),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Shifts && /* @__PURE__ */ jsx(
        ShiftActionItem,
        {
          shiftActionRow: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Attendance && /* @__PURE__ */ jsx(
        AttendanceActionItem,
        {
          attendanceActionRow: selectedRow,
          refresh: refreshItems,
          afterClose: () => {
            setSelectedRow(void 0);
          }
        }
      ),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Apps && /* @__PURE__ */ jsx(AppActionItem, { appActionRow: selectedRow, afterClose: () => setSelectedRow(void 0) }),
      (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Requests && /* @__PURE__ */ jsx(
        RequestFormActionItem,
        {
          requestFormRow: selectedRow,
          refresh: refreshItems,
          afterClose: () => setSelectedRow(void 0)
        }
      )
    ] })
  ] });
};
