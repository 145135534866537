"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { CustomBenefitCategory } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { conditionalSx, fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoGrey.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  NetPayArrangement,
  PaymentValues,
  PensionProviders,
  PensionRulesOptions,
  ReliefAtSource,
  SalarySacrifice
} from "@/lib/pensions";
import { SETTINGS_BENEFITS_DETAILS_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
export const ProviderSettingsStep = ({
  payrollId,
  pensionProvider,
  pensionRule,
  noOfActivePensionSchemes,
  refresh,
  displayName
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      pensionProvider,
      pensionRule,
      defaultWorkerGroup: "",
      paymentMethod: "",
      userName: "",
      password: "",
      isDefault: noOfActivePensionSchemes === 0,
      employerId: "",
      displayName: displayName != null ? displayName : ""
    },
    validationSchema: Yup.object({
      pensionProvider: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      pensionRule: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      defaultWorkerGroup: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      paymentMethod: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      userName: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      password: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      isDefault: Yup.boolean().notRequired(),
      employerId: Yup.string().when("pensionProvider", {
        is: PensionProviders.Nest,
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField"))
      }),
      displayName: Yup.string().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      return createProvider(values);
    })
  });
  const createProvider = (data) => __async(void 0, null, function* () {
    const createData = {
      providerName: data.pensionProvider,
      username: data.userName,
      password: data.password,
      payMethod: data.paymentMethod,
      pensionRule: data.pensionRule,
      employeeContribution: 5,
      employerContribution: 3,
      defaultWorkerGroup: data.defaultWorkerGroup,
      employerId: data.pensionProvider === PensionProviders.Nest ? data.employerId : void 0,
      displayName: data.displayName
    };
    try {
      setLoading(true);
      const createdPensionId = yield PensionAPI.createPensionScheme(payrollId, createData);
      showMessage(polyglot.t("PensionModule.pensionProviderCreated"), "success");
      yield refresh();
      history.push(
        generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
          productType: "standard",
          id: createdPensionId,
          category: CustomBenefitCategory.Pension
        })
      );
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Box, { sx: { margin: "auto", mt: spacing.mt10, width: "400px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { mb: spacing.mb30 }, children: polyglot.t("PensionModule.providerSettings") }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", flexDirection: "column" }), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PensionModule.pensionProvider") }),
      formik.values.pensionProvider ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g15, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5 }, children: getPensionLogoByProviderName(formik.values.pensionProvider, 23) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: (_a = PensionProvidersValueToLabel[formik.values.pensionProvider]) != null ? _a : formik.values.pensionProvider }) })
      ] }) : /* @__PURE__ */ jsx(EmptyCell, {})
    ] }),
    formik.values.pensionProvider && [PensionProviders.Nest, PensionProviders.SmartPension].includes(formik.values.pensionProvider) ? /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, conditionalSx), { display: "flex", gap: spacing.gap5, alignItems: "center" }), children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("PensionModule.thisIsSalarySacrificePension"),
          name: "isDefault",
          checked: formik.values.pensionRule === SalarySacrifice.value,
          onChange: () => {
            if (formik.values.pensionRule !== SalarySacrifice.value)
              formik.setFieldValue("pensionRule", SalarySacrifice.value);
            else if (formik.values.pensionProvider === PensionProviders.Nest)
              formik.setFieldValue("pensionRule", ReliefAtSource.value);
            else formik.setFieldValue("pensionRule", NetPayArrangement.value);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        StyledTooltip,
        {
          title: /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", sx: themeFonts.tooltip, children: [
            polyglot.t("PensionModule.agreementBeforeEnrollment"),
            " ",
            /* @__PURE__ */ jsx(
              "a",
              {
                href: "https://www.zelt.app/post/the-employer-s-guide-to-pension-contributions",
                target: "_blank",
                rel: "noreferrer",
                children: polyglot.t("PensionModule.here")
              }
            )
          ] }),
          children: /* @__PURE__ */ jsx(Box, { sx: { cursor: "pointer" }, children: /* @__PURE__ */ jsx(InfoIcon, __spreadValues({}, iconSize)) })
        }
      )
    ] }) : /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "pensionRule",
        label: polyglot.t("PensionModule.pensionRule"),
        options: PensionRulesOptions,
        value: formik.values.pensionRule,
        compareValue: formik.values.pensionRule,
        onChange: formik.handleChange,
        error: !!formik.errors.pensionRule && formik.touched.pensionRule,
        helperText: formik.touched.pensionRule && formik.errors.pensionRule
      }
    ) }),
    formik.values.pensionProvider === PensionProviders.Nest && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employerId",
        label: polyglot.t("PensionModule.employerId"),
        value: formik.values.employerId,
        onChange: formik.handleChange,
        endAdornment: "none",
        error: formik.touched.employerId && !!formik.errors.employerId,
        helperText: formik.touched.employerId && formik.errors.employerId
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "displayName",
        label: polyglot.t("General.displayName"),
        value: formik.values.displayName,
        onChange: formik.handleChange,
        endAdornment: "none",
        error: formik.touched.displayName && !!formik.errors.displayName,
        helperText: formik.touched.displayName && formik.errors.displayName
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "paymentMethod",
        label: polyglot.t("PensionModule.paymentMethod"),
        options: PaymentValues,
        value: formik.values.paymentMethod,
        compareValue: formik.values.paymentMethod,
        onChange: formik.handleChange,
        error: !!formik.errors.paymentMethod && formik.touched.paymentMethod,
        helperText: formik.touched.paymentMethod && formik.errors.paymentMethod
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "defaultWorkerGroup",
        label: polyglot.t("PensionModule.defaultWorkerGroup"),
        value: formik.values.defaultWorkerGroup,
        onChange: formik.handleChange,
        endAdornment: "none",
        error: formik.touched.defaultWorkerGroup && !!formik.errors.defaultWorkerGroup,
        helperText: formik.touched.defaultWorkerGroup && formik.errors.defaultWorkerGroup
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "userName",
        label: polyglot.t("PensionModule.username"),
        value: formik.values.userName,
        onChange: formik.handleChange,
        endAdornment: "none",
        error: formik.touched.userName && !!formik.errors.userName,
        helperText: formik.touched.userName && formik.errors.userName
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "password",
        label: polyglot.t("PensionModule.password"),
        value: formik.values.password,
        onChange: formik.handleChange,
        endAdornment: "none",
        type: "password",
        error: formik.touched.password && !!formik.errors.password,
        helperText: formik.touched.password && formik.errors.password
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.mt30 }), children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("PensionModule.addProvider"),
        loading,
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) }) });
};
