"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import { useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SideMenuHeader } from "@/v2/feature/payroll/components/side-menu-header.component";
import { formatPayrunPeriod } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { GlobalPayrunPage } from "@/v2/feature/payroll/features/payroll-global/global-payrun-flow/global-payrun.page";
import { PayrunFlowPage } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-flow.page";
import { PayrunOverviewPage } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-overview.page";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { PayrollLocalApi } from "@/v2/feature/payroll/payroll-local.api";
import { navigateToPayrunsView } from "@/v2/feature/payroll/payroll-router.util";
import { PayrollAPI, PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
const UKPayrunPage = ({ payroll, payrollCount, taxYear, payPeriod, period: periodNumber }) => {
  var _a, _b;
  const isViewingClosedPayrun = useRef();
  const routerHistory = useHistory();
  const [payrunState, setPayrunState] = useState();
  const [showMessage] = useMessage();
  const fetchPayrunData = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    try {
      const payrollId = payroll.id;
      const payrunsStats = yield PayrollExternalApi.getPayrunsStatsForTaxYear(payrollId, taxYear);
      const [
        localPayRun2,
        externalPayRun2,
        entries2,
        previousEntries2,
        employmentAllowance,
        hmrcLiability
      ] = yield Promise.all([
        PayrollLocalApi.getLocalPayRun(payrollId, taxYear, payPeriod, periodNumber),
        PayrollExternalApi.getExternalPayRun(payrollId, taxYear, payPeriod, periodNumber),
        PayrollLocalApi.findLocalPayRunEntries(payrollId, taxYear, payPeriod, periodNumber),
        periodNumber > payrunsStats.oldestPayrunPeriodNumber ? PayrollLocalApi.findLocalPayRunEntries(payrollId, taxYear, payPeriod, periodNumber - 1) : [],
        PayrollAPI.getEmploymentAllowance(payrollId),
        PayrollExternalApi.getHmrcLiabilityByPeriod(payrollId, taxYear, periodNumber)
      ]);
      entries2.sort((a, b) => a.employee.name.localeCompare(b.employee.name, void 0, { sensitivity: "base" }));
      if (isViewingClosedPayrun.current === void 0 || !externalPayRun2.isClosed) {
        isViewingClosedPayrun.current = externalPayRun2.isClosed;
      }
      let payrunEmpAllowance2 = 0;
      if (externalPayRun2.isClosed) {
        payrunEmpAllowance2 = (_a2 = hmrcLiability.employmentAllowanceClaim) != null ? _a2 : 0;
      } else if (employmentAllowance.current && externalPayRun2.totals.employerNi > 0) {
        const { allowance, claimed } = employmentAllowance.current;
        payrunEmpAllowance2 = Math.min(externalPayRun2.totals.employerNi, allowance - claimed);
      }
      setPayrunState({
        externalPayRun: externalPayRun2,
        entries: entries2,
        previousEntries: previousEntries2,
        localPayRun: localPayRun2,
        payrunEmpAllowance: payrunEmpAllowance2
      });
    } catch (error) {
      showMessage(`Failed to fetch payrun information. ${nestErrorMessage(error)}`, "error");
    }
  }), [payroll.id, payPeriod, periodNumber, showMessage, taxYear]);
  useEffect(() => {
    fetchPayrunData();
  }, [fetchPayrunData]);
  const refreshPayrun = useCallback(() => fetchPayrunData(), [fetchPayrunData]);
  const startPayrun = useCallback(
    (payrollId, payrun, paymentDate) => __async(void 0, null, function* () {
      let result = false;
      try {
        result = (yield PayrollExternalApi.finalisePayRun(
          payrollId,
          payrun.taxYear,
          payrun.payPeriod,
          payrun.period,
          paymentDate
        ), true);
      } catch (error) {
        showMessage(`Failed to start payrun. ${nestErrorMessage(error)}`, "error");
      }
      if (result) {
        yield refreshPayrun();
      }
      return result;
    }),
    [refreshPayrun, showMessage]
  );
  const completePayrun = useCallback(
    (localPayrun) => __async(void 0, null, function* () {
      let result = false;
      try {
        result = (yield PayrollLocalApi.completePayrun(localPayrun.id), true);
      } catch (error) {
        showMessage(`Could not mark payrun as complete. ${nestErrorMessage(error)}`, "error");
      }
      return result;
    }),
    [showMessage]
  );
  if (!payrunState) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  const { externalPayRun, entries, previousEntries, localPayRun, payrunEmpAllowance } = payrunState;
  const entityName = (_b = (_a = payroll.entity) == null ? void 0 : _a.legalName) != null ? _b : "";
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, flexFlow: "row", alignItems: "top", gap: spacing.g60 }, children: [
    /* @__PURE__ */ jsx(
      SideMenuHeader,
      {
        heading: formatPayrunPeriod(localPayRun),
        caption: entityName,
        onBackClick: () => {
          navigateToPayrunsView(routerHistory, "replace", localPayRun.payrollId, localPayRun.id);
        },
        sx: { mt: "20px", ml: "20px" }
      }
    ),
    /* @__PURE__ */ jsx(Stack, { sx: { flex: 1 }, children: isViewingClosedPayrun.current ? /* @__PURE__ */ jsx(
      PayrunOverviewPage,
      {
        entityName,
        payrollCount,
        externalPayRun,
        entries,
        previousEntries,
        payrunEmpAllowance,
        localPayRun,
        refreshPayrun,
        completePayrun
      }
    ) : /* @__PURE__ */ jsx(
      PayrunFlowPage,
      {
        entityName,
        payrollCount,
        externalPayRun,
        entries,
        previousEntries,
        payrunEmpAllowance,
        localPayRun,
        refreshPayrun,
        startPayrun,
        completePayrun
      }
    ) })
  ] });
};
export const PayrunPage = () => {
  const params = useParams();
  const [payrollId, taxYear, payPeriod, period] = useMemo(
    () => [
      Number(params.payrollId),
      params.taxYear,
      // "Year2020"
      params.payPeriod,
      Number(params.periodNumber)
    ],
    [params]
  );
  const { data: companyPayrolls } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });
  const payroll = useMemo(() => companyPayrolls == null ? void 0 : companyPayrolls.items.find((p) => p.id === payrollId), [
    companyPayrolls == null ? void 0 : companyPayrolls.items,
    payrollId
  ]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    companyPayrolls && payroll && payroll.employer && /* @__PURE__ */ jsx(
      UKPayrunPage,
      {
        payroll,
        payrollCount: companyPayrolls.items.length,
        taxYear,
        payPeriod,
        period
      }
    ),
    companyPayrolls && payroll && !payroll.employer && /* @__PURE__ */ jsx(
      GlobalPayrunPage,
      {
        payroll,
        payrollCount: companyPayrolls.items.length,
        taxYear,
        payPeriod,
        period
      }
    )
  ] });
};
