"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayItemAPI } from "@v2/feature/payroll/features/pay-item/pay-item.api";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { LocalDate } from "@v2/util/local-date";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
export function getNewPayItemValidationSchema(polyglot) {
  return yup.object({
    date: yup.string().test(dateFieldTest).required(polyglot.t("ValidationMessages.requiredField")),
    amount: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
    multiplier: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
    payCode: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
    description: yup.string().required(polyglot.t("ValidationMessages.requiredField"))
  });
}
export const AddNewPayItemDrawer = ({ isOpen, setIsOpen, userId, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(AddNewPayItemDrawerContent, { userId, refresh, setIsOpen }) });
};
export const AddNewPayItemDrawerContent = ({ userId, refresh, setIsOpen }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const data = {
        amount: Number(values.amount),
        multiplier: Number(values.multiplier),
        date: values.date,
        description: values.description,
        payCode: values.payCode
      };
      try {
        setLoading(true);
        yield PayItemAPI.createUpcomingPayItem(userId, data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [refresh, setIsOpen, showMessage, userId, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      date: new LocalDate().toDateString(),
      amount: 0,
      multiplier: 1,
      description: "",
      payCode: ""
    },
    validationSchema: getNewPayItemValidationSchema(polyglot),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PayItemModule.addOneOffPayItem") }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "date",
        label: polyglot.t("General.date"),
        onChange: (value) => {
          formik.setFieldValue("date", value);
        },
        value: formik.values.date,
        error: formik.touched.date && !!formik.errors.date,
        helperText: formik.touched.date && formik.errors.date
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "payCode",
        label: polyglot.t("PayItemModule.payCode"),
        value: formik.values.payCode,
        onChange: formik.handleChange,
        error: formik.touched.payCode && !!formik.errors.payCode,
        helperText: (_a = formik.touched.payCode && formik.errors.payCode) != null ? _a : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: polyglot.t("PayItemModule.amount"),
        value: formik.values.amount,
        onChange: formik.handleChange,
        error: formik.touched.amount && !!formik.errors.amount,
        helperText: (_b = formik.touched.amount && formik.errors.amount) != null ? _b : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "description",
        label: polyglot.t("General.description"),
        multiline: true,
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: (_c = formik.touched.description && formik.errors.description) != null ? _c : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.add"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
