"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  TitleStatusComponent,
  getParticipantProgressStatus
} from "@v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { generatePath, useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/Action.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE
} from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import {
  ReminderType
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ReachType } from "@/v2/interfaces/general.interface";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewCycleDetailResultsPage = ({
  reviewCycle,
  cycleLoading,
  reach
}) => {
  const params = useParams();
  const cycleId = params.cycleId;
  const { data: progress, isLoading: loadingProgress } = useApiClient(
    ReviewResultEndpoints.getReviewProgressByCycleId(cycleId, reach),
    {
      suspense: false
    }
  );
  const [selectionModel, setSelectionModel] = useState([]);
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType: reach }),
      noHorizontalPadding: true,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: cycleLoading,
      children: /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: "Results",
              headerSx: { px: spacing.px16, boxSizing: "border-box" },
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    ReviewEntriesListComponent,
                    {
                      reviewCycle,
                      progress,
                      loadingProgress,
                      setSelectionModel,
                      selectionModel,
                      reach
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    }
  );
};
const ReviewEntriesListComponent = ({
  reviewCycle,
  progress,
  loadingProgress,
  setSelectionModel,
  selectionModel,
  reach
}) => {
  const routerHistory = useHistory();
  const tableColumns = useMemo(
    () => [
      ...reviewCycle.state !== CycleState.Completed ? [
        {
          id: "select",
          enableSorting: false,
          header: () => {
            const displayedParticipantsId = new Set(progress == null ? void 0 : progress.map(({ participant }) => participant));
            const allSelected = selectionModel.length > 0 && selectionModel.length === displayedParticipantsId.size && selectionModel.every((id) => displayedParticipantsId.has(id));
            return /* @__PURE__ */ jsx(Box, { sx: { display: "inline-block", width: "fit-content" }, onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: allSelected,
                value: "allSelceted",
                onChange: (_, checked) => {
                  setSelectionModel(checked ? [...displayedParticipantsId] : []);
                }
              }
            ) });
          },
          cell: ({ row: { original } }) => {
            var _a, _b;
            return /* @__PURE__ */ jsx(Box, { sx: { display: "inline-block", width: "fit-content" }, onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: (_a = original.participant) == null ? void 0 : _a.toString(),
                checked: selectionModel.includes(original.participant),
                value: (_b = original.participant) == null ? void 0 : _b.toString(),
                onChange: () => {
                  let finalArray = [];
                  if (selectionModel == null ? void 0 : selectionModel.includes(original.participant)) {
                    finalArray = selectionModel.filter((sm) => sm !== original.participant);
                  } else finalArray = [...selectionModel, original.participant];
                  setSelectionModel(finalArray);
                }
              }
            ) });
          },
          minWidth: 5,
          width: 5,
          maxWidth: 5
        }
      ] : [],
      {
        header: () => "Participants",
        accessorFn: (row) => row,
        id: "participants",
        enableSorting: false,
        cell: ({
          row: {
            original: { participant }
          }
        }) => participant ? /* @__PURE__ */ jsx(UserCell, { userId: participant }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 100,
        maxSize: 120
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => getParticipantProgressStatus(original, reviewCycle),
        minSize: 180,
        maxSize: 200
      },
      {
        header: () => "Result",
        accessorFn: (row) => row,
        id: "isResultShared",
        enableSorting: false,
        cell: ({
          row: {
            original: { isResultShared }
          }
        }) => isResultShared ? /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(ChipComponent, { name: "Shared", border: "background" }) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 80,
        maxSize: 100
      },
      ...reviewCycle.state !== CycleState.Completed ? [
        {
          id: "actions",
          header: () => "",
          accessorFn: (row) => row,
          enableSorting: false,
          minSize: 60,
          maxSize: 80,
          cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
            "div",
            {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              },
              children: /* @__PURE__ */ jsx(ActionMenu, { participantIds: [original.participant], reviewCycle, type: "table" })
            }
          )
        }
      ] : []
    ],
    [setSelectionModel, reviewCycle, progress, selectionModel]
  );
  const handleRowClick = useCallback(
    (row) => {
      var _a, _b;
      const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE : REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE;
      routerHistory.push(
        generatePath(reachRoute, {
          revieweeId: (_b = (_a = row.original) == null ? void 0 : _a.participant) != null ? _b : "",
          cycleId: reviewCycle.id
        })
      );
    },
    [routerHistory, reviewCycle, reach]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          px: spacing.px16,
          boxSizing: "border-box",
          visibility: selectionModel.length > 0 ? "visible" : "hidden"
        },
        children: reviewCycle.state !== CycleState.Completed && /* @__PURE__ */ jsx(ActionMenu, { participantIds: selectionModel, reviewCycle, type: "page" })
      }
    ),
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: progress != null ? progress : [],
        columnData: tableColumns,
        loading: loadingProgress,
        paginationSx: { px: spacing.px16, boxSizing: "border-box" },
        rowClick: handleRowClick
      }
    )
  ] });
};
const ActionMenu = ({
  participantIds,
  reviewCycle,
  type
}) => {
  const [showMessage] = useMessage();
  const getActionsOptions = useCallback(() => {
    const menuOptions = [
      {
        handler: () => __async(void 0, null, function* () {
          try {
            const remindBody = { cycleId: reviewCycle.id, revieweeIds: participantIds };
            yield ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.All, remindBody);
            showMessage("Successfully sent reminders", "success");
          } catch (error) {
            showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
          }
        }),
        label: "Remind all",
        disabled: false
      }
    ];
    menuOptions.push({
      handler: () => __async(void 0, null, function* () {
        try {
          const remindBody = { cycleId: reviewCycle.id, revieweeIds: participantIds };
          yield ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.Overdue, remindBody);
          showMessage("Successfully sent reminders", "success");
        } catch (error) {
          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
        }
      }),
      label: "Remind overdue",
      disabled: false
    });
    return menuOptions;
  }, [reviewCycle.id, showMessage, participantIds]);
  const actionButtonDetails = type === "page" ? {
    type: "button",
    colorVariant: "secondary",
    sizeVariant: "small",
    title: "Actions",
    icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
    iconPosition: "end"
  } : {
    type: "iconButton",
    colorVariant: "secondary",
    sizeVariant: "small",
    title: "actions",
    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
  };
  return /* @__PURE__ */ jsx(StyledMenuComponent, { options: getActionsOptions(), actionButtonDetails });
};
