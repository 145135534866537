"use strict";
import { capitalize } from "lodash";
export var CycleState = /* @__PURE__ */ ((CycleState2) => {
  CycleState2["Draft"] = "draft";
  CycleState2["Scheduled"] = "scheduled";
  CycleState2["Launching"] = "launching";
  CycleState2["Ongoing"] = "ongoing";
  CycleState2["Paused"] = "paused";
  CycleState2["Completed"] = "completed";
  CycleState2["Finalising"] = "finalising";
  return CycleState2;
})(CycleState || {});
export const CycleStateArray = Object.values(CycleState);
export const SurveyCycleStateArray = [
  "draft" /* Draft */,
  "completed" /* Completed */,
  "ongoing" /* Ongoing */,
  "paused" /* Paused */,
  "launching" /* Launching */
];
export var CycleType = /* @__PURE__ */ ((CycleType2) => {
  CycleType2["Rolling"] = "rolling";
  CycleType2["Scheduled"] = "scheduled";
  return CycleType2;
})(CycleType || {});
export const CycleTypeArray = Object.values(CycleType);
export const cycleTypeOptions = [
  {
    value: "scheduled" /* Scheduled */,
    label: capitalize("scheduled" /* Scheduled */),
    description: "One off or regular check-ins at fixed intervals, like quarterly performance reviews or weekly surveys."
  },
  {
    value: "rolling" /* Rolling */,
    label: capitalize("rolling" /* Rolling */),
    description: "Invite participants to the same cycle based on an enrolment trigger, such as probation or onboarding completions. "
  }
];
