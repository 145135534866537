"use strict";
import { spacing } from "@/v2/styles/spacing.styles";
export const chartBoxSx = {
  display: { sm: "block", md: "block", lg: "flex" },
  gap: { sm: spacing.g20, md: spacing.g40, lg: spacing.g80 },
  width: "100%"
};
export const oneChartSx = {
  width: { sm: "100%", md: "100%", lg: "100%" }
};
export const parentChartSx = {
  width: { sm: "100%", md: "100%", lg: "65%" }
};
export const childChartSx = {
  width: { sm: "100%", md: "100%", lg: "35%" }
};
export const equalChartSx = {
  width: { sm: "100%", md: "100%", lg: "50%" }
};
