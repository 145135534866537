"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Slide } from "@mui/material";
import ThemeLogin from "@/images/auth/Illustartion.png";
import { ReactComponent as ZeltText } from "@/images/zelt-text.svg";
import { AuthPageLiner } from "@/v2/feature/auth/components/auth-page-liner.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AuthThemeBg = () => {
  return /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ boxSizing: "border-box", height: "100%" }, spacing.pad40), children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }, children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      "a",
      {
        href: "https://zelt.app/",
        target: "_blank",
        rel: "noopener noreferrer",
        style: { textDecoration: "none", position: "absolute", top: "20px", left: "30px" },
        children: /* @__PURE__ */ jsx(ZeltText, { style: { fill: themeColors.black } })
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Slide, { direction: "down", in: true, mountOnEnter: true, timeout: { enter: 600 }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          backgroundImage: `url(${ThemeLogin})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
          backgroundSize: "contain",
          height: { xs: "250px", sm: "350px", md: "400px", lg: "450px" }
        }
      }
    ) }) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(AuthPageLiner, {}) })
  ] }) });
};
