"use strict";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
export var ContractTemplateFields = /* @__PURE__ */ ((ContractTemplateFields2) => {
  ContractTemplateFields2["employee_first_name"] = "employee_first_name";
  ContractTemplateFields2["employee_last_name"] = "employee_last_name";
  ContractTemplateFields2["employee_full_name"] = "employee_full_name";
  ContractTemplateFields2["employee_dob"] = "employee_dob";
  ContractTemplateFields2["employee_passport_number"] = "employee_passport_number";
  ContractTemplateFields2["employee_personal_email"] = "employee_personal_email";
  ContractTemplateFields2["employee_address"] = "employee_address";
  ContractTemplateFields2["employee_address_line1"] = "employee_address_line1";
  ContractTemplateFields2["employee_address_line2"] = "employee_address_line2";
  ContractTemplateFields2["employee_address_city"] = "employee_address_city";
  ContractTemplateFields2["employee_address_country"] = "employee_address_country";
  ContractTemplateFields2["employee_address_postcode"] = "employee_address_postcode";
  ContractTemplateFields2["employee_start_date"] = "employee_start_date";
  ContractTemplateFields2["employee_role"] = "employee_role";
  ContractTemplateFields2["employee_reports_to"] = "employee_reports_to";
  ContractTemplateFields2["employee_site"] = "employee_site";
  ContractTemplateFields2["employee_department"] = "employee_department";
  ContractTemplateFields2["employee_worker_type"] = "employee_worker_type";
  ContractTemplateFields2["employee_employment_contract"] = "employee_employment_contract";
  ContractTemplateFields2["employee_attendance_schedule"] = "employee_attendance_schedule";
  ContractTemplateFields2["employee_fte_percentage"] = "employee_fte_percentage";
  ContractTemplateFields2["employee_notice_period"] = "employee_notice_period";
  ContractTemplateFields2["employee_probation_period"] = "employee_probation_period";
  ContractTemplateFields2["compensation_rate"] = "compensation_rate";
  ContractTemplateFields2["compensation_salary_basis"] = "compensation_salary_basis";
  ContractTemplateFields2["compensation_units"] = "compensation_units";
  ContractTemplateFields2["compensation_currency"] = "compensation_currency";
  ContractTemplateFields2["compensation_pay_schedule"] = "compensation_pay_schedule";
  ContractTemplateFields2["compensation_equity"] = "compensation_equity";
  ContractTemplateFields2["compensation_equity_type"] = "compensation_equity_type";
  ContractTemplateFields2["compensation_equity_grant_date"] = "compensation_equity_grant_date";
  ContractTemplateFields2["compensation_equity_amount"] = "compensation_equity_amount";
  ContractTemplateFields2["compensation_equity_vesting_start"] = "compensation_equity_vesting_start";
  ContractTemplateFields2["compensation_equity_vesting_period"] = "compensation_equity_vesting_period";
  ContractTemplateFields2["compensation_equity_vesting_cliff"] = "compensation_equity_vesting_cliff";
  ContractTemplateFields2["compensation_equity_exercise_price"] = "compensation_equity_exercise_price";
  ContractTemplateFields2["company_logo"] = "company_logo";
  ContractTemplateFields2["employee_signature"] = "employee_signature";
  ContractTemplateFields2["recipient_signature_timestamp"] = "recipient_signature_timestamp";
  ContractTemplateFields2["company_signature"] = "company_signature";
  ContractTemplateFields2["additional_signature"] = "additional_signature";
  ContractTemplateFields2["additional_signatory_timestamp"] = "additional_signatory_timestamp";
  ContractTemplateFields2["additional_signatory_full_name"] = "additional_signatory_full_name";
  ContractTemplateFields2["additional_signatory_job_title"] = "additional_signatory_job_title";
  return ContractTemplateFields2;
})(ContractTemplateFields || {});
export const requiredTemplateFields = {
  employee_first_name: false,
  employee_last_name: false,
  employee_role: false,
  employee_start_date: false,
  employee_address: false,
  employee_signature: false,
  employee_notice_period: false,
  employee_probation_period: false,
  compensation_rate: false,
  compensation_salary_basis: false,
  compensation_equity: false,
  // compensation_paid_time_off: false,
  company_logo: false,
  company_signature: true
};
export const defaultMissingFieldValues = {
  /**Template Params */
  employee_first_name: "",
  employee_last_name: "",
  employee_role: "",
  employee_start_date: "",
  employee_address: "",
  employee_signature: "",
  employee_notice_period: "",
  employee_probation_period: "",
  compensation_rate: "",
  compensation_salary_basis: "",
  compensation_units: "",
  compensation_currency: "",
  compensation_equity: "",
  // compensation_paid_time_off: '',
  company_signature: "",
  /** Address */
  address_line_1: "",
  address_line_2: "",
  address_city: "",
  address_country: "",
  address_postalcode: "",
  /** Compensation */
  salary_rate: 0,
  salary_annual_amount: 0,
  salary_units: 0,
  salary_per_pay_schedule: 0,
  /** Role */
  role: "",
  role_department: "",
  /** Equity */
  equity_grant_date: null,
  equity_type: "",
  equity_amount: 0,
  /** Contract */
  probation_period_unit: "",
  probation_period_length: 0,
  notice_period_unit: "",
  notice_period_length: 0,
  employee_attendance_schedule: void 0
};
export var SignatoryType = /* @__PURE__ */ ((SignatoryType2) => {
  SignatoryType2["recipient"] = "recipient";
  SignatoryType2["additional"] = "additional";
  SignatoryType2["witness"] = "witness";
  return SignatoryType2;
})(SignatoryType || {});
export const WORK_TYPE_FIELDS = [
  "employee_role",
  "employee_reports_to",
  "employee_attendance_schedule",
  "employee_site",
  "employee_department",
  "employee_start_date",
  "employee_probation_period",
  "employee_notice_period",
  "employee_worker_type",
  "employee_employment_contract",
  "compensation_pay_schedule",
  "compensation_rate",
  "compensation_units",
  "compensation_currency",
  "compensation_salary_basis",
  "compensation_equity",
  "compensation_equity_type",
  "compensation_equity_grant_date",
  "compensation_equity_amount",
  "compensation_equity_vesting_start",
  "compensation_equity_vesting_period",
  "compensation_equity_vesting_cliff",
  "compensation_equity_exercise_price"
];
export const PERSONAL_TYPE_FIELDS = [
  "employee_first_name",
  "employee_last_name",
  "employee_dob",
  "employee_passport_number",
  "employee_address",
  "employee_personal_email",
  "employee_address_line1",
  "employee_address_line2",
  "employee_address_city",
  "employee_address_country",
  "employee_address_postcode",
  "address_line_1",
  "address_city",
  "address_country",
  "address_postalcode"
];
export const FIELD_DISPLAY_NAME = {
  employee_probation_period: "Probation period",
  employee_notice_period: "Notice period",
  employee_role: "Role",
  employee_reports_to: "Reports to",
  employee_attendance_schedule: "Attendance schedule",
  employee_site: "Site",
  employee_department: "Department",
  employee_start_date: "Start date",
  employee_worker_type: "Worker type",
  employee_employment_contract: "Employment contract",
  employee_fte_percentage: "FTE percentage",
  compensation_pay_schedule: "Pay schedule",
  compensation_rate: "Rate",
  compensation_units: "Units",
  compensation_currency: "Currency",
  compensation_salary_basis: "Salary basis",
  compensation_equity: "Equity",
  compensation_equity_type: "Type",
  compensation_equity_grant_date: "Grant date",
  compensation_equity_amount: "Amount",
  compensation_equity_vesting_start: "Vesting start",
  compensation_equity_vesting_period: "Vesting period",
  compensation_equity_vesting_cliff: "Vesting cliff",
  compensation_equity_exercise_price: "Exercise price",
  employee_first_name: "First name",
  employee_last_name: "Last name",
  employee_dob: "Date of birth",
  employee_passport_number: "Passport number",
  employee_address: void 0,
  employee_personal_email: "Personal email",
  employee_address_line1: "Address line 1",
  employee_address_line2: "Address line 2",
  employee_address_city: "City",
  employee_address_country: "Country",
  employee_address_postcode: "Postcode",
  address_line_1: "Address line 1",
  address_city: "City",
  address_country: "Country",
  address_postalcode: "Postal code"
};
export const CONTRACT_FIELDS_CATEGORIES = {
  employee_first_name: CustomProfileFormType.Basic,
  employee_last_name: CustomProfileFormType.Basic,
  employee_full_name: CustomProfileFormType.Basic,
  employee_dob: CustomProfileFormType.Details,
  employee_passport_number: CustomProfileFormType.Details,
  employee_personal_email: CustomProfileFormType.Details,
  employee_address: CustomProfileFormType.Address,
  employee_address_line1: CustomProfileFormType.Address,
  employee_address_line2: CustomProfileFormType.Address,
  employee_address_city: CustomProfileFormType.Address,
  employee_address_country: CustomProfileFormType.Address,
  employee_address_postcode: CustomProfileFormType.Address,
  address_line_1: CustomProfileFormType.Address,
  address_city: CustomProfileFormType.Address,
  address_country: CustomProfileFormType.Address,
  address_postalcode: CustomProfileFormType.Address,
  employee_probation_period: CustomProfileFormType.Contract,
  employee_notice_period: CustomProfileFormType.Contract,
  employee_attendance_schedule: CustomProfileFormType.Contract,
  employee_worker_type: CustomProfileFormType.Contract,
  employee_employment_contract: CustomProfileFormType.Contract,
  employee_fte_percentage: CustomProfileFormType.Contract,
  compensation_currency: CustomProfileFormType.Salary,
  compensation_pay_schedule: CustomProfileFormType.Salary,
  compensation_rate: CustomProfileFormType.Salary,
  compensation_salary_basis: CustomProfileFormType.Salary,
  compensation_units: CustomProfileFormType.Salary,
  compensation_equity: CustomProfileFormType.Equity,
  compensation_equity_type: CustomProfileFormType.Equity,
  compensation_equity_grant_date: CustomProfileFormType.Equity,
  compensation_equity_amount: CustomProfileFormType.Equity,
  compensation_equity_vesting_start: CustomProfileFormType.Equity,
  compensation_equity_vesting_period: CustomProfileFormType.Equity,
  compensation_equity_vesting_cliff: CustomProfileFormType.Equity,
  compensation_equity_exercise_price: CustomProfileFormType.Equity,
  employee_site: CustomProfileFormType.Role,
  employee_department: CustomProfileFormType.Role,
  employee_role: CustomProfileFormType.Role,
  employee_reports_to: CustomProfileFormType.Role
};
export const UNPROCESSABLE_FONT_ERROR = "not defined in the font section of the document definition.";
export const EMPLOYEE_SIGNATORY_PLACEHOLDER = "{{employee_signature}}";
export const OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER = "{{company_signature}}";
export const ADDITIONAL_SIGNATORY_PLACEHOLDER = "{{additional_signature}}";
export const COMPANY_SIGNATURE_TIMESTAMP = "{{additional_signatory_timestamp}}";
export const EMPLOYEE_SIGNATURE_TIMESTAMP = "{{recipient_signature_timestamp}}";
export const WITNESS_DETAILS_PLACEHOLDER = "{{witness_details}}";
