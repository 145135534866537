"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PaymentFormModal } from "@/v2/feature/payments/features/make-payment/components/payment-form-modal.component";
import { PaymentsAPI } from "@/v2/feature/payments/payments.api";
export function PaymentForDialogSection({
  onChange,
  setIsModalOpen,
  selectedPayment
}) {
  const [showMessage] = useMessage();
  const handleChange = (request) => __async(this, null, function* () {
    try {
      if (selectedPayment) yield PaymentsAPI.updatePayment(__spreadProps(__spreadValues({}, request), { institutionId: "none" }), selectedPayment.id);
      else yield PaymentsAPI.createPayment(__spreadProps(__spreadValues({}, request), { institutionId: "none" }));
      setIsModalOpen(false);
      onChange();
    } catch (e) {
      showMessage(nestErrorMessage(e), "error");
    }
  });
  return /* @__PURE__ */ jsx(PaymentFormModal, { onSubmit: handleChange, selectedPayment, setIsModalOpen });
}
