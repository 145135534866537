"use strict";
import { jsx } from "react/jsx-runtime";
import { Content2Columns } from "@v2/feature/app-layout/features/main-content/layouts/components/content-2-columns.component";
import { AttendanceMeWeeklyEntries } from "@/v2/feature/attendance/me/components/attendance-me-weekly-entries.component";
import { AttendanceWeeklyListSection } from "@/v2/feature/attendance/me/components/attendance-weekly-list-section.component";
export const AttendanceMeContent = ({
  searchInput,
  setSearchInput,
  setPagination,
  weekList,
  pagination,
  totalPages,
  onPageChange,
  loading,
  selectedWeekAndYear,
  weeklyEntries,
  userLoading,
  refresh,
  refreshWeekList,
  userId,
  totalItems
}) => {
  return /* @__PURE__ */ jsx(
    Content2Columns,
    {
      column1: /* @__PURE__ */ jsx(
        AttendanceWeeklyListSection,
        {
          searchInput,
          setSearchInput,
          setPagination,
          weekList,
          pagination,
          totalPages,
          onPageChange,
          handleWeekClick: refresh,
          loading,
          selectedWeekAndYear,
          totalItems
        }
      ),
      column2: /* @__PURE__ */ jsx(
        AttendanceMeWeeklyEntries,
        {
          weeklyEntries,
          userLoading,
          refresh,
          loading,
          weekList: weekList != null ? weekList : [],
          refreshWeekList,
          userId
        }
      ),
      borderLeft: true
    }
  );
};
