"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { appsWithoutAutomaticAccountCreation } from "@v2/feature/app-integration/app-integration.util";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_SETTINGS_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
  APP_STORE_ROUTE
} from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { AppIntegrationAPI, AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { AppCard } from "@/v2/feature/app-integration/apps.page";
import { AppButton } from "@/v2/feature/app-integration/components/app-button.component";
import { AppIntroduction } from "@/v2/feature/app-integration/components/app-introduction.component";
import { AppRequestAPI, AppRequestEndpoints } from "@/v2/feature/app-integration/features/app-request/app-request.api";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PersonalOverview = () => {
  var _a, _b;
  const [globalState] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const [filteredCompanyApps, setFilteredCompanyApps] = useState(void 0);
  const { getScopesContext } = useScopes();
  const { data: myApps, isValidating: myAppsLoading } = useApiClient(
    AppIntegrationEndpoints.getUserApps((_a = globalState == null ? void 0 : globalState.user) == null ? void 0 : _a.userId),
    { suspense: false }
  );
  const {
    data: installedApps,
    isValidating: installedAppsLoading
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });
  const { data: latestApps } = useApiClient(AppIntegrationEndpoints.getLatestApps(), { suspense: false });
  const { data: requestsOnlyForUser, mutate: refreshRequestsForUser } = useApiClient(
    AppRequestEndpoints.getAppRequestsForUser("Requested,Failed,Scheduled,Pending"),
    {
      suspense: false
    }
  );
  useEffect(() => {
    if (!myAppsLoading && !installedAppsLoading) {
      const nonAppOwnerAppsStubs = myApps == null ? void 0 : myApps.map((app) => app.stub);
      const nonAppOwnerApps = installedApps == null ? void 0 : installedApps.filter((app) => !(nonAppOwnerAppsStubs == null ? void 0 : nonAppOwnerAppsStubs.includes(app.stub)));
      setFilteredCompanyApps(nonAppOwnerApps);
    }
  }, [installedApps, myApps, myAppsLoading, installedAppsLoading]);
  const [showMessage] = useMessage();
  const createAppRequest = (appStub) => __async(void 0, null, function* () {
    try {
      const appRequestBody = {
        requestInfo: { appStub }
      };
      if (installedApps && appsWithoutAutomaticAccountCreation(installedApps).includes(appStub)) {
        yield AppRequestAPI.createPendingAppRequest(appRequestBody);
      } else {
        yield AppRequestAPI.createAppRequest(appRequestBody);
      }
      refreshRequestsForUser();
      showMessage(`App was successfully requested.`, "success");
    } catch (error) {
      showMessage(`App request failed. ${nestErrorMessage(error)}`, "error");
    }
  });
  const initiateOauthFlowForApp = (event, appStub) => {
    if (event) event.preventDefault();
    window.location.href = AppIntegrationAPI.getInitiateUrl(appStub);
  };
  const getActionForConnect = (app, event) => {
    var _a2, _b2;
    if ((app == null ? void 0 : app.authorised) && !((_a2 = app == null ? void 0 : app.directory) == null ? void 0 : _a2.oauth))
      return routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, { appStub: app.stub }));
    if ((_b2 = app == null ? void 0 : app.directory) == null ? void 0 : _b2.oauth) return initiateOauthFlowForApp(event, app.stub);
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Apps" }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: (_b = myAppsLoading || installedAppsLoading) != null ? _b : false, children: (myApps == null ? void 0 : myApps.length) === 0 && (installedApps == null ? void 0 : installedApps.length) === 0 ? /* @__PURE__ */ jsx(AppIntroduction, {}) : /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          gap: spacing.g40,
          alignItems: "flex-start",
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { width: { xs: "100%", sm: "100%", md: "60%", lg: "60%" } }, children: [
            /* @__PURE__ */ jsxs(Box, { children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), spacing.mb20), children: "Your apps" }),
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g10, flexWrap: "wrap" }, children: myApps && (myApps == null ? void 0 : myApps.length) > 0 ? myApps.map((a, idx) => {
                var _a2, _b2;
                return /* @__PURE__ */ jsx(
                  AppButton,
                  {
                    title: (_b2 = (_a2 = installedApps == null ? void 0 : installedApps.find((eachApp) => eachApp.stub === a.stub)) == null ? void 0 : _a2.name) != null ? _b2 : "",
                    handleClick: () => routerHistory.push(
                      generatePath(APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE, {
                        appStub: a.stub
                      })
                    ),
                    avatarImg: `/app-icons-v2/images/${a.stub}.png`,
                    stub: a.stub,
                    app: a,
                    expired: !!a.invalidatedAt,
                    expiredAction: getActionForConnect
                  },
                  `${a.stub}-${idx}`
                );
              }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "No apps are currently installed" }) })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m60 }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), spacing.mb20), children: "Company apps" }),
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g10, flexWrap: "wrap" }, children: filteredCompanyApps && (filteredCompanyApps == null ? void 0 : filteredCompanyApps.length) > 0 ? filteredCompanyApps.map((a, idx) => {
                var _a2, _b2;
                return /* @__PURE__ */ jsx(
                  AppButton,
                  {
                    title: (_b2 = (_a2 = installedApps == null ? void 0 : installedApps.find((eachApp) => eachApp.stub === a.stub)) == null ? void 0 : _a2.name) != null ? _b2 : "",
                    handleClick: () => routerHistory.push(
                      generatePath(APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE, {
                        appStub: a.stub
                      })
                    ),
                    avatarImg: `/app-icons-v2/images/${a.stub}.png`,
                    stub: a.stub,
                    action: requestsOnlyForUser && !requestsOnlyForUser.find((r) => r.requestInfo.appStub === a.stub) ? /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(Button, { sx: __spreadValues({}, secondaryCTABtn), fullWidth: true, onClick: () => createAppRequest(a.stub), children: "Request" }) }) : /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, spacing.mt20), { textAlign: "center" }), children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "Requested" }) }),
                    app: a,
                    expired: !!a.invalidatedAt,
                    expiredAction: getActionForConnect
                  },
                  `${a.stub}-${idx}`
                );
              }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "No apps are currently installed" }) })
            ] })
          ] }),
          /* @__PURE__ */ jsxs(Box, { sx: { width: { xs: "100%", sm: "100%", md: "40%", lg: "40%" } }, children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "New apps" }),
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "small",
                  colorVariant: "primary",
                  style: { width: "80px" },
                  onClick: () => routerHistory.push(APP_STORE_ROUTE),
                  children: "All apps"
                }
              )
            ] }),
            /* @__PURE__ */ jsx(Box, { children: latestApps == null ? void 0 : latestApps.map((l, idx) => {
              var _a2, _b2;
              if (idx < 5) {
                return /* @__PURE__ */ jsx(
                  AppCard,
                  {
                    title: (_a2 = l.name) != null ? _a2 : "",
                    subtitle: l.appStoreBlurb,
                    avatarImg: `/app-icons-v2/images/${l.stub}.png`,
                    stub: (_b2 = l.stub) != null ? _b2 : "",
                    showBorder: idx < 4,
                    action: () => routerHistory.push(
                      generatePath(APP_INTEGRATION_DETAILS_ABOUT_ROUTE, {
                        appStub: l.stub
                      })
                    ),
                    actionScope: ["apps.connect", "apps:all"],
                    context: getScopesContext({ userId: globalState.user.userId })
                  },
                  `${l.name}-${idx}`
                );
              } else return null;
            }) })
          ] })
        ]
      }
    ) })
  ] });
};
