"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { TimelineScheduledModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/scheduled/timeline-scheduled-modal.component";
import { timelineByReviwer } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const TimelineScheduledView = ({ reviewCycle, showFlags, isEditable, refreshCycle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { timelineSettings } = reviewCycle;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            headerWidth: "100%",
            title: "Timeline",
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.TextLine,
                value: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { pb: spacing.s2 }, children: "Timeline defines due dates for participants and serves as a reminder. After the deadline has passed, results will not be released automatically unless the whole cycle is explicitly finalised by cycle owner in the Overview page or individual results are shared with participants." })
              },
              ...timelineSettings.startNow && isEditable && reviewCycle.state !== CycleState.Ongoing ? [
                {
                  type: SectionItemType.Pair,
                  label: "Start now",
                  value: "Cycle will be immediately launched"
                }
              ] : [
                {
                  type: SectionItemType.Pair,
                  label: "Start date (GMT)",
                  value: (timelineSettings == null ? void 0 : timelineSettings.startDate) ? new LocalDate(timelineSettings == null ? void 0 : timelineSettings.startDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }) : "NA"
                },
                {
                  type: SectionItemType.Pair,
                  label: "Start time (GMT)",
                  value: (timelineSettings == null ? void 0 : timelineSettings.startTime) && (timelineSettings == null ? void 0 : timelineSettings.startTimeMeridiem) ? `${timelineSettings == null ? void 0 : timelineSettings.startTime} ${timelineSettings == null ? void 0 : timelineSettings.startTimeMeridiem}` : "NA"
                }
              ],
              ...timelineByReviwer(reviewCycle, showFlags)
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      TimelineScheduledModal,
      {
        reviewCycle,
        isOpen,
        setIsOpen,
        onClose: () => setIsOpen(false),
        refresh: () => __async(void 0, null, function* () {
          yield refreshCycle == null ? void 0 : refreshCycle();
        }),
        showFlags
      }
    )
  ] });
};
