"use strict";
export var UserSelectFiltersOptions = /* @__PURE__ */ ((UserSelectFiltersOptions2) => {
  UserSelectFiltersOptions2["None"] = "";
  UserSelectFiltersOptions2["Everyone"] = "everyone";
  UserSelectFiltersOptions2["SelectSpecific"] = "select-specific";
  UserSelectFiltersOptions2["CustomRule"] = "custom-rule";
  return UserSelectFiltersOptions2;
})(UserSelectFiltersOptions || {});
export var CustomRuleOptions = /* @__PURE__ */ ((CustomRuleOptions2) => {
  CustomRuleOptions2["Site"] = "site";
  CustomRuleOptions2["Department"] = "department";
  CustomRuleOptions2["Entity"] = "entity";
  return CustomRuleOptions2;
})(CustomRuleOptions || {});
export const getCustomRuleOptionsList = (polyglot) => {
  return [
    { value: "site", label: polyglot.t("getCustomRuleOptionsList.site") },
    { value: "department", label: polyglot.t("getCustomRuleOptionsList.department") },
    { value: "entity", label: polyglot.t("getCustomRuleOptionsList.entity") }
  ];
};
export const getUserSelectOptionLabel = (option, polyglot) => {
  if (option === "" /* None */) return polyglot.t("UserSelect.none");
  if (option === "select-specific" /* SelectSpecific */) return polyglot.t("UserSelect.specific");
  if (option === "custom-rule" /* CustomRule */) return polyglot.t("UserSelect.customRule");
  return polyglot.t("UserSelect.everyone");
};
