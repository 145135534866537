"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { CustomProfileFormAPI } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const CustomFormDrawer = ({ form, formNames, formTab, close }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMessage] = useMessage();
  const createSection = useCallback(
    (formTab2, formName) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const newForm = yield CustomProfileFormAPI.createForm({
          formTab: formTab2,
          formName
        });
        showMessage(`Section added successfully`, "success");
        close(newForm, "created");
      } catch (error) {
        showMessage(`Could not create section. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }),
    [close, showMessage]
  );
  const updateSection = useCallback(
    (form2, formName) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const newForm = yield CustomProfileFormAPI.updateForm(form2.formId, {
          formName
        });
        close(newForm, "updated");
      } catch (error) {
        showMessage(`Could not update section. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }),
    [close, showMessage]
  );
  const deleteSection = useCallback(
    (form2) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield CustomProfileFormAPI.deleteForm(form2.formId);
        showMessage(`Section deleted`, "success");
        close(form2, "deleted");
      } catch (error) {
        showMessage(`Could not delete section. ${nestErrorMessage(error)}`, "error");
      }
      setLoading(false);
    }),
    [close, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      formName: (_a = form == null ? void 0 : form.formName) != null ? _a : ""
    },
    validationSchema: yup.object({
      formName: yup.string().required("Section name is required").test("isDuplicateFormName", "Duplicate section name", (formName) => {
        const lowerFormName = formName == null ? void 0 : formName.toLocaleLowerCase();
        if (!formNames.find((n) => n.toLocaleLowerCase() === lowerFormName)) return true;
        return lowerFormName === (form == null ? void 0 : form.formName.toLocaleLowerCase());
      })
    }),
    onSubmit(values) {
      return __async(this, null, function* () {
        if (form) {
          yield updateSection(form, values.formName);
        } else {
          yield createSection(formTab, values.formName);
        }
      });
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: form ? `Edit ${form.formName}` : "New section" }),
      form && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: iconButtonSx,
            onClick: (e) => {
              setAnchorEl(e.currentTarget);
            },
            title: "Delete section",
            children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
          }
        ),
        /* @__PURE__ */ jsx(
          NotificationModal,
          {
            isOpen: Boolean(anchorEl),
            onClose: () => setAnchorEl(null),
            anchorEl,
            takeAction: () => __async(void 0, null, function* () {
              yield deleteSection(form);
              setAnchorEl(null);
            }),
            message: "Are you sure you want to delete this section? All fields and user data linked to this section will be permanently deleted.",
            callToAction: "Yes"
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "formName",
        value: formik.values.formName,
        label: "Section name",
        onChange: formik.handleChange,
        disabled: loading,
        maxLength: 50,
        error: !!formik.submitCount && !!formik.errors.formName,
        helperText: !!formik.submitCount && formik.errors.formName,
        autoFocus: true
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
