"use strict";
import { jsx } from "react/jsx-runtime";
import { UserDetailsTabsRouter } from "@/component/dashboard/userDetails/user-details-tabs.router";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { USER_DETAILS_ROUTE } from "@/lib/routes";
import { Switch, useParams } from "react-router-dom";
export const UserDetailsRouter = () => {
  const params = useParams();
  const userId = Number(params.userId);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["user:read"], context: scopesContext, path: USER_DETAILS_ROUTE, children: /* @__PURE__ */ jsx(UserDetailsTabsRouter, {}) }) });
};
