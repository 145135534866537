"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { ReactComponent as Warning } from "@/images/side-bar-icons/Warning.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { usePaymentContext } from "@/v2/feature/payments/features/make-payment/payment.context";
import { BankingHoursBanks, timeOptions } from "@/v2/feature/payments/payments.util";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ExecutionDateStep = ({ onNext }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t;
  const [paymentContext, setState] = usePaymentContext();
  const isSinglePayment = ((_a = paymentContext.payments) == null ? void 0 : _a.length) === 1;
  const isBulkPayment = paymentContext.payments && paymentContext.payments.length > 1;
  const executionDateTimeDays = isSinglePayment ? (_d = (_c = (_b = paymentContext == null ? void 0 : paymentContext.institution) == null ? void 0 : _b.config) == null ? void 0 : _c.singlePayment) == null ? void 0 : _d.executionDateTimeDays : (_g = (_f = (_e = paymentContext == null ? void 0 : paymentContext.institution) == null ? void 0 : _e.config) == null ? void 0 : _f.bulkPayment) == null ? void 0 : _g.executionDateTimeDays;
  const supportsScheduledPayment = isSinglePayment && ((_j = (_i = (_h = paymentContext == null ? void 0 : paymentContext.institution) == null ? void 0 : _h.config) == null ? void 0 : _i.singlePayment) == null ? void 0 : _j.supportsScheduledPayment) || isBulkPayment && ((_m = (_l = (_k = paymentContext == null ? void 0 : paymentContext.institution) == null ? void 0 : _k.config) == null ? void 0 : _l.bulkPayment) == null ? void 0 : _m.supportsScheduledPayments);
  const supportsImmediatePayment = isSinglePayment && ((_p = (_o = (_n = paymentContext == null ? void 0 : paymentContext.institution) == null ? void 0 : _n.config) == null ? void 0 : _o.singlePayment) == null ? void 0 : _p.supportsImmediatePayment) || isBulkPayment && ((_s = (_r = (_q = paymentContext == null ? void 0 : paymentContext.institution) == null ? void 0 : _q.config) == null ? void 0 : _r.bulkPayment) == null ? void 0 : _s.supportsImmediatePayments);
  const nextAvailableDateTime = executionDateTimeDays != null ? executionDateTimeDays : 0;
  const firstAvailableDay = /* @__PURE__ */ new Date();
  firstAvailableDay.setDate(firstAvailableDay.getDate() + nextAvailableDateTime);
  if (nextAvailableDateTime > 0) firstAvailableDay.setHours(9);
  firstAvailableDay.setMinutes(nextAvailableDateTime === 0 ? firstAvailableDay.getMinutes() + 10 : 0);
  const [showDateTimePicker, setShowDateTimePicker] = useState(
    supportsImmediatePayment ? "now" : "selectedDate"
  );
  const [value, setValue] = useState(new LocalDate(firstAvailableDay).toDateString());
  const [selectedTime, setSelectedTime] = useState("09:00");
  const handleChange = (value2) => {
    setShowDateTimePicker(value2);
  };
  const handleDateChange = (value2) => {
    setValue(value2);
  };
  const handleTimeChange = (event) => {
    var _a2;
    setSelectedTime((_a2 = event.target) == null ? void 0 : _a2.value);
  };
  const getDateTimeString = (value2, selectedTime2) => {
    const timeArray = selectedTime2.split(":");
    const paymentDate = dayjs(value2).set("hour", Number(timeArray[0])).set("minute", Number(timeArray[1]));
    return paymentDate.toDate();
  };
  const goToNextStep = () => {
    if (showDateTimePicker === "selectedDate" && !value) return;
    if (showDateTimePicker === "selectedDate" && value) {
      const combined = getDateTimeString(value, selectedTime);
      setState(({ payments, requests, institution, payerDetails }) => {
        return {
          payments,
          requests,
          institution,
          payerDetails,
          executionDateTime: combined
        };
      });
      onNext();
    }
    if (showDateTimePicker === "now") {
      setState(({ payments, requests, institution, payerDetails }) => {
        return {
          payments,
          requests,
          institution,
          payerDetails,
          executionDateTime: void 0
        };
      });
      onNext();
    }
  };
  const [filterString, setFilterString] = useState(supportsImmediatePayment ? "now" : "selectedDate");
  const TabFilter = [
    ...supportsImmediatePayment ? [{ name: "Pay now", value: "now" }] : [],
    ...supportsScheduledPayment ? [{ name: "Schedule for later", value: "selectedDate" }] : []
  ];
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: "When do you want to make payment?" }),
        paymentContext.institution && BankingHoursBanks.includes((_t = paymentContext.institution) == null ? void 0 : _t.id) && /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex" }, spacing.mt20), { alignItems: "center", gap: spacing.g10 }), children: [
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Warning, { width: 16, height: 16, style: { fill: themeColors.orange } }) }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: "Payment execution must be during banking hours." })
        ] }),
        showDateTimePicker === "selectedDate" && !!executionDateTimeDays && /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex" }, spacing.mt20), { alignItems: "center", gap: spacing.g10 }), children: [
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Warning, { width: 16, height: 16, style: { fill: themeColors.orange } }) }),
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({}, themeFonts.caption), children: [
            "Your bank requires at least ",
            executionDateTimeDays,
            " day",
            executionDateTimeDays > 1 ? "s" : "",
            " in advance to execute the payment."
          ] })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m30 }, children: [
          /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              filters: TabFilter,
              setFilterValue: setFilterString,
              filterValue: filterString,
              onFilterChange: ({ filterValue }) => {
                setFilterString(filterValue);
                handleChange(filterValue);
              }
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: showDateTimePicker === "selectedDate" && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              DatePickerComponent,
              {
                inputFormat: "DD/MM/YYYY",
                value,
                onChange: (value2) => {
                  if (dayjs(value2).isValid()) {
                    handleDateChange(value2);
                  }
                },
                name: "date",
                label: "Date",
                error: void 0,
                helperText: void 0,
                minDate: firstAvailableDay.getTime() > Date.now() ? firstAvailableDay.toISOString().slice(0, 16) : (/* @__PURE__ */ new Date()).toISOString().slice(0, 16)
              }
            ) }) }),
            /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                name: "Time",
                label: "Time",
                options: timeOptions,
                value: selectedTime,
                compareValue: selectedTime,
                error: void 0,
                onChange: handleTimeChange,
                helperText: void 0
              }
            ) })
          ] }) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, onClick: goToNextStep, sizeVariant: "medium", colorVariant: "primary", children: "Continue" }) })
      ] })
    }
  );
};
