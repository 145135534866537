"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { InsuranceMeOverviewPage } from "@v2/feature/benefits/subfeature/insurance/pages/insurance-me-overview.page";
import { matchPath, Redirect, Switch, useLocation } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  INSURANCE_ME_OVERVIEW_ROUTE,
  USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE
} from "@/lib/routes";
export const InsuranceUserRouter = ({ userId, userInsurance, refresh, loading }) => {
  const location = useLocation();
  return /* @__PURE__ */ jsxs(Switch, { children: [
    userInsurance && /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance"],
        context: { userId },
        path: [INSURANCE_ME_OVERVIEW_ROUTE, USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE],
        children: /* @__PURE__ */ jsx(InsuranceMeOverviewPage, { userId, userInsurance, refresh, loading })
      }
    ),
    matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE) ? /* @__PURE__ */ jsx(Redirect, { to: USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE }) : /* @__PURE__ */ jsx(Redirect, { to: INSURANCE_ME_OVERVIEW_ROUTE })
  ] });
};
