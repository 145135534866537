"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PermissionGroupEditDrawerPage } from "@/v2/feature/permission-group/components/permission-group-edit-drawer.component";
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES
} from "@/v2/feature/permission-group/permission-group.interface";
import { DEFAULT_GROUPS } from "@/v2/feature/permission-group/permission-group.util";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditPermissionGroupMembersPage = ({ permissionGroup, refresh, setLoading }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup == null ? void 0 : permissionGroup.name) : false;
  const openEditDrawer = () => {
    setEditDrawerOpen(true);
  };
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("PermissionGroups.router.members"),
      onEdit: !isDefault ? () => __async(void 0, null, function* () {
        return openEditDrawer();
      }) : void 0,
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(PermissionsMembersTable, { permissionGroup })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(DrawerModal, { isOpen: editDrawerOpen, setIsOpen: setEditDrawerOpen, children: /* @__PURE__ */ jsx(
          PermissionGroupEditDrawerPage,
          {
            refreshPermissionGroup: refresh,
            selectedPermission: permissionGroup,
            permissionGroupMembers: (_a = permissionGroup == null ? void 0 : permissionGroup.members) != null ? _a : [],
            closePage: () => __async(void 0, null, function* () {
              setEditDrawerOpen(false);
              yield refresh();
            }),
            mode: PERMISSION_GROUP_EDIT_DRAWER_MODES.member,
            setLoading,
            permissionGroupName: (_b = permissionGroup == null ? void 0 : permissionGroup.name) != null ? _b : ""
          }
        ) })
      ]
    }
  );
};
const PermissionsMembersTable = ({ permissionGroup }) => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const filteredUsers = useMemo(() => {
    if (!searchInput) return [...permissionGroup.members];
    const search = searchInput.toLowerCase();
    return [
      ...permissionGroup.members.filter(
        (u) => (u.firstName + u.lastName).toLowerCase().includes(search)
      )
    ];
  }, [permissionGroup.members, searchInput]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("AbsencePolicyMembersSection.name") }),
        accessorFn: (row) => row,
        id: "displayName",
        enableSorting: true,
        cell: ({
          row: {
            original: { userId, firstName, lastName }
          }
        }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId, size: "xsmall" }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: firstName + " " + lastName })
        ] }, userId)
      }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => setSearchInput(e.target.value),
        style: { width: "572px" }
      }
    ),
    filteredUsers.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { width: "600px" }, children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredUsers,
        columnData: tableColumns,
        initialSort: [{ id: "firstName", desc: true }],
        fixedLastColumn: false
      }
    ) })
  ] });
};
