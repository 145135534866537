"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SCParticipantAddModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/scheduled/sc-participant-add-modal.component";
import { SCParticipantsSharedTable } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/components/shared/sc-participants-shared-table.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCParticipantScheduledView = ({
  surveyParticipants,
  surveyParticipantsLoading,
  surveyCycle,
  refresh,
  reach,
  isEditable
}) => {
  var _a, _b;
  const [isParticipantAddOpen, setIsParticipantAddOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    surveyCycle && surveyCycle.participantIds && ((_a = surveyCycle.participantIds) == null ? void 0 : _a.length) > 0 ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              title: "Participants",
              items: [
                {
                  type: SectionItemType.Pair,
                  label: "Participants",
                  value: `${(_b = surveyCycle == null ? void 0 : surveyCycle.participantIds) == null ? void 0 : _b.length} - participant(s)`
                },
                {
                  type: SectionItemType.Component,
                  value: isEditable ? /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      sizeVariant: "small",
                      colorVariant: "primary",
                      onClick: () => setIsParticipantAddOpen(true),
                      style: { marginTop: spacing.m16 },
                      children: "Add participants"
                    }
                  ) : null
                }
              ]
            }
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: "Participants part of this cycle",
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    SCParticipantsSharedTable,
                    {
                      surveyCycle,
                      surveyParticipants,
                      surveyParticipantsLoading,
                      refresh,
                      isEditable
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    ] }) : /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            title: "Participants",
            items: [
              {
                type: SectionItemType.TextLine,
                value: "Please select the participants for the current survey cycle."
              },
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(
                  ParticipantsEmptyState,
                  {
                    setIsOpen: setIsParticipantAddOpen,
                    buttonLabel: "Add participants",
                    isEditable
                  }
                )
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      SCParticipantAddModal,
      {
        surveyCycle,
        isOpen: isParticipantAddOpen,
        setIsOpen: setIsParticipantAddOpen,
        onClose: () => setIsParticipantAddOpen(false),
        refresh,
        reach
      }
    )
  ] });
};
const ParticipantsEmptyState = ({
  setIsOpen,
  isEditable,
  buttonLabel
}) => {
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24, marginTop: spacing.m16 }, children: /* @__PURE__ */ jsx(
    ButtonComponent,
    {
      sizeVariant: "small",
      colorVariant: "primary",
      disabled: !isEditable,
      onClick: () => setIsOpen(true),
      "aria-label": "Add Participants",
      children: buttonLabel ? buttonLabel : "Add participants"
    }
  ) });
};
