"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { parentCardSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { CompanySubscriptionsTable } from "@/v2/feature/billing/components/v2-company-subscriptions-table.component";
import { V2UpdateCompanySubscriptionDrawer } from "@/v2/feature/billing/components/v2-update-company-subscription-drawer.component";
import {
  SubscriptionType
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CompanySubscriptionSection = ({ subscriptionDetailsForCompany, refresh }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const isInTrialMode = ((_a = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _a.type) === SubscriptionType.TRIAL;
  const trialDateEnding = isInTrialMode && ((_b = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _b.endDate) ? new Date((_c = subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.companySubscription) == null ? void 0 : _c.endDate) : null;
  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Box, { sx: parentCardSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", mt: 1 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("SubscriptionSection.subscription") }),
      !isUpdateDrawerOpen && /* @__PURE__ */ jsx(IconButton, { title: "Update subscription", sx: tableIconButtonSx, onClick: () => setIsUpdateDrawerOpen(true), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ my: spacing.m10 }, themeFonts.caption), { color: themeColors.DarkGrey }), children: polyglot.t("SubscriptionSection.description") }),
    isInTrialMode && trialDateEnding ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ my: spacing.m10 }, themeFonts.title4), { color: themeColors.DarkGrey }), children: polyglot.t("SubscriptionSection.trialMessage") }) : /* @__PURE__ */ jsx(CompanySubscriptionsTable, { subscriptionDetailsForCompany }),
    isUpdateDrawerOpen && subscriptionDetailsForCompany && /* @__PURE__ */ jsx(
      V2UpdateCompanySubscriptionDrawer,
      {
        isOpen: isUpdateDrawerOpen,
        setIsDrawerOpen: setIsUpdateDrawerOpen,
        companySubscriptionDetails: subscriptionDetailsForCompany,
        refresh
      }
    )
  ] });
};
