"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  DeferForceAtUserLoginMaxBypassAttemptsOptions,
  FilevaultEnabled,
  ZeltPolicyPayloadIdentifier
} from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@/v2/styles/spacing.styles";
export const ZeltMdmEncryptionPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const getPolicy = () => {
    var _a, _b, _c, _d, _e, _f;
    return {
      Enable: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableEncryptionPolicy) == null ? void 0 : _a.Enable) != null ? _b : "Off",
      Defer: true,
      ShowRecoveryKey: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableEncryptionPolicy) == null ? void 0 : _c.ShowRecoveryKey) != null ? _d : false,
      DeferForceAtUserLoginMaxBypassAttempts: (_f = (_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableEncryptionPolicy) == null ? void 0 : _e.DeferForceAtUserLoginMaxBypassAttempts) != null ? _f : -1,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FILE_VAULT_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(policy) {
    if (policy.Enable === "Off") {
      return void 0;
    }
    return {
      Enable: policy.Enable,
      Defer: policy.Defer,
      ShowRecoveryKey: policy.ShowRecoveryKey,
      DeferForceAtUserLoginMaxBypassAttempts: policy.DeferForceAtUserLoginMaxBypassAttempts,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FILE_VAULT_PAYLOAD_IDENTIFIER.valueOf()
    };
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableEncryptionPolicy: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Passcode" }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: "20px" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "enable",
            label: "Enable FileVault",
            options: FilevaultEnabled,
            value: formik.values.Enable,
            onChange: (e) => {
              const enable = e == null ? void 0 : e.target.value;
              formik.setFieldValue("Enable", enable);
            }
          }
        ) }),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Show recovery key",
            name: "ShowRecoveryKey",
            checked: formik.values.ShowRecoveryKey,
            onChange: (e, checked) => {
              formik.setFieldValue("ShowRecoveryKey", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "DeferForceAtUserLoginMaxBypassAttempts",
            label: "Max defer attempts",
            options: DeferForceAtUserLoginMaxBypassAttemptsOptions,
            value: formik.values.DeferForceAtUserLoginMaxBypassAttempts,
            onChange: (e) => {
              const deferForceAtUserLoginMaxBypassAttempts = e == null ? void 0 : e.target.value;
              formik.setFieldValue("DeferForceAtUserLoginMaxBypassAttempts", deferForceAtUserLoginMaxBypassAttempts);
            }
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center", marginTop: "20px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => formik.handleSubmit(), fullWidth: true, colorVariant: "primary", sizeVariant: "medium", children: "Save" }) })
    ] })
  ] });
};
