"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsenceEndpoints } from "@/v2/feature/absence/absence.api";
import { AppIntegrationAPI, AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { AbsencePolicyAppMappingDrawer } from "@/v2/feature/app-integration/features/app-details/components/absence-policy-app-mapping-drawer.component";
import { SettingsPageSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
const MappingTable = ({
  currentDomain,
  polyglot,
  mappings,
  policiesMap,
  policiesLoading,
  handleEdit,
  handleDelete
}) => {
  const columns = useMemo(
    () => [
      {
        header: "Policy Full Name",
        accessorFn: (row) => row,
        id: "policyFullName",
        cell: ({ row: { original } }) => {
          const policyName = policiesMap[original.entityLocalId] || "Unknown Policy";
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: policyName });
        }
      },
      {
        header: polyglot.t("AppMappingPage.type"),
        accessorFn: (row) => row,
        id: "type",
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: ((_a = original.entityExternalDetails) == null ? void 0 : _a.type) || "N/A" });
        }
      },
      {
        header: polyglot.t("AppMappingPage.reason"),
        accessorFn: (row) => row,
        id: "reason",
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: ((_a = original.entityExternalDetails) == null ? void 0 : _a.reason) || "N/A" });
        }
      },
      {
        header: "",
        id: "actions",
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(
            Box,
            {
              sx: { width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", gap: spacing.s1 },
              children: [
                /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    title: polyglot.t("General.delete"),
                    onClick: () => handleEdit(original),
                    sx: tableIconButtonSx,
                    children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
                  }
                ),
                /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    title: polyglot.t("General.delete"),
                    onClick: () => handleDelete(currentDomain, original),
                    sx: tableIconButtonSx,
                    children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
                  }
                )
              ]
            }
          );
        }
      }
    ],
    [currentDomain, handleDelete, handleEdit, policiesMap, polyglot]
  );
  return /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: mappings,
      columnData: columns,
      loading: policiesLoading,
      hidePagination: true,
      externalTdAndThPadding: "0 0px"
    }
  );
};
export const AppMappingPage = ({ app }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [policiesMap, setPoliciesMap] = useState({});
  const {
    data: mappingsByDomain,
    isValidating: loadingCurrentMappings,
    mutate: refreshAppMappingDetails
  } = useApiClient((app == null ? void 0 : app.stub) ? AppIntegrationEndpoints.getAppMappingDetails(app.stub) : { url: void 0 }, {
    suspense: false
  });
  const [existingAbsenceMapping, setExistingAbsenceMapping] = useState(null);
  const { data: externalAbsenceMappingDetails } = useApiClient(
    (app == null ? void 0 : app.stub) ? AppIntegrationEndpoints.getExternalMappingDetails(app.stub) : { url: void 0 },
    { suspense: false }
  );
  const { data: absencePolicies, isValidating: loadingAbsencePolicies } = useApiClient(
    AbsenceEndpoints.getAbsencePolicies(),
    {
      suspense: false
    }
  );
  const handleMappingDelete = (domain, selectedRow) => __async(void 0, null, function* () {
    if (app == null ? void 0 : app.stub) {
      try {
        yield AppIntegrationAPI.deleteExistingExternalMapping(app.stub, domain, selectedRow.entityLocalId);
        showMessage("Successfully deleted existing mapping", "success");
        refreshAppMappingDetails();
      } catch (error) {
        showMessage(
          `Encountered an error while trying to delete existing mapping: ${nestErrorMessage(error)}`,
          "error"
        );
      }
    }
  });
  useEffect(() => {
    if (absencePolicies && absencePolicies.length) {
      const map = (absencePolicies != null ? absencePolicies : []).reduce(
        (acc, policy) => {
          acc[policy.id] = policy.name;
          return acc;
        },
        {}
      );
      setPoliciesMap(map);
    }
  }, [absencePolicies]);
  const loadingData = loadingCurrentMappings || loadingAbsencePolicies;
  const alreadyMappedPolicies = useMemo(() => {
    const mappedPolicies = /* @__PURE__ */ new Set();
    if (mappingsByDomain) {
      Object.values(mappingsByDomain).forEach((mappings) => {
        mappings.forEach((mapping) => {
          mappedPolicies.add(mapping.entityLocalId);
        });
      });
    }
    return mappedPolicies;
  }, [mappingsByDomain]);
  return /* @__PURE__ */ jsxs(SettingsPageSection, { title: polyglot.t("AppMappingPage.title"), width: "100%", children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AppMappingPage.subtitle") }),
    (_a = app == null ? void 0 : app.supportedDomainsForExternalMapping) == null ? void 0 : _a.map((domain) => {
      var _a2;
      return /* @__PURE__ */ jsx(Box, { sx: { mt: 4 }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t(`getSidebarConfig.${domain}`) }),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => {
                setExistingAbsenceMapping(null);
                setIsEditOpen(true);
              },
              children: polyglot.t("AppMappingPage.newMapping")
            }
          )
        ] }),
        loadingData ? (
          // Show a loading indicator while data is being fetched
          /* @__PURE__ */ jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "100%", children: /* @__PURE__ */ jsx(LoadingSpinner, {}) })
        ) : mappingsByDomain && ((_a2 = mappingsByDomain[domain]) == null ? void 0 : _a2.length) > 0 ? (
          // Show the MappingTable if mappings exist
          /* @__PURE__ */ jsx(
            MappingTable,
            {
              mappings: mappingsByDomain[domain],
              policiesMap,
              policiesLoading: loadingCurrentMappings || loadingAbsencePolicies,
              polyglot,
              handleEdit: (selectedRow) => {
                const { entityLocalId, entityExternalDetails } = selectedRow;
                setExistingAbsenceMapping({
                  policyId: entityLocalId,
                  type: entityExternalDetails.type,
                  reason: entityExternalDetails.reason
                });
                setIsEditOpen(true);
              },
              handleDelete: handleMappingDelete,
              currentDomain: domain
            }
          )
        ) : (
          // Show an empty state message if no mappings exist
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mt: "8px" }, children: polyglot.t("AppMappingPage.noMappingsExist", { appName: app == null ? void 0 : app.name }) })
        )
      ] }) }, domain);
    }),
    isEditOpen && (app == null ? void 0 : app.stub) && /* @__PURE__ */ jsx(
      AbsencePolicyAppMappingDrawer,
      {
        isOpen: isEditOpen,
        setIsOpen: setIsEditOpen,
        onClose: function() {
          setIsEditOpen(false);
        },
        onUpdate: () => {
          refreshAppMappingDetails();
        },
        existingMapping: existingAbsenceMapping != null ? existingAbsenceMapping : null,
        absencePolicies: absencePolicies != null ? absencePolicies : [],
        externalMappingOptions: externalAbsenceMappingDetails != null ? externalAbsenceMappingDetails : {},
        appStub: app.stub,
        alreadyMappedPolicies
      }
    )
  ] });
};
