"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { PaymentsAPI } from "@v2/feature/payments/payments.api";
import { ContractorInvoiceStatus, DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
import { getContractorInvoiceStatusComponent } from "@v2/feature/payments/utils/get-contractor-invoice-status.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import moment from "moment/moment";
import { generatePath, useHistory } from "react-router-dom";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Document.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { nestErrorMessage } from "@/lib/errors";
import { INVOICES_COMPANY_EDIT_INVOICE_ROUTE, INVOICES_ME_EDIT_INVOICE_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { RejectDrawer } from "@/v2/components/reject-drawer.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { LineItemInDrawerComponent } from "@/v2/feature/payments/components/line-item-in-drawer.component";
import { ViewerItem } from "@/v2/feature/payments/components/payment-details-drawer.component";
import { getTaxRateForTotalSection } from "@/v2/feature/payments/expenses.util";
import { getFinalGrossForLineItemInvoice } from "@/v2/feature/payments/utils/invoice.util";
import { DocPreviewer } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { iconSize } from "@/v2/styles/menu.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const ContractorInvoiceModal = ({
  isOpen,
  setIsOpen,
  selectedInvoice,
  onClose,
  afterClose,
  onActionPerformed,
  currentUserIsAdmin,
  reach = "me"
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const [isUpdatingApproval, setIsUpdatingApproval] = useState(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const invoiceFileName = `Invoice_${(_a = selectedInvoice == null ? void 0 : selectedInvoice.fromUser) == null ? void 0 : _a.firstName}${(_b = selectedInvoice == null ? void 0 : selectedInvoice.fromUser) == null ? void 0 : _b.lastName}_${selectedInvoice == null ? void 0 : selectedInvoice.invoiceNumber}`;
  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState(false);
  const [attachmentBuffer, setAttachmentBuffer] = useState();
  const [selectedDocContentType, setSelectedDocContentType] = useState("");
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const currentUserIsApproverOfInvoice = (_c = selectedInvoice.approver) == null ? void 0 : _c.includes(user.userId);
  const currentUserIsInvoiceAdmin = hasScopes(
    ["invoices:all", "invoices:manager"],
    getScopesContext({ userId: user == null ? void 0 : user.userId })
  );
  const currentUserIsManagerOfInvoiceOwner = (selectedInvoice == null ? void 0 : selectedInvoice.from) ? checkScopes(user, ["invoices:manager"], { userId: selectedInvoice.from }) : false;
  const hasRights = currentUserIsManagerOfInvoiceOwner || currentUserIsInvoiceAdmin;
  const draftInvoice = (selectedInvoice == null ? void 0 : selectedInvoice.status) === ContractorInvoiceStatus.Draft;
  const handleApproveOrReject = useCallback(
    (invoiceId, userId, status, notes) => __async(void 0, null, function* () {
      try {
        setIsUpdatingApproval(true);
        yield ContractorInvoiceAPI.updateInvoiceApproval(invoiceId, userId, status, notes);
        showMessage(polyglot.t("ContractorInvoiceModal.successMessages.update", { status }), "success");
        setIsUpdatingApproval(false);
        setIsRejectDrawerOpen(false);
        onClose();
        yield onActionPerformed();
      } catch (e) {
        showMessage(
          polyglot.t("ContractorInvoiceModal.errorMessages.update", { errorMessage: nestErrorMessage(e) }),
          "error"
        );
        setIsUpdatingApproval(false);
      }
      try {
        const [contractorInvoices, payments] = yield Promise.all([
          ContractorInvoiceAPI.getAlerts(),
          PaymentsAPI.getAlerts()
        ]);
        dispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { contractorInvoices, payments }
        });
      } catch (error) {
        console.error("Could not update invoices alerts.", error);
      }
    }),
    [showMessage, polyglot, onClose, onActionPerformed, dispatch]
  );
  const syncInvoiceWithAccountingProvider = (invoiceId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield ContractorInvoiceAPI.syncInvoiceWithExternalProvider(invoiceId);
      showMessage(polyglot.t("ContractorInvoiceModal.successMessages.sync"), "success");
    } catch (error) {
      showMessage(
        polyglot.t("ContractorInvoiceModal.errorMessages.sync", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  const handleDownloadClick = (invoiceId) => __async(void 0, null, function* () {
    try {
      const PDFBlob = yield ContractorInvoiceAPI.downloadInvoice({ invoiceId });
      const file = new Blob([PDFBlob], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement("a");
      link.download = `${invoiceFileName}.pdf`;
      link.href = fileURL;
      link.click();
    } catch (e) {
      console.error("::Download error", e);
      showMessage(`Failed to download contractor invoice. ${nestErrorMessage(e)}`, "error");
    }
  });
  const handlePreviewClick = useCallback(
    (invoice) => __async(void 0, null, function* () {
      try {
        const PDFBlob = yield ContractorInvoiceAPI.downloadInvoice({ invoiceId: invoice.id });
        const file = new Blob([PDFBlob], { type: "application/pdf" });
        const arrayBuffer = yield file.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        const fileBuffer = Buffer.from(uint8Array);
        const bufferData = { data: fileBuffer };
        setAttachmentBuffer(bufferData);
        setSelectedDocContentType("application/pdf");
        setOpenPreviewModal(true);
      } catch (e) {
        console.error("::Download error", e);
        showMessage(`Failed to preview contractor invoice. ${nestErrorMessage(e)}`, "error");
      }
    }),
    [showMessage]
  );
  const invoicePending = (selectedInvoice == null ? void 0 : selectedInvoice.status) === ContractorInvoiceStatus.Pending;
  const canEditInvoice = hasRights && (draftInvoice || invoicePending) ? true : selectedInvoice && selectedInvoice.createdBy && user.userId === selectedInvoice.createdBy && draftInvoice ? true : selectedInvoice && selectedInvoice.from ? hasScopes(["invoices"], getScopesContext({ userId: selectedInvoice.from })) && user.userId === (selectedInvoice == null ? void 0 : selectedInvoice.from) : false;
  const canDeleteOrEditInvoice = (selectedInvoice == null ? void 0 : selectedInvoice.status) && [
    ContractorInvoiceStatus.Draft,
    ContractorInvoiceStatus.Pending,
    ContractorInvoiceStatus.Rejected,
    ContractorInvoiceStatus.Voided
  ].includes(selectedInvoice == null ? void 0 : selectedInvoice.status);
  const currentUserIsBeneficiaryOfInvoice = user.userId === (selectedInvoice == null ? void 0 : selectedInvoice.from);
  const deleteInvoice = useCallback(
    (invoiceId) => __async(void 0, null, function* () {
      try {
        setAnchorEl(null);
        yield ContractorInvoiceAPI.deleteInvoice(invoiceId);
        yield onActionPerformed();
        onClose();
      } catch (error) {
        showMessage(`Could not delete invoice. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [onActionPerformed, showMessage, onClose]
  );
  const navigateToInvoicePageToEdit = useCallback(() => {
    if (selectedInvoice) {
      if (!selectedInvoice || !(selectedInvoice == null ? void 0 : selectedInvoice.id) || !((selectedInvoice == null ? void 0 : selectedInvoice.status) === ContractorInvoiceStatus.Pending || (selectedInvoice == null ? void 0 : selectedInvoice.status) === ContractorInvoiceStatus.Draft))
        return;
    }
    if (reach === "me") {
      routerHistory.push(generatePath(INVOICES_ME_EDIT_INVOICE_ROUTE, { id: selectedInvoice == null ? void 0 : selectedInvoice.id }), {
        invoiceToEdit: selectedInvoice
      });
    } else if (reach === "company") {
      routerHistory.push(generatePath(INVOICES_COMPANY_EDIT_INVOICE_ROUTE, { id: selectedInvoice == null ? void 0 : selectedInvoice.id }), {
        invoiceToEdit: selectedInvoice
      });
    }
  }, [reach, routerHistory, selectedInvoice]);
  const groupedItems = selectedInvoice.lineItems.filter((item) => item.taxRate != null && item.amount != null && item.gross != null).reduce((acc, item) => {
    const taxRate = item.taxRate;
    const gross = item.gross;
    const amount = item.amount;
    if (!acc[taxRate]) {
      acc[taxRate] = 0;
    }
    acc[taxRate] += gross - amount;
    return acc;
  }, {});
  const nonZeroTaxRates = Object.entries(groupedItems).filter(([_, value]) => value !== 0).reduce((acc, [key, value]) => {
    acc[Number(key)] = value;
    return acc;
  }, {});
  const actionOptionsForInvoice = useMemo(() => {
    const options = [];
    const canEditOption = (invoicePending || draftInvoice) && canEditInvoice;
    const canDeleteOption = canDeleteOrEditInvoice && currentUserIsAdmin || invoicePending && currentUserIsBeneficiaryOfInvoice || draftInvoice && canEditInvoice;
    options.push({
      icon: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize)),
      label: "Edit",
      disabled: !canEditOption,
      handler: () => {
        navigateToInvoicePageToEdit();
      }
    });
    options.push({
      icon: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize)),
      label: "Delete",
      disabled: !canDeleteOption,
      handler: (event) => {
        setAnchorEl(event.currentTarget);
        setIsRemovalModalOpen(true);
      }
    });
    return options;
  }, [
    canDeleteOrEditInvoice,
    canEditInvoice,
    currentUserIsAdmin,
    currentUserIsBeneficiaryOfInvoice,
    draftInvoice,
    invoicePending,
    navigateToInvoicePageToEdit
  ]);
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, afterClose, children: /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, drawerContentSx), { boxSizing: "border-box" }), children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between"
        },
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ContractorInvoiceModal.title") }),
          (canDeleteOrEditInvoice && currentUserIsAdmin || invoicePending && currentUserIsBeneficiaryOfInvoice || draftInvoice && canEditInvoice) && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: actionOptionsForInvoice,
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
              }
            }
          ) })
        ]
      }
    ),
    selectedInvoice && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: 0, mb: spacing.mb20 }, children: [
      (selectedInvoice == null ? void 0 : selectedInvoice.from) && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.paidTo"),
          value: /* @__PURE__ */ jsx(UserCell, { userId: selectedInvoice.from, nameVariant: "title4" })
        }
      ),
      selectedInvoice.type && /* @__PURE__ */ jsx(ViewerItem, { title: polyglot.t("ContractorInvoiceModal.policy"), value: (_d = selectedInvoice.type) == null ? void 0 : _d.name }),
      selectedInvoice.notes && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.description"),
          value: (selectedInvoice == null ? void 0 : selectedInvoice.notes) && selectedInvoice.notes.length > 40 ? /* @__PURE__ */ jsx(StyledTooltip, { title: selectedInvoice.notes, placement: "top", children: /* @__PURE__ */ jsx("span", { children: truncateWithEllipses(selectedInvoice.notes, 40) }) }) : /* @__PURE__ */ jsx("div", { children: truncateWithEllipses(selectedInvoice.notes, 40) })
        }
      ),
      selectedInvoice.invoiceDate && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.date"),
          value: moment(selectedInvoice.invoiceDate).format("DD MMM YYYY")
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.type"),
          value: ((_e = selectedInvoice == null ? void 0 : selectedInvoice.lineItems) == null ? void 0 : _e.length) === 1 ? "Single" : "Multiple"
        }
      ),
      selectedInvoice.updatedBy && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.lastUpdatedBy"),
          value: /* @__PURE__ */ jsx(UserCell, { userId: selectedInvoice.updatedBy, nameVariant: "title4" })
        }
      ),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.lastUpdatedOn"),
          value: moment(selectedInvoice.updatedAt).format("DD MMM YYYY")
        }
      ),
      ((_f = selectedInvoice.lineItems) == null ? void 0 : _f.length) > 0 ? /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mt: spacing.mt20 }, children: "Items" }) : /* @__PURE__ */ jsx(Fragment, {}),
      (_g = selectedInvoice == null ? void 0 : selectedInvoice.lineItems) == null ? void 0 : _g.map((item, index) => /* @__PURE__ */ jsx(
        LineItemInDrawerComponent,
        {
          item,
          index,
          currency: selectedInvoice.currency,
          showBottomBorder: index + 1 === (selectedInvoice == null ? void 0 : selectedInvoice.lineItems.length)
        },
        item.id
      )),
      selectedInvoice.totalAmount && selectedInvoice.amount && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("NewInvoicePage.totalExcludingTax"),
          value: formatCurrency(
            selectedInvoice.amount ? selectedInvoice.amount : 0,
            void 0,
            (_h = selectedInvoice.currency) != null ? _h : DEFAULT_CURRENCY
          )
        }
      ),
      Object.keys(nonZeroTaxRates).length > 0 ? Object.keys(nonZeroTaxRates).map((taxRate) => /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: `${polyglot.t("NewInvoicePage.taxAmount")} ${getTaxRateForTotalSection(Number(taxRate))}`,
          value: formatCurrency(
            nonZeroTaxRates[Number(taxRate)],
            void 0,
            selectedInvoice.currency || DEFAULT_CURRENCY
          )
        },
        taxRate
      )) : null,
      selectedInvoice.amount && selectedInvoice.taxRate !== null && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("NewInvoicePage.amount"),
          value: formatCurrency(
            ((_i = selectedInvoice.lineItems) == null ? void 0 : _i.length) > 0 ? (_j = getFinalGrossForLineItemInvoice(selectedInvoice.lineItems)) != null ? _j : 0 : 0,
            void 0,
            (_k = selectedInvoice.currency) != null ? _k : DEFAULT_CURRENCY
          )
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", width: 1 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { fontWeight: 700 }, children: invoiceFileName }) }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap5 }, children: [
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => handleDownloadClick(selectedInvoice.id),
              children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsxs(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: (event) => {
                event.stopPropagation();
                handlePreviewClick(selectedInvoice);
              },
              children: [
                /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)),
                openPreviewModal && attachmentBuffer && /* @__PURE__ */ jsx(
                  DocPreviewer,
                  {
                    fileBuffer: attachmentBuffer,
                    contentType: selectedDocContentType,
                    visible: openPreviewModal,
                    onClose: () => {
                      setOpenPreviewModal(false);
                      setAttachmentBuffer(void 0);
                    },
                    title: invoiceFileName
                  }
                )
              ]
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mt: spacing.mt20 }, children: "Approval" }),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.status"),
          value: getContractorInvoiceStatusComponent(selectedInvoice.status, themeFonts.title4)
        }
      ),
      selectedInvoice.approvedByUser && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.approvedBy"),
          value: /* @__PURE__ */ jsx(UserCell, { userId: selectedInvoice.approvedByUser.userId, nameVariant: "title4" })
        }
      ),
      selectedInvoice.approvedOn && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("ContractorInvoiceModal.approvedOn"),
          value: new Date(selectedInvoice.approvedOn).toLocaleString()
        }
      ),
      selectedInvoice.status === ContractorInvoiceStatus.Rejected && selectedInvoice.approvalNotes && ((_l = selectedInvoice.approvalNotes) == null ? void 0 : _l.length) > 0 && /* @__PURE__ */ jsx(ViewerItem, { title: polyglot.t("ContractorInvoiceModal.notes"), value: selectedInvoice.approvalNotes })
    ] }),
    /* @__PURE__ */ jsx(
      AccountingIntegrationSection,
      {
        selectedInvoice,
        syncInvoiceWithAccountingProvider,
        loading,
        user
      }
    ),
    selectedInvoice.status === ContractorInvoiceStatus.Pending && (currentUserIsAdmin || currentUserIsApproverOfInvoice) && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: isUpdatingApproval,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            setIsRejectDrawerOpen(true);
          }),
          children: polyglot.t("General.reject")
        }
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: isUpdatingApproval,
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true,
          onClick: () => __async(void 0, null, function* () {
            yield handleApproveOrReject(
              selectedInvoice.id,
              selectedInvoice.from,
              ContractorInvoiceStatus.Approved
            );
          }),
          children: polyglot.t("General.approve")
        }
      )
    ] }),
    isRejectDrawerOpen && selectedInvoice && /* @__PURE__ */ jsx(
      RejectDrawer,
      {
        isOpen: isRejectDrawerOpen,
        setIsOpen: setIsRejectDrawerOpen,
        onReject: (notes) => __async(void 0, null, function* () {
          yield handleApproveOrReject(
            selectedInvoice.id,
            selectedInvoice.from,
            ContractorInvoiceStatus.Rejected,
            notes
          );
        })
      }
    ),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => setIsRemovalModalOpen(false),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (selectedInvoice) yield deleteInvoice(selectedInvoice.id);
        }),
        message: `Are you sure you want to delete this invoice?`,
        callToAction: "Yes"
      }
    ),
    openPreviewModal && attachmentBuffer && /* @__PURE__ */ jsx(
      DocPreviewer,
      {
        fileBuffer: attachmentBuffer,
        contentType: selectedDocContentType,
        visible: openPreviewModal,
        onClose: () => {
          setOpenPreviewModal(false);
          setAttachmentBuffer(void 0);
        },
        title: invoiceFileName
      }
    )
  ] }) });
};
const AccountingIntegrationSection = ({
  selectedInvoice,
  syncInvoiceWithAccountingProvider,
  loading,
  user
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap10, paddingBottom: spacing.s2 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: spacing.mb5, children: polyglot.t("ContractorInvoiceModal.accountingIntegration") }),
    selectedInvoice.externalId && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "External invoice" }),
      /* @__PURE__ */ jsx(
        "a",
        {
          href: `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${selectedInvoice.externalId}`,
          target: "_blank",
          rel: "noreferrer",
          children: /* @__PURE__ */ jsx(Box, { component: "span", sx: themeFonts.caption, children: "See here" })
        }
      )
    ] }),
    (selectedInvoice == null ? void 0 : selectedInvoice.lineItems) && (selectedInvoice == null ? void 0 : selectedInvoice.lineItems.length) > 0 && selectedInvoice.lineItems.some((item) => item.accountingCode !== null) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: Array.from(new Set(selectedInvoice.lineItems.map((item) => item.accountingCode).filter(Boolean))).map(
        (accountingCode) => {
          const item = selectedInvoice.lineItems.find((i) => i.accountingCode === accountingCode);
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: `${polyglot.t("PaymentSettingsPage.tableColumns.accountingCode")} (${accountingCode}${(item == null ? void 0 : item.accountingCodeDescription) ? truncateWithEllipses(" - ".concat(item == null ? void 0 : item.accountingCodeDescription), 15) : ""})` }, accountingCode);
        }
      ) }),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["invoices:all"], context: { userId: user.userId }, children: /* @__PURE__ */ jsxs(
        ButtonComponent,
        {
          colorVariant: "secondary",
          sizeVariant: "small",
          disabled: loading,
          onClick: () => __async(void 0, null, function* () {
            yield syncInvoiceWithAccountingProvider(selectedInvoice.id);
          }),
          children: [
            /* @__PURE__ */ jsx(RefreshIcon, { sx: __spreadValues({}, iconSize) }),
            polyglot.t(
              (selectedInvoice == null ? void 0 : selectedInvoice.externalId) ? "ContractorInvoiceModal.syncAgain" : "ContractorInvoiceModal.syncExternally"
            )
          ]
        }
      ) })
    ] }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "No accounting code" })
  ] });
};
