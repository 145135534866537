"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class BillingSubscriptionAPI {
  /** @deprecated */
  static getCompanySubscriptions() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/billing/subscriptions")).data;
    });
  }
  static getCompanySubscriptionDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/companies/subscription/details")).data;
    });
  }
  static upgradeSubscriptionAsSuperadmin(companyId, subscriptions) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/billing/subscriptions/superadmin/upgrade", { companyId, subscriptions });
    });
  }
  static downgradeSubscriptionAsSuperadmin(companyId, payload) {
    return __async(this, null, function* () {
      return yield axios.post("/apiv2/billing/subscriptions/superadmin/cancel", __spreadValues({ companyId }, payload));
    });
  }
  static upgradeSubscription(subscriptions) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/billing/subscriptions/upgrade", { subscriptions })).data;
    });
  }
  static upgradeSubscriptionV2(subscriptions) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/billing/subscriptions/upgrade-v2", { subscriptions })).data;
    });
  }
  static downgradeSubscription(payload) {
    return __async(this, null, function* () {
      return yield axios.post("/apiv2/billing/subscriptions/cancel", payload);
    });
  }
  static getSubscriptionStats() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/billing/subscriptions/stats")).data;
    });
  }
  static getSubscriptionBillingStats() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/billing/subscriptions/billing-stats")).data;
    });
  }
}
