"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { FieldValueComponent } from "@v2/feature/device/device.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
export const ActivationLockPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  configurationPayloadOption
}) => {
  const { polyglot } = usePolyglot();
  const showActivationLock = () => {
    if (!configurationPayloadOption) return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "No configuration payload" });
    return Object.entries(configurationPayloadOption).map(([key, value]) => /* @__PURE__ */ jsx(
      FieldValueComponent,
      {
        title: polyglot.t(`InHouseMdmPolicyViewDrawer.${key}`),
        value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: String(value) })
      },
      key
    ));
  };
  if (!configurationPayloadOption) return null;
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: policyName }),
    /* @__PURE__ */ jsx(
      Box,
      {
        component: "dl",
        sx: { display: "flex", flexDirection: "column", mt: spacing.m20, padding: 0, gap: spacing.g15 },
        children: showActivationLock()
      }
    )
  ] }) });
};
