"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Chip } from "@mui/material";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { NotImplemented } from "@v2/feature/reports/reports-advanced/filters-components/not-implemented.component";
import { ReportSQLOperator } from "@v2/feature/reports/reports.interface";
export const IntOptionSelector = ({
  operator,
  value,
  setValue,
  label,
  filterOptions
}) => {
  const IntOptionOperatorsWithNoExtraField = useMemo(() => {
    return /* @__PURE__ */ new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);
  return operator === ReportSQLOperator.eq ? /* @__PURE__ */ jsx(
    SelectComponent,
    {
      name: "value",
      label,
      options: filterOptions != null ? filterOptions : [],
      value,
      onChange: (e) => {
        setValue(Number(e.target.value));
      },
      fullWidth: true
    }
  ) : operator === ReportSQLOperator.in ? /* @__PURE__ */ jsx(
    AutocompleteComponent,
    {
      multiple: true,
      name: "value",
      label,
      filterSelectedOptions: true,
      value: (filterOptions != null ? filterOptions : []).filter((o) => value && value.includes(o.value)),
      options: filterOptions != null ? filterOptions : [],
      onChange: (_, e) => {
        const value2 = e;
        setValue(value2.map((o) => o.value));
      },
      isOptionEqualToValue: (x, y) => x.value === y.value,
      renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => {
        return /* @__PURE__ */ jsx(Chip, __spreadValues({ label: option.label }, getTagProps({ index })));
      })
    }
  ) : IntOptionOperatorsWithNoExtraField.has(operator) ? null : /* @__PURE__ */ jsx(NotImplemented, {});
};
