"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "./forms/button.component";
import MaterialModal from "@/component/widgets/ModalV2";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const ConfirmModal = ({
  title,
  isOpen,
  message,
  onClose,
  takeAction,
  callToAction,
  callToActionButtonType = "error",
  secondaryButton,
  isMainActionDisabled = false,
  loading = false
}) => {
  const { polyglot } = usePolyglot();
  useEscapeKey(() => onClose());
  return /* @__PURE__ */ jsx(MaterialModal, { isOpen, onClose, children: /* @__PURE__ */ jsxs(Stack, { sx: { padding: spacing.p5 }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), id: "modal-headline", children: title }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: message })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", mt: spacing.m50, gap: spacing.g10, justifyContent: "center" }, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: callToAction,
          loading,
          onClick: () => takeAction(),
          disabled: isMainActionDisabled,
          sizeVariant: "medium",
          colorVariant: callToActionButtonType === "error" ? "danger" : "primary"
        }
      ),
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => onClose(), disabled: loading, children: secondaryButton || polyglot.t("General.cancel") })
    ] })
  ] }) });
};
