"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { DOCUMENTS_ME_ROUTE, TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE } from "@/lib/routes";
import { UserDocumentsPage } from "@/v2/feature/documents/document-me/pages/user-documents.page";
import { TemplateEditorPage } from "@/v2/feature/templates/pages/template-editor-v-two.page";
export const DocumentsMeRouter = () => {
  const [state] = useContext(GlobalContext);
  const { userId } = state.user;
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: TEMPLATE_CONTRACT_SIGN_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "readonly", action: "sign" }) }),
    /* @__PURE__ */ jsx(Route, { path: TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TemplateEditorPage, { mode: "readonly", action: "company_sign" }) }),
    /* @__PURE__ */ jsx(Route, { path: DOCUMENTS_ME_ROUTE, exact: true, children: /* @__PURE__ */ jsx(UserDocumentsPage, { userId, userName: `${state.user.firstName} ${state.user.lastName}` }) })
  ] });
};
