"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
import { extractPathAfterDomain } from "@/v2/util/string.util";
export class GoogleCalendarAPI {
  static getConfig() {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get("/apiv2/google-calendar/config")) == null ? void 0 : _a.data;
    });
  }
  static syncCalendar() {
    return __async(this, null, function* () {
      yield axios.get("/apiv2/google-calendar/sync-events");
    });
  }
}
export class CalendarSettingsAPI {
  static getSettings() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/calendar-settings")).data;
    });
  }
  static upsertSettings(calendarSettingsDto) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/calendar-settings", calendarSettingsDto)).data;
    });
  }
}
export class CalendarAPI {
  static loadInitialCalendarData(dateRange, filterString, selectedTypes, searchInput, view, page, pageSize) {
    return __async(this, null, function* () {
      const query = [
        filterString,
        selectedTypes,
        `start=${dateRange.start}`,
        `end=${dateRange.end}`,
        searchInput ? `search=${searchInput}` : "",
        `view=${view}`,
        `page=${page}`,
        `pageSize=${pageSize}`
      ].filter(Boolean).join("&");
      return yield (yield axios.get(`/apiv2/calendar/initial?${query}`)).data;
    });
  }
  static loadAdvanceCalendarData() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/calendar/advance`)).data;
    });
  }
  static getAllCalendars(dateRange) {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/absences/calendar-types?start=${dateRange.start}&end=${dateRange.end}`)).data;
    });
  }
  static createCalendarEvent(event) {
    return __async(this, null, function* () {
      return yield (yield axios.post("/apiv2/company/events", { event })).data;
    });
  }
  static updateCalendarEvent(event, id) {
    return __async(this, null, function* () {
      return yield (yield axios.patch(`/apiv2/company/events/${id}`, { event })).data;
    });
  }
  static deleteCalendarEvent(id) {
    return __async(this, null, function* () {
      return yield (yield axios.delete(`/apiv2/company/events/${id}`)).data;
    });
  }
  static getCalendarEvent() {
    return __async(this, null, function* () {
      return yield (yield axios.get("/apiv2/company/events")).data;
    });
  }
  static getCalendarEventByDateRange(dateRange) {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/company/events/calendar?start=${dateRange.start}&end=${dateRange.end}`)).data;
    });
  }
  static updatePublishLinksForOutlookCalendar(publishLinksForCalendar) {
    return __async(this, null, function* () {
      return yield (yield axios.patch("/apiv2/outlook-calendar/publish-links", { publishLinksForCalendar })).data;
    });
  }
  static downloadIcsFromUrl(url) {
    return __async(this, null, function* () {
      const icsPath = extractPathAfterDomain(url);
      return yield (yield axios.get(icsPath, { responseType: "blob" })).data;
    });
  }
}
export class CalendarEndpoints {
  static getOutlookCalendarPublishLinks() {
    return { url: "/apiv2/outlook-calendar/publish-links" };
  }
  static getCalendarIcsAddressHash(mode, filter) {
    let queryString = "";
    if (filter && filter.length > 0) {
      queryString = "?";
      const filtersArray = filter.split("&");
      queryString += filtersArray.map((filter2) => `filter[]=${encodeURIComponent(filter2)}`).join("&");
    }
    return {
      url: `/apiv2/calendar/ical-address-hash/${mode}${queryString}`
    };
  }
}
