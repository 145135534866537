"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Radar } from "react-chartjs-2";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const ChartRadar = ({
  series,
  categories,
  externalTooltipHandler,
  tooltipEnabled = true,
  noDataTitle = "Not sufficient data to show results."
}) => {
  const chartData = {
    labels: categories,
    datasets: series
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: true
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: { padding: 4, stepSize: 25, display: false },
        border: {
          dashOffset: 0.5
        },
        pointLabels: {
          display: "auto",
          color: themeColors.Grey,
          font: {
            size: 14,
            family: "Inter, sans-serif",
            weight: 300
          },
          callback: (label) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            if (!context) return "";
            if (context.measureText(label).width <= 150) {
              return label;
            }
            while (label.length > 0) {
              label = label.slice(0, -1);
              if (context.measureText(label + "...").width <= 150) {
                return label + "...";
              }
            }
            return label != null ? label : "";
          }
        }
      }
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        fullWidth: false,
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          boxWidth: 5,
          boxHeight: 5,
          padding: 20,
          color: themeColors.Grey,
          font: {
            size: 14,
            family: "Inter, sans-serif",
            weight: 300
          }
        }
      },
      tooltip: {
        intersect: true,
        enabled: tooltipEnabled,
        position: "nearest",
        external: externalTooltipHandler != null ? externalTooltipHandler : null,
        bodyFont: {
          size: 12,
          family: "Inter, sans-serif",
          weight: 300
        },
        titleFont: {
          size: 12,
          family: "Inter, sans-serif",
          weight: 300
        },
        footerFont: {
          size: 12,
          family: "Inter, sans-serif",
          weight: 300
        }
      }
    }
  };
  return categories && categories.length > 0 ? /* @__PURE__ */ jsx(Radar, { data: chartData, options }) : /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center", height: "100%", verticalAlign: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: noDataTitle }) });
};
