"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ScopeActionEnum, ScopeFilterEnum } from "@/models/scopes.model";
export function isSupersetScope(scope, maybeSupersetScope) {
  if (maybeSupersetScope.entity !== scope.entity) {
    return false;
  }
  if (maybeSupersetScope.action !== ScopeActionEnum.ReadWrite && maybeSupersetScope.action !== scope.action) {
    return false;
  }
  switch (scope.filter) {
    case ScopeFilterEnum.All: {
      return maybeSupersetScope.filter === ScopeFilterEnum.All;
    }
    case ScopeFilterEnum.Manager: {
      return maybeSupersetScope.filter === ScopeFilterEnum.All || maybeSupersetScope.filter === ScopeFilterEnum.Manager;
    }
    default: {
      return true;
    }
  }
}
export function hasScopes(user, scopes) {
  var _a, _b;
  const parsedUserScopes = ((_b = (_a = user.scopes2) == null ? void 0 : _a.map((s) => s.scope)) != null ? _b : []).map(parseScope);
  const parsedRequiredScopes = scopes.map(parseScope);
  return parsedRequiredScopes.every((rs) => parsedUserScopes.some((us) => isSupersetScope(rs, us)));
}
export function canAccessScopes(user, scopes) {
  const hasReports = user.reports && user.reports.length > 0;
  const userScopes = user.scopes2;
  for (const scope of scopes) {
    const parsedScope = parseScope(scope);
    switch (parsedScope.filter) {
      case ScopeFilterEnum.Owner: {
        if (userScopes.some((userScope) => parseScope(userScope.scope).entity === parsedScope.entity)) return true;
        break;
      }
      case ScopeFilterEnum.Manager: {
        if (userScopes.some((userScope) => {
          const parsedUserScope = parseScope(userScope.scope);
          return parsedUserScope.entity === parsedScope.entity && (hasReports && parsedUserScope.filter === ScopeFilterEnum.Manager || ScopeFilterEnum.All === parsedUserScope.filter);
        }))
          return true;
        break;
      }
      case ScopeFilterEnum.All: {
        if (userScopes.some((userScope) => {
          const parsedUserScope = parseScope(userScope.scope);
          return parsedUserScope.entity === parsedScope.entity && parsedUserScope.filter === ScopeFilterEnum.All;
        }))
          return true;
        break;
      }
      default:
        return false;
    }
  }
  return false;
}
export function checkScopes(user, scopes = [], context, matchExactAction = false) {
  var _a;
  if (scopes.length === 0) {
    return true;
  } else if (!user.scopes2) {
    return false;
  } else {
    const localUser = __spreadProps(__spreadValues({}, user), { scopes: (_a = user.scopes2) == null ? void 0 : _a.map((s) => s.scope) });
    if (user.scopes2 && (context == null ? void 0 : context.userId)) {
      const nonExcludedPermissionGroups = user.scopes2.filter(
        (pg) => {
          var _a2, _b, _c;
          return !((_a2 = pg.exclusions) == null ? void 0 : _a2.includes(context.userId)) && (!pg.inclusions || ((_b = pg.inclusions) == null ? void 0 : _b.length) === 0 || ((_c = pg.inclusions) == null ? void 0 : _c.includes(context.userId)));
        }
      );
      const nonExcludedScopes = [...new Set(nonExcludedPermissionGroups.flatMap((permission) => permission.scope))];
      localUser.scopes = nonExcludedScopes;
    }
    return !!(scopes == null ? void 0 : scopes.some((scope) => checkScope(localUser, scope, context, matchExactAction)));
  }
}
function checkScope(user, scope, context, matchExactAction = false) {
  if (!user.scopes || user.scopes.length === 0) {
    return false;
  }
  const parsedScope = parseScope(scope);
  return user.scopes.some((userScope) => {
    const parsedUserScope = parseScope(userScope);
    if (parsedUserScope.entity !== parsedScope.entity) {
      return false;
    }
    if (parsedUserScope.filter === ScopeFilterEnum.All) {
      return matchScopes(parsedUserScope, parsedScope, matchExactAction);
    }
    if (parsedUserScope.filter === ScopeFilterEnum.Manager) {
      return checkManagerScope(user, parsedUserScope, parsedScope, context, matchExactAction);
    }
    if (parsedUserScope.filter === ScopeFilterEnum.Owner) {
      return checkOwnerScope(user, parsedUserScope, parsedScope, context, matchExactAction);
    }
    return false;
  });
}
function checkOwnerScope(user, userScope, requiredScope, context, matchExactAction = false) {
  const isOwner = user.userId === (context == null ? void 0 : context.userId);
  return (
    // TODO This check (userScope.filter === ScopeFilterEnum.All) is causing issues with how we are overloading
    // `read:all` scopes to mean non-admin access.
    matchScopes(userScope, requiredScope, matchExactAction) && (isOwner || userScope.filter === ScopeFilterEnum.All)
  );
}
function checkManagerScope(user, userScope, requiredScope, context, matchExactAction = false) {
  var _a;
  const isManagerOfUser = (context == null ? void 0 : context.userId) && ((_a = user.reports) == null ? void 0 : _a.includes(context.userId));
  return matchScopes(userScope, requiredScope, matchExactAction) && (isManagerOfUser || userScope.filter === ScopeFilterEnum.All);
}
function matchScopes(userScope, requiredScope, matchExactAction = false) {
  const matchEntity = userScope.entity === requiredScope.entity;
  const matchAction = !matchExactAction && userScope.action === ScopeActionEnum.ReadWrite || userScope.action === requiredScope.action;
  const matchFilter = userScope.filter === ScopeFilterEnum.All || userScope.filter === ScopeFilterEnum.Manager || userScope.filter === requiredScope.filter;
  return matchEntity && matchAction && matchFilter;
}
export function parseScope(scope) {
  const scopeParts = scope.split(":");
  if (!scopeParts.length) {
    throw new Error("Invalid scope");
  }
  if (scopeParts.length === 1) {
    return {
      entity: scopeParts[0],
      action: ScopeActionEnum.ReadWrite,
      filter: ScopeFilterEnum.Owner
    };
  }
  if (scopeParts.length === 2) {
    const actionOrFilter = `:${scopeParts[1]}`;
    let action, filter;
    if (Object.values(ScopeActionEnum).includes(actionOrFilter)) {
      action = actionOrFilter;
      filter = ScopeFilterEnum.Owner;
    } else {
      action = ScopeActionEnum.ReadWrite;
      filter = actionOrFilter;
    }
    return {
      entity: scopeParts[0],
      action,
      filter
    };
  }
  return {
    entity: scopeParts[0],
    action: `:${scopeParts[1]}`,
    filter: `:${scopeParts[2]}`
  };
}
export function checkIsManagerOrAdmin(user, scopes) {
  const isAdmin = checkScopes(user, scopes, { userId: user.userId });
  if (isAdmin) return true;
  let isManager = false;
  if (user && user.reports.length > 0) {
    isManager = checkScopes(user, scopes, { userId: user.reports[0] });
  }
  return isManager;
}
