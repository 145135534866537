"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import {
  EditUserPayrollPage
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll.page";
export const EditPayrollRecordDrawer = ({
  isOpen,
  onOpen = () => {
  },
  close,
  userId,
  payrollRecord,
  onUpdateStarted,
  onUpdateFinished,
  mode
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen: close != null ? close : onOpen, children: isOpen && userId ? /* @__PURE__ */ jsx(
    EditUserPayrollPage,
    {
      userId,
      record: payrollRecord,
      close,
      isUserUpdating: false,
      mode,
      requireReason: !!payrollRecord,
      onUpdateStarted,
      onUpdateFinished
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
