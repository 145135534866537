"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PersonalTask } from "@/v2/feature/task/pages/personal/components/personal-task.component";
import { RootStyle } from "@/v2/styles/root.styles";
export const UserTasksPage = ({ userId }) => {
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState(false);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Task" }) }),
    /* @__PURE__ */ jsx(
      PersonalTask,
      {
        userId,
        setIsTaskFormModalOpen,
        isTaskFormModalOpen,
        reach: "userProfile"
      }
    )
  ] });
};
