"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Stack, SwipeableDrawer, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AccountingConfirmDoneDrawer = ({ open, onClose, markAsSent }) => {
  const [confirmed, setConfirmed] = useState(false);
  const [markingAsSent, setMarkingAsSent] = useState(false);
  const [showMessage] = useMessage();
  const performMarkAsSent = useCallback(() => __async(void 0, null, function* () {
    setMarkingAsSent(true);
    try {
      yield markAsSent();
      onClose();
    } catch (error) {
      showMessage(`Failed to mark accounting journal as sent. ${nestErrorMessage(error)}`, "error");
      setMarkingAsSent(false);
    }
  }), [markAsSent, onClose, showMessage]);
  useEffect(() => {
    if (open) setConfirmed(false);
  }, [open]);
  return /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", onClose, onOpen: () => {
  }, open, children: /* @__PURE__ */ jsx(Stack, { sx: { width: "440px" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { p: spacing.pad40, gap: spacing.g20 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Accounting" }),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "By marking Accounting journal as sent you are confirming that a file has been uploaded to your accounting software or it was shared with an accountant outside of Zelt." }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: "I confirm that the accounting journal has been reported outside of Zelt.",
        value: confirmed,
        disabled: markingAsSent,
        onChange: (_, checked) => setConfirmed(checked),
        autoFocus: true,
        sx: { alignItems: "baseline" }
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Mark as sent",
        onClick: performMarkAsSent,
        disabled: !confirmed,
        loading: markingAsSent,
        fullWidth: true,
        sizeVariant: "large",
        colorVariant: "primary",
        style: { marginTop: "40px" }
      }
    )
  ] }) }) });
};
