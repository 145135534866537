"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack, SwipeableDrawer, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as StarFull } from "@/images/side-bar-icons/StarFull.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortNumeric } from "@/v2/components/table/table-sorting.util";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints
} from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { GlobalPayrollPayCodeSeederDrawer } from "@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary-paycode-seeder-drawer.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { COUNTRY_ISO_CODE_MAPPING } from "@/v2/infrastructure/country/country.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminSalaryPaycodeSeedersPage = ({ pageConfig }) => {
  const [countryCode, setCountryCode] = useState("");
  const [editingCode, setEditingCode] = useState(null);
  const [showMessage] = useMessage();
  const { data: paycodeSeeders, mutate: refreshPaycodeSeeders } = useApiClient(
    GlobalPayrollEndpoints.getPaycodeSeedersAsSuperAdmin(),
    {
      suspense: false
    }
  );
  const [getPaycodeSeedersForCountry, countryHasPaycodeSeeders] = useMemo(() => {
    const map = new Map(paycodeSeeders == null ? void 0 : paycodeSeeders.items.map((p) => [p.countryCode.toLowerCase(), p.paycodes]));
    return [
      (countryCode2) => {
        var _a;
        return (_a = map.get(countryCode2.toLowerCase())) != null ? _a : [];
      },
      (countryCode2) => map.has(countryCode2.toLowerCase())
    ];
  }, [paycodeSeeders == null ? void 0 : paycodeSeeders.items]);
  const countryOptions = useMemo(() => {
    return [
      { label: "Default", value: "" },
      ...Object.entries(COUNTRY_ISO_CODE_MAPPING).sort((a, b) => a[0].localeCompare(b[0])).map(([name, countryCode2]) => ({
        label: name,
        value: countryCode2,
        icon: countryHasPaycodeSeeders(countryCode2) ? /* @__PURE__ */ jsx(StarFull, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Fragment, {})
      }))
    ];
  }, [countryHasPaycodeSeeders]);
  const columns = useMemo(
    () => [
      { id: "code", header: () => "code", accessorFn: (x) => x, cell: (c) => c.row.original.code },
      { id: "name", header: () => "name", accessorFn: (x) => x, cell: (c) => c.row.original.name },
      { id: "credit", header: () => "credit", accessorFn: (x) => x, cell: (c) => c.row.original.credit },
      { id: "debit", header: () => "debit", accessorFn: (x) => x, cell: (c) => c.row.original.debit },
      {
        id: "required",
        header: () => "required",
        accessorFn: (x) => x,
        cell: (c) => c.row.original.required.toString()
      },
      {
        id: "order",
        header: () => "order",
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.order),
        cell: (c) => c.row.original.order
      },
      {
        id: "formula",
        header: () => "formula",
        accessorFn: (x) => x,
        cell: (c) => /* @__PURE__ */ jsx("div", { style: { whiteSpace: "pre-line", fontFamily: "monospace" }, children: c.row.original.formula })
      },
      {
        id: "actions",
        header: () => "",
        accessorFn: (x) => x,
        cell: (c) => /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            disabled: !!editingCode,
            onClick: () => setEditingCode(c.row.original),
            style: { marginLeft: "auto" },
            children: "Edit"
          }
        )
      }
    ],
    [editingCode]
  );
  const closePaycodeDrawerAndRefresh = useCallback(() => {
    setEditingCode(null);
    refreshPaycodeSeeders == null ? void 0 : refreshPaycodeSeeders();
  }, [refreshPaycodeSeeders]);
  const setPaycodesForCountry = useCallback(
    (destinationCountryCode, paycodes) => __async(void 0, null, function* () {
      if (!destinationCountryCode) return;
      if (!paycodes) return;
      try {
        yield GlobalPayrollAPI.updatePaycodeSeedersAsSuperAdmin(destinationCountryCode, paycodes);
      } catch (e) {
        showMessage(`Failed to save paycode seeders. ${nestErrorMessage(e)}`, "error");
        return;
      }
      refreshPaycodeSeeders == null ? void 0 : refreshPaycodeSeeders();
    }),
    [refreshPaycodeSeeders, showMessage]
  );
  const copyDefaultPaycodes = useCallback(
    (destinationCountryCode) => __async(void 0, null, function* () {
      yield setPaycodesForCountry(destinationCountryCode, getPaycodeSeedersForCountry(""));
    }),
    [getPaycodeSeedersForCountry, setPaycodesForCountry]
  );
  const deleteAllPaycodes = useCallback(
    (destinationCountryCode) => __async(void 0, null, function* () {
      yield setPaycodesForCountry(destinationCountryCode, []);
    }),
    [setPaycodesForCountry]
  );
  const selectedCountryOption = useMemo(() => countryOptions.find((c) => c.value === countryCode), [
    countryCode,
    countryOptions
  ]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Salary" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: pageConfig.header.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { secondLevel: true, children: /* @__PURE__ */ jsxs(Stack, { sx: { gap: "40px" }, children: [
      /* @__PURE__ */ jsxs(Stack, { children: [
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "countryCode",
            label: "Country",
            value: countryCode,
            onChange: (e) => setCountryCode(e.target.value),
            options: countryOptions,
            sx: { maxWidth: "400px" }
          }
        ),
        countryCode && selectedCountryOption && !countryHasPaycodeSeeders(countryCode) && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "textUnderline",
            disabled: !!editingCode,
            onClick: () => copyDefaultPaycodes(countryCode),
            children: `Initialise ${selectedCountryOption.label} with default paycodes`
          }
        ),
        countryCode && selectedCountryOption && countryHasPaycodeSeeders(countryCode) && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "textUnderline",
            disabled: !!editingCode,
            onClick: () => deleteAllPaycodes(countryCode),
            children: `Delete all paycodes for ${selectedCountryOption.label}`
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Stack, { children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            style: { marginLeft: "auto" },
            disabled: !!editingCode,
            onClick: () => setEditingCode("new"),
            children: "Add paycode"
          }
        ),
        /* @__PURE__ */ jsx(
          BasicTable,
          {
            initialSort: [{ id: "order", desc: false }],
            rowData: getPaycodeSeedersForCountry(countryCode),
            columnData: columns
          }
        )
      ] })
    ] }) }),
    /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open: !!editingCode, onClose: () => setEditingCode(null), onOpen: () => {
    }, children: /* @__PURE__ */ jsx(Stack, { sx: { width: "400px", padding: "20px" }, children: editingCode && /* @__PURE__ */ jsx(
      GlobalPayrollPayCodeSeederDrawer,
      {
        countryCode,
        paycode: editingCode === "new" ? null : editingCode,
        paycodes: getPaycodeSeedersForCountry(countryCode),
        onPaycodeDeleted: () => closePaycodeDrawerAndRefresh(),
        onPaycodeSaved: () => closePaycodeDrawerAndRefresh()
      }
    ) }) })
  ] });
};
