"use strict";
export const StudentLoanValues = [
  { value: "None", label: "None" },
  { value: "PlanOne", label: "Plan One" },
  { value: "PlanTwo", label: "Plan Two" },
  { value: "PlanFour", label: "Plan Four" }
];
export const CalculationMethodStandard = "Cumulative";
export const CalculationMethodMonth1 = "Month 1";
export const CalculationMethodsValues = [
  { value: CalculationMethodStandard, label: CalculationMethodStandard },
  { value: CalculationMethodMonth1, label: CalculationMethodMonth1 }
];
export const StarterDeclarationA = {
  value: "A",
  label: "A",
  details: "Has not received any taxable income or support in current tax year"
};
export const StarterDeclarationB = {
  value: "B",
  label: "B",
  details: "Has received taxable income or support in current tax year and this is the only job"
};
export const StarterDeclarationC = {
  value: "C",
  label: "C",
  details: "Has another job or receives a State or Occupational Pension"
};
export const StarterDeclarationValues = [StarterDeclarationA, StarterDeclarationB, StarterDeclarationC];
export const Yes = "Yes";
export const No = "No";
export const YesNoValues = [
  { value: Yes, label: Yes },
  { value: No, label: No }
];
export const NITableValues = [
  {
    value: "A",
    label: "A",
    details: "All Employees not assigned to a different code"
  },
  {
    value: "B",
    label: "B",
    details: "Married women and widows entitled to pay reduced National Insurance"
  },
  {
    value: "C",
    label: "C",
    details: "Employees over the State Pension age"
  },
  {
    value: "H",
    label: "H",
    details: "Apprentice under 25"
  },
  {
    value: "J",
    label: "J",
    details: "Employees who can defer National Insurance because they're already paying it in another job"
  },
  {
    value: "M",
    label: "M",
    details: "Employees under 21"
  },
  {
    value: "X",
    label: "X",
    details: "Employees who don't have to pay National Insurance, for example because they're under 16"
  },
  {
    value: "Z",
    label: "Z",
    details: "Employees under 21 who can defer NI because they're already paying it in another job"
  }
];
