"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Box, ListItemIcon } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import Lottie from "lottie-react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { Typography } from "@/v2/components/typography/typography.component";
import { getActiveSub } from "@/v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util";
import { getRolesByDomain, getSidebarConfig } from "@/v2/feature/app-layout/features/v2/sidebar-config.util";
import { NavItemStyle } from "@/v2/styles/list-button.styles";
const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
`;
export const NavItem = ({
  item,
  setActiveParent,
  activeParent,
  setShowSecondMenu,
  setActiveRole,
  user
}) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { pathname } = useLocation();
  const routerHistory = useHistory();
  const { isHidden, stub, path, hasChildren, title, loopAnimation, loopFilledAnimation } = item;
  const lottieRef1 = useRef(null);
  const lottieRef2 = useRef(null);
  useEffect(() => {
    var _a, _b;
    (_a = lottieRef1.current) == null ? void 0 : _a.setSpeed(1.3);
    (_b = lottieRef2.current) == null ? void 0 : _b.setSpeed(1.3);
  }, []);
  const handleItemClick = () => {
    setActiveParent(stub);
    const roles = getRolesByDomain(stub, user);
    if (roles && roles.length > 0) setActiveRole(roles[0]);
    if (!hasChildren && path) {
      handleDirectNavigation(path);
    } else if (roles && roles.length > 0) {
      handleNestedNavigation(stub, roles[0]);
    }
  };
  const handleDirectNavigation = (path2) => {
    setShowSecondMenu(false);
    routerHistory.push(path2);
  };
  const handleNestedNavigation = (stub2, role) => {
    var _a;
    const config = getSidebarConfig(user, role, polyglot, !!state.showHiddenFeatures);
    const targetItem = config.find((c) => c.stub === stub2);
    const firstChild = (_a = targetItem == null ? void 0 : targetItem.subItems) == null ? void 0 : _a.find((s) => !s.isHidden);
    if (firstChild == null ? void 0 : firstChild.hasChildren) {
      handleChildWithSubMenu(firstChild);
    } else if (firstChild == null ? void 0 : firstChild.path) {
      setShowSecondMenu(true);
      routerHistory.push(firstChild == null ? void 0 : firstChild.path);
    }
  };
  const handleChildWithSubMenu = (firstChild) => {
    var _a;
    setShowSecondMenu(true);
    const firstSubMenu = (_a = firstChild.subMenu) == null ? void 0 : _a.find((a) => !a.isHidden && a.path && !a.hasChildren);
    if (firstSubMenu && firstSubMenu.path) routerHistory.push(firstSubMenu.path);
  };
  if (isHidden) return null;
  return /* @__PURE__ */ jsxs(
    NavItemStyle,
    {
      onClick: handleItemClick,
      component: RouterLink,
      to: item.path,
      onMouseOver: () => {
        var _a, _b;
        (_a = lottieRef1.current) == null ? void 0 : _a.play();
        (_b = lottieRef2.current) == null ? void 0 : _b.play();
      },
      onMouseEnter: () => {
        var _a, _b;
        (_a = lottieRef1.current) == null ? void 0 : _a.play();
        (_b = lottieRef2.current) == null ? void 0 : _b.play();
      },
      onMouseLeave: () => {
        var _a, _b;
        (_a = lottieRef1.current) == null ? void 0 : _a.stop();
        (_b = lottieRef2.current) == null ? void 0 : _b.stop();
      },
      children: [
        /* @__PURE__ */ jsxs(ListItemIcon, { sx: { display: "flex", justifyContent: "center", padding: 0 }, children: [
          /* @__PURE__ */ jsx(
            LottieContainer,
            {
              style: {
                display: activeParent === stub || path && getActiveSub(pathname, path) ? "flex" : "none"
              },
              children: /* @__PURE__ */ jsx(
                Lottie,
                {
                  lottieRef: lottieRef2,
                  animationData: loopFilledAnimation,
                  loop: true,
                  autoplay: false,
                  rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
                }
              )
            }
          ),
          /* @__PURE__ */ jsx(
            LottieContainer,
            {
              style: {
                display: activeParent === stub || path && getActiveSub(pathname, path) ? "none" : "flex"
              },
              children: /* @__PURE__ */ jsx(
                Lottie,
                {
                  lottieRef: lottieRef1,
                  animationData: loopAnimation,
                  loop: true,
                  autoplay: false,
                  rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
                }
              )
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Typography, { variant: activeParent === stub || path && getActiveSub(pathname, path) ? "title5" : "captionSmall", children: title })
      ]
    },
    stub
  );
};
export const NavigationItem = ({
  navConfig,
  setActiveParent,
  activeParent,
  setShowSecondMenu,
  setActiveRole
}) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  return /* @__PURE__ */ jsx(Box, { children: navConfig.map((list) => /* @__PURE__ */ jsx(
    NavItem,
    {
      item: list,
      setActiveParent,
      activeParent,
      setShowSecondMenu,
      setActiveRole,
      user
    },
    list.title
  )) });
};
