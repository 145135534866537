"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { SUPER_ADMIN_SALARY_PAYCODE_SEEDERS_ROUTE, SUPER_ADMIN_SALARY_ROUTE } from "@/lib/routes";
import { SuperAdminSalaryPaycodeSeedersPage } from "@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary-paycode-seeders.page";
import { SuperAdminSalaryPage } from "@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary.page";
const PAGE_CONFIG = {
  header: {
    tabs: [
      { label: "Schedule migration", value: SUPER_ADMIN_SALARY_ROUTE, scopes: ["payroll"] },
      { label: "Paycode seeders", value: SUPER_ADMIN_SALARY_PAYCODE_SEEDERS_ROUTE, scopes: ["payroll"] }
    ]
  }
};
export const SuperAdminSalaryRouter = ({ companies }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_SALARY_PAYCODE_SEEDERS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminSalaryPaycodeSeedersPage, { pageConfig: PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_SALARY_ROUTE, exact: true, children: /* @__PURE__ */ jsx(SuperAdminSalaryPage, { companies, pageConfig: PAGE_CONFIG }) })
  ] });
};
