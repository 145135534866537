"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const BadgeLoaderHeader = () => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g40, height: "50px" }, children: [
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          display: "flex",
          alignItems: "center",
          gap: spacing.g10,
          height: "100%",
          maxWidth: "210px",
          width: "100%"
        },
        children: [
          /* @__PURE__ */ jsx(SkeletonLoader, { variant: "circular", width: "40px", height: "40px", sx: { background: themeColors.Background } }),
          /* @__PURE__ */ jsxs(
            "div",
            {
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: spacing.g5,
                height: "100%"
              },
              children: [
                /* @__PURE__ */ jsx(
                  SkeletonLoader,
                  {
                    variant: "rectangular",
                    width: "30%",
                    height: "16px",
                    sx: { background: themeColors.Background, borderRadius: radius.br10 }
                  }
                ),
                /* @__PURE__ */ jsx(
                  SkeletonLoader,
                  {
                    variant: "rectangular",
                    width: "80%",
                    height: "12px",
                    sx: { background: themeColors.Background, borderRadius: radius.br10 }
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(BadgeLoaderItem, {}),
    /* @__PURE__ */ jsx(BadgeLoaderItem, {}),
    /* @__PURE__ */ jsx(BadgeLoaderItem, {})
  ] });
};
const BadgeLoaderItem = () => {
  return /* @__PURE__ */ jsxs(
    "div",
    {
      style: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: spacing.g5,
        height: "100%",
        maxWidth: "50px",
        width: "100%"
      },
      children: [
        /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "100%",
            height: "16px",
            sx: { background: themeColors.Background, borderRadius: radius.br10 }
          }
        ),
        /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "100%",
            height: "12px",
            sx: { background: themeColors.Background, borderRadius: radius.br10 }
          }
        )
      ]
    }
  );
};
