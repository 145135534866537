"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { EditAttendanceSettingsDrawer } from "@v2/feature/attendance/company/components/edit-attendance-settings-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { RootStyle } from "@v2/styles/root.styles";
import {
  detailSx,
  detailTermSx,
  detailValueSx,
  parentContainerSx,
  responsiveParentCardSx,
  smallParentCardSx
} from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { useLocation } from "react-router-dom";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ATTENDANCE_COMPANY_ROUTE } from "@/lib/routes";
const getFieldToDay = (field, polyglot) => {
  switch (field) {
    case "workingMonday":
      return polyglot.t("AttendanceCompanySettingsOtherPage.Monday");
    case "workingTuesday":
      return polyglot.t("AttendanceCompanySettingsOtherPage.Tuesday");
    case "workingWednesday":
      return polyglot.t("AttendanceCompanySettingsOtherPage.Wednesday");
    case "workingThursday":
      return polyglot.t("AttendanceCompanySettingsOtherPage.Thursday");
    case "workingFriday":
      return polyglot.t("AttendanceCompanySettingsOtherPage.Friday");
    case "workingSaturday":
      return polyglot.t("AttendanceCompanySettingsOtherPage.Saturday");
    case "workingSunday":
      return polyglot.t("AttendanceCompanySettingsOtherPage.Sunday");
    default:
      return field;
  }
};
export const AttendanceCompanySettingsOtherPage = () => {
  const { polyglot } = usePolyglot();
  const { data: attendanceSettings, isLoading, mutate: refreshAttendanceSettings } = useApiClient(AttendanceEndpoints.getCompanyAttendanceSettings(), {
    suspense: false
  });
  const routerLocation = useLocation();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, []);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshAttendanceSettings) yield refreshAttendanceSettings();
  }), [refreshAttendanceSettings]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: polyglot.t("SettingsRouterPageConfig.other") }),
          !edit && /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setEdit((prev) => !prev), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
        ] }),
        showBack: routerLocation.pathname.includes(ATTENDANCE_COMPANY_ROUTE),
        backPath: ATTENDANCE_COMPANY_ROUTE
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: loading || isLoading, children: /* @__PURE__ */ jsxs(Box, { sx: parentContainerSx, children: [
      /* @__PURE__ */ jsx(Box, { sx: responsiveParentCardSx, children: attendanceSettings && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10 }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, detailSx), { mb: spacing.m10 }), children: [
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, detailTermSx), {
                whiteSpace: "nowrap"
              }),
              children: polyglot.t("AttendanceCompanySettingsOtherPage.WeeklyHours")
            }
          ),
          /* @__PURE__ */ jsx(Typography, { sx: detailValueSx, children: convertMinutesToClockHours(attendanceSettings.weeklyMinutes, polyglot) })
        ] }, "earlyCap"),
        /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, detailSx), { mb: spacing.m10 }), children: [
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, detailTermSx), {
                whiteSpace: "nowrap"
              }),
              children: polyglot.t("AttendanceCompanySettingsOtherPage.WorkingDays")
            }
          ),
          /* @__PURE__ */ jsx(Typography, { sx: detailValueSx, children: [
            "workingMonday",
            "workingTuesday",
            "workingWednesday",
            "workingThursday",
            "workingFriday",
            "workingSaturday",
            "workingSunday"
          ].filter((field) => attendanceSettings[field]).map((field) => getFieldToDay(field, polyglot)).join(", ") })
        ] }, "earlyCap"),
        /* @__PURE__ */ jsx(
          EditAttendanceSettingsDrawer,
          {
            isOpen: edit,
            setIsOpen: setEdit,
            attendanceSettings,
            refresh
          }
        )
      ] }) }),
      /* @__PURE__ */ jsx(Box, { sx: smallParentCardSx })
    ] }) })
  ] });
};
