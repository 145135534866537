"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayItemAPI } from "@v2/feature/payroll/features/pay-item/pay-item.api";
import { round2Digits } from "@v2/util/number.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { ScopesControl } from "@/component/widgets/Scopes";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
export const UpdateUpcomingPayItemDrawer = ({ isOpen, setIsOpen, oneOffPayment, refresh, onClose }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(UpdateUpcomingPayItemDrawerContent, { oneOffPayment, refresh, setIsOpen }) });
};
export const UpdateUpcomingPayItemDrawerContent = ({
  oneOffPayment,
  refresh,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!oneOffPayment.id) {
        return;
      }
      const data = {
        date: values.date,
        payCode: values.payCode,
        description: values.description,
        amount: Number(values.amount),
        multiplier: Number(values.multiplier)
      };
      try {
        setLoading(true);
        yield PayItemAPI.updateUpcomingOneOffPayItem(oneOffPayment.id, oneOffPayment.userId, data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [oneOffPayment, refresh, setIsOpen, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      date: (_a = oneOffPayment == null ? void 0 : oneOffPayment.date) != null ? _a : null,
      payCode: (_b = oneOffPayment.payCode) != null ? _b : null,
      description: (_c = oneOffPayment == null ? void 0 : oneOffPayment.description) != null ? _c : null,
      amount: (_d = oneOffPayment == null ? void 0 : oneOffPayment.amount) != null ? _d : null,
      multiplier: (_e = oneOffPayment == null ? void 0 : oneOffPayment.multiplier) != null ? _e : null
    },
    validationSchema: yup.object({
      date: yup.date().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      description: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      amount: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired(),
      payCode: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      multiplier: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired()
    }),
    onSubmit
  });
  const finalAmount = useMemo(() => {
    if (Number.isNaN(formik.values.amount) || Number.isNaN(formik.values.multiplier)) return 0;
    return round2Digits(Number(formik.values.amount) * Number(formik.values.multiplier));
  }, [formik.values.amount, formik.values.multiplier]);
  const deleteOneOffPayment = useCallback(() => __async(void 0, null, function* () {
    if (!oneOffPayment.id) {
      showMessage("This payment cannot be deleted.", "error");
      return;
    }
    try {
      setLoading(true);
      yield PayItemAPI.deleteUpcomingPayItem(oneOffPayment.userId, oneOffPayment.id);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoading(false);
    }
  }), [oneOffPayment, refresh, setIsOpen, showMessage, polyglot]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Update upcoming pay item" }),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["payroll:all"], context: { userId: oneOffPayment.userId }, children: /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: deleteOneOffPayment, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: oneOffPayment.userId, sx: { mt: spacing.m5 }, nameSx: __spreadValues({}, themeFonts.title4) })
    ] }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "date",
        label: polyglot.t("General.date"),
        onChange: (value) => {
          formik.setFieldValue("date", value);
        },
        value: formik.values.date,
        error: formik.touched.date && !!formik.errors.date,
        helperText: formik.touched.date && formik.errors.date
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "payCode",
        label: polyglot.t("PayItemModule.payCode"),
        value: formik.values.payCode,
        onChange: formik.handleChange,
        error: formik.touched.payCode && !!formik.errors.payCode,
        helperText: (_f = formik.touched.payCode && formik.errors.payCode) != null ? _f : " ",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "description",
        label: polyglot.t("General.description"),
        multiline: true,
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: (_g = formik.touched.description && formik.errors.description) != null ? _g : " ",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: polyglot.t("PayItemModule.amount"),
        value: formik.values.amount,
        onChange: formik.handleChange,
        error: formik.touched.amount && !!formik.errors.amount,
        helperText: (_h = formik.touched.amount && formik.errors.amount) != null ? _h : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "multiplier",
        label: polyglot.t("PayItemModule.payCodeMultiplier"),
        value: formik.values.multiplier,
        onChange: formik.handleChange,
        error: formik.touched.multiplier && !!formik.errors.multiplier,
        helperText: (_i = formik.touched.multiplier && formik.errors.multiplier) != null ? _i : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("PayItemModule.finalAmount") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: finalAmount })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.update"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
