"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ResultsOverviewQuestionDisplayBar } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-question-display-bar.component";
import { getReviewerTypesChips } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ResultsOverviewByQuestionModal = ({
  selectedQuestion,
  isOpen,
  setIsOpen,
  onClose,
  growthFactors
}) => {
  var _a;
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, widthPercentage: 95, children: /* @__PURE__ */ jsx(
    ResultsOverviewByQuestionModalContent,
    {
      selectedQuestion,
      answers: (_a = selectedQuestion == null ? void 0 : selectedQuestion.answers) != null ? _a : [],
      question: selectedQuestion,
      growthFactors
    }
  ) });
};
const ResultsOverviewByQuestionModalContent = ({
  selectedQuestion,
  answers,
  question,
  growthFactors
}) => {
  if (!selectedQuestion) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g48 }, children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Question" }),
      /* @__PURE__ */ jsx(ResultsOverviewQuestionDisplayBar, { question: selectedQuestion, growthFactors })
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Answers" }),
      answers && answers.map((a) => /* @__PURE__ */ jsx(ReviewAnswerComponent, { answer: a, question }))
    ] })
  ] });
};
const ReviewAnswerComponent = ({
  answer,
  question
}) => {
  var _a, _b;
  const scaleLabel = useMemo(() => {
    var _a2, _b2, _c, _d, _e;
    return answer.answer && question ? `${(_b2 = (_a2 = question.scaleConfig) == null ? void 0 : _a2.points) == null ? void 0 : _b2[answer.answer]} (${((_d = (_c = question.scaleConfig) == null ? void 0 : _c.value) == null ? void 0 : _d[answer.answer]) || 0} out of ${Math.max(...Object.values(((_e = question.scaleConfig) == null ? void 0 : _e.value) || []).map(Number))})` : "NA";
  }, [answer.answer, question]);
  if (!question) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      cells: [
        {
          content: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.g8 }, children: [
            answer.comment ? /* @__PURE__ */ jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: (_a = answer.comment) != null ? _a : "" } }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "No comment provided" }),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
              /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  name: answer.reviewerId ? /* @__PURE__ */ jsx(UserCell, { userId: answer.reviewerId, nameVariant: "caption", nameSx: { color: themeColors.Grey } }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Author hidden" }),
                  backgroundColor: "white",
                  border: "background"
                }
              ),
              /* @__PURE__ */ jsxs(Box, { children: [
                " ",
                getReviewerTypesChips(false)[answer.answerType]
              ] })
            ] })
          ] }),
          gridXs: 8
        },
        {
          content: answer.answer ? /* @__PURE__ */ jsx(Fragment, { children: question.type === QuestionType.ScaleQuestion ? /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: scaleLabel }) : question.type === QuestionType.MultipleAnswer ? /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: answer.answer.split(",").map((ans) => {
            var _a2;
            return (_a2 = question.answerOptions) == null ? void 0 : _a2[ans];
          }).filter(Boolean).join(", ") }) : question.type === QuestionType.SingleAnswer ? /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: ((_b = question.answerOptions) == null ? void 0 : _b[answer.answer]) || "NA" }) : /* @__PURE__ */ jsx(Fragment, {}) }) : /* @__PURE__ */ jsx(Fragment, {}),
          gridXs: 4,
          sx: { display: "flex", justifyContent: "flex-start" }
        }
      ]
    }
  );
};
