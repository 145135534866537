"use strict";
import { themeColors } from "@/v2/styles/colors.styles";
export const borders = {
  light: `1px solid ${themeColors.GreyLight}`,
  veryLight: `0.1px solid ${themeColors.GreyLight}`,
  middle: `1px solid ${themeColors.GreyMiddle}`,
  activeGrey: `1px solid ${themeColors.Grey}`,
  activeBlue: `1px solid ${themeColors.ZeltYellow}`,
  mistake: `1px solid ${themeColors.darkRed}`,
  dark: `1px solid ${themeColors.DarkGrey}`,
  dash: `1px dashed ${themeColors.Grey}`,
  dashHover: `1px dashed ${themeColors.ZeltYellow}`,
  background: `1px solid ${themeColors.Background}`
};
