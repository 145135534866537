"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { getScheduleCreateValidationSchema } from "@v2/feature/attendance/attendance.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE } from "@/lib/routes";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
export const AttendanceSettingsNewScheduleDrawer = ({ isOpen, setIsOpen, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const create = {
          name: values.name
        };
        const scheduleId = yield AttendanceAPI.createAttendanceSchedule(create);
        yield refresh();
        setIsOpen(false);
        history.push(generatePath(SETTINGS_ATTENDANCE_SCHEDULE_GENERAL_ROUTE, { scheduleId }));
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    }),
    [history, showMessage, refresh, setIsOpen, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: getScheduleCreateValidationSchema(polyglot),
    onSubmit
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceCompanySettingsAttendanceSchedulesPage.new") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("ScheduleGeneralForm.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " "
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen2) => setUpgradeModalOpen(isOpen2),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) }) });
};
