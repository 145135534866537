"use strict";
export const UserImportResultTabsLabels = [
  "Basic",
  "Details",
  "Family",
  "About",
  "Role",
  "Contract",
  "Lifecycle",
  "Salary",
  "Bank account",
  "Equity",
  "Address",
  // 'Right to work', - being hidden as Zinc integration is underway
  "Emergency"
];
export var InUseByTypes = /* @__PURE__ */ ((InUseByTypes2) => {
  InUseByTypes2["Email"] = "email";
  InUseByTypes2["Site"] = "site";
  InUseByTypes2["Inventory"] = "inventory";
  return InUseByTypes2;
})(InUseByTypes || {});
