"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE,
  REVIEWS_ME_INVITE_ROUTE,
  REVIEWS_ME_RESULT_DETAIL_ROUTE,
  REVIEWS_ME_RESULT_ROUTE,
  REVIEWS_ME_ROUTE
} from "@/lib/routes";
import { ReviewAnswerSubmissionPage } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/review-answer-submission.page";
import { ReviewCycleDetailResultsMeRouter } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-router/me/review-cycle-detail-results-me.router";
import { ReviewPersonalEntries } from "@/v2/feature/growth/reviews/features/review-personal/review-entry/review-personal-entries.page";
import { ReviewPersonalResultListPage } from "@/v2/feature/growth/reviews/features/review-personal/review-result/review-personal-result-list.page";
export const ReviewsMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews"], context, path: REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE, exact: true, children: /* @__PURE__ */ jsx(ReviewAnswerSubmissionPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews"], context, path: REVIEWS_ME_RESULT_DETAIL_ROUTE, children: /* @__PURE__ */ jsx(ReviewCycleDetailResultsMeRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews"], context, path: REVIEWS_ME_RESULT_ROUTE, children: /* @__PURE__ */ jsx(ReviewPersonalResultListPage, { userId: globalState.user.userId }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews"], context, path: REVIEWS_ME_INVITE_ROUTE, children: /* @__PURE__ */ jsx(ReviewPersonalEntries, { userId: globalState.user.userId }) }),
    /* @__PURE__ */ jsx(Redirect, { from: REVIEWS_ME_ROUTE, to: REVIEWS_ME_INVITE_ROUTE })
  ] });
};
