"use strict";
export const mapPackageDetailsToString = (packageDetails, polyglot) => {
  return Object.keys(packageDetails).map((key) => polyglot.t(`IdCheckPackage.id.${key}`).replace(" check", "")).join(", ");
};
export const getIndividualCheckOptions = (polyglot) => {
  return [
    { value: "addressCheck", label: polyglot.t("IdCheckPackage.id.addressCheck") },
    { value: "creditCheck", label: polyglot.t("IdCheckPackage.id.creditCheck") },
    { value: "criminalCheck", label: polyglot.t("IdCheckPackage.id.criminalCheck") },
    { value: "directorshipCheck", label: polyglot.t("IdCheckPackage.id.directorshipCheck") },
    { value: "documentCheck", label: polyglot.t("IdCheckPackage.id.documentCheck") },
    { value: "educationCheck", label: polyglot.t("IdCheckPackage.id.educationCheck") },
    { value: "financialCheck", label: polyglot.t("IdCheckPackage.id.financialCheck") },
    { value: "referenceCheck", label: polyglot.t("IdCheckPackage.id.referenceCheck") },
    { value: "rightToWorkCheck", label: polyglot.t("IdCheckPackage.id.rightToWorkCheck") },
    { value: "sanctionsCheck", label: polyglot.t("IdCheckPackage.id.sanctionsCheck") },
    { value: "selfCertificationCheck", label: polyglot.t("IdCheckPackage.id.selfCertificationCheck") }
  ];
};
