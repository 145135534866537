"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DeliveryMethod, DevicePossessionType } from "@/v2/feature/device/device.interface";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const DeviceDeliverySchema = Yup.object().shape({
  deliveryDate: Yup.string().test(dateFieldTest).required("Choose a delivery date"),
  destination: Yup.string().trim().required("Choose a destination region"),
  address: Yup.string().trim().required("Enter a delivery address")
});
export const DeviceShippingPage = ({
  devicePossession,
  onBackClick,
  onCloseClick,
  onFinish,
  user
}) => {
  var _a;
  const addressFetched = useRef(false);
  const [assigningDevice, setAssigningDevice] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      deliveryDate: (_a = user.startDate) != null ? _a : todaysDateShortISOString(),
      destination: "UK",
      address: ""
    },
    validationSchema: DeviceDeliverySchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setAssigningDevice(true);
        yield DeviceAPI.reassignInventoryDevicePossession(
          devicePossession.id,
          {
            possessionId: user.userId,
            possessionType: DevicePossessionType.User
          },
          {
            date: values.deliveryDate,
            deliveryAddress: values.address,
            deliveryMethod: DeliveryMethod.ShippingToEmployee
          }
        );
        onFinish == null ? void 0 : onFinish();
      } catch (error) {
        showMessage(`Failed to assign device to user. ${nestErrorMessage(error)}`, "error");
        setAssigningDevice(false);
      }
    }),
    validateOnMount: true
  });
  useEffect(() => {
    if (addressFetched.current) return;
    addressFetched.current = true;
    UserAddressAPI.findCurrentByUserId(user.userId).then((address) => {
      if (!address) return;
      formik.setFieldValue(
        "address",
        [address.addressLine1, address.addressLine2, address.city, address.postcode, address.country].filter(Boolean).join(", ")
      );
    });
  }, [formik, user.userId]);
  useEscapeKey(onCloseClick);
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30, position: "absolute", zIndex: 10, inset: 0, background: themeColors.white }, children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", left: spacing.m50 }),
        onClick: onBackClick,
        disabled: assigningDevice,
        children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
      }
    ),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", right: spacing.m50 }),
        onClick: onCloseClick,
        disabled: assigningDevice,
        children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey }))
      }
    ),
    /* @__PURE__ */ jsx(Stack, { sx: { width: "min(400px,90vw)", mx: "auto" }, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.mb10 }), children: "Shipping the device" }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mb: spacing.mb30 }), children: "When and how should this device be shipped?" }),
      /* @__PURE__ */ jsx(Stack, { sx: { overflow: "auto" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g30 }, children: [
        /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            name: "deliveryDate",
            label: "Delivery date",
            onChange: (value) => formik.setFieldValue("deliveryDate", value, true),
            minDate: todaysDateShortISOString(),
            value: formik.values.deliveryDate
          }
        ),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "destination",
            label: "Destination region",
            options: [
              { label: "United Kingdom", value: "UK" },
              { label: "European Union", value: "EU" }
            ],
            value: formik.values.destination,
            onChange: formik.handleChange
          }
        ),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "address",
            label: "Delivery address",
            onChange: formik.handleChange,
            value: formik.values.address,
            autoFocus: true
          }
        ),
        formik.isValid && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Order",
            loading: assigningDevice,
            style: { marginTop: "30px" },
            colorVariant: "primary",
            sizeVariant: "large"
          }
        )
      ] }) })
    ] }) }) })
  ] });
};
