"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@v2/components/divider.component";
import { formatMoney, formatPercentage } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Plan, Product, ProductPlanMapToLabel } from "@/models/subscription.model";
const InitialSubscriptionStats = {
  [Product.PEOPLE]: { plan: null, type: Plan.FREE },
  [Product.MONEY]: { plan: null, type: Plan.FREE },
  [Product.APPS]: { plan: null, type: Plan.FREE },
  [Product.DEVICES]: { plan: null, type: Plan.FREE },
  totalCost: 0,
  noOfActiveUsers: 1,
  hasDiscounts: false
};
const getDiscountPercentageFromPlan = (plan) => {
  const discountRatio = plan && plan.subscriptionCost && plan.totalDiscount ? plan.totalDiscount / plan.subscriptionCost : 0;
  if (discountRatio === 0) return void 0;
  return formatPercentage(100 * discountRatio, 0);
};
const getSubscriptionStats = (subscriptionsBillingStats) => {
  return Object.keys(subscriptionsBillingStats).reduce(
    (acc, key) => {
      var _a, _b, _c, _d;
      const activePlan = subscriptionsBillingStats ? (_b = (_a = subscriptionsBillingStats[Number(key)].find((plan) => plan.planPrice > 0)) != null ? _a : subscriptionsBillingStats[Number(key)][0]) != null ? _b : null : null;
      const activePlanType = activePlan && (activePlan == null ? void 0 : activePlan.planPrice) > 0 ? Plan.PRO : Plan.FREE;
      if (!activePlan) return acc;
      acc = __spreadProps(__spreadValues({}, acc), {
        [key]: {
          plan: activePlan,
          type: activePlanType
        },
        totalCost: acc.totalCost + ((_c = activePlan.subscriptionCost) != null ? _c : 0) - ((_d = activePlan.totalDiscount) != null ? _d : 0),
        noOfActiveUsers: activePlan.noOfActiveUsers > acc.noOfActiveUsers ? activePlan.noOfActiveUsers : acc.noOfActiveUsers,
        hasDiscounts: Boolean(acc.hasDiscounts || activePlan.totalDiscount > 0)
      });
      return acc;
    },
    __spreadValues({}, InitialSubscriptionStats)
  );
};
const TableRow = ({
  column1,
  plan,
  column3,
  column2 = "",
  showDivider = false,
  boldModule = false,
  boldPrice = false
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between",
          height: showDivider ? "50px" : "40px",
          alignItems: "center"
        },
        children: [
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({ width: "40%" }, boldModule ? themeFonts.title4 : themeFonts.caption), children: [
            column1,
            plan && /* @__PURE__ */ jsx(
              Typography,
              {
                component: "span",
                sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                  color: themeColors.Grey,
                  ml: spacing.m5
                }),
                children: plan === Plan.FREE ? "Free" : "Pro"
              }
            )
          ] }),
          column2 && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ width: "40%" }, themeFonts.caption), children: column2 }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ width: "70px" }, boldPrice ? themeFonts.title4 : themeFonts.caption), children: column3 })
        ]
      }
    ),
    showDivider && /* @__PURE__ */ jsx(Divider, {})
  ] });
};
export const SubscriptionsTable = ({ subscriptionsBillingStats }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const subscriptionsStats = subscriptionsBillingStats ? getSubscriptionStats(subscriptionsBillingStats) : __spreadValues({}, InitialSubscriptionStats);
  const activePeoplePlan = subscriptionsStats[Product.PEOPLE].plan;
  const activePeoplePlanType = subscriptionsStats[Product.PEOPLE].type;
  const activeMoneyPlan = subscriptionsStats[Product.MONEY].plan;
  const activeMoneyPlanType = subscriptionsStats[Product.MONEY].type;
  const activeAppsPlan = subscriptionsStats[Product.APPS].plan;
  const activeAppsPlanType = subscriptionsStats[Product.APPS].type;
  const activeDevicesPlan = subscriptionsStats[Product.DEVICES].plan;
  const activeDevicesPlanType = subscriptionsStats[Product.DEVICES].type;
  const totalCost = subscriptionsStats.totalCost;
  const noOfActiveUsers = subscriptionsStats.noOfActiveUsers;
  const hasDiscounts = subscriptionsStats.hasDiscounts;
  const peopleDiscount = getDiscountPercentageFromPlan(activePeoplePlan);
  const moneyDiscount = getDiscountPercentageFromPlan(activeMoneyPlan);
  const appsDiscount = getDiscountPercentageFromPlan(activeAppsPlan);
  const devicesDiscount = getDiscountPercentageFromPlan(activeDevicesPlan);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", height: "40px", alignItems: "center" }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({ width: "40%" }, themeFonts.caption), { color: themeColors.Grey }), children: [
        " ",
        polyglot.t("SubscriptionsTable.module")
      ] }),
      hasDiscounts && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ width: "40%" }, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("SubscriptionsTable.discount") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ width: "70px" }, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.t("SubscriptionsTable.listPrice") })
    ] }),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: ProductPlanMapToLabel(polyglot)[Product.PEOPLE],
        plan: activePeoplePlanType,
        column2: peopleDiscount,
        column3: formatMoney({ amount: (_a = activePeoplePlan == null ? void 0 : activePeoplePlan.planPrice) != null ? _a : 0 }),
        showDivider: true
      }
    ),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: ProductPlanMapToLabel(polyglot)[Product.MONEY],
        plan: activeMoneyPlanType,
        column2: moneyDiscount,
        column3: formatMoney({ amount: (_b = activeMoneyPlan == null ? void 0 : activeMoneyPlan.planPrice) != null ? _b : 0 }),
        showDivider: true
      }
    ),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: ProductPlanMapToLabel(polyglot)[Product.APPS],
        plan: activeAppsPlanType,
        column2: appsDiscount,
        column3: formatMoney({ amount: (_c = activeAppsPlan == null ? void 0 : activeAppsPlan.planPrice) != null ? _c : 0 }),
        showDivider: true
      }
    ),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: ProductPlanMapToLabel(polyglot)[Product.DEVICES],
        plan: activeDevicesPlanType,
        column2: devicesDiscount,
        column3: formatMoney({ amount: (_d = activeDevicesPlan == null ? void 0 : activeDevicesPlan.planPrice) != null ? _d : 0 }),
        showDivider: true
      }
    ),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: polyglot.t("SubscriptionsTable.subscriptionFeePerUser"),
        column3: formatMoney({ amount: totalCost / noOfActiveUsers }),
        boldPrice: true
      }
    ),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: polyglot.t("SubscriptionsTable.activeUsers"),
        column3: `${noOfActiveUsers} user${noOfActiveUsers === 1 ? "" : "s"}`,
        boldPrice: true
      }
    ),
    /* @__PURE__ */ jsx(
      TableRow,
      {
        column1: polyglot.t("SubscriptionsTable.subscriptionFee"),
        column3: formatMoney({ amount: totalCost }),
        boldModule: true,
        boldPrice: true
      }
    )
  ] });
};
