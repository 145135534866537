"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { COLOR_OPTIONS } from "@v2/feature/absence/absence.util";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { COLOR_PICKER_COLORS } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
export const AttendanceSettingsNewJobDrawer = ({
  isOpen,
  setIsOpen,
  job,
  refresh,
  onClose,
  afterClose,
  attendancePayMultipliers
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const payMultiplierOptions = useMemo(
    () => attendancePayMultipliers.map((m) => ({
      value: m.id,
      label: m.name
    })),
    [attendancePayMultipliers]
  );
  const backUpMultiplier = useMemo(
    () => {
      var _a2;
      return (_a2 = attendancePayMultipliers.find((m) => m.value === 1)) != null ? _a2 : attendancePayMultipliers[0];
    },
    [attendancePayMultipliers]
  );
  const deleteJob = useCallback(() => __async(void 0, null, function* () {
    if (!job) return;
    try {
      yield AttendanceAPI.deleteAttendanceJob(job.id);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
  }), [showMessage, polyglot, refresh, job, setIsOpen]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        const data = {
          name: values.name,
          description: values.description,
          color: values.color,
          payMultiplierId: Number(values.payMultiplierId)
        };
        setLoading(true);
        if (job == null ? void 0 : job.id) yield AttendanceAPI.updateAttendanceJob(job.id, data);
        else yield AttendanceAPI.createAttendanceJob(data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    }),
    [showMessage, refresh, setIsOpen, polyglot, job]
  );
  const formik = useFormik({
    initialValues: {
      name: (_a = job == null ? void 0 : job.name) != null ? _a : "",
      description: (_b = job == null ? void 0 : job.description) != null ? _b : "",
      color: (_c = job == null ? void 0 : job.color) != null ? _c : COLOR_PICKER_COLORS[Math.floor(Math.random() * COLOR_PICKER_COLORS.length)],
      payMultiplierId: (_e = (_d = job == null ? void 0 : job.payMultiplier) == null ? void 0 : _d.id) != null ? _e : backUpMultiplier == null ? void 0 : backUpMultiplier.id
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("validation.requiredField")),
      payMultiplierId: yup.number().typeError(polyglot.t("validation.selectValid")).integer(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
      color: yup.string().oneOf(COLOR_PICKER_COLORS, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
      description: yup.string().nullable().notRequired()
    }),
    onSubmit
  });
  useEffect(() => {
    var _a2, _b2;
    if (job) {
      formik.setFieldValue("name", job.name);
      formik.setFieldValue("description", (_a2 = job.description) != null ? _a2 : "");
      formik.setFieldValue(
        "color",
        (_b2 = job.color) != null ? _b2 : COLOR_PICKER_COLORS[Math.floor(Math.random() * COLOR_PICKER_COLORS.length)]
      );
    }
  }, [job]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: job ? polyglot.t("SettingsRouterPageConfig.updateJob") : polyglot.t("SettingsRouterPageConfig.newJob") }),
      job && !["Regular", "Break", "Overtime"].includes(job.name) && /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: deleteJob, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "60px" }, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "color",
          label: polyglot.t("General.color"),
          options: COLOR_OPTIONS,
          value: formik.values.color,
          onChange: formik.handleChange,
          compareValue: formik.values.color,
          error: !!formik.errors.color && formik.touched.color,
          helperText: formik.touched.color && formik.errors.color
        }
      ) }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: polyglot.t("General.name"),
          value: formik.values.name,
          onChange: formik.handleChange,
          error: formik.touched.name && !!formik.errors.name,
          helperText: (_f = formik.touched.name && formik.errors.name) != null ? _f : " "
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "description",
        label: polyglot.t("General.description"),
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: (_g = formik.touched.description && formik.errors.description) != null ? _g : " "
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "payMultiplierId",
        label: polyglot.t("AbsenceModule.payMultiplier"),
        options: payMultiplierOptions,
        value: formik.values.payMultiplierId,
        onChange: formik.handleChange,
        compareValue: formik.values.payMultiplierId,
        error: !!formik.errors.payMultiplierId && formik.touched.payMultiplierId,
        helperText: formik.touched.payMultiplierId && formik.errors.payMultiplierId
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen2) => setUpgradeModalOpen(isOpen2),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) }) });
};
