"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import cover from "@/images/app-empty.svg";
import { ChartColumnSingle } from "@/v2/components/charts/chart-column-single.component";
import { ChartPie } from "@/v2/components/charts/chart-pie.component";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { AppDetailsAPI } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { seriesDataExists } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { AppInsightsPlaceholder } from "@/v2/feature/app-integration/features/app-details/components/app-insights-placeholder.component";
import { AppNameHeader } from "@/v2/feature/app-integration/features/app-details/components/app-name-header.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useLocalStorage } from "@/v2/util/use-local-storage.util";
export const AppInsightsPage = ({ currentUser, appStub, app }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const [showMessage] = useMessage();
  const [loadingKpis, setLoadingKpis] = useState(false);
  const [kpisForApp, setKpisForApp] = useLocalStorage(
    `${appStub}-kpisForApp-${(_a = currentUser == null ? void 0 : currentUser.company) == null ? void 0 : _a.companyId}`,
    {
      companyId: void 0,
      kpi: []
    }
  );
  const gridsForKPIs = useMemo(() => {
    let gridToReturn = [];
    if (!kpisForApp || (kpisForApp == null ? void 0 : kpisForApp.kpi.length) === 0) return /* @__PURE__ */ jsx(Fragment, {});
    kpisForApp.kpi.forEach((eachKPI, index) => {
      var _a2, _b2, _c;
      if (index % 2 !== 0) {
        gridToReturn.push(/* @__PURE__ */ jsx(Grid, { item: true, xs: 0, md: 1, lg: 1 }));
      }
      if (eachKPI.type === "bar") {
        gridToReturn.push(
          /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 5, lg: 5, children: seriesDataExists(eachKPI) ? /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: eachKPI.name }) }),
            /* @__PURE__ */ jsx(ChartColumnSingle, { series: eachKPI.series, categories: (_a2 = eachKPI.categories) != null ? _a2 : [] })
          ] }) : /* @__PURE__ */ jsx(AppInsightsPlaceholder, { title: eachKPI.name }) })
        );
      }
      if (eachKPI.type === "horizontalStackedBar") {
        gridToReturn.push(
          /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 5, lg: 5, children: seriesDataExists(eachKPI) ? /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: eachKPI.name }) }),
            /* @__PURE__ */ jsx(
              ChartColumnSingle,
              {
                series: eachKPI.series,
                categories: (_b2 = eachKPI.categories) != null ? _b2 : [],
                horizontal: true,
                stacked: true
              }
            )
          ] }) : /* @__PURE__ */ jsx(AppInsightsPlaceholder, { title: eachKPI.name }) })
        );
      }
      if (eachKPI.type === "pie") {
        gridToReturn.push(
          /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 5, lg: 5, children: eachKPI.series.length > 0 ? /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: eachKPI.name }),
            /* @__PURE__ */ jsx(ChartPie, { series: eachKPI.series, labels: (_c = eachKPI.labels) != null ? _c : [] })
          ] }) : /* @__PURE__ */ jsx(AppInsightsPlaceholder, { title: eachKPI.name }) })
        );
      }
    });
    return gridToReturn;
  }, [kpisForApp]);
  const fetchData = useCallback(
    (loading = true) => __async(void 0, null, function* () {
      if ((app == null ? void 0 : app.authorised) && (app == null ? void 0 : app.hasKPIs)) {
        try {
          if (loading) setLoadingKpis(true);
          const insightData = yield AppDetailsAPI.getKPIsForApp(appStub);
          setKpisForApp(insightData);
        } catch (error) {
          showMessage("Failed to fetch app insight data", "error");
        } finally {
          if (loading) setLoadingKpis(false);
        }
      }
    }),
    [app == null ? void 0 : app.authorised, app == null ? void 0 : app.hasKPIs, appStub, setKpisForApp, showMessage]
  );
  useEffect(() => {
    fetchData(!kpisForApp || (kpisForApp == null ? void 0 : kpisForApp.kpi.length) === 0 ? true : false);
  }, [app == null ? void 0 : app.hasKPIs, app == null ? void 0 : app.authorised, appStub]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(AppNameHeader, { title: polyglot.t("AppInsightsPage.title"), app }),
        showAction: !(app == null ? void 0 : app.authorised) && currentUser && hasScopes(["apps"], { userId: currentUser.userId })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loadingKpis, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Insights" }),
      /* @__PURE__ */ jsx(Grid, { container: true, spacing: 2, sx: { mt: spacing.mt10 }, children: gridsForKPIs }),
      /* @__PURE__ */ jsx(Box, { children: ((_b = kpisForApp == null ? void 0 : kpisForApp.kpi) == null ? void 0 : _b.length) === 0 && /* @__PURE__ */ jsx(
        EmptyStateComponent,
        {
          header: "No KPIs",
          subheader: "Zelt gives you valuable insights into your app usage",
          detail: "Currently there isn't enough data to provide insights; Please check back later.",
          cover
        }
      ) })
    ] })
  ] });
};
