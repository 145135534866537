"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { HolidayCalendarEndpoints } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { HolidayCalendarGeneralSection } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/pages/holiday-calendar-general-section.component";
import { HolidayCalendarHolidaysSection } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/pages/holiday-calendar-holidays-section.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE,
  SETTINGS_TIME_HOLIDAY_CALENDAR_HOLIDAYS_ROUTE,
  SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE
} from "@/lib/routes";
const getPageConfig = (calendarId, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("General.general"),
          stub: "general",
          path: generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, { calendarId })
        },
        {
          title: polyglot.t("HolidayCalendarModule.calendar"),
          stub: "calendar",
          path: generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_HOLIDAYS_ROUTE, { calendarId })
        }
      ]
    }
  ];
};
export const HolidayCalendarRouter = ({ refreshAll }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const params = useParams();
  const calendarId = params.calendarId;
  const {
    data: holidayCalendar,
    mutate: refreshHolidayCalendar,
    isLoading
  } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendarById(calendarId, true), { suspense: false });
  const refreshCalendar = useCallback(() => __async(void 0, null, function* () {
    if (refreshHolidayCalendar) yield refreshHolidayCalendar();
  }), [refreshHolidayCalendar]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_a = holidayCalendar == null ? void 0 : holidayCalendar.name) != null ? _a : "",
        subtitle: polyglot.t("HolidayCalendarModule.holidayCalendar"),
        pageConfig: getPageConfig(calendarId, polyglot),
        backPath: SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, children: holidayCalendar ? /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        HolidayCalendarGeneralSection,
        {
          holidayCalendar,
          refreshAll: () => __async(void 0, null, function* () {
            yield Promise.all([refreshAll(), refreshCalendar()]);
          })
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_HOLIDAY_CALENDAR_HOLIDAYS_ROUTE, children: /* @__PURE__ */ jsx(HolidayCalendarHolidaysSection, { holidayCalendar, refresh: refreshCalendar }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE })
    ] }) : /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE }) }) })
  ] });
};
