"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ZeltText } from "@/images/zelt-text.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DASHBOARD_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { AccountStatus } from "@/lib/users";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { CachedUsersProvider } from "@/v2/feature/user/context/cached-users.context";
import { UserProfileActionAPI } from "@/v2/feature/user/features/user-profile-action/user-profile-action.api";
import { UserAPI } from "@/v2/feature/user/user.api";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { CloseButton } from "@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component";
import { OnboardingOverview } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/onboarding-overview.page";
import {
  filterPendingOnboardingContracts,
  filterUserOnboardingDocuments
} from "@/v2/feature/user-onboarding/user-onboarding.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
const DOCUMENT_SCOPE_RELATED_ONBOARDING_STEPS = [
  "documents",
  "contracts",
  "contracts_step2",
  "contracts_step3",
  "contracts_step4",
  "contracts_step5"
];
const CURRENTLY_IMPLEMENTED_ONBOARDING_STEPS = [
  "basic",
  "payroll",
  "tasks",
  ...DOCUMENT_SCOPE_RELATED_ONBOARDING_STEPS
];
export const UserOnboardingSummaryPage = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [state, setState] = useState();
  const today = todaysDateShortISOString();
  const getProgress = useCallback(
    (requiredSteps2, missingFields2, missingDocumentCount, incompleteTaskCount, missingContractCount) => {
      let total = 0;
      let completed = 0;
      if (requiredSteps2.includes("basic")) {
        total += 1;
        if (missingFields2.basic.length === 0) {
          completed += 1;
        }
      }
      if (requiredSteps2.includes("payroll")) {
        total += 1;
        if (missingFields2.payroll.length === 0) {
          completed += 1;
        }
      }
      if (requiredSteps2.includes("documents")) {
        total += 1;
        if (missingDocumentCount === 0) {
          completed += 1;
        }
      }
      if (requiredSteps2.includes("tasks")) {
        total += 1;
        if (incompleteTaskCount === 0) {
          completed += 1;
        }
      }
      if (requiredSteps2.some((step) => step.includes("contracts"))) {
        total += 1;
        if (missingContractCount === 0) {
          completed += 1;
        }
      }
      return {
        total,
        pending: total - completed,
        percentComplete: total && Math.trunc(100 * completed / total)
      };
    },
    []
  );
  const finishOnboarding = useCallback(
    (userId) => __async(void 0, null, function* () {
      yield UserProfileActionAPI.activateUser(userId, "onboarding");
      routerHistory.push(DASHBOARD_ROUTE);
    }),
    [routerHistory]
  );
  const refreshState = useCallback(() => __async(void 0, null, function* () {
    try {
      const { user: user2, publicURL: publicURL2 } = yield AuthAPI.getAuthMe();
      if (user2.accountStatus !== AccountStatus.InvitedToOnboard) {
        routerHistory.replace(DASHBOARD_ROUTE);
        return;
      }
      const hasTaskScope = checkScopes(user2, ["task"], { userId: user2.userId });
      const hasDocumentScope = checkScopes(user2, ["documents"], { userId: user2.userId });
      const hasPayrollScope = checkScopes(user2, ["payroll:read"], { userId: user2.userId });
      const [requiredSteps2, missingFields2, lifecycle2, allDocuments, documentTypes2, tasks] = yield Promise.all([
        OnboardingAPI.onboardingSteps().then(
          (steps) => (
            // remove steps not yet implemented
            steps.filter((step) => CURRENTLY_IMPLEMENTED_ONBOARDING_STEPS.includes(step)).filter((step) => !DOCUMENT_SCOPE_RELATED_ONBOARDING_STEPS.includes(step) || hasDocumentScope).filter((step) => step !== "tasks" || hasTaskScope).filter((step) => step !== "payroll" || hasPayrollScope)
          )
        ),
        OnboardingAPI.onboardingMissingFields(),
        UserAPI.getUserLifecycleInfo(user2.userId),
        hasDocumentScope ? DocumentNewAPI.getAllDocumentsByUserId(user2.userId) : Promise.resolve([]),
        DocumentAPI.listDocumentTypes(),
        hasTaskScope ? TaskAPI.listTasks(user2.userId) : Promise.resolve([])
      ]);
      const validDocuments = filterUserOnboardingDocuments(allDocuments);
      const pendingContracts2 = filterPendingOnboardingContracts(
        allDocuments.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      );
      const missingDocuments = validDocuments.filter((d) => !d.fileUuid);
      const missingContract = pendingContracts2.filter((d) => !d.fileUuid);
      const incompleteTasks2 = tasks.filter((t) => t.status !== "Complete");
      const checklistNames2 = [...new Set(tasks.map((t) => {
        var _a, _b;
        return (_b = (_a = t.checklist) == null ? void 0 : _a.name) != null ? _b : "";
      }).filter(Boolean))];
      const { pending, percentComplete } = getProgress(
        requiredSteps2,
        missingFields2,
        missingDocuments.length,
        incompleteTasks2.length,
        missingContract.length
      );
      const userHasStarted = !lifecycle2.startDate || lifecycle2.startDate <= todaysDateShortISOString();
      if (pending <= 0 && userHasStarted) {
        finishOnboarding(user2.userId);
        return;
      }
      setState({
        lifecycle: lifecycle2,
        requiredSteps: requiredSteps2,
        user: user2,
        missingFields: missingFields2,
        documents: validDocuments,
        pendingContracts: pendingContracts2,
        documentTypes: documentTypes2,
        incompleteTasks: incompleteTasks2,
        checklistNames: checklistNames2,
        taskCount: pending,
        progressPercent: percentComplete,
        publicURL: publicURL2
      });
    } catch (error) {
      showMessage(
        polyglot.t("OnboardingByUser.errors.accountStateRetrieveFailed", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, finishOnboarding, getProgress, routerHistory, showMessage]);
  useEffect(() => {
    refreshState();
  }, [refreshState]);
  if (!state) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  const {
    lifecycle,
    progressPercent,
    missingFields,
    documents,
    documentTypes,
    pendingContracts,
    incompleteTasks,
    checklistNames,
    requiredSteps,
    taskCount,
    user,
    publicURL
  } = state;
  return /* @__PURE__ */ jsx(CachedUsersProvider, { children: /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt40, mx: spacing.mx40 }, children: [
    /* @__PURE__ */ jsx(ZeltText, {}),
    /* @__PURE__ */ jsx(
      OnboardingOverview,
      {
        companyName: user.company.name,
        taskCount,
        progressPercent,
        requiredSteps,
        missingFields,
        documents,
        documentTypes,
        pendingContracts,
        incompleteTasks,
        checklistNames,
        startDate: lifecycle.startDate,
        sx: { mt: "100px" },
        userId: user.userId,
        publicURL
      }
    ),
    (!lifecycle.startDate || today >= lifecycle.startDate) && /* @__PURE__ */ jsx(
      CloseButton,
      {
        onClick: () => __async(void 0, null, function* () {
          yield finishOnboarding(user.userId);
        })
      }
    )
  ] }) });
};
