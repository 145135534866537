"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceType } from "@v2/feature/device/device.interface";
import { DeviceTypesValueLabelOptions } from "@v2/feature/device/device.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as FullScreen } from "@/images/side-bar-icons/FullScreen.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE } from "@/lib/routes";
export const StockDeviceEditDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  device,
  refresh
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: device ? /* @__PURE__ */ jsx(
  StockDeviceEditDrawerContent,
  {
    device,
    onAdd: () => __async(void 0, null, function* () {
      yield refresh();
      onClose();
      setIsOpen(false);
    })
  }
) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Device not selected." }) }) });
export const StockDeviceEditDrawerContent = ({ device, onAdd }) => {
  var _a, _b, _c, _d, _e;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      serialNumber: (_a = device.serialNumber) != null ? _a : null,
      internalNotes: (_b = device.internalNotes) != null ? _b : null,
      type: (_c = device.type) != null ? _c : DeviceType.Laptop
    },
    validationSchema: yup.object({
      serialNumber: yup.string().nullable().notRequired(),
      internalNotes: yup.string().nullable().notRequired(),
      type: yup.string().required("Device type is required")
    }),
    onSubmit: (formData) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const update = {
          serialNumber: formData.serialNumber,
          internalNotes: formData.internalNotes,
          type: formData.type
        };
        yield DeviceAPI.updateDeviceByIdAsSuperadmin(device.id, update);
        showMessage("Device successfully updated.", "success");
        yield onAdd();
      } catch (error) {
        showMessage(`Could not update device: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Update stock device" }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          title: "more-details",
          onClick: () => {
            history.push(generatePath(SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE, { deviceId: device.id }));
          },
          sx: tableIconButtonSx,
          children: /* @__PURE__ */ jsx(FullScreen, __spreadValues({}, actionIconSize))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: device.modelName }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: DeviceTypesValueLabelOptions,
        value: (_d = formik.values.type) != null ? _d : void 0,
        compareValue: (_e = formik.values.type) != null ? _e : void 0,
        onChange: formik.handleChange,
        error: formik.touched.type && !!formik.errors.type,
        helperText: formik.touched.type && formik.errors.type
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Serial Number",
        name: "serialNumber",
        value: formik.values.serialNumber,
        onChange: formik.handleChange,
        error: formik.touched.serialNumber && !!formik.errors.serialNumber,
        helperText: formik.touched.serialNumber && formik.errors.serialNumber,
        autoFocus: true,
        clearText: () => formik.setFieldValue("serialNumber", "")
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Internal notes",
        name: "internalNotes",
        value: formik.values.internalNotes,
        onChange: formik.handleChange,
        error: formik.touched.internalNotes && !!formik.errors.internalNotes,
        helperText: formik.touched.internalNotes && formik.errors.internalNotes,
        clearText: () => formik.setFieldValue("internalNotes", "")
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Update",
        fullWidth: true,
        loading,
        onClick: () => formik.handleSubmit(),
        colorVariant: "primary",
        sizeVariant: "medium"
      }
    ) })
  ] }) });
};
