"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class AuthAPI {
  static logout() {
    return __async(this, null, function* () {
      this.authMeResponse = null;
      yield axios.post("/apiv2/auth/logout");
    });
  }
  static signupEmail(signup) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/auth/signup", signup);
    });
  }
  static signupAccountCreation(onboarding) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.post("/apiv2/company/onboarding", onboarding)).data) != null ? _a : [];
    });
  }
  static forgotPassword(payload) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.post("/apiv2/auth/password/forgot", payload)).data) != null ? _a : [];
    });
  }
  static resetPassword(password, token, email) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.post("/apiv2/auth/password/reset", { password, token, email })).data) != null ? _a : [];
    });
  }
  static login(username, password, mfaCode, deviceId) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/auth/login", { username, password, mfaCode, deviceId })).data;
    });
  }
  static ssoSamlLogin(email, idToken, deviceId) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/auth/sso/saml/login", { email, idToken, deviceId })).data;
    });
  }
  static ssoOidcLogin(email, idToken, deviceId) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/auth/sso/oidc/login", { email, idToken, deviceId })).data;
    });
  }
  static ssoCheck(email) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/auth/ssocheck/${email}`)).data;
    });
  }
  static ssoCheckForCompany() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/auth/ssocheck`)).data;
    });
  }
  static mfaCheck() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/auth/mfacheck`)).data;
    });
  }
  static getAuthMe(useCache = true) {
    return __async(this, null, function* () {
      const currentTime = Date.now();
      const lastCallGap = currentTime - this.lastCallTimestamp;
      const isCacheValid = useCache && !!this.authMeResponse || !!this.authMeResponse && lastCallGap < this.cacheDuration;
      if (!isCacheValid) {
        this.authMeResponse = axios.get("/apiv2/auth/me");
        this.lastCallTimestamp = currentTime;
        this.authMeResponse.catch(() => {
          this.authMeResponse = null;
        });
      }
      const response = yield this.authMeResponse;
      return response ? response.data : {};
    });
  }
  static getAccountData() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/users/import/google-accounts")).data;
    });
  }
  static isPasswordSet(email) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/auth/pwdcheck?email=${email}`)).data;
    });
  }
  static isContractRecipientPasswordSet(contractId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/auth/contract/recipient/${contractId}`)).data;
    });
  }
  /**
   * Request a new 2FA code to be emailed to the user (to enable MFA)
   */
  static requestMFAEnableCode() {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/auth/mfa/init")).data;
    });
  }
  /**
   * Enables or disable MFA for the active user.
   *
   * Enabling MFA requires the 2FA code requested using `requestMFAEnableCode()`.
   * Disabling MFA requires the user's current password
   */
  static updateMFA(payload) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/auth/mfa/update", payload);
    });
  }
}
AuthAPI.lastCallTimestamp = 0;
AuthAPI.cacheDuration = 1e3;
AuthAPI.authMeResponse = null;
export class AuthEndpoints {
  static mfaCheck() {
    return {
      url: "/apiv2/auth/mfacheck"
    };
  }
}
