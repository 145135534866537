"use strict";
import { jsx } from "react/jsx-runtime";
import { UserInsuranceDependants } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import AvivaLogo from "@/images/vendors/AvivaSquareLogo.svg";
import AXALogo from "@/images/vendors/AXASquareLogo.svg";
import BupaLogo from "@/images/vendors/BupaSquareLogo.svg";
import VitalityLogo from "@/images/vendors/VitalitySquareLogo.svg";
import { LocalDate } from "@/v2/util/local-date";
export const getAdditionalMembersOptions = (polyglot) => [
  { label: polyglot.t("UserInsuranceDependants.SpousePartner"), value: UserInsuranceDependants.SpousePartner },
  { label: polyglot.t("UserInsuranceDependants.Children"), value: UserInsuranceDependants.Children },
  { label: polyglot.t("UserInsuranceDependants.Family"), value: UserInsuranceDependants.Family }
];
function monthDiff(d1, d2) {
  const years = d2.getFullYear() - d1.getFullYear();
  let months = d2.getMonth() - d1.getMonth();
  return years * 12 + months;
}
export function getLogoByProviderName(providerName, width = 20) {
  if (providerName.toLowerCase() === "axa") {
    return /* @__PURE__ */ jsx("img", { src: AXALogo, alt: "AXA", width });
  }
  if (providerName.toLowerCase() === "vitality") {
    return /* @__PURE__ */ jsx("img", { src: VitalityLogo, alt: "Vitality", width });
  }
  if (providerName.toLowerCase() === "aviva") {
    return /* @__PURE__ */ jsx("img", { src: AvivaLogo, alt: "Aviva", width });
  }
  if (providerName.toLowerCase() === "bupa") {
    return /* @__PURE__ */ jsx("img", { src: BupaLogo, alt: "Bupa", width });
  }
  return /* @__PURE__ */ jsx("img", { src: AvivaLogo, alt: "Aviva", width });
}
export function getInsuranceMultiplierValueByDependantsValue(employeeMonthlyPremium, additionalMembersValue, insuranceAddonsMultipliers) {
  if (additionalMembersValue === UserInsuranceDependants.SpousePartner)
    return Number.parseFloat(
      (employeeMonthlyPremium * insuranceAddonsMultipliers.spousePartner - employeeMonthlyPremium).toFixed(2)
    );
  if (additionalMembersValue === UserInsuranceDependants.Children)
    return Number.parseFloat(
      (employeeMonthlyPremium * insuranceAddonsMultipliers.children - employeeMonthlyPremium).toFixed(2)
    );
  if (additionalMembersValue === UserInsuranceDependants.Family)
    return Number.parseFloat(
      (employeeMonthlyPremium * insuranceAddonsMultipliers.family - employeeMonthlyPremium).toFixed(2)
    );
  return Number.parseFloat(employeeMonthlyPremium.toFixed(2));
}
export function calculateAnnualValueOfTheBenefitForCurrentTaxYear(date, employeeMonthlyPremium, employeeMonthlyContribution, dependantsMonthlyPremium, dependantsMonthlyContribution) {
  const startDate = new LocalDate(date).getDate();
  const currentYear = startDate.getFullYear();
  const endOfTaxYear = /* @__PURE__ */ new Date(`1 April ${currentYear}`);
  if (endOfTaxYear.getTime() <= startDate.getTime()) endOfTaxYear.setFullYear(currentYear + 1);
  const fullMonthDifference = monthDiff(startDate, endOfTaxYear);
  const totalMonthlyPremiumPaidByEmployer = employeeMonthlyPremium - (employeeMonthlyContribution != null ? employeeMonthlyContribution : 0) + (dependantsMonthlyPremium ? dependantsMonthlyPremium - (dependantsMonthlyContribution != null ? dependantsMonthlyContribution : 0) : 0);
  return Number.parseFloat((totalMonthlyPremiumPaidByEmployer * fullMonthDifference).toFixed(2));
}
