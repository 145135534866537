"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { SuperAdminDeviceDetailsDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-drawer.component";
import { themeColors } from "@v2/styles/colors.styles";
export const SuperAdminDeviceDetails = ({ device, refreshDevice }) => {
  const [isOpen, setIsOpen] = useState(false);
  return device ? /* @__PURE__ */ jsxs(Box, { sx: { width: "400px" }, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: { cursor: "pointer" },
        onClick: () => {
          setIsOpen(true);
        },
        children: [
          /* @__PURE__ */ jsx(DeviceDetailsLine, { label: "Device ID", value: device.id }),
          /* @__PURE__ */ jsx(DeviceDetailsLine, { label: "Serial number", value: device.serialNumber }),
          /* @__PURE__ */ jsx(DeviceDetailsLine, { label: "Model name", value: device.modelName }),
          /* @__PURE__ */ jsx(DeviceDetailsLine, { label: "Model number", value: device.modelNumber }),
          /* @__PURE__ */ jsx(DeviceDetailsLine, { label: "Externally invoiced", value: device.externallyInvoiced })
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      SuperAdminDeviceDetailsDrawer,
      {
        isOpen,
        setIsOpen,
        device,
        refreshDevice
      }
    )
  ] }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Device not found!" }) });
};
const DeviceDetailsLine = ({
  label,
  value
}) => {
  const valueDisplay = value === null || value === void 0 ? "N/A" : value.toString();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        ":hover": { bgcolor: themeColors.Background }
      },
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { width: "30%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: label }) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "end", width: "65%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: valueDisplay }) })
      ]
    }
  );
};
