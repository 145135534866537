"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as CleanGrey } from "@/images/fields/CleanGrey.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { ReviewEntryAPI } from "@/v2/feature/growth/reviews/api-client/review-entry.api";
import {
  getReviewDueDate,
  getReviewType
} from "@/v2/feature/growth/reviews/features/review-personal/review-entry/review-entry.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { EntryState } from "@/v2/feature/growth/reviews/interfaces/review-entry.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewEntriesPersonalStartModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  selectedEntry
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: selectedEntry ? /* @__PURE__ */ jsx(ReviewEntriesPersonalStartContent, { selectedEntry, onClose }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "No record was selected" }) });
};
const useStartModalForm = (selectedEntry, setLoading, shouldSelectPeers) => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const formik = useFormik({
    initialValues: {
      peerIds: []
    },
    validationSchema: yup.object({
      peerIds: shouldSelectPeers ? yup.array().of(yup.number()).min(1, "At least one peer is required").required("Peers are required") : yup.array().of(yup.number())
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a;
      try {
        if (!selectedEntry || !((_a = selectedEntry.cycle) == null ? void 0 : _a.id)) {
          return;
        }
        if (shouldSelectPeers) {
          const reviewersToAdd = {
            reviewers: [{ reviewerType: ReviewerTypes.Peer, ids: values.peerIds }],
            revieweeId: selectedEntry.revieweeId
          };
          const entry = __spreadProps(__spreadValues({}, selectedEntry), {
            entryState: EntryState.Draft,
            cycleId: selectedEntry.cycle.id
          });
          yield ReviewEntryAPI.invitePeersByEntry({ reviewersToAdd, entry });
        }
        routerHistory.push(
          generatePath(REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE, {
            entryId: selectedEntry.id,
            cycleId: selectedEntry.cycle.id
          })
        );
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return formik;
};
const ReviewEntriesPersonalStartContent = ({
  selectedEntry,
  onClose
}) => {
  var _a, _b;
  const reviewType = useMemo(() => selectedEntry ? getReviewType(selectedEntry) : "", [selectedEntry]);
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const shouldSelectPeers = useMemo(
    () => Boolean(
      reviewType === ReviewerTypes.Self && selectedEntry.cycle && selectedEntry && selectedEntry.entryState === EntryState.NotStarted && selectedEntry.cycle.reviewerSelect.includes(ReviewerTypes.Peer) && selectedEntry.cycle.cycleSettings.allowRevieweesToInvitePeers
    ),
    [reviewType, selectedEntry]
  );
  const handleContinue = useCallback(() => __async(void 0, null, function* () {
    if (!selectedEntry || !selectedEntry.cycle) {
      return;
    }
    routerHistory.push(
      generatePath(REVIEWS_ME_INVITE_CYCLE_ANSWER_ROUTE, {
        entryId: selectedEntry.id,
        cycleId: selectedEntry.cycle.id
      })
    );
  }), [selectedEntry, routerHistory]);
  const formik = useStartModalForm(selectedEntry, setLoading, shouldSelectPeers);
  const cycleOwnerSelectedPeers = useMemo(() => {
    var _a2, _b2;
    const initialPeers = ((_a2 = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _a2.peerReviewerIds) && ((_b2 = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _b2.peerReviewerIds[selectedEntry.revieweeId]);
    if (!initialPeers) return [];
    return initialPeers;
  }, [(_a = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _a.peerReviewerIds, selectedEntry.revieweeId]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        "Let's ",
        selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? "start" : "continue",
        " your",
        " ",
        reviewType,
        " review"
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "You can save and exit at any time, come back to this review later." })
    ] }),
    selectedEntry && selectedEntry.cycle && (selectedEntry.cycle.displayName || selectedEntry.cycle.internalName) && /* @__PURE__ */ jsx(ViewItem, { label: "Cycle name", value: selectedEntry.cycle.displayName || selectedEntry.cycle.internalName }),
    selectedEntry && selectedEntry.endDate && /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "Due date",
        value: getReviewDueDate(selectedEntry).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" })
      }
    ),
    (selectedEntry == null ? void 0 : selectedEntry.revieweeId) && (reviewType === ReviewerTypes.Manager || reviewType === ReviewerTypes.Upward || reviewType === ReviewerTypes.Peer) && /* @__PURE__ */ jsx(ViewItem, { label: "You are reviewing", value: /* @__PURE__ */ jsx(UserCell, { userId: selectedEntry == null ? void 0 : selectedEntry.revieweeId }) }),
    selectedEntry && selectedEntry.cycle && shouldSelectPeers && /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: "Peer reviewer",
        labelSx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }),
        selectedSpecificButtonLabel: "Add peer reviewer",
        value: (_b = formik.values.peerIds) != null ? _b : [],
        onChange: (userIds) => {
          formik.setFieldValue("peerIds", userIds);
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        excludeEveryone: true,
        excludeCustomRule: true,
        allInvalidUserIds: selectedEntry.revieweeId ? [selectedEntry.revieweeId, ...cycleOwnerSelectedPeers] : [],
        hideUserList: true
      }
    ),
    shouldSelectPeers && (selectedEntry == null ? void 0 : selectedEntry.revieweeId) && formik.values.peerIds.length > 0 && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "Newly added peer reviewers" }),
      /* @__PURE__ */ jsx(
        List,
        {
          height: 250,
          itemCount: formik.values.peerIds.length,
          itemSize: 40,
          width: "100%",
          itemData: __spreadProps(__spreadValues({}, formik.values.peerIds), { props: { formik, themeColors, spacing, iconSize } }),
          children: RenderNewPeerRow
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, onClick: onClose, children: "Cancel" }),
      selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          name: "Start review",
          fullWidth: true,
          type: "submit",
          loading,
          disabled: shouldSelectPeers && formik.values.peerIds.length === 0
        }
      ) : /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, onClick: () => handleContinue(), children: "Continue" })
    ] })
  ] }) });
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: label }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value })
  ] });
};
const RenderNewPeerRow = ({ index, style, data }) => {
  const peerId = data[index];
  const { formik } = data.props;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      style,
      sx: {
        paddingY: spacing.p12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx(UserCell, { userId: peerId }),
        /* @__PURE__ */ jsx(
          CleanGrey,
          __spreadProps(__spreadValues({}, iconSize), {
            style: { cursor: "pointer" },
            onClick: () => {
              const filteredPeerIds = formik.values.peerIds.filter((id) => id !== peerId);
              formik.setFieldValue("peerIds", filteredPeerIds);
            }
          })
        )
      ]
    }
  );
};
