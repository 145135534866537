"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { CompanyDepartmentAPI } from "@/api-client/index.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DepartmentFormModal } from "@/v2/feature/department/department-settings/components/department-form-modal.component";
import { DepartmentListingTable } from "@/v2/feature/department/department-settings/components/department-listing-table.component";
import { DepartmentViewModal } from "@/v2/feature/department/department-settings/components/department-view-modal.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DepartmentSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const refreshDepartments = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const departments2 = yield CompanyDepartmentAPI.getCompanyDepartmentsWithMemberDetails();
      setDepartments(departments2);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refreshDepartments();
  }, []);
  const filteredDepartments = useMemo(() => {
    return searchInput.length > 0 ? departments.filter((user) => user.name.toLowerCase().includes(searchInput.toLowerCase())) : departments;
  }, [departments, searchInput]);
  useEffect(() => {
    trackPage("Company department settings");
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Departments" }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsFormOpen(true), children: polyglot.t("DepartmentSettingsPage.newDepartment") }),
        showBack: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          DepartmentListingTable,
          {
            departments: filteredDepartments,
            loading,
            setIsViewOpen,
            setIsFormOpen,
            setSelectedDepartment
          }
        ),
        /* @__PURE__ */ jsx(
          DepartmentFormModal,
          {
            isOpen: isFormOpen,
            setIsOpen: setIsFormOpen,
            selectedDepartment,
            refreshDepartments,
            closePage: () => {
              setIsViewOpen(false);
              setIsFormOpen(false);
              setTimeout(() => setSelectedDepartment(void 0), 500);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          DepartmentViewModal,
          {
            isOpen: isViewOpen,
            setIsOpen: setIsViewOpen,
            setIsFormOpen,
            selectedDepartment,
            refreshDepartments,
            closePage: () => {
              setIsViewOpen(false);
              setTimeout(() => setSelectedDepartment(void 0), 500);
            }
          }
        )
      ] })
    ] })
  ] });
};
