"use strict";
export function isPaycodeForAdditions(paycode) {
  return paycode.credit === "Employee";
}
export function isPaycodeForDeductions(paycode) {
  return !isPaycodeForAdditions(paycode) && paycode.debit === "Employee";
}
export function isPaycodeForOtherEmployerPayments(paycode) {
  return !isPaycodeForAdditions(paycode) && !isPaycodeForDeductions(paycode) && paycode.debit === "Employer" && paycode.credit !== "Employee";
}
