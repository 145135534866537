"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { PayrollEditPayCodesPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycode-edit.component";
import { PayrollNewPayCodesPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycode-new.component";
export const PayrollEditPayCodesDrawer = ({
  payrollId,
  payCodesNames,
  payCode,
  nominalCodes,
  accountingConfig,
  isOpen,
  setIsOpen,
  createCompanyPaycode,
  deleteCompanyPaycode,
  updateCompanyPaycode,
  reimportNominalCodes
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    isOpen && payCode && payCode !== "new" && accountingConfig && /* @__PURE__ */ jsx(
      PayrollEditPayCodesPage,
      {
        payrollId,
        payCodesNames,
        payCode,
        close: () => setIsOpen(false),
        deleteCompanyPaycode,
        updateCompanyPaycode,
        nominalCodes,
        reimportNominalCodes,
        accountingConfig
      }
    ),
    isOpen && payCode === "new" && accountingConfig && /* @__PURE__ */ jsx(
      PayrollNewPayCodesPage,
      {
        payrollId,
        payCodesNames,
        close: () => setIsOpen(false),
        createCompanyPaycode,
        nominalCodes,
        reimportNominalCodes,
        accountingConfig
      }
    )
  ] }) });
};
