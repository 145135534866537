"use strict";
import { invert } from "lodash";
export const CountryCodes = [
  "af",
  "al",
  "dz",
  "as",
  "ad",
  "ao",
  "ai",
  "aq",
  "ag",
  "ar",
  "am",
  "aw",
  "au",
  "at",
  "az",
  "bs",
  "bh",
  "bd",
  "bb",
  "by",
  "be",
  "bz",
  "bj",
  "bm",
  "bt",
  "bo",
  "bq",
  "ba",
  "bw",
  "bv",
  "br",
  "io",
  "bn",
  "bg",
  "bf",
  "bi",
  "kh",
  "cm",
  "ca",
  "cv",
  "ky",
  "cf",
  "td",
  "cl",
  "cn",
  "cx",
  "cc",
  "co",
  "km",
  "cg",
  "cd",
  "ck",
  "cr",
  "hr",
  "cu",
  "cw",
  "cy",
  "cz",
  "ci",
  "dk",
  "dj",
  "dm",
  "do",
  "ec",
  "eg",
  "sv",
  "gq",
  "er",
  "ee",
  "et",
  "fk",
  "fo",
  "fj",
  "fi",
  "fr",
  "gf",
  "pf",
  "tf",
  "ga",
  "gm",
  "ge",
  "de",
  "gh",
  "gi",
  "gr",
  "gl",
  "gd",
  "gp",
  "gu",
  "gt",
  "gg",
  "gn",
  "gw",
  "gy",
  "ht",
  "hm",
  "va",
  "hn",
  "hk",
  "hu",
  "is",
  "in",
  "id",
  "ir",
  "iq",
  "ie",
  "im",
  "il",
  "it",
  "jm",
  "jp",
  "je",
  "jo",
  "kz",
  "ke",
  "ki",
  "kp",
  "kr",
  "kw",
  "kg",
  "la",
  "lv",
  "lb",
  "ls",
  "lr",
  "ly",
  "li",
  "lt",
  "lu",
  "mo",
  "mk",
  "mg",
  "mw",
  "my",
  "mv",
  "ml",
  "mt",
  "mh",
  "mq",
  "mr",
  "mu",
  "yt",
  "mx",
  "fm",
  "md",
  "mc",
  "mn",
  "me",
  "ms",
  "ma",
  "mz",
  "mm",
  "na",
  "nr",
  "np",
  "nl",
  "nc",
  "nz",
  "ni",
  "ne",
  "ng",
  "nu",
  "nf",
  "mp",
  "no",
  "om",
  "pk",
  "pw",
  "ps",
  "pa",
  "pg",
  "py",
  "pe",
  "ph",
  "pn",
  "pl",
  "pt",
  "pr",
  "qa",
  "ro",
  "ru",
  "rw",
  "re",
  "bl",
  "sh",
  "kn",
  "lc",
  "mf",
  "pm",
  "vc",
  "ws",
  "sm",
  "st",
  "sa",
  "sn",
  "rs",
  "sc",
  "sl",
  "sg",
  "sx",
  "sk",
  "si",
  "sb",
  "so",
  "za",
  "gs",
  "ss",
  "es",
  "lk",
  "sd",
  "sr",
  "sj",
  "sz",
  "se",
  "ch",
  "sy",
  "tw",
  "tj",
  "tz",
  "th",
  "tl",
  "tg",
  "tk",
  "to",
  "tt",
  "tn",
  "tr",
  "tm",
  "tc",
  "tv",
  "ug",
  "ua",
  "ae",
  "uk",
  "us",
  "um",
  "uy",
  "uz",
  "vu",
  "ve",
  "vn",
  "vg",
  "vi",
  "wf",
  "eh",
  "ye",
  "zm",
  "zw",
  "ax"
];
export var NoneCountryEnum = /* @__PURE__ */ ((NoneCountryEnum2) => {
  NoneCountryEnum2["name"] = "None";
  NoneCountryEnum2["code"] = "none";
  return NoneCountryEnum2;
})(NoneCountryEnum || {});
export var CustomCountryEnum = /* @__PURE__ */ ((CustomCountryEnum2) => {
  CustomCountryEnum2["name"] = "Custom";
  CustomCountryEnum2["code"] = "custom";
  return CustomCountryEnum2;
})(CustomCountryEnum || {});
export const Countries = [
  {
    code: "af",
    code3: "AFG",
    name: "Afghanistan"
  },
  {
    code: "ax",
    code3: "ALA",
    name: "\xC5land Islands"
  },
  {
    code: "al",
    code3: "ALB",
    name: "Albania"
  },
  {
    code: "dz",
    code3: "DZA",
    name: "Algeria"
  },
  {
    code: "as",
    code3: "ASM",
    name: "American Samoa"
  },
  {
    code: "ad",
    code3: "AND",
    name: "Andorra"
  },
  {
    code: "ao",
    code3: "AGO",
    name: "Angola"
  },
  {
    code: "ai",
    code3: "AIA",
    name: "Anguilla"
  },
  {
    code: "aq",
    code3: "ATA",
    name: "Antarctica"
  },
  {
    code: "ag",
    code3: "ATG",
    name: "Antigua and Barbuda"
  },
  {
    code: "ar",
    code3: "ARG",
    name: "Argentina"
  },
  {
    code: "am",
    code3: "ARM",
    name: "Armenia"
  },
  {
    code: "aw",
    code3: "ABW",
    name: "Aruba"
  },
  {
    code: "au",
    code3: "AUS",
    name: "Australia"
  },
  {
    code: "at",
    code3: "AUT",
    name: "Austria"
  },
  {
    code: "az",
    code3: "AZE",
    name: "Azerbaijan"
  },
  {
    code: "bs",
    code3: "BHS",
    name: "Bahamas"
  },
  {
    code: "bh",
    code3: "BHR",
    name: "Bahrain"
  },
  {
    code: "bd",
    code3: "BGD",
    name: "Bangladesh"
  },
  {
    code: "bb",
    code3: "BRB",
    name: "Barbados"
  },
  {
    code: "by",
    code3: "BLR",
    name: "Belarus"
  },
  {
    code: "be",
    code3: "BEL",
    name: "Belgium"
  },
  {
    code: "bz",
    code3: "BLZ",
    name: "Belize"
  },
  {
    code: "bj",
    code3: "BEN",
    name: "Benin"
  },
  {
    code: "bm",
    code3: "BMU",
    name: "Bermuda"
  },
  {
    code: "bt",
    code3: "BTN",
    name: "Bhutan"
  },
  {
    code: "bo",
    code3: "BOL",
    name: "Bolivia (Plurinational State of)"
  },
  {
    code: "bq",
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba"
  },
  {
    code: "ba",
    code3: "BIH",
    name: "Bosnia and Herzegovina"
  },
  {
    code: "bw",
    code3: "BWA",
    name: "Botswana"
  },
  {
    code: "bv",
    code3: "BVT",
    name: "Bouvet Island"
  },
  {
    code: "br",
    code3: "BRA",
    name: "Brazil"
  },
  {
    code: "io",
    code3: "IOT",
    name: "British Indian Ocean Territory"
  },
  {
    code: "bn",
    code3: "BRN",
    name: "Brunei Darussalam"
  },
  {
    code: "bg",
    code3: "BGR",
    name: "Bulgaria"
  },
  {
    code: "bf",
    code3: "BFA",
    name: "Burkina Faso"
  },
  {
    code: "bi",
    code3: "BDI",
    name: "Burundi"
  },
  {
    code: "cv",
    code3: "CPV",
    name: "Cabo Verde"
  },
  {
    code: "kh",
    code3: "KHM",
    name: "Cambodia"
  },
  {
    code: "cm",
    code3: "CMR",
    name: "Cameroon"
  },
  {
    code: "ca",
    code3: "CAN",
    name: "Canada"
  },
  {
    code: "ky",
    code3: "CYM",
    name: "Cayman Islands"
  },
  {
    code: "cf",
    code3: "CAF",
    name: "Central African Republic"
  },
  {
    code: "td",
    code3: "TCD",
    name: "Chad"
  },
  {
    code: "cl",
    code3: "CHL",
    name: "Chile"
  },
  {
    code: "cn",
    code3: "CHN",
    name: "China"
  },
  {
    code: "cx",
    code3: "CXR",
    name: "Christmas Island"
  },
  {
    code: "cc",
    code3: "CCK",
    name: "Cocos (Keeling) Islands"
  },
  {
    code: "co",
    code3: "COL",
    name: "Colombia"
  },
  {
    code: "km",
    code3: "COM",
    name: "Comoros"
  },
  {
    code: "cg",
    code3: "COG",
    name: "Congo"
  },
  {
    code: "cd",
    code3: "COD",
    name: "Congo (Democratic Republic of the)"
  },
  {
    code: "ck",
    code3: "COK",
    name: "Cook Islands"
  },
  {
    code: "cr",
    code3: "CRI",
    name: "Costa Rica"
  },
  {
    code: "ci",
    code3: "CIV",
    name: "C\xF4te d'Ivoire"
  },
  {
    code: "hr",
    code3: "HRV",
    name: "Croatia"
  },
  {
    code: "cu",
    code3: "CUB",
    name: "Cuba"
  },
  {
    code: "cw",
    code3: "CUW",
    name: "Cura\xE7ao"
  },
  {
    code: "cy",
    code3: "CYP",
    name: "Cyprus"
  },
  {
    code: "cz",
    code3: "CZE",
    name: "Czech Republic"
  },
  {
    code: "dk",
    code3: "DNK",
    name: "Denmark"
  },
  {
    code: "dj",
    code3: "DJI",
    name: "Djibouti"
  },
  {
    code: "dm",
    code3: "DMA",
    name: "Dominica"
  },
  {
    code: "do",
    code3: "DOM",
    name: "Dominican Republic"
  },
  {
    code: "ec",
    code3: "ECU",
    name: "Ecuador"
  },
  {
    code: "eg",
    code3: "EGY",
    name: "Egypt"
  },
  {
    code: "sv",
    code3: "SLV",
    name: "El Salvador"
  },
  {
    code: "gq",
    code3: "GNQ",
    name: "Equatorial Guinea"
  },
  {
    code: "er",
    code3: "ERI",
    name: "Eritrea"
  },
  {
    code: "ee",
    code3: "EST",
    name: "Estonia"
  },
  {
    code: "et",
    code3: "ETH",
    name: "Ethiopia"
  },
  {
    code: "fk",
    code3: "FLK",
    name: "Falkland Islands (Malvinas)"
  },
  {
    code: "fo",
    code3: "FRO",
    name: "Faroe Islands"
  },
  {
    code: "fj",
    code3: "FJI",
    name: "Fiji"
  },
  {
    code: "fi",
    code3: "FIN",
    name: "Finland"
  },
  {
    code: "fr",
    code3: "FRA",
    name: "France"
  },
  {
    code: "gf",
    code3: "GUF",
    name: "French Guiana"
  },
  {
    code: "pf",
    code3: "PYF",
    name: "French Polynesia"
  },
  {
    code: "tf",
    code3: "ATF",
    name: "French Southern Territories"
  },
  {
    code: "ga",
    code3: "GAB",
    name: "Gabon"
  },
  {
    code: "gm",
    code3: "GMB",
    name: "Gambia"
  },
  {
    code: "ge",
    code3: "GEO",
    name: "Georgia"
  },
  {
    code: "de",
    code3: "DEU",
    name: "Germany"
  },
  {
    code: "gh",
    code3: "GHA",
    name: "Ghana"
  },
  {
    code: "gi",
    code3: "GIB",
    name: "Gibraltar"
  },
  {
    code: "gr",
    code3: "GRC",
    name: "Greece"
  },
  {
    code: "gl",
    code3: "GRL",
    name: "Greenland"
  },
  {
    code: "gd",
    code3: "GRD",
    name: "Grenada"
  },
  {
    code: "gp",
    code3: "GLP",
    name: "Guadeloupe"
  },
  {
    code: "gu",
    code3: "GUM",
    name: "Guam"
  },
  {
    code: "gt",
    code3: "GTM",
    name: "Guatemala"
  },
  {
    code: "gg",
    code3: "GGY",
    name: "Guernsey"
  },
  {
    code: "gn",
    code3: "GIN",
    name: "Guinea"
  },
  {
    code: "gw",
    code3: "GNB",
    name: "Guinea-Bissau"
  },
  {
    code: "gy",
    code3: "GUY",
    name: "Guyana"
  },
  {
    code: "ht",
    code3: "HTI",
    name: "Haiti"
  },
  {
    code: "hm",
    code3: "HMD",
    name: "Heard Island and McDonald Islands"
  },
  {
    code: "va",
    code3: "VAT",
    name: "Holy See"
  },
  {
    code: "hn",
    code3: "HND",
    name: "Honduras"
  },
  {
    code: "hk",
    code3: "HKG",
    name: "Hong Kong"
  },
  {
    code: "hu",
    code3: "HUN",
    name: "Hungary"
  },
  {
    code: "is",
    code3: "ISL",
    name: "Iceland"
  },
  {
    code: "in",
    code3: "IND",
    name: "India"
  },
  {
    code: "id",
    code3: "IDN",
    name: "Indonesia"
  },
  {
    code: "ir",
    code3: "IRN",
    name: "Iran (Islamic Republic of)"
  },
  {
    code: "iq",
    code3: "IRQ",
    name: "Iraq"
  },
  {
    code: "ie",
    code3: "IRL",
    name: "Ireland"
  },
  {
    code: "im",
    code3: "IMN",
    name: "Isle of Man"
  },
  {
    code: "il",
    code3: "ISR",
    name: "Israel"
  },
  {
    code: "it",
    code3: "ITA",
    name: "Italy"
  },
  {
    code: "jm",
    code3: "JAM",
    name: "Jamaica"
  },
  {
    code: "jp",
    code3: "JPN",
    name: "Japan"
  },
  {
    code: "je",
    code3: "JEY",
    name: "Jersey"
  },
  {
    code: "jo",
    code3: "JOR",
    name: "Jordan"
  },
  {
    code: "kz",
    code3: "KAZ",
    name: "Kazakhstan"
  },
  {
    code: "ke",
    code3: "KEN",
    name: "Kenya"
  },
  {
    code: "ki",
    code3: "KIR",
    name: "Kiribati"
  },
  {
    code: "kp",
    code3: "PRK",
    name: "Korea (Democratic People's Republic of)"
  },
  {
    code: "kr",
    code3: "KOR",
    name: "Korea (Republic of)"
  },
  {
    code: "kw",
    code3: "KWT",
    name: "Kuwait"
  },
  {
    code: "kg",
    code3: "KGZ",
    name: "Kyrgyzstan"
  },
  {
    code: "la",
    code3: "LAO",
    name: "Lao People's Democratic Republic"
  },
  {
    code: "lv",
    code3: "LVA",
    name: "Latvia"
  },
  {
    code: "lb",
    code3: "LBN",
    name: "Lebanon"
  },
  {
    code: "ls",
    code3: "LSO",
    name: "Lesotho"
  },
  {
    code: "lr",
    code3: "LBR",
    name: "Liberia"
  },
  {
    code: "ly",
    code3: "LBY",
    name: "Libya"
  },
  {
    code: "li",
    code3: "LIE",
    name: "Liechtenstein"
  },
  {
    code: "lt",
    code3: "LTU",
    name: "Lithuania"
  },
  {
    code: "lu",
    code3: "LUX",
    name: "Luxembourg"
  },
  {
    code: "mo",
    code3: "MAC",
    name: "Macao"
  },
  {
    code: "mk",
    code3: "MKD",
    name: "Macedonia (the former Yugoslav Republic of)"
  },
  {
    code: "mg",
    code3: "MDG",
    name: "Madagascar"
  },
  {
    code: "mw",
    code3: "MWI",
    name: "Malawi"
  },
  {
    code: "my",
    code3: "MYS",
    name: "Malaysia"
  },
  {
    code: "mv",
    code3: "MDV",
    name: "Maldives"
  },
  {
    code: "ml",
    code3: "MLI",
    name: "Mali"
  },
  {
    code: "mt",
    code3: "MLT",
    name: "Malta"
  },
  {
    code: "mh",
    code3: "MHL",
    name: "Marshall Islands"
  },
  {
    code: "mq",
    code3: "MTQ",
    name: "Martinique"
  },
  {
    code: "mr",
    code3: "MRT",
    name: "Mauritania"
  },
  {
    code: "mu",
    code3: "MUS",
    name: "Mauritius"
  },
  {
    code: "yt",
    code3: "MYT",
    name: "Mayotte"
  },
  {
    code: "mx",
    code3: "MEX",
    name: "Mexico"
  },
  {
    code: "fm",
    code3: "FSM",
    name: "Micronesia (Federated States of)"
  },
  {
    code: "md",
    code3: "MDA",
    name: "Moldova (Republic of)"
  },
  {
    code: "mc",
    code3: "MCO",
    name: "Monaco"
  },
  {
    code: "mn",
    code3: "MNG",
    name: "Mongolia"
  },
  {
    code: "me",
    code3: "MNE",
    name: "Montenegro"
  },
  {
    code: "ms",
    code3: "MSR",
    name: "Montserrat"
  },
  {
    code: "ma",
    code3: "MAR",
    name: "Morocco"
  },
  {
    code: "mz",
    code3: "MOZ",
    name: "Mozambique"
  },
  {
    code: "mm",
    code3: "MMR",
    name: "Myanmar"
  },
  {
    code: "na",
    code3: "NAM",
    name: "Namibia"
  },
  {
    code: "nr",
    code3: "NRU",
    name: "Nauru"
  },
  {
    code: "np",
    code3: "NPL",
    name: "Nepal"
  },
  {
    code: "nl",
    code3: "NLD",
    name: "Netherlands"
  },
  {
    code: "nc",
    code3: "NCL",
    name: "New Caledonia"
  },
  {
    code: "nz",
    code3: "NZL",
    name: "New Zealand"
  },
  {
    code: "ni",
    code3: "NIC",
    name: "Nicaragua"
  },
  {
    code: "ne",
    code3: "NER",
    name: "Niger"
  },
  {
    code: "ng",
    code3: "NGA",
    name: "Nigeria"
  },
  {
    code: "nu",
    code3: "NIU",
    name: "Niue"
  },
  {
    code: "nf",
    code3: "NFK",
    name: "Norfolk Island"
  },
  {
    code: "mp",
    code3: "MNP",
    name: "Northern Mariana Islands"
  },
  {
    code: "no",
    code3: "NOR",
    name: "Norway"
  },
  {
    code: "om",
    code3: "OMN",
    name: "Oman"
  },
  {
    code: "pk",
    code3: "PAK",
    name: "Pakistan"
  },
  {
    code: "pw",
    code3: "PLW",
    name: "Palau"
  },
  {
    code: "ps",
    code3: "PSE",
    name: "Palestine, State of"
  },
  {
    code: "pa",
    code3: "PAN",
    name: "Panama"
  },
  {
    code: "pg",
    code3: "PNG",
    name: "Papua New Guinea"
  },
  {
    code: "py",
    code3: "PRY",
    name: "Paraguay"
  },
  {
    code: "pe",
    code3: "PER",
    name: "Peru"
  },
  {
    code: "ph",
    code3: "PHL",
    name: "Philippines"
  },
  {
    code: "pn",
    code3: "PCN",
    name: "Pitcairn"
  },
  {
    code: "pl",
    code3: "POL",
    name: "Poland"
  },
  {
    code: "pt",
    code3: "PRT",
    name: "Portugal"
  },
  {
    code: "pr",
    code3: "PRI",
    name: "Puerto Rico"
  },
  {
    code: "qa",
    code3: "QAT",
    name: "Qatar"
  },
  {
    code: "re",
    code3: "REU",
    name: "R\xE9union"
  },
  {
    code: "ro",
    code3: "ROU",
    name: "Romania"
  },
  {
    code: "ru",
    code3: "RUS",
    name: "Russian Federation"
  },
  {
    code: "rw",
    code3: "RWA",
    name: "Rwanda"
  },
  {
    code: "bl",
    code3: "BLM",
    name: "Saint Barth\xE9lemy"
  },
  {
    code: "sh",
    code3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    code: "kn",
    code3: "KNA",
    name: "Saint Kitts and Nevis"
  },
  {
    code: "lc",
    code3: "LCA",
    name: "Saint Lucia"
  },
  {
    code: "mf",
    code3: "MAF",
    name: "Saint Martin (French part)"
  },
  {
    code: "pm",
    code3: "SPM",
    name: "Saint Pierre and Miquelon"
  },
  {
    code: "vc",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines"
  },
  {
    code: "ws",
    code3: "WSM",
    name: "Samoa"
  },
  {
    code: "sm",
    code3: "SMR",
    name: "San Marino"
  },
  {
    code: "st",
    code3: "STP",
    name: "Sao Tome and Principe"
  },
  {
    code: "sa",
    code3: "SAU",
    name: "Saudi Arabia"
  },
  {
    code: "sn",
    code3: "SEN",
    name: "Senegal"
  },
  {
    code: "rs",
    code3: "SRB",
    name: "Serbia"
  },
  {
    code: "sc",
    code3: "SYC",
    name: "Seychelles"
  },
  {
    code: "sl",
    code3: "SLE",
    name: "Sierra Leone"
  },
  {
    code: "sg",
    code3: "SGP",
    name: "Singapore"
  },
  {
    code: "sx",
    code3: "SXM",
    name: "Sint Maarten (Dutch part)"
  },
  {
    code: "sk",
    code3: "SVK",
    name: "Slovakia"
  },
  {
    code: "si",
    code3: "SVN",
    name: "Slovenia"
  },
  {
    code: "sb",
    code3: "SLB",
    name: "Solomon Islands"
  },
  {
    code: "so",
    code3: "SOM",
    name: "Somalia"
  },
  {
    code: "za",
    code3: "ZAF",
    name: "South Africa"
  },
  {
    code: "gs",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands"
  },
  {
    code: "ss",
    code3: "SSD",
    name: "South Sudan"
  },
  {
    code: "es",
    code3: "ESP",
    name: "Spain"
  },
  {
    code: "lk",
    code3: "LKA",
    name: "Sri Lanka"
  },
  {
    code: "sd",
    code3: "SDN",
    name: "Sudan"
  },
  {
    code: "sr",
    code3: "SUR",
    name: "Suriname"
  },
  {
    code: "sj",
    code3: "SJM",
    name: "Svalbard and Jan Mayen"
  },
  {
    code: "sz",
    code3: "SWZ",
    name: "Swaziland"
  },
  {
    code: "se",
    code3: "SWE",
    name: "Sweden"
  },
  {
    code: "ch",
    code3: "CHE",
    name: "Switzerland"
  },
  {
    code: "sy",
    code3: "SYR",
    name: "Syrian Arab Republic"
  },
  {
    code: "tw",
    code3: "TWN",
    name: "Taiwan, Province of China"
  },
  {
    code: "tj",
    code3: "TJK",
    name: "Tajikistan"
  },
  {
    code: "tz",
    code3: "TZA",
    name: "Tanzania, United Republic of"
  },
  {
    code: "th",
    code3: "THA",
    name: "Thailand"
  },
  {
    code: "tl",
    code3: "TLS",
    name: "Timor-Leste"
  },
  {
    code: "tg",
    code3: "TGO",
    name: "Togo"
  },
  {
    code: "tk",
    code3: "TKL",
    name: "Tokelau"
  },
  {
    code: "to",
    code3: "TON",
    name: "Tonga"
  },
  {
    code: "tt",
    code3: "TTO",
    name: "Trinidad and Tobago"
  },
  {
    code: "tn",
    code3: "TUN",
    name: "Tunisia"
  },
  {
    code: "tr",
    code3: "TUR",
    name: "Turkey"
  },
  {
    code: "tm",
    code3: "TKM",
    name: "Turkmenistan"
  },
  {
    code: "tc",
    code3: "TCA",
    name: "Turks and Caicos Islands"
  },
  {
    code: "tv",
    code3: "TUV",
    name: "Tuvalu"
  },
  {
    code: "ug",
    code3: "UGA",
    name: "Uganda"
  },
  {
    code: "ua",
    code3: "UKR",
    name: "Ukraine"
  },
  {
    code: "ae",
    code3: "ARE",
    name: "United Arab Emirates"
  },
  {
    code: "uk",
    code3: "GBR",
    name: "United Kingdom"
  },
  {
    code: "us",
    code3: "USA",
    name: "United States"
  },
  {
    code: "um",
    code3: "UMI",
    name: "United States Minor Outlying Islands"
  },
  {
    code: "uy",
    code3: "URY",
    name: "Uruguay"
  },
  {
    code: "uz",
    code3: "UZB",
    name: "Uzbekistan"
  },
  {
    code: "vu",
    code3: "VUT",
    name: "Vanuatu"
  },
  {
    code: "ve",
    code3: "VEN",
    name: "Venezuela (Bolivarian Republic of)"
  },
  {
    code: "vn",
    code3: "VNM",
    name: "Viet Nam"
  },
  {
    code: "vg",
    code3: "VGB",
    name: "Virgin Islands (British)"
  },
  {
    code: "vi",
    code3: "VIR",
    name: "Virgin Islands (U.S.)"
  },
  {
    code: "wf",
    code3: "WLF",
    name: "Wallis and Futuna"
  },
  {
    code: "eh",
    code3: "ESH",
    name: "Western Sahara"
  },
  {
    code: "ye",
    code3: "YEM",
    name: "Yemen"
  },
  {
    code: "zm",
    code3: "ZMB",
    name: "Zambia"
  },
  {
    code: "zw",
    code3: "ZWE",
    name: "Zimbabwe"
  }
];
export const COUNTRY_ISO_CODE_MAPPING = {
  Afghanistan: "AF",
  "Aland Islands": "AX",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua And Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bosnia And Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO",
  "Brunei Darussalam": "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  Congo: "CG",
  "Congo, Democratic Republic": "CD",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  'Cote D"Ivoire': "CI",
  Croatia: "HR",
  Cuba: "CU",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  "Falkland Islands (Malvinas)": "FK",
  "Faroe Islands": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard Island & Mcdonald Islands": "HM",
  "Holy See (Vatican City State)": "VA",
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  "Iran, Islamic Republic Of": "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle Of Man": "IM",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  Korea: "KR",
  "North Korea": "KP",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  'Lao People"s Democratic Republic': "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  "Libyan Arab Jamahiriya": "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  Macedonia: "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  "Micronesia, Federated States Of": "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  "Netherlands Antilles": "AN",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "Northern Mariana Islands": "MP",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestinian Territory, Occupied": "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Pitcairn: "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  Reunion: "RE",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  "Saint Barthelemy": "BL",
  "Saint Helena": "SH",
  "Saint Kitts And Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre And Miquelon": "PM",
  "Saint Vincent And Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome And Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia And Sandwich Isl.": "GS",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  "Svalbard And Jan Mayen": "SJ",
  Swaziland: "SZ",
  Sweden: "SE",
  Switzerland: "CH",
  "Syrian Arab Republic": "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  "Timor-Leste": "TL",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad And Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks And Caicos Islands": "TC",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "UK",
  "United Kingdom - England": "UK",
  // should be 'GB' but the majority of functionality (including calendar integrations) is using 'UK'
  "United Kingdom - Alderney": "UK-ALD",
  "United Kingdom - Northern Ireland": "UK-NIR",
  "United Kingdom - Scotland": "UK-SCT",
  "United Kingdom - Wales": "UK-WLS",
  "United States of America": "US",
  // need to add an entry for America, as the United States was not matching with the Country Selector
  "United States": "US",
  "United States Outlying Islands": "UM",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  Venezuela: "VE",
  Vietnam: "VN",
  "Virgin Islands, British": "VG",
  "Virgin Islands, U.S.": "VI",
  "Wallis And Futuna": "WF",
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW"
};
export const COUNTRY_CODE_TO_NAME_MAPPING = invert(COUNTRY_ISO_CODE_MAPPING);
export const PublicHolidayCountryCodes = [
  ...Object.values(COUNTRY_ISO_CODE_MAPPING).map((code) => code.toLowerCase()),
  "none" /* code */
];
export const ALL_REGIONS_AND_COUNTRIES = [
  {
    label: "United Kingdom",
    isPayrollEligibleCountry: true,
    options: [
      { label: "England", value: "England" },
      { label: "Scotland", value: "Scotland" },
      { label: "Wales", value: "Wales" },
      { label: "Northern Ireland", value: "NorthernIreland" },
      { label: "United Kingdom", value: "United Kingdom" }
    ]
  },
  {
    label: "European Union",
    options: [
      { label: "Austria", value: "Austria" },
      { label: "Belgium", value: "Belgium" },
      { label: "Bulgaria", value: "Bulgaria" },
      { label: "Croatia", value: "Croatia" },
      { label: "Cyprus", value: "Cyprus" },
      { label: "Czechia", value: "Czechia" },
      { label: "Denmark", value: "Denmark" },
      { label: "Estonia", value: "Estonia" },
      { label: "Finland", value: "Finland" },
      { label: "France", value: "France" },
      { label: "Germany", value: "Germany" },
      { label: "Greece", value: "Greece" },
      { label: "Hungary", value: "Hungary" },
      { label: "Ireland", value: "Ireland" },
      { label: "Italy", value: "Italy" },
      { label: "Latvia", value: "Latvia" },
      { label: "Lithuania", value: "Lithuania" },
      { label: "Luxembourg", value: "Luxembourg" },
      { label: "Malta", value: "Malta" },
      { label: "Netherlands", value: "Netherlands" },
      { label: "Poland", value: "Poland" },
      { label: "Portugal", value: "Portugal" },
      { label: "Romania", value: "Romania" },
      { label: "Slovakia", value: "Slovakia" },
      { label: "Slovenia", value: "Slovenia" },
      { label: "Spain", value: "Spain" },
      { label: "Sweden", value: "Sweden" }
    ]
  },
  {
    label: "Rest of the World",
    options: [
      { value: "Afghanistan", label: "Afghanistan" },
      { value: "Albania", label: "Albania" },
      { value: "Algeria", label: "Algeria" },
      { value: "Andorra", label: "Andorra" },
      { value: "Angola", label: "Angola" },
      { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
      { value: "Argentina", label: "Argentina" },
      { value: "Armenia", label: "Armenia" },
      { value: "Australia", label: "Australia" },
      { value: "Azerbaijan", label: "Azerbaijan" },
      { value: "Bahamas", label: "Bahamas" },
      { value: "Bahrain", label: "Bahrain" },
      { value: "Bangladesh", label: "Bangladesh" },
      { value: "Barbados", label: "Barbados" },
      { value: "Belarus", label: "Belarus" },
      { value: "Belize", label: "Belize" },
      { value: "Benin", label: "Benin" },
      { value: "Bhutan", label: "Bhutan" },
      { value: "Bolivia", label: "Bolivia" },
      { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
      { value: "Botswana", label: "Botswana" },
      { value: "Brazil", label: "Brazil" },
      { value: "Brunei", label: "Brunei" },
      { value: "Burkina Faso", label: "Burkina Faso" },
      { value: "Burundi", label: "Burundi" },
      { value: "Cabo Verde", label: "Cabo Verde" },
      { value: "Cambodia", label: "Cambodia" },
      { value: "Cameroon", label: "Cameroon" },
      { value: "Canada", label: "Canada" },
      { value: "Central African Republic", label: "Central African Republic" },
      { value: "Chad", label: "Chad" },
      { value: "Chile", label: "Chile" },
      { value: "China", label: "China" },
      { value: "Colombia", label: "Colombia" },
      { value: "Comoros", label: "Comoros" },
      { value: "Costa Rica", label: "Costa Rica" },
      { value: "C\xF4te d'Ivoire", label: "Cote d'Ivoire" },
      { value: "Cuba", label: "Cuba" },
      { value: "Czech Republic", label: "Czech Republic" },
      { value: "Democratic Republic of the Congo", label: "Democratic Republic of the Congo" },
      { value: "Djibouti", label: "Djibouti" },
      { value: "Dominica", label: "Dominica" },
      { value: "Dominican Republic", label: "Dominican Republic" },
      { value: "Ecuador", label: "Ecuador" },
      { value: "Egypt", label: "Egypt" },
      { value: "El Salvador", label: "El Salvador" },
      { value: "Equatorial Guinea", label: "Equatorial Guinea" },
      { value: "Eritrea", label: "Eritrea" },
      { value: "Ethiopia", label: "Ethiopia" },
      { value: "Fiji", label: "Fiji" },
      { value: "Gabon", label: "Gabon" },
      { value: "Gambia", label: "Gambia" },
      { value: "Georgia", label: "Georgia" },
      { value: "Ghana", label: "Ghana" },
      { value: "Grenada", label: "Grenada" },
      { value: "Guatemala", label: "Guatemala" },
      { value: "Guinea", label: "Guinea" },
      { value: "Guinea-Bissau", label: "Guinea-Bissau" },
      { value: "Guyana", label: "Guyana" },
      { value: "Haiti", label: "Haiti" },
      { value: "Holy See", label: "Holy See" },
      { value: "Honduras", label: "Honduras" },
      { value: "Hong Kong", label: "Hong Kong" },
      { value: "Iceland", label: "Iceland" },
      { value: "India", label: "India" },
      { value: "Indonesia", label: "Indonesia" },
      { value: "Iran", label: "Iran" },
      { value: "Iraq", label: "Iraq" },
      { value: "Israel", label: "Israel" },
      { value: "Jamaica", label: "Jamaica" },
      { value: "Japan", label: "Japan" },
      { value: "Jordan", label: "Jordan" },
      { value: "Kazakhstan", label: "Kazakhstan" },
      { value: "Kenya", label: "Kenya" },
      { value: "Kiribati", label: "Kiribati" },
      { value: "Kuwait", label: "Kuwait" },
      { value: "Kyrgyzstan", label: "Kyrgyzstan" },
      { value: "Laos", label: "Laos" },
      { value: "Lebanon", label: "Lebanon" },
      { value: "Lesotho", label: "Lesotho" },
      { value: "Liberia", label: "Liberia" },
      { value: "Libya", label: "Libya" },
      { value: "Liechtenstein", label: "Liechtenstein" },
      { value: "Madagascar", label: "Madagascar" },
      { value: "Malawi", label: "Malawi" },
      { value: "Malaysia", label: "Malaysia" },
      { value: "Maldives", label: "Maldives" },
      { value: "Mali", label: "Mali" },
      { value: "Marshall Islands", label: "Marshall Islands" },
      { value: "Mauritania", label: "Mauritania" },
      { value: "Mauritius", label: "Mauritius" },
      { value: "Mexico", label: "Mexico" },
      { value: "Micronesia", label: "Micronesia" },
      { value: "Moldova", label: "Moldova" },
      { value: "Monaco", label: "Monaco" },
      { value: "Mongolia", label: "Mongolia" },
      { value: "Montenegro", label: "Montenegro" },
      { value: "Morocco", label: "Morocco" },
      { value: "Mozambique", label: "Mozambique" },
      { value: "Myanmar (formerly Burma)", label: "Myanmar" },
      { value: "Namibia", label: "Namibia" },
      { value: "Nauru", label: "Nauru" },
      { value: "Nepal", label: "Nepal" },
      { value: "New Zealand", label: "New Zealand" },
      { value: "Nicaragua", label: "Nicaragua" },
      { value: "Niger", label: "Niger" },
      { value: "Nigeria", label: "Nigeria" },
      { value: "North Korea", label: "North Korea" },
      { value: "North Macedonia", label: "North Macedonia" },
      { value: "Norway", label: "Norway" },
      { value: "Oman", label: "Oman" },
      { value: "Pakistan", label: "Pakistan" },
      { value: "Palau", label: "Palau" },
      { value: "Palestine State", label: "Palestine State" },
      { value: "Panama", label: "Panama" },
      { value: "Papua New Guinea", label: "Papua New Guinea" },
      { value: "Paraguay", label: "Paraguay" },
      { value: "Peru", label: "Peru" },
      { value: "Philippines", label: "Philippines" },
      { value: "Qatar", label: "Qatar" },
      { value: "Russia", label: "Russia" },
      { value: "Rwanda", label: "Rwanda" },
      { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
      { value: "Saint Lucia", label: "Saint Lucia" },
      { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
      { value: "Samoa", label: "Samoa" },
      { value: "San Marino", label: "San Marino" },
      { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
      { value: "Saudi Arabia", label: "Saudi Arabia" },
      { value: "Senegal", label: "Senegal" },
      { value: "Serbia", label: "Serbia" },
      { value: "Seychelles", label: "Seychelles" },
      { value: "Sierra Leone", label: "Sierra Leone" },
      { value: "Singapore", label: "Singapore" },
      { value: "Solomon Islands", label: "Solomon Islands" },
      { value: "Somalia", label: "Somalia" },
      { value: "South Africa", label: "South Africa" },
      { value: "South Korea", label: "South Korea" },
      { value: "South Sudan", label: "South Sudan" },
      { value: "Sri Lanka", label: "Sri Lanka" },
      { value: "Sudan", label: "Sudan" },
      { value: "Suriname", label: "Suriname" },
      { value: "Switzerland", label: "Switzerland" },
      { value: "Syria", label: "Syria" },
      { value: "Taiwan", label: "Taiwan" },
      { value: "Tajikistan", label: "Tajikistan" },
      { value: "Tanzania", label: "Tanzania" },
      { value: "Thailand", label: "Thailand" },
      { value: "Timor-Leste", label: "Timor-Leste" },
      { value: "Togo", label: "Togo" },
      { value: "Tonga", label: "Tonga" },
      { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
      { value: "Tunisia", label: "Tunisia" },
      { value: "Turkey", label: "Turkey" },
      { value: "Turkmenistan", label: "Turkmenistan" },
      { value: "Tuvalu", label: "Tuvalu" },
      { value: "Uganda", label: "Uganda" },
      { value: "Ukraine", label: "Ukraine" },
      { value: "United Arab Emirates", label: "United Arab Emirates" },
      { value: "United States of America", label: "United States of America" },
      { value: "Uruguay", label: "Uruguay" },
      { value: "Uzbekistan", label: "Uzbekistan" },
      { value: "Vanuatu", label: "Vanuatu" },
      { value: "Venezuela", label: "Venezuela" },
      { value: "Vietnam", label: "Vietnam" },
      { value: "Yemen", label: "Yemen" },
      { value: "Zambia", label: "Zambia" },
      { value: "Zimbabwe", label: "Zimbabwe" }
    ]
  }
];
export const CountriesCodes = Countries.map((country) => country.code);
