"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { OauthClientAPI, OauthClientEndpoints } from "@/api-client/oauth-client.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as WaitingEmpty } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  DescriptionLine,
  SettingsPageSection
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { DeveloperHubPermissionsEditDrawer } from "@/v2/feature/security/security-settings/features/developer-hub/components/developer-hub-permissions-edit-drawer.component";
import { getIconForAppStatus } from "@/v2/feature/security/security-settings/features/developer-hub/developer-hub-build-apps.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { LocalDate } from "@/v2/util/local-date";
import { toTitleCase } from "@/v2/util/string.util";
export const DeveloperHubPublishSettingsPage = ({ oauthApp, onUpdate }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { data: publishHistory, mutate: refresh } = useApiClient(
    oauthApp.clientId ? OauthClientEndpoints.getPublishRequestsForClientId(oauthApp.clientId) : { url: void 0 },
    {
      suspense: false
    }
  );
  const cannotSubmitAnotherPublishRequest = (publishHistory == null ? void 0 : publishHistory.some((request) => request.status === "pending")) || oauthApp.published;
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("DeveloperHub.clientTable.createdAt"),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: "createdAt",
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: dateAPItoDisplay(original.createdAt) }) })
      },
      {
        header: () => polyglot.t("DeveloperHub.clientTable.scopes"),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 150,
        // Increased max width to accommodate the list
        id: "scopes",
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Fragment, { children: original.scopeReasons ? /* @__PURE__ */ jsx(List, { dense: true, children: Object.keys(original.scopeReasons).map((scope) => /* @__PURE__ */ jsx(ListItem, { sx: { pl: 0 }, children: /* @__PURE__ */ jsx(ListItemText, { primary: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: scope }) }) }, scope)) }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => polyglot.t("DeveloperHub.reasons"),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 200,
        // Increased max width to accommodate the list
        id: "scopeReasons",
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Fragment, { children: original.scopeReasons ? /* @__PURE__ */ jsx(List, { dense: true, children: Object.values(original.scopeReasons).map((reason) => /* @__PURE__ */ jsx(ListItem, { sx: { pl: 0 }, children: /* @__PURE__ */ jsx(ListItemText, { primary: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: reason }) }) }, reason)) }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => polyglot.t("DeveloperHub.status"),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: "status",
        cell: (info) => {
          const status = info.getValue().status;
          const getStatusIcon = (status2) => {
            switch (status2) {
              case "pending":
                return /* @__PURE__ */ jsx(WaitingEmpty, {});
              case "approved":
                return /* @__PURE__ */ jsx(OkGreen, { fill: themeColors.Green });
              case "rejected":
                return /* @__PURE__ */ jsx(Rejected, { fill: themeColors.Red });
              default:
                return null;
            }
          };
          return /* @__PURE__ */ jsxs(Box, { display: "flex", alignItems: "center", children: [
            getStatusIcon(status),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { ml: 1 }, children: toTitleCase(status) })
          ] });
        }
      },
      {
        header: () => polyglot.t("DeveloperHub.reviewedAt"),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: "reviewedBy",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.reviewedAt ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: new LocalDate(original.reviewedAt).toDateTimeString() }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      }
    ];
  }, [polyglot]);
  const submitNewPublishRequest = () => __async(void 0, null, function* () {
    try {
      const { clientId, scopes, scopeReasons } = oauthApp;
      yield OauthClientAPI.submitOAuthClientPublishRequest(clientId, scopes, scopeReasons);
      showMessage("Successfully submitted publish request!", "success");
      refresh();
    } catch (error) {
      showMessage(`Encountered an error while trying to submit publish request: ${nestErrorMessage(error)}`, "error");
    }
  });
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
    SettingsPageSection,
    {
      title: polyglot.t("DeveloperHub.publish"),
      editSection: !oauthApp.published ? /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "small",
          onClick: submitNewPublishRequest,
          disabled: cannotSubmitAnotherPublishRequest,
          children: "Submit"
        }
      ) : /* @__PURE__ */ jsx(Fragment, {}),
      children: [
        !oauthApp.published && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeveloperHub.publishExplainer") }),
        isEditOpen && /* @__PURE__ */ jsx(
          DeveloperHubPermissionsEditDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            oauthApp,
            onClose: function() {
              setIsEditOpen(false);
            },
            onUpdate
          }
        ),
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("DeveloperHub.status"),
            value: getIconForAppStatus(polyglot, oauthApp.published)
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: spacing.mt20, mb: spacing.mb20 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { color: themeColors.DarkGrey }, children: polyglot.t("DeveloperHub.publishHistory") }),
          (publishHistory == null ? void 0 : publishHistory.length) && (publishHistory == null ? void 0 : publishHistory.length) > 0 ? /* @__PURE__ */ jsx(
            BasicTable,
            {
              rowData: publishHistory,
              columnData: columns,
              loading: false,
              hidePagination: true,
              externalTdAndThPadding: "0 0px",
              style: { width: "900px" }
            }
          ) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mt: "8px" }, children: polyglot.t("DeveloperHub.publishHistoryNoneYet") }) })
        ] })
      ]
    }
  ) });
};
