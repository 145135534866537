"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  InviteMessageOptions,
  ReminderFrequencyOptions,
  ReminderFrequencyValue
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const SurveyCycleCreationNotificationsModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SurveyCycleCreationNotificationsContent, { surveyCycle, onClose, refresh }) });
};
const useNotificationForm = (surveyCycle, onClose, refresh) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      notificationSettings: (surveyCycle == null ? void 0 : surveyCycle.notificationSettings) || {
        reminderFrequency: ReminderFrequencyValue.None
      }
    },
    validationSchema: yup.object({
      notificationSettings: yup.object({
        reminderFrequency: yup.string().oneOf([ReminderFrequencyValue.Daily, ReminderFrequencyValue.EveryMonday, ReminderFrequencyValue.None]).nullable().required("Reminder is required")
      })
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (surveyCycle) {
          yield SurveyCycleAPI.updateSurveyCycle(__spreadProps(__spreadValues({}, surveyCycle), {
            notificationSettings: {
              reminderFrequency: values.notificationSettings.reminderFrequency
            }
          }));
          showMessage(polyglot.t("CycleSettingsRouter.successMessages.update"), "success");
          yield refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
export const SurveyCycleCreationNotificationsContent = ({
  surveyCycle,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const formik = useNotificationForm(surveyCycle, onClose, refresh);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Notifications" }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "notificationSettings.reminderFrequency",
        label: "Reminder",
        options: ReminderFrequencyOptions,
        value: formik.values.notificationSettings.reminderFrequency,
        compareValue: formik.values.notificationSettings.reminderFrequency,
        error: !!((_a = formik.errors.notificationSettings) == null ? void 0 : _a.reminderFrequency) && ((_b = formik.touched.notificationSettings) == null ? void 0 : _b.reminderFrequency),
        onChange: formik.handleChange,
        helperText: ((_c = formik.touched.notificationSettings) == null ? void 0 : _c.reminderFrequency) && Boolean((_d = formik.errors.notificationSettings) == null ? void 0 : _d.reminderFrequency)
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        disabled: true,
        name: "message",
        label: "Invite message",
        options: InviteMessageOptions,
        value: "default",
        compareValue: "default"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
