"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ENTITY_IMPORT_WIZARD_ROUTE_OLD } from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.router";
export function EntityImportSuccessPage({ goNext, result }) {
  const routerHistory = useHistory();
  const getSuccessText = (count, action) => count === 1 ? `1 record has been ${action} successfully!` : `${count} records have been ${action} successfully!`;
  if (!result) {
    routerHistory.push(ENTITY_IMPORT_WIZARD_ROUTE_OLD);
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Box, { textAlign: "center", paddingY: 6, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: "Complete \u{1F44C}" }),
    /* @__PURE__ */ jsxs(Box, { paddingY: 1, children: [
      result.updatedCount > 0 && /* @__PURE__ */ jsx(Typography, { variant: "body2", children: getSuccessText(result.updatedCount, "updated") }),
      result.importedCount > 0 && /* @__PURE__ */ jsx(Typography, { variant: "body2", children: getSuccessText(result.importedCount, "imported") })
    ] }),
    /* @__PURE__ */ jsx(Button, { onClick: () => goNext(), sx: { marginTop: 3 }, variant: "contained", children: "Close" })
  ] });
}
