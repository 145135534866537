"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { PAYROLL_COMPANY_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import { SideMenuHeader } from "@/v2/feature/payroll/components/side-menu-header.component";
import { formatPayrunPeriod } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { PayrunStatus } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const PayrunListItem = ({
  schedule,
  secondLine,
  selected,
  disabled,
  onClick
}) => {
  const { polyglot } = usePolyglot();
  const color = selected ? void 0 : themeColors.Grey;
  return /* @__PURE__ */ jsxs(
    Stack,
    {
      sx: __spreadProps(__spreadValues({
        p: spacing.p10,
        gap: spacing.g1,
        borderRadius: radius.br10
      }, !disabled && {
        cursor: "pointer",
        ":hover": { backgroundColor: themeColors.GreyHover },
        transition: "background-color .2s linear"
      }), {
        background: selected ? themeColors.Background : null,
        minWidth: "220px"
      }),
      onClick: () => !disabled && (onClick == null ? void 0 : onClick()),
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: selected ? "title4" : "caption", children: formatPayrunPeriod(schedule, polyglot.locale()) }),
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5, color }, children: [
          "state" in secondLine && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(PayrunStatus, { variant: "captionSmall", iconSize: 13, color: "inherit", state: secondLine.state }),
            /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: "inherit" }, children: "\u2022" }),
            /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: "inherit" }, children: polyglot.t("CompanyPayroll.noOfEmployees", secondLine.employeeCount) })
          ] }),
          "upcoming" in secondLine && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: "inherit" }, children: polyglot.t("CompanyPayroll.upcoming") }) })
        ] })
      ]
    }
  );
};
export const PayrunListSideMenu = ({ disabled, payroll, onPayrunSelected, selectedPayrunId, sx }) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const sortedPayruns = useMemo(() => {
    const payruns = payroll.employer ? payroll.payruns : payroll.globalPayruns;
    return payruns.slice().sort((a, b) => b.taxYear.localeCompare(a.taxYear) || b.period - a.period);
  }, [payroll]);
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(
      SideMenuHeader,
      {
        heading: payroll.displayName,
        caption: polyglot.t("CompanyPayroll.allPayruns"),
        onBackClick: () => routerHistory.replace(PAYROLL_COMPANY_ROUTE),
        sx: { mt: spacing.mt20 }
      }
    ),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden auto" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { my: spacing.my20, gap: spacing.g2 }, children: [
      payroll.nextPayRun && !payroll.currentPayRun && // this is shown when there are no currently open payruns
      /* @__PURE__ */ jsx(
        PayrunListItem,
        {
          schedule: payroll.nextPayRun,
          secondLine: { upcoming: true },
          selected: selectedPayrunId === "next-payrun",
          disabled,
          onClick: () => onPayrunSelected == null ? void 0 : onPayrunSelected("next-payrun")
        }
      ),
      sortedPayruns.map((payrun) => /* @__PURE__ */ jsx(
        PayrunListItem,
        {
          schedule: payrun,
          secondLine: {
            state: payrun.state,
            employeeCount: payrun.employeeCount
          },
          selected: payrun.id === selectedPayrunId,
          disabled,
          onClick: () => onPayrunSelected == null ? void 0 : onPayrunSelected(payrun.id)
        },
        payrun.id
      ))
    ] }) })
  ] });
};
