"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ContractBig } from "@/images/documents/ContractBig.svg";
import { ReactComponent as BenefitsBig } from "@/images/documents/DocumentBig.svg";
import { ReactComponent as NotiBig } from "@/images/documents/NotiBig.svg";
import {
  IntroCardGroup
} from "@/v2/components/theme-components/icon-content-action-card.component";
import { NewDocumentButton } from "@/v2/feature/documents/components/new-document-action-button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { parentCardSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DocumentEmptyOverview = ({
  onUploadClick,
  onRequestClick,
  isAdmin,
  onBulkUploadClick,
  onTemplateClick,
  documentTypes
}) => {
  const { polyglot } = usePolyglot();
  const DocumentCards = [
    {
      title: polyglot.t("DocumentEmptyOverview.manage"),
      content: polyglot.t("DocumentEmptyOverview.manageDescription"),
      iconMedia: /* @__PURE__ */ jsx(BenefitsBig, {})
    },
    {
      title: polyglot.t("DocumentEmptyOverview.requestUploads"),
      content: polyglot.t("DocumentEmptyOverview.requestUploadsDescription"),
      iconMedia: /* @__PURE__ */ jsx(NotiBig, {})
    },
    {
      title: polyglot.t("DocumentEmptyOverview.signContracts"),
      content: polyglot.t("DocumentEmptyOverview.signContractsDescription"),
      iconMedia: /* @__PURE__ */ jsx(ContractBig, {})
    }
  ];
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, parentCardSx), children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, color: themeColors.DarkGrey }), children: polyglot.t("DocumentEmptyOverview.documentsDescription") }),
          /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
            NewDocumentButton,
            {
              onUploadClick,
              onRequestClick,
              size: "large",
              isAdmin,
              onBulkUploadClick,
              onTemplateClick,
              documentTypes
            }
          ) })
        ] }),
        /* @__PURE__ */ jsx(IntroCardGroup, { cards: DocumentCards, sx: { mt: "auto" } })
      ]
    }
  );
};
