"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE,
  SETTINGS_ONBOARDING_FLOWS_ROUTE,
  SETTINGS_ONBOARDING_ROUTE
} from "@/lib/routes";
import { OnboardingIntro } from "@/v2/feature/onboarding/onboarding-intro.page";
import { OnboardingSettings } from "@/v2/feature/onboarding/onboarding-settings.page";
import { OnboardingTemplateEdit } from "@/v2/feature/onboarding/onboarding-template-edit.page";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
export const OnboardingSettingsRouter = () => {
  const [templates, setTemplates] = useState();
  const [showMessage] = useMessage();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      const [templates2] = yield Promise.all([OnboardingAPI.getOnboardingTemplates()]);
      setTemplates(templates2);
    } catch (error) {
      showMessage(`Onboarding failed to load. ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  if (!templates) {
    return /* @__PURE__ */ jsx(OnboardingIntro, { loading: true });
  }
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE, component: OnboardingTemplateEdit }),
    /* @__PURE__ */ jsx(Route, { path: SETTINGS_ONBOARDING_FLOWS_ROUTE, children: /* @__PURE__ */ jsx(OnboardingSettings, { templates }) }),
    /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_ONBOARDING_ROUTE, to: SETTINGS_ONBOARDING_FLOWS_ROUTE, exact: true })
  ] });
};
