"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useContext, useMemo, useState } from "react";
import { jsPDF } from "jspdf";
import { keyBy } from "lodash";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { iconSize } from "@/v2/components/forms/editable-title.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import { ResultsOverviewByQuestionModal } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-by-question-modal.component";
import { ResultsOverviewQuestionDisplayBar } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-question-display-bar.component";
import { ResultsOverviewReviewComment } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-review-comment.component";
import { ResultsOverviewReviewScoreTable } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-review-score-table.component";
import { ResultsOverviewShareResultModal } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-share-result-modal.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
import { captureExcel } from "@/v2/util/export-reports.util";
import { LocalDate } from "@/v2/util/local-date";
import { stripHtml } from "@/v2/util/string.util";
export const ReviewCycleDetailResultsOverviewPage = ({
  reviewCycle,
  cycleLoading,
  reachType
}) => {
  const { getCachedUserById } = useCachedUsers();
  const [state] = useContext(GlobalContext);
  const params = useParams();
  const { cycleId, revieweeId } = params;
  const { data: results, isLoading: resultsLoading, mutate: refreshResults, error: resultsError } = useApiClient(
    ReviewResultEndpoints.getReviewOverviewResultByReviewee(revieweeId, cycleId),
    {
      suspense: false
    }
  );
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSharableOpen, setIsSharableOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(void 0);
  const reportName = useMemo(() => `${reviewCycle.displayName}-result`, [reviewCycle.displayName]);
  const getAnswerLabel = (question, answer) => {
    var _a, _b, _c, _d, _e, _f;
    if (!(answer == null ? void 0 : answer.answer)) return "";
    if (question.type === QuestionType.ScaleQuestion)
      return `${(_b = (_a = question.scaleConfig) == null ? void 0 : _a.points) == null ? void 0 : _b[answer.answer]} (${((_d = (_c = question.scaleConfig) == null ? void 0 : _c.value) == null ? void 0 : _d[answer.answer]) || 0} out of ${Math.max(...Object.values(((_e = question.scaleConfig) == null ? void 0 : _e.value) || []).map(Number))})`;
    if (question.type === QuestionType.MultipleAnswer)
      return answer.answer.split(",").map((ans) => {
        var _a2;
        return (_a2 = question.answerOptions) == null ? void 0 : _a2[ans];
      }).filter(Boolean).join(", ");
    if (question.type === QuestionType.SingleAnswer) return ((_f = question.answerOptions) == null ? void 0 : _f[answer.answer]) || "NA";
    return "";
  };
  const generateExcel = () => {
    var _a;
    if (!results) return [];
    const fromDate = new LocalDate(reviewCycle.timelineSettings.startDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" });
    const revieweeName = ((_a = getCachedUserById(Number(revieweeId))) == null ? void 0 : _a.displayName) || "NA";
    const { questionResponseSummary } = results;
    const data = [];
    const addBoldHeader = (text) => {
      data.push([{ v: text, s: { font: { bold: true } } }]);
    };
    addBoldHeader(`${reviewCycle.displayName} - ${fromDate}` || `Review cycle - ${fromDate}`);
    addBoldHeader(`You are reviewing: ${revieweeName}`);
    addBoldHeader("");
    data.push(["Question", "Factor", "Role", "Name", "Answer", "Comment"]);
    (questionResponseSummary || []).forEach((question) => {
      const { questionMain, questionSelf, factor, answers } = question;
      answers.forEach((answer) => {
        var _a2, _b;
        const { answerType, comment } = answer;
        const questionText = answerType === ReviewerTypes.Self ? questionSelf : questionMain;
        const username = ((_a2 = results == null ? void 0 : results.visibilitySettings) == null ? void 0 : _a2.hidePeerAuthor) && !answer.reviewerId ? "Author hidden" : ((_b = getCachedUserById(answer.reviewerId)) == null ? void 0 : _b.displayName) || "NA";
        const commentText = comment ? stripHtml(comment) : "";
        const answerLabel = getAnswerLabel(question, answer);
        data.push([questionText, factor, answerType, username, answerLabel, commentText]);
      });
    });
    captureExcel(data, reportName);
  };
  const generatePdfReport = (results2, reviewCycle2, reportName2) => {
    var _a;
    if (!results2) return;
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFont("Times-Roman", "bold");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 14;
    const maxWidth = pageWidth - margin * 2;
    const lineHeight = 8;
    const textLineHeight = 6;
    let yPosition = 22;
    const checkAndAddPage = () => {
      if (yPosition + lineHeight > pageHeight - margin) {
        doc.addPage();
        yPosition = margin + lineHeight;
      }
    };
    const revieweeName = ((_a = getCachedUserById(Number(revieweeId))) == null ? void 0 : _a.displayName) || "NA";
    doc.setFontSize(18);
    doc.text(`${reviewCycle2.displayName}`, margin, yPosition);
    yPosition += textLineHeight;
    doc.setFont("Times-Roman", "normal");
    doc.setFontSize(12);
    doc.text(
      `Start Date: ${new LocalDate(reviewCycle2.timelineSettings.startDate).toLocaleDateString()}`,
      margin,
      yPosition
    );
    yPosition += textLineHeight;
    doc.text(`You are reviewing: ${revieweeName}`, margin, yPosition);
    yPosition += textLineHeight * 2;
    doc.setFont("Times-Roman", "bold");
    doc.setFontSize(14);
    doc.text("Review results", margin, yPosition);
    yPosition += 4;
    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += textLineHeight;
    results2.questionResponseSummary.forEach((question, idx) => {
      yPosition += lineHeight;
      const { questionMain, questionSelf, answers, type } = question;
      const questionText = questionMain || questionSelf;
      doc.setFont("Times-Roman", "bold");
      doc.setFontSize(12);
      const splitQuestionText = doc.splitTextToSize(`${questionText}`, maxWidth);
      splitQuestionText.forEach((line) => {
        checkAndAddPage();
        doc.text(line, margin, yPosition);
        yPosition += textLineHeight;
      });
      yPosition += 1;
      answers.forEach((answer) => {
        var _a2, _b;
        const { comment } = answer;
        const username = ((_a2 = results2 == null ? void 0 : results2.visibilitySettings) == null ? void 0 : _a2.hidePeerAuthor) && !answer.reviewerId ? "Author hidden" : ((_b = getCachedUserById(answer.reviewerId)) == null ? void 0 : _b.displayName) || "NA";
        const commentText = comment ? stripHtml(comment) : "";
        const answerLabel = type === QuestionType.OpenEnded ? commentText : getAnswerLabel(question, answer);
        doc.setFont("Times-Roman", "normal");
        doc.setFontSize(12);
        const answerText = `[${username}] ${answerLabel}`;
        const splitAnswerText = doc.splitTextToSize(answerText, maxWidth);
        splitAnswerText.forEach((line) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });
        if (commentText && type !== QuestionType.OpenEnded) {
          const splitCommentText = doc.splitTextToSize(`[Comment]: ${commentText}`, maxWidth);
          splitCommentText.forEach((line) => {
            checkAndAddPage();
            doc.text(line, margin, yPosition);
            yPosition += textLineHeight;
          });
        }
        yPosition += 2;
      });
      yPosition += lineHeight / 2;
      checkAndAddPage();
      if (idx !== results2.questionResponseSummary.length - 1) {
        doc.setLineWidth(0.5);
        doc.setDrawColor(0);
        doc.line(margin, yPosition, pageWidth - margin, yPosition);
        yPosition += lineHeight / 2;
      }
    });
    if (results2 == null ? void 0 : results2.comments) {
      yPosition += textLineHeight * 2;
      doc.setFont("Times-Roman", "bold");
      doc.setFontSize(14);
      doc.text("Review comments", margin, yPosition);
      yPosition += 4;
      doc.setLineWidth(1);
      doc.setDrawColor(0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
      yPosition += textLineHeight;
      results2.comments.forEach((commentObj) => {
        var _a2, _b;
        const commentText = stripHtml((_a2 = commentObj == null ? void 0 : commentObj.comment) != null ? _a2 : "");
        const isPublic = commentObj.isPublic ? "Public comment" : "Private comment";
        const username = ((_b = getCachedUserById(commentObj.commentBy)) == null ? void 0 : _b.displayName) || "NA";
        doc.setFont("Times-Roman", "normal");
        doc.setFontSize(12);
        const commentHeader = `[${isPublic} by ${username}]:`;
        const splitCommentHeader = doc.splitTextToSize(commentHeader, maxWidth);
        splitCommentHeader.forEach((line) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });
        const splitCommentText = doc.splitTextToSize(commentText, maxWidth);
        splitCommentText.forEach((line) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });
        yPosition += lineHeight;
      });
    }
    doc.save(`${reportName2}.pdf`);
  };
  if (!results && resultsError && resultsError.status === 403) {
    return /* @__PURE__ */ jsx("div", { style: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) });
  }
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      id: reportName,
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      loading: cycleLoading,
      buttons: [
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                handler: () => generatePdfReport(results, reviewCycle, reportName),
                label: "PDF report"
              },
              {
                handler: () => generateExcel(),
                label: "Excel report"
              }
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Export",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ResultsOverviewReviewScoreTable,
                      {
                        results,
                        reviewCycle,
                        reviewScore: results ? results.reviewerScoreSummary : [],
                        resultsLoading: Boolean(resultsLoading),
                        setIsSharableOpen,
                        reachType
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                title: "Results",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ResultsOverviewScoreByAnswers,
                      {
                        answerScore: results ? results.questionResponseSummary : [],
                        setIsOpen,
                        setSelectedQuestion,
                        growthFactors
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                title: "Comments",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ResultsOverviewReviewComment,
                      {
                        results,
                        refreshResults,
                        loggedInUser: state.user.userId,
                        reachType
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          ResultsOverviewByQuestionModal,
          {
            selectedQuestion,
            isOpen,
            setIsOpen,
            onClose: () => {
              setIsOpen(false);
            },
            growthFactors
          }
        ),
        /* @__PURE__ */ jsx(
          ResultsOverviewShareResultModal,
          {
            isOpen: isSharableOpen,
            setIsOpen: setIsSharableOpen,
            onClose: () => {
              setIsSharableOpen(false);
            },
            results,
            refreshResults
          }
        )
      ]
    }
  );
};
const ResultsOverviewScoreByAnswers = ({
  answerScore,
  setIsOpen,
  setSelectedQuestion,
  growthFactors
}) => {
  return /* @__PURE__ */ jsx(Fragment, { children: answerScore.map((question, idx) => {
    return /* @__PURE__ */ jsx(
      ResultsOverviewQuestionDisplayBar,
      {
        question,
        growthFactors,
        onClick: () => {
          setIsOpen(true);
          setSelectedQuestion(question);
        }
      },
      idx
    );
  }) });
};
