"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { AttendanceScheduleSelect } from "@v2/components/attendance-schedule-select.component";
import { HolidayCalendarEndpoints } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { CustomCountryEnum } from "@v2/infrastructure/country/country.interface";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ContractsAPI } from "@/api-client/contracts.api";
import {
  UserContractKinds
} from "@/component/dashboard/userDetails/validations/userFormDefinitions";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ContractEndDatePickerComponent } from "@/v2/components/contract-end-date-picker.component";
import { EntitySelect } from "@/v2/components/entity-select.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { getPublicHolidayCalendarOptions } from "@/v2/feature/absence/absence.util";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import {
  getEmploymentTypeOptions,
  getUnitTypeOptions
} from "@/v2/feature/user/features/user-profile/user-profile.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NewEmployeeContractSection = ({
  contract,
  onNext,
  startDate,
  sx,
  userId
}) => {
  var _a, _b, _c, _d;
  const { data: holidayCalendars } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendars(), { suspense: false });
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = contract ? "edit" : "add";
  const NewEmployeeContractSchema = useMemo(
    () => Yup.object().shape({
      type: Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.employmentTypeRequired")),
      contract: Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.contractTypeRequired")),
      entityId: Yup.number().nullable().optional(),
      attendanceScheduleId: Yup.number().nullable().optional(),
      publicHolidays: Yup.string().required(
        polyglot.t("NewEmployeeContractSection.errorMessages.publicCalendarRequired")
      ),
      probationPeriodUnit: Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.probationValueRequired")),
      probationPeriodLength: Yup.number().required(polyglot.t("NewEmployeeContractSection.errorMessages.probationValueRequired")).integer(),
      noticePeriodUnit: Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.noticeValueRequired")),
      noticePeriodLength: Yup.number().required(polyglot.t("NewEmployeeContractSection.errorMessages.noticeValueRequired")).integer(),
      effectiveDate: Yup.string().test(dateFieldTest).required("Required"),
      contractEndDate: Yup.date().nullable().when(["effectiveDate"], (effectiveDate, schema) => {
        return schema.nullable().min(effectiveDate, "Invalid end date. Please chose a date equal or greater than the effective date.").notRequired();
      })
    }),
    [polyglot]
  );
  const customHolidayCalendarsOptions = useMemo(() => {
    if (!holidayCalendars) return [];
    return holidayCalendars.map((hc) => ({ label: hc.name, value: hc.id }));
  }, [holidayCalendars]);
  const defaultUserContractValues = useMemo(
    () => ({
      effectiveDate: new LocalDate(startDate).toDateString(),
      contract: "Full-time",
      type: "Employee",
      ftePercent: 100,
      publicHolidays: "uk",
      changeReason: void 0,
      noticePeriodLength: 1,
      noticePeriodUnit: "Months",
      probationPeriodLength: 1,
      probationPeriodUnit: "Months",
      entityId: null,
      attendanceScheduleId: null,
      contractEndDate: null,
      customUpdates: []
    }),
    [startDate]
  );
  const formik = useFormik({
    initialValues: contract != null ? contract : defaultUserContractValues,
    validateOnMount: true,
    validationSchema: NewEmployeeContractSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        if ("id" in values) {
          const update = {
            id: Number(values.id),
            effectiveDate: values.effectiveDate,
            contract: values.contract,
            type: values.type,
            ftePercent: Number(values.ftePercent),
            publicHolidays: values.publicHolidays,
            holidayCalendarId: values.publicHolidays === CustomCountryEnum.code ? values.holidayCalendarId : null,
            changeReason: values.changeReason,
            noticePeriodLength: values.noticePeriodLength ? Number(values.noticePeriodLength) : 0,
            noticePeriodUnit: values.noticePeriodUnit,
            probationPeriodLength: values.probationPeriodLength ? Number(values.probationPeriodLength) : 0,
            probationPeriodUnit: values.probationPeriodUnit,
            entityId: values.entityId,
            attendanceScheduleId: values.attendanceScheduleId,
            contractEndDate: values.contractEndDate,
            customUpdates: values.customUpdates
          };
          yield ContractsAPI.updateById(userId, update);
        } else {
          const create = {
            effectiveDate: values.effectiveDate,
            contract: values.contract,
            type: values.type,
            ftePercent: Number(values.ftePercent),
            publicHolidays: values.publicHolidays,
            holidayCalendarId: values.publicHolidays === CustomCountryEnum.code ? values.holidayCalendarId : null,
            changeReason: values.changeReason,
            noticePeriodLength: values.noticePeriodLength ? Number(values.noticePeriodLength) : 0,
            noticePeriodUnit: values.noticePeriodUnit,
            probationPeriodLength: values.probationPeriodLength ? Number(values.probationPeriodLength) : 0,
            probationPeriodUnit: values.probationPeriodUnit,
            entityId: values.entityId,
            attendanceScheduleId: values.attendanceScheduleId,
            contractEndDate: values.contractEndDate,
            customUpdates: values.customUpdates
          };
          yield ContractsAPI.createUserContract(userId, create);
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeContractSection.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("NewEmployeeContractSection.contract") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: polyglot.t("NewEmployeeContractSection.type"),
        options: getEmploymentTypeOptions(polyglot),
        value: formik.values.type,
        onChange: formik.handleChange,
        disabled: saving,
        helperText: hasSubmitted && formik.errors.type,
        error: hasSubmitted && !!formik.errors.type
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "contract",
        label: polyglot.t("NewEmployeeContractSection.contractLabel"),
        options: UserContractKinds(polyglot),
        value: formik.values.contract,
        onChange: formik.handleChange,
        disabled: saving,
        helperText: hasSubmitted && formik.errors.contract,
        error: hasSubmitted && !!formik.errors.contract
      }
    ),
    /* @__PURE__ */ jsx(
      EntitySelect,
      {
        name: "entityId",
        label: polyglot.t("NewEmployeeContractSection.entityId"),
        value: formik.values.entityId,
        disabled: saving,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.entityId,
        helperText: hasSubmitted && formik.errors.entityId,
        autoSelectSingleEntity: (entityId) => formik.setFieldValue("entityId", entityId)
      }
    ),
    /* @__PURE__ */ jsx(
      AttendanceScheduleSelect,
      {
        name: "attendanceScheduleId",
        label: polyglot.t("NewEmployeeContractSection.attendanceScheduleId"),
        value: formik.values.attendanceScheduleId,
        disabled: saving,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.attendanceScheduleId,
        helperText: hasSubmitted && formik.errors.attendanceScheduleId,
        autoSelectSingleEntity: (attendanceScheduleId) => formik.setFieldValue("attendanceScheduleId", attendanceScheduleId)
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "publicHolidays",
        label: polyglot.t("NewEmployeeContractSection.publicHolidays"),
        options: getPublicHolidayCalendarOptions({ includeCustom: true }),
        value: formik.values.publicHolidays,
        disabled: saving,
        onChange: formik.handleChange,
        helperText: hasSubmitted && formik.errors.publicHolidays,
        error: hasSubmitted && !!formik.errors.publicHolidays
      }
    ),
    formik.values.publicHolidays === CustomCountryEnum.code && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "holidayCalendarId",
        label: polyglot.t("HolidayCalendarModule.customHolidayCalendar"),
        options: customHolidayCalendarsOptions,
        value: formik.values.holidayCalendarId,
        disabled: saving,
        onChange: formik.handleChange,
        helperText: hasSubmitted && formik.errors.holidayCalendarId,
        error: hasSubmitted && !!formik.errors.holidayCalendarId
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "probationPeriodLength",
          label: polyglot.t("NewEmployeeContractSection.probationPeriodLength"),
          type: "tel",
          value: (_b = (_a = formik.values.probationPeriodLength) == null ? void 0 : _a.toString()) != null ? _b : "",
          disabled: saving,
          onChange: (e) => {
            var _a2, _b2;
            const newValue = Number((_b2 = (_a2 = e.target.value.match(/^\d{1,2}/)) == null ? void 0 : _a2[0]) != null ? _b2 : "nan");
            formik.setFieldValue("probationPeriodLength", Number.isInteger(newValue) ? newValue : void 0);
          },
          clearText: () => formik.setFieldValue("probationPeriodLength", 0),
          helperText: hasSubmitted && formik.errors.probationPeriodLength,
          error: hasSubmitted && !!formik.errors.probationPeriodLength
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "probationPeriodUnit",
          label: polyglot.t("NewEmployeeContractSection.probationPeriodUnit"),
          options: getUnitTypeOptions(polyglot),
          value: formik.values.probationPeriodUnit,
          disabled: saving,
          onChange: formik.handleChange,
          helperText: hasSubmitted && formik.errors.probationPeriodUnit,
          error: hasSubmitted && !!formik.errors.probationPeriodUnit
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "noticePeriodLength",
          label: polyglot.t("NewEmployeeContractSection.noticePeriodUnit"),
          type: "tel",
          value: (_d = (_c = formik.values.noticePeriodLength) == null ? void 0 : _c.toString()) != null ? _d : "",
          disabled: saving,
          onChange: (e) => {
            var _a2, _b2;
            const newValue = Number((_b2 = (_a2 = e.target.value.match(/^\d{1,2}/)) == null ? void 0 : _a2[0]) != null ? _b2 : "nan");
            formik.setFieldValue("noticePeriodLength", Number.isInteger(newValue) ? newValue : void 0);
          },
          clearText: () => formik.setFieldValue("noticePeriodLength", 0),
          helperText: hasSubmitted && formik.errors.noticePeriodLength,
          error: hasSubmitted && !!formik.errors.noticePeriodLength
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "noticePeriodUnit",
          label: polyglot.t("NewEmployeeContractSection.noticePeriodMeasure"),
          options: getUnitTypeOptions(polyglot),
          value: formik.values.noticePeriodUnit,
          disabled: saving,
          onChange: formik.handleChange,
          helperText: hasSubmitted && formik.errors.noticePeriodUnit,
          error: hasSubmitted && !!formik.errors.noticePeriodUnit
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        rowModalMode,
        formName: CustomProfileFormType.Contract,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      ContractEndDatePickerComponent,
      {
        name: "contractEndDate",
        label: "End date",
        value: formik.values.contractEndDate,
        onChange: (value) => formik.setFieldValue("contractEndDate", value),
        effectiveDate: formik.values.effectiveDate,
        error: !!formik.errors.contractEndDate,
        helperText: formik.errors.contractEndDate
      }
    ),
    formik.isValid && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        colorVariant: "primary",
        sizeVariant: "large",
        fullWidth: true
      }
    )
  ] }) }) });
};
