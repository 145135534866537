"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const iconButtonSx = {
  borderRadius: radius.br10,
  p: spacing.p10,
  width: 40,
  height: 40,
  backgroundColor: themeColors.Background,
  fill: themeColors.DarkGrey,
  color: themeColors.DarkGrey,
  objectFit: "contain",
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
};
export const tableIconButtonSx = __spreadProps(__spreadValues({}, themeFonts.caption), {
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  objectFit: "contain",
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
});
export const tableWhiteIconButtonSx = __spreadProps(__spreadValues({}, themeFonts.caption), {
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  objectFit: "contain",
  backgroundColor: themeColors.white,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.TableHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.Grey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
});
export const tableIconErrorButtonSx = __spreadProps(__spreadValues({}, themeFonts.caption), {
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  objectFit: "contain",
  backgroundColor: themeColors.Red,
  color: themeColors.white,
  fill: themeColors.white,
  "&:hover": {
    backgroundColor: themeColors.hoverRed,
    fill: themeColors.white
  },
  "&:target": {
    backgroundColor: themeColors.pressRed,
    fill: themeColors.white
  },
  "&:disabled": {
    backgroundColor: themeColors.lightRed,
    fill: themeColors.white,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
});
export const tablePrimaryIconButtonSx = __spreadProps(__spreadValues({}, themeFonts.caption), {
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  backgroundColor: themeColors.ZeltYellow,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.YellowHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.YellowPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.DisabledYellow,
    fill: themeColors.DarkGrey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
});
export const tableSecondaryIconButtonSx = __spreadProps(__spreadValues({}, themeFonts.caption), {
  borderRadius: radius.br8,
  p: spacing.p8,
  width: 30,
  height: 30,
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
});
export const paginationIconButtonSx = __spreadProps(__spreadValues({}, themeFonts.caption), {
  borderRadius: radius.br8,
  p: spacing.p8,
  minWidth: 30,
  height: 30,
  marginLeft: spacing.m5,
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
});
export const paginationActiveStyle = __spreadProps(__spreadValues({}, themeFonts.caption), {
  fill: themeColors.DarkGrey,
  backgroundColor: themeColors.DarkGrey,
  color: themeColors.white,
  "&:hover": {
    color: themeColors.white,
    fill: themeColors.DarkGrey,
    backgroundColor: themeColors.DarkGrey
  }
});
export const iconCrossButtonSx = {
  borderRadius: radius.br10,
  p: spacing.p10,
  width: 40,
  height: 40,
  fontSize: "20px",
  backgroundColor: themeColors.Background,
  fill: themeColors.DarkGrey,
  color: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
};
export const iconCrossPrimaryButtonSx = {
  borderRadius: radius.br10,
  p: spacing.p10,
  width: 40,
  height: 40,
  fontSize: "20px",
  backgroundColor: themeColors.ZeltYellow,
  fill: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.YellowHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.YellowPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.DisabledYellow,
    fill: themeColors.DarkGrey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
};
export const hazardMissingField = {
  width: "20px",
  height: "20px",
  borderRadius: radius.br15,
  backgroundColor: themeColors.red,
  fill: themeColors.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
export const iconCTAButtonSx = {
  borderRadius: radius.br10,
  p: spacing.p5,
  width: 30,
  height: 30,
  fontSize: "20px",
  backgroundColor: themeColors.Background,
  fill: themeColors.DarkGrey,
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    fill: themeColors.Grey,
    cursor: "not-allowed",
    pointerEvents: "all",
    "&:hover": {
      cursor: "not-allowed"
    }
  }
};
