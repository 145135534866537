"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { extractTimeOnlyFromDateTimeString } from "@v2/util/date-format.util";
import { format } from "date-fns";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as WaitingEmpty } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AttendanceEndpoints } from "@/v2/feature/attendance/attendance.api";
import { ScheduleTrackingType } from "@/v2/feature/attendance/attendance.interface";
import { getAttendanceEntriesStatus, getAttendanceStatusIcon } from "@/v2/feature/attendance/attendance.util";
import { EditTrackTimeDrawerContent } from "@/v2/feature/attendance/company/components/track-time-drawer.component";
import { LabelValueViewItem } from "@/v2/feature/dashboard/features/sections/user-attendance/components/label-value-view-item.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const RegularAttendanceContent = ({
  userId,
  selectedDate,
  refreshWidgetData,
  setIsViewOpen
}) => {
  const { polyglot } = usePolyglot();
  const logDate = useMemo(() => new LocalDate(selectedDate).toDateString(), [selectedDate]);
  const { data: attendanceSchedule, isLoading: loadingSchedule } = useApiClient(
    userId ? AttendanceEndpoints.getUserAttendanceScheduleByLogDate(userId, logDate != null ? logDate : new LocalDate().toDateString()) : null,
    { suspense: false }
  );
  const { data: userAttendanceEntries, isLoading: loadingAttendance, mutate: refreshEntries } = useApiClient(
    (attendanceSchedule == null ? void 0 : attendanceSchedule.trackingType) !== ScheduleTrackingType.ClockInClockOut ? AttendanceEndpoints.getUserAttendanceEntriesByLogDate(userId, logDate) : null,
    { suspense: false }
  );
  const [isTrackTimeOpen, setIsTrackTimeOpen] = useState(false);
  const refreshUserAttendanceEntries = useCallback(() => __async(void 0, null, function* () {
    if (refreshEntries) yield refreshEntries();
  }), [refreshEntries]);
  useEffect(() => {
    refreshUserAttendanceEntries();
  }, [refreshUserAttendanceEntries]);
  return /* @__PURE__ */ jsx(Box, { sx: drawerContentSx, children: loadingAttendance || loadingSchedule ? /* @__PURE__ */ jsx(RegularDrawer, {}) : !isTrackTimeOpen ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("getUserAttendanceData.attendance") }),
    userAttendanceEntries && attendanceSchedule ? /* @__PURE__ */ jsx(
      ViewPendingAttendance,
      {
        userId,
        userAttendanceEntries: userAttendanceEntries != null ? userAttendanceEntries : [],
        selectedDate,
        setIsTrackTimeOpen,
        attendanceSchedule
      }
    ) : /* @__PURE__ */ jsx(ViewNewAttendance, { selectedDate, setIsTrackTimeOpen })
  ] }) : /* @__PURE__ */ jsx(
    EditTrackTimeDrawerContent,
    {
      refresh: () => __async(void 0, null, function* () {
        yield refreshWidgetData == null ? void 0 : refreshWidgetData();
        setIsViewOpen(false);
      }),
      userId,
      setIsOpen: setIsViewOpen,
      attendanceEntries: userAttendanceEntries != null ? userAttendanceEntries : [],
      view: "user",
      logDate,
      userSchedule: attendanceSchedule
    }
  ) });
};
const ViewPendingAttendance = ({
  userId,
  userAttendanceEntries,
  selectedDate,
  setIsTrackTimeOpen,
  attendanceSchedule
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const hasAttendanceScopeForUser = hasScopes(["attendance"], { userId });
  const hasManagerAttendanceScopeForUser = hasScopes(["attendance:manager"], { userId });
  const attendanceTypesAllowedById = useMemo(
    () => {
      var _a2, _b;
      return (_b = (_a2 = attendanceSchedule == null ? void 0 : attendanceSchedule.attendanceTypesAllowed) == null ? void 0 : _a2.reduce((response, type) => {
        response[type.id] = type;
        return response;
      }, {})) != null ? _b : {};
    },
    [attendanceSchedule]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(
        LabelValueViewItem,
        {
          label: polyglot.t("ViewPendingAttendance.status"),
          value: userAttendanceEntries.length > 0 ? getAttendanceStatusIcon((_a = getAttendanceEntriesStatus(userAttendanceEntries)) != null ? _a : "none", false, polyglot) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("ViewPendingAttendance.notSubmitted") })
          ] })
        }
      ),
      /* @__PURE__ */ jsx(
        LabelValueViewItem,
        {
          label: polyglot.t("ViewPendingAttendance.date"),
          value: `${format(new LocalDate(selectedDate != null ? selectedDate : /* @__PURE__ */ new Date()).getDate(), "d MMM yyyy")}`
        }
      )
    ] }),
    userAttendanceEntries.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("ViewPendingAttendance.hoursLogged") }),
      userAttendanceEntries.map((entry, index) => {
        var _a2, _b;
        return /* @__PURE__ */ jsx(
          LabelValueViewItem,
          {
            label: (_b = (_a2 = attendanceTypesAllowedById[entry.typeId]) == null ? void 0 : _a2.name) != null ? _b : "N/A",
            value: `${extractTimeOnlyFromDateTimeString(entry.startHour)} - ${extractTimeOnlyFromDateTimeString(
              entry.endHour
            )}`
          },
          `entry-${index}`
        );
      })
    ] }),
    (hasManagerAttendanceScopeForUser || hasAttendanceScopeForUser && (attendanceSchedule == null ? void 0 : attendanceSchedule.trackingType) !== ScheduleTrackingType.ClockInClockOut) && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "secondary",
        fullWidth: true,
        onClick: () => {
          setIsTrackTimeOpen(true);
        },
        children: polyglot.t("General.edit")
      }
    ) })
  ] });
};
const ViewNewAttendance = ({
  selectedDate,
  setIsTrackTimeOpen
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      LabelValueViewItem,
      {
        label: polyglot.t("ViewNewAttendance.status"),
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("ViewNewAttendance.notSubmitted") })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(
      LabelValueViewItem,
      {
        label: polyglot.t("ViewNewAttendance.date"),
        value: `${format(new LocalDate(selectedDate != null ? selectedDate : /* @__PURE__ */ new Date()).getDate(), "d MMM yyyy")}`
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        onClick: () => {
          setIsTrackTimeOpen(true);
        },
        children: polyglot.t("ViewNewAttendance.logHours")
      }
    ) })
  ] });
};
const RegularDrawer = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ViewNewAttendance.attendance") }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, marginTop: spacing.m30 }, children: [0, 1].map((num) => /* @__PURE__ */ jsx(Skeleton, { height: 20, width: "100%", style: { background: themeColors.Background } }, `${num}-isx`)) })
  ] });
};
