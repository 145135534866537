"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { SETTINGS_BILLING_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ZeltMdmUpgradeToPro = () => {
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Upgrade to Pro plan" }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10 }), children: "Upgrade to Devices Pro to save on renting devices for your team, protect them and manage security remotely and much more." }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: "40px" },
        fullWidth: true,
        onClick: () => {
          routerHistory.push(SETTINGS_BILLING_ROUTE);
        },
        children: "Go to billing"
      }
    )
  ] });
};
