"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Box, FormControl, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT } from "@v2/feature/task/subfeature/checklist/components/checklist-item-form-modal.component";
import { TaskStatuses } from "@v2/feature/task/task.interface";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as QuestionCircle } from "@/images/app-icons/QuestionCircle.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  AttachmentComponent,
  attachmentComponentValueToString,
  stringToAttachmentComponentValue
} from "@/v2/components/forms/attachment.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const TaskFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  formData,
  refresh,
  userId,
  isChecklist = false,
  reach
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const modalType = (formData == null ? void 0 : formData.id) ? "edit" : "new";
  const users = useMemo(() => {
    const reports = new Set(globalState.user.reports);
    return reach === "team" ? nonTerminatedCachedUsers.filter((u) => reports.has(u.userId) || u.userId === globalState.user.userId).map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    }) : nonTerminatedCachedUsers.map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    });
  }, [globalState.user.reports, globalState.user.userId, nonTerminatedCachedUsers, reach]);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const createTask = useCallback(
    (task) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.createTasksInBulk(task);
        showMessage(polyglot.t("TasksListPage.successMessages.create"), "success");
      } catch (error) {
        showMessage(
          polyglot.t("TasksListPage.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  const updateTask = useCallback(
    (data) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.updateTask(data);
      } catch (error) {
        showMessage(
          polyglot.t("TasksListPage.errorMessages.update", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  const deleteTask = useCallback(
    (taskId) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.removeTask(taskId);
        showMessage(polyglot.t("TasksListPage.successMessages.delete"), "success");
      } catch (error) {
        showMessage(
          polyglot.t("TasksListPage.errorMessages.remove", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  const handleSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      setLoading(true);
      const { dueDate, assignedUserIds } = values;
      if (assignedUserIds == null ? void 0 : assignedUserIds.length) {
        if (formData == null ? void 0 : formData.id) {
          const data = __spreadProps(__spreadValues({}, values), {
            id: formData == null ? void 0 : formData.id,
            dueDate: dueDate != null ? dueDate : todaysDateShortISOString(),
            assignedUserIds
          });
          yield updateTask(data);
        } else {
          const newTask = __spreadProps(__spreadValues({}, values), {
            dueDate: dueDate != null ? dueDate : todaysDateShortISOString(),
            assignedUserIds
          });
          yield createTask(newTask);
        }
      }
      if (refresh) yield refresh == null ? void 0 : refresh();
      setLoading(false);
      if (onClose) onClose();
      setIsOpen(false);
    }),
    [setIsOpen, createTask, formData == null ? void 0 : formData.id, onClose, refresh, updateTask]
  );
  const userOptions = useMemo(() => {
    if (formData == null ? void 0 : formData.assignedUserId) return [formData == null ? void 0 : formData.assignedUserId];
    if (userId) return [userId];
    return [];
  }, [formData == null ? void 0 : formData.assignedUserId, userId]);
  const validationSchema = useMemo(
    () => Yup.object({
      name: Yup.string().required(polyglot.t("TaskFormModal.errorMessages.nameRequired")),
      dueDate: Yup.string().test(dateFieldTest).nullable().notRequired(),
      description: Yup.string().nullable().notRequired(),
      assignedUserIds: isChecklist ? Yup.number().nullable().notRequired() : Yup.array().required("Required"),
      attachmentValues: Yup.string().nullable().notRequired()
    }),
    [polyglot, isChecklist]
  );
  const formik = useFormik({
    initialValues: {
      name: (_a = formData == null ? void 0 : formData.name) != null ? _a : "",
      description: (_b = formData == null ? void 0 : formData.description) != null ? _b : "",
      dueDate: (_c = formData == null ? void 0 : formData.dueDate) != null ? _c : null,
      assignedUserId: (formData == null ? void 0 : formData.assignedUserId) || userId || null,
      assignedUserIds: userOptions,
      requestedForId: (_d = formData == null ? void 0 : formData.requestedForId) != null ? _d : null,
      status: TaskStatuses.INCOMPLETE,
      taskForSomebodyElse: Boolean(!!(formData == null ? void 0 : formData.requestedForId)),
      attachmentValues: (_e = formData == null ? void 0 : formData.attachmentValues) != null ? _e : void 0
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center"
        },
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: modalType === "edit" ? polyglot.t("TaskFormModal.edit") : polyglot.t("TaskFormModal.new") }),
          modalType === "edit" && /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: iconButtonSx,
              onClick: (event) => {
                setAnchorEl(event.currentTarget);
              },
              children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("TaskFormModal.name"),
        value: formik.values.name,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_f = formik.touched.name && formik.errors.name) != null ? _f : " ",
        endAdornment: "none",
        autoFocus: true
      }
    ),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: polyglot.t("TaskFormModal.description"),
        value: (_g = formik.values.description) != null ? _g : "",
        onChange: (value) => formik.setFieldValue("description", value)
      }
    ),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        shortcuts: true,
        value: (_h = formik.values.dueDate) != null ? _h : todaysDateShortISOString(),
        onChange: (value) => {
          formik.setFieldValue("dueDate", value);
        },
        name: "dueDate",
        label: polyglot.t("TaskFormModal.dueDate"),
        error: !!formik.errors.dueDate && Boolean(formik.touched.dueDate),
        helperText: formik.errors.dueDate && Boolean(formik.touched.dueDate)
      }
    ) }),
    modalType === "edit" && formik.values.assignedUserId && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("TaskViewModal.assignedTo") }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5, display: "flex", alignItem: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(UserCell, { userId: formik.values.assignedUserId }) })
    ] }),
    modalType === "new" && /* @__PURE__ */ jsx(Tooltip, { title: polyglot.t("TaskFormModal.whoShouldComplete"), placement: "top-start", children: /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("TaskFormModal.whoShouldComplete"),
        selectedLabel: polyglot.t("TaskFormModal.selectedEmployees"),
        value: (_i = formik.values.assignedUserIds) != null ? _i : [],
        onChange: (userIds) => {
          formik.setFieldValue("assignedUserIds", userIds);
        },
        userOptions: users
      }
    ) }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "taskForSomebodyElse",
        label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("TaskFormModal.someoneElse") }),
          /* @__PURE__ */ jsx(StyledTooltip, { title: TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT(polyglot), children: /* @__PURE__ */ jsx(QuestionCircle, { style: { height: 12, width: 12 }, fill: themeColors.GreyMiddle }) })
        ] }),
        checked: formik.values.taskForSomebodyElse,
        onChange: (_, checked) => {
          formik.setFieldValue("taskForSomebodyElse", checked);
          if (!checked) formik.setFieldValue("requestedForId", null);
        }
      }
    ),
    formik.values.taskForSomebodyElse && /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "requestedForId",
        options: users,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("requestedForId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        value: formik.values.requestedForId,
        label: polyglot.t("TaskFormModal.requestedForId"),
        error: formik.touched.requestedForId && Boolean(formik.errors.requestedForId),
        helperText: formik.touched.requestedForId && formik.errors.requestedForId
      }
    ),
    /* @__PURE__ */ jsx(
      AttachmentComponent,
      {
        variant: "caption",
        disabled: false,
        label: "Add a file",
        name: "attachmentValues",
        showSelectExisting: true,
        value: stringToAttachmentComponentValue((_j = formik.values.attachmentValues) != null ? _j : ""),
        onChange: (value) => {
          formik == null ? void 0 : formik.setFieldValue("attachmentValues", attachmentComponentValueToString(value));
        },
        error: formik.touched.attachmentValues && Boolean(formik.errors.attachmentValues),
        helperText: formik.touched.attachmentValues && formik.errors.attachmentValues
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        disabled: !formik.isValid || !formik.dirty || loading || ((_k = formik.values.assignedUserIds) == null ? void 0 : _k.length) === 0
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: Boolean(anchorEl),
        onClose: () => setAnchorEl(null),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (formData == null ? void 0 : formData.id) yield deleteTask(formData.id);
          if (refresh) yield refresh == null ? void 0 : refresh();
          setAnchorEl(null);
          if (onClose) onClose();
          setIsOpen(false);
        }),
        message: polyglot.t("TaskFormModal.confirmDelete"),
        callToAction: polyglot.t("General.yes")
      }
    )
  ] }) }) });
};
