"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { BooleanSelector } from "@v2/feature/reports/reports-advanced/filters-components/boolean-selector.component";
import { DateonlySelector } from "@v2/feature/reports/reports-advanced/filters-components/dateonly-selector.component";
import { DateTimeSelector } from "@v2/feature/reports/reports-advanced/filters-components/datetime-selector.component";
import { IntOptionSelector } from "@v2/feature/reports/reports-advanced/filters-components/int-option-selector.component";
import { NotImplemented } from "@v2/feature/reports/reports-advanced/filters-components/not-implemented.component";
import { NumberSelector } from "@v2/feature/reports/reports-advanced/filters-components/number-selector.component";
import { StringOptionSelector } from "@v2/feature/reports/reports-advanced/filters-components/string-option-selector.component";
import { StringSelector } from "@v2/feature/reports/reports-advanced/filters-components/string-selector.component";
import { FilterTypeOption } from "@v2/feature/reports/reports.interface";
const SelectorByFilterType = {
  [FilterTypeOption.number]: () => NumberSelector,
  [FilterTypeOption.string]: () => StringSelector,
  [FilterTypeOption.stringOption]: () => StringOptionSelector,
  [FilterTypeOption.intOption]: () => IntOptionSelector,
  [FilterTypeOption.dateonly]: () => DateonlySelector,
  [FilterTypeOption.boolean]: () => BooleanSelector,
  [FilterTypeOption.datetime]: () => DateTimeSelector,
  // Time does not support filters right now - we don't really have time only columns
  [FilterTypeOption.time]: () => NotImplemented
};
export const FilterValueSelector = (_a) => {
  var _b = _a, { filterType } = _b, props = __objRest(_b, ["filterType"]);
  const SelectedComponent = SelectorByFilterType[filterType] ? SelectorByFilterType[filterType]() : NotImplemented;
  return /* @__PURE__ */ jsx(SelectedComponent, __spreadValues({}, props));
};
