"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { SettingsReadOnlyLine } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { WeekDayNames } from "@v2/feature/attendance/attendance.interface";
import {
  getAllTimeFromScheduleByWeekIndex,
  getTotalLengthOfDayFromScheduleEntries
} from "@v2/feature/attendance/attendance.util";
import { AttendancePatternScheduleStartDateDrawer } from "@v2/feature/attendance/settings/components/attendance-pattern-schedule-start-date-drawer.component";
import { AttendanceScheduleDayEntryDrawer } from "@v2/feature/attendance/settings/components/attendance-schedule-edit-day-entries-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { nestErrorMessage } from "@/lib/errors";
function getEntryPeriod(entry, attendancePayMultipliersById, isFlexible, polyglot) {
  var _a, _b;
  if (isFlexible) {
    const dayLength = getTotalLengthOfDayFromScheduleEntries([entry], attendancePayMultipliersById, isFlexible);
    const hours = Math.floor(dayLength / 60);
    const minutes = dayLength % 60;
    return dayLength > 0 ? `${hours > 0 ? `${hours}h` : ""} ${minutes > 0 ? `${minutes}m` : ""}`.trim() : "";
  } else {
    const from = (_a = entry == null ? void 0 : entry.from.slice(11, 16)) != null ? _a : "";
    const to = (_b = entry == null ? void 0 : entry.to.slice(11, 16)) != null ? _b : "";
    return polyglot.t("ScheduleSectionReadonly.period", {
      from,
      to
    });
  }
}
export const AttendanceSchedulePatternSubSection = ({
  schedule,
  jobTypes,
  refreshSchedule,
  attendancePayMultipliers
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAddingWeek, setIsAddingWeek] = useState(false);
  const [isStartDateDrawerOpen, setIsStartDateDrawerOpen] = useState(false);
  const [isStartDateDrawerDetails, setIsStartDateDrawerDetails] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayEntry, setSelectedDayEntry] = useState(null);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(null);
  const attendancePayMultipliersById = useMemo(() => new Map(attendancePayMultipliers.map((m) => [m.id, m])), [
    attendancePayMultipliers
  ]);
  const weeksArray = useMemo(() => {
    const arr = [];
    for (let w = 0; w < schedule.noOfWeeks; w += 1) {
      arr.push(w);
    }
    return arr;
  }, [schedule.noOfWeeks]);
  const rows = useMemo(() => {
    const weeks = [];
    for (let weekNo = 0; weekNo < schedule.noOfWeeks; weekNo++) {
      const monday = schedule.monday[weekNo];
      const tuesday = schedule.tuesday[weekNo];
      const wednesday = schedule.wednesday[weekNo];
      const thursday = schedule.thursday[weekNo];
      const friday = schedule.friday[weekNo];
      const saturday = schedule.saturday[weekNo];
      const sunday = schedule.sunday[weekNo];
      const startDateOfFirstWeek = schedule.startDateOfFirstWeek;
      const weekTotal = getAllTimeFromScheduleByWeekIndex(schedule, weekNo, attendancePayMultipliersById);
      weeks.push({
        weekNo: weekNo + 1,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        weekTotal,
        startDateOfFirstWeek
      });
    }
    return weeks;
  }, [schedule, attendancePayMultipliersById]);
  const editDayEntry = useCallback((day, weekIndex, entry) => {
    setSelectedDay(day);
    setSelectedDayEntry(entry);
    setSelectedWeekIndex(weekIndex);
    setIsDrawerOpen(true);
  }, []);
  const addWeek = useCallback(
    (newStartDateOfFirstWeek) => __async(void 0, null, function* () {
      setIsAddingWeek(true);
      try {
        yield AttendanceAPI.addEmptyWeekToAttendanceSchedule(schedule.id, newStartDateOfFirstWeek);
        yield refreshSchedule();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
      setIsAddingWeek(false);
    }),
    [showMessage, polyglot, schedule, refreshSchedule]
  );
  const deleteWeekByIndex = useCallback(
    (weekIndex) => __async(void 0, null, function* () {
      try {
        yield AttendanceAPI.deleteWeekFromAttendanceScheduleByWeekIndex(schedule.id, weekIndex);
        yield refreshSchedule();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [showMessage, polyglot, schedule, refreshSchedule]
  );
  const clearWeekByIndex = useCallback(
    (weekIndex) => __async(void 0, null, function* () {
      try {
        yield AttendanceAPI.clearWeekFromAttendanceScheduleByWeekIndex(schedule.id, weekIndex);
        yield refreshSchedule();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [showMessage, polyglot, schedule, refreshSchedule]
  );
  const duplicateWeekByIndex = useCallback(
    (weekIndex, startDateOfFirstWeek) => __async(void 0, null, function* () {
      try {
        yield AttendanceAPI.duplicateWeekFromAttendanceScheduleByWeekIndex(
          schedule.id,
          weekIndex,
          startDateOfFirstWeek
        );
        yield refreshSchedule();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [showMessage, polyglot, schedule, refreshSchedule]
  );
  const columns = useMemo(() => {
    return [
      {
        header: () => "Week",
        accessorFn: (row) => row,
        id: "weekNo",
        minSize: 60,
        maxSize: 60,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("AttendanceDomain.weekNo", { weekNo: original.weekNo }) }) })
      },
      ...WeekDayNames.map((day) => {
        return {
          header: () => polyglot.t(`Days.${day}`),
          accessorFn: (row) => row,
          minSize: 100,
          maxSize: 150,
          id: day,
          enableSorting: false,
          cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
            DayCell,
            {
              weekNo: original.weekNo,
              day,
              dayData: original[day],
              editDayEntry,
              jobTypes,
              schedule,
              attendancePayMultipliersById
            }
          )
        };
      }),
      {
        header: () => "Week total (paid)",
        accessorFn: (row) => row,
        id: "weekTotal",
        minSize: 50,
        maxSize: 50,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: convertMinutesToClockHours(original.weekTotal, polyglot) }) })
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                gap: spacing.gap5
              },
              children: /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: [
                    {
                      handler: () => __async(void 0, null, function* () {
                        yield clearWeekByIndex(original.weekNo - 1);
                      }),
                      label: "Clear week"
                    },
                    {
                      handler: () => __async(void 0, null, function* () {
                        if (schedule.monday.length === 1) {
                          setIsStartDateDrawerDetails({ action: "duplicate", weekIndexToCopy: original.weekNo - 1 });
                          setIsStartDateDrawerOpen(true);
                        } else {
                          yield duplicateWeekByIndex(original.weekNo - 1, original.startDateOfFirstWeek);
                        }
                      }),
                      label: "Duplicate week"
                    },
                    {
                      handler: () => __async(void 0, null, function* () {
                        yield deleteWeekByIndex(original.weekNo - 1);
                      }),
                      label: "Delete week",
                      hidden: schedule.noOfWeeks === 1
                    }
                  ],
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              )
            }
          );
        },
        maxSize: 50,
        minSize: 50
      }
    ];
  }, [
    polyglot,
    editDayEntry,
    jobTypes,
    schedule,
    clearWeekByIndex,
    duplicateWeekByIndex,
    deleteWeekByIndex,
    attendancePayMultipliersById
  ]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    weeksArray.length > 1 && /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.s2 }, children: /* @__PURE__ */ jsx(
      SettingsReadOnlyLine,
      {
        field: polyglot.t("ScheduleSectionReadonly.startDateOfFirstWeek"),
        value: new LocalDate(schedule.startDateOfFirstWeek).toLocaleDateString()
      },
      "schedule_start_week"
    ) }),
    /* @__PURE__ */ jsx(BasicTable, { rowData: rows, columnData: columns, hideHover: true, hidePagination: true }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.s1, width: "100px" }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "filter",
        colorVariant: "secondary",
        fullWidth: true,
        style: { height: "25px" },
        loading: isAddingWeek,
        onClick: () => __async(void 0, null, function* () {
          if (schedule.monday.length === 1) {
            setIsStartDateDrawerDetails({ action: "add" });
            setIsStartDateDrawerOpen(true);
          } else {
            yield addWeek(schedule.startDateOfFirstWeek);
          }
        }),
        children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AttendanceSchedulePattern.addWeek") })
      }
    ) }),
    /* @__PURE__ */ jsx(
      AttendanceScheduleDayEntryDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        day: selectedDay,
        weekIndex: selectedWeekIndex,
        dayEntry: selectedDayEntry,
        schedule,
        afterClose: () => {
          setSelectedDay(null);
          setSelectedWeekIndex(null);
          setSelectedDayEntry(null);
        },
        refresh: refreshSchedule,
        attendancePayMultipliers,
        attendanceTypes: jobTypes
      }
    ),
    /* @__PURE__ */ jsx(
      AttendancePatternScheduleStartDateDrawer,
      {
        isOpen: isStartDateDrawerOpen,
        setIsOpen: setIsStartDateDrawerOpen,
        drawerDetails: isStartDateDrawerDetails,
        setDrawerDetails: setIsStartDateDrawerDetails,
        addWeek,
        duplicateWeek: duplicateWeekByIndex,
        schedule
      }
    )
  ] });
};
const DayCell = ({
  weekNo,
  day,
  dayData,
  editDayEntry,
  jobTypes,
  schedule,
  attendancePayMultipliersById
}) => {
  const { polyglot } = usePolyglot();
  const [hoverOverCell, setHoverOverCell] = useState(false);
  const handleMouseOver = () => {
    setHoverOverCell(true);
  };
  const handleMouseLeave = () => {
    setHoverOverCell(false);
  };
  return /* @__PURE__ */ jsxs(Box, { onMouseOver: handleMouseOver, onMouseLeave: handleMouseLeave, children: [
    dayData && dayData.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: dayData.filter(Boolean).map((entry) => {
      var _a, _b;
      const type = jobTypes.find((t) => t.id === entry.typeId);
      return /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            gap: spacing.s1,
            alignItems: "stretch",
            cursor: "pointer",
            ":hover": {
              bgcolor: themeColors.TableHover
            }
          },
          onClick: () => {
            editDayEntry(day, weekNo - 1, entry);
          },
          children: [
            /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  width: spacing.sm,
                  height: "auto",
                  // Let it grow with the sibling's content
                  minHeight: "100%",
                  // Ensure the line stretches with content height
                  bgcolor: (_a = type == null ? void 0 : type.color) != null ? _a : "grey"
                }
              }
            ),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title4", children: (_b = type == null ? void 0 : type.name) != null ? _b : "Unknown" }),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getEntryPeriod(entry, attendancePayMultipliersById, schedule.isFlexible, polyglot) })
            ] })
          ]
        },
        entry.id
      );
    }) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("AttendanceDomain.notScheduled") }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.s1, height: "25px" }, children: hoverOverCell && /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "filter",
        colorVariant: "secondary",
        fullWidth: true,
        style: { height: "25px" },
        onClick: () => {
          editDayEntry(day, weekNo - 1, null);
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "title3", color: "Grey", children: "+" })
      }
    ) })
  ] });
};
