"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { DraggableAbsencePolicies } from "@v2/feature/absence/subfeatures/settings/components/draggable-absence-policies.component";
import { NewTimePolicyDrawer } from "@v2/feature/absence/subfeatures/settings/components/new-time-policy-drawer.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const AbsenceCompanySettingsPoliciesPage = ({
  absencePolicies,
  archivedPolicies,
  refreshPolicies,
  isLoading
}) => {
  const { polyglot } = usePolyglot();
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), { suspense: false });
  const { data: entities } = useApiClient(CompanyEndpoints.getCompanyEntities(), { suspense: false });
  const { data: approvalRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const allPolicies = useMemo(() => [...absencePolicies, ...archivedPolicies], [absencePolicies, archivedPolicies]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AbsenceCompanySettingsPoliciesPage.policies") }),
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            style: { marginRight: "9px" },
            onClick: () => {
              setIsDrawerOpen(true);
            },
            children: polyglot.t("AbsenceCompanySettingsPoliciesPage.new")
          }
        ),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(
      ContentWrapper,
      {
        noHorizontalPadding: true,
        sx: {
          display: "flex",
          justifyContent: "flex-start",
          px: spacing.s2,
          pt: "2px"
        },
        loading: isLoading,
        children: [
          allPolicies.length > 0 && /* @__PURE__ */ jsx(
            DraggableAbsencePolicies,
            {
              absencePolicies: allPolicies,
              sites: sites != null ? sites : [],
              departments: departments != null ? departments : [],
              entities: entities != null ? entities : [],
              approvalRules: approvalRules != null ? approvalRules : []
            }
          ),
          isDrawerOpen && /* @__PURE__ */ jsx(NewTimePolicyDrawer, { isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen, refresh: refreshPolicies })
        ]
      }
    )
  ] });
};
