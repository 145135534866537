"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { PAYROLL_COMPANY_ROUTE } from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { GlobalPayrollPayruns } from "@/v2/feature/payroll/features/payroll-global/global-payroll-payruns/global-payroll-payruns.component";
import { UKPayrollPayruns } from "@/v2/feature/payroll/features/payroll-uk/payroll-payruns/payroll-payruns.component";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const CompanyPayrollPage = () => {
  const { polyglot } = usePolyglot();
  const payrollId = Number(useParams().payrollId);
  const [showMessage] = useMessage();
  const [companyPayrolls, setCompanyPayrolls] = useState();
  const [payroll, setPayroll] = useState();
  const routerHistory = useHistory();
  const refreshPayroll = useCallback((abort) => __async(void 0, null, function* () {
    const payrolls = yield PayrollAPI.getCompanyPayrolls();
    if (abort == null ? void 0 : abort.signal.aborted) return;
    setCompanyPayrolls(payrolls);
  }), []);
  useEffect(() => {
    const ac = new AbortController();
    refreshPayroll(ac);
    return () => ac.abort();
  }, [refreshPayroll]);
  useEffect(() => {
    if (!(companyPayrolls == null ? void 0 : companyPayrolls.items)) return;
    const matchedPayroll = companyPayrolls.items.find((payroll2) => payroll2.id === payrollId);
    if (!matchedPayroll) {
      showMessage(polyglot.t("CompanyPayroll.payrollNotFound"), "error");
      routerHistory.push(PAYROLL_COMPANY_ROUTE);
      return;
    }
    setPayroll(matchedPayroll);
  }, [companyPayrolls == null ? void 0 : companyPayrolls.items, payrollId, routerHistory, showMessage, polyglot]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: !payroll, hideFooter: true, noHorizontalPadding: true, sx: { pb: 0 }, children: [
    payroll && !!payroll.employer && /* @__PURE__ */ jsx(
      UKPayrollPayruns,
      {
        payroll,
        refreshPayroll: () => __async(void 0, null, function* () {
          yield refreshPayroll == null ? void 0 : refreshPayroll();
        })
      }
    ),
    payroll && !payroll.employer && /* @__PURE__ */ jsx(
      GlobalPayrollPayruns,
      {
        payroll,
        refreshPayroll: () => __async(void 0, null, function* () {
          yield refreshPayroll == null ? void 0 : refreshPayroll();
        })
      }
    )
  ] });
};
