"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { SETTINGS_PERMISSION_GROUP_ROUTE, SETTINGS_PERMISSION_GROUPS_ROUTE } from "@/lib/routes";
import { PermissionGroupEditRouter } from "@/v2/feature/permission-group/pages/permission-group-edit/permission-group-edit.router";
import { PermissionGroupsSettingsPage } from "@/v2/feature/permission-group/permission-group-settings.page";
export function PermissionGroupRouter() {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["company.settings:all"], path: SETTINGS_PERMISSION_GROUPS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PermissionGroupsSettingsPage, {}) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["company.settings:all"],
        path: SETTINGS_PERMISSION_GROUP_ROUTE,
        component: PermissionGroupEditRouter
      }
    )
  ] });
}
