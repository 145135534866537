"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const ParticipantsDeleteRevieweeModal = ({
  reviewCycle,
  toDeleteRevieweeIds,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    ParticipantsDeleteRevieweeContent,
    {
      reviewCycle,
      onClose,
      refresh,
      toDeleteRevieweeIds
    }
  ) });
};
const ParticipantsDeleteRevieweeContent = ({
  reviewCycle,
  onClose,
  refresh,
  toDeleteRevieweeIds
}) => {
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const handleDeleteReviewees = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (reviewCycle) {
        yield ReviewCycleAPI.deleteReviewees(reviewCycle.id, toDeleteRevieweeIds);
        showMessage("Successfully deleted the participants", "success");
      }
      yield refresh();
      onClose();
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Delete participants" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "This will permanently delete all participant reviews and any associated feedback from reviewers. Are you sure?" }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "button",
        onClick: handleDeleteReviewees,
        sizeVariant: "medium",
        colorVariant: "primary",
        name: "Remove",
        loading,
        fullWidth: true
      }
    ) })
  ] });
};
