"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { FormControlLabel, IconButton, RadioGroup, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoGrey.svg";
import { StudentLoanValues } from "@/lib/payroll";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { StudentLoanInfo } from "@/v2/feature/payroll/features/payroll-uk/components/student-loan-info.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StudentLoansOptions = ({
  value,
  setStudentLoans,
  disabled,
  helperText,
  error
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [openStudentLoanInfo, setOpenStudentLoanInfo] = useState(false);
  const setHasStudentLoans = useCallback(
    (hasLoans) => {
      var _a2, _b2;
      if (hasLoans) {
        setStudentLoans({
          studentLoanPlan: (_a2 = value == null ? void 0 : value.studentLoanPlan) != null ? _a2 : "None",
          postgradLoan: (_b2 = value == null ? void 0 : value.postgradLoan) != null ? _b2 : false
        });
      } else {
        setStudentLoans(null);
      }
    },
    [setStudentLoans, value == null ? void 0 : value.postgradLoan, value == null ? void 0 : value.studentLoanPlan]
  );
  const hasStudentLoan = useMemo(() => {
    if (value === void 0) {
      return;
    }
    return !!value;
  }, [value]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsx(StudentLoanInfo, { open: openStudentLoanInfo, setOpen: (isOpen) => setOpenStudentLoanInfo(isOpen) }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.doYouMakeStudentLoanPayments") }),
    /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        value: hasStudentLoan != null ? hasStudentLoan : "",
        onChange: (e) => setHasStudentLoans(e.target.value === "true"),
        sx: { flexFlow: "row" },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "true",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("General.yes") }),
              disabled,
              sx: { width: 200 }
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "false",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              disabled,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("General.no") })
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { mt: hasStudentLoan ? spacing.m30 : "-50%", gap: spacing.g20, transition: "margin-top 0.4s" }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: [
        polyglot.t("OnboardingByUser.chooseTheCorrectStudentLoans"),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            "aria-label": "info",
            onClick: () => setOpenStudentLoanInfo(true),
            disabled,
            size: "small",
            children: /* @__PURE__ */ jsx(InfoIcon, {})
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "studentLoan",
          label: polyglot.t("OnboardingByUser.studentLoan"),
          options: StudentLoanValues,
          value: (_a = value == null ? void 0 : value.studentLoanPlan) != null ? _a : "None",
          onChange: (e) => {
            var _a2;
            return setStudentLoans({
              studentLoanPlan: e.target.value,
              postgradLoan: (_a2 = value == null ? void 0 : value.postgradLoan) != null ? _a2 : false
            });
          },
          disabled: disabled || !hasStudentLoan
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "postgradLoan",
          label: polyglot.t("OnboardingByUser.postgradLoan"),
          checked: (_b = value == null ? void 0 : value.postgradLoan) != null ? _b : false,
          onChange: (_, checked) => {
            var _a2;
            return setStudentLoans({
              studentLoanPlan: (_a2 = value == null ? void 0 : value.studentLoanPlan) != null ? _a2 : "None",
              postgradLoan: checked
            });
          },
          disabled: disabled || !hasStudentLoan
        }
      ),
      /* @__PURE__ */ jsx("div", { style: { display: "relative" }, children: /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({ position: "absolute" }, themeFonts.caption), { color: error ? themeColors.red : void 0 }),
          children: helperText
        }
      ) })
    ] }) })
  ] }) });
};
