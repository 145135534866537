"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from "react";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getSSOLoginResponse } from "@/v2/feature/auth/auth.util";
import { isProduction, SignInFlow } from "@/v2/infrastructure/firebase/identity-platform.util";
import { primarySmallBtn } from "@/v2/styles/buttons.styles";
export const AuthLoginSSOIOSPage = () => {
  const routerLocation = useLocation();
  const loginButton = useRef(null);
  const queries = new URLSearchParams(routerLocation.search);
  const triggerSSOLogin = useCallback(() => __async(void 0, null, function* () {
    var _a;
    try {
      const email = queries.get("email");
      const companyId = queries.get("companyId");
      const app = queries.get("app");
      const deviceId = (_a = queries.get("deviceId")) != null ? _a : void 0;
      const iosScheme = isProduction ? "zeltapp" : "zeltapp-beta";
      if (email && companyId && app) {
        let matchingProvider = { companyId: +companyId, app, enabled: true };
        let loginResponse = yield getSSOLoginResponse(
          email,
          matchingProvider,
          SignInFlow.Popup,
          deviceId
        );
        if (loginResponse) {
          window.location.replace(`${iosScheme}://zelt.app/sso-login?authToken=${loginResponse.accessToken}`);
        }
      }
    } catch (error) {
    }
  }), []);
  return /* @__PURE__ */ jsxs("body", { children: [
    /* @__PURE__ */ jsx("h1", { children: "Processing login with SSO provider" }),
    /* @__PURE__ */ jsxs("form", { children: [
      " ",
      /* @__PURE__ */ jsx(
        Button,
        {
          fullWidth: true,
          ref: loginButton,
          sx: __spreadValues({}, primarySmallBtn),
          onClick: () => __async(void 0, null, function* () {
            yield triggerSSOLogin();
          }),
          children: "Start"
        }
      )
    ] })
  ] });
};
