"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Filter } from "@/images/new-theme-icon/Filter.svg";
import { ReactComponent as ArrowLeft } from "@/images/side-bar-icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "@/images/side-bar-icons/ArrowRight.svg";
import { ReactComponent as CheckedCheckbox } from "@/images/side-bar-icons/CheckedCheckbox.svg";
import { ReactComponent as EmptyCheckbox } from "@/images/side-bar-icons/EmptyCheckbox.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledMenu = styled((props) => /* @__PURE__ */ jsx(
  Menu,
  __spreadValues({
    elevation: 0,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    }
  }, props)
))(() => ({
  "& .MuiPaper-root": {
    boxShadow: "0px 2px 20px 0px rgba(13, 13, 14, 0.1)",
    textAlign: "left",
    borderRadius: radius.br10,
    paddingRight: spacing.p10,
    "& .MuiMenu-list": __spreadProps(__spreadValues({}, spacing.pad20), {
      display: "flex",
      flexDirection: "column",
      gap: spacing.g10
    }),
    "& .MuiMenuItem-root": __spreadProps(__spreadValues({
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      padding: "0px",
      minHeight: "20px"
    }, themeFonts.caption), {
      "&:hover": {
        backgroundColor: "transparent",
        color: themeColors.Grey,
        fill: themeColors.Grey
      }
    })
  }
}));
const iconSize = { width: 14, height: 14 };
export const TableFilter = ({
  filterTypes,
  setFilterString,
  filterString,
  dynamicFilterNames = false,
  showSelectedFilters = true,
  onClose = void 0
}) => {
  var _a, _b;
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterObject, setFilterObject] = useState({});
  const [filter, setFilter] = useState(null);
  const filterStringToObject = useCallback(() => {
    const defaultFilterString = {};
    if (filterString.length > 0) {
      const andString = filterString.split("&");
      if (andString.length > 0) {
        andString.forEach((item) => {
          const [key, values] = item.split("=");
          if (key && values) defaultFilterString[key] = values.split(",");
        });
      }
    }
    setFilterObject(defaultFilterString);
  }, [filterString]);
  useEffect(() => {
    filterStringToObject();
  }, [filterStringToObject]);
  const handleFilterSelect = (value) => {
    var _a2, _b2;
    let updatedValues;
    if (filter) {
      const deselectValue = filterObject[filter] && ((_a2 = filterObject[filter]) == null ? void 0 : _a2.length) && filterObject[filter].indexOf(value);
      if (deselectValue >= 0) {
        updatedValues = filterObject[filter].filter((item) => item !== value);
        if (filterObject[filter].length <= 1) delete filterObject[filter];
      } else {
        updatedValues = ((_b2 = filterObject[filter]) == null ? void 0 : _b2.length) ? [...filterObject[filter], value] : [value];
      }
      const newFilterOptions = __spreadProps(__spreadValues({}, filterObject), { [filter]: updatedValues });
      setFilterObject(newFilterOptions);
      let newQueryString = "";
      Object.keys(newFilterOptions).forEach((key, index) => {
        const optionsSelected = newFilterOptions[key].toString();
        if (optionsSelected.length > 0) {
          if (index === 0) newQueryString += `${key}=${optionsSelected}`;
          else newQueryString += `&${key}=${optionsSelected}`;
        }
      });
      setFilterString(newQueryString);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : void 0;
  const handleCloseFilters = (filter2, value) => {
    var _a2, _b2;
    let updatedValues;
    const deselectValue = filterObject[filter2] && ((_a2 = filterObject[filter2]) == null ? void 0 : _a2.length) && filterObject[filter2].indexOf(value);
    if (deselectValue >= 0) {
      updatedValues = filterObject[filter2].filter((item) => item !== value);
      if (filterObject[filter2].length <= 1) delete filterObject[filter2];
    } else {
      updatedValues = ((_b2 = filterObject[filter2]) == null ? void 0 : _b2.length) ? [...filterObject[filter2], value] : [value];
    }
    const newFilterOptions = __spreadProps(__spreadValues({}, filterObject), { [filter2]: updatedValues });
    setFilterObject(newFilterOptions);
    let newQueryString = "";
    Object.keys(newFilterOptions).forEach((key, index) => {
      const optionsSelected = newFilterOptions[key].toString();
      if (optionsSelected.length > 0) {
        if (index === 0) newQueryString += `${key}=${optionsSelected}`;
        else newQueryString += `&${key}=${optionsSelected}`;
      }
    });
    setFilterString(newQueryString);
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5, flexWrap: "wrap" }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: Object.keys(filterObject).length > 0 ? "active" : "secondary",
          onClick: (event) => setAnchorEl(event.currentTarget),
          startIcon: /* @__PURE__ */ jsx(Filter, __spreadValues({}, iconSize)),
          children: "Filter"
        }
      ),
      showSelectedFilters && ((_a = Object.keys(filterObject)) == null ? void 0 : _a.map(
        (filterObj) => {
          var _a2;
          return (_a2 = filterObject[filterObj]) == null ? void 0 : _a2.map((subFilterName) => {
            var _a3, _b2, _c;
            return /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "filter",
                style: { textTransform: "capitalize" },
                colorVariant: "secondary",
                endIcon: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey })),
                onClick: () => __async(void 0, null, function* () {
                  handleCloseFilters(filterObj, subFilterName);
                }),
                children: (_c = filterTypes[filterObj] && ((_b2 = (_a3 = filterTypes[filterObj]) == null ? void 0 : _a3.find((typeObj) => typeObj.value.toString() === subFilterName)) == null ? void 0 : _b2.label)) != null ? _c : /* @__PURE__ */ jsx(Skeleton, { width: 80, animation: "wave", sx: { backgroundColor: themeColors.Background } })
              },
              subFilterName
            );
          });
        }
      ))
    ] }),
    /* @__PURE__ */ jsxs(
      StyledMenu,
      {
        id,
        MenuListProps: {
          "aria-labelledby": "btnMenuOptions"
        },
        open,
        anchorEl,
        onClose: () => __async(void 0, null, function* () {
          setAnchorEl(null);
          setFilter(null);
          if (onClose) yield onClose();
        }),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: { horizontal: "left", vertical: "top" },
        children: [
          !filter && Object.keys(filterTypes).map((filter2) => {
            var _a2, _b2;
            return /* @__PURE__ */ jsxs(
              MenuItem,
              {
                onClick: () => setFilter(filter2),
                disableRipple: true,
                sx: { display: "flex", alignItems: "center", justifyContent: "space-between", gap: spacing.g10 },
                children: [
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: dynamicFilterNames ? filter2.split("-").pop() : filter2 }),
                  /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
                    ((_a2 = filterObject[filter2]) == null ? void 0 : _a2.length) > 0 && /* @__PURE__ */ jsx(
                      Typography,
                      {
                        sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                          color: themeColors.DarkGrey
                        }),
                        children: (_b2 = filterObject[filter2]) == null ? void 0 : _b2.length
                      }
                    ),
                    /* @__PURE__ */ jsx(ArrowRight, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Grey, color: themeColors.Grey } }))
                  ] })
                ]
              },
              filter2
            );
          }),
          filter && /* @__PURE__ */ jsxs(MenuItem, { onClick: () => setFilter(null), disableRipple: true, children: [
            /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { fill: themeColors.Grey }), children: /* @__PURE__ */ jsx(ArrowLeft, __spreadValues({}, iconSize)) }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: dynamicFilterNames ? filter.split("-").pop() : filter })
          ] }),
          filter && ((_b = filterTypes[filter]) == null ? void 0 : _b.map((subFilter) => {
            var _a2;
            return /* @__PURE__ */ jsxs(
              MenuItem,
              {
                value: subFilter.value,
                onClick: (e) => {
                  handleFilterSelect(subFilter.value.toString());
                  e.stopPropagation();
                },
                disableRipple: true,
                sx: { display: "flex", alignItems: "center", gap: spacing.g10 },
                children: [
                  filterObject[filter] && ((_a2 = filterObject[filter]) == null ? void 0 : _a2.indexOf(subFilter.value.toString())) !== -1 ? /* @__PURE__ */ jsx(CheckedCheckbox, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(EmptyCheckbox, __spreadValues({}, iconSize)),
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: subFilter.label })
                ]
              },
              subFilter.value
            );
          }))
        ]
      }
    )
  ] });
};
