"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserCell = ({
  userId,
  avatarSx,
  name,
  nameSx,
  nameVariant = "caption",
  nameColor,
  sx,
  endAdornment,
  avatarSize = "xxsmall",
  prefix
}) => {
  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(userId);
  if (!user) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", flex: 1, gap: spacing.g4, alignItems: "center" }, sx), children: [
    prefix && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: prefix }),
    /* @__PURE__ */ jsx(
      UserAvatar,
      {
        userId: user.userId,
        size: avatarSize,
        sx: __spreadValues({ flex: "0 0 auto" }, avatarSx),
        showName: true,
        name,
        nameVariant,
        nameColor,
        nameSx
      },
      user.userId
    ),
    endAdornment
  ] });
};
UserCell.getDisplayedName = (user) => {
  var _a, _b, _c;
  return ((_a = user.displayName) == null ? void 0 : _a.trim()) || `${(_b = user.firstName) != null ? _b : ""} ${(_c = user.lastName) != null ? _c : ""}`.trim() || `User:${user.userId}`;
};
