"use strict";
import { WeekDayNames } from "@v2/feature/attendance/attendance.interface";
import { round2Digits } from "@v2/util/number.util";
import { differenceInDays } from "date-fns";
export const DEFAULT_SCHEDULE_CAP = 15;
export function getAverageNumberOfWorkingDaysPerScheduleWeek(schedule) {
  let totalNoOfWorkingDaysDefined = 0;
  for (let weekNo = 0; weekNo < schedule.noOfWeeks; weekNo += 1) {
    for (const day of WeekDayNames) {
      if (schedule[day] && schedule[day][weekNo] && schedule[day][weekNo].length > 0) totalNoOfWorkingDaysDefined += 1;
    }
  }
  return round2Digits(totalNoOfWorkingDaysDefined / schedule.noOfWeeks);
}
export function getIndexOfWeekInSchedule(scheduleStart, date, noOfWeeks) {
  if (!scheduleStart) throw new Error("Missing scheduleStart!");
  if (!noOfWeeks) throw new Error("Missing noOfWeeks!");
  const diff = differenceInDays(new Date(date), new Date(scheduleStart));
  const week = Math.floor(diff / 7) % noOfWeeks;
  return week >= 0 ? week : week + noOfWeeks;
}
