"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { ImportedDeviceTableCell } from "./imported-device-table-cell.component";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceImportResultTable = ({ result, completeImport, loading }) => {
  const [showMessage] = useMessage();
  const rowData = useMemo(() => {
    if (result == null ? void 0 : result.errors.some((e) => e.entity)) {
      return result == null ? void 0 : result.errors.flatMap((record) => record.entity);
    }
  }, [result]);
  const rowErrors = useMemo(() => {
    if (result == null ? void 0 : result.errors.some((e) => e.errors.length > 0)) {
      return result == null ? void 0 : result.errors.map((record) => record.errors);
    }
  }, [result]);
  const notifyPendingErrors = useCallback(() => {
    showMessage("We found some issues in your formatting. Please correct the mistakes and try again", "info");
  }, [showMessage]);
  const notifyDataValid = useCallback(() => {
    showMessage("Your imported data looks good! Continue to finish import.", "success");
  }, [showMessage]);
  useEffect(() => {
    if (rowErrors == null ? void 0 : rowErrors.length) {
      notifyPendingErrors();
    } else {
      notifyDataValid();
    }
  }, [notifyDataValid, notifyPendingErrors, rowErrors == null ? void 0 : rowErrors.length]);
  const getErrorForRow = useCallback(
    (index) => {
      return rowErrors ? rowErrors[index] : [];
    },
    [rowErrors]
  );
  const deviceColumns = useMemo(() => {
    return [
      {
        header: () => "Model name",
        accessorFn: (row) => row,
        id: "modelName",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "modelName"
            }
          );
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => "Model number",
        accessorFn: (row) => row,
        id: "modelNumber",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "modelNumber"
            }
          );
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => "Manufacturer",
        accessorFn: (row) => row,
        id: "manufacturer",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "manufacturer"
            }
          );
        },
        maxSize: 70,
        minSize: 70
      },
      {
        header: () => "Platform",
        accessorFn: (row) => row,
        id: "platform",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "platform"
            }
          );
        },
        maxSize: 60,
        minSize: 60
      },
      {
        header: () => "Serial Number",
        accessorFn: (row) => row,
        id: "serialNumber",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "serialNumber"
            }
          );
        },
        maxSize: 80,
        minSize: 80
      },
      {
        header: () => "Memory",
        accessorFn: (row) => row,
        id: "memoryGb",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "memoryGb"
            }
          );
        },
        maxSize: 50,
        minSize: 50
      },
      {
        header: () => "Storage",
        accessorFn: (row) => row,
        id: "storageGb",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "storageGb"
            }
          );
        },
        maxSize: 50,
        minSize: 50
      },
      {
        header: () => "CPU cores",
        accessorFn: (row) => row,
        id: "cpuCores",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "cpuCores"
            }
          );
        },
        maxSize: 60,
        minSize: 60
      },
      {
        header: () => "Notes",
        accessorFn: (row) => row,
        id: "notes",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "notes"
            }
          );
        },
        maxSize: 70,
        minSize: 70
      },
      {
        header: () => "In use by",
        accessorFn: (row) => row,
        id: "inUseBy",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "inUseBy"
            }
          );
        },
        maxSize: 80,
        minSize: 80
      },
      {
        header: () => "Work email",
        accessorFn: (row) => row,
        id: "workEmail",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "workEmail"
            }
          );
        },
        maxSize: 90,
        minSize: 90
      },
      {
        header: () => "Site",
        accessorFn: (row) => row,
        id: "site",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "site"
            }
          );
        },
        maxSize: 90,
        minSize: 90
      },
      {
        header: () => "Type",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedDeviceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "type"
            }
          );
        },
        maxSize: 80,
        minSize: 80
      }
    ];
  }, [getErrorForRow]);
  const totalPendingErrors = useMemo(() => {
    var _a, _b;
    return (_b = (_a = rowErrors == null ? void 0 : rowErrors.flat()) == null ? void 0 : _a.length) != null ? _b : 0;
  }, [rowErrors]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          pt: spacing.p25,
          flex: 1,
          overflow: "auto",
          borderTop: "1px solid",
          borderColor: themeColors.GreyLight
        },
        children: /* @__PURE__ */ jsx(BasicTable, { rowData: rowData != null ? rowData : [], columnData: deviceColumns, rowClick: () => {
        }, loading })
      }
    ),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: "30px" },
        disabled: loading,
        onClick: () => {
          if (rowErrors == null ? void 0 : rowErrors.length) {
            notifyPendingErrors();
          } else {
            completeImport();
          }
        },
        children: totalPendingErrors ? `Try again` : "Continue"
      }
    )
  ] });
};
