"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { primarySmallBtn } from "@v2/styles/buttons.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const DeviceActivationStepPackaging = ({ goToNextStep }) => {
  return /* @__PURE__ */ jsx(Box, { sx: { height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "400px", gap: spacing.gap20 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Keep the original packaging" }),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Please keep the original packaging for your device. When it\u2019s time to say Goodbye to your hardware - you will be asked to return it in the original box." }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Button, { sx: primarySmallBtn, onClick: goToNextStep, children: "Continue" }) })
  ] }) });
};
