"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const SideAccentedTextLabel = ({
  text,
  linkText,
  linkAction,
  borderColor = "#FFC107"
}) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
        display: "flex",
        alignItems: "center",
        padding: "10px",
        borderLeft: `5px solid ${borderColor}`,
        color: themeColors.black
      }),
      children: /* @__PURE__ */ jsxs("span", { children: [
        text,
        linkText && linkAction && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "text",
            onClick: linkAction,
            style: {
              margin: 0,
              padding: 0,
              color: themeColors.blue,
              display: "inline",
              textDecoration: "underline"
            },
            children: linkText
          }
        )
      ] })
    }
  );
};
