"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getReviewerTypesChips } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ResultsOverviewReviewScoreTable = ({
  results,
  reviewCycle,
  resultsLoading,
  reviewScore,
  setIsSharableOpen,
  reachType
}) => {
  const rowData = useMemo(() => {
    const initialData = results ? [{ reviewerId: results.revieweeId, average: results.reviewAvgScore, type: null, entryState: null }] : [];
    return [...initialData, ...reviewScore || []];
  }, [results, reviewScore]);
  const shouldShowActionColumn = useMemo(
    () => reviewCycle && (reviewCycle.state === CycleState.Completed || reviewCycle.state === CycleState.Ongoing) && reachType !== ReachType.Me,
    [reviewCycle, reachType]
  );
  const getActionCell = useCallback(
    (type) => {
      if (!results || type || results && results.questionResponseSummary.length === 0 || reviewCycle.state === CycleState.Completed && !(results == null ? void 0 : results.visibilitySettings.allowManagerToReleaseFeedback))
        return /* @__PURE__ */ jsx(Fragment, {});
      if (!(results == null ? void 0 : results.isResultShared)) {
        return /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "small", onClick: () => setIsSharableOpen(true), children: "Share results" });
      }
      return /* @__PURE__ */ jsx(ChipComponent, { name: "Shared", border: "background" });
    },
    [setIsSharableOpen, reviewCycle.state, results]
  );
  const tableColumns = useMemo(
    () => [
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "reviewerId",
        enableSorting: false,
        cell: ({
          row: {
            original: { reviewerId }
          }
        }) => {
          var _a;
          return ((_a = results == null ? void 0 : results.visibilitySettings) == null ? void 0 : _a.hidePeerAuthor) && !reviewerId ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Author hidden" }) : /* @__PURE__ */ jsx(UserCell, { userId: reviewerId });
        },
        minSize: 120,
        maxSize: 180
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: ({
          row: {
            original: { type }
          }
        }) => type ? /* @__PURE__ */ jsx("div", { children: getReviewerTypesChips(false)[type] }) : /* @__PURE__ */ jsx(Fragment, {}),
        minSize: 120,
        maxSize: 180
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "average",
        enableSorting: false,
        cell: ({
          row: {
            original: { average }
          }
        }) => average ? /* @__PURE__ */ jsxs("div", { children: [
          average,
          "%"
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120,
        maxSize: 180
      },
      ...shouldShowActionColumn ? [
        {
          header: () => "",
          accessorFn: (row) => row,
          id: "action",
          enableSorting: false,
          cell: ({
            row: {
              original: { type }
            }
          }) => getActionCell(type),
          minSize: 120,
          maxSize: 180
        }
      ] : []
    ],
    [results, getActionCell, shouldShowActionColumn]
  );
  const customRowStyle = (row) => {
    if (!row.original.type) return { backgroundColor: themeColors.TableHover };
    return {};
  };
  return /* @__PURE__ */ jsx("div", { className: "hide-for-capture", children: /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: rowData != null ? rowData : [],
      columnData: tableColumns,
      loading: resultsLoading,
      paginationSx: { px: spacing.px16, boxSizing: "border-box" },
      customRowStyle
    }
  ) });
};
