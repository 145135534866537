"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ProfileNotificationAPI {
  static getProfileNotifications() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/company/profile-notifications`)).data;
    });
  }
  static updateNotificationSettings(record) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/company/profile-notifications/settings`, { record });
    });
  }
  static toggleNotificationStatus(notificationId, notificationName, newStatus) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/company/profile-notifications/toggle-status`, {
        notificationId,
        notificationName,
        newStatus
      });
    });
  }
  static testProfileNotification(record) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/company/profile-notifications/send-a-test`, { record });
    });
  }
  static createNewProfileNotification(record) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/company/profile-notifications`, { record });
    });
  }
  static deleteCustomProfileNotification(notificationId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/company/profile-notifications/${notificationId}`);
    });
  }
}
export class NotificationEndpoints {
  static getProfileNotifications() {
    return {
      url: "/apiv2/company/profile-notifications"
    };
  }
}
