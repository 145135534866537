"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppDetailsAPI } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { EnableSSODisableMFAConflictConfirmDrawer } from "@/v2/feature/app-integration/features/app-details/components/app-details-sso-enable-mfa-conflict-drawer.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { getSSOLoginResponse } from "@/v2/feature/auth/auth.util";
import { ENABLED_SSO_APPS } from "@/v2/feature/auth/features/auth-login/auth-login.page";
import { SSOOktaDomainToolTipInstructions } from "@/v2/feature/security/security-settings/components/sso-okta-domain-tooltip.component";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { isDefined } from "@/v2/feature/user-onboarding/user-onboarding.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SSOOktaUpdateForm = ({
  mfaEnabled,
  ssoState,
  onUpdate,
  currentUser
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [oktaDomain, setOktaDomain] = useState("");
  const [ssoEnabled, setSsoEnabled] = useState(true);
  const [apikey, setApikey] = useState("");
  const [confirmEnablement, setConfirmEnablement] = useState(false);
  const [confirmEnableSSOAndDisableMFA, setConfirmEnableSSOAndDisableMFA] = useState(false);
  const [testingSSO, setTestingSSO] = useState(false);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2, _c, _d;
    try {
      const ssoOktaEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "sso-okta");
      if (ssoOktaEntry) {
        setOktaDomain((_b2 = (_a2 = ssoOktaEntry == null ? void 0 : ssoOktaEntry.state) == null ? void 0 : _a2.siteUrl) != null ? _b2 : "");
        setApikey((_d = (_c = ssoOktaEntry == null ? void 0 : ssoOktaEntry.state) == null ? void 0 : _c.apikey) != null ? _d : "");
      }
      const someEntryEnabled = ssoState == null ? void 0 : ssoState.some((eachEntry) => eachEntry.state.enabled);
      setSsoEnabled(someEntryEnabled);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t("SSOOktaUpdateForm.errorMessages.fetch")}: ${JSON.stringify(error)}`, "error");
    }
  }), [polyglot, showMessage, ssoState]);
  useEffect(() => {
    refresh();
  }, [refresh, ssoState]);
  const saveSsoOidcMetadata = (values) => __async(void 0, null, function* () {
    var _a2;
    try {
      if (!ssoEnabled && mfaEnabled && !confirmEnableSSOAndDisableMFA) {
        setConfirmEnablement(true);
        return;
      }
      if (!values || ((_a2 = Object.keys(values)) == null ? void 0 : _a2.length) === 0) return;
      setLoading(true);
      const finalPayload = {
        apikey: isDefined(values.apikey) ? values.apikey : "",
        siteUrl: isDefined(values.oktaDomain) ? values.oktaDomain : "",
        enabled: true
      };
      const encoded = btoa(JSON.stringify(finalPayload));
      if (values.apikey && values.oktaDomain && encoded) {
        yield AppDetailsAPI.saveSSOktaMetadata({
          sso_oidc_metadata: encoded,
          disable_mfa: confirmEnableSSOAndDisableMFA
        });
      }
      yield testOktaSSOLogin();
    } catch (error) {
      showMessage(`${polyglot.t("SSOOktaUpdateForm.errorMessages.save")}: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
      refresh();
    }
  });
  const oktaEnabled = useMemo(() => {
    return !!ssoState.find((eachApp) => eachApp.app === "sso-okta" && eachApp.state.enabled);
  }, [ssoState]);
  const disableOktaSSO = (forced = false) => __async(void 0, null, function* () {
    try {
      if (!forced && !oktaEnabled) return;
      setLoading(true);
      yield AppDetailsAPI.disableSSOOkta();
      if (!forced) showMessage(polyglot.t("SSOOktaUpdateForm.successMessages.disable"), "success");
      onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t("SSOOktaUpdateForm.errorMessages.disable")}: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
      refresh();
    }
  });
  const formik = useFormik({
    initialValues: {
      apikey: void 0,
      oktaDomain: void 0,
      ssoEnabled
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      apikey: Yup.string().required(polyglot.t("SSOOktaUpdateForm.errorMessages.tknRequired")),
      oktaDomain: Yup.string().url(polyglot.t("SSOOktaUpdateForm.errorMessages.validUrlRequired")).required(polyglot.t("SSOOktaUpdateForm.errorMessages.oktaDomainRequired")).test(
        "url-should-not-include-trailing-slash",
        polyglot.t("SSOOktaUpdateForm.errorMessages.urlNoTrailingSlash"),
        (value) => !(value == null ? void 0 : value.endsWith("/"))
      )
    }),
    onSubmit: saveSsoOidcMetadata
  });
  const triggerEnableSSOAndDisableMFA = () => {
    setConfirmEnableSSOAndDisableMFA(true);
    formik.handleSubmit();
  };
  const testOktaSSOLogin = () => __async(void 0, null, function* () {
    setTestingSSO(true);
    try {
      const loginCheckData = yield AuthAPI.ssoCheck(currentUser.emailAddress);
      const ssoConfig = loginCheckData == null ? void 0 : loginCheckData.ssoConfig;
      const matchingProviderData = ssoConfig.find(
        (eachApp) => ENABLED_SSO_APPS.includes(eachApp.app) && eachApp.enabled
      );
      if (matchingProviderData) {
        const loginResult = yield getSSOLoginResponse(
          currentUser.emailAddress,
          matchingProviderData
        );
        if (loginResult.accessToken && loginResult.userId && loginResult.companyId === currentUser.company.companyId) {
          showMessage(polyglot.t("SSOOktaUpdateForm.successMessages.ssoTest"), "success");
          onUpdate();
        } else {
          disableOktaSSO(true);
          showMessage(polyglot.t("SSOOktaUpdateForm.errorMessages.ssoTest"), "error");
        }
      }
    } catch (error) {
      showMessage(
        `${polyglot.t("SSOOktaUpdateForm.errorMessages.ssoTestErrorEncountered")}: ${nestErrorMessage(error)}`,
        "error"
      );
      console.error("Encountered an error while trying to test SSO: ", error);
      disableOktaSSO(true);
    } finally {
      setTestingSSO(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", mt: spacing.mt10 }, children: [
    !testingSSO && !oktaEnabled && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: [
        `${polyglot.t("SSOOktaUpdateForm.provideOktatkn")}.`,
        /* @__PURE__ */ jsx("br", {}),
        /* @__PURE__ */ jsx("br", {}),
        `${polyglot.t("SSOOktaUpdateForm.setupnote")}.`
      ] }) }),
      /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", mt: spacing.mt20 }, component: "section", children: /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
            /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                fullWidth: true,
                label: polyglot.t("SSOOktaUpdateForm.oktaDomain"),
                name: "oktaDomain",
                placeholder: oktaDomain,
                value: formik.values.oktaDomain,
                onChange: formik.handleChange,
                error: formik.touched.oktaDomain && !!formik.errors.oktaDomain,
                helperText: formik.touched.oktaDomain && formik.errors.oktaDomain,
                clearText: () => formik.setFieldValue("oktaDomain", ""),
                tooltip: /* @__PURE__ */ jsx(SSOOktaDomainToolTipInstructions, {})
              }
            ) }),
            /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                fullWidth: true,
                label: polyglot.t("SSOOktaUpdateForm.oidcToken"),
                name: "apikey",
                placeholder: apikey,
                value: formik.values.apikey,
                onChange: formik.handleChange,
                error: formik.touched.apikey && !!formik.errors.apikey,
                helperText: formik.touched.apikey && formik.errors.apikey,
                clearText: () => formik.setFieldValue("apikey", "")
              }
            ) })
          ] }) }),
          /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", my: spacing.m20 }, children: /* @__PURE__ */ jsx(
            LoaderButton,
            {
              fullWidth: true,
              disabled: formik.values.ssoEnabled && (!formik.values.apikey || !formik.values.oktaDomain) || !formik.dirty || loading,
              sizeVariant: "medium",
              colorVariant: "primary",
              name: polyglot.t("General.save"),
              loading
            }
          ) })
        ] }) }),
        /* @__PURE__ */ jsx(
          EnableSSODisableMFAConflictConfirmDrawer,
          {
            isOpen: confirmEnablement,
            setIsOpen: setConfirmEnablement,
            onClose: () => setConfirmEnablement(false),
            onConfirm: () => triggerEnableSSOAndDisableMFA()
          }
        )
      ] })
    ] }),
    !testingSSO && oktaEnabled && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", mt: spacing.mt20 }, component: "section", children: /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("SSOOktaUpdateForm.oktadomain"),
            name: "oktaDomain",
            placeholder: oktaDomain,
            value: formik.values.oktaDomain,
            onChange: formik.handleChange,
            error: formik.touched.oktaDomain && !!formik.errors.oktaDomain,
            helperText: (_a = formik.touched.oktaDomain && formik.errors.oktaDomain) != null ? _a : " ",
            clearText: () => formik.setFieldValue("oktaDomain", ""),
            tooltip: /* @__PURE__ */ jsx(SSOOktaDomainToolTipInstructions, {})
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("SSOOktaUpdateForm.apiTkn"),
            name: "apikey",
            placeholder: apikey,
            value: formik.values.apikey,
            onChange: formik.handleChange,
            error: formik.touched.apikey && !!formik.errors.apikey,
            helperText: (_b = formik.touched.apikey && formik.errors.apikey) != null ? _b : " ",
            clearText: () => formik.setFieldValue("apikey", "")
          }
        ) })
      ] }) }),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", mt: spacing.m20, gap: spacing.g10, justifyContent: "center" }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            onClick: () => {
              disableOktaSSO();
            },
            disabled: loading,
            children: polyglot.t("SSOOktaUpdateForm.disable")
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            disabled: formik.values.ssoEnabled && (!formik.values.apikey || !formik.values.oktaDomain) || !formik.dirty || loading,
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("SSOOktaUpdateForm.update"),
            loading
          }
        )
      ] })
    ] }) }) }),
    testingSSO && /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: polyglot.t("SSOOktaUpdateForm.testingsso") }) })
  ] });
};
