"use strict";
import { useEffect } from "react";
export function useKeypress(key, onKeyPressed) {
  useEffect(() => {
    if (!onKeyPressed) return () => {
    };
    const onKeyDown = (e) => {
      if (e.key === key) {
        onKeyPressed();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [key, onKeyPressed]);
}
export function useEscapeKey(onEscapeKeyPressed) {
  useKeypress("Escape", onEscapeKeyPressed);
}
