"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import Box from "@mui/material/Box";
import { NumberCell } from "@v2/components/table/number-cell.component";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { getNormalisedKeysOldReports, getNormalisedValue } from "@v2/feature/reports/util/report.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { translateColumnsOptions } from "@/v2/infrastructure/i18n/translate.util";
const DEVICE_NUMBER_COLUMNS = ["ram", "cpu", "gpu", "storage", "screenSize"];
const ATTENDANCE_NUMBER_COLUMNS = [];
const TIME_NUMBER_COLUMNS = ["workdayCount", "currentBalanceInDays"];
const ATTENDANCE_EXCEL_CLOCK_HOURS_COLUMNS = ["length"];
const TIME_EXCEL_CLOCK_HOURS_COLUMNS = ["length", "currentBalance", "unitsTaken", "unitsBooked"];
const PEOPLE_NUMBER_COLUMNS = [
  "userId",
  "Contract: ftePercent",
  "Contract: noticePeriodLength",
  "Contract: probationPeriodLength",
  "Contract: entityId",
  "Compensation: rate",
  "Compensation: units",
  "Payroll: openingCurrentTax",
  "Payroll: openingPreviousTax",
  "Payroll: openingCurrentGross",
  "Payroll: openingPreviousGross"
];
const NUMBER_COLUMNS_SET = /* @__PURE__ */ new Set([
  ...DEVICE_NUMBER_COLUMNS,
  ...ATTENDANCE_NUMBER_COLUMNS,
  ...PEOPLE_NUMBER_COLUMNS,
  ...TIME_NUMBER_COLUMNS
]);
export const EXCEL_CLOCK_HOURS_COLUMNS_SET = /* @__PURE__ */ new Set([
  ...ATTENDANCE_EXCEL_CLOCK_HOURS_COLUMNS,
  ...TIME_EXCEL_CLOCK_HOURS_COLUMNS
]);
function sortingFn(columnName, a, b) {
  const valueOfA = a.original;
  const valueOfB = b.original;
  if (NUMBER_COLUMNS_SET.has(columnName) || (!valueOfA[columnName] || typeof valueOfA[columnName] === "number") && (!valueOfB[columnName] || typeof valueOfB[columnName] === "number")) {
    return sortNumeric(
      a,
      b,
      (item) => item[columnName] ? Number.parseFloat(item[columnName]) : void 0
    );
  }
  if (EXCEL_CLOCK_HOURS_COLUMNS_SET.has(columnName)) {
    return sortNumeric(a, b, (item) => {
      const [hourValue, minutesValue] = item[columnName] ? item[columnName].split(":") : [];
      const h = hourValue ? Number.parseInt(hourValue) : 0;
      const m = minutesValue ? Number.parseInt(minutesValue) : 0;
      return h * 60 + m;
    });
  }
  return sortString(
    a,
    b,
    (item) => getNormalisedValue(item[columnName], columnName)
  );
}
export const ResultTableOldReports = ({ filteredData, resultColumnNameMapping, loading }) => {
  const { polyglot } = usePolyglot();
  const currentColumn = useMemo(() => {
    const headerName = (columnName) => {
      var _a;
      return (_a = resultColumnNameMapping == null ? void 0 : resultColumnNameMapping[columnName]) != null ? _a : translateColumnsOptions(
        columnName.split(/(?=[A-Z])/).join(" ").replaceAll("-", " ").replaceAll(":", "").replaceAll(" ", "").toLowerCase(),
        polyglot
      );
    };
    return getNormalisedKeysOldReports(filteredData).map((o) => {
      return {
        header: () => headerName(o),
        accessorFn: (row) => row,
        id: o,
        enableSorting: true,
        sortingFn: (a, b) => sortingFn(o, a, b),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: NUMBER_COLUMNS_SET.has(o) || EXCEL_CLOCK_HOURS_COLUMNS_SET.has(o) ? /* @__PURE__ */ jsx(NumberCell, { value: getNormalisedValue(original[o], o) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getNormalisedValue(original[o], o) }) }, o)
      };
    });
  }, [polyglot, filteredData, resultColumnNameMapping]);
  return /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: [...filteredData],
      columnData: currentColumn,
      fixedLastColumn: false,
      loading
    }
  );
};
