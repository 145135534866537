"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const TextComponentDrawer = ({ value, onSave }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      label: (_a = value == null ? void 0 : value.label) != null ? _a : "",
      required: (_b = value == null ? void 0 : value.required) != null ? _b : false
    },
    validationSchema: yup.object({
      label: yup.string().required(polyglot.t("TextComponentDrawer.required"))
    }),
    onSubmit(values) {
      var _a2;
      onSave == null ? void 0 : onSave({
        type: "text",
        id: (_a2 = value == null ? void 0 : value.id) != null ? _a2 : uuidv4(),
        label: values.label,
        required: values.required
      });
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("TextComponentDrawer.field") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "label",
        label: polyglot.t("TextComponentDrawer.label"),
        value: formik.values.label,
        onChange: formik.handleChange,
        maxLength: 50,
        autoFocus: true,
        clearText: () => formik.setFieldValue("label", ""),
        helperText: formik.submitCount ? formik.errors.label : "",
        error: !!formik.submitCount && !!formik.errors.label
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "required",
        label: polyglot.t("General.required"),
        checked: formik.values.required,
        onChange: formik.handleChange
      }
    ),
    !!formik.values.label && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "primary", children: polyglot.t("General.save") }) })
  ] }) });
};
