"use strict";
import { BENEFITS_COMPANY_ROUTE, BENEFITS_ME_ROUTE } from "@/lib/routes";
export const MONEY_BENEFITS_ROUTES = {
  company: BENEFITS_COMPANY_ROUTE,
  me: BENEFITS_ME_ROUTE
};
export const MONEY_BENEFITS_SCOPES = {
  company: ["insurance:all"],
  me: ["insurance:read"]
};
