"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GrowthQuestionLibraryContent = ({
  questions,
  setIsQuestionsModalOpen,
  action,
  setSearchInput,
  searchInput,
  allGrowthFactors,
  setFilterString,
  filterString,
  factorFilterTypes,
  questionsLoading
}) => {
  const [selectedQuestionIds, setSelectedQuestionIds] = useState(/* @__PURE__ */ new Set());
  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedQuestionIds(new Set(questions == null ? void 0 : questions.map(({ id }) => id)));
    } else {
      setSelectedQuestionIds(/* @__PURE__ */ new Set());
    }
  };
  const handleSelectQuestion = (id, checked) => {
    const newSelectedQuestionIds = new Set(selectedQuestionIds);
    if (checked) {
      newSelectedQuestionIds.add(id);
    } else {
      newSelectedQuestionIds.delete(id);
    }
    setSelectedQuestionIds(newSelectedQuestionIds);
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        position: "relative",
        width: "100%",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsx(
          QuestionLibraryHeader,
          {
            searchInput,
            setSearchInput,
            setFilterString,
            filterString,
            factorFilterTypes
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              mt: spacing.m24,
              px: spacing.px16,
              py: spacing.p12,
              display: "flex",
              alignItems: "center",
              gap: spacing.g12
            },
            children: [
              /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  labelPlacement: "end",
                  checked: selectedQuestionIds.size > 0 && selectedQuestionIds.size === (questions == null ? void 0 : questions.length),
                  disabled: (questions == null ? void 0 : questions.length) === 0,
                  onChange: (_, checked) => handleSelectAll(checked),
                  sx: { width: "100%" }
                }
              ),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "All questions" })
            ]
          }
        ),
        questionsLoading ? /* @__PURE__ */ jsx(LoadingState, {}) : questions ? /* @__PURE__ */ jsx(Box, { sx: { maxHeight: "100%", display: "flex", flexDirection: "column" }, children: questions.map((question, idx) => /* @__PURE__ */ jsx(
          QuestionRow,
          {
            question,
            allGrowthFactors,
            selectedQuestionIds,
            handleSelectQuestion,
            isFirstRow: idx === 0
          },
          question.id
        )) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: spacing.px16, children: "No questions found" }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              boxSizing: "border-box",
              position: "sticky",
              bottom: "24px",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "16px"
            },
            children: /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                colorVariant: "primary",
                sizeVariant: "medium",
                disabled: selectedQuestionIds.size === 0 || questionsLoading,
                onClick: () => __async(void 0, null, function* () {
                  if (selectedQuestionIds) yield action(selectedQuestionIds);
                  setIsQuestionsModalOpen(false);
                }),
                children: "Add questions"
              }
            )
          }
        )
      ]
    }
  );
};
const QuestionRow = ({
  question,
  allGrowthFactors,
  selectedQuestionIds,
  handleSelectQuestion,
  isFirstRow
}) => {
  var _a, _b;
  const factorColor = (_b = (_a = allGrowthFactors == null ? void 0 : allGrowthFactors.find((factor) => factor.name === question.factor)) == null ? void 0 : _a.color) != null ? _b : themeColors.Grey;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        gap: spacing.g12,
        py: spacing.p12,
        borderTop: isFirstRow ? `1px solid ${themeColors.lightGrey}` : "none",
        borderBottom: `1px solid ${themeColors.lightGrey}`,
        borderCollapse: "collapse",
        px: spacing.px16,
        "&:hover": {
          backgroundColor: themeColors.TableHover
        }
      },
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { width: "80%", display: "flex", gap: spacing.g12, alignItems: "center" }, children: /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            role: "checkbox",
            id: "question-check",
            checked: selectedQuestionIds.has(question.id),
            onChange: (_, checked) => handleSelectQuestion(question.id, checked),
            sx: { mr: "auto" },
            label: question.questionText
          }
        ) }),
        question.factor && /* @__PURE__ */ jsxs(Box, { sx: { width: "20%", display: "flex", justifyContent: "flex-end", alignItems: "center", gap: spacing.g4 }, children: [
          /* @__PURE__ */ jsx("div", { style: { backgroundColor: factorColor, width: "8px", height: "8px", borderRadius: "32px" } }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: question.factor })
        ] })
      ]
    }
  );
};
const QuestionLibraryHeader = ({
  searchInput,
  setSearchInput,
  setFilterString,
  filterString,
  factorFilterTypes
}) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", px: spacing.px16, gap: spacing.g16 }, children: [
  /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Question library" }),
  /* @__PURE__ */ jsx(
    TableSearch,
    {
      style: { width: "100%", boxSizing: "border-box", marginTop: spacing.m24 },
      query: searchInput,
      handleChange: (e) => setSearchInput(e.target.value)
    }
  ),
  factorFilterTypes && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
    CategoryFilters,
    {
      filterTypes: factorFilterTypes,
      setFilterString,
      filterString
    }
  ) })
] });
const LoadingState = () => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column"
      },
      children: [1, 2, 3, 4, 5, 6, 7].map((idx) => {
        return /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              width: "100%",
              boxSizing: "border-box",
              gap: spacing.g12,
              py: spacing.p12,
              borderTop: idx === 0 ? `1px solid ${themeColors.lightGrey}` : "none",
              borderBottom: `1px solid ${themeColors.lightGrey}`,
              borderCollapse: "collapse",
              px: spacing.px16,
              "&:hover": {
                backgroundColor: themeColors.TableHover
              }
            },
            children: /* @__PURE__ */ jsx(Box, { sx: { width: "80%", display: "flex", gap: spacing.g12, alignItems: "center", height: "20px" }, children: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                width: "90%",
                height: "90vh",
                sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
              }
            ) })
          },
          idx
        );
      })
    }
  );
};
