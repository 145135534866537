"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class BillingAPI {
  static getBillingInformation() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/billing/info")).data;
    });
  }
  static manuallyChargeCustomerForUnpaidInvoice(invoice, companySubscription) {
    return __async(this, null, function* () {
      return yield axios.post("/apiv2/billing/manually-charge-invoice", { invoice, companySubscription });
    });
  }
  static updateXeroMappings(mappings) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/billing/xero-mapping`, mappings);
    });
  }
  static manualUpgradeIntercept(manualUpgradeBody) {
    return __async(this, null, function* () {
      return yield axios.post("/apiv2/billing/annual-customer-manual-upgrade-intercept", manualUpgradeBody);
    });
  }
}
export class BillingEndpoints {
  static getBillingInfo() {
    return { url: "/apiv2/billing/info" };
  }
  static getLocalMapping() {
    return { url: "/apiv2/billing/xero-mapping" };
  }
  static getXeroItemCodesForMapping() {
    return { url: "/apiv2/billing/xero-mapping/external/items" };
  }
}
