"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { getModelImage } from "@/v2/feature/device/device.util";
import { DeviceSettingsAPI } from "@/v2/feature/device/features/devices-settings/devices-settings.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DevicesStoreSettingsPage = () => {
  const routerLocation = useLocation();
  const [deviceModels, setDeviceModels] = useState([]);
  const [generalSettings, setGeneralSettings] = useState(void 0);
  const [hiddenDevices, setHiddenDevices] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const getAllAvailableDevices = useCallback(() => __async(void 0, null, function* () {
    var _a;
    try {
      setLoading(true);
      const [deviceModels2, settings] = yield Promise.all([
        DeviceAPI.getDeviceModels(),
        DeviceSettingsAPI.getCompanyDeviceSettings()
      ]);
      setDeviceModels(deviceModels2);
      setGeneralSettings(settings);
      setHiddenDevices((_a = settings.hiddenDeviceModels) != null ? _a : []);
    } catch (error) {
      showMessage(`Could not retrieve devices list. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    getAllAvailableDevices();
  }, [getAllAvailableDevices]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Devices",
        accessorFn: (row) => row,
        id: "modelName",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Box, { children: deviceModel && getModelImage(deviceModel.type, deviceModel.modelName, {
              width: "30px",
              height: "auto"
            }) }),
            deviceModel.modelName
          ] });
        },
        size: 135
      },
      {
        header: () => "Model Number",
        accessorFn: (row) => row,
        id: "modelNumber",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.modelNumber ? /* @__PURE__ */ jsx(Box, { children: deviceModel.modelNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      },
      {
        header: () => "Model Version",
        accessorFn: (row) => row,
        id: "modelVersion",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.modelVersion ? /* @__PURE__ */ jsx(Box, { children: deviceModel.modelVersion }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 110
      },
      {
        header: () => "Manufacturer",
        accessorFn: (row) => row,
        id: "manufacturer",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.manufacturer ? /* @__PURE__ */ jsx(Box, { children: deviceModel.manufacturer }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 110
      },
      {
        header: () => "Screen Size",
        accessorFn: (row) => row,
        id: "screenSize",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.screenSize ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.screenSize,
            '"'
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "RAM",
        accessorFn: (row) => row,
        id: "ram",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.ram ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.ram,
            "GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 30
      },
      {
        header: () => "Storage",
        accessorFn: (row) => row,
        id: "storage",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.storage ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.storage,
            "GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 50
      },
      {
        header: () => "CPU",
        accessorFn: (row) => row,
        id: "cpuCores",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return deviceModel.cpuCores ? /* @__PURE__ */ jsxs(Box, { children: [
            deviceModel.cpuCores,
            "-cores"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 65
      },
      {
        header: () => "Hide",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: (info) => {
          const deviceModel = info.getValue();
          return /* @__PURE__ */ jsx(
            SwitchComponent,
            {
              checked: hiddenDevices ? hiddenDevices == null ? void 0 : hiddenDevices.some((d) => d === deviceModel.id) : false,
              name: "show-device",
              onChange: (_e, enabled) => __async(void 0, null, function* () {
                let finalArray = [];
                if (enabled) {
                  finalArray = [...hiddenDevices != null ? hiddenDevices : [], deviceModel.id];
                } else {
                  finalArray = hiddenDevices && (hiddenDevices == null ? void 0 : hiddenDevices.length) > 0 ? hiddenDevices.filter((d) => d !== deviceModel.id) : [];
                }
                const updateSettings = {
                  requiredEnrolment: generalSettings == null ? void 0 : generalSettings.requiredEnrolment,
                  hiddenDeviceModels: finalArray
                };
                setHiddenDevices(finalArray);
                yield DeviceSettingsAPI.updateCompanyDeviceSettings(updateSettings);
              })
            }
          );
        },
        size: 65
      }
    ];
  }, [hiddenDevices, generalSettings == null ? void 0 : generalSettings.requiredEnrolment]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Store" }),
        showBack: routerLocation.pathname.includes("/settings")
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsx(BasicTable, { rowData: [...deviceModels], columnData, loading }) })
  ] });
};
