"use strict";
import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from "react";
const UserOnboardingByUserContext = createContext({
  onboardingComplete: false,
  pendingRequirements: {},
  setPendingRequirements: () => {
  }
});
export const useUserOnboardingByUserContext = () => {
  const contextState = useContext(UserOnboardingByUserContext);
  if (contextState === null) {
    throw new Error("useUserOnboardingByUserContext must be used within a UserOnboardingByUserProvider tag");
  }
  return contextState;
};
export const UserOnboardingByUserProvider = ({ children }) => {
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [pendingRequirements, setPendingRequirements] = useState([]);
  useEffect(() => {
    setOnboardingComplete(pendingRequirements.length === 0);
  }, [pendingRequirements]);
  return /* @__PURE__ */ jsx(UserOnboardingByUserContext.Provider, { value: { onboardingComplete, pendingRequirements, setPendingRequirements }, children });
};
