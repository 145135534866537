"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import { GlobalContext } from "@/GlobalState";
import { TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getDetailByDomain } from "@/v2/feature/dashboard/dashboard.util";
import { DomainTypes } from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { MissingPersonalEmployeeFieldsModal } from "@/v2/feature/templates/components/missing-personal-employee-fields-modal.component";
import { MissingTemplateFieldModal } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import {
  ADDITIONAL_SIGNATORY_PLACEHOLDER,
  EMPLOYEE_SIGNATORY_PLACEHOLDER,
  OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER,
  SignatoryType,
  WITNESS_DETAILS_PLACEHOLDER
} from "@/v2/feature/templates/templates.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const ContractActionItem = ({
  selectedRow,
  afterClose,
  isModalOpen
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const routerHistory = useHistory();
  const [isEditOpen, setIsEditOpen] = useState(isModalOpen);
  const [missingFields, setMissingFields] = useState({});
  const [openMissingEmployeeFieldModal, setOpenMissingEmployeeFieldModal] = useState(false);
  const [openMissingAdditionalSignatoryModal, setOpenMissingAdditionalSignatoryModal] = useState(false);
  const signatoriesRequiredForContract = (templateText) => {
    const signatories = [];
    if (templateText.includes(EMPLOYEE_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.recipient);
    if (templateText.includes(OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.additional);
    if (templateText.includes(ADDITIONAL_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.additional);
    if (templateText.includes(WITNESS_DETAILS_PLACEHOLDER)) signatories.push(SignatoryType.witness);
    return signatories;
  };
  const companySignatureRequired = (row) => {
    var _a2, _b2, _c2;
    const signatoriesRequired = signatoriesRequiredForContract(row.contract.templateText);
    return ((_a2 = row == null ? void 0 : row.contract) == null ? void 0 : _a2.id) && signatoriesRequired && signatoriesRequired.length > 0 && signatoriesRequired.includes(SignatoryType.additional) && ((_b2 = row.contract) == null ? void 0 : _b2.companySignatory) === (currentUser == null ? void 0 : currentUser.userId) && !((_c2 = row.contract) == null ? void 0 : _c2.companySignatureTimestamp);
  };
  const recipientSignatureRequired = (row) => {
    var _a2, _b2, _c2;
    const signatoriesRequired = signatoriesRequiredForContract(row.contract.templateText);
    return ((_a2 = row == null ? void 0 : row.contract) == null ? void 0 : _a2.id) && signatoriesRequired && signatoriesRequired.length > 0 && signatoriesRequired.includes(SignatoryType.recipient) && ((_b2 = row.contract) == null ? void 0 : _b2.recipient) === (currentUser == null ? void 0 : currentUser.userId) && !((_c2 = row.contract) == null ? void 0 : _c2.recipientSignatureTimestamp);
  };
  const refreshMissingFieldsForEmployee = useCallback((contractId) => __async(void 0, null, function* () {
    const missingFieldData = yield ContractAPI.getEmployeeFieldsForContractById(contractId);
    setMissingFields(missingFieldData);
  }), []);
  const handleContractSignAction = (row) => __async(void 0, null, function* () {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g, _h;
    if (recipientSignatureRequired(row)) {
      if ((_a2 = Object.values(missingFields)) == null ? void 0 : _a2.some((v) => v === true)) {
        setOpenMissingEmployeeFieldModal(true);
      } else {
        routerHistory.push(
          generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
            templateId: (_b2 = row.contract) == null ? void 0 : _b2.templateId,
            contractId: (_c2 = row.contract) == null ? void 0 : _c2.id,
            userId: (_d2 = row.contract) == null ? void 0 : _d2.recipient
          })
        );
      }
    } else if (companySignatureRequired(row)) {
      const stateToPassThrough = {
        companySignatoryUserId: (_e2 = row.contract) == null ? void 0 : _e2.companySignatory
      };
      routerHistory.push(
        generatePath(TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, {
          templateId: (_f2 = row.contract) == null ? void 0 : _f2.templateId,
          contractId: (_g = row.contract) == null ? void 0 : _g.id,
          userId: (_h = row.contract) == null ? void 0 : _h.companySignatory
        }),
        stateToPassThrough
      );
    }
  });
  useEffect(() => {
    var _a2, _b2;
    if (((_a2 = selectedRow == null ? void 0 : selectedRow.contract) == null ? void 0 : _a2.id) && recipientSignatureRequired(selectedRow))
      refreshMissingFieldsForEmployee((_b2 = selectedRow == null ? void 0 : selectedRow.contract) == null ? void 0 : _b2.id);
  }, []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isEditOpen, setIsOpen: setIsEditOpen, afterClose, children: /* @__PURE__ */ jsxs("div", { children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        " ",
        polyglot.t("ContractActionItem.signContract")
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { marginTop: spacing.m10 }, children: getDetailByDomain(selectedRow, getCachedUserById, currentUser, polyglot) }),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true,
          onClick: () => handleContractSignAction(selectedRow),
          children: polyglot.t("ContractActionItem.viewAndSign")
        }
      ) })
    ] }) }),
    selectedRow && (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Contracts && openMissingEmployeeFieldModal && missingFields && recipientSignatureRequired(selectedRow) && /* @__PURE__ */ jsx(
      MissingPersonalEmployeeFieldsModal,
      {
        open: openMissingEmployeeFieldModal,
        setOpen: setOpenMissingEmployeeFieldModal,
        templateId: (_a = selectedRow.contract) == null ? void 0 : _a.templateId,
        contractId: (_b = selectedRow.contract) == null ? void 0 : _b.id,
        missingFields,
        contractRecipientId: (_c = selectedRow.contract) == null ? void 0 : _c.recipient,
        refreshMissingFields: refreshMissingFieldsForEmployee
      }
    ),
    selectedRow && (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Contracts && openMissingAdditionalSignatoryModal && missingFields && companySignatureRequired(selectedRow) && /* @__PURE__ */ jsx(
      MissingTemplateFieldModal,
      {
        open: openMissingAdditionalSignatoryModal,
        setOpen: setOpenMissingAdditionalSignatoryModal,
        templateId: (_d = selectedRow == null ? void 0 : selectedRow.contract) == null ? void 0 : _d.templateId,
        missingFields,
        contractRecipientId: (_e = selectedRow == null ? void 0 : selectedRow.contract) == null ? void 0 : _e.recipient,
        companySignatoryUserId: (_f = selectedRow == null ? void 0 : selectedRow.contract) == null ? void 0 : _f.companySignatory
      }
    )
  ] });
};
