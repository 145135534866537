"use strict";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { getIndexOfWeekInSchedule } from "@v2/feature/attendance/attendance-schedule.util";
import {
  calculateSingleEntryLengthInMinutes,
  getTotalLengthOfDayFromScheduleEntries
} from "@v2/feature/attendance/attendance.util";
import { isEntryOvernight } from "@v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { extractTimeOnlyFromDateTimeString } from "@v2/util/date-format.util";
import { isSameDay } from "date-fns";
export const getMondayOfCurrentWeek = () => {
  const date = /* @__PURE__ */ new Date();
  const day = date.getDay(), diff = date.getDate() - day + (day === 0 ? -6 : 1);
  date.setDate(diff);
  return date;
};
export const getWorkTimeRangeFromScheduleByDay = (attendanceSchedule, day, date, polyglot) => {
  const dayEntries = attendanceSchedule[day];
  const weekIndex = getIndexOfWeekInSchedule(
    attendanceSchedule.startDateOfFirstWeek,
    date,
    attendanceSchedule.noOfWeeks
  );
  let isOvernight = false;
  if (!dayEntries || !dayEntries[weekIndex] || dayEntries[weekIndex].length === 0) return null;
  const totalLength = getTotalLengthOfDayFromScheduleEntries(
    dayEntries[weekIndex],
    null,
    attendanceSchedule.isFlexible
  );
  for (const entry of dayEntries[weekIndex]) {
    if (isEntryOvernight(entry)) {
      isOvernight = true;
      break;
    }
  }
  const start = extractTimeOnlyFromDateTimeString(dayEntries[weekIndex][0].from);
  const end = extractTimeOnlyFromDateTimeString(dayEntries[weekIndex].at(-1).to);
  const totalLengthString = convertMinutesToClockHours(totalLength, polyglot);
  return [start, end, totalLengthString, isOvernight];
};
export const getAttendanceEntry = (currentWeekEntries, currentDate) => {
  if (currentWeekEntries && currentWeekEntries.length === 0) return [];
  return currentWeekEntries.filter((a) => isSameDay(currentDate, new Date(a.logDate)));
};
export const calculateSummary = (attendanceEntriesLookup, date, polyglot) => {
  const result = {
    workEntries: ["", ""],
    hasOvernightEntry: false,
    totalLength: ""
  };
  if (attendanceEntriesLookup && attendanceEntriesLookup.length > 0 && date) {
    let totalLengthInMinutes = 0;
    let earliestStart = "";
    let latestEnd = "";
    let hasOvernightEntry = false;
    for (const entry of attendanceEntriesLookup) {
      const entryStart = extractTimeOnlyFromDateTimeString(entry.startHour);
      const entryEnd = extractTimeOnlyFromDateTimeString(entry.endHour);
      const isOvernight = entryStart > entryEnd;
      if (isOvernight) hasOvernightEntry = true;
      totalLengthInMinutes += calculateSingleEntryLengthInMinutes(entry);
      if (!earliestStart || entryStart < earliestStart) earliestStart = entryStart;
      if (!earliestStart || // no time saved yet
      isOvernight && !hasOvernightEntry || // no overnight entry so far and this one is overnight
      isOvernight && hasOvernightEntry && entryEnd > latestEnd || // already has one overnight entry and this one is also overnight ends later than the latestOne so far -- not really a valid case
      !isOvernight && !hasOvernightEntry && entryEnd > latestEnd)
        latestEnd = entryEnd;
    }
    result.workEntries[0] = earliestStart;
    result.workEntries[1] = latestEnd;
    result.hasOvernightEntry = hasOvernightEntry;
    result.totalLength = convertMinutesToClockHours(totalLengthInMinutes, polyglot);
  }
  return result;
};
export const getDayWithDateArray = (weekDate, day) => {
  const date = new Date(weekDate);
  switch (day) {
    case "monday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -6 : 1);
      date.setDate(diff);
      return ["Mon", date.getDate()];
    }
    case "tuesday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -5 : 2);
      date.setDate(diff);
      return ["Tue", date.getDate()];
    }
    case "wednesday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -4 : 3);
      date.setDate(diff);
      return ["Wed", date.getDate()];
    }
    case "thursday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -3 : 4);
      date.setDate(diff);
      return ["Thu", date.getDate()];
    }
    case "friday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -2 : 5);
      date.setDate(diff);
      return ["Fri", date.getDate()];
    }
    case "saturday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? -1 : 6);
      date.setDate(diff);
      return ["Sat", date.getDate()];
    }
    case "sunday": {
      const d = date.getDay(), diff = date.getDate() - d + (d === 0 ? 0 : 7);
      date.setDate(diff);
      return ["Sun", date.getDate()];
    }
    default: {
      return day;
    }
  }
};
