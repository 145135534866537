"use strict";
import { jsx } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { TIME_ME_CALENDAR_ROUTE } from "@/lib/routes";
import { CalendarPage } from "@/v2/feature/calendar/features/calendar.page";
export const CalendarMeRouter = ({
  calendarAdvEventsTimeline,
  setCalendarAdvEventsTimeline,
  setLoading,
  loading
}) => {
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { path: [TIME_ME_CALENDAR_ROUTE], children: /* @__PURE__ */ jsx(
    CalendarPage,
    {
      view: "me",
      calendarAdvEventsTimeline,
      setCalendarAdvEventsTimeline,
      setLoading,
      loading
    }
  ) }) });
};
