"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { CustomProfileFormEndpoints } from "@v2/feature/custom-fields/custom-profile-fields.api";
import { PeopleChangeUpdateReport } from "@v2/feature/reports/features/update-report/people/people-change-update-report.component";
import { PeopleCurrentUpdateReport } from "@v2/feature/reports/features/update-report/people/people-current-update-report.component";
import { ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { DataDomain, ReportTypePeopleSelection } from "@v2/feature/reports/reports.interface";
import { includeCustomFieldsColumns } from "@v2/feature/reports/util/people-report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const PeopleReportSection = ({
  report,
  loading,
  refreshReport
}) => {
  const { data: fields } = useApiClient(CustomProfileFormEndpoints.listAllFields(), { suspense: false });
  const { data: additionalColumns } = useApiClient(ReportsEndpoints.getPeopleReportsAdditionalColumnsOptions(), {
    suspense: false
  });
  const customFields = useMemo(() => {
    var _a;
    if (!report || report.config.domain !== DataDomain.People) return {};
    return includeCustomFieldsColumns(report.config.entities, (_a = fields == null ? void 0 : fields.dataFieldsCustom) != null ? _a : []);
  }, [fields == null ? void 0 : fields.dataFieldsCustom, report]);
  if (!customFields || !additionalColumns) return null;
  return report.config.type === ReportTypePeopleSelection.CurrentRecords && report.config.domain === DataDomain.People ? /* @__PURE__ */ jsx(
    PeopleCurrentUpdateReport,
    {
      report,
      loading,
      customFields,
      additionalColumns: additionalColumns != null ? additionalColumns : []
    }
  ) : /* @__PURE__ */ jsx(
    PeopleChangeUpdateReport,
    {
      report,
      loading,
      customFields,
      additionalColumns: additionalColumns != null ? additionalColumns : [],
      refreshReport
    }
  );
};
