"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as BackBtn } from "@/images/fields/Left.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const StepperHeader = ({
  content,
  contentSx,
  showStepBack = false,
  stepBackAction,
  stepBackSx,
  showBack = false,
  backAction,
  backSx,
  disableClose
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "100%", justifyContent: "space-between" }, children: [
    /* @__PURE__ */ jsx(Box, { sx: stepBackSx, children: showStepBack && /* @__PURE__ */ jsx(IconButton, { colorVariant: "secondary", sizeVariant: "small", onClick: stepBackAction, children: /* @__PURE__ */ jsx(BackBtn, {}) }) }),
    /* @__PURE__ */ jsx(Box, { sx: contentSx, children: content }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", justifyContent: "flex-end" }, backSx), children: showBack && /* @__PURE__ */ jsx(IconButton, { colorVariant: "secondary", sizeVariant: "small", onClick: backAction, disabled: disableClose, children: /* @__PURE__ */ jsx(Close, { stroke: themeColors.DarkGrey }) }) })
  ] });
};
