"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { AvatarCard, AvatarCardWidth } from "@/v2/components/avatar-card.component";
import { NavigationButtons } from "@/v2/components/navigation-buttons.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const avatarGap = spacing.g10;
const avatarGapValue = parseFloat(avatarGap);
const MaximumRenderedCards = 100;
export const TeamSlide = ({ cards, partialCards, sx, title, width }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const teamContainer = useRef(null);
  const [marginRight, setMarginRight] = useState(0);
  const renderedCardCount = Math.min(cards.length, MaximumRenderedCards);
  const getContainerWidth = useCallback(() => {
    return teamContainer.current ? Math.ceil(parseFloat(window.getComputedStyle(teamContainer.current).getPropertyValue("width"))) + marginRight : 0;
  }, [marginRight]);
  const calculateMaxScrollValue = useCallback(() => {
    if (!renderedCardCount) return 0;
    const totalAvatarsWidth = renderedCardCount * (AvatarCardWidth + avatarGapValue) - avatarGapValue;
    const containerWidth = getContainerWidth();
    return Math.max(totalAvatarsWidth - containerWidth, 0);
  }, [renderedCardCount, getContainerWidth]);
  const minScrollValue = 0;
  const maxScrollValue = calculateMaxScrollValue();
  const scrollAvatars = useCallback(
    (direction) => {
      const avatarsPerScroll = 2;
      const scrollAmount = (AvatarCardWidth + avatarGapValue) * avatarsPerScroll;
      setScrollPosition((position) => {
        return direction === "left" ? Math.max(position - scrollAmount, minScrollValue) : Math.min(position + scrollAmount, maxScrollValue);
      });
    },
    [maxScrollValue]
  );
  const recalculateMargin = useCallback(() => {
    if (partialCards) {
      setMarginRight(0);
      return;
    }
    const containerWidth = getContainerWidth();
    const maxCardsVisibleInContainer = Math.trunc(
      (containerWidth + avatarGapValue) / (AvatarCardWidth + avatarGapValue)
    );
    const newmr = Math.trunc(
      containerWidth && maxCardsVisibleInContainer ? containerWidth - maxCardsVisibleInContainer * (AvatarCardWidth + avatarGapValue) + avatarGapValue : 0
    );
    setMarginRight(newmr);
  }, [getContainerWidth, partialCards]);
  useLayoutEffect(recalculateMargin, [recalculateMargin]);
  useEffect(() => {
    const onResize = () => {
      recalculateMargin();
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [recalculateMargin]);
  const renderedCards = useMemo(
    () => cards.slice(0, renderedCardCount).map(({ user, cardProps, action, publicURL }) => /* @__PURE__ */ jsx(
      AvatarCard,
      __spreadValues({
        size: "large",
        userId: user.userId,
        title: `${user.firstName} ${user.lastName.slice(0, 1)}`,
        onClick: action,
        publicURL
      }, cardProps),
      user.userId
    )),
    [cards, renderedCardCount]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadProps(__spreadValues({}, sx), { width }), children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between", mr: marginRight + "px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { whiteSpace: "nowrap", color: themeColors.DarkGrey }), children: title }),
      /* @__PURE__ */ jsx(
        NavigationButtons,
        {
          disableBack: scrollPosition <= minScrollValue,
          disabledNext: scrollPosition >= maxScrollValue,
          onNext: () => scrollAvatars("right"),
          onBack: () => scrollAvatars("left")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      Stack,
      {
        ref: teamContainer,
        sx: { overflow: "hidden", marginTop: spacing.mt20, marginRight: `${marginRight != null ? marginRight : 0}px` },
        children: /* @__PURE__ */ jsx(
          Stack,
          {
            sx: {
              flexFlow: "row",
              gap: avatarGap,
              width: "fit-content",
              // setting a negative margin-left "scrolls" the view
              ml: `${-scrollPosition}px`,
              transition: "margin-left 0.4s"
            },
            children: renderedCards
          }
        )
      }
    )
  ] });
};
