"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { spacing } from "@v2/styles/spacing.styles";
import { useParams } from "react-router-dom";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const AuxPage = () => {
  const [isPayLoading, setIsPayLoading] = useState(false);
  const [isSimulatePaymentLoading, setIsSimulatePaymentLoading] = useState(false);
  const { invoiceId } = useParams();
  const [showMessage] = useMessage();
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap60 }, children: [
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Temporary pay button",
        onClick: () => __async(void 0, null, function* () {
          try {
            setIsPayLoading(true);
            yield ContractorInvoiceAPI.payInvoice(invoiceId);
            showMessage("Success", "success");
          } catch (error) {
            showMessage(`Error. ${nestErrorMessage(error)}`, "error");
          } finally {
            setIsPayLoading(false);
          }
        }),
        loading: isPayLoading,
        colorVariant: "primary",
        sizeVariant: "large"
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Simulate payment request",
        colorVariant: "secondary",
        sizeVariant: "large",
        onClick: () => __async(void 0, null, function* () {
          try {
            setIsSimulatePaymentLoading(true);
            yield ContractorInvoiceAPI.simulatePaymentResponse(invoiceId);
            showMessage("Success", "success");
          } catch (error) {
            showMessage(`Error. ${nestErrorMessage(error)}`, "error");
          } finally {
            setIsSimulatePaymentLoading(false);
          }
        }),
        loading: isSimulatePaymentLoading
      }
    )
  ] }) });
};
