"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { OauthClientAPI } from "@/api-client/oauth-client.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UploadPublicImage } from "@/v2/components/uploadPublicImage/upload-public-image.component";
export const DeveloperHubClientGenerateDrawer = ({
  isOpen,
  existingClient,
  setIsOpen,
  onClose,
  onUpdate,
  setClientInfo
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [clientLoading, setClientLoading] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { publicURL } = globalState;
  const formik = useFormik({
    initialValues: __spreadValues({
      name: void 0,
      description: void 0,
      redirectionUri: void 0,
      // logo: undefined,
      scopes: []
    }, existingClient),
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      redirectionUri: Yup.string().nullable().required("Required"),
      scopes: Yup.array().nullable().required("Required")
    }),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      console.info("values", values);
    }
  });
  const generateClient = () => __async(void 0, null, function* () {
    try {
      const { name, description, scopes, redirectionUri } = formik.values;
      if (!name || !description || !scopes || !redirectionUri) return;
      setClientLoading(true);
      const newClient = yield OauthClientAPI.createNewClient(name, description, redirectionUri, scopes);
      if (newClient && setClientInfo) {
        setClientInfo(newClient);
        formik.resetForm();
        onClose();
        showMessage(polyglot.t("DeveloperHubClientGenerateDrawer.successMessages.create"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("DeveloperHubClientGenerateDrawer.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setClientLoading(false);
    }
  });
  const updateClient = () => __async(void 0, null, function* () {
    try {
      const { clientId, id, name, description, scopes, redirectionUri, logo } = formik.values;
      if (!clientId || !id || !name || !description || !scopes || !redirectionUri) return;
      setClientLoading(true);
      yield OauthClientAPI.updateExistingClient(clientId, id, name, description, redirectionUri, scopes, logo);
      showMessage(polyglot.t("DeveloperHubClientGenerateDrawer.successMessages.update"), "success");
      onUpdate();
      onClose();
    } catch (error) {
      showMessage(
        polyglot.t("DeveloperHubClientGenerateDrawer.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setClientLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        onClose();
      },
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t((existingClient == null ? void 0 : existingClient.clientId) ? "DeveloperHub.editApp" : "DeveloperHub.newApp") }),
        !(existingClient == null ? void 0 : existingClient.clientId) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DeveloperHubClientGenerateDrawer.subtitle"),
          /* @__PURE__ */ jsx("b", { children: polyglot.t("DeveloperHubClientGenerateDrawer.once") }),
          "."
        ] }),
        /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "name",
            label: polyglot.t("DeveloperHub.clientTable.name"),
            value: formik.values.name,
            onChange: formik.handleChange,
            error: formik.touched.name && !!formik.errors.name,
            helperText: formik.touched.name && formik.errors.name,
            endAdornment: "none",
            fullWidth: true
          }
        ) }),
        (existingClient == null ? void 0 : existingClient.clientId) && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("DeveloperHub.appIcon") }),
          /* @__PURE__ */ jsx(
            UploadPublicImage,
            {
              disabled: false,
              uploadedFileName: formik.values.logo ? `Icon uploaded (${formik.values.logo})` : "",
              value: formik.values.logo ? `${publicURL}/${formik.values.logo}` : void 0,
              onChange: (temp) => __async(void 0, null, function* () {
                formik.setFieldValue("logo", temp);
              }),
              url: OauthClientAPI.getLogoUploadURL(existingClient == null ? void 0 : existingClient.clientId),
              refresh: () => __async(void 0, null, function* () {
              }),
              width: "100%",
              restrictedFormats: ["image/webp", "image/jpeg", "image/jpg", "image/png"],
              handleDelete: () => __async(void 0, null, function* () {
                try {
                  if (formik.values.logo) {
                    yield OauthClientAPI.deleteClientLogo(existingClient.clientId, formik.values.logo);
                    formik.setFieldValue("logo", null);
                  }
                } catch (error) {
                  showMessage(
                    `${polyglot.t("CompanySettingsGeneralEdit.errorMessages.delete")}: ${nestErrorMessage(error)}`,
                    "error"
                  );
                }
              })
            }
          )
        ] }),
        /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "description",
            label: polyglot.t("DeveloperHub.clientTable.description"),
            value: formik.values.description,
            onChange: formik.handleChange,
            error: formik.touched.description && !!formik.errors.description,
            helperText: formik.touched.description && formik.errors.description,
            endAdornment: "none",
            fullWidth: true
          }
        ) }),
        /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "redirectionUri",
            label: polyglot.t("DeveloperHub.clientTable.redirectionUri"),
            value: formik.values.redirectionUri,
            onChange: formik.handleChange,
            error: formik.touched.redirectionUri && !!formik.errors.redirectionUri,
            helperText: formik.touched.redirectionUri && formik.errors.redirectionUri,
            endAdornment: "none",
            fullWidth: true
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            fullWidth: true,
            colorVariant: existingClient ? "secondary" : "primary",
            sizeVariant: "medium",
            loading: clientLoading,
            onClick: () => __async(void 0, null, function* () {
              if (!existingClient) yield generateClient();
              else yield updateClient();
            }),
            disabled: !formik.isValid || clientLoading,
            children: polyglot.t(existingClient ? "DeveloperHub.updateClient" : "DeveloperHub.generate")
          }
        ) })
      ] }) })
    }
  );
};
