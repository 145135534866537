"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltPolicyPayloadIdentifier } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const ZeltMdmAdditionalPasswordSecurityPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const getPolicy = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    return {
      allowAutoUnlock: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _a.allowAutoUnlock) !== void 0 ? !((_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _b.allowAutoUnlock) : false,
      allowFingerprintForUnlock: ((_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _c.allowFingerprintForUnlock) !== void 0 ? !((_d = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _d.allowFingerprintForUnlock) : false,
      allowPasswordSharing: ((_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _e.allowPasswordSharing) !== void 0 ? !((_f = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _f.allowPasswordSharing) : false,
      allowPasswordProximityRequests: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _g.allowPasswordProximityRequests) !== void 0 ? !((_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _h.allowPasswordProximityRequests) : false,
      allowPasswordAutoFill: ((_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _i.allowPasswordAutoFill) !== void 0 ? !((_j = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _j.allowPasswordAutoFill) : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(passwordForm) {
    let allNulls = true;
    for (const key in passwordForm) {
      if (key !== "PayloadIdentifier" && passwordForm[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return void 0;
    }
    return {
      allowFingerprintForUnlock: !passwordForm.allowFingerprintForUnlock,
      allowAutoUnlock: !passwordForm.allowAutoUnlock,
      allowPasswordProximityRequests: !passwordForm.allowPasswordProximityRequests,
      allowPasswordSharing: !passwordForm.allowPasswordSharing,
      allowPasswordAutoFill: !passwordForm.allowPasswordAutoFill,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (passwordForm) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(passwordForm);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableAdditionalPasswordPolicy: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Additional password security" }),
    /* @__PURE__ */ jsx(Box, { sx: { gap: 2, mt: 2 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Restrict password actions:" }) }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 2 }, children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Touch ID unlock",
            name: "allowFingerprintForUnlock",
            checked: formik.values.allowFingerprintForUnlock,
            onChange: (e, checked) => {
              formik.setFieldValue("allowFingerprintForUnlock", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Auto unlock",
            name: "allowAutoUnlock",
            checked: formik.values.allowAutoUnlock,
            onChange: (e, checked) => {
              formik.setFieldValue("allowAutoUnlock", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Requesting password from nearby devices",
            name: "allowPasswordProximityRequests",
            checked: formik.values.allowPasswordProximityRequests,
            onChange: (e, checked) => {
              formik.setFieldValue("allowPasswordProximityRequests", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Password sharing via Airdrop",
            name: "allowPasswordSharing",
            checked: formik.values.allowPasswordSharing,
            onChange: (e, checked) => {
              formik.setFieldValue("allowPasswordSharing", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Auto-filling passwords",
            name: "allowPasswordAutoFill",
            checked: formik.values.allowPasswordAutoFill,
            onChange: (e, checked) => {
              formik.setFieldValue("allowPasswordAutoFill", checked);
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => formik.handleSubmit(), fullWidth: true, colorVariant: "primary", sizeVariant: "medium", children: "Save" }) })
    ] })
  ] });
};
