"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltMdmRestrictionPolicyEditor } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-restriction-policy-editor.component";
import {
  ZeltMdmDevicePolicyType,
  ZeltPolicyPayloadIdentifier
} from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { themeColors } from "@/v2/styles/colors.styles";
export const ZeltMdmRestrictionPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [selectedRestriction, setSelectedRestriction] = useState();
  const iCloudRestriction = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
    return {
      allowCloudBookmarks: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _a.allowCloudBookmarks) !== void 0 ? (_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _b.allowCloudBookmarks : true,
      allowCloudMail: ((_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _c.allowCloudMail) !== void 0 ? (_d = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _d.allowCloudMail : true,
      allowCloudCalendar: ((_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _e.allowCloudCalendar) !== void 0 ? (_f = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _f.allowCloudCalendar : true,
      allowCloudReminders: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _g.allowCloudReminders) !== void 0 ? (_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _h.allowCloudReminders : true,
      allowCloudAddressBook: ((_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _i.allowCloudAddressBook) !== void 0 ? (_j = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _j.allowCloudAddressBook : true,
      allowCloudNotes: ((_k = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _k.allowCloudNotes) !== void 0 ? (_l = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _l.allowCloudNotes : true,
      allowCloudDocumentSync: ((_m = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _m.allowCloudDocumentSync) !== void 0 ? (_n = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _n.allowCloudDocumentSync : true,
      allowCloudKeychainSync: ((_o = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _o.allowCloudKeychainSync) !== void 0 ? (_p = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _p.allowCloudKeychainSync : true,
      allowCloudPhotoLibrary: ((_q = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _q.allowCloudPhotoLibrary) !== void 0 ? (_r = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _r.allowCloudPhotoLibrary : true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const securityRestrictions = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
    return {
      allowCamera: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _a.allowCamera) !== void 0 ? (_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _b.allowCamera : true,
      allowSafari: ((_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _c.allowSafari) !== void 0 ? (_d = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _d.allowSafari : true,
      allowAirDrop: ((_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _e.allowAirDrop) !== void 0 ? (_f = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _f.allowAirDrop : true,
      allowDictation: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _g.allowDictation) !== void 0 ? (_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _h.allowDictation : true,
      allowScreenShot: ((_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _i.allowScreenShot) !== void 0 ? (_j = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _j.allowScreenShot : true,
      allowAssistant: ((_k = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _k.allowAssistant) !== void 0 ? (_l = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _l.allowAssistant : true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const browsingRestrictions = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return {
      restrictWeb: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _a.restrictWeb) !== void 0 ? (_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _b.restrictWeb : false,
      useContentFilter: ((_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _c.useContentFilter) !== void 0 ? (_d = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _d.useContentFilter : false,
      filterBlacklist: ((_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _e.filterBlacklist) !== void 0 ? (_f = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _f.filterBlacklist : [],
      filterWhitelist: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _g.filterWhitelist) !== void 0 ? (_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _h.filterWhitelist : [],
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.BROWSING_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "iCloud restrictions" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setSelectedRestriction(ZeltMdmDevicePolicyType.icloud);
                  setIsOpenEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Safari bookmarks" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudBookmarks ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudBookmarks ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Mail" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudMail ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudMail ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Calendar" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudCalendar ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudCalendar ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Reminders" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudReminders ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudReminders ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Contacts" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudAddressBook ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudAddressBook ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Notes" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudNotes ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudNotes ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Drive" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudDocumentSync ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudDocumentSync ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "KeyChain sync" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudKeychainSync ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudKeychainSync ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Photos" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, iCloudRestriction().allowCloudPhotoLibrary ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: iCloudRestriction().allowCloudPhotoLibrary ? "Allowed" : "Restricted"
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Apps and features restrictions" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setSelectedRestriction(ZeltMdmDevicePolicyType.security);
                  setIsOpenEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Camera" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, securityRestrictions().allowCamera ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: securityRestrictions().allowCamera ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Safari" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, securityRestrictions().allowSafari ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: securityRestrictions().allowSafari ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "AirDrop" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, securityRestrictions().allowAirDrop ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: securityRestrictions().allowAirDrop ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Siri" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, securityRestrictions().allowAssistant ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: securityRestrictions().allowAssistant ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Dictation" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, securityRestrictions().allowDictation ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: securityRestrictions().allowDictation ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Screen capture" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, securityRestrictions().allowScreenShot ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: securityRestrictions().allowScreenShot ? "Allowed" : "Restricted"
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Safari browsing" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setSelectedRestriction(ZeltMdmDevicePolicyType.browsing);
                  setIsOpenEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Blacklist web URLs" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, browsingRestrictions().filterBlacklist.length > 0 ? __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 })),
                children: browsingRestrictions().filterBlacklist.length > 0 ? browsingRestrictions().filterBlacklist : "Not set"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Whitelist web URLs" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, browsingRestrictions().filterWhitelist.length > 0 ? __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 })),
                children: browsingRestrictions().filterWhitelist.length > 0 ? browsingRestrictions().filterWhitelist : "Not set"
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isOpenEditor, setIsOpen: setIsOpenEditor, children: /* @__PURE__ */ jsx(
      ZeltMdmRestrictionPolicyEditor,
      {
        configurablePoliciesSelected,
        updatePolicies,
        selectedRestriction,
        isOpen: isOpenEditor,
        setIsOpen: setIsOpenEditor
      }
    ) })
  ] });
};
