"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserCalendarVersionSmallLoader = () => {
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "small", isLoadingState: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs("div", { style: { padding: spacing.p5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Calendar" }),
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: "This week" })
    ] }) }) }),
    /* @__PURE__ */ jsx(Box, { sx: { height: "100%", padding: spacing.p5 }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: spacing.g25
        },
        children: [0, 1, 2, 3, 4].map((num) => /* @__PURE__ */ jsx("div", { className: "ucw-loader-container", children: /* @__PURE__ */ jsx("div", { className: "ucw-progress" }) }, `${num}-ucs`))
      }
    ) })
  ] }) });
};
