"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  ABSENCE_COMPANY_ROUTE,
  ABSENCE_ME_ROUTE,
  ABSENCE_TEAM_ROUTE,
  ATTENDANCE_COMPANY_ROUTE,
  ATTENDANCE_ME_ROUTE,
  ATTENDANCE_TEAM_ROUTE,
  TIME_COMPANY_CALENDAR_ROUTE,
  TIME_ME_CALENDAR_ROUTE,
  TIME_ROUTE,
  TIME_TEAM_CALENDAR_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { AbsenceRouter } from "@/v2/feature/absence/absence.router";
import { AttendanceRouter } from "@/v2/feature/attendance/attendance.router";
import { CalendarRouter } from "@/v2/feature/calendar/calendar.router";
export const TimeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["absence"],
        context: scopesContext,
        path: [TIME_COMPANY_CALENDAR_ROUTE, TIME_TEAM_CALENDAR_ROUTE, TIME_ME_CALENDAR_ROUTE],
        component: CalendarRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["absence"],
        context: scopesContext,
        path: [ABSENCE_COMPANY_ROUTE, ABSENCE_TEAM_ROUTE, ABSENCE_ME_ROUTE],
        component: AbsenceRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["attendance"],
        context: scopesContext,
        path: [ATTENDANCE_COMPANY_ROUTE, ATTENDANCE_TEAM_ROUTE, ATTENDANCE_ME_ROUTE],
        component: AttendanceRouter
      }
    ),
    canAccessScopes(user, ["absence:all"]) && /* @__PURE__ */ jsx(Redirect, { from: TIME_ROUTE, to: TIME_COMPANY_CALENDAR_ROUTE }),
    canAccessScopes(user, ["absence:manager", "absence:read:manager", "attendance:manager"]) && /* @__PURE__ */ jsx(Redirect, { from: TIME_ROUTE, to: TIME_TEAM_CALENDAR_ROUTE }),
    canAccessScopes(user, ["absence"]) && /* @__PURE__ */ jsx(Redirect, { from: TIME_ROUTE, to: TIME_ME_CALENDAR_ROUTE })
  ] });
};
