"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { EmergencyContactForm } from "@v2/feature/user/features/user-profile/details/components/user-profile-emergency-contact-form.component";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { MissingFieldsDetail } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const MissingEmergency = ({ userId, refreshData, missingFieldsForCategory }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: emergencyContact, isLoading } = useApiClient(UserEndpoints.getUserEmergencyContact(userId), {
    suspense: false
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "column" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Emergency contact information" }),
        /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => setIsModalOpen(true), children: /* @__PURE__ */ jsx(Plus, { width: "14px", height: "14px" }) })
      ] }),
      missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, closeIcon: "back", children: !isLoading ? /* @__PURE__ */ jsx(
      EmergencyContactForm,
      {
        userId,
        initialValues: emergencyContact != null ? emergencyContact : {
          emergencyName: "",
          emergencyRelationship: "",
          emergencyNumber: "",
          customUpdates: []
        },
        onSubmit: () => __async(void 0, null, function* () {
          yield refreshData == null ? void 0 : refreshData();
          setIsModalOpen(false);
        }),
        onClose: () => setIsModalOpen(false)
      }
    ) : /* @__PURE__ */ jsx(LoadingSpinner, {}) })
  ] });
};
