"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { useHistory } from "react-router-dom";
import { LOGIN_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const AuthLoginButton = ({ sx }) => {
  const history = useHistory();
  return /* @__PURE__ */ jsxs(Stack, { direction: "row", alignItems: "center", sx: __spreadValues({}, sx), children: [
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        type: "button",
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => history.push(LOGIN_ROUTE),
        children: "Log in"
      }
    ),
    window.outerWidth > 400 && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { ml: "0.5em" }, children: "if you already have an account" })
  ] });
};
