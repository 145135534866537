"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useReducer } from "react";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
import { AccountStatus } from "@/lib/users";
export var GlobalStateActions = /* @__PURE__ */ ((GlobalStateActions2) => {
  GlobalStateActions2["UPDATE_USER"] = "UPDATE_USER";
  GlobalStateActions2["LOG_OUT"] = "LOG_OUT";
  GlobalStateActions2["UPDATE_HIDDEN_FEATURE"] = "UPDATE_HIDDEN_FEATURE";
  GlobalStateActions2["SHOW_MESSAGE"] = "SHOW_MESSAGE";
  GlobalStateActions2["HIDE_MESSAGE"] = "HIDE_MESSAGE";
  GlobalStateActions2["UPDATE_INSPECTOR"] = "UPDATE_INSPECTOR";
  GlobalStateActions2["UPDATE_DRAWER"] = "UPDATE_DRAWER";
  GlobalStateActions2["UPDATE_ALERTS"] = "UPDATE_ALERTS";
  GlobalStateActions2["PAYMENT_FAILED"] = "PAYMENT_FAILED";
  GlobalStateActions2["UPDATE_TEST_MODE"] = "UPDATE_TEST_MODE";
  GlobalStateActions2["UPDATE_PUBLIC_URL"] = "UPDATE_PUBLIC_URL";
  GlobalStateActions2["UPDATE_TRIAL_MODE"] = "UPDATE_TRIAL_MODE";
  GlobalStateActions2["UPDATE_SHOW_SETTING_BAR"] = "UPDATE_SHOW_SETTING_BAR";
  return GlobalStateActions2;
})(GlobalStateActions || {});
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER" /* UPDATE_USER */:
      return __spreadProps(__spreadValues({}, state), {
        user: action.payload
      });
    case "LOG_OUT" /* LOG_OUT */:
      return __spreadProps(__spreadValues({}, state), {
        loggedOut: action.payload
      });
    case "UPDATE_HIDDEN_FEATURE" /* UPDATE_HIDDEN_FEATURE */:
      return __spreadProps(__spreadValues({}, state), {
        showHiddenFeatures: action.payload
      });
    case "SHOW_MESSAGE" /* SHOW_MESSAGE */:
      return __spreadProps(__spreadValues({}, state), {
        message: [...state.message, action.payload]
      });
    case "UPDATE_INSPECTOR" /* UPDATE_INSPECTOR */:
      return __spreadProps(__spreadValues({}, state), {
        inspectorMode: action.payload
      });
    case "PAYMENT_FAILED" /* PAYMENT_FAILED */:
      return __spreadProps(__spreadValues({}, state), {
        paymentFailed: action.payload
      });
    case "HIDE_MESSAGE" /* HIDE_MESSAGE */:
      return __spreadProps(__spreadValues({}, state), {
        message: []
      });
    case "UPDATE_DRAWER" /* UPDATE_DRAWER */:
      return __spreadProps(__spreadValues({}, state), {
        drawerState: action.payload
      });
    case "UPDATE_ALERTS" /* UPDATE_ALERTS */:
      return __spreadProps(__spreadValues({}, state), {
        alerts: __spreadValues(__spreadValues({}, state.alerts), action.payload)
      });
    case "UPDATE_TEST_MODE" /* UPDATE_TEST_MODE */:
      return __spreadProps(__spreadValues({}, state), {
        isTestModeEnabled: action.payload
      });
    case "UPDATE_TRIAL_MODE" /* UPDATE_TRIAL_MODE */:
      return __spreadProps(__spreadValues({}, state), {
        trialMode: action.payload
      });
    case "UPDATE_PUBLIC_URL" /* UPDATE_PUBLIC_URL */:
      return __spreadProps(__spreadValues({}, state), {
        publicURL: action.payload
      });
    case "UPDATE_SHOW_SETTING_BAR" /* UPDATE_SHOW_SETTING_BAR */:
      return __spreadProps(__spreadValues({}, state), {
        showSettingBar: action.payload
      });
    default: {
      return state;
    }
  }
};
export const GlobalStateProvider = ({
  children,
  initialState: initialState2
}) => {
  const [state, dispatch] = useReducer(reducer, initialState2);
  return /* @__PURE__ */ jsx(GlobalContext.Provider, { value: [state, dispatch], children });
};
const initialState = {
  user: {
    userId: 0,
    emailAddress: "",
    firstName: "",
    lastName: "",
    language: "en",
    accountType: {
      ProfileOwner: true,
      Admin: false,
      OtherProfiles: false,
      Manager: false
    },
    contractType: "",
    accountStatus: AccountStatus.Created,
    company: { name: "", companyId: 0 },
    scopes: [],
    scopes2: [],
    reports: [],
    mfaType: null,
    currency: DEFAULT_CURRENCY,
    hasPaymentMethodOnFile: false,
    features: null,
    navigation: null,
    createdAt: /* @__PURE__ */ new Date()
  },
  loggedOut: true,
  showHiddenFeatures: false,
  message: [],
  inspectorMode: false,
  paymentFailed: false,
  drawerState: false,
  isTestModeEnabled: false,
  trialMode: { enabled: false, lapsed: false },
  publicURL: null,
  showSettingBar: false,
  alerts: {
    apps: void 0,
    tasks: void 0,
    documents: void 0,
    devices: void 0,
    absences: void 0,
    people: void 0,
    payments: void 0,
    contractorInvoices: void 0,
    salary: void 0,
    pension: void 0
  }
};
export const GlobalContext = createContext([initialState, () => {
}]);
export const useGlobalContext = () => useContext(GlobalContext);
