"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SCScheduledTimelineModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/components/scheduled/sc-scheduled-timeline-modal.component";
import {
  SurveyFrequencyEnum,
  SurveyFrequencyValues,
  SurveyStartOptionsEnum
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const SCScheduledTimelineView = ({ isEditable, surveyCycle, refreshCycle }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const [isOpen, setIsOpen] = useState(false);
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            title: "Timeline",
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.TextLine,
                value: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { pb: spacing.s2 }, children: "Timeline defines the end date of the survey cycle for all participants. All invites will expire on the last day of the cycle." })
              },
              ...((_a = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _a.startNow) && isEditable && surveyCycle.state !== CycleState.Ongoing ? [
                {
                  type: SectionItemType.Pair,
                  label: "Start options",
                  value: SurveyStartOptionsEnum.now
                }
              ] : [
                {
                  type: SectionItemType.Pair,
                  label: "Start date (GMT)",
                  value: ((_b = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _b.startDate) ? new LocalDate((_c = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _c.startDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }) : "NA"
                },
                {
                  type: SectionItemType.Pair,
                  label: "Start time (GMT)",
                  value: ((_d = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _d.startTime) && ((_e = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _e.startTimeMeridiem) ? `${(_f = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _f.startTime} ${(_g = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _g.startTimeMeridiem}` : "NA"
                }
              ],
              {
                type: SectionItemType.Pair,
                label: polyglot.t("CycleSettingTimelineSection.surveyOpenFor"),
                value: `${(_i = (_h = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _h.surveyOpenFor) != null ? _i : "7"} days`
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t("CycleSettingTimelineSection.surveyFrequency"),
                value: ((_j = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _j.surveyFrequency) === SurveyFrequencyValues.once ? SurveyFrequencyEnum.once : SurveyFrequencyEnum.recurring
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      SCScheduledTimelineModal,
      {
        surveyCycle,
        isOpen,
        setIsOpen,
        onClose: () => setIsOpen(false),
        refresh: () => __async(void 0, null, function* () {
          yield refreshCycle == null ? void 0 : refreshCycle();
        })
      }
    )
  ] });
};
