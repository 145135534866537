"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Badge, Box, Button, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { QUEUE_TABS } from "@/v2/feature/monitoring/monitoring.page";
import { UserImportAPI } from "@/v2/feature/user/features/user-import/user-import.api";
import { secondarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminLinkedinImportPage = () => {
  const [linkedInUrl, setLinkedInUrl] = useState();
  const [emailDomain, setEmailDomain] = useState();
  const [loading, setLoading] = useState(false);
  const [queueData, setQueueData] = useState(void 0);
  const [currentTab, setCurrentTab] = useState("Active");
  const [showMessage] = useMessage();
  const triggerLinkedInImport = () => __async(void 0, null, function* () {
    setLoading(true);
    try {
      if (linkedInUrl && emailDomain) {
        showMessage(
          "Successfully triggered user data fetch via LinkedIn - This may take a few minutes, depending on the size of the company",
          "success"
        );
        yield UserImportAPI.importViaLinkedIn(linkedInUrl, emailDomain);
        setLinkedInUrl("");
        setEmailDomain("");
      } else {
        showMessage("Unable to trigger user data fetch via LinkedIn without required values!", "error");
      }
    } catch (error) {
      showMessage(
        `Encountered an error while trying to fetch user data via LinkedIn: ${JSON.stringify(error)}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  const refreshQueueData = useCallback(() => {
    setLoading(true);
    UserImportAPI.getUserImportLinkedInQueues().then((data) => {
      setQueueData(data);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    refreshQueueData();
  }, [refreshQueueData]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => "jobId",
        id: "id",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.id) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Job Name",
        id: "name",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.name) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "linkedInUrl",
        id: "linkedInUrl",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.data.linkedinUrl) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "emailDomain",
        id: "emailDomain",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.data.emailDomain) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "result",
        id: "result",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 105,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.returnvalue && /* @__PURE__ */ jsx(
          Button,
          {
            sx: secondarySmallBtn,
            onClick: () => {
              const csvData = original.returnvalue;
              const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
              const url = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.setAttribute("href", url);
              link.setAttribute("download", `${original.data.emailDomain}-${(/* @__PURE__ */ new Date()).toISOString()}.csv`);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document == null ? void 0 : document.body.removeChild(link);
            },
            children: "Download CSV"
          }
        ) })
      }
    ],
    []
  );
  const currentTabData = useMemo(() => {
    switch (currentTab) {
      case "Active":
        return queueData == null ? void 0 : queueData.active;
      case "Completed":
        return queueData == null ? void 0 : queueData.completed;
      case "Failed":
        return queueData == null ? void 0 : queueData.failed;
      case "Waiting":
        return queueData == null ? void 0 : queueData.waiting;
      default:
        return [];
    }
  }, [currentTab, queueData]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Fetch user data via Linkedin" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { children: /* @__PURE__ */ jsxs(Stack, { sx: { width: "800px", gap: "20px" }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "LinkedIn URL of target company (e.g: https://www.linkedin.com/company/apple)",
          name: "linkedInUrl",
          defaultValue: "",
          value: linkedInUrl,
          onChange: (e) => {
            setLinkedInUrl(e.target.value);
          },
          clearText: () => setLinkedInUrl(""),
          disabled: loading
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Email domain of target company (e.g: apple.com)",
          name: "emailDomain",
          defaultValue: "",
          value: emailDomain,
          onChange: (e) => {
            setEmailDomain(e.target.value);
          },
          clearText: () => setEmailDomain(""),
          disabled: loading
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          id: "fetchLinkedinData",
          name: "Fetch user data",
          loading,
          onClick: () => triggerLinkedInImport(),
          disabled: !linkedInUrl || !emailDomain || loading,
          sizeVariant: "large",
          colorVariant: "primary"
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { borderBottom: 1, borderColor: "divider" }, children: /* @__PURE__ */ jsxs(
        Tabs,
        {
          value: currentTab,
          scrollButtons: "auto",
          variant: "scrollable",
          allowScrollButtonsMobile: true,
          onChange: (e, value) => setCurrentTab(value),
          children: [
            QUEUE_TABS.filter((tab) => tab.value !== "Delayed").map((tab) => /* @__PURE__ */ jsx(
              Tab,
              {
                disableRipple: true,
                label: /* @__PURE__ */ jsxs(
                  "span",
                  {
                    style: {
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      paddingRight: (
                        // @ts-ignore
                        queueData && queueData[tab.value.toLowerCase()].length > 0 ? 10 : 0
                      )
                    },
                    children: [
                      tab.value,
                      queueData && queueData[tab.value.toLowerCase()].length > 0 && /* @__PURE__ */ jsx(
                        Badge,
                        {
                          badgeContent: (
                            // @ts-ignore
                            queueData && queueData[tab.value.toLowerCase()].length > 0 ? (
                              // @ts-ignore
                              queueData[tab.value.toLowerCase()].length
                            ) : ""
                          ),
                          color: "primary",
                          sx: { ml: 2 }
                        }
                      )
                    ]
                  }
                ),
                value: tab.value
              },
              tab.value
            )),
            /* @__PURE__ */ jsx(IconButton, { color: "secondary", onClick: () => refreshQueueData(), children: /* @__PURE__ */ jsx(RefreshIcon, {}) })
          ]
        }
      ) }),
      /* @__PURE__ */ jsx(BasicTable, { rowData: currentTabData != null ? currentTabData : [], columnData: tableColumns, loading })
    ] }) })
  ] });
};
