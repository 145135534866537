"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  ZeltMdmDevicePolicyType,
  ZeltPolicyPayloadIdentifier
} from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const ZeltMdmRestrictionPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  selectedRestriction,
  isOpen,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [whiteListUrlsHelper, setWhiteListUrlsHelper] = useState(
    (_c = (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _a.filterWhitelist) == null ? void 0 : _b.toString()) != null ? _c : ""
  );
  const [blackListUrlsHelper, setBlackListUrlsHelper] = useState(
    (_f = (_e = (_d = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _d.filterBlacklist) == null ? void 0 : _e.toString()) != null ? _f : ""
  );
  const getICloudRestriction = () => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
    return {
      allowCloudBookmarks: ((_a2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _a2.allowCloudBookmarks) !== void 0 ? !((_b2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _b2.allowCloudBookmarks) : false,
      allowCloudMail: ((_c2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _c2.allowCloudMail) !== void 0 ? !((_d2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _d2.allowCloudMail) : false,
      allowCloudCalendar: ((_e2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _e2.allowCloudCalendar) !== void 0 ? !((_f2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _f2.allowCloudCalendar) : false,
      allowCloudReminders: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _g.allowCloudReminders) !== void 0 ? !((_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _h.allowCloudReminders) : false,
      allowCloudAddressBook: ((_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _i.allowCloudAddressBook) !== void 0 ? !((_j = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _j.allowCloudAddressBook) : false,
      allowCloudNotes: ((_k = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _k.allowCloudNotes) !== void 0 ? !((_l = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _l.allowCloudNotes) : false,
      allowCloudDocumentSync: ((_m = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _m.allowCloudDocumentSync) !== void 0 ? !((_n = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _n.allowCloudDocumentSync) : false,
      allowCloudKeychainSync: ((_o = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _o.allowCloudKeychainSync) !== void 0 ? !((_p = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _p.allowCloudKeychainSync) : false,
      allowCloudPhotoLibrary: ((_q = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _q.allowCloudPhotoLibrary) !== void 0 ? !((_r = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableICloudRestriction) == null ? void 0 : _r.allowCloudPhotoLibrary) : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const iCloudinitialValues = getICloudRestriction();
  function analyzeICloudPolicyBeforeSave(policy) {
    let allNulls = true;
    for (const key in policy) {
      if (key !== "PayloadIdentifier" && policy[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return void 0;
    }
    return {
      allowCloudBookmarks: !policy.allowCloudBookmarks,
      allowCloudMail: !policy.allowCloudMail,
      allowCloudCalendar: !policy.allowCloudCalendar,
      allowCloudReminders: !policy.allowCloudReminders,
      allowCloudAddressBook: !policy.allowCloudAddressBook,
      allowCloudNotes: !policy.allowCloudNotes,
      allowCloudDocumentSync: !policy.allowCloudDocumentSync,
      allowCloudKeychainSync: !policy.allowCloudKeychainSync,
      allowCloudPhotoLibrary: !policy.allowCloudPhotoLibrary,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ICLOUD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  }
  const formikICloud = useFormik({
    initialValues: iCloudinitialValues,
    onSubmit: (iCloudForm) => __async(void 0, null, function* () {
      const configurablePolicy = analyzeICloudPolicyBeforeSave(iCloudForm);
      updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableICloudRestriction: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  const getSecurityRestrictions = () => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g, _h, _i, _j, _k, _l;
    return {
      allowCamera: ((_a2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _a2.allowCamera) !== void 0 ? !((_b2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _b2.allowCamera) : false,
      allowSafari: ((_c2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _c2.allowSafari) !== void 0 ? !((_d2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _d2.allowSafari) : false,
      allowAirDrop: ((_e2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _e2.allowAirDrop) !== void 0 ? !((_f2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _f2.allowAirDrop) : false,
      allowDictation: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _g.allowDictation) !== void 0 ? !((_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _h.allowDictation) : false,
      allowScreenShot: ((_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _i.allowScreenShot) !== void 0 ? !((_j = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _j.allowScreenShot) : false,
      allowAssistant: ((_k = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _k.allowAssistant) !== void 0 ? !((_l = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableSecurity) == null ? void 0 : _l.allowAssistant) : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const securityValues = getSecurityRestrictions();
  function analyzeSecurityPolicyBeforeSave(policy) {
    let allNulls = true;
    for (const key in policy) {
      if (key !== "PayloadIdentifier" && policy[key] !== false) {
        allNulls = false;
        break;
      }
    }
    if (allNulls) {
      return void 0;
    }
    return {
      allowCamera: !policy.allowCamera,
      allowSafari: !policy.allowSafari,
      allowAirDrop: !policy.allowAirDrop,
      allowDictation: !policy.allowDictation,
      allowScreenShot: !policy.allowScreenShot,
      allowAssistant: !policy.allowAssistant,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.SECURITY_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  }
  const formikSecurity = useFormik({
    initialValues: securityValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzeSecurityPolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableSecurity: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  const getBrowsingRestrictions = () => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g, _h;
    return {
      restrictWeb: ((_a2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _a2.restrictWeb) !== void 0 ? (_b2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _b2.restrictWeb : true,
      useContentFilter: ((_c2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _c2.useContentFilter) !== void 0 ? (_d2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _d2.useContentFilter : true,
      filterBlacklist: ((_e2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _e2.filterBlacklist) !== void 0 ? (_f2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _f2.filterBlacklist : [],
      filterWhitelist: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _g.filterWhitelist) !== void 0 ? (_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableBrowsing) == null ? void 0 : _h.filterWhitelist : [],
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.BROWSING_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const browsingValues = getBrowsingRestrictions();
  function analyzeBrowsingPolicyBeforeSave() {
    if (whiteListUrlsHelper.length === 0 && blackListUrlsHelper.length === 0) {
      return void 0;
    }
    return __spreadProps(__spreadValues(__spreadValues({
      restrictWeb: true,
      useContentFilter: true
    }, blackListUrlsHelper.length > 0 ? { filterBlacklist: blackListUrlsHelper.split(",") } : {}), whiteListUrlsHelper.length > 0 ? { filterWhitelist: whiteListUrlsHelper.split(",") } : {}), {
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.BROWSING_PAYLOAD_IDENTIFIER.valueOf()
    });
  }
  const formikBrowsing = useFormik({
    initialValues: browsingValues,
    onSubmit: () => __async(void 0, null, function* () {
      const configurablePolicy = analyzeBrowsingPolicyBeforeSave();
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableBrowsing: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    formikICloud.validateForm();
    formikSecurity.validateForm();
    formikBrowsing.validateForm();
  }, []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    selectedRestriction === ZeltMdmDevicePolicyType.icloud && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "iCloud restrictions" }),
      /* @__PURE__ */ jsxs(FormikProvider, { value: formikICloud, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 3 }, children: [
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Safari bookmarks",
              name: "allowCloudBookmarks",
              checked: formikICloud.values.allowCloudBookmarks,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Mail",
              name: "allowCloudMail",
              checked: formikICloud.values.allowCloudMail,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Calendar",
              name: "allowCloudCalendar",
              checked: formikICloud.values.allowCloudCalendar,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Reminders",
              name: "allowCloudReminders",
              checked: formikICloud.values.allowCloudReminders,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Contacts",
              name: "allowCloudAddressBook",
              checked: formikICloud.values.allowCloudAddressBook,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Notes",
              name: "allowCloudNotes",
              checked: formikICloud.values.allowCloudNotes,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Drive",
              name: "allowCloudDocumentSync",
              checked: formikICloud.values.allowCloudDocumentSync,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "KeyChain sync",
              name: "allowCloudKeychainSync",
              checked: formikICloud.values.allowCloudKeychainSync,
              onChange: formikICloud.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Photos",
              name: "allowCloudPhotoLibrary",
              checked: formikICloud.values.allowCloudPhotoLibrary,
              onChange: formikICloud.handleChange
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: 3 }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => formikICloud.handleSubmit(),
            fullWidth: true,
            colorVariant: "primary",
            sizeVariant: "medium",
            children: "Save"
          }
        ) })
      ] })
    ] }),
    selectedRestriction === ZeltMdmDevicePolicyType.security && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Apps and features restrictions" }),
      /* @__PURE__ */ jsxs(FormikProvider, { value: formikSecurity, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 3 }, children: [
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Camera",
              name: "allowCamera",
              checked: formikSecurity.values.allowCamera,
              onChange: formikSecurity.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Safari",
              name: "allowSafari",
              checked: formikSecurity.values.allowSafari,
              onChange: formikSecurity.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "AirDrop",
              name: "allowAirDrop",
              checked: formikSecurity.values.allowAirDrop,
              onChange: formikSecurity.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Siri",
              name: "allowAssistant",
              checked: formikSecurity.values.allowAssistant,
              onChange: formikSecurity.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Dictation",
              name: "allowDictation",
              checked: formikSecurity.values.allowDictation,
              onChange: formikSecurity.handleChange
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: "Screen capture",
              name: "allowScreenShot",
              checked: formikSecurity.values.allowScreenShot,
              onChange: formikSecurity.handleChange
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: 3 }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => formikSecurity.handleSubmit(),
            fullWidth: true,
            colorVariant: "primary",
            sizeVariant: "medium",
            children: "Save"
          }
        ) })
      ] })
    ] }),
    selectedRestriction === ZeltMdmDevicePolicyType.browsing && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Safari browsing" }),
      /* @__PURE__ */ jsx(Box, { sx: { gap: 2, mt: 2 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Please be aware that your actions can have an impact on your access to web URLs." }) }),
      /* @__PURE__ */ jsxs(FormikProvider, { value: formikBrowsing, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 3 }, children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Blacklist web URLs",
              name: "blackListUrlsHelper",
              value: blackListUrlsHelper,
              onChange: (e) => {
                let value = e == null ? void 0 : e.target.value;
                setBlackListUrlsHelper(value);
              }
            }
          ),
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Whitelist web URLs",
              name: "whiteListUrlsHelper",
              value: whiteListUrlsHelper,
              onChange: (e) => {
                let value = e == null ? void 0 : e.target.value;
                setWhiteListUrlsHelper(value);
              }
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: 3 }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => formikBrowsing.handleSubmit(),
            fullWidth: true,
            colorVariant: "primary",
            sizeVariant: "medium",
            children: "Save"
          }
        ) })
      ] })
    ] })
  ] });
};
