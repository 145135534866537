"use strict";
export var ReadAPIAction = /* @__PURE__ */ ((ReadAPIAction2) => {
  ReadAPIAction2["ListUsers"] = "listUsers";
  ReadAPIAction2["ListSites"] = "listSites";
  ReadAPIAction2["ListDepartments"] = "listDepartments";
  ReadAPIAction2["ListJobPositions"] = "listJobPositions";
  ReadAPIAction2["ListPermissionGroupWithMembers"] = "listPermissionGroupWithMembers";
  ReadAPIAction2["UserBasic"] = "userBasic";
  ReadAPIAction2["UserPersonal"] = "userPersonal";
  ReadAPIAction2["UserEventsByUserId"] = "userEventsByUserId";
  ReadAPIAction2["UserEventCurrent"] = "userEventCurrent";
  ReadAPIAction2["UserContractByUserId"] = "userContractByUserId";
  ReadAPIAction2["UserContractCurrentByUserId"] = "userContractCurrentByUserId";
  ReadAPIAction2["UserCompensationByUserId"] = "userCompensationByUserId";
  ReadAPIAction2["UserCompensationCurrentByUserId"] = "userCompensationCurrentByUserId";
  ReadAPIAction2["UserRole"] = "userRole";
  ReadAPIAction2["UserRoleCurrent"] = "userRoleCurrent";
  ReadAPIAction2["DocumentTypes"] = "documentTypes";
  return ReadAPIAction2;
})(ReadAPIAction || {});
export var WriteAPIAction = /* @__PURE__ */ ((WriteAPIAction2) => {
  WriteAPIAction2["CreateUser"] = "createUser";
  WriteAPIAction2["UpdateBasic"] = "updateBasic";
  WriteAPIAction2["UpdatePersonal"] = "updatePersonal";
  WriteAPIAction2["UpdateUserEvent"] = "updateUserEvent";
  WriteAPIAction2["UpdateUserContract"] = "updateUserContract";
  WriteAPIAction2["DeleteUserContract"] = "deleteUserContract";
  WriteAPIAction2["CreateNewUserContract"] = "createNewUserContract";
  WriteAPIAction2["CreateUserCompensation"] = "createUserCompensation";
  WriteAPIAction2["UpdateUserCompensation"] = "updateUserCompensation";
  WriteAPIAction2["DeleteUserCompensation"] = "deleteUserCompensation";
  WriteAPIAction2["CreateNewUserRole"] = "createNewUserRole";
  WriteAPIAction2["UpdateUserRole"] = "updateUserRole";
  WriteAPIAction2["DeleteUserRole"] = "deleteUserRole";
  WriteAPIAction2["RehireUser"] = "rehireUser";
  WriteAPIAction2["CreateDocument"] = "createDocument";
  return WriteAPIAction2;
})(WriteAPIAction || {});
export const APIDescriptions = {
  read: {
    ["listUsers" /* ListUsers */]: "List all users for company",
    ["listSites" /* ListSites */]: "List all sites for company",
    ["listDepartments" /* ListDepartments */]: "List all departments for company",
    ["listJobPositions" /* ListJobPositions */]: "List all job positions",
    ["listPermissionGroupWithMembers" /* ListPermissionGroupWithMembers */]: "List all permission groups with members for the company",
    ["userBasic" /* UserBasic */]: "Get user basic details",
    ["userPersonal" /* UserPersonal */]: "Get user personal info",
    ["userEventsByUserId" /* UserEventsByUserId */]: "Get user events by user id",
    ["userEventCurrent" /* UserEventCurrent */]: "Get currently effective user event",
    ["userContractByUserId" /* UserContractByUserId */]: "Get user contracts by user id",
    ["userContractCurrentByUserId" /* UserContractCurrentByUserId */]: "Get current user contract by user id",
    ["userCompensationByUserId" /* UserCompensationByUserId */]: "Get user compensation details",
    ["userCompensationCurrentByUserId" /* UserCompensationCurrentByUserId */]: "Get currently effective compensation details",
    ["userRole" /* UserRole */]: "Get user role details",
    ["userRoleCurrent" /* UserRoleCurrent */]: "Get currently effective user role",
    ["documentTypes" /* DocumentTypes */]: "List all document types for company"
  },
  write: {
    ["createUser" /* CreateUser */]: "Create a new user",
    ["updateBasic" /* UpdateBasic */]: "Update user basic details",
    ["updatePersonal" /* UpdatePersonal */]: "Update user personal info",
    ["updateUserEvent" /* UpdateUserEvent */]: "Update a user event by id",
    ["updateUserContract" /* UpdateUserContract */]: "Update a user contract record",
    ["deleteUserContract" /* DeleteUserContract */]: "Delete a user contract record",
    ["createNewUserContract" /* CreateNewUserContract */]: "Create a new user contract record",
    ["createUserCompensation" /* CreateUserCompensation */]: "Create a new user compensation record",
    ["updateUserCompensation" /* UpdateUserCompensation */]: "Update user compensation record",
    ["deleteUserCompensation" /* DeleteUserCompensation */]: "Delete user compensation record",
    ["createNewUserRole" /* CreateNewUserRole */]: "Create a new user role record",
    ["updateUserRole" /* UpdateUserRole */]: "Update user role record",
    ["deleteUserRole" /* DeleteUserRole */]: "Delete user role record",
    ["rehireUser" /* RehireUser */]: "Rehire a user",
    ["createDocument" /* CreateDocument */]: "Create one or multiple documents for company"
  }
};
export const APIScopeMapping = {
  read: {
    ["listUsers" /* ListUsers */]: ["user:read:all"],
    ["userBasic" /* UserBasic */]: ["user.basicInfo:read"],
    ["userPersonal" /* UserPersonal */]: ["user.personalInfo:all", "user.personalInfo:read"],
    ["userEventsByUserId" /* UserEventsByUserId */]: ["user.lifecycle:read:all"],
    ["userEventCurrent" /* UserEventCurrent */]: ["user.lifecycle:read:all"],
    ["userContractByUserId" /* UserContractByUserId */]: ["user.contract:read:all"],
    ["userContractCurrentByUserId" /* UserContractCurrentByUserId */]: ["user.contract:read:all"],
    ["userCompensationByUserId" /* UserCompensationByUserId */]: ["user.compensation:read:all"],
    ["userCompensationCurrentByUserId" /* UserCompensationCurrentByUserId */]: ["user.compensation:read:all"],
    ["userRole" /* UserRole */]: ["user.role:read:all"],
    ["userRoleCurrent" /* UserRoleCurrent */]: ["user.role:read:all"],
    ["listSites" /* ListSites */]: ["company.settings:read"],
    ["listDepartments" /* ListDepartments */]: ["company.settings:read"],
    ["listJobPositions" /* ListJobPositions */]: ["company.settings:read"],
    ["listPermissionGroupWithMembers" /* ListPermissionGroupWithMembers */]: ["company.settings:read"],
    ["documentTypes" /* DocumentTypes */]: ["documents:all"]
  },
  write: {
    ["updateBasic" /* UpdateBasic */]: ["user.basicInfo:all"],
    ["updatePersonal" /* UpdatePersonal */]: ["user.personalInfo:all"],
    ["createUser" /* CreateUser */]: ["user.onboard:all"],
    ["updateUserEvent" /* UpdateUserEvent */]: ["user.lifecycle:all"],
    ["rehireUser" /* RehireUser */]: ["user.lifecycle:all"],
    ["updateUserContract" /* UpdateUserContract */]: ["user.contract:all"],
    ["deleteUserContract" /* DeleteUserContract */]: ["user.contract:all"],
    ["createNewUserContract" /* CreateNewUserContract */]: ["user.contract:all"],
    ["createUserCompensation" /* CreateUserCompensation */]: ["user.compensation:all"],
    ["updateUserCompensation" /* UpdateUserCompensation */]: ["user.compensation:all"],
    ["deleteUserCompensation" /* DeleteUserCompensation */]: ["user.compensation:all"],
    ["createNewUserRole" /* CreateNewUserRole */]: ["user.role:all"],
    ["updateUserRole" /* UpdateUserRole */]: ["user.role:all"],
    ["deleteUserRole" /* DeleteUserRole */]: ["user.role:all"],
    ["createDocument" /* CreateDocument */]: ["documents:all"]
  }
};
