"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { ReportCompanyRouter } from "@v2/feature/app-layout/features/v2/routers/report-company.router";
import { ReportTeamRouter } from "@v2/feature/app-layout/features/v2/routers/report-team.router";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { DASHBOARD_ROUTE, REPORT_COMPANY_ROUTE, REPORT_TEAM_ROUTE, REPORTS_ROUTE } from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
export const ReportRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const location = useLocation();
  const { isCompanyTab, isRootPath, isAdmin, isManager } = useMemo(() => {
    const isCompanyTab2 = location.pathname.startsWith(REPORT_COMPANY_ROUTE);
    const isRootPath2 = location.pathname === REPORTS_ROUTE;
    const isAdmin2 = canAccessScopes(state.user, ["reports:all"]);
    const isManager2 = canAccessScopes(state.user, ["reports:manager"]);
    return { isCompanyTab: isCompanyTab2, isRootPath: isRootPath2, isAdmin: isAdmin2, isManager: isManager2 };
  }, [location.pathname, state.user]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reports:all"],
        context: scopesContext,
        path: REPORT_COMPANY_ROUTE,
        component: ReportCompanyRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reports:manager"],
        context: scopesContext,
        path: REPORT_TEAM_ROUTE,
        component: ReportTeamRouter
      }
    ),
    /* @__PURE__ */ jsx(
      Redirect,
      {
        to: isAdmin && (isCompanyTab || isRootPath) ? REPORT_COMPANY_ROUTE : isManager ? REPORT_TEAM_ROUTE : DASHBOARD_ROUTE
      }
    )
  ] });
};
