"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { ApprovalRuleDrawer } from "@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component";
import { ApprovalRulesList } from "@v2/feature/approval-rule/approval-rule-settings/components/approval-rules-list.component";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const ApprovalRuleSettingsPage = () => {
  const {
    data: approvalRules,
    isLoading,
    mutate: refreshApprovalRules
  } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });
  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshApprovalRules) yield refreshApprovalRules();
  }), [refreshApprovalRules]);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, children: /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("ApprovalRuleModule.approvalRules"),
      contentWidth: "100%",
      headerWidth: "100%",
      buttons: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: () => {
              setIsDrawerOpen(true);
            },
            children: polyglot.t("ApprovalRuleModule.newRule")
          }
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(ApprovalRulesList, { approvalRules: approvalRules != null ? approvalRules : [], refresh })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(ApprovalRuleDrawer, { isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen, approvalRule: null, refresh })
      ]
    }
  ) });
};
