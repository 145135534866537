"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  SUPER_ADMIN_BILLING_DISCOUNTS_ROUTE,
  SUPER_ADMIN_BILLING_INVOICES_ROUTE,
  SUPER_ADMIN_BILLING_PLANS_ROUTE
} from "@/lib/routes";
import { SuperAdminBillingDiscountsPage } from "@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-discounts.page";
import { SuperAdminBillingInvoicesPage } from "@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-invoices.page";
import { SuperAdminBillingPlansPage } from "@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-plans.page";
const BILLING_PAGE_CONFIG = {
  header: {
    tabs: [
      { label: "Plans", value: SUPER_ADMIN_BILLING_PLANS_ROUTE },
      { label: "Discounts", value: SUPER_ADMIN_BILLING_DISCOUNTS_ROUTE },
      { label: "Invoices", value: SUPER_ADMIN_BILLING_INVOICES_ROUTE }
    ]
  }
};
export const SuperAdminBillingRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_PLANS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingPlansPage, { pageConfig: BILLING_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_DISCOUNTS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingDiscountsPage, { pageConfig: BILLING_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_INVOICES_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingInvoicesPage, { pageConfig: BILLING_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_BILLING_PLANS_ROUTE })
  ] });
};
