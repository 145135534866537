"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownArrow } from "@/images/fields/DownArrow.svg";
import { ReactComponent as UpArrow } from "@/images/fields/UpArrow.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowLeft } from "@/images/side-bar-icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "@/images/side-bar-icons/ArrowRight.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewQuestionAPI } from "@/v2/feature/growth/reviews/api-client/review-question.api";
import { iconSize } from "@/v2/styles/menu.styles";
export const useCycleQuestionOptions = (question, setQuestion, setIsQuestionModalOpen, setIsMoveToSectionOpen, cycle, refreshReviewStructure, setSelectedQuestionId, hasSections) => {
  const { id: cycleId } = cycle;
  const [showMessage] = useMessage();
  const editHandler = useCallback(() => {
    setQuestion(question);
    setIsQuestionModalOpen(true);
  }, [question, setQuestion, setIsQuestionModalOpen]);
  const deleteHandler = useCallback(() => __async(void 0, null, function* () {
    try {
      yield ReviewQuestionAPI.deleteReviewQuestion(question.id, cycleId);
      yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
      showMessage("Successfully deleted", "success");
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage, question.id, cycleId, refreshReviewStructure]);
  const currentOrder = cycle.order ? [...cycle.order] : [];
  const findQuestionIndex = () => currentOrder.findIndex((o) => o.type === "question" && o.id === question.id);
  const isQuestionFirst = () => findQuestionIndex() === 0;
  const isQuestionLast = () => findQuestionIndex() === currentOrder.length - 1;
  const moveSection = (direction) => __async(void 0, null, function* () {
    const sectionIndex = findQuestionIndex();
    if (direction === "up" && sectionIndex > 0) {
      [currentOrder[sectionIndex - 1], currentOrder[sectionIndex]] = [
        currentOrder[sectionIndex],
        currentOrder[sectionIndex - 1]
      ];
    } else if (direction === "down" && sectionIndex < currentOrder.length - 1) {
      [currentOrder[sectionIndex], currentOrder[sectionIndex + 1]] = [
        currentOrder[sectionIndex + 1],
        currentOrder[sectionIndex]
      ];
    }
    yield ReviewCycleAPI.updateOrderReviewCycle({ cycleId, order: currentOrder });
    yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
  });
  return [
    { icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)), handler: editHandler, label: "Edit", disabled: false },
    ...!(question == null ? void 0 : question.sectionId) ? [
      {
        icon: /* @__PURE__ */ jsx(UpArrow, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          try {
            if (!cycle || !cycle.order || isQuestionFirst()) return;
            yield moveSection("up");
            showMessage("Successfully moved the question up", "success");
          } catch (error) {
            showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
          }
        }),
        label: "Move up",
        disabled: isQuestionFirst()
      },
      {
        icon: /* @__PURE__ */ jsx(DownArrow, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          try {
            if (!cycle || !cycle.order || isQuestionLast()) return;
            yield moveSection("down");
            showMessage("Successfully moved the question down", "success");
          } catch (error) {
            showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
          }
        }),
        label: "Move down",
        disabled: isQuestionLast()
      },
      {
        icon: /* @__PURE__ */ jsx(ArrowRight, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          try {
            setIsMoveToSectionOpen(true);
            setSelectedQuestionId(question.id);
          } catch (error) {
            showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
          }
        }),
        label: "Move inside section",
        disabled: !hasSections
      }
    ] : [
      {
        icon: /* @__PURE__ */ jsx(ArrowLeft, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          try {
            if (!cycle || !question.sectionId) return;
            yield ReviewCycleAPI.moveQuestionOutsideSection({
              cycleId,
              sectionId: question.sectionId,
              questionId: question.id
            });
            yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
            showMessage("Successfully moved the outside the section", "success");
          } catch (error) {
            showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
          }
        }),
        label: "Move outside section",
        disabled: false
      }
    ],
    { icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)), handler: deleteHandler, label: "Delete", disabled: false }
  ];
};
