"use strict";
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as TasksIcon } from "@/images/onboarding-step-icons/TaskBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { EditTasks } from "@/v2/feature/onboarding/onboarding-items/tasks/edit-tasks.component";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { TaskScopes } from "@/v2/feature/task/task.scopes";
import { spacing } from "@/v2/styles/spacing.styles";
export const TasksOnboardingItem = ({
  template,
  setDrawerOpen,
  setDrawerView,
  onDelete,
  checkOnboardingItemScope
}) => {
  const { polyglot } = usePolyglot();
  const canAddTasks = checkOnboardingItemScope(TaskScopes.GET_CHECKLISTS);
  const [checklists, setChecklists] = useState();
  const refreshTaskTypes = useCallback(() => {
    if (!canAddTasks) return;
    TaskAPI.getChecklists().then(setChecklists);
  }, [canAddTasks]);
  useEffect(refreshTaskTypes, [refreshTaskTypes]);
  const chips = useMemo(() => {
    if (!checklists) return [];
    if (!template.template.tasks) return [];
    return template.template.tasks.checklistIds.map((id) => {
      var _a, _b;
      return (_b = (_a = checklists.find((c) => c.id === id)) == null ? void 0 : _a.name) != null ? _b : "";
    });
  }, [checklists, template.template.tasks]);
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: TasksIcon,
        title: polyglot.t("TasksOnboardingItem.title"),
        description: polyglot.t("TasksOnboardingItem.desc")
      },
      chips,
      addButtonIcon: template.template.tasks ? "edit" : "plus",
      onAddClick: canAddTasks && (() => {
        var _a;
        setDrawerView(
          /* @__PURE__ */ jsx(
            EditTasks,
            {
              checklistIds: (_a = template.template.tasks) == null ? void 0 : _a.checklistIds,
              onSave: (checklistIds) => {
                template.template.tasks = {
                  checklistIds: [...checklistIds]
                };
                setDrawerOpen(false);
              }
            }
          )
        );
        setDrawerOpen(true);
      }),
      onDeleteClick: template.template.tasks && onDelete,
      sx: {
        py: spacing.py20
      }
    }
  );
};
