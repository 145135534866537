"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer, useState } from "react";
import { Typography } from "@mui/material";
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  initialiseState,
  reduceDocumentFormModal,
  uploadDocumentIfNeeded
} from "@v2/feature/documents/documents.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DocumentEmptyOverview } from "@/v2/feature/documents/components/document-empty-overview.component";
import { DocumentFormModal } from "@/v2/feature/documents/components/document-form-modal.component";
import { PersonalDocuments } from "@/v2/feature/documents/document-me/components/personal-documents.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
const initialState = initialiseState([]);
export const UserDocumentsPage = ({ userId, userName }) => {
  const { polyglot } = usePolyglot();
  const [documentFormMode, setDocumentFormMode] = useState("add");
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(["documents:all"], getScopesContext(globalState.user));
  const [showMessage] = useMessage();
  useEffect(() => {
    setLoading(true);
    (() => __async(void 0, null, function* () {
      try {
        const [documents2, types] = yield Promise.all([
          DocumentAPI.listUserDocuments(userId),
          DocumentAPI.listDocumentTypes()
        ]);
        setDocuments(documents2);
        documentDispatch({ kind: "set_types", value: types });
      } catch (error) {
        showMessage(
          polyglot.t("UserDocumentsPage.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }))();
  }, [polyglot, userId, showMessage, documentDispatch]);
  function closeUploadModal() {
    setOpen(false);
  }
  const getDocuments = () => __async(void 0, null, function* () {
    try {
      const documents2 = yield DocumentAPI.listUserDocuments(userId);
      setDocuments(documents2);
    } catch (error) {
      showMessage(
        polyglot.t("UserDocumentsPage.errorMessages.retrieve", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const completeSave = () => __async(void 0, null, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    try {
      let dynamicFileUuid;
      if (documentState.documentLink && !documentState.fileUuid) {
        dynamicFileUuid = yield uploadDocumentIfNeeded(documentState.documentLink);
      }
      if (!documentState.fileUuid && !dynamicFileUuid) {
        showMessage(polyglot.t("UserDocumentsPage.errorMessages.select"), "error");
        return;
      }
      let documentObject = {
        name: documentState.name || "",
        type: (_b = (_a = documentState.type) == null ? void 0 : _a.value) != null ? _b : "",
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: (_c = documentState.fileUuid) != null ? _c : dynamicFileUuid,
        note: documentState.note,
        expirySettings: (_d = documentState == null ? void 0 : documentState.expirySettings) != null ? _d : { expiryDate: "", documentExpires: false }
      };
      if (documentState.id && documentState.name) {
        if (((_e = documentState.type) == null ? void 0 : _e.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_f = documentState.pinned) != null ? _f : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage(polyglot.t("UserDocumentsPage.successMessages.update"), "success");
      } else if (documentState.name) {
        if (((_g = documentState.type) == null ? void 0 : _g.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_h = documentState.pinned) != null ? _h : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.createDocument(documentObject);
        showMessage(polyglot.t("UserDocumentsPage.successMessages.save"), "success");
      }
      yield getDocuments();
    } catch (error) {
      showMessage(
        polyglot.t("UserDocumentsPage.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    !loading && documents.length < 1 ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        TopHeader,
        {
          title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("UserDocumentsPage.documents") })
        }
      ),
      /* @__PURE__ */ jsx(ContentWrapper, { children: /* @__PURE__ */ jsx(
        DocumentEmptyOverview,
        {
          onUploadClick: () => {
            documentDispatch({ kind: "add_document", owner: { label: userName, value: userId } });
            setDocumentFormMode("add");
            setFilterValue(
              getDocumentParentCategory(
                "me",
                "add",
                void 0,
                currentUserIsDocumentAdmin,
                documentState.types
              )
            );
            setOpen(true);
          },
          onRequestClick: () => {
            documentDispatch({ kind: "request_document", owner: { label: userName, value: userId }, reach: "me" });
            setDocumentFormMode("request");
            setFilterValue(
              getDocumentParentCategory(
                "me",
                "request",
                void 0,
                currentUserIsDocumentAdmin,
                documentState.types
              )
            );
            setOpen(true);
          },
          isAdmin: currentUserIsDocumentAdmin
        }
      ) })
    ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        TopHeader,
        {
          title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("UserDocumentsPage.documents") }),
          showAction: true,
          actions: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "primary",
              sizeVariant: "small",
              onClick: () => {
                documentDispatch({ kind: "add_document", owner: { label: userName, value: userId } });
                setDocumentFormMode("add");
                setFilterValue(
                  getDocumentParentCategory(
                    "me",
                    "add",
                    void 0,
                    currentUserIsDocumentAdmin,
                    documentState.types
                  )
                );
                setOpen(true);
              },
              children: polyglot.t("UserDocumentsPage.newDocument")
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        PersonalDocuments,
        {
          userId,
          userName,
          setDocumentFormMode,
          documentState,
          documentDispatch,
          setOpen,
          loading,
          documents,
          setFilterValue
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      DocumentFormModal,
      {
        mode: documentFormMode,
        state: documentState,
        currentUserIsAdmin: currentUserIsDocumentAdmin,
        dispatch: documentDispatch,
        newDocumentTypes: documentState.types,
        onClose: closeUploadModal,
        onSubmit: completeSave,
        setOpen,
        open,
        setLoading,
        getDocuments,
        setFilterValue,
        filterValue
      }
    )
  ] });
};
