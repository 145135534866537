"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_BENEFITS_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
export const DeletePensionDrawer = ({ isOpen, setIsOpen, provider }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(DeletePensionDrawerContent, { setIsOpen, provider })
  }
) });
export const DeletePensionDrawerContent = ({ setIsOpen, provider }) => {
  const { polyglot } = usePolyglot();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const deleteProvider = () => __async(void 0, null, function* () {
    if (!(provider == null ? void 0 : provider.id)) return;
    try {
      setIsDeleteLoading(true);
      yield PensionAPI.deletePension(provider.id);
      showMessage(polyglot.t("PensionModule.pensionDeleted"), "success");
      setIsOpen(false);
      history.push(SETTINGS_BENEFITS_ROUTE);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setIsDeleteLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: provider.displayName }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Are you sure you want to delete this pension?" }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.delete"),
          loading: isDeleteLoading,
          sizeVariant: "medium",
          colorVariant: "danger",
          fullWidth: true,
          onClick: deleteProvider
        }
      )
    ] })
  ] });
};
