"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { getMonthOptions } from "@v2/feature/absence/absence.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyCarryOverEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        carryOverEnabled: true,
        carryOverLimit: values.isLimited ? Number(values.carryOverLimit) : null,
        carryOverExpirationMonth: values.isExpiring ? values.carryOverExpirationMonth : null,
        carryOverExpirationDay: values.carryOverExpirationMonth ? Number(values.carryOverExpirationDay) : null
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyCarryOver(absencePolicy.id, policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, absencePolicy.id, refresh, setIsOpen, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      carryOverEnabled: true,
      isLimited: absencePolicy.carryOverLimit !== null,
      isExpiring: Boolean((absencePolicy == null ? void 0 : absencePolicy.carryOverExpirationDay) && (absencePolicy == null ? void 0 : absencePolicy.carryOverExpirationMonth)),
      carryOverLimit: absencePolicy.carryOverEnabled ? absencePolicy.carryOverLimit : null,
      carryOverExpirationMonth: absencePolicy.carryOverEnabled ? absencePolicy.carryOverExpirationMonth : null,
      carryOverExpirationDay: absencePolicy.carryOverEnabled ? absencePolicy.carryOverExpirationDay : null
    },
    validationSchema: yup.object({
      carryOverEnabled: yup.boolean().required(polyglot.t("PolicyCarryOverEditDrawer.selectValidField")),
      carryOverLimit: yup.number().when("isLimited", {
        is: true,
        then: (schema) => schema.typeError(polyglot.t("PolicyCarryOverEditDrawer.selectValidField")).min(0, polyglot.t("PolicyCarryOverEditDrawer.selectValidField")).required("ValidationMessages.requiredField"),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
      carryOverExpirationMonth: yup.number().when("isExpiring", {
        is: true,
        then: yup.number().min(1, polyglot.t("getLimitedAllowanceValidationSchema.valueSelect")).max(12, polyglot.t("getLimitedAllowanceValidationSchema.valueSelect")).integer(polyglot.t("getLimitedAllowanceValidationSchema.valueSelect")).typeError(polyglot.t("getLimitedAllowanceValidationSchema.valueSelect")).required(polyglot.t("getLimitedAllowanceValidationSchema.carryOverExpiration")),
        otherwise: yup.number().nullable().notRequired()
      }),
      carryOverExpirationDay: yup.number().nullable().when("isExpiring", {
        is: true,
        then: yup.number().min(1, polyglot.t("getLimitedAllowanceValidationSchema.value")).max(31, polyglot.t("getLimitedAllowanceValidationSchema.value")).integer(polyglot.t("getLimitedAllowanceValidationSchema.value")).typeError(polyglot.t("getLimitedAllowanceValidationSchema.value")).when("carryOverExpirationMonth", (carryOverExpirationMonth, schema) => {
          const daysInMonth = new Date((/* @__PURE__ */ new Date()).getFullYear(), carryOverExpirationMonth, 0).getDate();
          return schema.max(
            daysInMonth,
            polyglot.t("getLimitedAllowanceValidationSchema.monthsDays", { daysInMonth })
          );
        }).required(polyglot.t("getLimitedAllowanceValidationSchema.carryOverExpirationDay")),
        otherwise: yup.number().nullable().notRequired()
      })
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PolicyCarryOverEditDrawer.carryOver") }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PolicyCarryOverEditDrawer.carryOverDesc") }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => {
                formik.setFieldValue("isLimited", false);
                formik.setFieldValue("carryOverLimit", null);
              },
              colorVariant: !formik.values.isLimited ? "active" : "secondary",
              sizeVariant: "filter",
              children: polyglot.t("PolicyCarryOverEditDrawer.unlimited")
            }
          ),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => {
                formik.setFieldValue("isLimited", true);
                formik.setFieldValue("carryOverLimit", 8);
              },
              sizeVariant: "filter",
              colorVariant: formik.values.isLimited ? "active" : "secondary",
              children: polyglot.t("PolicyCarryOverEditDrawer.limited")
            }
          )
        ] }),
        formik.values.isLimited && /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "carryOverLimit",
            label: polyglot.t("PolicyCarryOverEditDrawer.carryOverLimit"),
            value: formik.values.carryOverLimit,
            onChange: formik.handleChange,
            error: formik.touched.carryOverLimit && !!formik.errors.carryOverLimit,
            helperText: (_a = formik.touched.carryOverLimit && formik.errors.carryOverLimit) != null ? _a : " ",
            endAdornment: "none"
          }
        ),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: polyglot.t("PolicyCarryOverEditDrawer.expiry"),
              name: "isExpiring",
              checked: formik.values.isExpiring,
              onChange: (e, checked) => {
                formik.handleChange(e);
                if (!checked) {
                  formik.setFieldValue("carryOverExpirationDay", null);
                  formik.setFieldValue("carryOverExpirationMonth", null);
                }
              }
            }
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { ml: spacing.m25, color: themeColors.Grey }, children: polyglot.t("PolicyCarryOverEditDrawer.expiryDesc") })
        ] }),
        formik.values.isExpiring && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "carryOverExpirationDay",
              label: polyglot.t("PolicyCarryOverEditDrawer.day"),
              value: formik.values.carryOverExpirationDay,
              onChange: formik.handleChange,
              error: formik.touched.carryOverExpirationDay && !!formik.errors.carryOverExpirationDay,
              helperText: (_b = formik.touched.carryOverExpirationDay && formik.errors.carryOverExpirationDay) != null ? _b : " ",
              endAdornment: "none"
            }
          ),
          /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "carryOverExpirationMonth",
              label: polyglot.t("PolicyCarryOverEditDrawer.month"),
              options: getMonthOptions(polyglot),
              value: formik.values.carryOverExpirationMonth,
              onChange: formik.handleChange,
              compareValue: formik.values.carryOverExpirationMonth,
              error: !!formik.errors.carryOverExpirationMonth && !!formik.touched.carryOverExpirationMonth,
              helperText: formik.touched.carryOverExpirationMonth && formik.errors.carryOverExpirationMonth
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true
          }
        ) })
      ] }) })
    }
  );
};
