"use strict";
import { jsx } from "react/jsx-runtime";
import { Suspense } from "react";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { EnableMFAPage } from "@/v2/feature/user/features/mfa/enable-mfa.page";
import { themeColors } from "@/v2/styles/colors.styles";
export const EnableMFADrawer = ({ isOpen, setIsOpen }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(EnableMFAPage, { isOpen, onClose: () => setIsOpen(false) })
  }
) });
