"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx } from "react/jsx-runtime";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { SnackBarMessage } from "@v2/components/snack-bar-message.component";
import { useContext, useEffect, useState } from "react";
const MESSAGE_TIMEOUT = 5e3;
const initialMessageState = { message: "", type: "success", show: false };
const Message = ({ messageObject }) => {
  const [showMessage, setShowMessage] = useState(initialMessageState);
  useEffect(() => {
    setShowMessage(messageObject);
  }, [messageObject]);
  return /* @__PURE__ */ jsx(
    SnackBarMessage,
    {
      showMessage,
      handleClose: (_, reason) => {
        if (reason !== "clickaway") {
          setShowMessage(__spreadProps(__spreadValues({}, showMessage), { show: false }));
        }
      }
    }
  );
};
export const MessageWrapper = ({ timeout = MESSAGE_TIMEOUT }) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { message } = state;
  useEffect(() => {
    if (Boolean(message.length)) {
      setTimeout(() => {
        dispatch({ type: GlobalStateActions.HIDE_MESSAGE });
      }, timeout);
    }
  }, [message.length, dispatch, timeout]);
  return message.length !== 0 ? /* @__PURE__ */ jsx(Fragment, { children: message.map((msg, idx) => /* @__PURE__ */ jsx(Message, { messageObject: { message: msg.message, show: true, type: msg.type } }, idx)) }) : /* @__PURE__ */ jsx(Fragment, {});
};
