"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ReportConfigBackButton } from "@v2/feature/reports/reports-advanced/components/report-config-back-button.component";
import { selectColumnUtil, unselectColumnUtil } from "@v2/feature/reports/reports-advanced/reports-advanced.util";
import {
  FilterTypeOptionToDefaultReportFormat,
  ReportDisplayFormat
} from "@v2/feature/reports/reports-formatting.util";
import {
  ReportColumnType
} from "@v2/feature/reports/reports.interface";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const ReportColumnsSelection = ({ selectedColumns, setSelectedColumns, reportColumns, goBack }) => {
  const [searchInput, setSearchInput] = useState("");
  const [selectedColumnsLocal, setSelectedColumnsLocal] = useState({});
  const filteredColumns = useMemo(() => {
    if (!searchInput) return reportColumns;
    const search = searchInput.toLowerCase();
    return reportColumns.filter((c) => {
      for (let key in c.columns) {
        if (c.columns[key].label.toLowerCase().includes(search)) return true;
      }
      return c.category.toLowerCase().includes(search);
    }).map((c) => {
      if (c.category.toLowerCase().includes(search)) return c;
      const filteredCols = {};
      for (const key in c.columns) {
        if (c.columns[key].label.toLowerCase().includes(search)) {
          filteredCols[key] = c.columns[key];
        }
      }
      return __spreadProps(__spreadValues({}, c), { columns: filteredCols });
    });
  }, [reportColumns, searchInput]);
  const addColumns = useCallback(
    (selectedColumnsLocal2) => {
      setSelectedColumns((prevSelectedColumns) => {
        const prevCopy = __spreadValues({}, prevSelectedColumns);
        for (const tStub in selectedColumnsLocal2) {
          if (!prevCopy[tStub]) prevCopy[tStub] = [];
          const reportColumnsForStub = reportColumns.find((rC) => rC.stub === tStub);
          prevCopy[tStub].push(
            ...selectedColumnsLocal2[tStub].map((c) => {
              const colSchema = (reportColumnsForStub == null ? void 0 : reportColumnsForStub.columns) ? reportColumnsForStub.columns[c.col] : null;
              const format = (colSchema == null ? void 0 : colSchema.type) && FilterTypeOptionToDefaultReportFormat[colSchema.type] ? FilterTypeOptionToDefaultReportFormat[colSchema.type] : ReportDisplayFormat.none;
              return __spreadProps(__spreadValues({}, c), {
                format
              });
            })
          );
        }
        return prevCopy;
      });
      goBack();
    },
    [goBack, setSelectedColumns, reportColumns]
  );
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        height: "100%",
        overflowY: "hidden"
      },
      children: [
        /* @__PURE__ */ jsx(ReportConfigBackButton, { title: "Columns", goBack }),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            },
            style: { minWidth: "150px", maxWidth: "240px" }
          }
        ),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
              scrollbarWidth: "thin",
              paddingRight: "4px"
            },
            children: filteredColumns.map((c) => /* @__PURE__ */ jsxs(Box, { children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title4", children: c.category }, c.stub),
              Object.keys(c.columns).map((key) => {
                const isSelected = Boolean(
                  selectedColumnsLocal[c.stub] && selectedColumnsLocal[c.stub].some((c2) => c2.col === key)
                );
                const previousSelectionNumber = selectedColumns[c.stub] ? selectedColumns[c.stub].filter((col) => col.col === key).length : 0;
                return /* @__PURE__ */ jsxs(
                  Box,
                  {
                    sx: { display: "flex", gap: spacing.s1, alignItems: "center" },
                    children: [
                      /* @__PURE__ */ jsx(
                        CheckboxComponent,
                        {
                          label: c.columns[key].label,
                          name: c.columns[key].label,
                          checked: isSelected,
                          value: "allSelected",
                          onChange: (_, checked) => {
                            if (!checked) {
                              setSelectedColumnsLocal((prev) => {
                                let prevCopy = __spreadValues({}, prev);
                                for (const type of Object.values(ReportColumnType)) {
                                  prevCopy = unselectColumnUtil(prevCopy, c.stub, key, type);
                                }
                                return prevCopy;
                              });
                            } else {
                              let type = ReportColumnType.PLAIN;
                              if (c.columns[key].operators && c.columns[key].operators.length > 0 && !c.columns[key].operators.includes(ReportColumnType.PLAIN))
                                type = c.columns[key].operators[0];
                              setSelectedColumnsLocal((prev) => {
                                return selectColumnUtil(prev, c.stub, key, type, c.columns[key].label, selectedColumns);
                              });
                            }
                          }
                        }
                      ),
                      previousSelectionNumber > 0 && /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Grey", children: [
                        "(",
                        previousSelectionNumber + (isSelected ? 1 : 0),
                        ")"
                      ] })
                    ]
                  },
                  `${key}.${c.columns[key].label}`
                );
              })
            ] }, c.stub))
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true,
            onClick: () => addColumns(selectedColumnsLocal),
            children: "Add"
          }
        ) })
      ]
    }
  );
};
