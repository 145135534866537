"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const SiteListingTable = ({
  sites,
  loading,
  setIsFormOpen,
  setSelectedSite,
  setIsViewOpen
}) => {
  const { polyglot } = usePolyglot();
  const tableColumns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("SiteListingTable.name"),
        accessorFn: (row) => row,
        id: "displayName",
        maxSize: 120,
        minSize: 120,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => {
          return original && /* @__PURE__ */ jsx("div", { children: polyglot.t(original.name) });
        }
      },
      {
        header: () => polyglot.t("SiteListingTable.address"),
        accessorFn: (row) => row,
        id: "address",
        maxSize: 200,
        minSize: 150,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && /* @__PURE__ */ jsx("div", { children: original.address });
        }
      },
      {
        header: () => polyglot.t("SiteListingTable.employees"),
        accessorFn: (row) => row,
        id: "employees",
        maxSize: 350,
        minSize: 100,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.users ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { width: "20px" }, children: original.users.length }),
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", ml: spacing.ml10 }, children: original.users.map((user) => /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, tooltip: true, size: "xxsmall", sx: { ml: spacing.m4 } }, user.userId)) })
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", width: "100%", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: polyglot.t("SiteListingTable.edit"),
            onClick: (event) => {
              setIsFormOpen(true);
              setSelectedSite(original);
              event.stopPropagation();
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ];
  }, [polyglot, setIsFormOpen, setSelectedSite]);
  const handleRowClick = useCallback(
    (row) => {
      setSelectedSite(row.original);
      setIsViewOpen(true);
    },
    [setIsViewOpen, setSelectedSite]
  );
  return /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: sites,
      columnData: tableColumns,
      loading,
      initialSort: [{ id: "displayName", desc: true }],
      rowClick: handleRowClick
    }
  );
};
