"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as DownloadIcon } from "@/images/fields/Download.svg";
import { ReactComponent as Eye } from "@/images/new-theme-icon/Eye.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Document.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PublicImageViewer = ({
  fileName,
  uploadName,
  hasView,
  hasDownload
}) => {
  const [state] = useContext(GlobalContext);
  const [showPreview, setShowPreview] = useState(false);
  const publicUrl = useMemo(() => {
    if (!state.publicURL || !fileName) return void 0;
    return `${state.publicURL}/${fileName}`;
  }, [state.publicURL, fileName]);
  const handlePreviewClick = () => {
    setShowPreview(true);
  };
  const handleClose = () => {
    setShowPreview(false);
  };
  const handleDownloadClick = () => __async(void 0, null, function* () {
    if (!publicUrl) return;
    try {
      const response = yield fetch(publicUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch file");
      }
      const blob = yield response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download failed:", error);
    }
  });
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: uploadName })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
      hasView && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: handlePreviewClick, children: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)) }),
      hasDownload && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => handleDownloadClick(), children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize)) })
    ] }),
    /* @__PURE__ */ jsx(PublicImageView, { publicUrl, showPreview, handleClose })
  ] }) });
};
export const PublicImageView = ({
  publicUrl,
  showPreview,
  handleClose
}) => {
  return /* @__PURE__ */ jsx(
    Modal,
    {
      open: showPreview,
      onClose: handleClose,
      closeAfterTransition: true,
      BackdropComponent: Backdrop,
      BackdropProps: {
        timeout: 500
      },
      children: /* @__PURE__ */ jsx(Fade, { in: showPreview, children: /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "900px",
            height: "auto",
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: { xs: spacing.s1, sm: spacing.s2 },
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: spacing.s2
          },
          children: publicUrl ? /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                position: "relative",
                width: "100%",
                paddingTop: "56.25%",
                overflow: "hidden"
              },
              children: /* @__PURE__ */ jsx(
                "iframe",
                {
                  src: publicUrl,
                  title: "Preview",
                  style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none"
                  }
                }
              )
            }
          ) : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "darkRed", children: "Failed to load the document." })
        }
      ) })
    }
  );
};
