"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { TabFilterButtons } from "@v2/components/tab-filter-buttons.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { InsuranceAddToPolicyDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/components/insurance-add-to-policy-drawer.component";
import { InsuranceAddDependantsDrawer } from "@v2/feature/benefits/subfeature/insurance/components/insurance-add-dependants-drawer.component";
import { InsuranceEditUserPolicyDrawer } from "@v2/feature/benefits/subfeature/insurance/components/insurance-edit-user-policy-drawer.component";
import { InsuranceRemoveFromPolicyDrawer } from "@v2/feature/benefits/subfeature/insurance/components/insurance-remove-from-policy-drawer.component";
import { InsuranceEndpoints } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { UserInsurancePolicyStatus } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as ArrowRight } from "@/images/side-bar-icons/Next.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as People } from "@/images/side-bar-icons/People.svg";
import { ReactComponent as Reject } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected-grey.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { nestErrorMessage } from "@/lib/errors";
import { INSURANCE_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
const getTabFilter = (polyglot) => [
  { name: polyglot.t("General.all"), value: "All" },
  { name: polyglot.t("BenefitModule.covered"), value: "Covered" },
  { name: polyglot.t("General.pending"), value: "Pending" },
  { name: polyglot.t("BenefitModule.notCovered"), value: "NotCovered" },
  { name: polyglot.t("BenefitModule.optedOut"), value: "OptOut" }
];
export const InsuranceCompanyEmployeeListPage = ({
  insurancePolicy,
  loading
}) => {
  const {
    data: userInsuranceRecords,
    mutate: refreshUserRecords,
    isLoading
  } = useApiClient(InsuranceEndpoints.getAllUsersInsurancePolicyById(insurancePolicy.id), { suspense: false });
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers({ refresh: true });
  const [selectedUserInsurance, setSelectedUserInsurance] = useState(null);
  const [isAddDependantsDrawerOpen, setIsAddDependantsDrawerOpen] = useState(false);
  const [isAddToPolicyDrawerOpen, setIsAddToPolicyDrawerOpen] = useState(false);
  const [isRemoveDrawerOpen, setIsRemoveDrawerOpen] = useState(false);
  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const filteredUserRecords = useMemo(() => {
    let filteredUserRecords2 = nonTerminatedCachedUsers.map((user) => {
      const userInsuranceRecord = userInsuranceRecords == null ? void 0 : userInsuranceRecords.find((record) => record.userId === user.userId);
      return userInsuranceRecord != null ? userInsuranceRecord : {
        userId: user.userId,
        policyId: insurancePolicy.id,
        status: null,
        startDate: null,
        endDate: null,
        monthlyPremium: null,
        monthlyContribution: null,
        dependants: null,
        dependantsMonthlyPremium: null,
        dependantsMonthlyContribution: null
      };
    }).sort((a, b) => {
      let aScore = 0, bScore = 0;
      if (a.status === UserInsurancePolicyStatus.Active && a.monthlyPremium === null) aScore = 5;
      if (a.status === UserInsurancePolicyStatus.Active && a.monthlyPremium !== null) aScore = 4;
      if (a.status === null) aScore = 3;
      if (a.status === UserInsurancePolicyStatus.OptOut) aScore = 2;
      if (b.status === UserInsurancePolicyStatus.Active && b.monthlyPremium === null) bScore = 5;
      if (b.status === UserInsurancePolicyStatus.Active && b.monthlyPremium !== null) bScore = 4;
      if (b.status === null) bScore = 3;
      if (b.status === UserInsurancePolicyStatus.OptOut) bScore = 2;
      return bScore - aScore;
    });
    if (filterValue !== "All") {
      filteredUserRecords2 = filteredUserRecords2.filter((userInsurance) => {
        if (filterValue === "Covered") return (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.Active;
        if (filterValue === "Pending")
          return (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.Pending || (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.PendingOptOut;
        if (filterValue === "OptOut") return (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.OptOut;
        if (filterValue === "NotCovered")
          return !(userInsurance == null ? void 0 : userInsurance.status) || ![UserInsurancePolicyStatus.Active, UserInsurancePolicyStatus.Pending].includes(userInsurance.status);
        return true;
      });
    }
    if (searchInput) {
      filteredUserRecords2 = filteredUserRecords2.filter((userInsurance) => {
        var _a;
        return (_a = getCachedUserById(userInsurance.userId)) == null ? void 0 : _a.displayName.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    return filteredUserRecords2;
  }, [filterValue, getCachedUserById, insurancePolicy.id, nonTerminatedCachedUsers, searchInput, userInsuranceRecords]);
  const getUserRecords = useCallback(() => __async(void 0, null, function* () {
    try {
      if (refreshUserRecords) yield refreshUserRecords();
    } catch (error) {
      showMessage(
        polyglot.t("BenefitModule.ErrorMessages.couldNotRetrieveUserRecords", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    }
  }), [polyglot, refreshUserRecords, showMessage]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => polyglot.t("General.name"),
        accessorFn: (row) => row,
        id: "userId",
        enableSorting: false,
        cell: (info) => {
          const userInsurance = info.getValue();
          return /* @__PURE__ */ jsx(UserCell, { userId: userInsurance.userId });
        },
        maxSize: 250,
        minSize: 150
      },
      {
        header: () => polyglot.t("BenefitModule.dependants"),
        accessorFn: (row) => row,
        id: "dependants",
        enableSorting: false,
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.dependants ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: userInsurance.dependants }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 250,
        minSize: 150
      },
      {
        header: () => polyglot.t("BenefitModule.employeePremium"),
        accessorFn: (row) => row,
        id: "employeePremium",
        enableSorting: false,
        cell: (info) => {
          var _a, _b;
          const userInsurance = info.getValue();
          let totalEmployeePremium = (_a = userInsurance.monthlyPremium) != null ? _a : 0;
          if (userInsurance.dependants && userInsurance.dependantsMonthlyPremium)
            totalEmployeePremium += (_b = userInsurance.dependantsMonthlyPremium) != null ? _b : 0;
          return userInsurance.monthlyPremium ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: formatMoney({ amount: totalEmployeePremium }) }) : userInsurance.status === UserInsurancePolicyStatus.Active ? /* @__PURE__ */ jsx(
            StyledTooltip,
            {
              title: /* @__PURE__ */ jsxs("div", { children: [
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.tooltip), { padding: 0, margin: 0 }), children: polyglot.t("BenefitModule.missingEmployeePremium") }),
                /* @__PURE__ */ jsxs(
                  ButtonComponent,
                  {
                    sizeVariant: "small",
                    colorVariant: "tooltip",
                    onClick: () => {
                      setSelectedUserInsurance(userInsurance);
                      setIsUpdateDrawerOpen(true);
                    },
                    children: [
                      polyglot.t("BenefitModule.completeInfoHere"),
                      " ",
                      /* @__PURE__ */ jsx(ArrowRight, { width: 12, height: 12, style: { fill: themeColors.Grey } })
                    ]
                  }
                )
              ] }),
              children: /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize))
            }
          ) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 125,
        minSize: 125
      },
      {
        header: () => polyglot.t("BenefitModule.employeeContribution"),
        accessorFn: (row) => row,
        id: "employeeContribution",
        enableSorting: false,
        cell: (info) => {
          var _a;
          const userInsurance = info.getValue();
          let totalEmployeeContributions = (_a = userInsurance.monthlyContribution) != null ? _a : 0;
          if (userInsurance.dependants && userInsurance.dependantsMonthlyContribution)
            totalEmployeeContributions += userInsurance.dependantsMonthlyContribution;
          return totalEmployeeContributions ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: formatMoney({ amount: totalEmployeeContributions }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 125,
        minSize: 125
      },
      {
        header: () => polyglot.t("General.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: (info) => {
          const userInsurance = info.getValue();
          return (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.Active ? /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                gap: "5px",
                display: "flex",
                alignItems: "center"
              }),
              children: [
                /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
                " ",
                polyglot.t("BenefitModule.covered")
              ]
            }
          ) : (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.OptOut ? /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.Grey,
                gap: "5px",
                display: "flex",
                alignItems: "center"
              }),
              children: [
                /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
                " ",
                polyglot.t("BenefitModule.optedOut")
              ]
            }
          ) : (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.Pending ? /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.Grey,
                gap: "5px",
                display: "flex",
                alignItems: "center"
              }),
              children: [
                /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
                " ",
                polyglot.t("General.pending")
              ]
            }
          ) : (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.PendingOptOut ? /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.Grey,
                gap: "5px",
                display: "flex",
                alignItems: "center"
              }),
              children: [
                /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
                " ",
                polyglot.t("BenefitModule.pendingOptOut")
              ]
            }
          ) : /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.Grey,
                gap: "5px",
                display: "flex",
                alignItems: "center"
              }),
              children: [
                /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
                " ",
                polyglot.t("BenefitModule.notCovered")
              ]
            }
          );
        },
        maxSize: 120,
        minSize: 120
      },
      {
        header: () => polyglot.t("General.startDate"),
        accessorFn: (row) => row,
        id: "startDate",
        enableSorting: false,
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.startDate ? /* @__PURE__ */ jsx(Box, { children: new Date(userInsurance.startDate).toLocaleDateString(void 0, {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 85,
        minSize: 85
      },
      {
        header: () => polyglot.t("General.endDate"),
        accessorFn: (row) => row,
        id: "endDate",
        enableSorting: false,
        cell: (info) => {
          const userInsurance = info.getValue();
          return userInsurance.endDate ? /* @__PURE__ */ jsx(Box, { children: new Date(userInsurance.endDate).toLocaleDateString(void 0, {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 85,
        minSize: 85
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        enableSorting: false,
        cell: (info) => {
          const userInsurance = info.getValue();
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                gap: spacing.gap5
              },
              children: userInsurance.status === UserInsurancePolicyStatus.Active ? /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: [
                    {
                      handler: () => {
                        setSelectedUserInsurance(userInsurance);
                        setIsAddDependantsDrawerOpen(true);
                      },
                      label: polyglot.t("BenefitModule.addDependants"),
                      icon: /* @__PURE__ */ jsx(People, __spreadValues({}, iconSize)),
                      hidden: !userInsurance.monthlyPremium
                    },
                    {
                      handler: () => {
                        setSelectedUserInsurance(userInsurance);
                        setIsUpdateDrawerOpen(true);
                      },
                      label: polyglot.t("BenefitModule.editPremium"),
                      icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
                    },
                    {
                      handler: () => __async(void 0, null, function* () {
                        setSelectedUserInsurance(userInsurance);
                        setIsRemoveDrawerOpen(true);
                      }),
                      label: polyglot.t("BenefitModule.removeFromPolicy"),
                      icon: /* @__PURE__ */ jsx(Reject, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red }))
                    }
                  ],
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              ) : null
            }
          );
        },
        maxSize: 50,
        minSize: 50
      }
    ];
  }, [polyglot]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: polyglot.t("General.employees"), showBack: true, backPath: INSURANCE_COMPANY_OVERVIEW_ROUTE }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading || isLoading, sx: {}, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            filters: getTabFilter(polyglot),
            setFilterValue,
            filterValue,
            hasSearch: true,
            onFilterChange: ({ filterValue: filterValue2, searchInput: searchInput2 }) => {
              setFilterValue(filterValue2);
              setSearchInput(searchInput2);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => {
              setIsAddToPolicyDrawerOpen(true);
            },
            children: polyglot.t("BenefitModule.addEmployees")
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m15 }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredUserRecords,
          columnData,
          loading,
          hidePagination: true
        }
      ) }),
      isAddDependantsDrawerOpen && selectedUserInsurance && /* @__PURE__ */ jsx(
        InsuranceAddDependantsDrawer,
        {
          isOpen: isAddDependantsDrawerOpen,
          setIsOpen: setIsAddDependantsDrawerOpen,
          insurancePolicy,
          userInsurance: selectedUserInsurance,
          refresh: getUserRecords,
          onClose: () => {
            setSelectedUserInsurance(null);
            setIsAddDependantsDrawerOpen(false);
          }
        }
      ),
      isUpdateDrawerOpen && selectedUserInsurance && /* @__PURE__ */ jsx(
        InsuranceEditUserPolicyDrawer,
        {
          isOpen: isUpdateDrawerOpen,
          setIsOpen: setIsUpdateDrawerOpen,
          insurancePolicy,
          userInsurance: selectedUserInsurance,
          refresh: getUserRecords,
          onClose: () => {
            setSelectedUserInsurance(null);
            setIsUpdateDrawerOpen(false);
          }
        }
      ),
      isAddToPolicyDrawerOpen && /* @__PURE__ */ jsx(
        InsuranceAddToPolicyDrawer,
        {
          isOpen: isAddToPolicyDrawerOpen,
          setIsOpen: setIsAddToPolicyDrawerOpen,
          insurancePolicy,
          refresh: getUserRecords,
          onClose: () => {
            setSelectedUserInsurance(null);
            setIsAddToPolicyDrawerOpen(false);
          }
        }
      ),
      selectedUserInsurance && /* @__PURE__ */ jsx(
        InsuranceRemoveFromPolicyDrawer,
        {
          isOpen: isRemoveDrawerOpen,
          setIsOpen: setIsRemoveDrawerOpen,
          userInsurance: selectedUserInsurance,
          refresh: getUserRecords,
          onClose: () => {
            setSelectedUserInsurance(null);
            setIsRemoveDrawerOpen(false);
          }
        }
      )
    ] })
  ] });
};
