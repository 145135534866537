"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box, Button, Card, CardContent, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { EntityImportDatagridValidation } from "@/v2/feature/entity-import/components/entity-import-datagrid-validation.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserImportAPI } from "@/v2/feature/user/features/user-import/user-import.api";
import { getRowIdsWithErrors, getTotalErrorCount, hasErrors } from "@/v2/util/data-import.util";
export function UserImportGoogleWorkspacePage({
  goNext,
  goBack
}) {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [uploadResponse, setUploadResponse] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { refreshCachedUsers } = useCachedUsers();
  const validationTableColumns = [
    {
      headerName: polyglot.t("UserProperties.firstName"),
      field: "firstName",
      width: 200
    },
    {
      headerName: polyglot.t("UserProperties.lastName"),
      field: "lastName",
      width: 200
    },
    {
      headerName: polyglot.t("UserProperties.workEmail"),
      field: "workEmail",
      width: 300
    }
  ];
  const tableColumns = [
    {
      headerName: polyglot.t("UserProperties.firstName"),
      field: "name.givenName",
      sortable: true,
      width: 200,
      valueGetter: (param) => param.row.name.givenName
    },
    {
      headerName: polyglot.t("UserProperties.lastName"),
      field: "name.familyName",
      sortable: true,
      width: 200,
      valueGetter: (param) => param.row.name.familyName
    },
    {
      headerName: polyglot.t("UserProperties.workEmail"),
      field: "primaryEmail",
      sortable: true,
      width: 300
    }
  ];
  const loadUsers = () => __async(this, null, function* () {
    var _a2;
    setLoading(true);
    try {
      const accountData = yield AuthAPI.getAccountData();
      setUsers(accountData.users);
      setLoading(false);
    } catch (error) {
      if (((_a2 = error.response) == null ? void 0 : _a2.status) === 401) {
        window.location.href = "/apiv2/auth/initiate?source=google";
        return;
      }
      setLoading(false);
    }
  });
  useEffect(() => {
    loadUsers();
  }, []);
  const handleSubmit = () => __async(this, null, function* () {
    const nullDefaults = { dateOfBirth: null, startDate: null, visaExpirationDate: null };
    const importedUsers = users.filter((user) => selectedUsers.includes(user.primaryEmail)).map((user) => __spreadValues({
      workEmail: user.primaryEmail,
      firstName: user.name.givenName,
      lastName: user.name.familyName
    }, nullDefaults));
    setIsSubmitting(true);
    try {
      const result = yield UserImportAPI.bulkCreate(importedUsers);
      setUploadResponse(result);
      yield refreshCachedUsers();
      if (!hasErrors(result.errors)) goNext(result);
    } finally {
      setIsSubmitting(false);
    }
  });
  const tryAgain = () => {
    setUploadResponse(void 0);
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: { width: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" },
      children: [
        /* @__PURE__ */ jsxs(Box, { textAlign: "center", paddingY: 3, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: polyglot.t("UserImportGoogleWorkspacePage.selectEmployees") }),
          /* @__PURE__ */ jsx(Typography, { variant: "body2", children: polyglot.t("UserImportGoogleWorkspacePage.addInformation") })
        ] }),
        uploadResponse && uploadResponse.errors.length > 0 && /* @__PURE__ */ jsxs(
          Alert,
          {
            severity: hasErrors(uploadResponse.errors) ? "error" : "success",
            sx: { marginBottom: 3, width: "100%", marginX: "auto" },
            action: hasErrors(uploadResponse.errors) && /* @__PURE__ */ jsx(
              Button,
              {
                color: "inherit",
                size: "small",
                variant: "outlined",
                onClick: () => tryAgain(),
                sx: { marginY: "auto" },
                children: "Try again"
              }
            ),
            children: [
              /* @__PURE__ */ jsxs(AlertTitle, { children: [
                hasErrors(uploadResponse.errors) ? polyglot.t("UserImportCsvPage.checkFormatting") : polyglot.t("UserImportCsvPage.importCompleted"),
                " "
              ] }),
              polyglot.t("UserImportCsvPage.rowsImportedSuccessfully", {
                count: ((_a = uploadResponse == null ? void 0 : uploadResponse.errors) == null ? void 0 : _a.length) - getTotalErrorCount(uploadResponse.errors)
              }),
              hasErrors(uploadResponse.errors) && polyglot.t("UserImportCsvPage.rowsImportedSuccessfully", {
                count: getTotalErrorCount(uploadResponse.errors)
              })
            ]
          }
        ),
        /* @__PURE__ */ jsx(Card, { dir: "ltr", sx: { minWidth: "100%" }, children: /* @__PURE__ */ jsx(
          CardContent,
          {
            sx: {
              height: 400
            },
            children: uploadResponse && ((_b = uploadResponse == null ? void 0 : uploadResponse.errors) == null ? void 0 : _b.length) > 0 ? /* @__PURE__ */ jsx(
              EntityImportDatagridValidation,
              {
                rows: uploadResponse.errors,
                columns: validationTableColumns,
                rowIdsToExport: getRowIdsWithErrors(uploadResponse.errors)
              }
            ) : /* @__PURE__ */ jsx(
              DataGrid,
              {
                columns: tableColumns,
                rows: users,
                loading,
                getRowId: (param) => {
                  return param.primaryEmail;
                },
                hideFooter: true,
                checkboxSelection: true,
                onSelectionModelChange: (newSelectionModel) => {
                  setSelectedUsers(newSelectionModel);
                },
                selectionModel: selectedUsers
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsxs(Box, { sx: { my: 2, display: "flex", width: "100%", justifyContent: "center" }, children: [
          /* @__PURE__ */ jsx(Button, { onClick: goBack, sx: { mr: 1 }, children: polyglot.t("General.back") }),
          /* @__PURE__ */ jsx(
            LoadingButton,
            {
              variant: "contained",
              onClick: () => handleSubmit(),
              loading: isSubmitting,
              disabled: (selectedUsers == null ? void 0 : selectedUsers.length) === 0,
              children: polyglot.t("General.continue")
            }
          )
        ] })
      ]
    }
  );
}
