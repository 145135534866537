"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { ManageInsurancePolicyDrawer } from "@v2/feature/super-admin/features/super-admin-insurance/components/manage-insurance-policy-drawer.component";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE } from "@/lib/routes";
export const SuperAdminInsurancePoliciesPage = ({
  companies,
  policies,
  refreshPolicies,
  loading,
  pageConfig
}) => {
  var _a, _b;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const history = useHistory();
  const [showMessage] = useMessage();
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Company name",
        accessorFn: (row) => row,
        id: "companyId",
        cell: (info) => {
          const policy = info.getValue();
          const company = companies.find((company2) => policy.companyId === company2.companyId);
          return company ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: company.name }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Provider name",
        accessorFn: (row) => row,
        id: "providerName",
        cell: (info) => {
          const policy = info.getValue();
          return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: policy.providerName });
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Policy type",
        accessorFn: (row) => row,
        id: "type",
        cell: (info) => {
          const policy = info.getValue();
          return policy.type ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: policy.type }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Start date",
        accessorFn: (row) => row,
        id: "startDate",
        cell: (info) => {
          const policy = info.getValue();
          return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: new Date(policy.startDate).toLocaleDateString("en-GB") });
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "End date",
        accessorFn: (row) => row,
        id: "endDate",
        cell: (info) => {
          const policy = info.getValue();
          return /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: new Date(policy.endDate).toLocaleDateString("en-GB") });
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        cell: (info) => {
          const policy = info.getValue();
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: { display: "flex", justifyContent: "flex-end" },
              onClick: (e) => {
                e.stopPropagation();
              },
              children: /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: [
                    {
                      icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
                      handler: () => {
                        setSelectedPolicy(policy);
                        setIsDrawerOpen(true);
                      },
                      label: "Edit details"
                    },
                    {
                      icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                      handler: () => __async(void 0, null, function* () {
                        try {
                          yield InsuranceAPI.deleteInsurancePolicyByIdAsSuperAdmin(policy.id);
                          yield refreshPolicies();
                        } catch (error) {
                          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                        }
                      }),
                      label: "Delete"
                    }
                  ],
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              )
            }
          );
        },
        maxSize: 50,
        minSize: 50
      }
    ];
  }, [companies, refreshPolicies, showMessage]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Health Insurance" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, secondLevel: true, children: [
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: [...policies],
          columnData,
          rowClick: (row) => history.push(generatePath(SUPER_ADMIN_INSURANCE_POLICY_DETAILS_ROUTE, { policyId: row.original.id }))
        }
      ) }),
      selectedPolicy && isDrawerOpen && /* @__PURE__ */ jsx(
        ManageInsurancePolicyDrawer,
        {
          policy: selectedPolicy,
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          refresh: refreshPolicies,
          onSave: () => setSelectedPolicy(null)
        }
      )
    ] })
  ] });
};
