"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
export const TextFieldCell = ({ name, value, update, id, type, error }) => {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  return /* @__PURE__ */ jsx(
    TextfieldComponent,
    {
      name,
      value: inputValue,
      fullWidth: true,
      type,
      placeholder: "Type here",
      onChange: (e) => {
        const castedValue = type === "number" ? e.target.value ? Number(e.target.value) : null : e.target.value;
        setInputValue(castedValue);
      },
      onBlur: () => update(id, name, inputValue),
      error,
      endAdornment: "none",
      fieldBackgroundColor: "white",
      fieldBackgroundHoverColor: "white",
      valueFont: "caption",
      fieldPadding: "0.5px",
      borderRadius: "0px"
    },
    name
  );
};
export const SelectFieldCell = ({
  name,
  value,
  options,
  update,
  id,
  error,
  maxLabelDisplayCharacters
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  return /* @__PURE__ */ jsx(
    SelectComponent,
    {
      name,
      placeholder: "Select",
      value: selectedValue != null ? selectedValue : "",
      fullWidth: true,
      options,
      onChange: (e) => {
        const newValue = e.target.value;
        setSelectedValue(newValue);
      },
      onBlur: () => update(id, name, selectedValue),
      error,
      fieldBackgroundColor: "white",
      fieldBackgroundHoverColor: "white",
      valueFont: "caption",
      fieldPadding: "0.5px",
      maxLabelDisplayCharacters: maxLabelDisplayCharacters != null ? maxLabelDisplayCharacters : 12,
      borderRadius: "0px"
    },
    name
  );
};
