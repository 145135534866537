"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { CurrencySelect } from "@v2/components/currency-select.component";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CompanyAPI } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CountrySelect } from "@/v2/components/country-select.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
const validationSchema = (polyglot) => Yup.object().shape({
  legalName: Yup.string().trim().required(polyglot.t("CompanyEntityDrawerPage.errorMessages.enterLegalName")),
  address: Yup.object().shape({
    addressLine1: Yup.string().trim().required(polyglot.t("CompanyEntityDrawerPage.errorMessages.enterRegisteredAddress")),
    addressLine2: Yup.string().notRequired(),
    city: Yup.string().trim().required(polyglot.t("CompanyEntityDrawerPage.errorMessages.enterRegisteredAddress")),
    postCode: Yup.string().trim().required(polyglot.t("CompanyEntityDrawerPage.errorMessages.enterRegisteredAddress")),
    countryCode: Yup.string().required(polyglot.t("CompanyEntityDrawerPage.errorMessages.selectCountry"))
  }),
  nationalId: Yup.string().trim().nullable().optional(),
  taxId: Yup.string().trim().nullable().optional(),
  isDefaultBillingEntity: Yup.boolean().notRequired(),
  currency: Yup.string().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
});
export const CompanyEntityDrawerPage = ({ entity, close }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const { polyglot } = usePolyglot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: __spreadProps(__spreadValues({
      legalName: "",
      nationalId: null,
      taxId: null
    }, entity), {
      address: __spreadValues({
        addressLine1: "",
        addressLine2: "",
        city: "",
        postCode: "",
        countryCode: ""
      }, entity == null ? void 0 : entity.address),
      isDefaultBillingEntity: !!(entity == null ? void 0 : entity.isDefaultBillingEntity),
      currency: (_a = entity == null ? void 0 : entity.currency) != null ? _a : "GBP"
    }),
    validationSchema: validationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setIsSubmitting(true);
        if (entity) {
          yield CompanyAPI.updateEntity(__spreadValues({ id: entity.id }, values));
        } else {
          yield CompanyAPI.createEntity(values);
        }
        close(true);
      } catch (error) {
        showMessage(
          `Failed to ${entity ? polyglot.t("CompanyEntityDrawerPage.errorMessages.update") : polyglot.t("CompanyEntityDrawerPage.errorMessages.save")} entity: ${nestErrorMessage(error)}`,
          "error"
        );
        setIsSubmitting(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: entity ? polyglot.t("CompanyEntityDrawerPage.edit") : polyglot.t("CompanyEntityDrawerPage.add") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "legalName",
        label: polyglot.t("CompanyEntityDrawerPage.legalName"),
        value: formik.values.legalName,
        type: "text",
        disabled: isSubmitting,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.legalName,
        helperText: hasSubmitted && formik.errors.legalName,
        clearText: () => formik.setFieldValue("legalName", ""),
        autoFocus: true
      }
    ),
    /* @__PURE__ */ jsx(
      CountrySelect,
      {
        name: "country",
        onChange: (_country, isoCode) => formik.setFieldValue("address.countryCode", isoCode),
        value: formik.values.address.countryCode,
        valueType: "iso-code",
        disabled: isSubmitting,
        helperText: hasSubmitted && ((_b = formik.errors.address) == null ? void 0 : _b.countryCode),
        error: hasSubmitted && !!((_c = formik.errors.address) == null ? void 0 : _c.countryCode)
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "address.addressLine1",
        label: polyglot.t("CompanyEntityDrawerPage.line1"),
        value: formik.values.address.addressLine1,
        type: "text",
        disabled: isSubmitting,
        onChange: formik.handleChange,
        error: hasSubmitted && !!((_d = formik.errors.address) == null ? void 0 : _d.addressLine1),
        helperText: hasSubmitted && ((_e = formik.errors.address) == null ? void 0 : _e.addressLine1),
        clearText: () => formik.setFieldValue("address.addressLine1", "")
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "address.addressLine2",
        label: polyglot.t("CompanyEntityDrawerPage.line2"),
        value: formik.values.address.addressLine2,
        type: "text",
        disabled: isSubmitting,
        onChange: formik.handleChange,
        error: hasSubmitted && !!((_f = formik.errors.address) == null ? void 0 : _f.addressLine2),
        helperText: hasSubmitted && ((_g = formik.errors.address) == null ? void 0 : _g.addressLine2),
        clearText: () => formik.setFieldValue("address.addressLine2", "")
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "address.city",
        label: polyglot.t("CompanyEntityDrawerPage.city"),
        value: formik.values.address.city,
        type: "text",
        disabled: isSubmitting,
        onChange: formik.handleChange,
        error: hasSubmitted && !!((_h = formik.errors.address) == null ? void 0 : _h.city),
        helperText: hasSubmitted && ((_i = formik.errors.address) == null ? void 0 : _i.city),
        clearText: () => formik.setFieldValue("address.city", "")
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "address.postCode",
        label: polyglot.t("CompanyEntityDrawerPage.postcode"),
        value: formik.values.address.postCode,
        type: "text",
        disabled: isSubmitting,
        onChange: formik.handleChange,
        error: hasSubmitted && !!((_j = formik.errors.address) == null ? void 0 : _j.postCode),
        helperText: hasSubmitted && ((_k = formik.errors.address) == null ? void 0 : _k.postCode),
        clearText: () => formik.setFieldValue("address.postCode", "")
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "nationalId",
        label: polyglot.t("CompanyEntityDrawerPage.companyid"),
        value: formik.values.nationalId,
        type: "text",
        disabled: isSubmitting,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.nationalId,
        helperText: hasSubmitted && formik.errors.nationalId,
        clearText: () => formik.setFieldValue("nationalId", "")
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "taxId",
        label: polyglot.t("CompanyEntityDrawerPage.vat"),
        value: formik.values.taxId,
        type: "text",
        disabled: isSubmitting,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.taxId,
        helperText: hasSubmitted && formik.errors.taxId,
        clearText: () => formik.setFieldValue("taxId", "")
      }
    ),
    /* @__PURE__ */ jsx(
      CurrencySelect,
      {
        name: "currency",
        onChange: (currency) => {
          formik.setFieldValue("currency", currency);
        },
        value: formik.values.currency,
        helperText: hasSubmitted && formik.errors.currency,
        error: hasSubmitted && !!formik.errors.currency
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: polyglot.t("CompanyEntityDrawerPage.isDefaultBillingEntity"),
        name: "isDefaultBillingEntity",
        checked: formik.values.isDefaultBillingEntity,
        onChange: formik.handleChange
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          fullWidth: true,
          colorVariant: "secondary",
          onClick: () => close(false),
          disabled: isSubmitting,
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          fullWidth: true,
          loading: isSubmitting,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
