"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import {
  APP_GROUP_MANAGEMENT_DRAWER_MODES
} from "@/v2/feature/app-integration/app-integration.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
const today = /* @__PURE__ */ new Date();
export const AppGroupManagementMemberAddList = ({
  mode,
  userListForProcessing,
  userOptionsForAdd,
  groupOptionList,
  formikSetFieldValue
}) => {
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const usersList = nonTerminatedCachedUsers.filter((user) => userOptionsForAdd.some((u) => u.value === user.userId)).map((u) => {
    return __spreadValues({ label: u.displayName, value: u.userId }, u);
  });
  return /* @__PURE__ */ jsx(Fragment, { children: mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.add && userListForProcessing && userListForProcessing.length > 0 && userListForProcessing.map((eachUser, index) => {
    var _a;
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        SingleUserSelect,
        {
          name: "userListForProcessing[index].selectedUser",
          options: usersList,
          onChange: (_, x) => {
            const listForUpdating = [...userListForProcessing];
            if (!x)
              listForUpdating[index] = __spreadProps(__spreadValues({}, userListForProcessing[index]), {
                userId: void 0,
                selectedUser: void 0
              });
            else {
              listForUpdating[index] = __spreadProps(__spreadValues({}, userListForProcessing[index]), {
                userId: +x.value,
                selectedUser: x
              });
            }
            formikSetFieldValue("userListForProcessing", listForUpdating, true);
          },
          value: eachUser.userId,
          label: "Employee",
          error: void 0,
          helperText: void 0
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: userListForProcessing[index].dateOfActivation,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              const listForUpdating = [...userListForProcessing];
              listForUpdating[index] = __spreadProps(__spreadValues({}, userListForProcessing[index]), {
                dateOfActivation: value
              });
              formikSetFieldValue("userListForProcessing", listForUpdating, true);
            }
          },
          name: "actionDate",
          minDate: today,
          label: "Activation date"
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        MultipleSelectCheckbox,
        {
          id: "group-select",
          limitTags: 5,
          options: groupOptionList,
          value: (_a = userListForProcessing[index].groupList) != null ? _a : [],
          onChange: (_, values) => {
            const listForUpdating = [...userListForProcessing];
            listForUpdating[index] = __spreadProps(__spreadValues({}, userListForProcessing[index]), {
              groupList: values
            });
            formikSetFieldValue("userListForProcessing", listForUpdating, true);
          },
          isOptionEqualToValue: (x, y) => x.value === y.value,
          getOptionLabel: ({ label }) => label,
          label: "Groups (optional)"
        }
      ) })
    ] });
  }) });
};
