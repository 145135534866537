"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import PigeonMap from "@/component/widgets/PigeonMap";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const LocationCard = ({ device, assignedUser }) => {
  var _a, _b, _c, _d, _e;
  const getMarker = (device2) => {
    const lastLocation = device2 == null ? void 0 : device2.lastLocation;
    return lastLocation ? {
      latitude: Number(lastLocation.latitude),
      longitude: Number(lastLocation.longitude),
      name: lastLocation.name,
      time: lastLocation.time,
      owner: assignedUser == null ? void 0 : assignedUser.displayName
    } : null;
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    ((_a = device == null ? void 0 : device.lastLocation) == null ? void 0 : _a.latitude) && (device == null ? void 0 : device.lastLocation.longitude) && !((_b = device == null ? void 0 : device.lastLocation) == null ? void 0 : _b.locationTrackingDisabled) && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", height: "100%" }, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", borderRadius: "15px", overflow: "hidden" }, children: /* @__PURE__ */ jsx(PigeonMap, { markers: [getMarker(device)] }) }) }),
    (!((_c = device == null ? void 0 : device.lastLocation) == null ? void 0 : _c.latitude) || !(device == null ? void 0 : device.lastLocation.longitude)) && /* @__PURE__ */ jsxs(Fragment, { children: [
      ((_d = device == null ? void 0 : device.lastLocation) == null ? void 0 : _d.locationTrackingDisabled) && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "User disabled location tracking." }),
      !((_e = device == null ? void 0 : device.lastLocation) == null ? void 0 : _e.locationTrackingDisabled) && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "Location not available." })
    ] })
  ] });
};
