"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, InputAdornment } from "@mui/material";
import { Form, FormikProvider } from "formik";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { useSCTimelineForm } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/hook/use-sc-timeline-form.hook";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCRollingTimelineModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SCRollingTimelineContent, { surveyCycle, onClose, refresh }) });
};
const SCRollingTimelineContent = ({
  surveyCycle,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const formik = useSCTimelineForm(surveyCycle, onClose, refresh);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Timeline" }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "timelineSettings.surveyOpenFor",
        label: polyglot.t("SurveyParticipantDrawer.surveyOpenFor"),
        value: formik.values.timelineSettings.surveyOpenFor,
        onChange: formik.handleChange,
        error: ((_a = formik.touched.timelineSettings) == null ? void 0 : _a.surveyOpenFor) && !!((_b = formik.errors.timelineSettings) == null ? void 0 : _b.surveyOpenFor),
        helperText: (_e = ((_c = formik.touched.timelineSettings) == null ? void 0 : _c.surveyOpenFor) && ((_d = formik.errors.timelineSettings) == null ? void 0 : _d.surveyOpenFor)) != null ? _e : " ",
        endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "days" }) }),
        type: "number"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
