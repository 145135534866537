"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { isFriday, isMonday, isSameDay, isSaturday, isSunday, isThursday, isTuesday, isWednesday } from "date-fns";
import dayjs from "dayjs";
import {
  AfternoonOnly,
  CalendarActionType,
  FullDay,
  MorningOnly
} from "@/v2/feature/calendar/calendar.interface";
import { EventKindEnum } from "@/v2/feature/calendar/features/components/calendar-special-events.component";
import { DARK_CONTRAST_COLOR, themeColors } from "@/v2/styles/colors.styles";
import { LocalDate } from "@/v2/util/local-date";
export const getStartFullOrHalfDayType = (polyglot, event) => {
  if (event == null ? void 0 : event.endDate) {
    if (event.afternoonOnly) return AfternoonOnly(polyglot).value;
    return FullDay(polyglot).value;
  }
  if (event == null ? void 0 : event.morningOnly) return MorningOnly(polyglot).value;
  if (event == null ? void 0 : event.afternoonOnly) return AfternoonOnly(polyglot).value;
  return FullDay(polyglot).value;
};
export const getEndFullOrHalfDayType = (polyglot, event) => {
  if (!(event == null ? void 0 : event.endDate)) return FullDay(polyglot).value;
  if (event.morningOnly) return MorningOnly(polyglot).value;
  return FullDay(polyglot).value;
};
export const getTextColor = (event) => {
  if (event == null ? void 0 : event.color) {
    return DARK_CONTRAST_COLOR.includes(event == null ? void 0 : event.color) ? "white" : "DarkGrey";
  }
  return "DarkGrey";
};
export const getWeekWidth = (d, day) => {
  if (d == null ? void 0 : d.endDate) {
    if (d.morningOnly || d.afternoonOnly) {
      if (isSameDay(new Date(day), new Date(d.startDate)) && d.afternoonOnly) return "50%";
      else if (isSameDay(new Date(day), new Date(d.endDate)) && d.morningOnly) return "50%";
      else return "100%";
    } else return "100%";
  } else {
    return d.morningOnly || d.afternoonOnly ? "50%" : "100%";
  }
};
export const getWeekAlignment = (d, day) => {
  if (d == null ? void 0 : d.endDate) {
    if (d.morningOnly || d.afternoonOnly) {
      if (isSameDay(new Date(day), new Date(d.startDate)) && d.afternoonOnly) return "flex-end";
      else if (isSameDay(new Date(day), new Date(d.endDate)) && d.morningOnly) return "flex-start";
      else return "center";
    } else return "center";
  } else {
    if (d.morningOnly || d.afternoonOnly) return d.morningOnly ? "flex-start" : "flex-end";
    return "center";
  }
};
export const getBoxBackgroundColor = (advEntry, entry, attendanceSettings) => {
  const date = new LocalDate(entry.day);
  if (advEntry && advEntry.events && advEntry.events.schedule) {
    return {
      backgroundColor: advEntry.events.schedule.isScheduleToday ? themeColors.white : themeColors.Background,
      opacity: advEntry.events.schedule.isScheduleToday ? 1 : 0.5
    };
  }
  if (attendanceSettings) {
    const dayOfWeekCheckers = {
      workingMonday: isMonday,
      workingTuesday: isTuesday,
      workingWednesday: isWednesday,
      workingThursday: isThursday,
      workingFriday: isFriday,
      workingSaturday: isSaturday,
      workingSunday: isSunday
    };
    for (const [key, checkDay] of Object.entries(dayOfWeekCheckers)) {
      if (checkDay(date.getDate()) && attendanceSettings[key]) {
        return { backgroundColor: themeColors.white, opacity: 1 };
      }
    }
    return { backgroundColor: themeColors.Background, opacity: 0.5 };
  }
  return { backgroundColor: themeColors.white, opacity: 1 };
};
export function calendarReducer(state, action) {
  switch (action.type) {
    case CalendarActionType.SET_ACTIVE_VIEW:
      return __spreadProps(__spreadValues({}, state), { activeView: action.payload });
    case CalendarActionType.SET_RANGE:
      return __spreadProps(__spreadValues({}, state), { range: action.payload });
    case CalendarActionType.SET_TIMELINE:
      return __spreadProps(__spreadValues({}, state), { timelineDays: action.payload });
    case CalendarActionType.SET_SELECTED_DATE:
      return __spreadProps(__spreadValues({}, state), { selectedDay: action.payload });
    default:
      return state;
  }
}
export function getRangeByDays(days) {
  return {
    start: new LocalDate(days[0]).toDateString(),
    end: new LocalDate(days[days.length - 1]).toDateString()
  };
}
export const getMonthDays = (date) => {
  const startOfMonth = dayjs(date).startOf("month").toDate();
  const endOfMonth = dayjs(date).endOf("month").toDate();
  let days = [];
  for (let day = startOfMonth; day <= endOfMonth; day = dayjs(day).add(1, "day").toDate()) {
    days.push(day);
  }
  return days;
};
export const getStartDateByKind = (entry, specialKind) => {
  if (!specialKind) return entry.day;
  if (specialKind === EventKindEnum.birthday && entry.events.dob) return entry.events.dob;
  if (specialKind === EventKindEnum.workAnniversary && entry.events.workAnniversary)
    return entry.events.workAnniversary;
  if (specialKind === EventKindEnum.firstDay && entry.events.firstDay) return entry.events.firstDay;
  if (specialKind === EventKindEnum.lastDay && entry.events.lastDay) return entry.events.lastDay;
  else return entry.day;
};
