"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortDate, sortNumeric } from "@v2/components/table/table-sorting.util";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { PayrollLocalApi } from "@v2/feature/payroll/payroll-local.api";
import { PayrollAPI } from "@v2/feature/payroll/payroll.api";
import { PayrunStates } from "@v2/feature/payroll/payroll.interface";
import { HelperPayrunDetailsPage } from "@v2/feature/super-admin/features/helper-dashboard/helper-payrun-details.page";
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { secondarySmallBtn } from "@v2/styles/buttons.styles";
import { neutralColors, themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, Route, Switch, useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const HelperCompanyPayrunsRouter = ({
  company
}) => {
  var _a;
  const routerHistory = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [allPayruns, setAllPayruns] = useState([]);
  const [showMessage] = useMessage();
  const params = useParams();
  const companyId = Number(params.companyId);
  const [companyPayrolls, setCompanyPayrolls] = useState(null);
  const [payroll, setPayroll] = useState(null);
  const filteredPayruns = useMemo(() => {
    return allPayruns.filter((payrun) => {
      return payrun.state.toLowerCase().includes(searchInput.toLowerCase()) || String(payrun.period).includes(searchInput) || new Date(payrun.startDate).toLocaleDateString().includes(searchInput) || new Date(payrun.endDate).toLocaleDateString().includes(searchInput);
    });
  }, [searchInput, allPayruns]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      if (!companyId) return;
      try {
        setLoading(true);
        const companyPayrolls2 = yield PayrollAPI.getCompanyPayrollsAsSuperAdmin(companyId);
        setCompanyPayrolls(companyPayrolls2);
        if (companyPayrolls2.items && companyPayrolls2.items[0]) setPayroll(companyPayrolls2.items[0]);
      } catch (error) {
        showMessage(`Error loading payruns: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [showMessage, companyId]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (payroll) {
          const payruns = yield PayrollLocalApi.getPayRunsAsSuperAdmin(companyId, payroll.id);
          setAllPayruns(payruns);
        }
      } catch (error) {
        showMessage(`Error loading payruns: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [showMessage, companyId, payroll]);
  const getColorByPayrunState = (state) => {
    switch (state) {
      case PayrunStates.draft:
        return neutralColors.n7;
      case PayrunStates.inProgress:
        return "#ada61a";
      case PayrunStates.partiallyCompleted:
        return "#23ad51";
      case PayrunStates.completed:
        return "#23ad51";
      default:
        return "#F00";
    }
  };
  const columns = useMemo(
    () => [
      {
        header: () => "Tax year",
        id: "taxYear",
        maxSize: 100,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.taxYear })
      },
      {
        header: () => "Pay period",
        id: "payPeriod",
        maxSize: 100,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.payPeriod })
      },
      {
        header: () => "Period",
        id: "period",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.period),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.period })
      },
      {
        header: () => "State",
        id: "state",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: getColorByPayrunState(original.state) }), children: original.state })
      },
      {
        header: () => "Start date",
        id: "startDate",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.startDate),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: new Date(original.startDate).toLocaleDateString() })
      },
      {
        header: () => "End date",
        id: "endDate",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.endDate),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: new Date(original.endDate).toLocaleDateString() })
      },
      {
        header: () => "Payment date",
        id: "paymentDate",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.paymentDate),
        cell: ({ row: { original } }) => original.paymentDate ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: new Date(original.paymentDate).toLocaleDateString() }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Employees",
        id: "employeeCount",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.employeeCount),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.employeeCount })
      },
      {
        header: () => "Gross",
        id: "gross",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.gross),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: formatMoney({ amount: original.gross }) })
      },
      {
        header: () => "Total cost",
        id: "totalCost",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalCost),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: formatMoney({ amount: original.totalCost }) })
      }
    ],
    []
  );
  const handleRowClick = useCallback(
    ({ original }) => {
      routerHistory.push(
        generatePath(SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE, { companyId, payrunId: original.id })
      );
    },
    [companyId, routerHistory]
  );
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_PAYRUN_DETAILS_ROUTE, children: /* @__PURE__ */ jsx(HelperPayrunDetailsPage, { company }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_PAYRUNS_ROUTE, children: /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        TopHeader,
        {
          title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
            "Helper page - Payruns | ",
            (company == null ? void 0 : company.name) ? company.name : "",
            " [ ",
            companyId,
            " ]"
          ] }),
          showBack: true,
          backPath: generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })
        }
      ),
      /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: {}, children: [
        /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, spacing.px40), { display: "flex", gap: spacing.g20, mb: spacing.m20 }), children: companyPayrolls && ((_a = companyPayrolls.items) == null ? void 0 : _a.map((i) => {
          var _a2;
          return /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 2,
                width: "300px",
                bgcolor: (payroll == null ? void 0 : payroll.id) === i.id ? "#aaa" : "#ddd",
                p: spacing.p10
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: i.id }),
                ((_a2 = i.entity) == null ? void 0 : _a2.legalName) && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: i.entity.legalName }),
                /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Button, { onClick: () => setPayroll(i), disabled: (payroll == null ? void 0 : payroll.id) === i.id, sx: secondarySmallBtn, children: "Select" }) })
              ]
            }
          );
        })) }),
        /* @__PURE__ */ jsx(Box, { sx: spacing.px40, children: /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: [...filteredPayruns],
            columnData: columns,
            loading,
            rowClick: handleRowClick,
            hidePagination: true
          }
        ) })
      ] })
    ] }) })
  ] });
};
