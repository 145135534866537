"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { UserDetailsScopes } from "@/component/dashboard/userDetails/user-details.scopes";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import {
  ADMIN_USER_DOCUMENTS_NEW_ROUTE,
  ADMIN_USER_DOCUMENTS_ROUTE,
  ADMIN_USER_REVIEWS_ROUTE,
  PEOPLE_ME_DIRECTORY_ROUTE,
  USER_ABSENCE_ROUTE,
  USER_APPS_TAB,
  USER_ATTENDANCE_ROUTE,
  USER_BENEFITS_ROUTE,
  USER_COMPENSATION_TAB,
  USER_CONTACT_TAB,
  USER_DETAILS_PROFILE_ROUTE,
  USER_DETAILS_ROUTE,
  USER_DEVICES_PAGE,
  USER_EXPENSES_NEW_EXPENSE_ROUTE,
  USER_EXPENSES_ROUTE,
  USER_INVOICES_NEW_INVOICE_ROUTE,
  USER_INVOICES_ROUTE,
  USER_PAY_ITEMS_ROUTE,
  USER_PAYROLL_NEW_STARTER,
  USER_PAYROLL_PAYSLIPS,
  USER_PAYROLL_SETTINGS,
  USER_PERSONAL_TAB,
  USER_TASKS_PAGE,
  USER_WORK_TAB
} from "@/lib/routes";
import { Redirect, Switch } from "react-router-dom";
import { UserBenefitsRouter } from "@/component/dashboard/userDetails/benefits/user-benefits.router";
import { UserPayItemsPage } from "@/component/dashboard/userDetails/pay-items/user-pay-items.page";
import { getUserProfileSecondaryMenu } from "@/component/dashboard/userDetails/user-details.interface";
import { UploadProfilePicture } from "@/component/forms/UploadProfilePicture";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsenceScopes } from "@/v2/feature/absence/absence.scopes";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceScopes } from "@/v2/feature/device/device.scopes";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { ExpensePage } from "@/v2/feature/expense/expense.page";
import { USER_PROFILE_GROWTH_SCOPE } from "@/v2/feature/growth/reviews/interfaces/review.interface";
import { InvoicesPage } from "@/v2/feature/payments/pages/invoice.page";
import { NewExpensePage } from "@/v2/feature/payments/pages/new-expense.page";
import { NewInvoicePage } from "@/v2/feature/payments/pages/new-invoice.page";
import { TaskScopes } from "@/v2/feature/task/task.scopes";
import { OnboardingPayrollPage } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll.page";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { collateMissingFieldsForUser } from "@/v2/feature/user/features/user-forms/user-forms.util";
import { UserAbsencePage } from "@/v2/feature/user/features/user-profile/absence/user-absence.page";
import { UserAppsPage } from "@/v2/feature/user/features/user-profile/apps/pages/user-apps.page";
import { UserAttendancePage } from "@/v2/feature/user/features/user-profile/attendance/user-attendance.page";
import { UserProfileAction } from "@/v2/feature/user/features/user-profile/details/components/user-profile-action.component";
import { UserCompensation } from "@/v2/feature/user/features/user-profile/details/pages/user-compensation.page";
import { UserContact } from "@/v2/feature/user/features/user-profile/details/pages/user-contact.page";
import { UserPersonal } from "@/v2/feature/user/features/user-profile/details/pages/user-personal.page";
import { UserWork } from "@/v2/feature/user/features/user-profile/details/pages/user-work.page";
import { UserProfileDocument } from "@/v2/feature/user/features/user-profile/documents/user-profile-document.page";
import { UserProfileNewDocumentPage } from "@/v2/feature/user/features/user-profile/new-document/user-profile-new-document.page";
import { UserProfilePayroll } from "@/v2/feature/user/features/user-profile/payroll/user-profile-payroll.page";
import { UserTasksPage } from "@/v2/feature/user/features/user-profile/tasks/user-tasks.page";
import { UserAPI, UserEndpoints } from "@/v2/feature/user/user.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { textOverflowSx } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { Box } from "@mui/material";
import { UserProfileDeviceRouter } from "@v2/feature/user/features/user-profile/devices/user-profile-device.router";
import { useUserProfileConfig } from "@v2/feature/user/features/user-profile/user-profile.hook";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { UserDetailsGrowthRouter } from "./user-details-growth.router";
export const UserDetailsTabsRouter = () => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [state, dispatch] = useContext(GlobalContext);
  const userId = useUserIdParam();
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const { userOverviewConfig, refreshUserOverviewConfig } = useUserProfileConfig(userId);
  const { companyConfig } = useCompanyConfigState();
  const scopesContext = getScopesContext({ userId });
  const isOwnerProfile = ((_a = state.user) == null ? void 0 : _a.userId) === userId;
  const getProfileScope = useCallback(
    (scopeRoot) => [
      isOwnerProfile ? `${scopeRoot}:read` : `${scopeRoot}:read:all`
    ],
    [isOwnerProfile]
  );
  const payrollScopes = getProfileScope("payroll");
  const pensionScopes = getProfileScope("pension");
  const deferredEndpointsPaused = useRef(true);
  const [globalState] = useContext(GlobalContext);
  const hasUserAllScope = checkScopes(globalState.user, ["user", "user:all"], scopesContext);
  const [localAvatar, setLocalAvatar] = useState();
  const [showMessage] = useMessage();
  const { data: userMissingFields, mutate: refreshMissingFields, isLoading } = useApiClient(
    UserEndpoints.getUserMissingFields(userId),
    {
      isPaused: () => deferredEndpointsPaused.current,
      suspense: false
    }
  );
  const missingFields = useMemo(() => userMissingFields && collateMissingFieldsForUser(userMissingFields), [
    userMissingFields
  ]);
  useEffect(() => {
    if (!deferredEndpointsPaused.current) return;
    deferredEndpointsPaused.current = false;
    refreshMissingFields == null ? void 0 : refreshMissingFields();
  }, [refreshMissingFields]);
  const handleUpdate = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    yield refreshUserOverviewConfig();
    dispatch({
      type: GlobalStateActions.UPDATE_ALERTS,
      payload: { people: yield UserAPI.getAlerts((_a2 = state.user) == null ? void 0 : _a2.userId) }
    });
  }), [dispatch, refreshUserOverviewConfig, (_b = state.user) == null ? void 0 : _b.userId]);
  const finallyRefreshUserObject = useCallback(
    (section) => __async(void 0, null, function* () {
      if (["user.basicInfo", "user.role", "user.lifecycle"].includes(section)) {
      }
      yield handleUpdate();
    }),
    [handleUpdate]
  );
  const { getCachedUserById } = useCachedUsers();
  const user = getCachedUserById(userId);
  const getUserAvatar = useCallback(() => __async(void 0, null, function* () {
    if (!userId) return;
    try {
      const userAvataURL = yield UserAPI.getUserAvatar(userId);
      setLocalAvatar(userAvataURL);
    } catch (error) {
      showMessage(`${polyglot.t("UserProfileHeader.errorMessages.load")}: ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage, userId, polyglot]);
  useEffect(() => {
    getUserAvatar();
  }, [getUserAvatar]);
  const customSection = useMemo(() => {
    var _a2, _b2, _c, _d, _e;
    return isLoading ? /* @__PURE__ */ jsx(UserProfileLoader, {}) : user ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
      /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "center", width: "100%", boxSizing: "border-box" }, children: hasUserAllScope ? /* @__PURE__ */ jsx(
        UploadProfilePicture,
        {
          value: `${state.publicURL}/${localAvatar}`,
          onChange: (tempAvatar) => {
            setLocalAvatar(tempAvatar);
          },
          url: UserAPI.getUserAvatarURL(userId),
          userId,
          avatarSize: "x100",
          boxSize: 100
        }
      ) : /* @__PURE__ */ jsx(UserAvatar, { userId: user == null ? void 0 : user.userId, size: "x100" }, user == null ? void 0 : user.userId) }),
      /* @__PURE__ */ jsxs(
        "div",
        {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            width: "100%"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: textOverflowSx, children: polyglot.t(user.displayName) }),
            ((_b2 = (_a2 = user.role) == null ? void 0 : _a2.jobPosition) == null ? void 0 : _b2.title) && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: textOverflowSx, children: polyglot.t((_e = (_d = (_c = user.role) == null ? void 0 : _c.jobPosition) == null ? void 0 : _d.title) != null ? _e : "") }),
            user.emailAddress && /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "captionSmall",
                sx: __spreadValues({
                  color: themeColors.Grey,
                  marginTop: spacing.sm
                }, textOverflowSx),
                children: user.emailAddress
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(UserProfileAction, { userId, finallyRefreshUserObject })
    ] }) : /* @__PURE__ */ jsx(Fragment, {});
  }, [user, isLoading, finallyRefreshUserObject, hasUserAllScope, localAvatar, polyglot, userId, state == null ? void 0 : state.publicURL]);
  return /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        showBack: true,
        backPath: PEOPLE_ME_DIRECTORY_ROUTE,
        pageConfig: getUserProfileSecondaryMenu(
          userId,
          companyConfig,
          userOverviewConfig,
          currentUser,
          !!state.showHiddenFeatures,
          scopesContext,
          polyglot,
          missingFields
        ),
        customTitle: customSection
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: TaskScopes.VIEW_USER_TASKS, context: scopesContext, path: USER_TASKS_PAGE, children: /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "83%",
              height: "40%",
              sx: { borderRadius: 2, backgroundColor: themeColors.Background }
            }
          ),
          children: /* @__PURE__ */ jsx(UserTasksPage, { userId })
        }
      ) }),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: AbsenceScopes.VIEW_USER_ABSENCES,
          context: scopesContext,
          path: USER_ABSENCE_ROUTE,
          children: /* @__PURE__ */ jsx(UserAbsencePage, { userId })
        }
      ),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: AbsenceScopes.VIEW_USER_ABSENCES, context: scopesContext, path: USER_ABSENCE_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: USER_ABSENCE_ROUTE }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["attendance:manager"], context: scopesContext, path: USER_ATTENDANCE_ROUTE, children: /* @__PURE__ */ jsx(UserAttendancePage, { userId }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: DeviceScopes.VIEW_USER_DEVICES, context: scopesContext, path: USER_DEVICES_PAGE, children: /* @__PURE__ */ jsx(UserProfileDeviceRouter, {}) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: payrollScopes, context: scopesContext, path: USER_PAYROLL_NEW_STARTER, children: /* @__PURE__ */ jsx(OnboardingPayrollPage, { mode: "new-starter" }) }),
      (userOverviewConfig == null ? void 0 : userOverviewConfig.contractType) !== "Contractor" && /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: payrollScopes,
          context: scopesContext,
          path: [USER_PAYROLL_PAYSLIPS, USER_PAYROLL_SETTINGS],
          exact: true,
          children: /* @__PURE__ */ jsx(UserProfilePayroll, { userId, userOverviewConfig })
        }
      ),
      (userOverviewConfig == null ? void 0 : userOverviewConfig.contractType) !== "Contractor" && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance", "pension"], context: scopesContext, path: [USER_BENEFITS_ROUTE], children: /* @__PURE__ */ jsx(UserBenefitsRouter, { userId, pensionScopes, handleUpdate }) }),
      (userOverviewConfig == null ? void 0 : userOverviewConfig.contractType) !== "Contractor" && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["payroll"], context: scopesContext, path: [USER_PAY_ITEMS_ROUTE], children: /* @__PURE__ */ jsx(UserPayItemsPage, { userId }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["expenses"], context: scopesContext, path: USER_EXPENSES_NEW_EXPENSE_ROUTE, children: /* @__PURE__ */ jsx(NewExpensePage, { preselectedEmployee: userId, fromUserProfile: true }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["expenses"], context: scopesContext, path: [USER_EXPENSES_ROUTE], children: /* @__PURE__ */ jsx(ExpensePage, { reach: "me", userId }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["invoices"], context: scopesContext, path: USER_INVOICES_NEW_INVOICE_ROUTE, children: /* @__PURE__ */ jsx(NewInvoicePage, { preselectedEmployee: userId, fromUserProfile: true }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["invoices"], context: scopesContext, path: [USER_INVOICES_ROUTE], children: /* @__PURE__ */ jsx(InvoicesPage, { reach: "me", userId }) }),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: DocumentsScopes.VIEW_USER_DOCUMENTS,
          context: scopesContext,
          path: ADMIN_USER_DOCUMENTS_ROUTE,
          exact: true,
          children: /* @__PURE__ */ jsx(UserProfileDocument, { userId, userName: userOverviewConfig.name })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: DocumentsScopes.VIEW_USER_DOCUMENTS,
          context: scopesContext,
          path: ADMIN_USER_DOCUMENTS_NEW_ROUTE,
          exact: true,
          children: /* @__PURE__ */ jsx(UserProfileNewDocumentPage, { userId })
        }
      ),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: USER_PROFILE_GROWTH_SCOPE, context: scopesContext, path: ADMIN_USER_REVIEWS_ROUTE, children: /* @__PURE__ */ jsx(UserDetailsGrowthRouter, {}) }),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: UserDetailsScopes.VIEW_USER_WORK,
          context: scopesContext,
          path: USER_WORK_TAB,
          exact: true,
          children: /* @__PURE__ */ jsx(UserWork, { userId, onUpdate: handleUpdate })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: UserDetailsScopes.VIEW_USER_PERSONAL,
          context: scopesContext,
          path: USER_PERSONAL_TAB,
          exact: true,
          children: /* @__PURE__ */ jsx(UserPersonal, { userId, onUpdate: handleUpdate })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: UserDetailsScopes.VIEW_USER_CONTACT,
          context: scopesContext,
          path: USER_CONTACT_TAB,
          exact: true,
          children: /* @__PURE__ */ jsx(UserContact, { userId, onUpdate: handleUpdate })
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: UserDetailsScopes.VIEW_USER_COMPENSATION,
          context: scopesContext,
          path: USER_COMPENSATION_TAB,
          exact: true,
          children: /* @__PURE__ */ jsx(UserCompensation, { userId, onUpdate: handleUpdate })
        }
      ),
      /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: AppScopes.VIEW_USER_APPS, context: scopesContext, path: USER_APPS_TAB, children: /* @__PURE__ */ jsx(UserAppsPage, {}) }),
      /* @__PURE__ */ jsx(Redirect, { from: USER_DETAILS_ROUTE, to: USER_DETAILS_PROFILE_ROUTE })
    ] })
  ] });
};
const UserProfileLoader = () => {
  return /* @__PURE__ */ jsxs(
    "div",
    {
      style: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.s2,
        justifyContent: "center",
        width: "100%",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx(SkeletonLoader, { variant: "circular", width: "100px", height: 100 }),
        /* @__PURE__ */ jsxs(
          "div",
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.s1,
              justifyContent: "center",
              width: "100%",
              alignItems: "center"
            },
            children: [
              /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "130px", height: 15, rowGap: "5px" }),
              /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "120px", height: 15, rowGap: "5px" }),
              /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100px", height: 15, rowGap: "5px" })
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          "div",
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.s1,
              justifyContent: "center",
              width: "100%"
            },
            children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 30, rowGap: "5px" })
          }
        )
      ]
    }
  );
};
