"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ChartColumnSingle } from "@/v2/components/charts/chart-column-single.component";
import { ChartDonut } from "@/v2/components/charts/chart-donut.component";
import { chartBoxSx, childChartSx, parentChartSx } from "@/v2/components/charts/styles.layout";
import { AnalyticsAPI } from "@/v2/feature/analytics/analytics.api";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AnalyticsInsightsPeoplePage = () => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [chartProps, setChartProps] = useState({
    series: [],
    category: []
  });
  const [genderOverTime, setGenderOverTime] = useState({
    series: [],
    labels: [],
    totalSeries: [],
    totalLabels: []
  });
  const [hiresAndFiresMonth, setHiresAndFiresMonth] = useState({
    series: [
      {
        name: "Joiners",
        data: [],
        tooltip: []
      },
      {
        name: "Leavers",
        data: [],
        tooltip: []
      }
    ],
    months: [],
    categories: [],
    data: []
  });
  const [headcountDeptChartProps, setHeadcountDeptChartProps] = useState({
    series: [],
    labels: []
  });
  const fetchChartStats = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [genderOverTime2, headcountOverTime, headcountByDepartment, hiresAndFiresOverMonth] = yield Promise.all([
        AnalyticsAPI.findGenderOverTime(),
        AnalyticsAPI.getHeadcountOverTime(),
        AnalyticsAPI.findHeadcountByDepartment(),
        AnalyticsAPI.getHiresAndFiresLastMonth()
      ]);
      setGenderOverTime(genderOverTime2);
      setHiresAndFiresMonth(hiresAndFiresOverMonth);
      let series = [];
      let labels = [];
      headcountByDepartment.forEach((item) => {
        series.push(item.count);
        labels.push(item.department.name);
      });
      setHeadcountDeptChartProps({ series, labels });
      setChartProps(headcountOverTime);
    } catch (err) {
      console.error("err", err);
      showMessage(polyglot.t("AnalyticsInsightsPeoplePage.errorMessages.badRequest"), "error");
    } finally {
      setLoading(false);
    }
  }), []);
  useEffect(() => {
    fetchChartStats();
  }, [fetchChartStats]);
  const customTooltip = ({ seriesIndex, dataPointIndex }) => {
    var _a, _b;
    const yValue = (_a = hiresAndFiresMonth == null ? void 0 : hiresAndFiresMonth.series[seriesIndex]) == null ? void 0 : _a.tooltip[dataPointIndex];
    return `<div class='arrow_box'>${(_b = Object.values(yValue)) == null ? void 0 : _b.toString().replaceAll(",", " <br/>")}</div>`;
  };
  useEffect(() => {
    trackPage("People insights");
  }, []);
  const getTranslatedCategoriesForLeaversJoinersChart = (polyglot2, categories) => {
    return categories ? categories.map((category) => polyglot2.t(`LeaversJoinersChart.${category}`)) : [];
  };
  const getTranslatedSeriesNamesForLeaversJoinersChart = (polyglot2, leaversJoiners) => {
    return (leaversJoiners == null ? void 0 : leaversJoiners.series) ? leaversJoiners.series.map((eachSeries) => {
      return __spreadProps(__spreadValues({}, eachSeries), { name: polyglot2.t(`LeaversJoinersChart.${eachSeries.name}`) });
    }) : [];
  };
  const getMonthAbbreviationsForXAxis = (polyglot2, months) => {
    return months ? months.map((month) => polyglot2.t(`getMonthAbbreviatedOptions.${month}`)) : [];
  };
  const getTranslatedSeriesNamesForGenderOverTimeChart = (polyglot2, genderOverTimeSeries) => {
    return genderOverTimeSeries ? genderOverTimeSeries.map((eachSeries) => {
      return __spreadProps(__spreadValues({}, eachSeries), { name: polyglot2.t(`GenderOverTimeChart.${eachSeries.name}`) });
    }) : [];
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: polyglot.t("AnalyticsInsightsPeoplePage.insights") }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: spacing.mb20, children: polyglot.t("AnalyticsInsightsPeoplePage.headcount") }) }),
      /* @__PURE__ */ jsxs(Box, { sx: chartBoxSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: parentChartSx, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("AnalyticsInsightsPeoplePage.lastMonths") }),
          /* @__PURE__ */ jsx(
            ChartColumnSingle,
            {
              series: chartProps.series,
              categories: getMonthAbbreviationsForXAxis(polyglot, chartProps.category),
              stacked: true
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: childChartSx, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("AnalyticsInsightsPeoplePage.byDep") }),
          /* @__PURE__ */ jsx(ChartDonut, { series: headcountDeptChartProps == null ? void 0 : headcountDeptChartProps.series, labels: headcountDeptChartProps == null ? void 0 : headcountDeptChartProps.labels })
        ] })
      ] }),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: __spreadProps(__spreadValues({}, spacing.mb20), { marginTop: spacing.m60 }), children: polyglot.t("AnalyticsInsightsPeoplePage.diversity") }) }),
      /* @__PURE__ */ jsxs(Box, { sx: chartBoxSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: parentChartSx, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("AnalyticsInsightsPeoplePage.gender") }),
          /* @__PURE__ */ jsx(
            ChartColumnSingle,
            {
              series: getTranslatedSeriesNamesForGenderOverTimeChart(polyglot, genderOverTime.series),
              categories: getMonthAbbreviationsForXAxis(polyglot, genderOverTime.labels),
              stacked: true,
              max: 100,
              percentage: true,
              min: 0,
              tickAmount: 5
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: childChartSx, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("AnalyticsInsightsPeoplePage.nationality") }),
          /* @__PURE__ */ jsx(ChartDonut, { series: genderOverTime.totalSeries, labels: genderOverTime.totalLabels })
        ] })
      ] }),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: __spreadProps(__spreadValues({}, spacing.mb20), { marginTop: spacing.m60 }), children: polyglot.t("AnalyticsInsightsPeoplePage.joinersLeavers") }) }),
      /* @__PURE__ */ jsxs(Box, { sx: chartBoxSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: parentChartSx, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("AnalyticsInsightsPeoplePage.lastMonths") }),
          /* @__PURE__ */ jsx(
            ChartColumnSingle,
            {
              series: getTranslatedSeriesNamesForLeaversJoinersChart(polyglot, hiresAndFiresMonth),
              categories: getMonthAbbreviationsForXAxis(polyglot, hiresAndFiresMonth.months),
              customTooltip
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: childChartSx, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("AnalyticsInsightsPeoplePage.lastMonths") }),
          /* @__PURE__ */ jsx(
            ChartDonut,
            {
              series: hiresAndFiresMonth.data,
              labels: getTranslatedCategoriesForLeaversJoinersChart(polyglot, hiresAndFiresMonth.categories)
            }
          )
        ] })
      ] })
    ] })
  ] });
};
