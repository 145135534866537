"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { RefinancingAPI } from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api";
import { buttonBoxSx, fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@v2/styles/colors.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { BillingEndpoints } from "@/api-client/billing.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const RefinancePlanCreateAccountDrawer = ({
  isOpen,
  setIsOpen,
  order,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsx(RefinancePlanCreateAccountDrawerContent, { order, refresh })
    }
  ) });
};
const RefinancePlanCreateAccountDrawerContent = ({ order, refresh }) => {
  var _a, _b;
  const { data: billingInfo } = useApiClient(BillingEndpoints.getBillingInfo());
  const [showMessage] = useMessage();
  const [isCreateAccountLoading, setIsCreateAccountLoading] = useState(false);
  const createRepaymentAccount = useCallback(
    (companyId, values) => __async(void 0, null, function* () {
      try {
        setIsCreateAccountLoading(true);
        yield RefinancingAPI.createCompanyRepaymentAccount(companyId, values);
        showMessage("Account successfully created.", "success");
        yield refresh();
      } catch (error) {
        showMessage(`Could not create repayment account. ${nestErrorMessage(error)}`, "error");
      } finally {
        setIsCreateAccountLoading(false);
      }
    }),
    [refresh, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      billingPhone: (_b = (_a = billingInfo == null ? void 0 : billingInfo.billingContact) == null ? void 0 : _a.phoneNumber) != null ? _b : ""
    },
    validationSchema: yup.object({
      billingPhone: yup.string().required("Billing phone is required.")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2;
      if ((_a2 = order.refinancing) == null ? void 0 : _a2.accountId) {
        showMessage("An account has already been created for this company.", "error");
        return;
      }
      yield createRepaymentAccount(order.companyId, values);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Create repayment account" }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Billing Phone",
        name: "billingPhone",
        value: formik.values.billingPhone,
        onChange: formik.handleChange,
        error: formik.touched.billingPhone && Boolean(formik.errors.billingPhone),
        helperText: formik.touched.billingPhone && formik.errors.billingPhone,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "large",
        colorVariant: "primary",
        name: "Create",
        loading: isCreateAccountLoading,
        fullWidth: true
      }
    ) })
  ] }) });
};
