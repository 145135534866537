"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
export const PayrollTableSkeleton = ({ includeMargin = true, includeGutter = false, rows = 10, delay = 400 }) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        marginTop: includeMargin ? 9 : 0,
        marginBottom: includeGutter ? 4 : 0
      },
      children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: "40px", rows, delay })
    }
  );
};
