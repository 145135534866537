"use strict";
export var ReachType = /* @__PURE__ */ ((ReachType2) => {
  ReachType2["Team"] = "team";
  ReachType2["Company"] = "company";
  ReachType2["Me"] = "me";
  return ReachType2;
})(ReachType || {});
export var UserType = /* @__PURE__ */ ((UserType2) => {
  UserType2["Profile"] = "profile";
  UserType2["App"] = "app";
  return UserType2;
})(UserType || {});
