"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { PublicHolidaysAPI } from "@v2/feature/absence/public-holidays.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Cancel } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
export const AbsenceDrawerPHButtons = ({
  absence,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
  const [isLoadingSecondary, setIsLoadingSecondary] = useState(false);
  const handleApproval = useCallback(
    (absence2, status) => __async(void 0, null, function* () {
      if (!absence2.deletionRequestId) return;
      try {
        yield PublicHolidaysAPI.handleUserDeletionApprovalByRequestId(
          absence2.userId,
          absence2.deletionRequestId,
          status
        );
        yield refresh();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [refresh, polyglot, showMessage]
  );
  const handleForceApproval = useCallback(
    (absence2, status) => __async(void 0, null, function* () {
      if (!absence2.deletionRequestId) return;
      try {
        yield PublicHolidaysAPI.handleUserDeletionForceApprovalByRequestId(
          absence2.userId,
          absence2.deletionRequestId,
          status
        );
        yield refresh();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [showMessage, polyglot, refresh]
  );
  const deleteCancellation = useCallback(
    (absence2) => __async(void 0, null, function* () {
      if (!absence2.deletionRequestId) return;
      try {
        yield PublicHolidaysAPI.deletePHDeletionRequest(absence2.userId, absence2.deletionRequestId);
        yield refresh();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [showMessage, polyglot, refresh]
  );
  const { primaryAction, allOtherActions } = useMemo(() => {
    const allActions = getActionButtons(absence, handleApproval, handleForceApproval, deleteCancellation, polyglot);
    const primaryAction2 = allActions.find((a) => a.variant === "primary");
    return {
      primaryAction: primaryAction2,
      allOtherActions: primaryAction2 ? allActions.filter((a) => a.id !== primaryAction2.id) : allActions
    };
  }, [absence, polyglot, handleApproval, handleForceApproval, deleteCancellation]);
  return !primaryAction && allOtherActions.length === 0 ? null : /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
    !primaryAction && allOtherActions.length === 2 ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: isLoadingSecondary,
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            setIsLoadingSecondary(true);
            yield allOtherActions[0].handler();
            setIsLoadingSecondary(false);
          }),
          name: allOtherActions[0].label,
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: isLoadingSecondary,
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            setIsLoadingSecondary(true);
            yield allOtherActions[1].handler();
            setIsLoadingSecondary(false);
          }),
          name: allOtherActions[1].label,
          fullWidth: true
        }
      )
    ] }) : (
      //   IF MORE THAN 2 ACTIONS IN TOTAL (including one primary)
      allOtherActions.length > 1 ? /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: allOtherActions,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "medium",
            title: polyglot.t("General.actions"),
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end",
            fullWidth: true
          },
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { horizontal: "left", vertical: "top" }
        }
      ) }) : (
        // IF ONLY ONE SECONDARY ACTION
        allOtherActions.length === 1 ? /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            loading: isLoadingSecondary,
            colorVariant: "secondary",
            onClick: () => __async(void 0, null, function* () {
              setIsLoadingSecondary(true);
              yield allOtherActions[0].handler();
              setIsLoadingSecondary(false);
            }),
            name: allOtherActions[0].label,
            fullWidth: true
          }
        ) : null
      )
    ),
    primaryAction && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        loading: isLoadingPrimary,
        colorVariant: "primary",
        onClick: () => __async(void 0, null, function* () {
          setIsLoadingPrimary(true);
          yield primaryAction.handler();
          setIsLoadingPrimary(false);
        }),
        name: primaryAction.label,
        fullWidth: true
      }
    )
  ] });
};
var AbsenceActionVariant = /* @__PURE__ */ ((AbsenceActionVariant2) => {
  AbsenceActionVariant2["primary"] = "primary";
  AbsenceActionVariant2["secondary"] = "secondary";
  return AbsenceActionVariant2;
})(AbsenceActionVariant || {});
const getActionButtons = (absence, handleApproval, handleForceApproval, deleteCancellation, polyglot) => {
  return [
    /* PRIMARY BUTTONS */
    // APPROVE
    {
      label: polyglot.t("AbsenceViewerApprovalButtons.approve"),
      handler: () => __async(void 0, null, function* () {
        yield handleApproval(absence, AbsenceStatus.Approved);
      }),
      hidden: !handleApproval || !absence.canApprove,
      variant: "primary" /* primary */,
      icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
      id: "approve"
    },
    // FORCE APPROVE
    {
      label: polyglot.t("AbsenceTableActions.forceApprove"),
      handler: () => __async(void 0, null, function* () {
        yield handleForceApproval(absence, AbsenceStatus.Approved);
      }),
      hidden: !handleForceApproval || !absence.canForceApprove,
      variant: "primary" /* primary */,
      icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
      id: "forceApprove"
    },
    /* SECONDARY BUTTONS */
    // FORCE REJECT
    {
      label: polyglot.t("AbsenceTableActions.forceReject"),
      handler: () => __async(void 0, null, function* () {
        yield handleForceApproval(absence, AbsenceStatus.Rejected);
      }),
      hidden: !handleForceApproval || !absence.canForceReject,
      variant: "secondary" /* secondary */,
      icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })),
      id: "forceReject"
    },
    // REJECT
    {
      label: polyglot.t("AbsenceViewerApprovalButtons.reject"),
      handler: () => __async(void 0, null, function* () {
        yield handleApproval(absence, AbsenceStatus.Rejected);
      }),
      hidden: !handleApproval || !absence.canReject,
      variant: "secondary" /* secondary */,
      icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })),
      id: "reject"
    },
    // DELETE
    {
      label: polyglot.t("AbsenceViewerApprovalButtons.deleteCancellation"),
      handler: () => __async(void 0, null, function* () {
        yield deleteCancellation(absence);
      }),
      hidden: !deleteCancellation || !absence.canDeletePHCancellation,
      variant: "secondary" /* secondary */,
      icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
      id: "delete"
    }
  ].filter((btn) => !btn.hidden);
};
