"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useContext, useMemo } from "react";
import { Avatar, Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as CompletedAction } from "@/images/side-bar-icons/CheckCircle.svg";
import { ReactComponent as ActionCardIcon } from "@/images/side-bar-icons/WaitingFull.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { styledChipSx } from "@/v2/styles/chip.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getIconForCard = (iconState, requiresAdminAction) => {
  if (iconState === "done")
    return /* @__PURE__ */ jsx(CompletedAction, { style: { height: 16, width: 16, flexShrink: 0, fill: themeColors.green } });
  if (iconState === "pending")
    return /* @__PURE__ */ jsx(ActionCardIcon, { style: { height: 16, width: 16, flexShrink: 0, fill: themeColors.YellowHover } });
  if (requiresAdminAction)
    return /* @__PURE__ */ jsx(ActionCardIcon, { style: { height: 16, width: 16, flexShrink: 0, fill: themeColors.red } });
  return /* @__PURE__ */ jsx(ActionCardIcon, { style: { height: 16, width: 16, flexShrink: 0 } });
};
export const OffboardingActionCard = ({
  chips,
  iconState = "todo",
  disabled,
  label,
  description,
  owner,
  onActionClick,
  buttonLabel,
  buttonColor = "primary",
  actionButton,
  requiresAdminAction = false,
  sx
}) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", gap: spacing.g10, py: spacing.p15, alignItems: "center" }, sx), children: [
    getIconForCard(iconState, requiresAdminAction),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { whiteSpace: "nowrap" }, children: label }),
      description && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { whiteSpace: "nowrap" }, children: description })
    ] }),
    /* @__PURE__ */ jsx(ChipsList, { chips: chips != null ? chips : [] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", ml: "auto", gap: spacing.g20 }, children: [
      owner === "company" && /* @__PURE__ */ jsx(Avatar, { src: globalState.user.company.avatar, sx: { width: 20, height: 20 } }),
      typeof owner === "number" && /* @__PURE__ */ jsx(UserAvatar, { userId: owner, size: "xxsmall" }),
      (actionButton || onActionClick) && !disabled && /* @__PURE__ */ jsx(Stack, { sx: { width: "100px", flexShrink: 0 }, children: actionButton != null ? actionButton : onActionClick && /* @__PURE__ */ jsx(ButtonComponent, { onClick: onActionClick, sizeVariant: "small", colorVariant: buttonColor, fullWidth: true, children: buttonLabel != null ? buttonLabel : polyglot.t("OnboardingByUser.start") }) })
    ] })
  ] });
};
const ChipsList = ({ chips }) => {
  const displayedChips = useMemo(() => {
    var _a;
    return (_a = chips == null ? void 0 : chips.filter(Boolean)) != null ? _a : [];
  }, [chips]);
  return displayedChips.length > 0 ? /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", flexWrap: "wrap", gap: spacing.g4 }, children: chips.map((chip, index) => /* @__PURE__ */ jsx(ChipRender, { boxKey: index, chip }, index)) }) : null;
};
const ChipRender = ({ boxKey, chip }) => {
  return /* @__PURE__ */ jsx(React.Fragment, { children: typeof chip === "string" || React.isValidElement(chip) ? /* @__PURE__ */ jsx(Box, { sx: styledChipSx, children: chip }, boxKey) : /* @__PURE__ */ jsx(
    Box,
    {
      sx: __spreadProps(__spreadValues({}, styledChipSx), {
        color: chip.type === "error" ? themeColors.Red : chip.type === "warning" ? themeColors.Orange : chip.type === "success" ? themeColors.Green : themeColors.black
      }),
      children: chip.text
    },
    boxKey
  ) }, boxKey);
};
