"use strict";
export var DeviceInstalledApplicationStatus = /* @__PURE__ */ ((DeviceInstalledApplicationStatus2) => {
  DeviceInstalledApplicationStatus2["INSTALLED"] = "INSTALLED";
  DeviceInstalledApplicationStatus2["NOT_INSTALLED_DOWNLOAD_CANCELLED"] = "NOT_INSTALLED_DOWNLOAD_CANCELLED";
  DeviceInstalledApplicationStatus2["NOT_INSTALLED_DOWNLOAD_FAILED"] = "NOT_INSTALLED_DOWNLOAD_FAILED";
  DeviceInstalledApplicationStatus2["NOT_INSTALLED_DOWNLOAD_PAUSED"] = "NOT_INSTALLED_DOWNLOAD_PAUSED";
  DeviceInstalledApplicationStatus2["NOT_INSTALLED_DOWNLOAD_WAITING"] = "NOT_INSTALLED_DOWNLOAD_WAITING";
  DeviceInstalledApplicationStatus2["SENT_TO_DEVICE"] = "SENT_TO_DEVICE";
  DeviceInstalledApplicationStatus2["UNKNOWN"] = "UNKNOWN";
  return DeviceInstalledApplicationStatus2;
})(DeviceInstalledApplicationStatus || {});
export function mergeDevicePolicies(obj1, obj2) {
  const mergedPolicies = {};
  const allKeys = /* @__PURE__ */ new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
  allKeys.forEach((key) => {
    const mergedArray = [...obj1[key] || [], ...obj2[key] || []];
    const uniqueValues = Array.from(new Set(mergedArray));
    if (uniqueValues.some((value) => value !== 0)) {
      mergedPolicies[key] = uniqueValues.filter((value) => value !== 0);
    } else {
      mergedPolicies[key] = uniqueValues;
    }
  });
  return mergedPolicies;
}
