"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { PaymentFlowPage } from "@/v2/feature/payments/features/make-payment/pages/layout/components/payment-flow.page";
import { usePaymentContext } from "@/v2/feature/payments/features/make-payment/payment.context";
import { PAYMENTS_ROUTE } from "@/v2/feature/payments/payments.router";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function CreatePaymentPage() {
  var _a, _b, _c, _d, _e, _f;
  const routerHistory = useHistory();
  const [state] = usePaymentContext();
  const [loading, setLoading] = useState(true);
  const [showPayerDetails, setShowPayerDetails] = useState(true);
  useEffect(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g, _h;
    setLoading(true);
    if (state.payments && ((_a2 = state.payments) == null ? void 0 : _a2.length) < 1) {
      routerHistory.push(PAYMENTS_ROUTE);
    }
    const isSinglePayment = ((_b2 = state.payments) == null ? void 0 : _b2.length) === 1 && !((_e2 = (_d2 = (_c2 = state.institution) == null ? void 0 : _c2.config) == null ? void 0 : _d2.singlePayment) == null ? void 0 : _e2.payerRequired);
    const isBulkPayment = state.payments && state.payments.length > 1 && !((_h = (_g = (_f2 = state.institution) == null ? void 0 : _f2.config) == null ? void 0 : _g.bulkPayment) == null ? void 0 : _h.payerRequired);
    if (isSinglePayment || isBulkPayment) {
      setShowPayerDetails(false);
    }
    setLoading(false);
  }, [
    state,
    state.payments,
    (_c = (_b = (_a = state.institution) == null ? void 0 : _a.config) == null ? void 0 : _b.bulkPayment) == null ? void 0 : _c.payerRequired,
    (_f = (_e = (_d = state.institution) == null ? void 0 : _d.config) == null ? void 0 : _e.singlePayment) == null ? void 0 : _f.payerRequired,
    routerHistory
  ]);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(
    ContentWrapper,
    {
      loading,
      noHeader: true,
      border: false,
      sx: __spreadValues({ paddingTop: spacing.p30 }, spacing.px40),
      children: /* @__PURE__ */ jsx(PaymentFlowPage, { showPayerDetails })
    }
  ) });
}
