"use strict";
import { jsx } from "react/jsx-runtime";
import { AbsenceDrawerPage } from "@v2/feature/absence/sections/absence-drawer/absence-drawer.page";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
export const AbsenceDrawer = ({
  isOpen,
  onClose,
  afterClose,
  absence,
  userId,
  refresh,
  setIsAbsenceDrawerOpen,
  reach,
  usedForDataImport,
  importHandler,
  policyId
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen: setIsAbsenceDrawerOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
  AbsenceDrawerPage,
  {
    absence,
    userId,
    refresh,
    closePage: () => onClose(),
    reach,
    usedForDataImport,
    importHandler,
    policyId
  }
) });
