"use strict";
export var CustomProfileFormType = /* @__PURE__ */ ((CustomProfileFormType2) => {
  CustomProfileFormType2["Basic"] = "basic";
  CustomProfileFormType2["Details"] = "details";
  CustomProfileFormType2["Family"] = "family";
  CustomProfileFormType2["About"] = "about";
  CustomProfileFormType2["Other"] = "other";
  CustomProfileFormType2["Role"] = "role";
  CustomProfileFormType2["Contract"] = "contract";
  CustomProfileFormType2["Lifecycle"] = "lifecycle";
  CustomProfileFormType2["IdentityCheck"] = "identity-check";
  CustomProfileFormType2["Salary"] = "salary";
  CustomProfileFormType2["TaxInformation"] = "taxInformation";
  CustomProfileFormType2["BankAccount"] = "bank-account";
  CustomProfileFormType2["Equity"] = "equity";
  CustomProfileFormType2["Address"] = "address";
  CustomProfileFormType2["Emergency"] = "emergency";
  CustomProfileFormType2["WorkContact"] = "work-contact";
  return CustomProfileFormType2;
})(CustomProfileFormType || {});
export var ProfileTab = /* @__PURE__ */ ((ProfileTab2) => {
  ProfileTab2["Personal"] = "personal";
  ProfileTab2["Work"] = "work";
  ProfileTab2["Compensation"] = "compensation";
  ProfileTab2["Contact"] = "contact";
  return ProfileTab2;
})(ProfileTab || {});
