"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as CleanGrey } from "@/images/fields/CleanGrey.svg";
import { nestErrorMessage } from "@/lib/errors";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import {
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsRemoveReviewerModal = ({
  reviewCycle,
  selectedReviewee,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    ParticipantsRemoveReviewerContent,
    {
      reviewCycle,
      onClose,
      refresh,
      selectedReviewee
    }
  ) });
};
const useReviewerDeleteForm = (selectedReviewee, reviewCycle, onClose, refresh, deletedPeerIds, deletedUpwardIds, deletedManagerIds) => {
  const [showMessage] = useMessage();
  function handleDraftOrScheduledReviewCycle(values, reviewCycle2, selectedReviewee2) {
    return __async(this, null, function* () {
      const filteredPeerIds = values.peerIds.filter((peerId) => !deletedPeerIds.includes(peerId));
      const filteredUpwardsIds = values.upwardIds.filter((upwardId) => !deletedUpwardIds.includes(upwardId));
      const filteredManagerIds = values.managerIds.filter((managerId) => !deletedManagerIds.includes(managerId));
      yield ReviewCycleAPI.removeReviewersToPreOngoing(reviewCycle2.id, {
        selfId: selectedReviewee2.selfId,
        peerIds: filteredPeerIds,
        upwardIds: filteredUpwardsIds,
        managerIds: filteredManagerIds
      });
    });
  }
  function handleOngoingReviewCycle(reviewCycle2, selectedReviewee2) {
    return __async(this, null, function* () {
      const reviewers = reviewCycle2.reviewerSelect.reduce((acc, r) => {
        let ids = null;
        if (r === ReviewerTypes.Peer && deletedPeerIds && deletedPeerIds.length) {
          ids = deletedPeerIds;
        } else if (r === ReviewerTypes.Manager && deletedManagerIds && deletedManagerIds.length) {
          ids = deletedManagerIds;
        } else if (r === ReviewerTypes.Upward && deletedUpwardIds && deletedUpwardIds.length) {
          ids = deletedUpwardIds;
        }
        if (ids) {
          acc.push({ reviewerType: r, ids });
        }
        return acc;
      }, []);
      yield ReviewCycleAPI.removeReviewers(reviewCycle2.id, {
        reviewers,
        revieweeId: selectedReviewee2.selfId
      });
    });
  }
  const formik = useFormik({
    initialValues: {
      peerIds: selectedReviewee && selectedReviewee.peerIds ? selectedReviewee.peerIds : [],
      upwardIds: selectedReviewee && selectedReviewee.upwardIds ? selectedReviewee.upwardIds : [],
      managerIds: selectedReviewee && selectedReviewee.managerIds ? selectedReviewee.managerIds : []
    },
    validationSchema: yup.object({
      peerIds: yup.array().of(yup.number()).required(),
      upwardIds: yup.array().of(yup.number()).required(),
      managerIds: yup.array().of(yup.number()).required()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (!reviewCycle || !selectedReviewee) throw new Error("Review cycle or selected reviewee is not defined.");
        if (reviewCycle.state === CycleState.Draft || reviewCycle.state === CycleState.Scheduled) {
          yield handleDraftOrScheduledReviewCycle(values, reviewCycle, selectedReviewee);
        }
        if ((reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Ongoing) {
          yield handleOngoingReviewCycle(reviewCycle, selectedReviewee);
        }
        showMessage("Successfully deleted reviewers", "success");
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
const ParticipantsRemoveReviewerContent = ({
  reviewCycle,
  onClose,
  refresh,
  selectedReviewee
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const { polyglot } = usePolyglot();
  const [deletedPeerIds, setDeletedPeerIds] = useState([]);
  const [deletedUpwardIds, setDeletedUpwardIds] = useState([]);
  const [deletedManagerIds, setDeletedManagerIds] = useState([]);
  const formik = useReviewerDeleteForm(
    selectedReviewee,
    reviewCycle,
    onClose,
    refresh,
    deletedPeerIds,
    deletedUpwardIds,
    deletedManagerIds
  );
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Remove reviewers" }),
    reviewCycle.state === CycleState.Ongoing && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Any submitted answers for removed participants will be lost." }),
    (selectedReviewee == null ? void 0 : selectedReviewee.selfId) && /* @__PURE__ */ jsx(ViewItem, { title: "Participant", value: /* @__PURE__ */ jsx(UserCell, { userId: selectedReviewee == null ? void 0 : selectedReviewee.selfId }) }),
    ((_a = reviewCycle.reviewerSelect) == null ? void 0 : _a.includes(ReviewerTypes.Manager)) ? ((_b = formik.values) == null ? void 0 : _b.managerIds) && ((_c = formik.values) == null ? void 0 : _c.managerIds.length) > 0 && /* @__PURE__ */ jsx(
      ViewItem,
      {
        title: "Manager",
        value: /* @__PURE__ */ jsx(Box, { children: (_d = formik.values) == null ? void 0 : _d.managerIds.map((managerId, idx) => {
          var _a2;
          return /* @__PURE__ */ jsx(
            ReviewerListing,
            {
              reviewerId: managerId,
              isLast: idx === ((_a2 = formik.values) == null ? void 0 : _a2.managerIds.length) - 1,
              onClick: () => {
                const filteredPeerIds = formik.values.managerIds.filter((id) => id !== managerId);
                formik.setFieldValue("managerIds", filteredPeerIds);
                setDeletedManagerIds((prevDeleted) => [...prevDeleted, managerId]);
              }
            },
            idx
          );
        }) })
      }
    ) : /* @__PURE__ */ jsx(ViewItem, { title: "Manager reviewer", value: "This employee has no manager reviewers." }),
    ((_e = reviewCycle.reviewerSelect) == null ? void 0 : _e.includes(ReviewerTypes.Upward)) ? ((_f = formik.values) == null ? void 0 : _f.upwardIds) && ((_g = formik.values) == null ? void 0 : _g.upwardIds.length) > 0 && /* @__PURE__ */ jsx(
      ViewItem,
      {
        title: "Upward reviewer",
        value: /* @__PURE__ */ jsx(Box, { children: (_h = formik.values) == null ? void 0 : _h.upwardIds.map((upwardId, idx) => {
          var _a2;
          return /* @__PURE__ */ jsx(
            ReviewerListing,
            {
              reviewerId: upwardId,
              isLast: idx === ((_a2 = formik.values) == null ? void 0 : _a2.upwardIds.length) - 1,
              onClick: () => {
                const filteredPeerIds = formik.values.upwardIds.filter((id) => id !== upwardId);
                formik.setFieldValue("upwardIds", filteredPeerIds);
                setDeletedUpwardIds((prevDeleted) => [...prevDeleted, upwardId]);
              }
            },
            idx
          );
        }) })
      }
    ) : /* @__PURE__ */ jsx(ViewItem, { title: "Upward reviewer", value: "This employee has no upward reviewers." }),
    ((_i = reviewCycle.reviewerSelect) == null ? void 0 : _i.includes(ReviewerTypes.Peer)) ? ((_j = formik.values) == null ? void 0 : _j.peerIds) && ((_k = formik.values) == null ? void 0 : _k.peerIds.length) > 0 && /* @__PURE__ */ jsx(
      ViewItem,
      {
        title: "Peer reviewer",
        value: /* @__PURE__ */ jsx(Box, { children: (_l = formik.values) == null ? void 0 : _l.peerIds.map((peerId, idx) => {
          var _a2;
          return /* @__PURE__ */ jsx(
            ReviewerListing,
            {
              reviewerId: peerId,
              isLast: idx === ((_a2 = formik.values) == null ? void 0 : _a2.peerIds.length) - 1,
              onClick: () => {
                const filteredPeerIds = formik.values.peerIds.filter((id) => id !== peerId);
                formik.setFieldValue("peerIds", filteredPeerIds);
                setDeletedPeerIds((prevDeleted) => [...prevDeleted, peerId]);
              }
            },
            peerId
          );
        }) })
      }
    ) : /* @__PURE__ */ jsx(ViewItem, { title: "Peer reviewer", value: "This employee has no peers reviewers." }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        disabled: deletedPeerIds.length === 0 && deletedManagerIds.length === 0 && deletedUpwardIds.length === 0,
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
const ViewItem = ({ title, value }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g8
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: title }),
        typeof value === "string" ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value }) : /* @__PURE__ */ jsx(Box, { children: value })
      ]
    }
  );
};
const ReviewerListing = ({
  reviewerId,
  onClick,
  isLast
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        paddingY: spacing.p12,
        borderBottom: isLast ? "none" : `1px solid ${themeColors.Background}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx(UserCell, { userId: reviewerId }, reviewerId),
        /* @__PURE__ */ jsx(CleanGrey, __spreadProps(__spreadValues({}, iconSize), { style: { cursor: "pointer" }, onClick }))
      ]
    },
    reviewerId
  );
};
