"use strict";
import { jsx } from "react/jsx-runtime";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { ENTITY_IMPORT_WIZARD_V2_ROUTE } from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.router";
export const ImportWizardSection = () => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsx(SettingsSectionContent, { title: polyglot.t("SettingsRouterPageConfig.import"), children: /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          items: [
            {
              type: SectionItemType.TextLine,
              value: polyglot.t("ImportWizardSection.description")
            },
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "medium",
                  colorVariant: "primary",
                  onClick: () => history.push(ENTITY_IMPORT_WIZARD_V2_ROUTE),
                  style: { marginTop: "10px" },
                  children: polyglot.t("ImportWizardSection.launchImport")
                }
              )
            }
          ]
        }
      ]
    }
  ) }) });
};
