"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class JobLevelAPI {
  static createJobTrack(jobLTrack) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/job-level/track", jobLTrack);
    });
  }
  static createJobLevel(jobLevel) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/job-level/level", jobLevel);
    });
  }
  static updateJobLevel(jobLevel) {
    return __async(this, null, function* () {
      yield axios.patch("/apiv2/job-level/level", jobLevel);
    });
  }
  static deleteJobLevel(levelId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/job-level/level/${levelId}`);
    });
  }
}
export class JobLevelEndpoints {
  static countJobLevel() {
    return {
      url: "/apiv2/job-level/count"
    };
  }
  static listJobLevel() {
    return {
      url: "/apiv2/job-level"
    };
  }
}
