"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import ReactApexChart from "react-apexcharts";
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import "./apex-charts.styles.css";
export function ChartColumnSingle({
  series,
  categories,
  horizontal,
  stacked,
  max,
  percentage = false,
  tickAmount = void 0,
  min,
  customTooltip,
  anonymousData = false,
  xFormatter,
  showEmptyState = true
}) {
  function isSeriesDataArray(series2) {
    return Array.isArray(series2) && series2.length > 0 && "data" in series2[0];
  }
  const allZeroes = isSeriesDataArray(series) ? series.every((s) => s.data.every((val) => val === 0)) : false;
  const yAxisConfig = allZeroes ? { min: 0, max: 1 } : { max, min };
  const chartOptions = __spreadProps(__spreadValues({
    chart: {
      fontFamily: "Inter, sans-serif !important",
      toolbar: {
        show: false
      },
      stacked: stacked != null ? stacked : false
    },
    colors: [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR]
  }, themeFonts.caption), {
    plotOptions: {
      bar: {
        horizontal: horizontal != null ? horizontal : false,
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 6,
      colors: ["transparent"]
    },
    xaxis: {
      type: "category",
      categories,
      labels: {
        show: categories.length > 0,
        style: {
          colors: Array(categories.length).fill(themeColors.Grey),
          fontFamily: "Inter, sans-serif !important",
          fontWeight: 400,
          fontSize: "12px"
        },
        formatter: xFormatter
      },
      min: 0,
      crosshairs: {
        show: false
      }
    },
    yaxis: __spreadProps(__spreadValues({}, yAxisConfig), {
      labels: {
        align: "left",
        style: {
          fontFamily: "Inter, sans-serif !important",
          fontWeight: 400,
          fontSize: "12px"
        },
        formatter: function(val) {
          return val.toFixed(0);
        }
      },
      crosshairs: {
        show: false
      },
      tickAmount
    }),
    tooltip: {
      enabled: !anonymousData,
      onDatasetHover: {
        highlightDataSeries: false
      },
      y: {
        formatter: (val) => `${val}`
      },
      style: { fontFamily: "Inter, sans-serif !important", fontWeight: 400, fontSize: "12px" },
      custom: customTooltip ? customTooltip : function({ series: series2, seriesIndex, dataPointIndex }) {
        return `<div class="arrow_box">${series2[seriesIndex][dataPointIndex]}${percentage ? "%" : ""}</div>`;
      }
    },
    legend: {
      show: true,
      horizontalAlign: "right",
      position: "top",
      fontFamily: "Inter, sans-serif !important",
      fontWeight: 400,
      fontSize: "12px",
      labels: { colors: [themeColors.DarkGrey] },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: void 0,
        radius: 16,
        customHTML: void 0,
        onClick: void 0
      }
    },
    noData: { text: showEmptyState ? "No data" : void 0 },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      }
    }
  });
  return /* @__PURE__ */ jsx(ReactApexChart, { type: "bar", series, options: chartOptions, height: "250px" });
}
