"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { PensionAPI, PensionEndpoints } from "@v2/feature/benefits/subfeature/pension/pension.api";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoGrey.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  NetPayArrangement,
  PaymentValues,
  PensionProviders,
  PensionRulesOptions,
  ReliefAtSource,
  SalarySacrifice
} from "@/lib/pensions";
import { Typography } from "@/v2/components/typography/typography.component";
export const EditPensionProviderSettingsDrawer = ({ isOpen, setIsOpen, provider, refresh }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(EditPensionProviderSettingsDrawerContent, { setIsOpen, refresh, provider })
  }
) });
export const EditPensionProviderSettingsDrawerContent = ({ setIsOpen, refresh, provider }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { data: noOfActivePensionSchemes } = useApiClient(PensionEndpoints.getCountOfActivePensionSchemes());
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      pensionProvider: (_a = provider.providerName) != null ? _a : void 0,
      pensionRule: (_b = provider.pensionRule) != null ? _b : void 0,
      defaultWorkerGroup: (_c = provider.defaultWorkerGroup) != null ? _c : void 0,
      paymentMethod: (_d = provider.payMethod) != null ? _d : void 0,
      userName: (_e = provider.username) != null ? _e : void 0,
      password: void 0,
      isDefault: (_f = provider.isDefault) != null ? _f : noOfActivePensionSchemes === 0,
      employerId: (_g = provider.employerId) != null ? _g : void 0
    },
    validationSchema: Yup.object({
      pensionProvider: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      pensionRule: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      defaultWorkerGroup: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      paymentMethod: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      userName: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      password: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      isDefault: Yup.boolean().notRequired(),
      employerId: Yup.string().when("pensionProvider", {
        is: PensionProviders.Nest,
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField"))
      })
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      return updateProvider(values);
    })
  });
  const updateProvider = (data) => __async(void 0, null, function* () {
    try {
      if (!(provider == null ? void 0 : provider.id) || !data.pensionProvider || !data.pensionRule || !data.defaultWorkerGroup || !data.paymentMethod || !data.userName || !data.password)
        return;
      setLoading(true);
      const updatePensionBody = {
        providerName: data.pensionProvider,
        pensionRule: data.pensionRule,
        employeeContribution: 5,
        employerContribution: 3,
        defaultWorkerGroup: data.defaultWorkerGroup,
        payMethod: data.paymentMethod,
        username: data.userName,
        password: data.password,
        isDefault: data.isDefault,
        employerId: data.employerId
      };
      yield PensionAPI.updatePensionProviderSettings(provider.id, updatePensionBody);
      showMessage(polyglot.t("PensionModule.pensionConnected"), "success");
      setIsOpen(false);
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PensionModule.updateProvider") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("PensionModule.pensionProvider") }),
      formik.values.pensionProvider ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5 }, children: getPensionLogoByProviderName(formik.values.pensionProvider, 23) }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: (_h = PensionProvidersValueToLabel[formik.values.pensionProvider]) != null ? _h : formik.values.pensionProvider }) })
      ] }) : /* @__PURE__ */ jsx(EmptyCell, {})
    ] }),
    formik.values.pensionProvider && [PensionProviders.Nest, PensionProviders.SmartPension].includes(formik.values.pensionProvider) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap5, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("PensionModule.thisIsSalarySacrificePension"),
          name: "isDefault",
          checked: formik.values.pensionRule === SalarySacrifice.value,
          onChange: () => {
            if (formik.values.pensionRule !== SalarySacrifice.value)
              formik.setFieldValue("pensionRule", SalarySacrifice.value);
            else if (formik.values.pensionProvider === PensionProviders.Nest)
              formik.setFieldValue("pensionRule", ReliefAtSource.value);
            else formik.setFieldValue("pensionRule", NetPayArrangement.value);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        StyledTooltip,
        {
          title: /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", sx: themeFonts.tooltip, children: [
            polyglot.t("PensionModule.agreementBeforeEnrollment"),
            " ",
            /* @__PURE__ */ jsx(
              "a",
              {
                href: "https://www.zelt.app/post/the-employer-s-guide-to-pension-contributions",
                target: "_blank",
                rel: "noreferrer",
                children: polyglot.t("PensionModule.here")
              }
            )
          ] }),
          children: /* @__PURE__ */ jsx(Box, { sx: { cursor: "pointer" }, children: /* @__PURE__ */ jsx(InfoIcon, __spreadValues({}, iconSize)) })
        }
      )
    ] }) : /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "pensionRule",
        label: polyglot.t("PensionModule.pensionRule"),
        options: PensionRulesOptions,
        value: formik.values.pensionRule,
        compareValue: formik.values.pensionRule,
        onChange: formik.handleChange,
        error: !!formik.errors.pensionRule && formik.touched.pensionRule,
        helperText: formik.touched.pensionRule && formik.errors.pensionRule
      }
    ),
    formik.values.pensionProvider === PensionProviders.Nest && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employerId",
        label: polyglot.t("PensionModule.employerId"),
        value: formik.values.employerId,
        onChange: formik.handleChange,
        clearText: () => {
          formik.setFieldValue("employerId", "");
        },
        error: formik.touched.employerId && !!formik.errors.employerId,
        helperText: formik.touched.employerId && formik.errors.employerId
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "paymentMethod",
        label: polyglot.t("PensionModule.paymentMethod"),
        options: PaymentValues,
        value: formik.values.paymentMethod,
        compareValue: formik.values.paymentMethod,
        onChange: formik.handleChange,
        error: !!formik.errors.paymentMethod && formik.touched.paymentMethod,
        helperText: formik.touched.paymentMethod && formik.errors.paymentMethod
      }
    ),
    provider ? /* @__PURE__ */ jsx(
      SelectComponent,
      {
        label: polyglot.t("PensionModule.defaultWorkerGroup"),
        name: "defaultWorkerGroup",
        options: (_i = provider.workerGroups.map((wg) => ({ label: wg.name, value: wg.externalId }))) != null ? _i : [],
        value: formik.values.defaultWorkerGroup,
        onChange: formik.handleChange,
        error: formik.touched.defaultWorkerGroup && !!formik.errors.defaultWorkerGroup,
        helperText: formik.touched.defaultWorkerGroup && formik.errors.defaultWorkerGroup
      }
    ) : /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "defaultWorkerGroup",
        label: polyglot.t("PensionModule.defaultWorkerGroup"),
        value: formik.values.defaultWorkerGroup,
        onChange: formik.handleChange,
        error: formik.touched.defaultWorkerGroup && !!formik.errors.defaultWorkerGroup,
        helperText: formik.touched.defaultWorkerGroup && formik.errors.defaultWorkerGroup,
        disabled: !!provider
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "userName",
        label: polyglot.t("PensionModule.username"),
        value: formik.values.userName,
        onChange: formik.handleChange,
        error: formik.touched.userName && !!formik.errors.userName,
        helperText: formik.touched.userName && formik.errors.userName
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "password",
        label: polyglot.t("PensionModule.password"),
        value: formik.values.password,
        onChange: formik.handleChange,
        type: "password",
        error: formik.touched.password && !!formik.errors.password,
        helperText: formik.touched.password && formik.errors.password
      }
    ),
    (noOfActivePensionSchemes !== 0 || provider) && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "isDefault",
        label: polyglot.t("PensionModule.isDefault"),
        checked: formik.values.isDefault,
        onChange: formik.handleChange,
        disabled: !!(noOfActivePensionSchemes === 0 || (provider == null ? void 0 : provider.isDefault))
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.update"),
        loading,
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) });
};
