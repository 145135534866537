"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { ChipSelect } from "@v2/components/table/chip-select.component";
export const NumericYearSelectChip = ({ year, setYear, options }) => {
  var _a, _b;
  return /* @__PURE__ */ jsx(
    ChipSelect,
    {
      title: (_b = (_a = options == null ? void 0 : options.find((o) => o.year === year)) == null ? void 0 : _a.label) != null ? _b : String(year),
      options: (options != null ? options : [{ label: String(year), year }]).map((p) => ({
        label: p.label,
        handler: () => setYear(p.year)
      }))
    }
  );
};
export const LabelYearSelectChip = ({ year, setYear }) => {
  var _a, _b;
  const labelOptions = useMemo(
    () => [
      { label: "Last cycle", handler: () => setYear("last"), value: "last" },
      { label: "Current cycle", handler: () => setYear("current"), value: "current" },
      { label: "Next cycle", handler: () => setYear("next"), value: "next" }
    ],
    [setYear]
  );
  return /* @__PURE__ */ jsx(ChipSelect, { title: (_b = (_a = labelOptions == null ? void 0 : labelOptions.find((o) => o.value === year)) == null ? void 0 : _a.label) != null ? _b : year, options: labelOptions });
};
