"use strict";
import { jsx } from "react/jsx-runtime";
import { useContext, useState, createContext } from "react";
const UserProfileFormContext = createContext(false);
const UserProfileFormUpdateContext = createContext(() => {
});
export const useProfileForm = () => {
  return useContext(UserProfileFormContext);
};
export const useProfileFormUpdate = () => {
  return useContext(UserProfileFormUpdateContext);
};
export const UserProfileProvider = ({ children }) => {
  const [formCurrentlyEditing, setFormCurrentlyEditing] = useState(false);
  return /* @__PURE__ */ jsx(UserProfileFormContext.Provider, { value: formCurrentlyEditing, children: /* @__PURE__ */ jsx(UserProfileFormUpdateContext.Provider, { value: setFormCurrentlyEditing, children }) });
};
