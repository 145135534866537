"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { GridDisplayBarV2 } from "@v2/feature/growth/shared/components/grid-display-bar.component";
import { SurveyQuestionGridLine } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-question-grid-line.component";
import { SurveyQuestionDrawer } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-question-drawer.component";
export const SurveyResultsByQuestion = ({
  resultByQuestion,
  impactResult,
  growthFactors,
  measureByFilter,
  impactFilter,
  emptyStateMessage = "No questions available."
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const showImpact = useMemo(() => {
    return Boolean(impactResult && Object.keys(impactResult).length > 0);
  }, [impactResult]);
  const gridsXs = useMemo(() => {
    if (!showImpact) return [6, 4];
    return [6, 4, 2];
  }, [showImpact]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      GridDisplayBarV2,
      {
        sx: { mt: "16px" },
        cells: [
          {
            content: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Question" }),
            gridXs: gridsXs[0]
          },
          {
            content: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Score" }),
            gridXs: gridsXs[1]
          },
          ...showImpact ? [
            {
              content: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Impact" }),
              gridXs: gridsXs[2]
            }
          ] : []
        ]
      },
      "header-by-question"
    ),
    resultByQuestion.length > 0 ? resultByQuestion.map((item) => {
      return /* @__PURE__ */ jsx(
        SurveyQuestionGridLine,
        {
          item,
          growthFactors,
          impactResult,
          impactFilter,
          measureByFilter,
          gridsXs,
          setIsOpen,
          setSelectedQuestion
        },
        item.question.id
      );
    }) : /* @__PURE__ */ jsx(EmptyState, { emptyStateMessage }),
    /* @__PURE__ */ jsx(
      SurveyQuestionDrawer,
      {
        isOpen,
        setIsOpen,
        onClose: () => {
          setSelectedQuestion(null);
        },
        growthFactors,
        questionItem: selectedQuestion,
        impactResult,
        impactFilter,
        measureByFilter,
        gridsXs
      }
    )
  ] });
};
const EmptyState = ({ emptyStateMessage }) => {
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      cells: [
        {
          content: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignContent: "center", justifyContent: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: emptyStateMessage }) }),
          gridXs: 12
        }
      ]
    },
    "empty-state"
  );
};
