"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { InfoModal } from "@v2/components/info-modal.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { mergeDevicePolicies } from "@v2/feature/device/device.dto";
import { DevicePolicyType, SupportedDeviceType } from "@v2/feature/device/device.interface";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DevicesSettingsCard } from "@/v2/feature/device/features/devices-settings/devices-setings-card.component";
import { NoAppliedPolicies } from "@/v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { StyledFormCheckbox } from "@/v2/styles/checkbox.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import {
  buttonBoxSx,
  cardListSx,
  cardSubtitleSx,
  cardTitleIconSx,
  fieldLabelSx,
  helperTextSx,
  settingsfieldSx
} from "@/v2/styles/settings.styles";
export const DevicesMDMSettings = () => {
  var _a, _b, _c, _d, _e;
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [editPoliciesCard, setEditPoliciesCard] = useState(false);
  const [unchangedAppliedPolicies, setUnchangedAppliedPolicies] = useState(NoAppliedPolicies);
  const [windowsAppliedPolicies, setWindowsAppliedPolicies] = useState(NoAppliedPolicies);
  const [appliedPolicies, setAppliedPolicies] = useState(NoAppliedPolicies);
  const [passCodePolicies, setPassCodePolicies] = useState([]);
  const [firewallPolicies, setFirewallPolicies] = useState([]);
  const [osUpdatesPolicies, setOsUpdatesPolicies] = useState([]);
  const [encryptionPolicies, setEncryptionPolicies] = useState([]);
  const [appsPolicies, setAppsPolicies] = useState([]);
  const [allPolicies, setAllPolicies] = useState([]);
  const handlePolicyChange = (policyType) => (event) => {
    setWindowsAppliedPolicies((prev) => __spreadProps(__spreadValues({}, prev), {
      [policyType]: [Number(event.target.value)]
    }));
  };
  const handleMandatoryAppsChange = useCallback(
    (policyId) => (event) => {
      if (event.target.checked) {
        setWindowsAppliedPolicies((prev) => __spreadProps(__spreadValues({}, prev), { apps: [...windowsAppliedPolicies.apps, policyId] }));
      } else {
        setWindowsAppliedPolicies((prev) => {
          var _a2;
          return __spreadProps(__spreadValues({}, prev), {
            apps: (_a2 = windowsAppliedPolicies.apps) == null ? void 0 : _a2.filter((id) => id !== policyId)
          });
        });
      }
    },
    [windowsAppliedPolicies.apps]
  );
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [policies, appliedPolicies2] = yield Promise.all([
        DeviceAPI.getAvailableCompanyDevicePolicies(),
        DeviceAPI.getAppliedCompanyDevicePolicies()
      ]);
      setUnchangedAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setWindowsAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setAllPolicies(policies);
      setPassCodePolicies([
        {
          id: 0,
          name: "None",
          type: DevicePolicyType.passcode,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.windows
        },
        ...policies.filter((policy) => {
          return policy.type === DevicePolicyType.passcode && policy.supportedDeviceType.toString() === SupportedDeviceType.windows.toString();
        })
      ]);
      setEncryptionPolicies([
        {
          id: 0,
          name: "Disabled",
          type: DevicePolicyType.encryption,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.windows
        },
        ...policies.filter((policy) => {
          return policy.type === DevicePolicyType.encryption && policy.supportedDeviceType.toString() === SupportedDeviceType.windows.toString();
        })
      ]);
      setAppsPolicies([
        ...policies.filter((policy) => policy.type === DevicePolicyType.apps && !policy.isInhouseMdm && !policy.hide)
      ]);
      setFirewallPolicies([
        {
          id: 0,
          name: "Disabled",
          type: DevicePolicyType.firewall,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.windows
        },
        ...policies.filter((policy) => {
          return policy.type === DevicePolicyType.firewall && policy.supportedDeviceType.toString() === SupportedDeviceType.windows.toString();
        })
      ]);
      setOsUpdatesPolicies([
        {
          id: 0,
          name: "None",
          type: DevicePolicyType.updates,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.windows
        },
        ...policies && policies.length > 0 ? policies.filter((policy) => {
          return policy.type === DevicePolicyType.updates && policy.supportedDeviceType.toString() === SupportedDeviceType.windows.toString();
        }) : []
      ]);
    } catch (error) {
      showMessage("Something went wrong. Could not load the company policies.", "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const passcodePoliciesOptions = passCodePolicies.filter((policy) => policy.externalId !== 1).map((policy) => ({
    value: policy.id,
    label: policy.name.includes("Basic") ? `${policy.name} (Recommended)` : policy.name,
    description: policy.description
  })).sort((a, b) => {
    var _a2, _b2;
    const sortValue = {
      None: 4,
      "Basic (Recommended)": 1,
      Moderate: 2,
      Strict: 3
    };
    return ((_a2 = sortValue[a.label]) != null ? _a2 : 0) - ((_b2 = sortValue[b.label]) != null ? _b2 : 0);
  });
  const selectedPasscodePolicy = (_a = passcodePoliciesOptions.find(
    (policy) => windowsAppliedPolicies[DevicePolicyType.passcode].includes(policy.value)
  )) != null ? _a : passcodePoliciesOptions.find((value) => value.value === 0);
  const encryptionPoliciesOptions = encryptionPolicies.map((policy) => ({
    value: policy.id,
    label: policy.name,
    description: policy.description
  }));
  const selectedEncryptionPolicy = (_b = encryptionPoliciesOptions.find(
    (policy) => windowsAppliedPolicies[DevicePolicyType.encryption].includes(policy.value)
  )) != null ? _b : encryptionPoliciesOptions.find((value) => value.value === 0);
  const firewallPoliciesOptions = firewallPolicies.map((policy) => ({
    value: policy.id,
    label: policy.name,
    description: policy.description
  }));
  const osUpdatesPoliciesOptions = osUpdatesPolicies.map((policy) => ({
    value: policy.id,
    label: policy.name,
    description: policy.description
  })).sort((a, b) => {
    var _a2, _b2;
    const sortValue = {
      None: 4,
      "Notify only": 1,
      "Auto install updates and remind to restart if required": 2,
      "Auto install and force restart": 3
    };
    return ((_a2 = sortValue[a.label]) != null ? _a2 : 0) - ((_b2 = sortValue[b.label]) != null ? _b2 : 0);
  });
  const selectedFirewallPolicy = (_c = firewallPoliciesOptions.find(
    (policy) => windowsAppliedPolicies[DevicePolicyType.firewall].includes(policy.value)
  )) != null ? _c : firewallPoliciesOptions.find((value) => value.value === 0);
  const selectedOsUpdatesPolicy = (_d = osUpdatesPoliciesOptions.find(
    (policy) => windowsAppliedPolicies[DevicePolicyType.updates].includes(policy.value)
  )) != null ? _d : osUpdatesPoliciesOptions.find((value) => value.value === 0);
  const columns = useMemo(
    () => [
      {
        header: () => "App",
        id: "name",
        size: 120,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Fragment, { children: original.name })
      },
      {
        header: () => " ",
        id: "id",
        size: 10,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m10, justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
            StyledFormCheckbox,
            {
              checked: (_a2 = windowsAppliedPolicies.apps) == null ? void 0 : _a2.includes(original.id),
              onChange: handleMandatoryAppsChange(original.id),
              inputProps: { "aria-label": "controlled" },
              disabled: !editPoliciesCard
            }
          ) });
        }
      }
    ],
    [windowsAppliedPolicies.apps, editPoliciesCard, handleMandatoryAppsChange]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Devices" }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      editPoliciesCard ? /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "100%",
              maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" },
              paddingBottom: spacing.p60
            },
            children: [
              /* @__PURE__ */ jsx(Box, { sx: cardTitleIconSx, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Security" }) }),
              /* @__PURE__ */ jsx(Typography, { sx: cardSubtitleSx, children: "Configure security settings for Windows devices connected to Hexnode MDM." }),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap30 }, children: [
                /* @__PURE__ */ jsxs(Box, { sx: settingsfieldSx, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: fieldLabelSx, children: "Passcode" }),
                  /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, cardListSx), { position: "relative" }), children: [
                    /* @__PURE__ */ jsx(
                      SelectComponent,
                      {
                        name: "passcode",
                        label: "",
                        options: passcodePoliciesOptions,
                        value: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.value,
                        compareValue: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.value,
                        onChange: handlePolicyChange(DevicePolicyType.passcode),
                        disabled: !editPoliciesCard
                      }
                    ),
                    (selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: helperTextSx, color: themeColors.Grey, children: selectedPasscodePolicy.description })
                  ] })
                ] }),
                /* @__PURE__ */ jsxs(Box, { sx: settingsfieldSx, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: fieldLabelSx, children: "Encryption" }),
                  /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, cardListSx), { position: "relative" }), children: [
                    /* @__PURE__ */ jsx(
                      SelectComponent,
                      {
                        name: "encryption",
                        label: "",
                        options: encryptionPoliciesOptions,
                        value: selectedEncryptionPolicy == null ? void 0 : selectedEncryptionPolicy.value,
                        onChange: handlePolicyChange(DevicePolicyType.encryption),
                        disabled: !editPoliciesCard
                      }
                    ),
                    (selectedEncryptionPolicy == null ? void 0 : selectedEncryptionPolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: helperTextSx, color: themeColors.Grey, children: selectedEncryptionPolicy.description })
                  ] })
                ] }),
                /* @__PURE__ */ jsxs(Box, { sx: settingsfieldSx, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: fieldLabelSx, children: "Firewall" }),
                  /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, cardListSx), { position: "relative" }), children: [
                    /* @__PURE__ */ jsx(
                      SelectComponent,
                      {
                        name: "firewall",
                        label: "",
                        options: firewallPoliciesOptions,
                        value: selectedFirewallPolicy == null ? void 0 : selectedFirewallPolicy.value,
                        compareValue: selectedFirewallPolicy == null ? void 0 : selectedFirewallPolicy.value,
                        onChange: handlePolicyChange(DevicePolicyType.firewall),
                        disabled: !editPoliciesCard
                      }
                    ),
                    (selectedFirewallPolicy == null ? void 0 : selectedFirewallPolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: helperTextSx, color: themeColors.Grey, children: selectedFirewallPolicy.description })
                  ] })
                ] }),
                /* @__PURE__ */ jsxs(Box, { sx: settingsfieldSx, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: fieldLabelSx, children: "OS updates" }),
                  /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, cardListSx), { position: "relative" }), children: [
                    /* @__PURE__ */ jsx(
                      SelectComponent,
                      {
                        name: "osUpdates",
                        label: "",
                        options: osUpdatesPoliciesOptions,
                        value: (_e = selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.value) != null ? _e : 0,
                        compareValue: selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.value,
                        onChange: handlePolicyChange(DevicePolicyType.updates),
                        disabled: !editPoliciesCard
                      }
                    ),
                    (selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: helperTextSx, color: themeColors.Grey, children: selectedOsUpdatesPolicy.description })
                  ] })
                ] })
              ] })
            ]
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "100%",
              maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" },
              paddingBottom: spacing.p60
            },
            children: [
              /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, cardTitleIconSx), { position: "relative" }), children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Managed Apps" }) }) }),
              /* @__PURE__ */ jsx(Typography, { sx: cardSubtitleSx, children: "Managed Apps contain sensitive company information and require more control. Selected apps will install automatically on your connected devices. If any apps are missing, device will be flagged as non-compliant and reinstall will be attempted every 10 minutes. Managed apps are automatically updated every 24 hours." }),
              /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(BasicTable, { rowData: appsPolicies, columnData: columns, hidePagination: true }) }),
              /* @__PURE__ */ jsx(Box, { children: editPoliciesCard && /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: __spreadProps(__spreadValues({}, buttonBoxSx), {
                    display: "flex",
                    maxWidth: "400px",
                    width: "auto",
                    mt: spacing.mt40,
                    gap: spacing.gap10
                  }),
                  children: [
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        fullWidth: true,
                        sizeVariant: "medium",
                        colorVariant: "secondary",
                        onClick: () => {
                          setEditPoliciesCard(false);
                          setWindowsAppliedPolicies(unchangedAppliedPolicies);
                        },
                        children: "Cancel"
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      LoaderButton,
                      {
                        name: "Save",
                        loading,
                        fullWidth: true,
                        colorVariant: "primary",
                        sizeVariant: "medium",
                        onClick: () => __async(void 0, null, function* () {
                          try {
                            const relevantPolicyIds = new Set(
                              allPolicies.filter(
                                (policy) => policy.supportedDeviceType.toString() === SupportedDeviceType.windows.toString()
                              ).map((policy) => policy.id)
                            );
                            for (const key of Object.values(DevicePolicyType)) {
                              appliedPolicies[key] = appliedPolicies[key].filter(
                                (value) => !relevantPolicyIds.has(value)
                              );
                            }
                            let updatedPolicies = mergeDevicePolicies(windowsAppliedPolicies, appliedPolicies);
                            yield DeviceAPI.updateCompanyDeviceAppliedPolicies(updatedPolicies);
                            setEditPoliciesCard(false);
                            showMessage("Policies successfully saved.", "success");
                            setIsInfoModalOpen(true);
                            yield refresh();
                          } catch (e) {
                            showMessage("The new policies could not be saved. Please try again.", "error");
                          }
                        })
                      }
                    )
                  ]
                }
              ) })
            ]
          }
        )
      ] }) : /* @__PURE__ */ jsx(
        DevicesSettingsCard,
        {
          setEditPoliciesCard,
          selectedPasscodePolicy,
          selectedEncryptionPolicy,
          selectedFirewallPolicy,
          selectedOsUpdatesPolicy,
          restrictions: { disableEnrollDevices: false, disableApps: false },
          appsPolicies: appsPolicies.filter((app) => windowsAppliedPolicies.apps.includes(app.id))
        }
      ),
      /* @__PURE__ */ jsx(InfoModal, { isOpen: isInfoModalOpen, title: "Changes saved", onClose: () => setIsInfoModalOpen(false), children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Your changes have been applied to all devices, however it may take sometime to show here. Check back in a few hours." }) })
    ] })
  ] });
};
