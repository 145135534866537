"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ChipComponentWithIcon } from "@v2/components/chip/chip-with-icon.component";
import { ChipComponent } from "@v2/components/chip/chip.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/table.styles";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { Typography } from "@/v2/components/typography/typography.component";
export const DevicePolicyTile = ({
  policyName,
  settingsNumber,
  status,
  onClick
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "600px",
        height: "108px",
        padding: "16px",
        gap: "32px",
        borderRadius: "8px",
        border: "1px solid #F1EEEA",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        opacity: 1,
        boxSizing: "border-box",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f5f5f5"
        }
      },
      onClick,
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: policyName }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: [
          /* @__PURE__ */ jsx(
            ChipComponent,
            {
              name: `${settingsNumber} setting${settingsNumber > 1 ? "s" : ""}`,
              backgroundColor: "white",
              textColor: "DarkGrey",
              textVariant: "caption"
            }
          ),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center"
              },
              children: /* @__PURE__ */ jsx(
                ChipComponentWithIcon,
                {
                  name: status === "Installed" ? polyglot.t("DevicePolicyTile.installed") : polyglot.t("DevicePolicyTile.missing"),
                  backgroundColor: "white",
                  textColor: "DarkGrey",
                  icon: status === "Installed" ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize)),
                  border: "light",
                  textVariant: "caption"
                }
              )
            }
          )
        ] })
      ]
    }
  );
};
