"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GridDisplayBarV2 = ({
  cells,
  onClick,
  sx = {}
}) => {
  return /* @__PURE__ */ jsx(
    Grid,
    {
      container: true,
      spacing: 2,
      sx: __spreadValues({
        paddingY: spacing.p16,
        borderBottom: borders.background,
        "&:hover": {
          background: onClick ? themeColors.TableHover : "none"
        },
        cursor: onClick ? "pointer" : "default"
      }, sx),
      alignItems: "center",
      onClick,
      children: cells.map((cell, index) => {
        var _a, _b;
        return /* @__PURE__ */ jsx(Grid, { item: true, xs: cell.gridXs, sx: (_a = cell.sx) != null ? _a : {}, children: (_b = cell.content) != null ? _b : null }, index);
      })
    }
  );
};
