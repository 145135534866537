"use strict";
export var PayrunPensionStates = /* @__PURE__ */ ((PayrunPensionStates2) => {
  PayrunPensionStates2["notSubmitted"] = "notSubmitted";
  PayrunPensionStates2["submitted"] = "submitted";
  PayrunPensionStates2["markedAsSent"] = "markedAsSent";
  return PayrunPensionStates2;
})(PayrunPensionStates || {});
export var EarningsType = /* @__PURE__ */ ((EarningsType2) => {
  EarningsType2["qualifying"] = "Qualifying Earnings";
  EarningsType2["custom"] = "Custom";
  EarningsType2["unbanded"] = "Unbanded";
  return EarningsType2;
})(EarningsType || {});
export var ExternalProviderConversationStatuses = /* @__PURE__ */ ((ExternalProviderConversationStatuses2) => {
  ExternalProviderConversationStatuses2["Queued"] = "Queued";
  ExternalProviderConversationStatuses2["Sent"] = "Sent";
  ExternalProviderConversationStatuses2["Failed"] = "Failed";
  ExternalProviderConversationStatuses2["Processing"] = "Processing";
  return ExternalProviderConversationStatuses2;
})(ExternalProviderConversationStatuses || {});
export var EmployeePensionEnrollmentStatus = /* @__PURE__ */ ((EmployeePensionEnrollmentStatus2) => {
  EmployeePensionEnrollmentStatus2["Enrolled"] = "Enrolled";
  EmployeePensionEnrollmentStatus2["NotEnrolled"] = "Not Enrolled";
  return EmployeePensionEnrollmentStatus2;
})(EmployeePensionEnrollmentStatus || {});
export var StaffologyEmployeeStates = /* @__PURE__ */ ((StaffologyEmployeeStates2) => {
  StaffologyEmployeeStates2["Automatic"] = "Automatic";
  StaffologyEmployeeStates2["OptOut"] = "OptOut";
  StaffologyEmployeeStates2["OptIn"] = "OptIn";
  StaffologyEmployeeStates2["VoluntaryJoiner"] = "VoluntaryJoiner";
  StaffologyEmployeeStates2["ContractualPension"] = "ContractualPension";
  StaffologyEmployeeStates2["CeasedMembership"] = "CeasedMembership";
  StaffologyEmployeeStates2["Leaver"] = "Leaver";
  StaffologyEmployeeStates2["Excluded"] = "Excluded";
  StaffologyEmployeeStates2["Enrol"] = "Enrol";
  StaffologyEmployeeStates2["NonEligible"] = "NonEligible";
  StaffologyEmployeeStates2["AgeMissing"] = "AgeMissing";
  StaffologyEmployeeStates2["SalaryMissing"] = "SalaryMissing";
  return StaffologyEmployeeStates2;
})(StaffologyEmployeeStates || {});
export var LeavingEmployeeStates = /* @__PURE__ */ ((LeavingEmployeeStates2) => {
  LeavingEmployeeStates2["OptOut"] = "OptOut" /* OptOut */;
  LeavingEmployeeStates2["CeasedMembership"] = "CeasedMembership" /* CeasedMembership */;
  LeavingEmployeeStates2["Leaver"] = "Leaver" /* Leaver */;
  LeavingEmployeeStates2["Excluded"] = "Excluded" /* Excluded */;
  return LeavingEmployeeStates2;
})(LeavingEmployeeStates || {});
