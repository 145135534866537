"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { StepperComponent } from "@v2/components/stepper.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceActivationStepActivation } from "@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-activation.component";
import { DeviceActivationStepCharger } from "@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-charger.component";
import { DeviceActivationStepCredentials } from "@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-credentials.component";
import { DeviceActivationStepPackaging } from "@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-packaging.component";
import { DeviceActivationStepTLC } from "@v2/feature/device/features/my-devices/pages/device-activation/components/device-activation-step-tlc.component";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
var ActivateDeviceSteps = /* @__PURE__ */ ((ActivateDeviceSteps2) => {
  ActivateDeviceSteps2["Activation"] = "Activation";
  ActivateDeviceSteps2["Credentials"] = "Credentials";
  ActivateDeviceSteps2["Packaging"] = "Packaging";
  ActivateDeviceSteps2["Charger"] = "Charger";
  ActivateDeviceSteps2["TLC"] = "TLC";
  return ActivateDeviceSteps2;
})(ActivateDeviceSteps || {});
const ACTIVATE_DEVICE_STEPS = [
  "Activation" /* Activation */,
  "Credentials" /* Credentials */,
  "Packaging" /* Packaging */,
  "Charger" /* Charger */,
  "TLC" /* TLC */
];
export const ConfirmDeviceOrderDeliveryStepper = () => {
  const params = useParams();
  const orderId = Number(params.orderId);
  const [showMessage] = useMessage();
  const [deviceOrder, setDeviceOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("Activation" /* Activation */);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const deviceOrder2 = yield DeviceAPI.getDeviceOrderDetails(orderId);
        setDeviceOrder(deviceOrder2);
      } catch (error) {
        showMessage(`Could not load device order. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [orderId, showMessage]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Device activation" })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(StepperComponent, { steps: ACTIVATE_DEVICE_STEPS, activeStep: step }) }),
      deviceOrder && /* @__PURE__ */ jsx(Box, { children: getStepContent(deviceOrder, step, setStep) })
    ] })
  ] });
};
const getStepContent = (deviceOrder, step, setStep) => {
  switch (step) {
    case "Activation" /* Activation */:
      return /* @__PURE__ */ jsx(
        DeviceActivationStepActivation,
        {
          deviceOrder,
          goToNextStep: () => setStep("Credentials" /* Credentials */)
        }
      );
    case "Credentials" /* Credentials */:
      return /* @__PURE__ */ jsx(
        DeviceActivationStepCredentials,
        {
          deviceOrder,
          goToNextStep: () => setStep("Packaging" /* Packaging */)
        }
      );
    case "Packaging" /* Packaging */:
      return /* @__PURE__ */ jsx(DeviceActivationStepPackaging, { goToNextStep: () => setStep("Charger" /* Charger */) });
    case "Charger" /* Charger */:
      return /* @__PURE__ */ jsx(DeviceActivationStepCharger, { goToNextStep: () => setStep("TLC" /* TLC */) });
    case "TLC" /* TLC */:
      return /* @__PURE__ */ jsx(DeviceActivationStepTLC, { deviceOrder });
    default:
      return /* @__PURE__ */ jsx(
        DeviceActivationStepActivation,
        {
          deviceOrder,
          goToNextStep: () => setStep("Credentials" /* Credentials */)
        }
      );
  }
};
