"use strict";
import { eachDayOfInterval, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
const getDaysInAWeekOptions = { weekStartsOn: 1 };
export function Z_startOfWeek(d) {
  return startOfWeek(d, getDaysInAWeekOptions);
}
export function Z_endOfWeek(d) {
  return endOfWeek(d, getDaysInAWeekOptions);
}
export function getWeekDays(d) {
  return eachDayOfInterval({ start: Z_startOfWeek(d), end: Z_endOfWeek(d) });
}
export function getMonthDays(d) {
  return eachDayOfInterval({ start: startOfMonth(d), end: endOfMonth(d) });
}
