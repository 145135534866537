"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from "react";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { downloadFileByUrl, isPreviewableFile } from "@v2/feature/documents/documents.util";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as PinnedDoc } from "@/images/documents/PinnedBlack.svg";
import { ReactComponent as MissingDoc } from "@/images/documents/PinnedMissingDoc.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { checkScopes } from "@/lib/scopes";
import { NavigationButtons } from "@/v2/components/navigation-buttons.component";
import { StyledMenu } from "@/v2/components/theme-components/styled-menu.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { truncateWithEllipses } from "@/v2/util/string.util";
import "swiper/swiper-bundle.css";
const SwiperHeader = ({ children, hideNavigation }) => {
  const swiper = useSwiper();
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", alignItems: "center", justifyContent: "space-between" }, spacing.mb20), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { whiteSpace: "nowrap", color: themeColors.DarkGrey }), children }),
    !hideNavigation && /* @__PURE__ */ jsx(NavigationButtons, { onNext: () => swiper.slideNext(), onBack: () => swiper.slidePrev() })
  ] });
};
const PinnedCard = ({
  pinnedDocument,
  onEditClick,
  onPreviewClick,
  documentTypes,
  personalDocuments
}) => {
  var _a, _b, _c, _d, _e;
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext(state.user);
  const reporteeScopeContext = {
    userId: pinnedDocument.belongsTo && ((_a = pinnedDocument.belongsTo) == null ? void 0 : _a.length) === 1 ? (_c = (_b = pinnedDocument.belongsTo[0]) == null ? void 0 : _b.User) == null ? void 0 : _c.userId : 0
  };
  const belongsToOnlyYou = pinnedDocument.belongsTo && pinnedDocument.belongsTo.length === 1 && pinnedDocument.belongsTo[0].User.userId === state.user.userId;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const attachmentExists = pinnedDocument.attachments && pinnedDocument.attachments.length && ((_d = pinnedDocument.attachments[0]) == null ? void 0 : _d.fileUuid) || pinnedDocument.fileUuid;
  const stdUserCan = state.user.userId === pinnedDocument.uploadedBy || belongsToOnlyYou;
  const hasScopesAll = hasScopes(["documents:all"], scopesContext);
  const hasScopesManager = checkScopes(state.user, ["documents"], scopesContext);
  const categoriesNotEditableByManager = ["Company Documents"];
  const belongsToNone = ((_e = pinnedDocument.belongsTo) != null ? _e : []).length === 0;
  const documentCategoriesEditableByManager = documentTypes.filter((d) => !categoriesNotEditableByManager.includes(d.category)).map((d) => d.value);
  const documentCategoriesEditableByStandardUser = documentTypes.filter((eachDocumentType) => eachDocumentType.canUserAdd).map((d) => d.value);
  const isEditableOrRemovableWithoutAttactment = hasScopesAll || !personalDocuments && hasScopesManager && [...documentCategoriesEditableByManager].includes(pinnedDocument.type) && !belongsToNone || documentCategoriesEditableByStandardUser.includes(pinnedDocument.type) && belongsToOnlyYou;
  const isEditableOrRemovableWithAttactment = attachmentExists && !pinnedDocument.contractId && hasScopes(["documents:all"], scopesContext) || stdUserCan || hasScopes(["documents:manager"], reporteeScopeContext);
  const options = [
    ...attachmentExists ? [
      {
        icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          if ((pinnedDocument == null ? void 0 : pinnedDocument.fileUuid) && typeof pinnedDocument.fileUuid === "string") {
            yield DocumentAPI.downloadViaUuid(pinnedDocument == null ? void 0 : pinnedDocument.fileUuid).then((url) => {
              downloadFileByUrl(url, pinnedDocument.fileName);
            });
          }
        }),
        label: "Download"
      }
    ] : [],
    ...!attachmentExists && isEditableOrRemovableWithoutAttactment || attachmentExists && isEditableOrRemovableWithAttactment ? [
      {
        icon: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize)),
        handler: () => {
          onEditClick(pinnedDocument);
        },
        label: "Edit"
      }
    ] : [],
    ...attachmentExists && isPreviewableFile(pinnedDocument) ? [
      {
        icon: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)),
        handler: () => {
          onPreviewClick(pinnedDocument);
        },
        label: "Preview"
      }
    ] : []
  ];
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadProps(__spreadValues({
          display: "flex",
          alignItems: "flex-start",
          gap: spacing.g10,
          cursor: "pointer",
          borderRadius: "10px"
        }, themeFonts.title2), {
          color: themeColors.DarkGrey,
          backgroundColor: themeColors.Background,
          "&:hover": {
            color: themeColors.BrownHover
          }
        }),
        onClick: (event) => {
          if (options.length > 0) {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
          }
        },
        children: [
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                width: "16px",
                height: "16px",
                mt: spacing.mt15,
                mb: spacing.mb15,
                ml: spacing.ml20
              },
              children: attachmentExists ? /* @__PURE__ */ jsx(
                PinnedDoc,
                {
                  style: {
                    width: "max-content",
                    height: "100%"
                  }
                }
              ) : /* @__PURE__ */ jsx(
                MissingDoc,
                {
                  style: {
                    width: "max-content",
                    height: "100%"
                  }
                }
              )
            }
          ),
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.title4), {
                whiteSpace: "nowrap",
                color: themeColors.DarkGrey,
                mt: spacing.mt20,
                mb: spacing.mb15
              }),
              children: truncateWithEllipses(pinnedDocument.name, 20)
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      StyledMenu,
      {
        id: `btnMenuOptions`,
        MenuListProps: {
          "aria-labelledby": "btnMenuOptions"
        },
        anchorEl,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        transformOrigin: { horizontal: "right", vertical: "top" },
        open,
        onClose: () => setAnchorEl(null),
        children: options && options.filter((option) => !option.hidden).map((option, i) => /* @__PURE__ */ jsxs(
          MenuItem,
          {
            id: `btn${option.label}_${i}`,
            onClick: (e) => {
              option.handler();
              setAnchorEl(null);
              e.stopPropagation();
            },
            disableRipple: true,
            disabled: (option == null ? void 0 : option.disabled) || false,
            sx: { marginTop: i > 0 ? "10px" : 0 },
            children: [
              option.icon,
              option.label
            ]
          },
          option.label
        ))
      }
    )
  ] });
};
export const PinnedDocument = ({
  pinnedDocuments,
  onEditClick,
  onPreviewClick,
  documentTypes,
  personalDocuments = false
}) => {
  const swiperRef = useRef(null);
  const [hideNavigation, setHideNavigation] = useState(false);
  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      const checkOverflow = () => {
        setHideNavigation(swiperInstance.isEnd);
      };
      swiperInstance.on("resize", checkOverflow);
      checkOverflow();
      return () => {
        swiperInstance.off("resize", checkOverflow);
      };
    }
  }, []);
  return /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
    Swiper,
    {
      ref: swiperRef,
      freeMode: true,
      modules: [FreeMode],
      onSwiper: (swiper) => swiperRef.current = swiper,
      breakpoints: {
        360: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        361: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        390: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        481: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        1500: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        1900: {
          slidesPerView: 5,
          spaceBetween: 10
        },
        2200: {
          slidesPerView: 6,
          spaceBetween: 10
        },
        2400: {
          slidesPerView: 7,
          spaceBetween: 10
        },
        2600: {
          slidesPerView: 8,
          spaceBetween: 10
        }
      },
      style: { display: "flex", flexDirection: "column-reverse" },
      children: [
        /* @__PURE__ */ jsx(SwiperHeader, { hideNavigation, children: "" }),
        pinnedDocuments.map((pinnedDocument, index) => /* @__PURE__ */ jsx(SwiperSlide, { children: /* @__PURE__ */ jsx(
          PinnedCard,
          {
            pinnedDocument,
            onEditClick,
            onPreviewClick,
            documentTypes,
            personalDocuments
          }
        ) }, index))
      ]
    }
  ) });
};
