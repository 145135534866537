"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box, FormControl, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { UserRoleAPI } from "@/api-client/user-role.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { DepartmentFormModal } from "@/v2/feature/department/department-settings/components/department-form-modal.component";
import { JobPositionAddEditFormDrawer } from "@/v2/feature/job-position/job-position-settings/features/components/job-position-setting-add-edit-form-drawer.component";
import { SiteFormModal } from "@/v2/feature/site/site-settings/features/components/site-form-modal.component";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import {
  drawerContentSx,
  editDeleteHeaderSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { getRoleLastChangeTypeOptions } from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const RoleSchemaValues = {
  jobPositionId: Yup.number().integer().typeError("Job title is a required field").required("Required"),
  managerId: Yup.number().nullable().notRequired(),
  departmentId: Yup.number().nullable().notRequired(),
  effectiveDate: Yup.string().test(dateFieldTest).notRequired(),
  lastChangeType: Yup.string().nullable().notRequired(),
  lastChangeReason: Yup.string().nullable().optional(),
  siteId: Yup.number().nullable().optional()
};
export const RoleSchema = Yup.object().shape(RoleSchemaValues);
export const RoleSchemaForImport = Yup.object().shape(__spreadProps(__spreadValues({}, RoleSchemaValues), {
  jobTitle: Yup.string().notRequired(),
  jobPositionId: Yup.number().nullable().notRequired()
}));
export const RoleForm = ({
  initialValues,
  userId,
  managers,
  departments,
  sites,
  jobPositions,
  onSubmit,
  onRefresh,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  },
  refreshSites,
  refreshDept,
  refreshJobPosition,
  defaultJobPosition
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const rowModalMode = initialValues && "id" in initialValues ? "edit" : "add";
  const [siteDrawerOpen, setSiteDrawerOpen] = useState(false);
  const [departmentDrawerOpen, setDepartmentDrawerOpen] = useState(false);
  const [jobDrawerOpen, setJobDrawerOpen] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const scopesContext = { userId: user.userId };
  const defaultBlankModalValues = useMemo(
    () => ({
      id: 0,
      jobTitle: "",
      jobPositionId: null,
      departmentId: void 0,
      managerId: void 0,
      effectiveDate: new LocalDate().toDateString(),
      lastChangeType: void 0,
      lastChangeReason: "",
      siteId: void 0,
      customUpdates: []
    }),
    []
  );
  const formik = useFormik({
    initialValues: initialValues != null ? initialValues : defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: RoleSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if ("id" in values) {
          const update = __spreadValues({}, values);
          if (values.id && values.id > 0) {
            yield UserRoleAPI.patch(userId, values.id, update);
          } else {
            yield UserRoleAPI.create(userId, update);
          }
          onSubmit(update);
          onRefresh();
          onClose();
          showMessage(polyglot.t("RoleForm.successMessages.update"), "success");
        } else {
          importHandler == null ? void 0 : importHandler(__spreadValues({}, values));
        }
      } catch (error) {
        showMessage(`${polyglot.t("RoleForm.errorMessages.delete")}: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const deleteRole = () => __async(void 0, null, function* () {
    try {
      if (usedForDataImport) {
        showMessage(polyglot.t("RoleForm.errorMessages.deleteFlow"), "error");
        return;
      }
      if (deleteCandidate) {
        yield UserRoleAPI.deleteById(userId, deleteCandidate == null ? void 0 : deleteCandidate.id);
        showMessage(polyglot.t("RoleForm.successMessages.delete"), "success");
        onRefresh();
        onClose();
      }
    } catch (error) {
      showMessage(polyglot.t("RoleForm.errorMessages.delete"), "error");
    }
  });
  const currentJobPositionValue = useMemo(() => {
    return jobPositions.find((option) => option.value === Number(formik.values.jobPositionId));
  }, [formik.values.jobPositionId, jobPositions]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    rowModalMode === "add" ? /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("RoleForm.new") }) : /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, editDeleteHeaderSx), { display: "flex", justifyContent: "space-between", width: "100%" }), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("RoleForm.edit") }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: iconCTAButtonSx,
          onClick: (event) => {
            if (!("id" in formik.values)) return;
            setAnchorEl(event.currentTarget);
            setIsRemovalModalOpen(true);
            setDeleteCandidate(formik.values);
          },
          disabled: usedForDataImport,
          children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "role.effectiveDate", children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_a = formik.values.effectiveDate) != null ? _a : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("effectiveDate", value);
            }
          },
          name: "effectiveDate",
          label: polyglot.t("RoleForm.effectiveDate"),
          error: !!formik.errors.effectiveDate && formik.touched.effectiveDate,
          helperText: formik.errors.effectiveDate && formik.touched.effectiveDate
        }
      ) }),
      jobPositions && /* @__PURE__ */ jsx(ProfileField, { fieldStub: "role.jobPositionId", children: /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "jobPositionId",
          label: polyglot.t("RoleForm.jobTitle"),
          options: jobPositions,
          value: currentJobPositionValue,
          compareValue: formik.values.jobPositionId,
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("jobPositionId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          error: formik.touched.jobPositionId && Boolean(formik.errors.jobPositionId),
          helperText: formik.touched.jobPositionId && formik.errors.jobPositionId,
          editList: {
            handler: () => setJobDrawerOpen(true),
            isHidden: false
          }
        }
      ) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "role.department", children: /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "departmentId",
          label: polyglot.t("RoleForm.departmentId"),
          options: departments,
          value: departments.find(({ value }) => value === formik.values.departmentId),
          compareValue: (_b = formik.values.departmentId) != null ? _b : "",
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("departmentId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          error: formik.touched.departmentId && Boolean(formik.errors.departmentId),
          helperText: formik.touched.departmentId && formik.errors.departmentId,
          editList: {
            handler: () => setDepartmentDrawerOpen(true),
            isHidden: !checkScopes(user, ["company.settings:all"], scopesContext)
          }
        }
      ) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "role.site", children: /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "siteId",
          label: polyglot.t("RoleForm.siteId"),
          options: sites,
          value: sites.find(({ value }) => value === formik.values.siteId),
          compareValue: (_c = formik.values.siteId) != null ? _c : "",
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("siteId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          error: formik.touched.siteId && Boolean(formik.errors.siteId),
          helperText: formik.touched.siteId && formik.errors.siteId,
          editList: {
            handler: () => setSiteDrawerOpen(true),
            isHidden: !checkScopes(user, ["company.settings:all"], scopesContext)
          }
        }
      ) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "role.manager", children: /* @__PURE__ */ jsx(
        SingleUserSelect,
        {
          name: "managerId",
          options: managers,
          onChange: (_, x) => {
            var _a2;
            return formik.setFieldValue("managerId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          value: formik.values.managerId,
          label: polyglot.t("RoleForm.managerId"),
          error: Boolean(formik.errors.managerId),
          helperText: formik.errors.managerId
        }
      ) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "role.lastChangeType", children: /* @__PURE__ */ jsx(FormControl, { fullWidth: true, error: !!formik.errors.lastChangeType, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "lastChangeType",
          label: polyglot.t("RoleForm.lastChangeType"),
          options: getRoleLastChangeTypeOptions(polyglot),
          value: formik.values.lastChangeType,
          compareValue: formik.values.lastChangeType,
          error: !!formik.errors.lastChangeType && formik.touched.lastChangeType,
          onChange: formik.handleChange,
          helperText: formik.errors.lastChangeType && formik.touched.lastChangeType
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "role.lastChangeReason", children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "lastChangeReason",
          label: polyglot.t("RoleForm.lastChangeReason"),
          value: formik.values.lastChangeReason,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.lastChangeReason && !!formik.errors.lastChangeReason,
          helperText: (_d = formik.touched.lastChangeReason && formik.errors.lastChangeReason) != null ? _d : " ",
          clearText: () => formik.setFieldValue("lastChangeReason", "")
        }
      ) }),
      /* @__PURE__ */ jsx(
        CustomFieldComponents,
        {
          values: formik.values.customUpdates,
          onChange: (values) => formik.setFieldValue("customUpdates", values),
          rowModalMode,
          formName: CustomProfileFormType.Role
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => setIsRemovalModalOpen(false),
        anchorEl,
        takeAction: deleteRole,
        message: polyglot.t("RoleForm.confirmDelete"),
        callToAction: polyglot.t("RoleForm.callToAction")
      }
    ),
    /* @__PURE__ */ jsx(
      SiteFormModal,
      {
        isOpen: siteDrawerOpen,
        setIsOpen: setSiteDrawerOpen,
        selectedSite: void 0,
        refreshSites: () => __async(void 0, null, function* () {
          if (refreshSites) yield refreshSites();
        }),
        closePage: () => setSiteDrawerOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(
      DepartmentFormModal,
      {
        isOpen: departmentDrawerOpen,
        setIsOpen: setDepartmentDrawerOpen,
        selectedDepartment: void 0,
        refreshDepartments: () => __async(void 0, null, function* () {
          if (refreshDept) yield refreshDept();
        }),
        closePage: () => setDepartmentDrawerOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(
      JobPositionAddEditFormDrawer,
      {
        isOpen: jobDrawerOpen,
        setIsOpen: setJobDrawerOpen,
        jobPositionToEdit: defaultJobPosition,
        onClose: () => setJobDrawerOpen(false),
        refreshJobPositions: () => __async(void 0, null, function* () {
          if (refreshJobPosition) yield refreshJobPosition();
        })
      }
    )
  ] }) });
};
