"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { sortDate, sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAllowanceType, AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { convertMinutesToClockHours, isHourlyPolicy } from "@v2/feature/absence/absence.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { ReactComponent as WaitingEmpty } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { ReactComponent as WaitingEmptyRed } from "@/images/side-bar-icons/WaitingEmptyRed.svg";
import { nestErrorMessage } from "@/lib/errors";
const getStatusIcon = ({
  status,
  absence,
  polyglot
}) => {
  switch (status) {
    case AbsenceStatus.Pending:
      return /* @__PURE__ */ jsxs(Box, { sx: { color: themeColors.Grey, gap: "5px", display: "flex", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("OffboardingAbsence.pending") })
      ] });
    case AbsenceStatus.Approved:
      return absence.cancellationRequested ? /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            color: themeColors.Red,
            gap: "5px",
            display: "flex",
            alignItems: "center"
          },
          children: [
            /* @__PURE__ */ jsx(WaitingEmptyRed, __spreadValues({}, iconSize)),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Red", children: polyglot.t("OffboardingAbsence.pendingCancellation") })
          ]
        }
      ) : /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            gap: "5px",
            display: "flex",
            alignItems: "center"
          },
          children: [
            /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingAbsence.approved") })
          ]
        }
      );
    case AbsenceStatus.Rejected:
      return /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            gap: "5px",
            display: "flex",
            alignItems: "center"
          },
          children: [
            /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingAbsence.rejected") })
          ]
        }
      );
  }
};
export const OffboardingFutureAbsencesTable = ({ absencesAfterLeaveDate, refreshAll }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAbsence, setSelectedAbsence] = useState(void 0);
  const deleteAbsence = useCallback(
    (absenceId) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.deleteAbsenceRecord(absenceId);
        showMessage(polyglot.t("OffboardingAbsence.successMessages.deleted"), "success");
        yield refreshAll();
        setAnchorEl(null);
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [polyglot, showMessage, refreshAll]
  );
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("OffboardingAbsence.period"),
        accessorFn: (row) => row,
        id: "start",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item == null ? void 0 : item.start),
        cell: ({ row }) => {
          const { original } = row;
          const isAM = original.morningOnly;
          const isPM = original.afternoonOnly;
          const startDateSuffixEl = isPM ? /* @__PURE__ */ jsx("span", { children: polyglot.t("OffboardingAbsence.pm") }) : isAM && !original.end ? /* @__PURE__ */ jsx("span", { children: polyglot.t("OffboardingAbsence.am") }) : null;
          const startDateEl = /* @__PURE__ */ jsx("time", { children: new LocalDate(original.start).toLocaleDateString() });
          return /* @__PURE__ */ jsx(Box, { sx: { whiteSpace: "pre", display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            startDateEl,
            startDateSuffixEl,
            original.end && /* @__PURE__ */ jsxs(Fragment, { children: [
              " - ",
              /* @__PURE__ */ jsx("time", { children: new LocalDate(original.end).toLocaleDateString() }),
              isAM ? /* @__PURE__ */ jsx("span", { children: polyglot.t("OffboardingAbsence.am") }) : null
            ] })
          ] }) });
        }
      },
      {
        header: () => polyglot.t("OffboardingAbsence.totalLength"),
        id: "totalLength",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => Number(item.totalLength)),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          var _a, _b;
          if (((_a = row.original.policy) == null ? void 0 : _a.allowanceType) === AbsencePolicyAllowanceType.UnlimitedHourly) {
            const startHour = row.original.startHourTimestamp ? new Date(row.original.startHourTimestamp).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit"
            }).toUpperCase() : void 0;
            const endHour = row.original.endHourTimestamp ? new Date(row.original.endHourTimestamp).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit"
            }).toUpperCase() : void 0;
            return startHour && endHour ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: `${startHour} - ${endHour}` }) : /* @__PURE__ */ jsx(EmptyCell, {});
          }
          const totalLength = (_b = row.original.totalLength) != null ? _b : 0;
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingAbsence.numHours", {
            smart_count: convertMinutesToClockHours(totalLength, polyglot)
          }) });
        }
      },
      {
        header: () => polyglot.t("AbsenceTable.workdayCount"),
        id: "workdayCount",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.workdayCount ? Number(item.workdayCount) : 0),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          const isHourly = isHourlyPolicy(row.original.policy);
          if (isHourly) return /* @__PURE__ */ jsx(EmptyCell, {});
          return /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            row.original.workdayCount,
            " day",
            row.original.workdayCount === 1 ? "" : "s"
          ] });
        }
      },
      {
        header: () => polyglot.t("OffboardingAbsence.policyId"),
        accessorFn: (row) => row,
        id: "policyId",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.policy) == null ? void 0 : _a.name;
        }),
        cell: ({ row }) => {
          var _a;
          return ((_a = row.original.policy) == null ? void 0 : _a.name) ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: row.original.policy.name }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("OffboardingAbsence.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.status),
        cell: ({ row }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getStatusIcon({
          status: row.original.status,
          absence: row.original,
          polyglot
        }) }) })
      },
      {
        header: () => polyglot.t("OffboardingAbsence.approvedBy"),
        accessorFn: (row) => row,
        id: "approvedByIds",
        cell: ({ row }) => {
          const approvers = row.original.approvedByIds.map((userId) => {
            var _a;
            const name = (_a = getCachedUserById(userId)) == null ? void 0 : _a.displayName;
            return name ? polyglot.t(name) : null;
          }).filter(Boolean);
          return approvers.length > 0 ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: approvers.join(", ") }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "",
        id: "actions",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "end", alignItems: "center" }, children: /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: (e) => __async(void 0, null, function* () {
                setSelectedAbsence(original);
                setAnchorEl(e.currentTarget);
                e.stopPropagation();
              }),
              children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
            }
          ) });
        }
      }
    ],
    [polyglot, getCachedUserById]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m60 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("OffboardingAbsence.approvedRequests") }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(BasicTable, { rowData: absencesAfterLeaveDate != null ? absencesAfterLeaveDate : [], columnData, hidePagination: true }) })
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: Boolean(anchorEl),
        onClose: () => setAnchorEl(null),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (!selectedAbsence) return;
          yield deleteAbsence(selectedAbsence.absenceId);
          setSelectedAbsence(void 0);
          setAnchorEl(null);
        }),
        message: polyglot.t("OffboardingAbsence.confirmDelete"),
        callToAction: polyglot.t("General.yes")
      }
    )
  ] });
};
