"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { MINUTES_IN_WEEK } from "@v2/util/time.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { drawerContentSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
const validationSchema = (polyglot) => yup.object({
  weeklyMinutes: yup.number().min(1, polyglot.t("EditAttendanceSettingsDrawer.InvalidValueMsg")).max(MINUTES_IN_WEEK, polyglot.t("EditAttendanceSettingsDrawer.InvalidValueMsg")).typeError(polyglot.t("EditAttendanceSettingsDrawer.InvalidValueMsg")).required(polyglot.t("EditAttendanceSettingsDrawer.InvalidValueMsg")),
  workingMonday: yup.boolean(),
  workingTuesday: yup.boolean(),
  workingWednesday: yup.boolean(),
  workingThursday: yup.boolean(),
  workingFriday: yup.boolean(),
  workingSaturday: yup.boolean(),
  workingSunday: yup.boolean()
}).test("at-least-one-day", polyglot.t("EditAttendanceSettingsDrawer.AtLeastOneDayRequiredMsg"), function(values) {
  const result = Object.entries(values).filter(([key]) => key.startsWith("working")).some(([_, value]) => value === true);
  if (!result) {
    return this.createError({
      message: polyglot.t("EditAttendanceSettingsDrawer.AtLeastOneDayRequiredMsg"),
      path: "workingMonday"
      // Optionally attach the error to 'workingMonday'
    });
  }
  return true;
});
export const EditAttendanceSettingsDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSettings,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        EditAttendanceSettingsDrawerContent,
        {
          attendanceSettings,
          refresh,
          setIsOpen
        }
      )
    }
  ) });
};
const EditAttendanceSettingsDrawerContent = ({
  attendanceSettings,
  refresh,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(Math.round(Math.floor(attendanceSettings.weeklyMinutes / 60)));
  const [minutes, setMinutes] = useState(
    Math.round(attendanceSettings.weeklyMinutes - Math.floor(attendanceSettings.weeklyMinutes / 60) * 60)
  );
  const formik = useFormik({
    initialValues: {
      weeklyMinutes: (_a = attendanceSettings.weeklyMinutes) != null ? _a : 40,
      workingMonday: (_b = attendanceSettings.workingMonday) != null ? _b : true,
      workingTuesday: (_c = attendanceSettings.workingTuesday) != null ? _c : true,
      workingWednesday: (_d = attendanceSettings.workingWednesday) != null ? _d : true,
      workingThursday: (_e = attendanceSettings.workingThursday) != null ? _e : true,
      workingFriday: (_f = attendanceSettings.workingFriday) != null ? _f : true,
      workingSaturday: (_g = attendanceSettings.workingSaturday) != null ? _g : false,
      workingSunday: (_h = attendanceSettings.workingSunday) != null ? _h : false
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: validationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      yield updateSettings(__spreadProps(__spreadValues({}, values), { weeklyMinutes: Number(values.weeklyMinutes) }));
    })
  });
  useEffect(() => {
    formik.setFieldValue("weeklyMinutes", hours * 60 + minutes);
  }, [hours, minutes]);
  const updateSettings = useCallback(
    (updateData) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield AttendanceAPI.updateAttendanceSettings(updateData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("EditAttendanceSettingsDrawer.SomethingWentWrongMsg", { errorMsg: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, showMessage, refresh, setIsOpen]
  );
  const handleCheckboxChange = (e, checked) => {
    const target = e.target;
    formik.setFieldValue(target.name, checked);
    setTimeout(() => formik.setFieldTouched(target.name, true, true));
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("SettingsRouterPageConfig.other") }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: "10px" }, children: polyglot.t("EditAttendanceSettingsDrawer.DefaultTotalWeeklyWorkingHours") }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", gap: spacing.g10 }), children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Hours"),
            name: "hours",
            value: hours,
            onChange: (e) => {
              const value = e.target.value;
              setHours(Number(value));
            },
            error: formik.touched.weeklyMinutes && Boolean(formik.errors.weeklyMinutes),
            helperText: formik.touched.weeklyMinutes && formik.errors.weeklyMinutes,
            fullWidth: true,
            endAdornment: "none"
          }
        ),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Minutes"),
            name: "minutes",
            value: minutes,
            onChange: (e) => {
              const value = e.target.value;
              setMinutes(Number(value));
            },
            fullWidth: true,
            endAdornment: "none"
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: "10px" }, children: polyglot.t("EditAttendanceSettingsDrawer.WorkingDays") }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "5px" }, children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Monday"),
            name: "workingMonday",
            checked: formik.values.workingMonday,
            onChange: (e, checked) => handleCheckboxChange(e, checked)
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Tuesday"),
            name: "workingTuesday",
            checked: formik.values.workingTuesday,
            onChange: (e, checked) => handleCheckboxChange(e, checked)
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Wednesday"),
            name: "workingWednesday",
            checked: formik.values.workingWednesday,
            onChange: (e, checked) => handleCheckboxChange(e, checked)
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Thursday"),
            name: "workingThursday",
            checked: formik.values.workingThursday,
            onChange: (e, checked) => handleCheckboxChange(e, checked)
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Friday"),
            name: "workingFriday",
            checked: formik.values.workingFriday,
            onChange: (e, checked) => handleCheckboxChange(e, checked)
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Saturday"),
            name: "workingSaturday",
            checked: formik.values.workingSaturday,
            onChange: (e, checked) => handleCheckboxChange(e, checked)
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("EditAttendanceSettingsDrawer.Sunday"),
            name: "workingSunday",
            checked: formik.values.workingSunday,
            onChange: (e, checked) => handleCheckboxChange(e, checked)
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("EditAttendanceSettingsDrawer.Update"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: !formik.isValid || !formik.dirty || Object.keys(formik.errors).length > 0
      }
    ) })
  ] }) });
};
