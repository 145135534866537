"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik, useFormikContext } from "formik";
import { intersection } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GrowthFactorFormModal } from "@/v2/feature/growth/growth-factor/components/growth-factor-form-modal.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { GrowthScaleEndpoints } from "@/v2/feature/growth/growth-scale/growth-scale.api";
import { OptionTypeQuestion } from "@/v2/feature/growth/reviews/features/components/option-type-question.component";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import {
  AnswerTypes,
  MainReviwerTypeArray,
  QuestionType,
  ReviewerTypesArray,
  SelfReviwerTypeArray
} from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const multipleAnswerTypes = [QuestionType.SingleAnswer, QuestionType.MultipleAnswer];
const getQuestionSchema = (polyglot) => Yup.object().shape({
  type: Yup.string().oneOf(Object.values(QuestionType), polyglot.t("ValidationMessages.validValue")).required(),
  factor: Yup.string().nullable().notRequired(),
  scaleType: Yup.string().nullable().notRequired(),
  reviewerSelect: Yup.array(Yup.string().required()).min(
    1,
    polyglot.t("FormsQuestionModal.errorMessages.oneReviewerRequired")
  ),
  questionMain: Yup.string().nullable().when("reviewerSelect", {
    is: (reviewerSelect) => reviewerSelect.some((r) => MainReviwerTypeArray.includes(r)),
    then: Yup.string().required(polyglot.t("FormsQuestionModal.errorMessages.questionStatementRequired"))
  }),
  questionSelf: Yup.string().nullable().when("reviewerSelect", {
    is: (reviewerSelect) => reviewerSelect.some((r) => SelfReviwerTypeArray.includes(r)),
    then: Yup.string().required(polyglot.t("FormsQuestionModal.errorMessages.questionStatementRequired"))
  }),
  hasComment: Yup.boolean().notRequired(),
  isCommentRequired: Yup.boolean().notRequired(),
  scaleConfig: Yup.object().nullable().when("type", {
    is: (val) => val === QuestionType.ScaleQuestion,
    then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField")),
    otherwise: (schema) => schema.notRequired()
  }),
  visibilitySettings: Yup.object().shape({
    hideManagerResult: Yup.boolean().required(),
    hidePeerResult: Yup.boolean().required(),
    hideUpwardResult: Yup.boolean().required()
  })
});
export const QuestionModalContent = ({
  onClose,
  question,
  refresh,
  onSubmit
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const { data: allScales } = useApiClient(GrowthScaleEndpoints.getGrowthScalesByCompanyId(), {
    suspense: false
  });
  const { data: allGrowthFactors, mutate: refreshFactors } = useApiClient(
    GrowthFactorEndpoints.getGrowthFactorsByCompanyId(),
    {
      suspense: false
    }
  );
  const [isFactorOpen, setIsFactorOpen] = useState(false);
  const [answerOpts, setAnswerOpts] = useState(question ? question.answerOptions : {});
  const [answerOptsError, setAnswerOptsError] = useState(null);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: question != null ? question : {
      id: "",
      questionMain: "",
      questionSelf: "",
      hasComment: false,
      isCommentRequired: false,
      reviewerSelect: [ReviewerTypes.Self, ReviewerTypes.Manager],
      type: QuestionType.OpenEnded,
      answerOptions: {},
      factor: null,
      scaleConfig: null,
      visibilitySettings: {
        hideManagerResult: false,
        hidePeerResult: false,
        hideUpwardResult: false
      }
    },
    enableReinitialize: true,
    validationSchema: getQuestionSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (multipleAnswerTypes.includes(values.type) && Object.keys(answerOpts).length < 2) {
          setAnswerOptsError(polyglot.t("FormsQuestionModal.errorMessages.twoOptRequired"));
          return;
        }
        const questionValues = __spreadProps(__spreadValues({}, values), {
          answerOptions: answerOpts,
          factor: values.factor !== "none" ? values.factor : null
        });
        yield onSubmit(questionValues);
        formik.resetForm();
        onClose();
        yield refresh();
        showMessage("Successfully added", "success");
      } catch (error) {
        showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, style: { height: "100%" }, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        gap: spacing.g24
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: question ? "Edit question" : "New question" }),
          /* @__PURE__ */ jsx(
            QuestionFormFields,
            {
              allScales,
              allGrowthFactors,
              setAnswerOpts,
              answerOptsError,
              answerOpts,
              setAnswerOptsError,
              setIsFactorOpen
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: question ? "Update question" : "Add question",
            loading,
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true
          }
        ) }),
        /* @__PURE__ */ jsx(
          GrowthFactorFormModal,
          {
            growthFactor: void 0,
            setIsOpen: setIsFactorOpen,
            isOpen: isFactorOpen,
            onClose: () => {
              setIsFactorOpen(false);
            },
            refresh: () => __async(void 0, null, function* () {
              yield refreshFactors == null ? void 0 : refreshFactors();
            })
          }
        )
      ]
    }
  ) }) });
};
const QuestionFormFields = ({
  allScales,
  allGrowthFactors,
  setAnswerOpts,
  answerOptsError,
  answerOpts,
  setAnswerOptsError,
  setIsFactorOpen
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const { polyglot } = usePolyglot();
  const { values, setFieldValue, handleChange, errors, touched } = useFormikContext();
  const FactorLabelValueArray = useMemo(() => {
    if (!allGrowthFactors) return [];
    const sanitizedGrowth = allGrowthFactors.map((factor) => {
      var _a2;
      return {
        label: factor.name,
        value: factor.name,
        icon: /* @__PURE__ */ jsx(
          "div",
          {
            style: {
              backgroundColor: (_a2 = factor.color) != null ? _a2 : themeColors.Grey,
              width: "8px",
              height: "8px",
              borderRadius: radius.br25
            }
          }
        )
      };
    });
    return [{ label: "None", value: "none" }, ...sanitizedGrowth];
  }, [allGrowthFactors]);
  const allScalesLabelValueArray = useMemo(() => {
    if (!allScales) return [];
    return allScales.map((scale) => {
      var _a2, _b2;
      const valueArray = Object.keys(scale.value);
      const min = Math.min(...valueArray.map(Number)) || 0;
      const max = Math.max(...valueArray.map(Number)) || 0;
      return {
        label: scale.type,
        value: scale.id,
        description: scale.points ? `${(_a2 = scale.points) == null ? void 0 : _a2[min]} \u2192 ${(_b2 = scale.points) == null ? void 0 : _b2[max]}` : ""
      };
    });
  }, [allScales]);
  const selectedReviewerSelectList = useMemo(() => {
    if (!values.reviewerSelect || !ReviewerTypesArray(polyglot)) return [];
    return values.reviewerSelect.map((value) => {
      const reviewList = ReviewerTypesArray(polyglot).find((m) => m.value === value);
      return reviewList ? { label: reviewList.label, value: reviewList.value } : null;
    }).filter(Boolean);
  }, [values.reviewerSelect, polyglot]);
  const generateType = (type, hasComment) => {
    if (hasComment) return `${type}+comment`;
    else return type;
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g16,
        paddingTop: spacing.p24
      },
      children: [
        /* @__PURE__ */ jsx(
          MultipleSelectCheckbox,
          {
            id: "reviewerSelect",
            label: polyglot.t("FormsQuestionModal.shouldRespond"),
            limitTags: -1,
            options: ReviewerTypesArray(polyglot),
            value: selectedReviewerSelectList,
            onChange: (_, values2) => {
              const updatedOptionList = values2.map(({ value }) => value);
              setFieldValue("reviewerSelect", updatedOptionList);
              if (!updatedOptionList.includes(ReviewerTypes.Manager))
                setFieldValue("visibilitySettings.hideManagerResult", false);
              if (!updatedOptionList.includes(ReviewerTypes.Upward))
                setFieldValue("visibilitySettings.hideUpwardResult", false);
              if (!updatedOptionList.includes(ReviewerTypes.Peer))
                setFieldValue("visibilitySettings.hidePeerResult", false);
            },
            isOptionEqualToValue: (x, y) => x.value === y.value,
            getOptionLabel: ({ label }) => label,
            error: touched.reviewerSelect && Boolean(errors.reviewerSelect),
            helperText: Boolean(touched.reviewerSelect && errors.reviewerSelect) ? errors.reviewerSelect : ""
          }
        ),
        intersection(values.reviewerSelect, MainReviwerTypeArray).length > 0 && /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            multiline: true,
            minRows: 2,
            name: "questionMain",
            label: polyglot.t("FormsQuestionModal.questionText"),
            value: values.questionMain,
            onChange: handleChange,
            endAdornment: "none",
            error: touched.questionMain && Boolean(errors.questionMain),
            helperText: (_a = touched.questionMain && errors.questionMain) != null ? _a : " "
          }
        ),
        ((_b = values.reviewerSelect) == null ? void 0 : _b.includes(ReviewerTypes.Self)) && /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            multiline: true,
            minRows: 2,
            name: "questionSelf",
            label: polyglot.t("FormsQuestionModal.selfReviewQText"),
            value: values.questionSelf,
            onChange: handleChange,
            endAdornment: "none",
            error: touched.questionSelf && Boolean(errors.questionSelf),
            helperText: (_c = touched.questionSelf && errors.questionSelf) != null ? _c : " "
          }
        ),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "factor",
            label: "Factor",
            options: FactorLabelValueArray,
            value: (_d = values.factor) != null ? _d : "none",
            compareValue: (_e = values.factor) != null ? _e : "none",
            error: !!errors.factor && touched.factor,
            onChange: (e) => {
              setFieldValue("factor", e.target.value);
            },
            helperText: errors.factor && touched.factor,
            editList: {
              isHidden: false,
              handler: () => {
                setIsFactorOpen(true);
              }
            }
          }
        ),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "type",
            label: polyglot.t("FormsQuestionModal.type"),
            options: AnswerTypes(polyglot),
            value: generateType(values.type, values.hasComment),
            compareValue: values.type,
            error: !!errors.type && touched.type,
            onChange: (e) => {
              const value = e.target.value;
              const parts = value.split("+");
              const type = parts[0];
              const comment = parts[1];
              setFieldValue("type", type);
              setFieldValue("hasComment", Boolean(comment));
              setFieldValue("isCommentRequired", false);
            },
            helperText: errors.type && touched.type
          }
        ),
        values.hasComment && /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Make comment required",
            name: "isCommentRequired",
            disabled: !values.hasComment,
            checked: values.isCommentRequired,
            onChange: (e, checked) => {
              setFieldValue("isCommentRequired", checked);
            }
          }
        ),
        multipleAnswerTypes.includes(values.type) && /* @__PURE__ */ jsx(
          OptionTypeQuestion,
          {
            setOptions: setAnswerOpts,
            options: answerOpts,
            answerOptsError,
            setAnswerOptsError
          }
        ),
        values.type && values.type.includes(QuestionType.ScaleQuestion) && /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "scaleType",
            label: "Scale type",
            options: allScalesLabelValueArray,
            value: (_f = values.scaleConfig) == null ? void 0 : _f.id,
            compareValue: (_g = values.scaleConfig) == null ? void 0 : _g.id,
            error: !!errors.scaleConfig && touched.scaleConfig,
            onChange: (e) => {
              const selectedId = e.target.value;
              const selectedScale = allScales == null ? void 0 : allScales.find((scale) => scale.id === selectedId);
              if (selectedScale)
                setFieldValue("scaleConfig", {
                  id: selectedScale.id,
                  type: selectedScale.type,
                  points: selectedScale.points,
                  value: selectedScale.value,
                  sentiments: selectedScale.sentiments
                });
            },
            helperText: errors.scaleConfig && touched.scaleConfig
          }
        ),
        intersection(values.reviewerSelect, MainReviwerTypeArray).length > 0 && /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              paddingTop: spacing.p12,
              borderTop: borders.light,
              display: "flex",
              flexDirection: "column",
              gap: spacing.g16
            },
            children: [
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Hide results from employee" }),
                /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Hide answers to this question from employee's results report." })
              ] }),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
                ((_h = values.reviewerSelect) == null ? void 0 : _h.includes(ReviewerTypes.Manager)) && /* @__PURE__ */ jsx(
                  CheckboxComponent,
                  {
                    label: "Manager",
                    name: "visibilitySettings.hideManagerResult",
                    checked: values.visibilitySettings.hideManagerResult,
                    onChange: (e, checked) => {
                      setFieldValue("visibilitySettings.hideManagerResult", checked);
                    }
                  }
                ),
                ((_i = values.reviewerSelect) == null ? void 0 : _i.includes(ReviewerTypes.Upward)) && /* @__PURE__ */ jsx(
                  CheckboxComponent,
                  {
                    label: "Upward",
                    name: "visibilitySettings.hideUpwardResult",
                    checked: values.visibilitySettings.hideUpwardResult,
                    onChange: (e, checked) => {
                      setFieldValue("visibilitySettings.hideUpwardResult", checked);
                    }
                  }
                ),
                ((_j = values.reviewerSelect) == null ? void 0 : _j.includes(ReviewerTypes.Peer)) && /* @__PURE__ */ jsx(
                  CheckboxComponent,
                  {
                    label: "Peer",
                    name: "visibilitySettings.hidePeerResult",
                    checked: values.visibilitySettings.hidePeerResult,
                    onChange: (e, checked) => {
                      setFieldValue("visibilitySettings.hidePeerResult", checked);
                    }
                  }
                )
              ] })
            ]
          }
        )
      ]
    }
  );
};
