"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Box } from "@mui/material";
import { Divider } from "@v2/components/divider.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { HolidaysProratingRule } from "@v2/feature/absence/absence.interface";
import { convertMinutesToClockHours, isHourlyPolicy } from "@v2/feature/absence/absence.util";
import {
  getHolidaysProratingRuleLong,
  getPublicHolidayAllowanceFromBreakdown,
  isoDateToLocale
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { Chip } from "@v2/feature/absence/me/policies/policy-breakdown/components/prorated-allowance-details-drawer.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { round2Digits } from "@v2/util/number.util";
export const PublicHolidayAllowanceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AllowanceDrawer.publicHolidayAllowance") }),
        /* @__PURE__ */ jsx(
          PublicHolidayAllowanceDetailsDrawerContent,
          {
            userBalanceDetailedStats,
            absencePolicy
          }
        )
      ] })
    }
  ) });
};
const PublicHolidayAllowanceDetailsDrawerContent = ({
  absencePolicy,
  userBalanceDetailedStats
}) => /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20 }, children: [
  /* @__PURE__ */ jsx(CalculationSection, { absencePolicy, userBalanceDetailedStats }),
  /* @__PURE__ */ jsx(DetailsSection, { absencePolicy, userBalanceDetailedStats })
] });
const CalculationSection = ({
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  if (!breakdown) return null;
  const publicHolidayAllowance = getPublicHolidayAllowanceFromBreakdown(absencePolicy, breakdown, polyglot);
  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    const firstOfJan = /* @__PURE__ */ new Date();
    firstOfJan.setMonth(0, 1);
    const thirtyFirstOfDec = /* @__PURE__ */ new Date();
    thirtyFirstOfDec.setMonth(11, 31);
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
        return /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              width: "100%",
              display: "flex",
              gap: spacing.g5,
              alignItems: "center",
              justifyContent: "space-between"
            },
            children: /* @__PURE__ */ jsx(
              ChipLine,
              {
                absencePolicy,
                userBalanceDetailedStats,
                segment
              },
              index
            )
          }
        );
      }),
      /* @__PURE__ */ jsx(Divider, {}),
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: publicHolidayAllowance }) })
    ] });
  }
  return null;
};
export const DetailsSection = ({
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  const proratingRule = getHolidaysProratingRuleLong(absencePolicy, polyglot);
  if (!breakdown) return null;
  return /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("AllowanceDrawer.details") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: proratingRule }),
    absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriod && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: polyglot.t("AllowanceDrawer.allInPeriodShortDesc") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.m20 }, children: polyglot.t("AllowanceDrawer.companyCalendarRunsFromTo", {
      startDate: isoDateToLocale(breakdown.currentCycle[0]),
      endDate: isoDateToLocale(breakdown.currentCycle[1])
    }) }),
    /* @__PURE__ */ jsx(DetailsSegments, { absencePolicy, userBalanceDetailedStats })
  ] });
};
export const DetailsSegments = ({
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  if (!breakdown) return null;
  const isHourly = isHourlyPolicy(absencePolicy);
  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
      const percentageInYear = round2Digits(segment.daysInSegment / segment.daysInCycle * 100);
      const segmentStart = isoDateToLocale(segment.segmentStart);
      const segmentEnd = isoDateToLocale(segment.segmentEnd);
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: spacing.m5 }, children: polyglot.t("AllowanceDrawer.contractNo", { contractNo: index + 1 }) }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: polyglot.t("AllowanceDrawer.effectiveFromTo", {
          start: segmentStart,
          end: segmentEnd,
          holidayCalendar: segment.holidayCalendar.toUpperCase()
        }) }),
        absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated ? /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "In ",
          breakdown.currentHolidayYear,
          " there were",
          " ",
          /* @__PURE__ */ jsx(Chip, { color: themeColors.FreshGreen, value: `${segment.publicHolidaysInYear} days` }),
          " of public holiday in ",
          segment.holidayCalendar === "uk" ? "the " : "",
          segment.holidayCalendar.toUpperCase()
        ] }) : /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "In this period there were",
          " ",
          /* @__PURE__ */ jsx(
            Chip,
            {
              color: themeColors.FreshGreen,
              value: `${segment.publicHolidaysInSegment} day${segment.publicHolidaysInSegment === 1 ? "" : "s"}`
            }
          ),
          " ",
          "of public holiday."
        ] }),
        absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated && absencePolicy.proratingStartDate && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "This contract is effective from ",
          segmentStart,
          " to ",
          segmentEnd,
          ", lasting ",
          segment.daysInSegment,
          " ",
          "days. (",
          segment.daysInSegment,
          "/",
          segment.daysInCycle,
          " =",
          " ",
          /* @__PURE__ */ jsx(Chip, { color: themeColors.ZeltYellow, value: `${percentageInYear}%` }),
          ")"
        ] }),
        absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated && absencePolicy.proratingFte && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "Your work pattern is ",
          segment.userNoOfWorkingDays,
          " days out of",
          " ",
          segment.fteEquivalentNoOfWorkingDays,
          " days per week. Therefore your prorating percentage is",
          " ",
          /* @__PURE__ */ jsx(Chip, { color: themeColors.Violet, value: `${round2Digits(segment.fteByWorkingDaysInWeek * 100)}%` }),
          ")"
        ] }),
        (absencePolicy.holidaysProratingRule !== HolidaysProratingRule.AllInPeriod && !breakdown.isOnRegularSchedule || isHourly && absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "Average workday length is",
          " ",
          /* @__PURE__ */ jsx(
            Chip,
            {
              color: themeColors.Orange,
              value: convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot)
            }
          )
        ] })
      ] }, index);
    }) });
  }
  return null;
};
const ChipLine = ({
  absencePolicy,
  userBalanceDetailedStats,
  segment
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
  const { polyglot } = usePolyglot();
  const isHourly = isHourlyPolicy(absencePolicy);
  if ([HolidaysProratingRule.AllInPeriod, HolidaysProratingRule.AllInCycle].includes(absencePolicy.holidaysProratingRule))
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Chip,
        {
          color: themeColors.FreshGreen,
          value: polyglot.t("AllowanceDrawer.noOfDays", { smart_count: segment.publicHolidaysInSegment })
        }
      ),
      /* @__PURE__ */ jsx(Box, { children: "=" }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { whiteSpace: "nowrap" }, children: isHourly || !((_a = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _a.isOnRegularSchedule) || !((_b = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _b.currentAverageWorkDayLength) ? convertMinutesToClockHours(segment.segmentPublicHolidaysValue, polyglot) : polyglot.t("AllowanceDrawer.noOfDays", {
        smart_count: (_d = round2Digits(
          segment.segmentPublicHolidaysValue / ((_c = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _c.currentAverageWorkDayLength)
        )) != null ? _d : 0
      }) })
    ] });
  const timesAverageWorkingHours = isHourly || !((_e = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _e.isOnRegularSchedule) || !((_f = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _f.currentAverageWorkDayLength) ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { children: "X" }),
    /* @__PURE__ */ jsx(Chip, { color: themeColors.Orange, value: convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot) })
  ] }) : null;
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated) {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Chip,
        {
          color: themeColors.FreshGreen,
          value: polyglot.t("AllowanceDrawer.noOfDays", { smart_count: segment.publicHolidaysInSegment })
        }
      ),
      /* @__PURE__ */ jsx(Box, { children: "X" }),
      /* @__PURE__ */ jsx(
        Chip,
        {
          color: themeColors.ZeltYellow,
          value: polyglot.t("AllowanceDrawer.percentage", {
            percentage: (_g = round2Digits(segment.fteByWorkingDaysInWeek * 100)) != null ? _g : 0
          })
        }
      ),
      timesAverageWorkingHours,
      /* @__PURE__ */ jsx(Box, { children: "=" }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { whiteSpace: "nowrap" }, children: isHourly || !((_h = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _h.isOnRegularSchedule) || !((_i = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _i.currentAverageWorkDayLength) ? convertMinutesToClockHours(segment.segmentPublicHolidaysValue, polyglot) : polyglot.t("AllowanceDrawer.noOfDays", {
        smart_count: (_k = round2Digits(
          segment.segmentPublicHolidaysValue / ((_j = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _j.currentAverageWorkDayLength)
        )) != null ? _k : 0
      }) })
    ] });
  }
  if (absencePolicy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated) {
    const percentageInYear = (_l = round2Digits(segment.daysInSegment / segment.daysInCycle * 100)) != null ? _l : 0;
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Chip,
        {
          color: themeColors.FreshGreen,
          value: polyglot.t("AllowanceDrawer.noOfDays", {
            smart_count: segment.publicHolidaysInYear
          })
        }
      ),
      absencePolicy.proratingStartDate && /* @__PURE__ */ jsx(Box, { children: "X" }),
      absencePolicy.proratingStartDate && /* @__PURE__ */ jsx(
        Chip,
        {
          color: themeColors.ZeltYellow,
          value: polyglot.t("AllowanceDrawer.percentage", { percentage: percentageInYear })
        }
      ),
      absencePolicy.proratingFte && /* @__PURE__ */ jsx(Box, { children: "X" }),
      absencePolicy.proratingFte && /* @__PURE__ */ jsx(
        Chip,
        {
          color: themeColors.Violet,
          value: polyglot.t("AllowanceDrawer.percentage", {
            percentage: (_m = round2Digits(segment.fteByWorkingDaysInWeek * 100)) != null ? _m : 0
          })
        }
      ),
      timesAverageWorkingHours,
      /* @__PURE__ */ jsx(Box, { children: "=" }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { whiteSpace: "nowrap" }, children: isHourly || !((_n = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _n.isOnRegularSchedule) || !((_o = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _o.currentAverageWorkDayLength) ? convertMinutesToClockHours(segment.segmentPublicHolidaysValue, polyglot) : polyglot.t("AllowanceDrawer.noOfDays", {
        smart_count: (_r = round2Digits(
          ((_p = segment.segmentPublicHolidaysValue) != null ? _p : 0) / ((_q = userBalanceDetailedStats[absencePolicy.id]) == null ? void 0 : _q.currentAverageWorkDayLength)
        )) != null ? _r : 0
      }) })
    ] });
  }
  return null;
};
