"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { TimelineRollingModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/rolling/timeline-rolling-modal.component";
import { timelineByReviwer } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const TimelineRollingView = ({ reviewCycle, showFlags, isEditable, refreshCycle }) => {
  const [isOpen, setIsOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    timelineByReviwer(reviewCycle, showFlags).length > 0 ? /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            headerWidth: "100%",
            title: "Timeline",
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.TextLine,
                value: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { pb: spacing.s2 }, children: "Each invited participant will have the defined amount of days to complete the review. The count starts from the day they are invited. After the deadline has passed, results will not be finalised unless the explicit action is taken in the Results page for each participant." })
              },
              ...timelineByReviwer(reviewCycle, showFlags)
            ]
          }
        ]
      }
    ) : /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            contentWidth: "100%",
            headerWidth: "100%",
            title: "Timeline",
            hideEdit: false,
            items: [
              {
                type: SectionItemType.TextLine,
                value: "Please add some questions to be able to set timelines"
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      TimelineRollingModal,
      {
        reviewCycle,
        isOpen,
        setIsOpen,
        onClose: () => setIsOpen(false),
        refresh: () => __async(void 0, null, function* () {
          yield refreshCycle == null ? void 0 : refreshCycle();
        }),
        showFlags
      }
    )
  ] });
};
