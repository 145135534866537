"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { Redirect, Switch, generatePath, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_BUILDER_ROUTE,
  SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE
} from "@/lib/routes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SurveyTemplateEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-template.api";
import { SurveyTemplateBuilderPage } from "@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/pages/survey-template-builder.page";
import { SurveyTemplateGeneralPage } from "@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/pages/survey-template-general.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const getPageConfig = (templateId, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: "General",
          stub: "general",
          path: generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE, { templateId })
        },
        {
          title: "Questions",
          stub: "questions",
          path: generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_BUILDER_ROUTE, { templateId })
        }
      ]
    }
  ];
};
export const SurveyTemplateOverviewPage = () => {
  const params = useParams();
  const templateId = params.templateId;
  const { polyglot } = usePolyglot();
  const {
    data: templateObject,
    mutate: refreshTemplate,
    isLoading: templateLoading
  } = useApiClient(SurveyTemplateEndpoints.getSurveyTemplateStructurebyId(templateId), { suspense: false });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Templates",
        subtitle: "",
        pageConfig: getPageConfig(templateId, polyglot),
        backPath: SETTINGS_GROWTH_SURVEYS_TEMPLATES_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: templateLoading, noHorizontalPadding: true, children: [
      /* @__PURE__ */ jsx(Box, { className: "main-title", sx: { p: spacing.p16 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: templateObject && templateObject.template ? templateObject.template.name : "Template" }) }),
      /* @__PURE__ */ jsxs(Switch, { children: [
        /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["surveys:all"], path: SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(SurveyTemplateGeneralPage, { templateObject, refreshTemplate }) }),
        /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["surveys:all"], path: SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_BUILDER_ROUTE, children: /* @__PURE__ */ jsx(SurveyTemplateBuilderPage, { templateObject, refreshTemplate }) }),
        /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_GENERAL_ROUTE })
      ] })
    ] })
  ] });
};
