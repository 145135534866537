"use strict";
export var FreeProductPlans = /* @__PURE__ */ ((FreeProductPlans2) => {
  FreeProductPlans2[FreeProductPlans2["PEOPLE_FREE"] = 1] = "PEOPLE_FREE";
  FreeProductPlans2[FreeProductPlans2["MONEY_FREE"] = 2] = "MONEY_FREE";
  FreeProductPlans2[FreeProductPlans2["APPS_FREE"] = 3] = "APPS_FREE";
  FreeProductPlans2[FreeProductPlans2["DEVICES_FREE"] = 4] = "DEVICES_FREE";
  return FreeProductPlans2;
})(FreeProductPlans || {});
export var PaidProductPlans = /* @__PURE__ */ ((PaidProductPlans2) => {
  PaidProductPlans2[PaidProductPlans2["PEOPLE_PRO"] = 5] = "PEOPLE_PRO";
  PaidProductPlans2[PaidProductPlans2["MONEY_PRO"] = 6] = "MONEY_PRO";
  PaidProductPlans2[PaidProductPlans2["APPS_PRO"] = 7] = "APPS_PRO";
  PaidProductPlans2[PaidProductPlans2["DEVICES_PRO"] = 8] = "DEVICES_PRO";
  return PaidProductPlans2;
})(PaidProductPlans || {});
export const FreePlanList = [
  1 /* PEOPLE_FREE */,
  2 /* MONEY_FREE */,
  3 /* APPS_FREE */,
  4 /* DEVICES_FREE */
];
export const FreeToProPlanMap = {
  [1 /* PEOPLE_FREE */]: 5 /* PEOPLE_PRO */,
  [2 /* MONEY_FREE */]: 6 /* MONEY_PRO */,
  [3 /* APPS_FREE */]: 7 /* APPS_PRO */,
  [4 /* DEVICES_FREE */]: 8 /* DEVICES_PRO */
};
export const ProductPlanTypes = {
  People: {
    Free: 1 /* PEOPLE_FREE */,
    Pro: 5 /* PEOPLE_PRO */
  },
  Money: {
    Free: 2 /* MONEY_FREE */,
    Pro: 6 /* MONEY_PRO */
  },
  Apps: {
    Free: 3 /* APPS_FREE */,
    Pro: 7 /* APPS_PRO */
  },
  Devices: {
    Free: 4 /* DEVICES_FREE */,
    Pro: 8 /* DEVICES_PRO */
  }
};
export var Product = /* @__PURE__ */ ((Product2) => {
  Product2[Product2["PEOPLE"] = 1] = "PEOPLE";
  Product2[Product2["MONEY"] = 2] = "MONEY";
  Product2[Product2["APPS"] = 3] = "APPS";
  Product2[Product2["DEVICES"] = 4] = "DEVICES";
  return Product2;
})(Product || {});
export var Plan = /* @__PURE__ */ ((Plan2) => {
  Plan2[Plan2["FREE"] = 1] = "FREE";
  Plan2[Plan2["PRO"] = 2] = "PRO";
  return Plan2;
})(Plan || {});
export const ProductPlanMapToLabel = (polyglot) => ({
  [1 /* PEOPLE */]: polyglot.t("ProductPlanMapToLabel.people"),
  [2 /* MONEY */]: polyglot.t("ProductPlanMapToLabel.money"),
  [3 /* APPS */]: polyglot.t("ProductPlanMapToLabel.apps"),
  [4 /* DEVICES */]: polyglot.t("ProductPlanMapToLabel.devices")
});
export const EMPTY_PAYMENT_METHOD = {
  id: -Infinity,
  companyId: -Infinity,
  type: "",
  card: {
    brand: "",
    last4: "",
    exp_year: -Infinity,
    exp_month: -Infinity
  },
  billingAddress: {
    city: "",
    line1: "",
    line2: "",
    state: "",
    country: "",
    postal_code: ""
  }
};
export const getProductPlanIdToName = (polyglot) => {
  return {
    [1 /* PEOPLE_FREE */]: polyglot.t("ProductPlanIdToName.peopleFree"),
    [2 /* MONEY_FREE */]: polyglot.t("ProductPlanIdToName.moneyFree"),
    [3 /* APPS_FREE */]: polyglot.t("ProductPlanIdToName.appsFree"),
    [4 /* DEVICES_FREE */]: polyglot.t("ProductPlanIdToName.devicesFree"),
    [5 /* PEOPLE_PRO */]: polyglot.t("ProductPlanIdToName.peoplePro"),
    [6 /* MONEY_PRO */]: polyglot.t("ProductPlanIdToName.moneyPro"),
    [7 /* APPS_PRO */]: polyglot.t("ProductPlanIdToName.appsPro"),
    [8 /* DEVICES_PRO */]: polyglot.t("ProductPlanIdToName.devicesPro")
  };
};
