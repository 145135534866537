"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { CategoryFilters } from "@v2/components/table/category-filters.component";
import { EmptyCell, IconEmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ChangeOwnerDrawer } from "@v2/feature/device/components/device-details/change-owner-drawer.component";
import { DeviceInventoryReassignDrawer } from "@v2/feature/device/components/devices-list-overview/device-inventory-reassign-drawer.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOwnership, DevicePossessionType } from "@v2/feature/device/device.interface";
import {
  DeviceTypeFilterOptions,
  getDeviceOwnerByDevicePossession,
  getDeviceOwnerByDevicePossessionTable,
  getModelImage,
  getRiskStatus,
  MDMABLE_DEVICE_TYPES
} from "@v2/feature/device/device.util";
import { filterStringToObject } from "@v2/feature/user/user.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { keyBy } from "lodash";
import { useLocation } from "react-router-dom";
import { OrderActionsDrawer } from "../device-details/order-actions-drawer.component";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { ReactComponent as EnvelopeSimple } from "@/images/side-bar-icons/EnvelopeSimple.svg";
import { ReactComponent as Person } from "@/images/side-bar-icons/Person.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from "@/lib/routes";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { sortDate, sortString } from "@/v2/components/table/table-sorting.util";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeFonts } from "@/v2/styles/fonts.styles";
const iconSize = { width: 14, height: 14 };
const DeviceFilterTypes = {
  type: DeviceTypeFilterOptions
};
export const DevicesSelectTable = ({
  devicePossessions,
  deviceTransits,
  rowClick,
  actionButton,
  showAssignedTo = false,
  showNotes = false,
  showOwners = false,
  showSecurity = false,
  showLastActive = false,
  showSearch = false,
  showDefaultActions = false,
  refresh,
  deviceLocation = "user",
  checkedDeviceIds,
  onDeviceCheckChange,
  appliedHexPolicies,
  appliedZeltPolicies,
  appliedMobileZeltPolicies
}) => {
  const { polyglot } = usePolyglot();
  const [isAssignDeviceDrawerOpen, setIsAssignDeviceDrawerOpen] = useState(false);
  const [isChangeOwnerDrawerOpen, setIsChangeOwnerDrawerOpen] = useState(false);
  const [devicePossessionToBeAssigned, setDevicePossessionToBeAssigned] = useState(null);
  const [devicePossessionToBeChanged, setDevicePossessionToBeChanged] = useState(null);
  const [devicePossessionToBeReturned, setDevicePossessionToBeReturned] = useState(null);
  const [isOrderActionsDrawerOpen, setIsOrderActionsDrawerOpen] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const [filterString, setFilterString] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [sitesById, setSitesById] = useState({});
  const [showMessage] = useMessage();
  const { pathname } = useLocation();
  const transitsByDeviceId = keyBy(deviceTransits, "deviceId");
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesById(keyBy(sites, "id"));
      } catch (error) {
        showMessage(`Could not get sites list. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage]);
  const filteredDevices = useMemo(() => {
    let filteredDevicePossessions = devicePossessions.filter((devicePossession) => {
      var _a, _b, _c;
      const ownerName = getDeviceOwnerByDevicePossession(
        devicePossession,
        sitesById[devicePossession.possessionId],
        getCachedUserById
      );
      const modelName = (_a = devicePossession.device) == null ? void 0 : _a.modelName;
      const serialNumber = (_c = (_b = devicePossession.device) == null ? void 0 : _b.serialNumber) != null ? _c : void 0;
      return (ownerName == null ? void 0 : ownerName.toLowerCase().includes(searchInput.toLowerCase())) || (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase())) || (serialNumber == null ? void 0 : serialNumber.toLowerCase().includes(searchInput.toLowerCase()));
    });
    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions.type) {
        filteredDevicePossessions = filteredDevicePossessions.filter(
          (devicePossession) => {
            var _a;
            return ((_a = devicePossession.device) == null ? void 0 : _a.type) && filterOptions.type.includes(devicePossession.device.type);
          }
        );
      }
    }
    return filteredDevicePossessions;
  }, [devicePossessions, searchInput, filterString, getCachedUserById, sitesById]);
  const getUserName = useCallback(
    (assignedUserId) => {
      var _a;
      const user = getCachedUserById(assignedUserId);
      return polyglot.t((_a = user == null ? void 0 : user.displayName) != null ? _a : "");
    },
    [getCachedUserById, polyglot]
  );
  const columnData = useMemo(() => {
    const getDeviceActionsOptions = (devicePossession, transits) => {
      var _a, _b, _c;
      return [
        {
          icon: /* @__PURE__ */ jsx(Person, __spreadValues({}, iconSize)),
          handler: () => {
            setDevicePossessionToBeChanged(devicePossession);
            setIsChangeOwnerDrawerOpen(true);
          },
          label: "Change owner",
          hidden: devicePossession.possessionType !== DevicePossessionType.User,
          disabled: Boolean(devicePossession.deviceId && transits[devicePossession.deviceId])
        },
        {
          icon: /* @__PURE__ */ jsx(EnvelopeSimple, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            try {
              if (devicePossession && devicePossession.id) yield DeviceAPI.sendEnrollmentRequest(devicePossession.id);
              showMessage("Notification was successfully sent.", "success");
            } catch (error) {
              showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
            }
          }),
          label: "Ask to enrol",
          hidden: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User || ((_a = devicePossession.device) == null ? void 0 : _a.enrollmentStatus)
          ),
          disabled: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User || ((_b = devicePossession.device) == null ? void 0 : _b.enrollmentStatus)
          )
        },
        {
          icon: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize)),
          handler: () => {
            setDevicePossessionToBeReturned(devicePossession);
            setIsOrderActionsDrawerOpen(true);
          },
          label: "Order action",
          hidden: !devicePossession.device || // ZeltStorage device with ownership non-rental should not be able to be cancelled
          ((_c = devicePossession.device) == null ? void 0 : _c.ownership) === DeviceOwnership.Company || devicePossession.possessionType === DevicePossessionType.ZeltStorage,
          disabled: Boolean(devicePossession.deviceId && transits[devicePossession.deviceId])
        }
      ];
    };
    return [
      ...checkedDeviceIds ? [
        {
          id: "checkedDevice",
          enableSorting: false,
          cell: (info) => {
            const { deviceId } = info.row.original;
            return /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: "",
                checked: checkedDeviceIds.includes(deviceId),
                onChange: (_, checked) => onDeviceCheckChange == null ? void 0 : onDeviceCheckChange(deviceId, checked)
              }
            );
          }
        }
      ] : [],
      {
        header: () => "Device",
        accessorFn: (row) => row,
        id: "modelName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.device) == null ? void 0 : _a.modelName;
        }),
        cell: (info) => {
          var _a;
          const devicePossession = info.getValue();
          return ((_a = devicePossession.device) == null ? void 0 : _a.modelName) ? /* @__PURE__ */ jsxs(
            Box,
            {
              onClick: () => rowClick == null ? void 0 : rowClick(devicePossession),
              sx: { cursor: "pointer", display: "flex", gap: spacing.g10, alignItems: "center" },
              children: [
                /* @__PURE__ */ jsx(Box, { children: devicePossession && getModelImage(devicePossession.device.type, devicePossession.device.modelName, {
                  width: "30px",
                  height: "auto"
                }) }),
                /* @__PURE__ */ jsx("div", { children: devicePossession.device.modelName })
              ]
            }
          ) : /* @__PURE__ */ jsx(Box, { onClick: () => rowClick == null ? void 0 : rowClick(devicePossession), sx: { cursor: "pointer", display: "flex", gap: 1 }, children: /* @__PURE__ */ jsx(IconEmptyCell, {}) });
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Device name",
        accessorFn: (row) => row,
        id: "deviceName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.device) == null ? void 0 : _a.deviceName;
        }),
        cell: (info) => {
          var _a;
          const devicePossession = info.getValue();
          return ((_a = devicePossession.device) == null ? void 0 : _a.deviceName) ? /* @__PURE__ */ jsx(Box, { children: devicePossession.device.deviceName }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Serial number",
        accessorFn: (row) => row,
        id: "serialNumber",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.device) == null ? void 0 : _a.serialNumber;
        }),
        cell: (info) => {
          var _a;
          const devicePossession = info.getValue();
          return ((_a = devicePossession.device) == null ? void 0 : _a.serialNumber) ? /* @__PURE__ */ jsx(Box, { children: devicePossession.device.serialNumber }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      ...showAssignedTo ? [
        {
          header: () => deviceLocation === "inventory" ? "Location" : "Used by",
          accessorFn: (row) => row,
          id: "id",
          enableSorting: false,
          cell: (info) => {
            const devicePossession = info.getValue();
            if (devicePossession.possessionType === DevicePossessionType.User && devicePossession.possessionId)
              return /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.m5
                  }),
                  children: [
                    /* @__PURE__ */ jsx(UserAvatar, { userId: devicePossession.possessionId, size: "xxsmall" }),
                    /* @__PURE__ */ jsx("div", { children: getUserName(devicePossession.possessionId) })
                  ]
                }
              );
            return /* @__PURE__ */ jsx(Box, { children: getDeviceOwnerByDevicePossessionTable(
              devicePossession,
              sitesById[devicePossession.possessionId],
              polyglot,
              getUserName(devicePossession.possessionId)
            ) });
          },
          maxSize: 200,
          minSize: 140
        }
      ] : [],
      ...showSecurity && (appliedZeltPolicies || appliedHexPolicies || appliedMobileZeltPolicies) ? [
        {
          header: () => "Security",
          accessorFn: (row) => row,
          id: "security",
          enableSorting: false,
          cell: (info) => {
            var _a;
            const devicePossession = info.getValue();
            if (!((_a = devicePossession.device) == null ? void 0 : _a.type) || !MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type))
              return /* @__PURE__ */ jsx(EmptyCell, {});
            return getRiskStatus(
              devicePossession,
              appliedZeltPolicies,
              appliedMobileZeltPolicies,
              appliedHexPolicies
            );
          },
          maxSize: 180,
          minSize: 120
        }
      ] : [],
      ...showLastActive ? [
        {
          header: () => "Last active",
          accessorFn: (row) => row,
          id: "lastActive",
          enableSorting: true,
          sortingFn: (a, b) => sortDate(a, b, (item) => {
            var _a;
            return (_a = item == null ? void 0 : item.device) == null ? void 0 : _a.lastCheckIn;
          }),
          cell: (info) => {
            var _a, _b;
            const devicePossession = info.getValue();
            if (!((_a = devicePossession.device) == null ? void 0 : _a.type) || !MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type))
              return /* @__PURE__ */ jsx(EmptyCell, {});
            const lastCheckIn = (_b = devicePossession.device) == null ? void 0 : _b.lastCheckIn;
            return lastCheckIn ? /* @__PURE__ */ jsx(Box, { children: getDateString(lastCheckIn) }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
          },
          maxSize: 180,
          minSize: 120
        }
      ] : [],
      ...showOwners ? [
        {
          header: () => "Owner",
          accessorFn: (row) => row,
          id: "ownership",
          enableSorting: true,
          sortingFn: (a, b) => sortString(a, b, (item) => {
            var _a;
            return (_a = item == null ? void 0 : item.device) == null ? void 0 : _a.ownership;
          }),
          cell: (info) => {
            const devicePossession = info.getValue();
            return devicePossession.device ? /* @__PURE__ */ jsx(Box, { children: devicePossession.device.ownership }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
          },
          maxSize: 180,
          minSize: 120
        }
      ] : [],
      ...showNotes ? [
        {
          header: () => "Notes",
          accessorFn: (row) => row,
          id: "notes",
          enableSorting: true,
          sortingFn: (a, b) => sortString(a, b, (item) => {
            var _a;
            return (_a = item == null ? void 0 : item.device) == null ? void 0 : _a.customerNotes;
          }),
          cell: ({ row: { original } }) => {
            var _a, _b;
            return /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                dangerouslySetInnerHTML: { __html: (_b = (_a = original == null ? void 0 : original.device) == null ? void 0 : _a.customerNotes) != null ? _b : "" },
                truncateHtml: true
              }
            );
          },
          maxSize: 180,
          minSize: 120
        }
      ] : [],
      ...showDefaultActions ? [
        {
          header: () => "",
          accessorFn: (row) => row,
          id: "actions",
          enableSorting: false,
          cell: ({ row: { original } }) => {
            return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: getDeviceActionsOptions(original, transitsByDeviceId),
                actionButtonDetails: {
                  type: "iconButton",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: "actions",
                  icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                }
              }
            ) });
          },
          maxSize: 50,
          minSize: 50
        }
      ] : []
    ];
  }, [
    checkedDeviceIds,
    showAssignedTo,
    showSecurity,
    appliedZeltPolicies,
    appliedHexPolicies,
    appliedMobileZeltPolicies,
    showLastActive,
    showOwners,
    showNotes,
    showDefaultActions,
    showMessage,
    onDeviceCheckChange,
    rowClick,
    deviceLocation,
    getUserName,
    sitesById,
    polyglot,
    transitsByDeviceId
  ]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-start", gap: spacing.g5 }, children: showSearch && devicePossessions.length > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          CategoryFilters,
          {
            filterTypes: DeviceFilterTypes,
            setFilterString,
            filterString
          }
        ),
        /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
          var _a, _b;
          return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
        } })
      ] }) }),
      actionButton && pathname.includes(DEVICES_COMPANY_DIRECTORY_ROUTE) && /* @__PURE__ */ jsx(Fragment, { children: actionButton })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(BasicTable, { rowData: filteredDevices, columnData, hidePagination: true }) }),
    devicePossessionToBeReturned && /* @__PURE__ */ jsx(
      OrderActionsDrawer,
      {
        devicePossession: devicePossessionToBeReturned,
        isOpen: isOrderActionsDrawerOpen,
        setIsOpen: setIsOrderActionsDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield refresh();
          setDevicePossessionToBeReturned(null);
          setIsOrderActionsDrawerOpen(false);
        })
      }
    ),
    devicePossessionToBeChanged && /* @__PURE__ */ jsx(
      ChangeOwnerDrawer,
      {
        isOpen: isChangeOwnerDrawerOpen,
        setIsOpen: setIsChangeOwnerDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield refresh();
          setDevicePossessionToBeChanged(null);
        }),
        devicePossession: devicePossessionToBeChanged
      }
    ),
    devicePossessionToBeAssigned && /* @__PURE__ */ jsx(
      DeviceInventoryReassignDrawer,
      {
        isOpen: isAssignDeviceDrawerOpen,
        setIsOpen: setIsAssignDeviceDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield refresh();
          setDevicePossessionToBeAssigned(null);
        }),
        devicePossession: devicePossessionToBeAssigned
      }
    )
  ] });
};
