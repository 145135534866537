"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import {
  AccordionComponent,
  AccordionComponentDetails,
  AccordionComponentSummary
} from "@v2/components/table/accordion.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { FixedSizeList as List } from "react-window";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { translatePageFilters, translatePageFilterSet } from "@/v2/infrastructure/i18n/translate.util";
const countSelectedFilters = (selectedFilters, filtersOptions) => {
  let count = 0;
  const filterOptionsStrings = filtersOptions.flatMap((fO) => Object.keys(fO.filters));
  const singleFilters = selectedFilters.split("&");
  for (const singleFilter of singleFilters) {
    if (!singleFilter) continue;
    const [filter, values] = singleFilter.split("=");
    if (!filterOptionsStrings.includes(filter)) continue;
    count += values.split(",").length;
  }
  return count;
};
const RenderRow = ({ index, style, data }) => {
  const { filtersSet, filter, localSelectedFilters, handleFilterChange, translatePageFilters: translatePageFilters2, polyglot } = data;
  const filterOption = filtersSet.filters[filter][index];
  return /* @__PURE__ */ jsx("div", { style, children: /* @__PURE__ */ jsx(AccordionComponentDetails, { children: filterOption.type === "radio" ? /* @__PURE__ */ jsx(
    FormControlLabel,
    {
      labelPlacement: "end",
      value: filterOption.value,
      checked: Boolean(
        localSelectedFilters[filter] && localSelectedFilters[filter].includes(String(filterOption.value))
      ),
      control: /* @__PURE__ */ jsx(StyledRadio, { disableRipple: true }),
      label: /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "caption",
          onClick: (e) => {
            handleFilterChange(filter, String(filterOption.value), "radio");
            e.stopPropagation();
          },
          children: filterOption.label
        }
      ),
      onClick: () => handleFilterChange(filter, String(filterOption.value), "radio")
    },
    "prorata"
  ) : /* @__PURE__ */ jsx(
    CheckboxComponent,
    {
      label: translatePageFilters2(filterOption, polyglot),
      name: filterOption.label,
      checked: Boolean(
        localSelectedFilters[filter] && localSelectedFilters[filter].includes(String(filterOption.value))
      ),
      onChange: () => handleFilterChange(filter, String(filterOption.value), "checkbox")
    }
  ) }) }, index);
};
const calculateListHeight = (itemCount, itemSize, maxHeight) => {
  const totalHeight = itemCount * itemSize;
  return totalHeight > maxHeight ? window.innerHeight - maxHeight : totalHeight;
};
export const FiltersDrawer = ({
  filtersOptions,
  selectedFilters,
  setSelectedFilters,
  fullWidth = false,
  encodedFilterNames = false,
  name,
  onApply,
  hideClearAll = false
}) => {
  const { polyglot } = usePolyglot();
  const componentName = name != null ? name : polyglot.t("FiltersDrawer.filters");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const noOfAppliedFilters = useMemo(() => {
    if (!selectedFilters) return 0;
    return countSelectedFilters(selectedFilters, filtersOptions);
  }, [filtersOptions, selectedFilters]);
  return /* @__PURE__ */ jsxs(Box, { sx: { width: fullWidth ? "100%" : "auto" }, children: [
    /* @__PURE__ */ jsx(
      FiltersSideDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        filtersOptions,
        selectedFilters,
        setSelectedFilters,
        encodedFilterNames,
        onApply,
        name: componentName,
        hideClearAll
      }
    ),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        onClick: () => {
          setIsDrawerOpen(true);
        },
        sizeVariant: "filter",
        style: { textTransform: "capitalize" },
        colorVariant: noOfAppliedFilters === 0 ? "secondary" : "active",
        fullWidth,
        children: noOfAppliedFilters === 0 ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: componentName }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: componentName }),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px",
                minWidth: "16px",
                height: "16px",
                bgcolor: "white"
              },
              children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "black", sx: { mx: "2px" }, children: noOfAppliedFilters })
            }
          )
        ] })
      }
    )
  ] });
};
const FiltersSideDrawer = ({
  isOpen,
  setIsOpen,
  filtersOptions,
  selectedFilters,
  setSelectedFilters,
  encodedFilterNames,
  onApply,
  name,
  hideClearAll
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        FiltersSideDrawerContent,
        {
          filtersOptions,
          selectedFilters,
          setSelectedFilters,
          setIsOpen,
          encodedFilterNames,
          onApply,
          name,
          hideClearAll
        }
      )
    }
  ) });
};
const FiltersSideDrawerContent = ({
  filtersOptions,
  selectedFilters,
  setSelectedFilters,
  setIsOpen,
  encodedFilterNames,
  onApply,
  name,
  hideClearAll
}) => {
  const { polyglot } = usePolyglot();
  const [localSelectedFilters, setLocalSelectedFilters] = useState({});
  const [expandedFilter, setExpandedFilter] = useState("");
  const filterStringToObject = useCallback(() => {
    const defaultFilterString = {};
    if (selectedFilters.length > 0) {
      const andString = selectedFilters.split("&");
      if (andString.length > 0) {
        andString.forEach((item) => {
          const [key, values] = item.split("=");
          if (key && values) defaultFilterString[key] = values.split(",");
        });
      }
    }
    setLocalSelectedFilters(defaultFilterString);
  }, [selectedFilters]);
  useEffect(() => {
    filterStringToObject();
  }, [filterStringToObject]);
  const expandFilter = useCallback(
    (filterName) => (event, newExpanded) => {
      setExpandedFilter(newExpanded ? filterName : "");
    },
    []
  );
  const handleFilterChange = useCallback((filter, filterValue, type) => {
    let updatedValues;
    if (!filter) return;
    setLocalSelectedFilters((prevFilters) => {
      var _a, _b;
      const isAlreadyIncluded = prevFilters[filter] && ((_a = prevFilters[filter]) == null ? void 0 : _a.length) && prevFilters[filter].includes(filterValue);
      if (type === "radio") {
        if (isAlreadyIncluded) {
          return __spreadValues({}, prevFilters);
        } else {
          updatedValues = [filterValue];
        }
        return __spreadProps(__spreadValues({}, prevFilters), { [filter]: updatedValues });
      }
      if (isAlreadyIncluded) {
        updatedValues = prevFilters[filter].filter((option) => option !== filterValue);
        if (prevFilters[filter].length <= 1) delete prevFilters[filter];
      } else {
        updatedValues = ((_b = prevFilters[filter]) == null ? void 0 : _b.length) > 0 ? [...prevFilters[filter], filterValue] : [filterValue];
      }
      return __spreadProps(__spreadValues({}, prevFilters), { [filter]: updatedValues });
    });
  }, []);
  const applyFiltersSelections = useCallback(
    (localSelectedFilters2) => __async(void 0, null, function* () {
      let newQueryString = "";
      Object.keys(localSelectedFilters2).forEach((key, index) => {
        const optionsSelected = localSelectedFilters2[key].toString();
        if (optionsSelected.length > 0) {
          if (index === 0) newQueryString += `${key}=${optionsSelected}`;
          else newQueryString += `&${key}=${optionsSelected}`;
        }
      });
      setSelectedFilters == null ? void 0 : setSelectedFilters(newQueryString);
      if (onApply) onApply(newQueryString);
      setIsOpen(false);
    }),
    [setSelectedFilters, onApply, setIsOpen]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: name }),
      !hideClearAll && Object.keys(localSelectedFilters).length > 0 && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10 }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setLocalSelectedFilters({}), sizeVariant: "link", colorVariant: "text", children: polyglot.t("FiltersDrawer.clearAll") }) })
    ] }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g5,
          overflowY: "auto",
          height: "65vh"
        },
        children: [...filtersOptions].map((filtersSet, indexSet) => /* @__PURE__ */ jsxs(Box, { sx: { mb: spacing.m10 }, children: [
          filtersSet.name && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: filtersSet.name }),
          Object.keys(filtersSet.filters).map(
            (filter) => filtersSet.filters[filter].length > 0 ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(AccordionComponent, { expanded: expandedFilter === filter, onChange: expandFilter(filter), children: [
              /* @__PURE__ */ jsx(
                AccordionComponentSummary,
                {
                  "aria-controls": `${filter}-content`,
                  id: `${filter}-header`,
                  sx: { bgcolor: expandedFilter === filter ? themeColors.Background : themeColors.white },
                  children: /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: translatePageFilterSet(encodedFilterNames ? filter.split("-").pop() : filter, polyglot) }),
                    localSelectedFilters[filter] && localSelectedFilters[filter].length > 0 ? /* @__PURE__ */ jsx(
                      Box,
                      {
                        sx: {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          bgcolor: "black",
                          borderRadius: "15px",
                          minWidth: "16px",
                          height: "16px"
                        },
                        children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", children: localSelectedFilters[filter].length })
                      }
                    ) : null
                  ] })
                }
              ),
              /* @__PURE__ */ jsx(
                List,
                {
                  height: calculateListHeight(filtersSet.filters[filter].length, 35, 450),
                  itemCount: filtersSet.filters[filter].length,
                  itemSize: 35,
                  width: "100%",
                  itemData: {
                    filtersSet,
                    filter,
                    localSelectedFilters,
                    handleFilterChange,
                    translatePageFilters,
                    polyglot
                  },
                  children: RenderRow
                }
              )
            ] }) }, filter) : null
          )
        ] }, indexSet))
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          fullWidth: true,
          onClick: () => {
            setIsOpen(false);
          },
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true,
          onClick: () => __async(void 0, null, function* () {
            yield applyFiltersSelections(localSelectedFilters);
          }),
          children: polyglot.t("FiltersDrawer.apply")
        }
      )
    ] })
  ] });
};
