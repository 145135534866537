"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import AttendanceEmpty from "@/images/attendance/AttendanceEmpty.png";
import { Typography } from "@/v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const AttendanceEmptyState = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: spacing.px40, children: [
    /* @__PURE__ */ jsx(Box, { sx: spacing.pt20, children: /* @__PURE__ */ jsx("img", { src: AttendanceEmpty, width: 140, height: 140, alt: "attendance-empty" }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5, mt: spacing.m30 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceEmptyState.noTimeSheets") }),
      /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
        polyglot.t("AttendanceEmptyState.nothingToShow"),
        " "
      ] })
    ] })
  ] });
};
