"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { DeveloperHubNewClientInfoDrawer } from "../../components/developer-hub-client-new-client-info-drawer.component";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsPageSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { DeveloperHubClientGenerateDrawer } from "@/v2/feature/security/security-settings/components/developer-hub-client-generate-drawer.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const publishedPoly = "DeveloperHub.clientTable.published";
const notPublishedPoly = "DeveloperHub.clientTable.notPublished";
export const getIconForAppStatus = (polyglot, published) => {
  return published ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    ChipComponent,
    {
      name: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: [
        /* @__PURE__ */ jsx(OkGreen, { style: { fill: themeColors.Green, marginRight: "4px" } }),
        polyglot.t(`${publishedPoly}`)
      ] }),
      backgroundColor: "white",
      textColor: "Grey",
      border: "light",
      textVariant: "caption"
    }
  ) }) : /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    ChipComponent,
    {
      name: polyglot.t(`${notPublishedPoly}`),
      backgroundColor: "white",
      textColor: "Grey",
      border: "light",
      textVariant: "caption"
    }
  ) });
};
export const DeveloperHubBuildAppsPage = ({ oauthClients, onUpdate }) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState(void 0);
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("DeveloperHub.appName"),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: "appName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.name }) })
      },
      {
        header: () => polyglot.t("DeveloperHub.status"),
        accessorFn: (row) => row,
        id: "status",
        minWidth: 30,
        maxWidth: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => getIconForAppStatus(polyglot, original.published)
      }
    ];
  }, [polyglot]);
  const handleRowClicked = (row) => {
    const rowData = row.original;
    routerHistory.push(generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE, { appId: rowData.id }));
  };
  return /* @__PURE__ */ jsxs(
    SettingsPageSection,
    {
      title: polyglot.t("DeveloperHub.mainPageSideTitle"),
      editSection: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsEditDrawerOpen(true), children: polyglot.t("DeveloperHub.newApp") }) }),
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeveloperHub.buildAppsPageExplainer") }),
        (oauthClients == null ? void 0 : oauthClients.length) && (oauthClients == null ? void 0 : oauthClients.length) > 0 ? /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: oauthClients != null ? oauthClients : [],
            columnData: columns,
            loading: false,
            hidePagination: true,
            externalTdAndThPadding: "0 0px",
            rowClick: handleRowClicked
          }
        ) }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mt: "8px" }, children: polyglot.t("DeveloperHub.noAppsYet") }) }),
        /* @__PURE__ */ jsx(
          DeveloperHubClientGenerateDrawer,
          {
            isOpen: isEditDrawerOpen,
            existingClient: void 0,
            setIsOpen: setIsEditDrawerOpen,
            onClose: () => setIsEditDrawerOpen(false),
            onUpdate,
            setClientInfo
          }
        ),
        clientInfo && /* @__PURE__ */ jsx(
          DeveloperHubNewClientInfoDrawer,
          {
            openInfo: !!clientInfo,
            clientInfo,
            onClose: () => setClientInfo(void 0),
            onUpdate
          }
        )
      ]
    }
  );
};
