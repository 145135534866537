"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { InsuranceUpdateUserPolicyDrawer } from "@/v2/feature/benefits/subfeature/insurance/components/insurance-update-user-policy-drawer.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const InsurancePendingDetails = ({
  insuranceQuote,
  refresh
}) => {
  const [state] = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const { getScopesContext } = useScopes();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g40, alignItems: "center" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap10 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Status" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.Grey }), children: "Pending..." })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap10 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Employees" }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: insuranceQuote.includedUserIds, avatarSize: "small", showLimit: 3 }),
          /* @__PURE__ */ jsx(ScopesControl, { scopes: ["insurance:all"], context: getScopesContext({ userId: state.user.userId }), children: /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: () => {
                setIsOpen(true);
              },
              children: /* @__PURE__ */ jsx(Edit, {})
            }
          ) })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose: () => setIsOpen(false), children: /* @__PURE__ */ jsx(InsuranceUpdateUserPolicyDrawer, { insuranceQuote, refresh, setIsOpen }) })
  ] });
};
