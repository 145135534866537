"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceOrderStatus } from "@v2/feature/device/device.interface";
import { spacing } from "@v2/styles/spacing.styles";
export const DeviceActivationStepActivation = ({ deviceOrder, goToNextStep }) => {
  const isDeviceInShipping = deviceOrder.status === DeviceOrderStatus.Shipping;
  const isDeviceDelivered = deviceOrder.status === DeviceOrderStatus.Delivered;
  return isDeviceInShipping || isDeviceDelivered ? /* @__PURE__ */ jsx(Box, { sx: { height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "400px", gap: spacing.gap20 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Welcome to your new laptop!" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Follow a few simple steps to activate your new device. Only start this flow if you have received the device." }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: goToNextStep, children: "My device arrived" }) })
  ] }) }) : /* @__PURE__ */ jsx(Box, { sx: { height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "400px", gap: spacing.gap20 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Your device was not shipped yet!" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "We will let you know once we ship the device." })
  ] }) });
};
