"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { JobPositionAddEditFormDrawer } from "@/v2/feature/job-position/job-position-settings/features/components/job-position-setting-add-edit-form-drawer.component";
import { JobPositionSettingTable } from "@/v2/feature/job-position/job-position-settings/features/components/job-position-table.component";
import { JobPositionEndpoints } from "@/v2/feature/job-position/job-position.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@/v2/styles/root.styles";
export const JobPositionSettingPage = () => {
  const { polyglot } = usePolyglot();
  const [postSave, setPostSave] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [notificationToEdit, setJobPositionToEdit] = useState(void 0);
  const {
    data: jobPositions,
    isValidating: loadingJobPositions,
    mutate: refreshJobPositions
  } = useApiClient(JobPositionEndpoints.listJobPositions(), { suspense: false });
  const nextPossibleId = useMemo(() => {
    var _a;
    if (!jobPositions) return Math.floor(Math.random() * 1e6).toString();
    const lastJobPosition = jobPositions.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = (_a = lastJobPosition[jobPositions.length - 1]) == null ? void 0 : _a.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1e6).toString();
    }
  }, [jobPositions]);
  const DEFAULT_NEW_JOB_POSITION = {
    id: void 0,
    internalCode: nextPossibleId,
    title: "",
    description: "",
    levelId: void 0
  };
  const refreshJobPositionData = () => __async(void 0, null, function* () {
    if (!refreshJobPositions) return;
    setPostSave(false);
    yield refreshJobPositions();
    setPostSave(true);
  });
  const onEditClick = (jobPosition) => {
    setJobPositionToEdit(jobPosition);
    setEditDrawerOpen(true);
  };
  const onNewJobPositionClick = () => {
    setJobPositionToEdit(DEFAULT_NEW_JOB_POSITION);
    setEditDrawerOpen(true);
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Job positions" }),
        showBack: true,
        showAction: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => onNewJobPositionClick(), children: polyglot.t("JobPositionSettingsPage.newPosition") })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(
        JobPositionSettingTable,
        {
          jobPositions: jobPositions != null ? jobPositions : [],
          loading: !postSave ? loadingJobPositions || loadingJobPositions : false,
          onEditClick
        }
      ),
      notificationToEdit && /* @__PURE__ */ jsx(
        JobPositionAddEditFormDrawer,
        {
          isOpen: editDrawerOpen,
          setIsOpen: setEditDrawerOpen,
          jobPositionToEdit: notificationToEdit,
          onClose: () => {
            setEditDrawerOpen(false);
            setJobPositionToEdit(void 0);
          },
          refreshJobPositions: refreshJobPositionData
        }
      )
    ] })
  ] });
};
