"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useCallback } from "react";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAllowanceSection } from "@v2/feature/absence/subfeatures/settings/policy-details/policy-allowance-section.component";
import { AbsencePolicyApprovalSection } from "@v2/feature/absence/subfeatures/settings/policy-details/policy-approval-section.component";
import { AbsencePolicyGeneralSection } from "@v2/feature/absence/subfeatures/settings/policy-details/policy-general-section.component";
import { AbsencePolicyMembersSection } from "@v2/feature/absence/subfeatures/settings/policy-details/policy-members-section.component";
import { AbsencePolicyNotificationsSection } from "@v2/feature/absence/subfeatures/settings/policy-details/policy-notifications-section.component";
import { AbsencePolicyPayrollSection } from "@v2/feature/absence/subfeatures/settings/policy-details/policy-payroll-section.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import {
  SETTINGS_TIME_POLICIES_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ALLOWANCE_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_APPROVAL_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_MEMBERS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_NOTIFICATIONS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_PAYROLL_ROUTE
} from "@/lib/routes";
export const getPageConfig = (policyId, inPayroll, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("AbsencePolicyRouter.general"),
          stub: "general",
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE, { policyId })
        },
        {
          title: polyglot.t("AbsencePolicyRouter.allowance"),
          stub: "allowance",
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_ALLOWANCE_ROUTE, { policyId })
        },
        {
          title: polyglot.t("AbsencePolicyRouter.members"),
          stub: "members",
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_MEMBERS_ROUTE, { policyId })
        },
        {
          title: polyglot.t("AbsencePolicyRouter.approval"),
          stub: "approval",
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_APPROVAL_ROUTE, { policyId })
        },
        {
          title: polyglot.t("AbsencePolicyRouter.notifications"),
          stub: "notifications",
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_NOTIFICATIONS_ROUTE, { policyId })
        },
        {
          title: polyglot.t("AbsencePolicyRouter.payroll"),
          stub: "payroll",
          path: generatePath(SETTINGS_TIME_POLICY_DETAILS_PAYROLL_ROUTE, { policyId }),
          isHidden: !inPayroll
        }
      ]
    }
  ];
};
export const AbsencePolicyRouter = ({
  refreshPolicies,
  refreshArchivedPolicies
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const params = useParams();
  const policyId = Number(params.policyId);
  const {
    data: absencePolicy,
    mutate: refreshPolicy,
    isLoading
  } = useApiClient(AbsenceEndpoints.getAbsencePolicyExtendedById(policyId), { suspense: false });
  const { companyConfig } = useCompanyConfigState();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshPolicy) yield refreshPolicy();
  }), [refreshPolicy]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_a = absencePolicy == null ? void 0 : absencePolicy.name) != null ? _a : "",
        subtitle: polyglot.t("AbsencePolicyRouter.absencePolicy"),
        pageConfig: getPageConfig(policyId, companyConfig.inGlobalPayroll || companyConfig.inPayroll, polyglot),
        backPath: SETTINGS_TIME_POLICIES_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, children: absencePolicy && /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        AbsencePolicyGeneralSection,
        {
          absencePolicy,
          refreshPolicy: refresh,
          refreshPolicies,
          refreshArchivedPolicies
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICY_DETAILS_ALLOWANCE_ROUTE, children: /* @__PURE__ */ jsx(AbsencePolicyAllowanceSection, { absencePolicy, refresh }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICY_DETAILS_MEMBERS_ROUTE, children: /* @__PURE__ */ jsx(AbsencePolicyMembersSection, { absencePolicy, refresh }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICY_DETAILS_APPROVAL_ROUTE, children: /* @__PURE__ */ jsx(AbsencePolicyApprovalSection, { absencePolicy, refresh }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICY_DETAILS_NOTIFICATIONS_ROUTE, children: /* @__PURE__ */ jsx(AbsencePolicyNotificationsSection, { absencePolicy, refresh }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICY_DETAILS_PAYROLL_ROUTE, children: /* @__PURE__ */ jsx(AbsencePolicyPayrollSection, { absencePolicy, refresh }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE, exact: true })
    ] }) })
  ] });
};
