"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { ColorBubble } from "@v2/feature/absence/absence.util";
import { AttendanceSettingsNewJobDrawer } from "@v2/feature/attendance/settings/components/attendance-settings-new-job-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
export const AttendanceJobsTable = ({ attendanceTypes, attendancePayMultipliers, refreshJobs }) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [jobToBeEdited, setJobToBeEdited] = useState(null);
  useEffect(() => {
    attendanceTypes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }, [attendanceTypes]);
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: () => polyglot.t("General.name"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s1, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(ColorBubble, { color: original.color }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.name })
        ] })
      },
      {
        id: "description",
        header: () => polyglot.t("General.description"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.description ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.description }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "payMultiplier",
        header: () => polyglot.t("AbsenceModule.payMultiplier"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.payMultiplierId) != null ? _a : 0;
        }),
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: (_b = (_a = original.payMultiplier) == null ? void 0 : _a.name) != null ? _b : "Unknown" }) });
        }
      }
    ],
    [polyglot]
  );
  const handleRowClick = useCallback((row) => {
    setJobToBeEdited(row.original);
    setIsOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(BasicTable, { rowData: attendanceTypes, columnData: columns, rowClick: handleRowClick }),
    /* @__PURE__ */ jsx(
      AttendanceSettingsNewJobDrawer,
      {
        isOpen,
        setIsOpen,
        job: jobToBeEdited,
        refresh: refreshJobs,
        onClose: () => {
          setIsOpen(false);
        },
        afterClose: () => {
          setJobToBeEdited(null);
        },
        attendancePayMultipliers
      }
    )
  ] });
};
