"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx } from "react/jsx-runtime";
import { AttendanceReportType } from "@v2/feature/reports/features/create-report/attendance/attendance-report-type.component";
import { PayrollReportType } from "@v2/feature/reports/features/create-report/payroll/payroll-report-type.component";
import { PeopleReportType } from "@v2/feature/reports/features/create-report/people/people-report-type.component";
import { TimeReportType } from "@v2/feature/reports/features/create-report/time/time-report-type.component";
import { DataDomain } from "@v2/feature/reports/reports.interface";
export const ReportType = (props) => {
  return /* @__PURE__ */ jsx(Fragment, { children: {
    [DataDomain.Devices]: () => /* @__PURE__ */ jsx(Fragment, {}),
    [DataDomain.Payroll]: () => /* @__PURE__ */ jsx(PayrollReportType, {}),
    [DataDomain.People]: () => /* @__PURE__ */ jsx(PeopleReportType, __spreadValues({}, props)),
    [DataDomain.TimeAway]: () => /* @__PURE__ */ jsx(TimeReportType, __spreadValues({}, props)),
    [DataDomain.Attendance]: () => /* @__PURE__ */ jsx(AttendanceReportType, __spreadValues({}, props))
  }[props.formData.domain]() });
};
