"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { RejectDrawer } from "@v2/components/reject-drawer.component";
import { canApproveOrRejectRequest } from "@v2/feature/approval-rule/approval-rule.util";
import { ExpenseStatus } from "@v2/feature/payments/payments.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx, buttonBoxSx } from "@v2/styles/settings.styles";
import { ExpenseAPI } from "@/api-client/expense.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ExpenseApprovalButtons = ({
  selectedExpense,
  onClose,
  onActionPerformed
}) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState(false);
  const [isForceRejectDrawerOpen, setIsForceRejectDrawerOpen] = useState(false);
  const [isUpdatingApproval, setIsUpdatingApproval] = useState(false);
  const canApproveOrReject = !!selectedExpense && canApproveOrRejectRequest(selectedExpense, user.userId);
  const canManageExpenses = hasScopes(["expenses:all"], getScopesContext({ userId: selectedExpense == null ? void 0 : selectedExpense.from }));
  const handleApproveOrReject = useCallback(
    (expenseId, from, status, notes) => __async(void 0, null, function* () {
      try {
        setIsUpdatingApproval(true);
        yield ExpenseAPI.updateExpenseApproval(expenseId, from, { status, approvalNotes: notes != null ? notes : null });
        showMessage(polyglot.t("ExpenseModal.successMessages.update", { status }), "success");
        onClose();
        yield onActionPerformed();
      } catch (e) {
        showMessage(polyglot.t("ExpenseModal.errorMessages.update", { errorMessage: nestErrorMessage(e) }), "error");
      } finally {
        setIsUpdatingApproval(false);
      }
    }),
    [onClose, onActionPerformed, showMessage, polyglot]
  );
  const handleForceApproveOrReject = useCallback(
    (expenseId, from, status, notes) => __async(void 0, null, function* () {
      try {
        setIsUpdatingApproval(true);
        yield ExpenseAPI.updateExpenseForceApproval(expenseId, from, { status, approvalNotes: notes != null ? notes : null });
        showMessage(polyglot.t("ExpenseModal.successMessages.update", { status }), "success");
        setIsUpdatingApproval(false);
        onClose();
        yield onActionPerformed();
      } catch (e) {
        showMessage(polyglot.t("ExpenseModal.errorMessages.update", { errorMessage: nestErrorMessage(e) }), "error");
      } finally {
        setIsUpdatingApproval(false);
      }
    }),
    [onClose, onActionPerformed, showMessage, polyglot]
  );
  const canReject = (selectedExpense == null ? void 0 : selectedExpense.status) !== ExpenseStatus.Rejected && (selectedExpense == null ? void 0 : selectedExpense.status) !== ExpenseStatus.Draft;
  return /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
    canManageExpenses && !canApproveOrReject ? /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { width: "100%" }), children: [
      canReject && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: isUpdatingApproval,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            setIsForceRejectDrawerOpen(true);
          }),
          children: polyglot.t("General.forceReject")
        }
      ),
      (selectedExpense == null ? void 0 : selectedExpense.status) === ExpenseStatus.Pending && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: isUpdatingApproval,
          sizeVariant: "medium",
          colorVariant: "secondary",
          fullWidth: true,
          loading: isUpdatingApproval,
          onClick: () => __async(void 0, null, function* () {
            yield handleForceApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Approved);
          }),
          children: polyglot.t("General.forceApprove")
        }
      )
    ] }) : canApproveOrReject ? /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { width: "100%" }), children: [
      canReject && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: isUpdatingApproval,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            setIsRejectDrawerOpen(true);
          }),
          children: polyglot.t("General.reject")
        }
      ),
      (selectedExpense == null ? void 0 : selectedExpense.status) === ExpenseStatus.Pending && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: isUpdatingApproval,
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true,
          loading: isUpdatingApproval,
          onClick: () => __async(void 0, null, function* () {
            yield handleApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Approved);
          }),
          children: polyglot.t("General.approve")
        }
      )
    ] }) : null,
    selectedExpense && /* @__PURE__ */ jsx(
      RejectDrawer,
      {
        isOpen: isRejectDrawerOpen,
        setIsOpen: setIsRejectDrawerOpen,
        onReject: (notes) => __async(void 0, null, function* () {
          yield handleApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Rejected, notes);
        })
      }
    ),
    selectedExpense && /* @__PURE__ */ jsx(
      RejectDrawer,
      {
        isOpen: isForceRejectDrawerOpen,
        setIsOpen: setIsForceRejectDrawerOpen,
        onReject: (notes) => __async(void 0, null, function* () {
          yield handleForceApproveOrReject(selectedExpense.id, selectedExpense.from, ExpenseStatus.Rejected, notes);
        })
      }
    )
  ] });
};
