"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { TaskFormModal } from "@v2/feature/task/components/task-add-modal/task-form-modal.component";
import { TaskLaunchModal } from "@v2/feature/task/components/task-launch/task-launch-modal.component";
import { PersonalTask } from "@v2/feature/task/pages/personal/components/personal-task.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath } from "react-router-dom";
import { USER_OFFBOARDING_ROUTE } from "@/lib/routes";
export const OffboardingTasksPage = ({ userId, offboardingState, refresh, loading }) => {
  const { polyglot } = usePolyglot();
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState(false);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OffboardingPage.tasks") }),
        showBack: true,
        backPath: generatePath(USER_OFFBOARDING_ROUTE, { userId })
      }
    ),
    offboardingState.tasksData.tasksNo > 0 ? /* @__PURE__ */ jsx(
      PersonalTask,
      {
        userId,
        isTaskFormModalOpen,
        setIsTaskFormModalOpen,
        reach: "userProfile",
        loading,
        forOffboarding: true,
        refreshOffboarding: refresh
      }
    ) : /* @__PURE__ */ jsx(
      EmptyStateOffboardingTasks,
      {
        userId,
        isTaskFormModalOpen,
        setIsTaskFormModalOpen,
        refresh,
        loading
      }
    )
  ] });
};
const EmptyStateOffboardingTasks = ({
  userId,
  isTaskFormModalOpen,
  setIsTaskFormModalOpen,
  refresh,
  loading
}) => {
  const { polyglot } = usePolyglot();
  const [isLaunchChecklistModalOpen, setIsLaunchChecklistModalOpen] = useState(false);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: __spreadValues({}, spacing.pt20), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingTasks.selectChecklist") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, mt: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "secondary",
          sizeVariant: "large",
          onClick: () => {
            setIsTaskFormModalOpen(true);
          },
          children: polyglot.t("OffboardingTasks.addTask")
        }
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "large",
          onClick: () => {
            setIsLaunchChecklistModalOpen(true);
          },
          children: polyglot.t("OffboardingTasks.add")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      TaskFormModal,
      {
        isOpen: isTaskFormModalOpen,
        setIsOpen: setIsTaskFormModalOpen,
        userId,
        formData: null,
        refresh
      }
    ),
    /* @__PURE__ */ jsx(
      TaskLaunchModal,
      {
        isOpen: isLaunchChecklistModalOpen,
        setIsOpen: setIsLaunchChecklistModalOpen,
        onClose: () => setIsLaunchChecklistModalOpen(false),
        refresh,
        assignUserId: userId,
        reach: "user"
      }
    )
  ] });
};
