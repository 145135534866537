"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { ReactComponent as InPayroll } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as NewJoiner } from "@/images/side-bar-icons/ok-orange.svg";
import { ReactComponent as NotInPayroll } from "@/images/side-bar-icons/Rejected-grey.svg";
import { ReactComponent as Leaver } from "@/images/side-bar-icons/Rejected.svg";
import { formatUserPayrollStatus } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
export const UserPayrollStatusCell = ({ status }) => {
  const { polyglot } = usePolyglot();
  const { label, icon } = status;
  return /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: "5px" }, children: [
    {
      // 'in-payroll': () => <InPayroll />,
      "not-in-payroll": () => /* @__PURE__ */ jsx(NotInPayroll, {}),
      "status-new": () => /* @__PURE__ */ jsx(NewJoiner, {}),
      "status-current": () => /* @__PURE__ */ jsx(InPayroll, { style: { fill: themeColors.Green } }),
      "status-leaver": () => /* @__PURE__ */ jsx(Leaver, { fill: themeColors.Red })
    }[icon](),
    formatUserPayrollStatus(label, polyglot)
  ] });
};
