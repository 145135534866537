"use strict";
export class FiltersEndpoints {
  static getUsersDirectoryFiltersConfig() {
    return {
      url: "/apiv2/filters/people-directory"
    };
  }
  static getCalendarFiltersOptions() {
    return {
      url: `/apiv2/filters/calendar`
    };
  }
  static getTimeFiltersConfig(view, userId) {
    const url = `/apiv2/filters/time/${view}` + (userId ? `?userId=${userId}` : "");
    return { url };
  }
  static getPeopleFiltersOfReports() {
    return {
      url: "/apiv2/filters/reports/people"
    };
  }
  static geTimeFiltersOfReports() {
    return {
      url: "/apiv2/filters/reports/time"
    };
  }
  static getReportsSalaryFilters() {
    return {
      url: "/apiv2/filters/reports/salary"
    };
  }
  static getSurveyFilterOptions() {
    return {
      url: `/apiv2/filters/growth/surveys`
    };
  }
}
