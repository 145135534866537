"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx, tablePrimaryIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/table.styles";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Reject } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { RejectDrawer } from "@/v2/components/reject-drawer.component";
export function AbsenceTableActions({
  absence,
  onApprove,
  onReject,
  onDelete,
  onEdit,
  onRequestCancellation,
  onRejectCancellation,
  onForceApproval
}) {
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mode, setMode] = useState(null);
  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
    setMode(null);
  };
  const showActions = useMemo(
    () => absence.canEdit && onEdit || absence.canDelete && onDelete || absence.canForceApprove && onForceApproval || absence.canForceReject && onForceApproval || absence.canRequestCancellation && onRequestCancellation,
    [absence, onEdit, onDelete, onForceApproval, onRequestCancellation]
  );
  return !showActions ? null : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "5px" }, children: [
    /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: getAbsenceActionsOptions(absence, polyglot, onEdit, onDelete, onForceApproval, onRequestCancellation),
        actionButtonDetails: {
          type: "iconButton",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "actions",
          icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
        }
      }
    ),
    absence.canReject && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tableIconButtonSx,
        onClick: (e) => {
          setAnchorEl(e.currentTarget);
          setMode("reject");
          e.stopPropagation();
        },
        children: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize))
      }
    ),
    absence.canApprove && onApprove && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tablePrimaryIconButtonSx,
        onClick: (e) => __async(this, null, function* () {
          if (onApprove) yield onApprove(absence);
          e.stopPropagation();
        }),
        children: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize))
      }
    ),
    absence.canRejectCancellation && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tableIconButtonSx,
        onClick: (e) => {
          setAnchorEl(e.currentTarget);
          setMode("reject cancellation of");
          e.stopPropagation();
        },
        children: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize))
      }
    ),
    absence.canApproveCancellation && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tablePrimaryIconButtonSx,
        onClick: (e) => {
          setAnchorEl(e.currentTarget);
          setMode("delete");
          e.stopPropagation();
        },
        children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
      }
    ),
    isRejectDrawerOpen && onReject && /* @__PURE__ */ jsx(
      RejectDrawer,
      {
        isOpen: isRejectDrawerOpen,
        setIsOpen: setIsRejectDrawerOpen,
        onReject: (notes) => __async(this, null, function* () {
          yield onReject(absence, notes);
        })
      }
    ),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: mode !== null,
        onClose: () => handleClose(),
        anchorEl,
        takeAction: () => __async(this, null, function* () {
          if (mode === "reject" && absence.canReject) setIsRejectDrawerOpen(true);
          else if (mode === "delete" && (absence.canDelete || absence.canApproveCancellation) && onDelete)
            yield onDelete(absence);
          else if (mode === "reject cancellation of" && onRejectCancellation) yield onRejectCancellation(absence);
          setMode(null);
        }),
        message: polyglot.t("AbsenceTableActions.confirmDelete", { mode }),
        callToAction: polyglot.t("AbsenceTableActions.callToAction")
      }
    )
  ] });
}
const getAbsenceActionsOptions = (absence, polyglot, onEdit, onDelete, onForceApproval, onRequestCancellation) => {
  const actions = [];
  if (absence.canForceApprove && onForceApproval)
    actions.push({
      icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        yield onForceApproval(absence, AbsenceStatus.Approved);
      }),
      label: polyglot.t("AbsenceTableActions.forceApprove"),
      disabled: false
    });
  if (absence.canForceReject && onForceApproval)
    actions.push({
      icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        yield onForceApproval(absence, AbsenceStatus.Rejected);
      }),
      label: polyglot.t("AbsenceTableActions.forceReject"),
      disabled: false
    });
  if (absence.canEdit && onEdit)
    actions.push({
      icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
      handler: () => {
        onEdit(absence);
      },
      label: polyglot.t("AbsenceTableActions.edit"),
      disabled: false
    });
  if (absence.canDelete && onDelete)
    actions.push({
      icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        yield onDelete(absence);
      }),
      label: polyglot.t("General.delete")
    });
  if (absence.canRequestCancellation && onRequestCancellation)
    actions.push({
      icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        yield onRequestCancellation(absence);
      }),
      label: polyglot.t("General.cancel")
    });
  return actions;
};
