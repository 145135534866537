"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { useDebouncedCallback } from "use-debounce";
import useMessage from "@/hooks/notification.hook";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyEntryEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-entry.api";
import { SurveyEntriesPersonalStartModal } from "@/v2/feature/growth/surveys/features/survey-entry/survey-entries-personal/components/survey-entries-personal-start-modal.component";
import { getEntryStatus, getSurveyDueDate } from "@/v2/feature/growth/surveys/features/survey-entry/survey-entry.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SurveyCycleEntriesPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const { data: userEntries, isValidating: loadingUserEntries } = useApiClient(
    SurveyEntryEndpoints.getUserEntries(searchInput),
    {
      suspense: false
    }
  );
  const { data: surveyCycleLaunchDetails, isValidating: loadingCycleLaunchDetails } = useApiClient(
    SurveyEntryEndpoints.getSurveyCycleLaunchDetailsForCompanyAndUser(),
    {
      suspense: false
    }
  );
  const [showMessage] = useMessage();
  const surveyCycleLaunchDetailsMap = useMemo(() => {
    const map = /* @__PURE__ */ new Map();
    surveyCycleLaunchDetails == null ? void 0 : surveyCycleLaunchDetails.forEach(
      (eachSurveyCycleLaunch) => map.set(eachSurveyCycleLaunch.id, eachSurveyCycleLaunch)
    );
    return map;
  }, [surveyCycleLaunchDetails]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(void 0);
  const tableColumns = useMemo(
    () => [
      {
        header: () => "Survey name",
        id: "cycle.displayName",
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return ((_a = original.cycle) == null ? void 0 : _a.displayName) ? /* @__PURE__ */ jsx("div", { children: original.cycle.displayName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Anonymous",
        id: "anonymous",
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return ((_b = (_a = original.cycle) == null ? void 0 : _a.visibilitySettings) == null ? void 0 : _b.anonymiseAnswers) ? /* @__PURE__ */ jsx("div", { children: "Yes" }) : /* @__PURE__ */ jsx("div", { children: "No" });
        }
      },
      {
        header: () => "Questions",
        id: "questionCount",
        size: 200,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return (original == null ? void 0 : original.cycleId) && surveyCycleLaunchDetailsMap.has(original.cycleId) ? (_a = surveyCycleLaunchDetailsMap == null ? void 0 : surveyCycleLaunchDetailsMap.get(original == null ? void 0 : original.cycleId)) == null ? void 0 : _a.totalQuestions : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Due date",
        id: "duedate",
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: getSurveyDueDate(original).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }) })
      },
      {
        header: () => "Status",
        id: "state",
        size: 240,
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: getEntryStatus()[original.entryState] })
      }
    ],
    [surveyCycleLaunchDetailsMap]
  );
  const handleRowClick = useCallback((row) => {
    var _a;
    if (((_a = row.original.cycle) == null ? void 0 : _a.state) !== CycleState.Completed) {
      setIsOpen(true);
      setSelectedEntry(row.original);
    }
  }, []);
  const debouncedSearch = useDebouncedCallback((event) => __async(void 0, null, function* () {
    try {
      setSearchInput(event.target.value);
    } catch (error) {
      showMessage("Failed to handle search", "error");
    }
  }), 300);
  const handleSearch = useCallback((event) => debouncedSearch.callback(event), [
    debouncedSearch
  ]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Surveys" }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      !loadingUserEntries && userEntries && userEntries.length === 0 && !searchInput ? /* @__PURE__ */ jsx(
        EmptyStateBox,
        {
          title: "Your invites",
          subtitle: "Here you will find invitations to surveys. There are no pending invites for you at the moment."
        }
      ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
        /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: handleSearch }),
        /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: userEntries ? [...userEntries] : [],
            columnData: tableColumns,
            loading: loadingUserEntries || loadingCycleLaunchDetails,
            hidePagination: true,
            rowClick: handleRowClick
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        SurveyEntriesPersonalStartModal,
        {
          isOpen,
          setIsOpen,
          onClose: () => {
            setIsOpen(false);
            setTimeout(() => {
              setSelectedEntry(void 0);
            }, 400);
          },
          selectedEntry,
          afterClose: () => setSelectedEntry(void 0)
        }
      )
    ] })
  ] });
};
