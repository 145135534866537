"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, FormControl } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { generatePath, useHistory } from "react-router-dom";
import { TemplateAPI } from "@/api-client/templates.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from "@/lib/routes";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { ClearIconButton } from "@/v2/components/theme-components/clear-icon-button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UpgradeToProModal, PlanNames } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { StyledTextfield } from "@/v2/styles/textfield.styles";
export const TemplateModal = ({ setOpen, open, documentTypes }) => {
  const { polyglot } = usePolyglot();
  const [templateName, setTemplateName] = useState("");
  const [showMessage] = useMessage();
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const routerHistory = useHistory();
  const createTemplate = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsCreatingTemplate(true);
      const templateId = yield TemplateAPI.createTemplate({
        name: templateName,
        templateText: "",
        type: documentType
      });
      routerHistory.push(generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, { templateId }), {
        returnPath: generatePath(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE)
      });
      setIsCreatingTemplate(false);
      setOpen(false);
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(
          polyglot.t("OnboardingByUser.errors.templateCreationFailed", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    } finally {
      setIsCreatingTemplate(false);
    }
  }), [polyglot, showMessage, templateName, setOpen, routerHistory, documentType]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OnboardingByUser.newTemplate") }),
    /* @__PURE__ */ jsx(FormControl, { children: /* @__PURE__ */ jsx(
      StyledTextfield,
      {
        label: polyglot.t("OnboardingByUser.name"),
        size: "small",
        required: true,
        value: templateName,
        onChange: ({ target: { value } }) => setTemplateName(value),
        fullWidth: true,
        variant: "standard",
        InputLabelProps: { shrink: true },
        InputProps: {
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: templateName && (templateName == null ? void 0 : templateName.length) > 0 && /* @__PURE__ */ jsx(ClearIconButton, { onClick: () => setTemplateName("") }) })
        }
      }
    ) }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "createTemplateType",
        label: polyglot.t("OnboardingByUser.templateType"),
        value: documentType,
        options: documentTypes.map((d) => ({ label: d.label, value: d.value })),
        onChange: (e) => setDocumentType(e.target.value),
        required: true
      },
      "createTemplateType"
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        name: polyglot.t("General.save"),
        loading: isCreatingTemplate,
        disabled: !templateName || !documentType,
        onClick: createTemplate,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) });
};
