"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class DocumentTypeAPI {
  static createNewCustomDocumentType(documentType) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/documents/custom-types", documentType);
    });
  }
  static updateCustomDocumentType(documentType) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/documents/custom-types/${documentType.id}`, documentType);
    });
  }
  static getAllDocumentTypesForSettings() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/documents/custom-types/settings")).data;
    });
  }
  static hideCustomDocumentType(documentType) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/documents/custom-types/${documentType.id}/hide`);
    });
  }
  static showCustomDocumentType(documentType) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/documents/custom-types/${documentType.id}/show`);
    });
  }
  static deleteCustomDocumentType(documentTypeId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/documents/custom-types/${documentTypeId}`);
    });
  }
}
