"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { PensionSummaryPage } from "@v2/feature/benefits/subfeature/pension/pension-summary.page";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE,
  PENSION_COMPANY_ROUTE,
  PENSION_COMPANY_SUMMARY_ROUTE
} from "@/lib/routes";
export const PensionCompanyRouter = ({ pensionSchemes, loading }) => {
  const userPensionState = useState([]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["pension:all"], path: PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE, children: /* @__PURE__ */ jsx(
      PensionSummaryPage,
      {
        pensionSchemes,
        userPensionState,
        loadingParent: loading
      }
    ) }),
    /* @__PURE__ */ jsx(Route, { path: PENSION_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: PENSION_COMPANY_SUMMARY_ROUTE }) })
  ] });
};
