"use strict";
import { jsx } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { TIME_TEAM_CALENDAR_ROUTE } from "@/lib/routes";
import { CalendarPage } from "@/v2/feature/calendar/features/calendar.page";
export const CalendarTeamRouter = ({
  calendarAdvEventsTimeline,
  setCalendarAdvEventsTimeline,
  setLoading,
  loading
}) => {
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { path: [TIME_TEAM_CALENDAR_ROUTE], children: /* @__PURE__ */ jsx(
    CalendarPage,
    {
      view: "team",
      calendarAdvEventsTimeline,
      setCalendarAdvEventsTimeline,
      setLoading,
      loading
    }
  ) }) });
};
