"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { PlusOneNextDayComponent } from "@v2/components/plus-one-next-day.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { getTotalLengthOfDayFromScheduleEntries } from "@v2/feature/attendance/attendance.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
export const AttendanceScheduleDayEntryDrawer = ({
  isOpen,
  setIsOpen,
  day,
  weekIndex,
  dayEntry,
  schedule,
  onClose,
  afterClose,
  refresh,
  attendancePayMultipliers,
  attendanceTypes
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: day && weekIndex !== null ? /* @__PURE__ */ jsx(
    AttendanceScheduleDayEntryDrawerContent,
    {
      setIsOpen,
      day,
      weekIndex,
      dayEntry,
      schedule,
      refresh,
      attendancePayMultipliers,
      attendanceTypes
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
export const AttendanceScheduleDayEntryDrawerContent = ({
  setIsOpen,
  day,
  weekIndex,
  dayEntry,
  schedule,
  refresh,
  attendancePayMultipliers,
  attendanceTypes
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const jobOptions = useMemo(() => {
    const options = attendanceTypes.map((j) => ({ value: j.id, label: j.name, description: j.description }));
    options.sort((a, b) => a.label > b.label ? 1 : -1);
    return options;
  }, [attendanceTypes]);
  const allowedPayMultipliers = useMemo(() => {
    const attendancePayMultipliersIdsSet = new Set(schedule.attendanceTypesAllowed.map((j) => j.payMultiplierId));
    return attendancePayMultipliers.filter((p) => attendancePayMultipliersIdsSet.has(p.id));
  }, [attendancePayMultipliers, schedule.attendanceTypesAllowed]);
  const multiplierOptions = useMemo(() => {
    return allowedPayMultipliers.map((m) => ({ value: m.id, label: m.name }));
  }, [allowedPayMultipliers]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        const data = {
          from: values.from,
          to: values.to,
          fromTimestamp: values.fromTimestamp,
          toTimestamp: values.toTimestamp,
          typeId: Number(values.typeId),
          payMultiplierId: Number(values.payMultiplierId),
          totalHours: values.totalHours
        };
        if (dayEntry == null ? void 0 : dayEntry.id)
          yield AttendanceAPI.updateAttendanceJobWorkEntryByDayAndWeekIndex(
            schedule.id,
            dayEntry.id,
            weekIndex,
            day,
            data
          );
        else yield AttendanceAPI.addAttendanceJobWorkEntryByDayAndWeekIndex(schedule.id, weekIndex, day, data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      }
    }),
    [schedule, day, polyglot, showMessage, setIsOpen, refresh, dayEntry, weekIndex]
  );
  const deleteEntry = useCallback(() => __async(void 0, null, function* () {
    if (!(dayEntry == null ? void 0 : dayEntry.id)) return;
    try {
      yield AttendanceAPI.deleteAttendanceJobWorkEntryByDayAndWeekIndex(schedule.id, dayEntry.id, weekIndex, day);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
  }), [refresh, showMessage, polyglot, dayEntry, schedule, setIsOpen, weekIndex, day]);
  const formik = useFormik({
    initialValues: {
      from: (_a = dayEntry == null ? void 0 : dayEntry.from) != null ? _a : "",
      to: (_b = dayEntry == null ? void 0 : dayEntry.to) != null ? _b : "",
      fromTimestamp: (_c = dayEntry == null ? void 0 : dayEntry.fromTimestamp) != null ? _c : null,
      toTimestamp: (_d = dayEntry == null ? void 0 : dayEntry.toTimestamp) != null ? _d : null,
      typeId: (_e = dayEntry == null ? void 0 : dayEntry.typeId) != null ? _e : null,
      payMultiplierId: (_j = (_i = (_g = dayEntry == null ? void 0 : dayEntry.payMultiplierId) != null ? _g : (_f = allowedPayMultipliers.find((p) => p.value === 1)) == null ? void 0 : _f.value) != null ? _i : (_h = allowedPayMultipliers[0]) == null ? void 0 : _h.id) != null ? _j : null,
      totalHours: (_k = dayEntry == null ? void 0 : dayEntry.totalHours) != null ? _k : ""
    },
    validationSchema: yup.object({
      from: yup.string().min(5, polyglot.t("validation.requiredField")).required(polyglot.t("validation.requiredField")),
      fromTimestamp: yup.date().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
      to: yup.string().min(5, polyglot.t("validation.requiredField")).required("End time is required"),
      toTimestamp: yup.date().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
      typeId: yup.number().typeError(polyglot.t("validation.selectValid")).positive(polyglot.t("validation.selectValid")).integer(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
      payMultiplierId: yup.number().typeError(polyglot.t("validation.selectValid")).positive(polyglot.t("validation.selectValid")).integer(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
      totalHours: schedule.isFlexible ? yup.string().required(polyglot.t("validation.requiredField")) : yup.string().notRequired()
    }),
    onSubmit
  });
  const isOvernight = useMemo(() => {
    return formik.values.from && formik.values.to && formik.values.from > formik.values.to;
  }, [formik.values.from, formik.values.to]);
  const attendancePayMultipliersById = useMemo(() => new Map(attendancePayMultipliers.map((m) => [m.id, m])), [
    attendancePayMultipliers
  ]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: `${polyglot.t(`Days.${day}`)} (${polyglot.t("AttendanceDomain.weekNo", {
        weekNo: weekIndex + 1
      })})` }),
      /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: deleteEntry, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
    ] }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "typeId",
        label: polyglot.t("AttendanceDomain.jobType"),
        options: jobOptions,
        value: (_l = formik.values.typeId) != null ? _l : "",
        compareValue: formik.values.typeId,
        onChange: formik.handleChange,
        error: Boolean(!!formik.errors.typeId && formik.touched.typeId),
        helperText: formik.touched.typeId && formik.errors.typeId
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "payMultiplierId",
        label: polyglot.t("AbsenceModule.payMultiplier"),
        options: multiplierOptions,
        value: (_m = formik.values.payMultiplierId) != null ? _m : "",
        compareValue: formik.values.payMultiplierId,
        onChange: formik.handleChange,
        error: Boolean(!!formik.errors.payMultiplierId && formik.touched.payMultiplierId),
        helperText: formik.touched.payMultiplierId && formik.errors.payMultiplierId
      }
    ),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "grid",
          gridTemplateColumns: "1.65fr 1.65fr 0.7fr",
          gridGap: spacing.g10,
          alignItems: "center",
          height: "auto",
          marginTop: "10px"
        },
        children: [
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            TimePickerComponent,
            {
              textFieldKey: "from",
              label: schedule.isFlexible ? polyglot.t("FlexibleRowComponent.coreStart") : polyglot.t("ScheduleRow.from"),
              value: (_n = formik.values.from) != null ? _n : "",
              onChange: (event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                  formik.setFieldValue("from", date.toFullString());
                  formik.setFieldValue("fromTimestamp", date.getDate());
                }
              },
              fullWidth: true,
              error: Boolean(!!formik.errors.from && formik.touched.from),
              helperText: formik.touched.from ? formik.errors.from : ""
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            TimePickerComponent,
            {
              textFieldKey: "to",
              label: schedule.isFlexible ? polyglot.t("FlexibleRowComponent.coreEnd") : polyglot.t("ScheduleRow.to"),
              value: (_o = formik.values.to) != null ? _o : "",
              onChange: (event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                  formik.setFieldValue("to", date.toFullString());
                  formik.setFieldValue("toTimestamp", date.getDate());
                }
              },
              fullWidth: true,
              error: Boolean(!!formik.errors.to && formik.touched.to),
              helperText: formik.touched.to ? formik.errors.to : ""
            }
          ) }),
          schedule.isFlexible ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            TimePickerComponent,
            {
              textFieldKey: "totalHours",
              label: polyglot.t("FlexibleRowComponent.totalHours"),
              value: (_p = formik.values.totalHours) != null ? _p : "",
              onChange: (event) => {
                const time = event.target.value;
                if (isValidTimeString(time)) {
                  const date = new LocalDate(`${new LocalDate().toDateString()}T${time}:00`);
                  formik.setFieldValue("totalHours", date.toFullString());
                }
              },
              fullWidth: true,
              error: Boolean(!!formik.errors.totalHours && formik.touched.totalHours),
              helperText: formik.touched.totalHours ? formik.errors.totalHours : ""
            }
          ) }) : /* @__PURE__ */ jsxs(Box, { children: [
            isOvernight && /* @__PURE__ */ jsx(PlusOneNextDayComponent, {}),
            /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { whiteSpace: "nowrap", textAlign: "center" }, children: convertMinutesToClockHours(
              getTotalLengthOfDayFromScheduleEntries(
                [formik.values],
                attendancePayMultipliersById,
                schedule.isFlexible
              ),
              polyglot
            ) }) })
          ] })
        ]
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, children: dayEntry ? polyglot.t("General.update") : polyglot.t("General.add") }) })
  ] }) });
};
