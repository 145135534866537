"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { recentDateStrings } from "@/v2/util/date-format.util";
export const OnboardingUserStats = ({ progressPercent, pendingTaskCount, startDate, sx }) => {
  const { polyglot } = usePolyglot();
  const startInfo = useMemo(() => {
    if (!startDate) return null;
    const { yesterday, today, tomorrow } = recentDateStrings();
    if (startDate === today) {
      return {
        label: polyglot.t("OnboardingUserStats.started"),
        value: polyglot.t("OnboardingUserStats.today"),
        date: startDate
      };
    }
    if (startDate === yesterday) {
      return {
        label: polyglot.t("OnboardingUserStats.started"),
        value: polyglot.t("OnboardingUserStats.yesterday"),
        date: startDate
      };
    }
    if (startDate === tomorrow) {
      return {
        label: polyglot.t("OnboardingUserStats.starting"),
        value: polyglot.t("OnboardingUserStats.tomorrow"),
        date: startDate
      };
    }
    return {
      label: today < startDate ? polyglot.t("OnboardingUserStats.startingIn") : polyglot.t("OnboardingUserStats.started"),
      // don't use formatDistanceToNow because it rounds incorrectly
      value: formatDistanceStrict(Date.parse(startDate), Date.parse(today), { addSuffix: today >= startDate }),
      date: startDate
    };
  }, [startDate, polyglot]);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", gap: spacing.g60 }, sx), children: [
    Number.isFinite(progressPercent) && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingUserStats.progress") }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { whiteSpace: "nowrap", color: themeColors.DarkGrey }), children: [
        Math.min(Math.max(Math.round(progressPercent), 0), 100),
        "%"
      ] })
    ] }),
    Number.isInteger(pendingTaskCount) && pendingTaskCount >= 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingUserStats.pending") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { whiteSpace: "nowrap", color: themeColors.DarkGrey }), children: polyglot.t("OnboardingUserStats.taskCount", { smart_count: pendingTaskCount }) })
    ] }),
    startInfo && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: startInfo.label }),
      /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.title2), { whiteSpace: "nowrap", color: themeColors.DarkGrey }),
          title: (
            // appending a time component to the date should force the result to be in local time, without tz adjustments
            (/* @__PURE__ */ new Date(`${startInfo.date}T12:00`)).toLocaleDateString(void 0, { dateStyle: "full" })
          ),
          children: startInfo.value
        }
      )
    ] })
  ] });
};
