"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { UserBenefitPaymentDrawer } from "@v2/feature/benefits/components/user-benefit-payment-drawer.component";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const BenefitPaymentActionItem = ({
  pendingBenefitPaymentTodo,
  refresh,
  afterClose
}) => {
  var _a, _b;
  const { data } = useApiClient(
    CustomBenefitEndpoints.getCustomBenefitPaymentAndRemainingBalance(
      pendingBenefitPaymentTodo.customBenefitId,
      pendingBenefitPaymentTodo.userId,
      pendingBenefitPaymentTodo.userCustomBenefitId,
      pendingBenefitPaymentTodo.id
    ),
    { suspense: false }
  );
  const payment = (_a = data == null ? void 0 : data.payment) != null ? _a : null;
  const remainingBalance = (_b = data == null ? void 0 : data.remainingBalance) != null ? _b : null;
  const [isViewOpen, setIsViewOpen] = useState(false);
  useEffect(() => {
    setIsViewOpen(Boolean(pendingBenefitPaymentTodo));
  }, [pendingBenefitPaymentTodo]);
  return /* @__PURE__ */ jsx(
    UserBenefitPaymentDrawer,
    {
      isOpen: isViewOpen,
      setIsOpen: setIsViewOpen,
      userBenefitPayment: payment,
      remainingBalance,
      refresh,
      onClose: () => {
        setIsViewOpen(false);
      },
      afterClose
    }
  );
};
