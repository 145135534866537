"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class GlobalPayrollAPI {
  static openPayrun(payrollId, taxYear, payPeriod) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/open`)).data || null;
    });
  }
  static reopenPayrun(payrollId, taxYear, payPeriod, period) {
    return __async(this, null, function* () {
      return (yield axios.post(
        `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/reopen`
      )).data || null;
    });
  }
  static updatePayrunEntries(payrollId, taxYear, payPeriod, period, update) {
    return __async(this, null, function* () {
      return (yield axios.patch(
        `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/entries`,
        { update }
      )).data;
    });
  }
  static deletePayrun(payrollId, taxYear, payPeriod, period) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}`);
    });
  }
  static finalisePayrun(payrollId, taxYear, payPeriod, period) {
    return __async(this, null, function* () {
      return (yield axios.post(
        `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/finalise`
      )).data || null;
    });
  }
  static createPaycode(payrollId, update) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/salary/payroll/${payrollId}/global/paycode`, update)).data;
    });
  }
  static updatePaycode(payrollId, update) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/company/salary/payroll/${payrollId}/global/paycode`, update)).data;
    });
  }
  static deletePaycode(payrollId, codeToDelete) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/company/salary/payroll/${payrollId}/global/paycode/${codeToDelete}`);
    });
  }
  static getPayrunForUserId(payrollId, taxYear, payPeriod, period, userId) {
    return __async(this, null, function* () {
      return (yield axios.get(
        `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/${userId}`
      )).data;
    });
  }
  static updatePaycodeSeedersAsSuperAdmin(countryCode, paycodes) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/company/salary/payroll/0/global/paycode/seeders?country=${countryCode}`, paycodes);
    });
  }
}
export class GlobalPayrollEndpoints {
  static getPayrun(payrollId, taxYear, payPeriod, period) {
    return {
      url: `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}`
    };
  }
  static getPayrollPaycodes(payrollId) {
    return {
      url: `/apiv2/company/salary/payroll/${payrollId}/global/paycodes`
    };
  }
  static exportPayrun(payrollId, taxYear, payPeriod, period) {
    return {
      url: `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/export`
    };
  }
  static getPaycodeSeedersAsSuperAdmin() {
    return {
      url: "/apiv2/company/salary/payroll/0/global/paycode/seeders"
    };
  }
}
