"use strict";
import {
  APPS_COMPANY_OVERVIEW_ROUTE,
  APPS_PERSONAL_OVERVIEW_ROUTE,
  APPS_REQUESTS_ROUTE,
  APP_COMPANY_STORE_ROUTE,
  APP_STORE_ROUTE
} from "@/lib/routes";
export const TECH_APPS_OVERVIEW_ROUTES = {
  company: APPS_COMPANY_OVERVIEW_ROUTE,
  me: APPS_PERSONAL_OVERVIEW_ROUTE
};
export const TECH_APPS_REQUEST_ROUTES = {
  company: APPS_REQUESTS_ROUTE
};
export const TECH_APPS_STORE_ROUTES = {
  company: APP_COMPANY_STORE_ROUTE,
  me: APP_STORE_ROUTE
};
export const TECH_APPS_OVERVIEW_SCOPES = {
  company: ["apps:all"],
  me: ["apps"]
};
export const TECH_APPS_REQUEST_SCOPES = {
  company: ["apps:all"]
};
export const TECH_APPS_STORE_SCOPES = {
  company: ["apps:all"],
  me: ["apps"]
};
