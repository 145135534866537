"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box, Slide, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { showSidebar } from "@/v2/util/app-layout.util";
const SECOND_DRAWER_WIDTH = 199;
const SecondMenuDesktopRootStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  flex: `0 0 ${SECOND_DRAWER_WIDTH}px`,
  maxWidth: `${SECOND_DRAWER_WIDTH}px`,
  overflowY: "auto",
  overflowX: "hidden",
  boxSizing: "border-box",
  "scrollbar-width": "1px",
  borderRight: borders.light
});
const SecondMenuTabletInnerStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  width: "100%",
  background: themeColors.white,
  overflowY: "auto",
  overflowX: "hidden",
  flex: `0 0 ${SECOND_DRAWER_WIDTH}px`,
  boxSizing: "border-box",
  maxWidth: `${SECOND_DRAWER_WIDTH}px`,
  borderRight: borders.light
});
const SecondMenuTabletStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  width: "100%",
  background: "#e2dfdc78",
  zIndex: 4,
  position: "absolute",
  left: 60
});
export const DomainSideMenu = ({ children }) => {
  const [state] = useContext(GlobalContext);
  const routerLocation = useLocation();
  const shouldShowSidebar = showSidebar(routerLocation.pathname);
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isTabletScreen = useMediaQuery(
    `(min-width:${theme.breakpoints.values.sm + 1}px) and (max-width:${theme.breakpoints.values.md - 1}px)`
  );
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (!shouldShowSidebar) {
    return null;
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    isDesktopScreen && /* @__PURE__ */ jsx(SecondMenuDesktopRootStyle, { children: /* @__PURE__ */ jsx(
      Slide,
      {
        direction: state.user.language !== "ar" ? "right" : "left",
        in: true,
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: { enter: 300, exit: 300 },
        children: /* @__PURE__ */ jsx(Box, { sx: { height: "100%", px: spacing.p10, width: "100%", boxSizing: "border-box" }, children })
      }
    ) }),
    (isTabletScreen || isMobileScreen) && /* @__PURE__ */ jsx(SecondMenuTabletStyle, { children: /* @__PURE__ */ jsx(SecondMenuTabletInnerStyle, { children: /* @__PURE__ */ jsx(
      Slide,
      {
        direction: state.user.language !== "ar" ? "right" : "left",
        in: true,
        mountOnEnter: true,
        unmountOnExit: true,
        timeout: { enter: 300, exit: 300 },
        children: /* @__PURE__ */ jsx(Box, { sx: { height: "100%", px: spacing.p10, width: "100%", boxSizing: "border-box" }, children })
      }
    ) }) })
  ] });
};
