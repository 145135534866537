"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ReportDisplayFormat } from "@v2/feature/reports/reports-formatting.util";
import { ReportColumnType } from "@v2/feature/reports/reports.interface";
export function unselectColumnUtil(prevSelection, stub, col, type) {
  if (!prevSelection[stub]) return prevSelection;
  const removedCol = prevSelection[stub].find((c) => {
    var _a;
    return c.col === col && ((_a = c.type) != null ? _a : ReportColumnType.PLAIN) === type;
  });
  if (!removedCol) return prevSelection;
  const newSelectedColumns = __spreadProps(__spreadValues({}, prevSelection), {
    [stub]: prevSelection[stub].filter((c) => {
      var _a;
      return c.col !== col || ((_a = c.type) != null ? _a : ReportColumnType.PLAIN) !== type;
    })
  });
  if (newSelectedColumns[stub].length === 0) delete newSelectedColumns[stub];
  for (const tableStub in newSelectedColumns) {
    newSelectedColumns[tableStub] = newSelectedColumns[tableStub].map((c) => __spreadProps(__spreadValues({}, c), {
      // if order > removed col order, subtract 1, otherwise keep the same
      order: c.order > removedCol.order ? c.order - 1 : c.order
    }));
  }
  return newSelectedColumns;
}
export function selectColumnUtil(prevSelection, stub, col, type, label, secondSelection) {
  if (prevSelection[stub] && prevSelection[stub].some((c) => {
    var _a;
    return c.col === col && ((_a = c.type) != null ? _a : ReportColumnType.PLAIN) === type;
  }))
    return prevSelection;
  let maxOrder = 0;
  for (const stub2 in prevSelection) {
    for (const col2 of prevSelection[stub2]) {
      if (col2.order > maxOrder) maxOrder = col2.order;
    }
  }
  if (secondSelection) {
    for (const stub2 in secondSelection) {
      for (const col2 of secondSelection[stub2]) {
        if (col2.order > maxOrder) maxOrder = col2.order;
      }
    }
  }
  const newCol = { col, type, order: maxOrder + 1, label, format: ReportDisplayFormat.none };
  const prevCopy = __spreadValues({}, prevSelection);
  if (!prevCopy[stub]) prevCopy[stub] = [];
  prevCopy[stub].push(newCol);
  return prevCopy;
}
export const ColumnTypeToSign = {
  [ReportColumnType.PLAIN]: null,
  [ReportColumnType.AGE]: "since",
  [ReportColumnType.DAY]: "day",
  [ReportColumnType.MONTH]: "month",
  [ReportColumnType.YEAR]: "year",
  [ReportColumnType.COUNT_DAY_ONLY]: "# day",
  [ReportColumnType.COUNT_MONTH_ONLY]: "# month",
  [ReportColumnType.COUNT_YEAR_ONLY]: "# year",
  [ReportColumnType.MIN]: "min",
  [ReportColumnType.MAX]: "max",
  [ReportColumnType.COUNT]: "#",
  [ReportColumnType.AVG]: "x\u0304",
  [ReportColumnType.SUM]: "\u03A3"
};
