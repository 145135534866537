"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { downloadTemplatesByID } from "@v2/feature/documents/documents.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import { ContractAPI, ContractEndpoints } from "@/api-client/contract.api";
import { TemplateAPI } from "@/api-client/templates.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  DASHBOARD_ROUTE,
  DOCUMENTS_NEW_COMPANY_ROUTE,
  DOCUMENTS_NEW_ME_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE,
  TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX
} from "@/lib/routes";
import { DocumentEditor } from "@/v2/components/forms/document-editor/document-editor.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CompanySignatorySignSignContractModal } from "@/v2/feature/templates/components/company-signatory-sign-contract-modal.component";
import { ContractTemplateEditorActionsButton } from "@/v2/feature/templates/components/contract-template-editor-actions-button.component";
import { MissingPersonalEmployeeFieldsModal } from "@/v2/feature/templates/components/missing-personal-employee-fields-modal.component";
import { UserSignContractModal } from "@/v2/feature/templates/components/user-sign-contract-modal.component";
import "@/v2/feature/templates/pages/template-editor.scss";
import {
  UNPROCESSABLE_FONT_ERROR
} from "@/v2/feature/templates/templates.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { base64Decode, toBase64 } from "@/v2/util/base64.util";
const EDIT_MODES = /* @__PURE__ */ new Set(["edit", "editForRecipient"]);
const MODES_TO_HIDE_CLOSE_BUTTON = /* @__PURE__ */ new Set(["editForRecipient"]);
export const TemplateEditorPage = ({ mode, action }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [innerHtml, setInnerHtml] = useState("");
  const [innerHtmlHasChanged, setInnerHtmlHasChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(void 0);
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [contractTemplate, setContractTemplate] = useState();
  const [signContractPreviewText, setSignContractPreviewText] = useState("");
  const [signaturePendingForCurrentUser, setSignaturePendingForCurrentUser] = useState(false);
  const [witnessPendingForCurrentUser, setWitnessPendingForCurrentUser] = useState(false);
  const [showMessage] = useMessage();
  const { templateId, userId, contractId } = useParams();
  const [isSendingContract, setIsSendingContract] = useState(false);
  const [missingFields, setMissingFields] = useState({});
  const [openMissingEmployeeFieldModal, setOpenMissingEmployeeFieldModal] = useState(false);
  const [openSignContractModal, setOpenSignContractModal] = useState(false);
  const [signature, setSignature] = useState();
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const returnPath = ((_a = routerLocation == null ? void 0 : routerLocation.state) == null ? void 0 : _a.returnPath) || DOCUMENTS_NEW_COMPANY_ROUTE;
  const companySignatoryUserId = (_b = routerLocation == null ? void 0 : routerLocation.state) == null ? void 0 : _b.companySignatoryUserId;
  const contractTemplateData = (_c = routerLocation == null ? void 0 : routerLocation.state) == null ? void 0 : _c.contractTemplateData;
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: contractFinalised } = useApiClient(
    contractId ? ContractEndpoints.contractFinalised(contractId) : { url: void 0 },
    {
      suspense: false
    }
  );
  const currentUserIsCompanySignatory = companySignatoryUserId === (user == null ? void 0 : user.userId) || userId && Number(userId) === (user == null ? void 0 : user.userId);
  const companySignMode = routerLocation.pathname.includes("company-sign");
  const recipientSignMode = routerLocation.pathname.includes(TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX);
  const [contractPreviewUserId, setContractPreviewUserId] = useState();
  const hasMode = useMemo(() => EDIT_MODES.has(mode), [mode]);
  const refreshMissingFieldsForEmployee = useCallback((contractId2) => __async(void 0, null, function* () {
    const missingFieldData = yield ContractAPI.getEmployeeFieldsForContractById(contractId2);
    setMissingFields(missingFieldData);
  }), []);
  const contractHasMissingFields = (_d = Object.values(missingFields)) == null ? void 0 : _d.some((v) => v === true);
  useEffect(() => {
    if (contractId && !companySignMode) refreshMissingFieldsForEmployee(contractId);
  }, []);
  useEffect(() => {
    setInnerHtml(signContractPreviewText || (contractTemplate == null ? void 0 : contractTemplate.templateText) || "");
  }, [signContractPreviewText, contractTemplate == null ? void 0 : contractTemplate.templateText]);
  const contractRecipientPreviewOptions = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => __spreadValues({ label: u.displayName, value: u.userId }, u));
  }, [nonTerminatedCachedUsers]);
  const getMissingFieldsFromContractTemplateData = useCallback(() => {
    var _a2;
    return contractTemplateData ? (_a2 = JSON.parse(base64Decode(contractTemplateData))) == null ? void 0 : _a2.missingTemplateData : void 0;
  }, [contractTemplateData]);
  const checkPendingSignature = useCallback(() => __async(void 0, null, function* () {
    if (contractId) {
      const { signaturePending, witnessPending } = yield ContractAPI.checkIfSignatureAndWitnessPendingForCurrentUser(
        contractId
      );
      setSignaturePendingForCurrentUser(signaturePending);
      setWitnessPendingForCurrentUser(witnessPending);
    }
  }), [contractId]);
  const loadTemplate = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (templateId && userId) {
        let missingTemplateData;
        if (contractTemplateData) {
          missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
        }
        const compiledContractTemplate = yield TemplateAPI.getContractPreview({
          templateId,
          contractRecipientUserId: Number(userId),
          missingTemplateFields: missingTemplateData || void 0,
          companySignatoryUserId: companySignatoryUserId ? Number(companySignatoryUserId) : void 0
        });
        setSignContractPreviewText(compiledContractTemplate.compiledTemplate);
      }
      const contractTemplate2 = yield TemplateAPI.getTemplateById({ templateId });
      setContractTemplate(contractTemplate2);
      yield checkPendingSignature();
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.loadTemplate", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [polyglot, templateId, userId, checkPendingSignature, contractTemplateData, companySignatoryUserId, showMessage]);
  const loadContract = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!contractId) {
        setLoading(false);
        return;
      }
      setLoading(true);
      const { compiledTemplate: contractPreviewText } = companySignMode ? yield ContractAPI.getContractByIdForCompanySign({ contractId }) : yield ContractAPI.getContractPreviewById({ contractId });
      setSignContractPreviewText(contractPreviewText);
      yield checkPendingSignature();
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.loadContract", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
      routerHistory.push(generatePath(DASHBOARD_ROUTE));
    } finally {
      setLoading(false);
    }
  }), [polyglot, contractId, companySignMode, checkPendingSignature, showMessage, routerHistory]);
  const loadUpdatedContract = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (templateId && userId && contractTemplateData) {
        const decodedTemplateData = JSON.parse(base64Decode(contractTemplateData));
        setSignContractPreviewText(decodedTemplateData.compiledTemplateText);
      }
      const contractTemplate2 = yield TemplateAPI.getTemplateById({ templateId });
      setContractTemplate(contractTemplate2);
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.loadTemplate", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [polyglot, showMessage, templateId, setSignContractPreviewText, userId, contractTemplateData]);
  const saveTemplate = useCallback(
    (forPreview = false) => __async(void 0, null, function* () {
      var _a2;
      try {
        if (!contractTemplate || !innerHtml) return;
        setSavingTemplate(true);
        const result = yield TemplateAPI.updateTemplate({
          id: contractTemplate.id,
          templateText: innerHtml,
          type: contractTemplate.type
        });
        if (result.valid) {
          setInnerHtmlHasChanged(false);
          showMessage("Template saved successfully", "success");
        } else {
          if ((_a2 = result.errorStack) == null ? void 0 : _a2.includes(UNPROCESSABLE_FONT_ERROR)) {
            showMessage(`${result.errorMessage}: ${result.errorStack}`, "warning");
          } else {
            showMessage(
              `WARNING: Incompatible fonts used in this template. Please review the template before sending or singing and ensure only Default Font is used.`,
              "warning"
            );
          }
        }
        setSavingTemplate(false);
        if (forPreview) return;
      } catch (error) {
        showMessage(
          polyglot.t("TemplateEditorPage.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setSavingTemplate(false);
      }
    }),
    [polyglot, contractTemplate, innerHtml, showMessage]
  );
  const getEditorActionsOptions = () => {
    const actionOptions = [];
    actionOptions.push({
      icon: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)),
      handler: () => previewTemplate(),
      label: polyglot.t("TemplateEditorPage.preview"),
      disabled: false
    });
    actionOptions.push({
      icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
      handler: () => downloadPreview(),
      label: polyglot.t("General.download"),
      disabled: false
    });
    return actionOptions;
  };
  const previewTemplate = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!contractTemplate || !innerHtml) return;
      if (innerHtmlHasChanged) {
        yield saveTemplate(true);
      }
      routerHistory.push(generatePath(SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE, { templateId: contractTemplate.id }));
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.preview", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setSavingTemplate(false);
    }
  }), [polyglot, contractTemplate, innerHtml, routerHistory, innerHtmlHasChanged, saveTemplate, showMessage]);
  const editContractOnlyForRecipient = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (!contractTemplate || !innerHtml || !userId) return;
      const missingFieldData = getMissingFieldsFromContractTemplateData();
      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE, {
          templateId: contractTemplate.id,
          userId
        }),
        {
          companySignatoryUserId,
          contractTemplateData: toBase64({
            compiledTemplateText: innerHtml,
            missingTemplateData: missingFieldData || void 0
          })
        }
      );
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.edit", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setSavingTemplate(false);
      setLoading(false);
    }
  }), [
    polyglot,
    companySignatoryUserId,
    contractTemplate,
    innerHtml,
    getMissingFieldsFromContractTemplateData,
    routerHistory,
    showMessage,
    userId
  ]);
  const downloadPreview = useCallback(() => __async(void 0, null, function* () {
    try {
      let missingTemplateData;
      if (contractTemplateData) {
        missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
      }
      if (innerHtmlHasChanged && mode === "edit") {
        yield saveTemplate(false);
      }
      const recipientUser = getCachedUserById(Number(userId));
      downloadTemplatesByID(contractTemplate == null ? void 0 : contractTemplate.name, recipientUser == null ? void 0 : recipientUser.displayName, {
        templateId,
        contractRecipientUserId: Number(userId),
        missingTemplateFields: missingTemplateData || void 0,
        companySignatoryUserId: companySignatoryUserId ? Number(companySignatoryUserId) : void 0,
        updatedTemplateText: contractTemplateData ? toBase64({ compiledTemplateText: innerHtml }) : void 0
      });
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.loadTemplate", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [
    polyglot,
    companySignatoryUserId,
    contractTemplate,
    contractTemplateData,
    getCachedUserById,
    showMessage,
    templateId,
    userId,
    innerHtmlHasChanged,
    saveTemplate,
    mode,
    innerHtml
  ]);
  const previewTemplateAsUser = useCallback(
    (userIdForPreview) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        setContractPreviewUserId(userIdForPreview);
        if (templateId && userIdForPreview) {
          let missingTemplateData;
          if (contractTemplateData) {
            missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
          }
          const compiledContractTemplate = yield TemplateAPI.getContractPreview({
            templateId,
            contractRecipientUserId: Number(userIdForPreview),
            missingTemplateFields: missingTemplateData || void 0
          });
          setSignContractPreviewText(compiledContractTemplate.compiledTemplate);
        }
        const contractTemplate2 = yield TemplateAPI.getTemplateById({ templateId });
        setContractTemplate(contractTemplate2);
      } catch (error) {
        showMessage(
          polyglot.t("TemplateEditorPage.errorMessages.loadTemplate", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, showMessage, templateId, setSignContractPreviewText, contractTemplateData]
  );
  const sendContract = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsSendingContract(true);
      let missingTemplateData;
      if (contractTemplateData) {
        missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
      }
      yield ContractAPI.create({
        templateId,
        recipient: Number(userId),
        missingTemplateFields: missingTemplateData || void 0,
        companySignatory: companySignatoryUserId,
        updatedTemplateText: contractTemplateData ? toBase64({ compiledTemplateText: innerHtml }) : void 0
      });
      showMessage(polyglot.t("TemplateEditorPage.successMessages.send"), "success");
      routerHistory.push(returnPath);
      setIsSendingContract(false);
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.send", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsSendingContract(false);
    }
  }), [
    polyglot,
    contractTemplateData,
    innerHtml,
    templateId,
    userId,
    companySignatoryUserId,
    showMessage,
    routerHistory,
    returnPath
  ]);
  const sendContractWithCompanySignature = useCallback(
    (companySignatoryUserId2, companySignature) => __async(void 0, null, function* () {
      var _a2, _b2;
      try {
        setErrorMessage(void 0);
        setIsSendingContract(true);
        let missingTemplateData;
        if (contractTemplateData) {
          missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
          missingTemplateData = __spreadProps(__spreadValues({}, missingTemplateData), { company_signature: companySignature });
        }
        yield ContractAPI.create({
          templateId,
          recipient: Number(userId),
          companySignature: companySignature ? companySignature : void 0,
          missingTemplateFields: missingTemplateData || void 0,
          companySignatory: companySignatoryUserId2,
          updatedTemplateText: contractTemplateData ? toBase64({ compiledTemplateText: innerHtml }) : void 0
        });
        showMessage(polyglot.t("TemplateEditorPage.successMessages.send"), "success");
        routerHistory.push(returnPath);
        setIsSendingContract(false);
      } catch (error) {
        setErrorMessage(
          ((_b2 = (_a2 = error == null ? void 0 : error.response) == null ? void 0 : _a2.data) == null ? void 0 : _b2.message) || (error == null ? void 0 : error.message) || "Issues encountered in creating this document, please try again"
        );
        showMessage(
          polyglot.t("TemplateEditorPage.errorMessages.send", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setIsSendingContract(false);
      }
    }),
    [polyglot, contractTemplateData, innerHtml, templateId, userId, showMessage, routerHistory, returnPath]
  );
  const signContractWithCompanySignature = useCallback(
    (_companySignatoryUserId, companySignature) => __async(void 0, null, function* () {
      var _a2, _b2;
      try {
        setIsSendingContract(true);
        setErrorMessage(void 0);
        if (!contractId) showMessage(polyglot.t("TemplateEditorPage.errorMessages.missingId"), "error");
        if (contractId) {
          yield ContractAPI.companySign({
            contractId,
            signature: companySignature
          });
        }
        showMessage(polyglot.t("TemplateEditorPage.successMessages.signed"), "success");
        routerHistory.push(returnPath);
        setIsSendingContract(false);
      } catch (error) {
        setErrorMessage(
          ((_b2 = (_a2 = error == null ? void 0 : error.response) == null ? void 0 : _a2.data) == null ? void 0 : _b2.message) || (error == null ? void 0 : error.message) || "Issues encountered in creating this document, please try again"
        );
        showMessage(
          polyglot.t("TemplateEditorPage.errorMessages.send", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setIsSendingContract(false);
      }
    }),
    [polyglot, contractId, showMessage, routerHistory, returnPath]
  );
  const userFinalisesContract = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsSendingContract(true);
      if (!contractId) showMessage(polyglot.t("TemplateEditorPage.errorMessages.missingId"), "error");
      if (contractId) {
        yield ContractAPI.finalise({
          contractId
        });
      }
      showMessage(polyglot.t("TemplateEditorPage.successMessages.signed"), "success");
      routerHistory.push(returnPath);
      setIsSendingContract(false);
    } catch (error) {
      showMessage(
        polyglot.t("TemplateEditorPage.errorMessages.send", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setIsSendingContract(false);
    }
  }), [polyglot, contractId, showMessage, routerHistory, returnPath]);
  const saveSignAndSendContract = useCallback(() => __async(void 0, null, function* () {
    if (currentUserIsCompanySignatory) {
      setOpenSignContractModal(true);
    } else {
      sendContract();
    }
  }), [currentUserIsCompanySignatory, sendContract]);
  const updateContractForRecipient = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    const missingFieldData = getMissingFieldsFromContractTemplateData();
    if (templateId && contractTemplateData && userId && innerHtml) {
      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId,
          userId
        }),
        {
          companySignatoryUserId,
          contractTemplateData: toBase64({
            compiledTemplateText: innerHtml,
            missingTemplateData: missingFieldData || void 0
          })
        }
      );
    }
    setLoading(false);
  }), [
    companySignatoryUserId,
    contractTemplateData,
    innerHtml,
    getMissingFieldsFromContractTemplateData,
    routerHistory,
    templateId,
    userId
  ]);
  const hasCompiledTemplateText = (contractTemplateData2) => {
    if (!contractTemplateData2) return false;
    const decodedTemplateData = JSON.parse(base64Decode(contractTemplateData2));
    return !!decodedTemplateData.compiledTemplateText;
  };
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      if (!hasCompiledTemplateText(contractTemplateData)) {
        yield loadTemplate();
        yield loadContract();
      } else {
        loadUpdatedContract();
      }
    }))();
  }, [loadTemplate, loadContract, contractTemplateData, loadUpdatedContract, missingFields, action]);
  const missingFieldsHaveToBeFilledBeforeSigning = !recipientSignMode || recipientSignMode && contractHasMissingFields;
  const handleCloseAction = () => {
    if (action === "sign") {
      routerHistory.push(returnPath ? returnPath : generatePath(DOCUMENTS_NEW_ME_ROUTE));
    } else if (action === "preview") {
      setSignContractPreviewText("");
      routerHistory.push(generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, { templateId }));
      loadTemplate();
    } else if (action === "update_before_sending" && contractTemplateData && userId) {
      let missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId,
          userId
        }),
        {
          companySignatoryUserId,
          contractTemplateData: contractTemplateData ? contractTemplateData : toBase64({
            missingTemplateData
          })
        }
      );
    } else {
      routerHistory.push(generatePath(returnPath));
    }
  };
  const handleContractSignAction = useCallback(() => __async(void 0, null, function* () {
    if (companySignMode && currentUserIsCompanySignatory) {
      setOpenSignContractModal(true);
    } else if (recipientSignMode && signaturePendingForCurrentUser && action === "sign" && !contractHasMissingFields) {
      setOpenSignContractModal(true);
    } else if (recipientSignMode && !signaturePendingForCurrentUser) {
      yield userFinalisesContract();
    } else if (recipientSignMode && signaturePendingForCurrentUser && contractHasMissingFields) {
      setOpenMissingEmployeeFieldModal(true);
    }
  }), [
    action,
    companySignMode,
    contractHasMissingFields,
    currentUserIsCompanySignatory,
    recipientSignMode,
    signaturePendingForCurrentUser,
    userFinalisesContract
  ]);
  return /* @__PURE__ */ jsxs(RootStyle, { style: { display: "flex", flexDirection: "column", height: "100vh" }, children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        sx: __spreadValues({ paddingLeft: hasMode ? "300px" : null, width: "100%" }, spacing.mx16),
        title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
          contractTemplate == null ? void 0 : contractTemplate.name,
          " ",
          action === "preview" ? " (preview)" : ""
        ] }),
        showAction: true,
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 1, width: "100%" }, children: [
          mode === "readonly" && action === "preview" && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", mb: spacing.mb10, width: "100%", minWidth: "300px" }, children: /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "contractPreviewUserId",
              options: contractRecipientPreviewOptions,
              onChange: (_, option) => {
                if (option == null ? void 0 : option.value) previewTemplateAsUser(option == null ? void 0 : option.value);
                else {
                  setSignContractPreviewText("");
                  setContractPreviewUserId(void 0);
                  loadTemplate();
                }
              },
              value: contractPreviewUserId,
              label: polyglot.t("TemplateEditorPage.contractPreviewUserId"),
              error: false,
              helperText: false
            }
          ) }),
          mode === "editForRecipient" && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("TemplateEditorPage.cancel"),
                loading: loading || savingTemplate,
                onClick: () => handleCloseAction(),
                sizeVariant: "small",
                colorVariant: "secondary"
              }
            ),
            /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("TemplateEditorPage.update"),
                loading: loading || savingTemplate,
                onClick: () => updateContractForRecipient(),
                sizeVariant: "small",
                colorVariant: "primary"
              }
            )
          ] }),
          mode === "edit" && /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: getEditorActionsOptions(),
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: polyglot.t("General.actions"),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end"
              }
            }
          ),
          mode === "edit" && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("General.save"),
              loading: loading || savingTemplate,
              onClick: () => saveTemplate(false),
              sizeVariant: "small",
              colorVariant: "primary"
            }
          ),
          action === "send" && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(
              ContractTemplateEditorActionsButton,
              {
                onEditClick: () => editContractOnlyForRecipient(),
                onDownloadClick: () => downloadPreview()
              }
            ),
            /* @__PURE__ */ jsx(
              StyledTooltip,
              {
                title: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.tooltip), children: polyglot.t("TemplateEditorPage.reviewContract") }),
                children: /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    name: "Send",
                    loading: loading || isSendingContract,
                    onClick: () => sendContract(),
                    sizeVariant: "small",
                    colorVariant: "primary"
                  }
                )
              }
            )
          ] }),
          mode === "readonly" && action === "update_sign_send" && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(
              ContractTemplateEditorActionsButton,
              {
                onEditClick: () => editContractOnlyForRecipient(),
                onDownloadClick: () => downloadPreview()
              }
            ),
            !contractFinalised && !currentUserIsCompanySignatory && /* @__PURE__ */ jsx(
              StyledTooltip,
              {
                title: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.tooltip), children: polyglot.t("TemplateEditorPage.reviewContract") }),
                children: /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    name: currentUserIsCompanySignatory ? "Sign" : "Send",
                    loading: loading || isSendingContract,
                    onClick: () => saveSignAndSendContract(),
                    sizeVariant: "small",
                    colorVariant: "primary"
                  }
                )
              }
            ),
            !contractFinalised && currentUserIsCompanySignatory && /* @__PURE__ */ jsx(
              StyledTooltip,
              {
                title: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.tooltip), children: polyglot.t("TemplateEditorPage.reviewContract") }),
                children: /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    name: "Sign Later",
                    loading: loading || isSendingContract,
                    onClick: () => sendContract(),
                    sizeVariant: "small",
                    colorVariant: "primary"
                  }
                )
              }
            )
          ] }),
          mode === "readonly" && action === "company_sign" && signaturePendingForCurrentUser && !currentUserIsCompanySignatory && !contractFinalised && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: currentUserIsCompanySignatory ? "Sign" : "Send",
              loading: loading || isSendingContract,
              onClick: () => handleContractSignAction(),
              sizeVariant: "small",
              colorVariant: "primary"
            }
          ),
          !MODES_TO_HIDE_CLOSE_BUTTON.has(mode) && /* @__PURE__ */ jsx(
            IconButton,
            {
              disabled: loading || savingTemplate,
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => handleCloseAction(),
              children: /* @__PURE__ */ jsx(Close, __spreadValues({ stroke: themeColors.DarkGrey }, iconSize))
            }
          )
        ] })
      }
    ),
    /* @__PURE__ */ jsx(
      DocumentEditor,
      {
        onChange: (val) => {
          if ((mode === "edit" || mode === "editForRecipient") && val !== innerHtml) {
            setInnerHtmlHasChanged(true);
            setInnerHtml(val);
          }
        },
        editable: hasMode,
        content: signContractPreviewText || (contractTemplate == null ? void 0 : contractTemplate.templateText) || "",
        mode,
        action,
        onSign: action === "update_sign_send" ? saveSignAndSendContract : () => {
          handleContractSignAction();
        },
        signer: recipientSignMode ? "recipient" : currentUserIsCompanySignatory ? "company" : void 0,
        signature
      }
    ),
    contractId && action && action === "sign" && !missingFieldsHaveToBeFilledBeforeSigning && /* @__PURE__ */ jsx(
      UserSignContractModal,
      {
        open: openSignContractModal,
        setOpen: setOpenSignContractModal,
        contractId,
        returnPath,
        witnessPending: witnessPendingForCurrentUser,
        updateSignature: setSignature
      }
    ),
    mode === "readonly" && action && ["update_sign_send", "company_sign"].includes(action) && /* @__PURE__ */ jsx(
      CompanySignatorySignSignContractModal,
      {
        open: openSignContractModal,
        setOpen: setOpenSignContractModal,
        companySignatoryUserId,
        signAndSendContract: companySignMode ? signContractWithCompanySignature : sendContractWithCompanySignature,
        errorMessage,
        updateSignature: setSignature
      }
    ),
    recipientSignMode && missingFields && contractHasMissingFields && contractId && signaturePendingForCurrentUser && /* @__PURE__ */ jsx(
      MissingPersonalEmployeeFieldsModal,
      {
        open: openMissingEmployeeFieldModal,
        setOpen: setOpenMissingEmployeeFieldModal,
        templateId,
        contractId,
        missingFields,
        contractRecipientId: user == null ? void 0 : user.userId,
        refreshMissingFields: refreshMissingFieldsForEmployee
      }
    )
  ] });
};
