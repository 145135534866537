"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Switch } from "react-router-dom";
import { AppIntegrationPersonalRouter } from "./app-integration-personal.router";
import { AppCompanyRouter } from "./features/app-overview/app-overview-company/app-company.router";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { APPS_COMPANY_ROUTE, APPS_PERSONAL_ROUTE } from "@/lib/routes";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
export function AppIntegrationRouter() {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: AppScopes.VIEW_COMPANY_APPS,
        context: scopesContext,
        path: APPS_COMPANY_ROUTE,
        component: AppCompanyRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: AppScopes.VIEW_APPS_OVERVIEW,
        context: scopesContext,
        path: APPS_PERSONAL_ROUTE,
        component: AppIntegrationPersonalRouter
      }
    )
  ] });
}
