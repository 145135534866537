"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useContext } from "react";
import { AppleDevices, DevicePossessionType } from "@v2/feature/device/device.interface";
import { DeviceProtection } from "@v2/feature/device/features/device-cards/components/device-protection.component";
import { SecurityDetailsInformation } from "@v2/feature/device/features/device-cards/components/security-information.component";
import { NoAppliedPolicies } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { EnrollDevice } from "@v2/feature/device/features/enrollment-device/components/enroll-existing-device.component";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { checkScopes } from "@/lib/scopes";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { MDMABLE_DEVICE_TYPES } from "@/v2/feature/device/device.util";
import { NotEnrollledRisk } from "@/v2/feature/device/features/device-cards/components/not-enrollled-risk.component";
import { EnrollmentStatus } from "@/v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { themeColors } from "@/v2/styles/colors.styles";
export const DeviceMdmSecurityComponent = ({
  devicePossession,
  activeTransit,
  loading,
  refreshDevice,
  enrollPath,
  appliedPolicies,
  appliedZeltPolicies,
  appliedZeltPoliciesForMobile
}) => {
  var _a, _b, _c, _d;
  const isDevicePossessionActive = Boolean(devicePossession && devicePossession.startDate && !devicePossession.endDate);
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasAdminOrEnrolScopes = checkScopes(globalState.user, ["devices.enroll", "devices:all"], scopesContext);
  const getProperPolicy = () => {
    var _a2, _b2;
    if (devicePossession.device && devicePossession.device.inHouseMdm) {
      if (["ios", "ipados"].includes((_a2 = devicePossession.device.os) != null ? _a2 : "")) {
        return appliedZeltPoliciesForMobile ? appliedZeltPoliciesForMobile : NoAppliedPolicies;
      } else if (["macos"].includes((_b2 = devicePossession.device.os) != null ? _b2 : "")) {
        return appliedZeltPolicies ? appliedZeltPolicies : NoAppliedPolicies;
      }
    } else if (appliedPolicies) {
      return appliedPolicies;
    }
    return NoAppliedPolicies;
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    devicePossession.device && !activeTransit && /* @__PURE__ */ jsx(SecurityDetailsInformation, { devicePossession }),
    Boolean(
      isDevicePossessionActive && devicePossession && devicePossession.device && ((_a = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a.type) && MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type) && (devicePossession.device.enrollmentStatus === "enrolled" || devicePossession.device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED)
    ) && /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        loadingAll: loading,
        sections: [
          {
            items: [
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(
                  Suspense,
                  {
                    fallback: /* @__PURE__ */ jsx(
                      SkeletonLoader,
                      {
                        variant: "rectangular",
                        width: "31%",
                        height: "100%",
                        sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                      }
                    ),
                    children: /* @__PURE__ */ jsx(DeviceProtection, { devicePossession, appliedPolicies: getProperPolicy() })
                  }
                )
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            items: [
              {
                type: SectionItemType.Component,
                hidden: !hasAdminOrEnrolScopes,
                value: /* @__PURE__ */ jsx(Fragment, { children: isDevicePossessionActive && devicePossession && devicePossession.device && ((_b = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _b.type) && MDMABLE_DEVICE_TYPES.includes(devicePossession.device.type) && !(devicePossession.device.enrollmentStatus === "enrolled" || devicePossession.device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED) && !activeTransit && devicePossession.possessionType !== DevicePossessionType.ZeltStorage && devicePossession.device.os && (AppleDevices.includes((_d = (_c = devicePossession.device) == null ? void 0 : _c.os) == null ? void 0 : _d.toLowerCase()) ? /* @__PURE__ */ jsx(EnrollDevice, { devicePossession, refreshAppData: refreshDevice }) : /* @__PURE__ */ jsx(NotEnrollledRisk, { enrollPath, devicePossession })) })
              }
            ]
          }
        ]
      }
    )
  ] });
};
