"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const OpenEndedQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry
}) => {
  const currentAnswer = useMemo(() => {
    var _a;
    return ((_a = answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) == null ? void 0 : _a.comment) || "";
  }, [
    answerArray,
    question.id
  ]);
  if (!question || !reviewType || !entry) return /* @__PURE__ */ jsx(Fragment, {});
  const handleInputChange = (value) => {
    handleAnswerChange(question.id, { updatedAnswer: null, comment: value }, entry);
  };
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: /* @__PURE__ */ jsx(
    RichTextField,
    {
      value: currentAnswer,
      onChange: (value) => {
        handleInputChange(value);
      }
    },
    question.id
  ) });
};
