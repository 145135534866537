"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Menu, MenuItem, styled, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import Lottie from "lottie-react";
import { useHistory } from "react-router-dom";
import NewAction from "@/animations/new-action.json";
import { ScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as AbsenceIcon } from "@/images/attendance/ClockBig.svg";
import { ReactComponent as DocumentIcon } from "@/images/documents/DocumentBig.svg";
import { ReactComponent as Payment } from "@/images/side-bar-icons/Bill.svg";
import { ReactComponent as OffboardingIcon } from "@/images/side-bar-icons/Offboarding.svg";
import { ReactComponent as OnboardingIcon } from "@/images/side-bar-icons/Onboarding.svg";
import { ReactComponent as RequestIcon } from "@/images/side-bar-icons/Request.svg";
import { ReactComponent as TaskIcon } from "@/images/task/TaskBig.svg";
import {
  EXPENSE_ME_NEW_EXPENSE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  REQUESTS_CHOOSE_REQUEST_ROUTE
} from "@/lib/routes";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { AbsenceDrawer } from "@/v2/feature/absence/sections/absence-drawer/absence-drawer.section";
import { NewDocumentFormModal } from "@/v2/feature/document-new/components/new-document-form-modal.component";
import { FormTypeEnum } from "@/v2/feature/document-new/interfaces/document-new.interface";
import { OffboardingDrawer } from "@/v2/feature/offboarding/components/offboarding-drawer.component";
import { OnboardNewEmployee } from "@/v2/feature/onboarding/components/onboard-new-employee.component";
import { RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { TaskFormModal } from "@/v2/feature/task/components/task-add-modal/task-form-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledMenu = styled((props) => /* @__PURE__ */ jsx(
  Menu,
  __spreadValues({
    elevation: 0,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    }
  }, props)
))(() => ({
  "& .MuiPaper-root": {
    boxShadow: "0px 2px 20px 0px rgba(13, 13, 14, 0.1)",
    textAlign: "left",
    borderRadius: radius.br10,
    "& .MuiMenu-list": __spreadProps(__spreadValues({}, spacing.pad20), {
      gap: "50px"
    }),
    "& .MuiMenuItem-root": __spreadProps(__spreadValues({
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      padding: "0px",
      minHeight: "20px",
      gap: spacing.g10
    }, themeFonts.caption), {
      fill: themeColors.DarkGrey,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        color: themeColors.Grey,
        fill: themeColors.Grey
      }
    })
  }
}));
export const QuickAction = ({ user }) => {
  const { polyglot } = usePolyglot();
  const { refreshCachedUsers } = useCachedUsers({ refresh: true });
  const [offboardingModalOpen, setOffboardingModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = useState(null);
  const [isOnboardNewUserOpen, setOnboardNewUserOpen] = useState(false);
  const { hasScopes, getScopesContext } = useScopes();
  const routerHistory = useHistory();
  const {
    data: requestForms,
    mutate: refreshRequestForms
  } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), { suspense: false });
  const [isPlaying, setIsPlaying] = useState(false);
  const lottieRef = useRef(null);
  useEffect(() => {
    const currentLottie = lottieRef.current;
    if (isPlaying) {
      currentLottie == null ? void 0 : currentLottie.play();
    } else {
      currentLottie == null ? void 0 : currentLottie.stop();
    }
  }, [isPlaying]);
  useEffect(() => {
    var _a;
    (_a = lottieRef.current) == null ? void 0 : _a.setSpeed(1.1);
  }, []);
  const currentUserIsDocumentAdmin = useMemo(() => hasScopes(["documents:all"], getScopesContext(user)), [
    getScopesContext,
    hasScopes,
    user
  ]);
  const currentUserIsDocumentManager = useMemo(() => hasScopes(["documents:manager"], getScopesContext(user)), [
    getScopesContext,
    hasScopes,
    user
  ]);
  const refreshQuickActionState = useCallback(() => {
    refreshRequestForms == null ? void 0 : refreshRequestForms();
  }, [refreshRequestForms]);
  const closeDrawer = useCallback(() => {
    setOpenDrawer(null);
  }, []);
  const options = useMemo(() => {
    return [
      {
        label: polyglot.t("QuickAction.onboard"),
        icon: OnboardingIcon,
        handler: () => setOnboardNewUserOpen(true),
        disabled: false,
        hidden: !hasScopes(["user.onboard:all"], getScopesContext(user))
      },
      {
        label: polyglot.t("QuickAction.offboard"),
        icon: OffboardingIcon,
        handler: () => setOffboardingModalOpen(true),
        disabled: false,
        hidden: !hasScopes(["user.onboard:all"], getScopesContext(user))
      },
      {
        label: polyglot.t("QuickAction.absence"),
        icon: AbsenceIcon,
        handler: () => setOpenDrawer("absence"),
        disabled: false
      },
      {
        label: polyglot.t("QuickAction.document"),
        icon: DocumentIcon,
        handler: () => setOpenDrawer("document"),
        disabled: false
      },
      {
        label: polyglot.t("QuickAction.task"),
        icon: TaskIcon,
        handler: () => setOpenDrawer("task"),
        disabled: false
      },
      {
        label: polyglot.t("QuickAction.request"),
        icon: RequestIcon,
        handler: () => routerHistory.push(REQUESTS_CHOOSE_REQUEST_ROUTE),
        disabled: false,
        hidden: !(requestForms == null ? void 0 : requestForms.length)
      },
      {
        label: polyglot.t("QuickAction.expense"),
        icon: Payment,
        handler: () => routerHistory.push(EXPENSE_ME_NEW_EXPENSE_ROUTE),
        disabled: false,
        hidden: !hasScopes(["expenses"], getScopesContext(user))
      },
      {
        label: polyglot.t("QuickAction.invoice"),
        icon: Payment,
        handler: () => routerHistory.push(INVOICES_ME_NEW_INVOICE_ROUTE),
        disabled: false,
        hidden: !hasScopes(["invoices"], getScopesContext(user))
      }
    ].filter((item) => !item.hidden);
  }, [polyglot, getScopesContext, hasScopes, requestForms, routerHistory, user]);
  const documentReach = useMemo(() => {
    if (currentUserIsDocumentAdmin) return ReachType.Company;
    if (currentUserIsDocumentManager) return ReachType.Team;
    return ReachType.Me;
  }, [currentUserIsDocumentAdmin, currentUserIsDocumentManager]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: { height: "60px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
        onClick: (event) => {
          refreshQuickActionState();
          setAnchorEl(event.currentTarget);
          event.stopPropagation();
        },
        children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            },
            children: /* @__PURE__ */ jsx(
              IconButton,
              {
                sizeVariant: "circle",
                colorVariant: "primary",
                onMouseEnter: () => setIsPlaying(true),
                onMouseLeave: () => setIsPlaying(false),
                children: /* @__PURE__ */ jsx(
                  Lottie,
                  {
                    lottieRef,
                    animationData: NewAction,
                    loop: true,
                    autoplay: false,
                    style: {
                      display: "flex",
                      height: "20px",
                      width: "20px"
                    }
                  }
                )
              }
            )
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(
      StyledMenu,
      {
        id: `btnMenuOptions`,
        MenuListProps: {
          "aria-labelledby": "btnMenuOptions"
        },
        anchorEl,
        anchorOrigin: { horizontal: "right", vertical: "center" },
        transformOrigin: { horizontal: "left", vertical: "center" },
        open,
        onClose: () => __async(void 0, null, function* () {
          setAnchorEl(null);
        }),
        children: [
          /* @__PURE__ */ jsx(MenuItem, { disableRipple: true, sx: { cursor: "default", mb: "15px" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: polyglot.t("QuickAction.quickActions") }) }),
          options.map(({ label, handler, disabled, icon: Icon }, i) => /* @__PURE__ */ jsxs(
            MenuItem,
            {
              id: `btn${label}_${i}`,
              onClick: (e) => {
                handler();
                setAnchorEl(null);
                e.stopPropagation();
              },
              disableRipple: true,
              disabled,
              sx: { marginTop: "10px" },
              children: [
                /* @__PURE__ */ jsx(Icon, __spreadValues({}, iconSize)),
                label
              ]
            },
            label
          ))
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      AbsenceDrawer,
      {
        isOpen: openDrawer === "absence",
        onClose: closeDrawer,
        userId: user.userId,
        setIsAbsenceDrawerOpen: (value) => setOpenDrawer(value ? "absence" : null)
      }
    ),
    /* @__PURE__ */ jsx(
      NewDocumentFormModal,
      {
        isOpen: openDrawer === "document",
        setIsOpen: (value) => setOpenDrawer(value ? "document" : null),
        onClose: closeDrawer,
        formType: FormTypeEnum.upload,
        reach: documentReach
      }
    ),
    /* @__PURE__ */ jsx(
      TaskFormModal,
      {
        isOpen: openDrawer === "task",
        setIsOpen: (value) => setOpenDrawer(value ? "task" : null),
        onClose: closeDrawer,
        userId: user.userId,
        formData: null
      }
    ),
    /* @__PURE__ */ jsx(
      OnboardNewEmployee,
      {
        isOpen: isOnboardNewUserOpen,
        setIsOpen: setOnboardNewUserOpen,
        onClose: () => __async(void 0, null, function* () {
          yield refreshCachedUsers();
        }),
        initialValues: void 0,
        onDraftUserCreated: () => setOnboardNewUserOpen(false),
        onUserCreated: () => setOnboardNewUserOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.onboard:all"], children: /* @__PURE__ */ jsx(OffboardingDrawer, { userId: void 0, isOpen: offboardingModalOpen, setIsOpen: setOffboardingModalOpen }) })
  ] });
};
