"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { EditInsuranceGeneralDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/components/edit-insurance-general-drawer.component";
import {
  BenefitsSettingsPageSection,
  DescriptionLine
} from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const InsuranceSettingsGeneralPage = ({ insuranceQuote, refreshInsuranceQuote }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("General.general"),
      onEdit: insuranceQuote.policy ? () => {
        setIsEditOpen(true);
      } : void 0,
      children: [
        (((_a = insuranceQuote.policy) == null ? void 0 : _a.displayName) || insuranceQuote.displayName) && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("General.displayName"),
            value: (_c = (_b = insuranceQuote.policy) == null ? void 0 : _b.displayName) != null ? _c : insuranceQuote.displayName
          }
        ),
        !insuranceQuote.policy && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.status"), value: insuranceQuote.status }),
        insuranceQuote.policy && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.provider"), value: insuranceQuote.policy.providerName }),
        insuranceQuote.policy && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.policyNumber"), value: insuranceQuote.policy.policyNumber }),
        insuranceQuote.policy && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.policyStart"), value: insuranceQuote.policy.startDate }),
        insuranceQuote.policy && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.policyEnd"), value: insuranceQuote.policy.endDate }),
        insuranceQuote.policy && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.claims"), value: insuranceQuote.policy.claimsPhone }),
        insuranceQuote.policy && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.support"), value: insuranceQuote.policy.supportPhone }),
        insuranceQuote.policy && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.insuranceBroker"),
            value: insuranceQuote.policy.insuranceBrokerPhone
          }
        ),
        isEditOpen && insuranceQuote.policy && /* @__PURE__ */ jsx(
          EditInsuranceGeneralDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            insurancePolicy: insuranceQuote.policy,
            refresh: refreshInsuranceQuote
          }
        )
      ]
    }
  );
};
