"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { OnboardingAPI } from "@v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/app-icons/Close.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ONBOARDING_ROUTE, ONBOARDING_USER_STATUS_ROUTE } from "@/lib/routes";
import { StepperComponent } from "@/v2/components/stepper.component";
import { JobPositionEndpoints } from "@/v2/feature/job-position/job-position.api";
import { OnboardingUserInvite } from "@/v2/feature/onboarding/user-setup/onboarding-user-invite.page";
import { NewEmployeeCompensationSection } from "@/v2/feature/onboarding/user-setup/sections/new-employee-compensation.section";
import { NewEmployeeContractSection } from "@/v2/feature/onboarding/user-setup/sections/new-employee-contract.section";
import { NewEmployeeEquitySection } from "@/v2/feature/onboarding/user-setup/sections/new-employee-equity.section";
import { NewEmployeeRoleSection } from "@/v2/feature/onboarding/user-setup/sections/new-employee-role.section";
import { NewEmployeeSection } from "@/v2/feature/onboarding/user-setup/sections/new-employee.section";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingStepperSteps = ["Employee", "Role", "Contract", "Compensation", "Equity"];
export const iconSize = { width: 14, height: 14 };
export const OnboardingUserSetup = () => {
  const { state } = useLocation();
  const { refreshCachedUsers } = useCachedUsers();
  const [activeStep, setActiveStep] = useState(
    // if we have a userId, skip the first step
    OnboardingStepperSteps[(state == null ? void 0 : state.newUserId) ? 1 : 0]
  );
  const { data: deptsData, mutate: refreshDept } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const departments = useMemo(() => {
    var _a;
    return (_a = deptsData == null ? void 0 : deptsData.map((dept) => ({ label: dept.name, value: dept.id }))) != null ? _a : [];
  }, [deptsData]);
  const { data: siteData, mutate: refreshSites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const sites = useMemo(() => {
    var _a;
    return (_a = siteData == null ? void 0 : siteData.map((site) => ({ label: site.name, value: site.id }))) != null ? _a : [];
  }, [siteData]);
  const { data: jobPositionData, mutate: refreshJobPosition } = useApiClient(JobPositionEndpoints.listJobPositions(), {
    suspense: false
  });
  const nextPossibleId = useMemo(() => {
    var _a;
    if (!jobPositionData) return "";
    const lastJobPosition = jobPositionData == null ? void 0 : jobPositionData.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = (_a = lastJobPosition[jobPositionData.length - 1]) == null ? void 0 : _a.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1e6).toString();
    }
  }, [jobPositionData]);
  const DEFAULT_NEW_JOB_POSITION = {
    id: void 0,
    internalCode: nextPossibleId,
    title: "",
    description: ""
  };
  const jobPositions = useMemo(() => {
    if (!jobPositionData) return [];
    return jobPositionData.map((eachJobPosition) => {
      return {
        label: eachJobPosition.title,
        value: eachJobPosition.id
      };
    });
  }, [jobPositionData]);
  const [onboardingState, setOnboardingState] = useState(null);
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const [showInviteConfirmation, setShowInviteConfirmation] = useState(false);
  const returnToOnboardingOverview = useCallback(() => {
    routerHistory.push(ONBOARDING_ROUTE);
  }, [routerHistory]);
  const routeToOnboardingUserStatus = useCallback(() => {
    if (onboardingState == null ? void 0 : onboardingState.user.userId) {
      refreshCachedUsers();
      routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboardingState.user.userId }));
    }
  }, [onboardingState, refreshCachedUsers, routerHistory]);
  const isFirstStep = useCallback(() => {
    return OnboardingStepperSteps.indexOf(activeStep) === 0;
  }, [activeStep]);
  const handleNext = useCallback(() => {
    const currentStepIdx = OnboardingStepperSteps.indexOf(activeStep);
    if (currentStepIdx >= OnboardingStepperSteps.length - 1) {
      returnToOnboardingOverview();
      return;
    }
    setActiveStep(OnboardingStepperSteps[currentStepIdx + 1]);
  }, [activeStep, returnToOnboardingOverview]);
  const handleBack = useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIdx = OnboardingStepperSteps.indexOf(currentStep);
      return OnboardingStepperSteps[Math.max(currentStepIdx - 1, 0)];
    });
  }, []);
  const getOnboardingInfo = useCallback(
    (userId) => __async(void 0, null, function* () {
      try {
        const state2 = yield OnboardingAPI.getInitOnboardingState(userId);
        setOnboardingState(state2);
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
        setOnboardingState(null);
      }
    }),
    [showMessage, polyglot]
  );
  useEffect(() => {
    if (!(state == null ? void 0 : state.newUserId)) return;
    getOnboardingInfo(state.newUserId);
  }, [getOnboardingInfo, state == null ? void 0 : state.newUserId]);
  if (showInviteConfirmation) {
    return onboardingState ? /* @__PURE__ */ jsx(
      OnboardingUserInvite,
      {
        onBackClick: () => setShowInviteConfirmation(false),
        onFinish: () => routeToOnboardingUserStatus(),
        user: onboardingState.user
      }
    ) : /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30, position: "absolute", zIndex: 10, inset: 0, background: themeColors.white }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", width: "70%", mx: "auto" }, children: [
      !isFirstStep() && /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", left: spacing.m50 }), onClick: handleBack, children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize)) }),
      /* @__PURE__ */ jsx(StepperComponent, { sx: { flex: 1 }, steps: OnboardingStepperSteps, activeStep }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", right: spacing.m50 }),
          onClick: () => returnToOnboardingOverview(),
          children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden auto", pb: spacing.p30 }, children: {
      Employee: () => /* @__PURE__ */ jsx(
        NewEmployeeSection,
        {
          onNext: (userId) => __async(void 0, null, function* () {
            if (userId) {
              yield getOnboardingInfo(userId);
              handleNext();
              return;
            }
            handleNext();
          }),
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          templateId: state == null ? void 0 : state.templateId,
          user: onboardingState == null ? void 0 : onboardingState.user
        }
      ),
      Role: () => onboardingState && /* @__PURE__ */ jsx(
        NewEmployeeRoleSection,
        {
          userId: onboardingState.user.userId,
          role: onboardingState.role,
          departments,
          sites,
          jobPositions,
          startDate: onboardingState.user.startDate,
          onNext: () => __async(void 0, null, function* () {
            yield getOnboardingInfo(onboardingState.user.userId);
            handleNext();
          }),
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          refreshSites,
          refreshDept,
          refreshJobPosition,
          defaultJobPosition: DEFAULT_NEW_JOB_POSITION
        }
      ),
      Contract: () => onboardingState && /* @__PURE__ */ jsx(
        NewEmployeeContractSection,
        {
          contract: onboardingState.contract,
          onNext: () => __async(void 0, null, function* () {
            yield getOnboardingInfo(onboardingState.user.userId);
            handleNext();
          }),
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          startDate: onboardingState.user.startDate,
          userId: onboardingState.user.userId
        }
      ),
      Compensation: () => onboardingState && /* @__PURE__ */ jsx(
        NewEmployeeCompensationSection,
        {
          compensation: onboardingState.compensation,
          onNext: () => __async(void 0, null, function* () {
            yield getOnboardingInfo(onboardingState.user.userId);
            handleNext();
          }),
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId: onboardingState.user.userId,
          startDate: onboardingState.user.startDate
        }
      ),
      Equity: () => onboardingState && /* @__PURE__ */ jsx(
        NewEmployeeEquitySection,
        {
          currentUserId: globalState.user.userId,
          equity: onboardingState.equity[0],
          onNext: () => __async(void 0, null, function* () {
            yield getOnboardingInfo(onboardingState.user.userId);
            setShowInviteConfirmation(true);
          }),
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          newUserId: onboardingState.user.userId,
          companyId: globalState.user.company.companyId
        }
      )
    }[activeStep]() })
  ] });
};
