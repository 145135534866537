"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AccountingCodeItem = ({
  payCode,
  nominalCodes,
  reimportNominalCodes,
  accountingConfig,
  accountingField,
  setAccountingField,
  autoFocus,
  disabled,
  sx
}) => {
  var _a, _b;
  const [isReloadingCodes, setIsReloadingCodes] = useState(false);
  const [showMessage] = useMessage();
  const nominalCodeSelectOptions = useMemo(() => {
    return nominalCodes == null ? void 0 : nominalCodes.sort((a, b) => a.code.localeCompare(b.code)).map(({ code, name }) => ({
      label: `${code} - ${name}`,
      value: code
    }));
  }, [nominalCodes]);
  const updateAccountingFieldValue = useCallback(
    (value) => {
      var _a2, _b2, _c;
      setAccountingField(__spreadValues({
        payCode,
        code: (_a2 = accountingField == null ? void 0 : accountingField.code) != null ? _a2 : "",
        nominalName: (_b2 = accountingField == null ? void 0 : accountingField.nominalName) != null ? _b2 : "",
        description: (_c = accountingField == null ? void 0 : accountingField.description) != null ? _c : ""
      }, value));
    },
    [accountingField, payCode, setAccountingField]
  );
  const reloadAccountingCodes = useCallback(() => __async(void 0, null, function* () {
    setIsReloadingCodes(true);
    try {
      yield reimportNominalCodes();
      showMessage("Accounting codes refreshed", "info");
    } catch (error) {
      showMessage(`Failed to refresh accounting codes. ${nestErrorMessage(error)}`, "error");
    }
    setIsReloadingCodes(false);
  }), [reimportNominalCodes, showMessage]);
  if (nominalCodes && nominalCodeSelectOptions && accountingConfig.organisation) {
    const nominalCodeMatch = accountingField && nominalCodes.find((n) => n.code === accountingField.code);
    const exactMatch = !!nominalCodeMatch && nominalCodeMatch.name === accountingField.nominalName;
    return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "20px" }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "flex-end", gap: "20px" }, children: [
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "accounting-code-select",
            value: exactMatch && (nominalCodeMatch == null ? void 0 : nominalCodeMatch.code) || "",
            onChange: (e) => {
              const selectedCode = nominalCodes.find((n) => n.code === e.target.value);
              if (!selectedCode) return;
              updateAccountingFieldValue({
                code: selectedCode.code,
                nominalName: selectedCode.name,
                description: selectedCode.description
              });
            },
            label: "Accounting code",
            options: nominalCodeSelectOptions,
            disabled: disabled || isReloadingCodes
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            loading: isReloadingCodes,
            disabled,
            onClick: () => reloadAccountingCodes(),
            sizeVariant: "medium",
            colorVariant: "primary",
            style: { width: "fit-content" },
            title: "Reload Xero accounting codes",
            children: /* @__PURE__ */ jsx(Reload, { style: { float: "left" } })
          }
        )
      ] }),
      /* if the saved accounting field does not exactly match the Xero nominal code, show the saved field underneath */
      !exactMatch && accountingField && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5, mt: spacing.mt10 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: `Current accounting code` }),
        /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadValues({}, themeFonts.caption),
            children: `${accountingField.code} - ${accountingField.nominalName}`
          }
        )
      ] })
    ] });
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g15 }, sx), children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accounting-code-value",
        label: "Accounting code",
        value: (_a = accountingField == null ? void 0 : accountingField.code) != null ? _a : "",
        disabled,
        onChange: (e) => {
          updateAccountingFieldValue({ code: e.target.value });
        },
        clearText: () => updateAccountingFieldValue({ code: "" }),
        autoFocus,
        autoComplete: "off",
        placeholder: "000",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accounting-code-name",
        label: "Accounting code name",
        value: (_b = accountingField == null ? void 0 : accountingField.nominalName) != null ? _b : "",
        disabled: disabled || !(accountingField == null ? void 0 : accountingField.code),
        onChange: (e) => updateAccountingFieldValue({ nominalName: e.target.value }),
        clearText: () => updateAccountingFieldValue({ nominalName: "" }),
        autoComplete: "off"
      }
    )
  ] });
};
