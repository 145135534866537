"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class UserProfileActionAPI {
  static deactivateUser(userId, deactivationDetails) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/deactivate`, deactivationDetails);
    });
  }
  static cancelDeactivation(userId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/deactivate/cancel`);
    });
  }
  static reactivateUser(userId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/reactivate`);
    });
  }
  static activateUser(userId, mode) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/activate/${mode}`);
    });
  }
  static deleteUser(userId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/users/${userId}`);
    });
  }
  static getDeletionChecks(userId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/users/${userId}/deletionChecks`)) == null ? void 0 : _a.data;
    });
  }
  static getDeactivationChecks(userId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/users/${userId}/deactivationChecks`)) == null ? void 0 : _a.data;
    });
  }
}
