"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Modal } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  pt: 4,
  pb: 2,
  display: "flex",
  flexDirection: "column",
  gap: "20px"
};
export const DeleteEmployeePensionModal = ({ isOpen, setIsOpen, userId, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage] = useMessage();
  const deletePension = () => __async(void 0, null, function* () {
    try {
      yield PensionAPI.deleteEmployeePension(userId);
      setIsLoading(true);
      showMessage("Pension record successfully removed.", "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(Modal, { open: isOpen, onClose: () => setIsOpen(false), children: /* @__PURE__ */ jsxs(Box, { sx: ModalStyle, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Delete employee pension" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "This option should only be used when an employee has been added to a pension in error." }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      "If the employee is leaving a pension scheme click ",
      /* @__PURE__ */ jsx("b", { children: "'Leave pension'" })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Delete",
        sizeVariant: "medium",
        colorVariant: "danger",
        type: "button",
        onClick: deletePension,
        loading: isLoading
      }
    ) })
  ] }) });
};
