"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { createShortDateAsUTC } from "@v2/util/date-format.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
export const DevicePossessionDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  companyId,
  refresh,
  onUpdate
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  DevicePossessionDrawerContent,
  {
    companyId,
    devicePossession,
    refresh,
    onUpdate
  }
) });
const DevicePossessionDrawerContent = ({
  companyId,
  devicePossession,
  refresh,
  onUpdate
}) => {
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      startDate: devicePossession.startDate,
      endDate: devicePossession.endDate,
      deliveryAddress: devicePossession.deliveryAddress
    },
    validationSchema: yup.object({
      startDate: yup.date().nullable().notRequired().typeError("Invalid date format."),
      endDate: yup.date().nullable().notRequired().typeError("Invalid date format."),
      deliveryAddress: yup.string().required("Delivery address is required.")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      return yield updateDevicePossession(values);
    })
  });
  const updateDevicePossession = (possessionUpdate) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield DeviceAPI.updateCompanyDevicePossessionById(companyId, devicePossession.id, possessionUpdate);
      showMessage("Possession updated successfully.", "success");
      yield refresh();
      onUpdate();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Update possession" }),
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: formik.values.startDate ? formik.values.startDate : "",
          onChange: (newVal) => {
            const formattedDate = newVal ? createShortDateAsUTC(newVal) : void 0;
            formik.setFieldValue("startDate", formattedDate);
          },
          name: "startDate",
          label: "Start date"
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: formik.values.startDate ? formik.values.startDate : "",
          onChange: (newVal) => {
            const formattedDate = newVal ? createShortDateAsUTC(newVal) : void 0;
            formik.setFieldValue("endDate", formattedDate);
          },
          name: "endDate",
          label: "End date"
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Delivery Address",
          name: "deliveryAddress",
          value: formik.values.deliveryAddress,
          onChange: formik.handleChange,
          error: formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress),
          helperText: formik.touched.deliveryAddress && formik.errors.deliveryAddress,
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ) }),
      /* @__PURE__ */ jsx(LoaderButton, { name: "Update", loading, sizeVariant: "small", colorVariant: "primary", fullWidth: true })
    ] }) })
  ] });
};
