"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ScheduleTrackingType } from "@v2/feature/attendance/attendance.interface";
import { EMPLOYEE_SITE, getTrackingTypeLabel } from "@v2/feature/attendance/attendance.util";
import { AttendanceScheduleEditSettingsDrawer } from "@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-settings-drawer.component";
import { DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY } from "@v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AttendanceSettingsTrackingSection = ({ schedule, refreshSchedule, sites }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  const allowedWorkSites = useMemo(() => {
    if (!schedule.useGeolocation) return null;
    const siteNames = [];
    if (schedule.geolocationEmployeeSite) siteNames.push(EMPLOYEE_SITE(polyglot).label);
    if (schedule.allowedWorkSites) {
      for (const siteId of schedule.allowedWorkSites) {
        const site = sites.find((site2) => site2.id === siteId);
        if (site) siteNames.push(site.name);
      }
    }
    return siteNames.join(", ");
  }, [polyglot, sites, schedule.useGeolocation, schedule.allowedWorkSites, schedule.geolocationEmployeeSite]);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AttendanceSchedule.tracking"),
      onEdit: () => {
        setEdit(true);
      },
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.timesheets"),
                    value: schedule.trackingType === ScheduleTrackingType.None ? polyglot.t("General.disabled") : polyglot.t("General.enabled")
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.method"),
                    value: getTrackingTypeLabel(schedule.trackingType, polyglot)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.earlyShift"),
                    value: polyglot.t("SettingsSectionReadonly.minutes", { capMins: schedule.clockInEarlyCapMinutes }),
                    hidden: schedule.trackingType !== ScheduleTrackingType.ClockInClockOut
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.gpsTracking"),
                    value: schedule.useGeolocation ? polyglot.t("General.enabled") : polyglot.t("General.disabled"),
                    hidden: schedule.trackingType !== ScheduleTrackingType.ClockInClockOut
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.restrictLocation"),
                    value: schedule.restrictByGeolocation ? polyglot.t("General.enabled") : polyglot.t("General.disabled"),
                    hidden: schedule.trackingType !== ScheduleTrackingType.ClockInClockOut
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.allowedLocations"),
                    value: allowedWorkSites,
                    hidden: !schedule.restrictByGeolocation || !allowedWorkSites
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.maximumDistance"),
                    value: polyglot.t("SettingsSectionReadonly.within", {
                      distance: (_a = schedule.geolocationDistance) != null ? _a : DEFAULT_MAXIMUM_ACCEPTED_PROXIMITY
                    }),
                    hidden: !schedule.restrictByGeolocation
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          AttendanceScheduleEditSettingsDrawer,
          {
            isOpen: edit,
            setIsOpen: setEdit,
            attendanceSchedule: schedule,
            refresh: refreshSchedule,
            sites
          }
        )
      ]
    }
  );
};
