"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { CreateHolidayCalendarDrawer } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/components/create-holiday-calendar-drawer.component";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { RootStyle } from "@/v2/styles/root.styles";
import { pluralText } from "@/v2/util/string.util";
export const HolidayCalendarsListPage = ({ holidayCalendars, refresh, isLoading }) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const filteredCalendars = useMemo(() => {
    if (!searchInput) return holidayCalendars;
    const search = searchInput.toLowerCase();
    return holidayCalendars.filter((c) => c.name.toLowerCase().includes(search));
  }, [holidayCalendars, searchInput]);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(ContentWrapper, { children: /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("HolidayCalendarModule.publicHolidays"),
      headerWidth: "100%",
      contentWidth: "100%",
      buttons: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: () => {
              setIsDrawerOpen(true);
            },
            children: polyglot.t("HolidayCalendarModule.newCalendar")
          }
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          var _a, _b;
                          return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (filteredCalendars || []).map((item) => {
                      var _a, _b;
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: item.name,
                          loading: isLoading,
                          boxSx: responsiveCardStyle,
                          boxAction: () => {
                            history.push(
                              generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, { calendarId: item.id })
                            );
                          },
                          contentItemsSets: [
                            ...item.createdBy ? [
                              {
                                type: ContentItemType.component,
                                contentComponent: /* @__PURE__ */ jsx(
                                  ChipComponent,
                                  {
                                    name: /* @__PURE__ */ jsx(UserCell, { userId: item.createdBy, nameColor: "DarkGrey" }),
                                    backgroundColor: "white",
                                    textColor: "DarkGrey",
                                    textVariant: "caption",
                                    border: "middle"
                                  }
                                )
                              }
                            ] : [],
                            {
                              name: `${((_a = item.holidays) == null ? void 0 : _a.length) || 0} holiday${pluralText(
                                ((_b = item.holidays) == null ? void 0 : _b.length) || 0
                              )}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ]
                        },
                        item.name
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(CreateHolidayCalendarDrawer, { isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen, refresh })
      ]
    }
  ) }) });
};
