"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useReducer } from "react";
const initialState = {
  bulkDocuments: []
};
const BulkUploadContext = createContext(void 0);
export function useBulkUpload() {
  const context = useContext(BulkUploadContext);
  if (context === void 0) {
    throw new Error("useBulkUpload must be used within a BulkUploadProvider");
  }
  return context;
}
export function BulkUploadProvider({ children }) {
  const [state, dispatch] = useReducer(bulkUploadReducer, initialState);
  return /* @__PURE__ */ jsx(BulkUploadContext.Provider, { value: { state, dispatch }, children });
}
function bulkUploadReducer(state, action) {
  switch (action.type) {
    case "ADD_DOCUMENT":
      return __spreadProps(__spreadValues({}, state), { bulkDocuments: [...state.bulkDocuments, action.payload] });
    case "UPDATE_DOCUMENT":
      const updatedState = state.bulkDocuments.map(
        (doc) => doc.documentId === action.payload.documentId ? __spreadValues(__spreadValues({}, doc), action.payload.updatedData) : doc
      );
      return __spreadProps(__spreadValues({}, state), {
        bulkDocuments: updatedState
      });
    case "REMOVE_DOCUMENT":
      return __spreadProps(__spreadValues({}, state), {
        bulkDocuments: state.bulkDocuments.filter((doc) => doc.documentId !== action.payload)
      });
    case "RESTORE_STATE":
      return action.payload;
    case "UPDATE_DOCUMENT_PENDING":
      return __spreadProps(__spreadValues({}, state), {
        bulkDocuments: state.bulkDocuments.map(
          (doc) => doc.documentId === action.payload.documentId ? __spreadProps(__spreadValues({}, doc), { pendingUpdate: __spreadValues(__spreadValues({}, doc.pendingUpdate || {}), action.payload.updatedData) }) : doc
        )
      });
    case "COMMIT_DOCUMENT_UPDATE":
      return __spreadProps(__spreadValues({}, state), {
        bulkDocuments: state.bulkDocuments.map(
          (doc) => doc.documentId === action.payload ? __spreadProps(__spreadValues(__spreadValues({}, doc), doc.pendingUpdate || {}), { pendingUpdate: void 0 }) : doc
        )
      });
    case "CANCEL_DOCUMENT_UPDATE":
      return __spreadProps(__spreadValues({}, state), {
        bulkDocuments: state.bulkDocuments.map(
          (doc) => doc.documentId === action.payload ? __spreadProps(__spreadValues({}, doc), { pendingUpdate: void 0 }) : doc
        )
      });
    default:
      return state;
  }
}
