"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from "react";
import { Box } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import EmojiPicker from "emoji-picker-react";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { StyledTextfield } from "@/v2/styles/textfield.styles";
const iconSize = { width: "14px", height: "14px" };
export const StyledEmojiPicker = forwardRef(
  ({ name, label, value, onChange, error, helperText, required = false, disabled = false }, ref) => {
    const [openPicker, setOpenPicker] = useState(false);
    return /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: () => setOpenPicker(false), children: /* @__PURE__ */ jsxs(Box, { sx: { position: "relative" }, children: [
      /* @__PURE__ */ jsx(
        StyledTextfield,
        {
          onClick: () => setOpenPicker(!openPicker),
          inputRef: ref,
          variant: "standard",
          label,
          size: "small",
          fullWidth: true,
          disabled,
          name,
          value,
          error,
          helperText,
          required,
          InputProps: { endAdornment: /* @__PURE__ */ jsx(ArrowDownACIcon, __spreadValues({}, iconSize)), readOnly: true },
          InputLabelProps: {
            shrink: true
          }
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { position: "absolute", zIndex: 2, left: "1px" }, children: openPicker && /* @__PURE__ */ jsx(EmojiPicker, { onEmojiClick: (emoji, e) => onChange(emoji, e), width: 320 }) })
    ] }) });
  }
);
