"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { BenefitsCreateRouter } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/benefits-create.router";
import { BenefitsSettingsListPage } from "@v2/feature/benefits/subfeature/benefits-settings/pages/benefits-settings-list.page";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { PensionAPI as PensionAPIV2 } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  SETTINGS_BENEFITS_DETAILS_ROUTE,
  SETTINGS_BENEFITS_ROUTE,
  SETTINGS_CREATE_NEW_BENEFIT_ROUTE
} from "@/lib/routes";
import { BenefitsSettingsDetailsRouter } from "@/v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/benefits-settings-details.router";
export const BenefitsSettingsRouter = ({ context }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();
  const { companyConfig, isLoading } = useCompanyConfigState();
  const hasPensionAllScope = hasScopes(["pension:all"]);
  const hasInsuranceAllScope = hasScopes(["insurance:all"]);
  const [loading, setLoading] = useState(true);
  const [pensionSchemes, setPensionSchemes] = useState([]);
  const [insuranceQuote, setInsuranceQuote] = useState(null);
  const [customBenefits, setCustomBenefits] = useState([]);
  const refreshPensionSchemes = useCallback(() => __async(void 0, null, function* () {
    if (!hasPensionAllScope || !(companyConfig == null ? void 0 : companyConfig.inPension)) return;
    try {
      const pensionSchemes2 = yield PensionAPIV2.getCompanyPensionSchemes();
      setPensionSchemes(pensionSchemes2);
    } catch (err) {
      showMessage(
        polyglot.t("PensionModule.ErrorMessages.couldNotGetProviders", { errorMessage: nestErrorMessage(err) }),
        "error"
      );
    }
  }), [polyglot, companyConfig, hasPensionAllScope, showMessage]);
  const refreshInsuranceQuote = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceAllScope) return;
    try {
      const insuranceQuote2 = yield InsuranceAPI.getInsuranceQuote();
      setInsuranceQuote(insuranceQuote2);
    } catch (err) {
      showMessage(
        polyglot.t("BenefitModule.ErrorMessages.couldNotGetInsuranceQuote", { errorMessage: nestErrorMessage(err) }),
        "error"
      );
    }
  }), [polyglot, hasInsuranceAllScope, showMessage]);
  const refreshCustomBenefits = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceAllScope) return;
    try {
      const benefits = yield CustomBenefitAPI.getAllCustomBenefits();
      setCustomBenefits(benefits);
    } catch (err) {
      showMessage(
        polyglot.t("BenefitModule.ErrorMessages.couldNotGetCustomBenefit", { errorMessage: nestErrorMessage(err) }),
        "error"
      );
    }
  }), [polyglot, hasInsuranceAllScope, showMessage]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      setLoading(true);
      yield Promise.all([refreshPensionSchemes(), refreshInsuranceQuote(), refreshCustomBenefits()]);
      setLoading(false);
    }))();
  }, [refreshPensionSchemes, refreshInsuranceQuote, refreshCustomBenefits]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance:all", "pension:all"],
        path: SETTINGS_BENEFITS_DETAILS_ROUTE,
        component: BenefitsSettingsDetailsRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["insurance:all", "pension:all"],
        path: SETTINGS_CREATE_NEW_BENEFIT_ROUTE,
        children: /* @__PURE__ */ jsx(
          BenefitsCreateRouter,
          {
            pensionSchemes,
            refreshPensionSchemes,
            refreshInsuranceQuote,
            companyConfig: companyConfig != null ? companyConfig : { inPayroll: false, inPension: false }
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { context, scopes: ["insurance:all", "pension:all"], path: SETTINGS_BENEFITS_ROUTE, children: /* @__PURE__ */ jsx(
      BenefitsSettingsListPage,
      {
        pensionSchemes,
        customBenefits,
        insuranceQuote,
        refreshCustomBenefits,
        loading: Boolean(loading || isLoading)
      }
    ) }),
    /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_BENEFITS_ROUTE })
  ] });
};
