"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const parentCardSx = {
  maxWidth: { sm: "300px", md: "600px", lg: "600px" },
  paddingBottom: spacing.p60
};
export const parentContainerSx = {
  width: "100%",
  display: "flex",
  gap: spacing.gap80
};
export const responsiveParentCardSx = {
  minWidth: { sm: "150px", md: "300px", lg: "300px" },
  width: "100%"
};
export const smallParentCardSx = {
  maxWidth: { sm: "200px", md: "400px", lg: "400px" },
  minWidth: { sm: "200px", md: "400px", lg: "400px" }
};
export const settingCardSx = {
  maxWidth: { sm: "300px", md: "600px", lg: "600px" }
};
export const cardTitleIconSx = __spreadValues({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center"
}, spacing.mb10);
export const cardSubtitleSx = __spreadValues(__spreadValues({}, themeFonts.caption), spacing.mb30);
export const cardListSx = {
  display: "flex",
  flexDirection: "column",
  margin: 0,
  padding: 0,
  width: "65%"
};
export const settingsfieldSx = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: 65,
  gap: spacing.g20
};
export const fieldLabelSx = __spreadProps(__spreadValues({}, themeFonts.caption), {
  width: "30%"
});
export const lockBoxSx = {
  position: "absolute",
  right: -40,
  top: 0
};
export const lockIconSx = {
  borderRadius: radius.br15,
  padding: "7px",
  backgroundColor: themeColors.DarkGrey,
  display: "flex",
  justifyContent: "center",
  width: "15px",
  height: "15px"
};
export const helperTextSx = __spreadProps(__spreadValues({}, themeFonts.captionSmall), {
  marginTop: spacing.m5
});
export const detailListSx = { display: "flex", flexDirection: "column", margin: 0, padding: 0 };
export const detailSx = {
  display: "flex",
  marginBottom: spacing.m15,
  width: "100%",
  gap: spacing.g10
};
export const detailTermSx = __spreadValues({
  maxWidth: { sm: "90px", md: "190px", lg: "190px" },
  width: "100%"
}, themeFonts.caption);
export const detailValueSx = __spreadValues({
  maxWidth: { sm: "200px", md: "400px", lg: "400px" },
  width: "100%"
}, themeFonts.title4);
export const detailHelperSx = __spreadProps(__spreadValues({
  marginTop: spacing.m5
}, themeFonts.captionSmall), {
  color: themeColors.Grey
});
export const buttonBoxSx = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: spacing.gap10
};
export const buttonBoxDrawerSx = __spreadProps(__spreadValues({}, buttonBoxSx), {
  mt: "auto",
  pb: "20px"
});
