"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { USER_BENEFITS_PENSION_SUMMARY_ROUTE } from "@/lib/routes";
import { PensionAPI } from "@/v2/feature/benefits/subfeature/pension/pension.api";
import { UserProfilePension } from "@/v2/feature/user/features/user-profile/pension/user-profile-pension.page";
export const UserPensionRouter = ({ userId, onUpdate }) => {
  const [employeePension, setEmployeePension] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const autoEnrollmentCallback = useCallback(() => __async(void 0, null, function* () {
    setIsLoading(true);
    try {
      const employeePension2 = yield PensionAPI.getEmployeePension(userId);
      setEmployeePension(employeePension2);
      yield onUpdate();
    } catch (error) {
      setEmployeePension(null);
    }
    setIsLoading(false);
  }), [onUpdate, userId]);
  useEffect(() => {
    autoEnrollmentCallback();
  }, [autoEnrollmentCallback]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { exact: true, path: USER_BENEFITS_PENSION_SUMMARY_ROUTE, children: employeePension && /* @__PURE__ */ jsx(
      UserProfilePension,
      {
        userId,
        employeePension,
        onEmployeeUpdate: autoEnrollmentCallback,
        loading
      }
    ) }),
    /* @__PURE__ */ jsx(Redirect, { to: USER_BENEFITS_PENSION_SUMMARY_ROUTE })
  ] });
};
