"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useState } from "react";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOrderStatus } from "@v2/feature/device/device.interface";
import { DeviceOrderDetailsPage } from "@v2/feature/device/features/devices-company/pages/device-order-details.page";
import { DeviceStorePage } from "@v2/feature/device/features/devices-company/pages/device-store.page";
import { MyDeviceDetailsRouter } from "@v2/feature/device/features/my-devices/my-device-details.router";
import { ConfirmDeviceOrderDeliveryStepper } from "@v2/feature/device/features/my-devices/pages/device-activation/confirm-device-delivery-stepper.component";
import { DevicesActivationPage } from "@v2/feature/device/features/my-devices/pages/device-activation/devices-activation.page";
import { MyDevicesOverviewPage } from "@v2/feature/device/features/my-devices/pages/my-devices-overview.page";
import { generatePath, Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { useGlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  DEVICES_ME_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDER_DETAILS_ROUTE,
  DEVICES_ME_ORDERS_ACTIVATION_ROUTE,
  DEVICES_ME_OVERVIEW_DEVICE_ROUTE,
  DEVICES_ME_DIRECTORY_ROUTE,
  DEVICES_ME_STORE_ROUTE
} from "@/lib/routes";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const DevicesMeRouter = () => {
  const [globalState] = useGlobalContext();
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [deviceTransits, setDeviceTransits] = useState([]);
  const [deviceOrders, setDeviceOrders] = useState([]);
  const [inShippingDevices, setInShippingDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const refreshDevicesAppData = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [deviceTransits2, deviceOrders2] = yield Promise.all([
        DeviceAPI.getUserInTransitDevices(userId),
        DeviceAPI.getUserDeviceOrders(userId)
      ]);
      setDeviceTransits(deviceTransits2);
      setDeviceOrders(deviceOrders2.filter((deviceOrder) => deviceOrder.status !== DeviceOrderStatus.Delivered));
      setInShippingDevices(deviceOrders2.filter((deviceOrder) => deviceOrder.status === DeviceOrderStatus.Shipping));
    } catch (error) {
      showMessage(`Could not get devices inventory stats. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [userId, showMessage]);
  useEffect(() => {
    refreshDevicesAppData();
  }, [refreshDevicesAppData]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["devices", "devices.enroll"],
        context: scopesContext,
        path: DEVICES_ME_OVERVIEW_DEVICE_ROUTE,
        children: /* @__PURE__ */ jsx(MyDeviceDetailsRouter, { refreshDevicesAppData })
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices"], context: scopesContext, path: DEVICES_ME_ORDER_ACTIVATION_ROUTE, children: /* @__PURE__ */ jsx(ConfirmDeviceOrderDeliveryStepper, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices"], context: scopesContext, path: DEVICES_ME_ORDERS_ACTIVATION_ROUTE, children: inShippingDevices.length === 1 ? /* @__PURE__ */ jsx(Redirect, { to: generatePath(DEVICES_ME_ORDER_ACTIVATION_ROUTE, { orderId: inShippingDevices[0].id }) }) : /* @__PURE__ */ jsx(DevicesActivationPage, { deviceOrders: inShippingDevices, loading }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices"], context: scopesContext, path: DEVICES_ME_ORDER_DETAILS_ROUTE, children: /* @__PURE__ */ jsx(DeviceOrderDetailsPage, { loading, setLoading, refreshOrders: refreshDevicesAppData }) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["devices.store", "devices:all"],
        context: scopesContext,
        path: DEVICES_ME_STORE_ROUTE,
        children: /* @__PURE__ */ jsx(
          Suspense,
          {
            fallback: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                width: "90%",
                height: "90vh",
                sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
              }
            ),
            children: /* @__PURE__ */ jsx(DeviceStorePage, { loading, refresh: refreshDevicesAppData, reach: "me" })
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices"], context: scopesContext, path: DEVICES_ME_DIRECTORY_ROUTE, children: /* @__PURE__ */ jsx(
      MyDevicesOverviewPage,
      {
        deviceOrders,
        deviceTransits,
        refreshDevicesData: refreshDevicesAppData
      }
    ) }),
    /* @__PURE__ */ jsx(Redirect, { to: DEVICES_ME_DIRECTORY_ROUTE })
  ] });
};
