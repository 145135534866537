"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { capitalize, keyBy } from "lodash";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/fields/Edit.svg";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DocumentNewImportFormModal } from "@/v2/feature/document-new/document-new-import/document-new-import-form-modal.component";
import { validateDocumentImport } from "@/v2/feature/entity-import/wizard/entity-import-validator.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const DocumentNewImportAssignTypeModal = React.lazy(
  () => import("@/v2/feature/document-new/document-new-import/document-new-import-assign-type-modal.component").then(
    (module) => ({
      default: module.DocumentNewImportAssignTypeModal
    })
  )
);
const DocumentNewImportAssignOwnerModal = React.lazy(
  () => import("@/v2/feature/document-new/document-new-import/document-new-import-assign-owner-modal.component").then(
    (module) => ({
      default: module.DocumentNewImportAssignOwnerModal
    })
  )
);
export const NewDocumentImportResultTable = ({
  result,
  completeImport,
  onUpdateAndRevalidate,
  loading,
  setLoading,
  documentTypes
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [editOpen, setEditOpen] = useState(false);
  const [assignTypeOpen, setAssignTypeOpen] = useState(false);
  const [assignOwnerOpen, setAssignOwnerOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(void 0);
  const [selectionModel, setSelectionModel] = useState([]);
  const rowData = useMemo(() => {
    if (result == null ? void 0 : result.errors.some((e) => e.entity)) {
      return result == null ? void 0 : result.errors.flatMap((record) => record.entity);
    }
  }, [result]);
  const rowErrors = useMemo(() => {
    if (result == null ? void 0 : result.errors.some((e) => e.errors.length > 0)) {
      return result == null ? void 0 : result.errors.map((record) => record.errors);
    }
  }, [result == null ? void 0 : result.errors]);
  const documentTypesLookup = useMemo(() => keyBy(documentTypes != null ? documentTypes : [], "id"), [documentTypes]);
  const totalPendingErrors = useMemo(() => {
    var _a, _b;
    return (_b = (_a = rowErrors == null ? void 0 : rowErrors.flat()) == null ? void 0 : _a.length) != null ? _b : 0;
  }, [rowErrors]);
  const notifyPendingErrors = useCallback(() => {
    showMessage("Please assign user and type to each document", "info");
  }, [showMessage]);
  const notifyDataValid = useCallback(() => {
    showMessage("Your document data looks good! Continue to finish import.", "success");
  }, [showMessage]);
  useEffect(() => {
    if (rowErrors == null ? void 0 : rowErrors.length) {
      notifyPendingErrors();
    } else {
      notifyDataValid();
    }
  }, [notifyDataValid, notifyPendingErrors, rowErrors == null ? void 0 : rowErrors.length]);
  const updateDatasetWithRecord = useCallback(
    (values) => {
      if (!rowData) return [];
      const foundIndex = rowData.findIndex((record) => record.id === values.id);
      if (foundIndex < 0) return rowData;
      const olderRecord = rowData[foundIndex];
      const updatedEntry = __spreadProps(__spreadValues(__spreadValues({}, olderRecord), values), {
        id: olderRecord.id
      });
      const updatedRecords = rowData ? [...rowData] : [];
      updatedRecords[foundIndex] = updatedEntry;
      return updatedRecords;
    },
    [rowData]
  );
  const updateStatusWithRecord = useCallback(
    (validationResult) => {
      return __spreadProps(__spreadValues({}, validationResult), {
        errors: validationResult.errors.map((result2) => {
          return __spreadProps(__spreadValues({}, result2), {
            entity: __spreadProps(__spreadValues({}, result2.entity), {
              state: result2.errors.length === 0 ? "complete" : "pending"
              // Set 'complete' if no errors, otherwise 'pending'
            })
          });
        })
      });
    },
    []
  );
  const importHandler = useCallback(
    (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const updatedRecords = updateDatasetWithRecord(values);
        const validationResult = yield validateDocumentImport(updatedRecords);
        const updatedStatusRecords = updateStatusWithRecord(validationResult);
        onUpdateAndRevalidate(updatedStatusRecords);
      } catch (error) {
        showMessage("Something went wrong", "error");
      } finally {
        setLoading(false);
      }
    }),
    [onUpdateAndRevalidate, setLoading, updateStatusWithRecord, updateDatasetWithRecord, showMessage]
  );
  const assignOwnerHandler = useCallback(
    (userId) => __async(void 0, null, function* () {
      if (!selectionModel || selectionModel.length === 0 || !rowData) return;
      setLoading(true);
      try {
        const updatedDocuments = rowData.map((document) => {
          if (selectionModel.includes(document.id)) {
            return __spreadProps(__spreadValues({}, document), { userId });
          }
          return document;
        });
        const validationResult = yield validateDocumentImport(updatedDocuments);
        const updatedStatusRecords = updateStatusWithRecord(validationResult);
        onUpdateAndRevalidate(updatedStatusRecords);
        setSelectionModel([]);
        setAssignOwnerOpen(false);
      } catch (error) {
        showMessage("Failed to assign owner to selected documents", "error");
      } finally {
        setLoading(false);
      }
    }),
    [onUpdateAndRevalidate, rowData, selectionModel, setLoading, showMessage, updateStatusWithRecord]
  );
  const assignTypeHandler = useCallback(
    (typeId) => __async(void 0, null, function* () {
      if (!selectionModel || selectionModel.length === 0 || !rowData) return;
      setLoading(true);
      try {
        const updatedDocuments = rowData.map((document) => {
          if (selectionModel.includes(document.id)) {
            return __spreadProps(__spreadValues({}, document), { typeId });
          }
          return document;
        });
        const validationResult = yield validateDocumentImport(updatedDocuments);
        const updatedStatusRecords = updateStatusWithRecord(validationResult);
        onUpdateAndRevalidate(updatedStatusRecords);
        setSelectionModel([]);
        setAssignTypeOpen(false);
      } catch (error) {
        showMessage("Failed to assign owner to selected documents", "error");
      } finally {
        setLoading(false);
      }
    }),
    [onUpdateAndRevalidate, rowData, selectionModel, setLoading, showMessage, updateStatusWithRecord]
  );
  const deleteBulkHandler = useCallback(() => __async(void 0, null, function* () {
    if (!selectionModel || selectionModel.length === 0 || !rowData) return;
    setLoading(true);
    try {
      const updatedDocuments = rowData.filter((document) => !selectionModel.includes(document.id));
      const validationResult = yield validateDocumentImport(updatedDocuments);
      const updatedStatusRecords = updateStatusWithRecord(validationResult);
      onUpdateAndRevalidate(updatedStatusRecords);
      setSelectionModel([]);
      setSelectionModel([]);
    } catch (error) {
      showMessage("Failed to assign owner to selected documents", "error");
    } finally {
      setLoading(false);
    }
  }), [onUpdateAndRevalidate, selectionModel, rowData, setLoading, showMessage, updateStatusWithRecord]);
  const deleteHandler = useCallback(
    (toDeleteId) => __async(void 0, null, function* () {
      if (!rowData) return;
      setLoading(true);
      try {
        const updatedDocuments = (rowData != null ? rowData : []).filter((document) => document.id !== toDeleteId);
        const validationResult = yield validateDocumentImport(updatedDocuments);
        const updatedStatusRecords = updateStatusWithRecord(validationResult);
        onUpdateAndRevalidate(updatedStatusRecords);
      } catch (error) {
        showMessage("Failed to assign owner to selected documents", "error");
      } finally {
        setLoading(false);
      }
    }),
    [onUpdateAndRevalidate, rowData, setLoading, showMessage, updateStatusWithRecord]
  );
  const getDocumentActions = () => {
    return [
      {
        handler: () => deleteBulkHandler(),
        label: "Delete selected",
        disabled: false
      },
      {
        handler: () => {
          setAssignOwnerOpen(true);
        },
        label: "Assign owner",
        disabled: false
      },
      {
        handler: () => setAssignTypeOpen(true),
        label: "Assign type",
        disabled: false
      }
    ];
  };
  const columns = useMemo(() => {
    return [
      {
        id: "select",
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        header: () => {
          const selectableDocumentIds = new Set((rowData || []).map(({ id }) => id));
          const allSelected = selectionModel.length > 0 && selectionModel.length === selectableDocumentIds.size && selectionModel.every((id) => selectableDocumentIds.has(id));
          return /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: "allSelected",
              checked: allSelected,
              value: "allSelected",
              onChange: (_, checked) => {
                setSelectionModel(checked ? [...selectableDocumentIds] : []);
              }
            }
          ) });
        },
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: (_a = original.id) == null ? void 0 : _a.toString(),
              checked: selectionModel.includes(original.id),
              value: (_b = original.id) == null ? void 0 : _b.toString(),
              onChange: () => {
                let finalArray;
                if (selectionModel == null ? void 0 : selectionModel.includes(original.id)) {
                  finalArray = selectionModel.filter((sm) => sm !== original.id);
                } else finalArray = [...selectionModel, original.id];
                setSelectionModel(finalArray);
              }
            }
          ) });
        }
      },
      {
        header: () => polyglot.t("DocumentTable.name"),
        accessorFn: (row) => row,
        id: "name",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        maxSize: 180,
        minSize: 160,
        cell: ({ row: { original } }) => original.name ? /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }, children: original.name }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("DocumentTable.type"),
        accessorFn: (row) => row,
        id: "typeId",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        maxSize: 140,
        minSize: 120,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Fragment, { children: documentTypesLookup && original.typeId && documentTypesLookup[original.typeId] ? documentTypesLookup[original.typeId].label : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => polyglot.t("DocumentTable.belongsTo"),
        accessorFn: (row) => row,
        id: "userId",
        enableSorting: false,
        maxSize: 120,
        minSize: 100,
        cell: ({ row: { original } }) => {
          return original.userId ? /* @__PURE__ */ jsx(UserCell, { userId: original.userId }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Unassigned" });
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "state",
        enableSorting: false,
        maxSize: 120,
        minSize: 100,
        cell: ({ row: { original } }) => {
          return original.state ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: capitalize(original.state) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "",
        id: "actions",
        enableSorting: false,
        maxSize: 80,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
            /* @__PURE__ */ jsx(
              IconButton,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => {
                  setEditOpen(true);
                  setSelectedDocument(original);
                },
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            ),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => __async(void 0, null, function* () {
                  yield deleteHandler(original.id);
                }),
                children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
              }
            )
          ] });
        }
      }
    ];
  }, [documentTypesLookup, polyglot, rowData, selectionModel, deleteHandler]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(BasicTable, { rowData: rowData != null ? rowData : [], columnData: columns, loading: false }),
    /* @__PURE__ */ jsx(
      DocumentNewImportFormModal,
      {
        isOpen: editOpen,
        setIsOpen: setEditOpen,
        onClose: () => {
          setEditOpen(false);
        },
        documentTypes,
        document: selectedDocument,
        onFormSubmit: (values) => {
          importHandler(values);
          setEditOpen(false);
        }
      }
    ),
    /* @__PURE__ */ jsx(React.Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100%" }), children: /* @__PURE__ */ jsx(
      DocumentNewImportAssignOwnerModal,
      {
        isOpen: assignOwnerOpen,
        setIsOpen: setAssignOwnerOpen,
        onClose: () => {
          setAssignOwnerOpen(false);
        },
        onFormSubmit: (value) => {
          assignOwnerHandler(value);
        }
      }
    ) }),
    /* @__PURE__ */ jsx(React.Suspense, { fallback: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100%" }), children: /* @__PURE__ */ jsx(
      DocumentNewImportAssignTypeModal,
      {
        isOpen: assignTypeOpen,
        setIsOpen: setAssignTypeOpen,
        onClose: () => {
          setAssignTypeOpen(false);
        },
        documentTypes,
        onFormSubmit: (value) => {
          assignTypeHandler(value);
        }
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, marginTop: "30px" }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: loading,
          onClick: () => {
            if (rowErrors == null ? void 0 : rowErrors.length) {
              notifyPendingErrors();
            } else {
              completeImport();
            }
          },
          children: totalPendingErrors ? "Try again" : "Continue"
        }
      ),
      Boolean(selectionModel && selectionModel.length > 0) && /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          disabled: loading,
          options: getDocumentActions(),
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "medium",
            title: "Actions",
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end"
          }
        }
      )
    ] })
  ] });
};
