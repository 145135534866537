"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import useMessage from "@/hooks/notification.hook";
import { ConfirmModal } from "@/v2/components/confirm-modal.component";
import { useUserProfileConfig } from "@/v2/feature/user/features/user-profile/user-profile.hook";
import { UserProfileActionAPI } from "@/v2/feature/user/features/user-profile-action/user-profile-action.api";
export const UserProfileActionModal = ({
  userId,
  deactivationDate,
  actionMode,
  setActionMode,
  refresh
}) => {
  const [showMessage] = useMessage();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [altAction, setAltAction] = useState("");
  const [confirmModalTitle, setConfirmModalTitle] = useState("");
  const { userOverviewConfig } = useUserProfileConfig(userId);
  useEffect(() => {
    switch (actionMode) {
      case "cancelDeactivate":
        setIsConfirmModalOpen(true);
        setAltAction("cancel");
        setConfirmModalTitle("Cancel Deactivation");
        break;
      case "reactivate":
        setIsConfirmModalOpen(true);
        setAltAction("reactivate");
        setConfirmModalTitle("Reactivate User");
        break;
    }
  }, [actionMode]);
  const triggerCancelDeactivate = () => __async(void 0, null, function* () {
    if (deactivationDate) yield UserProfileActionAPI.cancelDeactivation(userId);
    showMessage("Successfully cancelled deactivation for user.", "success");
    refresh();
    setIsConfirmModalOpen(false);
  });
  const triggerReactivate = () => __async(void 0, null, function* () {
    if (deactivationDate || userOverviewConfig.accountStatus === "Deactivated") {
      yield UserProfileActionAPI.reactivateUser(userId);
      showMessage("Successfully re-activated user.", "success");
      refresh();
    } else {
      showMessage(
        "Failed to re-activate user as they are neither in a deactivated state nor do they have a deactivation date",
        "warning"
      );
    }
    setIsConfirmModalOpen(false);
  });
  const confirmModalTakeAction = () => {
    if (altAction === "cancel") triggerCancelDeactivate();
    else if (altAction === "reactivate") triggerReactivate();
  };
  const confirmModalMessage = "This action will revert employee\u2019s status to Active and they will be able to access their account as usual. Are you sure?";
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    ConfirmModal,
    {
      isOpen: isConfirmModalOpen,
      onClose: () => {
        setActionMode(void 0);
        setIsConfirmModalOpen(false);
      },
      takeAction: confirmModalTakeAction,
      title: confirmModalTitle,
      callToAction: confirmModalTitle,
      callToActionButtonType: "primary",
      message: confirmModalMessage,
      type: "info"
    }
  ) });
};
