"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { IconButton } from "@v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { MultiUserAvatar } from "@v2/components/theme-components/multi-user-avatar.component";
import { SpecificUserModal } from "@v2/components/user-select-type/components/specific-user-modal.component";
import { UserSelectFiltersOptions } from "@v2/components/user-select-type/user-select.interface";
import { getApprovalStepSchema } from "@v2/feature/approval-rule/approval-rule.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { styledChipSx } from "@v2/styles/chip.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { Typography } from "@/v2/components/typography/typography.component";
export const AddApprovalStepDrawer = ({
  isOpen,
  setIsOpen,
  addStep,
  existingSteps,
  step,
  stepIndex,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: /* @__PURE__ */ jsx(
    ApprovalRuleDrawerContent,
    {
      setIsOpen,
      addStep,
      existingSteps,
      step,
      stepIndex
    }
  ) });
};
const ApprovalRuleDrawerContent = ({
  setIsOpen,
  addStep,
  existingSteps,
  step,
  stepIndex
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isSpecificOpen, setSpecificIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState(UserSelectFiltersOptions.None);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!values.adminApproval && !values.managerManagerApproval && !values.managerApproval && values.specificApprovers.length === 0) {
        showMessage("Please select at least 1 approvers group.", "error");
        return;
      }
      const ruleData = {
        adminApproval: values.adminApproval,
        managerManagerApproval: values.managerManagerApproval,
        managerApproval: values.managerApproval,
        specificApprovers: values.specificApprovers,
        minApprovers: Number(values.minApprovers)
      };
      addStep(ruleData, stepIndex);
      setIsOpen(false);
    }),
    [showMessage, setIsOpen, addStep, stepIndex]
  );
  const { showAdmin, showManager, showManagersManager, alreadySelectedUsers } = useMemo(() => {
    let showAdmin2 = true;
    let showManager2 = true;
    let showManagersManager2 = true;
    let alreadySelectedUsers2 = [];
    for (const [index, currentStep] of existingSteps.entries()) {
      if (step && index === stepIndex) continue;
      if (currentStep.adminApproval) showAdmin2 = false;
      if (currentStep.managerApproval) showManager2 = false;
      if (currentStep.managerManagerApproval) showManagersManager2 = false;
      if (currentStep.specificApprovers) alreadySelectedUsers2.push(...currentStep.specificApprovers);
    }
    return { showAdmin: showAdmin2, showManager: showManager2, showManagersManager: showManagersManager2, alreadySelectedUsers: alreadySelectedUsers2 };
  }, [stepIndex, step, existingSteps]);
  const formik = useFormik({
    initialValues: {
      adminApproval: (_a = step == null ? void 0 : step.adminApproval) != null ? _a : false,
      managerManagerApproval: (_b = step == null ? void 0 : step.managerManagerApproval) != null ? _b : false,
      managerApproval: (_c = step == null ? void 0 : step.managerApproval) != null ? _c : false,
      specificApprovers: (_d = step == null ? void 0 : step.specificApprovers) != null ? _d : [],
      minApprovers: (_e = step == null ? void 0 : step.minApprovers) != null ? _e : 1
    },
    validationSchema: getApprovalStepSchema(polyglot),
    onSubmit
  });
  const setRuleValue = (fieldName, value) => {
    formik.setFieldValue(fieldName, value);
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ApprovalRuleModule.newStep") }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: "5px" }, children: "Who should approve?" }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Select from specific employees or placeholders" })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexWrap: "wrap", gap: "5px", alignItems: "center" }, children: [
      !formik.values.adminApproval && showAdmin && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: "secondary",
          onClick: () => setRuleValue("adminApproval", true),
          children: polyglot.t("ApproverSelectComponent.Admin")
        }
      ),
      !formik.values.managerManagerApproval && showManagersManager && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: "secondary",
          onClick: () => setRuleValue("managerManagerApproval", true),
          children: polyglot.t("ApproverSelectComponent.ManagersManager")
        }
      ),
      !formik.values.managerApproval && showManager && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: "secondary",
          onClick: () => setRuleValue("managerApproval", true),
          children: polyglot.t("ApproverSelectComponent.Manager")
        }
      ),
      (!((_f = formik.values) == null ? void 0 : _f.specificApprovers) || formik.values.specificApprovers.length === 0) && /* @__PURE__ */ jsx(Button, { sx: styledChipSx, onClick: () => setSpecificIsOpen(true), children: polyglot.t("ApproverSelectComponent.selectSpecific") }),
      /* @__PURE__ */ jsx(
        SpecificUserModal,
        {
          isSpecificOpen,
          setSpecificIsOpen,
          label: polyglot.t("UserSelect.specific"),
          value: (_g = formik.values.specificApprovers) != null ? _g : [],
          onChange: (userIds) => {
            setRuleValue("specificApprovers", userIds);
          },
          filterValue,
          setLastValidFilterValue: setFilterValue,
          excludeUsers: alreadySelectedUsers
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: [
      formik.values.adminApproval && /* @__PURE__ */ jsx(
        SelectedChip,
        {
          label: polyglot.t("ApproverSelectComponent.Admin"),
          onClick: () => setRuleValue("adminApproval", false)
        }
      ),
      formik.values.managerManagerApproval && /* @__PURE__ */ jsx(
        SelectedChip,
        {
          label: polyglot.t("ApproverSelectComponent.ManagersManager"),
          onClick: () => setRuleValue("managerManagerApproval", false)
        }
      ),
      formik.values.managerApproval && /* @__PURE__ */ jsx(
        SelectedChip,
        {
          label: polyglot.t("ApproverSelectComponent.Manager"),
          onClick: () => setRuleValue("managerApproval", false)
        }
      ),
      formik.values.specificApprovers.length > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: (_h = formik.values.specificApprovers) != null ? _h : [], showLimit: 5 }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "filter",
            colorVariant: "secondary",
            style: { marginLeft: "auto", marginRight: "10px" },
            onClick: () => setSpecificIsOpen(true),
            children: polyglot.t("ApproverSelectComponent.selectSpecific")
          }
        ),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            colorVariant: "secondary",
            sizeVariant: "small",
            onClick: () => setRuleValue("specificApprovers", []),
            children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "How many of them must approve?",
        name: "minApprovers",
        value: formik.values.minApprovers,
        onChange: formik.handleChange,
        error: formik.touched.minApprovers && !!formik.errors.minApprovers,
        helperText: (_i = formik.touched.minApprovers && formik.errors.minApprovers) != null ? _i : ""
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, children: polyglot.t("General.save") }) })
  ] }) });
};
export const SelectedChip = ({ label, onClick }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: label }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(IconButton, { colorVariant: "secondary", sizeVariant: "small", onClick, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) })
  ] });
};
