"use strict";
export const booleanValues = /* @__PURE__ */ new Set([true, false]);
export const stringifiedBooleanValues = /* @__PURE__ */ new Set(["true", "false"]);
export const zeroValues = /* @__PURE__ */ new Set([0]);
export function sum(entries, getValue) {
  return entries.reduce((total, entry) => {
    var _a;
    return total + ((_a = getValue(entry)) != null ? _a : 0);
  }, 0);
}
const caseInsensitiveCollator = new Intl.Collator(void 0, { sensitivity: "base" });
export function caseInsensitiveFind(array, findValue, getValue) {
  return array.find((item) => caseInsensitiveCollator.compare(getValue(item), findValue) === 0);
}
export function caseInsensitiveSort(a, b, getValue) {
  return caseInsensitiveCollator.compare(getValue(a), getValue(b));
}
export function clamp(array, index) {
  if (index < 0) return array[0];
  if (index >= array.length) return array[array.length - 1];
  return array[index];
}
export function filterByTextSearch(search, array, getTextValues) {
  if (!search) {
    return array.slice();
  }
  const lowerSearch = search.toLocaleLowerCase();
  return array.filter((item) => {
    const texts = getTextValues(item);
    return texts.some((text) => typeof text === "string" && text.toLocaleLowerCase().includes(lowerSearch));
  });
}
