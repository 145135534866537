"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { AppDetailsEndpoints } from "@v2/feature/app-integration/features/app-details/app-details.api";
import { AuthEndpoints } from "@v2/feature/auth/auth.api";
import { SSOConfigureDrawer } from "@v2/feature/security/security-settings/components/sso-configure-drawer.component";
import { SSOAppsEnum } from "@v2/feature/security/security-settings/security.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useJune } from "@/v2/infrastructure/june/june.hook";
export const SecuritySettingSSODetailPage = () => {
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();
  const { data: mfaEnabled, isLoading: loadingMFa } = useApiClient(AuthEndpoints.mfaCheck(), {
    suspense: false
  });
  const { data: ssoState, isLoading: loadingSSO, mutate: refreshSSO } = useApiClient(
    AppDetailsEndpoints.getSSOMetadata(),
    {
      suspense: false
    }
  );
  const [editSSO, setEditSSO] = useState(false);
  useEffect(() => {
    trackPage("Company security settings");
  }, []);
  const refreshSSOState = useCallback(() => __async(void 0, null, function* () {
    if (refreshSSO) yield refreshSSO();
  }), [refreshSSO]);
  const { certificate, apikey, enabledEntry, ssoProvider } = useMemo(() => {
    var _a, _b, _c, _d, _e;
    const gwEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "google-workspace");
    const ssoGwEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "sso-google-workspace");
    const ssoAzureAdEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "sso-azure-ad");
    const ssoOktaEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "sso-okta");
    const enabledEntry2 = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.state.enabled);
    const apikey2 = (_b = (_a = ssoOktaEntry == null ? void 0 : ssoOktaEntry.state) == null ? void 0 : _a.apikey) != null ? _b : "";
    const ssoProvider2 = enabledEntry2 ? SSOAppsEnum[enabledEntry2.app] : "None";
    let certificate2 = "";
    if (gwEntry) certificate2 = (_c = gwEntry == null ? void 0 : gwEntry.state) == null ? void 0 : _c.certificate;
    if (ssoGwEntry) certificate2 = (_d = ssoGwEntry == null ? void 0 : ssoGwEntry.state) == null ? void 0 : _d.certificate;
    if (ssoAzureAdEntry) certificate2 = (_e = ssoAzureAdEntry == null ? void 0 : ssoAzureAdEntry.state) == null ? void 0 : _e.certificate;
    return { certificate: certificate2, apikey: apikey2, enabledEntry: enabledEntry2, ssoProvider: ssoProvider2 };
  }, [ssoState]);
  const secondConfigTitle = useMemo(() => {
    if (enabledEntry && (enabledEntry.app === "sso-google-workspace" || enabledEntry.app === "sso-azure-ad") && (certificate == null ? void 0 : certificate.length) > 0)
      return "IdP Metadata";
    else if (enabledEntry && enabledEntry.app === "sso-okta" && (apikey == null ? void 0 : apikey.length) > 0) return "API token";
    else return "";
  }, [enabledEntry, certificate, apikey]);
  const secondConfigValue = useMemo(() => {
    if (enabledEntry && (enabledEntry.app === "sso-google-workspace" || enabledEntry.app === "sso-azure-ad") && (certificate == null ? void 0 : certificate.length) > 0)
      return certificate;
    else if (enabledEntry && enabledEntry.app === "sso-okta" && (apikey == null ? void 0 : apikey.length) > 0) return apikey;
    else return "";
  }, [enabledEntry, certificate, apikey]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: loadingSSO || loadingMFa, children: [
    /* @__PURE__ */ jsx(SettingsSectionContent, { title: polyglot.t("SettingsRouterPageConfig.security"), children: /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            title: polyglot.t("SSOMetadata.singleSignOn"),
            onEdit: () => setEditSSO(true),
            items: [
              {
                type: SectionItemType.TextLine,
                value: polyglot.t("SSOMetadata.connectYourCompany")
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t("SSOMetadata.ssoProvider"),
                value: ssoProvider
              },
              {
                type: SectionItemType.Pair,
                label: secondConfigTitle,
                value: secondConfigValue
              }
            ]
          }
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(
      SSOConfigureDrawer,
      {
        isOpen: editSSO,
        setIsOpen: setEditSSO,
        onClose: () => setEditSSO(false),
        mfaEnabled: !!mfaEnabled,
        ssoState: ssoState != null ? ssoState : [],
        onUpdate: () => __async(void 0, null, function* () {
          yield refreshSSOState();
        })
      }
    )
  ] });
};
