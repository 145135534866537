"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as DocumentIcon } from "@/images/onboarding-step-icons/DocumentBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { EditDocuments } from "@/v2/feature/onboarding/onboarding-items/documents/edit-documents.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DocumentsOnboardingItem = ({
  template,
  onDelete,
  setDrawerOpen,
  setDrawerView,
  checkOnboardingItemScope
}) => {
  const { polyglot } = usePolyglot();
  const canAddDocuments = checkOnboardingItemScope(DocumentsScopes.LIST_DOCUMENT_TYPES);
  const [allDocumentTypes, setDocumentTypes] = useState();
  const refreshDocumentTypes = useCallback(() => __async(void 0, null, function* () {
    if (!canAddDocuments) return;
    const [documentTypesFromServer] = yield Promise.all([DocumentAPI.listDocumentTypes()]);
    const filteredDocuments = documentTypesFromServer.filter((type) => type.availableTo === "personal" && type.visible);
    setDocumentTypes(filteredDocuments);
  }), [canAddDocuments]);
  useEffect(() => {
    refreshDocumentTypes();
  }, [refreshDocumentTypes]);
  const chips = useMemo(() => {
    if (!allDocumentTypes) return [];
    if (!template.template.documents) return [];
    return [
      ...template.template.documents.types.reduce((result, t) => {
        var _a;
        const label = t !== "other" && ((_a = allDocumentTypes.find((dt) => dt.value === t)) == null ? void 0 : _a.label);
        if (label) result.push(label);
        return result;
      }, []),
      ...template.template.documents.others
    ];
  }, [allDocumentTypes, template.template.documents]);
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: DocumentIcon,
        title: polyglot.t("DocumentsOnboardingItem.title"),
        description: polyglot.t("DocumentsOnboardingItem.desc")
      },
      chips,
      addButtonIcon: template.template.documents ? "edit" : "plus",
      onAddClick: canAddDocuments && allDocumentTypes && (() => {
        var _a, _b;
        setDrawerView(
          /* @__PURE__ */ jsx(
            EditDocuments,
            {
              documentTypes: (_a = template.template.documents) == null ? void 0 : _a.types,
              allDocumentTypes,
              otherDocumentNames: (_b = template.template.documents) == null ? void 0 : _b.others,
              onSave: (types, others) => {
                template.template.documents = types.length || others.length ? {
                  types,
                  others
                } : void 0;
                setDrawerOpen(false);
              }
            }
          )
        );
        setDrawerOpen(true);
      }),
      onDeleteClick: template.template.documents && onDelete,
      sx: {
        py: spacing.py20
      }
    }
  );
};
