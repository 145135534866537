"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import {
  StyledTabs,
  StyledTab
} from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { StyledBadge } from "@/v2/styles/badge.styles";
export const UserImportResultsTabs = ({
  tabs,
  currentTab,
  onChange,
  sx,
  errorCountByTab
}) => {
  return /* @__PURE__ */ jsx(Box, { sx, children: /* @__PURE__ */ jsx(
    StyledTabs,
    {
      value: currentTab || false,
      scrollButtons: "auto",
      variant: "scrollable",
      allowScrollButtonsMobile: true,
      onChange: (_, value) => {
        onChange == null ? void 0 : onChange(value);
      },
      children: tabs.map((label) => /* @__PURE__ */ jsx(
        StyledTab,
        {
          disableRipple: true,
          label: /* @__PURE__ */ jsxs(
            "div",
            {
              style: {
                display: "flex",
                alignItems: "center",
                gap: "15px",
                textTransform: "inherit",
                marginRight: 10,
                marginLeft: 10
              },
              children: [
                /* @__PURE__ */ jsx("span", { children: label }),
                errorCountByTab[label] > 0 ? /* @__PURE__ */ jsx(StyledBadge, { sx: { mx: "5px" }, badgeContent: errorCountByTab[label], color: "error" }) : /* @__PURE__ */ jsx(Fragment, {})
              ]
            }
          ),
          value: label
        },
        label
      ))
    }
  ) });
};
