"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Loader } from "@v2/components/loader.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { InsuranceCompanyEmployeeListPage } from "@v2/feature/benefits/subfeature/insurance/pages/insurance-company-employee-list.page";
import { InsuranceCompanyOverviewPage } from "@v2/feature/benefits/subfeature/insurance/pages/insurance-company-overview.page";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE, INSURANCE_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
export const InsuranceCompanyRouter = ({
  insuranceQuote,
  refreshInsuranceQuote,
  loading
}) => {
  const [showMessage] = useMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [insurancePolicy, setInsurancePolicy] = useState(null);
  const fetchInsurancePolicy = useCallback(() => __async(void 0, null, function* () {
    try {
      if (insuranceQuote == null ? void 0 : insuranceQuote.policyId) {
        setIsLoading(true);
        const insurancePolicy2 = yield InsuranceAPI.getInsurancePolicyById(insuranceQuote.policyId, true);
        setInsurancePolicy(insurancePolicy2);
      }
    } catch (error) {
      showMessage(`Could not get insurance policy details. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoading(false);
    }
  }), [insuranceQuote == null ? void 0 : insuranceQuote.policyId, showMessage]);
  useEffect(() => {
    fetchInsurancePolicy();
  }, [fetchInsurancePolicy]);
  return /* @__PURE__ */ jsx(Loader, { loading: isLoading || loading, children: /* @__PURE__ */ jsxs(Switch, { children: [
    insurancePolicy && /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: ["insurance:all"], path: INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE, children: /* @__PURE__ */ jsx(InsuranceCompanyEmployeeListPage, { insurancePolicy, loading: isLoading || loading }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance:all"], path: INSURANCE_COMPANY_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(
      InsuranceCompanyOverviewPage,
      {
        insurancePolicy: insurancePolicy != null ? insurancePolicy : void 0,
        insuranceQuote,
        loading: isLoading || loading,
        refresh: refreshInsuranceQuote
      }
    ) })
  ] }) });
};
