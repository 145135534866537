"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { spacing } from "@v2/styles/spacing.styles";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
export const EmptyStateComponent = ({
  header,
  subheader,
  detail,
  actionTitle,
  action,
  cover,
  width = "35%",
  actionScope = []
}) => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: __spreadValues({
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
      }, spacing.px40),
      children: /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            mt: 2,
            width,
            px: 1.5,
            py: 3.5,
            borderRadius: 1
          },
          children: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "100%"
              },
              children: [
                /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx("img", { src: cover, alt: "payroll-empty", width: 160, height: "auto" }) }) }),
                /* @__PURE__ */ jsx(
                  Box,
                  {
                    sx: {
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3
                    },
                    children: /* @__PURE__ */ jsxs(Box, { sx: { display: "block", textAlign: "center" }, children: [
                      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: { fontSize: 20, fontWeight: 600, color: "text.secondary" }, children: header }) }),
                      /* @__PURE__ */ jsx(Typography, { sx: { fontSize: 13, color: "text.secondary", mt: 1 }, children: subheader }),
                      /* @__PURE__ */ jsx(Typography, { sx: { fontSize: 12, color: "text.secondary", mt: 1 }, children: detail })
                    ] })
                  }
                ),
                /* @__PURE__ */ jsx(Box, { sx: { mt: 2 }, children: actionTitle && action && /* @__PURE__ */ jsx(ScopesControl, { scopes: actionScope, context: getScopesContext({ userId: state.user.userId }), children: /* @__PURE__ */ jsx(Button, { variant: "contained", color: "primary", onClick: () => action(), size: "small", sx: { width: 160 }, children: actionTitle }) }) })
              ]
            }
          )
        }
      )
    }
  );
};
export const TableEmptyState = ({ title, subtitle }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { textAlign: "center", mt: 10 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: { fontSize: 14 }, children: title }),
    subtitle && /* @__PURE__ */ jsx(Typography, { sx: { fontSize: 14 }, children: subtitle })
  ] });
};
