"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DeletePensionDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/components/delete-pension-drawer.component";
import { EditPensionGeneralDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/components/edit-pension-general-drawer.component";
import {
  BenefitsSettingsPageSection,
  DescriptionLine
} from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
export const PensionSettingsGeneralPage = ({ type, pensionScheme, refreshPension }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("General.general"),
      onEdit: () => {
        setIsEditOpen(true);
      },
      children: [
        /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.type"), value: type }),
        /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.displayName"), value: pensionScheme.displayName }),
        ((_b = (_a = pensionScheme == null ? void 0 : pensionScheme.payroll) == null ? void 0 : _a.entity) == null ? void 0 : _b.legalName) && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("PayrollModule.payrollEntity"),
            value: pensionScheme.payroll.entity.legalName
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { mt: "30px" }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "text",
            onClick: () => setIsDeleteOpen(true),
            style: { margin: 0, padding: 0, color: themeColors.RedDark },
            children: polyglot.t("PensionModule.deletePension")
          }
        ) }),
        /* @__PURE__ */ jsx(
          EditPensionGeneralDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            provider: pensionScheme,
            refresh: refreshPension
          }
        ),
        /* @__PURE__ */ jsx(DeletePensionDrawer, { isOpen: isDeleteOpen, setIsOpen: setIsDeleteOpen, provider: pensionScheme })
      ]
    }
  );
};
