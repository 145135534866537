"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOrderStatus } from "@v2/feature/device/device.interface";
import { DeviceOrderDetailsPage } from "@v2/feature/device/features/devices-company/pages/device-order-details.page";
import { DevicesLocationPage } from "@v2/feature/device/features/devices-company/pages/devices-location.page";
import { ConfirmDeviceOrderDeliveryStepper } from "@v2/feature/device/features/my-devices/pages/device-activation/confirm-device-delivery-stepper.component";
import { DevicesActivationPage } from "@v2/feature/device/features/my-devices/pages/device-activation/devices-activation.page";
import { generatePath, Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
  DEVICES_COMPANY_DIRECTORY_ROUTE,
  DEVICES_COMPANY_LOCATION_ROUTE,
  DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE,
  DEVICES_COMPANY_ORDER_DETAILS_ROUTE,
  DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE,
  DEVICES_COMPANY_STORE_ROUTE
} from "@/lib/routes";
import { DeviceScopes } from "@/v2/feature/device/device.scopes";
import { DevicesDetailsRouter } from "@/v2/feature/device/features/devices-company/device-details.router";
import { DeviceStorePage } from "@/v2/feature/device/features/devices-company/pages/device-store.page";
import { DevicesActivePage } from "@/v2/feature/device/features/devices-company/pages/devices-active.page";
export const DeviceCompanyRouter = () => {
  const [devicePossessions, setDevicePossessions] = useState([]);
  const [, setDeviceOrders] = useState([]);
  const [inShippingDeviceOrders, setInShippingDeviceOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const [showMessage] = useMessage();
  const refreshDevicePossessions = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [devicePossessions2] = yield Promise.all([DeviceAPI.getActiveDevicePossessions()]);
      setDevicePossessions(devicePossessions2);
    } catch (error) {
      showMessage(`Could not retrieve devices list: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  const refreshOrders = useCallback(() => __async(void 0, null, function* () {
    try {
      const deviceOrders = yield DeviceAPI.getDeviceOrders();
      setDeviceOrders(deviceOrders.filter((deviceOrder) => deviceOrder.status !== DeviceOrderStatus.Delivered));
      setInShippingDeviceOrders(
        deviceOrders.filter((deviceOrder) => deviceOrder.status === DeviceOrderStatus.Shipping)
      );
    } catch (error) {
      showMessage(`Could not retrieve device orders list. ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage]);
  const refreshDeviceAppData = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshDevicePossessions()]);
  }), [refreshDevicePossessions]);
  useEffect(() => {
    refreshDeviceAppData();
  }, [refreshDeviceAppData]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["devices.enroll", "devices:all"],
        path: DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
        context: scopesContext,
        children: /* @__PURE__ */ jsx(DevicesDetailsRouter, { refreshDeviceAppData })
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: DeviceScopes.VIEW_DEVICE_LOCATIONS, path: DEVICES_COMPANY_LOCATION_ROUTE, children: /* @__PURE__ */ jsx(DevicesLocationPage, { devicesPossessions: devicePossessions, loading }) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        exact: true,
        scopes: DeviceScopes.VIEW_DEVICE_STORE,
        path: DEVICES_COMPANY_STORE_ROUTE,
        context: scopesContext,
        children: /* @__PURE__ */ jsx(
          DeviceStorePage,
          {
            loading,
            refresh: () => __async(void 0, null, function* () {
              yield Promise.all([refreshDevicePossessions(), refreshOrders()]);
            }),
            reach: "company"
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: DeviceScopes.CONFIRM_DEVICE_ORDER_DELIVERY,
        context: scopesContext,
        path: DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE,
        children: /* @__PURE__ */ jsx(ConfirmDeviceOrderDeliveryStepper, {})
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: DeviceScopes.VIEW_DEVICE_ORDER_ACTIVATIONS,
        context: scopesContext,
        path: DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE,
        children: inShippingDeviceOrders.length === 1 ? /* @__PURE__ */ jsx(
          Redirect,
          {
            to: generatePath(DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE, { orderId: inShippingDeviceOrders[0].id })
          }
        ) : /* @__PURE__ */ jsx(DevicesActivationPage, { deviceOrders: inShippingDeviceOrders, loading })
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        exact: true,
        context: scopesContext,
        scopes: DeviceScopes.VIEW_DEVICE_ORDER_DETAILS,
        path: DEVICES_COMPANY_ORDER_DETAILS_ROUTE,
        children: /* @__PURE__ */ jsx(DeviceOrderDetailsPage, { loading, setLoading, refreshOrders })
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: DeviceScopes.VIEW_ACTIVE_DEVICES, path: DEVICES_COMPANY_DIRECTORY_ROUTE, children: /* @__PURE__ */ jsx(DevicesActivePage, { loading }) }),
    /* @__PURE__ */ jsx(Redirect, { to: DEVICES_COMPANY_DIRECTORY_ROUTE })
  ] });
};
