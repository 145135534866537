"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  SURVEYS_COMPANY_ONGOING_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_FORM_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_LAUNCH_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_NOTIFICATION_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_PARTICIPANTS_ROUTE,
  SURVEYS_COMPANY_ONGOING_SETTINGS_TIMELINE_ROUTE
} from "@/lib/routes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SCUpsertGeneralPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/sc-upsert-general.page";
import { SCUpsertLaunchPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.page";
import { SurveyCycleCreationParticipantsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/sc-upsert-participants.page";
import { SCUpsertTimelinePage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/sc-upsert-timeline.page";
import { SurveyCycleCreationNotificationsPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-notifications/survey-cycle-creation-notifications.page";
import { SurveyCycleCreationQuestionPage } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/survey-cycle-creation-question.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
export const SURVEYS_ALL_SCOPE = "surveys:all";
export const getPageConfig = (cycleId, surveyCycle, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("CycleSettingsRouter.general"),
          stub: "general",
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE, { cycleId })
        },
        {
          title: "Questions",
          stub: "question",
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_FORM_ROUTE, { cycleId })
        },
        {
          title: "Participants",
          stub: "participants",
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_PARTICIPANTS_ROUTE, { cycleId })
        },
        {
          title: "Timeline",
          stub: "timeline",
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_TIMELINE_ROUTE, { cycleId })
        },
        {
          title: "Notifications",
          stub: "notifications",
          path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_NOTIFICATION_ROUTE, { cycleId })
        },
        ...(surveyCycle == null ? void 0 : surveyCycle.state) && [CycleState.Draft, CycleState.Scheduled].includes(surveyCycle == null ? void 0 : surveyCycle.state) ? [
          {
            title: "Start survey",
            stub: "launch",
            path: generatePath(SURVEYS_COMPANY_ONGOING_SETTINGS_LAUNCH_ROUTE, { cycleId })
          }
        ] : []
      ]
    }
  ];
};
export const SurveyCycleCreationCompanyRouter = () => {
  const { polyglot } = usePolyglot();
  const params = useParams();
  const cycleId = params.cycleId;
  const { data: surveyCycle, mutate: refreshReviewCycle, isLoading: cycleLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, ReachType.Company),
    {
      suspense: false
    }
  );
  const refresh = () => __async(void 0, null, function* () {
    yield refreshReviewCycle == null ? void 0 : refreshReviewCycle();
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "Surveys",
        subtitle: polyglot.t("OngoingSurveyList.surveys"),
        pageConfig: getPageConfig(cycleId, surveyCycle, polyglot),
        backPath: generatePath(SURVEYS_COMPANY_ONGOING_ROUTE, {
          cycleId
        }),
        showBack: true,
        type: "Domain"
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_ALL_SCOPE], path: SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        SCUpsertGeneralPage,
        {
          surveyCycle,
          refresh,
          cycleLoading,
          reach: ReachType.Company
        }
      ) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_ALL_SCOPE], path: SURVEYS_COMPANY_ONGOING_SETTINGS_FORM_ROUTE, children: /* @__PURE__ */ jsx(SurveyCycleCreationQuestionPage, { cycleId }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_ALL_SCOPE], path: SURVEYS_COMPANY_ONGOING_SETTINGS_PARTICIPANTS_ROUTE, children: /* @__PURE__ */ jsx(SurveyCycleCreationParticipantsPage, { cycleId, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_ALL_SCOPE], path: SURVEYS_COMPANY_ONGOING_SETTINGS_TIMELINE_ROUTE, children: /* @__PURE__ */ jsx(SCUpsertTimelinePage, { cycleId, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_ALL_SCOPE], path: SURVEYS_COMPANY_ONGOING_SETTINGS_NOTIFICATION_ROUTE, children: /* @__PURE__ */ jsx(SurveyCycleCreationNotificationsPage, { surveyCycle, refresh }) }),
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: [SURVEYS_ALL_SCOPE], path: SURVEYS_COMPANY_ONGOING_SETTINGS_LAUNCH_ROUTE, children: /* @__PURE__ */ jsx(SCUpsertLaunchPage, { cycleId, reach: ReachType.Company }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SURVEYS_COMPANY_ONGOING_SETTINGS_GENERAL_ROUTE, exact: true })
    ] })
  ] });
};
