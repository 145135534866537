"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { getSelectProps } from "@/v2/styles/menu.styles";
const meridiemOptions = [
  {
    value: "AM",
    label: "AM"
  },
  {
    value: "PM",
    label: "PM"
  }
];
export const TimeTextField = (_a) => {
  var _b = _a, {
    name,
    label,
    value,
    onChange,
    selectedMeridiem,
    onMeridiemChange,
    error,
    helperText,
    disableMeridiemSelector = false,
    sx
  } = _b, props = __objRest(_b, [
    "name",
    "label",
    "value",
    "onChange",
    "selectedMeridiem",
    "onMeridiemChange",
    "error",
    "helperText",
    "disableMeridiemSelector",
    "sx"
  ]);
  const handleMeridiemChange = (event) => {
    onMeridiemChange(event.target.value);
  };
  const handleTimeChange = (event) => {
    onChange(event.target.value);
  };
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadValues({
          display: "flex",
          flexFlow: "row",
          alignItems: "flex-end",
          width: "100%",
          boxSizing: "border-box",
          p: 0,
          m: 0
        }, sx),
        children: [
          /* @__PURE__ */ jsx(Box, { sx: { flex: "1 1 auto", boxSizing: "border-box", p: 0, m: 0 }, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            __spreadProps(__spreadValues({}, props), {
              fullWidth: true,
              value: value === 0 ? "" : value,
              variant: "standard",
              type: "number",
              onChange: handleTimeChange,
              label,
              name,
              minValue: 1,
              maxValue: 12,
              maxLength: 1,
              validateNumber: true,
              error,
              endAdornment: "none",
              sx: {
                "& div.MuiInputBase-root.MuiInput-root": {
                  borderBottomRightRadius: "0px !important",
                  borderTopRightRadius: "0px !important"
                }
              }
            })
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { flex: "0 0 75px", boxSizing: "border-box", p: 0, m: 0 }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              disabled: disableMeridiemSelector,
              value: selectedMeridiem,
              onChange: handleMeridiemChange,
              sx: {
                borderLeft: `1px solid ${themeColors.GreyMiddle}`,
                boxSizing: "border-box",
                "& div.MuiInputBase-root.MuiInput-root": {
                  borderBottomLeftRadius: "0px !important",
                  borderTopLeftRadius: "0px !important"
                }
              },
              variant: "standard",
              size: "small",
              error,
              SelectProps: __spreadValues({}, getSelectProps),
              options: meridiemOptions,
              name: "meridiem"
            }
          ) })
        ]
      }
    ),
    error && helperText && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "RedDark", children: helperText })
  ] });
};
