"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ReassignDeviceUserForm } from "@v2/feature/device/components/device-details/reassign-device-form.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ReassignDeviceDrawer = ({
  isOpen,
  onClose,
  devicePossession,
  setIsOpen,
  isInventory
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(ReassignDeviceDrawerContent, { devicePossession, closePage: onClose, isInventory }) });
const ReassignDeviceDrawerContent = ({
  devicePossession,
  closePage,
  isInventory
}) => {
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const reassignDevice = (currentDevicePossessionId, reassignDeviceData) => __async(void 0, null, function* () {
    let isChangeOwnerRequested = false;
    try {
      yield DeviceAPI.reassignDevice(currentDevicePossessionId, reassignDeviceData);
      isChangeOwnerRequested = true;
      showMessage("Reassign device action has been initiated successfully", "success");
      const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      yield closePage();
    } catch (error) {
      if (isChangeOwnerRequested) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not initiate device reassign action. ${nestErrorMessage(error)}`, "error");
      }
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: isInventory ? "Return" : "Reassign" }),
    /* @__PURE__ */ jsx(
      ReassignDeviceUserForm,
      {
        currentDevicePossession: devicePossession,
        reassignDevice,
        isInventory
      }
    )
  ] });
};
