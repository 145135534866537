"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { buttonBoxSx, fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const DeferEnrollmentDrawer = ({
  isOpen,
  setIsOpen,
  userPension,
  refresh,
  onClose
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(DeferEnrollmentDrawerContent, { setIsOpen, userPension, refreshData: refresh })
  }
) });
const DeferEnrollmentDrawerContent = ({ setIsOpen, userPension, refreshData }) => {
  var _a, _b, _c;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const tomorrow = new LocalDate();
  tomorrow.getDate().setDate(tomorrow.getDate().getDate() + 1);
  const userStartDate = (_b = (_a = getCachedUserById(userPension.userId)) == null ? void 0 : _a.startDate) != null ? _b : /* @__PURE__ */ new Date();
  const lastAvailableEnrollmentDate = new LocalDate(userStartDate);
  lastAvailableEnrollmentDate.getDate().setMonth(lastAvailableEnrollmentDate.getDate().getMonth() + 3);
  const formik = useFormik({
    initialValues: {
      enrollmentDate: tomorrow.toDateString()
    },
    validationSchema: Yup.object({
      enrollmentDate: Yup.string().test(dateFieldTest).required("Enrolment date is required")
    }),
    onSubmit: (_0) => __async(void 0, [_0], function* ({ enrollmentDate }) {
      if (!enrollmentDate) {
        showMessage("A valid date must be provided.", "error");
        return;
      }
      try {
        setLoading(true);
        yield PensionAPI.deferEmployeeEnrolment(userPension.userId, enrollmentDate);
        yield refreshData();
        setIsOpen(false);
      } catch (error) {
        console.error(error);
        showMessage(`Could not defer enrollment. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Deferral" }),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "You can choose to postpone (or defer) employee enrolment into a pension scheme for up to three months. Employee will be automatically enrolled on the selected deferred date." }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { my: spacing.my20 }), children: [
      "Employee will be notified of this postponement. Read more about it",
      " ",
      /* @__PURE__ */ jsx(
        "a",
        {
          href: "https://www.thepensionsregulator.gov.uk/en/employers/new-employers/im-an-employer-who-has-to-provide-a-pension/work-out-who-to-put-into-a-pension/postponement",
          target: "_blank",
          rel: "noreferrer",
          children: "here"
        }
      ),
      "."
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { mb: spacing.mb20 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), { color: themeColors.Grey }), children: "Employee" }),
      /* @__PURE__ */ jsx(UserCell, { userId: userPension.userId, sx: { mt: spacing.m5 }, nameSx: __spreadValues({}, themeFonts.title4) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "enrollmentDate",
        label: "Enrollment date",
        inputFormat: "DD/MM/YYYY",
        value: (_c = formik.values.enrollmentDate) != null ? _c : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("enrollmentDate", value);
          }
        },
        error: !!formik.errors.enrollmentDate && Boolean(formik.touched.enrollmentDate),
        helperText: formik.errors.enrollmentDate && Boolean(formik.touched.enrollmentDate),
        disablePast: true,
        minDate: tomorrow.getDate(),
        maxDate: lastAvailableEnrollmentDate.getDate()
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading, sizeVariant: "small", colorVariant: "primary", fullWidth: true }) })
  ] }) });
};
