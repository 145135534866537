"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SentimentsOptions
} from "@/v2/feature/growth/growth-scale/growth-scale.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const GrowthScalePointFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  growthScalePoint,
  growthScale,
  addPoint
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    GrowthScaleFormContent,
    {
      onClose,
      growthScalePoint,
      growthScale,
      addPoint
    }
  ) });
};
const GrowthScaleFormContent = ({ onClose, growthScalePoint, growthScale, addPoint }) => {
  const { polyglot } = usePolyglot();
  const modalFormik = useFormik({
    initialValues: growthScale && growthScalePoint && Object.keys(growthScale.points).length > 0 ? {
      points: growthScale == null ? void 0 : growthScale.points[growthScalePoint],
      value: growthScale == null ? void 0 : growthScale.value[growthScalePoint],
      sentiments: growthScale == null ? void 0 : growthScale.sentiments[growthScalePoint]
    } : {
      points: "",
      value: "",
      sentiments: ""
    },
    validationSchema: yup.object({
      points: yup.string().required("Point is required"),
      value: yup.number().required("Value is required"),
      sentiments: yup.string().required("Sentiment is required")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      addPoint(values);
      onClose();
    })
  });
  if (!growthScalePoint) return null;
  return /* @__PURE__ */ jsx(FormikProvider, { value: modalFormik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, onSubmit: modalFormik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Scale point" }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "points",
        label: "Label",
        value: modalFormik.values.points,
        onChange: modalFormik.handleChange,
        error: modalFormik.touched.points && !!modalFormik.errors.points,
        helperText: modalFormik.touched.points && modalFormik.errors.points
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "value",
        label: "Value",
        type: "number",
        value: modalFormik.values.value,
        onChange: modalFormik.handleChange,
        error: modalFormik.touched.value && !!modalFormik.errors.value,
        helperText: modalFormik.touched.value && modalFormik.errors.value
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "sentiments",
        label: "Sentiment",
        options: SentimentsOptions,
        value: modalFormik.values.sentiments,
        onChange: modalFormik.handleChange,
        error: modalFormik.touched.sentiments && !!modalFormik.errors.sentiments,
        helperText: modalFormik.touched.sentiments && modalFormik.errors.sentiments
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: !modalFormik.isValid || modalFormik.isSubmitting,
        name: polyglot.t("General.save"),
        loading: modalFormik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
