"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { AttendanceReportResults } from "@v2/feature/reports/features/create-report/attendance/attendance-report-results.component";
import { DevicesReportResults } from "@v2/feature/reports/features/create-report/devices/devices-report-results.component";
import { PayrollReportType } from "@v2/feature/reports/features/create-report/payroll/payroll-report-type.component";
import { PeopleReportResults } from "@v2/feature/reports/features/create-report/people/people-report-results.component";
import { TimeReportResults } from "@v2/feature/reports/features/create-report/time/time-report-results.component";
import { DataDomain } from "@v2/feature/reports/reports.interface";
export const ReportResults = (props) => {
  return /* @__PURE__ */ jsx(Fragment, { children: {
    [DataDomain.Devices]: () => /* @__PURE__ */ jsx(DevicesReportResults, { formData: props.formData, setFormData: props.setFormData }),
    [DataDomain.Payroll]: () => /* @__PURE__ */ jsx(PayrollReportType, {}),
    [DataDomain.People]: () => /* @__PURE__ */ jsx(PeopleReportResults, { formData: props.formData, setFormData: props.setFormData }),
    [DataDomain.TimeAway]: () => /* @__PURE__ */ jsx(TimeReportResults, { formData: props.formData, setFormData: props.setFormData }),
    [DataDomain.Attendance]: () => /* @__PURE__ */ jsx(AttendanceReportResults, { formData: props.formData, setFormData: props.setFormData })
  }[props.formData.domain]() });
};
