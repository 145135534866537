"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE } from "@/lib/routes";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DraftUKPayrunTable } from "@/v2/feature/payroll/components/draft-payrun-table.component";
import { PayrunListSideMenu } from "@/v2/feature/payroll/components/payrun-list-side-menu.component";
import { UpcomingPayrunTable } from "@/v2/feature/payroll/components/upcoming-payrun-table.component";
import { formatPayrunPeriod } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { PayrunStatus } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component";
import { UKPayrunSummaryTable } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-summary-table.component";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { PayrollLocalEndpoints } from "@/v2/feature/payroll/payroll-local.api";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
const PAYRUN_END_OF_SCHEDULE_ERROR = "for this year have been completed";
export const UKPayrollPayruns = ({ payroll, refreshPayroll }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const payrollId = Number(useParams().payrollId);
  const loc = useLocation();
  const initialPayrunId = (_a = loc.state) == null ? void 0 : _a.payrunId;
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [selectedPayrunId, setSelectedPayrunId] = useState();
  const [loadingPayrun, setLoadingPayrun] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  useEffect(() => {
    if (typeof selectedPayrunId !== "undefined") return;
    if (typeof initialPayrunId === "number" && payroll.payruns.some((p) => p.id === initialPayrunId)) {
      setSelectedPayrunId(initialPayrunId);
      return;
    }
    if (payroll.nextPayRun && !payroll.currentPayRun) {
      setSelectedPayrunId("next-payrun");
      return;
    }
    const openPayrun = payroll.payruns.find((payrun2) => payrun2.state === PayrunStates.draft);
    if (openPayrun) {
      setSelectedPayrunId(openPayrun.id);
      return;
    }
  }, [initialPayrunId, payroll, selectedPayrunId]);
  const selectedPayrun = useMemo(() => {
    var _a2;
    return (_a2 = payroll == null ? void 0 : payroll.payruns.find(({ id }) => id === selectedPayrunId)) != null ? _a2 : null;
  }, [payroll == null ? void 0 : payroll.payruns, selectedPayrunId]);
  const { data: payrunDetails, mutate: refreshPayrun } = useApiClient(
    selectedPayrun && PayrollLocalEndpoints.getPayrunDetails(
      payrollId,
      selectedPayrun.taxYear,
      selectedPayrun.payPeriod,
      selectedPayrun.period
    ),
    { suspense: false }
  );
  const showPayrun = useCallback(
    (_0, _1, _2) => __async(void 0, [_0, _1, _2], function* (payrollId2, { taxYear, payPeriod, period }, mode) {
      let payrunEndOfScheduleError = false;
      setLoadingPayrun(true);
      if (mode === "create") {
        yield PayrollExternalApi.createPayRun(payrollId2, taxYear, payPeriod).catch((error) => {
          var _a2, _b;
          setLoadingPayrun(false);
          if ((_b = (_a2 = error == null ? void 0 : error.response) == null ? void 0 : _a2.data) == null ? void 0 : _b.message.includes(PAYRUN_END_OF_SCHEDULE_ERROR)) {
            payrunEndOfScheduleError = true;
            showMessage(
              polyglot.t("CompanyPayroll.payrunEndOfSchedule", { currentYear: "2024", nextYear: "2025" }),
              "info"
            );
          } else {
            showMessage(nestErrorMessage(error), "error");
          }
        }).then(() => {
          if (!payrunEndOfScheduleError) {
            routerHistory.push(
              generatePath(PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE, {
                payrollId: payrollId2,
                taxYear,
                payPeriod,
                periodNumber: period
              })
            );
          }
        });
      } else {
        routerHistory.push(
          generatePath(PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE, {
            payrollId: payrollId2,
            taxYear,
            payPeriod,
            periodNumber: period
          })
        );
      }
    }),
    [routerHistory, showMessage, polyglot]
  );
  const { payrun, payrunEntries, previousPayrunEntries } = useMemo(() => {
    var _a2;
    if (!selectedPayrunId || !payrunDetails || selectedPayrunId !== ((_a2 = payrunDetails.payrun) == null ? void 0 : _a2.id))
      return {
        payrun: void 0,
        payrunEntries: void 0,
        previousPayrunEntries: void 0
      };
    const { payrun: payrun2, payrunEntries: payrunEntries2, previousPayrunEntries: previousPayrunEntries2 } = payrunDetails != null ? payrunDetails : {};
    return { payrun: payrun2, payrunEntries: payrunEntries2, previousPayrunEntries: previousPayrunEntries2 };
  }, [payrunDetails, selectedPayrunId]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", flex: 1, gap: spacing.g20 }, children: [
    /* @__PURE__ */ jsx(
      PayrunListSideMenu,
      {
        payroll,
        selectedPayrunId,
        onPayrunSelected: (payrunId) => setSelectedPayrunId(payrunId),
        disabled: loadingPayrun,
        sx: { ml: "20px", height: "100vh" }
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, gap: spacing.g20, height: "100vh" }, children: [
      payroll.nextPayRun && selectedPayrunId === "next-payrun" && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between", mr: "20px" }, children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20, gap: spacing.g2 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title2", children: formatPayrunPeriod(payroll.nextPayRun, polyglot.locale()) }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("CompanyPayroll.upcoming") })
          ] }),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              loading: loadingPayrun,
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => payroll.nextPayRun && showPayrun(payroll.id, payroll.nextPayRun, "create"),
              children: polyglot.t("CompanyPayroll.startPayrun")
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          UpcomingPayrunTable,
          {
            disabled: loadingPayrun,
            payroll,
            nextPayrun: payroll.nextPayRun,
            searchQuery,
            setSearchQuery,
            refreshPayroll: () => __async(void 0, null, function* () {
              yield Promise.all([refreshPayroll == null ? void 0 : refreshPayroll(), refreshPayrun == null ? void 0 : refreshPayrun()]);
            }),
            selectedUsers: selectedUserIds,
            setSelectedUsers: (users) => setSelectedUserIds(users),
            noCurrencySymbol: false,
            sx: { display: "flex", flexFlow: "column", overflow: "hidden", mr: spacing.mr20 },
            stickyHeader: true
          }
        )
      ] }),
      payrun && payrunEntries && previousPayrunEntries && /* @__PURE__ */ jsxs(Fragment, { children: [
        payrun.state === PayrunStates.draft && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between", mr: "20px" }, children: [
            /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20, gap: spacing.g2 }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title2", children: formatPayrunPeriod(payrun, polyglot.locale()) }),
              /* @__PURE__ */ jsx(PayrunStatus, { state: payrun.state })
            ] }),
            /* @__PURE__ */ jsx(
              LoaderButton,
              {
                loading: loadingPayrun,
                sizeVariant: "small",
                colorVariant: "primary",
                onClick: () => showPayrun(payroll.id, payrun, "view"),
                children: polyglot.t("CompanyPayroll.continuePayrun")
              }
            )
          ] }),
          /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
          /* @__PURE__ */ jsx(
            DraftUKPayrunTable,
            {
              payroll,
              localPayRun: payrun,
              entries: payrunEntries,
              previousEntries: previousPayrunEntries,
              searchQuery,
              refreshPayroll: () => __async(void 0, null, function* () {
                yield Promise.all([refreshPayroll == null ? void 0 : refreshPayroll(), refreshPayrun == null ? void 0 : refreshPayrun()]);
              }),
              sx: { display: "flex", flexFlow: "column", overflow: "hidden", mr: spacing.mr20 },
              stickyHeader: true
            }
          )
        ] }),
        payrun.state !== PayrunStates.draft && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between", mr: "20px" }, children: [
            /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20, gap: spacing.g2 }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title2", children: formatPayrunPeriod(payrun, polyglot.locale()) }),
              /* @__PURE__ */ jsx(PayrunStatus, { state: payrun.state })
            ] }),
            /* @__PURE__ */ jsx(
              LoaderButton,
              {
                loading: loadingPayrun,
                sizeVariant: "small",
                colorVariant: "primary",
                onClick: () => showPayrun(payroll.id, payrun, "view"),
                children: polyglot.t("CompanyPayroll.viewPayrun")
              }
            )
          ] }),
          /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
          /* @__PURE__ */ jsx(
            UKPayrunSummaryTable,
            {
              localPayRun: payrun,
              entries: payrunEntries,
              previousEntries: previousPayrunEntries,
              searchQuery,
              sx: { display: "flex", flexFlow: "column", overflow: "hidden", mr: spacing.mr20 },
              stickyHeader: true
            }
          )
        ] })
      ] })
    ] })
  ] }) });
};
