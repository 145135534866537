"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { getUKTaxYear } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StarterDeclarationChoice = ({ declaration, setDeclaration, disabled }) => {
  const { polyglot } = usePolyglot();
  const taxYear = useMemo(() => getUKTaxYear().start.getFullYear(), []);
  return /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.whichSituationsAppliesToYou") }),
    /* @__PURE__ */ jsx(FormControl, { sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        onChange: (event) => {
          setDeclaration(event.target.value);
        },
        value: declaration || "",
        sx: { gap: spacing.g20 },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "A",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("OnboardingByUser.thisIsYourFirstJob", { taxYearStartDate: `6 April ${taxYear}` }) }),
              disabled,
              sx: { alignItems: "start" }
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "B",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("OnboardingByUser.thisIsYourOnlyJob", { taxYearStartDate: `6 April ${taxYear}` }) }),
              disabled,
              sx: { alignItems: "start" }
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "C",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("OnboardingByUser.youGotAnotherJob") }),
              disabled,
              sx: { alignItems: "start" }
            }
          )
        ]
      }
    ) })
  ] });
};
