"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { CopyableTextField } from "@/v2/components/forms/copyable-text-field.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const APPS_NEEDING_COMPANY_ID_FOR_WEBHOOK_CONFIG = ["teamtailor"];
export const AppAutomaticOnboardingViaWebhook = ({ appDetails, companyId }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "550px", mt: spacing.m60 }, children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Webhook" }) }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: `Create a Webhook for ${appDetails == null ? void 0 : appDetails.name} to automatically onboard new hires in Zelt` }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", mt: spacing.m10 }, children: [
      appDetails && appDetails.credentialsPlaceholders && "webhookEndpoint" in (appDetails == null ? void 0 : appDetails.credentialsPlaceholders) && /* @__PURE__ */ jsx(
        CopyableTextField,
        {
          label: "Endpoint URL",
          value: String(appDetails.credentialsPlaceholders["webhookEndpoint"])
        }
      ),
      appDetails && appDetails.credentialsPlaceholders && "apikey" in (appDetails == null ? void 0 : appDetails.credentialsPlaceholders) && /* @__PURE__ */ jsx(CopyableTextField, { label: "Secret Key", value: String(appDetails.credentialsPlaceholders["apikey"]), mask: true }),
      appDetails && APPS_NEEDING_COMPANY_ID_FOR_WEBHOOK_CONFIG.includes(appDetails.stub) && /* @__PURE__ */ jsx(CopyableTextField, { label: "Company ID", value: String(companyId) })
    ] })
  ] });
};
