"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { capitalize, keyBy } from "lodash";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
const getSelectedRule = (ruleString) => {
  if (!ruleString) return "";
  return ruleString.split("=")[0];
};
const getCustomRule = (ruleString) => {
  if (!ruleString) return "";
  return ruleString;
};
const getSelectedOption = (ruleString, sites, departments, entities) => {
  if (!ruleString || !sites || sites && sites.length === 0 || !departments || departments && departments.length === 0 || !entities || entities && entities.length === 0)
    return [];
  const [type, idsString] = ruleString.split("=");
  if (!type || !idsString) return [];
  const ids = idsString.split(",").map((id) => Number(id));
  let options = [];
  const siteLookup = keyBy(sites, "id");
  const departmentsLookup = keyBy(departments, "id");
  const entitiesLookup = keyBy(entities, "id");
  switch (type) {
    case "site":
      options = ids.map((id) => {
        const site = siteLookup[id];
        return {
          value: id,
          label: site ? site.name : `Site ${id}`
        };
      });
      break;
    case "department":
      options = ids.map((id) => {
        const department = departmentsLookup[id];
        return {
          value: id,
          label: department ? department.name : `Department ${id}`
        };
      });
      break;
    case "entity":
      options = ids.map((id) => {
        const entity = entitiesLookup[id];
        return {
          value: id,
          label: entity ? entity.legalName : `Entity ${id}`
        };
      });
      break;
    default:
      options = [];
      break;
  }
  return options;
};
export const CustomUserModal = React.memo(
  ({
    isCustomOpen,
    setCustomIsOpen,
    label,
    onChange,
    customRuleOptions,
    onClose,
    setCustomRuleTypeString,
    ruleString,
    sites,
    departments,
    entities,
    shouldSelectUsers = false
  }) => {
    const { polyglot } = usePolyglot();
    const [selectedValue, setSelectedValue] = useState(getSelectedRule(ruleString));
    const [customRule, setCustomRule] = useState(getCustomRule(ruleString));
    const [selectedOption, setSelectedOption] = useState(
      getSelectedOption(ruleString, sites, departments, entities)
    );
    const { nonTerminatedCachedUsers } = useCachedUsers();
    const filteredUsers = useMemo(() => {
      let filteredUsersList = nonTerminatedCachedUsers;
      if (selectedValue === "site") {
        filteredUsersList = filteredUsersList.filter(
          (u) => {
            var _a, _b;
            return ((_b = (_a = u.role) == null ? void 0 : _a.site) == null ? void 0 : _b.id) && selectedOption.some((o) => {
              var _a2, _b2;
              return o.value === ((_b2 = (_a2 = u.role) == null ? void 0 : _a2.site) == null ? void 0 : _b2.id);
            });
          }
        );
      }
      if (selectedValue === "department") {
        filteredUsersList = filteredUsersList.filter(
          (u) => {
            var _a, _b;
            return ((_b = (_a = u.role) == null ? void 0 : _a.department) == null ? void 0 : _b.id) && selectedOption.some((o) => {
              var _a2, _b2;
              return o.value === ((_b2 = (_a2 = u.role) == null ? void 0 : _a2.department) == null ? void 0 : _b2.id);
            });
          }
        );
      }
      if (selectedValue === "entity") {
        filteredUsersList = filteredUsersList.filter(
          (u) => {
            var _a;
            return ((_a = u.userContract) == null ? void 0 : _a.entityId) && selectedOption.some((o) => {
              var _a2;
              return o.value === ((_a2 = u.userContract) == null ? void 0 : _a2.entityId);
            });
          }
        );
      }
      return filteredUsersList;
    }, [selectedValue, nonTerminatedCachedUsers, selectedOption]);
    const options = useMemo(() => {
      if (selectedValue === "department") {
        return departments.map((d) => ({ label: polyglot.t(d.name), value: d.id }));
      }
      if (selectedValue === "site") {
        return sites.map((s) => ({ label: polyglot.t(s.name), value: s.id }));
      }
      if (selectedValue === "entity") {
        return entities.map((e) => ({ label: polyglot.t(e.legalName), value: e.id }));
      }
      return [];
    }, [polyglot, departments, entities, selectedValue, sites]);
    return /* @__PURE__ */ jsx(DrawerModal, { isOpen: isCustomOpen, setIsOpen: setCustomIsOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: label != null ? label : polyglot.t("General.users") }),
      /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m30 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CustomUserModal.rule") }) }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g30 }, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            label: polyglot.t("CustomUserModal.condition"),
            name: "condition",
            options: customRuleOptions,
            value: selectedValue,
            onChange: (e) => {
              setSelectedValue(e.target.value);
              setSelectedOption([]);
            }
          }
        ),
        selectedValue && /* @__PURE__ */ jsx(
          AutocompleteComponent,
          {
            multiple: true,
            label: capitalize(selectedValue),
            disableCloseOnSelect: true,
            name: selectedValue,
            filterSelectedOptions: true,
            value: selectedOption,
            compareValue: selectedOption,
            options: options != null ? options : [],
            onChange: (_, e) => {
              const value = e;
              setSelectedOption(value);
              setCustomRule(`${selectedValue}=${value.map((v) => v.value).join(",")}`);
              setCustomRuleTypeString(`${selectedValue} ${value.map((v) => v.label).join(", ") || ""}`);
            },
            isOptionEqualToValue: (x, y) => x.value === y.value,
            renderTags: (options2) => {
              return options2.map((o) => o.label).join(", ");
            }
          }
        ),
        selectedValue && selectedOption && /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("CustomUserModal.selectedFrom", {
          source: capitalize(selectedValue),
          source2: selectedOption.map((o) => o.label).join(", ") || ""
        }) })
      ] }),
      shouldSelectUsers && selectedValue && selectedOption && filteredUsers.length === 0 && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "RedDark", sx: { mt: spacing.m10 }, children: polyglot.t("CustomUserModal.noMatch") }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", gap: spacing.g10 }, spacing.mt40), children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            fullWidth: true,
            onClick: () => setCustomIsOpen(false),
            children: polyglot.t("General.cancel")
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true,
            onClick: () => {
              onChange(
                filteredUsers.map((u) => u.userId),
                customRule
              );
              setCustomIsOpen(false);
            },
            disabled: shouldSelectUsers && filteredUsers.length === 0,
            children: polyglot.t("General.save")
          }
        )
      ] })
    ] }) });
  }
);
