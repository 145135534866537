"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { InsuranceSetupStepper } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-stepper.component";
import { PensionSetupPage } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup.page";
import { CustomBenefitCategory } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { InsuranceType } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { SETTINGS_BENEFITS_ROUTE, SETTINGS_CREATE_NEW_BENEFIT_ROUTE } from "@/lib/routes";
export const BenefitsCreateRouter = ({
  pensionSchemes,
  refreshInsuranceQuote,
  refreshPensionSchemes,
  companyConfig
}) => {
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const context = getScopesContext({ userId: currentUser.userId });
  const { category, name } = useParams();
  const isCustomType = Object.values(CustomBenefitCategory).includes(category);
  const isPension = category === "Pension";
  const isInsurance = category === InsuranceType.Health;
  const allowAccessToSetupFlow = (isPension || isInsurance || isCustomType) && (!isPension || companyConfig.inPayroll);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    allowAccessToSetupFlow && /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: isPension ? ["pension:all"] : ["insurance:all"],
        path: SETTINGS_CREATE_NEW_BENEFIT_ROUTE,
        children: isPension ? /* @__PURE__ */ jsx(
          PensionSetupPage,
          {
            name,
            noOfActivePensionSchemes: pensionSchemes.length,
            refresh: refreshPensionSchemes
          }
        ) : isInsurance ? /* @__PURE__ */ jsx(InsuranceSetupStepper, { name, refresh: refreshInsuranceQuote }) : isCustomType ? /* @__PURE__ */ jsx(Fragment, { children: null }) : null
      }
    ),
    /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_BENEFITS_ROUTE })
  ] });
};
