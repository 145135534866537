"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ListView } from "@/v2/components/list-view/list-view.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const AttendanceWeeklyListSection = ({
  searchInput,
  setSearchInput,
  setPagination,
  pagination,
  totalPages,
  onPageChange,
  loading,
  weekList,
  handleWeekClick,
  selectedWeekAndYear,
  totalItems
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: spacing.pt20, children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, spacing.px20), { pb: spacing.p10 }), children: /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        style: { width: "90%" },
        handleChange: (e) => {
          var _a, _b;
          setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
          if (pagination && setPagination) setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
        }
      }
    ) }),
    /* @__PURE__ */ jsx(
      ListView,
      {
        list: weekList,
        pagination: pagination && onPageChange ? {
          pageSize: pagination.pageSize,
          pageIndex: pagination.pageIndex,
          total: totalPages != null ? totalPages : 1,
          totalItems,
          onPageChange: (pageIndex, pageSize) => onPageChange(pageIndex, pageSize)
        } : void 0,
        clickAction: (weekNo) => handleWeekClick(weekNo),
        loading,
        hideCicularLoader: true,
        selected: selectedWeekAndYear
      }
    )
  ] });
};
