"use strict";
export var TransactionType = /* @__PURE__ */ ((TransactionType2) => {
  TransactionType2["DomesticSinglePayment"] = "DOMESTIC_SINGLE_PAYMENT";
  TransactionType2["BulkPayment"] = "BULK_PAYMENT";
  TransactionType2["SinglePaymentSortCode"] = "SINGLE_PAYMENT_SORTCODE";
  TransactionType2["InternationalSinglePayment"] = "INTERNATIONAL_SINGLE_PAYMENT";
  TransactionType2["DomesticScheduledPayment"] = "DOMESTIC_SCHEDULED_PAYMENT";
  TransactionType2["DomesticPeriodicPayment"] = "DOMESTIC_PERIODIC_PAYMENT";
  return TransactionType2;
})(TransactionType || {});
export var TransactionStatus = /* @__PURE__ */ ((TransactionStatus2) => {
  TransactionStatus2["COMPLETED"] = "COMPLETED";
  TransactionStatus2["FAILED"] = "FAILED";
  TransactionStatus2["PENDING"] = "PENDING";
  return TransactionStatus2;
})(TransactionStatus || {});
export var PaymentCategory = /* @__PURE__ */ ((PaymentCategory2) => {
  PaymentCategory2["Payroll"] = "Payroll";
  PaymentCategory2["Expense"] = "Expense";
  PaymentCategory2["Invoice"] = "Invoice";
  return PaymentCategory2;
})(PaymentCategory || {});
export var PaymentStatus = /* @__PURE__ */ ((PaymentStatus2) => {
  PaymentStatus2["All"] = "All";
  PaymentStatus2["Paid"] = "Paid";
  PaymentStatus2["MarkPaid"] = "Mark Paid";
  PaymentStatus2["Failed"] = "Failed";
  PaymentStatus2["Pending"] = "Pending";
  PaymentStatus2["NotPaid"] = "Not Paid";
  PaymentStatus2["Processing"] = "Processing";
  PaymentStatus2["Outstanding"] = "Outstanding";
  PaymentStatus2["Unknown"] = "Unknown";
  return PaymentStatus2;
})(PaymentStatus || {});
export var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Employee"] = "Employee";
  PaymentType2["Taxes"] = "Taxes";
  PaymentType2["Pension"] = "Pension";
  PaymentType2["Expense"] = "Expense";
  PaymentType2["Invoice"] = "Invoice";
  return PaymentType2;
})(PaymentType || {});
export var PaymentCategoryType = /* @__PURE__ */ ((PaymentCategoryType2) => {
  PaymentCategoryType2["EmployeeExpense"] = "Employee expense";
  PaymentCategoryType2["EmployeeInvoice"] = "Employee invoice";
  PaymentCategoryType2["CompanyTaxes"] = "Company taxes";
  return PaymentCategoryType2;
})(PaymentCategoryType || {});
