"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { RichTextField } from "@v2/components/forms/rich-text/rich-text-field.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const DeviceNotesOverviewModal = ({
  isOpen,
  setIsOpen,
  onClose,
  selectedNote,
  devicePossession
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(OverviewNotesContent, { onClose, selectedNote, devicePossession }) });
};
const useCommentForm = (selectedNote, devicePossession, onClose) => {
  const [showMessage] = useMessage();
  return useFormik({
    initialValues: {
      notes: selectedNote ? selectedNote : ""
    },
    validationSchema: yup.object({
      notes: yup.string().notRequired()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        yield DeviceAPI.updateDeviceCustomerNotes(devicePossession.deviceId, {
          customerNotes: values.notes
        });
        showMessage("Successfully update the notes", "success");
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
};
const OverviewNotesContent = ({
  onClose,
  selectedNote,
  devicePossession
}) => {
  const { polyglot } = usePolyglot();
  const formik = useCommentForm(selectedNote, devicePossession, onClose);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Add note" }),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: "Notes text",
        value: formik.values.notes,
        onChange: (value) => formik.setFieldValue("notes", value)
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
