"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { flow } from "fp-ts/lib/function";
import * as RR from "fp-ts/lib/ReadonlyRecord";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const definitionListSx = { display: "flex", flexDirection: "column", margin: 0, padding: 0 };
export const definitionSx = {
  display: "flex",
  marginBottom: spacing.m10,
  width: "100%",
  gap: spacing.g10
};
export const definitionTermSx = __spreadValues({
  maxWidth: { xs: "120px", sm: "120px", md: "190px", lg: "190px" },
  width: "100%"
}, themeFonts.caption);
export const definitionValueSx = __spreadValues({
  maxWidth: { xs: "200px", sm: "350px", md: "400px", lg: "400px" },
  width: "100%"
}, themeFonts.title4);
export const cardSx = __spreadValues({
  maxWidth: { xs: "350px", sm: "350px", md: "600px", lg: "600px" }
}, spacing.pb40);
export const cardMaxWidth = {
  maxWidth: { xs: "350px", sm: "350px", md: "600px", lg: "600px" }
};
export const tablecardSx = __spreadValues({}, spacing.pb40);
export const showTableSx = { marginTop: spacing.m15 };
export const cardHeaderSx = __spreadProps(__spreadValues({
  alignItems: "center",
  display: "flex"
}, spacing.pb20), {
  justifyContent: "space-between"
});
export const fieldSx = {
  minHeight: 65
};
export const conditionalSx = { mb: spacing.m35 };
export const dateShortcutSx = { minHeight: "110px", mb: spacing.m10 };
export const nonLabelfieldSx = { minHeight: 65 };
export const drawerContentSx = {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  height: "100%"
};
export const buttonBoxSx = {
  display: "flex",
  alignItems: "center",
  gap: spacing.m5,
  width: "100%",
  mt: spacing.m25
};
export const buttonSettingSx = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: spacing.m10
};
export const titleSx = __spreadProps(__spreadValues({}, themeFonts.title2), { mb: "20px", color: themeColors.DarkGrey });
export const editDeleteHeaderSx = { mb: "20px" };
export const calculateSkeletonHeight = flow(
  (xs) => xs.fields,
  RR.keys,
  (x) => `${4 + x.length * 2}rem`
);
export const hazardSize = { width: 10, height: 10 };
