"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { BENEFITS_COMPANY_ROUTE, DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE } from "@/lib/routes";
import { CustomBenefitEmployeeList } from "@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit-employee-list.page";
export const CustomBenefitCompanyRouter = ({
  loading,
  customBenefits
}) => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(state.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance:all"],
        context: scopesContext,
        path: DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE,
        exact: true,
        children: /* @__PURE__ */ jsx(CustomBenefitEmployeeList, { loading, customBenefits })
      }
    ),
    /* @__PURE__ */ jsx(Route, { path: BENEFITS_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: BENEFITS_COMPANY_ROUTE }) })
  ] });
};
