"use strict";
import { useState, useMemo } from "react";
export function useArray(values) {
  const [array, setArray] = useState([...values]);
  const stateArray = useMemo(() => {
    return {
      [Symbol("array")]: array,
      at(idx) {
        return array[idx];
      },
      clear() {
        setArray([]);
      },
      delete(...values2) {
        const valuesToDelete = new Set(values2);
        setArray(array.filter((item) => !valuesToDelete.has(item)));
      },
      includes(value) {
        return array.includes(value);
      },
      push(...items) {
        const newArray = [...array, ...items];
        setArray(newArray);
        return newArray.length;
      },
      splice(start, deleteCount, ...items) {
        const newArray = [...array];
        const deleted = newArray.splice(start, deleteCount, ...items);
        setArray(newArray);
        return deleted;
      },
      get forEach() {
        return array.forEach.bind(array);
      },
      get map() {
        return array.map.bind(array);
      },
      get some() {
        return array.some.bind(array);
      },
      get every() {
        return array.every.bind(array);
      },
      length: array.length,
      toArray() {
        return [...array];
      },
      update(value, add) {
        if (add === true || add === "add") {
          this.push(value);
        } else {
          this.delete(value);
        }
      },
      get [Symbol.iterator]() {
        return array[Symbol.iterator].bind(array);
      }
    };
  }, [array]);
  return stateArray;
}
