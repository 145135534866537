"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const UserPersonalSettingsSchema = Yup.object().shape({
  showBirthday: Yup.boolean().required("Required field")
});
export const UserPrivacySettingDrawer = ({ isOpen, setIsOpen, settings, refreshSettings }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(UserPrivacySettingContent, { settings, setIsOpen, refreshSettings }) });
};
const UserPrivacySettingContent = ({ setIsOpen, settings, refreshSettings }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: { showBirthday: settings ? settings.showBirthday : true },
    enableReinitialize: true,
    validationSchema: UserPersonalSettingsSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield UserAPI.patchUserPersonalSettings(values);
        showMessage(polyglot.t("UserPrivacySettingContent.successMessages.update"), "success");
        yield refreshSettings == null ? void 0 : refreshSettings();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("UserPrivacySettingContent.errorMessages.encounter", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserPrivacySettingContent.privacysettings") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: polyglot.t("UserPrivacySettingContent.description") })
    ] }),
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        "aria-labelledby": "show-birthday",
        name: "show-birthday",
        onChange: (event) => {
          formik.setFieldValue("showBirthday", event.target.value === "true");
        },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: false,
              checked: !formik.values.showBirthday,
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("UserPrivacySettingContent.hidemybirthdayfromcalendar") }) })
            },
            "false"
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: true,
              checked: formik.values.showBirthday,
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("UserPrivacySettingContent.showmybirthdayincalendar") }) }),
              sx: { my: spacing.m10 }
            },
            "true"
          )
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
