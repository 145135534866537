"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Popover } from "@mui/material";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { DashboardAPI } from "@/v2/feature/dashboard/dashboard.api";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GifSelector = ({
  type,
  setSelectedGIF
}) => {
  const [gifs, setGifs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleSearch = () => __async(void 0, null, function* () {
    const results = yield DashboardAPI.getGIFS(type);
    setGifs(results);
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : void 0;
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx(
      Button,
      {
        sx: __spreadValues({}, secondaryCTABtn),
        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
        onClick: (event) => {
          handleClick(event);
          handleSearch();
        },
        children: "Add GIF"
      }
    ),
    /* @__PURE__ */ jsx(
      Popover,
      {
        id,
        open,
        anchorEl,
        onClose: handleClose,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        PaperProps: {
          sx: {
            boxShadow: "0px 2px 20px 0px #0D0D0E1A",
            border: "0px solid #e6eaed",
            width: "25%"
          }
        },
        children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5, maxHeight: "200px", overflowY: "auto" }, children: /* @__PURE__ */ jsx("div", { style: { width: "100%", justifyContent: "center", display: "flex", flexWrap: "wrap", gap: spacing.g5 }, children: gifs.map((gif, idx) => /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
          "img",
          {
            src: gif,
            alt: "GIF",
            width: "100px",
            height: "100px",
            onClick: () => {
              setSelectedGIF(gif);
            },
            style: { borderRadius: radius.br10 }
          },
          idx
        ) })) }) })
      }
    )
  ] });
};
