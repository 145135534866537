"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { getScheduleGeneralValidationSchema } from "@v2/feature/attendance/attendance.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
export const ScheduleGeneralForm = ({
  attendanceSchedule,
  onSubmit,
  buttonName,
  loading
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      name: attendanceSchedule.name
    },
    validationSchema: getScheduleGeneralValidationSchema(polyglot),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("ScheduleGeneralForm.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " "
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: buttonName, loading, fullWidth: true, sizeVariant: "medium", colorVariant: "primary" }) })
  ] }) });
};
