"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserLifecycleStatuses } from "@/models/user-role.model";
import { UserAPI } from "@/v2/feature/user/user.api";
import { ACTIVE_INVITABLE_USER_ACCOUNT_STATUS_FOR_BILLING } from "@/v2/feature/user/user.interface";
const CachedUsersContext = createContext({
  loaded: false,
  cachedUsers: [],
  nonTerminatedCachedUsers: [],
  employedUsersCachedUsers: [],
  getCachedUserById: () => void 0,
  getCachedUsersByEmail: () => [],
  getActiveUserCountForBilling: () => void 0,
  filterCachedUsersByStatus: () => [],
  refreshCachedUsers: () => __async(void 0, null, function* () {
  })
});
export const useCachedUsers = (opts) => {
  const hasRefreshed = useRef(false);
  const contextState = useContext(CachedUsersContext);
  if (contextState === null) {
    throw new Error("useCachedUsers must be used within a CachedUsersProvider tag");
  }
  useEffect(() => {
    if (!(opts == null ? void 0 : opts.refresh)) return;
    if (hasRefreshed.current) return;
    hasRefreshed.current = true;
    contextState.refreshCachedUsers();
  }, [contextState, opts == null ? void 0 : opts.refresh]);
  return contextState;
};
export const CachedUsersProvider = ({ children }) => {
  const [cache, setCache] = useState({
    loaded: false,
    users: [],
    nonTerminatedUsers: [],
    employedUsers: [],
    usersById: /* @__PURE__ */ new Map()
  });
  const getCachedUserById = useCallback((userId) => cache.usersById.get(userId), [cache]);
  const getActiveUserCountForBilling = useCallback(
    () => {
      var _a;
      return ((_a = cache.users.filter(
        ({ accountStatus }) => ACTIVE_INVITABLE_USER_ACCOUNT_STATUS_FOR_BILLING.includes(accountStatus)
      )) == null ? void 0 : _a.length) || 0;
    },
    [cache.users]
  );
  const getCachedUsersByEmail = useCallback(
    (emailAddresses) => {
      return cache.users.filter(({ emailAddress }) => {
        return emailAddresses ? emailAddresses.includes(emailAddress) : [];
      });
    },
    [cache]
  );
  const filterUsersByStatus = useCallback(
    (users, { include = [], exclude = [] }) => users.filter(({ userEvent }) => {
      const status = userEvent == null ? void 0 : userEvent.status;
      return include && include.length > 0 ? status && (include == null ? void 0 : include.includes(status)) : exclude && exclude.length > 0 ? !status || !(exclude == null ? void 0 : exclude.includes(status)) : status;
    }),
    []
  );
  const filterCachedUsersByStatus = useCallback((filter) => filterUsersByStatus(cache.users, filter), [
    cache,
    filterUsersByStatus
  ]);
  const refreshCachedUsers = useCallback(
    (useCache = false) => __async(void 0, null, function* () {
      const users = yield UserAPI.fetchUserCache(useCache);
      const ciCollator = new Intl.Collator(void 0, { sensitivity: "base" });
      users.sort((a, b) => ciCollator.compare(a.displayName, b.displayName));
      const usersById = new Map(users.map((u) => [u.userId, u]));
      const nonTerminatedUsers = filterUsersByStatus(users, { exclude: [UserLifecycleStatuses.Terminated] });
      const employedUsers = filterUsersByStatus(users, { include: [UserLifecycleStatuses.Employed] });
      setCache({
        users,
        nonTerminatedUsers,
        employedUsers,
        usersById,
        loaded: true
      });
    }),
    [filterUsersByStatus]
  );
  useEffect(() => {
    refreshCachedUsers(true);
  }, [refreshCachedUsers]);
  return /* @__PURE__ */ jsx(
    CachedUsersContext.Provider,
    {
      value: {
        cachedUsers: cache.users,
        nonTerminatedCachedUsers: cache.nonTerminatedUsers,
        employedUsersCachedUsers: cache.employedUsers,
        loaded: cache.loaded,
        getActiveUserCountForBilling,
        getCachedUserById,
        getCachedUsersByEmail,
        filterCachedUsersByStatus,
        refreshCachedUsers
      },
      children
    }
  );
};
