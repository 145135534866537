"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const settingsMaxWidth = {
  maxWidth: { sm: "300px", md: "600px", lg: "600px" }
};
export const settingHeaderSx = __spreadProps(__spreadValues({
  alignItems: "start",
  display: "flex"
}, spacing.pb20), {
  justifyContent: "space-between",
  gap: spacing.g10
});
export const settingListSx = { display: "flex", flexDirection: "column", margin: 0, padding: 0 };
export const settingSx = {
  display: "flex",
  width: "100%",
  gap: spacing.g10,
  alignItems: "center"
};
export const settingTermSx = __spreadValues({
  maxWidth: { sm: "90px", md: "190px", lg: "190px" },
  width: "100%",
  paddingY: spacing.p8
}, themeFonts.caption);
export const settingValueSx = __spreadProps(__spreadValues({
  display: "flex",
  gap: spacing.g10,
  alignItems: "center",
  maxWidth: { sm: "170px", md: "370px", lg: "370px" },
  width: "100%"
}, themeFonts.caption), {
  paddingY: spacing.p8,
  color: themeColors.Grey
});
export const settingIconSx = {
  maxWidth: { sm: "40px", md: "40px", lg: "40px" },
  width: "100%",
  display: "flex",
  alignItems: "center"
};
