"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useSWRConfig } from "swr";
import { GlobalContext } from "@/GlobalState";
import { performLogout } from "@/v2/feature/auth/auth.util";
export const AuthLogoutPage = () => {
  const routerHistory = useHistory();
  const swrConfig = useSWRConfig();
  const [, dispatch] = useContext(GlobalContext);
  const { shutdown } = useIntercom();
  useEffect(() => {
    performLogout(routerHistory, dispatch, swrConfig, shutdown);
  }, [dispatch, routerHistory, swrConfig, shutdown]);
  return /* @__PURE__ */ jsx(Fragment, {});
};
