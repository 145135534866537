"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack, SwipeableDrawer } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { GlobalPayrollPayCodeEditDrawer } from "@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes-edit-drawer.component";
import { GlobalPayrollPayCodeNewDrawer } from "@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes-new-drawer.component";
import { GlobalPayrollPayCodesTable } from "@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes-table.component";
import { GlobalPayrollEndpoints } from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const GlobalPayrollPayCodes = ({ payroll }) => {
  const { polyglot } = usePolyglot();
  const [searchQuery, setSearchQuery] = useState("");
  const [editingCode, setEditingCode] = useState(null);
  const { data: payrollPaycodes, mutate: refreshPaycodes } = useApiClient(
    GlobalPayrollEndpoints.getPayrollPaycodes(payroll.id),
    {
      suspense: false
    }
  );
  const sortedPaycodes = useMemo(() => {
    return payrollPaycodes == null ? void 0 : payrollPaycodes.paycodes.sort(
      (a, b) => Number(b.required) - Number(a.required) || a.code.localeCompare(b.code)
    );
  }, [payrollPaycodes == null ? void 0 : payrollPaycodes.paycodes]);
  const filteredPayCodes = useMemo(() => {
    if (!sortedPaycodes) return [];
    const locale = polyglot.locale();
    const lowerSearchQuery = searchQuery.trim().toLocaleLowerCase(locale);
    if (!lowerSearchQuery) {
      return sortedPaycodes;
    }
    return sortedPaycodes.filter(
      (p) => p.code.toLocaleLowerCase(locale).includes(lowerSearchQuery) || p.name.toLocaleLowerCase(locale).includes(lowerSearchQuery)
    );
  }, [polyglot, searchQuery, sortedPaycodes]);
  const closePaycodeDrawerAndRefresh = useCallback(() => {
    setEditingCode(null);
    refreshPaycodes == null ? void 0 : refreshPaycodes();
  }, [refreshPaycodes]);
  return /* @__PURE__ */ jsxs(Stack, { sx: { mr: spacing.mr20, mt: spacing.mt20 }, children: [
    /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { children: "Pay codes" }),
    /* @__PURE__ */ jsxs(
      Stack,
      {
        sx: {
          flexFlow: "row",
          justifyContent: "space-between",
          gap: spacing.g10,
          mb: spacing.g20
        },
        children: [
          /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => setEditingCode("new"),
              style: { width: "fit-content" },
              children: polyglot.t("PayrollPaycodeSettings.newCode")
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      GlobalPayrollPayCodesTable,
      {
        payCodes: filteredPayCodes,
        onEditPayCodeClick: (paycode) => setEditingCode(paycode.code)
      }
    ),
    /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open: !!editingCode, onClose: () => setEditingCode(null), onOpen: () => {
    }, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ width: "400px", padding: "20px" }, drawerContentSx), children: [
      editingCode && editingCode !== "new" && /* @__PURE__ */ jsx(
        GlobalPayrollPayCodeEditDrawer,
        {
          payrollId: payroll.id,
          code: editingCode,
          onPaycodeDeleted: () => closePaycodeDrawerAndRefresh(),
          onPaycodeSaved: () => closePaycodeDrawerAndRefresh()
        }
      ),
      editingCode && editingCode === "new" && /* @__PURE__ */ jsx(
        GlobalPayrollPayCodeNewDrawer,
        {
          payrollId: payroll.id,
          onPaycodeSaved: () => closePaycodeDrawerAndRefresh()
        }
      )
    ] }) })
  ] });
};
