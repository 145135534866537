"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { DnUserListingPage } from "@/v2/feature/document-new/features/document-new-user/dn-user-listing.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ReachType, UserType } from "@/v2/interfaces/general.interface";
export const UserProfileNewDocumentPage = ({ userId }) => {
  const { data: sitesForCompany } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departmentsForCompany } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false
  });
  const { data: entitiesForCompany } = useApiClient(CompanyEndpoints.getCompanyEntities(), { suspense: false });
  const customRuleDataset = useMemo(() => {
    return {
      sites: sitesForCompany != null ? sitesForCompany : [],
      departments: departmentsForCompany != null ? departmentsForCompany : [],
      entties: entitiesForCompany != null ? entitiesForCompany : []
    };
  }, [sitesForCompany, departmentsForCompany, entitiesForCompany]);
  return /* @__PURE__ */ jsx(
    DnUserListingPage,
    {
      userId,
      reach: ReachType.Me,
      userReach: UserType.Profile,
      customRuleDataset
    }
  );
};
