"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { getDateFormation } from "@v2/feature/dashboard/dashboard.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ClockIcon } from "@/images/side-bar-icons/TimeAway.svg";
import { ShiftActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/shift-action-item.component";
import { TodoCard } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { iconSize } from "@/v2/styles/menu.styles";
export const UnfinishedAttendanceShiftTodo = ({
  todo,
  setLastChild,
  refreshTodos
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState(void 0);
  return /* @__PURE__ */ jsxs(Box, { children: [
    todo.shifts.map((attendanceShift, idx) => {
      var _a;
      const title = `${(_a = getCachedUserById(attendanceShift.userId)) == null ? void 0 : _a.displayName}: ${getDateFormation(
        attendanceShift.logDate
      )}`;
      return /* @__PURE__ */ jsx(
        TodoCard,
        {
          icon: /* @__PURE__ */ jsx(ClockIcon, __spreadValues({}, iconSize)),
          title: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              sx: {
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%"
              },
              children: polyglot.t("UnfinishedAttendanceShiftTodo.unfinishedFrom", { title })
            }
          ),
          actionOnclick: () => {
            setSelectedRow(attendanceShift);
          },
          showBorder: !(setLastChild && todo.shifts.length === idx + 1),
          userAvatar: /* @__PURE__ */ jsx(UserAvatar, { userId: attendanceShift.userId, size: "xxxsmall" })
        },
        `attendance-${attendanceShift.userId}-${attendanceShift.logDate}`
      );
    }),
    selectedRow && /* @__PURE__ */ jsx(
      ShiftActionItem,
      {
        shiftActionRow: selectedRow,
        refresh: refreshTodos,
        afterClose: () => setSelectedRow(void 0)
      }
    )
  ] });
};
