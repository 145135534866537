"use strict";
import { getDayOfWeekName } from "@/v2/infrastructure/date/date-format.util";
export const ruleForApprovals = (approvalRules, ruleIdForType) => {
  const noneOption = "None";
  if (!approvalRules || !ruleIdForType) return noneOption;
  const matchingRule = approvalRules == null ? void 0 : approvalRules.find((r) => r.id === ruleIdForType);
  return matchingRule ? matchingRule == null ? void 0 : matchingRule.name : noneOption;
};
const ordinalString = (n) => {
  const ordinalSuffixes = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
  return `${n}${ordinalSuffixes[Math.abs(n) % 10]}`;
};
export const formatPayrollDateType = (payPeriod, date, type) => {
  if (!payPeriod || !date || !type) return;
  if (payPeriod === "Weekly") {
    return `${getDayOfWeekName(date)} of each week`;
  }
  return {
    LastDay: "Last day of the month",
    SameDate: `The ${ordinalString(Number(date.slice(8)))} of each month`
  }[type];
};
export const formatTaxYearStart = (value) => {
  return value && (/* @__PURE__ */ new Date(`${value}-2000`)).toLocaleDateString(void 0, {
    month: "long",
    day: "2-digit"
  });
};
