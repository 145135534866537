"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import ReactApexChart from "react-apexcharts";
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import "./apex-charts.styles.css";
export function ChartDonut({ series, labels, percentage = false }) {
  const chartOptions = __spreadValues({
    chart: {
      fontFamily: "Inter, sans-serif !important",
      toolbar: {
        show: false
      },
      offsetY: 5,
      width: "100%"
    },
    dataLabels: {
      enabled: false
    },
    labels,
    stroke: { show: false },
    legend: {
      show: true,
      horizontalAlign: "right",
      position: "right",
      fontFamily: "Inter, sans-serif !important",
      fontWeight: 400,
      fontSize: "12px",
      labels: { colors: [themeColors.DarkGrey] },
      offsetY: -20,
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: void 0,
        radius: 16,
        customHTML: void 0,
        onClick: void 0
      }
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`
      },
      style: { fontFamily: "Inter, sans-serif !important", fontWeight: 400, fontSize: "12px" },
      custom: function({ series: series2, seriesIndex, w }) {
        var _a, _b;
        var data = (_b = (_a = w == null ? void 0 : w.globals) == null ? void 0 : _a.seriesNames[seriesIndex]) != null ? _b : "";
        return `<div class="arrow_box">${series2[seriesIndex]}${percentage ? "%" : ` ${data}`}</div>`;
      }
    },
    plotOptions: { pie: { customScale: 0.9, offsetY: 20, donut: { size: "75%" } } },
    show: true,
    label: "Total",
    colors: [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR]
  }, themeFonts.caption);
  return /* @__PURE__ */ jsx(ReactApexChart, { type: "donut", series, options: chartOptions, height: "250px" });
}
