"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class OauthClientAPI {
  static createNewClient(name, description, redirectionUri, scopes) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/oauth/client", { name, description, redirectionUri, scopes })).data;
    });
  }
  static deleteClient() {
    return __async(this, null, function* () {
      yield axios.delete("/apiv2/oauth/client");
    });
  }
  static deleteSpecificClient(clientId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/oauth/client/${clientId}`);
    });
  }
  static getLogoUploadURL(clientId) {
    return `/apiv2/oauth/client/${clientId}/upload-logo`;
  }
  static deleteClientLogo(clientId, key) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/oauth/client/${clientId}/delete-logo`, { remoteFileName: key });
    });
  }
  static validateAuthorizationAttempt(clientId, state, redirectionUri) {
    return __async(this, null, function* () {
      return yield axios.get(
        `/apiv2/oauth/authorize?response_type=code&client_id=${clientId}&state=${state}&redirect_uri=${redirectionUri}`
      );
    });
  }
  static updateExistingClient(clientId, appId, name, description, redirectionUri, scopes, logo) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/oauth/client/${clientId}`, {
        id: appId,
        name,
        description,
        redirectionUri,
        scopes,
        logo
      })).data;
    });
  }
  static updateScopeAndScopeReasonsForExistingClient(clientId, appId, scopes, scopeReasons) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/oauth/client-scopes/${clientId}`, {
        id: appId,
        scopes,
        scopeReasons
      })).data;
    });
  }
  static submitOAuthClientPublishRequest(clientId, requestedScopes, scopeReasons) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/oauth/publish-requests", { clientId, requestedScopes, scopeReasons });
    });
  }
  static approvePublishRequest(companyId, clientId, reviewNotes) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/oauth/publish-requests/superadmin/${companyId}/${clientId}/approve`, { reviewNotes });
    });
  }
  static rejectPublishRequest(companyId, clientId, reviewNotes) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/oauth/publish-requests/superadmin/${companyId}/${clientId}/reject`, { reviewNotes });
    });
  }
}
export class OauthClientEndpoints {
  static getSpecificClientForCompanyForConsentStart(clientId, redirectionUri) {
    return {
      url: `/apiv2/oauth/client/${clientId}?redirectionUri=${redirectionUri}`
    };
  }
  static getAuthorizationCode() {
    return {
      url: "/apiv2/oauth/clients"
    };
  }
  static getAllClientsForCompany() {
    return {
      url: "/apiv2/oauth/clients"
    };
  }
  static getTokenPairForClientId(clientId) {
    return {
      url: `/apiv2/oauth/client/${clientId}/token-pair`
    };
  }
  static getGroupedStats() {
    return {
      url: "/apiv2/oauth/superadmin/partner-api/grouped-stats"
    };
  }
  static getSpecificAppDetails(appId) {
    return {
      url: `/apiv2/oauth/app/${appId}`
    };
  }
  static getAllTokenPairsForClientId(clientId) {
    return {
      url: `/apiv2/oauth/client/${clientId}/token-pairs-for-client`
    };
  }
  static getPublishRequestsForCompany() {
    return {
      url: "/apiv2/oauth/publish-requests/"
    };
  }
  static getPublishRequestsForClientId(clientId) {
    return {
      url: `/apiv2/oauth/publish-requests/${clientId}`
    };
  }
  static getPublishRequestsForAllCompanies() {
    return {
      url: "/apiv2/oauth/publish-requests/superadmin"
    };
  }
  static getAvailableScopes() {
    return {
      url: "/apiv2/oauth/publish-requests/available-scopes/"
    };
  }
}
