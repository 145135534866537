"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OptionTypeQuestion = ({ setOptions, options, answerOptsError, setAnswerOptsError }) => {
  const { polyglot } = usePolyglot();
  const optionValues = Object.values(options).length > 0 ? Object.values(options) : ["", ""];
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16 }, children: [
    /* @__PURE__ */ jsx(Fragment, { children: optionValues.map((opt, idx) => {
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            value: opt,
            placeholder: polyglot.t("OptionTypeQuestion.pleaseAdd"),
            onChange: (e) => {
              const currentOpt = e.target.value;
              optionValues[idx] = currentOpt;
              setOptions(__spreadValues({}, optionValues));
            },
            clearText: () => {
              optionValues[idx] = "";
              setOptions(__spreadValues({}, optionValues));
            }
          }
        ),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            onClick: () => {
              setOptions(__spreadValues({}, optionValues.filter((_, idy) => idy !== idx)));
            },
            children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
          }
        )
      ] });
    }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "block" }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => {
            setOptions(__spreadValues({}, [...optionValues, ""]));
            setAnswerOptsError(null);
          },
          children: polyglot.t("OptionTypeQuestion.addAnswer")
        }
      ),
      answerOptsError && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.darkRed, mt: spacing.m10 }), children: answerOptsError })
    ] })
  ] });
};
