"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DIRECTORY_ROUTE, DEVICES_ME_DIRECTORY_ROUTE, SETTINGS_BILLING_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DeviceOrderStatus } from "@/v2/feature/device/device.interface";
import {
  FieldValueComponent,
  getDeviceOwnerByDevicePossessionTable,
  getModelImage,
  getOrderStatus,
  getTechSpecs
} from "@/v2/feature/device/device.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceOrderDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  deviceOrder,
  refresh,
  sitesById,
  reach
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const [globalState, dispatch] = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [isDeliveredLoading, setIsDeliveredLoading] = useState(false);
  const { userId } = globalState.user;
  const hasDevicesAllScope = checkScopes(globalState.user, ["devices:all"], { userId });
  const isRequested = DeviceOrderStatus.Requested === (deviceOrder == null ? void 0 : deviceOrder.status);
  const routerHistory = useHistory();
  const yesterday = new LocalDate();
  yesterday.getDate().setDate(yesterday.getDate().getDate() - 1);
  yesterday.getDate().setHours(23);
  const deleteOrder = (orderId) => __async(void 0, null, function* () {
    let orderDeleted = false;
    try {
      setLoading(true);
      yield DeviceAPI.deleteDeviceOrder(orderId);
      orderDeleted = true;
      showMessage("Order deleted", "success");
      yield refresh == null ? void 0 : refresh();
      routerHistory.push(reach === "company" ? DEVICES_COMPANY_DIRECTORY_ROUTE : DEVICES_ME_DIRECTORY_ROUTE);
    } catch (error) {
      if (!orderDeleted) showMessage(`Could not delete order. ${nestErrorMessage(error)}`, "error");
      else showMessage(`Could not refresh orders list. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const approveOrder = (orderId) => __async(void 0, null, function* () {
    let isOrderApprove = false;
    try {
      setLoading(true);
      yield DeviceAPI.approveDeviceOrder(orderId);
      showMessage("Order approved.", "success");
      isOrderApprove = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      yield refresh();
    } catch (error) {
      if (isOrderApprove) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not approve order. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setLoading(false);
    }
  });
  const rejectOrder = (orderId) => __async(void 0, null, function* () {
    let wasOrderRejected = false;
    try {
      setLoading(true);
      yield DeviceAPI.rejectDeviceOrder(orderId);
      showMessage("Order rejected", "success");
      wasOrderRejected = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(globalState.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      yield refresh();
    } catch (error) {
      if (wasOrderRejected) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not reject order. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setLoading(false);
    }
  });
  const cancelOrder = (orderId) => __async(void 0, null, function* () {
    let orderCancelled = false;
    try {
      yield DeviceAPI.cancelDeviceOrder(orderId);
      orderCancelled = true;
      showMessage("Order cancelled", "success");
      yield refresh();
    } catch (error) {
      if (!orderCancelled) showMessage(`Could not cancel order. ${nestErrorMessage(error)}`, "error");
      else showMessage(`Could not refresh orders list. ${nestErrorMessage(error)}`, "error");
    }
  });
  const setOrderAsDelivered = () => __async(void 0, null, function* () {
    const deliveryDate = (deviceOrder == null ? void 0 : deviceOrder.deliveryDate) && yesterday.toDateString() < deviceOrder.deliveryDate ? deviceOrder.deliveryDate : new LocalDate().toDateString();
    try {
      setIsDeliveredLoading(true);
      if (!deliveryDate) {
        showMessage("Please confirm the delivery date", "error");
        return;
      }
      if (!(deviceOrder == null ? void 0 : deviceOrder.deviceId)) {
        showMessage("Could not set the device as delivered as no device is assigned to this order.", "error");
        return;
      }
      yield DeviceAPI.confirmDeviceOrderDelivery(deviceOrder.id, deliveryDate);
      yield refresh();
      showMessage("Order status updated.", "success");
    } catch (error) {
      showMessage(`Could not update device order status. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDeliveredLoading(false);
    }
  });
  const allTechSpecs = (deviceOrder == null ? void 0 : deviceOrder.deviceModel) ? getTechSpecs(deviceOrder.deviceModel) : [];
  const getDomain = (url) => {
    if (url.includes("http")) return url;
    return `https://${url}`;
  };
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_b = (_a = deviceOrder == null ? void 0 : deviceOrder.deviceModel) == null ? void 0 : _a.modelName) != null ? _b : "Order" }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center" }, children: getModelImage((_c = deviceOrder == null ? void 0 : deviceOrder.deviceModel) == null ? void 0 : _c.type, (_d = deviceOrder == null ? void 0 : deviceOrder.deviceModel) == null ? void 0 : _d.modelName, {
      width: "180px",
      height: "auto"
    }) }),
    /* @__PURE__ */ jsxs(Box, { component: "section", sx: drawerContentSx, children: [
      /* @__PURE__ */ jsxs(Box, { component: "dl", sx: { display: "flex", flexDirection: "column", margin: 0, padding: 0, gap: spacing.s2 }, children: [
        (deviceOrder == null ? void 0 : deviceOrder.status) && /* @__PURE__ */ jsx(
          FieldValueComponent,
          {
            title: "Order status",
            value: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getOrderStatus(deviceOrder.status) })
          }
        ),
        (deviceOrder == null ? void 0 : deviceOrder.possession) && /* @__PURE__ */ jsx(
          FieldValueComponent,
          {
            title: "Employee",
            value: getDeviceOwnerByDevicePossessionTable(
              deviceOrder.possession,
              sitesById[deviceOrder.possessionId],
              polyglot,
              (_f = getCachedUserById((_e = deviceOrder == null ? void 0 : deviceOrder.possession) == null ? void 0 : _e.possessionId)) == null ? void 0 : _f.displayName
            )
          }
        ),
        (deviceOrder == null ? void 0 : deviceOrder.price) && deviceOrder.price > 0 && /* @__PURE__ */ jsx(
          FieldValueComponent,
          {
            title: "Price",
            value: /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
              "\xA3",
              (deviceOrder == null ? void 0 : deviceOrder.price).toFixed(2)
            ] })
          }
        ),
        (deviceOrder == null ? void 0 : deviceOrder.contractLength) && /* @__PURE__ */ jsx(
          FieldValueComponent,
          {
            title: "Contract",
            value: /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
              deviceOrder == null ? void 0 : deviceOrder.contractLength,
              " months"
            ] })
          }
        ),
        (deviceOrder == null ? void 0 : deviceOrder.deliveryAddress) && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Address", value: deviceOrder == null ? void 0 : deviceOrder.deliveryAddress }),
        (deviceOrder == null ? void 0 : deviceOrder.trackingLink) && /* @__PURE__ */ jsx(
          FieldValueComponent,
          {
            title: "Tracking link",
            value: /* @__PURE__ */ jsx(
              "a",
              {
                href: getDomain(deviceOrder == null ? void 0 : deviceOrder.trackingLink),
                target: "_blank",
                rel: "noreferrer",
                style: { color: themeColors.DarkGrey },
                children: "Click here"
              }
            )
          }
        ),
        (deviceOrder == null ? void 0 : deviceOrder.deliveryDate) && /* @__PURE__ */ jsx(
          FieldValueComponent,
          {
            title: "Delivery date",
            value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDateString(deviceOrder == null ? void 0 : deviceOrder.deliveryDate) })
          }
        ),
        (deviceOrder == null ? void 0 : deviceOrder.deviceModel) && allTechSpecs.length > 0 && /* @__PURE__ */ jsx(
          FieldValueComponent,
          {
            title: "Tech specs",
            value: /* @__PURE__ */ jsx(Box, { children: allTechSpecs.map((t, index) => /* @__PURE__ */ jsx(Typography, { variant: "title4", children: t }, index)) })
          }
        )
      ] }),
      deviceOrder && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        [DeviceOrderStatus.Cancelled, DeviceOrderStatus.Rejected].includes(deviceOrder == null ? void 0 : deviceOrder.status) && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Delete order",
            loading,
            fullWidth: true,
            colorVariant: "secondary",
            sizeVariant: "medium",
            onClick: () => __async(void 0, null, function* () {
              return yield deleteOrder(deviceOrder == null ? void 0 : deviceOrder.id);
            })
          },
          "delete-order"
        ),
        [DeviceOrderStatus.Placed].includes(deviceOrder == null ? void 0 : deviceOrder.status) && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Cancel order",
            loading,
            fullWidth: true,
            colorVariant: "secondary",
            sizeVariant: "medium",
            onClick: () => __async(void 0, null, function* () {
              return yield cancelOrder(deviceOrder == null ? void 0 : deviceOrder.id);
            })
          },
          "cancel-placed-order"
        ),
        hasDevicesAllScope && isRequested && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Reject order",
            loading,
            fullWidth: true,
            colorVariant: "secondary",
            sizeVariant: "medium",
            onClick: () => __async(void 0, null, function* () {
              yield rejectOrder(deviceOrder == null ? void 0 : deviceOrder.id);
            })
          },
          "reject-order"
        ),
        !hasDevicesAllScope && isRequested && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Cancel order",
            loading,
            fullWidth: true,
            colorVariant: "secondary",
            sizeVariant: "medium",
            onClick: () => __async(void 0, null, function* () {
              return yield cancelOrder(deviceOrder == null ? void 0 : deviceOrder.id);
            })
          },
          "cancel-requested-order"
        ),
        hasDevicesAllScope && isRequested && /* @__PURE__ */ jsx(Fragment, { children: !globalState.user.hasPaymentMethodOnFile ? /* @__PURE__ */ jsx(
          StyledTooltip,
          {
            title: /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: __spreadValues({}, themeFonts.tooltip), children: [
                "To proceed with this order ",
                /* @__PURE__ */ jsx("br", {}),
                " Please add billing information. ",
                /* @__PURE__ */ jsx("br", {})
              ] }),
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "small",
                  colorVariant: "tooltip",
                  onClick: () => routerHistory.push(generatePath(SETTINGS_BILLING_ROUTE)),
                  children: "Go to settings"
                }
              )
            ] }),
            children: /* @__PURE__ */ jsx("span", { style: { width: "100%" }, children: /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: "Approve",
                loading,
                fullWidth: true,
                onClick: () => __async(void 0, null, function* () {
                  return yield approveOrder(deviceOrder == null ? void 0 : deviceOrder.id);
                }),
                disabled: true,
                colorVariant: "primary",
                sizeVariant: "medium"
              },
              "approve-no-payment-method"
            ) })
          }
        ) : /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Approve",
            loading,
            fullWidth: true,
            onClick: () => __async(void 0, null, function* () {
              return yield approveOrder(deviceOrder == null ? void 0 : deviceOrder.id);
            }),
            colorVariant: "primary",
            sizeVariant: "medium"
          },
          "approve"
        ) }),
        [DeviceOrderStatus.Shipping].includes(deviceOrder == null ? void 0 : deviceOrder.status) && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Confirm delivery",
            loading: isDeliveredLoading,
            fullWidth: true,
            colorVariant: "secondary",
            sizeVariant: "medium",
            onClick: () => __async(void 0, null, function* () {
              return yield setOrderAsDelivered();
            })
          },
          "confirm-delivery"
        )
      ] })
    ] })
  ] }) });
};
