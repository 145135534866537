"use strict";
export const popularCurrencyOptions = [
  { value: "GBP", label: "GBP - British Pound Sterling" },
  { value: "EUR", label: "EUR - Euro" },
  { value: "USD", label: "USD - United States Dollar" },
  { value: "AUD", label: "AUD - Australian Dollar" },
  { value: "RUB", label: "RUB - Russian ruble" },
  { value: "AFN", label: "AFN - Afghan afghani" },
  { value: "ALL", label: "ALL - Albanian lek" },
  { value: "DZD", label: "DZD - Algerian dinar" },
  { value: "AOA", label: "AOA - Angolan kwanza" },
  { value: "XCD", label: "XCD - Eastern Caribbean dollar" },
  { value: "ARS", label: "ARS - Argentine peso" },
  { value: "AMD", label: "AMD - Armenian dram" },
  { value: "AWG", label: "AWG - Aruban florin" },
  { value: "SHP", label: "SHP - Saint Helena pound" },
  { value: "AZN", label: "AZN - Azerbaijani manat" },
  { value: "BSD", label: "BSD - Bahamian dollar" },
  { value: "BHD", label: "BHD - Bahraini dinar" },
  { value: "BDT", label: "BDT - Bangladeshi taka" },
  { value: "BBD", label: "BBD - Barbadian dollar" },
  { value: "BYN", label: "BYN - Belarusian ruble" },
  { value: "BZD", label: "BZD - Belize dollar" },
  { value: "XOF", label: "XOF - West African CFA franc" },
  { value: "BMD", label: "BMD - Bermudian dollar" },
  { value: "BTN", label: "BTN - Bhutanese ngultrum" },
  { value: "INR", label: "INR - Indian rupee" },
  { value: "BOB", label: "BOB - Bolivian boliviano" },
  { value: "BAM", label: "BAM - Bosnia and Herzegovina convertible mark" },
  { value: "BWP", label: "BWP - Botswana pula" },
  { value: "BRL", label: "BRL - Brazilian real" },
  { value: "BND", label: "BND - Brunei dollar" },
  { value: "SGD", label: "SGD - Singapore dollar" },
  { value: "BGN", label: "BGN - Bulgarian lev" },
  { value: "BIF", label: "BIF - Burundian franc" },
  { value: "KHR", label: "KHR - Cambodian riel" },
  { value: "XAF", label: "XAF - Central African CFA franc" },
  { value: "CAD", label: "CAD - Canadian dollar" },
  { value: "CVE", label: "CVE - Cape Verdean escudo" },
  { value: "KYD", label: "KYD - Cayman Islands dollar" },
  { value: "CLP", label: "CLP - Chilean peso" },
  { value: "CNY", label: "CNY - Chinese yuan" },
  { value: "COP", label: "COP - Colombian peso" },
  { value: "KMF", label: "KMF - Comorian franc" },
  { value: "CDF", label: "CDF - Congolese franc" },
  { value: "CRC", label: "CRC - Costa Rican col\xF3n" },
  { value: "HRK", label: "HRK - Croatian kuna" },
  { value: "CUP", label: "CUP - Cuban peso" },
  { value: "CZK", label: "CZK - Czech koruna" },
  { value: "DKK", label: "DKK - Danish krone" },
  { value: "DJF", label: "DJF - Djiboutian franc" },
  { value: "DOP", label: "DOP - Dominican peso" },
  { value: "EGP", label: "EGP - Egyptian pound" },
  { value: "XAF", label: "XAF - Central African CFA franc" },
  { value: "ERN", label: "ERN - Eritrean nakfa" },
  { value: "EEK", label: "EEK - Estonian kroon" },
  { value: "SZL", label: "SZL - Swazi lilangeni" },
  { value: "ETB", label: "ETB - Ethiopian birr" },
  { value: "FKP", label: "FKP - Falkland Islands pound" },
  { value: "FJD", label: "FJD - Fijian dollar" },
  { value: "XPF", label: "XPF - CFP franc" },
  { value: "GMD", label: "GMD - Gambian dalasi" },
  { value: "GEL", label: "GEL - Georgian lari" },
  { value: "GHS", label: "GHS - Ghanaian cedi" },
  { value: "GIP", label: "GIP - Gibraltar pound" },
  { value: "GTQ", label: "GTQ - Guatemalan quetzal" },
  { value: "GNF", label: "GNF - Guinean franc" },
  { value: "GYD", label: "GYD - Guyanese dollar" },
  { value: "HTG", label: "HTG - Haitian gourde" },
  { value: "HNL", label: "HNL - Honduran lempira" },
  { value: "HKD", label: "HKD - Hong Kong dollar" },
  { value: "HUF", label: "HUF - Hungarian forint" },
  { value: "ISK", label: "ISK - Icelandic kr\xF3na" },
  { value: "INR", label: "INR - Indian rupee" },
  { value: "IDR", label: "IDR - Indonesian rupiah" },
  { value: "IRR", label: "IRR - Iranian rial" },
  { value: "IQD", label: "IQD - Iraqi dinar" },
  { value: "ILS", label: "ILS - Israeli new shekel" },
  { value: "JMD", label: "JMD - Jamaican dollar" },
  { value: "JPY", label: "JPY - Japanese yen" },
  { value: "JOD", label: "JOD - Jordanian dinar" },
  { value: "KZT", label: "KZT - Kazakhstani tenge" },
  { value: "KES", label: "KES - Kenyan shilling" },
  { value: "KWD", label: "KWD - Kuwaiti dinar" },
  { value: "KGS", label: "KGS - Kyrgyzstani som" },
  { value: "LAK", label: "LAK - Lao kip" },
  { value: "LVL", label: "LVL - Latvian lats" },
  { value: "LBP", label: "LBP - Lebanese pound" },
  { value: "LSL", label: "LSL - Lesotho loti" },
  { value: "LRD", label: "LRD - Liberian dollar" },
  { value: "LYD", label: "LYD - Libyan dinar" },
  { value: "LTL", label: "LTL - Lithuanian litas" },
  { value: "MOP", label: "MOP - Macanese pataca" },
  { value: "MKD", label: "MKD - Macedonian denar" },
  { value: "MGA", label: "MGA - Malagasy ariary" },
  { value: "MWK", label: "MWK - Malawian kwacha" },
  { value: "MYR", label: "MYR - Malaysian ringgit" },
  { value: "MVR", label: "MVR - Maldivian rufiyaa" },
  { value: "MRO", label: "MRO - Mauritanian ouguiya" },
  { value: "MUR", label: "MUR - Mauritian rupee" },
  { value: "MXN", label: "MXN - Mexican peso" },
  { value: "MDL", label: "MDL - Moldovan leu" },
  { value: "MNT", label: "MNT - Mongolian t\xF6gr\xF6g" },
  { value: "MAD", label: "MAD - Moroccan dirham" },
  { value: "MZN", label: "MZN - Mozambican metical" },
  { value: "MMK", label: "MMK - Myanmar kyat" },
  { value: "NAD", label: "NAD - Namibian dollar" },
  { value: "NPR", label: "NPR - Nepalese rupee" },
  { value: "ANG", label: "ANG - Netherlands Antillean guilder" },
  { value: "TWD", label: "TWD - New Taiwan dollar" },
  { value: "NZD", label: "NZD - New Zealand dollar" },
  { value: "NIO", label: "NIO - Nicaraguan c\xF3rdoba" },
  { value: "NGN", label: "NGN - Nigerian naira" },
  { value: "KPW", label: "KPW - North Korean won" },
  { value: "NOK", label: "NOK - Norwegian krone" },
  { value: "OMR", label: "OMR - Omani rial" },
  { value: "PKR", label: "PKR - Pakistani rupee" },
  { value: "PAB", label: "PAB - Panamanian balboa" },
  { value: "PGK", label: "PGK - Papua New Guinean kina" },
  { value: "PYG", label: "PYG - Paraguayan guaran\xED" },
  { value: "PEN", label: "PEN - Peruvian sol" },
  { value: "PHP", label: "PHP - Philippine peso" },
  { value: "PLN", label: "PLN - Polish z\u0142oty" },
  { value: "QAR", label: "QAR - Qatari riyal" },
  { value: "RON", label: "RON - Romanian leu" },
  { value: "RWF", label: "RWF - Rwandan franc" },
  { value: "SHP", label: "SHP - Saint Helena pound" },
  { value: "WST", label: "WST - Samoan t\u0101l\u0101" },
  { value: "SAR", label: "SAR - Saudi riyal" },
  { value: "XOF", label: "XOF - Senegalese franc" },
  { value: "RSD", label: "RSD - Serbian dinar" },
  { value: "SCR", label: "SCR - Seychellois rupee" },
  { value: "SLL", label: "SLL - Sierra Leonean leone" },
  { value: "SGD", label: "SGD - Singapore dollar" },
  { value: "SBD", label: "SBD - Solomon Islands dollar" },
  { value: "SOS", label: "SOS - Somali shilling" },
  { value: "ZAR", label: "ZAR - South African rand" },
  { value: "KRW", label: "KRW - South Korean won" },
  { value: "SSP", label: "SSP - South Sudanese pound" },
  { value: "LKR", label: "LKR - Sri Lankan rupee" },
  { value: "SDG", label: "SDG - Sudanese pound" },
  { value: "SRD", label: "SRD - Surinamese dollar" },
  { value: "SZL", label: "SZL - Swazi lilangeni" },
  { value: "SEK", label: "SEK - Swedish krona" },
  { value: "CHF", label: "CHF - Swiss franc" },
  { value: "SYP", label: "SYP - Syrian pound" },
  { value: "TJS", label: "TJS - Tajikistani somoni" },
  { value: "TZS", label: "TZS - Tanzanian shilling" },
  { value: "THB", label: "THB - Thai baht" },
  { value: "TOP", label: "TOP - Tongan pa\u02BBanga" },
  { value: "TTD", label: "TTD - Trinidad and Tobago dollar" },
  { value: "TND", label: "TND - Tunisian dinar" },
  { value: "TRY", label: "TRY - Turkish lira" },
  { value: "TMT", label: "TMT - Turkmenistan manat" },
  { value: "UGX", label: "UGX - Ugandan shilling" },
  { value: "UAH", label: "UAH - Ukrainian hryvnia" },
  { value: "AED", label: "AED - United Arab Emirates dirham" },
  { value: "UYU", label: "UYU - Uruguayan peso" },
  { value: "UZS", label: "UZS - Uzbekistani som" },
  { value: "VUV", label: "VUV - Vanuatu vatu" },
  { value: "VUV", label: "VUV - Vanuatu vatu" },
  { value: "VES", label: "VES - Venezuelan bol\xEDvar" },
  { value: "VND", label: "VND - Vietnamese \u0111\u1ED3ng" },
  { value: "XOF", label: "XOF - West African CFA franc" },
  { value: "YER", label: "YER - Yemeni rial" },
  { value: "ZMW", label: "ZMW - Zambian kwacha" },
  { value: "ZWL", label: "ZWL - Zimbabwean dollar" }
];
export const popularCurrencyShortOptions = popularCurrencyOptions.map((o) => ({
  value: o.value,
  label: o.value
}));
