"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { AttendanceJobsTable } from "@v2/feature/attendance/settings/components/attendance-jobs-table.component";
import { AttendanceSettingsNewJobDrawer } from "@v2/feature/attendance/settings/components/attendance-settings-new-job-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
export const AttendanceCompanySettingsJobsPage = ({
  jobTypes,
  refreshJobs,
  isLoading,
  attendancePayMultipliers
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoading, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("SettingsRouterPageConfig.jobs"),
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "small", onClick: () => setIsOpen(true), children: polyglot.t("SettingsRouterPageConfig.newJob") })
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      AttendanceJobsTable,
                      {
                        attendanceTypes: jobTypes != null ? jobTypes : [],
                        refreshJobs,
                        attendancePayMultipliers
                      }
                    )
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      AttendanceSettingsNewJobDrawer,
      {
        isOpen,
        setIsOpen,
        job: null,
        refresh: refreshJobs,
        attendancePayMultipliers
      }
    )
  ] });
};
