"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class CompanyConfigAPI {
  static disableOrEnableTestModeAsSuperAdmin(companyId) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/company-config/superadmin/${companyId}/test-mode/state-change`);
    });
  }
  static disableOrEnableTestMode() {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/company-config/test-mode/state-change`);
    });
  }
}
export class CompanyConfigEndpoints {
  static getCompanyConfig() {
    return {
      url: "/apiv2/company-config"
    };
  }
}
