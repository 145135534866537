"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  UserCustomBenefitPaymentStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const UserBenefitPaymentDrawer = ({
  isOpen,
  setIsOpen,
  remainingBalance,
  userBenefitPayment,
  refresh,
  onClose,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    UserBenefitPaymentDrawerContent,
    {
      userBenefitPayment,
      remainingBalance,
      refresh,
      setIsOpen
    }
  ) });
};
const UserBenefitPaymentDrawerContent = ({
  userBenefitPayment,
  remainingBalance,
  refresh,
  setIsOpen
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const approvePayment = useCallback(() => __async(void 0, null, function* () {
    if (!userBenefitPayment) return;
    try {
      setLoadingApprove(true);
      yield CustomBenefitAPI.approveOrRejectCustomBenefitPaymentById(
        userBenefitPayment.customBenefitId,
        userBenefitPayment.userId,
        userBenefitPayment.userCustomBenefitId,
        userBenefitPayment.id,
        UserCustomBenefitPaymentStatus.Approved
      );
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoadingApprove(false);
    }
  }), [userBenefitPayment, refresh, setIsOpen, showMessage, polyglot]);
  const rejectPayment = useCallback(() => __async(void 0, null, function* () {
    if (!userBenefitPayment) return;
    try {
      setLoadingReject(true);
      yield CustomBenefitAPI.approveOrRejectCustomBenefitPaymentById(
        userBenefitPayment.customBenefitId,
        userBenefitPayment.userId,
        userBenefitPayment.userCustomBenefitId,
        userBenefitPayment.id,
        UserCustomBenefitPaymentStatus.Rejected
      );
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoadingReject(false);
    }
  }), [userBenefitPayment, setIsOpen, showMessage, polyglot, refresh]);
  return userBenefitPayment ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Benefit payment requested" }),
    userBenefitPayment.userId && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: "Employee" }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: "5px" }, children: /* @__PURE__ */ jsx(UserAvatar, { userId: userBenefitPayment.userId, size: "xxsmall", showName: true, nameVariant: "title4" }) })
    ] }),
    ((_a = userBenefitPayment.customBenefit) == null ? void 0 : _a.name) && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: "Benefit" }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: userBenefitPayment.customBenefit.name })
    ] }),
    userBenefitPayment.notes && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: "Notes" }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: userBenefitPayment.notes })
    ] }),
    remainingBalance !== null && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: "Current available balance" }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: formatMoney({ amount: remainingBalance }) })
    ] }),
    userBenefitPayment.amount && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: "Amount" }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: formatMoney({ amount: userBenefitPayment.amount }) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: loadingReject,
          colorVariant: "secondary",
          name: "Reject",
          fullWidth: true,
          onClick: rejectPayment
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: loadingApprove,
          colorVariant: "primary",
          name: "Approve",
          fullWidth: true,
          onClick: approvePayment
        }
      )
    ] })
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Benefit payment requested" }),
    /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "50vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    )
  ] });
};
