"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CycleCreationVisibilitySettingsModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  showPeerSetting
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    CycleCreationVisibilitySettingsContent,
    {
      reviewCycle,
      onClose,
      refresh,
      showPeerSetting
    }
  ) });
};
const useVisibilityForm = (reviewCycle, onClose, refresh) => {
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      visibilitySettings: (reviewCycle == null ? void 0 : reviewCycle.visibilitySettings) || {
        allowManagerToReleaseFeedback: false,
        hidePeerAuthor: false
      }
    },
    validationSchema: yup.object({
      visibilitySettings: yup.object({
        allowManagerToReleaseFeedback: yup.boolean(),
        hidePeerAuthor: yup.boolean()
      })
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (reviewCycle) {
          yield ReviewCycleAPI.updateReviewCycle(__spreadProps(__spreadValues({}, reviewCycle), {
            visibilitySettings: values.visibilitySettings
          }));
          showMessage("Successfully update the cycle", "success");
          yield refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
const CycleCreationVisibilitySettingsContent = ({
  reviewCycle,
  onClose,
  refresh,
  showPeerSetting
}) => {
  const { polyglot } = usePolyglot();
  const formik = useVisibilityForm(reviewCycle, onClose, refresh);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Visibility" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "flex-start" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Require manager to release feedback" }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Feedback will be held for review and released only after a manager's approval." })
      ] }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: formik.values.visibilitySettings.allowManagerToReleaseFeedback,
          name: "allow-manager-to-release-feedback",
          onChange: (_e, enabled) => {
            formik.setFieldValue("visibilitySettings.allowManagerToReleaseFeedback", enabled);
          }
        }
      )
    ] }),
    showPeerSetting && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "flex-start" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Hide peer's name" }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Peer feedback will be displayed without author's name." })
      ] }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: formik.values.visibilitySettings.hidePeerAuthor,
          name: "hide-peer-author",
          onChange: (_e, enabled) => {
            formik.setFieldValue("visibilitySettings.hidePeerAuthor", enabled);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
