"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { HelperAbsencePolicyBalanceDrawer } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-balance-drawer.component";
export const HelperAbsencePolicyBalance = ({ companyId, absencePolicies, users }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        onClick: () => {
          setOpenDrawer(true);
        },
        children: "User balance"
      }
    ),
    /* @__PURE__ */ jsx(
      HelperAbsencePolicyBalanceDrawer,
      {
        isOpen: openDrawer,
        setIsOpen: setOpenDrawer,
        companyId,
        absencePolicies,
        users
      }
    )
  ] });
};
