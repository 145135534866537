"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SurveyResultsFilters } from "@v2/feature/growth/surveys/features/components/survey-results-filters.component";
import { SurveyResultsByQuestion } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-results-by-question.component";
import {
  SurveyImpactLabel
} from "@v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { GlobalContext } from "@/GlobalState";
export const SurveyQuestionsBiggestImpact = ({
  surveyResult,
  growthFactors,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
  surveyCycle
}) => {
  var _a, _b, _c;
  const [state, dispatch] = useContext(GlobalContext);
  const [measureByFilter, setMeasureByFilter] = useState(
    ((_c = (_b = (_a = state.user.features) == null ? void 0 : _a.surveys) == null ? void 0 : _b.results) == null ? void 0 : _c.measuredBy) || "nps"
  );
  const reportName = useMemo(() => `${surveyCycle.internalName}-biggest-impact`, [surveyCycle.internalName]);
  const existingFactors = useMemo(() => {
    const factorsArray = surveyResult == null ? void 0 : surveyResult.resultByQuestion.map((r) => r.question.factor).filter(Boolean);
    return [...new Set(factorsArray)];
  }, [surveyResult]);
  const [impactFilter, setImpactFilter] = useState(existingFactors[0] || "");
  const filteredResultByQuestion = useMemo(() => {
    if (!(surveyResult == null ? void 0 : surveyResult.impactResult) || !(surveyResult == null ? void 0 : surveyResult.resultByQuestion) || !impactFilter) return [];
    const questionIdsWithImpactStats = Object.keys(surveyResult.impactResult).map((questionId) => {
      const impactStats = surveyResult.impactResult[questionId] ? surveyResult.impactResult[questionId][impactFilter] : null;
      if (!impactStats) return null;
      return { questionId, impactStats };
    }).filter(
      (entry) => entry && entry.impactStats.coefficient !== null && [SurveyImpactLabel.High, SurveyImpactLabel.Medium].includes(entry.impactStats.label)
    );
    questionIdsWithImpactStats.sort(
      (a, b) => Math.abs(a.impactStats.coefficient) < Math.abs(b.impactStats.coefficient) ? 1 : -1
    );
    return questionIdsWithImpactStats.slice(0, 3).map(({ questionId }) => surveyResult.resultByQuestion.find((q) => q.question.id === questionId)).filter(Boolean);
  }, [surveyResult, impactFilter]);
  if (!surveyResult) return null;
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      SurveyResultsFilters,
      {
        existingFactors,
        showByFilter: "Question",
        measureByFilter,
        setMeasureByFilter,
        impactFilter,
        setImpactFilter,
        dispatch,
        filtersAndTypesOptions: filtersAndTypesOptions || [],
        setFilterString,
        filterString,
        surveyCycle
      }
    ),
    /* @__PURE__ */ jsx("div", { id: reportName, children: /* @__PURE__ */ jsx(
      SurveyResultsByQuestion,
      {
        resultByQuestion: filteredResultByQuestion,
        impactResult: surveyResult.impactResult,
        growthFactors,
        impactFilter,
        measureByFilter,
        emptyStateMessage: "No questions with high or medium impact available for the selected factor."
      }
    ) })
  ] });
};
