"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltPolicyPayloadIdentifier } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { iconSize } from "@v2/styles/menu.styles";
import { FormikProvider, useFormik } from "formik";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
export const ZeltMdmLoginItemsPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  var _a;
  const getPolicy = () => {
    var _a2, _b;
    const loginItemPath = { Path: "", Hide: false };
    return {
      autoLaunchedApplicationDictionaryManaged: ((_a2 = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableLoginItems) == null ? void 0 : _a2.autoLaunchedApplicationDictionaryManaged) !== void 0 ? (_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableLoginItems) == null ? void 0 : _b.autoLaunchedApplicationDictionaryManaged : [loginItemPath],
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.LOGIN_ITEMS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const initialValues = getPolicy();
  const formik = useFormik({
    initialValues,
    onSubmit: (loginItemsForm) => __async(void 0, null, function* () {
      const analyzedPolicyBeforeSave = analyzePolicyBeforeSave(loginItemsForm);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableLoginItems: analyzedPolicyBeforeSave
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  function analyzePolicyBeforeSave(policy) {
    if (!policy.autoLaunchedApplicationDictionaryManaged || policy.autoLaunchedApplicationDictionaryManaged.length === 0) {
      return void 0;
    }
    return {
      autoLaunchedApplicationDictionaryManaged: policy.autoLaunchedApplicationDictionaryManaged,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.LOGIN_ITEMS_PAYLOAD_IDENTIFIER.valueOf()
    };
  }
  useEffect(() => {
    formik.validateForm();
  }, []);
  const handleAddTextField = () => {
    const loginItemPath = { Path: "", Hide: false };
    const newArray = [...formik.values.autoLaunchedApplicationDictionaryManaged || [], loginItemPath];
    formik.setFieldValue("autoLaunchedApplicationDictionaryManaged", newArray);
  };
  const handleRemoveTextField = (indexToRemove) => {
    const newArray = (formik.values.autoLaunchedApplicationDictionaryManaged || []).filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("autoLaunchedApplicationDictionaryManaged", newArray);
  };
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Open at login" }),
    /* @__PURE__ */ jsx(Box, { sx: { gap: 2, mt: 2 }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "For each individual item, you should supply a path" }) }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 2 }, children: (_a = formik.values.autoLaunchedApplicationDictionaryManaged) == null ? void 0 : _a.map((item, index) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 2 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: `Path to the application`,
            name: `autoLaunchedApplicationDictionaryManaged[${index}].Path`,
            value: item.Path,
            onChange: (e) => {
              const path = e == null ? void 0 : e.target.value;
              const updatedArray = [...formik.values.autoLaunchedApplicationDictionaryManaged];
              item.Path = path;
              updatedArray[index] = item;
              formik.setFieldValue("autoLaunchedApplicationDictionaryManaged", updatedArray);
            },
            autoFocus: index === 0
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { marginTop: "14px" }, children: /* @__PURE__ */ jsx(IconButton, { sx: iconButtonSx, onClick: () => handleRemoveTextField(index), title: "Remove", children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize)) }) })
      ] }, index)) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: handleAddTextField, sizeVariant: "small", colorVariant: "secondary", children: "Add path" }) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => formik.handleSubmit(), fullWidth: true, colorVariant: "primary", sizeVariant: "medium", children: "Save" }) })
    ] })
  ] });
};
