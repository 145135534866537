"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ReportConfigBackButton } from "@v2/feature/reports/reports-advanced/components/report-config-back-button.component";
import { FilterValueSelector } from "@v2/feature/reports/reports-advanced/filters-components/filter-value-selector.component";
import { operatorOptionsByType } from "@v2/feature/reports/reports-test.util";
import {
  ReportSQLOperator
} from "@v2/feature/reports/reports.interface";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
export const FilterEditSection = ({ setSelectedFilters, reportFilters, filter, goBack }) => {
  var _a, _b, _c;
  const [selectedOperator, setSelectedOperator] = useState((_a = filter == null ? void 0 : filter.op) != null ? _a : ReportSQLOperator.eq);
  const [value, setValue] = useState((_b = filter == null ? void 0 : filter.value) != null ? _b : "");
  const filterObj = useMemo(() => {
    var _a2;
    if (!filter) return null;
    const fObj = reportFilters.find((f) => f.stub === filter.stub);
    if (!fObj) return null;
    return (_a2 = fObj.filters[filter.filterKey]) != null ? _a2 : null;
  }, [filter, reportFilters]);
  useEffect(() => {
    if ((filterObj == null ? void 0 : filterObj.type) === "boolean") setSelectedOperator(ReportSQLOperator.is);
  }, [filterObj]);
  const operatorOptions = useMemo(() => {
    if (!(filterObj == null ? void 0 : filterObj.type)) return [];
    return operatorOptionsByType(filterObj.type);
  }, [filterObj]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px", height: "100%" }, children: [
    /* @__PURE__ */ jsx(ReportConfigBackButton, { title: "Filters", goBack }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: filterObj ? (_c = filterObj.label) != null ? _c : "Selected filter" : "No filter selected" }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        options: operatorOptions,
        value: selectedOperator,
        name: "operator",
        label: "Operator",
        onChange: (e) => setSelectedOperator(e.target.value)
      }
    ),
    filterObj && /* @__PURE__ */ jsx(
      FilterValueSelector,
      {
        label: "Value",
        filterType: filterObj.type,
        filterOptions: filterObj.options,
        operator: selectedOperator,
        value,
        setValue
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: filter && /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => {
          setSelectedFilters((prev) => {
            const newFiltersSet = __spreadValues({}, prev);
            if (!newFiltersSet[filter.stub]) newFiltersSet[filter.stub] = { [filter.filterKey]: [] };
            if (!newFiltersSet[filter.stub][filter.filterKey]) newFiltersSet[filter.stub][filter.filterKey] = [];
            const filterToBeAdded = { op: selectedOperator, value };
            if (filter.type === "new") {
              newFiltersSet[filter.stub][filter.filterKey].push(filterToBeAdded);
            } else {
              const oldFilterIndex = newFiltersSet[filter.stub][filter.filterKey].findIndex((f) => {
                return f.op === filter.op && f.value === filter.value;
              });
              if (oldFilterIndex >= 0)
                newFiltersSet[filter.stub][filter.filterKey][oldFilterIndex] = filterToBeAdded;
            }
            return newFiltersSet;
          });
          goBack();
        },
        fullWidth: true,
        children: "Apply"
      }
    ) })
  ] });
};
