"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { ApprovalRuleDrawer } from "@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
const getApprovalRulesOptions = (approvalRules) => approvalRules.map((rule) => ({
  label: rule.name,
  value: rule.id
}));
export const PolicyApprovalEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  approvalRules,
  refresh,
  refreshApprovalRules
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();
  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        backoffApprovalRuleId: values.backoffApprovalRuleId,
        updateApprovalNotRequired: values.updateApprovalNotRequired
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyApproval(absencePolicy.id, policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceDrawerPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      backoffApprovalRuleId: absencePolicy.backoffApprovalRuleId,
      updateApprovalNotRequired: absencePolicy.updateApprovalNotRequired
    },
    validationSchema: yup.object({
      backoffApprovalRuleId: yup.number().integer(polyglot.t("PolicyApprovalEditDrawer.selectValidField")).typeError(polyglot.t("PolicyApprovalEditDrawer.selectValidField")).required(polyglot.t("PolicyApprovalEditDrawer.selectValidField")),
      updateApprovalNotRequired: yup.boolean().required(polyglot.t("PolicyApprovalEditDrawer.requiredField"))
    }),
    onSubmit
  });
  const autoApproveRule = useMemo(() => approvalRules.find((rule) => rule.name === "Auto approve"), [approvalRules]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PolicyApprovalEditDrawer.editApproval") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "backoffApprovalRuleId",
        label: polyglot.t("EditPolicyApprovalDrawer.backoffApprovalRuleId"),
        options: getApprovalRulesOptions(approvalRules),
        value: formik.values.backoffApprovalRuleId,
        onChange: formik.handleChange,
        compareValue: formik.values.backoffApprovalRuleId,
        error: !!formik.errors.backoffApprovalRuleId && formik.touched.backoffApprovalRuleId,
        helperText: formik.touched.backoffApprovalRuleId && formik.errors.backoffApprovalRuleId,
        editList: {
          isHidden: !hasScopes(["company.settings:all"]),
          handler: () => {
            setIsApprovalDrawerOpen(true);
          }
        }
      }
    ),
    formik.values.backoffApprovalRuleId !== (autoApproveRule == null ? void 0 : autoApproveRule.id) && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: polyglot.t("EditPolicyApprovalDrawer.updateApprovalNotRequired"),
        name: "updateApprovalNotRequired",
        checked: !formik.values.updateApprovalNotRequired,
        onChange: (_, checked) => {
          formik.setFieldValue("updateApprovalNotRequired", !checked);
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ApprovalRuleDrawer,
      {
        isOpen: isApprovalDrawerOpen,
        setIsOpen: setIsApprovalDrawerOpen,
        approvalRule: null,
        afterClose: () => {
          setIsApprovalDrawerOpen(false);
        },
        refresh: () => __async(void 0, null, function* () {
          if (refreshApprovalRules) yield refreshApprovalRules();
        })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] }) }) });
};
