"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class AnalyticsAPI {
  static findGenderOverTime() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/analytics/users/gender-over-time")).data;
    });
  }
  static findHeadcountByDepartment() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/analytics/users/headcount/department")).data;
    });
  }
  static getHeadcountOverTime() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/analytics/users/headcount-over-time")).data;
    });
  }
  static getHiresAndFiresLastMonth() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/analytics/users/hires-fires-over-month")).data;
    });
  }
}
export class AnalyticEndpoints {
  static getSalaryBand(filterString) {
    return {
      url: `/apiv2/analytics/users/salary-band?${filterString}`
    };
  }
  static findGenderPayGap(filterString) {
    return {
      url: `/apiv2/analytics/users/quartile-salary-per-gender?${filterString}`
    };
  }
  static findLastSalaryChange(filterString) {
    return {
      url: `/apiv2/analytics/users/last-salary-change?${filterString}`
    };
  }
}
