"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { SelectBankForm } from "./features/make-payment/components/select-bank-form-step.component";
import { CreatePaymentPage } from "./features/make-payment/pages/create-payment.page";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import {
  PAYMENTS_TEAM_CREATE_ROUTE,
  PAYMENTS_TEAM_SELECT_BANK_ROUTE,
  PAYMENT_TEAM_NEW_INVOICE_ROUTE,
  PAYMENT_TEAM_OVERVIEW_ROUTE,
  PAYMENT_TEAM_ROUTE
} from "@/lib/routes";
import { NewInvoicePage } from "@/v2/feature/payments/pages/new-invoice.page";
import { PaymentsTeamPage } from "@/v2/feature/payments/pages/payments.team.page";
export const PaymentsTeamRouter = ({ isManager }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["payments:manager"], path: PAYMENT_TEAM_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PaymentsTeamPage, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["payments:manager"], path: PAYMENTS_TEAM_SELECT_BANK_ROUTE, children: /* @__PURE__ */ jsx(SelectBankForm, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["payments:manager"], path: PAYMENTS_TEAM_CREATE_ROUTE, children: /* @__PURE__ */ jsx(CreatePaymentPage, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["payments:manager"], path: PAYMENT_TEAM_NEW_INVOICE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(NewInvoicePage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { to: PAYMENT_TEAM_OVERVIEW_ROUTE, from: PAYMENT_TEAM_ROUTE })
  ] });
};
