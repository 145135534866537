"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Link } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { underlinedLinkLight } from "@/v2/styles/buttons.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NotionInstallInstructions = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "a) Go to settings & members." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "b) Go to Identity & provisioning." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "c) Generate a SCIM token in SCIM provisioning and copy the API key." })
  ] });
};
export const NotionInfoCard = () => {
  return /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
    /* @__PURE__ */ jsx(Link, { sx: underlinedLinkLight, href: "https://www.notion.so/pricing", children: "Enterprise plan" }),
    " ",
    "required to use Notion API.",
    " ",
    /* @__PURE__ */ jsx(Link, { sx: underlinedLinkLight, href: "https://www.notion.so/startups", children: "Notion for startups" }),
    " ",
    "offers $1000 free credit."
  ] });
};
