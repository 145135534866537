"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
import useSWR from "swr";
export const defaultSwrOptions = {
  dedupingInterval: 2e3,
  revalidateOnFocus: false,
  suspense: true,
  revalidateIfStale: true
};
export const fetcher = (url) => __async(void 0, null, function* () {
  return axios.get(url).then(
    (res) => (
      // NestJS returns null/undefined values as empty strings, so convert them in the returned data
      res.data === "" ? null : res.data
    )
  ).catch((error) => error);
});
export function useApiClient(query, swrOptions, enabled = true) {
  var _a, _b;
  const swrOptionsWithEnabled = enabled ? {} : {
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  };
  const { data, mutate, isValidating, error } = useSWR((_a = query == null ? void 0 : query.url) != null ? _a : null, fetcher, __spreadValues(__spreadValues(__spreadValues(__spreadValues({}, defaultSwrOptions), query == null ? void 0 : query.swrOptions), swrOptions), swrOptionsWithEnabled));
  if (((_b = data == null ? void 0 : data.response) == null ? void 0 : _b.status) > 299) {
    console.error(data);
    console.error({ error });
    const dataResponse = data == null ? void 0 : data.response;
    const status = dataResponse.status;
    const statusText = dataResponse == null ? void 0 : dataResponse.statusText;
    return {
      data: null,
      error: {
        status,
        statusText,
        data: dataResponse == null ? void 0 : dataResponse.data
      },
      isValidating
    };
  }
  const isLoading = Boolean(data === void 0 && !error && query && enabled);
  return { data, mutate, isValidating, isLoading };
}
export const clearApiClientCache = ({ cache }) => {
  cache.clear();
};
