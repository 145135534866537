"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { PersonalAbsencePage } from "@v2/feature/absence/me/policies/pages/personal-absence.page";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { ABSENCE_ME_REQUESTS_ROUTE } from "@/lib/routes";
export const AbsenceMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getUserAbsencePolicies(globalState.user.userId), {
    suspense: false
  });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence"], path: ABSENCE_ME_REQUESTS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PersonalAbsencePage, { absencePolicies: absencePolicies != null ? absencePolicies : null, userId: globalState.user.userId }) }),
    /* @__PURE__ */ jsx(Redirect, { to: ABSENCE_ME_REQUESTS_ROUTE })
  ] });
};
