"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { getSchedulePayrollValidationSchema } from "@v2/feature/attendance/attendance.util";
import { drawerContentSx, fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const AttendanceScheduleEditPayrollDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    AttendanceScheduleEditPayrollDrawerContent,
    {
      attendanceSchedule,
      refresh,
      setIsOpen
    }
  ) });
};
const AttendanceScheduleEditPayrollDrawerContent = ({
  attendanceSchedule,
  refresh,
  setIsOpen
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        const update = {
          includedInPayroll: values.includedInPayroll,
          payCode: values.payCode ? values.payCode : null
        };
        setLoading(true);
        yield AttendanceAPI.updateAttendanceSchedulePayroll(attendanceSchedule.id, update);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AttendanceScheduleEditSettingsDrawer.errorMessages.update", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [attendanceSchedule.id, showMessage, refresh, setIsOpen, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      includedInPayroll: attendanceSchedule.includedInPayroll,
      payCode: (_a = attendanceSchedule.payCode) != null ? _a : ""
    },
    validationSchema: getSchedulePayrollValidationSchema(polyglot),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PayrollModule.payroll") }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "includedInPayroll",
        label: polyglot.t("PayrollModule.includedInPayroll"),
        checked: formik.values.includedInPayroll,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("PayItemModule.payCode"),
        name: "payCode",
        value: formik.values.payCode,
        onChange: formik.handleChange,
        error: formik.touched.payCode && !!formik.errors.payCode,
        helperText: (_b = formik.touched.payCode && formik.errors.payCode) != null ? _b : " ",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", loading, fullWidth: true, children: polyglot.t("General.save") }) })
  ] }) });
};
