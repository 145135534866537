"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@v2/styles/spacing.styles";
export const LoginItemPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  configurationPayloadOption
}) => {
  const renderList = (items) => /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        padding: "16px",
        gap: "16px",
        borderRadius: "8px",
        border: "1px solid #F1EEEA",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Open at login policy" }),
        items.length > 0 ? items.map((item, index) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: item.Path }) }, index)) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "No applications" })
      ]
    }
  );
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Login items" }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", mt: spacing.m20, padding: 0, gap: spacing.g15 }, children: renderList((configurationPayloadOption == null ? void 0 : configurationPayloadOption.autoLaunchedApplicationDictionaryManaged) || []) })
  ] }) });
};
