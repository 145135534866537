"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
export const UserBenefitRequestDrawer = ({ isOpen, setIsOpen, userBenefitRequest, onClose, refresh }) => {
  return userBenefitRequest ? /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(UserBenefitRequestDrawerContent, { userBenefitRequest, refresh, onClose }) }) : null;
};
const DetailsLine = ({ title, value }) => /* @__PURE__ */ jsxs(Box, { children: [
  /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: title }),
  /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value })
] });
export const UserBenefitRequestDrawerContent = ({ userBenefitRequest, onClose, refresh }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const hasInsuranceAll = hasScopes(["insurance:all"]);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const approveRequest = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceAll) return;
    try {
      setIsApproveLoading(true);
      yield CustomBenefitAPI.approveUserBenefitRequest(
        userBenefitRequest.userId,
        userBenefitRequest.customBenefitId,
        userBenefitRequest.id
      );
      yield refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setIsApproveLoading(true);
    }
  }), [hasInsuranceAll, showMessage, refresh, onClose, userBenefitRequest, polyglot]);
  const rejectRequest = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceAll) return;
    try {
      setIsRejectLoading(true);
      yield CustomBenefitAPI.rejectUserBenefitRequest(
        userBenefitRequest.userId,
        userBenefitRequest.customBenefitId,
        userBenefitRequest.id
      );
      yield refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setIsRejectLoading(false);
    }
  }), [hasInsuranceAll, showMessage, refresh, onClose, userBenefitRequest, polyglot]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Benefit Request" }),
    ((_a = userBenefitRequest.customBenefit) == null ? void 0 : _a.type) && /* @__PURE__ */ jsx(DetailsLine, { title: polyglot.t("General.type"), value: userBenefitRequest.customBenefit.type }),
    /* @__PURE__ */ jsx(DetailsLine, { title: polyglot.t("General.amount"), value: formatMoney({ amount: userBenefitRequest.amount }) }),
    userBenefitRequest.numberOfInstallments && /* @__PURE__ */ jsx(
      DetailsLine,
      {
        title: polyglot.t("BenefitModule.numberOfInstallments"),
        value: userBenefitRequest.numberOfInstallments
      }
    ),
    /* @__PURE__ */ jsx(DetailsLine, { title: polyglot.t("General.status"), value: userBenefitRequest.status }),
    (((_b = userBenefitRequest.creator) == null ? void 0 : _b.displayName) || ((_c = userBenefitRequest.creator) == null ? void 0 : _c.name)) && /* @__PURE__ */ jsx(
      DetailsLine,
      {
        title: polyglot.t("General.createdBy"),
        value: (_e = (_d = userBenefitRequest.creator.displayName) != null ? _d : userBenefitRequest.creator.name) != null ? _e : ""
      }
    ),
    /* @__PURE__ */ jsx(
      DetailsLine,
      {
        title: polyglot.t("General.createdAt"),
        value: new Date(userBenefitRequest.createdAt).toLocaleDateString()
      }
    ),
    (((_f = userBenefitRequest.approver) == null ? void 0 : _f.displayName) || ((_g = userBenefitRequest.approver) == null ? void 0 : _g.name)) && /* @__PURE__ */ jsx(
      DetailsLine,
      {
        title: polyglot.t("General.approvedBy"),
        value: (_i = (_h = userBenefitRequest.approver.displayName) != null ? _h : userBenefitRequest.approver.name) != null ? _i : ""
      }
    ),
    userBenefitRequest.approvedOnTimestamp && /* @__PURE__ */ jsx(
      DetailsLine,
      {
        title: polyglot.t("General.approvedAt"),
        value: new Date(userBenefitRequest.approvedOnTimestamp).toLocaleDateString()
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.notes") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: userBenefitRequest.notes }),
    hasInsuranceAll && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: isRejectLoading,
          colorVariant: "secondary",
          name: polyglot.t("General.reject"),
          onClick: rejectRequest,
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: isApproveLoading,
          colorVariant: "primary",
          name: polyglot.t("General.approve"),
          onClick: approveRequest,
          fullWidth: true
        }
      )
    ] })
  ] });
};
