"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useContext } from "react";
import { Box } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import "@/v2/feature/dashboard/features/sections/user-profile-widget/dashboard-user-profile.scss";
import { spacing } from "@/v2/styles/spacing.styles";
export const DashboardUserProfileLoader = () => {
  const [globalState] = useContext(GlobalContext);
  const user = globalState.user;
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "small", isLoadingState: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: spacing.p10
        },
        children: [
          /* @__PURE__ */ jsx("div", { className: "upl-wave" }),
          /* @__PURE__ */ jsxs(
            Typography,
            {
              variant: "title2",
              sx: {
                marginTop: spacing.m15,
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
                width: "100%"
              },
              children: [
                user.firstName,
                " ",
                user.lastName
              ]
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5, width: "100%", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx("div", { style: { width: "40%" }, children: /* @__PURE__ */ jsx("div", { className: "upl-loader-container", children: /* @__PURE__ */ jsx("div", { className: "upl-progress", style: { width: "50%" } }) }) }) })
  ] }) });
};
