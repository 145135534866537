"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useLayoutEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollTestModeSettings = ({
  payrollId,
  disable,
  refreshPayroll,
  sx,
  testMode: initialTestMode
}) => {
  const [testMode, setTestMode] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showMessage] = useMessage();
  useLayoutEffect(() => {
    setTestMode(initialTestMode);
  }, [initialTestMode]);
  const updateTestMode = useCallback(
    (enabled) => __async(void 0, null, function* () {
      setUpdating(true);
      setTestMode(enabled);
      try {
        yield PayrollAPI.setPayrollTestMode(payrollId, enabled);
        yield refreshPayroll();
      } catch (error) {
        showMessage(`Test mode update failed. ${nestErrorMessage(error)}`, "error");
      } finally {
        setUpdating(false);
      }
    }),
    [payrollId, refreshPayroll, showMessage]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { children: "Test mode" }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: "When enabled, your RTI documents will be sent to the HMRC test service instead of the live service." }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5, mt: spacing.m10 }, children: [
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: testMode,
          name: "hrmc-test-mode",
          onChange: (_e, enabled) => {
            updateTestMode(enabled);
          },
          disabled: updating || disable
        }
      ),
      updating && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Updating..." }),
      !updating && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: [
        "Test mode is ",
        testMode ? "enabled" : "disabled"
      ] })
    ] })
  ] });
};
