"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Autocomplete, Box, Paper } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { ClearIcon } from "@v2/components/theme-components/clear-icon.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { StyledRadioGroup } from "@v2/components/theme-components/styled-radio-group.component";
import { Typography } from "@v2/components/typography/typography.component";
import { getPublicHolidayCalendarOptions } from "@v2/feature/absence/absence.util";
import { HolidayCalendarAPI } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { StyledAuto, StyledAutoTextfield } from "@v2/styles/autocomplete.styles";
import { StyledMenuItem } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { iconSize } from "@v2/styles/table.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE } from "@/lib/routes";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
const validationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("validation.requiredField")),
  country: yup.string().nullable().notRequired()
});
export const CreateHolidayCalendarDrawer = ({ isOpen, setIsOpen, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(CreateHolidayCalendarDrawerContent, { refresh, setIsOpen })
    }
  ) });
};
const CreateHolidayCalendarDrawerContent = ({ refresh, setIsOpen }) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [copyCountry, setCopyCountry] = useState(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const countriesOptions = useMemo(() => getPublicHolidayCalendarOptions({ excludeNone: true }), []);
  const createCalendar = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const data = {
          name: values.name,
          country: values.country ? values.country : null
        };
        const calendarId = yield HolidayCalendarAPI.createHolidayCalendar(data);
        yield refresh();
        setIsOpen(false);
        history.push(generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, { calendarId }));
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setLoading(false);
    }),
    [showMessage, polyglot, refresh, history, setIsOpen]
  );
  const formik = useFormik({
    initialValues: {
      name: "",
      country: null
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: createCalendar
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("HolidayCalendarModule.newCalendar") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("General.name"),
        name: "name",
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && Boolean(formik.errors.name),
        helperText: formik.touched.name && formik.errors.name,
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(
      StyledRadioGroup,
      {
        options: [
          {
            label: polyglot.t("HolidayCalendarModule.createNewCalendar"),
            value: "false"
          },
          {
            label: polyglot.t("HolidayCalendarModule.copyExisting"),
            value: "true"
          }
        ],
        selectedValue: copyCountry.toString(),
        labelPlacement: "end",
        onChange: (e) => {
          const copy = e.target.value === "true";
          if (!copy) formik.setFieldValue("country", null);
          setCopyCountry(copy);
        },
        radioSx: {
          borderBottom: 0,
          justifyContent: "normal",
          py: 0
        }
      }
    ),
    copyCountry && /* @__PURE__ */ jsx(
      Autocomplete,
      {
        options: countriesOptions,
        value: countriesOptions.find(({ value }) => value === formik.values.country),
        getOptionLabel: (option) => option.label,
        onChange: (_, x) => formik.setFieldValue("country", x == null ? void 0 : x.value),
        renderOption: (props, option) => {
          return option.value === formik.values.country ? /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: option.label }),
            /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
          ] }) })) : /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: option.label }));
        },
        renderInput: (params) => /* @__PURE__ */ jsx(
          StyledAutoTextfield,
          __spreadProps(__spreadValues({}, params), {
            variant: "standard",
            InputLabelProps: { shrink: true },
            placeholder: polyglot.t("HolidayCalendarModule.holidayCalendar"),
            size: "small",
            label: polyglot.t("HolidayCalendarModule.copyFromSystem"),
            name: "country",
            error: formik.touched.country && !!formik.errors.country,
            helperText: formik.touched.country && formik.errors.country
          })
        ),
        PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children }),
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, {}),
        clearIcon: /* @__PURE__ */ jsx(ClearIcon, {})
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.create"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
