"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { SwitchButton } from "@v2/components/split-switch-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { Typography } from "@/v2/components/typography/typography.component";
import { RolesOptions } from "@/v2/feature/app-layout/features/v2/menu/menu.interface";
import { radius } from "@/v2/styles/radius.styles";
import "@/v2/scss/components/top-navigation-menu.scss";
export const TopNavigationMenu = ({
  roles,
  setActiveRole,
  activeRole
}) => {
  const { polyglot } = usePolyglot();
  const showRoles = useMemo(() => roles.length > 1, [roles]);
  return showRoles ? /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        width: "100%",
        height: "30px",
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: "flex",
        alignItems: "center"
      },
      children: roles.map((r) => /* @__PURE__ */ jsx(
        SwitchButton,
        {
          isActive: r === activeRole,
          color: themeColors.DarkGrey,
          action: () => setActiveRole(r),
          children: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              sx: { color: r === activeRole ? themeColors.white : themeColors.DarkGrey },
              className: r === activeRole ? "tnm-switch-active-text" : "tnm-switch-text",
              children: RolesOptions(polyglot)[r]
            }
          )
        },
        r
      ))
    }
  ) : /* @__PURE__ */ jsx(Fragment, {});
};
