"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { HolidayDrawer } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holiday-drawer.component";
import { HolidaysTable } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holidays-table.component";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const HolidayCalendarHolidaysSection = ({ holidayCalendar, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("HolidayCalendarModule.calendar"),
      headerWidth: "100%",
      contentWidth: "100%",
      buttons: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => {
              setIsOpen(true);
            },
            children: polyglot.t("HolidayCalendarModule.newHoliday")
          }
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      HolidaysTable,
                      {
                        calendarId: holidayCalendar.id,
                        holidays: (_a = holidayCalendar.holidays) != null ? _a : [],
                        refresh
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(HolidayDrawer, { isOpen, setIsOpen, calendarId: holidayCalendar.id, refresh })
      ]
    }
  );
};
