"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, List, ListItem } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceContractLength } from "@v2/feature/device/device.interface";
import { DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS } from "@v2/feature/device/device.util";
import { buttonBoxSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const SuperAdminEditOrderForm = ({ deviceOrder, devices, refresh }) => {
  var _a, _b, _c, _d, _e;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      deviceId: (_a = deviceOrder.deviceId) != null ? _a : 0,
      price: deviceOrder.price,
      contractLength: (_b = deviceOrder.contractLength) != null ? _b : DeviceContractLength.Months36,
      phoneNumber: deviceOrder.phoneNumber,
      deliveryAddress: (_c = deviceOrder.deliveryAddress) != null ? _c : "",
      deliveryDate: (_d = deviceOrder.deliveryDate) != null ? _d : new LocalDate().toDateString()
    },
    validationSchema: Yup.object({
      deviceId: Yup.number().required("Device id is required").typeError("You must specify a number."),
      price: Yup.number().required("Price is required").typeError("You must specify a number."),
      contractLength: Yup.number().required("Contract Length is required").typeError("You must specify a number."),
      phoneNumber: Yup.string().nullable().notRequired(),
      deliveryAddress: Yup.string().required("Delivery Address is required"),
      deliveryDate: Yup.string().test(dateFieldTest).required("Delivery Date is required")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      const { deviceId, price, contractLength, phoneNumber, deliveryAddress, deliveryDate } = values;
      try {
        setLoading(true);
        yield DeviceAPI.updateDeviceOrderAsSuperadmin(deviceOrder.id, {
          deviceId,
          price: Number(price),
          contractLength,
          phoneNumber,
          deliveryAddress,
          deliveryDate
        });
        showMessage("Order successfully updated.", "success");
        yield refresh();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Select model" }),
      devices && /* @__PURE__ */ jsx(
        List,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            maxHeight: "200px",
            overflowY: "auto"
          },
          children: devices.map((device) => /* @__PURE__ */ jsxs(
            ListItem,
            {
              onClick: () => {
                formik.setFieldValue("deviceId", device.id);
              },
              sx: {
                border: device.id === formik.values.deviceId ? "2px solid #a0a0a0" : "1px solid #e0e0e0",
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                gap: 1
              },
              children: [
                /* @__PURE__ */ jsxs(Box, { sx: { width: "40%" }, children: [
                  /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
                    " ",
                    device.modelName
                  ] }),
                  /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                    " Id: ",
                    device.id
                  ] }),
                  /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                    " ",
                    device.serialNumber
                  ] })
                ] }),
                /* @__PURE__ */ jsxs(Box, { children: [
                  /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                    "RAM: ",
                    device.ram,
                    " GB"
                  ] }),
                  /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                    "Storage: ",
                    device.storage,
                    " GB"
                  ] })
                ] }),
                /* @__PURE__ */ jsxs(Box, { children: [
                  /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                    "CPU: ",
                    device.cpu,
                    "-cores"
                  ] }),
                  /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
                    "Screen size: ",
                    device.screenSize,
                    '"'
                  ] })
                ] })
              ]
            }
          ))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_e = formik.values.deliveryDate) != null ? _e : new LocalDate().toDateString(),
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("deliveryDate", value);
          }
        },
        name: "deliveryDate",
        label: "Delivery date",
        error: !!formik.touched.deliveryDate && !!formik.errors.deliveryDate,
        helperText: formik.touched.deliveryDate && formik.errors.deliveryDate
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Delivery Address",
        name: "deliveryAddress",
        value: formik.values.deliveryAddress,
        onChange: formik.handleChange,
        error: formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress),
        helperText: formik.touched.deliveryAddress && formik.errors.deliveryAddress,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Phone Number",
        name: "phoneNumber",
        value: formik.values.phoneNumber,
        onChange: formik.handleChange,
        error: formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber),
        helperText: formik.touched.phoneNumber && formik.errors.phoneNumber,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "contractLength",
        label: "Contract Length",
        options: DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS,
        value: formik.values.contractLength,
        onChange: formik.handleChange,
        error: formik.touched.contractLength && Boolean(formik.errors.contractLength),
        helperText: formik.touched.contractLength && formik.errors.contractLength
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Price",
        name: "price",
        value: formik.values.price,
        onChange: formik.handleChange,
        error: formik.touched.price && Boolean(formik.errors.price),
        helperText: formik.touched.price && formik.errors.price,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Update order", loading, fullWidth: true, sizeVariant: "large", colorVariant: "primary" }) })
  ] }) });
};
