"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { CircularProgressChart } from "@v2/feature/absence/me/policies/policy-breakdown/components/circular-progress-chart.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { round2Digits } from "@v2/util/number.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsencePolicyAllowanceType } from "@/v2/feature/absence/absence.interface";
import { convertMinutesToClockHours, isHourlyPolicy } from "@/v2/feature/absence/absence.util";
import { themeColors } from "@/v2/styles/colors.styles";
export const PolicyAnalytics = ({ primaryBalance }) => {
  const { polyglot } = usePolyglot();
  const userTotalAllowance = useMemo(() => {
    var _a, _b, _c;
    if (!((_a = primaryBalance.proRatedAbsenceAllowance) == null ? void 0 : _a.totalProratedAllowance)) return 0;
    return primaryBalance.proRatedAbsenceAllowance.totalProratedAllowance + ((_b = primaryBalance.carryOver.remaining) != null ? _b : 0) + ((_c = primaryBalance.oneOffAdjustment) != null ? _c : 0);
  }, [primaryBalance]);
  const percentage = useMemo(() => {
    return !(primaryBalance == null ? void 0 : primaryBalance.currentBalance) || !primaryBalance.userAllowance ? 0 : primaryBalance.currentBalance * 100 / userTotalAllowance;
  }, [primaryBalance, userTotalAllowance]);
  const unitsLeft = useMemo(() => {
    if (!primaryBalance) return 0;
    if (primaryBalance.isOnRegularSchedule && primaryBalance.currentBalanceInDays && primaryBalance.currentAverageWorkDayLength && !isHourlyPolicy({ allowanceType: primaryBalance.policyAllowanceType }))
      return polyglot.t("PolicyAnalytics.numDays", {
        smart_count: primaryBalance.currentBalanceInDays
      });
    return convertMinutesToClockHours(primaryBalance.currentBalance, polyglot);
  }, [primaryBalance, polyglot]);
  const unitsTaken = useMemo(() => {
    if (!primaryBalance) return 0;
    if (primaryBalance.isOnRegularSchedule && primaryBalance.currentAverageWorkDayLength && !isHourlyPolicy({ allowanceType: primaryBalance.policyAllowanceType }))
      return polyglot.t("PolicyAnalytics.numDays", {
        smart_count: round2Digits(primaryBalance.unitsTaken.total / primaryBalance.currentAverageWorkDayLength)
      });
    return convertMinutesToClockHours(primaryBalance == null ? void 0 : primaryBalance.unitsTaken.total, polyglot);
  }, [primaryBalance, polyglot]);
  const balanceInDays = useMemo(() => {
    return primaryBalance.currentBalanceInDays;
  }, [primaryBalance]);
  if (!primaryBalance) return null;
  return /* @__PURE__ */ jsx(Fragment, { children: primaryBalance.policyAllowanceType !== AbsencePolicyAllowanceType.Unlimited && primaryBalance.policyAllowance !== null && unitsLeft !== null ? /* @__PURE__ */ jsxs(Box, { sx: { position: "relative" }, children: [
    /* @__PURE__ */ jsx(
      CircularProgressChart,
      {
        value: percentage,
        color: primaryBalance.policyColor,
        size: 168,
        thickness: 3,
        baseColor: themeColors.white
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        },
        children: balanceInDays !== null && (primaryBalance == null ? void 0 : primaryBalance.isOnRegularSchedule) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "block", textAlign: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: primaryBalance.currentAverageWorkDayLength ? polyglot.t("PolicyAnalytics.numDays", { smart_count: balanceInDays ? balanceInDays : 0 }) : convertMinutesToClockHours(primaryBalance.currentBalance, polyglot) }),
          /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.DarkGrey, width: "100px" }, children: primaryBalance.policyName })
        ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "block", textAlign: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: primaryBalance.currentAverageWorkDayLength ? polyglot.t("PolicyAnalytics.numDays", { smart_count: balanceInDays ? balanceInDays : 0 }) : convertMinutesToClockHours(primaryBalance.currentBalance, polyglot) }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: polyglot.t("PolicyAnalytics.left", { units: unitsLeft }) }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, width: "100px", alignSelf: "center" }, children: primaryBalance.policyName })
        ] })
      }
    )
  ] }) : primaryBalance.policyAllowanceType === AbsencePolicyAllowanceType.Unlimited ? /* @__PURE__ */ jsxs(Box, { sx: { display: "block", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("PolicyAnalytics.taken", { units: unitsTaken }) }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: primaryBalance.policyName })
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "block", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("PolicyAnalytics.taken", { units: unitsTaken }) }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: primaryBalance.policyName })
  ] }) });
};
