"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Stack, styled } from "@mui/material";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  ReviewerTypesOrderMap,
  getReviewerTypesChips
} from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { QuestionTypeMap } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
import { stripHtml } from "@/v2/util/string.util";
export const ReviewQuestionBankViewModal = ({
  isOpen,
  setIsOpen,
  setIsEditOpen,
  onClose,
  question
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(ReviewQuestionBankViewContent, { question, setIsEditOpen }) });
};
const ReviewQuestionBankViewContent = ({
  question,
  setIsEditOpen
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g24
        },
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Question" }),
          (question == null ? void 0 : question.reviewerSelect) && /* @__PURE__ */ jsx(
            ViewItem,
            {
              title: "Who should respond to this question?",
              value: /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center", gap: spacing.g4 }, children: question == null ? void 0 : question.reviewerSelect.sort((a, b) => ReviewerTypesOrderMap[a] - ReviewerTypesOrderMap[b]).map((r) => {
                var _a, _b, _c;
                const hideVisibility = r !== ReviewerTypes.Self && (r === ReviewerTypes.Manager && ((_a = question == null ? void 0 : question.visibilitySettings) == null ? void 0 : _a.hideManagerResult) || r === ReviewerTypes.Upward && ((_b = question == null ? void 0 : question.visibilitySettings) == null ? void 0 : _b.hideUpwardResult) || r === ReviewerTypes.Peer && ((_c = question == null ? void 0 : question.visibilitySettings) == null ? void 0 : _c.hidePeerResult));
                return getReviewerTypesChips(hideVisibility)[r];
              }) })
            }
          ),
          (question == null ? void 0 : question.questionMain) && /* @__PURE__ */ jsx(ViewItem, { title: "Question text", value: stripHtml(question == null ? void 0 : question.questionMain) }),
          (question == null ? void 0 : question.questionSelf) && /* @__PURE__ */ jsx(ViewItem, { title: "Question Self", value: stripHtml(question == null ? void 0 : question.questionSelf) }),
          (question == null ? void 0 : question.factor) && /* @__PURE__ */ jsx(ViewItem, { title: "Factor", value: question == null ? void 0 : question.factor }),
          (question == null ? void 0 : question.type) && /* @__PURE__ */ jsx(ViewItem, { title: "Type", value: QuestionTypeMap[question.type] }),
          question && /* @__PURE__ */ jsx(
            ViewItem,
            {
              title: "Created by",
              value: question.companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
                /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "1.2em", width: "1.2em", padding: ".25em", "aria-label": "Payroll logo" }),
                "Zelt"
              ] }) : (question == null ? void 0 : question.createdBy) ? /* @__PURE__ */ jsx(UserCell, { userId: question.createdBy }) : /* @__PURE__ */ jsx(EmptyCell, {})
            }
          ),
          (question == null ? void 0 : question.createdAt) && /* @__PURE__ */ jsx(
            ViewItem,
            {
              title: "Created on",
              value: new LocalDate(question.createdAt).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" })
            }
          )
        ]
      }
    ),
    question && question.companyId !== null && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => setIsEditOpen(true),
        "aria-label": "Edit question",
        children: "Edit"
      }
    ) })
  ] });
};
const ViewItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.g8};
`;
const ViewItem = ({ title, value }) => {
  return /* @__PURE__ */ jsxs(ViewItemContainer, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: title }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value })
  ] });
};
