"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { NumberCell } from "@v2/components/table/number-cell.component";
import {
  getOpeningBalanceColumns,
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { ViewUserCustomBenefitDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/view-user-custom-benefit-drawer.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { keyBy } from "lodash";
import { useParams } from "react-router-dom";
import { BENEFITS_COMPANY_ROUTE } from "@/lib/routes";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CustomBenefitEndpoints } from "@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getTabFilter = (polyglot) => [
  { name: polyglot.t("General.member"), value: "Member" },
  { name: polyglot.t("BenefitModule.notIncluded"), value: "Not included" },
  { name: polyglot.t("General.all"), value: "All" }
];
export const CustomBenefitEmployeeList = ({
  loading,
  customBenefits
}) => {
  const params = useParams();
  const customBenefitId = Number(params.benefitId);
  const {
    data: userCustomBenefits,
    mutate: refreshUserCustomBenefits
  } = useApiClient(CustomBenefitEndpoints.getAllUserCustomBenefits(customBenefitId), { suspense: false });
  const { polyglot } = usePolyglot();
  const currentBenefit = useMemo(() => customBenefits == null ? void 0 : customBenefits.find((c) => c.id === customBenefitId), [
    customBenefits,
    customBenefitId
  ]);
  const isLoan = useMemo(() => isLoanBenefit(currentBenefit == null ? void 0 : currentBenefit.type), [currentBenefit]);
  const isRecurring = useMemo(() => isRecurringBenefit(currentBenefit == null ? void 0 : currentBenefit.type), [currentBenefit]);
  const [filterValue, setFilterValue] = useState("Member");
  const [searchInput, setSearchInput] = useState("");
  const [selectedUserCustomBenefit, setSelectedUserCustomBenefit] = useState(null);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const { nonTerminatedCachedUsers } = useCachedUsers({ refresh: true });
  const userCustomBenefitsByUserId = useMemo(
    () => userCustomBenefits ? keyBy(userCustomBenefits, "userId") : {},
    [userCustomBenefits]
  );
  const refreshUserMembers = useCallback(() => __async(void 0, null, function* () {
    if (refreshUserCustomBenefits) yield refreshUserCustomBenefits();
  }), [refreshUserCustomBenefits]);
  const filteredUserRecords = useMemo(() => {
    function sortUsers(a, b) {
      const aIncluded = !!userCustomBenefitsByUserId[a.userId];
      const bIncluded = !!userCustomBenefitsByUserId[b.userId];
      if (aIncluded && !bIncluded) return -1;
      if (!aIncluded && bIncluded) return 1;
      return 0;
    }
    let filteredUserRecords2 = [...nonTerminatedCachedUsers];
    if (filterValue !== "All") {
      filteredUserRecords2 = filteredUserRecords2.filter((user) => {
        if (filterValue === "Member") return userCustomBenefitsByUserId[user.userId];
        if (filterValue === "Not included") return !userCustomBenefitsByUserId[user.userId];
        return true;
      });
    }
    if (searchInput) {
      const lowerSearch = searchInput.toLocaleLowerCase();
      filteredUserRecords2 = filteredUserRecords2.filter((u) => u.displayName.toLocaleLowerCase().includes(lowerSearch));
    }
    return filteredUserRecords2.sort(sortUsers).map(
      (user) => user.userId && userCustomBenefitsByUserId[user.userId] ? __spreadProps(__spreadValues({}, userCustomBenefitsByUserId[user.userId]), { included: true }) : { customBenefit: currentBenefit, customBenefitId, userId: user.userId, included: false }
    );
  }, [currentBenefit, customBenefitId, nonTerminatedCachedUsers, filterValue, searchInput, userCustomBenefitsByUserId]);
  const columnData = useMemo(() => {
    const openingBalanceColumns = [];
    const hasOpeningBalance = isUsingOpeningBalance(currentBenefit == null ? void 0 : currentBenefit.type);
    if (hasOpeningBalance)
      openingBalanceColumns.push(...getOpeningBalanceColumns(isLoan, polyglot));
    return [
      {
        header: () => polyglot.t("General.name"),
        accessorFn: (row) => row,
        id: "userId",
        enableSorting: false,
        cell: (info) => {
          const user = info.getValue();
          return /* @__PURE__ */ jsx(UserCell, { userId: user.userId });
        }
      },
      ...openingBalanceColumns,
      ...isRecurring ? [
        {
          header: () => polyglot.t("BenefitModule.employerContribution"),
          accessorFn: (row) => row,
          id: "employerContribution",
          enableSorting: false,
          cell: (userObj) => {
            const userBenefit = userObj.getValue();
            return /* @__PURE__ */ jsx(
              NumberCell,
              {
                value: (userBenefit == null ? void 0 : userBenefit.employerContribution) ? formatMoney({
                  amount: userBenefit.employerContribution,
                  asDecimal: true
                }) : null
              }
            );
          }
        },
        {
          header: () => polyglot.t("BenefitModule.employeeContribution"),
          accessorFn: (row) => row,
          id: "employeeContribution",
          enableSorting: false,
          cell: (userObj) => {
            const userBenefit = userObj.getValue();
            return /* @__PURE__ */ jsx(
              NumberCell,
              {
                value: (userBenefit == null ? void 0 : userBenefit.employeeContribution) ? formatMoney({
                  amount: userBenefit.employeeContribution,
                  asDecimal: true
                }) : null
              }
            );
          }
        },
        {
          header: () => polyglot.t("BenefitModule.dependants"),
          accessorFn: (row) => row,
          id: "dependants",
          enableSorting: false,
          cell: (userObj) => {
            const userBenefit = userObj.getValue();
            return (userBenefit == null ? void 0 : userBenefit.dependants) ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: userBenefit.dependants }) : /* @__PURE__ */ jsx(EmptyCell, {});
          }
        }
      ] : [],
      {
        header: () => polyglot.t("General.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: (userObj) => {
          const userBenefit = userObj.getValue();
          return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: userBenefit.included ? polyglot.t("General.member") : polyglot.t("BenefitModule.notIncluded") });
        }
      }
    ];
  }, [isLoan, isRecurring, currentBenefit, polyglot]);
  const handleRowClick = useCallback((row) => {
    setSelectedUserCustomBenefit(row.original);
    setIsViewOpen(true);
  }, []);
  return currentBenefit ? /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: currentBenefit.name, showBack: true, backPath: BENEFITS_COMPANY_ROUTE }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "space-between",
            gap: spacing.g10,
            alignItems: "center"
          },
          children: /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              filters: getTabFilter(polyglot),
              setFilterValue,
              filterValue,
              hasSearch: true,
              onFilterChange: ({ filterValue: filterValue2, searchInput: searchInput2 }) => {
                setFilterValue(filterValue2);
                setSearchInput(searchInput2);
              }
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m15 }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredUserRecords,
          columnData,
          loading,
          hidePagination: true,
          rowClick: handleRowClick
        }
      ) }),
      selectedUserCustomBenefit && /* @__PURE__ */ jsx(
        ViewUserCustomBenefitDrawer,
        {
          isOpen: isViewOpen,
          setIsOpen: setIsViewOpen,
          userBenefit: selectedUserCustomBenefit,
          onClose: () => {
            setSelectedUserCustomBenefit(null);
            setIsViewOpen(false);
          },
          refresh: refreshUserMembers
        }
      )
    ] })
  ] }) : null;
};
