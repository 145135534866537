"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { ListItemIcon, ListItemText } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Settings } from "@/images/fields/Settings.svg";
import { ReactComponent as SettingsActive } from "@/images/side-bar-icons/SettingsFilled.svg";
import {
  SETTINGS_BILLING_ROUTE,
  SETTINGS_COMPANY_ROUTE,
  SETTINGS_DEPARTMENTS_ROUTE,
  SETTINGS_NOTIFICATIONS_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  SETTINGS_SITES_ROUTE
} from "@/lib/routes";
import { activeSubStyle, ListItemStyle } from "@/v2/styles/list-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { truncateWithEllipses } from "@/v2/util/string.util";
const SETTINGS_ROUTES = [
  SETTINGS_COMPANY_ROUTE,
  SETTINGS_SITES_ROUTE,
  SETTINGS_DEPARTMENTS_ROUTE,
  SETTINGS_BILLING_ROUTE,
  SETTINGS_NOTIFICATIONS_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  "/settings/permission-groups",
  "/settings/import"
];
export const CompanyUserHeader = ({ headerName, redirectPath }) => {
  const routerHistory = useHistory();
  const { pathname } = useLocation();
  return /* @__PURE__ */ jsxs(
    ListItemStyle,
    {
      onClick: () => routerHistory.push(redirectPath),
      sx: __spreadProps(__spreadValues({}, SETTINGS_ROUTES.includes(pathname) && activeSubStyle), {
        width: "100%",
        mb: spacing.mb10
      }),
      children: [
        /* @__PURE__ */ jsx(ListItemIcon, { sx: { marginRight: spacing.m10, minWidth: "16px" }, children: SETTINGS_ROUTES.includes(pathname) ? /* @__PURE__ */ jsx(SettingsActive, {}) : /* @__PURE__ */ jsx(Settings, {}) }),
        /* @__PURE__ */ jsx(ListItemText, { disableTypography: true, primary: truncateWithEllipses(headerName) })
      ]
    },
    headerName
  );
};
