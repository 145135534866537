"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { UserSelect } from "@v2/components/user-select-type/user-select.component";
import { UserSelectFiltersOptions } from "@v2/components/user-select-type/user-select.interface";
import { PermissionGroupAPI } from "@v2/feature/permission-group/permission-group.api";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const convertUserSummariesToIds = (userSummaries) => {
  let permissionGroupMemberIds;
  if (!userSummaries || userSummaries.length === 0) permissionGroupMemberIds = [];
  else if (Number.isInteger(userSummaries[0])) permissionGroupMemberIds = userSummaries;
  else permissionGroupMemberIds = userSummaries.map((m) => m.userId);
  return permissionGroupMemberIds;
};
export const EditPermissionGroupTargetsDrawer = ({
  isOpen,
  setIsOpen,
  permissionGroup,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    EditPermissionGroupTargetsDrawerContent,
    {
      permissionGroup,
      refresh,
      setIsOpen
    }
  ) });
};
export const EditPermissionGroupTargetsDrawerContent = ({
  permissionGroup,
  refresh,
  setIsOpen
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [selectedOption, setSelectedOption] = useState(
    (_a = permissionGroup.targetsExclRule) != null ? _a : UserSelectFiltersOptions.None
  );
  const [customRule, setCustomRule] = useState((_b = permissionGroup.customExclRule) != null ? _b : "");
  const [selectedUsers, setSelectedUsers] = useState(
    (_c = permissionGroup.exclusions) != null ? _c : []
  );
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (membersRule, customRule2, selectedMembersIds) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield PermissionGroupAPI.updatePermissionGroupDetails(permissionGroup.id, {
          name: permissionGroup.name,
          description: permissionGroup.description,
          targetsExclRule: membersRule,
          customExclRule: customRule2,
          members: convertUserSummariesToIds(permissionGroup.members),
          exclusions: selectedMembersIds,
          inclusions: []
        });
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          `${polyglot.t("EditPolicyMembersDrawerContent.errorMessages.updateFailure")}. ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [
      permissionGroup.id,
      refresh,
      setIsOpen,
      showMessage,
      polyglot,
      permissionGroup.name,
      permissionGroup.members,
      permissionGroup.description
    ]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Edit exclusions" }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("EditPolicyMembersDrawerContent.whoSelect"),
        selectedLabel: "Selected employees",
        value: convertUserSummariesToIds(selectedUsers),
        onChange: (userIds, filterValue, customRule2) => {
          setSelectedUsers([...userIds]);
          setValidationMessage("");
          setSelectedOption(filterValue != null ? filterValue : UserSelectFiltersOptions.None);
          setCustomRule(customRule2 != null ? customRule2 : "");
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        error: !!validationMessage,
        helperText: !!validationMessage && validationMessage,
        initialFilterValue: (_d = permissionGroup.targetsExclRule) != null ? _d : UserSelectFiltersOptions.None,
        ruleString: customRule,
        excludeEveryone: true,
        excludeCustomRule: true,
        allowEmpty: true
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m20 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        type: "button",
        onClick: () => __async(void 0, null, function* () {
          if (selectedOption === UserSelectFiltersOptions.None)
            setValidationMessage(polyglot.t("EditPolicyMembersDrawerContent.errorMessages.selectOption"));
          else if (selectedOption === UserSelectFiltersOptions.CustomRule && !customRule)
            setValidationMessage(polyglot.t("EditPolicyMembersDrawerContent.errorMessages.enterCustomRule"));
          else {
            const membersRule = selectedOption != null ? selectedOption : UserSelectFiltersOptions.Everyone;
            const selectedCustomRule = selectedOption === UserSelectFiltersOptions.CustomRule ? customRule : null;
            yield onSubmit(membersRule, selectedCustomRule, selectedUsers);
          }
        }),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] });
};
