"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppIconsList = ({ stubs, size = "20px" }) => {
  const [apps, setApps] = useState();
  useEffect(() => {
    AppIntegrationAPI.availableApps().then(setApps);
  }, []);
  const images = useMemo(() => {
    const appName = (stub) => {
      var _a, _b;
      return (_b = (_a = apps == null ? void 0 : apps.find((a) => a.stub === stub)) == null ? void 0 : _a.name) != null ? _b : "";
    };
    return stubs == null ? void 0 : stubs.map((stub) => /* @__PURE__ */ jsx(
      "img",
      {
        src: `/app-icons-v2/images/${stub}.png`,
        style: { maxHeight: size, maxWidth: size },
        alt: "",
        title: appName(stub)
      },
      stub
    ));
  }, [apps, stubs, size]);
  return /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", alignItems: "center", flexWrap: "wrap", gap: spacing.g5 }, children: images });
};
