"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as External } from "@/images/icons/external-link-arrow.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Connected } from "@/images/side-bar-icons/Ok.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { ReactComponent as NotConnected } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  SETTINGS_ATTENDANCE_SCHEDULE_ROUTE,
  SETTINGS_BENEFITS_DETAILS_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ROUTE
} from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortBoolean, sortString } from "@/v2/components/table/table-sorting.util";
import { AbsenceEndpoints } from "@/v2/feature/absence/absence.api";
import { AttendanceEndpoints } from "@/v2/feature/attendance/attendance.api";
import { CustomBenefitAPI } from "@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { PayrollIntegrationSettingsEditDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/integrations-settings/payroll-integration-settings-drawer.component";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const PayrollCompanySettingsIntegrations = () => {
  const { polyglot } = usePolyglot();
  const [isEditIntegrationOpen, setEditIntegrationOpen] = useState(false);
  const [editIntegration, setEditIntegration] = useState(null);
  const [integrations, setIntegrations] = useState();
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const updateIntegrations = useCallback((kind, values) => {
    setIntegrations((current) => {
      if (!current) return values;
      return [...current.filter((value) => value.kind !== kind), ...values];
    });
  }, []);
  const {
    data: absencePolicies,
    mutate: refreshAbsencePolicies
  } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });
  const {
    data: attendanceSchedules,
    mutate: refreshAttendanceSchedules
  } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const {
    data: expenses,
    mutate: refreshExpenses
  } = useApiClient(PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId(), { suspense: false });
  useEffect(() => {
    if (!absencePolicies) return;
    const absenceIntegrations = absencePolicies.map((absencePolicy) => ({
      kind: "absence",
      absencePolicy,
      id: absencePolicy.id,
      name: absencePolicy.name,
      hasPayrollIntegration: (absencePolicy.inPayrollOffboarding || absencePolicy.inPayrollRequests) && !!absencePolicy.payCode
    }));
    updateIntegrations("absence", absenceIntegrations);
  }, [absencePolicies, updateIntegrations]);
  useEffect(() => {
    if (!attendanceSchedules) return;
    const attendanceIntegrations = attendanceSchedules.map((attendanceSchedule) => ({
      kind: "attendance",
      attendanceSchedule,
      id: attendanceSchedule.id,
      name: attendanceSchedule.name,
      hasPayrollIntegration: attendanceSchedule.includedInPayroll && !!attendanceSchedule.payCode
    }));
    updateIntegrations("attendance", attendanceIntegrations);
  }, [attendanceSchedules, updateIntegrations]);
  useEffect(() => {
    if (!expenses) return;
    const expenseIntegrations = expenses.settings.map((expense) => ({
      kind: "expense",
      expense,
      id: expense.id,
      name: expense.name,
      hasPayrollIntegration: !!expense.payrolled && !!expense.paycode
    }));
    updateIntegrations("expense", expenseIntegrations);
  }, [expenses, updateIntegrations]);
  const refreshBenefits = useCallback(() => __async(void 0, null, function* () {
    try {
      const benefits = yield CustomBenefitAPI.getAllCustomBenefits();
      const benefitIntegrations = benefits.map((benefit) => ({
        kind: "benefit",
        name: benefit.name,
        benefit,
        id: benefit.id,
        hasPayrollIntegration: benefit.paymentMethod === "Payroll" && !!benefit.payCode
      }));
      updateIntegrations("benefit", benefitIntegrations);
    } catch (err) {
      showMessage(nestErrorMessage(err), "error");
    }
  }), [showMessage, updateIntegrations]);
  useEffect(() => {
    refreshBenefits();
  }, [refreshBenefits]);
  const integrationTypeLabel = useCallback(
    (kind) => {
      return {
        absence: () => polyglot.t("PayrollIntegrationSettings.integrationTypeAbsence"),
        attendance: () => polyglot.t("PayrollIntegrationSettings.integrationTypeAttendance"),
        benefit: () => polyglot.t("PayrollIntegrationSettings.integrationTypeBenefit"),
        expense: () => polyglot.t("PayrollIntegrationSettings.integrationTypeExpense")
      }[kind]();
    },
    [polyglot]
  );
  const navigateToIntegration = useCallback(
    (integration) => {
      const path = {
        absence: () => generatePath(SETTINGS_TIME_POLICY_DETAILS_ROUTE, { policyId: integration.id }),
        attendance: () => generatePath(SETTINGS_ATTENDANCE_SCHEDULE_ROUTE, { scheduleId: integration.id }),
        benefit: () => generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
          productType: "custom",
          category: integration.benefit.category,
          id: integration.id
        }),
        expense: () => generatePath(SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE, { id: integration.id })
      }[integration.kind]();
      routerHistory.push(path);
    },
    [routerHistory]
  );
  const columns = useMemo(() => {
    return [
      {
        id: "type",
        header: () => polyglot.t("PayrollIntegrationSettings.integrationType"),
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => integrationTypeLabel(item.kind)),
        cell: (c) => integrationTypeLabel(c.row.original.kind)
      },
      {
        id: "name",
        header: () => polyglot.t("PayrollIntegrationSettings.integrationName"),
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name, { sensitivity: "base" }),
        cell: (c) => c.row.original.name
      },
      {
        id: "status",
        header: () => polyglot.t("PayrollIntegrationSettings.integrationStatus"),
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.hasPayrollIntegration),
        cell: (c) => c.row.original.hasPayrollIntegration ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(Connected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Green })),
          polyglot.t("PayrollIntegrationSettings.integrationStatusConnected")
        ] }) : /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(NotConnected, __spreadValues({}, iconSize)),
          polyglot.t("PayrollIntegrationSettings.integrationStatusNotConnected")
        ] })
      },
      {
        id: "actions",
        header: () => "",
        accessorFn: (x) => x,
        enableSorting: false,
        cell: (c) => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "flex-end", gap: spacing.g5 }, children: [
          c.row.original.hasPayrollIntegration && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => {
                setEditIntegration(c.row.original);
                setEditIntegrationOpen(true);
              },
              children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
            }
          ),
          !c.row.original.hasPayrollIntegration && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => {
                setEditIntegration(c.row.original);
                setEditIntegrationOpen(true);
              },
              children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => {
                navigateToIntegration(c.row.original);
              },
              children: /* @__PURE__ */ jsx(External, __spreadValues({}, iconSize))
            }
          )
        ] })
      }
    ];
  }, [integrationTypeLabel, navigateToIntegration, polyglot]);
  useEffect(() => {
    if (!isEditIntegrationOpen) {
      setEditIntegration(null);
    }
  }, [isEditIntegrationOpen]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g20, my: spacing.my20 }, children: [
      /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { children: polyglot.t("PayrollIntegrationSettings.title") }),
      integrations && /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: integrations,
          columnData: columns,
          initialSort: [
            { id: "type", desc: true },
            { id: "name", desc: true }
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      PayrollIntegrationSettingsEditDrawer,
      {
        isOpen: isEditIntegrationOpen,
        setIsOpen: setEditIntegrationOpen,
        integration: editIntegration,
        refreshAbsences: refreshAbsencePolicies,
        refreshAttendances: refreshAttendanceSchedules,
        refreshBenefits,
        refreshExpenses
      }
    )
  ] });
};
