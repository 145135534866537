"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import {
  APP_GROUP_MANAGEMENT_DRAWER_MODES
} from "@/v2/feature/app-integration/app-integration.interface";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppGroupManagementMemberEditList = ({
  appStub,
  existingUser,
  mode,
  setUserListForMembershipDeletion,
  userListForMembershipDeletion,
  matchingUserForEmailOrId,
  groupMembers,
  listForMemberDeletion,
  groupOptionList,
  existingUserGroupList,
  formikSetFieldValue
}) => {
  const filteredGroupMembers = useCallback(
    (groupDetails, markedForDeletionList) => {
      if (appStub === "google-workspace")
        return Object.values(groupDetails).filter(
          (eachMember) => eachMember.email && !markedForDeletionList.includes(eachMember.email)
        );
      if (appStub === "jira")
        return Object.values(groupDetails).filter(
          (eachMember) => eachMember.accountType === "atlassian" && !markedForDeletionList.includes(eachMember.accountId)
        );
      if (appStub === "microsoft365")
        return Object.values(groupDetails).filter(
          (eachMember) => !markedForDeletionList.includes(eachMember.id)
        );
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appStub]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && groupMembers && filteredGroupMembers(groupMembers, listForMemberDeletion).map((member) => {
      let identifier;
      if (appStub === "google-workspace") {
        identifier = member.email;
      }
      if (appStub === "jira") {
        identifier = member.accountId;
      }
      if (appStub === "microsoft365") {
        identifier = member.userPrincipalName;
      }
      const matchingUser = matchingUserForEmailOrId(identifier != null ? identifier : "");
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between", mb: "35px" }, children: [
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.title4), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5,
                mb: "10px",
                color: themeColors.DarkGrey
              }),
              children: [
                (matchingUser == null ? void 0 : matchingUser.userId) && /* @__PURE__ */ jsx(UserAvatar, { userId: +matchingUser.userId, size: "xxsmall" }),
                matchingUser == null ? void 0 : matchingUser.displayName
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5,
                mb: "10px",
                color: themeColors.DarkGrey
              }),
              children: identifier
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            onClick: () => {
              setUserListForMembershipDeletion([...userListForMembershipDeletion, member]);
            },
            children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
          }
        ) })
      ] });
    }),
    mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && groupMembers && filteredGroupMembers(groupMembers, listForMemberDeletion).length === 0 && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between", mb: "35px" }, children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.title4), {
          display: "flex",
          alignItems: "center",
          gap: spacing.m5,
          mb: "10px",
          color: themeColors.DarkGrey
        }),
        children: [
          mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && listForMemberDeletion.length > 0 && /* @__PURE__ */ jsx(Fragment, { children: "No members left to remove" }),
          mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.edit && listForMemberDeletion.length === 0 && /* @__PURE__ */ jsx(Fragment, { children: "No members" })
        ]
      }
    ) }) }),
    mode === APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup && existingUser && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: existingUser == null ? void 0 : existingUser.displayName }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        MultipleSelectCheckbox,
        {
          id: "group-select",
          limitTags: 5,
          options: groupOptionList,
          value: existingUserGroupList != null ? existingUserGroupList : [],
          onChange: (_, values) => {
            formikSetFieldValue("existingUser", existingUser, true);
            formikSetFieldValue("existingUserGroupList", values, true);
          },
          isOptionEqualToValue: (x, y) => x.value === y.value,
          getOptionLabel: ({ label }) => label,
          label: "Groups"
        }
      ) })
    ] })
  ] });
};
