"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { UserAddressSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CountrySelect } from "@/v2/components/country-select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const UserAddressSection = ({ onNext, sx, values, userId, form }) => {
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const rowModalMode = values ? "edit" : "add";
  const defaultFieldsVisibility = useMemo(() => {
    var _a, _b, _c, _d, _e;
    if (!form)
      return {
        addressLine1: false,
        addressLine2: false,
        city: false,
        postcode: false,
        country: false
      };
    const addressLine1Field = (_a = form.fields) == null ? void 0 : _a.find((f) => f.isDefault && f.fieldStub === "address.addressLine1");
    const addressLine2Field = (_b = form.fields) == null ? void 0 : _b.find((f) => f.isDefault && f.fieldStub === "address.addressLine2");
    const cityField = (_c = form.fields) == null ? void 0 : _c.find((f) => f.isDefault && f.fieldStub === "address.city");
    const postcodeField = (_d = form.fields) == null ? void 0 : _d.find((f) => f.isDefault && f.fieldStub === "address.postcode");
    const countryField = (_e = form.fields) == null ? void 0 : _e.find((f) => f.isDefault && f.fieldStub === "address.country");
    return {
      addressLine1: Boolean(addressLine1Field && !addressLine1Field.isHidden),
      addressLine2: Boolean(addressLine2Field && !addressLine2Field.isHidden),
      city: Boolean(cityField && !cityField.isHidden),
      postcode: Boolean(postcodeField && !postcodeField.isHidden),
      country: Boolean(countryField && !countryField.isHidden)
    };
  }, [form]);
  const validationSchema = useMemo(() => UserAddressSchema(polyglot), [polyglot]);
  const defaultAddressValues = useMemo(() => {
    return {
      addressLine1: "",
      city: "",
      postcode: "",
      country: "United Kingdom",
      effectiveDate: todaysDateShortISOString(),
      customUpdates: []
    };
  }, []);
  const formik = useFormik({
    // all default fields here are required, no need for clean up
    initialValues: values != null ? values : defaultAddressValues,
    validationSchema,
    onSubmit: (values2) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        let updated;
        if ("userAddressId" in values2) {
          updated = yield UserAddressAPI.update(userId, values2);
        } else {
          updated = yield UserAddressAPI.create(userId, values2);
        }
        onNext(updated);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.addressNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AddressForm.homeAddress") }),
    defaultFieldsVisibility.addressLine1 && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine1",
        label: polyglot.t("AddressForm.addressLine1"),
        value: formik.values.addressLine1,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("addressLine1", ""),
        helperText: hasSubmitted && formik.errors.addressLine1,
        error: hasSubmitted && !!formik.errors.addressLine1,
        maxLength: 30,
        autoFocus: true
      }
    ),
    defaultFieldsVisibility.addressLine2 && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine2",
        label: polyglot.t("AddressForm.addressLine2"),
        value: formik.values.addressLine2,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("addressLine2", ""),
        helperText: hasSubmitted && formik.errors.addressLine2,
        error: hasSubmitted && !!formik.errors.addressLine2,
        maxLength: 30
      }
    ),
    defaultFieldsVisibility.city && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "city",
        label: polyglot.t("AddressForm.city"),
        value: formik.values.city,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("city", ""),
        helperText: hasSubmitted && formik.errors.city,
        error: hasSubmitted && !!formik.errors.city,
        maxLength: 30
      }
    ),
    defaultFieldsVisibility.postcode && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "postcode",
        label: polyglot.t("AddressForm.postCode"),
        value: formik.values.postcode,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("postcode", ""),
        helperText: hasSubmitted && formik.errors.postcode,
        error: hasSubmitted && !!formik.errors.postcode,
        maxLength: 20
      }
    ),
    defaultFieldsVisibility.country && /* @__PURE__ */ jsx(
      CountrySelect,
      {
        name: "country",
        label: polyglot.t("AddressForm.country"),
        value: formik.values.country,
        onChange: (value) => formik.setFieldValue("country", value),
        disabled: saving,
        error: hasSubmitted && !!formik.errors.country,
        helperText: hasSubmitted && formik.errors.country
      }
    ),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values2) => formik.setFieldValue("customUpdates", values2),
        disabled: saving,
        formName: CustomProfileFormType.Address,
        rowModalMode,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
