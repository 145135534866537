"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { createElement } from "react";
import { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { groupBy } from "lodash";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import {
  APP_INTEGRATION_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE,
  APP_STORE_ROUTE
} from "@/lib/routes";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { ActionCard } from "@/v2/components/theme-components/action-card.component";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { appCurrentlyInstalled } from "@/v2/feature/app-integration/app-integration.util";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
function appStubFromHash(hash) {
  return hash.replace("#", "");
}
export const AppStorePage = () => {
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const routerState = routerLocation.state;
  const existingCategory = routerState == null ? void 0 : routerState.category;
  const [globalState, dispatch] = useContext(GlobalContext);
  const [filterString, setFilterString] = useState(existingCategory != null ? existingCategory : "Productivity");
  const [tabFilter, setTabFilter] = useState([]);
  const { getScopesContext } = useScopes();
  const [categorizedAvailableApps, setCategorizedAvailableApps] = useState();
  const {
    data: availableApps,
    isValidating: loadingAvailableApps
  } = useApiClient(AppIntegrationEndpoints.getAvailableApps(), { suspense: false });
  const {
    data: installedApps,
    isValidating: loadingInstalledApps
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });
  const [app, setApp] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isInCompanyPath = routerLocation.pathname.includes("company");
  useEffect(() => {
    (function() {
      return __async(this, null, function* () {
        var _a;
        const response = yield AuthAPI.getAuthMe(false);
        const authUser = (_a = response == null ? void 0 : response.user) != null ? _a : null;
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: authUser
        });
      });
    })();
  }, [dispatch]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const availableAppsByCategory = groupBy(availableApps, "category");
        const tabFiltersFromCategory = Object.keys(availableAppsByCategory).map((x) => ({
          name: x,
          value: x
        }));
        setTabFilter(tabFiltersFromCategory);
        setCategorizedAvailableApps(availableAppsByCategory);
      } catch (e) {
        showMessage("Could not load the list of apps.", "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [showMessage, availableApps, installedApps]);
  useEffect(() => {
    const appStubHash = appStubFromHash(routerLocation.hash);
    if (availableApps && availableApps.some((x) => x.stub === appStubHash)) {
      setApp(appStubHash);
    }
  }, [routerLocation.hash, availableApps]);
  useEffect(() => {
    const appStubHash = appStubFromHash(routerLocation.hash);
    if (existingCategory) {
      routerHistory.push({
        pathname: generatePath(APP_STORE_ROUTE),
        state: { category: existingCategory }
      });
      return;
    }
    if (availableApps) {
      if (app) {
        if (appStubHash !== app) {
          routerHistory.push(`${generatePath(APP_STORE_ROUTE)}/#${app}`);
        }
      } else {
        if (appStubHash !== "") {
          routerHistory.push(generatePath(APP_STORE_ROUTE));
        }
      }
    }
  }, [availableApps, app, routerLocation.hash, routerHistory, existingCategory]);
  useEffect(() => {
    const scrollToElement = () => {
      const myEl = document.getElementById(filterString);
      if (myEl) {
        myEl.scrollIntoView({
          block: "center",
          // Start, center, end, or nearest. Defaults to start.
          behavior: "smooth"
        });
      }
    };
    if (!document.getElementById(filterString)) {
      const delayTimeout = setTimeout(scrollToElement, 800);
      return () => clearTimeout(delayTimeout);
    } else {
      scrollToElement();
    }
  }, [filterString]);
  const onTitleClick = (app2) => {
    const isAuthorised = app2 == null ? void 0 : app2.authorised;
    let targetRoute;
    if (isAuthorised) {
      targetRoute = isInCompanyPath ? APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_USER_DIRECTORY_ROUTE;
    } else {
      targetRoute = isInCompanyPath ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE;
    }
    const path = generatePath(targetRoute, { appStub: app2.stub });
    routerHistory.push(path);
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Store" }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: loading || loadingAvailableApps || loadingInstalledApps, border: false, sx: { pt: 0 }, children: /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Box, { sx: { position: "fixed", background: themeColors.white, zIndex: 1, width: "100%" }, children: /* @__PURE__ */ jsx(
        TabFilterButtons,
        {
          filters: tabFilter,
          setFilterValue: setFilterString,
          filterValue: filterString,
          onFilterChange: ({ filterValue }) => {
            setFilterString(filterValue);
          }
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx("div", { style: { marginTop: spacing.m60 }, children: categorizedAvailableApps && Object.entries(categorizedAvailableApps).map(([key, value]) => /* @__PURE__ */ jsxs(Grid, { container: true, columnSpacing: spacing.g40, sx: { marginBottom: spacing.m80 }, children: [
        /* @__PURE__ */ jsx(Grid, { item: true, sm: 12, md: 12, children: /* @__PURE__ */ jsx("div", { id: key, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey, marginBottom: spacing.m30 }),
            children: key
          }
        ) }) }),
        value.map((a, index) => {
          var _a;
          return /* @__PURE__ */ jsx(Grid, { item: true, sm: 6, md: 4, sx: __spreadValues({}, spacing.mb20), children: /* @__PURE__ */ createElement(
            ActionCard,
            __spreadProps(__spreadValues({}, a), {
              key: a.name,
              title: a.name,
              titleClick: () => onTitleClick(a),
              description: a.appStoreBlurb ? a.appStoreBlurb : "",
              avatarImg: `/app-icons-v2/images/${a.stub}.png`,
              active: (_a = installedApps && appCurrentlyInstalled(installedApps, a.stub)) != null ? _a : false,
              actionText: "Connect",
              showAction: true,
              actionClick: () => {
                routerHistory.push(
                  generatePath(
                    isInCompanyPath ? APP_INTEGRATION_DETAILS_ABOUT_ROUTE : APP_INTEGRATION_PERSONAL_DETAILS_ABOUT_ROUTE,
                    {
                      appStub: a.stub
                    }
                  )
                );
              },
              showDivider: index < value.length - 3,
              actionScope: ["apps.connect", "apps:all"],
              context: getScopesContext({ userId: globalState.user.userId })
            })
          ) }, a.name);
        })
      ] }, key)) }) })
    ] }) })
  ] });
};
