"use strict";
import { PAYROLL_COMPANY_OVERVIEW_ROUTE, PAYROLL_ME_SUMMARY_ROUTE } from "@/lib/routes";
export const MONEY_SALARY_PAYROLL_HUB_ROUTES = {
  company: PAYROLL_COMPANY_OVERVIEW_ROUTE
};
export const MONEY_SALARY_SUMMARY_ROUTES = {
  me: PAYROLL_ME_SUMMARY_ROUTE
};
export const MONEY_SALARY_SCOPES = {
  company: ["payroll:all"],
  me: ["payroll:read"]
};
