"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import {
  StyledTabs,
  StyledTab
} from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const PayrunOverviewTabs = ({
  tabs,
  currentTab,
  onChange,
  sx
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, sx), { position: "relative" }), children: [
    /* @__PURE__ */ jsx(
      "div",
      {
        style: {
          position: "absolute",
          bottom: 1,
          left: 0,
          right: 0,
          height: "1px",
          backgroundColor: themeColors.middleGrey
        }
      }
    ),
    /* @__PURE__ */ jsx(
      StyledTabs,
      {
        value: currentTab || false,
        scrollButtons: "auto",
        variant: "scrollable",
        allowScrollButtonsMobile: true,
        onChange: (_, value) => {
          onChange == null ? void 0 : onChange(value);
        },
        children: tabs.map((label) => /* @__PURE__ */ jsx(
          StyledTab,
          {
            disableRipple: true,
            label: /* @__PURE__ */ jsx(
              "div",
              {
                style: {
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  textTransform: "inherit"
                },
                children: label
              }
            ),
            value: label
          },
          label
        ))
      }
    )
  ] });
};
