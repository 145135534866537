"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { ProductPlanTypes } from "@/models/subscription.model";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const SuperAdminBillingPlanEditFormItem = ({
  section,
  planDefaultValue,
  planLabel,
  handlePlanSelect,
  discountDefaultValue,
  handleDiscountSelect,
  discountLabel,
  planInputKey,
  discountInputKey,
  discountList
}) => {
  let plans = ProductPlanTypes.People;
  let defaultValue;
  switch (section) {
    case "Apps":
      plans = ProductPlanTypes.Apps;
      break;
    case "Money":
      plans = ProductPlanTypes.Money;
      break;
    case "Devices":
      plans = ProductPlanTypes.Devices;
      break;
  }
  defaultValue = plans[planDefaultValue];
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { marginTop: 5 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey, mt: 4, mb: 4 }), children: section }),
      /* @__PURE__ */ jsxs(
        TextField,
        {
          sx: { mb: 4 },
          select: true,
          variant: "outlined",
          fullWidth: true,
          onChange: (e) => handlePlanSelect(Number(planInputKey), Number(e.target.value)),
          label: planLabel,
          defaultValue,
          children: [
            /* @__PURE__ */ jsx(MenuItem, { value: plans.Free, children: "Free" }),
            /* @__PURE__ */ jsx(MenuItem, { value: plans.Pro, children: "Pro" })
          ]
        },
        planInputKey
      )
    ] }),
    /* @__PURE__ */ jsxs(
      TextField,
      {
        select: true,
        variant: "outlined",
        fullWidth: true,
        onChange: (e) => handleDiscountSelect(Number(planInputKey), Number(e.target.value)),
        label: discountLabel,
        defaultValue: discountDefaultValue || 0,
        children: [
          /* @__PURE__ */ jsx(MenuItem, { value: 0, children: "None" }),
          discountList.map((discount) => /* @__PURE__ */ jsx(MenuItem, { value: discount.id, children: discount.description }, uuidv4()))
        ]
      },
      discountInputKey
    )
  ] });
};
