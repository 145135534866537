"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { TextFieldAndUnit } from "@v2/components/forms/textfield-and-unit.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AccrualCliff, AccrualUnit } from "@v2/feature/absence/absence.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyAccrualEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        accrualUnit: values.accrualUnit,
        accrualUnitsNo: values.accrualUnitsNo,
        accrualCliff: values.accrualCliff,
        accrueUpfront: values.accrueUpfront
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyAccrual(absencePolicy.id, policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, absencePolicy.id, refresh, setIsOpen, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      accrualUnit: (_a = absencePolicy.accrualUnit) != null ? _a : AccrualUnit.Day,
      accrualUnitsNo: (_b = absencePolicy.accrualUnitsNo) != null ? _b : 1,
      accrualCliff: (_c = absencePolicy.accrualCliff) != null ? _c : null,
      accrueUpfront: (_d = absencePolicy.accrueUpfront) != null ? _d : true
    },
    validationSchema: yup.object({
      accrualUnit: yup.string().oneOf(Object.values(AccrualUnit), polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      accrualUnitsNo: yup.number().integer(polyglot.t("ValidationMessages.validValue")).when("accrualUnit", {
        is: (val) => val === AccrualUnit.Day,
        then: (schema) => schema.min(1, polyglot.t("ValidationMessages.validValue")).max(365, polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.when("accrualUnit", {
          is: (val) => val === AccrualUnit.Week,
          then: (schema2) => schema2.min(1, polyglot.t("ValidationMessages.validValue")).max(52, polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
          otherwise: (schema2) => schema2.when("accrualUnit", {
            is: (val) => val === AccrualUnit.Month,
            then: (schema3) => schema3.min(1, polyglot.t("ValidationMessages.validValue")).max(12, polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
            otherwise: (schema3) => schema3.required(polyglot.t("ValidationMessages.requiredField"))
          })
        })
      }).required(polyglot.t("ValidationMessages.requiredField")),
      accrualCliff: yup.string().nullable().oneOf([null, ...Object.values(AccrualCliff)], polyglot.t("ValidationMessages.validValue")).notRequired(),
      accrueUpfront: yup.boolean().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AbsenceModule.accrual") }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PolicyAccrualEditDrawer.restrictByAccrualDesc") }),
        /* @__PURE__ */ jsx(
          TextFieldAndUnit,
          {
            label: polyglot.t("AbsenceModule.accrualFrequency"),
            valueName: "accrualUnitsNo",
            value: formik.values.accrualUnitsNo,
            onValueChange: (value) => {
              formik.setFieldValue("accrualUnitsNo", Number(value));
            },
            unitName: "accrualUnit",
            unit: formik.values.accrualUnit,
            onUnitChange: (value) => {
              formik.setFieldValue("accrualUnit", value);
            },
            unitOptions: [
              { label: polyglot.t("getUnitTypeOptions.days"), value: AccrualUnit.Day },
              { label: polyglot.t("getUnitTypeOptions.weeks"), value: AccrualUnit.Week },
              { label: polyglot.t("getUnitTypeOptions.months"), value: AccrualUnit.Month }
            ],
            error: !!formik.errors.accrualUnit && formik.touched.accrualUnit || !!formik.errors.accrualUnitsNo && formik.touched.accrualUnitsNo,
            helperText: formik.touched.accrualUnit && formik.errors.accrualUnit || formik.touched.accrualUnitsNo && formik.errors.accrualUnitsNo
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("AbsenceModule.accrueUpfront") }),
            /* @__PURE__ */ jsx(
              SwitchComponent,
              {
                checked: Boolean(formik.values.accrueUpfront),
                name: "accrual-upfront",
                onChange: (_e, enabled) => {
                  formik.setFieldValue("accrueUpfront", enabled);
                }
              }
            )
          ] }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsenceModule.accrueUpfrontDesc") })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("AbsenceModule.accrualCliff") }),
            /* @__PURE__ */ jsx(
              SwitchComponent,
              {
                checked: Boolean(formik.values.accrualCliff),
                name: "accrual-cliff",
                onChange: (_e, enabled) => {
                  if (enabled) formik.setFieldValue("accrualCliff", AccrualCliff.Probation);
                  else formik.setFieldValue("accrualCliff", null);
                }
              }
            )
          ] }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("PolicyAccrualEditDrawer.accrualCliffDesc") })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true
          }
        ) })
      ] }) })
    }
  );
};
