"use strict";
import { SMART_FIELD_CUSTOM_MARKER } from "@/v2/components/forms/document-editor/tiptap/nodes/smart-field.node";
import { SMART_FIELD_MAP_BY_LABEL } from "@/v2/feature/templates/components/editor";
export const cleanHTML = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const markers = tempDiv.querySelectorAll(`.${SMART_FIELD_CUSTOM_MARKER}`);
  markers.forEach((marker) => {
    if (!(marker == null ? void 0 : marker.textContent)) return;
    const value = SMART_FIELD_MAP_BY_LABEL.get(marker.textContent);
    if (!value) return;
    if (marker) {
      marker.textContent = value.value;
      if (value.fontFamily) {
        marker.classList.add("smartfield__signature");
      }
    }
  });
  const separators = tempDiv.querySelectorAll(".ProseMirror-separator");
  separators.forEach((element) => {
    element.remove();
  });
  const trailingBreak = tempDiv.querySelectorAll(".ProseMirror-trailingBreak");
  trailingBreak.forEach((element) => {
    element.remove();
  });
  const emptyImages = tempDiv.querySelectorAll('img[alt=""]');
  emptyImages.forEach((element) => {
    element.remove();
  });
  const tables = tempDiv.querySelectorAll("table");
  tables.forEach((table) => {
    table.removeAttribute("style");
    table.style.width = "100%";
    const rows = tempDiv.querySelectorAll("tr");
    rows.forEach((row) => {
      row.removeAttribute("style");
      let cellOverride = void 0;
      let percentages = 0;
      row.childNodes.forEach((child, i) => {
        var _a;
        const node = child;
        if (child.nodeName !== "TD" && child.nodeName !== "TH") {
          return;
        }
        const cellWidth = (_a = node.attributes.getNamedItem("colwidth")) == null ? void 0 : _a.value;
        const width = cellWidth ? parseInt(cellWidth) : null;
        if (width) {
          const widthPercentage = width / 793.7007874 * 100;
          node.style.width = `${widthPercentage}%`;
          percentages += widthPercentage;
          return;
        }
        if (!cellOverride) {
          cellOverride = (100 - percentages) / (row.childNodes.length - i);
        }
        node.style.width = `${cellOverride}%`;
      });
    });
  });
  return tempDiv.innerHTML;
};
