"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { tableIconButtonSx, tableIconErrorButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { LocalDate } from "@v2/util/local-date";
import { BillingInvoiceAPI } from "@/api-client/billing-invoice.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { ReactComponent as ExternalLink } from "@/images/icons/external-link-alt-solid.svg";
import { ReactComponent as Plus } from "@/images/icons/plus-solid.svg";
import { ReactComponent as CleanCircle } from "@/images/side-bar-icons/CleanCircle.svg";
import { ReactComponent as Envelope } from "@/images/side-bar-icons/EnvelopeSimple.svg";
import { nestErrorMessage } from "@/lib/errors";
import { InvoiceStatus } from "@/models/invoice.model";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SuperAdminBillingInvoiceTableHelper } from "@/v2/feature/super-admin/features/super-admin-billing/helper/super-admin-billing-invoices-table-helper";
import { themeColors } from "@/v2/styles/colors.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function SuperAdminBillingInvoicesPage({ pageConfig }) {
  var _a, _b;
  const [invoices, setInvoices] = useState([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElInvoice, setAnchorElInvoice] = useState(null);
  const [invoiceFailedPaymentToDelete, setInvoiceFailedPaymentToDelete] = useState(null);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [showMessage] = useMessage();
  const [sorting, setSorting] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const fetchInvoices = useCallback(
    (params) => __async(this, null, function* () {
      try {
        setIsLoadingInvoices(true);
        const { invoices: invoices2, pagination: paginationInfo } = yield BillingInvoiceAPI.getAllInvoices(params);
        setInvoices(invoices2 || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
        setIsLoadingInvoices(false);
      } catch (error) {
        showMessage(`Something went wrong fetching invoices. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const refresh = useCallback(() => __async(this, null, function* () {
    yield fetchInvoices({
      page: pageIndex.toString(),
      pageSize: DEFAULT_PAGE_SIZE.toString(),
      searchQuery
    });
  }), [fetchInvoices, pageIndex, searchQuery]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const handleDownloadClick = useCallback(
    (invoice) => __async(this, null, function* () {
      try {
        const PDFBlob = yield BillingInvoiceAPI.downloadInvoiceAsSuperAdmin({
          companyId: invoice.companyId,
          id: invoice.id
        });
        const file = new Blob([PDFBlob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement("a");
        link.download = `${invoice.id}-${/* @__PURE__ */ new Date()}.pdf`;
        link.href = fileURL;
        link.click();
      } catch (e) {
        console.error("::Download error", e);
        showMessage(`Failed to download invoice. ${nestErrorMessage(e)}`, "error");
      }
    }),
    [showMessage]
  );
  const handleDeleteInvoiceFailedBillingPayment = useCallback(
    (companyId, invoiceId, failedPaymentId) => __async(this, null, function* () {
      if (!invoiceId) return;
      try {
        yield BillingInvoiceAPI.deleteFailiedBillingPaymentAsSuperAdmin(companyId, invoiceId, failedPaymentId);
        yield refresh();
      } catch (error) {
        showMessage(`Could not delete failed payment. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refresh, showMessage]
  );
  const handleDeleteInvoice = useCallback(
    (companyId, invoiceId) => __async(this, null, function* () {
      if (!invoiceId) return;
      try {
        yield BillingInvoiceAPI.deleteInvoiceAsSuperAdmin(companyId, invoiceId);
        yield refresh();
      } catch (error) {
        showMessage(`Could not delete invoice. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refresh, showMessage]
  );
  const generateExternalInvoice = useCallback(
    (invoiceId) => __async(this, null, function* () {
      try {
        yield BillingInvoiceAPI.generateExternalInvoiceAsSuperAdmin(invoiceId);
        yield refresh();
      } catch (error) {
        console.error("Generate invoice error", error);
        showMessage(`Failed to generate external invoice. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refresh, showMessage]
  );
  const sendUnpaidInvoiceReminder = useCallback(
    (companyId, invoiceId) => __async(this, null, function* () {
      try {
        yield BillingInvoiceAPI.sendEmailReminderOfUnpaidInvoiceAsSuperAdmin(companyId, invoiceId);
        showMessage("Email sent.", "success");
      } catch (error) {
        showMessage(`Action failed. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const invoiceTableColumns = useMemo(
    () => [
      SuperAdminBillingInvoiceTableHelper.getInvoiceIdColumn({
        header: "Invoice Id",
        id: "id",
        size: 145
      }),
      SuperAdminBillingInvoiceTableHelper.getCompanyNameColumn({
        header: "Company",
        id: "companyName",
        size: 145
      }),
      SuperAdminBillingInvoiceTableHelper.getCompanyIdColumn({
        header: "Company ID",
        id: "companyId",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getPeriodStartDateColumn({
        header: "Period Start",
        id: "periodStart",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getPeriodEndDateColumn({
        header: "Period End",
        id: "periodEnd",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getInvoiceDateColumn({
        header: "Invoice Date",
        id: "invoiceDate",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getInvoiceStatusColumn({
        header: "Status",
        id: "status",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getNoOfSubscriptionColumn({
        header: "Total Subscriptions",
        id: "totalSubscriptions",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getTotalAmountColumn({
        header: "Total Amount",
        id: "totalAmount",
        size: 100
      }),
      {
        header: () => "Action",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2;
          return original.id ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
            /* @__PURE__ */ jsx(StyledTooltip, { id: "download-invoice", title: "Download invoice", placement: "top", children: /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => __async(this, null, function* () {
                  yield handleDownloadClick(original);
                }),
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize))
              },
              `download-${original.id}`
            ) }),
            original.externalId ? /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  display: "flex",
                  justifyContent: "center"
                },
                children: /* @__PURE__ */ jsx(
                  "a",
                  {
                    href: `https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${original.externalId}`,
                    target: "_blank",
                    rel: "noreferrer",
                    children: /* @__PURE__ */ jsx(StyledTooltip, { id: "external-invoice", title: "External invoice", placement: "top", children: /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, children: /* @__PURE__ */ jsx(ExternalLink, __spreadValues({}, iconSize)) }, `redirect-${original.id}`) })
                  },
                  `redirect-${original.externalId}`
                )
              }
            ) : /* @__PURE__ */ jsx(StyledTooltip, { id: "external-invoice", title: "Push to Xero", placement: "top", children: /* @__PURE__ */ jsx(
              IconButton,
              {
                sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { bgcolor: themeColors.lightBlue }),
                onClick: () => __async(this, null, function* () {
                  yield generateExternalInvoice(original.id);
                }),
                children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
              },
              `generate-${original.id}`
            ) }),
            original.status !== InvoiceStatus.Paid && /* @__PURE__ */ jsx(StyledTooltip, { id: "reminder-email", title: "Send reminder", placement: "top", children: /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => __async(this, null, function* () {
                  yield sendUnpaidInvoiceReminder(original.companyId, original.id);
                }),
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Envelope, __spreadValues({}, iconSize))
              },
              `reminder-email-${original.id}`
            ) }),
            ((_a2 = original.billingFailedPayment) == null ? void 0 : _a2.id) && /* @__PURE__ */ jsx(
              StyledTooltip,
              {
                id: "delete-failed-payment",
                title: "Delete invoice failed payment",
                placement: "top",
                sx: { ml: "10px" },
                children: /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    onClick: (event) => {
                      var _a3;
                      if (!((_a3 = original.billingFailedPayment) == null ? void 0 : _a3.id)) return;
                      setInvoiceFailedPaymentToDelete({
                        companyId: original.companyId,
                        companyName: original.company.name,
                        invoiceId: original.id,
                        failedPaymentId: original.billingFailedPayment.id,
                        startDate: original.billingPeriodStartDate,
                        endDate: original.billingPeriodEndDate
                      });
                      setAnchorEl(event.currentTarget);
                    },
                    color: "error",
                    sx: tableIconErrorButtonSx,
                    children: /* @__PURE__ */ jsx(CleanCircle, __spreadValues({}, iconSize))
                  },
                  `delete-payment-${original.id}`
                )
              }
            ),
            original.status !== InvoiceStatus.Paid && /* @__PURE__ */ jsx(StyledTooltip, { id: "delete-invoice", title: "Delete unpaid invoice", placement: "top", sx: { ml: "10px" }, children: /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: (event) => {
                  setInvoiceToDelete({
                    companyId: original.companyId,
                    companyName: original.company.name,
                    invoiceId: original.id,
                    startDate: original.billingPeriodStartDate,
                    endDate: original.billingPeriodEndDate
                  });
                  setAnchorElInvoice(event.currentTarget);
                },
                color: "error",
                sx: tableIconErrorButtonSx,
                children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
              },
              `delete-invoice-${original.id}`
            ) })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 220
      }
    ],
    [generateExternalInvoice, handleDownloadClick, sendUnpaidInvoiceReminder]
  );
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { width: "100%" }, children: "Billing" })
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoadingInvoices, secondLevel: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({
            display: "flex",
            justifyContent: "flex-start",
            width: "100%"
          }, spacing.mb20), {
            gap: "5px",
            alignItems: "center"
          }),
          children: /* @__PURE__ */ jsx(
            TableSearch,
            {
              style: { width: "20em" },
              query: searchQuery,
              handleChange: (e) => {
                var _a2, _b2;
                setSearchQuery((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              },
              placeholder: "Enter company id or name..."
            }
          )
        }
      ),
      !isLoadingInvoices && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
        BasicServerTable,
        {
          rowData: [...invoices],
          columnData: invoiceTableColumns,
          sorting,
          setSorting,
          pagination,
          setPagination,
          totalPages,
          totalItems
        }
      ) }),
      anchorEl && invoiceFailedPaymentToDelete && /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: Boolean(anchorEl),
          onClose: () => {
            setAnchorEl(null);
            setInvoiceFailedPaymentToDelete(null);
          },
          anchorEl,
          takeAction: () => __async(this, null, function* () {
            if (!invoiceFailedPaymentToDelete) return;
            yield handleDeleteInvoiceFailedBillingPayment(
              invoiceFailedPaymentToDelete.companyId,
              invoiceFailedPaymentToDelete.invoiceId,
              invoiceFailedPaymentToDelete.failedPaymentId
            );
            setInvoiceFailedPaymentToDelete(null);
            setAnchorEl(null);
          }),
          message: /* @__PURE__ */ jsxs(Box, { sx: { mb: "10px" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: "5px" }, children: "Delete billing failed payment?" }),
            /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
              "Company: ",
              /* @__PURE__ */ jsx("b", { children: invoiceFailedPaymentToDelete.companyName })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
              "Invoice ID: ",
              /* @__PURE__ */ jsx("b", { children: invoiceFailedPaymentToDelete.invoiceId })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
              "Invoice period:",
              " ",
              /* @__PURE__ */ jsxs("b", { children: [
                new LocalDate(invoiceFailedPaymentToDelete.startDate).toLocaleDateString(),
                " -",
                " ",
                new LocalDate(invoiceFailedPaymentToDelete.endDate).toLocaleDateString()
              ] })
            ] })
          ] }),
          callToAction: "Delete"
        }
      ),
      anchorElInvoice && invoiceToDelete && /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: Boolean(anchorElInvoice),
          onClose: () => {
            setAnchorEl(null);
            setInvoiceFailedPaymentToDelete(null);
          },
          anchorEl,
          takeAction: () => __async(this, null, function* () {
            if (!invoiceToDelete) return;
            yield handleDeleteInvoice(invoiceToDelete.companyId, invoiceToDelete.invoiceId);
            setInvoiceToDelete(null);
            setAnchorElInvoice(null);
          }),
          message: /* @__PURE__ */ jsxs(Box, { sx: { mb: "10px" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: "5px" }, children: "Delete invoice?" }),
            /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
              "Company: ",
              /* @__PURE__ */ jsx("b", { children: invoiceToDelete.companyName })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
              "Invoice ID: ",
              /* @__PURE__ */ jsx("b", { children: invoiceToDelete.invoiceId })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { variant: "captionSmall", children: [
              "Invoice period:",
              " ",
              /* @__PURE__ */ jsxs("b", { children: [
                new LocalDate(invoiceToDelete.startDate).toLocaleDateString(),
                " -",
                " ",
                new LocalDate(invoiceToDelete.endDate).toLocaleDateString()
              ] })
            ] })
          ] }),
          callToAction: "Delete"
        }
      )
    ] }) })
  ] });
}
