"use strict";
import { useCallback, useEffect, useRef } from "react";
export function useEventSource(url, callbacks) {
  const eventSourceRef = useRef(null);
  const callbacksRef = useRef(callbacks);
  const connect = useCallback(() => {
    var _a, _b;
    if (!url) {
      console.info("No URL provided, skipping EventSource connection");
      return () => {
      };
    }
    console.info("Connecting EventSource:", url);
    (_b = (_a = callbacksRef.current).onInit) == null ? void 0 : _b.call(_a);
    const eventSource = new EventSource(url);
    eventSourceRef.current = eventSource;
    eventSource.onmessage = ({ data }) => {
      var _a2, _b2;
      if (data === "ping") {
        return;
      }
      try {
        (_b2 = (_a2 = callbacksRef.current).onMessage) == null ? void 0 : _b2.call(_a2, JSON.parse(data));
      } catch (error) {
        console.error("Failed to parse message:", error);
      }
    };
    eventSource.onerror = (ev) => {
      var _a2, _b2;
      console.error("EventSource error:", ev);
      (_b2 = (_a2 = callbacksRef.current).onError) == null ? void 0 : _b2.call(_a2, new Error("EventSource connection error"));
      eventSource.close();
      console.debug("Not retrying eventsource connection on error!");
    };
    const pingInterval = setInterval(() => {
      if (eventSource.readyState === EventSource.OPEN) {
        eventSource.dispatchEvent(new Event("ping"));
      }
    }, 25e3);
    return () => {
      clearInterval(pingInterval);
      eventSource.close();
    };
  }, [url]);
  useEffect(() => {
    callbacksRef.current = callbacks;
  }, [callbacks]);
  useEffect(() => {
    const cleanup = connect();
    return () => {
      var _a;
      cleanup();
      (_a = eventSourceRef.current) == null ? void 0 : _a.close();
    };
  }, [connect]);
}
