"use strict";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
export const ThemeSlider = styled(Slider)(({ theme }) => ({
  color: themeColors.ZeltYellow,
  height: 2,
  "& .MuiSlider-thumb": {
    visibility: "hidden"
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none"
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000"
    }
  },
  "& .MuiSlider-track": {
    border: "none"
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: themeColors.lightGrey
  },
  "& .MuiSlider-mark": {
    backgroundColor: themeColors.lightGrey,
    height: "6px",
    width: "6px",
    borderRadius: radius.br15,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor"
    }
  }
}));
