"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box, Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useSWRConfig } from "swr";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import trialExpiredBannerImage from "@/images/trial/trial-expired-banner.png";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { performLogout } from "@/v2/feature/auth/auth.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const TrialExpiredLayer = () => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const swrConfig = useSWRConfig();
  const routerLocation = useLocation();
  const BILLING_ROUTE = "/settings/billing";
  const currentlyInBilling = routerLocation.pathname.includes(BILLING_ROUTE);
  const [globalState, dispatch] = useContext(GlobalContext);
  const { shutdown } = useIntercom();
  const { hasScopes } = useScopes();
  const scopesContext = { userId: globalState.user.userId };
  const isAdminUser = hasScopes(["company.settings:all"], scopesContext);
  const hasTrialExpired = globalState.trialMode.lapsed;
  const redirectToBilling = () => {
    history.push(BILLING_ROUTE);
  };
  if (!hasTrialExpired || currentlyInBilling) {
    return null;
  }
  const LogoutButton = () => /* @__PURE__ */ jsx(
    ButtonComponent,
    {
      sizeVariant: "medium",
      colorVariant: "secondary",
      onClick: () => performLogout(history, dispatch, swrConfig, shutdown),
      children: polyglot.t("UserAvatarMenu.logout")
    }
  );
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999
      },
      children: /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            backgroundColor: "white",
            padding: "32px",
            width: "50vw",
            height: "45vh",
            textAlign: "left",
            backgroundImage: `url(${trialExpiredBannerImage})`,
            backgroundSize: "130%",
            backgroundPosition: "100% -50%"
          },
          children: /* @__PURE__ */ jsxs(
            Stack,
            {
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "left",
              sx: { mt: spacing.mt30, gap: spacing.g20 },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("TrialExpired.title") }),
                isAdminUser ? /* @__PURE__ */ jsxs(Fragment, { children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "bodyLarge", children: polyglot.t("TrialExpired.adminSuffix") }),
                  /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyItems: "left",
                        justifyContent: "flex-start",
                        mt: spacing.mt20,
                        gap: spacing.g20
                      },
                      children: [
                        /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", onClick: redirectToBilling, children: polyglot.t("TrialExpired.upgradeButton") }),
                        /* @__PURE__ */ jsx(LogoutButton, {})
                      ]
                    }
                  )
                ] }) : /* @__PURE__ */ jsxs(Stack, { flexDirection: "column", justifyContent: "center", children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "bodyLarge", children: polyglot.t("TrialExpired.nonAdminSuffix") }),
                  /* @__PURE__ */ jsx(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyItems: "left",
                        justifyContent: "flex-start",
                        mt: spacing.mt20,
                        gap: spacing.g20
                      },
                      children: /* @__PURE__ */ jsx(LogoutButton, {})
                    }
                  )
                ] })
              ]
            }
          )
        }
      )
    }
  );
};
