"use strict";
import { jsx } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { TIME_COMPANY_CALENDAR_ROUTE } from "@/lib/routes";
import { CalendarPage } from "@/v2/feature/calendar/features/calendar.page";
export const CalendarCompanyRouter = ({
  calendarAdvEventsTimeline,
  setCalendarAdvEventsTimeline,
  setLoading,
  loading
}) => /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { path: [TIME_COMPANY_CALENDAR_ROUTE], children: /* @__PURE__ */ jsx(
  CalendarPage,
  {
    view: "company",
    calendarAdvEventsTimeline,
    setCalendarAdvEventsTimeline,
    setLoading,
    loading
  }
) }) });
