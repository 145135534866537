"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PersonalTask } from "@/v2/feature/task/pages/personal/components/personal-task.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
export const TasksUserPage = ({ userId }) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const params = useParams();
  const targetUserId = Number(params.userId);
  const { getScopesContext, hasScopes } = useScopes();
  const hasTaskManagerScopes = hasScopes(["task:manager"], getScopesContext(user));
  const hasTaskAdminScopes = hasScopes(["task:all"], getScopesContext(user));
  const isManagerOfTargetUserId = useMemo(() => {
    return hasTaskManagerScopes && user.reports.includes(targetUserId);
  }, [hasTaskManagerScopes, targetUserId, user.reports]);
  const { polyglot } = usePolyglot();
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState(false);
  const { trackPage } = useJune();
  useEffect(() => {
    trackPage("Personal tasks");
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: polyglot.t("TasksUserPage.tasks"),
        showAction: hasTaskAdminScopes || isManagerOfTargetUserId,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsTaskFormModalOpen(true), children: polyglot.t("PersonalTask.new") })
      }
    ),
    /* @__PURE__ */ jsx(
      PersonalTask,
      {
        userId,
        setIsTaskFormModalOpen,
        isTaskFormModalOpen,
        reach: "me"
      }
    )
  ] });
};
