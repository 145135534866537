"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { UserLifecycleSchemaForImport } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { drawerContentSx, titleSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const UserImportLifecycleForm = ({
  initialValues,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  }
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserLifecycleSchemaForImport,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (usedForDataImport) {
          importHandler == null ? void 0 : importHandler(values);
        }
      } catch (error) {
        showMessage(polyglot.t("UserImportLifecycleForm.errorMessages.save"), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "h1", sx: titleSx, children: polyglot.t("UserImportLifecycleForm.edit") }),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_a = formik.values.startDate) != null ? _a : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("startDate", value);
          } else {
            formik.setFieldValue("startDate", null);
          }
        },
        name: "startDate",
        label: polyglot.t("UserImportLifecycleForm.startDate"),
        error: !!formik.errors.startDate && formik.touched.startDate,
        helperText: formik.errors.startDate && formik.touched.startDate
      }
    ) }),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_b = formik.values.leaveDate) != null ? _b : null,
        returnNullOnChange: true,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("leaveDate", value);
          } else if (value === "" || !value) {
            formik.setFieldValue("leaveDate", null);
          }
        },
        name: "leaveDate",
        label: polyglot.t("UserImportLifecycleForm.leaveDate"),
        error: !!formik.errors.leaveDate && formik.touched.leaveDate,
        helperText: formik.errors.leaveDate && formik.touched.leaveDate
      }
    ) }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "changeReason",
        label: polyglot.t("UserImportLifecycleForm.changeReason"),
        value: formik.values.changeReason,
        onChange: formik.handleChange,
        error: formik.touched.changeReason && !!formik.errors.changeReason,
        helperText: (_c = formik.touched.changeReason && formik.errors.changeReason) != null ? _c : " "
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
