"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl, RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { useEventSource } from "@/hooks/event-source-hook";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { EXPENSES_COMPANY_ROUTE, EXPENSES_ME_ROUTE, EXPENSES_TEAM_ROUTE } from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { ExpenseCompanyRouter } from "@/v2/feature/expense/expense.company.router";
import { ExpensesMeRouter } from "@/v2/feature/expense/expense.me.router";
import { ExpenseTeamRouter } from "@/v2/feature/expense/expense.team.router";
import { PaymentContextProvider } from "@/v2/feature/payments/features/make-payment/payment.context";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { eventSourceErrorHandler } from "@/v2/infrastructure/push-events/push-events.util";
export const PAYMENTS_ROUTE = "/money/company/payments";
export const PAYMENTS_SELECT_BANK_ROUTE = "/money/company/payments/overview/select-bank";
export const PAYMENTS_AUX_ROUTE = "/money/company/payments/overview/aux/:invoiceId";
export const PAYMENTS_CREATE_ROUTE = "/money/company/payments/overview/create";
export const ExpenseRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const [showMessage] = useMessage();
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(globalState.user.userId), {
    suspense: false
  });
  const { data: isAccountingAppConfigured } = useApiClient(PaymentTypeSettingsEndpoints.accountingAppConfigured(), {
    suspense: false
  });
  const EXPENSE_CHANNEL_NAME = `expense-actions-${environmentPrefix}-user-${globalState.user.userId}`;
  const EXPENSE_SYNC_FAILURE_EVENT_SOURCE_URL = `/apiv2/push-events/${EXPENSE_CHANNEL_NAME}-expense-external-sync-failed`;
  const isManager = useMemo(() => {
    var _a, _b, _c;
    const nonTerminatedDirectReports = (_c = (_b = (_a = userSummaryWithReports == null ? void 0 : userSummaryWithReports.directReports) == null ? void 0 : _a.filter(
      (d) => d.userEvent && d.userEvent.status && ["Employed", "Hired"].includes(d.userEvent.status)
    )) == null ? void 0 : _b.length) != null ? _c : 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);
  const generalMessageHandling = useCallback(
    (data) => {
      if (data && data.message && data.type) showMessage(data.message, data.type);
    },
    [showMessage]
  );
  const expenseSyncEventSourceURL = useMemo(() => {
    if (isAccountingAppConfigured) {
      return EXPENSE_SYNC_FAILURE_EVENT_SOURCE_URL;
    } else {
      return null;
    }
  }, [EXPENSE_SYNC_FAILURE_EVENT_SOURCE_URL, isAccountingAppConfigured]);
  useEventSource(expenseSyncEventSourceURL, {
    onMessage: generalMessageHandling,
    onError: eventSourceErrorHandler
  });
  return /* @__PURE__ */ jsx(PaymentContextProvider, { payments: [], children: /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["expenses:all"], path: EXPENSES_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(ExpenseCompanyRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["expenses"], path: EXPENSES_ME_ROUTE, children: /* @__PURE__ */ jsx(ExpensesMeRouter, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["expenses:manager"], path: EXPENSES_TEAM_ROUTE, children: /* @__PURE__ */ jsx(ExpenseTeamRouter, { isManager }) }),
    canAccessScopes(globalState.user, ["expenses:all"]) && /* @__PURE__ */ jsx(Redirect, { to: EXPENSES_COMPANY_ROUTE })
  ] }) });
};
