"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback, useContext, useEffect, useState } from "react";
import { AnalyticsBrowser } from "@june-so/analytics-next";
import { LocalDate } from "@v2/util/local-date";
import { GlobalContext } from "@/GlobalState";
import { isProduction } from "@/v2/infrastructure/firebase/identity-platform.util";
import { getDisplayName } from "@/v2/util/string-format.util";
import { useLocalStorage } from "@/v2/util/use-local-storage.util";
const ONE_HOUR_IN_MS = 36e5;
export var JUNE_ACTIONS = /* @__PURE__ */ ((JUNE_ACTIONS2) => {
  JUNE_ACTIONS2["ABOUT_FORM_CANCEL"] = "About.form.cancel.editing";
  JUNE_ACTIONS2["ABOUT_FORM_EDIT"] = "About.form.edit";
  JUNE_ACTIONS2["ABOUT_FORM_SAVE"] = "About.form.save";
  JUNE_ACTIONS2["ADDRESS_FORM_CANCEL"] = "Address.form.cancel.editing";
  JUNE_ACTIONS2["ADDRESS_FORM_EDIT"] = "Address.form.edit";
  JUNE_ACTIONS2["ADDRESS_FORM_SAVE"] = "Address.form.save";
  JUNE_ACTIONS2["BANK_ACCOUNT_FORM_CANCEL"] = "Bank account.form.cancel.editing";
  JUNE_ACTIONS2["BANK_ACCOUNT_FORM_EDIT"] = "Bank account.form.edit";
  JUNE_ACTIONS2["BANK_ACCOUNT_FORM_SAVE"] = "Bank account.form.save";
  JUNE_ACTIONS2["BASIC_INFORMATION_FORM_CANCEL"] = "Basic information.form.cancel.editing";
  JUNE_ACTIONS2["BASIC_INFORMATION_FORM_EDIT"] = "Basic information.form.edit";
  JUNE_ACTIONS2["BASIC_INFORMATION_FORM_SAVE"] = "Basic information.form.save";
  JUNE_ACTIONS2["COMPENSATION_FORM_CANCEL"] = "Compensation.form.cancel.editing";
  JUNE_ACTIONS2["COMPENSATION_FORM_EDIT"] = "Compensation.form.edit";
  JUNE_ACTIONS2["CONTRACT_FORM_CANCEL"] = "Contract.form.cancel.editing";
  JUNE_ACTIONS2["CONTRACT_FORM_EDIT"] = "Contract.form.edit";
  JUNE_ACTIONS2["EMERGENCY_CONTACT_FORM_CANCEL"] = "Emergency contact.form.cancel.editing";
  JUNE_ACTIONS2["EMERGENCY_CONTACT_FORM_EDIT"] = "Emergency contact.form.edit";
  JUNE_ACTIONS2["EMERGENCY_CONTACT_FORM_SAVE"] = "Emergency contact.form.save";
  JUNE_ACTIONS2["EQUITY_FORM_CANCEL"] = "Equity.form.cancel.editing";
  JUNE_ACTIONS2["EQUITY_FORM_EDIT"] = "Equity.form.edit";
  JUNE_ACTIONS2["FAMILY_FORM_CANCEL"] = "Family.form.cancel.editing";
  JUNE_ACTIONS2["FAMILY_FORM_EDIT"] = "Family.form.edit";
  JUNE_ACTIONS2["FAMILY_FORM_SAVE"] = "Family.form.save";
  JUNE_ACTIONS2["IDENTIFICATION_FORM_CANCEL"] = "Identification.form.cancel.editing";
  JUNE_ACTIONS2["IDENTIFICATION_FORM_EDIT"] = "Identification.form.edit";
  JUNE_ACTIONS2["IDENTIFICATION_FORM_SAVE"] = "Identification.form.save";
  JUNE_ACTIONS2["LIFECYCLE_FORM_CANCEL"] = "Lifecycle.form.cancel.editing";
  JUNE_ACTIONS2["LIFECYCLE_FORM_EDIT"] = "Lifecycle.form.edit";
  JUNE_ACTIONS2["LIFECYCLE_FORM_SAVE"] = "Lifecycle.form.save";
  JUNE_ACTIONS2["PERSONAL_CONTACT_DETAILS_FORM_CANCEL"] = "Personal contact details.form.cancel.editing";
  JUNE_ACTIONS2["PERSONAL_CONTACT_DETAILS_FORM_EDIT"] = "Personal contact details.form.edit";
  JUNE_ACTIONS2["PERSONAL_CONTACT_DETAILS_FORM_SAVE"] = "Personal contact details.form.save";
  JUNE_ACTIONS2["PERSONAL_INFORMATION_FORM_CANCEL"] = "Personal information.form.cancel.editing";
  JUNE_ACTIONS2["PERSONAL_INFORMATION_FORM_EDIT"] = "Personal information.form.edit";
  JUNE_ACTIONS2["PERSONAL_INFORMATION_FORM_SAVE"] = "Personal information.form.save";
  JUNE_ACTIONS2["RIGHT_TO_WORK_FORM_CANCEL"] = "Right to work.form.cancel.editing";
  JUNE_ACTIONS2["RIGHT_TO_WORK_FORM_EDIT"] = "Right to work.form.edit";
  JUNE_ACTIONS2["RIGHT_TO_WORK_FORM_SAVE"] = "Right to work.form.save";
  JUNE_ACTIONS2["ROLE_FORM_CANCEL"] = "Role.form.cancel.editing";
  JUNE_ACTIONS2["ROLE_FORM_EDIT"] = "Role.form.edit";
  JUNE_ACTIONS2["WORK_CONTACT_DETAILS_FORM_CANCEL"] = "Work contact details.form.cancel.editing";
  JUNE_ACTIONS2["WORK_CONTACT_DETAILS_FORM_EDIT"] = "Work contact details.form.edit";
  JUNE_ACTIONS2["WORK_CONTACT_DETAILS_FORM_SAVE"] = "Work contact details.form.save";
  JUNE_ACTIONS2["USED_DOCUMENT_TEMPLATE"] = "Used document template";
  JUNE_ACTIONS2["CREATED_BULK_PAYMENT_VIA_EXPORT"] = "Created bulk payment via export";
  JUNE_ACTIONS2["CREATED_BULK_PAYMENT_VIA_OPEN_BANKING"] = "Created bulk payment via open banking";
  return JUNE_ACTIONS2;
})(JUNE_ACTIONS || {});
let analyticsInstance;
export function useJune() {
  const [analytics, setAnalytics] = useState(analyticsInstance);
  const [state] = useContext(GlobalContext);
  const currentUser = state == null ? void 0 : state.user;
  const [lastIdentifyTimestamp, setLastIdentifyTimestamp] = useLocalStorage(
    `${currentUser.userId}-lastIdentify`,
    null
  );
  useEffect(() => {
    if (!analyticsInstance) {
      const loadAnalytics = () => __async(this, null, function* () {
        try {
          const response = yield AnalyticsBrowser.load({
            writeKey: "4Jzfw1BKbsGbhTHB"
          });
          analyticsInstance = response[0];
          setAnalytics(analyticsInstance);
        } catch (error) {
          console.error("Error loading analytics:", error);
        }
      });
      loadAnalytics();
    }
  }, []);
  const identifyUser = useCallback(
    (props) => {
      var _a, _b, _c, _d;
      const { user, onAuth = false } = props;
      if (analytics && isProduction) {
        const currentTime = Date.now();
        if (onAuth || lastIdentifyTimestamp === null || currentTime - lastIdentifyTimestamp >= ONE_HOUR_IN_MS) {
          analytics.group({
            userId: user.userId,
            groupId: user.company.companyId,
            traits: {
              name: user.company.name,
              avatar: ((_a = user.company) == null ? void 0 : _a.avatar) || null
            }
          });
          analytics.identify({
            userId: user.userId,
            context: { groupId: user.company.companyId },
            traits: {
              email: user == null ? void 0 : user.emailAddress,
              name: getDisplayName(user),
              companyId: user.company.companyId,
              companyName: (_b = user.company) == null ? void 0 : _b.name,
              firstName: user.firstName,
              lastName: user.lastName,
              isAdmin: ((_c = user == null ? void 0 : user.accountType) == null ? void 0 : _c.Admin) ? ((_d = user == null ? void 0 : user.accountType) == null ? void 0 : _d.Admin) === true : false,
              lastTimeOnlineInZelt: (/* @__PURE__ */ new Date()).toUTCString(),
              lastDateOnlineInZelt: new LocalDate().toDateString()
            }
          });
          setLastIdentifyTimestamp(currentTime);
        }
      }
    },
    [analytics, lastIdentifyTimestamp, setLastIdentifyTimestamp]
  );
  const trackPage = useCallback(
    (page) => {
      if (analytics && isProduction) {
        const currentTime = Date.now();
        if (lastIdentifyTimestamp === null || currentTime - lastIdentifyTimestamp >= ONE_HOUR_IN_MS) {
          identifyUser({ user: currentUser });
        }
        analytics.page(page);
      }
    },
    [analytics, currentUser, identifyUser, lastIdentifyTimestamp]
  );
  const trackEvent = useCallback(
    (payload) => {
      if (analytics && isProduction) {
        analytics.track(payload.event, payload.properties);
      }
    },
    [analytics]
  );
  return { identifyUser, trackPage, trackEvent };
}
