"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class MonitoringAPI {
  static getGoogleCalendarQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/google-calendar/queues")).data;
    });
  }
  static getAbsenceImportQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/absence-queue/status")).data;
    });
  }
  static getOutlookCalendarQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/outlook-calendar/queues")).data;
    });
  }
  static getPayrunEntriesQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/payroll/payruns/queues")).data;
    });
  }
  static getAppActionsQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/actions/queues")).data;
    });
  }
  static getInHouseMdmDeviceSyncQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/mdm/device-sync/queues")).data;
    });
  }
  static cleanInHouseMdmQueues(queueName) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/apps/mdm/clean/queue/${queueName}`)).data;
    });
  }
  static getNumberOfJobsToCleanUp() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/mdm/clean/queues/review")).data;
    });
  }
  static getAppEmailsQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/apps/emails/queues")).data;
    });
  }
  static getBillingQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/billing/queues")).data;
    });
  }
  static getReviewQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/review-cycle/queues")).data;
    });
  }
  static getSurveyQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/survey-cycle/queues")).data;
    });
  }
  static getPayrollQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/companies/queues")).data;
    });
  }
  static getBillPaymentQueueDetails() {
    return __async(this, null, function* () {
      return yield (yield axios.get("/apiv2/bill-payment/queues")).data;
    });
  }
  static getDevicesMDMQueueDetails() {
    return __async(this, null, function* () {
      return yield (yield axios.get("/apiv2/devices/queues/mdm-queue")).data;
    });
  }
  static getDevicesNotificationsQueueDetails() {
    return __async(this, null, function* () {
      return yield (yield axios.get("/apiv2/devices/queues/notifications-queue")).data;
    });
  }
  static getAttendanceImportQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/attendances/import/queues/import-queue")).data;
    });
  }
  static getUsersImportQueueDetails() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/users/import/queues/import-queue")).data;
    });
  }
  static deleteJobFromCalendarQueue(queueName, jobId) {
    return __async(this, null, function* () {
      return yield (yield axios.patch(`/apiv2/${queueName}/queues/delete/job/${jobId}`)).data;
    });
  }
}
