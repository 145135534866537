"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { SuperAdminDeviceOrdersTable } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-orders-table.component";
import { BackofficeRootStyle } from "@v2/styles/root.styles";
import { keyBy } from "lodash";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { themeColors } from "@/v2/styles/colors.styles";
export const SuperAdminDeviceOrdersPage = ({
  users,
  companies,
  pageConfig
}) => {
  var _a, _b;
  const [stockDevices, setStockDevices] = useState([]);
  const [deviceOrders, setDeviceOrders] = useState([]);
  const [sites, setSitesById] = useState({});
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const fetchData = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [stockDevicesData, deviceOrdersData, sitesData] = yield Promise.all([
        DeviceAPI.getStockDevicesAsSuperadmin(),
        DeviceAPI.getDeviceOrdersAsSuperadmin(),
        SiteAPI.listSitesAsSuperAdmin()
      ]);
      setStockDevices(stockDevicesData);
      setDeviceOrders(deviceOrdersData);
      setSitesById(keyBy(sitesData, "id"));
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: "Device Orders" }),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, secondLevel: true, children: loading ? /* @__PURE__ */ jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "100%", children: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "83%",
        height: "100%",
        sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
      }
    ) }) : /* @__PURE__ */ jsx(Fragment, { children: deviceOrders.length > 0 ? /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "83%",
            height: "100%",
            sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
          }
        ),
        children: /* @__PURE__ */ jsx(
          SuperAdminDeviceOrdersTable,
          {
            deviceOrders,
            users,
            companies,
            refresh: fetchData,
            devices: stockDevices,
            sites
          }
        )
      }
    ) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "No device orders." }) }) })
  ] });
};
