"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __restKey = (key) => typeof key === "symbol" ? key : key + "";
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { calculatePreTaxAmount, NO_VAT_VALUE } from "@v2/feature/payments/expenses.util";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { SelectFieldCell, TextFieldCell } from "@/v2/components/table/basic-table.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { TaxRateOptions } from "@/v2/feature/payments/payments.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const getNewLineItemValidationSchema = (polyglot) => {
  return yup.object({
    accountingCode: yup.number().nullable(),
    accountingCodeDescription: yup.string().nullable(),
    description: yup.string().nullable().max(200, polyglot.t("ValidationMessages.maxLength", { max: 200 })),
    amount: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
    gross: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
    taxRate: yup.number().oneOf(
      TaxRateOptions.map((option) => Number(option.value)),
      polyglot.t("ValidationMessages.validValue")
    ).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
  });
};
const lineItemFields = ["accountingCode", "accountingCodeDescription", "description", "amount", "gross", "taxRate"];
const createEmptyLineItem = () => ({
  id: uuidv4(),
  accountingCode: void 0,
  accountingCodeDescription: "",
  description: "",
  amount: void 0,
  gross: void 0,
  taxRate: null,
  isTaxIncluded: false
});
export const ExpenseLineItemTable = ({
  expense,
  accountingCodeOptions,
  updateLineItems,
  errorInLineItems
}) => {
  const { polyglot } = usePolyglot();
  const [lineItems, setLineItems] = useState(expense.lineItems || []);
  const [errors, setErrors] = useState({});
  const validationSchema = useMemo(() => getNewLineItemValidationSchema(polyglot), [polyglot]);
  const validateField = useCallback(
    (id, field, value) => __async(void 0, null, function* () {
      try {
        const itemToValidate = { [field]: value };
        yield validationSchema.validateAt(field, itemToValidate);
        setErrors((prevErrors) => {
          var _a;
          const _b = prevErrors, { [_a = `${id}-${field}`]: removed } = _b, rest = __objRest(_b, [__restKey(_a)]);
          return rest;
        });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          setErrors((prevErrors) => __spreadProps(__spreadValues({}, prevErrors), { [`${id}-${field}`]: error.message }));
        }
      }
    }),
    [validationSchema]
  );
  const handleFieldChange = useCallback(
    (id, field, value) => {
      const updatedItems = lineItems.map((item) => {
        if (item.id !== id) return item;
        let updatedItem = __spreadProps(__spreadValues({}, item), { [field]: value });
        if (field === "accountingCode" && Number(value) > 0) {
          const matchingType = accountingCodeOptions.find((o) => o.value === value);
          updatedItem.accountingCodeDescription = (matchingType == null ? void 0 : matchingType.label) || "";
        }
        if (field === "taxRate") {
          updatedItem.isTaxIncluded = value !== NO_VAT_VALUE;
        }
        let { preTax } = updatedItem.gross && updatedItem.taxRate ? calculatePreTaxAmount(updatedItem.gross, updatedItem.taxRate) : { preTax: 0 };
        updatedItem.amount = preTax;
        return updatedItem;
      });
      setLineItems(updatedItems);
      const fieldToValidate = updatedItems.find((item) => item.id === id);
      validateField(id, field, fieldToValidate ? fieldToValidate[field] : value);
      updateLineItems(updatedItems);
    },
    [lineItems, updateLineItems, validateField, accountingCodeOptions]
  );
  const deleteLineItemById = useCallback(
    (id) => {
      const updatedItems = lineItems.filter((item) => item.id !== id);
      setLineItems(updatedItems);
      updateLineItems(updatedItems);
    },
    [lineItems, updateLineItems]
  );
  const validateAllFields = useCallback(() => __async(void 0, null, function* () {
    const allErrors = {};
    for (const item of lineItems) {
      for (const field of lineItemFields) {
        try {
          const value = item[field];
          const itemToValidate = { [field]: value };
          yield validationSchema.validateAt(field, itemToValidate);
          if (allErrors[`${item.id}-${field}`]) {
            delete allErrors[`${item.id}-${field}`];
          }
        } catch (error) {
          if (error instanceof yup.ValidationError && item.id) {
            allErrors[`${item.id}-${field}`] = error.message;
          }
        }
      }
    }
    setErrors(allErrors);
  }), [lineItems, validationSchema]);
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("NewExpensePage.description"),
        accessorFn: (row) => row,
        id: "description",
        minSize: 100,
        maxSize: 100,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          TextFieldCell,
          {
            id: original.id,
            name: "description",
            value: original.description || "",
            update: handleFieldChange,
            error: Boolean(errors[`${original.id}-description`])
          }
        )
      },
      ...accountingCodeOptions && accountingCodeOptions.length > 0 ? [
        {
          header: () => polyglot.t("NewExpensePage.accountingCode"),
          accessorFn: (row) => row,
          minSize: 100,
          maxSize: 100,
          id: "accountingCode",
          enableSorting: false,
          cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
            SelectFieldCell,
            {
              id: original.id,
              name: "accountingCode",
              value: original.accountingCode,
              options: accountingCodeOptions,
              update: handleFieldChange,
              error: Boolean(errors[`${original.id}-accountingCode`])
            }
          )
        }
      ] : [],
      {
        header: () => polyglot.t("NewInvoicePage.taxRate"),
        accessorFn: (row) => row,
        minSize: 100,
        maxSize: 100,
        id: "taxRate",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          SelectFieldCell,
          {
            id: original.id,
            name: "taxRate",
            value: original.taxRate,
            options: TaxRateOptions,
            update: handleFieldChange,
            error: Boolean(errors[`${original.id}-taxRate`])
          }
        )
      },
      {
        header: () => polyglot.t("PayItemModule.amount"),
        accessorFn: (row) => row,
        minSize: 70,
        maxSize: 70,
        id: "gross",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          TextFieldCell,
          {
            id: original.id,
            name: "gross",
            value: original.gross,
            update: handleFieldChange,
            type: "number",
            error: Boolean(errors[`${original.id}-gross`])
          }
        )
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        enableSorting: false,
        cell: ({ row }) => /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              visibility: lineItems.length > 1 ? "visible" : "hidden"
            },
            children: /* @__PURE__ */ jsx(
              IconButton,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => {
                  if (row.original.id) deleteLineItemById(row.original.id);
                },
                children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
              }
            )
          }
        )
      }
    ];
  }, [polyglot, accountingCodeOptions, deleteLineItemById, errors, handleFieldChange, lineItems.length]);
  const addEmptyRow = useCallback(() => {
    const newLineItem = createEmptyLineItem();
    const updatedItems = [...lineItems, newLineItem];
    setLineItems(updatedItems);
    updateLineItems(updatedItems);
    validateAllFields();
  }, [lineItems, updateLineItems, validateAllFields]);
  useEffect(() => {
    if (expense.lineItems.length === 0 && lineItems.length === 0) {
      addEmptyRow();
    }
  }, [expense.lineItems, lineItems.length, addEmptyRow]);
  useEffect(() => {
    if (errorInLineItems) {
      validateAllFields();
    }
  }, [errorInLineItems, validateAllFields]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: lineItems,
        columnData: columns,
        loading: false,
        externalTdAndThPadding: "0px 0.5px",
        hideHover: true,
        hidePagination: true
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", mt: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: addEmptyRow, children: polyglot.t("NewExpensePage.addLineItems") }),
      /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "captionSmall",
          color: "RedDark",
          sx: {
            opacity: Object.keys(errors).length > 0 ? 1 : 0,
            transition: "opacity 0.3s ease"
          },
          children: polyglot.t("NewExpensePage.addMissingDetails")
        }
      )
    ] })
  ] });
};
