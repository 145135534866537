"use strict";
import {
  PayScheduleEnum,
  SalaryBasisEnum
} from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
export const MonthlyPayScheduleOption = (polyglot) => ({
  value: PayScheduleEnum.Monthly,
  label: polyglot ? polyglot.t("PaySchedules.monthly") : "Monthly"
});
export const WeeklyPayScheduleOption = (polyglot) => ({
  value: PayScheduleEnum.Weekly,
  label: polyglot ? polyglot.t("PaySchedules.weekly") : "Weekly"
});
export const MonthlySalaryBasisOption = (polyglot) => ({
  value: SalaryBasisEnum.Monthly,
  label: polyglot ? polyglot.t("PaySchedules.monthly") : "Monthly"
});
export const WeeklySalaryBasisOption = (polyglot) => ({
  value: SalaryBasisEnum.Weekly,
  label: polyglot ? polyglot.t("PaySchedules.weekly") : "Weekly"
});
export const DailySalaryBasisOption = (polyglot) => ({
  value: SalaryBasisEnum.Daily,
  label: polyglot ? polyglot.t("PaySchedules.daily") : "Daily"
});
export const HourlySalaryBasisOption = (polyglot) => ({
  value: SalaryBasisEnum.Hourly,
  label: polyglot ? polyglot.t("PaySchedules.hourly") : "Hourly"
});
export const AnnualSalaryBasisOption = (polyglot) => ({
  value: SalaryBasisEnum.Annual,
  label: polyglot ? polyglot.t("PaySchedules.annual") : "Annual"
});
export const PaySchedules = (polyglot) => [
  MonthlyPayScheduleOption(polyglot),
  WeeklyPayScheduleOption(polyglot)
];
export const SalaryBasis = (polyglot) => [
  AnnualSalaryBasisOption(polyglot),
  MonthlySalaryBasisOption(polyglot),
  WeeklySalaryBasisOption(polyglot),
  DailySalaryBasisOption(polyglot),
  HourlySalaryBasisOption(polyglot)
];
export const displayRateLabel = (salaryBasis, polyglot) => {
  switch (salaryBasis) {
    case SalaryBasisEnum.Annual:
      return polyglot.t("CompensationForm.annualSalary");
    case SalaryBasisEnum.Monthly:
      return polyglot.t("CompensationForm.monthlySalary");
    case SalaryBasisEnum.Daily:
      return polyglot.t("CompensationForm.dailyRate");
    case SalaryBasisEnum.Hourly:
      return polyglot.t("CompensationForm.hourlyRate");
    default:
      return polyglot.t("CompensationForm.rate");
  }
};
export const equityTypeOptions = (polyglot) => {
  return [
    { value: "Share", label: polyglot.t("equityTypeOptions.share") },
    { value: "Option", label: polyglot.t("equityTypeOptions.option") },
    { value: "RSU", label: polyglot.t("equityTypeOptions.rsu") }
  ];
};
