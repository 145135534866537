"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { BenefitsUserOverviewPage } from "@v2/feature/benefits/benefits-user-overview.page";
import { CustomBenefitUserRouter } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit-user.router";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { InsuranceUserRouter } from "@v2/feature/benefits/subfeature/insurance/insurance-user.router";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { PensionMeRouter } from "@v2/feature/benefits/subfeature/pension/pension-me.router";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { useUserProfileConfig } from "@v2/feature/user/features/user-profile/user-profile.hook";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@v2/styles/root.styles";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BENEFITS_ME_ROUTE, CUSTOM_BENEFIT_ME_ROUTE, INSURANCE_ME_ROUTE, PENSION_ME_ROUTE } from "@/lib/routes";
export const BenefitsMeRouter = ({ insuranceQuote, loading }) => {
  const [state] = useContext(GlobalContext);
  const userId = state.user.userId;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionOwnerScope = hasScopes(["pension"], scopesContext);
  const hasInsuranceOwnerScope = hasScopes(["insurance"], scopesContext);
  const { data: userBenefits, isLoading: loadingCustomBenefits, mutate: refreshUserCBenefits } = useApiClient(
    hasInsuranceOwnerScope ? CustomBenefitEndpoints.allEffectiveUserBenefitsByUserId(userId) : null,
    {
      suspense: false
    }
  );
  const [loadingPension, setLoadingPension] = useState(false);
  const [loadingInsurance, setLoadingInsurance] = useState(false);
  const { userOverviewConfig, refreshUserOverviewConfig } = useUserProfileConfig(userId);
  const [employeePension, setEmployeePension] = useState(null);
  const [userInsurance, setUserInsurance] = useState(null);
  const [showMessage] = useMessage();
  const fetchUserInsurance = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceOwnerScope) return;
    try {
      if (insuranceQuote == null ? void 0 : insuranceQuote.policyId) {
        setLoadingInsurance(true);
        const userInsurance2 = yield InsuranceAPI.getUserInsurancePolicyById(state.user.userId, true);
        setUserInsurance(userInsurance2);
      }
    } catch (error) {
      showMessage(`Could not get insurance policy details. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoadingInsurance(false);
    }
  }), [hasInsuranceOwnerScope, insuranceQuote == null ? void 0 : insuranceQuote.policyId, state.user.userId, showMessage]);
  const autoEnrollmentCallback = useCallback(() => __async(void 0, null, function* () {
    if (!hasPensionOwnerScope) return;
    try {
      setLoadingPension(true);
      const [employeePension2] = yield Promise.all([
        PensionAPI.getEmployeePension(userId),
        yield refreshUserOverviewConfig()
      ]);
      setEmployeePension(employeePension2 != null ? employeePension2 : null);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoadingPension(false);
    }
  }), [hasPensionOwnerScope, userId, refreshUserOverviewConfig, showMessage]);
  const refreshCustomBenefits = useCallback(() => __async(void 0, null, function* () {
    if (refreshUserCBenefits) yield refreshUserCBenefits();
  }), [refreshUserCBenefits]);
  useEffect(() => {
    if (userOverviewConfig == null ? void 0 : userOverviewConfig.inPension) {
      autoEnrollmentCallback();
    }
  }, [autoEnrollmentCallback, userOverviewConfig == null ? void 0 : userOverviewConfig.inPension]);
  useEffect(() => {
    fetchUserInsurance();
  }, [fetchUserInsurance]);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsxs(Switch, { children: [
    userOverviewConfig.inCustomBenefits && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance"], context: scopesContext, path: CUSTOM_BENEFIT_ME_ROUTE, children: /* @__PURE__ */ jsx(
      CustomBenefitUserRouter,
      {
        userId,
        userBenefits: userBenefits != null ? userBenefits : [],
        loading: !!loadingCustomBenefits,
        refreshCustomBenefits
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance"], context: scopesContext, path: INSURANCE_ME_ROUTE, children: /* @__PURE__ */ jsx(
      InsuranceUserRouter,
      {
        userId,
        userInsurance,
        refresh: fetchUserInsurance,
        loading: loadingInsurance || loading
      }
    ) }),
    (userOverviewConfig == null ? void 0 : userOverviewConfig.inPension) && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["pension"], context: scopesContext, path: PENSION_ME_ROUTE, children: /* @__PURE__ */ jsx(
      PensionMeRouter,
      {
        userId,
        userPension: employeePension,
        refreshUserPension: autoEnrollmentCallback,
        loading: loadingPension || loading
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance", "pension"], context: scopesContext, path: BENEFITS_ME_ROUTE, children: /* @__PURE__ */ jsx(
      BenefitsUserOverviewPage,
      {
        userId,
        userPension: employeePension,
        userInsurance,
        userBenefits: userBenefits != null ? userBenefits : [],
        userOverviewConfig,
        loading: loadingInsurance || loadingPension || loading || !!loadingCustomBenefits
      }
    ) })
  ] }) });
};
