"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { InfoCard } from "@v2/feature/absence/components/info-card.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
const getSingleProductSubscriptionsCost = (subscriptionBills) => {
  return subscriptionBills.reduce((acc, subscription) => {
    acc += subscription.subscriptionCost - subscription.totalDiscount;
    return acc;
  }, 0);
};
export const BillingOverview = ({ subscriptionStats, subscriptionsBillingStats }) => {
  const { polyglot } = usePolyglot();
  const nextInvoiceCost = subscriptionsBillingStats ? Object.keys(subscriptionsBillingStats).reduce((acc, product) => {
    acc += getSingleProductSubscriptionsCost(subscriptionsBillingStats[Number(product)]);
    return acc;
  }, 0) : 0;
  const activeUsers = subscriptionStats.companyStats.noOfActiveUsers;
  const rentedDevices = subscriptionStats.companyStats.noOfRentedDevices;
  const activeAccountsText = `${activeUsers} user${activeUsers === 1 ? "" : "s"}`;
  const today = /* @__PURE__ */ new Date();
  const nextInvoiceDate = new Date(today.getFullYear(), today.getMonth() + 1, 1).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric"
  });
  const rentedDevicesText = `${rentedDevices} device${rentedDevices === 1 ? "" : "s"}`;
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap60 }, children: [
    nextInvoiceCost > 0 && /* @__PURE__ */ jsx(InfoCard, { title: polyglot.t("BillingOverview.nextInvoiceDate"), value: nextInvoiceDate }),
    nextInvoiceCost > 0 && /* @__PURE__ */ jsx(InfoCard, { title: polyglot.t("BillingOverview.amount"), value: formatMoney({ amount: nextInvoiceCost }) }),
    /* @__PURE__ */ jsx(InfoCard, { title: polyglot.t("BillingOverview.active"), value: activeAccountsText }),
    rentedDevices > 0 && /* @__PURE__ */ jsx(InfoCard, { title: polyglot.t("BillingOverview.rented"), value: rentedDevicesText })
  ] });
};
