"use strict";
import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import { AbsenceViewDrawer } from "@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page";
export const PendingPHCancellationActionItem = ({
  timeActionRow,
  refresh,
  afterClose
}) => {
  const [isOpen, setIsOpen] = useState(!!timeActionRow);
  return /* @__PURE__ */ jsx(
    AbsenceViewDrawer,
    {
      isOpen,
      absence: timeActionRow,
      setIsAbsenceDrawerViewOpen: setIsOpen,
      showCalendarLink: true,
      afterClose,
      refresh
    }
  );
};
