"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useContext } from "react";
import { ActivationLockZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/activation-lock-zelt-mdm.page";
import { AppsZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/apps-zelt-mdm.page";
import { OsUpdatesMobileZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/os-updates-mobile-zelt-mdm.page";
import { PasscodeMobileZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/passcode-mobile-zelt-mdm.page";
import { RestrictionsMobileZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/restrictions-mobile-zelt-mdm.page";
import { WifiMobileZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/mobile/wifi-mobile-zelt-mdm.page";
import { EncryptionZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/encryption-zelt-mdm.page";
import { FirewallZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/firewall-zelt-mdm.page";
import { OsUpdatesZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/os-updates-zelt-mdm.page";
import { PasscodeZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/passcode-zelt-mdm.page";
import { RestrictionsZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/restrictions-zelt-mdm.page";
import { ScreenSettingsZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/screen-settings-zelt-mdm.page";
import { WifiZeltMdmPage } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/wifi-zelt-mdm.page";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  SETTINGS_DEVICES_GENERAL_ROUTE,
  SETTINGS_DEVICES_ZELT_ACTIVATION_LOCK_ROUTE,
  SETTINGS_DEVICES_ZELT_APPS_ROUTE,
  SETTINGS_DEVICES_ZELT_ENCRYPTION_ROUTE,
  SETTINGS_DEVICES_ZELT_FIREWALL_ROUTE,
  SETTINGS_DEVICES_ZELT_OS_UPDATES_ROUTE,
  SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE,
  SETTINGS_DEVICES_ZELT_RESTRICTIONS_ROUTE,
  SETTINGS_DEVICES_ZELT_ROUTE,
  SETTINGS_DEVICES_ZELT_SCREEN_SETTINGS_ROUTE,
  SETTINGS_DEVICES_ZELT_WIFI_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_OS_UPDATES_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_RESTRICTIONS_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_ROUTE,
  SETTINGS_MOBILE_DEVICES_ZELT_WIFI_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { DeviceScopes } from "@/v2/feature/device/device.scopes";
export const getAppleSettingsPageConfig = (polyglot, currentUser, context) => {
  let menu = [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: "Passcode",
          stub: "passcode",
          path: SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "Encryption",
          stub: "encryption",
          path: SETTINGS_DEVICES_ZELT_ENCRYPTION_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "Firewall",
          stub: "firewall",
          path: SETTINGS_DEVICES_ZELT_FIREWALL_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "Apps",
          stub: "apps",
          path: SETTINGS_DEVICES_ZELT_APPS_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "Screen",
          stub: "screenSettings",
          path: SETTINGS_DEVICES_ZELT_SCREEN_SETTINGS_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "Restrictions",
          stub: "restrictions",
          path: SETTINGS_DEVICES_ZELT_RESTRICTIONS_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "WiFi",
          stub: "wifi",
          path: SETTINGS_DEVICES_ZELT_WIFI_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "OS updates",
          stub: "os_updates",
          path: SETTINGS_DEVICES_ZELT_OS_UPDATES_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "Activation lock",
          stub: "activation_lock",
          path: SETTINGS_DEVICES_ZELT_ACTIVATION_LOCK_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        }
      ]
    }
  ];
  return menu;
};
export const getMobileDevicesAppleSettingsPageConfig = (polyglot, currentUser, context) => {
  let menu = [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: "Passcode",
          stub: "passcode",
          path: SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "Restrictions",
          stub: "restrictions",
          path: SETTINGS_MOBILE_DEVICES_ZELT_RESTRICTIONS_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "WiFi",
          stub: "wifi",
          path: SETTINGS_MOBILE_DEVICES_ZELT_WIFI_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        },
        {
          title: "OS updates",
          stub: "os_updates",
          path: SETTINGS_MOBILE_DEVICES_ZELT_OS_UPDATES_ROUTE,
          isHidden: !checkScopes(currentUser, ["user:read:all"], context),
          hasChildren: false
        }
      ]
    }
  ];
  return menu;
};
export const ZeltMdmRouter = () => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "MacOS",
        pageConfig: getAppleSettingsPageConfig(polyglot, currentUser, scopesContext),
        backPath: SETTINGS_DEVICES_GENERAL_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE,
          children: /* @__PURE__ */ jsx(PasscodeZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_ENCRYPTION_ROUTE,
          children: /* @__PURE__ */ jsx(EncryptionZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_FIREWALL_ROUTE,
          children: /* @__PURE__ */ jsx(FirewallZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_APPS_ROUTE,
          children: /* @__PURE__ */ jsx(AppsZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_SCREEN_SETTINGS_ROUTE,
          children: /* @__PURE__ */ jsx(ScreenSettingsZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_RESTRICTIONS_ROUTE,
          children: /* @__PURE__ */ jsx(RestrictionsZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_WIFI_ROUTE,
          children: /* @__PURE__ */ jsx(WifiZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_OS_UPDATES_ROUTE,
          children: /* @__PURE__ */ jsx(OsUpdatesZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_DEVICES_ZELT_ACTIVATION_LOCK_ROUTE,
          children: /* @__PURE__ */ jsx(ActivationLockZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_DEVICES_ZELT_ROUTE, to: SETTINGS_DEVICES_ZELT_PASSCODE_ROUTE })
    ] })
  ] });
};
export const ZeltMobileMdmRouter = () => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: "iOS and iPadOS",
        pageConfig: getMobileDevicesAppleSettingsPageConfig(polyglot, currentUser, scopesContext),
        backPath: SETTINGS_DEVICES_GENERAL_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE,
          children: /* @__PURE__ */ jsx(PasscodeMobileZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_MOBILE_DEVICES_ZELT_RESTRICTIONS_ROUTE,
          children: /* @__PURE__ */ jsx(RestrictionsMobileZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_MOBILE_DEVICES_ZELT_WIFI_ROUTE,
          children: /* @__PURE__ */ jsx(WifiMobileZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          context: scopesContext,
          exact: true,
          scopes: DeviceScopes.VIEW_DEVICE_COMPANY_SETTINGS,
          path: SETTINGS_MOBILE_DEVICES_ZELT_OS_UPDATES_ROUTE,
          children: /* @__PURE__ */ jsx(OsUpdatesMobileZeltMdmPage, {})
        }
      ),
      /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_MOBILE_DEVICES_ZELT_ROUTE, to: SETTINGS_MOBILE_DEVICES_ZELT_PASSCODE_ROUTE })
    ] })
  ] });
};
