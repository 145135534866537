"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { isUrlValid } from "@v2/feature/device/device.util";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const DeviceOrderTrackingLinkForm = ({ deviceOrder }) => {
  const [trackingLink, setTrackingLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const trackingLink2 = yield DeviceAPI.getOrderTrackingLinkAsSuperAdmin(deviceOrder.id);
        setTrackingLink(trackingLink2 != null ? trackingLink2 : "");
      } catch (error) {
        showMessage(`Could not get order tracking link. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [deviceOrder.id, showMessage]);
  const updateTrackingLink = () => __async(void 0, null, function* () {
    if (!trackingLink) return;
    try {
      setLoading(true);
      const validatedLink = trackingLink.startsWith("https://") ? trackingLink : `https://${trackingLink}`;
      if (!isUrlValid(validatedLink)) throw new Error("Tracking link is not valid!");
      yield DeviceAPI.updateOrderTrackingLink(deviceOrder.id, validatedLink);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap20, alignItems: "end" }, children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Tracking Link",
        name: "trackingLink",
        value: trackingLink,
        onChange: (event) => {
          setTrackingLink(event.target.value);
        }
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Update",
        loading,
        onClick: updateTrackingLink,
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: !trackingLink
      }
    )
  ] });
};
