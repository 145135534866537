"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const urlOrBase64Regex = /^(https?:\/\/[^\s]+|data:[\w/\-+]+;base64,[A-Za-z0-9+/=]+)(?!.*(\/|\\))/;
export const isLocalFilePath = (imgSrc) => {
  return !urlOrBase64Regex.test(imgSrc);
};
export const blobToBase64 = (blob) => __async(void 0, null, function* () {
  return yield new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
});
