"use strict";
import * as Yup from "yup";
const getTemplateFieldShape = (requiredFields) => {
  if (!requiredFields) return;
  const finalSchema = {};
  for (const key of Object.keys(requiredFields)) {
    finalSchema[key] = templateShape[key];
  }
  return finalSchema;
};
export const getTemplateSchema = (requiredFields) => Yup.object().shape(getTemplateFieldShape(requiredFields));
const templateShape = {
  employee_first_name: Yup.string(),
  employee_last_name: Yup.string(),
  employee_role: Yup.string(),
  employee_start_date: Yup.date().typeError("Invalid date format."),
  employee_address: Yup.string(),
  employee_notice_period: Yup.string(),
  employee_probation_period: Yup.string(),
  compensation_rate: Yup.string(),
  compensation_salary_basis: Yup.string(),
  compensation_equity: Yup.string(),
  // compensation_paid_time_off: Yup.string(),
  company_logo: Yup.string(),
  company_signature: Yup.string()
};
