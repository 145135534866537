"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { getDeviceOwnerByDevicePossession } from "@v2/feature/device/device.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { keyBy } from "lodash";
import { SiteAPI } from "@/api-client/site.api";
import PigeonMap from "@/component/widgets/PigeonMap";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
export const DevicesLocationPage = ({ devicesPossessions, loading }) => {
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [sitesById, setSitesById] = useState({});
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesById(keyBy(sites, "id"));
      } catch (error) {
        showMessage(`Could not list sites. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage]);
  const inUseDevices = devicesPossessions.filter((devicePossession) => {
    var _a;
    return (_a = devicePossession.device) == null ? void 0 : _a.lastLocation;
  });
  const markers = inUseDevices.filter((devicePossession) => {
    var _a;
    return Boolean((_a = devicePossession.device) == null ? void 0 : _a.lastLocation);
  }).map((devicePossession) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    return {
      latitude: Number((_b = (_a = devicePossession.device) == null ? void 0 : _a.lastLocation) == null ? void 0 : _b.latitude),
      longitude: Number((_d = (_c = devicePossession.device) == null ? void 0 : _c.lastLocation) == null ? void 0 : _d.longitude),
      name: (_f = (_e = devicePossession.device) == null ? void 0 : _e.lastLocation) == null ? void 0 : _f.name,
      time: ((_h = (_g = devicePossession.device) == null ? void 0 : _g.lastLocation) == null ? void 0 : _h.time) ? (_j = (_i = devicePossession.device) == null ? void 0 : _i.lastLocation) == null ? void 0 : _j.time : null,
      owner: getDeviceOwnerByDevicePossession(
        devicePossession,
        sitesById[devicePossession.possessionId],
        getCachedUserById
      )
    };
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Devices locations" }),
        showBack: true,
        backPath: DEVICES_COMPANY_OVERVIEW_ROUTE
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsx(Card, { children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", mr: 1, width: "100%", height: "75vh" }, children: /* @__PURE__ */ jsx(PigeonMap, { markers, zoomControl: true }) }) }) })
  ] });
};
