"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
export const PaymentEntitySelect = ({
  name,
  label,
  value,
  values,
  disabled,
  onChange,
  error,
  helperText
}) => {
  const options = useMemo(() => {
    const uniqueValues = new Set(values);
    uniqueValues.add(value);
    return [...uniqueValues].filter((x) => x).sort().map((value2) => ({
      label: value2,
      value: value2
    }));
  }, [value, values]);
  return /* @__PURE__ */ jsx(
    AutocompleteComponent,
    {
      name,
      label,
      options,
      value: options.find((d) => d.value === value),
      disabled,
      freeSolo: true,
      onBlur: (e) => {
        onChange == null ? void 0 : onChange(e.target.value);
      },
      onChange: (e, update) => {
        if (Array.isArray(update)) return;
        if (update) {
          onChange == null ? void 0 : onChange(typeof update === "object" ? update.value : update);
        } else {
          onChange == null ? void 0 : onChange("");
        }
      },
      error,
      helperText
    }
  );
};
