"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ArrowUp } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowUpSelect.svg";
import { secondaryTextSmallBtn } from "@/v2/styles/buttons.styles";
const iconSize = { width: 14, height: 14 };
export const ShowHideButton = ({
  showDetails,
  setShowDetails,
  showTitle,
  hideTitle
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Fragment, { children: showDetails ? /* @__PURE__ */ jsxs(
    Typography,
    {
      sx: __spreadProps(__spreadValues({}, secondaryTextSmallBtn), { display: "flex", alignItems: "center" }),
      onClick: () => setShowDetails(false),
      children: [
        hideTitle != null ? hideTitle : polyglot.t("ShowHideButton.hideHistory"),
        " ",
        /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize))
      ]
    }
  ) : /* @__PURE__ */ jsxs(
    Typography,
    {
      title: polyglot.t("ShowHideButton.showDetails"),
      sx: __spreadProps(__spreadValues({}, secondaryTextSmallBtn), { display: "flex", alignItems: "center" }),
      onClick: () => setShowDetails(true),
      children: [
        showTitle != null ? showTitle : polyglot.t("ShowHideButton.showHistory"),
        " ",
        /* @__PURE__ */ jsx(ArrowUp, __spreadValues({}, iconSize))
      ]
    }
  ) });
};
