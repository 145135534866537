"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { FieldValueComponent } from "@v2/feature/device/device.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as GreenOkay } from "@/images/side-bar-icons/ok-green.svg";
export const WifiPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  configurationPayloadOption
}) => {
  const { polyglot } = usePolyglot();
  if (!configurationPayloadOption) return null;
  const entries = Object.entries(configurationPayloadOption);
  const payloadIdentifierEntry = entries.filter(([key]) => key === "PayloadIdentifier");
  const passwordEntry = entries.find(([key]) => key === "Password");
  const otherEntries = entries.filter(([key]) => !["PayloadIdentifier", "Password"].includes(key));
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: policyName }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        component: "dl",
        sx: { display: "flex", flexDirection: "column", mt: spacing.m20, padding: 0, gap: spacing.g15 },
        children: [
          otherEntries.map(([key, value]) => /* @__PURE__ */ jsx(
            FieldValueComponent,
            {
              title: polyglot.t(`InHouseMdmPolicyViewDrawer.${key}`),
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: String(value) })
            },
            key
          )),
          payloadIdentifierEntry.map(([key, value]) => /* @__PURE__ */ jsx(
            FieldValueComponent,
            {
              title: polyglot.t(`Payload identifier`),
              value: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: String(value) })
            },
            key
          )),
          passwordEntry ? /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", mt: spacing.m10 }, children: [
              /* @__PURE__ */ jsx(GreenOkay, { width: 15, fill: themeColors.Green, height: 15 }),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.black }), children: "WiFi password" })
            ] }),
            /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m15 }, children: /* @__PURE__ */ jsx(TextfieldComponent, { type: "password", value: passwordEntry, autoFocus: true }) })
          ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", mt: spacing.m20 }, children: [
            /* @__PURE__ */ jsx(Mistake, { width: 15, height: 15 }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Red }), children: "No WiFi password" })
          ] })
        ]
      }
    )
  ] }) });
};
