"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AttendanceEndpoints } from "@/v2/feature/attendance/attendance.api";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { ImportedUserTableCell } from "@/v2/feature/entity-import/wizard/components/user/imported-user-table-cell.component";
import { UserImportResultsTabs } from "@/v2/feature/entity-import/wizard/components/user/user-import-results-tabs.component";
import { validatePeopleImport2 } from "@/v2/feature/entity-import/wizard/entity-import-validator.util";
import { JobPositionEndpoints } from "@/v2/feature/job-position/job-position.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserImportLifecycleForm } from "@/v2/feature/user/features/user-profile/details/components/user-import-lifecycle-form.component";
import { AboutForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-about-form.component";
import { AddressForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component";
import { BankForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-bank-information-form.component";
import { BasicInformationForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-form.component";
import { CompensationForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component";
import { ContractForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-contract-form.component";
import { CustomSectionForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component";
import { EmergencyContactForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-emergency-contact-form.component";
import { FamilyForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-family-form.component";
import { PersonalInformationForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-form.component";
import { RoleForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { getCountryByCode } from "@/v2/infrastructure/country/country.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconCrossButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { camelCaseToTitleCase } from "@/v2/util/string.util";
const UNIMPORTED_USER_ID = 0;
export const UserImportResultTable = ({ result, completeImport, onUpdateAndRevalidate, loading }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [selectedUsers] = useState(/* @__PURE__ */ new Set());
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [viewErrorUsers, setViewErrorUsers] = useState(false);
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const { data: attendanceSchedules } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const [currentFormForEditing, setCurrentFormForEditing] = useState(/* @__PURE__ */ jsx(Fragment, {}));
  const { data: customProfileForms } = useApiClient(CustomProfileFormEndpoints.listForms(), { suspense: false });
  const { data: deptsData, mutate: refreshDept } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false
  });
  const departments = useMemo(() => {
    var _a2;
    return (_a2 = deptsData == null ? void 0 : deptsData.map((dept) => ({ label: dept.name, value: dept.id }))) != null ? _a2 : [];
  }, [deptsData]);
  const { data: siteData, mutate: refreshSites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const sites = useMemo(() => {
    var _a2;
    return (_a2 = siteData == null ? void 0 : siteData.map((site) => ({ label: site.name, value: site.id }))) != null ? _a2 : [];
  }, [siteData]);
  const { data: jobPositionData, mutate: refreshJobPosition } = useApiClient(JobPositionEndpoints.listJobPositions(), {
    suspense: false
  });
  const nextPossibleId = useMemo(() => {
    var _a2;
    if (!jobPositionData) return "";
    const lastJobPosition = jobPositionData == null ? void 0 : jobPositionData.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = (_a2 = lastJobPosition[jobPositionData.length - 1]) == null ? void 0 : _a2.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1e6).toString();
    }
  }, [jobPositionData]);
  const DEFAULT_NEW_JOB_POSITION = useMemo(() => {
    return {
      id: void 0,
      internalCode: nextPossibleId,
      title: "",
      description: ""
    };
  }, [nextPossibleId]);
  const jobPositions = useMemo(() => {
    var _a2;
    return (_a2 = jobPositionData == null ? void 0 : jobPositionData.map((eachJobPosition) => ({
      label: eachJobPosition.title,
      value: eachJobPosition.id
    }))) != null ? _a2 : [];
  }, [jobPositionData]);
  const { nonTerminatedCachedUsers: companyUsers } = useCachedUsers({ refresh: false });
  const managers = useMemo(
    () => companyUsers.map((rec) => __spreadValues({ label: rec.displayName, value: rec.userId }, rec)),
    [companyUsers]
  );
  const UserImportTableFormatters = useMemo(
    () => ({
      Role: {
        departmentId: {
          header: "Department",
          rawCellValue: (item) => {
            var _a2;
            if (!(item == null ? void 0 : item.departmentId)) return "";
            return (_a2 = deptsData == null ? void 0 : deptsData.find((d) => d.id === item.departmentId)) == null ? void 0 : _a2.name;
          }
        },
        siteId: {
          header: "Site",
          rawCellValue: (item) => {
            var _a2;
            if (!(item == null ? void 0 : item.siteId)) return "";
            return (_a2 = siteData == null ? void 0 : siteData.find((s) => s.id === item.siteId)) == null ? void 0 : _a2.name;
          }
        }
      },
      Contract: {
        entityId: {
          header: "Entity",
          rawCellValue: (item) => {
            var _a2;
            if (!(item == null ? void 0 : item.entityId)) return "";
            return (_a2 = generalSettings == null ? void 0 : generalSettings.entities.find((e) => e.id === item.entityId)) == null ? void 0 : _a2.legalName;
          }
        },
        publicHolidays: {
          header: "Public Holidays",
          rawCellValue: (item) => {
            var _a2;
            if (!(item == null ? void 0 : item.publicHolidays)) return "";
            return (_a2 = getCountryByCode(item.publicHolidays)) == null ? void 0 : _a2.name;
          }
        },
        attendanceScheduleId: {
          header: "Attendance Schedule",
          rawCellValue: (item) => {
            var _a2;
            if (!(item == null ? void 0 : item.attendanceScheduleId)) return "";
            return (_a2 = attendanceSchedules == null ? void 0 : attendanceSchedules.find((s) => s.id === item.attendanceScheduleId)) == null ? void 0 : _a2.name;
          }
        }
      }
    }),
    [attendanceSchedules, deptsData, generalSettings == null ? void 0 : generalSettings.entities, siteData]
  );
  const userIdentifierColumn = useCallback(() => {
    const userIdentColumn = {
      header: () => "User",
      accessorFn: (row) => row,
      id: "user-import-identifier",
      enableSorting: false,
      cell: (info) => {
        var _a2;
        return (_a2 = info.row.original.entity.default.Basic.values.emailAddress) != null ? _a2 : "(Missing user ID)";
      }
    };
    return userIdentColumn;
  }, []);
  const buildDefaultFormColumns = useCallback(
    (userImport, sectionName) => {
      var _a2;
      const section = userImport.default[sectionName];
      const values = section.values;
      if (!values) return [];
      const fields = Object.keys(values).filter((fieldName) => fieldName !== "customUpdates");
      const userIdColumn = "emailAddress" in values ? [] : [userIdentifierColumn()];
      return [
        ...userIdColumn,
        ...fields.map(
          (fieldName) => ({
            header: () => {
              var _a3, _b2, _c2;
              return (
                // @ts-ignore
                (_c2 = (_b2 = (_a3 = UserImportTableFormatters[sectionName]) == null ? void 0 : _a3[fieldName]) == null ? void 0 : _b2.header) != null ? _c2 : camelCaseToTitleCase(fieldName)
              );
            },
            accessorFn: (row) => row,
            id: fieldName,
            enableSorting: false,
            cell: (info) => {
              var _a3, _b2, _c2, _d, _e, _f;
              const data = (_a3 = info.row.original.entity.default[sectionName]) == null ? void 0 : _a3.values;
              const field = fieldName;
              const value = (
                // @ts-ignore
                (_f = (_d = (_c2 = (_b2 = UserImportTableFormatters[sectionName]) == null ? void 0 : _b2[fieldName]) == null ? void 0 : _c2.rawCellValue) == null ? void 0 : _d.call(_c2, data)) != null ? _f : `${(_e = data == null ? void 0 : data[field]) != null ? _e : ""}`
              );
              return /* @__PURE__ */ jsx(
                ImportedUserTableCell,
                {
                  rawCellValue: value,
                  rowErrors: info.row.original.errors,
                  fieldKey: info.column.id
                }
              );
            },
            maxSize: 150,
            minSize: 150
          })
        ),
        ...((_a2 = values.customUpdates) != null ? _a2 : []).map((customField) => ({
          header: () => camelCaseToTitleCase(customField.field.fieldName),
          accessorFn: (row) => row,
          id: customField.fieldId,
          enableSorting: false,
          cell: (info) => {
            var _a3, _b2;
            const data = (_a3 = info.row.original.entity.default[sectionName].values) == null ? void 0 : _a3.customUpdates;
            const field = data == null ? void 0 : data.find((f) => f.fieldId === customField.fieldId);
            return /* @__PURE__ */ jsx(
              ImportedUserTableCell,
              {
                rawCellValue: `${(_b2 = field == null ? void 0 : field.value) != null ? _b2 : ""}`,
                rowErrors: info.row.original.errors,
                fieldKey: info.column.id
              }
            );
          },
          maxSize: 150,
          minSize: 150
        })),
        {
          header: () => "",
          accessorFn: (row) => row,
          id: "actions",
          enableSorting: false,
          cell: (info) => {
            if (sectionName === "Basic") {
              return /* @__PURE__ */ jsx(Fragment, {});
            }
            return /* @__PURE__ */ jsx(
              IconButton,
              {
                sx: __spreadProps(__spreadValues({}, iconCrossButtonSx), { backgroundColor: themeColors.white, fill: themeColors.DarkGrey }),
                title: `Clear ${sectionName.toLowerCase()} information for ${info.row.original.entity.default.Basic.values.emailAddress}`,
                onClick: (e) => __async(void 0, null, function* () {
                  e.stopPropagation();
                  info.row.original.entity.default[sectionName].values = null;
                  const updated = yield validatePeopleImport2(
                    result.errors.map((e2) => e2.entity),
                    polyglot
                  );
                  onUpdateAndRevalidate(updated);
                }),
                children: /* @__PURE__ */ jsx(Close, { width: 16, height: 16, fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey })
              }
            );
          },
          maxSize: 50,
          minSize: 50
        }
      ];
    },
    [UserImportTableFormatters, onUpdateAndRevalidate, result, userIdentifierColumn, polyglot]
  );
  const buildCustomFormColumns = useCallback(
    (tab, record) => {
      return [
        userIdentifierColumn(),
        ...record.fields.map(
          (customField) => ({
            header: () => customField.field.fieldName,
            accessorFn: (row) => row,
            id: customField.fieldId,
            enableSorting: false,
            cell: (info) => {
              var _a2;
              const data = info.row.original.entity.custom.find(({ tabName }) => tabName === tab);
              const field = data == null ? void 0 : data.fields.find((f) => f.fieldId === customField.fieldId);
              return /* @__PURE__ */ jsx(
                ImportedUserTableCell,
                {
                  rawCellValue: `${(_a2 = field == null ? void 0 : field.value) != null ? _a2 : ""}`,
                  rowErrors: info.row.original.errors,
                  fieldKey: info.column.id
                }
              );
            },
            maxSize: 150,
            minSize: 150
          })
        )
      ];
    },
    [userIdentifierColumn]
  );
  const tabsAndColumns = useMemo(() => {
    if (!result.errors || result.errors.length === 0) return [];
    const tabsAndColumns2 = [];
    const completedTabs = /* @__PURE__ */ new Set();
    for (const [key, { tabName }] of Object.entries(result.errors[0].entity.default)) {
      const sectionName = key;
      const entityWithValues = result.errors.find((e) => e.entity.default[sectionName].values);
      if (entityWithValues) {
        const columns = buildDefaultFormColumns(entityWithValues.entity, sectionName);
        tabsAndColumns2.push({ tabName, formId: null, columns });
        completedTabs.add(tabName);
      }
    }
    for (const { entity } of result.errors) {
      entity.custom.forEach((c) => {
        if (completedTabs.has(c.tabName)) return;
        const columns = buildCustomFormColumns(c.tabName, c);
        tabsAndColumns2.push({ tabName: c.tabName, formId: c.formId, columns });
        completedTabs.add(c.tabName);
      });
    }
    return tabsAndColumns2;
  }, [buildCustomFormColumns, buildDefaultFormColumns, result.errors]);
  const editEntity = useCallback(
    (tab, entity) => {
      var _a2, _b2;
      let currentForm;
      function closeFormAndRevalidate() {
        return __async(this, null, function* () {
          const updated = yield validatePeopleImport2(
            result.errors.map((e) => e.entity),
            polyglot
          );
          onUpdateAndRevalidate(updated);
          setIsOpen(false);
        });
      }
      switch (tab) {
        case "Basic":
          currentForm = /* @__PURE__ */ jsx(
            BasicInformationForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Basic.values,
              onSubmit: (_values) => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Basic.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              handleRefresh: () => {
              }
            }
          );
          break;
        case "Details":
          currentForm = /* @__PURE__ */ jsx(
            PersonalInformationForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Details.values,
              onSubmit: (_values) => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Details.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false)
            }
          );
          break;
        case "Address":
          currentForm = /* @__PURE__ */ jsx(
            AddressForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Address.values,
              onSubmit: () => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Address.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              handleRefresh: () => {
              }
            }
          );
          break;
        case "Lifecycle":
          currentForm = /* @__PURE__ */ jsx(
            UserImportLifecycleForm,
            {
              initialValues: entity.default.Lifecycle.values,
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Lifecycle.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false)
            }
          );
          break;
        case "Role":
          currentForm = /* @__PURE__ */ jsx(
            RoleForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Role.values,
              onSubmit: () => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Role.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              departments,
              managers,
              sites,
              jobPositions,
              onRefresh: () => {
              },
              refreshSites,
              refreshDept,
              refreshJobPosition,
              defaultJobPosition: DEFAULT_NEW_JOB_POSITION
            }
          );
          break;
        case "Salary":
          currentForm = /* @__PURE__ */ jsx(
            CompensationForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Salary.values,
              handleSubmit: (_) => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Salary.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              getUserCompensation: () => __async(void 0, null, function* () {
              }),
              rowModalMode: "add",
              setFormCurrentlyEditing: () => {
              },
              setIsModalOpen: () => {
              },
              setLoading: () => {
              },
              loading: false
            }
          );
          break;
        case "Bank account":
          currentForm = /* @__PURE__ */ jsx(
            BankForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.BankAccount.values,
              handleSubmit: (_) => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.BankAccount.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              defaultName: "",
              getUserBankAccounts: () => __async(void 0, null, function* () {
              }),
              rowModalMode: "add",
              setFormCurrentlyEditing: () => {
              },
              setIsModalOpen: () => {
              },
              setLoading: () => {
              },
              loading: false
            }
          );
          break;
        case "Family":
          currentForm = /* @__PURE__ */ jsx(
            FamilyForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Family.values,
              usedForDataImport: true,
              onSubmit: (values) => __async(void 0, null, function* () {
                entity.default.Family.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              userFamilyMembers: [],
              refreshMembers: () => __async(void 0, null, function* () {
              }),
              handleRefresh: () => {
              }
            }
          );
          break;
        case "Contract":
          currentForm = /* @__PURE__ */ jsx(
            ContractForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Contract.values,
              handleSubmit: (_) => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Contract.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              rowModalMode: "add",
              setFormCurrentlyEditing: () => {
              },
              setIsModalOpen: () => {
              },
              setLoading: () => {
              },
              loading: false,
              loadInitialData: () => __async(void 0, null, function* () {
              })
            }
          );
          break;
        case "About":
          currentForm = /* @__PURE__ */ jsx(
            AboutForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.About.values,
              usedForDataImport: true,
              onSubmit: (values) => __async(void 0, null, function* () {
                entity.default.About.values = values;
                yield closeFormAndRevalidate();
              }),
              onClose: () => setIsOpen(false),
              handleRefresh: () => {
              }
            }
          );
          break;
        case "Emergency contact":
          currentForm = /* @__PURE__ */ jsx(
            EmergencyContactForm,
            {
              userId: UNIMPORTED_USER_ID,
              initialValues: entity.default.Emergency.values,
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                entity.default.Emergency.values = values;
                yield closeFormAndRevalidate();
              }),
              onSubmit: () => {
              },
              onClose: () => setIsOpen(false)
            }
          );
          break;
        default: {
          const formId = (_a2 = tabsAndColumns.find((t) => t.tabName === tab)) == null ? void 0 : _a2.formId;
          const customForm = entity.custom.find((f) => f.formId === formId);
          const form = customProfileForms == null ? void 0 : customProfileForms.find((f) => f.formId === formId);
          if (!formId || !(form == null ? void 0 : form.fields)) return;
          currentForm = /* @__PURE__ */ jsx(
            CustomSectionForm,
            {
              userId: UNIMPORTED_USER_ID,
              form,
              fields: (_b2 = customForm == null ? void 0 : customForm.fields) != null ? _b2 : form.fields.map((f) => ({
                field: f,
                fieldId: f.fieldId,
                formId: f.formId,
                value: ""
              })),
              onSubmit: (_values) => {
              },
              usedForDataImport: true,
              importHandler: (values) => __async(void 0, null, function* () {
                if (customForm) {
                  customForm.fields = values;
                } else {
                  entity.custom.push({
                    tabName: tab,
                    formId,
                    fields: values
                  });
                }
                const updated = yield validatePeopleImport2(
                  result.errors.map((e) => e.entity),
                  polyglot
                );
                onUpdateAndRevalidate(updated);
                setIsOpen(false);
              }),
              onClose: () => setIsOpen(false)
            }
          );
          break;
        }
      }
      setCurrentFormForEditing(currentForm);
      setIsOpen(true);
    },
    [
      polyglot,
      customProfileForms,
      departments,
      jobPositions,
      managers,
      onUpdateAndRevalidate,
      refreshJobPosition,
      refreshDept,
      refreshSites,
      result,
      sites,
      tabsAndColumns,
      DEFAULT_NEW_JOB_POSITION
    ]
  );
  const { entriesWithErrors, errorCountByTab } = useMemo(() => {
    const errorCountByTab2 = {};
    const entriesWithErrors2 = [];
    for (const e of result.errors) {
      e.errors.map((e2) => {
        var _a2;
        return errorCountByTab2[e2.domain] = ((_a2 = errorCountByTab2[e2.domain]) != null ? _a2 : 0) + 1;
      });
      if (e.errors.length) {
        entriesWithErrors2.push(e);
      }
    }
    return { entriesWithErrors: entriesWithErrors2, errorCountByTab: errorCountByTab2 };
  }, [result.errors]);
  useEffect(() => {
    if (entriesWithErrors.length === 0 && viewErrorUsers) {
      setViewErrorUsers(false);
    }
  }, [entriesWithErrors.length, viewErrorUsers]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    entriesWithErrors.length > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15, mb: "30px" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Mistake, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("UserImportResultTable.usersHaveProblems") })
      ] }),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("UserImportResultTable.showImportedWithErrors"),
          name: "viewErrorUsers",
          checked: viewErrorUsers,
          onChange: (_, checked) => setViewErrorUsers(checked)
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      UserImportResultsTabs,
      {
        tabs: tabsAndColumns.map((t) => t.tabName),
        currentTab: (_a = tabsAndColumns[tabIndex]) == null ? void 0 : _a.tabName,
        onChange: (tab) => setTabIndex(tabsAndColumns.findIndex((t) => t.tabName === tab)),
        errorCountByTab
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          pt: spacing.p25,
          flex: 1,
          overflow: "auto",
          borderTop: "1px solid",
          borderColor: themeColors.GreyLight
        },
        children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: viewErrorUsers ? entriesWithErrors : [...result.errors],
            columnData: (_c = (_b = tabsAndColumns[tabIndex]) == null ? void 0 : _b.columns) != null ? _c : [],
            rowClick: (row) => {
              editEntity(tabsAndColumns[tabIndex].tabName, row.original.entity);
            },
            loading
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose: () => {
    }, children: currentFormForEditing }),
    entriesWithErrors.length === 0 && /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: "30px" },
        disabled: loading,
        onClick: () => {
          const usersToImport = selectedUsers.size ? [...selectedUsers] : result.errors.map(({ entity }) => entity);
          completeImport([], usersToImport);
        },
        children: "Start import..."
      }
    )
  ] });
};
