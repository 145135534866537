"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { spacing } from "@v2/styles/spacing.styles";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { usePaymentContext } from "@/v2/feature/payments/features/make-payment/payment.context";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const PayerDetailsStep = ({ onNext }) => {
  const [, setState] = usePaymentContext();
  const formik = useFormik({
    initialValues: {
      accountName: "",
      accountNumber: "",
      sortCode: ""
    },
    validationSchema: Yup.object().shape({
      accountName: Yup.string().matches(/^[0-9a-zA-Z ]*$/, "Please avoid any special character").required("Account Name is required"),
      accountNumber: Yup.string().matches(/^[0-9a-zA-Z]*$/, "Please avoid using space or any special character").required("Account Number is required"),
      sortCode: Yup.string().matches(/^[0-9-]*$/, "Please use only numeric characters").required("Sort Code is required")
    }),
    onSubmit: () => goToNextStep()
  });
  const goToNextStep = () => {
    setState(({ payments, requests, institution }) => {
      return {
        payments,
        requests,
        payerDetails: {
          accountName: formik.values.accountName,
          accountNumber: formik.values.accountNumber,
          sortCode: formik.values.sortCode.replaceAll("-", "")
        },
        institution
      };
    });
    onNext();
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center"
      },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: "Please add payer details" }),
        /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt40), children: [
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Account Name",
              name: "accountName",
              value: formik.values.accountName,
              onChange: formik.handleChange,
              error: formik.touched.accountName && Boolean(formik.errors.accountName),
              helperText: formik.touched.accountName && formik.errors.accountName,
              type: "text",
              endAdornment: "none",
              autoFocus: true
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Account Number",
              name: "accountNumber",
              value: formik.values.accountNumber,
              onChange: formik.handleChange,
              error: formik.touched.accountNumber && Boolean(formik.errors.accountNumber),
              helperText: formik.touched.accountNumber && formik.errors.accountNumber,
              type: "text",
              endAdornment: "none",
              autoFocus: true
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Sort Code",
              name: "sortCode",
              fullWidth: true,
              value: formik.values.sortCode,
              onChange: formik.handleChange,
              error: formik.touched.sortCode && Boolean(formik.errors.sortCode),
              helperText: formik.touched.sortCode && formik.errors.sortCode,
              type: "text",
              endAdornment: "none",
              autoFocus: true
            }
          ) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            type: "submit",
            onClick: () => formik.handleSubmit(),
            fullWidth: true,
            children: "Next"
          }
        ) })
      ] })
    }
  ) });
};
