"use strict";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import differenceInDays from "date-fns/differenceInDays";
import differenceInWeeks from "date-fns/differenceInWeeks";
import { PayrollStatusLabel } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const formatPayrunState = (state, polyglot) => {
  return {
    [PayrunStates.draft]: () => polyglot.t("PayrunStates.draft"),
    [PayrunStates.inProgress]: () => polyglot.t("PayrunStates.inProgress"),
    [PayrunStates.partiallyCompleted]: () => polyglot.t("PayrunStates.partiallyComplete"),
    [PayrunStates.completed]: () => polyglot.t("PayrunStates.completed")
  }[state]();
};
export const formatPayPeriod = (payPeriod, polyglot) => {
  return {
    Monthly: () => polyglot.t("PayPeriod.monthly"),
    Weekly: () => polyglot.t("PayPeriod.weekly")
  }[payPeriod]();
};
export const formatPayPeriodIncome = (payPeriod, polyglot) => {
  return {
    Monthly: () => polyglot.t("PayrunTable.monthlyIncome"),
    Weekly: () => polyglot.t("PayrunTable.weeklyIncome")
  }[payPeriod]();
};
export const formatUserPayrollStatus = (status, polyglot) => {
  return {
    [PayrollStatusLabel.Current]: () => polyglot.t("PayrunUserStates.current"),
    [PayrollStatusLabel.NewJoiner]: () => polyglot.t("PayrunUserStates.newJoiner"),
    [PayrollStatusLabel.Leaver]: () => polyglot.t("PayrunUserStates.leaver"),
    [PayrollStatusLabel.NotInPayroll]: () => polyglot.t("PayrunUserStates.notInPayroll")
  }[status]();
};
export const formatSalaryBasis = (salaryBasis, polyglot) => {
  if (!salaryBasis) return "";
  return {
    Annual: () => polyglot.t("SalaryBasis.annual"),
    Monthly: () => polyglot.t("SalaryBasis.monthly"),
    Weekly: () => polyglot.t("SalaryBasis.weekly"),
    Daily: () => polyglot.t("SalaryBasis.daily"),
    Hourly: () => polyglot.t("SalaryBasis.hourly")
  }[salaryBasis]();
};
export function getTaxYearStartDate(taxYearStart, date) {
  const [_, yyyy, mm_dd] = date.match(/^(\d\d\d\d)-(\d\d-\d\d)/);
  if (mm_dd < taxYearStart) {
    return /* @__PURE__ */ new Date(`${Number(yyyy) - 1}-${taxYearStart}`);
  }
  return /* @__PURE__ */ new Date(`${yyyy}-${taxYearStart}`);
}
export function formatPayrunPeriod(payrun, locales) {
  var _a;
  const taxYearStart = (_a = payrun.taxYearStart) != null ? _a : "04-06";
  if ("period" in payrun) {
    const year = payrun.taxYear.slice(4);
    if (payrun.payPeriod === "Weekly") {
      return `W${payrun.period} / ${year}`;
    }
    const startOfTaxYear = /* @__PURE__ */ new Date(`${year}-${taxYearStart}`);
    return addMonths(startOfTaxYear, payrun.period - 1).toLocaleString(locales, {
      month: "long",
      year: "numeric"
    });
  }
  if (payrun.payPeriod === "Weekly") {
    const endDate = new Date(payrun.firstPaymentDate);
    const startOfTaxYear = getTaxYearStartDate(taxYearStart, payrun.firstPaymentDate);
    const offsetToW1Monday = (startOfTaxYear.getDay() + 6) % 7;
    const weekNo = differenceInWeeks(endDate, addDays(startOfTaxYear, -offsetToW1Monday)) + 1;
    return `W${weekNo} / ${startOfTaxYear.getFullYear()}`;
  }
  return new Date(payrun.firstPaymentDate).toLocaleString(locales, {
    month: "long",
    year: "numeric"
  });
}
export const formatDueDateMessage = (paymentDate, polyglot) => {
  const days = differenceInDays(Date.parse(paymentDate), Date.parse(todaysDateShortISOString()));
  if (days === 1) {
    return [polyglot.t("DueDates.tomorrow"), false];
  }
  if (days > 0) {
    return [polyglot.t("DueDates.dueInDays", { smart_count: days }), false];
  }
  if (days < 0) {
    return [polyglot.t("DueDates.overdueByDays", { smart_count: -days }), true];
  }
  return [polyglot.t("DueDates.today"), false];
};
