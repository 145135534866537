"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class RequestFormsAPI {
  static canRequestBeCreatedOnPlan() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/requests/form/template/allowed")).data;
    });
  }
  static createFormTemplate(data) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/requests/form/template", data)).data;
    });
  }
  static updateFormTemplate(data) {
    return __async(this, null, function* () {
      return (yield axios.patch("/apiv2/requests/form/template", data)).data;
    });
  }
  static deleteFormTemplate(id) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/requests/form/template/${id}`);
    });
  }
  static submitForm(data) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/requests/form/submitted", data)).data;
    });
  }
  static setSubmittedFormStatus(id, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/requests/form/submitted/${id}/status`, data);
    });
  }
  static deleteSubmittedForm(id) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/requests/form/submitted/${id}`);
    });
  }
}
export class RequestFormsEndpoints {
  static getFormTemplates() {
    return {
      url: "/apiv2/requests/form/template"
    };
  }
  static getFormsAvailableForMe() {
    return {
      url: "/apiv2/requests/form/template/member/me"
    };
  }
  static getSubmittedById(id) {
    return {
      url: `/apiv2/requests/form/submitted/${id}`
    };
  }
}
