"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { getDateType } from "@v2/feature/reports/util/report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import saveAs from "file-saver";
import JSZip from "jszip";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORTS_CREATE_OLD_ROUTE, REPORTS_UPDATE_OLD_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortDate, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { iconSize } from "@/v2/feature/onboarding/onboarding-template-edit.page";
import { ReportsEmptyState } from "@/v2/feature/reports/components/reports-empty-state.component";
import { ReportsAPI, ReportsEndpoints } from "@/v2/feature/reports/reports.api";
import { DataDomain, ReportTypePeopleSelection } from "@/v2/feature/reports/reports.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserAPI } from "@/v2/feature/user/user.api";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReportsPage = () => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const {
    data: reportsData,
    isLoading: loading,
    mutate: refreshReportsList
  } = useApiClient(ReportsEndpoints.getAllReports(), { suspense: false });
  const routerHistory = useHistory();
  const [filteredData, setFilteredData] = useState(void 0);
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [searchInput, setSearchInput] = useState("");
  const [state, dispatch] = useContext(GlobalContext);
  const refreshReports = useCallback(() => __async(void 0, null, function* () {
    if (refreshReportsList) yield refreshReportsList();
  }), [refreshReportsList]);
  useEffect(() => {
    var _a2;
    let filteredData2;
    if (searchInput.length) {
      filteredData2 = (_a2 = reportsData == null ? void 0 : reportsData.filter((r) => r.name.includes(searchInput) || r.type.includes(searchInput))) != null ? _a2 : [];
      setFilteredData(filteredData2);
    } else {
      setFilteredData(reportsData != null ? reportsData : []);
    }
  }, [searchInput, reportsData]);
  const handleDownloadClick = useCallback(
    (report) => __async(void 0, null, function* () {
      try {
        const zip = new JSZip();
        if (report.config.type === ReportTypePeopleSelection.CurrentRecords && report.config.domain === DataDomain.People) {
          const allCsvs = yield ReportsAPI.exportCurrentReportById(report.id);
          zip.file(`report-${report.name}.csv`, allCsvs);
          zip.generateAsync({ type: "blob" }).then(function(content) {
            saveAs(content, `${report.name}.zip`);
          });
        } else {
          const { allCsvs, allKeys } = yield ReportsAPI.exportReportById(report.id);
          allCsvs.map((file, idx) => zip.file(`report-${allKeys[idx]}.csv`, file));
          zip.generateAsync({ type: "blob" }).then(function(content) {
            saveAs(content, `${report.name}.zip`);
          });
        }
      } catch (e) {
        showMessage(polyglot.t("ReportsPage.errorMessages.download"), "error");
      }
    }),
    [showMessage, polyglot]
  );
  const getReportsActionsOptions = useCallback(
    (original) => {
      return [
        {
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            try {
              yield ReportsAPI.deleteReport(original.id);
              showMessage(polyglot.t("ReportsPage.successMessages.delete"), "success");
              yield refreshReports();
            } catch (e) {
              showMessage(nestErrorMessage(e), "error");
            }
          }),
          label: polyglot.t("ReportsPage.delete"),
          disabled: false
        },
        {
          icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            try {
              yield handleDownloadClick(original);
            } catch (e) {
              showMessage(nestErrorMessage(e), "error");
            }
          }),
          label: polyglot.t("ReportsPage.download"),
          disabled: false
        }
      ];
    },
    [polyglot, showMessage, refreshReports, handleDownloadClick]
  );
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: () => polyglot.t("ReportsPage.name"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.name ? /* @__PURE__ */ jsx(Box, { children: original.name }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "data",
        header: () => polyglot.t("ReportsPage.data"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.config.domain),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.config.domain ? /* @__PURE__ */ jsx(Box, { children: original.config.domain }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "type",
        header: () => polyglot.t("ReportsPage.type"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.type),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.config.type ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.config.type }),
          original.config.type === ReportTypePeopleSelection.ChangeReport && original.config.start && original.config.end && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getDateType(original.config.start, original.config.end, polyglot) })
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "createdAt",
        header: () => polyglot.t("ReportsPage.createdAt"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item == null ? void 0 : item.createdAt),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.createdAt ? getDateString(original.createdAt) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => polyglot.t("ReportsPage.createdBy"),
        accessorFn: (row) => row,
        id: "createdBy",
        maxSize: 200,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2;
          return item.createdBy ? (_a2 = getCachedUserById(item.createdBy)) == null ? void 0 : _a2.displayName : "";
        }),
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.createdBy);
          return user && /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(UserCell, { userId: original.createdBy }) });
        }
      },
      {
        id: "actions",
        header: () => "",
        accessorFn: (row) => row,
        maxSize: 80,
        minSize: 60,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(
            "div",
            {
              style: {
                display: "flex",
                justifyContent: "flex-end",
                width: "100%"
              },
              onClick: (e) => e.stopPropagation(),
              children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: getReportsActionsOptions(original),
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              ) })
            }
          );
        }
      }
    ],
    [polyglot, getCachedUserById, getReportsActionsOptions]
  );
  const handleRowClick = useCallback(
    (row) => {
      routerHistory.push(generatePath(REPORTS_UPDATE_OLD_ROUTE, { reportId: row.original.id }));
    },
    [routerHistory]
  );
  useEffect(() => {
    trackPage("Reports overview");
  }, []);
  return /* @__PURE__ */ jsx(RootStyle, { children: !loading && reportsData && reportsData.length < 1 ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ReportsPage.reports") }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "This app has been replaced with the new reporting app." })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, sx: { pt: 2, pb: 0, height: "100%" }, border: false, children: /* @__PURE__ */ jsx(ReportsEmptyState, { onClick: () => routerHistory.push(REPORTS_CREATE_OLD_ROUTE) }) })
  ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ReportsPage.reports") }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "This app has been replaced with the new reporting app." })
        ] }),
        showAction: false,
        actions: /* @__PURE__ */ jsx(
          StyledTooltip,
          {
            open: true,
            placement: "left-end",
            disabled: (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.report) == null ? void 0 : _b.tooltip) == null ? void 0 : _c.createReport,
            title: /* @__PURE__ */ jsxs(Stack, { sx: { alignItems: "flex-start", maxWidth: "180px" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "white", sx: { m: spacing.m10 }, children: polyglot.t("ReportsPage.create") }),
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  onClick: () => __async(void 0, null, function* () {
                    const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                      "report",
                      "tooltip",
                      "createReport",
                      true
                    );
                    dispatch({
                      type: GlobalStateActions.UPDATE_USER,
                      payload: updatedGlobalUser
                    });
                  }),
                  sizeVariant: "small",
                  colorVariant: "tooltip",
                  children: polyglot.t("ReportsPage.okay")
                }
              )
            ] }),
            children: /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "primary",
                onClick: () => routerHistory.push(REPORTS_CREATE_OLD_ROUTE),
                disabled: true,
                children: polyglot.t("ReportsPage.new")
              }
            )
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { mt: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.s2 }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredData ? [...filteredData] : [],
          columnData: columns,
          loading,
          rowClick: handleRowClick
        }
      ) })
    ] })
  ] }) });
};
