"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton, Link } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomDEPTokenSteps } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component";
import { InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { saveAs } from "file-saver";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArchiveIcon } from "@/images/side-bar-icons/Download.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DownloadPublicMdmKeyComponent = ({
  onNext,
  setFormData,
  formData
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [allowNext, setAllowNext] = useState(false);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Download Token Public Key" }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m20 }, children: "Download this file with Zelt MDM details. You will need to upload it into your Apple business manager in the next step." }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", width: "100%", mt: spacing.m30 }, children: /* @__PURE__ */ jsxs(
          Link,
          {
            href: "#",
            onClick: () => __async(void 0, null, function* () {
              try {
                const profile = yield InHouseMdmAPI.getDepPublicKey(formData.mdmServerName);
                const blob = new Blob([Buffer.from(profile, "binary")], {
                  type: "application/application/x-pem-file"
                });
                saveAs(blob, "Zelt-MDM-Token-Public-Key.pem");
                setAllowNext(true);
              } catch (error) {
                showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, "error");
              }
            }),
            underline: "none",
            color: "inherit",
            style: { display: "flex", alignItems: "center", textDecoration: "none" },
            children: [
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  onClick: () => __async(void 0, null, function* () {
                    try {
                      const profile = yield InHouseMdmAPI.getDepPublicKey(formData.mdmServerName);
                      const blob = new Blob([Buffer.from(profile, "binary")], {
                        type: "application/application/x-pem-file"
                      });
                      saveAs(blob, "Zelt-MDM-Token-Public-Key.pem");
                    } catch (error) {
                      showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, "error");
                    }
                  }),
                  title: "Zelt-MDM-Token-Public-Key.pem",
                  children: /* @__PURE__ */ jsx(ArchiveIcon, __spreadValues({}, iconSize))
                }
              ),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Zelt-MDM-Token-Public-Key.pem" })
            ]
          }
        ) }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "large",
            colorVariant: "primary",
            style: { marginTop: spacing.m25 },
            disabled: !allowNext,
            onClick: () => {
              formData.type = CustomDEPTokenSteps.Download_key;
              setFormData(formData);
              onNext();
            },
            fullWidth: true,
            children: polyglot.t("General.next")
          }
        )
      ] })
    }
  );
};
