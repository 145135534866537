"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { SETTINGS_TASK_CHECKLIST_ROUTE, SETTINGS_TASK_ROUTE } from "@/lib/routes";
import { ChecklistRouter } from "@/v2/feature/task/subfeature/checklist/checklist.router";
export const TaskSettingRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["task:all"], path: SETTINGS_TASK_CHECKLIST_ROUTE, children: /* @__PURE__ */ jsx(ChecklistRouter, {}) }),
    /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_TASK_ROUTE, to: SETTINGS_TASK_CHECKLIST_ROUTE, exact: true })
  ] });
};
