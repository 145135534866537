"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
export function DisplayInvoiceUserAvatar(props) {
  const { polyglot } = usePolyglot();
  const { row, user } = props;
  const userId = row[user.idField];
  const userData = row[user.userField];
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-start", gap: 1 }, children: [
    /* @__PURE__ */ jsx(UserAvatar, { userId, size: "xxsmall", sx: { alignSelf: "self-start" } }),
    /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
          display: "flex",
          alignItems: "center"
        }),
        children: userData ? polyglot.t(`${userData == null ? void 0 : userData.firstName} ${userData == null ? void 0 : userData.lastName}`) : ""
      }
    )
  ] });
}
