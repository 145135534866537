"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "@v2/components/typography/typography.component";
import { UserImportRouter } from "@v2/feature/user/features/user-import/user-import.router";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { PEOPLE_ME_DIRECTORY_ROUTE } from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { EntityImportSuccessPage } from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/pages/entity-import-success.page";
import { PayrollImportRouter } from "@/v2/feature/payroll/features/payroll-uk/payroll-import/payroll-import.router";
import { RootStyle } from "@/v2/styles/root.styles";
export const ENTITY_IMPORT_WIZARD_ROUTE_OLD = "/settings/import/wizard";
export const ENTITY_IMPORT_WIZARD_V2_ROUTE = "/settings/v2/import/wizard";
export const ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE = `${ENTITY_IMPORT_WIZARD_ROUTE_OLD}/:entity(users|payroll|devices)/:source(csv|list|google|xero|quickbooks)`;
export const ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE = `${ENTITY_IMPORT_WIZARD_ROUTE_OLD}/success`;
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderRadius: 1,
    borderTopWidth: 3,
    borderColor: theme.palette.divider
  }
}));
export function EntityImportWizardOldRouter() {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const [loading, setLoading] = useState(false);
  const steps = [
    {
      label: polyglot.t("EntityImportWizardRouter.entries"),
      path: ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE
    },
    {
      label: polyglot.t("EntityImportWizardRouter.import"),
      path: ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE
    }
  ];
  const matchStepPath = useCallback(
    (path) => matchPath(routerLocation.pathname, {
      path
    }),
    [routerLocation.pathname]
  );
  const entityImportData = useMemo(() => {
    var _a, _b;
    const match = matchStepPath(ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE) || { params: { entity: "", source: "" } };
    return (_b = (_a = match.params) == null ? void 0 : _a.entity) != null ? _b : "";
  }, [matchStepPath]);
  const [result, setResult] = useState();
  const { pathname } = useLocation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  useEffect(() => {
    setLoading(true);
    const index = pipe(
      steps,
      RA.findLastIndex((step) => matchStepPath(step.path) !== null),
      O.getOrElse(() => 0)
    );
    setActiveStepIndex(index);
    setLoading(false);
  }, [pathname, matchStepPath]);
  const sourceProps = useMemo(
    () => ({
      goBack: () => routerHistory.push(ENTITY_IMPORT_WIZARD_V2_ROUTE),
      goNext: (stepResult) => {
        setResult(stepResult);
        routerHistory.push(ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE);
      }
    }),
    [routerHistory]
  );
  const EntityImportRouter = useMemo(() => {
    if (!entityImportData) return void 0;
    if (entityImportData === "payroll") return () => PayrollImportRouter(sourceProps);
    if (entityImportData === "users") return () => UserImportRouter(sourceProps);
    return void 0;
  }, [entityImportData, sourceProps]);
  const afterSuccessRoute = entityImportData !== "" ? PEOPLE_ME_DIRECTORY_ROUTE : "";
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("EntityImportWizardRouter.import") }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsx(Grid, { container: true, justifyContent: "center", children: /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, md: 9, lg: 8, xl: 8, children: /* @__PURE__ */ jsx(Stepper, { alternativeLabel: true, activeStep: activeStepIndex, connector: /* @__PURE__ */ jsx(QontoConnector, {}), children: steps.map((step, index) => /* @__PURE__ */ jsx(Step, { children: /* @__PURE__ */ jsx(StepLabel, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: step.label }) }) }, index)) }) }) }),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Switch, { children: [
        /* @__PURE__ */ jsx(Route, { path: ENTITY_IMPORT_WIZARD_ENTRIES_ROUTE, exact: true, children: entityImportData !== "" && EntityImportRouter }),
        /* @__PURE__ */ jsx(Route, { path: ENTITY_IMPORT_WIZARD_SUCCESS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
          EntityImportSuccessPage,
          {
            goNext: () => {
              routerHistory.push(afterSuccessRoute);
            },
            result
          }
        ) }),
        /* @__PURE__ */ jsx(Redirect, { from: ENTITY_IMPORT_WIZARD_ROUTE_OLD, to: ENTITY_IMPORT_WIZARD_V2_ROUTE })
      ] }) })
    ] })
  ] });
}
