"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ZincIcon } from "@/images/side-bar-icons/zinc.svg";
import { checkScopes } from "@/lib/scopes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { NewIdCheckPackageDrawer } from "@/v2/feature/id-check/components/new-id-check-package-drawer.page";
import { IdCheckEndpoints } from "@/v2/feature/id-check/id-check.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { underlinedLinkLight } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatAsGBPCurrency } from "@/v2/util/string-format.util";
export const ZINC_TERMS_LINK = "https://zincwork.com/terms-payg";
export const ZINC_CHECKS_LINK = "https://zincwork.com/checks";
export const EditIdVerify = ({ onSave, mode = "template" }) => {
  const { polyglot } = usePolyglot();
  const {
    data: individualChecks,
    mutate: refreshIndividualChecks
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });
  const { data: idCheckPackages, isLoading: fetchingPackages, mutate: refreshIdCheckPackages } = useApiClient(
    IdCheckEndpoints.getIdCheckPackages(),
    {
      suspense: false
    }
  );
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const scopesContext = { userId: user.userId };
  const [selectedPackageOption, setSelectedPackageOption] = useState(void 0);
  const [newPackageDrawerOpen, setNewPackageDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshIdCheckPackages) yield refreshIdCheckPackages();
    if (refreshIndividualChecks) yield refreshIndividualChecks();
  }), [refreshIdCheckPackages, refreshIndividualChecks]);
  const formik = useFormik({
    initialValues: {
      chosenPackage: void 0
    },
    validationSchema: yup.object({
      chosenPackage: yup.object().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      const { chosenPackage } = values;
      if (!chosenPackage) {
        showMessage("Package missing - try again", "error");
        return;
      }
      const packageToUse = {
        id: chosenPackage == null ? void 0 : chosenPackage.id,
        name: chosenPackage.name,
        description: "",
        cost: chosenPackage == null ? void 0 : chosenPackage.price
      };
      onSave(packageToUse);
    })
  });
  const packageOptions = useMemo(() => {
    return idCheckPackages && idCheckPackages.length > 0 ? idCheckPackages.map((pkg) => ({
      label: `${pkg.name} (${formatAsGBPCurrency(pkg.price, 2)})`,
      value: pkg.id
    })) : [];
  }, [idCheckPackages]);
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: polyglot.t("EditIdVerify.check") }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.mt10 }), children: [
      polyglot.t("EditIdVerify.poweredBy"),
      " ",
      /* @__PURE__ */ jsx(ZincIcon, __spreadValues({}, iconSize)),
      " ",
      polyglot.t("EditIdVerify.zincMex")
    ] }),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "chosenPackageId",
        label: polyglot.t("IdCheckPackage.selectPackage"),
        options: packageOptions,
        value: selectedPackageOption,
        onChange: (_, x) => {
          if (!x) return;
          const selectedOption = x;
          const matchingPackage = (idCheckPackages != null ? idCheckPackages : []).find(
            (pkg) => pkg.id === selectedOption.value
          );
          formik.setFieldValue("chosenPackage", matchingPackage);
          setSelectedPackageOption(selectedOption);
        },
        error: hasSubmitted && !!formik.errors.chosenPackage,
        helperText: hasSubmitted && formik.errors.chosenPackage,
        editList: {
          handler: () => {
            setNewPackageDrawerOpen(true);
          },
          isHidden: !checkScopes(user, ["user.onboard:all"], scopesContext)
        }
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { direction: "row", sx: { gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Link, { sx: __spreadValues({}, underlinedLinkLight), target: "_blank", href: ZINC_TERMS_LINK, children: polyglot.t("EditIdVerify.zinc") }),
      /* @__PURE__ */ jsx(Link, { sx: __spreadValues({}, underlinedLinkLight), target: "_blank", href: ZINC_CHECKS_LINK, children: polyglot.t("EditIdVerify.zincChecksInfo") })
    ] }),
    /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        title: !globalState.user.hasPaymentMethodOnFile && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.tooltip), { opacity: 1 }), children: [
          polyglot.t("EditIdVerify.cannotUse"),
          " ",
          /* @__PURE__ */ jsx(Link, { target: "_blank", href: "/settings/billing", children: polyglot.t("EditIdVerify.billing") })
        ] }),
        children: /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            disabled: fetchingPackages || !globalState.user.hasPaymentMethodOnFile,
            fullWidth: true,
            colorVariant: "primary",
            sizeVariant: "medium",
            children: mode === "template" ? polyglot.t("General.save") : polyglot.t("General.invite")
          }
        ) })
      }
    ),
    newPackageDrawerOpen && /* @__PURE__ */ jsx(
      NewIdCheckPackageDrawer,
      {
        isOpen: newPackageDrawerOpen,
        setIsOpen: setNewPackageDrawerOpen,
        currentUser: user,
        idCheckPackage: null,
        individualChecks: individualChecks != null ? individualChecks : [],
        refresh
      }
    )
  ] }) });
};
