"use strict";
import { jsx } from "react/jsx-runtime";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as AppsIcon } from "@/images/onboarding-step-icons/AppsBig2.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { AppIconsList } from "@/v2/components/app-icons-list.component";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
import { EditApps } from "@/v2/feature/onboarding/onboarding-items/apps/edit-apps.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppsOnboardingItem = ({
  template,
  onDelete,
  setDrawerView,
  setDrawerOpen,
  checkOnboardingItemScope
}) => {
  const { polyglot } = usePolyglot();
  const canAddApps = checkOnboardingItemScope(AppScopes.ADD_TO_ONBOARDING_TEMPLATE);
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: AppsIcon,
        title: polyglot.t("AppsOnboardingItem.title"),
        description: polyglot.t("AppsOnboardingItem.desc")
      },
      chips: template.template.apps && [/* @__PURE__ */ jsx(AppIconsList, { stubs: template.template.apps.stubs })],
      onDeleteClick: template.template.apps && onDelete,
      addButtonIcon: template.template.apps ? "edit" : "plus",
      onAddClick: canAddApps && (() => {
        setDrawerView == null ? void 0 : setDrawerView(
          /* @__PURE__ */ jsx(
            EditApps,
            {
              config: template.template.apps,
              onSave: (config) => {
                template.template.apps = config;
                setDrawerOpen == null ? void 0 : setDrawerOpen(false);
              }
            }
          )
        );
        setDrawerOpen == null ? void 0 : setDrawerOpen(true);
      }),
      sx: {
        py: spacing.py20
      }
    }
  );
};
