"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Extension } from "@tiptap/core";
import { DOMParser } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { blobToBase64 } from "@/v2/components/forms/document-editor/tiptap/extensions/helpers/file-download.util";
import {
  generateSmartNodeWrapperElements,
  SMART_FIELD_CUSTOM_MARKER
} from "@/v2/components/forms/document-editor/tiptap/nodes/smart-field.node";
const DELETE_POSITION_DATA_TRANSFER = "delete-position";
function wrapHtmlInTemplate(value) {
  const element = document.createElement("span");
  element.innerHTML = value.trim();
  return element;
}
export const HandleDropExtension = Extension.create({
  name: "HandleDropExtension",
  addOptions() {
    return {
      handleError: () => {
      },
      mode: ""
    };
  },
  addProseMirrorPlugins() {
    const { handleError, mode } = this.options;
    return [
      new Plugin({
        key: new PluginKey("HandleDropExtension"),
        props: {
          handleDOMEvents: {
            dragstart: (view, event) => {
              var _a, _b;
              const target = event.target;
              if (target) {
                const pos = view.posAtDOM(target, 0);
                const node = view.state.doc.nodeAt(pos);
                (_a = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _a.setData("text/plain", target == null ? void 0 : target.outerHTML);
                if (node) {
                  (_b = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _b.setData(
                    DELETE_POSITION_DATA_TRANSFER,
                    JSON.stringify({ to: pos + (node == null ? void 0 : node.nodeSize), from: pos })
                  );
                }
                return true;
              }
              return false;
            },
            //@ts-ignore
            drop: (view, event) => __async(this, null, function* () {
              var _a, _b, _c, _d, _e, _f, _g, _h, _i;
              const { state, dispatch } = view;
              const { tr } = state;
              if (!event) return false;
              event.preventDefault();
              const htmlContent = ((_a = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _a.getData("snippet")) || ((_b = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _b.getData("text/plain"));
              if (htmlContent) {
                const transactions = [];
                const deleteContentStr = (_c = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _c.getData(DELETE_POSITION_DATA_TRANSFER);
                const deleteContent = deleteContentStr ? JSON.parse(deleteContentStr) : void 0;
                const coordinates = view.posAtCoords({
                  left: event.clientX,
                  top: event.clientY
                });
                if (coordinates && (coordinates == null ? void 0 : coordinates.pos) <= (deleteContent == null ? void 0 : deleteContent.to) && (coordinates == null ? void 0 : coordinates.pos) >= (deleteContent == null ? void 0 : deleteContent.from)) {
                  return false;
                }
                let fragment = void 0;
                const parsedContent = DOMParser.fromSchema(view.state.schema).parseSlice(
                  wrapHtmlInTemplate(htmlContent)
                );
                if (htmlContent.includes(SMART_FIELD_CUSTOM_MARKER)) {
                  fragment = generateSmartNodeWrapperElements(
                    mode,
                    view.state,
                    (_f = (_e = (_d = parsedContent == null ? void 0 : parsedContent.content) == null ? void 0 : _d.child) == null ? void 0 : _e.call(_d, 0)) == null ? void 0 : _f.text
                  );
                }
                if (coordinates) {
                  const node = view.state.doc.nodeAt(coordinates == null ? void 0 : coordinates.pos);
                  const resolvedDropNode = state.doc.resolve(coordinates.pos);
                  if (!node || node && node.content.size === 0) {
                    if (deleteContent) {
                      transactions.push({
                        to: deleteContent.from,
                        tr: () => tr.delete(deleteContent.from, deleteContent.to)
                      });
                    }
                    const isDropLocationSmartMarker = node == null ? void 0 : node.marks.find(
                      (mark) => {
                        var _a2, _b2, _c2;
                        return (_c2 = (_b2 = (_a2 = mark == null ? void 0 : mark.attrs) == null ? void 0 : _a2.class) == null ? void 0 : _b2.includes) == null ? void 0 : _c2.call(_b2, SMART_FIELD_CUSTOM_MARKER);
                      }
                    );
                    transactions.push({
                      to: coordinates.pos,
                      tr: () => tr.insert(
                        isDropLocationSmartMarker ? resolvedDropNode.end(resolvedDropNode.depth) : coordinates.pos,
                        fragment && (fragment == null ? void 0 : fragment.childCount) > 0 ? fragment : parsedContent.content
                      )
                    });
                  }
                }
                transactions.sort((a, b) => b.to - a.to);
                transactions.forEach((t) => {
                  t.tr();
                });
                dispatch(tr);
                return true;
              } else if (((_g = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _g.files) && (((_h = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _h.files) || []).length) {
                const files = (_i = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _i.files;
                try {
                  const src = yield blobToBase64(files[0]);
                  src && this.editor.chain().focus().setImage({ src: src == null ? void 0 : src.toString() }).run();
                } catch (err) {
                  handleError("Failed to load your image. Please try again.");
                }
              }
              return false;
            })
          }
        }
      })
    ];
  }
});
