"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { UserPayrollStatusCell } from "@/v2/feature/payroll/components/user-payroll-status-cell.component";
import { formatUserPayrollStatus } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { EditPayrollRecordDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component";
import { EmployeeActionsCell } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/employee-actions-column.component";
import { PayrollMissingInformationDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/payroll-missing-information.component";
import { ViewUserPayrollDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/view-user-payroll-drawer.component";
import {
  canAddToPayroll,
  canRemoveFromPayroll
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/payroll-company-employees.util";
import { getUserStatusFromPayrunEntry } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { SalarySummaryDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/salary-summary-drawer.component";
import {
  CurrencyWithDiff,
  ValueWithDiff
} from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component";
import { PayrollLocalEndpoints } from "@/v2/feature/payroll/payroll-local.api";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { getApiErrorMessage } from "@/v2/infrastructure/api-error/api-error.util";
import { isSameCountryCode } from "@/v2/infrastructure/country/country.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { filterByTextSearch, sum } from "@/v2/util/array.util";
export const DraftUKPayrunTable = ({
  payroll,
  localPayRun,
  entries,
  previousEntries,
  searchQuery,
  sx,
  disabled,
  refreshPayroll,
  stickyHeader
}) => {
  const { polyglot } = usePolyglot();
  const [_globalState, dispatch] = useContext(GlobalContext);
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [drawer, setDrawer] = useState(null);
  const [usersBeingUpdated, setUsersBeingUpdated] = useState(/* @__PURE__ */ new Set());
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const {
    data: rawPayrollList,
    mutate: refreshPayrollUsers,
    isValidating: loadingPayrollUsers
  } = useApiClient(PayrollLocalEndpoints.getUserPayrollMembershipList(), { suspense: false });
  const employeeList = useMemo(() => {
    var _a;
    return (_a = rawPayrollList == null ? void 0 : rawPayrollList.all) != null ? _a : [];
  }, [rawPayrollList == null ? void 0 : rawPayrollList.all]);
  const [salarySummary, setSalarySummary] = useState();
  const { data: customPayCodes } = useApiClient(PayrollLocalEndpoints.getUKPayrunPayCodes(localPayRun.id), {
    suspense: false
  });
  const drawerRecord = useMemo(() => employeeList.find((e) => e.userId === (drawer == null ? void 0 : drawer.userId)), [
    drawer == null ? void 0 : drawer.userId,
    employeeList
  ]);
  const refreshPayrollState = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshPayroll(), refreshPayrollUsers == null ? void 0 : refreshPayrollUsers()]);
  }), [refreshPayroll, refreshPayrollUsers]);
  const getUserDisplayName = useCallback(
    (userId) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );
  const markUserUpdating = useCallback((userId, isUpdating) => {
    setUsersBeingUpdated((userIds) => {
      const newUpdatingUserIds = new Set(userIds);
      if (isUpdating) newUpdatingUserIds.add(userId);
      else newUpdatingUserIds.delete(userId);
      return newUpdatingUserIds;
    });
  }, []);
  const contractCountryMatchesUserPayrollCountry = useCallback((record) => {
    var _a, _b, _c;
    return isSameCountryCode((_a = record.payrollJurisdiction) != null ? _a : "GB", (_c = (_b = record.userPayroll) == null ? void 0 : _b.countryCode) != null ? _c : "GB");
  }, []);
  const refreshBillingRestrictions = useCallback(() => __async(void 0, null, function* () {
    var _a;
    const response = yield AuthAPI.getAuthMe(false);
    const authUser = (_a = response == null ? void 0 : response.user) != null ? _a : null;
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: authUser
    });
  }), [dispatch]);
  const addToPayroll = useCallback(
    (userId) => __async(void 0, null, function* () {
      let userAddedToPayroll = false;
      const name = getUserDisplayName(userId);
      markUserUpdating(userId, true);
      try {
        userAddedToPayroll = (yield PayrollAPI.addUsersToPayroll(payroll.id, [userId]), true);
        showMessage(polyglot.t("PayrunTable.nameHasBeenAddedToPayroll", { name }), "success");
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("PayrunTable.nameCouldNotBeAddedToPayroll", {
              name,
              reason: getApiErrorMessage(error)
            }),
            "warning"
          );
        }
      } finally {
        markUserUpdating(userId, false);
      }
      yield refreshPayrollState == null ? void 0 : refreshPayrollState();
      yield refreshBillingRestrictions();
      return userAddedToPayroll;
    }),
    [
      getUserDisplayName,
      markUserUpdating,
      refreshBillingRestrictions,
      refreshPayrollState,
      showMessage,
      polyglot,
      payroll.id
    ]
  );
  const removeFromPayroll = useCallback(
    (userId) => __async(void 0, null, function* () {
      var _a;
      let userRemovedFromPayroll = false;
      const name = ((_a = getCachedUserById(userId)) == null ? void 0 : _a.displayName) || `User ${userId}`;
      markUserUpdating(userId, true);
      try {
        yield PayrollAPI.removeUsersFromPayroll(payroll.id, [userId]);
        userRemovedFromPayroll = true;
        showMessage(polyglot.t("PayrunTable.nameHasBeenRemovedFromPayroll", { name }), "success");
      } catch (error) {
        showMessage(
          polyglot.t("PayrunTable.nameCouldNotBeRemovedFromPayroll", {
            name,
            reason: getApiErrorMessage(error)
          }),
          "warning"
        );
      } finally {
        markUserUpdating(userId, false);
      }
      refreshPayrollState == null ? void 0 : refreshPayrollState();
      refreshBillingRestrictions();
      return userRemovedFromPayroll;
    }),
    [
      getCachedUserById,
      markUserUpdating,
      refreshPayrollState,
      refreshBillingRestrictions,
      payroll.id,
      showMessage,
      polyglot
    ]
  );
  const rows = useMemo(() => {
    var _a;
    const result = /* @__PURE__ */ new Map();
    for (const payrunEntry of entries) {
      const { userId } = payrunEntry;
      result.set(userId, { userId, payrunEntry, payrollUser: null });
    }
    for (const payrollUser of employeeList) {
      const { userId, entityId, user } = payrollUser;
      const paySchedule = (_a = payrollUser.compensationBreakdown) == null ? void 0 : _a.paySchedule;
      const payrunUser = result.get(userId);
      if (payrunUser) {
        result.set(userId, __spreadProps(__spreadValues({}, payrunUser), { payrollUser }));
        continue;
      }
      if (entityId !== payroll.entity.id) continue;
      if (user.status === "Terminated") continue;
      if (user.startDate && user.startDate > localPayRun.endDate) continue;
      if (paySchedule && paySchedule !== localPayRun.payPeriod) continue;
      result.set(userId, { userId, payrunEntry: null, payrollUser });
    }
    return [...result.values()].sort(
      (a, b) => getUserDisplayName(a.userId).localeCompare(getUserDisplayName(b.userId), polyglot.locale(), {
        sensitivity: "base"
      })
    );
  }, [entries, payroll.entity.id, employeeList, getUserDisplayName, localPayRun, polyglot]);
  const filteredRows = useMemo(() => {
    return filterByTextSearch(searchQuery, rows, (user) => [getUserDisplayName(user.userId)]);
  }, [getUserDisplayName, searchQuery, rows]);
  const [summaryTotals, previousSummaryTotals] = useMemo(() => {
    const calculateTotals = (entries2) => ({
      additions: sum(entries2, (e) => e.totals.additions),
      deductions: sum(entries2, (e) => e.totals.deductions),
      takeHomePay: sum(entries2, (e) => e.totals.takeHomePay),
      totalCost: sum(entries2, (e) => e.totals.totalCost)
    });
    return [
      calculateTotals(entries),
      previousEntries.length ? calculateTotals(previousEntries) : {}
    ];
  }, [entries, previousEntries]);
  const columnData = useMemo(() => {
    const previousById = new Map(previousEntries.map((item) => [item.userId, item]));
    return [
      {
        id: "employee",
        header: () => polyglot.t("PayrunTable.employee"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId }),
        footer: () => polyglot.t("PayrunTable.total"),
        size: 100
      },
      {
        id: "income",
        header: () => polyglot.t("PayrunTable.income"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.payrunEntry) == null ? void 0 : _a.totals.additions;
        }),
        cell: (c) => {
          var _a, _b, _c;
          if (!c.row.original.payrunEntry) return null;
          const currency = (_c = (_b = (_a = c.row.original.payrollUser) == null ? void 0 : _a.compensationBreakdown) == null ? void 0 : _b.currency) != null ? _c : DEFAULT_CURRENCY;
          return /* @__PURE__ */ jsx(
            ValueWithDiff,
            {
              current: c.row.original.payrunEntry,
              previous: previousById.get(c.row.original.userId),
              getValue: (item) => item.totals.additions,
              currency
            }
          );
        },
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: summaryTotals.additions,
            previousValue: previousSummaryTotals.additions,
            currency: DEFAULT_CURRENCY
          }
        ),
        size: 60
      },
      {
        id: "deductions",
        header: () => polyglot.t("PayrunTable.deductions"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.payrunEntry) == null ? void 0 : _a.totals.deductions;
        }),
        cell: (c) => {
          var _a, _b, _c;
          if (!c.row.original.payrunEntry) return null;
          const currency = (_c = (_b = (_a = c.row.original.payrollUser) == null ? void 0 : _a.compensationBreakdown) == null ? void 0 : _b.currency) != null ? _c : DEFAULT_CURRENCY;
          return /* @__PURE__ */ jsx(
            ValueWithDiff,
            {
              current: c.row.original.payrunEntry,
              previous: previousById.get(c.row.original.userId),
              getValue: (item) => item.totals.deductions,
              currency
            }
          );
        },
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: summaryTotals.deductions,
            previousValue: previousSummaryTotals.deductions,
            currency: DEFAULT_CURRENCY
          }
        ),
        size: 80
      },
      {
        id: "take-home",
        header: () => polyglot.t("PayrunTable.takeHomePay"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.payrunEntry) == null ? void 0 : _a.totals.takeHomePay;
        }),
        cell: (c) => {
          var _a, _b, _c;
          if (!c.row.original.payrunEntry) return null;
          const currency = (_c = (_b = (_a = c.row.original.payrollUser) == null ? void 0 : _a.compensationBreakdown) == null ? void 0 : _b.currency) != null ? _c : DEFAULT_CURRENCY;
          return /* @__PURE__ */ jsx(
            ValueWithDiff,
            {
              current: c.row.original.payrunEntry,
              previous: previousById.get(c.row.original.userId),
              getValue: (item) => item.totals.takeHomePay,
              currency
            }
          );
        },
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: summaryTotals.takeHomePay,
            previousValue: previousSummaryTotals.takeHomePay,
            currency: DEFAULT_CURRENCY
          }
        ),
        size: 100
      },
      {
        id: "employer-cost",
        header: () => polyglot.t("PayrunTable.employerCost"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.payrunEntry) == null ? void 0 : _a.totals.totalCost;
        }),
        cell: (c) => {
          var _a, _b, _c;
          if (!c.row.original.payrunEntry) return null;
          const currency = (_c = (_b = (_a = c.row.original.payrollUser) == null ? void 0 : _a.compensationBreakdown) == null ? void 0 : _b.currency) != null ? _c : DEFAULT_CURRENCY;
          return /* @__PURE__ */ jsx(
            ValueWithDiff,
            {
              current: c.row.original.payrunEntry,
              previous: previousById.get(c.row.original.userId),
              getValue: (item) => item.totals.totalCost,
              currency
            }
          );
        },
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: summaryTotals.totalCost,
            previousValue: previousSummaryTotals.totalCost,
            currency: DEFAULT_CURRENCY
          }
        ),
        size: 100
      },
      {
        id: "status",
        header: () => polyglot.t("PayrunTable.status"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(
          a,
          b,
          (item) => item.payrunEntry && formatUserPayrollStatus(getUserStatusFromPayrunEntry(item.payrunEntry).label, polyglot)
        ),
        cell: (c) => {
          var _a;
          return /* @__PURE__ */ jsx(
            UserPayrollStatusCell,
            {
              status: getUserStatusFromPayrunEntry((_a = c.row.original.payrunEntry) != null ? _a : "not-in-payroll")
            }
          );
        },
        size: 60
      },
      {
        header: () => "",
        id: "actions",
        // maxSize: 70,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const { payrollUser } = original;
          if (!payrollUser) return /* @__PURE__ */ jsx(Fragment, {});
          return /* @__PURE__ */ jsx(
            EmployeeActionsCell,
            {
              user: payrollUser,
              payroll,
              openDrawer: (mode, { userId }) => setDrawer({ userId, mode }),
              disabled,
              updating: usersBeingUpdated.has(original.userId),
              addToPayroll,
              removeFromPayroll,
              currentPayrun: localPayRun
            }
          );
        }
      }
    ];
  }, [
    previousEntries,
    polyglot,
    getUserDisplayName,
    summaryTotals,
    previousSummaryTotals,
    payroll,
    disabled,
    usersBeingUpdated,
    addToPayroll,
    removeFromPayroll,
    localPayRun
  ]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx, children: [
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          loading: loadingPayrollUsers && !rawPayrollList,
          rowData: filteredRows,
          columnData,
          hidePagination: true,
          maxUnpaginatedRows: 500,
          showFooter: true,
          rowClick: (row) => {
            const { userId, payrunEntry } = row.original;
            if (usersBeingUpdated.has(userId)) {
              return;
            }
            if (payrunEntry) {
              setSalarySummary({ userId, payrunEntry });
            }
          },
          stickyHeader
        }
      ),
      /* @__PURE__ */ jsx(
        SalarySummaryDrawer,
        {
          userId: salarySummary == null ? void 0 : salarySummary.userId,
          payrunEntry: salarySummary == null ? void 0 : salarySummary.payrunEntry,
          onClose: () => setSalarySummary(void 0),
          customPayCodes: customPayCodes != null ? customPayCodes : [],
          localPayRun
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      PayrollMissingInformationDrawer,
      {
        isOpen: (drawer == null ? void 0 : drawer.mode) === "missing-info",
        close: () => setDrawer(null),
        payrollRecord: drawerRecord,
        refreshPayroll: refreshPayrollState
      }
    ),
    /* @__PURE__ */ jsx(
      EditPayrollRecordDrawer,
      {
        isOpen: (drawer == null ? void 0 : drawer.mode) === "edit",
        close: () => setDrawer(null),
        payrollRecord: drawerRecord && contractCountryMatchesUserPayrollCountry(drawerRecord) && drawerRecord.userPayroll || null,
        mode: (drawerRecord == null ? void 0 : drawerRecord.userPayroll) && contractCountryMatchesUserPayrollCountry(drawerRecord) ? "append" : "initial",
        userId: drawer == null ? void 0 : drawer.userId,
        onUpdateStarted: () => !!drawer && markUserUpdating(drawer.userId, true),
        onUpdateFinished: (success) => {
          !!drawer && markUserUpdating(drawer.userId, false);
          if (success) refreshPayrollState == null ? void 0 : refreshPayrollState();
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ViewUserPayrollDrawer,
      {
        isOpen: (drawer == null ? void 0 : drawer.mode) === "view",
        close: (options) => {
          if ((options == null ? void 0 : options.switchToEdit) && drawer) {
            setDrawer({ userId: drawer.userId, mode: "edit" });
            return;
          }
          setDrawer(null);
        },
        onClose: () => (drawer == null ? void 0 : drawer.mode) === "view" && setDrawer(null),
        record: drawerRecord || null,
        isUserUpdating: !!drawer && usersBeingUpdated.has(drawer.userId),
        addToPayroll: drawerRecord && canAddToPayroll(drawerRecord, payroll) ? (record) => addToPayroll(record.user.userId) : void 0,
        removeFromPayroll: drawerRecord && canRemoveFromPayroll(drawerRecord, payroll) ? (record) => removeFromPayroll(record.user.userId) : void 0,
        canEdit: true
      }
    ),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.MONEY_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] });
};
