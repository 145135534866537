"use strict";
export var InsuranceQuoteStatus = /* @__PURE__ */ ((InsuranceQuoteStatus2) => {
  InsuranceQuoteStatus2["Pending"] = "Pending";
  InsuranceQuoteStatus2["PolicyAssigned"] = "PolicyAssigned";
  return InsuranceQuoteStatus2;
})(InsuranceQuoteStatus || {});
export var InsuranceType = /* @__PURE__ */ ((InsuranceType2) => {
  InsuranceType2["Health"] = "Health";
  return InsuranceType2;
})(InsuranceType || {});
export var UserInsuranceDependants = /* @__PURE__ */ ((UserInsuranceDependants2) => {
  UserInsuranceDependants2["SpousePartner"] = "SpousePartner";
  UserInsuranceDependants2["Children"] = "Children";
  UserInsuranceDependants2["Family"] = "Family";
  return UserInsuranceDependants2;
})(UserInsuranceDependants || {});
export var InsuranceProvider = /* @__PURE__ */ ((InsuranceProvider2) => {
  InsuranceProvider2["Vitality"] = "Vitality";
  InsuranceProvider2["AXA"] = "AXA";
  InsuranceProvider2["BupaDental"] = "BupaDental";
  InsuranceProvider2["BupaPMI"] = "BupaPMI";
  InsuranceProvider2["Aviva"] = "Aviva";
  InsuranceProvider2["Freedom"] = "Freedom";
  return InsuranceProvider2;
})(InsuranceProvider || {});
export var UserInsurancePolicyStatus = /* @__PURE__ */ ((UserInsurancePolicyStatus2) => {
  UserInsurancePolicyStatus2["Pending"] = "Pending";
  UserInsurancePolicyStatus2["Active"] = "Active";
  UserInsurancePolicyStatus2["OptOut"] = "OptOut";
  UserInsurancePolicyStatus2["PendingOptOut"] = "PendingOptOut";
  UserInsurancePolicyStatus2["None"] = "null";
  return UserInsurancePolicyStatus2;
})(UserInsurancePolicyStatus || {});
