"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { pick } from "lodash";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Back } from "@/images/fields/Left.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_REQUEST_FORMS_ROUTE } from "@/lib/routes";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { StepperComponent } from "@/v2/components/stepper.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { ApprovalPage } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/approval.page";
import { FormBuilderPage } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/form-builder.page";
import { GeneralPage } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/general.page";
import { MembersPage } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/members.page";
import { PreviewPage } from "@/v2/feature/requests/features/request-forms/new-request-form/sections/preview.page";
import { RequestFormsAPI } from "@/v2/feature/requests/request-forms.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { clamp } from "@/v2/util/array.util";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const RequestFormStepperSteps = ["General", "Form", "Approval", "Members", "Preview"];
export const NewRequestFormPage = ({ templateToEdit }) => {
  const { polyglot } = usePolyglot();
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), {
    suspense: false
  });
  const [savingForm, setSavingForm] = useState(false);
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const template = useRef(
    templateToEdit != null ? templateToEdit : {
      name: "",
      description: "",
      layout: { items: [] },
      approvalRuleId: 0,
      members: { userIds: [], filter: UserSelectFiltersOptions.None }
    }
  );
  const [step, setStep] = useState("General");
  const stepIndex = RequestFormStepperSteps.indexOf(step);
  const refreshApprovalRules = useCallback(() => __async(void 0, null, function* () {
    if (refreshRules) yield refreshRules();
  }), [refreshRules]);
  const performStep = useCallback(
    (direction) => {
      const nexStepIndex = stepIndex + (direction === "next" ? 1 : -1);
      setStep(clamp(RequestFormStepperSteps, nexStepIndex));
    },
    [stepIndex]
  );
  const saveEditedFormTemplate = useCallback(
    (template2) => __async(void 0, null, function* () {
      setSavingForm(true);
      try {
        return RequestFormsAPI.updateFormTemplate(template2);
      } catch (error) {
        showMessage(
          polyglot.t("NewRequestFormPage.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setSavingForm(false);
      }
    }),
    [showMessage, polyglot]
  );
  const saveNewFormTemplate = useCallback(
    (template2) => __async(void 0, null, function* () {
      setSavingForm(true);
      try {
        return RequestFormsAPI.createFormTemplate(template2);
      } catch (error) {
        showMessage(
          polyglot.t("NewRequestFormPage.errorMessages.creation", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setSavingForm(false);
      }
    }),
    [showMessage, polyglot]
  );
  const saveRequestTemplate = useCallback(() => __async(void 0, null, function* () {
    if ("id" in template.current) {
      return saveEditedFormTemplate(template.current);
    } else {
      return saveNewFormTemplate(template.current);
    }
  }), [saveEditedFormTemplate, saveNewFormTemplate]);
  const closeForm = useCallback(() => {
    routerHistory.replace(SETTINGS_REQUEST_FORMS_ROUTE);
  }, [routerHistory]);
  useEscapeKey(() => {
    if (!template.current.name) {
      closeForm();
    }
  });
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.s3 }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "start", px: spacing.px40, gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(
        IconButton,
        {
          colorVariant: "secondary",
          sizeVariant: "small",
          style: { visibility: stepIndex > 0 ? void 0 : "hidden" },
          onClick: () => performStep("back"),
          children: /* @__PURE__ */ jsx(Back, {})
        }
      ),
      /* @__PURE__ */ jsx(
        StepperComponent,
        {
          steps: RequestFormStepperSteps,
          activeStep: step,
          sx: { flex: 1, mt: spacing.s2, mx: spacing.s3 }
        }
      ),
      /* @__PURE__ */ jsx(IconButton, { colorVariant: "secondary", sizeVariant: "small", onClick: () => closeForm(), children: /* @__PURE__ */ jsx(Close, { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.s3 } }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          overflowY: "auto",
          height: "100%",
          pb: spacing.s3
        },
        children: {
          General: () => /* @__PURE__ */ jsx(
            GeneralPage,
            {
              initialValues: pick(template.current, ["name", "description"]),
              onNext: ({ name, description }) => {
                template.current.name = name;
                template.current.description = description;
                performStep("next");
              }
            }
          ),
          Form: () => /* @__PURE__ */ jsx(
            FormBuilderPage,
            {
              initialForm: template.current.layout,
              onNext: (values) => {
                template.current.layout = values;
                performStep("next");
              }
            }
          ),
          Approval: () => {
            var _a;
            return /* @__PURE__ */ jsx(
              ApprovalPage,
              {
                initialValues: (_a = template.current.approvalRuleId) != null ? _a : null,
                onNext: (approvalRuleId) => {
                  template.current.approvalRuleId = approvalRuleId;
                  performStep("next");
                },
                approvalRules: approvalRules != null ? approvalRules : [],
                refreshApprovalRules
              }
            );
          },
          Members: () => /* @__PURE__ */ jsx(
            MembersPage,
            {
              initialValues: template.current.members,
              onNext: (values) => {
                template.current.members = values;
                performStep("next");
              }
            }
          ),
          Preview: () => /* @__PURE__ */ jsx(
            PreviewPage,
            {
              template: template.current,
              saving: savingForm,
              onNext: () => __async(void 0, null, function* () {
                const form = yield saveRequestTemplate();
                if (!form) return;
                closeForm();
              }),
              approvalRules: approvalRules != null ? approvalRules : []
            }
          )
        }[step]()
      }
    )
  ] });
};
