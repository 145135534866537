"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { SURVEYS_ME_INVITE_ROUTE, SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE, SURVEYS_ME_ROUTE } from "@/lib/routes";
import { SurveyAnswerSubmissionPage } from "@/v2/feature/growth/surveys/features/survey-answer/survey-answer-submission/survey-answer-submission.page";
import { SurveyCycleEntriesPage } from "@/v2/feature/growth/surveys/features/survey-entry/survey-entries-personal/survey-entries-personal.page";
export const SurveysMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["surveys"], context, path: SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(SurveyAnswerSubmissionPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["surveys"], context, path: SURVEYS_ME_INVITE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(SurveyCycleEntriesPage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { from: SURVEYS_ME_ROUTE, to: SURVEYS_ME_INVITE_ROUTE })
  ] });
};
