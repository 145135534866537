"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { DeviceCompanyRouter } from "@v2/feature/device/device-company.router";
import { DevicesMeRouter } from "@v2/feature/device/devices-me.router";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  DEVICES_ACTIVATION_ROUTE,
  DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE,
  DEVICES_COMPANY_ROUTE,
  DEVICES_ME_ORDERS_ACTIVATION_ROUTE,
  DEVICES_ME_ROUTE,
  TECH_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
export const DeviceRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices"], context: scopesContext, path: DEVICES_ACTIVATION_ROUTE, children: checkScopes(globalState.user, ["devices:all"]) ? /* @__PURE__ */ jsx(Redirect, { to: DEVICES_COMPANY_ORDERS_ACTIVATION_ROUTE }) : /* @__PURE__ */ jsx(Redirect, { to: DEVICES_ME_ORDERS_ACTIVATION_ROUTE }) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["devices:all", "devices.enroll"],
        context: scopesContext,
        path: DEVICES_COMPANY_ROUTE,
        children: /* @__PURE__ */ jsx(DeviceCompanyRouter, {})
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices", "devices.enroll"], context: scopesContext, path: DEVICES_ME_ROUTE, children: /* @__PURE__ */ jsx(DevicesMeRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices"], context: scopesContext, path: TECH_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: DEVICES_ME_ROUTE }) })
  ] });
};
