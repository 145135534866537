"use strict";
import { jsx } from "react/jsx-runtime";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { DOCUMENTS_NEW_TEAM_ROUTE } from "@/lib/routes";
import { DnTeamListing } from "@/v2/feature/document-new/features/document-new-team/dn-team-listing.page";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { ReachType } from "@/v2/interfaces/general.interface";
export const DocumentNewTeamRouter = ({ customRuleDataset }) => {
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_TEAM_DOCUMENTS, path: DOCUMENTS_NEW_TEAM_ROUTE, exact: true, children: /* @__PURE__ */ jsx(DnTeamListing, { reach: ReachType.Team, customRuleDataset }) }) });
};
