"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { DocumentsCompanyRouter } from "@v2/feature/documents/documents.company.router";
import { DocumentsMeRouter } from "@v2/feature/documents/documents.me.router";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { DOCUMENTS_COMPANY_DOMAIN_ROUTE, DOCUMENTS_ME_DOMAIN_ROUTE, DOCUMENTS_TEAM_DOMAIN_ROUTE } from "@/lib/routes";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { DocumentsTeamRouter } from "@/v2/feature/documents/documents.team.router";
export const DocumentsRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_COMPANY_DOCUMENTS, path: DOCUMENTS_COMPANY_DOMAIN_ROUTE, children: /* @__PURE__ */ jsx(DocumentsCompanyRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_TEAM_DOCUMENTS, path: DOCUMENTS_TEAM_DOMAIN_ROUTE, children: /* @__PURE__ */ jsx(DocumentsTeamRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_MY_DOCUMENTS, path: DOCUMENTS_ME_DOMAIN_ROUTE, children: /* @__PURE__ */ jsx(DocumentsMeRouter, {}) })
  ] });
};
