"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { AppIntegrationAPI } from "@v2/feature/app-integration/app-integration.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { ReactComponent as Cancel } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import {
  APPS_NOT_REQUIRING_REFRESH_POST_ACTION,
  APP_ACTION_DRAWER_MODES,
  APP_GROUP_MANAGEMENT_DRAWER_MODES,
  REFRESH_DELAY_APP_USER_LIST
} from "@/v2/feature/app-integration/app-integration.interface";
import {
  externalUserHasDelayedAppAction,
  getCancellableActionMenuOption,
  getDelayedActionsForExternalUser,
  getDelayedActionsForUserId,
  userHasDelayedAppAction
} from "@/v2/feature/app-integration/app-integration.util";
import {
  getActionNameForApp,
  hasActiveEmailForAccount
} from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { tablePrimaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppDetailsActionButton = ({
  user,
  alreadyImported,
  employment,
  appStub,
  refresh,
  markTeamUserPending,
  setOnboardUserDrawerOpen,
  setIsAppActionsDrawerOpen,
  setGroupManagementDrawerOpen,
  setGroupManagementDrawerMode,
  setSelectedActiveUserLogin,
  setPreselectedUser,
  setEmployeeForOnboarding,
  setActionsDrawerMode,
  delayedActions,
  refreshDelayedActions,
  table,
  hasAppsAllOrAppOwner,
  error,
  setAppStub,
  appAccessDetailsDrawerMode = false,
  setAppAccessDetailsDrawerMode
}) => {
  const [state] = useContext(GlobalContext);
  const [actionOptions, setActionOptions] = useState(void 0);
  const actionDate = /* @__PURE__ */ new Date();
  const [showMessage] = useMessage();
  const [refreshAt, setRefreshAt] = useState(null);
  const { polyglot } = usePolyglot();
  useEffect(() => {
    if (!refreshAt) return;
    const delay = Math.max(refreshAt - Date.now(), 0);
    setTimeout(() => {
      setRefreshAt(null);
      refresh(appStub);
    }, delay);
  }, [appStub, refresh, refreshAt]);
  function refreshApp(delay = REFRESH_DELAY_APP_USER_LIST) {
    setRefreshAt(Date.now() + delay);
  }
  function getActionDate(date) {
    return (date == null ? void 0 : date.setHours(0, 0, 0, 0)) === (/* @__PURE__ */ new Date()).setHours(0, 0, 0, 0) ? void 0 : date;
  }
  function userStatusIs(status, choices) {
    if (!status) return false;
    return choices.includes(status);
  }
  const closeAppAccessDetailDrawerIfOpen = useCallback(() => {
    if (appAccessDetailsDrawerMode && setAppAccessDetailsDrawerMode)
      setTimeout(() => setAppAccessDetailsDrawerMode(false), 500);
  }, [appAccessDetailsDrawerMode, setAppAccessDetailsDrawerMode]);
  const suspendUser = useCallback(() => __async(void 0, null, function* () {
    try {
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.suspend);
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if (setPreselectedUser) setPreselectedUser(user);
    } catch (error2) {
      showMessage(`Oops, something happened. Please try again: ${error2}`, "error");
    }
  }), [
    setActionsDrawerMode,
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    setPreselectedUser,
    user,
    showMessage
  ]);
  const activateUser = useCallback(
    (date) => __async(void 0, null, function* () {
      var _a, _b, _c;
      try {
        yield AppIntegrationAPI.activateAppUser(
          appStub,
          user == null ? void 0 : user.id,
          (_a = user == null ? void 0 : user.userId) != null ? _a : 0,
          getActionDate(date)
        );
        showMessage("Activation initiated", "success");
        if ((user == null ? void 0 : user.userId) && markTeamUserPending) markTeamUserPending(Number(user == null ? void 0 : user.userId));
      } catch (error2) {
        if (((_c = (_b = error2.response) == null ? void 0 : _b.data) == null ? void 0 : _c.error) === `Higher tier of ${appStub} app needed.`) {
          showMessage(
            "In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.",
            "error"
          );
        } else {
          showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error2)}`, "error");
        }
      }
    }),
    [appStub, showMessage, user == null ? void 0 : user.id, user == null ? void 0 : user.userId, markTeamUserPending]
  );
  const unassignUser = useCallback(
    (_0) => __async(void 0, [_0], function* ({ emailObj }) {
      try {
        yield AppIntegrationAPI.unassignUser(appStub, user == null ? void 0 : user.userId, emailObj.email);
        showMessage("Account unassigned successfully", "success");
        if (!APPS_NOT_REQUIRING_REFRESH_POST_ACTION.includes(appStub)) refresh(appStub);
        if ((user == null ? void 0 : user.userId) && markTeamUserPending) markTeamUserPending(Number(user == null ? void 0 : user.userId));
      } catch (error2) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error2)}`, "error");
      }
    }),
    [appStub, user == null ? void 0 : user.userId, showMessage, refresh, markTeamUserPending]
  );
  const deleteUserFromDrawer = useCallback(() => __async(void 0, null, function* () {
    try {
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.delete);
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if (setPreselectedUser) setPreselectedUser(user);
    } catch (error2) {
      showMessage(`Oops, something happened. Please try again: ${error2}`, "error");
    }
  }), [
    setActionsDrawerMode,
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    setPreselectedUser,
    user,
    showMessage
  ]);
  const onboardUser = useCallback(() => __async(void 0, null, function* () {
    try {
      if (setOnboardUserDrawerOpen) setOnboardUserDrawerOpen(true);
      if (setPreselectedUser) setPreselectedUser(user);
    } catch (error2) {
      showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error2)}`, "error");
    }
  }), [setOnboardUserDrawerOpen, setPreselectedUser, showMessage, user]);
  const onboardEmployment = useCallback(() => __async(void 0, null, function* () {
    try {
      if (setOnboardUserDrawerOpen) setOnboardUserDrawerOpen(true);
      if (setEmployeeForOnboarding) setEmployeeForOnboarding(employment);
    } catch (error2) {
      showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error2)}`, "error");
    }
  }), [employment, setEmployeeForOnboarding, setOnboardUserDrawerOpen, showMessage]);
  const resetPasswordForUser = useCallback(() => __async(void 0, null, function* () {
    var _a, _b, _c, _d, _e;
    try {
      if (appStub === "google-workspace" && user && ((user == null ? void 0 : user.userId) || (user == null ? void 0 : user.id))) {
        yield AppIntegrationAPI.resetPasswordForAppUser(
          appStub,
          user.id,
          (user == null ? void 0 : user.userId) ? +(user == null ? void 0 : user.userId) : 0,
          (_a = user == null ? void 0 : user.primaryEmail) != null ? _a : "",
          (_c = (_b = user == null ? void 0 : user.name) == null ? void 0 : _b.fullName) != null ? _c : ""
        );
        showMessage("Password reset successfully; please check your email for the temporary password.", "success");
      } else {
        refreshApp(1e3);
      }
    } catch (error2) {
      if (((_e = (_d = error2.response) == null ? void 0 : _d.data) == null ? void 0 : _e.error) === `Higher tier of ${appStub} app needed.`) {
        showMessage(
          "In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.",
          "error"
        );
      } else {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error2)}`, "error");
      }
    }
  }), [appStub, showMessage, user]);
  const assignUser = useCallback(() => __async(void 0, null, function* () {
    try {
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if ((user == null ? void 0 : user.login) && setSelectedActiveUserLogin) setSelectedActiveUserLogin(user == null ? void 0 : user.login);
      else if ((user == null ? void 0 : user.primaryEmail) && setSelectedActiveUserLogin) setSelectedActiveUserLogin(user == null ? void 0 : user.primaryEmail);
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.assign);
    } catch (error2) {
      showMessage(`Oops, something happened. Please try again: ${error2}`, "error");
    }
  }), [
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    user == null ? void 0 : user.login,
    user == null ? void 0 : user.primaryEmail,
    setSelectedActiveUserLogin,
    setActionsDrawerMode,
    showMessage
  ]);
  const transferDataForUserBeforeDeleting = useCallback(() => __async(void 0, null, function* () {
    try {
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
      if ((user == null ? void 0 : user.id) && setPreselectedUser) setPreselectedUser(user);
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete);
    } catch (error2) {
      showMessage(`Oops, something happened. Please try again: ${error2}`, "error");
    }
  }), [
    setAppStub,
    appStub,
    setIsAppActionsDrawerOpen,
    closeAppAccessDetailDrawerIfOpen,
    user,
    setPreselectedUser,
    setActionsDrawerMode,
    showMessage
  ]);
  const giveAccess = useCallback(() => __async(void 0, null, function* () {
    try {
      if (setActionsDrawerMode) setActionsDrawerMode(APP_ACTION_DRAWER_MODES.create);
      if (setPreselectedUser) setPreselectedUser(user);
      if (setAppStub) setAppStub(appStub);
      closeAppAccessDetailDrawerIfOpen();
      setIsAppActionsDrawerOpen(true);
    } catch (error2) {
      showMessage(`Oops, something happened. Please try again: ${error2}`, "error");
    }
  }), [
    setActionsDrawerMode,
    setPreselectedUser,
    user,
    setAppStub,
    appStub,
    closeAppAccessDetailDrawerIfOpen,
    setIsAppActionsDrawerOpen,
    showMessage
  ]);
  const cancelScheduledAction = useCallback(
    (jobId, userId, action) => __async(void 0, null, function* () {
      try {
        const successfullyCancelled = yield AppIntegrationAPI.cancelScheduledAction(appStub, jobId, userId, action);
        if (successfullyCancelled) {
          showMessage("Action cancelled", "success");
          if (refreshDelayedActions) refreshDelayedActions();
        } else {
          showMessage("Failed to cancel action", "error");
          if (refreshDelayedActions) refreshDelayedActions();
        }
        closeAppAccessDetailDrawerIfOpen();
      } catch (error2) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error2)}`, "error");
      }
    }),
    [appStub, closeAppAccessDetailDrawerIfOpen, showMessage, refreshDelayedActions]
  );
  useEffect(() => {
    var _a;
    let options = [];
    if ((user == null ? void 0 : user.userId) && ["team", "candidates"].includes(table) && delayedActions && userHasDelayedAppAction(delayedActions, Number(user == null ? void 0 : user.userId), appStub)) {
      const cancellableActions = getDelayedActionsForUserId(delayedActions, Number(user == null ? void 0 : user.userId), appStub);
      cancellableActions.forEach(
        (eachAction) => options.push({
          icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
          label: getCancellableActionMenuOption(eachAction.data.action),
          handler: () => cancelScheduledAction(String(eachAction.id), eachAction.data.userId, eachAction.data.action)
        })
      );
    } else if (((user == null ? void 0 : user.primaryEmail) || (user == null ? void 0 : user.id)) && table === "external" && delayedActions && externalUserHasDelayedAppAction(delayedActions, user.primaryEmail, user.id ? String(user.id) : "0")) {
      const cancellableActions = getDelayedActionsForExternalUser(
        delayedActions,
        (user == null ? void 0 : user.primaryEmail) ? user == null ? void 0 : user.primaryEmail : "",
        (user == null ? void 0 : user.id) ? String(user == null ? void 0 : user.id) : "0"
      );
      cancellableActions.forEach(
        (eachAction) => options.push({
          icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
          label: getCancellableActionMenuOption(eachAction.data.action),
          handler: () => cancelScheduledAction(String(eachAction.id), eachAction.data.userId, eachAction.data.action)
        })
      );
    } else {
      if ((user == null ? void 0 : user.primaryEmail) && table === "external") {
        options.push({
          label: "Assign to team member",
          disabled: error,
          handler: () => assignUser()
        });
      }
      (_a = user == null ? void 0 : user.emails) == null ? void 0 : _a.filter((emailObj) => emailObj.status !== "No access").map((emailObj) => {
        var _a2;
        if (!emailObj.primary) {
          options.push({
            icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
            label: `Unassign ${(_a2 = emailObj.email) != null ? _a2 : user == null ? void 0 : user.primaryEmail}`,
            handler: () => unassignUser({ emailObj })
          });
        }
        return null;
      });
      if ((user == null ? void 0 : user.allowsResetPassword) && (user == null ? void 0 : user.id)) {
        options.push({
          icon: /* @__PURE__ */ jsx(Fragment, {}),
          label: "Reset password",
          disabled: error,
          handler: () => resetPasswordForUser()
        });
      }
      if (!["candidates"].includes(table) && (user == null ? void 0 : user.allowsSuspend) && !userStatusIs(user == null ? void 0 : user.userStatus, ["Suspended", "No access"]) && (user == null ? void 0 : user.id)) {
        options.push({
          icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
          label: getActionNameForApp("Suspend", appStub),
          disabled: error,
          handler: () => suspendUser()
        });
      }
      if (user && (user == null ? void 0 : user.allowsGroupManagement) && !userStatusIs(user == null ? void 0 : user.userStatus, ["Deleted", "Suspended", "No access"]) && (user == null ? void 0 : user.id)) {
        options.push({
          icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
          label: getActionNameForApp("Add to group", appStub),
          disabled: error,
          handler: (e) => {
            if (setPreselectedUser) setPreselectedUser(user);
            setGroupManagementDrawerOpen(true);
            setGroupManagementDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.addExistingToGroup);
            e == null ? void 0 : e.stopPropagation();
          }
        });
      }
      if ((user == null ? void 0 : user.allowsDelete) && !["employments", "candidates"].includes(table) && !userStatusIs(user == null ? void 0 : user.userStatus, ["Scheduled", "Deleted", "No access"])) {
        options.push({
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          label: getActionNameForApp("Delete", appStub),
          disabled: (user == null ? void 0 : user.userId) === state.user.userId || error,
          handler: () => {
            if (appStub !== "google-workspace") deleteUserFromDrawer();
            if (appStub === "google-workspace") {
              transferDataForUserBeforeDeleting();
            }
          }
        });
      }
      if (!["candidates"].includes(table) && (user == null ? void 0 : user.allowsSuspend) && (user == null ? void 0 : user.userStatus) === "Suspended" && (user == null ? void 0 : user.id)) {
        options.push({
          icon: /* @__PURE__ */ jsx(Fragment, {}),
          label: "Activate",
          disabled: error,
          handler: () => activateUser(actionDate)
        });
      }
      if ((user == null ? void 0 : user.allowsGiveAccess) && userStatusIs(user == null ? void 0 : user.userStatus, ["No access"]) && !hasActiveEmailForAccount(user)) {
        options.push({
          icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
          label: "Give access",
          disabled: error,
          handler: (e) => {
            giveAccess();
            e == null ? void 0 : e.stopPropagation();
          }
        });
      }
      if (!alreadyImported && (employment == null ? void 0 : employment.status) !== "No access" && (employment == null ? void 0 : employment.allowsImport) && ["employments"].includes(table) && (employment == null ? void 0 : employment.status) !== "Imported") {
        options.push({
          icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
          label: "Onboard",
          disabled: error,
          handler: () => onboardEmployment()
        });
      }
      if ((user == null ? void 0 : user.allowsImport) && ["candidates"].includes(table) && (user == null ? void 0 : user.userStatus) !== "Imported") {
        options.push({
          icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
          label: "Onboard",
          disabled: error,
          handler: () => onboardUser()
        });
      }
    }
    setActionOptions(options);
  }, [activateUser, assignUser, resetPasswordForUser, suspendUser, table, unassignUser, user]);
  const onlyHasGiveAccess = (actionOptions2) => {
    return (actionOptions2 == null ? void 0 : actionOptions2.length) === 1 && (actionOptions2 == null ? void 0 : actionOptions2.find((eachOption) => eachOption.label === "Give access"));
  };
  return appAccessDetailsDrawerMode ? /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { display: "flex", gap: spacing.g10 }), children: [
    onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        onClick: (e) => {
          giveAccess();
          e.stopPropagation();
        },
        id: `btnCreate_${user == null ? void 0 : user.userId}`,
        children: polyglot.t("AppAccessDetailsDrawer.giveAccess")
      },
      "delete"
    ),
    actionOptions && actionOptions.length > 0 && !onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: actionOptions,
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: polyglot.t("General.actions"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end",
          fullWidth: true
        },
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        transformOrigin: { horizontal: "left", vertical: "top" }
      }
    ) })
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", alignItems: "center", gap: spacing.m10 }, children: [
    onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(
      IconButton,
      {
        disabled: error,
        sx: tablePrimaryIconButtonSx,
        onClick: (e) => {
          giveAccess();
          e.stopPropagation();
        },
        id: `btnCreate_${user == null ? void 0 : user.userId}`,
        children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
      }
    ),
    actionOptions && actionOptions.length > 0 && !onlyHasGiveAccess(actionOptions) && hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: actionOptions,
        actionButtonDetails: {
          type: "iconButton",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "actions",
          icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
        }
      }
    ) })
  ] });
};
