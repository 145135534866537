"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const DrawerViewerItem = ({ title, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    typeof title === "string" ? /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: title }) : /* @__PURE__ */ jsx(Box, { children: title }),
    typeof value === "string" ? /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "DarkGrey", children: value }) : /* @__PURE__ */ jsx(Box, { children: value })
  ] });
};
