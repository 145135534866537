"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { UserDetailsScopes } from "@/component/dashboard/userDetails/user-details.scopes";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ONBOARDING_USER_STATUS_ROUTE, USER_PERSONAL_TAB } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { OnboardNewEmployee } from "@/v2/feature/onboarding/components/onboard-new-employee.component";
import { filterByLifecycle } from "@/v2/feature/onboarding/onboarding.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserAPI } from "@/v2/feature/user/user.api";
import { filterStringToObject } from "@/v2/feature/user/user.util";
import { OnboardingEndpoints } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingUserTable = () => {
  const { polyglot } = usePolyglot();
  const TabFilter = [
    { name: polyglot.t("OnboardingUserTable.tabFilter.all"), value: "all" },
    { name: polyglot.t("OnboardingUserTable.tabFilter.draft"), value: "draft" },
    { name: polyglot.t("OnboardingUserTable.tabFilter.employee"), value: "employee" },
    { name: polyglot.t("OnboardingUserTable.tabFilter.contractor"), value: "contractor" }
  ];
  const [state] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("all");
  const [categoryFilterString, setCategoryFilterString] = useState("lifecycle=Employed,Hired");
  const { getCachedUserById, refreshCachedUsers } = useCachedUsers({ refresh: true });
  const [isOnboardNewUserOpen, setOnboardNewUserOpen] = useState(false);
  const onboardNewUser = useRef();
  const routerHistory = useHistory();
  const { trackPage } = useJune();
  const openNewUserDrawer = useCallback(
    (userId) => __async(void 0, null, function* () {
      onboardNewUser.current = userId ? getCachedUserById(userId) : void 0;
      const additionalUserInfoForOnboarding = userId ? yield UserAPI.getUserSummaryForOnboardingById(userId, false) : {};
      onboardNewUser.current = onboardNewUser.current ? __spreadValues(__spreadValues({}, onboardNewUser.current), additionalUserInfoForOnboarding) : onboardNewUser.current;
      setOnboardNewUserOpen(true);
    }),
    [getCachedUserById]
  );
  const { data, mutate: refreshUserOnboardingStates } = useApiClient(OnboardingEndpoints.getOnboardingUsers(), {
    suspense: false
  });
  const onboardingUsers = useMemo(() => {
    const collator = new Intl.Collator(void 0, { sensitivity: "base" });
    return data == null ? void 0 : data.sort((a, b) => collator.compare(a.displayName, b.displayName));
  }, [data]);
  const filteredUsers = useMemo(() => {
    const filter = filterString;
    if (!categoryFilterString && !searchInput && filter === "all" || !onboardingUsers) {
      return onboardingUsers;
    }
    const lowerSearchInput = searchInput.toLocaleLowerCase();
    let onboardingFilteredUsers = onboardingUsers.filter((u) => {
      const user = getCachedUserById(u.userId);
      if (!user) return false;
      const matchesFilter = {
        all: () => true,
        draft: () => !u.template,
        employee: () => u.contractType === "Employee",
        contractor: () => u.contractType === "Contractor"
      }[filter]();
      return matchesFilter && `${user.firstName} ${user.lastName}`.toLocaleLowerCase().includes(lowerSearchInput);
    });
    if (categoryFilterString) {
      const filterOptions = filterStringToObject(categoryFilterString);
      for (const key of Object.keys(filterOptions)) {
        switch (key) {
          case "lifecycle": {
            onboardingFilteredUsers = filterByLifecycle(onboardingFilteredUsers, filterOptions[key]);
            break;
          }
          default:
            break;
        }
      }
    }
    return onboardingFilteredUsers;
  }, [filterString, getCachedUserById, onboardingUsers, searchInput, categoryFilterString]);
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: () => polyglot.t("OnboardingUserTable.name"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.displayName),
        maxSize: 220,
        minSize: 150,
        cell: (c) => {
          return /* @__PURE__ */ jsx(UserCell, { userId: c.getValue().userId });
        }
      },
      {
        id: "type",
        header: () => polyglot.t("OnboardingUserTable.type"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.contractType),
        maxSize: 50,
        minSize: 100,
        cell: (c) => {
          var _a;
          return (_a = c.getValue().contractType) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "createdby",
        header: () => polyglot.t("OnboardingUserTable.createdBy"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = getCachedUserById((_a = item.createdBy) != null ? _a : 0)) == null ? void 0 : _b.displayName;
        }, {
          sensitivity: "base"
        }),
        maxSize: 220,
        minSize: 150,
        cell: (c) => {
          return c.row.original.createdBy ? /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.createdBy }) : /* @__PURE__ */ jsx(Fragment, {});
        }
      },
      {
        id: "startdate",
        header: () => polyglot.t("OnboardingUserTable.startdate"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.startDate),
        maxSize: 220,
        minSize: 150,
        cell: (c) => {
          const startDate = c.getValue().startDate;
          return startDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: startDate }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "template",
        header: () => polyglot.t("OnboardingUserTable.template"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.templateName),
        maxSize: 220,
        minSize: 150,
        cell: (c) => {
          var _a;
          return (_a = c.row.original.templateName) != null ? _a : "";
        }
      },
      {
        id: "action-buttons",
        header: "",
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 50,
        minSize: 100,
        cell: (c) => {
          const { userId } = c.row.original;
          const canViewProfile = checkScopes(currentUser, UserDetailsScopes.VIEW_USER_PERSONAL, { userId });
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: [
                {
                  label: polyglot.t("OnboardingUserTable.view"),
                  handler: () => routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId })),
                  disabled: !canViewProfile
                }
              ],
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(ActionsSmall, {})
              }
            }
          ) });
        }
      }
    ],
    [currentUser, getCachedUserById, routerHistory, polyglot]
  );
  useEffect(() => {
    trackPage("Onboarding overview page");
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Onboarding" }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => openNewUserDrawer(), sizeVariant: "small", colorVariant: "primary", children: polyglot.t("OnboardingUserTable.onboard") })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: !filteredUsers, sx: { overflow: "hidden" }, children: /* @__PURE__ */ jsx(Stack, { sx: { gap: spacing.g20, height: "inherit" }, children: filteredUsers && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            filters: TabFilter,
            setFilterValue: setFilterString,
            filterValue: filterString,
            onFilterChange: ({ filterValue }) => {
              setFilterString(filterValue);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CategoryFilters,
          {
            filterTypes: {
              lifecycle: [
                { label: polyglot.t("OnboardingUserTable.employed"), value: "Employed" },
                { label: polyglot.t("OnboardingUserTable.hired"), value: "Hired" },
                { label: polyglot.t("OnboardingUserTable.terminated"), value: "Terminated" },
                { label: polyglot.t("OnboardingUserTable.leave"), value: "Leave" }
              ]
            },
            setFilterString: setCategoryFilterString,
            filterString: categoryFilterString
          }
        ),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredUsers,
          columnData: columns,
          stickyHeader: true,
          rowClick: (row) => {
            if (!row.original.template) {
              openNewUserDrawer(row.original.userId);
              return;
            }
            routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: row.original.userId }));
          }
        }
      )
    ] }) }) }),
    /* @__PURE__ */ jsx(
      OnboardNewEmployee,
      {
        isOpen: isOnboardNewUserOpen,
        setIsOpen: setOnboardNewUserOpen,
        onClose: () => __async(void 0, null, function* () {
          if (refreshUserOnboardingStates) yield refreshUserOnboardingStates();
          yield refreshCachedUsers();
        }),
        initialValues: onboardNewUser.current,
        onDraftUserCreated: () => setOnboardNewUserOpen(false)
      }
    )
  ] });
};
