"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { SelectComponent } from "@v2/components/forms/select.component";
import { ReportSQLOperator } from "@v2/feature/reports/reports.interface";
export const BooleanSelector = ({ operator, value, setValue, label }) => {
  const BooleanOperatorsWithNoExtraField = useMemo(() => {
    return /* @__PURE__ */ new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);
  return BooleanOperatorsWithNoExtraField.has(operator) ? null : /* @__PURE__ */ jsx(
    SelectComponent,
    {
      name: "value",
      label,
      options: [
        { label: "True", value: "true" },
        { label: "False", value: "false" }
      ],
      value,
      onChange: (e) => {
        setValue(e.target.value);
      },
      fullWidth: true
    }
  );
};
