"use strict";
export var DeviceType = /* @__PURE__ */ ((DeviceType2) => {
  DeviceType2["Laptop"] = "Laptop";
  DeviceType2["Desktop"] = "Desktop";
  DeviceType2["Phone"] = "Phone";
  DeviceType2["Tablet"] = "Tablet";
  DeviceType2["Monitor"] = "Monitor";
  DeviceType2["Headset"] = "Headset";
  DeviceType2["Mouse"] = "Mouse";
  DeviceType2["Keyboard"] = "Keyboard";
  DeviceType2["Other"] = "Other";
  return DeviceType2;
})(DeviceType || {});
export var ReturnDeviceReason = /* @__PURE__ */ ((ReturnDeviceReason2) => {
  ReturnDeviceReason2["Damage"] = "Damage";
  ReturnDeviceReason2["CancelContract"] = "CancelContract";
  ReturnDeviceReason2["ReturnToCompanySite"] = "ReturnToCompanySite";
  ReturnDeviceReason2["SendToZeltStorage"] = "SendToZeltStorage";
  return ReturnDeviceReason2;
})(ReturnDeviceReason || {});
export var PasswordPolicyLevel = /* @__PURE__ */ ((PasswordPolicyLevel2) => {
  PasswordPolicyLevel2["MODERATE"] = "MODERATE";
  PasswordPolicyLevel2["STRICT"] = "STRICT";
  PasswordPolicyLevel2["BASIC"] = "BASIC";
  PasswordPolicyLevel2["NONE"] = "NONE";
  return PasswordPolicyLevel2;
})(PasswordPolicyLevel || {});
export var DeviceOwnership = /* @__PURE__ */ ((DeviceOwnership2) => {
  DeviceOwnership2["Rental"] = "Rental";
  DeviceOwnership2["CompanyPurchase"] = "CompanyPurchase";
  DeviceOwnership2["Company"] = "Company";
  return DeviceOwnership2;
})(DeviceOwnership || {});
export var DevicePolicyType = /* @__PURE__ */ ((DevicePolicyType2) => {
  DevicePolicyType2["passcode"] = "passcode";
  DevicePolicyType2["encryption"] = "encryption";
  DevicePolicyType2["location"] = "location";
  DevicePolicyType2["updates"] = "updates";
  DevicePolicyType2["apps"] = "apps";
  DevicePolicyType2["icloud"] = "icloud";
  DevicePolicyType2["firewall"] = "firewall";
  return DevicePolicyType2;
})(DevicePolicyType || {});
export var SupportedDeviceType = /* @__PURE__ */ ((SupportedDeviceType2) => {
  SupportedDeviceType2["android"] = "android";
  SupportedDeviceType2["windows"] = "windows";
  SupportedDeviceType2["apple"] = "apple";
  return SupportedDeviceType2;
})(SupportedDeviceType || {});
export var DevicePolicyZeltType = /* @__PURE__ */ ((DevicePolicyZeltType2) => {
  DevicePolicyZeltType2["passcode"] = "passcode";
  DevicePolicyZeltType2["encryption"] = "encryption";
  DevicePolicyZeltType2["icloud"] = "icloud";
  DevicePolicyZeltType2["firewall"] = "firewall";
  DevicePolicyZeltType2["apps"] = "apps";
  return DevicePolicyZeltType2;
})(DevicePolicyZeltType || {});
export var DevicePolicyNameByZelt = /* @__PURE__ */ ((DevicePolicyNameByZelt2) => {
  DevicePolicyNameByZelt2["passcode"] = "Passcode";
  DevicePolicyNameByZelt2["encryption"] = "Encryption";
  DevicePolicyNameByZelt2["icloud"] = "Restrict iCloud";
  DevicePolicyNameByZelt2["firewall"] = "Firewall";
  DevicePolicyNameByZelt2["apps"] = "Managed Apps";
  return DevicePolicyNameByZelt2;
})(DevicePolicyNameByZelt || {});
export var DevicePolicyConfigurableNameByZelt = /* @__PURE__ */ ((DevicePolicyConfigurableNameByZelt2) => {
  DevicePolicyConfigurableNameByZelt2["passcode"] = "Passcode";
  DevicePolicyConfigurableNameByZelt2["encryption"] = "Encryption";
  DevicePolicyConfigurableNameByZelt2["icloud"] = "Restrict iCloud";
  DevicePolicyConfigurableNameByZelt2["firewall"] = "Firewall";
  DevicePolicyConfigurableNameByZelt2["apps"] = "Managed Apps";
  return DevicePolicyConfigurableNameByZelt2;
})(DevicePolicyConfigurableNameByZelt || {});
export var DevicePossessionType = /* @__PURE__ */ ((DevicePossessionType2) => {
  DevicePossessionType2["CompanySite"] = "CompanySite";
  DevicePossessionType2["User"] = "User";
  DevicePossessionType2["ZeltStorage"] = "ZeltStorage";
  DevicePossessionType2["ZeltStock"] = "ZeltStock";
  return DevicePossessionType2;
})(DevicePossessionType || {});
export const DevicePossessionTypeLabels = {
  ["CompanySite" /* CompanySite */]: "Company site",
  ["User" /* User */]: "User",
  ["ZeltStorage" /* ZeltStorage */]: "Zelt storage",
  ["ZeltStock" /* ZeltStock */]: "Zelt stock"
};
export const DevicePossessionTypeOptions = [
  { label: "CompanySite", value: "CompanySite" /* CompanySite */ },
  { label: "User", value: "User" /* User */ },
  { label: "ZeltStorage", value: "ZeltStorage" /* ZeltStorage */ },
  { label: "ZeltStock", value: "ZeltStock" /* ZeltStock */ }
];
export var DeviceTransitStatus = /* @__PURE__ */ ((DeviceTransitStatus2) => {
  DeviceTransitStatus2["Pending"] = "Pending";
  DeviceTransitStatus2["Rejected"] = "Rejected";
  DeviceTransitStatus2["Shipping"] = "Shipping";
  DeviceTransitStatus2["Delivered"] = "Delivered";
  DeviceTransitStatus2["Cancelled"] = "Cancelled";
  DeviceTransitStatus2["MangedByZelt"] = "MangedByZelt";
  return DeviceTransitStatus2;
})(DeviceTransitStatus || {});
export var DeviceOrderStatus = /* @__PURE__ */ ((DeviceOrderStatus2) => {
  DeviceOrderStatus2["Requested"] = "Requested";
  DeviceOrderStatus2["Placed"] = "Placed";
  DeviceOrderStatus2["Accepted"] = "Accepted";
  DeviceOrderStatus2["Rejected"] = "Rejected";
  DeviceOrderStatus2["Cancelled"] = "Cancelled";
  DeviceOrderStatus2["Shipping"] = "Shipping";
  DeviceOrderStatus2["Delivered"] = "Delivered";
  return DeviceOrderStatus2;
})(DeviceOrderStatus || {});
export var DeliveryMethod = /* @__PURE__ */ ((DeliveryMethod2) => {
  DeliveryMethod2["ShippingToEmployee"] = "shippingToEmployee";
  DeliveryMethod2["ShippingToSite"] = "shippingToSite";
  DeliveryMethod2["Handover"] = "handover";
  DeliveryMethod2["AssignDepToUser"] = "assignDepToUser";
  return DeliveryMethod2;
})(DeliveryMethod || {});
export var DeviceShippingMethod = /* @__PURE__ */ ((DeviceShippingMethod2) => {
  DeviceShippingMethod2["RequestZeltShipping"] = "requestZeltShipping";
  DeviceShippingMethod2["ManualTracking"] = "manualTracking";
  DeviceShippingMethod2["NoTrackingReassignInstantly"] = "noTrackingReassignInstantly";
  return DeviceShippingMethod2;
})(DeviceShippingMethod || {});
export var WipeMethod = /* @__PURE__ */ ((WipeMethod2) => {
  WipeMethod2["WipeNow"] = "wipeNow";
  WipeMethod2["WipeLater"] = "wipeLater";
  WipeMethod2["Disenrol"] = "disenrol";
  return WipeMethod2;
})(WipeMethod || {});
export var OrderDeliveryCountry = /* @__PURE__ */ ((OrderDeliveryCountry2) => {
  OrderDeliveryCountry2["UK"] = "UK";
  OrderDeliveryCountry2["EU"] = "EU";
  return OrderDeliveryCountry2;
})(OrderDeliveryCountry || {});
export var DeviceContractLength = /* @__PURE__ */ ((DeviceContractLength2) => {
  DeviceContractLength2[DeviceContractLength2["Months36"] = 36] = "Months36";
  DeviceContractLength2[DeviceContractLength2["Months24"] = 24] = "Months24";
  DeviceContractLength2[DeviceContractLength2["Months12"] = 12] = "Months12";
  DeviceContractLength2[DeviceContractLength2["Months6"] = 6] = "Months6";
  return DeviceContractLength2;
})(DeviceContractLength || {});
export var DeviceModelOs = /* @__PURE__ */ ((DeviceModelOs2) => {
  DeviceModelOs2["MacOS"] = "macos";
  DeviceModelOs2["iOS"] = "ios";
  DeviceModelOs2["iPadOS"] = "ipados";
  DeviceModelOs2["Android"] = "android";
  DeviceModelOs2["Windows"] = "windows";
  DeviceModelOs2["Linux"] = "linux";
  DeviceModelOs2["Other"] = "other";
  DeviceModelOs2["None"] = "none";
  return DeviceModelOs2;
})(DeviceModelOs || {});
export const AppleDevices = ["macos" /* MacOS */, "ios" /* iOS */, "ipados" /* iPadOS */];
export var DeviceInformationTypes = /* @__PURE__ */ ((DeviceInformationTypes2) => {
  DeviceInformationTypes2["OSVersion"] = "OS Version";
  DeviceInformationTypes2["Storage"] = "Storage";
  DeviceInformationTypes2["LastActive"] = "Last active";
  DeviceInformationTypes2["Mdm"] = "MDM";
  return DeviceInformationTypes2;
})(DeviceInformationTypes || {});
export var MDMConfigurationTypes = /* @__PURE__ */ ((MDMConfigurationTypes2) => {
  MDMConfigurationTypes2["Passcode"] = "Passcode";
  MDMConfigurationTypes2["Encryption"] = "Encryption";
  MDMConfigurationTypes2["iCloudSync"] = "iCloud sync";
  MDMConfigurationTypes2["Firewall"] = "Firewall";
  MDMConfigurationTypes2["Apps"] = "Managed Apps";
  MDMConfigurationTypes2["ActivationLock"] = "Activation Lock";
  return MDMConfigurationTypes2;
})(MDMConfigurationTypes || {});
export var SecurityStatus = /* @__PURE__ */ ((SecurityStatus2) => {
  SecurityStatus2["None"] = "None";
  SecurityStatus2["NotEnrolled"] = "Not enrolled";
  SecurityStatus2["Alert"] = "Alert";
  SecurityStatus2["Good"] = "Good";
  return SecurityStatus2;
})(SecurityStatus || {});
export var EnrolmentType = /* @__PURE__ */ ((EnrolmentType2) => {
  EnrolmentType2["NONE"] = "NONE";
  EnrolmentType2["ABM"] = "ABM";
  EnrolmentType2["ABM_DEP"] = "ABM_DEP";
  EnrolmentType2["OPEN_ENROLMENT"] = "OPEN_ENROLMENT";
  return EnrolmentType2;
})(EnrolmentType || {});
export var ConfigurablePoliciesByZeltMdm = /* @__PURE__ */ ((ConfigurablePoliciesByZeltMdm2) => {
  ConfigurablePoliciesByZeltMdm2["configurablePasscode"] = "configurablePasscode";
  ConfigurablePoliciesByZeltMdm2["configurableICloudRestriction"] = "configurableICloudRestriction";
  ConfigurablePoliciesByZeltMdm2["configurableAdditionalPasswordPolicy"] = "configurableAdditionalPasswordPolicy";
  ConfigurablePoliciesByZeltMdm2["configurableEncryptionPolicy"] = "configurableEncryptionPolicy";
  ConfigurablePoliciesByZeltMdm2["configurableFirewallPolicy"] = "configurableFirewallPolicy";
  ConfigurablePoliciesByZeltMdm2["configurableScreenSettingPolicy"] = "configurableScreenSettingPolicy";
  ConfigurablePoliciesByZeltMdm2["configurableBrowsing"] = "configurableBrowsing";
  ConfigurablePoliciesByZeltMdm2["configurableSecurity"] = "configurableSecurity";
  ConfigurablePoliciesByZeltMdm2["configurableApps"] = "configurableApps";
  return ConfigurablePoliciesByZeltMdm2;
})(ConfigurablePoliciesByZeltMdm || {});
export var ConfigurableDeviceStatus = /* @__PURE__ */ ((ConfigurableDeviceStatus2) => {
  ConfigurableDeviceStatus2["order"] = "order";
  ConfigurableDeviceStatus2["shipping"] = "shipping";
  ConfigurableDeviceStatus2["inUse"] = "in-use";
  ConfigurableDeviceStatus2["inventory"] = "inventory";
  ConfigurableDeviceStatus2["pending"] = "pending";
  return ConfigurableDeviceStatus2;
})(ConfigurableDeviceStatus || {});
