"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { OffboardingAbsencePage } from "@v2/feature/offboarding/offboarding-process/absence/offboarding-absence.page";
import { OffboardingAppsPage } from "@v2/feature/offboarding/offboarding-process/apps/offboarding-apps.page";
import { OffboardingDevicesPage } from "@v2/feature/offboarding/offboarding-process/devices/offboarding-devices.page";
import { OffboardingManagerChangePage } from "@v2/feature/offboarding/offboarding-process/manager-change/offboarding-manager-change.page";
import {
  OffboardingProcessPage,
  OffboardingStepStatus
} from "@v2/feature/offboarding/offboarding-process/offboarding-process.page";
import { OffboardingTasksPage } from "@v2/feature/offboarding/offboarding-process/tasks/offboarding-tasks.page";
import { OffboardingEndpoints } from "@v2/feature/offboarding/offboarding.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import {
  USER_OFFBOARDING_ABSENCE_ROUTE,
  USER_OFFBOARDING_APPS_ROUTE,
  USER_OFFBOARDING_DEVICES_ROUTE,
  USER_OFFBOARDING_MANAGER_CHANGE_ROUTE,
  USER_OFFBOARDING_ROUTE,
  USER_OFFBOARDING_TASKS_ROUTE
} from "@/lib/routes";
export const OffboardingUserRouter = () => {
  const params = useParams();
  const userId = Number(params.userId);
  const {
    data: offboardingState,
    isLoading,
    mutate: refreshOffboardingDues
  } = useApiClient(OffboardingEndpoints.getOffboardingState(userId), { suspense: false });
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [state, setState] = useState({
    leaveDate: null,
    userEvent: null,
    tasks: OffboardingStepStatus.TODO,
    absence: OffboardingStepStatus.TODO,
    apps: OffboardingStepStatus.TODO,
    devices: OffboardingStepStatus.TODO,
    managerChange: OffboardingStepStatus.TODO,
    tasksData: { tasksNo: 0, pendingTasksNo: 0 },
    appsData: { activeAppsNo: 0, scheduledToDeactivationNo: 0, nonScheduledToDeactivationNo: 0 },
    devicesData: {
      devicePossessionAllTimeNo: 0,
      activeDevicesNo: 0,
      requestedReturnNo: 0,
      nonRequestedReturnNo: 0,
      activeOrdersNo: 0
    },
    absenceData: {
      policyMembershipsNo: 0,
      remainingBalancePoliciesNo: 0,
      remainingBalancePayrollPoliciesNo: 0
    },
    managerChangeData: {
      usersNeedingChangeNo: 0,
      managerChangeRequiredNo: 0,
      wasOrIsManager: false
    }
  });
  useEffect(() => {
    if (offboardingState) {
      setState(offboardingState);
    }
  }, [offboardingState]);
  const refreshState = useCallback(() => __async(void 0, null, function* () {
    if (refreshOffboardingDues) yield refreshOffboardingDues();
  }), [refreshOffboardingDues]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["task:manager"], path: USER_OFFBOARDING_TASKS_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(OffboardingTasksPage, { userId, offboardingState: state, refresh: refreshState, loading: isLoading }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:manager"], path: USER_OFFBOARDING_ABSENCE_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(OffboardingAbsencePage, { userId, offboardingState: state, refresh: refreshState, loading: isLoading }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["apps:manager"], path: USER_OFFBOARDING_APPS_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(OffboardingAppsPage, { userId, offboardingState: state, refresh: refreshState, loading: isLoading }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["devices:manager"], path: USER_OFFBOARDING_DEVICES_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(OffboardingDevicesPage, { userId, refresh: refreshState, loading: isLoading }) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["user.role:manager"],
        path: USER_OFFBOARDING_MANAGER_CHANGE_ROUTE,
        context: scopesContext,
        children: /* @__PURE__ */ jsx(OffboardingManagerChangePage, { userId, refresh: refreshState, loading: isLoading })
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["user.onboard:manager"], path: USER_OFFBOARDING_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(OffboardingProcessPage, { userId, offboardingState: state, refresh: refreshState, loading: isLoading }) })
  ] });
};
