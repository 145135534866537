"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { PayrollHMRCReadonlyState } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-hmrc-readonly.component";
import { PayrollHMRCConnectState } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-connect.component";
import { PayrollSettingHMRCEdit } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/hmrc-settings/payroll-setting-hmrc-edit.component";
export const PayrollSettingHmrcSetting = ({
  payrollId,
  employer,
  setEdit,
  edit,
  refreshPayroll,
  onConnectClick
}) => {
  const hasHMRCDetails = !!employer.hmrcDetails.officeNumber;
  return /* @__PURE__ */ jsxs(Stack, { children: [
    !hasHMRCDetails && /* @__PURE__ */ jsx(PayrollHMRCConnectState, { onConnectClick: () => onConnectClick() }),
    hasHMRCDetails && !edit && /* @__PURE__ */ jsx(PayrollHMRCReadonlyState, { employer, onEditClick: () => setEdit(true) }),
    edit && /* @__PURE__ */ jsx(
      PayrollSettingHMRCEdit,
      {
        title: "HMRC connection",
        payrollId,
        payroll: employer,
        onClose: () => {
          refreshPayroll();
          setEdit(false);
        }
      }
    )
  ] });
};
