"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useRef } from "react";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { SETTINGS_ATTENDANCE_ROUTE } from "@/lib/routes";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { convertMinutesToClockHours } from "@/v2/feature/absence/absence.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
export const NO_SCHEDULE_VALUE = 0;
const NO_SCHEDULE_OPTION = { label: "None", value: NO_SCHEDULE_VALUE };
export const AttendanceScheduleSelect = (_a) => {
  var _b = _a, {
    label = "Attendance schedule",
    name,
    error,
    helperText,
    value,
    onChange,
    footer,
    autoSelectSingleEntity
  } = _b, selectProps = __objRest(_b, [
    "label",
    "name",
    "error",
    "helperText",
    "value",
    "onChange",
    "footer",
    "autoSelectSingleEntity"
  ]);
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const canEditAttendanceSchedules = hasScopes(["attendance:all"], { userId: user.userId });
  const hasAutoSelected = useRef(false);
  const { data: attendanceSchedules } = useApiClient(AttendanceEndpoints.getAttendanceSchedules(), { suspense: false });
  const schedulesOptions = useMemo(() => {
    return attendanceSchedules ? [
      NO_SCHEDULE_OPTION,
      ...attendanceSchedules.map((s) => {
        const ftePercent = s.ftePercent;
        const totalHours = convertMinutesToClockHours(s.totalTime, polyglot);
        const label2 = /* @__PURE__ */ jsxs(Fragment, { children: [
          s.name,
          " ",
          /* @__PURE__ */ jsxs("span", { style: { color: themeColors.Grey }, children: [
            "(",
            polyglot.t("ContractForm.contractFTEPercent", { ftePercent, totalHours }),
            ")"
          ] })
        ] });
        return { label: label2, value: s.id };
      })
    ] : [NO_SCHEDULE_OPTION];
  }, [attendanceSchedules, polyglot]);
  useEffect(() => {
    if (!hasAutoSelected.current && !!autoSelectSingleEntity && !value && (schedulesOptions == null ? void 0 : schedulesOptions.length) === 1) {
      hasAutoSelected.current = true;
      autoSelectSingleEntity(schedulesOptions[0].value);
    }
  }, [autoSelectSingleEntity, schedulesOptions, value]);
  return /* @__PURE__ */ jsx(
    SelectComponent,
    __spreadValues({
      name,
      label,
      options: schedulesOptions != null ? schedulesOptions : [],
      value: value != null ? value : NO_SCHEDULE_VALUE,
      error,
      helperText,
      footer,
      editList: {
        handler: () => routerHistory.push(SETTINGS_ATTENDANCE_ROUTE),
        isHidden: !canEditAttendanceSchedules
      },
      onChange: (e) => {
        if (e.target.value === NO_SCHEDULE_VALUE) {
          e.target.value = null;
        }
        const selectedSchedule = attendanceSchedules == null ? void 0 : attendanceSchedules.find((a) => a.id === e.target.value);
        onChange == null ? void 0 : onChange(e, selectedSchedule);
      }
    }, selectProps)
  );
};
