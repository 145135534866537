"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserAPI } from "@v2/feature/user/user.api";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { translateUserFamilyMemberType } from "@/v2/infrastructure/i18n/translate.util";
export const AddUserFamilyMemberDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  member,
  onClose,
  type,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    AddUserFamilyMemberDrawerContent,
    {
      userId,
      type,
      refresh,
      setIsOpen,
      member
    }
  ) });
};
export const AddUserFamilyMemberDrawerContent = ({
  type,
  refresh,
  member,
  userId,
  setIsOpen
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const upsertMember = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (member)
          yield UserAPI.updateUserFamilyMemberById(userId, member.id, {
            type: values.type,
            name: values.name,
            dob: values.dob ? values.dob : null,
            gender: values.gender ? values.gender : null
          });
        else
          yield UserAPI.addUserFamilyMember(userId, {
            type: values.type,
            name: values.name,
            dob: values.dob ? values.dob : null,
            gender: values.gender ? values.gender : null
          });
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          `${polyglot.t("AddUserFamilyMemberDrawer.errorMessages.badRequest")}. ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, userId, member, refresh, setIsOpen, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      type,
      name: (_a = member == null ? void 0 : member.name) != null ? _a : "",
      dob: (_b = member == null ? void 0 : member.dob) != null ? _b : "",
      gender: (_c = member == null ? void 0 : member.gender) != null ? _c : ""
    },
    validationSchema: Yup.object({
      type: Yup.string().required(polyglot.t("AddUserFamilyMemberDrawer.errorMessages.memberRequired")),
      name: Yup.string().required(polyglot.t("AddUserFamilyMemberDrawer.errorMessages.nameRequired")),
      dob: Yup.string().test(dateFieldTest).nullable().notRequired(),
      gender: Yup.string().nullable().notRequired()
    }),
    onSubmit: upsertMember
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("AddUserFamilyMemberDrawer.details", { type: translateUserFamilyMemberType(type, polyglot) }) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("AddUserFamilyMemberDrawer.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: !!formik.errors.name && formik.touched.name,
        helperText: formik.touched.name && formik.errors.name
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_d = formik.values.dob) != null ? _d : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) formik.setFieldValue("dob", value);
          else formik.setFieldValue("dob", "");
        },
        disableFuture: true,
        name: "dob",
        label: polyglot.t("AddUserFamilyMemberDrawer.dateOfBirth"),
        error: !!formik.errors.dob && formik.touched.dob,
        helperText: formik.touched.dob && formik.errors.dob
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "gender",
        label: polyglot.t("AddUserFamilyMemberDrawer.gender"),
        options: [
          { label: polyglot.t("AddUserFamilyMemberDrawer.male"), value: "male" },
          { label: polyglot.t("AddUserFamilyMemberDrawer.female"), value: "female" },
          { label: polyglot.t("AddUserFamilyMemberDrawer.preferNotToSay"), value: "preferNotToSay" }
        ],
        value: formik.values.gender,
        onChange: formik.handleChange,
        compareValue: formik.values.gender,
        error: !!formik.errors.gender && formik.touched.gender,
        helperText: formik.touched.gender && formik.errors.gender
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m40 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: member ? polyglot.t("General.save") : polyglot.t("General.add"),
        loading,
        fullWidth: true,
        sizeVariant: "large",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
