"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { styled } from "@mui/material/styles";
import { Typography } from "@v2/components/typography/typography.component";
import { borders } from "@v2/styles/borders.styles";
import { themeColors } from "@v2/styles/colors.styles";
const StyledChip = styled("div")(({ onClick, backgroundColor, border }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: "2px 10px",
  borderRadius: "25px",
  backgroundColor: themeColors[backgroundColor],
  cursor: onClick ? "pointer" : "default",
  border: border ? borders[border] : void 0,
  gap: "8px",
  transition: "background-color 0.3s ease",
  "&:hover": { background: themeColors.GreyHover }
}));
export const DeviceActionChip = ({
  name,
  icon,
  onClick,
  backgroundColor = "Background",
  textColor = "Grey",
  border,
  textVariant = "caption"
}) => {
  const iconStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
  const handleClick = (event) => {
    event.stopPropagation();
    if (onClick) onClick(event);
  };
  return /* @__PURE__ */ jsxs(StyledChip, { onClick: onClick ? handleClick : void 0, backgroundColor, border, children: [
    icon && /* @__PURE__ */ jsx("div", { style: iconStyle, children: icon }),
    /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, children: name })
  ] });
};
