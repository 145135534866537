"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { PayrollEditPayCodesDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycodes-drawer.component";
import { PayrollSettingsPayCodesTable } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycodes-table.component";
import { PayrollAPI, PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollCompanySettingsPayCodes = ({ payroll }) => {
  const { polyglot } = usePolyglot();
  const [editingPayCode, setEditingPayCode] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [showMessage] = useMessage();
  const { data: payCodes, mutate: updateAndRefreshPayCodes } = useApiClient(
    PayrollEndpoints.getCompanyPaycodes(payroll.id, false),
    { suspense: false }
  );
  const { data: nominalCodes, mutate: refreshNominalCodes } = useApiClient(
    PayrollEndpoints.getNominalCodes(payroll.id),
    { suspense: false }
  );
  const { data: accountingConfig, mutate: updateAndRefreshAccounting } = useApiClient(
    PayrollEndpoints.getAccounting(payroll.id),
    { suspense: false }
  );
  const payCodesNames = useMemo(() => {
    var _a;
    return (_a = payCodes == null ? void 0 : payCodes.map(({ code }) => code)) != null ? _a : [];
  }, [payCodes]);
  const filteredPayCodes = useMemo(() => {
    const query = searchQuery.trim().toUpperCase();
    if (!query) return payCodes;
    return payCodes == null ? void 0 : payCodes.filter(
      ({ code, title }) => code.toUpperCase().includes(query) || title.toUpperCase().includes(query)
    );
  }, [payCodes, searchQuery]);
  const reimportNominalCodes = useCallback(() => __async(void 0, null, function* () {
    yield PayrollAPI.refreshNominalCodes(payroll.id);
    yield refreshNominalCodes == null ? void 0 : refreshNominalCodes();
  }), [payroll.id, refreshNominalCodes]);
  const setAccountingFieldForPayCode = useCallback(
    (payCode, accountingField) => __async(void 0, null, function* () {
      var _a;
      if (!accountingConfig) return;
      const updatedAccountingFields = [...(_a = accountingConfig.accountingCodes.fields) != null ? _a : []].filter(
        (a) => a.payCode !== payCode
      );
      if (accountingField == null ? void 0 : accountingField.code) {
        updatedAccountingFields.push(accountingField);
      }
      yield PayrollAPI.updateAccounting(payroll.id, updatedAccountingFields);
      updateAndRefreshAccounting == null ? void 0 : updateAndRefreshAccounting(__spreadProps(__spreadValues({}, accountingConfig), {
        accountingCodes: { fields: updatedAccountingFields }
      }));
    }),
    [accountingConfig, payroll.id, updateAndRefreshAccounting]
  );
  const createCompanyPaycode = useCallback(
    (newPayCode, accountingField) => __async(void 0, null, function* () {
      try {
        const fullCode = yield PayrollAPI.createCompanyPaycode(payroll.id, newPayCode);
        const updatedPayCodeList = payCodes == null ? void 0 : payCodes.concat([fullCode]);
        updateAndRefreshPayCodes == null ? void 0 : updateAndRefreshPayCodes(updatedPayCodeList);
        yield setAccountingFieldForPayCode(newPayCode.code, accountingField);
        showMessage(polyglot.t("PayrollPaycodeSettings.paycodeCreated", { paycode: newPayCode.code }), "success");
        return true;
      } catch (error) {
        showMessage(
          polyglot.t("PayrollPaycodeSettings.paycodeCreationFailed", { reason: nestErrorMessage(error) }),
          "error"
        );
        return false;
      }
    }),
    [payroll.id, payCodes, updateAndRefreshPayCodes, setAccountingFieldForPayCode, showMessage, polyglot]
  );
  const updateCompanyPaycode = useCallback(
    (updatedCode, updatedAccountingField) => __async(void 0, null, function* () {
      try {
        if (!payCodes) return false;
        if (!(updatedCode.isSystemCode || updatedCode.isControlCode)) {
          yield PayrollAPI.updateCompanyPaycodes(payroll.id, [updatedCode]);
          const updatedPayCodeList = payCodes.filter((payCode) => payCode.code !== updatedCode.code).concat(updatedCode);
          updateAndRefreshPayCodes == null ? void 0 : updateAndRefreshPayCodes(updatedPayCodeList);
        }
        yield setAccountingFieldForPayCode(updatedCode.code, updatedAccountingField);
        showMessage(polyglot.t("PayrollPaycodeSettings.paycodesUpdated"), "success");
        return true;
      } catch (error) {
        showMessage(
          polyglot.t("PayrollPaycodeSettings.paycodeUpdateFailed", { reason: nestErrorMessage(error) }),
          "error"
        );
        return false;
      }
    }),
    [payCodes, payroll.id, updateAndRefreshPayCodes, setAccountingFieldForPayCode, showMessage, polyglot]
  );
  const deleteCompanyPaycode = useCallback(
    (payCode) => __async(void 0, null, function* () {
      try {
        if (payCode.isSystemCode || payCode.isControlCode) {
          throw new Error(`Cannot delete system/control pay code: ${payCode.code}`);
        }
        const { code: codeToDelete } = payCode;
        yield PayrollAPI.deleteCompanyPaycode(payroll.id, codeToDelete);
        const updatedPayCodeList = payCodes == null ? void 0 : payCodes.filter((payCode2) => payCode2.code !== codeToDelete);
        updateAndRefreshPayCodes == null ? void 0 : updateAndRefreshPayCodes(updatedPayCodeList);
        yield setAccountingFieldForPayCode(codeToDelete, void 0);
        showMessage(polyglot.t("PayrollPaycodeSettings.paycodeDeleted", { paycode: codeToDelete }), "success");
        return true;
      } catch (error) {
        showMessage(
          polyglot.t("PayrollPaycodeSettings.paycodeDeletionFailed", { reason: nestErrorMessage(error) }),
          "error"
        );
        return false;
      }
    }),
    [payroll.id, payCodes, updateAndRefreshPayCodes, setAccountingFieldForPayCode, showMessage, polyglot]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: { mr: spacing.mr20, mt: spacing.mt20 }, children: [
    /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { children: "Pay codes" }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "primary",
          onClick: () => setEditingPayCode("new"),
          style: { width: "min-content" },
          children: polyglot.t("PayrollPaycodeSettings.newCode")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      PayrollSettingsPayCodesTable,
      {
        payCodes: filteredPayCodes,
        onEditPayCodeClick: setEditingPayCode,
        accountingConfig
      }
    ),
    /* @__PURE__ */ jsx(
      PayrollEditPayCodesDrawer,
      {
        payrollId: payroll.id,
        payCode: editingPayCode,
        payCodesNames,
        isOpen: !!editingPayCode,
        setIsOpen: (isOpen) => !isOpen && setEditingPayCode(void 0),
        createCompanyPaycode,
        deleteCompanyPaycode,
        updateCompanyPaycode,
        nominalCodes,
        reimportNominalCodes,
        accountingConfig
      }
    )
  ] });
};
