"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { PayrunButtons } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-buttons.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
const PayrunReviewLine = ({ label, value, sx }) => {
  return /* @__PURE__ */ jsxs(
    Stack,
    {
      flexDirection: "row",
      justifyContent: "space-between",
      sx: { py: spacing.p15, pr: "120px", borderBottom: `1px solid ${themeColors.lightGrey}` },
      children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadValues({ color: themeColors.DarkGrey }, themeFonts.caption), sx), children: label }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadValues({ color: themeColors.DarkGrey }, themeFonts.caption), sx), children: value })
      ]
    }
  );
};
export const PayrunReviewSection = ({
  payrollId,
  payrun,
  payrunEmpAllowance,
  onBack,
  onNext,
  startPayrunProcess,
  sx
}) => {
  const [startingPayrunProcess, setStartingPayrunProcess] = useState(false);
  const [paymentDate, setPaymentDate] = useState(payrun.paymentDate);
  const {
    totalCost = 0,
    takeHomePay = 0,
    tax = 0,
    employerNi = 0,
    employeeNi = 0,
    studentLoanRecovered = 0,
    postgradLoanRecovered = 0,
    employeePensionContribution = 0,
    employerPensionContribution = 0
  } = payrun.totals;
  const totalPension = employeePensionContribution + employerPensionContribution;
  const totalHMRC = tax + employerNi + employeeNi + studentLoanRecovered + postgradLoanRecovered - payrunEmpAllowance;
  const totalEmployerCost = totalCost - payrunEmpAllowance;
  const lines = {
    "Pay to Employees": { value: takeHomePay, style: themeFonts.title4 },
    "Pay to HMRC": { value: totalHMRC, style: themeFonts.title4 },
    "PAYE Tax": { value: tax },
    "Employer NI": { value: employerNi },
    "Employee NI": { value: employeeNi },
    "Student loans": { value: studentLoanRecovered + postgradLoanRecovered },
    "Employment allowance": { value: -payrunEmpAllowance },
    // subtraction
    "Pay to Pension": { value: totalPension, style: themeFonts.title4 },
    "Employer contributions": { value: employerPensionContribution },
    "Employee contributions": { value: employeePensionContribution },
    "Total Cost": { value: totalEmployerCost, style: themeFonts.title4 }
  };
  if (!payrunEmpAllowance) {
    delete lines["Employment allowance"];
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flex: 1, overflowY: "auto" }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Review the total cost of this payrun" }),
    /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.mt20, maxWidth: "600px" }, children: Object.entries(lines).map(([label, { value, style }]) => /* @__PURE__ */ jsx(
      PayrunReviewLine,
      {
        label,
        value: formatCurrency(value) || "",
        sx: __spreadProps(__spreadValues({}, style), { color: themeColors.DarkGrey })
      },
      label
    )) }),
    /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.mt40, gap: spacing.g10, maxWidth: "450px" }, children: payrun.dateClosed ? /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }),
        children: `This payrun was closed on ${new Date(payrun.dateClosed).toLocaleDateString()}`
      }
    ) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Payrun payment date" }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Enter the date on which you intend to pay your employees. This information is submitted to HMRC." }),
      /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          name: "payrun-payment-date",
          label: "Payment date",
          onChange: (value) => {
            setPaymentDate(value);
          },
          value: paymentDate,
          disabled: startingPayrunProcess,
          error: void 0,
          helperText: void 0
        }
      )
    ] }) }),
    /* @__PURE__ */ jsx(
      PayrunButtons,
      {
        primaryButtonLabel: payrun.isClosed ? "View payrun" : "Process payrun",
        showBack: true,
        onBackClick: onBack,
        onPrimaryClick: () => __async(void 0, null, function* () {
          if (payrun.isClosed) {
            onNext == null ? void 0 : onNext();
            return;
          }
          if (!paymentDate) return;
          setStartingPayrunProcess(true);
          const payrunStarted = yield startPayrunProcess(payrollId, payrun, paymentDate);
          setStartingPayrunProcess(false);
          if (!payrunStarted) {
            return;
          }
          onNext == null ? void 0 : onNext();
        }),
        loadingPrimary: startingPayrunProcess,
        disabledPrimary: !paymentDate || startingPayrunProcess,
        disabledBack: startingPayrunProcess,
        sx: { my: spacing.my40 }
      }
    )
  ] });
};
