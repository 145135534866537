"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { LabelValueViewItem } from "@v2/feature/dashboard/features/sections/user-attendance/components/label-value-view-item.component";
import { WidgetInfoMessage } from "@v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { format } from "date-fns";
export const UserShiftHandlerDrawerContent = ({
  clockInEarlyCapMinutes,
  theFollowingScheduledDay,
  isDayStarted,
  isShiftEntryStarted,
  isLastEntryLogged,
  isDayEnded,
  time,
  shiftLoggedHours,
  isStartingShiftEntry,
  isFetchingLocation,
  lessThanEarlyCapMinutesUntilStart,
  startShiftEntry,
  endShiftEntry,
  endDay,
  isEndingShiftEntry,
  isEndingDay,
  isScheduledForToday,
  selectedDate,
  shiftSelectedLoggedHours,
  currentType
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceDomain.Attendance") }),
    isScheduledForToday ? /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g10 }, spacing.mt20), children: [
      /* @__PURE__ */ jsx(
        LabelValueViewItem,
        {
          label: polyglot.t("AttendanceDomain.AttendanceShift.Status"),
          value: /* @__PURE__ */ jsx(
            WidgetInfoMessage,
            {
              isScheduledForToday,
              scheduleDay: theFollowingScheduledDay,
              isStarted: isShiftEntryStarted,
              time,
              isEnded: isDayEnded,
              clockInEarlyCapMinutes
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        LabelValueViewItem,
        {
          label: polyglot.t("AttendanceDomain.AttendanceShift.Date"),
          value: `${format(new LocalDate(selectedDate).getDate(), "d MMM yyyy")}`
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m30, display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("AttendanceDomain.AttendanceShift.YourShift") }),
        currentType && /* @__PURE__ */ jsx(LabelValueViewItem, { label: polyglot.t("General.type"), value: currentType.name }),
        shiftLoggedHours && /* @__PURE__ */ jsx(
          LabelValueViewItem,
          {
            label: polyglot.t("AttendanceDomain.AttendanceShift.Actions.Start"),
            value: shiftLoggedHours[0]
          }
        ),
        shiftLoggedHours && /* @__PURE__ */ jsx(
          LabelValueViewItem,
          {
            label: polyglot.t("AttendanceDomain.AttendanceShift.Actions.End"),
            value: shiftLoggedHours[1]
          }
        ),
        shiftLoggedHours && /* @__PURE__ */ jsx(
          LabelValueViewItem,
          {
            label: polyglot.t("AttendanceDomain.AttendanceShift.totalHours"),
            value: shiftLoggedHours[2]
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadValues({
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "space-between",
            gap: spacing.g20
          }, spacing.mt40),
          children: !isDayEnded && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, width: "100%" }, children: [
            !isShiftEntryStarted && /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("AttendanceDomain.AttendanceShift.Actions.ClockIn"),
                loading: isStartingShiftEntry || isFetchingLocation,
                type: "button",
                fullWidth: true,
                onClick: startShiftEntry,
                disabled: !lessThanEarlyCapMinutesUntilStart,
                colorVariant: "primary",
                sizeVariant: "medium"
              }
            ),
            isDayStarted && isLastEntryLogged && /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("AttendanceDomain.AttendanceShift.Actions.CompleteDay"),
                loading: isEndingDay,
                type: "button",
                fullWidth: true,
                onClick: endDay,
                colorVariant: "danger",
                sizeVariant: "medium"
              }
            ),
            isShiftEntryStarted && /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("AttendanceDomain.AttendanceShift.Actions.Finish"),
                loading: isEndingShiftEntry,
                type: "button",
                fullWidth: true,
                onClick: endShiftEntry,
                colorVariant: "danger",
                sizeVariant: "medium"
              }
            )
          ] })
        }
      )
    ] }) : /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g10 }, spacing.mt20), children: [
      /* @__PURE__ */ jsx(
        LabelValueViewItem,
        {
          label: polyglot.t("AttendanceDomain.AttendanceShift.Status"),
          value: /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { color: themeColors.DarkGrey }, children: "Can not log hours for this day" })
        }
      ),
      /* @__PURE__ */ jsx(
        LabelValueViewItem,
        {
          label: polyglot.t("AttendanceDomain.AttendanceShift.Date"),
          value: `${format(new LocalDate(selectedDate).getDate(), "d MMM yyyy")}`
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m30, display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Your shift" }),
        shiftSelectedLoggedHours && /* @__PURE__ */ jsx(
          LabelValueViewItem,
          {
            label: polyglot.t("AttendanceDomain.AttendanceShift.Actions.Start"),
            value: shiftSelectedLoggedHours[0]
          }
        ),
        shiftSelectedLoggedHours && /* @__PURE__ */ jsx(
          LabelValueViewItem,
          {
            label: polyglot.t("AttendanceDomain.AttendanceShift.Actions.End"),
            value: shiftSelectedLoggedHours[1]
          }
        ),
        shiftSelectedLoggedHours && /* @__PURE__ */ jsx(
          LabelValueViewItem,
          {
            label: polyglot.t("AttendanceDomain.AttendanceShift.totalHours"),
            value: shiftSelectedLoggedHours[2]
          }
        )
      ] })
    ] })
  ] });
};
