"use strict";
import { jsx } from "react/jsx-runtime";
import { DeviceMdmSecurityComponent } from "@v2/feature/device/features/device-cards/device-security-cards.component";
import { DEVICE_COMPANY_DEVICE_ENROLL_ROUTE } from "@/lib/routes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceMdmSecurityPage = ({
  devicePossession,
  fetchDeviceDetails,
  loading,
  activeTransit,
  appliedPolicies,
  appliedZeltPolicies,
  appliedZeltPoliciesForMobile
}) => {
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: "Security",
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading,
      children: /* @__PURE__ */ jsx(
        DeviceMdmSecurityComponent,
        {
          devicePossession,
          activeTransit,
          loading,
          refreshDevice: fetchDeviceDetails,
          enrollPath: DEVICE_COMPANY_DEVICE_ENROLL_ROUTE,
          appliedPolicies,
          appliedZeltPolicies,
          appliedZeltPoliciesForMobile
        }
      )
    }
  );
};
