"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class DocumentAPI {
  static getUrlParams(param) {
    let searchParams;
    if (param) {
      searchParams = new URLSearchParams(__spreadValues(__spreadValues({
        page: param.page.toString(),
        pageSize: param.pageSize.toString(),
        searchQuery: param.searchQuery,
        filter: param.filterString || "",
        statusFilter: param.statusFilter || ""
      }, (param == null ? void 0 : param.order) ? { order: param.order } : {}), (param == null ? void 0 : param.shouldLimitResult) ? { shouldLimitResult: String(param == null ? void 0 : param.shouldLimitResult) } : {}));
    }
    return searchParams;
  }
  static listDocumentsV2(param) {
    return __async(this, null, function* () {
      let searchParams = DocumentAPI.getUrlParams(param);
      const BASE_URL = "/apiv2/documents/all-v2";
      const URL = searchParams ? `${BASE_URL}?${searchParams.toString()}` : BASE_URL;
      return (yield axios.get(URL)).data;
    });
  }
  static listCompanyDocumentsForExport() {
    return __async(this, null, function* () {
      const URL = "/apiv2/documents/company/export";
      return (yield axios.get(URL)).data;
    });
  }
  static listTeamDocuments(param) {
    return __async(this, null, function* () {
      let searchParams = DocumentAPI.getUrlParams(param);
      const BASE_URL = "/apiv2/documents/all/team";
      const URL = searchParams ? `${BASE_URL}?${searchParams.toString()}` : BASE_URL;
      return (yield axios.get(URL)).data;
    });
  }
  // left in listing only
  static downloadViaUuid(fileUuid, originalFilename) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/documents/download-via-uuid", { fileUuid, originalFilename })).data;
    });
  }
  static downloadBlobViaUuid(fileUuid) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/files/id/${fileUuid}`, { responseType: "blob" })).data;
    });
  }
  static bulkDeleteDocuments(documentIds) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/documents/bulk/delete`, { documentIds })).data;
    });
  }
  static bulkEditDocuments(documentIds, updatedDocumentValues) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/documents/bulk/edit`, { documentIds, updatedDocumentValues });
    });
  }
  static downloadViaId(id) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/documents/download-via-id", { id })).data;
    });
  }
  static getDocumentAttachmentByUuid(fileUuid) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/files/uuid/${fileUuid}/attachment`)).data;
    });
  }
  static previewViaUuid(fileUuid) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/documents/preview-via-uuid/${fileUuid}`)).data;
    });
  }
  // left in listing only
  static shareDocumentViaEmail(fileUuid, recipientId, note) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/documents/share-document-via-email`, { fileUuid, recipientId, note });
    });
  }
  // left in listing only
  static deleteDocumentById(documentId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/documents/${documentId}`);
    });
  }
  static getDocumentById(documentId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/documents/${documentId}`)).data;
    });
  }
  static listDocumentTypes() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/documents/types")).data;
    });
  }
  // left in listing only
  static updateDocument(documentId, document) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/documents/${documentId}`, document)).data;
    });
  }
  // left in listing only
  static createDocument(document) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/documents", document)).data;
    });
  }
  // left in listing only
  static listUserDocuments(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/documents/users/${userId}`)).data;
    });
  }
  static uploadDocument(fileData, mapping) {
    return __async(this, null, function* () {
      return yield axios.post(`/apiv2/documents/upload`, {
        fileData,
        mapping
      });
    });
  }
  // left in listing only
  static uploadFileViaURL(documentLink) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/files/file-via-url`, { documentLink })).data;
    });
  }
  // left in listing only
  static getPinnedDocuments(param) {
    return __async(this, null, function* () {
      const URL = `/apiv2/documents/pinned?${new URLSearchParams({
        reach: param.reach
      }).toString()}`;
      return (yield axios.get(URL)).data;
    });
  }
  // left in listing only
  static remindUserForPendingDocument(pendingDocumentName, belongsToUserId) {
    return __async(this, null, function* () {
      return yield axios.post("/apiv2/documents/remind-pending-upload", { pendingDocumentName, belongsToUserId });
    });
  }
}
export class DocumentEndpoints {
  static getDocumentsByCategory(param, showSelectExisting) {
    const URL = `/apiv2/documents/by-category?${new URLSearchParams({
      category: param.category
    }).toString()}`;
    return {
      url: showSelectExisting ? URL : void 0
    };
  }
  static getTypesData() {
    return {
      url: "/apiv2/documents/types"
    };
  }
}
