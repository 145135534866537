"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { StepperHeader } from "@v2/components/stepper-header.component";
import { StepperComponent } from "@v2/components/stepper.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { ChoosePayrollStep } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup-steps/choose-payroll-step.component";
import { ProviderSettingsStep } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup-steps/provider-settings-step.component";
import { SelectPensionProviderStep } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-pension/pension-setup-steps/select-provider-step.component";
import { PayrollAPI } from "@v2/feature/payroll/payroll.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_BENEFITS_ROUTE } from "@/lib/routes";
var PensionSetupSteps = /* @__PURE__ */ ((PensionSetupSteps2) => {
  PensionSetupSteps2["ChoosePayroll"] = "Choose Payroll";
  PensionSetupSteps2["SelectProvider"] = "Select Provider";
  PensionSetupSteps2["ProviderSettings"] = "Provider Settings";
  return PensionSetupSteps2;
})(PensionSetupSteps || {});
export const OTHER_PAYROLL_ENTITY = 999990;
export const PensionSetupPage = ({ name, noOfActivePensionSchemes, refresh }) => {
  var _a;
  const history = useHistory();
  const { polyglot } = usePolyglot();
  const [payrollId, setPayrollId] = useState(0);
  const [payrolls, setPayrolls] = useState([]);
  const [pensionProvider, setPensionProvider] = useState("");
  const [pensionRule, setPensionRule] = useState("");
  const [step, setStep] = useState("Choose Payroll" /* ChoosePayroll */);
  const [showMessage] = useMessage();
  const pensionSetupSteps = useMemo(
    () => ["Choose Payroll" /* ChoosePayroll */, "Select Provider" /* SelectProvider */, "Provider Settings" /* ProviderSettings */],
    []
  );
  const refreshCompanyPayrolls = useCallback(() => __async(void 0, null, function* () {
    try {
      const payrolls2 = yield PayrollAPI.getCompanyPayrolls();
      setPayrolls(payrolls2.items);
    } catch (err) {
      showMessage(
        polyglot.t("PensionModule.ErrorMessages.couldNotGetProviders", { errorMessage: nestErrorMessage(err) }),
        "error"
      );
    }
  }), [showMessage, polyglot]);
  useEffect(() => {
    refreshCompanyPayrolls();
  }, [refreshCompanyPayrolls]);
  const pensionStepsComponent = useMemo(
    () => [
      {
        name: "Choose Payroll" /* ChoosePayroll */,
        component: /* @__PURE__ */ jsx(
          ChoosePayrollStep,
          {
            name,
            onNext: () => setStep("Select Provider" /* SelectProvider */),
            payrollId,
            payrolls,
            setPayrollId,
            refresh
          }
        )
      },
      {
        name: "Select Provider" /* SelectProvider */,
        component: /* @__PURE__ */ jsx(
          SelectPensionProviderStep,
          {
            onNext: () => setStep("Provider Settings" /* ProviderSettings */),
            pensionProvider,
            setPensionProvider,
            setPensionRule
          }
        )
      },
      {
        name: "Provider Settings" /* ProviderSettings */,
        component: /* @__PURE__ */ jsx(
          ProviderSettingsStep,
          {
            displayName: name,
            payrollId,
            pensionProvider,
            pensionRule,
            noOfActivePensionSchemes,
            refresh
          }
        )
      }
    ],
    [name, payrollId, payrolls, pensionProvider, pensionRule, noOfActivePensionSchemes, refresh]
  );
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: false, noHeader: true, border: false, children: /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, mt: spacing.m20 }, children: [
    /* @__PURE__ */ jsx(
      StepperHeader,
      {
        content: /* @__PURE__ */ jsx(StepperComponent, { steps: pensionSetupSteps, activeStep: step, hideConnector: true }),
        contentSx: { width: "80%", textAlign: "center" },
        showStepBack: step !== "Choose Payroll" /* ChoosePayroll */,
        stepBackAction: () => {
          if (pensionSetupSteps.indexOf(step) > 0)
            return setStep(pensionSetupSteps[pensionSetupSteps.indexOf(step) - 1]);
          history.goBack();
        },
        stepBackSx: {
          width: "10%",
          textAlign: "left"
        },
        showBack: true,
        backAction: () => {
          history.push(SETTINGS_BENEFITS_ROUTE);
        },
        backSx: { width: "10%", textAlign: "right" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 } }),
    (_a = pensionStepsComponent[pensionSetupSteps.indexOf(step)]) == null ? void 0 : _a.component
  ] }) });
};
