"use strict";
import {
  SURVEYS_COMPANY_ONGOING_ROUTE,
  SURVEYS_TEAM_ONGOING_ROUTE,
  SURVEYS_ME_ROUTE,
  SURVEYS_ME_INVITE_ROUTE,
  SURVEYS_ME_RESULT_ROUTE
} from "@/lib/routes";
export const GROWTH_SURVEYS_ONGOING_ROUTES = {
  company: SURVEYS_COMPANY_ONGOING_ROUTE,
  team: SURVEYS_TEAM_ONGOING_ROUTE
};
export const GROWTH_SURVEYS_ME_ROUTES = {
  me: SURVEYS_ME_ROUTE
};
export const GROWTH_SURVEYS_INVITE_ROUTES = {
  me: SURVEYS_ME_INVITE_ROUTE
};
export const GROWTH_SURVEYS_INVITE_SCOPES = {
  me: ["surveys"]
};
export const GROWTH_SURVEYS_RESULT_ROUTES = {
  me: SURVEYS_ME_RESULT_ROUTE
};
export const GROWTH_SURVEYS_RESULT_SCOPES = {
  me: ["surveys"]
};
export const GROWTH_SURVEYS_ONGOING_SCOPES = {
  company: ["surveys:all"],
  team: ["surveys:manager"],
  me: ["surveys"]
};
