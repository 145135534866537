"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortNumeric } from "@v2/components/table/table-sorting.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { EditIndividualCheckForm } from "@/v2/feature/id-check/components/edit-individual-check-drawer.page";
import { IdCheckEndpoints } from "@/v2/feature/id-check/id-check.api";
import { RootStyle } from "@/v2/styles/root.styles";
import { formatAsGBPCurrency } from "@/v2/util/string-format.util";
export const IndividualCheckBackofficePage = () => {
  const {
    data: individualChecks,
    isLoading: loadingIndividualChecks,
    mutate: refresh
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });
  const [searchInput, setSearchInput] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const filteredChecks = useMemo(() => {
    if (!individualChecks) return [];
    if (!searchInput) return individualChecks;
    const search = searchInput.toLowerCase();
    return individualChecks.filter((check) => check.id.toLowerCase().includes(search));
  }, [searchInput, individualChecks]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Check Id",
        accessorFn: (row) => row,
        id: "ID",
        enableSorting: true,
        cell: (info) => {
          const individualCheck = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: individualCheck.id }) });
        }
      },
      {
        header: () => "Price",
        accessorFn: (row) => row,
        id: "price",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.price),
        cell: (info) => {
          const idPackage = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: idPackage.price ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: formatAsGBPCurrency(idPackage.price, 2) }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Updated at",
        accessorFn: (row) => row,
        id: "createdBy",
        enableSorting: false,
        cell: (info) => {
          const individualCheck = info.getValue();
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: new Date(individualCheck.updatedAt).toUTCString() }) });
        }
      }
    ];
  }, []);
  const handleRowClick = useCallback((row) => {
    setSelectedCheck(row.original);
    setIsDrawerOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Individual checks for Zinc ID checks" }), showAction: true }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loadingIndividualChecks, sx: {}, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        TableSearch,
        {
          placeholder: "Search",
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          },
          style: { width: "250px", minWidth: "250px" }
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: "10px" }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredChecks,
          columnData,
          loading: loadingIndividualChecks,
          hidePagination: true,
          rowClick: handleRowClick
        }
      ) }),
      selectedCheck && /* @__PURE__ */ jsx(DrawerModal, { isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen, children: /* @__PURE__ */ jsx(
        EditIndividualCheckForm,
        {
          initialValues: selectedCheck,
          onClose: () => {
            setIsDrawerOpen(false);
            refresh();
          }
        }
      ) })
    ] })
  ] });
};
