"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box } from "@mui/material";
import { AbsenceBalancesTable } from "@v2/feature/absence/components/absence-balances-table.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { SETTINGS_TIME_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
export const AbsenceTeamBalancesPage = ({ absencePolicies }) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { userId } = globalState.user;
  const routerHistory = useHistory();
  const hasAbsenceAllScope = checkScopes(globalState.user, ["absence:all"], { userId });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: polyglot.t("getPageConfig.balances"),
        settingsAction: () => routerHistory.push(SETTINGS_TIME_ROUTE),
        settingsHide: !hasAbsenceAllScope
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: __spreadProps(__spreadValues({}, spacing.pt20), { overflow: "hidden" }), children: /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(AbsenceBalancesTable, { absencePolicies, view: "team", stickyHeader: true }) }) })
  ] });
};
