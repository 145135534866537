"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewTemplateEndpoints } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { TemplateBlock } from "@/v2/feature/growth/surveys/features/components/template-block.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const CycleCreationTemplateModal = ({
  isTemplateModalOpen,
  setIsTemplateModalOpen,
  cycleId,
  refresh
}) => {
  const [searchInput, setSearchInput] = useState("");
  const {
    data: templates,
    isLoading: templatesLoading
  } = useApiClient(ReviewTemplateEndpoints.getReviewTemplatesCompanyId(searchInput), { suspense: false });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: isTemplateModalOpen,
      setIsOpen: setIsTemplateModalOpen,
      onClose: () => {
        setIsTemplateModalOpen(false);
      },
      widthPercentage: 95,
      sx: {
        px: 0
      },
      loading: templatesLoading,
      children: /* @__PURE__ */ jsx(
        CycleSetupTemplateContent,
        {
          templates,
          setIsTemplateModalOpen,
          cycleId,
          refresh,
          setSearchInput,
          searchInput
        }
      )
    }
  );
};
const TemplateLibraryHeader = ({
  searchInput,
  setSearchInput
}) => /* @__PURE__ */ jsxs(Box, { sx: { px: spacing.px16, mb: spacing.m24 }, children: [
  /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Template" }),
  /* @__PURE__ */ jsx(
    TableSearch,
    {
      style: { width: "100%", boxSizing: "border-box", marginTop: spacing.m24 },
      query: searchInput,
      handleChange: (e) => setSearchInput(e.target.value)
    }
  )
] });
const CycleSetupTemplateContent = ({
  templates,
  setIsTemplateModalOpen,
  cycleId,
  refresh,
  setSearchInput,
  searchInput
}) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [activeId, setActiveId] = useState(void 0);
  const [isLoading, setIsLoading] = useState(false);
  const cloneTemplate = useCallback(
    (templateId) => __async(void 0, null, function* () {
      if (!templateId) {
        showMessage("Please select a template.", "warning");
        return;
      }
      try {
        setIsLoading(true);
        yield ReviewCycleAPI.cloneTemplateInCycle(templateId, cycleId);
        yield refresh == null ? void 0 : refresh();
        setIsTemplateModalOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setIsLoading(false);
    }),
    [cycleId, refresh, setIsTemplateModalOpen, polyglot, showMessage]
  );
  if (!templates) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        position: "relative",
        width: "100%",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsx(TemplateLibraryHeader, { searchInput, setSearchInput }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              px: spacing.px16,
              boxSizing: "border-box",
              m: -spacing.p8
            },
            children: templates.map((template) => /* @__PURE__ */ jsx(Box, { sx: { width: ["100%", "50%", "25%", "25%"], boxSizing: "border-box", p: spacing.p8 }, children: /* @__PURE__ */ jsx(TemplateBlock, { template, action: () => setActiveId(template.id), active: activeId }) }, template.id))
          }
        ),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              position: "sticky",
              bottom: "24px",
              right: 0,
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "16px",
              boxSizing: "border-box"
            },
            children: /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                colorVariant: "primary",
                sizeVariant: "medium",
                disabled: !activeId,
                onClick: () => __async(void 0, null, function* () {
                  yield cloneTemplate(activeId);
                }),
                loading: isLoading,
                children: "Add template"
              }
            )
          }
        )
      ]
    }
  );
};
