"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from "react";
import { pusherClient } from "@v2/infrastructure/pusher/pusher.hook";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { SURVEYS_COMPANY_ONGOING_ROUTE, SURVEYS_TEAM_ONGOING_ROUTE } from "@/lib/routes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SCRollingLaunchView } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/components/rolling/sc-rolling-launch-view.component";
import { SCScheduledLaunchView } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/components/scheduled/sc-scheduled-launch-view.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ReachType } from "@/v2/interfaces/general.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCUpsertLaunchPage = ({ cycleId, reach }) => {
  const { data: surveyCycleLaunchDetails, mutate: refreshSurveyCycle, isLoading: cycleLaunchLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyLaunchDetailsByCycleId(cycleId),
    {
      suspense: false
    }
  );
  const routerHistory = useHistory();
  const [state] = useContext(GlobalContext);
  const companyId = state.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  useEffect(() => {
    const surveyCycleUpdatedDataHandler = (data) => {
      if (data.message) {
        refreshSurveyCycle == null ? void 0 : refreshSurveyCycle();
        return;
      }
    };
    const standardChannel = pusherClient.subscribe(
      `survey_cycle-${environmentPrefix}-company_id-${companyId}`
    );
    standardChannel.bind(`surveyCycleLaunch`, surveyCycleUpdatedDataHandler);
  }, [companyId, environmentPrefix, refreshSurveyCycle]);
  const goBack = () => {
    const reachRoute = reach === ReachType.Company ? SURVEYS_COMPANY_ONGOING_ROUTE : SURVEYS_TEAM_ONGOING_ROUTE;
    routerHistory.push(reachRoute);
  };
  if (!surveyCycleLaunchDetails) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: surveyCycleLaunchDetails ? surveyCycleLaunchDetails.internalName : "Start survey",
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: cycleLaunchLoading,
      children: [
        surveyCycleLaunchDetails.type === CycleType.Scheduled && /* @__PURE__ */ jsx(SCScheduledLaunchView, { surveyCycleLaunchDetails, cycleId, goBack }),
        surveyCycleLaunchDetails.type === CycleType.Rolling && /* @__PURE__ */ jsx(SCRollingLaunchView, { surveyCycleLaunchDetails, cycleId, goBack })
      ]
    }
  );
};
