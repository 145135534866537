"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { ProfilePicture } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/components/profile-picture.component";
import { spacing } from "@/v2/styles/spacing.styles";
const BasicInfoSchema = (visibility, polyglot) => Yup.object().shape({
  firstName: visibility.firstName ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  lastName: visibility.lastName ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  middleName: Yup.string().nullable().notRequired(),
  displayName: visibility.displayName ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired()
});
export const UserBasicInfoSection = ({ basicInfo, onNext, sx, userId, form }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const autoPopulateDisplayName = React.useMemo(() => !basicInfo.displayName, [basicInfo.displayName]);
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const defaultFieldsVisibility = useMemo(() => {
    var _a2, _b, _c, _d;
    if (!form)
      return {
        firstName: false,
        lastName: false,
        middleName: false,
        displayName: false
      };
    const firstNameField = (_a2 = form.fields) == null ? void 0 : _a2.find((f) => f.isDefault && f.fieldStub === "firstName");
    const lastNameField = (_b = form.fields) == null ? void 0 : _b.find((f) => f.isDefault && f.fieldStub === "lastName");
    const middleNameField = (_c = form.fields) == null ? void 0 : _c.find((f) => f.isDefault && f.fieldStub === "middleName");
    const displayNameField = (_d = form.fields) == null ? void 0 : _d.find((f) => f.isDefault && f.fieldStub === "displayName");
    return {
      firstName: Boolean(firstNameField && !firstNameField.isHidden),
      lastName: Boolean(lastNameField && !lastNameField.isHidden),
      middleName: Boolean(middleNameField && !middleNameField.isHidden),
      displayName: Boolean(displayNameField && !displayNameField.isHidden)
    };
  }, [form]);
  const initialValues = useMemo(() => {
    var _a2, _b, _c, _d;
    const values = {};
    if (defaultFieldsVisibility.firstName) values.firstName = (_a2 = basicInfo.firstName) != null ? _a2 : "";
    if (defaultFieldsVisibility.lastName) values.lastName = basicInfo.lastName;
    if (defaultFieldsVisibility.middleName) values.middleName = (_b = basicInfo.middleName) != null ? _b : "";
    if (defaultFieldsVisibility.displayName) values.displayName = (_c = basicInfo.displayName) != null ? _c : "";
    values.customUpdates = (_d = basicInfo.customUpdates) != null ? _d : [];
    return values;
  }, [defaultFieldsVisibility, basicInfo]);
  const formik = useFormik({
    initialValues,
    validationSchema: BasicInfoSchema(defaultFieldsVisibility, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserBasicInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.basicInfoNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  useEffect(() => {
    if (!autoPopulateDisplayName || !formik.values.firstName || !formik.values.lastName) return;
    const newDisplayName = `${formik.values.firstName.trim()} ${formik.values.lastName.trim()}`;
    if (newDisplayName === formik.values.displayName) return;
    formik.setFieldValue("displayName", `${formik.values.firstName} ${formik.values.lastName}`);
  }, [autoPopulateDisplayName, formik]);
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OnboardingByUser.basicInfo") }),
    /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(Fragment, {}), children: /* @__PURE__ */ jsx(ProfilePicture, { userId, disabled: saving }) }),
    defaultFieldsVisibility.firstName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "firstName",
        label: polyglot.t("OnboardingByUser.firstName"),
        value: formik.values.firstName,
        onChange: formik.handleChange,
        disabled: saving,
        clearText: () => formik.setFieldValue("firstName", ""),
        helperText: hasSubmitted && formik.errors.firstName,
        error: hasSubmitted && !!formik.errors.firstName,
        autoFocus: true
      }
    ),
    defaultFieldsVisibility.lastName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "lastName",
        label: polyglot.t("OnboardingByUser.lastName"),
        value: formik.values.lastName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("lastName", ""),
        helperText: hasSubmitted && formik.errors.lastName,
        error: hasSubmitted && !!formik.errors.lastName
      }
    ),
    defaultFieldsVisibility.middleName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "middleName",
        label: polyglot.t("OnboardingByUser.middleName"),
        value: formik.values.middleName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("middleName", ""),
        helperText: hasSubmitted && formik.errors.middleName,
        error: hasSubmitted && !!formik.errors.middleName
      }
    ),
    defaultFieldsVisibility.displayName && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "displayName",
        label: polyglot.t("OnboardingByUser.displayName"),
        value: formik.values.displayName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("displayName", ""),
        helperText: hasSubmitted && formik.errors.displayName,
        error: hasSubmitted && !!formik.errors.displayName
      }
    ),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: (_a = formik.values.customUpdates) != null ? _a : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true
      }
    )
  ] }) }) });
};
