"use strict";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
function canAddToGlobalPayroll(u, payroll) {
  return !u.inPayroll && // user must have a contract linked to an entity associated with a payroll
  !!u.entityId && payroll.entity.id === u.entityId;
}
function canAddToUKPayroll(u, payroll) {
  var _a;
  return !u.inPayroll && !!u.userPayroll && !hasPayrollMissingFields(u) && !!u.compensationBreakdown && compensationCurrencyIsGBP(u) && // user must have a contract linked to an entity associated with a payroll
  payroll.entity.id === u.entityId && // the pay period of the payroll must match the pay schedule of the user
  ((_a = payroll.schedule) == null ? void 0 : _a.payPeriod) === u.compensationBreakdown.paySchedule;
}
export function canAddToPayroll(u, payroll) {
  if (!payroll.employer) return canAddToGlobalPayroll(u, payroll);
  return canAddToUKPayroll(u, payroll);
}
export function canRemoveFromPayroll(u, payroll) {
  if (!u.inPayroll) return false;
  const finalisedPayrunIds = new Set(payroll.payruns.filter((p) => p.state !== PayrunStates.draft).map(({ id }) => id));
  const userIsInFinalisedPayrun = u.payruns.some(({ payrunId }) => finalisedPayrunIds.has(payrunId));
  if (userIsInFinalisedPayrun) {
    return false;
  }
  return true;
}
export function hasPayrollMissingFields(u) {
  var _a, _b;
  return !!((_b = (_a = u.missingFields) == null ? void 0 : _a.payroll) == null ? void 0 : _b.length);
}
export function compensationCurrencyIsGBP(u) {
  var _a;
  return ((_a = u.compensationBreakdown) == null ? void 0 : _a.currency) === "GBP";
}
