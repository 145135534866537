"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const MondayApiKeyInstructions = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "a) Log into your monday.com account." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "b) Click on your avatar (picture icon) in the bottom left corner." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "c) Select Admin from the resulting menu (this requires you to have admin permissions)." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "d) Go to the API section." }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: [
      "e) Generate a \u201C",
      /* @__PURE__ */ jsx("b", { children: "API v2 Token" }),
      "\u201D"
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mb: spacing.m5 }, children: "e) Copy your token." })
  ] });
};
