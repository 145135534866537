"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ReviewSectionBankAPI {
  static createReviewSectionInBank(sectionBody) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/review-section-bank", sectionBody)).data;
    });
  }
  static updateReviewSectionInBank(sectionBody) {
    return __async(this, null, function* () {
      yield axios.patch("/apiv2/review-section-bank", sectionBody);
    });
  }
  static deleteSectionById(templateId, sectionId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/review-section-bank/${templateId}/${sectionId}`);
    });
  }
}
