"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button, IconButton, Link, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { SETTINGS_DOCUMENTS_TYPES_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditDocuments = (props) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const { onSave, allDocumentTypes } = props;
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState((_a = props.documentTypes) != null ? _a : []);
  const [otherDocumentError, setOtherDocumentError] = useState(false);
  const [otherCount, setOtherCount] = useState(
    (_c = (_b = props.otherDocumentNames) == null ? void 0 : _b.map((o, idx) => {
      return { label: o, id: idx };
    })) != null ? _c : void 0
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: polyglot.t("EditDocuments.select") }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.mt10 }), children: [
      /* @__PURE__ */ jsx("br", {}),
      polyglot.t("EditDocuments.chooseDocument2"),
      " ",
      /* @__PURE__ */ jsx(Link, { target: "_blank", rel: "noreferrer", href: SETTINGS_DOCUMENTS_TYPES_ROUTE, children: polyglot.t("EditDocuments.here") })
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.mt30, gap: spacing.g20 }, children: allDocumentTypes.map((eachDocumentType) => {
      return /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: eachDocumentType.label,
          checked: selectedDocumentTypes.includes(eachDocumentType.value),
          onChange: (_, checked) => {
            setSelectedDocumentTypes(
              checked ? [...selectedDocumentTypes, eachDocumentType.value] : selectedDocumentTypes.filter((type) => type !== eachDocumentType.value)
            );
            if (eachDocumentType.value === "other") setOtherCount([{ label: void 0, id: 0 }]);
          }
        },
        eachDocumentType.value
      );
    }) }),
    selectedDocumentTypes.includes("other") && /* @__PURE__ */ jsxs(Fragment, { children: [
      otherCount == null ? void 0 : otherCount.map((o) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "flex-end", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            autoFocus: true,
            type: "text",
            label: polyglot.t("EditDocuments.add"),
            value: o.label,
            onChange: (e) => {
              const index = otherCount.indexOf(o);
              otherCount[index].label = e.target.value;
              setOtherCount([...otherCount]);
              setOtherDocumentError(false);
            },
            sx: { mt: spacing.mt20 },
            helperText: otherDocumentError && !o.label && polyglot.t("EditDocuments.create"),
            error: otherDocumentError && !o.label
          },
          o.id
        ),
        otherCount.indexOf(o) !== 0 && /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            onClick: () => {
              setOtherCount(otherCount.filter((record) => record.id !== o.id));
            },
            children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
          }
        )
      ] })),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", alignItems: "center", justifyContent: "space-between" }, spacing.mt20), children: /* @__PURE__ */ jsxs(
        Button,
        {
          sx: secondaryCTABtn,
          onClick: () => {
            if (otherCount) setOtherCount([...otherCount, { label: void 0, id: (otherCount == null ? void 0 : otherCount.length) + 1 }]);
            else setOtherCount([{ label: void 0, id: 0 }]);
          },
          children: [
            /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
            " \xA0",
            polyglot.t("EditDocuments.addOther")
          ]
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        type: "button",
        onClick: () => __async(void 0, null, function* () {
          var _a2;
          const hasOther = selectedDocumentTypes.includes("other");
          const otherCountUndefined = otherCount == null ? void 0 : otherCount.filter((o) => !o.label);
          if (hasOther && otherCountUndefined && (otherCountUndefined == null ? void 0 : otherCountUndefined.length) > 0) {
            setOtherDocumentError(true);
            return;
          }
          setOtherDocumentError(false);
          const otherDocumentName = (_a2 = otherCount == null ? void 0 : otherCount.map((o) => o.label).filter(Boolean)) != null ? _a2 : [];
          onSave(selectedDocumentTypes, otherDocumentName);
        }),
        fullWidth: true,
        colorVariant: "primary",
        sizeVariant: "large",
        style: { marginTop: "40px" },
        children: polyglot.t("General.save")
      }
    )
  ] });
};
