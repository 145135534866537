"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { MdmMigratorAPI } from "@v2/feature/user/features/mdm-migration/mdm-migrator.api";
import useMessage from "@/hooks/notification.hook";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminMdmMigratorPage = () => {
  const [companyId, setCompanyId] = useState();
  const [devices, setDevices] = useState();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  function isValidCSV(input) {
    if (!input) {
      showMessage("DeviceIds cannot be empty", "error");
      return false;
    }
    const csvRegex = /^\d+(,\d+)*$/;
    let result = csvRegex.test(input);
    if (!result) {
      showMessage("DeviceIds have to be number separated by commas", "error");
      return false;
    }
    return true;
  }
  function isValidCompanyId(companyId2) {
    if (isNaN(Number(companyId2))) {
      showMessage("CompanyId has to be number", "error");
      return false;
    }
    return true;
  }
  const triggerMdmImportImport = () => __async(void 0, null, function* () {
    setLoading(true);
    try {
      if (isValidCompanyId(companyId) && isValidCSV(devices)) {
        showMessage("Successfully triggered MDM import", "success");
        let deviceIds = devices == null ? void 0 : devices.split(",").map((num) => Number(num));
        if (!deviceIds) {
          deviceIds = [];
        }
        const mdmMigrationDto = { companyId: Number(companyId), deviceIds };
        yield MdmMigratorAPI.startMigration(mdmMigrationDto);
      }
    } catch (error) {
      showMessage(`Encountered an error while trying to start MDM migration: ${JSON.stringify(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const syncMobileDevices = () => __async(void 0, null, function* () {
    setLoading(true);
    try {
      yield MdmMigratorAPI.startMigrationForMobileDevices();
    } catch (error) {
      showMessage(
        `Encountered an error while trying to start MDM migration fro mobile devices: ${JSON.stringify(error)}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  const syncAllDevicesPolicies = () => __async(void 0, null, function* () {
    setLoading(true);
    try {
      showMessage("Successfully triggered MDM devices policies", "success");
      yield MdmMigratorAPI.startPoliciesSyncForAllDevices();
    } catch (error) {
      showMessage(`Encountered an error while trying to sync policies: ${JSON.stringify(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Start MDM migration process" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { children: /* @__PURE__ */ jsxs(Stack, { sx: { width: "500px", gap: "20px" }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Company ID for migration",
          name: "companyId",
          defaultValue: "Input company ID",
          value: companyId,
          onChange: (e) => {
            setCompanyId(e.target.value);
          },
          clearText: () => setCompanyId(""),
          disabled: loading,
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Device IDs for migration",
          helperText: "Device ids separated by commas",
          multiline: true,
          value: devices,
          onChange: (e) => {
            setDevices(e.target.value);
          },
          clearText: () => setDevices(""),
          disabled: loading
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Start migration",
          loading,
          onClick: () => triggerMdmImportImport(),
          disabled: !companyId || loading,
          sizeVariant: "large",
          colorVariant: "primary"
        }
      ),
      /* @__PURE__ */ jsx(Divider, {}),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Sync policies for all devices" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Sync policies",
          loading,
          onClick: () => syncAllDevicesPolicies(),
          disabled: loading,
          sizeVariant: "large",
          colorVariant: "primary"
        }
      ),
      /* @__PURE__ */ jsx(Divider, {}),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Sync mobile devices" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Sync mobile devices",
          loading,
          onClick: () => syncMobileDevices(),
          disabled: loading,
          sizeVariant: "large",
          colorVariant: "primary"
        }
      )
    ] }) })
  ] });
};
