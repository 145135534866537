"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { SuperAdminRefinancingDevicesPage } from "@v2/feature/super-admin/features/super-admin-refinancing/pages/super-admin-refinancing-devices.page";
import { Redirect, Route, Switch } from "react-router-dom";
import { SUPER_ADMIN_REFINANCING_DEVICES_ROUTE } from "@/lib/routes";
export const SuperAdminRefinancingRouter = ({ companies }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_REFINANCING_DEVICES_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminRefinancingDevicesPage, { companies }) }),
    /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_REFINANCING_DEVICES_ROUTE })
  ] });
};
