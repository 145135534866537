"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { keyBy } from "lodash";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { JobLevelEndpoints } from "@/v2/feature/job-level/job-level.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { tableSecondaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const JobPositionSettingTable = ({
  jobPositions,
  loading,
  onEditClick
}) => {
  const { data: jobLevels } = useApiClient(JobLevelEndpoints.listJobLevel(), { suspense: false });
  const [tableData, setTableData] = useState([]);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [searchInput, setSearchInput] = useState("");
  const jobsLookup = useMemo(() => {
    const groupMap = /* @__PURE__ */ new Map();
    jobLevels == null ? void 0 : jobLevels.forEach((item) => {
      if (!groupMap.has(item.trackName)) {
        groupMap.set(item.trackName, { counter: 1, options: [] });
      }
      const group = groupMap.get(item.trackName);
      group.options.push({
        value: item.levelId,
        label: `${item.trackName.split(" ").map((word) => word[0]).join("")}
       ${group.counter}`
      });
      group.counter += 1;
    });
    return keyBy(
      Array.from(groupMap.values()).flatMap((group) => group.options),
      "value"
    );
  }, [jobLevels]);
  useEffect(() => {
    const filteredData = [...jobPositions].filter((n) => {
      var _a, _b;
      const s = searchInput.toLowerCase();
      return ((_a = n.title) == null ? void 0 : _a.toLowerCase().includes(s)) || ((_b = n.description) == null ? void 0 : _b.toLowerCase().includes(s)) || n.internalCode.toLowerCase().includes(s);
    }).sort((a, b) => a.title.localeCompare(b.title));
    setTableData(filteredData);
  }, [searchInput, nonTerminatedCachedUsers, jobPositions]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Internal code",
        accessorFn: (row) => row,
        id: "id",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        maxSize: 50,
        minSize: 50,
        cell: (info) => {
          return /* @__PURE__ */ jsx("div", { children: info.getValue().internalCode });
        }
      },
      {
        header: () => "Level code",
        accessorFn: (row) => row,
        id: "levelId",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.levelId),
        maxSize: 50,
        minSize: 50,
        cell: (info) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: info.getValue().levelId ? (_a = jobsLookup[info.getValue().levelId]) == null ? void 0 : _a.label : "-" });
        }
      },
      {
        header: () => "Job title",
        accessorFn: (row) => row,
        id: "title",
        maxSize: 100,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.title),
        cell: (info) => {
          var _a;
          return /* @__PURE__ */ jsx(Fragment, { children: (_a = info.getValue()) == null ? void 0 : _a.title });
        }
      },
      {
        header: () => "Description",
        accessorFn: (row) => row,
        id: "description",
        maxSize: 120,
        minSize: 120,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.description),
        cell: (info) => {
          var _a, _b, _c;
          return /* @__PURE__ */ jsx(Fragment, { children: ((_a = info.getValue()) == null ? void 0 : _a.description) ? truncateWithEllipses((_c = (_b = info.getValue()) == null ? void 0 : _b.description) != null ? _c : "", 50) : "\u2013" });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        maxSize: 10,
        minSize: 10,
        cell: (info) => {
          return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableSecondaryIconButtonSx,
              onClick: (e) => {
                onEditClick(info.getValue());
                e.stopPropagation();
              },
              children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
            }
          ) });
        }
      }
    ];
  }, [jobsLookup, onEditClick]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => {
          setSearchInput(e.target.value);
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(BasicTable, { rowData: [...tableData], columnData, loading }) })
  ] });
};
