"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { Children, Fragment as Fragment2 } from "react";
export function flattenChildren(children) {
  const childrenArray = Children.toArray(children);
  return childrenArray.reduce((flatChildren, child) => {
    if (child.type === Fragment2) {
      return flatChildren.concat(flattenChildren(child.props.children));
    }
    flatChildren.push(child);
    return flatChildren;
  }, []);
}
export const NestedRouter = ({ children }) => /* @__PURE__ */ jsx(Fragment, { children: flattenChildren(children) });
