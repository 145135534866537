"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { AppDetailsAPI } from "@/v2/feature/app-integration/features/app-details/app-details.api";
export const DisableSSOConfirmDrawer = ({ isOpen, setIsOpen, ssoMetadata, companyId, afterAction }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(
      DisableSSOConfirmDrawerContent,
      {
        ssoMetadata,
        companyId,
        setIsOpen,
        afterAction
      }
    )
  }
) });
export const DisableSSOConfirmDrawerContent = ({
  ssoMetadata,
  companyId,
  setIsOpen,
  afterAction
}) => {
  const [showMessage] = useMessage();
  const [isDelete, setIsDelete] = useState(false);
  const disableSSOForCompany = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsDelete(true);
      if (!ssoMetadata || ssoMetadata.length === 0) return;
      const firstElementOfSSOState = ssoMetadata[0];
      const { appStub } = firstElementOfSSOState;
      let result = false;
      switch (appStub) {
        case "sso-google-workspace":
          yield AppDetailsAPI.superadminDisableSSOGW(companyId);
          result = true;
          break;
        case "sso-azure-ad":
          yield AppDetailsAPI.superAdminDisableSSOAzureAdSaml(companyId);
          result = true;
          break;
        case "sso-okta":
          yield AppDetailsAPI.superAdminDisableSSOOkta(companyId);
          result = true;
          break;
        default:
          console.warn("SSO App not configured - cannot disable ", ssoMetadata);
          result = false;
          break;
      }
      if (result) {
        showMessage(`SSO successfully disabled for company Id: ${companyId}`, "success");
      }
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)})`, "error");
    } finally {
      setIsDelete(false);
      afterAction();
    }
  }), [afterAction, companyId, showMessage, ssoMetadata]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Disable SSO" }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      "Are you sure you want to disable SSO for companyId: ",
      companyId,
      "?"
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: "Cancel" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Disable",
          sizeVariant: "medium",
          colorVariant: "danger",
          loading: isDelete,
          onClick: disableSSOForCompany,
          fullWidth: true
        }
      )
    ] })
  ] });
};
