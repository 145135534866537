"use strict";
import { NO_VAT_VALUE } from "@v2/feature/payments/expenses.util";
export var YapilyInstitutions = /* @__PURE__ */ ((YapilyInstitutions2) => {
  YapilyInstitutions2["BarclaysBusiness"] = "barclays_business";
  YapilyInstitutions2["BarclaysCorporate"] = "barclays_corporate";
  YapilyInstitutions2["LloydsBusiness"] = "lloydsbusiness";
  YapilyInstitutions2["Natwest"] = "natwest";
  YapilyInstitutions2["NatwestBusiness"] = "natwestbusiness";
  YapilyInstitutions2["Revolut"] = "revolut";
  YapilyInstitutions2["SantanderUk"] = "santander_uk";
  YapilyInstitutions2["Starling"] = "starling";
  YapilyInstitutions2["AIBROIBusiness"] = "aibbusiness";
  YapilyInstitutions2["HSBCBusiness"] = "hsbcbusiness_uk";
  YapilyInstitutions2["HSBCNet"] = "hsbccorporate";
  YapilyInstitutions2["RBS"] = "rbs";
  YapilyInstitutions2["RBSBusiness"] = "rbsbusiness";
  YapilyInstitutions2["SiliconValleyBank"] = "siliconvalleybank";
  YapilyInstitutions2["Monzo"] = "monzo_ob";
  YapilyInstitutions2["Tide"] = "tide";
  YapilyInstitutions2["Wise"] = "wise-live";
  YapilyInstitutions2["OtherBanks"] = "other-banks";
  YapilyInstitutions2["ModeloSandbox"] = "modelo-sandbox";
  YapilyInstitutions2["AibGBSandbox"] = "aibgb-sandbox";
  YapilyInstitutions2["BankOfScotlandSandbox"] = "bankofscotland-sandbox";
  YapilyInstitutions2["BarclaysSandbox"] = "barclays-sandbox";
  YapilyInstitutions2["LloydsSandbox"] = "lloyds-sandbox";
  YapilyInstitutions2["NatwestSandbox"] = "natwest-sandbox";
  return YapilyInstitutions2;
})(YapilyInstitutions || {});
export var PayerType = /* @__PURE__ */ ((PayerType2) => {
  PayerType2["SinglePayer"] = "singlePayer";
  PayerType2["PayerInEachPayment"] = "payerInEachPayment";
  return PayerType2;
})(PayerType || {});
export var ContractorInvoiceStatus = /* @__PURE__ */ ((ContractorInvoiceStatus2) => {
  ContractorInvoiceStatus2["Pending"] = "Pending";
  ContractorInvoiceStatus2["Approved"] = "Approved";
  ContractorInvoiceStatus2["Rejected"] = "Rejected";
  ContractorInvoiceStatus2["Voided"] = "Voided";
  ContractorInvoiceStatus2["Draft"] = "Draft";
  return ContractorInvoiceStatus2;
})(ContractorInvoiceStatus || {});
export var EntryTypeForPaymentTable = /* @__PURE__ */ ((EntryTypeForPaymentTable2) => {
  EntryTypeForPaymentTable2["EXPENSE"] = "Expense";
  EntryTypeForPaymentTable2["INVOICE"] = "Invoice";
  EntryTypeForPaymentTable2["PAYMENT"] = "Payment";
  return EntryTypeForPaymentTable2;
})(EntryTypeForPaymentTable || {});
export const DEFAULT_CURRENCY = "GBP";
export var ExpenseStatus = /* @__PURE__ */ ((ExpenseStatus2) => {
  ExpenseStatus2["Pending"] = "Pending";
  ExpenseStatus2["Approved"] = "Approved";
  ExpenseStatus2["Rejected"] = "Rejected";
  ExpenseStatus2["Voided"] = "Voided";
  ExpenseStatus2["Draft"] = "Draft";
  ExpenseStatus2["All"] = "All";
  return ExpenseStatus2;
})(ExpenseStatus || {});
export const NO_VAT_OPTION = "No VAT";
export const REVERSE_CHARGE_OPTION = "Reverse charge";
export const TaxRateOptions = [
  {
    label: NO_VAT_OPTION,
    value: NO_VAT_VALUE
    // dummy value to default to no vat / null
  },
  {
    label: "20% (VAT on Expenses)",
    value: 20
  },
  {
    label: "5% (VAT on Expenses)",
    value: 5
  },
  {
    label: "Reverse Charge Expenses (20%)",
    value: 0
  }
];
