"use strict";
import { jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { CompanyEndpoints } from "@/api-client/company.api";
import {
  SETTINGS_DEPARTMENTS_ROUTE,
  SETTINGS_ENTITIES_ROUTE,
  SETTINGS_JOB_LEVELS_ROUTE,
  SETTINGS_JOB_POSITIONS_ROUTE,
  SETTINGS_SITES_ROUTE
} from "@/lib/routes";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const OrgStructureSettingPage = () => {
  const { data: orgCounts, isValidating: loadingOrgCounts } = useApiClient(CompanyEndpoints.getOrgStructureCounts(), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const handleCardClick = useCallback(
    (itemName) => {
      if (itemName.includes("Department")) routerHistory.push(generatePath(SETTINGS_DEPARTMENTS_ROUTE));
      else if (itemName.includes("Sites")) routerHistory.push(generatePath(SETTINGS_SITES_ROUTE));
      else if (itemName.includes("positions")) routerHistory.push(generatePath(SETTINGS_JOB_POSITIONS_ROUTE));
      else if (itemName.includes("levels")) routerHistory.push(generatePath(SETTINGS_JOB_LEVELS_ROUTE));
      else if (itemName.includes("Entities")) routerHistory.push(generatePath(SETTINGS_ENTITIES_ROUTE));
    },
    [routerHistory]
  );
  const tableData = useMemo(() => {
    const dataForTable = [];
    if (!orgCounts) return dataForTable;
    if (orgCounts.entityCount) {
      dataForTable.push({ name: "Entities", count: orgCounts.entityCount });
    }
    if (orgCounts.departmentCount) {
      dataForTable.push({ name: "Departments", count: orgCounts.departmentCount });
    }
    if (orgCounts.siteCount) {
      dataForTable.push({ name: "Sites", count: orgCounts.siteCount });
    }
    if (orgCounts.jobPositionCount) {
      dataForTable.push({ name: "Job positions", count: orgCounts.jobPositionCount });
    }
    if (orgCounts.jobLevelCount) {
      dataForTable.push({ name: "Job levels", count: orgCounts.jobLevelCount });
    }
    return dataForTable;
  }, [orgCounts]);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: loadingOrgCounts, children: /* @__PURE__ */ jsx(SettingsSectionContent, { title: polyglot.t("SettingsRouterPageConfig.orgStructure"), contentWidth: "100%", children: /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          items: [
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: tableData.map((item) => /* @__PURE__ */ jsx(
                SettingsItemCard,
                {
                  title: item.name,
                  boxSx: responsiveCardStyle,
                  contentItemsSets: [
                    {
                      name: `${item == null ? void 0 : item.count} ${item.name.toLowerCase()}`,
                      type: ContentItemType.chip,
                      textColor: "DarkGrey",
                      backgroundColor: "white",
                      border: "middle"
                    }
                  ],
                  boxAction: () => handleCardClick(item.name)
                },
                item.name
              )) })
            }
          ],
          contentWidth: "100%"
        }
      ]
    }
  ) }) });
};
