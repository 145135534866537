"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const DepartmentListingTable = ({
  departments,
  loading,
  setIsViewOpen,
  setIsFormOpen,
  setSelectedDepartment
}) => {
  const { polyglot } = usePolyglot();
  const tableColumns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("DepartmentListingTable.departmentNameLabel"),
        accessorFn: (row) => row,
        id: "displayName",
        maxSize: 200,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => {
          return original && /* @__PURE__ */ jsx("div", { children: original.name });
        }
      },
      {
        header: () => polyglot.t("DepartmentListingTable.employees"),
        accessorFn: (row) => row,
        id: "employees",
        maxSize: 350,
        minSize: 100,
        enableSorting: false,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return Number((_a = item.users) == null ? void 0 : _a.length);
        }),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.users ? /* @__PURE__ */ jsx(Fragment, { children: original.users.length }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", width: "100%", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: "edit",
            onClick: (event) => {
              setSelectedDepartment(original);
              setIsFormOpen(true);
              event.stopPropagation();
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ];
  }, [setSelectedDepartment, setIsFormOpen, polyglot]);
  const handleRowClick = useCallback(
    (row) => {
      setSelectedDepartment(row.original);
      setIsViewOpen(true);
    },
    [setSelectedDepartment, setIsViewOpen]
  );
  return /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: departments,
      columnData: tableColumns,
      loading,
      initialSort: [{ id: "displayName", desc: true }],
      rowClick: handleRowClick
    }
  );
};
