"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltPolicyPayloadIdentifier } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const ZeltMdmWifiPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const getPolicy = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    return {
      AutoJoin: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableWifi) == null ? void 0 : _a.AutoJoin) != null ? _b : false,
      EncryptionType: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableWifi) == null ? void 0 : _c.EncryptionType) != null ? _d : "None",
      HIDDEN_NETWORK: (_f = (_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableWifi) == null ? void 0 : _e.HIDDEN_NETWORK) != null ? _f : false,
      SSID_STR: (_h = (_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableWifi) == null ? void 0 : _g.SSID_STR) != null ? _h : "",
      Password: (_j = (_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableWifi) == null ? void 0 : _i.Password) != null ? _j : "",
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.WIFI_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const encryptionTypes = [
    { label: "Not set", value: "None" },
    { label: "WEP", value: "WEP" },
    { label: "WPA", value: "WPA" },
    { label: "WPA2", value: "WPA2" },
    { label: "WPA3", value: "WPA3" }
  ];
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(policy) {
    if (!policy.AutoJoin && !policy.HIDDEN_NETWORK && policy.EncryptionType === "None" && policy.SSID_STR === "" && policy.Password === "") {
      return void 0;
    }
    return {
      AutoJoin: policy.AutoJoin,
      EncryptionType: policy.EncryptionType,
      HIDDEN_NETWORK: policy.HIDDEN_NETWORK,
      SSID_STR: policy.SSID_STR,
      Password: policy.Password,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.WIFI_PAYLOAD_IDENTIFIER.valueOf()
    };
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableWifi: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "WiFi" }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20, mt: "20px" }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Network name - SSID",
            value: formik.values.SSID_STR,
            type: "text",
            onChange: (e) => {
              const updatedValue = e.currentTarget.value;
              formik.setFieldValue("SSID_STR", updatedValue);
            },
            clearText: () => formik.setFieldValue("SSID_STR", "")
          }
        ),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: "Automatically join the network",
            name: "AutoJoin",
            checked: formik.values.AutoJoin,
            onChange: (e, checked) => {
              formik.setFieldValue("AutoJoin", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "EncryptionType",
            label: "Security versions",
            options: encryptionTypes,
            value: formik.values.EncryptionType,
            onChange: (e) => {
              const updatedValue = e.target.value;
              formik.setFieldValue("EncryptionType", updatedValue);
              if (updatedValue === "None") {
                formik.setFieldValue("Password", "");
              }
            }
          }
        )
      ] }),
      formik.values.EncryptionType !== "None" && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", marginTop: "10px" }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Password",
          value: formik.values.Password,
          type: "password",
          onChange: (e) => {
            const updatedValue = e.currentTarget.value;
            formik.setFieldValue("Password", updatedValue);
          },
          endAdornment: "none",
          clearText: () => formik.setFieldValue("Password", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => formik.handleSubmit(), fullWidth: true, colorVariant: "primary", sizeVariant: "medium", children: "Save" }) })
    ] })
  ] });
};
