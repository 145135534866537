"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { CustomSectionForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserCustomSection = ({ onNext, sx, userId, fields, form }) => {
  return /* @__PURE__ */ jsx(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: /* @__PURE__ */ jsx(
    CustomSectionForm,
    {
      userId,
      form,
      fields: [...fields],
      onSubmit: (values) => {
        onNext(values);
      },
      usedForOnboarding: true
    }
  ) });
};
