"use strict";
export const extractFilters = (filterString) => {
  const filters = filterString.split("&");
  if (!filters.length) return null;
  let finalFilter = { userStatuses: [], accountTypes: [] };
  const STATUS_FILTER = "Status=";
  const ROLE_FILTER = "Role=";
  for (const filter of filters) {
    if (filter.startsWith(STATUS_FILTER)) {
      finalFilter.userStatuses = filter.split(STATUS_FILTER)[1].split(",");
    }
    if (filter.startsWith(ROLE_FILTER)) {
      finalFilter.accountTypes = filter.split(ROLE_FILTER)[1].split(",");
    }
  }
  return finalFilter;
};
