"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { themeColors } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
export const SettingsPageSection = ({ children, title, onEdit, editSection, width = "600px" }) => {
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width, justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: title }),
          onEdit && /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: onEdit, children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) }, "edit"),
          editSection != null ? editSection : /* @__PURE__ */ jsx(Fragment, {})
        ] })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { sx: { scrollbarWidth: "none" }, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", justifyContent: "left" }, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, width: "600px" }, children }) }) })
  ] });
};
export const DescriptionLine = ({ field, value }) => {
  const renderValue = () => {
    if (!value) return /* @__PURE__ */ jsx(EmptyCell, {});
    if (typeof value === "object" && "isLink" in value) {
      const { label, icon, onClick } = value;
      return /* @__PURE__ */ jsxs(
        Box,
        {
          component: "span",
          onClick,
          sx: {
            cursor: "pointer",
            textDecoration: "underline",
            display: "flex",
            alignItems: "center",
            gap: 1
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: label }),
            icon
          ]
        }
      );
    }
    if (["string", "number"].includes(typeof value)) {
      return /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value.toString() });
    }
    return value;
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(Box, { sx: { width: "200px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: field }) }),
    /* @__PURE__ */ jsx(Box, { sx: { width: "390px" }, children: renderValue() })
  ] });
};
