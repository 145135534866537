"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Edit } from "@/images/fields/Edit.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { getSelectProps, StyledMenuItem } from "@/v2/styles/menu.styles";
import { StyledTextfield } from "@/v2/styles/textfield.styles";
const iconSize = { width: "14px", height: "14px" };
export const SelectComponent = forwardRef(
  (_a, ref) => {
    var _b = _a, {
      fieldBackgroundColor = "Background",
      fieldBackgroundHoverColor = "GreyHover",
      valueFont = "title4",
      fieldPadding = "10px",
      borderRadius = "10px"
    } = _b, props = __objRest(_b, [
      "fieldBackgroundColor",
      "fieldBackgroundHoverColor",
      "valueFont",
      "fieldPadding",
      "borderRadius"
    ]);
    const _a2 = props, { options, footer, label, editList, placeholder, placeholderOverride } = _a2, textFieldProps = __objRest(_a2, ["options", "footer", "label", "editList", "placeholder", "placeholderOverride"]);
    const selectedValue = props.value;
    const selectProps = textFieldProps.SelectProps ? textFieldProps.SelectProps : getSelectProps;
    const getLabelText = (labelValue) => {
      if (!textFieldProps.maxLabelDisplayCharacters) return labelValue;
      if (typeof labelValue !== "string") return labelValue;
      if (labelValue.length < textFieldProps.maxLabelDisplayCharacters) return labelValue;
      return labelValue.substring(0, textFieldProps.maxLabelDisplayCharacters) + "...";
    };
    return /* @__PURE__ */ jsxs(
      StyledTextfield,
      __spreadProps(__spreadValues({}, textFieldProps), {
        select: true,
        label,
        inputRef: ref,
        variant: "standard",
        size: "small",
        InputLabelProps: { shrink: true },
        fieldBackgroundColor,
        fieldBackgroundHoverColor,
        valueFont,
        fieldPadding,
        borderRadius,
        fullWidth: true,
        SelectProps: __spreadProps(__spreadValues({}, selectProps), {
          displayEmpty: true,
          renderValue: (value) => {
            if (placeholderOverride) {
              return placeholderOverride;
            }
            const option = options.find((opt) => opt.value === value);
            const label2 = getLabelText(option ? option.label : placeholder);
            return !option ? /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: placeholder && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: label2 }) }) : /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
              /* @__PURE__ */ jsx(Fragment, { children: option.icon }),
              /* @__PURE__ */ jsx(Typography, { variant: valueFont, children: label2 })
            ] });
          }
        }),
        children: [
          options.map(({ value, label: label2, icon, description, disabled }) => {
            return /* @__PURE__ */ jsx(StyledMenuItem, { value, disabled, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "100%" }, children: [
              /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
                /* @__PURE__ */ jsx(Fragment, { children: icon }),
                /* @__PURE__ */ jsx(Typography, { variant: value === selectedValue ? valueFont : "caption", children: label2 }),
                value === selectedValue && /* @__PURE__ */ jsx(ChoseIcon, __spreadProps(__spreadValues({}, iconSize), { style: { marginLeft: "auto" } }))
              ] }),
              description && /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "caption",
                  color: "Grey",
                  sx: { maxWidth: "350px", whiteSpace: "pre-wrap", mt: spacing.m5 },
                  children: description
                }
              )
            ] }) }, value);
          }),
          footer && /* @__PURE__ */ jsx(StyledMenuItem, { disabled: true, sx: { borderTop: "1px solid #ddd" }, children: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              sx: {
                maxWidth: "350px",
                whiteSpace: "pre-wrap",
                mt: spacing.m5
              },
              children: footer
            }
          ) }),
          editList && !editList.isHidden && /* @__PURE__ */ jsx(
            StyledMenuItem,
            {
              sx: {
                borderTop: `1px solid ${themeColors.Background}`,
                position: "sticky",
                zIndex: 3,
                bottom: 0,
                left: 0,
                background: themeColors.white
              },
              onClick: () => {
                var _a3;
                return (_a3 = editList.handler) == null ? void 0 : _a3.call(editList);
              },
              children: /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  },
                  children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
                    /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
                    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Edit list" })
                  ] })
                }
              )
            }
          )
        ]
      })
    );
  }
);
