"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Slide, Stack } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { AuthFooter } from "@/v2/feature/auth/components/auth-footer.component";
import { AuthLoginButton } from "@/v2/feature/auth/components/auth-login-button.component";
import { AuthThemeBg } from "@/v2/feature/auth/components/auth-theme-bg.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AuthLayout = ({ title, description, showLoginButton, children, submit, onKeyDown }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
        height: "100%",
        backgroundColor: themeColors.Background,
        overflow: "hidden",
        width: "100%"
      },
      children: [
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              width: { xs: "100%", sm: "55%", md: "65%", lg: "70%" },
              height: { xs: "50%", sm: "100%", md: "100%", lg: "100%" }
            },
            children: /* @__PURE__ */ jsx(AuthThemeBg, {})
          }
        ),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              width: { xs: "100%", sm: "45%", md: "35%", lg: "30%" },
              height: { xs: "50%", sm: "100%", md: "100%", lg: "100%" }
            },
            children: /* @__PURE__ */ jsx(Slide, { direction: window.innerWidth > 600 ? "left" : "up", in: true, mountOnEnter: true, timeout: { enter: 600 }, children: /* @__PURE__ */ jsxs(
              Box,
              {
                sx: __spreadProps(__spreadValues({
                  boxSizing: "border-box",
                  backgroundColor: themeColors.white
                }, spacing.pad40), {
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between"
                }),
                children: [
                  /* @__PURE__ */ jsxs(Box, { children: [
                    /* @__PURE__ */ jsxs(Stack, { sx: { mt: { xs: "0px", sm: "0px", md: "0px", lg: "0px" }, mb: "30px" }, children: [
                      /* @__PURE__ */ jsx(Typography, { variant: "title1", children: title }),
                      description && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.m10 }, children: description })
                    ] }),
                    /* @__PURE__ */ jsx("form", { onSubmit: submit, onKeyDown, children })
                  ] }),
                  /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g40 }, children: [
                    showLoginButton && /* @__PURE__ */ jsx(AuthLoginButton, {}),
                    /* @__PURE__ */ jsx(AuthFooter, {})
                  ] })
                ]
              }
            ) })
          }
        )
      ]
    }
  );
};
