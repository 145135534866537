"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@v2/styles/colors.styles";
export const HelperAbsenceSettingsSection = ({
  absenceSettings,
  isLoadingSettings
}) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Absence settings" }),
    isLoadingSettings ? /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "300px", height: "200px", sx: { borderRadius: "10px" } }) : absenceSettings ? /* @__PURE__ */ jsxs(Box, { sx: { width: "300px" }, children: [
      /* @__PURE__ */ jsx(HelperReadOnlyField, { label: "holidayCountry", value: absenceSettings.holidayCountry }),
      /* @__PURE__ */ jsx(HelperReadOnlyField, { label: "showOverlap", value: absenceSettings.showOverlap ? "True" : "False" }),
      absenceSettings.showOverlap && /* @__PURE__ */ jsx(HelperReadOnlyField, { label: "overlapsRule", value: absenceSettings.overlapsRule })
    ] }) : null
  ] });
};
const HelperReadOnlyField = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        py: "2px",
        px: "5px",
        "&:hover": { bgcolor: themeColors.Background, borderRadius: "15px" }
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { width: "240px" }, children: label }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { display: "flex", justifyContent: "end", width: "50px" }, children: value })
      ]
    }
  );
};
