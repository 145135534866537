"use strict";
export const DeviceScopes = {
  CONFIRM_DEVICE_ORDER_DELIVERY: ["devices"],
  READ_COMPANY_INVENTORY: ["devices:read:all"],
  VIEW_ACTIVE_DEVICES: ["devices:all"],
  VIEW_DEVICES_OVERVIEW: ["devices:all"],
  VIEW_DEVICE_COMPANY_SETTINGS: ["devices:all"],
  VIEW_DEVICE_LOCATIONS: ["devices:all"],
  VIEW_DEVICE_STORE: ["devices.store", "devices:all"],
  VIEW_IN_TRANSIT_DEVICES: ["devices:all"],
  VIEW_DEVICE_INVENTORY: ["devices:all"],
  VIEW_DEVICE_ORDER_ACTIVATIONS: ["devices"],
  VIEW_DEVICE_ORDER_DETAILS: ["devices:all"],
  VIEW_DEVICE_ORDERS: ["devices:all"],
  VIEW_USER_DEVICES: ["devices:manager"]
};
