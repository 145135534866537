"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import {
  RefinancingAPI,
  RefinancingEndpoints
} from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api";
import { TwoCancellationReason } from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@v2/styles/colors.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
const CancellationReasonsOptions = Object.values(TwoCancellationReason).map((value) => ({ label: value, value }));
const validationSchema = yup.object({
  cancellationReason: yup.string().min(10, "Please select a valid reason").required("This field is required"),
  idempotencyKey: yup.string().min(10, "Please input a key of at least 10 characters").required("This field is required"),
  cancelledAt: yup.string().min(24, `Please type a full ISO Date (e.g.: ${(/* @__PURE__ */ new Date()).toISOString()})`).required("This field is required")
});
export const SuperAdminOrderRefinancingCancellationDrawer = ({
  isOpen,
  setIsOpen,
  deviceOrder,
  refreshOrders,
  refreshDeviceDetails
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: deviceOrder ? /* @__PURE__ */ jsx(
      SuperAdminOrderRefinancingTerminationDrawerContent,
      {
        deviceOrder,
        refreshOrders,
        refreshDeviceDetails,
        setIsOpen
      }
    ) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Please select an order" }) })
  }
) });
export const SuperAdminOrderRefinancingTerminationDrawerContent = ({
  deviceOrder,
  refreshOrders,
  refreshDeviceDetails,
  setIsOpen
}) => {
  var _a, _b;
  const { data: refinancingPlan, isLoading } = useApiClient(
    RefinancingEndpoints.getOrderRepaymentPlan(deviceOrder.companyId, deviceOrder.id),
    { suspense: false }
  );
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const cancelRefinancing = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!deviceOrder || !refinancingPlan) return;
      try {
        setLoading(true);
        const cancellationData = {
          cancellationReason: values.cancellationReason,
          idempotencyKey: values.idempotencyKey,
          cancelledAt: values.cancelledAt
        };
        yield RefinancingAPI.cancelRefinancingPlanAsSuperAdmin(
          deviceOrder.companyId,
          deviceOrder.id,
          refinancingPlan.id,
          cancellationData
        );
        yield Promise.all([refreshOrders(), refreshDeviceDetails()]);
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
      setLoading(false);
    }),
    [refinancingPlan, deviceOrder, showMessage, refreshDeviceDetails, refreshOrders, setIsOpen]
  );
  const formik = useFormik({
    initialValues: {
      cancellationReason: TwoCancellationReason.TERMINATED,
      idempotencyKey: "",
      cancelledAt: (/* @__PURE__ */ new Date()).toISOString()
    },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      return cancelRefinancing(values);
    })
  });
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: !!isLoading, noHorizontalPadding: true, children: refinancingPlan && refinancingPlan.externalId && deviceOrder.externallyInvoiced ? /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Cancel refinancing" }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Device ID" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: deviceOrder.deviceId }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Order ID" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: deviceOrder.id }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Refinancing plan ID" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: refinancingPlan.id }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Refinancing plan external ID" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: refinancingPlan.externalId }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Externally invoiced" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: (_a = deviceOrder.externallyInvoiced) != null ? _a : "NULL" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 2 }, children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "cancellationReason",
          label: "Cancellation reason",
          options: CancellationReasonsOptions,
          value: formik.values.cancellationReason,
          compareValue: formik.values.cancellationReason,
          onChange: formik.handleChange,
          error: formik.touched.cancellationReason && !!formik.errors.cancellationReason,
          helperText: formik.touched.cancellationReason && formik.errors.cancellationReason
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Idempotency key",
          name: "idempotencyKey",
          value: formik.values.idempotencyKey,
          onChange: formik.handleChange,
          error: formik.touched.idempotencyKey && Boolean(formik.errors.idempotencyKey),
          helperText: formik.touched.idempotencyKey && formik.errors.idempotencyKey,
          size: "small",
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("idempotencyKey", "")
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Cancelled at",
          name: "cancelledAt",
          value: formik.values.cancelledAt,
          onChange: formik.handleChange,
          error: formik.touched.cancelledAt && Boolean(formik.errors.cancelledAt),
          helperText: formik.touched.cancelledAt && formik.errors.cancelledAt,
          size: "small",
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("cancelledAt", "")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: "30px" }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Cancel refinancing",
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Cancel refinancing" }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Device ID" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: deviceOrder.deviceId }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Order ID" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: deviceOrder.id }),
    refinancingPlan && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Refinancing plan ID" }),
    refinancingPlan && /* @__PURE__ */ jsx(Typography, { variant: "title4", children: refinancingPlan.id }),
    refinancingPlan && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Refinancing plan external ID" }),
    refinancingPlan && /* @__PURE__ */ jsx(Typography, { variant: "title4", children: refinancingPlan.externalId }),
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { mt: "10px" }, children: "Externally invoiced" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: (_b = deviceOrder.externallyInvoiced) != null ? _b : "NULL" }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mt: "20px", color: themeColors.RedDark }, children: "No active refinancing plan has been found." })
  ] }) }) });
};
