"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { EditPensionProviderSettingsDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/pension/components/edit-pension-provider-settings-drawer.component";
import {
  BenefitsSettingsPageSection,
  DescriptionLine
} from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { definitionValueSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { PaymentValues, PensionRulesOptions } from "@/lib/pensions";
export const PensionSettingsProviderPage = ({ pensionScheme, refreshPension }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("PensionModule.providerSettings"),
      onEdit: () => {
        setIsEditOpen(true);
      },
      children: [
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("PensionModule.provider"),
            value: /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.gap10,
                  ml: 0
                },
                children: [
                  getPensionLogoByProviderName(pensionScheme.providerName),
                  /* @__PURE__ */ jsx(Typography, { sx: definitionValueSx, children: (_a = PensionProvidersValueToLabel[pensionScheme.providerName]) != null ? _a : pensionScheme.providerName })
                ]
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("PensionModule.provider"),
            value: (_c = (_b = PensionRulesOptions.find((option) => option.value === pensionScheme.pensionRule)) == null ? void 0 : _b.label) != null ? _c : pensionScheme.pensionRule
          }
        ),
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("PensionModule.defaultWorkerGroup"),
            value: (_e = (_d = pensionScheme.workerGroups.find((wG) => wG.externalId === pensionScheme.defaultWorkerGroup)) == null ? void 0 : _d.name) != null ? _e : "N/A"
          }
        ),
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("PensionModule.paymentMethod"),
            value: (_g = (_f = PaymentValues.find((option) => option.value === pensionScheme.payMethod)) == null ? void 0 : _f.label) != null ? _g : pensionScheme.payMethod
          }
        ),
        isEditOpen && /* @__PURE__ */ jsx(
          EditPensionProviderSettingsDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            provider: pensionScheme,
            refresh: refreshPension
          }
        )
      ]
    }
  );
};
