"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2 } from "react";
import { Box } from "@mui/material";
import { BenefitRequestTodoVersionSmall } from "@v2/feature/dashboard/features/sections/user-todos/components/version-small/benefit-request-todo-version-small.component";
import { ExpenseRequestTodoVersionSmall } from "@v2/feature/dashboard/features/sections/user-todos/components/version-small/expense-request-todo-version-small.section";
import { InvoiceRequestTodoVersionSmall } from "@v2/feature/dashboard/features/sections/user-todos/components/version-small/invoice-request-todo-version-small.section";
import { PendingBenefitPaymentTodoVersionSmall } from "@v2/feature/dashboard/features/sections/user-todos/components/version-small/pending-benefit-payment-todo-version-small.component";
import { PHDeletionRequestsTodoVersionSmall } from "@v2/feature/dashboard/features/sections/user-todos/components/version-small/ph-deletion-requests-todo-version-small.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { PEOPLE_ME_ACTION_ITEMS } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { UserTodosEmptyState } from "@/v2/feature/dashboard/features/sections/user-todos/components/user-todos-empty-state.component";
import { AppTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/app-todo-version-small.component";
import { AttendanceTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/attendance-todo-version-small.component";
import { ContractTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/contract-todo-version-small.component";
import { DocumentTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/document-todo-version-small.component";
import { ReviewTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/review-todo-version-small.component";
import { ShiftsTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/shifts-todo-version-small.component";
import { SubmittedRequestTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/submitted-request-todo-version-small.component";
import { TaskTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/task-todo-version-small.component";
import { TimeRequestTodoVersionSmall } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/time-request-todo-version-small.component";
import "@/v2/feature/dashboard/features/sections/user-todos/user-todos.scss";
import { KindTypes } from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserTodosVersionSmall = ({
  todos,
  readOnly = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const limit = 5;
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly, size: "small", children: /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          width: "100%",
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          cursor: "pointer"
        },
        onClick: () => routerHistory.push(PEOPLE_ME_ACTION_ITEMS),
        children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserTodosVersionSmall.action") }) })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { height: "100%" }, children: todos && ((_a = todos == null ? void 0 : todos.personal) == null ? void 0 : _a.totalCount) === 0 ? /* @__PURE__ */ jsx(UserTodosEmptyState, {}) : /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column"
        },
        children: [
          (_c = (_b = todos == null ? void 0 : todos.personal) == null ? void 0 : _b.todosItems) == null ? void 0 : _c.map((todo) => {
            switch (todo.kind) {
              case KindTypes.IncompleteTasks:
                return /* @__PURE__ */ jsx(TaskTodoVersionSmall, { todo }, "overdue-tasks");
              case KindTypes.PendingRequest:
                return /* @__PURE__ */ jsx(TimeRequestTodoVersionSmall, { todo }, "pending-reqs");
              case KindTypes.PendingPHCancellation:
                return /* @__PURE__ */ jsx(PHDeletionRequestsTodoVersionSmall, { todo }, "pending-ph-deletion-reqs");
              case KindTypes.ExpenseRequest:
                return /* @__PURE__ */ jsx(ExpenseRequestTodoVersionSmall, { todo }, "expense-reqs");
              case KindTypes.InvoiceRequest:
                return /* @__PURE__ */ jsx(InvoiceRequestTodoVersionSmall, { todo }, "invoice-reqs");
              case KindTypes.PendingBenefitPayment:
                return /* @__PURE__ */ jsx(PendingBenefitPaymentTodoVersionSmall, { todo }, "pending-benefit-payments");
              case KindTypes.SubmittedAttendance:
                return /* @__PURE__ */ jsx(AttendanceTodoVersionSmall, { todo }, "submitted-reqs");
              case KindTypes.UnfinishedAttendance:
                return /* @__PURE__ */ jsx(ShiftsTodoVersionSmall, { todo }, "unfinished-shifts");
              case KindTypes.MissingDocuments:
                return /* @__PURE__ */ jsx(DocumentTodoVersionSmall, { todo }, "missing-docs");
              case KindTypes.PendingContracts:
                return /* @__PURE__ */ jsx(ContractTodoVersionSmall, { todo }, "pending-contracts");
              case KindTypes.PendingReviews:
                return /* @__PURE__ */ jsx(ReviewTodoVersionSmall, { todo }, "pending-reviews");
              case KindTypes.PendingRequests:
                return /* @__PURE__ */ jsx(AppTodoVersionSmall, { todo }, "app-requests");
              case KindTypes.SubmittedRequestForm:
                return /* @__PURE__ */ jsx(SubmittedRequestTodoVersionSmall, { todo }, "submitted-requests");
              case KindTypes.BenefitRequest:
                return /* @__PURE__ */ jsx(BenefitRequestTodoVersionSmall, { todo }, "submitted-requests");
              default:
                return /* @__PURE__ */ jsx(Fragment, {});
            }
          }),
          Boolean(((_d = todos == null ? void 0 : todos.personal) == null ? void 0 : _d.totalCount) && ((_e = todos == null ? void 0 : todos.personal) == null ? void 0 : _e.totalCount) - limit > 0) && /* @__PURE__ */ jsx(
            "div",
            {
              onClick: () => routerHistory.push(PEOPLE_ME_ACTION_ITEMS),
              role: "button",
              className: "clickable",
              style: { padding: spacing.p5 },
              children: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey }, children: ((_f = todos == null ? void 0 : todos.personal) == null ? void 0 : _f.totalCount) && todos.personal.totalCount > 0 ? polyglot.t("UserTodosVersionSmall.more", {
                smart_count: ((_g = todos == null ? void 0 : todos.personal) == null ? void 0 : _g.totalCount) && ((_h = todos == null ? void 0 : todos.personal) == null ? void 0 : _h.totalCount) - limit
              }) : "" })
            }
          )
        ]
      }
    ) })
  ] }) });
};
