"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Typography } from "@v2/components/typography/typography.component";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { EditCustomBenefitApprovalDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-approval-drawer.component";
import {
  BenefitsSettingsPageSection,
  DescriptionLine
} from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import {
  CustomBenefitPaymentMethodType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const CustomBenefitSettingsApprovalPage = ({ customBenefit, refreshBenefit }) => {
  const { polyglot } = usePolyglot();
  const { data: approvalRules, mutate: refreshRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(), {
    suspense: false
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const approvalRule = approvalRules == null ? void 0 : approvalRules.find((rule) => rule.id === customBenefit.approvalRuleId);
  const refreshApprovalRules = useCallback(() => __async(void 0, null, function* () {
    if (refreshRules) yield refreshRules();
  }), [refreshRules]);
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("BenefitModule.approvalRule"),
      onEdit: () => {
        setIsEditOpen(true);
      },
      children: [
        /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.approval"), value: approvalRule == null ? void 0 : approvalRule.name }),
        customBenefit.paymentMethod === CustomBenefitPaymentMethodType.Expenses && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: 'This gets overwritten by the expense approval rule when payment method is "Expenses".' }),
        isEditOpen && /* @__PURE__ */ jsx(
          EditCustomBenefitApprovalDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            customBenefit,
            refresh: refreshBenefit,
            approvalRules: approvalRules != null ? approvalRules : [],
            refreshApprovalRules
          }
        )
      ]
    }
  );
};
