"use strict";
import { Node, mergeAttributes } from "@tiptap/core";
export const CustomParagraphNode = Node.create({
  name: "paragraph",
  group: "block",
  content: "inline*",
  parseHTML() {
    return [
      {
        tag: "p"
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["p", mergeAttributes(HTMLAttributes), 0];
  },
  addAttributes() {
    return {
      color: {
        default: null,
        parseHTML: (element) => element.style.color || null,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return { style: `color: black` };
          }
          return { style: `color: ${attributes.color}` };
        }
      }
    };
  }
});
