"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { DeviceOwnership, DeviceType } from "@v2/feature/device/device.interface";
import { DeviceDomainReportFilters } from "@v2/feature/reports/reports.interface";
import { capitalize } from "@v2/feature/reports/util/report.util";
export const DevicesReportColumnsOptions = (polyglot) => {
  return [
    { label: polyglot.t("DevicesReportColumnsOptions.owner"), value: "owner" },
    { label: polyglot.t("DevicesReportColumnsOptions.type"), value: "type" },
    { label: polyglot.t("DevicesReportColumnsOptions.manufacturer"), value: "manufacturer" },
    { label: polyglot.t("DevicesReportColumnsOptions.modelName"), value: "modelName" },
    { label: polyglot.t("DevicesReportColumnsOptions.modelNumber"), value: "modelNumber" },
    { label: polyglot.t("DevicesReportColumnsOptions.serialNumber"), value: "serialNumber" },
    { label: polyglot.t("DevicesReportColumnsOptions.platform"), value: "platform" },
    { label: polyglot.t("DevicesReportColumnsOptions.ram"), value: "ram" },
    { label: polyglot.t("DevicesReportColumnsOptions.storage"), value: "storage" },
    { label: polyglot.t("DevicesReportColumnsOptions.cpu"), value: "cpu" },
    { label: polyglot.t("DevicesReportColumnsOptions.gpu"), value: "gpu" },
    { label: polyglot.t("DevicesReportColumnsOptions.screenSize"), value: "screenSize" },
    { label: polyglot.t("DevicesReportColumnsOptions.ownership"), value: "ownership" }
  ];
};
export function getDeviceReportFiltersOptions(devicesFiltersOptions, polyglot) {
  const typeOptions = [
    { label: polyglot.t("getDeviceReportFiltersOptions.laptop"), value: DeviceType.Laptop },
    { label: polyglot.t("getDeviceReportFiltersOptions.phone"), value: DeviceType.Phone },
    { label: polyglot.t("getDeviceReportFiltersOptions.tablet"), value: DeviceType.Tablet },
    { label: polyglot.t("getDeviceReportFiltersOptions.monitor"), value: DeviceType.Monitor },
    { label: polyglot.t("getDeviceReportFiltersOptions.headset"), value: DeviceType.Headset },
    { label: polyglot.t("getDeviceReportFiltersOptions.mouse"), value: DeviceType.Mouse },
    { label: polyglot.t("getDeviceReportFiltersOptions.keyboard"), value: DeviceType.Keyboard },
    { label: polyglot.t("getDeviceReportFiltersOptions.other"), value: DeviceType.Other }
  ];
  const ownershipOptions = (polyglot2) => {
    return [
      { label: polyglot2.t("ownershipOptions.company"), value: DeviceOwnership.Company },
      { label: polyglot2.t("ownershipOptions.purchase"), value: DeviceOwnership.CompanyPurchase },
      { label: polyglot2.t("ownershipOptions.rental"), value: DeviceOwnership.Rental }
    ];
  };
  const platformOptions = devicesFiltersOptions && devicesFiltersOptions["platform"] ? devicesFiltersOptions["platform"].map((o) => ({
    label: o === "macos" ? "macOS" : o === "ipados" ? "iPadOS" : capitalize(o),
    value: o
  })) : null;
  const modelOptions = devicesFiltersOptions && devicesFiltersOptions["modelName"] ? devicesFiltersOptions["modelName"].map((o) => ({
    label: o,
    value: o
  })) : null;
  let filters = {};
  const FILTERS = DeviceDomainReportFilters(
    typeOptions,
    ownershipOptions(polyglot),
    platformOptions,
    modelOptions,
    polyglot
  );
  for (const filterObj of FILTERS) {
    filters = __spreadProps(__spreadValues({}, filters), { [`${filterObj.domain}-${filterObj.field}-${filterObj.name}`]: filterObj.options });
  }
  return filters;
}
