"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Stack } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { PayrollLogoZelt } from "@v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const TemplateBlock = ({ template, action, active }) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      role: "button",
      sx: {
        height: "100%",
        width: "100%",
        minHeight: "120px",
        boxSizing: "border-box",
        background: active && active === template.id ? themeColors.Background : themeColors.white,
        border: `1px solid ${themeColors.GreyPress}`,
        borderRadius: radius.br12,
        padding: spacing.p12,
        "&:hover": {
          cursor: "pointer",
          background: themeColors.GreyHover
        }
      },
      onClick: action,
      children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title3", children: template.name }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
          /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            template.questionIds ? template.questionIds.length : 0,
            " questions"
          ] }),
          template.companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
            /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "15px", width: "15px", padding: "2.5px" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                color: "DarkGrey",
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                },
                children: "Zelt"
              }
            )
          ] }) : template.createdBy ? /* @__PURE__ */ jsx(UserCell, { userId: template.createdBy }) : /* @__PURE__ */ jsx(EmptyCell, {})
        ] })
      ] })
    }
  );
};
