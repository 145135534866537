"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer, useState } from "react";
import { Typography } from "@mui/material";
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  initialiseState,
  reduceDocumentFormModal
} from "@v2/feature/documents/documents.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DocumentEmptyOverview } from "@/v2/feature/documents/components/document-empty-overview.component";
import { DocumentFormModal } from "@/v2/feature/documents/components/document-form-modal.component";
import { PersonalDocuments } from "@/v2/feature/documents/document-me/components/personal-documents.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const initialState = initialiseState([]);
export const UserProfileDocument = ({ userId, userName }) => {
  const [documentFormMode, setDocumentFormMode] = useState("add");
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(["documents:all"], getScopesContext(globalState.user));
  const [filterValue, setFilterValue] = useState("");
  const [showMessage] = useMessage();
  useEffect(() => {
    setLoading(true);
    (() => __async(void 0, null, function* () {
      try {
        const [documents2, types] = yield Promise.all([
          DocumentAPI.listUserDocuments(userId),
          DocumentAPI.listDocumentTypes()
        ]);
        setDocuments(documents2);
        documentDispatch({ kind: "set_types", value: types });
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [userId, showMessage, documentDispatch]);
  const completeSave = () => __async(void 0, null, function* () {
    var _a, _b, _c, _d, _e, _f, _g;
    try {
      let documentObject = {
        name: documentState.name || "",
        type: (_b = (_a = documentState.type) == null ? void 0 : _a.value) != null ? _b : "",
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: documentState.fileUuid,
        note: documentState.note,
        expirySettings: (_c = documentState == null ? void 0 : documentState.expirySettings) != null ? _c : { expiryDate: "", documentExpires: false }
      };
      if (documentState.id && documentState.name) {
        if (((_d = documentState.type) == null ? void 0 : _d.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_e = documentState.pinned) != null ? _e : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage("Document updated successfully.", "success");
      } else if (documentState.name) {
        if (((_f = documentState.type) == null ? void 0 : _f.category) === "Company Documents") {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { isPinned: (_g = documentState.pinned) != null ? _g : false });
        } else if (documentState.visibility) {
          documentObject = __spreadProps(__spreadValues({}, documentObject), { visibilitySettings: documentState.visibility });
        }
        yield DocumentAPI.createDocument(documentObject);
        showMessage("Document saved successfully.", "success");
      }
      yield getDocuments();
    } catch (error) {
      showMessage(`Could not save the document. ${nestErrorMessage(error)}`, "error");
    }
  });
  function closeUploadModal() {
    setOpen(false);
  }
  const getDocuments = () => __async(void 0, null, function* () {
    try {
      const documents2 = yield DocumentAPI.listUserDocuments(userId);
      setDocuments(documents2);
    } catch (error) {
      showMessage(`Could not retrieve the document. ${nestErrorMessage(error)}`, "error");
    }
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: "Documents" }) }),
    !loading && documents.length < 1 ? /* @__PURE__ */ jsx(ContentWrapper, { loading, sx: __spreadValues({}, spacing.pt20), children: /* @__PURE__ */ jsx(
      DocumentEmptyOverview,
      {
        onUploadClick: () => {
          documentDispatch({ kind: "add_document", owner: { label: userName, value: userId } });
          setDocumentFormMode("add");
          setFilterValue(
            getDocumentParentCategory(
              "me",
              "add",
              void 0,
              currentUserIsDocumentAdmin,
              documentState.types
            )
          );
          setOpen(true);
        },
        onRequestClick: () => {
          documentDispatch({ kind: "request_document", owner: { label: userName, value: userId }, reach: "me" });
          setDocumentFormMode("request");
          setFilterValue(
            getDocumentParentCategory(
              "me",
              "request",
              void 0,
              currentUserIsDocumentAdmin,
              documentState.types
            )
          );
          setOpen(true);
        },
        isAdmin: currentUserIsDocumentAdmin
      }
    ) }) : /* @__PURE__ */ jsx(
      PersonalDocuments,
      {
        userId,
        userName,
        setDocumentFormMode,
        documentState,
        documentDispatch,
        setOpen,
        loading,
        documents,
        setFilterValue
      }
    ),
    /* @__PURE__ */ jsx(
      DocumentFormModal,
      {
        mode: documentFormMode,
        state: documentState,
        currentUserIsAdmin: currentUserIsDocumentAdmin,
        dispatch: documentDispatch,
        newDocumentTypes: documentState.types,
        onClose: closeUploadModal,
        onSubmit: completeSave,
        setOpen,
        open,
        setLoading,
        getDocuments,
        setFilterValue,
        filterValue
      }
    )
  ] });
};
