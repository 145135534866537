"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import OTPInput from "react-otp-input";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import "./auth-mfs-code.css";
export const MFACodeEntry = ({
  codeLength = 6,
  disabled,
  error,
  helperText,
  onCodeComplete,
  sx
}) => {
  const [otp, setOtp] = useState("");
  return /* @__PURE__ */ jsxs(FormControl, { sx: __spreadValues({ width: "100%", margin: 0 }, sx), error, children: [
    /* @__PURE__ */ jsx(
      OTPInput,
      {
        value: otp,
        onChange: (val) => {
          onCodeComplete(val);
          setOtp(val);
        },
        numInputs: codeLength,
        renderInput: (props) => /* @__PURE__ */ jsx("input", __spreadProps(__spreadValues({}, props), { disabled, className: "otp-style" })),
        containerStyle: { display: "flex", justifyContent: "space-around", gap: spacing.g10 },
        inputStyle: __spreadProps(__spreadValues({
          border: "none",
          borderBottom: `1px solid ${themeColors.DarkGrey}`
        }, themeFonts.title2), {
          color: themeColors.DarkGrey
        })
      }
    ),
    error && helperText && /* @__PURE__ */ jsx(FormHelperText, { sx: { margin: 0, padding: 0 }, id: "component-error-text", children: helperText })
  ] });
};
