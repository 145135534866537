"use strict";
import {
  EXPENSES_COMPANY_OVERVIEW_ROUTE,
  EXPENSES_ME_OVERVIEW_ROUTE,
  EXPENSES_TEAM_OVERVIEW_ROUTE
} from "@/lib/routes";
export const MONEY_EXPENSES_OVERVIEW_ROUTES = {
  company: EXPENSES_COMPANY_OVERVIEW_ROUTE,
  me: EXPENSES_ME_OVERVIEW_ROUTE,
  team: EXPENSES_TEAM_OVERVIEW_ROUTE
};
export const MONEY_EXPENSE_SCOPES = {
  company: ["expenses:all"],
  team: ["expenses:manager"],
  me: ["expenses"]
};
