"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { initializeApp } from "firebase/app";
import {
  SAMLAuthProvider,
  getAuth,
  signInWithPopup,
  OAuthProvider,
  getRedirectResult,
  signInWithRedirect
} from "firebase/auth";
export const isProduction = process.env.REACT_APP_ENV === "production";
const rubikFirebaseConfig = {
  apiKey: "AIzaSyDV3xN31jsrcRfnTNLEImzsH885o1M0GaY",
  authDomain: "noble-descent-379309.firebaseapp.com",
  projectId: "noble-descent-379309",
  storageBucket: "noble-descent-379309.appspot.com",
  messagingSenderId: "958432800127",
  appId: "1:958432800127:web:45eb0ee18330e162773e4a"
};
const productionFirebaseConfig = {
  apiKey: "AIzaSyDAEwqkbXJFq7mJq-_3wll0kCn5yd8JFY0",
  authDomain: "zelt-production.firebaseapp.com",
  projectId: "zelt-production",
  storageBucket: "zelt-production.appspot.com",
  messagingSenderId: "387778103327",
  appId: "1:387778103327:web:7cb0474de3104277fb2d2a",
  measurementId: "G-YNNDE0QC8V"
};
const app = initializeApp(isProduction ? productionFirebaseConfig : rubikFirebaseConfig);
const auth = getAuth(app);
export var SignInFlow = /* @__PURE__ */ ((SignInFlow2) => {
  SignInFlow2[SignInFlow2["Popup"] = 0] = "Popup";
  SignInFlow2[SignInFlow2["Redirect"] = 1] = "Redirect";
  return SignInFlow2;
})(SignInFlow || {});
const signInWithAuthProvider = (authProvider, flow) => __async(void 0, null, function* () {
  try {
    switch (flow) {
      case 0 /* Popup */:
        return signInWithPopup(auth, authProvider);
      case 1 /* Redirect */:
        let result = yield getRedirectResult(auth);
        if (result) {
          return result;
        }
        yield signInWithRedirect(auth, authProvider);
        return void 0;
      default:
        return void 0;
    }
  } catch (error) {
    console.error("Encountered an error during the signInWithAuthProvider process: ", error);
  }
});
export const signinWithGoogle = (_0, _1, ..._2) => __async(void 0, [_0, _1, ..._2], function* (userEmail, ssoCheckResult, flow = 0 /* Popup */) {
  if ((ssoCheckResult == null ? void 0 : ssoCheckResult.app) && (ssoCheckResult == null ? void 0 : ssoCheckResult.companyId) && (ssoCheckResult == null ? void 0 : ssoCheckResult.enabled)) {
    const companySAMLProvider = new SAMLAuthProvider(`saml.company-${ssoCheckResult.companyId}.${ssoCheckResult.app}`);
    companySAMLProvider.setCustomParameters({
      prompt: "select_account",
      login_hint: userEmail
    });
    return signInWithAuthProvider(companySAMLProvider, flow);
  }
  throw new Error(`Unable to find matching SSO provider: ${JSON.stringify(ssoCheckResult)}`);
});
export const signinWithAzureAD = (_0, _1, ..._2) => __async(void 0, [_0, _1, ..._2], function* (userEmail, ssoCheckResult, flow = 0 /* Popup */) {
  if ((ssoCheckResult == null ? void 0 : ssoCheckResult.app) && (ssoCheckResult == null ? void 0 : ssoCheckResult.companyId) && (ssoCheckResult == null ? void 0 : ssoCheckResult.enabled)) {
    const companySAMLProvider = new SAMLAuthProvider(`saml.company-${ssoCheckResult.companyId}.${ssoCheckResult.app}`);
    companySAMLProvider.setCustomParameters({
      prompt: "select_account",
      login_hint: userEmail
    });
    return signInWithAuthProvider(companySAMLProvider, flow);
  }
  throw new Error(`Unable to find matching SSO provider: ${JSON.stringify(ssoCheckResult)}`);
});
export const signinWithOkta = (_0, _1, ..._2) => __async(void 0, [_0, _1, ..._2], function* (userEmail, ssoCheckResult, flow = 0 /* Popup */) {
  if ((ssoCheckResult == null ? void 0 : ssoCheckResult.app) && (ssoCheckResult == null ? void 0 : ssoCheckResult.companyId) && (ssoCheckResult == null ? void 0 : ssoCheckResult.enabled)) {
    const companySAMLProvider = new OAuthProvider(`oidc.company-${ssoCheckResult.companyId}.${ssoCheckResult.app}`);
    companySAMLProvider.setCustomParameters({
      prompt: "select_account",
      login_hint: userEmail
    });
    return signInWithAuthProvider(companySAMLProvider, flow);
  }
  throw new Error(`Unable to find matching SSO provider: ${JSON.stringify(ssoCheckResult)}`);
});
