"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { PaymentYapilyConnect } from "@v2/feature/payments/features/make-payment/components/payment-yapily-connect.component";
import { usePaymentContext } from "@/v2/feature/payments/features/make-payment/payment.context";
import { PaymentsAPI } from "@/v2/feature/payments/payments.api";
export const PaymentYapilyConnectStep = ({
  onNext,
  setUnsafeClose
}) => {
  const [paymentContext] = usePaymentContext();
  const makePayment = (groupPayments, groupPaymentsReference) => __async(void 0, null, function* () {
    var _a, _b, _c, _d, _e, _f;
    setUnsafeClose(true);
    if (((_a = paymentContext.payments) == null ? void 0 : _a.length) === 1) {
      return PaymentsAPI.executeSinglePaymentRequest(
        (_b = paymentContext.institution) == null ? void 0 : _b.id,
        paymentContext.payments[0].id,
        paymentContext.executionDateTime,
        paymentContext.payerDetails
      );
    } else if (paymentContext.payments && ((_c = paymentContext.payments) == null ? void 0 : _c.length) > 1) {
      return PaymentsAPI.executeBulkPaymentRequest(
        (_d = paymentContext.institution) == null ? void 0 : _d.id,
        (_e = paymentContext.payments) == null ? void 0 : _e.map((payment) => payment.id),
        paymentContext.executionDateTime,
        paymentContext.payerDetails,
        groupPayments,
        groupPaymentsReference
      );
    }
    return PaymentsAPI.initSinglePaymentRequest((_f = paymentContext.institution) == null ? void 0 : _f.id, paymentContext.requests[0]);
  });
  return /* @__PURE__ */ jsx(
    PaymentYapilyConnect,
    {
      requests: paymentContext.requests,
      institution: paymentContext.institution,
      onSubmit: makePayment,
      onChange: () => onNext(),
      setUnsafeClose
    }
  );
};
