"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Mistake } from "@/images/reports/Mistake.svg";
import { ReactComponent as CheckCircle } from "@/images/side-bar-icons/CheckCircle.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { DocumentStatus } from "@/v2/feature/documents/documents.interface";
import { allPartiesHaveSignedContract } from "@/v2/feature/documents/documents.util";
import { SignatoryType } from "@/v2/feature/templates/templates.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { translateDocumentStatus } from "@/v2/infrastructure/i18n/translate.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
const iconSize = { width: 14, height: 14 };
export const documentExpiringSoon = (document) => {
  var _a, _b;
  const sevenDays = 7 * 24 * 60 * 60 * 1e3;
  if (!((_a = document == null ? void 0 : document.expirySettings) == null ? void 0 : _a.expiryDate)) return false;
  const expiryDate = new Date((_b = document == null ? void 0 : document.expirySettings) == null ? void 0 : _b.expiryDate);
  const today = /* @__PURE__ */ new Date();
  return expiryDate.getTime() - today.getTime() <= sevenDays;
};
export const documentExpired = (document) => {
  var _a, _b;
  if (!((_a = document == null ? void 0 : document.expirySettings) == null ? void 0 : _a.expiryDate)) return false;
  const expiryDate = new Date((_b = document == null ? void 0 : document.expirySettings) == null ? void 0 : _b.expiryDate);
  const today = /* @__PURE__ */ new Date();
  return expiryDate.getTime() < today.getTime();
};
const getTooltipTextForPartiallySignedContract = (document, companySignatoryUser, recipientUser) => {
  var _a, _b;
  const { contract } = document;
  const signedParties = [];
  const pendingSignatories = [];
  const requiredCompanySignature = (contract == null ? void 0 : contract.signatoriesRequired) && ((_a = contract == null ? void 0 : contract.signatoriesRequired) == null ? void 0 : _a.length) > 0 && contract.signatoriesRequired.includes(SignatoryType.additional);
  const requiredRecipientSignature = (contract == null ? void 0 : contract.signatoriesRequired) && ((_b = contract == null ? void 0 : contract.signatoriesRequired) == null ? void 0 : _b.length) > 0 && contract.signatoriesRequired.includes(SignatoryType.recipient);
  if (requiredCompanySignature && (contract == null ? void 0 : contract.companySignatureTimestamp) && companySignatoryUser)
    signedParties.push(companySignatoryUser == null ? void 0 : companySignatoryUser.displayName);
  if (requiredRecipientSignature && (contract == null ? void 0 : contract.recipientSignatureTimestamp) && recipientUser)
    signedParties.push(recipientUser == null ? void 0 : recipientUser.displayName);
  if (requiredCompanySignature && !(contract == null ? void 0 : contract.companySignatureTimestamp) && companySignatoryUser)
    pendingSignatories.push(companySignatoryUser == null ? void 0 : companySignatoryUser.displayName);
  if (requiredRecipientSignature && !(contract == null ? void 0 : contract.recipientSignatureTimestamp) && recipientUser)
    pendingSignatories.push(recipientUser == null ? void 0 : recipientUser.displayName);
  if (pendingSignatories.length === 0) return "";
  let tooltipText = signedParties.length > 0 ? `Signed by:  ${signedParties.join(", ")}; ` : "";
  tooltipText += pendingSignatories.length > 0 ? `Pending signature: ${pendingSignatories.join(", ")};` : "";
  return tooltipText.replace(/;$/, "").trim();
};
const getSignedStatus = (document) => {
  var _a, _b, _c;
  const { contract } = document;
  const fileUuidFromAttachment = document.attachments && document.attachments[0] && ((_a = document.attachments[0]) == null ? void 0 : _a.fileUuid);
  const finalUuid = (_b = document.fileUuid) != null ? _b : fileUuidFromAttachment;
  const contractFinalised = !!((contract == null ? void 0 : contract.id) && finalUuid);
  const signatories = [];
  const partiesSigned = [];
  if (contract && contract.signatoriesRequired && ((_c = contract.signatoriesRequired) == null ? void 0 : _c.length) > 0) {
    if (contract.signatoriesRequired.includes(SignatoryType.additional) && contract.companySignatory) {
      signatories.push(contract.companySignatory);
      if (contract.companySignatureTimestamp) {
        partiesSigned.push(contract.companySignatory);
      }
    }
    if (contract.signatoriesRequired.includes(SignatoryType.recipient) && contract.recipient) {
      signatories.push(contract.recipient);
      if (contract.recipientSignatureTimestamp) {
        partiesSigned.push(contract.recipient);
      }
    }
  }
  const noPendingSignatures = partiesSigned.length === signatories.length;
  const missingInformation = (contract == null ? void 0 : contract.hasMissingPersonalFields) || (contract == null ? void 0 : contract.hasMissingWorkFields);
  return contract && contract.id ? noPendingSignatures && (!missingInformation || contractFinalised) ? DocumentStatus.Signed : noPendingSignatures && missingInformation && !contractFinalised ? DocumentStatus.MissingInformation : `${partiesSigned.length}/${signatories.length} Signed` : DocumentStatus.Uploaded;
};
export const getDocumentStatus = (document, contract) => {
  var _a, _b;
  let status = DocumentStatus.Pending;
  const fileUuidFromAttachment = document.attachments && document.attachments[0] && ((_a = document.attachments[0]) == null ? void 0 : _a.fileUuid);
  const finalUuid = (_b = document.fileUuid) != null ? _b : fileUuidFromAttachment;
  if (finalUuid) status = DocumentStatus.Uploaded;
  if (document.contractId) {
    if ((contract == null ? void 0 : contract.companySignatureTimestamp) && (contract == null ? void 0 : contract.recipientSignatureTimestamp)) {
      status = DocumentStatus.Signed;
    } else if (!(contract == null ? void 0 : contract.companySignatureTimestamp) && (contract == null ? void 0 : contract.recipientSignatureTimestamp) || (contract == null ? void 0 : contract.companySignatureTimestamp) && !(contract == null ? void 0 : contract.recipientSignatureTimestamp)) {
      status = getSignedStatus(document);
    } else if (!(contract == null ? void 0 : contract.companySignatureTimestamp) && !(contract == null ? void 0 : contract.recipientSignatureTimestamp)) {
      status = getSignedStatus(document);
    }
  }
  if (documentExpiringSoon(document)) status = DocumentStatus.expiringSoon;
  if (documentExpired(document)) status = DocumentStatus.expired;
  return status;
};
export const DisplayDocumentStatus = ({ document }) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { contract } = document;
  const hideTooltip = !document.contractId || !!(document.contractId && allPartiesHaveSignedContract(document)) || !!(document.contractId && document.contract && document.contract.signatoriesRequired && document.contract.signatoriesRequired.length === 0);
  const documentStatus = useMemo(() => {
    return getDocumentStatus(document, contract);
  }, [contract, document]);
  if (documentStatus === DocumentStatus.Pending)
    return /* @__PURE__ */ jsxs(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
          color: themeColors.Grey,
          gap: "5px",
          display: "flex",
          alignItems: "center"
        }),
        children: [
          /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
          " ",
          translateDocumentStatus(documentStatus, polyglot)
        ]
      }
    );
  else
    return /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        disabled: hideTooltip,
        title: (contract == null ? void 0 : contract.companySignatory) && !(contract == null ? void 0 : contract.companySignatureTimestamp) || !(contract == null ? void 0 : contract.recipientSignatureTimestamp) ? /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.tooltip), children: getTooltipTextForPartiallySignedContract(
          document,
          (contract == null ? void 0 : contract.companySignatory) ? getCachedUserById(contract == null ? void 0 : contract.companySignatory) : void 0,
          (contract == null ? void 0 : contract.recipient) ? getCachedUserById(contract == null ? void 0 : contract.recipient) : void 0
        ) }) : void 0,
        children: /* @__PURE__ */ jsxs(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              gap: "5px",
              display: "flex",
              alignItems: "center",
              color: [DocumentStatus.expiringSoon, DocumentStatus.expired].includes(documentStatus) ? themeColors.Red : themeColors.Grey
            }),
            children: [
              documentStatus === DocumentStatus.Uploaded && /* @__PURE__ */ jsx(CheckCircle, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
              [DocumentStatus.expiringSoon, DocumentStatus.expired].includes(documentStatus) && /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize)),
              translateDocumentStatus(documentStatus, polyglot)
            ]
          }
        )
      }
    );
};
