"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { APPS_COMPANY_OVERVIEW_ROUTE, APPS_REQUESTS_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getDetailByDomain } from "@/v2/feature/dashboard/dashboard.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppActionItem = ({
  appActionRow,
  afterClose
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(Boolean(appActionRow));
  const { getCachedUserById } = useCachedUsers();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const routerHistory = useHistory();
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId: currentUser.userId });
  const hasAppsAll = hasScopes(["apps:all"], scopesContext);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = appActionRow == null ? void 0 : appActionRow.appName) != null ? _a : "Apps" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { marginTop: spacing.m10 }, children: getDetailByDomain(appActionRow, getCachedUserById, currentUser, polyglot) }),
    /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        onClick: () => routerHistory.push(hasAppsAll ? APPS_REQUESTS_ROUTE : APPS_COMPANY_OVERVIEW_ROUTE),
        children: polyglot.t("AppActionItem.view")
      }
    ) })
  ] }) });
};
