"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { IconButton, Stack } from "@mui/material";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { Typography } from "@/v2/components/typography/typography.component";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const PayrollSettingSectionHeader = ({
  children,
  editButtonHint,
  showEditButton,
  onEditClick
}) => {
  return /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", mb: spacing.m10, gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children }),
    showEditButton && /* @__PURE__ */ jsx(IconButton, { title: editButtonHint, sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { ml: "auto" }), onClick: () => onEditClick == null ? void 0 : onEditClick(), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
  ] });
};
