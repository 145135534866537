"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback } from "react";
import { InHouseEndpoints } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const useDepAccountDetails = (mdmServerName) => {
  const processedMdmServerName = mdmServerName.replaceAll(" ", "_").replaceAll(/[^\p{L}\p{N}_]+/gu, "");
  const { data, mutate, isLoading } = useApiClient(InHouseEndpoints.getDepAccountDetails(processedMdmServerName), {
    suspense: false
  });
  const refreshDepAccountDetails = useCallback(() => __async(void 0, null, function* () {
    if (mutate) yield mutate();
  }), [mutate]);
  return {
    depAccountDetails: data != null ? data : void 0,
    refreshDepAccountDetails,
    isLoading: Boolean(isLoading)
  };
};
