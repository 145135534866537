"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { GlobalContext } from "@/GlobalState";
import { canAccessScopes, checkScopes } from "@/lib/scopes";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import React, { Children, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
export const ScopesSwitch = ({ children }) => {
  var _a;
  const [state] = useContext(GlobalContext);
  let matched, otherwise;
  (_a = Children.toArray(children)) == null ? void 0 : _a.some((child) => {
    if (React.isValidElement(child)) {
      if (child.props.otherwise) {
        otherwise = child;
      } else if (checkScopes(state.user, child.props.scopes, child.props.context)) {
        matched = child;
        return true;
      }
    }
    return false;
  });
  return matched || otherwise || null;
};
const CustomWidthTooltip = styled((_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ jsx(Tooltip, __spreadProps(__spreadValues({}, props), { classes: { popper: className } }));
})({
  [`& .${tooltipClasses.tooltip}`]: {
    whiteSpace: "pre-line",
    backgroundColor: "red"
  }
});
export const ScopesInspector = ({ children, scopes, isRoute = false }) => {
  const tooltipText = `${isRoute ? "Route: " : ""} ${scopes.join("\n")}`;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    !isRoute && /* @__PURE__ */ jsx(CustomWidthTooltip, { title: tooltipText, placement: "top", children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          border: 1,
          borderColor: "red",
          minWidth: 10,
          minHeight: 10,
          width: "inherit",
          borderStyle: "dotted"
        },
        children
      }
    ) }),
    isRoute && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: { border: 1, borderColor: "orange", minWidth: 10, minHeight: 10, width: "inherit", position: "relative" },
        children: [
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: {
                position: "absolute",
                bottom: 0,
                backgroundColor: "orange",
                color: "white",
                zIndex: 99,
                padding: 1
              },
              children: tooltipText
            }
          ),
          children
        ]
      }
    )
  ] });
};
export const ScopesControl = ({ children, scopes, context }) => {
  const ctx = useContext(GlobalContext);
  if (!ctx) return /* @__PURE__ */ jsx(Fragment, { children });
  const state = ctx[0] || {};
  if (!scopes || !scopes.length) return /* @__PURE__ */ jsx(Fragment, { children });
  const shouldRender = checkScopes(state.user, scopes, context);
  if (!shouldRender && !state.inspectorMode) return /* @__PURE__ */ jsx(Fragment, {});
  if (shouldRender && !state.inspectorMode) return /* @__PURE__ */ jsx(Fragment, { children });
  return /* @__PURE__ */ jsx(ScopesInspector, { scopes, children: shouldRender && children });
};
export const RouteScopesHas = (_c) => {
  var _d = _c, {
    scopes
  } = _d, props = __objRest(_d, [
    "scopes"
  ]);
  const ctx = useContext(GlobalContext);
  if (!ctx) {
    return /* @__PURE__ */ jsx(Route, __spreadValues({}, props));
  }
  const state = ctx[0] || {};
  const shouldRender = canAccessScopes(state.user, scopes);
  if (!shouldRender) {
    return /* @__PURE__ */ jsx(Redirect, { to: "/" });
  }
  if (shouldRender && !state.inspectorMode) {
    return /* @__PURE__ */ jsx(Route, __spreadValues({}, props));
  }
  return /* @__PURE__ */ jsx(ScopesInspector, { scopes, isRoute: true, children: /* @__PURE__ */ jsx(Route, __spreadValues({}, props)) });
};
export const RouteScopesControl = (_e) => {
  var _f = _e, { scopes, context } = _f, props = __objRest(_f, ["scopes", "context"]);
  const ctx = useContext(GlobalContext);
  if (!ctx) return /* @__PURE__ */ jsx(Route, __spreadValues({}, props));
  const state = ctx[0] || {};
  const shouldRender = canAccessScopes(state.user, scopes);
  if (!shouldRender) return /* @__PURE__ */ jsx(Redirect, { to: "/" });
  if (shouldRender && !state.inspectorMode) return /* @__PURE__ */ jsx(Route, __spreadValues({}, props));
  return /* @__PURE__ */ jsx(ScopesInspector, { scopes, isRoute: true, children: /* @__PURE__ */ jsx(Route, __spreadValues({}, props)) });
};
