"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { PAYMENTS_ROUTE } from "@/v2/feature/payments/payments.router";
import { navigateToPayrunsView } from "@/v2/feature/payroll/payroll-router.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrunCompleteSection = ({ payrun, sx }) => {
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g10, mr: spacing.mr40, mb: spacing.m30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Payrun completed" }),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Do you want to make payroll payments now?" }),
    /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", sx: { gap: spacing.g20, mt: spacing.mt20 }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => {
            navigateToPayrunsView(routerHistory, "replace", payrun.payrollId, payrun.id);
          },
          sizeVariant: "medium",
          colorVariant: "secondary",
          children: "Later"
        }
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => {
            routerHistory.replace(PAYMENTS_ROUTE);
          },
          sizeVariant: "medium",
          colorVariant: "primary",
          children: "Pay now"
        }
      )
    ] })
  ] });
};
