"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, List, ListItemIcon, ListItemText } from "@mui/material";
import { getActiveSub } from "@v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { themeColors } from "@/v2/styles/colors.styles";
import { activeSubStyle, ListItemStyle } from "@/v2/styles/list-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NavItem = ({ item }) => {
  const { pathname } = useLocation();
  const { title, children, isHidden } = item;
  if (children) {
    return /* @__PURE__ */ jsxs(List, { sx: { display: "flex", flexDirection: "column" }, disablePadding: true, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: children.map((item2) => {
        const { title: title2, path, icon, externalPath, isHidden: isHidden2, iconActive } = item2;
        const isVisibleForUser = !isHidden2;
        return isVisibleForUser && /* @__PURE__ */ jsxs(
          ListItemStyle,
          {
            component: RouterLink,
            to: { pathname: path },
            target: externalPath ? "_blank" : void 0,
            sx: __spreadProps(__spreadValues({}, getActiveSub(pathname, path) && activeSubStyle), {
              width: "100%",
              textTransform: "none"
            }),
            children: [
              /* @__PURE__ */ jsx(ListItemIcon, { sx: { marginRight: spacing.m10, minWidth: "16px" }, children: getActiveSub(pathname, path) ? iconActive : icon }),
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: /* @__PURE__ */ jsx(ListItemText, { disableTypography: true, primary: title2 }) })
            ]
          },
          title2
        );
      }) }),
      !isHidden && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ borderBottom: `1px solid ${themeColors.Background}` }, spacing.my20) })
    ] });
  }
  return /* @__PURE__ */ jsx(ListItemStyle, { children: /* @__PURE__ */ jsx(ListItemText, { disableTypography: true, primary: title }) });
};
export const NavigationSection = ({ navConfig }) => {
  return /* @__PURE__ */ jsx(Box, { children: navConfig.map(
    (list) => list.items.map((item) => !item.isHidden && /* @__PURE__ */ jsx(NavItem, { item }, item.title))
  ) });
};
