"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, DialogActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { themeFonts } from "@v2/styles/fonts.styles";
import { themeColors } from "@/v2/styles/colors.styles";
const MaterialModal = ({
  children,
  isOpen,
  onClose,
  title,
  titleJustifyContent = "flex-start",
  actionButton,
  buttonJustifyContent = "flex-end",
  size = "sm"
}) => {
  return /* @__PURE__ */ jsxs(Dialog, { maxWidth: size, fullWidth: true, open: isOpen, onClose: () => onClose(), "aria-labelledby": "form-dialog-title", children: [
    title && /* @__PURE__ */ jsx(DialogTitle, { id: "form-dialog-title", sx: { display: "flex", justifyContent: titleJustifyContent }, children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: title }) }) }),
    /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsx(Box, { children }) }),
    actionButton && /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: buttonJustifyContent }, children: actionButton }) })
  ] });
};
export default MaterialModal;
