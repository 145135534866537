"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { AnalyticsAPI } from "@/api-client/index.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { neutralColors, themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
const chartColors = ["#567BC5", "#85a7ff", "#f1629d", "#F4701D", "#704C48", "#5e385f"];
const getLineChartOptions = (categories) => ({
  colors: chartColors,
  chart: {
    type: "line",
    toolbar: {
      show: true
    }
  },
  tooltip: {
    enabled: true,
    marker: {
      show: true
    },
    theme: "dark",
    onDatasetHover: {
      highlightDataSeries: true
    }
  },
  markers: {
    size: 3
  },
  stroke: {
    width: 3
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories,
    tickAmount: 4
  },
  yaxis: { forceNiceScale: true, min: 0 }
});
const getBarChartOptions = (categories) => {
  return {
    colors: chartColors,
    chart: {
      type: "bar",
      toolbar: {
        show: true
      }
    },
    tooltip: {
      theme: "dark",
      marker: {
        show: true
      }
    },
    markers: {
      size: 5
    },
    stroke: {
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories,
      tickAmount: 4
    },
    yaxis: {
      forceNiceScale: true,
      showForNullSeries: false
    }
  };
};
const initialState = {
  dates: [],
  registeredCompanies: [],
  registeredUsers: [],
  activeUsers: [],
  dac: [],
  wac: [],
  mac: [],
  dau: [],
  wau: [],
  mau: [],
  activeDevices: [],
  activeApps: []
};
export const SuperAdminOverviewPage = () => {
  const [analytics, setAnalytics] = useState([]);
  const [dates, setDates] = useState([]);
  const [registeredCompanies, setRegisteredCompanies] = useState([]);
  const [dac, setDac] = useState([]);
  const [wac, setWac] = useState([]);
  const [mac, setMac] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [dau, setDau] = useState([]);
  const [wau, setWau] = useState([]);
  const [mau, setMau] = useState([]);
  const [activeDevices, setActiveDevices] = useState([]);
  const [activeApps, setActiveApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const analytics2 = yield AnalyticsAPI.getLatestAnalyticsRecord();
        const data = analytics2.reduceRight((acc, record) => {
          acc.dates.push(new Date(record.createdAt).toLocaleString().split(",")[0]);
          acc.registeredCompanies.push(record.registeredCompanies);
          acc.registeredUsers.push(record.registeredUsers);
          acc.activeUsers.push(record.activeUsers);
          acc.dac.push(record.dac);
          acc.wac.push(record.wac);
          acc.mac.push(record.mac);
          acc.dau.push(record.dau);
          acc.wau.push(record.wau);
          acc.mau.push(record.mau);
          acc.activeDevices.push(record.activeDevices);
          acc.activeApps.push(record.activeApps);
          return acc;
        }, initialState);
        setDates(data.dates);
        setRegisteredCompanies(data.registeredCompanies);
        setRegisteredUsers(data.registeredUsers);
        setActiveUsers(data.activeUsers);
        setDac(data.dac);
        setWac(data.wac);
        setMac(data.mac);
        setDau(data.dau);
        setWau(data.wau);
        setMau(data.mau);
        setActiveDevices(data.activeDevices);
        setActiveApps(data.activeApps);
        setAnalytics(analytics2);
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [showMessage]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Statistics" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: analytics.length > 0 && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { borderTopWidth: 8, p: 2, borderColor: "#92c5fd" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h6", sx: { color: neutralColors.n6 }, children: "Registered Users" }),
        /* @__PURE__ */ jsx(
          Chart,
          {
            options: getBarChartOptions(dates),
            series: [{ data: registeredUsers, name: "Registered Users" }],
            type: "bar",
            width: "100%",
            height: "250px"
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { borderTopWidth: 8, p: 2, borderColor: "#92c5fd" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h6", sx: { color: neutralColors.n6 }, children: "Users Activity Over Time" }),
        /* @__PURE__ */ jsx(
          Chart,
          {
            options: getLineChartOptions(dates),
            series: [
              {
                data: activeUsers,
                name: "Active Users"
                // value: 'activeUsers',
              },
              { data: dau, name: "Daily Active Users" },
              { data: wau, name: "Weekly Active Users" },
              { data: mau, name: "Monthly Active Users" }
            ],
            type: "line",
            width: "100%",
            height: "250px"
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { borderTopWidth: 8, p: 2, borderColor: "#92c5fd" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h6", sx: { color: neutralColors.n6 }, children: "Companies Over Time" }),
        /* @__PURE__ */ jsx(
          Chart,
          {
            options: getLineChartOptions(dates),
            series: [
              {
                data: registeredCompanies,
                name: "Registered Companies"
              },
              { data: dac, name: "Daily Active Companies" },
              { data: wac, name: "Weekly Active Companies" },
              { data: mac, name: "Monthly Active Companies" }
            ],
            type: "line",
            width: "100%",
            height: "250px"
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { borderTopWidth: 8, p: 2, borderColor: "#92c5fd", width: "49.5%" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "h6", sx: { color: neutralColors.n6 }, children: "Total Active Apps Over Time" }),
          /* @__PURE__ */ jsx(
            Chart,
            {
              options: getBarChartOptions(dates),
              series: [{ data: activeApps, name: "Active Apps" }],
              type: "bar",
              stacked: false,
              width: "100%",
              height: "250px"
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { borderTopWidth: 8, p: 2, borderColor: "#92c5fd", width: "49.5%" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "h6", sx: { color: neutralColors.n6 }, children: "Total Active Devices Over Time" }),
          /* @__PURE__ */ jsx(
            Chart,
            {
              options: getBarChartOptions(dates),
              series: [{ data: activeDevices, name: "Active Devices" }],
              type: "bar",
              stacked: false,
              width: "100%",
              height: "250px"
            }
          )
        ] })
      ] })
    ] }) })
  ] });
};
