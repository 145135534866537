"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CleanText } from "@/images/side-bar-icons/CleanText.svg";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PermissionGroupMemberRemoverList = ({
  setUserListForMembershipDeletion,
  userListForMembershipDeletion,
  existingMemberList
}) => {
  const { getCachedUserById } = useCachedUsers();
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20, mt: spacing.m5 }, children: existingMemberList.filter((m) => !userListForMembershipDeletion.includes(m)).map((memberUserId) => {
    var _a;
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
            display: "flex",
            alignItems: "center",
            gap: spacing.m5
          }),
          children: [
            /* @__PURE__ */ jsx(UserAvatar, { userId: +memberUserId, size: "xxsmall" }),
            (_a = getCachedUserById(memberUserId)) == null ? void 0 : _a.displayName
          ]
        }
      ) }),
      /* @__PURE__ */ jsx(
        Box,
        {
          onClick: () => {
            setUserListForMembershipDeletion([...userListForMembershipDeletion, memberUserId]);
          },
          children: /* @__PURE__ */ jsx(CleanText, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } }))
        }
      )
    ] });
  }) });
};
