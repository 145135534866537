"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { CompanyPayrollType } from "@v2/feature/payroll/payroll.interface";
import { TitledHeaderStack } from "@/v2/components/titled-header.component";
import { PayrunHeader } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header.component";
import { PayrunOverviewTabs } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-overview-tabs.component";
import { PayrunProcessSection } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-process.section";
import { UKPayrunSalariesSection } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-salaries.section";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
export const PayrunTotalPaymentsSummary = ({ payrun, payrunEmpAllowance }) => {
  const {
    totalCost = 0,
    takeHomePay = 0,
    tax = 0,
    employerNi = 0,
    employeeNi = 0,
    studentLoanRecovered = 0,
    postgradLoanRecovered = 0,
    employeePensionContribution = 0,
    employerPensionContribution = 0
  } = payrun.totals;
  const totalPension = employeePensionContribution + employerPensionContribution;
  const totalHMRC = tax + employerNi + employeeNi + studentLoanRecovered + postgradLoanRecovered - payrunEmpAllowance;
  const employerCost = totalCost - payrunEmpAllowance;
  return /* @__PURE__ */ jsx(
    TitledHeaderStack,
    {
      items: [
        { label: "Paid to Employees", value: formatCurrency(takeHomePay) },
        { label: "Paid to Pension", value: formatCurrency(totalPension) },
        { label: "Paid to HMRC", value: formatCurrency(totalHMRC) },
        { label: "Employer Cost", value: formatCurrency(employerCost) }
      ]
    }
  );
};
const PayrunOverviewTabsLabels = ["Overview", "Employees"];
export const PayrunOverviewPage = ({
  entityName,
  externalPayRun,
  entries,
  previousEntries,
  localPayRun,
  payrunEmpAllowance,
  refreshPayrun,
  completePayrun
}) => {
  const [tab, setTab] = useState("Overview");
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, overflow: "hidden" }, children: [
    /* @__PURE__ */ jsx(
      PayrunHeader,
      {
        entityName,
        payrollType: CompanyPayrollType.UKPayroll,
        payrollId: localPayRun.payrollId,
        state: localPayRun.state,
        onReopenPayrun: refreshPayrun,
        sx: { mt: spacing.m20, mr: spacing.mr40 }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 } }),
    /* @__PURE__ */ jsx(PayrunTotalPaymentsSummary, { payrun: externalPayRun, payrunEmpAllowance }),
    /* @__PURE__ */ jsx(
      PayrunOverviewTabs,
      {
        tabs: PayrunOverviewTabsLabels,
        currentTab: tab,
        onChange: (tab2) => setTab(tab2),
        sx: { mt: spacing.mt30 }
      }
    ),
    tab === "Overview" && /* @__PURE__ */ jsx(
      PayrunProcessSection,
      {
        hideTitle: true,
        localPayRun,
        completePayrun: (localPayrun) => __async(void 0, null, function* () {
          const result = yield completePayrun(localPayrun);
          if (result) yield refreshPayrun();
          return result;
        }),
        sx: { mt: spacing.m10 }
      }
    ),
    tab === "Employees" && /* @__PURE__ */ jsx(
      UKPayrunSalariesSection,
      {
        hideTitle: true,
        hideButtons: true,
        localPayRun,
        entries,
        previousEntries,
        refreshPayrun
      }
    )
  ] });
};
