"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import { DeveloperHubAppDetailRouter } from "./developer-hub-app-detail.router";
import { OauthClientEndpoints } from "@/api-client/oauth-client.api";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_DETAIL_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE,
  SETTINGS_SECURITY_ROUTE
} from "@/lib/routes";
import { DeveloperHubBuildAppsPage } from "@/v2/feature/security/security-settings/features/developer-hub/developer-hub-build-apps.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const DeveloperHubPage = () => {
  const { data: oauthClients, mutate: refreshAppList } = useApiClient(OauthClientEndpoints.getAllClientsForCompany(), {
    suspense: false
  });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["company.settings:all"], path: SETTINGS_SECURITY_DEVELOPER_HUB_APP_DETAIL_ROUTE, children: /* @__PURE__ */ jsx(DeveloperHubAppDetailRouter, { refreshAppList: () => refreshAppList() }) }),
    /* @__PURE__ */ jsx(Route, { path: SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE, children: /* @__PURE__ */ jsx(DeveloperHubBuildAppsPage, { oauthClients, onUpdate: () => refreshAppList() }) }),
    /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_SECURITY_ROUTE })
  ] });
};
