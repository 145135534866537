"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import { themeColors } from "@/v2/styles/colors.styles";
export const StyledRadio = styled((props) => /* @__PURE__ */ jsx(Radio, __spreadValues({ size: "small", disableRipple: true, disableFocusRipple: true, disableTouchRipple: true }, props)))(() => ({
  "&.MuiRadio-root": {
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&:active": {
      backgroundColor: "transparent"
    },
    '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="RadioButtonUncheckedIcon"]': {
      color: themeColors.Grey
    },
    "&.Mui-disabled": {
      '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium[data-testid="RadioButtonUncheckedIcon"]': {
        opacity: 0.4
      }
    }
  },
  "&.MuiRadio-root.Mui-checked": {
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&:active": {
      backgroundColor: "transparent"
    },
    '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonUncheckedIcon"]': {
      color: themeColors.DarkGrey
    },
    '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonCheckedIcon"]': {
      color: themeColors.DarkGrey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "&.Mui-disabled": {
      '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonUncheckedIcon"]': {
        opacity: 0.4
      },
      '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[data-testid="RadioButtonCheckedIcon"]': {
        opacity: 0.4
      }
    }
  }
}));
export const StyledRadioStyle = styled("input")(() => ({
  display: "inline-block",
  padding: "0px !important",
  margin: "0px !important",
  width: "12px",
  height: "12px",
  visibility: "hidden",
  position: "absolute"
}));
