"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { IconButton } from "@mui/material";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { themeColors } from "@/v2/styles/colors.styles";
const iconSize = { width: "14px", height: "14px" };
export const ClearIconButton = (props) => {
  return /* @__PURE__ */ jsx(IconButton, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(ClearIcon, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })) }));
};
