"use strict";
export var DataDomain = /* @__PURE__ */ ((DataDomain2) => {
  DataDomain2["People"] = "People";
  DataDomain2["Payroll"] = "Payroll";
  DataDomain2["TimeAway"] = "Time Away";
  DataDomain2["Attendance"] = "Attendance";
  DataDomain2["Devices"] = "Devices";
  return DataDomain2;
})(DataDomain || {});
export const domainArray = (polyglot) => {
  return [
    { name: "People" /* People */, label: polyglot.t("domainArray.people") },
    { name: "Payroll" /* Payroll */, label: polyglot.t("domainArray.payroll") },
    { name: "Time Away" /* TimeAway */, label: polyglot.t("domainArray.timeAway") },
    { name: "Attendance" /* Attendance */, label: polyglot.t("domainArray.attendance") },
    { name: "Devices" /* Devices */, label: polyglot.t("domainArray.devices") }
  ];
};
export var ReportTypePeopleSelection = /* @__PURE__ */ ((ReportTypePeopleSelection2) => {
  ReportTypePeopleSelection2["CurrentRecords"] = "Current records";
  ReportTypePeopleSelection2["ChangeReport"] = "Change report";
  return ReportTypePeopleSelection2;
})(ReportTypePeopleSelection || {});
export const reportTypeArray = (polyglot, v2 = false) => {
  return [
    {
      name: "Current records" /* CurrentRecords */,
      desc: polyglot.t("reportTypeArray.export"),
      label: polyglot.t("reportTypeArray.current"),
      disabled: false
    },
    {
      name: "Change report" /* ChangeReport */,
      desc: polyglot.t("reportTypeArray.track"),
      label: polyglot.t("reportTypeArray.change"),
      disabled: v2
    }
  ];
};
export var ReportTypeTimeSelection = /* @__PURE__ */ ((ReportTypeTimeSelection2) => {
  ReportTypeTimeSelection2["Requests"] = "Requests";
  ReportTypeTimeSelection2["Balances"] = "Balances";
  return ReportTypeTimeSelection2;
})(ReportTypeTimeSelection || {});
export var ReportTypeDevicesSelection = /* @__PURE__ */ ((ReportTypeDevicesSelection2) => {
  ReportTypeDevicesSelection2["Devices"] = "Devices";
  return ReportTypeDevicesSelection2;
})(ReportTypeDevicesSelection || {});
export var ReportTypeAttendanceSelection = /* @__PURE__ */ ((ReportTypeAttendanceSelection2) => {
  ReportTypeAttendanceSelection2["Attendance"] = "Attendance";
  return ReportTypeAttendanceSelection2;
})(ReportTypeAttendanceSelection || {});
export var ReportEntity = /* @__PURE__ */ ((ReportEntity2) => {
  ReportEntity2["User"] = "User";
  ReportEntity2["Basic"] = "Basic";
  ReportEntity2["Details"] = "Details";
  ReportEntity2["Family"] = "Family";
  ReportEntity2["About"] = "About";
  ReportEntity2["AccountInfo"] = "Account info";
  ReportEntity2["EmergencyContact"] = "Emergency contact";
  ReportEntity2["WorkContact"] = "Work contact";
  ReportEntity2["RightToWork"] = "Right to work";
  ReportEntity2["Role"] = "Role";
  ReportEntity2["Contract"] = "Contract";
  ReportEntity2["Lifecycle"] = "Lifecycle";
  ReportEntity2["Compensation"] = "Compensation";
  ReportEntity2["BankAccount"] = "Bank account";
  ReportEntity2["Equity"] = "Equity";
  ReportEntity2["Address"] = "Address";
  ReportEntity2["Requests"] = "Requests";
  ReportEntity2["Balances"] = "Balances";
  ReportEntity2["Devices"] = "Devices";
  ReportEntity2["Attendance"] = "Attendance";
  ReportEntity2["Payroll"] = "Payroll";
  ReportEntity2["TaxInformation"] = "Tax information";
  ReportEntity2["Salary"] = "Salary";
  return ReportEntity2;
})(ReportEntity || {});
export const UserEntitiesArray = [
  { name: "Basic" /* Basic */, scope: ["user.basicInfo:all"], type: ["Current records" /* CurrentRecords */] },
  { name: "Details" /* Details */, scope: ["user.personalInfo:all"], type: ["Current records" /* CurrentRecords */] },
  { name: "Family" /* Family */, scope: ["user.family:all"], type: ["Current records" /* CurrentRecords */] },
  { name: "About" /* About */, scope: ["user.about:all"], type: ["Current records" /* CurrentRecords */] },
  {
    name: "Account info" /* AccountInfo */,
    scope: ["user.accountInfo:read:all"],
    type: ["Current records" /* CurrentRecords */]
  },
  {
    name: "Role" /* Role */,
    scope: ["user.role:all"],
    type: ["Change report" /* ChangeReport */, "Current records" /* CurrentRecords */]
  },
  {
    name: "Contract" /* Contract */,
    scope: ["user.contract:all"],
    type: ["Change report" /* ChangeReport */, "Current records" /* CurrentRecords */]
  },
  {
    name: "Lifecycle" /* Lifecycle */,
    scope: ["user.lifecycle:all"],
    type: ["Change report" /* ChangeReport */, "Current records" /* CurrentRecords */]
  },
  { name: "Right to work" /* RightToWork */, scope: ["user.rightWork:all"], type: ["Current records" /* CurrentRecords */] },
  {
    name: "Compensation" /* Compensation */,
    scope: ["user.compensation:all"],
    type: ["Change report" /* ChangeReport */, "Current records" /* CurrentRecords */]
  },
  {
    name: "Tax information" /* TaxInformation */,
    scope: ["user.compensation:all"],
    type: ["Current records" /* CurrentRecords */]
  },
  {
    name: "Bank account" /* BankAccount */,
    scope: ["user.bankAccount:all"],
    type: ["Change report" /* ChangeReport */, "Current records" /* CurrentRecords */]
  },
  { name: "Equity" /* Equity */, scope: ["user.equity:all"], type: ["Change report" /* ChangeReport */] },
  {
    name: "Address" /* Address */,
    scope: ["user.address:all"],
    type: ["Change report" /* ChangeReport */, "Current records" /* CurrentRecords */]
  },
  {
    name: "Emergency contact" /* EmergencyContact */,
    scope: ["user.emergencyContact:all"],
    type: ["Current records" /* CurrentRecords */]
  },
  { name: "Work contact" /* WorkContact */, scope: ["user.workContact:all"], type: ["Current records" /* CurrentRecords */] }
];
export var DateType = /* @__PURE__ */ ((DateType2) => {
  DateType2["Last30days"] = "last30days";
  DateType2["Last7days"] = "last7days";
  DateType2["SelectDates"] = "selectDates";
  return DateType2;
})(DateType || {});
var FilterType = /* @__PURE__ */ ((FilterType2) => {
  FilterType2["Category"] = "Category";
  FilterType2["Number"] = "Number";
  return FilterType2;
})(FilterType || {});
export const PeopleDomainReportFilters = ({
  departmentsOptions,
  sitesOptions,
  genderOptions,
  accountStatusOptions,
  jobTitlesOptions,
  nationalitiesOptions,
  employmentTypeOptions,
  contractTypeOptions,
  publicHolidaysOptions,
  entityNameOptions,
  userStatusOptions,
  userEventChangeTypeOptions,
  rightToWorkCheckedOptions,
  payScheduleOptions,
  salaryBasisOptions,
  currencyOptions,
  // "tax-information" domain
  payrollCountryOptions,
  inPayrollOptions,
  NITableOptions,
  taxCodeOptions,
  legalGenderOptions,
  studentLoanOptions,
  postgradLoanOptions,
  starterDeclarationOptions,
  // 'bank' domain
  bankNameOptions,
  bankCountryOptions,
  bankCurrencyOptions,
  // 'address' domain
  addressCityOptions,
  addressCountryOptions,
  polyglot
}) => {
  return [
    {
      name: polyglot.t("PeopleDomainReportFilters.gender"),
      domain: "Details" /* Details */,
      type: "Category" /* Category */,
      options: genderOptions,
      field: "gender"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.nationality"),
      domain: "Details" /* Details */,
      type: "Category" /* Category */,
      options: nationalitiesOptions,
      field: "nationality"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.accountStatus"),
      domain: "Account info" /* AccountInfo */,
      type: "Category" /* Category */,
      options: accountStatusOptions,
      field: "accountStatus"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.jobTitle"),
      domain: "Role" /* Role */,
      type: "Category" /* Category */,
      options: jobTitlesOptions,
      field: "jobTitle"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.departmentId"),
      domain: "Role" /* Role */,
      type: "Category" /* Category */,
      options: departmentsOptions,
      field: "departmentId"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.site"),
      domain: "Role" /* Role */,
      type: "Category" /* Category */,
      options: sitesOptions,
      field: "siteId"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.type"),
      domain: "Contract" /* Contract */,
      type: "Category" /* Category */,
      options: employmentTypeOptions,
      field: "type"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.contract"),
      domain: "Contract" /* Contract */,
      type: "Category" /* Category */,
      options: contractTypeOptions,
      field: "contract"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.publicHolidays"),
      domain: "Contract" /* Contract */,
      type: "Category" /* Category */,
      options: publicHolidaysOptions,
      field: "publicHolidays"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.entityId"),
      domain: "Contract" /* Contract */,
      type: "Category" /* Category */,
      options: entityNameOptions,
      field: "entityId"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.status"),
      domain: "Lifecycle" /* Lifecycle */,
      type: "Category" /* Category */,
      options: userStatusOptions,
      field: "status"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.changeType"),
      domain: "Lifecycle" /* Lifecycle */,
      type: "Category" /* Category */,
      options: userEventChangeTypeOptions,
      field: "changeReason"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.rightToWorkChecked"),
      domain: "Right to work" /* RightToWork */,
      type: "Category" /* Category */,
      options: rightToWorkCheckedOptions,
      field: "rightToWorkChecked"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.paySchedule"),
      domain: "Compensation" /* Compensation */,
      type: "Category" /* Category */,
      options: payScheduleOptions,
      field: "paySchedule"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.salaryBasis"),
      domain: "Compensation" /* Compensation */,
      type: "Category" /* Category */,
      options: salaryBasisOptions,
      field: "salaryBasis"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.currency"),
      domain: "Compensation" /* Compensation */,
      type: "Category" /* Category */,
      options: currencyOptions,
      field: "currency"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.inPayroll"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: inPayrollOptions,
      field: "inPayroll"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.payrollCountry"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: payrollCountryOptions,
      field: "countryCode"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.niTable"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: NITableOptions,
      field: "niTable"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.taxCode"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: taxCodeOptions,
      field: "taxCode"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.legalGender"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: legalGenderOptions,
      field: "legalGender"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.studentLoan"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: studentLoanOptions,
      field: "studentLoan"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.postgradLoan"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: postgradLoanOptions,
      field: "postgradLoan"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.starterDeclaration"),
      domain: "Tax information" /* TaxInformation */,
      type: "Category" /* Category */,
      options: starterDeclarationOptions,
      field: "starterDeclaration"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.bankName"),
      domain: "Bank account" /* BankAccount */,
      type: "Category" /* Category */,
      options: bankNameOptions,
      field: "bankName"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.country"),
      domain: "Bank account" /* BankAccount */,
      type: "Category" /* Category */,
      options: bankCountryOptions,
      field: "country"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.bankCurrency"),
      domain: "Bank account" /* BankAccount */,
      type: "Category" /* Category */,
      options: bankCurrencyOptions,
      field: "currency"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.city"),
      domain: "Address" /* Address */,
      type: "Category" /* Category */,
      options: addressCityOptions,
      field: "city"
    },
    {
      name: polyglot.t("PeopleDomainReportFilters.addressCountry"),
      domain: "Address" /* Address */,
      type: "Category" /* Category */,
      options: addressCountryOptions,
      field: "country"
    }
  ];
};
export const TimeDomainReportFilters = (policies, polyglot, statuses) => {
  return [
    {
      name: polyglot.t("TimeDomainReportFilters.policy"),
      domain: "Time",
      type: "Category" /* Category */,
      options: policies,
      field: "policy"
    },
    ...statuses ? [
      {
        name: polyglot.t("TimeDomainReportFilters.status"),
        domain: "Time",
        type: "Category" /* Category */,
        options: statuses,
        field: "status"
      }
    ] : []
  ];
};
export const AttendanceDomainReportFilters = (schedules, statuses, entities, polyglot) => {
  return [
    {
      name: polyglot.t("AttendanceDomainReportFilters.schedule"),
      domain: "Attendance",
      type: "Category" /* Category */,
      options: schedules,
      field: "schedule"
    },
    {
      name: polyglot.t("AttendanceDomainReportFilters.status"),
      domain: "Attendance",
      type: "Category" /* Category */,
      options: statuses,
      field: "status"
    },
    {
      name: polyglot.t("AttendanceDomainReportFilters.entity"),
      domain: "Attendance",
      type: "Category" /* Category */,
      options: entities,
      field: "entity"
    }
  ];
};
export const DeviceDomainReportFilters = (typeOptions, ownershipOptions, platformOptions, modelOptions, polyglot) => {
  return [
    {
      name: polyglot.t("DeviceDomainReportFilters.type"),
      domain: "Devices",
      type: "Category" /* Category */,
      options: typeOptions,
      field: "type"
    },
    {
      name: polyglot.t("DeviceDomainReportFilters.modelName"),
      domain: "Devices",
      type: "Category" /* Category */,
      options: modelOptions,
      field: "modelName"
    },
    {
      name: polyglot.t("DeviceDomainReportFilters.platform"),
      domain: "Devices",
      type: "Category" /* Category */,
      options: platformOptions,
      field: "platform"
    },
    {
      name: polyglot.t("DeviceDomainReportFilters.ownership"),
      domain: "Devices",
      type: "Category" /* Category */,
      options: ownershipOptions,
      field: "ownership"
    }
  ];
};
export var FilterTypeOption = /* @__PURE__ */ ((FilterTypeOption2) => {
  FilterTypeOption2["string"] = "string";
  FilterTypeOption2["number"] = "number";
  FilterTypeOption2["boolean"] = "boolean";
  FilterTypeOption2["intOption"] = "intOption";
  FilterTypeOption2["stringOption"] = "stringOption";
  FilterTypeOption2["dateonly"] = "dateonly";
  FilterTypeOption2["datetime"] = "datetime";
  FilterTypeOption2["time"] = "time";
  return FilterTypeOption2;
})(FilterTypeOption || {});
export var ReportSQLOperator = /* @__PURE__ */ ((ReportSQLOperator2) => {
  ReportSQLOperator2["eq"] = "=";
  ReportSQLOperator2["gte"] = ">=";
  ReportSQLOperator2["lte"] = "<=";
  ReportSQLOperator2["gt"] = ">";
  ReportSQLOperator2["lt"] = "<";
  ReportSQLOperator2["not"] = "<>";
  ReportSQLOperator2["in"] = "in";
  ReportSQLOperator2["is"] = "is";
  ReportSQLOperator2["contains"] = "contains";
  ReportSQLOperator2["notContains"] = "notContains";
  ReportSQLOperator2["between"] = "><";
  ReportSQLOperator2["isKnown"] = "!null";
  ReportSQLOperator2["isUnknown"] = "null";
  ReportSQLOperator2["startsWith"] = "startsWith";
  ReportSQLOperator2["endsWith"] = "endsWith";
  ReportSQLOperator2["this"] = "this";
  ReportSQLOperator2["last"] = "last";
  ReportSQLOperator2["next"] = "next";
  ReportSQLOperator2["dayIs"] = "dayIs";
  ReportSQLOperator2["monthIs"] = "monthIs";
  ReportSQLOperator2["yearIs"] = "yearIs";
  return ReportSQLOperator2;
})(ReportSQLOperator || {});
export var ReportColumnType = /* @__PURE__ */ ((ReportColumnType2) => {
  ReportColumnType2["PLAIN"] = "PLAIN";
  ReportColumnType2["MIN"] = "MIN";
  ReportColumnType2["MAX"] = "MAX";
  ReportColumnType2["SUM"] = "SUM";
  ReportColumnType2["AVG"] = "AVG";
  ReportColumnType2["COUNT"] = "COUNT";
  ReportColumnType2["AGE"] = "AGE";
  ReportColumnType2["DAY"] = "DAY";
  ReportColumnType2["MONTH"] = "MONTH";
  ReportColumnType2["YEAR"] = "YEAR";
  ReportColumnType2["COUNT_DAY_ONLY"] = "COUNT_D";
  ReportColumnType2["COUNT_MONTH_ONLY"] = "COUNT_M";
  ReportColumnType2["COUNT_YEAR_ONLY"] = "COUNT_Y";
  return ReportColumnType2;
})(ReportColumnType || {});
