"use strict";
export var ExternalPayRunEntryStateEnum = /* @__PURE__ */ ((ExternalPayRunEntryStateEnum2) => {
  ExternalPayRunEntryStateEnum2["Open"] = "Open";
  ExternalPayRunEntryStateEnum2["SubmittedForProcessing"] = "SubmittedForProcessing";
  ExternalPayRunEntryStateEnum2["Processing"] = "Processing";
  ExternalPayRunEntryStateEnum2["AwaitingApproval"] = "AwaitingApproval";
  ExternalPayRunEntryStateEnum2["Approved"] = "Approved";
  ExternalPayRunEntryStateEnum2["Finalised"] = "Finalised";
  return ExternalPayRunEntryStateEnum2;
})(ExternalPayRunEntryStateEnum || {});
export var ExternalGovTalkSubmissionStatuses = /* @__PURE__ */ ((ExternalGovTalkSubmissionStatuses2) => {
  ExternalGovTalkSubmissionStatuses2["notSubmitted"] = "NotSubmitted";
  ExternalGovTalkSubmissionStatuses2["submitted"] = "Submitted";
  ExternalGovTalkSubmissionStatuses2["errorResponse"] = "ErrorResponse";
  ExternalGovTalkSubmissionStatuses2["accepted"] = "Accepted";
  return ExternalGovTalkSubmissionStatuses2;
})(ExternalGovTalkSubmissionStatuses || {});
export var ExternalFPSLateReasons = /* @__PURE__ */ ((ExternalFPSLateReasons2) => {
  ExternalFPSLateReasons2["NoneGiven"] = "NoneGiven";
  ExternalFPSLateReasons2["NotionalExpat"] = "NotionalExpat";
  ExternalFPSLateReasons2["NotionalErs"] = "NotionalErs";
  ExternalFPSLateReasons2["NotionalOther"] = "NotionalOther";
  ExternalFPSLateReasons2["Class1"] = "Class1";
  ExternalFPSLateReasons2["MicroEmployer"] = "MicroEmployer";
  ExternalFPSLateReasons2["NoRequirement"] = "NoRequirement";
  ExternalFPSLateReasons2["ReasonableExcuse"] = "ReasonableExcuse";
  ExternalFPSLateReasons2["Correction"] = "Correction";
  return ExternalFPSLateReasons2;
})(ExternalFPSLateReasons || {});
export const MAX_PAYLINE_CODE_LENGTH = 20;
export const MAX_PAYLINE_DESCRIPTION_LENGTH = 100;
export const MAX_PAYROLL_ID_LENGTH = 35;
