"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsenceAPI } from "@/v2/feature/absence/absence.api";
export const UpdatePolicyInstructionsDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh, onClose }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(UpdatePolicyInstructionsDrawerContent, { absencePolicy, refresh, setIsOpen }) });
};
export const UpdatePolicyInstructionsDrawerContent = ({
  absencePolicy,
  refresh,
  setIsOpen
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!values.instructions) {
        return;
      }
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyInstructionsById(absencePolicy == null ? void 0 : absencePolicy.id, values.instructions);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [absencePolicy == null ? void 0 : absencePolicy.id, refresh, setIsOpen, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      instructions: (_a = absencePolicy == null ? void 0 : absencePolicy.instructions) != null ? _a : null
    },
    validationSchema: yup.object({
      instructions: yup.string().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AbsencePolicyGeneralSection.instructionsModalTitle") }) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "instructions",
        label: polyglot.t("AbsencePolicyGeneralSection.instructions"),
        value: formik.values.instructions,
        onChange: formik.handleChange,
        error: formik.touched.instructions && !!formik.errors.instructions,
        helperText: (_b = formik.touched.instructions && formik.errors.instructions) != null ? _b : " ",
        endAdornment: "none",
        multiline: true,
        minRows: 2
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.update"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
