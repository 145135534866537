"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as BulkUpload } from "@/images/documents/BulkUpload.svg";
import { ReactComponent as DocumentBig } from "@/images/documents/DocumentBig.svg";
import { ReactComponent as Megaphone } from "@/images/documents/Megaphone.svg";
import { ReactComponent as Upload } from "@/images/fields/Upload.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { iconSize } from "@/v2/styles/menu.styles";
export const NewDocumentButton = ({
  onUploadClick,
  onRequestClick,
  onBulkUploadClick,
  onTemplateClick,
  isAdmin,
  documentTypes
}) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const exportDataForDownload = useCallback(
    (data) => {
      const documentTypeHash = documentTypes ? Object.fromEntries(documentTypes.map(({ value, label }) => [value, label])) : {};
      const headers = [
        { label: "Name", key: "Name" },
        { label: "Type", key: "Type" },
        { label: "Upload", key: "Upload" },
        { label: "Uploaded By", key: "Uploaded By" },
        { label: "Belongs to", key: "Belongs to" },
        { label: "Status", key: "Status" },
        { label: "Signatories", key: "Signatories" }
      ];
      const rows = data.map((d) => {
        var _a, _b;
        return {
          Name: d.documentName,
          Type: (_b = (_a = documentTypeHash[d.type]) == null ? void 0 : _a.toString()) != null ? _b : "Other",
          Upload: d.uploadDate,
          "Uploaded By": d.uploadedBy,
          "Belongs to": d.belongsTo,
          Status: d.status,
          Signatories: d.signatories.map((s) => {
            var _a2;
            return (_a2 = getCachedUserById(s)) == null ? void 0 : _a2.displayName;
          }).join(" / ")
        };
      });
      return csvContent(headers, rows);
    },
    [getCachedUserById, documentTypes]
  );
  const csvContent = (headers, rows) => {
    const csvHeaders = headers.map((header) => header.label).join(",");
    const csvRows = rows.map((row) => headers.map((header) => row[header.key]).join(",")).join("\n");
    return `${csvHeaders}
${csvRows}`;
  };
  const handleDownload = useCallback(() => __async(void 0, null, function* () {
    try {
      const documentDataForExport = yield DocumentAPI.listCompanyDocumentsForExport();
      const csvContent2 = exportDataForDownload(documentDataForExport);
      const blob = new Blob([csvContent2], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents-export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(polyglot.t("DocumentExportButton.errorMessages.export"), error.message);
    }
  }), [exportDataForDownload, polyglot]);
  const newDocumentButtonOptions = useMemo(() => {
    const optionsAvailable = [];
    if (onUploadClick)
      optionsAvailable.push({
        icon: /* @__PURE__ */ jsx(Upload, __spreadValues({}, iconSize)),
        handler: () => onUploadClick(),
        label: polyglot.t("NewDocumentButton.upload"),
        disabled: false
      });
    if (onRequestClick && isAdmin)
      optionsAvailable.push({
        icon: /* @__PURE__ */ jsx(Megaphone, __spreadValues({}, iconSize)),
        handler: () => onRequestClick(),
        label: polyglot.t("NewDocumentButton.request"),
        disabled: false
      });
    if (onBulkUploadClick && isAdmin)
      optionsAvailable.push({
        icon: /* @__PURE__ */ jsx(BulkUpload, __spreadValues({}, iconSize)),
        handler: () => onBulkUploadClick(),
        label: polyglot.t("NewDocumentButton.bulkUpload"),
        disabled: false
      });
    if (onTemplateClick && isAdmin)
      optionsAvailable.push({
        icon: /* @__PURE__ */ jsx(DocumentBig, __spreadValues({}, iconSize)),
        handler: () => onTemplateClick(),
        label: polyglot.t("NewDocumentButton.template"),
        disabled: false
      });
    if (documentTypes && isAdmin)
      optionsAvailable.push({
        icon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
        handler: () => handleDownload(),
        label: polyglot.t("General.export"),
        disabled: false
      });
    return optionsAvailable;
  }, [
    polyglot,
    onUploadClick,
    onRequestClick,
    isAdmin,
    onBulkUploadClick,
    onTemplateClick,
    handleDownload,
    documentTypes
  ]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options: newDocumentButtonOptions,
      actionButtonDetails: {
        type: "button",
        colorVariant: "secondary",
        sizeVariant: "small",
        title: polyglot.t("NewDocumentButton.new"),
        icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
        iconPosition: "end"
      }
    }
  ) });
};
