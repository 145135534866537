"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { FieldCard } from "@v2/components/field-card.component";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { BenefitPaymentsDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/benefit-payments-drawer.component";
import { LoanBorrowedDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/loan-borrowed-drawer.component";
import { RepayLoanPaymentDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/repay-loan-payment-drawer.component";
import { UserBenefitRequestDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/user-benefit-request-drawer.component";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { UserCustomBenefitRequestType } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { calculatePaidAmount, isLoanBenefit } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { UserBenefitOverviewTitle } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/user-benefit-overview-title.component";
import { UserCustomBenefitRequestDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit-request-drawer.component";
import {
  UserCustomBenefitRequestStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto";
import { UserCustomBenefitListTableComponent } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-list-table.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { LocalDate } from "@v2/util/local-date";
import { generatePath, matchPath, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE, USER_BENEFITS_ROUTE } from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
export const LoanBenefitOverview = ({ userCustomBenefit, userId, refresh, loading }) => {
  var _a, _b, _c, _d;
  const {
    data: userBenefitsRequests,
    isLoading: loadingRequests,
    mutate: refreshReq
  } = useApiClient(
    CustomBenefitEndpoints.getUserCustomBenefitRequestsByBenefitId(
      userId,
      userCustomBenefit.customBenefitId,
      userCustomBenefit.id
    ),
    { suspense: false }
  );
  const [state] = useContext(GlobalContext);
  const { polyglot } = usePolyglot();
  const [isBenefitRequestOpen, setIsBenefitRequestOpen] = useState(false);
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false);
  const [isBorrowedOpen, setIsBorrowedOpen] = useState(false);
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [selectedBenefitRequest, setSelectedBenefitRequest] = useState(null);
  const [isRepayPaymentOpen, setIsRepayPaymentOpen] = useState(false);
  const { hasScopes } = useScopes();
  const location = useLocation();
  const repaid = useMemo(() => {
    var _a2, _b2;
    if (!userCustomBenefit.userCustomBenefitPayments) return 0;
    return calculatePaidAmount(
      userCustomBenefit.userCustomBenefitPayments,
      (_a2 = userCustomBenefit.customBenefit) == null ? void 0 : _a2.type,
      (_b2 = userCustomBenefit.customBenefit) == null ? void 0 : _b2.allowanceType,
      new LocalDate().toDateString()
    );
  }, [userCustomBenefit]);
  const remaining = ((_a = userCustomBenefit.openingBalance) != null ? _a : 0) - repaid;
  const pendingRequests = userBenefitsRequests == null ? void 0 : userBenefitsRequests.filter((req) => req.status === UserCustomBenefitRequestStatus.Pending);
  const approvedRequests = userBenefitsRequests == null ? void 0 : userBenefitsRequests.filter(
    (req) => req.status === UserCustomBenefitRequestStatus.Approved
  );
  const refreshRequests = useCallback(() => __async(void 0, null, function* () {
    if (refreshReq) yield refreshReq();
  }), [refreshReq]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(UserBenefitOverviewTitle, { userCustomBenefit }),
        showBack: true,
        backPath: matchPath(location.pathname, USER_BENEFITS_ROUTE) ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId }) : BENEFITS_ME_ROUTE,
        actions: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: () => {
              setIsBenefitRequestOpen(true);
            },
            children: polyglot.t("BenefitModule.newRequest")
          }
        ) }),
        showAction: hasScopes(["insurance"], { userId }) && userCustomBenefit && isLoanBenefit((_b = userCustomBenefit.customBenefit) == null ? void 0 : _b.type) && userCustomBenefit.openingBalance === null && loadingRequests === false && (!pendingRequests || pendingRequests.length === 0) && (!approvedRequests || approvedRequests.length === 0)
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { mt: "20px", mb: "10px" }, children: [
      userCustomBenefit.openingBalance !== null && /* @__PURE__ */ jsxs(Box, { sx: { width: "600px", display: "flex", gap: "5px", alignItems: "center", mb: "30px" }, children: [
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.requested"),
            value: formatMoney({
              amount: userCustomBenefit.openingBalance,
              asDecimal: true
            }),
            action: () => setIsBorrowedOpen(true),
            color: "secondary"
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "-" }),
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.repaid"),
            value: formatMoney({ amount: repaid, asDecimal: true }),
            color: "warning",
            action: () => setIsPaymentsOpen(true)
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "=" }),
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.remaining"),
            value: formatMoney({
              amount: remaining,
              asDecimal: true
            }),
            action: void 0,
            color: "success"
          }
        )
      ] }),
      pendingRequests && /* @__PURE__ */ jsx(Box, { sx: { width: "100%", mb: "20px" }, children: pendingRequests.map((request) => /* @__PURE__ */ jsx(
        FieldCard,
        {
          name: polyglot.t("BenefitModule.pendingRequest"),
          value: formatMoney({ amount: request.amount, asDecimal: true }),
          color: "warning",
          action: () => {
            setSelectedBenefitRequest(request);
            setIsRequestOpen(true);
          },
          sx: { width: "180px" }
        },
        request.id
      )) }),
      /* @__PURE__ */ jsx(
        UserCustomBenefitListTableComponent,
        {
          benefitId: userCustomBenefit.customBenefitId,
          userBenefit: userCustomBenefit,
          userId
        }
      ),
      state.showHiddenFeatures && /* @__PURE__ */ jsx(Box, { sx: { mt: "10px" }, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          onClick: () => __async(void 0, null, function* () {
            setIsRepayPaymentOpen(true);
          }),
          children: polyglot.t("BenefitModule.addRepayment")
        }
      ) }),
      /* @__PURE__ */ jsx(
        RepayLoanPaymentDrawer,
        {
          isOpen: isRepayPaymentOpen,
          setIsOpen: setIsRepayPaymentOpen,
          userCustomBenefit,
          refresh
        }
      ),
      userCustomBenefit.userCustomBenefitPayments && /* @__PURE__ */ jsx(
        BenefitPaymentsDrawer,
        {
          isOpen: isPaymentsOpen,
          setIsOpen: setIsPaymentsOpen,
          userCustomBenefitPayments: userCustomBenefit.userCustomBenefitPayments,
          type: "repaid",
          benefitType: (_c = userCustomBenefit.customBenefit) == null ? void 0 : _c.type,
          allowanceType: (_d = userCustomBenefit.customBenefit) == null ? void 0 : _d.allowanceType
        }
      ),
      /* @__PURE__ */ jsx(LoanBorrowedDrawer, { isOpen: isBorrowedOpen, setIsOpen: setIsBorrowedOpen, userBenefit: userCustomBenefit }),
      /* @__PURE__ */ jsx(
        UserBenefitRequestDrawer,
        {
          isOpen: isRequestOpen,
          setIsOpen: setIsRequestOpen,
          userBenefitRequest: selectedBenefitRequest,
          onClose: () => {
            setSelectedBenefitRequest(null);
            setIsRequestOpen(false);
          },
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refreshRequests(), refresh()]);
          })
        }
      ),
      /* @__PURE__ */ jsx(
        UserCustomBenefitRequestDrawer,
        {
          isOpen: isBenefitRequestOpen,
          setIsOpen: setIsBenefitRequestOpen,
          userBenefit: userCustomBenefit,
          onClose: () => {
            setIsBenefitRequestOpen(false);
          },
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refreshRequests(), refresh()]);
          }),
          type: UserCustomBenefitRequestType.LoanRequest
        }
      )
    ] })
  ] });
};
