"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { StepperHeader } from "@v2/components/stepper-header.component";
import { StepperComponent } from "@v2/components/stepper.component";
import { processMdmServerName } from "@v2/feature/device/device.util";
import { CustomDepTokenResult } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/custom-dep-token-result.component";
import { useDepAccountDetails } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook";
import { DownloadPublicMdmKeyComponent } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/download-public-mdm-key.component";
import { DownloadServerTokenFromAbmComponent } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/download-server-token-from-abm.component";
import { UploadMdmTokenComponent } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/upload-abm-token.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { SETTINGS_DEVICES_GENERAL_ROUTE } from "@/lib/routes";
export var CustomDEPTokenSteps = /* @__PURE__ */ ((CustomDEPTokenSteps2) => {
  CustomDEPTokenSteps2["Download_key"] = "download_key";
  CustomDEPTokenSteps2["Download_token"] = "download_token";
  CustomDEPTokenSteps2["Upload_token"] = "upload_token";
  CustomDEPTokenSteps2["Result"] = "result";
  return CustomDEPTokenSteps2;
})(CustomDEPTokenSteps || {});
export var CustomDEPTokenActions = /* @__PURE__ */ ((CustomDEPTokenActions2) => {
  CustomDEPTokenActions2["Create_token"] = "create_token";
  CustomDEPTokenActions2["Renew_token"] = "renew_token";
  return CustomDEPTokenActions2;
})(CustomDEPTokenActions || {});
const getStepLabel = (step, polyglot) => {
  if (step === "download_key" /* Download_key */) return polyglot.t("CustomDEPTokenLayout.download_key");
  if (step === "download_token" /* Download_token */) return polyglot.t("CustomDEPTokenLayout.download_token");
  if (step === "upload_token" /* Upload_token */) return polyglot.t("CustomDEPTokenLayout.upload_token");
  if (step === "result" /* Result */) return polyglot.t("CustomDEPTokenLayout.results");
  return step;
};
export const CreateCustomDepWizard = () => {
  var _a;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const { depAccountDetails } = useDepAccountDetails(
    globalState.user.company.companyId + globalState.user.company.name
  );
  const [formData, setFormData] = useState({
    mdmServerName: processMdmServerName(globalState.user.company.companyId + globalState.user.company.name),
    type: "download_key" /* Download_key */,
    action: depAccountDetails ? "renew_token" /* Renew_token */ : "create_token" /* Create_token */,
    depAccountDetails
  });
  const stepperLabels = Object.values(CustomDEPTokenSteps).filter((step2) => {
    return !depAccountDetails || step2 !== "download_key" /* Download_key */;
  }).map((step2) => {
    return getStepLabel(step2, polyglot);
  });
  const [step, setStep] = useState(
    depAccountDetails ? "download_token" /* Download_token */ : "download_key" /* Download_key */
  );
  const customDepTokenStepsComponent = useMemo(
    () => [
      ...!depAccountDetails ? [
        {
          name: "download_key" /* Download_key */,
          label: polyglot.t("CreateFlowLayout.data"),
          component: /* @__PURE__ */ jsx(
            DownloadPublicMdmKeyComponent,
            {
              onNext: () => setStep("download_token" /* Download_token */),
              setFormData,
              formData
            }
          )
        }
      ] : [],
      {
        name: "download_token" /* Download_token */,
        label: polyglot.t("CreateFlowLayout.data"),
        component: /* @__PURE__ */ jsx(
          DownloadServerTokenFromAbmComponent,
          {
            onNext: () => setStep("upload_token" /* Upload_token */),
            setFormData,
            formData
          }
        )
      },
      {
        name: "upload_token" /* Upload_token */,
        label: polyglot.t("CreateFlowLayout.data"),
        component: /* @__PURE__ */ jsx(
          UploadMdmTokenComponent,
          {
            onNext: () => setStep("result" /* Result */),
            setFormData,
            formData
          }
        )
      },
      {
        name: "result" /* Result */,
        label: polyglot.t("CreateFlowLayout.results"),
        component: /* @__PURE__ */ jsx(
          CustomDepTokenResult,
          {
            onNext: () => {
              routerHistory.push(SETTINGS_DEVICES_GENERAL_ROUTE);
            },
            setFormData,
            formData
          }
        )
      }
    ],
    [depAccountDetails, polyglot, formData, routerHistory]
  );
  const depSteps = useMemo(
    () => !depAccountDetails ? [
      "download_key" /* Download_key */,
      "download_token" /* Download_token */,
      "upload_token" /* Upload_token */,
      "result" /* Result */
    ] : ["download_token" /* Download_token */, "upload_token" /* Upload_token */, "result" /* Result */],
    [depAccountDetails]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
    /* @__PURE__ */ jsx(
      StepperHeader,
      {
        content: /* @__PURE__ */ jsx(StepperComponent, { steps: stepperLabels, activeStep: getStepLabel(step, polyglot) }),
        contentSx: { width: "80%", textAlign: "center" },
        showStepBack: true,
        stepBackAction: () => {
          if (depSteps.indexOf(step) > 0) {
            return setStep(depSteps[depSteps.indexOf(step) - 1]);
          }
          routerHistory.goBack();
        },
        stepBackSx: {
          width: "10%",
          textAlign: "left"
        },
        showBack: true,
        backAction: () => {
          routerHistory.push(SETTINGS_DEVICES_GENERAL_ROUTE);
        },
        backSx: { width: "10%", textAlign: "right" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 } }),
    (_a = customDepTokenStepsComponent[depSteps.indexOf(step)]) == null ? void 0 : _a.component
  ] });
};
