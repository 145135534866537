"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { createHeadlessForm } from "./json-schema-form";
import { useScrollToFormError } from "@/hooks/use-scroll-to-form-error.hook";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { StyledRadioGroup } from "@/v2/components/theme-components/styled-radio-group.component";
import {
  getFormikValidationHandler,
  mapSchemaFieldsToFormikObject
} from "@/v2/infrastructure/forms/remote/formik-helper.util";
import { secondaryTableSmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
const Description = ({ text, sx }) => {
  return text ? /* @__PURE__ */ jsx(Box, { sx, children: /* @__PURE__ */ jsx(
    "div",
    {
      style: __spreadProps(__spreadValues({}, themeFonts.captionSmall), { color: themeColors.DarkGrey }),
      dangerouslySetInnerHTML: { __html: text }
    }
  ) }) : /* @__PURE__ */ jsx(Fragment, {});
};
export function RemoteFormBuilder({ onSubmit, schema, children, style, values }) {
  const { fields, handleValidation } = React.useMemo(() => {
    return createHeadlessForm(
      {
        schema: schema.data,
        version: null
      },
      values != null ? values : {}
    );
  }, [schema.data, values]);
  const initialValues = React.useMemo(() => values != null ? values : mapSchemaFieldsToFormikObject(fields), [fields, values]);
  const formik = useFormik({
    initialValues,
    validate: getFormikValidationHandler(handleValidation),
    onSubmit
  });
  useScrollToFormError(formik);
  const { isSubmitting } = formik;
  const hasSubmitted = formik.submitCount > 0;
  const generateComponent = (field, prefix = "") => {
    var _a, _b;
    if (field.isVisible === false) {
      return null;
    }
    const fieldName = prefix + field.name;
    if (field.type === "select") {
      const { label, description, options } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: fieldName,
            label,
            options,
            value: (_a = formik.getFieldProps(fieldName).value) != null ? _a : "",
            onChange: formik.handleChange,
            disabled: isSubmitting,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "countries") {
      const { label, description, options } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: fieldName,
            label,
            options,
            value: (_b = formik.getFieldProps(fieldName).value) != null ? _b : "",
            onChange: formik.handleChange,
            disabled: isSubmitting,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "number") {
      const { label, description } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: fieldName,
            label,
            type: "tel",
            value: formik.getFieldProps(fieldName).value,
            onChange: (e) => {
              const n = Number(e.target.value);
              formik.setFieldValue(fieldName, Number.isNaN(n) ? e.target.value : n);
            },
            disabled: isSubmitting,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "text") {
      const { label, description } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: fieldName,
            label,
            type: "text",
            value: formik.getFieldProps(fieldName).value,
            onChange: formik.handleChange,
            disabled: isSubmitting,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "textarea") {
      const { label, description } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: label }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            multiline: true,
            name: fieldName,
            type: "text",
            value: formik.getFieldProps(fieldName).value,
            onChange: formik.handleChange,
            disabled: isSubmitting,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "radio") {
      const { label, description, options } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: label }),
        /* @__PURE__ */ jsx(Description, { text: description, sx: { mt: "5px" } }),
        /* @__PURE__ */ jsx(
          StyledRadioGroup,
          {
            name: fieldName,
            options: options != null ? options : [],
            selectedValue: formik.getFieldMeta(fieldName).value,
            onChange: formik.handleChange,
            disabled: isSubmitting,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error,
            radioSx: { paddingY: "2px" }
          }
        )
      ] }, fieldName);
    }
    if (field.type === "checkbox") {
      const { label, description } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(Description, { text: description }),
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            name: fieldName,
            label,
            value: formik.getFieldProps(fieldName).value,
            onChange: formik.handleChange,
            disabled: isSubmitting
          }
        )
      ] }, fieldName);
    }
    if (field.type === "date") {
      const {
        label,
        description
        /*, minDate */
      } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "2px" }, children: [
        /* @__PURE__ */ jsx(
          TypeableDateComponent,
          {
            name: fieldName,
            label: label + " (dd/mm/yyyy)",
            value: formik.getFieldProps(fieldName).value,
            onChange: (value) => formik.setFieldValue(fieldName, value),
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error,
            disabled: isSubmitting
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "money") {
      const { label, description } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: label }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            type: "tel",
            name: fieldName,
            value: formik.getFieldProps(fieldName).value,
            onChange: formik.handleChange,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error,
            disabled: isSubmitting
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "email") {
      const { label, description } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            type: "email",
            label,
            name: fieldName,
            value: formik.getFieldProps(fieldName).value,
            onChange: formik.handleChange,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error,
            disabled: isSubmitting
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "tel") {
      const { label, description } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            type: "tel",
            label,
            name: fieldName,
            value: formik.getFieldProps(fieldName).value,
            onChange: formik.handleChange,
            error: hasSubmitted && !!formik.getFieldMeta(fieldName).error,
            helperText: hasSubmitted && formik.getFieldMeta(fieldName).error,
            disabled: isSubmitting
          }
        ),
        /* @__PURE__ */ jsx(Description, { text: description })
      ] }, fieldName);
    }
    if (field.type === "fieldset") {
      const { label, description, fields: fields2 } = field;
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: label }),
        /* @__PURE__ */ jsx(Description, { text: description }),
        /* @__PURE__ */ jsx(Stack, { sx: { gap: "15px", mt: "10px" }, children: fields2 == null ? void 0 : fields2.map((field2) => generateComponent(field2, fieldName + ".")) })
      ] }, fieldName);
    }
    if (field.type === "group-array") {
      const { name, label, description, fields: fields2 } = field;
      const fieldName2 = prefix + name;
      const groupFields = fields2();
      const items = formik.values[fieldName2];
      return /* @__PURE__ */ jsxs(Stack, { sx: { gap: "5px" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: label }),
        /* @__PURE__ */ jsx(Description, { text: description }),
        items == null ? void 0 : items.map((_, idx) => /* @__PURE__ */ jsxs(
          Stack,
          {
            sx: {
              m: "5px 0",
              p: "10px 20px",
              gap: "30px",
              position: "relative",
              borderTop: "1px solid #ddd"
            },
            children: [
              groupFields == null ? void 0 : groupFields.map((field2) => generateComponent(field2, `${fieldName2}.${idx}.`)),
              /* @__PURE__ */ jsx(
                Button,
                {
                  sx: __spreadProps(__spreadValues({}, secondaryTableSmallBtn), { width: "min-content" }),
                  onClick: () => formik.setFieldValue(
                    fieldName2,
                    items.filter((_2, i) => i !== idx)
                  ),
                  children: "Delete"
                }
              )
            ]
          },
          fieldName2 + idx
        )),
        /* @__PURE__ */ jsx(
          Button,
          {
            sx: __spreadProps(__spreadValues({}, secondaryTableSmallBtn), { mt: "5px", width: "min-content" }),
            onClick: () => {
              formik.setFieldValue(fieldName2, [...items, {}]);
            },
            children: "Add..."
          }
        )
      ] }, fieldName2);
    }
    return /* @__PURE__ */ jsx(Typography, {
      sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.darkRed }),
      // keep this here in case a new field type appears in a schema definition
      // @ts-expect-error - Property 'type' does not exist on type 'never'
      children: `Field type '${field.type}' not supported`
    }, fieldName);
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style, children: [
    fields.map((field) => generateComponent(field)),
    children
  ] }) });
}
