"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useMemo } from "react";
import { UserAPI } from "@v2/feature/user/user.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalStateActions } from "@/GlobalState";
export const AbsenceUserFilterFeaturesUpdater = ({
  filterString,
  initialFilterString,
  globalDispatch,
  view
}) => {
  const { polyglot } = usePolyglot();
  const featureName = useMemo(() => {
    if (view === "company") return "companyFilters";
    if (view === "team") return "teamFilters";
    return "personalFilters";
  }, [view]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      if (filterString === initialFilterString) {
        return;
      }
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "timeAway",
          "requestsTable",
          featureName,
          filterString
        );
        globalDispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        console.error(polyglot.t("AbsenceUserFilterFeaturesUpdater.errorMessages.update"), error);
      }
    }))();
  }, [filterString, initialFilterString, globalDispatch, featureName, polyglot]);
  return null;
};
