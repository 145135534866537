"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
export const LegalGenderSelect = ({ gender, setGender, disabled, helperText, error }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.stateLegalGender") }),
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        value: gender || "",
        onChange: (event) => {
          setGender(event.target.value);
        },
        sx: { flexFlow: "row" },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "male",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("OnboardingByUser.male") }),
              disabled,
              sx: { width: 200 }
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "female",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              disabled,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: polyglot.t("OnboardingByUser.female") })
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ jsx("div", { style: { display: "relative" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ position: "absolute" }, themeFonts.caption), { color: error ? themeColors.red : void 0 }), children: helperText }) })
  ] }) });
};
