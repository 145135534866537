"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { TasksUserPage } from "@v2/feature/task/pages/tasks-user.page";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { TASKS_ME_OVERVIEW_ROUTE } from "@/lib/routes";
export const TaskMeRouter = () => {
  const [state] = useContext(GlobalContext);
  const { userId } = state.user;
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["task"], path: TASKS_ME_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(TasksUserPage, { userId }) }),
    /* @__PURE__ */ jsx(Redirect, { to: TASKS_ME_OVERVIEW_ROUTE })
  ] });
};
