"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import { SuperAdminBillingXeroMappingPage } from "./pages/super-admin-billing-xero-mapping.page";
import {
  SUPER_ADMIN_BILLING_V2_INVOICES_ROUTE,
  SUPER_ADMIN_BILLING_V2_ONE_OFF_CHARGES_ROUTE,
  SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE,
  SUPER_ADMIN_BILLING_V2_XERO_MAPPING_ROUTE
} from "@/lib/routes";
import { SuperAdminBillingInvoicesPage } from "@/v2/feature/super-admin/features/super-admin-billing/pages/super-admin-billing-invoices.page";
import { SuperAdminBillingCompanySubscriptionsPage } from "@/v2/feature/super-admin/features/super-admin-billing-v2/pages/super-admin-billing-company-subscriptions.page";
import { SuperAdminBillingOneOffChargesPage } from "@/v2/feature/super-admin/features/super-admin-billing-v2/pages/super-admin-billing-one-off-charges.page";
const BILLING_PAGE_CONFIG = {
  header: {
    tabs: [
      // { label: 'Plans', value: SUPER_ADMIN_BILLING_V2_PLANS_ROUTE },
      { label: "Subscriptions", value: SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE },
      { label: "Invoices", value: SUPER_ADMIN_BILLING_V2_INVOICES_ROUTE },
      { label: "One-off charges", value: SUPER_ADMIN_BILLING_V2_ONE_OFF_CHARGES_ROUTE },
      { label: "Xero Mapping", value: SUPER_ADMIN_BILLING_V2_XERO_MAPPING_ROUTE }
    ]
  }
};
export const SuperAdminBillingV2Router = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingCompanySubscriptionsPage, { pageConfig: BILLING_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_V2_INVOICES_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingInvoicesPage, { pageConfig: BILLING_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_V2_ONE_OFF_CHARGES_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingOneOffChargesPage, { pageConfig: BILLING_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_V2_XERO_MAPPING_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingXeroMappingPage, { pageConfig: BILLING_PAGE_CONFIG }) }),
    /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_BILLING_V2_SUBSCRIPTIONS_ROUTE })
  ] });
};
