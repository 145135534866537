"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { InfoCard } from "@v2/feature/absence/components/info-card.component";
import { AccountingTransactionsAPI } from "@v2/feature/payments/features/accounting-transaction/accounting-transactions.api";
import { WithdrawDrawer } from "@v2/feature/payments/features/accounting-transaction/components/withdraw-drawer.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { secondaryTableSmallBtn } from "@v2/styles/buttons.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ContractorOverviewCard = () => {
  const [balance, setBalance] = useState(0);
  const [receivables, setReceivables] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const getUserBalance = useCallback(() => __async(void 0, null, function* () {
    try {
      const balance2 = yield AccountingTransactionsAPI.getUserBalance(state.user.userId);
      setBalance(balance2);
    } catch (error) {
      showMessage(`Could not read user balance. ${nestErrorMessage(error)}`, "error");
    }
  }), [state.user.userId, showMessage]);
  const getUserReceivables = useCallback(() => __async(void 0, null, function* () {
    try {
      const receivables2 = yield AccountingTransactionsAPI.getUserReceivables(state.user.userId);
      setReceivables(receivables2);
    } catch (error) {
      showMessage(`Could not read user receivables. ${nestErrorMessage(error)}`, "error");
    }
  }), [state.user.userId, showMessage]);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    yield Promise.all([getUserBalance(), getUserReceivables()]);
    setLoading(false);
  }), [getUserBalance, getUserReceivables]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap60 }, children: [
    /* @__PURE__ */ jsx(InfoCard, { title: "Receivable", value: loading ? "loading..." : formatMoney({ amount: receivables }) }),
    /* @__PURE__ */ jsx(
      InfoCard,
      {
        title: "Balance",
        value: loading ? "loading..." : formatMoney({ amount: balance }),
        action: /* @__PURE__ */ jsx(Button, { sx: secondaryTableSmallBtn, onClick: () => setIsDrawerOpen(true), children: "Withdraw" })
      }
    ),
    isDrawerOpen && /* @__PURE__ */ jsx(WithdrawDrawer, { isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen, balance, refresh })
  ] });
};
