"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Form, FormikProvider, useFormik } from "formik";
import { OauthClientAPI } from "@/api-client/oauth-client.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ViewerItem } from "@/v2/feature/payments/components/payment-details-drawer.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PublishRequestsApproveRejectDrawerForm = ({ initialValues, onClose }) => {
  var _a, _b;
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {
    }
  });
  const approveRequest = (companyId, id) => __async(void 0, null, function* () {
    setLoading(true);
    try {
      yield OauthClientAPI.approvePublishRequest(companyId, id, formik.values.reviewNotes);
      showMessage(`Request approved successfully`, "success");
      onClose();
    } catch (error) {
      showMessage(`Failed to approve request: ${nestErrorMessage(error)}; Please try again.`, "error");
    } finally {
      setLoading(false);
    }
  });
  const rejectRequest = (companyId, id) => __async(void 0, null, function* () {
    setLoading(true);
    try {
      yield OauthClientAPI.rejectPublishRequest(companyId, id, formik.values.reviewNotes);
      showMessage(`Request rejected successfully`, "success");
      onClose();
    } catch (error) {
      showMessage(`Failed to rejected request: ${nestErrorMessage(error)}; Please try again.`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Request details" }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Request ID:", value: formik.values.id }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Client ID:", value: formik.values.clientId }),
    /* @__PURE__ */ jsx(ViewerItem, { title: "Company:", value: (_b = (_a = formik.values.company) == null ? void 0 : _a.name) != null ? _b : "" }),
    /* @__PURE__ */ jsx(
      ViewerItem,
      {
        title: "Scope reasons:",
        value: /* @__PURE__ */ jsx(Box, { sx: { ml: 2 }, children: Object.entries(formik.values.scopeReasons).map(([scope, reason]) => /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          /* @__PURE__ */ jsxs("strong", { children: [
            scope,
            ":"
          ] }),
          "\xA0",
          /* @__PURE__ */ jsx("em", { children: reason })
        ] }, scope)) })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.g10 }, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Reviewer Notes",
        name: "reviewNotes",
        value: formik.values.reviewNotes,
        onChange: formik.handleChange,
        fullWidth: true,
        multiline: true,
        rows: 4,
        placeholder: "Add notes for approval or rejection (optional)"
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Reject",
          fullWidth: true,
          loading,
          onClick: () => rejectRequest(formik.values.companyId, formik.values.id),
          sizeVariant: "medium",
          colorVariant: "danger"
        },
        "reject"
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Approve",
          fullWidth: true,
          loading,
          onClick: () => approveRequest(formik.values.companyId, formik.values.id),
          sizeVariant: "medium",
          colorVariant: "primary"
        },
        "approve"
      )
    ] })
  ] }) }) });
};
