"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { QuestionTypeMap } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { replaceParamsInQuestionText, stripHtml } from "@/v2/util/string.util";
export const SurveyQuestionBuilder = ({
  questionOptions,
  question,
  growthFactors,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  template,
  showAction
}) => {
  var _a, _b, _c, _d, _e;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : void 0;
  const cleanedQuestionText = (_c = replaceParamsInQuestionText(stripHtml(question.questionText), { company_name })) != null ? _c : "";
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        bgcolor: themeColors.white,
        p: spacing.p16,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: `1px solid ${themeColors.lightGrey}`,
        borderBottom: `1px solid ${themeColors.lightGrey}`
      },
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g8
            },
            children: [
              /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.g4
                  },
                  children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: cleanedQuestionText })
                }
              ),
              /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.g4
                  },
                  children: [
                    question.factor && /* @__PURE__ */ jsx(
                      ChipComponent,
                      {
                        backgroundColor: "white",
                        border: "background",
                        name: /* @__PURE__ */ jsxs(
                          Box,
                          {
                            sx: {
                              display: "flex",
                              alignItems: "center",
                              gap: spacing.g4
                            },
                            children: [
                              /* @__PURE__ */ jsx(
                                "div",
                                {
                                  style: {
                                    backgroundColor: (_e = (_d = growthFactors[question.factor]) == null ? void 0 : _d.color) != null ? _e : themeColors.Grey,
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: radius.br25
                                  }
                                }
                              ),
                              /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: question.factor })
                            ]
                          }
                        )
                      }
                    ),
                    question.type && /* @__PURE__ */ jsx(
                      ChipComponent,
                      {
                        backgroundColor: "white",
                        border: "background",
                        name: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: QuestionTypeMap[question.type] })
                      }
                    ),
                    question.type && question.type === "scaleQuestion" && question.scaleConfig && /* @__PURE__ */ jsx(
                      ChipComponent,
                      {
                        backgroundColor: "white",
                        border: "background",
                        name: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: question.scaleConfig.type })
                      }
                    ),
                    question.hasComment && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.Grey }, children: "Comment" })
                  ]
                }
              )
            ]
          }
        ),
        showAction && /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: questionOptions,
            actionButtonDetails: {
              type: "iconButton",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "actions",
              icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
            }
          }
        )
      ]
    }
  );
};
