"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import "@/v2/feature/dashboard/features/sections/user-todos/user-todos.scss";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const TodoCard = ({ showBorder = true, icon, title, actionOnclick, userAvatar }) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: "clickable todo-item",
      style: {
        borderBottom: showBorder ? "none" : "none",
        borderRadius: radius.br5,
        height: "30px"
      },
      onClick: actionOnclick,
      children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          },
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center", width: "84%" }, children: [
              /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    alignItems: "center"
                  },
                  children: icon
                }
              ),
              /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                  },
                  children: title
                }
              )
            ] }),
            /* @__PURE__ */ jsx(Box, { sx: { width: "10%", display: "flex", justifyContent: "flex-end", alignItems: "center" }, children: userAvatar })
          ]
        }
      ) })
    }
  );
};
