"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PublicHolidaysAPI {
  static handleUserDeletionApprovalByRequestId(userId, requestId, status) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/public-holidays-deleted/${requestId}/users/${userId}/approval`, { action: status });
    });
  }
  static handleUserDeletionForceApprovalByRequestId(userId, requestId, status) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/public-holidays-deleted/${requestId}/users/${userId}/force-approval`, { action: status });
    });
  }
  static requestDeletionOfPublicHoliday(userId, policyId, date) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/public-holidays-deleted/users/${userId}/new-request`, { policyId, date });
    });
  }
  static deletePHDeletionRequest(userId, requestId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/public-holidays-deleted/${requestId}/users/${userId}`);
    });
  }
}
