"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import "@/v2/components/list-view/list-view.scss";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { PaginationDetail } from "@/v2/components/table/pagination-detail.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ListView = ({
  list,
  pagination,
  clickAction,
  loading,
  selected,
  hideCicularLoader = false
}) => {
  const { polyglot } = usePolyglot();
  const isNextDisabled = pagination ? pagination.pageIndex >= Math.ceil(pagination.total / pagination.pageSize) - 1 : true;
  const isPrevDisabled = pagination ? pagination.pageIndex <= 0 : true;
  const handleNext = () => {
    if (pagination && !isNextDisabled) {
      pagination.onPageChange(pagination.pageIndex + 1, pagination.pageSize);
    }
  };
  const handlePrevious = () => {
    if (pagination && !isPrevDisabled) {
      pagination.onPageChange(pagination.pageIndex - 1, pagination.pageSize);
    }
  };
  return /* @__PURE__ */ jsx("div", { children: loading ? /* @__PURE__ */ jsx(ListViewItemLoader, { hideCicularLoader }) : /* @__PURE__ */ jsxs(Fragment, { children: [
    list.map((l, i) => /* @__PURE__ */ jsx(
      ListViewItem,
      {
        name: polyglot.t(l.name),
        details: l.details,
        avatar: l.avatar,
        id: l.id,
        showAlert: l.showAlert,
        alertColor: l.alertColor,
        clickAction,
        selected,
        highlightTitle: l.highlightTitle
      },
      i
    )),
    pagination && /* @__PURE__ */ jsx(
      PaginationDetail,
      {
        totalPageCount: pagination.total,
        totalRecordCount: pagination.totalItems,
        current: pagination.pageIndex,
        onNextAction: handleNext,
        nextDisabled: pagination.pageIndex === pagination.total,
        onPreviousAction: handlePrevious,
        previousDisabled: pagination.pageIndex === 1,
        handleState: (page) => pagination.onPageChange(page, pagination.pageSize),
        paginationState: { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize },
        setPaginationState: (pageSize, pageIndex) => {
          pagination.onPageChange(pageIndex, pageSize);
        },
        showCompact: true
      }
    )
  ] }) });
};
const ListViewItem = ({
  avatar,
  name,
  id,
  details,
  clickAction,
  selected,
  showAlert,
  alertColor,
  highlightTitle
}) => {
  return /* @__PURE__ */ jsx("div", { className: "view-item-box", children: /* @__PURE__ */ jsx("div", { onClick: () => clickAction(id), role: "button", children: /* @__PURE__ */ jsx("div", { className: selected === id ? "view-item-padding-active" : "view-item-padding", children: /* @__PURE__ */ jsxs("div", { className: "view-item-content", children: [
    /* @__PURE__ */ jsxs("div", { className: "view-item-detail", children: [
      avatar,
      /* @__PURE__ */ jsxs("div", { className: "item-title-detail", children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            variant: highlightTitle ? "title4" : "caption",
            sx: {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            },
            children: name
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: details })
      ] })
    ] }),
    showAlert && /* @__PURE__ */ jsx(
      "div",
      {
        className: "Ellipse190",
        style: { width: "10px", height: "10px", background: alertColor, borderRadius: radius.br20 }
      }
    )
  ] }) }) }) });
};
const ListViewItemLoader = ({ hideCicularLoader }) => {
  return /* @__PURE__ */ jsx(Fragment, { children: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10].map((num) => /* @__PURE__ */ jsx("div", { style: { height: "55px", paddingLeft: "20px", paddingRight: "20px" }, children: /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center", height: "100%" }, children: [
    !hideCicularLoader && /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "circular",
        width: "35px",
        height: "35px",
        sx: { background: themeColors.Background, marginRight: "16px" }
      }
    ),
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          gap: spacing.g5
        },
        children: [
          /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "30%",
              height: "16px",
              sx: { background: themeColors.Background, borderRadius: radius.br10 }
            }
          ),
          /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "80%",
              height: "12px",
              sx: { background: themeColors.Background, borderRadius: radius.br10 }
            }
          )
        ]
      }
    )
  ] }) }, num)) });
};
