"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Stack } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_MONEY_SALARY_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { PayrollLocalApi } from "@/v2/feature/payroll/payroll-local.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { StyledSwipeableDrawer } from "@/v2/styles/sidebar-edit.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollDeleteSection = ({ payroll, sx, refreshPayroll }) => {
  const { polyglot } = usePolyglot();
  const [showDeletePayrollDrawer, setShowDeletePayrollDrawer] = useState(false);
  const [typedEntityName, setTypedEntityName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const handleDelete = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsDeleting(true);
      yield PayrollLocalApi.deletePayroll(payroll.id);
      yield refreshPayroll();
      showMessage("Payroll removed successfully.", "success");
      routerHistory.push(SETTINGS_MONEY_SALARY_ROUTE);
    } catch (error) {
      showMessage(`Payroll deletion failed. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDeleting(false);
    }
  }), [payroll, refreshPayroll, routerHistory, setIsDeleting, showMessage]);
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { children: "Delete payroll" }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      "If you want to permanently delete this payroll and all of its data, including payslips and reports, you can do so\xA0",
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "link",
          colorVariant: "text",
          style: { color: themeColors.RedDark, display: "inline" },
          onClick: () => setShowDeletePayrollDrawer(true),
          children: "here"
        }
      ),
      "."
    ] }),
    /* @__PURE__ */ jsx(
      StyledSwipeableDrawer,
      {
        anchor: "right",
        onClose: () => setShowDeletePayrollDrawer(false),
        onOpen: () => {
        },
        open: showDeletePayrollDrawer,
        children: showDeletePayrollDrawer && /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ width: "400px", padding: "20px", gap: spacing.g20 }, drawerContentSx), children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Delete payroll" }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Please type the payroll entity name to confirm." }),
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "entity-name",
              value: typedEntityName,
              onChange: (e) => setTypedEntityName(e.target.value),
              autoFocus: true,
              disabled: isDeleting
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "danger",
              fullWidth: true,
              loading: isDeleting,
              disabled: typedEntityName !== payroll.entity.legalName,
              onClick: () => handleDelete(),
              children: polyglot.t("General.delete")
            }
          ) })
        ] })
      }
    )
  ] });
};
