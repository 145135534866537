"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { DeviceNotesComponent } from "@v2/feature/device/features/device-cards/components/device-notes.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { RootStyle } from "@/v2/styles/root.styles";
export const DeviceNotesPage = ({ devicePossession, refresh }) => {
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: "Notes" }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsx(DeviceNotesComponent, { devicePossession, refresh }) })
  ] });
};
