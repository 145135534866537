"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortBoolean } from "@v2/components/table/table-sorting.util";
import { spacing } from "@v2/styles/spacing.styles";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
export const InstalledApplications = ({ applications, loading }) => {
  const [searchInput, setSearchInput] = useState("");
  const filteredApps = useMemo(() => {
    var _a;
    return (_a = applications == null ? void 0 : applications.filter((app) => {
      return app.name.toLowerCase().includes(searchInput.toLowerCase());
    })) != null ? _a : [];
  }, [applications, searchInput]);
  const applicationsTableColumns = useMemo(
    () => [
      {
        header: () => "Name",
        accessorFn: (row) => row,
        id: "name",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: (original == null ? void 0 : original.name) ? original.name : /* @__PURE__ */ jsx(EmptyCell, {}) }),
        size: 145
      },
      {
        header: () => "Manged",
        accessorFn: (row) => row,
        id: "manged",
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.managed),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: (original == null ? void 0 : original.managed) ? "True" : /* @__PURE__ */ jsx(EmptyCell, {}) }),
        size: 145
      },
      {
        header: () => "Version",
        accessorFn: (row) => row,
        id: "version",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: (original == null ? void 0 : original.version) ? original.version : /* @__PURE__ */ jsx(EmptyCell, {}) }),
        size: 145
      }
    ],
    []
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          gap: "5px",
          alignItems: "center"
        },
        children: /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        )
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredApps,
        initialSort: [{ id: "manged", desc: true }],
        columnData: applicationsTableColumns,
        loading,
        hidePagination: true
      }
    ) })
  ] });
};
