"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl, IconButton, Typography } from "@mui/material";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { actionIconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CalendarAPI } from "@/v2/feature/calendar/calendar.api";
import {
  AfternoonOnly,
  colorOptions,
  fullOrAfternoonOnlyValues,
  fullOrHalfDayValues,
  fullOrMorningOnlyValues,
  MorningOnly
} from "@/v2/feature/calendar/calendar.interface";
import { buttonBoxSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CalendarEventSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().nullable().notRequired(),
  startDate: Yup.string().test(dateFieldTest).required("Required"),
  endDate: Yup.string().test(dateFieldTest).nullable().notRequired(),
  startFullOrHalfDay: Yup.string().required("Full or Half Day is required"),
  endFullOrHalfDay: Yup.string(),
  multipleDays: Yup.boolean()
});
export const AddEventModal = ({ initialValues, onClose }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CalendarEventSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2;
      setLoading(true);
      try {
        const morningOnly = Boolean(
          !values.endDate && values.startFullOrHalfDay === MorningOnly(polyglot).value || values.endDate && values.endFullOrHalfDay === MorningOnly(polyglot).value
        );
        const afternoonOnly = values.startFullOrHalfDay === AfternoonOnly(polyglot).value;
        const payload = {
          name: values.name,
          description: values.description,
          afternoonOnly,
          morningOnly,
          startDate: values.startDate,
          endDate: values.multipleDays && values.endDate ? values.endDate : null,
          blockTimeawayRequests: values.blockTimeawayRequests,
          color: (_a2 = values.color) != null ? _a2 : "#F5F5F5"
        };
        if (initialValues == null ? void 0 : initialValues.id) {
          yield CalendarAPI.updateCalendarEvent(payload, initialValues.id);
        } else {
          yield CalendarAPI.createCalendarEvent(payload);
        }
        onClose();
      } catch (error) {
        showMessage(polyglot.t("AddEventModal.errorMessages.create"), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadValues({
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center"
        }, spacing.mb20),
        children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: (initialValues == null ? void 0 : initialValues.id) ? polyglot.t("AddEventModal.editEvent") : polyglot.t("AddEventModal.newEvent") }),
          (initialValues == null ? void 0 : initialValues.id) && /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: iconButtonSx,
              onClick: () => __async(void 0, null, function* () {
                if (initialValues == null ? void 0 : initialValues.id) {
                  yield CalendarAPI.deleteCalendarEvent(initialValues == null ? void 0 : initialValues.id);
                  onClose();
                  showMessage(polyglot.t("AddEventModal.successMessages.delete"), "success");
                }
              }),
              children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(Box, { sx: { width: "20%" }, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "color",
            label: polyglot.t("AddEventModal.color"),
            options: colorOptions,
            value: formik.values.color,
            compareValue: formik.values.color,
            error: !!formik.errors.color && formik.touched.color,
            onChange: formik.handleChange,
            helperText: formik.errors.color && formik.touched.color
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { width: "80%" }, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "name",
            label: polyglot.t("AddEventModal.name"),
            value: formik.values.name,
            type: "text",
            onChange: formik.handleChange,
            error: formik.touched.name && !!formik.errors.name,
            helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " ",
            clearText: () => formik.setFieldValue("name", "")
          }
        ) })
      ] }) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          multiline: true,
          name: "description",
          label: polyglot.t("AddEventModal.description"),
          value: formik.values.description,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.description && !!formik.errors.description,
          helperText: (_b = formik.touched.description && formik.errors.description) != null ? _b : " ",
          clearText: () => formik.setFieldValue("description", "")
        }
      ) }),
      /* @__PURE__ */ jsxs(Box, { sx: { minHeight: 20, mb: spacing.m5, display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            name: "multipleDays",
            label: polyglot.t("AddEventModal.multipleDays"),
            checked: formik.values.multipleDays,
            onChange: (_, checked) => {
              const isChecked = checked;
              formik.setFieldValue("multipleDays", checked);
              if (!isChecked) {
                formik.setFieldValue("endDate", null);
              } else {
                const nextDate = new LocalDate(formik.values.startDate);
                nextDate.getDate().setDate(nextDate.getDate().getDate() + 1);
                formik.setFieldValue("endDate", nextDate.toDateString());
              }
            }
          }
        ),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }) })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_c = formik.values.startDate) != null ? _c : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("startDate", value);
            }
          },
          name: "startDate",
          label: polyglot.t("AddEventModal.startDate"),
          error: !!formik.errors.startDate && Boolean(formik.touched.startDate),
          helperText: formik.errors.startDate && Boolean(formik.touched.startDate)
        }
      ) }) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "startFullOrHalfDay",
          label: polyglot.t("AddEventModal.startFullOrHalfDay"),
          options: formik.values.multipleDays ? fullOrAfternoonOnlyValues(polyglot) : fullOrHalfDayValues(polyglot),
          value: formik.values.startFullOrHalfDay,
          compareValue: formik.values.startFullOrHalfDay,
          error: !!formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay,
          onChange: formik.handleChange,
          helperText: formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay
        }
      ) }),
      formik.values.multipleDays && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            inputFormat: "DD/MM/YYYY",
            value: (_d = formik.values.endDate) != null ? _d : null,
            onChange: (value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue("endDate", value);
              }
            },
            label: polyglot.t("AddEventModal.endDate"),
            name: "endDate",
            error: !!formik.errors.endDate && Boolean(formik.touched.endDate),
            helperText: formik.errors.endDate && Boolean(formik.touched.endDate)
          }
        ) }) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "endFullOrHalfDay",
            label: polyglot.t("AddEventModal.endFullOrHalfDay"),
            options: formik.values.multipleDays ? fullOrMorningOnlyValues(polyglot) : fullOrHalfDayValues(polyglot),
            value: formik.values.endFullOrHalfDay,
            compareValue: formik.values.endFullOrHalfDay,
            error: !!formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay,
            onChange: formik.handleChange,
            helperText: formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { minHeight: 20, mb: spacing.m5, display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            name: "blockTimeawayRequests",
            label: polyglot.t("AddEventModal.blockTimeawayRequests"),
            checked: formik.values.blockTimeawayRequests,
            onChange: (_, checked) => {
              formik.setFieldValue("blockTimeawayRequests", checked);
            }
          }
        ),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }) })
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) }) });
};
