"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Avatar, Box } from "@mui/material";
import { TableSearch } from "@v2/components/table/table-search.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { NewIdCheckPackageDrawer } from "@/v2/feature/id-check/components/new-id-check-package-drawer.page";
import { IdCheckEndpoints } from "@/v2/feature/id-check/id-check.api";
import { mapPackageDetailsToString } from "@/v2/feature/id-check/id-check.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { formatAsGBPCurrency } from "@/v2/util/string-format.util";
const zeltLogo = "/app-icons-v2/images/zelt.png";
export const IdCheckSettingsPage = () => {
  const {
    data: idCheckPackages,
    isLoading: loadingIdCheckPackages,
    mutate: refreshIdCheckPackages
  } = useApiClient(IdCheckEndpoints.getIdCheckPackages(), { suspense: false });
  const {
    data: individualChecks,
    isLoading: loadingIndividualChecks,
    mutate: refreshIndividualChecks
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshIdCheckPackages) yield refreshIdCheckPackages();
    if (refreshIndividualChecks) yield refreshIndividualChecks();
  }), [refreshIdCheckPackages, refreshIndividualChecks]);
  const filteredPackages = useMemo(() => {
    if (!idCheckPackages) return [];
    if (!searchInput) return idCheckPackages.sort((a, b) => a.id - b.id);
    const search = searchInput.toLowerCase();
    return idCheckPackages.sort((a, b) => a.id - b.id).filter((checkPackage) => checkPackage.name.toLowerCase().includes(search));
  }, [searchInput, idCheckPackages]);
  const isLoading = loadingIdCheckPackages || loadingIndividualChecks;
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoading, sx: {}, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("SettingsRouterPageConfig.idCheckPackages"),
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "primary",
              sizeVariant: "small",
              onClick: () => {
                setSelectedPackage(null);
                setIsDrawerOpen(true);
              },
              children: polyglot.t("IdCheckPackage.newPackage")
            }
          )
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          setSearchInput(e.target.value);
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: filteredPackages.map((item) => {
                      const { packageDetails } = item;
                      const usesCustomPackage = item && packageDetails ? "customPackage" in packageDetails : false;
                      const packageName = !usesCustomPackage && item.packageDetails ? mapPackageDetailsToString(item.packageDetails, polyglot) : item.name;
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: item.name,
                          description: packageName,
                          boxSx: responsiveCardStyle,
                          contentItemsSets: [
                            {
                              type: ContentItemType.component,
                              contentComponent: /* @__PURE__ */ jsx(
                                ChipComponent,
                                {
                                  name: !item.createdBy ? /* @__PURE__ */ jsxs(
                                    Box,
                                    {
                                      sx: {
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        gap: spacing.s1
                                      },
                                      children: [
                                        /* @__PURE__ */ jsx(Avatar, { src: zeltLogo, alt: "zelt", style: iconSize }),
                                        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Zelt" })
                                      ]
                                    }
                                  ) : /* @__PURE__ */ jsx(UserCell, { userId: item.createdBy, nameColor: "DarkGrey" }),
                                  backgroundColor: "white",
                                  textColor: "DarkGrey",
                                  textVariant: "caption",
                                  border: "middle"
                                }
                              )
                            },
                            {
                              name: `${formatAsGBPCurrency(item.price, 2)}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ],
                          boxAction: () => {
                            setSelectedPackage(item);
                            setIsDrawerOpen(true);
                          }
                        },
                        item.name
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    isDrawerOpen && /* @__PURE__ */ jsx(
      NewIdCheckPackageDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        currentUser,
        idCheckPackage: selectedPackage,
        individualChecks: individualChecks != null ? individualChecks : [],
        refresh
      }
    )
  ] });
};
