"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback, useMemo } from "react";
import { AccountStatus } from "@/lib/users";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
const defaultConfig = {
  name: "",
  emailAddress: "",
  startDate: "",
  leaveDate: null,
  accountStatus: AccountStatus.Created,
  contractType: "",
  inPayroll: false,
  inGlobalPayroll: false,
  inGlobalPayrollPast: false,
  inPension: false,
  inInsurance: false,
  inCustomBenefits: false,
  companyInPayroll: false,
  companyInPension: false,
  default: true,
  currency: null
};
export const useUserProfileConfig = (userId) => {
  const { getCachedUserById } = useCachedUsers();
  const { data, mutate, isValidating } = useApiClient(UserEndpoints.getUserOverviewConfig(userId), { suspense: false });
  const defaultOverviewConfig = useMemo(() => {
    var _a, _b, _c;
    const userData = getCachedUserById(userId);
    if (userData)
      return {
        startDate: userData.startDate,
        accountStatus: userData.accountStatus,
        emailAddress: userData.emailAddress,
        name: `${userData.firstName} ${userData.lastName}`,
        inPayroll: false,
        inGlobalPayroll: false,
        inGlobalPayrollPast: false,
        inPension: false,
        inInsurance: false,
        inCustomBenefits: false,
        companyInPayroll: false,
        companyInPension: false,
        default: false,
        currency: (_c = (_b = (_a = userData.userContract) == null ? void 0 : _a.entity) == null ? void 0 : _b.currency) != null ? _c : null
      };
    return defaultConfig;
  }, [getCachedUserById, userId]);
  const refreshUserOverviewConfig = useCallback(() => __async(void 0, null, function* () {
    if (mutate) yield mutate();
  }), [mutate]);
  return {
    userOverviewConfig: data ? __spreadProps(__spreadValues({}, data), { default: false }) : defaultOverviewConfig,
    refreshUserOverviewConfig,
    loading: isValidating
  };
};
