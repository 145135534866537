"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { UserSelect } from "@v2/components/user-select-type/user-select.component";
import { InsuranceAPI, InsuranceEndpoints } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const InsuranceAddToPolicyDrawer = ({
  isOpen,
  setIsOpen,
  insurancePolicy,
  refresh,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(InsuranceAddToPolicyDrawerContent, { insurancePolicy, refresh, onClose })
    }
  ) });
};
const InsuranceAddToPolicyDrawerContent = ({
  insurancePolicy,
  refresh,
  onClose
}) => {
  var _a;
  const { data: userInsuranceRecords } = useApiClient(
    InsuranceEndpoints.getAllUsersInsurancePolicyById(insurancePolicy.id)
  );
  const { polyglot } = usePolyglot();
  const { nonTerminatedCachedUsers } = useCachedUsers({ refresh: true });
  const usersWithoutInsurance = useMemo(() => {
    var _a2;
    return (_a2 = nonTerminatedCachedUsers == null ? void 0 : nonTerminatedCachedUsers.filter(
      (user) => !userInsuranceRecords || !userInsuranceRecords.find((record) => record.userId === user.userId && !!record.status)
    )) != null ? _a2 : [];
  }, [nonTerminatedCachedUsers, userInsuranceRecords]);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      userIds: [],
      startDate: insurancePolicy.startDate
    },
    validationSchema: yup.object({
      userIds: yup.array().of(yup.number().required(polyglot.t("ValidationMessages.requiredField"))).min(1, polyglot.t("BenefitModule.atLeast1Employee")),
      startDate: yup.string().test(dateFieldTest).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => addToPolicy(values, insurancePolicy.id)
  });
  const addToPolicy = (values, insurancePolicyId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const { userIds, startDate } = values;
      const data = {
        userIds,
        startDate
      };
      yield InsuranceAPI.addUsersToPolicy(insurancePolicyId, data);
      yield refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("BenefitModule.addToPolicy") }),
    /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("UserSelect.whoSelect"),
        selectedLabel: polyglot.t("BenefitModule.selectedEmployees"),
        value: formik.values.userIds,
        onChange: (userIds) => {
          formik.setFieldValue("userIds", userIds);
        },
        userOptions: usersWithoutInsurance.map((u) => {
          return {
            label: polyglot.t(u.displayName),
            value: u.userId
          };
        })
      }
    ),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "startDate",
        label: polyglot.t("BenefitModule.policyStartDate"),
        inputFormat: "DD/MM/YYYY",
        value: (_a = formik.values.startDate) != null ? _a : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("startDate", value);
          }
        },
        error: !!formik.errors.startDate && Boolean(formik.touched.startDate),
        helperText: formik.errors.startDate && Boolean(formik.touched.startDate)
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.add"),
        loading,
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) });
};
