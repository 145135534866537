"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { StepperHeader } from "@v2/components/stepper-header.component";
import { StepperComponent } from "@v2/components/stepper.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { InsuranceSetupDataSharingStep } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-data-sharing-step.component";
import { InsuranceSetupEmployeesStep } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-employees-step.component";
import { InsuranceSetupExploreStep } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-explore-step.component";
import { InsuranceSetupQuoteStep } from "@v2/feature/benefits/subfeature/benefits-settings/new-benefit/new-insurance/insurance-setup-steps/insurance-setup-quote-step.component";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { SETTINGS_BENEFITS_ROUTE } from "@/lib/routes";
var InsuranceSetupSteps = /* @__PURE__ */ ((InsuranceSetupSteps2) => {
  InsuranceSetupSteps2["Explore"] = "Explore";
  InsuranceSetupSteps2["Quote"] = "Quote";
  InsuranceSetupSteps2["Employees"] = "Employees";
  InsuranceSetupSteps2["DataSharing"] = "Data sharing";
  return InsuranceSetupSteps2;
})(InsuranceSetupSteps || {});
export const InsuranceSetupStepper = ({ name, refresh }) => {
  var _a;
  const [quoteData, setQuoteData] = useState({
    outPatientTreatment: false,
    therapies: false,
    mentalHealth: false,
    dentalCare: false,
    opticalCare: false,
    travelCover: false,
    additionalNotes: null,
    displayName: name,
    selectedUsers: []
  });
  const routerHistory = useHistory();
  const insuranceQuoteSteps = useMemo(
    () => [
      "Explore" /* Explore */,
      "Quote" /* Quote */,
      "Employees" /* Employees */,
      "Data sharing" /* DataSharing */
    ],
    []
  );
  const [step, setStep] = useState("Explore" /* Explore */);
  const insuranceQuoteStepsComponent = useMemo(
    () => [
      {
        name: "Explore" /* Explore */,
        component: /* @__PURE__ */ jsx(InsuranceSetupExploreStep, { name, onNext: () => setStep("Quote" /* Quote */) })
      },
      {
        name: "Quote" /* Quote */,
        component: /* @__PURE__ */ jsx(
          InsuranceSetupQuoteStep,
          {
            name,
            onNext: () => setStep("Employees" /* Employees */),
            setQuoteData
          }
        )
      },
      {
        name: "Employees" /* Employees */,
        component: /* @__PURE__ */ jsx(
          InsuranceSetupEmployeesStep,
          {
            onNext: () => setStep("Data sharing" /* DataSharing */),
            quoteData,
            setQuoteData
          }
        )
      },
      {
        name: "Data sharing" /* DataSharing */,
        component: /* @__PURE__ */ jsx(InsuranceSetupDataSharingStep, { quoteData, refresh })
      }
    ],
    [name, quoteData, refresh]
  );
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: false, border: false, children: /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, mt: spacing.m20 }, children: [
    /* @__PURE__ */ jsx(
      StepperHeader,
      {
        content: /* @__PURE__ */ jsx(StepperComponent, { steps: insuranceQuoteSteps, activeStep: step, hideConnector: true }),
        contentSx: { width: "80%", textAlign: "center" },
        showStepBack: true,
        stepBackAction: () => {
          if (insuranceQuoteSteps.indexOf(step) > 0)
            return setStep(insuranceQuoteSteps[insuranceQuoteSteps.indexOf(step) - 1]);
          routerHistory.goBack();
        },
        stepBackSx: {
          width: "10%",
          textAlign: "left"
        },
        showBack: true,
        backAction: () => routerHistory.push(SETTINGS_BENEFITS_ROUTE),
        backSx: { width: "10%", textAlign: "right" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 } }),
    (_a = insuranceQuoteStepsComponent[insuranceQuoteSteps.indexOf(step)]) == null ? void 0 : _a.component
  ] }) });
};
