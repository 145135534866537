"use strict";
export const MoneyEnum = {
  inPayroll: "payroll",
  inPension: "pension",
  inInsurance: "insurance"
};
export var ProgressStatus = /* @__PURE__ */ ((ProgressStatus2) => {
  ProgressStatus2["None"] = "None";
  ProgressStatus2["Pending"] = "Pending";
  ProgressStatus2["Completed"] = "Completed";
  ProgressStatus2["Missing"] = "Missing";
  return ProgressStatus2;
})(ProgressStatus || {});
export var TimeSegment = /* @__PURE__ */ ((TimeSegment2) => {
  TimeSegment2["All"] = "All";
  TimeSegment2["Past"] = "Past";
  TimeSegment2["Upcoming"] = "Upcoming";
  return TimeSegment2;
})(TimeSegment || {});
