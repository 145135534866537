"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import { GooglePlacesKey } from "@/api-client/google-places.api";
import useMessage from "@/hooks/notification.hook";
import { StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}
const autocompleteService = { current: null };
const placesService = { current: null };
export const GoogleMapsAutocomplete = ({ label, value, fullWidth, onChange, apiKey }) => {
  const [place, setPlace] = useState(
    value ? {
      description: value,
      place_id: "",
      structured_formatting: { main_text: "", secondary_text: "" }
    } : null
  );
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }
    loaded.current = true;
  }
  const fetch = useMemo(
    () => debounce((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback);
    }, 400),
    []
  );
  const fetchPlace = useMemo(
    () => (request, callback) => {
      placesService.current.getDetails(request, callback);
    },
    []
  );
  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return void 0;
    }
    if (inputValue === "") {
      setOptions(place ? [place] : []);
      return void 0;
    }
    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (place) {
          newOptions = [place];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [place, inputValue, fetch]);
  useEffect(() => {
    let active = true;
    if (!placesService.current && window.google) {
      const map = new window.google.maps.Map(document.getElementById("google-maps"));
      placesService.current = new window.google.maps.places.PlacesService(map);
    }
    if (!placesService.current) {
      return void 0;
    }
    if (!(place == null ? void 0 : place.place_id)) {
      return void 0;
    }
    fetchPlace({ placeId: place.place_id }, (result) => {
      var _a, _b, _c, _d, _e, _f, _g;
      if (active && result) {
        const fullAddress = (_a = result == null ? void 0 : result.formatted_address) != null ? _a : "";
        const longitude = (_d = (_c = (_b = result == null ? void 0 : result.geometry) == null ? void 0 : _b.location) == null ? void 0 : _c.lng()) != null ? _d : null;
        const latitude = (_g = (_f = (_e = result == null ? void 0 : result.geometry) == null ? void 0 : _e.location) == null ? void 0 : _f.lat()) != null ? _g : null;
        onChange(fullAddress != null ? fullAddress : "", latitude, longitude);
      }
    });
    return () => {
      active = false;
    };
  }, [fetchPlace, place, fetch, onChange]);
  return /* @__PURE__ */ jsx(
    Autocomplete,
    {
      id: "google-map-demo",
      freeSolo: true,
      getOptionLabel: (option) => typeof option === "string" ? option : option.description,
      filterOptions: (x) => x,
      options,
      autoComplete: true,
      includeInputInList: true,
      filterSelectedOptions: true,
      value: place,
      noOptionsText: "No locations",
      onChange: (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setPlace(newValue);
      },
      onInputChange: (event, newInputValue) => {
        setInputValue(newInputValue);
      },
      fullWidth,
      renderInput: (params) => /* @__PURE__ */ jsx(
        StyledAutoTextfield,
        __spreadProps(__spreadValues({
          variant: "standard"
        }, params), {
          placeholder: typeof label === "string" ? label : void 0,
          size: "small",
          InputLabelProps: { shrink: true },
          label
        })
      ),
      renderOption: (props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        return /* @__PURE__ */ jsx("li", __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(Grid, { container: true, alignItems: "center", children: [
          /* @__PURE__ */ jsx(Grid, { item: true, sx: { display: "flex", width: 44 }, children: /* @__PURE__ */ jsx(LocationOnIcon, { sx: { color: "text.secondary" } }) }),
          /* @__PURE__ */ jsxs(Grid, { item: true, sx: { width: "calc(100% - 44px)", wordWrap: "break-word" }, children: [
            parts.map((part, index) => /* @__PURE__ */ jsx(Box, { component: "span", sx: { fontWeight: part.highlight ? "bold" : "regular" }, children: part.text }, index)),
            /* @__PURE__ */ jsx(Typography, { variant: "body2", color: "text.secondary", children: option.structured_formatting.secondary_text })
          ] })
        ] }) }));
      }
    }
  );
};
export const GooglePlacesWrapper = ({ label, value, fullWidth, onChange }) => {
  const [key, setKey] = useState(null);
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const apiKey = yield GooglePlacesKey.getPlacesAPIKey();
        setKey(apiKey);
      } catch (error) {
        showMessage("Could not load map finder.", "error");
      }
    }))();
  }, [showMessage]);
  return key ? /* @__PURE__ */ jsx(GoogleMapsAutocomplete, { apiKey: key, label, value, fullWidth, onChange }) : null;
};
