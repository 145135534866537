"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { isHourlyPolicy } from "@v2/feature/absence/absence.util";
import {
  absenceAdjustmentsImportValidationSchema,
  absenceImportValidationSchema
} from "@v2/feature/absence/subfeatures/absence-import/absence-import.util";
import { getAttendanceImportValidationSchema } from "@v2/feature/attendance/subfeatures/attendance-import/attendance-import.util";
import { kebabCase } from "lodash";
import { v4 } from "uuid";
import {
  TimeUnitTypes,
  UserContractKinds,
  UserContractTypes
} from "@/component/dashboard/userDetails/validations/userFormDefinitions";
import {
  UserAddressSchemaForImport,
  UserBankAccountFormSchemaForImport,
  UserCompensationSchemaForImport,
  UserContractImportSchema,
  UserEquitySchemaForImport,
  UserLifecycleSchemaForImport
} from "@/component/dashboard/userDetails/validations/userFormValidations";
import { deviceImportValidationSchema } from "@/v2/feature/device/features/devices-import/device-import.interface";
import { getDocumentImportValidationSchema } from "@/v2/feature/document-new/document-new-import/document-new-import.util";
import {
  mapAbsenceAdjustmentsImportToZelt,
  mapAbsenceImportToZelt,
  mapDeviceImportToZelt,
  mapUserImportToZelt,
  sanitizeUserImportRecord
} from "@/v2/feature/entity-import/wizard/entity-import-mapper.util";
import {
  UserImportResultTabsLabels
} from "@/v2/feature/entity-import/wizard/import-wizard.interface";
import {
  PayScheduleEnum,
  SalaryBasisEnum
} from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
import { BasicInformationSchema } from "@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-form.component";
import { PersonalInformationSchemaForImport } from "@/v2/feature/user/features/user-profile/details/components/user-profile-personal-information-form.component";
import { RoleSchemaForImport } from "@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component";
import {
  FAMILY_INFORMATION_MARITAL,
  PERSONAL_INFORMATION_GENDERS
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { isDefined } from "@/v2/feature/user-onboarding/user-onboarding.util";
import { CountryCodes } from "@/v2/infrastructure/country/country.interface";
import { dmyToIsoDateString } from "@/v2/infrastructure/date/date-format.util";
import { caseInsensitiveFind } from "@/v2/util/array.util";
import { fixedNumber } from "@/v2/util/number.util";
function getYupValidationErrors(err, domain, fieldsToCheck) {
  var _a;
  const validationErrors = [];
  (_a = err.inner) == null ? void 0 : _a.forEach((error) => {
    if (error.path && fieldsToCheck && fieldsToCheck.length > 0 && (fieldsToCheck == null ? void 0 : fieldsToCheck.includes(String(error.path))) || error.path && (!fieldsToCheck || fieldsToCheck.length === 0)) {
      validationErrors.push({
        property: error.path.toString(),
        value: error.value,
        constraints: { [error.path]: error.message },
        domain
      });
    }
  });
  return validationErrors;
}
function validationSchemaForDomain(domain, polyglot) {
  let chosenSchema;
  switch (domain) {
    case "Basic":
      chosenSchema = BasicInformationSchema;
      break;
    case "Details":
      chosenSchema = PersonalInformationSchemaForImport(polyglot);
      break;
    case "Address":
      chosenSchema = UserAddressSchemaForImport(polyglot);
      break;
    case "Role":
      chosenSchema = RoleSchemaForImport;
      break;
    case "Lifecycle":
      chosenSchema = UserLifecycleSchemaForImport;
      break;
    case "Salary":
      chosenSchema = UserCompensationSchemaForImport(polyglot);
      break;
    case "Bank account":
      chosenSchema = UserBankAccountFormSchemaForImport(polyglot);
      break;
    case "Equity":
      chosenSchema = UserEquitySchemaForImport;
      break;
    case "Contract":
      chosenSchema = UserContractImportSchema(polyglot);
      break;
    default:
      break;
  }
  return chosenSchema;
}
const allFieldsForDomainEmpty = (recordToCheck, fieldsToCheck) => {
  const fieldsMissingData = /* @__PURE__ */ new Set();
  const EMPTY_VALUES = [null, void 0, ""];
  fieldsToCheck.forEach((a) => {
    if (!recordToCheck[a] || recordToCheck[a] && EMPTY_VALUES.includes(recordToCheck[a])) {
      fieldsMissingData.add(a);
    }
  });
  return fieldsMissingData.size === fieldsToCheck.length;
};
const isFieldRequired = (domainSchema, field) => {
  var _a, _b;
  return ((_b = (_a = domainSchema == null ? void 0 : domainSchema.fields[field]) == null ? void 0 : _a.exclusiveTests) == null ? void 0 : _b.required) || false;
};
const errorOccurredInFieldsToCheck = (err, fieldsToCheck) => {
  return err.inner.length > 0 && err.inner.some((eachError) => fieldsToCheck.includes(eachError.path));
};
const validatePeopleDomain = (domain, ongoingResult, polyglot) => __async(void 0, null, function* () {
  const domainSchema = validationSchemaForDomain(domain, polyglot);
  const fieldsToExcludeFromCheck = ["effectiveDate", "salaryBasis", "currency", "country"];
  const fieldsToCheck = domainSchema ? Object.keys(domainSchema == null ? void 0 : domainSchema.fields).filter(
    (field) => !fieldsToExcludeFromCheck.includes(field) && (isFieldRequired(domainSchema, field) || !isFieldRequired(domainSchema, field) && isDefined(ongoingResult.entity[field]))
  ) : [];
  let result = ongoingResult;
  if (fieldsToCheck && fieldsToCheck.length > 0 && !allFieldsForDomainEmpty(ongoingResult.entity, fieldsToCheck)) {
    try {
      if (domainSchema) yield domainSchema.validate(ongoingResult.entity, { abortEarly: false });
    } catch (err) {
      if (errorOccurredInFieldsToCheck(err, fieldsToCheck)) {
        const domainErrors = getYupValidationErrors(err, domain, fieldsToCheck);
        result.errors = [...result.errors, ...domainErrors];
      }
    }
  }
  return result;
});
const validateAbsenceDomain = (ongoingResult, policy) => __async(void 0, null, function* () {
  try {
    const isHourly = isHourlyPolicy(policy);
    const hasHourSet = !!ongoingResult.entity.startHour;
    yield absenceImportValidationSchema(isHourly || hasHourSet).validate(ongoingResult.entity, { abortEarly: false });
  } catch (err) {
    const domainErrors = getYupValidationErrors(err, "absence");
    ongoingResult.errors = [...ongoingResult.errors, ...domainErrors];
  }
});
const validateAbsenceAdjustmentsDomain = (ongoingResult) => __async(void 0, null, function* () {
  try {
    yield absenceAdjustmentsImportValidationSchema().validate(ongoingResult.entity, { abortEarly: false });
  } catch (err) {
    const domainErrors = getYupValidationErrors(err, "absenceAdjustments");
    ongoingResult.errors = [...ongoingResult.errors, ...domainErrors];
  }
});
export const reValidatePeopleImportResult = (importResult, departments, sites, polyglot) => __async(void 0, null, function* () {
  let ongoingResult;
  const allValidations = [];
  const domainToSkipVerification = ["Right to work"];
  const domainsToVerify = UserImportResultTabsLabels.filter((domain) => !domainToSkipVerification.includes(domain));
  const records = importResult.errors;
  for (const eachRecord of records) {
    const recordForValidation = mapUserImportToZelt(
      sanitizeUserImportRecord(eachRecord.entity),
      departments,
      sites,
      void 0
    );
    ongoingResult = {
      entity: recordForValidation,
      errors: [...eachRecord.errors]
    };
    for (const eachDomain of domainsToVerify) {
      ongoingResult = yield validatePeopleDomain(eachDomain, ongoingResult, polyglot);
    }
    allValidations.push(ongoingResult);
  }
  return {
    errors: allValidations
  };
});
export const validateDeviceDomain = (ongoingResult) => __async(void 0, null, function* () {
  let result = ongoingResult;
  try {
    yield deviceImportValidationSchema.validate(ongoingResult.entity, { abortEarly: false });
  } catch (err) {
    const domainErrors = getYupValidationErrors(err, "device");
    result.errors = [...result.errors, ...domainErrors];
  }
  return result;
});
const getUserNotFoundError = (entity, domain, property = "workEmail", userNotFoundMessage = "This user does not exist in your company") => {
  return {
    target: entity,
    property,
    value: "Missing user",
    constraints: {
      userNotFound: userNotFoundMessage
    },
    domain,
    id: entity.id
  };
};
const getPolicyNotFoundError = (entity, domain, property = "policyName", notFoundMessage = "Policy not found") => {
  return {
    target: entity,
    property,
    value: "Missing policy",
    constraints: {
      policyNotFound: notFoundMessage
    },
    domain,
    id: entity.id
  };
};
export const validateAbsenceImport = (rawLocalData, allUsersIncludingTerminated, absencePolicies, validateIndexOnly = -1) => __async(void 0, null, function* () {
  let ongoingResult;
  const allValidations = [];
  for (let i = 0; i < rawLocalData.length; i++) {
    const eachRecord = rawLocalData[i];
    const matchingZeltUser = allUsersIncludingTerminated.find(
      (u) => {
        var _a;
        return u.emailAddress === ((_a = eachRecord.workEmail) == null ? void 0 : _a.toLowerCase());
      }
    );
    const policy = absencePolicies.find((p) => p.fullName === eachRecord.policyName);
    if (validateIndexOnly >= 0 && i !== validateIndexOnly) {
      ongoingResult = {
        entity: eachRecord,
        errors: !matchingZeltUser ? [getUserNotFoundError(eachRecord, "absence")] : !policy ? [getPolicyNotFoundError(eachRecord, "absence")] : []
      };
      if (policy) yield validateAbsenceDomain(ongoingResult, policy);
      allValidations.push(ongoingResult);
    } else {
      const recordForValidation = mapAbsenceImportToZelt(eachRecord, policy, matchingZeltUser);
      ongoingResult = {
        entity: recordForValidation,
        errors: !matchingZeltUser ? [getUserNotFoundError(eachRecord, "absence")] : !policy ? [getPolicyNotFoundError(eachRecord, "absence")] : []
      };
      if (policy) yield validateAbsenceDomain(ongoingResult, policy);
      allValidations.push(ongoingResult);
    }
  }
  return {
    errors: allValidations
  };
});
export const validateAbsenceAdjustmentsImport = (rawLocalData, allUsersIncludingTerminated, absencePolicies, validateIndexOnly = -1) => __async(void 0, null, function* () {
  let ongoingResult;
  const allValidations = [];
  for (let i = 0; i < rawLocalData.length; i++) {
    const eachRecord = rawLocalData[i];
    const matchingZeltUser = allUsersIncludingTerminated.find(
      (u) => {
        var _a;
        return u.emailAddress === ((_a = eachRecord.workEmail) == null ? void 0 : _a.toLowerCase());
      }
    );
    const policy = absencePolicies.find((p) => p.fullName === eachRecord.policyName);
    if (validateIndexOnly >= 0 && i !== validateIndexOnly) {
      ongoingResult = {
        entity: eachRecord,
        errors: !matchingZeltUser ? [getUserNotFoundError(eachRecord, "absenceAdjustments")] : !policy ? [getPolicyNotFoundError(eachRecord, "absenceAdjustments")] : []
      };
      yield validateAbsenceAdjustmentsDomain(ongoingResult);
      allValidations.push(ongoingResult);
    } else {
      const recordForValidation = mapAbsenceAdjustmentsImportToZelt(eachRecord, policy, matchingZeltUser);
      ongoingResult = {
        entity: recordForValidation,
        errors: !matchingZeltUser ? [getUserNotFoundError(eachRecord, "absenceAdjustments")] : !policy ? [getPolicyNotFoundError(eachRecord, "absenceAdjustments")] : []
      };
      yield validateAbsenceAdjustmentsDomain(ongoingResult);
      allValidations.push(ongoingResult);
    }
  }
  return {
    errors: allValidations
  };
});
export const validateDeviceImport = (rawLocalData) => __async(void 0, null, function* () {
  let ongoingResult;
  const allValidations = [];
  for (const eachRecord of rawLocalData) {
    const recordForValidation = mapDeviceImportToZelt(eachRecord);
    ongoingResult = {
      entity: recordForValidation,
      errors: []
    };
    ongoingResult = yield validateDeviceDomain(ongoingResult);
    allValidations.push(ongoingResult);
  }
  return {
    errors: allValidations
  };
});
export const validateAttendanceImport = (rawLocalData, allUsersIncludingTerminated, attendanceTypes) => __async(void 0, null, function* () {
  const allValidations = [];
  for (const eachRecord of rawLocalData) {
    const recordForValidation = mapAttendanceImportToZelt(eachRecord, allUsersIncludingTerminated, attendanceTypes);
    const ongoingResult = {
      entity: recordForValidation,
      errors: !recordForValidation.userId ? [getUserNotFoundError(eachRecord, "attendances")] : []
    };
    yield validateAttendanceDomain(ongoingResult, attendanceTypes);
    allValidations.push(ongoingResult);
  }
  return {
    errors: allValidations
  };
});
export const validateDocumentImport = (preparedDocs) => __async(void 0, null, function* () {
  const allValidations = [];
  for (const eachRecord of preparedDocs) {
    const ongoingResult = {
      entity: eachRecord,
      errors: []
    };
    yield validateDocumentDomain(ongoingResult);
    allValidations.push(ongoingResult);
  }
  return {
    errors: allValidations
  };
});
const validateDocumentDomain = (ongoingResult) => __async(void 0, null, function* () {
  try {
    yield getDocumentImportValidationSchema().validate(ongoingResult.entity, { abortEarly: false });
  } catch (err) {
    const domainErrors = getYupValidationErrors(err, "documents");
    ongoingResult.errors = [...ongoingResult.errors, ...domainErrors];
  }
});
const validateAttendanceDomain = (ongoingResult, attendanceTypes) => __async(void 0, null, function* () {
  try {
    yield getAttendanceImportValidationSchema(attendanceTypes).validate(ongoingResult.entity, { abortEarly: false });
  } catch (err) {
    const domainErrors = getYupValidationErrors(err, "attendances");
    ongoingResult.errors = [...ongoingResult.errors, ...domainErrors];
  }
});
export const mapAttendanceImportToZelt = (record, allUsersIncludingTerminated, attendanceTypes) => {
  var _a, _b, _c, _d;
  const zeltUser = allUsersIncludingTerminated.find((u) => {
    var _a2;
    return u.emailAddress === ((_a2 = record.workEmail) == null ? void 0 : _a2.toLowerCase());
  });
  const attendanceType = attendanceTypes.find((p) => p.name === record.attendanceType);
  return __spreadProps(__spreadValues({}, record), {
    id: (_a = record.id) != null ? _a : v4(),
    // add id only if it is not already set
    workEmail: zeltUser ? zeltUser.emailAddress : record.workEmail.toLowerCase().trim(),
    userId: zeltUser ? zeltUser.userId : void 0,
    attendanceType: (_d = (_c = attendanceType == null ? void 0 : attendanceType.name) != null ? _c : (_b = record.attendanceType) == null ? void 0 : _b.trim()) != null ? _d : ""
  });
};
const validatePeopleImportRecord2 = (entity, polyglot) => __async(void 0, null, function* () {
  const result = {
    entity,
    errors: []
  };
  const schemas = [
    { name: "Basic", form: entity.default.Basic.values },
    { name: "Details", form: entity.default.Details.values },
    { name: "Family", form: entity.default.Family.values },
    { name: "About", form: entity.default.About.values },
    { name: "Lifecycle", form: entity.default.Lifecycle.values },
    { name: "Role", form: entity.default.Role.values },
    { name: "Contract", form: entity.default.Contract.values },
    { name: "Salary", form: entity.default.Salary.values },
    { name: "Bank account", form: entity.default.BankAccount.values },
    { name: "Address", form: entity.default.Address.values },
    { name: "Emergency", form: entity.default.Emergency.values }
  ];
  for (const { name, form } of schemas) {
    const domainSchema = validationSchemaForDomain(name, polyglot);
    try {
      if (domainSchema && form) {
        yield domainSchema.validate(form, { abortEarly: false });
      }
    } catch (err) {
      const domainErrors = getYupValidationErrors(err, name);
      result.errors = [...result.errors, ...domainErrors];
    }
  }
  return result;
});
export const validatePeopleImport2 = (records, polyglot) => __async(void 0, null, function* () {
  return {
    errors: yield Promise.all(records.map((record) => validatePeopleImportRecord2(record, polyglot)))
  };
});
const convertPeopleRecordToUserImport2 = (record, users, departments, sites, companyEntities, attendanceSchedules, customForms, getCustomFieldsForForm, polyglot) => {
  var _a, _b, _c;
  const textValue = (key, defaultValue) => {
    var _a2;
    return (_a2 = record[key]) != null ? _a2 : defaultValue;
  };
  const numberValue = (key, defaultValue = 0) => /^\d+(\.\d*)?/.test(record[key]) ? parseFloat(record[key]) : defaultValue;
  const optionalNumber = (key) => parseFloat(record[key]) || void 0;
  const moneyValue = (key, defaultValue = 0) => fixedNumber(record[key], 2) || defaultValue;
  const currencyValue = (key) => {
    var _a2;
    return (_a2 = record[key]) == null ? void 0 : _a2.toUpperCase();
  };
  const booleanValue = (key, defaultValue) => {
    if (/^(y|yes|t|true)$/i.test(record[key])) return true;
    if (/^(n|no|f|false)$/i.test(record[key])) return false;
    return defaultValue;
  };
  const dateValue = (key, defaultValue) => {
    var _a2;
    const value = record[key];
    if (!value) return defaultValue;
    return (_a2 = dmyToIsoDateString(value)) != null ? _a2 : value;
  };
  const optionValue = (key, values, defaultValue) => {
    var _a2;
    const importValue = record[key];
    return (_a2 = caseInsensitiveFind(values, importValue, (item) => item)) != null ? _a2 : defaultValue;
  };
  const userIdValue = (key) => {
    var _a2;
    return (_a2 = caseInsensitiveFind(users, record[key], (item) => item.emailAddress)) == null ? void 0 : _a2.userId;
  };
  const departmentIdValue = (key) => {
    var _a2;
    return (_a2 = caseInsensitiveFind(departments, record[key], (item) => item.name)) == null ? void 0 : _a2.id;
  };
  const siteIdValue = (key) => {
    var _a2;
    return (_a2 = caseInsensitiveFind(sites, record[key], (item) => item.name)) == null ? void 0 : _a2.id;
  };
  const companyEntityIdValue = (key) => {
    var _a2, _b2;
    return (_b2 = (_a2 = caseInsensitiveFind(companyEntities, record[key], (item) => item.legalName)) == null ? void 0 : _a2.id) != null ? _b2 : null;
  };
  const attendanceScheduleIdValue = (key) => {
    var _a2, _b2;
    return (_b2 = (_a2 = caseInsensitiveFind(attendanceSchedules, record[key], (item) => item.name)) == null ? void 0 : _a2.id) != null ? _b2 : null;
  };
  const hasSection = (sectionId) => Object.entries(record).some(([key, value]) => key.startsWith(`${sectionId}.`) && value);
  const getImportedCustomFieldsForForm = (formName) => {
    return getCustomFieldsForForm(formName).reduce((arr, customField) => {
      const importFieldName = `${formName}.${kebabCase(customField.fieldName)}`;
      if (Object.prototype.hasOwnProperty.call(record, importFieldName)) {
        arr.push({
          fieldId: customField.fieldId,
          formId: customField.formId,
          field: customField,
          value: ["Date picker", "Date input", "Date"].includes(customField.fieldType) ? dateValue(importFieldName, "") : textValue(importFieldName, "")
        });
      }
      return arr;
    }, []);
  };
  const userEmailAddress = textValue("basic.work-email", "");
  const cachedUser = caseInsensitiveFind(users, userEmailAddress, (entry) => entry.emailAddress);
  const basic = {
    firstName: textValue("basic.first-name", (_a = cachedUser == null ? void 0 : cachedUser.firstName) != null ? _a : ""),
    lastName: textValue("basic.last-name", (_b = cachedUser == null ? void 0 : cachedUser.lastName) != null ? _b : ""),
    middleName: textValue("basic.middle-name", null),
    displayName: textValue("basic.display-name", void 0),
    emailAddress: textValue("basic.work-email", ""),
    customUpdates: getImportedCustomFieldsForForm("basic")
  };
  const details = hasSection("details") ? {
    dob: dateValue("details.date-of-birth", null),
    gender: optionValue("details.gender", PERSONAL_INFORMATION_GENDERS, null),
    nationality: textValue("details.nationality", null),
    personalEmail: textValue("basic.personal-email", null),
    // basic not details
    phone: textValue("details.phone", null),
    passportNumber: textValue("details.passport-no", null),
    customUpdates: getImportedCustomFieldsForForm("details")
  } : null;
  const family = hasSection("family") ? {
    maritalStatus: optionValue("family.marital-status", FAMILY_INFORMATION_MARITAL, null),
    customUpdates: getImportedCustomFieldsForForm("family")
  } : null;
  const about = hasSection("about") ? {
    about: textValue("about.overview", null),
    hobbies: textValue("about.hobbies", null),
    social: textValue("about.linkedin", null),
    foodPreferences: textValue("about.favourite-food", null),
    dietaryRestrictions: textValue("about.dietary-restrictions", null),
    customUpdates: getImportedCustomFieldsForForm("about")
  } : null;
  const lifecycle = hasSection("lifecycle") ? {
    startDate: dateValue("lifecycle.start-date", ""),
    leaveDate: dateValue("lifecycle.leave-date", void 0),
    changeReason: textValue("lifecycle.change-reason", ""),
    customUpdates: getImportedCustomFieldsForForm("lifecycle")
  } : null;
  const role = hasSection("role") ? {
    effectiveDate: dateValue("role.effective", ""),
    jobTitle: textValue("role.job-title", ""),
    jobPositionId: null,
    departmentId: departmentIdValue("role.department"),
    siteId: siteIdValue("role.site"),
    managerId: userIdValue("role.manager"),
    managerEmail: textValue("role.manager", ""),
    customUpdates: getImportedCustomFieldsForForm("role")
  } : null;
  const contract = hasSection("contract") ? {
    effectiveDate: dateValue("contract.effective", ""),
    contract: optionValue(
      "contract.kind",
      UserContractKinds(polyglot).map((c) => c.value),
      "Full-time"
    ),
    type: optionValue(
      "contract.type",
      UserContractTypes(polyglot).map((c) => c.value),
      "Employee"
    ),
    entityId: companyEntityIdValue("contract.entity"),
    ftePercent: numberValue("contract.fte-percent", 100),
    publicHolidays: optionValue("contract.holiday-calendar", CountryCodes, null),
    noticePeriodLength: optionalNumber("contract.notice-period-length"),
    noticePeriodUnit: optionValue(
      "contract.notice-period-unit",
      TimeUnitTypes(polyglot).map((c) => c.value),
      void 0
    ),
    probationPeriodLength: optionalNumber("contract.probation-period-length"),
    probationPeriodUnit: optionValue(
      "contract.probation-period-unit",
      TimeUnitTypes(polyglot).map((c) => c.value),
      void 0
    ),
    attendanceScheduleId: attendanceScheduleIdValue("contract.attendance-schedule"),
    contractEndDate: dateValue("contract.end-date", null),
    customUpdates: getImportedCustomFieldsForForm("contract")
  } : null;
  const salary = hasSection("salary") ? {
    effectiveDate: dateValue("salary.effective", ""),
    paySchedule: optionValue("salary.pay-schedule", Object.values(PayScheduleEnum), PayScheduleEnum.Monthly),
    salaryBasis: optionValue("salary.basis", Object.values(SalaryBasisEnum), SalaryBasisEnum.Annual),
    rate: moneyValue("salary.rate"),
    currency: (_c = currencyValue("salary.currency")) != null ? _c : null,
    prorateSalaryByFte: booleanValue("salary.prorate-by-fte", false),
    customUpdates: getImportedCustomFieldsForForm("salary")
  } : null;
  const bankAccount = hasSection("bank-account") ? {
    effectiveDate: dateValue("bank-account.effective", ""),
    accountName: textValue("bank-account.account-holder-name", ""),
    accountNumber: textValue("bank-account.account-number/iban", ""),
    sortCode: textValue("bank-account.sort-code/bic/swift", ""),
    bankName: textValue("bank-account.bank-name", ""),
    country: textValue("bank-account.country", null),
    currency: currencyValue("bank-account.currency"),
    customUpdates: getImportedCustomFieldsForForm("bank-account")
  } : null;
  const address = hasSection("address") ? {
    effectiveDate: dateValue("address.effective", ""),
    addressLine1: textValue("address.line-1", ""),
    addressLine2: textValue("address.line-2", ""),
    city: textValue("address.city", ""),
    postcode: textValue("address.postcode", ""),
    country: textValue("address.country", void 0),
    customUpdates: getImportedCustomFieldsForForm("address")
  } : null;
  const emergency = hasSection("emergency") ? {
    emergencyName: textValue("emergency.name", null),
    emergencyNumber: textValue("emergency.number", null),
    emergencyRelationship: textValue("emergency.relationship", null),
    customUpdates: getImportedCustomFieldsForForm("emergency")
  } : null;
  const sectionNameToCustomForm = new Map(
    customForms.filter((f) => !f.isDefault).map((f) => [
      kebabCase(f.formName),
      {
        tabName: f.formName,
        formId: f.formId,
        fields: getCustomFieldsForForm(f.formName).map((field) => ({
          fieldId: field.fieldId,
          formId: field.formId,
          field,
          value: ""
        }))
      }
    ])
  );
  for (const [key, value] of Object.entries(record)) {
    const [sectionID, fieldName] = key.split(".");
    const entry = sectionNameToCustomForm.get(sectionID);
    if (!entry) continue;
    const matchedField = entry.fields.find((f) => kebabCase(f.field.fieldName) === fieldName);
    if (!matchedField) continue;
    matchedField.value = value;
  }
  const result = {
    default: {
      Basic: {
        tabName: "Basic",
        values: basic
      },
      Details: {
        tabName: "Details",
        values: details
      },
      Family: {
        tabName: "Family",
        values: family
      },
      About: {
        tabName: "About",
        values: about
      },
      Role: {
        tabName: "Role",
        values: role
      },
      Contract: {
        tabName: "Contract",
        values: contract
      },
      Lifecycle: {
        tabName: "Lifecycle",
        values: lifecycle
      },
      Salary: {
        tabName: "Salary",
        values: salary
      },
      BankAccount: {
        tabName: "Bank account",
        values: bankAccount
      },
      Address: {
        tabName: "Address",
        values: address
      },
      Emergency: {
        tabName: "Emergency contact",
        values: emergency
      }
    },
    custom: [...sectionNameToCustomForm.values()].filter((x) => x.fields.some((f) => f.value))
  };
  return result;
};
export const convertPeopleCSVToUserImport2 = (records, users, departments, sites, companyEntities, attendanceSchedules, customForms, getCustomFieldsForForm, polyglot) => {
  return records.map(
    (record) => convertPeopleRecordToUserImport2(
      record,
      users,
      departments,
      sites,
      companyEntities,
      attendanceSchedules,
      customForms,
      getCustomFieldsForForm,
      polyglot
    )
  );
};
