"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Column } from "@/images/table/Column.svg";
import { checkScopes } from "@/lib/scopes";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { iconSize } from "@/v2/styles/menu.styles";
export const HiddenColumnSelector = ({
  options,
  columnAction,
  hiddenColumns,
  setHiddenColumns
}) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [globalState, dispatch] = useContext(GlobalContext);
  const filterOptions = useMemo(() => {
    return options.filter(
      (option) => !option.scopes || !option.scopes || option.context ? checkScopes(globalState.user, option.scopes, option.context) : hasScopes(option.scopes, getScopesContext(globalState.user))
    );
  }, [options, globalState.user, hasScopes, getScopesContext]);
  const optionsValuesSet = useMemo(() => new Set(options.map((o) => o.value)), [options]);
  return /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      checkOptions: filterOptions,
      anchorOrigin: { horizontal: "left", vertical: "bottom" },
      transformOrigin: { horizontal: "left", vertical: "top" },
      closeOnSelect: false,
      actionButtonDetails: {
        type: "button",
        sizeVariant: "small",
        colorVariant: "secondary",
        title: polyglot.t("HiddenColumnSelector.columns"),
        icon: /* @__PURE__ */ jsx(Column, __spreadValues({}, iconSize)),
        iconPosition: "start"
      },
      selectedCheckbox: filterOptions.filter((o) => !(hiddenColumns == null ? void 0 : hiddenColumns.includes(o.value))).map((o) => o.value),
      onCheckboxChange: (option) => __async(void 0, null, function* () {
        const hideColumns = ((hiddenColumns == null ? void 0 : hiddenColumns.includes(option.value)) ? hiddenColumns.filter((sm) => sm !== option.value) : [...hiddenColumns, option.value]).filter((columnName) => optionsValuesSet.has(columnName));
        setHiddenColumns(hideColumns);
        if (columnAction) {
          const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
            columnAction.domain,
            columnAction.subDomain,
            columnAction.feature,
            hideColumns
          );
          dispatch({
            type: GlobalStateActions.UPDATE_USER,
            payload: updatedGlobalUser
          });
        }
      })
    }
  );
};
