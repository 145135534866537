"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  buttonBoxSx,
  fieldSx,
  titleSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserAPI } from "@/v2/feature/user/user.api";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatLongDate } from "@/v2/util/date-format.util";
export const AccountInformationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Employee Id is required")
});
export const AccountInformationForm = ({ initialValues, userId, onSubmit, onClose }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AccountInformationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield UserAPI.updateEmployeeId(userId, { employeeId: values.employeeId });
        onSubmit(values);
        showMessage(polyglot.t("AccountInformationForm.successMessages.update"), "success");
      } catch (error) {
        showMessage(
          `${polyglot.t("AccountInformationForm.errorMessages.update")}: ${nestErrorMessage(error)}; Please try again.`,
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("AccountInformationForm.edit") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "employeeId",
          label: polyglot.t("AccountInformationForm.employeeId"),
          value: formik.values.employeeId,
          onChange: formik.handleChange,
          type: "text",
          error: formik.touched.employeeId && !!formik.errors.employeeId,
          helperText: (_a = formik.touched.employeeId && formik.errors.employeeId) != null ? _a : " ",
          clearText: () => formik.setFieldValue("employeeId", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "accountStatus",
          label: polyglot.t("AccountInformationForm.status"),
          value: formik.values.accountStatus,
          type: "text",
          disabled: true
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "activationDate",
          label: polyglot.t("AccountInformationForm.activationDate"),
          value: formik.values.activationDate ? formatLongDate(formik.values.activationDate) : "",
          type: "text",
          disabled: true
        }
      ) }),
      formik.values.deactivationDate && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "deactivationDate",
          label: polyglot.t("AccountInformationForm.deactivationDate"),
          value: formik.values.deactivationDate ? formatLongDate(formik.values.deactivationDate) : "",
          type: "text",
          disabled: true
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "createdAt",
          label: polyglot.t("AccountInformationForm.createdAt"),
          value: formik.values.createdAt ? formatLongDate(formik.values.createdAt) : "",
          type: "text",
          disabled: true
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "updatedAt",
          label: polyglot.t("AccountInformationForm.updatedAt"),
          value: formik.values.updatedAt ? formatLongDate(formik.values.updatedAt) : "",
          type: "text",
          disabled: true
        }
      ) }),
      formik.values.lastTimeOnline && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "lastTimeOnline",
          label: polyglot.t("AccountInformationForm.lastTimeOnline"),
          value: formik.values.lastTimeOnline ? formatLongDate(formik.values.lastTimeOnline) : "",
          type: "text",
          disabled: true
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) }) });
};
