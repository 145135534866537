"use strict";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import * as XLSX from "sheetjs-style";
export const capturePdf = (captureName) => {
  const element = document.getElementById(captureName);
  if (element) {
    html2canvas(element, { scale: 2, ignoreElements: (el) => el.classList.contains("hide-for-capture") }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 200;
        const pageHeight = 287;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save(`${captureName}.pdf`);
      }
    );
  }
};
export const capturePNG = (captureName) => {
  const element = document.getElementById(captureName);
  if (element) {
    html2canvas(element, {
      scale: 2,
      ignoreElements: (el) => el.classList.contains("hide-for-capture")
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${captureName}.png`;
      link.click();
    });
  }
};
export const captureExcel = (data, reportName) => {
  const truncatedReportName = reportName.length > 30 ? reportName.slice(0, 30) : reportName;
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, truncatedReportName);
  XLSX.writeFile(workbook, `${truncatedReportName}.xlsx`);
};
