"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import * as yup from "yup";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { AdminDocumentView } from "@/v2/feature/document-new/interfaces/document-new.interface";
export const preparePendingDocuments = (filesUploaded, documentTypes) => {
  const otherType = documentTypes.find((type) => type.value === "other");
  return filesUploaded.map((file, idx) => {
    var _a;
    return {
      id: idx,
      file,
      contractId: null,
      fileUuid: null,
      isExternalResource: false,
      programmaticRecordType: null,
      programmaticRecordId: null,
      userId: null,
      typeId: (_a = otherType == null ? void 0 : otherType.id) != null ? _a : -1,
      name: file.file.name,
      note: null,
      membersRule: UserSelectFiltersOptions.SelectSpecific,
      viewType: AdminDocumentView.Personal,
      customRule: null,
      documentSettings: {
        isPinned: false,
        expiryDate: null,
        private: false,
        hideFromEmployee: false
      },
      state: "pending"
    };
  });
};
export const getDocumentImportValidationSchema = () => {
  return yup.object({
    userId: yup.number().nullable().required(),
    typeId: yup.number().required(),
    viewType: yup.string().required(),
    name: yup.string().required(),
    fileUuid: yup.string().nullable().notRequired(),
    file: yup.mixed().required(),
    membersRule: yup.string().required(),
    customRule: yup.string().nullable().notRequired(),
    note: yup.string().nullable().notRequired()
  });
};
export function readFileAsBase64(file) {
  return __async(this, null, function* () {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((reader == null ? void 0 : reader.result).split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  });
}
