"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { createContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import {
  PAYROLL_COMPANY_INTRO_ROUTE,
  PAYROLL_COMPANY_NEW_PAYROLL_ROUTE,
  PAYROLL_COMPANY_OVERVIEW_ROUTE,
  PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE,
  PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE,
  PAYROLL_COMPANY_ROUTE
} from "@/lib/routes";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { CompanyPayrollPage } from "@/v2/feature/payroll/features/payroll-company/company-payroll.page";
import { PayrollTopHeader } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-top-header.component";
import { PayrollCompanyCreate } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-create/payroll-company-create.component";
import { PayrollCompanyIntro } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-intro/payroll-company-intro.component";
import { PayrollCompanyListPayrolls } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-setup/payroll-company-list-payrolls.page";
import { PayrunPage } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/payrun.page";
import { PayrollScopes } from "@/v2/feature/payroll/payroll.scopes";
import { RootStyle } from "@/v2/styles/root.styles";
export const PayrollContext = createContext({
  refreshPayrollState: () => {
  }
});
export const PayrollCompanyRouter = ({ loading }) => {
  const { companyConfig, refreshCompanyConfig, isLoading } = useCompanyConfigState();
  const hasPayroll = isLoading ? void 0 : companyConfig == null ? void 0 : companyConfig.inPayroll;
  if (loading || typeof hasPayroll !== "boolean") {
    return /* @__PURE__ */ jsxs(RootStyle, { children: [
      /* @__PURE__ */ jsx(PayrollTopHeader, {}),
      /* @__PURE__ */ jsx(LoadingSpinner, {})
    ] });
  }
  return /* @__PURE__ */ jsx(PayrollContext.Provider, { value: { refreshPayrollState: () => refreshCompanyConfig() }, children: /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { path: PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE, exact: true, scopes: ["payroll:all"], children: /* @__PURE__ */ jsx(PayrunPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { path: PAYROLL_COMPANY_NEW_PAYROLL_ROUTE, exact: true, scopes: PayrollScopes.CREATE_NEW_PAYROLL, children: /* @__PURE__ */ jsx(PayrollCompanyCreate, {}) }),
    /* @__PURE__ */ jsxs(RouteScopesControl, { path: PAYROLL_COMPANY_INTRO_ROUTE, exact: true, scopes: PayrollScopes.VIEW_COMPANY_INTRO, children: [
      hasPayroll && /* @__PURE__ */ jsx(Redirect, { to: PAYROLL_COMPANY_OVERVIEW_ROUTE }),
      !hasPayroll && /* @__PURE__ */ jsx(PayrollCompanyIntro, {})
    ] }),
    /* @__PURE__ */ jsxs(RouteScopesControl, { path: PAYROLL_COMPANY_OVERVIEW_ROUTE, exact: true, scopes: ["payroll:all"], children: [
      !hasPayroll && /* @__PURE__ */ jsx(Redirect, { to: PAYROLL_COMPANY_INTRO_ROUTE }),
      hasPayroll && /* @__PURE__ */ jsx(PayrollCompanyListPayrolls, {})
    ] }),
    /* @__PURE__ */ jsx(RouteScopesControl, { path: PAYROLL_COMPANY_PAYROLL_PAYRUNS_ROUTE, exact: true, scopes: ["payroll:all"], children: /* @__PURE__ */ jsx(CompanyPayrollPage, {}) }),
    /* @__PURE__ */ jsx(
      Redirect,
      {
        from: PAYROLL_COMPANY_ROUTE,
        to: hasPayroll ? PAYROLL_COMPANY_OVERVIEW_ROUTE : PAYROLL_COMPANY_INTRO_ROUTE
      }
    )
  ] }) });
};
