"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { REPORT_COMPANY_REPORTS_OVERVIEW, REPORTS_CREATE_OLD_ROUTE, REPORTS_UPDATE_OLD_ROUTE } from "@/lib/routes";
import { CreateReportPage } from "@/v2/feature/reports/features/create-report/create-report.page";
import { UpdateReportPage } from "@/v2/feature/reports/features/update-report/update-report.page";
import { ReportsPage } from "@/v2/feature/reports/reports.page";
export const ReportsRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reports:all"], path: REPORTS_CREATE_OLD_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(CreateReportPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reports:all"], path: REPORTS_UPDATE_OLD_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(UpdateReportPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reports:all"], path: REPORT_COMPANY_REPORTS_OVERVIEW, context: scopesContext, children: /* @__PURE__ */ jsx(ReportsPage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { to: REPORT_COMPANY_REPORTS_OVERVIEW })
  ] });
};
