"use strict";
import { jsx } from "react/jsx-runtime";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as PayrollIcon } from "@/images/onboarding-step-icons/WalletBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { EditPayroll } from "@/v2/feature/onboarding/onboarding-items/payroll/edit-payroll.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollOnboardingItem = ({ template, setDrawerOpen, setDrawerView, onDelete }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: PayrollIcon,
        title: polyglot.t("PayrollOnboardingItem.title"),
        description: polyglot.t("PayrollOnboardingItem.desc")
      },
      addButtonIcon: template.template.payroll ? "edit" : "plus",
      onAddClick: template.template.payroll ? void 0 : () => {
        setDrawerView(
          /* @__PURE__ */ jsx(
            EditPayroll,
            {
              onSave: () => {
                template.template.payroll = { enabled: true };
                setDrawerOpen(false);
              }
            }
          )
        );
        setDrawerOpen(true);
      },
      onDeleteClick: template.template.payroll && onDelete,
      sx: {
        py: spacing.py20
      }
    }
  );
};
