"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import { GlobalPayrollSalarySummaryPage } from "./global-payroll-salary-summary-page.component";
import useMessage from "@/hooks/notification.hook";
import { GlobalPayrollAPI } from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const GlobalPayrollSalarySummaryDrawer = ({
  onOpen = () => {
  },
  onClose = () => {
  },
  payslip,
  providedPayrunEntry,
  providedPayrun,
  providedUser,
  handleEditAction
}) => {
  const [payrun, setPayrun] = useState(null);
  const [showMessage] = useMessage();
  const refreshPayrun = useCallback(() => __async(void 0, null, function* () {
    if (!payslip && !providedPayrunEntry) {
      setPayrun(null);
      return;
    }
    let payrun2;
    if (payslip) {
      const { payrollId, taxYear, payPeriod, period, userId } = payslip;
      payrun2 = yield GlobalPayrollAPI.getPayrunForUserId(payrollId, taxYear, payPeriod, period, userId);
    } else if (providedPayrun) {
      payrun2 = providedPayrun;
    }
    let payrunEntry2 = providedPayrunEntry != null ? providedPayrunEntry : payslip && (payslip == null ? void 0 : payslip.userId) && (payrun2 == null ? void 0 : payrun2.entries.find((e) => e.userId === (payslip == null ? void 0 : payslip.userId)));
    if (!payrunEntry2) {
      showMessage("Failed to find payrun entry", "error");
    }
    setPayrun(payrun2 != null ? payrun2 : null);
  }), [payslip, providedPayrunEntry, showMessage]);
  useEffect(() => {
    refreshPayrun();
  }, [refreshPayrun]);
  const { getCachedUserById } = useCachedUsers();
  const user = providedUser != null ? providedUser : (payslip == null ? void 0 : payslip.userId) && getCachedUserById(payslip == null ? void 0 : payslip.userId);
  const payrunEntry = providedPayrunEntry != null ? providedPayrunEntry : payslip && (payslip == null ? void 0 : payslip.userId) && (payrun == null ? void 0 : payrun.entries.find((e) => e.userId === (payslip == null ? void 0 : payslip.userId)));
  return /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open: !!user, onClose, onOpen, children: user && payrun && payrunEntry && /* @__PURE__ */ jsx(Box, { sx: { px: spacing.px40, py: spacing.p30, width: "440px", boxSizing: "border-box", maxWidth: "100vw" }, children: /* @__PURE__ */ jsx(
    GlobalPayrollSalarySummaryPage,
    {
      user,
      payrunEntry,
      globalPaycodes: payrun.paycodes,
      payslip,
      handleEditAction,
      payrun
    }
  ) }) });
};
