"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ChangeOwnerUserForm } from "@v2/feature/device/components/device-details/change-owner-user-form.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from "@/lib/routes";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { ChangeOwnerCompanyInventoryForm } from "@/v2/feature/device/components/device-details/change-owner-company-inventory-form.component";
import { ChangeOwnerZeltStorageForm } from "@/v2/feature/device/components/device-details/change-owner-zelt-storage-form.component";
import { DevicePossessionType } from "@/v2/feature/device/device.interface";
const TabFilter = [
  { name: "Employee", value: "Employee" },
  { name: "Zelt storage", value: DevicePossessionType.ZeltStorage },
  { name: "Company inventory", value: DevicePossessionType.CompanySite }
];
export const ChangeOwnerDrawer = ({
  isOpen,
  onClose,
  devicePossession,
  setIsOpen
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(ChangeOwnerDrawerContent, { devicePossession, closePage: onClose }) });
const ChangeOwnerDrawerContent = ({ devicePossession, closePage }) => {
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const [filterValue, setFilterValue] = useState("Employee");
  const routerHistory = useHistory();
  const changeOwner = (currentDevicePossessionId, transitData) => __async(void 0, null, function* () {
    let isChangeOwnerRequested = false;
    try {
      yield DeviceAPI.changeDeviceOwnerTransitRequest(currentDevicePossessionId, transitData);
      isChangeOwnerRequested = true;
      showMessage("Owner change has been initiated successfully", "success");
      routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
      const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      yield closePage();
    } catch (error) {
      if (isChangeOwnerRequested) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not initiate owner change. ${nestErrorMessage(error)}`, "error");
      }
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Change owner" }),
    /* @__PURE__ */ jsx(
      TabFilterButtons,
      {
        filters: TabFilter,
        setFilterValue,
        filterValue,
        onFilterChange: ({ filterValue: filterValue2 }) => {
          setFilterValue(filterValue2);
        }
      }
    ),
    filterValue === "Employee" && /* @__PURE__ */ jsx(ChangeOwnerUserForm, { currentDevicePossession: devicePossession, changeOwner }),
    filterValue === DevicePossessionType.ZeltStorage && /* @__PURE__ */ jsx(ChangeOwnerZeltStorageForm, { devicePossession, closePage }),
    filterValue === DevicePossessionType.CompanySite && /* @__PURE__ */ jsx(ChangeOwnerCompanyInventoryForm, { devicePossession, closePage })
  ] });
};
