"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ReportConfigBackButton } from "@v2/feature/reports/reports-advanced/components/report-config-back-button.component";
import {
  ReportDisplayFormat,
  ReportDisplayFormatDateOptions,
  ReportDisplayFormatDateTimeOptions,
  ReportDisplayFormatNumberOptions
} from "@v2/feature/reports/reports-formatting.util";
import {
  FilterTypeOption,
  ReportColumnType
} from "@v2/feature/reports/reports.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
const OperatorToLabel = {
  [ReportColumnType.PLAIN]: "None",
  [ReportColumnType.AGE]: "Time since",
  [ReportColumnType.DAY]: "Day Only",
  [ReportColumnType.MONTH]: "Month Only",
  [ReportColumnType.YEAR]: "Year Only",
  [ReportColumnType.COUNT_DAY_ONLY]: "Count Day Only",
  [ReportColumnType.COUNT_MONTH_ONLY]: "Count Month Only",
  [ReportColumnType.COUNT_YEAR_ONLY]: "Count Year Only",
  [ReportColumnType.SUM]: "Total",
  [ReportColumnType.AVG]: "Average",
  [ReportColumnType.MIN]: "Min",
  [ReportColumnType.MAX]: "Max",
  [ReportColumnType.COUNT]: "Count"
};
function getColumnToBeReplaced(selectedColumns, column) {
  var _a, _b;
  if (!column) return null;
  const col = (_b = (_a = selectedColumns[column.stub]) == null ? void 0 : _a.find(
    (c) => c.col === column.col && c.type === column.type && c.order === column.order
  )) != null ? _b : null;
  return col ? __spreadValues({}, col) : null;
}
const FilterTypeOptionToReportDisplayFormatFunction = {
  [FilterTypeOption.string]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.number]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.number1;
  },
  [FilterTypeOption.boolean]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.intOption]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.stringOption]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.dateonly]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    if (opType === ReportColumnType.AGE) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.DAY) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.MONTH) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.YEAR) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.COUNT_DAY_ONLY) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.COUNT_MONTH_ONLY) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.COUNT_YEAR_ONLY) return ReportDisplayFormat.none;
    return ReportDisplayFormat.dateonly1;
  },
  [FilterTypeOption.datetime]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    if (opType === ReportColumnType.AGE) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.DAY) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.MONTH) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.YEAR) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.COUNT_DAY_ONLY) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.COUNT_MONTH_ONLY) return ReportDisplayFormat.none;
    if (opType === ReportColumnType.COUNT_YEAR_ONLY) return ReportDisplayFormat.none;
    return ReportDisplayFormat.datetime1;
  },
  [FilterTypeOption.time]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  }
};
function getDefaultOutputDisplayFormat(columnObjType, reportColumnType) {
  if (!columnObjType) return ReportDisplayFormat.none;
  const fn = FilterTypeOptionToReportDisplayFormatFunction[columnObjType];
  if (!fn) return ReportDisplayFormat.none;
  return fn(reportColumnType);
}
export const ColumnEditSection = ({
  selectedColumns,
  setSelectedColumns,
  reportColumns,
  column,
  goBack
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const columnToBeReplaced = useMemo(() => getColumnToBeReplaced(selectedColumns, column), [selectedColumns, column]);
  const columnObj = useMemo(() => {
    if (!column) return null;
    const tableStub = reportColumns.find((c) => c.stub === column.stub);
    return tableStub == null ? void 0 : tableStub.columns[column.col];
  }, [column, reportColumns]);
  const aggregationOptions = useMemo(() => {
    return (columnObj == null ? void 0 : columnObj.operators) ? columnObj.operators.map((o) => ({ label: OperatorToLabel[o], value: o })) : [
      {
        label: OperatorToLabel[ReportColumnType.PLAIN],
        value: ReportColumnType.PLAIN
      }
    ];
  }, [columnObj]);
  const onSubmit = useCallback(
    (values) => {
      if (!column) return;
      setSelectedColumns((prev) => {
        const prevCopy = __spreadValues({}, prev);
        const index = prevCopy[column.stub].findIndex(
          (c) => c.col === column.col && c.type === column.type && c.order === column.order
        );
        if (index >= 0 && prevCopy[column.stub][index]) {
          prevCopy[column.stub][index].type = values.type;
          prevCopy[column.stub][index].label = values.label;
          prevCopy[column.stub][index].format = values.format;
        }
        return prevCopy;
      });
      goBack();
    },
    [column, setSelectedColumns, goBack]
  );
  const formik = useFormik({
    initialValues: {
      label: (_a = columnToBeReplaced == null ? void 0 : columnToBeReplaced.label) != null ? _a : "",
      type: (_b = columnToBeReplaced == null ? void 0 : columnToBeReplaced.type) != null ? _b : ReportColumnType.PLAIN,
      format: (_d = (_c = columnToBeReplaced == null ? void 0 : columnToBeReplaced.format) != null ? _c : getDefaultOutputDisplayFormat(columnObj == null ? void 0 : columnObj.type, columnToBeReplaced == null ? void 0 : columnToBeReplaced.type)) != null ? _d : ReportDisplayFormat.none
    },
    validationSchema: yup.object({
      label: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      type: yup.string().oneOf(Object.values(ReportColumnType), polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      format: yup.string().nullable().oneOf([null, ...Object.values(ReportDisplayFormat)], polyglot.t("ValidationMessages.validValue")).notRequired()
    }),
    onSubmit
  });
  const formatOptions = useMemo(() => {
    if (!columnObj || !columnToBeReplaced) return [];
    return columnObj.type === "number" || formik.values.type === ReportColumnType.COUNT ? ReportDisplayFormatNumberOptions : columnObj.type === "dateonly" ? ReportDisplayFormatDateOptions : columnObj.type === "datetime" ? ReportDisplayFormatDateTimeOptions : [];
  }, [columnObj, columnToBeReplaced, formik.values.type]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: { display: "flex", flexDirection: "column", gap: "10px", height: "100%" }, children: [
    /* @__PURE__ */ jsx(ReportConfigBackButton, { title: "Columns", goBack }),
    /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Edit column" }),
    columnObj && /* @__PURE__ */ jsx(TextfieldComponent, { label: "Field", value: columnObj.label, disabled: true }),
    columnToBeReplaced && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Label",
        name: "label",
        value: formik.values.label,
        onChange: formik.handleChange,
        error: formik.touched.label && !!formik.errors.label,
        helperText: (_e = formik.touched.label && formik.errors.label) != null ? _e : " ",
        endAdornment: "none"
      }
    ),
    columnObj && columnToBeReplaced && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        label: "Aggregation",
        name: "type",
        options: aggregationOptions,
        value: formik.values.type,
        onChange: (e) => {
          formik.handleChange(e);
          const reportColumnType = e.target.value;
          const format = getDefaultOutputDisplayFormat(columnObj == null ? void 0 : columnObj.type, reportColumnType);
          formik.setFieldValue("format", format);
        },
        compareValue: formik.values.type,
        error: !!formik.errors.type && formik.touched.type,
        helperText: formik.touched.type && formik.errors.type,
        disabled: aggregationOptions.length <= 1
      }
    ),
    formatOptions.length > 0 && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        label: "Format",
        name: "format",
        options: formatOptions,
        value: formik.values.format,
        onChange: formik.handleChange,
        compareValue: formik.values.format,
        error: !!formik.errors.format && formik.touched.format,
        helperText: formik.touched.format && formik.errors.format
      }
    ),
    column && columnToBeReplaced && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, children: "Update" }) })
  ] }) });
};
