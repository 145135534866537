"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import {
  StyledTabs,
  StyledTab
} from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
export const PayrunSalaryTabs = ({ tabs, currentTab, onChange, sx }) => {
  return /* @__PURE__ */ jsx(Box, { sx, children: /* @__PURE__ */ jsx(
    StyledTabs,
    {
      value: currentTab || false,
      scrollButtons: "auto",
      variant: "scrollable",
      allowScrollButtonsMobile: true,
      onChange: (_, value) => {
        onChange == null ? void 0 : onChange(value);
      },
      children: tabs.map((label) => /* @__PURE__ */ jsx(
        StyledTab,
        {
          disableRipple: true,
          label: /* @__PURE__ */ jsx(
            "div",
            {
              style: {
                display: "flex",
                alignItems: "center",
                gap: 4,
                textTransform: "inherit"
              },
              children: label
            }
          ),
          value: label
        },
        label
      ))
    }
  ) });
};
