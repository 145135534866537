"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { PolicyPayrollEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-payroll-edit-drawer.component";
import { AttendanceScheduleEditPayrollDrawer } from "@/v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-payroll-drawer.component";
import { EditCustomBenefitPaymentDrawer } from "@/v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-payment-drawer.component";
import { PaymentSettingsNewTypeDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component";
export const PayrollIntegrationSettingsEditDrawer = ({
  isOpen,
  setIsOpen,
  integration,
  refreshAbsences,
  refreshAttendances,
  refreshBenefits,
  refreshExpenses
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    (integration == null ? void 0 : integration.kind) === "absence" && /* @__PURE__ */ jsx(
      PolicyPayrollEditDrawer,
      {
        isOpen,
        setIsOpen,
        absencePolicy: integration.absencePolicy,
        refresh: () => __async(void 0, null, function* () {
          yield refreshAbsences == null ? void 0 : refreshAbsences();
        })
      }
    ),
    (integration == null ? void 0 : integration.kind) === "attendance" && /* @__PURE__ */ jsx(
      AttendanceScheduleEditPayrollDrawer,
      {
        isOpen,
        setIsOpen,
        attendanceSchedule: integration.attendanceSchedule,
        refresh: () => __async(void 0, null, function* () {
          yield refreshAttendances == null ? void 0 : refreshAttendances();
        })
      }
    ),
    (integration == null ? void 0 : integration.kind) === "benefit" && /* @__PURE__ */ jsx(
      EditCustomBenefitPaymentDrawer,
      {
        isOpen,
        setIsOpen,
        customBenefit: integration.benefit,
        refresh: refreshBenefits
      }
    ),
    (integration == null ? void 0 : integration.kind) === "expense" && /* @__PURE__ */ jsx(
      PaymentSettingsNewTypeDrawer,
      {
        isOpen,
        setIsOpen,
        typeForEdit: integration.expense,
        editMode: "payment",
        refreshAllSettings: refreshExpenses
      }
    )
  ] });
};
