"use strict";
import { RequestStatus } from "@/v2/feature/app-integration/features/app-request/app-request.interface";
import { themeColors } from "@/v2/styles/colors.styles";
export const getRequestStatusColor = (status) => {
  switch (status) {
    case RequestStatus.Requested:
      return themeColors.orange;
    case RequestStatus.Completed:
      return themeColors.green;
    case RequestStatus.Scheduled:
      return themeColors.lightBlue;
    case RequestStatus.Rejected:
      return themeColors.darkRed;
    case RequestStatus.Failed:
      return themeColors.red;
    case RequestStatus.Cancelled:
      return themeColors.Grey;
  }
};
export const userCanRequestAppAccess = (requestList) => {
  return (
    // requestList.length === 0 || [].every(..) returns always true (if the array is emtpy)
    requestList.every(
      (eachRequest) => eachRequest.status === RequestStatus.Cancelled || eachRequest.status === RequestStatus.Rejected || eachRequest.status === RequestStatus.Failed || eachRequest.status === RequestStatus.Completed
    )
  );
};
export const userCanCancelAppAccessRequest = (requestList) => {
  return requestList.some((eachRequest) => eachRequest.status === RequestStatus.Requested);
};
export const filterByRequestStatus = (requestList, validValues) => {
  return requestList.filter((r) => r.status && validValues.includes(r.status));
};
export const filterByRequestedApp = (requestList, validValues) => {
  return requestList.filter((r) => r.requestInfo && validValues.includes(r.requestInfo.appStub));
};
