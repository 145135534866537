"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@v2/styles/spacing.styles";
export const SafariBrowsingPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  configurationPayloadOption
}) => {
  var _a, _b;
  const whiteListAllowed = (_a = (configurationPayloadOption == null ? void 0 : configurationPayloadOption.filterWhitelist) && (configurationPayloadOption == null ? void 0 : configurationPayloadOption.filterWhitelist.length) > 0) != null ? _a : false;
  const blackListAllowed = (_b = (configurationPayloadOption == null ? void 0 : configurationPayloadOption.filterBlacklist) && (configurationPayloadOption == null ? void 0 : configurationPayloadOption.filterBlacklist.length) > 0) != null ? _b : false;
  const renderList = (title, items, isAllowed) => /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        padding: "16px",
        gap: "16px",
        borderRadius: "8px",
        border: "1px solid #F1EEEA",
        backgroundColor: isAllowed ? "#FFFFFF" : "#F8F8F8",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
        isAllowed ? items.map((item, index) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: "8px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: item }) }, index)) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "No websites" })
      ]
    }
  );
  return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Safari browsing policy" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", mt: spacing.m20, padding: 0, gap: spacing.g15 }, children: [
      renderList("Allowed websites", (configurationPayloadOption == null ? void 0 : configurationPayloadOption.filterWhitelist) || [], whiteListAllowed),
      renderList("Restricted websites", (configurationPayloadOption == null ? void 0 : configurationPayloadOption.filterBlacklist) || [], blackListAllowed)
    ] })
  ] }) });
};
