"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class BillingInvoiceAPI {
  static getAllInvoices(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/billing/invoice?${searchParams.toString()}`)).data;
    });
  }
  static getAllInvoicesForCompany(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/billing/invoice/all?${searchParams.toString()}`)).data;
    });
  }
  static getAllCharges(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/billing/one-off-charges?${searchParams.toString()}`)).data;
    });
  }
  static createNewChargeAsSuperadmin(data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/billing/one-off-charges`, data);
    });
  }
  static updateExistingChargeAsSuperadmin(originalCompanyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/billing/one-off-charges/${originalCompanyId}/${data.id}`, data);
    });
  }
  static deleteChargeAsSuperadmin(companyId, chargeId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/billing/one-off-charges/${companyId}/${chargeId}`);
    });
  }
  static downloadInvoiceAsSuperAdmin(payload) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/billing/invoice/superadmin/download/${payload.companyId}/${payload.id}`, {
        responseType: "blob"
      })).data;
    });
  }
  static deleteInvoiceAsSuperAdmin(companyId, invoiceId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/billing/invoice/superadmin/${invoiceId}/company/${companyId}`);
    });
  }
  static deleteFailiedBillingPaymentAsSuperAdmin(companyId, invoiceId, failedPaymentId) {
    return __async(this, null, function* () {
      yield axios.delete(
        `/apiv2/billing/invoice/superadmin/${invoiceId}/company/${companyId}/failed-payment/${failedPaymentId}`
      );
    });
  }
  static generateExternalInvoiceAsSuperAdmin(invoiceId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/billing/invoice/superadmin/${invoiceId}/generate-external`);
    });
  }
  static sendEmailReminderOfUnpaidInvoiceAsSuperAdmin(companyId, invoiceId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/billing/invoice/superadmin/${companyId}/${invoiceId}/send-email-reminder`);
    });
  }
  static downloadInvoice(invoiceId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/billing/invoice/download/${invoiceId}`, {
        responseType: "blob"
      })).data;
    });
  }
}
