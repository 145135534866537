"use strict";
import { Extension } from "@tiptap/core";
export function setDefaultColor(htmlString) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  function wrapTextNodes(node) {
    node.childNodes.forEach((child) => {
      if (child.nodeType === 1) {
        const p = document.createElement("p");
        if (!p.style.color) p.style.color = "black";
      } else if (child.nodeType === 1) {
        wrapTextNodes(child);
      }
    });
  }
  wrapTextNodes(tempDiv);
  return tempDiv.innerHTML;
}
export const ColorExtension = Extension.create({
  name: "color",
  addOptions() {
    return {
      types: ["textStyle"]
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => element.style.color || null,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }
              return {
                style: `color: ${attributes.color}`
              };
            }
          }
        }
      }
    ];
  },
  addCommands() {
    return {
      setColor: (color) => ({ chain }) => {
        return chain().setMark("textStyle", { color }).run();
      },
      unsetColor: () => ({ chain }) => {
        return chain().setMark("textStyle", { color: null }).run();
      }
    };
  }
});
