"use strict";
import { jsx } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { GrowthFactorFormContent } from "@/v2/feature/growth/growth-factor/components/growth-factor-form-content.component";
export const GrowthFactorFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  growthFactor,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, sx: { p: 0 }, children: /* @__PURE__ */ jsx(GrowthFactorFormContent, { onClose, growthFactor, refresh }) });
};
