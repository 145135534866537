"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ContractsAPI {
  static findByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/contracts`)).data;
    });
  }
  static createUserContract(userId, newUserContractEntry) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/${userId}/contracts`, newUserContractEntry)).data;
    });
  }
  static updateById(userId, existingUserContractEntry) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/contracts/${existingUserContractEntry.id}`, existingUserContractEntry);
    });
  }
  static deleteById(userId, contractId) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/users/${userId}/contracts/${contractId}`);
    });
  }
  static getContractsByCompanyId(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/superadmin/company/${companyId}/contracts`)).data;
    });
  }
  static bulkDeleteContractsByIds(companyId, contractIds) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/superadmin/company/${companyId}/contracts`, { data: contractIds });
    });
  }
}
export class UserContractEndpoints {
  static findCurrentByUserId(userId) {
    return {
      url: `/apiv2/users/${userId}/contracts/current`
    };
  }
  static getAllCurrent() {
    return { url: `/apiv2/users/contracts/current` };
  }
}
