"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDown.svg";
export const AccordionComponent = styled((props) => /* @__PURE__ */ jsx(MuiAccordion, __spreadProps(__spreadValues({ disableGutters: true, elevation: 0, square: true }, props), { TransitionProps: { unmountOnExit: true }, children: props.children })))(() => ({
  margin: 0,
  padding: 0,
  height: "auto",
  minHeight: "auto",
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));
export const AccordionComponentSummary = styled((props) => /* @__PURE__ */ jsx(MuiAccordionSummary, __spreadValues({ expandIcon: /* @__PURE__ */ jsx(ArrowDown, {}) }, props)))(() => ({
  margin: 0,
  padding: 0,
  height: "auto",
  minHeight: "auto",
  backgroundColor: themeColors.white,
  borderRadius: "15px",
  flexDirection: "row",
  "&:hover": {
    backgroundColor: themeColors.Background
  },
  "& .MuiAccordionSummary-content": {
    marginTop: spacing.m5,
    marginBottom: spacing.m5,
    marginLeft: spacing.m15,
    marginRight: spacing.m15
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: spacing.m15
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-180deg)"
  }
}));
export const AccordionComponentDetails = styled(MuiAccordionDetails)(() => ({
  paddingTop: spacing.p5,
  paddingBottom: spacing.p5,
  paddingLeft: spacing.p15
}));
