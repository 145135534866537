"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { ddMMYYYYToIsoDateString, isoDateStringToDDMMYYY } from "@/v2/infrastructure/date/date-format.util";
export const TypeableDateComponent = (props) => {
  var _b;
  const _a = props, { value, onChange, error, helperText } = _a, textFieldProps = __objRest(_a, ["value", "onChange", "error", "helperText"]);
  const [rawDate, setRawDate] = useState((_b = isoDateStringToDDMMYYY(value != null ? value : "")) != null ? _b : "");
  const editing = useRef(false);
  return /* @__PURE__ */ jsx(
    TextfieldComponent,
    __spreadProps(__spreadValues({}, textFieldProps), {
      value: rawDate,
      onChange: (e) => {
        const inputDate = e.target.value.trim().replace(/[^\d/]/g, "");
        editing.current = true;
        setRawDate(inputDate);
      },
      maxLength: 10,
      helperText: !editing.current && error && helperText,
      error: !editing.current && error,
      clearText: () => {
        editing.current = false;
        setRawDate("");
        onChange == null ? void 0 : onChange("");
      },
      onBlur: () => {
        editing.current = false;
        const iso = ddMMYYYYToIsoDateString(rawDate != null ? rawDate : "");
        if (!iso || Number.isNaN(Date.parse(iso))) {
          onChange == null ? void 0 : onChange(rawDate);
          return;
        }
        onChange == null ? void 0 : onChange(iso);
      }
    })
  );
};
