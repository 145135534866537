"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { ReactComponent as NextBtn } from "@/images/side-bar-icons/NextBtn.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import {
  FieldStructure,
  ProfileCard
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { MissingInformationLabel } from "@/v2/feature/user/features/user-profile/details/components/missing-information-label.component";
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import {
  AboutForm,
  AboutSchema
} from "@/v2/feature/user/features/user-profile/details/components/user-profile-about-form.component";
import {
  getDietaryOptions
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { isDataEmpty } from "@/v2/feature/user/features/user-profile/details/user-profile.util";
import { UserAPI } from "@/v2/feature/user/user.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { hazardMissingField, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const SCOPE = "user.about";
const iconSize = { width: 14, height: 14 };
const skeletonHeight = calculateSkeletonHeight(AboutSchema);
export const AboutCard = ({ userId, onSubmit, missingFields }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [dataO, setDataO] = useState(() => O.none);
  const [isEmpty, setEmpty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const data = yield UserAPI.getUserAbout(userId);
        setDataO(O.some(data));
        setEmpty(isDataEmpty(data, data.customUpdates));
      } catch (error) {
        showMessage(polyglot.t("AboutCard.errorMessages.load"), "error");
      }
    }))();
  }, [polyglot, showMessage, userId, isOpen]);
  const getDomain = (url) => {
    if (url.includes("http")) return url;
    return `https://${url}`;
  };
  return pipe(
    dataO,
    O.fold(
      () => /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          height: skeletonHeight,
          sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
        }
      ),
      (data) => /* @__PURE__ */ jsxs(
        ProfileCard,
        {
          customUpdates: data.customUpdates,
          fieldStubs: ["about", "hobbies", "social", "foodPreferences", "dietaryRestrictions"],
          sx: cardSx,
          children: [
            /* @__PURE__ */ jsxs(Box, { component: "header", sx: cardHeaderSx, children: [
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "h3", sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("AboutCard.other") }),
                /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.about:read"], context: scopesContext, children: (missingFields == null ? void 0 : missingFields.find((rec) => rec.cardId === CustomProfileFormType.About)) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) })
              ] }),
              /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(IconButton, { onClick: () => setIsOpen(true), title: "Edit", sx: tableIconButtonSx, children: isEmpty ? /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }) })
            ] }),
            /* @__PURE__ */ jsx(Box, { component: "section", children: isEmpty ? /* @__PURE__ */ jsx(MissingInformationLabel, {}) : /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
              /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: polyglot.t("AboutCard.about"), fieldValue: data.about, fieldStub: "about" }),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("AboutCard.hobbies"),
                  fieldValue: data.hobbies,
                  fieldStub: "hobbies"
                }
              ),
              (data == null ? void 0 : data.social) && /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("AboutCard.social"),
                  fieldValue: {
                    isLink: true,
                    label: polyglot.t("AboutCard.showProfile"),
                    icon: /* @__PURE__ */ jsx(NextBtn, __spreadValues({}, iconSize)),
                    onClick: () => {
                      if (data == null ? void 0 : data.social) window.open(getDomain(data == null ? void 0 : data.social), "_blank");
                    }
                  },
                  fieldStub: "social"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("AboutCard.foodPreferences"),
                  fieldValue: data.foodPreferences,
                  fieldStub: "foodPreferences"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("AboutCard.dietaryRestrictions"),
                  fieldValue: data.dietaryRestrictions && (() => {
                    const restrictionsList = data.dietaryRestrictions.trim().split(/\s*,\s*/);
                    return getDietaryOptions(polyglot).filter((option) => restrictionsList.includes(option.value)).map(({ label }) => label).join(", ");
                  })(),
                  fieldStub: "dietaryRestrictions"
                }
              ),
              sortCustomFields(data.customUpdates).map(
                (f) => !f.field.isHidden && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: f.field.fieldName, fieldValue: f.value }, f.field.fieldId)
              )
            ] }) }),
            /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
              AboutForm,
              {
                userId,
                initialValues: data,
                onSubmit: (values) => {
                  setDataO(O.some(values));
                  setIsOpen(false);
                  onSubmit(values);
                },
                onClose: () => setIsOpen(false),
                handleRefresh: () => {
                }
              }
            ) }) })
          ]
        }
      )
    )
  );
};
