"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { ColumnsDrawer } from "@v2/components/table/columns-drawer.component";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { StyledTabsComponent } from "@v2/components/theme-components/styled-tabs.component";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { ResultTableOldReports } from "@v2/feature/reports/components/result-table.component";
import {
  ErrorComponent,
  LoadingTableComponent
} from "@v2/feature/reports/features/create-report/sections/util-sections.component";
import {
  UpdateReportDatesDrawer
} from "@v2/feature/reports/features/update-report/people/update-report-dates-drawer.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import {
  DateType
} from "@v2/feature/reports/reports.interface";
import {
  getAllSelectedColumnsFromUserReportColumnsOptions,
  getFilterColumnsPeopleChangeReport,
  getPeopleReportFilters
} from "@v2/feature/reports/util/people-report.util";
import { exportReportCSVOldReports } from "@v2/feature/reports/util/report.util";
import { UserAPI } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableWhiteIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_OVERVIEW } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const PeopleChangeCreateReportResults = ({
  formData,
  setFormData,
  customFields,
  additionalColumns
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false
  });
  const { hasScopes } = useScopes();
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reportName, setReportName] = useState(polyglot.t("PeopleChangeCreateReportResults.new"));
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState("");
  const [filteredData, setFilteredData] = useState(void 0);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState(
    void 0
  );
  const [currentTab, setCurrentTab] = useState(
    formData.entities && formData.entities[0] ? formData.entities[0] : ""
  );
  const tabs = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = formData.entities) == null ? void 0 : _a2.map((entity) => ({
        label: entity,
        value: entity
      }))) != null ? _b2 : [];
    },
    [formData.entities]
  );
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState(
    formData.entities && ((_a = formData.entities) == null ? void 0 : _a.length) > 0 ? (_b = selectedColumns[formData.entities[0]]) != null ? _b : [] : []
  );
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    setCurrentTab((prev) => {
      var _a2, _b2;
      return prev && tabs.some((t) => t.value === prev) ? prev : (_b2 = (_a2 = tabs[0]) == null ? void 0 : _a2.value) != null ? _b2 : "";
    });
  }, [tabs]);
  useEffect(() => {
    var _a2;
    const selectedCols = getAllSelectedColumnsFromUserReportColumnsOptions(
      formData.entities,
      customFields,
      hasScopes,
      additionalColumns,
      polyglot
    );
    setSelectedColumns(selectedCols);
    setSelectedColumnsStrings(currentTab ? (_a2 = selectedCols[currentTab]) != null ? _a2 : [] : []);
    setAllDataLoaded(true);
  }, [polyglot, currentTab, hasScopes, formData.entities, customFields, additionalColumns]);
  useEffect(() => {
    if (!allDataLoaded) return;
    setSelectedColumns((prev) => __spreadProps(__spreadValues({}, prev), { [currentTab]: selectedColumnsStrings }));
  }, [allDataLoaded, currentTab, selectedColumnsStrings]);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [editDates, setEditDates] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);
  const getFilteredData = useCallback(
    (selectedColumns2, filterString2) => __async(void 0, null, function* () {
      setReportLoading(true);
      try {
        const paramObj = {
          domain: formData.domain,
          type: formData.type,
          entities: formData.entities,
          start: formData.start,
          end: formData.end,
          dateType: formData.dateType,
          filters: filterString2,
          columns: selectedColumns2
        };
        let filteredUsers = yield ReportsAPI.generatePeopleCreatedChangeReport(paramObj);
        setReportError(false);
        setFilteredData(filteredUsers);
      } catch (error) {
        setReportError(true);
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setReportLoading(false);
      }
    }),
    [formData, showMessage]
  );
  useEffect(() => {
    if (allDataLoaded) getFilteredData(selectedColumns, filterString);
  }, [allDataLoaded, getFilteredData, selectedColumns, filterString]);
  useEffect(() => {
    var _a2;
    setFilteredAndSearchedData(
      filteredData && currentTab && filteredData[currentTab] ? (_a2 = filteredData[currentTab]) == null ? void 0 : _a2.filter(
        (data) => !searchInput || (data == null ? void 0 : data.employeeName) && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase())
      ) : void 0
    );
  }, [currentTab, searchInput, filteredData]);
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const createReport = {
        domain: formData.domain,
        type: formData.type,
        entities: formData.entities,
        start: formData.start,
        end: formData.end,
        dateType: formData.dateType,
        filters: filterString,
        columns: selectedColumns,
        fileName: reportName
      };
      yield ReportsAPI.saveReport(createReport);
      setFormData(__spreadProps(__spreadValues({}, formData), { filters: filterString }));
      showMessage(polyglot.t("PeopleChangeCreateReportResults.successMessages.save"), "success");
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  }), [polyglot, filterString, formData, reportName, routerHistory, selectedColumns, setFormData, showMessage]);
  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);
  const exportCsv = useCallback(() => {
    try {
      exportReportCSVOldReports(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t("PeopleChangeCreateReportResults.errorMessages.download"), "error");
    }
  }, [polyglot, showMessage, reportName, filteredData]);
  const reportDates = useMemo(() => {
    if (formData.dateType === DateType.Last30days) return polyglot.t("PeopleChangeCreateReportResults.tDays");
    if (formData.dateType === DateType.Last7days) return polyglot.t("PeopleChangeCreateReportResults.sDays");
    if (formData.start && formData.end)
      return `${new LocalDate(formData.start).toLocaleDateString()} - ${new LocalDate(
        formData.end
      ).toLocaleDateString()}`;
  }, [polyglot, formData]);
  const updateReportDates = useCallback(
    (datesUpdate) => __async(void 0, null, function* () {
      if (datesUpdate.dateType && datesUpdate.start && datesUpdate.end) {
        setFormData((prev) => __spreadValues(__spreadValues({}, prev), datesUpdate));
      }
    }),
    [setFormData]
  );
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: [
        reportLoading && /* @__PURE__ */ jsx(LoadingTableComponent, {}),
        !reportLoading && reportError && /* @__PURE__ */ jsx(ErrorComponent, {}),
        !reportLoading && filteredData && /* @__PURE__ */ jsxs(Box, { sx: { width: "80%" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10, minHeight: "60px" }, children: /* @__PURE__ */ jsx(
            EditableTitle,
            {
              variant: "title2",
              value: reportName,
              onChange: (value) => {
                setReportName(value);
              },
              maxLength: 50,
              editButtonTooltip: ((_e = (_d = (_c = state.user.features) == null ? void 0 : _c.report) == null ? void 0 : _d.tooltip) == null ? void 0 : _e.saveReportName) ? void 0 : {
                open: true,
                title: /* @__PURE__ */ jsxs(Stack, { sx: { alignItems: "flex-start", maxWidth: "200px" }, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.white, m: spacing.m10 }), children: polyglot.t("PeopleChangeCreateReportResults.type") }),
                  /* @__PURE__ */ jsx(
                    Button,
                    {
                      onClick: () => __async(void 0, null, function* () {
                        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                          "report",
                          "tooltip",
                          "saveReportName",
                          true
                        );
                        dispatch({
                          type: GlobalStateActions.UPDATE_USER,
                          payload: updatedGlobalUser
                        });
                      }),
                      sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey, textTransform: "none" }),
                      children: polyglot.t("PeopleChangeCreateReportResults.okay")
                    }
                  )
                ] })
              }
            }
          ) }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: reportDates }),
            /* @__PURE__ */ jsx(IconButton, { sx: __spreadValues({}, tableWhiteIconButtonSx), onClick: () => setEditDates(true), children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }),
            /* @__PURE__ */ jsx(
              UpdateReportDatesDrawer,
              {
                isOpen: editDates,
                setIsOpen: setEditDates,
                reportDates: { dateType: formData.dateType, start: formData.start, end: formData.end },
                action: updateReportDates
              }
            )
          ] }),
          /* @__PURE__ */ jsx(
            StyledTooltip,
            {
              open: isTooltipOpen,
              title: /* @__PURE__ */ jsxs(Stack, { sx: { alignItems: "flex-start", maxWidth: "200px" }, children: [
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.white, m: spacing.m10 }), children: polyglot.t("PeopleChangeCreateReportResults.filter") }),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    colorVariant: "tooltip",
                    sizeVariant: "small",
                    onClick: () => {
                      setIsTooltipOpen(false);
                    },
                    children: polyglot.t("PeopleChangeCreateReportResults.okay")
                  }
                )
              ] }),
              children: /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: __spreadProps(__spreadValues({}, spacing.mt20), { display: "flex", alignItems: "center", gap: spacing.g10, width: "fit-content" }),
                  children: [
                    /* @__PURE__ */ jsx(
                      ColumnsDrawer,
                      {
                        columnsOptions: getFilterColumnsPeopleChangeReport(
                          currentTab,
                          formData.entities,
                          customFields,
                          hasScopes,
                          additionalColumns,
                          polyglot
                        ),
                        selectedColumns: selectedColumnsStrings,
                        setSelectedColumns: setSelectedColumnsStrings
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      FiltersDrawer,
                      {
                        filtersOptions: [{ filters: filterTypes }],
                        selectedFilters: filterString,
                        setSelectedFilters: setFilterString,
                        encodedFilterNames: true
                      }
                    ),
                    /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
                      var _a2, _b2;
                      return setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                    } })
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: tabs && /* @__PURE__ */ jsx(StyledTabsComponent, { tabs, currentTab, setCurrentTab }) }),
          /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: filteredAndSearchedData && currentTab && /* @__PURE__ */ jsx(ResultTableOldReports, { filteredData: filteredAndSearchedData, loading }) }),
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: __spreadProps(__spreadValues({
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: spacing.g10
              }, spacing.mt40), {
                width: "50%"
              }),
              children: [
                /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    sizeVariant: "medium",
                    colorVariant: "primary",
                    name: "Save",
                    loading,
                    fullWidth: true,
                    onClick: handleSave
                  }
                ),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    fullWidth: true,
                    sizeVariant: "medium",
                    colorVariant: "secondary",
                    onClick: exportCsv,
                    startIcon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                    children: polyglot.t("PeopleChangeCreateReportResults.downloadCsv")
                  }
                )
              ]
            }
          )
        ] })
      ]
    }
  );
};
