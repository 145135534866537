"use strict";
export const MINUTES_IN_WEEK = 10080;
export const HOURS_40_IN_MINUTES = 2400;
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function compareTimePartOnly(date1, date2) {
  const date1Local = new Date(date1);
  const date2Local = new Date(date2);
  date1Local.setFullYear(0, 0, 0);
  date2Local.setFullYear(0, 0, 0);
  if (date1Local > date2Local) return 1;
  else if (date2Local > date1Local) return -1;
  return 0;
}
