"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  FormControlLabel,
  RadioGroup,
  Typography
} from "@mui/material";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledRadioGroup = (props) => {
  const _a = props, {
    options,
    selectedValue,
    disabled,
    radioSx,
    error,
    helperText,
    labelPlacement = "start"
  } = _a, radioGroupProps = __objRest(_a, [
    "options",
    "selectedValue",
    "disabled",
    "radioSx",
    "error",
    "helperText",
    "labelPlacement"
  ]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(RadioGroup, __spreadProps(__spreadValues({}, radioGroupProps), { value: selectedValue != null ? selectedValue : "", children: options.map((o, idx) => /* @__PURE__ */ jsx(
      FormControlLabel,
      {
        labelPlacement,
        value: o.value || disabled,
        control: /* @__PURE__ */ jsx(StyledRadio, { disableRipple: true }),
        disabled: o.disabled,
        label: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: o.disabled ? themeColors.Grey : themeColors.DarkGrey }), children: o.label }),
        sx: __spreadValues({
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          margin: 0,
          paddingY: spacing.p15,
          borderBottom: idx < options.length - 1 ? borders.background : "none"
        }, radioSx)
      },
      idx
    )) })),
    helperText && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: error ? themeColors.darkRed : themeColors.DarkGrey }), children: helperText })
  ] });
};
