"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  getAnalyticsByType,
  getReviewerTypesChipsByAnswer
} from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ResultsOverviewQuestionDisplayBar = ({
  question,
  growthFactors,
  onClick
}) => {
  var _a, _b;
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      onClick,
      cells: [
        {
          content: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.g8 }, children: [
            question && question.questionText && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: question.questionText }),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
              question.factor && /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "background",
                  name: /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.g4
                      },
                      children: [
                        /* @__PURE__ */ jsx(
                          "div",
                          {
                            style: {
                              backgroundColor: (_b = (_a = growthFactors[question.factor]) == null ? void 0 : _a.color) != null ? _b : themeColors.Grey,
                              height: "8px",
                              width: "8px",
                              borderRadius: radius.br25
                            }
                          }
                        ),
                        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: question.factor })
                      ]
                    }
                  )
                }
              ),
              question.type === QuestionType.ScaleQuestion && question.scaleConfig && /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "background",
                  name: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: question.scaleConfig.type })
                }
              ),
              question.answers && question.answers.length > 0 && getReviewerTypesChipsByAnswer(question.answers)
            ] })
          ] }),
          gridXs: 8
        },
        {
          content: getAnalyticsByType(question),
          gridXs: 3,
          sx: { display: "flex", justifyContent: "flex-start" }
        },
        {
          content: /* @__PURE__ */ jsx(Fragment, {}),
          gridXs: 1
        }
      ]
    }
  );
};
