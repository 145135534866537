"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { AccountType } from "@v2/feature/payments/features/accounting-transaction/accounting-transaction.interface";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
const getZeltRevenue = (records) => {
  return records.filter(
    (record) => !record.companyId && !record.userId && !record.subaccountCompanyId && !record.subaccountUserId && record.accountType === AccountType.Revenue
  ).reduce((sum, record) => {
    var _a;
    return sum + ((_a = record.credit) != null ? _a : 0);
  }, 0);
};
const getZeltCash = (records) => {
  return records.filter(
    (record) => !record.companyId && !record.userId && !record.subaccountCompanyId && !record.subaccountUserId && record.accountType === AccountType.Cash
  ).reduce((sum, record) => {
    var _a;
    return sum + ((_a = record.debit) != null ? _a : 0);
  }, 0);
};
const getCompanyCash = (records, companyId) => {
  return records.filter(
    (record) => !record.companyId && !record.userId && record.subaccountCompanyId === companyId && !record.subaccountUserId && record.accountType === AccountType.Cash
  ).reduce((sum, record) => {
    var _a;
    return sum + ((_a = record.credit) != null ? _a : 0);
  }, 0);
};
const getCompanyDebit = (records, companyId) => {
  return records.filter(
    (record) => !record.companyId && !record.userId && record.subaccountCompanyId === companyId && !record.subaccountUserId && record.accountType === AccountType.Deposit
  ).reduce((sum, record) => {
    var _a;
    return sum + ((_a = record.debit) != null ? _a : 0);
  }, 0);
};
const getUserCash = (records, companyId, userId) => {
  return records.filter(
    (record) => !record.companyId && !record.userId && record.subaccountCompanyId === companyId && record.subaccountUserId === userId && record.accountType === AccountType.Cash
  ).reduce((sum, record) => {
    var _a;
    return sum + ((_a = record.debit) != null ? _a : 0);
  }, 0);
};
const getUserDeposit = (records, companyId, userId) => {
  return records.filter(
    (record) => !record.companyId && !record.userId && record.subaccountCompanyId === companyId && record.subaccountUserId === userId && record.accountType === AccountType.Deposit
  ).reduce((sum, record) => {
    var _a;
    return sum + ((_a = record.credit) != null ? _a : 0);
  }, 0);
};
const AccountingTransactionsOverview = ({
  title,
  label1,
  value1,
  label2,
  value2
}) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mb: spacing.mb10 }), children: title }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            width: "150px",
            display: "flex",
            gap: spacing.gap10,
            justifyContent: "space-between",
            alignItems: "center"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: label1 }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: value1 })
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            width: "150px",
            display: "flex",
            gap: spacing.gap10,
            justifyContent: "space-between",
            alignItems: "center"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: label2 }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: value2 })
          ]
        }
      )
    ] })
  ] });
};
export const AccountingTransactionSummary = ({
  accountingTransactions,
  companyId,
  companyName,
  users
}) => {
  const userIdsIncludedInTransactions = [
    ...new Set(
      accountingTransactions.filter(
        (record) => !record.companyId && !record.userId && record.subaccountCompanyId === companyId && !!record.subaccountUserId && [AccountType.Deposit, AccountType.Cash].includes(record.accountType)
      ).map((record) => record.subaccountUserId)
    )
  ];
  return /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, spacing.px40), { display: "flex", gap: spacing.gap60, mb: spacing.mb30 }), children: [
    /* @__PURE__ */ jsx(
      AccountingTransactionsOverview,
      {
        title: "Zelt",
        label1: "Revenue",
        value1: `\xA3${getZeltRevenue(accountingTransactions)}`,
        label2: "Cash",
        value2: `\xA3${getZeltCash(accountingTransactions)}`
      },
      "zelt"
    ),
    /* @__PURE__ */ jsx(
      AccountingTransactionsOverview,
      {
        title: companyName,
        label1: "Deposit",
        value1: `\xA3${getCompanyCash(accountingTransactions, companyId)}`,
        label2: "Cash",
        value2: `\xA3${getCompanyDebit(accountingTransactions, companyId)}`
      },
      "company"
    ),
    userIdsIncludedInTransactions.map((userId) => {
      const user = users.find((user2) => user2.userId === userId);
      return /* @__PURE__ */ jsx(
        AccountingTransactionsOverview,
        {
          title: user ? `${user.firstName} ${user.lastName}` : `User ${userId}`,
          label1: "Deposit",
          value1: `\xA3${getUserDeposit(accountingTransactions, companyId, userId)}`,
          label2: "Cash",
          value2: `\xA3${getUserCash(accountingTransactions, companyId, userId)}`
        },
        `user-${userId}`
      );
    })
  ] });
};
