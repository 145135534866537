"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { FieldCard } from "@v2/components/field-card.component";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { UserCustomBenefitRequestType } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { isRecurringBenefit } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { BenefitOptOutDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-opt-out-drawer.component";
import { BenefitRequestViewDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-request-view-drawer.component";
import { UserBenefitOverviewTitle } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/user-benefit-overview-title.component";
import { UserCustomBenefitRequestDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit-request-drawer.component";
import {
  UserCustomBenefitRequestStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto";
import { UserCustomBenefitListTableComponent } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-list-table.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { generatePath, matchPath, useLocation } from "react-router-dom";
import useScopes from "@/hooks/scopes.hook";
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE, USER_BENEFITS_ROUTE } from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { RootStyle } from "@/v2/styles/root.styles";
export const RecurringBenefitOverview = ({
  userId,
  userCustomBenefit,
  loading,
  refresh
}) => {
  var _a, _b, _c, _d, _e;
  const {
    data: userBenefitsRequests,
    mutate: refreshReq
  } = useApiClient(
    CustomBenefitEndpoints.getUserCustomBenefitRequestsByBenefitId(
      userId,
      userCustomBenefit.customBenefitId,
      userCustomBenefit.id
    ),
    { suspense: false }
  );
  const { polyglot } = usePolyglot();
  const location = useLocation();
  const { hasScopes } = useScopes();
  const [isBenefitRequestOpen, setIsBenefitRequestOpen] = useState(false);
  const [isOptOutOpen, setIsOptOutOpen] = useState(false);
  const [viewRequest, setViewRequest] = useState(false);
  const [viewOptOutRequest, setViewOptOutRequest] = useState(false);
  const hasNoPendingChangeRequest = useMemo(() => {
    if (!userBenefitsRequests) return void 0;
    return userBenefitsRequests.filter(
      (req) => req.type === UserCustomBenefitRequestType.RecurringChange && req.status === UserCustomBenefitRequestStatus.Pending
    ).length === 0;
  }, [userBenefitsRequests]);
  const hasNoPendingOptOutRequest = useMemo(() => {
    if (!userBenefitsRequests) return void 0;
    return userBenefitsRequests.filter(
      (req) => req.type === UserCustomBenefitRequestType.OptOut && req.status === UserCustomBenefitRequestStatus.Pending
    ).length === 0;
  }, [userBenefitsRequests]);
  const refreshRequests = useCallback(() => __async(void 0, null, function* () {
    if (refreshReq) yield refreshReq();
  }), [refreshReq]);
  const pendingChangeRequest = useMemo(
    () => userBenefitsRequests == null ? void 0 : userBenefitsRequests.find(
      (req) => req.type === UserCustomBenefitRequestType.RecurringChange && req.status === UserCustomBenefitRequestStatus.Pending
    ),
    [userBenefitsRequests]
  );
  const pendingOptOutRequest = useMemo(
    () => userBenefitsRequests == null ? void 0 : userBenefitsRequests.find(
      (req) => req.type === UserCustomBenefitRequestType.OptOut && req.status === UserCustomBenefitRequestStatus.Pending
    ),
    [userBenefitsRequests]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(UserBenefitOverviewTitle, { userCustomBenefit }),
        showBack: true,
        backPath: matchPath(location.pathname, USER_BENEFITS_ROUTE) ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId }) : BENEFITS_ME_ROUTE,
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
          hasNoPendingOptOutRequest && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => {
                setIsOptOutOpen(true);
              },
              children: polyglot.t("BenefitModule.optOut")
            }
          ),
          hasNoPendingChangeRequest && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "primary",
              sizeVariant: "small",
              onClick: () => {
                setIsBenefitRequestOpen(true);
              },
              children: polyglot.t("BenefitModule.requestChange")
            }
          )
        ] }),
        showAction: Boolean(
          hasScopes(["insurance"], { userId }) && userCustomBenefit && isRecurringBenefit((_a = userCustomBenefit.customBenefit) == null ? void 0 : _a.type)
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { mt: "20px", mb: "10px" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { width: "600px", display: "flex", gap: "5px", alignItems: "center", mb: "30px" }, children: [
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.employerContribution"),
            value: formatMoney({
              amount: (_b = userCustomBenefit.employerContribution) != null ? _b : 0,
              asDecimal: true
            }),
            action: void 0,
            color: "secondary"
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "+" }),
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.employeeContribution"),
            value: formatMoney({
              amount: (_c = userCustomBenefit.employeeContribution) != null ? _c : 0,
              asDecimal: true
            }),
            color: "warning",
            action: void 0
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "=" }),
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.totalValue"),
            value: formatMoney({
              amount: ((_d = userCustomBenefit.employerContribution) != null ? _d : 0) + ((_e = userCustomBenefit.employeeContribution) != null ? _e : 0),
              asDecimal: true
            }),
            action: void 0,
            color: "success"
          }
        )
      ] }),
      pendingOptOutRequest && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", mb: "20px" }, children: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
        polyglot.t("BenefitModule.optOutRequested"),
        /* @__PURE__ */ jsx(
          "span",
          {
            onClick: () => {
              setViewOptOutRequest(true);
            },
            style: { marginLeft: "5px", cursor: "pointer", color: themeColors.pressBlue },
            children: polyglot.t("BenefitModule.seeMoreDetailsHere")
          }
        )
      ] }) }),
      pendingChangeRequest && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", mb: "20px" }, children: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
        polyglot.t("BenefitModule.changeRequested"),
        ".",
        /* @__PURE__ */ jsx(
          "span",
          {
            onClick: () => {
              setViewRequest(true);
            },
            style: { cursor: "pointer", color: themeColors.pressBlue },
            children: polyglot.t("BenefitModule.seeMoreDetailsHere")
          }
        )
      ] }) }),
      /* @__PURE__ */ jsx(
        UserCustomBenefitListTableComponent,
        {
          benefitId: userCustomBenefit.customBenefitId,
          userBenefit: userCustomBenefit,
          userId
        }
      ),
      /* @__PURE__ */ jsx(
        UserCustomBenefitRequestDrawer,
        {
          isOpen: isBenefitRequestOpen,
          setIsOpen: setIsBenefitRequestOpen,
          userBenefit: userCustomBenefit,
          onClose: () => {
            setIsBenefitRequestOpen(false);
          },
          refresh: refreshRequests,
          type: UserCustomBenefitRequestType.RecurringChange
        }
      ),
      pendingChangeRequest && /* @__PURE__ */ jsx(
        BenefitRequestViewDrawer,
        {
          isOpen: viewRequest,
          setIsOpen: setViewRequest,
          request: pendingChangeRequest,
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refresh(), refreshRequests()]);
          })
        }
      ),
      pendingOptOutRequest && /* @__PURE__ */ jsx(
        BenefitRequestViewDrawer,
        {
          isOpen: viewOptOutRequest,
          setIsOpen: setViewOptOutRequest,
          request: pendingOptOutRequest,
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refresh(), refreshRequests()]);
          })
        }
      ),
      /* @__PURE__ */ jsx(
        BenefitOptOutDrawer,
        {
          isOpen: isOptOutOpen,
          setIsOpen: setIsOptOutOpen,
          userId,
          userBenefit: userCustomBenefit,
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([refreshRequests(), refresh()]);
          })
        }
      )
    ] })
  ] });
};
