"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import {
  EXPENSES_COMPANY_OVERVIEW_ROUTE,
  EXPENSES_COMPANY_ROUTE,
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  EXPENSE_COMPANY_NEW_EXPENSE_ROUTE
} from "@/lib/routes";
import { ExpensePage } from "@/v2/feature/expense/expense.page";
import { NewExpensePage } from "@/v2/feature/payments/pages/new-expense.page";
export const ExpenseCompanyRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["expenses:all"], path: EXPENSES_COMPANY_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(ExpensePage, { reach: "company" }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["expenses:all"], path: EXPENSE_COMPANY_NEW_EXPENSE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(NewExpensePage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["expenses:all"], path: EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(NewExpensePage, { editMode: true }) }),
    /* @__PURE__ */ jsx(Redirect, { from: EXPENSES_COMPANY_ROUTE, to: EXPENSES_COMPANY_OVERVIEW_ROUTE, exact: true })
  ] });
};
