"use strict";
import { jsx } from "react/jsx-runtime";
import { Box, SwipeableDrawer } from "@mui/material";
import { SalarySummaryPage } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/salary-summary-page.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const SalarySummaryDrawer = ({
  onOpen = () => {
  },
  onClose = () => {
  },
  userId,
  localPayRun,
  payrunEntry,
  customPayCodes
}) => {
  const { getCachedUserById } = useCachedUsers();
  const user = userId && getCachedUserById(userId);
  return /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open: !!user, onClose, onOpen, children: user && payrunEntry && /* @__PURE__ */ jsx(Box, { sx: { px: spacing.px40, py: spacing.p30, width: "440px", boxSizing: "border-box", maxWidth: "100vw" }, children: /* @__PURE__ */ jsx(
    SalarySummaryPage,
    {
      user,
      payrunEntry,
      customPayCodes,
      localPayRun
    }
  ) }) });
};
