"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { OffboardingUserRouter } from "@v2/feature/offboarding/offboarding-user.router";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { OFFBOARDING_ROUTE, USER_OFFBOARDING_ROUTE } from "@/lib/routes";
import { OffboardingPage } from "@/v2/feature/offboarding/offboarding.page";
export const OffboardingRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: globalState.user.userId });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["user.onboard:all"], path: USER_OFFBOARDING_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(OffboardingUserRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["user.onboard:all"], path: OFFBOARDING_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(OffboardingPage, {}) })
  ] });
};
