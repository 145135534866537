"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { ADMIN_USER_REVIEWS_RESULT_ROUTE, ADMIN_USER_REVIEWS_ROUTE } from "@/lib/routes";
import { ReviewUserResultPage } from "@/v2/feature/growth/reviews/features/review-user/review-user-result/review-user-result.page";
export const UserDetailsGrowthRouter = () => {
  const userId = useUserIdParam();
  const { getScopesContext } = useScopes();
  const context = getScopesContext({ userId });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reviews"], context, path: ADMIN_USER_REVIEWS_RESULT_ROUTE, children: /* @__PURE__ */ jsx(ReviewUserResultPage, { targetUserId: userId }) }),
    /* @__PURE__ */ jsx(Redirect, { from: ADMIN_USER_REVIEWS_ROUTE, to: ADMIN_USER_REVIEWS_RESULT_ROUTE })
  ] });
};
