"use strict";
import { PAYMENT_COMPANY_OVERVIEW_ROUTE, PAYMENT_TEAM_OVERVIEW_ROUTE } from "@/lib/routes";
export const MONEY_PAYMENTS_OVERVIEW_ROUTES = {
  company: PAYMENT_COMPANY_OVERVIEW_ROUTE,
  // me: PAYMENT_ME_OVERVIEW_ROUTE,
  team: PAYMENT_TEAM_OVERVIEW_ROUTE
};
export const MONEY_PAYMENTS_SCOPES = {
  company: ["payments:all"],
  team: ["payments:manager"]
  // me: ['payments'],
};
