"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import { getAllUsersCurrentRecordsEntitiesNames } from "@v2/feature/reports/util/people-report.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { subDays } from "date-fns";
import dayjs from "dayjs";
import useScopes from "@/hooks/scopes.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import {
  DateType,
  reportTypeArray,
  ReportTypePeopleSelection
} from "@/v2/feature/reports/reports.interface";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const TabFilter = (polyglot) => {
  return [
    { name: polyglot.t("TabFilter.thirtyDays"), value: DateType.Last30days },
    { name: polyglot.t("TabFilter.sevenDays"), value: DateType.Last7days },
    { name: polyglot.t("TabFilter.select"), value: DateType.SelectDates }
  ];
};
export const PeopleReportType = ({
  onNext,
  setFormData,
  formData
}) => {
  const { polyglot } = usePolyglot();
  const [filterString, setFilterString] = useState(DateType.Last30days);
  const { hasScopes } = useScopes();
  const CurrentRecordsEntities = useMemo(() => {
    return getAllUsersCurrentRecordsEntitiesNames(hasScopes);
  }, [hasScopes]);
  useEffect(() => {
    if (formData.type === ReportTypePeopleSelection.CurrentRecords)
      setFormData((prevForm) => __spreadProps(__spreadValues({}, prevForm), { entities: CurrentRecordsEntities }));
  }, [setFormData, CurrentRecordsEntities, formData.type]);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: polyglot.t("PeopleReportType.select") }),
        /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({ width: "100%" }, spacing.mt20), children: /* @__PURE__ */ jsx(
          RadioGroup,
          {
            value: formData.type,
            onChange: (event) => {
              const type = event.target.value;
              setFormData((prevForm) => __spreadProps(__spreadValues({}, prevForm), {
                type,
                entities: type === ReportTypePeopleSelection.ChangeReport ? [] : CurrentRecordsEntities
              }));
            },
            sx: { flexFlow: "column" },
            children: reportTypeArray(polyglot).map((d, idx) => /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "start",
                value: d.name,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                disabled: false,
                label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: d.label }),
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.captionSmall), { color: themeColors.Grey }), children: d.desc })
                ] }),
                sx: {
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: idx < reportTypeArray(polyglot).length - 1 ? borders.background : "none"
                }
              },
              idx
            ))
          }
        ) }),
        formData.type === ReportTypePeopleSelection.ChangeReport && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10 }, children: /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            filters: TabFilter(polyglot),
            setFilterValue: setFilterString,
            filterValue: filterString,
            onFilterChange: ({ filterValue }) => {
              if (filterValue === DateType.Last30days) {
                setFormData(__spreadProps(__spreadValues({}, formData), {
                  start: new LocalDate(subDays(/* @__PURE__ */ new Date(), 30)).toDateString(),
                  end: new LocalDate().toDateString(),
                  dateType: DateType.Last30days
                }));
              } else if (filterValue === DateType.Last7days) {
                setFormData(__spreadProps(__spreadValues({}, formData), {
                  start: new LocalDate(subDays(/* @__PURE__ */ new Date(), 7)).toDateString(),
                  end: new LocalDate().toDateString(),
                  dateType: DateType.Last7days
                }));
              } else {
                setFormData(__spreadProps(__spreadValues({}, formData), {
                  dateType: DateType.SelectDates
                }));
              }
              setFilterString(filterValue);
            }
          }
        ) }),
        filterString === DateType.SelectDates && formData.type === ReportTypePeopleSelection.ChangeReport && /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
            DatePickerComponent,
            {
              inputFormat: "DD/MM/YYYY",
              value: formData.start,
              onChange: (value) => {
                if (dayjs(value).isValid()) {
                  setFormData(__spreadProps(__spreadValues({}, formData), { start: value }));
                }
              },
              name: "startDate",
              label: polyglot.t("PeopleReportType.startDate")
            }
          ) }) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
            DatePickerComponent,
            {
              inputFormat: "DD/MM/YYYY",
              value: formData.end,
              onChange: (value) => {
                if (dayjs(value).isValid()) {
                  setFormData(__spreadProps(__spreadValues({}, formData), { end: value }));
                }
              },
              name: "endDate",
              label: polyglot.t("PeopleReportType.endDate")
            }
          ) }) })
        ] }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "large",
            colorVariant: "primary",
            style: { marginTop: spacing.m25 },
            onClick: onNext,
            fullWidth: true,
            children: polyglot.t("General.next")
          }
        )
      ] })
    }
  );
};
