"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Typography } from "@v2/components/typography/typography.component";
import { getDeviceLastActiveInDays } from "@v2/feature/device/device.util";
import { spacing } from "@v2/styles/spacing.styles";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
export const SecurityDetailsInformation = ({ devicePossession }) => {
  var _a;
  const device = devicePossession.device;
  const lastActiveString = useMemo(() => {
    var _a2;
    const deviceLastActiveInDays = getDeviceLastActiveInDays((_a2 = devicePossession.device) == null ? void 0 : _a2.lastCheckIn);
    if (!device.lastCheckIn) {
      return "NA";
    }
    const lastCheckInDate = new Date(device.lastCheckIn);
    if (isNaN(lastCheckInDate.getTime())) {
      return "N/A";
    }
    const dateString = device.inHouseMdm ? lastCheckInDate.toUTCString() : lastCheckInDate.toUTCString().split(" ").slice(0, 4).join(" ");
    return /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: dateString }),
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "red", sx: { ml: spacing.g5 }, children: deviceLastActiveInDays.isOverdue && `${deviceLastActiveInDays.daysDifference} days ago` })
    ] });
  }, [device.inHouseMdm, device.lastCheckIn, (_a = devicePossession.device) == null ? void 0 : _a.lastCheckIn]);
  return /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          title: "",
          items: [
            {
              type: SectionItemType.Pair,
              label: "Last check in",
              value: lastActiveString,
              hidden: !device.lastCheckIn
            },
            {
              type: SectionItemType.TextLine,
              value: "No activity",
              hidden: Boolean(device.lastCheckIn)
            }
          ]
        }
      ]
    }
  );
};
