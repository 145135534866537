"use strict";
import { isToday as isTodayDateNfs } from "date-fns";
export function dateDiffInDays(dateA, dateB) {
  const MsPerDay = 864e5;
  const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
  const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());
  return Math.floor((utc1 - utc2) / MsPerDay);
}
export const dateIncludedInLastXHours = (date, X) => {
  const now = Math.round((/* @__PURE__ */ new Date()).getTime() / 1e3);
  const nowMinusXH = now - X * 3600;
  return new Date(date).getTime() >= new Date(nowMinusXH * 1e3).getTime();
};
export const isToday = (date) => {
  return isTodayDateNfs(date);
};
