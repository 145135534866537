"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { PayItemEndpoints } from "@v2/feature/payroll/features/pay-item/pay-item.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { PayItemsPreviewList } from "./pay-items-preview-list.component";
export const AbsenceDrawerPayItemPreview = ({ userId, absenceId }) => {
  const { data: oneOffPayments, mutate: refreshPayments } = useApiClient(
    PayItemEndpoints.getUserOneOffPaymentsByAbsence(userId, absenceId),
    {
      suspense: false
    }
  );
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshPayments) yield refreshPayments();
  }), [refreshPayments]);
  return oneOffPayments && oneOffPayments.length > 0 ? /* @__PURE__ */ jsx(PayItemsPreviewList, { oneOffPayments, refresh }) : null;
};
