"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { TemplateAPI } from "@/api-client/templates.api";
import { SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const validationSchema = yup.object().shape({
  templateId: yup.string().required("Select a contract template")
});
export const EditContracts = ({ contract, onSave }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [contractTemplates, setContractTemplates] = useState();
  const routerHistory = useHistory();
  const refreshContractTemplates = useCallback(() => __async(void 0, null, function* () {
    TemplateAPI.getTemplates().then(setContractTemplates);
  }), []);
  const formik = useFormik({
    initialValues: {
      templateId: (_a = contract == null ? void 0 : contract.templateId) != null ? _a : ""
    },
    validationSchema,
    onSubmit(values) {
      const selectedContract = contractTemplates == null ? void 0 : contractTemplates.find((t) => t.id === values.templateId);
      if (!selectedContract) return;
      onSave({
        templateId: selectedContract.id,
        templateName: selectedContract.name
      });
    }
  });
  useEffect(() => {
    refreshContractTemplates();
  }, [refreshContractTemplates]);
  const templateOptions = useMemo(() => {
    return contractTemplates == null ? void 0 : contractTemplates.sort((a, b) => a.name.localeCompare(b.name, void 0, { sensitivity: "base" })).map((t) => ({
      label: t.name,
      value: t.id
    }));
  }, [contractTemplates]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: polyglot.t("EditContracts.select") }),
    !templateOptions && /* @__PURE__ */ jsx(LoadingSpinner, {}),
    templateOptions && templateOptions.length > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.mt10 }), children: polyglot.t("EditContracts.chooseJoiner") }),
      /* @__PURE__ */ jsxs("form", { onSubmit: formik.handleSubmit, children: [
        /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.mt30, gap: spacing.g20 }, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "templateId",
            label: polyglot.t("EditContracts.template"),
            options: templateOptions,
            onChange: formik.handleChange,
            value: (_b = formik.values.templateId) != null ? _b : null,
            error: formik.touched.templateId && Boolean(formik.errors.templateId),
            helperText: formik.touched.templateId && formik.errors.templateId
          }
        ) }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            type: "submit",
            fullWidth: true,
            style: { marginTop: "40px" },
            colorVariant: "primary",
            sizeVariant: "large",
            children: polyglot.t("General.save")
          }
        )
      ] })
    ] }),
    templateOptions && templateOptions.length === 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.mt10 }), children: polyglot.t("EditContracts.noTemplate") }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          type: "button",
          onClick: () => routerHistory.push(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE),
          fullWidth: true,
          colorVariant: "primary",
          sizeVariant: "large",
          style: { marginTop: "40px" },
          children: polyglot.t("EditContracts.goToDocuments")
        }
      )
    ] })
  ] });
};
