"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayItemAPI } from "@v2/feature/payroll/features/pay-item/pay-item.api";
import { round2Digits } from "@v2/util/number.util";
import { tableIconButtonSx, tableWhiteIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { ScopesControl } from "@/component/widgets/Scopes";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { AbsenceViewDrawer } from "@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page";
import { ViewUserCustomBenefitDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/view-user-custom-benefit-drawer.component";
import { ExpenseAPI } from "@/api-client/expense.api";
import { ExpenseModal } from "@/v2/feature/payments/pages/components/expense-modal.component";
export const ViewUpcomingPayItemDrawer = ({
  isOpen,
  setIsOpen,
  oneOffPayment,
  refresh,
  onClose,
  showDelete = false
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: oneOffPayment ? /* @__PURE__ */ jsx(
    ViewUpcomingPayItemDrawerContent,
    {
      oneOffPayment,
      refresh,
      setIsOpen,
      onClose,
      showDelete
    }
  ) : /* @__PURE__ */ jsx(Fragment, {}) });
};
export const ViewUpcomingPayItemDrawerContent = ({
  oneOffPayment,
  refresh,
  setIsOpen,
  onClose,
  showDelete
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isAbsenceOpen, setIsAbsenceOpen] = useState(false);
  const [isBenefitOpen, setIsBenefitOpen] = useState(false);
  const [isExpenseOpen, setIsExpenseOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(void 0);
  const finalAmount = useMemo(() => {
    return round2Digits(Number(oneOffPayment.amount) * Number(oneOffPayment.multiplier));
  }, [oneOffPayment.amount, oneOffPayment.multiplier]);
  const deleteOneOffPayment = useCallback(() => __async(void 0, null, function* () {
    if (!showDelete || !oneOffPayment.id) {
      showMessage("This payment cannot be deleted.", "error");
      return;
    }
    try {
      yield PayItemAPI.deleteUpcomingPayItem(oneOffPayment.userId, oneOffPayment.id);
      yield refresh();
      onClose();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [showDelete, oneOffPayment, refresh, setIsOpen, showMessage, polyglot, onClose]);
  const handleExpenseClick = (oneOffPayment2) => __async(void 0, null, function* () {
    if (oneOffPayment2.id) {
      const expenseObject = yield ExpenseAPI.getExpenseForOneOffPaymentId(oneOffPayment2.userId, oneOffPayment2.id);
      setSelectedExpense(expenseObject);
      setIsExpenseOpen(true);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Pay item" }),
      showDelete && /* @__PURE__ */ jsx(ScopesControl, { scopes: ["payroll:all"], context: { userId: oneOffPayment.userId }, children: /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: deleteOneOffPayment, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: oneOffPayment.userId, sx: { mt: spacing.m5 }, nameSx: __spreadValues({}, themeFonts.title4) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.date") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: oneOffPayment.date })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.description") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: oneOffPayment.description })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("PayItemModule.payCode") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: oneOffPayment.payCode })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("PayItemModule.totalAmount") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", sx: { fontWeight: "500" }, children: finalAmount })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("PayItemModule.amount") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: oneOffPayment.amount })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("PayItemModule.payCodeMultiplier") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: oneOffPayment.multiplier })
    ] }),
    (oneOffPayment.autogenerated || oneOffPayment.createdBy) && /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("PayItemModule.source") }),
      oneOffPayment.autogenerated && oneOffPayment.absenceId ? /* @__PURE__ */ jsx(
        ViewSourceDrawer,
        {
          name: polyglot.t("PayItemModule.autogeneratedFromAbsence"),
          onClick: () => setIsAbsenceOpen(true)
        }
      ) : oneOffPayment.autogenerated && oneOffPayment.userCustomBenefitPaymentId ? /* @__PURE__ */ jsx(
        ViewSourceDrawer,
        {
          name: polyglot.t("PayItemModule.autogeneratedFromBenefit"),
          onClick: () => setIsBenefitOpen(true)
        }
      ) : oneOffPayment.autogenerated && oneOffPayment.expenseId ? /* @__PURE__ */ jsx(
        ViewSourceDrawer,
        {
          name: polyglot.t("PayItemModule.autogeneratedFromExpense"),
          onClick: () => handleExpenseClick(oneOffPayment)
        }
      ) : oneOffPayment.createdBy ? /* @__PURE__ */ jsx(UserCell, { userId: oneOffPayment.createdBy }) : null
    ] }),
    isAbsenceOpen && oneOffPayment.absence && /* @__PURE__ */ jsx(
      AbsenceViewDrawer,
      {
        isOpen: isAbsenceOpen,
        setIsAbsenceDrawerViewOpen: setIsAbsenceOpen,
        absence: oneOffPayment.absence,
        showCalendarLink: false,
        refresh
      }
    ),
    isBenefitOpen && oneOffPayment.userCustomBenefit && /* @__PURE__ */ jsx(
      ViewUserCustomBenefitDrawer,
      {
        isOpen: isBenefitOpen,
        setIsOpen: setIsBenefitOpen,
        userBenefit: oneOffPayment.userCustomBenefit
      }
    ),
    isExpenseOpen && selectedExpense && /* @__PURE__ */ jsx(
      ExpenseModal,
      {
        isOpen: isExpenseOpen,
        setIsOpen: setIsExpenseOpen,
        selectedExpense,
        onClose: () => setSelectedExpense(void 0),
        onActionPerformed: () => __async(void 0, null, function* () {
        }),
        currentUserIsAdmin: false
      }
    )
  ] });
};
const ViewSourceDrawer = ({ name, onClick }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
  /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: name }),
  /* @__PURE__ */ jsx(IconButton, { sx: tableWhiteIconButtonSx, onClick, title: "View", children: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)) })
] });
