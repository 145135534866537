"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const OverlappingCounter = ({
  overlappingEventsCounter,
  activeView
}) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        position: "absolute",
        top: activeView === "Week" ? 4 : "50%",
        right: activeView === "Week" ? 4 : "50%",
        transform: activeView === "Month" ? "translate(50%, -50%)" : "none",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        backgroundColor: themeColors.DarkGrey,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1
      },
      children: /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "white", children: overlappingEventsCounter })
    }
  );
};
