"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class SiteAPI {
  static listSites(includeUserCount = false) {
    return __async(this, null, function* () {
      const query = `includeUserCount=${includeUserCount ? "true" : "false"}`;
      return (yield axios.get(`/apiv2/companies/sites?${query}`)).data;
    });
  }
  static getSitesWithMemberDetails() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/sites-members`)).data;
    });
  }
  static getSite(siteId, includeUserCount = false) {
    return __async(this, null, function* () {
      const query = `includeUserCount=${includeUserCount ? "true" : "false"}`;
      return (yield axios.get(`/apiv2/companies/sites/${siteId}?${query}`)).data;
    });
  }
  static saveNewSite(site) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/companies/sites", site)).data;
    });
  }
  static updateSite(siteId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/sites/${siteId}`, update);
    });
  }
  static deleteSite(siteId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/companies/sites/${siteId}`);
    });
  }
  static listSitesAsSuperAdmin() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/sites/superadmin`)).data;
    });
  }
}
export class SiteEndpoints {
  static getSites(includeUserCount = false) {
    const query = `includeUserCount=${includeUserCount ? "true" : "false"}`;
    return {
      url: `/apiv2/companies/sites?${query}`
    };
  }
  static getSiteOptions() {
    return {
      url: `/apiv2/companies/sites-options`
    };
  }
  static getSitesCount() {
    return {
      url: `/apiv2/companies/sites-count`
    };
  }
  static getUserSite(userId) {
    return {
      url: `/apiv2/users/${userId}/site`
    };
  }
}
