"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  INVOICES_ME_EDIT_INVOICE_ROUTE,
  INVOICES_ME_NEW_INVOICE_ROUTE,
  INVOICES_ME_OVERVIEW_ROUTE,
  INVOICES_ME_ROUTE
} from "@/lib/routes";
import { InvoicesPage } from "@/v2/feature/payments/pages/invoice.page";
import { NewInvoicePage } from "@/v2/feature/payments/pages/new-invoice.page";
export const InvoiceMeRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["invoices"], path: INVOICES_ME_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(InvoicesPage, { reach: "me" }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["invoices"], path: INVOICES_ME_NEW_INVOICE_ROUTE, children: /* @__PURE__ */ jsx(NewInvoicePage, { preselectedEmployee: globalState.user.userId }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["invoices"], context: scopesContext, path: INVOICES_ME_EDIT_INVOICE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(NewInvoicePage, { reach: "user", preselectedEmployee: globalState.user.userId, editMode: true }) }),
    /* @__PURE__ */ jsx(Redirect, { to: INVOICES_ME_OVERVIEW_ROUTE, from: INVOICES_ME_ROUTE })
  ] });
};
