"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { UserPensionPage } from "@/component/dashboard/userDetails/pension/user-pension.page";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { BENEFITS_ME_ROUTE, PENSION_ME_SUMMARY_ROUTE } from "@/lib/routes";
export const PensionMeRouter = ({
  userId,
  userPension,
  loading,
  refreshUserPension
}) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    (userPension == null ? void 0 : userPension.inPension) && /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: ["pension"], context: { userId }, path: PENSION_ME_SUMMARY_ROUTE, children: /* @__PURE__ */ jsx(
      UserPensionPage,
      {
        userId,
        employeePension: userPension,
        onEmployeeUpdate: refreshUserPension,
        loading
      }
    ) }),
    /* @__PURE__ */ jsx(Redirect, { to: BENEFITS_ME_ROUTE })
  ] });
};
