"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { DevicePolicyType } from "@v2/feature/device/device.interface";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as CheckCircle } from "@/images/side-bar-icons/CheckCircle.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, DEVICES_ME_OVERVIEW_DEVICE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DevicePossessionType } from "@/v2/feature/device/device.interface";
import { NoAppliedPolicies } from "@/v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { buttonSettingSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { settingCardSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 14, height: 14 };
const GetPolicyLabel = ({ policy }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10, display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(CheckCircle, __spreadValues({}, iconSize)) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: policy.description }) })
  ] });
};
const GetKeyValue = ({
  key1,
  value1,
  key2,
  value2
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10, display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({}, themeFonts.caption), children: [
        key1,
        ": "
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: value1 })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10, display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({}, themeFonts.caption), children: [
        key2,
        ": "
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: value2 })
    ] })
  ] });
};
export const ProtectDevice = ({ setProgress, devicePossession }) => {
  const [credentials, setCredentials] = useState(null);
  const [value, setValue] = useState(void 0);
  const [showingState, setShowingState] = useState("credentials");
  const [unmatchedDevices, setUnmatchedDevices] = useState([]);
  const [isDeviceMatchingSaved, setIsDeviceMatchingSaved] = useState(false);
  const [appliedPolicies, setAppliedPolicies] = useState(NoAppliedPolicies);
  const [passCodePolicies, setPassCodePolicies] = useState([]);
  const [iCloudCodePolicies, setICloudPolicies] = useState([]);
  const [encryptionPolicies, setEncryptionPolicies] = useState([]);
  const [locationPolicies, setLocationPolicies] = useState([]);
  const [updatesPolicies, setUpdatesPolicies] = useState([]);
  const [firewallPolicies, setFirewallPolicies] = useState([]);
  const routerHistory = useHistory();
  const location = useLocation();
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        if (devicePossession.possessionType !== DevicePossessionType.User) {
          setShowingState("inventorydevice");
          return;
        }
        const userDetails = yield DeviceAPI.getDeviceAssignedExternalUserCredentials(devicePossession.possessionId);
        setCredentials(userDetails);
      } catch (error) {
        showMessage(`Could not retrieve user credentials. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [devicePossession, showMessage]);
  const getUnmatchedDevices = () => __async(void 0, null, function* () {
    var _a;
    setShowingState("unmatchedloading");
    try {
      const [unmatchedDevicesResponse, appliedPolicies2, policies] = yield Promise.all([
        DeviceAPI.getUnmatchedExternalDevicesForPossession(devicePossession.id),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
        DeviceAPI.getAvailableCompanyDevicePolicies()
      ]);
      setUnmatchedDevices(unmatchedDevicesResponse.devices);
      setIsDeviceMatchingSaved(unmatchedDevicesResponse.deviceMatchingSaved);
      setAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setPassCodePolicies(policies.filter((policy) => policy.type === DevicePolicyType.passcode));
      setEncryptionPolicies(policies.filter((policy) => policy.type === DevicePolicyType.encryption));
      setLocationPolicies(policies.filter((policy) => policy.type === DevicePolicyType.location));
      setUpdatesPolicies(policies.filter((policy) => policy.type === DevicePolicyType.updates));
      setICloudPolicies(policies.filter((policy) => policy.type === DevicePolicyType.icloud));
      setFirewallPolicies(policies.filter((policy) => policy.type === DevicePolicyType.firewall));
      if (!unmatchedDevicesResponse.deviceMatchingSaved) {
        if (((_a = unmatchedDevicesResponse.devices) == null ? void 0 : _a.length) && unmatchedDevicesResponse.devices.length > 1) {
          DeviceAPI.notifyDeviceEnrolledByHexnode(devicePossession.deviceId);
          setShowingState("unmatchedsuccess");
        } else setShowingState("unmatchedfailed");
      } else {
        setTimeout(() => __async(void 0, null, function* () {
          setShowingState("unmatchedsuccess");
          yield setProgress(100);
        }), 2e3);
      }
    } catch (error) {
      setUnmatchedDevices([]);
      setShowingState("unmatchedfailed");
      if ((error == null ? void 0 : error.response.status) !== 404) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
      }
    }
  });
  const getCurrentState = () => {
    switch (showingState) {
      case "credentials":
        return /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Establish connection" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Connect your device to Zelt in order to apply company security configurations to it. Download the device management profile and use the following credentials to activate it." }),
          credentials ? /* @__PURE__ */ jsx(
            GetKeyValue,
            {
              key1: "Username",
              value1: credentials.username,
              key2: "Password",
              value2: credentials.password
            }
          ) : /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Couldn't fetch the credentials." }) }),
          /* @__PURE__ */ jsxs(Box, { sx: __spreadValues(__spreadProps(__spreadValues({}, buttonSettingSx), { maxWidth: "70%", width: "auto" }), spacing.mt40), children: [
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "secondary",
                fullWidth: true,
                onClick: () => routerHistory.push(
                  generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                ),
                children: "Skip"
              }
            ),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "primary",
                fullWidth: true,
                onClick: () => __async(void 0, null, function* () {
                  window.open("https://zelt.hexnodemdm.com/enroll/", "_blank");
                  setShowingState("downloaded");
                }),
                children: "Download"
              }
            )
          ] })
        ] });
      case "downloaded":
        return /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Establish connection" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Connect your device to Zelt in order to apply company security configurations to it. Download the device management profile and use the following credentials to activate it." }),
          credentials ? /* @__PURE__ */ jsx(
            GetKeyValue,
            {
              key1: "Username",
              value1: credentials.username,
              key2: "Password",
              value2: credentials.password
            }
          ) : /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Couldn't fetch the credentials." }) }),
          /* @__PURE__ */ jsxs(Box, { sx: __spreadValues(__spreadProps(__spreadValues({}, buttonSettingSx), { maxWidth: "70%", width: "auto" }), spacing.mt40), children: [
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "secondary",
                fullWidth: true,
                onClick: () => routerHistory.push(
                  generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                ),
                children: "Skip"
              }
            ),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "primary",
                fullWidth: true,
                onClick: () => __async(void 0, null, function* () {
                  yield getUnmatchedDevices();
                }),
                children: "Continue"
              }
            )
          ] })
        ] });
      case "unmatchedloading":
        return /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
            "Device syncing ",
            /* @__PURE__ */ jsx(CircularProgress, { size: 14 })
          ] }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "This may take a few moments" })
        ] });
      case "unmatchedfailed":
        return /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Device syncing failed" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "We couldn't find your device just yet. Sometimes it can take a few seconds to show up in our servers." }),
          /* @__PURE__ */ jsxs(Box, { sx: __spreadValues(__spreadProps(__spreadValues({}, buttonSettingSx), { maxWidth: "70%", width: "auto" }), spacing.mt40), children: [
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "secondary",
                fullWidth: true,
                onClick: () => __async(void 0, null, function* () {
                  return setShowingState("credentials");
                }),
                children: "Go back"
              }
            ),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "primary",
                fullWidth: true,
                onClick: () => __async(void 0, null, function* () {
                  yield getUnmatchedDevices();
                }),
                children: "Continue"
              }
            )
          ] })
        ] });
      case "unmatchedsuccess":
        return unmatchedDevices.length > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }) }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Your device has been successfully enroled. You can sit back and enjoy your device securely. To see and manage this device go to Devices app." })
          ] }),
          /* @__PURE__ */ jsx(Box, { children: unmatchedDevices.length > 1 ? /* @__PURE__ */ jsx(FormControl, { component: "fieldset", sx: { px: 2, width: "100%" }, children: /* @__PURE__ */ jsx(RadioGroup, { "aria-label": "unmatched-device", name: "radio-buttons-group", children: unmatchedDevices.map((device) => /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              value: device.id,
              onChange: (e) => {
                setValue(Number(e.target.value));
              },
              control: /* @__PURE__ */ jsx(
                Radio,
                {
                  checked: isDeviceMatchingSaved && unmatchedDevices.length === 1 || value === device.id,
                  disabled: isDeviceMatchingSaved && unmatchedDevices.length === 1
                }
              ),
              sx: { ":hover": { bgcolor: "#f3f3f3" }, borderRadius: 1, p: 1, m: 0, width: "100%" },
              label: /* @__PURE__ */ jsx(
                GetKeyValue,
                {
                  key1: "Device model",
                  value1: device.modelName,
                  key2: "Platform",
                  value2: device.platform
                }
              )
            }
          )) }) }) : unmatchedDevices.map((device) => /* @__PURE__ */ jsx(
            GetKeyValue,
            {
              key1: "Device model",
              value1: device.modelName,
              key2: "Platform",
              value2: device.platform
            }
          )) }),
          Boolean(appliedPolicies) && /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt40), children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.m10, color: themeColors.DarkGrey }), children: "Policies applied to your device" }),
            appliedPolicies[DevicePolicyType.passcode].filter((policyId) => policyId !== 0).map((policyId) => {
              const policy = passCodePolicies.find((p) => p.id === policyId);
              return policy ? /* @__PURE__ */ jsx(GetPolicyLabel, { policy }) : null;
            }),
            appliedPolicies[DevicePolicyType.icloud].filter((policyId) => policyId !== 0).map((policyId) => {
              const policy = iCloudCodePolicies.find((p) => p.id === policyId);
              return policy ? /* @__PURE__ */ jsx(GetPolicyLabel, { policy }) : null;
            }),
            appliedPolicies[DevicePolicyType.encryption].filter((policyId) => policyId !== 0).map((policyId) => {
              const policy = encryptionPolicies.find((p) => p.id === policyId);
              return policy ? /* @__PURE__ */ jsx(GetPolicyLabel, { policy }) : null;
            }),
            appliedPolicies[DevicePolicyType.location].filter((policyId) => policyId !== 0).map((policyId) => {
              const policy = locationPolicies.find((p) => p.id === policyId);
              return policy ? /* @__PURE__ */ jsx(GetPolicyLabel, { policy }) : null;
            }),
            appliedPolicies[DevicePolicyType.updates].filter((policyId) => policyId !== 0).map((policyId) => {
              const policy = updatesPolicies.find((p) => p.id === policyId);
              return policy ? /* @__PURE__ */ jsx(GetPolicyLabel, { policy }) : null;
            }),
            appliedPolicies[DevicePolicyType.firewall].filter((policyId) => policyId !== 0).map((policyId) => {
              const policy = firewallPolicies.find((p) => p.id === policyId);
              return policy ? /* @__PURE__ */ jsx(GetPolicyLabel, { policy }) : null;
            })
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: __spreadValues(__spreadProps(__spreadValues({}, buttonSettingSx), { maxWidth: "35%", width: "auto" }), spacing.mt40), children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "primary",
              fullWidth: true,
              onClick: () => __async(void 0, null, function* () {
                if (value) {
                  yield DeviceAPI.matchDeviceLocalIdWithDeviceExternalId(devicePossession.id, value);
                } else {
                  routerHistory.push(
                    generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                  );
                }
              }),
              children: "Complete"
            }
          ) })
        ] });
      case "inventorydevice":
        return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "You have to assign a user to this device before being able to enrol it to MDM." }),
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => {
                const path = location.pathname.includes("company") ? DEVICES_COMPANY_DEVICE_DETAILS_ROUTE : DEVICES_ME_OVERVIEW_DEVICE_ROUTE;
                routerHistory.push(generatePath(path, { devicePossessionId: devicePossession.id }));
              },
              children: "See device"
            }
          ) })
        ] });
      default:
        return /* @__PURE__ */ jsx(Fragment, { children: "Something went wrong" });
    }
  };
  return /* @__PURE__ */ jsx(Box, { sx: settingCardSx, children: getCurrentState() });
};
