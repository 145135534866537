"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CountrySelect } from "@/v2/components/country-select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { GenderSelect } from "@/v2/components/gender-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomFieldComponents } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { spacing } from "@/v2/styles/spacing.styles";
const PersonalInfoSchema = (visibility, polyglot) => {
  return Yup.object().shape({
    dob: visibility.dob ? Yup.string().test(dateFieldTest).nullable().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
    nationality: visibility.nationality ? Yup.string().nullable().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
    gender: visibility.gender ? Yup.string().nullable().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
    passportNumber: Yup.string().nullable().notRequired(),
    personalEmail: visibility.personalEmail ? Yup.string().email(polyglot.t("OnboardingByUser.validations.validValue")).required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
    phone: Yup.string().nullable().notRequired()
  });
};
export const UserPersonalInfoSection = ({ onNext, sx, userId, personalInfo, form }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const defaultFieldsVisibility = useMemo(() => {
    var _a2, _b, _c, _d, _e, _f;
    if (!form)
      return {
        dob: false,
        nationality: false,
        gender: false,
        passportNumber: false,
        personalEmail: false,
        phone: false
      };
    const dobField = (_a2 = form.fields) == null ? void 0 : _a2.find((f) => f.isDefault && f.fieldStub === "dob");
    const nationalityField = (_b = form.fields) == null ? void 0 : _b.find((f) => f.isDefault && f.fieldStub === "nationality");
    const genderField = (_c = form.fields) == null ? void 0 : _c.find((f) => f.isDefault && f.fieldStub === "gender");
    const passportNumberField = (_d = form.fields) == null ? void 0 : _d.find((f) => f.isDefault && f.fieldStub === "passportNumber");
    const personalEmailField = (_e = form.fields) == null ? void 0 : _e.find((f) => f.isDefault && f.fieldStub === "personalEmail");
    const phoneField = (_f = form.fields) == null ? void 0 : _f.find((f) => f.isDefault && f.fieldStub === "phone");
    return {
      dob: Boolean(dobField && !dobField.isHidden),
      nationality: Boolean(nationalityField && !nationalityField.isHidden),
      gender: Boolean(genderField && !genderField.isHidden),
      passportNumber: Boolean(passportNumberField && !passportNumberField.isHidden),
      personalEmail: Boolean(personalEmailField && !personalEmailField.isHidden),
      phone: Boolean(phoneField && !phoneField.isHidden)
    };
  }, [form]);
  const initialValues = useMemo(() => {
    var _a2, _b, _c, _d, _e, _f, _g;
    const values = {};
    if (defaultFieldsVisibility.dob) values.dob = (_a2 = personalInfo.dob) != null ? _a2 : "";
    if (defaultFieldsVisibility.gender) values.gender = (_b = personalInfo.gender) != null ? _b : "";
    if (defaultFieldsVisibility.nationality) values.nationality = (_c = personalInfo.nationality) != null ? _c : "";
    if (defaultFieldsVisibility.passportNumber) values.passportNumber = (_d = personalInfo.passportNumber) != null ? _d : "";
    if (defaultFieldsVisibility.personalEmail) values.personalEmail = (_e = personalInfo.personalEmail) != null ? _e : "";
    if (defaultFieldsVisibility.phone) values.phone = (_f = personalInfo.phone) != null ? _f : "";
    values.customUpdates = (_g = personalInfo.customUpdates) != null ? _g : [];
    return values;
  }, [defaultFieldsVisibility, personalInfo]);
  const formik = useFormik({
    initialValues,
    validationSchema: PersonalInfoSchema(defaultFieldsVisibility, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserPersonalInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.basicInfoNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OnboardingByUser.personalInfo") }),
    defaultFieldsVisibility.dob && /* @__PURE__ */ jsx(
      TypeableDateComponent,
      {
        name: "dob",
        label: polyglot.t("OnboardingByUser.dob"),
        value: formik.values.dob,
        onChange: (value) => formik.setFieldValue("dob", value),
        disabled: saving,
        error: hasSubmitted && !!formik.errors.dob,
        helperText: hasSubmitted && formik.errors.dob
      }
    ),
    defaultFieldsVisibility.nationality && /* @__PURE__ */ jsx(
      CountrySelect,
      {
        name: "nationality",
        label: polyglot.t("OnboardingByUser.nationality"),
        value: formik.values.nationality,
        onChange: (value) => formik.setFieldValue("nationality", value),
        disabled: saving,
        error: hasSubmitted && !!formik.errors.nationality,
        helperText: hasSubmitted && formik.errors.nationality
      }
    ),
    defaultFieldsVisibility.gender && /* @__PURE__ */ jsx(
      GenderSelect,
      {
        name: "gender",
        value: formik.values.gender,
        onChange: (value) => formik.setFieldValue("gender", value),
        disabled: saving,
        error: hasSubmitted && !!formik.errors.gender,
        helperText: hasSubmitted && formik.errors.gender
      }
    ),
    defaultFieldsVisibility.passportNumber && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "passportNumber",
        label: polyglot.t("OnboardingByUser.passportNumber"),
        value: formik.values.passportNumber,
        onChange: formik.handleChange,
        disabled: saving,
        clearText: () => formik.setFieldValue("passportNumber", ""),
        helperText: hasSubmitted && formik.errors.passportNumber,
        error: hasSubmitted && !!formik.errors.passportNumber
      }
    ),
    defaultFieldsVisibility.personalEmail && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "personalEmail",
        label: polyglot.t("OnboardingByUser.personalEmail"),
        type: "email",
        value: formik.values.personalEmail,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("personalEmail", ""),
        helperText: hasSubmitted && formik.errors.personalEmail,
        error: hasSubmitted && !!formik.errors.personalEmail
      }
    ),
    defaultFieldsVisibility.phone && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "phone",
        label: polyglot.t("OnboardingByUser.phone"),
        type: "tel",
        value: formik.values.phone,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("phone", ""),
        helperText: hasSubmitted && formik.errors.phone,
        error: hasSubmitted && !!formik.errors.phone
      }
    ),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: (_a = formik.values.customUpdates) != null ? _a : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
