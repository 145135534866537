"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import {
  HMRCSubmissionStates,
  PayrunProcessStepStates
} from "@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface";
import { PayrollExternalApi } from "@v2/feature/payroll/payroll-external.api";
import {
  ExternalFPSLateReasons,
  ExternalGovTalkSubmissionStatuses
} from "@v2/feature/payroll/payroll-external.interface";
import { generatePath } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from "@/lib/routes";
import { isHMRCSetup } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayrunProcessingItem } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component";
import { PayrollLocalApi } from "@/v2/feature/payroll/payroll-local.api";
import { primaryTableSmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
import { sleep } from "@/v2/util/time.util";
const iconSize20px = { height: "20px", width: "20px" };
const lateReasonObjects = [
  { label: ExternalFPSLateReasons.NoneGiven, value: ExternalFPSLateReasons.NoneGiven },
  { label: ExternalFPSLateReasons.NotionalExpat, value: ExternalFPSLateReasons.NotionalExpat },
  { label: ExternalFPSLateReasons.NotionalErs, value: ExternalFPSLateReasons.NotionalErs },
  { label: ExternalFPSLateReasons.NotionalOther, value: ExternalFPSLateReasons.NotionalOther },
  { label: ExternalFPSLateReasons.Class1, value: ExternalFPSLateReasons.Class1 },
  { label: ExternalFPSLateReasons.MicroEmployer, value: ExternalFPSLateReasons.MicroEmployer },
  { label: ExternalFPSLateReasons.NoRequirement, value: ExternalFPSLateReasons.NoRequirement },
  { label: ExternalFPSLateReasons.ReasonableExcuse, value: ExternalFPSLateReasons.ReasonableExcuse },
  { label: ExternalFPSLateReasons.Correction, value: ExternalFPSLateReasons.Correction }
];
export const HMRCSubmission = ({
  payRun,
  hmrcSubmissionState,
  setHmrcSubmissionState,
  sx
}) => {
  const [accordionState, setAccordionState] = useState(PayrunProcessStepStates.pending);
  const [lateReason, setLateReason] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFPSLate, setIsFPSLate] = useState(false);
  const [missingHMRCDetails, setMissingHMRCDetails] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const cancelRequest = useRef(false);
  const [showMessage] = useMessage();
  const downloadFpsReport = () => __async(void 0, null, function* () {
    var _a;
    try {
      setIsDownloading(true);
      const req = yield PayrollExternalApi.getExternalFPSReportByPeriod(
        payRun.payrollId,
        payRun.taxYear,
        payRun.payPeriod,
        payRun.period,
        "text/csv"
      );
      const file = new Blob([(_a = req.content) != null ? _a : req], { type: "text/csv" });
      const fileURL = URL.createObjectURL(file);
      let link = document.createElement("a");
      link.download = `payrun-${payRun.taxYear}-Month-${payRun.period}-FPS-report.csv`;
      link.href = fileURL;
      link.click();
      showMessage("FPS Reports downloaded successfully.", "success");
    } catch (error) {
      showMessage(`Failed to download FPS Reports. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDownloading(false);
    }
  });
  const checkIfFPSIsLate = (fps) => {
    if (!fps.paymentDate) {
      return true;
    }
    return todaysDateShortISOString() > fps.paymentDate;
  };
  const submitFPS = useCallback(
    (lateReason2, fps, submitAttempt = 0) => __async(void 0, null, function* () {
      var _a;
      if (cancelRequest.current) {
        return;
      }
      setAccordionState(PayrunProcessStepStates.pending);
      setMissingHMRCDetails(false);
      setIsFPSLate(false);
      const [externalFPS, localFPS] = yield Promise.all([
        fps != null ? fps : PayrollExternalApi.fetchExternalFPSStatus(payRun.id),
        PayrollLocalApi.fetchFPS(payRun.id)
      ]);
      if (localFPS.state === HMRCSubmissionStates.markedAsSent) {
        setHmrcSubmissionState(HMRCSubmissionStates.markedAsSent);
        setAccordionState(PayrunProcessStepStates.success);
        return;
      }
      if (!isHMRCSetup(__spreadValues({ kind: "fps" }, externalFPS.employerReferences))) {
        setSubmissionMessage(
          "Missing HMRC credentials. This payrun can be left as partially complete while you are waiting for your account details."
        );
        setMissingHMRCDetails(true);
        setAccordionState(PayrunProcessStepStates.warning);
        return;
      }
      const currentStatus = (_a = externalFPS.govTalkSubmission) == null ? void 0 : _a.status;
      if (currentStatus === ExternalGovTalkSubmissionStatuses.accepted) {
        setSubmissionMessage(externalFPS.govTalkSubmission.message);
        setHmrcSubmissionState(HMRCSubmissionStates.submitted);
        setAccordionState(PayrunProcessStepStates.success);
        return;
      }
      if (currentStatus === ExternalGovTalkSubmissionStatuses.submitted) {
        yield sleep(5e3);
        submitFPS();
        return;
      }
      if (currentStatus === ExternalGovTalkSubmissionStatuses.errorResponse && submitAttempt > 0) {
        setSubmissionMessage(externalFPS.govTalkSubmission.message);
        setAccordionState(PayrunProcessStepStates.failure);
        return;
      }
      if (checkIfFPSIsLate(externalFPS) && !lateReason2) {
        setIsFPSLate(true);
        setAccordionState(PayrunProcessStepStates.warning);
        return;
      }
      try {
        if (submitAttempt > 5) {
          throw new Error(`Submission failed.`);
        }
        const result = yield PayrollExternalApi.submitFPS(payRun.id, lateReason2);
        submitFPS(lateReason2, result, submitAttempt + 1);
      } catch (error) {
        setAccordionState(PayrunProcessStepStates.failure);
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [payRun.id, setHmrcSubmissionState, showMessage]
  );
  useEffect(() => {
    submitFPS();
    return () => {
      cancelRequest.current = true;
    };
  }, [submitFPS]);
  if (isFPSLate) {
    return /* @__PURE__ */ jsxs(
      Stack,
      {
        sx: __spreadValues({
          gap: spacing.g15,
          borderTop: "1px solid",
          borderTopColor: themeColors.middleGrey,
          width: "600px",
          py: spacing.p25
        }, sx),
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g15 }, children: [
            /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize20px), { style: { fill: themeColors.GreyMiddle, flexShrink: 0 } })),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }), children: "HMRC: Late Filing Reason" })
          ] }),
          /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "You're submitting the FPS for this payrun after the payment date. You should provide a reason for the late filing." }),
          /* @__PURE__ */ jsx(
            TextField,
            {
              label: "Late reason",
              select: true,
              value: lateReason != null ? lateReason : "",
              placeholder: "None selected",
              size: "small",
              sx: { width: "300px" },
              onChange: (event) => {
                setLateReason(event.target.value);
              },
              children: lateReasonObjects.map((option) => {
                return /* @__PURE__ */ jsx(MenuItem, { value: option.value, children: option.label }, option.value);
              })
            }
          ),
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: __spreadProps(__spreadValues({}, primaryTableSmallBtn), { width: "100px" }),
              onClick: () => submitFPS(lateReason),
              disabled: !lateReason,
              children: "Submit FPS"
            }
          )
        ]
      }
    );
  }
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    PayrunProcessingItem,
    {
      title: "HMRC",
      description: {
        pending: "Processing Full Payment Submission...",
        failure: `An error occurred while submitting the FPS. ${submissionMessage} (Current status: ${hmrcSubmissionState})`,
        success: `Full Payment Submission accepted. ${submissionMessage}`,
        warning: submissionMessage
      }[accordionState],
      buttons: [
        {
          style: "primary",
          label: "Add HMRC Credentials",
          show: missingHMRCDetails,
          onClick: () => window.open(
            generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId: payRun.payrollId }),
            "_blank"
          ),
          type: "button"
        },
        {
          style: "primary",
          label: "Retry",
          show: !missingHMRCDetails && accordionState !== PayrunProcessStepStates.success,
          onClick: () => submitFPS(lateReason),
          type: "button"
        },
        {
          style: "secondary",
          label: "Download",
          show: !missingHMRCDetails,
          loading: isDownloading,
          onClick: () => downloadFpsReport(),
          type: "button"
        }
      ],
      success: accordionState === PayrunProcessStepStates.pending ? void 0 : accordionState === PayrunProcessStepStates.success,
      sx
    },
    "hmrc"
  ) });
};
