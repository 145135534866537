"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Box } from "@mui/material";
import { Divider } from "@v2/components/divider.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { convertMinutesToClockHours, isHourlyPolicy, isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import {
  getAllowanceProratingRuleLongDesc,
  getAnnualBalanceFromBreakdown,
  getProratedAllowanceFromBreakdown,
  isoDateToLocale
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { round2Digits } from "@v2/util/number.util";
export const ProratedAllowanceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AllowanceDrawer.proratedAllowance") }),
        /* @__PURE__ */ jsx(
          ProratedAllowanceDetailsDrawerContent,
          {
            userBalanceDetailedStats,
            absencePolicy
          }
        )
      ] })
    }
  ) });
};
const ProratedAllowanceDetailsDrawerContent = ({
  absencePolicy,
  userBalanceDetailedStats
}) => /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20 }, children: [
  /* @__PURE__ */ jsx(CalculationSection, { absencePolicy, userBalanceDetailedStats }),
  /* @__PURE__ */ jsx(DetailsSection, { absencePolicy, userBalanceDetailedStats })
] });
export const Chip = ({ color, value }) => /* @__PURE__ */ jsx(
  "span",
  {
    style: {
      whiteSpace: "nowrap",
      backgroundColor: color,
      padding: `${spacing.p5} ${spacing.p10} ${spacing.p5} ${spacing.p10}`,
      borderRadius: "15px"
    },
    className: "caption",
    children: value
  }
);
const ChipLine = ({
  allowance,
  yearPercent,
  ftePercent,
  avgHoursPerDay,
  result,
  showTimesAvgDayInHours,
  showFtePercent,
  showYearPercent
}) => /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", gap: spacing.g5, alignItems: "center", justifyContent: "space-between" }, children: [
  showTimesAvgDayInHours && showYearPercent && /* @__PURE__ */ jsx(Box, { children: "(" }),
  /* @__PURE__ */ jsx(Chip, { color: themeColors.FreshGreen, value: allowance }),
  showYearPercent && /* @__PURE__ */ jsx(Box, { children: "X" }),
  showYearPercent && /* @__PURE__ */ jsx(Chip, { color: themeColors.ZeltYellow, value: yearPercent }),
  showFtePercent && /* @__PURE__ */ jsx(Box, { children: "X" }),
  showFtePercent && /* @__PURE__ */ jsx(Chip, { color: themeColors.Violet, value: ftePercent }),
  showTimesAvgDayInHours && /* @__PURE__ */ jsx(Box, { children: ")" }),
  showTimesAvgDayInHours && /* @__PURE__ */ jsx(Box, { children: "X" }),
  showTimesAvgDayInHours && showYearPercent && /* @__PURE__ */ jsx(Chip, { color: themeColors.Orange, value: avgHoursPerDay }),
  /* @__PURE__ */ jsx(Box, { children: "=" }),
  /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { whiteSpace: "nowrap" }, children: result })
] });
const CalculationSection = ({ absencePolicy, userBalanceDetailedStats }) => {
  const { polyglot } = usePolyglot();
  const isHourly = isHourlyPolicy(absencePolicy);
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  if (!breakdown) return null;
  const showAvgWorkDay = !isHourly && (!breakdown.isOnRegularSchedule || !breakdown.currentAverageWorkDayLength);
  const policyAllowance = getAnnualBalanceFromBreakdown(absencePolicy, breakdown, polyglot);
  const proratedBasicAllowance = getProratedAllowanceFromBreakdown(absencePolicy, breakdown, polyglot);
  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    const firstOfJan = /* @__PURE__ */ new Date();
    firstOfJan.setMonth(0, 1);
    const thirtyFirstOfDec = /* @__PURE__ */ new Date();
    thirtyFirstOfDec.setMonth(11, 31);
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
        var _a;
        const yearPercent = round2Digits(segment.daysInSegment / segment.daysInCycle * 100);
        const segmentValue = breakdown.isOnRegularSchedule && !isHourly && breakdown.currentAverageWorkDayLength ? polyglot.t("AllowanceDrawer.noOfDays", {
          smart_count: (_a = round2Digits(segment.segmentValue / breakdown.currentAverageWorkDayLength)) != null ? _a : 0
        }) : convertMinutesToClockHours(segment.segmentValue, polyglot);
        return /* @__PURE__ */ jsx(
          ChipLine,
          {
            showYearPercent: absencePolicy.proratingStartDate || absencePolicy.proratingFte,
            showTimesAvgDayInHours: showAvgWorkDay,
            showFtePercent: absencePolicy.proratingFte,
            allowance: policyAllowance,
            yearPercent: `${yearPercent}%`,
            ftePercent: `${round2Digits(segment.fteByWorkingDaysInWeek * 100)}%`,
            avgHoursPerDay: convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot),
            result: segmentValue
          },
          index
        );
      }),
      /* @__PURE__ */ jsx(Divider, {}),
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: proratedBasicAllowance }) })
    ] });
  }
  return null;
};
export const DetailsSection = ({ absencePolicy, userBalanceDetailedStats }) => {
  const { polyglot } = usePolyglot();
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  const proratingRule = getAllowanceProratingRuleLongDesc(absencePolicy, polyglot);
  const policyAllowance = getAnnualBalanceFromBreakdown(absencePolicy, breakdown, polyglot);
  const isHourly = isHourlyPolicy(absencePolicy);
  const isLimited = !isUnlimitedPolicy(absencePolicy);
  if (!breakdown) return null;
  const tenureValue = breakdown.tenure.tenureValueInUnits;
  return /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("AllowanceDrawer.details") }),
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
      "Policy allowance for the whole year is ",
      /* @__PURE__ */ jsx(Chip, { color: themeColors.FreshGreen, value: policyAllowance })
    ] }),
    isLimited && tenureValue > 0 && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
      "This includes tenure-based adjustment of +",
      tenureValue,
      " ",
      isHourly ? "hour" : "day",
      tenureValue === 1 ? "" : "s",
      "."
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: polyglot.t("AllowanceDrawer.companyCalendarRunsFromTo", {
      startDate: isoDateToLocale(breakdown.currentCycle[0]),
      endDate: isoDateToLocale(breakdown.currentCycle[1])
    }) }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.m20 }, children: proratingRule }),
    /* @__PURE__ */ jsx(DetailsSegments, { absencePolicy, userBalanceDetailedStats })
  ] });
};
export const DetailsSegments = ({ absencePolicy, userBalanceDetailedStats }) => {
  const { polyglot } = usePolyglot();
  const isHourly = isHourlyPolicy(absencePolicy);
  const breakdown = userBalanceDetailedStats[absencePolicy.id];
  if (!breakdown) return null;
  const showAvgWorkDay = !isHourly && !breakdown.isOnRegularSchedule;
  if (breakdown.proRatedAbsenceAllowance.fteSegments) {
    return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: breakdown.proRatedAbsenceAllowance.fteSegments.map((segment, index) => {
      const segmentStart = isoDateToLocale(segment.segmentStart);
      const segmentEnd = isoDateToLocale(segment.segmentEnd);
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: spacing.m5 }, children: polyglot.t("AllowanceDrawer.contractNo", { contractNo: index + 1 }) }),
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "This contract is effective from ",
          segmentStart,
          " to ",
          segmentEnd,
          ", lasting ",
          segment.daysInSegment,
          " days. (",
          segment.daysInSegment,
          "/",
          segment.daysInCycle,
          " =",
          " ",
          /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
            Chip,
            {
              color: themeColors.ZeltYellow,
              value: `${round2Digits(segment.daysInSegment / segment.daysInCycle * 100)}%`
            }
          ) }),
          ")"
        ] }),
        absencePolicy.proratingFte && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "Your work pattern is ",
          segment.userNoOfWorkingDays,
          " days out of ",
          segment.fteEquivalentNoOfWorkingDays,
          " ",
          "days per week. Therefore your prorating percentage is",
          " ",
          /* @__PURE__ */ jsx(Chip, { color: themeColors.Violet, value: `${round2Digits(segment.fteByWorkingDaysInWeek * 100)}%` })
        ] }),
        showAvgWorkDay && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mb: spacing.m10 }, children: [
          "Average workday length is",
          " ",
          /* @__PURE__ */ jsx(
            Chip,
            {
              color: themeColors.Orange,
              value: convertMinutesToClockHours(segment.averageValueOf1WorkDay, polyglot)
            }
          )
        ] })
      ] }, index);
    }) });
  }
  return null;
};
