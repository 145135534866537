"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as InfoGrey } from "@/images/side-bar-icons/InfoGrey.svg";
export const ReportSettingsSection = ({ effectiveDate, setEffectiveDate }) => {
  const { polyglot } = usePolyglot();
  const [value, setValue] = useState(effectiveDate);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px", height: "100%" }, children: [
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "effectiveDate",
        label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s1, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("General.effectiveDate") }),
          /* @__PURE__ */ jsx(
            StyledTooltip,
            {
              title: "Select a date to view employee records that were in effect on that day.",
              placement: "top",
              children: /* @__PURE__ */ jsx(InfoGrey, __spreadProps(__spreadValues({}, iconSize), { style: { cursor: "pointer" } }))
            }
          )
        ] }),
        required: true,
        onChange: (date) => {
          setValue(date);
        },
        value
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => {
          setEffectiveDate(value);
        },
        fullWidth: true,
        children: "Apply"
      }
    ) })
  ] });
};
