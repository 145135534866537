"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { spacing } from "@v2/styles/spacing.styles";
import { SuperAdminBillingInvoiceTableHelper } from "../../super-admin/features/super-admin-billing/helper/super-admin-billing-invoices-table-helper";
import { BillingFailedPaymentAPI } from "@/api-client/billing-failed-payment.api";
import { BillingInvoiceAPI } from "@/api-client/billing-invoice.api";
import { BillingAPI } from "@/api-client/billing.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { nestErrorMessage } from "@/lib/errors";
import { InvoiceStatus } from "@/models/invoice.model";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const BillingInvoices = ({ subscriptionDetailsForCompany }) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [sorting, setSorting] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [showMessage] = useMessage();
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isRetryingPayments, setIsRetryingPayments] = useState(false);
  const [payingInvoice, setPayingInvoice] = useState(false);
  const [currentInvoiceIdBeingProcessed, setCurrentInvoiceIdBeingProcessed] = useState(null);
  const fetchInvoices = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setIsLoadingInvoices(true);
        const { invoices: invoices2, pagination: paginationInfo } = yield BillingInvoiceAPI.getAllInvoicesForCompany(params);
        setInvoices(invoices2 || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
        setIsLoadingInvoices(false);
      } catch (error) {
        showMessage(`Something went wrong fetching invoices. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const refresh = useCallback(() => __async(void 0, null, function* () {
    yield fetchInvoices({
      page: pageIndex.toString(),
      pageSize: pageSize.toString()
      // Use the current pageSize instead of DEFAULT_PAGE_SIZE
    });
  }), [fetchInvoices, pageIndex, pageSize]);
  const payInvoice = useCallback(
    (invoice) => __async(void 0, null, function* () {
      try {
        if (!subscriptionDetailsForCompany) return;
        const { companySubscription } = subscriptionDetailsForCompany;
        setCurrentInvoiceIdBeingProcessed(invoice.id);
        yield BillingAPI.manuallyChargeCustomerForUnpaidInvoice(invoice, companySubscription);
        showMessage("Initiated invoice payment...", "info");
      } catch (error) {
        showMessage(`Failed to initiate invoice payment: ${nestErrorMessage(error)}`, "error");
      } finally {
        setPayingInvoice(false);
        setCurrentInvoiceIdBeingProcessed(null);
      }
    }),
    [showMessage, subscriptionDetailsForCompany]
  );
  const handleDownloadClick = useCallback(
    (invoiceId) => __async(void 0, null, function* () {
      try {
        const PDFBlob = yield BillingInvoiceAPI.downloadInvoice(invoiceId);
        const file = new Blob([PDFBlob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement("a");
        link.download = `${invoiceId}-${/* @__PURE__ */ new Date()}.pdf`;
        link.href = fileURL;
        link.click();
      } catch (error) {
        console.error("::Download error", error);
        showMessage(`Failed to download invoice. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const retryFailedPayment = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setIsRetryingPayments(true);
        const { failedPaymentId, invoiceId } = params;
        setCurrentInvoiceIdBeingProcessed(invoiceId);
        yield BillingFailedPaymentAPI.retryFailedPayment({ failedPaymentId });
        refresh();
        showMessage(`Charge successful for invoice No. ${invoiceId}`, "success");
      } catch (error) {
        const defaultErrorMessage = "Failed to retry payment";
        showMessage(`${nestErrorMessage(error) || defaultErrorMessage}`, "error");
      } finally {
        setIsRetryingPayments(false);
        setCurrentInvoiceIdBeingProcessed(null);
      }
    }),
    [refresh, showMessage]
  );
  useEffect(() => {
    refresh();
  }, [pageIndex, pageSize, refresh]);
  const invoiceTableColumns = useMemo(
    () => [
      SuperAdminBillingInvoiceTableHelper.getInvoiceIdColumn({
        header: "Invoice Id",
        id: "id",
        size: 145
      }),
      SuperAdminBillingInvoiceTableHelper.getPeriodStartDateColumn({
        header: "Period Start",
        id: "periodStart",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getPeriodEndDateColumn({
        header: "Period End",
        id: "periodEnd",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getInvoiceDateColumn({
        header: "Invoice Date",
        id: "invoiceDate",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getInvoiceStatusColumn({
        header: "Status",
        id: "status",
        size: 100
      }),
      SuperAdminBillingInvoiceTableHelper.getTotalAmountColumn({
        header: "Total Amount",
        id: "totalAmount",
        size: 100
      }),
      {
        id: "action-buttons",
        header: () => "",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 40,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", justifyContent: "flex-end", gap: "5px", whiteSpace: "nowrap", children: [
            original.id && /* @__PURE__ */ jsx(
              IconButton,
              {
                sx: tableIconButtonSx,
                onClick: () => original.id ? handleDownloadClick(original.id) : void 0,
                title: "Download",
                children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize))
              }
            ),
            original.status === InvoiceStatus.Unpaid && ((_a = original == null ? void 0 : original.billingFailedPayment) == null ? void 0 : _a.id) && /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                onClick: () => {
                  var _a2;
                  return ((_a2 = original.billingFailedPayment) == null ? void 0 : _a2.id) ? retryFailedPayment({
                    failedPaymentId: original.billingFailedPayment.id,
                    invoiceId: original.id
                  }) : void 0;
                },
                sizeVariant: "small",
                colorVariant: "primary",
                disabled: isRetryingPayments,
                loading: original.id === currentInvoiceIdBeingProcessed && isRetryingPayments,
                children: "Pay"
              }
            ),
            original.status === InvoiceStatus.Unpaid && !((_b = original == null ? void 0 : original.billingFailedPayment) == null ? void 0 : _b.id) && /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                onClick: () => payInvoice(original),
                sizeVariant: "small",
                colorVariant: "primary",
                loading: original.id === currentInvoiceIdBeingProcessed && payingInvoice,
                disabled: payingInvoice,
                children: "Pay"
              }
            )
          ] });
        }
      }
    ],
    [
      currentInvoiceIdBeingProcessed,
      handleDownloadClick,
      isRetryingPayments,
      payInvoice,
      payingInvoice,
      retryFailedPayment
    ]
  );
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  return invoices && invoices.length > 0 ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Invoices" }) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: !isLoadingInvoices && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      BasicServerTable,
      {
        rowData: [...invoices],
        columnData: invoiceTableColumns,
        sorting,
        setSorting,
        pagination,
        setPagination,
        totalPages,
        totalItems
      }
    ) }) })
  ] }) : null;
};
