"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { secondarySmallBtn } from "@v2/styles/buttons.styles";
import { LocalDate } from "@v2/util/local-date";
import CsvDownloader from "react-csv-downloader";
import { useDebouncedCallback } from "use-debounce";
import { BillingDiscountAPI } from "@/api-client/billing-discount.api";
import { CompanyAPI } from "@/api-client/index.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { FreeProductPlans, PaidProductPlans } from "@/models/subscription.model";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableFilter } from "@/v2/components/table/table-filter.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SuperAdminBillingPlanEditForm } from "@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-edit-form.component";
import { SuperAdminBillingPlanEditModal } from "@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-edit-modal.component";
import {
  getPlanFromSubscriptions,
  groupSubscriptionDiscounts,
  ProductTypes,
  SuperAdminBillingPlanTableHelper
} from "@/v2/feature/super-admin/features/super-admin-billing/helper/super-admin-billing-plan-table-helper";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function SuperAdminBillingPlansPage({ pageConfig }) {
  var _a, _b;
  const [companyPlans, setCompanyPlans] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false);
  const [selectedCompanyToEdit, setCompanyPlanToEdit] = useState();
  const [showMessage] = useMessage();
  const [sorting, setSorting] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filterString, setFilterString] = useState(
    `Plans=${PaidProductPlans.APPS_PRO},${PaidProductPlans.DEVICES_PRO},${PaidProductPlans.MONEY_PRO},${PaidProductPlans.PEOPLE_PRO}`
  );
  const [filterTypes, setFilterTypes] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const debouncedFilter = useDebouncedCallback((filterString2) => __async(this, null, function* () {
    try {
      setFilterString(filterString2);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage("Failed to handle filter", "error");
    }
  }), 500);
  const handleFilter = useCallback((event) => debouncedFilter.callback(event), [debouncedFilter]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const getFilterOptions = useCallback(() => __async(this, null, function* () {
    const PLAN_FILTER_TYPES = [
      { label: "People Free", value: FreeProductPlans.PEOPLE_FREE.toString() },
      { label: "People Pro", value: PaidProductPlans.PEOPLE_PRO.toString() },
      { label: "Apps Free", value: FreeProductPlans.APPS_FREE.toString() },
      { label: "Apps Pro", value: PaidProductPlans.APPS_PRO.toString() },
      { label: "Devices Free", value: FreeProductPlans.DEVICES_FREE.toString() },
      { label: "Devices Pro", value: PaidProductPlans.DEVICES_PRO.toString() },
      { label: "Money Free", value: FreeProductPlans.MONEY_FREE.toString() },
      { label: "Money Pro", value: PaidProductPlans.MONEY_PRO.toString() }
    ];
    const FILTERS = ["Plans"];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case "Plans":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: PLAN_FILTER_TYPES
          });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), []);
  const fetchCompanyPlans = useCallback(
    (params) => __async(this, null, function* () {
      try {
        const { companyPlanInfo: apiCompanyPlans, pagination: paginationInfo } = yield CompanyAPI.getAllCompanyPlans(
          params
        );
        setCompanyPlans(apiCompanyPlans || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
      } catch (error) {
        showMessage(`Something went wrong fetching company plans. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const fetchDiscounts = useCallback(() => __async(this, null, function* () {
    try {
      const { discounts: apiDiscounts } = yield BillingDiscountAPI.getAllDiscounts({ shouldLimitResult: "false" });
      setDiscounts(apiDiscounts || []);
    } catch (error) {
      showMessage(`Something went wrong fetching discounts. ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage]);
  const refresh = useCallback(() => __async(this, null, function* () {
    try {
      yield fetchCompanyPlans({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split("Plans=")[1] || ""
      });
      yield fetchDiscounts();
      setIsEditModalOpen(false);
    } catch (error) {
      showMessage(`Failed to refresh. ${nestErrorMessage(error)}`, "error");
    }
  }), [fetchCompanyPlans, pageIndex, searchQuery, filterString, fetchDiscounts, showMessage]);
  useEffect(() => {
    (() => __async(this, null, function* () {
      setIsLoadingSubscriptions(true);
      yield fetchCompanyPlans({
        page: pageIndex.toString(),
        pageSize: pageSize.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split("Plans=")[1] || ""
      });
      yield fetchDiscounts();
      yield getFilterOptions();
      setIsLoadingSubscriptions(false);
    }))();
  }, [
    fetchCompanyPlans,
    setIsLoadingSubscriptions,
    fetchDiscounts,
    getFilterOptions,
    pageIndex,
    pageSize,
    searchQuery,
    filterString
  ]);
  const handleEditButtonClick = (selectedCompany) => {
    setCompanyPlanToEdit(selectedCompany);
    setIsEditModalOpen(true);
  };
  const getExportData = useCallback(() => {
    if (!companyPlans) return [];
    const exportHeader = [
      "Company Name",
      "Company ID",
      "People Plan",
      "Activation Date",
      "Active Discounts",
      "Price",
      "Money Plan",
      "Activation Date",
      "Active Discounts",
      "Price",
      "Apps Plan",
      "Activation Date",
      "Active Discounts",
      "Price",
      "Devices Plan",
      "Activation Date",
      "Active Discounts",
      "Price"
    ];
    const exportRows = companyPlans.map((plansData) => {
      var _a2, _b2, _c, _d, _e, _f, _g, _h, _i, _j;
      const peoplePlan = getPlanFromSubscriptions(ProductTypes.PEOPLE, plansData.subscriptions);
      const moneyPlan = getPlanFromSubscriptions(ProductTypes.MONEY, plansData.subscriptions);
      const appsPlan = getPlanFromSubscriptions(ProductTypes.APPS, plansData.subscriptions);
      const devicesPlan = getPlanFromSubscriptions(ProductTypes.DEVICES, plansData.subscriptions);
      const peopleDiscounts = peoplePlan ? groupSubscriptionDiscounts(peoplePlan).join(",") : "";
      const moneyDiscounts = moneyPlan ? groupSubscriptionDiscounts(moneyPlan).join(",") : "";
      const appsDiscounts = appsPlan ? groupSubscriptionDiscounts(appsPlan).join(",") : "";
      const devicesDiscounts = devicesPlan ? groupSubscriptionDiscounts(devicesPlan).join(",") : "";
      return [
        (_a2 = plansData == null ? void 0 : plansData.name) != null ? _a2 : "",
        String((_b2 = plansData == null ? void 0 : plansData.companyId) != null ? _b2 : ""),
        // PEOPLE
        (_c = peoplePlan == null ? void 0 : peoplePlan.planName) != null ? _c : "",
        (peoplePlan == null ? void 0 : peoplePlan.activationDate) ? new LocalDate(peoplePlan.activationDate).toDateString() : "",
        peopleDiscounts,
        String((_d = peoplePlan == null ? void 0 : peoplePlan.cost) != null ? _d : ""),
        // MONEY
        (_e = moneyPlan == null ? void 0 : moneyPlan.planName) != null ? _e : "",
        (moneyPlan == null ? void 0 : moneyPlan.activationDate) ? new LocalDate(moneyPlan.activationDate).toDateString() : "",
        moneyDiscounts,
        String((_f = moneyPlan == null ? void 0 : moneyPlan.cost) != null ? _f : ""),
        // APPS
        (_g = appsPlan == null ? void 0 : appsPlan.planName) != null ? _g : "",
        (appsPlan == null ? void 0 : appsPlan.activationDate) ? new LocalDate(appsPlan.activationDate).toDateString() : "",
        appsDiscounts,
        String((_h = appsPlan == null ? void 0 : appsPlan.cost) != null ? _h : ""),
        // DEVICES
        (_i = devicesPlan == null ? void 0 : devicesPlan.planName) != null ? _i : "",
        (devicesPlan == null ? void 0 : devicesPlan.activationDate) ? new LocalDate(devicesPlan.activationDate).toDateString() : "",
        devicesDiscounts,
        String((_j = devicesPlan == null ? void 0 : devicesPlan.cost) != null ? _j : "")
      ];
    });
    return [exportHeader, ...exportRows];
  }, [companyPlans]);
  const companyPlanColumn = useMemo(
    () => [
      {
        header: () => "Company Name",
        accessorFn: (row) => row,
        id: "companyName",
        enableSorting: true,
        cell: ({ row: { original } }) => original.name ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.DarkGrey,
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original == null ? void 0 : original.name
          }
        ) }) : /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Grey
            }),
            children: "\u2014"
          }
        ),
        size: 220
      },
      {
        header: () => "Id",
        accessorFn: (row) => row,
        id: "companyId",
        enableSorting: true,
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.DarkGrey,
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original == null ? void 0 : original.companyId
          }
        ) }) : /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Grey
            }),
            children: "\u2014"
          }
        ),
        size: 220
      },
      /**** PEOPLE PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: "People Plan",
        id: "peoplePlan",
        productType: ProductTypes.PEOPLE,
        size: 145
      }),
      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: "Date",
        id: "peoplePlanSubscriptionDate",
        size: 140,
        productType: ProductTypes.PEOPLE
      }),
      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: "Active Discount",
        id: "peoplePlanSubscriptionActiveDiscount",
        size: 140,
        productType: ProductTypes.PEOPLE
      }),
      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: "Price",
        id: "peoplePlanSubscriptionPrice",
        size: 140,
        productType: ProductTypes.PEOPLE
      }),
      /**** MONEY PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: "Money Plan",
        id: "moneyPlan",
        productType: ProductTypes.MONEY,
        size: 145
      }),
      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: "Date",
        id: "moneyPlanSubscriptionDate",
        size: 140,
        productType: ProductTypes.MONEY
      }),
      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: "Active Discount",
        id: "moneyPlanSubscriptionActiveDiscount",
        size: 140,
        productType: ProductTypes.MONEY
      }),
      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: "Price",
        id: "moneyPlanSubscriptionPrice",
        size: 140,
        productType: ProductTypes.MONEY
      }),
      /**** APPS PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: "Apps Plan",
        id: "appsPlan",
        productType: ProductTypes.APPS,
        size: 145
      }),
      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: "Date",
        id: "appsPlanSubscriptionDate",
        size: 140,
        productType: ProductTypes.APPS
      }),
      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: "Active Discount",
        id: "appsPlanSubscriptionActiveDiscount",
        size: 140,
        productType: ProductTypes.APPS
      }),
      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: "Price",
        id: "appsPlanSubscriptionPrice",
        size: 140,
        productType: ProductTypes.APPS
      }),
      /**** DEVICES PLAN ****/
      SuperAdminBillingPlanTableHelper.getProductPlanColumn({
        header: "Devices Plan",
        id: "devicesPlan",
        productType: ProductTypes.DEVICES,
        size: 145
      }),
      SuperAdminBillingPlanTableHelper.getDateColumn({
        header: "Date",
        id: "devicesPlanSubscriptionDate",
        size: 140,
        productType: ProductTypes.DEVICES
      }),
      SuperAdminBillingPlanTableHelper.getDiscountColumn({
        header: "Active Discount",
        id: "devicesPlanSubscriptionActiveDiscount",
        size: 140,
        productType: ProductTypes.DEVICES
      }),
      SuperAdminBillingPlanTableHelper.getPriceColumn({
        header: "Price",
        id: "devicesPlanSubscriptionPrice",
        size: 140,
        productType: ProductTypes.DEVICES
      }),
      {
        header: () => "Action",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: true,
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Button,
          {
            onClick: () => {
              handleEditButtonClick(original);
            },
            sx: {
              minWidth: 10,
              padding: 1,
              maxWidth: 40,
              borderRadius: 1,
              backgroundColor: themeColors.lightGrey,
              color: themeColors.DarkGrey
            },
            children: /* @__PURE__ */ jsx(Edit, { sx: { height: 20, width: "20px" } })
          },
          original.companyId
        ) }) : /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              color: themeColors.Grey
            }),
            children: "\u2014"
          }
        ),
        size: 220
      }
    ],
    []
  );
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Billing" }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          CsvDownloader,
          {
            filename: `billing_plans_${new LocalDate().toDateString()}`,
            separator: ",",
            datas: getExportData,
            children: /* @__PURE__ */ jsx(Button, { fullWidth: true, variant: "contained", sx: secondarySmallBtn, children: "Export" })
          }
        )
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoadingSubscriptions, secondLevel: true, children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: __spreadProps(__spreadValues({
            display: "flex",
            justifyContent: "flex-start",
            width: "100%"
          }, spacing.mb20), {
            gap: "5px",
            alignItems: "center"
          }),
          children: [
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                style: { width: "15em" },
                query: searchQuery,
                handleChange: (e) => {
                  var _a2, _b2;
                  setSearchQuery((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                  setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                },
                placeholder: "Enter company name..."
              }
            ),
            filterTypes && /* @__PURE__ */ jsx(TableFilter, { filterTypes, setFilterString: handleFilter, filterString })
          ]
        }
      ),
      !isLoadingSubscriptions && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          BasicServerTable,
          {
            rowData: [...companyPlans],
            columnData: companyPlanColumn,
            sorting,
            setSorting,
            pagination,
            setPagination,
            totalPages,
            totalItems
          }
        ),
        selectedCompanyToEdit && /* @__PURE__ */ jsx(SuperAdminBillingPlanEditModal, { isOpen: isEditModalOpen, setIsOpen: setIsEditModalOpen, children: /* @__PURE__ */ jsx(
          SuperAdminBillingPlanEditForm,
          {
            discounts,
            companyPlan: selectedCompanyToEdit,
            onSave: refresh
          }
        ) })
      ] })
    ] })
  ] });
}
