"use strict";
import styled from "@emotion/styled";
import { styled as materialStyled } from "@mui/material/styles";
const styles = () => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flex: 1,
  overflowY: "auto",
  overflowX: "hidden"
});
export const RootStyle = styled.div(styles);
export const BackofficeRootStyle = materialStyled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%"
});
export const MainHeaderRootStyle = materialStyled("div")(() => ({
  display: "flex",
  backgroundColor: "#fff"
}));
export const textOverflowSx = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  textAlign: "center",
  width: "90%"
};
