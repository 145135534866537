"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Box } from "@mui/material";
import { addWeeks, getWeek } from "date-fns";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as CompanyEventIcon } from "@/images/calendar/CompanyEvent.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { ReactComponent as Calendar } from "@/images/side-bar-icons/Calendar.svg";
import { ReactComponent as GoogleButton } from "@/images/side-bar-icons/GoogleButton.svg";
import { ReactComponent as Next } from "@/images/side-bar-icons/NextBtn.svg";
import { ReactComponent as OutlookLogo } from "@/images/side-bar-icons/outlook_logo.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { FiltersDrawer } from "@/v2/components/table/filters-drawer.component";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { AttendanceEndpoints } from "@/v2/feature/attendance/attendance.api";
import { CalendarEndpoints, GoogleCalendarAPI } from "@/v2/feature/calendar/calendar.api";
import {
  CalendarActionType,
  CalendarView,
  FullDay
} from "@/v2/feature/calendar/calendar.interface";
import { calendarReducer, getMonthDays, getRangeByDays } from "@/v2/feature/calendar/calendar.util";
import { AddEventModal } from "@/v2/feature/calendar/features/components/add-event-modal.component";
import { CalendarTimeline } from "@/v2/feature/calendar/features/components/calendar-timeline.component";
import { ICalLinkDrawer } from "@/v2/feature/calendar/features/components/ical-link-drawer.component";
import { OutlookCalendarPublishDrawer } from "@/v2/feature/calendar/features/components/outlook-calendar-publish-drawer.component";
import { useCalendarData } from "@/v2/feature/calendar/features/hooks/calendar.hook";
import { FiltersEndpoints } from "@/v2/feature/filters/filters.api";
import { UserAPI } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { getWeekDays, Z_startOfWeek } from "@/v2/util/date-fns/week.util";
import { LocalDate } from "@/v2/util/local-date";
const CalendarEventInitial = (polyglot) => ({
  name: "",
  description: "",
  morningOnly: false,
  afternoonOnly: false,
  blockTimeawayRequests: false,
  startDate: new LocalDate().toDateString(),
  endDate: null,
  startFullOrHalfDay: FullDay(polyglot).value,
  endFullOrHalfDay: FullDay(polyglot).value,
  multipleDays: false,
  color: null
});
const initialState = {
  activeView: void 0,
  range: void 0,
  timelineDays: void 0,
  selectedDay: void 0
};
export const CalendarPage = ({
  view,
  calendarAdvEventsTimeline,
  setCalendarAdvEventsTimeline,
  setLoading,
  loading
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const [globalState, globalDipatch] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const context = getScopesContext(globalState.user);
  const hasAppsRead = hasScopes(["apps"], context);
  const [state, dispatch] = useReducer(calendarReducer, initialState);
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const currentUserIsCalendarAdmin = hasScopes(["calendar:all"], getScopesContext(context));
  const [outlookPublishDrawerMode, setOutlookPublishDrawerMode] = useState(
    "subscribe"
  );
  const [outlookPublishDrawerOpen, setOutlookPublishDrawerOpen] = useState(false);
  const [googleCalendarConfig, setGoogleCalendarConfig] = useState(null);
  const [icalLinkDrawerOpen, setIcalLinkDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterString, setFilterString] = useState((_d = (_c = (_b = (_a = globalState.user.features) == null ? void 0 : _a.calendar) == null ? void 0 : _b.table) == null ? void 0 : _c.filters) != null ? _d : "");
  const [selectedEvents, setSelectedEvents] = useState(
    (_h = (_g = (_f = (_e = globalState.user.features) == null ? void 0 : _e.calendar) == null ? void 0 : _f.table) == null ? void 0 : _g.selectedEvents) != null ? _h : ""
  );
  const [searchInput, setSearchInput] = useState("");
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useState(CalendarEventInitial(polyglot));
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const { data: filtersAndTypesOptions, isLoading: filterLoading } = useApiClient(
    FiltersEndpoints.getCalendarFiltersOptions(),
    {
      suspense: false
    }
  );
  const { data: installedApps } = useApiClient(AppIntegrationEndpoints.getCalendarApps(hasAppsRead), {
    suspense: false
  });
  const { data: attendanceSettings } = useApiClient(
    AttendanceEndpoints.getCompanyAttendanceSettings(),
    {
      suspense: false
    }
  );
  const peopleFilters = useMemo(() => {
    var _a2;
    return (_a2 = filtersAndTypesOptions == null ? void 0 : filtersAndTypesOptions.peopleFilters) != null ? _a2 : {};
  }, [filtersAndTypesOptions]);
  const eventsFilters = useMemo(() => {
    var _a2;
    return (_a2 = filtersAndTypesOptions == null ? void 0 : filtersAndTypesOptions.eventsFilters) != null ? _a2 : {};
  }, [filtersAndTypesOptions]);
  const microsoft365Installed = useMemo(() => {
    if (hasAppsRead)
      return installedApps && (installedApps == null ? void 0 : installedApps.length) > 0 ? installedApps == null ? void 0 : installedApps.some((app) => app.stub === "microsoft365") : false;
  }, [installedApps, hasAppsRead]);
  const { data: existingPublishLinks, mutate: refreshCalendarPublishLinks } = useApiClient(
    microsoft365Installed ? CalendarEndpoints.getOutlookCalendarPublishLinks() : { url: "" },
    {
      suspense: false
    }
  );
  const { calendarEventsTimeline, fetchCalendarData, totalPages, totalItems } = useCalendarData(
    {
      dateRange: state.range,
      filterString,
      selectedTypes: selectedEvents,
      searchInput,
      view,
      page: pagination.pageIndex,
      pageSize: pagination.pageSize
    },
    setCalendarAdvEventsTimeline,
    setLoading
  );
  useEffect(() => {
    const defaultFilters = Object.keys(eventsFilters).map((key) => {
      const values = eventsFilters[key] && eventsFilters[key].length > 0 ? eventsFilters[key].map((o) => o.value).join(",") : void 0;
      return values ? `${key}=${values}` : void 0;
    }).filter(Boolean).join("&");
    setSelectedEvents((prev) => {
      var _a2;
      return (_a2 = prev ? prev : defaultFilters) != null ? _a2 : "";
    });
  }, [eventsFilters]);
  const presetDays = useCallback((intialActiveView) => {
    if (intialActiveView === CalendarView.Week) {
      const days = getWeekDays(/* @__PURE__ */ new Date());
      const range = getRangeByDays(days);
      dispatch({
        type: CalendarActionType.SET_RANGE,
        payload: range
      });
      dispatch({
        type: CalendarActionType.SET_SELECTED_DATE,
        payload: Z_startOfWeek(/* @__PURE__ */ new Date())
      });
      dispatch({
        type: CalendarActionType.SET_TIMELINE,
        payload: days
      });
    } else {
      const days = getMonthDays(/* @__PURE__ */ new Date());
      const range = getRangeByDays(days);
      dispatch({
        type: CalendarActionType.SET_RANGE,
        payload: range
      });
      dispatch({
        type: CalendarActionType.SET_SELECTED_DATE,
        payload: dayjs().startOf("month").toDate()
      });
      dispatch({
        type: CalendarActionType.SET_TIMELINE,
        payload: days
      });
    }
  }, []);
  const presetCalendarState = useCallback(() => {
    var _a2, _b2, _c2, _d2;
    const intialActiveView = (_d2 = (_c2 = (_b2 = (_a2 = globalState.user.features) == null ? void 0 : _a2.calendar) == null ? void 0 : _b2.table) == null ? void 0 : _c2.calendarView) != null ? _d2 : CalendarView.Week;
    if (state.activeView !== intialActiveView) {
      dispatch({
        type: CalendarActionType.SET_ACTIVE_VIEW,
        payload: intialActiveView
      });
      presetDays(intialActiveView);
    }
  }, [(_k = (_j = (_i = globalState.user.features) == null ? void 0 : _i.calendar) == null ? void 0 : _j.table) == null ? void 0 : _k.calendarView, state.activeView, presetDays]);
  useEffect(() => {
    if (!state.activeView || !state.range || !state.timelineDays) {
      presetCalendarState();
    }
  }, [presetCalendarState, state.activeView, state.range, state.timelineDays]);
  useEffect(() => {
    if (state.range) {
      fetchCalendarData(state.range);
    }
  }, [fetchCalendarData, state.range]);
  const navigate = useCallback(
    (offset) => __async(void 0, null, function* () {
      if (state.activeView === CalendarView.Week && state.selectedDay) {
        setLoading(true);
        const updatedSelectedWeek = addWeeks(state.selectedDay, offset);
        const days = getWeekDays(updatedSelectedWeek);
        const range = getRangeByDays(days);
        dispatch({
          type: CalendarActionType.SET_RANGE,
          payload: range
        });
        dispatch({
          type: CalendarActionType.SET_SELECTED_DATE,
          payload: updatedSelectedWeek
        });
        dispatch({
          type: CalendarActionType.SET_TIMELINE,
          payload: days
        });
      } else if (state.activeView === CalendarView.Month) {
        setLoading(true);
        const updatedSelectedMonth = dayjs(state.selectedDay).add(offset, "month").toDate();
        const days = getMonthDays(updatedSelectedMonth);
        const range = getRangeByDays(days);
        dispatch({
          type: CalendarActionType.SET_RANGE,
          payload: range
        });
        dispatch({
          type: CalendarActionType.SET_SELECTED_DATE,
          payload: updatedSelectedMonth
        });
        dispatch({
          type: CalendarActionType.SET_TIMELINE,
          payload: days
        });
      }
    }),
    [state.selectedDay, state.activeView, setLoading]
  );
  const fetchGoogleCalendarConfig = useCallback(() => __async(void 0, null, function* () {
    try {
      const data = yield GoogleCalendarAPI.getConfig();
      setGoogleCalendarConfig(data);
    } catch (error) {
      setGoogleCalendarConfig(null);
    }
  }), []);
  useEffect(() => {
    fetchGoogleCalendarConfig();
  }, [fetchGoogleCalendarConfig]);
  const googleDisconnected = useMemo(() => {
    return !googleCalendarConfig;
  }, [googleCalendarConfig]);
  const showOutlookCalendarPublishDrawer = () => {
    setOutlookPublishDrawerOpen(true);
    setOutlookPublishDrawerMode("publish");
  };
  const showOutlookCalendarSubscribeDrawer = () => {
    setOutlookPublishDrawerOpen(true);
    setOutlookPublishDrawerMode("subscribe");
  };
  const neitherAppIsConnected = useMemo(() => {
    return !(microsoft365Installed || !googleDisconnected);
  }, [googleDisconnected, microsoft365Installed]);
  const noAppConnectedAndStandardUser = useMemo(() => {
    return neitherAppIsConnected && !currentUserIsCalendarAdmin;
  }, [currentUserIsCalendarAdmin, neitherAppIsConnected]);
  const iCalLinkMenuOption = useMemo(() => {
    return {
      icon: /* @__PURE__ */ jsx(Calendar, __spreadValues({}, iconSize)),
      handler: () => __async(void 0, null, function* () {
        setIcalLinkDrawerOpen(true);
      }),
      label: polyglot.t("CalendarPage.addToCalendar"),
      disabled: false
    };
  }, [polyglot]);
  const headerOptions = useMemo(
    () => [
      {
        title: polyglot.t("CalendarPage.calendar"),
        options: [
          ...!googleDisconnected ? [
            {
              icon: /* @__PURE__ */ jsx(GoogleButton, __spreadValues({}, iconSize)),
              handler: () => {
                if (googleDisconnected) {
                  routerHistory.push("apps-details/google-workspace/about");
                } else if (googleCalendarConfig) {
                  const calendarUrl = `https://calendar.google.com/calendar/u/${globalState.user.emailAddress}/r?cid=${googleCalendarConfig.calendarId}`;
                  window.open(calendarUrl);
                }
              },
              label: polyglot.t("CalendarPage.subscribe"),
              disabled: false
            }
          ] : [],
          ...microsoft365Installed && currentUserIsCalendarAdmin ? [
            {
              icon: /* @__PURE__ */ jsx(OutlookLogo, __spreadValues({}, iconSize)),
              handler: () => showOutlookCalendarPublishDrawer(),
              label: polyglot.t("OutlookCalendarPublishDrawer.publish"),
              disabled: false
            }
          ] : [],
          ...microsoft365Installed && existingPublishLinks ? [
            {
              icon: /* @__PURE__ */ jsx(OutlookLogo, __spreadValues({}, iconSize)),
              handler: () => showOutlookCalendarSubscribeDrawer(),
              label: polyglot.t("CalendarPage.subscribe"),
              disabled: false
            }
          ] : [],
          ...currentUserIsCalendarAdmin ? [iCalLinkMenuOption] : []
        ]
      },
      {
        title: polyglot.t("CalendarPage.add"),
        options: currentUserIsCalendarAdmin ? [
          {
            icon: /* @__PURE__ */ jsx(CompanyEventIcon, __spreadValues({}, iconSize)),
            handler: () => __async(void 0, null, function* () {
              setIsOpen(true);
              setSelectedCalendarEvent(__spreadValues({}, CalendarEventInitial(polyglot)));
            }),
            label: polyglot.t("CalendarPage.event"),
            disabled: false
          }
        ] : []
      }
    ],
    [
      currentUserIsCalendarAdmin,
      existingPublishLinks,
      googleCalendarConfig,
      googleDisconnected,
      iCalLinkMenuOption,
      microsoft365Installed,
      polyglot,
      routerHistory,
      globalState.user.emailAddress
    ]
  );
  const standardUserActions = useMemo(
    () => [
      {
        title: polyglot.t("CalendarPage.calendar"),
        options: [iCalLinkMenuOption]
      }
    ],
    [iCalLinkMenuOption, polyglot]
  );
  useEffect(() => {
    trackPage("Calendar");
  }, []);
  const hasActionOptions = useMemo(() => {
    var _a2;
    return ((_a2 = headerOptions == null ? void 0 : headerOptions.flatMap((o) => o.options)) == null ? void 0 : _a2.length) > 0;
  }, [headerOptions]);
  const filterForIcalLink = useMemo(() => {
    return [filterString, selectedEvents, `view=${view}`].filter(Boolean).join("&");
  }, [filterString, selectedEvents, view]);
  const handleTodayClick = useCallback(() => __async(void 0, null, function* () {
    presetDays(state.activeView || CalendarView.Week);
  }), [presetDays, state.activeView]);
  const debouncedSearch = useDebouncedCallback((event) => __async(void 0, null, function* () {
    try {
      setSearchInput(event.target.value);
      setPagination({ pageIndex: 1, pageSize: pagination.pageSize });
    } catch (error) {
      showMessage("Failed to handle search", "error");
    }
  }), 500);
  const handleSearch = useCallback((event) => debouncedSearch.callback(event), [
    debouncedSearch
  ]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("calendar.title") }),
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
          !noAppConnectedAndStandardUser && hasActionOptions && /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              headerOptions,
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: polyglot.t("CalendarPage.actions"),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end",
                fullWidth: true
              },
              anchorOrigin: { horizontal: "left", vertical: "bottom" },
              transformOrigin: { horizontal: "left", vertical: "top" }
            }
          ),
          !currentUserIsCalendarAdmin && standardUserActions && /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              headerOptions: standardUserActions,
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: polyglot.t("CalendarPage.actions"),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end",
                fullWidth: true
              },
              anchorOrigin: { horizontal: "left", vertical: "bottom" },
              transformOrigin: { horizontal: "left", vertical: "top" }
            }
          )
        ] }),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: filterLoading, sx: __spreadProps(__spreadValues({}, spacing.pt20), { overflowY: "hidden" }), children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky"
          },
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
              /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: handleSearch }),
              peopleFilters && /* @__PURE__ */ jsx(
                FiltersDrawer,
                {
                  filtersOptions: [{ filters: peopleFilters }],
                  selectedFilters: filterString,
                  onApply: (selectedFilters) => __async(void 0, null, function* () {
                    setPagination((prevPagination) => __spreadProps(__spreadValues({}, prevPagination), {
                      pageIndex: 1
                    }));
                    try {
                      setFilterString(selectedFilters);
                      setPagination((prevPagination) => __spreadProps(__spreadValues({}, prevPagination), {
                        pageIndex: 1
                      }));
                      const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                        "calendar",
                        "table",
                        "filters",
                        selectedFilters
                      );
                      globalDipatch({
                        type: GlobalStateActions.UPDATE_USER,
                        payload: updatedGlobalUser
                      });
                    } catch (error) {
                      showMessage(
                        polyglot.t("CalendarPage.errorMessages.schedule", {
                          errorMessage: nestErrorMessage(error)
                        }),
                        "error"
                      );
                    }
                  })
                }
              )
            ] }),
            /* @__PURE__ */ jsx(
              FiltersDrawer,
              {
                name: polyglot.t("CalendarPage.display"),
                filtersOptions: [{ filters: eventsFilters }],
                selectedFilters: selectedEvents,
                onApply: (selectedFilters) => __async(void 0, null, function* () {
                  try {
                    setSelectedEvents(selectedFilters);
                    const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                      "calendar",
                      "table",
                      "selectedEvents",
                      selectedFilters
                    );
                    globalDipatch({
                      type: GlobalStateActions.UPDATE_USER,
                      payload: updatedGlobalUser
                    });
                  } catch (error) {
                    showMessage(
                      polyglot.t("CalendarPage.errorMessages.schedule", {
                        errorMessage: nestErrorMessage(error)
                      }),
                      "error"
                    );
                  }
                })
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            mt: spacing.s2
          },
          children: [
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  width: "100%",
                  gap: spacing.s1,
                  alignItems: "center"
                },
                children: [
                  /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      colorVariant: "secondary",
                      sizeVariant: "small",
                      disabled: loading,
                      onClick: handleTodayClick,
                      style: { borderRadius: radius.br16 },
                      children: polyglot.t("CalendarPage.today")
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    MonthWeekSwitcher,
                    {
                      navigate,
                      timelineDays: state.timelineDays || [],
                      activeView: state.activeView || CalendarView.Week,
                      nextDisabled: loading,
                      previousDisabled: loading
                    }
                  )
                ]
              }
            ),
            /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  height: "30px",
                  background: themeColors.Background,
                  borderRadius: radius.br20,
                  display: "flex",
                  alignItems: "center"
                },
                children: [CalendarView.Week, CalendarView.Month].map((v) => /* @__PURE__ */ jsx(
                  SwitchButton,
                  {
                    isActive: v === state.activeView,
                    color: themeColors.DarkGrey,
                    action: () => __async(void 0, null, function* () {
                      dispatch({
                        type: CalendarActionType.SET_ACTIVE_VIEW,
                        payload: v
                      });
                      presetDays(v || CalendarView.Week);
                      const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("calendar", "table", "calendarView", v);
                      globalDipatch({
                        type: GlobalStateActions.UPDATE_USER,
                        payload: updatedGlobalUser
                      });
                    }),
                    children: /* @__PURE__ */ jsx(
                      Typography,
                      {
                        variant: "caption",
                        sx: { color: v === state.activeView ? themeColors.white : themeColors.DarkGrey },
                        className: v === state.activeView ? "tnm-switch-active-text" : "tnm-switch-text",
                        children: v
                      }
                    )
                  },
                  v
                ))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.s2 }, children: state.timelineDays && state.timelineDays.length > 0 && /* @__PURE__ */ jsx(
        CalendarTimeline,
        {
          timelineDays: state.timelineDays,
          usersCalendarEvents: calendarEventsTimeline,
          calendarEvents: (calendarAdvEventsTimeline == null ? void 0 : calendarAdvEventsTimeline.companyEvents) || [],
          setIsOpen,
          setSelectedCalendarEvent,
          refreshAbsences: () => __async(void 0, null, function* () {
            yield fetchCalendarData(state.range);
          }),
          activeView: state.activeView || CalendarView.Week,
          advanceLoading: loading,
          attendanceSettings,
          setPagination,
          pagination,
          totalPages,
          totalItems,
          calendarAdvEventsTimeline: (calendarAdvEventsTimeline == null ? void 0 : calendarAdvEventsTimeline.data) || [],
          setLoading
        }
      ) }),
      /* @__PURE__ */ jsx(
        OutlookCalendarPublishDrawer,
        {
          isOpen: outlookPublishDrawerOpen,
          setIsOpen: setOutlookPublishDrawerOpen,
          mode: outlookPublishDrawerMode,
          publishLinks: existingPublishLinks,
          refreshCalendarPublishLinks
        }
      ),
      /* @__PURE__ */ jsx(
        ICalLinkDrawer,
        {
          isOpen: icalLinkDrawerOpen,
          setIsOpen: setIcalLinkDrawerOpen,
          filterString: filterForIcalLink != null ? filterForIcalLink : ""
        }
      ),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["calendar:all"], context, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
        AddEventModal,
        {
          initialValues: selectedCalendarEvent,
          onClose: () => __async(void 0, null, function* () {
            setIsOpen(false);
            setSelectedCalendarEvent(CalendarEventInitial(polyglot));
            yield fetchCalendarData(state.range);
          })
        }
      ) }) })
    ] })
  ] });
};
const buttonStyles = {
  paddingX: spacing.p10,
  paddingY: spacing.p5,
  borderRadius: radius.br20,
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  "&:hover": {
    cursor: "pointer"
  }
};
const SwitchButton = ({
  isActive,
  color,
  action,
  children
}) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      className: isActive ? "switch-no-hover" : "switch-hover",
      sx: __spreadProps(__spreadValues({}, buttonStyles), {
        color: isActive ? themeColors.white : themeColors.DarkGrey,
        background: isActive ? color : "none",
        transition: "all 0.2s ease-in-out"
      }),
      onClick: action,
      children
    }
  );
};
const MonthWeekSwitcher = ({
  navigate,
  timelineDays,
  activeView,
  nextDisabled,
  previousDisabled
}) => {
  const { polyglot } = usePolyglot();
  const generateOptions = () => {
    const options = [];
    const format = activeView === CalendarView.Week ? "week" : "month";
    for (let i = -2; i <= 2; i++) {
      const label = activeView === CalendarView.Week ? `Week ${getWeek(addWeeks(new Date(timelineDays[0]), i))}` : dayjs(timelineDays[0]).add(i, format).format("MMM");
      options.push({
        handler: () => navigate(i),
        label: /* @__PURE__ */ jsx(Typography, { variant: i === 0 ? "title4" : "caption", children: label }),
        disabled: false
      });
    }
    return options;
  };
  const title = activeView === CalendarView.Week ? `Week ${getWeek(new Date(timelineDays[0]))}` : dayjs(timelineDays[0]).format("MMM");
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        backgroundColor: themeColors.Background,
        color: themeColors.DarkGrey,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: radius.br20
      },
      children: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            onClick: () => navigate(-1),
            title: polyglot.t("CalendarPage.previous"),
            sizeVariant: "small",
            colorVariant: "secondary",
            style: { borderRadius: radius.br20, pointerEvents: previousDisabled ? "none" : "auto" },
            disabled: previousDisabled,
            children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "center", minWidth: "100px" }, children: /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title,
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end",
              fullWidth: true
            },
            anchorOrigin: { horizontal: "left", vertical: "bottom" },
            transformOrigin: { horizontal: "left", vertical: "top" },
            options: generateOptions(),
            disabled: nextDisabled && previousDisabled
          }
        ) }),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            onClick: () => navigate(1),
            title: polyglot.t("CalendarPage.next"),
            sizeVariant: "small",
            colorVariant: "secondary",
            style: { borderRadius: radius.br20, pointerEvents: nextDisabled ? "none" : "auto" },
            disabled: nextDisabled,
            children: /* @__PURE__ */ jsx(Next, __spreadValues({}, iconSize))
          }
        )
      ]
    }
  );
};
