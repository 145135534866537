"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import Polyglot from "node-polyglot";
import { CompanyAPI } from "@/api-client/company.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
const PolyglotContext = createContext({
  loaded: false,
  polyglot: new Polyglot({ allowMissing: true }),
  refreshPolyglotData: () => __async(void 0, null, function* () {
  }),
  replacePhrasesOnly: () => __async(void 0, null, function* () {
  }),
  useInitLanguage: () => {
  },
  changeLocale: () => {
  },
  setUserLanguage: () => {
  }
});
export const usePolyglot = () => {
  const contextState = useContext(PolyglotContext);
  if (contextState === null) {
    throw new Error("usePolyglot must be used within a PolyglotProvider tag");
  }
  return contextState;
};
const translationsAvailable = ["en", "ar", "es", "fr", "de", "pt", "it"];
export const PolyglotProvider = ({ children }) => {
  var _a;
  const [hasCompanyTranslations, setHasCompanyTranslations] = useState(false);
  const [cache, setCache] = useState({
    loaded: false,
    translationsJSON: void 0,
    polyglot: new Polyglot({ allowMissing: true })
  });
  const browserLanguage = navigator.language.split("-")[0];
  const defaultLocale = browserLanguage in translationsAvailable ? browserLanguage : "en";
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const [userLanguage, setUserLanguage] = useState((_a = state == null ? void 0 : state.user) == null ? void 0 : _a.language);
  const changeLocale = useCallback(
    (locale) => {
      if (typeof locale !== "undefined" && cache.polyglot && cache.translationsJSON && cache.translationsJSON[locale]) {
        setUserLanguage(locale);
        cache.polyglot.locale(locale.toString());
        cache.polyglot.replace(cache.translationsJSON[locale]);
        if (locale === "ar") {
          document.documentElement.setAttribute("dir", "rtl");
          document.documentElement.setAttribute("lang", "ar");
        } else {
          document.documentElement.setAttribute("dir", "ltr");
          document.documentElement.setAttribute("lang", locale.toString());
        }
      } else {
        console.warn(`Translation for ${locale} not found.`);
      }
    },
    [cache]
  );
  const useInitLanguage = useCallback(() => {
    if (cache.translationsJSON && userLanguage && userLanguage in cache.translationsJSON) {
      changeLocale(userLanguage);
    } else {
      changeLocale(defaultLocale);
    }
  }, [cache.translationsJSON, changeLocale, defaultLocale, userLanguage]);
  const refreshPolyglotData = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const translationsJSON = yield CompanyAPI.getDefaultTranslationsJSON();
      const polyglot = new Polyglot({ phrases: translationsJSON, allowMissing: true });
      setCache({
        polyglot,
        translationsJSON,
        loaded: true
      });
    } catch (error) {
      console.error(error);
      showMessage("Could not load translations", "error");
      setCache({
        polyglot: new Polyglot({ allowMissing: true }),
        translationsJSON: {},
        loaded: true
      });
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refreshPolyglotData();
  }, [refreshPolyglotData]);
  const replacePhrasesOnly = useCallback(() => __async(void 0, null, function* () {
    if (!cache.polyglot || hasCompanyTranslations) return;
    try {
      setLoading(true);
      const translationsJSON = yield CompanyAPI.getTranslationsJSON();
      setCache({
        polyglot: new Polyglot({ phrases: translationsJSON, allowMissing: true }),
        translationsJSON,
        loaded: true
      });
      setHasCompanyTranslations(true);
    } catch (error) {
      showMessage(`Could not replace polyglot content. ${nestErrorMessage(error)}`, "error");
    }
    setLoading(false);
  }), [hasCompanyTranslations, showMessage, cache]);
  return /* @__PURE__ */ jsx(
    PolyglotContext.Provider,
    {
      value: {
        polyglot: cache.polyglot,
        loaded: cache.loaded,
        refreshPolyglotData,
        useInitLanguage,
        changeLocale,
        replacePhrasesOnly,
        setUserLanguage
      },
      children: loading ? /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "83%",
          height: "40%",
          sx: { borderRadius: 2, backgroundColor: themeColors.Background }
        }
      ) : /* @__PURE__ */ jsx(Fragment, { children })
    }
  );
};
