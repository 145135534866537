"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { AbsenceCompanyRouter } from "@v2/feature/absence/company/absence-company.router";
import { AbsenceMeRouter } from "@v2/feature/absence/me/absence-me.router";
import { AbsenceTeamRouter } from "@v2/feature/absence/team/absence-team.router";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { ABSENCE_COMPANY_ROUTE, ABSENCE_ME_ROUTE, ABSENCE_TEAM_ROUTE } from "@/lib/routes";
export const AbsenceRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(user.userId), {
    suspense: false
  });
  const { data: approvalRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });
  const isManager = useMemo(() => {
    var _a, _b, _c;
    const nonTerminatedDirectReports = (_c = (_b = (_a = userSummaryWithReports == null ? void 0 : userSummaryWithReports.directReports) == null ? void 0 : _a.filter(
      (d) => d.userEvent && d.userEvent.status && ["Employed", "Hired"].includes(d.userEvent.status)
    )) == null ? void 0 : _b.length) != null ? _c : 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);
  return approvalRules ? /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:all"], path: ABSENCE_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(AbsenceCompanyRouter, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence"], path: ABSENCE_TEAM_ROUTE, children: /* @__PURE__ */ jsx(AbsenceTeamRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence"], path: ABSENCE_ME_ROUTE, children: /* @__PURE__ */ jsx(AbsenceMeRouter, {}) })
  ] }) : null;
};
