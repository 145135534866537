"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { getAbsencePolicyPayrollValidationSchema } from "@v2/feature/absence/company/policies/pages/absence-policy.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsenceAPI } from "@/v2/feature/absence/absence.api";
export const PolicyPayrollEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const connectedToPayroll = values.includedInPayroll && (values.inPayrollRequests || values.inPayrollOffboarding);
      const toSubmit = {
        inPayrollRequests: connectedToPayroll && values.inPayrollRequests,
        inPayrollOffboarding: connectedToPayroll && values.inPayrollOffboarding,
        payCode: connectedToPayroll && values.payCode ? values.payCode : null,
        payCodeMultiplier: values.inPayrollRequests && values.payCodeMultiplier ? Number(values.payCodeMultiplier) : null
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyPayroll(absencePolicy.id, toSubmit);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      includedInPayroll: absencePolicy.inPayrollRequests || absencePolicy.inPayrollOffboarding,
      inPayrollRequests: absencePolicy.inPayrollRequests,
      inPayrollOffboarding: absencePolicy.inPayrollOffboarding,
      payCode: (_a = absencePolicy.payCode) != null ? _a : "",
      payCodeMultiplier: absencePolicy.inPayrollRequests ? absencePolicy.payCodeMultiplier : null
    },
    validationSchema: getAbsencePolicyPayrollValidationSchema(polyglot),
    onSubmit
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AbsencePolicyRouter.payroll") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Connected to payroll" }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: formik.values.includedInPayroll,
          name: "prevent-own-overlaps",
          onChange: (_e, enabled) => {
            formik.setFieldValue("includedInPayroll", enabled);
            if (enabled) {
              formik.setFieldValue("payCode", null);
              formik.setFieldValue("inPayrollRequests", true);
              formik.setFieldValue("payCodeMultiplier", 1);
              formik.setFieldValue("inPayrollOffboarding", false);
            } else {
              formik.setFieldValue("payCode", null);
              formik.setFieldValue("inPayrollRequests", false);
              formik.setFieldValue("payCodeMultiplier", null);
              formik.setFieldValue("inPayrollOffboarding", false);
            }
          }
        }
      )
    ] }),
    formik.values.includedInPayroll && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "payCode",
        label: polyglot.t("PayItemModule.payCode"),
        value: formik.values.payCode,
        onChange: formik.handleChange,
        error: formik.touched.payCode && !!formik.errors.payCode,
        helperText: (_b = formik.touched.payCode && formik.errors.payCode) != null ? _b : " ",
        endAdornment: "none"
      }
    ),
    formik.values.includedInPayroll && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("AbsencePolicyPayrollSection.approvedRequestsTitle") }),
        /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: formik.values.inPayrollRequests,
            name: "prevent-own-overlaps",
            onChange: (_e, enabled) => {
              formik.setFieldValue("inPayrollRequests", enabled);
              if (enabled) {
                formik.setFieldValue("payCodeMultiplier", 1);
              } else if (!enabled && !formik.values.inPayrollOffboarding) {
                formik.setFieldValue("inPayrollRequests", enabled);
              }
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsencePolicyPayrollSection.approvedRequestsDesc") })
    ] }),
    formik.values.includedInPayroll && formik.values.inPayrollRequests && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "payCodeMultiplier",
        label: polyglot.t("PayItemModule.payCodeMultiplier"),
        value: formik.values.payCodeMultiplier,
        onChange: formik.handleChange,
        error: formik.touched.payCodeMultiplier && !!formik.errors.payCodeMultiplier,
        helperText: (_c = formik.touched.payCodeMultiplier && formik.errors.payCodeMultiplier) != null ? _c : " ",
        endAdornment: "none"
      }
    ),
    formik.values.includedInPayroll && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("AbsencePolicyPayrollSection.accruedHolidayTitle") }),
        /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: formik.values.inPayrollOffboarding,
            name: "prevent-own-overlaps",
            onChange: (_e, enabled) => {
              formik.setFieldValue("inPayrollOffboarding", enabled);
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsencePolicyPayrollSection.accruedHolidayDesc") })
    ] }),
    formik.values.includedInPayroll && !formik.values.inPayrollOffboarding && !formik.values.inPayrollRequests && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "RedDark", children: polyglot.t("AbsencePolicyPayrollSection.pleaseSelect1PayrollOption") }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] }) }) });
};
