"use strict";
import { NO_VAT_OPTION, REVERSE_CHARGE_OPTION } from "./payments.interface";
export const NO_VAT_VALUE = 999;
export const getExpenseTypeOptions = (expenseTypes, allowedExpenseTypes) => {
  var _a;
  return (_a = expenseTypes == null ? void 0 : expenseTypes.filter((et) => Boolean(et.id) && (!allowedExpenseTypes || allowedExpenseTypes.includes(et.id))).map((et) => ({
    label: et.name,
    value: et.id != null ? et.id : ""
  }))) != null ? _a : [];
};
export const getTaxRateFromSelectedOption = (value) => {
  if (value === NO_VAT_VALUE) return null;
  else return value;
};
export const getTaxRateForTotalSection = (taxRate, withParentheses = true) => {
  if (taxRate === null) return "";
  let result;
  if (taxRate === NO_VAT_VALUE) result = NO_VAT_OPTION;
  else if (taxRate === 0) result = REVERSE_CHARGE_OPTION;
  else result = `${taxRate}%`;
  return withParentheses ? ` (${result})` : result;
};
export const calculatePreTaxAmount = (gross, taxRate) => {
  if (taxRate === 0 || !taxRate || taxRate === NO_VAT_VALUE) return { preTax: gross, taxAmount: 0 };
  if (taxRate && gross) {
    const calculatedPretaxAmount = gross / (1 + taxRate / 100);
    const taxAmount = gross - calculatedPretaxAmount;
    return { preTax: calculatedPretaxAmount, taxAmount };
  }
  return { preTax: gross, taxAmount: 0 };
};
export const getExpenseTotalsBasedOnLineItems = (lineItems) => {
  let totalGross = 0;
  let totalAmount = 0;
  let totalTaxAmount = 0;
  for (const eachLineItem of lineItems) {
    const { amount = 0, gross = 0, taxRate = 0 } = eachLineItem;
    if (taxRate === 0) {
      totalAmount += gross;
    } else {
      const { taxAmount } = calculatePreTaxAmount(gross, taxRate);
      totalTaxAmount += taxAmount;
      totalAmount += amount;
    }
    totalGross += gross;
  }
  totalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));
  totalAmount = parseFloat(totalAmount.toFixed(2));
  totalGross = parseFloat(totalGross.toFixed(2));
  return { totalGross, totalAmount, totalTaxAmount };
};
