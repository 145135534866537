"use strict";
import axios from "axios";
import { generatePath, useHistory } from "react-router-dom";
import { LOGIN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
export function useApiErrorInterceptor() {
  const routerHistory = useHistory();
  axios.interceptors.response.use(
    (successResponse) => successResponse,
    (error) => {
      var _a, _b, _c, _d, _e, _f, _g, _h;
      if (((_a = error.response) == null ? void 0 : _a.status) === 403 && ((_c = (_b = error.response) == null ? void 0 : _b.data) == null ? void 0 : _c.code) === "DEACTIVATED_ACCOUNT") {
        routerHistory.push(`${LOGIN_ROUTE}?deactivated=true`);
        return new Promise(() => {
        });
      }
      if (((_d = error.response) == null ? void 0 : _d.status) === 403 && ((_f = (_e = error.response) == null ? void 0 : _e.data) == null ? void 0 : _f.code) === "ONBOARDING_REQUIRED") {
        const userId = (_h = (_g = error.response) == null ? void 0 : _g.data) == null ? void 0 : _h.message.split(";")[1];
        routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
        return new Promise(() => {
        });
      }
      return Promise.reject(error);
    }
  );
}
