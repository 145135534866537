"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceLengthUnit } from "@v2/feature/absence/absence.interface";
import { convertMinutesToClockHours, isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import { BalanceAdjustmentsDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/balance-adjustments-drawer.component";
import { AbsenceDrawer } from "@v2/feature/absence/sections/absence-drawer/absence-drawer.section";
import { OffboardingAbsenceAddToPayrollDrawer } from "@v2/feature/offboarding/offboarding-process/absence/offboarding-absence-add-to-payroll-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Action } from "@/images/fields/Action.svg";
export const OffboardingAbsenceBalancesTable = ({
  userId,
  userBalanceStats,
  userPolicies,
  userLeaveDate,
  refreshState,
  refreshFutureRequests
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [isAddAdjustmentOpen, setIsAddAdjustmentOpen] = useState(false);
  const [isAddToPayrollOpen, setIsAddToPayrollOpen] = useState(false);
  const hasPayrollManagerScope = useMemo(() => {
    return hasScopes(["payroll:manager"], getScopesContext({ userId }));
  }, [userId, hasScopes, getScopesContext]);
  const policyBalance = useMemo(() => {
    var _a2;
    if (!selectedPolicyId || !userBalanceStats) return null;
    return (_a2 = userBalanceStats[selectedPolicyId]) != null ? _a2 : null;
  }, [selectedPolicyId, userBalanceStats]);
  const selectedPolicy = useMemo(() => {
    var _a2;
    if (!selectedPolicyId || !userPolicies) return null;
    return (_a2 = userPolicies.find((p) => p.id === selectedPolicyId)) != null ? _a2 : null;
  }, [userPolicies, selectedPolicyId]);
  const payrollPoliciesSet = useMemo(() => {
    if (!userPolicies) return /* @__PURE__ */ new Set();
    const payrollPoliciesIds = userPolicies.filter((p) => p.inPayrollOffboarding).map((p) => p.id);
    return new Set(payrollPoliciesIds);
  }, [userPolicies]);
  const userPoliciesData = useMemo(() => {
    if (!userPolicies || !userBalanceStats) return [];
    return userPolicies.map((policy) => {
      var _a2, _b;
      if (isUnlimitedPolicy(policy)) return null;
      const balance = userBalanceStats[policy.id];
      if (!balance) return null;
      const showBalanceInDays = Boolean(balance.lengthUnit === AbsenceLengthUnit.Day && balance.isOnRegularSchedule);
      const balanceValue = showBalanceInDays ? (_a2 = balance.currentBalanceInDays) != null ? _a2 : 0 : (_b = balance.currentBalance) != null ? _b : 0;
      const balanceString = showBalanceInDays ? polyglot.t("OffboardingAbsence.numDays", {
        smart_count: balanceValue
      }) : convertMinutesToClockHours(balanceValue, polyglot);
      return {
        id: policy.id,
        name: policy.name,
        inPayrollOffboarding: policy.inPayrollOffboarding,
        payCode: policy.payCode,
        balance: balanceString,
        balanceInMinutes: balance.currentBalance
      };
    }).filter(Boolean);
  }, [userPolicies, userBalanceStats, polyglot]);
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("OffboardingAbsence.policy"),
        accessorFn: (row) => row,
        id: "id",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2;
          return (_a2 = item.name) != null ? _a2 : "";
        }),
        cell: ({ row }) => {
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: row.original.name }) });
        }
      },
      {
        header: () => polyglot.t("OffboardingAbsence.accruedBalance"),
        accessorFn: (row) => row,
        id: "balance",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a2;
          return (_a2 = item.balanceInMinutes) != null ? _a2 : 0;
        }),
        cell: ({ row }) => {
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: row.original.balance }) });
        }
      },
      {
        header: () => polyglot.t("OffboardingAbsence.payCode"),
        accessorFn: (row) => row,
        id: "payCode",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2;
          return (_a2 = item.payCode) != null ? _a2 : "";
        }),
        cell: ({ row }) => {
          return /* @__PURE__ */ jsx(Box, { children: row.original.payCode ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: row.original.payCode }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "",
        id: "actions",
        enableSorting: false,
        cell: ({ row }) => {
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "end", alignItems: "center" }, children: /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: [
                {
                  handler: () => {
                    setSelectedPolicyId(row.original.id);
                    setIsAddToPayrollOpen(true);
                  },
                  label: polyglot.t("OffboardingAbsence.addToPayroll"),
                  hidden: !hasPayrollManagerScope || !payrollPoliciesSet.has(row.original.id)
                },
                {
                  handler: () => {
                    setSelectedPolicyId(row.original.id);
                    setIsBookingOpen(true);
                  },
                  label: polyglot.t("OffboardingAbsence.bookAbsence")
                },
                {
                  handler: () => {
                    setSelectedPolicyId(row.original.id);
                    setIsAddAdjustmentOpen(true);
                  },
                  label: polyglot.t("OffboardingAbsence.addAdjustment")
                }
              ],
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(Action, __spreadValues({}, iconSize))
              }
            }
          ) });
        }
      }
    ],
    [hasPayrollManagerScope, payrollPoliciesSet, polyglot]
  );
  return userPoliciesData.length > 0 ? /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingAbsence.remainingBalanceAtTermination", {
      date: new LocalDate(userLeaveDate).toLocaleDateString(void 0, {
        day: "numeric",
        month: "short",
        year: "numeric"
      })
    }) }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mb: spacing.s2 }, children: polyglot.t("OffboardingAbsence.remainingBalanceActionsDesc") }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(BasicTable, { rowData: userPoliciesData != null ? userPoliciesData : [], columnData, hidePagination: true }) }),
    /* @__PURE__ */ jsx(
      AbsenceDrawer,
      {
        isOpen: isBookingOpen,
        userId,
        policyId: selectedPolicyId != null ? selectedPolicyId : void 0,
        onClose: () => {
          setIsBookingOpen(false);
          setSelectedPolicyId(null);
        },
        refresh: () => __async(void 0, null, function* () {
          yield Promise.all([refreshState(), refreshFutureRequests()]);
        }),
        setIsAbsenceDrawerOpen: setIsBookingOpen,
        afterClose: () => {
          setSelectedPolicyId(null);
        }
      }
    ),
    policyBalance && selectedPolicy && /* @__PURE__ */ jsx(
      BalanceAdjustmentsDrawer,
      {
        isOpen: isAddAdjustmentOpen,
        setIsOpen: setIsAddAdjustmentOpen,
        userId,
        absencePolicy: selectedPolicy,
        policyBreakdown: policyBalance,
        refresh: refreshState,
        isOnRegularSchedule: !!(policyBalance == null ? void 0 : policyBalance.isOnRegularSchedule),
        currentAverageWorkDayLength: (_a = policyBalance == null ? void 0 : policyBalance.currentAverageWorkDayLength) != null ? _a : 480,
        afterClose: () => {
          setSelectedPolicyId(null);
        }
      }
    ),
    /* @__PURE__ */ jsx(
      OffboardingAbsenceAddToPayrollDrawer,
      {
        isOpen: isAddToPayrollOpen,
        setIsOpen: setIsAddToPayrollOpen,
        userId,
        absencePolicy: selectedPolicy,
        refresh: refreshState,
        afterClose: () => {
          setSelectedPolicyId(null);
        }
      }
    )
  ] }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingAbsence.noCurrentBalance") }) });
};
