"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { IconButton, Box } from "@mui/material";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { ReactComponent as Next } from "@/images/side-bar-icons/NextBtn.svg";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NavigationButtons = ({ onNext, onBack, disableBack, disabledNext }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tableIconButtonSx,
        onClick: (e) => {
          onBack && onBack();
          e.stopPropagation();
        },
        disabled: disableBack,
        children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
      }
    ),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tableIconButtonSx,
        onClick: (e) => {
          onNext && onNext();
          e.stopPropagation();
        },
        disabled: disabledNext,
        children: /* @__PURE__ */ jsx(Next, __spreadValues({}, iconSize))
      }
    )
  ] });
};
