"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ParticipantsAddRevieweeModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-add-reviewee-modal.component";
import { ParticipantsEmptyState } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-empty-state.component";
import { ParticipantsSharedTable } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/shared/participants-shared-table.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsScheduledView = ({
  reviewCycle,
  isEditable,
  refresh,
  reviewParticipants,
  reviewParticipantsLoading,
  reach
}) => {
  var _a;
  const [isRevieweeAddOpen, setIsRevieweeAddOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    reviewCycle && reviewCycle.revieweeId && ((_a = reviewCycle.revieweeId) == null ? void 0 : _a.length) > 0 ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: "Participants",
              items: [
                {
                  type: SectionItemType.Pair,
                  label: "Reviewer select",
                  value: (reviewCycle == null ? void 0 : reviewCycle.reviewerSelect) ? reviewCycle.reviewerSelect.join(", ") : "NA"
                },
                {
                  type: SectionItemType.Pair,
                  label: "Participants",
                  value: (reviewCycle == null ? void 0 : reviewCycle.revieweeId) ? `${reviewCycle == null ? void 0 : reviewCycle.revieweeId.length} - ${(reviewCycle == null ? void 0 : reviewCycle.revieweeId.length) > 1 ? "participants" : "participant"}` : "No participant was selected"
                },
                {
                  type: SectionItemType.Component,
                  value: isEditable ? /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      sizeVariant: "small",
                      colorVariant: "primary",
                      onClick: () => setIsRevieweeAddOpen(true),
                      style: { marginTop: spacing.m16 },
                      children: "Add participants"
                    }
                  ) : null
                }
              ]
            }
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Completed || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Ongoing ? "Participants part of this cycle" : "Participants who will be invited",
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    ParticipantsSharedTable,
                    {
                      reviewCycle,
                      reviewParticipants,
                      reviewParticipantsLoading,
                      refresh,
                      isEditable
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    ] }) : /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            contentWidth: "100%",
            headerWidth: "100%",
            title: "Participants",
            items: [
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(ParticipantsEmptyState, { setIsOpen: setIsRevieweeAddOpen })
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      ParticipantsAddRevieweeModal,
      {
        reviewCycle,
        isOpen: isRevieweeAddOpen,
        setIsOpen: setIsRevieweeAddOpen,
        onClose: () => setIsRevieweeAddOpen(false),
        refresh,
        reach
      }
    )
  ] });
};
