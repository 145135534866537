"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const RequestOnDemandBenefitDrawer = ({
  isOpen,
  setIsOpen,
  userCustomBenefit,
  remainingBalance,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    RequestOnDemandBenefitDrawerContent,
    {
      userCustomBenefit,
      remainingBalance,
      refresh,
      setIsOpen
    }
  ) });
};
export const RequestOnDemandBenefitDrawerContent = ({
  userCustomBenefit,
  remainingBalance,
  refresh,
  setIsOpen
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      amount: 0,
      notes: ""
    },
    validationSchema: yup.object({
      amount: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).min(0, polyglot.t("ValidationMessages.validValue")).max(remainingBalance, polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      notes: yup.string().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setIsLoading(true);
        const data = { amount: Number(values.amount), notes: values.notes };
        yield CustomBenefitAPI.userCustomBenefitOneOffPayment(
          userCustomBenefit.userId,
          userCustomBenefit.customBenefitId,
          userCustomBenefit.id,
          data
        );
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setIsLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("BenefitModule.requestPayment") }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: "20px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("BenefitModule.remainingToday") }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: formatMoney({ amount: remainingBalance }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: "10px" }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: polyglot.t("General.amount"),
        value: formik.values.amount,
        onChange: formik.handleChange,
        error: formik.touched.amount && Boolean(formik.errors.amount),
        helperText: formik.touched.amount && formik.errors.amount,
        fullWidth: true,
        size: "small"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: "10px" }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "notes",
        label: polyglot.t("General.notes"),
        value: formik.values.notes,
        onChange: formik.handleChange,
        error: formik.touched.notes && Boolean(formik.errors.notes),
        helperText: formik.touched.notes && formik.errors.notes,
        fullWidth: true,
        size: "small"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: "30px" }), children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.request"),
        sizeVariant: "medium",
        loading: isLoading,
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) });
};
