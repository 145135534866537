"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Collapse, Link } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as NewArrowDown } from "@/images/side-bar-icons/NewArrowDown.svg";
import { ReactComponent as NewArrowUp } from "@/images/side-bar-icons/NewArrowUp.svg";
import { getActiveSub } from "@/v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { activeSubItemStyle, NavSubItemStyle, SubItemStyle } from "@/v2/styles/list-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: "12px", height: "12px" };
export const renderSingleItem = (list, action, pathname, index) => {
  var _a;
  if (list.isHidden) return null;
  return /* @__PURE__ */ jsx(
    Link,
    {
      to: (_a = list.path) != null ? _a : "",
      component: RouterLink,
      style: { textDecoration: "none" },
      children: /* @__PURE__ */ jsx(
        NavSubItemStyle,
        {
          sx: __spreadValues({}, list.path && getActiveSub(pathname, list.path) && activeSubItemStyle),
          onClick: () => {
            action(list);
          },
          className: list.path && getActiveSub(pathname, list.path) ? "no-hover-effect" : "hover-effect",
          children: /* @__PURE__ */ jsxs(SubItemStyle, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: list.path && getActiveSub(pathname, list.path) ? "title4" : "caption", children: list.title }),
            list.deprecated && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: "(deprecated)" })
          ] })
        },
        list.stub
      )
    },
    `${list.stub}-${index}-link`
  );
};
export const renderSubMenu = (list, handleParentClick, pathname, activeSubMenuParent, handleSubmenuClick, index) => {
  if (!list.subMenu || list.isHidden || !list.hasChildren) return null;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      NavSubItemStyle,
      {
        onClick: () => handleParentClick(list),
        className: list.path && getActiveSub(pathname, list.path) ? "no-hover-effect" : "hover-effect",
        children: [
          /* @__PURE__ */ jsx(SubItemStyle, { children: list.title }),
          activeSubMenuParent === list.stub ? /* @__PURE__ */ jsx(NewArrowUp, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(NewArrowDown, __spreadValues({}, iconSize))
        ]
      },
      `${list.stub}-${index}-subitem`
    ),
    /* @__PURE__ */ jsx(
      Collapse,
      {
        in: activeSubMenuParent === list.stub,
        timeout: "auto",
        unmountOnExit: true,
        children: list.subMenu.map(
          (sm, idx) => {
            var _a;
            return !sm.isHidden && /* @__PURE__ */ jsx(
              Link,
              {
                to: (_a = sm.path) != null ? _a : "",
                component: RouterLink,
                style: { textDecoration: "none" },
                children: /* @__PURE__ */ jsx(
                  NavSubItemStyle,
                  {
                    sx: __spreadValues({
                      paddingLeft: spacing.p10
                    }, sm.path && getActiveSub(pathname, sm.path) && activeSubItemStyle),
                    onClick: () => handleSubmenuClick(sm),
                    className: sm.path && getActiveSub(pathname, sm.path) ? "no-hover-effect" : "hover-effect",
                    children: /* @__PURE__ */ jsx(
                      SubItemStyle,
                      {
                        sx: __spreadValues({}, sm.path && getActiveSub(pathname, sm.path) ? themeFonts.title4 : themeFonts.caption),
                        children: sm.title
                      }
                    )
                  },
                  sm.stub
                )
              },
              `${list.stub}-${idx}-link`
            );
          }
        )
      },
      `${list.stub}-${index}-collapse`
    )
  ] }, `${list.stub}-${index}-subitem`);
};
