"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
export const useTimelineForm = (reviewCycle, onClose, refresh, showFlags) => {
  const [showMessage] = useMessage();
  const { showSelf, showUpward, showPeer, showManager } = showFlags;
  const validationSchema = useMemo(() => {
    return yup.object({
      timelineSettings: yup.object({
        startNow: yup.boolean().required(),
        startDate: yup.string().nullable().when("startNow", {
          is: false,
          then: yup.string().required("Start date is required"),
          otherwise: yup.string().nullable()
        }),
        startTime: yup.string().nullable().when("startNow", {
          is: false,
          then: yup.string().required("Start time is required"),
          otherwise: yup.string().nullable()
        }),
        startTimeMeridiem: yup.string().nullable().when("startNow", {
          is: false,
          then: yup.string().required("Start time meridiem is required"),
          otherwise: yup.string().nullable()
        }),
        selfReviewDeadline: showSelf ? yup.number().nullable().required("Self review deadline is required") : yup.number().nullable(),
        upwardReviewDeadline: showUpward ? yup.number().nullable().required("Upward review deadline is required") : yup.number().nullable(),
        peerReviewDeadline: showPeer ? yup.number().nullable().required("Peer review deadline is required") : yup.number().nullable(),
        managerReviewDeadline: showManager ? yup.number().nullable().required("Manager review deadline is required") : yup.number().nullable()
      })
    });
  }, [showSelf, showUpward, showPeer, showManager]);
  const formik = useFormik({
    initialValues: {
      timelineSettings: (reviewCycle == null ? void 0 : reviewCycle.timelineSettings) || {
        startNow: true,
        startDate: null,
        startTime: "9",
        startTimeMeridiem: "AM",
        selfReviewDeadline: 7,
        upwardReviewDeadline: 7,
        peerReviewDeadline: 7,
        managerReviewDeadline: 14
      }
    },
    validationSchema,
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (reviewCycle) {
          yield ReviewCycleAPI.updateTimelinelinesReviewCycle(reviewCycle.id, __spreadValues({}, values.timelineSettings));
          const successMessage = reviewCycle.state === CycleState.Draft ? "Successfully update the cycle" : "Please wait, the update is in the queue.";
          showMessage(successMessage, "success");
          yield refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
