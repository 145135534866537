"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Switch } from "react-router-dom";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import {
  DOCUMENTS_NEW_COMPANY_DOMAIN_ROUTE,
  DOCUMENTS_NEW_ME_DOMAIN_ROUTE,
  DOCUMENTS_NEW_TEAM_DOMAIN_ROUTE
} from "@/lib/routes";
import { DocumentNewCompanyRouter } from "@/v2/feature/document-new/document-new-company.router";
import { DocumentNewMeRouter } from "@/v2/feature/document-new/document-new-me.router";
import { DocumentNewTeamRouter } from "@/v2/feature/document-new/document-new-team.router";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const DocumentNewRouter = () => {
  const { data: sitesForCompany } = useApiClient(SiteEndpoints.getSiteOptions(), { suspense: false });
  const { data: departmentsForCompany } = useApiClient(DepartmentEndpoints.getCompanyDepartmentsOptions(), {
    suspense: false
  });
  const { data: entitiesForCompany } = useApiClient(CompanyEndpoints.getCompanyEntityOptions(), { suspense: false });
  const customRuleDataset = useMemo(() => {
    return {
      sites: sitesForCompany != null ? sitesForCompany : [],
      departments: departmentsForCompany != null ? departmentsForCompany : [],
      entties: entitiesForCompany != null ? entitiesForCompany : []
    };
  }, [sitesForCompany, departmentsForCompany, entitiesForCompany]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_COMPANY_DOCUMENTS, path: DOCUMENTS_NEW_COMPANY_DOMAIN_ROUTE, children: /* @__PURE__ */ jsx(DocumentNewCompanyRouter, { customRuleDataset }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_TEAM_DOCUMENTS, path: DOCUMENTS_NEW_TEAM_DOMAIN_ROUTE, children: /* @__PURE__ */ jsx(DocumentNewTeamRouter, { customRuleDataset }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: DocumentsScopes.VIEW_MY_DOCUMENTS, path: DOCUMENTS_NEW_ME_DOMAIN_ROUTE, children: /* @__PURE__ */ jsx(DocumentNewMeRouter, { customRuleDataset }) })
  ] });
};
