"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { PublicHolidaysAPI } from "@v2/feature/absence/public-holidays.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const RequestPHCancellationDrawer = ({ isOpen, setIsOpen, absence, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(RequestPHCancellationDrawerContent, { absence, refresh, setIsOpen })
    }
  ) });
};
const RequestPHCancellationDrawerContent = ({ absence, refresh, setIsOpen }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setIsLoading] = useState(false);
  const requestPHCancellation = useCallback(() => __async(void 0, null, function* () {
    if (!absence.isPublicHoliday || !absence.canRequestPHDeletion) return;
    setIsLoading(true);
    try {
      yield PublicHolidaysAPI.requestDeletionOfPublicHoliday(absence.userId, absence.policyId, absence.start);
      setIsOpen(false);
      yield refresh();
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
    setIsLoading(false);
  }), [absence, showMessage, polyglot, refresh, setIsOpen]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AbsencePHCancellation.requestCancellation") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsencePHCancellation.description") }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("AbsencePHCancellation.requestCancellation"),
          onClick: requestPHCancellation,
          sizeVariant: "medium",
          colorVariant: "primary",
          loading,
          fullWidth: true
        }
      )
    ] })
  ] });
};
