"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const CommaSeparatedTextfieldComponent = ({
  fieldName,
  label = "",
  fieldValue,
  setCommaSeparatedValue,
  errorText,
  helperText
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label,
        name: fieldName,
        defaultValue: fieldValue,
        value: fieldValue,
        onChange: (e) => {
          setCommaSeparatedValue(fieldName, e.target.value);
        },
        clearText: () => setCommaSeparatedValue(fieldName, "")
      }
    ),
    errorText && fieldValue ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { opacity: 1, color: themeColors.darkRed }), children: errorText }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.caption), children: helperText })
  ] });
};
