"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
export const DocPreviewer = ({
  docData,
  fileBuffer,
  contentType,
  visible,
  onClose,
  title
}) => {
  const [iframeSrc, setIframeSrc] = useState(null);
  useEffect(() => {
    if (fileBuffer) {
      const buffer = new Uint8Array(fileBuffer.data);
      const blob = new Blob([buffer], { type: contentType });
      const objectUrl = URL.createObjectURL(blob);
      setIframeSrc(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [contentType, fileBuffer]);
  return /* @__PURE__ */ jsx(
    Dialog,
    {
      open: visible,
      onClose,
      sx: { margin: "auto", height: "100%", overflowX: "hidden", width: "100%" },
      PaperProps: { sx: { height: "100%", overflowX: "hidden", width: "100%" } },
      maxWidth: "md",
      children: /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "100%",
            height: "100%"
          },
          children: [
            fileBuffer && contentType && iframeSrc && /* @__PURE__ */ jsx(
              "iframe",
              {
                height: "100%",
                width: "100%",
                style: { overflowX: "hidden", border: "none", outline: "none" },
                src: iframeSrc,
                title
              }
            ),
            docData && /* @__PURE__ */ jsx(
              "iframe",
              {
                height: "100%",
                width: "100%",
                style: { overflowX: "hidden", border: "none", outline: "none" },
                src: docData,
                title
              }
            )
          ]
        }
      ) })
    }
  );
};
