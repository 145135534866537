"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ReactComponent as MistakeGray } from "@/images/side-bar-icons/MistakeGray.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserHeader } from "@/v2/components/user-header.component";
import { isUKCountryCode } from "@/v2/infrastructure/country/country.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
const ItemDetail = ({
  name,
  value,
  uppercase,
  capitalize
}) => /* @__PURE__ */ jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [
  /* @__PURE__ */ jsx("span", { style: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: name }),
  /* @__PURE__ */ jsx(
    "span",
    {
      style: __spreadProps(__spreadValues({}, themeFonts.title4), { textTransform: uppercase ? "uppercase" : capitalize ? "capitalize" : void 0 }),
      children: value != null ? value : /* @__PURE__ */ jsx(MistakeGray, {})
    }
  )
] });
export const ViewUserPayrollPage = ({
  user,
  record,
  canEdit,
  addToPayroll,
  removeFromPayroll,
  isUserUpdating,
  close
}) => {
  var _a, _b, _c;
  const [updatingMode, setUpdatingMode] = useState();
  const booleanToValue = (value, t = "Yes", f = "No") => {
    if (typeof value === "undefined") return void 0;
    return value ? t : f;
  };
  const slPlanToValue = (value) => {
    switch (value) {
      case "PlanOne":
        return "Plan 1";
      case "PlanTwo":
        return "Plan 2";
      case "PlanFour":
        return "Plan 4";
      default:
        return value;
    }
  };
  const payrollValues = (_a = record.userPayroll) == null ? void 0 : _a.payrollValues;
  return /* @__PURE__ */ jsxs(Stack, { flex: 1, children: [
    /* @__PURE__ */ jsx(UserHeader, { user }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mt: "30px", color: themeColors.DarkGrey }), children: "Payroll" }),
    ((_b = record.userPayroll) == null ? void 0 : _b.countryCode) && !isUKCountryCode(record.userPayroll.countryCode) ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: "20px", color: themeColors.DarkGrey }), children: "User is in a non-UK payroll jurisdiction." }) : /* @__PURE__ */ jsxs(Stack, { sx: { gap: "16px", mt: "14px" }, children: [
      /* @__PURE__ */ jsx(ItemDetail, { name: "Payroll ID", value: (_c = record.userPayroll) == null ? void 0 : _c.payrollId }),
      /* @__PURE__ */ jsx(ItemDetail, { name: "Tax Code", value: payrollValues == null ? void 0 : payrollValues.taxCode, uppercase: true }),
      /* @__PURE__ */ jsx(
        ItemDetail,
        {
          name: "Calculation method",
          value: booleanToValue(payrollValues == null ? void 0 : payrollValues.week1Month1, "Month 1", "Cumulative")
        }
      ),
      /* @__PURE__ */ jsx(ItemDetail, { name: "Starter Declaration", value: payrollValues == null ? void 0 : payrollValues.starterDeclaration, uppercase: true }),
      /* @__PURE__ */ jsx(ItemDetail, { name: "NI Category", value: payrollValues == null ? void 0 : payrollValues.niTable, uppercase: true }),
      /* @__PURE__ */ jsx(ItemDetail, { name: "NI Number", value: payrollValues == null ? void 0 : payrollValues.niNumber, uppercase: true }),
      /* @__PURE__ */ jsx(ItemDetail, { name: "Student Loan", value: slPlanToValue(payrollValues == null ? void 0 : payrollValues.studentLoan) }),
      /* @__PURE__ */ jsx(ItemDetail, { name: "Postgraduate Loan", value: booleanToValue(payrollValues == null ? void 0 : payrollValues.postgradLoan) }),
      /* @__PURE__ */ jsx(ItemDetail, { name: "Legal gender", value: payrollValues == null ? void 0 : payrollValues.legalGender, capitalize: true }),
      /* @__PURE__ */ jsx(
        ItemDetail,
        {
          name: "Directorship",
          value: booleanToValue(
            payrollValues == null ? void 0 : payrollValues.isDirector,
            `${payrollValues == null ? void 0 : payrollValues.directorFrom} - ${(payrollValues == null ? void 0 : payrollValues.directorTo) || "present"}`,
            "None"
          )
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Stack, { direction: "row", sx: { m: "30px auto 0", gap: "10px" }, children: [
      canEdit && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          style: { width: "175px", maxWidth: "40vw" },
          onClick: () => close({ switchToEdit: true }),
          disabled: isUserUpdating,
          children: "Edit"
        }
      ),
      addToPayroll && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Add to payroll",
          loading: updatingMode === "add",
          style: { width: "175px", maxWidth: "40vw" },
          colorVariant: "primary",
          sizeVariant: "medium",
          onClick: () => __async(void 0, null, function* () {
            setUpdatingMode("add");
            if (yield addToPayroll(record)) close();
            else setUpdatingMode(void 0);
          }),
          disabled: isUserUpdating
        }
      ),
      removeFromPayroll && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Remove from payroll",
          loading: updatingMode === "remove",
          colorVariant: "primary",
          sizeVariant: "medium",
          style: { width: "175px", maxWidth: "40vw" },
          onClick: () => __async(void 0, null, function* () {
            setUpdatingMode("remove");
            if (yield removeFromPayroll(record)) close();
            else setUpdatingMode(void 0);
          }),
          disabled: isUserUpdating
        }
      )
    ] })
  ] });
};
