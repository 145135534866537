"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from "@mui/material";
import { themeColors } from "@/v2/styles/colors.styles";
const sizeMapping = {
  xxs: 1,
  xs: 4,
  sm: 6,
  md: 8,
  lg: 12,
  xl: 16
};
const yMarginMapping = {
  xxs: 1,
  xs: 2,
  sm: 4,
  md: 8,
  lg: 16,
  xl: 20
};
export const LoadingSpinner = ({ size = "lg", yMargin = "lg" }) => /* @__PURE__ */ jsx(
  Box,
  {
    sx: {
      mt: yMarginMapping[yMargin],
      color: themeColors.ZeltYellow,
      display: "flex",
      placeContent: "center"
    },
    children: /* @__PURE__ */ jsx(
      CircularProgress,
      {
        sx: {
          height: sizeMapping[size],
          width: sizeMapping[size],
          color: themeColors.ZeltYellow
        }
      }
    )
  }
);
export const Loader = ({
  loading,
  children,
  size = "lg",
  yMargin = "lg",
  sx = {},
  hideFooter
}) => /* @__PURE__ */ jsxs(
  Box,
  {
    sx: __spreadValues({
      minHeight: hideFooter ? "100vh" : "auto",
      height: hideFooter ? "inherit" : "100%",
      display: "flex",
      flexFlow: "column",
      flex: 1
    }, sx),
    children: [
      loading && /* @__PURE__ */ jsx(LoadingSpinner, { size, yMargin }),
      !loading && children,
      !hideFooter && /* @__PURE__ */ jsx(Box, { sx: { flex: "0 0 40px" } }),
      " "
    ]
  }
);
export const SmallLoader = ({ loading, children, size, sx = {}, spinnerSx = {} }) => {
  return loading ? /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ mt: 2, display: "flex", placeContent: "center" }, spinnerSx), children: /* @__PURE__ */ jsx(CircularProgress, { size, sx: { color: themeColors.ZeltYellow } }) }) : /* @__PURE__ */ jsx(Box, { sx, children });
};
