"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Box } from "@mui/material";
import { Divider } from "@v2/components/divider.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  getBookedFromAllowanceFromBreakdown,
  getBookedFromBreakdown,
  getBookedFromPHAllowanceFromBreakdown,
  getPendingBookedFromAllowanceFromBreakdown
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
const WIDTH_COLUMN1 = "80%";
const WIDTH_COLUMN2 = "20%";
export const BookedAllowanceDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AllowanceDrawer.bookedAbsence") }),
        /* @__PURE__ */ jsx(
          BookedAllowanceDrawerContent,
          {
            userBalanceDetailedStats,
            absencePolicy
          }
        )
      ] })
    }
  ) });
};
const BookedDrawerField = ({ title, value, boldTitle = false }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
  /* @__PURE__ */ jsx(Box, { sx: { width: WIDTH_COLUMN1, display: "flex", flexDirection: "column" }, children: /* @__PURE__ */ jsx(Typography, { variant: boldTitle ? "title4" : "caption", children: title }) }),
  /* @__PURE__ */ jsx(Box, { sx: { width: WIDTH_COLUMN2, display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value }) })
] });
const BookedAllowanceDrawerContent = ({
  absencePolicy,
  userBalanceDetailedStats
}) => {
  const { polyglot } = usePolyglot();
  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];
  const bookedFromMainAllowance = getBookedFromAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const hasPendingUpcoming = getPendingBookedFromAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const bookedFromPHAllowance = getBookedFromPHAllowanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  const totalBooked = getBookedFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", flexDirection: "column", gap: spacing.g10, mt: spacing.m20 }, children: [
    /* @__PURE__ */ jsx(
      BookedDrawerField,
      {
        title: hasPendingUpcoming ? polyglot.t("AllowanceDrawer.bookedFromAllowanceIncPending") : polyglot.t("AllowanceDrawer.bookedFromAllowance"),
        value: bookedFromMainAllowance
      }
    ),
    /* @__PURE__ */ jsx(BookedDrawerField, { title: polyglot.t("AllowanceDrawer.bookedFromPHAllowance"), value: bookedFromPHAllowance }),
    /* @__PURE__ */ jsx(Divider, { style: { marginBottom: spacing.m10 } }),
    /* @__PURE__ */ jsx(BookedDrawerField, { title: polyglot.t("AllowanceDrawer.bookedTotal"), value: totalBooked, boldTitle: true })
  ] });
};
