"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, FormControlLabel, RadioGroup, Stack } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PaymentEntitySelect } from "@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/payment-entity-select.component";
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints
} from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { cleanPayCodeValue } from "@/v2/feature/payroll/features/payroll-global/global-payroll.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GlobalPayrollPayCodeNew = ({ payrollId, paycodes, onPaycodeSaved }) => {
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const otherPaycodes = useMemo(() => [...paycodes], [paycodes]);
  const currentCreditors = useMemo(() => paycodes.map(({ credit }) => credit), [paycodes]);
  const currentDebitors = useMemo(() => paycodes.map(({ debit }) => debit), [paycodes]);
  const createPaycode = useCallback(
    (newPaycode) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield GlobalPayrollAPI.createPaycode(payrollId, newPaycode);
      } catch (e) {
        showMessage(`Failed to create paycode. ${nestErrorMessage(e)}`, "error");
        setSaving(false);
        return;
      }
      showMessage(`Paycode created.`, "success");
      onPaycodeSaved == null ? void 0 : onPaycodeSaved();
    }),
    [onPaycodeSaved, payrollId, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      credit: "",
      debit: "",
      formula: "",
      order: paycodes.length,
      required: false,
      paycodeKind: "paycode-payment"
    },
    validationSchema: yup.object({
      code: yup.string().required("Code is required"),
      name: yup.string().required("Name is required"),
      credit: yup.string().when("paycodeKind", {
        is: (val) => val === "paycode-payment",
        then: yup.string().required("Credit party is required"),
        otherwise: yup.string().optional()
      }),
      debit: yup.string().when("paycodeKind", {
        is: (val) => val === "paycode-payment",
        then: yup.string().required("Debit party is required"),
        otherwise: yup.string().optional()
      })
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      const newPaycode = {
        code: values.code,
        name: values.name,
        domain: "earnings",
        credit: values.paycodeKind === "paycode-payment" ? values.credit : "",
        debit: values.paycodeKind === "paycode-payment" ? values.debit : "",
        formula: values.formula.trim(),
        order: values.order,
        required: values.required
      };
      yield createPaycode(newPaycode);
    })
  });
  const orders = useMemo(() => {
    const orderedCodes = otherPaycodes.map(({ name, order }) => ({ name, order })).sort((a, b) => a.order - b.order);
    orderedCodes.splice(formik.values.order, 0, { name: formik.values.name, order: formik.values.order });
    return orderedCodes.map(({ name }, n) => ({
      label: `${n}`,
      value: n,
      description: name
    }));
  }, [otherPaycodes, formik.values.order, formik.values.name]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "New pay code" }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "code",
        label: "Code",
        value: formik.values.code,
        onChange: (e) => formik.setFieldValue("code", cleanPayCodeValue(e.target.value)),
        autoFocus: true,
        disabled: saving,
        error: formik.submitCount > 0 && !!formik.errors.code,
        helperText: formik.submitCount > 0 && formik.errors.code
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: "Name",
        value: formik.values.name,
        onChange: formik.handleChange,
        disabled: saving,
        error: formik.submitCount > 0 && !!formik.errors.name,
        helperText: formik.submitCount > 0 && formik.errors.name
      }
    ),
    /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        name: "paycodeKind",
        value: formik.values.paycodeKind,
        onChange: formik.handleChange,
        sx: { flexFlow: "row" },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "paycode-payment",
              control: /* @__PURE__ */ jsx(StyledRadio, { disableRipple: true }),
              label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Payment" }),
              disabled: saving
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "paycode-value",
              control: /* @__PURE__ */ jsx(StyledRadio, { disableRipple: true }),
              label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Value" }),
              disabled: saving
            }
          )
        ]
      }
    ),
    formik.values.paycodeKind === "paycode-payment" && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(
        PaymentEntitySelect,
        {
          name: "credit",
          label: "Credit",
          values: currentCreditors,
          value: formik.values.credit,
          onChange: (value) => {
            formik.setFieldValue("credit", value);
          },
          disabled: saving,
          error: formik.submitCount > 0 && !!formik.errors.credit,
          helperText: formik.submitCount > 0 && formik.errors.credit
        }
      ) }),
      /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(
        PaymentEntitySelect,
        {
          name: "debit",
          label: "Debit",
          values: currentDebitors,
          value: formik.values.debit,
          onChange: (value) => {
            formik.setFieldValue("debit", value);
          },
          disabled: saving,
          error: formik.submitCount > 0 && !!formik.errors.debit,
          helperText: formik.submitCount > 0 && formik.errors.debit
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "formula",
        label: "Default value",
        value: formik.values.formula,
        onChange: formik.handleChange,
        multiline: true,
        minRows: 4,
        InputProps: { style: { whiteSpace: "pre", overflow: "auto" } },
        disabled: saving
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "order",
        label: "Payrun display order",
        options: orders,
        value: formik.values.order,
        onChange: formik.handleChange,
        disabled: saving
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "required",
        label: "Required",
        checked: formik.values.required,
        onChange: formik.handleChange,
        disabled: saving
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        type: "submit",
        fullWidth: true,
        loading: saving,
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: spacing.m20 }
      }
    ) })
  ] }) });
};
export const GlobalPayrollPayCodeNewDrawer = ({ payrollId, onPaycodeSaved }) => {
  const { data: payrollPaycodes } = useApiClient(GlobalPayrollEndpoints.getPayrollPaycodes(payrollId));
  const paycodes = useMemo(() => {
    var _a;
    return (_a = payrollPaycodes == null ? void 0 : payrollPaycodes.paycodes) != null ? _a : [];
  }, [payrollPaycodes == null ? void 0 : payrollPaycodes.paycodes]);
  return paycodes ? /* @__PURE__ */ jsx(GlobalPayrollPayCodeNew, { payrollId, paycodes, onPaycodeSaved }) : null;
};
