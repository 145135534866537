"use strict";
export var ScopeActionEnum = /* @__PURE__ */ ((ScopeActionEnum2) => {
  ScopeActionEnum2["ReadWrite"] = "";
  ScopeActionEnum2["ReadOnly"] = ":read";
  ScopeActionEnum2["WriteOnly"] = ":write";
  return ScopeActionEnum2;
})(ScopeActionEnum || {});
export var ScopeFilterEnum = /* @__PURE__ */ ((ScopeFilterEnum2) => {
  ScopeFilterEnum2["Owner"] = "";
  ScopeFilterEnum2["Manager"] = ":manager";
  ScopeFilterEnum2["All"] = ":all";
  return ScopeFilterEnum2;
})(ScopeFilterEnum || {});
