"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ColumnEditSection } from "@v2/feature/reports/reports-advanced/components/column-edit.section";
import { ColumnsFiltersSwitch } from "@v2/feature/reports/reports-advanced/components/columns-filters-switch.component";
import { FilterEditSection } from "@v2/feature/reports/reports-advanced/components/filter-edit.section";
import { ReportColumnsSelection } from "@v2/feature/reports/reports-advanced/components/report-columns-selection.component";
import { ReportColumns } from "@v2/feature/reports/reports-advanced/components/report-columns.component";
import { ReportConfigBackButton } from "@v2/feature/reports/reports-advanced/components/report-config-back-button.component";
import { ReportFiltersSelection } from "@v2/feature/reports/reports-advanced/components/report-filters-selection.component";
import { ReportFilters } from "@v2/feature/reports/reports-advanced/components/report-filters.component";
import { ReportSettingsSection } from "@v2/feature/reports/reports-advanced/components/report-settings.section";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { borders } from "@v2/styles/borders.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE, REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE } from "@/lib/routes";
export const ReportConfigSection = ({
  selectedColumns,
  setSelectedColumns,
  selectedFilters,
  setSelectedFilters,
  effectiveDate,
  setEffectiveDate,
  reportColumns,
  reportFilters,
  target
}) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [view, setView] = useState("columns-list");
  const [columnToEdit, setColumnToEdit] = useState(null);
  const [filterToEdit, setFilterToEdit] = useState(null);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({}, drawerContentSx), {
        width: "264px",
        borderRight: borders.background,
        px: "20px"
      }),
      children: [
        ["columns-list", "filters-list", "settings"].includes(view) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
          /* @__PURE__ */ jsx(
            ReportConfigBackButton,
            {
              title: polyglot.t("getSidebarConfig.reportsV2"),
              goBack: () => {
                if (target === "all") history.push(REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE);
                else history.push(REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE);
              }
            }
          ),
          /* @__PURE__ */ jsx(ColumnsFiltersSwitch, { view, setView })
        ] }),
        view === "columns-list" ? (
          // COLUMNS sTART
          /* @__PURE__ */ jsx(
            ReportColumns,
            {
              openAddColumns: () => {
                setView("columns-selection");
              },
              selectedColumns,
              setSelectedColumns,
              editColumn: (stub, col, type, order) => {
                setColumnToEdit({ stub, col, type, order });
                setView("column-edit");
              },
              reportColumns
            }
          )
        ) : view === "columns-selection" ? /* @__PURE__ */ jsx(
          ReportColumnsSelection,
          {
            goBack: () => {
              setView("columns-list");
            },
            selectedColumns,
            setSelectedColumns,
            reportColumns
          }
        ) : view === "column-edit" ? /* @__PURE__ */ jsx(
          ColumnEditSection,
          {
            goBack: () => {
              setView("columns-list");
              setColumnToEdit(null);
            },
            selectedColumns,
            setSelectedColumns,
            reportColumns,
            column: columnToEdit
          }
        ) : (
          // COLUMNS END
          //   FILTERS START
          view === "filters-list" ? /* @__PURE__ */ jsx(
            ReportFilters,
            {
              openAddFilters: () => {
                setView("filters-selection");
              },
              selectedFilters,
              setSelectedFilters,
              editFilter: (selectedFilter) => {
                setFilterToEdit(selectedFilter);
                setView("filter-edit");
              },
              reportFilters
            }
          ) : view === "filters-selection" ? /* @__PURE__ */ jsx(
            ReportFiltersSelection,
            {
              goBack: () => {
                setView("filters-list");
              },
              goToEdit: () => {
                setView("filter-edit");
              },
              reportFilters,
              setFilterToEdit
            }
          ) : view === "filter-edit" ? /* @__PURE__ */ jsx(
            FilterEditSection,
            {
              setSelectedFilters,
              goBack: () => {
                setView("filters-list");
                setFilterToEdit(null);
              },
              reportFilters,
              filter: filterToEdit
            }
          ) : (
            // FILTERS END
            view === "settings" ? /* @__PURE__ */ jsx(ReportSettingsSection, { effectiveDate, setEffectiveDate }) : null
          )
        )
      ]
    }
  );
};
