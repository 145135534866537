"use strict";
export const CATEGORIES_FOR_PRIVATE_DOCUMENTS = /* @__PURE__ */ new Set(["Personal Documents", "Tax Documents", "Other"]);
export var DocumentStatus = /* @__PURE__ */ ((DocumentStatus2) => {
  DocumentStatus2["Pending"] = "Pending";
  DocumentStatus2["Uploaded"] = "Uploaded";
  DocumentStatus2["Signed"] = "Signed";
  DocumentStatus2["MissingInformation"] = "Missing info";
  DocumentStatus2["expiringSoon"] = "Expiring soon";
  DocumentStatus2["expired"] = "Expired";
  DocumentStatus2["All"] = "All";
  return DocumentStatus2;
})(DocumentStatus || {});
export const getDocumentTypeAvailableToOptions = (polyglot) => {
  return [
    { value: "company", label: polyglot.t("getDocumentTypeAvailableToOptions.company") },
    { value: "personal", label: polyglot.t("getDocumentTypeAvailableToOptions.personal") }
  ];
};
export const PREVIEWABLE_FILE_FORMATS = [".pdf", ".png", ".jpg", ".jpeg"];
export var DocumentUploadStatus = /* @__PURE__ */ ((DocumentUploadStatus2) => {
  DocumentUploadStatus2["PENDING"] = "pending";
  DocumentUploadStatus2["MAPPED"] = "mapped";
  DocumentUploadStatus2["PROCESSING"] = "processing";
  DocumentUploadStatus2["UPLOADED"] = "uploaded";
  DocumentUploadStatus2["FAILED"] = "failed";
  return DocumentUploadStatus2;
})(DocumentUploadStatus || {});
export var BulkUploadDocumentActionsMode = /* @__PURE__ */ ((BulkUploadDocumentActionsMode2) => {
  BulkUploadDocumentActionsMode2["DELETE"] = "delete";
  BulkUploadDocumentActionsMode2["ASSIGN_OWNER"] = "assignOwner";
  BulkUploadDocumentActionsMode2["ASSIGN_TYPE"] = "assignType";
  return BulkUploadDocumentActionsMode2;
})(BulkUploadDocumentActionsMode || {});
export var BulkActionModeLabelMap = /* @__PURE__ */ ((BulkActionModeLabelMap2) => {
  BulkActionModeLabelMap2["delete"] = "Delete";
  BulkActionModeLabelMap2["assignOwner"] = "Assign owner";
  BulkActionModeLabelMap2["assignType"] = "Assign type";
  return BulkActionModeLabelMap2;
})(BulkActionModeLabelMap || {});
