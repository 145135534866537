"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { IconButton } from "@v2/components/forms/icon-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DocumentAPI } from "@v2/feature/documents/document.api";
import { DocPreviewer } from "@v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Document.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { nestErrorMessage } from "@/lib/errors";
export const AttachmentInDrawer = ({
  entityWithAttachment,
  fileLabel,
  customHandlePreview,
  customDownload
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [documentAttachment, setDocumentAttachment] = useState(void 0);
  const [attachmentBuffer, setAttachmentBuffer] = useState();
  const [selectedAttachmentName, setSelectedAttachmentName] = useState("");
  const [selectedDocContentType, setSelectedDocContentType] = useState("");
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  useEffect(() => {
    const fetchAttachment = () => __async(void 0, null, function* () {
      try {
        const receiptData = (entityWithAttachment == null ? void 0 : entityWithAttachment.attachment) ? yield DocumentAPI.getDocumentAttachmentByUuid(entityWithAttachment.attachment) : void 0;
        setDocumentAttachment(receiptData);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    });
    fetchAttachment();
  }, [showMessage, polyglot, entityWithAttachment == null ? void 0 : entityWithAttachment.attachment]);
  const handleDownloadClick = useCallback(() => __async(void 0, null, function* () {
    try {
      if (customDownload) {
        yield customDownload();
        return;
      }
      if (!entityWithAttachment || !entityWithAttachment.attachment || !documentAttachment) {
        console.error("Invalid receipt attachment");
        return;
      }
      const fileBlob = yield DocumentAPI.downloadBlobViaUuid(entityWithAttachment.attachment);
      const fileURL = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = documentAttachment.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error("::Download error", e);
      showMessage(`Failed to download attachment. ${nestErrorMessage(e)}`, "error");
    }
  }), [customDownload, entityWithAttachment, documentAttachment, showMessage]);
  const handlePreviewClick = useCallback(() => __async(void 0, null, function* () {
    var _a;
    try {
      if (!entityWithAttachment || !entityWithAttachment.attachment || ((_a = entityWithAttachment.attachment) == null ? void 0 : _a.length) === 0) {
        console.error("Invalid receipt attachment");
        return;
      }
      yield DocumentAPI.previewViaUuid(entityWithAttachment.attachment).then(
        (_0) => __async(void 0, [_0], function* ({ contentType, file }) {
          setSelectedAttachmentName("");
          setAttachmentBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        })
      );
    } catch (e) {
      console.error("::URL Download error", e);
      showMessage(`Failed to preview attachment: ${nestErrorMessage(e)}`, "error");
    }
  }), [entityWithAttachment, showMessage]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: fileLabel != null ? fileLabel : polyglot.t("DocumentModule.attachment") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", width: 1 }, children: /* @__PURE__ */ jsx(Typography, { variant: documentAttachment ? "title4" : "caption", children: documentAttachment ? documentAttachment == null ? void 0 : documentAttachment.filename : "No attachment" }) }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap5 }, children: [
        documentAttachment && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => handleDownloadClick(), children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize)) }),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sizeVariant: "small",
            colorVariant: "secondary",
            onClick: () => __async(void 0, null, function* () {
              return customHandlePreview ? customHandlePreview() : yield handlePreviewClick();
            }),
            children: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize))
          }
        )
      ] })
    ] }),
    openPreviewModal && /* @__PURE__ */ jsx(
      DocPreviewer,
      {
        fileBuffer: attachmentBuffer,
        contentType: selectedDocContentType,
        visible: true,
        onClose: () => setOpenPreviewModal(false),
        title: selectedAttachmentName
      }
    )
  ] });
};
