"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { IconButton } from "@v2/components/forms/icon-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { nestErrorMessage } from "@/lib/errors";
import { APP_STORE_ROUTE } from "@/lib/routes";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortNumeric } from "@/v2/components/table/table-sorting.util";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  PaymentSettingsNewTypeDrawer
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component";
import {
  SettingsPageSection,
  DescriptionLine
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import {
  SyncToExternalOptionsEnum
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import {
  PaymentTypeSettingsAPI,
  PaymentTypeSettingsEndpoints
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
const ACCOUNTING_FINANCE_CATEGORY = "Accounting and Finance";
export const PaymentTypeAccountingSettingsPage = ({ paymentTypeSetting, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { data: isAccountingAppConfigured } = useApiClient(PaymentTypeSettingsEndpoints.accountingAppConfigured(), {
    suspense: false
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const { data: payrollId } = useApiClient(PayrollEndpoints.getCompanyCurrentPayrollId(), {
    suspense: false
  });
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editMode, setEditMode] = useState(void 0);
  const [codeToDelete, setCodeToDelete] = useState(0);
  const [typeForEdit, setTypeForEdit] = useState(void 0);
  const [codeConfigIndexToEdit, setCodeConfigIndexToEdit] = useState(void 0);
  const { payrolled, accountingCodeConfig, paycode, syncToExternal, externalSyncOrganisation } = paymentTypeSetting;
  const handleAccountingEdit = useCallback(
    (code) => {
      setIsEditOpen(true);
      setTypeForEdit(paymentTypeSetting);
      const indexToEdit = accountingCodeConfig ? accountingCodeConfig.findIndex((item) => item.accountingCode === code) : void 0;
      setCodeConfigIndexToEdit(indexToEdit);
      setEditMode("accounting");
    },
    [accountingCodeConfig, paymentTypeSetting]
  );
  const handleAccountingInsert = useCallback(() => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setCodeConfigIndexToEdit(void 0);
    setEditMode("accounting");
  }, [paymentTypeSetting]);
  const handleDeleteAccountingCode = useCallback(() => __async(void 0, null, function* () {
    try {
      yield PaymentTypeSettingsAPI.deleteAccountingCodeFromPaymentTypeSetting(paymentTypeSetting.id, codeToDelete);
      showMessage("Successfully removed accounting code from type", "success");
      setAnchorEl(null);
      setCodeToDelete(0);
      refresh();
    } catch (error) {
      showMessage(`Failed to remove accounting code from type: ${nestErrorMessage(error)}`, "error");
    }
  }), [codeToDelete, paymentTypeSetting.id, refresh, showMessage]);
  const handleSyncEdit = useCallback(() => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setEditMode("sync");
  }, [paymentTypeSetting]);
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("PaymentSettingsPage.tableColumns.accountingCode"),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: "accountingCode",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.accountingCode),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.accountingCode }) })
      },
      {
        header: () => polyglot.t("PaymentSettingsPage.tableColumns.accountingCodeDescription"),
        accessorFn: (row) => row,
        id: "accountingCodeDescription",
        minWidth: 30,
        maxWidth: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => original.accountingCodeDescription ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.accountingCodeDescription }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "actions",
        maxSize: 100,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: (event) => {
                setAnchorEl(event.currentTarget);
                setCodeToDelete(original.accountingCode);
              },
              children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
            },
            "delete"
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => handleAccountingEdit(original.accountingCode),
              children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize))
            },
            "edit"
          )
        ] })
      }
    ];
  }, [handleAccountingEdit, polyglot]);
  const connectAccountingApp = useCallback(() => {
    if (!paymentTypeSetting.id) return;
    routerHistory.push({
      pathname: generatePath(APP_STORE_ROUTE),
      state: { category: ACCOUNTING_FINANCE_CATEGORY }
    });
  }, [paymentTypeSetting.id, routerHistory]);
  return /* @__PURE__ */ jsxs(
    SettingsPageSection,
    {
      title: polyglot.t("PaymentSettingsPage.accountingHeader"),
      editSection: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", gap: spacing.g5 }, children: [
        !isAccountingAppConfigured && payrollId && /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: () => connectAccountingApp(), children: polyglot.t("PayrollCompanySetup.connectAccountingTitle") }),
        /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "primary", onClick: () => handleAccountingInsert(), children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, actionIconSize)) }, "edit")
      ] }),
      children: [
        !payrolled ? /* @__PURE__ */ jsxs(Fragment, { children: [
          (accountingCodeConfig == null ? void 0 : accountingCodeConfig.length) && (accountingCodeConfig == null ? void 0 : accountingCodeConfig.length) > 0 ? /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
            BasicTable,
            {
              rowData: (_a = paymentTypeSetting == null ? void 0 : paymentTypeSetting.accountingCodeConfig) != null ? _a : [],
              columnData: columns,
              loading: false,
              hidePagination: true,
              externalTdAndThPadding: "0 0px"
            }
          ) }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mt: "8px" }, children: polyglot.t("PaymentSettingsPage.noAccountingCodes") }) }),
          isAccountingAppConfigured && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", mt: spacing.mt20 }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { color: themeColors.DarkGrey }, children: polyglot.t("PaymentSettingsPage.syncSettings") }),
              /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => handleSyncEdit(), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) }, "edit")
            ] }),
            /* @__PURE__ */ jsx(
              DescriptionLine,
              {
                field: polyglot.t("PaymentSettingsPage.organisation"),
                value: `${(externalSyncOrganisation == null ? void 0 : externalSyncOrganisation.name) ? externalSyncOrganisation.name : "No syncing organisation selected yet"}`
              }
            ),
            /* @__PURE__ */ jsx(
              DescriptionLine,
              {
                field: polyglot.t("ContractorInvoiceModal.syncMethod"),
                value: `${syncToExternal ? SyncToExternalOptionsEnum[syncToExternal] : ""}`
              }
            )
          ] })
        ] }) : /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("PaymentSettingsPage.subtitles.paycode"), value: paycode }),
        isEditOpen && /* @__PURE__ */ jsx(
          PaymentSettingsNewTypeDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            refreshIndividualSetting: refresh,
            typeForEdit,
            editMode,
            codeConfigIndex: codeConfigIndexToEdit
          }
        ),
        /* @__PURE__ */ jsx(
          NotificationModal,
          {
            isOpen: Boolean(anchorEl),
            onClose: () => setAnchorEl(null),
            anchorEl,
            takeAction: () => __async(void 0, null, function* () {
              handleDeleteAccountingCode();
            }),
            message: polyglot.t("PaymentSettingsPage.deleteCodeConfirm"),
            callToAction: polyglot.t("General.yes")
          }
        )
      ]
    }
  );
};
