"use strict";
import { AccountStatus } from "@/lib/users";
export var UserAccountTypes = /* @__PURE__ */ ((UserAccountTypes2) => {
  UserAccountTypes2["Admin"] = "Admin";
  UserAccountTypes2["ProfileOwner"] = "Profile Owner";
  UserAccountTypes2["OtherProfiles"] = "Other Profiles";
  UserAccountTypes2["Manager"] = "Manager";
  return UserAccountTypes2;
})(UserAccountTypes || {});
const INVITED_ACCOUNT_STATUSES = [AccountStatus.Invited, AccountStatus.InvitedToOnboard];
export const INVITABLE_USER_ACCOUNT_STATUS = [AccountStatus.Created, ...INVITED_ACCOUNT_STATUSES];
export const ACTIVE_INVITABLE_USER_ACCOUNT_STATUS_FOR_BILLING = [AccountStatus.Active, ...INVITED_ACCOUNT_STATUSES];
