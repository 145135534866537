"use strict";
import {
  REVIEWS_COMPANY_ONGOING_ROUTE,
  REVIEWS_ME_INVITE_ROUTE,
  REVIEWS_ME_RESULT_ROUTE,
  REVIEWS_ME_ROUTE,
  REVIEWS_TEAM_ONGOING_ROUTE
} from "@/lib/routes";
export const GROWTH_REVIEWS_ONGOING_ROUTES = {
  company: REVIEWS_COMPANY_ONGOING_ROUTE,
  team: REVIEWS_TEAM_ONGOING_ROUTE
};
export const GROWTH_REVIEWS_ME_ROUTES = {
  me: REVIEWS_ME_ROUTE
};
export const GROWTH_REVIEWS_INVITE_ROUTES = {
  me: REVIEWS_ME_INVITE_ROUTE
};
export const GROWTH_REVIEWS_INVITE_SCOPES = {
  me: ["reviews"]
};
export const GROWTH_REVIEWS_RESULT_ROUTES = {
  me: REVIEWS_ME_RESULT_ROUTE
};
export const GROWTH_REVIEWS_RESULT_SCOPES = {
  me: ["reviews"]
};
export const GROWTH_REVIEWS_ONGOING_SCOPES = {
  company: ["reviews:all"],
  team: ["reviews:manager"],
  me: ["reviews"]
};
