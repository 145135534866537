"use strict";
import { jsx } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { CompanySettingsDetailsComponent } from "@/v2/feature/company/company-settings/features/components/company-settings/company-details/company-settings-details.component";
export const CompanySettingsGeneral = ({
  generalSettings,
  refreshSettings
}) => {
  const { polyglot } = usePolyglot();
  const [editMode, setEditMode] = useState(false);
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: polyglot.t("CompanySettingsHeader.company"),
      contentWidth: "100%",
      onEdit: () => setEditMode(true),
      children: /* @__PURE__ */ jsx(
        CompanySettingsDetailsComponent,
        {
          generalSettings,
          refreshSettings,
          setEditMode,
          editMode
        }
      )
    }
  );
};
