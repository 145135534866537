"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class OffboardingAPI {
  static getOffboardingListing(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/offboarding?${searchParams.toString()}`)).data;
    });
  }
  static getOffboardingDues(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/offboarding/dues`)).data;
    });
  }
  static postUserOffboard(userId, offboard) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/${userId}/offboarding`, offboard)).data;
    });
  }
  static getOffboardingCompanyApps() {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/offboarding/apps`)).data;
    });
  }
  static getOffboardingAppsForUser(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/offboarding/apps/${userId}`)).data;
    });
  }
  static getOffboardingChangeReasons() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/offboarding/change-reasons")).data;
    });
  }
}
export class OffboardingEndpoints {
  static getOffboardingState(userId) {
    return {
      url: `/apiv2/users/${userId}/offboarding/state`
    };
  }
}
