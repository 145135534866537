"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/system";
import { capitalize } from "lodash";
import { ReactComponent as Document } from "@/images/documents/document.svg";
import { ReactComponent as Image } from "@/images/documents/image.svg";
import { ReactComponent as OtherFormat } from "@/images/documents/other_format.svg";
import { ReactComponent as PDF } from "@/images/documents/pdf.svg";
import { ReactComponent as Spreadsheet } from "@/images/documents/spreadsheet.svg";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { CustomRuleOptions, UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import {
  AdminDocumentView
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import {
  ADDITIONAL_SIGNATORY_PLACEHOLDER,
  EMPLOYEE_SIGNATORY_PLACEHOLDER,
  OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER,
  SignatoryType,
  WITNESS_DETAILS_PLACEHOLDER
} from "@/v2/feature/templates/templates.interface";
export const getCustomRuleString = (customRule, sitesForCompany, departmentsForCompany, entitiesForCompany) => {
  const ruleTypeArray = customRule ? customRule.split("=") : [];
  if (ruleTypeArray.length === 0 && !sitesForCompany && !departmentsForCompany && !entitiesForCompany) return;
  const ruleType = ruleTypeArray[0];
  const ids = ruleTypeArray[1] ? ruleTypeArray[1].split(",").map(Number) : [];
  switch (ruleType) {
    case CustomRuleOptions.Site:
      if (sitesForCompany) {
        const siteNames = ids.map((id) => {
          var _a;
          return (_a = sitesForCompany.find((site) => site.id === id)) == null ? void 0 : _a.name;
        }).filter(Boolean).join(", ");
        return siteNames ? `Site: ${siteNames}` : "sites";
      }
      return "sites";
    case CustomRuleOptions.Department:
      if (departmentsForCompany) {
        const departmentNames = ids.map((id) => {
          var _a;
          return (_a = departmentsForCompany.find((department) => department.id === id)) == null ? void 0 : _a.name;
        }).filter(Boolean).join(", ");
        return departmentNames ? `Department: ${departmentNames}` : "department";
      }
      return "department";
    case CustomRuleOptions.Entity:
      if (entitiesForCompany) {
        const entityNames = ids.map((id) => {
          var _a;
          return (_a = entitiesForCompany.find((entity) => entity.id === id)) == null ? void 0 : _a.legalName;
        }).filter(Boolean).join(", ");
        return entityNames ? `Entity: ${entityNames}` : "entity";
      }
      return "entity";
    default:
      return "";
  }
};
export const getBelongsToByMemberRule = (document, nameVariant = "caption", ruleString) => {
  if (document.userId)
    return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UserCell, { userId: document.userId, nameVariant }) });
  if (document.membersRule === UserSelectFiltersOptions.Everyone) return capitalize(UserSelectFiltersOptions.Everyone);
  if (document.membersRule === UserSelectFiltersOptions.CustomRule && document.customRule)
    return ruleString != null ? ruleString : "Custom rule";
  else return "Unassigned";
};
export const newDocViewTypeOptions = [
  {
    value: AdminDocumentView.Company,
    label: AdminDocumentView.Company,
    description: "Document for the entire company or a group of people"
  },
  {
    value: AdminDocumentView.Personal,
    label: AdminDocumentView.Personal,
    description: "Document for a single employee"
  }
];
export function signatoriesRequiredForContract(templateText) {
  const signatories = [];
  if (templateText.includes(EMPLOYEE_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.recipient);
  if (templateText.includes(OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.additional);
  if (templateText.includes(ADDITIONAL_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.additional);
  if (templateText.includes(WITNESS_DETAILS_PLACEHOLDER)) signatories.push(SignatoryType.witness);
  return signatories;
}
export const companySignatureRequired = (row, signatoriesRequired) => {
  var _a;
  return Boolean(
    signatoriesRequired && (signatoriesRequired == null ? void 0 : signatoriesRequired.includes(SignatoryType.additional)) && ((_a = row.contract) == null ? void 0 : _a.companySignatory)
  );
};
export const recipientSignatureRequired = (row, signatoriesRequired) => {
  var _a;
  return Boolean(
    signatoriesRequired && (signatoriesRequired == null ? void 0 : signatoriesRequired.includes(SignatoryType.recipient)) && ((_a = row.contract) == null ? void 0 : _a.recipient)
  );
};
export function getAllPartiesHaveSigned(document, signatoriesRequired) {
  const { contractId, contract } = document;
  const companySignatureExpected = companySignatureRequired(document, signatoriesRequired);
  const recipientSignatureExpected = recipientSignatureRequired(document, signatoriesRequired);
  return Boolean(
    !!contractId && !!contract && (!companySignatureExpected && !recipientSignatureExpected || !Array.isArray(signatoriesRequired) || signatoriesRequired.length === 0 || companySignatureExpected && !!contract.companySignatureTimestamp && recipientSignatureExpected && !!contract.recipientSignatureTimestamp)
  );
}
export const getFileName = (original) => {
  var _a, _b;
  if (!original.fileName) return "Uploaded document";
  const fileName = original.fileName;
  const extension = (_b = (_a = fileName == null ? void 0 : fileName.split("/")) == null ? void 0 : _a.pop()) == null ? void 0 : _b.toLowerCase();
  return extension != null ? extension : "Uploaded document";
};
export const getImageByFileName = (original) => {
  var _a, _b;
  if (!original.fileName) return /* @__PURE__ */ jsx(OtherFormat, { width: 16, height: 16 });
  const fileName = original.fileName;
  const extension = (_b = (_a = fileName == null ? void 0 : fileName.split(".")) == null ? void 0 : _a.pop()) == null ? void 0 : _b.toLowerCase();
  switch (extension) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "svg":
      return /* @__PURE__ */ jsx(Image, { width: 16, height: 16 });
    case "pdf":
      return /* @__PURE__ */ jsx(PDF, { width: 16, height: 16 });
    case "doc":
    case "docx":
      return /* @__PURE__ */ jsx(Document, { width: 16, height: 16 });
    case "xls":
    case "xlsx":
    case "csv":
      return /* @__PURE__ */ jsx(Spreadsheet, { width: 16, height: 16 });
    default:
      return /* @__PURE__ */ jsx(OtherFormat, { width: 16, height: 16 });
  }
};
