"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { BenefitsCompanyRouter } from "@v2/feature/benefits/benefits-company.router";
import { BenefitsMeRouter } from "@v2/feature/benefits/benefits-me.router";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { PensionAPI as PensionAPIV2 } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { RootStyle } from "@v2/styles/root.styles";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BENEFITS_COMPANY_ROUTE, BENEFITS_ME_ROUTE } from "@/lib/routes";
import { CustomBenefitAPI } from "@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
export const BenefitsRouter = () => {
  const [state] = useContext(GlobalContext);
  const userId = state.user.userId;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [showMessage] = useMessage();
  const location = useLocation();
  const { companyConfig, isLoading } = useCompanyConfigState();
  const hasPensionAllScope = hasScopes(["pension:all"], scopesContext);
  const hasInsuranceAllScope = hasScopes(["insurance:all"], scopesContext);
  const hasInsuranceScope = hasScopes(["insurance"], scopesContext);
  const [loading, setLoading] = useState(true);
  const [pensionSchemes, setPensionSchemes] = useState([]);
  const [insuranceQuote, setInsuranceQuote] = useState(null);
  const [customBenefits, setCustomBenefits] = useState([]);
  const [benefitsLoading, setBenefitsLoading] = useState(false);
  const [insuranceLoading, setInsuranceLoading] = useState(false);
  const [pensionLoading, setPensionLoading] = useState(false);
  const refreshPensionSchemes = useCallback(() => __async(void 0, null, function* () {
    if (!hasPensionAllScope || !(companyConfig == null ? void 0 : companyConfig.inPension)) return;
    try {
      setPensionLoading(true);
      const pensionSchemes2 = yield PensionAPIV2.getCompanyPensionSchemes();
      setPensionSchemes(pensionSchemes2);
    } catch (err) {
      showMessage(`Could not get company pension providers. ${nestErrorMessage(err)}`, "error");
    }
    setPensionLoading(false);
  }), [companyConfig, hasPensionAllScope, showMessage]);
  const refreshInsuranceQuote = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceScope) return;
    try {
      setInsuranceLoading(true);
      const insuranceQuote2 = yield InsuranceAPI.getInsuranceQuote();
      setInsuranceQuote(insuranceQuote2);
    } catch (err) {
      showMessage(`Could not get insurance quote. ${nestErrorMessage(err)}`, "error");
    }
    setInsuranceLoading(false);
  }), [hasInsuranceScope, showMessage]);
  const refreshCustomBenefits = useCallback(() => __async(void 0, null, function* () {
    if (!hasInsuranceAllScope) return;
    try {
      setBenefitsLoading(true);
      const benefits = yield CustomBenefitAPI.getAllCustomBenefits();
      setCustomBenefits(benefits);
    } catch (err) {
      showMessage(`Could not get custom benefit. ${nestErrorMessage(err)}`, "error");
    } finally {
      setBenefitsLoading(false);
    }
  }), [hasInsuranceAllScope, showMessage]);
  const refreshAll = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    yield Promise.all([refreshPensionSchemes(), refreshInsuranceQuote(), refreshCustomBenefits()]);
    setLoading(false);
  }), [refreshPensionSchemes, refreshInsuranceQuote, refreshCustomBenefits]);
  useEffect(() => {
    refreshAll();
  }, [refreshAll]);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance:all", "pension:all"],
        context: scopesContext,
        path: BENEFITS_COMPANY_ROUTE,
        children: /* @__PURE__ */ jsx(
          BenefitsCompanyRouter,
          {
            pensionSchemes,
            customBenefits,
            insuranceQuote,
            refreshInsuranceQuote,
            refreshAllBenefits: refreshAll,
            companyConfig,
            loading: loading || pensionLoading || benefitsLoading || insuranceLoading || isLoading
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance", "pension"], context: scopesContext, path: BENEFITS_ME_ROUTE, children: /* @__PURE__ */ jsx(BenefitsMeRouter, { insuranceQuote, loading: loading || insuranceLoading }) }),
    (hasInsuranceAllScope || hasPensionAllScope) && location.pathname.startsWith(BENEFITS_COMPANY_ROUTE) && /* @__PURE__ */ jsx(Redirect, { to: BENEFITS_COMPANY_ROUTE }),
    /* @__PURE__ */ jsx(Redirect, { to: BENEFITS_ME_ROUTE })
  ] }) });
};
