"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  OffboardingStepStatus
} from "@v2/feature/offboarding/offboarding-process/offboarding-process.page";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
export const OffboardingProgressionBar = ({
  state,
  showProgress = false,
  showPendingTasks = false
}) => {
  const { polyglot } = usePolyglot();
  const progress = useMemo(() => {
    const UNIT = 20;
    function getStatePercentage(domainStatus) {
      const domainValue = domainStatus === OffboardingStepStatus.COMPLETE ? UNIT : domainStatus === OffboardingStepStatus.PENDING ? UNIT / 2 : 0;
      return { domainValue, domainMax: UNIT };
    }
    let currentValue = UNIT;
    let maxValue = currentValue;
    const domainStatuses = [
      state.tasks,
      state.absence,
      state.apps,
      state.devices,
      state.managerChange
    ];
    for (const domainStatus of domainStatuses) {
      const { domainValue, domainMax } = getStatePercentage(domainStatus);
      currentValue += domainValue;
      maxValue += domainMax;
    }
    const percentage = currentValue / maxValue * 100;
    return Math.ceil(percentage / 5) * 5;
  }, [state]);
  const noOfPendingTasks = useMemo(() => {
    let count = 0;
    if (state.absence !== OffboardingStepStatus.COMPLETE) count++;
    if (state.apps !== OffboardingStepStatus.COMPLETE) count++;
    if (state.devices !== OffboardingStepStatus.COMPLETE) count++;
    if (state.tasks !== OffboardingStepStatus.COMPLETE) count++;
    if (state.managerChange !== OffboardingStepStatus.COMPLETE) count++;
    return count;
  }, [state]);
  const leavingInNoOfDays = useMemo(() => {
    if (!state.leaveDate) return null;
    const today = new LocalDate(null, { h: 0, min: 0, s: 0, ms: 0 });
    const leaveDate = new LocalDate(state.leaveDate);
    const diffInMs = leaveDate.getDate().getTime() - today.getDate().getTime();
    return Math.round(diffInMs / (1e3 * 60 * 60 * 24));
  }, [state]);
  const leavingTitle = useMemo(() => {
    if (leavingInNoOfDays === null) return "";
    if (leavingInNoOfDays > 0) return polyglot.t("OffboardingProgressionBar.leavingIn");
    if (leavingInNoOfDays === 0) return polyglot.t("OffboardingProgressionBar.leaving");
    return polyglot.t("OffboardingProgressionBar.hasLeft");
  }, [leavingInNoOfDays, polyglot]);
  const leavingDaysMsg = useMemo(() => {
    if (leavingInNoOfDays === null) return "";
    if (leavingInNoOfDays > 0) return polyglot.t("General.noOfDays", { smart_count: leavingInNoOfDays });
    if (leavingInNoOfDays === 0) return polyglot.t("OffboardingProgressionBar.today");
    return polyglot.t("General.noOfDaysAgo", { smart_count: Math.abs(leavingInNoOfDays) });
  }, [polyglot, leavingInNoOfDays]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s4 }, children: [
    showProgress && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingProgressionBar.progress") }),
      /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
        progress,
        "%"
      ] })
    ] }),
    showPendingTasks && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("OffboardingProgressionBar.pending") }),
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OffboardingProgressionBar.noOfTasks", { smart_count: noOfPendingTasks }) })
    ] }),
    leavingInNoOfDays !== null && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: leavingTitle }),
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: leavingDaysMsg })
    ] })
  ] });
};
