"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { JobPositionEndpoints } from "@v2/feature/job-position/job-position.api";
import { MissingFieldsDetail } from "@v2/feature/templates/components/missing-template-field-modal.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { RoleForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const MissingRole = ({ userId, refreshData, missingFieldsForCategory }) => {
  const { polyglot } = usePolyglot();
  const { data: deptsData, mutate: refreshDept, isLoading: loadingDept } = useApiClient(
    DepartmentEndpoints.getCompanyDepartments(),
    {
      suspense: false
    }
  );
  const departments = useMemo(() => {
    var _a;
    return (_a = deptsData == null ? void 0 : deptsData.map((dept) => ({ label: dept.name, value: dept.id }))) != null ? _a : [];
  }, [deptsData]);
  const { data: siteData, mutate: refreshSites, isLoading: loadingSites } = useApiClient(SiteEndpoints.getSites(), {
    suspense: false
  });
  const sites = useMemo(() => {
    var _a;
    return (_a = siteData == null ? void 0 : siteData.map((site) => ({ label: site.name, value: site.id }))) != null ? _a : [];
  }, [siteData]);
  const { data: jobPositionData, mutate: refreshJobPosition, isLoading: loadingJobPosition } = useApiClient(
    JobPositionEndpoints.listJobPositions(),
    {
      suspense: false
    }
  );
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const managers = useMemo(() => {
    var _a;
    return (_a = nonTerminatedCachedUsers.filter((u) => u.userId !== userId)) == null ? void 0 : _a.map((rec) => {
      return __spreadValues({ label: rec.displayName, value: rec.userId }, rec);
    });
  }, [nonTerminatedCachedUsers, userId]);
  const jobPositions = useMemo(() => {
    if (!jobPositionData) return [];
    return jobPositionData ? jobPositionData == null ? void 0 : jobPositionData.map((eachJobPosition) => {
      return {
        label: eachJobPosition.title,
        value: eachJobPosition.id
      };
    }) : [];
  }, [jobPositionData]);
  const nextPossibleId = useMemo(() => {
    var _a;
    if (!jobPositionData) return "";
    const lastJobPosition = jobPositionData == null ? void 0 : jobPositionData.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = (_a = lastJobPosition[jobPositionData.length - 1]) == null ? void 0 : _a.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1e6).toString();
    }
  }, [jobPositionData]);
  const defaultJobPosition = useMemo(() => {
    return {
      id: void 0,
      internalCode: nextPossibleId,
      title: "",
      description: ""
    };
  }, [nextPossibleId]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "column" }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("MissingRole.roleInfo") }),
        /* @__PURE__ */ jsx(IconButton, { onClick: () => setIsModalOpen(true), sx: __spreadProps(__spreadValues({}, secondaryCTABtn), { p: "7px", ml: "auto" }), children: /* @__PURE__ */ jsx(Plus, { width: "14px", height: "14px" }) })
      ] }),
      missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, closeIcon: "back", children: refreshData && !loadingDept && !loadingJobPosition && !loadingSites ? /* @__PURE__ */ jsx(
      RoleForm,
      {
        userId,
        departments,
        managers,
        sites,
        refreshSites,
        refreshDept,
        jobPositions,
        initialValues: null,
        onSubmit: () => refreshData == null ? void 0 : refreshData(),
        onClose: () => setIsModalOpen(false),
        onRefresh: () => refreshData == null ? void 0 : refreshData(),
        refreshJobPosition,
        defaultJobPosition
      }
    ) : /* @__PURE__ */ jsx(LoadingSpinner, {}) })
  ] });
};
