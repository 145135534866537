"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Document } from "@/images/dashboard-icons/Document.svg";
import { DocumentActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/document-action-item.component";
import { TodoCard } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const MissingDocumentsTodo = ({
  todo,
  setLastChild,
  userId,
  refreshTodos
}) => {
  const { polyglot } = usePolyglot();
  const [selectedRow, setSelectedRow] = useState(void 0);
  const getTitle = (doc) => {
    return polyglot.t("MissingDocumentsTodo.missingDocs", { docName: doc.name });
  };
  return /* @__PURE__ */ jsxs(Box, { children: [
    todo.documents.map((doc) => /* @__PURE__ */ jsx(
      TodoCard,
      {
        icon: /* @__PURE__ */ jsx(Document, __spreadValues({}, iconSize)),
        title: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "caption",
            sx: {
              display: "inline-block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            },
            children: getTitle(doc)
          }
        ),
        actionOnclick: () => setSelectedRow(doc),
        showBorder: !setLastChild,
        userAvatar: userId ? /* @__PURE__ */ jsx(UserAvatar, { userId, size: "xxxsmall" }) : /* @__PURE__ */ jsx(Fragment, {})
      },
      "missing-docs"
    )),
    selectedRow && /* @__PURE__ */ jsx(
      DocumentActionItem,
      {
        documentActionRow: selectedRow,
        refresh: () => __async(void 0, null, function* () {
          yield refreshTodos == null ? void 0 : refreshTodos();
        }),
        afterClose: () => setSelectedRow(void 0)
      }
    )
  ] });
};
