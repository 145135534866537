"use strict";
import he from "he";
export const isValidEmailString = (email) => {
  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return new RegExp(emailRegex).test(email);
};
export const equalsIgnoreCase = (str1, str2) => {
  if (!str1 || !str2) {
    return false;
  }
  return str1.toLocaleLowerCase() === str2.toLocaleLowerCase();
};
export const isValidCommaSeparatedEmail = (commaSeparatedString) => {
  const emails = commaSeparatedString.split(",").map((s) => s.trim());
  return emails.every((email) => isValidEmailString(email));
};
export const toTitleCase = (inputText) => {
  if (!inputText) return "";
  return inputText == null ? void 0 : inputText.replace(/\b([a-z])/g, (_, initial) => initial == null ? void 0 : initial.toUpperCase());
};
export const pluralText = (count) => {
  return count === 1 ? "" : "s";
};
export const truncateWithEllipses = (inputText, limit = 12) => {
  return inputText.length > limit ? `${inputText.substring(0, limit)}...` : inputText;
};
export const arrayBufferToString = (buffer, encoding = "UTF-8") => {
  return new Promise((resolve, reject) => {
    const blob = new Blob([buffer], { type: "text/plain" });
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (evt.target) {
        resolve(evt.target.result);
      } else {
        reject(new Error("Could not convert array to string!"));
      }
    };
    reader.readAsText(blob, encoding);
  });
};
export const camelCaseToTitleCase = (input) => {
  const interim = input.replace(/([A-Z])/g, " $1");
  return interim.charAt(0).toUpperCase() + interim.slice(1);
};
export const getFirstAndLastNameFromNameData = (nameData) => {
  const nameArray = nameData.trim().split(/\s+/);
  const firstName = nameArray[0];
  const lastName = nameArray[nameArray.length - 1];
  return [firstName, lastName];
};
export const isDefined = (input) => {
  return input && (input == null ? void 0 : input.length) > 0;
};
export const stripHtml = (html) => {
  if (!html) return "";
  const strippedString = html.replace(/<[^>]*>/g, "");
  return he.decode(strippedString);
};
export const replaceParamsInQuestionText = (input, params) => {
  if (!input) return "";
  const regex = /{(\w+)}/g;
  return input.replace(regex, (_, key) => params[key] || `{${key}}`);
};
export function urlify(text) {
  const urlRegex = /(((https?:\/\/)|(www\.))\S+)/g;
  return text.replaceAll(urlRegex, function(url, b, c) {
    const url2 = c === "www." ? "http://" + url : url;
    return '<a href="' + url2 + '" target="_blank" style="color: #2F2F2F;">' + url + "</a>";
  });
}
export function extractPathAfterDomain(url) {
  try {
    const urlObj = new URL(url);
    return urlObj.pathname + urlObj.search + urlObj.hash;
  } catch (error) {
    console.error("Invalid URL:", error);
    return "";
  }
}
