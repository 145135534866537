"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ReportConfigBackButton } from "@v2/feature/reports/reports-advanced/components/report-config-back-button.component";
import { ReportSQLOperator } from "@v2/feature/reports/reports.interface";
import { themeColors } from "@v2/styles/colors.styles";
export const ReportFiltersSelection = ({ reportFilters, goBack, goToEdit, setFilterToEdit }) => {
  const [searchInput, setSearchInput] = useState("");
  const filteredFilters = useMemo(() => {
    var _a;
    if (!searchInput) return reportFilters != null ? reportFilters : [];
    const search = searchInput.toLowerCase();
    const filtered = [];
    for (const reportFilter of reportFilters) {
      if (reportFilter.category.toLowerCase().includes(search)) {
        filtered.push(__spreadValues({}, reportFilter));
        continue;
      }
      const filterCopy = {
        category: reportFilter.category,
        stub: reportFilter.stub,
        filters: {}
      };
      for (const key in reportFilter.filters) {
        const label = (_a = reportFilter.filters[key]) == null ? void 0 : _a.label;
        if (label && label.toLowerCase().includes(search)) {
          filterCopy.filters[key] = reportFilter.filters[key];
        }
      }
      if (Object.keys(filterCopy.filters).length > 0) filtered.push(filterCopy);
    }
    return filtered;
  }, [searchInput, reportFilters]);
  return /* @__PURE__ */ jsxs(Box, { sx: { overflowY: "auto", display: "flex", flexDirection: "column", gap: "10px", height: "auto" }, children: [
    /* @__PURE__ */ jsx(ReportConfigBackButton, { title: "Filters", goBack }),
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => {
          setSearchInput(e.target.value);
        },
        style: { minWidth: "150px", maxWidth: "230px" }
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: 1,
          overflowY: "auto",
          scrollbarWidth: "thin",
          paddingRight: "4px",
          pb: 3
        },
        children: filteredFilters.map((filterCat) => {
          return /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: 1 }, children: filterCat.category }),
            /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: "1px" }, children: Object.keys(filterCat.filters).map((key) => {
              return /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    px: 0.5,
                    cursor: "pointer",
                    ":hover": {
                      background: themeColors.Background,
                      borderRadius: "5px"
                    }
                  },
                  onClick: () => {
                    setFilterToEdit({
                      stub: filterCat.stub,
                      filterKey: key,
                      op: ReportSQLOperator.eq,
                      value: "",
                      type: "new"
                    });
                    goToEdit();
                  },
                  children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: filterCat.filters[key].label })
                },
                `${filterCat.stub}-${key}`
              );
            }) })
          ] }, filterCat.stub);
        })
      }
    )
  ] });
};
