"use strict";
export var DocumentFileOperation = /* @__PURE__ */ ((DocumentFileOperation2) => {
  DocumentFileOperation2["download"] = "download";
  DocumentFileOperation2["audit"] = "audit";
  DocumentFileOperation2["preview"] = "preview";
  return DocumentFileOperation2;
})(DocumentFileOperation || {});
export var DocumentNewStatus = /* @__PURE__ */ ((DocumentNewStatus2) => {
  DocumentNewStatus2["Pending"] = "pending";
  DocumentNewStatus2["Ready"] = "ready";
  DocumentNewStatus2["Expired"] = "expired";
  return DocumentNewStatus2;
})(DocumentNewStatus || {});
export var ActionView = /* @__PURE__ */ ((ActionView2) => {
  ActionView2["Table"] = "table";
  ActionView2["Modal"] = "modal";
  return ActionView2;
})(ActionView || {});
export var FormTypeEnum = /* @__PURE__ */ ((FormTypeEnum2) => {
  FormTypeEnum2["upload"] = "upload";
  FormTypeEnum2["request"] = "request";
  return FormTypeEnum2;
})(FormTypeEnum || {});
export var AdminDocumentView = /* @__PURE__ */ ((AdminDocumentView2) => {
  AdminDocumentView2["Pinned"] = "Pinned";
  AdminDocumentView2["Company"] = "Company";
  AdminDocumentView2["Personal"] = "Personal";
  return AdminDocumentView2;
})(AdminDocumentView || {});
export const AdminDocumentViewArray = Object.values(AdminDocumentView);
export var ManagerDocumentView = /* @__PURE__ */ ((ManagerDocumentView2) => {
  ManagerDocumentView2["Pinned"] = "Pinned";
  ManagerDocumentView2["Personal"] = "Personal";
  return ManagerDocumentView2;
})(ManagerDocumentView || {});
export const ManagerDocumentViewArray = Object.values(ManagerDocumentView);
export var UserDocumentView = /* @__PURE__ */ ((UserDocumentView2) => {
  UserDocumentView2["Pinned"] = "Pinned";
  UserDocumentView2["Company"] = "Company";
  UserDocumentView2["Personal"] = "Personal";
  return UserDocumentView2;
})(UserDocumentView || {});
export const UserDocumentViewArray = Object.values(UserDocumentView);
