"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class UserBankAccountAPI {
  static findByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/bank-accounts`)).data;
    });
  }
  static findCurrentByUserIds(userIds) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/bank-accounts/current?userIds=${userIds.join(",")}`)).data;
    });
  }
  static findCurrentByUserId(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/bank-accounts/current`)).data || null;
    });
  }
  static update(userId, id, update) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/users/${userId}/bank-accounts/${id}`, update)).data;
    });
  }
  static create(userId, create) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/users/${userId}/bank-accounts`, create)).data;
    });
  }
  static deleteById(userId, accountId) {
    return __async(this, null, function* () {
      return axios.delete(`/apiv2/users/${userId}/bank-accounts/${accountId}`);
    });
  }
}
export class UserBankAccountEndpoints {
  static findCurrentByUserId(userId) {
    return userId ? { url: `/apiv2/users/${userId}/bank-accounts/current` } : null;
  }
}
