"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  SETTINGS_GROWTH_REVIEWS_QUESTIONS_ROUTE,
  SETTINGS_GROWTH_REVIEWS_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
  SETTINGS_GROWTH_REVIEWS_TEMPLATES_ROUTE
} from "@/lib/routes";
import { ReviewQuestionBankPage } from "@/v2/feature/growth/reviews/features/review-question-bank/review-question-bank.page";
import { ReviewTemplateOverviewPage } from "@/v2/feature/growth/reviews/features/review-template/review-template-builder/review-template-overview.page";
import { ReviewTemplateListPage } from "@/v2/feature/growth/reviews/features/review-template/review-template-list/review-template-list.page";
export const ReviewSettingsRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(
    RouteScopesControl,
    {
      context,
      scopes: ["reviews:all", "surveys:all"],
      path: SETTINGS_GROWTH_REVIEWS_ROUTE,
      children: /* @__PURE__ */ jsx(ReviewSettingRouter, { context })
    }
  ) });
};
const ReviewSettingRouter = ({ context }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["reviews:all", "surveys:all"],
        path: SETTINGS_GROWTH_REVIEWS_QUESTIONS_ROUTE,
        exact: true,
        children: /* @__PURE__ */ jsx(ReviewQuestionBankPage, {})
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["reviews:all", "surveys:all"],
        path: SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE,
        children: /* @__PURE__ */ jsx(ReviewTemplateOverviewPage, {})
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["reviews:all", "surveys:all"],
        path: SETTINGS_GROWTH_REVIEWS_TEMPLATES_ROUTE,
        exact: true,
        children: /* @__PURE__ */ jsx(ReviewTemplateListPage, {})
      }
    )
  ] });
};
