"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { PARTNER_API_AUTHORISE_ROUTE } from "@/lib/routes";
import { ConsentPage } from "@/v2/feature/public-api/consent.page";
export const PublicAPIRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["company.settings:all"], path: PARTNER_API_AUTHORISE_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(ConsentPage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { to: PARTNER_API_AUTHORISE_ROUTE })
  ] });
};
