"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SettingsPageSection,
  DescriptionLine
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { DeveloperHubClientGenerateDrawer } from "@/v2/feature/security/security-settings/components/developer-hub-client-generate-drawer.component";
import { DeveloperHubDeleteClientConfirmDrawer } from "@/v2/feature/security/security-settings/features/developer-hub/components/developer-hub-delete-client-confirm-drawer.component";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
const API_TERMS_LINK = "https://zelt.app/legal/api-terms-of-use/";
const README_LINK = "https://zelt.readme.io/";
export const DeveloperHubGeneralSettingsPage = ({ oauthApp, onUpdate, onDelete }) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    SettingsPageSection,
    {
      title: polyglot.t("General.general"),
      onEdit: () => {
        setIsEditDrawerOpen(true);
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("DeveloperHub.generalPageExplainer") }),
        /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("DeveloperHub.appName"), value: oauthApp.name }),
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("DeveloperHub.appIcon"),
            value: oauthApp.logo ? /* @__PURE__ */ jsx("img", { src: `${state.publicURL}/${oauthApp.logo}`, alt: "app-icon", width: 30 }) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("DeveloperHub.clientTable.description"), value: oauthApp.description }),
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("DeveloperHub.clientTable.redirectionUri"),
            value: oauthApp.redirectionUri && {
              isLink: true,
              label: oauthApp.redirectionUri,
              icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
              onClick: () => navigator.clipboard.writeText(oauthApp.redirectionUri)
            }
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { color: themeColors.DarkGrey }, children: polyglot.t("DeveloperHub.clientTable.deleteApp") }) }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DeveloperHub.clientTable.deleteSubtitle"),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "text",
              onClick: () => {
                setIsDeleteDrawerOpen(true);
              },
              style: {
                margin: 0,
                padding: 0,
                color: themeColors.RedDark,
                display: "inline",
                textDecoration: "underline"
              },
              children: polyglot.t("PaymentSettingsPage.deleteHere")
            }
          ),
          "."
        ] }) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { color: themeColors.DarkGrey }, children: polyglot.t("DeveloperHub.resourcesTitle") }) }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DeveloperHub.resourcesSubtitleFirst"),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "text",
              onClick: () => {
                window.open(API_TERMS_LINK, "_blank");
              },
              style: {
                margin: 0,
                padding: 0,
                color: themeColors.hoverBlue,
                display: "inline",
                textDecoration: "underline"
              },
              children: "terms of use"
            }
          ),
          ".",
          polyglot.t("DeveloperHub.resourcesSubtitleSecond"),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "text",
              onClick: () => {
                window.open(README_LINK, "_blank");
              },
              style: {
                margin: 0,
                padding: 0,
                color: themeColors.hoverBlue,
                display: "inline",
                textDecoration: "underline"
              },
              children: "here"
            }
          ),
          "."
        ] }) }),
        oauthApp && /* @__PURE__ */ jsx(
          DeveloperHubDeleteClientConfirmDrawer,
          {
            oauthApp,
            isOpen: isDeleteDrawerOpen,
            setIsOpen: setIsDeleteDrawerOpen,
            onDelete
          }
        ),
        /* @__PURE__ */ jsx(
          DeveloperHubClientGenerateDrawer,
          {
            isOpen: isEditDrawerOpen,
            existingClient: oauthApp,
            setIsOpen: setIsEditDrawerOpen,
            onClose: () => setIsEditDrawerOpen(false),
            onUpdate
          }
        )
      ]
    }
  );
};
