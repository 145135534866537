"use strict";
import { jsx } from "react/jsx-runtime";
import { Fragment, useCallback, useMemo } from "react";
import _, { keyBy } from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "@/v2/feature/dashboard//dashboard.scss";
import { UserAttendanceLoader } from "@/v2/feature/dashboard/features/sections/user-attendance/components/user-attendance-loader.component";
import { UserCalendarVersionbigloader } from "@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-calendar-version-big-loader.component";
import { UserCalendarVersionSmallLoader } from "@/v2/feature/dashboard/features/sections/user-calendar/components/version-small/user-calendar-version-small-loader.component";
import { DashboardUserProfileLoader } from "@/v2/feature/dashboard/features/sections/user-profile-widget/components/dashboard-user-profile-loader.component";
import { UserShiftLoader } from "@/v2/feature/dashboard/features/sections/user-shift/components/user-shift-loader.component";
import { UserTimePlannerBigLoader } from "@/v2/feature/dashboard/features/sections/user-time-planner/components/version-big/user-time-planner-big-loader.section";
import { UserTimePlannerSmallLoader } from "@/v2/feature/dashboard/features/sections/user-time-planner/components/version-small/user-time-planner-small-loader.component";
import { UserTodosVersionBigLoading } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/user-todos-version-big-loading.component";
import { UserTodosVersionSmallLoading } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-small/user-todos-version-small-loading.component";
import { WidgetTypes } from "@/v2/feature/dashboard/interfaces/dashboard.interface";
const ResponsiveGridLayout = WidthProvider(Responsive);
export const DashboardLayoutLoader = ({ userConfig }) => {
  const { preference, order } = userConfig != null ? userConfig : {};
  let preferenceIdLookup = useMemo(() => {
    return keyBy(preference, "id");
  }, [preference]);
  const initialRows = [
    {
      type: WidgetTypes.USER_PROFILE_SMALL,
      content: /* @__PURE__ */ jsx(DashboardUserProfileLoader, {})
    },
    {
      type: WidgetTypes.USER_TODOS_BIG,
      content: /* @__PURE__ */ jsx(UserTodosVersionBigLoading, {})
    },
    {
      type: WidgetTypes.USER_TODOS_SMALL,
      content: /* @__PURE__ */ jsx(UserTodosVersionSmallLoading, {})
    },
    {
      type: WidgetTypes.USER_ATTENDANCE_BIG,
      content: /* @__PURE__ */ jsx(UserAttendanceLoader, {})
    },
    {
      type: WidgetTypes.USER_SHIFT_SMALL,
      content: /* @__PURE__ */ jsx(UserShiftLoader, {})
    },
    {
      type: WidgetTypes.USER_CALENDAR_BIG,
      content: /* @__PURE__ */ jsx(UserCalendarVersionbigloader, {})
    },
    {
      type: WidgetTypes.USER_CALENDAR_SMALL,
      content: /* @__PURE__ */ jsx(UserCalendarVersionSmallLoader, {})
    },
    {
      type: WidgetTypes.USER_TIME_PLANNER_SMALL,
      content: /* @__PURE__ */ jsx(UserTimePlannerSmallLoader, {})
    },
    {
      type: WidgetTypes.USER_TIME_PLANNER_BIG,
      content: /* @__PURE__ */ jsx(UserTimePlannerBigLoader, {})
    }
  ];
  const generateLayout = useCallback(() => {
    let x = 0;
    let y = 0;
    let countY = 0;
    const layouts2 = order == null ? void 0 : order.reduce((acc, widget) => {
      const widgetDetails = preferenceIdLookup[widget];
      const w = (widgetDetails == null ? void 0 : widgetDetails.size) === "small" ? 4 : 8;
      if (x + w > 12) {
        x = 0;
        y += countY;
        countY = 0;
      }
      const layout = {
        x,
        y,
        w,
        h: 1,
        i: widget,
        static: widgetDetails == null ? void 0 : widgetDetails.isStatic,
        isBounded: true,
        isResizable: false
      };
      x += w;
      countY += w;
      return [...acc, layout];
    }, []);
    return layouts2;
  }, [preferenceIdLookup, order]);
  const layouts = useMemo(() => {
    return { lg: generateLayout() };
  }, [generateLayout]);
  const generateDOM = () => {
    return _.map(layouts.lg, function(l, i) {
      var _a;
      const widget = preferenceIdLookup[l.i];
      const component = (_a = initialRows.find((row) => row.type === widget.type)) == null ? void 0 : _a.content;
      return /* @__PURE__ */ jsx("div", { id: `user-widget-${i}`, children: component }, l.i);
    });
  };
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    ResponsiveGridLayout,
    {
      className: "layout grid-width",
      layouts: { lg: generateLayout() },
      breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
      cols: { lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 },
      measureBeforeMount: true,
      rowHeight: 280,
      isDraggable: false,
      isResizable: false,
      useCSSTransforms: false,
      preventCollision: true,
      margin: [30, 30],
      children: generateDOM()
    }
  ) });
};
