"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { AbsenceSettingsPublicHolidaysRouter } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/absence-settings-public-holidays-router.component";
import { AbsencePolicyRouter } from "@v2/feature/absence/subfeatures/settings/policy-details/absence-policy-router.component";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl, RouteScopesHas } from "@/component/widgets/Scopes";
import {
  SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE,
  SETTINGS_TIME_OTHER_ROUTE,
  SETTINGS_TIME_POLICIES_ROUTE,
  SETTINGS_TIME_POLICY_DETAILS_ROUTE,
  SETTINGS_TIME_ROUTE
} from "@/lib/routes";
import { AbsenceEndpoints } from "@/v2/feature/absence/absence.api";
import { AbsenceCompanySettingsGeneralPage } from "@/v2/feature/absence/company/policies/pages/absence-company-settings-general.page";
import { AbsenceCompanySettingsPoliciesPage } from "@/v2/feature/absence/company/policies/pages/absence-company-settings-policies.page";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const AbsenceSettingsRouter = () => {
  const {
    data: absencePolicies,
    mutate: refreshAbsencePolicies,
    isLoading
  } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });
  const {
    data: archivedPolicies,
    mutate: refreshAPolicies,
    isLoading: isLoadingArchived
  } = useApiClient(AbsenceEndpoints.getArchivedAbsencePolicies(), { suspense: false });
  const refreshPolicies = useCallback(() => __async(void 0, null, function* () {
    if (refreshAbsencePolicies) yield refreshAbsencePolicies();
  }), [refreshAbsencePolicies]);
  const refreshArchivedPolicies = useCallback(() => __async(void 0, null, function* () {
    if (refreshAPolicies) yield refreshAPolicies();
  }), [refreshAPolicies]);
  const refreshAllPolicies = useCallback(() => __async(void 0, null, function* () {
    if (!refreshAPolicies) return;
    if (refreshAPolicies) yield Promise.all([refreshAPolicies(), refreshPolicies()]);
  }), [refreshAPolicies, refreshPolicies]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICY_DETAILS_ROUTE, children: /* @__PURE__ */ jsx(AbsencePolicyRouter, { refreshPolicies, refreshArchivedPolicies }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:all"], path: SETTINGS_TIME_POLICIES_ROUTE, children: /* @__PURE__ */ jsx(
      AbsenceCompanySettingsPoliciesPage,
      {
        absencePolicies: absencePolicies != null ? absencePolicies : [],
        archivedPolicies: archivedPolicies != null ? archivedPolicies : [],
        refreshPolicies: refreshAllPolicies,
        isLoading: isLoading || isLoadingArchived
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:all"], path: SETTINGS_TIME_OTHER_ROUTE, exact: true, children: /* @__PURE__ */ jsx(AbsenceCompanySettingsGeneralPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["absence:all"], path: SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE, children: /* @__PURE__ */ jsx(AbsenceSettingsPublicHolidaysRouter, {}) }),
    /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_TIME_ROUTE, to: SETTINGS_TIME_POLICIES_ROUTE, exact: true })
  ] });
};
