"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { Box, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import { spacing } from "@v2/styles/spacing.styles";
import Lottie from "lottie-react";
import ZeltLoader from "@/animations/Zelt-gradient-loader.json";
const LoadingSpinner = () => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: "flex",
        height: "100%",
        placeContent: "center",
        verticalAlign: "middle",
        alignItems: "center"
      },
      children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Lottie, { animationData: ZeltLoader, loop: true, autoplay: true }) })
    }
  );
};
export const Loader = ({ loading, children, sx = {}, hideFooter }) => /* @__PURE__ */ jsxs(
  Box,
  {
    sx: __spreadValues({
      minHeight: hideFooter ? "100vh" : "auto",
      height: hideFooter ? "inherit" : "100%",
      display: "flex",
      flexFlow: "column",
      flex: 1
    }, sx),
    children: [
      loading && /* @__PURE__ */ jsx(LoadingSpinner, {}),
      !loading && /* @__PURE__ */ jsx(Fade, { in: !loading, timeout: { enter: 600, exit: 600 }, children: /* @__PURE__ */ jsx("div", { style: { display: "flex", flex: 1, flexFlow: "column" }, children }) }),
      !hideFooter && /* @__PURE__ */ jsx(Box, { sx: { flex: "0 0 40px" } }),
      " "
    ]
  }
);
const MainStyle = styled("div")({
  flexGrow: 1,
  paddingBottom: "40px",
  overflowY: "auto"
});
export const ContentWrapper = forwardRef(
  ({
    loading = false,
    children,
    sx = {},
    loaderSx = {},
    hideFooter = false,
    secondLevel = false,
    noHorizontalPadding = false
  }, ref) => {
    return /* @__PURE__ */ jsx(
      MainStyle,
      {
        ref,
        sx: __spreadValues(__spreadValues(__spreadValues({}, secondLevel ? { paddingTop: "50px" } : {}), sx), noHorizontalPadding ? {} : spacing.px16),
        children: /* @__PURE__ */ jsx(Loader, { loading: !!loading, hideFooter, sx: loaderSx, children })
      }
    );
  }
);
