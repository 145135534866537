"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { isUsingPlaceholderHMRCData } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollHMRCReadonlyState = ({ employer, onEditClick }) => {
  const { officeNumber, payeReference, accountsOfficeReference } = employer.hmrcDetails;
  const { senderId: govGatewayID, contact } = employer.rtiSubmissionSettings;
  const usingPlaceholderData = isUsingPlaceholderHMRCData(__spreadValues({ kind: "employer" }, employer.hmrcDetails));
  const entries = {
    "PAYE reference": /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx("span", { children: officeNumber }),
      /* @__PURE__ */ jsx("span", { style: { margin: `0 3px` }, children: "/" }),
      /* @__PURE__ */ jsx("span", { children: payeReference })
    ] }),
    "Accounts office reference": accountsOfficeReference,
    "Government gateway ID": govGatewayID,
    "Payroll notifications": contact ? `${contact.firstName} ${contact.lastName} ${contact.email}` : ""
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { showEditButton: true, onEditClick, children: "HMRC connection" }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "inline-grid",
          gridTemplateColumns: "1fr 3fr",
          rowGap: spacing.g10,
          columnGap: spacing.g20
        },
        children: Object.entries(entries).map(([label, value], idx) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { whiteSpace: "nowrap" }, children: label }),
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: usingPlaceholderData ? "-" : value })
        ] }, `payroll-setting-${idx}`))
      }
    ),
    usingPlaceholderData && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.mt20 }, children: "Company is not registered with HMRC or has no HMRC information available" })
  ] });
};
