"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { BenefitsOverviewPage } from "@v2/feature/benefits/benefits-company-overview.page";
import { InsuranceCompanyRouter } from "@v2/feature/benefits/subfeature/insurance/insurance-company.router";
import { PensionCompanyRouter } from "@v2/feature/benefits/subfeature/pension/pension-company.router";
import { RootStyle } from "@v2/styles/root.styles";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  BENEFITS_COMPANY_ROUTE,
  CUSTOM_BENEFIT_COMPANY_ROUTE,
  INSURANCE_COMPANY_ROUTE,
  PENSION_COMPANY_ROUTE
} from "@/lib/routes";
import { CustomBenefitCompanyRouter } from "@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit-company.router";
export const BenefitsCompanyRouter = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshInsuranceQuote,
  refreshAllBenefits,
  companyConfig,
  loading
}) => {
  const [state] = useContext(GlobalContext);
  const userId = state.user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsxs(Switch, { children: [
    companyConfig.inPayroll && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["pension:all"], path: PENSION_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(PensionCompanyRouter, { pensionSchemes, loading }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance:all"], path: CUSTOM_BENEFIT_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(CustomBenefitCompanyRouter, { loading, customBenefits }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance:all"], context: scopesContext, path: INSURANCE_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(
      InsuranceCompanyRouter,
      {
        insuranceQuote,
        refreshInsuranceQuote,
        loading
      }
    ) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["insurance:all", "pension:all"], path: BENEFITS_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(
      BenefitsOverviewPage,
      {
        pensionSchemes,
        customBenefits,
        insuranceQuote,
        refreshAllBenefits,
        loading
      }
    ) })
  ] }) });
};
