"use strict";
import { format, parseISO } from "date-fns";
import { removeTZ, timeAgo } from "@/v2/util/date-format.util";
export const tsFormat = "MMMM do, hha";
export const getStartingTimeForJob = (row) => {
  return row.processedOn ? timeAgo(removeTZ(new Date(row.processedOn).toISOString())) : row.opts.delay ? removeTZ(new Date(row.timestamp + row.opts.delay).toISOString()) : "";
};
export const getScheduledTimeForAppAccessDetailDrawer = (row) => {
  const scheduledDate = row.processedOn ? timeAgo(removeTZ(new Date(row.processedOn).toISOString())) : row.opts.delay ? removeTZ(new Date(row.timestamp + row.opts.delay).toISOString()) : void 0;
  return scheduledDate ? format(parseISO(scheduledDate), tsFormat) : void 0;
};
