"use strict";
import { AccountStatus } from "@/lib/users";
export const canInviteAgainStatuses = [AccountStatus.Invited, AccountStatus.InvitedToOnboard];
export function getUserFullName({ firstName, lastName }) {
  return `${firstName} ${lastName}`;
}
export const filterStringToObject = (currentFilterString) => {
  const defaultFilterString = {};
  if (currentFilterString.length > 0) {
    const andString = currentFilterString.split("&");
    if (andString.length > 0) {
      andString.forEach((item) => {
        const [key, values] = item.split("=");
        if (key && values) defaultFilterString[key] = values.split(",");
      });
    }
  }
  return defaultFilterString;
};
