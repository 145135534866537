"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserAPI } from "@/v2/feature/user/user.api";
export const WorkContactSchema = Yup.object().shape({
  workMobile: Yup.string().optional().nullable(),
  workPhone: Yup.string().optional().nullable()
});
export const WorkContactForm = ({ initialValues, userId, onSubmit, onClose }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: WorkContactSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      const update = __spreadValues({}, values);
      try {
        const _result = yield UserAPI.patchUserWorkContactInfo(userId, update);
        onSubmit(update);
        showMessage(polyglot.t("WorkContactForm.successMessages.update"), "success");
      } catch (error) {
        showMessage(polyglot.t("WorkContactForm.errorMessages.save", { msg: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("WorkContactForm.edit") }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "user.workMobile", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "workMobile",
        label: polyglot.t("WorkContactForm.workMobile"),
        value: formik.values.workMobile,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.workMobile && !!formik.errors.workMobile,
        helperText: (_a = formik.touched.workMobile && formik.errors.workMobile) != null ? _a : " ",
        clearText: () => formik.setFieldValue("workMobile", "")
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "user.workPhone", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "workPhone",
        label: polyglot.t("WorkContactForm.workPhone"),
        value: formik.values.workPhone,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.workPhone && !!formik.errors.workPhone,
        helperText: (_b = formik.touched.workPhone && formik.errors.workPhone) != null ? _b : " ",
        clearText: () => formik.setFieldValue("workPhone", "")
      }
    ) }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values)
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading, fullWidth: true, sizeVariant: "medium", colorVariant: "primary" })
    ] })
  ] }) });
};
