"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const DocumentConfirmBulkDeleteDrawer = ({
  isOpen,
  setIsOpen,
  onActionConfirm,
  drawerTitle,
  confirmationText,
  documentCountForDeletion
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    DocumentConfirmBulkDeleteDrawerContent,
    {
      onActionConfirm,
      setIsOpen,
      drawerTitle,
      confirmationText,
      documentCountForDeletion
    }
  ) });
};
export const DocumentConfirmBulkDeleteDrawerContent = ({
  onActionConfirm,
  setIsOpen,
  drawerTitle,
  confirmationText,
  documentCountForDeletion
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const onActionConfirmClick = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    yield onActionConfirm();
    setIsOpen(false);
    setLoading(false);
  }), [onActionConfirm, setIsOpen]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: drawerTitle }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: confirmationText }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "confirmTxt",
        label: `Please type "Confirm" in the field below to delete ${documentCountForDeletion} documents`,
        autoFocus: true,
        autoComplete: "off",
        value: confirmText,
        onChange: (newVal) => {
          if (!newVal) return;
          setConfirmText(newVal.currentTarget.value);
        }
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => setIsOpen(false),
          disabled: loading,
          fullWidth: true,
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.delete"),
          disabled: (confirmText == null ? void 0 : confirmText.toLowerCase()) !== "confirm" || loading,
          loading,
          onClick: onActionConfirmClick,
          type: "button",
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true
        }
      )
    ] })
  ] });
};
