"use strict";
import { jsx } from "react/jsx-runtime";
import Savings from "@mui/icons-material/Savings";
import { LocalDate } from "@v2/util/local-date";
import NestLogo from "@/images/pension/nest.svg";
import CushonLogo from "@/images/vendors/cushon.png";
import SmartPensionLogo from "@/images/vendors/SmartPension.svg";
import ThePeoplesPensionLogo from "@/images/vendors/ThePeoplesPension.svg";
import { PensionProviders } from "@/lib/pensions";
export const getPensionLogoByProviderName = (provider, width = 25) => {
  switch (provider) {
    case PensionProviders.Nest:
      return /* @__PURE__ */ jsx("img", { src: NestLogo, alt: "Smart Pension", width });
    case PensionProviders.SmartPension:
      return /* @__PURE__ */ jsx("img", { src: SmartPensionLogo, alt: "Smart Pension", width });
    case PensionProviders.PeoplesPension:
      return /* @__PURE__ */ jsx("img", { src: ThePeoplesPensionLogo, alt: "People's pension", width });
    case PensionProviders.Cushon:
      return /* @__PURE__ */ jsx("img", { src: CushonLogo, alt: "Cushon", width });
    case PensionProviders.OtherProvider:
    default:
      return /* @__PURE__ */ jsx(Savings, { fontSize: "small", width });
  }
};
export const PensionProvidersValueToLabel = {
  [PensionProviders.Nest]: "Nest",
  [PensionProviders.SmartPension]: "Smart Pension",
  [PensionProviders.PeoplesPension]: "People's pension",
  [PensionProviders.Cushon]: "Cushon",
  [PensionProviders.OtherProvider]: "Other Provider"
};
export const PensionProvidersOptions = [
  {
    label: "Nest",
    value: PensionProviders.Nest,
    icon: getPensionLogoByProviderName(PensionProviders.Nest)
  },
  {
    label: "Smart pension",
    value: PensionProviders.SmartPension,
    icon: getPensionLogoByProviderName(PensionProviders.SmartPension)
  },
  {
    label: "People's pension",
    value: PensionProviders.PeoplesPension,
    icon: getPensionLogoByProviderName(PensionProviders.PeoplesPension)
  },
  {
    label: "Cushon",
    value: PensionProviders.Cushon,
    icon: getPensionLogoByProviderName(PensionProviders.Cushon)
  },
  {
    label: "Other Provider",
    value: PensionProviders.OtherProvider,
    icon: getPensionLogoByProviderName(PensionProviders.OtherProvider)
  }
];
export const getActionRequiredFromLastAssessment = (lastAssessment) => {
  if (!lastAssessment) return "Assess";
  return !(lastAssessment == null ? void 0 : lastAssessment.action.actionCompleted) ? lastAssessment == null ? void 0 : lastAssessment.action.action : "No change";
};
export const getLastActionFromReport = (lastAssessment) => {
  var _a, _b, _c, _d;
  if ((_a = lastAssessment == null ? void 0 : lastAssessment.action) == null ? void 0 : _a.actionCompleted) {
    return `${((_b = lastAssessment == null ? void 0 : lastAssessment.action) == null ? void 0 : _b.actionCompletedMessage) || ((_c = lastAssessment == null ? void 0 : lastAssessment.action) == null ? void 0 : _c.employeeState) || ((_d = lastAssessment == null ? void 0 : lastAssessment.action) == null ? void 0 : _d.action)} on ${new LocalDate(lastAssessment.assessmentDate).toDateString()}`;
  }
  return "";
};
export const getPensionProviderFromUserPensionAndDefaultSchema = (userPension, defaultPensionScheme) => {
  if (userPension.pensionProviderId) return userPension.pensionProviderId;
  if (defaultPensionScheme == null ? void 0 : defaultPensionScheme.externalId) return defaultPensionScheme.externalId;
  return "";
};
export const getWorkerGroupFromUserPensionAndDefaultSchema = (userPension, defaultWorkerGroup) => {
  if (userPension.workerGroupId) return userPension.workerGroupId;
  if (defaultWorkerGroup == null ? void 0 : defaultWorkerGroup.externalId) return defaultWorkerGroup.externalId;
  return "";
};
