"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { PaymentSettingsEditApprovalRulesDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-edit-approval-rules.component";
import { SettingsPageSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import {
  PaymentCategoryEnum
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import { ruleForApprovals } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { detailSx, detailTermSx, detailValueSx } from "@/v2/styles/settings.styles";
export const PaymentSettingsApprovalsPage = ({ paymentTypeSetting, refreshPaymentTypeSetting }) => {
  const { data: approvalRules, mutate: refreshRules } = useApiClient(
    ApprovalRuleEndpoints.getApprovalRules((paymentTypeSetting == null ? void 0 : paymentTypeSetting.type) === PaymentCategoryEnum.EXPENSE),
    { suspense: false }
  );
  const refreshApprovalRules = useCallback(() => __async(void 0, null, function* () {
    if (refreshRules) yield refreshRules();
  }), [refreshRules]);
  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    SettingsPageSection,
    {
      title: polyglot.t("PaymentSettingsPage.approvalsTitle"),
      onEdit: () => {
        setIsDrawerOpen(true);
      },
      children: [
        (paymentTypeSetting == null ? void 0 : paymentTypeSetting.approvalRuleId) && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, detailTermSx), { whiteSpace: "nowrap" }), children: polyglot.t("PaymentSettingsPage.approvalsTitle") }),
          /* @__PURE__ */ jsx(Typography, { sx: detailValueSx, children: ruleForApprovals(approvalRules, paymentTypeSetting.approvalRuleId) })
        ] }, "approval"),
        isDrawerOpen && (paymentTypeSetting == null ? void 0 : paymentTypeSetting.approvalRuleId) && /* @__PURE__ */ jsx(
          PaymentSettingsEditApprovalRulesDrawer,
          {
            isOpen: isDrawerOpen,
            setIsOpen: setIsDrawerOpen,
            paymentTypeSetting,
            approvalRules: approvalRules != null ? approvalRules : [],
            refresh: refreshPaymentTypeSetting,
            refreshApprovalRules
          }
        )
      ]
    }
  );
};
