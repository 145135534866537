"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { CompanyEndpoints } from "@/api-client/company.api";
import { ReactComponent as EditIcon } from "@/images/side-bar-icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "@/images/side-bar-icons/Trash.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CompanyEntityDrawerPage } from "@/v2/feature/company/company-settings/features/components/company-settings/entity-details/company-entity-drawer.component";
import { DeleteCompanyEntityDrawer } from "@/v2/feature/company/company-settings/features/components/company-settings/entity-details/delete-company-entity-drawer.component";
import { getCountryNameForCode } from "@/v2/feature/payments/payments.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const CompanySettingsEntityTable = () => {
  const { polyglot } = usePolyglot();
  const {
    data: generalSettings,
    isValidating: loadingGeneralSettings,
    mutate: refreshEntities
  } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const [drawer, setDrawer] = useState();
  const sortedEntities = useMemo(
    () => ((generalSettings == null ? void 0 : generalSettings.entities) || []).sort(
      (a, b) => a.legalName.localeCompare(b.legalName, void 0, { sensitivity: "base" })
    ),
    [generalSettings]
  );
  const columns = useMemo(() => {
    const formatAddress = ({ addressLine1, addressLine2, city, postCode, countryCode, region }) => [addressLine1, addressLine2, city, postCode, countryCode, region].filter(Boolean).join(" ");
    const NoWrap = ({ children }) => /* @__PURE__ */ jsx("div", { style: { whiteSpace: "nowrap", textOverflow: "ellipsis" }, children });
    return [
      {
        id: "legal-name",
        header: () => polyglot.t("CompanySettingsEntityTable.legalName"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.legalName, { sensitivity: "base" }),
        cell: ({ row }) => /* @__PURE__ */ jsx(NoWrap, { children: row.original.legalName })
      },
      {
        id: "country",
        header: () => polyglot.t("CompanySettingsEntityTable.country"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return getCountryNameForCode((_a = item.address) == null ? void 0 : _a.countryCode);
        }, {
          sensitivity: "base"
        }),
        cell: ({ row }) => {
          var _a;
          return /* @__PURE__ */ jsx(NoWrap, { children: getCountryNameForCode((_a = row.original.address) == null ? void 0 : _a.countryCode) });
        }
      },
      {
        id: "address",
        header: () => polyglot.t("CompanySettingsEntityTable.address"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.address && formatAddress(item.address), { sensitivity: "base" }),
        cell: ({ row }) => /* @__PURE__ */ jsx(NoWrap, { children: row.original.address && formatAddress(row.original.address) })
      },
      {
        id: "company-id",
        header: () => polyglot.t("CompanySettingsEntityTable.companyid"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.nationalId, { sensitivity: "base" }),
        cell: ({ row }) => /* @__PURE__ */ jsx(NoWrap, { children: row.original.nationalId })
      },
      {
        id: "vat-no",
        header: () => polyglot.t("CompanySettingsEntityTable.vat"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.taxId, { sensitivity: "base" }),
        cell: ({ row }) => /* @__PURE__ */ jsx(NoWrap, { children: row.original.taxId })
      },
      {
        id: "currency",
        header: () => polyglot.t("General.currency"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.currency),
        cell: ({ row }) => /* @__PURE__ */ jsx(NoWrap, { children: row.original.currency })
      },
      {
        id: "is-default-billing-entity",
        header: () => polyglot.t("CompanySettingsEntityTable.billingEntity"),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row }) => /* @__PURE__ */ jsx(NoWrap, { children: row.original.isDefaultBillingEntity ? "Yes" : "No" })
      },
      {
        id: "actions",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row }) => {
          return /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "flex-end", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(
              IconButton,
              {
                title: polyglot.t("CompanySettingsEntityTable.deleteCompanyEntity"),
                onClick: () => setDrawer({ entity: row.original, isDeleting: true }),
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(DeleteIcon, __spreadValues({}, actionIconSize))
              }
            ),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                title: polyglot.t("CompanySettingsEntityTable.editCompanyEntity"),
                onClick: () => setDrawer({ entity: row.original }),
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize))
              }
            )
          ] });
        }
      }
    ];
  }, [polyglot]);
  const closeDrawer = useCallback(
    (updated) => {
      setDrawer(void 0);
      if (updated) refreshEntities == null ? void 0 : refreshEntities();
    },
    [refreshEntities]
  );
  const isDrawerOpen = typeof drawer !== "undefined";
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CompanySettingsEntityTable.entityInformation") }),
        showBack: true,
        showAction: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setDrawer({ entity: null }), children: polyglot.t("CompanySettingsEntityTable.buttonNewEntity") })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: sortedEntities,
          columnData: columns,
          hidePagination: true,
          style: { width: "initial", marginTop: spacing.s1 },
          loading: loadingGeneralSettings
        }
      ),
      /* @__PURE__ */ jsx(DrawerModal, { isOpen: isDrawerOpen, setIsOpen: (open) => !open && closeDrawer(false), children: /* @__PURE__ */ jsxs(Fragment, { children: [
        (drawer == null ? void 0 : drawer.entity) && drawer.isDeleting && /* @__PURE__ */ jsx(DeleteCompanyEntityDrawer, { entity: drawer.entity, close: closeDrawer }),
        (drawer == null ? void 0 : drawer.entity) !== void 0 && !drawer.isDeleting && /* @__PURE__ */ jsx(CompanyEntityDrawerPage, { entity: drawer.entity, close: closeDrawer })
      ] }) })
    ] })
  ] });
};
