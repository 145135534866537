"use strict";
export var RoleTypes = /* @__PURE__ */ ((RoleTypes2) => {
  RoleTypes2["company"] = "company";
  RoleTypes2["team"] = "team";
  RoleTypes2["me"] = "me";
  return RoleTypes2;
})(RoleTypes || {});
export var MenuActionType = /* @__PURE__ */ ((MenuActionType2) => {
  MenuActionType2["SET_SECOND_MENU_VISIBILITY"] = "SET_SECOND_MENU_VISIBILITY";
  MenuActionType2["SET_ACTIVE_PARENT"] = "SET_ACTIVE_PARENT";
  MenuActionType2["SET_ACTIVE_SUBMENU_PARENT"] = "SET_ACTIVE_SUBMENU_PARENT";
  MenuActionType2["SET_ACTIVE_ROLE"] = "SET_ACTIVE_ROLE";
  return MenuActionType2;
})(MenuActionType || {});
export const RolesOptions = (polyglot) => {
  return {
    company: polyglot == null ? void 0 : polyglot.t("topNavigationMenu.company"),
    team: polyglot == null ? void 0 : polyglot.t("topNavigationMenu.team"),
    me: polyglot == null ? void 0 : polyglot.t("topNavigationMenu.me")
  };
};
export var MainMenuSectionEnum = /* @__PURE__ */ ((MainMenuSectionEnum2) => {
  MainMenuSectionEnum2["dashboard"] = "dashboard";
  MainMenuSectionEnum2["people"] = "people";
  MainMenuSectionEnum2["time"] = "time";
  MainMenuSectionEnum2["money"] = "money";
  MainMenuSectionEnum2["tech"] = "tech";
  MainMenuSectionEnum2["growth"] = "growth";
  MainMenuSectionEnum2["report"] = "report";
  return MainMenuSectionEnum2;
})(MainMenuSectionEnum || {});
