"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PensionAPI {
  static deletePension(schemeId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/pension/${schemeId}`);
    });
  }
  static createPensionScheme(payrollId, pensionScheme) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/pension?payrollId=${payrollId}`, pensionScheme)).data;
    });
  }
  static updatePensionGeneralData(pensionSchemeId, pensionScheme) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/pension/${pensionSchemeId}/general`, pensionScheme);
    });
  }
  static updatePensionProviderSettings(pensionSchemeId, pensionScheme) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/pension/${pensionSchemeId}/provider`, pensionScheme);
    });
  }
  static getEmployeeMemberships() {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get("/apiv2/employees")) == null ? void 0 : _a.data;
    });
  }
  static getCompanyPensionSchemes() {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get("/apiv2/pension")) == null ? void 0 : _a.data;
    });
  }
  static getPension(pensionSchemeId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/pension/${pensionSchemeId}`)) == null ? void 0 : _a.data;
    });
  }
  static getPayrunPensionRecordForPensionScheme(payrunId, pensionSchemeId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/payroll/payruns/${payrunId}/pension/${pensionSchemeId}`)).data;
    });
  }
  static fetchPensionSchemeEmployeesPension(pensionSchemeId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/employees/pensionschemes/${pensionSchemeId}`)) == null ? void 0 : _a.data;
    });
  }
  static addWorkerGroup(schemeId, workerGroup) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/pension/pensionSchemes/${schemeId}/workergroup`, workerGroup);
    });
  }
  static deleteWorkerGroup(schemeId, workerGroupId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/pension/pensionSchemes/${schemeId}/workergroup/${workerGroupId}`);
    });
  }
  static updateWorkerGroup(schemeId, workerGroupId, workerGroup) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/pension/pensionSchemes/${schemeId}/workergroup/${workerGroupId}`, workerGroup);
    });
  }
  static getEmployeePension(employeeId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/employees/${employeeId}/pension`)) == null ? void 0 : _a.data;
    });
  }
  static createEmployeePension(employeeId, employeePension, isRetrospectively = false) {
    return __async(this, null, function* () {
      const url = `/apiv2/employees/${employeeId}/pension${isRetrospectively ? "?isRetrospectively=true" : ""}`;
      yield axios.post(url, employeePension);
    });
  }
  static updateEmployeePension(employeeId, employeePension) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/employees/${employeeId}/pension`, employeePension);
    });
  }
  static deleteEmployeePension(employeeId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/employees/${employeeId}/pension`);
    });
  }
  static getEmployeeAutoEnrolment(employeeId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/employees/${employeeId}/pension/autoenrolment`)) == null ? void 0 : _a.data;
    });
  }
  static assessEmployeeForAE(employeeId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/employees/${employeeId}/pension/assessments`)).data;
    });
  }
  static assessEmployeeForAEInternally(employeeId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/employees/${employeeId}/pension/internal-assessments`)) == null ? void 0 : _a.data;
    });
  }
  static deferEmployeeEnrolment(employeeId, deferralDate) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/employees/${employeeId}/defer-enrollment`, { deferralDate })) == null ? void 0 : _a.data;
    });
  }
  static optOutPensionEmployees(employeeId, optOutSettings) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/employees/${employeeId}/pension/optout`, optOutSettings);
    });
  }
  static getPensionExternalProviders(payrollId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/pension/pensionschemes/external-providers?payrollId=${payrollId}`)).data;
    });
  }
  static getPapdisFile(pensionSchemeId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/pension/reports/${taxYear}/${payPeriod}/${periodNumber}/papdis/${pensionSchemeId}`)).data;
    });
  }
  static getCushonFile(pensionSchemeId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/pension/reports/${taxYear}/${payPeriod}/${periodNumber}/cushon/${pensionSchemeId}`)).data;
    });
  }
  static getSpecificPensionSchemeContribution(pensionSchemeId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      return (yield axios.get(
        `/apiv2/pension/pensionschemes/${pensionSchemeId}/contributions/${taxYear}/${payPeriod}/${periodNumber}`
      )).data;
    });
  }
  static markPensionSchemeContributionAsSent(pensionSchemeId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      yield axios.post(
        `/apiv2/pension/pensionschemes/${pensionSchemeId}/contributions/${taxYear}/${payPeriod}/${periodNumber}/markassent`
      );
    });
  }
  static resubmitPensionSchemeContribution(pensionSchemeId, taxYear, payPeriod, periodNumber) {
    return __async(this, null, function* () {
      yield axios.post(
        `/apiv2/pension/pensionschemes/${pensionSchemeId}/contributions/${taxYear}/${payPeriod}/${periodNumber}`
      );
    });
  }
}
export class PensionEndpoints {
  static getMinimumEmployeePensionStartDate(userId, payrollId) {
    return {
      url: `/apiv2/employees/${userId}/pension/minimum-enrollment-date?payrollId=${payrollId}`
    };
  }
  static fetchPensionSchemeEmployeesPension(pensionSchemeId) {
    return {
      url: `/apiv2/employees/pensionschemes/${pensionSchemeId}`
    };
  }
  static getPension(pensionSchemeId) {
    return {
      url: `/apiv2/pension/${pensionSchemeId}`
    };
  }
  static getCountOfActivePensionSchemes() {
    return {
      url: "/apiv2/pension/count"
    };
  }
}
