"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserTodosVersionBigLoading = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "big", isLoadingState: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserTodosVersionBigLoading.action") }) })
      },
      "box1"
    ),
    /* @__PURE__ */ jsx(Box, { sx: { height: "100%", padding: spacing.p5 }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: spacing.g25
        },
        children: [0, 1, 2, 3, 4, 5].map((i) => /* @__PURE__ */ jsx("div", { className: "uai-loader-container", children: /* @__PURE__ */ jsx("div", { className: "uai-progress" }) }, i))
      }
    ) }, "box2")
  ] }) }, "big-todos-loading");
};
