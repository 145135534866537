"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { CompanyAPI } from "@/api-client/company.api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UploadPublicImage } from "@/v2/components/uploadPublicImage/upload-public-image.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
const validationSchema = () => yup.object({
  showWallpaper: yup.boolean().required()
});
export const CompanySettingsCustomisationDetailsDrawer = ({
  setEditMode,
  editMode,
  generalSettings,
  refreshSettings
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: editMode, setIsOpen: setEditMode, children: /* @__PURE__ */ jsx(
    CompanySettingsCustomisationDetailsContent,
    {
      setEditMode,
      generalSettings,
      refreshSettings
    }
  ) });
};
const CompanySettingsCustomisationDetailsContent = ({
  setEditMode,
  generalSettings,
  refreshSettings
}) => {
  var _a;
  const [localAvatar, setLocalAvatar] = useState((_a = generalSettings == null ? void 0 : generalSettings.wallpaper) != null ? _a : "");
  const [loading, setLoading] = useState(false);
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [, dispatch] = useContext(GlobalContext);
  const formik = useFormik({
    initialValues: {
      showWallpaper: Boolean(generalSettings == null ? void 0 : generalSettings.showWallpaper) || false
    },
    enableReinitialize: true,
    validationSchema: validationSchema(),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const update = {
          name: generalSettings.name,
          showWallpaper: values == null ? void 0 : values.showWallpaper
        };
        yield CompanyAPI.updateGeneralSettings(update);
        const res = yield AuthAPI.getAuthMe(false);
        yield refreshSettings == null ? void 0 : refreshSettings();
        setEditMode(false);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: res.user
        });
        showMessage(polyglot.t("CompanySettingsGeneralEdit.successMessages.update"), "success");
      } catch (error) {
        showMessage(
          `${polyglot.t("CompanySettingsGeneralEdit.errorMessages.update")}: ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CompanySettingsCustomisationDetailsContent.customisation") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("CompanySettingsCustomisationDetailsContent.upload") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: formik.values.showWallpaper,
          name: "showWallpaper",
          onChange: (_e, enabled) => __async(void 0, null, function* () {
            formik.setFieldValue("showWallpaper", enabled);
          })
        }
      ),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("CompanySettingsCustomisationDetailsContent.displayCustom") })
    ] }),
    /* @__PURE__ */ jsx(
      UploadPublicImage,
      {
        disabled: false,
        value: localAvatar,
        onChange: (tempAvatar) => {
          setLocalAvatar(tempAvatar != null ? tempAvatar : "");
        },
        url: CompanyAPI.getCompanyUploadWallpaperURL(),
        refresh: () => __async(void 0, null, function* () {
          yield refreshSettings == null ? void 0 : refreshSettings();
        }),
        width: "100%",
        restrictedFormats: ["image/webp"],
        handleDelete: () => __async(void 0, null, function* () {
          try {
            yield CompanyAPI.deleteCompanyWallpaper();
            setLocalAvatar("");
          } catch (error) {
            showMessage(
              `${polyglot.t("CompanySettingsGeneralEdit.errorMessages.delete")}: ${nestErrorMessage(error)}`,
              "error"
            );
          }
        })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          disabled: loading,
          onClick: () => setEditMode(false),
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          type: "submit",
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: loading,
          loading
        }
      )
    ] })
  ] }) });
};
