"use strict";
import { differenceInCalendarDays, getDaysInMonth, lastDayOfMonth } from "date-fns";
import { fixedNumber } from "@/v2/util/number.util";
export function getNoOfActiveDays(startDate, endDate) {
  return differenceInCalendarDays(new Date(endDate), new Date(startDate)) + 1;
}
export function ShortDateString(date) {
  if (!date) return (/* @__PURE__ */ new Date()).toISOString().slice(0, 10);
  return new Date(date).toISOString().slice(0, 10);
}
export function getNoOfActiveDaysForBilling(startDate) {
  const today = ShortDateString();
  const referenceDate = new Date(startDate || today);
  return getNoOfActiveDays(referenceDate, lastDayOfMonth(referenceDate));
}
export function getBillToEndOfMonth(price, inPence, startDate) {
  const today = ShortDateString();
  const referenceDate = new Date(startDate || today);
  const noOfDaysInMonth = getDaysInMonth(referenceDate);
  const noOfActiveDays = getNoOfActiveDaysForBilling(startDate);
  const amountInPounds = fixedNumber(noOfActiveDays / noOfDaysInMonth * price, 2);
  return inPence ? fixedNumber(amountInPounds * 100, 0) : amountInPounds;
}
