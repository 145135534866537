"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { SETTINGS_CALENDAR_ROUTE } from "@/lib/routes";
import { CalendarSettingsPage } from "@/v2/feature/calendar/features/settings/calendar-settings.page";
export const CalendarSettingRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["calendar:all"], path: SETTINGS_CALENDAR_ROUTE, children: /* @__PURE__ */ jsx(CalendarSettingsPage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_CALENDAR_ROUTE, to: SETTINGS_CALENDAR_ROUTE, exact: true })
  ] });
};
