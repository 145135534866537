"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Link } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const JiraApiTokenInstructions = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: [
      "a) Log in to",
      " ",
      /* @__PURE__ */ jsx(Link, { target: "_blank", href: "https://id.atlassian.com/manage-profile/security/api-tokens", children: "Atlassian - API Tokens site" }),
      "."
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: "b) Click Create API token." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: "c) From the dialog that appears, enter a memorable and concise Label for your token and click Create." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: "d) Click Copy to clipboard, then paste the token here" })
  ] });
};
export const JiraSiteUrlInstructions = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: [
      "a) Log in to",
      " ",
      /* @__PURE__ */ jsx(Link, { target: "_blank", href: "https://admin.atlassian.com/", children: "Atlassian Admin" })
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: "b) Choose the organisation you intend to integrate Zelt with." }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: "c) Click the three dots menu, and Open Jira Software" }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: "d) The tab that opens up will have your Jira Site URL, and it will be something like mycompany.atlassian.net" })
  ] });
};
export const JiraAdminEmailInstructions = () => {
  return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "white", sx: { mb: spacing.m5 }, children: "NOTE: This email has to belong to your Site / Organisation admin; any other email address will not allow you to integrate successfully" }) });
};
