"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class EventsAPI {
  static getAllUserEvents(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/events`)).data;
    });
  }
  static createUserEventEntry(userId, newUserEventEntry) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/events`, newUserEventEntry);
    });
  }
  static additionalStepsForRehire(userId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/events/rehire`);
    });
  }
  static updateUserEventEntry(userId, existingUserEventEntry) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/events/${existingUserEventEntry.id}`, existingUserEventEntry);
    });
  }
}
