"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import {
  DownloadPayslipsStates,
  PayrunProcessStepStates
} from "@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface";
import { PayrollExternalApi } from "@v2/feature/payroll/payroll-external.api";
import { PayrollLocalApi } from "@v2/feature/payroll/payroll-local.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { sleep } from "@v2/util/time.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PayrunProcessingItem } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component";
import { LocalDate } from "@/v2/util/local-date";
export const DownloadPayslip = ({ payRun, setDownloadPayslipsState, sx }) => {
  const [accordionState, setAccordionState] = useState(PayrunProcessStepStates.pending);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [downloadLoading, setDownloadLoading] = useState(false);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      var _a;
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        const payRunPayslipsDetails = yield PayrollLocalApi.getPayrunPayslipsDetails(payRun.id);
        const downloadPayslipsState = (_a = payRunPayslipsDetails == null ? void 0 : payRunPayslipsDetails.state) != null ? _a : DownloadPayslipsStates.notDownloaded;
        setDownloadPayslipsState(downloadPayslipsState);
        if (downloadPayslipsState === DownloadPayslipsStates.notDownloaded) {
          yield sleep(1e3);
          const updatedDownloadPayslips = yield PayrollLocalApi.createPayslips(payRun.id);
          setDownloadPayslipsState(updatedDownloadPayslips.state);
        }
        setAccordionState(PayrunProcessStepStates.success);
      } catch (error) {
        setErrorMessage(nestErrorMessage(error));
        setAccordionState(PayrunProcessStepStates.failure);
      }
    }))();
  }, [payRun, setDownloadPayslipsState]);
  const createPayslips = (payRunId) => __async(void 0, null, function* () {
    try {
      setAccordionState(PayrunProcessStepStates.pending);
      const updatedDownloadPayslips = yield PayrollLocalApi.createPayslips(payRunId);
      setDownloadPayslipsState(updatedDownloadPayslips.state);
      setAccordionState(PayrunProcessStepStates.success);
    } catch (error) {
      setErrorMessage(nestErrorMessage(error));
      setAccordionState(PayrunProcessStepStates.failure);
    }
  });
  const downloadPayslips = useCallback(() => __async(void 0, null, function* () {
    try {
      setDownloadLoading(true);
      const paySchedules = yield PayrollExternalApi.getPaySchedulesForAllTaxYears(payRun.payrollId);
      const payslipPayruns = (paySchedules != null ? paySchedules : []).map(({ payRuns }) => payRuns.map(({ metadata }) => metadata)).flat().sort((a, b) => b.endDate.localeCompare(a.endDate));
      const selectedPayslipPayruns = payslipPayruns.filter(
        (e) => e.taxYear === payRun.taxYear && e.payPeriod === payRun.payPeriod && e.periodNumber === payRun.period
      );
      let ordinal = 1;
      for (const p of selectedPayslipPayruns) if (p.ordinal > ordinal) ordinal = p.ordinal;
      const url = yield PayrollExternalApi.downloadPayslipsUrl(
        payRun.payrollId,
        payRun.taxYear,
        payRun.payPeriod,
        payRun.period,
        ordinal
      );
      window.location.href = url;
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
    setDownloadLoading(false);
  }), [payRun, showMessage, polyglot]);
  const downloadP30 = useCallback(() => __async(void 0, null, function* () {
    setDownloadLoading(true);
    try {
      const periodEndingDate = new LocalDate(payRun.endDate);
      if (periodEndingDate.getDate().getDate() <= 5) {
        periodEndingDate.getDate().setDate(5);
      } else {
        periodEndingDate.getDate().setMonth(periodEndingDate.getDate().getMonth() + 1, 5);
      }
      const periodEnding = periodEndingDate.toDateString();
      const url = yield PayrollExternalApi.downloadP30Url(payRun.payrollId, payRun.taxYear, periodEnding);
      window.location.href = url;
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
    setDownloadLoading(false);
  }), [payRun, showMessage, polyglot]);
  const downloadP32 = useCallback(() => __async(void 0, null, function* () {
    setDownloadLoading(true);
    try {
      const url = yield PayrollExternalApi.downloadP32Url(payRun.payrollId, payRun.taxYear);
      window.location.href = url;
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
    setDownloadLoading(false);
  }), [payRun, showMessage, polyglot]);
  return /* @__PURE__ */ jsx(
    PayrunProcessingItem,
    {
      title: "Documents",
      description: {
        pending: "Preparing payslips and payroll reports...",
        failure: `An error occurred while generating the payslips. ${errorMessage}`,
        success: "Payroll reports have been generated.",
        warning: `A warning occurred while generating the payslips. ${errorMessage}`
      }[accordionState],
      buttons: [
        {
          style: "primary",
          label: "Retry",
          show: accordionState !== PayrunProcessStepStates.success,
          onClick: () => createPayslips(payRun.id),
          type: "button"
        },
        {
          style: "secondary",
          label: "Download",
          show: accordionState === PayrunProcessStepStates.success,
          type: "dropdown",
          loading: downloadLoading,
          dropdownOptions: [
            {
              label: "P30 - Download your Employer's Payslip",
              handler: downloadP30
            },
            {
              label: "P32 - Download your Employer Payment Record for tax year",
              handler: downloadP32
            },
            {
              label: "All payslips for all employees",
              handler: downloadPayslips
            }
          ]
        }
      ],
      success: accordionState === PayrunProcessStepStates.pending ? void 0 : accordionState === PayrunProcessStepStates.success,
      sx
    },
    "Documents"
  );
};
