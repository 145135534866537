"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { TranslationDrawer } from "@v2/feature/company/company-translation/company-translation-drawer.component";
import { TranslationTable } from "@v2/feature/company/company-translation/company-translation-table.component";
import { CompanyTranslationsCsvUploadDrawer } from "@v2/feature/company/company-translation/company-translations-csv-upload-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { CompanyEndpoints } from "@/api-client/company.api";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const CompanyTranslationPage = () => {
  const { data: translations, mutate: refresh, isLoading } = useApiClient(CompanyEndpoints.getCompanyTranslations(), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const refreshTranslations = useCallback(() => __async(void 0, null, function* () {
    if (refresh) yield refresh();
  }), [refresh]);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, children: /* @__PURE__ */ jsx(SettingsSectionContent, { title: polyglot.t("SettingsRouterPageConfig.translations"), contentWidth: "100%", children: /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      sections: [
        {
          contentWidth: "100%",
          items: [
            {
              type: SectionItemType.TextLine,
              value: "If your company functions in multiple languages and you would like to translate information entered by yourself and other employees, you can add translations for specific words or phrases here. Note that Zelt always stores what is entered by employees as is, and that translations are only applied afterwards into the language set in the personal account settings."
            },
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsx(TranslationsTable, { translations: translations != null ? translations : [], refreshTranslations })
            }
          ]
        }
      ]
    }
  ) }) });
};
const TranslationsTable = ({
  translations,
  refreshTranslations
}) => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isAddNewOpen, setIsAddNewOpen] = useState(false);
  const filteredTranslations = useMemo(() => {
    if (!translations) return [];
    if (!searchInput) return translations;
    const search = searchInput.toLowerCase();
    return translations.filter(
      (w) => {
        var _a, _b, _c, _d, _e, _f, _g;
        return ((_a = w.en) == null ? void 0 : _a.toLowerCase().startsWith(search)) || ((_b = w.it) == null ? void 0 : _b.toLowerCase().startsWith(search)) || ((_c = w.ar) == null ? void 0 : _c.toLowerCase().startsWith(search)) || ((_d = w.es) == null ? void 0 : _d.toLowerCase().startsWith(search)) || ((_e = w.de) == null ? void 0 : _e.toLowerCase().startsWith(search)) || ((_f = w.pt) == null ? void 0 : _f.toLowerCase().startsWith(search)) || ((_g = w.fr) == null ? void 0 : _g.toLowerCase().startsWith(search));
      }
    );
  }, [translations, searchInput]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", justifyContent: "space-between", alignItems: "center" }, spacing.pt20), children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ) }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: () => {
              setIsImportOpen(true);
            },
            sizeVariant: "small",
            colorVariant: "secondary",
            children: polyglot.t("General.import")
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => {
              setIsAddNewOpen(true);
            },
            children: polyglot.t("General.add")
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(TranslationTable, { translations: filteredTranslations, refreshTable: refreshTranslations }),
    /* @__PURE__ */ jsx(
      TranslationDrawer,
      {
        isOpen: isAddNewOpen,
        setIsOpen: setIsAddNewOpen,
        translation: null,
        onClose: () => {
        },
        refresh: refreshTranslations
      }
    ),
    isImportOpen && /* @__PURE__ */ jsx(
      CompanyTranslationsCsvUploadDrawer,
      {
        isOpen: isImportOpen,
        setIsOpen: setIsImportOpen,
        refresh: refreshTranslations
      }
    )
  ] });
};
