"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { activeTabsFilterBtnSx, tabsFilterBtnSx } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const EditAppAccount = ({ app, defaultScheduleOffset, user, onFinish }) => {
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [showMessage] = useMessage();
  const addDays = useCallback((date, days) => {
    const ms = new LocalDate(date).getDate().getTime() + days * (24 * 60 * 6e4);
    return new LocalDate(new Date(ms)).toDateString();
  }, []);
  const maxDate = useCallback((date1, date2) => date1 < date2 ? date2 : date1, []);
  const { startDate } = user;
  const formik = useFormik({
    initialValues: {
      activationDate: maxDate(
        startDate ? addDays(startDate, defaultScheduleOffset) : todaysDateShortISOString(),
        todaysDateShortISOString()
      )
    },
    onSubmit: (values) => __async(void 0, null, function* () {
      setCreatingAccount(true);
      let params;
      const actionDate = values.activationDate === todaysDateShortISOString() ? void 0 : values.activationDate;
      try {
        yield AppIntegrationAPI.createAppUser(app.stub, user.userId, params, actionDate);
        onFinish();
      } catch (error) {
        showMessage(`Failed to set up account. ${nestErrorMessage(error)}`, "error");
        setCreatingAccount(false);
      }
    })
  });
  const schedulePresets = useMemo(
    () => ({
      "Start day": 0,
      "Day before start": -1,
      "Week before start": -7
    }),
    []
  );
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.mb10 }), children: "Create account" }),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx("img", { src: `/app-icons-v2/images/${app.stub}.png`, style: { maxWidth: "20px", maxHeight: "20px" }, alt: "" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: app.name })
      ] }),
      startDate && /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", gap: spacing.g10, flexWrap: "wrap" }, children: Object.entries(schedulePresets).map(([label, offset]) => {
        const buttonDate = addDays(startDate, offset);
        if (buttonDate < todaysDateShortISOString()) return /* @__PURE__ */ jsx(Fragment, {});
        return /* @__PURE__ */ jsx(
          Button,
          {
            sx: formik.values.activationDate === buttonDate ? activeTabsFilterBtnSx : tabsFilterBtnSx,
            disabled: creatingAccount,
            onClick: () => {
              formik.setFieldValue("activationDate", buttonDate);
            },
            children: label
          },
          label
        );
      }) }),
      /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          name: "activationDate",
          label: "Activation date",
          onChange: (value) => formik.setFieldValue("activationDate", value),
          value: formik.values.activationDate,
          disabled: creatingAccount,
          shortcuts: true,
          disablePast: true
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          style: { marginTop: "40px" },
          loading: creatingAccount,
          name: "Add",
          fullWidth: true,
          colorVariant: "primary",
          sizeVariant: "large"
        }
      )
    ] })
  ] }) });
};
