"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { CompanyEndpoints } from "@/api-client/company.api";
import { CompanySettingsGeneral } from "@/v2/feature/company/company-settings/features/components/company-settings/company-settings-general.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useJune } from "@/v2/infrastructure/june/june.hook";
export const CompanySettingsPage = () => {
  const { trackPage } = useJune();
  const {
    data: generalSettings,
    isValidating: loadingGeneralSettings,
    mutate: refreshGeneralSettings
  } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  useEffect(() => {
    trackPage("Company settings");
  }, []);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: loadingGeneralSettings, children: /* @__PURE__ */ jsx(CompanySettingsGeneral, { generalSettings: generalSettings != null ? generalSettings : null, refreshSettings: refreshGeneralSettings }) });
};
