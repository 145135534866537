"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class IDCheckAPI {
  static updateIndividualCheck(data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/individual-checks/${data.id}`, data);
    });
  }
  static updatePackage(id, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/id-check-package/${id}`, data);
    });
  }
  static createPackage(data) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/id-check-package`, data);
    });
  }
  static deletePackageById(packageId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/id-check-package/${packageId}`);
    });
  }
}
export class IdCheckEndpoints {
  static getIdCheckPackages() {
    return {
      url: `/apiv2/id-check-package`
    };
  }
  static getIndividualChecks() {
    return {
      url: `/apiv2/individual-checks`
    };
  }
}
