"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { BillingInformationSection } from "@v2/feature/billing/components/billing-information-section.component";
import { BillingOverview } from "@v2/feature/billing/components/billing-overview.component";
import { spacing } from "@v2/styles/spacing.styles";
import { BillingSubscriptionAPI } from "@/api-client/billing-subscription.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { BillingInvoices } from "@/v2/feature/billing/components/billing-invoices.component";
import { SubscriptionSection } from "@/v2/feature/billing/components/subscription-section.component";
import { CompanySubscriptionBillingOverview } from "@/v2/feature/billing/components/v2-company-subscription-billing-overview.component";
import { CompanySubscriptionSection } from "@/v2/feature/billing/components/v2-company-subscription-section.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
var CheckoutSessionStatus = /* @__PURE__ */ ((CheckoutSessionStatus2) => {
  CheckoutSessionStatus2["success"] = "success";
  CheckoutSessionStatus2["cancelled"] = "cancelled";
  return CheckoutSessionStatus2;
})(CheckoutSessionStatus || {});
var PaymentMethodSetupStatus = /* @__PURE__ */ ((PaymentMethodSetupStatus2) => {
  PaymentMethodSetupStatus2["setup_success"] = "setup_success";
  PaymentMethodSetupStatus2["setup_cancelled"] = "setup_cancelled";
  return PaymentMethodSetupStatus2;
})(PaymentMethodSetupStatus || {});
const CHECKOUT_SUCCESS_MSG = "Charge was successful. Subscription will be activated on payment confirmation. \nThis takes a few seconds.";
const CHECKOUT_CANCELLED_MSG = "Upgrade cancelled successfully.";
const PAYMENT_METHOD_SUCCESS_MSG = "Payment method is being setup. Card would reflect under billing section once completed.";
const PAYMENT_METHOD_CANCELLED_MSG = "Payment method setup has been cancelled.";
export const BillingSettingsPage = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const [showMessage] = useMessage();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionStats, setSubscriptionStats] = useState(null);
  const [subscriptionsBillingStats, setSubscriptionsBillingStats] = useState(null);
  const [subscriptionDetailsForCompany, setSubscriptionDetailsForCompany] = useState(
    null
  );
  const [loading, setLoading] = useState(false);
  const { trackPage } = useJune();
  const { hasScopes } = useScopes();
  const scopesContext = { userId: user.userId };
  const isAdminUser = hasScopes(["company.settings:all"], scopesContext);
  const { data: downgradeViability } = useApiClient(
    isAdminUser ? CompanyEndpoints.getDowngradeViability() : { url: void 0 },
    { suspense: false }
  );
  const defaultProductDowngradeViability = { disableDowngrade: false, disabledDescription: "" };
  const defaultViability = {
    APPS: defaultProductDowngradeViability,
    DEVICES: defaultProductDowngradeViability,
    MONEY: defaultProductDowngradeViability,
    PEOPLE: defaultProductDowngradeViability
  };
  const refresh = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const [
        companyBillingSubscriptions,
        subscriptionStats2,
        billingStats,
        companySubscriptionDetails
      ] = yield Promise.all([
        BillingSubscriptionAPI.getCompanySubscriptions(),
        BillingSubscriptionAPI.getSubscriptionStats(),
        BillingSubscriptionAPI.getSubscriptionBillingStats(),
        BillingSubscriptionAPI.getCompanySubscriptionDetails()
      ]);
      setSubscriptionsBillingStats(billingStats);
      setSubscriptions(companyBillingSubscriptions);
      setSubscriptionStats(subscriptionStats2);
      setSubscriptionDetailsForCompany(companySubscriptionDetails);
      if (getSubscriptionStatus("status") === "success" /* success */) {
        showMessage(CHECKOUT_SUCCESS_MSG, "success", "top-right", markNotificationAsRead);
      }
      if (getSubscriptionStatus("status") === "cancelled" /* cancelled */) {
        showMessage(CHECKOUT_CANCELLED_MSG, "info", "top-right", markNotificationAsRead);
      }
      if (getSubscriptionStatus("status") === "setup_success" /* setup_success */) {
        showMessage(PAYMENT_METHOD_SUCCESS_MSG, "success", "top-right", markNotificationAsRead);
      }
      if (getSubscriptionStatus("status") === "setup_cancelled" /* setup_cancelled */) {
        showMessage(PAYMENT_METHOD_CANCELLED_MSG, "info", "top-right", markNotificationAsRead);
      }
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
    setLoading(false);
  }), [showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const markNotificationAsRead = () => {
    const urlWithoutQuery = window.location.href.toString().split("?")[0];
    window.history.pushState({}, "", urlWithoutQuery);
  };
  const getSubscriptionStatus = (field) => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get(field);
  };
  useEffect(() => {
    trackPage("Company billing settings");
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Billing" }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: {}, children: [
      !(subscriptionDetailsForCompany == null ? void 0 : subscriptionDetailsForCompany.migratedToNewPlans) ? /* @__PURE__ */ jsxs(Fragment, { children: [
        subscriptionStats && subscriptions && /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m40 }, children: /* @__PURE__ */ jsx(
          BillingOverview,
          {
            subscriptionStats,
            subscriptionsBillingStats
          }
        ) }),
        subscriptionStats && subscriptions && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g80 }, children: [
          /* @__PURE__ */ jsx(
            SubscriptionSection,
            {
              subscriptions,
              subscriptionsBillingStats,
              subscriptionsStats: subscriptionStats,
              downgradeViability: downgradeViability != null ? downgradeViability : defaultViability,
              refresh
            }
          ),
          /* @__PURE__ */ jsx(BillingInformationSection, {})
        ] })
      ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
        subscriptionStats && subscriptions && /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m40 }, children: /* @__PURE__ */ jsx(CompanySubscriptionBillingOverview, { subscriptionDetailsForCompany }) }),
        subscriptionStats && subscriptions && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g80 }, children: [
          /* @__PURE__ */ jsx(
            CompanySubscriptionSection,
            {
              subscriptionDetailsForCompany,
              refresh
            }
          ),
          /* @__PURE__ */ jsx(BillingInformationSection, {})
        ] })
      ] }),
      /* @__PURE__ */ jsx(BillingInvoices, { subscriptionDetailsForCompany })
    ] })
  ] });
};
