"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { HolidayCalendarRouter } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar-details.router";
import { HolidayCalendarEndpoints } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { HolidayCalendarsListPage } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/pages/holiday-calendars-list.page";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE, SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE } from "@/lib/routes";
export const AbsenceSettingsPublicHolidaysRouter = () => {
  const {
    data: holidayCalendars,
    mutate: refreshHolidayCalendars,
    isLoading
  } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendars(), { suspense: false });
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshHolidayCalendars) yield refreshHolidayCalendars();
  }), [refreshHolidayCalendars]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_HOLIDAY_CALENDAR_DETAILS_ROUTE, children: /* @__PURE__ */ jsx(HolidayCalendarRouter, { refreshAll: refresh }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["absence:all"], path: SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE, children: /* @__PURE__ */ jsx(HolidayCalendarsListPage, { holidayCalendars: holidayCalendars != null ? holidayCalendars : [], refresh, isLoading: !!isLoading }) }),
    /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE })
  ] });
};
