"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { PayrunHeaderMenu } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header-menu.component";
import { PayrunStatus } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component";
import { navigateToPayrunsView } from "@/v2/feature/payroll/payroll-router.util";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
export const PayrunHeader = ({ payrollId, payrollType, state, onReopenPayrun, sx }) => {
  const routerHistory = useHistory();
  const isClosed = state !== PayrunStates.draft;
  return /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", alignItems: "center", sx, children: [
    /* @__PURE__ */ jsx(PayrunStatus, { state }),
    /* @__PURE__ */ jsx(
      PayrunHeaderMenu,
      {
        payrollType,
        canReopenPayrun: isClosed,
        canDeletePayrun: !isClosed,
        onReopenPayrun,
        onDeletePayrun: () => {
          navigateToPayrunsView(routerHistory, "replace", payrollId);
        },
        sx: { ml: "auto" }
      }
    )
  ] });
};
