"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SingleAnswerQuestionReadonly = ({
  questionText,
  options,
  hasComment,
  isCommentRequired
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
    /* @__PURE__ */ jsx(FormControl, { component: "fieldset", sx: { width: "100%", margin: 0 }, children: /* @__PURE__ */ jsx(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label", name: "answer", "aria-disabled": true, children: options && Object.keys(options).map((a) => /* @__PURE__ */ jsx(
      FormControlLabel,
      {
        labelPlacement: "end",
        value: a,
        disabled: true,
        checked: false,
        control: /* @__PURE__ */ jsx(StyledRadio, {}),
        label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: options[+a] }),
        sx: {
          py: spacing.p10,
          margin: 0,
          gap: spacing.g10,
          px: 0,
          "& span.MuiButtonBase-root.MuiRadio-root": {
            padding: 0
          }
        }
      },
      a
    )) }) }),
    hasComment && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g8,
          pt: spacing.p16,
          borderTop: `${borders.background}`
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Add comment" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: isCommentRequired ? "Required" : "Optional" })
          ] }),
          /* @__PURE__ */ jsx(RichTextField, { value: null, disabled: true })
        ]
      }
    )
  ] });
};
