"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { AddChecklistTask } from "@v2/feature/task/subfeature/checklist/pages/add-checklist.page";
import { ChecklistsPage } from "@v2/feature/task/subfeature/checklist/pages/checklists.page";
import { TasksChecklistOverview } from "@v2/feature/task/subfeature/checklist/pages/manage-checklist.page";
import { Route, Switch } from "react-router-dom";
import {
  SETTINGS_TASK_CHECKLISTS_ADD_CHECKLIST_ROUTE,
  SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE,
  SETTINGS_TASK_CHECKLIST_ROUTE
} from "@/lib/routes";
export const ChecklistRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_TASK_CHECKLISTS_ADD_CHECKLIST_ROUTE, children: /* @__PURE__ */ jsx(AddChecklistTask, {}) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, children: /* @__PURE__ */ jsx(TasksChecklistOverview, {}) }),
    /* @__PURE__ */ jsx(Route, { path: SETTINGS_TASK_CHECKLIST_ROUTE, children: /* @__PURE__ */ jsx(ChecklistsPage, {}) })
  ] });
};
