"use strict";
import { useEffect } from "react";
export function useScrollToFormError(formik) {
  useEffect(() => {
    if (!formik.isSubmitting) return;
    for (const fieldName in formik.errors) {
      const el = document.getElementsByName(fieldName);
      if (el == null ? void 0 : el[0]) {
        el[0].scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center"
        });
        return;
      }
    }
  }, [formik.errors, formik.isSubmitting]);
}
