"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SiteViewModal = ({
  isOpen,
  setIsOpen,
  selectedSite,
  closePage,
  setIsFormOpen,
  refreshSites
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose: closePage, children: /* @__PURE__ */ jsx(
    SiteViewModalContent,
    {
      selectedSite,
      setIsFormOpen,
      refreshSites,
      closePage
    }
  ) });
};
const SiteViewModalContent = ({
  selectedSite,
  setIsFormOpen,
  refreshSites,
  closePage
}) => {
  var _a, _b;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState();
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const deleteSite = () => __async(void 0, null, function* () {
    try {
      if (siteToDelete) yield SiteAPI.deleteSite(siteToDelete);
      else throw new Error(polyglot.t("SiteManagementDrawerPage.errorMessages.noSitetoDelete"));
      showMessage(polyglot.t("SiteManagementDrawerPage.successMessages.deleteSite"), "success");
      yield refreshSites();
    } catch (error) {
      showMessage(
        `${polyglot.t("SiteManagementDrawerPage.errorMessages.deleteSite")}: ${nestErrorMessage(error)}`,
        "error"
      );
    }
  });
  const confirmDelete = (event, siteId) => {
    if (event) setAnchorEl(event.currentTarget);
    setSiteToDelete(siteId);
    setIsConfirmModalOpen(true);
  };
  if (!selectedSite) {
    return /* @__PURE__ */ jsx(Box, { sx: drawerContentSx, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Site not found" }) });
  }
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: selectedSite == null ? void 0 : selectedSite.name }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Members" }),
      (selectedSite == null ? void 0 : selectedSite.users) && ((_a = selectedSite == null ? void 0 : selectedSite.users) == null ? void 0 : _a.length) > 0 ? /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            maxHeight: "69.5vh",
            overflowY: "auto"
          },
          children: (_b = selectedSite == null ? void 0 : selectedSite.users) == null ? void 0 : _b.map((eachUser) => {
            return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(UserCell, { userId: eachUser.userId, avatarSize: "xxsmall", nameVariant: "caption" }, eachUser.userId) }, eachUser.userId);
          })
        }
      ) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "No members" })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          colorVariant: "secondary",
          sizeVariant: "medium",
          onClick: (event) => {
            if (selectedSite) confirmDelete(event, selectedSite.id);
            else showMessage(polyglot.t("SiteManagementDrawerPage.errorMessages.noSiteSelected"), "error");
          },
          children: "Delete"
        }
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          colorVariant: "secondary",
          sizeVariant: "medium",
          onClick: () => {
            setIsFormOpen(true);
          },
          children: "Edit"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isConfirmModalOpen,
        onClose: () => {
          setIsConfirmModalOpen(false);
        },
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (siteToDelete) {
            setIsConfirmModalOpen(false);
            closePage();
            yield deleteSite();
          } else showMessage(polyglot.t("SiteManagementDrawerPage.errorMessages.noSiteSelected"), "error");
        }),
        message: polyglot.t("SiteManagementDrawerPage.deleteSiteMessage"),
        callToAction: "Yes"
      }
    )
  ] });
};
