"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOrderStatus, DevicePossessionType, DeviceTransitStatus } from "@v2/feature/device/device.interface";
import { AddExistingDeviceDrawer } from "@v2/feature/device/features/devices-company/components/add-existing-device-drawer.component";
import { MyDevicesEmptyOverview } from "@v2/feature/device/features/my-devices/components/my-devices-empty-overview.component";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { NoAppliedPolicies } from "@/v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { MyDevicesActive } from "@/v2/feature/device/features/my-devices/components/my-devices-active.component";
import { MyDevicesOrders } from "@/v2/feature/device/features/my-devices/components/my-devices-orders.component";
import { MyDevicesReturns } from "@/v2/feature/device/features/my-devices/components/my-devices-returns.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MyDevicesOverviewPage = ({
  deviceTransits,
  deviceOrders,
  refreshDevicesData
}) => {
  const [loading, setLoading] = useState(false);
  const [isAddExistingOpen, setIsAddExistingOpen] = useState(false);
  const [devicePossessions, setDevicePossessions] = useState([]);
  const [appliedZeltPolicies, setAppliedZeltPolicies] = useState(
    NoAppliedPolicies
  );
  const [
    appliedZeltMobilePolicies,
    setAppliedZeltMobilePolicies
  ] = useState(NoAppliedPolicies);
  const [appliedHexPolicies, setAppliedHexPolicies] = useState(NoAppliedPolicies);
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [showMessage] = useMessage();
  const hasDevicesEnrollScope = hasScopes(["devices.enroll", "devices:all"], scopesContext);
  const refreshLocalDevices = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [devicePossessions2, zeltPolicies, zeltMobilePolicies, hexPolicies] = yield Promise.all([
        DeviceAPI.getActiveDevicePossessionsByUserId(userId),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile(),
        DeviceAPI.getAppliedCompanyDevicePolicies()
      ]);
      setDevicePossessions(devicePossessions2);
      setAppliedZeltPolicies(zeltPolicies);
      setAppliedZeltMobilePolicies(zeltMobilePolicies);
      setAppliedHexPolicies(hexPolicies);
    } catch (error) {
      showMessage("Could not load user devices list", "error");
    } finally {
      setLoading(false);
    }
  }), [userId, showMessage]);
  useEffect(() => {
    refreshLocalDevices();
  }, [refreshLocalDevices]);
  const notifiableDeviceOrders = deviceOrders.some(
    (deviceOrder) => [
      DeviceOrderStatus.Requested,
      DeviceOrderStatus.Placed,
      DeviceOrderStatus.Accepted,
      DeviceOrderStatus.Shipping
    ].includes(deviceOrder.status)
  );
  const validShippingOrders = deviceOrders.filter((d) => d.status !== DeviceOrderStatus.Cancelled);
  const nonOrderTransits = deviceTransits.filter((deviceTransit) => {
    var _a;
    return (deviceTransit.status === DeviceTransitStatus.Pending || deviceTransit.status === DeviceTransitStatus.Shipping) && ((_a = deviceTransit.sender) == null ? void 0 : _a.possessionType) !== DevicePossessionType.ZeltStock;
  });
  const notifiableDeviceTransits = Boolean((nonOrderTransits == null ? void 0 : nonOrderTransits.length) > 0);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Devices" }),
        showAction: (devicePossessions == null ? void 0 : devicePossessions.length) > 0 || notifiableDeviceOrders || notifiableDeviceTransits,
        actions: /* @__PURE__ */ jsxs(ScopesControl, { scopes: ["devices.enroll", "devices:all"], context: scopesContext, children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "secondary",
              sizeVariant: "small",
              onClick: () => setIsAddExistingOpen(true),
              disabled: !hasDevicesEnrollScope,
              children: "Add existing"
            }
          ),
          isAddExistingOpen && /* @__PURE__ */ jsx(
            AddExistingDeviceDrawer,
            {
              isOpen: isAddExistingOpen,
              setIsOpen: setIsAddExistingOpen,
              refresh: () => __async(void 0, null, function* () {
                yield refreshDevicesData();
                yield refreshLocalDevices();
                setIsAddExistingOpen(false);
              })
            }
          )
        ] })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, sx: { pt: spacing.p30 }, children: (devicePossessions == null ? void 0 : devicePossessions.length) > 0 || notifiableDeviceOrders || notifiableDeviceTransits ? /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "column", lg: "row", xl: "row" },
          justifyContent: "space-between",
          gap: spacing.g60
        },
        children: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "70%", xl: "70%" } }, children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g60
            },
            children: [
              (validShippingOrders == null ? void 0 : validShippingOrders.length) > 0 && /* @__PURE__ */ jsx(MyDevicesOrders, { deviceOrders: validShippingOrders, refreshDevicesData }),
              (nonOrderTransits == null ? void 0 : nonOrderTransits.length) > 0 && /* @__PURE__ */ jsx(
                MyDevicesReturns,
                {
                  deviceTransits: nonOrderTransits,
                  refreshDevicesData,
                  userId
                }
              ),
              (devicePossessions == null ? void 0 : devicePossessions.length) > 0 && /* @__PURE__ */ jsx(
                MyDevicesActive,
                {
                  devicePossessions,
                  appliedZeltPolicies,
                  appliedZeltMobilePolicies,
                  appliedHexPolicies,
                  userId
                }
              )
            ]
          }
        ) })
      }
    ) : /* @__PURE__ */ jsx(
      MyDevicesEmptyOverview,
      {
        refresh: () => __async(void 0, null, function* () {
          yield refreshDevicesData();
          yield refreshLocalDevices();
          setIsAddExistingOpen(false);
        })
      }
    ) })
  ] });
};
