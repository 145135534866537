"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { APPS_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { activeTabsFilterBtnSx, tabsFilterBtnSx } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditApps = (props) => {
  const { polyglot } = usePolyglot();
  const { onSave, config } = props;
  const [apps, setApps] = useState();
  const [selectedStubs, setSelectedStubs] = useState(
    new Set(config == null ? void 0 : config.stubs)
  );
  const routerHistory = useHistory();
  const [creationDateOffset, setCreationDateOffset] = useState(config == null ? void 0 : config.scheduleOffsetFromStartDate);
  const [before, setBefore] = useState(
    Boolean((config == null ? void 0 : config.scheduleOffsetFromStartDate) && (config == null ? void 0 : config.scheduleOffsetFromStartDate) < 0)
  );
  const adminApps = [
    "xero",
    "quickbooks",
    "teamtailor",
    "remote",
    "greenhouse",
    "workable",
    "deel",
    "oysterhr",
    "lever",
    "hubspot"
  ];
  const refreshAppState = useCallback(() => __async(void 0, null, function* () {
    const [available, installed] = yield Promise.all([
      AppIntegrationAPI.availableApps(),
      AppIntegrationAPI.getInstalledApps()
    ]);
    available.sort((a, b) => a.name.localeCompare(b.name, void 0, { sensitivity: "base" }));
    setApps({
      available,
      installedStubs: new Set(installed.filter((app) => !app.invalidatedAt).map((app) => app.stub))
    });
  }), []);
  useEffect(() => {
    refreshAppState();
  }, [refreshAppState]);
  const appsToShow = useMemo(
    () => apps == null ? void 0 : apps.available.filter(
      (app) => (
        // display conencted apps which allow us to create accounts
        apps.installedStubs.has(app.stub) && app.allowsGiveAccess || // if the user is editing an existing template, we need to show the apps currently configured in
        // the template, even if they are not currently authorised
        (config == null ? void 0 : config.stubs.includes(app.stub))
      )
    ),
    [apps, config == null ? void 0 : config.stubs]
  );
  if (appsToShow && appsToShow.length === 0) {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: polyglot.t("EditApps.appAccounts") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.mt10 }), children: polyglot.t("EditApps.automateAccountCreation") }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          onClick: () => routerHistory.push(APPS_COMPANY_OVERVIEW_ROUTE),
          sizeVariant: "medium",
          colorVariant: "primary",
          style: { marginTop: "40px" },
          children: polyglot.t("EditApps.goToApps")
        }
      )
    ] });
  }
  const hasSelectedApps = selectedStubs.size > 0;
  const hasSelectedCreationDate = typeof creationDateOffset === "number";
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.title2, children: [
      " ",
      polyglot.t("EditApps.appAccounts")
    ] }),
    appsToShow && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.mt10 }), children: polyglot.t("EditApps.selectApps") }),
    /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt30, gap: spacing.g20 }, children: [
      !appsToShow && /* @__PURE__ */ jsx(LoadingSpinner, {}),
      apps && (appsToShow == null ? void 0 : appsToShow.map(({ stub, name }) => /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.s1 }, children: [
            /* @__PURE__ */ jsx(
              "img",
              {
                src: `/app-icons-v2/images/${stub}.png`,
                style: { maxHeight: "20px", maxWidth: "20px" },
                alt: name
              }
            ),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: name }),
            adminApps.includes(stub) && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "(Administrator role)" }),
            !apps.installedStubs.has(stub) && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, title: polyglot.t("EditApps.appNotConnected"), children: "\u26A0\uFE0F" })
          ] }),
          labelPlacement: "end",
          checked: selectedStubs.has(stub),
          onChange: (_, checked) => {
            const stubs = new Set(selectedStubs);
            stubs[checked ? "add" : "delete"](stub);
            setSelectedStubs(stubs);
          }
        },
        stub
      )))
    ] }),
    (hasSelectedApps || hasSelectedCreationDate) && /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt40, gap: spacing.g15, flex: 0 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("EditApps.creationDateMex") }),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          Button,
          {
            sx: creationDateOffset === 0 ? activeTabsFilterBtnSx : tabsFilterBtnSx,
            onClick: () => {
              setCreationDateOffset(0);
              setBefore(false);
            },
            children: polyglot.t("EditApps.startDay")
          }
        ),
        /* @__PURE__ */ jsx(
          Button,
          {
            sx: creationDateOffset && creationDateOffset < 0 ? activeTabsFilterBtnSx : tabsFilterBtnSx,
            onClick: () => {
              setCreationDateOffset(-1);
              setBefore(true);
            },
            children: polyglot.t("EditApps.before")
          }
        )
      ] }),
      config && before && /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        __spreadProps(__spreadValues({}, props), {
          fullWidth: true,
          value: creationDateOffset,
          variant: "standard",
          type: "number",
          onChange: (event) => {
            const value = Number(event.target.value);
            setCreationDateOffset(value);
          },
          label: polyglot.t("EditApps.daysBefore"),
          validateNumber: true,
          endAdornment: "none",
          sx: {
            "& div.MuiInputBase-root.MuiInput-root": {
              borderBottomRightRadius: "0px !important",
              borderTopRightRadius: "0px !important"
            }
          }
        })
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        type: "button",
        fullWidth: true,
        disabled: !hasSelectedApps && !hasSelectedCreationDate,
        onClick: () => {
          const stubs = [...selectedStubs];
          if (typeof creationDateOffset !== "number" || !stubs.length) {
            return;
          }
          onSave({
            stubs,
            scheduleOffsetFromStartDate: creationDateOffset <= 0 ? creationDateOffset : -creationDateOffset
          });
        },
        colorVariant: "primary",
        sizeVariant: "large",
        style: {
          marginTop: "40px",
          visibility: hasSelectedApps && hasSelectedCreationDate ? "visible" : "hidden"
        },
        children: polyglot.t("General.save")
      }
    )
  ] });
};
