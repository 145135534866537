"use strict";
import { Extension } from "@tiptap/core";
import { DOMSerializer } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { cleanHTML } from "@/v2/components/forms/document-editor/document-editor.util";
const handleCutCopy = (view, event) => {
  var _a, _b;
  const { selection } = view.state;
  const { $from, $to } = selection;
  const fragment = view.state.doc.slice($from.pos, $to.pos);
  const serializer = DOMSerializer.fromSchema(view.state.schema);
  const div = document.createElement("div");
  div.appendChild(serializer.serializeFragment(fragment.content));
  const innerHTML = cleanHTML(div.innerHTML);
  div.innerHTML = innerHTML;
  (_a = event.clipboardData) == null ? void 0 : _a.setData("text/html", innerHTML);
  (_b = event.clipboardData) == null ? void 0 : _b.setData("text/plain", (div == null ? void 0 : div.textContent) || "");
  event.preventDefault();
};
export const CopyExtension = Extension.create({
  name: "copy",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("copy"),
        props: {
          handleDOMEvents: {
            copy(view, event) {
              handleCutCopy(view, event);
              return false;
            },
            cut(view, event) {
              handleCutCopy(view, event);
              const transaction = view.state.tr.delete(view.state.selection.from, view.state.selection.to);
              view.dispatch(transaction);
              return false;
            }
          }
        }
      })
    ];
  }
});
