"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  JiraAdminEmailInstructions,
  JiraApiTokenInstructions,
  JiraSiteUrlInstructions
} from "@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-jira.component";
import {
  LastpassAccountIdInstructions,
  LastpassInfoCard,
  LastpassProvHashInstructions
} from "@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-lastpass.component";
import { MondayApiKeyInstructions } from "@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-monday.component";
import {
  NotionInfoCard,
  NotionInstallInstructions
} from "@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-notion.component";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { AppDetailsAPI } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { JumpcloudApiKeyInstructions } from "@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-jumpcloud.component";
import { RemoteApiTokenInstructions } from "@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-remote.component";
import { TeamtailorApiKeyInstructions } from "@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-teamtailor.component";
import { buttonBoxSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const appCredentialsInstructions = {
  notion: { token: /* @__PURE__ */ jsx(NotionInstallInstructions, {}) },
  lastpass: {
    cid: /* @__PURE__ */ jsx(LastpassAccountIdInstructions, {}),
    provhash: /* @__PURE__ */ jsx(LastpassProvHashInstructions, {})
  },
  monday: {
    apikey: /* @__PURE__ */ jsx(MondayApiKeyInstructions, {})
  },
  jumpcloud: {
    token: /* @__PURE__ */ jsx(JumpcloudApiKeyInstructions, {})
  },
  jira: {
    token: /* @__PURE__ */ jsx(JiraApiTokenInstructions, {}),
    siteUrl: /* @__PURE__ */ jsx(JiraSiteUrlInstructions, {}),
    adminEmail: /* @__PURE__ */ jsx(JiraAdminEmailInstructions, {})
  },
  teamtailor: {
    apikey: /* @__PURE__ */ jsx(TeamtailorApiKeyInstructions, {})
  },
  remote: {
    apitoken: /* @__PURE__ */ jsx(RemoteApiTokenInstructions, {})
  }
};
const appInfoCards = {
  notion: /* @__PURE__ */ jsx(NotionInfoCard, {}),
  lastpass: /* @__PURE__ */ jsx(LastpassInfoCard, {})
};
export const AppBasicAuthConnectForm = ({ currentUser, appDetails }) => {
  var _a;
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const [credentialsPlaceholders, setCredentialsPlaceholders] = useState(void 0);
  const [credentials, setCredentials] = useState(void 0);
  const { mutate: refreshInstalledApps } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), {
    suspense: false
  });
  const [credsTestPassed, setCredsTestPassed] = useState(true);
  const INVALID_CREDENTIALS_ERROR = "These API credentials are not working. Please generate another set and try again.";
  const PREFILLED_CREDENTIALS = ["adminEmail", "zeltCompanyId"];
  const updateCredentials = useCallback(
    (key, value) => {
      setCredentials(__spreadProps(__spreadValues({}, credentials), {
        [key]: value
      }));
    },
    [credentials]
  );
  useEffect(() => {
    var _a2;
    if (appDetails == null ? void 0 : appDetails.authorised) {
      setCredentialsPlaceholders((_a2 = appDetails == null ? void 0 : appDetails.credentialsPlaceholders) != null ? _a2 : void 0);
      setCredentials(void 0);
    }
    if (appDetails && APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION.includes(appDetails.stub) && currentUser && currentUser.emailAddress) {
      updateCredentials("adminEmail", currentUser.emailAddress);
    }
  }, [appDetails, currentUser]);
  function areCredentialsCompleted() {
    var _a2, _b;
    if (!credentials) {
      return false;
    } else {
      if (appDetails && ((_a2 = appDetails.directory) == null ? void 0 : _a2.credentials)) {
        for (let cred of (_b = appDetails == null ? void 0 : appDetails.directory) == null ? void 0 : _b.credentials) {
          if (cred) {
            const propName = cred.name || void 0;
            if (!credentials[propName] && cred.name && !PREFILLED_CREDENTIALS.includes(cred.name)) return false;
          }
        }
      }
    }
    return true;
  }
  function upsertCredentials(credParams) {
    return __async(this, null, function* () {
      try {
        if (credParams && (appDetails == null ? void 0 : appDetails.stub)) {
          yield AppDetailsAPI.saveOrUpdateIntegrationCredentials(appDetails == null ? void 0 : appDetails.stub, credParams);
          const app = yield AppDetailsAPI.getIntegrationData(appDetails == null ? void 0 : appDetails.stub);
          refreshInstalledApps();
          setTimeout(
            () => routerHistory.push(
              generatePath(APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, {
                appStub: appDetails == null ? void 0 : appDetails.stub
              }),
              { app, needToRefreshDirectory: true }
            ),
            700
          );
        }
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
      } finally {
      }
    });
  }
  function testBasicAuthCredentials() {
    return __async(this, null, function* () {
      try {
        if (credentials && (appDetails == null ? void 0 : appDetails.stub)) {
          const testResult = yield AppDetailsAPI.testBasicAuthCredentials(appDetails == null ? void 0 : appDetails.stub, credentials);
          setCredsTestPassed(!!testResult);
          return testResult;
        }
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
      }
    });
  }
  const basicAuthSuccess = (result) => {
    return result && typeof result === "object" && "onBehalfOf" in result && "apikey" && result ? true : false;
  };
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: (_values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const result = yield testBasicAuthCredentials();
        if (result) {
          if (basicAuthSuccess(result) && (appDetails == null ? void 0 : appDetails.stub) === "greenhouse") {
            yield upsertCredentials(__spreadValues(__spreadValues({}, credentials), result));
          } else {
            yield upsertCredentials(__spreadValues({}, credentials));
          }
          showMessage(
            (appDetails == null ? void 0 : appDetails.authorised) ? "You have successfully updated your app credentials." : "You have successfully connected the app.",
            "success"
          );
        }
      } catch (error) {
        showMessage(`Encountered an error when trying to connect the app: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const appCredentialType = useMemo(() => {
    var _a2, _b, _c;
    if (((_a2 = appDetails == null ? void 0 : appDetails.directory) == null ? void 0 : _a2.credentials) && ((_b = appDetails == null ? void 0 : appDetails.directory) == null ? void 0 : _b.credentials.length) > 0)
      return (_c = appDetails == null ? void 0 : appDetails.directory) == null ? void 0 : _c.credentials[0].label;
    else return "an API key";
  }, [(_a = appDetails == null ? void 0 : appDetails.directory) == null ? void 0 : _a.credentials]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "title2", children: [
      "Enter ",
      appCredentialType
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.m10 }, children: appDetails && (appDetails == null ? void 0 : appDetails.stub) && appInfoCards[appDetails.stub] }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m50 }, children: appDetails && appDetails.directory && appDetails.directory.credentials && appDetails.directory.credentials.map((credential) => {
        var _a2, _b, _c, _d;
        if (credential.name !== "zeltCompanyId")
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: credential.name,
              label: credential.label,
              disabled: credential.name === "adminEmail" && APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION.includes(appDetails == null ? void 0 : appDetails.stub),
              required: true,
              placeholder: credentialsPlaceholders ? (_a2 = credentialsPlaceholders[(credential == null ? void 0 : credential.name) || ""]) == null ? void 0 : _a2.toString() : void 0,
              onChange: (event) => {
                updateCredentials(event.target.name, event.target.value);
              },
              value: credential.name === "adminEmail" && APPS_NEEDING_ADMIN_EMAIL_FOR_INTEGRATION.includes(appDetails == null ? void 0 : appDetails.stub) ? currentUser.emailAddress : credentials == null ? void 0 : credentials[credential.name],
              clearText: () => {
                updateCredentials(String(credential.name), "");
              },
              error: !credsTestPassed,
              helperText: (_b = !credsTestPassed && INVALID_CREDENTIALS_ERROR) != null ? _b : " ",
              tooltip: credential.name && appCredentialsInstructions[appDetails.stub] ? appCredentialsInstructions[appDetails.stub][String(credential.name)] : ""
            }
          ) });
        else {
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: credential.name,
              label: credential.label,
              disabled: true,
              value: (_d = (_c = currentUser == null ? void 0 : currentUser.company) == null ? void 0 : _c.companyId) != null ? _d : "0"
            }
          ) });
        }
      }) }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          disabled: !areCredentialsCompleted(),
          name: (appDetails == null ? void 0 : appDetails.authorised) ? "Update credentials" : "Connect",
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) })
    ] })
  ] }) });
};
