"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import {
  AccountingJournalStates,
  PayrunProcessStepStates
} from "@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface";
import { formatAccountingJournal } from "@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.util";
import { PayrollLocalApi } from "@v2/feature/payroll/payroll-local.api";
import { getCSVRows, toCsv } from "@v2/util/csv.util";
import { CSVLink } from "react-csv";
import { generatePath } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE } from "@/lib/routes";
import { PayrunProcessingItem } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component";
import { AccountingConfirmDoneDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/accounting/accounting-confirm-done-drawer.component";
export const AccountingJournal = ({
  payRun,
  accountingJournalState,
  setAccountingJournalState,
  sx
}) => {
  const [accordionState, setAccordionState] = useState(PayrunProcessStepStates.pending);
  const [isAccountingAppInstalled, setIsAccountingAppInstalled] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [openConfirmMarkAsSent, setOpenConfirmMarkAsSent] = useState(false);
  const [failureMessage, setFailureMessate] = useState("");
  const csvRef = useRef();
  const [csvFile, setCSVFile] = useState({
    name: "default.csv",
    data: []
  });
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        const payRunAccountingDetails = yield PayrollLocalApi.getPayrunAccountingRecord(payRun.id);
        setAccountingJournalState(payRunAccountingDetails.state);
        if ([AccountingJournalStates.submitted, AccountingJournalStates.markedAsSent].includes(
          payRunAccountingDetails.state
        )) {
          setAccountingJournalState(payRunAccountingDetails.state);
          setAccordionState(PayrunProcessStepStates.success);
          return;
        }
        if (!(payRunAccountingDetails == null ? void 0 : payRunAccountingDetails.isAccountingAppInstalled) || !payRunAccountingDetails.isAccountingAppLinked) {
          setIsAccountingAppInstalled(false);
          setAccountingJournalState(payRunAccountingDetails.state);
          setAccordionState(PayrunProcessStepStates.warning);
          return;
        }
        const updatedPayrunAccountingDetails = yield PayrollLocalApi.submitAccountingJournal(payRun.id);
        setAccountingJournalState(updatedPayrunAccountingDetails.state);
        setAccordionState(PayrunProcessStepStates.success);
      } catch (error) {
        setFailureMessate(nestErrorMessage(error));
        setAccordionState(PayrunProcessStepStates.failure);
        showMessage(`Could not submit accounting journal. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [payRun, showMessage]);
  const submitJournal = () => __async(void 0, null, function* () {
    try {
      setAccordionState(PayrunProcessStepStates.pending);
      if (!isAccountingAppInstalled) {
        const payRunAccountingDetails = yield PayrollLocalApi.getPayrunAccountingRecord(payRun.id);
        if (!payRunAccountingDetails.isAccountingAppInstalled) {
          setIsAccountingAppInstalled(false);
          setAccountingJournalState(payRunAccountingDetails.state);
          setAccordionState(PayrunProcessStepStates.warning);
          return;
        }
      }
      const updatedPayrunAccountingDetails = yield PayrollLocalApi.submitAccountingJournal(payRun.id);
      setAccountingJournalState(updatedPayrunAccountingDetails.state);
      setAccordionState(PayrunProcessStepStates.success);
    } catch (error) {
      setFailureMessate(nestErrorMessage(error));
      showMessage(`Error: Could not submit accounting journal. ${nestErrorMessage(error)}`, "error");
      setAccordionState(PayrunProcessStepStates.failure);
    }
  });
  useEffect(() => {
    if ((csvFile == null ? void 0 : csvFile.data) && csvFile.name !== "default.csv" && csvRef.current && csvRef.current.link) {
      csvRef.current.link.click();
    }
  }, [csvFile]);
  const downloadJournal = () => __async(void 0, null, function* () {
    setIsDownloading(true);
    try {
      const journalData = yield PayrollLocalApi.getPayrunExternalAccountingData(payRun.id);
      const formattedData = formatAccountingJournal(journalData);
      const data = toCsv(getCSVRows(formattedData));
      setCSVFile({ name: `payrun-${payRun.taxYear}-Month-${payRun.period}-accounting.csv`, data });
      showMessage("Journal downloaded", "success");
    } catch (error) {
      showMessage(`Could not download journal data. ${nestErrorMessage(error)}`, "error");
    }
    setIsDownloading(false);
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      PayrunProcessingItem,
      {
        title: "Accounting",
        description: {
          pending: "Publishing payrun journal...",
          failure: `Accounting journal submission failed. ${failureMessage}`,
          success: accountingJournalState === AccountingJournalStates.markedAsSent ? "Accounting journal is marked as sent." : "Accounting journal updated.",
          warning: "There is no accounting app connected to this payroll."
        }[accordionState],
        buttons: [
          {
            style: "primary",
            label: "Connect Accounting App",
            show: accordionState === PayrunProcessStepStates.warning,
            onClick: () => window.open(
              generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE, { payrollId: payRun.payrollId }),
              "_blank"
            ),
            type: "button"
          },
          {
            style: "primary",
            label: "Retry",
            show: accordionState === PayrunProcessStepStates.failure,
            onClick: () => submitJournal(),
            type: "button"
          },
          {
            style: "secondary",
            label: "Mark as sent",
            show: accordionState === PayrunProcessStepStates.failure || accordionState === PayrunProcessStepStates.warning,
            onClick: () => setOpenConfirmMarkAsSent(true),
            type: "button"
          },
          {
            style: "secondary",
            label: "Download",
            show: true,
            loading: isDownloading,
            onClick: () => downloadJournal(),
            type: "button"
          }
        ],
        success: accordionState === PayrunProcessStepStates.pending ? void 0 : accordionState === PayrunProcessStepStates.success,
        sx
      },
      "accounting"
    ),
    /* @__PURE__ */ jsx(
      CSVLink,
      {
        ref: csvRef,
        filename: csvFile.name,
        data: Array.isArray(csvFile.data) || typeof csvFile.data === "string" ? csvFile.data : []
      }
    ),
    /* @__PURE__ */ jsx(
      AccountingConfirmDoneDrawer,
      {
        open: openConfirmMarkAsSent,
        onClose: () => setOpenConfirmMarkAsSent(false),
        markAsSent: () => __async(void 0, null, function* () {
          const updatedPayrunAccountingRecord = yield PayrollLocalApi.markAccountingJournalAsSent(payRun.id);
          setAccountingJournalState(updatedPayrunAccountingRecord.state);
          setAccordionState(PayrunProcessStepStates.success);
        })
      }
    )
  ] });
};
