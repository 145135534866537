"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
const _AppRequestAPI = class _AppRequestAPI {
};
_AppRequestAPI.createAppRequest = (requestCreation) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps`, requestCreation);
});
_AppRequestAPI.createPendingAppRequest = (requestCreation) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps/pending`, requestCreation);
});
_AppRequestAPI.cancelAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps/${requestId}/cancel`);
});
_AppRequestAPI.getAppRequestsForApp = (appStub) => __async(_AppRequestAPI, null, function* () {
  return (yield axios.get(`/apiv2/requests/apps/${appStub}`)).data;
});
_AppRequestAPI.approveAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps/${requestId}/approve`);
});
_AppRequestAPI.completePendingAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps/${requestId}/mark-pending-complete`);
});
_AppRequestAPI.retryFailedAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps/${requestId}/retry`);
});
_AppRequestAPI.deleteFailedAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.delete(`/apiv2/requests/apps/${requestId}/failed`);
});
_AppRequestAPI.deletePendingAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.delete(`/apiv2/requests/apps/${requestId}/pending`);
});
_AppRequestAPI.deleteRejectedAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.delete(`/apiv2/requests/apps/${requestId}/rejected`);
});
_AppRequestAPI.createPendingRequestTaskReminder = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps/${requestId}/pending-request-task`);
});
_AppRequestAPI.rejectAppRequest = (requestId) => __async(_AppRequestAPI, null, function* () {
  yield axios.post(`/apiv2/requests/apps/${requestId}/reject`);
});
export let AppRequestAPI = _AppRequestAPI;
export class AppRequestEndpoints {
  static getAppRequestsForSpecificApp(appStub) {
    return {
      url: `/apiv2/requests/apps/${appStub}`
    };
  }
  static getAppRequestsForSpecificAppAndSpecificPerson(appStub, requestedFor) {
    return {
      url: `/apiv2/requests/apps/${appStub}/${requestedFor}`
    };
  }
  static getAppRequestsForCompany(statusFilter) {
    const requestFilter = statusFilter ? `?status=${statusFilter}` : "";
    return {
      url: `/apiv2/requests/apps/all${requestFilter}`
    };
  }
  static getAppRequestsForUser(statusFilter) {
    const requestFilter = statusFilter ? `?status=${statusFilter}` : "";
    return {
      url: `/apiv2/requests/apps${requestFilter}`
    };
  }
}
