"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { AppStorePage } from "@v2/feature/app-integration/app-store.page";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppIntegrationDetailedPersonalRouter } from "./app-integration-detailed-personal.router";
import { PersonalOverview } from "./features/app-overview/app-overview-personal/personal-overview.components";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  APPS_PERSONAL_OVERVIEW_ROUTE,
  APPS_PERSONAL_ROUTE,
  APP_INTEGRATION_PERSONAL_DETAILS_ROUTE,
  APP_STORE_ROUTE
} from "@/lib/routes";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
export function AppIntegrationPersonalRouter() {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: AppScopes.VIEW_APP_STORE, context: scopesContext, path: APP_STORE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(AppStorePage, {}) }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: AppScopes.VIEW_APP_INTEGRATION,
        context: scopesContext,
        path: [APP_INTEGRATION_PERSONAL_DETAILS_ROUTE],
        children: /* @__PURE__ */ jsx(AppIntegrationDetailedPersonalRouter, {})
      }
    ),
    /* @__PURE__ */ jsx(Route, { path: APPS_PERSONAL_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(PersonalOverview, {}) }),
    /* @__PURE__ */ jsx(Route, { path: APPS_PERSONAL_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: APPS_PERSONAL_OVERVIEW_ROUTE }) })
  ] });
}
