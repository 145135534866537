"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
const primaryBasic = __spreadProps(__spreadValues({}, themeFonts.caption), {
  color: themeColors.DarkGrey,
  backgroundColor: themeColors.ZeltYellow,
  fill: themeColors.DarkGrey,
  textTransform: "inherit",
  whiteSpace: "nowrap",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: themeColors.YellowHover,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    boxShadow: "none",
    backgroundColor: themeColors.YellowPress,
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    boxShadow: "none",
    backgroundColor: themeColors.DisabledYellow,
    fill: themeColors.DarkGrey,
    color: themeColors.DarkGrey,
    cursor: "not-allowed"
  }
});
export const secondaryBasic = __spreadProps(__spreadValues({}, themeFonts.caption), {
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  backgroundColor: themeColors.Background,
  textTransform: "inherit",
  whiteSpace: "nowrap",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    boxShadow: "none",
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    boxShadow: "none",
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    color: themeColors.Grey,
    fill: themeColors.Grey,
    boxShadow: "none"
  }
});
export const primarySmallBtn = __spreadValues({
  minWidth: "max-content",
  height: "40px",
  padding: "10px 15px",
  borderRadius: radius.br10
}, primaryBasic);
export const primaryTableSmallBtn = __spreadProps(__spreadValues(__spreadValues({
  minWidth: "40px",
  height: "30px"
}, primaryBasic), themeFonts.caption), {
  borderRadius: radius.br8
});
export const secondarySmallBtn = __spreadValues({
  minWidth: "max-content",
  padding: "10px 15px",
  height: "40px",
  borderRadius: radius.br10
}, secondaryBasic);
export const secondaryCTABtn = __spreadProps(__spreadValues({
  minWidth: "fit-content",
  padding: "5px 10px",
  height: "30px"
}, secondaryBasic), {
  borderRadius: radius.br8
});
export const secondaryTableSmallBtn = __spreadValues({
  minWidth: "fit-content",
  height: "30px",
  padding: "5px 8px"
}, secondaryBasic);
export const secondaryTextSmallBtn = __spreadProps(__spreadValues({
  p: 0,
  minWidth: "40px",
  height: "20px"
}, themeFonts.caption), {
  color: themeColors.Grey,
  fill: themeColors.Grey,
  backgroundColor: themeColors.white,
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: radius.br8,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey
  },
  "&:active": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey
  },
  "&:focus": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.Background
  }
});
export const secondaryLargeBtn = __spreadProps(__spreadValues({
  height: "50px"
}, secondaryBasic), {
  borderRadius: radius.br10,
  "@media (min-width:600px)": {
    "&:not(.MuiButton-fullWidth)": {
      width: "190px"
    }
  }
});
export const secondaryRoundMenuButton = __spreadValues({
  minWidth: "fit-content",
  padding: "5px 10px",
  height: "30px",
  borderRadius: radius.br15
}, secondaryBasic);
export const errorSmallBtn = __spreadProps(__spreadValues({
  minWidth: "max-content",
  padding: "10px 15px",
  height: "40px"
}, themeFonts.caption), {
  color: themeColors.white,
  fill: themeColors.ZeltYellow,
  backgroundColor: themeColors.Red,
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: radius.br10,
  "&:hover": {
    backgroundColor: themeColors.hoverRed,
    boxShadow: "none",
    color: themeColors.white,
    fill: themeColors.white
  },
  "&:target": {
    backgroundColor: themeColors.pressRed,
    boxShadow: "none",
    color: themeColors.white,
    fill: themeColors.white
  },
  "&:disabled": {
    backgroundColor: themeColors.lightRed,
    color: themeColors.white,
    fill: themeColors.white,
    boxShadow: "none"
  }
});
export const successSmallBtn = __spreadProps(__spreadValues({}, themeFonts.caption), {
  minWidth: "max-content",
  height: "40px",
  padding: "10px 15px",
  borderRadius: radius.br10,
  color: themeColors.white,
  fill: themeColors.white,
  textTransform: "inherit",
  boxShadow: "none",
  backgroundColor: themeColors.Green,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: themeColors.ZeltSuccess,
    color: themeColors.white,
    fill: themeColors.white
  },
  "&:target": {
    boxShadow: "none",
    backgroundColor: themeColors.GreenMiddle,
    color: themeColors.white,
    fill: themeColors.white
  },
  "&:disabled": {
    boxShadow: "none",
    backgroundColor: themeColors.GreenDisabled,
    fill: themeColors.white,
    color: themeColors.white,
    cursor: "not-allowed"
  }
});
export const successLargeBtn = __spreadProps(__spreadValues({}, themeFonts.caption), {
  minWidth: "max-content",
  height: "50px",
  padding: "10px 15px",
  borderRadius: radius.br10,
  "@media (min-width:600px)": {
    "&:not(.MuiButton-fullWidth)": {
      width: "190px"
    }
  },
  color: themeColors.white,
  fill: themeColors.white,
  textTransform: "inherit",
  boxShadow: "none",
  backgroundColor: themeColors.Green,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: themeColors.ZeltSuccess,
    color: themeColors.white,
    fill: themeColors.white
  },
  "&:target": {
    boxShadow: "none",
    backgroundColor: themeColors.GreenMiddle,
    color: themeColors.white,
    fill: themeColors.white
  },
  "&:disabled": {
    boxShadow: "none",
    backgroundColor: themeColors.GreenDisabled,
    fill: themeColors.white,
    color: themeColors.white,
    cursor: "not-allowed"
  }
});
export const successCTABtn = __spreadProps(__spreadValues({
  minWidth: "fit-content",
  padding: "5px 8px",
  height: "30px",
  border: "none"
}, themeFonts.caption), {
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  backgroundColor: themeColors.Green,
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: radius.br8,
  "&:hover": {
    backgroundColor: themeColors.middleGrey,
    boxShadow: "none",
    fill: themeColors.DarkGrey
  },
  "&:target": {
    backgroundColor: themeColors.pressGrey,
    boxShadow: "none",
    fill: themeColors.DarkGrey
  },
  "&:disabled": {
    backgroundColor: themeColors.lightGrey,
    color: themeColors.Grey,
    fill: themeColors.Grey,
    boxShadow: "none"
  }
});
export const whiteSmallBtn = __spreadProps(__spreadValues({}, themeFonts.caption), {
  minWidth: "max-content",
  height: "40px",
  padding: "10px 15px",
  borderRadius: radius.br10,
  color: themeColors.black,
  fill: themeColors.black,
  textTransform: "inherit",
  boxShadow: "none",
  backgroundColor: themeColors.white,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: themeColors.GreyHover,
    color: themeColors.black,
    fill: themeColors.black
  },
  "&:target": {
    boxShadow: "none",
    backgroundColor: themeColors.GreyPress,
    color: themeColors.black,
    fill: themeColors.black
  },
  "&:disabled": {
    boxShadow: "none",
    backgroundColor: themeColors.DisabledGrey,
    fill: themeColors.Grey,
    color: themeColors.Grey,
    cursor: "not-allowed"
  }
});
export const errorTextSmallBtn = __spreadProps(__spreadValues({
  p: 0,
  minWidth: "40px",
  height: "20px"
}, themeFonts.caption), {
  color: themeColors.red,
  fill: themeColors.red,
  backgroundColor: themeColors.white,
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: radius.br8,
  "&:hover": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.red
  },
  "&:target": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.pressRed
  },
  "&:active": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.red
  },
  "&:focus": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.red
  },
  "&:disabled": {
    backgroundColor: themeColors.white,
    boxShadow: "none",
    color: themeColors.lightRed
  }
});
export const tabsFilterBtnSx = __spreadProps(__spreadValues({
  padding: "5px 10px",
  height: "30px",
  minWidth: "30px",
  border: "none",
  borderRadius: radius.br15,
  boxSizing: "border-box"
}, secondaryBasic), {
  "&:active": {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: "none"
  }
});
export const activeTabsFilterBtnSx = __spreadProps(__spreadValues({
  padding: "5px 10px",
  height: "30px",
  minWidth: "30px"
}, themeFonts.caption), {
  color: themeColors.Background,
  fill: themeColors.Background,
  borderRadius: radius.br15,
  backgroundColor: themeColors.DarkGrey,
  textTransform: "inherit",
  boxShadow: "none",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: "none"
  },
  "&:target": {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: "none"
  },
  "&:disabled": {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: "none"
  },
  "&:active": {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.white,
    boxShadow: "none"
  }
});
export const dangerLargeBtn = __spreadProps(__spreadValues({
  height: "50px"
}, themeFonts.caption), {
  color: themeColors.white,
  backgroundColor: themeColors.darkRed,
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: radius.br10,
  "&:hover": {
    backgroundColor: themeColors.hoverRed,
    color: themeColors.white,
    boxShadow: "none"
  },
  "&:target": {
    backgroundColor: themeColors.pressRed,
    color: themeColors.white,
    boxShadow: "none"
  },
  "&:disabled": {
    backgroundColor: themeColors.lightRed,
    color: themeColors.white,
    boxShadow: "none"
  },
  "@media (min-width:600px)": {
    "&:not(.MuiButton-fullWidth)": {
      width: "190px"
    }
  }
});
export const loginHeaderBtn = __spreadProps(__spreadValues({
  minWidth: "40px",
  height: "30px"
}, themeFonts.caption), {
  color: themeColors.DarkGrey,
  fill: themeColors.lightGrey,
  backgroundColor: themeColors.Background,
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: themeColors.TableHover
  }
});
export const activefilteredSmallBtn = __spreadProps(__spreadValues({
  padding: "5px 10px",
  height: "30px",
  color: themeColors.Background,
  fill: themeColors.Background,
  borderRadius: radius.br15,
  backgroundColor: themeColors.DarkGrey,
  textTransform: "inherit",
  boxShadow: "none"
}, themeFonts.caption), {
  "&:hover": {
    backgroundColor: themeColors.Grey,
    boxShadow: "none"
  },
  "&:target": {
    backgroundColor: themeColors.GreyPress,
    boxShadow: "none",
    fill: themeColors.Background
  },
  "&:disabled": {
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.Background,
    fill: themeColors.Background,
    boxShadow: "none"
  }
});
export const tabCountSx = __spreadValues({
  borderRadius: radius.br15,
  minWidth: "16px",
  height: "16px",
  display: "flex",
  alignItems: "center"
}, themeFonts.caption);
export const activeTabCount = __spreadProps(__spreadValues({
  borderRadius: radius.br15,
  minWidth: "16px",
  height: "16px",
  display: "flex",
  alignItems: "center"
}, themeFonts.caption), {
  color: themeColors.Background
});
export const underlinedLinkLight = __spreadProps(__spreadValues({}, themeFonts.caption), {
  color: themeColors.Grey,
  textTransform: "inherit",
  textDecorationColor: themeColors.Grey,
  "&:hover": {
    color: themeColors.DarkGrey
  },
  "&:target": {
    color: themeColors.pressGrey,
    textDecoration: "none"
  }
});
export const underlinedLinkDark = __spreadProps(__spreadValues({}, themeFonts.caption), {
  color: themeColors.DarkGrey,
  textTransform: "inherit",
  textDecorationColor: themeColors.DarkGrey,
  "&:hover": {
    color: themeColors.Grey
  },
  "&:target": {
    color: themeColors.pressGrey,
    textDecoration: "none"
  }
});
export const linkButtonTitle3Sx = __spreadProps(__spreadValues({}, themeFonts.title4), {
  background: "none",
  border: "none",
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
  p: 0,
  m: 0,
  minWidth: "40px",
  height: "20px",
  fill: themeColors.DarkGrey,
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: radius.br8
});
export const linkButtonSx = {
  background: "none",
  border: "none",
  textDecoration: "underline",
  cursor: "pointer",
  p: 0,
  m: 0,
  minWidth: "40px",
  height: "20px",
  textTransform: "inherit",
  boxShadow: "none",
  borderRadius: radius.br8
};
export const linkTextSx = {
  color: themeColors.DarkGrey,
  textTransform: "inherit",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    color: themeColors.BrownHover
  },
  "&:target": {
    color: themeColors.BrownHover,
    textDecoration: "none"
  }
};
