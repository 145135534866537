"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useState } from "react";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionType } from "@v2/feature/device/device.interface";
import { DeviceActivationSection } from "@v2/feature/device/features/activation-modal/components/device-activation-section.component";
import useMessage from "@/hooks/notification.hook";
import { themeColors } from "@/v2/styles/colors.styles";
export const DeviceCredentialsDrawer = ({ isOpen, setIsOpen, devicePossession }) => {
  const [credentials, setCredentials] = useState(void 0);
  const [showMessage] = useMessage();
  const getDeviceAccountCredentials = useCallback(() => __async(void 0, null, function* () {
    try {
      if (devicePossession.possessionType !== DevicePossessionType.User) {
        showMessage("This device is not assigned to a user.", "error");
        return;
      }
      const credentials2 = yield DeviceAPI.getDeviceAssignedExternalUserCredentials(devicePossession.possessionId);
      setCredentials(credentials2);
    } catch (error) {
      showMessage("Could not retrieve the credentials.", "error");
    }
  }), [showMessage, devicePossession.possessionType, devicePossession.possessionId]);
  useEffect(() => {
    getDeviceAccountCredentials();
  }, [getDeviceAccountCredentials]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsx(DeviceActivationSection, { credentials })
    }
  ) });
};
