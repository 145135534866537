"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, FormControl, Typography } from "@mui/material";
import { Form, FormikProvider } from "formik";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const VatNumberModal = ({ setOpen, open, formik }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          paddingBottom: spacing.p10
        },
        children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: polyglot.t("InvoiceTaxModal.title") })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: formik.values.from ? /* @__PURE__ */ jsx(UserCell, { userId: formik.values.from }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: polyglot.t("InvoiceTaxModal.employeeNotSelected") }) }),
    /* @__PURE__ */ jsx(FormControl, { sx: __spreadProps(__spreadValues({}, fieldSx), { mb: spacing.mb20 }), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "vatNumber",
        label: "VAT number",
        value: formik.values.vatNumber,
        onChange: formik.handleChange,
        error: formik.touched.vatNumber && Boolean(formik.errors.vatNumber),
        helperText: formik.touched.vatNumber && formik.errors.vatNumber,
        clearText: () => formik.setFieldValue("vatNumber", "")
      }
    ) }),
    /* @__PURE__ */ jsx(FormControl, { sx: __spreadProps(__spreadValues({}, fieldSx), { mb: spacing.mb20 }), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "taxId",
        label: "Tax ID",
        value: formik.values.taxId,
        onChange: formik.handleChange,
        error: formik.touched.taxId && Boolean(formik.errors.taxId),
        helperText: formik.touched.taxId && formik.errors.taxId,
        clearText: () => formik.setFieldValue("taxId", "")
      }
    ) }),
    /* @__PURE__ */ jsx(FormControl, { sx: __spreadProps(__spreadValues({}, fieldSx), { mb: spacing.mb20 }), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        name: polyglot.t("General.save"),
        loading: false,
        colorVariant: "primary",
        sizeVariant: "large",
        onClick: () => setOpen(false)
      }
    ) })
  ] }) }) }) });
};
