"use strict";
export var PayrunProcessStepStates = /* @__PURE__ */ ((PayrunProcessStepStates2) => {
  PayrunProcessStepStates2["success"] = "success";
  PayrunProcessStepStates2["pending"] = "pending";
  PayrunProcessStepStates2["failure"] = "failure";
  PayrunProcessStepStates2["warning"] = "warning";
  return PayrunProcessStepStates2;
})(PayrunProcessStepStates || {});
export var DownloadPayslipsStates = /* @__PURE__ */ ((DownloadPayslipsStates2) => {
  DownloadPayslipsStates2["notDownloaded"] = "notDownloaded";
  DownloadPayslipsStates2["downloaded"] = "downloaded";
  return DownloadPayslipsStates2;
})(DownloadPayslipsStates || {});
export var HMRCSubmissionStates = /* @__PURE__ */ ((HMRCSubmissionStates2) => {
  HMRCSubmissionStates2["notSubmitted"] = "notSubmitted";
  HMRCSubmissionStates2["submitted"] = "submitted";
  HMRCSubmissionStates2["markedAsSent"] = "markedAsSent";
  return HMRCSubmissionStates2;
})(HMRCSubmissionStates || {});
export var PensionContributionStates = /* @__PURE__ */ ((PensionContributionStates2) => {
  PensionContributionStates2["notSubmitted"] = "notSubmitted";
  PensionContributionStates2["submitted"] = "submitted";
  PensionContributionStates2["markedAsSent"] = "markedAsSent";
  return PensionContributionStates2;
})(PensionContributionStates || {});
export var PensionContributionStatuses = /* @__PURE__ */ ((PensionContributionStatuses2) => {
  PensionContributionStatuses2["Queued"] = "Queued";
  PensionContributionStatuses2["Processing"] = "Processing";
  PensionContributionStatuses2["Sent"] = "Sent";
  PensionContributionStatuses2["Failed"] = "Failed";
  PensionContributionStatuses2["Unknown"] = "Unknown";
  return PensionContributionStatuses2;
})(PensionContributionStatuses || {});
export var AccountingJournalStates = /* @__PURE__ */ ((AccountingJournalStates2) => {
  AccountingJournalStates2["notSubmitted"] = "notSubmitted";
  AccountingJournalStates2["markedAsSent"] = "markedAsSent";
  AccountingJournalStates2["submitted"] = "submitted";
  return AccountingJournalStates2;
})(AccountingJournalStates || {});
export var PayrunPaymentsStates = /* @__PURE__ */ ((PayrunPaymentsStates2) => {
  PayrunPaymentsStates2["created"] = "created";
  PayrunPaymentsStates2["notCreated"] = "notCreated";
  return PayrunPaymentsStates2;
})(PayrunPaymentsStates || {});
