"use strict";
import { Mark, mergeAttributes } from "@tiptap/core";
export const SmartFieldMark = Mark.create({
  name: "customMark",
  group: "block",
  content: "inline*",
  draggable: true,
  allowGapCursor: true,
  addAttributes() {
    return {
      id: {
        default: null
      },
      class: {
        default: null
      },
      contenteditable: {
        default: false
      },
      draggable: {
        default: false
      },
      selectable: {
        default: false
      },
      "data-draggable-node": {
        default: true
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (dom) => ({
          class: dom.getAttribute("class"),
          contenteditable: dom.getAttribute("contenteditable"),
          draggable: dom.getAttribute("draggable"),
          "data-draggable-node": true
        })
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(HTMLAttributes), 0];
  }
});
