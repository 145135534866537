"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Link, Typography } from "@mui/material";
import { secondaryLink } from "@/v2/styles/links.styles";
const AuthFooterLink = ({ href, title }) => /* @__PURE__ */ jsx(Typography, { sx: { mx: 1 }, children: /* @__PURE__ */ jsx(Link, { href, target: "_blank", rel: "noopener noreferrer", sx: secondaryLink, children: title }) });
export const AuthFooter = () => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
        flexDirection: "column",
        justifyContent: "flex-end"
      },
      children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "start" }, children: [
        /* @__PURE__ */ jsx(AuthFooterLink, { href: "mailto:support@zelt.app", title: "Contact us" }),
        /* @__PURE__ */ jsx(AuthFooterLink, { href: "https://zelt.app/legal/privacy-policy/", title: "Privacy" }),
        /* @__PURE__ */ jsx(AuthFooterLink, { href: "https://zelt.app/legal/terms/", title: "Terms" }),
        /* @__PURE__ */ jsx(AuthFooterLink, { href: "https://zelt.app/legal/", title: "Legal" })
      ] })
    }
  );
};
