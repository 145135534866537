"use strict";
import { jsx } from "react/jsx-runtime";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { AppGroupManagementDrawerPage } from "@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.page";
export const AppGroupManagementDrawer = ({
  appStub,
  selectedGroupMembership,
  externalUserList,
  usersWithoutAccess,
  usersWithAccess,
  isOpen,
  onClose,
  refreshApp,
  setIsDrawerOpen,
  mode,
  existingUser,
  groupMembers,
  groupList
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen: setIsDrawerOpen, children: /* @__PURE__ */ jsx(
  AppGroupManagementDrawerPage,
  {
    appStub,
    selectedGroupMembership,
    externalUserList,
    usersWithoutAccess,
    usersWithAccess,
    refreshApp,
    closePage: () => onClose(),
    mode,
    existingUser,
    groupMembers,
    groupList
  }
) });
