"use strict";
export var RelativeAssignment = /* @__PURE__ */ ((RelativeAssignment2) => {
  RelativeAssignment2["manager"] = "Manager of Checklist assignee";
  RelativeAssignment2["checklistAssignee"] = "Checklist assignee";
  RelativeAssignment2["referenceUser"] = "Checklist assignee";
  RelativeAssignment2["someoneElse"] = "Someone else";
  RelativeAssignment2["everyone"] = "Everyone";
  RelativeAssignment2["customRule"] = "Custom rule";
  return RelativeAssignment2;
})(RelativeAssignment || {});
export var RelativeAssignmentValues = /* @__PURE__ */ ((RelativeAssignmentValues2) => {
  RelativeAssignmentValues2["manager"] = "manager";
  RelativeAssignmentValues2["checklistAssignee"] = "checklistAssignee";
  RelativeAssignmentValues2["referenceUser"] = "checklistAssignee";
  RelativeAssignmentValues2["someoneElse"] = "someoneElse";
  RelativeAssignmentValues2["everyone"] = "everyone";
  RelativeAssignmentValues2["customRule"] = "customRule";
  return RelativeAssignmentValues2;
})(RelativeAssignmentValues || {});
export const RelativeAssignmentOptions = [
  { label: "Checklist assignee" /* checklistAssignee */, value: "checklistAssignee" },
  { label: "Manager of Checklist assignee" /* manager */, value: "manager" },
  { label: "Someone else" /* someoneElse */, value: "someoneElse" },
  { label: "Everyone" /* everyone */, value: "everyone" /* everyone */ },
  { label: "Custom rule" /* customRule */, value: "customRule" /* customRule */ }
];
export const RequestedForRelativeAssignmentOptions = [
  { label: "Checklist assignee" /* checklistAssignee */, value: "checklistAssignee" },
  { label: "Manager of Checklist assignee" /* manager */, value: "manager" },
  { label: "Someone else" /* someoneElse */, value: "someoneElse" }
];
