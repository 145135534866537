"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ScaleQuestionReadonly = ({
  questionText,
  scaleConfig,
  hasComment,
  isCommentRequired
}) => {
  if (!questionText) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
    /* @__PURE__ */ jsx(RatingScale, { scaleConfig }),
    hasComment && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g8,
          pt: spacing.p16,
          borderTop: `${borders.background}`
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Add comment" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: isCommentRequired ? "Required" : "Optional" })
          ] }),
          /* @__PURE__ */ jsx(RichTextField, { value: null, disabled: true })
        ]
      }
    )
  ] });
};
const RatingScale = ({ scaleConfig }) => {
  const [scaleKey, selectedScaleKey] = useState(null);
  const scaleItemStyle = (value) => ({
    flex: 1,
    height: "44px",
    display: "flex",
    width: "100%",
    cursor: "pointer",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: scaleKey === value ? themeColors.DarkGrey : themeColors.Background,
    borderRadius: radius.br8,
    userSelect: "none"
  });
  return /* @__PURE__ */ jsxs("div", { style: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
    (scaleConfig == null ? void 0 : scaleConfig.points) && /* @__PURE__ */ jsx(Box, { sx: { visibility: scaleKey ? "visible" : "hidden", minHeight: "20px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: scaleKey ? scaleConfig.points[scaleKey] : "" }) }),
    /* @__PURE__ */ jsx(Box, { style: { display: "flex", flexGrow: 1, gap: spacing.g4, height: "44px" }, children: (scaleConfig == null ? void 0 : scaleConfig.value) && Object.entries(scaleConfig.value).map(([key, value]) => /* @__PURE__ */ jsx(
      Box,
      {
        style: scaleItemStyle(key),
        onClick: () => {
          selectedScaleKey(key);
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: scaleKey === key ? "Background" : "DarkGrey", children: value })
      },
      key
    )) })
  ] });
};
