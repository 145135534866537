"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { getDayPreviousFromDateString } from "@v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { LocalDate } from "@v2/util/local-date";
import axios from "axios";
export class AttendanceShiftAPI {
  static startShiftEntry(entryId, longitude, latitude, isYesterday) {
    return __async(this, null, function* () {
      const today = new LocalDate();
      yield axios.post("/apiv2/attendance-shifts/start-entry", {
        entryId,
        logDate: isYesterday ? getDayPreviousFromDateString(today.toDateString()) : today.toDateString(),
        startHour: today.toFullString(),
        startHourTimestamp: today.getDate(),
        longitude,
        latitude
      });
    });
  }
  static endShiftEntry(entryId, longitude, latitude, isYesterday) {
    return __async(this, null, function* () {
      const today = new LocalDate();
      yield axios.post("/apiv2/attendance-shifts/end-entry", {
        entryId,
        logDate: isYesterday ? getDayPreviousFromDateString(today.toDateString()) : today.toDateString(),
        endHour: today.toFullString(),
        endHourTimestamp: today.getDate(),
        longitude,
        latitude
      });
    });
  }
  static endShiftForDay(logDate) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/attendance-shifts/end-shift", { logDate });
    });
  }
  static endShiftForDayFromActionItem(logDate, userId) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/attendance-shifts/end-shift-action-item", { logDate, userId });
    });
  }
  static getShiftById(id, userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/attendance-shifts/${id}/${userId}/by-user`)).data;
    });
  }
  static endUnfinishedAttendanceShiftById(userId, entryId, logDate, endHour, endHourTimestamp, longitude, latitude) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-shifts/unfinished/${userId}/end`, {
        logDate,
        entryId,
        endHour,
        endHourTimestamp,
        longitude,
        latitude
      });
    });
  }
}
export class AttendanceShiftEndpoints {
  static getTodaysShiftByUser(userId) {
    const today = new LocalDate().toDateString();
    return {
      url: `/apiv2/attendance-shifts/date/${today}/users/${userId}`
    };
  }
}
