"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { DomainSideMenuContent } from "@v2/components/domain-side-menu-content.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { InsuranceSettingsGeneralPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/pages/insurance-settings-general.page";
import { InsuranceSettingsMembersPage } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/pages/insurance-settings-members.page";
import { CustomBenefitCategory } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { InsuranceEndpoints } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateCustomBenefitCategory } from "@v2/infrastructure/i18n/translate.util";
import { generatePath, Redirect, Route, Switch } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE,
  SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE,
  SETTINGS_BENEFITS_ROUTE
} from "@/lib/routes";
export const getPageConfig = (category, id, polyglot) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("General.general"),
          stub: "general",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, { category, id, productType: "standard" })
        },
        {
          title: polyglot.t("General.members"),
          stub: "members",
          path: generatePath(SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, { category, id, productType: "standard" })
        }
      ]
    }
  ];
};
export const InsuranceSettingsRouter = ({ id, category }) => {
  var _a, _b, _c;
  const { data: insuranceQuote, mutate: refresh } = useApiClient(InsuranceEndpoints.getInsuranceQuote());
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const refreshInsuranceQuote = useCallback(() => __async(void 0, null, function* () {
    try {
      if (refresh) yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [polyglot, refresh, showMessage]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_c = (_b = (_a = insuranceQuote == null ? void 0 : insuranceQuote.policy) == null ? void 0 : _a.displayName) != null ? _b : insuranceQuote == null ? void 0 : insuranceQuote.displayName) != null ? _c : translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot),
        subtitle: translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot),
        pageConfig: getPageConfig(category, id, polyglot),
        backPath: SETTINGS_BENEFITS_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { children: insuranceQuote && /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE, children: /* @__PURE__ */ jsx(
        InsuranceSettingsGeneralPage,
        {
          insuranceQuote,
          refreshInsuranceQuote
        }
      ) }),
      /* @__PURE__ */ jsx(Route, { exact: true, path: SETTINGS_BENEFITS_DETAILS_MEMBERS_ROUTE, children: /* @__PURE__ */ jsx(InsuranceSettingsMembersPage, { insuranceQuote, refreshInsurance: refreshInsuranceQuote }) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_BENEFITS_DETAILS_GENERAL_ROUTE })
    ] }) })
  ] });
};
