"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { SURVEYS_COMPANY_ROUTE, SURVEYS_ME_ROUTE, SURVEYS_TEAM_ROUTE } from "@/lib/routes";
import { SurveysCompanyRouter } from "@/v2/feature/growth/surveys/surveys-company.router";
import { SurveysMeRouter } from "@/v2/feature/growth/surveys/surveys-me.router";
import { SurveysTeamRouter } from "@/v2/feature/growth/surveys/surveys-team.router";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const SurveysRouter = () => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(user.userId), {
    suspense: false
  });
  const isManager = useMemo(() => {
    var _a, _b, _c;
    const nonTerminatedDirectReports = (_c = (_b = (_a = userSummaryWithReports == null ? void 0 : userSummaryWithReports.directReports) == null ? void 0 : _a.filter(
      (d) => d.userEvent && d.userEvent.status && ["Employed", "Hired"].includes(d.userEvent.status)
    )) == null ? void 0 : _b.length) != null ? _c : 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["surveys:all"], path: SURVEYS_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(SurveysCompanyRouter, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["surveys:manager"], path: SURVEYS_TEAM_ROUTE, children: /* @__PURE__ */ jsx(SurveysTeamRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["surveys"], path: SURVEYS_ME_ROUTE, children: /* @__PURE__ */ jsx(SurveysMeRouter, {}) })
  ] });
};
