"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { capitalize } from "lodash";
import { GlobalContext } from "@/GlobalState";
import { canAccessScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { CycleState, CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleCreationModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/components/sc-creation-modal.component";
import { SCGeneralRollingStateModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/rolling/sc-general-rolling-state-modal.component";
import { SCGeneralScheduledReopenModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/scheduled/sc-general-scheduled-reopen-modal.component";
import { SCGeneralDeleteModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/shared/sc-general-delete-modal.component";
import { SCGeneralDuplicateModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/components/shared/sc-general-duplicate-modal.component";
import { TitleStatusComponent } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCUpsertGeneralPage = ({
  surveyCycle,
  refresh,
  cycleLoading,
  reach
}) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["surveys:all"]);
  const isManager = canAccessScopes(user, ["surveys:manager"]);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDuplicateDrawerOpen, setIsDuplicateDrawerOpen] = useState(false);
  const [isDeactivateDrawerOpen, setIsDeactivateDrawerOpen] = useState(false);
  const [isReopenDrawerOpen, setIsReopenDrawerOpen] = useState(false);
  if (!surveyCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: surveyCycle ? /* @__PURE__ */ jsx(TitleStatusComponent, { surveyCycle }) : "Start review cycle",
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: cycleLoading,
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                title: "General",
                onEdit: () => setIsEditDrawerOpen(true),
                hideEdit: Boolean(surveyCycle.state === CycleState.Completed) || !(isAdmin || isManager && surveyCycle.owner === user.userId),
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: "Survey type",
                    value: capitalize(surveyCycle.type)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: "Internal name",
                    value: surveyCycle.internalName
                  },
                  ...(surveyCycle == null ? void 0 : surveyCycle.displayName) ? [
                    {
                      type: SectionItemType.Pair,
                      label: "Display name",
                      value: surveyCycle.displayName
                    }
                  ] : [],
                  {
                    type: SectionItemType.Pair,
                    label: "Status",
                    value: capitalize(surveyCycle.state)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: "Created by",
                    value: /* @__PURE__ */ jsx(UserCell, { userId: surveyCycle.owner })
                  }
                ]
              }
            ]
          }
        ),
        (isAdmin || isManager && surveyCycle.owner === user.userId) && surveyCycle.type === CycleType.Scheduled && surveyCycle.state === CycleState.Completed && /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                title: "Reopen cycle",
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: "You will be able to edit participants, timeline settings and reminders. Set a new end date for this cycle below"
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        sizeVariant: "small",
                        colorVariant: "secondary",
                        onClick: () => setIsReopenDrawerOpen(true),
                        children: "Reopen cycle"
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        (isAdmin || isManager && surveyCycle.owner === user.userId) && surveyCycle.type === CycleType.Rolling && (surveyCycle.state === CycleState.Ongoing || surveyCycle.state === CycleState.Paused) && /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                title: surveyCycle.state === CycleState.Ongoing ? "Pause cycle" : "Reactivate cycle",
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: "Pausing a rolling cycle will retain all collected answers, new invites will not be sent out. Reactivate this cycle when you are ready to start inviting participants again."
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsxs(
                      ButtonComponent,
                      {
                        sizeVariant: "small",
                        colorVariant: "secondary",
                        onClick: () => setIsDeactivateDrawerOpen(true),
                        children: [
                          surveyCycle.state === CycleState.Ongoing ? "Pause" : "Reactivate",
                          " cycle"
                        ]
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        (isAdmin || isManager && surveyCycle.owner === user.userId) && /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                title: "Duplicate cycle",
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: "All cycle settings will be duplicated into a new draft cycle"
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        sizeVariant: "small",
                        colorVariant: "secondary",
                        onClick: () => setIsDuplicateDrawerOpen(true),
                        children: "Duplicate cycle"
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        (isAdmin || isManager && surveyCycle.owner === user.userId) && /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                title: "Delete cycle",
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
                      "If you want to permanently delete this survey cycle and all associated data. You can do so\xA0",
                      /* @__PURE__ */ jsx(
                        "span",
                        {
                          onClick: () => {
                            setIsDeleteDrawerOpen(true);
                          },
                          style: { color: "red", textDecoration: "underline", cursor: "pointer" },
                          children: "here"
                        }
                      )
                    ] })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SCGeneralDeleteModal,
          {
            surveyCycle,
            reach,
            isOpen: isDeleteDrawerOpen,
            setIsOpen: setIsDeleteDrawerOpen,
            onClose: () => setIsDeleteDrawerOpen(false)
          }
        ),
        /* @__PURE__ */ jsx(
          SCGeneralDuplicateModal,
          {
            surveyCycle,
            reach,
            isOpen: isDuplicateDrawerOpen,
            setIsOpen: setIsDuplicateDrawerOpen,
            onClose: () => setIsDuplicateDrawerOpen(false)
          }
        ),
        /* @__PURE__ */ jsx(
          SurveyCycleCreationModal,
          {
            surveyCycle,
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            onClose: () => setIsEditDrawerOpen(false),
            refresh: () => __async(void 0, null, function* () {
              yield refresh == null ? void 0 : refresh();
            }),
            reach
          }
        ),
        /* @__PURE__ */ jsx(
          SCGeneralRollingStateModal,
          {
            surveyCycle,
            refresh,
            isOpen: isDeactivateDrawerOpen,
            setIsOpen: setIsDeactivateDrawerOpen,
            onClose: () => setIsDeactivateDrawerOpen(false)
          }
        ),
        /* @__PURE__ */ jsx(
          SCGeneralScheduledReopenModal,
          {
            surveyCycle,
            isOpen: isReopenDrawerOpen,
            setIsOpen: setIsReopenDrawerOpen,
            onClose: () => setIsReopenDrawerOpen(false),
            refresh: () => __async(void 0, null, function* () {
              yield refresh == null ? void 0 : refresh();
            })
          }
        )
      ]
    }
  );
};
