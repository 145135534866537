"use strict";
import { jsx } from "react/jsx-runtime";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
export const ChipSelect = ({ title, options }) => {
  return /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options,
      actionButtonDetails: {
        type: "button",
        sizeVariant: "filter",
        colorVariant: "secondary",
        title
      }
    }
  );
};
