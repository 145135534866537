"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback } from "react";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { CompanyEndpoints } from "@/api-client/company.api";
const DefaultCompanyConfig = {
  inPayroll: false,
  inPension: false,
  inGlobalPayroll: false
};
export const useCompanyConfigState = () => {
  const { data, mutate, isLoading } = useApiClient(CompanyEndpoints.getCompanyConfig(), { suspense: false });
  const refreshCompanyConfig = useCallback(() => __async(void 0, null, function* () {
    if (mutate) yield mutate();
  }), [mutate]);
  return {
    companyConfig: data != null ? data : DefaultCompanyConfig,
    refreshCompanyConfig,
    isLoading: Boolean(isLoading)
  };
};
