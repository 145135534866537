"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BidirectionalButton } from "@v2/components/bidirectional-button.component";
import { Divider } from "@v2/components/divider.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI, AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { isHourlyPolicy, isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const AllowanceAdjustmentDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  refresh,
  policyBreakdown,
  currentCycle
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        AllowanceAdjustmentDrawerContent,
        {
          userId,
          absencePolicy,
          refreshAllowancePage: refresh,
          policyBreakdown,
          currentCycle
        }
      )
    }
  ) });
};
const AllowanceAdjustmentDrawerContent = ({
  userId,
  absencePolicy,
  refreshAllowancePage,
  policyBreakdown,
  currentCycle
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [effectiveYear, setEffectiveYear] = useState(policyBreakdown.holidayYear);
  const { data: userAllowance, mutate: refreshAllowance } = useApiClient(
    AbsenceEndpoints.getUserAbsencePolicyAllowances(absencePolicy.id, userId, effectiveYear)
  );
  const { hasScopes } = useScopes();
  const isAdminOrManager = hasScopes(["absence:manager"], { userId });
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const isHourly = isHourlyPolicy(absencePolicy);
  const userAllowanceValue = (_a = userAllowance == null ? void 0 : userAllowance.userAllowance) != null ? _a : absencePolicy.allowance;
  const userTotalAllowance = policyBreakdown.userAllowance;
  const [isEditMode, setIsEditMode] = useState(false);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshAllowancePage(absencePolicy.id), refreshAllowance ? refreshAllowance() : void 0]);
  }), [absencePolicy.id, refreshAllowancePage, refreshAllowance]);
  const cycleStart = useMemo(() => {
    const start = new LocalDate(currentCycle[0]);
    start.getDate().setFullYear(effectiveYear);
    return start;
  }, [effectiveYear, currentCycle]);
  const cycleEnd = useMemo(() => {
    const end = new LocalDate(currentCycle[1]);
    const isSameYearAsStart = currentCycle[0].slice(0, 4) === currentCycle[1].slice(0, 4);
    end.getDate().setFullYear(isSameYearAsStart ? effectiveYear : effectiveYear + 1);
    return end;
  }, [effectiveYear, currentCycle]);
  const tenureValue = (_b = policyBreakdown.tenure) == null ? void 0 : _b.tenureValueInUnits;
  return absencePolicy.allowance !== null && userAllowance ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AllowanceDrawer.userAllowance") }),
      /* @__PURE__ */ jsx(
        BidirectionalButton,
        {
          navigateBack: () => setEffectiveYear((y) => y - 1),
          navigateNext: () => setEffectiveYear((y) => y + 1),
          buttonTitle: effectiveYear.toString()
        }
      )
    ] }),
    /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.absenceCycleStart"), value: cycleStart.toLocaleDateString() }),
    /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.absenceCycleEnd"), value: cycleEnd.toLocaleDateString() }),
    /* @__PURE__ */ jsx(Divider, {}),
    userTotalAllowance !== null ? /* @__PURE__ */ jsx(
      PolicyLine,
      {
        label: polyglot.t("AllowanceDrawer.userAllowance"),
        value: isHourly ? polyglot.t("AllowanceDrawer.noOfHours", {
          smart_count: userTotalAllowance
        }) : polyglot.t("AllowanceDrawer.noOfDays", {
          smart_count: userTotalAllowance
        }),
        boldLabel: true
      }
    ) : /* @__PURE__ */ jsx(
      PolicyLine,
      {
        label: polyglot.t("AllowanceDrawer.userAllowance"),
        value: polyglot.t("AllowanceDrawer.unlimited"),
        boldLabel: true
      }
    ),
    tenureValue > 0 && /* @__PURE__ */ jsx(
      PolicyLine,
      {
        label: polyglot.t("AbsencePolicyAllowanceSection.tenureBonus"),
        value: isHourly ? polyglot.t("AllowanceDrawer.noOfHours", {
          smart_count: tenureValue
        }) : polyglot.t("AllowanceDrawer.noOfDays", {
          smart_count: tenureValue
        })
      }
    ),
    userAllowanceValue !== null && !isEditMode && tenureValue !== 0 && /* @__PURE__ */ jsx(
      PolicyLine,
      {
        label: polyglot.t("AllowanceDrawer.basicUserAllowance"),
        value: isHourly ? polyglot.t("AllowanceDrawer.noOfHours", {
          smart_count: userAllowanceValue
        }) : polyglot.t("AllowanceDrawer.noOfDays", {
          smart_count: userAllowanceValue
        })
      }
    ),
    isAdminOrManager && !isEditMode && !isUnlimited && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setIsEditMode(true), sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, children: polyglot.t("General.edit") }) }),
    isEditMode && /* @__PURE__ */ jsx(
      EditMode,
      {
        setIsEditMode,
        absencePolicy,
        userAllowance,
        refresh
      }
    )
  ] }) : null;
};
const EditMode = ({
  absencePolicy,
  userAllowance,
  setIsEditMode,
  refresh
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const userAllowanceValue = (_a = userAllowance == null ? void 0 : userAllowance.userAllowance) != null ? _a : absencePolicy.allowance;
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      userAllowance: userAllowanceValue != null ? userAllowanceValue : 0,
      effectiveYear: userAllowance.effectiveYear
    },
    validationSchema: Yup.object({
      userAllowance: Yup.number().min(0, polyglot.t("AllowanceDrawer.errors.inputValidValue")).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      effectiveYear: Yup.number().min(2e3, polyglot.t("ValidationMessages.validValue")).integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield AbsenceAPI.updateAbsencePolicyUserAllowances(userAllowance.policyId, userAllowance.userId, {
          effectiveYear: Number(values.effectiveYear),
          userAllowance: Number(values.userAllowance)
        });
        yield refresh();
        setIsEditMode(false);
      } catch (error) {
        showMessage(
          polyglot.t("AllowanceDrawer.errors.couldNotUpdateAllowance", {
            nestError: nestErrorMessage(error)
          }),
          "error"
        );
      }
      setLoading(false);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("AllowanceDrawer.basicUserAllowance"),
        name: "userAllowance",
        value: formik.values.userAllowance,
        onChange: formik.handleChange,
        fullWidth: true,
        error: formik.touched.userAllowance && !!formik.errors.userAllowance,
        helperText: formik.touched.userAllowance && formik.errors.userAllowance
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
const PolicyLine = ({
  label,
  value,
  boldLabel = false,
  sx = {}
}) => /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ width: "100%", display: "flex", justifyContent: "space-between" }, sx), children: [
  /* @__PURE__ */ jsx(Typography, { variant: boldLabel ? "title4" : "caption", children: label }),
  /* @__PURE__ */ jsx(Typography, { variant: "title4", children: value })
] });
