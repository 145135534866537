"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { PermissionGroupEditDrawerPage } from "@/v2/feature/permission-group/components/permission-group-edit-drawer.component";
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES
} from "@/v2/feature/permission-group/permission-group.interface";
import { DEFAULT_GROUPS } from "@/v2/feature/permission-group/permission-group.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const EditPermissionGroupTargetsPage = ({ permissionGroup, refresh, setLoading }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup == null ? void 0 : permissionGroup.name) : false;
  const isAdmin = (permissionGroup == null ? void 0 : permissionGroup.name) === "Admin";
  const openDrawerInEditMembersMode = () => {
    setEditDrawerOpen(true);
  };
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("PermissionGroups.router.targets"),
      onEdit: !isDefault && !isAdmin ? () => __async(void 0, null, function* () {
        return openDrawerInEditMembersMode();
      }) : void 0,
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: polyglot.t("PermissionGroupListingTable.targets.disclaimer")
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("PermissionGroupListingTable.targets.includedTargets"),
                    value: permissionGroup.inclusions.length === 0 ? polyglot.t("UserSelect.everyone") : polyglot.t("PermissionGroupListingTable.targets.countTargets", permissionGroup.inclusions.length)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("PermissionGroupListingTable.targets.excludedTargets"),
                    value: permissionGroup.exclusions.length === 0 ? polyglot.t("General.none") : polyglot.t("PermissionGroupListingTable.targets.countTargets", permissionGroup.exclusions.length)
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(DrawerModal, { isOpen: editDrawerOpen, setIsOpen: setEditDrawerOpen, children: /* @__PURE__ */ jsx(
          PermissionGroupEditDrawerPage,
          {
            refreshPermissionGroup: refresh,
            selectedPermission: permissionGroup,
            permissionGroupMembers: (_a = permissionGroup == null ? void 0 : permissionGroup.members) != null ? _a : [],
            closePage: () => __async(void 0, null, function* () {
              setEditDrawerOpen(false);
              yield refresh();
            }),
            mode: PERMISSION_GROUP_EDIT_DRAWER_MODES.target,
            setLoading,
            permissionGroupName: (_b = permissionGroup == null ? void 0 : permissionGroup.name) != null ? _b : ""
          }
        ) })
      ]
    }
  );
};
