"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { PermissionGroupAPI } from "@v2/feature/permission-group/permission-group.api";
import {
  DefaultManagerPermissionLevel
} from "@v2/feature/permission-group/permission-group.interface";
import { getPermissionGroupsReportsLevelOptionsForManagerGroup } from "@v2/feature/permission-group/permission-group.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const ManagerPermissionGroupOptionsEditDrawer = ({
  isOpen,
  setIsOpen,
  permissionGroup,
  refreshPermissionGroup
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    ManagerPermissionGroupOptionsEditDrawerContent,
    {
      permissionGroup,
      refreshPermissionGroup,
      setIsOpen
    }
  ) });
};
const ManagerPermissionGroupOptionsEditDrawerContent = ({
  permissionGroup,
  refreshPermissionGroup,
  setIsOpen
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const isManagerPermissionGroup = permissionGroup.name === "Manager";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: { reportsLevels: (_a = permissionGroup.options.reportsLevels) != null ? _a : DefaultManagerPermissionLevel },
    validationSchema: Yup.object({
      reportsLevels: Yup.number().typeError(polyglot.t("validation.selectValid")).integer().required(polyglot.t("validation.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setIsSubmitting(true);
        const update = isManagerPermissionGroup ? { reportsLevels: values.reportsLevels } : {};
        yield PermissionGroupAPI.updatePermissionGroupOptions(permissionGroup.id, update);
        yield refreshPermissionGroup();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setIsSubmitting(false);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ManagerPermissionGroup.options") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ManagerPermissionGroup.howManyLevelsOfReports") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "reportsLevels",
        label: polyglot.t("ManagerPermissionGroup.levelsOfReports"),
        options: getPermissionGroupsReportsLevelOptionsForManagerGroup(polyglot),
        value: formik.values.reportsLevels,
        onChange: formik.handleChange,
        error: Boolean(!!formik.errors.reportsLevels && formik.touched.reportsLevels),
        helperText: formik.touched.reportsLevels && formik.errors.reportsLevels,
        disabled: !isManagerPermissionGroup
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        loading: isSubmitting,
        disabled: !isManagerPermissionGroup,
        fullWidth: true,
        children: polyglot.t("General.save")
      }
    ) })
  ] }) });
};
