"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class AbsenceAPI {
  static forceApprovalByAbsencesIds(absenceIds, status, approvalNotes) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch("/apiv2/absences/force-approval", { absenceIds, approvalNotes, status })) == null ? void 0 : _a.data;
    });
  }
  static approveAllAbsences(absenceIds) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch("/apiv2/absences/bulk-approve", { absenceIds })) == null ? void 0 : _a.data;
    });
  }
  static rejectAllAbsences(absenceIds, approvalNotes) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch("/apiv2/absences/bulk-reject", { absenceIds, approvalNotes })) == null ? void 0 : _a.data;
    });
  }
  static calculateCompanyPolicyCarryOverByYearAsSuperAdmin(companyId, year, policyId) {
    return __async(this, null, function* () {
      yield axios.post(
        `/apiv2/absences/superadmin/carry-over-next-year-calculation/${year}/${companyId}/policies/${policyId}`
      );
    });
  }
  static addLeaverPolicyBalanceToPayroll(userId, policyId, payItem) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/absence-policies/${policyId}/users/${userId}/offboarding/balance-to-payroll`, { payItem });
    });
  }
  static getCompanyAbsencesAsSuperAdmin(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/absences/superadmin/companies/${companyId}`)).data;
    });
  }
  static updateAbsenceByIdAsSuperAdmin(absenceId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absences/superadmin/${absenceId}`, data);
    });
  }
  static getCompanyUserAllowanceAdjustmentsByYearAsSuperAdmin(companyId, year, policyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/absences/allowance-adjustment/companies/${companyId}/year/${year}/policies/${policyId}`)).data;
    });
  }
  static fetchCompanyUsersAbsenceBalanceBreakdown(policyId, calendar, page, pageSize, name) {
    return __async(this, null, function* () {
      let queryString = `?policyId=${policyId}&Calendar=${calendar}&page=${page}&pageSize=${pageSize}`;
      if (name) queryString = queryString + `&name=${name}`;
      return (yield axios.get(`/apiv2/absences/company/balance${queryString}`)).data;
    });
  }
  static exportCompanyUsersBalancesAsCSV(policyId, year) {
    return `/apiv2/absences/company/balance/export?policyId=${policyId}&year=${year}`;
  }
  static fetchTeamUsersAbsenceBalanceBreakdown(policyId, calendar, page, pageSize, name) {
    return __async(this, null, function* () {
      let queryString = `?policyId=${policyId}&Calendar=${calendar}&page=${page}&pageSize=${pageSize}`;
      if (name) queryString = queryString + `&name=${name}`;
      return (yield axios.get(`/apiv2/absences/team/balance${queryString}`)).data;
    });
  }
  static updateCompanyAbsenceSettings(absenceSettings) {
    return __async(this, null, function* () {
      yield axios.put("/apiv2/absence-settings", absenceSettings);
    });
  }
  static fetchAllAbsences(page, pageSize, filterString, searchInput) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [
        filterString,
        searchInput ? `search=${searchInput}` : "",
        `page=${page}`,
        `pageSize=${pageSize}`
      ].filter(Boolean).join("&");
      return (_a = (yield axios.get(`/apiv2/absences?${queryString}`)).data) != null ? _a : [];
    });
  }
  static fetchManagerTreeAbsences(page, pageSize, filterString, searchInput) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [
        filterString,
        searchInput ? `search=${searchInput}` : "",
        `page=${page}`,
        `pageSize=${pageSize}`
      ].filter(Boolean).join("&");
      return (_a = (yield axios.get(`/apiv2/absences/manager?${queryString}`)).data) != null ? _a : [];
    });
  }
  static fetchUserAbsences(userId, policyId, calendar, page, pageSize, filterString, searchInput) {
    return __async(this, null, function* () {
      var _a, _b;
      const queryString = [
        ...(_a = filterString == null ? void 0 : filterString.split("&").filter((f) => !f.startsWith("Time policy") && !f.startsWith("Calendar"))) != null ? _a : [],
        `Time policy=${policyId}`,
        `Calendar=${calendar}`,
        searchInput ? `search=${searchInput}` : "",
        `page=${page}`,
        `pageSize=${pageSize}`
      ].filter(Boolean).join("&");
      return (_b = (yield axios.get(`/apiv2/absences/users/${userId}?${queryString}`)).data) != null ? _b : [];
    });
  }
  static updateAbsenceRecord(absenceId, absence) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/absences/${absenceId}`, absence);
    });
  }
  static getAbsenceById(absenceId, userId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/absences/${absenceId}/${userId}/by-user`)) == null ? void 0 : _a.data;
    });
  }
  static getNewAbsenceBalanceImpactDetails(absenceData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/absences/request-value-and-balance", absenceData)).data;
    });
  }
  static addMultipleAbsenceRecord(absence) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/absences/multiple", absence)).data;
    });
  }
  static deleteAbsenceRecord(absenceId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/absences/${absenceId}`);
    });
  }
  static getAlerts(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/absences/${userId}/alerts`)).data;
    });
  }
  static verifyCompanyOverlaps(parameters) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/absences/verify-overlap`, parameters)).data;
    });
  }
  static createAbsencePolicy(data) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/absence-policies", data)).data;
    });
  }
  static orderAbsencePolicies(policiesOrder) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/absence-policies/reorder-policies", policiesOrder);
    });
  }
  static updateAbsencePolicyGeneral(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/general`, data);
    });
  }
  static updateAbsencePolicyMembers(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/members`, data);
    });
  }
  static updateAbsencePolicyAllowance(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/allowance`, data);
    });
  }
  static updateAbsencePolicyTenure(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/tenure`, data);
    });
  }
  static updateAbsencePolicyAccrual(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/accrual`, data);
    });
  }
  static updateAbsencePolicyTOIL(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/toil`, data);
    });
  }
  static removeTOILFromAbsencePoilcy(policyId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/absence-policies/${policyId}/toil`);
    });
  }
  static updateAbsencePolicyPublicHolidays(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/public-holidays`, data);
    });
  }
  static updateAbsencePolicyCarryOver(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/carry-over`, data);
    });
  }
  static updateAbsencePolicyProrating(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/prorating`, data);
    });
  }
  static updateAbsencePolicyRounding(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/rounding`, data);
    });
  }
  static updateAbsencePolicyApproval(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/approval`, data);
    });
  }
  static updateAbsencePolicyNotifications(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/notifications`, data);
    });
  }
  static updateAbsencePolicyPayroll(policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/payroll`, data);
    });
  }
  static archiveAbsencePolicyById(policyId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/absence-policies/${policyId}/archive`);
    });
  }
  static unarchiveAbsencePolicyById(policyId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/absence-policies/${policyId}/unarchive`);
    });
  }
  static deleteAbsencePolicyById(policyId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/absence-policies/${policyId}`);
    });
  }
  static updateAbsencePolicyInstructionsById(policyId, instructions) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/instructions`, { instructions });
    });
  }
  static deleteAbsencePolicyInstructionsById(policyId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/absence-policies/${policyId}/instructions`);
    });
  }
  static updateAbsencePolicyUserAllowances(policyId, userId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absence-policies/${policyId}/users/${userId}/allowances/user-allowance`, data);
    });
  }
  static updateAbsencePolicyUserAllowanceCarryOverAdjustment(policyId, userId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absences/allowance-adjustment/policies/${policyId}/users/${userId}/carry-over`, data);
    });
  }
  static updateAbsencePolicyUserAllowanceOneOffAdjustment(userId, policyId, data) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/absences/allowance-adjustment/policies/${policyId}/users/${userId}/one-off`, data);
    });
  }
  static deleteAbsencePolicyUserAllowanceOneOffAdjustmentEntry(userId, policyId, effectiveYear, entryId) {
    return __async(this, null, function* () {
      yield axios.delete(
        `/apiv2/absences/allowance-adjustment/policies/${policyId}/users/${userId}/one-off/${effectiveYear}/entries/${entryId}`
      );
    });
  }
  static getCountOfPolicyRequests(policyId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/absence-policies/${policyId}/requests-count`)) == null ? void 0 : _a.data;
    });
  }
  static requestTimeRequestCancellation(absenceId) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/absences/${absenceId}/request-cancellation`);
    });
  }
  static rejectTimeCancellationRequests(absenceIds, userId) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/absences/reject-cancellation-requests", { userId, absenceIds });
    });
  }
  static getUserAbsenceBalanceBreakdownByPolicyId(userId, options) {
    return __async(this, null, function* () {
      const query = [
        options.calendar ? `Calendar=${options.calendar}` : null,
        options.cycleStartYear ? `Year=${options.cycleStartYear}` : null,
        options.effectiveDate ? `Date=${options.effectiveDate}` : null,
        options.policyId ? `policyId=${options.policyId}` : null,
        options.includeFormerPolicies ? `includeFormerPolicies=${options.includeFormerPolicies}` : null
      ].filter(Boolean).join("&");
      return (yield axios.get(`/apiv2/absences/users/${userId}/balance?${query}`)).data;
    });
  }
  static getUserAbsenceBalanceBreakdownByPolicyIdAsSuperAdmin(companyId, userId, policyId, startCycleYear, effectiveDate) {
    return __async(this, null, function* () {
      const queryParams = [
        `policyId=${policyId}`,
        startCycleYear ? `year=${startCycleYear}` : null,
        effectiveDate ? `effectiveDate=${effectiveDate}` : null
      ].filter(Boolean).join("&");
      return (yield axios.get(`/apiv2/absences/superadmin/companies/${companyId}/users/${userId}/balance?${queryParams}`)).data;
    });
  }
}
export class AbsenceEndpoints {
  static getAlerts(userId) {
    return {
      url: `/apiv2/absences/${userId}/alerts`
    };
  }
  static getAbsencePolicies() {
    return {
      url: "/apiv2/absence-policies"
    };
  }
  // This includes fullName in the response (should be visible only in settings)
  static getAbsencePoliciesExtended() {
    return {
      url: "/apiv2/absence-policies/extended"
    };
  }
  static getTeamAbsencePolicies() {
    return {
      url: "/apiv2/absence-policies/team"
    };
  }
  static getTeamAbsencePoliciesExtended() {
    return {
      url: "/apiv2/absence-policies/team/extended"
    };
  }
  static getUserAbsencePolicies(userId, includePastMemberships = false) {
    const query = [`includePastMemberships=${includePastMemberships}`].filter(Boolean).join("&");
    return {
      url: `/apiv2/absence-policies/users/${userId}?${query}`
    };
  }
  static getArchivedAbsencePolicies() {
    return {
      url: "/apiv2/absence-policies/archived"
    };
  }
  static getAbsencePolicyExtendedById(policyId) {
    return {
      url: `/apiv2/absence-policies/${policyId}/extended`
    };
  }
  static getAbsencePolicyById(policyId) {
    return {
      url: `/apiv2/absence-policies/${policyId}`
    };
  }
  static getUserAbsencePolicyAllowances(policyId, userId, year) {
    return {
      url: `/apiv2/absence-policies/${policyId}/users/${userId}/allowances/${year}`
    };
  }
  static fetchUserAbsenceBalanceBreakdown(userId, options) {
    const query = [
      options.calendar ? `Calendar=${options.calendar}` : null,
      options.cycleStartYear ? `Year=${options.cycleStartYear}` : null,
      options.effectiveDate ? `Date=${options.effectiveDate}` : null,
      options.policyId ? `policyId=${options.policyId}` : null,
      options.includeFormerPolicies ? `includeFormerPolicies=${options.includeFormerPolicies}` : null
    ].filter(Boolean).join("&");
    return {
      url: `/apiv2/absences/users/${userId}/balance?${query}`
    };
  }
  static fetchUserAllowedAbsencesByDateRange(dateRange, userId) {
    return {
      url: `/apiv2/absences/by-range?start=${dateRange.start}&end=${dateRange.end}&userId=${userId}`
    };
  }
  static getCompanyAbsenceSettingsAsSuperAdmin(companyId) {
    return {
      url: `/apiv2/absence-settings/superadmin/companies/${companyId}`
    };
  }
  static getAllCompanyAbsencePoliciesAsSuperAdmin(companyId) {
    return {
      url: `/apiv2/absence-policies/superadmin/companies/${companyId}`
    };
  }
  static getCompanyAbsenceSettings() {
    return {
      url: "/apiv2/absence-settings"
    };
  }
  static getUserAbsencesAfterLeaveDate(userId) {
    return {
      url: `/apiv2/absences/users/${userId}/offboarding`
    };
  }
  static getUserAbsenceOneOffPaymentToBeCreated(userId, policyId) {
    return {
      url: `/apiv2/absence-policies/${policyId}/users/${userId}/offboarding/balance-to-payroll`
    };
  }
}
