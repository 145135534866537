"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { PayrunUserHeader } from "@/v2/feature/payroll/components/payrun-user-header.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
export const EditEmployerCostPage = ({ user, payrunEntry, payrunClosed, saveIncomeUpdates }) => {
  const [savingUpdate, setSavingUpdate] = useState(false);
  const initialValues = useMemo(() => {
    return {
      income: payrunEntry.totals.additions,
      employerNi: payrunEntry.totals.employerNi,
      employerPension: payrunEntry.totals.employerPensionContribution
    };
  }, [payrunEntry]);
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => __async(void 0, null, function* () {
      const setIfChanged = (key) => values[key] !== formik.initialValues[key] ? values[key] : void 0;
      const update = {
        id: payrunEntry.id,
        userId: user.userId,
        deductions: {
          employerNi: setIfChanged("employerNi"),
          employerPension: setIfChanged("employerPension"),
          paylines: []
        }
      };
      setSavingUpdate(true);
      yield saveIncomeUpdates([update]);
      setSavingUpdate(false);
    })
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: "Edit employer costs" }),
    /* @__PURE__ */ jsx(PayrunUserHeader, { user, sx: { mt: spacing.m10 } }),
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, mt: spacing.m30, gap: spacing.g30 }, children: [
        /* @__PURE__ */ jsx(
          MoneyTextfieldComponent,
          {
            name: "income",
            label: "Income",
            value: formik.values.income,
            onChange: () => {
            },
            disabled: true,
            allowNegative: true,
            emptyIsZero: true
          }
        ),
        /* @__PURE__ */ jsx(
          MoneyTextfieldComponent,
          {
            name: "employerNi",
            label: "Employer NI",
            value: formik.values.employerNi,
            onChange: (value) => formik.setFieldValue("employerNi", value),
            disabled: savingUpdate || payrunClosed,
            allowNegative: true,
            emptyIsZero: true
          }
        ),
        /* @__PURE__ */ jsx(
          MoneyTextfieldComponent,
          {
            name: "employerPension",
            label: "Employer pension",
            value: formik.values.employerPension,
            onChange: (value) => formik.setFieldValue("employerPension", value),
            disabled: savingUpdate || payrunClosed,
            allowNegative: true,
            emptyIsZero: true
          }
        )
      ] }),
      payrunClosed && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m30 }), children: "Employer costs cannot be changed because the payrun is closed." }),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.m30, flexFlow: "row", alignItems: "center", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: "Total cost" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title4), children: formatCurrency(payrunEntry.totals.totalCost) })
      ] }),
      !payrunClosed && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          loading: savingUpdate,
          name: "Save",
          type: "submit",
          sizeVariant: "large",
          colorVariant: "primary",
          style: { marginTop: spacing.m30 }
        }
      )
    ] }) })
  ] });
};
