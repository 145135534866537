"use strict";
import { jsx } from "react/jsx-runtime";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as TrainingIcon } from "@/images/onboarding-step-icons/BookBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const TrainingOnboardingItem = (_) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: TrainingIcon,
        title: polyglot.t("TrainingOnboardingItem.title"),
        description: polyglot.t("TrainingOnboardingItem.desc")
      },
      sx: {
        py: spacing.py20
      }
    }
  );
};
