"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
const _AnalyticsAPI = class _AnalyticsAPI {
};
_AnalyticsAPI.getLatestAnalyticsRecord = () => __async(_AnalyticsAPI, null, function* () {
  return (yield axios.get(`/apiv2/analytics`)).data;
});
export let AnalyticsAPI = _AnalyticsAPI;
