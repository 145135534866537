"use strict";
import { jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from "react";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
const validateMoneyInput = (s, allowNegative) => {
  const match = s.match(/-?(\d{0,8}\.?\d{0,2})/);
  if (!match) return "";
  return allowNegative ? match[0] : match[1];
};
export const MoneyTextfieldComponent = ({
  allowNegative,
  autoFocus,
  clearToZero,
  disabled,
  readonly,
  emptyIsZero,
  error,
  helperText,
  id,
  label,
  name,
  onChange,
  value,
  sx
}) => {
  const lastTypedValue = useRef();
  const lastValue = useRef(0);
  const [updatingText, setUpdatingText] = useState();
  const textValue = useMemo(() => {
    var _a;
    setUpdatingText(void 0);
    lastValue.current = value != null ? value : 0;
    const typedValue = lastTypedValue.current;
    if (typeof typedValue === "undefined") {
      return (_a = value == null ? void 0 : value.toFixed(2)) != null ? _a : emptyIsZero ? "0" : "";
    }
    if (typeof value === "undefined") {
      return emptyIsZero ? "0" : "";
    }
    if (!value && (!typedValue || typedValue === "-")) {
      return typedValue;
    }
    if (parseFloat(typedValue) === value) {
      return typedValue;
    }
    return value.toFixed(2);
  }, [emptyIsZero, value]);
  const changeTextValue = useCallback(
    (s) => {
      const newTextValue = validateMoneyInput(s, !!allowNegative);
      lastTypedValue.current = newTextValue;
      setUpdatingText(newTextValue);
      if (newTextValue) {
        onChange == null ? void 0 : onChange(parseFloat(newTextValue) || 0);
      } else {
        onChange == null ? void 0 : onChange(emptyIsZero ? 0 : void 0);
      }
    },
    [allowNegative, emptyIsZero, onChange]
  );
  const normaliseInput = useCallback(() => {
    setUpdatingText(lastValue.current.toFixed(2));
  }, []);
  return /* @__PURE__ */ jsx(
    TextfieldComponent,
    {
      id,
      name,
      label,
      type: "tel",
      autoComplete: "off",
      autoFocus,
      value: typeof updatingText === "string" ? updatingText : textValue,
      onBlur: () => normaliseInput(),
      onChange: (e) => changeTextValue(e.target.value),
      InputProps: {
        readOnly: readonly
      },
      clearText: () => changeTextValue(clearToZero ? "0" : ""),
      error,
      helperText,
      disabled,
      sx
    }
  );
};
