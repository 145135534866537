"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  RelativeAssignment,
  RelativeAssignmentValues
} from "@v2/feature/task/subfeature/checklist/checklist-item.dto";
import { LaunchFormDrawer } from "@v2/feature/task/subfeature/checklist/components/launch-form-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useLocation, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { TooltipCell } from "@/v2/components/table/components/tooltip-cell.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ChecklistItemFormModal } from "@/v2/feature/task/subfeature/checklist/components/checklist-item-form-modal.component";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 14, height: 14 };
const ChecklistItemsCard = ({
  checklist,
  refresh,
  setIsAddTaskModalOpen,
  isAddTaskModalOpen
}) => {
  const { polyglot } = usePolyglot();
  const location = useLocation();
  const locationState = location.state;
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [checklistItems, setChecklistItems] = useState([...checklist.checklistItems]);
  const checklistId = checklist.id;
  const getOffSetNumberBasedOnUnit = (duration, unit) => {
    let offset = 0;
    switch (unit) {
      case "Days":
        offset = duration;
        break;
      case "Weeks":
        offset = duration * 7;
        break;
      case "Months":
        offset = duration * 30;
        break;
      default:
        break;
    }
    return offset;
  };
  const handleNewTask = useCallback(() => {
    setIsAddTaskModalOpen(true);
    setTaskToEdit(null);
  }, [setIsAddTaskModalOpen]);
  useEffect(() => {
    if (locationState == null ? void 0 : locationState.openNewTaskModal) handleNewTask();
  }, [handleNewTask, locationState]);
  useEffect(() => {
    const filteredList = [...checklist.checklistItems].filter(
      (task) => task.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setChecklistItems(filteredList);
  }, [searchInput, checklist.checklistItems]);
  const [showMessage] = useMessage();
  const deleteChecklistItem = useCallback(
    (checklistItemId) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.deleteChecklistItemById(checklist.id, checklistItemId);
        showMessage(polyglot.t("ChecklistItemsCard.successMessages.delete"), "success");
        yield refresh();
      } catch (error) {
        showMessage(`${polyglot.t("ChecklistItemsCard.errorMessages.delete")}. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [polyglot, checklist.id, refresh, showMessage]
  );
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: () => polyglot.t("ChecklistItemsCard.tasks"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.name),
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(
            TooltipCell,
            {
              title: (_a = original.name) != null ? _a : "",
              maxWidth: 260,
              cell: /* @__PURE__ */ jsx("div", { children: original.name ? /* @__PURE__ */ jsx(Box, { children: original.name }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
            }
          );
        }
      },
      {
        id: "assignedToPlaceholder",
        header: () => polyglot.t("ChecklistItemsCard.assignedToPlaceholder"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d;
          return /* @__PURE__ */ jsx(
            TooltipCell,
            {
              title: (_a = original.assignedToPlaceholder) != null ? _a : "",
              maxWidth: 260,
              cell: /* @__PURE__ */ jsxs("div", { children: [
                /* @__PURE__ */ jsx("div", { children: ((_b = original.assignedUser) == null ? void 0 : _b.userId) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
                  /* @__PURE__ */ jsx(UserAvatar, { userId: original.assignedUser.userId, size: "xxsmall" }),
                  /* @__PURE__ */ jsx("div", { children: ((_c = original.assignedUser) == null ? void 0 : _c.firstName) + " " + ((_d = original == null ? void 0 : original.assignedUser) == null ? void 0 : _d.lastName) })
                ] }) }),
                original.assignedToPlaceholder !== RelativeAssignmentValues.someoneElse && //@ts-ignore
                /* @__PURE__ */ jsx(Box, { children: RelativeAssignment[original.assignedToPlaceholder] })
              ] })
            }
          );
        }
      },
      {
        id: "requestedForPlaceholder",
        header: () => polyglot.t("ChecklistItemsCard.requestedForPlaceholder"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d;
          return /* @__PURE__ */ jsx(
            TooltipCell,
            {
              title: (_a = original.requestedForPlaceholder) != null ? _a : "",
              maxWidth: 260,
              cell: /* @__PURE__ */ jsxs("div", { children: [
                /* @__PURE__ */ jsx("div", { children: ((_b = original.requestedFor) == null ? void 0 : _b.userId) && /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
                  /* @__PURE__ */ jsx(UserAvatar, { userId: original.requestedFor.userId, size: "xxsmall" }),
                  /* @__PURE__ */ jsx("div", { children: ((_c = original.requestedFor) == null ? void 0 : _c.firstName) + " " + ((_d = original == null ? void 0 : original.requestedFor) == null ? void 0 : _d.lastName) })
                ] }) }),
                original.requestedForPlaceholder !== RelativeAssignmentValues.someoneElse && //@ts-ignore
                /* @__PURE__ */ jsx(Box, { children: RelativeAssignment[original.requestedForPlaceholder] })
              ] })
            }
          );
        }
      },
      {
        id: "dueDateOffsetDuration",
        header: () => polyglot.t("ChecklistItemsCard.dueDateOffsetDuration"),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(
          a,
          b,
          (item) => getOffSetNumberBasedOnUnit(Number(item.dueDateOffsetDuration), item.dueDateOffsetUnit)
        ),
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d, _e;
          return /* @__PURE__ */ jsx(
            TooltipCell,
            {
              title: (_a = String(original.dueDateOffsetDuration)) != null ? _a : "",
              maxWidth: 260,
              cell: /* @__PURE__ */ jsxs("div", { children: [
                original.dueDateOffsetDuration !== 0 && /* @__PURE__ */ jsxs(Box, { children: [
                  original.dueDateOffsetDuration > 0 ? polyglot.t("ChecklistItemsCard.afterDate", {
                    count: Math.abs(original.dueDateOffsetDuration),
                    unit: (_c = (_b = original.dueDateOffsetUnit) == null ? void 0 : _b.toLowerCase()) != null ? _c : "days"
                  }) : polyglot.t("ChecklistItemsCard.beforeDate", {
                    count: Math.abs(original.dueDateOffsetDuration),
                    unit: (_e = (_d = original.dueDateOffsetUnit) == null ? void 0 : _d.toLowerCase()) != null ? _e : "days"
                  }),
                  " "
                ] }),
                original.dueDateOffsetDuration === 0 && /* @__PURE__ */ jsx(Box, { children: polyglot.t("ChecklistItemsCard.onChecklistDate") })
              ] })
            }
          );
        }
      },
      {
        id: "actions",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 100,
        minSize: 80,
        cell: ({ row: { original } }) => {
          const task = original;
          return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "flex-end", gap: spacing.m5 }, children: [
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  deleteChecklistItem(original.id);
                },
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
              }
            ),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setTaskToEdit(task);
                  setIsAddTaskModalOpen(true);
                },
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ] });
        }
      }
    ],
    [polyglot, setIsAddTaskModalOpen, deleteChecklistItem]
  );
  const closeTaskFormModal = () => {
    setTaskToEdit(null);
    refresh();
    setIsAddTaskModalOpen(false);
  };
  const addTaskToChecklist = (data) => __async(void 0, null, function* () {
    try {
      const newChecklistItem = {
        name: data.name,
        description: data.description,
        assignedToPlaceholder: data.assignedToPlaceholder,
        requestedForPlaceholder: data.requestedForPlaceholder,
        assignedUserId: data.assignedUserId,
        assignedUserIds: data.assignedUserIds,
        requestedForId: data.requestedForId,
        dueDate: data.dueDate,
        userAssignWithPlaceholder: data.userAssignWithPlaceholder,
        dueDateOffsetDuration: data.dueDateOffsetDuration,
        dueDateOffsetUnit: data.dueDateOffsetUnit,
        attachmentValues: data.attachmentValues,
        assignedToCustomRule: data.assignedToCustomRule
      };
      yield TaskAPI.addChecklistItemToChecklist(checklistId, newChecklistItem);
      showMessage(polyglot.t("ChecklistItemsCard.successMessages.add"), "success");
      yield refresh();
      setTaskToEdit(null);
    } catch (error) {
      showMessage(`${polyglot.t("ChecklistItemsCard.errorMessages.add")}. ${nestErrorMessage(error)}`, "error");
    }
  });
  const updateChecklistItem = (data) => __async(void 0, null, function* () {
    const checklistItemId = data.id;
    if (!(checklist == null ? void 0 : checklist.id) || !checklistItemId) return;
    try {
      const update = {
        name: data.name,
        description: data.description,
        assignedToPlaceholder: data.assignedToPlaceholder,
        requestedForPlaceholder: data.requestedForPlaceholder,
        assignedUserId: data.assignedUserId,
        assignedUserIds: data.assignedUserIds,
        requestedForId: data.requestedForId,
        dueDate: data.dueDate,
        userAssignWithPlaceholder: data.userAssignWithPlaceholder,
        dueDateOffsetDuration: data.dueDateOffsetDuration,
        dueDateOffsetUnit: data.dueDateOffsetUnit,
        attachmentValues: data.attachmentValues,
        assignedToCustomRule: data.assignedToCustomRule
      };
      yield TaskAPI.updateChecklistItem(checklist.id, checklistItemId, update);
      showMessage("Task updated successfully", "success");
      yield refresh();
      setTaskToEdit(null);
    } catch (error) {
      showMessage(`Could not update checklist item. ${nestErrorMessage(error)}`, "error");
    }
  });
  const handleChecklistItemFormSubmit = (data) => __async(void 0, null, function* () {
    if (data.id) {
      yield updateChecklistItem(data);
    } else {
      yield addTaskToChecklist(data);
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    checklist.checklistItems.length > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          },
          children: [
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                query: searchInput,
                handleChange: (e) => {
                  setSearchInput(e.target.value);
                }
              }
            ),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                id: "add_task_button",
                onClick: () => handleNewTask(),
                sizeVariant: "small",
                colorVariant: "primary",
                children: polyglot.t("ChecklistItemsCard.new")
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: [...checklistItems],
          columnData: columns,
          initialSort: [{ id: "dueDateOffsetDuration", desc: true }]
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isAddTaskModalOpen, setIsOpen: setIsAddTaskModalOpen, children: /* @__PURE__ */ jsx(
      ChecklistItemFormModal,
      {
        onClose: closeTaskFormModal,
        formData: taskToEdit,
        action: handleChecklistItemFormSubmit,
        deleteAction: deleteChecklistItem
      }
    ) })
  ] });
};
export const TasksChecklistOverview = () => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [checklist, setChecklist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [launchChecklistOpen, setLaunchChecklistOpen] = useState(false);
  const [showMessage] = useMessage();
  const params = useParams();
  const checklistId = Number(params.checklistId);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const checklist2 = yield TaskAPI.getChecklistById(checklistId);
        setChecklist(checklist2);
      } catch (error) {
        showMessage(
          `${polyglot.t("TasksChecklistOverview.errorMessages.retrieve")}. ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setLoading(false);
      }
    }))();
  }, [polyglot, checklistId, showMessage]);
  const refresh = () => __async(void 0, null, function* () {
    const checklist2 = yield TaskAPI.getChecklistById(checklistId);
    setChecklist(checklist2);
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: (_a = checklist == null ? void 0 : checklist.name) != null ? _a : "Checklist",
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            disabled: !(checklist == null ? void 0 : checklist.checklistItems) || (checklist == null ? void 0 : checklist.checklistItems.length) === 0,
            onClick: () => setLaunchChecklistOpen(true),
            children: polyglot.t("General.launch")
          }
        ),
        showBack: true
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, sx: __spreadValues({}, spacing.pt20), children: /* @__PURE__ */ jsxs(Box, { children: [
      checklist && ((_b = checklist == null ? void 0 : checklist.checklistItems) == null ? void 0 : _b.length) < 1 && /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "600px" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("TasksChecklistOverview.create") }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { mt: spacing.m5 }, children: polyglot.t("TasksChecklistOverview.createTaskList") }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsAddTaskModalOpen(true), children: polyglot.t("TasksChecklistOverview.newTask") }) })
      ] }),
      checklist && /* @__PURE__ */ jsx(
        ChecklistItemsCard,
        {
          checklist,
          refresh,
          setIsAddTaskModalOpen,
          isAddTaskModalOpen
        }
      ),
      /* @__PURE__ */ jsx(DrawerModal, { isOpen: launchChecklistOpen, setIsOpen: setLaunchChecklistOpen, children: checklist ? /* @__PURE__ */ jsx(
        LaunchFormDrawer,
        {
          checklist,
          onClose: () => __async(void 0, null, function* () {
            setLaunchChecklistOpen(false);
            yield refresh();
          })
        }
      ) : /* @__PURE__ */ jsx(Fragment, {}) })
    ] }) })
  ] });
};
