"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const ChartProgress = ({
  progress,
  color,
  size,
  thickness,
  title,
  titleVariant = "title2",
  titleColor = "DarkGrey",
  noDataTitle = "No data",
  noData
}) => {
  return noData ? /* @__PURE__ */ jsx(Box, { sx: { height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: noDataTitle }) }) : /* @__PURE__ */ jsxs(Box, { sx: { position: "relative" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { position: "relative" }, children: [
      /* @__PURE__ */ jsx(
        CircularProgress,
        {
          variant: "determinate",
          sx: {
            color: themeColors.Background
          },
          size,
          thickness,
          value: 100
        }
      ),
      /* @__PURE__ */ jsx(
        CircularProgress,
        {
          variant: "determinate",
          disableShrink: true,
          sx: {
            color: themeColors[color],
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round"
            }
          },
          size,
          thickness,
          value: progress
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: size,
          width: size
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: titleVariant, color: titleColor, children: title })
      }
    )
  ] });
};
