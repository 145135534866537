"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { HelperAbsenceEditDrawer } from "@v2/feature/super-admin/features/helper-dashboard/components/helper-absence-edit-drawer.component";
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { secondarySmallBtn } from "@v2/styles/buttons.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, Route, Switch, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const HelperAbsencePage = ({ company }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const params = useParams();
  const companyId = (_a = company == null ? void 0 : company.companyId) != null ? _a : Number(params.companyId);
  const [loading, setLoading] = useState(false);
  const [absences, setAbsences] = useState([]);
  const [selectedAbsence, setSelectedAbsence] = useState(null);
  const [filteredAbsences, setFilteredAbsences] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [showMessage] = useMessage();
  const getFilteredAbsences = useCallback(
    (searchString) => {
      if (!searchString || Number.isNaN(searchString)) {
        setFilteredAbsences(absences);
        return;
      }
      const userId = Number(searchString);
      if (!Number.isInteger(userId)) {
        setFilteredAbsences(absences);
        return;
      }
      const filteredAbsences2 = absences.filter((a) => a.userId === userId);
      setFilteredAbsences(filteredAbsences2);
    },
    [absences]
  );
  const refreshCompanyAbsences = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const absences2 = yield AbsenceAPI.getCompanyAbsencesAsSuperAdmin(companyId);
      setAbsences(absences2);
      setFilteredAbsences(absences2);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
    setLoading(false);
  }), [companyId, showMessage]);
  useEffect(() => {
    if (companyId) refreshCompanyAbsences();
  }, [companyId, refreshCompanyAbsences]);
  const columns = useMemo(
    () => [
      {
        header: () => "Absence Id",
        id: "absenceId",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Fragment, { children: original.absenceId });
        }
      },
      {
        header: () => "User Id",
        id: "userId",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Fragment, { children: original.userId });
        }
      },
      {
        header: () => "Start",
        id: "start",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return original.startHour ? /* @__PURE__ */ jsxs(Fragment, { children: [
            original.start,
            " (",
            original.startHour,
            ")"
          ] }) : /* @__PURE__ */ jsx(Fragment, { children: original.start });
        }
      },
      {
        header: () => "End",
        id: "end",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return original.endHour ? /* @__PURE__ */ jsxs(Fragment, { children: [
            original.start,
            " (",
            original.endHour,
            ")"
          ] }) : original.end ? /* @__PURE__ */ jsx(Fragment, { children: original.end }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Length",
        id: "totalLength",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(Fragment, { children: [
            original.totalLength,
            " min = ",
            convertMinutesToClockHours(original.totalLength, polyglot)
          ] });
        }
      },
      {
        header: () => "Workday Count",
        id: "workdayCount",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(Fragment, { children: [
            original.workdayCount,
            " days"
          ] });
        }
      },
      {
        header: () => "Year 2021",
        id: "year2021",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(Fragment, { children: [
            original.year2021,
            " min = ",
            convertMinutesToClockHours(original.year2021, polyglot)
          ] });
        }
      },
      {
        header: () => "Year 2022",
        id: "year2022",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(Fragment, { children: [
            original.year2022,
            " min = ",
            convertMinutesToClockHours(original.year2022, polyglot)
          ] });
        }
      },
      {
        header: () => "Year 2023",
        id: "year2023",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(Fragment, { children: [
            original.year2023,
            " min = ",
            convertMinutesToClockHours(original.year2023, polyglot)
          ] });
        }
      },
      {
        header: () => "Potential Carry Over",
        id: "potentialCarryOver",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(Fragment, { children: [
            original.potentialCarryOver,
            " min = ",
            convertMinutesToClockHours(original.potentialCarryOver, polyglot)
          ] });
        }
      }
    ],
    [polyglot]
  );
  const handleRowClick = useCallback((row) => {
    setSelectedAbsence(row.original);
    setIsOpen(true);
  }, []);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsxs(Route, { path: SUPER_ADMIN_HELPER_COMPANY_TIME_ROUTE, children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
          "Helper page - Absences | ",
          (company == null ? void 0 : company.name) ? company.name : "",
          " [ ",
          companyId,
          " ]"
        ] }),
        showBack: true,
        backPath: generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: {}, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "userId",
            label: "User ID",
            value: search,
            onChange: (e) => {
              setSearch(e.target.value);
            },
            sx: { width: "200px" },
            clearText: () => setSearch("")
          }
        ),
        /* @__PURE__ */ jsx(Button, { sx: secondarySmallBtn, onClick: () => getFilteredAbsences(search), children: "Search" })
      ] }) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredAbsences,
          columnData: columns,
          hidePagination: true,
          maxUnpaginatedRows: 1e3,
          rowClick: handleRowClick
        }
      ) }),
      selectedAbsence && /* @__PURE__ */ jsx(
        HelperAbsenceEditDrawer,
        {
          isOpen,
          setIsOpen,
          absence: selectedAbsence,
          refreshAbsences: refreshCompanyAbsences,
          closePage: () => {
            setSelectedAbsence(null);
            setIsOpen(false);
          }
        }
      )
    ] })
  ] }) });
};
