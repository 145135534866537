"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltMdmFirewallPolicyEditor } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-firewall-policy-editor.component";
import { ZeltPolicyPayloadIdentifier } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { themeColors } from "@/v2/styles/colors.styles";
export const ZeltMdmFirewallSecurityPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected
}) => {
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const getPolicy = () => {
    var _a, _b, _c, _d, _e, _f;
    return {
      EnableFirewall: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableFirewallPolicy) == null ? void 0 : _a.EnableFirewall) != null ? _b : false,
      BlockAllIncoming: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableFirewallPolicy) == null ? void 0 : _c.BlockAllIncoming) != null ? _d : false,
      EnableStealthMode: (_f = (_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableFirewallPolicy) == null ? void 0 : _e.EnableStealthMode) != null ? _f : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.FILE_VAULT_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const initialValues = getPolicy();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Firewall" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setIsOpenEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Enable Firewall" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, !initialValues.EnableFirewall ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: initialValues.EnableFirewall ? "Enabled" : "Not set"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Block all incoming" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, !initialValues.BlockAllIncoming ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: initialValues.BlockAllIncoming ? "Enabled" : "Not set"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Enable stealth mode" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "caption",
                sx: __spreadValues({}, !initialValues.EnableStealthMode ? __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.black, mt: spacing.m10 })),
                children: initialValues.EnableStealthMode ? "Enabled" : "Not set"
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isOpenEditor, setIsOpen: setIsOpenEditor, children: /* @__PURE__ */ jsx(
      ZeltMdmFirewallPolicyEditor,
      {
        configurablePoliciesSelected,
        updatePolicies,
        isOpen: isOpenEditor,
        setIsOpen: setIsOpenEditor
      }
    ) })
  ] });
};
