"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { TASKS_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
import { TasksListPage } from "@/v2/feature/task/pages/tasks-list.page";
export const TaskCompanyRouter = ({ isManager }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["task:all"], path: TASKS_COMPANY_OVERVIEW_ROUTE, children: /* @__PURE__ */ jsx(TasksListPage, { reach: "company", isManager }) }),
    /* @__PURE__ */ jsx(Redirect, { to: TASKS_COMPANY_OVERVIEW_ROUTE })
  ] });
};
