"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { Box, Stack } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { useArray } from "@/hooks/use-array.hook";
import { ActionSelectionItemDeleteButton } from "@/v2/components/action-selection-group.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const SelectComponentDrawer = ({ value, onSave }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const options = useArray((_a = value == null ? void 0 : value.options) != null ? _a : [""]);
  const autoFocusFieldIdx = useRef(-1);
  const formik = useFormik({
    initialValues: {
      label: (_b = value == null ? void 0 : value.label) != null ? _b : "",
      required: (_c = value == null ? void 0 : value.required) != null ? _c : false
    },
    validationSchema: yup.object({
      label: yup.string().required(polyglot.t("SelectComponentDrawerProps.required"))
    }),
    onSubmit(values) {
      var _a2;
      onSave == null ? void 0 : onSave({
        type: "select",
        id: (_a2 = value == null ? void 0 : value.id) != null ? _a2 : uuidv4(),
        label: values.label,
        options: options.toArray().filter(
          (x, idx, array) => (
            // remove empty and duplicate values
            !!x && array.indexOf(x) === idx
          )
        ),
        required: values.required
      });
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("SelectComponentDrawerProps.field") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "label",
        label: polyglot.t("SelectComponentDrawerProps.label"),
        value: formik.values.label,
        onChange: formik.handleChange,
        maxLength: 50,
        autoFocus: autoFocusFieldIdx.current < 0,
        clearText: () => formik.setFieldValue("label", ""),
        helperText: formik.submitCount ? formik.errors.label : "",
        error: !!formik.submitCount && !!formik.errors.label
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g10 }, children: [
      options.map((option, idx) => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: option,
            label: polyglot.t("SelectComponentDrawerProps.optionNum", { num: idx + 1 }),
            value: option,
            onChange: (e) => {
              options.splice(idx, 1, e.target.value);
            },
            endAdornment: "none",
            maxLength: 50,
            autoFocus: autoFocusFieldIdx.current === idx
          }
        ),
        /* @__PURE__ */ jsx(ActionSelectionItemDeleteButton, { onClick: () => options.splice(idx, 1) })
      ] }, idx)),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "secondary",
          sizeVariant: "filter",
          style: { width: "fit-content" },
          disabled: options.includes(""),
          onClick: () => {
            autoFocusFieldIdx.current = options.length;
            options.push("");
          },
          children: polyglot.t("SelectComponentDrawerProps.add")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "required",
        label: polyglot.t("General.required"),
        checked: formik.values.required,
        onChange: formik.handleChange
      }
    ),
    !!formik.values.label && options.some((option) => option.trim()) && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, children: polyglot.t("General.save") }) })
  ] }) });
};
