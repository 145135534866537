"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box } from "@mui/material";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { FieldStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { definitionListSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const UserProfileTaxDetailsRemote = ({
  payrollId,
  countryCode,
  remotePayrollValues
}) => {
  const { data: payrollSchema } = useApiClient(PayrollEndpoints.getPayrollSchemaForCountry(countryCode), {
    suspense: false
  });
  const convertPropertyToFieldName = useCallback((schema, property) => {
    var _a, _b;
    return (_b = (_a = schema == null ? void 0 : schema.properties[property]) == null ? void 0 : _a.title) != null ? _b : property;
  }, []);
  const formatFieldValue = useCallback((schema, property, value) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const p = schema == null ? void 0 : schema.properties[property];
    return (_g = (_f = (_e = (_b = (_a = p == null ? void 0 : p.oneOf) == null ? void 0 : _a.find((item) => item.const === value)) == null ? void 0 : _b.title) != null ? _e : (_d = (_c = p == null ? void 0 : p.presentation.options) == null ? void 0 : _c.find((item) => item.value === value)) == null ? void 0 : _d.label) != null ? _f : value == null ? void 0 : value.toString()) != null ? _g : "";
  }, []);
  const convertPayrollValuesToFieldStructures = useCallback(
    (values, schema) => {
      return Object.entries(values).filter(([_, value]) => value !== null).filter(([key]) => {
        var _a;
        return ((_a = schema.properties[key]) == null ? void 0 : _a.type) !== "array";
      }).sort(
        ([a], [b]) => {
          var _a, _b, _c, _d, _e, _f;
          return ((_c = (_b = (_a = schema == null ? void 0 : schema.properties[a]) == null ? void 0 : _a.presentation) == null ? void 0 : _b.position) != null ? _c : 0) - ((_f = (_e = (_d = schema == null ? void 0 : schema.properties[b]) == null ? void 0 : _d.presentation) == null ? void 0 : _e.position) != null ? _f : 0);
        }
      ).flatMap(([property, value]) => {
        var _a;
        const propertySchema = (_a = schema == null ? void 0 : schema.properties) == null ? void 0 : _a[property];
        if ((propertySchema == null ? void 0 : propertySchema.type) === "object") {
          return convertPayrollValuesToFieldStructures(
            value,
            propertySchema
          );
        }
        return /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: convertPropertyToFieldName(schema, property),
            fieldValue: formatFieldValue(schema, property, value)
          }
        );
      });
    },
    [convertPropertyToFieldName, formatFieldValue]
  );
  if (!payrollSchema) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
    payrollId && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Payroll ID", fieldValue: payrollId }),
    convertPayrollValuesToFieldStructures(remotePayrollValues, payrollSchema.schema.data)
  ] });
};
