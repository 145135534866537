"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const TimePlannerEmptyState = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        verticalAlign: "center",
        height: "100%"
      },
      children: /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "caption",
          sx: {
            color: themeColors.Grey,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          },
          children: polyglot.t("TimePlannerEmptyState.noPolicies")
        }
      )
    }
  );
};
