"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledTextfield = styled(TextField)(
  ({
    fieldBackgroundColor = "Background",
    valueFont = "title4",
    fieldBackgroundHoverColor = "GreyHover",
    fieldPadding = "10px",
    borderRadius = "10px"
  }) => ({
    //this class hides background if form is preselected
    "input:-webkit-autofill,input:-webkit-autofill:focus": {
      transition: "background-color 600000s 0s, color 600000s 0s"
    },
    // these 2 classes hide the password default eye in MS Edge
    'input[type="password"]::-ms-reveal': {
      display: "none"
    },
    'input[type="password"]::-ms-clear': {
      display: "none"
    },
    // this hides default time picker icon on chromium based browsers
    'input[type="time"]::-webkit-calendar-picker-indicator': {
      background: "none",
      display: "none"
    },
    "&.MuiTextField-root": {
      //normal placeholder label
      "& label.MuiInputLabel-root.Mui-error": __spreadValues({
        color: `${themeColors.Grey} !important`
      }, themeFonts.caption),
      // label for untoched fields
      "& label.MuiFormLabel-root.MuiInputLabel-root": __spreadValues({
        color: `${themeColors.Grey} !important`
      }, themeFonts.caption),
      //shrunk placeholder on top
      "& label.MuiInputLabel-shrink.MuiInputLabel-sizeSmall": __spreadProps(__spreadValues({
        color: `${themeColors.DarkGrey} !important`
      }, themeFonts.captionSmall), {
        fontWeight: "400 !important",
        transform: "none",
        marginTop: "-1px"
      }),
      //typed in value
      "& input.MuiInputBase-input.MuiInput-input": __spreadProps(__spreadValues({
        color: `${themeColors.DarkGrey} !important`
      }, themeFonts[valueFont]), {
        padding: 0,
        "&.MuiInputBase-inputAdornedEnd": {
          paddingRight: 12
        },
        '&[value=""]': {
          fontWeight: 300
          // lighter placeholder text
        }
      }),
      //
      "& div.MuiInputBase-root.MuiInput-root": __spreadProps(__spreadValues({
        color: `${themeColors.DarkGrey} !important`,
        boxSizing: "border-box"
      }, themeFonts[valueFont]), {
        background: themeColors[fieldBackgroundColor],
        minHeight: "40px",
        borderRadius,
        padding: fieldPadding,
        display: "flex",
        alignItems: "center",
        border: `1px solid ${themeColors[fieldBackgroundColor]} !important`,
        ":hover:not(.Mui-disabled):not(.Mui-readOnly)": {
          background: themeColors[fieldBackgroundHoverColor]
        },
        "&.Mui-focused": {
          border: `1px solid ${themeColors.GreyMiddle}!important`
        }
      }),
      "& .Mui-readOnly": {
        cursor: "default"
      },
      "& div.MuiInputBase-root.MuiInput-root.Mui-error": {
        border: `1px solid ${themeColors.Red} !important`
      },
      //underlines variations
      // 1. normal state (non active)
      ".MuiInput-underline:before": {
        borderBottom: "none"
      },
      ".MuiInput-underline:after": {
        borderBottom: "none"
      },
      ".MuiInput-underline:not(.Mui-disabled):hover:before": {
        borderBottom: "none"
      },
      // 2. normal state (active)
      ".MuiInput-underline.Mui-focused:after": {
        borderBottom: "none"
      },
      // 2. normal state (active:error)
      ".MuiInput-underline.Mui-error:after": {
        borderBottom: "none"
      },
      // normal helper text
      "& p.MuiFormHelperText-root": __spreadProps(__spreadValues({}, themeFonts.captionSmall), {
        color: themeColors.Grey,
        marginTop: spacing.m5
      }),
      // error helper text
      "& p.MuiFormHelperText-root.Mui-error": {
        color: themeColors.RedDark
      },
      //input adornment buttons
      "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium": {
        padding: 0,
        position: "absolute",
        right: "10px",
        marginRight: 0
      },
      // hides background of selects auto selection
      "& div.MuiSelect-select": {
        backgroundColor: "transparent"
      },
      // select value font
      "& div.MuiSelect-select.MuiInputBase-input.MuiInput-input": __spreadProps(__spreadValues({
        color: `${themeColors.DarkGrey} !important`
      }, themeFonts[valueFont]), {
        padding: 0
      }),
      "& svg.MuiSelect-icon.MuiSelect-iconStandard": {
        fill: `${themeColors.Grey} !important`,
        top: "40%",
        right: "10px"
      },
      // endorment component alignment
      "& div.MuiAutocomplete-endAdornment": {
        top: "calc(50% - 5px)",
        display: "flex",
        alignItems: "center"
      },
      //clear indictaor autocomplete
      "& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator": {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        visibility: "visible"
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        WebkitAppearance: "none"
      },
      //hidden label field
      "& .MuiInputBase-hiddenLabel": {
        paddingTop: "0px !important"
      }
    },
    "& div.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-standard.MuiInputAdornment-sizeSmall": {
      fill: themeColors.Grey,
      marginRight: "10px"
    },
    "& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator": {
      fill: themeColors.Grey,
      marginRight: "10px"
    },
    "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
      display: "none"
    },
    "& span.notranslate": {
      display: "none"
    },
    textarea: {
      whiteSpace: "inherit",
      overflow: "inherit !important",
      "&::placeholder": __spreadValues({}, themeFonts.caption)
    }
  })
);
