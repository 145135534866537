"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { Typography } from "@v2/components/typography/typography.component";
import { themeColors } from "@v2/styles/colors.styles";
import { FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const ZeltMdmActivationLockPolicyEditor = ({
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const getPolicy = () => {
    var _a, _b;
    return {
      ActivationLockAllowedWhileSupervised: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableActivationLock) == null ? void 0 : _a.ActivationLockAllowedWhileSupervised) != null ? _b : false
    };
  };
  const initialValues = getPolicy();
  function analyzePolicyBeforeSave(policy) {
    return { ActivationLockAllowedWhileSupervised: policy.ActivationLockAllowedWhileSupervised };
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (form) => __async(void 0, null, function* () {
      const configurablePolicy = analyzePolicyBeforeSave(form);
      yield updatePolicies(__spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableActivationLock: configurablePolicy
      }));
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Activation lock" }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap80, mt: "20px" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Activation lock" }),
        /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: formik.values.ActivationLockAllowedWhileSupervised,
            name: "automaticOsUpdate",
            sx: { marginLeft: "auto" },
            onChange: (_e, enabled) => __async(void 0, null, function* () {
              formik.setFieldValue("ActivationLockAllowedWhileSupervised", enabled);
            })
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "80%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey, my: spacing.m5 }, children: "Enable the collection of code to bypass Activation Lock on enrolled devices. To reliably collect the bypass code, ask device owners to disable \u201CFind My\u201D and then enable it again in the settings of their device." }) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => formik.handleSubmit(),
          fullWidth: true,
          colorVariant: "primary",
          sizeVariant: "medium",
          children: "Save"
        }
      ) })
    ] })
  ] }) });
};
