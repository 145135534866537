"use strict";
import { jsx } from "react/jsx-runtime";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
export class TableColumn {
  define(columnDefinition) {
    const {
      id,
      header,
      enableSorting,
      sortingFn,
      size,
      maxSize,
      minSize,
      fieldName,
      formatter,
      parseRow,
      defaultValue
    } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      enableSorting,
      sortingFn,
      cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: parseRow ? parseRow(original) : original[fieldName] ? formatter ? formatter(original[fieldName]) : original[fieldName] : defaultValue ? defaultValue : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size,
      maxSize,
      minSize
    };
  }
}
