"use strict";
export var PayrunStates = /* @__PURE__ */ ((PayrunStates2) => {
  PayrunStates2["draft"] = "DRAFT";
  PayrunStates2["inProgress"] = "IN_PROGRESS";
  PayrunStates2["partiallyCompleted"] = "PARTIALLY_COMPLETED";
  PayrunStates2["completed"] = "COMPLETED";
  return PayrunStates2;
})(PayrunStates || {});
export const PayrollProviders = {
  zelt: { name: "Zelt" }
};
export var CompanyPayrollType = /* @__PURE__ */ ((CompanyPayrollType2) => {
  CompanyPayrollType2["UKPayroll"] = "uk-payroll";
  CompanyPayrollType2["GlobalPayroll"] = "global-payroll";
  return CompanyPayrollType2;
})(CompanyPayrollType || {});
