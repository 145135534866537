"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class TemplateAPI {
  static getTemplates(param) {
    return __async(this, null, function* () {
      const searchParams = new URLSearchParams(param);
      return (yield axios.get(`/apiv2/contract-template?${searchParams.toString()}`)).data;
    });
  }
  static createTemplate(parameters) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/contract-template", parameters)).data;
    });
  }
  static getTemplateById(parameters) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/contract-template/${parameters.templateId}`)).data;
    });
  }
  static updateTemplate(parameters) {
    return __async(this, null, function* () {
      return (yield axios.put("/apiv2/contract-template", parameters)).data;
    });
  }
  static patchTemplate(parameters) {
    return __async(this, null, function* () {
      return (yield axios.patch("/apiv2/contract-template", parameters)).data;
    });
  }
  static verifyTemplateParameters(parameters) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/contract-template/verify-template-parameters", parameters)).data;
    });
  }
  static getContractPreview(parameters) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/contract-template/preview", parameters)).data;
    });
  }
  static downloadContractTemplatePreview(parameters) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/contract-template/download`, parameters)).data;
    });
  }
  static deleteTemplate(parameters) {
    return __async(this, null, function* () {
      return (yield axios.put("/apiv2/contract-template/delete", parameters)).data;
    });
  }
  static duplicateTemplate(parameters) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/contract-template/duplicate", parameters)).data;
    });
  }
}
export class TemplateEndpoints {
  static getTemplateOptions() {
    return {
      url: "/apiv2/contract-template/options"
    };
  }
}
