"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import {
  EXPENSES_TEAM_OVERVIEW_ROUTE,
  EXPENSES_TEAM_ROUTE,
  EXPENSES_TEAM_NEW_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE
} from "@/lib/routes";
import { ExpensePage } from "@/v2/feature/expense/expense.page";
import { NewExpensePage } from "@/v2/feature/payments/pages/new-expense.page";
export const ExpenseTeamRouter = ({ isManager }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["expenses:manager"], path: EXPENSES_TEAM_OVERVIEW_ROUTE, exact: true, children: /* @__PURE__ */ jsx(ExpensePage, { reach: "team" }) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["expenses:manager"], path: EXPENSES_TEAM_NEW_EXPENSE_ROUTE, exact: true, children: /* @__PURE__ */ jsx(NewExpensePage, { reach: "team" }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["expenses:manager"], path: EXPENSE_TEAM_EDIT_EXPENSE_ROUTE, children: /* @__PURE__ */ jsx(NewExpensePage, { reach: "team", editMode: true }) }),
    /* @__PURE__ */ jsx(Redirect, { from: EXPENSES_TEAM_ROUTE, to: EXPENSES_TEAM_OVERVIEW_ROUTE, exact: true })
  ] });
};
