"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { timeSince } from "@/lib/moment.lib";
import { Map, Marker, Overlay, ZoomControl } from "pigeon-maps";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
const PigeonMap = ({ markers, zoomControl = false, zoom = 6 }) => {
  const [overlay, setOverlay] = useState({
    latitude: 0,
    longitude: 0,
    name: "",
    owner: "",
    time: /* @__PURE__ */ new Date()
  });
  const noOfValidMarkers = markers.filter((marker) => marker && marker.latitude && marker.longitude).length;
  const latitudeCenter = noOfValidMarkers ? markers.reduce((acc, m) => {
    if (m && m.latitude) {
      acc = acc + m.latitude;
    }
    return acc;
  }, 0) / noOfValidMarkers : 51.5072;
  const longitudeCenter = noOfValidMarkers ? markers.reduce((acc, m) => {
    if (m && m.longitude) {
      acc = acc + m.longitude;
    }
    return acc;
  }, 0) / noOfValidMarkers : 0.1276;
  return /* @__PURE__ */ jsxs(Map, { defaultCenter: [latitudeCenter, longitudeCenter], defaultZoom: zoom, onClick: () => setOverlay(null), children: [
    zoomControl && /* @__PURE__ */ jsx(ZoomControl, {}),
    markers.map(
      (marker, index) => marker && marker.longitude && marker.latitude ? /* @__PURE__ */ jsx(
        Marker,
        {
          width: 40,
          anchor: [marker.latitude, marker.longitude],
          onClick: () => setOverlay(marker)
        },
        index
      ) : /* @__PURE__ */ jsx(Fragment, {})
    ),
    overlay && overlay.latitude && overlay.longitude && /* @__PURE__ */ jsxs(Overlay, { anchor: [overlay.latitude, overlay.longitude], offset: [108, overlay.owner ? 120 : 100], children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            bgcolor: "white",
            width: "100%",
            p: 2,
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)"
          },
          className: "bg-white w-60 flex flex-col rounded-md p-2 shadow-xl",
          children: [
            overlay && overlay.owner && /* @__PURE__ */ jsx(Typography, { variant: "body2", fontWeight: "bold", children: overlay.owner }),
            overlay && overlay.name && /* @__PURE__ */ jsx(Typography, { variant: "body2", children: overlay.name }),
            overlay && overlay.time && /* @__PURE__ */ jsxs(Typography, { variant: "body2", children: [
              "Last seen: ",
              timeSince(new Date(overlay.time))
            ] })
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            width: 0,
            height: 0,
            mx: "auto",
            px: 0.1,
            borderTop: "10px solid white",
            borderRight: "10px solid transparent",
            borderLeft: "10px solid transparent"
          }
        }
      )
    ] })
  ] });
};
export default PigeonMap;
