"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { NotImplemented } from "@v2/feature/reports/reports-advanced/filters-components/not-implemented.component";
import { ReportSQLOperator } from "@v2/feature/reports/reports.interface";
export const NumberSelector = ({ operator, value, setValue, label }) => {
  const NumberOperatorsWithNoExtraField = useMemo(() => {
    return /* @__PURE__ */ new Set([ReportSQLOperator.isUnknown, ReportSQLOperator.isKnown]);
  }, []);
  const NumberOperatorsWithTextField = useMemo(() => {
    return /* @__PURE__ */ new Set([
      ReportSQLOperator.eq,
      ReportSQLOperator.gt,
      ReportSQLOperator.lt,
      ReportSQLOperator.gte,
      ReportSQLOperator.lte,
      ReportSQLOperator.not
    ]);
  }, []);
  return NumberOperatorsWithTextField.has(operator) ? /* @__PURE__ */ jsx(
    TextfieldComponent,
    {
      value,
      name: "value",
      label,
      onChange: (e) => {
        setValue(Number(e.target.value));
      },
      endAdornment: "none"
    }
  ) : operator === ReportSQLOperator.in ? /* @__PURE__ */ jsx(ArrayNumbersPicker, { value, setValue, label }) : operator === ReportSQLOperator.between ? /* @__PURE__ */ jsx(BetweenNumbersPicker, { value, setValue }) : NumberOperatorsWithNoExtraField.has(operator) ? null : /* @__PURE__ */ jsx(NotImplemented, {});
};
const BetweenNumbersPicker = ({ value, setValue }) => {
  const [localValues, setLocalValues] = useState([
    Array.isArray(value) && value[0] && typeof value[0] === "number" ? Number(value[0]) : 0,
    Array.isArray(value) && value[1] && typeof value[1] === "number" ? Number(value[1]) : 10
  ]);
  useEffect(() => {
    var _a, _b;
    const start = (_a = localValues[0]) != null ? _a : 0;
    const end = (_b = localValues[1]) != null ? _b : start + 10;
    if (!Number.isNaN(start) && !Number.isNaN(end)) setValue([start, end]);
  }, [localValues, setValue]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "10px" }, children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        value: String(localValues[0]),
        name: "value",
        label: "Start value",
        onChange: (e) => {
          const value2 = Number(e.target.value);
          setLocalValues((prev) => [value2, prev[1]]);
        },
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        value: String(localValues[1]),
        name: "value",
        label: "End value",
        onChange: (e) => {
          const value2 = Number(e.target.value);
          setLocalValues((prev) => [prev[0], value2]);
        },
        endAdornment: "none"
      }
    )
  ] });
};
const ArrayNumbersPicker = ({
  value,
  setValue,
  label
}) => {
  const [localValue, setLocalValue] = useState(Array.isArray(value) ? value.join(",") : "");
  useEffect(() => {
    const newValue = localValue.split(",").filter(Boolean).map((n) => Number(n.trim())).filter((v) => !Number.isNaN(v));
    setValue(newValue);
  }, [localValue, setValue]);
  return /* @__PURE__ */ jsx(
    TextfieldComponent,
    {
      value: localValue,
      name: "value",
      label,
      helperText: "eg.: 1,2,3",
      onChange: (e) => {
        setLocalValue(e.target.value);
      },
      endAdornment: "none"
    }
  );
};
