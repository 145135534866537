"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl, RouteScopesHas } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { PAYMENT_COMPANY_ROUTE, PAYMENT_ME_ROUTE, PAYMENT_TEAM_ROUTE } from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { SelectBankForm } from "@/v2/feature/payments/features/make-payment/components/select-bank-form-step.component";
import { CreatePaymentPage } from "@/v2/feature/payments/features/make-payment/pages/create-payment.page";
import { PaymentContextProvider } from "@/v2/feature/payments/features/make-payment/payment.context";
import { AuxPage } from "@/v2/feature/payments/pages/auxiliary.page";
import { PaymentsCompanyRouter } from "@/v2/feature/payments/payments.company.router";
import { PaymentsMeRouter } from "@/v2/feature/payments/payments.me.router";
import { PaymentsTeamRouter } from "@/v2/feature/payments/payments.team.router";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const PAYMENTS_ROUTE = "/money/company/payments";
export const PAYMENTS_SELECT_BANK_ROUTE = "/money/company/payments/overview/select-bank";
export const PAYMENTS_AUX_ROUTE = "/money/company/payments/overview/aux/:invoiceId";
export const PAYMENTS_CREATE_ROUTE = "/money/company/payments/overview/create";
export const PaymentsRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext(globalState.user);
  const { user } = globalState;
  const _currentUserIsAdmin = hasScopes(["payments:all"], getScopesContext(user));
  const { data: userSummaryWithReports } = useApiClient(UserEndpoints.getUserSummaryById(globalState.user.userId), {
    suspense: false
  });
  const isManager = useMemo(() => {
    var _a, _b, _c;
    const nonTerminatedDirectReports = (_c = (_b = (_a = userSummaryWithReports == null ? void 0 : userSummaryWithReports.directReports) == null ? void 0 : _a.filter(
      (d) => d.userEvent && d.userEvent.status && ["Employed", "Hired"].includes(d.userEvent.status)
    )) == null ? void 0 : _b.length) != null ? _c : 0;
    return nonTerminatedDirectReports > 0;
  }, [userSummaryWithReports]);
  return /* @__PURE__ */ jsx(PaymentContextProvider, { payments: [], children: /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["payments:all"], path: PAYMENTS_AUX_ROUTE, children: /* @__PURE__ */ jsx(AuxPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["payments:all"], path: PAYMENTS_SELECT_BANK_ROUTE, children: /* @__PURE__ */ jsx(SelectBankForm, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["payments:all"], path: PAYMENTS_CREATE_ROUTE, children: /* @__PURE__ */ jsx(CreatePaymentPage, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["payments:all"], path: PAYMENT_COMPANY_ROUTE, children: /* @__PURE__ */ jsx(PaymentsCompanyRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { context: scopesContext, scopes: ["payments"], path: PAYMENT_ME_ROUTE, children: /* @__PURE__ */ jsx(PaymentsMeRouter, {}) }),
    isManager && /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["payments:manager"], path: PAYMENT_TEAM_ROUTE, children: /* @__PURE__ */ jsx(PaymentsTeamRouter, { isManager }) }),
    canAccessScopes(globalState.user, ["payments:all"]) && /* @__PURE__ */ jsx(Redirect, { to: PAYMENT_COMPANY_ROUTE })
  ] }) });
};
