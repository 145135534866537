"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import { APIMonitoringDashboard } from "./pages/partner-api-monitoring.page";
import { SUPER_ADMIN_PARTNER_API_ROUTE } from "@/lib/routes";
export const SuperAdminPartnerAPIMonitoringRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_PARTNER_API_ROUTE, children: /* @__PURE__ */ jsx(APIMonitoringDashboard, {}) }),
    /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_PARTNER_API_ROUTE })
  ] });
};
