"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ExpenseAPI {
  static getApprovalListForNewExpense(userId, type) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/expense/approver-list-for-new-expense/${userId}/${type}`)).data;
    });
  }
  static createExpense(payload) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/expense", payload)).data;
    });
  }
  static updateExpense(expenseId, payload) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/expense/${expenseId}`, payload)).data;
    });
  }
  static updateExpenseApproval(expenseId, userId, payload) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/expense/${expenseId}/users/${userId}/approval`, payload)).data;
    });
  }
  static updateExpenseForceApproval(expenseId, userId, payload) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/expense/${expenseId}/users/${userId}/force-approval`, payload)).data;
    });
  }
  static bulkApprove(expenseIds) {
    return __async(this, null, function* () {
      return (yield axios.patch("/apiv2/expense/bulk-approve", expenseIds)).data;
    });
  }
  static deleteExpense(expenseId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/expense/${expenseId}`)).data;
    });
  }
  static getExpenseForPaymentId(userId, paymentId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/expense/users/${userId}/payments/${paymentId}`)).data;
    });
  }
  static getExpenseForOneOffPaymentId(userId, oneOffPaymentId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/expense/users/${userId}/one-off-payments/${oneOffPaymentId}`)).data;
    });
  }
  static syncExpenseWithExternalProvider(expenseId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/expense/${expenseId}/sync-with-accounting-provider`)).data;
    });
  }
  static deleteUploadReceipt(key) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/expense/delete-receipt", { remoteFileName: key });
    });
  }
  static getExpenseUploadReceiptURL() {
    return `/apiv2/expense/upload-receipt`;
  }
}
export class ExpenseEndpoints {
  static getEventSourceUrlForExternalExpenseSyncSuccess(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-expense-external-sync-success`
    };
  }
  static getCompanyExpenses() {
    return {
      url: "/apiv2/expense"
    };
  }
  static getTeamExpenses() {
    return {
      url: "/apiv2/expense/team"
    };
  }
  static getUserExpenses() {
    return {
      url: "/apiv2/expense/user"
    };
  }
  static getSpecificUserExpenses(userId) {
    return {
      url: `/apiv2/expense/user/${userId}`
    };
  }
  static getExpensesById(id, userId) {
    return {
      url: `/apiv2/expense/${id}/users/${userId}`
    };
  }
}
