"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { getAttendanceImportValidationSchema } from "@v2/feature/attendance/subfeatures/attendance-import/attendance-import.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const EditAttendanceEntryDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  attendance,
  attendanceTypes,
  allUsers,
  importHandler
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const formik = useFormik({
    initialValues: {
      id: attendance.id,
      userId: attendance.workEmail ? (_b = (_a = allUsers.find((u) => u.emailAddress === attendance.workEmail)) == null ? void 0 : _a.userId) != null ? _b : void 0 : void 0,
      firstName: (_c = attendance.firstName) != null ? _c : "",
      lastName: (_d = attendance.lastName) != null ? _d : "",
      workEmail: (_e = attendance.workEmail) != null ? _e : "",
      attendanceType: (_f = attendance.attendanceType) != null ? _f : "",
      logDate: (_g = attendance.logDate) != null ? _g : "",
      startHour: (_h = attendance.startHour) != null ? _h : "",
      endHour: (_i = attendance.endHour) != null ? _i : ""
    },
    validationSchema: getAttendanceImportValidationSchema(attendanceTypes),
    onSubmit: (values) => __async(void 0, null, function* () {
      importHandler(values);
      onClose();
    })
  });
  const attendanceTypeOptions = useMemo(() => {
    return attendanceTypes.map((o) => ({ label: o.name, value: o.name }));
  }, [attendanceTypes]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Edit entry" }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "firstName",
        label: "First Name",
        value: formik.values.firstName,
        onChange: formik.handleChange,
        error: formik.touched.firstName && !!formik.errors.firstName,
        helperText: (_j = formik.touched.firstName && formik.errors.firstName) != null ? _j : " "
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "lastName",
        label: "Last Name",
        value: formik.values.lastName,
        onChange: formik.handleChange,
        error: formik.touched.lastName && !!formik.errors.lastName,
        helperText: (_k = formik.touched.lastName && formik.errors.lastName) != null ? _k : " "
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "workEmail",
        label: "Work email",
        value: formik.values.workEmail,
        onChange: (e) => {
          formik.handleChange(e);
          const value = e.target.value;
          const user = allUsers.find((u) => u.emailAddress === value);
          formik.setFieldValue("userId", user ? user.userId : "");
        },
        error: formik.touched.workEmail && !!formik.errors.workEmail || formik.touched.userId && !!formik.errors.userId,
        helperText: (_l = formik.touched.workEmail && formik.errors.workEmail || formik.touched.userId && formik.errors.userId) != null ? _l : " "
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "attendanceType",
        label: "Attendance type",
        options: attendanceTypeOptions,
        value: formik.values.attendanceType,
        compareValue: formik.values.attendanceType,
        onChange: formik.handleChange,
        error: !!formik.errors.attendanceType && formik.touched.attendanceType,
        helperText: formik.touched.attendanceType && formik.errors.attendanceType
      }
    ),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: formik.values.logDate,
        onChange: (value) => {
          formik.setFieldValue("logDate", value);
        },
        name: "logDate",
        label: "Date",
        error: formik.touched.logDate && !!formik.errors.logDate,
        helperText: (_m = formik.touched.logDate && formik.errors.logDate) != null ? _m : " "
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g15 }, children: [
      /* @__PURE__ */ jsx(
        TimePickerComponent,
        {
          label: "Start hour",
          textFieldKey: "start",
          value: formik.values.startHour,
          onChange: (event) => {
            const time = event.target.value;
            if (isValidTimeString(time)) {
              formik.setFieldValue("start", time);
            }
          },
          error: formik.touched.startHour && !!formik.errors.startHour,
          helperText: formik.touched.startHour && formik.errors.startHour,
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(
        TimePickerComponent,
        {
          label: "End hour",
          textFieldKey: "end",
          value: formik.values.endHour,
          onChange: (event) => {
            const time = event.target.value;
            if (isValidTimeString(time)) {
              formik.setFieldValue("end", time);
            }
          },
          error: formik.touched.endHour && !!formik.errors.endHour,
          helperText: formik.touched.endHour && formik.errors.endHour,
          fullWidth: true
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { type: "submit", sizeVariant: "medium", colorVariant: "primary", fullWidth: true, children: "Update" }) })
  ] }) }) });
};
