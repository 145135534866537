"use strict";
export var CustomProfileFieldType = /* @__PURE__ */ ((CustomProfileFieldType2) => {
  CustomProfileFieldType2["Input"] = "Input";
  CustomProfileFieldType2["Select"] = "Select";
  CustomProfileFieldType2["Date"] = "Date";
  CustomProfileFieldType2["DateInput"] = "Date input";
  CustomProfileFieldType2["DatePicker"] = "Date picker";
  CustomProfileFieldType2["Number"] = "Number";
  CustomProfileFieldType2["Multiselect"] = "Multiselect";
  return CustomProfileFieldType2;
})(CustomProfileFieldType || {});
