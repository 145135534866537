"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Modal } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { LeavingEmployeeStates } from "@v2/feature/benefits/subfeature/pension/pension.interface";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  pt: 4,
  pb: 2,
  display: "flex",
  flexDirection: "column",
  gap: "20px"
};
export const LeavePensionModal = ({ isOpen, setIsOpen, userId, userPension, refresh }) => {
  const referenceDate = userPension.startDate && new LocalDate().toDateString() < userPension.startDate ? userPension.startDate : new LocalDate().toDateString();
  const oneMonthAfterStartDate = userPension.startDate ? new LocalDate(userPension.startDate) : new LocalDate();
  oneMonthAfterStartDate.getDate().setMonth(oneMonthAfterStartDate.getDate().getMonth() + 1);
  const hasMoreThanOneMonthInPension = oneMonthAfterStartDate.toDateString() < referenceDate;
  const reason = hasMoreThanOneMonthInPension ? LeavingEmployeeStates.CeasedMembership : LeavingEmployeeStates.OptOut;
  const [loading, setLoading] = useState(false);
  const [moreThanOneMonthInPension, setMoreThanOneMonthInPension] = useState(hasMoreThanOneMonthInPension);
  const [leaveDate, setLeaveDate] = useState(referenceDate);
  const [isLeavingReason, setIsLeavingReason] = useState(reason);
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasPensionAllScope = hasScopes(["pension:all"], scopesContext);
  const [showMessage] = useMessage();
  const leavePension = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield PensionAPI.optOutPensionEmployees(userId, {
        leaveDate,
        employeeState: isLeavingReason
      });
      showMessage("Successfully removed from workplace pension.", "success");
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const updateDate = (leaveDate2) => {
    setLeaveDate(leaveDate2);
    const oneMonthAfterStartDate2 = userPension.startDate ? new LocalDate(userPension.startDate) : new LocalDate();
    oneMonthAfterStartDate2.getDate().setMonth(oneMonthAfterStartDate2.getDate().getMonth() + 1);
    const moreThanOneMonthInPension2 = oneMonthAfterStartDate2.toDateString() < leaveDate2;
    const reason2 = moreThanOneMonthInPension2 ? LeavingEmployeeStates.CeasedMembership : LeavingEmployeeStates.OptOut;
    setIsLeavingReason(reason2);
    setMoreThanOneMonthInPension(moreThanOneMonthInPension2);
  };
  return /* @__PURE__ */ jsx(Modal, { open: isOpen, onClose: () => setIsOpen(false), children: /* @__PURE__ */ jsxs(Box, { sx: ModalStyle, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Leaving pension" }),
    hasPensionAllScope && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "By performing this action on behalf of the employee you confirm that they have expressed will to leave this pension scheme. A record of this action will be kept for your records. Note that your Pension Provider may still require this employee to notify them of the decision." }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: leaveDate,
        minDate: userPension.startDate,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            updateDate(value);
          }
        },
        name: "leaveDate",
        label: "Leave date"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: moreThanOneMonthInPension ? "Leave Pension" : "Opt Out",
        sizeVariant: "medium",
        colorVariant: "danger",
        onClick: leavePension,
        loading,
        type: "button"
      }
    ) })
  ] }) });
};
