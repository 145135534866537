"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  Box,
  Checkbox,
  FormControlLabel,
  styled
} from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const CheckboxIcon = styled("span")(() => ({
  borderRadius: radius.br5,
  width: 12,
  height: 12,
  border: `1px solid ${themeColors.Grey}`,
  "input:hover ~ &": {
    borderColor: themeColors.DarkGrey,
    transition: "border-color 0.4s"
  },
  "input:disabled ~ &": {
    borderColor: themeColors.middleGrey
  }
}));
const CheckedIcon = styled(CheckboxIcon)({
  backgroundColor: themeColors.DarkGrey,
  borderColor: themeColors.DarkGrey,
  backgroundImage: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    `<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fill-rule='evenodd' clip-rule='evenodd' d='M4.91634 8.26438L10.6219 1.67285L11.378 2.32732L4.96529 9.73579L0.644531 5.36808L1.35545 4.66481L4.91634 8.26438Z' fill='white'/>
    </svg>`
  )}")`,
  "input:hover ~ &": {
    backgroundColor: "#111",
    transition: "background-color 0.1s"
  },
  "input:disabled ~ &": {
    borderColor: themeColors.middleGrey,
    backgroundColor: themeColors.middleGrey
  }
});
export const CheckboxComponent = ({
  checked,
  checkboxSx,
  disabled,
  onChange,
  name,
  label,
  labelPlacement,
  labelSx,
  labelTooltip,
  description,
  sx
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(
      FormControlLabel,
      {
        control: /* @__PURE__ */ jsx(
          Checkbox,
          {
            disableRipple: true,
            checkedIcon: /* @__PURE__ */ jsx(CheckedIcon, {}),
            icon: /* @__PURE__ */ jsx(CheckboxIcon, {}),
            name,
            checked,
            disabled,
            onChange,
            sx: __spreadValues({ p: 0, m: 0, marginInlineEnd: spacing.m10 }, checkboxSx)
          }
        ),
        disabled,
        labelPlacement,
        label: typeof label === "string" ? /* @__PURE__ */ jsx(StyledTooltip, { title: !disabled && labelTooltip || "", children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: disabled ? "Grey" : "DarkGrey", sx: labelSx, children: label }) }) : label,
        sx: __spreadValues({ m: 0 }, sx)
      }
    ),
    description && /* @__PURE__ */ jsx(
      Typography,
      {
        variant: "caption",
        color: "Grey",
        sx: __spreadProps(__spreadValues({}, themeFonts.tooltip), {
          ml: "25px"
        }),
        children: description
      }
    )
  ] });
};
