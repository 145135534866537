"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAllowanceType } from "@v2/feature/absence/absence.interface";
import { isHourlyPolicy } from "@v2/feature/absence/absence.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { getAllowancesUnits } from "@v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyAllowanceEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(absencePolicy.allowance === null);
  const [allowanceUnits, setAllowanceUnits] = useState(isHourlyPolicy(absencePolicy) ? "hour" : "day");
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        allowance: values.allowance === null ? null : Number(values.allowance),
        allowanceType: values.allowanceType
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyAllowance(absencePolicy.id, policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceDrawerPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      allowance: absencePolicy.allowance,
      allowanceType: absencePolicy.allowanceType
    },
    validationSchema: yup.object({
      allowanceType: yup.string().required(polyglot.t("PolicyAllowanceEditDrawer.requiredField")),
      allowance: yup.number().when("allowanceType", {
        is: (val) => !!val && [AbsencePolicyAllowanceType.Limited, AbsencePolicyAllowanceType.LimitedHourly].includes(val),
        then: yup.number().min(0, polyglot.t("PolicyAllowanceEditDrawer.selectValidField")).typeError(polyglot.t("PolicyAllowanceEditDrawer.selectValidField")).required(polyglot.t("PolicyAllowanceEditDrawer.requiredField")),
        otherwise: yup.number().nullable().notRequired()
      })
    }),
    onSubmit
  });
  useEffect(() => {
    if (allowanceUnits === "day")
      formik.setFieldValue(
        "allowanceType",
        formik.values.allowance === null ? AbsencePolicyAllowanceType.Unlimited : AbsencePolicyAllowanceType.Limited
      );
    if (allowanceUnits === "hour")
      formik.setFieldValue(
        "allowanceType",
        formik.values.allowance === null ? AbsencePolicyAllowanceType.UnlimitedHourly : AbsencePolicyAllowanceType.LimitedHourly
      );
  }, [allowanceUnits, formik.values.allowance]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PolicyAllowanceEditDrawer.editAllowance") }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => {
                setIsUnlimited(true);
                formik.setFieldValue("allowance", null);
              },
              sizeVariant: "filter",
              colorVariant: isUnlimited ? "active" : "secondary",
              children: polyglot.t("PolicyAllowanceEditDrawer.unlimited")
            }
          ),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => {
                setIsUnlimited(false);
                formik.setFieldValue("allowance", 25);
              },
              sizeVariant: "filter",
              colorVariant: !isUnlimited ? "active" : "secondary",
              children: polyglot.t("PolicyAllowanceEditDrawer.limited")
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "allowanceUnits",
              label: polyglot.t("AbsencePolicyAllowanceSection.unit"),
              options: getAllowancesUnits(polyglot),
              value: allowanceUnits,
              onChange: (e) => setAllowanceUnits(e.target.value)
            }
          ),
          formik.values.allowance !== null && /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: polyglot.t("AbsencePolicyAllowanceSection.allowance"),
              name: "allowance",
              value: formik.values.allowance,
              onChange: (e) => {
                const value = e.target.value;
                const numberRegex = /^\d*(.)?[0,5]?$/g;
                if (value.match(numberRegex)) formik.setFieldValue("allowance", value);
              },
              fullWidth: true,
              size: "small",
              endAdornment: "none"
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true
          }
        ) })
      ] }) })
    }
  );
};
