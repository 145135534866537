"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { XMLValidator } from "fast-xml-parser";
import { Form, FormikProvider, useFormik } from "formik";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppDetailsAPI } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { EnableSSODisableMFAConflictConfirmDrawer } from "@/v2/feature/app-integration/features/app-details/components/app-details-sso-enable-mfa-conflict-drawer.component";
import { isDefined } from "@/v2/feature/user-onboarding/user-onboarding.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { arrayBufferToString } from "@/v2/util/string.util";
const ssoProdACSURL = "https://zelt-production.firebaseapp.com/__/auth/handler";
const ssoAzureADVideoInstructionsURL = "https://youtu.be/E5v8pNmPn_0";
export const SSOAzureAdUpdateForm = ({ mfaEnabled, ssoState, onUpdate }) => {
  const { polyglot } = usePolyglot();
  const SSO_APP_STUB = "sso-azure-ad";
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState("");
  const [ssoEnabled, setSsoEnabled] = useState(true);
  const [confirmEnablement, setConfirmEnablement] = useState(false);
  const [confirmEnableSSOAndDisableMFA, setConfirmEnableSSOAndDisableMFA] = useState(false);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    var _a, _b;
    try {
      const azureAdEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "azure-ad");
      const ssoGwEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === SSO_APP_STUB);
      const someEntryEnabled = ssoState == null ? void 0 : ssoState.some((eachEntry) => eachEntry.state.enabled);
      if (azureAdEntry) setCertificate((_a = azureAdEntry == null ? void 0 : azureAdEntry.state) == null ? void 0 : _a.certificate);
      if (ssoGwEntry) setCertificate((_b = ssoGwEntry == null ? void 0 : ssoGwEntry.state) == null ? void 0 : _b.certificate);
      setSsoEnabled(someEntryEnabled);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t("SSOAzureAdUpdateForm.errorMessages.fetch")}: ${JSON.stringify(error)}`, "error");
    }
  }), [showMessage, ssoState, polyglot]);
  useEffect(() => {
    refresh();
  }, [refresh, ssoState]);
  const saveSsoSamlMetadata = (values) => __async(void 0, null, function* () {
    var _a;
    try {
      if (!ssoEnabled && mfaEnabled && !confirmEnableSSOAndDisableMFA) {
        setConfirmEnablement(true);
        return;
      }
      if (!values || ((_a = Object.keys(values)) == null ? void 0 : _a.length) === 0) return;
      setLoading(true);
      const finalPayload = {
        certificate: isDefined(values.certificate) ? values.certificate : certificate,
        enabled: true
      };
      const encoded = btoa(JSON.stringify(finalPayload));
      if (values.certificate && encoded) {
        yield AppDetailsAPI.saveSSOSamlMetadata(SSO_APP_STUB, {
          sso_saml_metadata: encoded,
          disable_mfa: confirmEnableSSOAndDisableMFA
        });
      }
      showMessage(polyglot.t("SSOAzureAdUpdateForm.successMessages.update"), "success");
      onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t("SSOAzureAdUpdateForm.errorMessages.save")}: ${JSON.stringify(error)}`, "error");
    } finally {
      setLoading(false);
      refresh();
    }
  });
  const azureADEnabled = useMemo(() => {
    return !!ssoState.find((eachApp) => eachApp.app === SSO_APP_STUB && eachApp.state.enabled);
  }, [ssoState]);
  const disableSAMLSSO = () => __async(void 0, null, function* () {
    try {
      if (!azureADEnabled) return;
      setLoading(true);
      yield AppDetailsAPI.disableSSOSaml(SSO_APP_STUB);
      showMessage(polyglot.t("SSOAzureAdUpdateForm.successMessages.disable"), "success");
      onUpdate();
    } catch (error) {
      showMessage(`${polyglot.t("SSOAzureAdUpdateForm.errorMessages.disable")}: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
      refresh();
    }
  });
  const formik = useFormik({
    initialValues: {
      certificate,
      ssoEnabled
    },
    enableReinitialize: true,
    onSubmit: saveSsoSamlMetadata
  });
  const triggerEnableSSOAndDisableMFA = () => {
    setConfirmEnableSSOAndDisableMFA(true);
    formik.handleSubmit();
  };
  return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", mt: spacing.mt10 }, children: !azureADEnabled ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: [
      `${polyglot.t("SSOAzureAdUpdateForm.uploadIdP")}.`,
      /* @__PURE__ */ jsx("br", {}),
      `${polyglot.t("SSOAzureAdUpdateForm.findGuide")} `,
      /* @__PURE__ */ jsxs(Link, { target: "_blank", rel: "noreferrer", href: ssoAzureADVideoInstructionsURL, children: [
        polyglot.t("SSOAzureAdUpdateForm.video"),
        "."
      ] }),
      /* @__PURE__ */ jsx("br", {}),
      /* @__PURE__ */ jsx("br", {}),
      `${polyglot.t("SSOAzureAdUpdateForm.findIdP")}.`,
      /* @__PURE__ */ jsx("br", {}),
      /* @__PURE__ */ jsx("br", {}),
      `${polyglot.t("SSOAzureAdUpdateForm.uselink")}:`,
      /* @__PURE__ */ jsx("br", {}),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: `${ssoProdACSURL}` }),
      /* @__PURE__ */ jsx("br", {})
    ] }) }),
    /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, component: "section", children: /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(
          UploadInput,
          {
            skipUpload: true,
            onChange: (_, file) => __async(void 0, null, function* () {
              try {
                if (!file) return;
                const arrayBuffer = yield file.arrayBuffer();
                const xmlBuffer = yield arrayBufferToString(arrayBuffer);
                const xmlValidationResult = XMLValidator.validate(
                  xmlBuffer
                );
                if (typeof xmlValidationResult !== "boolean") {
                  formik.setFieldError(
                    "certificate",
                    polyglot.t("SSOAzureAdUpdateForm.errorMessages.invalidxml")
                  );
                  throw new Error("Invalid XML file");
                }
                formik.setFieldError("certificate", void 0);
                formik.setFieldValue("certificate", xmlBuffer, true);
              } catch (error) {
                showMessage(polyglot.t("SSOAzureAdUpdateForm.errorMessages.parsingcsv"), "error");
                console.error(":::: ERROR PARSING FILE :::::", error);
              }
            })
          }
        ) }) }) }),
        /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", my: spacing.m20 }, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            fullWidth: true,
            disabled: formik.values.ssoEnabled && !certificate && !formik.values.certificate || !formik.isValid || !formik.dirty || loading,
            sizeVariant: "large",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading
          }
        ) })
      ] }) }),
      /* @__PURE__ */ jsx(
        EnableSSODisableMFAConflictConfirmDrawer,
        {
          isOpen: confirmEnablement,
          setIsOpen: setConfirmEnablement,
          onClose: () => setConfirmEnablement(false),
          onConfirm: () => triggerEnableSSOAndDisableMFA()
        }
      )
    ] })
  ] }) : /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, component: "section", children: /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(
      UploadInput,
      {
        skipUpload: true,
        onChange: (_, file) => __async(void 0, null, function* () {
          try {
            if (!file) return;
            const arrayBuffer = yield file.arrayBuffer();
            const xmlBuffer = yield arrayBufferToString(arrayBuffer);
            const xmlValidationResult = XMLValidator.validate(
              xmlBuffer
            );
            if (typeof xmlValidationResult !== "boolean") {
              formik.setFieldError(
                "certificate",
                polyglot.t("SSOAzureAdUpdateForm.errorMessages.invalidxml")
              );
              throw new Error("Invalid XML file");
            }
            formik.setFieldError("certificate", void 0);
            formik.setFieldValue("certificate", xmlBuffer, true);
          } catch (error) {
            showMessage(polyglot.t("SSOAzureAdUpdateForm.errorMessages.parsingcsv"), "error");
            console.error(":::: ERROR PARSING FILE :::::", error);
          }
        })
      }
    ) }) }) }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", mt: spacing.m20, gap: spacing.g10, justifyContent: "center" }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => {
            disableSAMLSSO();
          },
          disabled: loading,
          children: polyglot.t("SSOAzureAdUpdateForm.disable")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          disabled: formik.values.ssoEnabled && !certificate && !formik.values.certificate || !formik.isValid || !formik.dirty || loading,
          sizeVariant: "medium",
          colorVariant: "primary",
          name: polyglot.t("SSOAzureAdUpdateForm.update"),
          loading
        }
      )
    ] })
  ] }) }) }) });
};
