"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { borders } from "@v2/styles/borders.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Comment } from "@/images/growth/Comment.svg";
import { ReactComponent as SmileHappy } from "@/images/growth/Smile_Happy.svg";
export const GrowthChip = ({ options }) => {
  const icon = useMemo(() => {
    if (!options.icon) return null;
    if (typeof options.icon === "string") {
      if (options.icon === "smile") return /* @__PURE__ */ jsx(SmileHappy, __spreadValues({}, iconSize));
      if (options.icon === "comment") return /* @__PURE__ */ jsx(Comment, __spreadValues({}, iconSize));
      return /* @__PURE__ */ jsx(GrowthCircle, { color: options.icon });
    }
    return options.icon;
  }, [options.icon]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        border: borders.background,
        borderRadius: radius.br15,
        px: spacing.p8
      },
      children: [
        icon && options.iconPosition !== "end" && /* @__PURE__ */ jsx(Box, { children: icon }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: options.text }),
        icon && options.iconPosition === "end" && /* @__PURE__ */ jsx(Box, { children: icon })
      ]
    }
  );
};
export const GrowthCircle = ({ color, size = "8px" }) => /* @__PURE__ */ jsx(Box, { sx: { borderRadius: "15px", width: size, height: size, bgcolor: color } });
