"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { EditUserPayrollNonUK } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll-non-uk.page";
import { EditUserPayrollUKPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll-uk.page";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { getApiErrorMessage } from "@/v2/infrastructure/api-error/api-error.util";
import { isUKCountryCode } from "@/v2/infrastructure/country/country.util";
export const EditUserPayrollPage = ({
  userId,
  mode,
  record,
  close,
  isUserUpdating,
  requireReason,
  onUpdateStarted,
  onUpdateFinished
}) => {
  const { polyglot } = usePolyglot();
  const [payrollSchema, setPayrollSchema] = useState();
  const { payrollId, payrollValues, remotePayrollValues, inPayroll } = record != null ? record : {};
  const { userPayrollId, effectiveDate, changeReason } = mode === "edit" && record || {};
  const [showMessage] = useMessage();
  const isUKPayroll = useMemo(() => {
    if (mode === "edit") {
      return record ? isUKCountryCode(record.countryCode) : void 0;
    }
    if (payrollSchema === null) return true;
    if (!payrollSchema) return void 0;
    return isUKCountryCode(payrollSchema.countryCode);
  }, [mode, payrollSchema, record]);
  useEffect(() => {
    if (record == null ? void 0 : record.countryCode) {
      PayrollAPI.getPayrollSchemaForCountry(record.countryCode).then(setPayrollSchema);
    } else {
      PayrollAPI.getUserPayrollSchema(userId).then(setPayrollSchema);
    }
  }, [userId, record == null ? void 0 : record.countryCode]);
  const savePayrollRecord = useCallback(
    (record2) => __async(void 0, null, function* () {
      let userUpdated = false;
      onUpdateStarted == null ? void 0 : onUpdateStarted();
      try {
        userUpdated = mode === "append" || mode === "initial" ? (yield PayrollAPI.addUserPayrollRecord(userId, record2), true) : (yield PayrollAPI.updateUserPayrollRecord(userId, record2), true);
        showMessage(polyglot.t("EditUserPayrollPage.successMessages.update"), "success");
      } catch (error) {
        showMessage(
          `${polyglot.t("EditUserPayrollPage.errorMessages.update")}. ${getApiErrorMessage(error)}`,
          "warning"
        );
      } finally {
        onUpdateFinished == null ? void 0 : onUpdateFinished(userUpdated);
      }
      return userUpdated;
    }),
    [polyglot, mode, onUpdateFinished, onUpdateStarted, showMessage, userId]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: {
      initial: polyglot.t("EditUserPayrollPage.initial"),
      edit: polyglot.t("EditUserPayrollPage.edit"),
      append: polyglot.t("EditUserPayrollPage.append")
    }[mode] }),
    /* @__PURE__ */ jsxs(Stack, { gap: spacing.sm, children: [
      !inPayroll && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("EditUserPayrollPage.notInPayroll") }),
      /* @__PURE__ */ jsx(UserCell, { userId, nameVariant: "title4" })
    ] }),
    isUKPayroll && /* @__PURE__ */ jsx(
      EditUserPayrollUKPage,
      {
        payrollId,
        payrollValues,
        effectiveDate,
        changeReason,
        savePayrollRecord,
        close,
        isUserUpdating,
        requireReason,
        userPayrollId
      }
    ),
    !isUKPayroll && payrollSchema && /* @__PURE__ */ jsx(
      EditUserPayrollNonUK,
      {
        payrollId,
        payrollSchema,
        remotePayrollValues,
        effectiveDate,
        changeReason,
        savePayrollRecord,
        close,
        isUserUpdating,
        requireReason,
        userPayrollId
      }
    )
  ] });
};
