"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Button, IconButton, Stack } from "@mui/material";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Loader } from "@/images/side-bar-icons/Loader.svg";
import { ReactComponent as Minus } from "@/images/side-bar-icons/Minus.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import {
  canAddToPayroll,
  canRemoveFromPayroll,
  hasPayrollMissingFields
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/payroll-company-employees.util";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableIconButtonSx, tablePrimaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function EmployeeActionsCell({
  user,
  payroll,
  openDrawer,
  addToPayroll,
  removeFromPayroll,
  disabled,
  updating,
  currentPayrun
}) {
  const { polyglot } = usePolyglot();
  const hasMissingFields = hasPayrollMissingFields(user);
  const isUKPayroll = !!payroll.employer;
  const showAddButton = (!hasMissingFields || !isUKPayroll) && canAddToPayroll(user, payroll);
  const disableAddButtonForDraftPayrun = (currentPayrun == null ? void 0 : currentPayrun.state) === PayrunStates.draft;
  const showRemoveButton = (!hasMissingFields || !isUKPayroll) && canRemoveFromPayroll(user, payroll);
  const disabledAddButtonTooltipText = disableAddButtonForDraftPayrun ? "This payrun is already open. To add employee, delete the currently open payrun." : null;
  return /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", justifyContent: "flex-end", gap: "5px", whiteSpace: "nowrap", children: [
    hasMissingFields && /* @__PURE__ */ jsx(
      Button,
      {
        disableRipple: true,
        sx: __spreadProps(__spreadValues({}, secondaryCTABtn), { p: spacing.p15, color: themeColors.DarkGrey }),
        onClick: (e) => {
          e.stopPropagation();
          openDrawer("missing-info", user);
        },
        disabled: disabled || updating,
        children: polyglot.t("PayrunTable.completeUserInfo")
      }
    ),
    !hasMissingFields && isUKPayroll && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tableIconButtonSx,
        onClick: (e) => {
          e.stopPropagation();
          openDrawer("edit", user);
        },
        disabled: disabled || updating,
        title: polyglot.t("PayrunTable.viewOrChangePayroll"),
        children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
      }
    ),
    showAddButton && /* @__PURE__ */ jsx(StyledTooltip, { title: disabledAddButtonTooltipText, children: /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tablePrimaryIconButtonSx,
        onClick: (e) => __async(this, null, function* () {
          e.stopPropagation();
          yield addToPayroll(user.userId);
        }),
        disabled: disabled || disableAddButtonForDraftPayrun,
        title: polyglot.t("PayrunTable.addToCompanyPayroll"),
        children: updating ? /* @__PURE__ */ jsx(Loader, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
      }
    ) }),
    showRemoveButton && /* @__PURE__ */ jsx(
      IconButton,
      {
        sx: tablePrimaryIconButtonSx,
        onClick: (e) => __async(this, null, function* () {
          e.stopPropagation();
          yield removeFromPayroll(user.userId);
        }),
        disabled,
        title: polyglot.t("PayrunTable.removeFromCompanyPayroll"),
        children: updating ? /* @__PURE__ */ jsx(Loader, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Minus, __spreadValues({}, iconSize))
      }
    )
  ] });
}
