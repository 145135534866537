"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { AttendanceScheduleEditApprovalDrawer } from "@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-approval-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AttendanceSettingsApprovalSection = ({
  schedule,
  refreshSchedule,
  approvalRules,
  refreshApprovalRules
}) => {
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AttendanceSchedule.approval"),
      onEdit: () => {
        setEdit(true);
      },
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AttendanceSchedule.approvalRule"),
                    value: schedule.approvalRule ? schedule.approvalRule.name : polyglot.t("AttendanceSchedule.noApprovalRequired")
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          AttendanceScheduleEditApprovalDrawer,
          {
            isOpen: edit,
            setIsOpen: setEdit,
            attendanceSchedule: schedule,
            refresh: refreshSchedule,
            approvalRules,
            refreshApprovalRules
          }
        )
      ]
    }
  );
};
