"use strict";
export var ChargeTypes = /* @__PURE__ */ ((ChargeTypes2) => {
  ChargeTypes2["IdCheck"] = "id-check";
  ChargeTypes2["DevicePurchaseNew"] = "device-purchase-new";
  ChargeTypes2["DevicePurchaseUsed"] = "device-purchase-used";
  ChargeTypes2["LogisticsRecharge"] = "logistics-recharge";
  ChargeTypes2["EarlyReturnFee"] = "early-return-fee";
  ChargeTypes2["DeviceAccessories"] = "device-accessories";
  return ChargeTypes2;
})(ChargeTypes || {});
