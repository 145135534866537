"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ParticipantsUpsertTriggersModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/rolling/participants-upsert-triggers-modal.component";
import { ParticipantsSharedTable } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/shared/participants-shared-table.component";
import { countFilters } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { CycleState, CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsRollingView = ({
  reviewCycle,
  isEditable,
  refresh,
  reviewParticipants,
  reviewParticipantsLoading,
  reach
}) => {
  const [isTriggerOpen, setIsTriggerOpen] = useState(false);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const generateReport = useCallback(() => __async(void 0, null, function* () {
    const { enrolmentTriggerFilters } = reviewCycle;
    setIsGenerating(true);
    if (!enrolmentTriggerFilters) {
      setFilteredParticipants([]);
      return;
    }
    try {
      const response = yield ReviewCycleAPI.generateRollingReviewParticipants(enrolmentTriggerFilters, reach);
      const userIds = (response == null ? void 0 : response.map((u) => u.userId)) || [];
      if (reviewCycle.state === CycleState.Draft && reviewCycle.enrolmentTriggerFilters)
        setFilteredParticipants(userIds);
    } catch (error) {
      setFilteredParticipants([]);
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setIsGenerating(false);
    }
  }), [reviewCycle, polyglot, showMessage, reach]);
  useEffect(() => {
    if (reviewCycle.type === CycleType.Rolling) {
      if (reviewCycle.state === CycleState.Draft && reviewCycle.enrolmentTriggerFilters) {
        generateReport();
      } else if (reviewCycle.state === CycleState.Ongoing || reviewCycle.state === CycleState.Paused) {
        setFilteredParticipants(reviewCycle.revieweeId);
      }
    }
  }, [reviewCycle, generateReport]);
  const refreshRollingView = () => __async(void 0, null, function* () {
    yield refresh();
    yield generateReport();
  });
  const filterCount = useMemo(() => {
    if (reviewCycle.enrolmentTriggerFilters && reviewCycle.enrolmentTriggerFilters !== null && Object.keys(reviewCycle.enrolmentTriggerFilters).length > 0)
      return countFilters(reviewCycle.enrolmentTriggerFilters);
    return 0;
  }, [reviewCycle]);
  const qualifyingText = "Any qualifying participant that matches selected filters will be added to this review at 12:00PM GMT. Cycle owner will receive a notification when it happens and any other relevant participants will be invited at the same time.";
  return /* @__PURE__ */ jsxs("div", { children: [
    filterCount > 0 ? /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            title: "Enrolment trigger",
            onEdit: () => setIsTriggerOpen(true),
            items: [
              {
                type: SectionItemType.TextLine,
                value: "Participants will be invited based on the qualifying criteria."
              },
              {
                type: SectionItemType.TextLine,
                value: /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { paddingBottom: spacing.s2 }, children: qualifyingText })
              },
              {
                type: SectionItemType.Pair,
                label: "Filters",
                value: reviewParticipantsLoading || isGenerating ? /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "40%", height: 15, rowGap: "2px" }) : `${filterCount} filter(s)` || "Please create an enrolment trigger"
              },
              {
                type: SectionItemType.Pair,
                label: "Currently qualifying",
                value: reviewParticipantsLoading || isGenerating ? /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "40%", height: 15, rowGap: "2px" }) : filteredParticipants.length > 0 ? `${filteredParticipants.length} participant(s)` : "No participants qualify the enrolment trigger"
              }
            ]
          }
        ]
      }
    ) : /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            headerWidth: "100%",
            title: "Enrolment trigger",
            items: [
              {
                type: SectionItemType.TextLine,
                value: "Participants will be invited based on the qualifying criteria."
              },
              {
                type: SectionItemType.TextLine,
                value: qualifyingText
              },
              {
                type: SectionItemType.Component,
                value: isEditable ? /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    sizeVariant: "small",
                    colorVariant: "primary",
                    onClick: () => setIsTriggerOpen(true),
                    style: { marginTop: spacing.m16 },
                    children: "Add enrolment trigger"
                  }
                ) : null
              }
            ]
          }
        ]
      }
    ),
    reviewCycle.state !== CycleState.Draft && /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            contentWidth: "100%",
            headerWidth: "100%",
            headerSx: { mt: spacing.s3 },
            title: (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Completed || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Ongoing ? "Participants part of this cycle" : "Participants who will be invited",
            items: [
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(
                  ParticipantsSharedTable,
                  {
                    reviewCycle,
                    reviewParticipants,
                    reviewParticipantsLoading,
                    refresh,
                    isEditable
                  }
                )
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      ParticipantsUpsertTriggersModal,
      {
        reviewCycle,
        isOpen: isTriggerOpen,
        setIsOpen: setIsTriggerOpen,
        onClose: () => setIsTriggerOpen(false),
        refresh: () => __async(void 0, null, function* () {
          return refreshRollingView();
        }),
        reach
      }
    )
  ] });
};
