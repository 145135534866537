"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { Route, Switch } from "react-router-dom";
import { PayrollImportCsvPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-import/pages/payroll-import-csv.page";
const ENTITY_IMPORT_WIZARD_ENTRIES_CSV_ROUTE = "/settings/import/wizard/payroll/csv";
export function PayrollImportRouter(props) {
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { path: ENTITY_IMPORT_WIZARD_ENTRIES_CSV_ROUTE, children: /* @__PURE__ */ jsx(PayrollImportCsvPage, __spreadValues({}, props)) }) });
}
