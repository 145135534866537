"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import {
  InsuranceProvider
} from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
const INSURANCE_PROVIDERS_OPTIONS = [
  { label: "Vitality", value: InsuranceProvider.Vitality },
  { label: "AXA", value: InsuranceProvider.AXA },
  { label: "Bupa - Dental", value: InsuranceProvider.BupaDental },
  { label: "Bupa - PMI", value: InsuranceProvider.BupaPMI },
  { label: "Aviva", value: InsuranceProvider.Aviva },
  { label: "Freedom", value: InsuranceProvider.Freedom }
];
export const ManageInsurancePolicyDrawer = ({
  isOpen,
  setIsOpen,
  quoteId,
  policy,
  onSave,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(ManageInsurancePolicyDrawerContent, { quoteId, policy, refresh, onSave }) });
};
const ManageInsurancePolicyDrawerContent = ({
  quoteId,
  policy,
  onSave,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      providerName: (_a = policy == null ? void 0 : policy.providerName) != null ? _a : InsuranceProvider.Vitality,
      policyStartDate: policy ? policy.startDate : new LocalDate().toDateString(),
      policyEndDate: policy ? policy.endDate : new LocalDate().toDateString(),
      policyNumber: (_b = policy == null ? void 0 : policy.policyNumber) != null ? _b : "",
      claimsPhoneNumber: (_c = policy == null ? void 0 : policy.claimsPhone) != null ? _c : "",
      supportPhoneNumber: (_d = policy == null ? void 0 : policy.supportPhone) != null ? _d : "",
      insuranceBrokerPhoneNumber: (_e = policy == null ? void 0 : policy.insuranceBrokerPhone) != null ? _e : ""
    },
    validationSchema: yup.object({
      providerName: yup.string().required("Provider name is required"),
      policyStartDate: yup.string().test(dateFieldTest).required("Policy start date is required"),
      policyEndDate: yup.string().test(dateFieldTest).required("Policy end date is required"),
      policyNumber: yup.string().required("Policy number is required"),
      claimsPhoneNumber: yup.string().required("Claims phone is required"),
      supportPhoneNumber: yup.string().required("Support phone is required"),
      insuranceBrokerPhoneNumber: yup.string().required("Insurance broker phone is required")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      if (!quoteId && !policy) {
        showMessage("Quote Id or Policy is required.", "error");
        return;
      }
      quoteId ? yield createPolicy(quoteId, values) : yield updatePolicy(policy, values);
    })
  });
  const createPolicy = (quoteId2, values) => __async(void 0, null, function* () {
    setLoading(true);
    try {
      yield InsuranceAPI.createPolicyForInsuranceAsSuperAdmin(quoteId2, values);
      yield refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not create the policy. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const updatePolicy = (policy2, values) => __async(void 0, null, function* () {
    setLoading(true);
    try {
      yield InsuranceAPI.updatePolicyForInsuranceAsSuperAdmin(policy2.id, values);
      yield refresh();
      onSave();
    } catch (error) {
      showMessage(`Could not update the policy. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: policy ? "Update policy" : "Create policy" }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.mb20 }), children: "Policy details" }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "providerName",
        label: "Provider name",
        options: INSURANCE_PROVIDERS_OPTIONS,
        value: formik.values.providerName,
        onChange: formik.handleChange,
        compareValue: formik.values.providerName,
        error: !!formik.errors.providerName && formik.touched.providerName,
        helperText: formik.touched.providerName && formik.errors.providerName
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "policyStartDate",
        label: "Policy start date",
        inputFormat: "DD/MM/YYYY",
        value: (_f = formik.values.policyStartDate) != null ? _f : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("policyStartDate", value);
          }
        },
        error: !!formik.errors.policyStartDate && Boolean(formik.touched.policyStartDate),
        helperText: formik.errors.policyStartDate && Boolean(formik.touched.policyStartDate)
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "policyEndDate",
        label: "Policy end date",
        inputFormat: "DD/MM/YYYY",
        value: (_g = formik.values.policyEndDate) != null ? _g : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("policyEndDate", value);
          }
        },
        error: !!formik.errors.policyEndDate && Boolean(formik.touched.policyEndDate),
        helperText: formik.errors.policyEndDate && Boolean(formik.touched.policyEndDate),
        disablePast: true
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Policy number",
        name: "policyNumber",
        value: formik.values.policyNumber,
        onChange: formik.handleChange,
        error: formik.touched.policyNumber && Boolean(formik.errors.policyNumber),
        helperText: formik.touched.policyNumber && formik.errors.policyNumber,
        size: "small",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.mb20, mt: spacing.mt40 }), children: "Contact details" }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Claims phone number",
        name: "claimsPhoneNumber",
        value: formik.values.claimsPhoneNumber,
        onChange: formik.handleChange,
        error: formik.touched.claimsPhoneNumber && Boolean(formik.errors.claimsPhoneNumber),
        helperText: formik.touched.claimsPhoneNumber && formik.errors.claimsPhoneNumber,
        size: "small",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Support phone number",
        name: "supportPhoneNumber",
        value: formik.values.supportPhoneNumber,
        onChange: formik.handleChange,
        error: formik.touched.supportPhoneNumber && Boolean(formik.errors.supportPhoneNumber),
        helperText: formik.touched.supportPhoneNumber && formik.errors.supportPhoneNumber,
        size: "small",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Insurance broker phone number",
        name: "insuranceBrokerPhoneNumber",
        value: formik.values.insuranceBrokerPhoneNumber,
        onChange: formik.handleChange,
        error: formik.touched.insuranceBrokerPhoneNumber && Boolean(formik.errors.insuranceBrokerPhoneNumber),
        helperText: formik.touched.insuranceBrokerPhoneNumber && formik.errors.insuranceBrokerPhoneNumber,
        size: "small",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.m30 }), children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading, sizeVariant: "medium", colorVariant: "secondary", fullWidth: true }) })
  ] }) });
};
