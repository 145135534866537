"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Back } from "@/images/fields/Left.svg";
import "@/v2/components/domain-side-menu-content.scss";
import { DomainSideMenu } from "@/v2/components/domain-side-menu.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { renderSingleItem, renderSubMenu } from "@/v2/feature/app-layout/features/v2/second-menu/second-menu.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { showCustomDomainSidebar, showDomainSideBar } from "@/v2/util/app-layout.util";
function decomposePath(path) {
  const parts = path.split("/");
  return {
    domain: parts[1] || "",
    subDomain: parts[2] || "",
    subMenu: parts[3] || ""
  };
}
export const DomainSideMenuContent = ({
  title,
  customTitle,
  subtitle,
  pageConfig,
  showBack,
  backPath,
  type = "Domain"
}) => {
  const [state, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isTabletScreen = useMediaQuery(
    `(min-width:${theme.breakpoints.values.sm + 1}px) and (max-width:${theme.breakpoints.values.md - 1}px)`
  );
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { pathname } = useLocation();
  const { subDomain } = useMemo(() => decomposePath(pathname), [pathname]);
  const handleAction = useCallback(
    (list) => {
      if (list.path) routerHistory.push(list.path);
    },
    [routerHistory]
  );
  const handleParentClick = useCallback(
    (list) => {
      var _a;
      const firstChild = (_a = list.subMenu) == null ? void 0 : _a.find((s) => !s.isHidden && s.path);
      if (firstChild && firstChild.path) {
        routerHistory.push(firstChild.path);
      }
    },
    [routerHistory]
  );
  const handleSubmenuClick = useCallback(
    (sm) => {
      if (!sm.hasChildren && sm.path) {
        routerHistory.push(sm.path);
      }
    },
    [routerHistory]
  );
  const shouldRenderEmpty = useMemo(() => {
    return type === "Domain" && (showDomainSideBar(pathname) && (isTabletScreen || isMobileScreen) && !state.showSettingBar && !isDesktopScreen || !showDomainSideBar(pathname)) || type === "Custom" && (showCustomDomainSidebar(pathname) && (isTabletScreen || isMobileScreen) && !state.showSettingBar && !isDesktopScreen || !showCustomDomainSidebar(pathname));
  }, [pathname, isTabletScreen, isMobileScreen, isDesktopScreen, state.showSettingBar, type]);
  const renderContent = useMemo(
    () => /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          background: themeColors.white,
          display: "flex",
          height: "100%",
          flexDirection: "column",
          boxSizing: "border-box"
        },
        children: /* @__PURE__ */ jsxs(
          "div",
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.s1,
              width: "100%",
              paddingTop: spacing.s2,
              paddingBottom: spacing.m25
            },
            children: [
              /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: spacing.g5,
                    boxSizing: "border-box"
                  },
                  children: [
                    showBack && /* @__PURE__ */ jsx(
                      IconButton,
                      {
                        sizeVariant: "small",
                        colorVariant: "secondary",
                        onClick: () => __async(void 0, null, function* () {
                          if (backPath) routerHistory.push(backPath);
                          else routerHistory.goBack();
                        }),
                        children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
                      }
                    ),
                    title && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", flexGrow: 1, mx: spacing.g5 }, children: [
                      /* @__PURE__ */ jsx(
                        Typography,
                        {
                          variant: "title2",
                          sx: {
                            maxWidth: "125px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis"
                          },
                          children: title
                        }
                      ),
                      subtitle && /* @__PURE__ */ jsx(
                        Typography,
                        {
                          variant: title.length > 20 && subtitle.length <= 20 ? "caption" : "captionSmall",
                          sx: { maxWidth: "125px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" },
                          children: subtitle
                        }
                      )
                    ] }),
                    !title && /* @__PURE__ */ jsx(Box, { sx: { flexGrow: 1 } }),
                    (isTabletScreen || isMobileScreen) && /* @__PURE__ */ jsx(
                      IconButton,
                      {
                        sizeVariant: "small",
                        colorVariant: "secondary",
                        onClick: () => {
                          dispatch({ type: GlobalStateActions.UPDATE_SHOW_SETTING_BAR, payload: false });
                        },
                        children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey }))
                      }
                    )
                  ]
                }
              ),
              customTitle,
              /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({ width: "100%" }, spacing.mt20), { display: "flex", flexDirection: "column", gap: spacing.g20 }), children: pageConfig == null ? void 0 : pageConfig.map((list) => {
                var _a;
                if (list.isHidden) return null;
                return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { padding: "0px 10px" }, children: list.title }),
                  /* @__PURE__ */ jsx(Fragment, { children: list.hasChildren && !list.isHidden && ((_a = list.subItems) == null ? void 0 : _a.map(
                    (l, i) => l.hasChildren ? renderSubMenu(l, handleParentClick, pathname, subDomain, handleSubmenuClick, i) : renderSingleItem(l, handleAction, pathname, i)
                  )) })
                ] }, list.stub);
              }) })
            ]
          }
        )
      }
    ),
    [
      showBack,
      title,
      subtitle,
      pageConfig,
      backPath,
      routerHistory,
      handleParentClick,
      pathname,
      subDomain,
      handleSubmenuClick,
      handleAction,
      isMobileScreen,
      isTabletScreen,
      dispatch,
      customTitle
    ]
  );
  if (shouldRenderEmpty) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsx(DomainSideMenu, { children: renderContent });
};
