"use strict";
import { jsx } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { SECONDARY_CHART_COLOR } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const FullDay = (polyglot) => {
  return { value: "full", label: polyglot.t("CalendarInterface.fullDay") };
};
export const MorningOnly = (polyglot) => {
  return { value: "morningOnly", label: polyglot.t("CalendarInterface.morningOnly") };
};
export const AfternoonOnly = (polyglot) => {
  return { value: "afternoonOnly", label: polyglot.t("CalendarInterface.afternoonOnly") };
};
export const OtherLength = (polyglot) => {
  return { value: "otherLength", label: polyglot.t("CalendarInterface.otherLength") };
};
export const fullOrMorningOnlyValues = (polyglot) => {
  return [FullDay(polyglot), MorningOnly(polyglot)];
};
export const fullOrAfternoonOnlyValues = (polyglot) => {
  return [FullDay(polyglot), AfternoonOnly(polyglot)];
};
export const fullOrHalfDayValues = (polyglot) => {
  return [FullDay(polyglot), MorningOnly(polyglot), AfternoonOnly(polyglot), OtherLength(polyglot)];
};
export const colorOptions = SECONDARY_CHART_COLOR.map((s) => {
  return {
    value: s,
    label: /* @__PURE__ */ jsx(Typography, { sx: { bgcolor: s, width: "14px", height: "14px", borderRadius: "100%", mt: spacing.m4 } })
  };
});
export var ViewSettingEnum = /* @__PURE__ */ ((ViewSettingEnum2) => {
  ViewSettingEnum2["Company"] = "company";
  ViewSettingEnum2["Me"] = "me";
  ViewSettingEnum2["Team"] = "team";
  return ViewSettingEnum2;
})(ViewSettingEnum || {});
export const ViewSettingValueArray = ["company" /* Company */, "team" /* Team */, "me" /* Me */];
export const ViewSettingsLabel = (polyglot) => {
  return {
    ["company" /* Company */]: polyglot == null ? void 0 : polyglot.t("CalendarSettingsPage.company"),
    ["team" /* Team */]: polyglot == null ? void 0 : polyglot.t("CalendarSettingsPage.team"),
    ["me" /* Me */]: polyglot == null ? void 0 : polyglot.t("CalendarSettingsPage.me")
  };
};
export const ViewSettingsOptionLabel = (polyglot) => {
  return [
    { label: polyglot == null ? void 0 : polyglot.t("CalendarSettingsPage.company"), value: "company" /* Company */ },
    { label: polyglot == null ? void 0 : polyglot.t("CalendarSettingsPage.team"), value: "team" /* Team */ },
    { label: polyglot == null ? void 0 : polyglot.t("CalendarSettingsPage.me"), value: "me" /* Me */ }
  ];
};
export var iCalLinkModeEnum = /* @__PURE__ */ ((iCalLinkModeEnum2) => {
  iCalLinkModeEnum2["ALL"] = "all";
  iCalLinkModeEnum2["FILTERED"] = "filtered";
  return iCalLinkModeEnum2;
})(iCalLinkModeEnum || {});
export var PaginationPartsEnum = /* @__PURE__ */ ((PaginationPartsEnum2) => {
  PaginationPartsEnum2["totalItems"] = "total_items";
  return PaginationPartsEnum2;
})(PaginationPartsEnum || {});
export var CalendarView = /* @__PURE__ */ ((CalendarView2) => {
  CalendarView2["Month"] = "Month";
  CalendarView2["Week"] = "Week";
  return CalendarView2;
})(CalendarView || {});
export var CalendarActionType = /* @__PURE__ */ ((CalendarActionType2) => {
  CalendarActionType2["SET_ACTIVE_VIEW"] = "SET_ACTIVE_VIEW";
  CalendarActionType2["SET_RANGE"] = "SET_RANGE";
  CalendarActionType2["SET_TIMELINE"] = "SET_TIMELINE";
  CalendarActionType2["SET_SELECTED_DATE"] = "SET_SELECTED_DATE";
  return CalendarActionType2;
})(CalendarActionType || {});
