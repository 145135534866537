"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { getDocumentTypeLabel } from "@/lib/documents";
import {
  USER_ONBOARDING_ABOUT,
  USER_ONBOARDING_DOCUMENTS,
  USER_ONBOARDING_PAYROLL,
  USER_ONBOARDING_TASKS
} from "@/lib/routes";
import { TeamSlide } from "@/v2/components/team-slide.component";
import { ProfileModal } from "@/v2/components/theme-components/profile-modal.component";
import { UserProfileBar } from "@/v2/feature/user/components/user-profile-bar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserLifecycleStatuses } from "@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface";
import { OnboardingContracts } from "@/v2/feature/user-onboarding/onboarding-by-user/components/onboarding-contracts/onboarding-contracts.component";
import { OnboardingActionCard } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-action-card.component";
import { OnboardingContractActionCard } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-contract-action-card.component";
import { OnboardingUserStats } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-user-stats.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingOverview = ({
  companyName,
  progressPercent,
  requiredSteps,
  missingFields,
  documents,
  documentTypes,
  pendingContracts,
  incompleteTasks,
  checklistNames,
  startDate,
  sx,
  taskCount,
  userId,
  publicURL
}) => {
  const { polyglot, useInitLanguage } = usePolyglot();
  useInitLanguage();
  const router = useHistory();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const { trackPage } = useJune();
  const [contractToSign, setContractToSign] = useState(null);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserId, setSetSelectedUserId] = useState(null);
  const cards = useMemo(
    () => [...companyUsers].filter((u) => {
      var _a;
      return ((_a = u.userEvent) == null ? void 0 : _a.status) === UserLifecycleStatuses.Employed;
    }).sort(() => 0.5 - Math.random()).map((user) => {
      var _a, _b;
      return {
        user,
        cardProps: {
          subTitle: (_b = (_a = user.role) == null ? void 0 : _a.jobPosition) == null ? void 0 : _b.title
        },
        action: () => {
          setIsOpen(true);
          setSetSelectedUserId(user.userId);
        },
        publicURL
      };
    }),
    [companyUsers, publicURL]
  );
  const missingDocuments = useMemo(() => documents.filter((d) => !d.fileUuid), [documents]);
  const actionCards = useMemo(() => {
    return requiredSteps.map((step, idx) => {
      if (step.startsWith("contracts")) {
        const stepNumberMatch = step.match(/contracts(?:_step(\d+))?/);
        let stepNumber = 1;
        if (stepNumberMatch && stepNumberMatch[1]) {
          stepNumber = parseInt(stepNumberMatch[1], 10);
        }
        return /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsx(
          OnboardingContractActionCard,
          {
            step: stepNumber,
            pendingContracts,
            setContractToSign,
            setOpenMissingFieldContractModal
          }
        ) }, `action-card-${idx}`);
      } else {
        switch (step) {
          case "basic":
            return /* @__PURE__ */ jsx(
              OnboardingActionCard,
              {
                label: polyglot.t("OnboardingOverview.completeProfile"),
                completed: missingFields.basic.length === 0,
                onStartClick: () => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId })),
                showEdit: missingFields.basic.length === 0,
                onEditClick: () => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId }))
              },
              `action-card-${idx}`
            );
          case "benefits":
            return /* @__PURE__ */ jsx(
              OnboardingActionCard,
              {
                label: polyglot.t("OnboardingOverview.selectBenefits")
              },
              `action-card-${idx}`
            );
          case "documents":
            return /* @__PURE__ */ jsx(
              OnboardingActionCard,
              {
                label: polyglot.t("OnboardingOverview.uploadDocuments"),
                completed: missingDocuments.length === 0,
                onStartClick: () => router.push(generatePath(USER_ONBOARDING_DOCUMENTS, { userId })),
                chips: missingDocuments.map((d) => getDocumentTypeLabel(d, documentTypes))
              },
              `action-card-${idx}`
            );
          case "payroll":
            return /* @__PURE__ */ jsx(
              OnboardingActionCard,
              {
                label: polyglot.t("OnboardingOverview.completePayroll"),
                completed: missingFields.payroll.length === 0,
                onStartClick: () => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId })),
                showEdit: missingFields.payroll.length === 0,
                onEditClick: () => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId }))
              },
              `action-card-${idx}`
            );
          case "tasks":
            return /* @__PURE__ */ jsx(
              OnboardingActionCard,
              {
                label: polyglot.t("OnboardingOverview.tasks"),
                completed: incompleteTasks.length === 0,
                onStartClick: () => router.push(generatePath(USER_ONBOARDING_TASKS, { userId })),
                chips: checklistNames
              },
              `action-card-${idx}`
            );
          default:
            return null;
        }
      }
    });
  }, [
    pendingContracts,
    checklistNames,
    polyglot,
    documentTypes,
    incompleteTasks.length,
    missingDocuments,
    missingFields.basic.length,
    missingFields.payroll.length,
    requiredSteps,
    router,
    userId
  ]);
  useEffect(() => {
    trackPage("User onboarding");
  }, []);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", gap: "140px" }, sx), children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: "0 0", maxWidth: "470px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.getStarted") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { mt: spacing.m10, textAlign: "justify", color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.welcomeMessage", { companyName }) }),
      cards && /* @__PURE__ */ jsx(TeamSlide, { cards, title: "Your colleagues", sx: { mt: spacing.mt40 }, width: 470 })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, maxWidth: "1000px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.onboardingTasks") }),
      /* @__PURE__ */ jsx(
        OnboardingUserStats,
        {
          progressPercent,
          startDate,
          pendingTaskCount: taskCount,
          sx: { mt: spacing.mt20, mb: spacing.mb5 }
        }
      ),
      /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.m25 }, children: actionCards.map((card, idx) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
        idx > 0 && /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        card
      ] }, `action-card-${idx}`)) }),
      /* @__PURE__ */ jsx(
        ProfileModal,
        {
          isOpen,
          setIsOpen,
          onClose: () => {
            setIsOpen(false);
          },
          children: selectedUserId ? /* @__PURE__ */ jsx(UserProfileBar, { userId: selectedUserId }) : void 0
        }
      ),
      contractToSign && /* @__PURE__ */ jsx(
        OnboardingContracts,
        {
          selectedContract: contractToSign,
          setOpenModal: setOpenMissingFieldContractModal,
          openModal: openMissingFieldContractModal
        }
      )
    ] })
  ] });
};
