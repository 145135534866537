"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TIME_POLICIES_ROUTE } from "@/lib/routes";
export const AbsencePolicyDeleteConfirmationDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh,
  requestsCount
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const deletePolicy = useCallback(() => __async(void 0, null, function* () {
    if (requestsCount > 0) return;
    try {
      setLoading(true);
      yield AbsenceAPI.deleteAbsencePolicyById(absencePolicy.id);
      yield refresh();
      history.push(SETTINGS_TIME_POLICIES_ROUTE);
    } catch (error) {
      showMessage(
        polyglot.t("AbsencePolicyDeleteConfirmationDrawer.error", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [polyglot, requestsCount, absencePolicy.id, refresh, history, showMessage]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AbsencePolicyDeleteConfirmationDrawer.title") }),
    requestsCount > 0 ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsencePolicyDeleteConfirmationDrawer.description", { smart_count: requestsCount }) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AbsencePolicyDeleteConfirmationDrawer.descriptionCanDelete") }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          type: "button",
          sizeVariant: "medium",
          colorVariant: "danger",
          name: polyglot.t("General.delete"),
          loading,
          onClick: deletePolicy,
          disabled: requestsCount > 0
        }
      )
    ] })
  ] }) });
};
