"use strict";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useCallback, useMemo } from "react";
export function useProfileFields() {
  const { data: userDataFields } = useApiClient(CustomProfileFormEndpoints.listAllFieldsProfileHook(), {
    suspense: false
  });
  const defaultFieldsByStub = useMemo(() => {
    var _a;
    return new Map((_a = userDataFields == null ? void 0 : userDataFields.dataFieldsDefault) == null ? void 0 : _a.map((f) => [f.fieldStub, f]));
  }, [userDataFields == null ? void 0 : userDataFields.dataFieldsDefault]);
  const getDefaultField = useCallback(
    (fieldStub) => {
      return defaultFieldsByStub.get(fieldStub);
    },
    [defaultFieldsByStub]
  );
  const getCustomFieldsForForm = useCallback(
    (formName) => {
      var _a;
      return (_a = userDataFields == null ? void 0 : userDataFields.dataFieldsCustom.filter((f) => {
        var _a2;
        return ((_a2 = f.form) == null ? void 0 : _a2.formName) === formName;
      })) != null ? _a : [];
    },
    [userDataFields == null ? void 0 : userDataFields.dataFieldsCustom]
  );
  return { getCustomFieldsForForm, getDefaultField, loading: !userDataFields };
}
