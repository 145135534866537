"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Content2Columns } from "@v2/feature/app-layout/features/main-content/layouts/components/content-2-columns.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { addMonths, addWeeks, getMonth, getWeek, subMonths, subWeeks } from "date-fns";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { AttendanceAPI } from "@/v2/feature/attendance/attendance.api";
import { UserAttendancesDetail } from "@/v2/feature/attendance/company/sections/user-attendances-detail.section";
import { AttendanceViewTopHeaderActions } from "@/v2/feature/attendance/components/attendance-view-top-header-actions.component";
import { ListSectionComponent } from "@/v2/feature/attendance/components/list-section.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserAPI } from "@/v2/feature/user/user.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { LocalDate } from "@/v2/util/local-date";
const buildFilterString = (type, date) => `type=${type}&date=${new LocalDate(date).toDateString()}`;
export const AttendanceTeamView = ({ view }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const { getCachedUserById, loaded } = useCachedUsers();
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [attendanceRequests, setAttendanceRequests] = useState(
    void 0
  );
  const [selectedUser, setSelectedUser] = useState(void 0);
  const [selectedUserAttendances, setSelectedUserAttendances] = useState(
    void 0
  );
  const [state, dispatch] = useContext(GlobalContext);
  const [rangeType, setRangeType] = useState(
    (_d = (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.attendance) == null ? void 0 : _b.table) == null ? void 0 : _c.rangeType) != null ? _d : "Month"
  );
  const [selectedDate, setSelectedDate] = useState(() => new LocalDate().getDate());
  const [buttonTitle, setButtonTitle] = useState(
    () => rangeType === "Month" ? new LocalDate().toLocaleDateString(void 0, { month: "short", year: "numeric" }) : polyglot.t("UserAttendancePage.weekNum", { weekNum: getWeek(/* @__PURE__ */ new Date()) })
  );
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState(buildFilterString(rangeType, new LocalDate().getDate()));
  const [showMessage] = useMessage();
  const getUserAttendances = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    const { pageIndex, pageSize } = pagination;
    setLoading(true);
    setUserLoading(true);
    setSelectedUser(void 0);
    setSelectedUserAttendances(void 0);
    try {
      const requestsResponse = yield AttendanceAPI.findTeamAttendancePerUserId(
        pageIndex,
        pageSize,
        searchInput,
        filterString
      );
      setTotalPages(requestsResponse.totalPages);
      setTotalItems(requestsResponse.totalItems);
      setAttendanceRequests(requestsResponse.items);
      if (!((_a2 = requestsResponse == null ? void 0 : requestsResponse.items) == null ? void 0 : _a2.length)) {
        return;
      }
      const userAttendances = yield AttendanceAPI.getAttendanceTableByUserId(
        requestsResponse.items[0].userId,
        filterString
      );
      setSelectedUser(requestsResponse.items[0].userId);
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserAttendances", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
      setUserLoading(false);
    }
  }), [polyglot, pagination, filterString, searchInput, showMessage]);
  useEffect(() => {
    getUserAttendances();
  }, [getUserAttendances]);
  const onPageChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };
  const listUsers = useMemo(() => {
    return attendanceRequests ? attendanceRequests.map((n) => {
      var _a2, _b2;
      return {
        id: n.userId,
        name: (_b2 = (_a2 = getCachedUserById(n.userId)) == null ? void 0 : _a2.displayName) != null ? _b2 : "",
        details: polyglot.t("AttendanceDomain.noOfPending", { smart_count: n.count }),
        avatar: /* @__PURE__ */ jsx(UserAvatar, { userId: n.userId }),
        showAlert: n.count > 0,
        alertColor: n.count > 0 ? themeColors.Orange : "none"
      };
    }) : [];
  }, [attendanceRequests, getCachedUserById, polyglot]);
  const handleUserAttendance = (userId) => __async(void 0, null, function* () {
    setUserLoading(true);
    try {
      setSelectedUser(userId);
      const userAttendances = yield AttendanceAPI.getAttendanceTableByUserId(userId, filterString);
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserAttendanceDetails", {
          nestError: nestErrorMessage(error)
        }),
        "error"
      );
      setSelectedUser(void 0);
    } finally {
      setUserLoading(false);
    }
  });
  const navigateDate = (operation) => {
    const isWeek = rangeType === "Week";
    const operationFn = operation === "add" ? isWeek ? addWeeks : addMonths : isWeek ? subWeeks : subMonths;
    const updatedSelectedDate = operationFn(selectedDate, 1);
    const title = isWeek ? polyglot.t("UserAttendancePage.weekNum", { weekNum: getWeek(updatedSelectedDate) }) : new LocalDate(updatedSelectedDate).toLocaleDateString(void 0, { month: "short", year: "numeric" });
    setSelectedDate(updatedSelectedDate);
    setButtonTitle(title);
    setFilterString(buildFilterString(rangeType, updatedSelectedDate));
  };
  const navigateBack = () => navigateDate("sub");
  const navigateNext = () => navigateDate("add");
  const handleRange = (type) => __async(void 0, null, function* () {
    const updatedButtonTitle = type === "Month" ? new LocalDate(selectedDate).toLocaleDateString(void 0, { month: "short", year: "numeric" }) : polyglot.t("UserAttendancePage.weekNum", { weekNum: getWeek(selectedDate) });
    setRangeType(type);
    setButtonTitle(updatedButtonTitle);
    setFilterString(buildFilterString(type, selectedDate));
    const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("attendance", "table", "rangeType", type);
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: updatedGlobalUser
    });
  });
  const recenterToday = () => {
    const todayDate = new LocalDate().getDate();
    if (rangeType === "Month") {
      setSelectedDate(todayDate);
      setButtonTitle(() => new LocalDate(todayDate).toLocaleDateString(void 0, { month: "short", year: "numeric" }));
      setFilterString(buildFilterString(rangeType, todayDate));
    } else {
      setSelectedDate(todayDate);
      const weekTitle = polyglot.t("UserAttendancePage.weekNum", { weekNum: getWeek(todayDate) });
      setButtonTitle(weekTitle);
      setFilterString(buildFilterString(rangeType, todayDate));
    }
  };
  const refreshListUsers = () => __async(void 0, null, function* () {
    var _a2;
    const { pageIndex, pageSize } = pagination;
    setLoading(true);
    setSelectedUser(void 0);
    setSelectedUserAttendances(void 0);
    try {
      if (selectedUser) {
        const requestsResponse = yield AttendanceAPI.findTeamAttendancePerUserId(
          pageIndex,
          pageSize,
          searchInput,
          filterString
        );
        setTotalPages(requestsResponse.totalPages);
        setAttendanceRequests(requestsResponse.items);
        if (!((_a2 = requestsResponse == null ? void 0 : requestsResponse.items) == null ? void 0 : _a2.length)) return;
        const user = requestsResponse.items.some((u) => u.userId === selectedUser) ? selectedUser : requestsResponse.items[0].userId;
        setUserLoading(true);
        const userAttendances = yield AttendanceAPI.getAttendanceTableByUserId(user, filterString);
        setSelectedUser(user);
        setSelectedUserAttendances(userAttendances);
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserAttendances", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
      setUserLoading(false);
    }
  });
  const showToday = useMemo(() => {
    const todayDate = new LocalDate().getDate();
    return getWeek(selectedDate) !== getWeek(todayDate) || getMonth(selectedDate) !== getMonth(todayDate);
  }, [selectedDate]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AttendanceDomain.Attendance") }),
        actions: /* @__PURE__ */ jsx(
          AttendanceViewTopHeaderActions,
          {
            handleRange,
            rangeType,
            navigateBack,
            navigateNext,
            buttonTitle,
            recenterToday,
            showToday
          }
        ),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: { pt: 0 }, children: /* @__PURE__ */ jsx(
      Content2Columns,
      {
        column1: /* @__PURE__ */ jsx(
          ListSectionComponent,
          {
            searchInput,
            setSearchInput,
            setPagination,
            listUsers,
            pagination,
            totalPages,
            totalItems,
            onPageChange,
            handleUserAttendance,
            loading: loading || !loaded,
            selectedUser
          }
        ),
        column2: /* @__PURE__ */ jsx(
          UserAttendancesDetail,
          {
            selectedUserAttendances,
            selectedUser,
            loading: loading || userLoading,
            view,
            refresh: refreshListUsers,
            rangeType,
            selectedDate
          }
        ),
        borderLeft: true
      }
    ) })
  ] });
};
