"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { v4 } from "uuid";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomProfileFormAPI } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { sortSelectOptions } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { cleanPayCodeValue } from "@/v2/feature/payroll/features/payroll-global/global-payroll.util";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getFieldByType = (fieldType, field, setField, options, setOptions, polyglot) => {
  switch (fieldType) {
    case "Input":
    case "Number":
    case "Date picker":
    case "Date input":
      return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          value: field.fieldName,
          label: polyglot.t("getFieldByType.label", { type: field.fieldType }),
          onChange: (e) => {
            const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { fieldName: e.target.value });
            setField(allFieldsUpdate);
          },
          autoFocus: true
        }
      ) });
    case "Select":
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g40 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            value: field.fieldName,
            label: polyglot.t("getFieldByType.label", { type: field.fieldType }),
            onChange: (e) => {
              const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { fieldName: e.target.value });
              setField(allFieldsUpdate);
            },
            autoFocus: true
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g40 }, children: sortSelectOptions(options).map((o) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              value: o.value,
              label: o.key,
              onChange: (e) => {
                const currentOpt = { label: e.target.value, value: e.target.value, key: o.key };
                const opts = sortSelectOptions(
                  options.filter((opt) => opt.key !== o.key).concat(currentOpt)
                );
                const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { options: { allOptions: opts } });
                setField(allFieldsUpdate);
                setOptions(opts);
              }
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: () => {
                const opts = sortSelectOptions(options.filter((opt) => opt.key !== o.key));
                const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { options: { allOptions: [...opts] } });
                setField(allFieldsUpdate);
                setOptions([...opts]);
              },
              children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
            }
          )
        ] }, o.key)) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: /* @__PURE__ */ jsxs(
          Button,
          {
            sx: secondaryCTABtn,
            onClick: () => setOptions([
              ...options,
              { label: "Next Option", value: "Next Option", key: `Answer ${options.length + 1}` }
            ]),
            children: [
              /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
              " \xA0",
              polyglot.t("getFieldByType.addAnswer")
            ]
          }
        ) })
      ] });
  }
};
export const CustomFieldDrawer = ({ form, fieldType, selectedField, onClose }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [linkToPayroll, setLinkToPayroll] = useState(!!(selectedField == null ? void 0 : selectedField.paycode));
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState(
    selectedField != null ? selectedField : {
      fieldType,
      isRequired: false,
      fieldName: "",
      fieldId: v4(),
      formId: form.formId,
      companyId: form.companyId,
      placeholder: "",
      defaultValue: "",
      isHidden: false,
      isDefault: false,
      fieldStub: null,
      isEditable: true,
      options: {
        allOptions: []
      },
      paycode: null,
      displayOrder: null
    }
  );
  const [showMessage] = useMessage();
  const [options, setOptions] = useState(
    selectedField && (selectedField == null ? void 0 : selectedField.options) ? (_a = selectedField == null ? void 0 : selectedField.options) == null ? void 0 : _a.allOptions : [
      { label: polyglot.t("CustomFieldDrawer.optionA"), value: "Option A", key: "Answer 1" },
      { label: "Option B", value: polyglot.t("CustomFieldDrawer.optionA"), key: "Answer 2" }
    ]
  );
  const saveField = () => __async(void 0, null, function* () {
    setLoading(true);
    try {
      yield CustomProfileFormAPI.addFields(__spreadProps(__spreadValues({}, field), {
        paycode: linkToPayroll && field.paycode || null,
        options: field.fieldType === "Select" ? field.options : { allOptions: [] }
      }));
      showMessage(
        `${selectedField ? polyglot.t("CustomFieldDrawer.successMessages.updated") : polyglot.t("CustomFieldDrawer.successMessages.added")} ${polyglot.t("CustomFieldDrawer.successMessages.addedField")}`,
        "success"
      );
      onClose();
    } catch (error) {
      showMessage(`${polyglot.t("CustomFieldDrawer.errorMessages.notAdded")}. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const deleteField = () => __async(void 0, null, function* () {
    try {
      if (selectedField) {
        yield CustomProfileFormAPI.deleteField(selectedField == null ? void 0 : selectedField.fieldId);
        showMessage(`${polyglot.t("CustomFieldDrawer.successMessages.deleted")}`, "success");
      }
      onClose();
    } catch (error) {
      showMessage(`${polyglot.t("CustomFieldDrawer.errorMessages.notAdded")}. ${nestErrorMessage(error)}`, "error");
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { textTransform: selectedField ? "inherit" : "capitalize" }, children: selectedField ? selectedField.fieldName : polyglot.t("CustomFieldDrawer.addField") }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("CustomFieldDrawer.hideField"),
          name: "hidden",
          checked: field.isHidden,
          disabled: field.isRequired,
          value: "hidden",
          onChange: (_, checked) => {
            const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { isHidden: checked });
            setField(allFieldsUpdate);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("CustomFieldDrawer.requiredField"),
          name: "required",
          checked: field.isRequired,
          disabled: field.isHidden,
          value: "required",
          onChange: (_, checked) => {
            const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { isRequired: checked });
            setField(allFieldsUpdate);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { children: !field.isDefault && getFieldByType(fieldType, field, setField, options, setOptions, polyglot) }),
    form.formName === "salary" && fieldType === "Number" && // allow custom salary number fields to link to pay codes
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g3 }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("CustomFieldDrawer.linkFieldValueToPaycode"),
          name: "linkToPayroll",
          checked: linkToPayroll,
          onChange: (_, checked) => setLinkToPayroll(checked)
        }
      ),
      linkToPayroll && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "paycode",
          label: polyglot.t("CustomFieldDrawer.paycode"),
          value: (_b = field.paycode) != null ? _b : "",
          onChange: (e) => setField(__spreadProps(__spreadValues({}, field), { paycode: cleanPayCodeValue(e.target.value) || null }))
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      selectedField && !field.isDefault && /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: deleteField, fullWidth: true, children: polyglot.t("CustomFieldDrawer.deleteField") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          onClick: saveField,
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true
        }
      )
    ] })
  ] });
};
