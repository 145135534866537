"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { Box, SwipeableDrawer } from "@mui/material";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditPayrunEntryDrawer = ({
  onOpen = () => {
  },
  onClose = () => {
  },
  userId,
  payCodes,
  payrunEntry,
  payrunClosed,
  saveIncomeUpdates,
  Content
}) => {
  const { getCachedUserById } = useCachedUsers();
  const user = userId && getCachedUserById(userId);
  return /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open: !!user, onClose, onOpen, children: user && payrunEntry && payCodes && /* @__PURE__ */ jsx(Box, { sx: { px: spacing.px40, py: spacing.p30, width: "440px", boxSizing: "border-box", maxWidth: "100vw" }, children: /* @__PURE__ */ jsx(
    Content,
    {
      user,
      payrunEntry,
      payCodes,
      payrunClosed,
      saveIncomeUpdates: (updates) => __async(void 0, null, function* () {
        const result = yield saveIncomeUpdates(updates);
        if (result) setImmediate(onClose);
        return result;
      })
    }
  ) }) });
};
