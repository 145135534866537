"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box, Chip, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as Back } from "@/images/fields/Left.svg";
import { ReactComponent as Menu } from "@/images/fields/Menu Mobile.svg";
import { ReactComponent as Settings } from "@/images/fields/Settings.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { MainHeaderRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { showCustomDomainSidebar, showDomainSideBar } from "@/v2/util/app-layout.util";
export function TopHeader({
  title,
  actions,
  showAction = false,
  onBack = void 0,
  showBack,
  views,
  settingsHide = false,
  settingsAction,
  settingsTooltip,
  backPath,
  showClose,
  closeAction,
  alphaTesting = false,
  sx = __spreadValues({ width: "100%" }, spacing.mx16)
}) {
  const [state, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isTabletScreen = useMediaQuery(
    `(min-width:${theme.breakpoints.values.sm + 1}px) and (max-width:${theme.breakpoints.values.md - 1}px)`
  );
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return /* @__PURE__ */ jsx(MainHeaderRootStyle, { children: /* @__PURE__ */ jsx(Box, { sx, children: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", py: spacing.s2 }, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", alignItems: "center", gap: spacing.g15 }, children: [
          showBack && /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: iconCTAButtonSx,
              onClick: () => __async(this, null, function* () {
                if (onBack) yield onBack();
                if (backPath) routerHistory.push(backPath);
                else routerHistory.goBack();
              }),
              children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
            }
          ),
          (showDomainSideBar(pathname) || showCustomDomainSidebar(pathname)) && (isTabletScreen || isMobileScreen) && /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: iconCTAButtonSx,
              onClick: () => {
                dispatch({ type: GlobalStateActions.UPDATE_SHOW_SETTING_BAR, payload: !state.showSettingBar });
              },
              children: /* @__PURE__ */ jsx(Menu, __spreadValues({}, iconSize))
            }
          ),
          title && typeof title === "string" ? /* @__PURE__ */ jsx(Typography, { variant: "title2", children: title }) : title,
          !!views && /* @__PURE__ */ jsx(Box, { children: views }),
          alphaTesting && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            Chip,
            {
              label: "Alpha testing",
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                color: themeColors.white,
                backgroundColor: themeColors.Orange,
                padding: "5px 0px 5px 0px"
              })
            }
          ) })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
          settingsAction && !settingsHide && /* @__PURE__ */ jsx(StyledTooltip, __spreadProps(__spreadValues({}, settingsTooltip), { children: /* @__PURE__ */ jsx(IconButton, { sx: iconCTAButtonSx, onClick: settingsAction, children: /* @__PURE__ */ jsx(Settings, __spreadValues({}, iconSize)) }) })),
          showClose && /* @__PURE__ */ jsx(IconButton, { sx: iconCTAButtonSx, onClick: closeAction, children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey })) }),
          showAction && /* @__PURE__ */ jsx(Fragment, { children: actions })
        ] })
      ]
    }
  ) }) }) });
}
