"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Box, Button, Divider, IconButton } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI, AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { convertMinutesToClockHours, isHourlyPolicy } from "@v2/feature/absence/absence.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { getAllowancesUnits } from "@v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { activeTabsFilterBtnSx, tabsFilterBtnSx } from "@v2/styles/buttons.styles";
import { iconButtonSx } from "@v2/styles/icon-button.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { round2Digits } from "@v2/util/number.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const BalanceAdjustmentsDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  policyBreakdown,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        BalanceAdjustmentsDrawerContent,
        {
          userId,
          policyBreakdown,
          absencePolicy,
          refreshAllowancePage: refresh,
          isOnRegularSchedule,
          currentAverageWorkDayLength
        }
      )
    }
  ) });
};
const BalanceAdjustmentsDrawerContent = ({
  userId,
  absencePolicy,
  refreshAllowancePage,
  policyBreakdown,
  isOnRegularSchedule,
  currentAverageWorkDayLength
}) => {
  const currentYear = useMemo(() => policyBreakdown.holidayYear, [policyBreakdown]);
  const { data: userAllowance, mutate: refreshAllowance } = useApiClient(
    AbsenceEndpoints.getUserAbsencePolicyAllowances(absencePolicy.id, userId, currentYear)
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshAllowancePage(absencePolicy.id), refreshAllowance ? refreshAllowance() : void 0]);
  }), [absencePolicy.id, refreshAllowancePage, refreshAllowance]);
  const isHourly = isHourlyPolicy(absencePolicy);
  return /* @__PURE__ */ jsx(Box, { sx: drawerContentSx, children: isEditMode ? /* @__PURE__ */ jsx(
    EditMode,
    {
      userId,
      effectiveYear: currentYear,
      setIsEditMode,
      refresh,
      absencePolicy,
      isOnRegularSchedule,
      currentAverageWorkDayLength,
      isHourly
    }
  ) : /* @__PURE__ */ jsx(
    ReadMode,
    {
      userId,
      effectiveYear: currentYear,
      absencePolicy,
      userAllowance: userAllowance != null ? userAllowance : null,
      setIsEditMode,
      refresh,
      isOnRegularSchedule,
      currentAverageWorkDayLength,
      isHourly
    }
  ) });
};
const ReadMode = ({
  userId,
  absencePolicy,
  effectiveYear,
  userAllowance,
  setIsEditMode,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  isHourly
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { hasScopes } = useScopes();
  const isAdminOrManager = hasScopes(["absence:manager"], { userId });
  const total = useMemo(() => {
    var _a2, _b, _c;
    const prefix = ((_a2 = userAllowance == null ? void 0 : userAllowance.userOneOffAdjustment) != null ? _a2 : 0) > 0 ? "+" : "";
    if (isOnRegularSchedule && !isHourly && currentAverageWorkDayLength) {
      const days = round2Digits(((_b = userAllowance == null ? void 0 : userAllowance.userOneOffAdjustment) != null ? _b : 0) / currentAverageWorkDayLength);
      return `${prefix}${days} day${days === 1 ? "" : "s"}`;
    }
    return `${prefix}${convertMinutesToClockHours((_c = userAllowance == null ? void 0 : userAllowance.userOneOffAdjustment) != null ? _c : 0, polyglot)}`;
  }, [polyglot, userAllowance, isOnRegularSchedule, currentAverageWorkDayLength, isHourly]);
  const [showMessage] = useMessage();
  const deleteEntry = useCallback(
    (entryId) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.deleteAbsencePolicyUserAllowanceOneOffAdjustmentEntry(
          userId,
          absencePolicy.id,
          effectiveYear,
          entryId
        );
        yield refresh();
      } catch (error) {
        showMessage(polyglot.t("AllowanceDrawer.errors.badRequest", { nestError: nestErrorMessage(error) }), "error");
      }
    }),
    [polyglot, showMessage, userId, absencePolicy.id, effectiveYear, refresh]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AllowanceDrawer.adjustments") }),
    (_a = userAllowance == null ? void 0 : userAllowance.oneOffAdjustmentEntries) == null ? void 0 : _a.map((entry) => {
      var _a2;
      const createdBy = entry.createdBy ? getCachedUserById(entry.createdBy) : null;
      const entryValue = isOnRegularSchedule && !isHourly && currentAverageWorkDayLength ? polyglot.t("AllowanceDrawer.noOfDays", {
        smart_count: (_a2 = round2Digits(entry.value / currentAverageWorkDayLength)) != null ? _a2 : 0
      }) : convertMinutesToClockHours(entry.value, polyglot);
      return /* @__PURE__ */ jsxs(
        Box,
        {
          sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: spacing.m5, mb: spacing.m20 },
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", mb: spacing.m5 }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("AllowanceDrawer.adjustment") }),
              /* @__PURE__ */ jsx(IconButton, { sx: iconButtonSx, onClick: () => __async(void 0, null, function* () {
                return yield deleteEntry(entry.id);
              }), children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize)) })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AllowanceDrawer.type") }),
              /* @__PURE__ */ jsx(Typography, { variant: "title4", children: entry.value >= 0 ? polyglot.t("AllowanceDrawer.addition") : polyglot.t("AllowanceDrawer.deduction") })
            ] }),
            createdBy && /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AllowanceDrawer.createdBy") }),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
                /* @__PURE__ */ jsx(UserAvatar, { userId: createdBy.userId, size: "xxsmall" }),
                /* @__PURE__ */ jsx(Typography, { variant: "title4", children: createdBy.displayName || `${createdBy.firstName} ${createdBy.lastName}` })
              ] })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AllowanceDrawer.createdOn") }),
              /* @__PURE__ */ jsx(Typography, { variant: "title4", children: new Date(entry.createdAt).toLocaleDateString() })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AllowanceDrawer.amount") }),
              /* @__PURE__ */ jsx(Typography, { variant: "title4", children: `${entry.value > 0 ? "+" : ""}${entryValue}` })
            ] }),
            entry.notes && /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AllowanceDrawer.note") }),
              /* @__PURE__ */ jsx(Typography, { variant: "title4", children: entry.notes })
            ] }),
            /* @__PURE__ */ jsx(Divider, { sx: { mb: spacing.m20 } })
          ]
        },
        entry.id
      );
    }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("AllowanceDrawer.total") }),
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: total })
    ] }, "total"),
    isAdminOrManager && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setIsEditMode(true), sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, children: polyglot.t("AllowanceDrawer.add") }) })
  ] });
};
const EditMode = ({
  userId,
  absencePolicy,
  effectiveYear,
  setIsEditMode,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  isHourly
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const updateOneOffAdjustment = useCallback(
    (value, notes) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield AbsenceAPI.updateAbsencePolicyUserAllowanceOneOffAdjustment(userId, absencePolicy.id, {
          effectiveYear,
          value,
          notes
        });
        yield refresh();
        setIsEditMode(false);
      } catch (error) {
        showMessage(
          polyglot.t("AllowanceDrawer.errors.couldNotUpdateAllowance", {
            nestError: nestErrorMessage(error)
          }),
          "error"
        );
      }
      setLoading(false);
    }),
    [polyglot, absencePolicy.id, userId, effectiveYear, refresh, showMessage, setIsEditMode]
  );
  const formik = useFormik({
    initialValues: {
      unit: isOnRegularSchedule && !isHourly && currentAverageWorkDayLength ? "day" : "hour",
      value: 0,
      adjustmentType: "addition",
      notes: ""
    },
    validationSchema: Yup.object({
      value: Yup.number().min(0, polyglot.t("AllowanceDrawer.errors.inputValidValue")).typeError(polyglot.t("AllowanceDrawer.errors.inputValidValue")).required(polyglot.t("AllowanceDrawer.errors.validValueRequired")),
      notes: Yup.string().notRequired(),
      unit: Yup.string().oneOf(["hour", "day"]).required(polyglot.t("AllowanceDrawer.errors.validValueRequired")),
      adjustmentType: Yup.string().oneOf(["addition", "deduction"]).required(polyglot.t("AllowanceDrawer.errors.validValueRequired"))
    }),
    onSubmit: (_0) => __async(void 0, [_0], function* ({
      value,
      notes,
      unit,
      adjustmentType
    }) {
      const signMultiplier = adjustmentType === "addition" ? 1 : -1;
      const minutesMultiplier = unit === "hour" || !currentAverageWorkDayLength ? 60 : currentAverageWorkDayLength;
      const adjustment = Math.round(Number(value) * minutesMultiplier * signMultiplier);
      yield updateOneOffAdjustment(adjustment, notes);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AllowanceDrawer.newAdjustment") }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          sx: formik.values.adjustmentType === "addition" ? activeTabsFilterBtnSx : tabsFilterBtnSx,
          onClick: () => formik.setFieldValue("adjustmentType", "addition"),
          disableRipple: true,
          children: polyglot.t("AllowanceDrawer.addition")
        },
        "addition"
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          sx: formik.values.adjustmentType === "deduction" ? activeTabsFilterBtnSx : tabsFilterBtnSx,
          onClick: () => formik.setFieldValue("adjustmentType", "deduction"),
          disableRipple: true,
          children: polyglot.t("AllowanceDrawer.deduction")
        },
        "deduction"
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "unit",
          label: polyglot.t("BalanceAdjustmentsDrawer.unit"),
          options: getAllowancesUnits(polyglot),
          value: formik.values.unit,
          onChange: formik.handleChange,
          disabled: !isOnRegularSchedule || isHourly || !currentAverageWorkDayLength
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("BalanceAdjustmentsDrawer.adjustment"),
          name: "value",
          value: formik.values.value,
          onChange: (e) => {
            formik.setFieldValue("value", e.target.value);
          },
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          error: formik.touched.value && !!formik.errors.value,
          helperText: formik.touched.value && formik.errors.value
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "notes",
        label: polyglot.t("AllowanceDrawer.note"),
        value: formik.values.notes,
        onChange: formik.handleChange,
        endAdornment: "none",
        error: formik.touched.notes && !!formik.errors.notes,
        helperText: formik.touched.notes && formik.errors.notes
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
