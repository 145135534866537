"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { ABSENCE_ME_REQUESTS_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/feature/dashboard/dashboard.scss";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { PolicyAnalytics } from "@/v2/feature/dashboard/features/sections/user-time-planner/components/policy-analytics.component";
import { TimePlannerEmptyState } from "@/v2/feature/dashboard/features/sections/user-time-planner/components/time-planner-empty-state.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserTimePlannerSmall = ({
  userBalance,
  readOnly = false
}) => {
  const { polyglot } = usePolyglot();
  const primaryBalance = userBalance[0];
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly, size: "small", children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column" }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          width: "100%",
          cursor: "pointer"
        },
        onClick: () => routerHistory.push(ABSENCE_ME_REQUESTS_ROUTE),
        children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("UserTimePlannerSmall.timePlanner") }) })
      }
    ) }),
    userBalance && userBalance.length > 0 ? /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          height: "100%",
          width: "100%",
          verticalAlign: "middle",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: spacing.m10
        },
        children: /* @__PURE__ */ jsx(PolicyAnalytics, { primaryBalance })
      }
    ) : /* @__PURE__ */ jsx(TimePlannerEmptyState, {})
  ] }) });
};
