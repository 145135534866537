"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Card, CardContent, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { SettingsReadOnlyLine } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { Loader } from "@/v2/components/loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
export var SectionItemType = /* @__PURE__ */ ((SectionItemType2) => {
  SectionItemType2["Pair"] = "pair";
  SectionItemType2["TextLine"] = "textLine";
  SectionItemType2["Component"] = "component";
  return SectionItemType2;
})(SectionItemType || {});
export const SettingsSubsectionContent = ({
  loadingAll = false,
  sections,
  id,
  useCardStyle = false
}) => {
  return /* @__PURE__ */ jsx(Loader, { loading: !!loadingAll, sx: { minHeight: "auto", height: "100%" }, hideFooter: true, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: "20px" }, id, children: sections.map(
    (s, index) => {
      var _a;
      return s.hidden ? null : /* @__PURE__ */ jsx(
        Loader,
        {
          loading: !!s.loading,
          sx: { minHeight: "auto", height: "100%", width: (_a = s.contentWidth) != null ? _a : "600px", gap: "20px" },
          hideFooter: true,
          children: useCardStyle ? /* @__PURE__ */ jsx(
            Card,
            {
              variant: "outlined",
              sx: {
                boxShadow: "none",
                borderRadius: "15px",
                borderColor: themeColors.GreyLight,
                cursor: s.onClick ? "pointer" : "cursor"
              },
              onClick: s.onClick,
              children: /* @__PURE__ */ jsx(CardContent, { children: /* @__PURE__ */ jsx(SectionContent, { section: s, useCardStyle: true }) })
            }
          ) : /* @__PURE__ */ jsx(SectionContent, { section: s })
        },
        `section-${index}`
      );
    }
  ) }) });
};
const SectionContent = ({ section: s, useCardStyle = false }) => {
  var _a;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      SectionTitle,
      {
        title: s.title,
        headerWidth: (_a = s.headerWidth) != null ? _a : "600px",
        onEdit: s.onEdit,
        editButtonSx: s.editButtonSx,
        editButtonDisabled: s.editButtonDisabled,
        buttons: s.buttons,
        hideEdit: s.hideEdit,
        headerSx: s.headerSx,
        useCardStyle: true
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: s.items.map((item, index) => {
      if (item.hidden) return null;
      if (item.type === "textLine" /* TextLine */)
        return typeof item.value === "object" ? item.value : /* @__PURE__ */ jsx(Typography, { variant: "caption", color: useCardStyle ? "grey" : "black", children: item.value }, index);
      if (item.type === "pair" /* Pair */)
        return /* @__PURE__ */ jsx(SettingsReadOnlyLine, { field: item.label, value: item.value }, item.label);
      if (item.type === "component" /* Component */) return /* @__PURE__ */ jsx(Box, { children: item.value }, index);
      return null;
    }) })
  ] });
};
const SectionTitle = ({
  title,
  buttons,
  onEdit,
  editButtonSx,
  editButtonDisabled = false,
  hideEdit = false,
  buttonIcon = /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)),
  headerWidth,
  headerSx,
  useCardStyle = false
}) => {
  return title || buttons || onEdit ? /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues({
        width: headerWidth,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }, headerSx),
      children: [
        title && typeof title === "string" ? /* @__PURE__ */ jsx(Typography, { variant: useCardStyle ? "title4" : "title3", children: title }) : title ? title : null,
        (buttons || onEdit) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
          buttons,
          onEdit && !hideEdit && /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: __spreadValues(__spreadValues({}, tableIconButtonSx), editButtonSx),
              disabled: editButtonDisabled,
              onClick: onEdit,
              children: buttonIcon
            },
            "edit"
          )
        ] })
      ]
    }
  ) : null;
};
